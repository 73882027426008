<div fxFill fxLayout="row" fxLayoutAlign="start stretch">
  <mat-card class="list-view-main-container no-rounded-corners-left"
    fxFlex="{{ displayService.isTableFullscreen() ? 100 : 35}}"
    [ngClass]="{
      'list-item-hidden': displayService.isDetailsFullscreen(),
      'list-item-margin': displayService.isTableAndDetails() }">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div class="list-view-main__left-header"
          fxFlex="60px"
          fxLayout="row"
          fxLayoutAlign="end center">
          <div *ngIf="displayService.isTableFullscreen()"
            fxFlex="60px"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="change-display-mode"
            (click)="openDetails()"
            [matTooltip]="'general.labels.detailView.expand'|translate">
            <mat-icon>chevron_left</mat-icon>
          </div>
        </div>

        <div class="list-view-main__left-body scrollable-table-container" fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start stretch">
          <mat-table *ngIf="showTable | async; else noResult"
              bhResizeColumn
              [resizeColumnConfigs]="columnService.columnConfigs | async"
              (resizeColumnEnd)="columnService.updateColumnConfigs($event)"
              class="pagination-data-table left-body-list exclude-initial-fixed-basis-size"
              [ngClass]="{'hide-header': !displayService.isTableFullscreen()}"
              fxFlex
              [dataSource]="contactDataSource"
              matSort
              [matSortActive]="contactDataSource.sort.active"
              [matSortDirection]="contactDataSource.sort.direction"
              (matSortChange)="sortData($event)">

            <ng-container cdkColumnDef="contactOverview">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row"
                fxFlex
                class="pagination-data-table__overview-cell left-body-list__overview-cell">
                <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="overview-container">
                  <div class="overview-container__icon-container" fxLayout="row" fxLayoutAlign="center center">
                    <fa-icon [icon]="getContactTypeIcon(row.type)"></fa-icon>
                  </div>
                  <div class="left-body-list__info" fxFlex fxLayout="column">
                    <div fxLayout="row" fxLayoutGap="5px">
                      <bh-contact-status-indicator [status]="row.status"></bh-contact-status-indicator>
                      <div class="text-overflow">{{row.name}}</div>
                    </div>
                    <div class="text-overflow">{{row.identifier || '-'}}</div>
                    <div class="text-overflow">{{row.phoneNumber || '-'}}</div>
                    <div class="text-overflow">{{formatAddress(row.address)}}</div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of selectedColumns">
              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span>{{ column.valueCallback(row) }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.ICON">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__icon-cell">
                  <fa-icon
                    fxLayoutAlign="center center"
                    [icon]="contactTypeResolver.resolveIcon(column.valueCallback(row))">
                  </fa-icon>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  (click)="openDetails()"
                  [routerLink]="[row.contactId]"
                  [matTooltip]="'general.labels.openDetails'|translate">
                  <mat-icon>info</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__status-cell" fxLayoutGap="5px">
                  <bh-contact-status-indicator [status]="row.status" [showTooltip]="false"></bh-contact-status-indicator>
                  <span class="text-overflow">{{row.status | contactStatus}}</span>
                </mat-cell>
              </ng-container>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns"
              [routerLink]="[row.contactId]"
              (dblclick)="openDetails()"
              routerLinkActive="left-body-item--active">
            </mat-row>
          </mat-table>

        </div>

        <div class="list-view-main__left-body-paginator" fxLayout="row wrap">
          <div class="button-wrapper" fxFlex="80px">
            <button mat-icon-button class="rotatable" (click)="openConfiguration()"
              [matTooltip]="'general.labels.confColumnArrangement'|translate">
              <mat-icon>settings</mat-icon>
            </button>
            <button mat-icon-button (click)="openContactListExport()"
                    [matTooltip]="'modules.contact.contactListExport.title'|translate">
              <fa-icon [icon]="faFileExcel" size="2x"></fa-icon>
            </button>
          </div>
          <mat-paginator #paginator fxFlex="0"
            [length]="contactDataSource.pagination.totalElements"
            [pageIndex]="contactDataSource.pagination.index"
            [pageSize]="contactDataSource.pagination.size"
            [pageSizeOptions]="[5, 25, 50, 100]"
            bhCurrentItemPaginator
            [data]="contactDataSource.contacts | async"
            [currentItemId]="(this.contactDataSource.currentContact | async)?.contactId"
            [isIdentifierOfItem]="isIdentifierOfContact"
            (paginationChanges)="onPaginateChange($event)"
            data-osp-test="list-paginator">
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="list-view-main-container"
    fxFlex="{{ displayService.isDetailsFullscreen() ? 100 : 65}}"
    [ngClass]="{'list-item-hidden': displayService.isTableFullscreen()}">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #noResult>
  <div class="list-view-main__no-result" fxFlex fxLayout="row"
       fxLayoutAlign="center center">
    <div>{{'general.labels.noSearchResults'|translate}}</div>
  </div>
</ng-template>
