export enum OrganisationType {
  Abteilung = <any>'Abteilung',
  Bereich = <any>'Bereich',
  Firma = <any>'Firma',
  Standort = <any>'Standort',
  Subfirma = <any>'Subfirma',

  Department = <any>'Department',
  Area = <any>'Area',
  Company = <any>'Company',
  Location = <any>'Location',
  Subcompany = <any>'Subcompany'
}
