import { IntervalRetake } from './interval-retake';
import { Retake } from './retake.enum';

export class EmployeeQualification {
    qualificationId: string;
    customerId: string;
    qualificationName: string;
    qualificationDescription: string;
    acquisitionDate: Date;
    qualificationRetakeType: Retake;
    lastRenewal: Date;
    retakeDate: Date;
    interval: IntervalRetake;
    eachYearRetakeDate: number;
    eachYearRetakeMonth: number;
    employeeId: string;
    qualificationMediumWarningThreshold: number;
    qualificationHighWarningThreshold: number;
}

