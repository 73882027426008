import { GuardedNavigableInputComponent } from '../../../shared/navigation-guards/guarded-navigable-input.component';
import { ElementRef, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { ChatWriteCommand } from '../contract/chat-write-command';
import { CommunicationDataSource } from '../shared/communication.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../core/keycloak';
import { ChatMessageView } from '../contract/chat-message-view';
import { ChatMessage } from '../contract/chat-message';
import { ChatUser } from '../contract/chat-user.interface';
import { CommunicationService } from '../shared/communication.service';
import { UserStatus } from 'app/shared/enums/user-status.enum';
import { RouterHistory } from '../../../shared/router-history';

@Directive()
export abstract class BaseChatComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  @ViewChild('chatScroll', { static: true }) chatScroll: ElementRef;

  public answerOptionsArea_active = false;
  public chatInput = '';
  public replyOptionsInputs: string[] = [''];
  public recipient: ChatUser;
  public scrollTop = 0;
  public detailMsg: ChatMessageView;
  public messages: ChatMessageView[] = [];

  private lastNewDate: Date;
  private recipientId: string;

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected communicationDataSource: CommunicationDataSource,
                        protected communicationService: CommunicationService,
                        protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.recipientId = this.route.snapshot.paramMap.get('id');
    this.communicationService.getChatPartner(this.recipientId).subscribe((user: ChatUser) => this.recipient = user);
    this.getChatMessages();
  }

  public replyToMsg(option: string, chatMessageId: string) {
    if (option) {
      const msg = new ChatWriteCommand();
      msg.recipientId = this.recipientId;
      msg.message = option;
      msg.answeredMessage = chatMessageId;
      this.communicationDataSource.chatWrite(msg);
    }
  }

  addReplyOptionsInput() {
    if (this.replyOptionsInputs.length < 4) {
      this.replyOptionsInputs.push('');
    }
  }

  toggleAnswerOptionsArea(): void {
    this.answerOptionsArea_active = !this.answerOptionsArea_active;
  }

  sendMessageClick(): void {
    if (this.chatInput && this.chatInput !== '') {
      const msg = new ChatWriteCommand();
      msg.recipientId = this.recipientId;
      msg.message = this.chatInput;
      if (this.answerOptionsArea_active) {
        msg.replyOptions = this.replyOptionsInputs;
      }
      this.communicationDataSource.chatWrite(msg);
    }
    this.replyOptionsInputs = [''];
    this.chatInput = '';
    this.answerOptionsArea_active = false;
  }

  public getMessageById(id: string): ChatMessageView {
    return this.messages.find(msg => {
      return msg.chatMessageId === id;
    });
  }

  private getChatMessages(): void {
    this.communicationDataSource.currentChatMessages.subscribe((res: ChatMessage[]) => {
      if (res) {

        const oldestDate: Date = this.messages.map(msg => msg.messageDate)
          .reduce((a, b) => {
            return a > b ? a : b;
          }, undefined);

        const initialSize = this.messages.length;

        this.messages = this.messages.concat(
          res
            .map((msg) => new ChatMessageView(msg, this.recipientId))
            .filter(msg => !oldestDate || msg.messageDate.valueOf() > oldestDate.valueOf())
            .sort((cur, last) => (cur.messageDate.valueOf() < last.messageDate.valueOf()) ? -1 : 1));
        this.recalculateNewDate();
        this.applyNewLine();

        if (initialSize < this.messages.length) {
          setTimeout(() => {
            if (this.chatScroll.nativeElement.scrollHeight) {
              this.scrollTop = this.chatScroll.nativeElement.scrollHeight;
            }
          }, 0);
        }
      }
    });
    this.communicationDataSource.pollMessages(this.recipientId);
  }

  navigateToChatList(): void {
    this.communicationDataSource.resetChatMessages();
    this.router.navigate([{outlets: {sidenav: ['chat']}}], {skipLocationChange: true});
  }

  navigateToMobileChatList(): void {
    this.communicationDataSource.resetChatMessages();
    this.router.navigate(['mobile/chat']);
  }

  private recalculateNewDate() {
    this.messages.forEach(msg => {
      if (!this.lastNewDate) {
        this.lastNewDate = msg.messageDate;
        msg.newDateLine = msg.messageDate.toString();
      }
      const lastDate = new Date(this.lastNewDate);
      const newDate = new Date(msg.messageDate);
      if (newDate.getDate() !== lastDate.getDate()) {
        this.lastNewDate = msg.messageDate;
        msg.newDateLine = this.lastNewDate.toString();
      }
    });
  }

  private applyNewLine() {
    let found = false;
    this.messages.forEach(msg => {
      if (!found && !msg.read && msg.recipientId === this.authService.getUserUserId()) {
        found = true;
        msg.newLine = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.communicationDataSource.stopMessagesPoll();
  }

  public get chatPartnerActive(): boolean {
    return this.recipient && this.recipient.status === UserStatus.ACTIVE;
  }
}
