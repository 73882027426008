import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { IntervalPanelDisplayMode } from 'app/modules/maintenance/rules/interval-panel/interval-panel-display-mode.enum';
import { IntervalPanelInputType } from 'app/modules/maintenance/rules/interval-panel/interval-panel-input-type.enum';
import { DueDateEditDialogParam } from 'app/modules/maintenance/tasks/shared/components/maintenance-task-due-date-edit/due-date-edit-dialog-param.interface';
import { DueDateEditDialogResult } from 'app/modules/maintenance/tasks/shared/components/maintenance-task-due-date-edit/due-date-edit-dialog-result.interface';
import { MaintenanceTaskDueDateEditComponent } from 'app/modules/maintenance/tasks/shared/components/maintenance-task-due-date-edit/maintenance-task-due-date-edit.component';
import { SetDueDateCommand } from 'app/modules/maintenance/tasks/shared/contract/commands/set-due-date.command';
import { MaintenanceTaskView } from 'app/modules/maintenance/tasks/shared/maintenance-task';
import { MaintenanceTaskDataSource } from 'app/modules/maintenance/tasks/shared/service/maintenance-task.datasource';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { IntervalPanelFormType } from 'app/shared/services/interval-panel-form-group-factory/interval-panel-form-type.enum';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface TaskViewChange extends SimpleChanges {
  task: SimpleChange;
}

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-view-general-rule',
  templateUrl: './maintenance-task-view-general-rule.component.html',
  styleUrls: ['./maintenance-task-view-general-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskViewGeneralRuleComponent implements OnChanges {
  @Input() task: MaintenanceTaskView;
  private _isDueCalculated = new BehaviorSubject<boolean>(true);
  public readonly isDueCalculated = this._isDueCalculated.asObservable();
  public readonly emptyValue = '';
  public readonly formType = IntervalPanelFormType;
  public readonly inputTypes = IntervalPanelInputType;
  public readonly displayMode = IntervalPanelDisplayMode;
  public readonly canEditTask = this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE);

  constructor(
    private router: Router,
    private authService: KeycloakService,
    private dialog: MatDialog,
    private maintenanceTaskDataSource: MaintenanceTaskDataSource,
  ) {}

  public ngOnChanges({ task }: TaskViewChange): void {
    if (task) {
      this._isDueCalculated.next(this.isDueDateCalculated(this.task));
    }
  }

  public navigateToCompletedTask(taskId: string): void {
    if (taskId) {
      this.router.navigate(['maintenance', 'tasks', 'maintenance-completed-list', taskId]);
    }
  }

  public setDueDate(event: PointerEvent): void {
    event.stopPropagation();
    const data: DueDateEditDialogParam = {
      isCalculated: !Boolean(this.task.nextManualThresholdDate),
      date: !this.isMaxDate(this.task.dueDateInfo.dueDate) && this.task.dueDateInfo.dueDate
        ? new Date(this.task.dueDateInfo.dueDate)
        : null,
      lastCompletionDate: this.task.latestCompletionDate
        ? moment(this.task.latestCompletionDate).add(1, 'd').toDate()
        : null,
    }

    this.dialog.open(MaintenanceTaskDueDateEditComponent, { data, autoFocus: null, restoreFocus: false })
      .afterClosed()
      .pipe(
        untilDestroyed(this)
      ).subscribe((result: DueDateEditDialogResult) => {
      if (result?.result === dialogResults.SAVE) {
        this.maintenanceTaskDataSource.setDueDate(new SetDueDateCommand(this.task.id, result.date)).subscribe(() => {
          const isDueDateSetManually = result.date !== null;
          this._isDueCalculated.next(!isDueDateSetManually);
        })
      }
    });
  }

  public navigateToEquipment(equipmentId: string): void {
    if (equipmentId) {
      this.router.navigate(['assets', 'equipment', 'list', equipmentId, 'tasks'], {
        queryParams: {tabIndex: 0},
      });
    }
  }

  private isMaxDate(date: string): boolean {
    return (date || '').indexOf('3000-01-01') > -1;
  }

  private isDueDateCalculated(task: MaintenanceTaskView): boolean {
    return !Boolean(task?.nextManualThresholdDate)
  }
}
