import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NativeAppLinksDialogComponent } from 'app/shared/components/native-app-links-dialog/native-app-links-dialog.component';
import { CustomDeviceDetectorService } from 'app/shared/services/custom-device-detector.service';
import { environment } from 'environments/environment';
import { MobileWebAppPopupMode } from 'app/shared/enums/mobile-web-app-popup-mode.enum';

@Injectable()
export class CanActivateChildMobileWebApp implements CanActivateChild {

  constructor(private dialog: MatDialog,
              private deviceService: CustomDeviceDetectorService) {
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): true {
    if (
      (state.url.includes('mobile') || this.deviceService.isMobile()) &&
        environment.MOBILE_WEB_APP_POPUP_MODE !== MobileWebAppPopupMode.NOT_SHOW_POPUP && !this.deviceService.isModalShown.getValue()
    ) {
      this.dialog.open(NativeAppLinksDialogComponent, {
        disableClose: true,
        width: '80vw'
      });
    }

    this.deviceService.isModalShown.next(true);

    return true;
  }
}
