import { Pipe, PipeTransform } from '@angular/core';
import { byTypeFieldName, MaintenanceFieldValue } from 'app/shared/contract/task-completion/maintenance-field-value';

@Pipe({
  name: 'sortMaintenanceFields'
})
export class SortMaintenanceFieldsPipe implements PipeTransform {

  public transform(fields: MaintenanceFieldValue[]): MaintenanceFieldValue[] {
    return fields.sort(byTypeFieldName())
  }

}
