import { AttachedDocument } from '../../../shared/contract/attached-document.interface';

export class AddServiceContractCommand {
  serviceContractId: string;
  equipmentId: string;
  serviceContractNumber: string;
  contractPartner: string;
  monthlyRate: number;
  composedOf: string;
  comment: string;
  documents: AttachedDocument[];
  imported: boolean;
}
