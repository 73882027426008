import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { RealmType } from '../../../../shared/contract/realm-type';
import { LanguageService } from '../../../../shared/services/language.service';
import { UsersStore } from '../../shared/users.store';
import { SyncKeycloakCommand } from '../shared/sync-keycloak-command';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { KeycloakInfo } from '../shared/keycloak-info';
import moment from 'moment';
import {
  ConfirmationDialogComponent,
} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { FocusMonitor } from '@angular/cdk/a11y';

interface RealmInfo extends KeycloakInfo {
  realmName: string;
  inSync?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'bh-user-sync-keycloak',
  templateUrl: './user-sync-keycloak.component.html',
  styleUrls: ['./user-sync-keycloak.component.scss'],
})
export class UserSyncKeycloakComponent implements OnInit, OnDestroy, AfterViewChecked {

  realms: RealmInfo[] = [];
  displayedColumns: string[] = ['realmType', 'lastSyncStarted', 'lastSyncFinished', 'status'];

  private readonly definedRealms: RealmInfo[] = [
    {realmType: RealmType.DEFAULT, realmName: this.translate('modules.userrole.roleRealm.default')},
    {realmType: RealmType.WACKER_NEUSON, realmName: this.translate('modules.userrole.roleRealm.wackerNeuson')},
    {realmType: RealmType.TRIATHLON, realmName: this.translate('modules.userrole.roleRealm.triathlon')},
  ];

  private interval;

  constructor(protected languageService: LanguageService,
              protected dialogRef: MatDialogRef<UserSyncKeycloakComponent>,
              protected dialog: MatDialog,
              public usersStore: UsersStore,
              private focusMonitor: FocusMonitor) {
  }

  ngOnInit(): void {
    this.usersStore.syncKeycloakStatus
      .subscribe(keycloakInfos => {
        this.realms = this.mergeKeycloakInfo(keycloakInfos);
      });

    this.usersStore.fetchSyncKeycloakStatus();
    this.interval = setInterval(() => {
      this.usersStore.fetchSyncKeycloakStatus();
    }, 5000);
  }

  ngAfterViewChecked() {
    this.focusMonitor.stopMonitoring(document.getElementById('syncUsersButton'));
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public isSyncInProcess({lastSyncStarted, lastSyncFinished, inSync}: RealmInfo): boolean {
    return inSync
      || (!!lastSyncStarted && (!lastSyncFinished || moment(lastSyncFinished).isBefore(moment(lastSyncStarted))));
  }

  public isAnySyncInProcess(): boolean {
    for (const realm of this.realms) {
      if (this.isSyncInProcess(realm)) {
        return true;
      }
    }

    return false;
  }

  public syncAll(): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.userrole.roleRealm.syncRealm.syncAllQuestion');
    dialogRef.afterClosed()
    .subscribe(result => {
      if (result === dialogResults.YES) {
        this.realms.forEach(realm => {
          realm.inSync = true;
        });

        this.usersStore.syncKeycloak(new SyncKeycloakCommand());
      }
    });
  }

  public sync(realmInfo: RealmInfo): void {
    const cmd: SyncKeycloakCommand = new SyncKeycloakCommand();
    cmd.realm = realmInfo.realmType;

    realmInfo.inSync = true;

    this.usersStore.syncKeycloak(cmd);
  }

  protected translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private mergeKeycloakInfo(keycloakSyncInfo: KeycloakInfo[]): RealmInfo[] {
    const realms: RealmInfo[] = [];

    this.definedRealms.forEach(realm => {
      const realmStatus = keycloakSyncInfo.find(item => item.realmType === realm.realmType);
      realms.push({...realm, ...realmStatus});
    });

    return realms;
  }
}
