import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export type PublicApiConnector = {
  connectorId: string;
  clientId: string;
  customerId: string;
  createdDate: Date;
};

export type PublicApiConnectorData = {
  clientId: string;
  secret: string;
  accessTokenUrl: string;
};

@Injectable({providedIn: 'root'})
export class PublicApiConnectorService {

  private url = environment.APP_JOB_SERVICE_BASE_URL + '/api/v1/osp';

  constructor(private http: HttpClient) {}

  public getPublicApiConnector(): Observable<PublicApiConnector> {
    return this.http.get<PublicApiConnector>(`${this.url}/connector`);
  }

  public createPublicApiConnector(): Observable<PublicApiConnectorData> {
    return this.http.post<PublicApiConnectorData>(`${this.url}/create`, {});
  }

  public deletePublicApiConnector(): Observable<string> {
    return this.http.post(`${this.url}/delete`, {}, {responseType: 'text'});
  }
}
