import * as _ from 'lodash';
import { OrganisationFilterView } from './filter-view/organisation-filter-view.interface';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';

export class FilterUtils {
  public static convertToOrganisationMap(organisations: ViewOrganisation[]): Map<string, ViewOrganisation> {
    return new Map<string, ViewOrganisation>(organisations.map(organisation => [organisation.organisationId, organisation]));
  }

  public static toFlatList(organisations: ViewOrganisation[]): ViewOrganisation[] {
    return [
      ...(organisations || []),
      ...organisations.reduce((acc, { children }) => [
        ...acc,
        ...(FilterUtils.toFlatList(children) || [])
      ], [])
    ];
  }

  public static pushMissingParentOrganisationToFilters(
    filterOrgs: OrganisationFilterView[],
    organisationMap: Map<string, ViewOrganisation>
  ): void {
    const originalFilterOrgs = _.cloneDeep(filterOrgs);
    originalFilterOrgs.map(filterOrg =>
      organisationMap.get(filterOrg.organisationId))
    .filter(organisation => organisation?.parentOrganisationId != null)
    .forEach(organisation =>
      this.collectMissingParentOrganisations(organisation, filterOrgs, organisationMap));
  }

  private static collectMissingParentOrganisations(
    organisation: ViewOrganisation,
    filterOrgs: OrganisationFilterView[],
    organisationMap: Map<string, ViewOrganisation>
  ): void {
    if (this.shouldAddParentOrganisation(organisation, filterOrgs)) {
      const parentOrganisation = organisationMap.get(organisation.parentOrganisationId);
      if (parentOrganisation) {
        this.pushFilterOrganisationToList(parentOrganisation, filterOrgs);
        this.collectMissingParentOrganisations(parentOrganisation, filterOrgs, organisationMap);
      }
    }
  }

  private static shouldAddParentOrganisation(organisation: ViewOrganisation, filterOrgs: OrganisationFilterView[]): boolean {
    return organisation
      && organisation.parentOrganisationId
      && !filterOrgs.map(org => org.organisationId).includes(organisation.parentOrganisationId);
  }

  private static pushFilterOrganisationToList(organisation: ViewOrganisation, organisations: OrganisationFilterView[]) {
    organisations.push({
      organisationId: organisation.organisationId,
      organisationName: organisation.organisationName,
      count: undefined
    });
  }

}
