import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { CompleteReminderCommand } from 'app/modules/equipment/contract/complete-reminder-command';
import { DeleteReminderCommand } from 'app/modules/equipment/contract/delete-reminder-command';
import { UnassignMaintenanceRuleCommand } from 'app/modules/maintenance/rules/shared/contract/commands/unassign-maintenance-rule.command';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DisplayService } from 'app/shared/display.service';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { Modules } from 'app/shared/enums/modules.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { filter } from 'rxjs';
import { MaintenanceTaskCompleteNavigationExtras } from '../../shared/contract/maintenance-task-complete-extra-state.interface';
import { MaintenanceTaskView } from '../../shared/maintenance-task';
import { MaintenanceTaskDataSource } from '../../shared/service/maintenance-task.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-view',
  templateUrl: './maintenance-task-view.component.html',
  styleUrls: ['./maintenance-task-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskViewComponent implements OnInit {
  public readonly currentTask = this.maintenanceTaskDataSource.currentTask;
  public readonly maintenanceCategory = MaintenanceCategory;
  public readonly allowedToManage = this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE) &&
    this.authService.hasModule(Modules.SERVICE);

  constructor(
    public readonly displayService: DisplayService,
    private route: ActivatedRoute,
    private router: Router,
    private maintenanceTaskDataSource: MaintenanceTaskDataSource,
    private authService: KeycloakService,
    private dialog: MatDialog,
    private languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.initListeners();
    this.maintenanceTaskDataSource.refreshOnChanges();
  }

  public completeTask(task: MaintenanceTaskView): void {
    if (task?.id) {
      const navigationExtras: MaintenanceTaskCompleteNavigationExtras = {
        state: {
          navigationAfterComplete: 'maintenance/tasks/maintenance-list'
        }
      };
      this.router.navigate(['maintenance', 'tasks', 'complete', task.id], navigationExtras).then(() => {
        this.maintenanceTaskDataSource.changeCurrentTask(null);
      });
    }
  }

  public unassignRule(task: MaintenanceTaskView): void {
    if (task?.maintenanceRuleId && task?.equipmentId) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.getConfirmationDialog(
        this.translate('shared.maintenance.task.unassignRuleTitle'),
        this.translate('shared.maintenance.task.unassignRuleMessage')
      );
      dialogRef.afterClosed().subscribe((result: string) => {
        if (result === dialogResults.YES) {
          const command = new UnassignMaintenanceRuleCommand(task.maintenanceRuleId, task.equipmentId);
          this.maintenanceTaskDataSource.unassignRule(command);
        }
      });
    }
  }

  public completeReminder(task: MaintenanceTaskView): void {
    if (task?.id && task?.equipmentId) {
      const completeReminderCommand = new CompleteReminderCommand(task.id, task.equipmentId);
      this.maintenanceTaskDataSource.completeReminder(completeReminderCommand);
    }
  }

  public deleteReminder(task: MaintenanceTaskView): void {
    if (task?.id) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.getConfirmationDialog(
        this.translate('shared.maintenance.task.deleteReminderTitle'),
        this.translate('shared.maintenance.task.deleteReminderMessage')
      );
      dialogRef.afterClosed().subscribe((result: string) => {
        if (result === dialogResults.YES) {
          const command = new DeleteReminderCommand(task.id);
          this.maintenanceTaskDataSource.deleteReminder(command);
        }
      });
    }
  }

  private initListeners(): void {
    this.routeParamListener();
  }

  private routeParamListener(): void {
    this.route.params
      .pipe(
        filter((params: Params) => params.id),
        untilDestroyed(this))
      .subscribe(({ id }: Params) => this.maintenanceTaskDataSource.changeCurrentTask(id));
  }

  private getConfirmationDialog(confirmTitle: string, confirmMessage: string): MatDialogRef<ConfirmationDialogComponent> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = confirmTitle;
    dialogRef.componentInstance.confirmMessage = confirmMessage;
    return dialogRef;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
