<div class="scrollable" fxLayout="column" fxLayoutAlign="start stretch">
  <bh-title [text]="'general.comment.pl'|translate"></bh-title>

  <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <div fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-card class="comment-control-panel">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
          <span [matTooltip]="getAddTooltip()">
            <button mat-icon-button (click)="addEditComment(null)"
                    *ngIf="updateComments"
                    fxFlex="nogrow"
                    [disabled]="isProjectFinished()">
              <mat-icon>add</mat-icon>
            </button>
          </span>
        </div>
      </mat-card>
    </div>

    <div fxFlex="80" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-card class="comment-card" *ngFor="let comment of projectStore.comments | async">
        <mat-card-content class="comment-card__content">
          <div class="comment-card__content-main" fxLayout="row" fxLayoutAlign="space-between stretch"
               fxLayoutGap="10px">
            <div class="comment-card__properties" fxFlex="80" fxLayoutAlign="start none">
              <span>{{comment.text}}</span>
            </div>

            <div *ngIf="allowEditingDeleting(comment.userId) && updateComments && !isProjectFinished()">
              <button mat-icon-button
                      (click)="addEditComment(comment)" [matTooltip]="'general.buttons.change'|translate">
                <mat-icon class="bh-mat-icon">edit</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="deleteComment(comment)" [matTooltip]="'general.buttons.delete'|translate">
                <mat-icon class="bh-mat-icon">delete_forever</mat-icon>
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end none">
            <span class="comment-card__submitted-by">
              {{'general.comment.view.addedBy'|translate}}: {{comment.submittedBy}}
              {{'general.at'|translate}}: {{comment.submitDate | date:'EEEE, d. MMM y hh:mm a':'':getCurrentLocale()}}
              <span *ngIf="comment.lastEditDate">
                , {{'general.comment.view.lastEditedAt'|translate}}: {{comment.lastEditDate | date:'EEEE, d. MMM y hh:mm a':'':getCurrentLocale()}}
              </span>
            </span>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
