import { Chip } from 'app/shared/contract/chip';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { FilterValue } from 'app/shared/contract/filter/filter-value';
import { FilterChipsConverterGroupedTree } from './filter-chips-converter-grouped-tree.class';

export class FilterChipsConverterEquipmentType extends FilterChipsConverterGroupedTree {

  protected createChip(filter: FilterValue): Chip {
    return filter.parentStoreName
      ? this.createChipClass(filter)
      : this.createChipType(filter);
  }

  private createChipType(item: FilterValue): Chip {
    const selectedChildFilterValues = this.getChildFilterValues(item);
    const subChips = [item, ...selectedChildFilterValues]
      .map(filterValue => this.createChipClass(filterValue));

    return this.createChipFilterValue(
      item,
      { filterType: FilterTypes.TYPE, subChips }
    );
  }

  private createChipClass(item: FilterValue): Chip {
    return this.createChipFilterValue(item, { filterType: this.typeName });
  }

}
