import { Pipe, PipeTransform } from '@angular/core';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { Utils } from 'app/shared/utils';


@Pipe({
  name: 'isValidLocations'
})
export class IsValidLocationsPipe implements PipeTransform {

  public transform(value: MapLocationMarker[]): boolean {
    return Array.isArray(value) &&
      value.length > 0 &&
      value.some(marker => Utils.isValidLocation(marker));
  }

}
