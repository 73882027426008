import { BulkItemColumnDefinitionService } from '../../shared/services/bulk-item-column-definition.service';
import { Injectable } from '@angular/core';
import { ColumnService } from '../../../../shared/column.service';
import { DisplayService } from '../../../../shared/display.service';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

@Injectable()
export class BulkItemColumnService extends ColumnService {

  constructor(
    protected userConfigurationService: UserConfigurationService,
    protected displayService: DisplayService,
    protected authService: KeycloakService,
    bulkItemColumnDefinitionService: BulkItemColumnDefinitionService
  ) {
    super(
      'bulkItemOverview',
      bulkItemColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.bulkItemListConfiguration.columns,
      userConfigurationService.userConfiguration.bulkItemListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.bulkItemListConfiguration.pageSize },
      displayService,
      authService
    );
  }

  get columnLimit(): number {
    return undefined;
  }

  get selectedColumnsList(): string[] {
    return this.userConfigurationService.userConfiguration.bulkItemListConfiguration.columns;
  }

  public selectColumns(columns: ColumnDefinition[], pageSize: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === 'bulkItemIcon'
      || column.cdkColumnDef === 'bulkItemNumber'
      || column.cdkColumnDef === 'bulkItemName'
      || column.cdkColumnDef === 'bulkItemType'
      || column.cdkColumnDef === 'bulkItemCategory'
      || column.cdkColumnDef === 'bulkItemBGLCode'
      || column.cdkColumnDef === 'bulkItemScanCode'
      || column.cdkColumnDef === 'openDetails';
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveBulkItemListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.columnConfigsSubject.getValue(),
      this.pageSizeSubject.getValue(),
    );
  }
}
