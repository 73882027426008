import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomMaterialModule} from '../../core/custom-material/custom-material.module';
import {TelematicUnitAdministrationService} from './services/telematic-unit-administration.service';
import {TranslateModule} from '@ngx-translate/core';
import {telematicUnitsAdministrationRouting,} from './telematic-units-administration.routing';
import {RouterModule} from '@angular/router';
import {TelematicUnitAdministrationDatasource} from './services/telematic-unit-administration.datasource';
import {SharedModule} from '../../shared/shared.module';
import {
  TelematicUnitAdministrationAddEditComponent
} from './telematic-unit-administration-add-edit/telematic-unit-administration-add-edit.component';
import {TelematicUnitAdministrationTypeResolver} from './shared/telematic-unit-administration-type.resolver';
import {TelematicUnitsAdministrationResolver} from './shared/telematic-units-administration.resolver';
import {
  TelematicUnitsAdministrationListComponent
} from './telematic-units-administration-list/telematic-units-administration-list.component';
import {
  TelematicUnitsAdministrationBulkImportComponent
} from './telematic-units-administration-bulk-import/telematic-units-administration-bulk-import.component';
import {
  TelematicUnitAdministrationAssignComponent
} from './telematic-unit-administration-assign/telematic-unit-administration-assign.component';
import {TelematicsUnitColumnService} from '../disposition/shared/services/telematics-unit-column.service';
import {
  TelematicUnitsAdministrationBulkImportResultComponent
} from './telematic-units-administration-bulk-import-result/telematic-units-administration-bulk-import-result.component';
import {EquipmentInfoDialogComponent} from './equipment-info-dialog/equipment-info-dialog.component';
import {
  TelematicUnitLiveDataDialogComponent
} from './telematic-unit-live-data-dialog/telematic-unit-live-data-dialog.component';
import {MapModule} from '../../shared/modules/map/map.module';
import {
  TelematicUnitFotaWebDataDialogComponent
} from './telematic-unit-fota-web-data-dialog/telematic-unit-fota-web-data-dialog.component';

@NgModule({
  declarations: [
    TelematicUnitsAdministrationListComponent,
    TelematicUnitsAdministrationBulkImportComponent,
    TelematicUnitsAdministrationBulkImportResultComponent,
    TelematicUnitAdministrationAddEditComponent,
    TelematicUnitAdministrationAssignComponent,
    EquipmentInfoDialogComponent,
    TelematicUnitFotaWebDataDialogComponent,
    TelematicUnitLiveDataDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexLayoutModule,
    telematicUnitsAdministrationRouting,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    MapModule
  ],
  providers: [
    TelematicUnitAdministrationService,
    TelematicUnitAdministrationDatasource,
    TelematicsUnitColumnService,
    TelematicUnitsAdministrationResolver,
    TelematicUnitAdministrationTypeResolver
  ]
})
export class TelematicUnitAdministrationModule { }
