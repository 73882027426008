import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaintenanceTask } from 'app/modules/maintenance/tasks/shared/maintenance-task';

@Component({
  selector: 'bh-maintenance-task-view-general-reminder',
  templateUrl: './maintenance-task-view-general-reminder.component.html',
  styleUrls: ['./maintenance-task-view-general-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskViewGeneralReminderComponent {
  @Input() task: MaintenanceTask;
  public readonly emptyValue = '';

  constructor(private router: Router) {}

  public navigateToEquipment(equipmentId: string): void {
    if (equipmentId) {
      this.router.navigate(['assets', 'equipment', 'list', equipmentId, 'tasks'], {
        queryParams: {tabIndex: 0},
      });
    }
  }
}
