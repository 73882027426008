<div class="dialog-wrapper" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title>{{'general.labels.importTeltonikaTelematicUnitsSkippedUnits'|translate}}</h1>

  <mat-dialog-content fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-form-field class="import-telematics-unit-result">
      <textarea matInput [value]="result"
                readonly rows="10" ></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close>
      {{'general.buttons.close'|translate}}
    </button>
  </mat-dialog-actions>
</div>
