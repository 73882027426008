import { Injectable } from '@angular/core';
import { MapIconResolverProject } from './map-icon-resolver-entities/map-icon-resolver-project.service';
import { MapIconResolverOrganisation } from './map-icon-resolver-entities/map-icon-resolver-organisation.service';
import { MapIconResolverStock } from './map-icon-resolver-entities/map-icon-resolver-stock.service';
import { MapIconResolverContact } from './map-icon-resolver-entities/map-icon-resolver-contact.service';
import { MapIconResolverTransport } from './map-icon-resolver-entities/map-icon-resolver-transport.service';
import { MapIconPayloadStock } from './types/map-icon-payload-stock.type';
import { MapIconPayloadContact } from './types/map-icon-payload-contact.type';
import { MapIconPayloadTransport } from './types/map-icon-payload-transport.type';

@Injectable()
export class MapIconResolver {
  constructor(
    private resolverProject: MapIconResolverProject,
    private resolverOrganisation: MapIconResolverOrganisation,
    private resolverStock: MapIconResolverStock,
    private resolverContact: MapIconResolverContact,
    private resolverTransport: MapIconResolverTransport,
  ) {}

  public getIconProject(): string {
    return this.resolverProject.getSvgIcon();
  }

  public getIconOrganisation(): string {
    return this.resolverOrganisation.getSvgIcon();
  }

  public getIconStock(item: MapIconPayloadStock): string {
    return this.resolverStock.getSvgIcon(item);
  }

  public getIconContact(item: MapIconPayloadContact): string {
    return this.resolverContact.getSvgIcon(item);
  }

  public getIconTransport(item: MapIconPayloadTransport): string {
    return this.resolverTransport.getSvgIcon(item);
  }
}

