<div class="m-equipment-view-timeline" fxFlexFill fxLayout="column">

  <div class="m-equipment-view-timeline-entry"
       fxLayout="row" fxLayoutAlign="start center"
       (click)="openDetails(lc)"
       *ngFor="let lc of lifeCycles">

    <span class="m-equipment-view-timeline-entry-icon">
      <mat-icon *ngIf="useCustomFontIcon(lc)" fontSet="{{getIcon(lc)}}"></mat-icon>
      <fa-icon *ngIf="!useCustomFontIcon(lc)" [icon]="getIcon(lc)"></fa-icon>
    </span>

    <div class="m-equipment-view-timeline-details" fxLayout="column" fxLayoutAlign="start start">
      <div class="header_date">
        {{lc.logDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}
      </div>
      <div class="header_title">
        {{lc | lifeCycleTitle:true}}
      </div>
      <div class="header_subtitle">
        {{getSubtitle(lc)}}
      </div>
    </div>

  </div>

  <div class='no-timeline-found' *ngIf="lifeCycles.length <= 0">
    <quill-editor
      [readOnly]=true
      [placeholder]="'modules.equipment.equipmentDetailTimeline.noData' | translate"
      [modules]="{toolbar: false}"
      format="html"
    >
    </quill-editor>
  </div>


</div>
