import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FilterTypes, FilterTypesDisplayNames } from '../contract/filter/filter-types.enum';

@Pipe({
  name: 'searchFilterDefaultStatusName'
})

@Injectable()
export class SearchFilterDefaultStatusNamePipe implements PipeTransform {

  transform(value: FilterTypes | FilterTypesDisplayNames | string): string {
    switch (value) {
      case FilterTypes.CONTACT_STATE:
      case FilterTypes.EQUIPMENT_STATE:
      case FilterTypes.PROJECT_STATE:
        return FilterTypes.STATUS;
      default:
        return value;
    }
  }
}
