<div class="view-employee-assignment-dialog">
	<div class="dialog-close-icon">
		<button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
			<mat-icon aria-hidden="true">clear</mat-icon>
		</button>
	</div>
	<mat-dialog-content fxLayout="row" fxLayoutAlign="start start">
		<div class="employee-role-icon">
			<fa-icon [icon]="icon"></fa-icon>
		</div>
		<div fxFlex="350px" *ngIf="employeeAssignment">
			<h4>{{ employeeAssignment.employeeRole | equipmentEmployeeRole }}</h4>

			<mat-form-field>
				<mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
				<input matInput
							 readonly
							 value="{{employeeAssignment.employeeFirstName | defaultValue: ''}} {{employeeAssignment?.employeeName}}">
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{'general.personalNumber'|translate|uppercase}}</mat-label>
				<input matInput
							 readonly
							 value="{{employeeAssignment.employeeStaffNumber}}">
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{'general.email'|translate|uppercase}}</mat-label>
				<input matInput
							 readonly
							 value="{{employeeAssignment?.employeeEmail | defaultValue: '-'}}">
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{'general.phone'|translate|uppercase}}</mat-label>
				<input matInput
							 readonly
							 value="{{employeeAssignment?.employeeOfficePhoneNumber | defaultValue: '-'}}">
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{'general.phoneMobile'|translate|uppercase}}</mat-label>
				<input matInput
							 readonly
							 value="{{employeeAssignment?.employeeOfficeMobileNumber | defaultValue: '-'}}">
			</mat-form-field>
		</div>
	</mat-dialog-content>
</div>
