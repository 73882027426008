import { MaintenanceTypeCount } from './maintenance-type-count';
import { ArticleGroupCount } from './article-group-count';
import { CommonFilterView } from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { GroupedEquipmentLocationFilterView } from 'app/shared/contract/filter/filter-view/grouped-equipment-location-filter-view.interface';
import { OrganisationFilterView } from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import { MaintenanceStatusFilterView } from 'app/shared/contract/filter/filter-view/maintenance-status-filter-view.interface';
import { EquipmentStateFilterView } from 'app/shared/contract/filter/filter-view/equipment-state-filter-view.interface';

export interface MaintenanceFilterCollectionView {
  maintenanceTypes: MaintenanceTypeCount[];
  articleGroups: ArticleGroupCount[];
  employees: CommonFilterView[];
  warningLevels: MaintenanceStatusFilterView[];
  equipmentLabels: CommonFilterView[];
  equipmentLocations: GroupedEquipmentLocationFilterView[];
  organisations: OrganisationFilterView[];
  equipmentTypes: EquipmentTypeFilterView[];
  equipmentStatuses: EquipmentStateFilterView[];
  matchAllLabels: CommonFilterView[];
}

export const EMPTY_MAINTENANCE_VIEW_FILTER: MaintenanceFilterCollectionView = {
  maintenanceTypes: [],
  articleGroups: [],
  employees: [],
  warningLevels: [],
  equipmentLabels: [],
  equipmentLocations: [],
  organisations: [],
  equipmentTypes: [],
  equipmentStatuses: []
} as MaintenanceFilterCollectionView;
