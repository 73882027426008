<div class="scan-code-dialog">
  <h2 mat-dialog-title>{{scanCodeData?.objectName ? scanCodeData?.objectName : scanCodeData?.objectModel }}
    <span *ngIf="scanCodeData.objectSerialNumber">- {{ scanCodeData.objectSerialNumber }}</span>
  </h2>

  <div class="dialog-close-icon">
    <button mat-icon-button tabindex="-1" (click)="closeDialog()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <form class="dialog-form"
          [formGroup]="form"
          novalidate fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="15px">
      <mat-form-field>
        <mat-label>{{'general.labels.linkedScanCode'|translate}}</mat-label>
        <input matInput formControlName="scanCode"
               bhRemoveWhitespaces
               [onlySelfControlChanges]="false"
               [errorStateMatcher]="errorMatcher">
        <mat-error *ngIf="scanCodeControl.hasError('required') || scanCodeControl.hasError('empty')">
          {{'shared.validation.requiredField'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCodeControl.hasError('alreadyPairedToEquipment')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToEquipment'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCodeControl.hasError('alreadyPairedToProject')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToProject'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCodeControl.hasError('alreadyPairedToBulkItem')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToBulkItem'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCodeControl.hasError('alreadyPairedToStock')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToStock'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCodeControl.hasError('same')">
          {{'shared.validation.scanCodeAssignment.sameScanCode'|translate}}
        </mat-error>
      </mat-form-field>

      <div class="dialog-buttons-wrapper"
           fxLayout="row"
           fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button mat-raised-button color="accent"
                  (click)="closeDialog()">{{'general.buttons.cancel'|translate}}
          </button>
          <button class="ml"
                  mat-raised-button color="primary"
                  [disabled]="!(formValid | async)"
                  (click)="saveScanCode()">
            {{'general.buttons.save'|translate}}
          </button>
        </div>
        <button mat-raised-button color="warn"
                *ngIf="scanCodeData.scanCode"
                (click)="deleteScanCode()">
          {{'general.buttons.delete'|translate}}
        </button>
      </div>
    </form>
  </div>
</div>
