<bh-step-dialog class="bulk-item-dialog" [ngClass]="{'dialog-bulkitem' : bulkItem}"
                [title]="(bulkItem ? 'modules.disposition.bulkItemAddEdit.editBulkItem' : 'modules.disposition.bulkItemAddEdit.addBulkItem')|translate"
                [icon]="faTrafficCone">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="bulkItemForm">
      <mat-horizontal-stepper labelPosition="bottom" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch"
                              linear [ngClass]="{'no-display-header': bulkItem}">
        <mat-step *ngIf="!bulkItem" [stepControl]="bulkItemForm.get('bulkItemType')">
          <ng-template matStepLabel>{{'modules.disposition.bulkItemAddEdit.chooseType'|translate}}*</ng-template>
          <div class="bulk-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around stretch">
              <bh-card-select class="bulk-item-type-select"
                              [options]="bulkItemTypes"
                              [optionResolver]="bulkItemTypeResolver"
                              formControlName="bulkItemType" ngDefaultControl></bh-card-select>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
              <button class="bottom-button" mat-raised-button matStepperNext type="button"
                      [disabled]="bulkItemForm.get('bulkItemType').invalid">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="bulkItemForm.get('data')" [formGroup]="$any(bulkItemForm.get('data'))">
          <ng-template matStepLabel *ngIf="!bulkItem">{{'general.baseData'|translate}}*</ng-template>
          <div class="bulk-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxFlex="75" fxLayout="row" class="bulk-item-add-dialog-base-data"
                 fxLayoutAlign="space-around stretch">
              <div fxFlex="30" fxLayoutGap="15px">
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(category)"
                                 [label]="'modules.disposition.base.category.s'|translate"
                                 [autoCompleteOptions]="categories | async">
                  </bh-text-input>
                  <fa-icon [icon]="faThList" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'general.intNumber.abbr'|translate}}</mat-label>
                    <input matInput required
                           bhRemoveWhitespaces
                           formControlName="bulkItemNumber"
                           [maxlength]="fieldLimit.SHORT_IDENTIFIER">

                    <mat-error *ngIf="internalNumber.hasError('taken')">
                      {{'shared.validation.alreadyAssigned'|translate:{value: 'general.intNumber.s'|translate} }}
                    </mat-error>
                    <mat-error *ngIf="internalNumber.hasError('required')">
                      {{'shared.validation.required'|translate:{ value:'general.intNumber.abbr'|translate } }}
                    </mat-error>
                    <mat-error *ngIf="internalNumber.hasError('hasWhitespaces')">
                      {{'shared.validation.string.trimSpaces'|translate|translate}}
                    </mat-error>
                    <button mat-icon-button matSuffix (click)="clearInternalNumber()">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                  <fa-icon [icon]="faCalculatorAlt" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(name)"
                                 [label]="'modules.disposition.bulkItemAddEdit.itemName'|translate">
                  </bh-text-input>
                  <fa-icon [icon]="faTag" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemCostCenter)"
                                 [label]="'general.costCenter'|translate"
                                 [autoCompleteOptions]="costCenters | async">
                  </bh-text-input>
                  <fa-icon [icon]="faFileInvoiceDollar" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemCostType)"
                                 [label]="'modules.disposition.base.costType'|translate"
                                 [autoCompleteOptions]="costTypes | async">
                  </bh-text-input>
                  <fa-icon [icon]="faCoins" class="dialog-form-field-icon"></fa-icon>
                </div>
              </div>
              <div fxFlex="30" fxLayoutGap="15px">
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemManufacturer)"
                                 [label]="'general.manufacturer'|translate"
                                 [autoCompleteOptions]="manufacturers | async">
                  </bh-text-input>
                  <fa-icon [icon]="faIndustryAlt" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemManufacturerId)"
                                 [label]="'modules.disposition.base.manufacturerNumber'|translate">
                  </bh-text-input>
                  <fa-icon [icon]="faCalculatorAlt" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemSupplier)"
                                 [label]="'modules.disposition.base.supplier'|translate"
                                 [autoCompleteOptions]="suppliers | async">
                  </bh-text-input>
                  <fa-icon [icon]="faTruck" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemSupplierId)"
                                 [label]="'modules.disposition.base.supplierNumber'|translate">
                  </bh-text-input>
                  <fa-icon [icon]="faCalculatorAlt" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper" *ngIf="bulkItemType === 'CONSUMABLE'">
                  <mat-checkbox formControlName="bulkItemNonReturnable" color="primary" labelPosition="after">
                    {{'modules.disposition.base.consumedAutomatically'|translate}}
                  </mat-checkbox>
                  <fa-icon [icon]="faRecycle" class="dialog-form-field-icon"></fa-icon>
                </div>
              </div>
              <div fxFlex="30">
                <div fxLayout="row" fxLayoutGap="15px" class="field-wrapper">
                  <mat-form-field fxFlex="35">
                    <mat-label>{{'general.units.unit'|translate}}</mat-label>
                    <mat-select formControlName="bulkItemUnit"
                                (selectionChange)="enableWeightField()" disableOptionCentering="true">
                      <mat-option *ngFor="let unit of bulkItemUnits" [value]=unit>
                        {{ unit | dimensionUnitDisplay }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="bulkItemUnit.hasError('required')">
                      {{'shared.validation.required'|translate:{value: 'general.units.unit'|translate} }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="55">
                    <mat-label>{{ 'general.units.weight.weight' | translate }}</mat-label>
                    <input matInput
                           (input)="decimalNumberValidityService.setInputDecimalDigitsNumberValidity($event.target.value, bulkItemWeight)"
                           formControlName="bulkItemWeight"
                           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
                           type="number"
                           min="0">
                    <span matSuffix>{{ 'kg' | dimensionUnitDisplay }}</span>
                    <mat-error *ngIf="bulkItemWeight.hasError('decimalDigitsNumberInvalid')">
                      {{'shared.validation.maxDecimals'|translate:{value: 3} }}
                    </mat-error>
                    <mat-error *ngIf="bulkItemWeight.hasError('min')">
                      {{'shared.validation.number.greaterThanZero'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <div fxFlex="10">
                    <mat-icon class="bulk-item-info-icon"
                              [matTooltip]="'modules.disposition.bulkItemAddEdit.basedOneUnit'|translate">
                      info
                    </mat-icon>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="15px" class="field-wrapper">
                  <mat-form-field fxFlex="35">
                    <mat-label>{{'modules.disposition.bulkItemAddEdit.codeType'|translate}}</mat-label>
                    <mat-select
                      (selectionChange)="changeCodetype($event)"
                      disableOptionCentering="true" #matSelect>
                      <mat-option [value]="'BGL'">{{'general.BGLCode'|translate}}</mat-option>
                      <mat-option [value]="'BAL'">{{'modules.disposition.base.BALCode'|translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field fxFlex="65" *ngIf="!hasBAL">
                    <mat-label>{{'general.BGLCode'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="$any(bulkItemBGLCode)"
                           [onlySelfControlChanges]="false"
                           [maxLength]="30">
                    <mat-error *ngIf="bulkItemBGLCode.invalid">
                      {{'modules.disposition.bulkItemAddEdit.errorBGLCodeIncorrect'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="65" *ngIf="hasBAL">
                    <mat-label>{{'modules.disposition.base.BALCode'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="$any(bulkItemBALCode)"
                           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                  </mat-form-field>
                  <fa-icon [icon]="faMoneyCheckEdit" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <bh-text-input [control]="$any(bulkItemScanCode)"
                                 [label]="'general.scanCode'|translate"
                                 [errorStateMatcher]="errorMatcher"
                                 [customValidationErrors]="scanCodeValidationErrorMap">
                  </bh-text-input>
                  <fa-icon [icon]="faQrcode" class="dialog-form-field-icon"></fa-icon>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper" *ngIf="shortNameNotEmpty()">
                  <mat-form-field>
                    <mat-label>{{'general.shortName'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           readonly
                           [formControl]="$any(bulkItemShortName)"
                           [matTooltip]="'modules.disposition.bulkItemAddEdit.shortNameToolTip'|translate">
                  </mat-form-field>
                  <fa-icon [icon]="faStickyNote" class="dialog-form-field-icon"></fa-icon>
                </div>
              </div>
            </div>
            <div *ngIf="!bulkItem" fxLayout="row" class="label-container">
              <div class="label-field">
                <bh-label-chips (labelAdded)="addLabel($event)"
                                (labelDeleted)="deleteLabel($event)"
                                [customerLabels]="customerLabels | async"
                                [hasAuthority]="hasAuthority(authorities.EQUIPMENT_MANAGE_LABELS)"
                                [labels]="itemLabels | async">
                </bh-label-chips>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" class="field-wrapper">
              <button class="bottom-button" mat-raised-button matStepperPrevious type="button" *ngIf="!bulkItem">
                {{'general.buttons.back'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
              <button class="bottom-button"
                      mat-raised-button
                      matStepperNext
                      type="button"
                      [disabled]="!bulkItemForm.get('data').valid"
                      *ngIf="!bulkItem">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step *ngIf="stocks.length > 0 && !bulkItem">
          <ng-template matStepLabel>{{'modules.disposition.base.inventory.s'|translate}}</ng-template>
          <div class="bulk-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div fxFlex="90" class="stock-amounts-container">

              <mat-table [dataSource]="amountControls" formArrayName="amounts">
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> {{'general.name.s'|translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="cell-container cell-text">{{row.get('name').value}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cost-center">
                  <mat-header-cell *matHeaderCellDef> {{'general.costCenter'|translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="cell-container cell-text">{{row.get('costCenter').value}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <mat-header-cell *matHeaderCellDef class="table-column-250"> {{'modules.disposition.bulkItemAddEdit.numberOfPieces'|translate}}</mat-header-cell>
                  <mat-cell class="amount-cell table-column-250" *matCellDef="let row; let index = index" [formGroupName]="index">
                    <div fxLayout="row" fxLayoutGap="10px">
                      <input matInput type="number" class="amount-input"
                        [ngClass]="{'error': row.get('quantity').hasError('min') ||
                        row.get('quantity').hasError('max') ||
                        row.get('quantity').hasError('decimalDigitsNumberInvalid')}"
                        formControlName="quantity"
                        (input)="decimalNumberValidityService.setInputDecimalDigitsNumberValidity($event.target.value, row.get('quantity'))"
                      >
                      <button mat-raised-button
                        color="primary"
                        [disabled]="row.get('quantity').value >= 10000000"
                        (click)="raiseAmount(row)">
                        <fa-icon [icon]="faPLus"></fa-icon>
                      </button>
                      <button mat-raised-button
                        color="primary"
                        [disabled]="row.get('quantity').value < 1"
                        (click)="decreaseAmount(row)">
                        <fa-icon [icon]="faMinus"></fa-icon>
                      </button>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; let index = index; columns: displayedColumns;"></mat-row>
              </mat-table>

              <mat-error *ngIf="!checkFormForMinAndMaxAmount()">
                {{'modules.disposition.bulkItemAddEdit.errorStockLimit'|translate}}
              </mat-error>
              <mat-error *ngIf="!checkingFormForNumberOfDecimalPlaces()">
                {{'shared.validation.maxDecimals'|translate:{value: 3} }}
              </mat-error>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <button class="bottom-button" mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
              <button class="bottom-button"
                      mat-raised-button
                      matStepperNext
                      [disabled]="!isValid()"
                      type="button">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step *ngIf="!bulkItem">
          <ng-template matStepLabel>{{'general.description'|translate}}</ng-template>
          <div class="bulk-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <textarea matInput [maxlength]="fieldLimit.LONG_TEXT" fxFlex="100" formControlName="description"></textarea>
            <div fxLayout="row" fxLayoutGap="15px">
              <button class="bottom-button" mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>

<ng-template #saveButton>
  <button class="bottom-button"
    mat-raised-button
    (click)="save()"
    [disabled]="!isValid() || saveInProgress"
    color="primary"
    type="submit"
    data-osp-test="button-save">
    {{'general.buttons.save'|translate}}
  </button>
</ng-template>
