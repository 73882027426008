import { Pipe, PipeTransform } from '@angular/core';
import { TransportView } from 'app/modules/transportation/contracts/transport/transport-view.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { TransportItemLocationType } from 'app/modules/transportation/shared/enums/transport-item-location-type.enum';
import { TransportItemType } from 'app/modules/transportation/shared/enums/transport-item-type.enum';
import { TransportItem } from 'app/modules/equipment/shared/transport-items.interface';
import { MapInfoTransportLocationTypeComponent } from '../components/map-info-window/map-info-transport-location-type/map-info-transport-location-type.component';
import { TransportItemLocation } from '../interfaces/transport-item-location.interface';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';


@Pipe({
  name: 'mapMarkersConvertTransportView'
})
export class MapMarkersConvertTransportViewPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: TransportView): MapLocationMarker<TransportItemLocation>[] {
    return value
      ? this.getMapLocationMarkers(this.mergeTransportItemLocations(this.getTransportItemLocations(value)))
      : null;
  }

  private getMapLocationMarkers(items: TransportItemLocation[]): MapLocationMarker<TransportItemLocation>[] {
    return items.filter(Boolean).map(item => ({
      id: item.id,
      location: item.location,
      icon: this.getIconUrl(item),
      infoWindow: {
        content: MapInfoTransportLocationTypeComponent,
        isOpened: true,
      },
      item,
    }));
  }

  private getIconUrl(item: TransportItemLocation): string {
    return this.iconResolver.getIconTransport(item);
  }


  private getTransportItemLocations(transport: TransportView): TransportItemLocation[] {
    return [
      this.getTransportItemLocationStartAddress(transport),
      this.getTransportItemLocationTargetAddress(transport),
      this.getTransportItemLocationRelatedProject(transport)
    ].filter(Boolean);
  }



  private mergeTransportItemLocations(items: TransportItemLocation[]): TransportItemLocation[] {
    const result = items
      .reduce((itemMap, item) => {
          const newItem = !itemMap.has(item.id) ? item : this.mergeTwoTransportItemLocations(itemMap.get(item.id), item);
          itemMap.set(newItem.id, newItem);
          return itemMap;
        }, new Map<string, TransportItemLocation>());
    return [...result.values()];
  }

  private getTransportItemLocationStartAddress({ startItem }: TransportView): TransportItemLocation {
    return this.getTransportItemLocation(startItem, TransportItemLocationType.START);
  }

  private getTransportItemLocationTargetAddress({ targetItem }: TransportView): TransportItemLocation {
    return this.getTransportItemLocation(targetItem, TransportItemLocationType.TARGET);
  }

  private getTransportItemLocationRelatedProject({ projectInfo }: TransportView): TransportItemLocation {
    return projectInfo?.location
      ? {
        id: projectInfo.projectId,
        location: projectInfo.location,
        locationType: [TransportItemLocationType.PROJECT],
        itemType: TransportItemType.PROJECT,
      }
      : null;
  }

  private getTransportItemLocation(item: TransportItem, locationType: TransportItemLocationType): TransportItemLocation {
    return item?.location
      ? {
        id: item.id,
        location: item.location,
        locationType: [locationType],
        itemType: item.transportItemType,
        contactType: item.contactType,
        stockType: item.stockType,
      }
      : null;
  }

  private mergeTwoTransportItemLocations(first: TransportItemLocation, second: TransportItemLocation): TransportItemLocation {
    return {
      ...first,
      locationType: [...first.locationType, ...second.locationType]
    }
  }

}
