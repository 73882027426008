import { UserConfigurationService } from '../../../shared/services/user-configuration.service';
import { Injectable } from '@angular/core';
import { ColumnService } from '../../../shared/column.service';
import { DisplayService } from '../../../shared/display.service';
import { ColumnDefinition } from '../../../shared/column-definition';
import { KeycloakService } from 'app/core/keycloak';
import { EquipmentColumnDefinitionService } from './services/equipment-column-definition.service';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

@Injectable()
export class EquipmentColumnService extends ColumnService {
  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected equipmentColumnDefinitionService: EquipmentColumnDefinitionService,
              protected authService: KeycloakService) {
    super('equipmentOverview',
      equipmentColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.equipmentListConfiguration.columns,
      userConfigurationService.userConfiguration.equipmentListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.equipmentListConfiguration.pageSize },
      displayService,
      authService);
  }

  get columnLimit(): number {
    return undefined;
  }

  public selectColumns(columns: ColumnDefinition[], pageSize: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === 'equipmentImage'
      || column.cdkColumnDef === 'equipmentCustomerSerialNumber'
      || column.cdkColumnDef === 'equipmentSerialNumber'
      || column.cdkColumnDef === 'equipmentModel'
      || column.cdkColumnDef === 'manufacturerName'
      || column.cdkColumnDef === 'equipmentType.category2'
      || column.cdkColumnDef === 'address'
      || column.cdkColumnDef === 'status'
      || column.cdkColumnDef === 'openDetails';
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveEquipmentListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.columnConfigsSubject.getValue(),
      this.pageSizeSubject.getValue(),
    );
  }
}
