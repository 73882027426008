import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'currentThumbnails'
})
export class CurrentThumbnailsPipe implements PipeTransform {
  private minStopIndex = 0;

  public transform(
    images: string[] | SafeResourceUrl[],
    index: number,
    columns: number,
    rows: number,
    moveSize: number,
  ): string[] | SafeResourceUrl[] {
    if (!images) {
      return [];
    }

    let stopIndex = (index + columns + moveSize) * rows;
    if (stopIndex <= this.minStopIndex) {
      stopIndex = this.minStopIndex;
    } else {
      this.minStopIndex = stopIndex;
    }
    return images.slice(0, stopIndex);
  }

}
