import { Component } from '@angular/core';

@Component({
  selector: 'bh-maintenance-rule-assigned-equipments-dialog',
  templateUrl: './maintenance-rule-assigned-equipments-dialog.component.html',
  styleUrls: ['./maintenance-rule-assigned-equipments-dialog.component.scss']
})
export class MaintenanceRuleAssignedEquipmentsDialogComponent {
  equipments: string[] = [];
  ruleName: string;
}
