import { FilterChipsConverterBase } from './base/filter-chips-converter-base.class';
import { FilterValue } from 'app/shared/contract/filter/filter-value';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { Chip } from 'app/shared/contract/chip';


export class FilterChipsConverterGroupedTree extends FilterChipsConverterBase {
  private parents: FilterValue[] = [];
  private parentChildMap: Map<string, FilterValue[]>;

  protected convertFromFilterType(filterType: FilterType): Chip[] {
    this.findRelations(filterType);
    return this.getChips();
  }

  protected getChildFilterValues(parent: FilterValue): FilterValue[] {
    return this.parentChildMap.get(parent.storeName) ?? [];
  }

  private findRelations(filterType: FilterType): void {
    this.parents = [];
    this.parentChildMap = new Map<string, FilterValue[]>();
    filterType.groups.forEach(group => {
      group.filters.forEach(filter => {
        this.addItemToMaps(filter);
      })
    });
  }

  private addItemToMaps(item: FilterValue): void {
    if (item.parentStoreName) {
      this.trackChild(item);
    } else {
      this.trackParent(item);
    }
  }

  private trackChild(item: FilterValue): void {
    this.parentChildMap.set(item.parentStoreName, [
      item,
      ...(this.parentChildMap.get(item.parentStoreName) ?? [])
    ]);
  }

  private trackParent(item: FilterValue): void {
    this.parents.push(item);
    if (!this.parentChildMap.has(item.storeName)) {
      this.parentChildMap.set(item.storeName, []);
    }
  }

  private getChips(): Chip[] {
    return this.parents.reduce((acc, parent) => ([
      ...acc,
      ...this.createChipsByParent(parent)
    ]), []);
  }

  private createChipsByParent(parentFilterValue: FilterValue): Chip[] {
    if (parentFilterValue.value) {
      return [this.createChip(parentFilterValue)];
    } else {
      return this.getChildFilterValues(parentFilterValue)
        .filter(item => item.value)
        .map(item => this.createChip(item))
    }
  }

  protected createChip(filter: FilterValue): Chip {
    return this.createChipFilterValue(filter)
  }

}
