import { Injectable } from '@angular/core';
import { ViewProject } from '../../contract/view-project.interface';
import { ProjectStatus } from '../enums/project-status.enum';
import { ProjectStructureType } from '../enums/project-structure-type';
import { SearchProjectOrProjectGroup } from '../../contract/search-project.interface';
import { DispositionProject } from '../disposition-project';

@Injectable()
export class ProjectCheckerService {

  constructor() { }

  allowFinishProject(project: ViewProject): boolean {
    return project
      && (project.projectStatus === ProjectStatus.ACTIVE.toString()
      || project.projectStatus === ProjectStatus.PAUSED.toString())
      && (!this.isSubProject(project) && !this.isProjectGroup(project));

  }

  isProjectFinished(project: ViewProject | SearchProjectOrProjectGroup | DispositionProject): boolean {
    return project && project.projectStatus === ProjectStatus.FINISHED;
  }

  private isSubProject(project: ViewProject): boolean {
    return project
    && (project.projectStructureType === ProjectStructureType.SUB_PROJECT
    || project.projectStructureType === ProjectStructureType.INITIAL_SUB_PROJECT);
  }

  private isProjectGroup(project: ViewProject): boolean {
      return project
      && project.projectStructureType === ProjectStructureType.PROJECT_GROUP;
    }

}
