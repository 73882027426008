<div [class.error-bg]="validatedItem?.status === RequestStatus.FAILED && item.transferAmount>0" class="item"
     fxLayout="row">
  <div *ngIf="removable"
       class="item-remove row-element"
       fxFlex="60px"
       (click)="remove()"
       [matTooltip]="'modules.disposition.bulkItemTransfer.removeRow'|translate"
       fxLayoutAlign="center center">
    <fa-icon [icon]="faMinusCircle"></fa-icon>
  </div>
  <div fxLayout="column" class="arrow-div" fxLayoutAlign="center center" *ngIf="item.equipmentContainerId">
    <mat-icon _ngcontent-c27 class="arrow-icon" role="img" aria-hidden="true">subdirectory_arrow_right
    </mat-icon>
  </div>
  <div *ngIf="item.transferItemType === 'EQUIPMENT'" class="equipment-icon-container"
       fxFlex
       fxLayout="column"
       fxLayoutAlign="center start">
    <bh-thumbnail *ngIf="item.thumbnailKey"
                  class="equipment-icon"
                  [key]="item.thumbnailKey"
                  [size]="ThumbnailSize.XS">
    </bh-thumbnail>
  </div>
  <div class="row-element item-transfer-source" fxLayout="column" fxLayoutAlign="center stretch">
    <div class="upper-row" fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="item.transferItemSerialNumber else empty" class="text-overflow-ellipsis-nowrap">
        {{item.transferItemSerialNumber}}
      </span>
      <ng-template #empty>
        ( {{'general.empty' | translate}} )
      </ng-template>
    </div>
    <div class="upper-row" fxLayout="row" fxLayoutAlign="start center">
      <span class="text-overflow-ellipsis-nowrap">{{item.name}}</span>
      <fa-icon *ngIf="item.nonReturnable" [icon]="faRecycle"
               [matTooltip]="'modules.disposition.bulkItemTransfer.autoConsumedToProject'|translate">
      </fa-icon>
    </div>
    <span class="lower-row text-overflow-ellipsis-nowrap">{{item.sourceName}}</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start flex-end">
    <ng-container *ngIf="item.currentChargePeriod">
      <div *ngIf="item.currentChargePeriod.status"
           class="item-status {{resolveStatusClass(item.currentChargePeriod.status)}}"
           [matTooltip]="item.currentChargePeriod.comment"
           [matTooltipPosition]="'above'"
      >
        {{ item.currentChargePeriod.status | typeOfUseStatus }}
      </div>
      <div *ngIf="item.currentChargePeriod.statusStartDate" class="item-status-field"
           fxLayout="row">  {{"general.from" | translate}}
        : {{ item.currentChargePeriod.statusStartDate | date:'MM/dd/yyyy HH:mm':'':getCurrentLocale() }}</div>
      <div *ngIf="item.currentChargePeriod.statusEndDate" class="item-status-field"
           fxLayout="row">  {{"general.to" | translate}}
        : {{ item.currentChargePeriod.statusEndDate | date:'MM/dd/yyyy HH:mm':'':getCurrentLocale() }}</div>
      <div class="item-status-field" *ngIf="item.currentChargePeriod.reason" fxLayout="row">
        <fa-icon class="mr-5 custom-icon"
                 [icon]="iconService.resolveReasonIcon(item.currentChargePeriod.reason)"></fa-icon>
        {{item.currentChargePeriod.reason | typeOfUseStatusReason}}
      </div>
    </ng-container>
    <div *ngIf="validatedItem?.transferItem?.transferAmount>0">
      <div [class]="resolveIconClass(validatedItem.status)" fxLayout="row" fxLayoutAlign="start center">
        <fa-icon *ngIf="validatedItem.status === RequestStatus.SUCCESS" [icon]="faCheck" class="mr-5" style="display: initial"></fa-icon>
        <span *ngIf="validatedItem.status === RequestStatus.FAILED" class="error-span">
          <fa-icon [icon]="faTimes" class="mr-5" style="display: initial"></fa-icon>
          {{validatedItem.errorText}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="item.transferItemType === 'BULKITEM'&& !typeOfUseChanging; else toggle" class="row-element-group">
    <div class="item-available row-element" fxLayout="column" fxLayoutAlign="center stretch">
      <span class="upper-row text-overflow-ellipsis-nowrap">{{ sourceLabel }}</span>
      <div>
        <span class="amount-available">{{item.maxAmount | dimensionUnitConverter: item.unit: 3}}</span>
        <span class="lower-row">{{item.unit | dimensionUnitDisplay}}</span>
      </div>
    </div>
    <div class="item-amount row-element" fxLayout="column" fxLayoutAlign="center stretch"
         [ngClass]="{'invalid': !isValidAmount() || itemControl.hasError('decimalDigitsNumberInvalid')}">
      <span class="upper-row text-overflow-ellipsis-nowrap">{{ targetLabel }}</span>
      <span>
        <input
          (input)="decimalNumberValidityService.setInputDecimalDigitsNumberValidity($event.target.value, itemControl)"
          matInput
          id="amount"
          type="number"
          step="any"
          [formControl]="itemControl"
          [min]="item.minAmount | dimensionUnitConverter: item.unit: 3"
          [max]="item.maxAmount | dimensionUnitConverter: item.unit: 3"
          [matTooltip]="'shared.validation.maxDecimals'|translate:{value: 3}"
          [matTooltipDisabled]="!itemControl.hasError('decimalDigitsNumberInvalid')">
      </span>
    </div>
  </div>
  <ng-template #toggle>
    <div *ngIf="!item.equipmentContainerId && hasChildren" (click)="activateContainer.emit()"
         class="container-arrow-div" fxLayout="column">
      <fa-icon class="sub-arrow-icon" [icon]="resolveArrowIcon()"></fa-icon>
    </div>
    <div class="equipment-toggle row-element" fxFlex="60px" fxLayout="column" fxLayoutAlign="center center">
      <mat-slide-toggle color="primary" (change)="this.toggleItem($event.source)"
                        [checked]="this.itemControl.value !== 0">
      </mat-slide-toggle>
    </div>
  </ng-template>
</div>
