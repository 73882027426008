import { OnlineStatusService } from 'app/shared/services/online-status.service';
import { NavigationOptionResolver } from '../../../../shared/components/mobile-navigation-list/navigation-option.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { faQuestion, faTrafficCone } from '@fortawesome/pro-light-svg-icons';
import { DispositionProcessOption } from './disposition-process-option';
import { Injectable } from '@angular/core';

@Injectable()
export class DispositionProcessOptionResolver implements NavigationOptionResolver {
  constructor(private onlineStatusService: OnlineStatusService) {
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case DispositionProcessOption.EQUIPMENTS:
        return faTruckPlow;
      case DispositionProcessOption.BULK_ITEMS:
        return faTrafficCone;
      default:
        return faQuestion;
    }
  }

  public resolveName(option: string): string {
    switch (option) {
      case DispositionProcessOption.EQUIPMENTS:
        return 'Equipments';
      case DispositionProcessOption.BULK_ITEMS:
        return 'Mengenartikel';
      default: return '';
    }
  }

  public resolveUrl(option: string): string {
    // The task BEUT-5494. Temporary solution for offline mode
    if (!this.onlineStatusService.onlineStatus) {
      return '';
    }

    switch (option) {
      case DispositionProcessOption.EQUIPMENTS:
        return '/mobile/equipments/transfer-to-project/scan-code';
      case DispositionProcessOption.BULK_ITEMS:
        return '/mobile/transfer/bulk-items';
      default:
        return '';
    }
  }
}
