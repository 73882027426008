import { ExpandingTreeMapItem } from '../contracts/expanding-tree-map-item.class';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expandingTreeRootItems'
})
export class ExpandingTreeRootItemsPipe implements PipeTransform {
  transform(itemMap: Map<string, ExpandingTreeMapItem>): ExpandingTreeMapItem[] {
    return [...itemMap.values()].filter(item => !item.parentId);
  }
}
