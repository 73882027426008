import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ButtonLabeledBase } from '../base/button-labeled-base.class';

@Component({
  selector: 'bh-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends ButtonLabeledBase {
  @HostBinding('class')
  protected cssClass = 'osp-button osp-button-base';
}
