<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex>
        <div [formGroup]="searchForm" class="search-bar-input" fxLayout="row">
          <input fxFlex matInput formControlName="terms">
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <button mat-button class="search-bar-button-right" fxFlex="50px"
              (click)="navigateToAdd()"
              *ngIf="hasAuthority(authorities.ROLE_CREATE)"
              [matTooltip]="'modules.userrole.roleAddEdit.createRole'|translate">

        <mat-icon>group_add</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="data-table-wrapper" fxFlex>
        <mat-table class="data-table" #table [dataSource]="roles | async">
          <ng-container cdkColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef> {{'general.name.s'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.name}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="description">
            <mat-header-cell *cdkHeaderCellDef> {{'general.description'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.description}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="lastChanged">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.userrole.roleList.lastModified'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row" matTooltip="{{'general.from'|translate|lowercase}}: {{row.lastChangedBy}}"> {{row.lastChanged | date:"dd.MM.yyyy"}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="realmType"
                        *ngIf="hasAuthority(authorities.SUPERADMIN_CREATE)">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.userrole.roleList.realmType'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{ getRealmTypeName(row.realmType) }} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="cloneRole"
                        *ngIf="hasAuthority(authorities.ROLE_CREATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="navigateToClone(row.roleId)"
                      [matTooltip]="'modules.userrole.roleList.cloneRole'|translate">
                <fa-icon [icon]="faCopy"></fa-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="editRole"
                        *ngIf="hasAuthority(authorities.ROLE_UPDATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="navigateToEdit(row.roleId)"
                      *ngIf="!isInPrivilegedRole(row.name)"
                      [matTooltip]="'modules.userrole.roleList.editRole'|translate">
                <mat-icon>create</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="deleteRole"
                        *ngIf="hasAuthority(authorities.ROLE_DELETE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div *ngIf="!isInPrivilegedRole(row.name)"
                   [matTooltip]="resolveDeleteTooltip(row.deletable)|translate"
                   [matTooltipDisabled]="false">

                <button mat-button (click)="initiateDeleteRole(row.roleId)" [disabled]="!row.deletable">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

