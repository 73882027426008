<div class="employee-assignments" fxLayout="row" fxLayoutAlign="start stretch">

  <div class="employee-assignment" fxFlex="50" fxLayout="row"
       *ngFor="let employeeAssignment of employeeAssignments | responsibleSort; let index=index; let even=even"
       [ngClass]="{ 'even': even }">
    <mat-card fxFlexFill>
      <mat-card-header (click)="openViewAssignmentDialog(employeeAssignment)" fxLayoutGap="5px">
        <div mat-card-avatar>
          <fa-icon [icon]="getRoleIcon(employeeAssignment.assigneeType)"></fa-icon>
        </div>
        <div class="custom-title">
          <div class="h-24" fxLayoutAlign="space-between center">
            <mat-card-title fxFlex="1 1 80%">{{ employeeAssignment.assigneeType | assigneeType }}</mat-card-title>
            <mat-card-title fxFlex="1 1 20%">
              <fa-icon [ngClass]="{'active' : employeeAssignment.isAcceptanceUser}"
                *ngIf="hasAssignmentOrTransferRequestWorkflow"
                class="acceptance-icon" [icon]="faBadgeCheck"
                [matTooltip]="'modules.disposition.projectAssignees.transferRequestAcceptance' | translate"></fa-icon>
            </mat-card-title>
          </div>
          <mat-card-subtitle>{{ employeeAssignment.employeeFirstName }} {{ employeeAssignment.employeeName }}
          </mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="employee-assignment-info" (click)="openViewAssignmentDialog(employeeAssignment)">
          <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon matSuffix>email</mat-icon>
            <span>{{ employeeAssignment.employeeEmail | defaultValue: '-' }}</span>
          </div>
          <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon matSuffix>call</mat-icon>
            <span>{{ getPhoneNumber(employeeAssignment) }}</span>
          </div>
        </div>
        <div class="employee-assignment-actions" fxLayoutAlign="end center" *ngIf="allowAddEdit">
          <span *ngIf="hasAssignmentOrTransferRequestWorkflow" (click)="openUpdateAssignmentDialog(employeeAssignment)">
            <mat-icon matSuffix [matTooltip]="'general.buttons.change'|translate">mode_edit</mat-icon>
          </span>
          <span (click)="openDeleteAssignmentDialog(employeeAssignment)">
            <mat-icon matSuffix [matTooltip]="'general.buttons.remove'|translate">delete</mat-icon>
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="employee-assignment-add" fxFlex="50" fxLayout="row" *ngIf="allowAddEdit"
       [ngClass]="{ 'even': employeeAssignments && employeeAssignments.length % 2 === 0 }"
       [matTooltip]="'modules.equipment.equipmentDetailGeneral.noEmployeesAvailable'|translate"
       [matTooltipDisabled]="employeesAvailable">
    <mat-card fxFlex (click)="openAddAssignmentDialog()">
      <mat-card-content fxFlex fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>add_circle_outline</mat-icon>
      </mat-card-content>
    </mat-card>
  </div>

</div>
