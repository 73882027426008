import { FilterGroup } from 'app/shared/contract/filter/filter-group';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedSearchFilterValues',
  pure: false
})
export class SelectedSearchFilterValuesPipe implements PipeTransform {
  public transform(group: FilterGroup): string[] {
  return group
      && group.filters
          .filter(({ value }) => value)
          .map(({ storeName }) => storeName)
      || [];
  }
}
