import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportsDataSource } from '../../shared/services/reports.datasource';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteReportJobCommand } from '../../contract/delete-report-job-command';
import { ViewReportDetailsJob } from '../../contract/view-report-details-job.interface';
import {dialogResults} from '../../../../shared/enums/dialogResults.enum';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-report-jobs',
  templateUrl: './report-jobs.component.html',
  styleUrls: ['./report-jobs.component.scss']
})
export class ReportJobsComponent implements OnInit, OnDestroy {

  reportJobs: ViewReportDetailsJob[];

  constructor(private router: Router,
              private reportStore: ReportsDataSource,
              private dialog: MatDialog,
              private languageService: LanguageService) {
  }

  ngOnInit() {
    this.getReportJobs();
  }

  ngOnDestroy() {

  }

  getReportJobs() {
    this.reportStore.getReportJobs();

    this.reportStore.reportJobs.subscribe(res => {
      this.reportJobs = res;
    });
  }

  navigateToAddReportJob() {
    this.router.navigate(['/reports/jobs/add']);
  }

  deleteReportJob($event, reportJobId: string) {
    $event.stopPropagation();

    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.languageService.getInstant('modules.reports.reportJobs.deleteAutoReportMessage');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd: DeleteReportJobCommand = new DeleteReportJobCommand();
        cmd.reportJobId = reportJobId;

        this.reportStore.deleteReportJob(cmd).subscribe(
          () => {},
          () => {}
        )
      }
    });
  }

  editReportJob($event, reportJobId: string) {
    $event.stopPropagation();

    this.router.navigate([`/reports/jobs/edit/${reportJobId}`])
  }

  joinNames(names: string[]) {
    return names && names.join(', ');
  }

}
