<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
  <div fxFlex="0 0 400px">
    <mat-form-field>
      <input type="text"
             placeholder="{{'modules.userrole.userSettings.timezone'|translate}}"
             matInput
             [attr.aria-label]="'modules.userrole.userSettings.timezone'|translate"
             [formControl]="timezoneControl"
             [matAutocomplete]="auto"
             (focusout)="focusOut()"
             (focusin)="focusIn()">
      <mat-autocomplete requireSelection #auto="matAutocomplete">
        <mat-option *ngFor="let tz of timezoneOptions | async"
                    [value]="tz.id"
                    (onSelectionChange)="timezoneSelected($event)">
          {{ tz.text }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

</div>
