<div *ngIf="task" fxFlex fxLayout="column" class="wrapper-container">
  <div class="container-fields">
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.maintenanceTaskType' | translate}}</mat-label>
      <input matInput
        readonly
        [value]="task.category | maintenanceCategory | defaultValue : emptyValue">
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'general.equipm.s' | translate}}</mat-label>
      <input matInput
             readonly
             [value]="(task.equipmentName ? task.equipmentName : task.equipmentModel) | defaultValue : emptyValue"
             data-osp-test="input-maintenance-equipment-name">
      <span matSuffix>
          <mat-icon class="icon-navigation"
                    (click)="navigateToEquipment(task.equipmentId)"
                    [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.equipm.s'|translate }">
            open_in_new
          </mat-icon>
      </span>
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.due' | translate}}</mat-label>
      <input matInput
        readonly
        [value]="task.dueDateInfo | dueDateRemainingValue | defaultValue : emptyValue">
    </mat-form-field>
  </div>
</div>
