import { AuthType } from '../auth-type.enum';

export class AempV2ConnectionInformation {

  profileKey: string;
  url: string;
  authType: AuthType;
  basicAuthEncoding: string;
  authenticationUrl: string;
  clientId: string;
  clientSecret: string;
  username: string;
  password: string;
  apiKey: string;
}
