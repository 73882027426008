import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AddStockResponsibleEmployeeCommand } from '../../contract/add-stock-responsible-employee-command';
import { ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand } from '../../contract/change-stock-responsible-employee-acceptance-flag.command';
import { RemoveStockResponsibleEmployeeCommand } from '../../contract/remove-stock-responsible-employee-command';
import { ViewStockEmployeeAssignment } from '../../contract/view-stock-employee-assignment.interface';

@Injectable({
  providedIn: 'root'
})

export class StockEmployeeService {
  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/stocks';
  private responsibleEmployeeUrl = this.url + '/responsible-employee'

  constructor(private http: HttpClient) { }

  public getEmployeeAssignments(stockId: string): Observable<ViewStockEmployeeAssignment[]> {
    return this.http.get<ViewStockEmployeeAssignment[]>(this.url + '/' + stockId + '/responsible-employees');
  }

  public addStockEmployeeAssignments(command: AddStockResponsibleEmployeeCommand): Observable<string> {
    return this.http.post(this.responsibleEmployeeUrl + '/add-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public removeStockEmployeeAssignments(command: RemoveStockResponsibleEmployeeCommand): Observable<string> {
    return this.http.post(this.responsibleEmployeeUrl + '/remove-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public changeStockRespnsibleEmployeeAcceptanceUser(
    command: ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand ): Observable<string> {
    return this.http.post(this.responsibleEmployeeUrl + '/change-acceptance-user-flag', JSON.stringify(command), {responseType: 'text'});
  }

}
