import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ReportJobInterval } from '../../contract/report-job-interval.enum';

@Pipe({
  name: 'reportJobIntervalDisplay'
})
export class ReportJobIntervalPipe implements PipeTransform {
  private translationKeys = {
    [ReportJobInterval.DAILY]: 'shared.pipes.reportJobIntervalDisplay.daily',
    [ReportJobInterval.MONTHLY]: 'shared.pipes.reportJobIntervalDisplay.monthly',
    [ReportJobInterval.WEEKLY_MONDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklyMo',
    [ReportJobInterval.WEEKLY_TUESDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklyTu',
    [ReportJobInterval.WEEKLY_WEDNESDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklyWe',
    [ReportJobInterval.WEEKLY_THURSDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklyTh',
    [ReportJobInterval.WEEKLY_FRIDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklyFr',
    [ReportJobInterval.WEEKLY_SATURDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklySa',
    [ReportJobInterval.WEEKLY_SUNDAY]: 'shared.pipes.reportJobIntervalDisplay.weeklySu'
  };

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    return this.translate(this.translationKeys[value] || 'shared.pipes.reportJobIntervalDisplay.unknown')
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
