<mat-card class="view-select-card">
  <mat-card-content>
    <button mat-button *ngFor="let option of options"
            matTooltip="{{ resolveOptionName(option) }}"
            [ngClass]="{'view-active': isSelected(option), 'change-list-view-button': true}"
            [matTooltipPosition]="'right'"
            fxLayoutAlign="center center"
            (click)="navigateToView(option)"
            [attr.data-osp-test]="'nav-button-'+option">
      <ng-container *ngIf="resolveOptionIcon(option) as iconPayload">
        <ng-container [ngSwitch]="iconPayload.type">
          <fa-icon *ngSwitchCase="iconType.FA" [icon]="iconPayload.icon"></fa-icon>
          <mat-icon *ngSwitchCase="iconType.MAT">{{iconPayload.icon}}</mat-icon>
        </ng-container>
      </ng-container>
    </button>
  </mat-card-content>
</mat-card>
