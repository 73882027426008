import {Component, Inject} from '@angular/core';
import {TelematicUnitCustomerEquipmentInfo} from '../shared/telematic-unit-customer-equipment-info';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'bh-equipment-info-dialog',
  templateUrl: './equipment-info-dialog.component.html',
  styleUrls: ['./equipment-info-dialog.component.scss']
})
export class EquipmentInfoDialogComponent {

  title: string;
  telematicUnitCustomerEquipmentInfo: TelematicUnitCustomerEquipmentInfo;

  constructor(private dialogRef: MatDialogRef<TelematicUnitCustomerEquipmentInfo>, @Inject(MAT_DIALOG_DATA) data: {
    title: string,
    telematicsUnitCustomerEquipmentInfo: TelematicUnitCustomerEquipmentInfo
  }) {
    this.title = data.title;
    this.telematicUnitCustomerEquipmentInfo = data.telematicsUnitCustomerEquipmentInfo;
  }
}
