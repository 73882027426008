import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TimezoneService } from '../../../../../shared/services/timezone.service';
import { Timezone } from '../../../../../shared/contract/timezone';
import { first, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'bh-timezone-switcher',
  templateUrl: './timezone-switcher.component.html',
  styleUrls: ['./timezone-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class TimezoneSwitcherComponent implements OnInit, OnDestroy {
  public timezoneControl = new UntypedFormControl();
  public timezoneOptions: Observable<Timezone[]>;
  protected selectedTimezone = this.timezoneService.getUserTimezone();
  protected selectedTimezoneEntry: Timezone;

  public tC = new FormControl();

  constructor(private timezoneService: TimezoneService) {
  }

  public ngOnInit(): void {
    this.selectedTimezone = this.timezoneService.getUserTimezone();
    this.loadTimezones('');
    this.setValueFromTimezoneOptionsOnce();
    this.configControl();
    this.tC.disable();

  }

  public ngOnDestroy(): void {
  }

  public switchTimezone(timezone: string = this.selectedTimezone): void {
    this.selectedTimezone = timezone;
    this.timezoneService.setUserTimezone(timezone).subscribe();
    this.setValueFromTimezoneOptionsOnce();
  }

  private setValueFromTimezoneOptionsOnce() {
    this.timezoneOptions.pipe(first())
    .subscribe(timezones => timezones.forEach(tz => {
      if (tz.id === this.selectedTimezone) {
        this.timezoneControl.setValue(tz.text, {emitEvent: false});
      }
    }));
  }

  private loadTimezones(filterTerm: string): void {
    this.timezoneOptions = this.timezoneService.getTimezones(filterTerm);
  }

  private configControl(): void {
    this.timezoneControl.valueChanges.pipe(
      startWith(this.selectedTimezone),
      map(value => {
        this.loadTimezones(value || '');
        return value;
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  protected timezoneSelected($event: MatOptionSelectionChange<string>) {
    console.log('Timezone selected: ' + $event.source.value)
    this.switchTimezone($event.source.value);
  }


  protected focusOut() {
    setTimeout(() => {
      this.loadTimezones('');
      this.setValueFromTimezoneOptionsOnce();
    }, 300);
  }

  focusIn() {
    this.timezoneControl.reset();
  }
}
