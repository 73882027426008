import { Injectable } from '@angular/core';
@Injectable()
export class ChatUrlParser {

  private outletStartPattern = '(sidenav:';
  private outletEndPattern = ')';

  public parseChatRoute(url: string): string {
    let chatRoute = url;
    const outletIndex = url.indexOf(this.outletStartPattern);
    if (outletIndex > 0) {
      chatRoute = url.substring(outletIndex + this.outletStartPattern.length);
      chatRoute = chatRoute.replace(this.outletEndPattern, '');
    }

    return chatRoute;
  }

  public spliceChatRoute(url: string): string {
    let chatRoute = url;
    const outletIndex = url.indexOf(this.outletStartPattern);
    if (outletIndex > 0) {
      chatRoute = url.substring(0, outletIndex);
    }

    return chatRoute;
  }

  public isChatRoute(url: string): boolean {
    return url.includes(this.outletStartPattern);
  }

}
