import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ButtonLabeledBase } from '../base/button-labeled-base.class';


@Component({
  selector: 'bh-button-flat',
  templateUrl: './button-flat.component.html',
  styleUrls: ['./button-flat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonFlatComponent extends ButtonLabeledBase {
  @HostBinding('class')
  protected cssClass = 'osp-button osp-button-flat';
}
