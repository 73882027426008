<div class="label-field">
  <bh-label-chips (labelAdded)="addLabel($event)"
                  (labelDeleted)="deleteLabel($event)"
                  [customerLabels]="customerLabels | async"
                  [hasAuthority]="hasAuthority(authorities.EMPLOYEE_MANAGE_LABELS)"
                  [labels]="employeeLabels | async">
  </bh-label-chips>
</div>

<div class="employee-details" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">

  <div class="employee-view-detail__content" fxFlex="65" fxLayout="row" fxLayoutAlign="space-between stretch">

    <div class="inputs-line" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.personalNumber'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeStaffNumber}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.firstName'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeFirstName}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.lastName'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeName}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.org.s'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.organisationName}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.email'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeEmail}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.phone'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeOfficePhoneNumber}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.phoneMobile'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeOfficeMobileNumber}}">
      </mat-form-field>
    </div>

    <div fxLayout="column" class="employee-view-detail__content__extra-info">
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="employee?.isTeamLeader">
        <fa-icon class="star-icon green-title" [icon]="faStar"></fa-icon>
        <bh-subtitle class="green-element"
                    [text]="'modules.disposition.employeeViewDetail.employeeTeamLeader'|translate|uppercase">
        </bh-subtitle>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" *ngIf="employee?.assignedUserId">
        <div fxLayout="row" fxFlex="0 0 18px">
          <fa-icon [icon]="faLinkIcon" fxLayout="row" class="linked-user__icon"></fa-icon>
        </div>
        <span fxFlex="0 0 auto" class="linked-user__title">{{'general.userOSP'|translate}}:</span>
        <div fxLayout="column" class="linked-user__field-container">
          <div class="linked-user__field">{{employeeUser?.name}}</div>
          <div class="linked-user__field">{{employeeUser?.email}}</div>
        </div>
      </div>
    </div>
  </div>

  <div fxFlex="35" fxLayout="column">
    <bh-gallery-detail-view
      *ngIf="imageKeys?.length > 0, else icon"
      [keys]="imageKeys">
    </bh-gallery-detail-view>
    <ng-template #icon>
      <div class="icon-no-images">
        <fa-icon [icon]="faUserIcon"></fa-icon>
      </div>
    </ng-template>
  </div>

</div>

<bh-employee-view-general-associations></bh-employee-view-general-associations>
