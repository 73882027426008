import { FilterGroup } from './filter-group';
import { FilterTypes, FilterTypesDisplayNames } from './filter-types.enum';

export enum RenderStrategy {
  /* Default */
  LIST = 0,
  GROUPED_TREE = 1,
  RELATIONS_TREE = 2,
}

export class FilterType {
    typeName: FilterTypes;
    displayNameType?: FilterTypesDisplayNames;
    typeTotal: string;
    groups: FilterGroup[];
    display: boolean;
    renderStrategy?: RenderStrategy = RenderStrategy.LIST;
}
