import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EquipmentAuditResponse } from '../contract/equipment-history.interface';

@Injectable()
export class EquipmentAuditService {

  private url = environment.APP_EQUIPMENT_AUDIT_SERVICE_BASE_URL + '/api/v1/audit/equipments/';

  constructor(private http: HttpClient) {
  }

  getAuditData(equipmentId: string): Observable<EquipmentAuditResponse> {
    let httpParams = new HttpParams();
    return this.http.get<EquipmentAuditResponse>(this.url + equipmentId, {params: httpParams});
  }
}
