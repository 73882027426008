import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Directive()
export abstract class BaseProjectViewMapComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  private componentActive = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    this.projectStore.currentViewTab = 'map';
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  get project(): ViewProject {
    return this.projectStore.project();
  }

}
