import { environment } from 'environments/environment';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, finalize } from 'rxjs/operators';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { Month } from '../../../../shared/contract/month';
import { ViewManufacturer } from '../../contract/view-manufacturer.interface';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { EquipmentTypesService } from '../../shared/equipmenttypes.service';
import * as moment from 'moment';
import { SerialNumberInUseValidator } from '../../../../shared/custom-validators/serial-number-in-use.validator';
import {
  ManufacturerSerialNumberInUseValidator
} from '../../../../shared/custom-validators/manufacturer-serial-number-in-use.validator';
import { CreateEquipmentCommand } from '../../contract/create-equipment-command';
import { naturalNumberValidator } from '../../../../shared/custom-validators/natural-numbers.validator';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import {
  EquipmentSubTypeFilterView,
  EquipmentTypeFilterView
} from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { EquipmentFieldStore } from '../../shared/equipment-field.store';
import { EquipmentsService } from '../../shared/equipments.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatesService } from '../../../../shared/services/dates.service';
import { generateUniqueNumber, Utils } from '../../../../shared/utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdateEquipmentCommand } from '../../contract/update-equipment-command';
import * as _ from 'lodash';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  ManufacturerCreateDialogComponent
} from '../../shared/manufacturer-create-dialog/manufacturer-create-dialog.component';
import { RouterHistory } from '../../../../shared/router-history';
import { AssignEquipmentToOrganisationCommand } from '../../contract/assign-equipment-to-organisation-command';
import { LanguageService } from 'app/shared/services/language.service';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { ViewStock } from '../../shared/view-stock.interface';
import { matomoCategories } from '../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';
import { DateValidator } from 'app/shared/custom-validators/date.validator';
import { OrganisationInfo } from '../../contract/organisation-info.interface';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-add-edit',
  templateUrl: 'equipment-add-edit.component.html',
  styleUrls: ['equipment-add-edit.component.scss']
})
export class EquipmentAddEditComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inputDeliveryDateRef', { static: true }) inputDeliveryDateRef: ElementRef;
  @ViewChild('inputRegistrationDateRef', { static: true }) inputRegistrationDateRef: ElementRef;

  public readonly faTruckPlow: IconDefinition = faTruckPlow;
  public readonly faPlus: IconDefinition = faPlus;
  public readonly minDate = moment('1900-01-02').toDate();
  public readonly maxDate = moment('9999-12-30').toDate();

  public readonly fieldLimit = FieldLimit;

  public equipment: ViewEquipment;

  public equipmentForm: UntypedFormGroup;
  public organisationFilter: UntypedFormControl = new UntypedFormControl();
  public manufacturerFilter: UntypedFormControl = new UntypedFormControl();
  public stockFilter: UntypedFormControl = new UntypedFormControl();

  public organisations: Observable<OrganisationInfo[]> = this.equipmentFieldStore.filteredOrganisations;
  public manufacturers: Observable<ViewManufacturer[]> = this.equipmentFieldStore.filteredManufacturers;
  public stocks: Observable<ViewStock[]> = this.equipmentFieldStore.filteredStocks;
  public costCenters: Observable<string[]> = this.equipmentFieldStore.filteredCostCenters;
  public bglCodes: Observable<string[]> = this.equipmentFieldStore.filteredBglCodes;
  public customerLabels: Observable<string[]> = this.equipmentStore.availableCustomerLabels;

  public months: Month[];
  public years: string[];
  public mask: Array<string | RegExp> = [/[A-Z]/, '.', /[0-9]/, '.', /[0-9]/, /[0-9]/];
  public equipmentTypeSearchControl: UntypedFormControl = new UntypedFormControl();
  public filteredEquipmentTypes: EquipmentTypeFilterView[] = [];
  public saveInProgress = false;

  private equipmentLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private allEquipmentTypes: EquipmentTypeFilterView[] = [];

  private readonly DATA_CONTROL = 'data';
  private readonly EQUIPMENT_SERIAL_NUMBER_CONTROL = 'equipmentSerialNumber';
  private readonly EQUIPMENT_NAME_CONTROL = 'equipmentName';
  private readonly EQUIPMENT_PRODUCT_CLASS_CONTROL = 'equipmentProductClass';
  private readonly EQUIPMENT_LICENSE_PLATE_CONTROL = 'equipmentLicensePlate';
  private readonly EQUIPMENT_COST_CENTER_CONTROL = 'equipmentCostCenter';
  private readonly EQUIPMENT_CUSTOMER_SERIAL_NUMBER_CONTROL = 'equipmentCustomerSerialNumber';
  private readonly EQUIPMENT_CONSTRUCTION_MONTH_CONTROL = 'equipmentConstructionMonth';
  private readonly EQUIPMENT_CONSTRUCTION_YEAR_CONTROL = 'equipmentConstructionYear';
  private readonly EQUIPMENT_MODEL_CONTROL = 'equipmentModel';
  private readonly EQUIPMENT_GUARANTEE_VALUE_CONTROL = 'equipmentGuaranteeValue';
  private readonly EQUIPMENT_DELIVERY_DATE_CONTROL = 'equipmentDeliveryDate';
  private readonly EQUIPMENT_REGISTRATION_DATE_CONTROL = 'equipmentRegistrationDate';
  private readonly EQUIPMENT_GUARANTEE_OPERATING_HOURS_LIMIT_CONTROL = 'equipmentGuaranteeOperatingHoursLimit';
  private readonly EQUIPMENT_BGL_CODE_CONTROL = 'equipmentBGLCode';
  private readonly MANUFACTURER_ID_CONTROL = 'manufacturerId';
  private readonly ORGANISATION_ID_CONTROL = 'organisationId';
  private readonly STOCK_ID_CONTROL = 'stockId';


  constructor(private formBuilder: UntypedFormBuilder,
              private equipmentStore: EquipmentsDataSource,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private equipmentTypesService: EquipmentTypesService,
              private equipmentFieldStore: EquipmentFieldStore,
              private equipmentsService: EquipmentsService,
              private dialogRef: MatDialogRef<EquipmentAddEditComponent>,
              private dialog: MatDialog,
              private langService: LanguageService,
              private matomoTracker: MatomoTracker,
              private cdRef: ChangeDetectorRef,
              private translatableStringResolver: TranslatableStringPipe) {
    super(authService, router, route, routerHistory);
  }

  public get manufacturerSerialNumber(): AbstractControl | null {
    // a.k.a. equipmentSerialNumber
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_SERIAL_NUMBER_CONTROL);
  }

  public get manufacturer(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.MANUFACTURER_ID_CONTROL);
  }

  public get equipmentName(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_NAME_CONTROL);
  }

  public get equipmentProductClass(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_PRODUCT_CLASS_CONTROL);
  }

  public get equipmentLicensePlate(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_LICENSE_PLATE_CONTROL);
  }

  public get equipmentCostCenter(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_COST_CENTER_CONTROL);
  }

  public get customerSerialNumber(): AbstractControl | null {
    // a.k.a. equipmentCustomerSerialNumber
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_CUSTOMER_SERIAL_NUMBER_CONTROL);
  }

  public get equipmentConstructionMonth(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_CONSTRUCTION_MONTH_CONTROL);
  }

  public get equipmentConstructionYear(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_CONSTRUCTION_YEAR_CONTROL);
  }

  public get equipmentModel(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_MODEL_CONTROL);
  }

  public get equipmentGuaranteeValue(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_GUARANTEE_VALUE_CONTROL);
  }

  public get equipmentDeliveryDate(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_DELIVERY_DATE_CONTROL);
  }

  public get equipmentRegistrationDate(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_REGISTRATION_DATE_CONTROL);
  }

  public get equipmentBGLCode(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_BGL_CODE_CONTROL);
  }

  public get organisation(): AbstractControl | null {
    return this.equipmentForm.get(this.DATA_CONTROL).get(this.ORGANISATION_ID_CONTROL);
  }

  public get selectedStock(): Observable<string> {
    return this.equipmentForm.get(this.STOCK_ID_CONTROL).valueChanges;
  }

  public ngOnInit(): void {
    this.equipmentFieldStore.loadAllFields();
    this.getEquipmentTypes();
    this.subscribeToTypeSearch();
    this.buildForm();
    this.setMonths(this.langService.getCurrentLanguage());
    this.setYears();
    this.setSerialNumberInUseValidator();
    this.setManufacturerSerialNumberInUseValidator();
    this.subscribeToFormChanges();
  }

  public ngAfterViewInit(): void {
    if (this.equipment) {
      this.fillForm();
    }
  }

  public ngOnDestroy(): void {
  }

  public equipmentGuaranteeValueChanged(): void {
    if (this.equipmentGuaranteeValue.value) {
      this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_GUARANTEE_OPERATING_HOURS_LIMIT_CONTROL).enable();
    } else {
      this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_GUARANTEE_OPERATING_HOURS_LIMIT_CONTROL).disable();
    }
  }

  selectStock(stockId: string): void {
    this.equipmentForm.patchValue({ stockId: stockId });
  }

  public selectType(equipmentTypeId: string): void {
    this.equipmentForm.patchValue({ 'equipmentTypeId': equipmentTypeId });
  }

  public openManufacturerCreation(): void {
    this.dialog.open(ManufacturerCreateDialogComponent).afterClosed().subscribe(id => {
      if (id) {
        this.equipmentFieldStore.loadManufacturers();
        this.manufacturer.patchValue(id);
      }
    });
  }

  public setMonths(lang: string): void {
    moment.locale(lang);
    this.months = moment.months().map(month => {
      return {
        name: month,
        value: moment().month(month).format('MM')
      };
    });
    this.months.unshift({ name: this.langService.getInstant('general.noInformation'), value: '00' });
  }

  public setYears(): void {
    this.years = [];
    for (let i = (new Date().getFullYear() + 1); i >= 1970; i--) {
      this.years.push('' + i);
    }
  }

  public save(): void {
    this.matomoTracker.trackEvent(matomoCategories.ASSET_LIST, matomoActions.ADD_ASSET_DIALOG_SAVE);
    if (this.isValid()) {
      if (this.equipment) {
        this.update();
      } else {
        this.createEquipment();
      }
    }
  }

  public resetForm(): void {
    this.equipmentForm.reset();
  }

  public addLabel(label: string): void {
    const newLabels = [...this.equipmentLabels.value];
    newLabels.push(label);
    this.equipmentLabels.next(newLabels);
    this.filterCustomerLabels(this.customerLabels);
  }

  public deleteLabel(label: string): void {
    const newLabels = [...this.equipmentLabels.value];
    newLabels.splice(newLabels.indexOf(label), 1);
    this.equipmentLabels.next(newLabels);
    this.filterCustomerLabels(this.equipmentStore.filteredCustomerLabels);
  }

  public constructionMonthSelected(): void {
    this.equipmentConstructionYear.markAsTouched();
  }

  public constructionYearSelected(): void {
    if (!this.equipmentConstructionMonth.value) {
      this.equipmentConstructionMonth.patchValue('00');
    }
  }

  public removeBuildDate(): void {
    this.equipmentForm.get(this.DATA_CONTROL).patchValue({
      equipmentConstructionMonth: null,
      equipmentConstructionYear: null
    });
  }

  public clearCustomerSerialNumber(): void {
    this.equipmentForm.get(this.DATA_CONTROL).patchValue({
      equipmentCustomerSerialNumber: null
    });
  }

  public isValid(): boolean {
    return this.equipmentForm.valid;
  }

  assignOrganisation(equipmentId: string, organisationId: string): void {
    let cmd: AssignEquipmentToOrganisationCommand = new AssignEquipmentToOrganisationCommand();
    cmd.equipmentId = equipmentId;
    cmd.organisationId = organisationId;

    this.equipmentStore.assignEquipmentToOrganisation(cmd).subscribe(
      res => {
        this.dialogRef.close('save');
      }, error => {
        console.log('error on assign equipment to organisation: ', error);
        this.dialogRef.close('save');
      });
  }

  private createEquipment(): void {
    const command = new CreateEquipmentCommand();
    const formValue = this.equipmentForm.getRawValue();
    command.equipmentTypeId = formValue.equipmentTypeId;
    command.equipmentCustomerSerialNumber = formValue.data.equipmentCustomerSerialNumber;
    command.equipmentName = formValue.data.equipmentName;
    command.organisationId = formValue.data.organisationId;
    command.manufacturerId = formValue.data.manufacturerId;
    command.equipmentSerialNumber = Utils.isNotBlank(formValue.data.equipmentSerialNumber) ?
      formValue.data.equipmentSerialNumber :
      null;
    command.equipmentModel = formValue.data.equipmentModel;
    command.equipmentCostCenter = formValue.data.equipmentCostCenter;
    command.equipmentDeliveryDate = DatesService.ospDateTimeAtStartOfDay(formValue.data.equipmentDeliveryDate);
    command.equipmentRegistrationDate = DatesService.ospDateTimeAtStartOfDay(formValue.data.equipmentRegistrationDate);
    if (formValue.data.equipmentGuaranteeValue) {
      command.equipmentGuarantee = moment.duration(formValue.data.equipmentGuaranteeValue,
        formValue.data.equipmentGuaranteeUnit).toISOString();
      command.equipmentGuaranteeOperatingHoursLimit = formValue.data.equipmentGuaranteeOperatingHoursLimit;
    }
    command.equipmentProductClass = formValue.data.equipmentProductClass;
    command.equipmentLicensePlate = formValue.data.equipmentLicensePlate;
    command.equipmentBGLCode = formValue.data.equipmentBGLCode;
    if (formValue.data.equipmentConstructionMonth && formValue.data.equipmentConstructionYear) {
      command.equipmentConstructionYear = formValue.data.equipmentConstructionYear +
        '-' + formValue.data.equipmentConstructionMonth;
    }
    command.labels = this.equipmentLabels.value;
    command.stockId = formValue.stockId;

    this.saveInProgress = true;
    this.equipmentsService.addEquipment(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      finalize(() => this.saveInProgress = false))
    .subscribe(equipmentId => {
      this.router.navigate(['assets/equipment/list', equipmentId]);
      this.dialogRef.close('save');
    });
  }

  private update(): void {
    const formValue = this.equipmentForm.getRawValue();
    const currentEquipment = this.equipment;
    currentEquipment.equipmentSerialNumber = Utils.isNotBlank(formValue.data.equipmentSerialNumber) ?
      formValue.data.equipmentSerialNumber :
      null;
    currentEquipment.equipmentCustomerSerialNumber = Utils.isNotBlank(formValue.data.equipmentCustomerSerialNumber) ?
      formValue.data.equipmentCustomerSerialNumber :
      null;
    currentEquipment.equipmentName = formValue.data.equipmentName;
    currentEquipment.equipmentProductClass = formValue.data.equipmentProductClass;
    currentEquipment.equipmentCostCenter = formValue.data.equipmentCostCenter;
    currentEquipment.manufacturerId = formValue.data.manufacturerId;
    currentEquipment.equipmentLicensePlate = formValue.data.equipmentLicensePlate;
    currentEquipment.equipmentTypeId = this.equipment.equipmentTypeId;
    currentEquipment.equipmentDeliveryDate = formValue.data.equipmentDeliveryDate;
    if (formValue.data.equipmentGuaranteeValue) {
      currentEquipment.equipmentGuarantee = moment.duration(formValue.data.equipmentGuaranteeValue,
        formValue.data.equipmentGuaranteeUnit).toISOString();
      currentEquipment.equipmentGuaranteeOperatingHoursLimit = formValue.data.equipmentGuaranteeOperatingHoursLimit;
    }
    if (formValue.data.equipmentConstructionMonth && formValue.data.equipmentConstructionYear) {
      currentEquipment.equipmentConstructionYear = formValue.data.equipmentConstructionYear +
        '-' + formValue.data.equipmentConstructionMonth;
    } else {
      currentEquipment.equipmentConstructionYear = null;
    }
    currentEquipment.equipmentBGLCode = formValue.data.equipmentBGLCode;
    currentEquipment.equipmentModel = formValue.data.equipmentModel;
    currentEquipment.equipmentRegistrationDate = formValue.data.equipmentRegistrationDate;

    const command = UpdateEquipmentCommand.from(currentEquipment);
    this.saveInProgress = true;
    this.equipmentsService.updateEquipment(command)
      .pipe(
        delay(environment.DELAY_SHORT),
        finalize(() => this.saveInProgress = false))
      .subscribe(() => {
        this.equipmentStore.updateCurrentEquipment();
        if (currentEquipment.organisationId !== formValue.data.organisationId) {
          this.assignOrganisation(currentEquipment.equipmentId, formValue.data.organisationId);
        } else {
          this.dialogRef.close('save')
        }
        this.equipmentStore.updateFilters();
      });
  }

  private subscribeToFormChanges(): void {
    this.filterOrganisationsOnInput();
    this.filterManufacturersOnInput();
    this.filterCostCentersOnInput();
    this.filterBGLCodesOnInput();
    this.filterStocksOnInput();

    // necessary to validate manufacturerSerialnumber on manufacturer change
    this.manufacturer.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.manufacturerSerialNumber.updateValueAndValidity();
      })
  }

  private getEquipmentTypes(): void {
    this.equipmentTypesService.getAllEquipmentTypesGroupedByCategory1()
      .subscribe((equipmentTypes: EquipmentTypeFilterView[]) => {
        this.allEquipmentTypes = equipmentTypes;
        this.filteredEquipmentTypes = equipmentTypes;
      });
  }

  private subscribeToTypeSearch(): void {
    this.equipmentTypeSearchControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((searchTerm: string) => {
        this.filterTypes(searchTerm);
      });
  }

  private filterTypes(searchTerm: string): void {
    this.filteredEquipmentTypes = _.cloneDeep(this.allEquipmentTypes).map(typeGroup => {
      typeGroup.subTypes = typeGroup.subTypes.filter(subType => this.isMatchingSubtype(subType, searchTerm));
      return typeGroup;
    }).filter(typeGroup => typeGroup.subTypes.length > 0);
  }

  private isMatchingSubtype(subType: EquipmentSubTypeFilterView, searchTerm: string): boolean {
    return  this.translatableStringResolver.transform(subType.category2Name)
      .toLowerCase()
      .indexOf(searchTerm.toLowerCase()) !== -1;
  }

  private setSerialNumberInUseValidator(): void {
    if (this.equipment) {
      this.customerSerialNumber.setAsyncValidators(
        asyncValidatorFactory((value) => SerialNumberInUseValidator
          .isValid(value, this.equipmentStore, this.equipment.equipmentCustomerSerialNumber))
      );
    } else {
      this.customerSerialNumber.setAsyncValidators(
        asyncValidatorFactory((value) => SerialNumberInUseValidator.isValid(value, this.equipmentStore))
      );
    }
  }

  private setManufacturerSerialNumberInUseValidator(): void {
    this.manufacturerSerialNumber.setAsyncValidators(
      asyncValidatorFactory((value) => {
        let manufacturerId: string;
        if (!(this.manufacturer.value == null) && !(this.manufacturer.value === '')) {
          manufacturerId = this.manufacturer.value;
        }
        if (this.equipment) {
          return ManufacturerSerialNumberInUseValidator.isValid(value, this.equipmentStore,
            manufacturerId, this.equipment.equipmentSerialNumber);
        } else {
          return ManufacturerSerialNumberInUseValidator.isValid(value, this.equipmentStore, manufacturerId);
        }
      })
    );
  }

  private buildForm() {
    this.equipmentForm = this.formBuilder.group({
      equipmentTypeId: ['', Validators.required],
      data: this.formBuilder.group({
        equipmentName: ['', [Validators.maxLength(FieldLimit.NAME)]],
        equipmentModel: ['', [Validators.required, Validators.maxLength(FieldLimit.NAME)]],
        equipmentSerialNumber: ['', [TrimValidator.hasWhitespaces, Validators.maxLength(FieldLimit.SHORT_IDENTIFIER)]],
        equipmentCustomerSerialNumber: [generateUniqueNumber(), [Validators.required, TrimValidator.hasWhitespaces,
          Validators.maxLength(FieldLimit.SHORT_IDENTIFIER)]],
        equipmentProductClass: ['', [Validators.maxLength(FieldLimit.NAME)]],
        equipmentCostCenter: ['', [Validators.maxLength(FieldLimit.COST_CENTER)]],
        equipmentLicensePlate: ['', [Validators.maxLength(FieldLimit.LICENSE_PLATE)]],
        equipmentGuaranteeValue: [null, naturalNumberValidator()],
        equipmentGuaranteeUnit: ['months'],
        equipmentGuaranteeOperatingHoursLimit: [{ value: null, disabled: true }],
        equipmentConstructionMonth: [''],
        equipmentConstructionYear: [''],
        equipmentDeliveryDate: ['', DateValidator.isValidDateOrEmpty(this.inputDeliveryDateRef, this.langService)],
        organisationId: ['', Validators.required],
        manufacturerId: [''],
        equipmentBGLCode: ['', [Validators.maxLength(FieldLimit.SHORT_IDENTIFIER)]],
        equipmentRegistrationDate: ['', DateValidator.isValidDateOrEmpty(this.inputRegistrationDateRef, this.langService)],
        labels: ['']
      }),
      stockId: ['', this.equipment ? null : Validators.required]
    });
  }

  private filterCustomerLabels(_customerLabels: Observable<string[]>): void {
    let filteredCustomerLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);

    combineLatest(this.equipmentLabels, _customerLabels)
      .subscribe(([equipmentLabels, customerLabels]) => {
        const currentCustomerLabels = customerLabels.map(label => label)
          .filter(label => !equipmentLabels.includes(label));
        filteredCustomerLabels.next(currentCustomerLabels);
      });

    this.customerLabels = filteredCustomerLabels;
  }

  private filterOrganisationsOnInput(): void {
    this.organisationFilter
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.equipmentFieldStore.filterOrganisations(filterTerm));
  }

  private filterManufacturersOnInput(): void {
    this.manufacturerFilter
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.equipmentFieldStore.filterManufacturers(filterTerm));
  }

  private filterCostCentersOnInput(): void {
    this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_COST_CENTER_CONTROL)
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.equipmentFieldStore.filterCostCenters(filterTerm));
  }

  private filterBGLCodesOnInput(): void {
    this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_BGL_CODE_CONTROL)
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.equipmentFieldStore.filterBglCodes(filterTerm));
  }

  private filterStocksOnInput(): void {
    this.stockFilter
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.equipmentFieldStore.filterStocks(filterTerm));
  }

  private fillForm(): void {
    this.equipmentForm.patchValue({
      equipmentTypeId: this.equipment.equipmentTypeId,
      stockId: this.equipment.stockId
    });
    this.equipmentForm.get(this.DATA_CONTROL).patchValue({
      equipmentCustomerSerialNumber: this.equipment.equipmentCustomerSerialNumber,
      equipmentName: this.equipment.equipmentName,
      organisationId: this.equipment.organisationId,
      manufacturerId: this.equipment.manufacturerId,
      equipmentSerialNumber: this.equipment.equipmentSerialNumber,
      equipmentModel: this.equipment.equipmentModel,
      equipmentCostCenter: this.equipment.equipmentCostCenter,
      equipmentDeliveryDate: this.equipment.equipmentDeliveryDate,
      equipmentProductClass: this.equipment.equipmentProductClass,
      equipmentLicensePlate: this.equipment.equipmentLicensePlate,
      equipmentBGLCode: this.equipment.equipmentBGLCode,
      equipmentRegistrationDate: this.equipment.equipmentRegistrationDate
    });

    if (this.equipment.equipmentGuarantee) {
      this.parseAndPatchGuarantee(this.equipment.equipmentGuarantee);
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({
        equipmentGuaranteeOperatingHoursLimit:
        this.equipment.equipmentGuaranteeOperatingHoursLimit
      });
    }

    if (this.equipment.equipmentConstructionYear) {
      let parts: string[] = this.equipment.equipmentConstructionYear.split('-');
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentConstructionMonth: parts[1] });
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentConstructionYear: parts[0] });
    }
    this.cdRef.detectChanges();
  }

  private parseAndPatchGuarantee(equipmentGuarantee: string): void {
    const monthsDuration = moment.duration(equipmentGuarantee).get('months');
    const yearsDuration = moment.duration(equipmentGuarantee).get('years');

    if (monthsDuration > 0 && yearsDuration > 0) {
      const yearsToMonths = yearsDuration * 12;
      const totalMonthsDuration = monthsDuration + yearsToMonths;
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentGuaranteeValue: totalMonthsDuration });
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentGuaranteeUnit: 'months' });

    } else if (monthsDuration > 0 && yearsDuration === 0) {
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentGuaranteeValue: monthsDuration });
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentGuaranteeUnit: 'months' });

    } else if (monthsDuration === 0 && yearsDuration > 0) {
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentGuaranteeValue: yearsDuration });
      this.equipmentForm.get(this.DATA_CONTROL).patchValue({ equipmentGuaranteeUnit: 'years' });
    }
    this.equipmentForm.get(this.DATA_CONTROL).get(this.EQUIPMENT_GUARANTEE_OPERATING_HOURS_LIMIT_CONTROL).enable();
  }
}
