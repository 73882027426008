<div class="dialog-wrapper">
  <h2 mat-dialog-title>
    {{'modules.equipment.manufacturerCreateDialog.createManufacturer'|translate}}
  </h2>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close [attr.aria-label]="'general.labels.clear'|translate" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <form [formGroup]="manufacturerForm">
    <mat-dialog-content fxLayout="row" fxLayoutGap="15px" class="manufacturer-create-content">
      <mat-form-field fxFlex="50">
        <mat-label>{{'modules.equipment.manufacturerCreateDialog.manufacturerName'|translate}}</mat-label>
        <input matInput
          bhRemoveWhitespaces
          formControlName="manufacturerName"
          [bhMaxLength]="fieldLimit.MEDIUM_IDENTIFIER" required>
        <mat-error *ngIf="manufacturerName.hasError('required')">
          {{'shared.validation.required'|translate:
            { value: 'modules.equipment.manufacturerCreateDialog.manufacturerName'|translate } }}
        </mat-error>
        <mat-error *ngIf="manufacturerName.hasError('taken')">
          {{'shared.validation.alreadyAssigned'|translate:
            { value: 'modules.equipment.manufacturerCreateDialog.manufacturerName'|translate } }}
        </mat-error>
        <mat-error *ngIf="manufacturerName.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
          {{'shared.validation.string.trimSpaces'|translate|translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-label>{{'modules.equipment.manufacturerCreateDialog.manufacturerCode'|translate}}</mat-label>
        <input matInput formControlName="manufacturerAbbreviation">
      </mat-form-field>
    </mat-dialog-content>
  </form>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-raised-button mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!manufacturerForm.valid">{{'general.buttons.save'|translate}}
    </button>
  </mat-dialog-actions>
</div>
