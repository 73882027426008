import { AllowedErrorUrl } from './allowed-error-url';
import { HttpStatusCodes } from '../../shared/enums/http-status-codes.enum';

export class UrlErrorHandler {

  private readonly offlineUrls: string[] = [
    'api/v1/equipments/disposition',
    'api/v1/bulk-items/disposition',
    'api/v1/projects/disposition',
    'api/v1/stocks/disposition',
    'api/v1/amounts/disposition',
    'api/v1/project-amounts/disposition'
  ];
  private readonly pollingApiUrls: string[] = [
    'api/v1/communication/chat/unread',
    'api/v1/notifications/equipmentalarms/current-active',
    'api/v1/notifications/alarms/current-active'
  ];
  private readonly allowedErrorUrls: AllowedErrorUrl[] = [
    {
      url: 'beut-push-service:7216/api/v1/push/register',
      errorCodes: [
        HttpStatusCodes.UNKNOWN_ERROR,
        HttpStatusCodes.UNAUTHORIZED,
      ],
      throwObservableError: true,
    },
    {
      url: 'beut-push-service:7216/api/v1/push/unregister',
      errorCodes: [
        HttpStatusCodes.UNKNOWN_ERROR,
        HttpStatusCodes.UNAUTHORIZED,
      ],
      throwObservableError: true,
    },
    {
      url: 'api/v1/osp/connector',
      errorCodes: [
        HttpStatusCodes.NOT_FOUND,
      ],
    },
    {
      url: 'api/v1/bulk-items/disposition',
      errorCodes: [
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/communication/chat/unread',
      errorCodes: [
        HttpStatusCodes.UNKNOWN_ERROR,
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.SERVICE_UNAVAILABLE,
        HttpStatusCodes.BAD_GATEWAY,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/notifications/alarms/current-active',
      errorCodes: [
        HttpStatusCodes.UNKNOWN_ERROR,
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.SERVICE_UNAVAILABLE,
        HttpStatusCodes.BAD_GATEWAY,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/equipments/by-scan-code',
      errorCodes: [
        HttpStatusCodes.NOT_FOUND,
        HttpStatusCodes.FORBIDDEN,
        HttpStatusCodes.LOCKED,
      ],
      throwObservableError: true,
    },
    {
      url: 'api/v1/scancodes/scan-code-in-use',
      errorCodes: [
        HttpStatusCodes.NOT_FOUND,
      ],
      throwObservableError: true,
    },
    {
      url: 'api/v1/equipments/add-label',
      errorCodes: [
        HttpStatusCodes.NOT_MODIFIED,
      ],
    },
    {
      url: 'api/v1/equipments/filterable-equipment-types',
      errorCodes: [
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/equipments/disposition',
      errorCodes: [
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/notifications/equipmentalarms/current-active',
      errorCodes: [
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.SERVICE_UNAVAILABLE,
        HttpStatusCodes.BAD_GATEWAY,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/projects/disposition',
      errorCodes: [
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/stocks/disposition',
      errorCodes: [
        HttpStatusCodes.GATEWAY_TIMEOUT,
        HttpStatusCodes.UNAUTHORIZED,
      ],
    },
    {
      url: 'api/v1/docuware/find-documents',
      errorCodes: [
        HttpStatusCodes.INTERNAL_SERVER_ERROR
      ],
      throwObservableError: true
    },
    {
      url: 'api/v1/topfact/find-documents',
      errorCodes: [
        HttpStatusCodes.SERVICE_UNAVAILABLE
      ],
      throwObservableError: true
    },
    {
      url: 'api/v1/projects/type-of-use',
      errorCodes: [
        HttpStatusCodes.BAD_REQUEST,
        HttpStatusCodes.INTERNAL_SERVER_ERROR
      ],
      throwObservableError: true
    },
    {
      url: '/api/v1/stocks',
      errorCodes: [
        HttpStatusCodes.FORBIDDEN
      ],
      throwObservableError: true
    }
  ];

  public isExpectedOfflineOrAllowedErrorUrl(isOnline: boolean, url: string, errorCode?: number): boolean {
    return this.isHealthUrl(url)
        || this.isAllowedErrorUrl(url, errorCode)
        || (!isOnline && (this.isExpectedOfflineUrl(url)));
  }

  public shouldThrowObservable(url: string, errorCode: number): boolean {
    return !this.isAllowedErrorUrl(url, errorCode)
        || this.allowedErrorUrls.some((allowedErrorUrl: AllowedErrorUrl) =>
            url.includes(allowedErrorUrl.url)
            && (!errorCode || allowedErrorUrl.errorCodes.includes(errorCode))
            && allowedErrorUrl.throwObservableError);
  }

  private isHealthUrl(url: string): boolean {
    return url.endsWith('/health');
  }

  private isAllowedErrorUrl(url: string, errorCode: number): boolean {
    const allowUnknownError = this.isOfflineUrl(url) || this.isPollingUrl ? false : !errorCode;
    return this.allowedErrorUrls.some((allowedErrorUrl: AllowedErrorUrl) =>
        url.includes(allowedErrorUrl.url) && (allowUnknownError || allowedErrorUrl.errorCodes.includes(errorCode)));
  }

  private isOfflineUrl(url: string): boolean {
    return this.offlineUrls.some((offlineUrl: string) => url.includes(offlineUrl));
  }

  private isPollingUrl(url: string): boolean {
    return this.pollingApiUrls.some((pollingUrl: string) => url.includes(pollingUrl));
  }

  private isExpectedOfflineUrl(url: string): boolean {
    return this.isOfflineUrl(url) || this.isPollingUrl(url);
  }

  public isThumbnailUrl(url: string): boolean {
    const regex = /[?&]thumbnail=/;
    return regex.test(url);
  }
}
