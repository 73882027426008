import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { RouterHistory } from 'app/shared/router-history';
import { LanguageService } from 'app/shared/services/language.service';
import { DispositionBasicMapComponent } from '../../shared/disposition-basic-map.component';
import { ProjectDataSource } from '../../shared/project.datasource';
import { StockStore } from '../shared/stock.store';
import { MapConsts } from 'app/shared/modules/map/map.consts';

@UntilDestroy()
@Component({
  selector: 'bh-stock-map',
  templateUrl: './stock-map.component.html',
  styleUrls: ['./stock-map.component.scss']
})
export class StockMapComponent extends DispositionBasicMapComponent implements SearchFilterActivatedView {
  public readonly mapSettings: MapConfiguration = this.getInitialMapSettings();
  public readonly routeButton: ButtonInfo = {
    route: 'sites/stocks/list/',
    tooltip: this.languageService.getInstant('general.list'),
    iconName: 'list'
  }
  public readonly searchSuggestionsField = '';


  constructor(
    private stockStore: StockStore,
    projectStore: ProjectDataSource,
    router: Router,
    authService: KeycloakService,
    route: ActivatedRoute,
    organisationService: OrganisationsService,
    routerHistory: RouterHistory,
    languageService: LanguageService,
    equipmentStore: EquipmentsDataSource,
    contactDataSource: ContactDataSource)
  {
    super(
      projectStore,
      router,
      authService,
      route,
      organisationService,
      routerHistory,
      languageService,
      equipmentStore,
      contactDataSource);
  }

  public saveMapConfigs(mapConfig: MapConfiguration): void {
    this.stockStore.saveMapConfiguration(mapConfig);
  }

  private getInitialMapSettings(): MapConfiguration {
    return {
      ...MapConsts.getDefaultMapConfiguration(),
      showStocks: true,
      ...(this.stockStore.getMapConfiguration() || {}),
    }
  }
}
