import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { ReportsService } from './reports.service';
import { ViewReportDefinition } from '../../contract/view-report-definition.interface';
import { CreateReportDefinitionCommand } from '../../contract/create-report-definition-command';
import { UpdateReportDefinitionCommand } from '../../contract/update-report-definition-command';
import { DeleteReportDefinitionCommand } from '../../contract/delete-report-definition-command';
import { ReportGenerationCommand } from '../../contract/report-generation-command';
import { ViewReportJob } from '../../contract/view-report-job.interface';
import { CreateReportJobCommand } from '../../contract/create-report-job-command';
import { UpdateReportJobCommand } from '../../contract/update-report-job-command';
import { DeleteReportJobCommand } from '../../contract/delete-report-job-command';
import { ReportGenerateTransportOrdersCommand } from '../../contract/report-transport-orders-command';
import { ViewReportDetailsJob } from '../../contract/view-report-details-job.interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { EquipmentTypeCountTranslated } from '../../contract/equipment-type-count';
import { CustomerLabel } from '../../../../shared/contract/customer-label.interface';
import { ViewOrganisation } from '../../../organisation/contract/view-organisation.interface';
import { ReportParameters } from '../../contract/report-parameters';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class ReportsDataSource extends DataSource<ViewReportDefinition> {

  private _data = new BehaviorSubject<ViewReportDefinition[]>([]);
  private _reportParameters = new BehaviorSubject<ReportParameters>(null);
  private _reportJobs = new BehaviorSubject<ViewReportDetailsJob[]>(null);
  private _reportJob = new BehaviorSubject<ViewReportJob>(null);
  private _reportEquipmentTypes = new BehaviorSubject<EquipmentTypeCountTranslated[]>([]);
  private _labels = new BehaviorSubject<CustomerLabel[]>([]);
  private _organisations = new BehaviorSubject<ViewOrganisation[]>([]);

  public readonly reportDefinitions = this._data.asObservable();
  public readonly reportParameters = this._reportParameters.asObservable();
  public readonly reportJobs = this._reportJobs.asObservable();
  public readonly reportJob = this._reportJob.asObservable();
  public readonly reportEquipmentTypes = this._reportEquipmentTypes.asObservable();
  public readonly labels = this._labels.asObservable();
  public readonly organisations = this._organisations.asObservable();
  public customerId: string;

  constructor(
    private _reportsService: ReportsService,
    private translatableStringResolver: TranslatableStringPipe,
  ) {
    super();
  }

  get data(): ViewReportDefinition[] {
    return this._data.value;
  }

  public connect(): Observable<ViewReportDefinition[]> {
    if (this.customerId) {
      this.getReportDefinitionsByCustomer(this.customerId);
    } else {
      this.getReportDefinitions();
    }
    return merge(this._data);
  }

  public disconnect() {
  }

  // Report Definitions DataSource

  public getReportDefinitions() {
    this._reportsService.getReportDefinitions().subscribe(res => this._data.next(res.content));
  }

  public getReportDefinition(id: string): Observable<ViewReportDefinition> {
    return this._reportsService.getReportDefinition(id);
  }

  public getReportDefinitionsByCustomer(id: string) {
    this._reportsService.getReportDefinitionsByCustomer(id).subscribe(res => this._data.next(res.content));
  }

  public addReportDefinition(cmd: CreateReportDefinitionCommand): Observable<any> {
    return this._reportsService
      .addReportDefinition(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.getReportDefinitions();
        }, environment.DELAY_SHORT);

        return of(res);
      }));
  }

  public updateReportDefinition(cmd: UpdateReportDefinitionCommand): Observable<any> {
    return this._reportsService
      .updateReportDefinition(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.getReportDefinitions();
        }, environment.DELAY_SHORT);

        return of(res);
      }));
  }

  public deleteReportDefinition(cmd: DeleteReportDefinitionCommand): Observable<any> {
    return this._reportsService
      .deleteReportDefinition(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.getReportDefinitions();
        }, environment.DELAY_SHORT);

        return of(res);
      }));
  }

  // Report Generate DataSource

  public generateReport(cmd: ReportGenerationCommand): Observable<any> {
    return this._reportsService
      .generateReport(cmd)
      .pipe(tap(_ => this.saveReportParameters(cmd)))
      .pipe(switchMap((res: any) => {
        return of(res);
      }));
  }

  public generateTransportOrdersReport(cmd: ReportGenerateTransportOrdersCommand): Observable<any> {
    return this._reportsService
      .generateTransportOrdersReport(cmd)
      .pipe(switchMap((res: any) => {
        return of(res);
      }));
  }

  // Report Jobs DataSource

  public getReportJobs() {
    this._reportsService.getReportJobs().subscribe(res => this._reportJobs.next(res.content));
  }

  public getReportJob(id: string) {
    this._reportsService.getReportJob(id).subscribe(res => this._reportJob.next(res));
  }

  public addReportJob(cmd: CreateReportJobCommand): Observable<any> {
    return this._reportsService
      .addReportJob(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.getReportJobs();
        }, environment.DELAY_SHORT);

        return of(res);
      }));
  }

  public updateReportJob(cmd: UpdateReportJobCommand): Observable<any> {
    return this._reportsService
      .updateReportJob(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.getReportJobs();
        }, environment.DELAY_SHORT);

        return of(res);
      }));
  }

  public deleteReportJob(cmd: DeleteReportJobCommand): Observable<any> {
    return this._reportsService
      .deleteReportJob(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.getReportJobs();
        }, environment.DELAY_SHORT);

        return of(res);
      }));
  }

  public getEquipmentTypes() {
    this._reportsService.getUserEquipmentTypes()
      .pipe(map(res => res.map(type => ({
        ...type,
        category1NameTranslated: this.translatableStringResolver.transform(type.category1Name),
        category2NameTranslated: this.translatableStringResolver.transform(type.category2Name)
      }))))
      .subscribe((res: EquipmentTypeCountTranslated[]) => this._reportEquipmentTypes.next(res));
  }

  public getCustomerLabels(): Observable<CustomerLabel[]> {
    this._reportsService
      .getCustomerLabels()
      .subscribe((labels: CustomerLabel[]) => this._labels.next(labels));

    return this.labels;
  }

  public getOrganisations(): Observable<ViewOrganisation[]> {
    this._reportsService
      .getOrganisations()
      .subscribe((organisations: ViewOrganisation[]) => this._organisations.next(organisations));

    return this.organisations;
  }

  public saveReportParameters(cmd: ReportGenerationCommand): void {
    this._reportsService.saveReportParameters(cmd).subscribe();
  }

  public getReportParameters(reportDefinitionId: string): Observable<ReportParameters> {
    return this._reportsService.getReportParameters(reportDefinitionId);
  }
}
