import { environment } from 'environments/environment';
import { LanguageService } from './../../../../../../../shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteEquipmentAssignmentCommand } from '../../../../../contract/delete-equipment-assignment-command';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  EquipmentAssignmentDeleteDialogComponent
} from '../../../../../shared/equipment-assignment-delete-dialog/equipment-assignment-delete-dialog.component';
import {
  EquipmentAssignToProjectDialogComponent
} from '../../../../equipment-assign-to-project/dialog/equipment-assign-to-project-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { BaseEquipmentViewDispositionComponent } from '../base/base-equipment-view-disposition.component';
import { ProjectDataSource } from '../../../../../../disposition/shared/project.datasource';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { AssignEquipmentToProjectCommand } from '../../../../../contract/assign-equipment-to-project-command';
import {
  EquipmentAssignmentEditDialogComponent
} from '../../../../../shared/equipment-assignment-edit-dialog/equipment-assignment-edit-dialog.component';
import { ProjectsService } from '../../../../../../disposition/shared/project.service';
import { UpdateEquipmentAssignmentCommand } from '../../../../../contract/update-equipment-assignment-command';
import { ViewProject } from '../../../../../../disposition/contract/view-project.interface';
import { delay, filter, switchMap } from 'rxjs/operators';
import { ViewEquipmentProjectAssignment } from '../../../../../contract/view-equipment-project-assignment.interface';
import { Observable } from 'rxjs';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { matomoCategories } from '../../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'bh-equipment-view-disposition',
  templateUrl: './equipment-view-disposition.component.html',
  styleUrls: ['./equipment-view-disposition.component.scss']
})

export class EquipmentViewDispositionComponent extends BaseEquipmentViewDispositionComponent implements OnInit {

  public canSeeLink = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              public projectService: ProjectsService,
              public projectStore: ProjectDataSource,
              private equipmentCheckerService: EquipmentCheckerService,
              protected languageService: LanguageService,
              private matomoTracker: MatomoTracker) {
    super(authService, router, route, routerHistory, equipmentStore, languageService);
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_DISPOSITION);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.authService.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this.canSeeLink = true;
    }
  }

  deleteAssignment(assignment) {
    let dialogRef = this.dialog.open(EquipmentAssignmentDeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd = new DeleteEquipmentAssignmentCommand();
        cmd.equipmentId = assignment.equipmentId;
        cmd.assignmentId = assignment.assignmentId;
        cmd.projectId = assignment.projectId;
        cmd.startDate = new Date(assignment.startDate).toISOString();
        cmd.endDate = assignment.endDate ? new Date(assignment.endDate).toISOString() : null;

        this.equipmentStore.deleteAssignment(cmd).subscribe(
            () => {
              setTimeout(() => {
                this.equipmentStore.getAssignments(cmd.equipmentId);
                this.equipmentStore.updateListing();
              }, environment.DELAY_LONG);
            },
            (error: HttpErrorResponse) => {
              console.log('Delete assignment error: ', error.message);
            }
        );
      }
    });
  }

  public isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public editAssignment(assignment: ViewEquipmentProjectAssignment): void {
    this.projectService.getProject(assignment.projectId)
    .pipe(
        switchMap((project: ViewProject) => this.openAssignmentEditDialog(project, assignment)),
        filter(dialogResult => !!dialogResult),
        switchMap((command: UpdateEquipmentAssignmentCommand) => this.equipmentStore.updateAssignment(command)),
        delay(environment.DELAY_LONG)
    )
    .subscribe(() => this.equipmentStore.updateListing());
  }

  navigateToProjectAssignments(projectId) {
    this.projectStore.currentViewTab = 'assignments';
    this.router.navigate([`/sites/projects/list/${projectId}/assignments`]);
  }

  assignToProject() {
    let dialogRef = this.dialog.open(EquipmentAssignToProjectDialogComponent, <MatDialogConfig>{
      disableClose: true
    });
    dialogRef.componentInstance.equipments.push(this.equipment);
    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.afterClosed().subscribe((commands: AssignEquipmentToProjectCommand[]) => {
      if (commands) {
        commands.forEach((cmd) => {
          this.equipmentStore.assignEquipmentToProject(cmd).subscribe(res => {
          });
        });
        setTimeout(() => this.equipmentStore.updateListing(), environment.DELAY_LONG);
      }
    });
  }

  private openAssignmentEditDialog(project: ViewProject, assignment: ViewEquipmentProjectAssignment):
   Observable<UpdateEquipmentAssignmentCommand> {
    const dialogRef = this.dialog.open(EquipmentAssignmentEditDialogComponent, <MatDialogConfig>{
      disableClose: true
    });
    dialogRef.componentInstance.assignment = assignment;
    dialogRef.componentInstance.project = project;
    return dialogRef.afterClosed();
  }
}
