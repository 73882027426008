import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ManufacturerService } from '../../shared/manufacturer.service';
import { ViewManufacturer } from '../../contract/view-manufacturer.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteManufacturerCommand } from '../../contract/delete-manufacturer-command';
import { MatDialog } from '@angular/material/dialog';
import { ManufacturerDeleteDialogComponent } from '../../shared/manufacturer-delete-dialog/manufacturer-delete-dialog.component';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { debounceTime, delay, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { environment } from 'environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { PageEvent } from '@angular/material/paginator';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ManufacturerColumnService } from 'app/modules/disposition/shared/services/manufacturer-column.service';

@Component({
  selector: 'bh-manufacturer-list',
  templateUrl: 'manufacturer-list.component.html',
  styleUrls: ['manufacturer-list.component.scss']
})
@UntilDestroy()
export class ManufacturerListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy, AfterViewInit {
  public searchForm: UntypedFormGroup;

  displayedColumns = [
    'manufacturerName',
    'manufacturerAbbreviation',
    'manufacturerMakerCode',
    'manufacturerLogo'
  ];
  manufacturerDataSource: ManufacturersDataSource | null;

  @ViewChild('filter', { static: true }) filter: ElementRef;

  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  constructor(private dialog: MatDialog,
              private manufacturersService: ManufacturerService,
              protected router: Router,
              protected route: ActivatedRoute,
              public columnService: ManufacturerColumnService,
              private detector: ChangeDetectorRef,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
    this.manufacturerDataSource = new ManufacturersDataSource(this.manufacturersService, this.columnService);
  }

  public ngOnInit(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl(),
    });
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => {
      this.manufacturerDataSource.updateListing(this.manufacturerDataSource.pagination.index, pageSize);
    });
    this.columnPermissionHandler();
    this.manufacturerDataSource.updateListing();
  }

  public ngAfterViewInit(): void {
    // TODO: Remove this as it is a workaround to make the table visible when the page got reloaded
    this.detector.detectChanges();
    this.onSearchFormType();
  }

  public ngOnDestroy(): void {
  }

  public onPaginateChange({ pageIndex, pageSize }: PageEvent): void {
    this.manufacturerDataSource.updateListing(pageIndex, pageSize);
    this.columnService.selectColumns([], pageSize);
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(terms => this.updateOnSearch(terms));

    this.searchForm.patchValue({terms: this.manufacturerDataSource.searchTerms}, { emitEvent: false });
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public manufacturerDelete(manufacturerId: string): void {
    this.manufacturersService.manufacturerInUse(manufacturerId)
    .pipe(untilDestroyed(this))
    .subscribe(inUse => {
      this.openDeleteDialog(manufacturerId, !inUse);
    });
  }

  private openDeleteDialog(manufacturerId: string, deletable: boolean): void {
    const data = {deletable: deletable};
    let dialogRef = this.dialog.open(ManufacturerDeleteDialogComponent, {data});
    dialogRef.afterClosed()
    .pipe(
        filter(result => result === dialogResults.YES),
        switchMap(() => this.deleteManufacturer(manufacturerId)),
        delay(environment.DELAY_SHORT),
        untilDestroyed(this))
    .subscribe(() => this.manufacturerDataSource.updateListing());
  }

  private deleteManufacturer(manufacturerId: string): Observable<string> {
    let cmd = new DeleteManufacturerCommand();
    cmd.manufacturerId = manufacturerId;
    return this.manufacturersService.deleteManufacturer(cmd);
  }

  private columnPermissionHandler(): void {
    if (this.hasAuthority(this.authorities.MANUFACTURER_UPDATE)) {
      this.displayedColumns.push('manufacturerEdit');
    }

    if (this.hasAuthority(this.authorities.MANUFACTURER_DELETE)) {
      this.displayedColumns.push('manufacturerDelete');
    }
  }

  private updateOnSearch(searchTerms: string): void {
    this.manufacturerDataSource.searchTerms = searchTerms;
    this.manufacturerDataSource.updateListing(0);
  }
}

export class ManufacturersDataSource extends DataSource<any> {
  private subject: BehaviorSubject<ViewManufacturer[]> = new BehaviorSubject([]);
  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private manufacturerRequest = new Subject<Object>();

  public readonly manufacturers = this.subject.asObservable();

  constructor(
    private _manufacturersService: ManufacturerService,
    private manufacturerColumnService: ManufacturerColumnService
  ) {
    super();
    this.manufacturerRequestListener();
    this.manufacturerColumnService.pageSize.pipe(distinctUntilChanged()).subscribe((pageSize: number) => {
      this.updateListing(this.pagination.index, pageSize);
    });
  }

  public connect(): Observable<ViewManufacturer[]> {
    return this.manufacturers;
  }

  public disconnect(): void {
  }

  public updateListing(page: number = this.pagination.index, size: number = this.pagination.size): void {
    this.pagination.index = page;
    this.pagination.size = size;

    const params = {
      page,
      size,
      searchTerm: this._searchTerms
    }

    this.manufacturerRequest.next(params);
  }

  public get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  public set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  set searchTerms(searchTerms: string) {
    this._searchTerms = searchTerms;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  private manufacturerRequestListener(): void {
    this.manufacturerRequest
    .pipe(switchMap((params: Object) => this._manufacturersService.getManufacturers(params)))
    .subscribe(res => this.updateStoreData(res));
  }

  private updateStoreData(response: PagedResponse<ViewManufacturer>): void {
    this.updatePaginator(response);
    this.subject.next(response.content);
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private updatePaginator(response: PagedResponse<any>): void {
    this.pagination = {
      totalElements: response.totalElements,
      totalPages: response.totalPages,
      size: response.size,
      index: response.number,
      numberOfElements: response.numberOfElements,
    }
  }

}

