import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomDeviceDetectorService {

  private readonly localStorageKey: string = 'force-view';

  public isModalShown: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private deviceService: DeviceDetectorService) {
  }

  public isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}
