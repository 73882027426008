import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { MaintenanceEquipment } from '../../../shared/maintenance-equipment.interface';

@Injectable()
export class MaintenanceEquipmentService {

  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/equipments';

  constructor(private http: HttpClient) {}

  getById(equipmentId: string): Observable<MaintenanceEquipment> {
    return this.http.get<MaintenanceEquipment>(`${this.url}/${equipmentId}`)
  }
}
