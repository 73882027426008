export class OperatingHoursInterval {

  static from(operatingHoursFormValue: any): OperatingHoursInterval {
    return new OperatingHoursInterval(
        operatingHoursFormValue.interval,
        operatingHoursFormValue.mediumWarningThreshold,
        operatingHoursFormValue.highWarningThreshold);
  }

  constructor(
      public requiredIncrease: number,
      public mediumWarningThreshold: number,
      public highWarningThreshold: number
  ) {}
}
