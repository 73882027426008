import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { EquipmentLocationType } from 'app/modules/maintenance/shared/contract/equipment-location-type-enum';
import { faTrash, faWarehouse } from '@fortawesome/pro-duotone-svg-icons';
import { LanguageService } from 'app/shared/services/language.service';
import { Router } from '@angular/router';
import { GENERAL_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { TransferType } from '../../enums/transfer-type.enum';

@Component({
  selector: 'bh-location-column-data',
  templateUrl: './location-column-data.component.html',
  styleUrls: ['./location-column-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationColumnDataComponent implements OnInit {

  @Input() columnDefName = '';
  @Input() value = '';
  @Input() locationType: EquipmentLocationType;
  @Input() transferType: TransferType;
  @Input() locationId = '';
  @Input() isAllowedToSeeLocation = false;
  @Input() isAvailableForCurrentUser = true;

  public isProject: boolean;
  public isConsumed: boolean;
  public linkTooltip = '';

  public readonly columnDef = GENERAL_COLUMN_DEF;
  public readonly stockIcon: IconDefinition = faWarehouse;
  public readonly trashIcon: IconDefinition = faTrash;

  constructor(private router: Router,
              private languageService: LanguageService) { }

  ngOnInit(): void {
    this.setLocationType();
    this.setLinkTooltip();
    this.setIsConsumed();
  }

  private setLocationType(): void {
    if (this.locationType) {
      this.isProject = this.locationType === EquipmentLocationType.PROJECT;
    }
  }

  private setIsConsumed(): void {
    this.isConsumed = this.transferType === TransferType.CONSUME;
  }

  private setLinkTooltip(): void {
    this.isProject
      ? this.linkTooltip = this.translate('general.labels.jumpTo', {value: this.translate('general.project.s')})
      : this.linkTooltip = this.translate('general.labels.jumpTo', {value: this.translate('general.stock.s')});
  }

  public navigateToLocation(): void {
    this.isProject
      ? this.router.navigate(['sites/projects/list', this.locationId, 'general'])
      : this.router.navigate(['sites/stocks/list', this.locationId, 'general']);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
