import { Pipe, PipeTransform } from '@angular/core';
import { ActivityStatus } from 'app/shared/contract/activity-status.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'activityStatus'
})
export class ActivityStatusPipe implements PipeTransform {

  private translations: Record<ActivityStatus, string> = {
    [ActivityStatus.WORKING]: 'modules.equipment.activityStatus.working',
    [ActivityStatus.STOPPED]: 'modules.equipment.activityStatus.stopped',
    [ActivityStatus.IDLE]: 'modules.equipment.activityStatus.idle',
    [ActivityStatus.AT_PROJECT]: 'modules.equipment.activityStatus.atProject',
    [ActivityStatus.UNKNOWN]: 'general.unknown',
  };

  constructor(private languageService: LanguageService) {}

  public transform(value: ActivityStatus): string {
    return this.translate(this.translations[value] || this.translations[ActivityStatus.UNKNOWN]);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
