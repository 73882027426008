import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { AdditionalFieldEntity } from '../../modules/osp-ui/rich-components/bh-additional-field/additional-field';

@Pipe({
  name: 'additionalFieldEntityDisplay',
  pure: true
})
export class AdditionalFieldEntityDisplayPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case AdditionalFieldEntity.EQUIPMENT:
        return this.translate('general.additionalFieldEntity.equipment');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
