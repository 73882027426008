<bh-step-dialog
  [title]="(stock ? 'modules.disposition.stockAddEdit.editStock' : 'modules.disposition.stockAddEdit.createStock')|translate"
  [icon]="faWarehouseAlt">
  <form id="add-form" [formGroup]="stockForm" fxFlex="100" fxLayout="column"
        fxLayoutAlign="space-between stretch">
    <mat-horizontal-stepper linear labelPosition="bottom">
      <mat-step [stepControl]="stockForm.get('type')">
        <ng-template matStepLabel>{{'general.type'|translate}}</ng-template>
        <div class="step-content" fxLayout="column" fxLayoutGap="25px">
          <span class="type-select-title">{{'modules.disposition.stockAddEdit.selectStockType'|translate}}</span>
          <bh-card-select class="type-select" [options]="stockTypes"
                          [optionResolver]="stockTypeResolver"
                          [readOnly]="isMainStock()"
                          formControlName="type"
                          ngDefaultControl></bh-card-select>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
          <div class="button-wrapper" fxLayoutGap="15px">
            <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            <button fxLayoutAlign="end center"
                    mat-raised-button
                    [disabled]="stockForm.get('type').invalid"
                    matStepperNext>
              {{'general.buttons.continue'|translate}}
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="stockForm.get('data')" [formGroup]="$any(stockForm.get('data'))">
        <ng-template matStepLabel>{{'general.data'|translate}}</ng-template>
        <div class="step-content data-content" fxLayout="row" fxLayoutAlign="center center">
          <div fxLayout="column" fxFlex="45">
            <bh-text-input [control]="$any(name)"
                           [label]="'general.name.s'|translate"
                           [icon]="faTag">
            </bh-text-input>
            <bh-text-input [control]="$any(costCenter)"
                           [label]="'general.costCenter'|translate"
                           [icon]="faFileInvoiceDollar">
            </bh-text-input>
            <mat-form-field>
              <mat-label>{{'general.org.s'|translate}}</mat-label>
              <mat-select [required]="!isMainStock()"
                          formControlName="organisation"
                          [disableOptionCentering]="true">
                <mat-option>
                  <ngx-mat-select-search [formControl]="filterControl"
                                         [placeholderLabel]="'general.labels.search'|translate:{ value: 'general.org.s'|translate }"
                                         [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: 'general.org.pl'|translate }">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let organisation of organisations | async"
                            [value]="organisation.organisationId">
                  {{ organisation.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="organisation.hasError('required')">
                {{'shared.validation.required'|translate:{ value: 'general.org.s'|translate } }}
              </mat-error>
              <fa-icon matSuffix [icon]="faBuilding"></fa-icon>
            </mat-form-field>
            <div class="toggle-wrapper" fxLayout="row" fxLayoutAlign="start center">
              <mat-slide-toggle color="primary"
                                formControlName="displayInDispositionBoard"
                                fxFlex>
                {{'modules.disposition.stockAddEdit.displayOnBoard'|translate}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between stretch">
          <div class="button-wrapper" fxLayoutGap="15px">
            <button fxLayoutAlign="start center"
                    mat-raised-button
                    matStepperPrevious>
              {{'general.buttons.back'|translate}}
            </button>
          </div>
          <div class="button-wrapper" fxLayoutGap="15px">
            <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            <button *ngIf="!noLocationType()"
                    fxLayoutAlign="end center"
                    mat-raised-button
                    [disabled]="stockForm.get('data').invalid"
                    matStepperNext>{{'general.buttons.continue'|translate}}
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step #step *ngIf="!noLocationType()" [stepControl]="stockForm.get('address')"
                [formGroup]="$any(stockForm.get('address'))">
        <ng-template matStepLabel>{{'general.location.s'|translate}}</ng-template>
        <div class="step-content">
          <div fxLayout="row" fxLayoutGap="15px">
            <bh-map-location-select class="location-select"
                                    fxFlex
                                    [address]="enteredAddress | async"
                                    [location]="stockLocation"
                                    (addressChange)="updateAddress($event)"
                                    (geocoderStatus)="geocoderStatus = $event"
                                    data-osp-test="map-location-select">
            </bh-map-location-select>
            <div fxLayout="column" fxFlex="33">
              <h4>{{'general.address.address'|translate}}</h4>
              <bh-text-input [control]="$any(street)"
                             [label]="'general.address.street'|translate">
              </bh-text-input>
              <bh-text-input [control]="$any(streetNumber)"
                             [label]="'general.address.number'|translate">
              </bh-text-input>
              <bh-text-input [control]="$any(postalCode)"
                             [label]="'general.address.zipcode'|translate">
              </bh-text-input>
              <bh-text-input [control]="$any(city)"
                             [label]="'general.address.city'|translate">
              </bh-text-input>

              <div formGroupName="location" style="display: none;">
                <mat-form-field><input matInput formControlName="lat"></mat-form-field>
                <mat-form-field><input matInput formControlName="lon"></mat-form-field>
              </div>

              <ng-container *ngIf="!noLocationType() && !stock">
                <mat-checkbox color="primary" *ngIf="hasModule(modules.AUTOMATED_TRANSFERS) && hasAuthority(authorities.GEOFENCE_MANAGE)"
                              [checked]="isStockGeofence"
                              (click)="changeGeofenceStatus()"
                              [matTooltip]="'modules.disposition.stockAddEdit.selectedGeoDiameter200m'|translate">
                  {{'modules.disposition.projectAddEdit.autoGeo'|translate}}
                </mat-checkbox>
              </ng-container>

              <div class="error"
                   *ngIf="stockForm.get('address').hasError('notResolved') && stockForm.get('address').dirty">
                {{'modules.disposition.stockAddEdit.addressCannotBeResolved'|translate}}
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between stretch">
          <div class="button-wrapper" fxLayoutGap="15px">
            <button fxLayoutAlign="start center"
                    mat-raised-button
                    matStepperPrevious>
              {{'general.buttons.back'|translate}}
            </button>
          </div>
          <div class="button-wrapper" fxLayoutGap="15px">
            <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</bh-step-dialog>

<ng-template #saveButton>
  <button fxLayoutAlign="end center"
    color="primary"
    mat-raised-button
    (click)="save()"
    [disabled]="!isValid() || saveInProgress"
    data-osp-test="button-save">
    {{'general.buttons.save'|translate}}
  </button>
</ng-template>
