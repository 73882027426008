import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bh-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {

  @Input()
  public value: BehaviorSubject<boolean>;
  @Input()
  public tooltip: string;
  @Input()
  public static: boolean;

  public toggle(): void {
    this.value.next(this.static ? this.value.value : !this.value.value);
  }
}
