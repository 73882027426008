<mat-form-field appearance="outline"
  class="inline-search on-top"
  color="primary"
  *ngIf="showSearchBar">
  <mat-label class="on-top">{{'shared.basicMap.findAddress'|translate}}</mat-label>
  <input matInput type="text"
    class="on-top"
    disabled
    (keydown.enter)="$event.preventDefault()"
    autocapitalize="off"
    spellcheck="false"
    #search>
</mat-form-field>

<bh-speed-dial *ngIf="clusteredMap"
  (buttonToggled)="onSpeedDial($event)"
  [showRefreshButton]="showRefreshButton"
  [showSettingsButton]="showSettingsButton"
  (buttonRefresh)="onSpeedDialRefresh()"
  [initialStates]="mapSettings | speedDialStates">
</bh-speed-dial>

<div class="geofences-container on-top"
  *ngIf="canSeeGeofences"
  [ngClass]="{ 'hidden' : !showGeofenceSidebar }">
  <bh-geofence-sidebar
    [map]="clusteredMap?.map"
    [createGeofenceEnabled]="canManageGeofence"
    [deleteGeofenceEnabled]="canManageGeofence"
    [manageAlarmsEnabled]="false"
    [displayAlarmIndicators]="true"
    [tooltipTextAdd]="'shared.geofence.addGlobalGeofence'|translate"
    [tooltipTextDelete]="'shared.geofence.removeGlobalGeofence'|translate"
    (onCreate)="onCreateGeofence($event)"
    (onDelete)="onDeleteGeofence($event)">
  </bh-geofence-sidebar>
</div>


<bh-map-clustered #mapClustered fxFill
  (mapInitialized)="mapInitialized(mapClustered)"
  [clusters]="[
    (
      [] | concatArrays
      : (equipmentLocations | mapMarkersConvertEquipmentLocations : mapSettings)
      : (projectLocations | mapMarkersConvertProjectLocations : mapSettings)
      : (organisationLocations | mapMarkersConvertOrganisationLocations : mapSettings)
      : (stockLocations | mapMarkersConvertStockLocations : mapSettings)
      : (contactLocations | mapMarkersConvertContactLocations : mapSettings)
    )
    | mapClusterGlobal
  ]"
  [gridSize]="mapSettings?.gridSize"
  [disableAutoPan]="false"
  [enableLabels]="true"
  (markerClicked)="onMarkerClick($event)"
  (clusterClicked)="onMarkerClick($event)">
</bh-map-clustered>
