<mat-card fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card-header class="report-card-header">
    <div class="report-card-header__text">{{'modules.reports.reportGeneration.availableReports'|translate|uppercase}}</div>
  </mat-card-header>

  <mat-card-content fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
    <mat-list fxFlex role="list" class="report-list">
      <mat-list-item *ngFor="let rd of reportDefinitions">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
          <div class="list-item__title">{{rd.reportDefinitionName}}</div>
          <div class="list-item__icon"
            (click)="navigateToGenerate(rd.reportDefinitionId)"
            [matTooltip]="'modules.reports.base.runReport'|translate">
            <mat-icon class="bh-mat-icon">settings_applications</mat-icon>
          </div>
        </div>

        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>

    <div class="report-card-footer">
      <span>{{reportDefinitions.length}}</span>
        {{'modules.reports.reportGeneration.reportsAvailable'|translate}}
    </div>
  </mat-card-content>
</mat-card>
