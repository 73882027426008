import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MobileSwipeOptionComponent } from './mobile-swipe-option/mobile-swipe-option.component';

enum SwipeToRevealOptionsMovingDirections {
  NONE = 'NONE',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

@Component({
  selector: 'bh-mobile-swipe-to-options',
  templateUrl: './mobile-swipe-to-options.component.html',
  styleUrls: ['./mobile-swipe-to-options.component.scss']
})
export class MobileSwipeToOptionsComponent implements OnInit, OnDestroy {

  @ContentChildren(MobileSwipeOptionComponent) private mobileSwipeOptionComponents: MobileSwipeOptionComponent[];
  @ViewChild('wrapper', { static: true }) private wrapper: ElementRef<HTMLDivElement>;

  private touchStartPosX = 0;
  private touchStartPosY = 0;

  private startPosition = 0;
  private touching = false;
  private touchStartTime = 0;
  private leftBoundary = 0;
  private leftAnimationBorder = 0;
  private velocity = 0;
  private oldTimeStamp = 0;

  private movingDirection: SwipeToRevealOptionsMovingDirections = SwipeToRevealOptionsMovingDirections.NONE;
  private lastPosition = 0;

  private position = 0;
  private swiping = false;

  private touchMoveEventOffset = 0;

  @Input() public swipeLimitInPercent = 60;
  @Input() public disableSwiping: boolean;
  @Input() public overScroll = true;

  @Output() public swipeAction: EventEmitter<void> = new EventEmitter<void>();
  // is only being triggered if `disabledSwiping = true`
  @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();

  public onWrapperClicked(): void {
    if (this.disableSwiping) {
      this.onClick.emit();
    }
  }

  public ngOnInit(): void {
    this.wrapper.nativeElement.addEventListener('touchstart', this.touchStart.bind(this), {passive: true});
    this.wrapper.nativeElement.addEventListener('touchmove', this.touchMove.bind(this), {passive: true});
    this.wrapper.nativeElement.addEventListener('touchend', this.touchEnd.bind(this), {passive: true});
  }

  public ngOnDestroy(): void {
    this.wrapper.nativeElement.removeEventListener('touchstart', this.touchStart.bind(this));
    this.wrapper.nativeElement.removeEventListener('touchmove', this.touchMove.bind(this));
    this.wrapper.nativeElement.removeEventListener('touchend', this.touchEnd.bind(this));
  }

  private touchStart(e: TouchEvent): void {
    if (!this.disableSwiping) {
      // this.optionWrapperChildren = <NodeListOf<HTMLDivElement>>this.optionWrapper.nativeElement.querySelectorAll('.option');
      this.swiping = false;
      this.touching = true;
      this.touchMoveEventOffset = 0;
      // I recalculate the leftBoundary in case the size changes for some reason. In Web this can happen easily.
      // E.g. landscape mode to portrait mode or vice versa.
      this.leftAnimationBorder = 0 - (this.wrapper.nativeElement.clientWidth / 100 * this.swipeLimitInPercent);
      this.leftBoundary = 0 - this.wrapper.nativeElement.clientWidth;

      // if we have no primary button, then we do not want to use swip-to-action

      this.touchStartPosX = e.touches[0].clientX;
      this.touchStartPosY = e.touches[0].clientY;
      this.startPosition = this.position;
      this.touchStartTime = e.timeStamp;
    }
  }

  private touchMove(e: TouchEvent): void {
    if (!this.disableSwiping) {
      // Here I need to check if the user is still touching. Why?
      // I use passive EventListeners (performance reasons). These Listeners will be called at a later point (they
      // are deferred). This can cause the effect, that we have `touchmove` events after the `touchend` Event. These
      // `touchmove` events would mess with the animationLoop
      if (this.touching) {
        this.touchMoveEventOffset++;
        if (this.touchMoveEventOffset === 1) {
          let leftDifference = Math.abs(this.touchStartPosX - e.touches[0].clientX);
          let rightDifference = Math.abs(e.touches[0].clientX - this.touchStartPosX);
          let swipe = Math.max(leftDifference, rightDifference);

          let upDifference = Math.abs(this.touchStartPosY - e.touches[0].clientY);
          let downDifference = Math.abs(e.touches[0].clientY - this.touchStartPosY);
          let scroll = Math.max(upDifference, downDifference);

          this.swiping = swipe > scroll;
        }
        if (this.touchMoveEventOffset < 4 || this.swiping === false) {
          return;
        }
        let difference = e.touches[0].clientX - this.lastPosition;
        if (difference > 0 && this.movingDirection !== SwipeToRevealOptionsMovingDirections.RIGHT) {
          this.movingDirection = SwipeToRevealOptionsMovingDirections.RIGHT;
          this.movingDirectionChanged(e);
        } else if (difference < 0 && this.movingDirection !== SwipeToRevealOptionsMovingDirections.LEFT) {
          this.movingDirection = SwipeToRevealOptionsMovingDirections.LEFT;
          this.movingDirectionChanged(e);
        }

        let calculatedPosition = (e.touches[0].clientX - this.touchStartPosX) + this.startPosition;

        this.applyStyleToElement(calculatedPosition);
        this.lastPosition = e.touches[0].clientX;
      }
    }
  }

  private touchEnd(e: TouchEvent): void {
    if (!this.disableSwiping) {
      this.startAutomaticAnimation(this.startPosition, this.position, this.touchStartTime, e.timeStamp);
      this.touching = false;
      this.touchStartPosX = 0;
      this.touchStartPosY = 0;
      this.startPosition = 0;
      this.touchStartTime = 0;
    }
  };

  private movingDirectionChanged(e: TouchEvent): void {
    this.touchStartTime = e.timeStamp;
    this.touchStartPosX = e.touches[0].clientX;
    this.startPosition = this.position;
  }

  // Animated Throw

  private startAutomaticAnimation(oldPosX: number, newPosX: number, startTime: number, endTime: number): void {
    let calculatedVelocity: number = (newPosX - oldPosX) / (endTime - startTime);

    // I set the minimum velocity for both directions to 0.5. Like this, even if the User throws the Component
    // slightly to any side, he will still have a normal and fast transition. The transition with 0.5 velocity is
    // not to slow, the animation speed feels normal.
    if (calculatedVelocity < 0 && newPosX > this.leftAnimationBorder) {
      this.velocity = Math.min(calculatedVelocity, -0.5);
    } else {
      if (newPosX < this.leftAnimationBorder) {
        this.swipeAction.emit();
      }
      this.velocity = Math.max(calculatedVelocity, 0.5);
    }
    window.requestAnimationFrame(this.animationFrameLoop.bind(this));
  }

  private animationFrameLoop(timestamp: number): void {
    if (this.touching === false
        && this.velocity !== 0
        && (this.position < 0
            && (this.position > this.leftAnimationBorder || this.velocity > 0))) {

      if (this.oldTimeStamp !== 0) {
        let positionChange = (timestamp - this.oldTimeStamp) * this.velocity;
        this.applyStyleToElement(this.position + positionChange);
      }
      this.oldTimeStamp = timestamp;
      window.requestAnimationFrame(this.animationFrameLoop.bind(this));
    } else {
      if (this.touching === false && this.velocity < 0) {
        this.applyStyleToElement(this.leftAnimationBorder);
      } else if (this.touching === false && this.velocity > 0) {
        this.applyStyleToElement(0);
      }
      this.oldTimeStamp = 0;
    }
  }

  private applyStyleToElement(position: number): void {
    this.position = this.restrictMovementArea(position);
    this.mobileSwipeOptionComponents.forEach((mobileSwipeOptionComponent: MobileSwipeOptionComponent, index: number) => {
      mobileSwipeOptionComponent.currentPosition = Math.abs(this.position);
      mobileSwipeOptionComponent.index = index;
    });
    this.wrapper.nativeElement.style.transform = `translate3d(${this.position}px, 0, 0)`;
  };

  private restrictMovementArea(calculated: number): number {
    let restrictMovementToRight = Math.min(calculated, 0);
    return Math.max(this.overScroll ? this.leftBoundary : this.leftAnimationBorder, restrictMovementToRight);
  }


}
