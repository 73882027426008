import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Querystring from 'querystring'
import { ViewRole } from '../contract/view-role.interface';
import { DeleteRoleCommand } from '../contract/delete-role-command';
import { CreateRoleCommand } from '../contract/create-role-command';
import { UpdateRoleCommand } from '../contract/update-role-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { getHttpParameters } from 'app/shared/utils';

@Injectable()
export class RolesService {

  private url = environment.APP_USERROLE_SERVICE_BASE_URL + '/api/v1/roles';
  private byCustomer = 'by-customer';

  constructor(private http: HttpClient) {
  }

  public getRoles(page?: number, size?: number): Observable<PagedResponse<ViewRole>> {
    let urlParams = Querystring.stringify({page, size});
    const pagedUrl = this.url + '?' + urlParams;

    return this.http.get<PagedResponse<ViewRole>>(pagedUrl);
  }

  public getRolesByUserCustomer(): Observable<ViewRole[]> {
    return this.http.get<ViewRole[]>(this.url + '/' + this.byCustomer);
  }

  public getRolesByCustomer(customerId: string): Observable<ViewRole[]> {
    return this.http.get<ViewRole[]>(this.url + '/' + this.byCustomer + `/${customerId}`);
  }

  public getRolesByPartner(partnerId: string): Observable<ViewRole[]> {
    return this.http.get<ViewRole[]>(this.url + `/by-partner/${partnerId}`);
  }

  public getRole(userId: string): Observable<ViewRole> {
    return this.http.get<ViewRole>(this.url + '/' + userId);
  }

  public addRole(command: CreateRoleCommand): Observable<string> {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateRole(command: UpdateRoleCommand): Observable<string> {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  public getIsBrzRole(roleId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/owned-by-brz-customer/${roleId}`);
  }

  public deleteRole(command: DeleteRoleCommand): Observable<string> {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public roleNameInUse(roleName: string, realmType: string): Observable<boolean> {
    const httpParams = getHttpParameters({ name: roleName, realmType: realmType });

    return this.http.get<boolean>(this.url + '/name-in-use', { params: httpParams });
  }
}
