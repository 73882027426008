import { RouterModule, Routes } from '@angular/router';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { CanActivateChildMobile } from '../../../core/guards/can-activate-child-mobile';
import { ModuleWithProviders } from '@angular/core';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { Modules } from '../../../shared/enums/modules.enum';
import { MobileChatListComponent } from '../chat-list/mobile/mobile-chat-list.component';
import { MobileChatComponent } from '../chat/mobile/mobile-chat.component';
import { MobileChatContainerComponent } from '../mobile-chat.container.component';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { CanActivateChildMobileWebApp } from 'app/core/guards/can-activate-child-mobile-web-app.guard';

const mobileChatRoutes: Routes = [
  {
    path: 'mobile',
    children: [
      {
        path: 'chat',
        component: MobileChatContainerComponent,
        canActivate: [CanActivateModule],
        canActivateChild: [CanActivateChildAuthority, CanActivateChildMobile, CanActivateChildMobileWebApp],
        data: {
          modules: [Modules.COMMUNICATION],
          authorities: [Authorities.MESSAGE_CENTER_USE]
        },
        children: [
          {
            path: '',
            component: MobileChatListComponent,
          },
          {
            path: ':id',
            component: MobileChatComponent,
            data: {
              authorities: [Authorities.MESSAGE_CENTER_USE]
            },
          }
        ]
      }
    ]
  }
];

export const mobileChatRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(mobileChatRoutes);
