import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { FormArray, FormControl } from '@angular/forms';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { RouterHistory } from '../../../../../../shared/router-history';
import { FieldLimit } from '../../../../../../shared/enums/fieldLimit.enum';
import {
  EquipmentAdditionalFieldService,
} from 'app/modules/equipment/shared/services/equipment-additional-fields.service';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  GuardedNavigableInputComponent,
} from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { BehaviorSubject, EMPTY, Observable, concat, filter, map, switchMap } from 'rxjs';
import {
  AdditionalFieldAssignmentDialogComponent,
} from '../../../../../../shared/components/additional-field-assignment-dialog/additional-field-assignment-dialog.component';
import {
  AdditionalField,
  AdditionalFieldValue,
} from '../../../../../osp-ui/rich-components/bh-additional-field/additional-field';
import {
  AdditionalFieldsEquipmentUnassignCommand,
} from '../../../../contract/additional-fields-equipment-unassign.command';
import _ from 'lodash';
import {
  AdditionalFieldsEquipmentUpdateCommand,
} from '../../../../contract/update-equipment-additional-fields.command';
import {
  AdditionalFieldsEquipmentAssignCommand,
} from '../../../../contract/additional-fields-equipment-assign.command';
import { TranslatableStringPipe } from '../../../../../osp-ui/pipes/translatable-string/translatable-string.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-technical-field',
  templateUrl: './equipment-view-technical-field.component.html',
  styleUrls: ['./equipment-view-technical-field.component.scss'],
})
export class EquipmentViewTechnicalFieldComponent extends GuardedNavigableInputComponent implements OnInit {

  public readonly fieldLimit = FieldLimit;

  public fieldsFormArray = new FormArray<FormControl<AdditionalFieldValue>>([]);
  public searchControl = new FormControl<string>('');
  public editMode = false;
  public loading = false;

  protected equipment: ViewEquipment;
  protected _isActive = new BehaviorSubject<boolean>(false);
  protected isActive = this._isActive.asObservable();

  private beforeEditState: AdditionalFieldValue[] = [];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private equipmentStore: EquipmentsDataSource,
              private equipmentCheckerService: EquipmentCheckerService,
              private equipmentAdditionalFieldService: EquipmentAdditionalFieldService,
              private translatableStringPipe: TranslatableStringPipe,
              private dialog: MatDialog) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToEquipment();
  }

  public add(): void {
    this.equipmentAdditionalFieldService.getAllAdditionalFields()
    .pipe(
      map(availableFields => {
        const assignedFieldsIds = new Set(this.fieldsFormArray.value.map(({additionalFieldId}) => additionalFieldId));
        return availableFields.filter(({additionalFieldId}) => !assignedFieldsIds.has(additionalFieldId));
      }),
      switchMap(data => this.dialog.open(
          AdditionalFieldAssignmentDialogComponent,
          {data, autoFocus: false},
        ).afterClosed(),
      ),
      filter((result: AdditionalField[]) => result?.length > 0))
    .subscribe((result: AdditionalField[]) => this.assignNewFields(result));
  }

  public remove(index: number): void {
    this.fieldsFormArray.removeAt(index);
  }

  public setEditMode(enabled: boolean): void {
    this.editMode = enabled;
    if (this.editMode) {
      this.beforeEditState = _.cloneDeep(this.fieldsFormArray.value);
    }
  }

  public cancel(): void {
    this.setEditMode(false);
    this.setFieldFormArray(this.beforeEditState);
    this.beforeEditState = [];
  }

  public save(): void {
    const currentFieldIds = new Set(this.fieldsFormArray.value.map(({ additionalFieldId }) => additionalFieldId));
    const unassignedFields = this.beforeEditState
      .filter(({ additionalFieldId }) => !currentFieldIds.has(additionalFieldId));

    this.loading = true;
    concat(
      this.updateFields(this.fieldsFormArray.value),
      (unassignedFields.length > 0 ? this.unassignFields(unassignedFields) : EMPTY)
    )
    .subscribe({
      next: () => this.setEditMode(false),
      complete: () => this.loading = false,
      error: () => this.loading = false,
    })
  }

  private updateFields(fields: AdditionalFieldValue[]): Observable<string> {
    const additionalFields: Record<string, string> = Object.assign(
      {},
      ...fields.map(({ additionalFieldId, value }) => ({ [additionalFieldId]: value })),
    );

    const command: AdditionalFieldsEquipmentUpdateCommand = {
      equipmentId: this.equipment.equipmentId,
      additionalFields,
    };
    return this.equipmentAdditionalFieldService.updateFieldValues(command);
  }

  private unassignFields(fields: AdditionalFieldValue[]): Observable<string> {
    const additionalFieldIds = fields.map(({ additionalFieldId }) => additionalFieldId);

    const command: AdditionalFieldsEquipmentUnassignCommand = {
      equipmentId: this.equipment.equipmentId,
      additionalFieldIds,
    };
    return this.equipmentAdditionalFieldService.unassignFields(command);
  }

  private subscribeToEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(untilDestroyed(this))
    .subscribe(currentEquipment => {
      if (currentEquipment) {
        this.equipment = currentEquipment;
        this._isActive.next(this.isActiveEquipment());
        this.cancel();
        this.loadAssignedFields(currentEquipment);
        this.loading = false;
      }
    });
  }

  private isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  private loadAssignedFields(equipment: ViewEquipment): void {
    this.equipmentAdditionalFieldService.getAssignedFields(equipment?.equipmentId)
      .pipe(map(fields => fields.sort((a, b) => this.compareAdditionalFieldByName(a, b))))
      .subscribe(fields => this.setFieldFormArray(fields));
  }

  private setFieldFormArray(fields: AdditionalFieldValue[]): void {
    this.fieldsFormArray = new FormArray(fields.map(f => new FormControl(f)));
  }

  private assignNewFields(fields: AdditionalField[]): void {
    this.loading = true;
    const command: AdditionalFieldsEquipmentAssignCommand = {
      equipmentId: this.equipment.equipmentId,
      additionalFieldIds: fields.map(({additionalFieldId}) => additionalFieldId).filter(Boolean),
    };
    this.equipmentAdditionalFieldService.assignFields(command)
    .subscribe({
      next: () => {
        const assignedFields = [
          ...this.fieldsFormArray.value,
          ...fields.map(f => ({...f, value: f.additionalFieldDefaultValue})),
        ];
        this.setFieldFormArray(assignedFields);
      },
      complete: () => this.loading = false,
    });
  }

  private compareAdditionalFieldByName(a: AdditionalFieldValue, b: AdditionalFieldValue): number {
    const aName = this.translatableStringPipe.transform(a.additionalFieldName) ?? a.additionalFieldKey ?? '';
    const bName = this.translatableStringPipe.transform(b.additionalFieldName) ?? b.additionalFieldKey ?? '';
    return aName.localeCompare(bName);
  }
}
