import { LanguageService } from 'app/shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteProjectDocumentCommand } from '../../../../../contract/delete-project-document-command';
import { ProjectCheckerService } from '../../../../../shared/services/project-checker.service';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { combineLatest } from 'rxjs';
import { DocuwareDocument } from '../../../../../../../shared/contract/docuware-document.interface';
import { TopfactDocument } from '../../../../../../../shared/contract/topfact-document.interface';
import { ConnectorDocument } from '../../../../../../../shared/contract/connector-document';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProjectStructureType } from 'app/modules/disposition/shared/enums/project-structure-type';
import { UpdateProjectDocumentDescriptionCommand } from '../../../../../contract/update-project-document-description-command';
import { UpdateProjectDocumentNameCommand } from '../../../../../contract/update-project-document-name-command';

@UntilDestroy()
@Directive()
export abstract class BaseProjectViewAttachmentsComponent extends GuardedNavigableInputComponent implements OnInit {

  public readonly faSortAlphaDown: IconDefinition = faSortAlphaDown;

  public readonly fileName: string = 'fileName';
  public readonly fileDescription: string = 'fileDescription';
  public documents: DocumentLink[];
  public images: DocumentLink[];
  public docuwareDocuments: DocuwareDocument[];
  public topfactDocuments: TopfactDocument[];
  public projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  public canUpdateDocuments = false;
  public canDeleteDocuments = this.authService.hasAnyAuthority([Authorities.PROJECT_UPDATE,
    Authorities.PROJECT_DELETE_ATTACHMENTS]);
  public hasConnector = false;
  public hasTopfactConnector = false;
  public docuwareServiceAvailable = true;
  public topfactServiceAvailable = true;
  public docuwareRequestPending: boolean;
  public topfactRequestPending: boolean;
  public pendingDocuwareDownloads: DocuwareDocument[] = [];
  public pendingTopfactDownloads: TopfactDocument[] = [];
  public documentsSortParameter: string;
  public waiting = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected userConfigurationService: UserConfigurationService,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService,
              public matSnackBar: MatSnackBar) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.projectStore.currentViewTab = 'attachments';
    this.projectStore.updateProjectDocuwareConnectorExists();
    this.projectStore.updateProjectTopfactConnectorExists();
    this.documentsSortParameter = this.getDocumentsSortParameter();
    this.initListeners();
  }

  public deleteDocument(objectKey: string, isImage = false): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.documentDelete');
    dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: string) => {
        if (result === dialogResults.YES) {
          this.waiting = true;
          let cmd: DeleteProjectDocumentCommand = new DeleteProjectDocumentCommand();
          cmd.projectId = this.project.projectId;
          cmd.objectKey = objectKey;
          this.projectStore.deleteDocument(cmd)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.removeDocumentFromList(objectKey, isImage);
              this.waiting = false;
            });
        }
      });
  }

  public getFileTypeIcon(document: DocumentLink): IconDefinition {
    return FileUtils.getAttachmentFileTypeIcon(document);
  }

  public downloadDocuwareDocument(docuwareDocument: DocuwareDocument) {
    this.pendingDocuwareDownloads.push(docuwareDocument);
    this.projectStore.getDocuwareDocument(this.project.projectId, docuwareDocument.id).subscribe(blob => {
      this.handleBlob(blob, docuwareDocument);
      const index = this.pendingDocuwareDownloads.indexOf(docuwareDocument);
      if (index > -1) {
        this.pendingDocuwareDownloads.splice(index, 1);
      }
    });
  }

  public downloadTopfactDocument(document: TopfactDocument) {
    this.pendingTopfactDownloads.push(document);
    this.projectStore.getTopfactDocument(document.docId, document.fileId)
      .pipe(untilDestroyed(this))
      .subscribe((blob: Blob) => {
        this.handleBlob(blob, document);
        const index = this.pendingTopfactDownloads.indexOf(document);
        if (index > -1) {
          this.pendingTopfactDownloads.splice(index, 1);
        }
      }, () => {
        this.matSnackBar.open(
          this.translate('shared.attachment.topfactDownloadFailed'),
          this.translate('general.buttons.okay'),
          { duration: 3000 });
        const index = this.pendingTopfactDownloads.indexOf(document);
        if (index > -1) {
          this.pendingTopfactDownloads.splice(index, 1);
        }
      });
  }

  private removeDocumentFromList(documentKey: string, isImage = false): void {
    const docProp = isImage ? 'images' : 'documents';
    this[docProp] = (this[docProp] || []).filter(({ documentKey: docId }) => docId !== documentKey);
  }

  private initListeners(): void {
    this.initListenerCurrentProject();
    this.initListenerDocuments();

    this.checkDocuwareConnectorExistsAndGetDocuments();
    this.checkTopfactConnectorExistsAndGetDocuments()
  }

  private initListenerCurrentProject(): void {
    this.projectStore.currentProject
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewProject) => {
      if (res && res.projectStructureType !== ProjectStructureType.PROJECT_GROUP) {
        this.projectStore.getDocuments(this.project.projectId);
        this.canUpdateDocuments =
          this.authService.hasAnyAuthority([
            Authorities.PROJECT_UPDATE,
            Authorities.PROJECT_ADD_ATTACHMENTS
          ]) &&
          !this.projectCheckerService.isProjectFinished(res);
      }
    });
  }

  private initListenerDocuments(): void {
    this.projectStore.documents
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.sortDocumentLinkList(res, this.documentsSortParameter);
        const { documents, images } = FileUtils.splitDocumentLinks(res);
        this.documents = documents;
        this.images = images;
      });
  }

  private checkDocuwareConnectorExistsAndGetDocuments(): void {
    this.initListenerDocuwareRequestPending();
    this.initListenerDocuwareAvailable();
    this.initListenerDocuwareHasConnector();
    this.initListenerDocuwareDocuments();
  }

  private checkTopfactConnectorExistsAndGetDocuments() {
    this.initListenerTopfactRequestPending();
    this.initListenerTopfactAvailable();
    this.initListenerTopfactHasConnector();
    this.initListenerTopfactDocuments();
  }

  private initListenerDocuwareRequestPending(): void {
    this.projectStore.docuwareRequestPending
      .pipe(untilDestroyed(this))
      .subscribe(pending => this.docuwareRequestPending = pending);
  }

  private initListenerDocuwareAvailable(): void {
    this.projectStore.docuwareServiceAvailable
      .pipe(untilDestroyed(this))
      .subscribe(available => this.docuwareServiceAvailable = available);
  }

  private initListenerDocuwareHasConnector(): void {
    combineLatest([this.projectStore.currentProject, this.projectStore.hasProjectDocuwareConnector])
      .pipe(untilDestroyed(this))
      .subscribe(([project, hasConnector]) => {
        this.hasConnector = hasConnector;
        if (project && hasConnector) {
          this.projectStore.getDocuwareDocuments(project.projectId);
        }
      });
  }

  private initListenerDocuwareDocuments(): void {
    this.projectStore.docuwareDocuments
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.docuwareDocuments = res;
        this.sortDocuwareDocumentList(this.docuwareDocuments, this.documentsSortParameter);
      });
  }

  private initListenerTopfactRequestPending(): void {
    this.projectStore.topfactRequestPending
      .pipe(untilDestroyed(this))
      .subscribe(pending => this.topfactRequestPending = pending);
  }

  private initListenerTopfactAvailable(): void {
    this.projectStore.topfactServiceAvailable
    .pipe(untilDestroyed(this))
    .subscribe((available: boolean) => this.topfactServiceAvailable = available);
  }

  private initListenerTopfactHasConnector(): void {
    combineLatest([this.projectStore.currentProject, this.projectStore.hasProjectTopfactConnector])
      .pipe(untilDestroyed(this))
      .subscribe(([project, hasConnector]) => {
        this.hasTopfactConnector = hasConnector;
        if (project
          && project.projectStructureType !== ProjectStructureType.PROJECT_GROUP
          && hasConnector) {
          this.projectStore.getTopfactDocuments(project.projectId);
        }
      });
  }

  private initListenerTopfactDocuments(): void {
    this.projectStore.topfactDocuments
    .pipe(untilDestroyed(this))
    .subscribe((res: TopfactDocument[]) => {
      this.topfactDocuments = res;
      this.sortTopfactDocumentList(this.topfactDocuments, this.documentsSortParameter);
    });
  }

  private handleBlob(blob: Blob, document: ConnectorDocument): void {
    if (blob) {
      let documentBlob = new Blob([blob], {type: this.resolveContentType(document.contentType)});
      const fileURL = URL.createObjectURL(documentBlob);
      window.open(fileURL, '_blank');
    }
  }

  private resolveContentType(contentType: string) {
    if (contentType.includes('/')) {
      return contentType;
    } else if (contentType.includes('png') || contentType.includes('jpg') || contentType.includes('jpeg')) {
      return 'image/' + contentType;
    } else if (contentType.includes('xls')) {
      return 'application/vnd.ms-excel';
    } else if (contentType.includes('xlsx')) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (contentType.includes('doc')) {
      return 'application/msword';
    } else if (contentType.includes('docx')) {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (contentType.includes('odt')) {
      return 'application/vnd.oasis.opendocument.text';
    } else if (contentType.includes('ods')) {
      return 'application/vnd.oasis.opendocument.spreadsheet';
    } else if (contentType.includes('pdf')) {
      return 'application/' + contentType;
    } else {
      return contentType;
    }
  }

  public get project(): ViewProject {
    return this.projectStore.project();
  }

  public saveFileName(updatedDocument: DocumentLink): void {
    const cmd = new UpdateProjectDocumentNameCommand(
        this.project.projectId,
        updatedDocument.documentKey,
        updatedDocument.fileName,
    );
    this.projectStore.updateDocumentName(cmd);
  }

  public saveFileDescription(updatedDocument: DocumentLink): void {
    const cmd = new UpdateProjectDocumentDescriptionCommand(
        this.project.projectId,
        updatedDocument.documentKey,
        updatedDocument.fileDescription
    );
    this.projectStore.updateDocumentDescription(cmd);
  }

  public getDocumentsSortParameter(): string {
    if (this.userConfigurationService.getDocumentsSortConfiguration()) {
      return this.userConfigurationService.getDocumentsSortConfiguration().sortParameter;
    }
    return this.fileName;
  }

  public changeDocumentsSortParameter(sortParam: string): void {
    this.documentsSortParameter = sortParam;
    this.userConfigurationService.saveDocumentsSortConfiguration(sortParam);
    this.sortDocumentLinkList(this.documents, sortParam);
    this.sortDocumentLinkList(this.images, sortParam);
    this.sortDocuwareDocumentList(this.docuwareDocuments, sortParam);
  }

  public getFileName(filename: string): string {
    return filename.substring(0, filename.lastIndexOf('.'));
  }

  public getFileNameExtension(filename: string): string {
    return filename.substring(filename.lastIndexOf('.'), filename.length);
  }

  private sortDocumentLinkList(documentList: DocumentLink[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.fileName.localeCompare(b.fileName);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (a.fileDescription === '') {
          return 1;
        }
        if (b.fileDescription === '') {
          return -1;
        }
        return a.fileDescription.localeCompare(b.fileDescription);
      });
    }
  }

  private sortDocuwareDocumentList(documentList: DocuwareDocument[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.id.localeCompare(b.id);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (a.title === '') {
          return 1;
        }
        if (b.title === '') {
          return -1;
        }
        return a.title.localeCompare(b.title);
      });
    }
  }

  private sortTopfactDocumentList(documentList: TopfactDocument[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.fileId.toString().localeCompare(b.fileId);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (a.title === '') {
          return 1;
        }
        if (b.title === '') {
          return -1;
        }
        return a.title.toString().localeCompare(b.title);
      });
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
