<div fxFlexFill class="list-view" fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card fxFlex>
    <mat-card-content fxFlexFill fxLayout="column">
      <bh-map-global
        [mapSettings]="mapSettings"
        [showSearchBar]="true"
        (mapSettingsChanges)="saveMapConfigs($event)">
      </bh-map-global>
    </mat-card-content>
  </mat-card>
</div>
