<div class="chat-component" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon *ngIf="addUserListVisible" fxFlex="10" (click)="closeUserList()">
      keyboard_arrow_left
    </mat-icon>
    <span *ngIf="addUserListVisible" fxFlex="80">{{'modules.communication.chat.startNewChat'|translate}}</span>
    <span *ngIf="!addUserListVisible" fxFlex="100">{{'modules.communication.chat.myChats'|translate}}</span>
  </div>
  <div class="chat-list" fxFlex>
    <div *ngIf="!(onlineStatusService.isOnline | async)" class="no-data-info-text">
      <p>{{'modules.communication.chat.noInternetConnection'|translate}}</p>
    </div>
    <div *ngIf="!addUserListVisible">
      <mat-list>
        <mat-list-item *ngFor="let chat of communicationDataSource.chats | async" (click)="goToChat(chat.chatPartnerId)">
          <div fxLayout="row" fxLayoutAlign="end center" fxFill>
            <div fxLayout="column" fxFlex="90">
              <div class="chat-list__chat-title">
                <i>{{chat.chatPartnerStatus | ChatUserStatus}}</i>{{chat.chatPartnerFullName}}
              </div>
              <div class="chat-message">{{ chat.lastMessage }}</div>
            </div>
            <div fxFlex="10" fxLayout="column" fxLayoutAlign="end center">
              <div class="chat-list__unread-count" *ngIf="chat.unreadCount > 0">
                {{ chat.unreadCount }}
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div *ngIf="addUserListVisible">
      <mat-list>
        <mat-list-item *ngFor="let user of suggestedChatUsers | async" (click)="goToChat(user.userId)">
          <p>{{ user.firstName }} {{ user.name }}</p>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="chat-component_add-contact" *ngIf="!addUserListVisible">
    <button mat-raised-button (click)="openUserList()">
      {{'modules.communication.chat.addAnotherChat'|translate}}
    </button>
  </div>
  <div class="chat-component_add-contact" *ngIf="addUserListVisible">
    <button mat-raised-button (click)="closeUserList()">
      {{'general.buttons.back'|translate}}
    </button>
  </div>
</div>
