import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ServiceHealthService } from '../service/service-health.service';
import { timer } from 'rxjs';
import { ServiceHealthResponse } from '../interface/service-health-response.interface';

@UntilDestroy()
@Component({
  selector: 'bh-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent implements OnInit, OnDestroy {

  isServiceUp = false;
  private readonly HEALTH_RESPONSE = 'UP';
  private readonly LOOP_DELAY = 2000;
  private offlineCount = 0;

  @Input()
  serviceName: string;

  @Input()
  serviceUrl: string;

  constructor(private serviceHealthService: ServiceHealthService) { }

  ngOnInit(): void {
    timer(0, this.LOOP_DELAY).pipe(untilDestroyed(this)).subscribe(() => {
      this.offlineCount++;
      if (this.offlineCount > 2) {
        this.isServiceUp = false;
      }
      this.getHealth();
    });
  }

  ngOnDestroy(): void { }

  private getHealth(): void {
    this.serviceHealthService.getServiceHealth(this.serviceUrl).pipe(untilDestroyed(this)).subscribe((res: ServiceHealthResponse) => {
      if (res && res.status && res.status === this.HEALTH_RESPONSE) {
        this.offlineCount = 0;
        this.isServiceUp = true;
      } else {
        this.isServiceUp = false;
      }
    }, () => { this.isServiceUp = false; });
  }
}
