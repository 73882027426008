<div class="dialog" *ngIf="partner">
  <div class="dialog-view-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title">{{partner.partnerName}}</span>

    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <mat-tab-group>

    <mat-tab [label]="'general.partner.s'|translate">

      <div class="technical-field-list" fxLayout="row" fxLayoutAlign="space-between stretch">

        <div>
          <label>{{'general.name.s'|translate}}</label>
          <p>{{partner.partnerName}}</p>
        </div>

        <div>
          <label>{{'general.address.street'|translate|uppercase}}</label>
          <p>{{partner.partnerAddress.street}}</p>
        </div>

        <div>
          <label>{{'general.address.houseNumber'|translate|uppercase}}</label>
          <p>{{partner.partnerAddress.streetNumber}}</p>
        </div>

        <div>
          <label>{{'general.address.postCode'|translate|uppercase}}</label>
          <p>{{partner.partnerAddress.postalCode}}</p>
        </div>

        <div>
          <label>{{'general.address.city'|translate|uppercase}}</label>
          <p>{{partner.partnerAddress.city}}</p>
        </div>


      </div>

    </mat-tab>

  </mat-tab-group>

</div>

<div class="dialog" *ngIf="!partner">

  <p>{{'general.labels.notFound'|translate:{ value: 'general.partner.pl'|translate } }}</p>

</div>
