import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { Queueable } from '../../../shared/services/queueable.decorator';
import { QueuedActionHandler } from '../../../shared/services/queued-action-handler';
import { AddEquipmentDamageCommand } from '../contract/add-equipment-damage-command';
import { AddEquipmentIncidentCommand } from '../contract/add-equipment-incident-command';
import { AddEquipmentInvoiceCommand } from '../contract/add-equipment-invoice-command';
import { AddEquipmentLabelCommand } from '../contract/add-equipment-label-command';
import { AddInsuranceContractCommand } from '../contract/add-insurance-contract-command';
import { AddRentalContractCommand } from '../contract/add-rental-contract-command';
import { AddSalesContractCommand } from '../contract/add-sales-contract-command';
import { AddServiceContractCommand } from '../contract/add-service-contract-command';
import { AssignEquipmentToOrganisationCommand } from '../contract/assign-equipment-to-organisation-command';
import { AssignEquipmentToProjectCommand } from '../contract/assign-equipment-to-project-command';
import { AssignSubEquipmentCommand } from '../contract/assign-sub-equipment-command';
import { CountEquipmentByCategory1Type } from '../contract/count-equipment-by-category1-type';
import { CreateEquipmentCommand } from '../contract/create-equipment-command';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { DeleteEquipmentAssignmentCommand } from '../contract/delete-equipment-assignment-command';
import { DeleteEquipmentCommand } from '../contract/delete-equipment-command';
import { DeleteEquipmentDamageCommand } from '../contract/delete-equipment-damage-command';
import { DeleteEquipmentDocumentCommand } from '../contract/delete-equipment-document-command';
import { DeleteEquipmentImageCommand } from '../contract/delete-equipment-image-command';
import { DeleteEquipmentIncidentCommand } from '../contract/delete-equipment-incident-command';
import { DeleteEquipmentInvoiceCommand } from '../contract/delete-equipment-invoice-command';
import { DeleteInsuranceContractCommand } from '../contract/delete-insurance-contract-command';
import { DeleteRentalContractCommand } from '../contract/delete-rental-contract-command';
import { DeleteSalesContractCommand } from '../contract/delete-sales-contract-command';
import { DeleteServiceContractCommand } from '../contract/delete-service-contract-command';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { EquipmentInvoice } from '../contract/equipment-invoice.interface';
import { ImageLink } from '../contract/image-link.interface';
import { LifeCycle } from '../contract/life-cycle';
import { LifeCycleMileage } from '../contract/life-cycle-mileage';
import { LifeCycleOperatingHours } from '../contract/life-cycle-operating-hours';
import { LogManualMileageCommand } from '../contract/log-manual-mileage-command';
import { LogManualOperatingHoursCommand } from '../contract/log-manual-operating-hours-command';
import { PairEquipmentScanCodeCommand } from '../contract/pair-equipment-scan-code-command';
import { RemoveEquipmentLabelCommand } from '../contract/remove-equipment-label-command';
import { RevertManualMileageCommand } from '../contract/revert-manual-mileage-command';
import { RevertManualOperatingHoursCommand } from '../contract/revert-manual-operating-hours-command';
import { SearchEquipmentLocation } from '../contract/search-equipment-location.interface';
import { SearchEquipmentTransport } from '../contract/search-equipment-transport';
import { SearchEquipment } from '../contract/search-equipment.interface';
import { SetEquipmentDescriptionCommand } from '../contract/set-equipment-description.command';
import { SetEquipmentStandardImageCommand } from '../contract/set-equipment-standard-image-command';
import { UnAssignSubEquipmentCommand } from '../contract/un-assign-sub-equipment-command';
import { UpdateEquipmentAssignmentCommand } from '../contract/update-equipment-assignment-command';
import { UpdateEquipmentCommand } from '../contract/update-equipment-command';
import { UpdateEquipmentTechnicalFieldsCommand } from '../contract/update-equipment-technical-fields-command';
import { UpdateInsuranceContractCommand } from '../contract/update-insurance-contract-command';
import { UpdateRentalContractCommand } from '../contract/update-rental-contract-command';
import { UpdateSalesContractCommand } from '../contract/update-sales-contract-command';
import { UpdateServiceContractCommand } from '../contract/update-service-contract-command';
import { UploadEquipmentDocumentCommand } from '../contract/upload-equipment-document-command';
import { ViewEquipmentProjectAssignment } from '../contract/view-equipment-project-assignment.interface';
import { ViewEquipmentContracts } from '../contract/view-equipment-contracts.interface';
import { ViewEquipmentCostOverview } from '../contract/view-equipment-cost-overview.interface';
import { ViewEquipmentCosts } from '../contract/view-equipment-costs.interface';
import { ViewEquipment } from '../contract/view-equipment.interface';
import { DispositionEquipment } from './disposition-equipment';
import { ScanEquipment } from './scan-equipment';
import { UpdateEquipmentChangeTypeCommand } from '../contract/update-equipment-change-type-command';
import { UnpairEquipmentScanCodeCommand } from '../contract/unpair-equipment-scan-code-command';
import { List } from 'immutable';
import { RequestedAssignment } from './requested-assignment';
import { AcknowledgeAssignmentCommand } from '../../disposition/mobile/acknowledge-disposition-command';
import { ContractDocumentLink } from '../contract/contract-document-link.interface';
import { AddImageCommand } from '../contract/add-image-command';
import { getHttpParameters } from '../../../shared/utils';
import { ValidateAssignmentParams } from './contracts/validate-assignment-params.interface';
import { DeleteRequestedAssignmentCommand } from '../../disposition/mobile/delete-requested-assignment-command';
import { map } from 'rxjs/operators';
import { UpdateEquipmentDocumentDescriptionCommand } from '../contract/update-equipment-document-description-command';
import { LifeCycleDocumentLink } from '../contract/lifeCycleDocumentLink.interface';
import { AssignEquipmentToStockCommand } from '../contract/assign-equipment-to-stock-command';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { UploadService } from '../../../shared/services/upload-service/upload-service';
import { AttachedImage } from '../../../shared/contract/attached-image.interface';
import { EmployeeAssignmentCount } from '../contract/employee-assignment-count';
import { DeleteEquipmentLeasingContractCommand } from '../contract/delete-equipment-leasing-contract-command';
import { AddLeasingContractCommand } from '../contract/add-leasing-contract-command';
import { UpdateLeasingContractCommand } from '../contract/update-leasing-contract-command';
import { AssignTelematicsUnitCommand } from '../contract/assign-telematics-unit-command';
import { UnassignTelematicsUnitCommand } from '../contract/unassign-telematics-unit-command';
import { ViewEquipmentFilter } from '../contract/view-equipment-filter';
import { ViewFilterableEquipmentOrganisation } from '../contract/view-filterable-equipment-organisation';
import { EquipmentUsageRecord } from '../contract/equipment-usage-record';
import { ParentEquipment } from '../contract/parent-equipment';
import { SetEquipmentTransportTypesCommand } from '../contract/set-equipment-transport-types-command';
import { ViewEquipmentTelematicsData } from '../contract/view-equipment-telematics-data.interface';
import {
  EquipmentTransportFilterOptions,
} from '../../../shared/contract/filter/filter-view/equipment-transport-filter-options.interface';
import { CommonFilterView } from '../../../shared/contract/filter/filter-view/common-filter-view.interface';
import { Status } from '../contract/status.interface';
import { UpdateEquipmentChangeStatusCommand } from '../contract/update-equipment-change-status-command';
import { EquipmentStateFilterView } from 'app/shared/contract/filter/filter-view/equipment-state-filter-view.interface';
import { OrganisationFilterView } from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import {
  AssignedEmployeeFilterView,
} from 'app/shared/contract/filter/filter-view/assigned-employee-filter-view.interface';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { CustomerEquipmentStatuses } from 'app/modules/field-management/contact/customer-equipment-statuses.interface';
import {
  CustomerEquipmentStatusesUpdateCommand,
} from 'app/modules/field-management/contact/command/customer-equipment-statuses-update.command';
import {
  CustomerEquipmentStatusDeleteCommand,
} from 'app/modules/field-management/contact/command/customer-equipment-status-delete.command';
import { EquipmentStatusCategory } from '../contract/equipment-status-category.enum';
import { OrganisationInfo } from '../contract/organisation-info.interface';
import { ReassignTelematicsUnitCommand } from '../contract/reassign-telematics-unit-command';
import {
  UpdateMaintenanceTaskDocumentDescriptionCommand
} from '../../maintenance/tasks/shared/update-maintenance-task-document-description.command';
import {UpdateEquipmentDocumentNameCommand} from '../contract/update-equipment-document-name-command';
import { ViewOrganisation } from '../../organisation/contract/view-organisation.interface';
import { EquipmentCountByStatusCommand } from '../contract/equipment-count-by-status.command';
import { GroupedEquipmentStatusCount } from 'app/shared/contract/grouped-equipment-status-count.interface';
import { RequestErrorHandlerEquipmentService } from 'app/core/interceptors/request-error-handlers/request-error-handler-equipment.service';
import { DTZ } from 'app/shared/timezone/date-timezone';
import { FilterBaseView } from '../../../shared/contract/filter/filter-view/filter-base-view.interface';
import { RemoveFavouriteEquipmentCommand } from '../contract/remove-favourite-equipment.command';
import { SetFavouriteEquipmentCommand } from '../contract/set-favourite-equipment.command';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';

export type SearchEquipmentsBody = {
  terms?: string;
  searchColumns?: string[];
  status?: string[]; // new equipment statuses
  equipmentStatus?: string[];
  customerLabels?: string[];
  equipmentTypes?: string[];
  equipmentOrganisations?: string[];
  assignedEmployee?: string[];
  showSubEquipments?: boolean;
  transportTypes?: string[];
  showOnlyTransportVehicles?: boolean,
  showOnlyFavouriteEquipments?: boolean;
  matchAllLabels?: boolean
};

export type ExportEquipmentsBody = SearchEquipmentsBody & {
  columns?: string[];
};

export type SearchDispositionEquipmentsBody = SearchEquipmentsBody & {
  container?: boolean;
  available?: boolean;
  availableStart?: string;
  availableEnd?: string;
  excludedEquipmentStatusCategory?: [EquipmentStatusCategory];
};

export type SearchEquipmentsRequest = {
  body: SearchEquipmentsBody;
  page?: number;
  size?: number
  sort?: any
};

export type ExportEquipmentsRequest = SearchEquipmentsRequest & {
  body: ExportEquipmentsBody;
};

export type SearchDispositionEquipmentsRequest = SearchEquipmentsRequest & {
  body: SearchDispositionEquipmentsBody;
};

@Injectable()
export class EquipmentsService extends QueuedActionHandler implements UploadService {

  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/equipments';
  private maintenanceTaskUrl = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/tasks';
  private urlV2 = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v2/equipments';
  private dispositionUrl = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/equipments/disposition';
  private labelUrl = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/labels';
  private costCenterUrl = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/cost-centers';
  private bglcodeUrl = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/bgl-codes';
  private dispositionServiceUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/';
  private statusUrl = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/equipment-statuses';
  private organisationsUrl = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/organisations';

  constructor(private http: HttpClient,
              protected onlineStatusService: OnlineStatusService,
              protected userConfigurationService: UserConfigurationService) {
    super(EquipmentsService.name, onlineStatusService);
  }

  public addImage(image: AttachedImage, equipmentId: string): Observable<string> {
    const command = new AddImageCommand();
    command.equipmentId = equipmentId;
    command.image = image;

    return this.http.post(`${this.url}/add-image`, JSON.stringify(command), {responseType: 'text'});
  }

  getEquipments(request: SearchEquipmentsRequest): Observable<PagedResponse<SearchEquipment>> {
    let httpParams = getHttpParameters({
      page: request.page,
      size: request.size,
      sort: request.sort,
    });

    return this.http.post<PagedResponse<SearchEquipment>>(this.getSearchUrl(), request.body, {params: httpParams});
  }

  getEquipmentIds(request: SearchEquipmentsRequest): Observable<List<string>> {
    let httpParams = getHttpParameters({
      page: request.page,
      size: request.size,
      sort: request.sort,
    });

    return this.http.post<List<string>>(`${this.url}/ids`, request.body, {params: httpParams});
  }

  getEquipmentsSearch(request: SearchEquipmentsRequest | SearchDispositionEquipmentsRequest):
    Observable<PagedResponse<SearchEquipment>> {

    let httpParams = getHttpParameters({
      page: request.page,
      size: request.size,
      sort: request.sort,
    });

    return this.http.post<PagedResponse<SearchEquipment>>(`${this.getSearchUrl()}/search/all`, request.body, {params: httpParams});
  }

  getEquipmentsSearchRental(params: Object): Observable<HttpResponse<PagedResponse<SearchEquipment>>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchEquipment>>(`${this.url}/search/rental`, {
      params: httpParams,
      observe: 'response'
    });
  }

  getEquipmentsTransportSearch(params: Object): Observable<SearchEquipmentTransport[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<SearchEquipmentTransport[]>(`${this.getSearchUrl()}/search/transports`, {params: httpParams});
  }

  getEquipmentLocationsSearch(request: SearchEquipmentsRequest): Observable<SearchEquipmentLocation[]> {
    return this.http.post<SearchEquipmentLocation[]>(`${this.getSearchUrl()}/search/geo`, request.body);
  }

  getEquipmentLocations(request: SearchEquipmentsRequest): Observable<SearchEquipmentLocation[]> {
    return this.http.post<SearchEquipmentLocation[]>(`${this.getSearchUrl()}/geo`, request.body);
  }

  getEquipment(equipmentId: string): Observable<ViewEquipment> {
    return this.http.get<ViewEquipment>(this.url + '/' + equipmentId, { context: RequestErrorHandlerEquipmentService.getHttpContext() });
  }

  getEquipmentTelematicsData(equipmentId: string): Observable<ViewEquipmentTelematicsData> {
    return this.http.get<ViewEquipmentTelematicsData>(`${this.url}/${equipmentId}/telematics-data`);
  }

  getParentEquipment(equipmentId: string): Observable<ParentEquipment> {
    return this.http.get<ParentEquipment>(this.url + '/' + equipmentId + '/parent');
  }

  serialNumberInUse(serialNumber: string): Observable<boolean> {
    let httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('serialNumber', serialNumber)
    };
    return this.http.get<boolean>(`${this.url}/internal-serial-number-in-use`, httpOptions);
  }

  manufacturerSerialNumberInUse(serialNumber: string, manufacturerId?: string): Observable<boolean> {
    let httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('manufacturerSerialNumber', serialNumber)
    };
    if (manufacturerId) {
      httpOptions.params = httpOptions.params.append('manufacturerId', manufacturerId);
    }
    return this.http.get<boolean>(`${this.url}/manufacturer-serial-number-in-use`, httpOptions);
  }

  public getGroupedEquipmentTypeCounts(filter?: ViewEquipmentFilter): Observable<EquipmentTypeFilterView[]> {
    return this.http.post<EquipmentTypeFilterView[]>(`${this.url}/filterable-equipment-types`, filter || {});
  }

  public getEquipmentNewStatuses(filter: ViewEquipmentFilter): Observable<EquipmentStateFilterView[]> {
    return this.http.post<EquipmentStateFilterView[]>(`${this.url}/filterable-statuses`, filter || {});
  }

  public getEquipmentOrganisations(filter: ViewEquipmentFilter):
    Observable<ViewFilterableEquipmentOrganisation[] | OrganisationFilterView[]> {
    return this.http.post<ViewFilterableEquipmentOrganisation[]>(`${this.url}/filterable-equipment-organisations`, filter || {});
  }

  public getCustomerLabels(filter?: ViewEquipmentFilter): Observable<CustomerLabel[] | CommonFilterView[]> {
    return this.http.post<CustomerLabel[]>(this.labelUrl, filter || {});
  }

  public getResponsiblePeople(filter?: ViewEquipmentFilter): Observable<EmployeeAssignmentCount[] | AssignedEmployeeFilterView[]> {
    return this.http.post<EmployeeAssignmentCount[]>(`${this.url}/filterable-equipment-employee`, filter || {});
  }

  public getTransportFilterOptions(filter?: ViewEquipmentFilter): Observable<EquipmentTransportFilterOptions> {
    return this.http.post<{ transportTypeCounts: CommonFilterView[], count: number }>(`${this.url}/filterable-transport-types`,
        filter || {})
    .pipe(map(res => {
      return {
        transportTypes: res.transportTypeCounts,
        transportVehicleCount: res.count,
      };
    }));
  }

  public getFavouriteFilterOptions(filter?: ViewEquipmentFilter): Observable<FilterBaseView> {
    return this.http.post<FilterBaseView>(`${this.url}/filterable-favourite-equipments`,
      filter || {})
      .pipe(map(res => {
        return {
          count: res.count,
        };
      }));
  }

  public setFavouriteEquipment(command: SetFavouriteEquipmentCommand): Observable<string> {
    return this.http.post(`${this.url}/set-favourite-equipment`, JSON.stringify(command), {responseType: 'text'});
  }

  public removeFavouriteEquipment(command: RemoveFavouriteEquipmentCommand): Observable<string> {
    return this.http.post(`${this.url}/remove-favourite-equipment`, JSON.stringify(command), {responseType: 'text'});
  }

  getEquipmentUsage(equipmentId: string): Observable<EquipmentUsageRecord[]> {
    return this.http.get<EquipmentUsageRecord[]>(`${this.url}/${equipmentId}/usage`)
  }

  // Equipment types filterable and grouped

  public countsEquipmentsByTypeCategory1(organisationIds: string[]): Observable<CountEquipmentByCategory1Type[]> {
    return this.http.post<CountEquipmentByCategory1Type[]>(`${this.url}/list-category1-count`, {organisationIds: organisationIds});
  }

  public countsEquipmentByStatus(command: EquipmentCountByStatusCommand): Observable<GroupedEquipmentStatusCount[]> {
    return this.http.post<GroupedEquipmentStatusCount[]>(`${this.url}/list-status-count`, JSON.stringify(command));
  }

  assignSubEquipment(command: AssignSubEquipmentCommand): Observable<string> {
    return this.http.post(`${this.url}/assign-subequipments`, JSON.stringify(command), {responseType: 'text'});
  }

  unAssignSubEquipment(command: UnAssignSubEquipmentCommand): Observable<string> {
    return this.http.post(`${this.url}/unassign-subequipment`, JSON.stringify(command), {responseType: 'text'});
  }

  // SubEquipments Services

  getSubEquipments(equipmentIds: string[]): Observable<ViewEquipment[]> {
    let httpParams = new HttpParams();
    equipmentIds.forEach(id => httpParams = httpParams.append('equipmentIds', id));

    return this.http.get<ViewEquipment[]>(this.url + '/subequipments', {params: httpParams});
  }

  public getLifeCycles(equipmentId: string, filterData: any, page?: number, size?: number): Observable<PagedResponse<LifeCycle>> {
    const params = getHttpParameters({
      ...filterData,
      sort: 'logDate,submitDate,desc',
      page: page,
      size: size
    });
    return this.http.get<PagedResponse<LifeCycle>>(this.url + '/' + equipmentId + '/life-cycle', {params: params});
  }

  public getLifeCycleDetails(equipmentId: string, lcId: string): Observable<LifeCycle> {
    return this.http.get<LifeCycle>(`${this.url}/${equipmentId}/life-cycle/${lcId}`);
  }

  public getLatestOperatingHours(equipmentId: string): Observable<LifeCycleOperatingHours> {
    return this.http.get<LifeCycleOperatingHours>(this.url + '/' + equipmentId + '/latest-operating-hours');
  }

  public getLatestMileage(equipmentId: string): Observable<LifeCycleMileage> {
    return this.http.get<LifeCycleMileage>(this.url + '/' + equipmentId + '/latest-mileage');
  }

  getCosts(equipmentId: string, performanceTypeFilter?: string): Observable<ViewEquipmentCosts[]> {
    const urlParams = performanceTypeFilter ? '?filter=' + performanceTypeFilter : '';
    return this.http.get<ViewEquipmentCosts[]>(this.url + '/' + equipmentId + '/costs' + urlParams);
  }

  getCostOverview(equipmentId: string): Observable<ViewEquipmentCostOverview> {
    return this.http.get<ViewEquipmentCostOverview>(`${this.url}/${equipmentId}/cost-overview`);
  }

  getInvoices(equipmentId: string, params?: Object): Observable<EquipmentInvoice[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<EquipmentInvoice[]>(this.url + '/' + equipmentId + '/invoices', {params: httpParams});
  }

  getDocumentsForAllInvoices(equipmentId: string, params?: Object): Observable<DocumentLink[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<DocumentLink[]>(this.url + '/' + equipmentId + '/invoice-documents', {params: httpParams});
  }

  getDocumentsForAllContracts(equipmentId: string, params?: Object): Observable<ContractDocumentLink[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<ContractDocumentLink[]>(this.url + '/' + equipmentId + '/contract-documents', {params: httpParams});
  }

  getDocumentsForAllLifeCycles(equipmentId: string, params?: Object): Observable<LifeCycleDocumentLink[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<LifeCycleDocumentLink[]>(this.url + '/' + equipmentId + '/lifecycle-documents', {params: httpParams});
  }

  getAssignments(equipmentId: string): Observable<ViewEquipmentProjectAssignment[]> {
    return this.http.get<ViewEquipmentProjectAssignment[]>(`${this.dispositionServiceUrl}equipments/${equipmentId}/assignments`);
  }

  getInvoiceDetails(equipmentId: string, invoiceId: string, invoiceNumber?: string): Observable<EquipmentInvoice> {
    let params = {
      'invoice-number': invoiceNumber
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<EquipmentInvoice>(`${this.url}/${equipmentId}/costs/${invoiceId}`, {params: httpParams});
  }

  getFiles(equipmentId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(this.url + '/' + equipmentId + '/files');
  }

  getImages(equipmentId: string): Observable<ImageLink[]> {
    return this.http.get<ImageLink[]>(this.url + '/' + equipmentId + '/images');
  }

  getDocuments(equipmentId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(this.url + '/' + equipmentId + '/documents');
  }

  setDescription(command: SetEquipmentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/set-description`, JSON.stringify(command), {responseType: 'text'});
  }

  getCostCenters(): Observable<string[]> {
    return this.http.get<string[]>(this.costCenterUrl);
  }

  getBGLCodes(): Observable<string[]> {
    return this.http.get<string[]>(this.bglcodeUrl);
  }

  addEquipment(command: CreateEquipmentCommand): Observable<string> {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  updateEquipment(command: UpdateEquipmentCommand): Observable<string> {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  changeEquipmentType(command: UpdateEquipmentChangeTypeCommand): Observable<string> {
    return this.http.post(this.url + '/change-equipment-type', JSON.stringify(command), {responseType: 'text'});
  }

  updateEquipmentTechnicalFields(command: UpdateEquipmentTechnicalFieldsCommand): Observable<string> {
    return this.http.post(this.url + '/update-technical-fields', JSON.stringify(command), {responseType: 'text'});
  }

  updateEquipmentChangeStatus(command: UpdateEquipmentChangeStatusCommand): Observable<string> {
    return this.http.post(this.url + '/change-equipment-status', JSON.stringify(command), {responseType: 'text'});
  }

  deleteEquipment(command: DeleteEquipmentCommand): Observable<string> {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public logManualOperatingHours(command: LogManualOperatingHoursCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/lifecycle/log-operating-hours', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteManualOperatingHours(command: RevertManualOperatingHoursCommand): Observable<string> {
    return this.http.post(this.url + '/lifecycle/revert-operating-hours', JSON.stringify(command), {responseType: 'text'});
  }

  public logManualMileage(command: LogManualMileageCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/lifecycle/log-mileage', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteManualMileage(command: RevertManualMileageCommand): Observable<string> {
    return this.http.post(this.url + '/lifecycle/revert-mileage', JSON.stringify(command), {responseType: 'text'});
  }

  setStandardImage(command: SetEquipmentStandardImageCommand): Observable<string> {
    return this.http.post(this.url + '/set-standard-image', JSON.stringify(command), {responseType: 'text'});
  }

  deleteImage(command: DeleteEquipmentImageCommand): Observable<string> {
    return this.http.post(this.url + '/delete-image', JSON.stringify(command), {responseType: 'text'});
  }

  deleteDocument(command: DeleteEquipmentDocumentCommand): Observable<string> {
    return this.http.post(this.url + '/delete-document', JSON.stringify(command), {responseType: 'text'});
  }

  public addDocument(document: AttachedDocument, equipmentId: string): Observable<string> {
    const command = new UploadEquipmentDocumentCommand();
    command.document = {
      documentKey: document.documentKey,
      fileName: document.fileName,
      fileType: document.fileType,
      fileDescription: document.fileDescription
    };
    command.equipmentId = equipmentId;

    return this.http.post(this.url + '/add-document', JSON.stringify(command), {responseType: 'text'});
  }

  addInvoice(command: AddEquipmentInvoiceCommand): Observable<string> {
    return this.http.post(`${this.urlV2}/add-invoice`, JSON.stringify(command), {responseType: 'text'});
  }

  updateInvoice(command: AddEquipmentInvoiceCommand): Observable<string> {
    return this.http.post(`${this.urlV2}/update-invoice`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteInvoice(command: DeleteEquipmentInvoiceCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-invoice`, JSON.stringify(command), {responseType: 'text'});
  }

  addDamage(command: AddEquipmentDamageCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/lifecycle/add-damage-by-violence', JSON.stringify(command), {responseType: 'text'});
  }

  updateDamage(command: AddEquipmentDamageCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/lifecycle/update-damage-by-violence', JSON.stringify(command), {responseType: 'text'});
  }

  deleteDamage(command: DeleteEquipmentDamageCommand): Observable<string> {
    return this.http.post(`${this.url}/lifecycle/delete-damage-by-violence`, JSON.stringify(command), {responseType: 'text'});
  }

  addIncident(command: AddEquipmentIncidentCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/lifecycle/add-incident', JSON.stringify(command), {responseType: 'text'});
  }

  updateIncident(command: AddEquipmentIncidentCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/lifecycle/update-incident', JSON.stringify(command), {responseType: 'text'});
  }

  deleteIncident(command: DeleteEquipmentIncidentCommand): Observable<string> {
    return this.http.post(this.url + '/lifecycle/delete-incident', JSON.stringify(command), {responseType: 'text'});
  }

  public saveLabel(command: AddEquipmentLabelCommand): Observable<string> {
    return this.http.put(this.url + '/add-label', JSON.stringify(command), {responseType: 'text'});
  }

  public removeLabel(command: RemoveEquipmentLabelCommand): Observable<string> {
    return this.http.put(this.url + '/remove-label', JSON.stringify(command), {responseType: 'text'});
  }

  assignToOrganisation(command: AssignEquipmentToOrganisationCommand): Observable<string> {
    return this.http.post(this.url + '/assign-to-organisation', JSON.stringify(command), {responseType: 'text'});
  }

  public assignToStock(command: AssignEquipmentToStockCommand): Observable<string> {
    return this.http.post(this.url + '/assign-to-stock', JSON.stringify(command), {responseType: 'text'});
  }

  validInvoiceNumber(equipmentId: string, params?: Object): Observable<boolean> {
    let httpParams = getHttpParameters(params);

    return this.http.get<boolean>(
      `${this.url}/${equipmentId}/conflicting-invoice-number`, {params: httpParams}
    );
  }

  @Queueable()
  assignToProject(command: AssignEquipmentToProjectCommand): Observable<string> {
    const cmdConvertedDates: AssignEquipmentToProjectCommand = {
      ...command,
      startDate: DTZ.DateTimezone({ display: command.startDate }).storeDate.toISOString(),
      endDate: command.endDate != null
        ? DTZ.DateTimezone({ display: command.endDate }).storeDate.toISOString()
        : null,
    };
    return this.http.post(
      `${this.dispositionServiceUrl}equipments/request-project-assignment`,
      JSON.stringify(cmdConvertedDates), {responseType: 'text'});
  }

  deleteAssignment(command: DeleteEquipmentAssignmentCommand): Observable<string> {
    return this.http.post(
      `${this.dispositionServiceUrl}equipments/remove-project-assignment`,
      JSON.stringify(command), {responseType: 'text'});
  }

  updateAssignment(command: UpdateEquipmentAssignmentCommand): Observable<string> {
    const cmdConvertedDates: UpdateEquipmentAssignmentCommand = {
      ...command,
      newStartDate: DTZ.DateTimezone({ display: command.newStartDate }).storeDate.toISOString(),
      newEndDate: command.newEndDate != null
        ? DTZ.DateTimezone({ display: command.newEndDate }).storeDate.toISOString()
        : null,
    };
    return this.http.post(
      `${this.dispositionServiceUrl}equipments/update-project-assignment`,
      JSON.stringify(cmdConvertedDates), {responseType: 'text'});
  }

  public validateAssignment(equipmentId: string, params: ValidateAssignmentParams): Observable<ViewEquipmentProjectAssignment[]> {
    let options = new HttpParams({encoder: new CustomEncoder()});
    if (params.id) {
      options = options.set('id', params.id);
    }
    if (params.assignmentStart) {
      options = options.set('assignmentStart', params.assignmentStart);
    }
    if (params.assignmentEnd) {
      options = options.set('assignmentEnd', params.assignmentEnd);
    }
    if (params.assignmentId) {
      options = options.set('assignmentId', params.assignmentId);
    }

    return this.http.get<ViewEquipmentProjectAssignment[] | undefined>(
        `${this.dispositionServiceUrl}equipments/${equipmentId}/valid-assignment-timeframe`,
        {params: options})
    .pipe(map(res => res ? res : []));
  }

  pairScanCode(command: PairEquipmentScanCodeCommand): Observable<string> {
    return this.http.post(`${this.url}/pair-scan-code`, command, {responseType: 'text'});
  }

  unpairScanCode(command: UnpairEquipmentScanCodeCommand): Observable<string> {
    return this.http.post(`${this.url}/unpair-scan-code`, command, {responseType: 'text'});
  }

  getByScanCode(code: string, dispositionMode = false): Observable<ScanEquipment> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('code', code)
      .set('dispoMode', dispositionMode.toString())
    };
    return this.http.get<ScanEquipment>(`${this.url}/by-scan-code`, httpOptions);
  }

  getDispositionEquipments(): Observable<DispositionEquipment[]> {
    return this.http.get<DispositionEquipment[]>(this.dispositionUrl);
  }

  public getRequestedAssignments(equipmentId?: string, projectId?: string, acknowledged?: boolean): Observable<RequestedAssignment[]> {
    const params: HttpParams = equipmentId ?
      getHttpParameters({equipmentId: equipmentId, acknowledged: acknowledged}) :
      getHttpParameters({projectId: projectId, acknowledged: acknowledged});
    return this.http.get<RequestedAssignment[]>(`${this.dispositionServiceUrl}equipments/request-list`, {params: params});
  }

  getRequestedAssignmentAmount(): Observable<number> {
    return this.http.get<number>(this.dispositionServiceUrl + 'equipments/badge-count');
  }

  acknowledgeAssignment(command: AcknowledgeAssignmentCommand): Observable<string> {
    return this.http.post(
      `${this.dispositionServiceUrl}equipments/acknowledge-project-assignment`,
      JSON.stringify(command), {responseType: 'text'});
  }

  deleteRequestedAssignment(command: DeleteRequestedAssignmentCommand): Observable<string> {
    return this.http.post(
      `${this.dispositionServiceUrl}equipments/cancel-project-assignment`,
      JSON.stringify(command), {responseType: 'text'});
  }

  validateOpenAssignmentRequest(equipmentId: string): Observable<RequestedAssignment[]> {
    return this.http.get<RequestedAssignment[]>(this.dispositionServiceUrl + 'equipments/validate-assignment-request/' + equipmentId);
  }

  // Contracts Services

  addRentalContract(command: AddRentalContractCommand): Observable<string> {
    return this.http.post(`${this.url}/add-rental-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  updateRentalContract(command: UpdateRentalContractCommand): Observable<string> {
    return this.http.post(`${this.url}/update-rental-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteRentalContract(command: DeleteRentalContractCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-rental-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  addSalesContract(command: AddSalesContractCommand): Observable<string> {
    return this.http.post(`${this.url}/add-sales-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  updateSalesContract(command: UpdateSalesContractCommand): Observable<string> {
    return this.http.post(`${this.url}/update-sales-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteSalesContract(command: DeleteSalesContractCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-sales-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  addServiceContract(command: AddServiceContractCommand): Observable<string> {
    return this.http.post(`${this.url}/add-service-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  updateServiceContract(command: UpdateServiceContractCommand): Observable<string> {
    return this.http.post(`${this.url}/update-service-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteServiceContract(command: DeleteServiceContractCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-service-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  addInsuranceContract(command: AddInsuranceContractCommand): Observable<string> {
    return this.http.post(`${this.url}/add-insurance-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  updateInsuranceContract(command: UpdateInsuranceContractCommand): Observable<string> {
    return this.http.post(`${this.url}/update-insurance-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteInsuranceContract(command: DeleteInsuranceContractCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-insurance-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  addLeasingContact(command: AddLeasingContractCommand): Observable<string> {
    return this.http.post(`${this.url}/add-leasing-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  updateLeasingContract(command: UpdateLeasingContractCommand): Observable<string> {
    return this.http.post(`${this.url}/update-leasing-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteLeasingContract(command: DeleteEquipmentLeasingContractCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-leasing-contract`, JSON.stringify(command), {responseType: 'text'});
  }

  getContracts(equipmentId: string): Observable<ViewEquipmentContracts> {
    return this.http.get<ViewEquipmentContracts>(`${this.url}/${equipmentId}/contracts`);
  }

  public exportEquipmentList(request: ExportEquipmentsRequest): Observable<Blob> {
    let httpParams = getHttpParameters({
      page: request.page,
      size: request.size,
      sort: request.sort,
    });

    return this.http.post(`${this.getSearchUrl()}/export-equipment-list`, request.body, {
      params: httpParams,
      responseType: 'blob'
    });
  }

  public updateDocumentName(command: UpdateEquipmentDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentDescription(command: UpdateEquipmentDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateMaintenanceTaskDescription(command: UpdateMaintenanceTaskDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.maintenanceTaskUrl}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public getLinkedInvoices(equipmentId: string): Observable<string[]> {
    return this.http.get<string[]>(this.url + `/${equipmentId}/linked-invoices`);
  }

  // Telematics unit
  public assignTelematicsUnit(cmd: AssignTelematicsUnitCommand): Observable<string> {
    return this.http.post(`${this.url}/assign-telematics-unit`, JSON.stringify(cmd), {responseType: 'text'});
  }

  public reassignTelematicsUnit(cmd: ReassignTelematicsUnitCommand): Observable<string> {
    return this.http.post(`${this.url}/reassign-telematics-unit`, JSON.stringify(cmd), {responseType: 'text'});
  }

  public unassignTelematicsUnit(cmd: UnassignTelematicsUnitCommand): Observable<string> {
    return this.http.post(`${this.url}/unassign-telematics-unit`, JSON.stringify(cmd), {responseType: 'text'});
  }

  public setEquipmentTransportTypes(command: SetEquipmentTransportTypesCommand): Observable<string> {
    return this.http.post(`${this.url}/set-transport-types`, JSON.stringify(command), {responseType: 'text'});
  }

  // Transport services

  public isTransportVehicleWithUnfulfilledTasks(equipmentId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + `/${equipmentId}/has-unfulfilled-transports`);
  }

  public hasAffectedTransportsByEquipmentAndTransportTypes(equipmentId: string, transportTypes: string[]): Observable<boolean> {
    const httpParams = getHttpParameters({transportTypes: transportTypes});
    return this.http.get<boolean>(this.url + `/${equipmentId}/has-unfulfilled-transports-by-type`, {params: httpParams});
  }

  // New equipment statuses

  public getNewEquipmentStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(this.statusUrl);
  }

  public getEquipmentStatusesTranslations(): Observable<CustomerEquipmentStatuses> {
    return this.http.get<CustomerEquipmentStatuses>(`${this.statusUrl}/translations`);
  }

  public saveEquipmentStatusesTranslations(command: CustomerEquipmentStatusesUpdateCommand): Observable<string> {
    return this.http.post(`${this.statusUrl}/bulk-update`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteEquipmentStatus(command: CustomerEquipmentStatusDeleteCommand): Observable<string> {
    return this.http.post(`${this.statusUrl}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  // Organisations cache

  public getOrganisations(page?: number, size?: number): Observable<PagedResponse<OrganisationInfo>> {
    let httpParams = getHttpParameters({page, size});
    return this.http.get<PagedResponse<OrganisationInfo>>(this.organisationsUrl, {params: httpParams});
  }

  public getOrganisationsTreeByCustomer(customerId: string): Observable<ViewOrganisation[]> {
    return this.http.get<ViewOrganisation[]>(`${this.organisationsUrl}/by-customer/${customerId}/tree`);
  }

  private getSearchUrl(): string {
    return this.userConfigurationService?.getEquipmentSearchV2Configuration()?.useSearchV2
      ? this.urlV2 : this.url;
  }
}
