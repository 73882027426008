import { Injectable } from '@angular/core';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import { CommonFilterViewConverter } from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import { OrganisationFilterViewConverter } from 'app/shared/contract/filter/filter-converter/organisation-filter-view-converter.class';
import { FilterTypes, FilterTypesDisplayNames } from 'app/shared/contract/filter/filter-types.enum';
import { EmployeeFilterCollectionView } from 'app/shared/contract/filter/filter-view/employee-filter-collection/employee-filter-collection-view.interface';
import { EmployeeFilterType } from 'app/shared/contract/filter/filter-view/employee-filter-collection/employee-filter-type.enum';
import { EmployeeStateFilterView } from 'app/shared/contract/filter/filter-view/employee-state-filter-view.interface';
import { OrganisationFilterView } from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import { FiltersBaseService } from 'app/shared/contract/filter/filters-base-service.class';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { BehaviorSubject, Observable, map, zip } from 'rxjs';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { EmployeeManagementService } from '../../shared/employee-management.service';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { EmployeeFilterParamsClass } from '../employee-request-params/employee-filter-params.class';
import { EmployeeFilterParams } from '../employee-request-params/employee-filter-params.interface';
import { KeycloakService } from 'app/core/keycloak';
import { FilterUtils } from 'app/shared/contract/filter/filter-utils.class';
import { CustomerLabel } from 'app/shared/contract/customer-label.interface';
import { ContactStatus } from 'app/modules/contact/shared/enums/contact-status.enum';
import { EmployeeResponseFilterCollectionView } from 'app/shared/contract/filter/filter-view/employee-filter-collection/employee-response-filter-collection-view.interface';
import { FilterType } from 'app/shared/contract/filter/filter-type';


type EmployeeFilterItemView =
  CustomerLabel
  | OrganisationFilterView
  | EmployeeStateFilterView;

type UpdateFilterByTypeCommand = {
  [key in EmployeeFilterType]?: EmployeeFilterItemView[];
}
@Injectable()
export class EmployeeFilterService extends FiltersBaseService<EmployeeFilterParams> {
  private organisationMap = new Map<string, ViewOrganisation>();
  private _isDefaultFiltersApplied = new BehaviorSubject<boolean>(false);
  public readonly isReady = this._isDefaultFiltersApplied.asObservable();
  private _labels = new BehaviorSubject<CustomerLabel[]>([]);
  public readonly labels = this._labels.asObservable();
  private isFiltersInit = false;
  private isFiltersChanged = false;
  private isInitialFiltersChange = false;
  private isNoFiltersApplied = true;

  public get isSearchFiltersApplied(): boolean {
    return this.isFiltersChanged && !this.isNoFiltersApplied;
  }

  constructor(private authService: KeycloakService,
              private employeeService: EmployeeManagementService,
              private organisationsService: OrganisationsService) {
    super(new EmployeeFilterParamsClass())
  }

  public getFilterParams(): EmployeeFilterParams {
    this.checkFilterInit(true);
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.checkFilterInit();
    super.updateFilterParams(commands, updateFilters);
  }

  public updateFilters(): void {
    this.checkFilterInit();
    if (this._isDefaultFiltersApplied.value) {
      this.filterChangeCheck();
      zip(this.getFilters(),
          this.getOrganisationMap())
      .subscribe(([filters, organisationMap]) => {
        const responseFilters = this.convertResponseFilterCollectionViewToFilterCollectionView(filters);
        this.organisationMap = organisationMap;
        FilterUtils.pushMissingParentOrganisationToFilters(responseFilters[EmployeeFilterType.ORGANISATION], organisationMap);
        const convertedFilters = this.convertToFilterTypes(responseFilters);
        this.mergeToResultFilters(convertedFilters);
        this.updateLabelsList(responseFilters);
        this.onFiltersUpdated.emit();
      });
    }
  }

  public updateFilterLabels(): void {
    if (this.hasParamValue(EmployeeFilterType.LABEL)) {
      this.updateFilters();
    } else {
      this.getCustomerLabels().subscribe(filters => this.updateFilterByType(EmployeeFilterType.LABEL, filters));
    }
  }

  private checkFilterInit(updateFilters = false): void {
    if (!this.isFiltersInit) {
      this.isFiltersInit = true;
      this._isDefaultFiltersApplied.next(true);
      this.setDefaultFilters();
      if (updateFilters) {
        this.updateFilters();
      }
    }
  }

  private filterChangeCheck(): void {
    this.isInitialFiltersChange
      ? this.isFiltersChanged = true
      : this.isInitialFiltersChange = true;
    this.isNoFiltersApplied = !this.params.hasAnyParam();
  }

  private getCustomerLabels(): Observable<CustomerLabel[]> {
    return this.employeeService.getCustomerLabels(this.params.getFilterParams());
  }

  private getFilters(): Observable<EmployeeResponseFilterCollectionView> {
    return this.employeeService.getEmployeeFilters(this.params.getFilterParams());
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
      .getOrganisationsByCustomerId(this.getUserCustomerId(), false)
      .pipe(map(FilterUtils.convertToOrganisationMap))
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }

  private setDefaultFilters(): void {
    this.params.updateParams([{
      filterType: FilterTypes.EMPLOYEE_STATE,
      storeName: ContactStatus.ACTIVE,
      newValue: true,
    }])
  }

  private updateLabelsList(filters: EmployeeFilterCollectionView): void {
    this._labels.next(filters.labels);
  }

  private convertToFilterTypes(filters: EmployeeFilterCollectionView): FilterType[] {
    return Object.keys(filters)
                  .map((key: EmployeeFilterType) => this.createConverter(key, filters[key] as EmployeeFilterItemView[]))
                  .map(converter => converter && converter.getFilters())
                  .filter(Boolean);
  }

  private createConverter(type: EmployeeFilterType, filters: EmployeeFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case EmployeeFilterType.LABEL:
        return new CommonFilterViewConverter(
          filters as CustomerLabel[],
          this.params,
          FilterTypes.LABEL,
        );
      case EmployeeFilterType.EMPLOYEE_STATE:
        return new CommonFilterViewConverter(
          this.convertStatusFilterViewToCustomerLabelView(filters as EmployeeStateFilterView[]),
          this.params,
          FilterTypes.EMPLOYEE_STATE,
        );
      case EmployeeFilterType.ORGANISATION:
        return new OrganisationFilterViewConverter(
          filters as OrganisationFilterView[],
          this.params,
          this.organisationMap,
          FilterTypesDisplayNames.ORGANISATION_EMPLOYEE,
        );
    }
  }

  private convertStatusFilterViewToCustomerLabelView(
    organisations: EmployeeStateFilterView[]
  ): CustomerLabel[] {
    return organisations.map(el => {
      return {
        name: el.activityStatus,
        count: el.count,
      };
    });
  }

  private hasParamValue(type: EmployeeFilterType): boolean {
    return (<EmployeeFilterParamsClass>this.params).hasParamValue(type);
  }

  private updateFilterByType(type: EmployeeFilterType, filters: EmployeeFilterItemView[]): void {
    this.updateFilterByTypes({[type]: filters});
  }

  private updateFilterByTypes(command: UpdateFilterByTypeCommand): void {
    Object.keys(command).forEach((type: EmployeeFilterType) => {
      const converter = this.createConverter(type, command[type]);
      this.pushToResultFilters(converter.getFilters());
    });
    this.onFiltersUpdated.emit();
  }

  private convertResponseFilterCollectionViewToFilterCollectionView(
    params: EmployeeResponseFilterCollectionView): EmployeeFilterCollectionView {
      return {
        status: params.statuses,
        labels: params.labels,
        organisations: params.organisations,
      }
  }
}
