import { Injectable } from '@angular/core';
import { PluginWrapperDataCategoryLabels } from './custom-plugins/plugin-wrapper-data-category-labels.class';
import { Plugin } from 'chart.js';
import { ChartCustomPluginId } from './enums/chart-custom-plugin-id.enum';

@Injectable()
export class CustomPluginFactory {

  public getPlugin(pluginId: ChartCustomPluginId): Plugin {
    switch (pluginId) {
      case ChartCustomPluginId.CUSTOM_DATA_CATEGORY_LABELS:
        return this.getPluginWrapperDataCategoryLabels();
      default:
        return null;
    }
  }

  private getPluginWrapperDataCategoryLabels(): Plugin {
    const pluginWrapper = new PluginWrapperDataCategoryLabels();
    return pluginWrapper.getPlugin();
  }

}
