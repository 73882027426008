<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex>
        <form class="search-bar-input" fxLayout="row"
              novalidate>
          <input fxFlex matInput class="search-input" [formControl]="filterControl" data-osp-test="input-search">
          <button mat-button [matTooltip]="'general.labels.resetSearchFilters'|translate" fxFlex="40px" (click)="filterControl.reset()">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </div>
      <button mat-button
              class="search-bar-button-right"
              fxFlex="50px"
              [matTooltip]="'modules.maintenance.maintenanceTypeList.createServiceType'|translate"
              routerLink="/maintenance/types/add">
        <mat-icon class="maintenance-list-icon">add_box</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="maintenance-card search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="card-title-panel">
        <mat-icon class="card-title-icon maintenance-list-icon">assignment_turned_in</mat-icon>
        <span class="maintenance-card-title">{{'general.serviceType.pl'|translate}}</span>
      </div>
      <div class="header-divider"></div>
      <div class="table-panel" fxFlex>
        <mat-table [dataSource]="maintenanceTypes | async" class="data-table" fxFlex>
          <ng-container cdkColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef>{{'general.name.s'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let type">{{type.name}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="fieldCount">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.maintenance.maintenanceTypeList.numberOfFields'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let type">{{type.typeFields.length}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="status">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let type">
              <mat-icon class="status-menu maintenance-list-icon" [matMenuTriggerFor]="maintenanceTypeOptions">
                more_vert
              </mat-icon>

              <mat-menu #maintenanceTypeOptions="matMenu">
                <section>
                  <button mat-menu-item (click)="editType(type.id)">
                    <mat-icon>edit</mat-icon>
                    <span>{{'modules.maintenance.maintenanceTypeList.editServiceType'|translate}}</span>
                  </button>
                </section>
                <section [matTooltip]="'modules.maintenance.maintenanceTypeList.deletionIfNotUsed'|translate"
                         [matTooltipDisabled]="canDeleteType(type.id)">
                  <button mat-menu-item (click)="deleteType(type)" [disabled]="!canDeleteType(type.id)">
                    <mat-icon>delete</mat-icon>
                    <span>{{'modules.maintenance.maintenanceTypeList.deleteServiceType'|translate}}</span>
                  </button>
                </section>
              </mat-menu>

            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="['name', 'fieldCount', 'status']"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: ['name', 'fieldCount', 'status']"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
