import { environment } from 'environments/environment';
import { LanguageService } from './../../../../shared/services/language.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { WizardOptions } from '../../../../shared/wizard-options.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { BulkItemNumberInUseValidator } from '../../../../shared/custom-validators/bulk-item-number-in-use.validator';
import { BulkItemsService } from '../../shared/bulk-items.service';
import { CreateBulkItemCommand } from '../../contract/create-bulk-item-command';
import { BulkItemType } from '../../contract/bulk-item-type';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DeferredUploadService } from '../../../../shared/services/deferred-upload.service';
import { MobileBulkItemAddFileUploadComponent } from './mobile-bulk-item-add-file-upload/mobile-bulk-item-add-file-upload.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterHistory } from '../../../../shared/router-history';
import { MobileWizardComponent } from '../../../../shared/components/mobile-wizard-component/mobile-wizard.component';
import { BGL_CODE_PATTERN } from '../../../../shared/custom-validators/bgl-code-pattern';
import { ScanCodeService } from '../../../../shared/services/scan-code.service';
import { ScanCodeInUseValidator } from '../../../../shared/custom-validators/scan-code-in-use.validator';

@Component({
  selector: 'bh-mobile-bulk-item-add',
  templateUrl: './mobile-bulk-item-add.component.html',
  styleUrls: ['./mobile-bulk-item-add.component.scss'],
  providers: [{
    provide: DeferredUploadService,
    useClass: DeferredUploadService,
    deps: [
      BulkItemsService
    ]
  }]
})
export class MobileBulkItemAddComponent implements OnInit {

  @ViewChild(MobileBulkItemAddFileUploadComponent, { static: true }) fileUploadTab: MobileBulkItemAddFileUploadComponent;
  @ViewChild(MobileWizardComponent, { static: true }) wizard: MobileWizardComponent;
  public wizardOptions: WizardOptions = {
    'bulk-item-add': [
      {
        key: 'overall-data',
        shortName: this.translate('general.baseData')
      },
      {
        key: 'general-data',
        shortName: this.translate('general.generalData'),
        optional: true
      },
      {
        key: 'file-upload',
        shortName: this.translate('general.attachment.pl'),
        optional: true
      }
    ]
  };

  public showConsumable: boolean;

  private form: UntypedFormGroup;
  private bulkItemType: BulkItemType;
  nextEnabled: boolean;

  constructor(private location: Location,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private bulkItemService: BulkItemsService,
              private scanCodeService: ScanCodeService,
              private matSnackBar: MatSnackBar,
              private routerHistory: RouterHistory,
              private deferredUploadService: DeferredUploadService,
              private languageService: LanguageService) {
  }

  get overallDataForm() {
    return this.form.get('overallData');
  }

  get generalDataForm() {
    return this.form.get('generalData');
  }

  public ngOnInit(): void {
    this.buildForm();
    this.form.valueChanges.subscribe(() => this.setValidity());
    this.generalDataForm.get('bulkItemUnit').valueChanges.subscribe(() => this.activateWeightValidation());
    this.activatedRoute.data.pipe(map(data => data['bulkItemType']))
      .subscribe(bulkItemType => {
        this.bulkItemType = bulkItemType;
        this.showConsumable = bulkItemType === BulkItemType.CONSUMABLE;
      });
  }

  public onCancel(): void {
    this.location.back();
  }

  public save(): void {
    const formValue = {
      ...this.form.getRawValue().overallData,
      ...this.form.getRawValue().generalData
    };
    const cmd: CreateBulkItemCommand = new CreateBulkItemCommand(
        this.bulkItemType,
        formValue.bulkItemCategory,
        formValue.bulkItemName,
        formValue.bulkItemNumber,
        formValue.bulkItemCostCenter,
        formValue.bulkItemCostType,
        formValue.bulkItemManufacturerId,
        formValue.bulkItemManufacturer,
        formValue.bulkItemSupplierName,
        formValue.bulkItemSupplierId,
        formValue.bulkItemUnit,
        formValue.bulkItemWeight,
        formValue.qrCode,
        formValue.bulkItemBGLCode,
        formValue.bulkItemBALCode,
        '',
        formValue.bulkItemNonReturnable,
        []
    );

    this.bulkItemService.addBulkItem(cmd).subscribe(bulkItemId => {
      this.deferredUploadService.updateQueueEntry(this.fileUploadTab.entryNumber, {
        directUpload: true,
        referencedEntityId: bulkItemId
      });

      setTimeout(() => {
        this.deferredUploadService.uploadAll();
        this.matSnackBar.open(
          this.translate('modules.disposition.bulkItemAddEdit.wasCreated', { value: this.overallDataForm.value.bulkItemName }),
          this.translate('general.buttons.okay'),
          { duration: 3000 });
        this.routerHistory.goBack('');
      }, environment.DELAY_LONG);
    });
  }

  public setValidity(): void {
    switch (this.wizard.currentStep) {
      case 0: this.nextEnabled = this.overallDataForm.valid; break;
      case 1: this.nextEnabled = this.generalDataForm.valid; break;
      default: this.nextEnabled = true;
    }
  }

  private activateWeightValidation(): void {
    this.generalDataForm.get('bulkItemWeight').setValidators([Validators.required, Validators.min(0)]);
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      overallData: this.buildOverallDataForm(),
      generalData: this.buildGeneralDataForm()
    });
  }

  private buildOverallDataForm(): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      qrCode: [{value: '', disabled: true}],
      bulkItemNumber: ['', Validators.required],
      bulkItemName: ['', Validators.required],
      bulkItemCategory: ['', Validators.required],
      bulkItemNonReturnable: false
    });

    formGroup.get('bulkItemNumber').setAsyncValidators(
        asyncValidatorFactory((value) => BulkItemNumberInUseValidator.isValid(value, this.bulkItemService))
    );

    formGroup.get('qrCode').setAsyncValidators(
      asyncValidatorFactory((value) => ScanCodeInUseValidator.isValid(value, this.scanCodeService))
    );

    return formGroup;
  }

  private buildGeneralDataForm(): UntypedFormGroup {
    return this.formBuilder.group({
      bulkItemUnit: ['', Validators.required],
      bulkItemWeight: [{value: '', disabled: true}],
      bulkItemCostCenter: [''],
      bulkItemCostType: [''],
      bulkItemManufacturer: [''],
      bulkItemManufacturerId: [''],
      bulkItemSupplier: [''],
      bulkItemSupplierId: [''],
      bulkItemBGLCode: [{
        value: '',
        disabled: true
      }, Validators.pattern(BGL_CODE_PATTERN)],
      bulkItemBALCode: [{value: '', disabled: false}]
    });
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
