import { Pipe, PipeTransform } from '@angular/core';
import { DraggableListItem } from './draggable-list-item.interface';

@Pipe({
  name: 'isRemovable'
})
export class IsRemovablePipe implements PipeTransform {
  public transform({ isNew: isCurrentNew }: DraggableListItem, list: DraggableListItem[]): boolean {
    return isCurrentNew
      || (!isCurrentNew && list.filter(({ isNew }) => !isNew).length > 1);
  }
}
