import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { FileUtils } from 'app/shared/fileUtils';
import { environment } from 'environments/environment';
import {Observable, ReplaySubject, map, take, EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class FileDownloadService {
  private baseUrl = environment.APP_FILE_DOWNLOAD_SERVICE_BASE_URL;
  private imageMap: Record<string, Observable<SafeUrl>> = {};

  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer
  ) {}

  public getImage(imageKey: string): Observable<SafeUrl> {
    if (this.imageMap[imageKey]) {
      return this.imageMap[imageKey];
    }

    const subj = new ReplaySubject<SafeUrl>(1);
    this.imageMap[imageKey] = subj.asObservable();

    this.getFileBlobData(imageKey)
      .pipe(
        take(1),
        map(blobData => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blobData))),
        catchError(error => {
          subj.error(error);
          return EMPTY;
        })
      )
      .subscribe(data => subj.next(data));

    return this.imageMap[imageKey];
  }

  public saveFile({ documentKey, fileName }: DocumentLink): void {
    const encodedDocumentKey = FileUtils.encodeDocumentKey(documentKey);
    this.getFileBlobData(encodedDocumentKey).subscribe(blobData => {
      const safeUrl = this.domSanitizer.sanitize(
        SecurityContext.URL,
        this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blobData)));
      const link = document.createElement('a');
      link.href = safeUrl;
      link.download = fileName || 'img';
      link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
      URL.revokeObjectURL(safeUrl);
      link.remove();
    });
  }

  private getFileBlobData(imageKey: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}/${imageKey}`, {responseType: 'blob'});
  }
}
