import { BulkItemColumnDefinitionService } from './shared/services/bulk-item-column-definition.service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { dispositionRouting } from './disposition.routing';
import { DispositionBoardComponent } from './disposition-board/disposition-board.component';
import { DispositionBoardSearchComponent } from './disposition-board/disposition-board-search/disposition-board-search.component';
import { DispositionBoardTimelineComponent } from './disposition-board/disposition-board-timeline/disposition-board-timeline.component';
import { GroupByPipe, NgPipesModule } from 'ngx-pipes';
import { DispositionDataSource } from './shared/disposition.datasource';
import { DispositionBoardListComponent } from './disposition-board/disposition-board-list/disposition-board-list.component';
import { SharedModule } from '../../shared/shared.module';
import { mobileDispositionRouting } from './routing/mobile-disposition.routing';
import { MobileAcceptDispositionComponent } from './mobile/mobile-accept-disposition.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import { ProjectListComponent } from './projects/project-list/landscape/project-list.component';
import { ProjectAddEditComponent } from './projects/project-add-edit/project-add-edit.component';
import { ProjectViewComponent } from './projects/project-list/project-view/landscape/project-view.component';
import { ProjectViewDetailComponent } from './projects/project-list/project-view/project-view-detail/landscape/project-view-detail.component';
import { ProjectViewGeofencesComponent } from './projects/project-list/project-view/project-view-geofences/project-view-geofences.component';
import { ProjectViewMapComponent } from './projects/project-list/project-view/project-view-map/landscape/project-view-map.component';
import { ProjectViewCommentsComponent } from './projects/project-list/project-view/project-view-comments/landscape/project-view-comments.component';
import { ProjectViewAttachmentsComponent } from './projects/project-list/project-view/project-view-attachments/landscape/project-view-attachments.component';
import { ProjectMapComponent } from './projects/project-map/landscape/project-map.component';
import { ProjectViewDispositionsComponent } from './projects/project-list/project-view/project-view-dispositions/landscape/project-view-dispositions.component';
import { MobileProjectListComponent } from './projects/project-list/mobile/mobile-project-list.component';
import { MobileProjectMapComponent } from './projects/project-map/mobile/mobile-project-map.component';
import { MobileProjectViewComponent } from './projects/project-list/project-view/mobile/mobile-project-view.component';
import { MobileProjectViewDetailComponent } from './projects/project-list/project-view/project-view-detail/mobile/mobile-project-view-detail.component';
import { MobileProjectViewAttachmentsComponent } from './projects/project-list/project-view/project-view-attachments/mobile/mobile-project-view-attachments.component';
import { MobileProjectViewCommentsComponent } from './projects/project-list/project-view/project-view-comments/mobile/mobile-project-view-comments.component';
import { MobileProjectViewMapComponent } from './projects/project-list/project-view/project-view-map/mobile/mobile-project-view-map.component';
import { MobileProjectViewDispositionsComponent } from './projects/project-list/project-view/project-view-dispositions/mobile/mobile-project-view-dispositions.component';
import { AddEditCommentDialogComponent } from './projects/project-list/project-view/project-view-comments/add-edit-comment-dialog/add-edit-comment-dialog.component';
import { FinishProjectDialogComponent } from './shared/finish-project-dialog/finish-project-dialog.component';
import { MobileProjectViewDescriptionComponent } from './projects/project-list/project-view/project-view-description/mobile-project-view-description.component';
import { AssigneeTypePipe } from './shared/pipes/assignee-type.pipe';
import { NotificationToggleComponent } from './shared/notification-toggle/notification-toggle.component';
import { ProjectsService } from './shared/project.service';
import { MatPaginator } from '@angular/material/paginator';
import { ProjectCheckerService } from './shared/services/project-checker.service';
import { ViewUserDialogComponent } from './shared/view-user-dialog/view-user-dialog.component';
import { StockAddEditComponent } from './stocks/stock-add-edit/stock-add-edit.component';
import { StockService } from './shared/services/stock.service';
import { BulkItemsService } from './shared/bulk-items.service';
import { BulkItemAddEditComponent } from './bulk-items/bulk-item-add-edit/bulk-item-add-edit.component';
import { BulkItemTypeResolver } from './bulk-items/bulk-item-add-edit/bulk-item-type.resolver';
import { BulkItemFieldStore } from './bulk-items/service/bulk-item-field.store';
import { MatDialogModule } from '@angular/material/dialog';
import { BulkItemDatasource } from './shared/bulk-item.datasource';
import { BulkItemViewComponent } from './bulk-items/bulk-item-list/bulk-item-view/landscape/bulk-item-view.component';
import { BulkItemViewDetailComponent } from './bulk-items/bulk-item-list/bulk-item-view/bulk-item-view-detail/landscape/bulk-item-view-detail.component';
import { BulkItemViewAttachmentComponent } from './bulk-items/bulk-item-list/bulk-item-view/bulk-item-view-attachment/landscape/bulk-item-view-attachment.component';
import { BulkItemViewStocksComponent } from './bulk-items/bulk-item-list/bulk-item-view/bulk-item-view-stocks/landscape/bulk-item-view-stocks.component';
import { CurrentBulkItemTabReroute } from './routing/current-bulk-item-tab-reroute';
import { BulkItemListComponent } from './bulk-items/bulk-item-list/bulk-item-list.component';
import { BulkItemColumnService } from './bulk-items/service/bulk-item-column.service';
import { ChangeAmountDialogComponent } from './shared/change-amount-dialog/change-amount-dialog.component';
import { TransferBackToStockDialogComponent } from './shared/transfer-back-to-stock-dialog/transfer-back-to-stock-dialog.component';
import { BulkItemTransferListComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-transfer-list/bulk-item-transfer-list.component';
import { AmountsService } from './shared/amounts.service';
import { StockListComponent } from './stocks/stock-list/stock-list.component';
import { StockStore } from './stocks/shared/stock.store';
import { StockViewComponent } from './stocks/stock-list/stock-view/stock-view.component';
import { StockViewDetailComponent } from './stocks/stock-list/stock-view/stock-view-detail/stock-view-detail.component';
import { StockViewAttachmentComponent } from './stocks/stock-list/stock-view/stock-view-attachment/stock-view-attachment.component';
import { StockViewInventoryComponent } from './stocks/stock-list/stock-view/stock-view-inventory/stock-view-inventory.component';
import { BulkItemStockToProjectTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-stock-to-project-transfer-cart/bulk-item-stock-to-project-transfer-cart.component';
import { TransferItemComponent } from './shared/transfer/transfer-items/transfer-item/transfer-item.component';
import { TransferItemsComponent } from './shared/transfer/transfer-items/transfer-items.component';
import { BulkItemTransferProjectSelectComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-transfer-project-select/bulk-item-transfer-project-select.component';
import { TransferService } from './shared/transfer.service';
import { BulkItemTransferDatasource } from './shared/bulk-item-transfer.datasource';
import { BulkItemProjectToStockTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-project-to-stock-transfer-cart/bulk-item-project-to-stock-transfer-cart.component';
import { BulkItemTransferStockSelectComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-transfer-stock-select/bulk-item-transfer-stock-select.component';
import { TransferCartHeaderComponent } from './bulk-items/bulk-item-transfer/landscape/transfer-cart-header/transfer-cart-header.component';
import { MobileBulkItemTransferComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-bulk-item-transfer.component';
import { MobileChooseDispositionProcessComponent } from './shared/mobile-choose-disposition-process/mobile-choose-disposition-process.component';
import { MobileTransferChooseLocationComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-transfer-choose-location/mobile-transfer-choose-location.component';
import { MobileTransferScannerComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-transfer-scanner/mobile-transfer-scanner.component';
import { MobileTransferListComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-transfer-list/mobile-transfer-list.component';
import { MobileTransferSummaryComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-transfer-summary/mobile-transfer-summary.component';
import { MobileTransferAmountInputDialogComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-transfer-amount-input-dialog/mobile-transfer-amount-input-dialog.component';
import { BulkItemProjectToProjectTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-project-to-project-transfer-cart/bulk-item-project-to-project-transfer-cart.component';
import { TransferStore } from './bulk-items/bulk-item-transfer/shared/transfer.store';
import { BulkItemStockToStockTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-stock-to-stock-transfer-cart/bulk-item-stock-to-stock-transfer-cart.component';
import { MobileTransferChooseAmountDialogComponent } from './bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-transfer-choose-amount-dialog/mobile-transfer-choose-amount-dialog.component';
import { AmountStore } from './shared/amount.store';
import { MobileBulkItemAddComponent } from './bulk-items/mobile-bulk-item-add/mobile-bulk-item-add.component';
import { MobileBulkItemAddOverallDataComponent } from './bulk-items/mobile-bulk-item-add/mobile-bulk-item-add-overall-data/mobile-bulk-item-add-overall-data.component';
import { MobileBulkItemAddGeneralDataComponent } from './bulk-items/mobile-bulk-item-add/mobile-bulk-item-add-general-data/mobile-bulk-item-add-general-data.component';
import { MobileBulkItemAddFileUploadComponent } from './bulk-items/mobile-bulk-item-add/mobile-bulk-item-add-file-upload/mobile-bulk-item-add-file-upload.component';
import { DispositionProcessOptionResolver } from './shared/mobile-choose-disposition-process/disposition-process-option.resolver';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProjectViewTransferHistoryComponent } from './projects/project-list/project-view/project-view-transfer-history/project-view-transfer-history.component';
import { TransferHistoryEntryComponent } from '../../shared/components/transfer-history-entry/transfer-history-entry.component';
import { TextLineComponent } from './projects/project-list/project-view/project-view-transfer-history/text-line/text-line.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TransferItemPipe } from '../../shared/components/transfer-history-entry/transfer-item.pipe';
import { EmployeeListComponent } from './staff/employee-list/employee-list.component';
import { EmployeeViewComponent } from './staff/employee-list/employee-view/employee-view.component';
import { EmployeeViewDetailComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-detail.component';
import { EmployeeManagementDatasource } from './shared/employee-management.datasource';
import { EmployeeManagementService } from './shared/employee-management.service';
import { EmployeeColumnService } from './staff/service/employee-column.service';
import { EmployeeAssignmentEditDialogComponent } from './staff/shared/employee-assignment-edit-dialog/employee-assignment-edit-dialog.component';
import { CurrentEmployeeTabReroute } from './routing/current-employee-tab-reroute';
import { EmployeeAddEditComponent } from './staff/employee-add-edit/employee-add-edit.component';
import { EmployeeViewAttachmentComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-attachment/employee-view-attachment.component';
import { EmployeeViewGeneralComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-general/employee-view-general.component';
import { EmployeeViewPrivateDataComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-private-data/employee-view-private-data.component';
import { EmployeeViewNotesComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-notes/employee-view-notes.component';
import { EmployeeStatusPipe } from '../../shared/pipes/employee-status.pipe';
import { UserAssignDialogComponent } from './shared/user-assign-dialog/user-assign-dialog.component';
import { UserInfoService } from './shared/userInfo.service';
import { EmployeeViewQualificationComponent } from './staff/employee-list/employee-view/employee-view-qualification/employee-view-qualification.component';
import { QualificationAddEditComponent } from './staff/employee-list/employee-view/employee-view-qualification/qualification-add-edit/qualification-add-edit.component';
import { QualificationService } from './shared/qualification.service';
import { TeamService } from './shared/services/team.service';
import { EmployeeViewGeneralAssociationsComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-general/employee-view-general-associations/employee-view-general-associations.component';
import { TranslateModule } from '@ngx-translate/core';
import { QualificationRenewComponent } from './staff/employee-list/employee-view/employee-view-qualification/qualification-renew/qualification-renew.component';
import { DispositionBoardSearchEquipmentComponent } from './disposition-board/disposition-board-search/disposition-board-search-equipment/disposition-board-search-equipment.component';
import { DispositionBoardSearchEmployeeComponent } from './disposition-board/disposition-board-search/disposition-board-search-employee/disposition-board-search-employee.component';
import { EmployeeDispositionDatasource } from './shared/employee-disposition.datasource';
import { EmployeeDispositionService } from './shared/employee-disposition.service';
import { EmployeeViewDispositionComponent } from './staff/employee-list/employee-view/employee-view-disposition/employee-view-disposition.component';
import { EmployeeAssignToProjectDialogComponent } from './shared/employee-assign-to-project-dialog/landscape/employee-assign-to-project-dialog.component';
import { EmployeeDispositionFormItemComponent } from './shared/employee-assign-to-project-dialog/landscape/employee-disposition-form-item/employee-disposition-form-item.component';
import { OrganisationInfoService } from './shared/services/organisation-info.service';
import { EmployeeColumnDefinitionService } from './shared/services/employee-column-definition.service';
import { MatBadgeModule } from '@angular/material/badge';
import { EquipmentTransferListComponent } from './equipments/equipment-transfer-list/equipment-transfer-list.component';
import { EquipmentTransferDatasource } from './shared/equipment-transfer.datasource';
import { EquipmentTransferColumnService } from './shared/equipment-transfer-column.service';
import { EquipmentTransferColumnDefinitionService } from './shared/services/equipment-transfer-column-definition.service';
import { TransferCartDatasource } from './shared/transfer-cart.datasource';
import { BulkItemTransferColumnDefinitionService } from './shared/services/bulk-item-transfer-column-definition.service';
import { BulkItemTransferColumnService } from './shared/bulk-item-transfer-column.service';
import { TransferCartConfirmationComponent } from './bulk-items/bulk-item-transfer/landscape/transfer-cart-confirmation/transfer-cart-confirmation.component';
import { ProjectEmployeeAssignmentManageDialogComponent } from './shared/project-employee-assignment-manage-dialog/project-employee-assignment-manage-dialog.component';
import { ResponsibleSortPipe } from './shared/pipes/responsible-sort.pipe';
import { TypeOfUseStatusReasonPipe } from './shared/pipes/type-of-use-status-reason.pipe';
import { TypeOfUseStatusPipe } from './shared/pipes/type-of-use-status.pipe';
import { ChangeTypeOfUseComponent } from './shared/change-type-of-use/change-type-of-use.component';
import { ProjectEmployeeAssigneeCardsComponent } from './projects/project-list/project-view/project-view-detail/project-employee-assignee-cards/project-employee-assignee-cards.component';
import { ProjectEmployeeAssignmentViewDialogComponent } from './shared/project-employee-assignment-view-dialog/project-employee-assignment-view-dialog.component';
import { ChangeTypeOfUseRequestsDialogComponent } from './shared/change-type-of-use-requests-dialog/change-type-of-use-requests-dialog.component';
import { DatesService } from 'app/shared/services/dates.service';
import { EquipmentDispositionAssignmentService } from 'app/shared/services/disposition-assignment/equipment-disposition-assignment.service';
import { EmployeeDispositionAssignmentService } from 'app/shared/services/disposition-assignment/employee-disposition-assignment.service';
import { TransferTargetProjectDatasource } from './shared/transfer-target-project.datasource';
import { EquipmentTableComponent } from './projects/project-list/project-view/project-view-dispositions/amountTable/equipment-table/equipment-table.component';
import { BulkItemTableComponent } from './projects/project-list/project-view/project-view-dispositions/amountTable/bulk-item-table/bulk-item-table.component';
import { StockEmployeeAssigneeCardsComponent } from './stocks/stock-list/stock-view/stock-view-detail/stock-employee-assignee-cards/stock-employee-assignee-cards.component';
import { StockEmployeeAssignmentManageDialogComponent } from './shared/stock-employee-assignment-manage-dialog/stock-employee-assignment-manage-dialog.component';
import { StockEmployeeAssignmentViewDialogComponent } from './shared/stock-employee-assignment-view-dialog/stock-employee-assignment-view-dialog.component';
import { DecimalNumberValidityService } from '../../shared/services/decimal-number-validity.service';
import { StockViewTransfersComponent } from './stocks/stock-list/stock-view/stock-view-transfers/stock-view-transfers.component';
import { StockViewTransfersService } from './stocks/stock-list/stock-view/stock-view-transfers.service';
import { CustomSlicePipe } from './shared/pipes/custom-slice.pipe';
import { TransferCartSummaryComponent } from './bulk-items/bulk-item-transfer/landscape/transfer-cart-summary/transfer-cart-summary.component';
import { TransferSummaryTableComponent } from './bulk-items/bulk-item-transfer/landscape/transfer-cart-summary/transfer-summary-table/transfer-summary-table.component';
import { WaitingComponent } from './shared/waiting/waiting.component';
import { ErrorMessageComponent } from './shared/error-message/error-message.component';
import { ProjectViewChangeTypeOfUseComponent } from './projects/project-list/project-view/project-view-change-type-of-use/project-view-change-type-of-use.component';
import { ViewValidityChangeTypeOfUseComponent } from './shared/change-type-of-use/view-validity-change-type-of-use/view-validity-change-type-of-use.component';
import { ProjectFiltersService } from './shared/services/project-filters.service';
import { ProjectStatusPipe } from 'app/shared/pipes/project-status.pipe';
import { ProjectColumnService } from './shared/services/project-column.service';
import { StockColumnService } from './shared/services/stock-column.service';
import { TransferCartColumnService } from './shared/services/transfer-target-column.service';
import { DispositionColumnService } from './shared/services/disposition-column.service';
import { EquipmentTransferFilterService } from './shared/equipment-transfer-filter.service';
import { StockMapComponent } from './stocks/stock-map/stock-map.component';
import { BulkItemStockToStockTransferCartService } from './bulk-items/service/bulk-item-stock-to-stock-transfer-cart.service';
import { TransferBackToStockDialogService } from './shared/services/transfer-back-to-stock-dialog.service';
import { BulkItemFilterService } from './shared/services/bulk-item-filter.service';
import { EmployeeFilterService } from './staff/service/employee-filter.service';
import { MapModule } from 'app/shared/modules/map/map.module';
import { BhTableModule } from '../osp-ui/components/bh-table/bh-table.module';
import { OspUiPipesModule } from '../osp-ui/pipes/osp-ui-pipes.module';
import {
  StockViewGeofencesComponent
} from './stocks/stock-list/stock-view/stock-view-geofences/stock-view-geofences.component';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    FormsModule,
    dispositionRouting,
    mobileDispositionRouting,
    ReactiveFormsModule,
    NgPipesModule,
    SharedModule,
    InfiniteScrollModule,
    MainPipeModule,
    MatDialogModule,
    DragDropModule,
    ScrollingModule,
    TranslateModule,
    MatBadgeModule,
    MapModule,
    BhTableModule,
    OspUiPipesModule,
  ],
  declarations: [
    DispositionBoardComponent,
    DispositionBoardSearchComponent,
    DispositionBoardSearchEquipmentComponent,
    DispositionBoardSearchEmployeeComponent,
    DispositionBoardTimelineComponent,
    DispositionBoardListComponent,
    MobileAcceptDispositionComponent,
    ProjectListComponent,
    BulkItemListComponent,
    ProjectAddEditComponent,
    ProjectViewComponent,
    ProjectViewDetailComponent,
    ProjectViewGeofencesComponent,
    ProjectViewDetailComponent,
    ProjectViewMapComponent,
    ProjectViewCommentsComponent,
    ProjectViewAttachmentsComponent,
    ProjectMapComponent,
    ProjectViewDispositionsComponent,
    ProjectViewChangeTypeOfUseComponent,
    MobileProjectListComponent,
    MobileProjectMapComponent,
    MobileProjectViewComponent,
    MobileProjectViewDetailComponent,
    MobileProjectViewAttachmentsComponent,
    TransferCartSummaryComponent,
    TransferCartConfirmationComponent,
    MobileProjectViewCommentsComponent,
    MobileProjectViewMapComponent,
    MobileProjectViewDispositionsComponent,
    AddEditCommentDialogComponent,
    FinishProjectDialogComponent,
    MobileProjectViewDescriptionComponent,
    ViewUserDialogComponent,
    AssigneeTypePipe,
    NotificationToggleComponent,
    BulkItemAddEditComponent,
    StockAddEditComponent,
    BulkItemViewComponent,
    BulkItemViewDetailComponent,
    BulkItemViewAttachmentComponent,
    BulkItemViewStocksComponent,
    BulkItemTypeResolver,
    ChangeAmountDialogComponent,
    TransferBackToStockDialogComponent,
    BulkItemTransferListComponent,
    StockListComponent,
    StockViewComponent,
    StockViewDetailComponent,
    StockViewAttachmentComponent,
    StockViewInventoryComponent,
    BulkItemStockToProjectTransferCartComponent,
    BulkItemProjectToProjectTransferCartComponent,
    BulkItemProjectToStockTransferCartComponent,
    BulkItemStockToStockTransferCartComponent,
    BulkItemTransferStockSelectComponent,
    BulkItemTransferProjectSelectComponent,
    TransferItemsComponent,
    TransferItemComponent,
    TransferCartHeaderComponent,
    MobileBulkItemTransferComponent,
    MobileChooseDispositionProcessComponent,
    MobileTransferChooseLocationComponent,
    MobileTransferScannerComponent,
    MobileTransferListComponent,
    MobileTransferSummaryComponent,
    MobileTransferAmountInputDialogComponent,
    MobileTransferChooseAmountDialogComponent,
    MobileBulkItemAddComponent,
    MobileBulkItemAddOverallDataComponent,
    MobileBulkItemAddGeneralDataComponent,
    MobileBulkItemAddFileUploadComponent,
    ProjectViewTransferHistoryComponent,
    TransferHistoryEntryComponent,
    TextLineComponent,
    TransferItemPipe,
    EmployeeListComponent,
    EmployeeViewComponent,
    EmployeeAddEditComponent,
    EmployeeViewDetailComponent,
    EmployeeViewGeneralComponent,
    EmployeeViewGeneralAssociationsComponent,
    EmployeeViewPrivateDataComponent,
    EmployeeAssignmentEditDialogComponent,
    EmployeeViewAttachmentComponent,
    EmployeeViewNotesComponent,
    EmployeeStatusPipe,
    UserAssignDialogComponent,
    EmployeeViewQualificationComponent,
    QualificationAddEditComponent,
    QualificationRenewComponent,
    EmployeeViewDispositionComponent,
    EmployeeAssignToProjectDialogComponent,
    EquipmentTransferListComponent,
    EmployeeDispositionFormItemComponent,
    ProjectEmployeeAssigneeCardsComponent,
    ProjectEmployeeAssignmentManageDialogComponent,
    ProjectEmployeeAssignmentViewDialogComponent,
    ResponsibleSortPipe,
    ChangeTypeOfUseComponent,
    ChangeTypeOfUseRequestsDialogComponent,
    TypeOfUseStatusPipe,
    TypeOfUseStatusReasonPipe,
    EquipmentTableComponent,
    BulkItemTableComponent,
    StockEmployeeAssigneeCardsComponent,
    StockEmployeeAssignmentManageDialogComponent,
    StockEmployeeAssignmentViewDialogComponent,
    StockViewTransfersComponent,
    CustomSlicePipe,
    TransferSummaryTableComponent,
    WaitingComponent,
    ErrorMessageComponent,
    ProjectStatusPipe,
    ViewValidityChangeTypeOfUseComponent,
    StockMapComponent,
    StockViewGeofencesComponent,
  ],
  providers: [
    ProjectsService,
    DatePipe,
    DispositionDataSource,
    GroupByPipe,
    MatPaginator,
    ProjectCheckerService,
    AssigneeTypePipe,
    BulkItemsService,
    BulkItemFilterService,
    AmountsService,
    BulkItemDatasource,
    BulkItemTransferDatasource,
    StockService,
    BulkItemTypeResolver,
    BulkItemFieldStore,
    ProjectColumnService,
    StockColumnService,
    TransferCartColumnService,
    DispositionColumnService,
    OrganisationInfoService,
    CurrentBulkItemTabReroute,
    BulkItemColumnService,
    StockStore,
    TransferService,
    TransferStore,
    BulkItemTransferColumnService,
    AmountStore,
    DispositionProcessOptionResolver,
    EmployeeManagementDatasource,
    EmployeeDispositionDatasource,
    EmployeeManagementService,
    EmployeeFilterService,
    EmployeeDispositionService,
    EmployeeColumnService,
    CurrentEmployeeTabReroute,
    UserInfoService,
    TeamService,
    QualificationService,
    UserInfoService,
    TranslateModule,
    BulkItemColumnDefinitionService,
    BulkItemTransferColumnDefinitionService,
    EquipmentTransferDatasource,
    EquipmentTransferColumnService,
    EquipmentTransferColumnDefinitionService,
    TransferCartDatasource,
    TransferBackToStockDialogService,
    TransferTargetProjectDatasource,
    EmployeeColumnDefinitionService,
    DatesService,
    EquipmentDispositionAssignmentService,
    EmployeeDispositionAssignmentService,
    StockViewTransfersService,
    EmployeeDispositionAssignmentService,
    DecimalNumberValidityService,
    ProjectStatusPipe,
    ProjectFiltersService,
    EquipmentTransferFilterService,
    BulkItemStockToStockTransferCartService,
  ],
  exports: [
    TypeOfUseStatusReasonPipe,
    TypeOfUseStatusPipe,
    TransferItemPipe,
    TextLineComponent,
    TransferHistoryEntryComponent,
    CustomSlicePipe
  ]
})
export class DispositionModule {
}
