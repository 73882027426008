import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';
import { Utils } from 'app/shared/utils';


@Component({
  selector: 'bh-map-marker-overlay',
  templateUrl: './map-marker-overlay.component.html',
})
export class MapMarkerOverlayComponent implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('content', { read: ElementRef }) template: ElementRef;
  @Input() location: LatLon;

  private overlayView: any;
  private marker: google.maps.Marker = null;
  constructor(private readonly googleMap: GoogleMap) {}

  public ngAfterViewInit(): void {
    this.load();
    this.onChanges = this.onChangesOverride
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.onChanges(changes)
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  private onChanges(changes: SimpleChanges): void {}

  private onChangesOverride(changes: SimpleChanges): void {
    if((changes.location && Utils.isValidLocation({ location: changes.location.currentValue }))) {
      this.destroy();
      this.load();
    }
  }

  private destroy(): void {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }

    if (this.overlayView) {
      if (this.overlayView.div) {
        this.overlayView.remove();
      }
      this.overlayView.setMap(null);
    }

    this.overlayView = null
  }

  private load(): void {
    if (!this.googleMap?.googleMap) {
      return;
    }
    const overlay = this.getOverlay();
    this.marker = new google.maps.Marker({
      position: { lat: overlay.latitude, lng: overlay.longitude },
      icon: ' ',
      clickable: true,
      visible: false,
    });
    this.marker.setMap(this.googleMap.googleMap);

    const setMap = this.marker.setMap;
    if ((<any>this.marker)['map']) {
      this.overlayView.setMap((<any>this.marker)['map']);
    }

    this.marker.setMap = (map) => {
      setMap.call(this.marker, map);
      if (this.overlayView) {
        this.overlayView.setMap(map);
      }
    };
  }

  private getOverlay() {
    this.overlayView = this.overlayView || new google.maps.OverlayView();
    this.overlayView.iconUrl = ' ';
    this.overlayView.latitude = this.location?.lat;
    this.overlayView.longitude = this.location?.lon;
    this.overlayView.visible = false;

    const elm = this.template.nativeElement.children[0];
    const elmClone = elm.cloneNode(true);
    elm.style.opacity = 0;

    const onMouseEnter = () => {
      elm.style.opacity = 1;
    };
    const onMouseLeave = () => {
      elm.style.opacity = 0;
    };

    const restore = (div: any) => {
      this.template.nativeElement.appendChild(div);
    }

    this.overlayView.remove = function() {
      if(!this.div) {
        return;
      }
      this.div.removeEventListener('mouseenter', onMouseEnter);
      this.div.removeEventListener('mouseleave', onMouseLeave);
      this.div.parentNode.removeChild(this.div);
      restore(this.div);
      delete this.div;

      elmClone.remove();
    }

    this.overlayView.draw = function() {
      if (!this.div) {
        this.div = elm;
        const panes = this.getPanes();
        if (!panes || !panes.overlayLayer || !panes.floatPane) {
          return;
        }

        panes.floatPane.appendChild(elm);
        panes.overlayLayer.appendChild(elmClone);

        elm.addEventListener('mouseenter', onMouseEnter);
        elm.addEventListener('mouseleave', onMouseLeave);
      }

      const latlng = new google.maps.LatLng(this.latitude,this.longitude);
      const proj = this.getProjection();
      if (!proj) {
        return;
      }

      const point = proj.fromLatLngToDivPixel(latlng);
      if (point) {
        const { left, right } = elm.firstChild?.getBoundingClientRect() || { left: 0, right: 0 };
        const shift = (right - left) / 2;

        elm.style.left = elmClone.style.left = (point.x - shift) + 'px'
        elm.style.top = elmClone.style.top = point.y + 'px'
      }
    }

    return this.overlayView;
  }

}
