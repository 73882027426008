//This component is not used

import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faCalculatorAlt,
  faCoins,
  faFileInvoiceDollar,
  faIndustryAlt,
  faMoneyCheckEdit,
  faTruck
} from '@fortawesome/pro-light-svg-icons';
import { Observable } from 'rxjs';
import { BulkItemFieldStore } from '../../service/bulk-item-field.store';
import { TransferItemUnit } from '../../../contract/transfer-item-unit';
import { UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { FieldLimit } from '../../../../../shared/enums/fieldLimit.enum';

@Component({
  selector: 'bh-mobile-bulk-item-add-general-data',
  templateUrl: './mobile-bulk-item-add-general-data.component.html',
  styleUrls: ['./mobile-bulk-item-add-general-data.component.scss']
})
export class MobileBulkItemAddGeneralDataComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  public readonly faFileInvoiceDollar: IconDefinition = faFileInvoiceDollar;
  public readonly faIndustryAlt: IconDefinition = faIndustryAlt;
  public readonly faTruck: IconDefinition = faTruck;
  public readonly faCoins: IconDefinition = faCoins;
  public readonly faMoneyCheckEdit: IconDefinition = faMoneyCheckEdit;
  public readonly faCalculatorAlt: IconDefinition = faCalculatorAlt;

  public readonly fieldLimit = FieldLimit;

  public bulkItemUnits = Object.keys(TransferItemUnit);

  public manufacturers: Observable<string[]>;
  public costCenters: Observable<string[]>;
  public costTypes: Observable<string[]>;
  public bglCodes: Observable<string[]>;
  public suppliers: Observable<string[]>;

  public hasBAL: boolean;

  constructor(private bulkItemFieldStore: BulkItemFieldStore) { }

  get bulkItemUnit() {
    return this.form.get('bulkItemUnit');
  }

  public ngOnInit(): void {
    this.manufacturers = this.bulkItemFieldStore.filteredManufacturers;
    this.costCenters = this.bulkItemFieldStore.filteredCostCenters;
    this.costCenters = this.bulkItemFieldStore.filteredCostTypes;
    this.bglCodes = this.bulkItemFieldStore.filteredBglCodes;
    this.suppliers = this.bulkItemFieldStore.filteredSuppliers;
  }

  public enableWeightField(): void {
    this.form.get('bulkItemWeight').enable();
  }

  public changeCodetype(event: MatSelectChange): void {
    if (event.value === 'BGL') {
      this.hasBAL = false;
      this.form.get('bulkItemBALCode').reset();
      this.form.get('bulkItemBGLCode').enable();
    } else {
      this.hasBAL = true;
      this.form.get('bulkItemBGLCode').reset();
      this.form.get('bulkItemBALCode').enable();
    }
  }
}
