import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bh-equipment-invoice-delete-dialog',
  templateUrl: './equipment-invoice-delete-dialog.component.html',
  styleUrls: ['./equipment-invoice-delete-dialog.component.scss']
})
export class EquipmentInvoiceDeleteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EquipmentInvoiceDeleteDialogComponent>) { }

  ngOnInit() {
  }

}
