import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchProjectOrProjectGroup } from '../../../../contract/search-project.interface';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TransferProject } from './transfer-project';
import { CustomerLabel } from '../../../../../../shared/contract/customer-label.interface';
import { TransferTargetProjectDatasource } from '../../../../shared/transfer-target-project.datasource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TransferCartColumnService } from 'app/modules/disposition/shared/services/transfer-target-column.service';
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-transfer-project-select',
  templateUrl: './bulk-item-transfer-project-select.component.html',
  styleUrls: ['./bulk-item-transfer-project-select.component.scss']
})
export class BulkItemTransferProjectSelectComponent implements OnInit {

  @Input() hiddenProjectId: string;
  @Output() projectSelected: EventEmitter<TransferProject> = new EventEmitter<TransferProject>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public projects: Observable<SearchProjectOrProjectGroup[]>;
  public projectLabels: Observable<CustomerLabel[]>;
  public searchForm: UntypedFormGroup;
  public selectedProjectId: string;

  constructor(public transferTargetProjectStore: TransferTargetProjectDatasource,
              public columnService: TransferCartColumnService,
              private formBuilder: UntypedFormBuilder) { }

  public ngOnInit(): void {
    this.columnService.transferCartProjectViewPageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });
    this.projectLabels = this.transferTargetProjectStore.getCustomerLabels();
    this.searchForm = this.formBuilder.group({
      terms: '',
      labels: [],
      hideFinished: true
    });
    this.projects = this.transferTargetProjectStore.projects.pipe(
      map(list => list.toArray().filter(value => value.projectId !== this.hiddenProjectId)));
    this.onSearchFormType(0, this.paginator.pageSize);
    this.onLabelsChange();
    this.onHideFinishedChange();
  }

  public reset(): void {
    this.searchForm.reset();
    this.unselectProject();
  }

  public onPaginateChange(event: PageEvent): void {
    this.transferTargetProjectStore.updateListing(event.pageIndex, event.pageSize);
    this.columnService.selectColumns([], null, {
      transferCartPaginatorOptions: { projectViewPageSize: this.paginator.pageSize }
    });
  }

  private unselectProject(): void {
    this.selectedProjectId = null;
    this.projectSelected.emit(null);
  }

  public selectProject(project: SearchProjectOrProjectGroup): void {
    this.selectedProjectId = project.projectId;
    this.projectSelected.emit(
      { projectId: project.projectId, projectName: project.projectName, projectAddress: project.projectAddress,
        projectNumber: project.projectNumber, projectCostCenter: project.projectCostCenter});
  }

  protected onSearchFormType(pageIndex: number, pageSize: number): void {
    this.searchForm.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(() => this.updateOnSearch(pageIndex, pageSize));
    this.searchForm.patchValue({
      terms: this.transferTargetProjectStore.searchTerms
    });
  }

  protected updateOnSearch(startIndex: number, size: number): void {
    this.transferTargetProjectStore.searchTerms = this.searchForm.get('terms').value;
    this.transferTargetProjectStore.updateListing(startIndex, size);
  }

  private onLabelsChange(): void {
    this.searchForm.get('labels').valueChanges.subscribe(labels => this.transferTargetProjectStore.searchLabels = labels);
  }

  private onHideFinishedChange(): void {
    this.searchForm.get('hideFinished').valueChanges.subscribe(hide => {
      this.transferTargetProjectStore.hideFinished = hide;
      this.updateOnSearch(0, this.transferTargetProjectStore.pagination.size);
    });
  }
}
