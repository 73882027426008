import { UpdateEmployeeDocumentNameCommand } from './update-employee-document-name.command';

export class UpdateEmployeeDocumentNameRenewalCommand extends UpdateEmployeeDocumentNameCommand {
  public qualificationId: string;
  constructor(employeeId: string, documentKey: string, fileName: string) {
    super(employeeId, documentKey, fileName);
    this.qualificationId = documentKey.split('/')?.[1];
  }

  static convert(cmd: UpdateEmployeeDocumentNameCommand): UpdateEmployeeDocumentNameRenewalCommand {
    return new UpdateEmployeeDocumentNameRenewalCommand(cmd?.employeeId, cmd?.documentKey, cmd?.fileName);
  }
}
