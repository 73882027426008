import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { MapLocationType } from '../../../enums/map-location-type.enum';
import { MapItemContactLocation } from '../../../interfaces/map-list-item/map-item-search-contact-location.interface';
import { MapItemViewSearchEquipmentLocation } from '../../../interfaces/map-list-item/map-item-search-equipment-location.interface';
import { MapItemSearchProjectLocation } from '../../../interfaces/map-list-item/map-item-search-project-location.interface';
import { MapItemViewStock } from '../../../interfaces/map-list-item/map-item-search-view-stock.interface';
import { MapItemViewOrganisation } from '../../../interfaces/map-list-item/map-item-view-organisation.interface';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';
import { AddressPipe } from 'app/shared/pipes/address.pipe';
import { MapClusterGlobalListItem } from '../../../interfaces/map-list-item/map-cluster-list-item.interface';
import { StockTypeResolver } from 'app/shared/pipes/stock-type.resolver';
import { LanguageService } from 'app/shared/services/language.service';
import { MapClusterItem } from '../../../interfaces/map-cluster-item.interface';


export class ListItemFactory {

  constructor(
    private contactTypeResolver: ContactTypeResolverPipe,
    private translatableStringResolver: TranslatableStringPipe,
    private stockTypeResolver: StockTypeResolver,
    private addressResolver: AddressPipe,
    private languageService: LanguageService,
  ) {}

  public getItem(value: MapClusterItem): MapClusterGlobalListItem {
    switch(value.locationType) {
      case MapLocationType.EQUIPMENT: return this.convertEquipment(value);
      case MapLocationType.CONTACT: return this.convertContact(value);
      case MapLocationType.ORGANISATION: return this.convertOrganisation(value);
      case MapLocationType.PROJECT: return this.convertProject(value);
      case MapLocationType.STOCK: return this.convertStock(value);
    }
  }

  private convertContact(value: MapClusterItem): MapClusterGlobalListItem {
    const {
      contactId: id,
      locationType: type,
      name: title,
      identifier: subTitle,
      type: contactType
    } = <MapItemContactLocation>value;
    return {
      id,
      type,
      icon: this.contactTypeResolver.resolveIcon(contactType),
      title,
      subTitle,
      jumpLinkTooltipText: this.getJumpToTranslation('general.contact.s'),
    };
  }

  private convertEquipment(value: MapClusterItem): MapClusterGlobalListItem {
    const {
      equipmentId: id,
      locationType: type,
      thumbnailKey,
      equipmentName,
      equipmentCustomerSerialNumber,
      equipmentModel,
      equipmentType,
    } = <MapItemViewSearchEquipmentLocation>value;

    const subTitle = [
      equipmentCustomerSerialNumber,
      equipmentModel,
      this.translatableStringResolver.transform(equipmentType?.category1Name),
      this.translatableStringResolver.transform(equipmentType?.category2Name),
    ].filter(Boolean).join(', ');

    return {
      id,
      type,
      thumbnailKey,
      icon: thumbnailKey ? null : faTruckPlow,
      title: equipmentName || equipmentModel,
      subTitle,
      jumpLinkTooltipText: this.getJumpToTranslation('general.equipm.s'),
    };
  }

  private convertOrganisation(value: MapClusterItem): MapClusterGlobalListItem {
    const {
      organisationId: id,
      locationType: type,
      organisationName: title,
      organisationNumber: subTitle
    } = <MapItemViewOrganisation>value;

    return {
      id,
      type,
      fontSet: 'icon-location_outline',
      title,
      subTitle,
      hideJumpLink: true,
    };
  }

  private convertProject(value: MapClusterItem): MapClusterGlobalListItem {
    const { projectId: id, locationType: type, projectName: title, projectNumber: subTitle } = <MapItemSearchProjectLocation>value;
    return {
      id,
      type,
      fontSet: 'icon-projects02_white_outline',
      title,
      subTitle,
      jumpLinkTooltipText: this.getJumpToTranslation('general.project.s'),
    };
  }

  private convertStock(value: MapClusterItem): MapClusterGlobalListItem {
    const { stockId: id, locationType: type, stockName: title, stockAddress, stockType } = <MapItemViewStock>value;
    return {
      id,
      type,
      icon: this.stockTypeResolver.resolveIcon(stockType),
      title,
      subTitle: this.addressResolver.transform(stockAddress),
      jumpLinkTooltipText: this.getJumpToTranslation('general.stock.s'),
    };
  }

  private getJumpToTranslation(entityTranslationKey: string): string {
    return this.translate('general.labels.jumpTo', { value: this.translate(entityTranslationKey) });
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
