import { LanguageService } from 'app/shared/services/language.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthName'
})

@Injectable()
export class MonthNamePipe implements PipeTransform {
  private keys = {
    '1': `general.units.time.month.names.january`,
    '2': `general.units.time.month.names.february`,
    '3': `general.units.time.month.names.march`,
    '4': `general.units.time.month.names.april`,
    '5': `general.units.time.month.names.may`,
    '6': `general.units.time.month.names.june`,
    '7': `general.units.time.month.names.july`,
    '8': `general.units.time.month.names.august`,
    '9': `general.units.time.month.names.september`,
    '10': `general.units.time.month.names.october`,
    '11': `general.units.time.month.names.november`,
    '12': `general.units.time.month.names.december`
  };

  constructor(private languageService: LanguageService) {
  }

  transform(value): string {

    if (!(typeof value === 'number')) {
      value = parseInt(value, 10);
    }

    return this.keys[value] ? this.translate(this.keys[value]) : '';
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
