import { BehaviorSubject, Subject } from 'rxjs';
import { ResizeColumnMouseDownEvent } from '../../../interfaces/resize-column-mouse-down-event.interface';
import { NgZone, Renderer2 } from '@angular/core';
import { ColumnConfig } from '../../../interfaces/column-config.interface';
import { MatTable } from '@angular/material/table';


interface ColumnData {
  name: string;
  header: HTMLElement;
  resizeHandler: HTMLElement;
  width: number;
  unlistens: (() => void)[];
}


export class ColumnConfigsController {
  public readonly mouseDown = new Subject<ResizeColumnMouseDownEvent>();
  public readonly columnState = new BehaviorSubject<Record<string, ColumnData>>({});

  private readonly defaultWidth = 150;
  private readonly cellClassPrefix = 'cdk-column-';
  private readonly headerCellTag = 'mat-header-cell';
  private readonly resizeTag = 'span';
  private readonly resizeClass = 'resize-handler';
  private readonly resizeIndicatorClass = 'resize-handler-indicator';

  private configs: Record<string, ColumnData> = {};
  private initialConfigs: Record<string, Pick<ColumnData, 'name' | 'width'>> = {};

  private get tableElement(): HTMLElement {
    return this.matTable['_elementRef'].nativeElement;
  }

  constructor(
    private matTable: MatTable<any>,
    private renderer: Renderer2,
    private zone: NgZone,
  ) { }

  public setColumnConfigs(configs: ColumnConfig[] = []): void {
    if (!this.isSameColumnConfigs(configs)) {
      this.initialConfigs = configs.reduce((acc, cnf) => ({ ...acc, [cnf.name]: cnf }), {});
      this.reset();
    }
  }

  public getColumnDataByName(columnName: string): ColumnData {
    return this.configs[columnName];
  }

  public getConfigByName(columnName: string): ColumnConfig {
    const { name, width } = this.getColumnDataByName(columnName) || {};
    return (name && width) ? { name, width } : null;
  }

  public checkChanges(): void {
    if (!this.isSameColumnAmount() || !this.isHeadersHaveResizeHandler()) {
      this.reset();
    }
  }

  public setWidth(column: string, width: number): void {
    this.configs[column].width = width;
    this.dispatchColumnState();
  }

  public destroy(): void {
    this.clear();
  }

  private getHeaderCellSelector(column: string): string {
    return `${this.headerCellTag}.${this.getCellClassByName(column)}`;
  }

  private getCellClassByName(column: string): string {
    return `${this.cellClassPrefix}${column.replaceAll('.', '-')}`;
  }

  private reset(): void {
    this.clear();
    this.buildColumnConfigs();
    this.dispatchColumnState();
  }

  private clear(): void {
    Object.values(this.configs).forEach(cnf => {
      (cnf.unlistens || []).forEach(unlisten => unlisten?.());
      if (cnf.header && cnf.resizeHandler && cnf.header.contains(cnf.resizeHandler)) {
        this.renderer.removeChild(cnf.header, cnf.resizeHandler);
      }
    });
  }

  private buildColumnConfigs(): void {
    const names = this.getResizableColumnNames();
    this.configs = names.reduce((acc, name) => ({
      ...acc,
      [name]: this.createColumnData(name)
    }), {} as Record<string, ColumnData>);
  }

  private dispatchColumnState(): void {
    this.columnState.next(this.configs);
  }

  private getResizableColumnNames(): string[] {
    return this.getCurrentTableColumnNames().filter(name => Boolean(this.initialConfigs[name]));
  }

  private getCurrentTableColumnNames(): string[] {
    const [headerDef] = this.matTable?._contentHeaderRowDefs?.toArray() ?? [{ columns: [] }];
    return Array.from(headerDef.columns);
  }

  private createColumnData(columnName: string): ColumnData {
    const name = columnName;
    const width = this.createColumnDataWidth(columnName);
    const header = this.createColumnDataHeader(columnName);
    const resizeHandler = this.createColumnDataResizeHandler(header);
    const unlistens = this.createColumnDataUnlistens(name, resizeHandler);

    return { name, width, header, resizeHandler, unlistens };
  }

  private createColumnDataWidth(columnName: string): number {
    const width = (this.configs[columnName]?.width || this.initialConfigs[columnName]?.width || 0);
    return width > 0 ? width : this.defaultWidth;
  }

  private createColumnDataHeader(columnName: string): HTMLElement {
    return this.tableElement.querySelector(this.getHeaderCellSelector(columnName));
  }

  private createColumnDataResizeHandler(header: HTMLElement): HTMLElement {
    const resizeHandlerElement = this.renderer.createElement(this.resizeTag);
    this.renderer.addClass(resizeHandlerElement, this.resizeClass);

    const resizeIndicator = this.renderer.createElement(this.resizeTag);
    this.renderer.addClass(resizeIndicator, this.resizeIndicatorClass);
    this.renderer.appendChild(resizeHandlerElement, resizeIndicator);

    this.renderer.appendChild(header, resizeHandlerElement);

    return resizeHandlerElement;
  }

  private createColumnDataUnlistens(column: string, handler: HTMLElement): (() => void)[] {
    return this.zone.runOutsideAngular(() => {
      const clickUnlisten = this.renderer.listen(handler, 'click', (event: PointerEvent) => {
        event.stopPropagation();
        event.preventDefault();
      });

      const mousedownUnlisten = this.renderer.listen(handler, 'mousedown', (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        this.mouseDown.next({ column, handler, event });
      });

      return [clickUnlisten, mousedownUnlisten];
    });
  }

  private isSameColumnConfigs(configs: ColumnConfig[]): boolean {
    return (configs || []).every(({ name, width }) =>
      // the same column name set was passed
      Boolean(this.initialConfigs[name])
      // the actual configs set have the same width (or corresponding column is absent at all)
      && (!Boolean(this.configs[name]) || width === this.configs[name].width)
    );
  }

  private isSameColumnAmount(): boolean {
    const resizableColumns = this.getResizableColumnNames();
    const actualResizableColumns = Object.keys(this.configs);

    if (resizableColumns.length === actualResizableColumns.length) {
      const resizableColumnsSet = new Set(resizableColumns);
      return actualResizableColumns.every(column => resizableColumnsSet.has(column));
    }
    return false;
  }

  private isHeadersHaveResizeHandler(): boolean {
    return Object.values(this.configs).every(({ header, resizeHandler }) =>
      header &&
      resizeHandler &&
      this.tableElement.contains(header) &&
      header.contains(resizeHandler));
  }

}
