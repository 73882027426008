import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { ViewOrganisation } from '../../../../organisation/contract/view-organisation.interface';
import { AbstractControl, UntypedFormBuilder, FormControlStatus, UntypedFormGroup, Validators } from '@angular/forms';
import { PagedResponse } from '../../../../../shared/contract/page-response.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';
import { MobileEquipmentAddSelections } from '../../../../../shared/mobile-equipment-add-selections.interface';
import { EquipmentTypesService } from '../../../shared/equipmenttypes.service';
import { asyncValidatorFactory } from '../../../../../shared/custom-validators/async-validator.factory';
import { SerialNumberInUseValidator } from '../../../../../shared/custom-validators/serial-number-in-use.validator';
import { LocalUserStorageService } from '../../../../../shared/services/local-user-storage.service';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from '../../../../../shared/router-history';
import { ScanCodeStore } from '../../../../../shared/services/scan-code.store';
import { AssetType } from '../../../../../shared/contract/asset-type.enum';
import { ScanCodeInUseValidator } from '../../../../../shared/custom-validators/scan-code-in-use.validator';
import { ScanCodeService } from '../../../../../shared/services/scan-code.service';
import { StockService } from '../../../shared/stock-service';
import { ViewStock } from '../../../shared/view-stock.interface';
import { StockType } from '../../../../../shared/enums/stock-type';

declare const EB: any;

export interface EquipmentOverallData {
  status: FormControlStatus;
  scannerActive: boolean;
  qrCode: string;
  name: string;
  internalSerialNumber: string;
  equipmentTypeId: string;
  organisationId: string;
  stockId: string;
}

@Component({
  selector: 'bh-mobile-equipment-add-overall-data',
  templateUrl: './mobile-equipment-add-overall-data.component.html',
  styleUrls: ['./mobile-equipment-add-overall-data.component.scss']
})
export class MobileEquipmentAddOverallDataComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public allEquipmentTypes: EquipmentTypeFilterView[] = [];
  public allOrganisations: ViewOrganisation[] = [];
  public allStocks: ViewStock[] = [];
  public overallInformationFormGroup: UntypedFormGroup;
  public scannerActive = false;
  public isEnterprise = false;
  public torchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public torchAvailable = false;
  public searchingEquipmentByQrCode = false;
  public alreadyPairedAsset: string;
  public alreadyPairedAssetType: string;

  @Output() public OnChange: EventEmitter<EquipmentOverallData> = new EventEmitter<EquipmentOverallData>();
  @ViewChild('qrCode', { static: true }) private qrCodeInput: ElementRef<HTMLInputElement>;
  private qrCodeResult: string;
  private savedSelections: MobileEquipmentAddSelections;
  private successSubscription: Subscription;
  private errorSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private equipmentTypesService: EquipmentTypesService,
              private scanCodeStore: ScanCodeStore,
              private scanCodeService: ScanCodeService,
              private equipmentsStore: EquipmentsDataSource,
              private organisationsService: OrganisationsService,
              private stockService: StockService,
              private keycloakService: KeycloakService,
              private matSnackBar: MatSnackBar,
              protected route: ActivatedRoute,
              private userConfigurationService: UserConfigurationService,
              protected router: Router,
              protected detector: ChangeDetectorRef,
              protected routerHistory: RouterHistory) {
    super(keycloakService, router, route, routerHistory);
  }

  public get serialNumber(): AbstractControl | null {
    return this.overallInformationFormGroup.get('internalSerialNumber');
  }

  public get scanCode(): AbstractControl | null {
    return this.overallInformationFormGroup.get('qrCode');
  }

  public showScanner(): void {
    if (this.isEnterprise) {
      EB.Barcode.enable({}, (code) => {
          this.parseCode(code.data);
      });
    }
    this.scannerActive = true;
  }

  public toggleTorch(): void {
    this.torchEnabled.next(!this.torchEnabled.value);
  }

  public activateQrEdit(event: MouseEvent): void {
    event.preventDefault();
    const qrCodeField: AbstractControl = this.getQrCodeField();

    if (qrCodeField.invalid) {
      return;
    }

    if (qrCodeField.disabled) {
      qrCodeField.enable();
      // Safari keyboard workaround. `click()` into the input to open the
      // keyboard and `focus()` to set the cursor into the input

      // This is only possible while being in a click-event.
      this.qrCodeInput.nativeElement.click();
      this.qrCodeInput.nativeElement.focus();
    } else {
      this.qrCodeResult = this.getQrCodeField().value;
      if (this.qrCodeResult) {
        this.scanCodeStore.searchScanCodeInUse(this.qrCodeResult);
      }
      qrCodeField.disable();
    }
  }

  public getQrCodeField(): AbstractControl {
    return this.overallInformationFormGroup.get('qrCode');
  }

  public getEquipmentTypeIdField(): AbstractControl {
    return this.overallInformationFormGroup.get('equipmentTypeId');
  }

  public getOrganisationIdField(): AbstractControl {
    return this.overallInformationFormGroup.get('organisationId');
  }

  public getStockIdField(): AbstractControl {
    return this.overallInformationFormGroup.get('stockId');
  }

  public parseCode(code: string): void {
    this.qrCodeResult = code;
    this.scanCodeStore.searchScanCodeInUse(code);
    this.searchingEquipmentByQrCode = true;
  }

  public qrCodeFieldDisabled(): boolean {
    return this.getQrCodeField().disabled;
  }

  public getNameField(): AbstractControl {
    return this.overallInformationFormGroup.get('name');
  }

  public ngOnInit(): void {
    this.buildForm();
    const {qrCode, name} = window.history.state;
    if (name) {
      this.getNameField().setValue(name);
    }
    if (qrCode) {
      this.getQrCodeField().setValue(qrCode);
    }
    this.getEquipmentTypes();
    this.getOrganisations(this.keycloakService.getUserCustomerId());
    this.getStocks();
    this.setSerialNumberInUseValidator();
    this.setScanCodeInUseValidator();
    this.savedSelections = this.userConfigurationService.getMobileEquipmentAddSelections();
    this.successSubscription = this.scanCodeStore.scanCodeInUse.subscribe(scanEntry => {
      if (scanEntry && scanEntry.assetType === AssetType.EQUIPMENT) {
        this.equipmentsStore.getDispositionEquipmentMatchingScanCode(scanEntry.scanCode)
          .subscribe(res => {
            this.alreadyPairedAssetType = scanEntry.assetType;
            this.alreadyPairedAsset = res.equipmentName || res.equipmentModel;
          });
        this.getQrCodeField().setValue('');
      } else {
        this.alreadyPairedAssetType = scanEntry.assetType;
        this.alreadyPairedAsset = null;
      }
      this.searchingEquipmentByQrCode = false;
    });
    this.errorSubscription = this.scanCodeStore.scanCodeInUseError.subscribe((error: number) => {
      if (error && error === 404) {
        this.alreadyPairedAsset = '';
        this.scannerActive = false;
        this.getQrCodeField().setValue(this.qrCodeResult);
        if (this.isEnterprise) {
          EB.Barcode.disable();
        }
        this.detector.detectChanges();
      }
      this.searchingEquipmentByQrCode = false;
    });

    if (LocalUserStorageService.getUserValue('isEnterprise', this.getUserUserId()) === 'true') {
      this.isEnterprise = true;
    }
  }

  public ngOnDestroy(): void {
    this.successSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
    if (this.isEnterprise && this.scannerActive) {
      EB.Barcode.disable();
    }
  }

  public closeScanner(): void {
    this.scannerActive = false;
  }

  /**
   * Necessary because Safari will automatically select the first entry, but the formGroup does
   * not have a value yet. And pressing done on the preset value will result in a validation error.
   */
  private preSelectOrganisation(): void {
    if (this.savedSelections.organisationId) {
      this.getOrganisationIdField().setValue(this.savedSelections.organisationId);
    } else {
      this.getOrganisationIdField().setValue(this.allOrganisations[0].organisationId);
    }
  }

  private getOrganisations(customerId: string): void {
    if (customerId) {
      this.organisationsService.getOrganisationsByCustomerId(customerId)
      .subscribe((data: ViewOrganisation[]) => {
            data.map(organisation => {
              let customizedOrganisation = {isSelected: false, ...organisation};
              this.allOrganisations.push(customizedOrganisation);
            });
            this.preSelectOrganisation();
          },
          error => {
            console.error('get organisations by customer id error: ', error);
          });
    } else {
      this.getAllOrganizations();
    }
  }

  private getAllOrganizations(): void {
    this.organisationsService.getOrganisations(0, 200)
    .subscribe((data: PagedResponse<ViewOrganisation>) => {
          data.content.map(organisation => {
            let customizedOrganisation = {isSelected: false, ...organisation};
            this.allOrganisations.push(customizedOrganisation);
          });
          this.preSelectOrganisation();
        },
        error => {
          console.error('get organisations by customer id error: ', error);
        });
  }

  private getStocks(): void {
    this.stockService.getStocks()
    .subscribe((data: ViewStock[]) => {
          data.forEach(stock => {
            this.allStocks.push(stock);
            if (stock.stockType === StockType.MAIN) {
              this.getStockIdField().setValue(stock.stockId);
            }
          });
        },
        error => {
          console.error('get stocks error: ', error);
        });
  }

  private getEquipmentTypes(): void {
    this.equipmentTypesService.getAllEquipmentTypesGroupedByCategory1()
    .subscribe((equipmentTypes: EquipmentTypeFilterView[]) => {
      this.allEquipmentTypes = equipmentTypes;
      if (this.savedSelections.equipmentTypeId) {
        this.getEquipmentTypeIdField().setValue(this.savedSelections.equipmentTypeId);
      } else {
        this.getEquipmentTypeIdField().setValue(equipmentTypes[0].subTypes[0].equipmentTypeId);
      }
    });
  }

  private buildForm(): void {
    this.overallInformationFormGroup = this.formBuilder.group({
      qrCode: [{value: '', disabled: true}],
      name: ['', Validators.required],
      internalSerialNumber: ['', Validators.required],
      equipmentTypeId: ['', Validators.required],
      organisationId: ['', Validators.required],
      stockId: ['', Validators.required],
    });

    this.overallInformationFormGroup.statusChanges.subscribe(status => {
      this.emitChanges(status);
    });
  }

  private setSerialNumberInUseValidator(): void {
    this.serialNumber.setAsyncValidators(
        asyncValidatorFactory((value) => SerialNumberInUseValidator.isValid(value, this.equipmentsStore))
    );
  }

  private setScanCodeInUseValidator(): void {
    this.scanCode.setAsyncValidators(
      asyncValidatorFactory((value) => ScanCodeInUseValidator.isValid(value, this.scanCodeService))
    );
  }

  private emitChanges(status: FormControlStatus): void {
    const value: EquipmentOverallData = this.overallInformationFormGroup.getRawValue();
    value.status = status;
    value.scannerActive = this.scannerActive;
    this.OnChange.emit(value);

    if (value.organisationId) {
      this.userConfigurationService.saveMobileEquipmentAddOrganisationSelection(value.organisationId);
    }

    if (value.equipmentTypeId) {
      this.userConfigurationService.saveMobileEquipmentAddEquipmentTypeSelection(value.equipmentTypeId);
    }
  }
}
