<mat-card class="entity-creation">
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-icon>assignment</mat-icon>
      <span>{{'modules.equipment.technicalField.createNewTechField'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!technicalFieldEditForm.valid || isSaveButtonDisabled">
          {{'general.buttons.save'|translate}} |
        <mat-icon>save</mat-icon>
      </button>
      <button (click)="goBack()" mat-raised-button>{{'general.buttons.close'|translate}}</button>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <form [formGroup]="technicalFieldEditForm" novalidate>
        <div class="entity-creation-form-left" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label>{{'modules.equipment.technicalField.uniqueKey'|translate}}</mat-label>
            <input  matInput
                    formControlName="technicalFieldKey"
                    [maxlength]="fieldLimit.SHORT_IDENTIFIER">

            <mat-error *ngIf="technicalFieldKey.hasError('keyAlreadyExist')">
              {{'modules.equipment.technicalField.keyAlreadyExists'|translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'modules.equipment.technicalField.uniqueName'|translate}}</mat-label>
            <input  matInput
                    formControlName="technicalFieldName"
                    [maxlength]="fieldLimit.LONG_IDENTIFIER">

            <mat-error *ngIf="technicalFieldName.hasError('nameAlreadyExist')">
              {{'modules.equipment.technicalField.nameAlreadyExists'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="entity-creation-form-left" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label>{{'modules.equipment.technicalField.fieldType'|translate}}</mat-label>
            <mat-select formControlName="technicalFieldType">
              <mat-option *ngFor="let technicalFieldType of technicalFieldTypes" [value]="technicalFieldType">
                {{ technicalFieldType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'modules.equipment.technicalField.fieldUnit'|translate}}</mat-label>
            <mat-select formControlName="technicalFieldUnit">
              <mat-option [value]="">--</mat-option>
              <mat-option *ngFor="let technicalFieldUnit of technicalFieldUnits" [value]="technicalFieldUnit">
                {{ technicalFieldUnit }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </form>
    </div>
  </mat-card-content>
</mat-card>

