import { Injectable } from '@angular/core';
import { CustomerLabel } from 'app/shared/contract/customer-label.interface';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import { CommonFilterViewConverter } from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { BulkItemCategoryFilterView } from 'app/shared/contract/filter/filter-view/bulk-item-category-filter-view.interface';
import { BulkItemFilterCollectionView } from 'app/shared/contract/filter/filter-view/bulk-item-filter-collection/bulk-item-filter-collection-view.interface';
import { BulkItemFilterType } from 'app/shared/contract/filter/filter-view/bulk-item-filter-collection/bulk-item-filter-type.enum';
import { BulkItemTypeFilterView } from 'app/shared/contract/filter/filter-view/bulk-item-type-filter-view.interface';
import { CommonFilterView } from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import { FiltersBaseService } from 'app/shared/contract/filter/filters-base-service.class';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BulkItemFilterParamsClass } from '../bulk-item-request-params/bulk-item-filter-params.class';
import { BulkItemFilterParams } from '../bulk-item-request-params/bulk-item-filter-params.interface';
import { BulkItemsService } from '../bulk-items.service';

type BulkItemFilterItemView =
  CommonFilterView
  | BulkItemCategoryFilterView
  | BulkItemTypeFilterView;

  type UpdateFilterByTypeCommand = {
    [key in BulkItemFilterType]?: BulkItemFilterItemView[];
  }
@Injectable()
export class BulkItemFilterService extends FiltersBaseService<BulkItemFilterParams> {
  private _isDefaultFiltersApplied = new BehaviorSubject<boolean>(false);
  public readonly isReady = this._isDefaultFiltersApplied.asObservable();
  private _labels = new BehaviorSubject<CustomerLabel[]>([]);
  public readonly labels = this._labels.asObservable();
  private isFiltersInit = false;
  private isInitialFiltersChange = false;
  private isFiltersChanged = false;
  private isNoFiltersApplied = true;

  public get isSearchFiltersApplied(): boolean {
    return this.isFiltersChanged && !this.isNoFiltersApplied;
  }

  constructor(private bulkItemsService: BulkItemsService) {
    super(new BulkItemFilterParamsClass())
  }

  public getFilterParams(): BulkItemFilterParams {
    this.checkFilterInit();
    return super.getFilterParams();
  }

  public updadeFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.checkFilterInit();
    super.updateFilterParams(commands, updateFilters);
  }

  public updateFilters(): void {
    this.checkFilterInit();
    if (this._isDefaultFiltersApplied.value) {
      this.filterChangeCheck();
      this.getFilters().subscribe(filters => {
        const convertedFilters = this.convertToFilterTypes(filters);
        this.mergeToResultFilters(convertedFilters);
        this.updateLabelsList(filters);
        this.onFiltersUpdated.emit();
      });
    }
  }

  public updateFilterLabel(): void {
    if (this.hasParamValue(BulkItemFilterType.LABEL)) {
      this.updateFilters();
    } else {
      this.getCustomerLabels().subscribe(filters => this.updateFilterByType(BulkItemFilterType.LABEL, filters));
    }
  }

  private updateLabelsList(filters: BulkItemFilterCollectionView): void {
    this._labels.next(filters.labels);
  }

  private checkFilterInit(): void {
    if (!this.isFiltersInit) {
      this.isFiltersInit = true;
      this._isDefaultFiltersApplied.next(true);
      this.updateFilters();
    }
  }

  private getCustomerLabels(): Observable<CustomerLabel[]> {
    return this.bulkItemsService.getCustomerLabels(this.params.getFilterParams());
  }

  private getFilters(): Observable<BulkItemFilterCollectionView> {
    return this.bulkItemsService.getBulkItemFilters(this.params.getFilterParams());
  }

  private convertToFilterTypes(filters: BulkItemFilterCollectionView): FilterType[] {
    return Object.keys(filters)
                 .map((key: BulkItemFilterType) => this.createConverter(key, filters[key] as BulkItemFilterItemView[]))
                 .map(converter => converter && converter.getFilters())
                 .filter(Boolean);
  }

  private createConverter(type: BulkItemFilterType, filters: BulkItemFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case BulkItemFilterType.LABEL:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.LABEL
        );
      case BulkItemFilterType.CATEGORY:
        return new CommonFilterViewConverter(
          this.convertCategoryFilterViewToCommonFIlterView(filters as BulkItemCategoryFilterView[]) as CommonFilterView[],
          this.params,
          FilterTypes.CATEGORY
        );
      case BulkItemFilterType.TYPE:
        return new CommonFilterViewConverter(
          this.convertTypeFilterViewToCommonFIlterView(filters as BulkItemTypeFilterView[]) as CommonFilterView[],
          this.params,
          FilterTypes.TYPE
        );
    }
  }

  private filterChangeCheck(): void {
    this.isInitialFiltersChange ? this.isFiltersChanged = true : this.isInitialFiltersChange = true;
    this.isNoFiltersApplied = !this.params.hasAnyParam();
  }

  private convertCategoryFilterViewToCommonFIlterView(
    categoryFilterView: BulkItemCategoryFilterView[]
  ): CommonFilterView[] {
    return categoryFilterView.map(el => {
      return {
        name: el.bulkItemCategory,
        count: el.count,
      }
    })
  }

  private convertTypeFilterViewToCommonFIlterView(
    typeFilterView: BulkItemTypeFilterView[]
  ): CommonFilterView[] {
    return typeFilterView.map(el => {
      return {
        name: el.bulkItemType,
        count: el.count,
      }
    })
  }

  private hasParamValue(type: BulkItemFilterType): boolean {
    return (<BulkItemFilterParamsClass>this.params).hasParamValue(type);
  }

  private updateFilterByType(type: BulkItemFilterType, filters: BulkItemFilterItemView[]): void {
    this.updateFilterByTypes({ [type]: filters });
  }

  private updateFilterByTypes(command: UpdateFilterByTypeCommand): void {
    Object.keys(command).forEach((type: BulkItemFilterType) => {
      const converter = this.createConverter(type, command[type]);
      this.pushToResultFilters(converter.getFilters());
    });
    this.onFiltersUpdated.emit();
  }
}
