import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { RolesService } from '../../../../userrole/shared/roles.service';
import { RoleRecipient } from '../../role-recipient';
import { ViewRole } from '../../../../userrole/contract/view-role.interface';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchUser } from '../../../../userrole/contract/search-user.interface';
import { UsersStore } from '../../../../userrole/shared/users.store';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { EmailRecipient } from '../../email-recipient';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { EquipmentAlarmRuleSelectionType } from '../../../contract/equipment-alarm-rule-selection-type.enum';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { AlarmLevel } from '../../../shared/enums/alarm-level.enum';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AlarmRuleControl } from 'app/modules/notifications/contract/alarm-rule-control.enum';
import { BehaviorSubject } from 'rxjs';
import { AlarmType } from 'app/modules/notifications/shared/enums/alarm-type.enum';

@Component({
  selector: 'bh-additional-data',
  templateUrl: './additional-data.component.html',
  styleUrls: ['./additional-data.component.scss']
})
@UntilDestroy()
export class AdditionalDataComponent implements OnInit, AfterViewChecked {
  @Input() set alarmType(type: AlarmType) {
    this._alarmType = type;
    if (this.dataForm) {
      this.selectedAlarmTypeChanged();
    }
  };

  get alarmType(): AlarmType {
    return this._alarmType;
  }

  @Input('form') dataForm: UntypedFormGroup;
  @Input() labelOptions: string[] = [];
  @Input() equipmentTypeOptions: EquipmentTypeFilterView[];
  @Input() emailRecipients: EmailRecipient[];
  @Input() roleRecipients: RoleRecipient[];

  @ViewChild('emailRecipientSearchRef') emailRecipientSearchRef: ElementRef<HTMLInputElement>;
  @ViewChild('roleRecipientSearchRef') roleRecipientSearchRef: ElementRef<HTMLInputElement>;

  public readonly controlNames = AlarmRuleControl;
  public readonly alarmLevels: string[] = Object.keys(AlarmLevel);
  public readonly selectionTypes = EquipmentAlarmRuleSelectionType;
  public readonly selectionTypesOptions: string[] = Object.keys(EquipmentAlarmRuleSelectionType);
  public readonly fieldLimit = FieldLimit;
  public readonly pageSizeRecipients = 100;

  private _alarmType: AlarmType;
  public emailRecipientSearchControl = new UntypedFormControl();
  public foundUsers: SearchUser[] = [];
  private userSuggestionsSubject = new BehaviorSubject<SearchUser[]>([]);
  public userSuggestions$ = this.userSuggestionsSubject.asObservable();

  public roleRecipientSearchControl = new UntypedFormControl();
  public foundRoles: ViewRole[] = [];
  private roleSuggestionsSubject = new BehaviorSubject<ViewRole[]>([]);
  public roleSuggestions$ = this.roleSuggestionsSubject.asObservable();

  constructor(private usersStore: UsersStore, private rolesService: RolesService) { }

  public ngOnInit(): void {
    this.emailRecipients = this.emailRecipients ? this.emailRecipients : [];
    this.roleRecipients = this.roleRecipients ? this.roleRecipients : [];
    this.emailToggleControl.setValue(this.emailRecipients.length > 0 || this.roleRecipients.length > 0);
    this.initListeners();
    this.searchRecipientEmails('');
  }

  public ngAfterViewChecked(): void {
    this.selectedAlarmTypeChanged();
  }

  public get alarmMessageControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_ALARM_MESSAGE);
  }
  public get alarmLevelControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_ALARM_LEVEL);
  }
  public get selectionTypeControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_SELECTION_TYPE);
  }
  public get labelsControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_LABELS);
  }
  public get equipmentTypesControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_EQUIPMENT_TYPES);
  }
  public get emailToggleControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_EMAIL_TOGGLE);
  }
  public get emailRecipientsControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_EMAIL_RECIPIENTS);
  }
  public get roleRecipientsControl(): AbstractControl {
    return this.dataForm.get(this.controlNames.DATA_ROLE_RECIPIENTS);
  }

  public get selectedType(): string {
    return this.selectionTypeControl.value;
  }

  public disabledSelectionType(selectionType: string): boolean {
    switch (selectionType) {
      case EquipmentAlarmRuleSelectionType.LABEL:
        return !this.labelOptions || !this.labelOptions.length;
      case EquipmentAlarmRuleSelectionType.EQUIPMENT_TYPE:
        return !this.equipmentTypeOptions || !this.equipmentTypeOptions.length;
      case EquipmentAlarmRuleSelectionType.ALL_EQUIPMENT:
        return false;
    }
    return true;
  }

  public isNeedSelectionType(): boolean {
    return this._alarmType !== AlarmType.TRANSPORT_TASK_CREATION
      && this._alarmType !== AlarmType.TRANSPORT_TASK_RETURNED;
  }

  // EMAILS
  public addEmail(event: MatAutocompleteSelectedEvent): void {
    const user: SearchUser = event.option.value;
    this.emailRecipients.push(EmailRecipient.from(user));
    this.emailRecipientsControl.setValue(this.emailRecipients.map(({ userId }) => userId));
    this.emailRecipientSearchRef.nativeElement.value = '';
    this.emailRecipientSearchControl.setValue('');
    this.updateEmailSuggestions();
  }

  public removeEmail(email: EmailRecipient): void {
    this.emailRecipients = [ ...this.emailRecipients.filter(({ userId }) => email.userId !== userId) ];
    this.emailRecipientsControl.setValue(this.emailRecipients.map(({ userId }) => userId));
    this.updateEmailSuggestions();
  }

  // ROLES
  public addRole(event: MatAutocompleteSelectedEvent): void {
    const role: ViewRole = event.option.value;
    this.roleRecipients.push(RoleRecipient.from(role));
    this.roleRecipientsControl.setValue(this.roleRecipients.map(({ roleId }) => roleId));
    this.roleRecipientSearchRef.nativeElement.value = '';
    this.roleRecipientSearchControl.setValue('');
    this.updateRoleSuggestions();
  }

  public removeRole(roleEmail: RoleRecipient): void {
    const index = this.roleRecipients.indexOf(roleEmail);
    if (index >= 0) {
      this.roleRecipients.splice(index, 1);
    }
    this.roleRecipientsControl.setValue(this.roleRecipients.map(({ roleId }) => roleId));
    this.updateRoleSuggestions();
  }

  private initListeners(): void {
    this.usersStore.users.pipe(
      untilDestroyed(this)
    ).subscribe((users: SearchUser[]) => {
      this.foundUsers = users;
      this.updateEmailSuggestions();
    });

    this.rolesService.getRolesByUserCustomer().pipe(
      untilDestroyed(this)
    ).subscribe((roles: ViewRole[]) => {
      this.foundRoles = roles;
      this.updateRoleSuggestions();
    });

    this.emailRecipientSearchControl.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchTerms: string) => this.searchRecipientEmails(searchTerms));

    this.roleRecipientSearchControl.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(() => this.updateRoleSuggestions());

    this.selectionTypeControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((selectedType: EquipmentAlarmRuleSelectionType) => this.selectedTypeChanged(selectedType));
  }

  private searchRecipientEmails(searchTerms: string): void {
    this.usersStore.searchTerms = searchTerms;
    this.usersStore.updateListing(0, this.pageSizeRecipients);
  }

  private updateEmailSuggestions(): void {
    const selectedEmails = new Set([ ...(this.emailRecipients.map(({ userId }) => userId)) ]);
    this.userSuggestionsSubject.next(this.foundUsers.filter(({ userId }) => !selectedEmails.has(userId)));
  }

  private updateRoleSuggestions(): void {
    const searchTerms = this.roleRecipientSearchControl.value;
    this.roleSuggestionsSubject.next(searchTerms ? this.filterAndEliminateDuplicates(searchTerms) : this.eliminateDuplicates());
  }

  private filterAndEliminateDuplicates(searchTerm: string): ViewRole[] {
    return this.foundRoles.filter(role => {
      return ((role.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
        && (!this.roleRecipients.map((recipient: RoleRecipient) => recipient.name).includes(role.name)));
    })
  }

  private eliminateDuplicates(): ViewRole[] {
    return this.foundRoles.filter(role => {
      return !this.roleRecipients.map((recipient: RoleRecipient) => recipient.name).includes(role.name);
    });
  }

  private selectedTypeChanged(type: EquipmentAlarmRuleSelectionType): void {
    this.updateRequiredValidator(this.controlNames.DATA_LABELS, type === EquipmentAlarmRuleSelectionType.LABEL);
    this.updateRequiredValidator(this.controlNames.DATA_EQUIPMENT_TYPES, type === EquipmentAlarmRuleSelectionType.EQUIPMENT_TYPE);
  }

  private selectedAlarmTypeChanged(): void {
    if (this.isNeedSelectionType()) {
      this.enableSelectionTypeControls();
    } else {
      this.disableSelectionTypeControls();
    }
  }

  private enableSelectionTypeControls(): void {
    [this.selectionTypeControl, this.labelsControl, this.equipmentTypesControl].forEach(control => control.enable());
  }

  private disableSelectionTypeControls(): void {
    [this.selectionTypeControl, this.labelsControl, this.equipmentTypesControl].forEach(control => {
      control.setValue(null, { emitEvent: false });
      control.disable();
    });
  }

  private updateRequiredValidator(controlName: string, isRequired: boolean): void {
    const control: UntypedFormControl = this.dataForm.get(controlName) as UntypedFormControl;
    control.setValidators(isRequired ? Validators.required : null);
    control.updateValueAndValidity();
  }
}
