<div fxFlexFill>
  <div #scheduler_rental class="dhx_cal_container" fxFlexFill [hidden]="!rentalStore.hasContent || rentalStore.emptyResContent">
    <div class="dhx_cal_navline">
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center">
          <div class="dhx_cal_tab" name="timeline_year_tab"></div>
          <div class="dhx_cal_tab" name="timeline_month_tab"></div>
          <div class="dhx_cal_tab" name="timeline_week_tab"></div>
        </div>

        <div class="dhx_cal_date" fxFlex fxShow="false" fxShow.lg="true" fxShow.xl="true"></div>

        <div fxLayoutAlign="end center">
          <div class="dhx_cal_today_button"></div>
          <div class="dhx_cal_prev_button"></div>
          <div class="dhx_cal_next_button"></div>
        </div>
      </div>
    </div>

    <div class="dhx_cal_header">
    </div>
    <div class="dhx_cal_data">
    </div>
  </div>

  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center"
       *ngIf="!rentalStore.hasContent && rentalStore.hasSearchFilters">
    <p>{{'modules.disposition.rentalBoard.foundMoreThanEquipments'|translate:{ value: 250 } }}</p>
  </div>

  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center"
       *ngIf="!rentalStore.hasSearchFilters">
    <p>{{'modules.disposition.rentalBoard.useSearch'|translate}}</p>
  </div>

  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center"
       *ngIf="rentalStore.emptyResContent">
    <p>{{'modules.disposition.rentalBoard.noSearchResult'|translate}}</p>
  </div>
</div>
