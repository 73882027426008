import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../navigation/sidenav/shared/sidenav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bh-chat-container',
  templateUrl: './chat.container.component.html',
  styleUrls: ['./chat.container.component.scss']
})
export class ChatContainerComponent implements OnInit {

  constructor(private sidenavService: SidenavService, private router: Router) {
  }

  ngOnInit(): void {
    this.sidenavService.open();
    this.sidenavService.closed.subscribe(() => {
      this.router.navigate(['.', {
        outlets: {sidenav: null}
      }], {skipLocationChange: true});
    });
  }

  closeChat(): void {
    this.sidenavService.close();
  }

}
