export class TreeItemStatus {
  public checked = false;
  public indeterminate = false;

  public setIndeterminate(): void {
    this.indeterminate = true;
    this.checked = false;
  }

  public setIndeterminateIfUnchecked(): void {
    if (!this.checked) {
      this.indeterminate = true;
    }
  }

  public setSelection(checked: boolean): void {
    if (checked) {
      this.setChecked();
    } else {
      this.setUnchecked();
    }
  }

  public setChecked(): void {
    this.indeterminate = false;
    this.checked = true;
  }

  public setUnchecked(): void {
    this.indeterminate = false;
    this.checked = false;
  }
}
