<!--TODO selectedTab functionality is a workaround for a known bug-->
<!-- https://github.com/angular/material2/issues/5269 -->

<div class="m-view-attachment m-view-tabs" fxFlexFill>
  <mat-tab-group fxFlexFill (selectedTabChange)="selectedTab = $event.tab">

    <!--Docuware-->
    <mat-tab [label]="'general.docuware'|translate|uppercase" *ngIf="hasConnector">
      <bh-mobile-attachment-docuware [documents]="docuwareDocuments"
                                     [sortParameter]="documentsSortParameter"
                                     [loading]="docuwareRequestPending"
                                     [serviceAvailable]="docuwareServiceAvailable"
                                     [pendingDownloads]="pendingDocuwareDownloads"
                                     (changeSortParameter)="changeDocumentsSortParameter($event)"
                                     (downloadRequested)="downloadDocuwareDocument($event)">
      </bh-mobile-attachment-docuware>
    </mat-tab>

    <!--Documents-->
    <mat-tab [label]="'general.document.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-accordion displayMode="flat">
        <mat-expansion-panel hideToggle="true" *ngFor="let document of documents">
          <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="70px" class="m-view-attachment__panel-header">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div class="file-info"
                   fxFlex="calc(100% - 50px)" fxLayout="row" fxLayoutAlign="start stretch"
                   (click)="openFile($event, document)">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(document)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 60px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{document.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(document.fileName)}}</span>
                    <span>{{getFileNameExtension(document.fileName)}}</span>
                  </div>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row" fxLayoutAlign="space-between stretch">
            <button mat-button class="download" fxFlex="50">
              <a href="{{document.url}}" target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
            <button mat-button class="delete" fxFlex="50"
                    *ngIf="canUpdateDocuments"
                    (click)="deleteDocument(document.documentKey)">
              {{'general.buttons.delete'|translate|uppercase}}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <bh-mobile-file-upload
        *ngIf="hasAnyAuthority([authorities.PROJECT_UPDATE, authorities.PROJECT_ADD_ATTACHMENTS])"
        [itemId]="project?.projectId"
        uploaderType="PROJECTS">
      </bh-mobile-file-upload>
    </mat-tab>
  </mat-tab-group>
</div>
