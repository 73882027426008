import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { ThumbnailSize } from 'app/shared/components/test-place/secured-image/enums/thumbnail-size.enum';
import { MapClusterGlobalListItem } from 'app/shared/modules/map/interfaces/map-list-item/map-cluster-list-item.interface';


@Component({
  selector: 'bh-cluster-list-item-container',
  templateUrl: './cluster-list-item-container.component.html',
  styleUrls: ['./cluster-list-item-container.component.scss']
})
export class ClusterListItemContainerComponent {
  @Input() item: MapClusterGlobalListItem;
  @Output() onNavigate = new EventEmitter<MapClusterGlobalListItem>();

  protected ThumbnailSize = ThumbnailSize;
  protected jumpIcon = faAngleRight;
}
