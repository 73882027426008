import { ReportExportFormat } from './report-export-format.enum';
import { ReportParameter } from './report-parameter.enum';

export class CreateReportDefinitionCommand {
  reportDefinitionName: string;
  reportDefinitionExportFormat: ReportExportFormat;
  reportDefinitionTemplateKey: string;
  reportDefinitionCustomers: string[];
  reportDefinitionParameters: ReportParameter;
  reportIncludesLocations: boolean;
}
