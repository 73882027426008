import { Pipe, PipeTransform } from '@angular/core';
import { TransportViewProject } from 'app/modules/transportation/contracts/transport/transport-view-project.interface';
import { TransportView } from 'app/modules/transportation/contracts/transport/transport-view.interface';
import { TransportItemContext } from '../../contract/transport-item-context.interface';
import { TransportItemLocationType } from '../../enums/transport-item-location-type.enum';
import { TransportItemType } from '../../enums/transport-item-type.enum';
import { TransportContextBase } from './transport-context-base.class';

@Pipe({
  name: 'transportContextProject'
})
export class TransportContextProjectPipe extends TransportContextBase implements PipeTransform {

  public transform(transport: TransportView, showLink = true): TransportItemContext {
    let context: TransportItemContext = {
      locationType: TransportItemLocationType.PROJECT,
      itemType: TransportItemType.PROJECT,
      state: transport?.state,
      name: null, address: null, navigationInfo: null
    };
    if (transport?.projectInfo) {
      const { address, projectId } = transport?.projectInfo;
      context = {
        ...context,
        name: this.getNameTitleProjectInfo(transport?.projectInfo),
        address: this.getAddressTitle(address),
        navigationInfo: showLink
          ? {
            url: this.getNavigationUrl(projectId, TransportItemType.PROJECT),
            isLatestItem: false
          }
          : null
      }
    }
    return context;
  }

  private getNameTitleProjectInfo(projectInfo: TransportViewProject): string {
    const { name, projectCostCenter } = projectInfo;
    return (name && name.trim() ? name.trim() : '')
      + (projectCostCenter && projectCostCenter.trim() ? ` (${projectCostCenter.trim()})` : '');
  }

}
