import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentLocationType } from '../contract/equipment-location-type-enum';

@Pipe({
  name: 'equipmentLocationTypeDisplay'
})
export class EquipmentLocationTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(value: EquipmentLocationType): string {
    switch (value) {
      case EquipmentLocationType.STOCK:
        return this.translate('shared.pipes.equipmentLocationType.stock');
      case EquipmentLocationType.PROJECT:
        return this.translate('shared.pipes.equipmentLocationType.project');
    }
    return null;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
