import { LifeCycle } from '../../../../../contract/life-cycle';
import { LanguageService } from '../../../../../../../shared/services/language.service';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { MatDialog } from '@angular/material/dialog';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from 'app/core/keycloak';
import { LifeCycleType } from '../../../../../contract/lifecycle-type.enum';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { filter, takeWhile, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { isDefined } from '../../../../../../../shared/utils';
import { LifeCycleTask } from '../../../../../contract/life-cycle-task';
import { MaintenanceCategoryPipe } from '../../../../../../../shared/pipes/maintenance-category.pipe';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faReceipt, faUser } from '@fortawesome/pro-light-svg-icons';
import { EquipmentEmployeeRolePipe } from '../../../../../../../shared/pipes/equipment-employee-role.pipe';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EquipmentsService } from '../../../../../shared/equipments.service';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentViewTimelineComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public readonly faUserIcon: IconDefinition = faUser;
  public readonly faReceiptIcon: IconDefinition = faReceipt;

  public equipment: ViewEquipment;
  public lifeCycleTypes = LifeCycleType;
  public lifeCycles: LifeCycle[];
  public firstOperatingHoursId: string;
  public firstMileageId: string;
  public filterForm: UntypedFormGroup;
  public isRequestInProgress = false;

  protected componentActive = true;

  constructor(protected equipmentsStore: EquipmentsDataSource,
              protected equipmentsService: EquipmentsService,
              protected employeeRolePipe: EquipmentEmployeeRolePipe,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected maintenanceCategoryPipe: MaintenanceCategoryPipe,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      maintenanceTypes: new UntypedFormControl(this.equipmentsStore.lifeCycleTypesFilter),
      startDate: new UntypedFormControl(this.equipmentsStore.lifeCycleLogDateFromFilter),
      endDate: new UntypedFormControl(this.equipmentsStore.lifeCycleLogDateToFilter),
      termFilter: new UntypedFormControl(this.equipmentsStore.lifeCycleTermFilter),
    });
    this.subscribeToCurrentEquipment();
    this.subscribeToFilterForm();
    this.subscribeToLifecycles();
    this.subscribeToManualOperatingHoursAddedOrDeleted();
    this.subscribeToManualMileageAddedOrDeleted();
  }

  private getEventControl(): AbstractControl {
    return this.filterForm.get('maintenanceTypes');
  }

  public getDateStartControl(): AbstractControl {
    return this.filterForm.get('startDate');
  }

  public getDateEndControl(): AbstractControl {
    return this.filterForm.get('endDate');
  }

  private getTermControl(): AbstractControl {
    return this.filterForm.get('termFilter');
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private subscribeToCurrentEquipment(): void {
    this.equipmentsStore.currentEquipment
    .pipe(
      untilDestroyed(this),
      takeWhile(() => this.componentActive),
      filter(isDefined),
      filter((newEquipment: ViewEquipment) => this.isChangedCurrentEquipment(newEquipment))
    )
    .subscribe((currentEquipment: ViewEquipment) => {
      const isSameCurrentEquipment = this.equipment && this.equipment.equipmentId === currentEquipment.equipmentId;
      this.equipment = currentEquipment;

      this.fetchLatestOperatingHoursLifecycleId();
      this.fetchLatestMileageLifecycleId();

      if (!isSameCurrentEquipment) {
        this.equipmentsStore.initLifeCyclePagination();
        this.onLifecycleScroll();
      }
    });
  }

  private fetchLatestOperatingHoursLifecycleId() {
    this.equipmentsService.getLatestOperatingHours(this.equipment.equipmentId)
      .subscribe(lifecycle => {
        if (!!lifecycle) {
          this.firstOperatingHoursId = lifecycle.lifecycleId
        }
      });
  }

  private fetchLatestMileageLifecycleId() {
    this.equipmentsService.getLatestMileage(this.equipment.equipmentId)
      .subscribe(lifecycle => {
        if (!!lifecycle) {
          this.firstMileageId = lifecycle.lifecycleId
        }
      });
  }

  private isChangedCurrentEquipment(newEquipment: ViewEquipment): boolean {
    return !this.equipment ||
      this.equipment.equipmentId !== newEquipment.equipmentId;
  }

  private subscribeToFilterForm() {
    this.getTermControl().valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this),
        takeWhile(() => this.componentActive))
      .subscribe(value => {
        this.equipmentsStore.lifeCycleTermFilter = value;
        this.equipmentsStore.getLifeCycles(this.equipment.equipmentId);
      });

    this.getDateStartControl().valueChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
        takeWhile(() => this.componentActive))
      .subscribe(value => {
        this.equipmentsStore.lifeCycleLogDateFromFilter = this.toDateStr(value);
        this.equipmentsStore.getLifeCycles(this.equipment.equipmentId);
      });

    this.getDateEndControl().valueChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
        takeWhile(() => this.componentActive))
      .subscribe(value => {
        this.equipmentsStore.lifeCycleLogDateToFilter = this.toDateStr(value);
        this.equipmentsStore.getLifeCycles(this.equipment.equipmentId);
      });

    this.getEventControl().valueChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
        takeWhile(() => this.componentActive))
      .subscribe(value => {
        this.equipmentsStore.lifeCycleTypesFilter = value;
        this.equipmentsStore.getLifeCycles(this.equipment.equipmentId);
      });
  }

  private toDateStr(val: string): string {
    return (!!val && val !== '') ? moment(val).format('YYYY-MM-DD') : null
  }

  private subscribeToLifecycles(): void {
    this.equipmentsStore.lifeCycles
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((lifeCycles) => {
        this.lifeCycles = lifeCycles;
        this.isRequestInProgress = false;
      });
  }

  public subscribeToManualOperatingHoursAddedOrDeleted(): void {
    this.equipmentsStore.equipmentManualOperatingHoursAddedOrDeleted
      .pipe(untilDestroyed(this))
      .subscribe(() => this.fetchLatestOperatingHoursLifecycleId());
  }

  public subscribeToManualMileageAddedOrDeleted(): void {
    this.equipmentsStore.equipmentManualMileageAddedOrDeleted
      .pipe(untilDestroyed(this))
      .subscribe(() => this.fetchLatestMileageLifecycleId());
  }

  public onLifecycleScroll(): void {
    if (this.equipmentsStore.lifeCycleLast || this.isRequestInProgress) {
      return;
    }

    this.equipmentsStore.lifeCyclePaginationSize = this.equipmentsStore.lifeCyclePagination.size + 5;
    this.isRequestInProgress = true;
    this.equipmentsStore.getLifeCycles(this.equipment.equipmentId);
  }

  public getIcon(lifeCycle: LifeCycle): string | IconDefinition {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return this.faReceiptIcon;
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        return 'icon-violence_outline';
      case LifeCycleType.OPERATING_HOURS_UPDATE:
        return 'icon-workinghours01_outline';
      case LifeCycleType.MILEAGE_UPDATE:
        return 'icon-odometer_outline';
      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        return 'icon-general2_outline';
      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        return 'icon-' + this.maintenanceCategoryPipe.transform((<LifeCycleTask>lifeCycle).category, 'icon');
      case LifeCycleType.COMPLETED_REMINDER:
        return 'icon-disposition_outline';
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        return this.faUserIcon;
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        return this.faUserIcon;
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        return this.faUserIcon;
    }
  }

  public useCustomFontIcon(lifeCycle: LifeCycle): boolean {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return false;
      default:
        return true;
    }
  }

  public getFullName(firstName: string, name: string): string {
    return (firstName ? firstName + ' ' : '') + name;
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
