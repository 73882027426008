export enum TransportTaskState {
  // Deprecated statuses
  NEW = 'NEW',
  POSTPONED = 'POSTPONED',

  // Used statuses
  DRAFT = 'DRAFT',
  RETURNED = 'RETURNED',
  PLANNABLE = 'PLANNABLE',
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
  CANCELED = 'CANCELED',

  // Group filter states
  OPEN = 'OPEN',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
}
