<div fxFill class="transfer-cart-overview" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
  <div class="transfer-cart-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <fa-icon [icon]="faSync"></fa-icon>
      <div fxLayout="column">
        <span>{{'modules.disposition.assetChangeTypeOfUse.changeOfUse'|translate}}</span>
      </div>
    </div>
    <div class="close" (click)="close()">
      <mat-icon>clear</mat-icon>
    </div>
  </div>

  <div class="transfer-cart-items" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <bh-transfer-items  [transferItems]="transferItemsFiltered | async" [itemsRemovable]="false"
                            [sourceLabel]="'modules.disposition.bulkItemTransfer.inProject'|translate"
                            [targetLabel]="'modules.disposition.bulkItemTransfer.toProject'|translate" [showSelectAll]="true"
                            [transferItemsWithStatus]="projectDataSource.transferItemsWithStatus"
                            (searchFilterApplied)="updateFilterControl($event)"
                            (amountUpdated)="updateAmount($event)"
                            [typeOfUseChanging]="true"
                            [saveSelectionStatusesWhenFiltering]="true">
        </bh-transfer-items>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <div fxLayout="column">
          <div class="header" fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between stretch">
            <div class="title" fxLayoutAlign="center center">
              {{'modules.disposition.assetChangeTypeOfUse.setTheUsageType'|translate}}
            </div>
          </div>
          <form [formGroup]="statusForm">
            <div class="content" fxLayout="column" fxLayoutAlign="start stretch">
              <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
                <mat-form-field floatLabel="always" fxFlex>
                  <mat-label>{{'modules.disposition.assetChangeTypeOfUse.selectFreeOfChargeStatus' |translate}}</mat-label>
                  <mat-select required formControlName="status">
                    <mat-option *ngFor="let statusType of statusTypes" [value]="statusType">
                      <div class="custom-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15">
                        <fa-icon [icon]="iconService.resolveStatusIcon(statusType)"></fa-icon>
                        <span>{{statusType | typeOfUseStatus}}</span>
                      </div>
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="statusControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
                </mat-form-field>
                <mat-form-field floatLabel="always" fxFlex>
                  <mat-label>{{'modules.disposition.assetChangeTypeOfUse.reason' |translate}}</mat-label>
                  <mat-select formControlName="reason">
                    <mat-option *ngFor="let statusReason of statusReasons" [value]="statusReason">
                      <div class="custom-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15">
                        <fa-icon [icon]="iconService.resolveReasonIcon(statusReason)"></fa-icon>
                        <span>{{statusReason | typeOfUseStatusReason}}</span>
                      </div>
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="reasonControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
                </mat-form-field>
              </div>
              <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
                <bh-date-time-range-select  fxFlex="100"
                                            [formGroup]="statusForm"
                                            [startDateTimeControl]=startDateTimeControl
                                            [endDateTimeControl]=endDateTimeControl
                                            [defaultStartDateTime]="defaultStartDateTime()"
                                            [defaultEndDateTime]="null"
                                            [endDateRequired]="false">
                </bh-date-time-range-select>
              </div>
              <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
                <mat-form-field floatLabel="always" fxFlex>
                  <mat-label>{{'general.buttons.addComment' | translate}}</mat-label>
                  <input formControlName="comment" matInput>
                </mat-form-field>
              </div>
              <div class="inputs-line" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <bh-view-validity-change-type-of-use [transferItems]="transferItemsFiltered | async"
                                                     [statusForm]="statusForm"
                                                     [project]="projectDataSource.currentProject | async"
                                                     (isItemsStatusLegal)="applyItemsStatus($event)">
                </bh-view-validity-change-type-of-use>
              </div>
              <div class="inputs-line" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <button mat-raised-button class="apply-button" type="button" [disabled]="saveDisabled | async" (click)="update()">
                  {{'modules.disposition.assetChangeTypeOfUse.applyUsageType'|translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
