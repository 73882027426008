import { LANGUAGE_LABEL_MAP, Languages } from 'app/shared/enums/languages.enum';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service'
import { LangSwitcherOption } from '../contract/lang-switcher-option';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LangChangeEvent } from '@ngx-translate/core';
import { UsersService } from 'app/modules/userrole/shared/users.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'bh-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
  public switcherControl = new UntypedFormControl();
  public devPrefixControl = new UntypedFormControl(null, Validators.pattern('^[^"]+$'));
  public options: Observable<LangSwitcherOption[]>;
  public isDevCurrentLanguage = false;
  private selectedLanguage = this.languageService.getCurrentLanguage();

  public tC = new FormControl();

  constructor(private languageService: LanguageService, private usersService: UsersService) { }

  public ngOnInit(): void {
    this.initOptions();
    this.configControl();
    this.tC.disable();
  }

  public ngOnDestroy(): void {}

  public switchLanguage(language: string = this.selectedLanguage): void {
    if (this.isDevCurrentLanguage) {
      this.languageService.setDevLanguagePrefix(this.devPrefixControl.value);
    }
    this.languageService.switchAndReload(language);
  }

  private initOptions(): void {
    this.devPrefixControl.setValue(this.languageService.getDevLanguagePrefix());
    this.options = this.usersService.getAvailableLanguages()
        .pipe(
          map(languagesSet => [...languagesSet]
            .map((lng: string) => ({ value: lng, label: LANGUAGE_LABEL_MAP[lng] ?? lng }))
            .sort((a, b) => a.label.localeCompare(b.label))),
          untilDestroyed(this));
  }

  private configControl(): void {
    this.isDevCurrentLanguage = this.selectedLanguage === Languages.DEV;
    this.switcherControl.setValue(this.selectedLanguage);
    this.languageService.onLanguageChange.pipe(
      untilDestroyed(this)
    ).subscribe(({ lang }: LangChangeEvent) => {
      this.selectedLanguage = lang;
      this.switcherControl.setValue(lang, { emitEvent: false });
    });

    this.switcherControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((lang: string) => {
      this.selectedLanguage = lang;
      this.isDevCurrentLanguage = lang === Languages.DEV;
      if (!this.isDevCurrentLanguage) {
        this.switchLanguage(lang);
      }
    });

    this.devPrefixControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => this.devPrefixControl.markAsTouched());
  }
}
