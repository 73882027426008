import { TransportTaskPriority } from './../enums/transport-task-priority.enum';
import { LanguageService } from './../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transportPriority'
})
export class TransportPriorityPipe implements PipeTransform {
  private definitionSet = {
    [TransportTaskPriority.LOWEST]: this.translate('modules.transportation.transportPriority.lowest'),
    [TransportTaskPriority.LOW]: this.translate('modules.transportation.transportPriority.low'),
    [TransportTaskPriority.MEDIUM]: this.translate('modules.transportation.transportPriority.medium'),
    [TransportTaskPriority.HIGH]: this.translate('modules.transportation.transportPriority.high'),
    [TransportTaskPriority.HIGHEST]: this.translate('modules.transportation.transportPriority.highest'),
  }

  constructor(private languageService: LanguageService) {
  }

  public transform(value: TransportTaskPriority | string): string {
    return this.definitionSet[value];
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
