import { UpdateEmployeeDocumentDescriptionCommand } from './update-employee-document-description-command';

export class UpdateEmployeeDocumentDescriptionRenewalCommand extends UpdateEmployeeDocumentDescriptionCommand {
  public qualificationId: string;
  constructor(employeeId: string, documentKey: string, description: string) {
    super(employeeId, documentKey, description);
    this.qualificationId = documentKey.split('/')?.[1];
  }

  static convert(cmd: UpdateEmployeeDocumentDescriptionCommand): UpdateEmployeeDocumentDescriptionRenewalCommand {
    return new UpdateEmployeeDocumentDescriptionRenewalCommand(cmd?.employeeId, cmd?.documentKey, cmd?.description);
  }
}
