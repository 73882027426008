import { Directive, Input, OnInit } from '@angular/core';
import { LocationAddressResolver } from 'app/shared/modules/map/services/location-address.resolver';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';
import { MapLocationMarker } from 'app/shared/modules/map/interfaces/map-location-marker.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { Utils } from 'app/shared/utils';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';

interface MapInfoTelematicsDateLog {
  title: string;
  date: string | Date;
}

@Directive()
export class MapInfoTelematics<T> implements OnInit, InfoWindowComponent {

  @Input() value: MapLocationMarker<T>;
  public readonly loading = this.locationAddressResolver.loading;
  public readonly address = this.locationAddressResolver.address;
  public readonly currentLocale = this.languageService.getCurrentLocale();
  public infoDates: MapInfoTelematicsDateLog[] = [];

  constructor(
    private locationAddressResolver: LocationAddressResolver,
    private languageService: LanguageService,
  ) {}

  public ngOnInit(): void {
    this.getAddress(this.value);
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private getAddress(value: LatLonLocation): void {
    if (Utils.isValidLocation(value)) {
      this.locationAddressResolver.resolveLocationAddress({
        lat: this.value?.location?.lat,
        lon: this.value?.location?.lon,
      });
    }
  }

}
