import { WidgetConfigParams } from '../contract/widget-config-param';
import { WidgetType } from '../contract/widget-type.enum';
import { WidgetDefinition } from '../widget-definition';
import { LastCompletedTasksWidgetComponent } from './last-completed-tasks-widget.component';

export class LastCompletedTasksWidgetConfiguration extends WidgetDefinition {
  organisationIds: string[] = [];
  equipmentTypeCategories: string[] = [];

  constructor({ title, titleIcon }: WidgetConfigParams) {
    super();
    this.widgetType = WidgetType.LAST_COMPLETED_TASKS;
    this.type = LastCompletedTasksWidgetComponent;
    this.title = title;
    this.minItemCols = 16;
    this.minItemRows = 10;
    this.cols = 20;
    this.rows = 12;
    this.x = 0;
    this.y = 0;
    this.titleIcon = titleIcon;
  }
}
