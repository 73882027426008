import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceDynamicField } from '../enums/maintenance-dynamic-field.enum';

@Pipe({
  name: 'maintenanceTypeFieldTranslation'
})
export class MaintenanceTypeFieldTranslationPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(value: any): any {
    switch (value) {
      case MaintenanceDynamicField.INSPECTION_DURATION:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.inspectionDuration');
      case MaintenanceDynamicField.LAST_INSPECTION:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.lastInspectionDate');
      case MaintenanceDynamicField.INSPECTOR:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.inspector');
      case MaintenanceDynamicField.PERFORMED_BY:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.performedBy');
      case MaintenanceDynamicField.NEXT_INSPECTION_DATE:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.nextInspectionDate');
      case MaintenanceDynamicField.DESCRIPTION:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.description');
      case MaintenanceDynamicField.PLANNED_DURATION:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.plannedDuration');
      case MaintenanceDynamicField.TRANSACTION_NUMBER:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.transactionNumber');
      case MaintenanceDynamicField.REPORT:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.result');
      case MaintenanceDynamicField.DENSITY_TO:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.densityTo');
      case MaintenanceDynamicField.DENSITY_FROM:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.densityFrom');
      case MaintenanceDynamicField.SIGN_BVG:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.bvgA3');
      case MaintenanceDynamicField.SIGN_VOLTAGE:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.voltageIndicator');
      case MaintenanceDynamicField.SIGN_ACID:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.acidDensityIndicator');
      case MaintenanceDynamicField.VOLTAGE:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.voltage');
      case MaintenanceDynamicField.CELL_VOLTAGE_TO:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.cellVoltageTo');
      case MaintenanceDynamicField.CELL_VOLTAGE_FROM:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.cellVoltageFrom');
      case MaintenanceDynamicField.STATUS:
        return this.translate('modules.maintenance.maintenanceTypeAddEdit.maintenanceTypeFields.state');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
