import { Component } from '@angular/core';
import { BaseChatComponent } from '../base-chat.component';
import { CommunicationDataSource } from '../../shared/communication.datasource';
import { KeycloakService } from '../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../shared/communication.service';
import { RouterHistory } from '../../../../shared/router-history';

@Component({
  selector: 'bh-chat-details',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends BaseChatComponent {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected communicationDataSource: CommunicationDataSource,
              protected communicationService: CommunicationService,
              protected routerHistory: RouterHistory) {
    super(authService, router, activatedRoute, communicationDataSource, communicationService, routerHistory);
  }
}
