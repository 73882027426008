import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../core/guards/can-activate-child-authority';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';
import {
  TelematicsUnitProfileListComponent
} from './telematics-unit-profile-list/telematics-unit-profile-list.component';
import { Authorities } from '../../shared/enums/authorities.enum';
import { ModuleWithProviders } from '@angular/core';

const telematicUnitsProfileRoutes: Routes = [
  {
    path: 'telematic-unit-profile',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: TelematicsUnitProfileListComponent,
        data: {
          authorities: [Authorities.SUPERADMIN_VIEW],
        },
      },
      {
        path: 'edit',
        component: TelematicsUnitProfileListComponent,
        data: {
          authorities: [Authorities.SUPERADMIN_VIEW],
          edit: true
        },
      }
    ]
  }
];

export const telematicsUnitProfilesRouting: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(telematicUnitsProfileRoutes);
