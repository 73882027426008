import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'bh-step-dialog',
  templateUrl: './step-dialog.component.html',
  styleUrls: ['./step-dialog.component.scss']
})
export class StepDialogComponent implements OnInit {

  @Input() title: string;
  @Input() icon: IconDefinition;
  @Input() fontSet: string;

  constructor() { }

  ngOnInit() {
  }

}
