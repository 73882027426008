import { TimelineEvent } from './timeline-event';
import { ViewEquipmentProjectAssignment } from 'app/modules/equipment/contract/view-equipment-project-assignment.interface';
import * as moment from 'moment';

export class EquipmentNotAvailableEvent extends TimelineEvent {

  public conflictingAssignment: ViewEquipmentProjectAssignment;

  constructor(projectId: string, conflictingAssignment: ViewEquipmentProjectAssignment) {
    super(projectId + '/' + conflictingAssignment.assignmentId,
      projectId + '/' + conflictingAssignment.equipmentId,
      conflictingAssignment.startDate,
      conflictingAssignment.endDate,
      conflictingAssignment.chargeDate ? new Date(moment(conflictingAssignment.chargeDate).format('YYYY-MM-DD')) : null,
      conflictingAssignment.projectName,
      true,
      false,
      'white');
    this.conflictingAssignment = conflictingAssignment;
  }
}

export function isEquipmentNotAvailableEvent(event: TimelineEvent): event is EquipmentNotAvailableEvent {
  return (<EquipmentNotAvailableEvent>event).conflictingAssignment !== undefined &&
   (<EquipmentNotAvailableEvent>event).conflictingAssignment.equipmentId !== undefined;
}
