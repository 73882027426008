<div class="equipment-history">
  <span *ngIf="!historyTable || historyTable.length === 0">
  &nbsp;({{'modules.equipment.equipmentDetailGeneral.noEntriesYet'|translate}})
</span>
  <table mat-table [dataSource]="historyTable" *ngIf="historyTable && historyTable.length > 0" class="custom-evenodd">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> {{'general.date'|translate}} </th>
      <td mat-cell *matCellDef="let row" data-osp-test="cell-date">
        <bh-date-local [date]="row.date"></bh-date-local>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> {{'general.labels.changedBy'|translate}} </th>
      <td mat-cell *matCellDef="let row" data-osp-test="cell-user">
        {{row.user | submitterInfo}}
      </td>
    </ng-container>

    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef> {{'general.property'|translate}} </th>
      <td mat-cell *matCellDef="let row" data-osp-test="cell-field">
        <span *ngIf="row.fieldName"> {{ row.fieldName | translatableString }} </span>
        <span *ngIf="!row.fieldName"> {{row.field | equipmentFields}} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="removed">
      <th mat-header-cell *matHeaderCellDef> {{'general.old'|translate}} </th>
      <td mat-cell *matCellDef="let row" data-osp-test="cell-removed">{{row.removed
        | equipmentHistoryValueFormat : row.field : row.typeOld
        | defaultValue: '-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="added">
      <th mat-header-cell *matHeaderCellDef> {{'general.new'|translate}} </th>
      <td mat-cell *matCellDef="let row" data-osp-test="cell-added">{{row.added
        | equipmentHistoryValueFormat : row.field : row.typeNew
        | defaultValue: '-'}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{'even': row.alternateBgColor }"></tr>

  </table>

</div>
