import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, catchError, concat, distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import { FileDownloadService } from '../../services/file-download.service';

export enum ImageLoadingStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  INVALID = 'INVALID',
  ERROR = 'ERROR',
}

interface ImagePayload {
  status: ImageLoadingStatus;
  src?: SafeUrl;
}

@Component({
  selector: 'bh-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnChanges  {
  @Input() public src: string;
  @Input() public showSpinner = true;
  @Input() public altText = '';

  @Output() public loading = new EventEmitter<boolean>();

  private srcSubj = new BehaviorSubject<string>(null);
  private _disabled = false;
  public status = ImageLoadingStatus;
  public spinnerDiameter = 20;
  public readonly notFoundSvgIcon = 'assets/icons/common/image-question.svg';

  public get spinnerStrokeWidth(): number {
    return this.spinnerDiameter ? Math.max(1, this.spinnerDiameter / 10) : 0;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  public imagePayload: Observable<ImagePayload> = this.srcSubj.pipe(
    filter(Boolean),
    distinctUntilChanged(),
    switchMap(url =>
      concat(
        of({ status: ImageLoadingStatus.PENDING }),
        this.loadImage(url)
      )
    ),
    tap(payload => this.loading.next(payload?.status === ImageLoadingStatus.PENDING))
  );

  constructor(private fileDownloadService: FileDownloadService) {}

  public ngOnChanges(): void {
    this.srcSubj.next(this.src);
  }

  private loadImage(imageKey: string): Observable<ImagePayload> {
    return (this.isImageKeyValid(imageKey))
      ? this.fileDownloadService.getImage(imageKey)
        .pipe(
          switchMap(src => of({ status: ImageLoadingStatus.COMPLETED, src })),
          catchError(() => {
            this.disabled = true;
            return of({ status: ImageLoadingStatus.ERROR });
          })
        )
      : of({ status: ImageLoadingStatus.INVALID });
  }

  private isImageKeyValid(imageKey: string): boolean {
    return typeof imageKey === 'string' && Boolean(imageKey);
  }
}
