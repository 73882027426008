import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, timer } from 'rxjs';
import { throttleTime } from 'rxjs/operators';


@UntilDestroy()
@Directive({
  selector: '[bhTimeoutSaveButton]',
  exportAs: 'bhTimeoutSaveButton',
})
export class TimeoutSavebuttonDirective implements OnInit {
  @Input() throttleTime = 3000;

  @Output() throttledClick = new EventEmitter<PointerEvent>();
  @Output() throttleOn = new EventEmitter<boolean>();

  private clicks = new Subject<PointerEvent>();

  constructor() { }

  public ngOnInit() {
    this.throttleOn.emit(false);
    this.clicks.pipe(
      throttleTime(this.throttleTime),
      untilDestroyed(this)
    ).subscribe(event => this.throttleStart(event));
  }

  @HostListener('click', ['$event'])
  public clickEvent(event: PointerEvent) {
    event.preventDefault();
    this.clicks.next(event);
  }

  private emitThrottledClick(event: PointerEvent) {
    this.throttledClick.emit(event);
  }

  private throttleStart(event: PointerEvent): void {
    this.emitThrottledClick(event);
    this.throttleOn.emit(true);
    timer(this.throttleTime).subscribe(() => this.throttleEnd());
  }

  private throttleEnd(): void {
    this.throttleOn.emit(false);
  }
}
