<div class="rename-equipment-type">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{'modules.equipment.equipmentTypeRenameDialog.renameType'|translate:{ value: oldValue } }}</h1>
  <div mat-dialog-content>
    {{'modules.equipment.equipmentTypeRenameDialog.shouldTypeBeRenamed'|translate:{ oldValue: oldValue, newValue: newValue } }}
  </div>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">{{'modules.equipment.equipmentTypeRenameDialog.no'|translate}}</button>
    <button mat-raised-button color="primary" mat-dialog-close="yes">{{'modules.equipment.equipmentTypeRenameDialog.yes'|translate}}</button>
  </mat-dialog-actions>

</div>
