import { LanguageService } from './../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { SalesContractAmortizationType } from '../../contract/sales-contract-amortization-type.enum';

@Pipe({
  name: 'salesContractAmortizationType'
})
export class SalesContractAmortizationTypePipe implements PipeTransform {
  private readonly translationKeys = {
    [SalesContractAmortizationType.LINEAR]: 'shared.pipes.salesContractAmortizationType.linear',
    [SalesContractAmortizationType.DEGRESSIVE]: 'shared.pipes.salesContractAmortizationType.degressive'
  };

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any, args?: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
