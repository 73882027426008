import { PipeTransform } from '@angular/core';
import { FilterValue } from './../filter-value';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { CommonFilterView } from '../filter-view/common-filter-view.interface';

export class CommonFilterViewConverter extends BaseFilterViewConverter<CommonFilterView> {
  constructor(
    originalFilters: CommonFilterView[],
    selectedValues: FilterParams,
    type: FilterTypes,
    private displayNameResolver?: PipeTransform
  ) {
    super(type, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: CommonFilterView): FilterValue {
    return {
      displayName: this.displayNameResolver ? this.displayNameResolver.transform(filterItem.name) : filterItem.name,
      storeName: filterItem.name,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.name),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
