import { LanguageService } from 'app/shared/services/language.service';
import { Modules } from 'app/shared/enums/modules.enum';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { Injectable } from '@angular/core';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LandingPageItem } from './landing-page-item';
import * as _ from 'lodash';

@Injectable()
export class LandingPageListItemsService {
  private landingListItems: LandingPageItem[] = [
    {
      path: 'mobile/equipments/list',
      title: this.translate('general.equipm.pl'),
      customIcon: true,
      icon: 'icon-main_equipmentlist01_outline',
      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
      module: undefined,
      offlineAvailable: false,
      enabled: true
    },
    {
      path: 'mobile/projects/list',
      title: this.translate('general.project.pl'),
      customIcon: true,
      icon: 'icon-projects02_white_outline',
      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
      module: Modules.DISPOSITION,
      offlineAvailable: false,
      enabled: true
    },
    {
      path: 'mobile/choose-create',
      title: this.translate('modules.landingPage.landingPageList.create'),
      customIcon: false,
      icon: 'add_circle_outline',
      authorities: [Authorities.EQUIPMENT_CREATE],
      module: undefined,
      offlineAvailable: false,
      enabled: true
    },
    {
      path: 'mobile/equipments/operating-hours/scan-code',
      title: this.translate('general.operatingHour.pl'),
      customIcon: true,
      icon: 'icon-workinghours01_outline',
      authorities: [
        Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
        Authorities.EQUIPMENT_MANAGE_OPERATING_HOURS,
        Authorities.EQUIPMENT_UPDATE],
      module: undefined,
      offlineAvailable: false,
      enabled: true
    },
    {
      path: 'mobile/equipments/add-damage/scan-code',
      title: this.translate('modules.landingPage.landingPageList.damages'),
      customIcon: true,
      icon: 'icon-violence_outline',
      authorities: [
        Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
        Authorities.EQUIPMENT_MANAGE_DAMAGES,
        Authorities.EQUIPMENT_UPDATE
      ],
      module: undefined,
      offlineAvailable: false,
      enabled: true
    },
    {
      path: 'mobile/transfer/choose-process',
      title: this.translate('general.disposition.s'),
      customIcon: true,
      icon: 'icon-disposition_outline',
      authorities: [Authorities.ASSET_FROM_PROJECT_TRANSFER, Authorities.ASSET_FROM_STOCK_TRANSFER],
      module: Modules.DISPOSITION,
      offlineAvailable: false,    // The task BEUT-5494. Temporary solution for offline mode
      enabled: true
    }
  ];
  private itemsSubject: BehaviorSubject<LandingPageItem[]> = new BehaviorSubject(this.landingListItems);
  public items: Observable<LandingPageItem[]> = this.itemsSubject.asObservable();

  constructor(private onlineStatusService: OnlineStatusService, private languageService: LanguageService) {

    this.onlineStatusService.isOnline
      .subscribe(isOnline => {
        const nextItems = _.clone(this.itemsSubject.value);
        nextItems
          .filter(item => isOnline || !item.offlineAvailable)
          .forEach(item => item.enabled = isOnline);

        this.itemsSubject.next(nextItems);
      });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
