import { TransportStatePipe } from '../../../shared/pipes/transport-state.pipe';
import { TransportAssignment } from '../../../contracts/transport/transport-assignment.interface';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  map,
  delay
} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ChooseAddProcessDialogComponent } from 'app/shared/components/choose-add-process-dialog/choose-add-process-dialog.component';
import { DisplayService } from 'app/shared/display.service';
import { VehicleDataSource } from 'app/modules/transportation/shared/services/vehicle.datasource';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { TransportationTimelineSchedulerService } from 'app/modules/transportation/shared/services/transportation-timeline-scheduler.service';
import { VehicleSearch } from 'app/modules/transportation/contracts/transport/vehicle-search.interface';
import { DragDropContainerIdentifier } from 'app/shared/enums/drag-drop-container-identifier.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportAssignmentPayload } from 'app/modules/transportation/shared/contract/transportation-timeline-scheduler/transport-assignment-payload.class';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { environment } from 'environments/environment';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { DatesService } from 'app/shared/services/dates.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { NEVER, of, iif, BehaviorSubject, combineLatest } from 'rxjs';
import { TransportDetailViewService } from 'app/modules/transportation/shared/services/transport-detail-view.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TransportTaskState } from 'app/modules/transportation/shared/enums/transport-task-status.enum';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';
import { TransportTaskChecker } from 'app/modules/transportation/shared/services/transport-task-checker.service';
import { PageType } from 'app/shared/enums/page-type.enum';
import { TransportBoardDataSource } from '../../../shared/services/transport-board.datasource';
import { faArchive } from '@fortawesome/pro-regular-svg-icons';
import { TransportColumnService } from 'app/modules/transportation/shared/services/transport-column.service';
import { ColumnDefinition } from 'app/shared/column-definition';
import { VehicleChecker } from 'app/modules/transportation/shared/services/vehicle-checker.service';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe'
import { DTZ } from 'app/shared/timezone/date-timezone';
import { OspDateTime } from 'app/modules/equipment/contract/osp-date-time';

@UntilDestroy()
@Component({
  selector: 'bh-transportation-board-timeline',
  templateUrl: './transportation-board-timeline.component.html',
  styleUrls: [
    './transportation-board-timeline-styles/transportation-board-timeline.component.scss',
    './transportation-board-timeline-styles/transportation-board-timeline-task-event.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportationBoardTimelineComponent implements SearchFilterActivatedView, OnInit, OnDestroy {
  @ViewChild('scheduler', {static: true}) private schedulerContainer: ElementRef;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  public timelineDateHeader = '';
  public schedulerDayViewColumnWidthPx = this.schedulerService.dayViewColumnWidthPx;
  public readonly schedulerDayViewColumnSmallWidthPx = 27;
  public filters: FilterType[] = this.vehicleDataSource.filters;
  public readonly onFiltersUpdated: EventEmitter<any> = this.vehicleDataSource.onFiltersUpdated;
  public readonly onTotalCountUpdated: EventEmitter<number> = new EventEmitter<number>();
  public searchForm: UntypedFormGroup;
  public routeButton: ButtonInfo = null;
  public readonly containerIdentifiers = DragDropContainerIdentifier;
  public readonly isDragging: Observable<boolean> = this.schedulerService.isDraggingChanges;
  public readonly mousePositionDate = this.schedulerService.mousePositionDateChanges;
  public readonly highlightPredicate = this.schedulerService.highlightPredicateChange;
  public readonly pageType = PageType;
  private vehicles: VehicleSearch[] = [];
  private isVehiclesLoading = new BehaviorSubject<boolean>(true);
  public readonly isDataLoading = combineLatest([
    this.isVehiclesLoading.asObservable(),
    this.vehicleDataSource.currentRequestLoading,
    this.transportDataSource.transportRequestInProgressChanges
  ]).pipe(map((loadStatuses) => loadStatuses.some(Boolean)), distinctUntilChanged());

  public faBoxArchive = faArchive;
  public selectedColumns: ColumnDefinition[];
  public readonly searchSuggestionsField = 'transportationBoardTimelineSuggestions';


  public get pagination(): PaginationInfo {
    return this.vehicleDataSource.pagination;
  }

  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  constructor(
    private vehicleDataSource: VehicleDataSource,
    private columnService: TransportColumnService,
    private dialog: MatDialog,
    private displayService: DisplayService,
    private router: Router,
    private schedulerService: TransportationTimelineSchedulerService,
    private snackBar: MatSnackBar,
    private languageService: LanguageService,
    private transportDetailViewService: TransportDetailViewService,
    private transportDataSource: TransportBoardDataSource,
    private transportStatePipe: TransportStatePipe,
    private equipmentStatusNamePipe: EquipmentStatusNamePipe
  ) {
  }

  private toZonedDateTransportAssignment(assignment: TransportAssignment): TransportAssignment {
    return {
      ...assignment,
      startDate: this.toZonedDateOspDateTime(assignment.startDate),
      endDate: this.toZonedDateOspDateTime(assignment.endDate),
      executionStartDate: this.toZonedDateOspDateTime(assignment.executionStartDate),
      executionEndDate: this.toZonedDateOspDateTime(assignment.executionEndDate),
      dueDate: this.toZonedDateOspDateTime(assignment.dueDate),
    }
  }

  private toZonedDateOspDateTime(datetime: OspDateTime): OspDateTime {
    return !datetime?.timestamp
      ? datetime
      : { ...datetime, timestamp: DTZ.DateTimezone({ store: datetime.timestamp }).displayDate.toISOString() };
  }


  public ngOnInit(): void {
    this.vehicleDataSource.updateFilters();
    this.initListeners();
    this.vehicleDataSource.clearTransportAssignmentsStore();
    this.schedulerService.buildViews(this.schedulerContainer);
    this.updateListing();
  }

  public ngOnDestroy(): void {
    this.schedulerService.clearSchedulerData();
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    this.isVehiclesLoading.next(true);
    this.vehicleDataSource.updateListing(index, size);
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.vehicleDataSource.updateFilterParams(commands);
    this.updateListing(0);
  }

  public onPaginateChange({pageIndex, pageSize}: PageEvent): void {
    this.updateListing(pageIndex, pageSize);
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(term => this.updateOnSearch(term));

    this.searchForm.patchValue({terms: this.vehicleDataSource.searchTerm}, {emitEvent: false});
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public openAddMenu(): void {
    this.dialog.open(ChooseAddProcessDialogComponent);
  }

  private initListeners(): void {
    this.vehiclesListener();
    this.assignmentsListener();
    this.assignmentClickListener();
    this.dateHeaderListener();
    this.equipmentNavigationListener();
    this.totalCountListener();
    this.assignmentCreateListener();
    this.assignmentUpdateListener();
    this.selectedColumnsListener();
    this.pageSizeListener();
    this.viewDateRangeChangesListener();
  }

  private vehiclesListener(): void {
    this.vehicleDataSource.vehicles
      .pipe(untilDestroyed(this))
      .subscribe((vehicles: VehicleSearch[]) => {
        this.vehicles = [...vehicles];
        this.schedulerService.setVehicles(this.vehicles);
        this.isVehiclesLoading.next(false);
      });
  }

  private assignmentsListener(): void {
    this.vehicleDataSource.assignments
      .pipe(map(assignments => assignments.map(item => this.toZonedDateTransportAssignment(item))))
      .pipe(untilDestroyed(this))
      .subscribe((assignments: TransportAssignment[]) => this.schedulerService.setTransportAssignment(assignments));
  }

  private assignmentClickListener(): void {
    this.schedulerService.assignmentClick
      .pipe(
        map(assignment => assignment.transportId),
        untilDestroyed(this)
      )
      .subscribe(transportId => this.transportDetailViewService.openDetailView(transportId));
  }

  private dateHeaderListener(): void {
    this.schedulerService.dateHeaderChanges
      .pipe(untilDestroyed(this))
      .subscribe(header => this.timelineDateHeader = header);
  }

  private equipmentNavigationListener(): void {
    this.schedulerService.equipmentNavigate
      .pipe(
        filter(Boolean),
        untilDestroyed(this))
      .subscribe((equipmentId: string) => this.navigateToEquipment(equipmentId));
  }

  private assignmentCreateListener(): void {
    this.schedulerService.assignmentCreate
      .pipe(
        tap(() => this.transportDataSource.transportRequestInProgress = true),
        tap(({assignment: {transportId}}) => this.revomeFromList(transportId)),
        switchMap(payload => this.checkIsPlannableState(payload)),
        switchMap(payload => this.checkIsAllDataForAssignment(payload)),
        switchMap(payload => this.checkCanCreateAssignment(payload)),
        switchMap(payload => this.checkTypeCompatibilityBeforeCreate(payload)),
        switchMap(payload => this.checkCreationAssignmentInPast(payload)),
        switchMap(payload => this.checkVehicleStatusBeforeCreate(payload)),
        switchMap(payload => this.createAssignment(payload)),
        delay(environment.DELAY_SHORTEST),
        untilDestroyed(this))
      .subscribe((assignment: TransportAssignment) => {
        this.schedulerService.addTransportAssignment(assignment);
        this.transportDataSource.updateListing();
        this.transportDataSource.transportRequestInProgress = false;
      });
  }

  private assignmentUpdateListener(): void {
    this.schedulerService.assignmentUpdate
      .pipe(
        tap(() => this.transportDataSource.transportRequestInProgress = true),
        switchMap(payload => this.checkCanUpdateAssignment(payload)),
        switchMap(payload => this.checkTypeCompatibilityBeforeUpdate(payload)),
        switchMap(payload => this.checkVehicleStatusBeforeUpdate(payload)),
        switchMap(payload => this.updateAssignment(payload)),
        untilDestroyed(this))
      .subscribe((assignment: TransportAssignment) => {
        this.schedulerService.updateTransportAssignment(assignment);
        this.transportDataSource.transportRequestInProgress = false;
      });
  }

  private viewDateRangeChangesListener(): void {
    this.schedulerService.viewDateRangeChanges
      .pipe(untilDestroyed(this))
      .subscribe(dateRange => this.vehicleDataSource.changeAssignmentDateRange(dateRange));
  }

  private selectedColumnsListener(): void {
    this.columnService.selectedColumns
      .pipe(untilDestroyed(this))
      .subscribe((selectedColumns: ColumnDefinition[]) => this.selectedColumns = [...selectedColumns]);
  }

  private pageSizeListener(): void {
    this.columnService.transportBoardViewBoardPageSize
      .pipe(untilDestroyed(this))
      .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
  }

  private checkIsPlannableState(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    if (payload.assignment.state !== TransportTaskState.PLANNABLE) {
      this.openWarningDialog(this.translate(
        'modules.transportation.transportTimeline.warningCreateAssignmentNotPlannableState',
        {value: this.transportStatePipe.transform(TransportTaskState.PLANNABLE)}));
      this.processCanceledAssignment();
      return NEVER;
    }
    return of(payload);
  }

  private checkIsAllDataForAssignment(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    if (!TransportTaskChecker.hasNecessaryDataForAssignment(payload.assignment)) {
      this.openWarningDialog(this.translate('modules.transportation.transportTimeline.warningCreateAssignmentNotAllData'));
      this.processCanceledAssignment();
      return NEVER;
    }
    return of(payload);
  }

  private checkCanCreateAssignment(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    return this.checkCanAssign(
      payload,
      this.translate('modules.transportation.transportTimeline.warningCreateAssignmentVehicleAlreadyHas')
    );
  }

  private checkCanUpdateAssignment(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    return (payload.assignment.equipmentId !== payload.equipmentId)
      ? this.checkCanAssign(
        payload,
        this.translate('modules.transportation.transportTimeline.warningUpdateAssignmentVehicleAlreadyHas'),
        true
      )
      : of(payload);
  }

  private checkCanAssign(
    payload: TransportAssignmentPayload,
    warningMessage: string,
    isUpdating = false
  ): Observable<TransportAssignmentPayload> {
    return this.vehicleDataSource.hasVehicleAssignment(payload.equipmentId, payload.assignment)
      .pipe(
        tap(hasAssignment => {
          if (hasAssignment) {
            this.showWarning(warningMessage);
            this.processCanceledAssignment(isUpdating);
          }
        }),
        switchMap((hasAssignment: boolean) => iif(() => hasAssignment, NEVER, of(payload)))
      );
  }

  private checkTypeCompatibilityBeforeCreate(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    const newAssignment: TransportAssignment = {...payload.assignment, equipmentId: payload.equipmentId};
    if (!this.isCompatibleType(newAssignment)) {
      return this.openAssignmentChangeConfirmationDialog(
        payload,
        this.translate('modules.transportation.transportTimeline.warningIncompatibleType', {value: payload.assignment.transportType})
      );
    }
    return of(payload);
  }

  private checkTypeCompatibilityBeforeUpdate(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    const changedAssignment: TransportAssignment = {...payload.assignment, equipmentId: payload.equipmentId};
    if (!this.isSameVehicleReassignment(payload) && !this.isCompatibleType(changedAssignment)) {
      return this.openAssignmentChangeConfirmationDialog(
        payload,
        this.translate('modules.transportation.transportTimeline.warningIncompatibleType', {value: payload.assignment.transportType}),
        true
      );
    }
    return of(payload);
  }

  private isSameVehicleReassignment(payload: TransportAssignmentPayload): boolean {
    return payload.equipmentId === payload.assignment.equipmentId;
  }

  private isCompatibleType(assignment: TransportAssignment): boolean {
    const vehicle = this.vehicles.find(({equipmentId}) => equipmentId === assignment.equipmentId);
    return !vehicle || vehicle.transportTypes.some(type => type === assignment.transportType);
  }

  private checkCreationAssignmentInPast(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    if (this.isPastDay(payload.startDate)) {
      return this.openAssignmentChangeConfirmationDialog(
        payload,
        this.translate('modules.transportation.transportTimeline.warningAssignmentInPast')
      );
    }
    return of(payload);
  }

  private isPastDay(date: Date): boolean {
    const today = new Date(DatesService.sameTimeZoneAtStartDateUTC(new Date()));
    return date.getTime() <= today.getTime();
  }

  private checkVehicleStatusBeforeCreate(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    const vehicle = this.vehicles.find(({ equipmentId }) => equipmentId === payload?.equipmentId);
    if (!VehicleChecker.isVehicleStatusAssignable(vehicle)) {
      return this.openAssignmentChangeConfirmationDialog(
        payload,
        this.translate(
          'modules.transportation.transportTimeline.warningUnassignableVehicleStatus',
          { status: this.equipmentStatusNamePipe.transform(vehicle.status) }
        )
      )
    }
    return of(payload);
  }

  private checkVehicleStatusBeforeUpdate(payload: TransportAssignmentPayload): Observable<TransportAssignmentPayload> {
    const vehicle = this.vehicles.find(({ equipmentId }) => equipmentId === payload?.equipmentId);
    if (!this.isSameVehicleReassignment(payload) && !VehicleChecker.isVehicleStatusAssignable(vehicle)) {
      return this.openAssignmentChangeConfirmationDialog(
        payload,
        this.translate(
          'modules.transportation.transportTimeline.warningUnassignableVehicleStatus',
          { status: this.equipmentStatusNamePipe.transform(vehicle.status) }
        ),
        true
      )
    }
    return of(payload);
  }

  private createAssignment(assignmentPayload: TransportAssignmentPayload): Observable<TransportAssignment> {
    return this.vehicleDataSource.createAssignment(assignmentPayload)
      .pipe(
        catchError(() => {
          this.showWarning(this.translate('modules.transportation.transportTimeline.assignTaskToVehicleError'));
          this.processCanceledAssignment();
          return NEVER;
        })
      );
  }

  private updateAssignment(assignmentPayload: TransportAssignmentPayload): Observable<TransportAssignment> {
    return this.vehicleDataSource.updateAssignment(assignmentPayload)
      .pipe(
        catchError(() => {
          this.showWarning(this.translate('modules.transportation.transportTimeline.updateVehicleAssignmentError'));
          this.processCanceledAssignment(true);
          return NEVER;
        })
      );
  }

  private openAssignmentChangeConfirmationDialog(
    payload: TransportAssignmentPayload,
    message: string,
    isUpdating = false
  ): Observable<TransportAssignmentPayload> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {disableClose: true});
    dialogRef.componentInstance.confirmMessage = message;
    return dialogRef.afterClosed().pipe(
      tap(result => {
        if (result !== dialogResults.YES) {
          this.processCanceledAssignment(isUpdating);
        }
      }),
      switchMap((result) => iif(() => result === dialogResults.YES, of(payload), NEVER))
    );
  }

  private processCanceledAssignment(isUpdating = false): void {
    this.transportDataSource.transportRequestInProgress = false;
    if (isUpdating) {
      this.schedulerService.revertOriginalEvent();
    } else {
      this.transportDataSource.revertRemovedFromList();
    }
  }

  private navigateToEquipment(equipmentId: string): void {
    if (equipmentId) {
      this.displayService.displayTableAndDetails();
      this.router.navigate(['/assets/equipment/list', equipmentId, 'general']);
    }
  }

  private updateOnSearch(searchTerm: string): void {
    this.vehicleDataSource.searchTerm = searchTerm;
    this.updateListing(0);
  }

  private totalCountListener(): void {
    this.vehicleDataSource.totalCount
      .pipe(untilDestroyed(this))
      .subscribe(count => this.onTotalCountUpdated.emit(count));
  }

  private showWarning(message: string): void {
    this.snackBar.open(message, undefined, {duration: 4000});
  }

  private openWarningDialog(message: string): void {
    const dialogRef = this.dialog.open(WarningDialogComponent, {disableClose: true});
    dialogRef.componentInstance.message = message;
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  public dropContainerDropped(event: CdkDragDrop<any>): void {
    this.schedulerService.dropped(event?.item?.data);
  }

  private revomeFromList(transportId: string): void {
    this.transportDataSource.removeFromListById(transportId);
  }

  public dropContainerExited(): void {
    this.schedulerService.exited();
  }

  public isDaySelected(): boolean {
    return this.schedulerService.isInDayMode();
  }

  public archiveAll(): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate(
      'modules.transportation.transportTimeline.warningMoveDoneToArchiveInBulk',
      {
        oldState: this.translate('modules.transportation.transportState.done'),
        newState: this.translate('modules.transportation.transportState.archived'),
      });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        let startDate = DatesService.formatTimeLineDayToInstantStartOfDay(this.timelineDateHeader, this.languageService.getCurrentLocale());
        let endDate = DatesService.formatTimeLineDayToInstantEndOfDay(this.timelineDateHeader, this.languageService.getCurrentLocale());
        this.transportDataSource.moveDoneToArchive(startDate, endDate)
          .pipe(
            delay(environment.DELAY_SHORTEST),
            untilDestroyed(this))
          .subscribe(() => {
            this.updateListing();
          });
      }
    });
  }
}
