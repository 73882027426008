<ng-container *ngIf="(imagePayload | async) as payload">
  <ng-container [ngSwitch]="payload?.status">

    <ng-container *ngSwitchCase="status.COMPLETED"
      [ngTemplateOutlet]="completed"
      [ngTemplateOutletContext]="{ src: payload?.src }">
    </ng-container>
    <ng-container *ngSwitchCase="status.PENDING"
      [ngTemplateOutlet]="pending">
    </ng-container>
    <ng-container *ngSwitchCase="status.ERROR"
      [ngTemplateOutlet]="error">
    </ng-container>
    <ng-container *ngSwitchCase="status.INVALID"
      [ngTemplateOutlet]="error">
    </ng-container>

  </ng-container>
</ng-container>


<ng-template #completed let-src="src">
  <img *ngIf="src as imageSrc" [src]="imageSrc" [attr.alt]="altText !== '' ? altText : null" data-osp-test="image"/>
</ng-template>

<ng-template #pending>
  <mat-spinner *ngIf="showSpinner"
    [diameter]="spinnerDiameter"
    [strokeWidth]="spinnerStrokeWidth"
    data-osp-test="spinner">
  </mat-spinner>
</ng-template>

<ng-template #error>
  <img [matTooltip]="'shared.attachment.fileNotFound' | translate" [src]="notFoundSvgIcon"
       [alt]="'shared.attachment.fileNotFound' | translate"/>
</ng-template>
