import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ControlErrorMessage } from 'app/modules/osp-ui/types/control-error-message.interface';
import { BehaviorSubject, Subscription, combineLatest, distinctUntilChanged } from 'rxjs';


@Component({
  selector: 'bh-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent {

  private _control: FormControl;
  @Input() set control(control: FormControl) {
    this.refreshControl(control);
  };
  public get control(): FormControl {
    return this._control;
  }

  @Input() set errorMessages(errors: ControlErrorMessage) {
    this.errorMessagesList = Object.keys(errors).map(errorType => ({
      errorType,
      message: errors[errorType]
    }));
    this.calculateActualErrorMessage();
  };

  private errorMessagesList: { errorType: string, message: string }[];
  private controlStateSubs: Subscription;
  private _actualErrorMessage = new BehaviorSubject<string>('');
  public readonly actualErrorMessage = this._actualErrorMessage.asObservable();

  private refreshControl(control: FormControl): void {
    this._control = control;
    this.initListenerControlStateChange();
  }

  private initListenerControlStateChange(): void {
    this.controlStateSubs?.unsubscribe();
    if (this.control) {
      this.calculateActualErrorMessage();
      combineLatest([
        this.control.valueChanges.pipe(distinctUntilChanged()),
        this.control.statusChanges.pipe(distinctUntilChanged())
      ]).subscribe(() => this.calculateActualErrorMessage());
    }
  }

  private calculateActualErrorMessage(): void {
    const error = this.control?.status === 'INVALID' && this.errorMessagesList?.length > 0
      ? this.errorMessagesList.find(({ errorType }) => this.control.hasError(errorType))?.message
      : '';

    this._actualErrorMessage.next(error);
  }

}
