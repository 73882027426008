import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { TransportationTasksAddEditComponent } from '../transportation-tasks-add-edit/transportation-tasks-add-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PageSegment } from 'app/shared/enums/page-segment.enum';
import { TransportBoardDataSource } from '../../shared/services/transport-board.datasource';
import {CustomDeviceDetectorService} from '../../../../shared/services/custom-device-detector.service';

@UntilDestroy()
@Component({
  selector: 'bh-transportation-board',
  templateUrl: './transportation-board.component.html',
  styleUrls: ['./transportation-board.component.scss']
})
export class TransportationBoardComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    private transportDataSource: TransportBoardDataSource,
    private deviceService: CustomDeviceDetectorService,
  ) {
    const create = this.route.snapshot.data['create'];
    if (create && !this.deviceService.isMobile()) {
      this.dialog.open(TransportationTasksAddEditComponent, { disableClose: true })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate([PageSegment.TRANSPORT_ROOT, PageSegment.TRANSPORT_BOARD]);
        this.transportDataSource.updateListing(0);
      });
    }
  }

  public ngOnInit(): void {
  }
}
