import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { DueDateEditDialogParam } from './due-date-edit-dialog-param.interface';
import { DueDateEditDialogResult } from './due-date-edit-dialog-result.interface';

@Component({
  selector: 'bh-maintenance-task-due-date-edit',
  templateUrl: './maintenance-task-due-date-edit.component.html',
  styleUrls: ['./maintenance-task-due-date-edit.component.scss']
})
export class MaintenanceTaskDueDateEditComponent implements OnInit {
  public readonly dialogResults = dialogResults;

  public readonly control = new UntypedFormControl();
  public title = '';
  public subTitle = '';
  public min: Date;
  public errorMessageMatcher = (control: AbstractControl) => {
    return control.getError('matDatepickerMin')
      ? this.translate('modules.maintenance.maintenanceDueDateEdit.errorCannotBeBeforeLatestCompletion')
      : null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DueDateEditDialogParam,
    private dialogRef: MatDialogRef<MaintenanceTaskDueDateEditComponent>,
    private languageService: LanguageService,
  ) {}

  public ngOnInit(): void {
    this.setTitle();
    this.setSubtitle();
    this.setDefaultValue();
    this.setValidation();
  }

  public save(): void {
    if (this.control.valid) {
      const result: DueDateEditDialogResult = {
        date: this.control.value || null,
        result: dialogResults.SAVE,
      };
      this.dialogRef.close(result);
    }
  }

  private setTitle(): void {
    this.title = this.dialogData.isCalculated
      ? this.translate('modules.maintenance.maintenanceDueDateEdit.titleSetDate')
      : this.translate('modules.maintenance.maintenanceDueDateEdit.titleEditDate')
  }

  private setSubtitle(): void {
    this.subTitle = this.dialogData.isCalculated
    ? this.translate('modules.maintenance.maintenanceDueDateEdit.subtitleSetDate')
    : this.translate('modules.maintenance.maintenanceDueDateEdit.subtitleEditDate');
  }

  private setDefaultValue(): void {
    this.control.setValue(this.dialogData.date);
  }

  private setValidation(): void {
    this.min = this.dialogData.lastCompletionDate;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
