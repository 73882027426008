<bh-pending-assignment-acknowledgements [equipmentId]="equipment?.equipmentId">
</bh-pending-assignment-acknowledgements>

<bh-title [text]="'modules.equipment.equipmentDetailDisposition.assignedProjects'|translate"></bh-title>

<div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px" class="reset-height">
  <div fxFlex="20" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-card class="disposition-control-panel">
      <div fxFlexFill fxFlexLayout="row" fxLayoutAlign="end center">
        <span [matTooltip]="'modules.equipment.equipmentDetailDisposition.assignToProjectWarning'|translate"
              [matTooltipDisabled]="isActiveEquipment()">
          <button mat-icon-button (click)="assignToProject()"
                  *ngIf="hasAuthority(authorities.EQUIPMENT_MANAGE_DISPOSITION)"
                  fxFlex="nogrow"
                  fxLayoutAlign="center center"
                  [matTooltip]="'modules.equipment.base.assignProject'|translate"
                  [disabled]="!isActiveEquipment()">

            <mat-icon>add</mat-icon>
          </button>
        </span>
      </div>
    </mat-card>
  </div>
  <div fxFlex="80" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <mat-card class="assignments-card"
              *ngFor="let assignment of equipmentStore.assignments | async">
      <mat-card-content>
        <p *ngIf="assignment.unknownProject" fxLayout="row" fxLayoutAlign="start center"
           fxLayoutGap="15px">
          <mat-icon [matTooltip]="'modules.equipment.equipmentDetailDisposition.projectBelongsToOrganization'|translate">warning
          </mat-icon>
          <span>
            <b>{{assignment.projectName}}</b>
            {{assignment.startDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }} -
            {{assignment.endDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
          </span>
        </p>
        <p *ngIf="!assignment.unknownProject" data-osp-test="card-assignment-date-range">
          <b>
            <span [class.link]="canSeeLink" (click)="canSeeLink && navigateToProjectAssignments(assignment.projectId)"
                  data-osp-test="card-assigned-project-name">
              {{assignment.projectName}}
            </span>
          </b>
          {{assignment.startDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }} -
          {{assignment.endDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
        </p>
        <bh-assignment-comment [assignment]="assignment" [readOnly]="!isActiveEquipment()">
        </bh-assignment-comment>
      </mat-card-content>

      <div fxLayout="row" fxLayoutAlign="end end" *ngIf="!assignment.unknownProject">
            <span class="submission-meta-data" data-osp-test="card-assignment-submission-date">
              {{'general.labels.scheduledBy'|translate}}: {{assignment.submittedBy}}
              {{'general.at'|translate}}: {{assignment.submitDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
            </span>
      </div>

      <mat-card-actions class="assignments-card-actions"
                        *ngIf="!assignment.unknownProject && isActiveEquipment() && hasAuthority(authorities.EQUIPMENT_MANAGE_DISPOSITION)">
        <mat-icon (click)="editAssignment(assignment)">mode_edit</mat-icon>
        <mat-icon (click)="deleteAssignment(assignment)">delete_forever</mat-icon>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
