import { ChartBarDisplayType } from '../contract/chart-bar-display-type.enum';
import { WidgetConfigParams } from '../contract/widget-config-param';
import { WidgetType } from '../contract/widget-type.enum';
import { WidgetDefinition } from '../widget-definition';
import { EquipmentStatusWidgetComponent } from './equipment-status-widget.component';

export class EquipmentStatusWidgetConfiguration extends WidgetDefinition {
  organisationIds: string[] = [];
  equipmentTypeCategories: string[] = [];
  displayType: ChartBarDisplayType = ChartBarDisplayType.LINEAR;

  constructor({ title }: WidgetConfigParams) {
    super();
    this.widgetType = WidgetType.EQUIPMENT_STATUS;
    this.type = EquipmentStatusWidgetComponent;
    this.title = title;
    this.minItemCols = 16;
    this.minItemRows = 10;
    this.cols = 20;
    this.rows = 12;
    this.x = 0;
    this.y = 0;
  }
}
