import { EquipmentContractType } from './../../contract/equipment-contract-type.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { LanguageService } from 'app/shared/services/language.service';
import { Injectable } from '@angular/core';
import { OptionResolver } from 'app/shared/components/card-select/option.resolver';
import {
  faKey,
  faKeySkeleton,
  faQuestion,
  faShieldCheck,
  faSuitcase,
  faWallet,
  faWrench
} from '@fortawesome/pro-regular-svg-icons';
import { DisabledOptionsConfiguration } from 'app/shared/components/card-select/disabled-options-configuration.interface';
@Injectable()
export class EquipmentContractTypeResolver implements OptionResolver {
  private _disabledOptions: DisabledOptionsConfiguration[] = [];

  constructor(private languageService: LanguageService) {
  }

  public set disabledOptionsConfiguration(disabledOptions: DisabledOptionsConfiguration[]) {
    this._disabledOptions = disabledOptions;
  }

  public resolveName(option: string): string {
    switch (option) {
      case EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT:
        return this.translate('modules.equipment.contract.contractTypes.fullServiceContract');
      case EquipmentContractType.RENTAL_CONTRACT:
        return this.translate('modules.equipment.contract.contractTypes.rentalContract');
      case EquipmentContractType.SALES_CONTRACT:
        return this.translate('modules.equipment.contract.contractTypes.purchaseContract');
      case EquipmentContractType.SERVICE_CONTRACT:
        return this.translate('modules.equipment.contract.contractTypes.serviceContract');
      case EquipmentContractType.INSURANCE_CONTRACT:
        return this.translate('modules.equipment.contract.contractTypes.insuranceContract');
      case EquipmentContractType.LEASING_CONTRACT:
        return this.translate('modules.equipment.contract.contractTypes.leasingContract');
    }
    return option;
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT:
        return faSuitcase;
      case EquipmentContractType.RENTAL_CONTRACT:
        return faKeySkeleton;
      case EquipmentContractType.SALES_CONTRACT:
        return faWallet;
      case EquipmentContractType.SERVICE_CONTRACT:
        return faWrench;
      case EquipmentContractType.INSURANCE_CONTRACT:
        return faShieldCheck;
      case EquipmentContractType.LEASING_CONTRACT:
        return faKey;
    }
    return faQuestion;
  }

  public isDisabledOption(option: string): boolean {
    return this._disabledOptions.some(({ value }) => value === option);
  }

  public getDisabledOptionTooltip(option: string): string {
    const disabledOption = this._disabledOptions.find(({ value }) => value === option);
    return disabledOption && disabledOption.tooltipText;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
