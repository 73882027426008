import { Injectable } from '@angular/core';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { MapSvgMarkerFactory } from 'app/shared/services/map-svg-marker-factory.service';
import { ContactUnion, MapIconPayloadContact } from '../types/map-icon-payload-contact.type';
import { MapIconPayloadTransport } from '../types/map-icon-payload-transport.type';

@Injectable()
export class MapIconResolverContact {

  constructor(
    private markerFactory: MapSvgMarkerFactory,
    private contactTypeResolverPipe: ContactTypeResolverPipe,
  ) { }

  public getSvgIcon(contact: MapIconPayloadContact): string {
    const contactType = (<ContactUnion>contact)?.type || (<MapIconPayloadTransport>contact)?.contactType;
    return contactType
      ? this.markerFactory.getSvgUrl({ iconDefinition: this.contactTypeResolverPipe.resolveIcon(contactType) })
      : null;
  }
}
