export enum ChargeType {
  BAD_WEATHER = 'BAD_WEATHER',
  FREE_OF_CHARGE = 'FREE_OF_CHARGE',
  IN_CHARGE = 'IN_CHARGE',
  IN_USE = 'IN_USE',
  READY_TO_PICKUP = 'READY_TO_PICKUP',
  RECOMMISSIONING = 'RECOMMISSIONING',
  STANDSTILL = 'STANDSTILL',
  STOCK = 'STOCK',
  WITHOUT_CHARGE = 'WITHOUT_CHARGE',
}
