import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { ProjectFilterRequestParams } from './project-filter-request-params.interface';

export class ProjectFilterParams extends FilterParams {
  public getFilterParams(): ProjectFilterRequestParams {
    return {
      status: this.getFilterTypeParams(FilterTypes.PROJECT_STATE),
      label: this.getFilterTypeParams(FilterTypes.LABEL)
    }
  }
}
