import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingpageListComponent } from './landingpage-list/landscape/landingpage-list.component';
import { landingpageRouting } from './landingpage.routing';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgPipesModule } from 'ngx-pipes';
import { MobileLandingpageListComponent } from './landingpage-list/mobile/mobile-landingpage-list.component';
import { LandingPageListItemsService } from './shared/landing-page-list-items.service';
import { MobileChooseCreationProcessComponent } from './mobile-choose-creation-process/mobile-choose-creation-process.component';
import { SharedModule } from '../../shared/shared.module';
import { ProcessCreateTypeResolver } from './mobile-choose-creation-process/process-create-type.resolver';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    CustomMaterialModule,
    landingpageRouting,
    NgPipesModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [LandingpageListComponent, MobileLandingpageListComponent, MobileChooseCreationProcessComponent],
  providers: [
      LandingPageListItemsService,
      ProcessCreateTypeResolver
  ]
})
export class LandingpageModule {
}
