<div class="attachment-type-container">
  <bh-subtitle text="{{category}}"></bh-subtitle>
  <div fxLayout="row" fxLayoutAlign="start center"
       fxLayoutGap="20px" *ngFor="let document of documents">
    <div fxFlex="100px">
      <fa-icon [icon]="getDocumentTypeIcon(document)" size="2x"></fa-icon>
    </div>

    <div class="file-name" fxFlex="1 1 100%">
      <bh-inline-filename-edit [filename]="document.fileName"
                               [editable]="editableName"
                               (saveValue)="changeFileName($event, document)">
      </bh-inline-filename-edit>
    </div>

    <div fxFlex="1 1 100%">
      <bh-inline-text-edit
        [initialValue]="document.fileDescription"
        [editable]="editable"
        (saveValue)="changeDescription($event, document)">
      </bh-inline-text-edit>
    </div>
    <div *ngIf="document.fileExists">
      <mat-icon class="bh-mat-icon"
                (click)="downloadDocument(document)"
                [matTooltip]="'general.buttons.download'|translate">
        cloud_download
      </mat-icon>
    </div>
    <div *ngIf="!document.fileExists">
      <mat-icon class="bh-mat-icon hover-red"
                [matTooltip]="'general.labels.notFound'|translate:{value: 'general.file'|translate}">
        cloud_off
      </mat-icon>
    </div>
    <div *ngIf="deletable">
      <mat-icon
        *ngIf="!pendingChanges; else pendingDelete"
        class="bh-mat-icon hover-red"
        [matTooltip]="'general.buttons.delete'|translate"
        (click)="deleteDocument(document.documentKey)">
        delete_forever
      </mat-icon>
      <ng-template #pendingDelete>
        <mat-icon class="bh-mat-icon hover-red" [matTooltip]="'general.buttons.deleteDisabled'|translate">
          auto_delete
        </mat-icon>
      </ng-template>
    </div>
  </div>
</div>
