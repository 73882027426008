import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentLink } from '../../contract/document-link.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FileUtils } from '../../fileUtils';
import { FileDownloadService } from '../test-place/secured-image/services/file-download.service';

@Component({
  selector: 'bh-attachment-list-category',
  templateUrl: './attachment-list-category.component.html',
  styleUrls: ['./attachment-list-category.component.scss']
})
export class AttachmentListCategoryComponent {

  @Input() documents: DocumentLink[];
  @Input() deletable = false;
  @Input() pendingChanges = false;
  @Input() editable = false;
  @Input() editableName = false;
  @Input() category = this.languageService.getInstant('general.document.pl');

  @Output() deleted = new EventEmitter<string>();
  @Output() nameChanged = new EventEmitter<DocumentLink>();
  @Output() descriptionChanged = new EventEmitter<DocumentLink>();

  constructor(private languageService: LanguageService,
              private fileDownloadService: FileDownloadService) {
  }

  public getDocumentTypeIcon(document: DocumentLink): IconDefinition {
    return FileUtils.getAttachmentFileTypeIcon(document);
  }

  public deleteDocument(documentKey: string): void {
    if (this.deletable) {
      this.deleted.emit(documentKey);
    }
  }

  public changeDescription(description: string, document: DocumentLink): void {
    if (this.editable) {
      this.descriptionChanged.emit({...document, fileDescription: description});
    }
  }

  public changeFileName(fileName: string, document: DocumentLink): void {
    if (this.editable && this.editableName) {
      this.nameChanged.emit({...document, fileName: fileName});
    }
  }

  public downloadDocument(document: DocumentLink): void {
    this.fileDownloadService.saveFile(document);
  }
}
