import { RentalByInfo } from '../../../contract/rental-by-info.interface';
import * as moment from 'moment';

export class HistoryEntry {

  startDate: Date;
  endDate?: Date;

  public static from(source: RentalByInfo): HistoryEntry {
    return new HistoryEntry(
      source.customerName,
      source.customerNumber,
      source.startDate,
      source.endDate);
  }

  public static dummyElement(): HistoryEntry {
    return new HistoryEntry('', '', '1900-01-01', '1900-01-02');
  }

  private constructor (
    readonly customerName?: string,
    readonly customerNumber?: string,
    startDate?,
    endDate?) {
    this.parseDates(startDate, endDate)
  };

  private parseDates(startDate: string | Date, endDate: string | Date): void {
    if (startDate) {
      this.startDate = new Date(moment(startDate).format('YYYY-MM-DD'));
      this.endDate = endDate
        ? new Date(moment(endDate).format('YYYY-MM-DD'))
        : new Date(moment(startDate).add(10, 'years').format('YYYY-MM-DD'));
    }
  }
}
