import { ExpandingTreeLeavesStatistic } from '../contracts/expanding-tree-leaves-statistic.class';
import { ExpandingTreeRootItemsPipe } from '../pipes/expanding-tree-root-items.pipe';
import { ExpandingTreeMapItem } from '../contracts/expanding-tree-map-item.class';
import { ExpandingTreeOption } from '../contracts/expanding-tree-option.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Input, TemplateRef, forwardRef } from '@angular/core';
import { TreeItemStatus } from '../contracts/tree-item-status.class';
import { ExpandingTreeSelectionMode } from '../contracts/expanding-tree-selection-mode.enum';


@Component({
  selector: 'bh-expanding-tree',
  templateUrl: './expanding-tree.component.html',
  styleUrls: ['./expanding-tree.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ExpandingTreeComponent),
    multi: true
  }]
})
export class ExpandingTreeComponent implements ControlValueAccessor {
  @Input('options') public set treeOptions(options: ExpandingTreeOption[]) {
    this.itemMap = new Map<string, ExpandingTreeMapItem>();
    if (options && this.keyFieldName) {
      this.itemMap = this.getItemMap(options);
      this.refresh();
    }
  }
  @Input() public keyFieldName: string;
  @Input() public contentTemplate: TemplateRef<any>;
  @Input('searchTerm') public set applySearchTerm(term: string) {
    this.searchTerm = term;
    this.filterMap(term);
  }
  @Input() public searchPredicate: (item: ExpandingTreeOption, searchTerm: string) => boolean;
  @Input() public alwaysExpanded = false;
  @Input() selectionMode: ExpandingTreeSelectionMode = ExpandingTreeSelectionMode.DEFAULT;

  public searchTerm = '';
  public itemMap = new Map<string, ExpandingTreeMapItem>();
  public selectedOptions = new Set<string>();
  private onChange = (value: any) => {};
  private onTouched = () => {};

  constructor(protected expandingTreeRootItemsPipe: ExpandingTreeRootItemsPipe) {
  }

  public get isNoSearchResult(): boolean {
    return ![...this.itemMap].some(([key, { hidden }]) => !hidden);
  }

  public writeValue(value: any[]): void {
    if (value instanceof Array) {
      this.selectedOptions = new Set([...value]);
      this.refresh();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public propagateChange(): void {
    this.onChange(this.getControlValue());
    this.onTouched();
  }

  public setContent(content: any): any {
    return { $implicit: content };
  }

  public isLeaf(item: ExpandingTreeMapItem): boolean {
    return !item.hasChildren()
      || !item.childrenIds.some(childId => !this.itemMap.get(childId).hidden);
  }

  public selectAll(): void {
    const rootItems = this.expandingTreeRootItemsPipe.transform(this.itemMap);
    rootItems
      .filter(({ hidden }) => !hidden)
      .forEach(item => this.changeSelection(true, item.id, false));
    this.propagateChange();
  }

  public deselectAll(): void {
    this.selectedOptions = new Set<string>();
    this.refresh();
    this.propagateChange();
  }

  protected getItemMap(options: ExpandingTreeOption[]): Map<string, ExpandingTreeMapItem> {
    const map = new Map<string, ExpandingTreeMapItem>();
    this.addItemsToMap(null, options, map);
    return map;
  }

  private addItemsToMap(parentId: string,
                        options: ExpandingTreeOption[],
                        map: Map<string, ExpandingTreeMapItem>): void {
    options.forEach(option => {
      if (option[this.keyFieldName]) {
        map.set(option[this.keyFieldName], this.createMapItem(parentId, option));
        if (option.children && option.children.length > 0) {
          this.addItemsToMap(option[this.keyFieldName], option.children, map);
        }
      }
    });
  }

  private createMapItem(parentId: string, option: ExpandingTreeOption): ExpandingTreeMapItem {
    return new ExpandingTreeMapItem(option, this.keyFieldName, parentId);
  }

  protected refresh(): void {
    this.itemMap.forEach(item => item.setUnchecked(true));
    if (this.selectedOptions && this.itemMap.size > 0) {
        let parentsIdsSet = new Set<string>();
        this.selectedOptions.forEach(id => {
          const item = this.itemMap.get(id);
          if (item) {
            item.setChecked(true);
          }
          parentsIdsSet = this.getAllParentIdsSet(item, parentsIdsSet);
        });
        parentsIdsSet.forEach(itemId => this.itemMap.get(itemId).setIndeterminateIfUnchecked(true));

        if (this.searchTerm) {
          this.filterMap(this.searchTerm);
        }
    }
  }

  private getAllParentIdsSet(item: ExpandingTreeMapItem, parentsIds: Set<string> = new Set()): Set<string> {
    return !item || !item.parentId
      ? parentsIds
      : this.getAllParentIdsSet(this.itemMap.get(item.parentId), new Set([...parentsIds, item.parentId]));
  }

  protected filterMap(term: string): void {
    this.expandingTreeRootItemsPipe
      .transform(this.itemMap)
      .forEach(item => this.checkItemMatchSearch(item, term));
  }

  private checkItemMatchSearch(item: ExpandingTreeMapItem, searchTerm: string): boolean {
    if (this.safeSearchPredicate(item.optionValue, searchTerm)) {
      this.makeTreeBranchVisible(item);
      return true;
    } else {
      const searchResultItemChildren = item.hasChildren()
        ? item.childrenIds
            .map(id => this.checkItemMatchSearch(this.itemMap.get(id), searchTerm))
            .some(Boolean)
        : false;
        item.hidden = !searchResultItemChildren;
      return searchResultItemChildren;
    }
  }

  private makeTreeBranchVisible(item: ExpandingTreeMapItem): void {
    item.hidden = false;
    if (item.hasChildren()) {
      item.childrenIds.forEach(childId => this.makeTreeBranchVisible(this.itemMap.get(childId)));
    }
  }

  private safeSearchPredicate(item: ExpandingTreeOption, searchTerm: string): boolean {
    try {
      return this.searchPredicate(item, searchTerm);
    } catch {
      return false;
    }
  }

  private getControlValue(): string[] {
    const result = [...this.selectedOptions].filter(option => this.itemMap.has(option));
    return result.length > 0 ? result : null;
  }

  public onChangeSelectionClick($event: MouseEvent, item: ExpandingTreeMapItem): void {
    $event.stopPropagation();
    if (item.disabled) {
      return;
    }

    if (item.indeterminate && !this.areAllClosestChildrenSelected(item)) {
      const leafStatistic = this.getBranchStatistic(item.id);
      if (leafStatistic.hasHidden()) {
        this.changeSelection(!leafStatistic.areAllVisibleSelected(), item.id);
        return;
      }
    }
    this.changeSelection(!item.checked, item.id);
  }

  private areAllClosestChildrenSelected(item: ExpandingTreeMapItem): boolean {
    return item.hasChildren() && item.childrenIds.every(childId => this.itemMap.get(childId).checked);
  }

  private getBranchStatistic(itemId: string,
    leafStatistic = new ExpandingTreeLeavesStatistic()): ExpandingTreeLeavesStatistic {
    const item = this.itemMap.get(itemId);
    leafStatistic.collectLeafStatistic(item, this.itemMap);
    return leafStatistic;
  }

  private changeSelection(checked: boolean, id: string, emitEvent = true): void {
    const item = this.itemMap.get(id);
    if (item) {
      this.selectItemAndChildren(item.id, checked);
      this.selectItemParents(item.parentId);
    }

    if (emitEvent) {
      this.propagateChange();
    }
  }

  private selectItemAndChildren(id: string, checked: boolean): ExpandingTreeMapItem {
    const item = this.itemMap.get(id);
    if (item.hidden) {
      return item;
    }

    if (item.hasChildren()) {
      const overallChildrenStatus = item.childrenIds.map(childId => this.selectItemAndChildren(childId, checked));
      this.selectionDecision(item, overallChildrenStatus);
    } else {
      item.setSelection(checked);
    }

    this.updateSelectedOptions(item);
    return item;
  }

  private selectionDecision(item: ExpandingTreeMapItem, overallChildrenStatus: ExpandingTreeMapItem[]): void {
    let allChecked = true;
    let hasChecked = false;
    let hasIndeterminate = false;

    overallChildrenStatus.forEach(({ virtualStatus: { checked, indeterminate } }) => {
      allChecked = allChecked && checked;
      hasChecked = hasChecked || checked;
      hasIndeterminate = hasIndeterminate || indeterminate;
    });

    if (allChecked) {
      item.setChecked();
      return;
    }
    if (hasIndeterminate || hasChecked) {
      item.setIndeterminate();
      return;
    }
    item.setUnchecked();
  }

  private selectItemParents(parentId: string): void {
    if (!parentId) {
      return;
    }
    const item = this.itemMap.get(parentId);
    this.parentSelectionDecision(item);

    this.updateSelectedOptions(item);
    this.selectItemParents(item.parentId);
  }

  private parentSelectionDecision(item: ExpandingTreeMapItem): void {
    const hasSelectedChildren = item.childrenIds.some(id => {
      const { checked, indeterminate } = this.itemMap.get(id).virtualStatus;
      return checked || indeterminate;
    });

    switch (this.selectionMode) {
      case ExpandingTreeSelectionMode.DEFAULT: {
        const hasAllSelectedChildren = item.childrenIds.every(id => this.itemMap.get(id).virtualStatus.checked);
        this.parentSelectionStandard(item, hasAllSelectedChildren, hasSelectedChildren);
        break;
      };
      case ExpandingTreeSelectionMode.DENY_PARENT_AUTO_DESELECT: {
        this.parentSelectionDenyAutoDeselect(item, hasSelectedChildren);
        break;
      }
    }
  }

  private parentSelectionStandard(item: ExpandingTreeMapItem, hasAllSelectedChildren: boolean, hasSelectedChildren: boolean): void {
    if (hasAllSelectedChildren) {
      item.setChecked();
    } else if (hasSelectedChildren) {
      item.setIndeterminate();
    } else {
      item.setUnchecked();
    }
  }

  private parentSelectionDenyAutoDeselect(item: ExpandingTreeMapItem, hasSelectedChildren: boolean): void {
    if (!item.virtualStatus.checked) {
      if (hasSelectedChildren) {
        item.setIndeterminate();
      } else {
        item.setUnchecked();
      }
    }
  }

  private updateSelectedOptions(item: ExpandingTreeMapItem): void {
    if (item.checked) {
      this.selectedOptions.add(item.id);
    } else {
      this.selectedOptions.delete(item.id);
    }
  }
}
