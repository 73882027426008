import { environment } from 'environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, finalize, mergeMap } from 'rxjs/operators';
import { CreateBulkItemCommand } from '../../contract/create-bulk-item-command';
import { BulkItemType } from '../../contract/bulk-item-type';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faCalculatorAlt,
  faCoins,
  faFileInvoiceDollar,
  faIndustryAlt,
  faMinus,
  faMoneyCheckEdit,
  faPlus,
  faQrcode,
  faRecycle,
  faStickyNote,
  faTag,
  faThList,
  faTrafficCone,
  faTruck
} from '@fortawesome/pro-light-svg-icons';
import { BulkItemsService } from '../../shared/bulk-items.service';
import { TransferItemUnit } from '../../contract/transfer-item-unit';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { BulkItemNumberInUseValidator } from '../../../../shared/custom-validators/bulk-item-number-in-use.validator';
import { ScanCodeInUseValidator } from '../../../../shared/custom-validators/scan-code-in-use.validator';
import { StockService } from '../../shared/services/stock.service';
import { BulkItemTypeResolver } from './bulk-item-type.resolver';
import { ViewStock } from '../../contract/view-stock';
import { BulkItemFieldStore } from '../service/bulk-item-field.store';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { UpdateBulkItemCommand } from '../../contract/update-bulk-item-command';
import { ViewBulkItem } from '../../contract/view-bulk-item.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { BulkItemDatasource } from '../../shared/bulk-item.datasource';
import { RouterHistory } from '../../../../shared/router-history';
import { ScanCodeService } from '../../../../shared/services/scan-code.service';
import { FieldTouchedErrorMatcher } from '../../../../shared/custom-validators/field-touched-error-matcher';
import { ErrorStateMatcher } from '@angular/material/core';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { OrganisationInfo } from '../../shared/services/organisation-info.interface';
import { DecimalNumberValidityService } from '../../../../shared/services/decimal-number-validity.service';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { AllMeasuresUnits } from 'convert-units/definitions/all';
import { generateUniqueNumber } from '../../../../shared/utils';

const DATA = 'data';

@Component({
  selector: 'bh-bulk-item-add',
  templateUrl: 'bulk-item-add-edit.component.html',
  styleUrls: ['bulk-item-add-edit.component.scss']
})
export class BulkItemAddEditComponent extends GuardedNavigableInputComponent implements OnInit {

  // Icons
  public readonly faFileInvoiceDollar: IconDefinition = faFileInvoiceDollar;
  public readonly faIndustryAlt: IconDefinition = faIndustryAlt;
  public readonly faTruck: IconDefinition = faTruck;
  public readonly faQrcode: IconDefinition = faQrcode;
  public readonly faTag: IconDefinition = faTag;
  public readonly faThList: IconDefinition = faThList;
  public readonly faCoins: IconDefinition = faCoins;
  public readonly faStickyNote: IconDefinition = faStickyNote;
  public readonly faCalculatorAlt: IconDefinition = faCalculatorAlt;
  public readonly faPLus: IconDefinition = faPlus;
  public readonly faMinus: IconDefinition = faMinus;
  public readonly faTrafficCone: IconDefinition = faTrafficCone;
  public readonly faMoneyCheckEdit: IconDefinition = faMoneyCheckEdit;
  public readonly faRecycle: IconDefinition = faRecycle;

  public readonly fieldLimit = FieldLimit;

  public bulkItemForm: UntypedFormGroup;
  public errorMatcher: ErrorStateMatcher = new FieldTouchedErrorMatcher();
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public bulkItem: ViewBulkItem;

  public costCenters: Observable<string[]> = this.bulkItemsFieldStore.filteredCostCenters;
  public costTypes: Observable<string[]> = this.bulkItemsFieldStore.filteredCostTypes;
  public bglCodes: Observable<string[]> = this.bulkItemsFieldStore.filteredBglCodes;
  public categories: Observable<string[]> = this.bulkItemsFieldStore.filteredCategories;
  public manufacturers: Observable<string[]> = this.bulkItemsFieldStore.filteredManufacturers;
  public suppliers: Observable<string[]> = this.bulkItemsFieldStore.filteredSuppliers;
  public organisations: Observable<OrganisationInfo[]> = this.bulkItemsFieldStore.filteredOrganisations;

  scanCodeValidationErrorMap = new Map([
    ['alreadyPairedToBulkItem', 'shared.validation.scanCodeAssignment.alreadyPairedToBulkItem'],
    ['alreadyPairedToEquipment', 'shared.validation.scanCodeAssignment.alreadyPairedToEquipment'],
    ['alreadyPairedToProject', 'shared.validation.scanCodeAssignment.alreadyPairedToProject']
  ]);

  public stocks: ViewStock[] = [];
  public bulkItemUnits = Object.keys(TransferItemUnit);
  public bulkItemTypes = Object.keys(BulkItemType);
  public mask: Array<string | RegExp> = [/[A-Z]/, '.', /\d/, '.', /\d/, /\d/];
  public customerLabels: Observable<string[]>;
  public displayedColumns: string[] = ['name', 'cost-center', 'amount'];
  public amountsArray: UntypedFormArray;
  public hasBAL: boolean;
  private _itemLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public itemLabels: Observable<string[]> = this._itemLabels.asObservable();
  public saveInProgress = false;
  @ViewChild('matSelect', { static: true })
  private matSelect: MatSelect;

  constructor(public decimalNumberValidityService: DecimalNumberValidityService,
              private formBuilder: UntypedFormBuilder,
              private bulkItemService: BulkItemsService,
              private bulkItemStore: BulkItemDatasource,
              private stockService: StockService,
              private scanCodeService: ScanCodeService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              public bulkItemTypeResolver: BulkItemTypeResolver,
              private bulkItemsFieldStore: BulkItemFieldStore,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              private dialogRef: MatDialogRef<BulkItemAddEditComponent>,
  ) {
    super(authService, router, route, routerHistory);
  }

  public get internalNumber(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemNumber');
  }

  public get name(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemName');
  }

  public get bulkItemCostCenter(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemCostCenter');
  }

  public get bulkItemCostType(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemCostType');
  }

  public get bulkItemManufacturer(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemManufacturer');
  }

  public get bulkItemManufacturerId(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemManufacturerId');
  }

  public get bulkItemSupplier(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemSupplier');
  }

  public get bulkItemSupplierId(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemSupplierId');
  }

  public get category(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemCategory');
  }

  public get organisationId(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemOrganisationId');
  }

  public get bulkItemUnit(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemUnit');
  }

  public get bulkItemBGLCode(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemBGLCode');
  }

  public get bulkItemBALCode(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemBALCode');
  }

  public get bulkItemScanCode(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemScanCode');
  }

  public get bulkItemShortName(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemShortName');
  }

  public get bulkItemWeight(): AbstractControl | null {
    return this.bulkItemForm.get(DATA).get('bulkItemWeight');
  }

  get amountControls(): AbstractControl[] {
    const typeFields: UntypedFormArray = this.bulkItemForm.get('amounts') as UntypedFormArray;
    return typeFields.controls;
  }

  get bulkItemType(): string {
    return this.bulkItemForm.get('bulkItemType').value;
  }

  ngOnInit() {
    this.amountsArray = this.formBuilder.array([]);
    this.bulkItemsFieldStore.loadAllFields();
    this.filterControl = new UntypedFormControl();
    this.buildForm();
    if (this.bulkItem) {
      this.fillForm();
      this.disableControls();
    } else {
      this.getAllStocks();
      this.hasBAL = false;
    }
    this.subscribeToFormChanges();
    this.customerLabels = this.bulkItemsFieldStore.filteredCustomerLabels;
  }

  public raiseAmount(control: UntypedFormControl): void {
    const oldValue = Number(control.get('quantity').value);
    control.patchValue({
      quantity: (oldValue + 1).toFixed(3)
    });
  }

  public decreaseAmount(control: UntypedFormControl): void {
    const oldValue = Number(control.get('quantity').value);
    control.patchValue({
      quantity: (oldValue - 1).toFixed(3)
    });
  }

  public addLabel(label: string): void {
    const newLabels = [...this._itemLabels.value];
    newLabels.push(label);
    this._itemLabels.next(newLabels);
    this.filterCustomerLabels(this.customerLabels);
  }

  public deleteLabel(label: string): void {
    const newLabels = [...this._itemLabels.value];
    newLabels.splice(newLabels.indexOf(label), 1);
    this._itemLabels.next(newLabels);
    this.filterCustomerLabels(this.bulkItemsFieldStore.filteredCustomerLabels);
  }

  public isValid(): boolean {
    return this.bulkItemForm.valid;
  }

  public changeCodetype(event: MatSelectChange) {
    if (event.value === 'BGL') {
      this.hasBAL = false;
      this.bulkItemBALCode.reset();
      this.bulkItemBGLCode.enable();
    } else {
      this.hasBAL = true;
      this.bulkItemBGLCode.reset();
      this.bulkItemBALCode.enable();
    }
  }

  public save(): void {
    if (this.isValid()) {
      const formValue = this.bulkItemForm.getRawValue();
      if (!this.bulkItem) {
        const command: CreateBulkItemCommand = new CreateBulkItemCommand(
          formValue.bulkItemType,
          formValue.data.bulkItemCategory,
          formValue.data.bulkItemName,
          formValue.data.bulkItemNumber,
          formValue.data.bulkItemCostCenter,
          formValue.data.bulkItemCostType,
          formValue.data.bulkItemManufacturerId,
          formValue.data.bulkItemManufacturer,
          formValue.data.bulkItemSupplier,
          formValue.data.bulkItemSupplierId,
          formValue.data.bulkItemUnit,
          this.toSystemDimensionUnit(formValue.data.bulkItemWeight, 'kg'),
          formValue.data.bulkItemScanCode,
          formValue.data.bulkItemBGLCode,
          formValue.data.bulkItemBALCode,
          formValue.description,
          formValue.data.bulkItemNonReturnable,
          this._itemLabels.value);
        this.saveInProgress = true;
        this.bulkItemStore.addBulkItem(command).pipe(
          delay(environment.DELAY_SHORT),
          mergeMap(res =>
            merge(
              ...this.amountControls
                .filter(ctrl => ctrl.value.quantity > 0)
                .map(ctrl => {
                  let amount = Math.round(ctrl.get('quantity').value * 1000) / 1000;
                  return {
                    bulkItemId: res,
                    stockId: ctrl.value.stockId,
                    amount: this.toSystemDimensionUnit(amount, command.bulkItemUnit),
                  }
                })
                .map(cmd => this.stockService.increaseBulkItemAmount(cmd)),
              this.router.navigate(['assets/bulk-item/list', res])
            )
          ),
          finalize(() => this.saveInProgress = false)
        )
        .subscribe({
          next: () => this.dialogRef.close(),
          error: (error: HttpErrorResponse) => console.log('ERROR: Error on adding Bulk Item: ', error),
        });
      } else {
        const command: UpdateBulkItemCommand = new UpdateBulkItemCommand(
          this.bulkItem.bulkItemId,
          formValue.data.bulkItemCategory,
          formValue.data.bulkItemName,
          formValue.data.bulkItemShortName,
          formValue.data.bulkItemNumber,
          formValue.data.bulkItemCostCenter,
          formValue.data.bulkItemCostType,
          formValue.data.bulkItemManufacturerId,
          formValue.data.bulkItemManufacturer,
          formValue.data.bulkItemSupplier,
          formValue.data.bulkItemSupplierId,
          formValue.data.bulkItemUnit,
          this.toSystemDimensionUnit(formValue.data.bulkItemWeight, 'kg'),
          formValue.data.bulkItemScanCode,
          formValue.data.bulkItemBGLCode,
          formValue.data.bulkItemBALCode,
          formValue.data.bulkItemNonReturnable);
        this.saveInProgress = true;
        this.bulkItemStore.updateBulkItem(command)
        .pipe(finalize(() => this.saveInProgress = false))
        .subscribe({
          next: () => this.dialogRef.close(),
          error: (error: HttpErrorResponse) => console.log('ERROR: Error on updating Bulk Item: ', error),
        });
      }
    }
  }

  public enableWeightField(): void {
    this.bulkItemForm.get(DATA).get('bulkItemWeight').enable();
  }

  public clearInternalNumber(): void {
    this.bulkItemForm.get(DATA).patchValue({
      bulkItemNumber: null
    });
  }

  checkingFormForNumberOfDecimalPlaces(): boolean {
    let isValid = true;
    this.amountControls.forEach(control => {
      if (!this.decimalNumberValidityService.regExp.test(control.value.quantity) && control.value.quantity !== null) {
        isValid = false;
      }
    });
    return isValid;
  }

  checkFormForMinAndMaxAmount(): boolean {
    let amount = 0;
    this.amountControls.forEach(control => {
      if (control.value.quantity !== null && typeof (control.value.quantity) === 'number') {
        amount = amount + control.value.quantity;
      }
    });
    return amount >= 0 && amount <= environment.TEN_MILLION;
  }

  protected buildForm(): void {
    this.bulkItemForm = this.formBuilder.group({
      bulkItemType: ['', Validators.required],
      data: this.formBuilder.group({
        bulkItemCategory: ['', [Validators.required, Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemNumber: [generateUniqueNumber(), [Validators.required, TrimValidator.hasWhitespaces, Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemName: ['', [Validators.required, Validators.maxLength(this.fieldLimit.NAME)]],
        bulkItemShortName: [''],
        bulkItemCostCenter: ['', [Validators.maxLength(this.fieldLimit.COST_CENTER)]],
        bulkItemCostType: ['', [Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemManufacturer: ['', [Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemManufacturerId: ['', [Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemSupplier: ['', [Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemSupplierId: ['', [Validators.maxLength(this.fieldLimit.MEDIUM_IDENTIFIER)]],
        bulkItemScanCode: ['', [TrimValidator.hasWhitespaces, Validators.maxLength(this.fieldLimit.SCAN_CODE)]],
        bulkItemUnit: ['', Validators.required],
        bulkItemWeight: [{ value: '', disabled: true }, [Validators.min(0)]],
        bulkItemBGLCode: [{ value: '', disabled: true }],
        bulkItemBALCode: [{ value: '', disabled: false }],
        bulkItemNonReturnable: false
      }),
      description: ['']
    });

    this.setScanCodeInUseValidator();
    this.setBulkItemNumberInUseValidator();
  }

  private fillForm(): void {
    this.bulkItemForm.get(DATA).patchValue({
      bulkItemCategory: this.bulkItem.bulkItemCategory,
      bulkItemNumber: this.bulkItem.bulkItemNumber,
      bulkItemName: this.bulkItem.bulkItemName,
      bulkItemShortName: this.bulkItem.bulkItemShortName,
      bulkItemCostCenter: this.bulkItem.bulkItemCostCenter,
      bulkItemCostType: this.bulkItem.bulkItemCostType,
      bulkItemManufacturer: this.bulkItem.manufacturerName,
      bulkItemManufacturerId: this.bulkItem.manufacturerId,
      bulkItemSupplier: this.bulkItem.supplierName,
      bulkItemSupplierId: this.bulkItem.supplierId,
      bulkItemScanCode: this.bulkItem.bulkItemScanCode,
      bulkItemUnit: this.bulkItem.bulkItemUnit,
      bulkItemWeight: this.dimensionUnitConverterPipe.toUserDimensionUnit(this.bulkItem.bulkItemWeight, 'kg'),
      bulkItemBGLCode: this.bulkItem.bulkItemBGLCode,
      bulkItemBALCode: this.bulkItem.bulkItemBALCode,
      bulkItemNonReturnable: this.bulkItem.nonReturnable
    });
    this.bulkItemForm.get('bulkItemType').patchValue(this.bulkItem.bulkItemType);

    if (this.bulkItem.bulkItemBALCode) {
      this.hasBAL = true;
      this.bulkItemForm.get('data.bulkItemBALCode').enable();
      this.bulkItemForm.get('data.bulkItemBGLCode').disable();
      this.matSelect.value = 'BAL';
    } else if (this.bulkItem.bulkItemBGLCode) {
      this.bulkItemForm.get('data.bulkItemBGLCode').enable();
      this.bulkItemForm.get('data.bulkItemBALCode').disable();
      this.matSelect.value = 'BGL';
    }

    this.enableWeightField();
  }

  private disableControls(): void {
    this.bulkItemForm.get('bulkItemType').disable();
  }

  private getAllStocks(): void {
    this.stockService.getStocks().pipe(filter(stocks => !!stocks))
      .subscribe(stocks => {
        this.stocks = stocks;
        const amountControls = this.formBuilder.array(stocks.map(amount => this.formBuilder.group({
          stockId: amount.stockId,
          name: amount.stockName,
          costCenter: amount.stockCostCenter,
          quantity: [0, [Validators.min(0), Validators.max(environment.TEN_MILLION)]]
        })));

        this.bulkItemForm.setControl('amounts', amountControls);
      });
  }

  private setBulkItemNumberInUseValidator(): void {
    if (this.bulkItem) {
      this.bulkItemForm.get(DATA).get('bulkItemNumber').setAsyncValidators(
        asyncValidatorFactory((value) => BulkItemNumberInUseValidator
          .isValid(value, this.bulkItemService, this.bulkItem.bulkItemNumber))
      );
    } else {
      this.bulkItemForm.get(DATA).get('bulkItemNumber').setAsyncValidators(
        asyncValidatorFactory((value) => BulkItemNumberInUseValidator.isValid(value, this.bulkItemService))
      );
    }
  }

  private setScanCodeInUseValidator(): void {
    if (this.bulkItem) {
      this.bulkItemForm.get(DATA).get('bulkItemScanCode').setAsyncValidators(
        asyncValidatorFactory(value => ScanCodeInUseValidator
          .isValid(value, this.scanCodeService, this.bulkItem.bulkItemScanCode, false))
      );
    } else {
      this.bulkItemForm.get(DATA).get('bulkItemScanCode').setAsyncValidators(
        asyncValidatorFactory(value => ScanCodeInUseValidator.isValid(value, this.scanCodeService, null))
      );
    }
  }

  private subscribeToFormChanges(): void {
    this.filterBulkItemType();
    this.filterBGLCodesOnInput();
    this.filterCategoriesOnInput();
    this.filterManufacturersOnInput();
    this.filterSuppliersOnInput();
    this.filterCostTypesOnInput();
    this.filterCostCentersOnInput();
  }

  private filterCustomerLabels(_customerLabels: Observable<string[]>): void {
    let filteredCustomerLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
    combineLatest(this._itemLabels, _customerLabels)
      .subscribe(([itemLabels, customerLabels]) => {
        const currentCustomerLabels = customerLabels.map(label => label)
          .filter(label => !itemLabels.includes(label));
        filteredCustomerLabels.next(currentCustomerLabels);
      });
    this.customerLabels = filteredCustomerLabels;
  }

  private filterBulkItemType(): void {
    this.bulkItemForm.get('bulkItemType')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(newType => {
        if (newType === BulkItemType.STANDARD) {
          this.bulkItemForm.get('data.bulkItemNonReturnable').patchValue(false)
        }
      });
  }

  private filterBGLCodesOnInput(): void {
    this.bulkItemForm.get(DATA).get('bulkItemBGLCode')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.bulkItemsFieldStore.filterBglCodes(filterTerm));
  }

  private filterCategoriesOnInput(): void {
    this.bulkItemForm.get(DATA).get('bulkItemCategory')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.bulkItemsFieldStore.filterCategories(filterTerm));
  }

  private filterManufacturersOnInput(): void {
    this.bulkItemForm.get(DATA).get('bulkItemManufacturer')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.bulkItemsFieldStore.filterManufacturer(filterTerm));
  }

  private filterSuppliersOnInput(): void {
    this.bulkItemForm.get(DATA).get('bulkItemSupplier')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.bulkItemsFieldStore.filterSuppliers(filterTerm));
  }

  private filterCostTypesOnInput(): void {
    this.bulkItemForm.get(DATA).get('bulkItemCostType')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.bulkItemsFieldStore.filterCostTypes(filterTerm));
  }

  private filterCostCentersOnInput(): void {
    this.bulkItemForm.get(DATA).get('bulkItemCostCenter')
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.bulkItemsFieldStore.filterCostCenters(filterTerm));
  }

  shortNameNotEmpty() {
    return this.bulkItem?.bulkItemShortName;
  }

  private toSystemDimensionUnit(value: number, unit: AllMeasuresUnits | TransferItemUnit): number {
    return this.dimensionUnitConverterPipe.toSystemDimensionUnit(value, unit);
  }
}
