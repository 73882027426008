import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AssignEmployeeToUserCommand } from '../../contract/assign-employee-to-user-command';
import { EmployeeManagementDatasource } from '../employee-management.datasource';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { UserInfo } from '../../contract/userInfo.interface'
import { UserInfoService } from '../userInfo.service';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-user-assign-dialog',
  templateUrl: 'user-assign-dialog.component.html',
  styleUrls: ['user-assign-dialog.component.scss']
})
export class UserAssignDialogComponent implements OnInit, OnDestroy {

  public employeeId;
  public form: UntypedFormGroup;
  public formDisabled = false;
  public userFilter: UntypedFormControl = new UntypedFormControl('');
  private searchTerm: string;
  private usersSubject: BehaviorSubject<UserInfo[]> = new BehaviorSubject([]);
  public users: Observable<UserInfo[]> = this.usersSubject.asObservable();

  constructor(public dialogRef: MatDialogRef<UserAssignDialogComponent>,
              private userInfoService: UserInfoService,
              private employeeManagementStore: EmployeeManagementDatasource,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
    this.getUsers('');
    this.subscribeToFormChanges();
  }

  ngOnDestroy() {
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      user: ['', [<any>Validators.required]]
    });
  }

  private getUsers(searchTerm: string): void {
    if (this.searchTerm !== searchTerm) {
      this.searchTerm = searchTerm;
      this.userInfoService.getUsers().pipe(untilDestroyed(this)).subscribe(
        (res: PagedResponse<UserInfo>) => {
          this.usersSubject.next(res.content.filter(user => this.userContainsSearchTerm(user, searchTerm)
            && !this.userConnectedToEmployee(user)));
        },
        (error: HttpErrorResponse) => {
          console.log('reading users error: ', error);
        }
      );
    }
  }

  private userContainsSearchTerm(user: UserInfo, searchTerm: string): boolean {
    return this.containsSearchTerm(user.name, searchTerm) || this.containsSearchTerm(user.firstName, searchTerm);
  }

  private containsSearchTerm(term: string, searchTerm: string): boolean {
    if (!searchTerm) {
      return true;
    } else if (!term) {
      return false;
    }
    return term.toLowerCase().includes(searchTerm.toLowerCase());
  }

  private userConnectedToEmployee(user: UserInfo): boolean {
    return !!user.employeeId
  }

  private subscribeToFormChanges(): void {
    this.userFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.getUsers(filterTerm));
  }

  public save(): void {
    if (this.isValid) {
      const formData = this.form.getRawValue();
      this.formDisabled = true;
      const command: AssignEmployeeToUserCommand = new AssignEmployeeToUserCommand(this.employeeId, formData.user.userId);
      this.employeeManagementStore.assignEmployeeToUser(command).subscribe(
        res => {
          this.dialogRef.close(dialogResults.SAVE);
        }, error => {
          console.log('error assigning employee to user', error);
          this.dialogRef.close();
        }
      )
    }
  }

  public isValid(): boolean {
    return this.form.valid && !this.formDisabled;
  }

  get abort(): string {
    return dialogResults.ABORT;
  }
}
