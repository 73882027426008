import { TimelineEvent } from './timeline-event';

export class TeamHeaderEvent extends TimelineEvent {

  public isTeamHeaderEvent = true;

  constructor(teamId: string, startDate: any, endDate: any) {
    super(teamId,
      teamId,
      startDate,
      endDate,
      null,
      '',
      true,
      false,
      '#f2f2f2');
  }
}

export function isTeamHeaderEvent(event: TimelineEvent): event is TeamHeaderEvent {
  return (<TeamHeaderEvent>event).isTeamHeaderEvent !== undefined;
}
