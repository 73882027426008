import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaintenanceTaskView } from 'app/modules/maintenance/tasks/shared/maintenance-task';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';

@Component({
  selector: 'bh-maintenance-task-view-general',
  templateUrl: './maintenance-task-view-general.component.html',
  styleUrls: ['./maintenance-task-view-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskViewGeneralComponent {
  @Input() task: MaintenanceTaskView;
  public readonly maintenanceCategory = MaintenanceCategory;
}
