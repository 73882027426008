<div class="select-content" fxLayout="row" fxLayoutGap="15px">
  <mat-card *ngFor="let option of options"
            class="option-content"
            [ngClass]="resolveCardClassName(option)"
            [class.active]="isSelected(option)"
            [class.disabled]="readOnly || isDisabledOption(option)"
            (click)="!readOnly && !isDisabledOption(option) ? select(option) : null"
            [matTooltip]="getDisabledOptionTooltip(option)"
            [matTooltipDisabled]="!getDisabledOptionTooltip(option)">
    <mat-card-header [ngClass]="{ 'header-increased': optionResolver.resolveSubtitle }">
      <mat-card-title>{{resolveOptionName(option)}}</mat-card-title>
      <mat-card-subtitle *ngIf="resolveSubtitle(option)">{{resolveSubtitle(option)}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngTemplateOutlet="cardOption; context: getCardContentDate(option)"></ng-container>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #cardOption let-logo="logo" let-icon="icon" let-iconImage="iconImage">
  <div class="option" fxFlexFill fxLayout="column" fxLayoutAlign="space-evenly center">
    <!-- Logo -->
    <img *ngIf="logo" class="option-item option-logo image" [fxFlex]="icon || iconImage ? 20 : 80" [ngClass]="{ 'single': !icon && !iconImage }" [src]='logo'>

    <!-- Fontawesome icon -->
    <fa-icon *ngIf="icon && !iconImage" class="option-item option-icon" [fxFlex]="logo ? 55 : 80" [ngClass]="{ 'single': !logo }" fxLayoutAlign="center center" [icon]="icon"></fa-icon>

    <!-- Icon-image -->
    <img *ngIf="iconImage" class="option-item option-icon-image image" [fxFlex]="logo ? 55 : 80" [ngClass]="{ 'single': !logo }" [src]="iconImage">
  </div>
</ng-template>
