import { TimelineEvent } from './timeline-event';
import moment from 'moment';
import { ViewAmountTimelineEntry } from './view-amount-timeline-entry.interface';

export class TransferItemAssignmentEvent extends TimelineEvent {

  public timelineEntry: ViewAmountTimelineEntry;

  constructor(entry: ViewAmountTimelineEntry) {
    let startDate = new Date(entry.statusStartDate);
    let endDate = entry.statusEndDate && moment(entry.statusEndDate).isSameOrBefore(moment.now()) ?
      new Date(entry.statusEndDate) : new Date();
    super(entry.projectId + '/' + entry.transferItemId + '/' + entry.amountStatusPeriodId,
      entry.projectId + '/' + entry.transferItemId,
      new Date(startDate),
      new Date(endDate),
      new Date(entry.chargeDate),
      '',
      false,
      true,
      'red',
    );
    this.timelineEntry = entry;
  }
}

export function isTransferItemAssignmentEvent(event: TimelineEvent): event is TransferItemAssignmentEvent {
  return (<TransferItemAssignmentEvent>event).timelineEntry !== undefined &&
    (<TransferItemAssignmentEvent>event).timelineEntry.transferItemId !== undefined;
}
