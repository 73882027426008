import { BasicWidget } from './basic-widget';
import { Directive } from '@angular/core';


@Directive()
export abstract class ChartWidget extends BasicWidget {
  protected constructor() {
    super();
  }
}
