import { UUID } from 'angular2-uuid';

export class Action {

  static create(service: string, functionName: string, command: any) {
    return new Action(UUID.UUID(), service, functionName, command)
  }

  constructor(public id: string, public service: string, public functionName: string, public command: any) {}
}
