import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { GalleryImage } from '../../models/gallery-image.class';
import { GalleryOptions } from '../../models/gallery-options.class';
import { GalleryImageSize } from '../../enums/gallery-image-size.enum';
import { ThumbnailSize } from '../../enums/thumbnail-size.enum';
import { FileUtils } from 'app/shared/fileUtils';

interface ImageViewComponentSimpleChanges extends SimpleChanges {
  key?: SimpleChange;
  size?: SimpleChange;
  height?: SimpleChange;
  width?: SimpleChange;
}

@Component({
  selector: 'bh-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewComponent implements OnInit, OnChanges {
  @Input() public key: string;
  @Input() public size: ThumbnailSize = ThumbnailSize.S;
  @Input() public height = 100;
  @Input() public width = 100;

  public images: GalleryImage[] = [];
  public options: GalleryOptions[] = [];

  constructor() { }

  public ngOnInit(): void {
    this.initParams();
  }

  public ngOnChanges(changes: ImageViewComponentSimpleChanges): void {
    if (changes.key || changes.size) {
      this.setGalleryImage();
    }
    if (changes.height || changes.width) {
      this.setGalleryOptions();
    }
  }

  private initParams(): void {
    this.setGalleryOptions();
    this.setGalleryImage();
  }

  private setGalleryOptions(): void {
    this.options = [{
      width: `${this.width}px`,
      height: `${this.height}px`,
      thumbnails: false,
      imageSize: GalleryImageSize.CONTAIN,
      previewCloseOnClick: true,
      imageArrows: false,
      previewArrows: false,
    }];
  }

  private setGalleryImage(): void {
    this.images = this.buildGalleryImages(this.key);
  }

  private buildGalleryImages(key: string): GalleryImage[] {
    if (!this.isKeyValid(key)) {
      return [];
    }

    const encodedKey = FileUtils.encodeDocumentKey(key);
    const thumbnailParam = this.size ? `?thumbnail=${this.size}` : '';

    return [{
      big: encodedKey,
      medium: `${encodedKey}${thumbnailParam}`
    }];
  }

  private isKeyValid(src: string): boolean {
    return Boolean(src && typeof src === 'string');
  }

}
