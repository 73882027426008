import { Pipe, PipeTransform } from '@angular/core';
import { TransportItem } from 'app/modules/equipment/shared/transport-items.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapLocationsCluster } from '../interfaces/map-location-cluster.interface';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';

@Pipe({
  name: 'mapClustersConvertTransportItems'
})
export class MapClustersConvertTransportItemsPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: TransportItem[]): MapLocationsCluster[] {
    return Array.isArray(value) ? this.getMapCluster(value) : null;
  }

  private getMapCluster(items: TransportItem[]): MapLocationsCluster[] {
    return [{ markers: this.getMapLocationMarkers(items) }];
  }

  private getMapLocationMarkers(items: TransportItem[]): MapLocationMarker<TransportItem>[] {
    return items.filter(Boolean).map(item => ({
      id: item.id,
      location: item.location,
      icon: this.getIconUrl(item),
      item,
    }));
  }

  private getIconUrl(item: TransportItem): string {
    return this.iconResolver.getIconTransport(item);
  }

}
