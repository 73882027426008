import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { RolesService } from '../../shared/roles.service';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { CreateUserCommand } from '../../contract/create-user-command';
import { CustomersService } from '../../../organisation/shared/customers.service';
import { KeycloakService } from '../../../../core/keycloak';
import { UsersStore } from '../../shared/users.store';
import { HttpErrorResponse } from '@angular/common/http';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewRole } from '../../contract/view-role.interface';
import { ViewCustomer } from '../../../organisation/contract/view-customer.interface';
import { UserAddBaseComponent } from '../user-add/user-add-base.component';
import { RouterHistory } from '../../../../shared/router-history';

@UntilDestroy()
@Component({
  selector: 'bh-user-add-fleetadmin',
  templateUrl: './user-add-fleetadmin.component.html',
  styleUrls: ['./user-add-fleetadmin.component.scss']
})
export class UserAddFleetadminComponent extends UserAddBaseComponent implements OnInit, OnDestroy {
  userAddForm: UntypedFormGroup;
  today: Date = new Date();
  customerName: string;

  private customerId: string;
  private fleetAdminRoleId: string;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected formBuilder: UntypedFormBuilder,
              protected customersService: CustomersService,
              protected rolesService: RolesService,
              protected usersStore: UsersStore,
              protected routerHistory: RouterHistory) {
    super(router, route, authService, usersStore, routerHistory);
  }

  ngOnInit(): void {
    this.customerId = this.route.snapshot.queryParams['customerId'];
    this.buildForm();
    this.getCustomer(this.customerId);
    this.setEmailInUseValidator();
    // TODO: what if customer id is missing
    if (this.customerId) {
      this.setFromCustomerId();
    }
  }

  ngOnDestroy(): void {
  }

  private buildForm(): void {
    this.userAddForm = this.formBuilder.group({
      email: ['', [<any>Validators.required, emailValidator()]],
      firstName: '',
      name: ['', [<any>Validators.required]],
      mobileNumber: '',
      validUntil: '',
      customerId: ['', [<any>Validators.required]],
      roleIds: this.formBuilder.array([], Validators.compose([<any>Validators.required])),
      organisationIds: this.formBuilder.array([])
    });
  }

  save(): void {
    if (this.isValid()) {
      if (this.userAddForm.valid) {
        let formValue = this.userAddForm.getRawValue();
        let validUntil = formValue.validUntil ? formValue.validUntil.toISOString() : null;
        let cmd: CreateUserCommand = new CreateUserCommand();

        cmd.email = formValue.email;
        cmd.firstName = formValue.firstName;
        cmd.name = formValue.name;
        cmd.mobileNumber = formValue.mobileNumber;
        cmd.roleIds = formValue.roleIds;
        cmd.customerId = formValue.customerId;
        cmd.organisationIds = formValue.organisationIds;
        cmd.validUntil = validUntil;

        this.usersStore.addUser(cmd).pipe(untilDestroyed(this)).subscribe(
          () => {
            this.router.navigate(['/users/list']);
          },
          (error: HttpErrorResponse) => {
            console.log('save fleedadmin error: ', error);
          }
        );
      }
    }
  }

  public isValid(): boolean {
    return this.userAddForm.valid;
  }
  resetForm(): void {
    this.roleIds.getRawValue().forEach(() => {
      this.roleIds.removeAt(0);
    });
    this.userAddForm.reset();
    this.setFromCustomerId();
    this.setFormFleetadminRoleId();
  }

  private getRoles(partnerId: string): void {
    this.rolesService.getRolesByPartner(partnerId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewRole[]) => {
          res.map((role: ViewRole) => {
            if (role.name === PrivilegedRole.Flottenadmin.toString()) {
              this.fleetAdminRoleId = role.roleId;
              this.setFormFleetadminRoleId();
            }
          });
        },
        error => {
          console.log('get roles error: ', error);
        }
      );
  }

  private getCustomer(customerId: string): void {
    this.customersService.getCustomer(customerId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewCustomer) => {
          this.customerName = res.customerName;
          this.getRoles(res.partnerId);
        },
        error => {
          console.log('get customer error: ', error);
        }
      );
  }

  private setFromCustomerId(): void {
    this.userAddForm.patchValue({
      customerId: this.customerId
    });
  }

  private setFormFleetadminRoleId(): void {
    this.roleIds.push(new UntypedFormControl(this.fleetAdminRoleId));
  }

  get roleIds(): UntypedFormArray {
    return this.userAddForm.get('roleIds') as UntypedFormArray;
  }

}
