import { Component, Input } from '@angular/core';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';

@Component({
  selector: 'bh-map-movement-arrows',
  templateUrl: './map-movement-arrows.component.html',
})
export class MapMovementArrowsComponent {
  @Input() locations: LatLonLocation[] = [];
  public readonly options: google.maps.PolylineOptions = {
    strokeColor: '#fd241f',
    strokeOpacity: 1,
    strokeWeight: 2,
    geodesic: true,
    icons: [
      {
        icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
        offset: '100%'
      }
    ]
  };
}
