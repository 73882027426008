import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { EmployeeManagementDatasource } from '../../../../../shared/employee-management.datasource';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faUser, faStar } from '@fortawesome/pro-solid-svg-icons';
import { AddEmployeeLabelCommand } from '../../../../../contract/add-employee-label-command';
import { RemoveEmployeeLabelCommand } from '../../../../../contract/remove-employee-label-command';
import { BaseEmployeeViewGeneralComponent } from './base/base-employee-view-general.component';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { UserInfoService } from '../../../../../shared/userInfo.service';

@Component({
  selector: 'bh-employee-view-general',
  templateUrl: './employee-view-general.component.html',
  styleUrls: ['./employee-view-general.component.scss']
})
export class EmployeeViewGeneralComponent extends BaseEmployeeViewGeneralComponent implements OnInit {

  public readonly faUserIcon: IconDefinition = faUser;
  public readonly faLinkIcon: IconDefinition = faLink;
  public readonly faStar: IconDefinition = faStar;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected userInfoService: UserInfoService,
              public employeeManagementStore: EmployeeManagementDatasource) {
    super(authService, router, route, routerHistory, employeeManagementStore, userInfoService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public addLabel(label: string): void {
    this.employeeManagementStore.saveLabel(new AddEmployeeLabelCommand(this.employee.employeeId, label));
  }

  public deleteLabel(label: string): void {
    this.employeeManagementStore.removeLabel(new RemoveEmployeeLabelCommand(this.employee.employeeId, label));
  }

}
