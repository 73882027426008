<div class="bulk-item-add-wrapper">
  <bh-mobile-wizard [title]="'modules.disposition.bulkItemAddEdit.addBulkItem'|translate"
                    [wizardOptions]="wizardOptions"
                    [nextEnabled]="nextEnabled"
                    (tabChanged)="setValidity()"
                    [backEnabled]="true"
                    (onFinish)="save()"
                    (onCancel)="onCancel()">
    <bh-mobile-wizard-tab key="overall-data">
      <bh-mobile-bulk-item-add-overall-data [form]="overallDataForm"
                                            [showConsumable]="showConsumable"></bh-mobile-bulk-item-add-overall-data>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="general-data">
      <bh-mobile-bulk-item-add-general-data [form]="generalDataForm"></bh-mobile-bulk-item-add-general-data>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="file-upload">
      <bh-mobile-bulk-item-add-file-upload></bh-mobile-bulk-item-add-file-upload>
    </bh-mobile-wizard-tab>
  </bh-mobile-wizard>
</div>
