import { Languages } from 'app/shared/enums/languages.enum';

import localeDe from '@angular/common/locales/de';
import localeDa from '@angular/common/locales/da';
import localeCs from '@angular/common/locales/cs';
import localeNl from '@angular/common/locales/nl';
import localeFr from '@angular/common/locales/fr';
import localeSv from '@angular/common/locales/sv';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeNo from '@angular/common/locales/no';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeRo from '@angular/common/locales/ro';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeJa from '@angular/common/locales/ja';
import localeTh from '@angular/common/locales/th';

interface LanguageLocale {
  locale: string;
  data: any;
}

export const LANGUAGE_LOCALES: LanguageLocale[] = [
  { locale: Languages.DE, data: localeDe },
  { locale: Languages.DK, data: localeDa },
  { locale: Languages.CZ, data: localeCs },
  { locale: Languages.NL, data: localeNl },
  { locale: Languages.FR, data: localeFr },
  { locale: Languages.SE, data: localeSv },
  { locale: Languages.HU, data: localeHu },
  { locale: Languages.IT, data: localeIt },
  { locale: Languages.NO, data: localeNo },
  { locale: Languages.PL, data: localePl },
  { locale: Languages.PT, data: localePt },
  { locale: Languages.RU, data: localeRu },
  { locale: Languages.RO, data: localeRo },
  { locale: Languages.ES, data: localeEs },
  { locale: Languages.FI, data: localeFi },
  { locale: Languages.JP, data: localeJa },
  { locale: Languages.TH, data: localeTh },
];
