import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: any, args?: any): any {
    let street = address?.street ?? ''
    const streetNumber = address?.streetNumber ?? ''
    const postalCode = address?.postalCode ?? ''
    const city = address?.city ?? ''

    if (street) {
      if (streetNumber) {
        street += ' ' + streetNumber.trim();
      }
      street += ',';
    }

    const concatenatedAddress = `${street ?? ''} ${postalCode ?? ''} ${
      city ?? ''
    }`.trim();
    return this.removeMultipleSpaces(concatenatedAddress);
  }

  private removeMultipleSpaces(value: string) {
    return value.replace(/\s\s+/g, ' ');
  }
}
