import { Pipe, PipeTransform } from '@angular/core';
import { TelematicUnitLastChangesType } from 'app/modules/equipment/contract/telematic-unit-traffic-last-changes.enum';
import { TelematicUnitTrafficBase } from './telematic-unit-traffic-base.class';

type TrafficLight = {
  [key in ('green' | 'yellow' | 'red')]?: boolean
}

@Pipe({
  name: 'telematicUnitTrafficLight'
})
export class TelematicUnitTrafficLightPipe extends TelematicUnitTrafficBase implements PipeTransform {

  public transform(lastDate: Date): TrafficLight {
    switch (this.getTrafficLastChangesType(lastDate)) {
      case TelematicUnitLastChangesType.TWO_MONTHS_AGO:
        return { red: true };
      case TelematicUnitLastChangesType.WEEK_AGO:
        return { yellow: true };
      default:
        return { green: true };
    }
  }
}
