import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EquipmentsDataSource } from '../equipments.datasource';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { KeycloakService } from '../../../../core/keycloak';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { SetEquipmentTransportTypesCommand } from '../../contract/set-equipment-transport-types-command';
import { TransportService } from '../../../transportation/shared/services/transport.service';
import { TransportationValidators } from '../../../transportation/shared/validators/transportation-validators.class';
import { LabelChipsComponent } from 'app/shared/components/label-chips-component/label-chips.component';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { switchMap } from 'rxjs/operators';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { LanguageService } from '../../../../shared/services/language.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-transport-types-edit-dialog',
  templateUrl: './equipment-transport-types-edit-dialog.component.html',
  styleUrls: ['./equipment-transport-types-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EquipmentTransportTypeEditDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollRepo', { static: true }) scrollRepo: ElementRef<HTMLDivElement>;
  @ViewChild('labelChips', { read: LabelChipsComponent, static: true }) labelChips: LabelChipsComponent;

  private _transportTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public transportTypes: Observable<string[]>;
  public editedTransportTypes: Observable<string[]> = this._transportTypes.asObservable();
  public hasAuthority: boolean;

  constructor(protected authService: KeycloakService,
              protected dialog: MatDialog,
              private equipmentStore: EquipmentsDataSource,
              private transportService: TransportService,
              private languageService: LanguageService,
              private dialogRef: MatDialogRef<EquipmentTransportTypeEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { equipmentId: string, equipmentTransportTypes: string[] }) {
  }

  public ngOnInit(): void {
    this.transportTypes = this.equipmentStore.getTransportTypes();
    this.hasAuthority = this.authService.hasAuthority(Authorities.TRANSPORT_VEHICLE_MANAGE);
    if (this.data.equipmentTransportTypes === null) {
      this.data.equipmentTransportTypes = [];
    }
    this.setEquipmentTransportTypes();
  }

  public ngAfterViewInit(): void {
    this.preventTypeCreationIfNecessary();
  }

  public save() {
    if (this.data.equipmentId) {
      this.editedTransportTypes
      .pipe(untilDestroyed(this))
      .subscribe((transportTypes) => {
        this.equipmentStore.hasAffectedTransportsByEquipmentAndTransportTypes(this.data.equipmentId, transportTypes)
        .pipe(
            switchMap(hasAffectedTransports => {
              if (hasAffectedTransports) {
                const dialogRef = this.dialog.open(ConfirmationDialogComponent);
                dialogRef.componentInstance.confirmMessage
                    = this.translate('modules.equipment.equipmentAddTransportTypesDialog.confirmationMessage');
                return dialogRef.afterClosed();
              }
              return of(dialogResults.YES);
            }),
            untilDestroyed(this))
        .subscribe(result => {
          if (result === dialogResults.YES) {
            let command: SetEquipmentTransportTypesCommand = new SetEquipmentTransportTypesCommand();
            command.equipmentId = this.data.equipmentId;
            command.transportTypes = transportTypes;
            this.equipmentStore.setEquipmentTransportTypes(command);
          }
          this.dialogRef.close();
        });
      });
    }
  }

  public addTransportType(transportType: string): void {
    const editedTransportTypes = [...this._transportTypes.value];
    editedTransportTypes.push(transportType);
    this._transportTypes.next(editedTransportTypes);
    this.filterTransportTypes(this.transportTypes);
    setTimeout(() => {
      this.scrollRepo.nativeElement.scrollTop = this.scrollRepo.nativeElement.scrollHeight;
    });
  }

  public removeTransportType(label: string): void {
    const editedTransportTypes = [...this._transportTypes.value];
    editedTransportTypes.splice(editedTransportTypes.indexOf(label), 1);
    this._transportTypes.next(editedTransportTypes);
    this.filterTransportTypes(this.equipmentStore.transportTypes);
  }

  public disable(): boolean {
    const lowerCaseTypes = this._transportTypes.value.map((type) => type.toLowerCase());
    return this._transportTypes.value.length === this.data.equipmentTransportTypes.length
      && this.data.equipmentTransportTypes.every(
        (transportType) => lowerCaseTypes.includes(transportType.toLowerCase()));
  }

  private setEquipmentTransportTypes() {
    this.data.equipmentTransportTypes.forEach((transportType) => this.addTransportType(transportType));
  }

  private filterTransportTypes(_transportTypes: Observable<string[]>): void {
    let filteredTransportTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);
    combineLatest([this._transportTypes, _transportTypes])
    .subscribe(([equipmentTransportTypes, transportTypes]) => {
      const currentTransportTypes = transportTypes.map(label => label)
      .filter(label => !equipmentTransportTypes.includes(label));
      filteredTransportTypes.next(currentTransportTypes);
    });

    this.transportTypes = filteredTransportTypes;
  }

  private preventTypeCreationIfNecessary(): void {
    if (!this.authService.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION)) {
      this.labelChips.labelForm.setAsyncValidators(TransportationValidators.hasTransportTypeAsync(this.transportService));
      this.labelChips.labelForm.updateValueAndValidity({ emitEvent: false });
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
