import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs/internal/Observable';
import { Modules } from 'app/shared/enums/modules.enum';
import { KeycloakService } from '../../../../core/keycloak';
import { TransportItemTypeResolverPipe } from '../../shared/pipes/transport-item-type-resolver.pipe';
import { LanguageService } from '../../../../shared/services/language.service';
import { Router } from '@angular/router';
import { TransportItemType } from '../../shared/enums/transport-item-type.enum';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TransportItemLocationType } from '../../shared/enums/transport-item-location-type.enum';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { TransportExecutionDatesEditComponent } from '../transport-execution-dates-edit/transport-execution-dates-edit.component';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';
import { delay, filter, finalize } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { TransportTaskChecker } from '../../shared/services/transport-task-checker.service';
import { TransportStatePipe } from '../../shared/pipes/transport-state.pipe';
import { TransportDialogEventType } from '../../shared/enums/transport-dialog-event-type.enum';
import { TransportBoardDataSource } from '../../shared/services/transport-board.datasource';
import { TransportationTimelineSchedulerService } from '../../shared/services/transportation-timeline-scheduler.service';
import { VehicleDataSource } from '../../shared/services/vehicle.datasource';
import { TransportDetailViewBase } from '../../contracts/transport/transport-detail-view-base.class';
import { ISelectStatusEvent } from '../transport-state-change/select-status-event';

@UntilDestroy()
@Component({
  selector: 'bh-transport-detail-view-dialog',
  templateUrl: './transport-detail-view-dialog.component.html',
  styleUrls: ['./transport-detail-view-dialog.component.scss']
})
export class TransportDetailViewDialogComponent extends TransportDetailViewBase implements OnInit {

  public transportTask: TransportView;
  public isLoading: boolean;
  public readonly emptyValue = '—';
  public readonly transportItemLocationType = TransportItemLocationType;
  public readonly authorities = Authorities;
  public readonly organisationIds: Observable<string[]>;
  public readonly canEditTransport = this.hasModule(Modules.TRANSPORT_TASK_MANAGEMENT)
    && this.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION);
  public readonly canCopyTransport = this.hasModule(Modules.TRANSPORT_TASK_MANAGEMENT)
    && this.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION);
  public readonly transportState = TransportTaskState;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TransportView,
    public dialogRef: MatDialogRef<TransportDetailViewDialogComponent>,
    protected authService: KeycloakService,
    private dialog: MatDialog,
    private transportDataSource: TransportBoardDataSource,
    private vehicleDataSource: VehicleDataSource,
    private schedulerService: TransportationTimelineSchedulerService,
    router: Router,
    languageService: LanguageService,
    transportItemTypeResolver: TransportItemTypeResolverPipe,
    transportStatePipe: TransportStatePipe,
  ) {
    super(router, languageService, transportItemTypeResolver, transportStatePipe, authService);
    this.transportTask = data;
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public editTransportTask(): void {
    if (TransportTaskChecker.isDraftOrReturned(this.transportTask)) {
      this.dialogClose(TransportDialogEventType.EDIT);
      return;
    }

    this.confirmationBeforeEdit(this.translate(
      'modules.transportation.transportDetailView.warningAboutStateBeforeEdit',
      {value: this.transportStatePipe.transform(this.transportTask.state)}));
  }

  public navigateTo(url: string): void {
    const navigateResult = super.navigateTo(url);
    this.closeAfterNavigate(navigateResult);
  }

  public navigateToEquipment(equipmentId: string): void {
    const navigateResult = super.navigateToEquipment(equipmentId);
    this.closeAfterNavigate(navigateResult);
  }

  public navigateToTransport(transportId: string): void {
    const navigateResult = super.navigateToTransport(transportId);
    this.closeAfterNavigate(navigateResult);
  }

  public getTooltipLink(type: TransportItemType): string {
    return this.translate('general.labels.jumpTo', {
      value: this.transportItemTypeResolver.transform(type),
    });
  }

  public updateTransportTaskState(event: ISelectStatusEvent): void {
    this.isLoading = true;
    this.transportDataSource.setNewStatusOfTransportTask(this.transportTask, event.state, event.date)?.pipe(
      delay(environment.DELAY_SHORTEST),
      finalize(() => this.isLoading = false),
      untilDestroyed(this),
    ).subscribe({
      next: () => {
        if (this.isInvolveTransportAssignmentDeletion(this.transportTask.state, event.state)) {
          this.schedulerService.deleteTransportAssignment(this.transportTask.equipmentAssignmentDetails.assignmentId);
        }
        this.transportDataSource.updateCurrentAndListing();
        this.vehicleDataSource.updateListing();
      },
    });
  }

  private isInvolveTransportAssignmentDeletion(oldState: TransportTaskState, newState: TransportTaskState): boolean {
    const needAfterPlanned = oldState === TransportTaskState.PLANNED
      && (newState === TransportTaskState.PLANNABLE || newState === TransportTaskState.CANCELED);
    const needAfterInProgress = oldState === TransportTaskState.IN_PROGRESS && newState === TransportTaskState.CANCELED;
    return needAfterPlanned || needAfterInProgress;
  }

  public copyTransport(): void {
    this.isLoading = true;
    this.transportDataSource.copyTransport(this.transportTask)
    .pipe(
      finalize(() => this.isLoading = false),
      untilDestroyed(this))
    .subscribe(() => this.transportDataSource.updateListing());
  }

  private confirmationBeforeEdit(message: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = message;
    dialogRef.afterClosed()
      .pipe(
        filter(result => result === dialogResults.YES),
        untilDestroyed(this))
      .subscribe(() => this.dialogClose(TransportDialogEventType.EDIT));
  }

  public updateExecutionStartAndEndDate(): void {
    const dialogRef = this.dialog.open(TransportExecutionDatesEditComponent, {data: this.transportTask});
    dialogRef.afterClosed()
      .subscribe(() => {
        this.transportDataSource.updateCurrentTransport();
        this.vehicleDataSource.updateListing();
      });
  }

  public canChangeExecutionDateTime(): boolean {
    return [
      TransportTaskState.IN_PROGRESS,
      TransportTaskState.DONE,
      TransportTaskState.ARCHIVED,
    ].includes(this.transportTask?.state);
  }

  private closeAfterNavigate(navigateResult: Promise<any> | void): void {
    if (navigateResult) {
      navigateResult.then(() => this.dialogRef.close());
    }
  }

  private dialogClose(eventType: TransportDialogEventType): void {
    this.dialogRef.close(eventType);
  }

  private initListeners(): void {
    this.transportDataSource.currentTransport
      .pipe(untilDestroyed(this))
      .subscribe(transport => this.transportTask = transport);
  }

}
