import { Serializable } from './serialiazable';
import { Authorities } from '../enums/authorities.enum';
export class User extends Serializable {
  name: string;
  firstName: string;
  lastName: string;
  authorities: any[];
  roles: any[];
  modules: any[];
  email: string;
  userId: string;
  customerId: string;
  partnerId: string;
  organisations: string[];


  constructor(token: any) {
    super();
    this.authorities = token.realm_access.roles;
    this.roles = token.groups;
    this.modules = token.modules;
    this.firstName = token.given_name;
    this.lastName = token.family_name;
    this.email = token.email;
    this.userId = token.userId;
    this.customerId = token.customerId;
    this.partnerId = token.partnerId;
    this.organisations = token.organisations;
  }

  public updateUser(token: any) {
    this.authorities = token.realm_access.roles.map((role) => Authorities[role]);
    this.roles = token.groups;
    this.modules = token.modules;
    this.firstName = token.given_name;
    this.lastName = token.family_name;
    this.email = token.email;
    this.userId = token.userId;
    this.customerId = token.customerId;
    this.partnerId = token.partnerId;
    this.organisations = token.organisations;
  }

  getFullName() {
    return (this.firstName ? this.firstName : '') + ' ' + (this.lastName ? this.lastName : '');
  }
}

