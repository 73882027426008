import { FormatDispositionProjectDateRangePipe } from 'app/shared/pipes/format-date/format-disposition-project-date-range.pipe';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { Router, ActivatedRoute } from '@angular/router';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { DispositionDataSource } from 'app/modules/disposition/shared/disposition.datasource';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { OnlineStatusService } from 'app/shared/services/online-status.service';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { RouterHistory } from 'app/shared/router-history';
import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { EmployeeDispositionDatasource } from 'app/modules/disposition/shared/employee-disposition.datasource';
import { BaseEmployeeAssignToProjectComponent } from '../base/base-employee-assign-to-project-component';
import { EmployeeAssignToProjectUtils } from '../base/employee-assign-to-project-utils';
import { LanguageService } from 'app/shared/services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UsersService } from '../../../../userrole/shared/users.service';
import { EmployeeDispositionMessageResolver } from 'app/shared/components/disposition-assign-confirmation-message/disposition-message-resolver/employee-disposition-message-resolver';

@UntilDestroy()
@Component({
  selector: 'bh-employee-assign-to-project-dialog',
  templateUrl: './employee-assign-to-project-dialog.component.html',
  styleUrls: ['./employee-assign-to-project-dialog.component.scss']
})
export class EmployeeAssignToProjectDialogComponent extends BaseEmployeeAssignToProjectComponent implements OnInit, OnDestroy {

  public filterControl: UntypedFormControl = new UntypedFormControl();
  public readonly fieldLimit = FieldLimit;

  @ViewChild('assignToProjectStepper', { static: true }) stepper: MatStepper;

  public formatAddress = EmployeeAssignToProjectUtils.formatAddress;
  public formatCostCenter = EmployeeAssignToProjectUtils.formatCostCenter;
  public messageResolver: EmployeeDispositionMessageResolver;

  constructor(protected authService: KeycloakService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected equipmentStore: EquipmentsDataSource,
    protected dispositionStore: DispositionDataSource,
    protected projectStore: ProjectDataSource,
    protected formBuilder: UntypedFormBuilder,
    protected onlineStatusService: OnlineStatusService,
    protected dialog: MatDialog,
    protected routerHistory: RouterHistory,
    protected employeeStore: EmployeeDispositionDatasource,
    protected employeeDispositionStore: EmployeeDispositionDatasource,
    protected  usersService: UsersService,
    protected langService: LanguageService,
    private formatDispositionProjectDateRange: FormatDispositionProjectDateRangePipe) {
      super(authService,
         router,
          route,
          employeeDispositionStore,
          routerHistory,
          projectStore,
          onlineStatusService,
          dialog,
          formBuilder,
          usersService,
          langService)
     }

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadProjects();
    this.filterControl.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.filterProjects();
    });
    this.messageResolver = new EmployeeDispositionMessageResolver(this.langService);
  }

  public ngOnDestroy() {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public get saveWarning(): string {
    if (this.stepper.selectedIndex > 0 && !this.project) {
      return this.langService.getInstant('modules.disposition.addEmployeeDisposition.noProjectSelected');
    }
    if (this.hasConflicts()) {
      return this.langService.getInstant('general.labels.conflictsFound');
    }
    return '';
  }

  private hasConflicts(): boolean {
    return this.employeeDispositions
    .filter(disposition => disposition.hasError('conflicts'))
      .length > 0;
  }

  private filterProjects(): void {
    if (!this.projects) {
      return;
    }
    let search = this.filterControl.value;
    if (!search) {
      this.filteredProjects.next(this.projects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredProjects.next(
      this.projects.filter(project => this.searchMatchesProject(search, project))
    );
  }

  private searchMatchesProject(search: string, project: DispositionProject): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0 && (project.projectName.toLowerCase().indexOf(param) === -1
        && this.formatAddress(project.projectAddress).toLowerCase().indexOf(param) === -1
        && this.formatDispositionProjectDateRange.transform(project).toLowerCase().indexOf(param) === -1)
        && this.formatCostCenter(project.projectCostCenter).toLowerCase().indexOf(param) === -1) {
        return false;
      }
    }
    return true;
  }
}
