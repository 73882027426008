<div *ngIf="(data | chartData : strategy) as transformData" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start start">
    <bh-subtitle [text]="subtitle"></bh-subtitle>
    <p class="last-update">{{ lastUpdate | telematicsFormatDate }}</p>
  </div>
  <div class="chart-container">
    <canvas baseChart
          [type]="type"
          [data]="transformData"
          [options]="options"
          [legend]="false">
  </canvas>
  </div>
</div>
