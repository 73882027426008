import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { VehicleFilterRequestParams } from './vehicle-filter-request-params.interface';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import {
  SingleFilterOption
} from '../../../../../shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';

export class VehicleFilterParams extends FilterParams {
  public getFilterParams(): VehicleFilterRequestParams {
    return {
      transportType: this.getFilterTypeParams(FilterTypes.TRANSPORT_TYPE),
      label: this.getFilterTypeParams(FilterTypes.LABEL),
      organisation: this.getFilterTypeParams(FilterTypes.ORGANISATION),
      type: this.getFilterTypeParams(FilterTypes.EQUIPMENT_CLASS),
      status: this.getFilterTypeParams(FilterTypes.EQUIPMENT_STATE).filter(value => !EquipmentStatusCategory[value]),
      matchAllLabels: this.getFilterTypeParams(FilterTypes.MATCH_ALL_LABELS).some(
        value => value === SingleFilterOption.ALL_LABELS)
    }
  }
}
