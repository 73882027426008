import { AssignmentResolveReason } from 'app/shared/enums/assignment-resolve-reason.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { DispositionMessageResolver } from './disposition-message-resolver.class';
import { DispositionMessages } from './disposition-messages.interface';

export class EquipmentDispositionMessageResolver extends DispositionMessageResolver {
  constructor(languageService: LanguageService) {
    super(languageService);
  }

  public getMessages(reasons: AssignmentResolveReason[]): DispositionMessages {
    let confirmationMessage: string;
    let warningMessage: string;

    const workflowIndex = reasons.indexOf(AssignmentResolveReason.HAS_ACKNOWLEDGE_REQUEST_WORKFLOW);
    if (workflowIndex >= 0) {
      const workflowMessage = this.translate('modules.disposition.confirmation.message.dispositionAcknowledgeRequestWorkflow');
      reasons.splice(workflowIndex, 1);
      if (reasons.length === 0) {
        return {
          confirmationMessage: workflowMessage
        };
      }
      warningMessage = workflowMessage;
    }

    confirmationMessage = reasons.length > 1
      ? this.translate('modules.disposition.confirmation.message.dispositionConflicts')
      : this.getSpecificMessage(reasons[0]);

    return {
      confirmationMessage,
      warningMessage
    };
  }

  private getSpecificMessage(reason: AssignmentResolveReason): string {
    switch (reason) {
      case AssignmentResolveReason.HAS_DATE_RANGE_CONFLICT:
        return this.translate('modules.disposition.confirmation.message.dispositionConflictProjectDateRange');
      case AssignmentResolveReason.HAS_ASSIGNMENT_COLLISION:
        return this.translate('modules.disposition.confirmation.message.dispositionConflictEquipmentAssigned');
      case AssignmentResolveReason.HAS_SUBEQUIPMENT:
        return this.translate('modules.disposition.confirmation.message.dispositionEquipmentHasSubequipment');
      default:
      return this.translate('modules.disposition.confirmation.message.dispositionConflicts');
    }
  }
}
