import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatMenu} from '@angular/material/menu';
import {ViewEquipment} from '../../../../modules/equipment/contract/view-equipment.interface';
import {Status} from '../../../../modules/equipment/contract/status.interface';
import {EquipmentsService} from '../../../../modules/equipment/shared/equipments.service';
import {LanguageService} from '../../../services/language.service';
import {EquipmentCheckerService} from '../../../../modules/equipment/shared/services/equipment-checker.service';
import {EquipmentStatusCategory} from '../../../../modules/equipment/contract/equipment-status-category.enum';
import {KeycloakService} from '../../../../core/keycloak';
import {Authorities} from '../../../enums/authorities.enum';

@Component({
  selector: 'bh-equipment-status-dropdown',
  templateUrl: './equipment-status-dropdown.component.html',
  styleUrls: ['./equipment-status-dropdown.component.scss']
})
@UntilDestroy()
export class EquipmentStatusDropdownComponent implements OnInit {

  @ViewChild(MatMenu, {static: true}) menu: MatMenu;

  @Input()
  public set equipment(equipment: ViewEquipment) {
    this._equipment = equipment;
    this._isTransferredToProject = this.equipmentCheckerService.isTransferedToProject(equipment);
    this._hasActiveTransferRequest = this.equipmentCheckerService.hasActiveTransferRequest(equipment);
  }

  @Input() public disabled = false;
  @Input() public categoryFilter = (_: EquipmentStatusCategory) => true;
  @Input() public noStatusItemText: string;

  @Output() public onStatusChange: EventEmitter<Status> = new EventEmitter();

  private _equipment: ViewEquipment;
  private _categories: string[] = [];
  private _statuses: Status[] = [];
  private _isTransferredToProject: boolean;
  private _hasActiveTransferRequest: boolean;

  constructor(private langService: LanguageService,
              protected authService: KeycloakService,
              private equipmentService: EquipmentsService,
              private equipmentCheckerService: EquipmentCheckerService) {
  }

  public ngOnInit(): void {
    if (!this.disabled) {
      this.equipmentService.getNewEquipmentStatuses()
      .pipe(untilDestroyed(this))
      .subscribe((res: Status[]) => {
        this._categories = EquipmentStatusDropdownComponent.extractCategoriesFromStatuses(res);
        this._statuses = res;
      });
    }
  }

  public get categories(): string[] {
    return (this._categories || []).filter(this.categoryFilter);
  }

  public get statuses(): Status[] {
    return this._statuses;
  }

  protected translate(key: string): string {
    return this.langService.getInstant(key);
  }

  public statusesByCategory(category: string): Status[] {
    const statusesSortedByRank: Status[] = this.statuses.sort((a, b) => a.rank - b.rank)
    return statusesSortedByRank.filter((status) => status.category === category)
  }

  private static extractCategoriesFromStatuses(statuses: Status[]) {
    const statusesSortedByRank: Status[] = statuses.sort((a, b) => a.rank - b.rank);
    return statusesSortedByRank.map((status) => status.category).reduce((p, c) => {
      if (p.indexOf(c) === -1) {
        p.push(c);
      }
      return p;
    }, []);
  }

  public onStatusButtonClick(equipmentStatus: Status): void {
    this.onStatusChange.emit(equipmentStatus);
  }

  public onNoStatusButtonClick(): void {
    this.onStatusChange.emit(null);
  }

  public shouldBeDisabled(status: Status): boolean {
    let disableByAuthority: boolean = this.disableByAuthority(status);
    return !this._equipment
      || this.isCurrentStatus(status)
      || (this.isStatusCategoryNotAvailable(status)
        ? this._isTransferredToProject || this._hasActiveTransferRequest
        : false)
      || disableByAuthority;
  }

  public disableByAuthority(status: Status): boolean {

    if (this.authService.hasAuthority(Authorities.EQUIPMENT_STATUS_UPDATE_CATEGORY)
      && status.category !== this._equipment?.status?.category) {
      return true;
    }

    if (this.authService.hasAuthority(Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE)
      && this._equipment?.status?.category === EquipmentStatusCategory.NOT_AVAILABLE
      && status.category !== EquipmentStatusCategory.NOT_AVAILABLE) {
      return true;
    }

    return this.authService.hasAuthority(Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE)
      && this._equipment?.status?.category !== EquipmentStatusCategory.NOT_AVAILABLE
      && status.category === EquipmentStatusCategory.NOT_AVAILABLE;

  }

  public getStatusTooltip(status: Status): string {
    if (!this._equipment) {
      return null;
    } else if (this.disableByAuthority(status)) {
      return this.translate('shared.pipes.equipmentStatus.blockedByUserAuthority');
    } else if (this._hasActiveTransferRequest && this.isStatusCategoryNotAvailable(status)) {
      return this.translate('shared.pipes.equipmentStatus.blockedByTransferRequest');
    } else if (this._isTransferredToProject && this.isStatusCategoryNotAvailable(status)) {
      return this.translate('shared.pipes.equipmentStatus.equipmentNeedsBeOnStock');
    } else {
      return null;
    }
  }

  private isCurrentStatus(status: Status): boolean {
    return this._equipment?.status?.equipmentStatusId === status?.equipmentStatusId;
  }

  private isStatusCategoryNotAvailable(status: Status): boolean {
    return this.equipmentCheckerService.isNotAvailableAnymore({ status });
  }
}
