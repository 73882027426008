import { LanguageService } from 'app/shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { ProjectCheckerService } from '../../../../../shared/services/project-checker.service';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewProjectAmount } from '../../../../../contract/view-project-amount.interface';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { EquipmentsDataSource } from '../../../../../../equipment/shared/equipments.datasource';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProjectStructureType } from 'app/modules/disposition/shared/enums/project-structure-type';

@UntilDestroy()
@Directive()
export abstract class BaseProjectViewDispositionsComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  public assignmentsEditable = false;
  public bulkItems: ViewProjectAmount[] = [];
  public equipments: ViewProjectAmount[] = [];
  public searchFilter: UntypedFormControl = new UntypedFormControl();

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              public equipmentStore: EquipmentsDataSource,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  get project(): ViewProject {
    return this.projectStore.project();
  }

  ngOnInit(): void {
    this.projectStore.currentViewTab = 'assignments';
    this.subscribeToCurrentProject();
    this.inventoryEquipmentsSubscription();
    this.inventoryBulkItemsSubscription();
    this.synchronizeSearchTermValue();
    this.assignmentsEditable = this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_DISPOSITION);
    this.filterAmountsOnInput();
  }

  ngOnDestroy(): void {
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  protected translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private subscribeToCurrentProject(): void {
    this.projectStore.currentProject
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewProject) => {
      if (res && res.projectStructureType !== ProjectStructureType.PROJECT_GROUP) {
        this.getRequestedAssignments(res.projectId);
        this.projectStore.resetInventoryPageIndexes();
        this.projectStore.updateInventoryEquipments();
        this.projectStore.updateInventoryBulkItems();
      }
    });
  }

  private getRequestedAssignments(projectId: string): void {
    this.equipmentStore.getRequestedAssignments({projectId});
  }

  private inventoryEquipmentsSubscription(): void {
    this.projectStore.inventoryEquipments.subscribe(res => this.equipments = res);
  }

  private synchronizeSearchTermValue(): void {
    this.searchFilter.setValue(this.projectStore.getInventorySearchTerms());
  }

  private inventoryBulkItemsSubscription(): void {
    this.projectStore.inventoryBulkItems.subscribe(res => this.bulkItems = res);
  }

  private filterAmountsOnInput(): void {
    this.searchFilter
      .valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(searchTerm => this.projectStore.updateInventorySearchTerms(searchTerm));
  }
}
