import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../shared/router-history';
import { takeWhile } from 'rxjs/operators';
import { ViewEmployee } from '../../../../contract/view-employee.interface';
import { EmployeeManagementDatasource } from '../../../../shared/employee-management.datasource';

@Directive()
export abstract class BaseEmployeeViewComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public employee: ViewEmployee;
  public equipmentsAssigned: boolean;
  public projectAssigned: boolean;
  protected componentActive = true;

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected routerHistory: RouterHistory,
                        protected employeeManagementStore: EmployeeManagementDatasource) {
    super(authService, router, route, routerHistory);
    this.handleRouting();
  }

  public ngOnInit(): void {
    this.subscribeToCurrentEmployee();
    this.subscribeToEquipmentAssignedToCurrentEmployee();
    this.subscribeToProjectAssignedToCurrentEmployee();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private handleRouting(): void {
    this.route.params
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((params: Params) => {
      if (params.id) {
        const auxIndex = params.id.indexOf('(');
        let employeeId = params.id;
        if (auxIndex > -1) {
          employeeId = params.id.slice(0, auxIndex);
        }
        this.employeeManagementStore.changeCurrentEmployee(employeeId);
      }
    });
    this.router.events
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((event: Event) => {
      if (event instanceof NavigationEnd && this.route.snapshot.firstChild) {
        this.employeeManagementStore.currentViewTab = this.route.snapshot.firstChild.url[0].path;
      }
    });
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployee) => {
      if (res) {
        this.employee = res;
      }
    });
  }

  private subscribeToEquipmentAssignedToCurrentEmployee(): void {
    this.employeeManagementStore.equipmentAssignedToCurrentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: boolean) => {
        this.equipmentsAssigned = res;
    });
  }

  private subscribeToProjectAssignedToCurrentEmployee(): void {
    this.employeeManagementStore.projectAssignedToCurrentEmployee
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((res: boolean) => {
        this.projectAssigned = res;
      });
  }

}
