import { TransportTaskState } from './../enums/transport-task-status.enum';

export class TransportStateHierarchy {
  private static childParentStateMap = {
    [TransportTaskState.DRAFT]: TransportTaskState.OPEN,
    [TransportTaskState.PLANNABLE]: TransportTaskState.OPEN,
    [TransportTaskState.RETURNED]: TransportTaskState.OPEN,

    [TransportTaskState.PLANNED]: TransportTaskState.ACTIVE,
    [TransportTaskState.IN_PROGRESS]: TransportTaskState.ACTIVE,
    [TransportTaskState.DONE]: TransportTaskState.ACTIVE,

    [TransportTaskState.ARCHIVED]: TransportTaskState.FINISHED,
    [TransportTaskState.CANCELED]: TransportTaskState.FINISHED,
  };

  private static parentChildrenStateMap = Object.keys(TransportStateHierarchy.childParentStateMap)
    .reduce((acc, state) => {
      const key = TransportStateHierarchy.childParentStateMap[state];
      return {
        ...acc,
        [key]: [...(acc[key] || []), state]
      }
    }, {});

  public static readonly parentStates = new Set<TransportTaskState>(
    <TransportTaskState[]>Object.keys(TransportStateHierarchy.parentChildrenStateMap));

  public static getParent(state: TransportTaskState | string): TransportTaskState {
    return TransportStateHierarchy.childParentStateMap[state];
  }

  public static isParentState(state: TransportTaskState | string): boolean {
    return this.parentStates.has(<TransportTaskState>state);
  }

  public static getChildren(state: TransportTaskState | string): TransportTaskState[] {
    return TransportStateHierarchy.parentChildrenStateMap[state] || [];
  }
}
