import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../core/guards/can-activate-child-authority';
import { Authorities } from '../../shared/enums/authorities.enum';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportAddEditComponent } from './report-add-edit/report-add-edit.component';
import { ReportViewComponent } from './report-view/report-view.component';
import { GenerateReportComponent } from './report-view/report-generation/generate-report/generate-report.component';
import { AddEditReportJobComponent } from './report-view/report-jobs/add-edit-report-job/add-edit-report-job.component';
import { Modules } from '../../shared/enums/modules.enum';
import { CanActivateModule } from '../../core/guards/can-activate-module';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';


const reportsRoutes: Routes = [
  {
    path: 'reportdefinitions',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: ReportListComponent,
        data: {
          authorities: [Authorities.REPORT_MANAGE]
        }
      },
      {
        path: 'add',
        component: ReportAddEditComponent,
        data: {
          authorities: [Authorities.REPORT_MANAGE]
        }
      },
      {
        path: 'edit/:id',
        component: ReportAddEditComponent,
        data: {
          authorities: [Authorities.REPORT_MANAGE]
        }
      }
    ]
  },
  {
    path: 'reports',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'view',
        component: ReportViewComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.REPORT_VIEW],
          modules: [Modules.REPORTING]
        }
      },
      {
        path: 'generate/:reportDefinitionId',
        component: GenerateReportComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.REPORT_VIEW],
          modules: [Modules.REPORTING]
        }
      },
      {
        path: 'jobs/add',
        component: AddEditReportJobComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.REPORT_VIEW],
          modules: [Modules.REPORTING]
        }
      },
      {
        path: 'jobs/edit/:reportJobId',
        component: AddEditReportJobComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.REPORT_VIEW],
          modules: [Modules.REPORTING]
        }
      },
    ]
  }
];

export const reportsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(reportsRoutes);
