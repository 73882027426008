import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { DocuwareDocument } from '../../../contract/docuware-document.interface';
import { BaseAttachmentDocuwareComponent } from '../base/base-attachment-docuware.component';

@Component({
  selector: 'bh-attachment-docuware',
  templateUrl: './attachment-docuware.component.html',
  styleUrls: ['./attachment-docuware.component.scss']
})
export class AttachmentDocuwareComponent extends BaseAttachmentDocuwareComponent {

  constructor(private languageService: LanguageService) {
    super();
  }

  public getToolTip(document: DocuwareDocument): string {
    return `${this.languageService.getInstant('shared.attachment.fileFormat')}: ${document.contentType.split('/')[1]}`;
  }
}
