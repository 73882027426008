import { AlarmTypeResolver } from './alarm-type.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { AlarmSettingsFieldData } from '../contract/alarm-field-data.type';
import { AlarmSettingsType } from '../contract/alarm-settings-type.enum';
import { AlarmSettingsUtilsService } from './alarm-settings-utils.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationDataSource } from '../shared/services/notification.datasource';
import { CreateAlarmRuleCommand } from '../contract/create-alarm-rule-command';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { KeycloakService } from '../../../core/keycloak';
import { ViewAlarmRule } from '../contract/view-alarm-rule.interface';
import { UpdateAlarmRuleCommand } from '../contract/update-alarm-rule-command';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { AlarmTypeConfig } from '../shared/interfaces/alarm-type-config.interface';
import { ViewGlobalGeofence } from '../../equipment/contract/view-global-geofence.interface';
import { RoleAuthorityGuardsComponent } from '../../../shared/navigation-guards/role-authority-guards.component';
import { PartnerTheme } from 'app/modules/organisation/contract/partner-theme.enum';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { AlarmTypeService } from '../shared/services/alarm-types.service';
import { AlarmRuleControl } from '../contract/alarm-rule-control.enum';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { DatesService } from 'app/shared/services/dates.service';
import { EquipmentAlarmRuleSelectionType } from '../contract/equipment-alarm-rule-selection-type.enum';
import {
  TelematicsSelectorEnum
} from './components/alarm-settings/inactive-telematic-unit-settings/telematics-selector.enum';
import { DimensionUnitConverterPipe } from '../../../shared/pipes/dimension-unit-converter.pipe';
import {
  telematicsUnitsTypesWithoutBeacons
} from './components/alarm-settings/inactive-telematic-unit-settings/telematics-unit-types-without-beacons';

@UntilDestroy()
@Component({
  selector: 'bh-alarm-rule-add-edit-dialog',
  templateUrl: './alarm-rule-add-edit-dialog.component.html',
  styleUrls: ['./alarm-rule-add-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmRuleAddEditDialogComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  // set in parent component
  public alarmRule: ViewAlarmRule | null;
  public labels: string[] = [];
  public equipmentTypes: EquipmentTypeFilterView[] = [];
  public globalGeofences: ViewGlobalGeofence[] = [];

  // used in html
  public alarmRuleForm: UntypedFormGroup;
  public readonly allowedAlarmTypes: Map<AlarmType, AlarmTypeConfig> =
    new Map(this.alarmTypeService.getAllowedAlarmTypes().map(type => [type.type, type]));
  public readonly controlNames = AlarmRuleControl;
  public readonly settingsTypes = AlarmSettingsType;
  public readonly alarmType = AlarmType;
  public faExclamationTriangle: IconDefinition = faExclamationTriangle;

  constructor(protected authService: KeycloakService,
              private formBuilder: UntypedFormBuilder,
              private notificationStore: NotificationDataSource,
              private alarmTypeService: AlarmTypeService,
              public alarmTypeResolver: AlarmTypeResolver,
              private alarmSettingsUtilsService: AlarmSettingsUtilsService,
              private userConfigurationService: UserConfigurationService,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              private dialogRef: MatDialogRef<AlarmRuleAddEditDialogComponent>) {
    super(authService);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.initListener();

    if (this.isEditMode) {
      this.patchFormToEdit();
    }
  }

  public ngOnDestroy(): void {}

  public get alarmTypeControl(): AbstractControl {
    return this.alarmRuleForm.get(this.controlNames.ALARM_TYPE);
  }

  public get selectedAlarmType(): AlarmType {
    return this.alarmRuleForm.get(this.controlNames.ALARM_TYPE).value;
  }

  public get settingsForm(): AbstractControl {
    return this.alarmRuleForm.get(this.controlNames.SETTINGS);
  }

  public get limitControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_LIMIT);
  }

  public get intervalControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_INTERVAL);
  }

  public get globalGeofenceControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_GLOBAL_GEOFENCE);
  }

  public get geofenceTriggerControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_GEOFENCE_TRIGGER);
  }

  public get dataForm(): AbstractControl {
    return this.alarmRuleForm.get(this.controlNames.DATA);
  }

  public get allowedAlarmTypeOptions(): AlarmType[] {
    return [...this.allowedAlarmTypes.keys()];
  }

  public isValid(): boolean {
    return this.alarmRuleForm.valid;
  }

  public currentSettingsType(): AlarmSettingsType {
    return this.alarmSettingsUtilsService.getAlarmSettingsType();
  }

  public getAlarmSettingsFieldData(): AlarmSettingsFieldData {
    return this.alarmSettingsUtilsService.getAlarmSettingsFieldData();
  }

  public getAlarmTypeDefaultExploration(alarmType: AlarmType): string {
    return this.allowedAlarmTypes.get(alarmType)?.explanation;
  }

  public get isEditMode(): boolean {
    return this.alarmRule !== null;
  }

  public save(): void {
    if (this.isValid()) {
      this.isEditMode ? this.updateAlarmRule() : this.saveAlarmRule();
    }
  }

  public isWNTheme() {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.WACKER_NEUSON;
  }

  private buildForm(): void {
    this.alarmRuleForm = this.formBuilder.group({
      [this.controlNames.ALARM_TYPE]: [null, Validators.required],
      [this.controlNames.SETTINGS]: this.formBuilder.group({
        [this.controlNames.SETTINGS_LIMIT]: [null, Validators.min(0)],
        [this.controlNames.SETTINGS_LIMIT_UNIT]: [null],
        [this.controlNames.SETTINGS_INTERVAL]: null,
        [this.controlNames.SETTINGS_GLOBAL_GEOFENCE]: null,
        [this.controlNames.SETTINGS_GEOFENCE_TRIGGER]: null,
        [this.controlNames.SETTINGS_TIMEFENCING_HOURS]: null,
        [this.controlNames.SETTINGS_TIMEFENCING_DISTANCE]: null,
        [this.controlNames.SETTINGS_TIMEFENCING_WEEK_DAY]: null,
        [this.controlNames.SETTINGS_TELEMATICS_SELECTOR]: null,
        [this.controlNames.SETTINGS_TELEMATICS_TYPES]: null,
        [this.controlNames.SETTINGS_TELEMATICS_BATTERY_STATUS]: null,
      }),
      [this.controlNames.DATA]: this.formBuilder.group({
        [this.controlNames.DATA_ALARM_MESSAGE]: [null, [<any>Validators.required]],
        [this.controlNames.DATA_ALARM_LEVEL]: [null, Validators.required],
        [this.controlNames.DATA_SELECTION_TYPE]: [null, Validators.required],
        [this.controlNames.DATA_LABELS]: [{value: null, disabled: this.isEditMode}],
        [this.controlNames.DATA_EQUIPMENT_TYPES]: [{value: null, disabled: this.isEditMode}],
        [this.controlNames.DATA_EMAIL_TOGGLE]: false,
        [this.controlNames.DATA_EMAIL_RECIPIENTS]: null,
        [this.controlNames.DATA_ROLE_RECIPIENTS]: null
      })
    })
  }

  private initListener(): void {
    this.alarmTypeControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => this.alarmTypeChanged());
  }

  private patchFormToEdit(): void {
    const hasTimefenceConf = Boolean(this.alarmRule.timefenceConfiguration);
    const isTelematicsAlarm = this.alarmRule.alarmType === AlarmType.INACTIVE_TELEMATICS_UNIT;

    const formValue = {
      [this.controlNames.ALARM_TYPE]: this.alarmRule.alarmType,
      [this.controlNames.SETTINGS]: {
        [this.controlNames.SETTINGS_LIMIT]: this.alarmRule.limit,
        [this.controlNames.SETTINGS_LIMIT_UNIT]: this.alarmRule.limitDurationUnit,
        [this.controlNames.SETTINGS_INTERVAL]: this.alarmRule.timeframeInDays,
        [this.controlNames.SETTINGS_GLOBAL_GEOFENCE]: this.alarmRule.globalGeofenceId,
        [this.controlNames.SETTINGS_GEOFENCE_TRIGGER]: this.alarmRule.geofenceAlarmTrigger,
        [this.controlNames.SETTINGS_TIMEFENCING_HOURS]: hasTimefenceConf
          ? this.alarmRule.timefenceConfiguration.operatingHoursLimit
          : null,
        [this.controlNames.SETTINGS_TIMEFENCING_DISTANCE]: hasTimefenceConf
          ? this.dimensionUnitConverterPipe.toUserDimensionUnit(this.alarmRule.timefenceConfiguration.distanceLimit, 'm')
          : null,
        [this.controlNames.SETTINGS_TIMEFENCING_WEEK_DAY]: hasTimefenceConf ?
         this.alarmRule.timefenceConfiguration.weekDayConfiguration : null,
        [this.controlNames.SETTINGS_TELEMATICS_TYPES]: isTelematicsAlarm ? this.alarmRule.telematicsUnitTypes : [],
        [this.controlNames.SETTINGS_TELEMATICS_SELECTOR]: this.getTelematicSelector(isTelematicsAlarm),
        [this.controlNames.SETTINGS_TELEMATICS_BATTERY_STATUS]: this.alarmRule.batteryStatus,
      },
      [this.controlNames.DATA]: {
        [this.controlNames.DATA_ALARM_MESSAGE]: this.alarmRule.alarmMessage,
        [this.controlNames.DATA_ALARM_LEVEL]: this.alarmRule.alarmLevel,
        [this.controlNames.DATA_SELECTION_TYPE]: this.alarmRule.selectionType,
        [this.controlNames.DATA_LABELS]: this.alarmRule.labels,
        [this.controlNames.DATA_EQUIPMENT_TYPES]: this.alarmRule.equipmentTypeIds,
        [this.controlNames.DATA_EMAIL_RECIPIENTS]: this.alarmRule.emailRecipientsFilledWithInfo
          ? this.alarmRule.emailRecipientsFilledWithInfo.map(({ userId }) => userId)
          : null,
        [this.controlNames.DATA_ROLE_RECIPIENTS]: this.alarmRule.userRolesEmailRecipientsFilledWithInfo
          ? this.alarmRule.userRolesEmailRecipientsFilledWithInfo.map(({ roleId }) => roleId)
          : null
      }
    }

    this.alarmRuleForm.patchValue(formValue);
  }

  private getTelematicSelector(isTelematicsAlarm: boolean) {
    if (!isTelematicsAlarm) {
      return null;
    }

    if (this.alarmRule.telematicsUnitTypes.length === 0) {
      return TelematicsSelectorEnum.ALL;
    } else if(this.compareArrays()) {
      return TelematicsSelectorEnum.ALL_TELEMATIC_UNITS_EXCEPT_BEACONS;
    } else {
      return TelematicsSelectorEnum.SELECTED;
    }
  }

  private compareArrays() {
    return this.alarmRule.telematicsUnitTypes.filter(item => telematicsUnitsTypesWithoutBeacons.indexOf(item) < 0).length === 0
      && this.alarmRule.telematicsUnitTypes.length === telematicsUnitsTypesWithoutBeacons.length
  }

  private alarmTypeChanged(): void {
    this.settingsForm.reset();
    this.alarmSettingsUtilsService.alarmType = this.selectedAlarmType;
    this.updateValidators();
  }

  private updateValidators(): void {
    [
      this.controlNames.SETTINGS_LIMIT,
      this.controlNames.SETTINGS_LIMIT_UNIT,
      this.controlNames.SETTINGS_INTERVAL,
      this.controlNames.SETTINGS_GLOBAL_GEOFENCE,
      this.controlNames.SETTINGS_GEOFENCE_TRIGGER,
      this.controlNames.SETTINGS_TIMEFENCING_WEEK_DAY,
      this.controlNames.SETTINGS_TELEMATICS_SELECTOR,
      this.controlNames.SETTINGS_TELEMATICS_TYPES,
    ].forEach(controlName => this.setValidator(controlName));
  }

  private setValidator(controlName: string): void {
    const control = this.settingsForm.get(controlName);
    control.setValidators(this.alarmSettingsUtilsService.getValidators(controlName));
    control.updateValueAndValidity();
  }

  private saveAlarmRule(): void {
    const cmd: CreateAlarmRuleCommand = new CreateAlarmRuleCommand();
    const formData = this.alarmRuleForm.getRawValue();
    this.setCommonCommandValues(cmd, formData);
    cmd.customerId = this.authService.getUserCustomerId();
    cmd.zoneId = DatesService.getLocalTimeZone();

    this.notificationStore.saveAlarmRule(cmd);
    this.dialogRef.close();
  }

  private updateAlarmRule(): void {
    const cmd: UpdateAlarmRuleCommand = new UpdateAlarmRuleCommand();
    const formData = this.alarmRuleForm.getRawValue();
    this.setCommonCommandValues(cmd, formData);
    cmd.alarmRuleId = this.alarmRule.alarmRuleId;

    this.notificationStore.updateAlarmRule(cmd);
    this.dialogRef.close();
  }

  private setCommonCommandValues(cmd: CreateAlarmRuleCommand | UpdateAlarmRuleCommand, formData: any): void {
    cmd.alarmType = formData[this.controlNames.ALARM_TYPE];
    cmd.limit = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_LIMIT];
    cmd.limitDurationUnit = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_LIMIT_UNIT];
    cmd.timeframeInDays = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_INTERVAL];
    cmd.globalGeofenceId = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_GLOBAL_GEOFENCE];
    cmd.geofenceAlarmTrigger = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_GEOFENCE_TRIGGER];

    cmd.alarmMessage = formData[this.controlNames.DATA][this.controlNames.DATA_ALARM_MESSAGE];
    cmd.alarmLevel = formData[this.controlNames.DATA][this.controlNames.DATA_ALARM_LEVEL];

    if (this.currentSettingsType() === AlarmSettingsType.TIMEFENCING) {
      cmd.timefenceConfiguration = { weekDayConfiguration:
         formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_TIMEFENCING_WEEK_DAY] };
      cmd.timefenceConfiguration.operatingHoursLimit
        = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_TIMEFENCING_HOURS];
      cmd.timefenceConfiguration.distanceLimit = this.dimensionUnitConverterPipe
       .toSystemDimensionUnit(formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_TIMEFENCING_DISTANCE], 'm')
    }

    if (this.currentSettingsType() === AlarmSettingsType.TELEMATICS_UNIT) {
      cmd.telematicsUnitTypes = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_TELEMATICS_TYPES];
    }

    if (this.currentSettingsType() === AlarmSettingsType.TRACKER_BATTERY_STATUS) {
      cmd.batteryStatus = formData[this.controlNames.SETTINGS][this.controlNames.SETTINGS_TELEMATICS_BATTERY_STATUS];
    }

    if (formData[this.controlNames.DATA][this.controlNames.DATA_EMAIL_TOGGLE]) {
      cmd.emailRecipients = formData[this.controlNames.DATA][this.controlNames.DATA_EMAIL_RECIPIENTS];
      cmd.userRolesEmailRecipients = formData[this.controlNames.DATA][this.controlNames.DATA_ROLE_RECIPIENTS];
    }

    cmd.selectionType = formData[this.controlNames.DATA][this.controlNames.DATA_SELECTION_TYPE];
    if (cmd.selectionType === EquipmentAlarmRuleSelectionType.LABEL) {
      cmd.labels = formData[this.controlNames.DATA][this.controlNames.DATA_LABELS];
    } else if (cmd.selectionType === EquipmentAlarmRuleSelectionType.EQUIPMENT_TYPE) {
      cmd.equipmentTypeIds = formData[this.controlNames.DATA][this.controlNames.DATA_EQUIPMENT_TYPES];
    }
  }
}
