<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="data?.confirmTitle">
    <mat-icon class="delete-icon">delete</mat-icon>
    {{ data.confirmTitle }}
  </h1>

  <div mat-dialog-content class="message">
    {{ data.confirmMessage }}
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button class="cancel-button" [mat-dialog-close]="DeleteDataResult.CANCEL">
      {{ data.cancelButtonLabel }}
    </button>
    <button mat-raised-button color="primary" class="keep-data-button" [mat-dialog-close]="DeleteDataResult.KEEP_DATA">
      {{ data.keepDataButtonLabel }}
    </button>
    <button mat-raised-button color="warn" class="delete-data-button" [mat-dialog-close]="DeleteDataResult.DELETE_DATA">
      {{ data.deleteDataButtonLabel }}
    </button>
  </div>
</div>
