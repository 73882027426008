<div class="equipment">
  <bh-map-locations
    class="equipment__map-address"
    [locations]="[equipment] | mapMarkerConvertEquipmentView">
  </bh-map-locations>

  <div>
    <p class="equipment__address">
      <span>{{locationData.address}}</span>
      {{equipmentLastPosition?.currentAddress?.postalCode}} {{equipmentLastPosition?.currentAddress?.city}}
    </p>
    <div class="equipment__position" fxLayout="row" fxLayoutAlign="start strech" fxLayoutGap="10px">
      <div fxFlex="0 1 0%" fxFlexAlign="center">
        <fa-icon *ngIf="!isMatIcon" class="icon position__icon location-type"
                 [icon]="locationData.icon"
                 [matTooltip]="locationData.iconTooltip">
        </fa-icon>
      </div>
      <div>
        <mat-icon *ngIf="isMatIcon" class="icon position__icon location-type"
                  [fontSet]="locationData.icon"
                  [matTooltip]="locationData.iconTooltip">
        </mat-icon>
      </div>
      <div *ngIf="equipmentLastPosition" fxLayout="column" fxFlex fxLayoutAlign="center start" class="equipment__position__info">
          <div fxLayout="row" fxLayoutAlign="start center" data-osp-test="label-equipment-current-location">
            {{locationData.name}}
            <mat-icon *ngIf="equipment.currentUserHasAccessToCurrentEquipmentLocation && isNavigationToLocationAvailable"
                      class="icon position__icon location-jump"
                      [matTooltip]="locationLinkParameters.tooltip"
                      [routerLink]="['/sites', locationLinkParameters.objects, 'list', locationLinkParameters.id, 'general']">
              open_in_new
            </mat-icon>
          </div>
          <bh-project-number-and-cost-center *ngIf="locationData.number"
                                             [number]="locationData.number"
                                             [costCenter]="locationData.costCenter">
          </bh-project-number-and-cost-center>
          <div *ngIf="locationData.transferDate">
            {{'general.labels.transferedWhen'|translate}} {{locationData.transferDate | date:'dd.MM.yyyy'}}
          </div>
          <div *ngIf="locationData.telematicTransferDate">
            {{locationData.telematicTransferDate | date:'dd.MM.yyyy HH:mm:ss'}}
          </div>
      </div>
      <bh-map-jump-google fxLayout="column"
                          *ngIf="[equipment] | mapMarkerConvertEquipmentView | isValidLocations"
                          [lat]="this.equipment.location.lat" [lon]="this.equipment.location.lon">
      </bh-map-jump-google>
      <div *ngIf="!equipmentLastPosition" fxFlex fxLayoutAlign="start center">
        <span>{{locationData.cannotBeDisplayedMessage}}</span>
      </div>
    </div>
  </div>
</div>
