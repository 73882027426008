import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrCodeDialogParams } from './qr-code-dialog-params.interface';

@Component({
  selector: 'bh-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeDialogComponent implements OnInit {
  public value: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: QrCodeDialogParams,
    private dialogRef: MatDialogRef<QrCodeDialogComponent>
  ){}

  public ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.value = this.dialogData?.value;
  }
}
