import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ReportType } from '../../contract/report-type.enum';

@Pipe({
  name: 'reportTypeDisplay'
})
export class ReportTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    switch (value) {
      case ReportType.EQUIPMENTS:
        return this.translate('general.equipm.pl');
      case ReportType.PROJECTS:
        return this.translate('general.project.pl');

      default:
        return this.translate('shared.pipes.reportTypeDisplay.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
