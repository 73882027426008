import { Pipe, PipeTransform } from '@angular/core';
import { FileUtils } from 'app/shared/fileUtils';
import { ThumbnailSize } from '../enums/thumbnail-size.enum';

@Pipe({
  name: 'imageKeyFormat'
})
export class ImageKeyFormatPipe implements PipeTransform {

  public transform(imageKey: string, size?: ThumbnailSize): string {
    if (!imageKey) {
      return null;
    }

    let result = imageKey;
    result = this.encodeImageKey(result);
    result = this.addGlobalDirectory(result);
    result = this.addParams(result, size);
    return result;
  }

  private encodeImageKey(imageKey: string): string {
    return FileUtils.encodeDocumentKey(imageKey);
  }

  private addGlobalDirectory(imageKey: string): string {
    return `${this.isFromGlobalDirectory(imageKey) ? 'global/' : ''}${imageKey}`;
  }

  private addParams(imageKey: string, size: ThumbnailSize): string {
    return `${imageKey}${size ? `?thumbnail=${size}` : ''}`;
  }

  private isFromGlobalDirectory(imageKey: string): boolean {
    return FileUtils.globalDirectories.some(dir => imageKey.startsWith(dir));
  }

}
