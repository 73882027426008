import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganisationAddComponent } from './organisations/organisation-add/organisation-add.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { CustomerAddComponent } from './customer/customer-add/customer-add.component';
import { OrganisationListComponent } from './organisations/organisation-list/organisation-list.component';
import { CustomerEditComponent } from './customer/customer-edit/customer-edit.component';
import { PartnerListComponent } from './partner/partner-list/partner-list.component';
import { PartnerAddComponent } from './partner/partner-add/partner-add.component';
import { PartnerEditComponent } from './partner/partner-edit/partner-edit.component';
import { CanActivateChildAuthority } from '../../core/guards/can-activate-child-authority';
import { Authorities } from '../../shared/enums/authorities.enum';
import { OrganisationEditComponent } from './organisations/organisation-edit/organisation-edit.component';
import { CustomerModulesEditComponent } from './customer/customer-modules-edit/customer-modules-edit.component';
import { PrivilegedRole } from '../../shared/enums/privileged-roles.enum';
import { CanActivateRole } from '../../core/guards/can-activate-role';
import { CustomerRolesEditComponent } from './customer/customer-roles-edit/customer-roles-edit.component';
import { PartnerModulesEditComponent } from './partner/partner-modules-edit/partner-modules-edit.component';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';
import { SubCustomerListComponent } from './customer/sub-customer-list/sub-customer-list.component';


const organisationRoutes: Routes = [
  {
    path: 'organisations',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'list',
        component: OrganisationListComponent,
        data: {
          authorities: [Authorities.ORGANISATION_VIEW]
        }
      },
      {
        path: 'add',
        component: OrganisationAddComponent,
        data: {
          authorities: [Authorities.ORGANISATION_CREATE]
        }
      },
      {
        path: 'edit/:id',
        component: OrganisationEditComponent,
        data: {
          authorities: [Authorities.ORGANISATION_UPDATE]
        }
      }
    ]
  },
  {
    path: 'customers',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'sub-customers/list',
        component: SubCustomerListComponent,
        data: {
          authorities: [Authorities.SUB_CUSTOMER_VIEW]
        }
      },
      {
        path: 'list',
        component: CustomerListComponent,
        data: {
          authorities: [Authorities.CUSTOMER_VIEW]
        }
      },
      {
        path: 'add',
        component: CustomerAddComponent,
        data: {
          authorities: [Authorities.CUSTOMER_CREATE, Authorities.SUB_CUSTOMER_MANAGE]
        }
      },
      {
        path: 'edit/:id',
        component: CustomerEditComponent,
        data: {
          authorities: [Authorities.CUSTOMER_UPDATE, Authorities.SUB_CUSTOMER_MANAGE]
        }
      },
      {
        path: 'edit/:id/modules',
        component: CustomerModulesEditComponent,
        canActivate: [CanActivateRole],
        data: {
          authorities: [Authorities.CUSTOMER_UPDATE],
          roles: [PrivilegedRole.Superadmin, PrivilegedRole.Partneradmin]
        }
      },
      {
        path: 'edit/:id/roles',
        component: CustomerRolesEditComponent,
        canActivate: [CanActivateRole],
        data: {
          authorities: [Authorities.CUSTOMER_UPDATE],
          roles: [PrivilegedRole.Superadmin, PrivilegedRole.Partneradmin]
        }
      }
    ]
  },
  {
    path: 'partners',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: PartnerListComponent,
        data: {
          authorities: [Authorities.PARTNER_VIEW]
        }
      },
      {
        path: 'add',
        component: PartnerAddComponent,
        data: {
          authorities: [Authorities.PARTNER_CREATE]
        }
      },
      {
        path: 'edit/:id',
        component: PartnerEditComponent,
        data: {
          authorities: [Authorities.PARTNER_UPDATE]
        }
      },
      {
        path: 'edit/:id/modules',
        component: PartnerModulesEditComponent,
        canActivate: [CanActivateRole],
        data: {
          authorities: [Authorities.PARTNER_UPDATE],
          roles: [PrivilegedRole.Superadmin]
        }
      }
    ]
  }
];

export const organisationRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(organisationRoutes);
