import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from 'app/shared/services/language.service';
import { tap, map } from 'rxjs/operators';
import { getLanguageFromUrl } from 'app/shared/translation-utils';
import { LanguageMapper, Languages } from 'app/shared/enums/languages.enum';
import { environment } from 'environments/environment';

@Injectable()
export class CanActivateLanguage implements CanActivate {
  constructor(private router: Router, private languageService: LanguageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const { language, urlTail } = getLanguageFromUrl(state.url);
    const languageKey = LanguageMapper[language];
    if (environment.production && languageKey === LanguageMapper.dev) {
      this.router.navigate([urlTail]);
      return false;
    }
    return this.languageService.use(Languages[languageKey]).pipe(
      tap(() => this.router.navigate([urlTail])),
      map(() => false)
    )
  }
}
