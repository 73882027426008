import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './users/user-list/user-list.component';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { RoleAddComponent } from './roles/role-add/role-add.component';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { UserAddSuperadminComponent } from './users/user-add-superadmin/user-add-superadmin.component';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { UserAddPartneradminComponent } from './users/user-add-partneradmin/user-add-partneradmin.component';
import { UserAddFleetadminComponent } from './users/user-add-fleetadmin/user-add-fleetadmin.component';
import { UserEditAdminComponent } from './users/user-edit-admin/user-edit-admin.component';
import { CanActivateChildAuthority } from '../../core/guards/can-activate-child-authority';
import { Authorities } from '../../shared/enums/authorities.enum';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';

const userroleRoutes: Routes = [
  {
    path: 'users',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: UserListComponent,
        data: {
          authorities: [
            Authorities.SUPERADMIN_VIEW,
            Authorities.PARTNER_VIEW,
            Authorities.FLEETADMIN_VIEW,
            Authorities.USER_VIEW
          ]
        }
      },
      {
        path: 'add/superadmin',
        component: UserAddSuperadminComponent,
        data: {
          authorities: [Authorities.SUPERADMIN_CREATE]
        }
      },
      {
        path: 'add/partneradmin',
        component: UserAddPartneradminComponent,
        data: {
          authorities: [Authorities.PARTNERADMIN_CREATE]
        }
      },
      {
        path: 'add/fleetadmin',
        component: UserAddFleetadminComponent,
        data: {
          authorities: [Authorities.FLEETADMIN_CREATE]
        }
      },
      {
        path: 'edit/admin/:id',
        component: UserEditAdminComponent,
        data: {
          authorities: [
            Authorities.SUPERADMIN_UPDATE,
            Authorities.PARTNER_UPDATE,
            Authorities.FLEETADMIN_UPDATE
          ]
        }
      },
      {
        path: 'settings',
        component: UserSettingsComponent,
        data: {
          authorities: [] // none required
        }
      }
    ]
  },
  {
    path: 'roles',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: RoleListComponent,
        data: {
          authorities: [Authorities.ROLE_VIEW]
        }
      },
      {
        path: 'add',
        component: RoleAddComponent,
        data: {
          authorities: [Authorities.ROLE_CREATE]
        }
      },
      {
        path: 'clone/:id',
        component: RoleEditComponent,
        data: {
          authorities: [Authorities.ROLE_CREATE]
        }
      },
      {
        path: 'edit/:id',
        component: RoleEditComponent,
        data: {
          authorities: [Authorities.ROLE_UPDATE]
        }
      }
    ]
  }
];

export const userroleRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(userroleRoutes);
