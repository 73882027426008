<div class="maintenance" fxFlexFill
     fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">

  <div fxLayout="row" fxLayoutAlign="space-between center" class="task-header">
    <bh-title
      [text]="('general.inspection.pl'|translate) + ' '
      + ('general.and'|translate) + ' '
      + ('general.maintenance.pl'|translate)">
    </bh-title>
    <mat-icon class="task-create-assign-trigger" [matTooltip]="'modules.equipment.task.createAndAssignTask'|translate"
              *ngIf="hasAuthority(authorities.MAINTENANCE_TASK_MANAGE)"
              [matMenuTriggerFor]="taskCreateAssign">add_box
    </mat-icon>
    <mat-menu #taskCreateAssign="matMenu" class="task-create-assign-panel">
      <span [matTooltip]="'modules.equipment.task.inactiveEquipmentCannotAssigned'|translate"
            [matTooltipDisabled]="isActiveEquipment()">
        <button mat-menu-item (click)="createRule()" [disabled]="!isActiveEquipment()">
          <mat-icon>assignment_turned_in</mat-icon>
          <span>{{'general.new'|translate}} {{'general.maintenance.s'|translate}} / {{'general.inspection.s'|translate}}</span>
        </button>
          <button mat-menu-item (click)="assignRules()" [disabled]="!isActiveEquipment()">
          <mat-icon>refresh</mat-icon>
          <span>{{'modules.equipment.task.assignExistingTask'|translate}}</span>
        </button>
      </span>
    </mat-menu>
  </div>

  <form [formGroup]="reminderForm" novalidate class="reminder-form"
        fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="20px">

    <input class="reminder-name"
           [placeholder]="'modules.equipment.task.addReminder'|translate|uppercase"
           fxFlex
           matInput
           bhRemoveWhitespaces
           [bhHasAsyncValidationError]="['reminderNameInUse']"
           (hasErrors)="hasAsyncErrors($event)"
           formControlName="reminderName"
           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
           [readonly]="!allowedToCreateReminder">

    <div *ngIf="isReminderNameInUse"
         class="reminder-error"
         fxLayout="row"
         fxLayoutAlign="center center"
         data-osp-test="reminder-in-use-message">
      {{'modules.equipment.task.nameAlreadyTaken'|translate}}
    </div>

    <div *ngIf="!isReminderNameInUse" class="reminder-date" fxLayout="row" fxLayoutAlign="center center">
      <input matInput
               placeholder=""
               formControlName="reminderDate" autocomplete="off"
               [min]="today"
               [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <div class="reminder-create" fxLayout="row" fxLayoutAlign="center center">
      <button mat-button
              bhTimeoutSaveButton
              mat-raised-button
              color="primary"
              (throttledClick)="saveReminder()"
              [disabled]="!reminderForm.valid || !allowedToCreateReminder">
        {{'modules.equipment.task.addReminder'|translate}}
      </button>
    </div>
  </form>

  <mat-tab-group #detailTabGroup
    mat-stretch-tabs
    animationDuration="0ms"
    class="task-tabs"
    (selectedTabChange)="updateTab($event)">
    <mat-tab>
      <ng-template mat-tab-label>{{ 'general.current'|translate }}</ng-template>
      <bh-task-list *ngIf="!(isTaskLoading | async); else spinner"
        fxFlex
        hideEquipmentColumn="true"
        [tasks]="tasks | async"
        [pageable]="maintenanceTaskStore"
        (reminderComplete)="completeReminder($event)"
        (taskComplete)="completeTask($event)"
        (taskDelete)="deleteReminder($event)"
        (ruleUnassign)="unassignRule($event)"
        (taskDetails)="navigateTaskDetails($event)">
      </bh-task-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>{{ 'general.history'|translate }}</ng-template>
      <bh-completed-task-list *ngIf="!(isCompletedTaskLoading | async); else spinner"
        fxFlex
        [completedTasks]="completedTasks | async"
        [pageable]="maintenanceTaskCompletedStore"
        (editTask)="editCompletedTask($event)"
        (deleteTask)="deleteCompletedTask($event)"
        (navigateDetails)="navigateTaskCompletedDetails($event)">
      </bh-completed-task-list>
    </mat-tab>

    <ng-template #spinner>
      <div class="spinner-container">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-template>
  </mat-tab-group>

</div>
