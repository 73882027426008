<bh-title [text]="'general.generalData'|translate"></bh-title>
<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
  <div fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.org.s'|translate|uppercase}}</mat-label>
        <input matInput
               readonly
               [value]="stock?.organisationName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'modules.disposition.stockDetail.stockType'|translate|uppercase}}</mat-label>
        <input matInput
               [value]="stock?.stockType | stockType"
               readonly>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
      <mat-form-field>
        <mat-label>{{'general.costCenter'|translate|uppercase}}</mat-label>
        <input matInput
               [value]="stock?.stockCostCenter | defaultValue: '-'"
               readonly>
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <mat-label>{{'general.scanCode'|translate|uppercase}}</mat-label>
        <input matInput
               readonly
               [value]="stock?.scanCode">
        <mat-icon
          matSuffix
          *ngIf="hasAuthority(authorities.STOCK_MANAGE_SCAN_CODE)"
          [matTooltip]="'modules.disposition.stockDetail.editScanCode'|translate"
          (click)="editScanCode()"
          data-osp-test="button-edit-stock-scan-code">
          mode_edit
        </mat-icon>
      </mat-form-field>
    </div>

    <div class="subsection" *ngIf="isLocationType(stock.stockType)">
      <bh-subtitle [text]="'general.location.s'|translate"></bh-subtitle>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-form-field fxFlex="25">
          <mat-label>{{'general.address.zipcode'|translate|uppercase}}</mat-label>
          <input matInput
                 value="{{ stock?.stockAddress?.postalCode | defaultValue: '-' }}"
                 readonly>
        </mat-form-field>

        <mat-form-field fxFlex="25">
          <mat-label>{{'general.address.city'|translate|uppercase}}</mat-label>
          <input matInput
                 value="{{ stock?.stockAddress?.city | defaultValue: '-' }}"
                 readonly>
        </mat-form-field>

        <mat-form-field fxFlex="25">
          <mat-label>{{'general.address.street'|translate|uppercase}}</mat-label>
          <input matInput
                 value="{{ stock?.stockAddress?.street | defaultValue: '-' }}"
                 readonly>
        </mat-form-field>

        <mat-form-field fxFlex="25">
          <mat-label>{{'general.address.number'|translate|uppercase}}</mat-label>
          <input matInput
                 value="{{ stock?.stockAddress?.streetNumber | defaultValue: '-' }}"
                 readonly>
        </mat-form-field>
      </div>
    </div>

    <div class="subsection" *ngIf="stock?.chargeDate">
      <bh-subtitle [text]="'modules.disposition.base.billing'|translate"></bh-subtitle>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-form-field fxFlex="25">
          <mat-label>{{'general.date'|translate}}</mat-label>
          <input matInput value="{{ stock?.chargeDate | date: 'dd.MM.yyyy' | defaultValue: '-' }}" readonly>
        </mat-form-field>
      </div>
    </div>

    <div class="subsection" fxLayout="column">
      <bh-subtitle [text]="'general.responsible'|translate"></bh-subtitle>
      <bh-stock-employee-assignee-cards></bh-stock-employee-assignee-cards>
    </div>
  </div>

  <div fxLayout="column" fxFlex="40">
    <bh-map-locations
      class="map-stock"
      [locations]="[stock] | mapMarkerConvertStockView">
    </bh-map-locations>
    <div class="stock-map-jump">
      <bh-map-jump-google
        *ngIf="[stock] | mapMarkerConvertStockView | isValidLocations"
        [lat]="this.stock.location.lat" [lon]="this.stock.location.lon">
      </bh-map-jump-google>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="!stock">
  {{'general.labels.notFound'|translate:{ value: 'general.stock.s'|translate } }}
</div>
