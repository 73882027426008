import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewGeofence } from '../../disposition/contract/view-geofence.interface';
import { ProjectsService } from '../../disposition/shared/project.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class ProjectGeofencesDataSource {

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _geofences: BehaviorSubject<ViewGeofence[]> = new BehaviorSubject([]);

  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly geofences: Observable<ViewGeofence[]> = this._geofences.asObservable();

  constructor(private projectsService: ProjectsService) {
  }

  getProjectGeofences(): void {
    this._loading.next(true);
    this.projectsService
    .getAllGeofences()
    .pipe(finalize(() => this._loading.next(false)))
    .subscribe((res: ViewGeofence[]) => this._geofences.next(res));
  }
}
