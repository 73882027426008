import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { connectorRouting } from './connector.routing';
import { ConnectorListComponent } from './connectors/connector-list/connector-list.component';
import { GenericConnectorListComponent } from './connectors/connector-list/generic-connector-list/generic-connector-list.component';
import { ConnectorService } from './shared/connector.service';
import { ConnectorAddComponent } from './connectors/connector-add/connector-add.component';
import { ConnectorEditComponent } from './connectors/connector-edit/connector-edit.component';
import { ShowHideComponent } from '../../shared/components/show-hide-component/show-hide.component';
import { SharedModule } from '../../shared/shared.module';
import { PublicApiConnectorComponent } from './public-api-connector/public-api-connector.component';
import { BhSecretTextModule } from '../osp-ui/components/bh-secret-text/bh-secret-text.module';
import {
  CreatePublicApiConnectorDialogComponent
} from './public-api-connector/create-public-api-connector-dialog/create-public-api-connector-dialog.component';
import { BhButtonModule } from '../osp-ui/components/bh-button/bh-button.module';
import { BhIconModule } from '../osp-ui/components/bh-icon/bh-icon.module';
import { BhInputModule } from '../osp-ui/components/bh-input/bh-input.module';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    connectorRouting,
    TranslateModule,
    SharedModule,
    BhSecretTextModule,
    BhButtonModule,
    BhIconModule,
    BhInputModule,
  ],
  declarations: [
    ConnectorListComponent,
    ConnectorAddComponent,
    ConnectorEditComponent,
    ShowHideComponent,
    GenericConnectorListComponent,
    PublicApiConnectorComponent,
    CreatePublicApiConnectorDialogComponent,
  ],
  providers: [
    DatePipe,
    ConnectorService
  ]
})
export class ConnectorModule {
}
