import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { speedDialAnimations } from './speed-dial-animations';
import { Button, ButtonEvent, ButtonType } from './button-types';
import { BehaviorSubject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { faAddressCard, faWarehouse } from '@fortawesome/pro-duotone-svg-icons';

@UntilDestroy()
@Component({
  selector: 'bh-speed-dial',
  templateUrl: './speed-dial.component.html',
  styleUrls: ['./speed-dial.component.scss'],
  animations: speedDialAnimations
})
export class SpeedDialComponent implements OnInit, OnDestroy {
  public toggle = false;
  public buttons: Button[] = [];

  @Input()
  public initialStates: ButtonEvent[];

  @Input()
  public showRefreshButton = false;

  @Input()
  public showSettingsButton = true;

  @Output()
  private readonly buttonToggled: EventEmitter<ButtonEvent> = new EventEmitter<ButtonEvent>();

  @Output()
  public readonly buttonRefresh: EventEmitter<any> = new EventEmitter<any>();

  private buttonConfigs: Button[] = [
    {
      fontSet: true,
      icon: 'icon-location_outline',
      tooltip: this.translate('shared.speedDial.showOrganizations'),
      type: ButtonType.ORGANISATION,
      value: new BehaviorSubject(true)
    },
    {
      fontSet: true,
      icon: 'icon-projects02_white_outline',
      tooltip: this.translate('shared.speedDial.showProjects'),
      type: ButtonType.PROJECT,
      value: new BehaviorSubject(true)
    },
    {
      fontSet: false,
      icon: 'room',
      tooltip: this.translate('shared.speedDial.showEquipment'),
      type: ButtonType.EQUIPMENT,
      value: new BehaviorSubject(true)
    },
    {
      faIcon: faAddressCard,
      tooltip: this.translate('shared.speedDial.showContacts'),
      type: ButtonType.CONTACT,
      value: new BehaviorSubject(false)
    },
    {
      faIcon: faWarehouse,
      tooltip: this.translate('shared.speedDial.showStocks'),
      type: ButtonType.STOCK,
      value: new BehaviorSubject(false)
    },
    {
      fontSet: true,
      icon: 'icon-geofences_outline',
      tooltip: this.translate('shared.speedDial.showGeofences'),
      type: ButtonType.GEOFENCE,
      value: new BehaviorSubject(true)
    },
    {
      fontSet: false,
      icon: 'settings',
      static: true,
      tooltip: this.translate('general.settings'),
      type: ButtonType.SETTINGS,
      value: new BehaviorSubject(false)
    }
  ];

  private initialized = false;

  constructor(private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    if (this.initialStates) {
      this.initialStates.forEach((state: ButtonEvent) => {
        const button: Button = this.buttonConfigs.find(btn => btn.type === state.type);
        if (button) {
          if (state.hide) {
            this.buttonConfigs = this.buttonConfigs.filter(btn => btn.type !== state.type);
            return;
          }
          button.value.next(state.value);
        }
      });
    }
    this.buttonConfigs.forEach((button: Button) => {
      button.value.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
        if (!this.initialized) {
          return;
        }
        this.buttonToggled.emit({type: button.type, value: value});
      });
    });
    this.initialized = true;
  }

  public ngOnDestroy(): void {
  }

  public onToggleFab(): void {
    this.buttons = this.toggle ? [] : this.buttonConfigs;
    this.toggle = !this.toggle;
  }

  public onRefreshClick(): void {
    this.buttonRefresh.emit()
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
