<div class="assign-equipment-project-dialog">

  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">

    <form [formGroup]="assignToProjectForm" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
      <mat-horizontal-stepper #assignToProjectStepper formArrayName="formArray" labelPosition="bottom"
                              fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch"
                              (selectionChange)="startAnimation()" (animationDone)="endAnimation()">

        <mat-step formGroupName="0" [stepControl]="projectSubForm" *ngIf="!outsideAssignmentData; else confirmation">
          <ng-template matStepLabel>{{'general.project.s'|translate}}</ng-template>

          <div class="assign-equipment-project-stepper-content"
               fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div fxFlex="20">
              <mat-form-field>
                <mat-select [placeholder]="'general.project.s'|translate" formControlName="projectId" disableOptionCentering="true" data-osp-test="select-project">
                  <mat-option class="mat-select-search">
                    <ngx-mat-select-search [formControl]="filterControl"
                                           [placeholderLabel]="'general.labels.search'|translate:{value: 'general.project.s'|translate}"
                                           [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{value: 'general.project.s'|translate}">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let project of filteredProjects | async" [value]="project.projectId">
                    {{project.projectName}} /
                    {{formatAddress(project.projectAddress) | defaultValue: '-'}} /
                    {{project | formatDispositionProjectDateRange}} /
                    #{{project.projectNumber}} /
                    {{project.projectCostCenter | defaultValue: '-'}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="80" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="50px">
              <div fxFlex="45" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                <mat-form-field>
                  <mat-label>{{'general.projectName'|translate}}</mat-label>
                  <input matInput disabled readonly
                         [value]="project?.projectName  | defaultValue: '-'">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{'general.projectNumber'|translate}}</mat-label>
                  <input matInput disabled readonly
                         [value]="project?.projectNumber | defaultValue: '-'">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{'general.costCenter'|translate}}</mat-label>
                  <input matInput disabled readonly
                         [value]="project?.projectCostCenter | defaultValue: '-'">
                </mat-form-field>
              </div>
              <div fxFlex="45" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                <mat-form-field>
                  <mat-label>{{'general.period'|translate}}</mat-label>
                  <input matInput disabled readonly
                         [value]="project | formatDispositionProjectDateRange | defaultValue: '-'">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{'general.address.address'|translate}}</mat-label>
                  <input matInput disabled readonly
                         [value]="formatAddress(project?.projectAddress) | defaultValue: '-'">
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{'general.org.s'|translate}}</mat-label>
                  <input matInput disabled readonly
                         [value]="project?.organisation?.name  | defaultValue: '-'">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <div fxLayoutAlign="start center" class="save-warning">
              {{saveWarning}}
            </div>
            <button fxLayoutAlign="end center"
                    mat-raised-button
                    bhTimeoutSaveButton
                    color="primary"
                    class="save-button"
                    (throttledClick)="save()"
                    [disabled]="!isValid()" >{{'general.buttons.save' |translate}}
            </button>
            <button fxLayoutAlign="end center" mat-raised-button matStepperNext
                    [disabled]="isContinueToDispositionDisabled()">{{'general.buttons.continue' |translate}}</button>
          </div>
        </mat-step>

        <ng-template #confirmation>
          <mat-step>
            <ng-template matStepLabel>
              {{'modules.equipment.equipmentAssignToProject.confirmationStepTitle'|translate}}
            </ng-template>
            <div class="assign-equipment-project-stepper-content"
                 fxLayout="column"
                 fxLayoutAlign="start stretch"
                 fxLayoutGap="25px">
               <bh-disposition-assign-confirmation-message [resolveReasons]="outsideAssignmentData.resolveReasons"
                                                           [messageResolver]="messageResolver">
               </bh-disposition-assign-confirmation-message>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
              <button mat-raised-button (click)="close()">{{'general.buttons.cancel'|translate}}</button>
              <button mat-raised-button color="primary" matStepperNext>{{'general.buttons.continue'|translate}}</button>
            </div>
          </mat-step>
        </ng-template>

        <mat-step formGroupName="1" [stepControl]="dispositionsSubForm">
          <ng-template matStepLabel>{{'general.disposition.pl'|translate}}</ng-template>

          <div class="assign-equipment-project-stepper-content" fxLayout="column" fxLayoutAlign="start stretch">

            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="50px">
              <div fxFlex="30">
                <h4>{{'general.dispositionPeriod'|translate}}</h4>
                <bh-date-range-select
                  [startDateControl]="startDateControl"
                  [endDateControl]="endDateControl"
                  (onDateChange)="updateAllConflicts()">
                </bh-date-range-select>
              </div>
              <div class="assign-equipment-project-stepper-project-daterange" fxFlex="30">
                <h4>{{'general.projectPeriod'|translate}}</h4>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px" *ngIf="project">
                  <mat-form-field>
                    <mat-label>{{'general.startDate'|translate}}</mat-label>
                    <input matInput disabled readonly
                           [value]="project | formatProjectStartDate">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.endDate'|translate}}</mat-label>
                    <input matInput disabled readonly
                           [value]="project | formatProjectEndDate">
                  </mat-form-field>
                </div>
                <div class="project-missing-warning" *ngIf="!project">
                  {{'general.labels.selectProjectError'|translate}}
                </div>
              </div>
            </div>

            <div class="assign-equipment-project-stepper-dispositions-header" fxLayout="row" fxLayoutAlign="start start">
              <div fxFlex="40">{{'general.equipm.s'|translate}}</div>
              <div fxFlex="30">{{'general.conflict.pl'|translate}}</div>
              <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">{{'general.labels.solveConflicts'|translate}}</div>
              <div fxFlex="10" fxLayout="row" fxLayoutAlign="center center">{{'general.buttons.remove'|translate}}</div>
            </div>

            <div class="assign-equipment-project-stepper-dispositions" fxFlex>
              <div *ngFor="let equipmentDisposition of equipmentDispositions">
                <bh-disposition-form-item *ngIf = "!equipmentDisposition.get('isSubEquipment').value"
                                          [equipmentDisposition]="equipmentDisposition"
                                          [removable]="isEquipmentRemovable()"
                                          [startDate]="startDateControl"
                                          (onRemove)="removeDisposition(equipmentDisposition)"
                                          (onOverwriteChange)="updateConflicts($event)">
                </bh-disposition-form-item>
                <div *ngFor="let subEquipmentDisposition of equipmentDispositions">
                  <bh-disposition-form-item *ngIf="subEquipmentDisposition.get('containerId').value === equipmentDisposition.get('equipmentId').value"
                                            [equipmentDisposition]="subEquipmentDisposition"
                                            [removable]="true"
                                            [startDate]="startDateControl"
                                            (onRemove)="removeDisposition(subEquipmentDisposition)"
                                            (onOverwriteChange)="updateConflicts($event)">
                  </bh-disposition-form-item>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <button  fxLayoutAlign="start center" mat-raised-button matStepperPrevious>{{'general.buttons.back' |translate}}</button>
            <div fxLayoutAlign="start center" class="save-warning">
              {{saveWarning}}
            </div>
            <button fxLayoutAlign="end center"
                    mat-raised-button
                    bhTimeoutSaveButton
                    (throttledClick)="save()"
                    color="primary"
                    class="save-button"
                    [disabled]="!isValid()">{{'general.buttons.save' |translate}}
            </button>
            <button fxLayoutAlign="end center" mat-raised-button matStepperNext
                    [disabled]="isContinueToCommentDisabled()">{{'general.buttons.continue' |translate}}</button>
          </div>
        </mat-step>

        <mat-step formGroupName="2" [stepControl]="commentSubForm" optional>
          <ng-template matStepLabel>{{'general.comment.s'|translate}}</ng-template>

          <div class="assign-equipment-project-stepper-content" fxLayout="column" fxLayoutAlign="start stretch">
            <textarea matInput [maxlength]="fieldLimit.LONG_TEXT" fxFlex="80" formControlName="comment"></textarea>
          </div>
          <div fxLayout="row" fxLayoutGap="15px">
            <button fxLayoutAlign="start center" mat-raised-button matStepperPrevious>{{'general.buttons.back' |translate}}</button>
            <div fxLayoutAlign="start center" class="save-warning">
              {{saveWarning}}
            </div>
            <button fxLayoutAlign="end center"
                    mat-raised-button
                    bhTimeoutSaveButton
                    color="primary"
                    class="save-button"
                    (throttledClick)="save()"
                    [disabled]="!isValid()" >{{'general.buttons.save' |translate}}
            </button>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
    </form>

  </mat-dialog-content>
</div>
