<bh-gallery-arrows *ngIf="arrows"
  (onPrevClick)="showPrev()"
  (onNextClick)="showNext()"
  [prevDisabled]="!canShowPrev()"
  [nextDisabled]="!canShowNext()"
  [arrowPrevIcon]="arrowPrevIcon"
  [arrowNextIcon]="arrowNextIcon">
</bh-gallery-arrows>

<div class="gallery-preview-top">
  <div class="gallery-preview-icons">
    <bh-gallery-action *ngIf="zoom" [icon]="zoomOutIcon" [disabled]="!canZoomOut()" (onClick)="zoomOut()"></bh-gallery-action>
    <bh-gallery-action *ngIf="zoom" [icon]="zoomInIcon" [disabled]="!canZoomIn()" (onClick)="zoomIn()"></bh-gallery-action>
    <bh-gallery-action *ngIf="rotate" [icon]="rotateLeftIcon" (onClick)="rotateLeft()"></bh-gallery-action>
    <bh-gallery-action *ngIf="rotate" [icon]="rotateRightIcon" (onClick)="rotateRight()"></bh-gallery-action>
    <bh-gallery-action *ngIf="fullscreen" [icon]="fullscreenIcon" (onClick)="manageFullscreen()"></bh-gallery-action>
    <bh-gallery-action [icon]="closeIcon" (onClick)="close()"></bh-gallery-action>
  </div>
</div>

<div class="spinner-wrapper gallery-center" [class.gallery-active]="showSpinner">
  <mat-spinner [diameter]="100" [strokeWidth]="10"></mat-spinner>
</div>

<div class="gallery-preview-wrapper"
  (click)="closeOnClick && close()"
  (mouseup)="mouseUpHandler()"
  (mousemove)="mouseMoveHandler($event)"
  (touchend)="mouseUpHandler()"
  (touchmove)="mouseMoveHandler($event)">
  <div class="gallery-preview-img-wrapper">
    <bh-image
      class="gallery-preview-img gallery-center"
      (click)="$event.stopPropagation()"
      (mouseenter)="imageMouseEnter()"
      (mouseleave)="imageMouseLeave()"
      (mousedown)="mouseDownHandler($event)"
      (touchstart)="mouseDownHandler($event)"
      [class.gallery-active]="!loading"
      [class.animation]="animation"
      [class.gallery-grab]="canDragOnZoom()"
      [style.transform]="getTransform()"
      [style.left]="positionLeft + 'px'"
      [style.top]="positionTop + 'px'"
      [src]="src"
      (loading)="onLoading($event)"
      data-osp-test="gallery-preview-img">
    </bh-image>
    <bh-gallery-bullets *ngIf="bullets" [count]="images.length" [active]="index" (onChange)="showAtIndex($event)"></bh-gallery-bullets>
  </div>
</div>
