import { LanguageService } from 'app/shared/services/language.service';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { OnInit, Directive } from '@angular/core';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProjectDataSource } from '../../../shared/project.datasource';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomerLabel } from '../../../../../shared/contract/customer-label.interface';
import { RouterHistory } from '../../../../../shared/router-history';
import { ProjectSubscriptionsService } from '../../../shared/project-subscriptions.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';

@Directive()
export abstract class BaseProjectListComponent extends GuardedNavigableInputComponent implements OnInit {
  public searchForm: UntypedFormGroup;
  public filterForm: UntypedFormGroup;
  public labels: CustomerLabel[] = [];
  public labelsSearch: string[] = [];
  public routeButton: ButtonInfo = {
    route: 'projects/map',
    tooltip: this.translate('general.map.s'),
    iconName: 'map'
  };

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService,
              protected subscriptions: ProjectSubscriptionsService,
  ) {

    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.buildFilterForm();
    this.getCustomerLabels();
    this.setLabelsSearch();
    this.loadProjects();
  }

  public onSearchFormType(): void {
    if (this.subscriptions.searchProjectsFormSubscription === null) {
      this.subscriptions.searchProjectsFormSubscription = this.searchForm.get('terms').valueChanges
        .pipe(
          debounceTime(environment.DELAY_SHORTEST),
          distinctUntilChanged(),
          untilDestroyed(this))
        .subscribe(searchTerm => this.updateOnSearch(searchTerm));
      this.searchForm.patchValue({terms: this.projectStore.searchTerms}, {emitEvent: false});
    }
  }

  protected updateOnSearch(searchTerm: string): void {
    this.projectStore.searchTerms = searchTerm;
    this.projectStore.updateListing(0);
  }

  public selectLabels(labelNames: string[]): void {
    this.labelsSearch = labelNames;
    this.projectStore.searchLabels = this.labelsSearch;
  }

  public setLabelsSearch(): void {
    this.labelsSearch = this.projectStore.searchLabels;
    this.filterForm.patchValue({selectedLabels: this.labelsSearch});
  }

  private buildFilterForm(): void {
    this.filterForm = new UntypedFormGroup({
      selectedLabels: new UntypedFormControl(this.projectStore.searchLabels),
    });

    this.setLabelsSearch();
  }

  private getCustomerLabels(): void {
    this.projectStore.getCustomerLabels(true)
      .subscribe((labels: CustomerLabel[]) => {
        this.labels = labels;
        // in case the label was removed completely
        this.setLabelsSearch();
      });
  }

  private loadProjects(): void {
    if (this.projectStore.project() || this.route.snapshot.firstChild) {
      this.projectStore.updateSearchResults(false);
    } else {
      this.projectStore.updateSearchResults(true);
    }
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  get termsValue(): string {
    return this.searchForm.get('terms').value;
  }
}
