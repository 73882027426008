import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { BaseProjectViewCommentsComponent } from '../base/base-project-view-comments.component';
import { MatDialog } from '@angular/material/dialog';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-project-view-comments',
  templateUrl: './mobile-project-view-comments.component.html',
  styleUrls: ['./mobile-project-view-comments.component.scss']
})
export class MobileProjectViewCommentsComponent extends BaseProjectViewCommentsComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService) {
    super(authService, router, route, dialog, routerHistory, projectStore, languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
