import { AfterViewInit, Directive, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ButtonBase } from './button-base.class';
import { BehaviorSubject } from 'rxjs';

@Directive()
export class ButtonLabeledBase extends ButtonBase implements AfterViewInit, OnDestroy {
  @ViewChild('tmplLabel', { static: true }) labelContainer: ElementRef;

  public readonly isLabelContainerEmpty = new BehaviorSubject<boolean>(true);

  private contentObserver: MutationObserver;

  public ngAfterViewInit(): void {
    setTimeout(() => this.initListenerContentObserver());
  }

  public ngOnDestroy(): void {
    if (this.contentObserver) {
      this.contentObserver.disconnect();
    }
  }

  private initListenerContentObserver(): void {
    this.contentObserver = new MutationObserver(() => this.checkLabelContainer());
    this.contentObserver.observe(
      this.labelContainer.nativeElement,
      { characterData: true, subtree: true }
    );
    this.checkLabelContainer();
  }

  private checkLabelContainer(): void {
    this.isLabelContainerEmpty.next(!(this.labelContainer.nativeElement.innerHTML.trim()));
  }

}
