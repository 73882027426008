<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <!--TODO Uncomment once all the functionality is finished-->
      <!--<div class="search-bar-select-container">-->
      <!--<select [(ngModel)]="selectedRole" name="role">-->
      <!--<option *ngFor="let role of roles" [ngValue]="role.value">-->
      <!--{{role.viewValue}}-->
      <!--</option>-->
      <!--</select>-->
      <!--</div>-->

      <div class="search-bar-input-container" fxFlex>
        <form class="search-bar-input" fxLayout="row" [formGroup]="searchForm"
              novalidate>
          <input fxFlex matInput formControlName="terms" data-osp-test="input-search">
          <button mat-button
            (click)="onSearchFormClick()"
            [matTooltip]="'general.search'|translate"
            fxFlex="40px">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-button
            (click)="reset()"
            [matTooltip]="'general.labels.resetSearchFilters'|translate"
            fxFlex="40px">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </div>

      <button mat-button class="search-bar-button-right" fxFlex="50px"
              [matTooltip]="'modules.userrole.base.createUser'|translate"
              (click)="addUser()"
              *ngIf="!hasAuthority(authorities.SUPERADMIN_CREATE)
              && !hasAuthority(authorities.PARTNERADMIN_CREATE)
              && hasAuthority(authorities.USER_CREATE)"
              data-osp-test="button-create-user">
        <mat-icon>person_add</mat-icon>
      </button>

      <button mat-button class="search-bar-button-right" fxFlex="50px"
              [matTooltip]="'modules.userrole.userList.createFleetAdmin'|translate"
              (click)="navigateToAdd('add/fleetadmin', {customerId: getUserCustomerId()})"
              *ngIf="!hasAuthority(authorities.SUPERADMIN_CREATE)
              && !hasAuthority(authorities.PARTNERADMIN_CREATE)
              && hasAuthority(authorities.FLEETADMIN_CREATE)">
        <mat-icon>account_circle</mat-icon>
      </button>

      <button mat-button class="search-bar-button-right" fxFlex="50px"
              [matTooltip]="'modules.userrole.userList.createPartnerAdmin'|translate"
              (click)="navigateToAdd('add/partneradmin', {partnerId: getUserPartnerId()})"
              *ngIf="!hasAuthority(authorities.SUPERADMIN_CREATE) &&
              hasAuthority(authorities.PARTNERADMIN_CREATE)">
        <mat-icon>account_circle</mat-icon>
      </button>

      <button mat-button class="search-bar-button-right" fxFlex="50px"
              [matTooltip]="'modules.userrole.userList.createSuperAdmin'|translate"
              (click)="navigateToAdd('add/superadmin')"
              *ngIf="hasAuthority(authorities.SUPERADMIN_CREATE)">
        <mat-icon>supervisor_account</mat-icon>
      </button>
      <button mat-button class="search-bar-button-right" fxFlex="50px"
              [matTooltip]="'general.buttons.sync'|translate"
              (click)="onOpenSyncDialog()"
              *ngIf="hasAuthority(authorities.SUPERADMIN_CREATE)">
        <mat-icon>sync</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div fxFlex="60px" class="list-paginator">
        <mat-paginator #paginator
                       [length]="usersStore.length | async"
                       [pageIndex]="usersStore.pagination.index"
                       [pageSize]="usersStore.pagination.size"
                       [pageSizeOptions]="[5, 25, 50, 100]"
                       (page)="onPaginateChange($event)">
        </mat-paginator>
      </div>

      <div class="data-table-wrapper" fxFlex>
        <mat-table #table [dataSource]="usersStore" fxFlex="1 1 100%" class="data-table">
          <!-- //TODO currently, not multiple editing
          <ng-container cdkColumnDef="select">
            <mat-header-cell *cdkHeaderCellDef> <mat-checkbox></mat-checkbox> </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> <mat-checkbox></mat-checkbox> </mat-cell>
          </ng-container>-->

          <ng-container cdkColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef fxFlex="25"> {{'general.name.s'|translate}}
              , {{'general.firstName'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="user-list-column" fxFlex="25">
              <p class="user-list-column__content">
                <span data-osp-test="cell-user-name">{{resolveFullName(row)}}</span><br>
                <span class="user-email" data-osp-test="cell-user-email">{{row.email}}</span>
              </p>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerName" *ngIf="showCustomerNameColumn()">
            <mat-header-cell *cdkHeaderCellDef
                             fxFlex="25"> {{'modules.userrole.userList.customerName'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="25"> {{row.customerName}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="organisation">
            <mat-header-cell *cdkHeaderCellDef
                             fxFlex="35">{{'modules.userrole.userList.orgUnit'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="35" class="user-list-multiline" data-osp-test="cell-user-organisations">
                <span class="user-list-multiline" *ngFor="let item of row.organisations" data-osp-test="item-user-organisation">
                  - {{item.name}}
                </span>
                <span *ngIf="row.numberOfAssignedOrganisations > row.organisations?.length"
                  class="user-list-multiline cell-list-bottom-hint">
                  ({{'modules.userrole.userList.hintMoreOrganisations'|translate|lowercase}})
                </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="role">
            <mat-header-cell *cdkHeaderCellDef fxFlex="35"> {{'general.role.pl'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="35" class="user-list-multiline" data-osp-test="cell-user-roles">
              <span class="user-list-multiline" *ngFor="let item of row.roles" data-osp-test="item-user-role">
               - {{item.name}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="validUntil">
            <mat-header-cell *cdkHeaderCellDef
                             fxFlex="15"> {{'modules.userrole.base.validUntil'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="15" data-osp-test="cell-user-valid-until">
              {{row.validUntil | date: 'dd.MM.yyyy'}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="status">
            <mat-header-cell *cdkHeaderCellDef fxFlex="75px"> {{'general.status'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="75px" data-osp-test="cell-user-status">
              <mat-slide-toggle color="primary"
                                (change)="toggleUserStatus($event, row)"
                                [checked]="row.status === userStatus.ACTIVE"
                                [disabled]="!canChangeStatusUser(row)">
              </mat-slide-toggle>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="external">
            <mat-header-cell *cdkHeaderCellDef fxFlex="35px"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="35px">
              <fa-icon *ngIf="row['external']" class="user-list-icon" [icon]="faShieldAlt"
                       matTooltip="{{'modules.userrole.userList.externalManaged' | translate}}"></fa-icon>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="more">
            <mat-header-cell *cdkHeaderCellDef fxFlex="55px"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="55px" class="user-list-more">
              <button mat-icon-button [matMenuTriggerFor]="userMenuMore"
                      [disabled]="!menuVisible(row)">
                <mat-icon *ngIf="menuVisible(row)">more_vert</mat-icon>
              </button>

              <mat-menu #userMenuMore="matMenu">
                <button mat-menu-item (click)="editUser(row)"
                        [disabled]="!canUpdateUser(row) || row['external']">
                  <mat-icon>edit</mat-icon>
                  <span>{{'modules.userrole.base.editUser'|translate}}</span>
                </button>
                <button *ngIf="row.status === userStatus.ACTIVE" mat-menu-item
                        [disabled]="!canUpdateUser(row)"
                        (click)="deactivateUser(row)">
                  <mat-icon>lock</mat-icon>
                  <span>{{'modules.userrole.userList.deactivateUser'|translate}}</span>
                </button>
                <button *ngIf="row.status === userStatus.ACTIVE" mat-menu-item
                        [disabled]="!canUpdateUser(row) || row.external"
                        (click)="resetPassword(row)">
                  <mat-icon>password</mat-icon>
                  <span>{{'modules.userrole.userList.generatenewPassword'|translate}}</span>
                </button>
                <button *ngIf="row.status === userStatus.INACTIVE" mat-menu-item
                        [disabled]="!canUpdateUser(row)"
                        (click)="activateUser(row)">
                  <mat-icon>lock_open</mat-icon>
                  <span>{{'modules.userrole.userList.activateUser'|translate}}</span>
                </button>

                <!--TODO: style to look like in mocups-->
                <button mat-menu-item (click)="deleteUser(row)"
                        [disabled]="!canDeleteUser(row)"
                        class="user-delete-menu">
                  <mat-icon>delete</mat-icon>
                  <span>{{'modules.userrole.userList.deleteUser'|translate}}</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
