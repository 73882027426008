import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { EquipmentTask } from '../contract/equipment-task';
import { CreateReminderCommand } from '../contract/create-reminder-command';
import { DeleteReminderCommand } from '../contract/delete-reminder-command';
import { UnassignMaintenanceRuleCommand } from '../../maintenance/rules/shared/contract/commands/unassign-maintenance-rule.command';
import { CompleteReminderCommand } from '../contract/complete-reminder-command';
import { DeleteReminderAfterCompletionCommand } from '../contract/delete-reminder-after-completion.command';
import { DeleteMaintenanceTaskAfterCompletionCommand } from '../contract/delete-maintenance-task-after-completion.command';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { TaskCompletedParams } from './contracts/task-completed-params.interface';
import { getHttpParameters } from 'app/shared/utils';

@Injectable()
export class MaintenanceService {

  private url = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance';

  constructor(private http: HttpClient) {
  }

  public getTasksForEquipment(equipmentId, page?: number, size?: number): Observable<PagedResponse<EquipmentTask>> {
    const params = new HttpParams()
    .set('equipmentId', equipmentId)
    .set('page', page.toString())
    .set('size', size.toString());

    return this.http.get<PagedResponse<EquipmentTask>>(`${this.url}/tasks`, {params: params});
  }

  public createReminder(command: CreateReminderCommand): Observable<string> {
    return this.http.post(`${this.url}/reminders/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public completeReminder(command: CompleteReminderCommand): Observable<string> {
    return this.http.post(`${this.url}/reminders/complete`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteReminder(command: DeleteReminderCommand): Observable<string> {
    return this.http.post(`${this.url}/reminders/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteReminderAfterCompletion(command: DeleteReminderAfterCompletionCommand): Observable<string> {
    return this.http.post(`${this.url}/reminders/delete-after-completion`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteMaintenanceTaskAfterCompletion(command: DeleteMaintenanceTaskAfterCompletionCommand): Observable<string> {
    return this.http.post(`${this.url}/tasks/delete-after-completion`, JSON.stringify(command), {responseType: 'text'});
  }

  public reminderNameInUse(equipmentId: string, name: string): Observable<boolean> {
    const params = new HttpParams().set('equipmentId', equipmentId).set('name', name);
    return this.http.get<boolean>(`${this.url}/tasks/reminder-name-in-use`, {params: params});
  }

  public unassignRule(command: UnassignMaintenanceRuleCommand): Observable<string> {
    return this.http.post(`${this.url}/rules/unassign`, command, {responseType: 'text'});
  }

  public getCompletedTasks(params: TaskCompletedParams): Observable<PagedResponse<TaskCompleted>> {
    return this.http.get<PagedResponse<TaskCompleted>>(`${this.url}/tasks/completed`, { params: getHttpParameters(params) });
  }

  public getUrl(): string {
    return this.url;
  }
}
