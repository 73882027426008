import { Component, OnInit } from '@angular/core';
import { versions } from '../../../../environments/versions';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomDeviceDetectorService } from '../../../shared/services/custom-device-detector.service';
import { LanguageService } from '../../../shared/services/language.service';
import { PartnerTheme } from '../../organisation/contract/partner-theme.enum';
import { UserConfigurationService } from '../../../shared/services/user-configuration.service';
import { EquipCareSupportInfo, OnestopProSupportInfo, TriathlonSupportInfo } from '../shared/support-info-constant';
import { CustomThemeService } from '../../../shared/services/custom-theme.service';
import { Languages } from 'app/shared/enums/languages.enum';

@Component({
  selector: 'bh-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {

  public applicationName: string;
  public supportInfo = OnestopProSupportInfo;
  commit = versions.commit;
  version = versions.tag;
  isMobile = this.deviceService.isMobile();
  link = 'https://www.onestop-pro.com' + this.translate('modules.navigation.appInfo.linkReleaseNotesUrl') + '?password=' +
      ((new Date(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(),
          new Date().getUTCDate(), 1, 0, 0)).getTime() / 100000) *
          (new Date(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(),
              new Date().getUTCDate(), 1, 0, 0)).getTime() / 100000))
      .toString()
      .split('')
      .reverse()
      .join('');
  contactLink = 'https://www.onestop-pro.com' + this.translate('modules.navigation.appInfo.linkContactFormUrl');
  ABGLink = this.getABGLink();

  constructor(public dialogRef: MatDialogRef<AppInfoComponent>,
              private deviceService: CustomDeviceDetectorService,
              private userConfigurationService: UserConfigurationService,
              private customThemeService: CustomThemeService,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.applicationName = this.customThemeService.getApplicationTitle();

    if (this.isWNTheme()) {
      this.supportInfo = EquipCareSupportInfo;
    }
    if (this.isTriathlonTheme()) {
      this.supportInfo = TriathlonSupportInfo;
    }
  }

  public isWNTheme() {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.WACKER_NEUSON;
  }

  public isTriathlonTheme() {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.TRIATHLON;
  }

  private getABGLink(): string {
    const translateMap = {
      [PartnerTheme.DEFAULT_OSP]: 'modules.navigation.appInfo.agbOSP',
      [PartnerTheme.WACKER_NEUSON]: 'modules.navigation.appInfo.agbWN',
      [PartnerTheme.TRIATHLON]: 'modules.navigation.appInfo.agbTR',
    };
    let translation = this.languageService.getInstantWithoutFallBack(translateMap[this.userConfigurationService.getPartnerTheme()]);

    if (this.languageService.getCurrentLanguage() === Languages.DEV
      && translation?.length > 0
    ) {
      const prefix = this.languageService.getDevLanguagePrefix();
      translation = translation[0] === prefix ? translation.slice(1) : translation;
      translation = translation[translation.length - 1] === prefix ? translation.slice(0, -1) : translation;
    }
    return translation;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
