import { ProjectToProjectTransferItem } from './project-to-project-transfer-item';

export class CreateProjectToProjectTransferCartCommand {
  constructor(
    public targetProjectId: string,
    public transferItems: ProjectToProjectTransferItem[],
    public employeeId?: string,
    public externalEmployee?: string,
    public comment?: string,
    public transferDate?: Date) {}
}
