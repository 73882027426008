import { BehaviorSubject, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { EquipmentsService } from '../../../equipment/shared/equipments.service';
import { OrganisationInfo } from '../../../equipment/contract/organisation-info.interface';
import { EquipmentTypesService } from '../../../equipment/shared/equipmenttypes.service';
import { GroupedEquipmentType } from 'app/shared/contract/grouped-equipment-type.interface';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class FleetCompositionWidgetStore {

  public readonly availableOrganisations = new BehaviorSubject<OrganisationInfo[]>(null);
  public readonly availableEquipmentTypeCategories = new BehaviorSubject<GroupedEquipmentType[]>(null);

  constructor(
    private equipmentsService: EquipmentsService,
    private equipmentTypesService: EquipmentTypesService,
    private translatableStringResolver: TranslatableStringPipe,
  ) {
  }

  public loadAvailableOrganisations(): void {
    this.equipmentsService.getOrganisations(0, 1000)
    .subscribe(page => this.availableOrganisations.next(page.content));
  }

  public loadAvailableEquipmentTypeCategories() {
    this.equipmentTypesService.getAllEquipmentTypesGroupedByCategory1()
    .pipe(map(equipmentTypes =>
      equipmentTypes.map(type => ({
        ...type,
        category1TranslatedName: this.translatableStringResolver.transform(type.category1Name)
      })))
    )
    .subscribe((equipmentTypeCategories: GroupedEquipmentType[]) => this.availableEquipmentTypeCategories.next(equipmentTypeCategories));
  }
}
