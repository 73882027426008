import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnDefinition } from 'app/shared/column-definition';
import { ColumnService } from 'app/shared/column.service';
import { DisplayService } from 'app/shared/display.service';
import { PaginatorPageSizeOptions } from 'app/shared/paginator-page-size-options';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';

@Injectable()
export class DispositionColumnService extends ColumnService {
  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService) {
    super(null, [], [], [],
      {
        dispositionListPaginatorOptions: {
          boardViewPageSize: userConfigurationService.userConfiguration.dispositionListConfiguration.boardViewPageSize,
          equipmentListPageSize: userConfigurationService.userConfiguration.dispositionListConfiguration.equipmentListPageSize,
          employeeListPageSize: userConfigurationService.userConfiguration.dispositionListConfiguration.employeeListPageSize
        }
      },
      displayService,
      authService);
  }

  public selectColumns(
    columns: ColumnDefinition[] = [],
    pageSize?: number,
    paginatorPageSizeOptions?: PaginatorPageSizeOptions
  ): void {
    this.userConfigurationService.saveDispositionListConfiguration(
      paginatorPageSizeOptions?.dispositionListPaginatorOptions?.boardViewPageSize,
      paginatorPageSizeOptions?.dispositionListPaginatorOptions?.equipmentListPageSize,
      paginatorPageSizeOptions?.dispositionListPaginatorOptions?.employeeListPageSize
    );
    super.selectColumns(columns, pageSize, paginatorPageSizeOptions);
  }

  public selectEquipmentListPageSize(equipmentListPageSize: number): void {
    if (equipmentListPageSize && equipmentListPageSize !== this.dispositionEquipmentListViewPageSizeSubject.getValue()) {
      this.selectColumns(
        this.selectedColumnsSubject.getValue(),
        null,
        { dispositionListPaginatorOptions: { equipmentListPageSize } }
      );
    }
  }

  public selectEmployeeListPageSize(employeeListPageSize: number): void {
    if (employeeListPageSize && employeeListPageSize !== this.dispositionEmployeeListViewPageSizeSubject.getValue()) {
      this.selectColumns(
        this.selectedColumnsSubject.getValue(),
        null,
        { dispositionListPaginatorOptions: { employeeListPageSize } }
      );
    }
  }

  public selectBoardViewPageSize(boardViewPageSize: number): void {
    if (boardViewPageSize && boardViewPageSize !== this.dispositionBoardViewPageSizeSubject.getValue()) {
      this.selectColumns(
        this.selectedColumnsSubject.getValue(),
        null,
        { dispositionListPaginatorOptions: { boardViewPageSize } }
      );
    }
  }
}
