<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close [attr.aria-label]="'general.labels.clear'|translate" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{'shared.mapSettings.mapConfig'|translate}}</h1>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="space-around start">

    <mat-form-field *ngIf="canSeeLinkEquipment">
      <mat-label>{{'shared.mapSettings.equipmRepresentation'|translate}}</mat-label>
      <mat-select [(value)]="selectedEquipmentField" disableOptionCentering>
        <mat-option *ngFor="let field of equipmentFields" [value]="field.value">
          {{field.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="canSeeLinkProject">
      <mat-label>{{'shared.mapSettings.projectRepresentation'|translate}}</mat-label>
      <mat-select [(value)]="selectedProjectField" disableOptionCentering>
        <mat-option *ngFor="let field of projectFields" [value]="field.value">
          {{field.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'shared.mapSettings.organisationRepresentation'|translate}}</mat-label>
      <mat-select [(value)]="selectedOrganisationField" disableOptionCentering>
        <mat-option *ngFor="let field of organisationFields" [value]="field.value">
          {{field.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'shared.mapSettings.stockRepresentation'|translate}}</mat-label>
      <mat-select [(value)]="selectedStockField" disableOptionCentering>
        <mat-option *ngFor="let field of stockFields" [value]="field.value">
          {{field.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="canSeeLinkContact">
      <mat-label>{{'shared.mapSettings.contactRepresentation'|translate}}</mat-label>
      <mat-select [(value)]="selectedContactField" disableOptionCentering>
        <mat-option *ngFor="let field of contactFields" [value]="field.value">
          {{field.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <mat-label class="smaller-font">{{'shared.mapSettings.sizeOfCluster'|translate}}:</mat-label>
      <mat-slider color="primary" step="10" min="10" max="100" [discrete]="true">
        <input matSliderThumb [(ngModel)]="gridSize"/>
      </mat-slider>
    </div>

  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button color="primary" mat-dialog-close="yes">{{'general.buttons.save'|translate}}</button>
  </div>
</div>
