<div class="dialog-wrapper" fxLayout="column">
  <h1 mat-dialog-title>
    <ng-container *ngIf="getCurrentShownTransferId()">
      {{'modules.disposition.summaryTransfer.title' |translate : {noteNumber: getCurrentShownTransferId()} }}
    </ng-container>
  </h1>

  <mat-dialog-content class="dialog-content">

    <div fxFlex="5" fxLayout="row" class="info-table">
      <div fxFlex="30" class="overview-title">
        {{'modules.disposition.summaryTransfer.secondaryTitle' | translate}}
      </div>
      <div fxFlex="70" class="overview-timestampAndUser">
        {{'general.labels.createdOn' | translate}}
        <span class="highlightText">{{currentTime}}</span>
        {{'general.from' | translate}}
        <span class="highlightText">{{currentUser}}</span>
      </div>
    </div>

    <div class="transfer-tabs">
      <mat-tab-group dynamicHeight [selectedIndex]="selected.value"
                     (selectedTabChange)="setCurrentShownTransferId($event.index)">

        <mat-tab [disabled]="!displayProjectTransferTab()"
                 label="{{'modules.disposition.bulkItemTransfer.transferFromProject'|translate}}">
          <bh-transfer-summary-table [transferItems]="transferItemsWithSourceFromProject"
                                     [transferItemsWithStatus]="transferItemsWithStatus">
          </bh-transfer-summary-table>
        </mat-tab>

        <mat-tab [disabled]="!displayStockTransferTab()"
                 label="{{'modules.disposition.bulkItemTransfer.transferFromStock'|translate}}">
          <bh-transfer-summary-table [transferItems]="transferItemsWithSourceFromStock">
          </bh-transfer-summary-table>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div fxFlex="5" class="transfer-footer">
      <div *ngIf="transferToProject(), else targetStockInfo">
        <p>
          {{'modules.disposition.summaryTransfer.deliveryNoteInfo' | translate
          : {targetType: 'general.project.s'|translate} }}
        </p>
        <div fxLayout="column">
          <span class="project-info">
            {{'general.project.s'|translate}}:
          </span>
          <pre class="project-info project-number"># {{ this.transferTarget.projectNumber?.trim() }}</pre>
          <span class="project-info">
            {{this.transferTarget.projectName}}
          </span>
          <div *ngIf="this.transferTarget.projectCostCenter" fxFlex fxLayoutAlign="start center">
            <fa-icon [icon]="faWallet"></fa-icon>
            <span class="project-info project-cost-center">
              {{this.transferTarget.projectCostCenter}}
            </span>
          </div>
          <div class="project-address" fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="1 1 24px" fxLayoutAlign="center center">
              <mat-icon
                class="project-address__icon"
                [svgIcon]="'transport-start'">
              </mat-icon>
            </span>
            <div fxFlex="1 1 0%" fxLayout="column" class="project-address__data">
              <span class="project-address__data__property"
                    *ngIf="this.transferTarget.projectAddress.street && this.transferTarget.projectAddress.streetNumber">
                {{this.transferTarget.projectAddress.street}}
                {{this.transferTarget.projectAddress.streetNumber}}
              </span>
              <span class="project-address__data__property">
                {{this.transferTarget.projectAddress.postalCode}}
                {{this.transferTarget.projectAddress.city}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #targetStockInfo>
        <p>
          {{'modules.disposition.summaryTransfer.deliveryNoteInfo' | translate
          : {targetType: 'general.stock.s'|translate} }}
        </p>
        {{'general.stock.s'|translate}}:
        {{this.transferTarget.stockName}}
        {{this.transferTarget.stockAddress.postalCode}}
        {{this.transferTarget.stockAddress.city}}
      </ng-template>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="transferFromProject?.transferId" fxLayout="row" fxLayoutAlign="center center"
            class="mat-mdc-focus-indicator mat-primary mat-mdc-raised-button mat-mdc-button-base mdc-button mdc-button--raised custom-btn"
            [matTooltip]="!downloadingFromProject
                          ? ('modules.disposition.summaryTransfer.title' |translate
                          : {noteNumber: transferFromProject?.deliveryNoteNumber}) : ''"
            [class.downloading]="downloadingFromProject"
            [disabled]="downloadingFromProject"
            (click)="exportTransferFromProjectToPdf($event, transferFromProject.transferId)">
      <div class="download-delivery-note__wrapper">
        <fa-icon *ngIf="!downloadingFromProject" class="download-delivery-note__icon" [icon]="faFilePdf"></fa-icon>
        <mat-spinner *ngIf="downloadingFromProject" class="download-delivery-note__spinner"></mat-spinner>
      </div>
      <span class="button-label">
        <ng-container *ngIf="!downloadingFromProject"> {{'modules.disposition.projectTransferHistory.deliveryNote'|translate}}</ng-container>
        <ng-container *ngIf="downloadingFromProject"> {{'modules.disposition.projectTransferHistory.downloading'|translate}}</ng-container>
      </span>
    </button>

    <button *ngIf="transferFromStock?.transferId" fxLayout="row" fxLayoutAlign="center center"
            class="mat-mdc-focus-indicator mat-primary mat-mdc-raised-button mat-mdc-button-base mdc-button mdc-button--raised custom-btn"
            [matTooltip]="!downloadingFromStock
                          ? ('modules.disposition.summaryTransfer.title' |translate
                          : {noteNumber: transferFromStock?.deliveryNoteNumber}) : ''"
            [class.downloading]="downloadingFromStock"
            [disabled]="downloadingFromStock"
            (click)="exportTransferFromStockToPdf($event, transferFromStock.transferId)">
      <div class="download-delivery-note__wrapper">
        <fa-icon *ngIf="!downloadingFromStock" class="download-delivery-note__icon" [icon]="faFilePdf"></fa-icon>
        <mat-spinner *ngIf="downloadingFromStock" class="download-delivery-note__spinner"></mat-spinner>
      </div>
      <span class="button-label">
        <ng-container *ngIf="!downloadingFromStock"> {{'modules.disposition.projectTransferHistory.deliveryNote'|translate}}</ng-container>
        <ng-container *ngIf="downloadingFromStock"> {{'modules.disposition.projectTransferHistory.downloading'|translate}}</ng-container>
      </span>
    </button>

    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{confirmLabel}}</button>
  </mat-dialog-actions>
</div>









