import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { EquipmentsDataSource } from '../../../../equipment/shared/equipments.datasource';
import { MatomoTracker } from 'ngx-matomo';
import { LanguageService } from '../../../../../shared/services/language.service';
import { matomoCategories } from '../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../assets/matomo/matomo-actions.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from '../../../../../shared/constants/base-column-definition-constants';
import { MaintenanceColumnDefinitionService } from '../../shared/service/maintenance-column-definition.service';
import { MaintenanceTaskDataSource } from '../../shared/service/maintenance-task.datasource';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { TimezoneDatesService } from '../../../../../shared/timezone/timezone-dates.service';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-list-export-dialog',
  templateUrl: './maintenance-list-export-dialog.component.html',
  styleUrls: ['./maintenance-list-export-dialog.component.scss']
})
export class MaintenanceListExportDialogComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {
  public selectAllColumns = false;
  public readonly selectedColumns: string[] = [];
  public applyCurrentFilter = true;
  public readonly sortableFields: { name: string, value: string }[] = [];
  public sortBy = MAINTENANCE_TASK_COLUMN_DEF.DUE_DATE;
  public sortDescending = false;
  public pendingRequest = false;

  constructor(authService: KeycloakService,
              private dialogRef: MatDialogRef<MaintenanceListExportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { selectedColumns: ColumnDefinition[] },
              private equipmentStore: EquipmentsDataSource,
              public maintenanceTaskStore: MaintenanceTaskDataSource,
              private matomoTracker: MatomoTracker,
              private maintenanceColumnDefinitionService: MaintenanceColumnDefinitionService,
              private languageService: LanguageService,
              private timezoneDataService: TimezoneDatesService) {
    super(authService)
  }

  public ngOnInit(): void {
    this.maintenanceColumnDefinitionService.availableColumns
    .filter(column => this.isSortableColumn(column))
    .forEach(column => {
      this.sortableFields.push({
        name: column.readableName,
        value: column.cdkColumnDef
      });
    });
    this.sortableFields.sort((a, b) => a.name.localeCompare(b.name));

    this.data.selectedColumns.filter(column => this.isDisplayableColumn(column)).forEach(column => {
      this.selectedColumns.push(column.cdkColumnDef);
    });
  }

  public ngOnDestroy(): void {
  }

  public cancel(): void {
    this.matomoTracker.trackEvent(matomoCategories.MAINTENANCE_LIST, matomoActions.EXPORT_MAINTENANCE_CANCEL);
    this.dialogRef.close();
  }

  public exportMaintenanceList(): void {
    this.matomoTracker.trackEvent(matomoCategories.MAINTENANCE_LIST, matomoActions.EXPORT_MAINTENANCE_EXPORT);
    this.pendingRequest = true;
    const columns: string[] = this.selectAllColumns ? this.getAllDisplayableColumns() : this.selectedColumns;
    this.maintenanceTaskStore.exportMaintenanceList(columns, this.applyCurrentFilter, this.sortBy, this.sortDescending,
      this.timezoneDataService.getSelectedTimezone())
    .pipe(untilDestroyed(this))
    .subscribe((data: Blob) => this.saveList(data), () => this.pendingRequest = false);
  }

  private isSortableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_NAME
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_COST_CENTER
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_CONSTRUCTION_YEAR
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.PROJECT_NAME
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_CATEGORY
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.DUE_DATE
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.REMAINING_VALUE
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.CURRENT_LOCATION_NAME
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.CURRENT_LOCATION_NUMBER
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY2
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.STATUS;
  }

  private getAllDisplayableColumns(): string[] {
    return this.maintenanceColumnDefinitionService.availableColumns
    .filter(column => this.isDisplayableColumn(column))
    .map(column => column.cdkColumnDef);
  }

  private isDisplayableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.cdkColumnDef !== GENERAL_COLUMN_DEF.OPEN_DETAILS
      && columnDefinition.type !== GENERAL_COLUMN_DEF.IMAGE
      && columnDefinition.type !== GENERAL_COLUMN_DEF.ICON
      && columnDefinition.type !== GENERAL_COLUMN_DEF.DETAILS
      && columnDefinition.type !== GENERAL_COLUMN_DEF.INFO
      && columnDefinition.cdkColumnDef !== MAINTENANCE_TASK_COLUMN_DEF.TASK_ACTION
      && columnDefinition.cdkColumnDef !== GENERAL_COLUMN_DEF.EQUIPMENT_INFO;
  }

  private saveList(data: Blob): void {
    this.pendingRequest = false;
    saveAs(data, this.createFileName());
    this.dialogRef.close();
  }

  private createFileName(): string {
    return this.languageService.getInstant('shared.maintenance.maintenanceListExport.maintenanceListFileName')
      + `_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`;
  }
}
