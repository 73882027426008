import { LanguageService } from '../../../../../../shared/services/language.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TelematicsLocationRecord } from '../../../../contract/telematics-location-record.interface';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { TelematicsDataSource } from '../../../../shared/telematics.datasource';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { CurrentEquipmentStatus } from '../../../../contract/current-equipment-status.interface';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { isDefined } from '../../../../../../shared/utils';
import {
  RoleAuthorityGuardsComponent
} from '../../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, skip } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-card',
  templateUrl: './equipment-view-card.component.html',
  styleUrls: ['./equipment-view-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentViewCardComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public equipment: ViewEquipment;
  public telematicsLocationsForm: UntypedFormGroup;
  public startDateControl: UntypedFormControl;
  public endDateControl: UntypedFormControl;
  public telematicsLocations: TelematicsLocationRecord[];
  public telematicsEquipmentStatus: CurrentEquipmentStatus;
  public showMap = false;
  public maxInterval: moment.Duration = moment.duration(1, 'month');
  public infoText = '';

  constructor(
    private telematicsStore: TelematicsDataSource,
    private formBuilder: UntypedFormBuilder,
    private equipmentStore: EquipmentsDataSource,
    protected authService: KeycloakService,
    private languageService: LanguageService,
    private changeDetectorRef: ChangeDetectorRef,
    private matomoTracker: MatomoTracker
  ) {
    super(authService);
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_MAP);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.subscribeToCurrentEquipment();
    this.subscribeToTelematicLocations();
    this.subscribeToTelematicsEquipmentStatus();
  }

  private subscribeToCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(untilDestroyed(this))
    .subscribe((currentEquipment: ViewEquipment) => {
      this.telematicsStore.clearTelematicsLocations();
      this.showMap = false;
      if (currentEquipment) {
        this.equipment = currentEquipment;
        this.infoText = this.translate('modules.equipment.map.choosePeriodOneMonth');
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  private subscribeToTelematicLocations(): void {
    this.telematicsStore.telematicsLocations
    .pipe(
      skip(1),
      untilDestroyed(this))
    .subscribe((telematicsLocations: TelematicsLocationRecord[]) => {
      this.showMap = telematicsLocations && !!telematicsLocations.length;
      if (!this.showMap) {
        this.infoText = this.translate('modules.equipment.map.noDataForPeriod');
      }
      this.telematicsLocations = telematicsLocations;
      this.getTelematicsEquipmentStatus();
      this.changeDetectorRef.detectChanges();
    });
  }

  private subscribeToTelematicsEquipmentStatus(): void {
    this.telematicsStore.equipmentStatus
    .pipe(
      untilDestroyed(this),
      filter(isDefined)
    )
    .subscribe(telematicsStatus => {
      this.telematicsEquipmentStatus = telematicsStatus;
      this.changeDetectorRef.detectChanges();
    });
  }

  public onSubmit(): void {
    if (this.telematicsLocationsForm.valid) {
      this.infoText = '';
      this.showMap = false;
      this.telematicsStore.getLocationsByEquipment(
        this.equipment.equipmentId,
        this.startDateControl.value,
        this.endDateControl.value);
    }
    this.changeDetectorRef.detectChanges();
  }

  private translate(key:  string): string {
    return this.languageService.getInstant(key);
  }

  private getTelematicsEquipmentStatus(): void {
    if (this.equipment?.equipmentId && this.telematicsLocations?.length > 0) {
      this.telematicsStore.getEquipmentStatus(this.equipment.equipmentId);
    }
  }

  private buildForm(): void {
    this.startDateControl = new UntypedFormControl(moment().subtract(1, 'day').toDate());
    this.endDateControl = new UntypedFormControl(new Date());

    this.telematicsLocationsForm = this.formBuilder.group({
      filterFrom: this.startDateControl,
      filterTo: this.endDateControl
    });
  }

  public isLoading(): Observable<boolean> {
    return this.telematicsStore.isLoading;
  }
}
