export class UpdateCustomerTelematicUnitCommand {
  constructor(
    public customerTelematicsUnitId: string,
    public boxNo: string,
    public serialNumber: string,
    public mac: string,
    public partnerId: string,
    public simCardProvider: string,
    public eSim1IccId: string,
    public eSim1Imsi: string
  ) {}
}
