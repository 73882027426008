import { environment } from 'environments/environment';
import { ViewTechnicalField } from '../contract/view-technical-field.interface';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { TechnicalFieldsService } from './technical-fields.service';
import { Injectable } from '@angular/core';
import { UpdatedTechnicalFieldCommand } from '../contract/updated-technical-field-command';
import { delay, filter, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { CreateTechnicalFieldCommand } from '../contract/create-technical-field-command';
import { isDefined } from '../../../shared/utils';

@Injectable()
export class TechnicalFieldsDataSource {
  private technicalFields: BehaviorSubject<ViewTechnicalField[]> = new BehaviorSubject([]);
  public readonly currentTechnicalFields: Observable<ViewTechnicalField[]> = this.technicalFields.asObservable();

  private technicalField:  BehaviorSubject<ViewTechnicalField> = new BehaviorSubject(undefined);
  public readonly currentTechnicalField: Observable<ViewTechnicalField> = this.technicalField
  .asObservable()
  .pipe(filter(technicalField => isDefined(technicalField)));

  public readonly currentTechnicalFieldInUse: Observable<boolean> = this.technicalField
  .pipe(
      filter(technicalField => isDefined(technicalField)),
      flatMap(technicalField => this.technicalFieldsService.technicalFieldInUse(technicalField.technicalFieldId)));

  private searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly filteredTechnicalFields: Observable<ViewTechnicalField[]> = combineLatest(this.currentTechnicalFields, this.searchTerm)
  .pipe(map(([availableTechnicalFields, searchTerm]) =>
      availableTechnicalFields.filter((technicalField: ViewTechnicalField) => {
        const technicalFieldKey = (technicalField.technicalFieldKey).toLowerCase();
        const technicalFieldName = (technicalField.technicalFieldName).toLowerCase();
        return !searchTerm || (technicalFieldKey.indexOf(searchTerm.toLowerCase()) !== -1 ||
            technicalFieldName.indexOf(searchTerm.toLowerCase()) !== -1) ;
      })
  ));

  constructor(public technicalFieldsService: TechnicalFieldsService) {
  }

  public reset(): void {
    this.loadTechnicalFields();
  }

  public filterTechnicalFieldsBy(searchTerm: string): void {
    return this.searchTerm.next(searchTerm);
  }

  public loadTechnicalFields(): void {
    this.initialize();
    this.technicalFieldsService.getTechnicalFields()
    .subscribe(technicalFields =>
        this.technicalFields.next(technicalFields.content)
    );
  }

  public updateTechnicalField(command: UpdatedTechnicalFieldCommand): Observable<string> {
    return this.technicalFieldsService
    .updateTechnicalField(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.loadTechnicalFields()));
  }

  public loadTechnicalField(technicalFieldKey: string): void {
    this.technicalFieldsService.getTechnicalFieldByTechnicalFieldKey(technicalFieldKey)
    .subscribe(technicalField => this.technicalField.next(technicalField));
  }

  public saveTechnicalField(command: CreateTechnicalFieldCommand): Observable<string> {
    return this.technicalFieldsService
    .saveTechnicalField(command)
    .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.loadTechnicalFields()));
  }

  public keyExistsCheck(key: string, sendId = false): Observable<boolean> {
    return this.technicalFieldsService.keyExistsCheck({
      id: sendId ? this.technicalField.value.technicalFieldId : null,
      key,
    });
  }

  public nameExistsCheck(name: string, sendId = false): Observable<boolean> {
    return this.technicalFieldsService.nameExistsCheck({
      id: sendId ? this.technicalField.value.technicalFieldId : null,
      name,
    });
  }

  private initialize(): void {
    this.searchTerm.next('');
    this.technicalFields.next([]);
  }
}
