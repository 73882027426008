import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Directive, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

export interface StepSelectionPayload {
  current: any,
  previous: any,
  clicked: any
}

@UntilDestroy()
@Directive({
  selector: '[bhStepHeaderOverlay]',
})
export class StepHeaderOverlayDirective implements AfterViewInit {
  @Input('bhStepHeaderOverlay') headerPayloads: any[] = [];
  @Output() headerClick = new EventEmitter<StepSelectionPayload>();

  private stepSelection: StepSelectionPayload;
  constructor(private matStepper: MatStepper) { }

  public ngAfterViewInit(): void {
    this.stepSelection = {
      current: this.getPayload(this.matStepper.selectedIndex),
      previous: null,
      clicked: null
    };

    this.matStepper._stepHeader.toArray().forEach((matStepHeader, index) =>
      matStepHeader['_elementRef'].nativeElement.addEventListener(
        'click',
        this.headerClicked.bind(this, index)));

    this.matStepper.selectionChange
      .pipe(untilDestroyed(this))
      .subscribe(({ selectedIndex, previouslySelectedIndex }) =>
        this.stepSelection = {
          current: this.getPayload(selectedIndex),
          previous: this.getPayload(previouslySelectedIndex),
          clicked: null
        }
      );
  }

  private headerClicked(headerIndex: number): void {
    this.headerClick.emit({
      ...this.stepSelection,
      clicked: this.getPayload(headerIndex)
    });
  }

  private getPayload(index: number): any {
    return this.headerPayloads[index] ? this.headerPayloads[index] : index;
  }
}
