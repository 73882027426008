import { Component } from '@angular/core';

@Component({
  selector: 'bh-warning-dialog',
  styleUrls: ['./warning-dialog.component.scss'],
  templateUrl: './warning-dialog.component.html'
})
export class WarningDialogComponent {
  public message: string;
  public title: string;
  public secondaryMessage: string;
}
