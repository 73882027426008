import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'digitalMatterMobileSignalPipe'
})
export class DigitalMatterMobileSignalPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  private readonly GOOD = 'modules.telematic.telematicUnitLiveData.digitalMatter.mobilesignal.good';
  private readonly MEDIUM = 'modules.telematic.telematicUnitLiveData.digitalMatter.mobilesignal.medium';
  private readonly BAD = 'modules.telematic.telematicUnitLiveData.digitalMatter.mobilesignal.bad';

  public transform(signalNumber: number): string {
    return this.translate(this.getSignalStringFromNumber(signalNumber) || 'general.unknown')
  }

  private getSignalStringFromNumber(signalNumber: number): string {
    if (signalNumber >= 0 && signalNumber < 11) {
      return this.BAD;
    }

    if (signalNumber >= 11 && signalNumber < 22) {
      return this.MEDIUM;
    }

    if (signalNumber >= 22 && signalNumber < 32) {
      return this.GOOD;
    }

    return null;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
