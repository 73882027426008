<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px" class="scrollable">
  <div fxLayout="column" fxFlex fxLayoutAlign="start stretch">

    <bh-title [text]="'general.geofence.pl'|translate"></bh-title>
    <div class="geofences-content" fxLayout="row" fxLayoutAlign="start stretch">
      <bh-geofence-sidebar
          #geofenceSidebar
          fxFlex="25"
          [map]="map"
          [createGeofenceEnabled]="createGeofenceEnabled"
          [editGeofenceEnabled]="editGeofenceEnabled"
          [deleteGeofenceEnabled]="deleteGeofenceEnabled"
          [manageAlarmsEnabled]="manageAlarmsEnabled"
          [tooltipTextAdd]="'shared.geofence.addProjectGeofence'|translate"
          [tooltipTextDelete]="'shared.geofence.removeProjectGeofence'|translate"
          (onCreate)="onCreate($event)"
          (onEdit)="onEdit($event)"
          (onDelete)="onDelete($event)"
          (onSetAlarm)="onSetAlarm($event)"
          (onRemoveAlarm)="onRemoveAlarm($event)">
      </bh-geofence-sidebar>
      <div class="geofences-map" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <bh-map id="map" (mapInitialized)="mapReady($event)"></bh-map>
      </div>
    </div>

  </div>
</div>
