<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="title">{{title}}</h1>
  <div mat-dialog-content class="message">
    {{message}}

    <div *ngIf="secondaryMessage" class="message secondary">
      {{secondaryMessage}}
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="primary" mat-dialog-close>{{'general.buttons.ok'|translate}}</button>
  </div>
</div>
