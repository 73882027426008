import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { BaseProjectViewAttachmentsComponent } from '../base/base-project-view-attachments.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab } from '@angular/material/tabs';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { ImageLink } from '../../../../../../equipment/contract/image-link.interface';
import { EquipmentMobileImageDialogComponent } from '../../../../../../equipment/equipments/equipment-mobile-image-dialog-component/equipment-mobile-image-dialog.component';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-project-view-attachments',
  templateUrl: './mobile-project-view-attachments.component.html',
  styleUrls: ['./mobile-project-view-attachments.component.scss']
})
export class MobileProjectViewAttachmentsComponent extends BaseProjectViewAttachmentsComponent implements OnInit {

  private dialogRef: MatDialogRef<EquipmentMobileImageDialogComponent>;

  public selectedTab: MatTab;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected userConfigurationService: UserConfigurationService,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService,
              public matSnackBar: MatSnackBar) {
    super(authService, router, route, dialog, userConfigurationService, routerHistory, projectStore, languageService, matSnackBar);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  private openImageDialog(image: ImageLink): void {
    event.stopPropagation();
    this.dialogRef = this.dialog.open(EquipmentMobileImageDialogComponent, {
      height: '80vh',
      width: '80vw'
    });
    this.dialogRef.componentInstance.image = image;
  }

  private directDownloadDocument(document: DocumentLink): void {

  }

  public openFile(event: MouseEvent, image: ImageLink | DocumentLink): void {
    event.stopPropagation();

    if (FileUtils.isPicture(image)) {
      return this.openImageDialog(<ImageLink>image);
    }
    this.directDownloadDocument(<DocumentLink>image);
  }

}
