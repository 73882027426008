<mat-form-field [floatLabel]="'always'">
  <mat-label>{{'general.description'|translate}}</mat-label>
  <input matInput
         bhRemoveWhitespaces
         [readonly]="readonly"
         [formControl]="inputControl"
         (keydown.enter)="save()"
         (keydown.escape)="cancel()">
  <mat-error *ngIf="inputControl.hasError('maxlength')">
    {{'shared.validation.maxCharacters'|translate:{ value:fieldLimit.SHORT_TEXT } }}
  </mat-error>
  <mat-icon matSuffix
            class="bh-mat-icon"
            *ngIf="readonly && editable"
            (click)="edit()"
            [matTooltip]="'general.buttons.change'|translate">
    mode_edit
  </mat-icon>
  <mat-icon matSuffix
            class="bh-mat-icon"
            *ngIf="!readonly && inputControl.valid"
            (click)="save()"
            [matTooltip]="'general.buttons.save'|translate">
    check
  </mat-icon>
  <mat-icon matSuffix
            class="bh-mat-icon hover-red"
            *ngIf="!readonly"
            (click)="cancel()"
            [matTooltip]="'general.buttons.cancel'|translate">
    close
  </mat-icon>
</mat-form-field>
