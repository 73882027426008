import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { takeWhile, tap, pluck, filter } from 'rxjs/operators';
import { ProjectCheckerService } from '../../../../../shared/services/project-checker.service';
import { Observable } from 'rxjs';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Directive()
export abstract class BaseProjectViewDetailComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  hasAddress: boolean;
  projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  public customerLabels: Observable<string[]>;
  public projectLabels: Observable<string[]>;
  private componentActive = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource) {
    super(authService, router, route, routerHistory);
  }

  get project(): ViewProject {
    return this.projectStore.project();
  }

  ngOnInit(): void {
    this.projectStore.currentViewTab = 'general';
    this.subscribeToCurrentProject();
    this.handleRouteProjectId();
    this.customerLabels = this.projectStore.filteredCustomerLabels;
    this.projectLabels = this.projectStore.projectLabels;
    this.getCustomerLabels();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  subscribeToCurrentProject(): void {
    this.projectStore.currentProject
    .pipe(
        takeWhile(() => this.componentActive),
        tap(() => this.getCustomerLabels())
    ).subscribe((res: ViewProject) => {
      if (res) {
        this.hasAddress = this.project && !!this.project.location;
        this.projectStore.getProjectLabels(res.projectId);
        this.projectStore.getEmployeeAssignments(res.projectId);
      }
    });
  }

  private handleRouteProjectId(): void {
    this.route.parent.params
    .pipe(
      pluck('id'),
      filter(Boolean),
      takeWhile(() => this.componentActive))
    .subscribe((paramProjectId: string) => {
      if (this.projectStore.currentlyLoadingProjectId !== paramProjectId) {
        this.projectStore.searchTerms = null;
      }
      if (this.projectStore.project()
        && this.projectStore.project().projectId !== paramProjectId
        && this.projectStore.currentlyLoadingProjectId !== paramProjectId) {
          this.projectStore.setCurrentProject(paramProjectId);
        }
    });
  }

  private getCustomerLabels(): void {
    this.projectStore.getCustomerLabels(true)
    .pipe(takeWhile(() => this.componentActive))
    .subscribe();
  }
}
