import { GalleryAnimation } from '../enums/gallery-animation.enum';
import { GalleryImageSize } from '../enums/gallery-image-size.enum';
import { GalleryLayout } from '../enums/gallery-layout.enum';
import { GalleryOrder } from '../enums/gallery-order.enum';
import { IGalleryOptions } from '../interfaces/gallery-options.interface';


export class GalleryOptions implements IGalleryOptions {
  public width?: string;
  public height?: string;
  public breakpoint?: number;
  public fullWidth?: boolean;
  public layout?: string;
  public startIndex?: number;
  public linkTarget?: string;
  public image?: boolean;
  public imagePercent?: number;
  public imageArrows?: boolean;
  public imageArrowsAutoHide?: boolean;
  public imageAnimation?: string;
  public imageSize?: string;
  public imageAutoPlay?: boolean;
  public imageAutoPlayInterval?: number;
  public imageAutoPlayPauseOnHover?: boolean;
  public imageInfinityMove?: boolean;
  public imageBullets?: boolean;
  public thumbnails?: boolean;
  public thumbnailsColumns?: number;
  public thumbnailsRows?: number;
  public thumbnailsPercent?: number;
  public thumbnailsMargin?: number;
  public thumbnailsArrows?: boolean;
  public thumbnailsArrowsAutoHide?: boolean;
  public thumbnailsMoveSize?: number;
  public thumbnailsOrder?: GalleryOrder;
  public thumbnailsRemainingCount?: boolean;
  public thumbnailsAsLinks?: boolean;
  public thumbnailsAutoHide?: boolean;
  public thumbnailMargin?: number;
  public thumbnailSize?: string;
  public preview?: boolean;
  public previewArrows?: boolean;
  public previewArrowsAutoHide?: boolean;
  public previewFullscreen?: boolean;
  public previewForceFullscreen?: boolean;
  public previewCloseOnClick?: boolean;
  public previewCloseOnEsc?: boolean;
  public previewKeyboardNavigation?: boolean;
  public previewAnimation?: boolean;
  public previewAutoPlay?: boolean;
  public previewAutoPlayInterval?: number;
  public previewAutoPlayPauseOnHover?: boolean;
  public previewInfinityMove?: boolean;
  public previewZoom?: boolean;
  public previewZoomStep?: number;
  public previewZoomMax?: number;
  public previewZoomMin?: number;
  public previewRotate?: boolean;
  public previewDownload?: boolean;
  public previewCustom?: (index: number) => void;
  public previewBullets?: boolean;

  constructor(obj: IGalleryOptions) {
    const preventDefaults = obj.breakpoint === undefined ? false : true;

    function use<T>(source: T, defaultValue: T): T {
        return obj && (source !== undefined || preventDefaults) ? source : defaultValue;
    }

    this.breakpoint = use(obj.breakpoint, undefined);
    this.width = use(obj.width, '500px');
    this.height = use(obj.height, '400px');
    this.fullWidth = use(obj.fullWidth, false);
    this.layout = use(obj.layout, GalleryLayout.THUMBNAILS_BOTTOM);
    this.startIndex = use(obj.startIndex, 0);
    this.linkTarget = use(obj.linkTarget, '_blank');

    this.image = use(obj.image, true);
    this.imagePercent = use(obj.imagePercent, 75);
    this.imageArrows = use(obj.imageArrows, true);
    this.imageArrowsAutoHide = use(obj.imageArrowsAutoHide, false);
    this.imageAnimation = use(obj.imageAnimation, GalleryAnimation.FADE);
    this.imageSize = use(obj.imageSize, GalleryImageSize.COVER);
    this.imageAutoPlay = use(obj.imageAutoPlay, false);
    this.imageAutoPlayInterval = use(obj.imageAutoPlayInterval, 2000);
    this.imageAutoPlayPauseOnHover = use(obj.imageAutoPlayPauseOnHover, false);
    this.imageInfinityMove = use(obj.imageInfinityMove, false);

    this.thumbnails = use(obj.thumbnails, true);
    this.thumbnailsColumns = use(obj.thumbnailsColumns, 4);
    this.thumbnailsRows = use(obj.thumbnailsRows, 1);
    this.thumbnailsPercent = use(obj.thumbnailsPercent, 25);
    this.thumbnailsMargin = use(obj.thumbnailsMargin, 10);
    this.thumbnailsArrows = use(obj.thumbnailsArrows, true);
    this.thumbnailsArrowsAutoHide = use(obj.thumbnailsArrowsAutoHide, false);
    this.thumbnailsMoveSize = use(obj.thumbnailsMoveSize, 1);
    this.thumbnailsOrder = use(obj.thumbnailsOrder, GalleryOrder.COLUMN);
    this.thumbnailsRemainingCount = use(obj.thumbnailsRemainingCount, false);
    this.thumbnailsAsLinks = use(obj.thumbnailsAsLinks, false);
    this.thumbnailsAutoHide = use(obj.thumbnailsAutoHide, false);
    this.thumbnailMargin = use(obj.thumbnailMargin, 10);
    this.thumbnailSize = use(obj.thumbnailSize, GalleryImageSize.COVER);

    this.preview = use(obj.preview, true);
    this.previewArrows = use(obj.previewArrows, true);
    this.previewArrowsAutoHide = use(obj.previewArrowsAutoHide, false);
    this.previewFullscreen = use(obj.previewFullscreen, false);
    this.previewCloseOnClick = use(obj.previewCloseOnClick, false);
    this.previewCloseOnEsc = use(obj.previewCloseOnEsc, false);
    this.previewKeyboardNavigation = use(obj.previewKeyboardNavigation, false);
    this.previewAnimation = use(obj.previewAnimation, true);
    this.previewAutoPlay = use(obj.previewAutoPlay, false);
    this.previewAutoPlayInterval = use(obj.previewAutoPlayInterval, 2000);
    this.previewAutoPlayPauseOnHover = use(obj.previewAutoPlayPauseOnHover, false);
    this.previewInfinityMove = use(obj.previewInfinityMove, false);
    this.previewZoom = use(obj.previewZoom, false);
    this.previewZoomStep = use(obj.previewZoomStep, 0.1);
    this.previewZoomMax = use(obj.previewZoomMax, 2);
    this.previewZoomMin = use(obj.previewZoomMin, 0.5);
    this.previewRotate = use(obj.previewRotate, false);
    this.previewDownload = use(obj.previewDownload, false);
    this.previewCustom = use(obj.previewCustom, undefined);
    this.previewBullets = use(obj.previewBullets, false);
  }
}
