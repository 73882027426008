import { VehicleDataSource } from './vehicle.datasource';
import { TransportationTasksAddEditComponent } from '../../transport/transportation-tasks-add-edit/transportation-tasks-add-edit.component';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { KeycloakService } from '../../../../core/keycloak';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { TransportDetailViewDialogComponent } from '../../transport/transport-detail-view-dialog/transport-detail-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Modules } from 'app/shared/enums/modules.enum';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TransportDialogEventType } from '../enums/transport-dialog-event-type.enum';
import { TransportBoardDataSource } from './transport-board.datasource';
import { skip, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class TransportDetailViewService extends RoleAuthorityGuardsComponent {
  private transport: TransportView;

  constructor(
      protected authService: KeycloakService,
      private dialog: MatDialog,
      private vehicleDataSource: VehicleDataSource,
      private transportDataSource: TransportBoardDataSource,
  ) {
    super(authService);
    this.initListeners();
  }

  public openDetailView(transportId: string): void {
    if (!this.canOpenDetailViewDialog()) {
      return;
    }

    this.transportDataSource.currentTransport.pipe(skip(1), take(1), untilDestroyed(this))
    .subscribe(transport => this.openTransportTaskDetailView(transport));
    this.transportDataSource.changeCurrentTransport(transportId);
  }

  private openTransportTaskDetailView(transportTask: TransportView): void {
    this.dialog.open(TransportDetailViewDialogComponent, {data: transportTask, autoFocus: false})
    .afterClosed()
    .subscribe((result: TransportDialogEventType) => this.afterTaskDetailViewClose(result));
  }

  private afterTaskDetailViewClose(event: TransportDialogEventType): void {
    if (!!event) {
      switch (event) {
        case TransportDialogEventType.EDIT:
          this.openEditFormDialog();
          break;
        default:
          this.transportDataSource.updateListing();
          this.vehicleDataSource.updateListing();
      }
    }
  }

  private openEditFormDialog(): void {
    this.dialog.open(TransportationTasksAddEditComponent, {disableClose: true, data: this.transport})
    .afterClosed()
    .subscribe(({ status }) => {
      if (status === TransportDialogEventType.EDIT_SUCCEEDED) {
        this.transportDataSource.currentTransport.pipe(skip(1), take(1), untilDestroyed(this))
          .subscribe(transport => {
            this.updateListingAccordingTransportTask(transport);
            this.openTransportTaskDetailView(transport);
          });
        this.transportDataSource.changeCurrentTransport(this.transport.transportId);
        return;
      }

      this.openTransportTaskDetailView(this.transport);
    });
  }

  private updateListingAccordingTransportTask(transportTask: TransportView): void {
    if (transportTask.equipmentAssignmentDetails) {
      this.vehicleDataSource.refreshAssignmentIfNecessary(transportTask);
    } else {
      this.transportDataSource.updateListing();
    }
  }

  private canOpenDetailViewDialog(): boolean {
    return this.hasModule(Modules.TRANSPORT_TASK_MANAGEMENT)
        && this.hasAnyAuthority([Authorities.TRANSPORT_TASK_DISPOSITION, Authorities.TRANSPORT_TASK_MANAGE]);
  }

  private initListeners(): void {
    this.transportDataSource.currentTransport.pipe(untilDestroyed(this))
    .subscribe(transport => this.transport = transport);
  }

}
