import { Component, Input, OnInit } from '@angular/core';
import { MobileWizardSettingsService } from '../../../services/mobile-wizard-settings.service';
import { WizardConfiguration, } from '../../../contract/user-configuration/wizard-configuration';
import { WizardTabSettings } from '../../../wizard-tab-settings.interface';

@Component({
  selector: 'bh-mobile-wizard-settings',
  templateUrl: './mobile-wizard-settings-component.html',
  styleUrls: ['./mobile-wizard-settings-component.scss']
})
export class MobileWizardSettingsComponent implements OnInit {

  @Input() public settingsTitle: string;
  public wizardConfiguration: WizardConfiguration;
  public configKeys: string[];
  public show = false;
  @Input() private entryName: string;

  constructor(private mobileWizardSettingsService: MobileWizardSettingsService) {
    this.mobileWizardSettingsService.openSettings.subscribe((value) => {
      if (this.entryName === value) {
        this.getSettings();
        this.show = !this.show;
      }
    });
  }

  public getTabOptions(key: string): WizardTabSettings {
    return this.wizardConfiguration.tabs[key];
  }

  public ngOnInit(): void {
    this.getSettings();
  }

  public checkBoxChanged(): void {
    this.mobileWizardSettingsService.persistConfiguration(this.entryName, this.wizardConfiguration);
  }

  private getSettings(): void {
    this.wizardConfiguration = this.mobileWizardSettingsService.getWizardConfiguration(this.entryName);
    if (this.wizardConfiguration) {
      this.configKeys = Object.keys(this.wizardConfiguration.tabs);
    }
  }
}
