import { GuardedNavigableInputComponent } from '../../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { Directive, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../../shared/router-history';
import { switchMap } from 'rxjs/operators';
import { ViewEmployee } from '../../../../../../contract/view-employee.interface';
import { EmployeeManagementDatasource } from '../../../../../../shared/employee-management.datasource';
import { UserConfigurationService } from '../../../../../../../../shared/services/user-configuration.service';
import { DocumentLink } from '../../../../../../../../shared/contract/document-link.interface';
import { FileUtils } from '../../../../../../../../shared/fileUtils';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NEVER, of } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class BaseEmployeeViewAttachmentComponent extends GuardedNavigableInputComponent implements OnInit {

  public readonly fileName: string = 'fileName';
  public readonly fileDescription: string = 'fileDescription';
  public readonly isEditable = this.hasAuthority(Authorities.EMPLOYEE_MANAGE_ATTACHMENTS);
  public readonly isEditableDocsRenewal = this.hasAnyAuthority([
    Authorities.EMPLOYEE_MANAGE_QUALIFICATIONS,
    Authorities.EMPLOYEE_RENEW_QUALIFICATIONS
  ]);
  public readonly isVisibleDocsRenewal = this.isEditableDocsRenewal || this.hasAuthority(Authorities.EMPLOYEE_VIEW_QUALIFICATIONS);
  public readonly setDefaultEnabled = this.hasAnyAuthority([
    Authorities.EMPLOYEE_MANAGE,
    Authorities.EMPLOYEE_MANAGE_ATTACHMENTS
  ]);

  public employee: ViewEmployee;
  public images: DocumentLink[];
  public defaultImage: DocumentLink;
  public documents: DocumentLink[];
  public documentsRenewal: DocumentLink[];
  public documentsSortParameter: string = this.getDocumentsSortParameter();

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected routerHistory: RouterHistory,
                        protected employeeManagementStore: EmployeeManagementDatasource,
                        protected userConfigurationService: UserConfigurationService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.initListenerCurrentEmployee();
    this.initListenerDocuments();
    this.initListenerDocumentsRenewal();
  }

  public sortDocumentLinkList(documentList: DocumentLink[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.fileName?.localeCompare(b.fileName);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (a.fileDescription === '') {
          return 1;
        }
        if (b.fileDescription === '') {
          return -1;
        }
        return a.fileDescription?.localeCompare(b.fileDescription);
      });
    }
  }

  private initListenerCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewEmployee) => {
      if (res) {
        this.employee = res;
        this.getDocuments(res);
        this.getDocumentsRenewal(res);
        this.documentsSortParameter = this.getDocumentsSortParameter();
      }
    });
  }

  private initListenerDocuments(): void {
    this.employeeManagementStore.documents
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.sortDocumentLinkList(res, this.documentsSortParameter);
        const { documents, images } = FileUtils.splitDocumentLinks(res);
        this.documents = documents;
        this.images = images;
        this.defaultImage = this.images.find(doc => doc.documentKey === this.employee.defaultImage);
      });
  }

  private initListenerDocumentsRenewal(): void {
    this.employeeManagementStore.documentsRenewal
      .pipe(
        switchMap(res => this.isVisibleDocsRenewal ? of(res) : NEVER),
        untilDestroyed(this))
      .subscribe(res => {
        this.documentsRenewal = [];
        if (res) {
          this.documentsRenewal = res;
          this.sortDocumentLinkList(this.documentsRenewal, this.documentsSortParameter);
        }
      });
  }

  private getDocuments(employee: ViewEmployee): void {
    this.employeeManagementStore.getDocuments(employee?.employeeId);
  }

  private getDocumentsRenewal(employee: ViewEmployee): void {
    if (this.isVisibleDocsRenewal) {
      this.employeeManagementStore.getDocumentsRenewal(employee?.employeeId);
    }
  }

  private getDocumentsSortParameter(): string {
    return this.userConfigurationService?.getDocumentsSortConfiguration()?.sortParameter || this.fileName;
  }

}
