<div fxLayout="column" class="transfer-items-component-container">
  <div class="header" fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="header-container">
      <div *ngIf="removeAllAvailable" fxFlex="60px" class="clear-transfer-cart"
           (click)="clearItems()"
           [matTooltip]="'modules.disposition.bulkItemTransfer.discardTransferBasket'|translate"
           fxLayoutAlign="center center">
        <fa-icon [icon]="faTrashAlt"></fa-icon>
      </div>
      <div *ngIf="typeOfUseChanging else transferItemsTitle" class="title" fxLayoutAlign="center center">
        {{'modules.disposition.assetChangeTypeOfUse.assetsPackage'|translate}}
      </div>
      <ng-template #transferItemsTitle>
        <div class="title" fxLayoutAlign="center center">
          {{'modules.disposition.bulkItemTransfer.clearTransferBasket'|translate}}
        </div>
      </ng-template>

      <button *ngIf="showSelectAll" (click)="selectAll()" mat-raised-button color="primary">
        {{ getButtonText() }}
      </button>
    </div>
    <div *ngIf="!typeOfUseChanging"
         fxFlex="60px"
         class="{{className}}"
         [ngClass]="{'create-transfer-cart-disabled': isInvalid()}"
         fxLayoutAlign="center center"
         (click)="submit()"
         [matTooltip]="showEquipmentMessage ? ('modules.disposition.bulkItemTransfer.equipmentsToConsumedInvalid' | translate) : null">
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </div>
  </div>

  <div class="search-container" fxFlex="60px" fxLayoutAlign="start center" fxLayoutGap="20px">
    <input  class="search-input"
            (input)="applyFilter($event.target.value)"
            [placeholder]="'general.search'|translate"/>
  </div>

  <div *ngIf="isLoadingItems" fxLayoutAlign="center center" class="spinner-container">
    <mat-spinner [strokeWidth]="2" [diameter]="100"></mat-spinner>
  </div>

  <div class="list-container" fxLayout="column" fxFlex="100" *ngIf="!isLoadingItems">
    <div *ngIf="typeItemsExist('EQUIPMENT')" class="label-div">
      <label>{{'general.equipm.pl' | translate}}</label>
    </div>
    <div *ngFor="let control of itemsFormArray.controls; let i = index">
      <bh-transfer-item *ngIf="transferItems[i].transferItemType === 'EQUIPMENT' &&
       !transferItems[i].hiddenOption &&
       (!transferItems[i].equipmentContainerId || (transferItems[i].equipmentContainerId && isActiveContainer(transferItems[i].equipmentContainerId)))"
                        [item]="transferItems[i]"
                        [itemControl]="control"
                        [sourceLabel]="sourceLabel"
                        [targetLabel]="targetLabel"
                        [removable]="itemsRemovable"
                        [hasChildren]="hasSubequipments(transferItems[i].transferId)"
                        [isActiveContainer]="transferItems[i].isActiveContainer"
                        [typeOfUseChanging]="typeOfUseChanging"
                        [validatedItem]="getTransferItemWithStatus(transferItems[i].id)"
                        (changeValue)="changeValue(i)"
                        (activateContainer)="activateEquipment(transferItems[i])"
                        (removed)="removeItem(i)"></bh-transfer-item>
    </div>
    <div *ngIf="typeItemsExist('BULKITEM')" class="label-div">
      <label>{{'general.bulkItems' | translate}}</label>
    </div>
    <div *ngFor="let control of itemsFormArray.controls; let i = index">
      <bh-transfer-item *ngIf="transferItems[i].transferItemType === 'BULKITEM' && !transferItems[i].hiddenOption"
                        [item]="transferItems[i]"
                        [itemControl]="control"
                        [sourceLabel]="sourceLabel"
                        [targetLabel]="targetLabel"
                        [removable]="itemsRemovable"
                        [typeOfUseChanging]="typeOfUseChanging"
                        [validatedItem]="getTransferItemWithStatus(transferItems[i].id)"
                        (removed)="removeItem(i)"
                        (changeValue)="changeValue(i)"></bh-transfer-item>
    </div>
  </div>
</div>
