<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{'modules.telematic.telematicUnitLiveData.title'|translate}}</h2>

  <div mat-dialog-content>
    <div [formGroup]="unitForm" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
        <div fxFlex="auto" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.imei'|translate}}</mat-label>
                <input matInput formControlName="imei">
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.customerSerialNumber'|translate}}</mat-label>
                  <input matInput formControlName="customerSerialNumber">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.lastUnitDate'|translate}}</mat-label>
                <input matInput formControlName="lastUnitDate">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.digitalInput1'|translate}}</mat-label>
                <input matInput formControlName="digitalInput1">
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.lastGpsDate'|translate}}</mat-label>
                  <input matInput formControlName="lastGpsDate">
                </mat-form-field>
              </div>
            </div>
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.satellites'|translate}}</mat-label>
                <input matInput formControlName="satellites">
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.lastOdometerInKmDate'|translate}}</mat-label>
                  <input matInput formControlName="lastOdometerInKmDate">
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.distanceOdometerInKm'|translate}}</mat-label>
                  <input matInput formControlName="distanceOdometerInKm">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.lastOperatingHoursDate'|translate}}</mat-label>
                <input matInput formControlName="lastOperatingHoursDate">
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.cumulativeOperatingHours'|translate}}</mat-label>
                  <input matInput formControlName="cumulativeOperatingHours">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.batteryVoltage'|translate}}</mat-label>
                  <input matInput formControlName="batteryVoltage">
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.powerSupplyVoltage'|translate}}</mat-label>
                  <input matInput formControlName="powerSupplyVoltage">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.analogInput1'|translate}}</mat-label>
                  <input matInput formControlName="analogInput1">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="auto" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.equipmentName'|translate}}</mat-label>
                <input matInput formControlName="equipmentName">
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div class="field-wrapper" fxFlex="auto">
                <mat-form-field>
                  <mat-label>{{'modules.telematic.telematicUnitLiveData.manufacturerSerialNumber'|translate}}</mat-label>
                  <input matInput formControlName="manufacturerSerialNumber">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.digitalInput2'|translate}}</mat-label>
                <input matInput formControlName="digitalInput2">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.digitalInput3'|translate}}</mat-label>
                <input matInput formControlName="digitalInput3">
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.gnssStatus'|translate}}</mat-label>
                <input matInput formControlName="gnssStatus">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitLiveData.gsmSignal'|translate}}</mat-label>
                <input matInput formControlName="gsmSignal">
              </mat-form-field>
            </div>
          </div>


          <bh-tracker-battery-status class="battery-status"
                                     [batteryStatus]=batteryStatus></bh-tracker-battery-status>

          <bh-map-locations
            fxFlex="200px"
            fxLayout="column"
            class="teltonika__map-address"
            [locations]="[liveData]">
          </bh-map-locations>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <button mat-raised-button class="refresh-data-button"
              (click)="fetchClearData()"
              [disabled]="isLoading()"
              [matTooltip]="getRefreshButtonTooltip()">
        <mat-icon *ngIf="isReady()">cached</mat-icon>
        <mat-icon *ngIf="isLoading()" class="loading">cached</mat-icon>
        <mat-icon *ngIf="isError()" class="error">warning</mat-icon>
      </button>
      <button mat-raised-button [mat-dialog-close]="true">{{'general.buttons.close'|translate}}</button>
    </div>
  </div>
</div>
