import { Languages } from 'app/shared/enums/languages.enum';
import * as moment from 'moment';
import { Month } from '../../../../shared/contract/month';

export function months(locale: Languages | string = Languages.DEFAULT): Month[] {
  moment.locale(locale);
  return moment.months().map(month => {
    return {
      name: month,
      value: moment().month(month).format('MM')
    }
  });
}
