import { TranslatableString } from 'app/shared/contract/translatable-string.type';
import { Languages } from 'app/shared/enums/languages.enum';

export enum AdditionalFieldType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
}

export enum AdditionalFieldEntity {
  EQUIPMENT = 'EQUIPMENT'
}

export enum AdditionalFieldCategory {
  ADDITIONAL_DATA = 'ADDITIONAL_DATA',
  GENERAL_DATA = 'GENERAL_DATA'
}

export type AdditionalFieldSelectOption = {
  optionKey: string;
  optionName: TranslatableString;
}

export type AdditionalField = {
  additionalFieldId: string;
  additionalFieldKey: string;
  additionalFieldName: { [key in Languages]?: string };
  additionalFieldType: AdditionalFieldType;
  additionalFieldEntity: AdditionalFieldEntity;
  additionalFieldCategory: AdditionalFieldCategory;
  additionalFieldUnit?: string;
  additionalFieldDefaultValue?: string;
  additionalFieldSelectOptions?: AdditionalFieldSelectOption[];
};

export type AdditionalFieldValue = AdditionalField & {
  value: string;
}
