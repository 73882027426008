import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { faHistory, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { LocalUserStorageService } from 'app/shared/services/local-user-storage.service';
import { MaintenanceTask } from '../../../shared/maintenance-task';

@Component({
  selector: 'bh-maintenance-task-completed-view-detail',
  templateUrl: './maintenance-task-completed-view-detail.component.html',
  styleUrls: ['./maintenance-task-completed-view-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskCompletedViewDetailComponent implements AfterViewInit {
  @ViewChild('detailTabGroup', { static: true })
  public detailTabGroup: MatTabGroup;

  @Input() public task: MaintenanceTask;

  public readonly faInfoCircle = faInfoCircle;
  public readonly faHistory = faHistory;
  public readonly reminderCategory = MaintenanceCategory.REMINDER;
  private readonly localStorageKey = 'maintenance-completed-details-tab';

  constructor(private localStorageService: LocalUserStorageService) { }

  public ngAfterViewInit(): void {
    this.detailTabGroup.selectedIndex = this.getLastSelectedTab();
  }

  public updateTab(event: MatTabChangeEvent): void {
    this.localStorageService.setUserValue(this.localStorageKey, `${event.index}`);
  }

  private getLastSelectedTab(): number {
    const selectedTab = Number(this.localStorageService.getUserValue(this.localStorageKey));
    return selectedTab >= 0 && selectedTab <= this.detailTabGroup._tabs.length ? selectedTab : 0;
  }
}
