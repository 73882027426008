import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IOptionsList } from 'app/shared/contract/selection-tree-options.interface';
import { changeParentOption } from 'app/shared/services/selection-tree.converter';

@Component({
  selector: 'bh-selection-tree',
  templateUrl: './selection-tree.component.html',
  styleUrls: ['./selection-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionTreeComponent implements OnInit, OnDestroy {
  @Input() public isWorkWithCategories = false;
  @Input() public optionsList: IOptionsList[] = [];
  @Output() public finalSelectedOptionsList = new EventEmitter<IOptionsList[]>();

  @ViewChild('virtualScroll', {static: true})
  public virtualScrollViewport: CdkVirtualScrollViewport;

  private keyEventListener: EventListener;

  constructor(private cdr: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.keyEventListener = (event: KeyboardEvent) => {
      if (this.isCtrlPlusACombination(event)) {
        event.preventDefault();
        this.changeSelectedStatusForList(this.optionsList);
        this.cdr.detectChanges();
      }
    }
    document.addEventListener('keydown', this.keyEventListener);
  }

  public ngOnDestroy(): void {
    document.removeEventListener('keydown', this.keyEventListener)
  }

  public getSelectedOptions(): void {
    this.finalSelectedOptionsList.emit(this.optionsList.filter(el => el.selected));
  }

  public open(): void {
    setTimeout(() => {
      this.virtualScrollViewport.scrollToIndex(1);
      this.virtualScrollViewport.scrollToIndex(0);
    });
  }

  public toggleCheckbox(event: UIEvent, option: IOptionsList): void {
    if (!option.item.disabled) {
      let searchArray = this.optionsList.slice(option.index + 1);
      let lastChildOptionIndex = this.getLastChildrenIndex(searchArray, option.offset);
      let changedOptionsList = [option, ...searchArray.slice(0, lastChildOptionIndex)];

      option.selected
        ? this.changeSelectedStatus(changedOptionsList, false)
        : this.changeSelectedStatus(changedOptionsList, true);
    }

    if (this.isWorkWithCategories && !option.item.disabled) {
      changeParentOption(this.optionsList, option);
    }
  }

  private changeSelectedStatus(options: IOptionsList[], status: boolean): void {
    options.forEach(el => {
      if (!el.item.disabled) {
        el.selected = status;
        el.indeterminate = false;
      }
    });
  }

  private getLastChildrenIndex(list: IOptionsList[], offset: number): number {
    let lastChildrenIndex = list.findIndex(el => el.offset <= offset);
    return lastChildrenIndex !== -1 ? lastChildrenIndex : list.length;
  }

  private changeSelectedStatusForList(list: IOptionsList[]): void {
    list.every(el => el.selected === true)
      ? this.changeSelectedStatus(list, false)
      : this.changeSelectedStatus(list, true);
  }

  private isCtrlPlusACombination(event: KeyboardEvent): boolean {
    return event.ctrlKey && event.code === 'KeyA';
  }
}
