<mat-card id="widget">
  <mat-card-header class="widget-header">
    <mat-card-title>
      <div class="widget-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <span class="widget-title__text">{{widget.title}}</span>
        <mat-icon *ngIf="widget.titleIcon?.content as icon"
          class="widget-title__icon"
          [fontIcon]="icon"
          [matTooltipDisabled]="!widget.titleIcon?.tooltip"
          [matTooltip]="widget.titleIcon?.tooltip"
          (click)="titleIconClick.emit()">
        </mat-icon>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-accordion>
    <mat-expansion-panel [expanded]="showEdit" class="action-panel">
      <button id="edit" mat-icon-button (click)="toggleEditMode(configuration)" aria-label="Editieren">
        <mat-icon>edit</mat-icon>
      </button>
      <button id="remove" mat-icon-button (mousedown)="deleteWidget($event)" aria-label="Löschen">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-card-content class="widget-content">
    <ng-content select="[widget-content]"></ng-content>
  </mat-card-content>
  <ng-template #configuration>
    <section [formGroup]="form">
      <bh-basic-widget-configuration-dialog (opened)="openConfiguration($event)"
                                            (closed)="closeConfiguration($event)">
        <mat-form-field>
          <mat-label>{{'modules.dashboard.widget.placeholderTitle'|translate}}</mat-label>
          <input
            matInput
            bhRemoveWhitespaces
            formControlName="title">
        </mat-form-field>
        <ng-content select="[widget-configuration]"></ng-content>
      </bh-basic-widget-configuration-dialog>
    </section>
  </ng-template>
</mat-card>
