<mat-card fxLayout="column" fxFlex="0 0 100%">
  <div class="header">
    <div class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <fa-icon class="title__icon" [icon]="faPenField"></fa-icon>
      <span>{{'modules.navigation.sidebar.administrationMenu.telematicUnitProfiles'|translate}}</span>
    </div>
  </div>

  <div class="telematic-unit-profiles-navbar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div fxFlex="50">
      {{'modules.telematic.telematicUnitProfiles.oyster3ble'|translate}}
    </div>
  </div>

  <ng-container *ngIf="!(isLoadingLanguages | async)">
    <mat-tab-group #tagGroup mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
      <mat-tab *ngFor="let language of availableLanguages">
        <ng-template mat-tab-label>
          {{ language | uppercase }}
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="search-results" fxFlex>
      <div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <div class="data-table-wrapper" fxFlex>
          <ng-container [ngTemplateOutlet]="profileTable"
                        [ngTemplateOutletContext]="{language:availableLanguages[tagGroup.selectedIndex]}">
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #profileTable let-language="language">
    <mat-table [dataSource]="digitalMatterProfileDatasource">
      <ng-container cdkColumnDef="profileName">
        <mat-header-cell *cdkHeaderCellDef>
          {{'modules.telematic.telematicUnitProfiles.profileName'|translate}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.translations?.get(language)?.displayName ?? ' - ' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="profileDescription">
        <mat-header-cell *cdkHeaderCellDef>
          {{'modules.telematic.telematicUnitProfiles.profileDescription'|translate}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.translations?.get(language)?.description ?? ' - ' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="profileTemplateId">
        <mat-header-cell *cdkHeaderCellDef>
          {{'modules.telematic.telematicUnitProfiles.profileTemplateId'|translate}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.templateId }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="profileFieldEdit">
        <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <button mat-button
                  [matTooltip]="'general.buttons.edit'|translate"
                  (click)="openEditProfileDialog(row, language)">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </ng-template>
</mat-card>
