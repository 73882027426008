<div class="scrollable">
  <bh-title [text]="'general.generalData'|translate"></bh-title>
  <div *ngIf="project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP" class="label-field">
    <bh-label-chips (labelAdded)="addLabel($event)"
                    (labelDeleted)="deleteLabel($event)"
                    [customerLabels]="customerLabels | async"
                    [hasAuthority]="canEditLabel"
                    [labels]="projectLabels | async ">
    </bh-label-chips>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
    <div fxLayout="column" fxFlex="60" fxLayoutAlign="start stretch">
      <mat-form-field>
        <mat-label>{{'general.projectNumber'|translate|uppercase}}</mat-label>
        <input matInput value="{{ project?.projectNumber }}" readonly>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-form-field fxFlex="20">
          <mat-label>{{'modules.disposition.base.projectType'|translate|uppercase}}</mat-label>
          <input matInput value="{{ project?.projectType }}" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="40">
          <mat-label>{{'general.costCenter'|translate|uppercase}}</mat-label>
          <input matInput value="{{ project?.projectCostCenter | defaultValue: '-'}}" readonly>
        </mat-form-field>

        <mat-form-field *ngIf="project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP" fxFlex="40">
          <mat-label>{{'general.scanCode'|translate|uppercase}}</mat-label>
          <input matInput value="{{ project?.scanCode | defaultValue: '-'}}" readonly>
          <mat-icon
            *ngIf="canEditScancode"
            matSuffix
            class="editLabelIcon"
            [matTooltip]="'modules.disposition.projectViewDetail.editScanCode'|translate"
            (click)="editScanCode()">
            mode_edit
          </mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-form-field fxFlex="20">
          <mat-label>{{'general.labels.start'|translate|uppercase}}</mat-label>
          <input matInput value="{{ project?.projectStart | date:'dd.MM.yyyy' | defaultValue: '-' }}" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="20">
          <mat-label>{{'general.labels.end'|translate|uppercase}}</mat-label>
          <input matInput value="{{ project?.projectEnd | date:'dd.MM.yyyy' | defaultValue: '-' }}" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="60">
          <mat-label>{{'modules.disposition.projectViewDetail.assignedTo'|translate}}</mat-label>
          <input matInput value="{{ project?.organisation.name | defaultValue: '-' }}" readonly>
        </mat-form-field>
      </div>

      <div class="subsection">
        <bh-subtitle [text]="'general.location.s'|translate"></bh-subtitle>

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
          <mat-form-field fxFlex="25">
            <mat-label>{{'general.address.zipcode'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.projectAddress?.postalCode | defaultValue: '-' }}" readonly>
          </mat-form-field>

          <mat-form-field fxFlex="25">
            <mat-label>{{'general.address.city'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.projectAddress?.city | defaultValue: '-' }}" readonly>
          </mat-form-field>

          <mat-form-field fxFlex="25">
            <mat-label>{{'general.address.street'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.projectAddress?.street | defaultValue: '-' }}" readonly>
          </mat-form-field>

          <mat-form-field fxFlex="25">
            <mat-label>{{'general.address.number'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.projectAddress?.streetNumber | defaultValue: '-' }}" readonly>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
          <mat-form-field fxFlex="50">
            <mat-label>{{'general.location.latitude'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.location?.lat | defaultValue: '-' }}" readonly>
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <mat-label>{{'general.location.longitude'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.location?.lon | defaultValue: '-' }}" readonly>
          </mat-form-field>
        </div>
      </div>

      <div class="subsection" *ngIf="project?.chargeDate">
        <bh-subtitle [text]="'modules.disposition.base.billing'|translate"></bh-subtitle>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
          <mat-form-field fxFlex="25">
            <mat-label>{{'general.date'|translate|uppercase}}</mat-label>
            <input matInput value="{{ project?.chargeDate | date: 'dd.MM.yyyy' | defaultValue: '-' }}" readonly>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP"
           class="subsection" fxLayout="column">
        <bh-subtitle [text]="'general.responsible'|translate"></bh-subtitle>
        <bh-project-employee-assignee-cards></bh-project-employee-assignee-cards>
      </div>
    </div>

    <div fxLayout="column" fxFlex="40">
      <bh-map-locations
        class="project-map"
        [locations]="[project] | mapMarkerConvertProjectView">
      </bh-map-locations>
      <div class="project-map-jump">
        <bh-map-jump-google
          *ngIf="[project] | mapMarkerConvertProjectView | isValidLocations"
          [lat]="this.project.location.lat" [lon]="this.project.location.lon">
        </bh-map-jump-google>

      </div>
    </div>
  </div>


  <div *ngIf="project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP"
       class="project-description subsection">
    <bh-subtitle [text]="'general.description'|translate"></bh-subtitle>

    <bh-description *ngIf="project"
                    [maxLength]="10000"
                    [(description)]="project.description"
                    [isEditingAllowed]="canEditProject"
                    [isActive]="!projectCheckerService.isProjectFinished(project)"
                    (descriptionSave)="saveDescription($event)"></bh-description>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="!project">
    {{'general.labels.notFound'|translate:{value: 'general.project.s'|translate} }}
  </div>
</div>
