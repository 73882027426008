import { LanguageService } from 'app/shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import * as moment from 'moment';
import { ViewProject } from '../../../../contract/view-project.interface';
import { filter } from 'rxjs/operators';
import { isDefined } from '../../../../../../shared/utils';
import { RouterHistory } from '../../../../../../shared/router-history';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AmountsService } from '../../../../shared/amounts.service';
import { ProjectStructureType } from '../../../../shared/enums/project-structure-type';
import { Authorities } from 'app/shared/enums/authorities.enum';

@UntilDestroy()
@Directive()
export abstract class BaseProjectViewComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public finishTooltip = '';
  public deleteDisabled = false;
  public finishDisabled = true;
  public hasAmounts = false;
  public project: ViewProject;
  protected hasTransferHistory = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected amountsService: AmountsService,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToCurrentProject();
  }

  public ngOnDestroy(): void {
  }

  private subscribeToCurrentProject(): void {
    const projectId = this.route.snapshot.params['id'];
    this.projectStore.setCurrentProject(projectId);
    this.projectStore.currentProject
      .pipe(untilDestroyed(this),
        filter(isDefined))
      .subscribe((currentProject: ViewProject) => {
        this.project = currentProject;
        const urlSegments: string[] = this.router.url.split('/');
        if (
          urlSegments[urlSegments.length - 1] !== 'general'
          && this.project?.projectStructureType === ProjectStructureType.PROJECT_GROUP
        ) {
          this.setTab('general');
          this.router.navigate(urlSegments.slice(1, urlSegments.length - 1));
        }
        if (!this.hasModule(this.modules.PROJECT_GROUP_MANAGEMENT) ||
          this.project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP) {
          this.projectStore.updateTransferHistory(this.project.projectId);
        }
        this.amountsService.hasAmounts(currentProject.projectId)
          .subscribe(res => {
            this.hasAmounts = res;
            this.hasCompleteProjectButtonDisabled();
            this.deleteDisabled = (currentProject.projectStart && moment().isAfter(moment(currentProject.projectStart))) || this.hasAmounts;
            this.setFinishTooltip();
          });
      });
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public setTab(tab: string): void {
    this.projectStore.currentViewTab = tab;
  }

  public hasCompleteProjectPermission(): boolean {
    return this.authService.hasAuthority(Authorities.PROJECT_UPDATE) || this.authService.hasAuthority(Authorities.PROJECT_UPDATE_STATUS);
  }

  private hasCompleteProjectButtonDisabled(): void {
    this.finishDisabled = !this.hasCompleteProjectPermission() || this.projectHasBeenAlreadyStarted() || this.hasAmounts;
  }

  private projectHasBeenAlreadyStarted(): boolean {
    return this.project.projectStart && moment().isSameOrBefore(moment(this.project.projectStart), 'day');
  }

  private setFinishTooltip(): void {
    if (!this.hasCompleteProjectPermission()) {
      this.finishTooltip = this.translate('modules.disposition.projectView.finishTooltipHasNoPermissions');
      return;
    }

    this.finishTooltip = this.translate(this.hasAmounts
                                          ? 'modules.disposition.projectView.finishTooltipHasAmount'
                                          : 'modules.disposition.projectView.finishTooltipHasNotAmount');
  }
}
