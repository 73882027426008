<mat-menu>
  <ng-container *ngFor="let profile of allDigitalMatterProfiles">
    <div mat-menu-item
         [disabled]="selectedDigitalMatterProfile.profileId === profile.profileId"
         (click)="onStatusChanged(profile)">

      <div class="title">
        <div>{{ profile.translations.get(this.selectedLanguage).displayName }}</div>
        <div *ngIf="selectedDigitalMatterProfile.profileId === profile.profileId">

          <ng-container *ngIf="status !== DigitalMatterProfileDeploymentState.DEPLOYMENT_CANCELED; else errorIcon">
            <mat-icon class="icon-{{ isCurrentlyUpdating() ? 'warn' : 'ok' }}">
              {{ isCurrentlyUpdating() ? 'schedule' : 'check' }}
            </mat-icon>
          </ng-container>

          <ng-template #errorIcon>
            <mat-icon class="icon-cancel">warning</mat-icon>
          </ng-template>
        </div>
      </div>

      <div class="description">{{ profile.translations.get(this.selectedLanguage).description }}</div>

    </div>
  </ng-container>
</mat-menu>
