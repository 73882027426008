import { KeycloakService } from '../../core/keycloak';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';

export class UserAdfsSyncService {

  private static sendSyncRequest(email: string): Promise<string> {
    return new Promise((resolve, reject) => {

      const token = localStorage.getItem('token');
      const options = {
        method: 'POST',
        url: environment.APP_USERROLE_SERVICE_BASE_URL + '/api/v1/users/adfs-sync',
        headers: {
          'Accept': 'text/html',
          'Authorization': 'Bearer ' + token
        },
        body: email
      };

      let xhr = new XMLHttpRequest();
      xhr.open(options.method || 'GET', options.url);
      if (options.headers) {
        Object.keys(options.headers).forEach(key => {
          xhr.setRequestHeader(key, options.headers[key]);
        });
      }
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.responseText);
        }
      };
      xhr.onerror = () => reject(xhr.responseText);
      xhr.send(options.body);
    });
  }

  public static async checkUserCreated(retry: number, wait: number) {
    return new Promise<any>(async (resolve, reject) => {
      let cnt = 0;
      let userId: string;
      do {
        if (cnt > 0) {
          await new Promise(r => setTimeout(r, wait));
        }
        await KeycloakService.refreshTokenForced();
        userId = KeycloakService.getUser().userId;
      } while (cnt++ < retry && !userId);

      userId ? resolve(userId) : reject('token timeout');
    });
  }

  public static async sync(email: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {

        if (!navigator.onLine) {
          resolve(null);
          return;
        }

        await this.sendSyncRequest(email);

        await this.checkUserCreated(5, 3000);

        resolve(KeycloakService.getUser());

      } catch (e) {
        reject(e);
      }
    });
  }

}
