import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ViewManufacturer} from '../../contract/view-manufacturer.interface';
import {ManufacturerService} from '../../shared/manufacturer.service';
import { UpdateManufacturerCommand } from '../../contract/update-manufacturer-command';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';

@Component({
  selector: 'bh-manufacturer-edit',
  templateUrl: 'manufacturer-edit.component.html',
  styleUrls: ['manufacturer-edit.component.scss']
})
export class ManufacturerEditComponent extends GuardedNavigableInputComponent implements OnInit {

  public manufacturerEditForm: UntypedFormGroup;
  public manufacturer: ViewManufacturer;

  constructor(private formBuilder: UntypedFormBuilder,
              private manufacturerService: ManufacturerService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.buildForm();
    this.route.params.subscribe(params => {
      const manufacturerId = params['id'];
      this.manufacturerService.getManufacturer(manufacturerId).subscribe(res => {
        this.manufacturer = res;
        this.manufacturerEditForm.patchValue({manufacturerName: res.manufacturerName});
        this.manufacturerEditForm.patchValue({manufacturerAbbreviation: res.manufacturerAbbreviation});
        this.manufacturerEditForm.patchValue({manufacturerMakerCode: res.manufacturerMakerCode});
      });
    });
  }

  public navigateBack(): void {
    this.goBack('/manufacturers/list');
  }

  private buildForm() {
    this.manufacturerEditForm = this.formBuilder.group({
      manufacturerName: ['', [<any>Validators.required]],
      manufacturerAbbreviation: [''],
      manufacturerMakerCode: ['']
    });
  }

  get manufacturerName() {
    return this.manufacturerEditForm.get('manufacturerName');
  }

  public save(): void {
    if (this.isValid()) {
      let formValue = this.manufacturerEditForm.value;
      let cmd: UpdateManufacturerCommand = new UpdateManufacturerCommand();
      cmd.manufacturerId = this.manufacturer.manufacturerId;
      cmd.manufacturerName = formValue.manufacturerName;
      cmd.manufacturerAbbreviation = formValue.manufacturerAbbreviation;
      cmd.manufacturerMakerCode = formValue.manufacturerMakerCode;

      this.manufacturerService.updateManufacturer(cmd).subscribe(
        () => {
            this.navigateAfterSubmit();
          },
        (error: HttpErrorResponse) => {
            console.log('update manufacturer error: ', error);
          }
      );
    }
  }

  public isValid(): boolean {
    return this.manufacturerEditForm.valid;
  }
}
