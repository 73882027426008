import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { UserStatus } from 'app/shared/enums/user-status.enum';

@Pipe({
  name: 'ChatUserStatus'
})
export class ChatUserStatusPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(status: UserStatus): string {
    switch (status) {
      case UserStatus.INACTIVE:
        return this.translate('shared.pipes.chatUserStatus.deactivated');
      case UserStatus.DELETED:
        return this.translate('shared.pipes.chatUserStatus.deletedUser');
      default:
        return '';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
