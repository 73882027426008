import {
  OnInit,
  Component,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseEquipmentDispositionFormItem } from '../base/form-item/base-disposition-form-item.component';

@UntilDestroy()
@Component({
  selector: 'bh-disposition-form-item',
  templateUrl: './disposition-form-item.component.html',
  styleUrls: ['./disposition-form-item.component.scss']
})
export class DispositionFormItemComponent extends BaseEquipmentDispositionFormItem implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
