import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { VehicleSearch } from '../../contracts/transport/vehicle-search.interface';


export class VehicleChecker {
  public static isVehicleStatusAssignable(vehicle: VehicleSearch): boolean {
    return !this.isVehicleNotOperational(vehicle) && !this.isVehicleNotAvailable(vehicle);
  }

  private static isVehicleNotAvailable(vehicle: VehicleSearch): boolean {
    return vehicle?.status?.category === EquipmentStatusCategory.NOT_AVAILABLE;
  }

  private static isVehicleNotOperational(vehicle: VehicleSearch): boolean {
    return vehicle?.status?.category === EquipmentStatusCategory.NOT_OPERATIONAL;
  }
}
