<div class="dialog-wrapper finish-project-dialog">
  <h1 mat-dialog-title>{{'modules.disposition.projectFinish.completeProject'|translate}}</h1>

  <div mat-dialog-content>
    <form [formGroup]="projectFinishForm" novalidate fxLayout="row">
      <mat-form-field>
        <input matInput
               required
               [min]="min"
               [max]="max"
               [matDatepicker]="datePicker"
               placeholder="{{'general.buttons.complete'|translate}} {{'general.on'|translate}}"
               formControlName="finishDate" autocomplete="off"
               tabindex="-1">

        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="finishDate.hasError('required')">
          {{'shared.validation.required'|translate:{value: 'general.date'|translate} }}
        </mat-error>
        <mat-error *ngIf="finishDate.hasError('matDatepickerMin')">
          {{'modules.disposition.projectFinish.dateBeforeStartProject'|translate}}
        </mat-error>
        <mat-error *ngIf="finishDate.hasError('matDatepickerMax')">
          {{'modules.disposition.projectFinish.dateInFuture'|translate}}
        </mat-error>
      </mat-form-field>
    </form>
    <div class="finish-project-dialog-warning" fxLayout="row" fxLayoutAlign="start center">
      <p fxFlex="25" fxLayout="row" fxLayoutAlign="center center"><mat-icon style="cursor:default">warning</mat-icon></p>
      <p fxFlex="75">
        {{'modules.disposition.projectFinish.warningMessage'|translate}}
      </p>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <div class="flex-container">
      <button type="button" mat-button color="primary" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
      <button type="button" color="primary" mat-button
            (click)="finishProject()"
            [disabled]="!projectFinishForm.valid">
            {{'modules.disposition.projectView.completeProject'|translate}}
      </button>
    </div>
  </div>
</div>
