<div class="dialog-wrapper add-edit-additional-field">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>
    <span *ngIf="isEdit">{{ 'modules.fieldManagement.additionalFieldManagement.editField' | translate }}</span>
    <span *ngIf="!isEdit">{{ 'modules.fieldManagement.additionalFieldManagement.addField' | translate }}</span>
  </h1>

  <div mat-dialog-content>
    <form [formGroup]="additionalFieldForm" novalidate>
      <div fxLayout="column" fxLayoutAlign="start stretch">
        <div>
          <p>{{ 'modules.fieldManagement.additionalFieldManagement.nameSection' | translate }}</p>
          <div class="form-block">
            <bh-input formControlName="additionalFieldKey"
                      [label]="'modules.fieldManagement.additionalFieldManagement.fieldKey' | translate"
                      [errorMessages]="{taken: ('modules.fieldManagement.additionalFieldManagement.fieldKeyInUse' | translate)}"
                      (focusout)="copyValueIntoDefaultLanguage()">
            </bh-input>

            <bh-input *ngFor="let fieldName of fieldNamesFormControl.controls; let i = index"
                      [formControl]="fieldName.get('name')"
                      label="{{ 'modules.fieldManagement.additionalFieldManagement.fieldName' | translate }} ({{ fieldName.get('language').value }})">
              <bh-icon *ngIf="i > 0" ospSuffix
                       (click)="deleteFieldName(i)"
                       [matTooltip]="'general.buttons.delete'|translate"
                       icon="delete"></bh-icon>
            </bh-input>

            <bh-button-flat *ngIf="(languageOptions | async).length !== 0"
                            class="add-item-button"
                            [matMenuTriggerFor]="menu">
              <bh-icon icon="add_circle"></bh-icon>
              {{ 'modules.fieldManagement.additionalFieldManagement.addTranslationButton' | translate }}
            </bh-button-flat>
            <mat-menu #menu="matMenu">
              <button *ngFor="let language of languageOptions | async" mat-menu-item
                      (click)="addFieldName(language)">
                {{ language }}
              </button>
            </mat-menu>

          </div>
        </div>

        <div>
          <p>{{ 'modules.fieldManagement.additionalFieldManagement.settingsSection' | translate }}</p>
          <div class="form-block">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'modules.fieldManagement.additionalFieldManagement.fieldCategory' | translate }}</mat-label>
              <mat-select formControlName="additionalFieldCategory">
                <mat-option *ngFor="let fieldCategory of additionalFieldCategories" [value]="fieldCategory">
                  {{ fieldCategory | additionalFieldCategoryDisplay }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'modules.fieldManagement.additionalFieldManagement.dataType' | translate }}</mat-label>
              <mat-select formControlName="additionalFieldType"
                          (selectionChange)="onAdditionalFieldTypeChanged($event)">
                <mat-option *ngFor="let fieldType of additionalFieldTypes" [value]="fieldType">
                  {{ fieldType | additionalFieldTypeDisplay }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isVisibleUnitField()" appearance="fill">
              <mat-label>{{ 'modules.fieldManagement.additionalFieldManagement.fieldUnit' | translate }}</mat-label>
              <mat-select formControlName="additionalFieldUnit" disableOptionCentering="true">
                <mat-option>
                  <ngx-mat-select-search [formControl]="unitFilter"
                                         [placeholderLabel]="'general.labels.searchTerm'|translate"
                                         [noEntriesFoundLabel]="'general.labels.noSearchResults'|translate">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option> -</mat-option>
                <mat-option *ngFor="let unit of additionalFieldUnits" [value]="unit">{{ unit }}</mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf="isVisibleValueField()">
              <bh-input *ngIf="!isNumericValueField()"
                        formControlName="additionalFieldDefaultValue"
                        [label]="'modules.fieldManagement.additionalFieldManagement.fieldDefaultValue' | translate">
              </bh-input>

              <bh-input-number *ngIf="isNumericValueField()"
                               formControlName="additionalFieldDefaultValue"
                               [label]="'modules.fieldManagement.additionalFieldManagement.fieldDefaultValue' | translate">
              </bh-input-number>
            </ng-container>

            <ng-container *ngIf="isVisibleOptionsField()">
              <section *ngFor="
                let fieldOption of fieldOptionsFormControl.controls;
                let i = index;
                let count = count;"
                class="option-section">
                <div fxLayoutAlign="space-between center">
                  <p>{{ 'modules.fieldManagement.additionalFieldManagement.fieldOption' | translate }} #{{i + 1}}</p>
                  <div
                    [matTooltip]="'modules.fieldManagement.additionalFieldManagement.removeOptionProhibited' | translate"
                    [matTooltipDisabled]="fieldOption.controls.isNew.value">
                    <bh-button-flat color="warn"
                      [disabled]="count < 2 || !fieldOption.controls.isNew.value"
                      (click)="deleteFieldOption(i)">
                      <bh-icon icon="delete"></bh-icon>
                      {{ 'modules.fieldManagement.additionalFieldManagement.removeOption' | translate }}
                    </bh-button-flat>
                  </div>
                </div>
                <bh-input
                  label="{{'modules.fieldManagement.additionalFieldManagement.fieldOption' | translate}} -
                    {{'modules.fieldManagement.additionalFieldManagement.fieldKey' | translate | lowercase}}"
                  [formControl]="fieldOption.controls.optionKey"
                  [errorMessages]="{duplicates: ('shared.validation.duplicate' | translate)}"
                  (focusout)="copyOptionKeyIntoDefaultLanguage(fieldOption)"
                ></bh-input>
                <bh-translation-section
                  [defaultLanguage]="defaultLanguage"
                  [otherLanguages]="selectedLanguages | async"
                  [labels]="(selectedLanguages | async) | optionLabels"
                  [formControl]="fieldOption.controls.optionName"
                ></bh-translation-section>
              </section>
              <bh-button-flat class="add-item-button" (click)="addFieldOption()">
                <bh-icon icon="add_circle"></bh-icon>
                {{ 'modules.fieldManagement.additionalFieldManagement.addOptionButton' | translate }}
              </bh-button-flat>
            </ng-container>

          </div>
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button mat-dialog-close>{{ 'general.buttons.cancel'|translate }}</button>
    <button mat-raised-button color="primary"
            [disabled]="!additionalFieldForm.valid"
            (click)="save()">
      {{ 'general.buttons.save'|translate }}
    </button>
  </div>
</div>
