<div class="timeline-event-details">
  <h2 class="timeline-event-details__title">{{'modules.equipment.base.extendedInvoiceInfo'|translate}}</h2>
  <div class="timeline-event-details__content">
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.timeRequirement'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{getDuration()}}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.mileageCovered'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.drive | dimensionUnitConverter: 'km' }}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.callOutFee'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.callOutFee | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.allowance'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.allowance | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.materialCosts'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.materialCosts | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.deliveryAndFreightCosts'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.freightCharges | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.personnelCosts'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.personnelCosts | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.travelCosts'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.travelCosts | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.supplierServiceProvider'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{invoice?.serviceContractor }}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.serviceStaff'|translate}}</mat-label>
        <input matInput
              readonly
              value="{{ invoice?.serviceWorker }}">
      </mat-form-field>
    </div>
  </div>
</div>
