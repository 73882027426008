import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../shared/router-history';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTruck } from '@fortawesome/pro-duotone-svg-icons';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { debounceTime, delay, finalize, map, mergeMap, share, startWith } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateTransportTaskCommand } from '../../contracts/transport/create-transport-task.command';
import { TransportItem } from '../../../equipment/shared/transport-items.interface';
import { DatesService } from '../../../../shared/services/dates.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageService } from '../../../../shared/services/language.service';
import { AddressService } from '../../../../shared/services/address.service';
import { TransportService } from '../../shared/services/transport.service';
import { TransportTaskPriority } from '../../shared/enums/transport-task-priority.enum';
import { TransportItemTypeResolverPipe } from '../../shared/pipes/transport-item-type-resolver.pipe';
import { MatStepper } from '@angular/material/stepper';
import { AttachedDocument } from '../../../../shared/contract/attached-document.interface';
import { EnrichedAttachedDocument } from '../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { UploadTransportDocumentCommand } from '../../contracts/transport/upload-transport-document.command';
import { environment } from '../../../../../environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { BrowserName } from 'app/shared/enums/browser-name.enum';
import { getBrowserName, Utils } from 'app/shared/utils';
import { TransportationValidators } from '../../shared/validators/transportation-validators.class';
import { DateValidator } from 'app/shared/custom-validators/date.validator';
import * as moment from 'moment';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TransportTitleCount } from '../../contracts/transport/transport-title-count.interface';
import { BaseTransportTaskCommand } from '../../contracts/transport/base-transport-task.command';
import { UpdateTransportTaskCommand } from '../../contracts/transport/update-transport-task.command';
import { TransportDialogEventType } from '../../shared/enums/transport-dialog-event-type.enum';
import { TransportTaskChecker } from '../../shared/services/transport-task-checker.service';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { LabelValidator } from '../../../../shared/custom-validators/label.validator';
import { TransportListDatasource } from '../../shared/services/transport-list.datasource';
import { IconPayload } from 'app/shared/contract/icon-payload.interface';
import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';
import { TransportSelectionController } from './transport-selection-controller.class';


interface TransportationTasksFormValue {
  data: {
    title: string;
    transportationTaskType: string;
    description: string;
    dueDate: string;
    dueTime: string;
    priority: string;
    estimatedDuration: string;
  };
  relatedProject: string;
  startAddress: string;
  endAddress: string;
  file: string;
}

@UntilDestroy()
@Component({
  selector: 'bh-transportation-tasks-add-edit',
  templateUrl: './transportation-tasks-add-edit.component.html',
  styleUrls: ['./transportation-tasks-add-edit.component.scss']
})
export class TransportationTasksAddEditComponent extends GuardedNavigableInputComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) private stepper: MatStepper;
  @ViewChild('transportationTasksNgForm') private transportationTasksNgForm: NgForm;
  @ViewChild('inputDateRef', { static: true }) inputDateRef: ElementRef;

  public readonly isEditMode: boolean;
  public readonly isEditPlannableAndBeyond: boolean;
  public readonly minDate = moment('1900-01-02').toDate();
  public readonly maxDate = moment('9999-12-30').toDate();
  public readonly faTruck: IconDefinition = faTruck;
  public readonly fieldLimit = FieldLimit;
  public readonly relatedProjectStepName = 'related-project';
  public transportationTasksForm: UntypedFormGroup;
  public filteredTransportTypeOptions: string[];
  public filteredStartAddressOptions: TransportItem[] = [];
  public filteredEndAddressOptions: TransportItem[] = [];
  public filteredRelatedProjectOptions: TransportItem[] = [];
  public filteredTransportTitleOptions: TransportTitleCount[] = [];
  public transportationTaskTypeData: string[] = [];
  public priorityList = Object.keys(TransportTaskPriority);
  public isLoading = false;
  public transportLocation: TransportItem[];
  public documents: AttachedDocument[] = [];
  public isFirefoxBrowser = getBrowserName() === BrowserName.FIREFOX;
  public transportTask: TransportView;
  public isIncompatibleTransportType: Observable<boolean>;
  public canRevertTransportType: Observable<boolean>;

  private readonly defaultFormValue: TransportationTasksFormValue = {
    data: {
      title: '',
      transportationTaskType: '',
      description: '',
      dueDate: '',
      dueTime: '',
      priority: TransportTaskPriority.MEDIUM,
      estimatedDuration: ''
    },
    relatedProject: '',
    startAddress: '',
    endAddress: '',
    file: ''
  };
  public readonly mapInfoWindowClasses = ['gm-style-iw', 'gm-style-iw-c'];
  public selectionController = new TransportSelectionController();
  private uploadPending = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TransportView,
      protected authService: KeycloakService,
      protected router: Router,
      protected route: ActivatedRoute,
      protected routerHistory: RouterHistory,
      private dialogRef: MatDialogRef<TransportationTasksAddEditComponent>,
      private formBuilder: UntypedFormBuilder,
      private transportDatasource: TransportListDatasource,
      private transportTasksService: TransportService,
      private transportTaskResolver: TransportItemTypeResolverPipe,
      private languageService: LanguageService,
      private addressService: AddressService,
      private utils: Utils,
      private cdref: ChangeDetectorRef,
  ) {
    super(authService, router, route, routerHistory);
    this.transportTask = data;
    this.isEditMode = Boolean(this.transportTask);
    this.isEditPlannableAndBeyond = this.isEditMode ? TransportTaskChecker.isPlannableAndBeyond(this.transportTask) : false;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.loadFormData();
    this.fillForm();
    this.initTransportSelectionController();
    this.initFormChangesListener();
  }

  public revertTransportType($event: Event) {
    $event.stopPropagation();
    this.transportationTaskType.setValue(this.transportTask.transportType);
  }

  public get title(): AbstractControl {
    return this.dataFormField.get('title');
  }

  public get transportationTaskType(): AbstractControl {
    return this.dataFormField.get('transportationTaskType');
  }

  public get dueDate(): AbstractControl {
    return this.dataFormField.get('dueDate');
  }

  public get dueTime(): AbstractControl {
    return this.dataFormField.get('dueTime');
  }

  public get estimatedDuration(): AbstractControl {
    return this.dataFormField.get('estimatedDuration');
  }

  public get startAddress(): AbstractControl {
    return this.transportationTasksForm.get('startAddress');
  }

  public get endAddress(): AbstractControl {
    return this.transportationTasksForm.get('endAddress');
  }

  public get relatedProject(): AbstractControl {
    return this.transportationTasksForm.get('relatedProject');
  }
  public get dataFormField(): AbstractControl {
    return this.transportationTasksForm.get('data');
  }

  public trackByTransportItemId(index: number, item: TransportItem): string {
    return item.id;
  }

  private loadFormData(): void {
    this.getTaskTypes();
    this.getTransportLocations();
    this.updateFilteredTitleOptions();
  }

  private getTaskTypes(): void {
    this.transportTasksService.getTransportationTasksType()
    .pipe(untilDestroyed(this))
    .subscribe((types: string[]) => {
      this.transportationTaskTypeData = types;
      this.updateFilteredTransportTypeOptions();
    });
  }

  private getTransportLocations(): void {
    this.transportTasksService.getTransportationTasksLocationGeneralData(this.transportTask?.transportId)
    .pipe(untilDestroyed(this))
    .subscribe((items: TransportItem[]) => {
      this.transportLocation = items;
      this.selectionController.setTransportItems(items);
      this.fillFormLocations(this.transportTask);
    });
  }

  private buildForm(): void {
    this.transportationTasksForm = this.formBuilder.group({
      data: this.formBuilder.group({
        title: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        transportationTaskType: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          LabelValidator.isValidLabel(this.languageService.getInstant('general.type'), [], this.languageService)],
          this.getTransportTypeAsyncValidators()],
        description: [''],
        dueDate: ['', DateValidator.isValidDateOrEmpty(this.inputDateRef, this.languageService)],
        dueTime: [''],
        priority: ['', <any> Validators.required],
        estimatedDuration: [null, <any> DateValidator.timeDurationNotZero()],
      }),
      relatedProject: ['', [Validators.required, TransportationValidators.isTransportItemOrEmpty]],
      startAddress: ['', [TransportationValidators.isTransportItemOrEmpty]],
      endAddress: ['', [TransportationValidators.isTransportItemOrEmpty]],
      file: ['']
    });

    this.setAdditionalValidators();
    this.disableRelatedItemsIfNecessary();
  }

  private disableRelatedItemsIfNecessary(): void {
    if (this.isEditMode && TransportTaskChecker.isFinishedTask(this.transportTask)) {
      this.startAddress.disable();
      this.endAddress.disable();
    }
  }

  private setAdditionalValidators(): void {
    this.addValidatorsToControl(this.dueTime, [TransportationValidators.timeWithDate(this.dueDate)]);
    this.addValidatorsPlannableTransportState();
  }

  private addValidatorsPlannableTransportState(): void {
    if (this.isEditPlannableAndBeyond) {
      this.addValidatorsToControl(this.transportationTaskType, [Validators.required]);
      this.addValidatorsToControl(this.startAddress, [Validators.required]);
      this.addValidatorsToControl(this.endAddress, [Validators.required]);
    }
  }

  private addValidatorsToControl(control: AbstractControl, validatorsFn: ValidatorFn[]): void {
    if (validatorsFn && validatorsFn.length > 0) {
      control.setValidators([
        ...(control.validator ? [control.validator] : []),
        ...validatorsFn
      ]);
    }
  }

  private getTransportTypeAsyncValidators(): AsyncValidatorFn[] {
    return this.shouldPreventTypeCreation()
      ? [TransportationValidators.hasTransportTypeAsync(this.transportTasksService)]
      : [];
  }

  private shouldPreventTypeCreation(): boolean {
    return !this.authService.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION);
  }

  private fillForm(): void {
    if (this.isEditMode) {
      this.fillFormGeneralData(this.transportTask);
      this.cdref.detectChanges();
    } else {
      this.fillFormDefaultValue();
    }
  }

  private fillFormGeneralData(transport: TransportView): void {
    if (transport) {
      this.transportationTasksForm.patchValue(this.transportToGeneralFormData(transport), { emitEvent: false });
      if (transport.transportType) {
        this.transportationTaskType.markAsTouched();
      }
    }
  }

  private fillFormDefaultValue(): void {
    this.transportationTasksForm.patchValue(this.defaultFormValue, { emitEvent: false });
  }

  private transportToGeneralFormData(transport: TransportView): any {
    const { title, dueDate: transportDueDate, priority, transportType, estimatedDuration, text } = transport;
    const dueDate = transportDueDate?.timestamp ? new Date(transportDueDate.timestamp) : null;

    return {
      data: {
        title,
        transportationTaskType: transportType,
        description: text,
        dueDate,
        dueTime: this.formatToTimeString(dueDate),
        priority,
        estimatedDuration: this.utils.parseFromDuration(estimatedDuration)
      }
    }
  }

  private fillFormLocations(transport: TransportView): void {
    if (transport) {
      this.startAddress.setValue(this.getTransportLocation(transport.startItem?.id));
      this.endAddress.setValue(this.getTransportLocation(transport.targetItem?.id));
      this.relatedProject.setValue(this.getTransportLocation(transport.projectInfo?.projectId));
    }
  }

  private getTransportLocation(id: string): TransportItem {
    return id ? this.transportLocation.find(item => item.id === id) : null;
  }

  private dueDateOnChanges(): void {
    this.dueDate.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(() => this.dueTime.updateValueAndValidity());
  }

  public displayAutocompleteValue(): (item: TransportItem) => string {
    return (item: TransportItem): string => {
      const formattedAddress = item?.address && this.addressService.formatFullAddressSingleLine(
        item.address.postalCode,
        item.address.city,
        item.address.street,
        item.address.streetNumber);

      return [item?.name, formattedAddress]
        .filter(Boolean)
        .join('\n');
    }
  }

  public transportTypeSelected(): void {
    this.updateFilteredTransportTypeOptions();
  }

  private updateFilteredTransportTypeOptions(value?: string): void {
    const filterValue = value && value.toLowerCase();
    this.filteredTransportTypeOptions = !filterValue
      ? [...this.transportationTaskTypeData]
      : this.transportationTaskTypeData
        .filter(option => option.toLowerCase().includes(filterValue));
  }

  private updateFilteredTitleOptions(value?: string): void {
    const term = value || '';
    this.transportTasksService.getTitles(term.trim())
    .pipe(untilDestroyed(this))
    .subscribe((titles: TransportTitleCount[]) => {
      this.filteredTransportTitleOptions = titles;
    });
  }

  private filterTaskTypeOnInput(): void {
    this.transportationTaskType.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(value => this.updateFilteredTransportTypeOptions(value));
  }

  private filterStartAddressItemOnInput(): void {
    this.startAddress.valueChanges
    .pipe(debounceTime(500), untilDestroyed(this))
    .subscribe(value => this.processStartAddressChangedValue(value));
  }

  private filterEndAddressItemOnInput(): void {
    this.endAddress.valueChanges
    .pipe(debounceTime(500), untilDestroyed(this))
    .subscribe(value => this.processEndAddressChangedValue(value));
  }

  private filterRelatedProjectItemOnInput(): void {
    this.relatedProject.valueChanges
    .pipe(debounceTime(500), untilDestroyed(this))
    .subscribe(value => this.processProjectAddressChangedValue(value));
  }

  private filterTitleOnInput(): void {
    this.dataFormField.get('title').valueChanges
    .pipe(debounceTime(500), untilDestroyed(this))
    .subscribe(value => this.updateFilteredTitleOptions(value));
  }

  private checkTransportTypeCompatibility(): void {
    if (this.isEditMode) {
      this.isIncompatibleTransportType = this.transportationTaskType.valueChanges
      .pipe(
        startWith(this.transportTask.transportType),
        debounceTime(500),
        map(type => !TransportTaskChecker.isCompatibleAssignedVehicleType(this.transportTask, type)
          && TransportTaskChecker.isPlannedOrInProgress(this.transportTask)),
        untilDestroyed(this),
        share());

      this.canRevertTransportType = this.isIncompatibleTransportType
      .pipe(
        map(isIncompatible => isIncompatible && this.transportTask.transportType !== this.transportationTaskType.value),
        untilDestroyed(this)
      )
    }
  }

  public processStartAddressChangedValue(value: TransportItem | string): void {
    this.selectionController.selectStartAddress(value);
  }

  private processEndAddressChangedValue(value: TransportItem | string): void {
    this.selectionController.selectEndAddress(value);
  }

  public processProjectAddressChangedValue(value: TransportItem | string): void {
    this.selectionController.selectProjectAddress(value);
  }

  private initFormChangesListener(): void {
    this.filterTaskTypeOnInput();
    this.filterStartAddressItemOnInput();
    this.filterEndAddressItemOnInput();
    this.filterRelatedProjectItemOnInput();
    this.filterTitleOnInput();
    this.dueDateOnChanges();
    this.checkTransportTypeCompatibility();
  }

  private initTransportSelectionController(): void {
    this.initStartAddressOptionsListener();
    this.initEndAddressOptionsListener();
    this.initRelatedProjectOptionsListener();
    this.initSelectedStartAddressListener();
    this.initSelectedEndAddressListener();
    this.initSelectedRelatedProjectListener();
  }

  private initStartAddressOptionsListener(): void {
    this.selectionController.startAddressOptions
      .pipe(untilDestroyed(this))
      .subscribe(options => this.filteredStartAddressOptions = options);
  }
  private initEndAddressOptionsListener(): void {
    this.selectionController.endAddressOptions
      .pipe(untilDestroyed(this))
      .subscribe(options => this.filteredEndAddressOptions = options);
  }
  private initRelatedProjectOptionsListener(): void {
    this.selectionController.projectAddressOptions
      .pipe(untilDestroyed(this))
      .subscribe(options => this.filteredRelatedProjectOptions = options);
  }
  private initSelectedStartAddressListener(): void {
    this.selectionController.selectedStartAddress
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value !== this.startAddress.value) {
          this.startAddress.setValue(value, { emitEvent: false });
        }
      });
  }
  private initSelectedEndAddressListener(): void {
    this.selectionController.selectedEndAddress
      .pipe(untilDestroyed(this))
      .subscribe(v => {
        if (v !== this.endAddress.value) {
          this.endAddress.setValue(v, { emitEvent: false });
        }
      });
  }
  private initSelectedRelatedProjectListener(): void {
    this.selectionController.selectedProjectAddress
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value !== this.relatedProject.value) {
          this.relatedProject.setValue(value, { emitEvent: false });
        }
      });
  }

  public save(): void {
    if (this.isEditMode) {
      this.doUpdate();
    } else {
      this.doCreate(true);
    }
  }

  public saveAndNew(): void {
    this.doCreate(false);
  }

  private doCreate(close: boolean): void {
    if (this.isValid()) {
      const command = this.getCreateTransportationTaskCommand();
      this.isLoading = true;
      this.transportTasksService.createTransportationTask(command)
      .pipe(
          delay(environment.DELAY_SHORTEST),
          mergeMap(transportId => forkJoin([of(transportId), ...this.addDocuments(transportId)])),
          finalize(() => this.isLoading = false),
          untilDestroyed(this))
      .subscribe(([transportId]) => close
        ? this.dialogRef.close({ transportId, status: TransportDialogEventType.CREATION_SUCCEEDED })
        : this.fullReset(this.defaultFormValue));
    }
  }

  private doUpdate(): void {
    if (this.isValid()) {
      const command = this.getUpdateTransportationTaskCommand();
      this.isLoading = true;
      this.transportDatasource.updateTransport(command)
      .pipe(
          delay(environment.DELAY_SHORTEST),
          finalize(() => this.isLoading = false),
          untilDestroyed(this))
      .subscribe((transportId) => {
        this.transportDatasource.updateFilters();
        this.dialogRef.close({ transportId, status: TransportDialogEventType.EDIT_SUCCEEDED });
      });
    }
  }

  private addDocuments(transportId: string): Observable<string>[] {
    return this.documents && this.documents.length > 0
      ? this.documents.map(doc => this.addDocument(transportId, doc))
      : [of(null)];
  }

  private addDocument(taskId: string, document: AttachedDocument): Observable<string> {
    const command = new UploadTransportDocumentCommand(taskId, document);
    return this.transportTasksService.addDocument(command);
  }

  private formValueToBaseTransportTaskCommand(): BaseTransportTaskCommand {
    const formValue = this.transportationTasksForm.getRawValue();
    const estimatedDuration = formValue.data.estimatedDuration
      ? this.utils.parseToDuration(formValue.data.estimatedDuration.hours, formValue.data.estimatedDuration.minutes)
      : null;
    const transportationTaskType = (formValue.data.transportationTaskType || '').trim() || null;
    const dueDate = DatesService.formatToOspDateTimeEndOfDay(formValue.data.dueDate, formValue.data.dueTime);

    let command = {
      title: formValue.data.title.trim(),
      transportType: transportationTaskType,
      text: formValue.data.description,
      dueDate,
      priority: formValue.data.priority,
      estimatedDuration,
      startItemId: undefined,
      startItemType: undefined,
      targetItemId: undefined,
      targetItemType: undefined,
      projectId: formValue.relatedProject?.id,
    };
    return this.setStartAndTarget(command);
  }

  private setStartAndTarget(command: BaseTransportTaskCommand): BaseTransportTaskCommand {
    const formValue = this.transportationTasksForm.getRawValue();
    if (!this.transportTask?.latestRelatedItemDetails?.isStartItemDeleted) {
      command.startItemId = formValue.startAddress?.id;
      command.startItemType = formValue.startAddress?.transportItemType;
    }

    if (!this.transportTask?.latestRelatedItemDetails?.isTargetItemDeleted) {
      command.targetItemId = formValue.endAddress?.id;
      command.targetItemType = formValue.endAddress?.transportItemType;
    }

    if ([TransportTaskState.DONE,
      TransportTaskState.ARCHIVED,
      TransportTaskState.CANCELED,
    ].includes(this.transportTask?.state)) {
      if (this.transportTask?.latestRelatedItemDetails?.latestStartItem) {
        command.startItemId = this.transportTask?.latestRelatedItemDetails?.latestStartItem.id;
        command.startItemType = this.transportTask?.latestRelatedItemDetails?.latestStartItem.transportItemType;
      }
      if (this.transportTask?.latestRelatedItemDetails?.latestTargetItem) {
        command.targetItemId = this.transportTask?.latestRelatedItemDetails?.latestTargetItem.id;
        command.targetItemType = this.transportTask?.latestRelatedItemDetails?.latestTargetItem.transportItemType;
      }
    }

    return command;

  }

  private getCreateTransportationTaskCommand(): CreateTransportTaskCommand {
    return this.formValueToBaseTransportTaskCommand();
  }

  private getUpdateTransportationTaskCommand(): UpdateTransportTaskCommand {
    return {
      ...this.formValueToBaseTransportTaskCommand(),
      transportId: this.transportTask.transportId
    }
  }

  private formatToTimeString(date: Date): string {
    if (date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      if (!(hours === 23 && minutes === 59)) {
        return [hours, minutes].map(n => n > 9 ? n : `0${n}`).join(':');
      }
    }

    return null;
  }

  private fullReset(formValue: TransportationTasksFormValue): void {
    this.loadFormData();
    this.stepper.reset();
    this.transportationTasksNgForm.resetForm(formValue);
    this.documents = [];
    this.selectionController.reset();
  }

  public isValid(): boolean {
    return this.transportationTasksForm.valid && !this.uploadPending;
  }

  public clearDueDate(): void {
    this.dueDate.reset();
    this.dueTime.reset();
  }

  public clearDueTime(): void {
    this.dueTime.reset();
  }

  public resolveIcon(item: TransportItem): IconPayload {
    return this.transportTaskResolver.resolveIcon(item);
  }

  public onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.documents.push(AttachedDocument.fromData(attachedDocument));
  }

  public onPendingUploadStatusChanged(pending: boolean): void {
    this.uploadPending = pending;
  }

  public removeDocument(document: AttachedDocument) {
    const index = this.documents.indexOf(document);
    if (index !== -1) {
      this.transportTasksService.deleteTempDocument(document.documentKey)
      .subscribe(() => this.documents.splice(index, 1),
      );
    }
  }

}

