<div class="dialog-wrapper">
  <div mat-dialog-content>
    {{'modules.equipment.rentalEquipmentWarningDialog.noteRentalPeriodForEquipment'|translate}}
  </div>

  <div mat-dialog-actions>
    <button type="button" mat-button color="primary" mat-dialog-close="no">{{'general.buttons.cancel'|translate}}</button>
    <button type="button" mat-button color="primary" mat-dialog-close="yes">
      {{'modules.equipment.rentalEquipmentWarningDialog.assignAnyway'|translate}}
    </button>
  </div>
</div>
