import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Alarm } from 'app/modules/notifications/shared/interfaces/alarm.interface';
import { AlarmLevel } from '../shared/enums/alarm-level.enum';
import { Modules } from '../../../shared/enums/modules.enum';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { KeycloakService } from '../../../core/keycloak';
import { NotificationDataSource } from '../shared/services/notification.datasource';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { Router } from '@angular/router';
import { AcknowledgeAlarmCommand } from '../contract/acknowledge-alarm-command';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../../../shared/components/success-snackbar/success-snackbar.component';
import { Authorities } from '../../../shared/enums/authorities.enum';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-alarms',
  templateUrl: './mobile-alarms.component.html',
  styleUrls: ['./mobile-alarms.component.scss']
})
export class MobileAlarmsComponent implements OnInit, OnDestroy {

  alarms: Alarm[] = [];

  private positions: number[] = [];

  constructor(private authService: KeycloakService,
              private notificationStore: NotificationDataSource,
              private onlineStatusService: OnlineStatusService,
              private router: Router,
              private snackbar: MatSnackBar,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.getAlarms();
  }

  ngOnDestroy(): void {
  }

  onPan(event: { deltaX: number, eventType: number, isFinal: boolean, type: string }, fullWidth: number, index: number): void {
    if (event.type !== 'pan' || event.eventType === 8) { // eventType === 8: swipe canceled
      return;
    }
    this.positions[index] = Math.min(0, event.deltaX);

    if (!event.isFinal) {
      return;
    }

    if (this.isAcknowledgeable(fullWidth, index)) {
      this.acknowledgeAlarm(index);
    } else {
      this.positions[index] = 0;
    }
  }

  getPos(index: number): number {
    return this.positions[index];
  }

  getAlarmLevelColor(type: string): string {
    switch (type) {
      case AlarmLevel.HIGH:
        return 'red';
      case AlarmLevel.MID:
        return 'yellow';
      case AlarmLevel.LOW:
        return 'green';
      default:
        return null;
    }
  }

  navigateToEquipment(equipmentId: string) {
    this.router.navigate([`mobile/equipments/view/${equipmentId}/general`], {queryParams: {routedFromAlarms: true}});
  }

  isAcknowledgeable(fullWidth: number, index: number): boolean {
    return this.positions[index] < fullWidth * -0.4;
  }

  isGeofenceAlarm(alarm: Alarm) {
    return alarm.alarmType === AlarmType.GEOFENCE_VIOLATION;
  }

  navigateToProject(projectId: string) {
    this.router.navigate([`mobile/projects/view/${projectId}/general`], {queryParams: {routedFromAlarms: true}});
  }

  private getAlarms() {
    if (this.authService.hasModule(Modules.ALARMS)
        && this.authService.hasAuthority(Authorities.NOTIFICATIONS_VIEW_ALARMS)) {

      this.notificationStore.currentActive.pipe(untilDestroyed(this)).subscribe(alarms => {
        if (alarms) {
          this.alarms = alarms;
          this.positions = [];
          for (let i = 0; i < alarms.length; i++) {
            this.positions.push(0);
          }
        }
      });
    }
  }

  private acknowledgeAlarm(index: number) {
    const acknowledgeAlarmCommand = new AcknowledgeAlarmCommand(this.alarms[index].alarmId);

    this.notificationStore.acknowledgeAlarm(acknowledgeAlarmCommand).subscribe(() => {
          this.snackbar.openFromComponent(SuccessSnackbarComponent, {
            data: {message: this.languageService.getInstant('modules.notification.alarms.alarmAcknowledge')},
            duration: 3000
          });
        }
    );

    this.positions.splice(index, 1);
    this.alarms.splice(index, 1);
  }
}
