import { of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomersService} from '../../modules/organisation/shared/customers.service';

export class CustomerNumberInUseValidator {
  static isValid(customerNumber: string, customerId: string, partnerId: string, customersService: CustomersService) {
    if (!customerNumber || !partnerId) {
      return of(null);
    }

    return customersService.customerNumberInUse(customerNumber, customerId, partnerId)
    .pipe(map(res => res ? {taken: true} : null));
  }
}
