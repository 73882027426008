import { OnDestroy, OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../core/keycloak';
import { ChatOverview } from '../contract/chat-overview.interface';
import { CommunicationDataSource } from '../shared/communication.datasource';
import { GuardedNavigableInputComponent } from '../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ChatUser } from '../contract/chat-user.interface';
import { map, withLatestFrom } from 'rxjs/operators';
import { RouterHistory } from '../../../shared/router-history';

@Directive()
export abstract class BaseChatListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public suggestedChatUsers: Observable<ChatUser[]>;
  public addUserListVisible = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected communicationDataSource: CommunicationDataSource,
              protected routerHistory: RouterHistory) {
    super(authService, router, activatedRoute, routerHistory);
  }

  public ngOnInit(): void {
    this.communicationDataSource.pollChats();
    this.filterKnownUsers();
  }

  public goToChat(recipientId: string): void {
    this.router.navigate([{outlets: {sidenav: ['chat', recipientId]}}], {skipLocationChange: true});
  }

  public goToMobileChat(recipientId: string): void {
    this.router.navigate(['mobile/chat', recipientId]);
  }

  public closeUserList(): void {
    this.addUserListVisible = false;
  }

  public openUserList(): void {
    this.addUserListVisible = true;
    this.communicationDataSource.updateKnownUsers();
  }

  public ngOnDestroy(): void {
    this.communicationDataSource.stopChatsPoll();
  }

  private filterKnownUsers(): void {
    this.suggestedChatUsers = this.communicationDataSource.knownChatUsers.pipe(
      withLatestFrom(this.communicationDataSource.chats),
      map(([users, chats]: [ChatUser[], ChatOverview[]]) => users.filter(
        (user: ChatUser) => !chats.find((chat: ChatOverview) => chat.chatPartnerId === user.userId)))
    );
  }
}
