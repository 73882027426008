import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { TechnicalFieldType } from '../../contract/technical-field-type';
import { TechnicalFieldUnit } from '../../contract/technical-field-unit';
import { TechnicalFieldsDataSource } from '../../shared/technical-fields.datasource';
import { CreateTechnicalFieldCommand } from '../../contract/create-technical-field-command';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { environment } from 'environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-technical-field-add',
  templateUrl: 'technical-field-add.component.html',
  styleUrls: ['technical-field-add.component.scss']
})
export class TechnicalFieldAddComponent extends GuardedNavigableInputComponent implements OnInit {
  technicalFieldEditForm: UntypedFormGroup;
  public technicalFieldTypes: string[] = [];
  public technicalFieldUnits: string[] = [];
  public readonly fieldLimit = FieldLimit;

  public isSaveButtonDisabled = false;

  public get technicalFieldKey(): AbstractControl {
    return this.technicalFieldEditForm.controls.technicalFieldKey;
  }

  public get technicalFieldName(): AbstractControl {
    return this.technicalFieldEditForm.controls.technicalFieldName;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private technicalFieldsDataSource: TechnicalFieldsDataSource) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.technicalFieldTypes = (<any>Object).values(TechnicalFieldType);
    this.technicalFieldUnits = (<any>Object).values(TechnicalFieldUnit);
    this.buildForm();

    this.disableSaveButtonWhilePrintingInKeyField();
    this.disableSaveButtonWhilePrintingInNameField();
    this.keyChangeSubscription();
    this.nameChangeSubscription();
  }

  private buildForm(): void {
    this.technicalFieldEditForm = this.formBuilder.group({
      technicalFieldKey: ['', [Validators.required]],
      technicalFieldName: ['', [Validators.required]],
      technicalFieldType: ['', [Validators.required]],
      technicalFieldUnit: ['']
    });
  }

  private disableSaveButtonWhilePrintingInKeyField(): void {
    this.technicalFieldKey.valueChanges.subscribe(() => this.isSaveButtonDisabled = true);
  }

  private disableSaveButtonWhilePrintingInNameField(): void {
    this.technicalFieldName.valueChanges.subscribe(() => this.isSaveButtonDisabled = true);
  }

  private keyChangeSubscription(): void {
    this.technicalFieldKey.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe(key => {
        if (key) {
          this.keyExistsCheck();
        }
      });
  }

  private keyExistsCheck(): void {
    this.technicalFieldsDataSource.keyExistsCheck(this.technicalFieldKey.value)
      .subscribe(isExist => {
        this.isSaveButtonDisabled = false;
        this.technicalFieldKey.setErrors(isExist ? {keyAlreadyExist: true} : null)
      });
  }

  private nameChangeSubscription(): void {
    this.technicalFieldName.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe(name => {
        if (name) {
          this.nameExistsCheck();
        }
      });
  }

  private nameExistsCheck(): void {
    this.technicalFieldsDataSource.nameExistsCheck(this.technicalFieldName.value)
      .subscribe(isExist => {
        this.isSaveButtonDisabled = false;
        this.technicalFieldName.setErrors(isExist ? {nameAlreadyExist: true} : null);
      });
  }

  public save(): void {
    this.technicalFieldsDataSource.saveTechnicalField(this.getUpdateTechnicalFieldCommand())
      .subscribe(() => this.navigateAfterSubmit());
  }

  private getUpdateTechnicalFieldCommand(): CreateTechnicalFieldCommand {
    const formValue = this.technicalFieldEditForm.value;
    const cmd = new CreateTechnicalFieldCommand();
    cmd.technicalFieldKey = formValue.technicalFieldKey;
    cmd.technicalFieldName = formValue.technicalFieldName;
    cmd.technicalFieldType = formValue.technicalFieldType;
    cmd.technicalFieldUnit = formValue.technicalFieldUnit;
    return cmd;
  }
}
