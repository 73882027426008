import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { FileUtils } from '../../../fileUtils';
import { TopfactDocument } from '../../../contract/topfact-document.interface';

@Directive()
export abstract class BaseAttachmentTopfactComponent {

  @Input() documents: TopfactDocument[];
  @Input() loading = false;
  @Input() serviceAvailable = true;
  @Input() pendingDownloads: TopfactDocument[] = [];

  @Output() downloadRequested = new EventEmitter<TopfactDocument>();

  public getDocumentTypeIcon(document: TopfactDocument) {
    return FileUtils.getTopfactFileTypeIcon(document);
  }

  public downloadDocument(document: TopfactDocument): void {
    this.downloadRequested.emit(document);
  }

  public isPendingDocument(document: TopfactDocument): boolean {
    return this.pendingDownloads.indexOf(document) > -1;
  }
}
