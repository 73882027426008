import { Pipe, PipeTransform } from '@angular/core';
import { CartesianScaleTypeRegistry, ChartConfiguration, TooltipItem } from 'chart.js';
import _ from 'lodash';
import { ChartOptionsExtendedPlugins } from '../chart-custom-plugin/interfaces/chart-plugin-options-extended.type';
import { EquipmentStatusColor } from 'app/modules/equipment/shared/contracts/equipment-status-color.enum';
import { EquipmentStatusChartDataPrepared } from '../contract/equipment-status-chart-data-prepared.interface';
import { ChartBarDisplayType } from '../contract/chart-bar-display-type.enum';


@Pipe({
  name: 'equipmentStatusChartOptions'
})
export class EquipmentStatusChartOptionsPipe implements PipeTransform {

  private chartTypeMap: Record<ChartBarDisplayType, keyof CartesianScaleTypeRegistry> = {
    [ChartBarDisplayType.LINEAR]: 'linear',
    [ChartBarDisplayType.LOGARITHMIC]: 'logarithmic',
  }
  private maxBottomPadding = 150;
  private maxCharactersAmount = 20;
  private labelCircleColors = [
    EquipmentStatusColor.OPERATIONAL,
    EquipmentStatusColor.OPERATIONAL_WITH_RESTRICTIONS,
    EquipmentStatusColor.NOT_OPERATIONAL,
    EquipmentStatusColor.NOT_AVAILABLE,
  ];

  public transform(
    data: EquipmentStatusChartDataPrepared,
    type: ChartBarDisplayType,
    labelClickCallback?: (equipmentStatusId: string) => void,
  ): ChartConfiguration['options'] {
    return this.getOptions(data, type, labelClickCallback);
  }

  public getOptions(
    chartData: EquipmentStatusChartDataPrepared,
    type: ChartBarDisplayType,
    labelClickCallback: (equipmentStatusId: string) => void,
  ): ChartOptionsExtendedPlugins {
    return {
      responsive: true,
      layout: {
        padding: {
          left: 10,
          top: 30,
          right: 10,
          bottom: this.maxBottomPadding,
        },
      },
      scales: {
        x: {
          ticks: { display: false }
        },
        y: {
          type: this.getChartType(type || ChartBarDisplayType.LINEAR),
          grid: {
            tickColor: data => data.tick.major ? 'rgb(196, 196, 196)' : 'rgb(196, 196, 196, 0)',
            lineWidth: data => data.tick.major ? 1 : 0.5,
            color: data => data.tick.major ? 'rgb(196, 196, 196)' : 'rgb(196, 196, 196, 0.5)',
          }
        }
      },
      plugins: {
        legend: { display: false },
        datalabels: {
          labels: {
            name: {
              anchor: 'start',
              align: 'start',
              rotation: 270,
              formatter: (value, ctx) => {
                const label = chartData?.[ctx.datasetIndex]?.[ctx.dataIndex]?.equipmentStatusName;
                if (!_.isNil(label)) {
                  return label.length <= this.maxCharactersAmount ? label : `${label.substring(0, this.maxCharactersAmount - 2)}...`;
                }
                return null;
              },
            },
            value: {
              anchor: 'end',
              align: 'end',
            }
          },
          listeners: {
            click: ctx => {
              if (labelClickCallback && typeof labelClickCallback === 'function') {
                const equipmentStatusId = chartData?.[ctx.datasetIndex]?.[ctx.dataIndex]?.equipmentStatusId;
                if (equipmentStatusId) {
                  labelClickCallback(equipmentStatusId);
                }
              }
            }
          }
        },
        tooltip: {
          callbacks: {
            title: (tooltipItems: TooltipItem<'bar'>[]) => {
              const { dataIndex, datasetIndex } = tooltipItems[0];
              return chartData?.[datasetIndex]?.[dataIndex]?.equipmentStatusName || null;
            },
            label: (tooltipItem: TooltipItem<'bar'>) => tooltipItem.label,
            labelColor: (tooltipItem: TooltipItem<'bar'>) => {
              return {
                borderColor: 'white',
                backgroundColor: tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex],
                borderWidth: 1,
                borderRadius: 2,
              }
            },
          },
          enabled: ctx => {
            if (ctx.tooltipItems) {
              const item = ctx.tooltipItems?.[0]
              return Boolean(chartData?.[item?.datasetIndex]?.[item?.dataIndex]);
            }
            return true;
          },
        },
        customDataCategoryLabels: {
          labelShiftLeft: 2,
          labelShiftRight: 10,
          labelLeftPadding: 5,
          labelFortSize: 12,
          labelColor: 'gray',
          labelCircleColors: this.labelCircleColors,
        }
      }
    }
  }

  private getChartType(type: ChartBarDisplayType): keyof CartesianScaleTypeRegistry {
    return this.chartTypeMap[type] ?? this.chartTypeMap[ChartBarDisplayType.LINEAR] ?? 'linear';
  }

}
