import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { GalleryOrderedImage } from '../../../../models/gallery-ordered-image.class';
import { GalleryAnimation } from '../../../../enums/gallery-animation.enum';
import {ImageComponent} from '../../../image/image.component';

@Component({
  selector: 'bh-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.scss'],
})
export class GalleryImageComponent implements OnInit {
  @Input() public images: GalleryOrderedImage[];
  @Input() public clickable: boolean;
  @Input() public selectedIndex: number;
  @Input() public arrows: boolean;
  @Input() public arrowsAutoHide: boolean;
  @Input() public animation: string;
  @Input() public size: string;
  @Input() public arrowPrevIcon: string;
  @Input() public arrowNextIcon: string;
  @Input() public autoPlay: boolean;
  @Input() public autoPlayInterval: number;
  @Input() public autoPlayPauseOnHover: boolean;
  @Input() public infinityMove: boolean;
  @Input() public bullets: boolean;

  @Output() public onClick = new EventEmitter();
  @Output() public onActiveChange = new EventEmitter();

  public canChangeImage = true;

  private timer: any;

  public ngOnInit(): void {
    if (this.arrows && this.arrowsAutoHide) {
      this.arrows = false;
    }

    if (this.autoPlay) {
      this.startAutoPlay();
    }
  }

  @HostListener('mouseenter') public onMouseEnter() {
    if (this.arrowsAutoHide && !this.arrows) {
      this.arrows = true;
    }

    if (this.autoPlay && this.autoPlayPauseOnHover) {
      this.stopAutoPlay();
    }
  }

  @HostListener('mouseleave') public onMouseLeave() {
    if (this.arrowsAutoHide && this.arrows) {
      this.arrows = false;
    }

    if (this.autoPlay && this.autoPlayPauseOnHover) {
      this.startAutoPlay();
    }
  }

  public reset(index: number): void {
    this.selectedIndex = index;
  }

  public startAutoPlay(): void {
    this.stopAutoPlay();

    this.timer = setInterval(() => {
      if (!this.showNext()) {
        this.selectedIndex = -1;
        this.showNext();
      }
    }, this.autoPlayInterval);
  }

  public stopAutoPlay() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public handleClick(event: Event, index: number, imageComponent: ImageComponent): boolean {
    if (imageComponent.disabled) {
      return;
    }

    if (this.clickable) {
      this.onClick.emit(index);

      event.stopPropagation();
      event.preventDefault();
    }
  }

  public show(index: number) {
    this.selectedIndex = index;
    this.onActiveChange.emit(this.selectedIndex);
    this.setChangeTimeout();
  }

  public showNext(): boolean {
    if (this.canShowNext() && this.canChangeImage) {
      this.selectedIndex++;

      if (this.selectedIndex === this.images.length) {
        this.selectedIndex = 0;
      }

      this.onActiveChange.emit(this.selectedIndex);
      this.setChangeTimeout();

      return true;
    } else {
      return false;
    }
  }

  public showPrev(): void {
    if (this.canShowPrev() && this.canChangeImage) {
      this.selectedIndex--;

      if (this.selectedIndex < 0) {
        this.selectedIndex = this.images.length - 1;
      }

      this.onActiveChange.emit(this.selectedIndex);
      this.setChangeTimeout();
    }
  }

  public setChangeTimeout() {
    this.canChangeImage = false;
    let timeout = 1000;

    if (this.animation === GalleryAnimation.SLIDE
      || this.animation === GalleryAnimation.FADE) {
      timeout = 500;
    }

    setTimeout(() => {
      this.canChangeImage = true;
    }, timeout);
  }

  public canShowNext(): boolean {
    if (this.images) {
      return this.infinityMove || this.selectedIndex < this.images.length - 1
        ? true : false;
    } else {
      return false;
    }
  }

  public canShowPrev(): boolean {
    if (this.images) {
      return this.infinityMove || this.selectedIndex > 0 ? true : false;
    } else {
      return false;
    }
  }

  public imageTrackBy(index: number, image: GalleryOrderedImage) {
    return image?.src;
  }
}
