<div class="line"
  [ngClass]="task?.dueDateInfo?.warningLevel | lowercase"
  fxLayout="row"
  fxLayoutAlign="space-between center">
  <span class="text-overflow">
    <bh-task-remaining [dueDateInfo]="task?.dueDateInfo"></bh-task-remaining>
  </span>
  <span id="completed-date" *ngIf="(task?.dueDateInfo | dueDate) as dueDate">
    {{ dueDate }}
  </span>
</div>

<div class="line" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="icon__service-type custom-sizing" [matTooltip]="task?.maintenanceCategory | maintenanceCategory">
    {{task?.maintenanceCategory | maintenanceCategory: 'icon'}}
  </mat-icon>
  <span class="name text-overflow">{{task?.maintenanceName}}</span>
</div>

<div class="line">
  <span class="text-overflow">{{task?.equipmentName || task?.equipmentModel}}</span>
</div>

<div class="line">
  <div class="grayed text-overflow"
    [ngClass]="task?.dueDateInfo?.warningLevel | lowercase">
    <span>{{task?.equipmentSerialNumber || ('modules.equipment.equipmentList.noSerialNumber'|translate)}}</span>
    /
    <span>{{task?.equipmentCustomerSerialNumber}}</span>
   </div>
</div>
