import { EventEmitter, Injectable } from '@angular/core';
import { UserConfigurationService } from './user-configuration.service';
import { WizardConfiguration, } from '../contract/user-configuration/wizard-configuration';
import { cloneDeep, isEqual } from 'lodash';
import { WizardSettings } from '../wizard-settings.interface';
import { WizardTabOptions } from '../wizard-tab-options.interface';

@Injectable()
export class MobileWizardSettingsService {

  public openSettings: EventEmitter<string> = new EventEmitter<string>();
  public settingsChanged: EventEmitter<WizardSettings> = new EventEmitter<WizardSettings>();

  constructor(private userConfigurationService: UserConfigurationService) {
  }

  public getWizardConfiguration(entryName: string): WizardConfiguration {
    return this.getSettings()[entryName];
  }

  public persistConfiguration(entryName: string, wizardConfiguration: WizardConfiguration): void {
    const wizardSettings = this.getSettings();
    wizardSettings[entryName] = wizardConfiguration;
    this.userConfigurationService.saveWizardSettings(wizardSettings);
    this.settingsChanged.emit(wizardSettings);
  }

  public registerWizard(entryName: string, tabs: WizardTabOptions[]): WizardSettings {
    const wizardSettings: WizardSettings = this.generateSettings(entryName, tabs);
    return this.checkForChanges(entryName, wizardSettings);
  }

  public showSettings(entryName: string): void {
    this.openSettings.next(entryName);
  }

  /**
   * We want to make sure that the user does not get broken settings in their localStorage.
   * So what happens if we change anything in the wizards order? E.g. removing, adding, changing
   * order or renaming tabs.
   *
   * To prevent this we check for changes and reinitialize the settings according to that.
   */
  private detectTabChanges(entryName: string, wizardSettings: WizardSettings, newSettings: WizardSettings): boolean {
    const currentSettings: WizardSettings = cloneDeep(wizardSettings);

    // reverting possible settings of a user, we do not care about that here.
    Object.keys(currentSettings[entryName].tabs).forEach((key: string) => {
      currentSettings[entryName].tabs[key].show = true;
    });

    // Comparing if any property did change. If anything changed we reinitialize the settings
    return !isEqual(currentSettings, newSettings);
  }

  private checkForChanges(entryName: string, generatedSettings: WizardSettings): WizardSettings {
    const wizardSettings: WizardSettings = this.getSettings();
    if (!wizardSettings[entryName] || this.detectTabChanges(entryName, wizardSettings, generatedSettings)) {
      this.userConfigurationService.saveWizardSettings(generatedSettings);
      return generatedSettings;
    }
    return wizardSettings;
  }

  private generateSettings(entryName: string, wizardTabOptions: WizardTabOptions[]): WizardSettings {
    const wizardSettings: WizardSettings = this.getSettings();
    // have to initialize with Object, because the savedSettings loses the information
    // about being the class wizardConfiguration in the process of getting saved in
    // the localStorage. But we still want to run _.isEqual here.
    const wizardConfiguration: WizardConfiguration = {tabs: {}};
    wizardTabOptions.forEach((tabOptions: WizardTabOptions) => {
      wizardConfiguration.tabs[tabOptions.key] = {
        show: true,
        shortName: tabOptions.shortName
      };
      if (tabOptions.optional) {
        wizardConfiguration.tabs[tabOptions.key].optional = tabOptions.optional;
      }
    });
    wizardSettings[entryName] = wizardConfiguration;
    return wizardSettings;
  }

  private getSettings(): WizardSettings {
    return this.userConfigurationService.getWizardSettings();
  }
}
