<bh-step-dialog class="equipment-dialog"
                [title]="(equipment
                          ? 'modules.equipment.equipmentAddEdit.editEquipm'
                          : 'modules.equipment.equipmentAddEdit.createEquipm')|translate"
                [icon]="faTruckPlow" [ngClass]="{'edit-mode': equipment}">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="equipmentForm">
      <mat-horizontal-stepper labelPosition="bottom" fxFlex="100" fxLayout="column"
                              fxLayoutAlign="start stretch" linear [ngClass]="{'no-display-header': equipment}">
        <mat-step [stepControl]="equipmentForm.get('equipmentTypeId')" *ngIf="!equipment">
          <ng-template matStepLabel>{{'modules.equipment.equipmentAddEdit.chooseType'|translate}}*
          </ng-template>
          <div class="equipment-stepper-content"
               fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="0px">
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="90">
              <mat-form-field class="hide-bottom-wrapper">
                <mat-label>{{'general.labels.search'|translate:{value: ('general.equipmType.s'|translate)} }}</mat-label>
                <input type="text" matInput
                       [formControl]="equipmentTypeSearchControl">
              </mat-form-field>

              <mat-list>
                <section *ngFor="let typeGroup of filteredEquipmentTypes">
                  <h3 matSubheader>
                    {{typeGroup.category1Name | translatableString}}
                  </h3>
                  <mat-list-item *ngFor="let subtype of typeGroup.subTypes"
                                 (click)="selectType(subtype.equipmentTypeId)"
                                 [ngClass]="{'selected': subtype.equipmentTypeId
                                    === equipmentForm.get('equipmentTypeId').value}">
                    {{subtype.category2Name | translatableString}}
                  </mat-list-item>
                </section>
              </mat-list>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <button class="bottom-button" mat-raised-button matStepperNext type="button"
                      [disabled]="equipmentForm.get('equipmentTypeId').invalid">{{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="equipmentForm.get('data')" [formGroup]="$any(equipmentForm.get('data'))">
          <ng-template matStepLabel>{{'modules.equipment.base.equipmData'|translate}}</ng-template>
          <div class="equipment-stepper-content"
               fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="equipment-stepper-content__data">
              <div fxLayout="row" class="equipment-dialog-base-data" fxLayoutAlign="space-around start">
                <div fxFlex="40" fxLayoutGap="15px">
                  <div class="field-wrapper">
                    <mat-form-field>
                      <mat-label>{{'general.intNumber.abbr'|translate}}</mat-label>
                      <input matInput required
                             bhRemoveWhitespaces
                             formControlName="equipmentCustomerSerialNumber"
                             [maxlength]="fieldLimit.SHORT_IDENTIFIER">

                      <mat-error *ngIf="customerSerialNumber.hasError('taken')">
                        {{'shared.validation.alreadyAssigned'|translate:{ value: ('general.intNumber.s'|translate) } }}
                      </mat-error>
                      <mat-error *ngIf="customerSerialNumber.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                        {{'shared.validation.string.trimSpaces'|translate|translate}}
                      </mat-error>
                      <mat-error *ngIf="customerSerialNumber.hasError('required')">
                        {{'shared.validation.required'|translate:{ value: ('general.intNumber.s'|translate) } }}
                      </mat-error>
                      <button mat-icon-button matSuffix (click)="clearCustomerSerialNumber()">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <div class="field-wrapper">
                    <bh-text-input [control]="$any(equipmentName)"
                                   [label]="'general.name.s'|translate">
                    </bh-text-input>
                  </div>
                  <div class="field-wrapper">
                    <mat-form-field>
                      <mat-label>{{'general.org.s'|translate}}</mat-label>
                      <mat-select formControlName="organisationId"
                                  disableOptionCentering="true" required>
                        <mat-option>
                          <ngx-mat-select-search [formControl]="organisationFilter"
                                                 [placeholderLabel]="'general.labels.search'|translate:{ value: ('general.org.s'|translate) }"
                                                 [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('general.org.pl'|translate) }"
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let organisation of organisations | async"
                                    [value]="organisation.organisationId">
                          {{ organisation.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="organisation.hasError('required')">
                        {{'shared.validation.required'|translate:{value: ('general.org.s'|translate)} }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="field-wrapper" fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field class="manufacturer-field">
                      <mat-label>{{'general.manufacturer'|translate}}</mat-label>
                      <mat-select formControlName="manufacturerId"
                                  disableOptionCentering="true">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="manufacturerFilter"
                                                 [placeholderLabel]="'general.labels.search'|translate:{ value: ('general.manufacturer'|translate) }"
                                                 [noEntriesFoundLabel]="'modules.equipment.equipmentAddEdit.noManufacturersFound.'|translate">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let manufacturer of manufacturers | async"
                                    [value]="manufacturer.manufacturerId">
                          {{ manufacturer.manufacturerName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button mat-button (click)="openManufacturerCreation()" class="manufacturer-add"
                            *ngIf="hasAuthority(authorities.MANUFACTURER_CREATE)"
                            [matTooltip]="'modules.equipment.equipmentAddEdit.createManufacturer'|translate"
                            fxLayout="row" fxLayoutAlign="center center">
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                  </div>
                  <div class="field-wrapper">
                    <bh-text-input [control]="$any(manufacturerSerialNumber)"
                                   [label]="'modules.equipment.base.manufacturerSerialNumber'|translate">
                    </bh-text-input>
                  </div>
                  <div class="field-wrapper">
                    <bh-text-input [control]="$any(equipmentModel)"
                                   [label]="'modules.equipment.base.model.s'|translate">
                    </bh-text-input>
                  </div>
                  <div class="field-wrapper">
                    <bh-text-input [control]="$any(equipmentCostCenter)"
                                   [label]="'general.costCenter'|translate"
                                   [autoCompleteOptions]="costCenters | async">
                    </bh-text-input>
                  </div>
                </div>
                <div fxFlex="40" fxLayoutGap="15px">
                  <div class="field-wrapper">
                    <mat-form-field>
                      <mat-label>{{'modules.equipment.base.deliveryDate'|translate}}</mat-label>
                      <input matInput
                             #inputDeliveryDateRef
                             bhInputAllowedCharacters="[0-9./]"
                             [matDatepicker]="picker"
                             [min]="minDate"
                             [max]="maxDate"
                             maxLength="20"
                             autocomplete="off"
                             formControlName="equipmentDeliveryDate">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error *ngIf="equipmentDeliveryDate.hasError('isValidDateInstance')">
                        {{equipmentDeliveryDate.getError('isValidDateInstance')}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="field-wrapper">
                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
                      <mat-form-field floatLabel="always">
                        <mat-label>{{'modules.equipment.base.warrantyPeriod'|translate}}</mat-label>
                        <input matInput type="number"
                               formControlName="equipmentGuaranteeValue"
                               min="0"
                               bhMaxLength="3"
                               bhInputAllowedCharacters="[0-9./]"
                               (ngModelChange)="equipmentGuaranteeValueChanged()"
                               [placeholder]="'general.units.value.s'|translate"
                               data-osp-test="input-warranty-period-value">
                        <mat-error *ngIf="equipmentGuaranteeValue.hasError('validNaturalNumber')">
                          {{'shared.validation.number.notNegative'|translate}}
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-select formControlName="equipmentGuaranteeUnit"
                                    data-osp-test="select-warranty-period-value">
                          <mat-option
                            value="months">{{'general.units.time.month.s_pl'|translate}}</mat-option>
                          <mat-option
                            value="years">{{'general.units.time.year.s_pl'|translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <mat-form-field>
                      <mat-label>{{'modules.equipment.base.guaranteeHours'|translate}}</mat-label>
                      <input matInput type="number"
                             bhInputAllowedCharacters="[0-9./]"
                             min="0"
                             formControlName="equipmentGuaranteeOperatingHoursLimit"/>
                    </mat-form-field>
                  </div>
                  <div class="field-wrapper">
                    <bh-text-input [control]="$any(equipmentProductClass)"
                                   [label]="'modules.equipment.base.productClass'|translate">
                    </bh-text-input>
                  </div>
                  <div class="field-wrapper">
                    <bh-text-input [control]="$any(equipmentLicensePlate)"
                                   [label]="'modules.equipment.base.mark'|translate">
                    </bh-text-input>
                  </div>
                  <div class="field-wrapper" fxLayout="row" fxLayoutAlign="space-between start"
                       fxLayoutGap="15px">
                    <mat-form-field>
                      <mat-label>{{'modules.equipment.base.firstRegistration'|translate}}</mat-label>
                      <input matInput
                             #inputRegistrationDateRef
                             bhInputAllowedCharacters="[0-9./]"
                             [matDatepicker]="regDatePicker"
                             [min]="minDate"
                             [max]="maxDate"
                             maxLength="20"
                             autocomplete="off"
                             formControlName="equipmentRegistrationDate">
                      <mat-datepicker-toggle matSuffix
                                             [for]="regDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #regDatePicker></mat-datepicker>
                      <mat-error
                        *ngIf="equipmentRegistrationDate.hasError('isValidDateInstance')">
                        {{equipmentRegistrationDate.getError('isValidDateInstance')}}
                      </mat-error>
                    </mat-form-field>

                    <bh-text-input [control]="$any(equipmentBGLCode)"
                                   [label]="'general.BGLCode'|translate">
                    </bh-text-input>
                  </div>
                  <div class="field-wrapper" fxLayout="row" fxLayoutAlign="space-between start"
                       fxLayoutGap="15px">
                    <mat-form-field floatLabel="always">
                      <mat-label>{{'modules.equipment.base.constrYear'|translate}}</mat-label>
                      <mat-select [placeholder]="'general.units.time.month.s'|translate"
                                  formControlName="equipmentConstructionMonth"
                                  (selectionChange)="constructionMonthSelected()"
                                  data-osp-test="select-construction-month">
                        <mat-option *ngFor="let m of months" [value]="m.value">
                          {{ m.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-select [required]="!!equipmentConstructionMonth.value"
                                  [placeholder]="'general.units.time.year.s'|translate"
                                  formControlName="equipmentConstructionYear"
                                  (selectionChange)="constructionYearSelected()"
                                  data-osp-test="select-construction-year">
                        <mat-option *ngFor="let y of years" [value]="y">
                          {{ y }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="equipmentConstructionYear.hasError('required')">
                        {{'shared.validation.required'|translate:{value: ('modules.equipment.base.constrYear'|translate)} }}
                      </mat-error>
                    </mat-form-field>
                    <mat-icon class="reset-date"
                              [matTooltip]="'general.buttons.resetDateFields'|translate"
                              (click)="removeBuildDate()">
                      clear
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div fxLayout="row" class="label-container" fxLayoutAlign="space-between start">
                <div class="label-field" fxFlex="100">
                  <bh-label-chips *ngIf="!equipment"
                                  (labelAdded)="addLabel($event)"
                                  (labelDeleted)="deleteLabel($event)"
                                  [customerLabels]="customerLabels | async"
                                  [hasAuthority]="hasAuthority(authorities.EQUIPMENT_MANAGE_LABELS)"
                                  [labels]="equipmentLabels | async">
                  </bh-label-chips>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <button *ngIf="!equipment" class="bottom-button" mat-raised-button matStepperPrevious
                      type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <button *ngIf="equipment" class="bottom-button" mat-raised-button type="button"
                      (click)="dialogRef.close()">
                {{'general.buttons.cancel'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <button *ngIf="!equipment; else saveButton" class="bottom-button" mat-raised-button
                      matStepperNext
                      [disabled]="!equipmentForm.get('data').valid" type="button">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step *ngIf="!equipment">
          <ng-template matStepLabel>{{'modules.equipment.equipmentAddEdit.chooseStock'|translate}}*
          </ng-template>
          <div class="equipment-stepper-content"
               fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="45px">
              <mat-form-field class="hide-bottom-wrapper">
                <mat-label>{{'general.labels.search'|translate:{value: 'general.stock.pl'|translate} }}</mat-label>
                <input type="text" matInput
                       [formControl]="stockFilter">
              </mat-form-field>
            </div>
            <div fxFlex class="stock-select">
              <bh-equipment-stock-select [selectedId]="selectedStock | async"
                                         [stocks]="stocks | async"
                                         (stockSelected)="selectStock($event)">
              </bh-equipment-stock-select>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <button *ngIf="!equipment" class="bottom-button" mat-raised-button matStepperPrevious
                      type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <button *ngIf="equipment" class="bottom-button" mat-raised-button type="button"
                      (click)="dialogRef.close()">
                {{'general.buttons.cancel'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>
<ng-template #saveButton>
  <button class="bottom-button"
          mat-raised-button
          (click)="save()"
          [disabled]="!isValid() || saveInProgress"
          color="primary" type="submit"
          data-osp-test="button-save">
    {{'general.buttons.save'|translate}}
  </button>
</ng-template>
