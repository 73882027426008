export enum TransportHistoryColumn {
  TRANSPORT_CREATED = 'created',
  TITLE = 'title',
  TRANSPORT_TYPE = 'transportType',
  DESCRIPTION = 'text',
  PRIORITY = 'priority',
  DUE_DATE = 'dueDate',
  ESTIMATED_DURATION = 'estimatedDuration',
  STATE = 'state',
  IMAGE = 'image',
  DOCUMENT = 'document',

  VEHICLE_CUSTOMER_SERIAL_NUMBER = 'equipmentAssignmentDetails.equipmentCustomerSerialNumber',
  VEHICLE_LICENSE_PLATE = 'equipmentAssignmentDetails.equipmentLicensePlate',
  VEHICLE_MODEL = 'equipmentAssignmentDetails.equipmentModel',
  VEHICLE_NAME = 'equipmentAssignmentDetails.equipmentName',
  VEHICLE_PLANNED_START_DATE = 'equipmentAssignmentDetails.startDate',
  VEHICLE_PLANNED_END_DATE = 'equipmentAssignmentDetails.endDate',
  VEHICLE_ACTUAL_START_DATE = 'equipmentAssignmentDetails.executionStartDate',
  VEHICLE_ACTUAL_END_DATE = 'equipmentAssignmentDetails.executionEndDate',

  START_ITEM_NAME = 'startItem.name',
  START_ITEM_ADDRESS = 'startItem.address',
  START_ITEM_LOCATION = 'startItem.location',
  START_ITEM_TYPE = 'startItem.transportItemType',
  START_ITEM_CONTACT_TYPE = 'startItem.contactType',
  START_ITEM_CONTACT_IDENTIFIER = 'startItem.contactIdentifier',
  START_ITEM_COST_CENTER = 'startItem.costCenter',

  TARGET_ITEM_NAME = 'targetItem.name',
  TARGET_ITEM_ADDRESS = 'targetItem.address',
  TARGET_ITEM_LOCATION = 'targetItem.location',
  TARGET_ITEM_TYPE = 'targetItem.transportItemType',
  TARGET_ITEM_CONTACT_TYPE = 'targetItem.contactType',
  TARGET_ITEM_CONTACT_IDENTIFIER = 'targetItem.contactIdentifier',
  TARGET_ITEM_COST_CENTER = 'targetItem.costCenter',

  RELATED_PROJECT_NAME = 'projectInfo.name',
  RELATED_PROJECT_STATUS = 'projectInfo.projectStatus',
  RELATED_PROJECT_COST_CENTER = 'projectInfo.projectCostCenter',
  RELATED_PROJECT_ADDRESS = 'projectInfo.address',
  RELATED_PROJECT_LOCATION = 'projectInfo.location',
}
