import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MapLocationsCluster } from '../../../interfaces/map-location-cluster.interface';
import { SearchProjectLocation } from 'app/modules/disposition/contract/search-project-location.interface';
import { MapLocationMarker } from '../../../interfaces/map-location-marker.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { NavigationMapperService } from 'app/shared/services/navigation-mapper.service';
import { DisplayService } from 'app/shared/display.service';
import { TimezoneDatesService } from '../../../../../timezone/timezone-dates.service';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';

type TableViewPayload = MapLocationsCluster<SearchProjectLocation> | MapLocationMarker<SearchProjectLocation>;

@Component({
  selector: 'bh-map-info-window-table-view-project',
  templateUrl: './map-info-window-table-view-project.component.html',
  styleUrls: ['../map-info-window-table-view.scss']
})
export class MapInfoWindowTableViewProjectComponent implements OnInit, InfoWindowComponent {

  @Input() value: TableViewPayload;

  public items: SearchProjectLocation[] = [];

  constructor(
    private languageService: LanguageService,
    private navigationMapperService: NavigationMapperService,
    private displayService: DisplayService,
    private timezoneDatesService: TimezoneDatesService,
    private ngZone: NgZone,
  ) { }

  public ngOnInit(): void {
    this.items = this.value ? this.getItems(this.value) : [];
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public navigateToViewDetails(id: string): void {
    this.ngZone.run(() => {
      this.navigationMapperService.navigateToProjectViewDetails(id)
        .then(() => this.displayService.displayTableAndDetails());
    });
  }

  public formatDate(date: Date) {
    if (date === null) {
      return '-';
    }
    return this.timezoneDatesService.timeZonedTransform(date,
      'D. MMM yyyy', this.languageService.getCurrentLocale())
  }

  private getItems(value: TableViewPayload): SearchProjectLocation[] {
    if (this.isCluster(value)) {
      return value.markers.filter(m => Boolean(m?.item)).map(m => m.item);
    } else {
      return value.item ? [value.item] : [];
    }
  }

  private isCluster(value: MapLocationsCluster | MapLocationMarker): value is MapLocationsCluster {
    return Boolean((<MapLocationsCluster>value).markers);
  }

}
