import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';

export const MAPS_CONFIG = new InjectionToken<MapsConfig>('angular-google-maps MAPS_CONFIG');

export interface MapsConfig {
  key?: string;
  language?: string;
}


@Injectable()
export class MapLoaderService {
  private _isLoaded = new BehaviorSubject<boolean>(false);
  private requiredParams = {
    libraries: 'places'
  };
  public readonly isLoaded = this._isLoaded.asObservable()
    .pipe(filter(Boolean), take(1));

  constructor(
    @Optional() @Inject(MAPS_CONFIG) configs: MapsConfig = {},
    private httpClient: HttpClient,
  ) {
    this.load(configs);
  }

  private load(configs: MapsConfig): void {
    const path = this.getPath();
    const params = this.getParams(configs);

    this._isLoaded.next(false);
    this.httpClient.jsonp(
      `${[path, params].filter(Boolean).join('?')}`,
      'callback'
    ).subscribe(() => {
      this._isLoaded.next(true);
    });
  }

  private getPath(): string {
    return 'https://maps.googleapis.com/maps/api/js';
  }

  private getParams(configs: MapsConfig): string {
    const params = {
      ...(configs || {}),
      ...this.requiredParams
    }

    return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
  }
}
