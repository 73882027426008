import { ThemeSettings } from '../theme.settings';

export const DEFAULT_OSP_THEME: ThemeSettings = {
  themeDirName: 'default-osp',

  mapIconFrameColor: '#a4d313',
  mapIconPrimaryColor: '#FFFFFF',
  mapIconSecondaryColor: '#f5f5f5',

  chartPrimaryColor: '#93c01c',
  chartSecondaryColor: '#fd241f',
  chartPositiveValueColor: '#1ba7f0',
  chartNegativeValueColor: '#fd241f',

  fleetCompositionWidgetColors: [
    '#4EAE30',
    '#C90621',
    '#FFCC00',
    '#277110',
    '#830214',
    '#A68500',
    '#7ED762',
    '#411BEA',
    '#FFD940',
    '#911EB4',
    '#F58231',
    '#42D4F4',
    '#808000',
    '#FFD8B1',
    '#F032E6',
    '#469990',
    '#FABEBE',
    '#FFE119',
    '#8080FF',
    '#ED7651',
    '#808080',
    '#BF40BF',
    '#F0C80E',
    '#362698',
    '#7E7EB8',
    '#008000',
    '#A0A424',
    '#19CB97',
    '#E43E35',
    '#1EAC41',
    '#FF1493',
    '#1E90FF'
  ]
}
