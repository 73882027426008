import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faLongArrowAltDown, faUndo } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { TransferType } from '../../../modules/disposition/projects/project-list/project-view/project-view-transfer-history/transfer-type';
import { faFilePdf, faMapMarkedAlt, faTrashAlt, faWarehouse } from '@fortawesome/pro-duotone-svg-icons';
import { TransferEntry } from '../../../modules/disposition/projects/project-list/project-view/project-view-transfer-history/transfer-entry';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  faCalendarAlt,
  faCalendarPlus,
  faCheck,
  faQuestion,
  faTimes,
  faWallet,
} from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LanguageService } from '../../services/language.service';
import { TransferRequestState } from '../../../modules/disposition/projects/project-list/project-view/project-view-transfer-history/transfer-request-state-enum';
import { environment } from '../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ReportsService } from '../../../modules/reports/shared/services/reports.service';
import { FileUtils } from '../../fileUtils';
import { Authorities } from '../../enums/authorities.enum';
import { KeycloakService } from '../../../core/keycloak';
import { LocationType } from 'app/shared/enums/location-type.enum';

@Component({
  selector: 'bh-transfer-history-entry',
  templateUrl: './transfer-history-entry.component.html',
  styleUrls: ['./transfer-history-entry.component.scss'],
})
@UntilDestroy()
export class TransferHistoryEntryComponent implements OnInit {

  @ViewChild('panel', {static: true}) panel: MatExpansionPanel;

  @Input() transferType: TransferType;
  @Input() transferDestination: string;
  @Input() targetProjectNumber: string;
  @Input() targetProjectCostCenter: string;
  @Input() targetStockCostCenter: string;
  @Input() timeStamp: string;
  @Input() issuer: string;
  @Input() acknowledgedBy: string;
  @Input() acknowledgeDate: Date;
  @Input() transferItems: TransferEntry[];
  @Input() isDeposit: boolean;
  @Input() transferId: string;
  @Input() internalEmployeeFirstName: string;
  @Input() internalEmployeeLastName: string;
  @Input() externalEmployee: string;
  @Input() comment: string;
  @Input() transferRequestStateChangeDate: string;
  @Input() transferRequestChangedBy: string;
  @Input() isRequest = false;
  @Input() requestState: TransferRequestState;
  @Input() transferDate: Date;
  @Input() transferCreateDate: Date;
  @Input() isPermissionForTransferRequests = false;


  @Output() onRevertRequest = new EventEmitter<string>();
  @Output() onDeclineTransferRequest = new EventEmitter<string>();
  @Output() onAcknowledgeTransferRequest = new EventEmitter<string>();

  public readonly faLongArrowAltDown: IconDefinition = faLongArrowAltDown;
  public readonly faUndo: IconDefinition = faUndo;
  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;
  public readonly faCalendarAlt: IconDefinition = faCalendarAlt;
  public readonly faCalendarPlus: IconDefinition = faCalendarPlus;
  public readonly faFilePdf: IconDefinition = faFilePdf;
  public readonly faWallet: IconDefinition = faWallet;
  public downloading: boolean;
  public groupedTransferItems: {
    sourceId: string;
    sourceLocationIsAccessibleForUser: boolean;
    sourceName: string;
    sourceNumber: string;
    items: TransferEntry[];
    sourceCostCenter: string
  }[];
  public isDeclined: boolean;
  public isAcknowledged: boolean;
  public isPending: boolean;
  public transferRequestIsProcessing = false;
  public isProjectTransfersRevertAuthority = false;

  public sourceIcon: IconDefinition;
  public sourceLabel: string;
  public sourceName: string;
  public sourceNumber: string;
  public sourceCostCenter: string;
  public isSourceProjectNumberDisplay: boolean;
  private sourceType: string;

  public targetIcon: IconDefinition;
  public targetLabel: string;
  public targetName: string;
  public targetNumber: string;
  public targetCostCenter: string;
  public isTargetProjectNumberDisplay: boolean;
  private targetType: string;

  public sourceTooltip: string;
  public linkObjects: string;
  public rollbackTooltip: string;
  public panelClass: string;
  public isRevertDisabled = false;

  private readonly stockIcon: IconDefinition = faWarehouse;
  private readonly projectIcon: IconDefinition = faMapMarkedAlt;
  private readonly consumedIcon: IconDefinition = faTrashAlt;
  private readonly defaultIcon: IconDefinition = faQuestion;

  public get internalEmployee(): string {
    return [this.internalEmployeeFirstName, this.internalEmployeeLastName]
    .filter(Boolean)
    .join(' ');
  }

  constructor(public elem: ElementRef,
              private languageService: LanguageService,
              private reportStore: ReportsService,
              private authService: KeycloakService,
  ) {
  }

  public ngOnInit(): void {
    this.setInitOptions();
  }

  public acknowledgeTransferRequest(event: MouseEvent, transferId: string): void {
    event.stopPropagation();
    this.disableTransferRequestButtons();
    this.onAcknowledgeTransferRequest.emit(transferId);
  }

  public declineTransferRequest(event: MouseEvent, transferId: string): void {
    event.stopPropagation();
    this.disableTransferRequestButtons();
    this.onDeclineTransferRequest.emit(transferId);
  }

  public exportToPdf(event: Event, transferId: string): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.downloading) {
      return;
    }

    this.downloading = true;
    this.reportStore
    .downloadDeliveryNote(transferId)
    .pipe(
      finalize(() => (this.downloading = false)),
    )
    .subscribe({
      next: (response: HttpResponse<Blob>) => FileUtils.downloadFile(response, true),
    });
  }

  public getAmountOfPreviousItems(groupIndex: number): number {
    return this.groupedTransferItems
    .slice(0, groupIndex)
    .reduce((acc, group) => acc + group.items.length, 0);
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public open(): void {
    this.panel.open();
  }

  public revertTransfer(event): void {
    event.stopPropagation();
    this.onRevertRequest.emit(this.transferId);
  }

  private setObjectTypes(): void {
    switch (this.transferType) {
      case TransferType.STOCK_TO_STOCK:
        this.sourceType = LocationType.STOCK;
        this.targetType = LocationType.STOCK;
        break;
      case TransferType.STOCK_TO_PROJECT:
        this.sourceType = LocationType.STOCK;
        this.targetType = LocationType.PROJECT;
        break;
      case TransferType.PROJECT_TO_PROJECT:
        this.sourceType = LocationType.PROJECT;
        this.targetType = LocationType.PROJECT;
        break;
      case TransferType.PROJECT_TO_STOCK:
        this.sourceType = LocationType.PROJECT;
        this.targetType = LocationType.STOCK;
        break;
      case TransferType.PROJECT_TO_CONSUMED:
        this.sourceType = LocationType.PROJECT;
        this.targetType = LocationType.CONSUMED;
        break;
    }
  }

  private setGeneralInfo(): void {
    switch (this.sourceType) {
      case LocationType.STOCK:
        this.sourceIcon = this.stockIcon;
        this.sourceLabel = `${this.translate('general.from')} ${this.translate('general.stock.s')}`;
        this.sourceTooltip = this.translate('general.labels.jumpTo', { value: this.translate('general.stock.s') });
        this.linkObjects = 'stocks';
        break;
      case LocationType.PROJECT:
        this.sourceIcon = this.projectIcon;
        this.sourceLabel = `${this.translate('general.from')} ${this.translate('general.project.s')}`;
        this.sourceTooltip = this.translate('general.labels.jumpTo', { value: this.translate('general.project.s') });
        this.linkObjects = 'projects';
        break;
      default:
        this.sourceIcon = this.defaultIcon;
        this.sourceLabel = this.translate('modules.disposition.projectTransferHistory.unknownTransferType');
    }

    switch (this.targetType) {
      case LocationType.STOCK:
        this.targetIcon = this.stockIcon;
        this.targetLabel = `${this.translate('general.toLocation')} ${this.translate('general.stock.s')}`;
        break;
      case LocationType.PROJECT:
        this.targetIcon = this.projectIcon;
        this.targetLabel = `${this.translate('general.toLocation')} ${this.translate('general.project.s')}`;
        break;
      case LocationType.CONSUMED:
        this.targetIcon = this.consumedIcon;
        this.targetLabel = this.translate('modules.disposition.projectTransferHistory.consumed');
        break;
      default:
        this.targetIcon = this.defaultIcon;
        this.targetLabel = this.translate('modules.disposition.projectTransferHistory.unknownTransferType');
    }

    this.isRevertDisabled = [this.sourceType, this.targetType].every(el => el === LocationType.STOCK);
  }

  private setData(): void {
    switch (this.sourceType) {
      case LocationType.STOCK:
        this.sourceName = this.transferItems[0].sourceStockName;
        this.sourceNumber = null;
        this.sourceCostCenter = this.transferItems[0].sourceStockCostCenter;
        this.isSourceProjectNumberDisplay = false;
        break;
      case LocationType.PROJECT:
        this.sourceName = this.transferItems[0].sourceProjectName;
        this.sourceNumber = this.transferItems[0].sourceProjectNumber;
        this.sourceCostCenter = this.transferItems[0].sourceProjectCostCenter;
        this.isSourceProjectNumberDisplay = true;
        break;
      default:
        this.sourceName = null;
        this.sourceNumber = null;
        this.sourceCostCenter = null;
        this.isSourceProjectNumberDisplay = false;
    }

    switch (this.targetType) {
      case LocationType.STOCK:
        this.targetName = this.transferDestination;
        this.targetNumber = null;
        this.targetCostCenter = this.targetStockCostCenter;
        this.isTargetProjectNumberDisplay = false;
        break;
      case LocationType.PROJECT:
        this.targetName = this.transferDestination;
        this.targetNumber = this.targetProjectNumber;
        this.targetCostCenter = this.targetProjectCostCenter;
        this.isTargetProjectNumberDisplay = true;
        break;
      case LocationType.CONSUMED:
      default:
        this.targetName = null;
        this.targetNumber = null;
        this.targetCostCenter = null;
        this.isTargetProjectNumberDisplay = false;
    }
  }

  private setRollbackTooltip(): void {
    this.isRevertDisabled
      ? this.rollbackTooltip = this.translate('general.labels.transferRollback.notPossible')
      : this.rollbackTooltip = this.translate('general.labels.transferRollback.possible');
  }

  private disableTransferRequestButtons(): void {
    this.transferRequestIsProcessing = true;
    setTimeout(() => {
      this.transferRequestIsProcessing = false;
    }, environment.DELAY_SHORT);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private groupTransferItemsAccordingToSource(): void {
    switch (this.transferType) {
      case TransferType.STOCK_TO_STOCK:
      case TransferType.STOCK_TO_PROJECT:
        let sourceStockIds = [...new Set(this.transferItems.map(transferItem => transferItem.sourceStockId))];
        this.groupedTransferItems = sourceStockIds.map(sourceStockId => {
          return {
            sourceId: sourceStockId,
            sourceLocationIsAccessibleForUser: this.transferItems[0].sourceLocationIsAccessibleForUser,
            sourceCostCenter: this.transferItems
            .find(transferItem => transferItem.sourceStockId === sourceStockId).sourceStockCostCenter,
            sourceName: this.transferItems.find(transferItem => transferItem.sourceStockId === sourceStockId).sourceStockName,
            sourceNumber: null,
            items: this.transferItems.filter(transferItem => transferItem.sourceStockId === sourceStockId),
          };
        });
        break;
      case TransferType.PROJECT_TO_STOCK:
      case TransferType.PROJECT_TO_PROJECT:
      case TransferType.PROJECT_TO_CONSUMED:
        let sourceProjectIds = [...new Set(this.transferItems.map(transferItem => transferItem.sourceProjectId))];
        this.groupedTransferItems = sourceProjectIds.map(sourceProjectId => {
          return {
            sourceId: sourceProjectId,
            sourceLocationIsAccessibleForUser: this.transferItems[0].sourceLocationIsAccessibleForUser,
            sourceCostCenter: this.transferItems
            .find(transferItem => transferItem.sourceProjectId === sourceProjectId).sourceProjectCostCenter,
            sourceName: this.transferItems.find(transferItem => transferItem.sourceProjectId === sourceProjectId).sourceProjectName,
            sourceNumber: this.transferItems.find(transferItem => transferItem.sourceProjectId === sourceProjectId).sourceProjectNumber,
            items: this.transferItems.filter(transferItem => transferItem.sourceProjectId === sourceProjectId),
          };
        });
        break;
      default:
        this.groupedTransferItems = [{
          sourceId: '',
          sourceLocationIsAccessibleForUser: false,
          sourceCostCenter: '',
          sourceName: '',
          sourceNumber: '',
          items: this.transferItems,
        }];
    }
  }

  private setPanelClass(): void {
    if (this.isRequest) {
      this.panelClass = this.requestState === TransferRequestState.OPEN ? 'pending' : 'archived';
    } else {
      this.panelClass = this.isDeposit ? 'deposit' : 'withdrawal';
    }
  }

  private setInitOptions(): void {
    this.isDeclined = this.requestState === TransferRequestState.DECLINED;
    this.isAcknowledged = this.requestState === TransferRequestState.ACCEPTED;
    this.isPending = this.requestState === TransferRequestState.OPEN;
    this.isProjectTransfersRevertAuthority = this.authService.hasAuthority(Authorities.PROJECT_TRANSFERS_REVERT);
    this.groupTransferItemsAccordingToSource();
    this.setObjectTypes();
    this.setGeneralInfo();
    this.setData();
    this.setRollbackTooltip();
    this.setPanelClass();
  }
}
