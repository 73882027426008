<div class="list-view" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <mat-card [ngClass]="{'list-item-hidden': stockStore.fullScreen,
                          'list-item-margin': !stockStore.fullScreen}"
              class="no-rounded-corners-left" fxFlex="35"
              data-osp-test="list-stocks">

      <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
        <div class="list-view-main__left-container" fxFlex>
          <div class="list-view-main__left" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
            <div class="list-view-main__left-header" fxFlex="60px" fxLayout="row"></div>

            <ng-container *ngIf="stocks | async as stockItems">
              <div *ngIf="stockItems.length; else noResult"
                   class="list-view-main__left-body" fxFlex>
                <div *ngFor="let stock of stockItems"
                     class="left-body-list" fxLayout="row" fxLayoutAlign="space-between center"
                     [routerLink]="[stock.stockId, detailTab | async]"
                     routerLinkActive="left-body-item--active"
                     [routerLinkActiveOptions]="routerLinkOptions"
                     data-osp-test="row-stock-list">

                  <div class="left-body-list__icon">
                    <fa-icon class="stock-type" [icon]="resolveIcon(stock.stockType)"></fa-icon>
                  </div>

                  <div class="left-body-list__info" fxFlex fxLayout="column" fxLayoutAlign="center start">
                    <div class="name" data-osp-test="row-stock-name">{{stock.name}}</div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <span>{{stock.costCenter ? stock.costCenter : '-'}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <span>{{formatAddress(stock.address)}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noResult>
                <div class="list-view-main__no-result" fxFlex fxLayout="row" fxLayoutAlign="center center">
                  <div>{{'general.labels.noSearchResults'|translate}}</div>
                </div>
              </ng-template>
            </ng-container>

            <div class="list-view-main__left-body-paginator" fxLayout="column" fxLayoutAlign="start stretch">
              <div fxLayout="row" fxLayoutAlign="end center">
                <mat-paginator #paginator
                               [pageSizeOptions]="[5, 25, 50, 100]"
                               [length]="stockStore.getLength() | async"
                               [pageIndex]="stockStore.getIndex() | async"
                               [pageSize]="stockStore.getSize() | async"
                               bhCurrentItemPaginator
                               [data]="stocks | async"
                               [currentItemId]="(this.stockStore.selectedStock | async)?.stockId"
                               [isIdentifierOfItem]="isIdentifierOfStock"
                               (paginationChanges)="stockStore.handlePageEvent($event)">
                </mat-paginator>
              </div>
            </div>

          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="list-view-main-container" fxFlex="{{ stockStore.fullScreen ? 100 : 65}}">
      <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
        <div fxFlex>
          <router-outlet></router-outlet>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>
