<div class="m-view-lifecycle-details" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

  <bh-mobile-sub-header
      [title]="equipmentStore.currentTitle | async"
      [subtitle]="equipmentStore.currentSubtitle | async">
  </bh-mobile-sub-header>

  <div class="m-view-details-menu static-title" fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'modules.equipment.lifecycle.responsibleAdded'|translate}}</span>
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon aria-label="Close">close</mat-icon>
    </button>
  </div>

  <div *ngIf="lifecycleEmployee | async as employeeAssignment; else spinner"
       class="m-view-lifecycle-details-content-wrapper">
    <div class="m-view-lifecycle-details-content">
      <div class="m-view-list-inputs">

        <mat-form-field floatLabel="always">
          <mat-label>{{'general.date'|translate|uppercase}}</mat-label>
          <input matInput readonly
                 value="{{employeeAssignment.logDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()}}">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>{{'general.role.s'|translate|uppercase}}</mat-label>
          <input matInput readonly
                 value="{{employeeAssignment.employeeRole | equipmentEmployeeRole}}">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.lifecycle.oldResponsible'|translate|uppercase}}</mat-label>
          <input matInput readonly value="{{getFullName(employeeAssignment.oldEmployeeFirstName, employeeAssignment.oldEmployeeName)}}">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.lifecycle.newResponsible'|translate|uppercase}}</mat-label>
          <input matInput readonly value="{{getFullName(employeeAssignment.newEmployeeFirstName, employeeAssignment.newEmployeeName)}}">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.lifecycle.handoverOn'|translate|uppercase}}</mat-label>
          <input matInput readonly value="{{employeeAssignment.handOverDate | date:'dd.MM.yyyy' }}">
        </mat-form-field>

        <div class="m-view-lifecycle-submit-info" fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <div>{{'general.labels.createdBy'|translate}}:</div>
            <div>{{employeeAssignment.submittedBy}}</div>
          </div>
          <div>
            <div>{{'general.on'|translate|titlecase}}:</div>
            <div>{{employeeAssignment.submitDate | date:'d. MMMM y':'':getCurrentLocale()}}</div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ng-template #spinner>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
    </div>
  </ng-template>

</div>
