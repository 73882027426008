import { Component, OnInit } from '@angular/core';
import { MarkerItemEquipmentTelematicsLocation } from '../../../../interfaces/marker-item-equipment-telematics-location.interface';
import { MapInfoTelematics } from '../map-info-telematics';

@Component({
  selector: 'bh-map-info-telematics-location',
  templateUrl: '../map-info-telematics.html',
})
export class MapInfoTelematicsLocationComponent extends MapInfoTelematics<MarkerItemEquipmentTelematicsLocation> implements OnInit {

  public ngOnInit(): void {
    this.setInfoDates();
    super.ngOnInit();
  }

  private setInfoDates(): void {
    this.infoDates = [
      { title: this.translate('general.from'), date: this.value?.item?.positionFirstLogDate },
      { title: this.translate('general.to'), date: this.value?.item?.positionLastLogDate },
    ];
  }

}
