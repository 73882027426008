import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({selector: '[bhAutoSizeInput]'})
export class AutoSizeInputDirective implements AfterViewInit, OnDestroy {

  @Input() maxWidth = -1;
  @Input() minWidth = -1;
  @Input() setParentWidth = false;
  @Input() useValueProperty = false;

  private destroy$ = new Subject<void>();

  constructor(private element: ElementRef,
              @Optional() private ngModel: NgModel,
              @Optional() private ngControl: NgControl,
              private renderer: Renderer2) {}

  get paddingWidth(): number {
    let paddingLeft = parseInt(this.style.getPropertyValue('padding-left'), 10);
    let paddingRight = parseInt(this.style.getPropertyValue('padding-right'), 10);
    return paddingLeft + paddingRight;
  }

  get style() {
    return getComputedStyle(this.element.nativeElement, '');
  }

  ngAfterViewInit() {
    if (this.ngModel) {
      // @ts-ignore
      this.ngModel.valueChanges.pipe(
        tap(() => this.updateWidth()),
        takeUntil(this.destroy$),
      ).subscribe();
    } else if (this.ngControl) {
      // @ts-ignore
      this.ngControl.valueChanges.pipe(
        tap(() => this.updateWidth()),
        takeUntil(this.destroy$),
      ).subscribe();
      this.updateWidth();
    } else {
      this.updateWidth();
    }
  }

  ngOnDestroy(): void {
    // noinspection TypeScriptValidateTypes
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('input', ['$event.target'])
  public onInput(event: Event): void {
    if (!this.ngModel && !this.ngControl) {
      this.updateWidth();
    }
  }

  setWidth(width: number): void {
    const {nativeElement} = this.element;
    const parent = this.renderer.parentNode(nativeElement);
    this.setParentWidth
      ? this.renderer.setStyle(parent, 'width', width + 'px')
      : this.renderer.setStyle(nativeElement, 'width', width + 'px');
  }

  setWidthUsingText(text: string): void {
    this.setWidth(this.textWidth(text) + this.paddingWidth);
  }

  textWidth(value: string): number {
    const ctx = this.renderer.createElement('canvas').getContext('2d');
    const {fontStyle, fontVariant, fontWeight, fontSize, fontFamily, letterSpacing} = this.style;
    ctx.font = fontStyle + ' ' + fontVariant + ' ' + fontWeight + ' ' + fontSize + ' ' + fontFamily;
    ctx.letterSpacing = letterSpacing;
    return ctx.measureText(value).width;
  }

  updateWidth(): void {
    const inputText = this.getInputValue();
    const inputTextWidth = this.textWidth(inputText) + this.paddingWidth;
    const setMinWidth = this.minWidth > 0 && this.minWidth > inputTextWidth;
    const setMaxWidth = this.maxWidth > 0 && (this.maxWidth < inputTextWidth);

    if (setMinWidth) {
      this.setWidth(this.minWidth);
    } else if (setMaxWidth) {
      this.setWidth(this.maxWidth);
    } else {
      this.setWidthUsingText(inputText);
    }
  }

  private getInputValue(): string {
    let value: string;
    if (this.useValueProperty) {
      value = this.element.nativeElement?.['value'];
    } else if (this.ngModel) {
      value = this.ngModel.value;
    } else if (this.ngControl) {
      value = this.ngControl.value;
    }
    // @ts-ignore
    return value || this.element.nativeElement?.['value'] || '';
  }
}
