import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomersService } from '../../shared/customers.service';
import { ViewCustomer } from '../../contract/view-customer.interface';
import { MoveCustomerCommand } from '../../contract/move-customer-command';
import { finalize, map } from 'rxjs/operators';

@Component({
  selector: 'bh-customer-move',
  templateUrl: './customer-move.component.html',
  styleUrls: ['./customer-move.component.scss']
})
export class CustomerMoveComponent implements OnInit {
  public rootLevel = 'rootLevel';
  public dialogRef: MatDialogRef<CustomerMoveComponent>;
  public organisationId: string;
  public customerId: string;
  public customerMoveForm: UntypedFormGroup;
  public customers: ViewCustomer[];
  public partnerId: string;
  public childCustomerIds: string[];

  constructor(private customersService: CustomersService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
    this.getCustomers();
  }

  private buildForm() {
    this.customerMoveForm = this.formBuilder.group({
      customerId: [this.customerId, [<any>Validators.required]],
      newParentCustomerId: [null, [<any>Validators.required]]
    });
  }

  get newParentCustomerId() {
    return this.customerMoveForm.get('newParentCustomerId');
  }

  save() {
    if (this.isValid()) {
      let formValue = this.customerMoveForm.getRawValue();
      let cmd = new MoveCustomerCommand();
      cmd.customerId = formValue.customerId;
      cmd.newParentCustomerId = formValue.newParentCustomerId === this.rootLevel ? null : formValue.newParentCustomerId;

      this.customersService.moveCustomer(cmd)
        .pipe(
          finalize(() => {this.dialogRef.close()})
        )
        .subscribe();
    }
  }

  public isValid(): boolean {
    return this.customerMoveForm.valid;
  }

  private getCustomers() {
    this.customersService.getAllCustomers()
      .pipe(
        map(res => res.filter(customer =>
          customer.customerId !== this.customerId &&
          customer.partnerId === this.partnerId &&
          !this.childCustomerIds.includes(customer.customerId)
        ))
      )
      .subscribe(
      res => {
        this.customers = res;
      });
  }
}
