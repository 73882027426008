import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit, HostListener } from '@angular/core';
import { LanguageService } from '../../../../../../../shared/services/language.service';
import { LifeCycle } from '../../../../../contract/life-cycle';
import { LifeCycleType } from '../../../../../contract/lifecycle-type.enum';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { LifeCycleTask } from '../../../../../contract/life-cycle-task';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteLifeCycleContract } from '../../../../../contract/delete-life-cycle.contract';
import { Observable, BehaviorSubject } from 'rxjs';
import { DeleteEquipmentDamageCommand } from '../../../../../contract/delete-equipment-damage-command';
import { RevertManualOperatingHoursCommand } from '../../../../../contract/revert-manual-operating-hours-command';
import { RevertManualMileageCommand } from '../../../../../contract/revert-manual-mileage-command';
import { DeleteEquipmentIncidentCommand } from '../../../../../contract/delete-equipment-incident-command';
import { LifeCycleReminder } from '../../../../../contract/life-cycle-reminder';
import { LifeCycleEmployeeAdded } from '../../../../../contract/life-cycle-employee-added';
import { LifeCycleEmployeeReplaced } from '../../../../../contract/life-cycle-employee-replaced';
import { LifeCycleEmployeeRemoved } from '../../../../../contract/life-cycle-employee-removed';
import { filter, switchMap } from 'rxjs/operators';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { logErrors } from '../../../../../../../shared/utils';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { DeleteReminderAfterCompletionCommand } from '../../../../../contract/delete-reminder-after-completion.command';
import { DeleteMaintenanceTaskAfterCompletionCommand } from '../../../../../contract/delete-maintenance-task-after-completion.command';
import { DeleteEquipmentEmployeeAddedLifecycleCommand } from '../../../../../contract/delete-equipment-employee-added-lifecycle.command';
import { DeleteEquipmentEmployeeReplacedLifecycleCommand } from '../../../../../contract/delete-equipment-employee-replaced-lifecycle.command';
import { DeleteEquipmentEmployeeRemovedLifecycleCommand } from '../../../../../contract/delete-equipment-employee-removed-lifecycle.command';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentLifeCycle } from '../../../../../contract/document-life-cycle';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { EquipmentInvoice } from '../../../../../contract/equipment-invoice.interface';
import { LifeCycleCost } from '../../../../../contract/lifecycle-cost';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { DeleteEquipmentInvoiceCommand } from '../../../../../contract/delete-equipment-invoice-command';

@Component({
  selector: 'bh-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})

@UntilDestroy()
export class EventComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('descriptionElem') set descriptionElement(el: ElementRef) {
    this.descriptionElem = el;
    this.getParamIsPanelDescriptionOverflown();
  };
  @Input() lifeCycle: LifeCycle;
  @Input() equipmentId: string;
  @Input() eventIcon: string | IconDefinition;
  @Input() eventHeaders: [string, string][];
  @Input() eventDescription: [string, string];
  @Input() eventDetails: [string, string][];
  @Input() editDisabled: boolean;
  @Input() editAllowed: boolean;
  @Input() deleteDisabled: boolean;
  @Input() deleteAllowed: boolean;
  @Input() hasAttachments: boolean;
  @Output() deleted = new EventEmitter<string>();

  public get hideToggleButton(): boolean {
    return !this.hasDetails && !this.isPanelDescriptionOverflown;
  }

  public lcDocuments: BehaviorSubject<DocumentLink[]> = new BehaviorSubject(null);
  public attachmentsLoading: boolean;
  public invoiceId: EquipmentInvoice;
  public lifeCycleType = LifeCycleType;
  public detailsForm: UntypedFormGroup;
  public hasDetails = false;
  public isPanelDescriptionOverflown = true;
  private descriptionElem: ElementRef;

  constructor(
    protected equipmentsStore: EquipmentsDataSource,
    protected dialog: MatDialog,
    protected languageService: LanguageService,
    protected router: Router) {
  }

  @HostListener('window:resize')
  public onResize() {
    this.getParamIsPanelDescriptionOverflown();
  }

  public ngOnInit(): void {
    this.detailsForm = new UntypedFormGroup({
      lifeCycleDetails: new UntypedFormControl(),
    });
    this.attachmentsLoading = true;
    if (this.lifeCycle.lifeCycleType === LifeCycleType.EQUIPMENT_COST_EVENT) {
      this.lifeCycle = <LifeCycleCost>this.lifeCycle;
    }
  }

  public ngOnChanges({ eventDescription, eventDetails }: SimpleChanges): void {
    if (eventDetails && eventDetails.currentValue?.length !== eventDetails.previousValue?.length) {
      this.calculateParamHasDetails();
    }

    if (eventDescription && !_.isEqual(eventDescription.currentValue, eventDescription.previousValue)) {
      this.getParamIsPanelDescriptionOverflown();
    }
  }

  public ngAfterViewInit(): void {
    this.getParamIsPanelDescriptionOverflown();
  }

  public ngOnDestroy(): void {
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public filterEventDetails(eventDetails: [string, string][]): [string, string][] {
    return eventDetails.filter(([label, value]) => label || value);
  }

  public eventDetailsTrackBy(index: number, eventDetails: [string, string]): string {
    return `${eventDetails[0]}${eventDetails[1]}`;
  }

  public useCustomFontIcon(lifeCycle: LifeCycle): boolean {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return false;
      default:
        return true;
    }
  }

  public getFileTypeIcon(document: DocumentLink): IconDefinition {
    return FileUtils.getAttachmentFileTypeIcon(document);
  }

  public editLifecycle(lifeCycle: LifeCycle) {
    switch (lifeCycle.lifeCycleType) {

      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        this.router.navigate(['equipments', this.equipmentId, 'edit-damage', lifeCycle.lifecycleId]);
        break;

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        this.router.navigate(['equipments', this.equipmentId, 'update-incident', lifeCycle.lifecycleId]);
        break;

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        this.router.navigate(['maintenance', 'tasks', 'edit', (<LifeCycleTask>lifeCycle).maintenanceTaskId]);
        break;
    }
  }

  public deleteLifecycle(lifeCycle: LifeCycle): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    let deleteCmd: DeleteLifeCycleContract;
    let sendCommandCallback: (cmd: DeleteLifeCycleContract) => Observable<string>;

    dialogRef.afterClosed()
      .pipe(filter((dialogResult: string) => dialogResult === dialogResults.YES))
      .subscribe(() => this.deleted.emit(lifeCycle.lifecycleId));

    switch (lifeCycle.lifeCycleType) {

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        this.deleteEquipmentCostEntry(dialogRef, (<LifeCycleCost>lifeCycle).invoiceId);
        return;

      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        dialogRef.componentInstance.confirmTitle =
          this.translate('modules.equipment.confirmation.title.deleteMaliciousDamage');
        deleteCmd = new DeleteEquipmentDamageCommand();
        sendCommandCallback = (cmd: DeleteLifeCycleContract) => this.equipmentsStore.deleteDamage(cmd);
        break;

      case LifeCycleType.OPERATING_HOURS_UPDATE:
        dialogRef.componentInstance.confirmTitle =
          this.translate('modules.equipment.confirmation.title.deleteOperatingHours');
        deleteCmd = new RevertManualOperatingHoursCommand();
        sendCommandCallback = (cmd: DeleteLifeCycleContract) => this.equipmentsStore.deleteManualOperatingHours(cmd);
        break;

      case LifeCycleType.MILEAGE_UPDATE:
        dialogRef.componentInstance.confirmTitle =
          this.translate('modules.equipment.confirmation.title.deleteMileage');
        deleteCmd = new RevertManualMileageCommand();
        sendCommandCallback = (cmd: DeleteLifeCycleContract) => this.equipmentsStore.deleteManualMileage(cmd);
        break;

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        dialogRef.componentInstance.confirmTitle =
          this.translate('modules.equipment.confirmation.title.deleteIncident');
        deleteCmd = new DeleteEquipmentIncidentCommand();
        sendCommandCallback = (cmd: DeleteLifeCycleContract) => this.equipmentsStore.deleteIncident(cmd);
        break;

      case LifeCycleType.COMPLETED_REMINDER:
        this.deleteReminderEntry(dialogRef, (<LifeCycleReminder>lifeCycle).reminderId);
        return;

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        this.deleteCompletedTaskEntry(dialogRef, (<LifeCycleTask>lifeCycle).maintenanceTaskId);
        return;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        this.deleteEquipmentEmployeeAddedLifecycle(dialogRef, (<LifeCycleEmployeeAdded>lifeCycle).employeeAssignmentId);
        return;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        this.deleteEquipmentEmployeeReplacedLifecycle(dialogRef,
          (<LifeCycleEmployeeReplaced>lifeCycle).newEmployeeAssignmentId);
        return;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        this.deleteEquipmentEmployeeRemovedLifecycle(dialogRef,
          (<LifeCycleEmployeeRemoved>lifeCycle).employeeAssignmentId);
        return;

      default:
        return;
    }
    dialogRef.componentInstance.confirmMessage =
      this.translate('modules.equipment.confirmation.message.processIsIrrevocable');
    deleteCmd.equipmentId = this.equipmentId;
    deleteCmd.lifecycleId = lifeCycle.lifecycleId;

    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => sendCommandCallback(deleteCmd)),
    ).subscribe(logErrors());
  }

  public getEventDocuments(): void {
    this.equipmentsStore.getLifeCycleDetails(this.equipmentId, this.lifeCycle.lifecycleId)
    .pipe(untilDestroyed(this))
    .subscribe(res => {
        if (res) {
          let documents = res as DocumentLifeCycle;
          this.attachmentsLoading = false;
          this.lcDocuments.next(documents.documents);
        }
      },
      error => {
        console.log('error get lifecycle details: ', error);
      });
  }

  public clearEventDocuments(): void {
    this.lcDocuments.next(null);
  }

  public afterPanelCollapse(): void {
    this.getParamIsPanelDescriptionOverflown();
  }

  private calculateParamHasDetails(): void {
    this.hasDetails = Boolean(this.eventDetails?.length > 0);
  }

  private getParamIsPanelDescriptionOverflown(): void {
    setTimeout(() => {
      this.isPanelDescriptionOverflown = Boolean(this.descriptionElem?.nativeElement
        && this.descriptionElem.nativeElement.scrollHeight > this.descriptionElem.nativeElement.clientHeight);
    }, 0);
  }

  private deleteReminderEntry(dialogRef, reminderId: string): void {
    const deleteReminderCmd = new DeleteReminderAfterCompletionCommand(reminderId, this.equipmentId);

    dialogRef.componentInstance.confirmTitle =
      this.translate('modules.equipment.confirmation.title.deleteReminder');
    dialogRef.componentInstance.confirmMessage =
      this.translate('modules.equipment.confirmation.message.processIsIrrevocable');
    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => this.equipmentsStore.deleteReminderAfterCompletion(deleteReminderCmd)),
    ).subscribe(logErrors());
  }

  private deleteCompletedTaskEntry(dialogRef, maintenanceTaskId: string): void {
    const deleteTaskCmd = new DeleteMaintenanceTaskAfterCompletionCommand(maintenanceTaskId);

    dialogRef.componentInstance.confirmTitle =
      this.translate('modules.equipment.confirmation.title.deleteMaintenanceEntry');
    dialogRef.componentInstance.confirmMessage =
      this.translate('modules.maintenance.confirmation.message.deleteCompletedTask');
    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => this.equipmentsStore.deleteMaintenanceTaskAfterCompletion(
        deleteTaskCmd, this.equipmentId)),
    ).subscribe(logErrors());
  }

  private deleteEquipmentEmployeeAddedLifecycle(dialogRef, employeeAssignmentId: string): void {
    const deleteCmd = new DeleteEquipmentEmployeeAddedLifecycleCommand(this.equipmentId, employeeAssignmentId);

    dialogRef.componentInstance.confirmTitle =
      this.translate('modules.equipment.confirmation.title.deleteEntry');
    dialogRef.componentInstance.confirmMessage =
      this.translate('modules.equipment.confirmation.message.entryWillBeRemovedTimeline');
    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => this.equipmentsStore.deleteEquipmentEmployeeAddedLifecycle(deleteCmd)),
    ).subscribe(logErrors());
  }

  private deleteEquipmentEmployeeReplacedLifecycle(dialogRef, employeeAssignmentId: string): void {
    const deleteCmd = new DeleteEquipmentEmployeeReplacedLifecycleCommand(this.equipmentId, employeeAssignmentId);

    dialogRef.componentInstance.confirmTitle =
      this.translate('modules.equipment.confirmation.title.deleteEntry');
    dialogRef.componentInstance.confirmMessage =
      this.translate('modules.equipment.confirmation.message.entryWillBeRemovedTimeline');
    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => this.equipmentsStore.deleteEquipmentEmployeeReplacedLifecycle(deleteCmd)),
    ).subscribe(logErrors());
  }

  private deleteEquipmentEmployeeRemovedLifecycle(dialogRef, employeeAssignmentId: string): void {
    const deleteCmd = new DeleteEquipmentEmployeeRemovedLifecycleCommand(this.equipmentId, employeeAssignmentId);

    dialogRef.componentInstance.confirmTitle =
      this.translate('modules.equipment.confirmation.title.deleteEntry');
    dialogRef.componentInstance.confirmMessage =
      this.translate('modules.equipment.confirmation.message.entryWillBeRemovedTimeline');
    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => this.equipmentsStore.deleteEquipmentEmployeeRemovedLifecycle(deleteCmd)),
    ).subscribe(logErrors());
  }

  private deleteEquipmentCostEntry(dialogRef, invoiceId: string): void {
    dialogRef.componentInstance.confirmTitle = this.translate('modules.equipment.confirmation.title.deleteCostItem');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.processIsIrrevocable');

    const deleteCmd = new DeleteEquipmentInvoiceCommand();
    deleteCmd.equipmentId = this.equipmentId;
    deleteCmd.invoiceId = invoiceId;

    dialogRef.afterClosed().pipe(
      filter((dialogResult: string) => dialogResult === dialogResults.YES),
      switchMap(() => this.equipmentsStore.deleteInvoiceLifecycle(deleteCmd)),
    ).subscribe(logErrors());
  }
}
