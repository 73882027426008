import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import * as moment from 'moment';
import { SearchEquipment } from '../contract/search-equipment.interface';
import { EquipmentsService } from './equipments.service';
import { HttpResponse } from '@angular/common/http';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { DateUtils } from 'app/shared/dateUtils';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';


@Injectable()
export class RentalDatasource {

  private _data: BehaviorSubject<SearchEquipment[]> = new BehaviorSubject([]);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);

  public readonly equipments: Observable<SearchEquipment[]> = this._data.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();

  private _pagination: PaginationInfo;
  private _searchTerms: string = null;
  private _searchEquipmentTypeIds: string[] = [];
  private _searchAvailableFilter: boolean = null;
  private _searchAvailableFilterStart: Date = null;
  private _searchAvailableFilterEnd: Date = null;
  private _searchLabels: string[] = [];
  private _hasContent = false;
  private _emptyResContent = false;
  private _hasSearchFilters = true;

  constructor(private _equipmentsService: EquipmentsService,
              private onlineStatusService: OnlineStatusService,
              private authService: KeycloakService) {
    if (onlineStatusService.onlineStatus) {
      this.init();
    }
  }

  get pagination(): PaginationInfo {
    return this._pagination;
  }

  set searchTerms(searchTerms: string) {
    this._searchTerms = searchTerms;
  }

  set searchEquipmentTypeIds(searchEquipmentTypeIds: string[]) {
    this._searchEquipmentTypeIds = searchEquipmentTypeIds;
  }

  get searchEquipmentTypeIds(): string[] {
    return this._searchEquipmentTypeIds;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchAvailableFilter(searchAvailableFilter: boolean) {
    this._searchAvailableFilter = searchAvailableFilter;
  }

  get searchAvailableFilter(): boolean {
    return this._searchAvailableFilter;
  }

  set searchAvailableFilterStart(searchAvailableFilterStart: Date) {
    this._searchAvailableFilterStart = searchAvailableFilterStart;
  }

  get searchAvailableFilterStart(): Date {
    return this._searchAvailableFilterStart;
  }

  set searchAvailableFilterEnd(searchAvailableFilterEnd: Date) {
    this._searchAvailableFilterEnd = searchAvailableFilterEnd;
  }

  get searchAvailableFilterEnd(): Date {
    return this._searchAvailableFilterEnd;
  }

  set searchLabels(searchLabels: string[]) {
    this._searchLabels = searchLabels;
  }

  get searchLabels(): string[] {
    return this._searchLabels;
  }

  get hasContent(): boolean {
    return this._hasContent;
  }

  get emptyResContent(): boolean {
    return this._emptyResContent;
  }

  get hasSearchFilters(): boolean {
    return this._hasSearchFilters;
  }

  set hasSearchFilters(hasSearchFilters: boolean) {
    this._hasSearchFilters = hasSearchFilters;
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0
    };
  }

  public updateListing(index: number = this.pagination.index,
                       size: number = this.pagination.size): void {
    this.pagination.size = size;
    this.pagination.index = index;

    let params = {
      terms: this._searchTerms,
      page: index,
      size: size,
      type: this._searchEquipmentTypeIds,
      available: this._searchAvailableFilter,
      availableStart: null,
      availableEnd: null,
      label: this._searchLabels
    };

    if (params.available !== null) {
      params.availableStart = moment(this._searchAvailableFilterStart).format('YYYY-MM-DD');
      params.availableEnd = moment(DateUtils.endDateOrDefault(this._searchAvailableFilterEnd)).format('YYYY-MM-DD');
    }

    this._equipmentsService
    .getEquipmentsSearchRental(params)
    .subscribe((res) => {
      this.updateStoreData(res)
    });
  }

  private init(): void {
    this.initPagination();
    let params = {
      page: this.pagination.index,
      size: this.pagination.size,
    };

    if (this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this._equipmentsService
      .getEquipmentsSearchRental(params)
      .subscribe(res => {
        this.updateStoreData(res);
      });
    }
  }

  private updateStoreData(res: HttpResponse<PagedResponse<SearchEquipment>>): void {
    this._hasContent = !(res.status === 204);
    this._emptyResContent = false;

    if (res.body && res.body.content) {
      this._emptyResContent = !res.body.content.length;
      this._data.next(res.body.content);
      this._currentTotalElements.next(res.body.totalElements);
      this._currentTotalPages.next(res.body.totalPages);
    } else {
      this._data.next([]);
      this._currentTotalElements.next(0);
      this._currentTotalPages.next(0);
    }
  }
}
