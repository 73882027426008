import { FilterParams } from './../filter-params.class';
import { FilterType } from './../filter-type';
import * as _ from 'lodash';
import { FilterTypes } from '../filter-types.enum';
import { FilterGroup } from '../filter-group';
import { FilterValue } from '../filter-value';

export abstract class BaseFilterViewConverter<T> {
  protected abstract convertToFilterValue(filterItem: T): FilterValue;

  protected convertedFilter: FilterType;
  constructor(
    protected type: FilterTypes,
    private originalFilters: T[],
    private selectedValues: FilterParams
  ) { }

  public getFilters(): FilterType {
    return this.convertedFilter;
  }

  protected addOriginalFilters(filters: T[]): void {
    this.originalFilters = [...this.originalFilters, ...filters];
  }

  protected convert(): void {
    this.convertedFilter = this.convertToFilterType(this.originalFilters);
  }

  protected resolveFilterValueSelection(filterCategoryName: FilterTypes, value: any): boolean {
    return this.selectedValues.hasParam(filterCategoryName, value);
  }

  protected formatCount(count: number): string {
    return !_.isNil(count) ? `(${count})` : undefined;
  }

  protected convertToFilterType(filterItems: T[]): FilterType {
    return {
      typeName: this.type,
      display: true,
      typeTotal: undefined,
      groups: this.convertToFilterGroups(filterItems)
    };
  }

  protected convertToFilterGroups(filterItems: T[]): FilterGroup[] {
    return [{
      groupName: '',
      display: true,
      groupTotal: undefined,
      filters: filterItems.map(item => this.convertToFilterValue(item)).filter(Boolean)
    }];
  }
}
