import { Directive, ElementRef, Optional, Renderer2 } from '@angular/core';
import { ResizeColumnDirective } from './resize-column.directive';
import { CdkColumnDef } from '@angular/cdk/table';
import { filter, map, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[bhResizeColumnCell]'
})
export class ResizeColumnCellDirective {

  private columnName: string;

  constructor(
    @Optional() private resizeColumnDirective: ResizeColumnDirective,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() column: CdkColumnDef,
  ) {
    this.columnName = column?.name;
    if (this.columnName && resizeColumnDirective) {
      this.initListenerStateChange();
    }
  }

  private initListenerStateChange(): void {
    this.resizeColumnDirective.resizeColumnState
      .pipe(
        map(state => state?.[this.columnName]?.width),
        filter(width => width > 0),
        untilDestroyed(this)
      )
      .subscribe(width => this.applyWidth(width));
  }

  private applyWidth(width: number): void {
    const widthPx = `${width}px`;
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', widthPx);
    this.renderer.setStyle(this.elementRef.nativeElement, 'min-width', widthPx);
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-width', widthPx);
  }

}
