<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="confirmTitle">{{confirmTitle}}</h1>
  <div class="warn-message" fxLayoutAlign="row">
    <mat-icon color="warn">warning</mat-icon>
    <div mat-dialog-content>
      {{confirmMessage}}
    </div>
  </div>
  <div class="checkbox-button-section" fxLayoutAlign="row">
    <div class="checkbox">
      <mat-checkbox #checkbox color="primary">
        {{secondaryConfirmMessage}}
      </mat-checkbox>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="warn" mat-dialog-close="yes" [disabled]="!checkbox.checked">{{buttonMessage}}</button>
    </div>
  </div>
</div>
