import { SafeResourceUrl } from '@angular/platform-browser';
import { IGalleryOrderedImage } from '../interfaces/gallery-ordered-image.interface';


export class GalleryOrderedImage implements IGalleryOrderedImage {
    public src: string | SafeResourceUrl;
    public index: number;

    constructor(obj: IGalleryOrderedImage) {
      this.src = obj.src;
      this.index = obj.index;
    }
}
