<div class="equipmentInfo dialog-wrapper">
  <h2 mat-dialog-title>{{title}}</h2>
  <div mat-dialog-content>
    <div><span class="identifier">{{'modules.telematic.telematicUnitEquipmentInfo.imei'|translate}}:</span>{{telematicUnitCustomerEquipmentInfo.imei}}</div>
    <div>
      <span class="identifier">{{'modules.telematic.telematicUnitEquipmentInfo.customerSerialNumber'|translate}}:</span>
      {{telematicUnitCustomerEquipmentInfo.customerSerialNumber}}
    </div>
    <div>
      <span class="identifier">{{'modules.telematic.telematicUnitEquipmentInfo.equipmentName'|translate}}:</span>
      {{telematicUnitCustomerEquipmentInfo.equipmentName}}
    </div>
    <div>
      <span class="identifier">{{'modules.telematic.telematicUnitEquipmentInfo.manufacturerSerialNumber'|translate}}
        :</span>
      {{telematicUnitCustomerEquipmentInfo.manufacturerSerialNumber}}
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true">{{'general.buttons.close'|translate}}</button>
  </div>
</div>
