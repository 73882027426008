import { AbstractControl } from '@angular/forms';
import { EquipmentInvoice } from '../../modules/equipment/contract/equipment-invoice.interface';

export class InvoiceNumberExistValidator {
  static isValid(invoices: EquipmentInvoice[]) {
    return (control: AbstractControl) => {
      if (control.value) {
        let invoice = invoices.find(inv => inv.invoiceId === control.value || inv.invoiceNumber === control.value);

        return invoice ? null : { invoiceExists: control.value };
      }

      return null;
    };
  }
}
