import {BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';
import { WidgetConfiguration } from 'app/shared/contract/user-configuration/dashboard-configuration';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { WidgetRegistry } from '../widgets/widget-registry';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { SaveUserDashboardCommand } from 'app/modules/userrole/contract/save-user-dashboard-command';
import { UsersService } from 'app/modules/userrole/shared/users.service';

@Injectable()
export class DashboardStore {
  private _editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly editMode = this._editMode.asObservable();

  constructor(
    private userConfigurationService: UserConfigurationService,
    private widgetRegistry: WidgetRegistry,
    private usersService: UsersService
  ) {}

  public setEditMode(editMode: boolean): void {
    this._editMode.next(editMode);
  }

  public getSavedDashboard(): WidgetConfiguration[] {
    return this.userConfigurationService.getDashboardConfiguration()
      .filter(widget => this.widgetRegistry.widgetDefinitions.get(widget.widgetType))
      .map(widget => this.widgetRegistry.getFullConfiguration(widget));
  }

  public saveDashboard(widgets: WidgetConfiguration[]): void {
    this.saveDashboardConfigurations(widgets);
  }

  private saveDashboardConfigurations(widgets: WidgetConfiguration[]): void {
    this.userConfigurationService.saveDashboardConfiguration(widgets);
    let cmd = new SaveUserDashboardCommand();
    cmd.widgets = widgets;
    this.usersService.saveDashBoardConfig(cmd).then(() => this.setEditMode(false));
  }

  public saveWidgetMapConfig(mapConfig: MapConfiguration, index: number): void {
    if (this._editMode.getValue()) {
      return;
    }

    const widgets = this.userConfigurationService.getDashboardConfiguration();
    widgets[index].mapConfiguration = mapConfig;
    this.saveDashboardConfigurations(widgets);
  }
}
