import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { EquipmentsService } from '../../../../shared/equipments.service';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { BaseEquipmentLogMileage } from '../base/base-equipment-log-mileage';
import { RouterHistory } from '../../../../../../shared/router-history';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DimensionUnitPipe } from '../../../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../../../shared/pipes/dimension-unit-converter.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-log-mileage',
  templateUrl: '../../landscape/equipment-log-lifecycle.component.html',
  styleUrls: ['../../landscape/equipment-log-lifecycle.component.scss']
})
export class EquipmentLogMileageComponent extends BaseEquipmentLogMileage implements OnInit {

  constructor(authService: KeycloakService,
              router: Router,
              route: ActivatedRoute,
              formBuilder: UntypedFormBuilder,
              equipmentsService: EquipmentsService,
              equipmentStore: EquipmentsDataSource,
              equipmentStatusService: EquipmentCheckerService,
              routerHistory: RouterHistory,
              dimensionUnitPipe: DimensionUnitPipe,
              dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              public dialogRef: MatDialogRef<EquipmentLogMileageComponent>,
              protected languageService: LanguageService) {
    super(authService,
      router,
      route,
      formBuilder,
      equipmentsService,
      equipmentStore,
      equipmentStatusService,
      routerHistory,
      languageService,
      dimensionUnitPipe,
      dimensionUnitConverterPipe);
  }

}
