<div fxFlex fxLayout="column" class="wrapper-container">
  <div class="container-fields">
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.maintenanceTaskName' | translate}}</mat-label>
      <input matInput readonly [value]="task?.name">
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'general.equipm.s' | translate}}</mat-label>
      <input matInput
             readonly
             [value]="(task?.equipmentName ? task?.equipmentName : task?.equipmentModel)"
             data-osp-test="input-maintenance-equipment-name">
      <span matSuffix>
          <mat-icon class="icon-navigation"
                    (click)="navigateToEquipment(task?.equipmentId)"
                    [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.equipm.s'|translate }">
            open_in_new
          </mat-icon>
      </span>
    </mat-form-field>
    <mat-form-field *ngIf="task?.category !== categories.REMINDER" class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.serviceType' | translate}}</mat-label>
      <input matInput readonly [value]="task?.maintenanceTypeName">
    </mat-form-field>

    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.completionDate' | translate}}</mat-label>
      <input matInput readonly [value]="task?.completedAtDate | formatTimezoneDateTime">
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.completedBy' | translate}}</mat-label>
      <input matInput readonly [value]="task?.completedByInfo | submitterInfo">
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.submittedAtDate' | translate}}</mat-label>
      <input matInput readonly [value]="task?.completionSubmittedAtDate | formatTimezoneDateTime">
    </mat-form-field>
  </div>

  <div class="container-fields">
    <ng-container
      [ngTemplateOutlet]="task?.category === categories.REMINDER ? reminderFields : maintenanceFields"
      [ngTemplateOutletContext]="{ task }">
    </ng-container>
  </div>

  <ng-container *ngIf="task?.category !== categories.REMINDER
    && task?.maintenanceTaskCompletionInfo?.maintenanceFieldValues?.length > 0">
    <p class="section-title">{{'modules.maintenance.maintenanceViewDetail.additionalInfo' | translate}}</p>
    <div class="container-fields">
      <ng-container *ngFor="let field of task.maintenanceTaskCompletionInfo.maintenanceFieldValues | sortMaintenanceFields"
        [ngTemplateOutlet]="fieldResolver"
        [ngTemplateOutletContext]="{ field }">
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #fieldResolver let-field="field">
  <mat-form-field *ngIf="field?.maintenanceFieldType && ((field.maintenanceTypeFieldName | isAllowedDynamicField) || field?.value !== null)"
                  [ngSwitch]="field.maintenanceFieldType" class="field" floatLabel="always">
    <mat-label>{{field?.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</mat-label>
    <input *ngSwitchCase="fieldTypes.DATE"
      matInput
      readonly
      [value]="field?.value | date: 'dd.MM.yyyy'">
    <textarea *ngSwitchCase="fieldTypes.LONG_TEXT"
      matInput
      readonly
      [value]="field?.value"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="3">
    </textarea>
    <input *ngSwitchDefault
      matInput
      readonly
      [value]="field?.value">
  </mat-form-field>
</ng-template>

<ng-template #maintenanceFields let-task="task">
  <mat-form-field class="field exclude-disable-style" floatLabel="always">
    <mat-label>{{ 'modules.maintenance.task.result'|translate }}</mat-label>
    <mat-select #maintenanceResultSelect
      [value]="task?.maintenanceResult"
      [disabled]="true"
      [disableOptionCentering]="true">
      <mat-select-trigger *ngIf="task?.maintenanceResult as result">
        <bh-maintenance-result-badge [result]="result"></bh-maintenance-result-badge>
      </mat-select-trigger>
      <mat-option *ngFor="let maintenanceResult of maintenanceResults" [value]="maintenanceResult">
        <bh-maintenance-result-badge [result]="maintenanceResult"></bh-maintenance-result-badge>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="field" floatLabel="always">
    <mat-label>{{'shared.maintenance.task.completedAtOperatingHours' | translate}}</mat-label>
    <input matInput readonly [value]="task?.completedAtOperatingHours">
  </mat-form-field>
  <mat-form-field class="field" floatLabel="always">
    <mat-label>{{'shared.maintenance.task.completedAtMileage' | translate}} ({{ 'km' | dimensionUnitDisplay }})</mat-label>
    <input matInput readonly [value]="task?.completedAtMileage | dimensionUnitConverter: 'km'">
  </mat-form-field>
</ng-template>

<ng-template #reminderFields let-task="task">
  <mat-form-field class="field" floatLabel="always">
    <mat-label>{{'shared.maintenance.task.reminderDate' | translate}}</mat-label>
    <input matInput readonly [value]="task?.reminderDate | date: 'dd.MM.yyyy'">
  </mat-form-field>
</ng-template>
