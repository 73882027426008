import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { EquipmentCheckerService } from 'app/modules/equipment/shared/services/equipment-checker.service';
import { ColumnDefinition } from 'app/shared/column-definition';
import { TableConfigurationDialogComponent } from 'app/shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { COLUMN_TYPE } from 'app/shared/constants/column-definition-constants';
import { TableView } from 'app/shared/contract/table-view/table-view.interface';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { Modules } from 'app/shared/enums/modules.enum';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from 'app/shared/router-history';
import _ from 'lodash';
import { BehaviorSubject, filter } from 'rxjs';
import { MaintenanceTaskCompletedSearch } from '../../shared/maintenance-task-completed-search.interface';
import { MaintenanceCompletedColumnService } from '../../shared/service/maintenance-completed-column.service';
import { MaintenanceTaskCompletedExportDialogComponent } from '../maintenance-task-completed-export-dialog/maintenance-task-completed-export-dialog.component';
import {ThumbnailSize} from '../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-completed-list-table',
  templateUrl: './maintenance-task-completed-list-table.component.html',
  styleUrls: ['../../shared/assets/maintenance-list-table-shared.scss', './maintenance-task-completed-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceTaskCompletedListTableComponent
  extends GuardedNavigableInputComponent
  implements OnInit, TableView<MaintenanceTaskCompletedSearch> {

  @Input() public data: MaintenanceTaskCompletedSearch[] = [];
  @Input() public index: number;
  @Input() public size: number;
  @Input() public length: number;
  @Input() public routerLinkField: string = null;
  @Output() public pageChange = new EventEmitter<PageEvent>();
  @Output() public sortChange = new EventEmitter<Sort>();
  @Output() public rowDoubleClick = new EventEmitter();

  private _singleOverviewColumn = new BehaviorSubject<boolean>(false);
  public readonly singleOverviewColumn = this._singleOverviewColumn.asObservable();

  public readonly allowedToManage = this.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE) && this.hasModule(Modules.SERVICE);
  public readonly allowedToSee = this.hasAuthority(Authorities.MAINTENANCE_TASK_VIEW) && this.hasModule(Modules.SERVICE);
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly COLUMN_DEFINITION = { ...GENERAL_COLUMN_DEF, ...MAINTENANCE_TASK_COLUMN_DEF };
  public readonly overviewColumnName = MaintenanceCompletedColumnService.overviewColumnName;
  public displayedColumns: string[] = [];
  public selectedColumns: ColumnDefinition[] = [];
  public readonly faFileExcel: IconDefinition = faFileExcel;

  constructor(
    authService: KeycloakService,
    router: Router,
    route: ActivatedRoute,
    routerHistory: RouterHistory,
    private columnService: MaintenanceCompletedColumnService,
    public equipmentCheckerService: EquipmentCheckerService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public getFirstLabels(equipment: any, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.equipmentLabels || []];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(equipment: any, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.equipmentLabels || []];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }

  public paginationChange(event: PageEvent): void {
    this.pageChange.emit(event);
  }

  public navigateToEquipmentTasks(equipmentId: string): void {
    if (equipmentId) {
      this.router.navigate(['assets', 'equipment', 'list', equipmentId, 'tasks'], {
        queryParams: {tabIndex: 1},
      });
    }
  }

  public concatTransportTypes(equipment: MaintenanceTaskCompletedSearch): string {
    return equipment?.transportTypes?.join(', ');
  }

  public sortData(sort: Sort): void {
    this.sortChange.next(sort);
  }

  public openConfiguration(): void {
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {columnService: this.columnService},
      restoreFocus: false,
      autoFocus: false,
    });

    dialogRef.afterClosed()
      .pipe(
        filter(selectedColumns => !_.isNil(selectedColumns)),
        untilDestroyed(this))
      .subscribe((selectedColumns: ColumnDefinition[]) => this.columnService.selectColumns(selectedColumns));
  }

  public openMaintenanceListExport(): void {
    this.dialog.open(MaintenanceTaskCompletedExportDialogComponent, {
      autoFocus: false,
      data: {
        selectedColumns: this.selectedColumns,
      },
    });
  }

  private initListeners(): void {
    this.initSelectedColumnsListener();
    this.initDisplayedColumnsListener();
  }

  private initSelectedColumnsListener(): void {
    this.columnService.selectedColumns
      .pipe(untilDestroyed(this))
      .subscribe((selectedColumns: ColumnDefinition[]) => {
        this.selectedColumns = selectedColumns;
        this.cdr.markForCheck();
      });
  }

  private initDisplayedColumnsListener(): void {
    this.columnService.displayedColumns
      .pipe(untilDestroyed(this))
      .subscribe((columns: string[]) => {
        this._singleOverviewColumn.next(this.isOverviewColumnOnly(columns));
        this.displayedColumns = _.cloneDeep(columns);
        this.cdr.markForCheck();
      });
  }

  private isOverviewColumnOnly(columns: string[]): boolean {
    return columns && columns?.length === 1 && columns[0] === MaintenanceCompletedColumnService.overviewColumnName;
  }

  protected readonly ThumbnailSize = ThumbnailSize;
}
