import { MaintenanceCategoryPipe } from 'app/shared/pipes/maintenance-category.pipe';
import { FilterParams } from '../filter-params.class';
import { FilterTypes } from '../filter-types.enum';
import { FilterValue } from '../filter-value';
import { MaintenanceCategoryFilterView } from '../filter-view/maintenance-category-filter-view.interface';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';

export class MaintenanceCategoryFilterViewConverter extends BaseFilterViewConverter<MaintenanceCategoryFilterView> {
  constructor(
    originalFilters: MaintenanceCategoryFilterView[],
    selectedValues: FilterParams,
    private maintenanceCategoryResolver: MaintenanceCategoryPipe
  ) {
    super(FilterTypes.MAINTENANCE_CATEGORY, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: MaintenanceCategoryFilterView): FilterValue {
    return {
      displayName: this.maintenanceCategoryResolver.transform(filterItem.maintenanceCategory),
      storeName: filterItem.maintenanceCategory,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.maintenanceCategory),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
