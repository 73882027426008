import { Component, OnInit } from '@angular/core';
import { ITransferItemWithValidityStatus, TransferItem } from '../../../../shared/transfer/model/transfer-item';
import moment from 'moment';
import { KeycloakService } from '../../../../../../core/keycloak';
import { LocationType } from '../../../../../../shared/enums/location-type.enum';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { UntypedFormControl } from '@angular/forms';
import { TransferProject } from '../bulk-item-transfer-project-select/transfer-project';
import { TransferStock } from '../bulk-item-transfer-stock-select/transfer-stock';
import { ReportsService } from '../../../../../reports/shared/services/reports.service';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { FileUtils } from '../../../../../../shared/fileUtils';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFilePdf } from '@fortawesome/pro-duotone-svg-icons';
import { faWallet } from '@fortawesome/pro-light-svg-icons';
import { ITransfer } from '../../../../contract/transfer.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { TimezoneDatesService } from '../../../../../../shared/timezone/timezone-dates.service';

@Component({
  selector: 'bh-transfer-cart-summary',
  templateUrl: './transfer-cart-summary.component.html',
  styleUrls: ['./transfer-cart-summary.component.scss']
})
export class TransferCartSummaryComponent implements OnInit {

  public transferItems: TransferItem[];
  public transferDestinationType: LocationType;
  public executedTransfers: ITransfer[];
  public transferFromProject: ITransfer;
  public transferFromStock: ITransfer;
  public transferTarget: TransferProject | TransferStock;

  public transferItemsWithSourceFromStock: TransferItem[] = null;
  public transferItemsWithSourceFromProject: TransferItem[] = null;
  public currentTime = null;
  public currentUser: string;
  public downloadingFromProject = false;
  public downloadingFromStock = false;

  public readonly faFilePdf: IconDefinition = faFilePdf;
  public readonly faWallet: IconDefinition = faWallet;

  public selected = new UntypedFormControl(0);

  public transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];

  constructor(protected authService: KeycloakService,
              private reportsService: ReportsService,
              private snackBar: MatSnackBar,
              private languageService: LanguageService,
              private timezoneDatesService: TimezoneDatesService) {
  }

  ngOnInit(): void {
    this.currentTime = this.timezoneDatesService.timeZonedTransform(new Date(moment.now()), 'DD.MM.YYYY HH:mm')
    this.currentUser = this.authService.getUserFullName();
    this.transferFromProject = this.executedTransfers[0];
    this.transferFromStock = this.executedTransfers[1];
    this.transferItemsWithSourceFromProject =
      this.transferItems.filter(item => item.currentLocationType === LocationType.PROJECT);
    this.transferItemsWithSourceFromStock =
      this.transferItems.filter(item => item.currentLocationType === LocationType.STOCK);
    this.initalizeTransferTab();
  }

  private initalizeTransferTab(): void {
    this.displayProjectTransferTab() ? this.setCurrentShownTransferId(0) : this.setCurrentShownTransferId(1);
  }

  public get confirmLabel(): string {
    return this.languageService.getInstant('general.buttons.ok');
  }

  public displayProjectTransferTab(): boolean {
    return this.transferItemsWithSourceFromProject.length > 0;
  }

  public displayStockTransferTab(): boolean {
    return this.transferItemsWithSourceFromStock.length > 0;
  }

  public transferToProject(): boolean {
    return this.transferDestinationType === LocationType.PROJECT;
  }

  public getCurrentShownTransferId(): string {
    return this.executedTransfers.length === 1 ? this.executedTransfers[0]?.deliveryNoteNumber :
      this.executedTransfers[this.selected.value]?.deliveryNoteNumber;
  }

  public setCurrentShownTransferId(index: number): void {
    this.selected.setValue(index);
  }

  public exportTransferFromProjectToPdf(event: Event, transferId: string): void {
    if (this.downloadingFromProject) {
      return;
    }
    this.downloadingFromProject = true;

    this.reportsService.downloadDeliveryNote(transferId)
      .pipe(finalize(() => this.downloadingFromProject = false))
      .subscribe({
        next: (response: HttpResponse<Blob>) => FileUtils.downloadFile(response, true, transferId),
        error: () => {
          this.snackBar.open(
            this.translate('shared.fileDownload.errorDuringDownloading',
            {value: this.translate('modules.disposition.projectTransferHistory.deliveryNote')}),
            undefined,
            {duration: environment.DELAY_LONG}
          );
        }
    });
  }

  public exportTransferFromStockToPdf(event: Event, transferId: string): void {
    if (this.downloadingFromStock) {
      return;
    }
    this.downloadingFromStock = true;

    this.reportsService.downloadDeliveryNote(transferId)
      .pipe(finalize(() => this.downloadingFromStock = false))
      .subscribe({
        next: (response: HttpResponse<Blob>) => FileUtils.downloadFile(response, true, transferId),
        error: () => {
          this.snackBar.open(
            this.translate('shared.fileDownload.errorDuringDownloading',
            {value: this.translate('modules.disposition.projectTransferHistory.deliveryNote')}),
            undefined,
            {duration: environment.DELAY_LONG}
          );
        }
    });
  }

  private translate(key: string, interpolateParam?: Object): string {
    return this.languageService.getInstant(key, interpolateParam);
  }
}
