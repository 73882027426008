import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportListComponent } from './report-list/report-list.component';
import { reportsRouting } from './reports.routing';
import { ReportsService } from './shared/services/reports.service';
import { ReportsDataSource } from './shared/services/reports.datasource';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { ReportAddEditComponent } from './report-add-edit/report-add-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { ReportParameterPipe } from './shared/pipes/report-parameter.pipe';
import { ReportTypePipe } from './shared/pipes/report-type.pipe';
import { ReportExportFormatPipe } from './shared/pipes/report-export-format.pipe';
import { ReportViewComponent } from './report-view/report-view.component';
import { ReportGenerationComponent } from './report-view/report-generation/report-generation.component';
import {
  GenerateReportComponent
} from './report-view/report-generation/generate-report/generate-report.component';
import { GroupByPipe, NgPipesModule } from 'ngx-pipes';
import { ReportJobsComponent } from './report-view/report-jobs/report-jobs.component';
import { AddEditReportJobComponent } from './report-view/report-jobs/add-edit-report-job/add-edit-report-job.component';
import { ReportJobIntervalPipe } from './shared/pipes/report-job-interval.pipe';
import {
  AllSelectedWarningDialogComponent
} from './report-view/all-selected-warning-dialog/all-selected-warning-dialog.component';
import { FilterCollectionPipe } from './report-view/filter-collection.pipe';
import { ConcatCollectionPipe } from './report-view/concat-collection.pipe';
import { MainPipeModule } from 'app/shared/pipes/main-pipe.module';
import { OspUiPipesModule } from '../osp-ui/pipes/osp-ui-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    reportsRouting,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedModule,
    FileUploadModule,
    NgPipesModule,
    TranslateModule,
    MainPipeModule,
    OspUiPipesModule,
  ],
  declarations: [
    ReportListComponent,
    ReportAddEditComponent,
    ReportParameterPipe,
    ReportTypePipe,
    ReportExportFormatPipe,
    ReportViewComponent,
    ReportGenerationComponent,
    GenerateReportComponent,
    ReportJobsComponent,
    AddEditReportJobComponent,
    ReportJobIntervalPipe,
    AllSelectedWarningDialogComponent,
    FilterCollectionPipe,
    ConcatCollectionPipe
  ],
  providers: [
    ReportsService,
    ReportsDataSource,
    GroupByPipe
  ]
})
export class ReportsModule { }
