<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar" data-osp-test="bar-search">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex>
        <div class="search-bar-input" fxLayout="row">
          <input fxFlex matInput class="search-input" [formControl]="termControl" data-osp-test="input-search">
          <button mat-button [matTooltip]="'general.buttons.resetSearchTerm'|translate" fxFlex="40px"
                  (click)="termControl.reset('')">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <button mat-button
              class="search-bar-button-right"
              fxFlex="50px"
              [matTooltip]="'modules.maintenance.maintenanceRuleList.createRule'|translate"
              routerLink="/maintenance/rules/add"
              data-osp-test="button-add-maintenance-rule">
        <mat-icon class="maintenance-list-icon">add_box</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="maintenance-card search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="card-title-panel" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="card-title-icon maintenance-list-icon">build</mat-icon>
        <span class="maintenance-card-title">{{'modules.maintenance.maintenanceRuleList.maintenanceInspectionRules'|translate}}</span>
      </div>
      <div class="header-divider"></div>
      <div class="filter-form-panel">
        <form [formGroup]="filterForm" fxLayoutGap="15px">
          <mat-form-field fxFlex="15">
            <mat-select multiple [placeholder]="'general.serviceType.s'|translate" formControlName="maintenanceTypes">
              <mat-option *ngFor="let maintenanceType of (maintenanceRulesFilter | async).maintenanceTypes"
                          [value]="maintenanceType.maintenanceTypeId">
                {{maintenanceType.maintenanceTypeName}} ({{maintenanceType.count}})
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="15">
            <mat-select multiple [placeholder]="'modules.maintenance.base.articleGroup'|translate" formControlName="articleGroups">
              <mat-option *ngFor="let item of (maintenanceRulesFilter | async).articleGroups"
                          [value]="item.articleGroup">
                {{item.articleGroup}} ({{item.count}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="table-panel" fxFlex>
        <mat-table [dataSource]="ruleStore" class="data-table" fxFlex>

          <ng-container cdkColumnDef="categoryIcon">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let rule">
              <mat-icon
                  class="rule-icon">{{rule.maintenanceCategory | maintenanceCategory: 'icon'}}</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef>{{'general.name.s'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let rule">{{rule.ruleName}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="serviceType">
            <mat-header-cell *cdkHeaderCellDef>{{'general.serviceType.s'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let rule">{{rule.maintenanceTypeName}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="intervals">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.maintenance.maintenanceRuleList.dueDate'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let rule">{{ruleStore.getRuleIntervals(
                rule.maintenanceRuleId)}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="status">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let rule">
              <mat-icon class="status-menu maintenance-list-icon"
                        [matMenuTriggerFor]="maintenanceTypeOptions">
                more_vert
              </mat-icon>

              <mat-menu #maintenanceTypeOptions="matMenu">
                <button mat-menu-item (click)="openRuleView(rule)">
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>{{'general.buttons.viewRule'|translate}}</span>
                </button>
                <button mat-menu-item [routerLink]="['/', 'maintenance', 'rules', 'edit', rule.maintenanceRuleId]">
                  <mat-icon>edit</mat-icon>
                  <span>{{'modules.maintenance.maintenanceRuleList.editRule'|translate}}</span>
                </button>
                <button mat-menu-item (click)="showAssignedEquipments(rule)"
                        [disabled]="noAssignedEquipments(rule.maintenanceRuleId)">
                  <mat-icon>list</mat-icon>
                  <span>{{'modules.maintenance.maintenanceRuleList.assignedEquipment'|translate}}</span>
                </button>
                <button mat-menu-item (click)="deleteRule(rule.maintenanceRuleId)">
                  <mat-icon>delete</mat-icon>
                  <span>{{'modules.maintenance.maintenanceRuleList.deleteRule'|translate}}</span>
                </button>
              </mat-menu>

            </mat-cell>
          </ng-container>

          <mat-header-row
              *cdkHeaderRowDef="['categoryIcon', 'name', 'serviceType', 'intervals', 'status']"></mat-header-row>
          <mat-row
              *cdkRowDef="let row; columns: ['categoryIcon', 'name', 'serviceType', 'intervals', 'status']"></mat-row>
        </mat-table>
      </div>
      <div class="paginator-panel">
        <mat-paginator #paginator
                       [length]="ruleStore.length | async"
                       [pageIndex]="ruleStore.index | async"
                       [pageSize]="ruleStore.size | async"
                       (page)="onPaginateChange($event)"
                       [pageSizeOptions]="[5, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
