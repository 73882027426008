<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
  <mat-card class="list-view-main-container no-rounded-corners-left"
    fxFlex="{{ (displayService.isTableFullscreenChanges | async) ? 100 : 35}}"
    [ngClass]="{
      'list-item-hidden': (displayService.isDetailsFullscreenChanges | async),
      'list-item-margin': (displayService.isTableAndDetailsChanges | async) }">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div fxLayout="row" class="list-view-main__left-header" fxLayoutAlign="end center">
          <div *ngIf="(displayService.isTableFullscreenChanges | async)"
            fxFlex="60px"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="change-display-mode"
            (click)="openDetails()"
            [matTooltip]="'general.labels.detailView.expand'|translate">
            <mat-icon>chevron_left</mat-icon>
          </div>
        </div>

        <bh-maintenance-task-completed-list-table fxFlex
          routerLinkField="id"
          [data]="maintenanceDataSource.data | async"
          [index]="(maintenanceDataSource.pagination | async).index"
          [size]="(maintenanceDataSource.pagination | async).size"
          [length]="(maintenanceDataSource.pagination | async).totalElements"
          (pageChange)="pageChange($event)"
          (sortChange)="sortData($event)"
          (rowDoubleClick)="openDetails()">
        </bh-maintenance-task-completed-list-table>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="list-view-main-container"
    fxFlex="{{ (displayService.isDetailsFullscreenChanges | async) ? 100 : 65}}"
    [ngClass]="{'list-item-hidden': (displayService.isTableFullscreenChanges | async)}">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-card-content>
  </mat-card>
</div>
