export const COLUMN_TYPE = {
  VALUE: 'value',
  IMAGE: 'image',
  EQUIPMENT_NAME: 'equipmentName',
  PRIORITY: 'priority',
  DETAILS: 'details',
  STATUS: 'status',
  LABELS: 'labels',
  SELECT: 'select',
  DATE: 'date',
  ICON: 'icon',
  ADDRESS: 'address',
  RELATED_PROJECT: 'relatedProject',
  TIME: 'time',
  OTHER: 'other',
  LOCATION: 'location',
  SOURCE_LOCATION: 'sourceLocation',
  TARGET_LOCATION: 'targetLocation',
  TELEMATIC: 'telematic',
  ACTION: 'action',
  CURRENT_LOCATION_NAME: 'currentLocationName',
  CURRENT_LOCATION_NUMBER: 'currentLocationNumber',
  CURRENT_LOCATION_COST_CENTER: 'currentLocationCostCenter',
  INTO_TRANSFER_CART: 'intoTransferCart',
  EQUIPMENT_AMOUNT_AVAILABLE: 'equipmentAmountAvailable',
  TRANSFER_TYPE: 'transferType',
  CHARGE_TYPE: 'chargeType',
  ITEM_TYPE: 'itemType',
  ID: 'id',
  NAME: 'name',
  LOCATION_ADDRESS: 'locationAddress',
  LOCATION_VALUE: 'locationValue',
  SORTABLE_VALUE: 'sortableValue',
}
