import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHttpParameters } from 'app/shared/utils';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { AddProjectResponsibleEmployeeCommand } from '../../contract/add-project-responsible-employee-command';
import { ChangeProjectResponsibleEmployeeAcceptanceUserFlagCommand } from '../../contract/change-project-responsible-employee-acceptance-user-flag-command';
import { RemoveProjectResponsibleEmployeeCommand } from '../../contract/remove-project-responsible-employee-command';
import { ViewAssignableEmployee } from '../../contract/view-assignable-employee';
import { ViewProjectEmployeeAssignment } from '../../contract/view-project-employee-assignment.interface';
import { ProjectStructureType } from '../enums/project-structure-type';

@Injectable({
  providedIn: 'root'
})
export class ProjectEmployeeService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/projects';
  private responsibleEmployeeUrl = this.url + '/responsible-employee';

  constructor(private http: HttpClient) {
  }

  public getEmployees(searchTerm?: string): Observable<ViewAssignableEmployee[]> {
    let params = {
      searchTerm: searchTerm
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<ViewAssignableEmployee[]>(this.url + '/assignable-employees', {params: httpParams});
  }

  public getEmployeeAssignments(projectId: string, projectStructureType: ProjectStructureType):
    Observable<ViewProjectEmployeeAssignment[]> {
    if (projectStructureType !== ProjectStructureType.PROJECT_GROUP) {
      return this.http.get<ViewProjectEmployeeAssignment[]>(this.url + '/' + projectId + '/responsible-employees');
    } else {
      return of([]);
    }
  }

  public addProjectEmployeeAssignments(command: AddProjectResponsibleEmployeeCommand): Observable<string> {
    return this.http.post(this.responsibleEmployeeUrl + '/add-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public changeProjectRespnsibleEmployeeAcceptanceUser(
    command: ChangeProjectResponsibleEmployeeAcceptanceUserFlagCommand): Observable<string> {
    return this.http.post(this.responsibleEmployeeUrl + '/change-acceptance-user-flag', JSON.stringify(command), {responseType: 'text'});
  }

  public removeProjectEmployeeAssignments(command: RemoveProjectResponsibleEmployeeCommand): Observable<string> {
    return this.http.post(this.responsibleEmployeeUrl + '/remove-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }
}
