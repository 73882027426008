import { AfterViewInit, Directive, HostBinding, Input } from '@angular/core';
import { isNumber } from 'lodash';

@Directive({
  selector: '[bhMultiline]'
})
export class MultilineDirective implements AfterViewInit {
  @Input() set rows(value: number) {
    this._rows = value;
    this.refreshStyles();
  }
  @Input() set disableMultiline(value: boolean) {
    this._disabled = value;
    this.refreshStyles();
  }

  private _rows: number;
  private _disabled = false;

  @HostBinding('style.display') display;
  @HostBinding('style.-webkit-box-orient') boxOrient;
  @HostBinding('style.overflow') overflow;
  @HostBinding('style.-webkit-line-clamp') lineClamp;

  public ngAfterViewInit(): void {
    this.refreshStyles();
  }

  private refreshStyles(): void {
    if (!this._disabled && isNumber(this._rows)) {
      this.enableStyles();
    } else {
      this.disableStyles();
    }
  }

  private enableStyles(): void {
    this.display = '-webkit-box';
    this.boxOrient = 'vertical';
    this.overflow = 'hidden';
    this.lineClamp = this._rows.toString();
  }

  private disableStyles(): void {
    this.display = null;
    this.boxOrient = null;
    this.overflow = null;
    this.lineClamp = null;
  }

}
