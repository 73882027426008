<bh-step-dialog class="user-dialog" [icon]="faUserPlus" [title]="getDialogTitle()">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate class="entity-creation">
    <form [formGroup]="userForm" class="entity-creation-form">
      <mat-horizontal-stepper labelPosition="bottom" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch"
                              linear
                              [bhStepHeaderOverlay]="stepHeaders"
                              (headerClick)="headerClick($event)">

        <!-- GENERAL USER DATA -->
        <mat-step [formGroup]="generalDataControl" [stepControl]="generalDataControl" >
          <ng-template matStepLabel>{{'general.generalData'|translate}} *</ng-template>
          <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px" class="step-content">
            <div fxFlex="90" fxLayout="row" fxLayoutAlign="center start" class="step-content__main">
              <div fxFlex="70" fxLayout="column" fxLayoutGap="10px" class="step-content__main__general">
                <div fxLayout="row" fxLayoutGap="40px">
                  <mat-form-field>
                    <mat-label>{{'general.firstName'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           formControlName="firstName"
                           [maxlength]="fieldLimit.NAME">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.lastName'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           formControlName="lastName"
                           [maxlength]="fieldLimit.NAME">
                    <mat-error *ngIf="lastNameControl.hasError('required') || lastNameControl.hasError('pattern')">
                      {{'shared.validation.required'|translate:{ value: 'general.lastName'|translate } }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="40px">
                  <mat-form-field>
                    <mat-label>{{'general.email'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           formControlName="email"
                           [maxlength]="fieldLimit.EMAIL"
                           [readonly]="user">
                    <mat-error *ngIf="emailControl.hasError('required')">
                      {{'shared.validation.required'|translate:{ value: 'general.email'|translate } }}
                    </mat-error>
                    <mat-error *ngIf="emailControl.hasError('validEmail')">
                      {{'shared.validation.enterValidEmail'|translate}}
                    </mat-error>
                    <mat-error *ngIf="emailControl.hasError('taken')">
                      {{'modules.userrole.base.emailTaken'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'modules.userrole.userAddEdit.mobileNumber'|translate}}</mat-label>
                    <input type="tel" matInput
                           bhRemoveWhitespaces
                           formControlName="mobileNumber"
                           [maxlength]="fieldLimit.PHONE_NUMBER">
                  </mat-form-field>
                </div>
                <div fxLayout="row">
                  <mat-form-field fxFlex="50" class="single-input">
                    <mat-label>{{'modules.userrole.base.validUntil'|translate|uppercase}}</mat-label>
                    <input matInput
                           formControlName="validUntil" autocomplete="off"
                           [matDatepicker]="picker"
                           [min]="today">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="validUntilControl.hasError('matDatepickerMin')">
                      {{'shared.validation.date.shouldBeInFuture'|translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxFlex="10" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px"
                 class="step-content__footer">
              <button mat-raised-button
                      matStepperNext
                      [disabled]="generalDataControl.invalid">
                {{'general.buttons.continue'|translate}}
              </button>
              <button *ngIf="user" mat-raised-button
                      color="primary"
                      [disabled]="userForm.invalid"
                      (click)="save()">
                {{'general.buttons.save'|translate}}
              </button>
            </div>
          </div>
        </mat-step>

        <!-- ORGANISATIONS -->
        <mat-step *ngIf="!isCurrentUser" [formGroup]="userForm" [stepControl]="organisationIdsControl">
          <ng-template matStepLabel>{{'general.org.pl'|translate}} *</ng-template>
          <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px" class="step-content">
            <div fxFlex="90" fxLayout="row" fxLayoutAlign="center start" class="step-content__main">
              <div fxFlex="100" fxLayout="column" fxLayoutGap="10px" class="step-content__main__list">
                <div class="text-centered">
                  <span>{{'modules.userrole.userAddEdit.selectOrganisations'|translate|uppercase}} *</span>
                </div>
                <div class="step-content__main__list__warning text-centered">
                  <span class="step-content__main__list__warning__text"
                        [ngClass]="{
                          'show': organisationIdsControl.hasError('required') && organisationIdsControl.touched
                        }">
                    {{'shared.validation.requiredAtLeastOne'|translate:{ value: 'general.org.s'|translate } }}
                  </span>
                </div>
                <div fxFlex fxLayout="column" class="step-content__main__list__selection">
                  <div fxLayout="row" fxLayoutAlign="space-around center" class="selection-header">
                    <mat-form-field *ngIf="searchPredicate" class="selection-header__item">
                      <mat-label>{{'general.labels.search'|translate:{ value: 'general.org.pl'|translate } }}</mat-label>
                      <input matInput (keydown.enter)="$event.preventDefault()"
                             [formControl]="searchTermOrgControl">
                      <mat-icon matSuffix (click)="searchTermOrgControl.reset('')">close</mat-icon>
                    </mat-form-field>
                    <div class="selection-header__item">
                      <button *ngIf="organisationIdsControl.value?.length < 1; else deselectAll"
                              mat-button
                              mat-raised-button
                              color="primary"
                              class="selection-header__item__select-button"
                              (click)="tree.selectAll()">
                        {{'general.selectAll'|translate}}
                      </button>
                      <ng-template #deselectAll>
                        <button mat-button
                                mat-raised-button
                                color="primary"
                                class="selection-header__item__select-button"
                                (click)="tree.deselectAll()">
                          {{'general.deselectAll'|translate}}: {{organisationIdsControl.value?.length}}
                        </button>
                      </ng-template>
                    </div>
                  </div>
                  <div fxFlex="100%" fxLayout="column" class="tree-container scrollable">
                    <bh-virtual-expanding-tree #tree
                                               formControlName="organisationIds"
                                               [options]="organisations"
                                               keyFieldName="organisationId"
                                               [contentTemplate]="titleTemplate"
                                               [searchPredicate]="searchPredicate"
                                               [searchTerm]="searchTermOrg"
                                               [selectionMode]="selectionMode"
                                               [tooltipTextPredicate]="tooltipTextPredicate">
                      <ng-template #titleTemplate let-item>
                        <div class="title-template">{{item.organisationName}}</div>
                      </ng-template>
                    </bh-virtual-expanding-tree>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="10" fxLayout="row" fxLayoutAlign="space-between center" class="step-content__footer">
              <button mat-raised-button
                      matStepperPrevious>
                {{'general.buttons.back'|translate}}
              </button>
              <div fxLayoutGap="10px">
                <button mat-raised-button
                        matStepperNext
                        [disabled]="organisationIdsControl.invalid">
                        {{'general.buttons.continue'|translate}}
                </button>
                <button *ngIf="user"
                        mat-raised-button
                        color="primary"
                        [disabled]="userForm.invalid"
                        (click)="save()">
                  {{'general.buttons.save'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <!-- ROLES -->
        <mat-step>
          <ng-template matStepLabel>{{'modules.userrole.userAddEdit.userRoles'|translate}} *</ng-template>
          <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px" class="step-content">
            <div fxFlex="90" fxLayout="row" fxLayoutAlign="center start"
                 class="step-content__main entity-creation-form-right">
              <div fxFlex="100" fxLayout="column" fxLayoutGap="10px" class="step-content__main__list">
                <div class="text-centered">
                  <span>{{'modules.userrole.userAddEdit.selectUserRoles'|translate|uppercase}} *</span>
                </div>
                <div class="step-content__main__list__warning text-centered">
                  <span class="step-content__main__list__warning__text"
                        [ngClass]="{'show': roleIdsControl.hasError('required') && roleIdsControl.touched }">
                    {{'shared.validation.requiredAtLeastOne'|translate:{ value: 'general.role.s'|translate } }}
                  </span>
                </div>
                <div fxFlex fxLayout="column" class="step-content__main__list__selection">
                  <div fxLayout="row" fxLayoutAlign="space-around center" class="selection-header">
                    <mat-form-field *ngIf="searchPredicate" class="selection-header__item">
                      <mat-label>{{'general.labels.search'|translate:{ value: 'general.role.pl'|translate } }}</mat-label>
                      <input matInput [formControl]="searchTermRoleControl">
                      <mat-icon matSuffix (click)="searchTermRoleControl.reset('')">close</mat-icon>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" class="scrollable">
                    <mat-list formArrayName="roleIds" *ngIf="!areRolesNotFound(); else noRolesFound">
                      <mat-list-item *ngFor="let role of customizedRoles"
                                     [ngClass]="{
                                       'entity-creation-form-right__list-item--selected': role.isSelected,
                                       'hidden': isRoleHidden(role)
                                     }"
                                     (click)="selectRole(role)"
                                     class="entity-creation-form-right__list-item"
                                     [attr.data-osp-test]="'item-role-selected-'+role.isSelected">
                        <mat-icon matListItemIcon>check_circle</mat-icon>
                        <p matListItemTitle>{{role.name}} </p>
                        <button *ngIf="authService.isSuperOrPartnerAdmin()"
                                matListItemMeta
                                mat-icon-button
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="menu"
                                [matMenuTriggerData]="{ role: role }"
                                matTooltip="{{'modules.userrole.userAddEdit.showAuth' | translate}}">
                          <mat-icon>list_alt</mat-icon>
                        </button>
                      </mat-list-item>
                    </mat-list>

                    <mat-menu #menu="matMenu" class="full-width">
                      <ng-template matMenuContent let-role="role">
                        <mat-list>
                          <mat-list-item *ngFor="let auth of role.authorities">
                            {{auth}}
                          </mat-list-item>
                        </mat-list>
                      </ng-template>
                    </mat-menu>

                    <ng-template #noRolesFound>
                      <div class="empty-list-warning">
                        <p>{{'modules.userrole.userAddEdit.noRolesFound'|translate}}</p>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>

            <div fxFlex="10" fxLayout="row" fxLayoutAlign="space-between center" class="step-content__footer">
              <button mat-raised-button
                      matStepperPrevious>
                {{'general.buttons.back'|translate}}
              </button>
              <button mat-raised-button
                      color="primary"
                      [disabled]="userForm.invalid"
                      bhTimeoutSaveButton
                      (throttledClick)="save()">
                {{'general.buttons.save'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>
