import { Injectable, Pipe, PipeTransform } from '@angular/core';
import {LanguageService} from '../services/language.service';
import {Languages} from '../enums/languages.enum';
import { UserConfigurationService } from '../services/user-configuration.service';

interface StatusTranslation {
  statusName: {
    [key in Languages]?: string
  }
}

@Pipe({
  name: 'equipmentStatusName'
})
@Injectable()
export class EquipmentStatusNamePipe implements PipeTransform {

  constructor(
    private languageService: LanguageService,
    private userConfigurationService: UserConfigurationService
  ) { }

  public transform(value: StatusTranslation): string {
    return value?.statusName
      ? value.statusName[this.languageService.getUserLanguage()]
        ?? value.statusName[this.userConfigurationService.getFallbackLanguage()]
        ?? value.statusName[Languages.DEFAULT]
        ?? ''
      : '';
  }
}
