import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import { BaseAttachmentTopfactComponent } from '../base/base-attachment-topfact.component';
import { TopfactDocument } from '../../../contract/topfact-document.interface';

@Component({
  selector: 'bh-mobile-attachment-topfact',
  templateUrl: './mobile-attachment-topfact.component.html',
  styleUrls: ['./mobile-attachment-topfact.component.scss']
})
export class MobileAttachmentTopfactComponent extends BaseAttachmentTopfactComponent {

  public readonly faSortAlphaDown: IconDefinition = faSortAlphaDown;

  @Input() sortParameter: string;
  @Output() changeSortParameter = new EventEmitter<string>();

  public changeSortParameterRequested(sortParam: string) {
    this.sortParameter = sortParam;
    this.changeSortParameter.emit(sortParam);
  }

  public getFileExtension(document: TopfactDocument) {
    return `.${document.contentType}`;
  }
}
