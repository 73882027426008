import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy } from '@angular/core';
import { BaseEquipmentViewAttachment } from '../base/base-equipment-view-attachment';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTab } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { ImageLink } from '../../../../../contract/image-link.interface';
import { EquipmentMobileImageDialogComponent } from '../../../../equipment-mobile-image-dialog-component/equipment-mobile-image-dialog.component';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'bh-mobile-equipment-view-attachment',
  templateUrl: './mobile-equipment-view-attachment.component.html',
  styleUrls: ['./mobile-equipment-view-attachment.component.scss']
})
@UntilDestroy()
export class MobileEquipmentViewAttachmentComponent extends BaseEquipmentViewAttachment implements OnDestroy {

  public selectedTab: MatTab;
  private dialogRef: MatDialogRef<EquipmentMobileImageDialogComponent>;

  constructor(protected equipmentsStore: EquipmentsDataSource,
              protected equipmentsService: EquipmentsService,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected userConfigurationService: UserConfigurationService,
              protected routerHistory: RouterHistory,
              public equipmentCheckerService: EquipmentCheckerService,
              protected languageService: LanguageService,
              protected matSnackBar: MatSnackBar) {
    super(equipmentsStore,
      equipmentsService,
      dialog,
      route,
      authService,
      router,
      userConfigurationService,
      routerHistory,
      equipmentCheckerService,
      languageService,
      matSnackBar);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public canDownloadFile(document: ImageLink | DocumentLink): boolean {
    return false;
  }

  public openFile(event: MouseEvent, image: ImageLink | DocumentLink): void {
    event.stopPropagation();

    if (!this.canDownloadFile(image)) {
      return;
    }

    if (FileUtils.isPicture(image)) {
      return this.openImageDialog(<ImageLink>image);
    }
    this.directDownloadDocument(<DocumentLink>image);
  }

  private openImageDialog(image: ImageLink): void {
    event.stopPropagation();
    this.dialogRef = this.dialog.open(EquipmentMobileImageDialogComponent, {
      height: '80vh',
      width: '80vw'
    });
    this.dialogRef.componentInstance.image = image;
  }

  private directDownloadDocument(image: DocumentLink): void {

  }
}
