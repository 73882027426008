import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { LanguageService } from '../../../../../shared/services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { TransportColumnDefinitionService } from '../../../shared/services/transport-column-definition.service';
import { TransportListDatasource } from '../../../shared/services/transport-list.datasource';
import { GENERAL_COLUMN_DEF, TRANSPORT_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { TimezoneDatesService } from '../../../../../shared/timezone/timezone-dates.service';

@UntilDestroy()
@Component({
  selector: 'bh-transport-list-export-dialog',
  templateUrl: './transport-list-export-dialog.component.html',
  styleUrls: ['./transport-list-export-dialog.component.scss']
})
export class TransportListExportDialogComponent implements OnInit {

  public selectAllColumns = false;
  public readonly selectedColumns: string[] = [];
  public applyCurrentFilter = true;
  public readonly sortableFields: { name: string, value: string }[] = [];
  public sortBy = 'title';
  public sortDescending = false;
  public pendingRequest = false;

  constructor(
    private dialogRef: MatDialogRef<TransportListExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { selectedColumns: ColumnDefinition[] },
    public transportDataSource: TransportListDatasource,
    private transportColumnDefinitionService: TransportColumnDefinitionService,
    private languageService: LanguageService,
    private timezoneDataService: TimezoneDatesService
  ) { }

  public ngOnInit(): void {
    this.transportColumnDefinitionService.availableColumns
    .filter(column => this.isSortableColumn(column))
    .forEach(column => {
      this.sortableFields.push({
        name: column.readableName,
        value: column.cdkColumnDef
      });
    });
    this.sortableFields.sort((a, b) => a.name.localeCompare(b.name));

    this.data.selectedColumns.filter(column => this.isDisplayableColumn(column)).forEach(column => {
      this.selectedColumns.push(column.cdkColumnDef);
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public exportTransportList(): void {
    this.pendingRequest = true;
    const columns: string[] = this.selectAllColumns ? this.getAllDisplayableColumns() : this.selectedColumns;
    this.transportDataSource.exportTransportList(columns, this.applyCurrentFilter, this.sortBy, this.sortDescending,
      this.timezoneDataService.getSelectedTimezone())
    .pipe(untilDestroyed(this))
    .subscribe((data: Blob) => this.saveList(data), () => this.pendingRequest = false);
  }

  private isSortableColumn({ cdkColumnDef }: ColumnDefinition): boolean {
    return cdkColumnDef === TRANSPORT_COLUMN_DEF.TITLE
      || cdkColumnDef === TRANSPORT_COLUMN_DEF.STATE
      || cdkColumnDef === TRANSPORT_COLUMN_DEF.PRIORITY
      || cdkColumnDef === TRANSPORT_COLUMN_DEF.PROJECT_INFO;
  }

  private getAllDisplayableColumns(): string[] {
    return this.transportColumnDefinitionService.availableColumns
    .filter(column => this.isDisplayableColumn(column))
    .map(column => column.cdkColumnDef);
  }

  private isDisplayableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.cdkColumnDef !== GENERAL_COLUMN_DEF.OPEN_DETAILS;
  }

  private saveList(data: Blob): void {
    this.pendingRequest = false;
    saveAs(data, this.createFileName());
    this.dialogRef.close();
  }

  private createFileName(): string {
    return this.languageService.getInstant('modules.transportation.transportListExport.transportTasksListFileName')
      + `_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`;
  }
}
