import { Directive, Host, OnInit, Self } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { CustomDeviceDetectorService } from '../services/custom-device-detector.service';
@Directive({
  selector: 'mat-datepicker[bhAutoModeSwitch]',
})
export class DatepickerAutoModeSwitchDirective implements OnInit {
  constructor(
    @Self() @Host() private datepicker: MatDatepicker<any>,
    private deviceService: CustomDeviceDetectorService
  ) { }

  public ngOnInit(): void {
    this.datepicker.touchUi = this.deviceService.isMobile();
  }
}
