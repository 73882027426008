import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapConsts } from 'app/shared/modules/map/map.consts';

@UntilDestroy()
@Component({
  selector: 'bh-contact-map',
  templateUrl: './contact-map.component.html',
  styleUrls: ['./contact-map.component.scss']
})
export class ContactMapComponent implements SearchFilterActivatedView, OnInit, OnDestroy {
  public routeButton: ButtonInfo = {
    route: 'assets/contact/list/',
    tooltip: this.languageService.getInstant('general.list'),
    iconName: 'list'
  };
  public onFiltersUpdated = this.contactDataSource.onFiltersUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  public filters: FilterType[] = this.contactDataSource.filters;
  public searchForm: UntypedFormGroup;
  public readonly mapSettings: MapConfiguration = this.getInitialMapSettings();
  public readonly searchSuggestionsField = '';

  constructor(
    private languageService: LanguageService,
    private contactDataSource: ContactDataSource,
    private equipmentStore: EquipmentsDataSource,
    private projectStore: ProjectDataSource
  ) { }

  public ngOnInit(): void {
    this.clearStoredLocations();
    this.contactDataSource.updateFilters();
    this.initListeners();
  }

  public ngOnDestroy(): void {
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.contactDataSource.updateFilterParams(commands);
    this.contactDataSource.updateMap(true);
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(term => this.updateOnSearch(term));

    this.searchForm.patchValue({terms: this.contactDataSource.searchTerms}, { emitEvent: false });
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public saveMapConfigs(mapConfig: MapConfiguration): void {
    this.contactDataSource.saveMapConfiguration(mapConfig);
  }

  private getInitialMapSettings(): MapConfiguration {
    return {
      ...MapConsts.getDefaultMapConfiguration(),
      showContacts: true,
      ...(this.contactDataSource.getMapConfiguration() || {}),
    }
  }

  private initListeners(): void {
    this.totalCountListener();
  }

  private totalCountListener(): void {
    this.contactDataSource.totalCountLocations
    .pipe(untilDestroyed(this))
    .subscribe(count => this.onTotalCountUpdated.emit(count));
  }

  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  private updateOnSearch(searchTerm: string): void {
    this.contactDataSource.searchTerms = searchTerm;
    this.contactDataSource.updateMap(true);
  }

  private clearStoredLocations(): void {
    this.equipmentStore.clearStoredLocations();
    this.projectStore.clearStoredLocations();
    this.contactDataSource.clearStoredLocations();
  }
}
