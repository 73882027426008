import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { CreateStockCommand } from '../../contract/create-stock.command';
import { Observable } from 'rxjs';
import { UpdateStockCommand } from '../../contract/update-stock.command';
import { ViewStock } from '../../contract/view-stock';
import { SearchStock } from '../../stocks/shared/search-stock';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { DeleteStockDocumentCommand } from '../../contract/delete-stock-document-command';
import { DocumentLink } from '../../../../shared/contract/document-link.interface';
import { UpdateStockDocumentDescriptionCommand } from '../../contract/update-stock-document-description-command';
import { UploadStockDocumentCommand } from '../../contract/upload-stock-document-command';
import { DeleteStockCommand } from '../../contract/delete-stock-command';
import { DispositionStock } from '../disposition-stock';
import { PairStockScanCodeCommand } from '../../contract/pair-stock-scan-code-command';
import { UnpairStockScanCodeCommand } from '../../contract/unpair-stock-scan-code-command';
import { ViewEquipmentAmount } from '../../contract/view-equipment-amount.interface';
import { getHttpParameters } from '../../../../shared/utils';
import { ViewBulkItemAmount } from '../../contract/view-bulk-item-amount.interface';
import { IChangeBulkItemAmountData } from '../interfaces/change-bulk-item-amount-data.interface';
import { ISearchTransferStocksParams } from './stock.interface';
import { UpdateStockDocumentNameCommand } from '../../contract/update-stock-document-name-command';
import { IAmountsRequestParams } from '../../stocks/shared/contrancts/amounts-request-params.interface';
import { ViewGeofence } from '../../contract/view-geofence.interface';
import { CreateGeofenceCommand } from '../../contract/create-geofence-command';
import {
  GenericUpdateGeofenceCommand
} from '../../../../shared/modules/map/components/geofence-sidebar-component/generic-update-geofence-command';
import { DeleteGeofenceCommand } from '../../contract/delete-geofence-command';
import { ChangeGeofenceStatusCommand } from '../../contract/change-geofence-status-command';

@Injectable()
export class StockService {
  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/stocks';
  private defaultUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1';
  private geofenceUrl = this.defaultUrl + '/geofences';
  private amountsUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/amounts';

  constructor(private http: HttpClient) {
  }

  public createStock(command: CreateStockCommand): Observable<string> {
    return this.http.post(this.url + '/create', command, {responseType: 'text'});
  }

  public getById(id: string): Observable<ViewStock> {
    return this.http.get<ViewStock>(`${this.url}/${id}`);
  }

  public getTransferSourceStockById(id: string): Observable<ViewStock> {
    return this.http.get<ViewStock>(`${this.url}/transfer/source/${id}`);
  }

  public pairScanCode(command: PairStockScanCodeCommand): Observable<string> {
    return this.http.post(`${this.url}/pair-scan-code`, JSON.stringify(command), {responseType: 'text'});
  }

  public unpairScanCode(command: UnpairStockScanCodeCommand): Observable<string> {
    return this.http.post(`${this.url}/unpair-scan-code`, JSON.stringify(command), {responseType: 'text'});
  }

  public getStocks(allOrganisations?: boolean): Observable<ViewStock[]> {
    let params = new HttpParams();
    if (allOrganisations) {
      params = params.set('allOrganisations', 'true');
    }
    return this.http.get<ViewStock[]>(this.url, {params: params});
  }

  public getDispositionStocks(): Observable<DispositionStock[]> {
    return this.http.get<DispositionStock[]>(this.url + '/disposition');
  }

  public increaseBulkItemAmount(command: IChangeBulkItemAmountData): Observable<string> {
    return this.http.post(this.url + '/increase-amount', command, {responseType: 'text'});
  }

  public decreaseBulkItemAmount(data: IChangeBulkItemAmountData): Observable<string> {
    return this.http.post(this.url + '/decrease-amount', data, {responseType: 'text'});
  }

  public search(index: string, size: string, terms?: string, allOrganisations?: boolean): Observable<PagedResponse<SearchStock>> {
    let httpParams = new HttpParams()
      .set('page', index)
      .set('size', size);

    if (terms) {
      httpParams = httpParams.set('terms', terms);
    }

    if (allOrganisations) {
      httpParams = httpParams.set('allOrganisations', 'true');
    }

    return this.http.get<PagedResponse<SearchStock>>(this.url + '/search', {params: httpParams});
  }

  public searchTransferStocks(index: string,
                              size: string,
                              terms?: string,
                              excludeStockId?: string,
                              targetOrganisationIds?: string[]): Observable<PagedResponse<SearchStock>> {
    let params: ISearchTransferStocksParams = {
      page: index,
      size: size,
    };

    if (terms) {
      params.terms = terms;
    }

    if (excludeStockId) {
      params.excludeStockId = excludeStockId;
    }

    if (targetOrganisationIds) {
      params.targetOrganisationIds = targetOrganisationIds;
    }

    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchStock>>(this.url + '/search/transfer', {params: httpParams});
  }


  public updateStock(command: UpdateStockCommand): Observable<string> {
    return this.http.post(this.url + '/update', command, {responseType: 'text'});
  }

  public getDocuments(stockId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(`${this.url}/${stockId}/documents`);
  }

  public addDocument(command: UploadStockDocumentCommand): Observable<string> {
    return this.http.post(`${this.url}/add-document`, command, {responseType: 'text'});
  }

  public deleteDocument(command: DeleteStockDocumentCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-document`, command, {responseType: 'text'});
  }

  public updateDocumentName(command: UpdateStockDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentDescription(command: UpdateStockDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-description`, command, {responseType: 'text'});
  }

  public getUrl(): string {
    return this.url;
  }

  public deleteStock(command: DeleteStockCommand): Observable<string> {
    return this.http.post(this.url + '/delete', command, {responseType: 'text'});
  }

  public getBulkItemAmounts(stockId: string, params: IAmountsRequestParams): Observable<PagedResponse<ViewBulkItemAmount>> {
    const httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<ViewBulkItemAmount>>(this.amountsUrl + '/stock/' + stockId, {params: httpParams});
  }

  public getEquipmentAmounts(stockId: string, params: IAmountsRequestParams): Observable<PagedResponse<ViewEquipmentAmount>> {
    const httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<ViewEquipmentAmount>>(this.amountsUrl + '/equipments/stock/' + stockId, {params: httpParams});
  }

  public isDeletable(stockId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/' + stockId + '/deletable');
  }

  public hasStockTransferRequestWorkflow(): Observable<boolean> {
    return this.http.get<boolean>(this.defaultUrl + '/has-stock-transfer-request-workflow');
  }

  public hasBrzConnector(): Observable<boolean> {
    return this.http.get<boolean>(this.defaultUrl + '/has-brz-connector');
  }

  public getGeofences(stockId: string): Observable<ViewGeofence[]> {
    return this.http.get<ViewGeofence[]>(`${this.url}/${stockId}/geofences`);
  }

  public createGeofence(command: CreateGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateGeofence(command: GenericUpdateGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteGeofence(command: DeleteGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public changeGeofenceStatus(command: ChangeGeofenceStatusCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/change-status`, JSON.stringify(command), {responseType: 'text'});
  }
}
