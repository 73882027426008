<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{'modules.telematic.telematicUnitFotaWebInfo.title'|translate}}</h2>

  <div mat-dialog-content>
    <div [formGroup]="fotaWebDataForm" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
        <div fxFlex="auto" fxLayout="column">
          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="25%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.imei'|translate}}</mat-label>
                <input matInput formControlName="imei">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="25%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.iccid'|translate}}</mat-label>
                <input matInput formControlName="iccid">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="25%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.unitType'|translate}}</mat-label>
                <input matInput formControlName="unitType">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="25%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.serialNumber'|translate}}</mat-label>
                <input matInput formControlName="serialNumber">
              </mat-form-field>
            </div>
          </div>

          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.lastConnected'|translate}}</mat-label>
                <input matInput formControlName="lastConnected">
              </mat-form-field>
            </div>
            <div class="field-wrapper" fxFlex="50%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.currentFirmware'|translate}}</mat-label>
                <input matInput formControlName="currentFirmware">
              </mat-form-field>
            </div>
          </div>

          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
            <div class="field-wrapper" fxFlex="100%">
              <mat-form-field>
                <mat-label>{{'modules.telematic.telematicUnitFotaWebInfo.currentConfig'|translate}}</mat-label>
                <input matInput formControlName="currentConfig">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div fxFlex="100" fxLayoutAlign="end center">
      <button mat-raised-button [mat-dialog-close]="true">{{'general.buttons.close'|translate}}</button>
    </div>
  </div>
</div>
