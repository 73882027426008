import { DeleteTransportDocumentCommand } from '../../../../../contracts/transport/delete-transport-document.command';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { LanguageService } from '../../../../../../../shared/services/language.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map, switchMap } from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { TransportListDatasource } from 'app/modules/transportation/shared/services/transport-list.datasource';
import { FileUtils } from 'app/shared/fileUtils';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { EMPTY, of } from 'rxjs';
import { UpdateTransportDocumentDescriptionCommand } from 'app/modules/transportation/contracts/transport/update-transport-document-description.command';
import {
  UpdateTransportDocumentNameCommand
} from '../../../../../contracts/transport/update-transport-document-name.command';

enum DocumentSortParameter {
  FILE_NAME = 'FILE_NAME',
  FILE_DESCRIPTION = 'FILE_DESCRIPTION'
}

@UntilDestroy()
@Component({
  selector: 'bh-transport-view-attachment',
  templateUrl: './transport-view-attachment.component.html',
  styleUrls: ['./transport-view-attachment.component.scss']
})
export class TransportViewAttachmentComponent implements OnInit, OnChanges {
  @Input() transportId: string = null;
  @Input() isActive = false;
  public readonly faSortAlphaDown = faSortAlphaDown;
  public documents: DocumentLink[];
  public images: DocumentLink[];
  public documentsSortParam: DocumentSortParameter;
  public readonly sortOptions = DocumentSortParameter;
  public readonly isEditable = this.authService.hasAnyAuthority([
    Authorities.TRANSPORT_TASK_MANAGE,
    Authorities.TRANSPORT_TASK_DISPOSITION
  ]);
  public waiting = false;

  constructor(
    private transportDataSource: TransportListDatasource,
    private userConfigurationService: UserConfigurationService,
    private authService: KeycloakService,
    private dialog: MatDialog,
    private languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.documentsSortParam = this.getDocumentsSortParameter();
    this.transportDocumentsListener();
  }

  public ngOnChanges(): void {
    if (this.transportId && this.isActive) {
      this.getAttachments();
    }
  }

  public changeDocumentsSortParameter(sortParam: DocumentSortParameter): void {
    this.documentsSortParam = sortParam;
    this.userConfigurationService.saveDocumentsSortConfiguration(this.documentsSortParam);
    this.documents = this.getSortedDocumentLinks(this.documents, this.documentsSortParam);
    this.images = this.getSortedDocumentLinks(this.images, this.documentsSortParam);
  }

  public isDeleteEnabled(): boolean {
    return this.isEditable && !this.waiting;
  }

  public deleteDocument(objectKey: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.deleteDoc');
    this.handleDialogConfirmation(dialogRef, objectKey);
  }

  public deleteImage(objectKey: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.deleteImage');
    this.handleDialogConfirmation(dialogRef, objectKey);
  }

  private handleDialogConfirmation(dialogRef: MatDialogRef<ConfirmationDialogComponent>, objectKey: string): void {
    dialogRef.afterClosed()
      .pipe(
        switchMap(result => result === dialogResults.YES ? of(result) : EMPTY),
        switchMap(() => {
          this.waiting = true;
          return this.transportDataSource.deleteDocument(new DeleteTransportDocumentCommand(this.transportId, objectKey));
        }),
        finalize(() => this.waiting = false),
        untilDestroyed(this))
      .subscribe();
  }

  public saveDescription(updatedDocument: DocumentLink): void {
    const cmd = new UpdateTransportDocumentDescriptionCommand(
      this.transportId, updatedDocument.documentKey, updatedDocument.fileDescription);
    this.transportDataSource.updateDocumentDescription(cmd);
  }

  public saveFileName(updatedDocument: DocumentLink): void {
    const cmd = new UpdateTransportDocumentNameCommand(
      this.transportId, updatedDocument.documentKey, updatedDocument.fileName);
    this.transportDataSource.updateDocumentName(cmd);
  }

  private getDocumentsSortParameter(): DocumentSortParameter {
    if (this.userConfigurationService.getDocumentsSortConfiguration()) {
      return <DocumentSortParameter>this.userConfigurationService.getDocumentsSortConfiguration().sortParameter;
    }
  }

  private transportDocumentsListener(): void {
    this.transportDataSource.documents
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        res = this.getSortedDocumentLinks(res, this.documentsSortParam)
        const { documents, images } = FileUtils.splitDocumentLinks(res);
        this.images = images;
        this.documents = documents;
      });
  }

  private getAttachments(): void {
    this.transportDataSource.getDocuments(this.transportId);
  }

  private getSortedDocumentLinks(documents: DocumentLink[], sortParam: DocumentSortParameter): DocumentLink[] {
    if (!documents) {
      return;
    }
    switch (sortParam) {
      case DocumentSortParameter.FILE_NAME:
        return documents.sort((a, b) => a.fileName.localeCompare(b.fileName));
      case DocumentSortParameter.FILE_DESCRIPTION:
        return documents.sort((a, b) => {
          if (a.fileDescription === '' || b.fileDescription === '') {
            return a.fileDescription === '' ? 1 : -1;
          }
          return a.fileDescription.localeCompare(b.fileDescription);
        })
      default:
        return documents;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
