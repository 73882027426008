import { LocalUserStorageService } from './local-user-storage.service';
import { OnlineStatusService } from './online-status.service';
import { Action } from './action';
import { concatMap, delay, map, skip } from 'rxjs/operators';
import { merge, Observable, OperatorFunction, timer } from 'rxjs';

export abstract class QueuedActionHandler {

  queuedActionsKey = 'queuedActions';

  protected constructor(private serviceName: string,
                        protected onlineStatusService: OnlineStatusService,
                        private throttleTimeMillis = 100) {
    // First online value is emitted too fast, but the queue has to be handled once when accessing app in online mode.
    // 3 seconds ensures service dependencies are available
    merge(
      this.onlineStatusService.isOnline.pipe(skip(1)),
      timer(3000)
    ).subscribe(() => this.handleQueue());
  }

  handleQueue(): void {
    const queue: Action[] = LocalUserStorageService
      .getValueDefault<Action[]>(this.queuedActionsKey, [])
      .filter((action: Action) => action.service === this.serviceName);

    if (this.onlineStatusService.onlineStatus && queue.length > 0) {

      let firstActionObs: Observable<void> = this.invokeAction(queue.shift(), this.throttleTimeMillis);
      const actionObs: OperatorFunction<any, void>[] = queue
        .map(action => this.invokeAction(action, this.throttleTimeMillis))
        .map(obs => concatMap(() => obs));

      if (actionObs.length > 0) {
        firstActionObs = (firstActionObs as any).pipe(...actionObs)
      }

      firstActionObs.subscribe();
    }
  }

  invokeAction(action: Action, throttleTimeMillis: number): Observable<void> {
    // Example: equipmentsService.assignToProject(someCommand)
    return this[action.functionName](action.command).pipe(
      map(() => this.completeAction(action.id)),
      delay(throttleTimeMillis) // Increase time between requests to throttle frequency
      );
  }

  completeAction(actionId: string): void {
    const queue: Action[] = LocalUserStorageService.getValueDefault<Action[]>(this.queuedActionsKey, []);
    queue.splice(queue.findIndex(a => a.id === actionId), 1);
    LocalUserStorageService.setValue(this.queuedActionsKey, JSON.stringify(queue));
  }
}
