import { LanguageService } from 'app/shared/services/language.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { EquipmentInvoice } from '../../../contract/equipment-invoice.interface';
import { EquipmentsDataSource } from '../../equipments.datasource';
import { Utils } from '../../../../../shared/utils';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentAttachmentDetailsComponent } from '../../equipment-attachment-details/equipment-attachment-details.component';
import { Authorities } from '../../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../../shared/enums/modules.enum';
import { RouterHistory } from '../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentInvoiceDetailsComponent extends EquipmentAttachmentDetailsComponent implements OnInit, OnDestroy {

  invoice: EquipmentInvoice;
  invoiceId;
  invoiceNumber: string;
  equipmentId: string;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected equipmentStore: EquipmentsDataSource,
              protected utils: Utils,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, dialog, routerHistory, languageService, fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    // this needs to be here for @TakeUntilDestroy, otherwise it will not work with ahead of time compiling
  }

  protected getInvoice(): void {
    if (this.allowedToSee) {
      this.equipmentStore.getInvoiceDetails(this.equipmentId, this.invoiceId, this.invoiceNumber)
          .pipe(untilDestroyed(this))
          .subscribe(
            (res: EquipmentInvoice) => {
                if (res) {
                  this.invoice = res;
                  this.invoiceDocumentsDataSource.data = res.documents ? res.documents : null;
                }
              },
              error => {
                console.log('error get invoice details: ', error);
              });
    }
  }

  public getDuration(): string {
    if (this.invoice && this.invoice.performanceDuration) {
      return this.utils.parseFromDurationToString(this.invoice.performanceDuration);
    } else {
      return null;
    }
  }

  public getDebitVoucher(): string {
    if (this.invoice && this.invoice.debitVoucher) {
      return this.invoice.debitVoucher.substring(0, this.invoice.debitVoucher.indexOf('_'));
    } else {
      return null;
    }
  }

  public getInvoiceNumber(): string {
    if (this.invoice) {
      return (this.invoice.finCompanyId ? this.invoice.finCompanyId + ' / ' : '') +
          (this.invoice.accountingId ? this.invoice.accountingId + ' / ' : '') +
          this.invoice.invoiceNumber;
    }
    return '';
  }

  get allowedToSee(): boolean {
    return this.hasAuthority(Authorities.EQUIPMENT_VIEW_COSTS) && this.hasModule(Modules.COSTS);
  }

}
