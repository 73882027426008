import { Pipe, PipeTransform } from '@angular/core';
import { TransferType } from '../enums/transfer-type.enum';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'transferTypeTranslator'
})
export class TransferTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(value: string, args?: any): string {
    switch (value) {
      case TransferType.CANCELLATION:
        return this.translate('general.transferType.cancellation');
      case TransferType.CONSUME:
        return this.translate('general.transferType.consume');
      case TransferType.DECREASE:
        return this.translate('general.transferType.decrease');
      case TransferType.INCREASE:
        return this.translate('general.transferType.increase');
      case TransferType.TRANSFER:
        return this.translate('general.transferType.transfer');
      case TransferType.TYPE_OF_USE:
        return this.translate('general.transferType.typeOfUse');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
