export enum HttpStatusCodes {
  OK = 200,
  UNKNOWN_ERROR = 0,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  LOCKED = 423,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY= 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504
}
