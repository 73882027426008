
import { Pipe, PipeTransform } from '@angular/core';
import { TransportItem } from '../../../equipment/shared/transport-items.interface';
import { TransportItemType } from '../enums/transport-item-type.enum';

@Pipe({
  name: 'formatTitleFromTransportItem'
})
export class FormatTitleFromTransportItemPipe implements PipeTransform {

  constructor() {
  }

  public transform(transportItem: TransportItem): string {
    if (transportItem !== null) {
      const titleSecondPart = ((transportItem.transportItemType === TransportItemType.CONTACT
        ? transportItem.contactIdentifier
        : transportItem.costCenter) || '').trim();
      return transportItem.name.trim() + (titleSecondPart ? ` (${titleSecondPart})` : '');
    }
    return '';
  }

}
