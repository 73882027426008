import { ScanEquipment } from './scan-equipment';
import { SearchEquipment } from '../contract/search-equipment.interface';

export class DispositionEquipment {

  static fromSearchEquipment(searchEquipment: SearchEquipment): DispositionEquipment {
    return new DispositionEquipment(searchEquipment.equipmentId,
      searchEquipment.equipmentName,
      searchEquipment.equipmentModel,
      searchEquipment.equipmentSerialNumber,
      searchEquipment.equipmentCustomerSerialNumber,
      searchEquipment.equipmentType,
      null,
      searchEquipment.hasLiveOperatingHours);
  }

  static fromScanEquipment(scanEquipment: ScanEquipment): DispositionEquipment {
    return new DispositionEquipment(scanEquipment.equipmentId,
      scanEquipment.equipmentName,
      scanEquipment.equipmentModel,
      scanEquipment.equipmentSerialNumber,
      scanEquipment.equipmentCustomerSerialNumber,
      scanEquipment.equipmentType,
      scanEquipment.equipmentScanCode,
      scanEquipment.hasLiveOperatingHours);
  }

  constructor(public equipmentId: string,
              public equipmentName: string,
              public equipmentModel: string,
              public equipmentSerialNumber: string,
              public equipmentCustomerSerialNumber: string,
              public equipmentType: {
                category2: string
              },
              public equipmentScanCode: string | null,
              public hasLiveOperatingHours: boolean) {
  }
}
