import { Injectable } from '@angular/core';
import { ChatWriteCommand } from '../contract/chat-write-command';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChatOverview } from '../contract/chat-overview.interface';
import { ChatMessage } from '../contract/chat-message';
import { environment } from '../../../../environments/environment';
import { ChatUser } from '../contract/chat-user.interface';


@Injectable()
export class CommunicationService {

  private url = environment.APP_COMMUNICATION_SERVICE_BASE_URL + '/api/v1/communication';

  constructor(private http: HttpClient) {
  }

  public chatWrite(command: ChatWriteCommand): Observable<string> {
    return this.http.post(this.url + '/chat/write', JSON.stringify(command), {responseType: 'text'});
  }

  public chatOverview(): Observable<ChatOverview[]> {
    return this.http.get<ChatOverview[]>(this.url + '/chat/overview');
  }

  public knownChatUsers(): Observable<ChatUser[]> {
    return this.http.get<ChatUser[]>(this.url + '/chat/known-users');
  }

  public chatMessages(recipientId: string): Observable<ChatMessage[]> {
    return this.http.get<ChatMessage[]>(this.url + '/chat/messages/' + recipientId);
  }

  public chatUnread(): Observable<number> {
    return this.http.get<number>(this.url + '/chat/unread');
  }

  public getChatPartner(chatPartnerId: string): Observable<ChatUser> {
    return this.http.get<ChatUser>(this.url + `/chat/chat-partner/${chatPartnerId}`);
  }
}
