import { environment } from 'environments/environment';
import { SchedulerLocaleLoaderService } from '../../../../shared/scheduler/scheduler-locale-loader.service';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DispositionDataSource } from '../../shared/disposition.datasource';
import { ViewProject } from '../../contract/view-project.interface';
import { TimelineEvent } from '../../contract/timeline-event';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { UpdateEquipmentAssignmentCommand } from '../../../equipment/contract/update-equipment-assignment-command';
import { HttpErrorResponse } from '@angular/common/http';
import { EquipmentsDataSource } from '../../../equipment/shared/equipments.datasource';
import { ProjectStatus } from '../../shared/enums/project-status.enum';
import { DatesService } from '../../../../shared/services/dates.service';
import { SchedulerHighlighter } from '../../../../shared/scheduler/scheduler-highlighter';
import { AddressService } from '../../../../shared/services/address.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  skip,
  skipUntil,
  take
} from 'rxjs/operators';
import { isDefined, joinNonEmpty } from '../../../../shared/utils';
import { Section } from '../../../../shared/scheduler/section.interface';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, combineLatest, merge, Observable, pipe, Subject, zip } from 'rxjs';
import { CustomerLabel } from '../../../../shared/contract/customer-label.interface';
import { AssigneeCount } from '../../contract/assignee-count';
import { isProjectEvent, ProjectEvent } from '../../contract/project-event';
import { EquipmentAssignmentEvent, isEquipmentAssignmentEvent } from '../../contract/equipment-assignment-event';
import { EquipmentNotAvailableEvent, isEquipmentNotAvailableEvent } from '../../contract/equipment-not-available-event';
import _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ListType } from 'app/shared/enums/list-type.enum';
import { ViewEmployeeProjectAssignment } from '../../contract/view-employee-project-assignment.interface';
import { EmployeeAssignmentEvent, isEmployeeAssignmentEvent } from '../../contract/employee-assignment-event';
import { EmployeeSection } from '../../contract/custom-section-types/employee-section.interface';
import { TeamMemberSection } from '../../contract/custom-section-types/team-member-section.interface';
import { EmployeeNotAvailableEvent, isEmployeeNotAvailableEvent } from '../../contract/employee-not-available-event';
import { EquipmentSection } from '../../contract/custom-section-types/equipment-section.interface';
import { SubEquipmentSection } from '../../contract/custom-section-types/sub-equipment-section.interface';
import { EmployeeDispositionDatasource } from '../../shared/employee-disposition.datasource';
import {
  ViewEquipmentProjectAssignment
} from '../../../equipment/contract/view-equipment-project-assignment.interface';
import { LocalUserStorageService } from 'app/shared/services/local-user-storage.service';
import { AssetListTypeResolver } from 'app/shared/components/asset-list-component/asset-list-type-resolver';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { KeycloakService } from 'app/core/keycloak';
import { EmptyPlaceholderSection } from '../../contract/custom-section-types/empty-placeholder-section.interface';
import { Modules } from 'app/shared/enums/modules.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { TeamHeaderSection } from '../../contract/custom-section-types/team-header-section.interface';
import { TeamHeaderEvent } from '../../contract/team-header-event';
import {
  UpdateEmployeeToProjectAssignmentCommand
} from 'app/modules/equipment/contract/update-employee-to-project-assignment-command';
import { ValidateEmployeeAssignmentParams } from '../../contract/validate-employee-assignment-params.interface';
import { EmployeeDispositionService } from '../../shared/employee-disposition.service';
import {
  EquipmentAssignmentEditDialogComponent
} from '../../../equipment/shared/equipment-assignment-edit-dialog/equipment-assignment-edit-dialog.component';
import {
  EmployeeAssignmentEditDialogComponent
} from '../../staff/shared/employee-assignment-edit-dialog/employee-assignment-edit-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AssignEquipmentToProjectCommand } from '../../../equipment/contract/assign-equipment-to-project-command';
import { SearchEquipment } from '../../../equipment/contract/search-equipment.interface';
import {
  EquipmentDispositionAssignmentService
} from 'app/shared/services/disposition-assignment/equipment-disposition-assignment.service';
import { AssignmentPeriodType } from 'app/shared/enums/assignment-period-type.enum';
import { DragAndDropService } from 'app/shared/services/drag-and-drop.service';
import { DragStatus } from 'app/shared/contract/drag-item-state.interface';
import { DragItemState } from 'app/shared/enums/drag-item-state.enum';
import { DragOverPayload } from 'app/shared/contract/drag-over-payload.interface';
import { DraggableItemType } from 'app/shared/enums/draggable-item-type.enum';
import {
  EmployeeDispositionAssignmentService
} from 'app/shared/services/disposition-assignment/employee-disposition-assignment.service';
import { AddEmployeeToProjectAssignmentCommand } from '../../contract/add-employee-project-assignment-command';
import { SearchEmployeeDisposition } from '../../contract/search-employee-disposition.interface';
import { DragDropContainerIdentifier } from 'app/shared/enums/drag-drop-container-identifier.enum';
import { ViewAmountTimelineEntry } from '../../contract/view-amount-timeline-entry.interface';
import {
  isTransferItemAssignmentEvent,
  TransferItemAssignmentEvent,
} from '../../contract/transfer-item-assignment-event';
import { DispositionColumnService } from '../../shared/services/disposition-column.service';
import { EquipmentCheckerService } from 'app/modules/equipment/shared/services/equipment-checker.service';
import { faWallet } from '@fortawesome/pro-light-svg-icons';
import { Router } from '@angular/router';
import {TimezoneDatesService} from '../../../../shared/timezone/timezone-dates.service';
import { DeleteEquipmentAssignmentCommand } from '../../../equipment/contract/delete-equipment-assignment-command';

interface SchedulerTooltip {
  delay: (showCallback: () => {}, tooltip: SchedulerTooltip, data: [MouseEvent, string], delayTimeMs?: number) => {};
  show: () => {};
  hide: () => {};
}

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-disposition-board-timeline',
  templateUrl: 'disposition-board-timeline.component.html',
  styleUrls: ['disposition-board-timeline.component.scss'],
})
export class DispositionBoardTimelineComponent implements OnInit {

  public sections: Section[] = [];
  public events: TimelineEvent[] = [];
  public equipmentAssignments: ViewEquipmentProjectAssignment[] = [];
  public employeeAssignments: ViewEmployeeProjectAssignment[] = [];
  public labels: Observable<CustomerLabel[]>;
  public projectAssignees: Observable<AssigneeCount[]>;
  public termsControl: UntypedFormControl;
  public selectedTypes: string[] = [];
  public filterForm: UntypedFormGroup;
  public options: string[] = [ListType.EQUIPMENTS, ListType.EMPLOYEES];
  private readonly isEquipmentAssignmentInProgress = new BehaviorSubject<boolean>(false);
  private readonly isEmployeeAssignmentInProgress = new BehaviorSubject<boolean>(false);
  public readonly isLoadingData = combineLatest([
    this.isEquipmentAssignmentInProgress.asObservable(),
    this.isEmployeeAssignmentInProgress.asObservable(),
    this.dispositionStore.isLoadingDispositionTimeline
  ]).pipe(map(states => states.some(Boolean)));

  @ViewChild('scheduler', { static: true }) private schedulerContainer: ElementRef;
  @ViewChild('paginator', { static: true }) private paginator: MatPaginator;
  private readonly selectedTypeStorageKey: string = 'disposition-board-timeline-tab';
  private readonly attributeProjectNumber = 'data-project-number';
  private readonly attributeProjectName = 'data-project-name';
  private readonly attributeProjectLink = 'data-project-link';
  private readonly attributeEquipmentLink = 'data-equipment-link';
  private readonly attributeCostCenter = 'data-cost-center';
  private readonly attributeAddress = 'data-address';
  private readonly timelineYearName = 'timeline_year';
  private readonly timelineMonthName = 'timeline_month';
  private readonly timelineWeekName = 'timeline_week';
  private readonly matrixCellClassName = 'dhx_matrix_cell';
  private readonly tooltipDateFormat = 'DD.MM.YYYY HH:mm:ss';
  private readonly mainRowHeightPx = 90;
  private readonly subRowHeightPx = 40;
  private openSections = new Set<string>();
  private projects: ViewProject[] = [];

  // Drag&Drop properties
  public highlightPredicate = SchedulerHighlighter.getClassDateDay;
  public draggingStatus: DragStatus;
  public mousePositionDate: Date;
  public readonly containerIdentifiers = DragDropContainerIdentifier;
  public dragAndDropContainerIds = [];
  private lastActionData = null;
  private lastDragOverElement = null;
  private dragOverCellClassName = 'drag-over-cell';
  private tooltipDelay = 50;

  constructor(private languageService: LanguageService,
              private authService: KeycloakService,
              private localStorageService: LocalUserStorageService,
              private optionResolver: AssetListTypeResolver,
              private equipmentStore: EquipmentsDataSource,
              private columnService: DispositionColumnService,
              private formBuilder: UntypedFormBuilder,
              private addressService: AddressService,
              private snackBar: MatSnackBar,
              private employeeDispositionStore: EmployeeDispositionDatasource,
              private employeeDispositionService: EmployeeDispositionService,
              private schedulerLocaleLoaderService: SchedulerLocaleLoaderService,
              private equipmentDispositionAssignmentService: EquipmentDispositionAssignmentService,
              private employeeDispositionAssignmentService: EmployeeDispositionAssignmentService,
              private equipmentCheckerService: EquipmentCheckerService,
              private router: Router,
              protected dialog: MatDialog,
              public dispositionStore: DispositionDataSource,
              public dndService: DragAndDropService,
              private timezoneDatesService: TimezoneDatesService) {
  }

  public ngOnInit(): void {
    this.projectAssignees = this.dispositionStore.projectAssignees;
    this.labels = this.dispositionStore.customerLabels;

    this.termsControl = new UntypedFormControl('');

    this.buildFilterForm();
    this.applySearchFilters();

    this.dispositionStore.getFilterableProjectAssignees();
    this.dispositionStore.getFilterableCustomerLabels();
    this.setSelectedValueFromStorage();

    scheduler = Scheduler.getSchedulerInstance();
    this.initListeners();

    this.subscribeToSelectedType();
    this.subscribeToDragStatus();
    this.subscribeToMouseMoveExternal();
    this.subscribeToAssetTypeChanges();

    this.onSearchFormType();
    this.dispositionStore.getProjectsWithAssignments();
  }

  public toggleFullScreen(): void {
    setTimeout(() => {
      scheduler.collapse();
    }, 0);
    this.dispositionStore.toggleFullScreen();
  }

  public updateSections(): void {
    if (this.dispositionStore.showIrrelevantAssignments) {
      this.sections.forEach((section: Section) => section.open = this.openSections.has(section.key));
      scheduler.updateCollection('sections', this.sections);
    } else {
      const state = scheduler.getState();
      this.hideSectionsWithoutAssignment(state.mode, state.date);
    }
  }

  public onPaginateChange(event: PageEvent): void {
    this.dispositionStore.changePage(event.pageIndex, event.pageSize);
    this.dispositionStore.getProjectsWithAssignments();
  }

  private initListeners(): void {
    const isInitialized = new Subject();
    const baseOperators = pipe(skipUntil(isInitialized), filter(isDefined), untilDestroyed(this));

    this.dispositionStore.projectsWithAssignments
      .pipe(baseOperators)
      .subscribe((projectsWithAssignments: ViewProject[]) => {
        this.projects = projectsWithAssignments ? [...projectsWithAssignments] : [];
        this.clearSchedulerData();
        this.setEvents(projectsWithAssignments);
        this.schedulerLoadData();
      });

    this.dispositionStore.assignmentsByEquipmentIds
      .pipe(baseOperators)
      .subscribe((equipmentProjectAssignments: any) => {
        this.addEquipmentNotAvailableEvents(equipmentProjectAssignments);
        this.schedulerLoadData();
      });

    this.dispositionStore.assignmentsByEmployeeIds
      .pipe(baseOperators)
      .subscribe((employeeProjectAssignments: any) => {
        this.addEmployeeNotAvailableEvents(employeeProjectAssignments);
        this.schedulerLoadData();
      });

    this.schedulerLocaleLoaderService.isLoaded
      .pipe(
        filter(Boolean),
        take(1),
        untilDestroyed(this))
      .subscribe(() => {
        this.createTimelineViews();
        this.schedulerInit();
        this.schedulerSetCustomCssClasses();
        this.schedulerEvents();
        this.schedulerTooltips();
        isInitialized.next(true);
      });

    this.columnService.dispositionBoardViewPageSize
      .pipe(untilDestroyed(this))
      .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
  }

  private updateAfterEventAdding(section: string, listType: ListType): void {
    scheduler.openSection(section);
    if (!this.isSelected(listType)) {
      this.activateView(listType);
    }
    this.dispositionStore.getProjectsWithAssignments();
  }

  private buildFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      showFinished: false,
      selectedLabels: null,
      selectedProjectAssignees: null,
    });
    this.filterForm.controls.selectedLabels.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (labels: string[]) => {
        this.dispositionStore.changeLabels(labels);
        this.dispositionStore.getFilterableProjectAssignees();
      }
    });
    this.filterForm.controls.selectedProjectAssignees.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (projectAssignees: string[]) => {
        this.dispositionStore.changeProjectAssignees(projectAssignees);
        this.dispositionStore.getFilterableCustomerLabels();
      }
    });
    this.filterForm.controls.showFinished.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (showFinished: string[]) => {
        this.dispositionStore.changeHideFinished(!showFinished);
        this.dispositionStore.getFilterableCustomerLabels();
        this.dispositionStore.getFilterableProjectAssignees();
      }
    });
  }

  private onSearchFormType(): void {
    const operators = pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(_.isEqual));

    merge(
      this.termsControl.valueChanges.pipe(operators),
      this.filterForm.valueChanges.pipe(operators))
      .pipe(
        untilDestroyed(this),
        debounceTime(environment.DELAY_SHORT)
      )
      .subscribe(() => this.searchProjects());
  }

  private applySearchFilters(): void {
    combineLatest(
      this.dispositionStore.terms,
      this.dispositionStore.showFinished,
      this.dispositionStore.labels,
      this.dispositionStore.selectedProjectAssignees,
    ).pipe(
      take(1),
    )
      .subscribe(([terms, showFinished, labels, selectedProjectAssignees]) => {
        this.termsControl.setValue(terms);
        this.filterForm.patchValue({
          showFinished: !showFinished,
          selectedLabels: labels,
          selectedProjectAssignees: selectedProjectAssignees,
        });
      });
  }

  private searchProjects(): void {
    this.dispositionStore.changePage(0, this.paginator.pageSize);
    this.dispositionStore.changeSearchTerms(this.termsControl.value);
    this.dispositionStore.changeHideFinished(!this.filterForm.get('showFinished').value);
    this.dispositionStore.changeLabels(this.filterForm.get('selectedLabels').value);
    this.dispositionStore.changeProjectAssignees(this.filterForm.get('selectedProjectAssignees').value);
    this.dispositionStore.getProjectsWithAssignments();
  }

  private addEquipmentNotAvailableEvents(equipmentProjectAssignments: ViewEquipmentProjectAssignment): void {
    const visitedSectionIds = new Set<string>();
    this.events.forEach((event: TimelineEvent) => {
      if (isEquipmentAssignmentEvent(event)) {
        if (!visitedSectionIds.has(event.section_id)) {
          visitedSectionIds.add(event.section_id);
          if (equipmentProjectAssignments[event.assignment.equipmentId]) {
            equipmentProjectAssignments[event.assignment.equipmentId]
              .filter((assignment: ViewEquipmentProjectAssignment) =>
                assignment.projectId !== event.assignment.projectId
                && assignment.equipmentId === event.assignment.equipmentId)
              .forEach((conflictingAssignment: ViewEquipmentProjectAssignment) =>
                this.events.push(new EquipmentNotAvailableEvent(event.assignment.projectId, conflictingAssignment)),
              );
          }
        }
      }
    });
  }

  private addEmployeeNotAvailableEvents(employeeProjectAssignments: ViewEmployeeProjectAssignment): void {
    const visitedSectionIds = new Set<string>();
    this.events.forEach((event: TimelineEvent) => {
      if (isEmployeeAssignmentEvent(event)) {
        if (!visitedSectionIds.has(event.section_id)) {
          visitedSectionIds.add(event.section_id);
          if (employeeProjectAssignments[event.assignment.employeeId]) {
            employeeProjectAssignments[event.assignment.employeeId]
              .filter((assignment: ViewEmployeeProjectAssignment) =>
                assignment.projectId !== event.assignment.projectId
                && assignment.employeeId === event.assignment.employeeId)
              .forEach((conflictingAssignment: ViewEmployeeProjectAssignment) =>
                this.events.push(new EmployeeNotAvailableEvent(event.assignment.projectId, conflictingAssignment)),
              );
          }
        }
      }
    });
  }

  private createTimelineViews(): void {
    this.schedulerCreateTimelineYear();
    this.schedulerCreateTimelineMonth();
    this.schedulerCreateTimelineWeek();

    this.schedulerOnBeforeViewChange();
    this.schedulerOnMouseMoveListener();
    this.schedulerOnEmptyClick();
  }

  private schedulerCreateTimelineYear(): void {
    scheduler.createTimelineView({
      name: this.timelineYearName,
      x_unit: 'month',
      x_date: '%M <br/> %Y',
      x_step: 1,
      x_size: 12,
      x_start: 0,
      y_unit: scheduler.serverList('sections'),
      y_property: 'section_id',
      render: 'tree',
      folder_dy: this.mainRowHeightPx,
      dy: this.subRowHeightPx,
      dx: 300,
      event_dy: 19,
      section_autoheight: false,
    });

    scheduler.date[this.timelineYearName + '_start'] = scheduler.date.year_start;
    scheduler.locale.labels[this.timelineYearName + '_tab'] = this.translate('general.units.time.year.s');
  }

  private schedulerCreateTimelineMonth(): void {
    scheduler.createTimelineView({
      name: this.timelineMonthName,
      x_unit: 'day',
      x_date: '%D <br/> %j',
      x_step: 1,
      x_size: 31,
      y_unit: scheduler.serverList('sections'),
      y_property: 'section_id',
      render: 'tree',
      folder_dy: this.mainRowHeightPx,
      dy: this.subRowHeightPx,
      dx: 300,
      event_dy: 19,
      section_autoheight: false,
      second_scale: {
        x_unit: 'week',
        x_date: '%W',
      },
    });

    scheduler.date[this.timelineMonthName + '_start'] = scheduler.date.month_start;
    scheduler.locale.labels[this.timelineMonthName + '_tab'] = this.translate('general.units.time.month.s');
  }

  private schedulerCreateTimelineWeek(): void {
    scheduler.createTimelineView({
      name: this.timelineWeekName,
      x_unit: 'day',
      x_date: '%l <br/> %M %d',
      x_step: 1,
      x_size: 7,
      y_unit: scheduler.serverList('sections'),
      y_property: 'section_id',
      render: 'tree',
      folder_dy: this.mainRowHeightPx,
      dy: this.subRowHeightPx,
      dx: 300,
      event_dy: 19,
      section_autoheight: false,
      second_scale: {
        x_unit: 'week',
        x_date: `${this.translate('general.units.time.week.calendarWeek')} %W`,
      },
    });

    scheduler.date[this.timelineWeekName + '_start'] = scheduler.date.week_start;
    scheduler.locale.labels[this.timelineWeekName + '_tab'] = this.translate('general.units.time.week.s');
  }

  private schedulerEvents(): void {

    // deactivate resize for readonly events
    scheduler.attachEvent('onBeforeDrag', this.schedulerBlockReadonly);
    scheduler.attachEvent('onClick', this.schedulerBlockReadonly);
    scheduler.attachEvent('onAfterFolderToggle', this.saveOpenSections.bind(this));
    scheduler.attachEvent('onMouseMove', this.onMouseMoveTimeline.bind(this));
    scheduler.attachEvent('onViewChange', this.onTimelineViewChange.bind(this));

    this.schedulerPreventMoving();
    this.schedulerHideTimeUnit();
    this.schedulerDragEnd();
    this.schedulerLightBox();
  }

  private onTimelineViewChange(mode: string): void {
    this.highlightPredicate = mode === this.timelineYearName
      ? SchedulerHighlighter.getClassDateMonth
      : SchedulerHighlighter.getClassDateDay;
  }

  private saveOpenSections(section: Section | true, isOpen: boolean, allSections: boolean): void {
    // "section takes the true value, if all branches were closed/opened at once by the
    // closeAllSections()/openAllSections() methods"
    if (section === true) {
      return;
    }
    if (isOpen) {
      this.openSections.add(section.key);
    } else {
      this.openSections.delete(section.key);
    }
  }

  private schedulerInit(): void {
    scheduler.xy.scale_height = this.subRowHeightPx;

    // Disable creating new events
    scheduler.config.drag_create = false;
    scheduler.config.dblclick_create = false;

    scheduler.config.container_autoresize = false;

    scheduler.config.cascade_event_display = true;
    scheduler.config.cascade_event_margin = 0;
    scheduler.config.collision_limit = 10;

    // inject scheduler into the html
    scheduler.init(this.schedulerContainer.nativeElement, new Date(), this.timelineMonthName);
  }

  private schedulerLoadData(): void {
    this.updateSections();
    scheduler.parse(this.events, 'json');
  }

  private schedulerSetCustomCssClasses(): void {
    // Custom CSS classes for "events"
    // events are:
    // 1) the horizontal bars on the disposition board representing dispositions
    // 2) the horizontal bars representing project durations
    scheduler.templates.event_class = SchedulerHighlighter.event;

    // Custom CSS classes for "cells"
    // cells in this context are all table cells of the disposition board, that are not part of the header (which shows
    // the date timeframe, i.e. current month, current week, etc) or the first column (which shows projects)
    scheduler.templates[this.timelineYearName + '_cell_class'] = SchedulerHighlighter.monthCell;
    scheduler.templates[this.timelineMonthName + '_cell_class'] = SchedulerHighlighter.dayCellDisposition;
    scheduler.templates[this.timelineWeekName + '_cell_class'] = SchedulerHighlighter.dayCellDisposition;

    // Custom CSS classes for "scaley"
    // scaley is the first column, which shows projects
    scheduler.templates[this.timelineYearName + '_scaley_class'] = SchedulerHighlighter.scaleY;
    scheduler.templates[this.timelineMonthName + '_scaley_class'] = SchedulerHighlighter.scaleY;
    scheduler.templates[this.timelineWeekName + '_scaley_class'] = SchedulerHighlighter.scaleY;

    // Custom CSS classes for "scalex"
    // scalex is the header, which shows the date timeframe, i.e. current month, current week, etc
    scheduler.templates[this.timelineYearName + '_scalex_class'] = SchedulerHighlighter.monthScaleX;
    scheduler.templates[this.timelineMonthName + '_scalex_class'] = SchedulerHighlighter.dayScaleX;
    scheduler.templates[this.timelineWeekName + '_scalex_class'] = SchedulerHighlighter.dayScaleX;

    // Custom CSS classes for "second_scalex"
    // second_scalex is the header line displaying calendar weeks in the month timeline view
    scheduler.templates[this.timelineMonthName + '_second_scalex_class'] = SchedulerHighlighter.daySecondScaleX;
  }

  private schedulerPreventMoving(): void {
    // prevent moving events to a different row
    scheduler.config.drag_move = false;
    const old_timeline_section = scheduler['_update_timeline_section'];

    scheduler['_update_timeline_section'] = function () {
      if (scheduler.getState().drag_mode !== 'resize') {
        return old_timeline_section.apply(this, arguments);
      }
    };
  }

  private schedulerBlockReadonly(id): boolean {
    return id ? !scheduler.getEvent(id).readonly : true;
  }

  private getCommentString(content: string): string {
    return `<div class="comment">
              <span>${this.translate('general.comment.s')}: </span>
              <span class="comment__message">${decodeURIComponent(content)}</span>
            </div>`;
  }

  private schedulerTooltips(): void {
    scheduler.templates.tooltip_text = (start: Date, end: Date, event: TimelineEvent) => {
      const startDate = moment(start).format(this.tooltipDateFormat);
      const endDate = moment(end).format(this.tooltipDateFormat);
      const comment = ((isEquipmentAssignmentEvent(event) || isEmployeeAssignmentEvent(event)) && event.assignment.comment)
        ? this.getCommentString(event.assignment.comment)
        : '';

      if (isProjectEvent(event)) {
        if (event.charge_date) {
          return `<b>${_.startCase(this.translate('general.from'))}: </b>${startDate} <br />
                <b>${_.startCase(this.translate('general.to'))}: </b>${endDate} <br />
                <b>${this.translate('modules.disposition.dispositionTimeline.billingDate')}: </b>
                  ${this.timezoneDatesService.convertDateWithTimezone(event.charge_date, this.tooltipDateFormat)}`;
        } else {
          return `<b>${_.startCase(this.translate('general.from'))}: </b>${startDate} <br />
                <b>${_.startCase(this.translate('general.to'))}: </b>${endDate}`;
        }
      }

      if (event.readonly) {
        // TODO: should Nutzungsprofil be dynamic
        return `<b>${this.translate('modules.disposition.dispositionTimeline.planned')}</b><br />
                <b>${this.translate('modules.disposition.dispositionTimeline.scheduledFor')}: </b>${event.text} <br />
                <b>${this.translate('general.labels.start')}: </b>${startDate} <br />
                <b>${this.translate('general.labels.end')}: </b>${endDate} ${comment}`;
      } else if (isEquipmentAssignmentEvent(event)) {
        // TODO: should Nutzungsprofil be dynamic
        return `<b>${this.translate('modules.disposition.dispositionTimeline.planned')}</b><br />
                <b>${this.translate('general.labels.start')}: </b>${startDate} <br />
                <b>${this.translate('general.labels.end')}: </b> ${endDate} ${comment}`;
      } else if (isTransferItemAssignmentEvent(event)) {
        return `<b>${this.translate('modules.disposition.dispositionTimeline.actual')}</b><br />
                <b>${this.translate('general.labels.start')}: </b>${startDate} <br />
                <b>${this.translate('general.labels.end')}: </b>${endDate} ${comment}`;
      } else {
        return `<b>${this.translate('general.labels.start')}: </b>${startDate} <br />
                <b>${this.translate('general.labels.end')}: </b>${endDate} ${comment}`;
      }
    };
  }

  private schedulerHideTimeUnit(): void {
    scheduler.attachEvent('onLightbox', () => {
      const node = scheduler.formSection('time').node;
      const selects = node.getElementsByTagName('select');
      selects[0].style.display = 'none';
      selects[4].style.display = 'none';
    });

    scheduler.attachEvent('onAfterLightbox', () => {
      scheduler.updateView();
    });

    scheduler.attachEvent('onEventChanged', () => {
      scheduler.updateView();
    });
  }

  private schedulerDragEnd(): void {
    let draggedEvent;
    let initialEvent;

    scheduler.attachEvent('onBeforeDrag', (id) => {
      draggedEvent = scheduler.getEvent(id);
      initialEvent = { ...draggedEvent };

      if (isEquipmentAssignmentEvent(initialEvent)) {
        const active = this.equipmentCheckerService.isActiveEquipment(initialEvent.assignment);
        if (!active) {
          this.snackBar.open(this.translate('modules.disposition.dispositionTimeline.equipmentInactiveMessage'),
            undefined,
            {
              duration: 4000,
            });
        }
        return initialEvent.assignment.projectStatus !== ProjectStatus.FINISHED && active;
      }

      return initialEvent.assignment.projectStatus !== ProjectStatus.FINISHED;
    });

    scheduler.attachEvent('onBeforeEventChanged', () => {
      return true;
    });

    scheduler.attachEvent('onDragEnd', () => {
      const event = draggedEvent;

      if (isEquipmentAssignmentEvent(event)) {
        this.equipmentStore.getAssignmentCollisions(
          draggedEvent.assignment.equipmentId,
          DatesService.sameTimeZoneAtStartDateUTC(draggedEvent.start_date),
          DatesService.sameTimeZoneAtEndDateUTC(draggedEvent.end_date),
          draggedEvent.assignment.assignmentId)
          .pipe(untilDestroyed(this))
          .subscribe((conflictingAssignments: ViewEquipmentProjectAssignment[]) => {
            if (conflictingAssignments.length === 0) {
              this.convertEventEndDate(event);
              this.updateAssignment(event, initialEvent);
            } else {
              this.revertEvent(initialEvent, false);
            }
          });
      } else if (isEmployeeAssignmentEvent(event)) {
        this.employeeDispositionStore.getAssignmentCollisions(
          draggedEvent.assignment.employeeId,
          DatesService.sameTimeZoneAtStartDateUTC(draggedEvent.start_date),
          DatesService.sameTimeZoneAtEndDateUTC(draggedEvent.end_date),
          draggedEvent.assignment.assignmentId)
          .pipe(untilDestroyed(this))
          .subscribe((conflictingAssignments: ViewEmployeeProjectAssignment[]) => {
            if (conflictingAssignments.length === 0) {
              this.convertEventEndDate(event);
              this.updateAssignment(event, initialEvent);
            } else {
              this.revertEvent(initialEvent, false);
            }
          });
      }
    });
  }

  private schedulerLightBox(): void {
    scheduler.attachEvent('onBeforeLightbox', (id) => {
      const event = scheduler.getEvent(id);
      const initialEvent = { ...event };

      if (isEquipmentAssignmentEvent(initialEvent)
        && !this.equipmentCheckerService.isActiveEquipment(initialEvent.assignment)) {
        this.snackBar.open(this.translate('modules.disposition.dispositionTimeline.equipmentInactiveMessage'),
          undefined,
          {
            duration: 4000
          });

        return false;
      } else {
        let dialog: any = isEquipmentAssignmentEvent(event) ? EquipmentAssignmentEditDialogComponent :
          EmployeeAssignmentEditDialogComponent;

        const project = this.dispositionStore.viewProjectsWithAssignments.find(p => p.projectId === event.assignment.projectId);
        let dialogRef;

        dialogRef = this.dialog.open(dialog, <MatDialogConfig>{});
        dialogRef.componentInstance.assignment = event.assignment;
        dialogRef.componentInstance.project = project;

        dialogRef.afterClosed().subscribe((result) => {

          if (result.length === 0) {
            return false;
          }
          if (result.delete === true) {
            scheduler.deleteEvent(id);
            this.deleteAssignment(result.command, initialEvent);
          } else {
            this.updateAssignmentWithEditDialog(event, initialEvent, result);
          }
        });
        return false;
      }
    });
  }

  private deleteAssignment(cmd: DeleteEquipmentAssignmentCommand, initialEvent: any): void {
    this.equipmentStore.deleteAssignment(cmd).subscribe(
      {
        next: () => this.dispositionStore.updateListing(),
        error: (error: HttpErrorResponse) =>
          this.revertEventWithMessage(initialEvent, error, 'Delete Equipment Assignment error', true)
      }
    );
  }

  private updateAssignmentWithEditDialog(event: any, initialEvent: any, result: any): void {
    let newStartDate = this.setEventNewStartDate(event, result);
    let newEndDate: any = this.setEventNewEndDate(event, initialEvent.end_date, result);
    let comment = result.comment;

    Object.defineProperty(event, 'start_date', { value: newStartDate });
    Object.defineProperty(event, 'end_date', { value: newEndDate });
    Object.defineProperty(event.assignment, 'comment', { value: comment });

    this.updateAssignment(event, initialEvent);
  }

  private setEventNewStartDate(event: EquipmentAssignmentEvent | EmployeeAssignmentEvent, result: any): Date {
    return isEquipmentAssignmentEvent(event) ? new Date(result.newStartDate) : new Date(result.newAssignmentStartDate);
  }

  private setEventNewEndDate(event: EquipmentAssignmentEvent | EmployeeAssignmentEvent, oldEndDate: Date, result: any): Date {
    if (isEquipmentAssignmentEvent(event)) {
      return result.newEndDate ? new Date(result.newEndDate) : oldEndDate;
    } else {
      return result.newAssignmentEndDate ? new Date(result.newAssignmentEndDate) : oldEndDate;
    }
  }


  private schedulerOnBeforeViewChange(): void {
    // custom logic to go to next/previous month, because default behavior may skip one
    scheduler.date['add_' + this.timelineMonthName] = function (date: Date, step: number) {
      if (step > 0) {
        step = 1;
      } else if (step < 0) {
        step = -1;
      }
      return scheduler.date.add(date, step, 'month');
    };

    scheduler.attachEvent('onBeforeViewChange', (old_timelineView: string,
                                                 old_date: Date,
                                                 new_timelineView: string,
                                                 new_date: Date) => {
      if (!this.dispositionStore.showIrrelevantAssignments
        && (old_timelineView !== new_timelineView || old_date !== new_date)) {
        this.hideSectionsWithoutAssignment(new_timelineView, new_date);
      }
      if (new_timelineView === this.timelineMonthName) {
        scheduler['matrix'][this.timelineMonthName].x_size = moment(new_date).daysInMonth();
      }
      return true;
    });
  }

  private schedulerOnEmptyClick(): void {
    scheduler.attachEvent('onEmptyClick', (date, event) => this.checkSchedulerEventProjectNavigation(event))
  }

  private checkSchedulerEventProjectNavigation(event: any): void {
    if (event.target && event.target.hasAttribute(this.attributeProjectLink)) {
      let id = event.target.getAttribute(this.attributeProjectLink);
      this.router.navigate(['sites/projects/list', id, 'general']);
    }

    if (event.target && event.target.hasAttribute(this.attributeEquipmentLink)) {
      let id = event.target.getAttribute(this.attributeEquipmentLink);
      this.router.navigate(['/assets/equipment/list', id, 'general']);
    }
  }

  private schedulerOnMouseMoveListener(): void {
    scheduler.attachEvent('onMouseMove', (id, event) => this.checkSchedulerEventShowTooltip(event));
  }

  private checkSchedulerEventShowTooltip(event: any): void {
    if (event.target && event.target.hasAttribute(this.attributeProjectNumber)) {
      this.showSchedulerTooltip(event, this.attributeProjectNumber);
    }

    if (event.target && event.target.hasAttribute(this.attributeProjectName)) {
      this.showSchedulerTooltip(event, this.attributeProjectName);
    }

    if (event.target && event.target.hasAttribute(this.attributeCostCenter)) {
      this.showSchedulerTooltip(event, this.attributeCostCenter);
    }

    if (event.target && event.target.hasAttribute(this.attributeAddress)) {
      this.showSchedulerTooltip(event, this.attributeAddress);
    }
  }

  private showSchedulerTooltip(event: any, attribute: string): void {
    const isOverflow = event.target.scrollWidth > event.target.clientWidth;
    if (isOverflow) {
      const tooltip: SchedulerTooltip = scheduler['dhtmlXTooltip'];
      const value = event.target.getAttribute(attribute);
      tooltip.delay(
        tooltip.show,
        tooltip,
        [event, this.getTooltipContent(value)],
        this.tooltipDelay);
    }
  }

  private getTooltipContent(value: string): string {
    return `<div>${value}</div>`;
  }

  private hideSectionsWithoutAssignment(timelineName: string, selectedDate: Date) {
    let timeUnit: unitOfTime.All;
    switch (timelineName) {
      case this.timelineYearName:
        timeUnit = 'year';
        break;
      case this.timelineMonthName:
        timeUnit = 'month';
        break;
      case this.timelineWeekName:
        timeUnit = 'isoWeek';
        break;
      default:
        console.log('unknown timeline:', timelineName);
        return;
    }

    const firstDay = moment(selectedDate).startOf(timeUnit).toDate();
    const lastDay = moment(selectedDate).endOf(timeUnit).toDate();

    const assignmentIdsInCurrentTimespan = this.events
      .filter(this.isVisibleAssignment(firstDay, lastDay))
      .map((assignmentSection: TimelineEvent) => assignmentSection.section_id);

    const mappedSections = this.sections.map((projectSection: Section) => {
      let assignedElements: Section[] = [this.createEmptyPlaceholderSection(
        this.getEmptyLabelValue(), this.selectedTypes)];
      if (projectSection.children.length > 0) {
        assignedElements = projectSection.children.filter(
          equipmentSection => assignmentIdsInCurrentTimespan.includes(equipmentSection.key));
        if (assignedElements.length === 0) {
          assignedElements = [this.createEmptyPlaceholderSection(
            this.getNoActiveAssignmentsLabelValue(), this.selectedTypes)];
        }
      }
      return {
        key: projectSection.key,
        label: projectSection.label,
        children: assignedElements,
        open: this.wasSectionOpen(projectSection.key),
      } as Section;
    });
    scheduler.updateCollection('sections', mappedSections);
  }

  private getEmptyLabelValue(): string {
    if (this.selectedTypes.includes(ListType.EQUIPMENTS) && this.selectedTypes.includes(ListType.EMPLOYEES)) {
      return this.translate('modules.disposition.dispositionTimeline.noEquipmentsNoEmployeesForPeriod');
    } else if (this.selectedTypes.includes(ListType.EQUIPMENTS)) {
      return this.translate('modules.disposition.dispositionTimeline.noEquipmentsForPeriod');
    } else if (this.selectedTypes.includes(ListType.EMPLOYEES)) {
      return this.translate('modules.disposition.dispositionTimeline.noEmployeesForPeriod');
    } else {
      return this.translate('modules.disposition.dispositionTimeline.selectOption');
    }
  }

  private getNoActiveAssignmentsLabelValue(): string {
    if (this.selectedTypes.includes(ListType.EQUIPMENTS) && this.selectedTypes.includes(ListType.EMPLOYEES)) {
      return this.translate('modules.disposition.dispositionTimeline.noEquipmentsNoEmployeesAssigned');
    } else if (this.selectedTypes.includes(ListType.EQUIPMENTS)) {
      return this.translate('modules.disposition.dispositionTimeline.noEquipmentsAssigned');
    } else if (this.selectedTypes.includes(ListType.EMPLOYEES)) {
      return this.translate('modules.disposition.dispositionTimeline.noEmployeesAssigned');
    } else {
      return this.translate('modules.disposition.dispositionTimeline.selectOption');
    }
  }

  private isVisibleAssignment(firstDay: Date, lastDay: Date): (assignment: TimelineEvent) => boolean {
    return (assignment: TimelineEvent) => !isEquipmentNotAvailableEvent(assignment)
      && this.isTimespansOverlapping(assignment.start_date, assignment.end_date, firstDay, lastDay);
  }

  private isTimespansOverlapping(start1: Date, end1: Date, start2: Date, end2: Date) {
    return start1 < end2 && start2 < end1;
  }

  private createEmptyPlaceholderSection(description: string, types: string[]): EmptyPlaceholderSection {
    return {
      label: description,
      key: '',
      types: types,
    };
  }

  private setEvents(projects: ViewProject[]): void {
    projects.forEach((project: ViewProject) => {
      const assignedSections: Section[] = [];
      project.equipmentAssignments = this.groupSubEquipmentsToContainers(project.equipmentAssignments);
      project.equipmentAssignments.forEach((assignment: ViewEquipmentProjectAssignment) => {
        const equipmentAssignmentEvent = new EquipmentAssignmentEvent(assignment);
        this.events.push(equipmentAssignmentEvent);
        this.addEquipmentSectionFromAssignment(assignedSections, equipmentAssignmentEvent.section_id, assignment);
      });
      this.equipmentAssignments.push(...project.equipmentAssignments);

      project.timelineEntries = this.groupTransfersToContainers(project.timelineEntries);
      project.timelineEntries.forEach((entry: ViewAmountTimelineEntry) => {
        const transferItemAssignmentEvent = new TransferItemAssignmentEvent(entry);
        this.events.push(transferItemAssignmentEvent);
        this.addEquipmentSectionFromAmount(assignedSections, transferItemAssignmentEvent.section_id, entry);
      });

      project.employeeAssignments = this.groupTeamsToContainers(project.employeeAssignments);
      project.employeeAssignments.forEach((assignment: ViewEmployeeProjectAssignment) => {
        const employeeAssignmentEvent = new EmployeeAssignmentEvent(assignment);
        this.events.push(employeeAssignmentEvent);
        this.addEmployeeAssignmentSection(assignedSections, employeeAssignmentEvent.section_id, assignment);
      });
      this.employeeAssignments.push(...project.employeeAssignments);

      if (project.projectStatus === 'DELETED') {
        return;
      }

      this.addProjectSection(project, assignedSections);
      this.events.push(new ProjectEvent(project));
    });

    this.dispositionStore.getAllAssignmentsForEquipmentsByProjectIds(
      this.equipmentAssignments
        .map((item: ViewEquipmentProjectAssignment) => item ? item.projectId : null)
        .filter(Boolean)
        .filter((value, index, self) => self.indexOf(value) === index),
    );

    this.dispositionStore.getAssignmentByEmployeeIds(
      this.employeeAssignments
        .map((item: ViewEmployeeProjectAssignment) => item ? item.employeeId : null)
        .filter(Boolean),
    );
  }

  private groupSubEquipmentsToContainers(assignments: ViewEquipmentProjectAssignment[]): ViewEquipmentProjectAssignment[] {
    const subEquipments: ViewEquipmentProjectAssignment[] = [];
    const normalEquipments: ViewEquipmentProjectAssignment[] = [];
    assignments.forEach((assignment: ViewEquipmentProjectAssignment) => {
      if (assignment.equipmentContainerId) {
        subEquipments.push(assignment);
      } else {
        normalEquipments.push(assignment);
      }
    });
    while (subEquipments.length) {
      let subEquipment = subEquipments.pop();
      let index = normalEquipments.findIndex((equipment: ViewEquipmentProjectAssignment) =>
        equipment.equipmentId === subEquipment.equipmentContainerId);
      if (index === -1) {
        // current solution for subequipments, which are dispositioned without their container equipment
        subEquipment.equipmentContainerId = undefined;
        normalEquipments.push(subEquipment);
      } else {
        normalEquipments.splice(index + 1, 0, subEquipment);
      }
    }
    return normalEquipments;
  }

  private groupTransfersToContainers(timelineEntries: ViewAmountTimelineEntry[]): ViewAmountTimelineEntry[] {
    const subEquipments: ViewAmountTimelineEntry[] = [];
    const normalEquipments: ViewAmountTimelineEntry[] = [];
    timelineEntries.forEach((entry: ViewAmountTimelineEntry) => {
      if (entry.equipmentContainerId) {
        subEquipments.push(entry);
      } else {
        normalEquipments.push(entry);
      }
    });
    while (subEquipments.length) {
      let subEquipment = subEquipments.pop();
      let index = normalEquipments.findIndex((entry: ViewAmountTimelineEntry) =>
        entry.transferItemId === subEquipment.equipmentContainerId);
      if (index === -1) {
        // current solution for subequipments, which are dispositioned without their container equipment
        subEquipment.equipmentContainerId = undefined;
        normalEquipments.push(subEquipment);
      } else {
        normalEquipments.splice(index + 1, 0, subEquipment);
      }
    }

    return normalEquipments;
  }

  private groupTeamsToContainers(assignments: ViewEmployeeProjectAssignment[]): ViewEmployeeProjectAssignment[] {
    const teamMembers: ViewEmployeeProjectAssignment[] = [];
    const employees: ViewEmployeeProjectAssignment[] = [];

    assignments.forEach((assignment: ViewEmployeeProjectAssignment) => {
      if (assignment.isTeamComplete && assignment.employeeTeamId) {
        if (assignment.employeeIsTeamLeader) {
          employees.push(assignment);
        } else {
          teamMembers.push(assignment);
        }
      } else {
        employees.push(assignment);
      }
    });
    while (teamMembers.length) {
      let teamMember = teamMembers.pop();
      let index = employees.findIndex((employee: ViewEmployeeProjectAssignment) =>
        employee.employeeTeamId === teamMember.employeeTeamId &&
        employee.employeeIsTeamLeader);
      if (index === -1) {
        // current solution for teamMembers, which are dispositioned without teams
        employees.push(teamMember);
      } else {
        employees.splice(index + 1, 0, teamMember);
      }
    }
    return employees;
  }

  private addEquipmentSectionFromAssignment(sections: Section[], newSectionId: string, assignment: ViewEquipmentProjectAssignment) {
    if (!sections.find((section: Section) => section.key === newSectionId)) {
      const assignmentSection: EquipmentSection = {
        key: newSectionId,
        label: `<div class="flex-row-label">
                  ${this.getEquipmentContainerId(assignment.equipmentContainerId)}
                  <div class="row-label">
                    <div>${assignment.equipmentName ? assignment.equipmentName : assignment.equipmentModel}</div>
                    <div>${joinNonEmpty(' / ', assignment.equipmentInternalSerialNumber, assignment.equipmentSerialNumber)}</div>
                  </div>
                </div>`,
        equipmentId: assignment.equipmentId,
      };
      if (assignment.equipmentContainerId) {
        (<SubEquipmentSection>assignmentSection).equipmentContainerId = assignment.equipmentContainerId;
      }
      sections.push(assignmentSection);
    }
  }

  private addEquipmentSectionFromAmount(sections: Section[], newSectionId: string, entry: ViewAmountTimelineEntry): void {
    if (!sections.find((section: Section) => section.key === newSectionId)) {
      const assignmentSection: EquipmentSection = {
        key: newSectionId,
        label: `<div class="flex-row-label">
                  ${this.getEquipmentContainerId(entry.equipmentContainerId)}
                  <div class="row-label">
                    <div>${entry.transferItemName ? entry.transferItemName : ''}</div>
                    <div>${joinNonEmpty(' / ', entry.transferItemInternalSerialNumber, entry.transferItemSerialNumber)}</div>
                  </div>
                </div>`,
        equipmentId: entry.transferItemId,
      };
      if (entry.equipmentContainerId) {
        (<SubEquipmentSection>assignmentSection).equipmentContainerId = entry.equipmentContainerId;
      }
      sections.push(assignmentSection);
    }
  }

  private getEquipmentContainerId(id: string): string {
    return id
      ? `<div class="star-div">
                <span class="helper-span"></span>
                <img class="center-image" src="../../../../../assets/disposition_equipment.png"></img>
              </div>`
      : '';
  }

  private addEmployeeAssignmentSection(sections: Section[],
                                       newSectionId: string,
                                       assignment: ViewEmployeeProjectAssignment): void {
    if (!sections.find((section: Section) => section.key === newSectionId)) {
      const assignmentSection: EmployeeSection = {
        key: newSectionId,
        label: `<div class="flex-row-label">
                  ${this.isLeaderOfCompleteTeam(assignment) ?
          `<div class="star-div"><span class="helper-span"></span><img class="center-image"
                     src="../../../../../assets/teamleader_star.png"></img></div>` : ''}
                  ${this.isMemberOfCompleteTeam(assignment) ?
          `<div class="star-div"><span class="helper-span"></span><img class="center-image"
                     src="../../../../../assets/disposition_employee.png"></img></div>` : ''}
                  <div class="row-label">
                    <div>${assignment.employeeName + ' ' + assignment.employeeFirstName}</div>
                  </div>
                </div>`,
        employeeId: assignment.employeeId,
      };
      if (assignment.employeeTeamId && assignment.isTeamComplete) {
        if (!sections.find((section: Section) =>
          section.key === assignment.projectId + '/' + assignment.employeeTeamId)) {
          this.addTeamHeaderSection(sections, assignment);
        }
        (<TeamMemberSection>assignmentSection).employeeTeamId = assignment.employeeTeamId;
      }
      sections.push(assignmentSection);
    }
  }

  private addTeamHeaderSection(sections: Section[], assignment: ViewEmployeeProjectAssignment) {
    const teamHeaderSection: TeamHeaderSection = {
      key: assignment.projectId + '/' + assignment.employeeTeamId,
      label: `<div class="flex-row-label">
                <div class="row-label">
                  <div>${assignment.employeeTeamName}</div>
                </div>
              </div>`,
      teamName: assignment.employeeTeamId,
    };
    sections.push(teamHeaderSection);
    this.events.push(new TeamHeaderEvent(
      assignment.projectId + '/' + assignment.employeeTeamId,
      assignment.assignmentStartDate,
      assignment.assignmentEndDate));
  }

  private addProjectSection(project: ViewProject, assignedSections: Section[]): void {
    const costCenter = this.getCostCenter(project.projectCostCenter);
    const uniqueTransfers = new Set(project.timelineEntries.map(
      (entry: ViewAmountTimelineEntry) => entry.transferItemId)).size;
    const uniqueEquipments = new Set(project.equipmentAssignments.map(
      (assignment: ViewEquipmentProjectAssignment) => assignment.equipmentId)).size;
    const uniqueEmployee = new Set(project.employeeAssignments.map(
      (assignment: ViewEmployeeProjectAssignment) => assignment.employeeId)).size;
    const address = this.addressService.formatFullAddressSingleLineOrderByStreetThenCity(
      project.projectAddress.postalCode,
      project.projectAddress.city,
      project.projectAddress.street,
      project.projectAddress.streetNumber);
    const projectSection: Section = {
      key: project.projectId,
      label: `<div class="row">
                <div class="row-info">
                  <div ${this.attributeProjectNumber}="${project.projectNumber}" class="project-number"># ${project.projectNumber}</div>
                  <div class="cost-center__wrapper">
                    <svg class="cost-center-icon" role="img" aria-hidden="true" focusable="false" viewBox="0 0 512 512">
                      ${this.iconDefinitionPath(faWallet)}
                    </svg>
                    <div ${this.attributeCostCenter}="${costCenter}" class="cost-center">${costCenter}</div>
                  </div>
                  <div class="amount-name">
                    <div class="amount">${uniqueTransfers}/${uniqueEquipments + uniqueEmployee}</div>
                    <div ${this.attributeProjectName}="${project.projectName}" class="name">${project.projectName}</div>
                  </div>
                  <div ${this.attributeAddress}="${address}" class="address">${address}</div>
                </div>
                <span ${this.attributeProjectLink}="${project.projectId}" class="material-icons link-icon">open_in_new</span>
              </div>`,
      open: this.wasSectionOpen(project.projectId),
      children: assignedSections,
    };

    this.sections.push(projectSection);
  }

  private getCostCenter(costCenter: string): string {
    return costCenter ? costCenter : '-';
  }

  private iconDefinitionPath(icon: IconDefinition): string {
    return `<path fill="currentColor" d="${icon.icon[4]}"></path>`;
  }

  private clearSchedulerData(): void {
    this.events = [];
    this.sections = [];
    this.equipmentAssignments = [];
    this.employeeAssignments = [];
    scheduler.clearAll();
  }

  private wasSectionOpen(key: string): boolean {
    return this.openSections.has(key);
  }

  private convertEventEndDate(event: TimelineEvent): void {
    scheduler.setEventEndDate(event.id, new Date(moment.utc(event.end_date).format('YYYY-MM-DD')));
  }

  private revertEvent(event: TimelineEvent, del: boolean): void {
    if (del) {
      scheduler.addEvent(event)
    } else {
      scheduler.setEventStartDate(event.id, new Date(event.start_date));
      scheduler.setEventEndDate(event.id, new Date(event.end_date));
    }
  }

  private updateEquipmentNotAvailableEvents(event: EquipmentAssignmentEvent): void {
    const oppositeEvent = this.events.find((ev) => {
      return isEquipmentNotAvailableEvent(ev) && ev.conflictingAssignment.assignmentId === event.assignment.assignmentId;
    });

    if (oppositeEvent) {
      scheduler.setEventStartDate(oppositeEvent.id, new Date(DatesService.sameTimeZoneAtStartDateUTC(event.start_date)));
      scheduler.setEventEndDate(oppositeEvent.id, new Date(DatesService.sameTimeZoneAtEndDateUTC(event.end_date)));
    }
  }

  private updateEmployeeNotAvailableEvents(event: EmployeeAssignmentEvent): void {
    const oppositeEvents = this.events.filter((ev) => {
      return isEmployeeNotAvailableEvent(ev) && ev.conflictingAssignment.assignmentId === event.assignment.assignmentId;
    });

    if (oppositeEvents) {
      oppositeEvents.forEach(oppositeEvent => {
        scheduler.setEventStartDate(oppositeEvent.id, new Date(DatesService.sameTimeZoneAtStartDateUTC(event.start_date)));
        scheduler.setEventEndDate(oppositeEvent.id, new Date(DatesService.sameTimeZoneAtEndDateUTC(event.end_date)));
      });
    }
  }

  private updateAssignment(event: EquipmentAssignmentEvent | EmployeeAssignmentEvent,
                           initialEvent: TimelineEvent): void {
    if (isEquipmentAssignmentEvent(event)) {
      this.equipmentAssignmentUpdate(event, initialEvent);
    } else {
      this.employeeAssignmentUpdate(event, initialEvent);
    }
  }

  private equipmentAssignmentUpdate(event: EquipmentAssignmentEvent, initialEvent: TimelineEvent) {
    let cmd: UpdateEquipmentAssignmentCommand =
      this.createUpdateAssignmentCommand(event) as UpdateEquipmentAssignmentCommand;
    this.updateEquipmentEventStartAndEndDate(event);
    this.equipmentStore.updateAssignment(cmd)
      .subscribe(
        {
          next: () => this.dispositionStore.updateListing(),
          error: (error: HttpErrorResponse) =>
            this.revertEventWithMessage(initialEvent, error, 'Assign Project to Equipment error', false)
        });
  }

  private updateEquipmentEventStartAndEndDate(event: EquipmentAssignmentEvent): void {
    this.updateEquipmentNotAvailableEvents(event);
    event.assignment.startDate = event.start_date;
    event.assignment.endDate = event.end_date;
    scheduler.setEventStartDate(event.id, moment(new Date(event.start_date)).startOf('day').toDate());
    scheduler.setEventEndDate(event.id, moment(new Date(event.end_date)).endOf('day').toDate());
  }

  private employeeAssignmentUpdate(event: EmployeeAssignmentEvent, initialEvent: TimelineEvent) {
    this.validateConflicts(event, event.assignment.employeeId)
      .subscribe(
        {
          next: (assignments: ViewEmployeeProjectAssignment[]) => {
            let hasConflicts = assignments
              .some(assignment => assignment.assignmentId !== event.assignment.assignmentId);
            this.onEmployeeAssignmentUpdateConflictsValidated(hasConflicts, event, initialEvent);
          },
          error: (error: HttpErrorResponse) =>
            this.revertEventWithMessage(initialEvent, error, 'Validate employee disposition error', false)
        }
      );
  }

  private revertEventWithMessage(initialEvent: TimelineEvent,
                                 error: HttpErrorResponse,
                                 message: string,
                                 del: boolean): void {
    this.revertEvent(initialEvent, del);
    console.log(message, error.message);
  }

  private onEmployeeAssignmentUpdateConflictsValidated(hasConflicts: boolean,
                                                       event: EmployeeAssignmentEvent,
                                                       initialEvent: TimelineEvent): void {
    if (hasConflicts) {
      this.revertEvent(initialEvent, false);
      this.showAnotherAssignmentConflictsWarning();
      return;
    }
    this.executeEmployeeToProjectAssignment(event, initialEvent);
  }

  private executeEmployeeToProjectAssignment(event: EmployeeAssignmentEvent,
                                             initialEvent: TimelineEvent) {
    let cmd: UpdateEmployeeToProjectAssignmentCommand =
      this.createUpdateAssignmentCommand(event) as UpdateEmployeeToProjectAssignmentCommand;
    this.updateEmployeeEventStartAndEndDate(event);
    this.employeeDispositionStore.updateEmployeeToProjectAssignment(cmd)
      .subscribe({
        next: () => setTimeout(() => {
          this.employeeDispositionStore.updateListing();
        }, environment.DELAY_SHORTEST),
        error: (error: HttpErrorResponse) =>
          this.revertEventWithMessage(initialEvent, error, 'Assign Project to Employee error', false)
      });
  }

  private updateEmployeeEventStartAndEndDate(event: EmployeeAssignmentEvent): void {
    this.updateEmployeeNotAvailableEvents(event);
    event.assignment.assignmentStartDate = event.start_date;
    event.assignment.assignmentEndDate = event.end_date;
    scheduler.setEventStartDate(event.id, moment(new Date(event.start_date)).startOf('day').toDate());
    scheduler.setEventEndDate(event.id, moment(new Date(event.end_date)).endOf('day').toDate());
  }

  private createUpdateAssignmentCommand(event: EquipmentAssignmentEvent | EmployeeAssignmentEvent):
    UpdateEquipmentAssignmentCommand | UpdateEmployeeToProjectAssignmentCommand {

    let cmd: UpdateEquipmentAssignmentCommand | UpdateEmployeeToProjectAssignmentCommand;
    const newStartDate = DatesService.sameTimeZoneAtStartDateUTC(event.start_date);
    const newEndDate = DatesService.sameTimeZoneAtEndDateUTC(event.end_date);
    if (isEquipmentAssignmentEvent(event)) {
      cmd = new UpdateEquipmentAssignmentCommand();
      cmd.equipmentId = event.assignment.equipmentId;
      cmd.newStartDate = newStartDate;
      cmd.newEndDate = newEndDate;
    } else {
      cmd = new UpdateEmployeeToProjectAssignmentCommand();
      cmd.employeeId = event.assignment.employeeId;
      cmd.newAssignmentStartDate = newStartDate;
      cmd.newAssignmentEndDate = newEndDate;
    }

    cmd.assignmentId = event.assignment.assignmentId;
    cmd.projectId = event.assignment.projectId;
    cmd.comment = event.assignment.comment;

    return cmd;
  }

  private setSelectedValueFromStorage(): void {
    let selectedTypes = JSON.parse(this.localStorageService.getUserValue(this.selectedTypeStorageKey));
    selectedTypes ? this.selectedTypes = selectedTypes : this.selectedTypes = [ListType.EMPLOYEES, ListType.EQUIPMENTS];
    this.selectedTypes.forEach(type => {
      if (!this.checkAuthority(type)) {
        this.selectedTypes.splice(this.selectedTypes.indexOf(type), 1);
        this.localStorageService.setUserValue(this.selectedTypeStorageKey, JSON.stringify(this.selectedTypes));
      }
    });
    this.dispositionStore.changeSelectedType(this.selectedTypes);
  }

  public activateView(option: string): void {
    const index = this.selectedTypes.indexOf(option);
    index !== -1 ? this.selectedTypes.splice(index, index + 1) : this.selectedTypes.push(option);
    this.localStorageService.setUserValue(this.selectedTypeStorageKey, JSON.stringify(this.selectedTypes));
    this.dispositionStore.changeSelectedType(this.selectedTypes);
  }

  public isSelected(option: string): boolean {
    return this.selectedTypes.includes(option);
  }


  private showAnotherAssignmentConflictsWarning(): void {
    this.snackBar.open(
      this.translate('modules.disposition.dispositionTimeline.assignmentsConflictWarning'),
      undefined,
      { duration: 4000 });
    return;
  }

  private validateConflicts(event: EmployeeAssignmentEvent, employeeId: string): Observable<ViewEmployeeProjectAssignment[]> {
    const params: ValidateEmployeeAssignmentParams = {
      id: employeeId,
      assignmentStart: moment(DatesService.sameTimeZoneAtStartDateUTC(event.start_date)).toISOString(),
      assignmentEnd: event.end_date ? moment(DatesService.sameTimeZoneAtEndDateUTC(event.end_date)).toISOString() : null,
      assignmentId: null,
    };
    return this.employeeDispositionService.validateAssignment(employeeId, params);
  }

  private isLeaderOfCompleteTeam(assignment: ViewEmployeeProjectAssignment): boolean {
    return assignment.employeeTeamId && assignment.isTeamComplete && assignment.employeeIsTeamLeader;
  }

  private isMemberOfCompleteTeam(assignment: ViewEmployeeProjectAssignment): boolean {
    return assignment.employeeTeamId && assignment.isTeamComplete && !assignment.employeeIsTeamLeader;
  }

  private subscribeToSelectedType(): void {
    this.dispositionStore.selectedTypes
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(() => this.dispositionStore.getProjectsWithAssignments());
  }

  public resolveOptionIcon(option: string): IconDefinition {
    return this.optionResolver.resolveIcon(option);
  }

  public resolveOptionName(option: string): string {
    return this.optionResolver.resolveName(option);
  }

  public checkAuthority(option: string): boolean {
    switch (option) {
      case ListType.EQUIPMENTS:
        return this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_DISPOSITION)
          && this.authService.hasModule(Modules.DISPOSITION)
          && this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]);
      case ListType.EMPLOYEES:
        return this.authService.hasAuthority(Authorities.EMPLOYEE_MANAGE_DISPOSITION)
          && this.authService.hasModule(Modules.DISPOSITION)
          && this.authService.hasModule(Modules.STAFF_MANAGEMENT);
      default:
        return false;
    }
  }

  get timelineDate(): string {
    if (scheduler.getState()) {
      const viewName = scheduler.getState().mode;
      const date = moment(scheduler.getState().date).locale(this.getCurrentLocale());

      switch (viewName) {
        case this.timelineYearName:
          return date.format('YYYY');
        case this.timelineMonthName:
          return date.format('MMMM YYYY');
        case this.timelineWeekName:
          const minDate = moment(scheduler.getState().min_date);
          const maxDate = moment(scheduler.getState().max_date);
          return minDate.format('DD.MM.YYYY') + ' - ' + maxDate.format('DD.MM.YYYY');
        default:
          return '';
      }
    }
    return '';
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  // Drag&Drop
  private subscribeToAssetTypeChanges(): void {
    this.dndService.activeListTypeChanges
      .pipe(untilDestroyed(this))
      .subscribe((listType: ListType) => {
        switch (listType) {
          case ListType.EMPLOYEES: {
            this.dragAndDropContainerIds = [
              this.containerIdentifiers.DISPOSITION_TEAM,
              this.containerIdentifiers.DISPOSITION_EMPLOYEE];
            break;
          }
          case ListType.EQUIPMENTS:
          default: {
            this.dragAndDropContainerIds = [this.containerIdentifiers.DISPOSITION_EQUIPMENT];
          }
        }
      });
  }

  private subscribeToDragStatus(): void {
    this.dndService.dragStatus
      .pipe(untilDestroyed(this))
      .subscribe(status => {
        this.draggingStatus = status;
        if (this.draggingStatus && this.draggingStatus.state === DragItemState.CANCELED) {
          this.dragDataReset();
        }
      });
  }

  private subscribeToMouseMoveExternal(): void {
    this.dndService.dragOver
      .pipe(untilDestroyed(this))
      .subscribe(payload => this.onMouseMoveExternal(payload));
  }

  private dragDataReset(): void {
    this.mousePositionDate = null;
    this.lastActionData = null;
    if (this.lastDragOverElement) {
      this.lastDragOverElement.classList.remove(this.dragOverCellClassName);
    }
    this.lastDragOverElement = null;
  }

  private onMouseMoveTimeline(id: string, event: MouseEvent): void {
    if (!this.isDragging()) {
      return;
    }

    if (this.isTimelineCell(<Element>event.target)) {
      this.mouseMoveProcessing({ event, dragOverElement: <Element>event.target });
    } else {
      this.dragDataReset();
    }
  }

  private onMouseMoveExternal(payload: DragOverPayload): void {
    if (!this.isDragging()) {
      return;
    }

    if (payload && payload.dragOverElement) {
      this.mouseMoveProcessing(payload);
    } else {
      this.dragDataReset();
    }
  }

  private mouseMoveProcessing(payload: DragOverPayload): void {
    this.lastActionData = scheduler.getActionData(payload.event);
    const isNewCellDate = this.isNewCellDate(this.mousePositionDate, this.lastActionData.date);
    if (isNewCellDate || this.lastDragOverElement !== payload.dragOverElement) {
      this.mousePositionDate = this.lastActionData.date;
      this.setMouseOverCell(payload.dragOverElement);
    }
  }

  private setMouseOverCell(cell: Element): void {
    if (cell) {
      if (this.lastDragOverElement) {
        this.lastDragOverElement.classList.remove(this.dragOverCellClassName);
      }
      this.lastDragOverElement = cell;
      this.lastDragOverElement.classList.add(this.dragOverCellClassName);
    }
  }

  private isTimelineCell(element: Element): boolean {
    return element.classList.contains(this.matrixCellClassName) ||
      element.parentElement.classList.contains(this.matrixCellClassName);
  }

  private isNewCellDate(previous: Date, current: Date): boolean {
    const timelineMode = scheduler.getState().mode;
    return !previous || (timelineMode === this.timelineYearName
      ? previous.getMonth() !== current.getMonth()
      : previous.getDate() !== current.getDate());
  }

  public isDragging(): boolean {
    return this.draggingStatus && this.draggingStatus.state === DragItemState.START;
  }

  public dropContainerExited(): void {
    this.dragDataReset();
  }

  public dropContainerDropped(event): void {
    if (this.draggingStatus.state === DragItemState.CANCELED) {
      return;
    }
    const timelineCellData = this.getTimelineCellData(this.lastActionData);
    if (timelineCellData && event.item.data) {
      const project = this.projects.find(({ projectId }) => projectId === timelineCellData.projectId);
      switch (this.draggingStatus.type) {
        case DraggableItemType.EQUIPMENT: {
          this.tryAssignEquipment(
            project,
            <SearchEquipment>event.item.data,
            timelineCellData.date,
            timelineCellData.section);
          break;
        }
        case DraggableItemType.EMPLOYEE: {
          this.tryAssignEmployee(
            project,
            <SearchEmployeeDisposition>event.item.data,
            timelineCellData.date,
            timelineCellData.section);
          break;
        }
      }
    }
  }

  private tryAssignEquipment(
    project: ViewProject,
    droppedEquipment: SearchEquipment,
    assignmentDate: Date,
    section: string
  ): void {
    const assignmentType = this.getAssignmentPeriodType();
    this.isEquipmentAssignmentInProgress.next(true);
    this.equipmentDispositionAssignmentService
      .getAssignEquipmentCommand(droppedEquipment, project, assignmentDate, assignmentType)
      .pipe(
        exhaustMap(commands => this.assignEquipmentsToProject(commands)),
        delay(environment.DELAY_LONG),
        untilDestroyed(this))
      .subscribe({
        next: () => {
          this.updateAfterEventAdding(section, ListType.EQUIPMENTS);
          this.isEquipmentAssignmentInProgress.next(false);
          this.dispositionStore.updateListing();
        },
        error: () => this.isEquipmentAssignmentInProgress.next(false),
        complete: () => this.isEquipmentAssignmentInProgress.next(false),
      });

    this.dragDataReset();
  }

  private tryAssignEmployee(
    project: ViewProject,
    droppedEmployee: SearchEmployeeDisposition,
    assignmentDate: Date,
    section: string
  ): void {
    const assignmentType = this.getAssignmentPeriodType();
    this.isEmployeeAssignmentInProgress.next(true);
    this.employeeDispositionAssignmentService
      .getAssignEquipmentCommand(droppedEmployee, project, assignmentDate, assignmentType)
      .pipe(
        exhaustMap(commands => this.assignEmployeesToProject(commands)),
        delay(environment.DELAY_LONG),
        untilDestroyed(this))
      .subscribe({
        next: () => {
          this.updateAfterEventAdding(section, ListType.EMPLOYEES);
          this.isEmployeeAssignmentInProgress.next(false);
          this.employeeDispositionStore.updateListing();
        },
        error: () => this.isEmployeeAssignmentInProgress.next(false),
        complete: () => this.isEmployeeAssignmentInProgress.next(false),
      });
  }

  private assignEquipmentsToProject(commands: AssignEquipmentToProjectCommand[]): Observable<string[]> {
    const requests = commands.map(command =>
      this.dispositionStore.assignEquipmentToProject(command));
    return zip(...requests);
  }

  private assignEmployeesToProject(commands: AddEmployeeToProjectAssignmentCommand[]): Observable<string[]> {
    const requests = commands.map(command =>
      this.employeeDispositionStore.assignEmployeeToProject(command));
    return zip(...requests);
  }

  private getTimelineCellData(actionData: any): { date: Date, projectId: string, section: string } {
    if (actionData) {
      const { date, section } = actionData;
      const sectionKeys = section.split('/');
      if (sectionKeys.length === 1 && sectionKeys[0]) {
        return { date, projectId: sectionKeys[0], section };
      }
      return null;
    }
    return null;
  }

  private getAssignmentPeriodType(): AssignmentPeriodType {
    const { mode } = scheduler.getState();
    if (mode === this.timelineYearName) {
      return AssignmentPeriodType.MONTH;
    }
    return AssignmentPeriodType.DAY;
  }
}
