<!-- This component is not used -->

<div class="general-data-wrapper">
  <form [formGroup]="form">
    <div>
      <mat-form-field>
        <mat-label>{{'general.manufacturer'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemManufacturer"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
               [matAutocomplete]="manufacturerAuto">
        <mat-autocomplete #manufacturerAuto="matAutocomplete">
          <mat-option *ngFor="let manufacturer of manufacturers | async"
                      [value]="manufacturer">
            {{ manufacturer }}
          </mat-option>
        </mat-autocomplete>
        <fa-icon [icon]="faIndustryAlt" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.base.manufacturerNumber'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemManufacturerId"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
        <fa-icon [icon]="faCalculatorAlt" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'general.costCenter'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemCostCenter"
               [maxlength]="fieldLimit.COST_CENTER"
               [matAutocomplete]="costCenterAuto">
        <mat-autocomplete #costCenterAuto="matAutocomplete">
          <mat-option *ngFor="let costCenter of costCenters | async"
                      [value]="costCenter | slice:0:30">
            {{ costCenter }}
          </mat-option>
        </mat-autocomplete>
        <fa-icon [icon]="faFileInvoiceDollar" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.base.costType'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemCostType"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
               [matAutocomplete]="costTypeAuto">
        <mat-autocomplete #costTypeAuto="matAutocomplete">
          <mat-option *ngFor="let type of costTypes | async"
                      [value]="type | slice:0:30">
            {{ type }}
          </mat-option>
        </mat-autocomplete>
        <fa-icon [icon]="faCoins" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.base.supplier'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemSupplier"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
               [matAutocomplete]="supplierAuto">
        <mat-autocomplete #supplierAuto="matAutocomplete">
          <mat-option *ngFor="let supplier of suppliers | async"
                      [value]="supplier">
            {{ supplier }}
          </mat-option>
        </mat-autocomplete>
        <fa-icon [icon]="faTruck" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.base.supplierNumber'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemSupplierId"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
        <fa-icon [icon]="faCalculatorAlt" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field fxFlex="35">
        <mat-label>{{'general.units.unit'|translate}}</mat-label>
        <mat-select formControlName="bulkItemUnit"
                    (selectionChange)="enableWeightField()" disableOptionCentering="true" required>
          <mat-option *ngFor="let unit of bulkItemUnits" [value]=unit>
            {{ unit | dimensionUnitDisplay }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="bulkItemUnit.hasError('required')">
          {{'modules.disposition.bulkItemAddEdit.requiredField'|translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="65">
        <mat-label>{{'general.units.weight.weight'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemWeight"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
               type="number"
               required>
        <span matSuffix>{{'kg'|dimensionUnitDisplay}}</span>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field fxFlex="35">
        <mat-label>{{'modules.disposition.bulkItemAddEdit.codeType'|translate}}</mat-label>
        <mat-select
                    (selectionChange)="changeCodetype($event)"
                    disableOptionCentering="true" #matSelect>
          <mat-option [value]="'BGL'">{{'general.BGLCode'|translate}}</mat-option>
          <mat-option [value]="'BAL'">{{'modules.disposition.base.BALCode'|translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="65" *ngIf="!hasBAL">
        <mat-label>{{'general.BGLCode'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemBGLCode"
               [matAutocomplete]="BGLCodeAuto">
        <mat-autocomplete #BGLCodeAuto="matAutocomplete">
          <mat-option *ngFor="let BGLCode of bglCodes | async"
                      [value]="BGLCode | slice:0:30">
            {{ BGLCode }}
          </mat-option>
        </mat-autocomplete>
        <fa-icon [icon]="faMoneyCheckEdit" matSuffix></fa-icon>
      </mat-form-field>
      <mat-form-field fxFlex="65" *ngIf="hasBAL">
        <mat-label>{{'modules.disposition.base.BALCode'|translate}}</mat-label>
        <input matInput
               formControlName="bulkItemBALCode"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
        <fa-icon [icon]="faMoneyCheckEdit" matSuffix></fa-icon>
      </mat-form-field>
    </div>
  </form>
</div>
