<div class="dialog-wrapper confirmation-dialog" fxLayout="column">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close="cancel" aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{'modules.disposition.confirmation.title.transfer' | translate}}</h1>
  <div mat-dialog-content fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="row" fxLayoutGap="10px">
      <fa-icon [icon]="faExchange" class="exchange-icon"></fa-icon>
      <div class="message">{{confirmMessage}}</div>
    </div>

    <form [formGroup]="transferForm" fxLayout="column" fxLayoutGap="15px">
      <div class="historicTransferToggleBlock" fxLayout="row" fxLayoutGap="15px" *ngIf="hasModule(modules.HISTORIC_TRANSFERS)">
        <div>{{'modules.disposition.confirmation.message.enableHistoricTransfer' | translate}}</div>

        <mat-slide-toggle color="primary"
                          (change)="switchToHistoricTransfer()">
        </mat-slide-toggle>
      </div>

      <div *ngIf="isHistoricTransfer && lastTransferDateResponse">
        <bh-date-time-picker
          [formGroup] = transferForm
          [datePlaceholder]="'general.date'"
          [timePlaceholder]="'general.time'"
          [dateTimeControl]="historicTransferDateTimeControl"
          [defaultDateTime]="maxHistoricTransferDate"
          [minDateTime]="minHistoricTransferDate"
          [maxDateTime]="maxHistoricTransferDatePlusOneMinute"
        ></bh-date-time-picker>
      </div>

      <div *ngIf="isHistoricTransfer && lastTransferDateResponse" fxLayout="row">
        <div class = "reason" fxLayout="column">
          <div *ngFor="let reason of lastTransferDateResponse.reasons" fxLayout="row">
            <p><mat-icon class="icon-info" matSuffix>info</mat-icon>{{reason | lastTransferDateReason: this.lastTransferDateResponse.lastTransferDate: getCurrentLocale() }}
              <span *ngIf="reason?.transferItemsInfo?.length > 1" [matMenuTriggerFor]="menu" class="more-info">
                {{'modules.disposition.confirmation.lastHistoricTransferDateReason.moreInfo'|translate}}
              </span>
            </p>
            <div fxLayout="column">
                <mat-menu #menu="matMenu" class="full-width" yPosition="below" xPosition="before">
                <mat-list class="transfer-item-list">
                  <mat-list-item
                    *ngFor="let info of reason.transferItemsInfo">
                    <div>
                      <p class="transfer-item-name">{{info.transferItemName}}</p>
                      <p class="transfer-item-number">{{info.transferItemNumber}}</p>
                    </div>
                  </mat-list-item>
                </mat-list>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>

      <div>{{'modules.disposition.confirmation.message.addResponsiblePerson' | translate}}</div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field *ngIf="hasModule(modules.STAFF_MANAGEMENT)" fxFlex="45">
          <mat-select [placeholder]="'general.employee.s'|translate"
                      formControlName="employeeId"
                      disableOptionCentering="true"
                      [value]="transferForm.get('employeeId')">
            <mat-option>
              <ngx-mat-select-search [formControl]="filterControl"
                                     [placeholderLabel]="'general.labels.search'|translate:{ value: 'general.employee.s'|translate }"
                                     [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: 'general.employee.pl'|translate }">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let employee of filteredEmployees | async"
                        [value]="employee.employeeId">
              {{ employee.employeeFirstName}} {{ employee.employeeName }} / {{ employee.organisationName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [fxFlex]="hasModule(modules.STAFF_MANAGEMENT) ? 45 : 90">
          <mat-label>{{'modules.disposition.base.externalEmployee'|translate}}</mat-label>
          <input matInput
                 formControlName="externalEmployee"
                 [maxlength]="fieldLimit.EXTERNAL_EMPLOYEE"
                 [matAutocomplete]="employeeAuto">
          <mat-autocomplete #employeeAuto="matAutocomplete">
            <mat-option *ngFor="let externalEmployee of filteredExternalEmployees |async"
                        [value]="externalEmployee | slice:0:30">
              {{ externalEmployee }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <fa-icon [icon]="faTimes" (click)="clear()" class="clear-icon">
        </fa-icon>
      </div>
      <mat-form-field fxFlex="60">
        <mat-label>{{'general.comment.s' | translate}}</mat-label>
        <textarea matInput
                autocomplete="off"
                formControlName="comment"
                [maxlength]="fieldLimit.COMMENT"
                cdkTextareaAutosize
                cdkAutosizeMinRows="2">
            </textarea>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="actions">
    <button mat-button mat-dialog-close="cancel">{{denyLabel}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="transferForm"
            [disabled]="hasModule(modules.HISTORIC_TRANSFERS) && this.isHistoricTransfer ? transferForm.invalid : false">{{confirmLabel}}</button>
  </div>
</div>
