import { Sort, SortDirection } from '@angular/material/sort';

export function sortToSortString(sort: Sort, addPostfix = true): string {
  if (sort && sort.active && sort.direction) {
    return `${sort.active}${addPostfix ? '.raw' : ''},${sort.direction}`
  }

  return null;
}

export function manualSortString(model: string, direction: SortDirection, addPostfix = true): string {
  const sort: Sort = {
    active: model,
    direction: direction,
  };

  return sortToSortString(sort, addPostfix);
}

