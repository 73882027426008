import { ConnectorInformation } from '../connector-information';
import { ScheduleInformation } from '../schedule-information';
import { TrackUnitClassicConnectionInformation } from './track-unit-classic-connection-information';

export class CreateTrackUnitClassicConnectorCommand {

  connector: ConnectorInformation;
  schedule: ScheduleInformation;
  connection: TrackUnitClassicConnectionInformation;
}
