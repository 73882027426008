import { Directive, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { RoleAuthorityGuardsComponent } from '../../../../shared/navigation-guards/role-authority-guards.component';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/sidenav.service';
import { AppInfoComponent } from '../../app-info/app-info.component';
import { ServiceHealthComponent } from '../../service-health/service-health.component';
import { MessagingService } from '../../../../shared/messaging.service';
import { CustomDeviceDetectorService } from '../../../../shared/services/custom-device-detector.service';
import { CustomersService } from '../../../organisation/shared/customers.service';
import { matomoCategories } from '../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../assets/matomo/matomo-actions.enum';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { MatomoTracker } from 'ngx-matomo';


@Directive()
export class BaseSidenavComponent extends RoleAuthorityGuardsComponent implements OnInit {
  private shopUrl: string = null;
  private payload = new Map<string, string>();

  constructor(protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected router: Router,
              protected sidenavService: SidenavService,
              protected messagingService: MessagingService,
              protected deviceService: CustomDeviceDetectorService,
              protected customersSevice: CustomersService,
              protected matomoTracker: MatomoTracker) {
    super(authService);
  }

  ngOnInit(): void {
    const userCustomerId = this.authService.getUserCustomerId();
    if (this.authService.hasAuthority(Authorities.ONESTOP_PRO_SHOP) && userCustomerId) {
      this.customersSevice.getShopUrl(userCustomerId).subscribe(shopUrl => {
        this.shopUrl = shopUrl;
        if (!!this.shopUrl) {
          this.generateShopLink();
        }
      });
    }
  }

  public getShopUrl(): string {
    return this.shopUrl;
  }

  navigateTo(url: string): void {
    if (this.deviceService.isMobile()) {
      this.sidenavService.close();
    }

    if (!this.router.url.includes(url) ||
     ( url === '/transfer' && this.router.url.includes('transfer-history'))) {
      this.router.navigate([url]);
    }
  }

  public isItemActive(url: string): boolean {

    if (url.split('/').length > 2) {
      url = url.substring(0, url.lastIndexOf('/'));
    }

    let routerUrl = this.router.url;

    if (routerUrl.includes('transfer-log')) {
      routerUrl = routerUrl.substring(0, routerUrl.indexOf('/',routerUrl.indexOf('/') + 1));
    }

    if (url === '/assets' && routerUrl === '/equipments/map') {
      return true;
    }

    if (routerUrl.split('/').length > 3) {
      routerUrl = routerUrl.substring(0, routerUrl.indexOf('/', routerUrl.indexOf('/', routerUrl.indexOf('/') + 1) + 1));
    }

    return routerUrl.includes(url);
  }

  serviceHealth(): void {
    this.dialog.open(ServiceHealthComponent);
  }

  appVersion(): void {
    this.dialog.open(AppInfoComponent);
  }

  public openShopLink(): void {
    this.matomoTracker.trackEvent(matomoCategories.OSP_SHOP, matomoActions.OSP_SHOP_LINK_CLICKED);
    window.open(this.shopUrl);
  }

  private generateShopLink(): void {
    this.payload.set('email', this.authService.getUserEmail());
    this.payload.set('fn', this.authService.getUserFirstName() ? this.authService.getUserFirstName() : this.authService.getUserLastName());
    this.payload.set('ln', this.authService.getUserLastName());

    for (let [key, value] of Array.from(this.payload)) {
      this.shopUrl = this.shopUrl.concat('&' + key + '=' + encodeURIComponent(value));
    }
  }

  logoutUser(event: Event): void {
    event.preventDefault();

    // unregister push token and logout
    this.messagingService.unregister();
  }
}
