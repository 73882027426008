<div fxFill fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <mat-card class="list-view-main-container no-rounded-corners-left"
    [ngClass]="{
      'list-item-hidden': displayService.isDetailsFullscreen(),
      'list-item-margin': displayService.isTableAndDetails()
    }"
    [fxFlex]="displayService.isTableFullscreen() ? 100 : 35">
    <mat-card-content
      class="list-view-main"
      fxFlexFill
      fxLayout="row"
      fxLayoutAlign="start stretch">
      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div class="list-view-main__left-header" fxFlex="60px" fxLayout="row" fxLayoutAlign="end stretch">
          <div *ngIf="displayService.isTableFullscreen()"
            class="change-display-mode"
            fxFlex="60px"
            fxLayout="row"
            fxLayoutAlign="center center"
            [matTooltip]="'general.labels.detailView.expand' | translate"
            (click)="openDetails()">
            <mat-icon>chevron_left</mat-icon>
          </div>
        </div>

        <div class="list-view-main__left-body scrollable-table-container"
          fxFlex fxLayout="row"
          fxLayoutGap="15px"
          fxLayoutAlign="start stretch">
          <div fxFlex class="not-found" *ngIf="!employeeManagementStore.employees">
            {{ "general.labels.noAnyFound" | translate : { value: "general.employee.s" | translate } }}
          </div>
          <mat-table #table
            fxFlex
            class="left-body-list scrollable-table exclude-initial-fixed-basis-size"
            [ngClass]="{ 'hide-header': !displayService.isTableFullscreen() }"
            [dataSource]="employeeManagementStore"
            matSort
            (matSortChange)="sortData($event)"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.columnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">
            <ng-container cdkColumnDef="employeeOverview">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row" fxFlex class="left-body-list__overview-cell">
                <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                  <fa-icon *ngIf="!row.thumbnailImageKey" [icon]="faPortrait"></fa-icon>
                  <bh-thumbnail *ngIf="row.thumbnailImageKey" [key]="row.thumbnailImageKey" [size]="ThumbnailSize.XS">
                  </bh-thumbnail>
                </div>

                <div class="left-body-list__info" fxFlex fxLayout="column">
                  <p class="employee-overview-title">
                    <span class="name">{{ row.employeeName }}, {{ row.employeeFirstName }}</span>
                  </p>
                  <p class="details">
                    <span>{{ row.employeeStaffNumber }}</span>
                  </p>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of shownColumns">
              <ng-container *ngIf="column.type === COLUMN_TYPE.ICON">
                <mat-header-cell *cdkHeaderCellDef class="icon-column" bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="icon-column">
                  <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                    <fa-icon *ngIf="!row.thumbnailImageKey" [icon]="faPortrait"></fa-icon>
                    <bh-thumbnail *ngIf="row.thumbnailImageKey" [size]="ThumbnailSize.XS" [key]="row.thumbnailImageKey">
                    </bh-thumbnail>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell mat-sort-header>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="employee-list-column">
                  <span>{{ column.valueCallback(row) }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  (click)="openDetails()"
                  [routerLink]="[row.employeeId]"
                  [matTooltip]="'general.labels.openDetails' | translate">
                  <mat-icon>info</mat-icon>
                </mat-cell>
              </ng-container>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="columnService.displayedColumns | async"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: columnService.displayedColumns | async"
              [routerLink]="[row.employeeId]"
              routerLinkActive="left-body-item--active"
              (dblclick)="openDetails()">
            </mat-row>
          </mat-table>
        </div>

        <div class="list-view-main__left-body-paginator" fxFlex="60px">
          <mat-paginator #paginator
            fxFlex="grow"
            [length]="employeeManagementStore.length | async"
            [pageIndex]="employeeManagementStore.pagination.index"
            [pageSize]="employeeManagementStore.pagination.size"
            [pageSizeOptions]="[5, 25, 50, 100]"
            bhCurrentItemPaginator
            [data]="employeeManagementStore.employees | async"
            [currentItemId]="(this.employeeManagementStore.currentEmployee | async)?.employeeId"
            [isIdentifierOfItem]="isIdentifierOfEmployee"
            (paginationChanges)="onPaginateChange($event)">
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="list-view-main-container"
    [fxFlex]="displayService.isDetailsFullscreen() ? 100 : 65"
    [ngClass]="{ 'list-item-hidden': displayService.isTableFullscreen() }">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-card-content>
  </mat-card>
</div>
