import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DispositionStock, instanceOfDispositionStock } from '../../../../../shared/disposition-stock';
import { DispositionProject, instanceOfDispositionProject } from '../../../../../shared/disposition-project';
import { MobileTransferItem } from '../../../../../contract/mobile-transfer-item';
import { BulkItemTypeResolver } from '../../../../bulk-item-add-edit/bulk-item-type.resolver';
import { faMapMarkedAlt, faPencil, faTrafficCone, faTrash, faWarehouse } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { MobileTransferAmountInputDialogComponent } from '../mobile-transfer-amount-input-dialog/mobile-transfer-amount-input-dialog.component';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleAuthorityGuardsComponent } from '../../../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EmployeeManagementService } from '../../../../../shared/employee-management.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewEmployeeBasicData } from '../../../../../contract/view-employee-basicData';
import { TransferService } from 'app/modules/disposition/shared/transfer.service';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-transfer-summary',
  templateUrl: './mobile-transfer-summary.component.html',
  styleUrls: ['./mobile-transfer-summary.component.scss']
})
export class MobileTransferSummaryComponent extends RoleAuthorityGuardsComponent implements OnInit {

  @Input() source: DispositionStock | DispositionProject;
  @Input() target: DispositionStock | DispositionProject;
  @Input() bulkItems: MobileTransferItem[] = [];
  @Input() consumed: boolean;
  @Input() additionalInfoForm: UntypedFormGroup;

  @Output() editTarget: EventEmitter<void> = new EventEmitter<void>();
  @Output() entryDeleted: EventEmitter<MobileTransferItem> = new EventEmitter<MobileTransferItem>();
  @Output() entryUpdated: EventEmitter<MobileTransferItem> = new EventEmitter<MobileTransferItem>();

  public readonly faPencil: IconDefinition = faPencil;
  public readonly faTrash: IconDefinition = faTrash;
  public readonly faWareHouse: IconDefinition = faWarehouse;
  public readonly faMapMarkedAlt: IconDefinition = faMapMarkedAlt;
  public readonly faTrafficCone: IconDefinition = faTrafficCone;
  public readonly faTimes: IconDefinition = faTimes;

  public filterControl: UntypedFormControl = new UntypedFormControl();
  public externalEmployees: string[] = [];
  public employees: ViewEmployeeBasicData[] = [];
  public filteredEmployees: BehaviorSubject<ViewEmployeeBasicData[]> = new BehaviorSubject<ViewEmployeeBasicData[]>([]);

  constructor(public bulkItemTypeResolver: BulkItemTypeResolver,
              private dialog: MatDialog,
              private languageService: LanguageService,
              protected authService: KeycloakService,
              private transferService: TransferService,
              private employeeService: EmployeeManagementService) {
    super(authService);
  }

  public ngOnInit() {
    this.subscribeToFormValueChanges();
    this.getEmployees();
    this.getExternalEmployees();
    this.filterControl.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.filterEmployees();
      });
  }

  public get employeeDisabled(): boolean {
    return this.additionalInfoForm ? this.additionalInfoForm.get('employeeId').disabled : false;
  }

  public getName(location: DispositionProject | DispositionStock): string {
    if (instanceOfDispositionProject(location)) {
      return location.projectName;
    } else if (instanceOfDispositionStock(location)) {
      return location.stockName;
    }
  }

  public edit(bulkItem: MobileTransferItem) {
    const dialogRef = this.dialog.open(MobileTransferAmountInputDialogComponent);
    dialogRef.componentInstance.bulkItemAmount = bulkItem;
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        bulkItem.amount = res;
        this.entryUpdated.emit(bulkItem);
      }
    });
  }

  public delete(bulkItem: MobileTransferItem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.deleteTransferItem');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.deleteTransferItem');
    dialogRef.componentInstance.confirmLabel = this.translate('general.buttons.delete');
    dialogRef.componentInstance.denyLabel = this.translate('general.buttons.cancel');

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'yes') {
        this.entryDeleted.emit(bulkItem);
      }
    });
  }

  public goToTargetSelect(): void {
    this.editTarget.emit();
  }

  public clear(): void {
    this.additionalInfoForm.get('externalEmployee').reset();
    this.additionalInfoForm.get('employeeId').reset();
    this.additionalInfoForm.get('externalEmployee').enable();
    this.additionalInfoForm.get('employeeId').enable();
  }

  public clearComment(): void {
    this.additionalInfoForm.get('comment').reset();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private subscribeToFormValueChanges(): void {
    this.additionalInfoForm.get('externalEmployee').valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value !== '') {
          this.additionalInfoForm.get('employeeId').disable();
        } else {
          this.additionalInfoForm.get('employeeId').enable();
        }
      });

    this.additionalInfoForm.get('employeeId').valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value !== '') {
          this.additionalInfoForm.get('externalEmployee').disable();
        } else {
          this.additionalInfoForm.get('externalEmployee').enable();
        }
      });
  }

  private getEmployees(): void {
    if (this.hasModule(this.modules.STAFF_MANAGEMENT)) {
      this.employeeService.getAllEmployeesForTransfer().subscribe(employees => {
        if (employees) {
          this.employees = employees;
          this.filteredEmployees.next(employees);
        }
      });
    }
  }

  private getExternalEmployees(): void {
    this.transferService.getAllExernalEmployees().subscribe(externalEmployees => {
      this.externalEmployees = externalEmployees;
    });
  }

  private filterEmployees(): void {
    if (!this.employees) {
      return;
    }
    let search = this.filterControl.value;
    if (!search) {
      this.filteredEmployees.next(this.employees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEmployees.next(
      this.employees.filter(employee => this.searchMatchesEmployee(search, employee))
    );
  }

  private searchMatchesEmployee(search: string, employee: ViewEmployeeBasicData): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0
        && (employee.employeeFirstName.toLowerCase().indexOf(param) === -1)
        && (employee.employeeName.toLowerCase().indexOf(param) === -1)
        && (employee.organisationName.toLowerCase().indexOf(param) === -1)) {
        return false;
      }
    }
    return true;
  }

}
