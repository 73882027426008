import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';

interface IconParams {
  fontSet: string;
  fontIcon: string;
  faIcon: IconDefinition;
}

@Pipe({
  name: 'iconParamsTransform'
})
export class IconParamsTransformPipe implements PipeTransform {

  public transform(fontSet: string, icon: string | IconDefinition): IconParams {
    return {
      fontSet,
      fontIcon: null,
      faIcon: null,
      ...(
        this.isIconDefinition(icon)
          ? { faIcon: icon }
          : { fontIcon: icon }
      )
    }
  }

  private isIconDefinition(obj: any): obj is IconDefinition {
    return obj?.icon
      && typeof obj?.iconName === 'string'
      && typeof obj?.prefix === 'string';
  }

}
