<div class="attachment-panel">
  <div class="document-div" *ngFor="let document of documents" fxLayout="row" fxLayoutAlign="space-between center"
    fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start">
      <span class="type-div">{{document | fileTypeName | uppercase}}</span>
      <span>{{document.fileName}}</span>
    </div>
    <span *ngIf="document.fileExists">
      <mat-icon class="bh-mat-icon"
                (click)="downloadDocument(document)"
                [matTooltip]="'general.buttons.download'|translate">
        cloud_download
      </mat-icon>
    </span>
  </div>
</div>
