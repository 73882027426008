import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { EquipmentMaintenanceTask } from '../../contract/equipment-maintenance-task';
import { DocumentLink } from '../../../../shared/contract/document-link.interface';

@Injectable()
export class EquipmentMaintenanceTaskService {

  private url = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/tasks';

  constructor(private http: HttpClient) {
  }

  getById(taskId: string): Observable<EquipmentMaintenanceTask> {
    return this.http.get<EquipmentMaintenanceTask>(`${this.url}/${taskId}`);
  }

  getFilesForTask(id: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(`${this.url}/${id}/files`);
  }
}
