<input matInput [formControl]="searchControl"
       fxFlex
       class="search-suggestion-input"
       (blur)="onBlur()"
       (focusin)="populateOptions()"
       (keydown.enter)="save()"
       [matAutocomplete]="searchSuggestions"
       data-osp-test="input-search">
<mat-autocomplete #searchSuggestions="matAutocomplete" class="search-suggestion-autocomplete">
  <mat-option class="search-suggestion" *ngFor="let searchSuggestion of(filteredOptions | async)"
              [value]="searchSuggestion">
    {{ searchSuggestion }}
  </mat-option>
</mat-autocomplete>
