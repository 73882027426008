import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FloatLabelType } from '@angular/material/form-field';
import { BaseControl } from 'app/modules/osp-ui/directives/host-control/base-control.directive';
import { HostControlDirective } from 'app/modules/osp-ui/directives/host-control/host-control.directive';

@Component({
  selector: 'bh-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    HostControlDirective.hostDirectiveProvider()
  ],
})
export class InputNumberComponent extends BaseControl {
  @HostBinding('class')
  protected cssClass = 'osp-input';

  @Input() floatLabel: FloatLabelType = 'auto';

}
