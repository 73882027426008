import { ViewEquipment } from './view-equipment.interface';
import { DatesService } from '../../../shared/services/dates.service';
import { TechnicalFieldValue } from './technical-field-value.interface';
import { OspDateTime } from './osp-date-time';

export class UpdateEquipmentCommand {

  static from(equipment: ViewEquipment): UpdateEquipmentCommand {
    return new UpdateEquipmentCommand(
        equipment.equipmentId,
        equipment.equipmentBeutlhauserId,
        equipment.equipmentSerialNumber,
        equipment.equipmentCustomerSerialNumber,
        equipment.equipmentName,
        equipment.equipmentProductClass,
        equipment.equipmentCostCenter,
        equipment.manufacturerId,
        equipment.equipmentLicensePlate,
        equipment.equipmentGuarantee,
        equipment.equipmentGuaranteeOperatingHoursLimit,
        equipment.equipmentTypeId,
        equipment.equipmentConstructionYear,
        DatesService.ospDateTimeAtStartOfDay(equipment.equipmentDeliveryDate),
        UpdateEquipmentCommand.technicalFields(equipment.technicalFields),
        equipment.equipmentBGLCode,
        equipment.equipmentModel,
        DatesService.ospDateTimeAtStartOfDay(equipment.equipmentRegistrationDate));
  }

  private static technicalFields(technicalFieldValues: TechnicalFieldValue[]): Map<string, string> {
    let technicalFields: Map<string, string> = new Map();

    if (technicalFieldValues && technicalFieldValues.length > 0) {
      technicalFieldValues.forEach(t => {
        technicalFields[t.technicalFieldId] = t.value;
      });
    }

    return technicalFields;
  }

  constructor(public equipmentId: string,
              public equipmentBeutlhauserId: string,
              public equipmentSerialNumber: string,
              public equipmentCustomerSerialNumber: string,
              public equipmentName: string,
              public equipmentProductClass: string,
              public equipmentCostCenter: string,
              public manufacturerId: string,
              public equipmentLicensePlate: string,
              public equipmentGuarantee: string,
              public equipmentGuaranteeOperatingHoursLimit: number,
              public equipmentTypeId: string,
              public equipmentConstructionYear: string,
              public equipmentDeliveryDate: OspDateTime,
              public technicalFields: Map<string, string>,
              public equipmentBGLCode: string,
              public equipmentModel: string,
              public equipmentRegistrationDate: OspDateTime) {
  }
}
