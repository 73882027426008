import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewReportDefinition } from '../../../contract/view-report-definition.interface';
import { ReportsDataSource } from '../../../shared/services/reports.datasource';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ReportJobInterval } from '../../../contract/report-job-interval.enum';
import { UsersService } from '../../../../userrole/shared/users.service';
import { SearchUser } from '../../../../userrole/contract/search-user.interface';
import { GroupByPipe } from 'ngx-pipes';
import { SearchProjectOrProjectGroup } from '../../../../disposition/contract/search-project.interface';
import { ViewOrganisation } from '../../../../organisation/contract/view-organisation.interface';
import { SearchEquipment } from '../../../../equipment/contract/search-equipment.interface';
import { CreateReportJobCommand } from '../../../contract/create-report-job-command';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ViewReportJob } from '../../../contract/view-report-job.interface';
import { UpdateReportJobCommand } from '../../../contract/update-report-job-command';
import { ReportParameter } from '../../../contract/report-parameter.enum';
import { CustomerLabel } from '../../../../../shared/contract/customer-label.interface';
import { debounceTime, distinctUntilChanged, map, skip, take } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { AllSelectedWarningDialogComponent } from '../../all-selected-warning-dialog/all-selected-warning-dialog.component';
import { RouterHistory } from '../../../../../shared/router-history';
import { FieldLimit } from '../../../../../shared/enums/fieldLimit.enum';
import { EquipmentTypeCount } from '../../../contract/equipment-type-count';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { ReportsService } from '../../../shared/services/reports.service';
import { environment } from 'environments/environment';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

interface SearchEquipmentOption extends SearchEquipment {
  labelFieldName: string;
}

interface EquipmentTypeCountGroup {
  [key: string]: EquipmentTypeCount[];
}

@UntilDestroy()
@Component({
  selector: 'bh-add-edit-report-job',
  templateUrl: './add-edit-report-job.component.html',
  styleUrls: ['./add-edit-report-job.component.scss']
})
export class AddEditReportJobComponent extends GuardedNavigableInputComponent implements OnInit {

  public reportJobForm: UntypedFormGroup;
  public reportDefinitions: ViewReportDefinition[];
  public reportJobIntervals: string[];

  public labels: Observable<CustomerLabel[]>;
  public organisations: Observable<ViewOrganisation[]>;

  public readonly equipmentTypeOptions = new BehaviorSubject<EquipmentTypeCountGroup>({});
  public readonly equipmentTypes = new BehaviorSubject<EquipmentTypeCount[]>([]);
  private equipmentTypeSearchTerm = new BehaviorSubject<string>('');

  public readonly equipmentOptions = new BehaviorSubject<SearchEquipmentOption[]>([]);
  private searchEquipments = new BehaviorSubject<SearchEquipment[]>([]);
  private selectedEquipmentMap = new Map<string, SearchEquipment>([]);

  public readonly projectOptions = new BehaviorSubject<SearchProjectOrProjectGroup[]>([]);
  private searchProjects = new BehaviorSubject<SearchProjectOrProjectGroup[]>([]);
  private selectedProjectsMap = new Map<string, SearchProjectOrProjectGroup>([]);

  public isShowAllEquipments = false;
  public isShowAllProjects = false;
  public isShowOrganisations = false;
  public isShowEquipmentTypes = false;
  public isShowEquipments = false;
  public isShowLabels = false;
  public isShowProjects = false;
  public readonly users = this.usersService.getUsers(0, 2000).pipe(take(1), map(res => res?.content ?? []));
  public readonly userFilterFields: (keyof SearchUser)[] = ['firstName', 'name'];
  public readonly userKeyField: keyof SearchUser = 'userId';
  public readonly formControlNameAllEquipments = 'allEquipments';
  public readonly formControlNameAllProjects = 'allProjects';
  public readonly formControlNameProjectIds = 'projectIds';
  public readonly formControlNameEquipmentTypeIds = 'equipmentTypeIds';
  public readonly formControlNameEquipmentLabels = 'equipmentLabels';
  public readonly formControlNameEquipmentIds = 'equipmentIds';
  public readonly formControlNameOrganisationIds = 'organisationIds';
  public readonly formControlNameProjectSearch = 'projectSearch';
  public readonly formControlNameEquipmentSearch = 'equipmentSearch';
  public readonly formControlNameEquipmentTypeSearch = 'equipmentTypeSearch';
  public readonly formControlNameReportJobName = 'reportJobName';
  public readonly formControlNameReportDefinitionId = 'reportDefinitionId';
  public readonly formControlNameReportJobInterval = 'reportJobInterval';
  public readonly formControlNameReportJobRecipientUserIds = 'reportJobRecipientUserIds';
  public readonly formControlNameJobRecipientUserSearch = 'jobRecipientUserSearch';
  public readonly fieldLimit = FieldLimit;
  public readonly faSearch: IconDefinition = faSearch;
  public readonly trackByEquipmentOption = (index: number, equipment: SearchEquipmentOption): string => equipment?.equipmentId;
  public readonly trackByProjectOption = (index: number, project: SearchProjectOrProjectGroup): string => project?.projectId;

  private reportJob: ViewReportJob;
  private reportJobId: string;
  private reportDefinition: ViewReportDefinition;
  private maxTermLength = 400;
  private readonly defaultRequestParams = { page: 0, size: 100 };
  private readonly defaultRequestParamsEquipment = {
    ...this.defaultRequestParams,
    excludedEquipmentStatusCategory: [EquipmentStatusCategory.NOT_AVAILABLE]
  }
  private readonly notLoadedReportOptions = new Set<ReportParameter>(Object.values(ReportParameter));

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected snackBar: MatSnackBar,
              protected routerHistory: RouterHistory,
              private formBuilder: UntypedFormBuilder,
              private reportStore: ReportsDataSource,
              private groupByPipe: GroupByPipe,
              private usersService: UsersService,
              private dialog: MatDialog,
              private reportsService: ReportsService,
              private languageService: LanguageService,
              private translatableStringResolver: TranslatableStringPipe) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.reportJobIntervals = Object.keys(ReportJobInterval);
    this.reportJobId = this.route.snapshot.params['reportJobId'];
    this.buildForm();
    this.getReportDefinitions();
    this.getReportJob();
  }

  public navigateToView(): void {
    this.router.navigate(['/reports/view']);
  }

  public save(): void {
    if (this.isValid()) {
      if (this.reportJob) {
        this.updateReportJob();
      } else {
        this.saveReportJob();
      }
    }
  }

  public isValid(): boolean {
    return this.reportJobForm.valid;
  }

  public selectReportDefinition(reportDefinitionId: string): void {
    if (reportDefinitionId) {
      this.reportDefinition = this.reportDefinitions.find(rd => rd.reportDefinitionId === reportDefinitionId);
      if (this.reportDefinition) {
        [this.formControlNameAllEquipments, this.formControlNameAllProjects].forEach(controlName => {
          this.reportJobForm.get(controlName).setValue(false);
          this.toggleControls(false, controlName);
        });

        this.setAllParameters();
        this.activateSelectionLabels();
        this.activateSelectionOrganisations();
        this.activateSelectionEquipmentTypes();
        this.activateSelectionEquipments();
        this.activateSelectionProjects();
      }
    }
  }

  public toggleAll(event: MatCheckboxChange, formControlName: string): void {
    this.toggleControls(event.checked, formControlName);
    if (event.checked) {
      this.dialog.open(
        AllSelectedWarningDialogComponent,
        { data: this.mapFormControlNameToReportParameterEnum(formControlName), autoFocus: false }
      )
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(warningAcknowledged => {
        if (!warningAcknowledged) {
          event.source.checked = false;
          this.toggleControls(false, formControlName);
          this.reportJobForm.get(formControlName).setValue(false);
        }
      });
    }
  }

  private mapFormControlNameToReportParameterEnum(formControlName: string): string {
    switch (formControlName) {
      case this.formControlNameAllEquipments:
        return ReportParameter.ALL_EQUIPMENTS;
      case this.formControlNameAllProjects:
        return ReportParameter.ALL_PROJECTS;
      default:
        return '';
    }
  }

  private setAllParameters(): void {
    this.isShowAllEquipments = this.hasReportParameter(ReportParameter.ALL_EQUIPMENTS);
    this.isShowAllProjects = this.hasReportParameter(ReportParameter.ALL_PROJECTS);
    this.isShowOrganisations = this.hasReportParameter(ReportParameter.ORGANISATIONS);
    this.isShowEquipmentTypes = this.hasReportParameter(ReportParameter.EQUIPMENT_TYPES);
    this.isShowEquipments = this.hasReportParameter(ReportParameter.EQUIPMENT_ID_LIST);
    this.isShowLabels = this.hasReportParameter(ReportParameter.EQUIPMENT_LABELS);
    this.isShowProjects = this.hasReportParameter(ReportParameter.PROJECT_ID_LIST);
  }

  private hasReportParameter(param: ReportParameter): boolean {
    return (this.reportDefinition?.reportDefinitionParameters ?? []).includes(param);
  }

  private saveReportJob(): void {
    let formData = this.reportJobForm.getRawValue();
    let cmd: CreateReportJobCommand = new CreateReportJobCommand(
      formData[this.formControlNameReportJobName],
      this.getUserUserId(),
      formData[this.formControlNameReportDefinitionId],
      formData[this.formControlNameReportJobRecipientUserIds],
      formData[this.formControlNameReportJobInterval],
      formData[this.formControlNameOrganisationIds],
      formData[this.formControlNameEquipmentTypeIds],
      formData[this.formControlNameEquipmentLabels],
      formData[this.formControlNameEquipmentIds],
      formData[this.formControlNameProjectIds],
      formData[this.formControlNameAllEquipments],
      formData[this.formControlNameAllProjects]
    );

    this.reportStore.addReportJob(<CreateReportJobCommand>this.requestCommandPostProcessing(cmd))
      .subscribe(() => this.navigateToView());
  }

  private updateReportJob(): void {
    let formData = this.reportJobForm.getRawValue();
    let cmd: UpdateReportJobCommand = new UpdateReportJobCommand(
      this.reportJobId,
      formData[this.formControlNameReportJobName],
      formData[this.formControlNameReportJobRecipientUserIds],
      formData[this.formControlNameReportJobInterval],
      formData[this.formControlNameOrganisationIds],
      formData[this.formControlNameEquipmentTypeIds],
      formData[this.formControlNameEquipmentLabels],
      formData[this.formControlNameEquipmentIds],
      formData[this.formControlNameProjectIds],
      formData[this.formControlNameAllEquipments],
      formData[this.formControlNameAllProjects]
    );

    this.reportStore.updateReportJob(<UpdateReportJobCommand>this.requestCommandPostProcessing(cmd))
      .subscribe(() => this.navigateToView());
  }

  private requestCommandPostProcessing(cmd: CreateReportJobCommand | UpdateReportJobCommand):
    CreateReportJobCommand | UpdateReportJobCommand
  {
    return {
      ...cmd,
      organisationIds: (this.isShowOrganisations && !cmd.allEquipments) ? cmd.organisationIds : null,
      equipmentTypeIds: (this.isShowEquipmentTypes && !cmd.allEquipments) ? cmd.equipmentTypeIds : null,
      equipmentLabels: (this.isShowLabels && !cmd.allEquipments) ? cmd.equipmentLabels : null,
      equipmentIds: (this.isShowEquipments && !cmd.allEquipments) ? cmd.equipmentIds : null,
      projectIds: (this.isShowAllProjects && !cmd.allProjects) ? cmd.projectIds : null,
    }
  }

  private getReportDefinitions(): void {
    this.reportStore.reportDefinitions
      .pipe(skip(1), take(1), untilDestroyed(this))
      .subscribe(res => {
        this.reportDefinitions = res;
        this.reportDefinitions.sort((a, b) => a.reportDefinitionName.localeCompare(b.reportDefinitionName));
        this.initialReportDefinitionEditSetup();
      });

    this.reportStore.getReportDefinitions();
  }

  private getReportJob(): void {
    if (this.reportJobId) {
      this.reportStore.reportJob
        .pipe(skip(1), take(1), untilDestroyed(this))
        .subscribe(res => {
          this.reportJob = res;
          this.initialReportDefinitionEditSetup();
        });
      this.reportStore.getReportJob(this.reportJobId);
    }
  }

  private initialReportDefinitionEditSetup(): void {
    if (this.reportJob?.reportDefinitionId
      && this.reportDefinitions.find(rd => rd.reportDefinitionId === this.reportJob.reportDefinitionId)
    ) {
      this.selectReportDefinition(this.reportJob.reportDefinitionId);
      this.setFormValues();
      this.reportJobForm.get(this.formControlNameReportDefinitionId).disable();
      this.toggleControls(this.reportJob.allEquipments, this.formControlNameAllEquipments);
      this.toggleControls(this.reportJob.allProjects, this.formControlNameAllProjects);
    }
  }

  private buildForm(): void {
    this.reportJobForm = this.formBuilder.group({
      [this.formControlNameReportJobName]: ['', [Validators.required]],
      [this.formControlNameReportDefinitionId]: [null, [Validators.required]],
      [this.formControlNameReportJobInterval]: [null, [Validators.required]],
      [this.formControlNameReportJobRecipientUserIds]: [null, [Validators.required]],
      [this.formControlNameEquipmentTypeIds]: [[]],
      [this.formControlNameEquipmentLabels]: [null],
      [this.formControlNameEquipmentIds]: [[]],
      [this.formControlNameOrganisationIds]: [null],
      [this.formControlNameProjectIds]: [[]],
      [this.formControlNameJobRecipientUserSearch]: [''],
      [this.formControlNameEquipmentSearch]: [''],
      [this.formControlNameEquipmentTypeSearch]: [''],
      [this.formControlNameProjectSearch]: [''],
      [this.formControlNameAllEquipments]: false,
      [this.formControlNameAllProjects]: false
    });
  }

  private setFormValues(): void {
    this.reportJobForm.patchValue({
      [this.formControlNameReportJobName]: this.reportJob.reportJobName,
      [this.formControlNameReportDefinitionId]: this.reportJob.reportDefinitionId,
      [this.formControlNameReportJobInterval]: this.reportJob.reportJobInterval,
      [this.formControlNameReportJobRecipientUserIds]: this.reportJob.reportJobRecipientUserIds,
      [this.formControlNameEquipmentTypeIds]: this.reportJob.equipmentTypeIds,
      [this.formControlNameEquipmentLabels]: this.reportJob.equipmentLabels,
      [this.formControlNameEquipmentIds]: this.reportJob.equipmentIds,
      [this.formControlNameOrganisationIds]: this.reportJob.organisationIds,
      [this.formControlNameProjectIds]: this.reportJob.projectIds,
      [this.formControlNameAllEquipments]: this.reportJob.allEquipments,
      [this.formControlNameAllProjects]: this.reportJob.allProjects
    });
  }

  private toggleControls(disableControls: boolean, formControlName: string): void {
    const abstractControls = [];
    if (formControlName === this.formControlNameAllProjects) {
      abstractControls.push(this.formControlNameProjectIds);
    } else if (formControlName === this.formControlNameAllEquipments) {
      abstractControls.push(
        this.formControlNameEquipmentTypeIds,
        this.formControlNameEquipmentLabels,
        this.formControlNameEquipmentIds,
        this.formControlNameOrganisationIds
      );
    }
    abstractControls.forEach((abstractControlName: string) => {
      const abstractControl = this.reportJobForm.get(abstractControlName);
      if (abstractControl != null) {
        disableControls ? abstractControl.disable() : abstractControl.enable();
      }
    });
  }

  private warningLongSearchTerm(): void {
    this.snackBar.open(this.translate('general.snackBarMessages.tooLongCannotSearch'), undefined, { duration: 5000 });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }


  // LABELS
  private activateSelectionLabels(): void {
    if (this.isShowLabels && this.notLoadedReportOptions.has(ReportParameter.EQUIPMENT_LABELS)) {
      this.notLoadedReportOptions.delete(ReportParameter.EQUIPMENT_LABELS);
      this.labels = this.reportStore.getCustomerLabels();
    }
  }


  // ORGS
  private activateSelectionOrganisations(): void {
    if (this.isShowOrganisations && this.notLoadedReportOptions.has(ReportParameter.ORGANISATIONS)) {
      this.notLoadedReportOptions.delete(ReportParameter.ORGANISATIONS);
      this.organisations = this.reportStore.getOrganisations();
    }
  }


  /// TYPES
  private activateSelectionEquipmentTypes(): void {
    if (this.isShowEquipmentTypes && this.notLoadedReportOptions.has(ReportParameter.EQUIPMENT_TYPES)) {
      this.notLoadedReportOptions.delete(ReportParameter.EQUIPMENT_TYPES);
      this.initListenerEquipmentTypesOptions();
      this.initListenerOnChangeSearchEquipmentType();
    }
  }

  private initListenerEquipmentTypesOptions(): void {
    combineLatest([
      this.reportsService.getUserEquipmentTypes(),
      this.equipmentTypeSearchTerm.asObservable()
    ])
    .pipe(
      map(([types, term]) => this.filterEquipmentTypes(
        types, term,
        (<string[]>(this.reportJobForm.get(this.formControlNameEquipmentTypeIds).value) ?? []))
      ),
      map(types => types.sort((a, b) => a.category1.localeCompare(b.category1))))
    .subscribe(types => {
      this.equipmentTypes.next(types);
      this.equipmentTypeOptions.next(this.groupByPipe.transform(types, 'category1'));
    });
  }

  private initListenerOnChangeSearchEquipmentType(): void {
    this.reportJobForm.get(this.formControlNameEquipmentTypeSearch).valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term: string) => this.updateOnEquipmentTypeSearch((term ?? '').trim()));
  }

  private filterEquipmentTypes(types: EquipmentTypeCount[], term: string, selectedIds: string[]): EquipmentTypeCount[] {
    return term.length === 0
      ? types
      : types.filter(({ equipmentTypeId, category2Name }) =>
        (selectedIds ?? []).includes(equipmentTypeId)
        || this.translatableStringResolver.transform(category2Name).toLowerCase().includes(term.toLowerCase()))
  }

  private updateOnEquipmentTypeSearch(term: string): void {
    if ((term ?? '').trim().length > this.maxTermLength) {
      this.warningLongSearchTerm();
      return;
    }
    this.equipmentTypeSearchTerm.next((term ?? '').trim());
  }


  /// EQUIPMENT
  private activateSelectionEquipments(): void {
    if (this.isShowEquipments && this.notLoadedReportOptions.has(ReportParameter.EQUIPMENT_ID_LIST)) {
      this.notLoadedReportOptions.delete(ReportParameter.EQUIPMENT_ID_LIST);
      this.initListenerEquipmentOptions();
      this.initListenerOnChangeEquipment();
      this.initListenerOnChangeSearchEquipment();
      this.initialEquipmentsLoad(this.reportJob?.equipmentIds ?? []);
    }
  }

  private initListenerEquipmentOptions(): void {
    this.searchEquipments
      .pipe(
        map(equipments => equipments.filter(({ equipmentId }) => !this.selectedEquipmentMap.has(equipmentId))),
        map(equipments => Array.from(this.selectedEquipmentMap.values()).concat(equipments)),
        map(equipments => equipments.map(eq => ({ ...eq, labelFieldName: (eq.equipmentName || eq.equipmentModel || '') }))),
        map(equipments => equipments.sort((a, b) => a.labelFieldName.localeCompare(b.labelFieldName))),
      )
      .subscribe(equipments => this.equipmentOptions.next(equipments));
  }

  private initListenerOnChangeEquipment(): void {
    this.reportJobForm.get(this.formControlNameEquipmentIds).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((equipmentIds: string) => {
        const idsSet = new Set(equipmentIds);
        this.keepSelectedEquipments(this.equipmentOptions.getValue().filter(({ equipmentId }) => idsSet.has(equipmentId)));
      });
  }

  private initialEquipmentsLoad(equipmentIds: string[]): void {
    const initialSearchParams = {
      ...this.defaultRequestParamsEquipment,
      ids: equipmentIds ?? [],
    };

    forkJoin([
      initialSearchParams.ids.length > 0 ? this.getEquipments(initialSearchParams) : of([]),
      this.getEquipments(this.defaultRequestParamsEquipment)
    ])
    .subscribe(([ currentSelectedEquipments, equipments ]) => {
      this.keepSelectedEquipments(currentSelectedEquipments);
      this.searchEquipments.next(equipments);
    });
  }

  private initListenerOnChangeSearchEquipment(): void {
    this.reportJobForm.get(this.formControlNameEquipmentSearch).valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term: string) => this.updateOnEquipmentSearch((term ?? '').trim()));
  }

  private keepSelectedEquipments(equipments: SearchEquipment[]): void {
    this.selectedEquipmentMap = new Map<string, SearchEquipment>(equipments.map(equipment => ([ equipment.equipmentId, equipment ])));
  }

  public updateOnEquipmentSearch(term: string): void {
    if (term.length <= this.maxTermLength) {
      this.getEquipments({ ...this.defaultRequestParamsEquipment, term: term })
        .subscribe(result => this.searchEquipments.next(result ?? []));
    } else if (term.length > this.maxTermLength) {
      this.warningLongSearchTerm();
    }
  }

  private getEquipments(params: Object): Observable<SearchEquipment[]> {
    return this.reportsService.searchEquipments(params).pipe(map(({ content }) => content));
  }


  // PROJECTS
  private activateSelectionProjects(): void {
    if (this.isShowProjects && this.notLoadedReportOptions.has(ReportParameter.PROJECT_ID_LIST)) {
      this.notLoadedReportOptions.delete(ReportParameter.PROJECT_ID_LIST);
      this.initListenerProjectOptions();
      this.initListenerOnChangeProject();
      this.initListenerOnChangeSearchProject();
      this.initialProjectsLoad(this.reportJob?.projectIds ?? []);
    }
  }

  private initListenerProjectOptions(): void {
    this.searchProjects
      .pipe(
        map(projects => projects.filter(({ projectId }) => !this.selectedProjectsMap.has(projectId))),
        map(projects => Array.from(this.selectedProjectsMap.values()).concat(projects)),
        map(projects => projects.sort((a, b) => (a.projectName || '').localeCompare(b.projectName || ''))),
      )
      .subscribe(projects => this.projectOptions.next(projects));
  }

  private initListenerOnChangeProject(): void {
    this.reportJobForm.get(this.formControlNameProjectIds).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((projectIds: string) => {
        const idsSet = new Set(projectIds);
        this.keepSelectedProjects(this.projectOptions.getValue().filter(({ projectId }) => idsSet.has(projectId)));
      });
  }

  private initListenerOnChangeSearchProject(): void {
    this.reportJobForm.get(this.formControlNameProjectSearch).valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term: string) => this.updateOnProjectSearch((term ?? '').trim()));
  }

  private initialProjectsLoad(projectIds: string[]): void {
    const initialSearchParams = {
      ...this.defaultRequestParams,
      ids: projectIds ?? []
    };

    forkJoin([
      initialSearchParams.ids.length > 0 ? this.getProjects(initialSearchParams) : of([]),
      this.getProjects(this.defaultRequestParams)
    ])
    .subscribe(([ currentSelectedProjects, projects ]) => {
      this.keepSelectedProjects(currentSelectedProjects);
      this.searchProjects.next(projects);
    })
  }

  private keepSelectedProjects(projects: SearchProjectOrProjectGroup[]): void {
    this.selectedProjectsMap = new Map<string, SearchProjectOrProjectGroup>(projects.map(proj => ([ proj.projectId, proj ])));
  }

  public updateOnProjectSearch(term: string): void {
    if (term.length <= this.maxTermLength) {
      let params = {
        ...this.defaultRequestParams,
        term: term,
      };
      this.getProjects(params).subscribe(result => this.searchProjects.next(result ?? []));
    } else if (term.length > this.maxTermLength) {
      this.warningLongSearchTerm();
    }
  }

  private getProjects(params: Object): Observable<SearchProjectOrProjectGroup[]> {
    return this.reportsService.searchProjects(params).pipe(map(({ content }) => content));
  }

}
