import { LanguageService } from 'app/shared/services/language.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { EquipmentMaintenanceTask } from '../../../../../contract/equipment-maintenance-task';
import { EquipmentMaintenanceTaskService } from '../../../../../shared/services/equipment-maintenance-task.service';
import { FieldType } from '../../../../../../../shared/enums/field-type';
import { flatMap, tap } from 'rxjs/operators';
import { byTypeFieldName } from '../../../../../../../shared/contract/task-completion/maintenance-field-value';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentAttachmentDetailsComponent } from '../../../../../shared/equipment-attachment-details/equipment-attachment-details.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { RouterHistory } from '../../../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentViewCompletedTaskComponent extends EquipmentAttachmentDetailsComponent implements OnInit, OnDestroy {

  public maintenanceTaskId: string;
  public loading = true;
  public task: EquipmentMaintenanceTask;
  public FieldType = FieldType;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentMaintenanceTaskService: EquipmentMaintenanceTaskService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, dialog, routerHistory, languageService, fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }

  getMaintenanceTask(): void {
    this.equipmentMaintenanceTaskService.getById(this.maintenanceTaskId)
    .pipe(untilDestroyed(this))
    .pipe(
        tap((task: EquipmentMaintenanceTask) => {
          this.task = task;
          this.task.maintenanceTaskCompletionInfo.maintenanceFieldValues.sort(byTypeFieldName());
          this.loading = false;
        }),
        flatMap(task => this.equipmentMaintenanceTaskService.getFilesForTask(task.id)),
        tap(documents => this.lcDocumentsDataSource.data = documents)
    )
    .subscribe();
  }

}
