import { UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';

export class GroupFormValidator {
  public static requiredIfOthersNotEmpty(requiredControlName: string, trackedControlNames: string[]): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const requiredControl = group.controls[requiredControlName];
      requiredControl.setValidators(trackedControlNames.some(controlName => !_.isNil(group.controls[controlName].value))
        ? Validators.required
        : null);
      requiredControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      requiredControl.markAsTouched();
      return;
    }
  }
}
