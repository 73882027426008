<button mat-icon-button [matMenuTriggerFor]="appMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #appMenu>
    <button mat-menu-item *ngIf="showDetailsButton" (click)="details.emit(taskAdapted)">
      <mat-icon>open_in_new</mat-icon>
      <span>{{'general.details'|translate}}</span>
    </button>
    <button mat-menu-item *ngIf="taskAdapted | isAllowEditCompletedTask" (click)="edit.emit(taskAdapted)">
      <mat-icon>edit</mat-icon>
      <span>{{'general.buttons.edit'|translate}}</span>
    </button>
    <button mat-menu-item *ngIf="taskAdapted | isAllowDeleteCompletedTask" (click)="delete.emit(taskAdapted)">
      <mat-icon>delete_forever</mat-icon>
      <span>{{'general.buttons.delete'|translate}}</span>
    </button>
</mat-menu>
