import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DigitalMatterProfile } from '../../assign-digital-matter-unit/digital-matter-profile';
import { MatMenu } from '@angular/material/menu';
import {
  DigitalMatterProfileDeploymentState
} from '../../assign-digital-matter-unit/digital-matter-profile-deployment-state';

@Component({
  selector: 'bh-digital-matter-profile-dropdown',
  templateUrl: './digital-matter-profile-dropdown.component.html',
  styleUrls: ['./digital-matter-profile-dropdown.component.scss']
})
export class DigitalMatterProfileDropdownComponent {

  @ViewChild(MatMenu, {static: true}) menu: MatMenu;

  @Input() selectedLanguage: string;
  @Input() status: DigitalMatterProfileDeploymentState;

  @Output() public onProfileChange: EventEmitter<DigitalMatterProfile> = new EventEmitter();

  @Input() allDigitalMatterProfiles: DigitalMatterProfile[];
  @Input() selectedDigitalMatterProfile: DigitalMatterProfile;

  onStatusChanged(profile: DigitalMatterProfile) {
    this.onProfileChange.emit(profile);
  }

  protected isCurrentlyUpdating(): boolean {
    return this.status !== DigitalMatterProfileDeploymentState.DEPLOYMENT_VERIFIED;
  }

  protected readonly DigitalMatterProfileDeploymentState = DigitalMatterProfileDeploymentState;
}
