<ng-container *ngIf="task">
  <ng-container [ngSwitch]="task.category">
    <ng-container *ngSwitchCase="maintenanceCategory.CHECK" [ngTemplateOutlet]="tmplRule"></ng-container>
    <ng-container *ngSwitchCase="maintenanceCategory.CHECK_AND_REPAIR" [ngTemplateOutlet]="tmplRule"></ng-container>
    <ng-container *ngSwitchCase="maintenanceCategory.REMINDER" [ngTemplateOutlet]="tmplReminder"></ng-container>
  </ng-container>
</ng-container>

<ng-template #tmplRule>
  <bh-maintenance-task-view-general-rule [task]="task"></bh-maintenance-task-view-general-rule>
</ng-template>

<ng-template #tmplReminder>
  <bh-maintenance-task-view-general-reminder [task]="task"></bh-maintenance-task-view-general-reminder>
</ng-template>

