import { environment } from 'environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MaintenanceTypeField } from '../shared/contract/maintenance-type-field.interface';
import { MaintenanceTypeService } from '../shared/service/maintenance-type.service';
import { CreateMaintenanceTypeCommand } from '../shared/contract/commands/create-maintenance-type.command';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { MaintenanceTypeFieldStore } from './service/maintenance-type-field.store';
import { MaintenanceType } from '../shared/contract/maintenance-type.interface';
import { UpdateMaintenanceTypeCommand } from '../shared/contract/commands/update-maintenance-type.command';
import { NameInUseValidator } from '../../shared/validators/name-in-use.validator';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-type-add-edit',
  templateUrl: './maintenance-type-add-edit.component.html',
  styleUrls: ['./maintenance-type-add-edit.component.scss'],
  providers: [MaintenanceTypeFieldStore]
})
export class MaintenanceTypeAddEditComponent implements OnInit, OnDestroy {

  maintenanceType: MaintenanceType;
  form: UntypedFormGroup;
  filterControl: AbstractControl;
  availableTypeFields: MaintenanceTypeField[] = [];
  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              private maintenanceTypeFieldStore: MaintenanceTypeFieldStore,
              private typeStore: MaintenanceTypeService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.maintenanceType = this.route.snapshot.data['maintenanceType'];

    this.buildForm();

    if (this.maintenanceType) {
      this.patchForm();
      this.form.updateValueAndValidity();
    }

    this.handleFilterChange();

    this.maintenanceTypeFieldStore.availableMaintenanceTypeFields
      .pipe(untilDestroyed(this))
      .subscribe((fields: MaintenanceTypeField[]) => this.availableTypeFields = fields);
    this.maintenanceTypeFieldStore.loadTypeFields();
    this.handleTypeFieldsChange();
  }

  save(): void {
    if (this.isValid()) {
      const formValue = this.form.getRawValue();

      const typeFieldIds: string[] = formValue.typeFields
        .filter(typeField => typeField.checked)
        .map(typeField => typeField.id);

      if (this.maintenanceType) {
        this.updateMaintenanceType(formValue, typeFieldIds);
      } else {
        this.saveMaintenanceType(formValue, typeFieldIds);
      }
    }
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  resetForm(): void {
    this.form.get('name').reset();
    this.form.get('description').reset();
    this.filterControl.reset();

    this.typeFieldControls.forEach(control => control.patchValue({
      checked: false
    }));
  }

  private updateMaintenanceType(formValue: any, typeFieldIds: string[]): void {
    const command: UpdateMaintenanceTypeCommand = new UpdateMaintenanceTypeCommand(this.maintenanceType.id,
       formValue.name,
       formValue.description,
       typeFieldIds);
    this.typeStore.updateMaintenanceType(command)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe(() => this.navigateToList());
  }

  private saveMaintenanceType(formValue: any, typeFieldIds: string[]): void {

    const command: CreateMaintenanceTypeCommand = new CreateMaintenanceTypeCommand(formValue.name, formValue.description, typeFieldIds);
    this.typeStore.createMaintenanceType(command)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe(() => this.navigateToList());
  }

  private navigateToList(): void {
    this.router.navigate(['maintenance', 'types', 'list']);
  }

  private buildForm(): void {
    const id = this.maintenanceType ? this.maintenanceType.id : '-1';
    this.form = this.formBuilder.group({
      name: [
        '',
        [Validators.required, Validators.maxLength(FieldLimit.MEDIUM_IDENTIFIER), TrimValidator.hasWhitespaces],
        asyncValidatorFactory(value => NameInUseValidator.isValid(id, value, this.typeStore))],
      description: ['', Validators.maxLength(FieldLimit.SHORT_DESCRIPTION)],
      typeFields: this.formBuilder.array([])
    });
  }

  private patchForm(): void {
    this.form.patchValue({
      name: this.maintenanceType.name,
      description: this.maintenanceType.description,
    });

    const typeFields = this.formBuilder.array(this.maintenanceType.typeFields.map(field => this.formBuilder.group({
      id: field.id,
      name: field.name,
      checked: true
    })));

    this.form.setControl('typeFields', typeFields);
  }

  private handleFilterChange(): void {
    this.filterControl = new UntypedFormControl();
    this.filterControl.valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.maintenanceTypeFieldStore.filterTypeFieldsBy(filterTerm));
  }

  private handleTypeFieldsChange(): void {
    this.maintenanceTypeFieldStore.filteredMaintenanceTypeFields
    .subscribe((filteredFields: MaintenanceTypeField[]) => {

      const previousTypeFieldControls: UntypedFormArray = this.form.get('typeFields') as UntypedFormArray;
      const nextTypeFieldControls: UntypedFormArray = this.formBuilder.array(this.availableTypeFields
        .map(field => this.toCheckboxGroup(previousTypeFieldControls, field, filteredFields)));

      this.form.setControl('typeFields', nextTypeFieldControls);
    });
  }

  private toCheckboxGroup(typeFieldControls: UntypedFormArray, field, filteredFields: MaintenanceTypeField[]): UntypedFormGroup {
    const ctrl = typeFieldControls.controls.find(c => c.get('id').value === field.id);
    const checked = ctrl ? ctrl.get('checked').value : false;
    const filtered = filteredFields
    .map(filteredField => filteredField.id)
    .includes(field.id);

    const group: UntypedFormGroup = this.formBuilder.group({
      id: field.id,
      name: field.name,
      checked: checked,
    });

    if (!filtered) {
      group.disable();
    }

    return group;
  }

  get typeFieldControls(): AbstractControl[] {
    const typeFields: UntypedFormArray = this.form.get('typeFields') as UntypedFormArray;

    return typeFields.controls;
  }

  noFieldsSelected(): boolean {
    const fields = this.form.get('typeFields') as UntypedFormArray;
    return fields.controls
      .filter(ctrl => ctrl.get('checked').value).length < 1;
  }

  ngOnDestroy(): void {
  }
}
