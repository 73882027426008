import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RentalDatasource } from '../../../shared/rental.datasource';
import { CustomerLabel } from '../../../../../shared/contract/customer-label.interface';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DateValidator } from '../../../../../shared/custom-validators/date.validator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { EquipmentsService } from '../../../shared/equipments.service';
import * as moment from 'moment';
import _ from 'lodash';
import { isDefined } from 'app/shared/utils';
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-rental-board-search',
  templateUrl: './rental-board-search.component.html',
  styleUrls: ['./rental-board-search.component.scss']
})
export class RentalBoardSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public FILTER_ALL = 'ALL';
  public FILTER_AVAILABLE = 'AVAILABLE';
  public FILTER_UNAVAILABLE = 'UNAVAILABLE';

  public labels: CustomerLabel[] = [];
  public searchForm: UntypedFormGroup;
  public groupedEquipmentTypes: EquipmentTypeFilterView[] = [];

  constructor(private equipmentStore: EquipmentsDataSource,
              private formBuilder: UntypedFormBuilder,
              public rentalStore: RentalDatasource,
              private equipmentService: EquipmentsService,
              private languageService: LanguageService) {
  }

  get startDate(): UntypedFormControl {
    return this.searchForm.get('startDate') as UntypedFormControl;
  }

  get endDate(): UntypedFormControl {
    return this.searchForm.get('endDate') as UntypedFormControl;
  }

  get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  get availabilityFilter(): string {
    return this.searchForm.get('availabilityFilter').value;
  }

  get startDateValue(): Date {
    return moment(this.startDate.value).startOf('day').toDate();
  }

  get endDateValue(): Date {
    let endDate = this.endDate.value;
    return endDate && moment(endDate).endOf('day').toDate();
  }

  get equipmentTypesFilters(): string[] {
    return this.searchForm.get('selectedEquipmentTypes').value;
  }

  get labelsSearch(): string[] {
    return this.searchForm.get('selectedLabels').value;
  }

  public ngOnInit(): void {
    this.buildSearchForm();
    this.applySearchFilters();

    this.setHasSearchFilters();
    this.getLabels();
    this.getEquipmentTypes();
  }

  public ngOnDestroy(): void {
    // for @TakeUntilDestroy
  }

  public ngAfterViewInit(): void {
    this.onSearchFormType();
  }

  public displayDateFields(): boolean {
    return this.availabilityFilter === this.FILTER_AVAILABLE || this.availabilityFilter === this.FILTER_UNAVAILABLE;
  }

  public onSearchFormSubmit(): void {
    this.rentalStore.searchTerms = this.termsValue;
    this.equipmentStore.searchTerms = this.termsValue;

    this.rentalStore.searchEquipmentTypeIds = this.equipmentTypesFilters;

    this.rentalStore.searchLabels = this.labelsSearch;

    if (this.availabilityFilter === this.FILTER_ALL) {
      this.rentalStore.searchAvailableFilter = null;
    }
    if (this.availabilityFilter === this.FILTER_AVAILABLE) {
      this.rentalStore.searchAvailableFilter = true;
      this.rentalStore.searchAvailableFilterStart = this.startDateValue;
      this.rentalStore.searchAvailableFilterEnd = this.endDateValue;
    }
    if (this.availabilityFilter === this.FILTER_UNAVAILABLE) {
      this.rentalStore.searchAvailableFilter = false;
      this.rentalStore.searchAvailableFilterStart = this.startDateValue;
      this.rentalStore.searchAvailableFilterEnd = this.endDateValue;
    }

    this.setHasSearchFilters();

    if (this.searchForm.valid) {
      this.rentalStore.updateListing(0, this.rentalStore.pagination.size);
    }
  }

  public reset(): void {
    this.searchForm.get('terms').reset();
  }

  public clearDateFilter(): void {
    this.searchForm.patchValue({
      startDate: new Date(),
      endDate: ''
    });
  }

  public onPaginateChange(event: PageEvent): void {
    if (this.searchForm.valid) {
      this.rentalStore.updateListing(event.pageIndex, event.pageSize);
    }
  }

  private onSearchFormType(): void {
    this.searchForm.valueChanges
    .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(_.isEqual),
        untilDestroyed(this)
    )
    .subscribe(() => this.onSearchFormSubmit());
  }

  private buildSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      terms: '',
      selectedEquipmentTypes: '',
      selectedLabels: '',
      startDate: [new Date(), [<any>Validators.required, DateValidator.isValidDate(this.languageService)]],
      endDate: '',
      availabilityFilter: ''
    });
  }

  private getLabels(): void {
    this.equipmentStore.getCustomerLabels()
    .pipe(untilDestroyed(this))
    .subscribe((customerLabels: CustomerLabel[]) => this.labels = customerLabels);
  }

  private getEquipmentTypes(): void {
    this.equipmentService.getGroupedEquipmentTypeCounts()
    .pipe(untilDestroyed(this))
    .subscribe((equipmentTypes: EquipmentTypeFilterView[]) => this.groupedEquipmentTypes = equipmentTypes);
  }

  private setHasSearchFilters(): void {
    this.rentalStore.hasSearchFilters = !!this.termsValue
        || !!(this.equipmentTypesFilters && this.equipmentTypesFilters.length)
        || !!(this.labelsSearch && this.labelsSearch.length)
        || !!this.availabilityFilter;
  }

  private applySearchFilters(): void {
    const {
      searchTerms,
      searchEquipmentTypeIds,
      searchLabels,
      searchAvailableFilter,
      searchAvailableFilterStart,
      searchAvailableFilterEnd
    } = this.rentalStore;

    const searchFormValues = {
      terms: searchTerms,
      selectedEquipmentTypes: searchEquipmentTypeIds,
      selectedLabels: searchLabels,
      startDate: searchAvailableFilterStart || new Date(),
      endDate: searchAvailableFilterEnd,
      availabilityFilter: this.FILTER_ALL
    };
    if (isDefined(searchAvailableFilter)) {
      searchFormValues.availabilityFilter = searchAvailableFilter ? this.FILTER_AVAILABLE : this.FILTER_UNAVAILABLE;
    }

    this.searchForm.patchValue(searchFormValues);
    this.rentalStore.updateListing();
  }
}
