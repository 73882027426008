import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { LanguageService } from '../../../../shared/services/language.service';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { EquipmentStatusCategoryPipe } from '../../../../shared/pipes/equipment-status-category.pipe';
import { AbstractEquipmentStatusStateChangeComponent } from '../abstract-equipment-status-state-change.component';

@Component({
  selector: 'bh-equipment-status-state-change-icon',
  templateUrl: './equipment-status-state-change-icon.component.html',
  styleUrls: ['./equipment-status-state-change-icon.component.scss']
})
export class EquipmentStatusStateChangeIconComponent extends AbstractEquipmentStatusStateChangeComponent {

  @Input() showTooltip = true;
  @Input() editable = true;
  @Input() disabled = false;
  @Input() equipment: ViewEquipment;

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger

  constructor(dialog: MatDialog,
              langService: LanguageService,
              equipmentStore: EquipmentsDataSource,
              equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe) {
    super(dialog, langService, equipmentStore, equipmentStatusCategoryPipe);
  }

  public onClick(): void {
    if (this.editable) {
      this.menuTrigger.openMenu()
    }
  }

  public onIconClick(event: MouseEvent): void {
    event.stopPropagation();
    this.onClick();
  }
}
