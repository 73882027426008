<div class="m-view-list-inputs-overflow comments">
  <button class="m-view-comment__button" mat-button (click)="addEditComment(null)"
    *ngIf="updateComments" [disabled]="isProjectFinished()">
    {{'general.buttons.addComment'|translate|uppercase}}
  </button>

  <div class="m-view-list-inputs">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
      <mat-card class="comment-card" *ngFor="let comment of projectStore.comments | async">
        <mat-card-content class="comment-card__content">
          <div class="comment-card__content-main" fxLayout="row" fxLayoutAlign="start stretch"
               fxLayoutGap="10px">
            <div class="comment-card__properties" fxFlex="70" fxLayoutAlign="start none">
              <span>{{comment.text}}</span>
            </div>

            <div *ngIf="allowEditingDeleting(comment.userId) && updateComments && !isProjectFinished()">
              <button mat-icon-button (click)="addEditComment(comment)"
                      [matTooltip]="'general.buttons.change'|translate">
                <mat-icon class="bh-mat-icon">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteComment(comment)"
                      [matTooltip]="'general.buttons.delete'|translate">
                <mat-icon class="bh-mat-icon">delete_forever</mat-icon>
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end none">
            <span class="comment-card__submitted-by">
              {{'general.comment.view.addedBy'|translate}}: {{comment.submittedBy}}
              {{'general.at'|translate}}: {{comment.submitDate | date:'EEEE, d. MMM y hh:mm a':'':getCurrentLocale()}}
              <span *ngIf="comment.lastEditDate">, {{'general.comment.view.lastEditedAt'|translate}}:
                {{comment.lastEditDate | date:'EEEE, d. MMM y hh:mm a':'':getCurrentLocale()}}</span>
            </span>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
