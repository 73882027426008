import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterPipe',
    pure: false
})
export class FilterDisplayPipe implements PipeTransform {
    transform(items: any[]): any {
       return items.filter(item => item.display !== false);
    }
}
