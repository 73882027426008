import { LanguageService } from '../services/language.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class TrimValidator {
  public static isValueStartWithSpaces(languageService: LanguageService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return control.value.startsWith(' ') ? {trim: languageService.getInstant('shared.validation.noSpacesAtStart')} : null;
      } else {
        return null;
      }
    };
  }

  public static isEmpty (control: AbstractControl): ValidationErrors {
    return !Boolean((control?.value || '').trim()) ? { empty: true } : null;
  }

  public static hasWhitespaces(control: AbstractControl): ValidationErrors {
    return control.value && !(/^\S$|^\S[ \S]*\S$/).test(control.value) ? { hasWhitespaces: true } : null;
  }
}
