<bh-title [text]="'modules.equipment.base.costs'|translate"></bh-title>

<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <div class="equipment-view-cost__calendar" fxFlex="20">
    <mat-card class="cost-control-panel" *ngIf="hasAuthority(authorities.EQUIPMENT_MANAGE_COSTS)">
      <div fxFlexFill fxFlexLayout="row" fxLayoutAlign="end center">
        <span [matTooltip]="'modules.equipment.cost.newCostInactiveEquipment'|translate"
              [matTooltipDisabled]="isActiveEquipment()">
          <button mat-icon-button
                  fxFlex="nogrow"
                  [matTooltip]="'modules.equipment.cost.logCost'|translate"
                  (click)="equipmentAddInvoice()"
                  [disabled]="!isActiveEquipment()">
          <mat-icon>add</mat-icon>
        </button>
        </span>
      </div>
    </mat-card>
    <mat-accordion cdk-accordion displayMode="flat" *ngIf="yearMonthsNavigation">
      <mat-expansion-panel [@.disabled]="!viewChecked"
                           *ngFor="let yearMonthsGroup of yearMonthsNavigation">
        <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px"
                                    class="equipment-view-cost__calendar-year">
          {{yearMonthsGroup.year}}
        </mat-expansion-panel-header>

        <div class="equipment-view-cost__calendar-month"
             *ngFor="let month of yearMonthsGroup.months"
             (click)="jumpTo(yearMonthsGroup.year, month)">
          {{month | monthName}}
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div fxFlex="80" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="equipment-view-cost__content">
      <mat-card>
        <mat-card-content>
          <div class="content__header" fxLayout="row" fxLayoutAlign="space-between center"
               fxLayoutGap="10px">
            <div fxFlex>
              <span>{{'modules.equipment.cost.costStatistics'|translate|uppercase}}</span>
            </div>
          </div>

          <div class="equipment-view-cost__overview">

            <!-- the following 7 entries are always displayed -->
            <div class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.total | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="'modules.equipment.cost.totalAllAmounts'|translate">
                {{'modules.equipment.cost.totalCost'|translate}}
              </div>
            </div>
            <div class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.maintenance | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum(('general.maintenance.s'|translate) + ' '
                     + ('general.or'|translate) + ' '
                     + ('modules.equipment.performanceType.wear'|translate))">
                {{'general.maintenance.s'|translate}}
              </div>
            </div>
            <div class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.repairs | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.repair.s'|translate)">
                {{'modules.equipment.performanceType.repair.pl'|translate}}
              </div>
            </div>
            <div class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.damages | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('general.maliciousDamage.s'|translate)">
                {{'general.maliciousDamage.pl'|translate}}
              </div>
            </div>
            <div class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.purchase | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.purchase'|translate)">
                {{'modules.equipment.performanceType.purchase'|translate}}
              </div>
            </div>
            <div class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.lifeCycle | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field" [matTooltip]="tooltipSumLifeCycle()">
                {{'modules.equipment.cost.lifeCycle'|translate}}
              </div>
            </div>
            <div class="overview-item">
              <div *ngIf="costOverview?.costsPerOperatingHour !== null"
                   class="overview-item__text">
                {{costOverview?.costsPerOperatingHour | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div *ngIf="costOverview?.costsPerOperatingHour === null"
                   class="overview-item__text">
                {{'general.noData'|translate}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSumPerOperatingHour()">
                {{'modules.equipment.cost.costPerOperatingHour'|translate}}
              </div>
            </div>

            <!-- the rest of the entries are only displayed, when they have a value -->
            <div *ngIf="showCostOverviewField('leasingLast')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.leasingLast | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipLast('modules.equipment.performanceType.leasing'|translate)">
                {{'modules.equipment.cost.rateLeasing'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('financingLast')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.financingLast | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipLast('modules.equipment.performanceType.financing'|translate)">
                {{'modules.equipment.cost.rateFinancing'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('rentalLast')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.rentalLast | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipLast('modules.equipment.performanceType.rentalFee'|translate)">
                {{'modules.equipment.cost.rateRentalFee'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('fullServiceLast')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.fullServiceLast | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipLast('modules.equipment.performanceType.fullService'|translate)">
                {{'modules.equipment.cost.rateFullService'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('machineInsuranceLast')"
                 class="overview-item">
              <div
                  class="overview-item__text">
                {{costOverview?.machineInsuranceLast | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipLast('modules.equipment.performanceType.machineBreakdownInsurance.s'|translate)">
                {{'modules.equipment.cost.rateMBI'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('energyLast')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.energyLast | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipLast('modules.equipment.performanceType.energyCosts'|translate)">
                {{'modules.equipment.cost.lastEnergyCosts'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('leasingTotal')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.leasingTotal | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.leasing'|translate)">
                {{'modules.equipment.performanceType.leasing'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('financingTotal')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.financingTotal | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.financing'|translate)">
                {{'modules.equipment.performanceType.financing'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('rentalTotal')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.rentalTotal | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.rentalFee'|translate)">
                {{'modules.equipment.performanceType.rentalFee'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('fullServiceTotal')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.fullServiceTotal | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.fullService'|translate)">
                {{'modules.equipment.performanceType.fullService'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('machineInsuranceTotal')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.machineInsuranceTotal | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.machineBreakdownInsurance.s'|translate)">
                {{'modules.equipment.performanceType.machineBreakdownInsurance.abbr'|translate}}
              </div>
            </div>
            <div *ngIf="showCostOverviewField('energyTotal')"
                 class="overview-item">
              <div class="overview-item__text">
                {{costOverview?.energyTotal | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}
              </div>
              <div class="overview-item__field"
                   [matTooltip]="tooltipSum('modules.equipment.performanceType.energyCosts'|translate)">
                {{'modules.equipment.cost.totalEnergyCosts'|translate}}
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="equipment-view-cost__content">
      <mat-card>
        <mat-card-content>
          <div class="content__header" fxLayout="row" fxLayoutAlign="space-between center"
               fxLayoutGap="10px">
            <div fxFlex>
              <span>{{'modules.equipment.cost.costChronicles'|translate|uppercase}}</span>
            </div>

            <div fxFlex="60" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
              <select #pt [attr.placeholder]="'modules.equipment.base.performanceType'|translate" (change)="selectPerformanceType(pt.value)">
                <option value="">-- {{'modules.equipment.cost.showAllEntries'|translate}}</option>
                <option *ngFor="let pt of performanceTypes" [value]="pt">
                  {{pt|performanceType}}
                </option>
              </select>
            </div>
          </div>

          <div class="content__body"
               fxLayout="row"
               fxLayoutAlign="center center" *ngIf="(equipmentStore.costs | async)?.length === 0">
            <div fxLayout="row" fxLayoutAlign="center center">
              {{'modules.equipment.cost.noDataAvailable'|translate}}
            </div>
          </div>

          <div class="content__body" *ngFor="let costEntry of equipmentStore.costs | async"
               id="{{costEntry.year}}-{{costEntry.month}}">
            <div class="content__body-total" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="text" fxFlex>
                <span>{{costEntry.totalMonth | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}</span>
              </div>
              <div class="icon" fxFlex fxLayout="row" fxLayoutAlign="end center">
                {{costEntry.month | monthName}} {{costEntry.year}}
              </div>
            </div>

            <div class="content__body-invoice"
                 fxLayout="row"
                 fxLayoutAlign="space-between center" *ngFor="let invoice of costEntry.invoices">
              <div class="content__body-invoice-data" fxFlex fxLayout="row"
                   fxLayoutAlign="start center">
                <mat-icon>{{getInvoiceTypeIcon(invoice.performanceType)}}</mat-icon>
                <div class="invoice-amount">
                  <span>{{invoice.invoiceAmount | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}</span>
                </div>
                <div class="invoice-type">
                  <span>{{invoice.performanceType|performanceType}}</span>
                </div>
              </div>
              <div class="content__body-invoice-date" fxFlex fxLayout="row"
                   fxLayoutAlign="end center">
                <span>{{invoice.sortDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</span>
              </div>
              <button mat-icon-button
                      (click)="displayInvoiceDetails(invoice, invoice.imported)"
                      *ngIf="!hasAuthority(authorities.EQUIPMENT_MANAGE_COSTS)"
                      [matTooltip]="'modules.equipment.cost.costDetails'|translate">
                <mat-icon>visibility</mat-icon>
              </button>
              <button mat-icon-button [matMenuTriggerFor]="menu"
                      tabindex="-1"
                      *ngIf="hasAuthority(authorities.EQUIPMENT_MANAGE_COSTS)">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                        (click)="displayInvoiceDetails(invoice, invoice.imported)">
                  <mat-icon>visibility</mat-icon>
                  <span>{{'general.details'|translate}}</span>
                </button>
                <span
                    [matTooltip]="'modules.equipment.cost.invoicesOnInactiveEquipment'|translate"
                    [matTooltipDisabled]="isActiveEquipment()">
                  <button mat-menu-item (click)="editInvoice(invoice.invoiceId)"
                          *ngIf="!invoice.imported"
                          [disabled]="!isActiveEquipment()">
                    <mat-icon>edit</mat-icon>
                    <span>{{'modules.equipment.cost.editInvoice'|translate}}</span>
                  </button>
                  <div
                    [matTooltip]="'modules.equipment.cost.invoiceAssignedToTimeline'|translate"
                    [matTooltipDisabled]="invoiceDeletable(invoice.invoiceId)">
                    <button mat-menu-item (click)="deleteInvoice(invoice.invoiceId)"
                            *ngIf="!invoice.imported"
                            [disabled]="!(invoiceDeletable(invoice.invoiceId) && isActiveEquipment())">
                      <mat-icon>
                        delete_forever
                      </mat-icon>
                      <span>{{'modules.equipment.cost.deleteInvoice'|translate}}</span>
                    </button>
                  </div>
                </span>
              </mat-menu>

            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
