<bh-title [text]="'general.map.s'|translate"></bh-title>

<div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
  <ng-container *ngIf="hasModule(modules.LIVE_DATA); else simpleMap">
    <form [formGroup]="telematicsLocationsForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <bh-date-range-select
        [startDateControl]="startDateControl"
        [endDateControl]="endDateControl"
        [allowFutureDates]="false"
        [startRequired]="true"
        [endRequired]="true"
        [maxInterval]="maxInterval"
        (onDateChange)="infoText=''">
      </bh-date-range-select>

      <button mat-raised-button
        class="equipment-view-card__button"
        (click)="onSubmit()"
        [disabled]="!hasAuthority(authorities.EQUIPMENT_VIEW_TELEMATICS)
        || !telematicsLocationsForm.valid || (isLoading() | async)">
        {{'modules.equipment.map.displayData'|translate}}
      </button>

      <mat-spinner [strokeWidth]="3" [diameter]="20" *ngIf="(isLoading() | async) && !showMap"></mat-spinner>
    </form>

    <ng-container *ngIf="showMap">
      <bh-map-locations fxFlex
        *ngIf="(telematicsLocations | mapMarkersConvertTelematicLocation : equipment) as telLocations"
        [locations]="telLocations | concatArrays : (telematicsEquipmentStatus | mapMarkersConvertTelematicStatus : equipment)"
        [movements]="telLocations"
        [singleInfoWindow]="true"
        data-osp-test="movement-map">
      </bh-map-locations>
    </ng-container>

    <div *ngIf="!showMap">{{infoText}}</div>
  </ng-container>
</div>


<ng-template #simpleMap>
  <bh-map-locations *ngIf="equipment"
    fxFlex
    [locations]="[equipment]"
    data-osp-test="simple-map">
  </bh-map-locations>
</ng-template>
