import { AssetDocumentType } from './document-type.enum';

export class UpdateEquipmentDocumentNameCommand {
  public constructor(
    public equipmentId: string,
    public documentKey: string,
    public documentName: string,
    public documentType: AssetDocumentType) {
  }
}
