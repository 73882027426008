import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { SearchStockEquipmentAmount } from '../contract/search-stock-equipment-amount.interface';
import { TransferCartItem } from '../contract/transfer-cart-item';
import { ViewAmount } from '../contract/view-amount';
import { DimensionUnitConverterPipe } from '../../../shared/pipes/dimension-unit-converter.pipe';


@Injectable()
export class TransferCartDatasource extends DataSource<TransferCartItem> {

  public _data: BehaviorSubject<Map<string, TransferCartItem>> = new BehaviorSubject(new Map());
  public _openedAmounts: Map<string, number> = new Map();


  public readonly transferCart: Observable<TransferCartItem[]> = this._data.asObservable().pipe(map(transferCartMap =>
     Array.from(transferCartMap.values())));
  public readonly transferCartLength: Observable<number> = this.transferCart.pipe(map(cart => cart.length));

  constructor(private dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
    super();
  }

  connect(): Observable<TransferCartItem[]> {
    return this.transferCart;
  }

  public disconnect(): void {
  }

  public transferCartEmpty(): boolean {
    return this._data.value.size === 0;
  }

  public isAmountOpened(amountId: string): boolean {
    return this._openedAmounts.has(amountId);
  }


  public isAmountInTransferCart(amountId: string): boolean {
    return this._data.value.has(amountId);
  }

  public isEquipmentAmountSubequipmentsInTransferCart(amount: ViewAmount): boolean {
    let result = false;
    if (!this.isAmountInTransferCart(amount.amountId) && amount.subEquipmentList) {
      amount.subEquipmentList.forEach(subEquipment => {
        if (this.isAmountInTransferCart(subEquipment.amountId)) {
          result = true;
        }
      });
    }
    return result;
  }

  public switchAmountOpened(amountId: string): void {
    if (this.isAmountInTransferCart(amountId)) {
      this._data.value.delete(amountId);
      this._data.next(_.cloneDeep(this._data.value));
    } else {
      this._openedAmounts.set(amountId, 1);
    }
  }

  public switchEquipmentAmountOpened(amount: SearchStockEquipmentAmount): void {
    if (this.isAmountInTransferCart(amount.amountId)) {
      this._data.value.delete(amount.amountId);
      if (amount.subEquipmentList && amount.subEquipmentList.length > 0) {
        amount.subEquipmentList.forEach((subEquipmentAmount: SearchStockEquipmentAmount) => {
          if (this.isAmountInTransferCart(subEquipmentAmount.amountId)) {
            this._data.value.delete(subEquipmentAmount.amountId);
          }
        });
      }
      this._data.next(_.cloneDeep(this._data.value));
    } else {
      this.addAmount(ViewAmount.fromSearchStockEquipmentAmount(amount), 1);
      if (amount.subEquipmentList && amount.subEquipmentList.length > 0) {
        amount.subEquipmentList.forEach((subEquipmentAmount: SearchStockEquipmentAmount) => {
          if (!this.isAmountInTransferCart(subEquipmentAmount.amountId) && subEquipmentAmount.isAvailableForCurrentUser) {
            this.addAmount(ViewAmount.fromSearchStockEquipmentAmount(subEquipmentAmount), 1);
          }
        });
      }
    }
  }

  private addAmount (amount: ViewAmount, transferAmount: number): void {
    this._data.value.set(amount.amountId, {
      transferAmount: transferAmount,
      amount: amount
    });
    this._data.next(_.cloneDeep(this._data.value));
  }

  public isValidTransferAmount(amount: ViewAmount): boolean {
    let {amountAvailable, bulkItemUnit} = amount;
    let available = this.dimensionUnitConverterPipe.toUserDimensionUnit(amountAvailable, bulkItemUnit, 3);
    return this.hasValidTransferAmount(amount.amountId, available);
  }

  private hasValidTransferAmount(amountId: string, maxAmount: number): boolean {
    return this._openedAmounts.get(amountId) > 0
      && this._openedAmounts.get(amountId) <= maxAmount
      && this.checkDecimalDigitsNumber(amountId);
  }

  public checkDecimalDigitsNumber(amountId: string): boolean {
    const regex = new RegExp('^[0-9]*(?:\.[0-9]{1,3})?$');
    return regex.test(this._openedAmounts.get(amountId).toString());
  }

  public getTransferAmount(amountId: string): string {
    return this._openedAmounts.has(amountId) ? this._openedAmounts.get(amountId).toString() : '1';
  }

  public updateTransferAmount(amountId: string, amount: number): void {
    this._openedAmounts.set(amountId, Number(amount));
  }

  public closeAmount(amountId: string): void {
    this._openedAmounts.delete(amountId);
  }

  public putAmountIntoTransferCart(amount: ViewAmount): void {
    if (this.isValidTransferAmount(amount)) {
      let value = this._openedAmounts.get(amount.amountId);
      this.addAmount(amount, this.dimensionUnitConverterPipe.toSystemDimensionUnit(value, amount.bulkItemUnit));
      this._openedAmounts.delete(amount.amountId);
    }
  }

  public isTransferCartItemValid(amountId: string): boolean {
    if (!this._data.value.has(amountId)) {
      return false;
    }
    const transferCartItem: TransferCartItem = this._data.value.get(amountId);
    return transferCartItem.transferAmount > 0 &&
        transferCartItem.transferAmount <= transferCartItem.amount.amountAvailable;
  }

  public isTransferCartValid(): boolean {
    let transferCartValid = this._data.value.size > 0;
    this._data.value.forEach(transferCartItem => {
      if (!this.isTransferCartItemValid(transferCartItem.amount.amountId)) {
        transferCartValid = false;
      }
    });
    return transferCartValid;
  }

  public updateTransferItem(amountId: string, amount: number): void {
    const transfers = _.clone(this._data.value);
    const item = transfers.get(amountId);
    item.transferAmount = amount;
    this._data.next(transfers);
  }

  public removeTransferCartItem(amountId: string): void {
    this._data.value.delete(amountId);
    this._data.next(_.cloneDeep(this._data.value));
  }

  public removeAllTransferCartItems(): void {
    this._data.next(new Map());
    this._openedAmounts.clear();
  }

  public removeStockToProjectTransferCartItems(): void {
    [...this._data.value.values()].forEach(item => {
      if (!item.amount.currentProjectId) {
        this._data.value.delete(item.amount.amountId);
        this._openedAmounts.delete(item.amount.amountId);
      }
    });
    this._data.next(_.cloneDeep(this._data.value));
  }

  public removeProjectToProjectTransferCartItems(): void {
    [...this._data.value.values()].forEach(item => {
      if (item.amount.currentProjectId) {
        this._data.value.delete(item.amount.amountId);
        this._openedAmounts.delete(item.amount.amountId);
      }
    });
    this._data.next(_.cloneDeep(this._data.value));
  }
}
