import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {TelematicsService} from './telematics.service';
import {ViewDigitalMatterUnit} from './view-digital-matter.unit';
import {TelematicLiveDataDatasource} from './telematic-datasource.interface';
import {TelematicsLoadingState} from './telematics-loading-state.enum';
import {DigitalMatterProfile} from '../../../shared/assign-digital-matter-unit/digital-matter-profile';

@Injectable()
export class DigitalMatterDatasource implements TelematicLiveDataDatasource<ViewDigitalMatterUnit> {

  private _digitalMatterData: BehaviorSubject<ViewDigitalMatterUnit> = new BehaviorSubject(null);
  private _digitalMatterDataLoading: BehaviorSubject<TelematicsLoadingState> = new BehaviorSubject(TelematicsLoadingState.READY);
  private _digitalMatterProfiles: BehaviorSubject<DigitalMatterProfile[]> = new BehaviorSubject(null);

  public readonly digitalMatterData: Observable<ViewDigitalMatterUnit> = this._digitalMatterData.asObservable();
  public readonly digitalMatterDataLoading: Observable<TelematicsLoadingState> = this._digitalMatterDataLoading.asObservable();
  public readonly digitalMatterProfiles: Observable<DigitalMatterProfile[]> = this._digitalMatterProfiles.asObservable();

  private updateSubs: Subscription;
  private currentImei: string;

  constructor(private telematicsService: TelematicsService) {
  }

  fetchLiveData(imei: string): void {
    this.fetchDigitalMatterLiveData(imei);
  }

  startAutoUpdate(intervalMs: number, updateInitially: boolean = true): void {
    this.stopAutoUpdate();
    this.updateSubs = timer(updateInitially ? 0 : intervalMs, intervalMs)
      .subscribe(() => {
        if (this.currentImei) {
          this.fetchDigitalMatterLiveData(this.currentImei);
        }
      });
  }

  stopAutoUpdate(): void {
    this.updateSubs?.unsubscribe();
  }

  getLiveData(): Observable<ViewDigitalMatterUnit> {
    return this.digitalMatterData;
  }

  getLoadingState(): Observable<TelematicsLoadingState> {
    return this.digitalMatterDataLoading;
  }

  public setCurrentImei(imei: string): void {
    this.currentImei = imei;
  }

  public fetchDigitalMatterLiveData(imei: string) {
    // Currently only working with one DM tracker at a time because loading state would be overwritten
    this._digitalMatterDataLoading.next(TelematicsLoadingState.LOADING);

    this.telematicsService.getDigitalMatterData(imei).subscribe(
      res => {
        this._digitalMatterData.next(res);
        this._digitalMatterDataLoading.next(TelematicsLoadingState.READY);
      },
      () => {
        this._digitalMatterDataLoading.next(TelematicsLoadingState.ERROR);
      });
  }

  public fetchAllDigitalMatterProfiles() {
    this.telematicsService.getAllDigitalMatterProfiles()
      .subscribe({
        next: res => this._digitalMatterProfiles.next(res),
        error: () => this._digitalMatterProfiles.next(null)
      });
  }
}
