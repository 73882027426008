<div fxFlex class="task-list-component" fxLayout="column" fxLayoutAlign="start stretch">
  <ng-container *ngIf="completedTasks?.length > 0; else noTasks">
  <div fxFlex class="scrollable-table">

    <table mat-table [dataSource]="completedTasks">
      <ng-container [matColumnDef]="taskColumns.ICON">
        <th mat-header-cell class="icon-column" *matHeaderCellDef></th>
        <td mat-cell class="icon-column" *matCellDef="let task">
        <span class="maintenance-icon">
          <mat-icon>{{task.category | maintenanceCategory: 'icon'}}</mat-icon>
        </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="taskColumns.NAME">
        <th mat-header-cell *matHeaderCellDef>{{'general.name.s'|translate}}</th>
        <td mat-cell *matCellDef="let task">
          <span class="name">{{task.name}}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="taskColumns.CATEGORY">
        <th mat-header-cell *matHeaderCellDef>{{'general.type'|translate}}</th>
        <td mat-cell *matCellDef="let task">
          <span>{{task.category | maintenanceCategory}}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="taskColumns.COMPLETION_DATE">
        <th mat-header-cell *matHeaderCellDef>{{ 'shared.maintenance.task.completionDate'|translate }}</th>
        <td mat-cell *matCellDef="let task">
          {{task.completedAtDate | formatTimezoneDateTime }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="taskColumns.RESULT">
        <th mat-header-cell *matHeaderCellDef>{{ 'shared.maintenance.task.maintenanceResult'|translate }}</th>
        <td mat-cell *matCellDef="let task">
          <bh-maintenance-result-badge *ngIf="task.category && task.category !== maintenanceCategory.REMINDER" [result]="task.maintenanceResult"></bh-maintenance-result-badge>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="taskColumns.PERFORMED_BY">
        <th mat-header-cell *matHeaderCellDef>{{ 'shared.maintenance.task.performedBy'|translate }}</th>
        <td mat-cell *matCellDef="let task">
          {{task.completedByInfo | submitterInfo}}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="taskColumns.ACTION">
        <th mat-header-cell class="actions-column" *matHeaderCellDef></th>
        <td mat-cell class="actions-column" *matCellDef="let task">
          <bh-maintenance-completed-task-action-button
            [task]="task"
            (edit)="editTask.emit(task)"
            (delete)="deleteTask.emit(task)"
            (details)="navigateDetails.emit(task)">
          </bh-maintenance-completed-task-action-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator #taskListPaginator
    *ngIf="completedTasks.length > 0"
    [length]="pageable.getLength() | async"
    [pageIndex]="pageable.getIndex() | async"
    [pageSize]="pageable.getSize() | async"
    (page)="pageable.handlePageEvent($event)"
    [pageSizeOptions]="[5, 25, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</ng-container>

<ng-template #noTasks>
  <div class="no-tasks" fxLayout="row" fxLayoutAlign="center center">
    <span>{{'shared.maintenance.task.noUpcomingCompletedTasks'|translate}}</span>
  </div>
</ng-template>

</div>
