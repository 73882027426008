import { Pipe, PipeTransform } from '@angular/core';
import { SearchEquipmentLocation } from 'app/modules/equipment/contract/search-equipment-location.interface';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapItemViewSearchEquipmentLocation } from '../interfaces/map-list-item/map-item-search-equipment-location.interface';
import { MapLocationType } from '../enums/map-location-type.enum';
import { GENERAL_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { MapInfoEquipmentDetailsComponent } from '../components/map-info-window/map-info-equipment-details/map-info-equipment-details.component';

@Pipe({
  name: 'mapMarkersConvertEquipmentLocations'
})
export class MapMarkersConvertEquipmentLocationsPipe implements PipeTransform {

  public transform(value: SearchEquipmentLocation[], mapSettings: MapConfiguration): MapLocationMarker<MapItemViewSearchEquipmentLocation>[] {
    if (!Array.isArray(value)) {
      return null;
    }

    return value.filter(Boolean).map(item => ({
      id: item.equipmentId,
      location: item.location,
      item: { ...item, locationType: MapLocationType.EQUIPMENT },
      infoWindow: { content: MapInfoEquipmentDetailsComponent },
      label: this.getLabel(item, mapSettings),
    }));
  }

  private getLabel(item: SearchEquipmentLocation, mapSettings: MapConfiguration): string {
    return mapSettings?.equipmentColumn !== GENERAL_COLUMN_DEF.EQUIPMENT_NONE
      ? item[mapSettings?.equipmentColumn] || item[GENERAL_COLUMN_DEF.EQUIPMENT_MODEL]
      : null;
  }

}
