<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-search-bar" fxFlex="60px" fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="list-view-search-bar__create" color="primary"
              (click)="addEditAlarmRule(null)"
              [disabled]="disableAdding"
              *ngIf="isAddEditAllowed"
              #addButton
              [matTooltip]="'modules.notification.notificationList.alarmIfEquipmentCreated'|translate"
              [matTooltipDisabled]="!disableAdding">
        <span>{{'modules.notification.notificationList.newAlarm'|translate}}</span>
      </button>
    </div>
  </div>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

      <div class="data-table-wrapper" fxFlex>
        <mat-table #table [dataSource]="notificationStore" fxFlex class="data-table">

          <ng-container cdkColumnDef="alarmType">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.notification.base.alarmType'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{row.alarmType | alarmTypeDisplay}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="selectionType">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.notification.notificationList.selectBy'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{ row.selectionType ? (row.selectionType | alarmSelectionType:row.alarmType) : '' }}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="labelsOrEquipmentTypes">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.notification.notificationList.associatedSelection'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="multiline-cell">
              {{ getSelection(row) }}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="alarmLevel">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.notification.notificationList.alarmLevel'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{row.alarmLevel | alarmLevelDisplay}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="alarmMessage">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.notification.notificationList.alarmMessage'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{row.alarmMessage}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="editAlarmRule">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div [matTooltip]="getEditTooltip(row.alarmType)">
                <button mat-button
                        (click)="addEditAlarmRule(row)"
                        *ngIf="isAddEditAllowed"
                        [disabled]="!alarmTypeService.isTypeAllowed(row.alarmType)">
                  <mat-icon [ngClass]="{'disabled': !alarmTypeService.isTypeAllowed(row.alarmType)}">edit</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="deleteAlarmRule">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="deleteAlarmRule(row)" [matTooltip]="'modules.notification.notificationList.deleteAlarmRule'|translate"
                      *ngIf="isAddEditAllowed">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
