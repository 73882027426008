<div *ngIf="(data | chartData : strategy) as transformData" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch">
  <bh-subtitle [text]="subtitle"></bh-subtitle>
  <div class="chart-container">
    <canvas baseChart
            [type]="type"
            [data]="transformData"
            [options]="options"
            [legend]="false">
    </canvas>
  </div>
</div>
