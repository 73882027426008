import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[bhSchedulerDragHighlighter]'
})
export class SchedulerDragHighlighterDirective {
  @Input('date') set cellDate(date: Date) {
    if (this.highlightClassNamePredicate) {
      this.cellChanged(date);
    }
  }
  @Input('highlightClassName') highlightClassName = 'dhx_custom_highlight';
  @Input('highlightClassNamePredicate') highlightClassNamePredicate: (date: Date) => string;
  private currentHighlightedClassName: string;

  constructor(private schedulerContainer: ElementRef) {
  }

  private cellChanged(date: Date): void {
    this.removeHighlightClass();
    this.highlightCells(date);
  }

  private removeHighlightClass(): void {
    const previousHighlighted = this.schedulerContainer
      .nativeElement
      .getElementsByClassName(this.currentHighlightedClassName) as HTMLCollection;
    Array.from(previousHighlighted).forEach((el) => {
      el.classList.remove(this.highlightClassName);
    });
  }

  private highlightCells(date: Date): void {
    if (!date) {
      return;
    }

    this.currentHighlightedClassName = this.highlightClassNamePredicate(date);
    const needHighlight = this.schedulerContainer
      .nativeElement
      .getElementsByClassName(this.currentHighlightedClassName) as HTMLCollection;
    Array.from(needHighlight).forEach((el) => {
      el.classList.add(this.highlightClassName);
    });
  }
}
