<div class="view-user-dialog">
	<div class="dialog-close-icon">
		<button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
			<mat-icon aria-hidden="true">clear</mat-icon>
		</button>
	</div>
	<mat-dialog-content>
		<p>{{'modules.disposition.viewUserDialog.contactDetails'|translate|uppercase}}</p>

		<mat-form-field>
      <mat-label>{{'general.firstName'|translate|uppercase}} / {{'general.lastName'|translate|uppercase}}</mat-label>
			<div *ngIf="user">
					<input matInput
					readonly
					value="{{user?.firstName | defaultValue: '-'}} / {{user?.name | defaultValue: '-'}}">
			</div>
			<div *ngIf="!user" class="error">
					<input matInput
					readonly
					[value]="'modules.disposition.base.personNotExists'|translate"
					placeholder="{{'general.firstName'|translate|uppercase}} / {{'general.lastName'|translate|uppercase}}">
			</div>
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{'general.email'|translate|uppercase}}</mat-label>
			<input matInput
				   readonly
				   value="{{user?.email | defaultValue: '-'}}">
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{'general.phone'|translate|uppercase}}</mat-label>
			<input matInput
				   readonly
				   value="{{user?.mobileNumber | defaultValue: '-'}}">
		</mat-form-field>
	</mat-dialog-content>
</div>
