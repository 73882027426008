import { InsuranceContractType } from './insurance-contract-type.enum';
import { InsuranceContractMileageType } from './insurance-contract-mileage-type.enum';
import {AttachedDocument} from '../../../shared/contract/attached-document.interface';

export class UpdateInsuranceContractCommand {
  insuranceContractId: string;
  equipmentId: string;
  insuranceContractNumber: string;
  insuranceContractType: InsuranceContractType;
  contractStartDate: string;
  contractEndDate: string;
  costsPerMonth: number;
  mileageLimitPerYear: number;
  insuranceContractMileageType: InsuranceContractMileageType;
  comment: string;
  documents: AttachedDocument[];
  imported: boolean;
}
