import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewStock } from '../../contract/view-stock';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, merge } from 'rxjs';
import { StockService } from '../services/stock.service';
import { BulkItemsService } from '../bulk-items.service';
import { ViewBulkItem } from '../../contract/view-bulk-item.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { distinctUntilChanged } from 'rxjs/operators';
import { numberBiggerThanValidator } from 'app/shared/custom-validators/number-bigger-than.validator';
import { ViewBulkItemAmount } from '../../contract/view-bulk-item-amount.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DecimalNumberValidityService } from '../../../../shared/services/decimal-number-validity.service';
import { IChangeBulkItemAmountData } from '../interfaces/change-bulk-item-amount-data.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { environment } from '../../../../../environments/environment';
import { BulkItemDatasource } from '../bulk-item.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-change-amount-dialog',
  templateUrl: './change-amount-dialog.component.html',
  styleUrls: ['./change-amount-dialog.component.scss']
})
export class ChangeAmountDialogComponent implements OnInit, OnDestroy {

  public bulkItem: ViewBulkItem;
  public stocks: ViewStock[] = [];
  public amounts: ViewBulkItemAmount[] = [];
  public filteredStocks: BehaviorSubject<ViewStock[]> = new BehaviorSubject([]);
  public amountForm: UntypedFormGroup;
  public currentAmount = undefined;
  public stockReadonly = false;
  public filterControl: UntypedFormControl = new UntypedFormControl();

  public readonly faArrowRight: IconDefinition = faArrowRight;

  constructor(public decimalNumberValidityService: DecimalNumberValidityService,
              protected formBuilder: UntypedFormBuilder,
              protected dialogRef: MatDialogRef<ChangeAmountDialogComponent>,
              protected stockService: StockService,
              protected bulkItemsService: BulkItemsService,
              protected bulkItemDatasource: BulkItemDatasource,
              protected dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
  }

  public get amount(): AbstractControl | null {
    return this.amountForm.get('amount');
  }
  public get stockId(): AbstractControl | null {
    return this.amountForm.get('stockId');
  }

  public ngOnInit(): void {
    this.getStocks();
    this.getAmounts();
    this.buildForm();
    this.filterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterStocks();
      });
  }

  public ngOnDestroy(): void {
  }

  public save(): void {
    const amount = this.amountForm?.get('amount').value;
    if (this.isValid() && amount) {
      let bulkItemData: IChangeBulkItemAmountData = {
        bulkItemId: this.bulkItem.bulkItemId,
        stockId: this.amountForm.get('stockId').value,
        amount: undefined,
      };

      switch (this.amountForm.get('action').value) {
        case dialogResults.ADD:
          bulkItemData.amount = this.toSystemDimensionUnit(amount);
          this.stockService.increaseBulkItemAmount(bulkItemData)
            .subscribe(() => {
              this.bulkItemDatasource.updateDeletableStatus(this.bulkItem.bulkItemId);
              this.dialogRef.close('update');
            });
          break;
        case dialogResults.DELETE:
          /* When available amount (rounded to 2 digits) same as input => then use available amount.
          *  This should avoid issue with unreachable values */
          if (this.amountForm.get('amount').value === this.getMaximumAmount()) {
            bulkItemData.amount = this.currentAmount.amountAvailable;
          } else {
            bulkItemData.amount = this.toSystemDimensionUnit(amount);
          }

          this.stockService.decreaseBulkItemAmount(bulkItemData)
            .subscribe(() => {
              this.bulkItemDatasource.updateDeletableStatus(this.bulkItem.bulkItemId);
              this.dialogRef.close('update')
            })
          break;
      }
    }
  }

  public isValid(): boolean {
    return this.amountForm.valid;
  }

  public getAvailable(): number {
    if (this.amountForm.get('stockId').value) {
      let amount = this.amounts.filter(am => am.stockId === this.amountForm.get('stockId').value)[0];
      return amount ? this.toUserDimensionUnit(amount.amountAvailable) : 0;
    } else {
      return null;
    }
  }

  public getTotal(): number {
    if (this.amountForm.get('stockId').value) {
      let amount = this.amounts.filter(am => am.stockId === this.amountForm.get('stockId').value)[0];
      return amount ? this.toUserDimensionUnit(amount.amountTotal) : 0;
    } else {
      return null;
    }
  }

  public getNewAvailable(): number {
    if (this.amountForm.get('stockId').value) {
      let amount = this.amounts.filter(am => am.stockId === this.amountForm.get('stockId').value)[0];
      if (this.amountForm.get('action').value === 'add') {
        let newAmount = amount
          ? +(this.toUserDimensionUnit(amount.amountAvailable) + this.amountForm.get('amount').value).toFixed(3)
          : this.amountForm.get('amount').value;
        let maximumAmountToBeAdded = amount
          ? this.toUserDimensionUnit(amount.amountAvailable) + environment.TEN_MILLION
          : environment.TEN_MILLION;
        return newAmount > maximumAmountToBeAdded ? maximumAmountToBeAdded : newAmount;
      } else {
        let newAmount = amount
          ? +(this.toUserDimensionUnit(amount.amountAvailable) - this.amountForm.get('amount').value).toFixed(3)
          : 0;
        let maximumAmountToBeRemoved = amount
          ? this.toUserDimensionUnit(amount.amountAvailable) - environment.TEN_MILLION
          : 0;
        return newAmount < maximumAmountToBeRemoved ? maximumAmountToBeRemoved : newAmount;
      }
    } else {
      return null;
    }
  }

  public getNewTotal(): number {
    if (this.amountForm.get('stockId').value) {
      let amount = this.amounts.filter(am => am.stockId === this.amountForm.get('stockId').value)[0];
      if (this.amountForm.get('action').value === 'add') {
        let newAmount = amount
          ? +(this.toUserDimensionUnit(amount.amountTotal) + this.amountForm.get('amount').value).toFixed(3)
          : this.amountForm.get('amount').value;
        let maximumAmountToBeAdded = amount
          ? this.toUserDimensionUnit(amount.amountTotal) + environment.TEN_MILLION
          : environment.TEN_MILLION;
        return newAmount > maximumAmountToBeAdded ? maximumAmountToBeAdded : newAmount;
      } else {
        let newAmount = amount
          ? +(this.toUserDimensionUnit(amount.amountTotal) - this.amountForm.get('amount').value).toFixed(3)
          : 0;
        let maximumAmountToBeRemoved = amount
          ? this.toUserDimensionUnit(amount.amountTotal) - environment.TEN_MILLION
          : 0;
        return newAmount < maximumAmountToBeRemoved ? maximumAmountToBeRemoved : newAmount;
      }
    } else {
      return null;
    }
  }

  public getMaximumAmount(): number {
    if (this.currentAmount) {
      if (this.amountForm.get('action').value === 'add') {
        let amountUntilStockLimit = environment.TEN_SEXTILLION - this.toUserDimensionUnit(this.currentAmount.amountTotal);
        return amountUntilStockLimit > environment.TEN_MILLION ? environment.TEN_MILLION : amountUntilStockLimit;
      } else {
        let amountAvailable = this.toUserDimensionUnit(this.currentAmount.amountAvailable);
        return amountAvailable > environment.TEN_MILLION ? environment.TEN_MILLION : amountAvailable;
      }
    } else {
      if (this.amountForm.get('action').value === 'add') {
        return environment.TEN_MILLION;
      } else {
        return 0;
      }
    }
  }

  private setStock(stockId: string): void {
    this.currentAmount = this.amounts.filter(amount => {
      return amount.stockId === stockId && amount.bulkItemId === this.bulkItem.bulkItemId;
    })[0];
    if (stockId) {
      this.amount.enable();
    }
  }

  private buildForm(): void {
    this.amountForm = this.formBuilder.group({
      action: ['add', Validators.required],
      stockId: ['', Validators.required],
      amount: [{
        value: 0,
        disabled: true
      }]
    });
    merge(
      this.amountForm.get('action').valueChanges,
      this.amountForm.get('amount').valueChanges,
      this.amountForm.get('stockId').valueChanges
    )
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.setStock(this.stockId.value);
        this.amount.setValidators([
          Validators.max(this.getMaximumAmount()),
          numberBiggerThanValidator(0),
          Validators.required
        ]);
        this.amount.updateValueAndValidity({emitEvent: false});
      });
  }

  private getStocks(): void {
    this.stockService.getStocks().subscribe(stocks => {
      if (stocks) {
        this.stocks = stocks;
        this.filteredStocks.next(stocks);
      }
    });
  }

  private getAmounts(): void {
    this.bulkItemsService.getAmounts(this.bulkItem.bulkItemId)
      .subscribe(res => {
        if (res) {
          this.amounts = res;
          this.setStock(this.stockId.value);
        }
      });
  }

  private filterStocks(): void {
    if (!this.stocks) {
      return;
    }
    let search = this.filterControl.value;
    if (!search) {
      this.filteredStocks.next(this.stocks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStocks.next(
      this.stocks.filter(stock => this.searchMatchesStock(search, stock))
    );
  }

  private searchMatchesStock(search: string, stock: ViewStock): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0 && (stock.stockName.toLowerCase().indexOf(param) === -1)) {
        return false;
      }
    }
    return true;
  }

  private toUserDimensionUnit(value: number): number {
    return this.dimensionUnitConverterPipe.toUserDimensionUnit(value, this.bulkItem.bulkItemUnit, 3);
  }

  private toSystemDimensionUnit(value: number): number {
    return this.dimensionUnitConverterPipe.toSystemDimensionUnit(value, this.bulkItem.bulkItemUnit);
  }
}
