import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnService } from '../../column.service';
import { ColumnDefinition } from '../../column-definition';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../assets/matomo/matomo-actions.enum';
import { COLUMN_TYPE } from '../../constants/column-definition-constants';
import { GENERAL_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';

export interface TableConfigurationData {
  /**
   * Property to declare how many columns the user can select
   */
  columnLimit?: number;
  /**
   * When a table supports the multiple selection option,
   * we can set the property to {@code true} and the first column will always be "select".
   */
  multiselectActive?: boolean;
  /**
   * Set Matomo Category to track user actions.
   */
  matomoCategory?: matomoCategories;

  columnService: ColumnService;
}

@UntilDestroy()
@Component({
  selector: 'bh-table-configuration-dialog',
  templateUrl: './table-configuration-dialog.component.html',
  styleUrls: ['./table-configuration-dialog.component.scss'],
})
export class TableConfigurationDialogComponent implements OnInit, OnDestroy {
  public columnsAvailableWithoutSelected: ColumnDefinition[] = [];
  public columnsSelected: ColumnDefinition[] = [];
  public columnsAvailable: ColumnDefinition[] = [];

  public columnLimit;
  public multiselectActive = false;
  public matomoCategory: matomoCategories;
  public columnService: ColumnService;

  constructor(@Inject(MAT_DIALOG_DATA) private data: TableConfigurationData,
              public dialogRef: MatDialogRef<TableConfigurationDialogComponent>,
              private matomoTracker: MatomoTracker) {
    this.columnService = data.columnService;
  }

  ngOnInit(): void {
    this.multiselectActive = Boolean(this.data?.multiselectActive);
    this.matomoCategory = this.data.matomoCategory;

    this.columnService.availableColumns
    .pipe(untilDestroyed(this))
    .subscribe((availableColumns: ColumnDefinition[]) => {
      this.columnsAvailable = availableColumns;
      this.columnLimit = this.data.columnLimit || this.columnService.columnLimit || availableColumns.length;
    });

    this.columnService.selectedColumns
    .pipe(untilDestroyed(this))
    .subscribe((selectedColumns: ColumnDefinition[]) => {
      this.columnsSelected = [...selectedColumns].filter(column => !this.columnService.isNonRemovable(column));
      this.filterAvailableColumns();
    });
  }

  ngOnDestroy(): void {
  }

  public drop(event: CdkDragDrop<ColumnDefinition[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, this.resolveTargetPosition(event));
    } else {
      if (event.container.id === 'available') {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      } else if (event.container.data.length < this.columnLimit) {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          this.resolveTargetPosition(event));
      }
    }
  }

  public remove(column: ColumnDefinition): void {
    transferArrayItem(this.columnsSelected,
      this.columnsAvailableWithoutSelected,
      this.columnsSelected.indexOf(column),
      this.columnsAvailableWithoutSelected.length + 1);
  }

  public reset(): void {
    if (this.matomoCategory) {
      this.matomoTracker.trackEvent(this.matomoCategory, matomoActions.COLUMN_CONFIG_SET_DEFAULT);
    }

    this.columnsSelected = cloneDeep(this.columnsAvailable)
    .filter(column => this.columnService.isDefaultColumn(column) && !this.columnService.isNonRemovable(column));

    if (this.multiselectActive) {
      const select: ColumnDefinition = {
        cdkColumnDef: GENERAL_COLUMN_DEF.SELECT,
        type: COLUMN_TYPE.SELECT,
        title: 'select',
        readableName: 'select',
        valueCallback: null,
      };
      this.columnsSelected.unshift(select);
    }

    this.filterAvailableColumns();
  }

  public cancel(): void {
    if (this.matomoCategory) {
      this.matomoTracker.trackEvent(this.matomoCategory, matomoActions.COLUMN_CONFIG_CANCEL);
    }

    this.dialogRef.close();
  }

  public save(): void {
    if (this.matomoCategory) {
      this.matomoTracker.trackEvent(this.matomoCategory, matomoActions.COLUMN_CONFIG_SAVE);
    }

    const selectedColumns = this.columnsSelected;
    this.columnsAvailable
      .filter(column => this.columnService.isNonRemovable(column))
      .forEach(mandatoryColumn => selectedColumns.push(mandatoryColumn));
    this.dialogRef.close(selectedColumns);
  }

  public isValid(): boolean {
    return this.columnsSelected.length > 0;
  }

  public isColumnTypeSelect(column: ColumnDefinition): boolean {
    return column.type === COLUMN_TYPE.SELECT;
  }

  private filterAvailableColumns(): void {
    this.columnsAvailableWithoutSelected = this.columnsAvailable
    .filter(column => !this.columnsSelected
    .map(c => c.cdkColumnDef)
    .includes(column.cdkColumnDef) && !this.columnService.isNonRemovable(column));
  }

  private resolveTargetPosition(event: CdkDragDrop<ColumnDefinition[]>): number {
    return this.multiselectActive && event.container.id === 'selected' && event.currentIndex === 0 ? 1 : event.currentIndex;
  }
}
