import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { TimezoneDatesService } from '../../../../../../../shared/timezone/timezone-dates.service';

@Pipe({
  name: 'telematicsFormatDate'
})
export class TelematicsFormatDatePipe implements PipeTransform {

  constructor(private languageService: LanguageService, private timezoneDatesService: TimezoneDatesService){}

  public transform(date: Date): string {
    return this.languageService.getInstant(
      'modules.equipment.telematics.formatDate',
      { value: !Boolean(date)
        ? this.languageService.getInstant('general.unknown')
        : this.timezoneDatesService.convertDateWithTimezone(date, 'DD.MM.YYYY HH:mm:ss') }
    );
  }

}
