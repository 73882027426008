import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { RentalContractType } from '../../contract/rental-contract-type.enum';

@Pipe({
  name: 'rentalContractType'
})
export class RentalContractTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    switch (value) {
      case RentalContractType.RENTAL_CONTRACT:
        return this.translate('shared.pipes.rentalContractType.rentalContract');
      case RentalContractType.RENTAL_CONTRACT_LONG_TERM:
        return this.translate('shared.pipes.rentalContractType.rentalContractLongTerm');
      case RentalContractType.MACHINE_BREAK_INSURANCE:
        return this.translate('shared.pipes.rentalContractType.machineBreakInsurance');
      case RentalContractType.RENTAL_PURCHASE_CONTRACT:
        return this.translate('shared.pipes.rentalContractType.rentalPurchaseContract');
      case RentalContractType.FULL_SERVICE:
        return this.translate('shared.pipes.rentalContractType.fullService');
      case RentalContractType.TRANSIT_OF_GOODS:
        return this.translate('shared.pipes.rentalContractType.transitOfGoods');

      default:
        return this.translate('general.unknown').toLowerCase();
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
