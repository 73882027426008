<div [formGroup]="settingsForm" fxFlex fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
  <div fxFlex="50">
    <mat-form-field>
      <mat-label>{{getFieldLabel([controlNames.SETTINGS_LIMIT])}}</mat-label>
      <input matInput [formControlName]="controlNames.SETTINGS_LIMIT"
             [min]="0"
             [bhChangeableInputAllowedPattern]="getFieldPattern()"
             required>
      <mat-error *ngIf="limitControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
      <mat-error *ngIf="limitControl.hasError('min')">{{'shared.validation.number.notNegative'|translate}}</mat-error>
    </mat-form-field>
    <bh-explanation-card class="explanation" [text]="getFieldExplanation([controlNames.SETTINGS_LIMIT])"></bh-explanation-card>
  </div>

  <div *ngIf="isIntervalFieldVisible" fxFlex="50">
    <mat-form-field>
      <mat-label>{{getFieldLabel([controlNames.SETTINGS_INTERVAL])}}</mat-label>
      <input matInput
             [formControlName]="controlNames.SETTINGS_INTERVAL"
             type="number"
             required>
      <mat-error *ngIf="limitControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
      <mat-error *ngIf="limitControl.hasError('min')">{{'shared.validation.number.notNegative'|translate}}</mat-error>
    </mat-form-field>
    <bh-explanation-card class="explanation" [text]="getFieldExplanation([controlNames.SETTINGS_INTERVAL])"></bh-explanation-card>
  </div>

</div>
