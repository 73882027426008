import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DispositionEquipment } from '../../modules/equipment/shared/disposition-equipment';
import { EquipmentsService } from '../../modules/equipment/shared/equipments.service';
import { KeycloakService } from '../../core/keycloak';
import { Authorities } from '../enums/authorities.enum';
import { RoleAuthorityGuardsComponent } from '../navigation-guards/role-authority-guards.component';
import { Modules } from '../enums/modules.enum';
import { DispositionStock } from '../../modules/disposition/shared/disposition-stock';
import { StockService } from '../../modules/disposition/shared/services/stock.service';
import { DispositionProject } from '../../modules/disposition/shared/disposition-project';
import { ProjectsService } from '../../modules/disposition/shared/project.service';
import { DispositionBulkItem } from '../../modules/disposition/shared/disposition-bulk-item';
import { BulkItemsService } from '../../modules/disposition/shared/bulk-items.service';
import { ViewProjectAmount } from '../../modules/disposition/contract/view-project-amount.interface';
import { ScanCodeEntry } from '../contract/scan-code-entry.interface';
import { ScanCodeService } from './scan-code.service';
import { ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';
import { AmountsService } from 'app/modules/disposition/shared/amounts.service';

@Injectable()
export class OfflineDataStore extends RoleAuthorityGuardsComponent {
  private offlineDispositionEquipmentsSubject: BehaviorSubject<DispositionEquipment[]> = new BehaviorSubject<DispositionEquipment[]>([]);
  private offlineStockSubject: BehaviorSubject<DispositionStock[]> = new BehaviorSubject<DispositionStock[]>([]);
  private offlineProjectsSubject: BehaviorSubject<DispositionProject[]> = new BehaviorSubject<DispositionProject[]>([]);
  private offlineBulkItemsSubject: BehaviorSubject<DispositionBulkItem[]> = new BehaviorSubject<DispositionBulkItem[]>([]);
  private offlineAmountsSubject: BehaviorSubject<ViewBulkItemAmount[]> = new BehaviorSubject<ViewBulkItemAmount[]>([]);
  private offlineProjectAmountsSubject: BehaviorSubject<ViewProjectAmount[]> = new BehaviorSubject<ViewProjectAmount[]>([]);
  private offlineScanCodesInUseSubject: BehaviorSubject<ScanCodeEntry[]> = new BehaviorSubject<ScanCodeEntry[]>([]);

  constructor(private equipmentsService: EquipmentsService,
              private projectService: ProjectsService,
              private stockService: StockService,
              private amountsService: AmountsService,
              private bulkItemsService: BulkItemsService,
              private scanCodeService: ScanCodeService,
              protected authService: KeycloakService) {
    super(authService);
  }

  public get offlineDispositionEquipments(): Observable<DispositionEquipment[]> {
    return this.offlineDispositionEquipmentsSubject.asObservable();
  }

  public get offlineDispositionStocks(): Observable<DispositionStock[]> {
    return this.offlineStockSubject.asObservable();
  }

  public get offlineDispositionProjects(): Observable<DispositionProject[]> {
    return this.offlineProjectsSubject.asObservable();
  }

  public get offlineDispositionBulkItems(): Observable<DispositionBulkItem[]> {
    return this.offlineBulkItemsSubject.asObservable();
  }

  public get offlineScanCodesInUse(): Observable<ScanCodeEntry[]> {
    return this.offlineScanCodesInUseSubject.asObservable();
  }

  public get offlineDispositionAmounts(): Observable<ViewBulkItemAmount[]> {
    return this.offlineAmountsSubject.asObservable();
  }

  public get offlineDispositionProjectAmounts(): Observable<ViewProjectAmount[]> {
    return this.offlineProjectAmountsSubject.asObservable();
  }

  public loadOfflineData(): void {
    // TODO: Consider updates to cached data in between polling
    if (this.hasOfflineDispositionAuthorities()) {
      this.loadOfflineEquipments();
      this.loadOfflineProjects();
      this.loadOfflineScanCodesInUse();
    }

    if (this.hasOfflineBulkItemTransferAuthorities()) {
      this.loadOfflineTransferAssets();
    }
  }

  private loadOfflineTransferAssets(): void {
    this.stockService.getDispositionStocks()
      .subscribe(stocks => this.offlineStockSubject.next(stocks));
    this.bulkItemsService.getDispositionBulkItems()
      .subscribe(bulkItems => this.offlineBulkItemsSubject.next(bulkItems));
    this.amountsService.getDispositionAmounts()
      .subscribe(amounts => this.offlineAmountsSubject.next(amounts));
    this.amountsService.getDispositionProjectAmounts()
      .subscribe(amounts => this.offlineProjectAmountsSubject.next(amounts));
  }

  private loadOfflineEquipments(): void {
    this.equipmentsService.getDispositionEquipments()
      .subscribe(dispositionEquipments => this.offlineDispositionEquipmentsSubject.next(dispositionEquipments));
  }

  private loadOfflineProjects(): void {
    this.projectService.getDispositionProjects().subscribe(projects => this.offlineProjectsSubject.next(projects));
  }

  private loadOfflineScanCodesInUse(): void {
    this.scanCodeService.findAllScanCodesInUse()
      .subscribe(scanCodesInUse => this.offlineScanCodesInUseSubject.next(scanCodesInUse));
  }

  // TODO: These are the required authorities in the backend. Do they make sense?
  private hasOfflineDispositionAuthorities(): boolean {
    return this.hasModule(Modules.DISPOSITION)
      && (this.hasAuthority(Authorities.PROJECT_ASSIGNEE_VIEW)
        || (this.hasAuthority(Authorities.EQUIPMENT_VIEW) && this.hasAuthority(Authorities.PROJECT_VIEW)));
  }

  private hasOfflineBulkItemTransferAuthorities(): boolean {
    return this.hasModule(Modules.BULK_ITEM_MANAGEMENT)
      && this.hasAnyAuthority([Authorities.ASSET_FROM_STOCK_TRANSFER,
        Authorities.ASSET_FROM_PROJECT_TRANSFER]);
  }
}
