import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import {
  EquipmentAuditResponse,
  EquipmentChange,
  EquipmentHistoryEntry,
} from '../../../../contract/equipment-history.interface';
import { EquipmentAuditService } from '../../../../shared/equipment-audit.service';
import { filter, takeWhile } from 'rxjs/operators';
import { merge } from 'rxjs';
import { RouterHistory } from '../../../../../../shared/router-history';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { isObject } from 'lodash';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { Languages } from '../../../../../../shared/enums/languages.enum';
import { EquipmentStatusCategoryPipe } from '../../../../../../shared/pipes/equipment-status-category.pipe';
import { EquipmentHistoryColumn } from '../../../../contract/equipment-history-column.enum';
import { SubmitterInfo } from 'app/shared/contract/submitter-info.interface';
import { EquipmentStatus } from '../../../../contract/equipment-status.enum';
import { UserConfigurationService } from '../../../../../../shared/services/user-configuration.service';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';
import { TranslatableString } from 'app/shared/contract/translatable-string.type';

interface HistoryTableRow {
  date: string;
  user: SubmitterInfo;
  field: string;
  fieldName: { [key in Languages]?: string };
  typeOld: string;
  typeNew: string;
  removed: string;
  added: string;
  alternateBgColor: boolean;
}

@Component({
  selector: 'bh-equipment-view-history',
  templateUrl: './equipment-view-history.component.html',
  styleUrls: ['./equipment-view-history.component.scss']
})
export class EquipmentViewHistoryComponent extends GuardedNavigableInputComponent
  implements OnInit, OnChanges, OnDestroy {

  @Input() isActive: boolean;

  public equipment: ViewEquipment;
  public historyTable: HistoryTableRow[] = [];
  public displayedColumns: string[] = ['date', 'user', 'field', 'removed', 'added'];

  protected componentActive = true;

  constructor(public equipmentStore: EquipmentsDataSource,
              protected equipmentAuditService: EquipmentAuditService,
              private equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
              private languageService: LanguageService,
              private userConfigurationService: UserConfigurationService,
              protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private translatableStringResolver: TranslatableStringPipe) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.getEquipment();
    if (this.hasAuthority(this.authorities.EQUIPMENT_VIEW_HISTORY)) {
      this.keepHistoryUpToDate();
    }
  }

  public ngOnChanges(): void {
    if (this.equipment && this.componentActive && this.isActive) {
      this.fetchEquipmentHistory();
    }
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  getEquipment() {
    this.equipmentStore.currentEquipment
    .pipe(takeWhile(() => this.componentActive))
    .subscribe(res => {
      if (res) {
        this.equipment = res;
      }
    });
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private keepHistoryUpToDate(): void {
    merge(
      this.equipmentStore.currentEquipment,
      this.equipmentStore.labels
    )
      .pipe(
        takeWhile(() => this.componentActive),
        filter(() => !!this.equipment)
      )
      .subscribe(() => this.fetchEquipmentHistory());
  }

  private fetchEquipmentHistory(): void {
    this.equipmentAuditService.getAuditData(this.equipment.equipmentId)
    .subscribe((auditResponse: EquipmentAuditResponse) => this.createHistoryTable(auditResponse));
  }

  private createHistoryTable(auditResponse: EquipmentAuditResponse): void {
    this.historyTable = [];

    if (!(auditResponse && auditResponse.equipmentHistory)) {
      return;
    }

    // separate history entries (that can contain multiple changes) by alternating table background color
    let alternateBgColor = true;
    // wrong order from backend => reverse
    auditResponse.equipmentHistory.reverse().forEach((historyEntry: EquipmentHistoryEntry) => {
      historyEntry.equipmentChanges.forEach((change: EquipmentChange, changeIndex: number) => {
        let oldValues = this.parseToArray(change.oldValueName ?? change.oldValue);
        let newValues = this.parseToArray(change.newValueName ?? change.newValue);
        let diff = this.arrayDiff(oldValues, newValues);
        let typeOld = '';
        let typeNew = '';

        // sometimes fields change between null and empty string (diff has empty arrays). Don't include them in the history
        if (diff.removed.length + diff.added.length > 0 || change.field === EquipmentHistoryColumn.EQUIPMENT_CREATED) {
          // next historyEntry: change background color
          if (0 === changeIndex) {
            alternateBgColor = !alternateBgColor;
          }
          if (change.field === EquipmentHistoryColumn.EQUIPMENT_STATUS) {
            diff.removed = [this.toReadableEquipmentStatus(diff.removed[0])];
            diff.added = [this.toReadableEquipmentStatus(diff.added[0])];
          } else if (change.field === EquipmentHistoryColumn.EQUIPMENT_STATUS_CATEGORY) {
            diff.removed = [this.equipmentStatusCategoryPipe.transform(diff.removed[0])];
            diff.added = [this.equipmentStatusCategoryPipe.transform(diff.added[0])];
          }

          if (change.field === EquipmentHistoryColumn.TELEMATICS_UNIT_ID) {
            let typeChange = historyEntry.equipmentChanges.find(c => c.field === EquipmentHistoryColumn.TELEMATICS_UNIT_TYPE);
            if (typeChange && change.newValue && change.newValue.length > 0) {
              typeNew = typeChange.newValue;
            } else if (typeChange && change.oldValue && change.oldValue.length > 0) {
              typeOld = typeChange.oldValue;
            }
          }

          const submitterInfo = 0 === changeIndex ? historyEntry.submitterInfo : null;

          this.historyTable.push(
            {
              // only print date and user on first line, if the entry has multiple changes
              date: 0 === changeIndex ? historyEntry.changeDate : '',
              user: submitterInfo,
              field: change.field,
              fieldName: change.fieldName,
              typeOld: typeOld,
              typeNew: typeNew,
              removed: diff.removed.join(', '),
              added: diff.added.join(', '),
              alternateBgColor: alternateBgColor
            } as HistoryTableRow);
        }
      });
    });
  }

  // value is null, string, or stringified array "[a, b, c, ...]"
  // value is object - when contain localized strings
  // parse all to string array to make them comparable
  private parseToArray(value: TranslatableString | string): string[] {
    if (value) {
      if (isObject(value)) {
        return [this.translatableStringResolver.transform(value)]
      } else {
        return value
          .replace('[', '')
          .replace(']', '')
          .split(',')
          .map(element => element.trim())
      }
    }
    return [];
  }

  // compare two arrays, return object with differences
  private arrayDiff(oldValues: string[], newValues: string[]): { removed: string[], added: string[] } {
    return {
      removed: oldValues.filter(x => newValues.indexOf(x) === -1),
      added: newValues.filter(x => oldValues.indexOf(x) === -1)
    };
  }

  private toReadableEquipmentStatus(value: string): string {
    switch (value) {
      case EquipmentStatus.ACTIVE:
        return this.translate('shared.pipes.equipmentStatus.active');
      case EquipmentStatus.ACTIVE_RENTAL:
        return this.translate('shared.pipes.equipmentStatus.activeRental');
      case EquipmentStatus.RENTED:
        return this.translate('shared.pipes.equipmentStatus.rented');
      case EquipmentStatus.INACTIVE:
        return this.translate('shared.pipes.equipmentStatus.inactive');
      case EquipmentStatus.INACTIVE_RENTAL:
        return this.translate('shared.pipes.equipmentStatus.inactiveRental');
      case EquipmentStatus.SOLD:
        return this.translate('shared.pipes.equipmentStatus.sold');
      case EquipmentStatus.DECOMMISSIONED:
        return this.translate('shared.pipes.equipmentStatus.decommissioned');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
