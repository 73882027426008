import { getHttpParameters } from 'app/shared/utils';
import { environment } from 'environments/environment';
import { CreateContactCommand } from '../../contract/contact/create-contact-command.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { ContactSearch } from '../../contract/contact/contact-search.interface';
import { CustomEncoder } from 'app/shared/custom-encoder';
import { ContactView } from '../../contract/contact/contact-view.interface';
import { UpdateContactCommand } from '../../contract/contact/update-contact-command.interface';
import { DeleteContactCommand } from '../../contract/contact/delete-contact-command.interface';
import { DeactivateContactCommand } from '../../contract/contact/deactivate-contact-command.interface';
import { ActivateContactCommand } from '../../contract/contact/activate-contact-command.interface';
import { ContactLocation } from '../../contract/contact/contact-location.interface';
import { AddContactLabelCommand } from '../../contract/contact/add-contact-label-command.interface';
import { RemoveContactLabelCommand } from '../../contract/contact/remove-contact-label-command.interface';
import { CustomerLabel } from 'app/shared/contract/customer-label.interface';
import { ContactFilterView } from '../contract/contact-filter-view.interface';
import { SetContactDescriptionCommand } from '../../contract/contact/set-contact-description-command.interface';
import { ExportContactRequest } from '../contract/contact-request-params/export-contact-request.interface';
import { ContactType } from '../enums/contact-type.enum';
import { ContactStatus } from '../enums/contact-status.enum';

export type SearchContactsBody = {
  terms?: string;
  searchColumns?: string[];
  type?: ContactType[];
  status: ContactStatus[];
  organisation?: string[];
  label?: string[];
};

export type SearchContactsRequest = {
  body: SearchContactsBody;
  page?: number;
  size?: number
  sort?: any
};

@Injectable()
export class ContactService {
  private url = `${environment.APP_CORE_DATA_SERVICE_BASE_URL}/api/v1/contacts`;

  constructor(private http: HttpClient) {
  }

  public addContact(command: CreateContactCommand): Observable<string> {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateContact(command: UpdateContactCommand): Observable<string> {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteContact(command: DeleteContactCommand): Observable<string> {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public deactivateContact(command: DeactivateContactCommand): Observable<string> {
    return this.http.post(this.url + '/deactivate', JSON.stringify(command), {responseType: 'text'});
  }

  public activateContact(command: ActivateContactCommand): Observable<string> {
    return this.http.post(this.url + '/activate', JSON.stringify(command), {responseType: 'text'});
  }

  public addContactLabel(command: AddContactLabelCommand): Observable<string> {
    return this.http.post(this.url + '/add-label', JSON.stringify(command), {responseType: 'text'});
  }

  public removeContactLabel(command: RemoveContactLabelCommand): Observable<string> {
    return this.http.post(this.url + '/remove-label', JSON.stringify(command), {responseType: 'text'});
  }

  public getContact(contactId: string): Observable<ContactView> {
    return this.http.get<ContactView>(this.url + '/' + contactId);
  }

  public identifierInUse(identifier: string): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('identifier', identifier)
    };
    return this.http.get<boolean>(`${this.url}/identifier-in-use`, httpOptions);
  }

  public getContactsSearch(searchParams: SearchContactsRequest): Observable<PagedResponse<ContactSearch>> {
    let httpParams = getHttpParameters({
      page: searchParams.page,
      size: searchParams.size,
      sort: searchParams.sort,
    });
    return this.http.post<PagedResponse<ContactSearch>>(`${this.url}/search/all`, searchParams.body,{ params: httpParams });
  }

  public getContactLocations(searchParams: Object): Observable<ContactLocation[]> {
    const params = getHttpParameters(searchParams);
    return this.http.get<ContactLocation[]>(`${this.url}/geo`, { params });
  }

  public getContactLocationsSearch(searchParams: Object): Observable<ContactLocation[]> {
    const params = getHttpParameters(searchParams);
    return this.http.get<ContactLocation[]>(`${this.url}/search/geo`, { params });
  }

  public getFilters(params: Object): Observable<ContactFilterView> {
    const httpParams = getHttpParameters(params);
    return this.http.get<ContactFilterView>(`${this.url}/filter`, { params: httpParams });
  }

  public getCustomerLabels(): Observable<CustomerLabel[]> {
    return this.http.get<CustomerLabel[]>(`${this.url}/label`);
  }

  public setDescription(command: SetContactDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/set-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public exportContactList(params: ExportContactRequest): Observable<Blob> {
    const httpParams = getHttpParameters(params);
    return this.http.get(`${this.url}/search/export`, { params: httpParams, responseType: 'blob' });
  }
}
