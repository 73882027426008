import { WeekDayConfiguration } from '../../../shared/contract/week-day-configuration.type';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { AlarmLevel } from '../shared/enums/alarm-level.enum';
import { GeofenceAlarmTrigger } from '../shared/enums/geofence-alarm-trigger.enum';
import { EquipmentAlarmRuleSelectionType } from './equipment-alarm-rule-selection-type.enum';
import { TelematicsUnitType } from '../../equipment/contract/telematics-unit-type.enum';

export class CreateAlarmRuleCommand {
  selectionType: EquipmentAlarmRuleSelectionType;
  labels?: string[];
  equipmentTypeIds?: string[];
  customerId: string;
  alarmType: AlarmType;
  limit?: number;
  limitDurationUnit?: string;
  batteryStatus?: string;
  timeframeInDays?: number;
  globalGeofenceId?: string;
  geofenceAlarmTrigger?: GeofenceAlarmTrigger;
  alarmLevel: AlarmLevel;
  alarmMessage: string;
  emailRecipients?: string[];
  userRolesEmailRecipients?: string[];
  timefenceConfiguration?: {
    operatingHoursLimit?: number;
    distanceLimit?: number;
    weekDayConfiguration: WeekDayConfiguration;
  };
  telematicsUnitTypes: TelematicsUnitType[];
  zoneId: string;

  toTransportCommand(): CreateTransportAlarmRuleCommand {
    const { alarmType, alarmLevel, alarmMessage, zoneId, customerId, emailRecipients, userRolesEmailRecipients } = this;
    return {
      alarmType,
      alarmLevel,
      alarmMessage,
      zoneId,
      customerId,
      emailRecipients,
      userRolesEmailRecipients
    };
  }
}

export class CreateTransportAlarmRuleCommand {
  alarmType: AlarmType;
  alarmLevel: AlarmLevel;
  alarmMessage: string;
  zoneId: string;
  customerId: string;
  emailRecipients?: string[];
  userRolesEmailRecipients?: string[];
}
