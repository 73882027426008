<ng-container [ngSwitch]="fieldType">

  <!-- STRING -->
  <bh-input *ngSwitchCase="AdditionalFieldType.STRING"
            [label]="field?.additionalFieldName | translatableString : language"
            [formControl]="control"
            [trimInputTextEnabled]="true"
            [errorMessages]="errorMessages"
            [readonly]="readonly"
            (keydown)="handleKeyPress($event)">
    <span *ngIf="fieldUnit" ospSuffix class="no-wrap">{{ fieldUnit }}</span>
  </bh-input>


  <!-- NUMBER -->
  <bh-input-number *ngSwitchCase="AdditionalFieldType.NUMBER"
                   [label]="field?.additionalFieldName | translatableString : language"
                   [formControl]="control"
                   [errorMessages]="errorMessages"
                   [readonly]="readonly"
                   (keydown)="handleKeyPress($event)">
    <span *ngIf="fieldUnit" ospSuffix class="no-wrap">{{ fieldUnit }}</span>
  </bh-input-number>


  <!-- BOOLEAN -->
  <mat-form-field *ngSwitchCase="AdditionalFieldType.BOOLEAN" appearance="fill">
    <mat-label>{{ field?.additionalFieldName | translatableString : language }}</mat-label>
    <input *ngIf="readonly" matInput [value]="getBoolValue() | translate" [readonly]="true">
    <mat-select *ngIf="!readonly" [formControl]="control" (keydown)="handleKeyPress($event)">
      <mat-option [value]="true">{{ 'general.yes' | translate }}</mat-option>
      <mat-option [value]="false">{{ 'general.no' | translate }}</mat-option>
    </mat-select>

    <mat-error>
      <bh-form-field-error [control]="control" [errorMessages]="errorMessages"></bh-form-field-error>
    </mat-error>
  </mat-form-field>


  <!-- SELECT -->
  <mat-form-field *ngSwitchCase="AdditionalFieldType.SELECT" appearance="fill">
    <mat-label>{{ field?.additionalFieldName | translatableString : language }}</mat-label>
    <input *ngIf="readonly" matInput [value]="getOptionValue() | translatableString : language" [readonly]="true">
    <mat-select *ngIf="!readonly" [formControl]="control" (keydown)="handleKeyPress($event)">
      <mat-option *ngFor="let option of fieldOptions" [value]="option.optionKey">
        {{ option.optionName | translatableString : language }}
      </mat-option>
    </mat-select>

    <mat-error>
      <bh-form-field-error [control]="control" [errorMessages]="errorMessages"></bh-form-field-error>
    </mat-error>
  </mat-form-field>
</ng-container>
