import * as moment from 'moment';

export class TimelineEvent {

  public start_date: Date;
  public end_date: Date;
  public charge_date: Date;

  constructor(public id: string,
              public section_id: string,
              start_date: Date,
              end_date: Date | null,
              charge_date: Date | null,
              public text = '',
              public readonly = false,
              public transfer = false,
              public color?: string) {
    this.start_date = new Date(start_date);
    this.end_date = end_date ? new Date(end_date) : moment(this.start_date).add(10, 'years').toDate();
    this.charge_date = charge_date ? new Date(charge_date) : null;
  }

}
