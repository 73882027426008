import { LanguageService } from './../../../../../../shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { RouterHistory } from '../../../../../../shared/router-history';
import { LifeCycleEmployeeReplaced } from '../../../../contract/life-cycle-employee-replaced';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-view-employee-assignment-replaced',
  templateUrl: './mobile-equipment-view-employee-assignment-replaced.component.html',
  styleUrls: ['./mobile-equipment-view-employee-assignment-replaced.component.scss']
})
export class MobileEquipmentViewEmployeeAssignmentReplacedComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public lifecycleEmployee: Observable<LifeCycleEmployeeReplaced>;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void  {
    this.lifecycleEmployee = this.route.params.pipe(
        flatMap(params => this.equipmentStore.getLifeCycleDetails(params['id'], params['lifecycleId'])),
        map(lifeCycle => lifeCycle as LifeCycleEmployeeReplaced));
  }

  ngOnDestroy(): void {
  }

  public navigateBack(): void {
    this.goBack(`/mobile/equipments/view/${this.route.snapshot.params.id}/timeline`);
  }

  public getFullName(firstName: string, name: string): string {
    return (firstName ? firstName + ' ' : '') + name;
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
