import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ButtonFlatComponent } from './components/button-flat/button-flat.component';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { ButtonIconFabComponent } from './components/button-icon-fab/button-icon-fab.component';


@NgModule({
  declarations: [
    ButtonComponent,
    ButtonFlatComponent,
    ButtonIconComponent,
    ButtonIconFabComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    ButtonComponent,
    ButtonFlatComponent,
    ButtonIconComponent,
    ButtonIconFabComponent,
  ]
})
export class BhButtonModule { }
