<mat-expansion-panel #panel class="panel">
  <mat-expansion-panel-header class="panel-header" [expandedHeight]="'155px'" [collapsedHeight]="'155px'">
    <mat-panel-title class="h-100">
      <div class="header-panel" fxLayout="row" fxFlex fxLayoutAlign="start center">
        <div class="request-info content-padding h-100" fxFlex="235px" fxLayout="column" fxLayoutAlign="center start">
          <bh-project-number-and-cost-center [number]="request.projectNumber"
                                             [costCenter]="request.projectCostCenter">
          </bh-project-number-and-cost-center>
          <div class="m-w-100" fxLayoutAlign="start center" fxLayoutGap="5px">
            <span class="text-ellipsis fw-500 fs-18">{{request.projectName}}</span>
            <mat-icon class="link-icon"
                      [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.project.s'|translate }"
                      [routerLink]="['/sites/projects/list', request.projectId]">
              open_in_new
            </mat-icon>
          </div>
          <div [matTooltip]="request.submitterEmail" class="color-gray w-100 request-created-by">
            {{"general.labels.createdBy" | translate}}: {{request.submittedBy}}
          </div>
          <div class="request-date request-date_stock color-gray">
            {{"general.on" | translate}}: {{ request.submitDate | formatTimezoneDateTime: {showTime: true} }}
          </div>
          <span class="transfer-elements">
            1
            {{(1 > 1 ? 'general.element.pl': 'general.element.s')|translate}}
          </span>
        </div>
        <div class="w-100 h-100 content-padding" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="h-100" fxLayout="column" fxLayoutAlign="space-between start">
            <div>
              <div fxLayout="row" class="mb-10">
                <fa-icon class="mr-5 custom-icon" [icon]="iconService.resolveStatusIcon(request.status)"></fa-icon>
                {{request.status | typeOfUseStatus}}
              </div>
              <div fxLayout="row">
                <ng-container *ngIf="request?.reason">
                  <fa-icon class="mr-5 custom-icon" [icon]="iconService.resolveReasonIcon(request.reason)"></fa-icon>
                  {{request.reason | typeOfUseStatusReason}}
                </ng-container>
              </div>
            </div>
            <div class="color-gray">
              <div>
                {{"general.labels.start" | translate}}: {{ request.startDate | formatTimezoneDateTime:{showTime: true} }}
              </div>
              <div>
                {{"general.labels.end" | translate}}: {{ request.endDate | formatTimezoneDateTime: {showTime: true} }}
              </div>
            </div>
          </div>
          <div>
            <ng-container *ngIf="showAcceptAndDeclineBtns">
              <button (click)="acknowledgeRequest($event)"
                      class="request-button request-accept mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                      [matTooltip]="'general.labels.changeTypeOfUseRequest.accept' | translate">
                <fa-icon [icon]="faCheck" class="accept-icon"></fa-icon>
              </button>
              <button (click)="declineRequest($event)"
                      class="request-button request-decline mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                      [matTooltip]="'general.labels.changeTypeOfUseRequest.decline' | translate">
                <fa-icon [icon]="faTimes" class="accept-icon"></fa-icon>
              </button>
            </ng-container>
            <button *ngIf="deleteBtnIsShow" (click)="removeRequest($event)"
                    class="request-button request-remove mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                    [matTooltip]="'general.labels.changeTypeOfUseRequest.delete' | translate">
              <fa-icon [icon]="faTrash" class="accept-icon"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div class="request-item" fxLayout="row" fxLayoutAlign="stretch" fxFill>
      <div class="index-cell content-padding" fxFlex="235px" fxLayout="column" fxLayoutAlign="center">
        #{{ 1 }}
      </div>
      <div class="p-10-20 br-1gl0 overflow-h" fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
        <div class="sub-content">{{request.transferItemNumber}}</div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px">
          <span class="text-ellipsis fw-500 fs-18">{{request.transferItemName}}</span>
          <mat-icon *ngIf="request.itemIsAccessibleForUser"
                    data-osp-test="item-link"
                    class="link-icon"
                    [matTooltip]="'general.labels.jumpTo'|translate:{ value: (request.transferListItemType | transferItem: 'TOOLTIP') }"
                    [routerLink]="(request.transferListItemType | transferItem: 'URL') + request.transferItemId">
            open_in_new
          </mat-icon>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px">
          <span class="sub-content">{{'general.origin'|translate}}:</span>
          <span class="text-ellipsis fw-500 fs-18">{{request.sourceStockName}}</span>
          <mat-icon *ngIf="request.sourceLocationIsAccessibleForUser"
                    data-osp-test="source-location-link"
                    class="link-icon"
                    [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.stock.s'|translate }"
                    [routerLink]="['/sites/stocks/list', request.sourceStockId]">
            open_in_new
          </mat-icon>
        </div>
      </div>
      <div fxFlex="90px" fxLayout="row" fxLayoutAlign="center center">
        {{ request.amount | dimensionUnitConverter: request.transferItemUnit:3 }} {{ request.transferItemUnit | dimensionUnitDisplay }}
      </div>
    </div>
  </div>

  <div class="request-item" fxLayout="row" fxFill>
    <div class="index-cell comment-section-cell" fxFlex="235px" fxLayout="column" fxLayoutAlign="center">
      {{ 'modules.disposition.projectTransferHistory.additionalInformation' | translate }}
    </div>

    <div class="comment content-padding comment-section-cell" fxLayout="row" fxLayoutGap="10px">
      <div class="">{{ 'modules.disposition.projectTransferHistory.comment' | translate }}:</div>
      <div class="sub-content">{{request.comment}}</div>
    </div>
  </div>
</mat-expansion-panel>
