<bh-step-dialog class="transportation-task-dialog"
                [title]="(isEditMode
                  ? 'modules.transportation.transportationTaskAddEdit.editTransportationTask'
                  : 'modules.transportation.transportationTaskAddEdit.createTransportationTask') | translate"
                [icon]="faTruck">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="transportationTasksForm" #transportationTasksNgForm="ngForm">
      <mat-horizontal-stepper labelPosition="bottom" fxFlex="100" fxLayout="column"
                              fxLayoutAlign="space-between stretch" linear #stepper>
        <!-- GENERAL DATA -->
        <mat-step [stepControl]="transportationTasksForm.get('data')" [formGroup]="transportationTasksForm.get('data')">
          <ng-template matStepLabel>{{'modules.transportation.base.baseData'|translate}}&nbsp;*</ng-template>
          <div class="transportation-task-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxLayout="row" class="transportation-task-dialog-base-data"
                 fxLayoutAlign="space-between stretch">
              <div fxFlex="47">
                <div class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.transportation.base.title'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="title"
                           [maxlength]="fieldLimit.NAME"
                           [matAutocomplete]="transportationTitle"
                           required>
                    <mat-error *ngIf="dataFormField.get('title').hasError('required') || dataFormField.get('title').hasError('pattern')">
                      {{'shared.validation.requiredField'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-autocomplete #transportationTitle="matAutocomplete">
                    <mat-option *ngFor="let title of filteredTransportTitleOptions"
                                [value]="title.title">
                      {{ title.title }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
              <div fxFlex="47">
                <div class="field-wrapper">
                  <mat-form-field class="mat-block" [ngClass]="{ 'form-field-warning': isIncompatibleTransportType | async }">
                    <mat-label>{{'modules.transportation.base.transportType'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="transportationTaskType"
                           [maxlength]="fieldLimit.SHORT_IDENTIFIER"
                           [matAutocomplete]="transportationType"
                           [required]="isEditPlannableAndBeyond">
                    <mat-spinner matSuffix *ngIf="transportationTaskType.pending" [diameter]="18"></mat-spinner>
                    <mat-icon matSuffix
                      *ngIf="transportationTaskType.valid && (canRevertTransportType | async)"
                      (click)="revertTransportType($event)"
                      [matTooltip]="'modules.transportation.transportationTaskAddEdit.incompatibleTypeRevertBack'|translate">
                      replay
                    </mat-icon>
                    <mat-error *ngIf="transportationTaskType.hasError('required') || transportationTaskType.hasError('pattern')">
                      {{'shared.validation.requiredField'|translate}}
                    </mat-error>
                    <mat-error *ngIf="transportationTaskType.hasError('transport-type-not-exist')">
                      {{'modules.transportation.base.errorCannotCreateNewType'|translate}}
                    </mat-error>
                    <mat-error *ngIf="transportationTaskType.hasError('labelExists')">
                      {{transportationTaskType.getError('labelExists')}}
                    </mat-error>
                    <mat-hint align="start" *ngIf="isIncompatibleTransportType | async">
                      {{'modules.transportation.transportationTaskAddEdit.incompatibleTypeHint'|translate}}
                    </mat-hint>
                  </mat-form-field>
                  <mat-autocomplete #transportationType="matAutocomplete" (optionSelected)="transportTypeSelected()">
                    <mat-option *ngFor="let type of filteredTransportTypeOptions"
                                [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.transportation.transportationTaskAddEdit.description'|translate}}</mat-label>
                    <textarea matInput
                              #inputDescription
                              class="transportation-task-description-field"
                              formControlName="description"
                              [maxlength]="fieldLimit.LONG_TEXT"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="2"
                    >
                    </textarea>
                    <mat-hint align="end">{{inputDescription.value?.length || 0}}/{{fieldLimit.LONG_TEXT}}</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxFlex="90" fxLayout="row" class="transportation-task-dialog-base-data"
                 fxLayoutAlign="space-between start">
                <mat-form-field fxFlex="25">
                  <mat-label>{{'modules.transportation.base.priority'|translate}}</mat-label>
                  <mat-select formControlName="priority" required>
                    <mat-option *ngFor="let priority of priorityList"
                                [value]="priority">
                      {{ priority  | transportPriority | uppercase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="field-wrapper" fxFlex="40" fxLayout="row" fxLayoutAlign="space-between stretch">
                  <mat-form-field fxFlex="49">
                    <mat-label>{{'modules.transportation.base.dueDate'|translate}}</mat-label>
                    <input matInput
                          #inputDateRef
                          bhInputAllowedCharacters="[0-9./]"
                          [matDatepicker]="picker"
                          [min]="minDate"
                          [max]="maxDate"
                          maxLength="20"
                          autocomplete="off"
                          formControlName="dueDate">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker-toggle matSuffix (click)="clearDueDate()">
                      <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="dueDate.hasError('isValidDateInstance')">
                      {{dueDate.getError('isValidDateInstance')}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="49" class="form-field-time">
                    <mat-label>{{'modules.transportation.base.dueTime'|translate}}</mat-label>
                    <input type="time"
                          matInput
                          formControlName="dueTime">
                    <button mat-icon-button matSuffix *ngIf="!isFirefoxBrowser" (click)="clearDueTime()">
                      <mat-icon>clear</mat-icon>
                    </button>
                    <mat-error *ngIf="dueTime.hasError('time-without-valid-date')">
                      {{'shared.validation.date.timeWithoutValidDate'|translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <mat-form-field fxFlex="25" floatLabel="always">
                  <mat-label>{{'modules.transportation.base.estimatedDuration'|translate}}</mat-label>
                  <bh-time-duration formControlName="estimatedDuration"></bh-time-duration>
                  <mat-error *ngIf="estimatedDuration.hasError('duration-zero')">
                    {{'modules.transportation.transportationTaskAddEdit.errorEstimatedDurationZero'|translate}}
                  </mat-error>
                </mat-form-field>
            </div>

            <div class="button-container" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
                <button mat-raised-button
                        matStepperNext
                        [disabled]="transportationTasksForm.get('data').invalid">
                  {{'general.buttons.continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <!-- START ADDRESS -->
        <mat-step [stepControl]="startAddress" *ngIf="!startAddress.disabled">
          <ng-template matStepLabel>
            {{'modules.transportation.base.startAddress'|translate}}
            {{isEditPlannableAndBeyond ? '*' : ''}}
          </ng-template>
          <div class="transportation-task-stepper-content" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="transportation-task-dialog-base-data transportation-map-data"
                 fxLayoutAlign="space-between start" fxLayoutGap="15px">
                <bh-transport-location-map-selection fxFlex="1 1 100%"
                  [locations]="selectionController.transportItems | async"
                  [displayInfo]="selectionController.mapDisplayedInfo | async"
                  [selectedItems]="selectionController.mapMoveStartAddress | async"
                  (selected)="processStartAddressChangedValue($event)">
                </bh-transport-location-map-selection>
                <div fxFlex="40" fxLayoutGap="15px">
                  <div class="field-wrapper">
                    <mat-form-field class="mat-block">
                      <mat-label>{{'modules.transportation.base.startAddress'|translate}}</mat-label>
                      <textarea matInput
                        formControlName="startAddress"
                        [matAutocomplete]="transportationStartAddress"
                        [required]="isEditPlannableAndBeyond"
                        bhPreventFocusSwitch
                        [unallowedElementClasses]="mapInfoWindowClasses"
                        cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6">
                      </textarea>
                      <mat-error *ngIf="startAddress.hasError('required')">
                        {{'shared.validation.requiredField'|translate}}
                      </mat-error>
                      <mat-error *ngIf="startAddress.hasError('not-transport-item')">
                        {{'modules.transportation.transportationTaskAddEdit.transportItemSelectOptionError'|translate}}
                      </mat-error>
                    </mat-form-field>
                    <mat-autocomplete #transportationStartAddress="matAutocomplete"
                                      class="transport-address-select"
                                      [displayWith]="displayAutocompleteValue()">
                      <mat-option *ngFor="let item of filteredStartAddressOptions; trackBy: trackByTransportItemId"
                                  [value]="item">
                        <ng-container
                          [ngTemplateOutlet]="transportAddressOption"
                          [ngTemplateOutletContext]="{ item: item }">
                        </ng-container>
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
            </div>

            <div class="button-container" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between end">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious>
                  {{'general.buttons.back'|translate}}
                </button>
              </div>
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button mat-raised-button
                        matStepperNext
                        [disabled]="!startAddress.valid">
                  {{'general.buttons.continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <!-- END ADDRESS -->
        <mat-step [stepControl]="endAddress" *ngIf="!endAddress.disabled">
          <ng-template matStepLabel>
            {{'modules.transportation.base.destinationAddress'|translate}}
            {{isEditPlannableAndBeyond ? '*' : ''}}
          </ng-template>
          <div class="transportation-task-stepper-content"
               fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="transportation-task-dialog-base-data transportation-map-data"
                 fxLayoutAlign="space-between start" fxLayoutGap="15px">
                <bh-transport-location-map-selection fxFlex="1 1 100%"
                  [locations]="selectionController.transportItems | async"
                  [displayInfo]="selectionController.mapDisplayedInfo | async"
                  [selectedItems]="selectionController.mapMoveEndAddress | async"
                  (selected)="processEndAddressChangedValue($event)">
                </bh-transport-location-map-selection>
                <div fxFlex="40" fxLayoutGap="15px">
                  <div class="field-wrapper">
                    <mat-form-field class="mat-block">
                      <mat-label>{{'modules.transportation.base.destinationAddress'|translate}}</mat-label>
                      <textarea matInput
                             formControlName="endAddress"
                             [matAutocomplete]="transportationEndAddress"
                             [required]="isEditPlannableAndBeyond"
                             bhPreventFocusSwitch
                             [unallowedElementClasses]="mapInfoWindowClasses"
                             cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6">
                      </textarea>
                      <mat-error *ngIf="endAddress.hasError('required')">
                        {{'shared.validation.requiredField'|translate}}
                      </mat-error>
                      <mat-error *ngIf="endAddress.hasError('not-transport-item')">
                        {{'modules.transportation.transportationTaskAddEdit.transportItemSelectOptionError'|translate}}
                      </mat-error>
                    </mat-form-field>
                    <mat-autocomplete #transportationEndAddress="matAutocomplete"
                                      class="transport-address-select"
                                      [displayWith]="displayAutocompleteValue()">
                      <mat-option *ngFor="let item of filteredEndAddressOptions; trackBy: trackByTransportItemId"
                                  [value]="item">
                        <ng-container
                          [ngTemplateOutlet]="transportAddressOption"
                          [ngTemplateOutletContext]="{ item: item }">
                        </ng-container>
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
            </div>
            <div class="button-container" fxLayout="row" fxLayoutGap="15px"
                 fxLayoutAlign="space-between end">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious>
                  {{'general.buttons.back'|translate}}
                </button>
              </div>
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button mat-raised-button
                        matStepperNext
                        [disabled]="!endAddress.valid">
                  {{'general.buttons.continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <!-- RELATED PROJECT -->
        <mat-step [label]="relatedProjectStepName" [stepControl]="relatedProject">
          <ng-template matStepLabel>{{'modules.transportation.base.relatedProject'|translate}}&nbsp;*
          </ng-template>
          <div class="transportation-task-stepper-content"
               fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="transportation-task-dialog-base-data transportation-map-data"
                 fxLayoutAlign="space-between start" fxLayoutGap="15px">
                <bh-transport-location-map-selection fxFlex="1 1 100%"
                  [locations]="selectionController.projectItems | async"
                  [displayInfo]="selectionController.mapDisplayedInfo | async"
                  [selectedItems]="selectionController.mapMoveProjectAddress | async"
                  (selected)="processProjectAddressChangedValue($event)">
                </bh-transport-location-map-selection>
                <div fxFlex="40" fxLayoutGap="15px">
                  <div class="field-wrapper">
                    <mat-form-field class="mat-block">
                      <mat-label>{{'modules.transportation.base.relatedProject'|translate}}</mat-label>
                      <textarea matInput
                             formControlName="relatedProject"
                             [matAutocomplete]="relatedProjectAutocomplete"
                             required
                             bhPreventFocusSwitch
                             [unallowedElementClasses]="mapInfoWindowClasses"
                             cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6">
                      </textarea>
                      <mat-error *ngIf="relatedProject.hasError('required')">
                        {{'shared.validation.requiredField'|translate}}
                      </mat-error>
                      <mat-error *ngIf="relatedProject.hasError('not-transport-item')">
                        {{'modules.transportation.transportationTaskAddEdit.transportItemSelectOptionError'|translate}}
                      </mat-error>
                    </mat-form-field>
                    <mat-autocomplete #relatedProjectAutocomplete="matAutocomplete"
                                      class="transport-address-select"
                                      [displayWith]="displayAutocompleteValue()">
                      <mat-option *ngFor="let item of filteredRelatedProjectOptions; trackBy: trackByTransportItemId"
                                  [value]="item">
                        <ng-container
                          [ngTemplateOutlet]="transportAddressOption"
                          [ngTemplateOutletContext]="{ item: item }">
                        </ng-container>
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
            </div>

            <div class="button-container" fxLayout="row"
                 fxLayoutGap="15px" fxLayoutAlign="space-between end">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious>
                  {{'general.buttons.back'|translate}}
                </button>
              </div>
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
                <ng-container *ngIf="!isEditMode" [ngTemplateOutlet]="saveAndNewButton"></ng-container>
                <button *ngIf="!isEditMode"
                        mat-raised-button
                        matStepperNext
                        [disabled]="!isValid()">
                  {{'general.buttons.continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <!-- ATTACHMENTS -->
        <mat-step *ngIf="!isEditMode">
          <ng-template matStepLabel>{{'general.attachment.pl'|translate}}</ng-template>
          <div class="transportation-task-stepper-content"
               fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="transportation-task-dialog-base-data transportation-attachment-data"
                 fxLayout="row" fxLayoutAlign="center center" >
              <div fxFlex="60" fxFlexAlign="stretch"
                   fxLayout="column" fxLayoutAlign="start stretch">
                <bh-file-upload
                    uploaderType="TRANSPORT_TASK_ADD_EDIT"
                    (onPendingUploadStatusChanged)="onPendingUploadStatusChanged($event)"
                    (onFileUploaded)="onFileUploaded($event)">
                </bh-file-upload>
                <div fxLayout="column" fxLayoutGap="5px">
                  <bh-file *ngFor="let document of documents" [file]="document" (fileRemove)="removeDocument(document)">
                  </bh-file>
                </div>
              </div>
            </div>

            <div class="button-container" fxLayout="row"
                 fxLayoutGap="15px" fxLayoutAlign="space-between end">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious>
                  {{'general.buttons.back'|translate}}
                </button>
              </div>
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
                <ng-container [ngTemplateOutlet]="saveAndNewButton"></ng-container>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>

<div class="spinner-wrapper" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
  <mat-spinner [strokeWidth]="5" [diameter]="140"></mat-spinner>
</div>

<ng-template #transportAddressOption let-item="item">
  <div class="transport-address-option" fxLayout="row" fxLayoutAlign="start center" data-osp-test="option-transport-address">
    <span class="transport-address-option__icon">
      <bh-icon-resolver [iconPayload]="resolveIcon(item)"></bh-icon-resolver>
    </span>
    <div class="transport-address-option__info">
      <div class="transport-address-option__info__line">
        {{item | formatTitleFromTransportItem}}
      </div>
      <div class="transport-address-option__info__line"
           *ngIf="(item | formatAddressFromTransport) !== null">
        {{item | formatAddressFromTransport}}
      </div>
    </div>
  </div>
</ng-template>


<ng-template #saveButton>
  <button mat-raised-button
    color="primary"
    (click)="save()"
    [disabled]="!isValid() || isLoading"
    data-osp-test="button-save">
  {{'general.buttons.save'|translate}}
  </button>
</ng-template>

<ng-template #saveAndNewButton>
  <button mat-raised-button
    color="primary"
    (click)="saveAndNew()"
    [disabled]="!isValid() || isLoading"
    data-osp-test="button-save-and-new">
  {{'general.buttons.saveAndNew'|translate}}
  </button>
</ng-template>


