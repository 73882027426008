import { Pipe, PipeTransform } from '@angular/core';
import { ButtonEvent, ButtonType } from 'app/shared/modules/map/components/speed-dial/button-types';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { Modules } from 'app/shared/enums/modules.enum';


@Pipe({
  name: 'speedDialStates'
})
export class SpeedDialStatesPipe implements PipeTransform {

  private canSeeEquipments = this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]) &&
    this.authService.hasModule(Modules.BASE);

  private canSeeProjects = this.authService.hasAuthority(Authorities.PROJECT_VIEW) &&
    this.authService.hasModule(Modules.DISPOSITION);

  private canSeeContacts = this.authService.hasAnyAuthority([Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]) &&
    this.authService.hasModule(Modules.CONTACT_MANAGEMENT);

  private canSeeStocks = this.authService.hasAuthority(Authorities.STOCK_VIEW) &&
    this.authService.hasModule(Modules.BASE) &&
    (this.authService.hasModule(Modules.DISPOSITION) || this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT));

  private canSeeGeofences = this.authService.hasAuthority(Authorities.GEOFENCE_VIEW) &&
    this.authService.hasModule(Modules.LIVE_DATA);

  constructor(private authService: KeycloakService) {}

  public transform(mapSettings: MapConfiguration): ButtonEvent[] {
    return [
      {
        type: ButtonType.EQUIPMENT,
        value: mapSettings.showEquipments && this.canSeeEquipments,
        hide: !this.canSeeEquipments,
      },
      {
        type: ButtonType.PROJECT,
        value: mapSettings.showProjects && this.canSeeProjects,
        hide: !this.canSeeProjects,
      },
      {
        type: ButtonType.ORGANISATION,
        value: mapSettings.showOrganisations,
        hide: false,
      },
      {
        type: ButtonType.CONTACT,
        value: mapSettings.showContacts && this.canSeeContacts,
        hide: !this.canSeeContacts,
      },
      {
        type: ButtonType.STOCK,
        value: mapSettings.showStocks && this.canSeeStocks,
        hide: !this.canSeeStocks,
      },
      {
        type: ButtonType.GEOFENCE,
        value: false,
        hide: !this.canSeeGeofences,
      }
    ];
  }

}
