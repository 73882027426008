<div class="content" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="maintenance-card entity-creation" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="card-title-panel">
        <mat-icon class="card-title-icon maintenance-list-icon">assignment_turned_in</mat-icon>
        <span class="maintenance-card-title">
          {{ disabled
          ? maintenanceRule?.ruleName + ' - ' + ('general.details'|translate)
          : ('shared.maintenance.rules.createNewRule'|translate) }}
        </span>
        <div *ngIf="disabled" class="dialog-close-icon">
          <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
            <mat-icon aria-hidden="true">clear</mat-icon>
          </button>
        </div>
      </div>
      <div class="entity-creation-actions">
        <button *ngIf="!disabled"
                [disabled]="!isValid() || savedForm"
                color="primary"
                class="create-button"
                name="create-button"
                bhTimeoutSaveButton
                mat-raised-button
                (throttledClick)="save()">
          {{'general.buttons.saveAndClose'|translate}}
        </button>
        <button mat-raised-button *ngIf="!disabled" (click)="navigateBack()">{{'general.buttons.cancel'|translate}}</button>
        <button mat-raised-button *ngIf="!disabled" (click)="form.reset()">{{'general.buttons.resetData'|translate}}</button>
      </div>
      <form [formGroup]="form" class="entity-creation-form">
        <div class="entity-creation-form-left">
          <div class="entity-creation-form-title">
            {{'general.generalData'|translate|uppercase}}
          </div>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start">
            <div class="type-radio-panel" fxFlex="25">
              <div class="type-icon-panel">
                <p>{{'general.type'|translate|uppercase}} *</p>
                <div class="type-icon-container">
                  <mat-icon class="maintenance-list-icon">{{evaluateTypeIcon()}}</mat-icon>
                </div>
              </div>
              <div>
                <mat-radio-group formControlName="category" aria-label="Select an option" required>
                  <mat-radio-button color="primary" [value]="MaintenanceCategory.CHECK">
                    {{'general.inspection.s'|translate}}
                  </mat-radio-button>
                  <mat-radio-button color="primary" [value]="MaintenanceCategory.CHECK_AND_REPAIR">
                    {{'general.maintenance.s'|translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <mat-form-field fxFlex="25" class="maintenance-rule-input">
              <mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
              <input matInput
                     autocomplete="off"
                     bhRemoveWhitespaces
                     [formControl]="name"
                     required
                     [maxLength]="fieldLimit.MEDIUM_IDENTIFIER"
                     data-osp-test="input-name">
              <mat-error *ngIf="form.get('name').hasError('nameInUse')">
                {{'shared.validation.alreadyAssigned'|translate:{value: 'general.name.s'|translate} }}
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('required')" data-osp-test="error-required-name">
                {{'shared.validation.requiredField'|translate }}
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('maxlength')" data-osp-test="error-max-length-name">
                {{'shared.validation.string.maxLength'|translate: { value: fieldLimit.MEDIUM_IDENTIFIER } }}
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                {{'shared.validation.string.trimSpaces'|translate|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="25" class="maintenance-rule-input">
              <mat-label>{{'general.serviceType.s'|translate}}</mat-label>
              <mat-select required formControlName="maintenanceTypeId">
                <mat-option *ngFor="let maintenanceType of maintenanceTypes | async" [value]="maintenanceType.id">
                  {{maintenanceType.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('maintenanceTypeId').hasError('required')" data-osp-test="error-maintenance-type-id-required-field">
                {{'shared.validation.requiredField'|translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="25" class="maintenance-rule-input">
              <mat-label>{{'shared.maintenance.rules.itemGroup'|translate|uppercase}}</mat-label>
              <input matInput
                autocomplete="off"
                bhRemoveWhitespaces
                [formControl]="articleGroup"
                [maxLength]="fieldLimit.MEDIUM_IDENTIFIER"
                data-osp-test="input-article-group">
                <mat-error *ngIf="form.get('articleGroup').hasError('maxlength')" data-osp-test="error-max-length-article-group">
                  {{'shared.validation.string.maxLength'|translate: { value: fieldLimit.MEDIUM_IDENTIFIER } }}
                </mat-error>
            </mat-form-field>
            <mat-autocomplete #articleGroupAuto="matAutocomplete">
              <mat-option *ngFor="let articleGroup of articleGroups | async"
                          [value]="articleGroup">
                {{ articleGroup }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div>
            <mat-form-field class="maintenance-rule-input">
              <mat-label>{{'general.description'|translate|uppercase}}</mat-label>
              <textarea matInput
                        class="maintenance-rule-description"
                        autocomplete="off"
                        formControlName="description"
                        [maxlength]="fieldLimit.LONG_DESCRIPTION"
                        data-osp-test="textarea-description">
              </textarea>
              <mat-error *ngIf="form.get('description').hasError('maxlength')" data-osp-test="error-max-length-description">
                {{'shared.validation.string.maxLength'|translate: { value: fieldLimit.LONG_DESCRIPTION } }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <mat-divider></mat-divider>
        </div>
        <div class="entity-creation-form-right">
          <div class="check-iteration-panel" fxLayout="row" fxLayoutGap="15px">
            <div class="entity-creation-form-title">
              {{'shared.maintenance.rules.whenShouldInspectionBeTaken'|translate|uppercase}} *
            </div>
            <div class="maintenance-type-field">
              <mat-checkbox [formControl]="intervalCountToggle" color="primary" required>
                {{'shared.maintenance.rules.ruleRepeatedCertainNumberOfTimes'|translate}}
              </mat-checkbox>
            </div>
            <div class="check-iteration-count">
              <mat-form-field class="maintenance-rule-input">
                <mat-label>{{'shared.maintenance.rules.repetitionsNumber'|translate}}</mat-label>
                <input matInput
                       type="number"
                       autocomplete="off"
                       formControlName="taskLimit"
                       min="1"
                       required>
              </mat-form-field>
            </div>
          </div>
          <span class="quiet">{{'shared.maintenance.rules.intervalPanelTitle'|translate}}</span>
          <div class="intervals-panel maintenance-type-field" fxLayout="row" fxLayoutGap="15px">
            <bh-interval-panel class="interval-panel"
                               [inputType]="inputTypes.DATE"
                               [maintenanceRule]="maintenanceRule"
                               [intervalGroup]="fixedDateInterval"
                               [disabled]="disabled"
                               [nextFixedDateThreshold]="nextFixedDateThreshold"
                               [checkboxLabel]="'shared.maintenance.rules.alwaysOnSpecificDate'|translate"
                               [inputLabel]="'shared.maintenance.rules.dueDate'|translate"
                               [warningSuffix]="'shared.maintenance.rules.daysBeforeExpiration'|translate">
            </bh-interval-panel>

            <bh-interval-panel class="interval-panel"
                               [inputType]="inputTypes.PERIOD"
                               [maintenanceRule]="maintenanceRule"
                               [intervalGroup]="periodInterval"
                               [disabled]="disabled"
                               [nextPeriodThreshold]="nextPeriodThreshold"
                               [checkboxLabel]="'shared.maintenance.rules.afterTimeInterval'|translate"
                               [inputLabel]="'shared.maintenance.rules.dueInterval'|translate"
                               [inputSuffix]="'general.units.time.month.pl'|translate"
                               [warningSuffix]="'shared.maintenance.rules.daysBeforeExpiration'|translate">
            </bh-interval-panel>

            <bh-interval-panel class="interval-panel"
                               [inputType]="inputTypes.NUMBER"
                               [maintenanceRule]="maintenanceRule"
                               [intervalGroup]="operatingHoursInterval"
                               [disabled]="disabled"
                               [nextOperatingHoursThresholdDate]="nextOperatingHoursThresholdDate"
                               [checkboxLabel]="'shared.maintenance.rules.afterOperatingHoursExpired'|translate"
                               [inputLabel]="'general.operatingHour.pl'|translate"
                               [inputSuffix]="'general.operatingHour.pl'|translate"
                               [warningSuffix]="'shared.maintenance.rules.beforeOperatingHoursExpired'|translate">
            </bh-interval-panel>

            <bh-interval-panel class="interval-panel"
                               [inputType]="inputTypes.NUMBER"
                               [maintenanceRule]="maintenanceRule"
                               [intervalGroup]="mileageInterval"
                               [disabled]="disabled"
                               [nextMileageThresholdDate]="nextMileageThresholdDate"
                               [checkboxLabel]="'shared.maintenance.rules.afterMileageExpired'|translate"
                               [inputLabel]="'shared.maintenance.rules.mileage'|translate"
                               [inputSuffix]="'km'|dimensionUnitDisplay"
                               warningSuffix="{{'shared.maintenance.rules.beforeMileageExpired'|translate}} ({{'km'|dimensionUnitDisplay}})">
            </bh-interval-panel>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
