import { OrganisationContact } from './organisation-contact.interface';
import { Address } from './address.interface';

export class UpdateOrganisationCommand {
  organisationId: string;
  organisationName: string;
  organisationNumber: string;
  organisationType: string;
  organisationContact: OrganisationContact;
  organisationAddress: Address;
}
