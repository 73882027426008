<div id="dialog" class="dialog-wrapper">
  <h1 mat-dialog-title>{{'shared.maintenance.rules.lastMaintenanceInspection'|translate}}</h1>
  <div mat-dialog-content>
    <div id="dialog-content">
      <p>{{'shared.maintenance.rules.defineLastMaintenanceInspection'|translate}}</p>
      <form [formGroup]="form">
        <div *ngIf="periodIntervalActive" fxLayout="column" fxLayoutGap="10px">
          <div>{{'general.units.time.moment'|translate}}</div>
          <mat-form-field floatLabel="always">
            <mat-label>{{'general.units.time.moment'|translate|uppercase}}</mat-label>
            <input matInput
                   required
                   formControlName="periodCalculationBase" autocomplete="off"
                   [matDatepicker]="periodStartPicker"
                   [placeholder]="'general.units.time.moment'|translate">
            <mat-datepicker-toggle matSuffix [for]="periodStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #periodStartPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="mileageIntervalActive" fxLayout="column" fxLayoutGap="10px">
          <div>{{'general.mileage'|translate}}</div>
          <mat-form-field floatLabel="always">
            <mat-label>{{'general.mileage'|translate|uppercase}}</mat-label>
            <input matInput
                   type="number"
                   formControlName="mileageCalculationBase"
                   [placeholder]="'general.mileage'|translate">
          </mat-form-field>
        </div>
        <div *ngIf="operatingHoursIntervalActive" fxLayout="column" fxLayoutGap="10px">
          <div>{{'general.operatingHour.pl'|translate}}</div>
          <mat-form-field floatLabel="always">
            <mat-label>{{'general.operatingHour.pl'|translate|uppercase}}</mat-label>
            <input matInput
                   type="number"
                   formControlName="operatingHoursCalculationBase"
                   [placeholder]="'general.operatingHour.pl'|translate">
          </mat-form-field>
        </div>
      </form>
      </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onCancel()">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="form.value" [disabled]="form.invalid || form.disabled">{{'general.buttons.save'|translate}}</button>
  </div>
</div>
