import { ScheduleInformation } from '../schedule-information';
import { MailConnectionInformation } from './mail-connection-information';
import { ScheduledConnectorCommand } from '../scheduled-connector/scheduled-connector-command';

export class UpdateMailConnectorCommand extends ScheduledConnectorCommand {

  schedule: ScheduleInformation;
  connection: MailConnectionInformation;
}

