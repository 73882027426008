import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionResolver } from '../card-select/option.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { LocalUserStorageService } from '../../services/local-user-storage.service';

@Component({
  selector: 'bh-horizontal-tab-select',
  templateUrl: './horizontal-tab-select.component.html',
  styleUrls: ['./horizontal-tab-select.component.scss']
})
export class HorizontalTabSelectComponent implements OnInit {

  @Input() options: string[];
  @Input() optionResolver: OptionResolver;

  @Output() optionSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

  private readonly localStorageKey: string = 'disposition-board-assets-tab';
  public selectedOptionIndex = 0;

  constructor(private localStorageService: LocalUserStorageService) {
  }

  ngOnInit(): void {
    const selectedTab = Number(this.localStorageService.getUserValue(this.localStorageKey));
    if (selectedTab >= 0 && selectedTab < this.options.length) {
      this.selectedOptionIndex = selectedTab;
    } else {
      this.selectedOptionIndex = 0;
      this.localStorageService.setUserValue(this.localStorageKey, this.selectedOptionIndex.toString());
    }
    this.optionSelectionChanged.emit(this.options[this.selectedOptionIndex]);
  }

  public selectOption(option: string): void {
    this.selectedOptionIndex = this.options.indexOf(option);
    this.selectedOptionIndex = this.selectedOptionIndex >= 0 ? this.selectedOptionIndex : 0;
    this.localStorageService.setUserValue(this.localStorageKey, this.selectedOptionIndex.toString());

    this.optionSelectionChanged.emit(option);
  }

  public resolveOptionIcon(option: string): IconDefinition {
    return this.optionResolver.resolveIcon(option);
  }

  public resolveOptionName(option: string): string {
    return this.optionResolver.resolveName(option);
  }

  public isSelected(option: string): boolean {
    return option === this.options[this.selectedOptionIndex];
  }

}
