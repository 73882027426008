import { EquipmentMultiseriesItem } from './equipment-multiseries-item';
import * as moment from 'moment';

export class EquipmentTelematicsMultiseries {
  public static fromData(data: EquipmentTelematicsMultiseries) {
    return new EquipmentTelematicsMultiseries(
        data.dailyOperatingHoursMultiSeries,
        data.externalVoltageStatusMultiSeries
    );
  }

  private resolveDate(value: string): string {
    if (value.startsWith('KW ')) {
      return value;
    }
    if (moment(value, 'YYYY-MM', true).isValid()) {
      return moment(value, 'YYYY-MM').format('MMM YYYY');
    }
    return moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY');
  }

  constructor(
      public dailyOperatingHoursMultiSeries?: EquipmentMultiseriesItem[],
      public externalVoltageStatusMultiSeries?: EquipmentMultiseriesItem[]) {

    if (dailyOperatingHoursMultiSeries) {
      dailyOperatingHoursMultiSeries.forEach((multiSeries: EquipmentMultiseriesItem) => {
        multiSeries.name = this.resolveDate(multiSeries.name);
      });
    }

    if (externalVoltageStatusMultiSeries) {
      externalVoltageStatusMultiSeries.forEach((multiSeries: EquipmentMultiseriesItem) => {
        multiSeries.name = this.resolveDate(multiSeries.name);
      });
    }
  }
}
