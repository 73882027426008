import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GeofenceBuilder } from './geofence-builder';
import { GeofenceListItem } from './geofence-list-item';
import { GenericGeofence } from '../../../../contract/generic-geofence.interface';
import { GenericCreateGeofenceCommand } from './generic-create-geofence-command';
import { GeofenceNameExistValidator } from '../../../../custom-validators/geofence-name-exist.validator';
import { FieldLimit } from '../../../../enums/fieldLimit.enum';
import { GenericUpdateGeofenceCommand } from './generic-update-geofence-command';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';

@Component({
  selector: 'bh-geofence-sidebar',
  templateUrl: 'geofence-sidebar.component.html',
  styleUrls: ['geofence-sidebar.component.scss']
})
export class GeofenceSidebarComponent implements OnInit {

  @Input() map: any;
  @Input() createGeofenceEnabled = false;
  @Input() editGeofenceEnabled = false;
  @Input() deleteGeofenceEnabled = false;
  @Input() manageAlarmsEnabled = false;
  @Input() displayAlarmIndicators = false;
  @Input() tooltipTextAdd = this.translate('shared.geofence.addGeofence');
  @Input() tooltipTextEdit = this.translate('shared.geofence.editGeofence');
  @Input() tooltipTextDelete = this.translate('shared.geofence.removeGeofence');

  @Output() onCreate: EventEmitter<GenericCreateGeofenceCommand> = new EventEmitter<GenericCreateGeofenceCommand>();
  @Output() onDelete: EventEmitter<GeofenceListItem> = new EventEmitter<GeofenceListItem>();
  @Output() onSetAlarm: EventEmitter<string> = new EventEmitter<string>();
  @Output() onRemoveAlarm: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEdit: EventEmitter<GenericUpdateGeofenceCommand> = new EventEmitter<GenericUpdateGeofenceCommand>();

  public readonly fieldLimit = FieldLimit;

  public createModeActive = false;
  public editModeActive = false;
  public deleteModeActive = false;
  public isLoading = false;
  public geofenceListItems: GeofenceListItem[] = [];
  public createForm: UntypedFormGroup;
  public colors: { name: string, hex: string }[];
  private defaultColor = '#d50000';
  private hideGeofences = false;
  private editingGeofence: GeofenceListItem = null;
  private readonly tooltipTextShowAll = this.translate('shared.geofence.showAll');
  private readonly tooltipTextHideAll = this.translate('shared.geofence.hideAll');
  private readonly tooltipTextNoGeofencesAvailable = this.translate('shared.geofence.noGeofencesAvailable');

  constructor(private formBuilder: UntypedFormBuilder, private languageService: LanguageService) {
  }

  public get hasAnyActiveMode(): boolean {
    return this.createModeActive || this.deleteModeActive || this.editModeActive;
  }

  public get hasSelectedGeofenceForEdit(): boolean {
    return this.editModeActive && Boolean(this.editingGeofence);
  }

  public get geofenceName(): AbstractControl {
    return this.createForm.get('geofenceName');
  }
  public get coordsOfNewGeofence(): google.maps.LatLng[] {
    return GeofenceBuilder.coords;
  }

  public get closePathButtonEnabled(): boolean {
    return !GeofenceBuilder.pathClosed && GeofenceBuilder.coords.length > 2;
  }

  public get showAllTooltip(): string {
    return this.getGeofenceTooltip(this.tooltipTextShowAll);
  }

  public get hideAllTooltip(): string {
    return this.getGeofenceTooltip(this.tooltipTextHideAll);
  }

  public validGeofence(): boolean {
    return GeofenceBuilder.validShape;
  }

  public invalidGeofence(): boolean {
    return GeofenceBuilder.pathClosed && !GeofenceBuilder.validShape;
  }


  ngOnInit(): void {
    this.buildForm();
  }

  public updateGeofences(geofences: GenericGeofence[], centerMap: boolean): void {
    this.removeAllGeofences();
    this.geofenceListItems = geofences.map(geofence => ({
      id: geofence.id,
      name: geofence.name,
      alarming: geofence.alarming,
      readOnly: geofence.readOnly,
      link: geofence.link,
      polygon: this.buildPolygon(geofence.coordinates, geofence.color),
    }));

    if (!this.geofenceListItems || this.geofenceListItems.length === 0) {
      this.deleteModeActive = false;
    }

    this.showAllGeofences(centerMap);
    this.updateValidators();
  }

  public save(): void {
    if (this.createForm.valid && this.validGeofence()) {
      const geofenceData = new GenericCreateGeofenceCommand();
      geofenceData.geofenceName = this.geofenceName.value;
      geofenceData.geofenceColor = GeofenceBuilder.color;
      geofenceData.coordinates = [
        ...GeofenceBuilder.coords,
        GeofenceBuilder.coords[0]
      ].map(coord => ({ lat: coord.lat(), lon: coord.lng() }));

      if (this.createModeActive) {
        this.create(geofenceData);
      } else if (this.hasSelectedGeofenceForEdit) {
        this.update({ ...geofenceData, geofenceId: this.editingGeofence.id });
      }
    }
  }

  public cancel(): void {
    this.reset();
  }

  private create(cmd: GenericCreateGeofenceCommand): void {
    this.onCreate.emit(cmd);
    this.leaveCreateMode();
  }

  private update(cmd: GenericUpdateGeofenceCommand): void {
    this.editingGeofence = {
      ...this.editingGeofence,
      id: cmd.geofenceId,
      name: cmd.geofenceName,
      polygon: this.buildPolygon(cmd.coordinates, cmd.geofenceColor)
    };

    this.geofenceListItems = this.geofenceListItems
      .map(item => item.id === this.editingGeofence.id ? this.editingGeofence : item);
    this.onEdit.emit(cmd);
    this.editingGeofence = null;
    this.leaveEditMode();
  }

  public delete(geofenceListItem: GeofenceListItem): void {
    this.onDelete.emit(geofenceListItem);
  }

  public setAlarm(geofenceListItem: GeofenceListItem): void {
    this.onSetAlarm.emit(geofenceListItem.id);
  }

  public removeAlarm(geofenceListItem: GeofenceListItem): void {
    this.onRemoveAlarm.emit(geofenceListItem.id);
  }

  public enterCreateMode(): void {
    this.createModeActive = true;
    this.hideAllGeofences(this.hideGeofences);
    GeofenceBuilder.start(this.map);
  }

  public leaveCreateMode(): void {
    GeofenceBuilder.reset();
    this.createForm.reset({
      geofenceColor: this.defaultColor,
    });
    this.createModeActive = false;
    this.refreshGeofences();
  }

  public enterEditMode(): void {
    this.editModeActive = true;
  }

  public beginGeofenceEdit(geofenceListItem: GeofenceListItem): void {
    this.editingGeofence = geofenceListItem;
    this.patchForm(this.editingGeofence);
    this.updateValidators();
    GeofenceBuilder.edit(this.editingGeofence.polygon);
  }

  public leaveEditMode(): void {
    if (this.editingGeofence) {
      GeofenceBuilder.cancelEdit();
      this.editingGeofence = null;
    } else {
      GeofenceBuilder.reset();
    }
    this.createForm.reset({
      geofenceColor: this.defaultColor,
    });
    this.updateValidators();
    this.editModeActive = false;
    this.refreshGeofences();
  }

  public enterDeleteMode(): void {
    this.deleteModeActive = true;
  }

  public leaveDeleteMode(): void {
    this.deleteModeActive = false;
  }

  public reset(): void {
    if (this.editModeActive) {
      this.leaveEditMode();
    }
    if (this.createModeActive) {
      this.leaveCreateMode();
    }
    if (this.deleteModeActive) {
      this.leaveDeleteMode();
    }
  }

  public showSingleGeofence(geofenceListItem: GeofenceListItem): void {
    this.hideAllGeofences(this.hideGeofences);
    geofenceListItem.polygon.setVisible(true);
    let bounds = new google.maps.LatLngBounds();
    geofenceListItem.polygon.getPath().forEach((coord: google.maps.LatLng) => bounds.extend(coord));
    if (this.map) {
      this.map.panToBounds(bounds);
      this.map.fitBounds(bounds);
    }
  }

  public showAllGeofences(centerMap: boolean): void {
    this.hideGeofences = false;
    if (this.geofenceListItems.length > 0) {
      let bounds = new google.maps.LatLngBounds();
      this.geofenceListItems.forEach(geofenceListItem => {
        geofenceListItem.polygon.setVisible(true);
        geofenceListItem.polygon.getPath().forEach((coord: google.maps.LatLng) => bounds.extend(coord));
      });
      if (this.map && centerMap) {
        this.map.panToBounds(bounds);
        this.map.fitBounds(bounds);
      }
    }
  }

  public hideAllGeofences(preserveState?: boolean): void {
    this.hideGeofences = preserveState ? preserveState : true;
    this.geofenceListItems.forEach(geofenceListItem => {
      if(geofenceListItem.id !== this.editingGeofence?.id) {
        geofenceListItem.polygon.setVisible(false);
      }
    });
  }

  public closePath(): void {
    GeofenceBuilder.closePath();
  }

  public setLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  private buildForm(): void {
    this.colors = [
      {name: this.translate('general.colors.red'), hex: '#d50000'},
      {name: this.translate('general.colors.blue'), hex: '#2962ff'},
      {name: this.translate('general.colors.green'), hex: '#00c853'},
      {name: this.translate('general.colors.yellow'), hex: '#ffff8d'},
      {name: this.translate('general.colors.orange'), hex: '#f57c00'},
      {name: this.translate('general.colors.lightBlue'), hex: '#0091ea'},
      {name: this.translate('general.colors.turquoise'), hex: '#00bfa5'},
      {name: this.translate('general.colors.cyan'), hex: '#00b8d4'},
      {name: this.translate('general.colors.purple'), hex: '#aa00ff'},
      {name: this.translate('general.colors.darkPurple'), hex: '#6a1b9a'},
      {name: this.translate('general.colors.lightGreen'), hex: '#64dd17'},
      {name: this.translate('general.colors.yellowGreen'), hex: '#aeea00'},
      {name: this.translate('general.colors.brown'), hex: '#795548'},
      {name: this.translate('general.colors.gray'), hex: '#757575'},
      {name: this.translate('general.colors.graublau'), hex: '#607d8b'}
    ];
    let geofenceColor = new UntypedFormControl(this.defaultColor);
    this.createForm = this.formBuilder.group({
      geofenceName: [null, Validators.required],
      geofenceColor: geofenceColor
    });
    geofenceColor.valueChanges.subscribe((newColor: string) => GeofenceBuilder.color = newColor);
  }

  private removeAllGeofences(): void {
    this.geofenceListItems.forEach(geofenceListItem =>
        geofenceListItem.polygon.setMap(null)
    );
  }

  private updateValidators(): void {
    const names = this.geofenceListItems
      .filter(geofence => geofence.id !== this.editingGeofence?.id)
      .map(geofence => geofence.name);
    this.geofenceName.setValidators(GeofenceNameExistValidator.isValid(names));
  }

  private getGeofenceTooltip(message: string): string {
    return this.geofenceListItems?.length > 0 ? message : this.tooltipTextNoGeofencesAvailable;
  }

  private refreshGeofences(): void {
    if (!this.hideGeofences) {
      this.showAllGeofences(false);
    }
  }

  private buildPolygon(coords: LatLon[], color: string): google.maps.Polygon {
    const coordinates = coords[0].lat === coords[coords.length-1].lat && coords[0].lon === coords[coords.length-1].lon
      ? coords.slice(0, -1)
      : coords;
    return new google.maps.Polygon({
      paths: coordinates.map(coord => ({lat: coord.lat, lng: coord.lon})),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.5,
      map: this.map
    })
  }

  private patchForm(geofenceListItem: GeofenceListItem): void {
    this.createForm.patchValue({
      geofenceName: geofenceListItem.name,
      geofenceColor: geofenceListItem.polygon.get('fillColor'),
    })
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
