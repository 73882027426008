import { LanguageService } from 'app/shared/services/language.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewEquipmentEmployeeAssignment } from '../../../../../contract/view-equipment-employee-assignment.interface';
import { EquipmentEmployeeRole } from '../../../../../contract/equipment-employee-role.enum';
import * as moment from 'moment';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCoins, faIdCard, faKey, faSteeringWheel, faTools, faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { EquipmentEmployeeAssignmentViewDialogComponent } from '../../../../../shared/equipment-employee-assignment-view-dialog/equipment-employee-assignment-view-dialog.component';
import { EquipmentEmployeeAssignmentManageDialogComponent } from '../../../../../shared/equipment-employee-assignment-manage-dialog/equipment-employee-assignment-manage-dialog.component';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { RemoveEquipmentEmployeeAssignmentCommand } from '../../../../../contract/remove-equipment-employee-assignment-command';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-equipment-employee-assignee-cards',
  templateUrl: './equipment-employee-assignee-cards.component.html',
  styleUrls: ['./equipment-employee-assignee-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentEmployeeAssigneeCardsComponent extends GuardedNavigableInputComponent implements OnInit {

  public readonly faUsers: IconDefinition = faUsers;

  public employeeAssignments: ViewEquipmentEmployeeAssignment[] = [];

  public get allowAddEdit(): boolean {
    return this.hasAuthority(Authorities.EQUIPMENT_MANAGE_EMPLOYEES);
  }

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToEmployeeAssignments();
  }

  public openViewAssignmentDialog(employeeAssignment: ViewEquipmentEmployeeAssignment): void {
    let dialogRef = this.dialog.open(EquipmentEmployeeAssignmentViewDialogComponent);
    dialogRef.componentInstance.employeeAssignment = employeeAssignment;
    dialogRef.componentInstance.icon = this.getRoleIcon(employeeAssignment.employeeRole);
  }

  public openAddAssignmentDialog(): void {
    let dialogRef = this.dialog.open(EquipmentEmployeeAssignmentManageDialogComponent);
    dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
  }

  public openReplaceAssignmentDialog(employeeAssignment: ViewEquipmentEmployeeAssignment): void {
    let dialogRef = this.dialog.open(EquipmentEmployeeAssignmentManageDialogComponent);
    dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
    dialogRef.componentInstance.icon = this.getRoleIcon(employeeAssignment.employeeRole);
    dialogRef.componentInstance.replaceMode = true;
    dialogRef.componentInstance.assignment = employeeAssignment;
  }

  public openEditAssignmentDialog(employeeAssignment: ViewEquipmentEmployeeAssignment): void {
    let dialogRef = this.dialog.open(EquipmentEmployeeAssignmentManageDialogComponent);
    dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
    dialogRef.componentInstance.icon = this.getRoleIcon(employeeAssignment.employeeRole);
    dialogRef.componentInstance.editMode = true;
    dialogRef.componentInstance.assignment = employeeAssignment;
  }

  public openDeleteAssignmentDialog(employeeAssignment: ViewEquipmentEmployeeAssignment): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.equipmentDetailGeneral.deleteAssignmentConfirm');
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        const command: RemoveEquipmentEmployeeAssignmentCommand = new RemoveEquipmentEmployeeAssignmentCommand(
            employeeAssignment.equipmentId,
            employeeAssignment.employeeAssignmentId,
            employeeAssignment.employeeId,
            employeeAssignment.employeeRole);
        this.equipmentStore.removeEmployeeAssignment(command);
      }
    });
  }

  public getRoleIcon(role: EquipmentEmployeeRole): IconDefinition {
    switch (role) {
      case EquipmentEmployeeRole.OWNER:
        return faCoins;
      case EquipmentEmployeeRole.POSSESSOR:
        return faKey;
      case EquipmentEmployeeRole.PERSON_IN_CHARGE:
        return faIdCard;
      case EquipmentEmployeeRole.DRIVER:
        return faSteeringWheel;
      case EquipmentEmployeeRole.MAINTENANCE_ASSIGNEE:
        return faTools;
      default:
        return null;
    }
  }

  public getPhoneNumber(employeeAssignment: ViewEquipmentEmployeeAssignment): string {
    const phone = employeeAssignment.employeeOfficePhoneNumber || employeeAssignment.employeeOfficeMobileNumber;
    return phone  || '-';
  }

  public getDateQualifier(date: Date): string {
    return moment(date).isBefore(moment())
      ? this.translate('general.labels.dateSince')
      : this.translate('general.labels.dateFrom');
  }

  private subscribeToEmployeeAssignments(): void {
    this.equipmentStore.employeeAssignments.subscribe(employeeAssignments => {
      if (employeeAssignments) {
        this.employeeAssignments = employeeAssignments;
        this.cdr.markForCheck();
      }
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
