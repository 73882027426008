import { LanguageService } from 'app/shared/services/language.service';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bh-notification-toggle',
  template: `<input type="checkbox" [value]="value" [attr.checked]="value" hidden/>
  <mat-icon #icon
            (click)="toggle()"
            [matTooltip]="tooltip"
            matTooltipClass="mat-tooltip-multiline"
            [ngClass]="!value ? 'enabled' : 'disabled'">email</mat-icon>`,
  styles: [
    '@keyframes growShrink {0% {transform: scale(1);} 50% {transform: scale(1.2);} 100% {transform: scale(1)}}',
    'mat-icon {transition: color 400ms ease-out; user-select: none}',
    '.disabled {color: #bfbfbf;}',
    '.enabled {color: #a4d313;}',
    '.anim {animation: growShrink 400ms ease-out;}'
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NotificationToggleComponent),
    multi: true
  }]
})
export class NotificationToggleComponent implements ControlValueAccessor, OnInit {
  @Input('value') _value: boolean;
  @Input() tooltip: string;
  @Input() toggleable = true;

  @ViewChild('icon', { static: true }) icon: MatIcon;

  private toggleTooltip = `${this.translate('modules.disposition.notificationToggle.toggleTooltip.info')}
      ${this.translate('modules.disposition.notificationToggle.toggleTooltip.notificationOn')}
      ${this.translate('modules.disposition.notificationToggle.toggleTooltip.notificationOff')}`;
  private toggleActiveTooltip = this.translate('modules.disposition.notificationToggle.toggleActiveTooltip');
  private toggleInactiveTooltip = this.translate('modules.disposition.notificationToggle.toggleInactiveTooltip');

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private languageService: LanguageService) {}

  ngOnInit() {
    this.tooltip = this.evaluateTooltip();
  }

  toggle(): void {
    if (this.toggleable) {
      this.icon._elementRef.nativeElement.classList.remove('anim');
      this.icon._elementRef.nativeElement.focus();
      this.icon._elementRef.nativeElement.classList.add('anim');
      this.value = !this.value;
    }
  }

  private evaluateTooltip(): string {
    if (this.toggleable) {
      return this.toggleTooltip;
    } else {
      if (this._value) {
        this.tooltip = this.toggleActiveTooltip;
      } else {
        this.tooltip = this.toggleInactiveTooltip;
      }
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    this.value = value;
  }

}
