import { Pipe, PipeTransform } from '@angular/core';
import { ViewEquipment } from 'app/modules/equipment/contract/view-equipment.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapCircleOptions } from '../interfaces/map-circle-options.interface';

@Pipe({
  name: 'mapMarkerConvertEquipmentView'
})
export class MapMarkerConvertEquipmentViewPipe implements PipeTransform {

  public transform(value: ViewEquipment[]): MapLocationMarker<ViewEquipment>[] {
    return Array.isArray(value) ? this.getMapLocationMarkers(value) : null;
  }

  private getMapLocationMarkers(items: ViewEquipment[]): MapLocationMarker<ViewEquipment>[] {
    return items.filter(Boolean).map(item => ({
      id: item.equipmentId,
      location: item.location,
      item,
      circle: this.getMapCircleOptions(item)
    }));
  }

  private getMapCircleOptions(item: ViewEquipment): MapCircleOptions {
    return item.currentRssi
      ? {
          radius: this.calcBeaconCircleRadius(item.currentRssi),
          fillColor: '#0000FF',
          fillOpacity: 0.3,
          strokeColor: '#FFFFFF',
          strokeOpacity: 0.1,
          strokeWeight: 2
        }
      : null;
  }

  private calcBeaconCircleRadius(rssi: number): number {
    return Math.pow(10, this.calcBeaconRadiusMathPower(rssi));
  }

  private calcBeaconRadiusMathPower(currentRssi: number): number {
    const measuredPower = -59;
    const n = 2.5;
    return (measuredPower - currentRssi) / (10 * n);
  }

}
