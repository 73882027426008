import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewTechnicalField } from '../contract/view-technical-field.interface';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { UpdatedTechnicalFieldCommand } from '../contract/updated-technical-field-command';
import { CreateTechnicalFieldCommand } from '../contract/create-technical-field-command';
import { getHttpParameters } from 'app/shared/utils';

@Injectable()
export class TechnicalFieldsService {

  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/technicalfields';

  constructor(private http: HttpClient) {
  }

  getTechnicalFields(page = 0, size = 2000): Observable<PagedResponse<ViewTechnicalField>> {
    const pagingUrl = this.url + '?page=' + page + '&size=' + size;

    return this.http.get<PagedResponse<ViewTechnicalField>>(pagingUrl);
  }

  getTechnicalField(technicalFieldId: string): Observable<ViewTechnicalField> {
    return this.http.get<ViewTechnicalField>(this.url + '/' + technicalFieldId);
  }

  public getTechnicalFieldByTechnicalFieldKey(technicalFieldKey: string): Observable<ViewTechnicalField> {
    return this.http.get<ViewTechnicalField>(this.url + '/by-key/' + technicalFieldKey);
  }

  public updateTechnicalField(command: UpdatedTechnicalFieldCommand): Observable<string> {
    return this.http.post(`${this.url}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public saveTechnicalField(command: CreateTechnicalFieldCommand): Observable<string> {
    return this.http.post(`${this.url}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public technicalFieldInUse(technicalFieldId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/technical-field-in-use/' + technicalFieldId);
  }

  public keyExistsCheck(params: Object): Observable<boolean> {
    const httpParams = getHttpParameters(params);

    return this.http.get<boolean>(this.url + '/key-exists', {params: httpParams});
  }

  public nameExistsCheck(params: Object): Observable<boolean> {
    const httpParams = getHttpParameters(params);

    return this.http.get<boolean>(this.url + '/name-exists', {params: httpParams});
  }
}
