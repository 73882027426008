import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'defaultValue'})
export class DefaultValuePipe implements PipeTransform {

  transform(value, def): number {
    return value || def;
  }

}
