import { StockToProjectTransferItem } from './stock-to-project-transfer-item';

export class CreateStockToProjectTransferCartCommand {
  constructor(
    public targetProjectId: string,
    public transferItems: StockToProjectTransferItem[],
    public employeeId?: string,
    public externalEmployee?: string,
    public comment?: string,
    public transferDate?: Date) {}
}
