<div class="file" fxLayout="row">
  <span class="file-type">{{file | fileTypeName | uppercase}}</span>
  <span class="file-name">{{file.fileName}}</span>
  <div class="file-actions">
    <div *ngIf="fileExists() && downloadable"
         class="download-wrapper">
      <span>
        <mat-icon (click)="downloadDocument(file)"
                  [matTooltip]="'general.buttons.download'|translate">cloud_download</mat-icon>
      </span>
    </div>
    <button *ngIf="deletable" mat-icon-button (click)="removeFile()">
      <mat-icon class="file-icon" [matTooltip]="'general.buttons.delete'|translate">delete_forever</mat-icon>
    </button>
  </div>
</div>
