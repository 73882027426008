import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Languages } from 'app/shared/enums/languages.enum';
import * as Sentry from '@sentry/angular-ivy';

import { LanguageUrl } from './contract/language.interfaces';
import { LanguageMapper } from './enums/languages.enum';
import { LanguageService } from './services/language.service';
import { UserConfigurationService } from './services/user-configuration.service';
import { MapsConfig } from './modules/map/services/map-loader.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export const LANGUAGES = Array.from(new Set(Object.keys(LanguageMapper).map(key => key.toLowerCase())))

export function TranslateHttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new S3TranslateHttpLoader(http);
}

export class S3TranslateHttpLoader extends TranslateHttpLoader {

  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix);
  }

  public getTranslation(lang: string): Observable<Object> {
    if (lang === Languages.DEV) {
      // Weblate allow only file with this name as base translations file (dev-DEV replaced with en_devel)
      lang = 'en_devel';
    }

    return this.loadTranslationsFromS3(lang)
      .pipe(catchError(() => super.getTranslation(lang)));
  }

  private loadTranslationsFromS3(lang: string): Observable<Object> {
    return new Observable(subscriber => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', `${environment.LOCALIZE_API_BASE_URL}/${lang}.json`);
      xhr.setRequestHeader('x-api-key', environment.LOCALIZE_API_KEY);
      xhr.onload = () => {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
          subscriber.next(JSON.parse(xhr.response));
        } else {
          subscriber.error(xhr.statusText);
        }
      };
      xhr.onerror = () => subscriber.error(xhr.statusText);
      xhr.onloadend = () => subscriber.complete();
      xhr.send();
    });
  }
}

export function appInitializerTranslationFactory(languageService: LanguageService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const language = languageService.getUserLanguage();
      languageService.use(language).subscribe({
        next: () => {},
        error: () => Sentry.captureMessage(`Problem with '${language}' language initialization.`, 'warning'),
        complete: () => resolve(null)
      });
    });
  });
}

export function getLanguageFromUrl(originalUrl: string): LanguageUrl {
  const [urlHead, ...urlTail] = originalUrl.split('/').slice(1);
  return (urlHead && LANGUAGES.some(item => item === urlHead.toLowerCase()))
  ? {
    language: urlHead.toLowerCase(),
    urlTail: urlTail.join('/')
  }
  : {
    language: null,
    urlTail: [urlHead, ...urlTail || []].join('/')
  }
}

export function translateCompilerFactory(userConfigurationService: UserConfigurationService): CustomTranslateCompiler {
  return new CustomTranslateCompiler(userConfigurationService.getUserTranslateConfiguration().devPrefix);
}

export class CustomTranslateCompiler implements TranslateCompiler {
  constructor(private devLanguagePrefix = '') {
  }

  public compile(value: string, lang: string): string | Function {
    return value;
  }

  public compileTranslations(translations: any, lang: string): any {
    if (lang === Languages.DEV) {
      try {
        return translationTransformation(translations, this.devLanguagePrefix);
      } catch (exception) {
        Sentry.captureMessage(`Warning! Translations weren't converted: ${exception}`, 'warning');
      }
    }
    return translations;
  }
}

function translationTransformation(obj: any, symbol: string) {
  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key]: typeof obj[key] === 'string'
        ? `${symbol}${obj[key]}${symbol}`
        : translationTransformation(obj[key], symbol) }
  }, {})
}

export function googleMapsConfigFactory(apiKey: string): (ls: LanguageService) => MapsConfig {
  return (languageService: LanguageService): MapsConfig => {
    return {
      key: apiKey,
      ...(languageService ? { language: languageService.getCurrentLocale() } : {})
    }
  }
}

export function localeFactory(languageService: LanguageService): string {
  return languageService.getCurrentLocale();
}
