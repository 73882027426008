import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaintenanceRule } from '../../../maintenance/rules/shared/contract/maintenance-rule';
import { AssignMaintenanceRuleCommand } from '../../../maintenance/rules/shared/contract/commands/assign-maintenance-rule.command';
import { UnassignMaintenanceRuleCommand } from '../../../maintenance/rules/shared/contract/commands/unassign-maintenance-rule.command';

@Injectable()
export class EquipmentMaintenanceRuleService {
  private url = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/rules';

  constructor(private http: HttpClient) {
  }

  assignMaintenanceRuleToEquipment(command: AssignMaintenanceRuleCommand): Observable<string> {
    return this.http.post(this.url + '/assign', command, {responseType: 'text'});
  }

  unassignMaintenanceRuleToEquipment(command: UnassignMaintenanceRuleCommand): Observable<string> {
    return this.http.post(this.url + '/unassign', command, {responseType: 'text'});
  }

  assignAllMaintenanceRuleToEquipment(commands: AssignMaintenanceRuleCommand[]): Observable<string> {
    return this.http.post(this.url + '/assignAll', commands, {responseType: 'text'});
  }

  unassignAllMaintenanceRuleToEquipment(commands: UnassignMaintenanceRuleCommand[]): Observable<string> {
    return this.http.post(this.url + '/unassignAll', commands, {responseType: 'text'});
  }

  getAll(): Observable<MaintenanceRule[]> {
    return this.http.get<MaintenanceRule[]>(this.url);
  }

  findAssignedToEquipment(equipmentId: string): Observable<MaintenanceRule[]> {
    const params = new HttpParams()
    .set('equipmentId', equipmentId);
    return this.http.get<MaintenanceRule[]>(`${this.url}/assigned`, {params: params});
  }
}
