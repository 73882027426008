import { DeleteEmployeeDocumentCommand } from './delete-employee-document-command';

export class DeleteEmployeeDocumentRenewalCommand extends DeleteEmployeeDocumentCommand {
  public qualificationId: string;
  constructor(employeeId: string, objectKey: string) {
    super(employeeId, objectKey);
    this.qualificationId = objectKey.split('/')?.[1];
  }

  static convert(cmd: DeleteEmployeeDocumentCommand): DeleteEmployeeDocumentRenewalCommand {
    return new DeleteEmployeeDocumentRenewalCommand(cmd?.employeeId, cmd?.objectKey);
  }
}
