import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransportDateTimeInputDialogConfig } from './transport-date-time-input-dialog-config';
import { getBrowserName } from '../../../../shared/utils';
import { BrowserName } from '../../../../shared/enums/browser-name.enum';
import { DateValidator } from '../../../../shared/custom-validators/date.validator';
import { LanguageService } from '../../../../shared/services/language.service';
import { TransportationValidators } from '../../shared/validators/transportation-validators.class';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OspDateTime } from '../../../equipment/contract/osp-date-time';
import { DatesService } from '../../../../shared/services/dates.service';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapterNoDeserialize } from '../../../../shared/custom-date-adapter-no-deserialize';

@UntilDestroy()
@Component({
  selector: 'bh-transport-date-time-input-dialog',
  templateUrl: './transport-date-time-input-dialog.component.html',
  styleUrls: ['./transport-date-time-input-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterNoDeserialize }
  ]
})
export class TransportDateTimeInputDialogComponent implements OnInit {

  public title: string;
  public dateLabel: string;
  public timeLabel: string;
  public confirmText: string;
  public dateTimeFormControlGroup: UntypedFormGroup;
  public hasFormGroupError = true;
  public isStartDate: boolean;
  public startDate: Date;

  public readonly isFirefoxBrowser = getBrowserName() === BrowserName.FIREFOX;
  public readonly formControlNameDate = 'date';
  public readonly formControlNameTime = 'time';

  private hasFormGroupErrorSubject = new Subject<boolean>();


  constructor(
    @Inject(MAT_DIALOG_DATA) data: TransportDateTimeInputDialogConfig,
    private dialogRef: MatDialogRef<TransportDateTimeInputDialogComponent>,
    private languageService: LanguageService,
    private formBuilder: UntypedFormBuilder) {
      this.mapInputData(data);
  }

  ngOnInit(): void {
    this.hasFormGroupErrorSubject.pipe(untilDestroyed(this)).subscribe(next => {
      this.hasFormGroupError = next
    });
    this.initFormGroup();
  }

  public submit(dateTimeFormControlGroup: UntypedFormGroup): void {
    this.dialogRef.close(this.createOspDateTime(
      dateTimeFormControlGroup.get(this.formControlNameDate).value,
      dateTimeFormControlGroup.get(this.formControlNameTime).value
    ))
  }

  public clearDate(): void {
    this.dateTimeFormControlGroup.get(this.formControlNameDate).reset();
  }

  public clearTime(): void {
    this.dateTimeFormControlGroup.get(this.formControlNameTime).reset();
  }

  private initFormGroup(): void {
    this.dateTimeFormControlGroup = this.formBuilder.group({
      [this.formControlNameDate]: ['', [Validators.required, DateValidator.isValidDate(this.languageService)]],
      [this.formControlNameTime]: ['']
    });

    this.setValidatorsForTimeInput();

    this.dateTimeFormControlGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.dateTimeFormControlGroup.get(this.formControlNameDate).updateValueAndValidity({emitEvent: false});
      this.dateTimeFormControlGroup.get(this.formControlNameTime).updateValueAndValidity({emitEvent: false});
      this.hasFormGroupErrorSubject.next(this.hasFormGroupErrors(this.dateTimeFormControlGroup));
    })

    if (this.isStartDate === true) {
      this.dateTimeFormControlGroup.get(this.formControlNameDate).patchValue(this.startDate);
      this.dateTimeFormControlGroup.get(this.formControlNameTime)
      .patchValue(DateValidator.formatTime(this.startDate.getHours(), this.startDate.getMinutes()));
      this.dateTimeFormControlGroup.updateValueAndValidity();
    }
  }

  private setValidatorsForTimeInput(): void {
    if (this.isStartDate === true) {
      this.dateTimeFormControlGroup.get(this.formControlNameTime).setValidators(
        [Validators.required,
          TransportationValidators.timeWithDate(this.dateTimeFormControlGroup.get(this.formControlNameDate))]);
    } else {
      this.dateTimeFormControlGroup.get(this.formControlNameTime).setValidators(
        [Validators.required,
          TransportationValidators.timeWithDate(this.dateTimeFormControlGroup.get(this.formControlNameDate)),
          DateValidator.minDateTime(
            this.dateTimeFormControlGroup.get(this.formControlNameDate),
            this.dateTimeFormControlGroup.get(this.formControlNameTime),
            this.startDate,
            this.languageService
          )]);
    }
  }

  private mapInputData(passedInData: TransportDateTimeInputDialogConfig): void {
    this.title = passedInData.title;
    this.dateLabel = passedInData.dateLabel
    this.timeLabel = passedInData.timeLabel
    this.confirmText = passedInData.confirmText;
    this.startDate = passedInData.startDate;
    this.isStartDate = passedInData.isStartDate
  }

  private hasFormGroupErrors(dateTimeFormGroup: UntypedFormGroup): boolean {
    for (const dateTimeFormGroupKey of Object.keys(dateTimeFormGroup.controls)) {
      if (dateTimeFormGroup.get(dateTimeFormGroupKey).invalid === true) {
        return true;
      }
    }
    return false;
  }

  private createOspDateTime(date: Date, time: string): OspDateTime {
    let [hours, minutes] = time.split(':');
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return {timestamp: date.toISOString(), zoneId: DatesService.getLocalTimeZone()}
  }
}
