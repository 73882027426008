import { Observable, Subject } from 'rxjs';

export class SingleEmit<T> {
  private subj = new Subject<T>();
  public asObservable(): Observable<T> {
    return this.subj.asObservable();
  }
  public next(value: T): void {
    this.subj.next(value);
    this.subj.complete();
  }
  public complete(): void {
    this.subj.complete();
  }
}
