import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { DeleteProjectCommand } from '../../../../contract/delete-project-command';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseProjectViewComponent } from '../base/base-project-view.component';
import { ProjectCheckerService } from '../../../../shared/services/project-checker.service';
import { ConfirmationDialogComponent } from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FinishProjectDialogComponent } from '../../../../shared/finish-project-dialog/finish-project-dialog.component';
import { ifDialogYes } from '../../../../../../shared/utils';
import { RouterHistory } from '../../../../../../shared/router-history';
import { AmountsService } from '../../../../shared/amounts.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faExchange, faPalletAlt, faRetweetAlt } from '@fortawesome/pro-light-svg-icons';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { InputConfirmDialogComponent } from '../../../../../../shared/components/input-confirm-dialog/input-confirm-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateProjectGroupCommand } from '../../../../contract/create-project-group-command';
import { ViewProject } from '../../../../contract/view-project.interface';
import { RenameSubProjectCommand } from '../../../../contract/rename-sub-project';
import { ProjectStructureType } from '../../../../shared/enums/project-structure-type';
import { ProjectStatus } from '../../../../shared/enums/project-status.enum';
import { CreateSubProjectCommand } from '../../../../contract/create-sub-project-command';
import { InputConfirmDialogService } from '../../../../../../shared/components/input-confirm-dialog/input-confirm-dialog.service';
import { DeleteProjectGroupCommand } from '../../../../contract/delete-project-group-command';
import { ProjectAddEditComponent } from '../../../project-add-edit/project-add-edit.component';
import { SubMenuTab } from 'app/modules/disposition/shared/interfaces/sub-menu-tab.interface';
import { ProjectSubMenuLink } from 'app/shared/enums/project-sub-menu-link.enum';
import { SubmenuTabsResizeHandler } from 'app/shared/submenu-tabs-resize-handler';
import { MatMenuTrigger } from '@angular/material/menu';
import { switchMap, filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'bh-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent extends BaseProjectViewComponent implements OnInit, AfterViewInit, AfterViewChecked {

  public readonly faExchange: IconDefinition = faExchange;
  public readonly faPalletAlt: IconDefinition = faPalletAlt;
  public readonly faRetweetAlt: IconDefinition = faRetweetAlt;
  public readonly subMenuRouterLinks = ProjectSubMenuLink;
  public ProjectStructureType = ProjectStructureType;
  protected hasTypeOfUseWorkflow = false;

  private submenuHandler: SubmenuTabsResizeHandler;
  private resizeObserver: ResizeObserver;

  @ViewChild('submenu', { static: true }) submenu: ElementRef;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  public hiddenTabs: SubMenuTab[] = [];
  public subMenuTabs: SubMenuTab[] = [
    {
      name: this.translate('general.generalData'),
      url: ProjectSubMenuLink.GENERAL,
      matIconFontSet: 'icon-general_outline',
    },
    {
      name: this.translate('modules.disposition.projectView.geofences'),
      url: ProjectSubMenuLink.GEOFENCES,
      matIconFontSet: 'icon-geofences_outline',
    },
    {
      name: this.translate('general.comment.s'),
      url: ProjectSubMenuLink.COMMENTS,
      matIconFontSet: 'icon-comments_outline',
    },
    {
      name: this.translate('general.attachment.pl'),
      url: ProjectSubMenuLink.ATTACHMENTS,
      matIconFontSet: 'icon-attachment_outline',
    },
    {
      name: this.translate('modules.disposition.base.inventory.s'),
      url: ProjectSubMenuLink.ASSIGNMENTS,
      faIcon: faPalletAlt,
    },
    {
      name: this.translate('modules.disposition.projectView.transfers'),
      url: ProjectSubMenuLink.TRANSFER_HISTORY,
      faIcon: faExchange,
    },
    {
      name: this.translate('modules.disposition.projectView.typeOfUse'),
      url: ProjectSubMenuLink.CHANGE_TYPE_OF_USE,
      faIcon: faRetweetAlt,
    }
  ];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              public projectStore: ProjectDataSource,
              public projectCheckerService: ProjectCheckerService,
              protected bulkItemAmountsService: AmountsService,
              protected languageService: LanguageService,
              private fb: UntypedFormBuilder,
              private inputConfirmDialog: InputConfirmDialogService,
              protected cdRef: ChangeDetectorRef,
  ) {
    super(authService, router, route, routerHistory, projectStore, bulkItemAmountsService, languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToTypeOfUseWorkflow();
    this.subscribeTransferHistory();
  }

  ngAfterViewInit(): void {
    this.defineTabsResizeObserver();
    this.route.params.pipe(
      switchMap(() => this.projectStore.currentProject),
      filter(currentProject => currentProject?.projectStructureType === ProjectStructureType.PROJECT_GROUP))
    .subscribe(() => this.defineTabsResizeObserver());
  }

  ngAfterViewChecked(): void {
    this.submenuHandler?.onTabsHeightArrayLengthChange();
    this.cdRef.detectChanges();
  }

  private defineTabsResizeObserver(): void {
    this.submenuHandler = new SubmenuTabsResizeHandler(this.submenu, this.subMenuTabs);
    this.resizeObserver = new ResizeObserver(() => {
      this.hiddenTabs = this.submenuHandler.onSubMenuResize();
      if (!this.hiddenTabs.length) {
        this.trigger.closeMenu();
      }
    });
    this.resizeObserver.observe(this.submenu.nativeElement);
    this.submenuHandler = new SubmenuTabsResizeHandler(this.submenu, this.subMenuTabs);
  }

  public isTabMoreMenuActive(): boolean {
    const tabsMoreMenuPaths = this.hiddenTabs.map(tab => tab.url);
    const currentTabPath = this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0].path;
    return tabsMoreMenuPaths.includes(currentTabPath);
  }

  public edit(): void {
    const dialogRef = this.dialog.open(ProjectAddEditComponent, {
      disableClose: true,
      restoreFocus: false
    });
    dialogRef.componentInstance.project = this.project;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
        this.projectStore.updateListing();
      }
    });
  }

  public projectDelete(projectId: string): void {
    if (this.hasAuthority(this.authorities.PROJECT_DELETE)) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.projectDelete');
      dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.projectDelete');
      dialogRef.afterClosed().pipe(ifDialogYes).subscribe(() =>
        this.projectStore.deleteProject(new DeleteProjectCommand(projectId)));
    }
  }

  public subProjectDelete(projectId: string, projectName: string): void {
    if (this.hasAuthority(this.authorities.PROJECT_DELETE)) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage =
        `${this.translate('modules.disposition.confirmation.message.subProjectDelete')} ${projectName}`;
      dialogRef.afterClosed().pipe(ifDialogYes).subscribe(() =>
        this.projectStore.deleteSubProject(new DeleteProjectCommand(projectId)));
    }
  }

  public projectGroupDelete(projectId: string): void {
    if (this.hasAuthority(this.authorities.PROJECT_DELETE)) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent,
        {
          width: '550px',
        });
      dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.projectGroupDelete');
      dialogRef.componentInstance.denyLabel = this.translate('general.buttons.cancel');
      dialogRef.afterClosed().pipe(ifDialogYes).subscribe(() =>
        this.projectStore.deleteProjectGroup(new DeleteProjectGroupCommand(projectId)));
    }
  }

  public deleteSubProjectDisabled(): boolean {
    return this.hasAmounts || this.project.projectStructureType === ProjectStructureType.INITIAL_SUB_PROJECT ||
      this.hasTransferHistory;
  }

  public deleteProjectGroupDisabled(project: ViewProject): boolean {
    return project.projectStructureType !== ProjectStructureType.PROJECT_GROUP ||
      project.subProjects.length !== 1 ||
      (project.subProjects.length === 1 &&
        project.subProjects[0].projectStructureType !== ProjectStructureType.INITIAL_SUB_PROJECT);
  }

  public createProjectGroup(initialProjectId: string, projetName: string): void {
    const pattern = new RegExp('(\\s+[^\\s]+)*$');
    const form: UntypedFormGroup = this.fb.group({
      newInitialProjectName: [`SUB ${projetName}`,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
          Validators.pattern(pattern)
        ]
      ],
    });

    const dialogRef: MatDialogRef<InputConfirmDialogComponent> = this.inputConfirmDialog.open(InputConfirmDialogComponent,
      {
        width: '400px',
        height: '250px',
        data: {
          title: this.translate('modules.disposition.projectView.createSubProject'),
          placeholder: this.translate('modules.disposition.projectAddEdit.projectName'),
          buttonCancelText: this.translate('general.buttons.cancel'),
          buttonOkText: this.translate('general.buttons.ok'),
          control: form.controls.newInitialProjectName,
        }
      },
      this.dialog);
    dialogRef.afterClosed().pipe(ifDialogYes).subscribe(
      () => {
        this.projectStore.createProjectGroup(new CreateProjectGroupCommand(form.controls.newInitialProjectName.value, initialProjectId));

      });
  }

  public createSubProject(projectGroupId: string, projectName: string): void {
    const pattern = new RegExp('(\\s+[^\\s]+)*$');
    const form: UntypedFormGroup = this.fb.group({
      projectName: [`SUB ${projectName}`,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
          Validators.pattern(pattern)
        ]
      ],
    });
    const dialogRef: MatDialogRef<InputConfirmDialogComponent> = this.inputConfirmDialog.open(InputConfirmDialogComponent,
      {
        width: '400px',
        height: '250px',
        data: {
          title: this.translate('modules.disposition.projectView.createSubProject'),
          placeholder: this.translate('modules.disposition.projectAddEdit.projectName'),
          buttonCancelText: this.translate('general.buttons.cancel'),
          buttonOkText: this.translate('general.buttons.ok'),
          control: form.controls.projectName,
        }
      },
      this.dialog);
    dialogRef.afterClosed().pipe(ifDialogYes).subscribe(
      () => {
        this.projectStore.createSubProject(new CreateSubProjectCommand(form.controls.projectName.value, projectGroupId));

      });
  }

  public renameSubProject(projectId: string, projectName: string): void {
    const pattern = new RegExp('(\\s+[^\\s]+)*$');
    const form: UntypedFormGroup = this.fb.group({
      projectName: [projectName,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
          Validators.pattern(pattern)
        ]
      ],
    });
    const dialogRef: MatDialogRef<InputConfirmDialogComponent> = this.inputConfirmDialog.open(InputConfirmDialogComponent,
      {
        width: '400px',
        height: '250px',
        data: {
          title: this.translate('modules.disposition.projectView.renameSubProject'),
          placeholder: this.translate('modules.disposition.projectAddEdit.projectName'),
          buttonCancelText: this.translate('general.buttons.cancel'),
          buttonOkText: this.translate('general.buttons.ok'),
          control: form.controls.projectName,
        }
      },
      this.dialog);
    dialogRef.afterClosed().pipe(ifDialogYes).subscribe(
      () => {
        this.projectStore.renameSubProject(new RenameSubProjectCommand(form.controls.projectName.value, projectId));
      });
  }

  public editButtonVisibleForProjectGroups(project: ViewProject): boolean {
    return this.hasModule(this.modules.PROJECT_GROUP_MANAGEMENT)
      ? this.isNotSubProject(project) && this.isNotProjectGroup(project)
      : true;
  }

  public isNotSubProject(project: ViewProject): boolean {
    return project?.projectStructureType !== ProjectStructureType.SUB_PROJECT &&
      project?.projectStructureType !== ProjectStructureType.INITIAL_SUB_PROJECT;
  }

  public isNotProjectGroup(project: ViewProject): boolean {
    return project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP;
  }

  public isActiveProject(project: ViewProject): boolean {
    return project?.projectStatus === ProjectStatus.ACTIVE;
  }

  public isStandaloneProject(projectStructureType: ProjectStructureType): boolean {
    return projectStructureType === ProjectStructureType.STANDALONE_PROJECT ||
      projectStructureType === null ||
      projectStructureType === undefined
  }

  public showMoreMenu(): boolean {
    return this.hasAnyAuthority([
      this.authorities.PROJECT_UPDATE,
      this.authorities.PROJECT_UPDATE_STATUS,
      this.authorities.PROJECT_DELETE
    ]);
  }

  public finishProject(): void {
    const dialogRef: MatDialogRef<FinishProjectDialogComponent> = this.dialog.open(FinishProjectDialogComponent);
    dialogRef.componentInstance.project = this.projectStore.project();
  }

  public getDeletableTooltip(): string {
    return this.translate(this.hasAmounts ? 'modules.disposition.projectView.deletableTooltipHasAmount'
      : 'modules.disposition.projectView.deletableTooltipHasNotAmount');
  }

  public getDeletableSubProjectTooltip(): string {
    if (this.project?.projectStructureType === ProjectStructureType.INITIAL_SUB_PROJECT) {
      return this.translate('modules.disposition.projectView.deletableSubProjectTooltipThisSubGroup');
    }
    return this.translate(this.hasAmounts ? 'modules.disposition.projectView.deletableTooltipHasAmount'
      : 'modules.disposition.projectView.deletableTooltipHasNotAmount');
  }

  public allowChangeTypeOfUse(): boolean {
    return this.hasAnyAuthority([Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE,
        Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE])
      && this.hasTypeOfUseWorkflow;
  }

  private subscribeToTypeOfUseWorkflow(): void {
    this.projectStore.hasTypeOfUseWorkflow.subscribe(res => {
      this.hasTypeOfUseWorkflow = res;
    });
    this.projectStore.updateTypeOfUseWorkflow();
  }

  private subscribeTransferHistory(): void {
    this.projectStore.hasTransferHistory.subscribe(res => {
      this.hasTransferHistory = res;
    });
  }
}
