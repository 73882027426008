import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  NgControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { concat, delay, distinctUntilChanged, merge, Subscription } from 'rxjs';
import { DraggableListItem } from './draggable-list-item.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { FieldLimit } from '../../enums/fieldLimit.enum';
import { UUID } from 'angular2-uuid';
import { FormArrayValidatorErrorType } from './form-array-validator-error-type.enum';
import { Utils } from 'app/shared/utils';

interface DragEvent {
  previousIndex: number;
  currentIndex: number;
}

@UntilDestroy()
@Component({
  selector: 'bh-draggable-list',
  templateUrl: './draggable-list.component.html',
  styleUrls: ['./draggable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableListComponent implements OnInit, ControlValueAccessor {
  @Input() isValuesRequired = false;
  @Input() maxAmount = 10;
  @Input() showInfoIcon = false;
  @Input() infoIconTooltipText = '';
  @Input() trackingErrorTypes: FormArrayValidatorErrorType[] = [];
  @Output() onDelete = new EventEmitter<string>();

  public value: DraggableListItem[] = [];
  public formArray: UntypedFormArray = new UntypedFormArray([]);
  public readonly containerBoundaryClassName = 'draggable-list-boundary';
  public maxInputLength = FieldLimit.MEDIUM_IDENTIFIER;
  public errorNames = FormArrayValidatorErrorType;
  private readonly draggableListErrorName = 'draggable-list-errors';
  private formChangesListener = new Subscription();
  private formStatusListener = new Subscription();
  private onChange: (value: DraggableListItem[]) => void;

  constructor(private ngControl: NgControl, private cdr: ChangeDetectorRef) {
    ngControl.valueAccessor = this;
  }

  public get isMaxAmountReached(): boolean {
    return this.formArray.controls?.length > this.maxAmount - 1;
  }

  public get parentControl(): AbstractControl {
    return this.ngControl.control;
  }

  public ngOnInit(): void {
    this.initParentControlErrorCheckListener();
  }

  public writeValue(value: DraggableListItem[]): void {
    if (!this.IsSameValue(this.value, value)) {
      this.value = value || [];
      this.rebuildForm(this.value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public drop($event: DragEvent): void {
    moveItemInArray(this.value, $event.previousIndex, $event.currentIndex);
    this.moveItem($event.previousIndex, $event.currentIndex);
  }

  public addNew(): void {
    const newItem: DraggableListItem = {
      id: UUID.UUID(),
      value: null,
      isNew: true,
    };
    this.value.push(newItem);
    this.formArray.push(new UntypedFormControl(newItem.value, this.isValuesRequired ? Validators.required : null));
    this.formArray.markAllAsTouched();
    this.applyParentControlErrors();
  }

  private IsSameValue(first: DraggableListItem[], second: DraggableListItem[]): boolean {
    if ((first?.length ?? 0) !== (second?.length ?? 0)) {
      return false;
    }
    return _.isEqual(first, second);
  }

  private emitValue(): void {
    this.value = this.value.map((item, index) => ({...item, value: this.formArray.value[index]}));
    this.onChange(this.value);
  }

  private moveItem(previousIndex: number, currentIndex: number): void {
    const control = this.formArray.at(previousIndex);
    this.formArray.removeAt(previousIndex, {emitEvent: false});
    this.formArray.insert(currentIndex, control);
  }

  private rebuildForm(list: DraggableListItem[]): void {
    this.formArray = new UntypedFormArray(
      list.map(({value}, index) => new UntypedFormControl(value, this.getFormArrayItemValidators()))
    );
    this.formArray.markAllAsTouched();
    this.initValueChangesListener();
    this.initStatusChangesListener();
    this.cdr.markForCheck();
  }

  private getFormArrayItemValidators(): ValidatorFn[] {
    return [
      this.isValuesRequired ? Validators.required : null
    ].filter(Boolean);
  }

  private initValueChangesListener(): void {
    this.formChangesListener.unsubscribe();
    this.formChangesListener = this.formArray.valueChanges
      .pipe(
        distinctUntilChanged(_.isEqual),
        untilDestroyed(this))
      .subscribe(() => this.emitValue());
  }

  private initStatusChangesListener(): void {
    this.formStatusListener.unsubscribe();
    this.formStatusListener = merge(
      this.formArray.statusChanges,
      this.formArray.valueChanges
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.applyParentControlErrors();
        this.pushErrorsToParentControl();
      });
  }

  private pushErrorsToParentControl(): void {
    Utils.removeError(this.parentControl, this.draggableListErrorName, { emitEvent: false });
    const resultErrors = this.getFormArrayErrorCollection();
    if (resultErrors) {
      Utils.addError(this.parentControl, resultErrors);
    }
  }

  private getFormArrayErrorCollection() {
    if (this.formArray.valid) {
      return null;
    }

    const itemErrors = this.formArray.controls
      .filter(control => control.invalid)
      .map(({ errors }) => errors);
    return {
      [this.draggableListErrorName]: {
        ...(this.formArray.errors ?? {}),
        ...(itemErrors.length > 0 ? { itemErrors } : {})
      }
    }
  }

  private initParentControlErrorCheckListener(): void {
    if (this.trackingErrorTypes?.length && this.trackingErrorTypes?.length > 0) {
      this.parentControl.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.applyParentControlErrors();
          this.parentControlRemoveErrorIfNecessary();
          if (this.parentControl.valid && this.formArray.invalid) {
            Utils.addError(this.parentControl, this.getFormArrayErrorCollection());
          }
          this.formArray.markAllAsTouched();
          this.cdr.markForCheck();
        })
    }
  }

  private applyParentControlErrors(): void {
    if (this.parentControl) {
      this.trackingErrorTypes.forEach(errorType => {
        const indexes = this.parentControl.getError(errorType) ?? [];
        this.applyErrorToFormArrayControls(indexes, errorType);
      });
      this.formArray.markAllAsTouched();
      this.cdr.markForCheck();
    }
  }

  private applyErrorToFormArrayControls(indexes: number[], errorType: FormArrayValidatorErrorType, errorBody: any = true): void {
    const error = { [errorType]: errorBody };
    this.formArray.controls.forEach(control => Utils.removeError(control, errorType, { emitEvent: false }));
    indexes.forEach(index => Utils.addError((<UntypedFormArray>this.formArray).at(index), error, { emitEvent: false }));
  }

  private parentControlRemoveErrorIfNecessary(): void {
    if (this.formArray.valid && this.parentControl.hasError(this.draggableListErrorName)) {
      Utils.removeError(this.parentControl, this.draggableListErrorName, { emitEvent: false });
    }
  }

}
