<div class="dialog-wrapper">
  <h2 mat-dialog-title>{{ 'modules.telematic.telematicUnitProfiles.editProfileDialogTitle'|translate }}</h2>

  <div mat-dialog-content>
    <div [formGroup]="profileDataForm">
      <bh-text-input
        [control]="$any(profileDataForm.controls.templateId)"
        [label]="'modules.telematic.telematicUnitProfiles.profileTemplateId'|translate">
      </bh-text-input>
      <bh-text-input
        [control]="$any(profileDataForm.controls.displayName)"
        [label]="'modules.telematic.telematicUnitProfiles.profileName'|translate">
      </bh-text-input>
      <bh-text-input
        [control]="$any(profileDataForm.controls.description)"
        [label]="'modules.telematic.telematicUnitProfiles.profileDescription'|translate">
      </bh-text-input>
    </div>
  </div>

  <div mat-dialog-actions>
    <div fxFlex="50" fxLayoutAlign="start center">
      <button mat-raised-button
              mat-dialog-close>
        {{ 'general.buttons.cancel'|translate }}
      </button>
    </div>
    <div fxFlex="50" fxLayoutAlign="end center">
      <button mat-raised-button
              color="primary"
              bhTimeoutSaveButton
              #timeoutButton="bhTimeoutSaveButton"
              [throttleTime]="buttonThrottle"
              (throttledClick)="save()"
              [disabled]="profileDataForm.invalid || (timeoutButton.throttleOn | async)">
        {{ 'general.buttons.edit'|translate }}
      </button>
    </div>
  </div>
</div>
