import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { EquipmentEmployeeRole } from '../../modules/equipment/contract/equipment-employee-role.enum';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'equipmentEmployeeRole'
})
@Injectable()
export class EquipmentEmployeeRolePipe implements PipeTransform {

  constructor(public languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    switch (value) {
      case EquipmentEmployeeRole.OWNER:
        return this.languageService.getInstant('shared.pipes.equipmentEmployeeRole.owner');
      case EquipmentEmployeeRole.POSSESSOR:
        return this.languageService.getInstant('shared.pipes.equipmentEmployeeRole.possessor');
      case EquipmentEmployeeRole.PERSON_IN_CHARGE:
        return this.languageService.getInstant('shared.pipes.equipmentEmployeeRole.personInCharge');
      case EquipmentEmployeeRole.DRIVER:
        return this.languageService.getInstant('shared.pipes.equipmentEmployeeRole.driver');
      case EquipmentEmployeeRole.MAINTENANCE_ASSIGNEE:
        return this.languageService.getInstant('shared.pipes.equipmentEmployeeRole.maintenanceAssignee');
      default:
        return this.languageService.getInstant('shared.pipes.equipmentEmployeeRole.unknown');
    }
  }
}
