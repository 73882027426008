<div class="m-view-current-loc" fxFlexFill fxLayout="column">
  <div class="m-view-current-loc__address" *ngIf="equipmentLastPosition?.currentAddress" fxLayout="column">
    <div class="title">
      {{'modules.equipment.equipmentCurrentLocation.currentLocation'|translate|uppercase}}
    </div>
    <div [ngSwitch]="equipmentLastPosition?.positionSource" fxLayout="row" fxLayoutGap="10px">
      <ng-template [ngSwitchCase]="lastPositionConfigs.STOCK.type">
        <fa-icon class="location-icon location-icon__stock"
                 [icon]="faWarehouse"
                 [matTooltip]="lastPositionConfigs.STOCK.iconTooltip"></fa-icon>
        <div *ngIf="lastPositionStockName() else noStockInfo" class="address-line">
          {{lastPositionStockName()}}
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="lastPositionConfigs.DISPOSITION.type">
        <mat-icon class="equipment__position__icon location-icon"
                  [fontSet]="lastPositionConfigs.DISPOSITION.icon"
                  [matTooltip]="lastPositionConfigs.DISPOSITION.iconTooltip">
        </mat-icon>
        <div *ngIf="lastPositionProjectName() else noProjectInfo" class="address-line">
          {{lastPositionProjectName()}}
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="lastPositionConfigs.TELEMATIC.type">
        <mat-icon class="equipment__position__icon location-icon"
                  [fontSet]="lastPositionConfigs.TELEMATIC.icon"
                  [matTooltip]="lastPositionConfigs.TELEMATIC.iconTooltip">
        </mat-icon>
        <div fxFlex fxLayoutAlign="start center" class="address-line"
             *ngIf="lastPositionTelematicDate() else noTelematicInfo">
          {{lastPositionTelematicDate() | date:'dd.MM.yyyy HH:mm:ss'}}
        </div>
      </ng-template>
    </div>
    <div class="address-line padding-left">
      {{equipmentLastPosition?.currentAddress?.street}} {{equipmentLastPosition?.currentAddress?.streetNumber}}
      , {{equipmentLastPosition?.currentAddress?.postalCode}} {{equipmentLastPosition?.currentAddress?.city}}
    </div>
  </div>
  <div fxFlex *ngIf="equipmentLastPosition?.location?.lat && equipmentLastPosition?.location?.lon">
    <!-- // TODO: Clean up commented fragment after the task BEUT-20502 is completed -->
    <!-- <agm-map *ngIf="equipmentLastPosition?.location?.lat && equipmentLastPosition?.location?.lon"
             bhCustomMap fxFlex
             [latitude]="equipmentLastPosition?.location.lat"
             [longitude]="equipmentLastPosition?.location.lon"
             [zoom]="13">
      <agm-marker-cluster [gridSize]="50">
        <agm-marker [latitude]="equipmentLastPosition?.location.lat"
                    [longitude]="equipmentLastPosition?.location.lon">
        </agm-marker>
      </agm-marker-cluster>
    </agm-map> -->
  </div>

  <div *ngIf="!equipmentLastPosition?.location?.lat || !equipmentLastPosition?.location?.lon">
    {{'general.labels.cannotBeDisplayed.invalidAddress'|translate}}
  </div>
</div>
<ng-template #noStockInfo>
  <div fxFlex fxLayoutAlign="start center">
    <span>{{'general.labels.cannotBeDisplayed.stock'|translate}}</span>
  </div>
</ng-template>

<ng-template #noProjectInfo>
  <div fxFlex fxLayoutAlign="start center">
    <span>{{'general.labels.cannotBeDisplayed.project'|translate}}</span>
  </div>
</ng-template>

<ng-template #noTelematicInfo>
  <div fxFlex fxLayoutAlign="start center">
    <span>{{'general.labels.cannotBeDisplayed.liveData'|translate}}</span>
  </div>
</ng-template>
