import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { BaseEquipmentMapComponent } from '../base/base-equipment-map.component';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentsService } from '../../../shared/equipments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { MatDialog } from '@angular/material/dialog';
import { RouterHistory } from '../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-equipment-map',
  templateUrl: './mobile-equipment-map.component.html',
  styleUrls: ['./mobile-equipment-map.component.scss']
})
export class MobileEquipmentMapComponent extends BaseEquipmentMapComponent implements OnInit {

  constructor(protected equipmentStore: EquipmentsDataSource,
              protected router: Router,
              protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected equipmentService: EquipmentsService,
              protected organisationService: OrganisationsService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, route, equipmentStore, equipmentService, organisationService, dialog, routerHistory, languageService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildSearchForm();
  }

  navigateToList() {
    this.router.navigate(['mobile/equipments/list']);
  }

  reset() {
    this.searchForm.reset();
  }
}
