import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { timer } from 'rxjs';
import { CommunicationDataSource } from '../../shared/communication.datasource';
import { KeycloakService } from '../../../../core/keycloak';
import { BaseChatListComponent } from '../base-chat-list.component';
import { RouterHistory } from '../../../../shared/router-history';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-chat',
  templateUrl: './mobile-chat-list.component.html',
  styleUrls: ['./mobile-chat-list.component.scss']
})

export class MobileChatListComponent extends BaseChatListComponent implements OnInit {

  @ViewChild('chatList', { static: true })
  private chatListHtmlElem;

  private oldClientHeight = -1;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected communicationDataSource: CommunicationDataSource,
              protected routerHistory: RouterHistory) {
    super(authService, router, activatedRoute, communicationDataSource, routerHistory);
  }

  ngOnInit() {
    super.ngOnInit();
    this.scrollingControl();
  }

  scrollingControl(): void {
    timer(0, 250).pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.chatListHtmlElem) {
        return;
      }
      if (this.oldClientHeight > this.chatListHtmlElem.clientHeight) {
        this.oldClientHeight = this.chatListHtmlElem.clientHeight;
        this.chatListHtmlElem.scrollTop = this.chatListHtmlElem.scrollHeight;
      } else {
        this.oldClientHeight = this.chatListHtmlElem.clientHeight;
      }
    });
  }

  goToLandingPage(): void {
    this.router.navigate(['mobile/landingpage']);
  }
}
