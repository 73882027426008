import { Injectable } from '@angular/core';
import { MapSvgMarkerOptions } from '../contract/map-svg-marker-options.interface';
import { CustomThemeService } from './custom-theme.service';
import { ThemeSettings } from '../../../assets/styles/themes/theme.settings';


interface SvgBuildOptions extends MapSvgMarkerOptions {
  baseWidth: number;
  baseHeight: number;
  primarySvgPath: string;
  secondarySvgPath: string;
  framePadding: number;
  cornerRadius: number;
  anchorWidth: number;
  anchorHeight: number;
  hLineLength: number;
  vLineLength: number;
  cornerControlsDistance: number;
}


@Injectable()
export class MapSvgMarkerFactory {

  private theme: ThemeSettings;

  private markerHashMap = new Map<string, string>();
  private kFramePadding = 0.17;
  private kCornerRadius = 0.14;
  private kAnchorWidth = 0.28;
  private hw = 40;
  private svgHead = `<?xml version="1.0" encoding="utf-8"?>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${this.hw}" height="${this.hw}" `;

  constructor(customThemeService: CustomThemeService) {
    this.theme = customThemeService.getThemeSettings();
  }

  public getSvgUrl(options: MapSvgMarkerOptions): string {
    const filledOptions = this.fillMapSvgMarkerOptions(options);

    const key = this.getMarkerHashMapKey(filledOptions);
    if (this.markerHashMap.has(key)) {
      return this.markerHashMap.get(key);
    }
    const svgUrl = this.generateSvgUrl(filledOptions);
    this.markerHashMap.set(key, svgUrl);
    return svgUrl;
  }

  private fillMapSvgMarkerOptions(options: MapSvgMarkerOptions): MapSvgMarkerOptions {
    return {
      ...options,
      ...{
        frameColor: options.frameColor || this.theme.mapIconFrameColor,
        primaryColor: options.primaryColor || this.theme.mapIconPrimaryColor,
        secondaryColor: options.secondaryColor || this.theme.mapIconSecondaryColor
      },
    }
  }

  private getMarkerHashMapKey(options: MapSvgMarkerOptions): string {
    const { iconDefinition: { prefix, iconName }, frameColor, primaryColor, secondaryColor } = options;
    return `${prefix}_${iconName}_${frameColor}_${primaryColor}_${secondaryColor}`;
  }

  private generateSvgUrl(options: MapSvgMarkerOptions): string {
    const svgBuildOptions = this.getSvgBuildOptions(options);

    const svgContent = `${this.svgHead}
      viewBox="-${svgBuildOptions.framePadding}
      -${svgBuildOptions.framePadding}
      ${svgBuildOptions.baseWidth + (svgBuildOptions.framePadding * 2)}
      ${svgBuildOptions.baseHeight + (svgBuildOptions.framePadding * 2) + svgBuildOptions.anchorHeight}">
    <g>
    ${this.generateFrameSvgPath(svgBuildOptions)}
    ${svgBuildOptions.secondarySvgPath
      ? `<path fill="${svgBuildOptions.secondaryColor}" d="${svgBuildOptions.secondarySvgPath}"></path>`
      : ``}
    <path fill="${svgBuildOptions.primaryColor}" d="${svgBuildOptions.primarySvgPath}"></path>
    </g></svg>`;

    return 'data:image/svg+xml;charset=UTF-8;base64,' +  btoa(svgContent);
  }

  private getSvgBuildOptions(options: MapSvgMarkerOptions): SvgBuildOptions {
    const {0: baseWidth, 1: baseHeight, length : l, [l - 1] : svgPathData} = options.iconDefinition.icon;
    const [secondarySvgPath, primarySvgPath] = svgPathData instanceof Array ? svgPathData : <string[]>[, svgPathData];
    const framePadding = Math.ceil((Math.max(baseWidth, baseHeight)) * this.kFramePadding);
    const cornerRadius = Math.ceil((Math.max(baseWidth, baseHeight)) * this.kCornerRadius);
    const anchorWidth = Math.ceil((baseWidth + (2 * framePadding)) * this.kAnchorWidth);

    return {
      baseWidth,
      baseHeight,
      primarySvgPath,
      secondarySvgPath,
      framePadding,
      cornerRadius,
      anchorWidth,
      anchorHeight: anchorWidth + (anchorWidth / 2),
      hLineLength: baseWidth - (2 * (cornerRadius - framePadding)),
      vLineLength: baseHeight - (2 * (cornerRadius - framePadding)),
      cornerControlsDistance: cornerRadius / 2,
      ...options
    }
  }

  private generateFrameSvgPath(svgBuildoptions: SvgBuildOptions): string {
    const {
      framePadding,
      cornerRadius,
      anchorWidth,
      anchorHeight,
      hLineLength,
      vLineLength,
      cornerControlsDistance,
      frameColor,
    } = svgBuildoptions;

    return `<path fill="${frameColor}" d="
      M${cornerRadius - framePadding} -${framePadding}
      h${hLineLength}
      c${cornerControlsDistance},0 ${cornerRadius},${cornerControlsDistance} ${cornerRadius},${cornerRadius}
      v${vLineLength}
      c0,${cornerControlsDistance} -${cornerControlsDistance},${cornerRadius} -${cornerRadius},${cornerRadius}
      h-${(hLineLength - anchorWidth) / 2}
      l-${anchorWidth / 2},${anchorHeight}
      l-${anchorWidth / 2},-${anchorHeight}
      h-${(hLineLength - anchorWidth) / 2}
      c-${cornerControlsDistance},0 -${cornerRadius},-${cornerControlsDistance} -${cornerRadius},-${cornerRadius}
      v-${vLineLength}
      c0,-${cornerControlsDistance} ${cornerControlsDistance},-${cornerRadius} ${cornerRadius},-${cornerRadius}z">
    </path>`;
  }
}




