<div *ngIf="loading; else content">
  <div class="content">
    <div class="loading-overlay">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<ng-template #content>
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title">{{task.name}}</span>
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <mat-tab-group animationDuration="0ms">
      <mat-tab [label]="'general.labels.inspectionForm'|translate">
        <p class="rule-description quiet">
          {{task.maintenanceTaskCompletionInfo.ruleDescriptionAtCompletion}}
        </p>
        <div class="technical-field-list" fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <label>{{'general.labels.performedOutOn'|translate}}</label>
            <p>{{task.completedAtDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</p>
          </div>
          <div>
            <label>{{'general.labels.performedOutAtMileage'|translate}} ({{ 'km' | dimensionUnitDisplay }})</label>
            <p>{{task.completedAtMileage | dimensionUnitConverter: 'km'}}</p>
          </div>
          <div>
            <label>{{'general.labels.performedOutAtOperatingHours'|translate}}</label>
            <p>{{task.completedAtOperatingHours}}</p>
          </div>
        </div>
        <div class="technical-field-list" fxLayout="row"fxLayoutAlign="space-between stretch">
          <div *ngFor="let field of task.maintenanceTaskCompletionInfo.maintenanceFieldValues">
            <label>{{field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</label>
            <p *ngIf="field.maintenanceFieldType === FieldType.DATE">{{field.value | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</p>
            <p *ngIf="field.maintenanceFieldType !== FieldType.DATE">{{field.value}}</p>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'general.attachment.pl'|translate">
        <div class="technical-field-list" fxLayout="row"
             fxLayoutAlign="start stretch">
          <div fxFlex="25" class="imageDisplay"
               *ngIf="imageDisplay">
            <bh-image *ngIf="imageDisplay.fileExists" [src]="imageDisplay.documentKey"></bh-image>
            <a class="viewImageCloseBtn" (click)="viewImageClose()">
              <mat-icon [matTooltip]="'general.buttons.close'|translate">clear</mat-icon>
            </a>
          </div>
          <div fxFlex>
            <mat-table fxFlex="100%" class="pagination-data-table left-body-list" #table
                       [dataSource]="lcDocumentsDataSource">

              <ng-container cdkColumnDef="fileIcon">
                <mat-header-cell *cdkHeaderCellDef>{{'general.icon'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <fa-icon [icon]="getFileTypeIcon(row)" size="2x"></fa-icon>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileName">
                <mat-header-cell *cdkHeaderCellDef>{{'general.file'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileName">
                  <span>{{ row.fileName }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileType">
                <mat-header-cell *cdkHeaderCellDef>{{'general.type'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileType">
                  <span>{{ row.fileType }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileDescription">
                <mat-header-cell *cdkHeaderCellDef>{{'general.description'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileDescription">
                  <span>{{ row.fileDescription }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="actions">
                <mat-header-cell *cdkHeaderCellDef>{{'general.actions'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <a *ngIf="row.fileExists && isImageFile(row.fileName)" (click)="viewImage(row)">
                    <mat-icon [matTooltip]="'general.buttons.view'|translate">visibility</mat-icon>
                  </a>
                  <span *ngIf="row.fileExists">
                    <mat-icon (click)="downloadDocument(row)" [matTooltip]="'general.buttons.download'|translate">
                      cloud_download
                    </mat-icon>
                  </span>
                </mat-cell>
              </ng-container>

              <mat-header-row
                  *cdkHeaderRowDef="['fileIcon','fileName', 'fileDescription', 'fileType', 'actions']"></mat-header-row>
              <mat-row
                  *cdkRowDef="let myRowData; columns: ['fileIcon','fileName','fileDescription', 'fileType', 'actions']">
              </mat-row>
            </mat-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>
