import { Pipe, PipeTransform } from '@angular/core';
import { ChartData } from 'chart.js';
import { ChartDataTransformStrategy } from './chart-data-transform-strategy.interface';

@Pipe({
  name: 'chartData'
})
export class ChartDataPipe<InputType> implements PipeTransform {

  public transform(data: InputType, transformStrategy: ChartDataTransformStrategy<InputType>): ChartData {
    if(this.canTransform(data, transformStrategy)) {
      return {
        labels: transformStrategy.getChartDataLabels(data),
        datasets: transformStrategy.getChartDatasets(data),
      }
    }
    return null;
  }

  private canTransform(data: InputType, transformStrategy: ChartDataTransformStrategy<InputType>): boolean {
    return transformStrategy
      && transformStrategy.isValidData
      && transformStrategy.getChartDataLabels
      && transformStrategy.getChartDatasets
      && transformStrategy.isValidData(data);
  }

}
