<div class="telematic-assign-dialog" fxLayout="column">
  <div fxLayout="row">
    <mat-icon class="title-icon" fontSet="icon-telematics_outline"></mat-icon>
    <h2 mat-dialog-title>
      {{'modules.telematic.telematicList.assignToCustomerTitle' |translate}}
    </h2>
  </div>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close tabindex="-1">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-content">
    <div *ngIf="!showForm"
         class="step-content"
         fxLayout="column"
         fxLayoutAlign="start center"
         fxLayoutGap="15px">
      <fa-icon class="success-icon" [icon]="sucessIcon"></fa-icon>
      <h3>
        {{'modules.telematic.telematicAssign.successMessageUnitName'|translate}}
        <strong>{{telematicsUnit.telematicsUnitType}}</strong>
      </h3>
      <h3>
        {{'modules.telematic.telematicAssign.successMessageCustomerName'|translate}}
        <strong>{{customerName}}</strong>
      </h3>
    </div>
    <div *ngIf="showForm" class="step-content" fxLayout="column" fxLayoutGap="25px">
        <form
          class="telematic-assign-form"
          [formGroup]="assignForm"
          novalidate
          fxLayout="row"
          fxLayoutAlign="space-between end"
          fxLayoutGap="15px"
        >
          <mat-form-field class="unit-data-field">
            <mat-label>{{'modules.telematic.telematicList.selectCustomer'|translate}}</mat-label>
            <mat-select #customerSelector
                        required
                        formControlName="customer"
                        disableOptionCentering="true"
                        [ngModel]="selectedValue"
                        [compareWith]="comparer"
                        (selectionChange)="onCustomerChange(customerSelector.value)">
              <mat-select-trigger>
                {{selectedCustomer.customerName || selectedValue.customerName}}
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [formControl]="customersNameFilter"
                                       [placeholderLabel]="'general.labels.search'|translate:{ value: ('modules.telematic.telematicList.customerName'|translate) }"
                                       [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('modules.telematic.telematicList.customerName'|translate) }"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let customer of customersDropdownList" [value]="customer">
                <bh-configurable-dialog-fields [item]="customer"></bh-configurable-dialog-fields>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assignForm.get('customer').hasError('required')">
              {{'shared.validation.requiredField'|translate}}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    <div *ngIf="showForm" fxLayout="row" fxLayoutGap="15px" class="button-bottom-wrapper"  fxLayoutAlign="space-between center">
      <button class="bottom-button" mat-dialog-close mat-raised-button type="button">
        {{'general.buttons.cancel'|translate}}
      </button>
      <button class="bottom-button"
              mat-raised-button
              bhTimeoutSaveButton
              [disabled]="isDisabled()"
              color="primary"
              (throttledClick)="save()">
        {{'general.buttons.save'|translate}}
      </button>
    </div>
    <div *ngIf="!showForm" fxLayout="row" fxLayoutGap="15px" class="button-bottom-wrapper" fxLayoutAlign="end center">
        <button class="bottom-button"
                mat-dialog-close
                mat-raised-button
                color="primary"
                type="button">
          {{'general.buttons.ok'|translate}}
        </button>
      </div>
  </mat-dialog-content>
</div>
