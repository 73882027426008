<div fxLayout="row" fxLayoutGap="15px">
  <div fxFlex="65">
    <bh-label-chips (labelAdded)="addLabel($event)"
                    (labelDeleted)="deleteLabel($event)"
                    [customerLabels]="customerLabels | async"
                    [hasAuthority]="hasAuthority(authorities.EQUIPMENT_MANAGE_LABELS) &&
                    equipmentCheckerService.isActiveEquipment(equipment)"
                    [labels]="equipmentLabels | async">
    </bh-label-chips>
  </div>
  <div fxFlex="35" class="status-change-container">
    <bh-equipment-status-state-change [disabled]="!hasAnyAuthority([
                                      authorities.EQUIPMENT_UPDATE,
                                      authorities.EQUIPMENT_STATUS_UPDATE_ANY,
                                      authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE,
                                      authorities.EQUIPMENT_STATUS_UPDATE_CATEGORY])"
                                      [equipment]="equipment">
    </bh-equipment-status-state-change>
  </div>
</div>

<!--TODO: add defaultValue pipe-->
<div class="equipment-details" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">

  <div class="equipment-view-detail__content" fxFlex="65" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="inputs-line inputs-line__bold" fxLayout="row" fxLayoutAlign="space-between start"
         fxLayoutGap="15px">
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'general.intNumber.abbr'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentCustomerSerialNumber}}">
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'modules.equipment.base.manufacturerSerialNumber'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentSerialNumber}}">
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always" *ngIf="equipment?.equipmentBeutlhauserId">
        <mat-label>{{'modules.equipment.base.beutlhauserId'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentBeutlhauserId}}">
      </mat-form-field>
    </div>

    <div *ngIf="equipment?.currentRental" class="inputs-line inputs-line__bold equipment-status-info" fxLayout="row"
         fxLayoutAlign="space-between center" fxLayoutGap="15px">

      <mat-form-field fxFlex="50" floatLabel="always">
        <mt-label>{{'modules.equipment.base.rentalStart'|translate}}</mt-label>
        <input matInput readonly value="{{equipment?.currentRental.startDate}}">
      </mat-form-field>

      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'modules.equipment.base.rentalEnd'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.currentRental.endDate}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field floatLabel="always" fxFlex>
        <mat-label>{{'general.costCenter'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentCostCenter}}">
      </mat-form-field>
      <mat-form-field floatLabel="always" fxFlex>
        <mat-label>{{'general.org.s'|translate}}</mat-label>
        <input matInput readonly
               value="{{equipment?.organisationName}}">
        <mat-icon
          *ngIf="hasAuthority(authorities.EQUIPMENT_UPDATE) && equipmentCheckerService.isActiveEquipment(equipment)"
          matSuffix
          [matTooltip]="'modules.equipment.equipmentDetailGeneral.assignToOrganization'|translate"
          (click)="assignToOrganisation()">
          mode_edit
        </mat-icon>
      </mat-form-field>

      <mat-form-field fxFlex floatLabel="always" *ngIf="this.hasModule(modules.BULK_ITEM_MANAGEMENT)">
        <mat-label>{{'modules.equipment.equipmentDetailGeneral.actualPosition'|translate}}</mat-label>
        <input matInput readonly
               value="{{equipment?.projectName ? equipment?.projectName : equipment?.stockName}}">
        <mat-icon *ngIf="isAllowedToSeeLink | async" [matTooltip]="resolveTooltip()"
                  (click)="navigateToStockOrProject()" matSuffix>
          open_in_new
        </mat-icon>
      </mat-form-field>

    </div>
    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'general.manufacturer'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.manufacturer?.manufacturerName}}">
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'modules.equipment.base.model.s'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentModel}}">
      </mat-form-field>
      <mat-form-field *ngIf="equipment?.equipmentLicensePlate" fxFlex="50" floatLabel="always">
        <mat-label>{{'modules.equipment.base.mark'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentLicensePlate}}">
      </mat-form-field>
    </div>
    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'general.type'|translate}}</mat-label>
        <input matInput readonly
          [value]="(equipment?.equipmentType?.category1Name | translatableString)
            || ('general.unknown' | translate | lowercase)">
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'general.class'|translate}}</mat-label>
        <input matInput readonly
          [value]="(equipment?.equipmentType?.category2Name | translatableString)
            || ('general.unknown'|translate|lowercase)">
        <mat-icon *ngIf="allowTypeEdit() && equipmentCheckerService.isActiveEquipment(equipment)" matSuffix
                  [matTooltip]="'modules.equipment.base.editEquipmentType'|translate"
                  (click)="equipmentEditType()">
          mode_edit
        </mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="equipment?.equipmentBGLCode as bglCode" fxFlex="20" floatLabel="always" data-osp-test="bgl-field">
        <mat-label>{{'general.BGLCode'|translate}}</mat-label>
        <input matInput readonly [value]="bglCode">
      </mat-form-field>
    </div>
    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'modules.equipment.base.constrYear'|translate}}</mat-label>
        <input matInput readonly
               value="{{equipment?.equipmentConstructionYear | constructionYearFormat}}">
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always" *ngIf="equipment?.equipmentRegistrationDate">
        <mat-label>{{'modules.equipment.base.firstRegistration'|translate}}</mat-label>
        <input matInput readonly
               value="{{equipment?.equipmentRegistrationDate | formatTimezoneDateTime}}">
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always">
        <mat-label>{{'general.labels.linkedScanCode'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentScanCode}}">
        <mat-icon *ngIf="allowScanCodeEdit() && equipmentCheckerService.isActiveEquipment(equipment)"
                  matSuffix [matTooltip]="'modules.equipment.equipmentDetailGeneral.editScanCode'|translate"
                  (click)="equipmentEditScanCode()">
          mode_edit
        </mat-icon>
      </mat-form-field>
    </div>
    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'modules.equipment.base.deliveryDate'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentDeliveryDate | formatTimezoneDateTime}}">
      </mat-form-field>
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'modules.equipment.base.warrantyPeriod'|translate}}</mat-label>
        <input matInput readonly value="{{transformGuaranteeString()}}">
      </mat-form-field>
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'modules.equipment.base.warrantyPeriodEnd'|translate}}</mat-label>
        <input matInput readonly value="{{guaranteeEndDate | formatTimezoneDateTime}}">
      </mat-form-field>
      <mat-form-field fxFlex floatLabel="always" *ngIf="equipment?.equipmentGuaranteeOperatingHoursLimit">
        <mat-label>{{'modules.equipment.base.operatingHoursLimit'|translate}}</mat-label>
        <input matInput readonly value="{{equipment?.equipmentGuaranteeOperatingHoursLimit}}">
      </mat-form-field>
    </div>
    <div fxLayout="row" class="inputs-line" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex="50" floatLabel="always"
                      *ngIf="allowedLiveData(equipment?.hasLiveOperatingHours)">
        <mat-label>{{'general.operatingHour.pl'|translate}}</mat-label>
        <input matInput readonly
               [value]="equipment?.currentOperatingHours
                ? (equipment.currentOperatingHours | operatingHours)
                : 'general.noData'|translate">
        <mat-icon
          *ngIf="allowManualLifecycle(equipment?.hasLiveOperatingHours, authorities.EQUIPMENT_MANAGE_OPERATING_HOURS)"
          matSuffix
          [matTooltip]="'modules.equipment.base.logOperatingHours'|translate"
          (click)="equipmentLogOperatingHours()">
          mode_edit
        </mat-icon>
        <mat-icon *ngIf="equipment?.hasLiveOperatingHours || hasTelematicsLiveData()"
                  matSuffix
                  fontSet="icon-telematics_outline"
                  [matTooltip]="'modules.equipment.equipmentDetailGeneral.loggedByTelematics'|translate">
        </mat-icon>
      </mat-form-field>
      <mat-form-field fxFlex="50" floatLabel="always"
                      *ngIf="allowedLiveData(equipment?.hasLiveMileage)">
        <mat-label>{{'general.mileage'|translate}} ({{ 'km' | dimensionUnitDisplay }})</mat-label>
        <input matInput readonly
               value="{{equipment?.currentMileage ? (equipment?.currentMileage | dimensionUnitConverter:'km' | mileage) : 'general.noData'|translate}}">
        <mat-icon
          *ngIf="allowManualLifecycle(equipment?.hasLiveMileage, authorities.EQUIPMENT_MANAGE_MILEAGE)"
          matSuffix
          [matTooltip]="'modules.equipment.base.logMileage'|translate"
          (click)="equipmentLogMileage()">
          mode_edit
        </mat-icon>
        <mat-icon *ngIf="equipment?.hasLiveMileage || hasTelematicsLiveData()"
                  matSuffix
                  fontSet="icon-telematics_outline"
                  [matTooltip]="'modules.equipment.equipmentDetailGeneral.loggedByTelematics'|translate">
        </mat-icon>
      </mat-form-field>
    </div>

    <div fxLayout="row" class="inputs-line">
      <bh-equipment-additional-general-fields></bh-equipment-additional-general-fields>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px"
         *ngIf="parentEquipment && parentEquipment.equipmentId">
      <div fxFlex>
        <span>{{'general.container'|translate}}: </span>
        <span [ngClass]="{ 'link': parentEquipment.allowedToSee }"
              (click)="parentEquipment.allowedToSee && navigateToParentContainer()">
          {{parentEquipment.equipmentName || parentEquipment.equipmentModel}}
        </span>
      </div>
    </div>

    <bh-subtitle *ngIf="showEmployeeAssignments" [text]="'general.responsible'|translate"></bh-subtitle>
    <bh-equipment-employee-assignee-cards *ngIf="showEmployeeAssignments"></bh-equipment-employee-assignee-cards>
  </div>

  <div class="equipment-detail__map_gallery" fxFlex="35" fxLayout="column">
    <bh-gallery-detail-view
      *ngIf="imageKeys?.length > 0"
      [keys]="imageKeys">
    </bh-gallery-detail-view>

    <bh-equipment-view-map-address [equipment]="equipment"></bh-equipment-view-map-address>
  </div>
</div>
