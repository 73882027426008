import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { DateRange } from './date-range';
import { UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { DateValidator } from '../../custom-validators/date.validator';
import moment, { Duration } from 'moment';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { DateUtils } from '../../dateUtils';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'bh-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss']
})
@UntilDestroy()
export class DateRangeSelectComponent implements OnDestroy {
  @Input() resettable = false;

  @Input() minStartDate: Date;
  @Input() maxStartDate: Date;
  @Input() startRequired = true;

  @Input() minEndDate: Date;
  @Input() maxEndDate: Date;
  @Input() endRequired = false;

  @Input() allowFutureDates = true;
  @Input() flexOrientation: 'row' | 'column' = 'row';
  @Input() showErrors = true;
  @Input() startDateDisabledFlagToDisablingReasonMessageTuple: [boolean, string] = [false, null];
  @Input() errorMatcher: ErrorStateMatcher = new ErrorStateMatcher();

  @Input() public set maxInterval(interval: Duration) {
    this._maxInterval = interval;
    this.refreshValidators();
  }
  public get maxInterval(): Duration {
    return this._maxInterval;
  }

  @Input() public set startDateControl(control: UntypedFormControl) {
    this._startDateControl = control;
    this.startDateControlOriginalValidator = this._startDateControl.validator;
    this.refreshValidators();
  };
  public get startDateControl(): UntypedFormControl {
    return this._startDateControl;
  }

  @Input() public set endDateControl(control: UntypedFormControl) {
    this._endDateControl = control;
    this.endDateControlOriginalValidator = this._endDateControl.validator;
    this.refreshValidators();
  };
  public get endDateControl(): UntypedFormControl {
    return this._endDateControl;
  }

  @Output() onDateChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  private _maxInterval: Duration = null;
  private _startDateControl: UntypedFormControl;
  private _endDateControl: UntypedFormControl;
  private startDateControlOriginalValidator: ValidatorFn;
  private endDateControlOriginalValidator: ValidatorFn;

  constructor(private languageService: LanguageService) {
  }

  ngOnDestroy(): void {
  }

  private refreshValidators(): void {
    this.addValidators();
    this.initDateRangeChangeEmitter();
  }

  private addValidators(): void {
    if (this._endDateControl) {
      this.addEndValidators();
    }
    if (this._startDateControl && this._endDateControl) {
      this.addStartValidators();
    }
  }

  private addStartValidators() {
    const startValidators = this.startDateControlOriginalValidator ? [this.startDateControlOriginalValidator] : [];
    startValidators.push(DateValidator.controlDateNotAfter(this.endDateControl));
    startValidators.push(DateValidator.yearWrongFormat(this.startDateControl, this.languageService));
    if (this.startRequired) {
      startValidators.push(
        Validators.required,
        DateValidator.isValidDate(this.languageService)
      );
    }
    if (this.maxInterval) {
      startValidators.push(DateValidator.maxInterval(this.endDateControl, this.maxInterval, this.languageService));
    }
    if (!this.allowFutureDates) {
      startValidators.push(DateValidator.noFutureDate(this.languageService));
    }
    this.startDateControl.setValidators(startValidators);
  };

  private addEndValidators() {
    const endValidators = this.endDateControlOriginalValidator ? [this.endDateControlOriginalValidator] : [];
    if (this.endRequired) {
      endValidators.push(
        Validators.required,
        DateValidator.isValidDate(this.languageService)
      );
    }
    if (!this.allowFutureDates) {
      endValidators.push(DateValidator.noFutureDate(this.languageService));
    }
    this.endDateControl.setValidators(endValidators);
  }

  private initDateRangeChangeEmitter() {
    if (this._startDateControl && this._endDateControl) {
      const startDateObservable = this.startDateControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          tap(() => this.startDateControl.updateValueAndValidity({onlySelf: false})));

      const endDateObservable = this.endDateControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          tap(() => this.endDateControl.updateValueAndValidity({onlySelf: false})));

      startDateObservable
        .pipe(
          untilDestroyed(this),
          debounceTime(10))
        .subscribe((start: Date) => {
          this.onDateChange.emit(new DateRange(start, this.endDateControl.value));
        });

      endDateObservable
        .pipe(
          untilDestroyed(this),
          debounceTime(10))
        .subscribe((end: Date) => {
          this.onDateChange.emit(new DateRange(this.startDateControl.value, end));
        });
    }
  }

  public getMinStartValue(): Date {
    return this.minStartDate;
  }

  public getMaxStartValue(): Date {
    let maxDate = this.maxStartDate
      || (this.endDateControl && this.endDateControl.value)
      || null;
    if (!this.allowFutureDates) {
      return DateUtils.minDate(maxDate, new Date());
    }
    return maxDate;
  }

  public getMinEndValue(): Date {
    return DateUtils.maxDate(this.minEndDate, (this.startDateControl && this.startDateControl.value));
  }

  public getMaxEndValue(): Date {
    if (!this.allowFutureDates) {
      return DateUtils.minDate(this.maxEndDate, new Date());
    }
    return this.maxEndDate;
  }

  public reset(): void {
    this.startDateControl.patchValue(new Date());
    this.endDateControl.patchValue(null);
  }

  public setDate(dateRange: DateRange): void {
    this.startDateControl.patchValue(dateRange.startDate);
    this.endDateControl.patchValue(dateRange.endDate);
  }

  public startDisabled(): boolean {
    return this.startDateDisabledFlagToDisablingReasonMessageTuple[0]
      ? true
      : this.maxStartDate && this.minStartDate && moment(this.maxStartDate).isSame(moment(this.minStartDate));
  }

  public endDisabled(): boolean {
    return this.maxEndDate && this.minEndDate && moment(this.maxEndDate).isSame(moment(this.minEndDate));
  }
}
