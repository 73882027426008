import { Address } from './address.interface';

export class UpdateCustomerCommand {
  customerId: string;
  customerName: string;
  customerNumber: string;
  customerAddress: Address;
  customerTypeList: Array<string>;
  customerImported: boolean;
  hasAcknowledgeAssignmentWorkflow: boolean;
  hasTransferRequestWorkflow: boolean;
  hasTypeOfUseWorkflow: boolean;
  hasStockTransferRequestWorkflow: boolean;
  activateTransferRequestAutoAcceptance: boolean;
  daysForTransferRequestAutoAcceptance: number;
}
