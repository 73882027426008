import { Injectable } from '@angular/core';
import { EquipmentLocationTypePipe } from 'app/modules/maintenance/shared/pipes/equipment-location-type.pipe';
import { EquipmentLocationsFilterViewConverter } from 'app/shared/contract/filter/filter-converter/equipment-locations-filter-view-converter.class';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { GroupedEquipmentLocationFilterView } from 'app/shared/contract/filter/filter-view/grouped-equipment-location-filter-view.interface';

@Injectable()
export class EquipmentLocationsFilterViewConverterFactoryService {

  constructor(protected equipmentLocationTypeDisplayPipe: EquipmentLocationTypePipe) {
  }

  public createEquipmentLocationsFilterViewConverter(originalFilters: GroupedEquipmentLocationFilterView[], selectedValues: FilterParams) {
    return new EquipmentLocationsFilterViewConverter(originalFilters, selectedValues, this.equipmentLocationTypeDisplayPipe);
  }
}
