import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { TransferListItemType } from '../../../modules/disposition/contract/transfer-list-item-type';
import { TransferItemType } from 'app/modules/disposition/shared/transfer/model/transfer-item-type';

@Pipe({
  name: 'transferItem'
})
export class TransferItemPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {}

  transform(value: any, mode: string, args?: any): any {

    switch (mode) {
      case TransferItemPipeMode.URL:
        return this.resolveUrl(value);
      case TransferItemPipeMode.TOOLTIP:
        return this.resolveTooltip(value);
      default: return '';
    }
  }

  private resolveUrl(transferItemType: string): string {

    switch (transferItemType) {
      case TransferItemType.BULKITEM:
      case TransferListItemType.STANDARD_BULK_ITEM:
      case TransferListItemType.CONSUMABLE_BULK_ITEM:
        return '/assets/bulk-item/list/';
      case TransferListItemType.EQUIPMENT:
        return '/assets/equipment/list/';
      default:
        return '';
    }
  }

  private resolveTooltip(transferItemType: string): string {

    switch (transferItemType) {
      case TransferItemType.BULKITEM:
      case TransferListItemType.STANDARD_BULK_ITEM:
      case TransferListItemType.CONSUMABLE_BULK_ITEM:
        return this.translate('general.bulkItem');
      case TransferListItemType.EQUIPMENT:
        return this.translate('general.equipm.s');
      default:
        return this.translate('general.bulkItemType.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}

export enum TransferItemPipeMode {
  URL = 'URL',
  TOOLTIP = 'TOOLTIP'
}
