<div class="list-view-main__right" fxFlexFill fxLayout="column">
  <div class="list-view-main__right-header"
    fxFlex="60px"
    fxLayout="row"
    fxLayoutAlign="space-between center">

    <div *ngIf="displayService.isTableAndDetailsChanges | async"
      class="full-screen"
      fxFlex="0 0 70px"
      fxLayout="row"
      fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.enlarge' | translate"
      (click)="displayService.displayDetailsFullscreen()" >
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div *ngIf="displayService.isDetailsFullscreenChanges | async"
      class="full-screen"
      fxFlex="0 0 70px"
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltip]="'general.labels.detailView.reduce' | translate"
      (click)="displayService.displayTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>

    <div class="name" fxFlex>
      <span>
        {{(currentTask | async)?.name}}
      </span>
    </div>

    <div *ngIf="showMenu" class="more" fxFlex="0 0 20px">
      <bh-maintenance-completed-task-action-button
        [task]="currentTask | async"
        [showDetailsButton]="false"
        (edit)="editCompletedTask($event)"
        (delete)="deleteCompletedTask($event)">
      </bh-maintenance-completed-task-action-button>
    </div>

    <div *ngIf="!(displayService.isTableFullscreenChanges | async)"
      class="change-display-mode"
      fxFlex="0 0 60px"
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltip]="'general.labels.detailView.collapse' | translate"
      (click)="displayService.displayTableFullscreen()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="content" fxFlex>
      <bh-maintenance-task-completed-view-detail [task]="currentTask | async"></bh-maintenance-task-completed-view-detail>
    </div>
  </div>
</div>
