import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../shared/enums/modules.enum';
import { DispositionProcessOption } from './disposition-process-option';
import { DispositionProcessOptionResolver } from './disposition-process-option.resolver';

@Component({
  selector: 'bh-mobile-choose-disposition-process',
  templateUrl: './mobile-choose-disposition-process.component.html',
  styleUrls: ['./mobile-choose-disposition-process.component.scss']
})
export class MobileChooseDispositionProcessComponent implements OnInit {

  public options: string[] = Object.keys(DispositionProcessOption);

  constructor(public resolver: DispositionProcessOptionResolver,
              private router: Router,
              private authService: KeycloakService) { }

  public ngOnInit(): void {
    const canManageEquipments = this.authService.hasAnyAuthority([Authorities.ASSET_FROM_STOCK_TRANSFER,
      Authorities.ASSET_FROM_PROJECT_TRANSFER])
    const canManageBulkItems = this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)
      && this.authService.hasAnyAuthority([Authorities.ASSET_FROM_STOCK_TRANSFER,
        Authorities.ASSET_FROM_PROJECT_TRANSFER]);
    if (canManageBulkItems && ! canManageEquipments) {
      this.router.navigate(['mobile/transfer/bulk-items']);
    } else if (canManageEquipments && !canManageBulkItems) {
      this.router.navigate(['mobile/equipments/transfer-to-project/scan-code']);
    } else if (!canManageBulkItems && !canManageEquipments) {
      this.router.navigate(['mobile/landingpage']);
    }
  }
}
