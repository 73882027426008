import { RequestStatus } from '../shared/enums/request-status.enum';
import { TransferItem } from '../shared/transfer/model/transfer-item';
import { InsertProjectTypeOfUseTimelineEntryCommand } from './insert-project-type-of-use-timeline-entry-command';

export class ChangeTypeOfUseRequest {

  constructor(public command: InsertProjectTypeOfUseTimelineEntryCommand,
     public status: RequestStatus,
     public transferItem: TransferItem,
     public errorText?: string) {}
}
