import {Component, Input} from '@angular/core';
import { IconDefinition, faMap } from '@fortawesome/pro-light-svg-icons';
import {MapConsts} from '../../../map.consts';

@Component({
  selector: 'bh-map-jump-google',
  templateUrl: './map-jump-google.component.html',
  styleUrls: ['./map-jump-google.component.scss']
})
export class MapJumpGoogleComponent {
  @Input() lat: number;
  @Input() lon: number;

  protected readonly faMap: IconDefinition = faMap;

  openGoogleMaps(): void {
    if (this.lat && this.lon) {
      const url = MapConsts.GOOGLE_MAP_URL + `?q=${this.lat},${this.lon}`;
      window.open(url, '_blank');
    }
  }
}
