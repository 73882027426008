import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TelematicUnitCustomerEquipmentInfo } from '../shared/telematic-unit-customer-equipment-info';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { LanguageService } from '../../../shared/services/language.service';
import { FormControl, FormGroup } from '@angular/forms';
import { get, isNull, isUndefined } from 'lodash';
import { VoltagePipe } from '../../../shared/pipes/voltage.pipe';
import { MileagePipe } from '../../../shared/pipes/mileage.pipe';
import { OperatingHoursPipe } from '../../../shared/pipes/operating-hours.pipe';
import { skip } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TelematicLiveDataDatasource } from '../../equipment/shared/telematic-datasource.interface';
import { TelematicsLoadingState } from '../../equipment/shared/telematics-loading-state.enum';
import { BatteryStatus } from '../../../shared/enums/battery-status.enum';

interface EquipmentDataFormGroup {
  lastUnitDate: FormControl<string>;
  lastGpsDate: FormControl<string>;
  satellites: FormControl<string>;
  gsmSignal: FormControl<string>;
  gnssStatus: FormControl<string>;
  digitalInput1: FormControl<string>;
  digitalInput2: FormControl<string>;
  digitalInput3: FormControl<string>;
  analogInput1: FormControl<string>;
  powerSupplyVoltage: FormControl<string>;
  batteryVoltage: FormControl<string>;
  distanceOdometerInKm: FormControl<string>;
  cumulativeOperatingHours: FormControl<string>;
  lastOperatingHoursDate: FormControl<string>;
  lastOdometerInKmDate: FormControl<string>;
  imei: FormControl<string>;
  customerSerialNumber: FormControl<string>;
  equipmentName: FormControl<string>;
  manufacturerSerialNumber: FormControl<string>;
}

export interface TelematicUnitLiveDataDialogData<T> {
  telematicsUnitCustomerEquipmentInfo: TelematicUnitCustomerEquipmentInfo
  datasource: TelematicLiveDataDatasource<T>,
  liveData: T
}

@UntilDestroy()
@Component({
  selector: 'bh-telematic-unit-live-data-dialog',
  templateUrl: './telematic-unit-live-data-dialog.component.html',
  styleUrls: ['./telematic-unit-live-data-dialog.component.scss']
})
export class TelematicUnitLiveDataDialogComponent<T> implements OnInit, OnDestroy {
  public liveData: T;
  public telematicsUnitCustomerEquipmentInfo: TelematicUnitCustomerEquipmentInfo;
  public loadingState: TelematicsLoadingState = TelematicsLoadingState.READY;
  public loadingDate: Date;
  public unitForm: FormGroup<EquipmentDataFormGroup>;
  private datasource: TelematicLiveDataDatasource<T>;
  private dataNotAvailableText: string;

  batteryStatus: BatteryStatus = BatteryStatus.UNKNOWN;

  constructor(private dialogRef: MatDialogRef<TelematicUnitLiveDataDialogComponent<T>>,
              @Inject(MAT_DIALOG_DATA) data: TelematicUnitLiveDataDialogData<T>,
              private languageService: LanguageService,
              private voltagePipe: VoltagePipe,
              private mileagePipe: MileagePipe,
              private operatingHoursPipe: OperatingHoursPipe,
  ) {
    this.datasource = data.datasource;
    this.setLiveData(data.liveData);
    this.telematicsUnitCustomerEquipmentInfo = data.telematicsUnitCustomerEquipmentInfo;
    this.dataNotAvailableText =
      this.languageService.getInstant('modules.telematic.telematicUnitLiveData.dataNotAvailable');
  }

  private setLiveData(liveData: T) {
    this.liveData = liveData;
    this.loadingDate = new Date();
  }

  public ngOnInit(): void {
    this.datasource.setCurrentImei(this.telematicsUnitCustomerEquipmentInfo.imei);
    this.buildForm();
    this.initializeData();
    this.telematicsDataListener();
    this.datasource.startAutoUpdate(60000, false);
  }

  public ngOnDestroy(): void {
    this.datasource.stopAutoUpdate();
  }

  public fetchClearData() {
    this.datasource.startAutoUpdate(60000);
  }

  public getRefreshButtonTooltip(): string {
    if (this.isReady()) {
      return this.languageService.getInstant('modules.telematic.telematicUnitLiveData.lastUpdate', {
        date: moment(this.loadingDate).format('DD.MM.YYYY HH:mm:ss')
      });
    } else if (this.isError()) {
      return this.languageService.getInstant('modules.telematic.telematicUnitLiveData.errorUpdate', {
        date: moment(this.loadingDate).format('DD.MM.YYYY HH:mm:ss')
      });
    }
  }

  public isReady(): boolean {
    return this.loadingState === TelematicsLoadingState.READY;
  }

  public isLoading(): boolean {
    return this.loadingState === TelematicsLoadingState.LOADING;
  }

  public isError(): boolean {
    return this.loadingState === TelematicsLoadingState.ERROR;
  }

  private buildForm(): void {
    this.unitForm = new FormGroup<EquipmentDataFormGroup>({
      lastUnitDate: new FormControl({ value: '', disabled: true }),
      lastGpsDate: new FormControl({ value: '', disabled: true }),
      satellites: new FormControl({ value: '', disabled: true }),
      gsmSignal: new FormControl({ value: '', disabled: true }),
      gnssStatus: new FormControl({ value: '', disabled: true }),
      digitalInput1: new FormControl({ value: '', disabled: true }),
      digitalInput2: new FormControl({ value: '', disabled: true }),
      digitalInput3: new FormControl({ value: '', disabled: true }),
      analogInput1: new FormControl({ value: '', disabled: true }),
      powerSupplyVoltage: new FormControl({ value: '', disabled: true }),
      batteryVoltage: new FormControl({ value: '', disabled: true }),
      distanceOdometerInKm: new FormControl({ value: '', disabled: true }),
      cumulativeOperatingHours: new FormControl({ value: '', disabled: true }),
      lastOperatingHoursDate: new FormControl({ value: '', disabled: true }),
      lastOdometerInKmDate: new FormControl({ value: '', disabled: true }),
      imei: new FormControl({ value: '', disabled: true }),
      customerSerialNumber: new FormControl({ value: '', disabled: true }),
      equipmentName: new FormControl({ value: '', disabled: true }),
      manufacturerSerialNumber: new FormControl({ value: '', disabled: true }),
    });
  }

  private telematicsDataListener(): void {
    this.datasource.getLoadingState()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe((state: TelematicsLoadingState) => {
        this.loadingState = state;
        if (state === TelematicsLoadingState.ERROR) {
          this.datasource.stopAutoUpdate();
        }
      });
    this.datasource.getLiveData()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe((data: T) => {
        this.setLiveData(data);
        this.initializeData();
      });
  }

  private initializeData(): void {
    this.unitForm.patchValue({
      lastUnitDate: this.getValue('lastUnitDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      lastGpsDate: this.getValue('lastGpsDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      satellites: this.getValue('satellites'),
      gsmSignal: this.getValue('gsmSignal'),
      gnssStatus: this.getValue('gnssStatus'),
      digitalInput1: this.getValue('digitalInput1',
        val => val === 1 ? this.getSwitchOnString() : this.getSwitchOffString()),
      digitalInput2: this.getValue('digitalInput2',
        val => val === 1 ? this.getSwitchOnString() : this.getSwitchOffString()),
      digitalInput3: this.getValue('digitalInput3',
        val => val === 1 ? this.getSwitchOnString() : this.getSwitchOffString()),
      analogInput1: this.getValue('analogInput1',
        val => this.voltagePipe.transform(val)),
      powerSupplyVoltage: this.getValue('powerSupplyVoltage',
        val => this.voltagePipe.transform(val)),
      batteryVoltage: this.getValue('batteryVoltage',
        val => this.voltagePipe.transform(val)),
      distanceOdometerInKm: this.getValue('distanceOdometerInKm',
        val => this.mileagePipe.transform(val)),
      cumulativeOperatingHours: this.getValue('cumulativeOperatingHours',
        val => this.operatingHoursPipe.transform(val)),
      lastOperatingHoursDate: this.getValue('lastOperatingHoursDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      lastOdometerInKmDate: this.getValue('lastOdometerInKmDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      imei: this.telematicsUnitCustomerEquipmentInfo.imei,
      customerSerialNumber: this.telematicsUnitCustomerEquipmentInfo.customerSerialNumber,
      equipmentName: this.telematicsUnitCustomerEquipmentInfo.equipmentName,
      manufacturerSerialNumber: this.telematicsUnitCustomerEquipmentInfo.manufacturerSerialNumber
    });
    const foundBatteryStatus = this.getValue('batteryStatus', val => val);
    this.batteryStatus = foundBatteryStatus !== this.dataNotAvailableText
      ? foundBatteryStatus
      : BatteryStatus.UNKNOWN;
  }

  private getSwitchOnString(): string {
    return this.languageService.getInstant('general.switch.on');
  }

  private getSwitchOffString(): string {
    return this.languageService.getInstant('general.switch.off');
  }

  private getValue(key: string, mapper: (val: any) => any = (val) => val): any {
    let value = get(this.liveData, key);
    if (!isUndefined(value) && !isNull(value)) {
      return mapper(get(this.liveData, key));
    }

    return this.dataNotAvailableText;
  }
}
