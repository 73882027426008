import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EquipmentMultiseriesItem } from 'app/modules/equipment/contract/equipment-multiseries-item';
import { MultiSeriesTransformStrategy } from 'app/shared/pipes/chart/strategies/multi-series-transform-strategy.class';
import { TransformStrategyDatasetOptions } from 'app/shared/pipes/chart/strategies/transform-strategy-options.interface';
import { CustomThemeService } from 'app/shared/services/custom-theme.service';
import { LanguageService } from 'app/shared/services/language.service';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'bh-equipment-telematics-external-voltage',
  templateUrl: './equipment-telematics-external-voltage.component.html',
  styleUrls: ['../equipment-chart.style.scss', './equipment-telematics-external-voltage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentTelematicsExternalVoltageComponent implements OnInit {
  @Input() set value (value: EquipmentMultiseriesItem[]) {
    this.data = value;
  }

  public data: EquipmentMultiseriesItem[] = [];
  public type: ChartType = 'line';
  public strategy: MultiSeriesTransformStrategy;
  public readonly options: ChartOptions = {
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: this.translate('general.units.voltage.pl'),
        }
      },
    },
    plugins: {
      tooltip: {
        intersect: false
      },
      datalabels: <any>false,
    }
  };

  constructor(
    private customThemeService: CustomThemeService,
    private languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.strategy = new MultiSeriesTransformStrategy(this.getStrategyOptions());
  }

  private getStrategyOptions(): TransformStrategyDatasetOptions[] {
    const themeSettings = this.customThemeService.getThemeSettings();
    return [
      {
        seriesName: 'minVoltage',
        color: themeSettings.chartNegativeValueColor,
        label: this.translate('general.min'),
      },
      {
        seriesName: 'maxVoltage',
        color: themeSettings.chartPositiveValueColor,
        label: this.translate('general.max'),
      },
    ];
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
