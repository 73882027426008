import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { TypeOfUseStatusReason } from '../enums/type-of-use-status-reason.enum';

@Pipe({
  name: 'typeOfUseStatusReason'
})

export class TypeOfUseStatusReasonPipe implements PipeTransform {

  constructor (protected translateService: LanguageService) {}

  public transform (value: any): string {
    switch (value) {
      case TypeOfUseStatusReason.BAD_WEATHER:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.badWeather')
      case TypeOfUseStatusReason.ACCIDENT:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.accident');
      case TypeOfUseStatusReason.CONSTRUCTION_DELAY:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.constructionDelay');
      case TypeOfUseStatusReason.OTHER:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.other');
    }
  }
}
