import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CustomThemeService } from 'app/shared/services/custom-theme.service';
import { LanguageService } from 'app/shared/services/language.service';
import { Utils } from 'app/shared/utils';
import { Chart, ChartData, ChartOptions, ChartType } from 'chart.js';
import _ from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'bh-equipment-telematics-single-bar',
  templateUrl: './equipment-telematics-single-bar.component.html',
  styleUrls: ['../equipment-chart.style.scss', './equipment-telematics-single-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentTelematicsSingleBarComponent implements AfterViewInit {
  @ViewChild('chartContainer') chartElemRef: ElementRef;
  @Input() public set value(value: number) {
    if (this.isViewInit) {
      this.updateChart(value);
    } else {
      this.convertToChartData(value);
    }
  };
  @Input() public subtitle: string;
  @Input() public lastUpdate: Date | string;
  @Input() public label: string;

  public transformedData: ChartData;
  public type: ChartType = 'bar';
  public readonly options: ChartOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        labels: {
          name: {
            font: {size: 16},
            formatter: value => value + '%',
          },
        },
      },
      tooltip: {
        intersect: false,
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: this.translate('general.units.percent.pl'),
        },
        stacked: true,
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
  };
  private chart: Chart;
  private isViewInit = false;

  constructor(
    private customThemeService: CustomThemeService,
    private languageService: LanguageService,
  ) {}

  public ngAfterViewInit(): void {
    this.isViewInit = true;
    this.buildChart();
  }

  private updateChart(value: number): void {
    this.convertToChartData(value);
    this.buildChart();
  }

  private convertToChartData(value: number): void {
    const themeSettings = this.customThemeService.getThemeSettings();
    this.transformedData = !_.isNil(value)
      ? {
          labels: [`${this.translate('general.units.percent.pl')}, %`],
          datasets: [{
            data: [Utils.round(value, 1)],
            backgroundColor: themeSettings.chartPrimaryColor,
            hoverBackgroundColor: themeSettings.chartPrimaryColor,
            hoverBorderColor: themeSettings.chartPrimaryColor,
            label: this.label,
            maxBarThickness: 128,
          }],
        }
      : null;
  }

  private buildChart(): void {
    if (this.chartElemRef?.nativeElement) {
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(
        this.chartElemRef.nativeElement,
        {
          type: this.type,
          data: this.transformedData,
          options: this.options,
          plugins: [ChartDataLabels]
        }
      );
    };
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
