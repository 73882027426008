import { Component, OnInit } from '@angular/core';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { forkJoin, interval, of } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { TelematicsService } from '../../shared/telematics.service';
import { LanguageService } from '../../../../shared/services/language.service';
import { KeycloakService } from '../../../../core/keycloak';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { finalize, first, mergeMap, timeout } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewEquipmentTelematicsData } from '../../contract/view-equipment-telematics-data.interface';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';


@UntilDestroy()
@Component({
  selector: 'bh-equipment-telematics-unit-calibration',
  templateUrl: './equipment-telematics-unit-calibration.component.html',
  styleUrls: ['./equipment-telematics-unit-calibration.component.scss']
})
export class EquipmentTelematicsUnitCalibrationComponent implements OnInit {

  public equipment: ViewEquipment;
  public readonly faTruckPlow: IconDefinition = faTruckPlow;
  public calibrationForm = this.formBuilder.group(
    {
      calibratedOperatingHours: ['', [Validators.required, Validators.min(0)]],
      calibratedMileage: ['', [Validators.required, Validators.min(0)]]
    });

  public calibrating = false;

  private readonly pollingDelay = 1000;
  private readonly pollingMaxAttempts = 10;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private telematicsService: TelematicsService,
    private equipmentsDataSource: EquipmentsDataSource,
    private dialogRef: MatDialogRef<EquipmentTelematicsUnitCalibrationComponent>,
    protected languageService: LanguageService,
    private authService: KeycloakService,
    private dimensionUnitConverterPipe: DimensionUnitConverterPipe
  ) {
  }

  ngOnInit(): void {
    this.fillForm();
  }

  public get isCalibrationOperatingHoursAvailable(): boolean {
    return this.authService.hasAuthority(Authorities.EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_OPERATING_HOURS);
  }

  public get isCalibrationMileageAvailable(): boolean {
    return this.authService.hasAuthority(Authorities.EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_MILEAGE);
  }

  public save() {
    if (this.isCalibrationOperatingHoursAvailable && !this.isCalibrationMileageAvailable) {
      this.updateOperatingHours()
        .subscribe(() => this.waitTelematicsChanges());
    } else if (this.isCalibrationMileageAvailable && !this.isCalibrationOperatingHoursAvailable) {
      this.updateMileage()
        .subscribe(() => this.waitTelematicsChanges());
    } else {
      forkJoin([this.updateOperatingHours(), this.updateMileage()])
        .subscribe(() => this.waitTelematicsChanges());
    }
  }

  private waitTelematicsChanges() {

    this.calibrating = true;

    const { equipmentId, currentOperatingHours, currentMileage } = this.equipment;

    const fetchEquipmentTelematicsData = () => {
      return this.equipmentsDataSource.getEquipmentTelematicsData(equipmentId);
    };


    const isTelematicsDataChanged = (data: ViewEquipmentTelematicsData) => {
      return data.currentOperatingHours !== currentOperatingHours
        || data.currentMileage !== currentMileage;
    }

    of(this.equipment)
      .pipe(
        untilDestroyed(this),
        mergeMap(() =>
          interval(this.pollingDelay)
            .pipe(mergeMap(fetchEquipmentTelematicsData))
            .pipe(first(isTelematicsDataChanged))
            .pipe(timeout(this.pollingDelay * this.pollingMaxAttempts))
            .pipe(finalize(() => {
              this.equipmentsDataSource.updateCurrentEquipment();
              this.dialogRef.close();
            })),
        )).subscribe();
  }

  private updateOperatingHours() {
    return this.telematicsService.calibrateOperatingHours(
      this.equipment.equipmentId,
      this.calibrationForm.get('calibratedOperatingHours').value
    );
  }

  private updateMileage() {
    return this.telematicsService.calibrateMileage(
      this.equipment.equipmentId,
      this.dimensionUnitConverterPipe.toSystemDimensionUnit(this.calibrationForm.get('calibratedMileage').value, 'km')
    );
  }

  private fillForm(): void {
    this.updateValidators();
    this.calibrationForm.patchValue({
      calibratedOperatingHours: this.equipment.currentOperatingHours,
      calibratedMileage: this.mileageToUserDimensionUnit(this.equipment.currentMileage)
    });
  }

  public isValid(): boolean {
    return this.calibrationForm.valid;
  }

  public get newValueOperatingHours(): AbstractControl {
    return this.calibrationForm.get('calibratedOperatingHours');
  }

  public get newValueMileage(): AbstractControl {
    return this.calibrationForm.get('calibratedMileage');
  }

  private updateValidators(): void {
    this.newValueOperatingHours
      .setValidators([
        Validators.min(0)
      ]);

    this.newValueMileage
      .setValidators([
        Validators.min(0)
      ]);
  }

  private mileageToUserDimensionUnit(mileage): number {
    return this.dimensionUnitConverterPipe.toUserDimensionUnit(mileage || 0, 'km');
  }
}
