<mat-table class="timefence-table"
           *ngIf="(tableData$ | async)?.length > 0"
           [dataSource]="tableData$ | async">
  <ng-container matColumnDef="day" sticky>
    <mat-cell *matCellDef="let element" class="timefence-table__day"> {{element.day | dayName}} </mat-cell>
  </ng-container>
  <ng-container *ngFor="let timeColumn of onlyTimeColumns" [matColumnDef]="timeColumn">
    <mat-cell *matCellDef="let element; let i = index;"
              class="timefence-table__time"
              [ngClass]="{'selected': element[timeColumn]?.isSelected}"
              (click)="selectTime(i, timeColumn)" >{{element[timeColumn].label}} </mat-cell>
  </ng-container>
  <mat-row *matRowDef="let row; columns: columns"
           [ngClass]="{'selected-row': (row | timefenceSelectedDay : (tableData$ | async))}">
  </mat-row>
</mat-table>
