<div class="dialog-wrapper">
  <div class="title" fxLayout="column">
    <span>{{ bulkItem.bulkItemName }}</span>
    <span class="title-line-two">{{'modules.disposition.transferChooseAmount.chooseOriginStock'|translate}}</span>
  </div>
  <div class="content-wrapper">
    <div class="content">
      <div *ngFor="let amount of amounts" fxLayout="row" (click)="chooseAmount(amount)" class="item" fxLayoutGap="10px">
        <div>
          <mat-checkbox [checked]="amount.stockId === chosenAmount?.stockId" color="primary">
          </mat-checkbox>
        </div>
        <div fxFlex="1 1 100%" fxLayout="column" class="item-name">
          {{ amount.stockName }}
        </div>
        <div class="amount">
          x {{ amount.amount }}
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center end">
      <button mat-raised-button mat-dialog-close="abort">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button [mat-dialog-close]="chosenAmount" color="primary" [disabled]="!chosenAmount">
        {{'general.buttons.confirm'|translate}}
      </button>
    </div>
  </div>
</div>
