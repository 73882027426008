import { Pipe, PipeTransform } from '@angular/core';
import { HistoryTableColumn } from 'app/shared/components/history-table/history-table-column.enum';
import { HistoryTableRow } from 'app/shared/components/history-table/history-table-row.interface';
import { FieldType } from 'app/shared/enums/field-type';
import { MaintenanceChangedValue, MaintenanceHistoryEntry, MaintenanceTaskCompletedAuditResponse, MaintenanceTaskCompletedChange } from '../../contract/maintenance-task-completed-audit-response.interface';
import { MaintenanceHistoryColumn } from '../../enums/maintenance-history-column.interface';
import { MaintenanceHistoryFieldType } from '../../enums/maintenance-history-field-type.enum';
import { MaintenanceTaskCompletedHistoryFieldTranslationPipe } from './maintenance-task-completed-history-field-translation.pipe';

type HistoryRowChangedValue = MaintenanceChangedValue
  | { changedValueType: FieldType, changedValue: MaintenanceChangedValue };

type HistoryRow = HistoryTableRow<MaintenanceHistoryFieldType, HistoryRowChangedValue>;

interface MaintenanceTaskCompletedChangeFormatted {
  type: MaintenanceHistoryFieldType;
  field: string;
  oldValue: HistoryRowChangedValue;
  newValue: HistoryRowChangedValue;
}

function sortHistoryCallback(a: MaintenanceHistoryEntry, b: MaintenanceHistoryEntry): number {
  return a?.changeDate > b?.changeDate ? -1 : 1;
}

@Pipe({
  name: 'maintenanceTaskCompletedHistoryTableConverter'
})
export class MaintenanceTaskCompletedHistoryTableConverterPipe implements PipeTransform {

  constructor(private fieldTranslation: MaintenanceTaskCompletedHistoryFieldTranslationPipe) {}

  public transform(auditData: MaintenanceTaskCompletedAuditResponse): HistoryRow[] {
    if (auditData?.history) {
      return this.convertAuditDataToTable({
        ...auditData,
        history: auditData.history.sort(sortHistoryCallback)
      });
    }
    return [];
  }

  private convertAuditDataToTable(auditDataResponse: MaintenanceTaskCompletedAuditResponse): HistoryRow[] {
    return auditDataResponse.history
      .filter(Boolean)
      .reduce((
        result: HistoryRow[],
        historyEntry: MaintenanceHistoryEntry,
        index: number
      ) => {
        return [
          ...result,
          ...this.convertHistoryToTable(historyEntry, index % 2 === 1)
        ];
      },
      []);
  }

  private convertHistoryToTable(historyEntry: MaintenanceHistoryEntry, alternateBgColor: boolean): HistoryRow[] {
    return historyEntry.maintenanceChanges.reduce((
      result: HistoryRow[],
      originalChange: MaintenanceTaskCompletedChange,
      index: number
    ) => {
      const change = this.formatMaintenanceTaskCompletedChange(originalChange);
      return [
        ...result,
        {
          [HistoryTableColumn.DATE]: 0 === index ? historyEntry.changeDate : null,
          [HistoryTableColumn.USER]: 0 === index ? historyEntry.submitterInfo : null,
          [HistoryTableColumn.FIELD]: change.field,
          [HistoryTableColumn.ALTERNATE_COLOR]: alternateBgColor,
          [HistoryTableColumn.OLD]: {
            type: change.type,
            value: change.oldValue,
          },
          [HistoryTableColumn.NEW]: {
            type: change.type,
            value: change.newValue,
          },
        }
      ];
    }, []);
  }

  private formatMaintenanceTaskCompletedChange(change: MaintenanceTaskCompletedChange): MaintenanceTaskCompletedChangeFormatted {
    return {
      ...change,
      field: this.fieldTranslation.transform(change.field),
      ...(this.isTaskCompletedChange(change) ? { oldValue: null, newValue: null } : {}),
      ...(this.isDynamicFieldChange(change)
        ? {
            oldValue: { changedValueType: change.fieldType, changedValue: change.oldValue },
            newValue: { changedValueType: change.fieldType, changedValue: change.newValue },
          }
        : {})
    }
  }

  private isDynamicFieldChange(change: MaintenanceTaskCompletedChange): boolean {
    return change.type === MaintenanceHistoryFieldType.MAINTENANCE_FIELD;
  }

  private isTaskCompletedChange(change: MaintenanceTaskCompletedChange): boolean {
    return change.field === MaintenanceHistoryColumn.CREATED;
  }

}
