<div *ngIf="!loading">

  <div class="technical-field-header" fxLayout="row" fxLayoutAlign="space-between start">

    <div class="technical-field-filter" fxFlex="48" fxLayout="row" fxLayoutAlign="space-between start">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>search</mat-icon>
        <mat-form-field fxFlex>
          <mat-label>{{'general.labels.searchTerm'|translate}}</mat-label>
          <input matInput
                 [formControl]="searchControl"
                 [maxlength]="fieldLimit.SEARCH_TERM">
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="hasAnyAuthority([authorities.EQUIPMENT_UPDATE, authorities.EQUIPMENT_TECHNICAL_DATA_MANAGE])"
      class="technical-field-edit"
      fxFlex="48"
      fxLayout="row"
      fxLayoutAlign="end center">

      <span *ngIf="!editMode; else editModeTmpl"
        [matTooltip]="'modules.equipment.equipmentDetailGeneral.inactiveEquipmentNoChanges'|translate"
        [matTooltipDisabled]="isActive| async">
        <bh-button-icon color="primary"
          (click)="add()"
          [disabled]="!(isActive| async)">
          <bh-icon icon="add"></bh-icon>
        </bh-button-icon>

        <bh-button-icon
          color="primary"
          (click)="setEditMode(true)"
          [disabled]="!(isActive| async)"
          data-osp-test="button-edit-field">
          <bh-icon icon="mode_edit"></bh-icon>
        </bh-button-icon>
      </span>

      <ng-template #editModeTmpl>
        <button *ngIf="editMode"
          mat-raised-button
          color="accent"
          (click)="cancel()">
          {{'general.buttons.cancel'|translate}}
        </button>
        <button *ngIf="editMode"
          mat-raised-button
          bhTimeoutSaveButton
          color="primary"
          (throttledClick)="save()">
          {{'general.buttons.save'|translate}}
        </button>
      </ng-template>

    </div>
  </div>

  <div class="fields-container">
    <ng-container *ngFor="let control of fieldsFormArray.controls; let index=index">
      <bh-additional-field-assigned *ngIf="searchControl.value | additionalFieldNameIncludes : control.value"
        [formControl]="control"
        [editMode]="editMode"
        (onDelete)="remove(index)"
        fxFlex="48"
      ></bh-additional-field-assigned>
    </ng-container>
  </div>

</div>

<div *ngIf="loading" class="loading" fxLayout="row" fxLayoutAlign="center center">
  <mat-spinner [strokeWidth]="10" [diameter]="100"></mat-spinner>
</div>
