import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { DocumentLink } from '../../../../../../shared/contract/document-link.interface';
import { ViewStock } from '../../../../contract/view-stock';
import { StockStore } from '../../../shared/stock.store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { DeleteStockDocumentCommand } from '../../../../contract/delete-stock-document-command';
import { UpdateStockDocumentDescriptionCommand } from '../../../../contract/update-stock-document-description-command';
import { EnrichedAttachedDocument } from '../../../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { AttachedDocument } from '../../../../../../shared/contract/attached-document.interface';
import { UploadStockDocumentCommand } from '../../../../contract/upload-stock-document-command';
import { RoleAuthorityGuardsComponent } from '../../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { UpdateStockDocumentNameCommand } from 'app/modules/disposition/contract/update-stock-document-name-command';
import { FileUtils } from 'app/shared/fileUtils';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';

@UntilDestroy()
@Component({
  selector: 'bh-stock-view-attachment',
  templateUrl: './stock-view-attachment.component.html',
  styleUrls: ['./stock-view-attachment.component.scss']
})
export class StockViewAttachmentComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public documents: AttachedDocument[] = [];
  public images: AttachedDocument[] = [];
  public stock: ViewStock;
  public readonly isDeletable = this.hasAnyAuthority([Authorities.STOCK_MANAGE, Authorities.STOCK_DELETE_ATTACHMENTS]);
  public readonly isEditable = this.hasAnyAuthority([Authorities.STOCK_MANAGE, Authorities.STOCK_ADD_ATTACHMENTS]);
  public waiting = false;

  constructor(private stockStore: StockStore,
              private dialog: MatDialog,
              private languageService: LanguageService,
              protected authService: KeycloakService) {
    super(authService);
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public deleteDocument(objectKey: string): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.documentDelete');
    dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: string) => {
      if (result === dialogResults.YES) {
        this.waiting = true;
        const cmd: DeleteStockDocumentCommand = new DeleteStockDocumentCommand(this.stock.stockId, objectKey);
        this.stockStore.deleteDocument(cmd)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.waiting = false);
      }
    });
  }

  public onDescriptionChanged(updatedDocument: DocumentLink): void {
    const cmd = new UpdateStockDocumentDescriptionCommand(
        this.stock.stockId,
        updatedDocument.documentKey,
        updatedDocument.fileDescription
    );
    this.stockStore.updateDocumentDescription(cmd);
  }

  public saveFileName(updatedDocument: DocumentLink): void {
    const cmd = new UpdateStockDocumentNameCommand(
        this.stock.stockId,
        updatedDocument.documentKey,
        updatedDocument.fileName,
    );
    this.stockStore.updateDocumentName(cmd);
  }

  public onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    const document = AttachedDocument.fromData(attachedDocument);
    this.stockStore.uploadDocument(new UploadStockDocumentCommand(attachedDocument.entityId, document));
  }

  private initListeners(): void {
    this.initListenerSelectedStock();
    this.initListenerDocuments();
  }

  private initListenerSelectedStock(): void {
    this.stockStore.selectedStock
      .pipe(untilDestroyed(this))
      .subscribe(stock => this.stock = stock);
  }

  private initListenerDocuments(): void {
    this.stockStore.documents
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        const { documents, images } = this.rangeDocuments(res);
        this.documents = documents;
        this.images = images;
      });
  }

  private rangeDocuments(docs: DocumentLink[]): { documents: DocumentLink[], images: DocumentLink[] } {
    const documents: DocumentLink[] = [];
    const images: DocumentLink[] = [];
    (docs || []).forEach(doc => (FileUtils.isPictureV2(doc) ? images : documents).push(doc));
    return { documents, images };
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
