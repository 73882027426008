import { filter } from 'rxjs/operators';
import { GeofenceAlarmTrigger } from './../../../../shared/enums/geofence-alarm-trigger.enum';
import { ViewGlobalGeofence } from './../../../../../equipment/contract/view-global-geofence.interface';
import { AlarmSettingsFieldData } from './../../../../contract/alarm-field-data.type';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AlarmSettingsBase } from '../alarm-settings-base.class';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'bh-geofence-selection-settings',
  templateUrl: './geofence-selection-settings.component.html',
  styleUrls: ['./geofence-selection-settings.component.scss']
})
@UntilDestroy()
export class GeofenceSelectionSettingsComponent extends AlarmSettingsBase implements OnInit, OnDestroy {
  @Input() set form(form: UntypedFormGroup) {
    this.settingsForm = form;
  }
  @Input() set fieldData(fieldData: AlarmSettingsFieldData) {
    this.alarmSettingsFieldData = fieldData;
  }
  @Input() globalGeofences: ViewGlobalGeofence[] = [];
  @Input() geoColor = 'red';

  public readonly triggers = GeofenceAlarmTrigger
  public mapCenterGermany = {lat: 50.98609893339354, lng: 10.290756225585938};
  private map: google.maps.Map;
  private displayedPolygon: google.maps.Polygon;

  constructor() {
    super();
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {}

  public mapReady(event): void {
    this.map = event.data.map;
    this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);

    this.initListeners();
    this.checkForEditMode();
  }

  public valueChangesGeofence(geofenceId: string): void {
    if (this.displayedPolygon) {
      this.displayedPolygon.setMap(null);
    }
    if (geofenceId) {
      this.showSingleGeofence(this.globalGeofences.find(({ globalGeofenceId }) => globalGeofenceId === geofenceId));
    }
  }

  private initListeners(): void {
    this.settingsForm.get(this.controlNames.SETTINGS_GLOBAL_GEOFENCE).valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((geofenceId: string) => this.valueChangesGeofence(geofenceId));
  }

  private checkForEditMode(): void {
    const geofenceId = this.settingsForm.get(this.controlNames.SETTINGS_GLOBAL_GEOFENCE).value;
    if (geofenceId) {
      this.valueChangesGeofence(geofenceId);
    }
  }

  private showSingleGeofence(viewGeofence: ViewGlobalGeofence): void {
    if (!viewGeofence) {
      return;
    }
    const polygon = this.mapPolygonMapper(viewGeofence);
    const bounds = new google.maps.LatLngBounds();

    polygon.setVisible(true);
    polygon.getPath().forEach((coord: google.maps.LatLng) => bounds.extend(coord));
    if (this.map) {
      this.map.panToBounds(bounds);
      this.map.fitBounds(bounds);
    }

    this.displayedPolygon = polygon;
  }

  private mapPolygonMapper(geofence: ViewGlobalGeofence): google.maps.Polygon {
    return new google.maps.Polygon({
      paths: geofence.coordinates.map(({ lat, lon }) => ({ lat, lng: lon })),
      strokeColor: this.geoColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: this.geoColor,
      fillOpacity: 0.5,
      map: this.map
    });
  }
}
