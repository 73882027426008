import { Pipe, PipeTransform } from '@angular/core';
import { TelematicsLocationRecord } from 'app/modules/equipment/contract/telematics-location-record.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { IconPosition, MapIcon } from '../contracts/map-icon.class';
import { MarkerItemEquipmentTelematicsLocation } from '../interfaces/marker-item-equipment-telematics-location.interface';
import { MapInfoTelematicsLocationComponent } from '../components/map-info-window/map-info-telematics/map-info-telematics-location/map-info-telematics-location.component';
import { ViewEquipment } from 'app/modules/equipment/contract/view-equipment.interface';

@Pipe({
  name: 'mapMarkersConvertTelematicLocation'
})
export class MapMarkersConvertTelematicLocationPipe implements PipeTransform {
  private readonly icon = new MapIcon({
    url: '/assets/green-marker.svg',
    size: 20,
    position: IconPosition.CENTER,
  });
  private equipment: ViewEquipment;

  public transform(
    value: TelematicsLocationRecord[],
    equipment: ViewEquipment
  ): MapLocationMarker<MarkerItemEquipmentTelematicsLocation>[] {
    this.equipment = equipment;
    return Array.isArray(value) ? this.getMapLocationMarkers(value) : null;
  }

  private getMapLocationMarkers(items: TelematicsLocationRecord[]): MapLocationMarker<MarkerItemEquipmentTelematicsLocation>[] {
    return items.filter(Boolean).map(item => ({
      id: item.recordId,
      location: { lat: item.lat, lon: item.lon },
      icon: this.icon,
      opacity: 0.8,
      infoWindow: { content: MapInfoTelematicsLocationComponent },
      item: this.getItem(item),
    }));
  }

  private getItem(item: TelematicsLocationRecord): MarkerItemEquipmentTelematicsLocation {
    return item
      ? {
        ...item,
        equipmentName: this.equipment?.equipmentName || this.equipment?.equipmentModel || null
      } : null;
  }

}
