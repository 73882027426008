import { AlarmRuleControl } from 'app/modules/notifications/contract/alarm-rule-control.enum';
import { AlarmTypeService } from '../shared/services/alarm-types.service';
import { LanguageService } from 'app/shared/services/language.service';
import { ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AlarmFieldData, AlarmSettingsFieldData } from '../contract/alarm-field-data.type';
import { AlarmSettingsType } from '../contract/alarm-settings-type.enum';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { Injectable } from '@angular/core';
import { DimensionUnitPipe } from '../../../shared/pipes/dimension-unit.pipe';

@Injectable()
export class AlarmSettingsUtilsService {
  private _currentAlarmType = new BehaviorSubject<AlarmType>(null);
  public alarmTypeChanges = this._currentAlarmType.asObservable();

  private alarmSettingsTypeMapper = {
    [AlarmType.OPERATING_HOURS]: AlarmSettingsType.LIMIT,
    [AlarmType.OPERATING_HOURS_CONTRACT]: AlarmSettingsType.LIMIT,
    [AlarmType.TANK_FILL_LEVEL]: AlarmSettingsType.LIMIT_PERCENTAGE,
    [AlarmType.DEF_TANK_LEVEL]: AlarmSettingsType.LIMIT_PERCENTAGE,
    [AlarmType.TOTAL_COSTS]: AlarmSettingsType.LIMIT,
    [AlarmType.DAMAGE_COSTS]: AlarmSettingsType.LIMIT,
    [AlarmType.REPAIR_COSTS]: AlarmSettingsType.LIMIT,
    [AlarmType.GUARANTEE]: AlarmSettingsType.LIMIT,
    [AlarmType.FULL_SERVICE_CONTRACT_END]: AlarmSettingsType.LIMIT,
    [AlarmType.LEASING_SERVICE_CONTRACT_END]: AlarmSettingsType.LIMIT,
    [AlarmType.GLOBAL_GEOFENCE_VIOLATION]: AlarmSettingsType.GEOFENCE,
    [AlarmType.TIMEFENCING]: AlarmSettingsType.TIMEFENCING,
    [AlarmType.INACTIVE_TELEMATICS_UNIT]: AlarmSettingsType.TELEMATICS_UNIT,
    [AlarmType.BATTERY_VOLTAGE]: AlarmSettingsType.LIMIT,
    [AlarmType.TELEMATICS_UNIT_BATTERY_STATUS]: AlarmSettingsType.TRACKER_BATTERY_STATUS,
  };

  private alarmSettingsRequiredFieldMapper = {
    [AlarmType.OPERATING_HOURS]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.OPERATING_HOURS_CONTRACT]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.TANK_FILL_LEVEL]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.DEF_TANK_LEVEL]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.TOTAL_COSTS]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.DAMAGE_COSTS]: [AlarmRuleControl.SETTINGS_LIMIT, AlarmRuleControl.SETTINGS_INTERVAL],
    [AlarmType.REPAIR_COSTS]: [AlarmRuleControl.SETTINGS_LIMIT, AlarmRuleControl.SETTINGS_INTERVAL],
    [AlarmType.GUARANTEE]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.FULL_SERVICE_CONTRACT_END]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.LEASING_SERVICE_CONTRACT_END]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.GLOBAL_GEOFENCE_VIOLATION]: [AlarmRuleControl.SETTINGS_GLOBAL_GEOFENCE, AlarmRuleControl.SETTINGS_GEOFENCE_TRIGGER],
    [AlarmType.TIMEFENCING]: [
      AlarmRuleControl.SETTINGS_TIMEFENCING_WEEK_DAY,
      AlarmRuleControl.SETTINGS_TIMEFENCING_HOURS,
      AlarmRuleControl.SETTINGS_TIMEFENCING_DISTANCE
    ],
    [AlarmType.INACTIVE_TELEMATICS_UNIT]: [
      AlarmRuleControl.SETTINGS_LIMIT,
      AlarmRuleControl.SETTINGS_LIMIT_UNIT,
      AlarmRuleControl.SETTINGS_TELEMATICS_SELECTOR,
      AlarmRuleControl.SETTINGS_TELEMATICS_TYPES
    ],
    [AlarmType.BATTERY_VOLTAGE]: [AlarmRuleControl.SETTINGS_LIMIT],
    [AlarmType.TELEMATICS_UNIT_BATTERY_STATUS]: [AlarmRuleControl.SETTINGS_TELEMATICS_BATTERY_STATUS],
  };

  private alarmSettingsValidatorsMapper = {
    [AlarmRuleControl.SETTINGS_LIMIT]: [Validators.required, Validators.min(0)],
    [AlarmRuleControl.SETTINGS_LIMIT_UNIT]: [Validators.required],
    [AlarmRuleControl.SETTINGS_INTERVAL]: [Validators.required, Validators.min(0)],
    [AlarmRuleControl.SETTINGS_GLOBAL_GEOFENCE]: [Validators.required],
    [AlarmRuleControl.SETTINGS_GEOFENCE_TRIGGER]: [Validators.required],
    [AlarmRuleControl.SETTINGS_TIMEFENCING_WEEK_DAY]: [Validators.required],
    [AlarmRuleControl.SETTINGS_TIMEFENCING_HOURS]: [Validators.required, Validators.min(1)],
    [AlarmRuleControl.SETTINGS_TIMEFENCING_DISTANCE]: [Validators.required, Validators.min(1)],
    [AlarmRuleControl.SETTINGS_TELEMATICS_SELECTOR]: [Validators.required],
  };

  private fieldData: AlarmFieldData = {
    [AlarmType.OPERATING_HOURS]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitHours'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.OPERATING_HOURS)
      }
    },
    [AlarmType.OPERATING_HOURS_CONTRACT]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitHours'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.OPERATING_HOURS_CONTRACT)
      }
    },
    [AlarmType.TANK_FILL_LEVEL]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.tankLevel'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.TANK_FILL_LEVEL)
      }
    },
    [AlarmType.DEF_TANK_LEVEL]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.tankLevel'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.DEF_TANK_LEVEL)
      }
    },
    [AlarmType.TELEMATICS_UNIT_BATTERY_STATUS]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.trackerBatteryStatus'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.TELEMATICS_UNIT_BATTERY_STATUS)
      }
    },
    [AlarmType.TOTAL_COSTS]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitMoney'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.TOTAL_COSTS)
      }
    },
    [AlarmType.DAMAGE_COSTS]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitMoney'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.DAMAGE_COSTS)
      },
      [AlarmRuleControl.SETTINGS_INTERVAL]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.intervalDays'),
        explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.intervalDays')
      }
    },
    [AlarmType.REPAIR_COSTS]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitMoney'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.REPAIR_COSTS)
      },
      [AlarmRuleControl.SETTINGS_INTERVAL]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.intervalDays'),
        explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.intervalDays')
      }
    },
    [AlarmType.GUARANTEE]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitDays'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.GUARANTEE)
      }
    },
    [AlarmType.FULL_SERVICE_CONTRACT_END]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitDays'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.FULL_SERVICE_CONTRACT_END)
      }
    },
    [AlarmType.LEASING_SERVICE_CONTRACT_END]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitDays'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.LEASING_SERVICE_CONTRACT_END)
      }
    },
    [AlarmType.GLOBAL_GEOFENCE_VIOLATION]: {
      [AlarmRuleControl.SETTINGS_GLOBAL_GEOFENCE]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.selectGeofence'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.GLOBAL_GEOFENCE_VIOLATION)
      },
      [AlarmRuleControl.SETTINGS_GEOFENCE_TRIGGER]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.trigger'),
      }
    },
    [AlarmType.TIMEFENCING]: {
      [AlarmRuleControl.SETTINGS_TIMEFENCING_HOURS]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.timefencingOperatingHours'),
        explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.timefencingOperatingHours')
      },
      [AlarmRuleControl.SETTINGS_TIMEFENCING_DISTANCE]: {
        label: this.getTimefencingDistanceLabel(),
        explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.timefencingDistance')
      },
      [AlarmRuleControl.SETTINGS_TIMEFENCING_WEEK_DAY]: {
        explanation: this.alarmTypeService.getExplanation(AlarmType.TIMEFENCING)
      }
    },
    [AlarmType.INACTIVE_TELEMATICS_UNIT]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.telematicsInterval')
      },
      [AlarmRuleControl.SETTINGS_LIMIT_UNIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.telematicsIntervalUnit')
      },
      [AlarmRuleControl.SETTINGS_TELEMATICS_SELECTOR]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.telematicsSelector')
      },
      [AlarmRuleControl.SETTINGS_TELEMATICS_TYPES]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.telematicsTypes')
      }
    },
    [AlarmType.BATTERY_VOLTAGE]: {
      [AlarmRuleControl.SETTINGS_LIMIT]: {
        label: this.translate('modules.notification.alarmRuleAddEdit.labels.limitVolts'),
        explanation: this.alarmTypeService.getExplanation(AlarmType.BATTERY_VOLTAGE)
      }
    },
  }

  constructor(private alarmTypeService: AlarmTypeService,
              private languageService: LanguageService,
              private dimensionUnitPipe: DimensionUnitPipe) { }

  public get alarmType(): AlarmType {
    return this._currentAlarmType.getValue();
  }

  public set alarmType(type: AlarmType) {
    this._currentAlarmType.next(type);
  }

  public getAlarmSettingsType(): AlarmSettingsType {
    return this.alarmSettingsTypeMapper[this.alarmType];
  }

  public isControlRequired(controlName: AlarmRuleControl | string): boolean {
    return this.alarmSettingsRequiredFieldMapper[this.alarmType] ?
     this.alarmSettingsRequiredFieldMapper[this.alarmType].some(field => field === controlName) : false;
  }

  public getValidators(controlName: AlarmRuleControl | string): ValidatorFn[] | null {
    return this.isControlRequired(controlName) ? this.alarmSettingsValidatorsMapper[controlName] : null;
  }

  public getAlarmSettingsFieldData(): AlarmSettingsFieldData {
    return this.fieldData[this.alarmType];
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public getFieldPattern(): string {
    switch (this.alarmType) {
      case AlarmType.BATTERY_VOLTAGE:
        // Allow decimals with 1 precision digit
        return '^\\d+(\\.\\d{0,1})?$';
      default:
        // Allow any decimals
        return '^\\d+(\\.\\d*)?$';
    }
  }

  private getTimefencingDistanceLabel() {
    const fieldName = this.translate('modules.notification.alarmRuleAddEdit.labels.timefencingDistance');
    const dimensionUnit = this.dimensionUnitPipe.getUserDimensionUnit('m');
    return `${fieldName} (${dimensionUnit})`;
  }
}
