import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment/moment';
import { UserConfigurationService } from '../services/user-configuration.service';
import * as momenttz from 'moment-timezone';
import { TimezoneUtils } from './timezone-utils';

@Injectable({
  providedIn: 'root'
})
export class TimezoneDatesService {

  private userConfigurationService: UserConfigurationService;
  isValidDate(date: string | Date): boolean {
    return Boolean(date)
      && (_.isDate(date) || _.isString(date))
      && moment(date).isValid()
      && moment(date).isAfter('1900-01-01')
      && moment(date).isBefore('9999-12-31');
  }

  constructor(userConfigurationService: UserConfigurationService) {
    this.userConfigurationService = userConfigurationService;
  }

  isEndOfDaySameTimeZone(date: string | Date): boolean {
    const currentDate = new Date(date);
    return this.isValidDate(currentDate) && currentDate.getHours() === 23 && currentDate.getMinutes() === 59;
  }

  public convertDateWithTimezone(givenDate: Date, format: string) {
    const selectedTimezone: string = this.getSelectedTimezone();
    let mappedTimezone = TimezoneUtils.timezoneMappings[selectedTimezone] || selectedTimezone;
    if (format) {
      return momenttz(givenDate).tz(mappedTimezone).format(format);
    }
    return momenttz(givenDate).tz(mappedTimezone).format();
  }

  public getSelectedTimezone(): string {
    if (!this.userConfigurationService.userConfiguration.timezoneConfiguration.lastUpdated) {
      this.userConfigurationService.applyAndSaveBrowserTimezone().subscribe();
    }
    const selectedTimezone = this.userConfigurationService.userConfiguration.timezoneConfiguration.timezone;
    const mappedTimezone = TimezoneUtils.timezoneMappings[selectedTimezone] || selectedTimezone;
    if (!mappedTimezone) {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return mappedTimezone;
  }

  public timeZonedTransform(date: string | Date, format: string, locale: string = null): string {
    if (locale === null) {
      return momenttz(date).tz(this.getSelectedTimezone()).format(format);
    }

    return momenttz(date).tz(this.getSelectedTimezone()).locale(locale).format(format);
  }

}
