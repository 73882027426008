import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BulkItemDatasource } from '../shared/bulk-item.datasource';
import {Injectable} from '@angular/core';

@Injectable()
export class CurrentBulkItemTabReroute implements CanActivate {
  constructor(private router: Router,
              private bulkItemStore: BulkItemDatasource) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): false {
    this.router.navigate([state.url, this.bulkItemStore.currentViewTab]);
    return false;
  }
}
