import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { UntypedFormGroup } from '@angular/forms';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { Directive, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsService } from '../../../shared/equipments.service';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { LanguageService } from 'app/shared/services/language.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { EquipmentStatusCategoryPipe } from '../../../../../shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';
import { Sort } from '@angular/material/sort';
import { environment } from 'environments/environment';
import { untilDestroyed } from '@ngneat/until-destroy';

@Directive()
export abstract class BaseEquipmentListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public searchForm: UntypedFormGroup;
  public filters: FilterType[] = this.equipmentStore.filters;
  public onFiltersUpdated: EventEmitter<any> = this.equipmentStore.onFiltersUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  public routeButton: ButtonInfo = {route: 'equipments/map', tooltip: this.translate('general.map.s'), iconName: 'map'};
  public defaultSort: Sort = { active: null, direction: null };
  protected componentActive = true;

  protected constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentStore: EquipmentsDataSource,
              protected equipmentService: EquipmentsService,
              protected userConfigurationService: UserConfigurationService,
              protected organisationService: OrganisationsService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
              protected equipmentStatusNamePipe: EquipmentStatusNamePipe) {

    super(authService, router, route, routerHistory);
    this.equipmentStore.updateFilters();
    this.equipmentStore.updateListing();
    this.setDefaultSort();
  }

  public get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  public ngOnInit(): void {
    this.equipmentStore.length.subscribe(value => {
      this.onTotalCountUpdated.emit(value);
    })
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  public setSearchTerms(emitEvent = true): void {
    this.searchForm.patchValue({terms: this.equipmentStore.searchTerms}, { emitEvent });
  }

  public onSearchFormType(): void {
    this.searchForm.get('terms').valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(searchTerm => this.updateOnSearch(searchTerm));
    this.setSearchTerms(false);
  }

  public updateOnSearch(searchTerm: string): void {
    this.equipmentStore.searchTerms = searchTerm;
    this.equipmentStore.updateListing(0);
  }


  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.equipmentStore.updateFilterParams(commands);
    this.equipmentStore.updateListing(0);
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private setDefaultSort(): void {
    this.defaultSort = ({ ...this.equipmentStore.sort } ?? this.defaultSort);
  }
}
