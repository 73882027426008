import { LanguageService } from 'app/shared/services/language.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { matomoCategories } from '../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../assets/matomo/matomo-actions.enum';
import { EquipmentColumnDefinitionService } from 'app/modules/equipment/shared/services/equipment-column-definition.service';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-list-export-dialog',
  templateUrl: './equipment-list-export-dialog.component.html',
  styleUrls: ['./equipment-list-export-dialog.component.scss']
})
export class EquipmentListExportDialogComponent implements OnInit {

  public selectAllColumns = false;
  public readonly selectedColumns: string[] = [];
  public applyCurrentFilter = true;
  public readonly sortableFields: { name: string, value: string }[] = [];
  public sortBy = 'equipmentName';
  public sortDescending = false;
  public pendingRequest = false;

  constructor(private dialogRef: MatDialogRef<EquipmentListExportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { selectedColumns: ColumnDefinition[] },
              private equipmentStore: EquipmentsDataSource,
              private matomoTracker: MatomoTracker,
              private equipmentColumnDefinitionService: EquipmentColumnDefinitionService,
              private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.equipmentColumnDefinitionService.availableColumns
      .filter(column => this.isSortableColumn(column))
      .forEach(column => {
        this.sortableFields.push({
          name: column.readableName,
          value: column.cdkColumnDef
        });
      });
    this.sortableFields.sort((a, b) => a.name.localeCompare(b.name));

    this.data.selectedColumns.filter(column => this.isDisplayableColumn(column)).forEach(column => {
      this.selectedColumns.push(column.cdkColumnDef);
    });
  }

  public cancel(): void {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.EXPORT_EQUIPMENT_CANCEL);
    this.dialogRef.close();
  }

  public exportEquipmentList(): void {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.EXPORT_EQUIPMENT_EXPORT);
    this.pendingRequest = true;
    const columns: string[] = this.selectAllColumns ? this.getAllDisplayableColumns() : this.selectedColumns;
    columns.unshift('subEquipment', 'equipmentCustomerSerialNumber', 'containerEquipmentCustomerSerialNumber')
    this.equipmentStore.exportEquipmentList(columns, this.applyCurrentFilter, this.sortBy, this.sortDescending)
    .pipe(untilDestroyed(this))
    .subscribe((data: Blob) => this.saveList(data), () => this.pendingRequest = false);
  }

  private isSortableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.type !== 'image'
        && columnDefinition.type !== 'labels'
        && columnDefinition.type !== 'details'
        && columnDefinition.cdkColumnDef !== 'address';
  }

  private getAllDisplayableColumns(): string[] {
    return this.equipmentColumnDefinitionService.availableColumns
      .filter(column => this.isDisplayableColumn(column))
      .map(column => column.cdkColumnDef);
  }

  private isDisplayableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.type !== 'select' && columnDefinition.type !== 'image' && columnDefinition.type !== 'details';
  }

  private saveList(data: Blob): void {
    this.pendingRequest = false;
    saveAs(data, this.createFileName());
    this.dialogRef.close();
  }

  private createFileName(): string {
    return this.languageService.getInstant('modules.equipment.equipmListExport.equipmentListFileName')
      + `_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`;
  }
}
