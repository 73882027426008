import { TransferType } from './transfer-type';
import { TransactionType } from './transaction-type';
import { TransferEntry } from './transfer-entry';

export interface TransferHistoryEntry {
  transferId: string;
  transactionType: TransactionType;
  transferredItems: number;
  items: TransferEntry[];
  transferType: TransferType;
  transferDestination: string;
  transferSources: string[];
  transferDate: Date,
  transferCreateDate: Date,
  transferTimeStamp: string;
  employeeId: string;
  employeeFirstName: string;
  employeeLastName: string;
  externalEmployee: string;
  comment: string;
  submittedBy: string;
  acknowledgedBy: string;
  acknowledgeDate: Date;
  transferItemDeleted: boolean;
  sourceLocationIsAccessibleForUser?: boolean;
  createdBy: string;
}

export function getCombinedId(entry: TransferHistoryEntry): string {
  return entry.transferId + '_' + entry.transactionType;
}
