import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[bhPreventFocusSwitch]'
})
export class PreventFocusSwitchDirective {
  @Input() public set unallowedElementClasses(classes: string[]) {
    this.unallowedElementClassesSet = new Set(classes || []);
  }
  private unallowedElementClassesSet: Set<string>;

  constructor() { }

  @HostListener('focusout', ['$event']) focusout(event: FocusEvent): void {
    if (this.isUnallowedRelatedTarget(<HTMLElement>event.relatedTarget)) {
      (<HTMLElement>event.target).focus();
    }
  }

  private isUnallowedRelatedTarget(relatedTarget: HTMLElement): boolean {
    return relatedTarget
      && this.unallowedElementClassesSet
      && this.unallowedElementClassesSet.size > 0
      && Array.from(relatedTarget.classList).some(className => this.unallowedElementClassesSet.has(className));
  }
}
