import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsDataSource } from '../../shared/services/reports.datasource';
import { ViewReportDefinition } from '../../contract/view-report-definition.interface';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-report-generation',
  templateUrl: './report-generation.component.html',
  styleUrls: ['./report-generation.component.scss']
})
export class ReportGenerationComponent implements OnInit, OnDestroy {

  reportDefinitions: ViewReportDefinition[];

  constructor(private router: Router,
              private reportStore: ReportsDataSource) { }

  ngOnInit() {
    this.getReportDefinitions();
  }

  ngOnDestroy() {
  }

  navigateToGenerate(reportDefinitionId: string) {
    this.router.navigate([`/reports/generate/${reportDefinitionId}`]);
  }

  private getReportDefinitions() {
    this.reportStore.getReportDefinitions();

    this.reportStore.reportDefinitions.subscribe(res => this.reportDefinitions = res);
  }

}
