import { Pipe, PipeTransform } from '@angular/core';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapItemViewOrganisation } from '../interfaces/map-list-item/map-item-view-organisation.interface';
import { MapLocationType } from '../enums/map-location-type.enum';
import { MapInfoWindowTableViewOrganisationComponent } from '../components/map-info-window-cluster-table-view/map-info-window-table-view-organisation/map-info-window-table-view-organisation.component';

@Pipe({
  name: 'mapMarkersConvertOrganisationLocations'
})
export class MapMarkersConvertOrganisationLocationsPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: ViewOrganisation[], mapSettings: MapConfiguration): MapLocationMarker<MapItemViewOrganisation>[] {
    if (!Array.isArray(value)) {
      return null;
    }

    return value.filter(Boolean).map(item => ({
      id: item.organisationId,
      location: item.location,
      icon: this.iconResolver.getIconOrganisation(),
      item: { ...item, locationType: MapLocationType.ORGANISATION },
      infoWindow: { content: MapInfoWindowTableViewOrganisationComponent },
      label: item[mapSettings?.organisationColumn],
    }));
  }

}
