export enum AlarmRuleControl {
  ALARM_TYPE = 'alarmType',
  SETTINGS = 'settings',
  SETTINGS_LIMIT = 'limit',
  SETTINGS_LIMIT_UNIT = 'limitDurationUnit',
  SETTINGS_INTERVAL = 'interval',
  SETTINGS_GLOBAL_GEOFENCE = 'globalGeofence',
  SETTINGS_GEOFENCE_TRIGGER = 'geofenceAlarmTrigger',
  SETTINGS_TIMEFENCING_HOURS = 'timefencingHoursLimit',
  SETTINGS_TIMEFENCING_DISTANCE = 'timefencingDistanceLimit',
  SETTINGS_TIMEFENCING_WEEK_DAY = 'timefencingWeekDay',
  SETTINGS_TELEMATICS_SELECTOR = 'telematicsSelector',
  SETTINGS_TELEMATICS_TYPES = 'telematicsTypes',
  SETTINGS_TELEMATICS_BATTERY_STATUS = 'trackerBatteryStatus',
  DATA = 'data',
  DATA_ALARM_MESSAGE = 'alarmMessage',
  DATA_ALARM_LEVEL = 'alarmLevel',
  DATA_SELECTION_TYPE = 'selectionType',
  DATA_LABELS = 'labels',
  DATA_EQUIPMENT_TYPES = 'equipmentTypes',
  DATA_EMAIL_TOGGLE = 'emailToggle',
  DATA_EMAIL_RECIPIENTS = 'emailRecipients',
  DATA_ROLE_RECIPIENTS = 'roleRecipients'
}
