<div class="contact-details-wrapper" fxLayout="column">

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group #detailTabGroup
      [disablePagination]="true"
      mat-stretch-tabs
      animationDuration="0ms"
      (selectedTabChange)="updateTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          {{'general.baseData'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-contact-view-general></bh-contact-view-general>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faStickyNote"></fa-icon> {{'general.description'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-description *ngIf="contact"
                          [maxLength]="maxDescriptionLength"
                          [(description)]="contact.description"
                          [isEditingAllowed]="hasAuthority(authorities.CONTACT_MANAGE)"
                          (descriptionSave)="saveDescription($event)">
          </bh-description>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
