import { Component, Input } from '@angular/core';

@Component({
  selector: 'bh-subtitle',
  template: '<h4>{{ text }}</h4>',
  styleUrls: ['./subtitle.component.scss']
})
export class SubtitleComponent {

  @Input() text: string;

  constructor() {
  }

}
