import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'bh-gallery-arrows',
  templateUrl: './gallery-arrows.component.html',
  styleUrls: ['./gallery-arrows.component.scss'],
})
export class GalleryArrowsComponent{
  @Input() public prevDisabled: boolean;
  @Input() public nextDisabled: boolean;
  @Output() public onPrevClick = new EventEmitter();
  @Output() public onNextClick = new EventEmitter();

  public prev: IconDefinition = faArrowCircleLeft;
  public next: IconDefinition = faArrowCircleRight;

  public handlePrevClick(): void {
      this.onPrevClick.emit();
  }

  public handleNextClick(): void {
      this.onNextClick.emit();
  }
}
