import { BehaviorSubject, combineLatest, filter, map, Observable, shareReplay, startWith, tap } from 'rxjs';
import { AdapterTabControllerDatasource } from './adapter-datasource/adapter-tab-controller-datasource.interface';

export class TabController<T> {
  public isNeedReset = true;
  public isNeedUpdate = true;

  private switchToEmptyData = new BehaviorSubject<boolean>(false);
  public readonly data: Observable<T[]> = combineLatest([
    this.datasource.data,
    this.switchToEmptyData.asObservable().pipe(filter(Boolean), startWith(false)),
  ])
  .pipe(
    map(([data]) => this.switchToEmptyData.getValue() ? [] : data),
    tap(() => this.switchToEmptyData.next(false)),
    shareReplay(1));

  constructor(private datasource: AdapterTabControllerDatasource<T>) {}

  public update(id: string): void {
    if (id) {
      if (this.isNeedReset) {
        this.datasource.reset(id);
      } else if (this.isNeedUpdate) {
        this.datasource.loadById(id);
      }

      this.isNeedReset = false;
      this.isNeedUpdate = false;
    }
  }

  public cleanData(): void {
    this.switchToEmptyData.next(true);
  }
}
