import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CreatePartnerCommand } from '../../contract/create-partner-command';
import { PartnersService } from '../../shared/partners.service';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { PartnerMailServer } from '../../contract/partner-mail-server.enum';
import { LanguageService } from '../../../../shared/services/language.service';
import { PartnerBaseComponent } from '../common/partner-base.component';
import {RealmType} from '../../../../shared/contract/realm-type';

@Component({
  selector: 'bh-customer-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.scss']
})
export class PartnerAddComponent extends PartnerBaseComponent implements OnInit {

  public partnerAddForm: UntypedFormGroup;

  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              private partnerService: PartnersService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(router, route, authService, routerHistory, languageService);
  }

  ngOnInit() {
    this.buildForm();
  }

  public navigateBack(): void {
    this.router.navigate(['/partners/list']);
  }

  buildForm() {
    this.partnerAddForm = this.formBuilder.group({
      partnerName: ['', [<any>Validators.required]],
      partnerTheme: ['', this.isAllowedToManageWhitelabel() ? [Validators.required] : []],
      partnerMailServer: [PartnerMailServer.DEFAULT_OSP, this.isAllowedToManageWhitelabel() ? [Validators.required] : []],
      partnerRealm: [RealmType.DEFAULT, this.isAllowedToManageWhitelabel() ? [Validators.required] : []],
      partnerAddress: this.formBuilder.group({
        street: '',
        streetNumber: '',
        postalCode: '',
        city: ''
      })
    });
  }

  get partnerName() {
    return this.partnerAddForm.get('partnerName');
  }

  save() {
    if (this.isValid()) {
      let formValue = this.partnerAddForm.getRawValue();
      let cmd = new CreatePartnerCommand();
      cmd.partnerName = formValue.partnerName;
      cmd.partnerTheme = formValue.partnerTheme;
      cmd.partnerMailServer = formValue.partnerMailServer;
      cmd.partnerAddress = formValue.partnerAddress;
      cmd.partnerRealm = formValue.partnerRealm;

      this.partnerService.addPartner(formValue).subscribe(
        () => {
            this.navigateAfterSubmit();
          },
        (error: HttpErrorResponse) => {
            console.log('save customer error: ', error);
          }
      );
    }
  }

  public isValid(): boolean {
    return this.partnerAddForm.valid;
  }

  resetForm() {
    this.partnerAddForm.reset();
  }

  isAllowedToManageWhitelabel(): boolean {
    return this.isInRole(PrivilegedRole.Superadmin)
  }
}
