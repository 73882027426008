<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex
           [ngClass]="{'search-bar-su' : isSuperAdmin()}">
        <div class="search-bar-input" fxLayout="row">
          <input fxFlex matInput [formControl]="termControl">
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px" (click)="searchClick()">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-button [matTooltip]="'general.labels.resetSearchFilters'|translate" fxFlex="40px"
                  (click)="searchReset()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <button *ngIf="isSuperAdmin()"
              mat-button class="search-bar-button-right"
              fxFlex="50px"
              [matTooltip]="'modules.telematic.telematicAddEdit.titleAdd'|translate"
              (click)="openAddMenu()">
        <mat-icon class="telematic-add-button">add_box</mat-icon>
      </button>

      <button *ngIf="isSuperAdmin()"
              #importBtn
              mat-button
              class="search-bar-button-right"
              [matTooltip]="'general.labels.importCsv'|translate" fxFlex="50px"
              (click)="bulkImport()">
        <mat-icon>file_upload</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="telematic-administration-card search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column">
      <div class="filter-form-panel">
        <form [formGroup]="filterForm" fxLayoutGap="15px">
          <mat-form-field fxFlex="15">
            <mat-select multiple [placeholder]="'modules.telematic.telematicList.telematicType'|translate" formControlName="telematicType">
              <mat-option *ngFor="let telematicUnit of telematicTypeFilterList"
                          [value]="telematicUnit">
                {{resolveName(telematicUnit)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="isSuperAdmin()" fxFlex="15">
            <mat-select multiple [placeholder]="'modules.telematic.telematicList.simCardProvider'|translate" formControlName="simCardProvider">
              <mat-option *ngFor="let simCardIProvidertem of simCardProviderFilterList"
                          [value]="simCardIProvidertem">
                {{simCardIProvidertem}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="isSuperAdmin()" fxFlex="15">
            <mat-select multiple [placeholder]="'modules.telematic.telematicList.assignedPartner'|translate" formControlName="assignedPartner">
              <mat-option *ngFor="let telematicUnit of partnerFilterList"
                          [value]="telematicUnit.partnerId">
                {{telematicUnit.partnerName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="15">
            <mat-select multiple
                        [placeholder]="'modules.telematic.telematicList.customer'|translate"
                        formControlName="customerId">
              <mat-option *ngFor="let customer of customersFilterList"
                          [value]="customer.customerId">
                {{resolveName(customer.customerName)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="table-panel" fxFlex>
        <div fxFlex class="task-list-component" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 100%">
          <div class="list-view-main__left-body scrollable-table-container" fxFlex fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="start stretch">

            <mat-table class="data-table" [dataSource]="telematicUnitsList" fxFlex="1 1 100%" matSort
                       (matSortChange)="sortChange($event)">

              <ng-container cdkColumnDef="telematicsUnitType">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'general.type'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow-multiline">
                    <span>{{resolveName(row.telematicsUnitType)}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="boxNo">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.boxNumber'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.boxNo}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="serialNumber">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.serialNumber'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.serialNumber}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="telematicsUnitId">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.imei'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.telematicsUnitId}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="customerName">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.customer'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow" matTooltipPosition="above"
                     matTooltip="{{row.customerName}}">
                    <span>{{row.customerName}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="mac">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.mac'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.mac}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="simCardProvider">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.simCardProvider'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.simCardProvider}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="eSim1IccId">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.eSim1IccId'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.eSim1IccId}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="eSim1Imsi">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.eSim1Imsi'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.eSim1Imsi}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="partnerId">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="30">
                  {{'modules.telematic.telematicList.assignedPartner'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.partnerName}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="addedDate">
                <mat-header-cell *cdkHeaderCellDef fxFlex="100px" mat-sort-header disableClear start="asc">
                  {{'modules.telematic.telematicList.addedDate'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="100px" class="data-table-unit-id">
                  <p class="text-overflow">
                    <span>{{row.addedDate | date:'dd.MM.yyyy'}}</span>
                  </p>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="equipment">
                <mat-header-cell *cdkHeaderCellDef fxFlex="100px"> {{'general.equipm.s'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="100px">
                  <fa-icon *ngIf="isAssignedToEquipment(row)"
                           [icon]="faTruckPlow"
                           [matTooltip]="'modules.telematic.telematicList.assignedToEquipment'|translate">
                  </fa-icon>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="status">
                <mat-header-cell *cdkHeaderCellDef fxFlex="75px"> {{'general.status'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="75px">
                  <mat-slide-toggle color="primary"
                                    [checked]="row.isActive"
                                    [disabled]="true">
                  </mat-slide-toggle>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="menu">
                <mat-header-cell *cdkHeaderCellDef fxFlex="75px"></mat-header-cell>
                <mat-cell *cdkCellDef="let row" fxFlex="75px" class="data-table-unit-id">
                  <mat-icon class="status-menu maintenance-list-icon"
                            [matMenuTriggerFor]="telematicUnitOptions">
                    more_vert
                  </mat-icon>

                  <mat-menu #telematicUnitOptions="matMenu">
                    <button *ngIf="canEdit()" (click)="edit(row)" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>{{'modules.telematic.telematicList.edit'|translate}}</span>
                    </button>

                    <button *ngIf="canActivate(row)" (click)="activate(row)" mat-menu-item>
                      <mat-icon>lock_open</mat-icon>
                      <span>{{'modules.telematic.telematicList.activate'|translate}}</span>
                    </button>

                    <button (click)="assignTelematicUnit(row)" mat-menu-item>
                      <mat-icon fontSet="icon-telematics_outline"></mat-icon>
                      <span>{{'modules.telematic.telematicList.assignToCustomer'|translate}}</span>
                    </button>

                    <button *ngIf="row.customerName" (click)="unassignUnit(row)" mat-menu-item>
                      <mat-icon><fa-icon [icon]="faUnlink"></fa-icon></mat-icon>
                      <span>{{'modules.telematic.telematicList.unassignUnit'|translate}}</span>
                    </button>

                    <button *ngIf="canDeactivate(row)" (click)="deactivate(row)" mat-menu-item>
                      <mat-icon>lock</mat-icon>
                      <span>{{'modules.telematic.telematicList.deactivate'|translate}}</span>
                    </button>

                    <button [matTooltip]="'modules.telematic.telematicList.noTelematicUnitAssigned'|translate"
                            [matTooltipDisabled]="isAssignedToEquipment(row)"
                            [disabled]="!isAssignedToEquipment(row)"
                            (click)="showEquipmentInfo(row)" mat-menu-item>
                      <mat-icon>info</mat-icon>
                      <span>{{'modules.telematic.telematicList.equipmentInfo'|translate}}</span>
                    </button>

                    <button [matTooltip]="'modules.telematic.telematicList.noTelematicUnitAssigned'|translate"
                            [matTooltipDisabled]="isAssignedToEquipment(row)"
                            [disabled]="!isAssignedToEquipment(row)"
                            (click)="showLiveData(row)" mat-menu-item>
                      <mat-icon>pin_drop</mat-icon>
                      <span>{{'modules.telematic.telematicList.liveData'|translate}}</span>
                    </button>

                    <button [matTooltip]="'modules.telematic.telematicList.noTelematicUnitAssigned'|translate"
                            [matTooltipDisabled]="isAssignedToEquipment(row)"
                            [disabled]="!isAssignedToEquipment(row)"
                            (click)="showFotaWebData(row)" mat-menu-item>
                      <mat-icon>aod</mat-icon>
                      <span>{{'modules.telematic.telematicList.fotaWebData'|translate}}</span>
                    </button>

                    <div [matTooltip]="'modules.telematic.telematicList.notAllowToDelete'|translate"
                         [matTooltipDisabled]="!row.customerId">
                      <button *ngIf="canDelete()"
                              mat-menu-item
                              [disabled]="row.customerId"
                              (click)="delete(row)">
                        <mat-icon>delete</mat-icon>
                        <span>{{'modules.telematic.telematicList.delete'|translate}}</span>
                      </button>
                    </div>
                  </mat-menu>

                </mat-cell>
              </ng-container>



              <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>
          </div>

          <div class="list-view-main__left-body-paginator" fxFlex="60px">
            <mat-paginator
               [length]="telematicUnitStore.getLength() | async"
               [pageIndex]="telematicUnitStore.getIndex() | async"
               [pageSize]="telematicUnitStore.getSize() | async"
               (page)="telematicUnitStore.handlePageEvent($event)"
               [pageSizeOptions]="[5, 25, 50, 100]" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
