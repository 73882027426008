import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ISearchProjectTypeOfUseEntry } from './project-view-change-type-of-use.models';
import { PagedResponse } from '../../../../../../shared/contract/page-response.interface';

@Injectable({
  providedIn: 'root'
})


export class ProjectViewChangeTypeOfUseApiService {
  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/projects';

  constructor(private http: HttpClient) { }

  getProjectPeriodsTypeOfUse(projectId: string, page: number, pageSize: number): Observable<PagedResponse<ISearchProjectTypeOfUseEntry>> {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('size', pageSize.toString());
    return this.http.get<PagedResponse<ISearchProjectTypeOfUseEntry>>(`${this.url}/${projectId}/type-of-use/periods`, {params});
  }
}
