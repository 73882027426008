import { FilterTypes, FilterTypesDisplayNames } from '../contract/filter/filter-types.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilterType'
})
@Injectable()
export class SearchFilterTypePipe implements PipeTransform {

  constructor(protected languageService: LanguageService) {
  }

  transform(value: FilterTypes | FilterTypesDisplayNames | string): string {
    switch (value) {
      case FilterTypes.CLASS:
      case FilterTypes.EQUIPMENT_CLASS:
        return this.translate('shared.pipes.searchFilterType.class');
      case FilterTypes.TYPE:
        return this.translate('shared.pipes.searchFilterType.type');
      case FilterTypes.EQUIPMENT_TYPE:
        return this.translate('shared.pipes.searchFilterType.equipmentType');
      case FilterTypes.LABEL:
        return this.translate('shared.pipes.searchFilterType.label');
      case FilterTypes.ORGANISATION:
        return this.translate('shared.pipes.searchFilterType.organization');
      case FilterTypes.STATUS:
        return this.translate('shared.pipes.searchFilterType.status');
      case FilterTypes.CONTACT_STATE:
        return this.translate('shared.pipes.searchFilterType.contactStatusName');
      case FilterTypes.EMPLOYEE_STATE:
        return this.translate('shared.pipes.searchFilterType.employeeStatusName');
      case FilterTypes.EQUIPMENT_STATE:
        return this.translate('shared.pipes.equipmentFields.equipmentStatusName');
      case FilterTypes.PROJECT_STATE:
        return this.translate('shared.pipes.searchFilterType.projectStatusName');
      case FilterTypes.SUBEQUIPMENT:
        return this.translate('shared.pipes.searchFilterType.subequipment');
      case FilterTypes.RESPONSIBLE_PERSON:
        return this.translate('shared.pipes.searchFilterType.responsiblePerson');
      case FilterTypes.TRANSPORT_TYPE:
        return this.translate('shared.pipes.searchFilterType.transportType');
      case FilterTypes.TRANSPORT_STATE:
        return this.translate('shared.pipes.searchFilterType.state');
      case FilterTypes.TRANSPORT_RELATED_PROJECT:
        return this.translate('shared.pipes.searchFilterType.relatedProject');
      case FilterTypes.PRIORITY:
        return this.translate('shared.pipes.searchFilterType.priority');
      case FilterTypes.TRANSPORT_ASSIGNED_VEHICLE:
        return this.translate('shared.pipes.searchFilterType.assignedVehicle');
      case FilterTypes.TRANSPORT_VEHICLE:
        return this.translate('shared.pipes.searchFilterType.transportVehicle');
      case FilterTypes.FAVOURITE_EQUIPMENT:
        return this.translate('shared.pipes.searchFilterType.favouriteEquipment');
      case FilterTypes.MAINTENANCE_TYPE:
        return this.translate('shared.pipes.searchFilterType.maintenanceType');
      case FilterTypes.ARTICLE_GROUPS:
        return this.translate('shared.pipes.searchFilterType.articleGroups');
      case FilterTypes.EQUIPMENT_LOCATIONS:
        return this.translate('shared.pipes.searchFilterType.currentLocation');
      case FilterTypes.DUE_DATE:
        return this.translate('shared.pipes.searchFilterType.dueDate');
      case FilterTypes.MAINTENANCE_CATEGORY:
        return this.translate('shared.pipes.searchFilterType.maintenanceCategory');
      case FilterTypes.MAINTENANCE_RESULT:
        return this.translate('shared.pipes.searchFilterType.maintenanceResult');
      case FilterTypes.CATEGORY:
        return this.translate('shared.pipes.searchFilterType.category');
      case FilterTypes.ONLY_LATEST_COMPLETED_FOR_RULE:
        return this.translate('shared.pipes.searchFilterType.latestResult');
      case FilterTypes.CHARGE_TYPE:
        return this.translate('shared.pipes.searchFilterType.chargeType');
      case FilterTypes.SOURCE_LOCATION:
        return this.translate('shared.pipes.searchFilterType.sourceLocation');
      case FilterTypes.SOURCE_LOCATION_ORGANISATION:
        return this.translate('shared.pipes.searchFilterType.sourceLocationOrganisation');
      case FilterTypes.TARGET_LOCATION:
        return this.translate('shared.pipes.searchFilterType.targetLocation');
      case FilterTypes.TARGET_LOCATION_ORGANISATION:
        return this.translate('shared.pipes.searchFilterType.targetLocationOrganisation');
      case FilterTypes.LOCATION:
        return this.translate('shared.pipes.searchFilterType.location');
      case FilterTypes.EQUIPMENT_ORGANISATION:
        return this.translate('shared.pipes.searchFilterType.organizationEquipment');
      case FilterTypes.TRANSFER_TYPE:
        return this.translate('shared.pipes.searchFilterType.transferType');
      case FilterTypesDisplayNames.ORGANISATION_CONTACT:
        return this.translate('shared.pipes.searchFilterType.organizationContact');
      case FilterTypesDisplayNames.ORGANISATION_EQUIPMENT:
        return this.translate('shared.pipes.searchFilterType.organizationEquipment');
      case FilterTypesDisplayNames.ORGANISATION_RELATED_PROJECT:
        return this.translate('shared.pipes.searchFilterType.organizationRelatedProject');
      case FilterTypesDisplayNames.ORGANISATION_STOCK:
        return this.translate('shared.pipes.searchFilterType.organizationStock');
      case FilterTypesDisplayNames.ORGANISATION_VEHICLE:
        return this.translate('shared.pipes.searchFilterType.organizationVehicle');
      case FilterTypesDisplayNames.ORGANISATION_EMPLOYEE:
        return this.translate('shared.pipes.searchFilterType.organizationEmployee');
      case FilterTypesDisplayNames.EQUIPMENT_STATUS_VEHICLE:
        return this.translate('shared.pipes.searchFilterType.equipmentStatusVehicle');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
