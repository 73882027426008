import { ButtonInfo } from '../../../../shared/contract/button-info';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DisplayService } from '../../../../shared/display.service';
import { BulkItemDatasource } from '../../shared/bulk-item.datasource';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { SearchBulkItem } from '../../contract/search-bulk-item.interface';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { BulkItemTypeResolver } from '../bulk-item-add-edit/bulk-item-type.resolver';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { BulkItemColumnService } from '../service/bulk-item-column.service';
import { ListType } from '../../../../shared/enums/list-type.enum';
import { TabService } from '../../../../shared/tab.service';
import { MatDialog } from '@angular/material/dialog';
import { BulkItemAddEditComponent } from '../bulk-item-add-edit/bulk-item-add-edit.component';
import { RouterHistory } from '../../../../shared/router-history';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { AssetListActivatedView } from 'app/shared/contract/activated-view.interface';
import { FocusMonitor } from '@angular/cdk/a11y';
import { CurrentItemPaginatorUtils } from 'app/shared/directives/current-item-paginator/current-item-paginator-utils.class';
import { COLUMN_TYPE } from '../../../../shared/constants/column-definition-constants';
import {
  TableConfigurationData,
  TableConfigurationDialogComponent,
} from '../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { environment } from 'environments/environment';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-list',
  templateUrl: './bulk-item-list.component.html',
  styleUrls: ['./bulk-item-list.component.scss']
})
export class BulkItemListComponent extends GuardedNavigableInputComponent
  implements AssetListActivatedView, OnInit {

  public searchForm: UntypedFormGroup;
  public shownColumns: ColumnDefinition[] = [];
  public routeButton = new ButtonInfo();
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly isIdentifierOfBulkItem = CurrentItemPaginatorUtils.isIdentifierOfBulkItem;
  public readonly searchSuggestionsField = 'bulkItemListSuggestions';
  public filters: FilterType[] = this.bulkItemStore.filters;
  public onFiltersUpdated: EventEmitter<any> = this.bulkItemStore.onFiltersUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  private searchTermSubscription = new Subscription();

  @ViewChild('paginator', {static: true}) private paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) private sort: MatSort;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public bulkItemStore: BulkItemDatasource,
              public displayService: DisplayService,
              protected columnService: BulkItemColumnService,
              public optionResolver: BulkItemTypeResolver,
              private tabService: TabService,
              private focusMonitor: FocusMonitor,
              private dialog: MatDialog) {
    super(authService, router, route, routerHistory);
    this.bulkItemStore.updateFilters();
    this.bulkItemStore.updateListing();
    const create = this.route.snapshot.data['create'];
    if (create) {
      this.dialog.open(BulkItemAddEditComponent, {disableClose: true}).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['assets/bulk-item/list']);
        }
      });
    } else if (!this.route.snapshot.firstChild) {
      bulkItemStore.selectCurrentBulkItemOrDefault();
    } else {
      setTimeout(() => displayService.displayTableAndDetails(), 0);
    }
  }

  private get searchTermsControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  public ngOnInit(): void {
    this.tabService.changeAssetTab(ListType.BULK_ITEMS);
    this.columnService.selectedColumns.pipe(untilDestroyed(this)).subscribe(shownColumns => this.shownColumns = shownColumns);
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe(pageSize => this.paginator.pageSize = pageSize);
    this.bulkItemStore.updateListing();
    this.bulkItemStore.length.subscribe(value => this.onTotalCountUpdated.emit(value));
    this.setSort();
  }

  public openDetails(): void {
    this.displayService.displayTableAndDetails();
  }

  public openConfiguration(): void {
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {columnService: this.columnService} as TableConfigurationData,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((columns: ColumnDefinition[]) => {
      if (columns) {
        this.columnService.selectColumns(columns, this.paginator.pageSize);
        this.bulkItemStore.updateListing();
      }
      this.focusMonitor.stopMonitoring(document.getElementById('configBtn'));
    });
  }

  public onPaginateChange(event: PageEvent): void {
    this.bulkItemStore.updateListing(event.pageIndex, event.pageSize);
  }

  public sortData(sort: Sort): void {
    this.bulkItemStore.sort = sort;
    this.bulkItemStore.updateListing();
  }

  public reset(): void {
    this.bulkItemStore.reset();
    this.initSearchTermListener();
    this.setSort();
  }

  public getFirstLabels(bulkItem: SearchBulkItem, limitToTwo?: boolean): string[] {
    if (this.shownColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...bulkItem.labels || []];
    if (limitToTwo === true || this.shownColumns.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(bulkItem: SearchBulkItem, limitToTwo?: boolean): string[] {
    if (this.shownColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...bulkItem.labels || []];
    if (limitToTwo === true || this.shownColumns.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }

  public onSearchFormType(): void {
    this.initSearchTermListener();
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.bulkItemStore.updateFilterParams(commands);
    this.bulkItemStore.updateListing(0);
  }

  private initSearchTermListener(): void {
    this.searchTermSubscription.unsubscribe();
    this.searchTermSubscription = this.searchTermsControl.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(() => this.updateOnSearch());
    this.setSearchTerms(false);
  }

  public updateOnSearch(): void {
    this.bulkItemStore.searchTerms = this.searchTermsControl.value;
    this.bulkItemStore.updateListing(0);
  }

  public onSearchFormClick(): void {
    this.bulkItemStore.updateListing(0, this.paginator.pageSize);
  }

  public setSearchTerms(emitEvent: boolean = true): void {
    this.searchForm.patchValue({terms: this.bulkItemStore.searchTerms}, { emitEvent });
  }

  private setSort(): void {
    const { active, direction } = this.bulkItemStore.sort ?? {};
    this.sort.active = active;
    this.sort.direction = direction;
    this.sort._stateChanges.next();
  }
}
