<div class="equipment-details-wrapper" fxLayout="column">

  <div class="equipment-details-header">
    <bh-title [text]="'general.generalData'|translate"></bh-title>
  </div>

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms" #detailTabGroup
                   (selectedTabChange)="updateTab($event)">

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faInfoCircle"></fa-icon> {{'general.baseData'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-equipment-view-general>
          </bh-equipment-view-general>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faStickyNote"></fa-icon> {{'general.description'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-description *ngIf="equipment"
                          [maxLength]="maxDescriptionLength"
                          [(description)]="equipment.description"
                          [isActive]="equipmentCheckerService.isActiveEquipment(equipment)"
                          [isEditingAllowed]="hasAnyAuthority([authorities.EQUIPMENT_UPDATE, authorities.EQUIPMENT_UPDATE_DESCRIPTION])"
                          (descriptionSave)="saveEquipmentDescription($event)">
          </bh-description>
        </div>
      </mat-tab>

      <mat-tab [label]="'modules.equipment.base.technicalData'|translate"
               *ngIf="hasAnyAuthority([authorities.EQUIPMENT_VIEW,
               authorities.PROJECT_ASSIGNEE_VIEW, authorities.EQUIPMENT_TECHNICAL_DATA_MANAGE])">
        <ng-template mat-tab-label>
          <mat-icon fontSet="icon-tec_data_outline"></mat-icon>
          {{'modules.equipment.base.technicalData'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-equipment-view-technical-field>
          </bh-equipment-view-technical-field>
        </div>
      </mat-tab>

      <mat-tab #historyTab *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW_HISTORY)">
        <ng-template mat-tab-label>
          <fa-icon [icon]="faHistory"></fa-icon>
          {{'general.history'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content ">
          <bh-equipment-view-history [isActive]="historyTab.isActive">
          </bh-equipment-view-history>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon fontSet="icon-attachment_outline"></mat-icon>
          {{'general.attachment.pl'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-equipment-view-attachment>
          </bh-equipment-view-attachment>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>

</div>
