import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import {
  RoleAuthorityGuardsComponent
} from '../../../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { combineLatest, Observable } from 'rxjs';
import {
  AdditionalField,
  AdditionalFieldValue,
} from '../../../../../../osp-ui/rich-components/bh-additional-field/additional-field';
import { EquipmentAdditionalFieldService } from '../../../../../shared/services/equipment-additional-fields.service';
import { UpdateEquipmentGeneralFieldCommand } from '../../../../../contract/update-equipment-general-field.command';
import { delay } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';
import { TranslatableStringPipe } from '../../../../../../osp-ui/pipes/translatable-string/translatable-string.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-additional-general-fields',
  templateUrl: './equipment-additional-general-fields.component.html',
  styleUrls: ['./equipment-additional-general-fields.component.scss']
})
export class EquipmentAdditionalGeneralFieldsComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public equipment: ViewEquipment;
  public generalFields: AdditionalFieldValue[] = [];
  public editable: boolean;

  constructor(protected authService: KeycloakService,
              protected equipmentStore: EquipmentsDataSource,
              protected equipmentCheckerService: EquipmentCheckerService,
              private equipmentAdditionalFieldService: EquipmentAdditionalFieldService,
              private translatableStringPipe: TranslatableStringPipe) {
    super(authService);
  }

  public ngOnInit(): void {
    this.subscribeToEquipment();
  }

  private subscribeToEquipment(): void {
    combineLatest([this.equipmentStore.currentEquipment, this.fetchEquipmentAdditionalGeneralFields()])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([viewEquipment, additionalGeneralFields]: [ViewEquipment, AdditionalField[]]) => {
          if (viewEquipment) {
            this.equipment = viewEquipment;
            this.editable = this.hasAuthority(Authorities.EQUIPMENT_UPDATE)
              && this.equipmentCheckerService.isActiveEquipment(viewEquipment);

            const {generalFieldValues} = viewEquipment;
            this.generalFields = additionalGeneralFields
              .map(field => this.toAdditionalFieldValue(field, generalFieldValues))
              .sort((a, b) => this.compareAdditionalFieldByName(a, b));
          }
        }
      });
  }

  private fetchEquipmentAdditionalGeneralFields(): Observable<AdditionalField[]> {
    return this.equipmentAdditionalFieldService.getAllGeneralFields();
  }

  public onFieldEdit(field: AdditionalFieldValue): void {
    const command: UpdateEquipmentGeneralFieldCommand = {
      equipmentId: this.equipment.equipmentId,
      additionalFieldId: field.additionalFieldId,
      additionalFieldValue: field.value
    };

    this.equipmentAdditionalFieldService.updateGeneralFieldValue(command)
      .pipe(delay(environment.DELAY_SHORT))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.equipmentStore.updateCurrentEquipment());
  }

  private toAdditionalFieldValue(field: AdditionalField, generalFieldValuesMap: any): AdditionalFieldValue {
    const value = (generalFieldValuesMap || {})[field.additionalFieldId] ?? field.additionalFieldDefaultValue;
    return {...field, value};
  }

  private compareAdditionalFieldByName(a: AdditionalFieldValue, b: AdditionalFieldValue): number {
    const aName = this.translatableStringPipe.transform(a.additionalFieldName) ?? a.additionalFieldKey ?? '';
    const bName = this.translatableStringPipe.transform(b.additionalFieldName) ?? b.additionalFieldKey ?? '';
    return aName.localeCompare(bName);
  }
}
