import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  CreateCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/create-customer-telematic-unit-command';
import {
  UpdateCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/update-customer-telematic-unit-command';
import {TelematicUnitListItem} from '../shared/telematic-unit-list.interface';
import {PagedResponse} from '../../../shared/contract/page-response.interface';
import {getHttpParameters} from '../../../shared/utils';
import {
  AssignCustomerTelematicUnitToCustomerCommand
} from '../shared/commands/action/assign-customer-telematic-unit-to-customer-command';
import {SearchCustomer} from '../../organisation/contract/search-customer.interface';
import {
  DeleteCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/delete-customer-telematic-unit-command';
import {ActivateCustomerTelematicUnitCommand} from '../shared/commands/action/activate-customer-telematic-unit-command';
import {
  DeactivateCustomerTelematicUnitCommand
} from '../shared/commands/action/deactivate-customer-telematic-unit-command';
import {PartnerInfo} from '../shared/partner-info.interface';
import {UnassignCustomerTelematicUnitCommand} from '../shared/commands/action/unassign-customer-telematic-unit-command';
import {TelematicUnitImportResultInterface} from '../shared/telematic-unit-import-result.interface';
import {TelematicUnitCustomerEquipmentInfo} from '../shared/telematic-unit-customer-equipment-info';
import {TelematicUnitTypeEnum} from '../shared/telematic-unit-type.enum';
import {CustomerTelematicUnitTypeEnum} from '../shared/customer-telematic-unit-type.enum';
import {TelematicsUnitType} from '../../equipment/contract/telematics-unit-type.enum';

@Injectable({
  providedIn: 'root'
})
export class TelematicUnitAdministrationService {
  private telematicsUnitsUrl = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/telematics-units';
  private teltonikaTelematicsUnitsUrl = this.telematicsUnitsUrl + '/teltonika';
  private digitalMatterTelematicsUnitsUrl = this.telematicsUnitsUrl + '/digital-matter';
  private telematicUnitsCustomersUrl = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/customers';
  private partnersInfoUrl = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/partners';
  private fotaApiToken = environment.FOTA_API_TOKEN;

  constructor(private http: HttpClient) {}

  public getTelematicUnits(data: Object): Observable<PagedResponse<TelematicUnitListItem>> {
    const params = getHttpParameters(data);
    return this.http.get<PagedResponse<TelematicUnitListItem>>(`${this.telematicsUnitsUrl}/search/all`, {params: params});
  }

  public addCustomerTelematicUnit(cmd: CreateCustomerTelematicUnitCommand): Observable<string> {
    switch (cmd.telematicsUnitType) {
      case TelematicUnitTypeEnum.DIGITAL_MATTER_OYSTER3_UNIT:
        return this.addDigitalMatterTelematicUnit(cmd);
      case TelematicUnitTypeEnum.TELTONIKA_CABLE_UNIT:
      case TelematicUnitTypeEnum.TELTONIKA_OBD_UNIT:
        return this.addTeltonikaTelematicUnit(cmd);
    }
  }

  public addTeltonikaTelematicUnit(command: CreateCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(`${this.teltonikaTelematicsUnitsUrl}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public addDigitalMatterTelematicUnit(command: CreateCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(`${this.digitalMatterTelematicsUnitsUrl}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateCustomerTelematicUnit(cmd: UpdateCustomerTelematicUnitCommand, type: TelematicsUnitType): Observable<string> {
    switch (type) {
      case TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT:
        return this.updateDigitalMatterTelematicsUnit(cmd);
      case TelematicsUnitType.TELTONIKA_CABLE_UNIT:
      case TelematicsUnitType.TELTONIKA_OBD_UNIT:
        return this.updateTeltonikaTelematicUnit(cmd);
    }
  }

  public updateTeltonikaTelematicUnit(command: UpdateCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(`${this.teltonikaTelematicsUnitsUrl}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDigitalMatterTelematicsUnit(command: UpdateCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(`${this.digitalMatterTelematicsUnitsUrl}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public assignTelematicUnitToCustomer(command: AssignCustomerTelematicUnitToCustomerCommand): Observable<string> {
    return this.http.post(`${this.telematicsUnitsUrl}/assign-to-customer`, JSON.stringify(command), {responseType: 'text'})
  }

  public unAssignTelematicUnitFromCustomer(command: UnassignCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(`${this.telematicsUnitsUrl}/unassign-from-customer`, JSON.stringify(command), {responseType: 'text'})
  }

  public activateTelematicUnit(command: ActivateCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(this.telematicsUnitsUrl + '/activate', JSON.stringify(command), {responseType: 'text'});
  }

  public deactivateTelematicUnit(command: DeactivateCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(this.telematicsUnitsUrl + '/deactivate', JSON.stringify(command), {responseType: 'text'});
  }

  public bulkImportCustomerTelematicUnit(data: CreateCustomerTelematicUnitCommand[]): Observable<TelematicUnitImportResultInterface[]> {
    switch (data[0].telematicsUnitType) {
      case TelematicUnitTypeEnum.DIGITAL_MATTER_OYSTER3_UNIT:
        return this.digitalMatterBulkImport(data);
      case TelematicUnitTypeEnum.TELTONIKA_OBD_UNIT:
      case TelematicUnitTypeEnum.TELTONIKA_CABLE_UNIT:
        return this.teltonikaBulkImport(data);
    }
  }

  public teltonikaBulkImport(data: CreateCustomerTelematicUnitCommand[]): Observable<TelematicUnitImportResultInterface[]> {
    const url = this.teltonikaTelematicsUnitsUrl + '/bulk-import';
    return this.http.post<TelematicUnitImportResultInterface[]>(url, JSON.stringify(data));
  }

  public digitalMatterBulkImport(data: CreateCustomerTelematicUnitCommand[]):
    Observable<TelematicUnitImportResultInterface[]> {
    const url = this.digitalMatterTelematicsUnitsUrl + '/bulk-import';
    return this.http.post<TelematicUnitImportResultInterface[]>(url, JSON.stringify(data));
  }

  public getSimCardProviders(): Observable<string[]> {
    return this.http.get<string[]>(this.telematicsUnitsUrl + '/sim-card-providers');
  }

  public deleteCustomerTelematicUnit(cmd: DeleteCustomerTelematicUnitCommand, type: CustomerTelematicUnitTypeEnum): Observable<string> {
    switch (type) {
      case CustomerTelematicUnitTypeEnum.DIGITAL_MATTER:
        return this.deleteDigitalMatterTelematicUnit(cmd);
      case CustomerTelematicUnitTypeEnum.TELTONIKA:
        return this.deleteTeltonikaTelematicUnit(cmd);
    }
  }

  public deleteTeltonikaTelematicUnit(command: DeleteCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(this.teltonikaTelematicsUnitsUrl + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteDigitalMatterTelematicUnit(command: DeleteCustomerTelematicUnitCommand): Observable<string> {
    return this.http.post(this.digitalMatterTelematicsUnitsUrl + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public getPartnersInfo(): Observable<PartnerInfo[]> {
    return this.http.get<PartnerInfo[]>(this.partnersInfoUrl);
  }

  public getAllCustomers(partnerId?: string): Observable<SearchCustomer[]> {
    let params = {
      'partnerId': partnerId
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<SearchCustomer[]>(this.telematicUnitsCustomersUrl, {params: httpParams});
  }

  public getEquipmentInfo(imei: string): Observable<TelematicUnitCustomerEquipmentInfo> {
    let params = {
      'imei': imei
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<TelematicUnitCustomerEquipmentInfo>(this.telematicsUnitsUrl + '/equipment-info', {params: httpParams});
  }

  public getFotaWebData(imei: string): Observable<Object> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.fotaApiToken
    });
    const url = 'https://api.teltonika.lt/devices/' + imei;
    return this.http.get<Object>(url, {headers: headers});
  }
}
