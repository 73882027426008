import { TransferItemUnit } from '../../modules/disposition/contract/transfer-item-unit';
import configureMeasurements from 'convert-units';
import allMeasures, { AllMeasures, AllMeasuresSystems, AllMeasuresUnits, } from 'convert-units/definitions/all';

export const measurementConvert = configureMeasurements<AllMeasures, AllMeasuresSystems, AllMeasuresUnits>(allMeasures);

export function normalizeUnitType(unit: string | AllMeasuresUnits | TransferItemUnit): AllMeasuresUnits {
  switch (unit) {
    case TransferItemUnit.PIECE:
      return 'pcs';
    case TransferItemUnit.METER:
      return 'm';
    case TransferItemUnit.CENTIMETER:
      return 'cm';
    case TransferItemUnit.TONNE:
      return 'mt';
    case TransferItemUnit.KILOGRAM:
      return 'kg';
    case TransferItemUnit.GRAM:
      return 'g';
    case TransferItemUnit.LITER:
      return 'l'
    case TransferItemUnit.MILLILITER:
      return 'ml';
    case TransferItemUnit.CUBIC_METER:
      return 'm3';
    case TransferItemUnit.PACKAGE:
      return 'ream';
  }

  return unit as AllMeasuresUnits;
}

export function resolvePairImperialUnit(valueUnitType: AllMeasuresUnits): AllMeasuresUnits {
  switch (valueUnitType) {
    case 'km':
      return 'mi';
    case 'm':
      return 'ft';
    case 'cm':
      return 'in';
    case 'mt':
      return 't';
    case 'kg':
      return 'lb';
    case 'g':
      return 'oz';
    case 'l':
      return 'qt';
    case 'ml':
      return 'fl-oz';
    case 'm3':
      return 'ft3';
    default:
      return valueUnitType
  }
}

