import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { LanguageService } from '../../../../shared/services/language.service';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { EquipmentStatusCategoryPipe } from '../../../../shared/pipes/equipment-status-category.pipe';
import { AbstractEquipmentStatusStateChangeComponent } from '../abstract-equipment-status-state-change.component';

@Component({
  selector: 'bh-equipment-status-state-change',
  templateUrl: './equipment-status-state-change.component.html',
  styleUrls: ['./equipment-status-state-change.component.scss']
})
export class EquipmentStatusStateChangeComponent extends AbstractEquipmentStatusStateChangeComponent {

  @Input() equipment: ViewEquipment;
  @Input() disabled = false;

  constructor(dialog: MatDialog,
              langService: LanguageService,
              equipmentStore: EquipmentsDataSource,
              equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe) {
    super(dialog, langService, equipmentStore, equipmentStatusCategoryPipe);
  }
}
