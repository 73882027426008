<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex="{{ editMode ? 100 : 90 }}" fxLayout="column" class="editable-text">
    <div fxLayout="row" class="textInfo" fxLayoutAlign="space-between stretch">
      <span *ngIf="textInfo && !editMode" class="text-info">{{textInfo}}</span>
    </div>
    <quill-editor [ngClass]="{'hide-toolbar': !editMode, 'edit-mode': editMode}"
                  class="editor"
                  [maxLength]="maxLength"
                  [readOnly]="!editMode"
                  [placeholder]="placeholder"
                  [modules]="{toolbar: config}"
                  format="html"
                  [(ngModel)]="text"
                  (onContentChanged)="textChanged($event)">
    </quill-editor>
  </div>
  <div class="buttons-container" *ngIf="!editMode">
    <div class="edit-button" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon *ngIf="!editMode && isActive && editingAllowed"
                matSuffix
                [matTooltip]="'general.buttons.change'|translate"
                (click)="editDescription()">
        mode_edit
      </mat-icon>
    </div>
    <div class="delete-button" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon *ngIf="!editMode && isActive && editingAllowed"
                matSuffix
                [matTooltip]="'general.buttons.delete'|translate"
                (click)="delete()">
        delete_forever
      </mat-icon>
    </div>
  </div>
</div>
<span class="hint" *ngIf="editMode && revertedChanges">
      {{'shared.textEdit.descriptionLengthWarning'|translate}}
</span>
<div class="buttons" *ngIf="editMode" mat-dialog-actions>
  <button mat-button (click)="cancel()">{{'general.buttons.cancel'|translate}}</button>
  <button mat-raised-button
          bhTimeoutSaveButton
          [disabled]="revertedChanges || textEmpty"
          (throttledClick)="save()" color="primary">
    {{'general.buttons.save'|translate}}
  </button>
</div>
