import { Component } from '@angular/core';
import { MobileWebAppPopupMode } from 'app/shared/enums/mobile-web-app-popup-mode.enum';
import { environment } from 'environments/environment';
import { KeycloakService } from 'app/core/keycloak';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { PartnerTheme } from 'app/modules/organisation/contract/partner-theme.enum';

@Component({
  selector: 'bh-native-app-links-dialog',
  templateUrl: './native-app-links-dialog.component.html',
  styleUrls: ['./native-app-links-dialog.component.scss']
})
export class NativeAppLinksDialogComponent {

  constructor(private userConfigurationService: UserConfigurationService) { }

  showCloseIcon(): boolean {
    return environment.MOBILE_WEB_APP_POPUP_MODE === MobileWebAppPopupMode.SHOW_POPUP_CLOSABLE;
  }

  public isWackerNeusonDomain(): boolean {
    return KeycloakService.isWackerNeusonDomain();
  }

  public isWNTheme(): boolean {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.WACKER_NEUSON;
  }

  public isTriathlonTheme(): boolean {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.TRIATHLON;
  }
}
