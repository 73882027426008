import { ColumnDefinition } from 'app/shared/column-definition';
import { ContactColumnDefinitionService } from './contact-column-definition.service';
import { KeycloakService } from 'app/core/keycloak';
import { DisplayService } from 'app/shared/display.service';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { Injectable } from '@angular/core';
import { ColumnService } from 'app/shared/column.service';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';


@Injectable()
export class ContactColumnService extends ColumnService {

  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService,
              protected contactColumnDefinitionService: ContactColumnDefinitionService) {
    super('contactOverview',
      contactColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.contactListConfiguration.columns,
      userConfigurationService.userConfiguration.contactListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.contactListConfiguration.pageSize },
      displayService,
      authService);
  }

  get columnLimit(): number {
    return undefined;
  }

  public selectColumns(columns: ColumnDefinition[], pageSize: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === 'identifier'
      || column.cdkColumnDef === 'name'
      || column.cdkColumnDef === 'type'
      || column.cdkColumnDef === 'email'
      || column.cdkColumnDef === 'phoneNumber'
      || column.cdkColumnDef === 'address'
      || column.cdkColumnDef === 'openDetails'
      || column.cdkColumnDef === 'icon';
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveContactListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.columnConfigsSubject.getValue(),
      this.pageSizeSubject.getValue(),
    );
  }
}

