import { MobileTransferItem } from './mobile-transfer-item';
import { TransferItemType } from '../shared/transfer/model/transfer-item-type.enum';
import { ViewAmount } from './view-amount';
import { ScanEquipment } from 'app/modules/equipment/shared/scan-equipment';

export class StockToProjectTransferItem {

  public static from(item: ViewAmount, transferAmount: number) {
    return new StockToProjectTransferItem(
      item.stockId,
      item.bulkItemId ? item.bulkItemId : item.equipmentId,
      item.bulkItemId ? TransferItemType.BULKITEM : TransferItemType.EQUIPMENT,
      item.nonReturnable,
      transferAmount,
      item.amountId);
  }

  public static fromMobileTransferItem(item: MobileTransferItem, sourceStockId: string) {
    return new StockToProjectTransferItem(sourceStockId, item.transferId, TransferItemType.BULKITEM, item.nonReturnable, item.amount);
  }


  public static fromScanEquipment(source: ScanEquipment) {
    return new StockToProjectTransferItem(
      source.sourceStockId,
      source.equipmentId,
      TransferItemType.EQUIPMENT,
      false,
      1
    );
  }

  private constructor(
    public sourceStockId: string,
    public transferItemId: string,
    public transferItemType: TransferItemType,
    public nonReturnable: boolean,
    public amount: number,
    public amountId?: string) {}
}
