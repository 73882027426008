import { EquipmentTelematicsDataseriesItem } from 'app/modules/equipment/contract/equipment-telematics-dataseries-item';
import { ChartDataTransformStrategy } from '../chart-data-transform-strategy.interface';
import { ChartDataset } from 'chart.js';
import { Utils } from 'app/shared/utils';
import { TransformStrategyDatasetOptions } from './transform-strategy-options.interface';

export class SingleSeriesLineTransformStrategy implements ChartDataTransformStrategy<EquipmentTelematicsDataseriesItem> {
  constructor(private options: TransformStrategyDatasetOptions) {}

  public isValidData(data: EquipmentTelematicsDataseriesItem): boolean {
    return data?.series?.length > 0;
  }

  public getChartDataLabels(data: EquipmentTelematicsDataseriesItem): string[] {
    return this.isValidData(data)
      ? data?.series.map(ser => ser.name)
      : [];
  }

  public getChartDatasets(data: EquipmentTelematicsDataseriesItem): ChartDataset[] {
    return this.isValidData(data)
    ? [{
        borderColor: this.options?.color,
        pointBackgroundColor: this.options?.color,
        fill: false,
        data: data?.series.map(ser => Utils.round(ser.value, 1))
      }]
    : [];
  }

}
