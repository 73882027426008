<mat-card class="entity-creation">

  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>location_city</mat-icon>
      <span>{{'modules.equipment.manufacturer.editManufacturer'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.save'|translate}} |
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.toOverview'|translate}}</button>
    </div>

    <div class="entity-creation-form" fxFlex="100">

      <form [formGroup]="manufacturerEditForm" novalidate>

        <div class="entity-creation-form-left" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">

          <mat-form-field>
            <mat-label>{{'general.name.s'|translate}}</mat-label>
            <input bhRemoveWhitespaces matInput formControlName="manufacturerName" required>
            <mat-error *ngIf="manufacturerName.hasError('required')">
              {{'shared.validation.required'|translate:{ value:'general.name.s'|translate } }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'modules.equipment.manufacturer.abbreviation'|translate}}</mat-label>
            <input matInput formControlName="manufacturerAbbreviation">
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'modules.equipment.manufacturer.makerCode'|translate}}</mat-label>
            <input matInput formControlName="manufacturerMakerCode">
          </mat-form-field>

        </div>

      </form>

    </div>

  </mat-card-content>

</mat-card>
