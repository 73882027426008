import { Pipe, PipeTransform } from '@angular/core';
import {BEUT_19720_filter_out_vars} from '../enums/maintenance-dynamic-field.enum';

@Pipe({
  name: 'isAllowedDynamicField'
})
export class IsAllowedDynamicField implements PipeTransform {

  transform(value: any): boolean {
    return !Object.values(BEUT_19720_filter_out_vars).includes(value);
  }

}
