export type Clone<T> = {
  [P in keyof T]: T[P];
};

export class AttachedImage {
  fileName: string;
  mimeType: string;
  imageKey: string;

  static fromData(data: Clone<AttachedImage>): AttachedImage {
    let instance = new AttachedImage();
    instance.fileName = data.fileName;
    instance.mimeType = data.mimeType;
    instance.imageKey = data.imageKey;
    return instance;
  }
}
