import { MaintenanceFieldValue } from '../../../../../../shared/contract/task-completion/maintenance-field-value';
import { AttachedDocument } from '../../../../../../shared/contract/attached-document.interface';
import {OspDateTime} from '../../../../../equipment/contract/osp-date-time';
import { MaintenanceResult } from '../../../../../../shared/enums/maintenance-result';

export class UpdateMaintenanceTaskAfterCompletionCommand {
  constructor(
      public maintenanceTaskId: string,
      public maintenanceFieldValues: MaintenanceFieldValue[],
      public documents: AttachedDocument[],
      public completedAtDate: OspDateTime,
      public completedAtMileage: number,
      public completedAtOperatingHours: number,
      public maintenanceResult: MaintenanceResult) {}
}
