import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EquipmentsDataSource } from '../../modules/equipment/shared/equipments.datasource';

export class ManufacturerSerialNumberInUseValidator {
  static isValid(value: string, equipmentDataSource: EquipmentsDataSource, manufacturerId?: string, oldSerialNumber?: string) {
    if (!value || value === oldSerialNumber) {
      return of(null);
    }
    return equipmentDataSource.manufacturerSerialNumberInUse(value, manufacturerId)
    .pipe(map(res => {
      return res ? { taken: true } : null;
    }));
  }
}
