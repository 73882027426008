import {
  IconDefinition,
  IconName,
  IconPrefix
} from '@fortawesome/fontawesome-common-types';

// Content of the "icon" property
//
// icon: [
//   number         width
//   number         height
//   string[]       ligatures
//   string         unicode
//   IconPathData   svgPathData
// ];
const cfaTrashShovel: IconDefinition = {
  prefix: 'cfa' as IconPrefix,
  iconName: 'trash-shovel' as IconName,
  icon: [512, 512, null, null,
    [
      `M 65.79 310.96 L 53 85 H 286 C 282.21 151.42 274.35 289.61 273.21 310.96 C 272.08 332.31 253.32 337.88 244.09 338 H 94.91 C 72.75 338 66.26 319.97 65.79 310.96 Z
      M 366.23 200.06 C 347.82 218.16 343.22 246.54 354.9 269.61 L 288 336.32 L 315.61 364 L 382.51 296.93 C 405.52 308.64 433.49 304.03 451.89 285.58 L 471.71 266.06 C 483.75 254 478.8 238.38 471.71 231.64 L 420.39 180.19 C 410.83 170.6 395.61 170.6 386.05 180.19 L 366.23 200.06 Z`,

      `M 53.53 85 H 286.46 H 296.41 C 304.36 85 306.11 78.63 306 75.45 V 56 C 306 48.08 299.6 46.34 296.41 46.46 H 223.26 C 222.79 45.28 220.99 41.29 217.58 34.79 C 214.17 28.28 207.4 26.89 204.44 27.01 H 135.2 C 128.38 27.01 123.84 32.19 122.42 34.79 L 116.38 46.46 H 43.59 C 35.63 46.46 34 52.11 34 56 V 75.45 C 34 83.59 39.68 85 43.59 85 H 53.53 Z
      M192.877 351.043C162.28 380.81 165.126 460.543 179.002 474.009C192.521 487.829 272.572 490.664 302.814 460.543L344.084 419.436C351.912 411.64 351.912 399.591 344.44 391.795L261.543 309.581C254.072 302.14 241.619 302.14 234.148 309.581L192.877 351.043Z`
    ]
  ]
};

export {
  cfaTrashShovel
};
