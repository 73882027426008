<div [formGroup]="dataForm" fxLayout="column">
  <mat-form-field>
    <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.alarmMessage'|translate}}</mat-label>
    <textarea matInput
              [formControlName]="controlNames.DATA_ALARM_MESSAGE"
              rows="4"
              [maxLength]="fieldLimit.LONG_TEXT"
              bhRemoveWhitespaces
              required>
    </textarea>
    <mat-error *ngIf="alarmMessageControl.hasError('required')">
      {{'shared.validation.requiredField'|translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.alarmLevel'|translate}}</mat-label>
    <mat-select [formControlName]="controlNames.DATA_ALARM_LEVEL" required>
      <mat-option *ngFor="let level of alarmLevels" [value]="level">{{level | alarmLevelDisplay}}</mat-option>
    </mat-select>
    <mat-error *ngIf="alarmLevelControl.hasError('required')">
      {{'shared.validation.requiredField'|translate}}
    </mat-error>
  </mat-form-field>

  <div fxLayout="row" fxLayoutGap="40px" *ngIf="isNeedSelectionType()">
    <mat-form-field fxFlex="50">
      <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.selectionType'|translate}}</mat-label>
      <mat-select [formControlName]="controlNames.DATA_SELECTION_TYPE" required>
        <mat-option *ngFor="let type of selectionTypesOptions" [value]="type" [disabled]="disabledSelectionType(type)">
          {{type | alarmSelectionType:alarmType}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="selectionTypeControl.hasError('required')">
        {{'shared.validation.requiredField'|translate}}
      </mat-error>
    </mat-form-field>

    <div fxFlex="50">
      <ng-container *ngIf="selectedType">
        <ng-container [ngSwitch]="selectedType">
          <mat-form-field *ngSwitchCase="selectionTypes.LABEL">
            <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.equipmentLabels'|translate}}</mat-label>
            <mat-select [formControlName]="controlNames.DATA_LABELS" multiple required>
              <mat-option *ngFor="let label of labelOptions" [value]="label">{{label}}</mat-option>
            </mat-select>
            <mat-error *ngIf="labelsControl.hasError('required')">
              {{'shared.validation.requiredField'|translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngSwitchCase="selectionTypes.EQUIPMENT_TYPE">
            <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.equipmentTypes'|translate}}</mat-label>
            <mat-select [formControlName]="controlNames.DATA_EQUIPMENT_TYPES" multiple required>
              <mat-optgroup *ngFor="let equipmentType of equipmentTypeOptions"
                            label="{{equipmentType.category1Name | translatableString}} {{equipmentType.total}}">
                <mat-option *ngFor="let subType of equipmentType.subTypes" [value]="subType.equipmentTypeId">
                  {{ subType.category2Name | translatableString }} ({{ subType.count }})
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error *ngIf="equipmentTypesControl.hasError('required')">
              {{'shared.validation.requiredField'|translate}}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <mat-slide-toggle class="switcher-email" color="primary" [formControlName]="controlNames.DATA_EMAIL_TOGGLE">
    {{'modules.notification.alarmRuleAddEdit.labels.emailToggle'|translate}}
  </mat-slide-toggle>

  <div *ngIf="emailToggleControl.value" fxLayout="row" fxLayoutGap="40px">
    <div fxFlex="50" fxLayout="row" fxLayoutGap="15px">
      <mat-form-field>
        <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.emailRecipients'|translate}}</mat-label>
        <mat-chip-grid #chipListEmail>
          <mat-chip-row *ngFor="let recipient of emailRecipients" [selectable]="false" [removable]="true"
                    (removed)="removeEmail(recipient)"
                    data-osp-test="chip-row-email-recipient">
            {{recipient.name}}
            <mat-icon matChipRemove data-osp-test="chip-button-email-remove">cancel</mat-icon>
          </mat-chip-row>
          <input #emailRecipientSearchRef
                 matInput
                 [formControl]="emailRecipientSearchControl"
                 [matChipInputFor]="chipListEmail"
                 autocomplete="block-browser-autofill"
                 [matAutocomplete]="autoEmail"
                 [matChipInputAddOnBlur]="false"
                 data-osp-test="input-email-recipients"/>
        </mat-chip-grid>
        <mat-autocomplete #autoEmail="matAutocomplete" (optionSelected)="addEmail($event)" panelWidth="auto">
          <mat-option *ngFor="let user of userSuggestions$|async" [value]="user">
            {{ user.firstName }} {{ user.name}} : {{ user.email}}
          </mat-option>
          <bh-mat-option-hint>
            {{ 'shared.selectOptionHint.onlySomeFirstShown' |translate :{ value: pageSizeRecipients } }}
          </bh-mat-option-hint>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutGap="15px">
      <mat-form-field>
        <mat-label>{{'modules.notification.alarmRuleAddEdit.labels.emailRoles'|translate}}</mat-label>
        <mat-chip-grid #chipListRole>
          <mat-chip-row *ngFor="let roleRecipient of roleRecipients"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeRole(roleRecipient)"
                    data-osp-test="chip-row-email-role">
            {{roleRecipient.name}}
            <mat-icon matChipRemove data-osp-test="chip-button-role-remove">cancel</mat-icon>
          </mat-chip-row>
          <input #roleRecipientSearchRef
                 matInput
                 [formControl]="roleRecipientSearchControl"
                 [matChipInputFor]="chipListRole"
                 autocomplete="block-browser-autofill"
                 [matAutocomplete]="autoRole"
                 [matChipInputAddOnBlur]="false"
                 data-osp-test="input-email-roles">
        </mat-chip-grid>
        <mat-autocomplete #autoRole="matAutocomplete" (optionSelected)="addRole($event)">
          <mat-option *ngFor="let role of roleSuggestions$|async" [value]="role">
            {{ role.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div>
