export enum ReportParameter {
  ALL_EQUIPMENTS = 'ALL_EQUIPMENTS',
  ALL_PROJECTS = 'ALL_PROJECTS',
  ORGANISATIONS = 'ORGANISATIONS',
  EQUIPMENT_TYPES = 'EQUIPMENT_TYPES',
  EQUIPMENT_LABELS = 'EQUIPMENT_LABELS',
  EQUIPMENT_ID_LIST = 'EQUIPMENT_ID_LIST',
  PROJECT_ID_LIST = 'PROJECT_ID_LIST',
  DATE_FROM = 'DATE_FROM',
  DATE_UNTIL = 'DATE_UNTIL'
}
