import { Injectable } from '@angular/core';
import { SearchFilterMediatorReceiver } from './search-filter-mediator-receiver.interface';
import { MediatorBaseService } from '../mediator-notifier-base.class';


@Injectable({
  providedIn: 'root'
})
export class SearchFilterMediatorService
  extends MediatorBaseService<SearchFilterMediatorReceiver> {

  public notifyEquipmentStatusesChanged(sender: any): void {
    this.processNotification(sender, receiver => receiver?.equipmentStatusesChanged?.());
  }
}
