import {Component, Input, OnInit} from '@angular/core';
import {DigitalMatterProfile} from '../../../assign-digital-matter-unit/digital-matter-profile';
import {
  DigitalMatterProfileDeploymentState
} from '../../../assign-digital-matter-unit/digital-matter-profile-deployment-state';
import {
  DigitalMatterProfileDeploymentCancelReason
} from '../../../assign-digital-matter-unit/digital-matter-profile-deployment-cancel-reason';

@Component({
  selector: 'bh-digital-matter-profile-status-badge',
  templateUrl: './digital-matter-profile-status-badge.component.html',
  styleUrls: ['./digital-matter-profile-status-badge.component.scss']
})
export class DigitalMatterProfileStatusBadgeComponent implements OnInit{

  @Input() profile: DigitalMatterProfile;
  @Input() status: DigitalMatterProfileDeploymentState;
  @Input() cancelReason: DigitalMatterProfileDeploymentCancelReason;

  @Input() selectedLanguage: string;

  ngOnInit(): void {
    if(!this.profile.translations.has(this.selectedLanguage)) {
      this.selectedLanguage = 'en-US';
    }
  }

  protected isCurrentlyUpdating(): boolean {
    return this.status !== DigitalMatterProfileDeploymentState.DEPLOYMENT_VERIFIED;
  }
}
