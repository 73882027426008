<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex>
        <form class="search-bar-input" fxLayout="row" [formGroup]="searchForm">
          <input fxFlex matInput #filter formControlName="terms">
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px" (click)="onSearchFormClick()">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>

      <button mat-button
        *ngIf="hasAuthority(authorities.MANUFACTURER_CREATE)"
        class="search-bar-button-right"
        (click)="navigateToAdd()"
        [matTooltip]="'modules.equipment.manufacturer.createNewManufacturer'|translate">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="data-table-wrapper" fxFlex>
        <mat-table #table [dataSource]="manufacturerDataSource" class="data-table" fxFlex>
          <ng-container cdkColumnDef="manufacturerName">
            <mat-header-cell *cdkHeaderCellDef> {{'general.name.s'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.manufacturerName}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="manufacturerAbbreviation">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.equipment.manufacturer.abbreviation'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.manufacturerAbbreviation}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="manufacturerMakerCode">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.equipment.manufacturer.makerCode'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.manufacturerMakerCode}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="manufacturerLogo">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.equipment.manufacturer.logo'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <mat-icon *ngIf="row.manufacturerLogoUrl">done</mat-icon>
              <mat-icon *ngIf="!row.manufacturerLogoUrl">clear</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="manufacturerEdit"
                        *ngIf="hasAuthority(authorities.MANUFACTURER_UPDATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="navigateToEdit(row.manufacturerId)"
                      [matTooltip]="'general.buttons.edit'|translate">
                <mat-icon>create</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="manufacturerDelete">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="manufacturerDelete(row.manufacturerId)"
                      [matTooltip]="'general.buttons.delete'|translate"
                      *ngIf="hasAuthority(authorities.MANUFACTURER_DELETE)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div class="list-view-main__left-body-paginator" fxFlex="60px">
        <mat-paginator fxFlex="grow"
                       [length]="manufacturerDataSource.pagination.totalElements"
                       [pageIndex]="manufacturerDataSource.pagination.index"
                       [pageSize]="manufacturerDataSource.pagination.size"
                       [pageSizeOptions]="[5, 25, 50, 100]"
                       (page)="onPaginateChange($event)">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
