import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import moment from 'moment';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { ViewEmployee } from 'app/modules/disposition/contract/view-employee.interface';
import { DispositionEmployee } from 'app/modules/disposition/contract/disposition-employee';
import { ViewEmployeeProjectAssignment } from 'app/modules/disposition/contract/view-employee-project-assignment.interface';
import { ViewUser } from '../../../../userrole/contract/view-user.interface';

export class EmployeeAssignToProjectUtils {
  public static formatEmployeeIdentifier(employee: ViewEmployee | DispositionEmployee): string {
    if (employee) {
      return  `${employee.employeeName} / ${employee.employeeFirstName}`;
    }
  }

  public static formatAddress(projectAddress: Address): string {
    if (projectAddress) {
      return '' +
          (projectAddress.postalCode ? projectAddress.postalCode + ' ' : '') +
          (projectAddress.city ? projectAddress.city : '');
    } else {
      return '-';
    }
  }

  public static formatProjectAssignee(projectAssignee: ViewUser): string {
    if (projectAssignee) {
      return '' +
          (projectAssignee.firstName ? projectAssignee.firstName + ' ' : '') +
          (projectAssignee.name ? projectAssignee.name : '');
    } else {
      return '-';
    }
  }

  public static formatProjectDateRange(project: DispositionProject): string {
    if (project && (project.projectStart || project.projectEnd)) {
      return '' +
          (project.projectStart ? moment(project.projectStart).format('DD.MM.YYYY') : 'Anfang offen') +
          ' - ' +
          (project.projectEnd ? moment(project.projectEnd).format('DD.MM.YYYY') : 'Ende offen');
    } else {
      return '-';
    }
  }

  public static formatProjectStartDate(project: DispositionProject): string {
    if (project && project.projectStart) {
      return moment(project.projectStart).format('DD.MM.YYYY');
    }
    if (project && !project.projectStart) {
      return 'Anfang offen';
    }
    return '';
  }

  public static formatProjectEndDate(project: DispositionProject): string {
    if (project && project.projectEnd) {
      return moment(project.projectEnd).format('DD.MM.YYYY');
    }
    if (project && !project.projectEnd) {
      return 'Ende offen';
    }
    return '';
  }

  static formatAssignmentDateRange(assignment: ViewEmployeeProjectAssignment): string {
    if (assignment && (assignment || assignment.assignmentEndDate)) {
      return '' +
          (assignment.assignmentStartDate ? moment(assignment.assignmentStartDate).format('DD.MM.YYYY') : 'Anfang offen') +
          ' - ' +
          (assignment.assignmentEndDate ? moment(assignment.assignmentEndDate).format('DD.MM.YYYY') : 'Ende offen');
    } else {
      return '-';
    }
  }

  static formatCostCenter(costCenter: string): string {
    return costCenter || '-';
  }
}
