import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { AlarmRuleControl } from 'app/modules/notifications/contract/alarm-rule-control.enum';
import { AlarmType } from '../../../../shared/enums/alarm-type.enum';
import { AlarmSettingsUtilsService } from '../../../alarm-settings-utils.service';
import { AlarmSettingsFieldData } from '../../../../contract/alarm-field-data.type';
import { UntypedFormGroup } from '@angular/forms';
import { AlarmSettingsBase } from '../alarm-settings-base.class';
import { TimefencingFieldAccessStatus as FieldStatus } from './../../../../contract/timefencing-field-access-status.enum';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { faClock, faRoute } from '@fortawesome/pro-regular-svg-icons';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';

@Component({
  selector: 'bh-timefencing-settings',
  templateUrl: './timefencing-settings.component.html',
  styleUrls: ['./timefencing-settings.component.scss']
})
@UntilDestroy()
export class TimefencingSettingsComponent extends AlarmSettingsBase implements OnInit, OnDestroy {
  @Input() set form(form: UntypedFormGroup) {
    this.settingsForm = form;
  }
  @Input() set fieldData(fieldData: AlarmSettingsFieldData) {
    this.alarmSettingsFieldData = fieldData;
  }

  private currentAlarmType: AlarmType;
  public fieldAccessabilityStatusOptions = FieldStatus;
  public fieldAccessabilityStatus = FieldStatus.OPERATING_HOURS;
  private filedAccessabilityMapper = {
    [AlarmRuleControl.SETTINGS_TIMEFENCING_HOURS]: [FieldStatus.BOTH, FieldStatus.OPERATING_HOURS],
    [AlarmRuleControl.SETTINGS_TIMEFENCING_DISTANCE]: [FieldStatus.BOTH, FieldStatus.DISTANCE],
  }
  public faClock: IconDefinition = faClock;
  public faRoute: IconDefinition = faRoute;
  public userTimezone = this.userConfigurationService.getUserTimezone();

  constructor(
    private alarmSettingsUtilsService: AlarmSettingsUtilsService,
    private userConfigurationService: UserConfigurationService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.alarmSettingsUtilsService.alarmTypeChanges.pipe(
      untilDestroyed(this)
    ).subscribe((type) => {
      this.currentAlarmType = type;
      this.checkControls();
    });
  }

  public ngOnDestroy(): void { }

  public inputAccessabilityChanged({ value }: { value: FieldStatus[] }): void {
    if (value.length > 0) {
      this.fieldAccessabilityStatus = value.length > 1 ? FieldStatus.BOTH : value[0];
    } else {
      this.fieldAccessabilityStatus = this.fieldAccessabilityStatus ===
       FieldStatus.DISTANCE ? FieldStatus.OPERATING_HOURS : FieldStatus.DISTANCE;
    }
    this.updateInputs();
  }

  public isFieldVisible(alarmRuleControl: AlarmRuleControl): boolean {
    return this.filedAccessabilityMapper[alarmRuleControl].some(status => status === this.fieldAccessabilityStatus);
  }
  public get isOnlyHoursFieldVisible(): boolean {
    return this.fieldAccessabilityStatus === FieldStatus.OPERATING_HOURS;
  }
  public get isOnlyDistanceFieldVisible(): boolean {
    return this.fieldAccessabilityStatus === FieldStatus.DISTANCE;
  }

  private checkControls(): void {
    if (this.hasControlsValue()) {
      this.checkInputs();
    }
    this.updateInputs();
  }

  private hasControlsValue(): boolean {
    return this.hourControl.value || this.distanceControl.value || this.weekDayControl.value;
  }

  private checkInputs(): void {
    if (this.hourControl.value && this.distanceControl.value) {
      this.fieldAccessabilityStatus = FieldStatus.BOTH;
    } else {
      this.fieldAccessabilityStatus = this.hourControl.value ? FieldStatus.OPERATING_HOURS : FieldStatus.DISTANCE;
    }
  }

  private updateInputs(): void {
    [this.controlNames.SETTINGS_TIMEFENCING_HOURS,
      this.controlNames.SETTINGS_TIMEFENCING_DISTANCE].forEach(controlName => this.setValidatorAndValue(controlName));
  }

  private setValidatorAndValue(controlName: AlarmRuleControl): void {
    const control = this.settingsForm.get(controlName);
    const isNeeded = this.isFieldVisible(controlName) && this.currentAlarmType === AlarmType.TIMEFENCING;
    control.setValidators(isNeeded ? this.alarmSettingsUtilsService.getValidators(controlName) : null);
    control.setValue(isNeeded ? control.value : null);
    control.updateValueAndValidity();
  }
}
