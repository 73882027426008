import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { EquipmentsDataSource } from '../equipments.datasource';
import { AssignEquipmentToOrganisationCommand } from '../../contract/assign-equipment-to-organisation-command';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrganisationInfo } from '../../contract/organisation-info.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-assign-to-organisation-dialog',
  templateUrl: './equipment-assign-to-organisation-dialog.component.html',
  styleUrls: ['./equipment-assign-to-organisation-dialog.component.scss']
})
export class EquipmentAssignToOrganisationDialogComponent implements OnInit {
  @Input() equipment: ViewEquipment;
  @Input() organisations: OrganisationInfo[];
  organisationName: UntypedFormControl;
  organisationFilter = new UntypedFormControl();
  filteredOrganisations: Observable<OrganisationInfo[]>;
  selectedOrganisation: OrganisationInfo;

  constructor(
    private equipmentStore: EquipmentsDataSource,
    private dialogRef: MatDialogRef<EquipmentAssignToOrganisationDialogComponent>
  ) { }

  ngOnInit() {
    this.organisationName = new UntypedFormControl('', [this.alreadyAssignedOrganisationValidator.bind(this)]);
    this.organisations.forEach(organisation => {
      if (organisation.organisationId === this.equipment.organisationId) {
        this.organisationName.setValue(organisation);
      }
    });
    this.filteredOrganisations = this.organisationFilter.valueChanges
      .pipe(
        startWith(''),
        map(name => name ? this.filterOrganisations(name) : this.organisations.slice()));
  }

  selectOrganisation(organisation: OrganisationInfo) {
    this.selectedOrganisation = organisation;
  }

  filterOrganisations(name: string): OrganisationInfo[] {
    return this.organisations.filter(org => org.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
  }

  save(): void {
    let cmd: AssignEquipmentToOrganisationCommand = new AssignEquipmentToOrganisationCommand();
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.organisationId = this.selectedOrganisation.organisationId;

    this.equipmentStore.assignEquipmentToOrganisation(cmd).subscribe(
      () => this.dialogRef.close(),
      () => this.dialogRef.close());
  }

  public alreadyAssignedOrganisationValidator(control: AbstractControl): { [key: string]: boolean } | null {
    return control.value['organisationId'] === this.equipment.organisationId ? {'alreadyAssignedError' : true} : null;
  }

  get saveDisabled(): boolean {
    return !this.selectedOrganisation || this.selectedOrganisation.organisationId === this.equipment?.organisationId;
  }

}
