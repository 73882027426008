import { AddEmployeeToProjectAssignmentCommand } from 'app/modules/disposition/contract/add-employee-project-assignment-command';
import { AssignmentResolveReason } from '../../enums/assignment-resolve-reason.enum';
import { switchMap, map, exhaustMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatesService } from '../dates.service';
import { DispositionProject } from '../../../modules/disposition/shared/disposition-project';
import { Injectable } from '@angular/core';
import { EMPTY, of, Observable, zip } from 'rxjs';
import { DispositionAssignment } from './disposition-assignment.class';
import { EmployeeDispositionDatasource } from 'app/modules/disposition/shared/employee-disposition.datasource';
import { EmployeeAssignToProjectDialogComponent } from 'app/modules/disposition/shared/employee-assign-to-project-dialog/landscape/employee-assign-to-project-dialog.component';
import { SearchEmployeeDisposition } from 'app/modules/disposition/contract/search-employee-disposition.interface';

@Injectable()
export class EmployeeDispositionAssignmentService extends
  DispositionAssignment<SearchEmployeeDisposition, AddEmployeeToProjectAssignmentCommand[]>  {
  constructor(private dialog: MatDialog,
              private employeeDispositionStore: EmployeeDispositionDatasource) {
    super();
  }

  protected checkForConflicts(
    assignment: SearchEmployeeDisposition,
    project: DispositionProject,
    startDate: Date,
    endDate: Date
  ): Observable<AddEmployeeToProjectAssignmentCommand[]> {
    const isTeam = assignment.teamName !== null;
    const hasDateRangeConflict = of(this.hasDateRangeConflict(project, startDate, endDate));
    const assignmentCollisionRequests = assignment.teamMembers.map(({ employeeId }) =>
      this.hasAssignmentCollision(employeeId, startDate, endDate));
    const hasAssignmentCollision = zip(...assignmentCollisionRequests)
      .pipe(map(results => results.every(Boolean)));

    return zip(hasDateRangeConflict, hasAssignmentCollision, of(isTeam))
    .pipe(
      map(([dateRangeConflict, assignmentCollision, team]) => [
        dateRangeConflict && AssignmentResolveReason.HAS_DATE_RANGE_CONFLICT,
        assignmentCollision && AssignmentResolveReason.HAS_ASSIGNMENT_COLLISION,
        team && AssignmentResolveReason.IS_EMPLOYEE_TEAM
      ]),
      map((resolveReasons) => resolveReasons.filter(Boolean)),
      exhaustMap(resolveReasons => resolveReasons && resolveReasons.length > 0
          ? this.openDispositionDialog(assignment, project, startDate, endDate, resolveReasons)
          : of(this.createAssignEquipmentToProjectCommands(assignment, project.projectId, startDate, endDate))
      )
    )
  }

  private hasAssignmentCollision(employeeId: string, startDate: Date, endDate: Date): Observable<boolean> {
    return this.employeeDispositionStore.getAssignmentCollisions(
      employeeId,
      DatesService.date(startDate, DatesService.zonedDateTimeFormat),
      DatesService.date(endDate, DatesService.zonedDateTimeFormat))
    .pipe(
      map(conflicts => Boolean(conflicts && conflicts.length > 0)));
  }

  private openDispositionDialog(
    employeeDisposition: SearchEmployeeDisposition,
    project: DispositionProject,
    startDate: Date,
    endDate: Date,
    resolveReasons: AssignmentResolveReason[]
  ): Observable<AddEmployeeToProjectAssignmentCommand[]> {
    const dialogRef = this.dialog.open(EmployeeAssignToProjectDialogComponent, <MatDialogConfig>{
      disableClose: true
    });
    dialogRef.componentInstance.setAssignmentData({
      project: project,
      assignment: employeeDisposition,
      startDate,
      endDate,
      resolveReasons
    });
    dialogRef.componentInstance.dialogRef = dialogRef;

    return dialogRef
      .afterClosed()
      .pipe(switchMap(commands => commands ? of(commands) : EMPTY));
  }

  private createAssignEquipmentToProjectCommands(
    assignment: SearchEmployeeDisposition,
    projectId: string,
    startDate: Date,
    endDate: Date
  ): AddEmployeeToProjectAssignmentCommand[] {
    return assignment.teamMembers.map(({ employeeId }) => new AddEmployeeToProjectAssignmentCommand(
      employeeId,
      projectId,
      DatesService.sameTimeZoneAtStartDateUTC(startDate),
      DatesService.sameTimeZoneAtEndDateUTC(endDate),
      '',
      false
    ));
  }
}
