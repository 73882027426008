import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { EquipmentTypesService } from '../../../../../shared/equipmenttypes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { OrganisationsService } from '../../../../../../organisation/shared/organisations.service';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { takeWhile } from 'rxjs/operators';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { BaseEquipmentViewGeneralComponent } from '../base/base-equipment-view-general.component';
import { MenuItem } from './detail-view-menu-item.interface';
import { EquipmentAmountInfo } from 'app/modules/disposition/contract/equipment-amount-info';
import { ScanEquipment } from 'app/modules/equipment/shared/scan-equipment';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';

@Component({
  selector: 'bh-mobile-equipment-view-general',
  templateUrl: './mobile-equipment-view-general.component.html',
  styleUrls: ['./mobile-equipment-view-general.component.scss']
})
export class MobileEquipmentViewGeneralComponent extends BaseEquipmentViewGeneralComponent implements OnInit, OnDestroy {

  public floatingButtonMenuItems: MenuItem[] = [];
  public equipmentAmountInfo: EquipmentAmountInfo;

  private transferAllowed: boolean = this.hasAnyAuthority([Authorities.ASSET_FROM_STOCK_TRANSFER,
        Authorities.ASSET_FROM_PROJECT_TRANSFER]) && this.hasModule(this.modules.DISPOSITION);

  constructor(protected equipmentsService: EquipmentsService,
              protected equipmentTypesService: EquipmentTypesService,
              public equipmentStore: EquipmentsDataSource,
              protected organisationsService: OrganisationsService,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory,
              public equipmentCheckerService: EquipmentCheckerService,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory, equipmentCheckerService, languageService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToEquipment();
    this.subscribeToEquipmentAmountInfo();
  }

  private subscribeToEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(takeWhile(() => this.componentActive))
    .subscribe(res => {
      if (res) {
        this.equipment = res;
        this.setGuaranteeEndDate();
        this.updateMenu(res);
        if (this.transferAllowed) {
          this.equipmentStore.getEquipmentAmountInfo(this.equipment.equipmentId);
        }
      }
    });
  }

  private subscribeToEquipmentAmountInfo(): void {
    this.equipmentStore.equipmentAmountInfo
    .pipe(takeWhile(() => this.componentActive))
    .subscribe(res => {
      if (res) {
        this.equipmentAmountInfo = res;
      }
    });
  }

  private updateMenu(equipment: ViewEquipment): void {
    this.floatingButtonMenuItems = [
      {
        label: this.translate('modules.equipment.base.transferToProject'),
        path: 'transfer-to-project',
        enabled: this.transferAllowed
      },
      {
        label: this.translate('modules.equipment.base.linkCode'),
        path: 'pair-scancode',
        enabled: this.hasAuthority(this.authorities.EQUIPMENT_UPDATE)
      },
      {
        label: this.translate('modules.equipment.base.logOperatingHours'),
        path: 'operating-hours',
        enabled: this.allowManualLifecycle(equipment.hasLiveOperatingHours,
                                           this.authorities.EQUIPMENT_MANAGE_OPERATING_HOURS)
      },
      {
        label: this.translate('modules.equipment.base.logMileage'),
        path: 'mileage',
        enabled: this.allowManualLifecycle(equipment.hasLiveMileage, this.authorities.EQUIPMENT_MANAGE_MILEAGE)
      },
      {
        label: this.translate('modules.equipment.equipmentDetailGeneral.damageTaken'),
        path: 'add-damage',
        enabled: this.hasAuthority(this.authorities.EQUIPMENT_UPDATE)
                 || this.hasAuthority(this.authorities.EQUIPMENT_MANAGE_DAMAGES)
                 || this.hasAuthority(this.authorities.EQUIPMENT_UPDATE)
      }
    ];
  }

  public navigateToPath(path: string): void {
    if (path === 'transfer-to-project') {
      const scanEquipment: ScanEquipment = {equipmentId: this.equipment.equipmentId,
        equipmentModel: this.equipment.equipmentModel,
        equipmentName: this.equipment.equipmentName,
        equipmentScanCode: this.equipment.equipmentScanCode,
        equipmentSerialNumber: this.equipment.equipmentSerialNumber,
        equipmentCustomerSerialNumber: this.equipment.equipmentCustomerSerialNumber,
        equipmentType: {category2: this.equipment.equipmentTypeCategory2},
        hasLiveOperatingHours: this.equipment.hasLiveOperatingHours,
        sourceStockId: this.equipmentAmountInfo.sourceStockId,
        currentLocationId: this.equipmentAmountInfo.currentLocationId,
        currentLocationType: this.equipmentAmountInfo.currentLocationType
     }
     this.equipmentStore.setScanEquipmentListForTransfer([scanEquipment]);
    }
    this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/${path}`]);
  }

  public isAnyMenuItemActive(): boolean {
    return this.floatingButtonMenuItems.some(menuItem => menuItem.enabled);
  }

}
