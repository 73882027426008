import { BasicWidget } from './basic-widget/basic-widget';
import { Type } from '@angular/core';
import { WidgetType } from './contract/widget-type.enum';

export abstract class WidgetDefinition {
  widgetType: WidgetType;
  type: Type<BasicWidget>;
  title: string;
  x: number;
  y: number;
  minItemCols: number;
  minItemRows: number;
  cols: number;
  rows: number;

  titleIcon?: {
    content: string;
    tooltip?: string;
  };
}
