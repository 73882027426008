import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControlStatus, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { asyncValidatorFactory } from 'app/shared/custom-validators/async-validator.factory';
import { FieldTouchedErrorMatcher } from 'app/shared/custom-validators/field-touched-error-matcher';
import { ScanCodeInUseValidator } from 'app/shared/custom-validators/scan-code-in-use.validator';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from 'app/shared/router-history';
import { ScanCodeService } from 'app/shared/services/scan-code.service';
import { environment } from 'environments/environment';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { concat, map, Observable, of, pairwise, switchMap, timer } from 'rxjs';
import { IScanCodeResult, IViewScanCodeData } from './scan-code.interface';

@UntilDestroy()
@Component({
  selector: 'bh-scan-code-edit',
  templateUrl: './scan-code-edit.component.html',
  styleUrls: ['./scan-code-edit.component.scss']
})
export class ScanCodeEditComponent extends GuardedNavigableInputComponent implements OnInit {

  public scanCodeData: IViewScanCodeData;
  public form: UntypedFormGroup;
  public errorMatcher: ErrorStateMatcher = new FieldTouchedErrorMatcher();
  public _scanCodeResult: IScanCodeResult;
  public formValid: Observable<boolean>;

  public get scanCodeControl(): AbstractControl {
    return this.form.get('scanCode');
  }

  public get scanCodeResult(): IScanCodeResult {
    return this._scanCodeResult;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) protected data: {scanCodeData: IViewScanCodeData },
    protected dialogRef: MatDialogRef<ScanCodeEditComponent>,
    protected authService: KeycloakService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerHistory: RouterHistory,
    protected scanCodeService: ScanCodeService
  ) {
    super(authService, router, route, routerHistory)
  }

  public ngOnInit(): void {
    this.scanCodeData = this.data.scanCodeData;
    this.createForm();
    this.initFormValidListener();
  }

  public setScanCodeResult(scanCode: string, status: string): void {
    this._scanCodeResult = {
      scanCode: scanCode,
      status: status,
    }
  }

  public saveScanCode(): void {
    this.setScanCodeResult(this.scanCodeControl.value, dialogResults.SAVE);
    this.dialogRef.close(this.scanCodeResult);
  }

  public deleteScanCode(): void {
    this.setScanCodeResult('', dialogResults.DELETE);
    this.dialogRef.close(this.scanCodeResult);
  }

  public closeDialog(): void {
    this.setScanCodeResult(this.scanCodeData.scanCode, dialogResults.ABORT);
    this.dialogRef.close(this.scanCodeResult);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      scanCode: [
        this.scanCodeData.scanCode,
        [Validators.required, TrimValidator.isEmpty],
        this.getValidator(),
      ]
    });
  }

  private initFormValidListener(): void {
    const valid: FormControlStatus = 'VALID';
    const pending: FormControlStatus = 'PENDING';
    this.formValid = concat(of(valid), this.form.statusChanges)
      .pipe(
        pairwise(),
        switchMap(([prev, curr]) => {
          return prev === valid && curr === pending
            ? timer(environment.DELAY_SHORT).pipe(map(() => this.form.valid))
            : of(curr === valid)
        }),
        untilDestroyed(this)
      );
  }

  private getValidator(): ValidatorFn {
    return asyncValidatorFactory((value) =>
      ScanCodeInUseValidator.isValid(value, this.scanCodeService, this.scanCodeData.scanCode));
  }

}
