import { LanguageService } from './../../../../../shared/services/language.service';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {EquipmentsDataSource} from '../../../shared/equipments.datasource';
import {EquipmentTypesService} from '../../../shared/equipmenttypes.service';
import {SpecificationService} from '../../../shared/specification.service';
import {ViewManufacturer} from '../../../contract/view-manufacturer.interface';
import {asyncValidatorFactory} from '../../../../../shared/custom-validators/async-validator.factory';
import {ManufacturerSerialNumberInUseValidator} from '../../../../../shared/custom-validators/manufacturer-serial-number-in-use.validator';
import { Month } from '../../../../../shared/contract/month';
import { EquipmentFieldStore } from '../../../shared/equipment-field.store';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SerialNumberInUseValidator } from '../../../../../shared/custom-validators/serial-number-in-use.validator';

export type FormControlStatus =
  | 'VALID'
  | 'INVALID'
  | 'PENDING'

export interface EquipmentGeneralData {
  status: FormControlStatus;
  serialNumber: string;
  costCenter: string;
  manufacturerId: string;
  model: string;
  productClass: string;
  constructionYear: string;
  constructionMonth: string;
  registrationNumber: string;
  deliveryDate: string;
  equipmentGuaranteeValue: number;
  equipmentGuaranteeUnit: moment.unitOfTime.DurationConstructor;
  licensePlate: string;
}

@Component({
  selector: 'bh-mobile-equipment-add-general-data',
  templateUrl: './mobile-equipment-add-general-data-component.html',
  styleUrls: ['./mobile-equipment-add-general-data-component.scss']
})
export class MobileEquipmentAddGeneralDataComponent implements OnInit {
  public generalInformationFormGroup: UntypedFormGroup;
  public manufacturerFilter: UntypedFormControl = new UntypedFormControl();
  public filteredCostCenters: Observable<string[]>;
  public allManufacturers: ViewManufacturer[] = [];
  public months: Month[];
  public years: string[];
  @Output() public OnChange: EventEmitter<EquipmentGeneralData> = new EventEmitter<EquipmentGeneralData>();
  public manufacturers: Observable<ViewManufacturer[]> = this.equipmentFieldStore.filteredManufacturers;

  private allCostCenters: string[];

  constructor(private formBuilder: UntypedFormBuilder,
              private equipmentsDataSource: EquipmentsDataSource,
              private equipmentTypeService: EquipmentTypesService,
              private specificationService: SpecificationService,
              private equipmentStore: EquipmentsDataSource,
              private equipmentFieldStore: EquipmentFieldStore,
              private languageService: LanguageService) {
  }

  public get serialNumber(): AbstractControl | null {
    return this.generalInformationFormGroup.get('serialNumber');
  }

  public get equipmentModel(): AbstractControl | null {
    return this.generalInformationFormGroup.get('model');
  }

  public get manufacturer(): AbstractControl | null {
    return this.generalInformationFormGroup.get('manufacturerId');
  }

  public get constructionMonth(): AbstractControl | null {
    return this.generalInformationFormGroup.get('constructionMonth');
  }

  public get constructionYear(): AbstractControl | null {
    return this.generalInformationFormGroup.get('constructionYear');
  }

  public filterCostCentersOnInput(): void {
    this.filteredCostCenters = this.generalInformationFormGroup.get('costCenter')
    .valueChanges
    .pipe(map(value => value ?
        this.allCostCenters.filter(costCenter =>
            costCenter.toLowerCase().indexOf(value.toLowerCase()) > -1) :
        this.allCostCenters.slice()));
  }

  public setModel(value: string): void {
    if (this.generalInformationFormGroup) {
      this.generalInformationFormGroup.get('model').setValue(value);
    }
  }

  public getModel(): string {
    if (this.generalInformationFormGroup) {
      return this.generalInformationFormGroup.get('model').value;
    }
  }

  public ngOnInit(): void {
    this.equipmentFieldStore.loadAllFields();
    this.buildForm();
    this.getAllCostCenters();
    this.setMonths();
    this.setYears();
    this.subscribeToFormChanges();
    this.setManufacturerSerialNumberInUseValidator();
  }

  public getManufacturers(equipmentTypeId: string): void {
    this.equipmentTypeService.getEquipmentType(equipmentTypeId).subscribe(res => {
      const specificationSource = this.getSpecificationSource(res.category1);
      this.specificationService.getManufacturers(specificationSource).subscribe(man => {
        this.allManufacturers = man;
        this.updateAutocompletes();
      });
    });
  }

  public getSpecificationSource(category1: string): string {
    // TODO return different web service names, depending on the value of category1
    return 'stub';
  }

  private filterManufacturersOnInput(): void {
    this.manufacturerFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => {
      console.log('test');
      this.equipmentFieldStore.filterManufacturers(filterTerm);
    });
  }

  public validateManufacturerSerialNumber() {
    let manufacturerId: string;
    if (this.manufacturer.value) {
      manufacturerId = this.allManufacturers.find(m => m.manufacturerName === this.manufacturer.value).manufacturerId;
    }
    this.equipmentStore.manufacturerSerialNumberInUse(this.serialNumber.value, manufacturerId)
    .subscribe(data => {
      this.serialNumber.setErrors(data ? {taken: true} : null);
    });
  }


  private setManufacturerSerialNumberInUseValidator(): void {
    this.serialNumber.setAsyncValidators(
      asyncValidatorFactory((value) => {
        let manufacturerId: string;
        if (!(this.manufacturer.value == null) && !(this.manufacturer.value === '')) {
          manufacturerId = this.allManufacturers
          .find(m => m.manufacturerName === this.manufacturer.value)
            .manufacturerId;
        }
        return ManufacturerSerialNumberInUseValidator.isValid(value, this.equipmentStore, manufacturerId);
      })
    );
  }

  private getAllCostCenters() {
    this.equipmentsDataSource.costCenters
    .subscribe(
        res => {
          if (res) {// TODO why do subscriptions first return with null before the actual value?
            this.allCostCenters = res;
            this.updateAutocompletes();
          }
        },
        error => {
          console.log('get cost centers by customer error: ', error);
        });
    this.equipmentsDataSource.getCostCenters();
  }

  private updateAutocompletes(): void {
    this.generalInformationFormGroup.get('costCenter').updateValueAndValidity();
  }

  private buildForm(): void {
    this.generalInformationFormGroup = this.formBuilder.group({
      serialNumber: [''],
      costCenter: [''],
      manufacturerId: [''],
      productClass: [''],
      model: ['', Validators.required],
      constructionYear: [''],
      constructionMonth: [''],
      registrationNumber: [''],
      deliveryDate: [''],
      licensePlate: ['']
    });
    this.generalInformationFormGroup.statusChanges.subscribe((status: FormControlStatus) => {
      this.emitChanges(status);
    });
  }

  private emitChanges(status: FormControlStatus): void {
    const values: EquipmentGeneralData = this.generalInformationFormGroup.getRawValue();
    const manufacturer = this.allManufacturers.find(m => m.manufacturerName === values.manufacturerId);
    if (manufacturer) {
      values.manufacturerId = manufacturer.manufacturerId;
    } else {
      values.manufacturerId = null;
    }
    values.status = status;
    this.OnChange.emit(values);
  }

  private subscribeToFormChanges(): void {
    this.filterManufacturersOnInput();
    this.filterCostCentersOnInput();
  }

  private setMonths(): void {
    moment.locale(this.languageService.getCurrentLocale());
    this.months = moment.months().map(month => {
      return {
        name: month,
        value: moment().month(month).format('MM')
      };
    });
    this.months.push({name: this.translate('general.noInformation'), value: '00'});
  }

  private setYears(): void {
    this.years = [];
    for (let i = (new Date().getFullYear() + 1); i >= 1970; i--) {
      this.years.push('' + i);
    }
    this.years.push(this.translate('general.noInformation'));
  }

  public constructionYearSelected(): void {
    if (!this.constructionMonth.value) {
      this.constructionMonth.patchValue('01');
    }
    this.constructionMonthSelected();
  }

  public constructionMonthSelected(): void {
    if (this.constructionMonth.value === '00' ||
        this.constructionYear.value === this.translate('general.noInformation')) {
      this.removeConstructionData();
    }
  }

  private removeConstructionData(): void {
    this.generalInformationFormGroup.patchValue({
      constructionMonth: null,
      constructionYear: null
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}

