<div fxFill class="transfer-cart-overview"
     fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">

  <bh-transfer-cart-header [lineOne]="sourceProject?.projectName"
                           [lineTwo]="'modules.disposition.bulkItemTransfer.clearProjectStockSelection'|translate"
                           [icon]="faBroom"
                           (closed)="closeCart()"></bh-transfer-cart-header>

  <div class="transfer-cart-items" fxFlex="100"
       fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <bh-transfer-items [transferItems]="transferItems | async"
                           [sourceLabel]="'modules.disposition.bulkItemTransfer.inProject'|translate"
                           [targetLabel]="'modules.disposition.bulkItemTransfer.toStock'|translate"
                           [showSelectAll]="true"
                           [invalid]="!selectedStock && (!consumedSelected || (consumedSelected && equipmentsSelected()))"
                           [showEquipmentMessage]="consumedSelected && equipmentsSelected()"
                           [searchable]="true"
                           [isLoadingItems]="isLoadingProjectAmounts"
                           (submitted)="confirmCart()"
                           (searchFilterApplied)="updateFilterControl($event)"
                           (amountUpdated)="updateAmount($event)"></bh-transfer-items>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <bh-bulk-item-transfer-stock-select #stockSelect
                                            [consumeEnabled]="true"
                                            (selectedStock)="selectStock($event)"
                                            (selectedConsumed)="selectConsumed()"></bh-bulk-item-transfer-stock-select>
      </mat-card-content>
    </mat-card>

  </div>

</div>
