<div class="m-view-lifecycle-details" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

  <bh-mobile-sub-header
      [title]="equipmentStore.currentTitle | async"
      [subtitle]="equipmentStore.currentSubtitle | async">
  </bh-mobile-sub-header>

  <div class="m-view-details-menu static-title" fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'general.reminder'|translate}}</span>
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
    </button>
  </div>

  <div *ngIf="!allowedToSee" class="m-view-details-unauthorized"
       fxFlexFill fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
    <mat-icon [attr.aria-label]="'general.buttons.close'|translate">no_encryption</mat-icon>
    <span>{{'modules.equipment.task.needPermissionsToViewInfo'|translate}}</span>
    <button mat-button (click)="navigateBack()">{{'general.buttons.back'|translate}}</button>
  </div>

  <div *ngIf="allowedToSee" fxFlexFill>

    <div *ngIf="completedReminder | async as completedReminderLifecycle; else spinner"
         class="m-view-lifecycle-details-content-wrapper">
      <div class="m-view-lifecycle-details-content">
        <div class="m-view-list-inputs">

          <mat-form-field floatLabel="always">
            <mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
            <input matInput readonly
                   [value]="completedReminderLifecycle.reminderName">
          </mat-form-field>

          <mat-form-field floatLabel="always">
            <mat-label>{{'modules.equipment.task.dueOn'|translate|uppercase}}</mat-label>
            <input matInput readonly
                   [value]="completedReminderLifecycle.reminderDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()">
          </mat-form-field>

          <mat-form-field floatLabel="always">
            <mat-label>{{'modules.equipment.task.finishedOn'|translate|uppercase}}</mat-label>
            <input matInput readonly
                   [value]="completedReminderLifecycle.completedDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()">
          </mat-form-field>

          <div class="m-view-lifecycle-submit-info" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <div>{{'general.labels.createdBy'|translate}}:</div>
              <div>{{completedReminderLifecycle.submittedBy}}</div>
            </div>
            <div>
              <div>{{'general.on'|translate|titlecase}}:</div>
              <div>{{completedReminderLifecycle.submitDate | date:'d. MMMM y':'':getCurrentLocale()}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <ng-template #spinner>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
      </div>
    </ng-template>

  </div>
</div>
