import { environment } from 'environments/environment';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { SearchProjectOrProjectGroup } from '../contract/search-project.interface';
import { ViewProject } from '../contract/view-project.interface';
import { CreateProjectCommand } from '../contract/create-project-command';
import { UpdateProjectCommand } from '../contract/update-project-command';
import { DeleteProjectCommand } from '../contract/delete-project-command';
import { List } from 'immutable';
import { Injectable } from '@angular/core';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { CreateGeofenceCommand } from '../contract/create-geofence-command';
import { DeleteGeofenceCommand } from '../contract/delete-geofence-command';
import { UploadProjectDocumentCommand } from '../contract/upload-project-document-command';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { DeleteProjectDocumentCommand } from '../contract/delete-project-document-command';
import { ViewProjectComment } from '../contract/view-project-comment.interface';
import { CreateProjectCommentCommand } from '../contract/create-project-comment-command';
import { UpdateProjectCommentCommand } from '../contract/update-project-comment-command';
import { DeleteProjectCommentCommand } from '../contract/delete-project-comment-command';
import { SetResetAlarmingGeofenceCommand } from '../contract/set-reset-alarming-geofence-command';
import { SearchProjectLocation } from '../contract/search-project-location.interface';
import { debounceTime, delay, map, skip, switchMap, tap } from 'rxjs/operators';
import { ViewGeofence } from '../contract/view-geofence.interface';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { DispositionProject } from './disposition-project';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { CustomerProjectLabel } from '../contract/customer-project-label.interface';
import { AddProjectLabelCommand } from '../contract/add-project-label-command';
import { DeleteProjectLabelCommand } from '../contract/delete-project-label-command';
import { UpdateProjectDocumentDescriptionCommand } from '../contract/update-project-document-description-command';
import { ViewProjectAmount } from '../contract/view-project-amount.interface';
import { AmountsService } from './amounts.service';
import { ITransferItemWithValidityStatus, TransferItem } from './transfer/model/transfer-item';
import { OfflineDataStore } from '../../../shared/services/offline-data.store';
import { DocuwareDocument } from '../../../shared/contract/docuware-document.interface';
import { DocuwareService } from '../../equipment/shared/docuware.service';
import { TopfactDocument } from '../../../shared/contract/topfact-document.interface';
import { TopfactService } from '../../equipment/shared/topfact.service';
import { ProjectEmployeeService } from './services/project-employee.service';
import { AddProjectResponsibleEmployeeCommand } from '../contract/add-project-responsible-employee-command';
import { ViewProjectEmployeeAssignment } from '../contract/view-project-employee-assignment.interface';
import { RemoveProjectResponsibleEmployeeCommand } from '../contract/remove-project-responsible-employee-command';
import { ChangeProjectResponsibleEmployeeAcceptanceUserFlagCommand } from '../contract/change-project-responsible-employee-acceptance-user-flag-command';
import { CreateProjectGroupCommand } from '../contract/create-project-group-command';
import { CreateSubProjectCommand } from '../contract/create-sub-project-command';
import { RenameSubProjectCommand } from '../contract/rename-sub-project';
import { DeleteProjectGroupCommand } from '../contract/delete-project-group-command';
import { Router } from '@angular/router';
import { ProjectFiltersService } from './services/project-filters.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { ProjectStatus } from './enums/project-status.enum';
import { ProjectsService } from './project.service';
import { SearchProjectParameter } from './interfaces/SearchProjectParameter.interface';
import { GetProjectLocationParameter } from './interfaces/GetProjectLocationParameter.interface';
import { GetProjectLocationSearchParameter } from './interfaces/GetProjectLocationSearchParameter.interface';
import { ProjectColumnService } from './services/project-column.service';
import { OrganisationInfoService } from './services/organisation-info.service';
import { OrganisationInfo } from './services/organisation-info.interface';
import { filterByTerm } from 'app/shared/collection-utils';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { UsersService } from 'app/modules/userrole/shared/users.service';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { EMPTY_MAP_LOCATIONS_PAYLOAD, MapLocationsPayload } from 'app/shared/modules/map/interfaces/map-locations-payload.interface';
import { UpdateProjectDocumentNameCommand } from '../contract/update-project-document-name-command';
import { ChangeGeofenceStatusCommand } from '../contract/change-geofence-status-command';
import { GenericUpdateGeofenceCommand } from 'app/shared/modules/map/components/geofence-sidebar-component/generic-update-geofence-command';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment';
import { InventoryRequestParams } from './interfaces/inventoryRequestParams.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataSource {

  private _projects: BehaviorSubject<List<SearchProjectOrProjectGroup>> = new BehaviorSubject(List([]));
  private _currentProject: BehaviorSubject<ViewProject> = new BehaviorSubject(null);
  private _projectLocations = new BehaviorSubject<MapLocationsPayload<SearchProjectLocation>>(EMPTY_MAP_LOCATIONS_PAYLOAD);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _amounts: BehaviorSubject<ViewProjectAmount[]> = new BehaviorSubject([]);
  private _transferItems: BehaviorSubject<TransferItem[]> = new BehaviorSubject<TransferItem[]>([]);
  private _geofences: BehaviorSubject<ViewGeofence[]> = new BehaviorSubject([]);
  private _documents: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private _comments: BehaviorSubject<ViewProjectComment[]> = new BehaviorSubject([]);
  private _dispositionProjects: BehaviorSubject<DispositionProject[]> = new BehaviorSubject([]);
  private _dispositionProjectTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _employeeAssignments: BehaviorSubject<ViewProjectEmployeeAssignment[]> = new BehaviorSubject(null);
  private _currentDispositionProject: Subject<DispositionProject> = new Subject();
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  private _projectLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _docuwareDocuments: BehaviorSubject<DocuwareDocument[]> = new BehaviorSubject([]);
  private _hasProjectDocuwareConnector: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _docuwareRequestPending: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _docuwareServiceAvailable: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _topfactDocuments: BehaviorSubject<TopfactDocument[]> = new BehaviorSubject([]);
  private _hasProjectTopfactConnector: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _topfactRequestPending: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _topfactServiceAvailable: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _hasAssignmentOrTransferRequestWorkflow: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasTypeOfUseWorkflow: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasTransferHistory: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _hasTransferRequestWorkflow: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _projectDeleted: BehaviorSubject<string> = new BehaviorSubject(null);
  private _totalCount = new BehaviorSubject<number>(0);
  private _organisationFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private _allOrganisations: BehaviorSubject<OrganisationInfo[]> = new BehaviorSubject<OrganisationInfo[]>([]);
  private _transferItemToggleEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _inventoryEquipments = new BehaviorSubject<ViewProjectAmount[]>([]);
  private _inventoryBulkItems = new BehaviorSubject<ViewProjectAmount[]>([]);

  private labelRequests: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly projects: Observable<List<SearchProjectOrProjectGroup>> = this._projects.asObservable();
  public readonly currentProject: Observable<ViewProject> = this._currentProject.asObservable();
  public readonly projectLocations = this._projectLocations.asObservable();
  public readonly geofences: Observable<ViewGeofence[]> = this._geofences.asObservable();
  public readonly amounts: Observable<ViewProjectAmount[]> = this._amounts.asObservable();
  public readonly transferItems: Observable<TransferItem[]> = this._transferItems.asObservable();
  public readonly documents: Observable<DocumentLink[]> = this._documents.asObservable();
  public readonly comments: Observable<ViewProjectComment[]> = this._comments.asObservable();
  public readonly currentDispositionProject: Observable<DispositionProject> = this._currentDispositionProject.asObservable();
  public readonly employeeAssignments: Observable<ViewProjectEmployeeAssignment[]> = this._employeeAssignments.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly projectLabels: Observable<string[]> = this._projectLabels.asObservable();
  public readonly docuwareDocuments: Observable<DocuwareDocument[]> = this._docuwareDocuments.asObservable();
  public readonly hasProjectDocuwareConnector: Observable<boolean> = this._hasProjectDocuwareConnector.asObservable();
  public readonly docuwareRequestPending: Observable<boolean> = this._docuwareRequestPending.asObservable();
  public readonly docuwareServiceAvailable: Observable<boolean> = this._docuwareServiceAvailable.asObservable();
  public readonly topfactDocuments: Observable<TopfactDocument[]> = this._topfactDocuments.asObservable();
  public readonly hasProjectTopfactConnector: Observable<boolean> = this._hasProjectTopfactConnector.asObservable();
  public readonly topfactRequestPending: Observable<boolean> = this._topfactRequestPending.asObservable();
  public readonly topfactServiceAvailable: Observable<boolean> = this._topfactServiceAvailable.asObservable();
  public readonly hasAssignmentOrTransferRequestWorkflow: Observable<boolean> = this._hasAssignmentOrTransferRequestWorkflow.asObservable();
  public readonly hasTypeOfUseWorkflow: Observable<boolean> = this._hasTypeOfUseWorkflow.asObservable();
  public readonly hasTransferHistory: Observable<boolean> = this._hasTransferHistory.asObservable();
  public readonly hasTransferRequestWorkflow: Observable<boolean> = this._hasTransferRequestWorkflow.asObservable();
  public readonly projectDeleted: Observable<string> = this._projectDeleted.asObservable();
  public readonly onFiltersUpdated = this.projectFiltersService.onFiltersUpdated;
  public readonly totalCount = this._totalCount.asObservable();
  public readonly filteredCustomerLabels: Observable<string[]> = combineLatest(this._labels, this._projectLabels)
    .pipe(map(([availableLabels, selectedLabels]) =>
      availableLabels.map(label => label.name)
        .filter(label => !selectedLabels.includes(label))
    ));
  public readonly filteredDispositionProjects: Observable<DispositionProject[]> =
    combineLatest(this._dispositionProjects, this._dispositionProjectTerm)
      .pipe(map(([projects, term]) => projects.filter(project => project.projectName.toLowerCase().includes(term.toLowerCase()))));
  public readonly filteredOrganisations: Observable<OrganisationInfo[]> = combineLatest([
    this._allOrganisations,
    this._organisationFilter]
  ).pipe(
    map(([organisations, filterTerm]) => filterByTerm(organisations, filterTerm, 'name')));
  public readonly transferItemToggleEvent: Observable<boolean> = this._transferItemToggleEvent.asObservable();
  public readonly inventoryEquipments = this._inventoryEquipments.asObservable();
  public readonly inventoryBulkItems = this._inventoryBulkItems.asObservable();

  public hideFinished = true;

  private _pagination: PaginationInfo;
  private _searchTerms = '';
  private _inventorySearchTerms = '';
  private _fullScreen = false;
  private _currentViewTab = 'general';
  private _isLastProject = false;
  private _searchLabels: string[] = [];
  private _currentlyLoadingProjectId: string;
  private _ignoreLocationSearchTerms = false;
  public transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];
  public readonly filters = this.projectFiltersService.filters;

  private _inventoryEquipmentsPageIndex = new BehaviorSubject(0);
  private _inventoryEquipmentsPageSize = new BehaviorSubject(25);
  private _inventoryEquipmentsLength = new BehaviorSubject(0);

  private _inventoryBulkItemsPageIndex = new BehaviorSubject(0);
  private _inventoryBulkItemsPageSize = new BehaviorSubject(25);
  private _inventoryBulkItemsLength = new BehaviorSubject(0);

  private get currentProjectId(): string {
    return this._currentProject.getValue().projectId;
  }

  constructor(private projectService: ProjectsService,
              private projectEmployeeService: ProjectEmployeeService,
              private projectColumnService: ProjectColumnService,
              private onlineStatusService: OnlineStatusService,
              private amountsService: AmountsService,
              private organisationsService: OrganisationInfoService,
              private offlineDataStore: OfflineDataStore,
              private docuwareService: DocuwareService,
              private topfactService: TopfactService,
              private router: Router,
              private projectFiltersService: ProjectFiltersService,
              private userConfigurationService: UserConfigurationService,
              private usersService: UsersService
  ) {
    this.paginatorPageSizeListener();

    this.filterOrganisations('');
    this._labels.subscribe(customerLabels =>
      this.searchLabels = this.searchLabels.filter(label =>
        customerLabels.map(l => l.name).includes(label)));

    this.labelRequests
      .pipe(
        skip(1),
        debounceTime(600))
      .subscribe(projectId => {
        this.getProjectLabels(projectId);
        this.getCustomerLabelsAndUpdate();
      });
  }

  public project(): ViewProject {
    return this._currentProject.getValue();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.projectFiltersService.updateFilterParams(commands);
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  public initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0
    } as PaginationInfo;
  }

  private paginatorPageSizeListener(): void {
    this.projectColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  get fullScreen(): boolean {
    return this._fullScreen;
  }

  get currentViewTab(): string {
    return this._currentViewTab;
  }

  set currentViewTab(currentViewTab: string) {
    this._currentViewTab = currentViewTab;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchTerms(searchTerms: string) {
    this._searchTerms = searchTerms;
  }

  public get ignoreLocationSearchTerms(): boolean {
    return this._ignoreLocationSearchTerms;
  }

  public set ignoreLocationSearchTerms(ignoreLocationSearchTerms: boolean) {
    this._ignoreLocationSearchTerms = ignoreLocationSearchTerms;
  }

  get isLastProject(): boolean {
    return this._isLastProject;
  }

  set isLastProject(lastProject: boolean) {
    this._isLastProject = lastProject;
  }

  get currentlyLoadingProjectId() {
    return this._currentlyLoadingProjectId;
  }

  set currentlyLoadingProjectId(projectId: string) {
    this._currentlyLoadingProjectId = projectId;
  }

  public toggleFullScreen(): void {
    this._fullScreen = !this._fullScreen
  }

  public setCurrentProject(projectId: string): void {
    this.currentlyLoadingProjectId = projectId;
    if (projectId) {
      this.projectService.getProject(projectId)
        .subscribe(currentProject => this._currentProject.next(currentProject));
    } else {
      this._currentProject.next(null);
    }
  }

  public setCurrentDispositionProject(projectId: string): void {
    this.loadProject(projectId).subscribe((project: DispositionProject) => this._currentDispositionProject.next(project));
  }

  private loadOfflineProject(projectId: string): Observable<DispositionProject> {
    return this.dispositionProjects
      .pipe(
        map(projects => {
          const matchingProject = projects.find(project => project.projectId === projectId);
          if (matchingProject === undefined) {
            throw new Error(`Could not find project with id '${projectId}'`);
          }
          return matchingProject;
        }));
  }

  public setProjectLocations(locations: SearchProjectLocation[], centerMap: boolean = false): void {
    this._projectLocations.next({ locations, centerMap });
  }

  public updateMap(allowCompletedProjects: boolean, centerMap: boolean = false): void {
    if (this._searchTerms && !this.ignoreLocationSearchTerms) {
      this.getProjectLocationsSearch(centerMap);
    } else {
      this.getProjectLocations(allowCompletedProjects, centerMap);
    }
  }

  public clearStoredLocations(): void {
    this._projectLocations.next(EMPTY_MAP_LOCATIONS_PAYLOAD);
  }

  public getMapConfiguration(): MapConfiguration {
    return this.userConfigurationService.getMapConfigurationProject();
  }

  public saveMapConfiguration(mapConfig: MapConfiguration): void {
    this.userConfigurationService.saveMapConfigurationProject(mapConfig);
    this.usersService.saveMapConfigurationProject(mapConfig).subscribe();
  }

  public getProjectAmounts(projectId: string, requestDate?: string, searchTerm?: string): void {
    let params = {
      requestDate: requestDate,
      searchTerm: searchTerm,
    };
    this.amountsService
      .getProjectAmounts(projectId, params)
      .subscribe((res: ViewProjectAmount[]) => this._amounts.next(res));
  }

  public loadOrganisations(): void {
    this.organisationsService.getOrganisations()
      .subscribe(organisations => this._allOrganisations.next(organisations));
  }

  public filterOrganisations(value: string): void {
    this._organisationFilter.next(value);
  }

  public addProject(cmd: CreateProjectCommand, afterCreate: (id: string) => void): void {
    this.projectService
      .addProject(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe((res: string) => {
        this.currentViewTab = 'general';
        this.setCurrentProject(res);
        this.updateSearchResults();
        this.projectFiltersService.updateFilters();
        afterCreate(res);
      });
  }

  public updateProject(cmd: UpdateProjectCommand, afterUpdate: () => void): void {
    this.projectService
      .updateProject(cmd)
      .subscribe((res: string) => {
        setTimeout(() => {
          this.setCurrentProject(res);
          this.projectFiltersService.updateFilters();
          afterUpdate();
        }, environment.DELAY_SHORT);
      });
  }

  public createProjectGroup(cmd: CreateProjectGroupCommand): void {
    this.projectService
      .createProjectGroup(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe({
        next: (res: string) => {
          this.currentViewTab = 'general';
          this.router.navigate([`sites/projects/list/${res}/${this.currentViewTab}`]);
          this.setCurrentProject(res);
          this.updateSearchResults();
        },
        error: () => this.updateSearchResults(),
      });
  }

  public createSubProject(cmd: CreateSubProjectCommand): void {
    this.projectService
      .createSubProject(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe({
        next: (res: string) => {
          this.currentViewTab = 'general';
          this.router.navigate([`sites/projects/list/${res}/${this.currentViewTab}`]);
          this.setCurrentProject(res);
          this.projectFiltersService.updateFilters();
          this.updateSearchResults();
        },
        error: () => this.updateSearchResults(),
      });
  }

  public renameSubProject(cmd: RenameSubProjectCommand): void {
    this.projectService
      .renameSubProject(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe({
        next: (res) => {
          this.currentViewTab = 'general';
          this.setCurrentProject(res);
          this.updateSearchResults();
        },
        error: () => this.updateSearchResults(),
      });
  }

  public deleteProject(cmd: DeleteProjectCommand): void {
    this.projectService
      .deleteProject(cmd)
      .pipe(delay(environment.DELAY_LONG))
      .subscribe((projectId: string) => this.afterProjectDelete(projectId));
  }

  public deleteSubProject(cmd: DeleteProjectCommand): void {
    this.projectService
      .deleteSubProject(cmd)
      .pipe(delay(environment.DELAY_LONG))
      .subscribe((projectId: string) => this.afterProjectDelete(projectId));
  }

  public deleteProjectGroup(cmd: DeleteProjectGroupCommand): void {
    this.projectService
      .deleteProjectGroup(cmd)
      .pipe(delay(environment.DELAY_LONG))
      .subscribe((projectId: string) => this.afterProjectDelete(projectId));
  }

  private afterProjectDelete(projectId: string): void {
    this._projectDeleted.next(projectId);
    this.projectFiltersService.updateFilters();
    this.updateSearchResults(true);
  }

  public finishProject(cmd: DeleteProjectCommand): void {
    this.projectService
      .finishProject(cmd)
      .subscribe(() => {
        setTimeout(() => {
          this.setCurrentProject(cmd.projectId);
          this.updateListing();
          this.projectFiltersService.updateFilters();
        }, environment.DELAY_LONG);
      });
  }

  public getGeofences(): void {
    this.projectService
      .getGeofences(this.project().projectId)
      .subscribe((res: ViewGeofence[]) => this._geofences.next(res));
  }

  public createGeofence(cmd: CreateGeofenceCommand): void {
    this.projectService
      .createGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public updateGeofence(cmd: GenericUpdateGeofenceCommand): void {
    this.projectService
      .updateGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public deleteGeofence(cmd: DeleteGeofenceCommand): void {
    this.projectService
      .deleteGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public changeGeofenceStatus(cmd: ChangeGeofenceStatusCommand): void {
    this.projectService
    .changeGeofenceStatus(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.getGeofences());
  }

  public setAlarmGeofence(cmd: SetResetAlarmingGeofenceCommand): Observable<string> {
    return this.projectService
      .setAlarmGeofence(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getGeofences())
      )
  }

  public resetAlarmGeofence(cmd: SetResetAlarmingGeofenceCommand): Observable<string> {
    return this.projectService
      .resetAlarmGeofence(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getGeofences())
      )
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.projectColumnService.selectPageSize(size);
    }
    this.pagination.size = size;
    this.pagination.index = index;
    this.updateSearchResults();
  }

  public updateSearchResults(navigateToFirst: boolean = false): void {
    this.projectService
      .searchProjects(this.createParameterForSearchProject())
      .subscribe((res: PagedResponse<SearchProjectOrProjectGroup>) => {
        this.updateStoreData(res);
        if (navigateToFirst) {
          this.router.navigate(['sites/projects/list']);
          this.setCurrentProject(res.content?.[0]?.projectId);
        }
      });
  }

  private updateStoreData(res: PagedResponse<SearchProjectOrProjectGroup>): void {
    this._projects.next(List(res.content));
    this._totalCount.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
    this.isLastProject = res.last;
  }

  // Documents Data Source
  public addDocument(cmd: UploadProjectDocumentCommand): Observable<string> {
    return this.projectService
      .addDocument(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getDocuments(cmd.projectId);
        }, environment.DELAY_LONG);
        return of(res);
      }));
  }

  public getDocuments(projectId: string): Observable<DocumentLink[]> {
    this.projectService
      .getDocuments(projectId)
      .subscribe((res: DocumentLink[]) => this._documents.next(res));

    return this.documents;
  }

  public deleteDocument(cmd: DeleteProjectDocumentCommand): Observable<string> {
    return this.projectService
      .deleteDocument(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getDocuments(cmd.projectId);
        }, environment.DELAY_LONG);
        return res;
      }));
  }

  // Comments Data Source

  public getComments(projectId: string): Observable<ViewProjectComment[]> {
    this.projectService
      .getComments(projectId)
      .subscribe((res: ViewProjectComment[]) => this._comments.next(res));

    return this._comments;
  }

  public addComment(cmd: CreateProjectCommentCommand): Observable<string> {
    return this.projectService
      .addComment(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getComments(cmd.projectId);
        }, environment.DELAY_SHORT);
        return res;
      }));
  }

  public updateComment(cmd: UpdateProjectCommentCommand): Observable<string> {
    return this.projectService
      .updateComment(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getComments(cmd.projectId);
        }, environment.DELAY_SHORT);
        return res;
      }));
  }

  public deleteComment(cmd: DeleteProjectCommentCommand): Observable<string> {
    return this.projectService
      .deleteComment(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getComments(cmd.projectId);
        }, environment.DELAY_SHORT);
        return res;
      }));
  }

  public hasAssignmentCollisions(projectId: string, startDate: string, endDate: string): Observable<boolean> {
    return this.projectService.hasAssignmentCollisions(projectId, startDate, endDate);
  }

  get dispositionProjects(): Observable<DispositionProject[]> {
    return this._dispositionProjects.asObservable();
  }

  public loadDispositionProjects(): void {
    if (this.onlineStatusService.onlineStatus) {
      this.projectService.getDispositionProjects()
        .subscribe(projects => this._dispositionProjects.next(projects));
    } else {
      this.offlineDataStore.offlineDispositionProjects
        .subscribe(projects => this._dispositionProjects.next(projects))
    }
  }

  get searchLabels() {
    return this._searchLabels;
  }

  set searchLabels(searchLabels: string[]) {
    this._searchLabels = searchLabels;
  }

  public getCustomerLabels(filterByActiveProjects?: boolean): Observable<CustomerProjectLabel[]> {
    this.projectService
      .getCustomerLabels(filterByActiveProjects ? {hideFinished: this.hideFinished} : false)
      .subscribe((labels: CustomerProjectLabel[]) => this._labels.next(labels));
    return this.labels;
  }

  public addProjectLabel(projectId: string, label: string): void {
    const newLabels = [...this._projectLabels.value];
    newLabels.push(label);
    this._projectLabels.next(newLabels);

    this.projectService
      .addLabel(new AddProjectLabelCommand(projectId, label))
      .pipe(
        delay(environment.DELAY_SHORTEST))
      .subscribe({
        next: () => {
          this.getCustomerLabelsAndUpdate();
          this.projectFiltersService.updateFilters();
        },
        error: error => {
          this.refreshProjectLabels(projectId);
          console.log('error on adding label', error);
        }
      });
  }

  public deleteProjectLabel(projectId: string, label: string): void {
    if (this._projectLabels.value.includes(label)) {
      const newLabels = [...this._projectLabels.value];
      newLabels.splice(newLabels.indexOf(label), 1);
      this._projectLabels.next(newLabels);

      this.projectService
        .deleteLabel(new DeleteProjectLabelCommand(projectId, label))
        .pipe(
          delay(environment.DELAY_SHORTEST))
        .subscribe({
          next: () => {
            this.refreshProjectLabels(projectId);
            this.projectFiltersService.updateFilters();
          },
          error: error => {
            this.refreshProjectLabels(projectId);
            console.log('error on deleting label', error);
          }
        });
    }
  }

  public getProjectLabels(projectId: string): void {
    this.projectService.getLabels(projectId, this._currentProject?.value?.projectStructureType)
      .subscribe(labels => this._projectLabels.next(labels));
  }

  public refreshProjectLabels(projectId: string): void {
    this.labelRequests.next(projectId);
  }

  private getCustomerLabelsAndUpdate(): void {
    this.getCustomerLabels();
    this.updateListing(this.pagination.index, this.pagination.size);
  }

  public filterDispositionProjects(term: string): void {
    this._dispositionProjectTerm.next(term);
  }

  public updateDocumentName(cmd: UpdateProjectDocumentNameCommand): void {
    this.projectService.updateDocumentName(cmd).pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getDocuments(cmd.projectId)))
    .subscribe();
  }

  public updateDocumentDescription(cmd: UpdateProjectDocumentDescriptionCommand): void {
    this.projectService.updateDocumentDescription(cmd).pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getDocuments(cmd.projectId)))
    .subscribe();
  }

  public noProjectSelected(): boolean {
    return this._currentProject.value === null;
  }

  public addResponsibleEmployee(command: AddProjectResponsibleEmployeeCommand): Observable<any> {
    return this.projectEmployeeService.addProjectEmployeeAssignments(command).pipe(
      delay(environment.DELAY_SHORT),
      switchMap((res: string) => {
        this.updateListing();
        this.setCurrentProject(res);
        return of(res);
      }));
  }

  removeEmployeeAssignment(cmd: RemoveProjectResponsibleEmployeeCommand): void {
    this.projectEmployeeService
      .removeProjectEmployeeAssignments(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe((res: string) => {
        this.updateListing();
        this.setCurrentProject(res);
      });
  }

  public changeProjectResponsibleEmployeeAcceptanceUser(
    command: ChangeProjectResponsibleEmployeeAcceptanceUserFlagCommand): Observable<any> {
    return this.projectEmployeeService.changeProjectRespnsibleEmployeeAcceptanceUser(command).pipe(
      delay(environment.DELAY_SHORT),
      switchMap((res: string) => {
        this.updateListing();
        this.setCurrentProject(res);
        return of(res);
      }));
  }

  public clearEmployeeAssignments(): void {
    this._employeeAssignments.next([]);
  }

  getDocuwareDocuments(projectId: string): Observable<DocuwareDocument[]> {
    this._docuwareRequestPending.next(true);
    let params = {
      projectId: projectId
    };
    this.docuwareService.getDocuments(params).subscribe(res => {
      this._docuwareServiceAvailable.next(true);
      this._docuwareDocuments.next(res);
      this._docuwareRequestPending.next(false);
    }, () => {
      this._docuwareServiceAvailable.next(false);
      this._docuwareDocuments.next([]);
      this._docuwareRequestPending.next(false);
    });
    return merge(this.docuwareDocuments);
  }

  getTopfactDocuments(projectId: string): void {
    this._topfactRequestPending.next(true);
    let params = {
      projectId: projectId
    };
    this.topfactService
      .getDocuments(params)
      .subscribe(res => {
        this._topfactServiceAvailable.next(true);
        this._topfactDocuments.next(res);
        this._topfactRequestPending.next(false);
      }, () => {
        this._topfactServiceAvailable.next(false);
        this._topfactDocuments.next([]);
        this._topfactRequestPending.next(false);
      });
  }

  public getTopfactDocument(docId: string, fileId: string): Observable<Blob> {
    return this.topfactService.getDocument(docId, fileId);
  }

  updateProjectDocuwareConnectorExists(): void {
    let params = {
      connectorType: 'PROJECT_CONNECTOR'
    };
    this.docuwareService.connectorExists(params).subscribe(res => {
      this._hasProjectDocuwareConnector.next(res);
    });
  }

  updateAssignmentOrTransferRequestWorkflow(): void {
    this.projectService.hasAssignmentOrTransferRequestWorkflow()
      .subscribe(res => this._hasAssignmentOrTransferRequestWorkflow.next(res));
  }

  updateTypeOfUseWorkflow(): void {
    this.projectService.hasTypeOfUseWorkflow()
      .subscribe(res => this._hasTypeOfUseWorkflow.next(res));
  }

  updateTransferHistory(projectId: string): void {
    this.projectService.hasTransferHistory(projectId).subscribe({
      next: (res) => {
        this._hasTransferHistory.next(res);
      }
    })
  }

  updateTransferRequestsWorkflow(): void {
    this.projectService.hasTransferRequestWorkflow()
      .subscribe(res => this._hasTransferRequestWorkflow.next(res));
  }

  public async setTransferRequestsWorkflow(): Promise<void> {
    await this.projectService.hasTransferRequestWorkflow().toPromise().then(value => {
      this._hasTransferRequestWorkflow.next(value);
    })
  }

  public getTransferRequestsWorkflow(): boolean {
    return this._hasTransferRequestWorkflow.value;
  }

  public updateTransferItemToggleEvent(): void {
    this._transferItemToggleEvent.next(true);
  }

  updateProjectTopfactConnectorExists(): void {
    this.topfactService.connectorExists()
      .subscribe(res => {
        this._hasProjectTopfactConnector.next(res);
      });
  }

  getDocuwareDocument(projectId: string, documentId: string) {
    return this.docuwareService.getDocument(projectId, documentId);
  }

  getEmployeeAssignments(projectId: string): Observable<any> {
    this.projectEmployeeService
      .getEmployeeAssignments(projectId, this._currentProject?.value?.projectStructureType)
      .subscribe(res => {
        this._employeeAssignments.next(res);
      }, error => {
        console.log('error get employee assignments: ', error);
      });
    return merge(this._employeeAssignments);
  }

  private loadProject(projectId: string): Observable<DispositionProject> {
    if (this.onlineStatusService.onlineStatus) {
      return this.projectService.getDispositionProject(projectId);
    }

    return this.loadOfflineProject(projectId);
  }

  private getProjectLocations(allowCompletedProjects: boolean, centerMap: boolean = false): void {
    const params = allowCompletedProjects
      ? this.createParameterForGetProjectLocation()
      : {statuses: [ProjectStatus.ACTIVE], labels: []};

    this.projectService.getProjectLocations(params)
      .subscribe(locations => this._projectLocations.next({ locations, centerMap }));
  }

  private getProjectLocationsSearch(centerMap: boolean = false): void {
    this.projectService
    .getProjectLocationsSearch(this.createParameterForGetProjectLocationSearch())
    .subscribe((locations: SearchProjectLocation[]) => this._projectLocations.next({ locations, centerMap }));
  }

  private createParameterForSearchProject(): SearchProjectParameter {
    return {
      page: this.pagination.index,
      size: this.pagination.size,
      labels: this.projectFiltersService.getProjectLabelFilter(),
      statuses: this.projectFiltersService.getProjectStatusFilter(),
      terms: this.searchTerms
    }
  }

  private createParameterForGetProjectLocation(): GetProjectLocationParameter {
    return {
      labels: this.projectFiltersService.getProjectLabelFilter(),
      statuses: this.projectFiltersService.getProjectStatusFilter()
    }
  }

  private createParameterForGetProjectLocationSearch(): GetProjectLocationSearchParameter {
    return {
      terms: this.searchTerms,
      labels: this.projectFiltersService.getProjectLabelFilter(),
      statuses: this.projectFiltersService.getProjectStatusFilter()
    }
  }

  public updateInventorySearchTerms(searchTerms: string): void {
    this._inventorySearchTerms = searchTerms;
    this.resetInventoryPageIndexes();
    this.updateInventoryEquipments();
    this.updateInventoryBulkItems();
  }

  public getInventorySearchTerms(): string {
    return this._inventorySearchTerms;
  }

  public inventoryEquipmentsPaginateEvent(event: PageEvent): void {
    if (this.isPageParamChanged(event.pageIndex, this._inventoryEquipmentsPageIndex.getValue()) ||
        this.isPageParamChanged(event.pageSize, this._inventoryEquipmentsPageSize.getValue())) {
      this._inventoryEquipmentsPageIndex.next(event.pageIndex);
      this._inventoryEquipmentsPageSize.next(event.pageSize);
      this.updateInventoryEquipments();
    }
  }

  public inventoryBulkItemsPaginateEvent(event: PageEvent): void {
    if (this.isPageParamChanged(event.pageIndex, this._inventoryBulkItemsPageIndex.getValue()) ||
        this.isPageParamChanged(event.pageSize, this._inventoryBulkItemsPageSize.getValue())) {
      this._inventoryBulkItemsPageIndex.next(event.pageIndex);
      this._inventoryBulkItemsPageSize.next(event.pageSize);
      this.updateInventoryBulkItems();
    }
  }

  private isPageParamChanged(newParam: number, currentParam: number): boolean {
    return newParam !== currentParam;
  }

  public updateInventoryEquipments(): void {
    this.amountsService.getInventoryEquipments(this.currentProjectId, this.getInventoryEquipmentsRequestParams())
      .subscribe(res => {
        this._inventoryEquipments.next(res.content);
        this._inventoryEquipmentsLength.next(res.totalElements);
      })
  }

  private getInventoryEquipmentsRequestParams(): InventoryRequestParams {
    return {
      page: this._inventoryEquipmentsPageIndex.getValue(),
      size: this._inventoryEquipmentsPageSize.getValue(),
      searchTerm: this._inventorySearchTerms,
      requestDate: new Date(moment.now()).toISOString(),
    }
  }

  public updateInventoryBulkItems(): void {
    this.amountsService.getInventoryBulkItems(this.currentProjectId, this.getInventoryBulkItemsRequestParams())
      .subscribe(res => {
        this._inventoryBulkItems.next(res.content);
        this._inventoryBulkItemsLength.next(res.totalElements);
      })
  }

  private getInventoryBulkItemsRequestParams(): InventoryRequestParams {
    return {
      page: this._inventoryBulkItemsPageIndex.getValue(),
      size: this._inventoryBulkItemsPageSize.getValue(),
      searchTerm: this._inventorySearchTerms,
      requestDate: new Date(moment.now()).toISOString(),
    }
  }

  public resetInventoryPageIndexes(): void {
    this._inventoryEquipmentsPageIndex.next(0);
    this._inventoryBulkItemsPageIndex.next(0);
  }

  public getInventoryEquipmentsPageIndex(): Observable<number> {
    return this._inventoryEquipmentsPageIndex.asObservable();
  }

  public getInventoryEquipmentsPageSize(): Observable<number> {
    return this._inventoryEquipmentsPageSize.asObservable();
  }

  public getInventoryEquipmentsLength(): Observable<number> {
    return this._inventoryEquipmentsLength.asObservable();
  }

  public getInventoryBulkItemsPageIndex(): Observable<number> {
    return this._inventoryBulkItemsPageIndex.asObservable();
  }

  public getInventoryBulkItemsPageSize(): Observable<number> {
    return this._inventoryBulkItemsPageSize.asObservable();
  }

  public getInventoryBulkItemsLength(): Observable<number> {
    return this._inventoryBulkItemsLength.asObservable();
  }
}
