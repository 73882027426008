import { Component, OnInit } from '@angular/core';
import { ViewEmployee } from '../../contract/view-employee.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faUser, faCalculatorAlt, faBuilding, faTag, faAt, faPhone, faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { CreateEmployeeCommand } from '../../contract/create-employee.command';
import { MatDialogRef } from '@angular/material/dialog';
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { UpdateEmployeeCommand } from '../../contract/update-employee.command';
import { Address } from '../../../organisation/contract/address.interface';
import { Router } from '@angular/router';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { EmployeeStaffNumberInUseValidator } from '../../../../shared/custom-validators/employee-staff-number-in-use.validator';
import { EmployeeManagementDatasource } from '../../shared/employee-management.datasource';
import { OrganisationInfoService } from '../../shared/services/organisation-info.service';
import { OrganisationInfo } from '../../shared/services/organisation-info.interface';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { RetryGeocoder } from 'app/shared/geolocation/retry-geocoder';
import { AddressLocationValidator } from 'app/shared/custom-validators/address-location.validator';
import { MapLoaderService } from '../../../../shared/modules/map/services/map-loader.service';

@Component({
  selector: 'bh-employee-add-edit',
  templateUrl: './employee-add-edit.component.html',
  styleUrls: ['./employee-add-edit.component.scss']
})
export class EmployeeAddEditComponent implements OnInit {

  // Icons
  public readonly faUser: IconDefinition = faUser;
  public readonly faTag: IconDefinition = faTag;
  public readonly faBuilding: IconDefinition = faBuilding;
  public readonly faCalculatorAlt: IconDefinition = faCalculatorAlt;
  public readonly faAt: IconDefinition = faAt;
  public readonly faPhone: IconDefinition = faPhone;
  public readonly faMapMarkerAlt: IconDefinition = faMapMarkerAlt;

  public readonly fieldLimit = FieldLimit;

  public employeeForm: UntypedFormGroup;
  public employee: ViewEmployee;
  public organisations: Observable<OrganisationInfo[]>;
  public filterControl: UntypedFormControl;
  public saveInProgress = false;

  private readonly CORE_DATA = 'coreData';
  private readonly PRIVATE_DATA = 'privateData';
  private readonly EMPLOYEE_ADDRESS_CONTROL = 'employeeAddress';
  private readonly EMPLOYEE_FIRST_NAME_CONTROL = 'employeeFirstName';
  private readonly EMPLOYEE_NAME_CONTROL = 'employeeName';
  private readonly EMPLOYEE_OFFICE_PHONE_NUMBER_CONTROL = 'employeeOfficePhoneNumber';
  private readonly EMPLOYEE_OFFICE_MOBILE_NUMBER_CONTROL = 'employeeOfficeMobileNumber';
  private readonly EMPLOYEE_STAFF_NUMBER_CONTROL = 'employeeStaffNumber';
  private readonly ORGANISATION_CONTROL = 'organisation';
  private readonly EMPLOYEE_EMAIL_CONTROL = 'employeeEmail';
  private readonly STREET_CONTROL = 'street';
  private readonly STREET_NUMBER_CONTROL = 'streetNumber';
  private readonly POSTAL_CODE_CONTROL = 'postalCode';
  private readonly CITY_CONTROL = 'city';
  private readonly EMPLOYEE_HOME_PHONE_NUMBER_CONTROL = 'employeeHomePhoneNumber';
  private readonly EMPLOYEE_HOME_MOBILE_NUMBER_CONTROL = 'employeeHomeMobileNumber';


  constructor(private dialogRef: MatDialogRef<EmployeeAddEditComponent>,
              private formBuilder: UntypedFormBuilder,
              private organisationService: OrganisationInfoService,
              private mapLoaderService: MapLoaderService,
              private employeeManagementStore: EmployeeManagementDatasource,
              protected router: Router) { }

  ngOnInit() {
    this.buildForm();
    this.filterControl = new UntypedFormControl();
    this.organisations = combineLatest(
      this.organisationService.getOrganisations(),
      this.filterControl.valueChanges.pipe(startWith('')))
    .pipe(
      map(([organisations, filterTerm]) => organisations
      .filter(org => org.name.toLowerCase().includes(filterTerm.toLowerCase()))));
    if (this.employee) {
      this.fillForm();
    }
    this.setEmployeeStaffNumberInUseValidator();
  }

  public get employeeFirstName(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_FIRST_NAME_CONTROL);
  }

  public get employeeName(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_NAME_CONTROL);
  }

  public get employeeOfficePhoneNumber(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_OFFICE_PHONE_NUMBER_CONTROL);
  }

  public get employeeOfficeMobileNumber(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_OFFICE_MOBILE_NUMBER_CONTROL);
  }

  public get employeeStaffNumber(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_STAFF_NUMBER_CONTROL);
  }

  public get organisation(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.ORGANISATION_CONTROL);
  }

  public get employeeAddress(): AbstractControl {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL);
  }

  public get employeeEmail(): AbstractControl | null {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_EMAIL_CONTROL);
  }

  public get street(): AbstractControl | null {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).get(this.STREET_CONTROL);
  }

  public get streetNumber(): AbstractControl | null {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).get(this.STREET_NUMBER_CONTROL);
  }

  public get postalCode(): AbstractControl | null {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).get(this.POSTAL_CODE_CONTROL);
  }

  public get city(): AbstractControl | null {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).get(this.CITY_CONTROL);
  }

  public get employeeHomePhoneNumber(): AbstractControl | null {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_HOME_PHONE_NUMBER_CONTROL);
  }

  public get employeeHomeMobileNumber(): AbstractControl | null {
    return this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_HOME_MOBILE_NUMBER_CONTROL);
  }

  public updateStreet(street: string): void {
    this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).patchValue({street: street});
  }

  public updateStreetNumber(streetNumber: string): void {
    this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).patchValue({streetNumber: streetNumber});
  }

  public updatePostalCode(postalCode: string): void {
    this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).patchValue({postalCode: postalCode});
  }

  public updateCity(city: string): void {
    this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).patchValue({city: city});
  }

  protected buildForm(): void {
    this.employeeForm = this.formBuilder.group({
      coreData: this.formBuilder.group({
        employeeStaffNumber: ['', [
          Validators.required,
          Validators.maxLength(FieldLimit.MEDIUM_IDENTIFIER),
          TrimValidator.hasWhitespaces]],
        employeeFirstName: ['', Validators.maxLength(FieldLimit.NAME)],
        employeeName: ['', [Validators.required,
          Validators.maxLength(FieldLimit.NAME)]],
        employeeEmail: ['', [emailValidator(), Validators.maxLength(FieldLimit.EMAIL)]],
        employeeOfficePhoneNumber: ['', Validators.maxLength(FieldLimit.PHONE_NUMBER)],
        employeeOfficeMobileNumber: ['', Validators.maxLength(FieldLimit.PHONE_NUMBER)],
        organisation: ['', Validators.required]
      }),
      privateData: this.formBuilder.group({
        employeeAddress: this.formBuilder.group({
          street: ['', Validators.maxLength(FieldLimit.STREET)],
          streetNumber: ['', Validators.maxLength(FieldLimit.STREET_NUMBER)],
          postalCode: ['', Validators.maxLength(FieldLimit.POSTAL_CODE)],
          city: ['', Validators.maxLength(FieldLimit.CITY)]
        }),
        employeeHomePhoneNumber: ['', Validators.maxLength(FieldLimit.PHONE_NUMBER)],
        employeeHomeMobileNumber: ['', Validators.maxLength(FieldLimit.PHONE_NUMBER)]
      })
    });

    this.setValidators();
  }

  private setValidators(): void {
    this.mapLoaderService.isLoaded.subscribe(() => {
      this.employeeAddress.setAsyncValidators(AddressLocationValidator(new RetryGeocoder(new google.maps.Geocoder()), false));
    });
  }

  private fillForm(): void {
    this.employeeForm.get(this.CORE_DATA).patchValue({
      employeeStaffNumber: this.employee.employeeStaffNumber,
      employeeFirstName: this.employee.employeeFirstName,
      employeeName: this.employee.employeeName,
      employeeEmail: this.employee.employeeEmail,
      employeeOfficePhoneNumber: this.employee.employeeOfficePhoneNumber,
      employeeOfficeMobileNumber: this.employee.employeeOfficeMobileNumber,
      organisation: this.employee.organisationId
    });
    this.employeeForm.get(this.PRIVATE_DATA).patchValue({
      employeeHomePhoneNumber: this.employee.employeeHomePhoneNumber,
      employeeHomeMobileNumber: this.employee.employeeHomeMobileNumber,
    });
    if (this.employee.employeeAddress) {
      this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).patchValue({
        street: this.employee.employeeAddress.street,
        streetNumber: this.employee.employeeAddress.streetNumber,
        postalCode: this.employee.employeeAddress.postalCode,
        city: this.employee.employeeAddress.city
      })
    }
  }

  public save(): void {
    if (this.isValid()) {
      const formValue = this.employeeForm.getRawValue();
      if (!this.employee) {
          this.create(formValue);
      } else {
          this.update(formValue);
      }
    }
  }

  public isValid(): boolean {
    return this.employeeForm.valid;
  }

  private update(formValue: any): void {
    const command: UpdateEmployeeCommand = {
      employeeId: this.employee.employeeId,
      employeeStaffNumber: formValue.coreData.employeeStaffNumber,
      employeeFirstName: formValue.coreData.employeeFirstName,
      employeeName: formValue.coreData.employeeName,
      employeeAddress: this.addressHasValue() ? formValue.privateData.employeeAddress : null,
      organisationId: formValue.coreData.organisation,
      employeeEmail: this.emailHasValue() ? formValue.coreData.employeeEmail : null,
      employeeHomePhoneNumber: formValue.privateData.employeeHomePhoneNumber,
      employeeHomeMobileNumber: formValue.privateData.employeeHomeMobileNumber,
      employeeOfficePhoneNumber: formValue.coreData.employeeOfficePhoneNumber,
      employeeOfficeMobileNumber: formValue.coreData.employeeOfficeMobileNumber
    };
    this.saveInProgress = true;
    this.employeeManagementStore.updateEmployee(command)
      .pipe(finalize(() => this.saveInProgress = false))
      .subscribe(() => this.dialogRef.close());
  }

  private create(formValue: any): void {
    const command: CreateEmployeeCommand = {
      employeeStaffNumber: formValue.coreData.employeeStaffNumber,
      employeeFirstName: formValue.coreData.employeeFirstName,
      employeeName: formValue.coreData.employeeName,
      employeeAddress: this.addressHasValue() ? formValue.privateData.employeeAddress : null,
      organisationId: formValue.coreData.organisation,
      employeeEmail: this.emailHasValue() ? formValue.coreData.employeeEmail : null,
      employeeHomePhoneNumber: formValue.privateData.employeeHomePhoneNumber,
      employeeHomeMobileNumber: formValue.privateData.employeeHomeMobileNumber,
      employeeOfficePhoneNumber: formValue.coreData.employeeOfficePhoneNumber,
      employeeOfficeMobileNumber: formValue.coreData.employeeOfficeMobileNumber
    };
    this.saveInProgress = true;
    this.employeeManagementStore.createEmployee(command)
      .pipe(finalize(() => this.saveInProgress = false))
      .subscribe(() => this.dialogRef.close());
  }

  private addressHasValue(): boolean {
    const address: Address = this.employeeForm.get(this.PRIVATE_DATA).get(this.EMPLOYEE_ADDRESS_CONTROL).value;
    return address.postalCode !== '' || address.city !== '';
  }

  private setEmployeeStaffNumberInUseValidator(): void {
    if (this.employee) {
      this.employeeStaffNumber.setAsyncValidators(
        asyncValidatorFactory((value) => EmployeeStaffNumberInUseValidator
        .isValid(value, this.employeeManagementStore, this.employee.employeeStaffNumber))
      );
    } else {
      this.employeeStaffNumber.setAsyncValidators(
        asyncValidatorFactory((value) => EmployeeStaffNumberInUseValidator.isValid(value, this.employeeManagementStore))
      );
    }
  }

  private emailHasValue(): boolean {
    return this.employeeForm.get(this.CORE_DATA).get(this.EMPLOYEE_EMAIL_CONTROL).value !== '';
  }
}
