import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewAlarmRule } from '../../contract/view-alarm-rule.interface';
import { NotificationService } from './notification.service';
import { CreateAlarmRuleCommand } from '../../contract/create-alarm-rule-command';
import { UpdateAlarmRuleCommand } from '../../contract/update-alarm-rule-command';
import { Alarm } from 'app/modules/notifications/shared/interfaces/alarm.interface';
import { AcknowledgeAlarmCommand } from '../../contract/acknowledge-alarm-command';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { delay } from 'rxjs/operators';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { DeleteAlarmRuleCommand } from '../../contract/delete-alarm-rule-command';
import { AlarmType } from '../enums/alarm-type.enum';

enum EntityType {
  TRANSPORT = 'TRANSPORT',
  EQUIPMENT = 'EQUIPMENT'
}

type AlarmTypesMapping = { [key in AlarmType]: EntityType };
type MethodsMapping = {
  [key in EntityType]: {
    create: (command: CreateAlarmRuleCommand) => Observable<string>;
    update: (command: UpdateAlarmRuleCommand) => Observable<string>;
    delete: (command: DeleteAlarmRuleCommand) => Observable<string>;
  }
}

@Injectable()
export class NotificationDataSource extends DataSource<ViewAlarmRule> {
  private readonly alarmTypesMapping: AlarmTypesMapping = {
    [AlarmType.OPERATING_HOURS]: EntityType.EQUIPMENT,
    [AlarmType.OPERATING_HOURS_CONTRACT]: EntityType.EQUIPMENT,
    [AlarmType.TANK_FILL_LEVEL]: EntityType.EQUIPMENT,
    [AlarmType.FAULTS]: EntityType.EQUIPMENT,
    [AlarmType.TOTAL_COSTS]: EntityType.EQUIPMENT,
    [AlarmType.DAMAGE_COSTS]: EntityType.EQUIPMENT,
    [AlarmType.REPAIR_COSTS]: EntityType.EQUIPMENT,
    [AlarmType.GUARANTEE]: EntityType.EQUIPMENT,
    [AlarmType.FULL_SERVICE_CONTRACT_END]: EntityType.EQUIPMENT,
    [AlarmType.LEASING_SERVICE_CONTRACT_END]: EntityType.EQUIPMENT,
    [AlarmType.GEOFENCE_VIOLATION]: EntityType.EQUIPMENT,
    [AlarmType.GLOBAL_GEOFENCE_VIOLATION]: EntityType.EQUIPMENT,
    [AlarmType.TIMEFENCING]: EntityType.EQUIPMENT,
    [AlarmType.TRANSPORT_TASK_CREATION]: EntityType.TRANSPORT,
    [AlarmType.TRANSPORT_TASK_RETURNED]: EntityType.TRANSPORT,
    [AlarmType.NEW_DAMAGE_ADDED]: EntityType.EQUIPMENT,
    [AlarmType.INACTIVE_TELEMATICS_UNIT]: EntityType.EQUIPMENT,
    [AlarmType.BATTERY_VOLTAGE]: EntityType.EQUIPMENT,
    [AlarmType.DEF_TANK_LEVEL]: EntityType.EQUIPMENT,
    [AlarmType.TELEMATICS_UNIT_BATTERY_STATUS]: EntityType.EQUIPMENT,
  };
  private readonly methodsMapping: MethodsMapping = {
    [EntityType.EQUIPMENT]: {
      create: (command: CreateAlarmRuleCommand) => this._notificationService.saveEquipmentAlarmRule(command),
      update: (command: UpdateAlarmRuleCommand) => this._notificationService.updateEquipmentAlarmRule(command),
      delete: (command: DeleteAlarmRuleCommand) => this._notificationService.removeEquipmentAlarmRule(command),
    },
    [EntityType.TRANSPORT]: {
      create: (command: CreateAlarmRuleCommand) => this._notificationService.saveTransportAlarmRule(command),
      update: (command: UpdateAlarmRuleCommand) => this._notificationService.updateTransportAlarmRule(command),
      delete: (command: DeleteAlarmRuleCommand) => this._notificationService.removeTransportAlarmRule(command),
    }
  }

  private _alarmRules: BehaviorSubject<ViewAlarmRule[]> = new BehaviorSubject<ViewAlarmRule[]>([]);
  private _labels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _equipmentTypes: BehaviorSubject<EquipmentTypeFilterView[]> = new BehaviorSubject([]);
  private _currentActive: BehaviorSubject<Alarm[]> = new BehaviorSubject<Alarm[]>(null);
  private _currentActiveTotalCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly labels: Observable<string[]> = this._labels.asObservable();
  public readonly equipmentTypes: Observable<EquipmentTypeFilterView[]> = this._equipmentTypes.asObservable();
  public readonly currentActive: Observable<Alarm[]> = this._currentActive.asObservable();
  public readonly currentActiveTotalCount: Observable<number> = this._currentActiveTotalCount.asObservable();

  constructor(private _notificationService: NotificationService) {
    super();
  }

  public connect(): Observable<ViewAlarmRule[]> {
    this.getAlarmRules();
    return this._alarmRules;
  }

  public disconnect(): void {
  }

  public getDistinctLabels(): Observable<string[]> {
    this._notificationService
      .getDistinctLabels()
      .subscribe((res: string[]) => this._labels.next(res));

    return this.labels;
  }

  public getDistinctEquipmentTypes(): Observable<EquipmentTypeFilterView[]> {
    this._notificationService
    .getDistinctEquipmentTypes()
    .subscribe((res: EquipmentTypeFilterView[]) => this._equipmentTypes.next(res));

    return this.equipmentTypes;
  }

  public saveAlarmRule(cmd: CreateAlarmRuleCommand): void {
    const method = this.methodsMapping[this.getEntityType(cmd.alarmType)]?.create;
    if (method) {
      method(cmd)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe(() => this.getAlarmRules());
    }
  }

  public updateAlarmRule(cmd: UpdateAlarmRuleCommand): void {
    const method = this.methodsMapping[this.getEntityType(cmd.alarmType)]?.update;
    if (method) {
      method(cmd)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe(() => this.getAlarmRules());
    }
  }

  public removeAlarmRule(cmd: DeleteAlarmRuleCommand): void {
    const method = this.methodsMapping[this.getEntityType(cmd.alarmType)]?.delete;
    if (method) {
      method(cmd)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe(() => this.getAlarmRules());
    }
  }

  public getCurrentActive(): Observable<Alarm[]> {
    this._notificationService
      .getCurrentActive()
      .subscribe((res: PagedResponse<Alarm>) => {
        this._currentActive.next(res.content);
        this._currentActiveTotalCount.next(res.totalElements);
      });
    return this._currentActive;
  }

  public acknowledgeAlarm(cmd: AcknowledgeAlarmCommand): Observable<string> {
    return this._notificationService.acknowledgeAlarm(cmd);
  }

  private getAlarmRules(): void {
    this._notificationService
      .getAlarmRules()
      .subscribe((res: PagedResponse<ViewAlarmRule>) => this._alarmRules.next(res.content));
  }

  private getEntityType(type: AlarmType): EntityType {
    return this.alarmTypesMapping[type];
  }
}
