import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhIconModule } from '../bh-icon/bh-icon.module';
import { SecretTextComponent } from './component/bh-secret-text.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    SecretTextComponent,
  ],
  imports: [
    CommonModule,
    BhIconModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ClipboardModule,
  ],
  exports: [
    SecretTextComponent,
  ],
})
export class BhSecretTextModule {}
