import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeycloakService } from '../keycloak';
import { PrivilegedRole } from '../../shared/enums/privileged-roles.enum';

@Injectable()
export class CanActivateChildRole implements CanActivateChild {
  constructor(private authService: KeycloakService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!childRoute.data.hasOwnProperty('roles')) {
      throw Error('Route has no roles property');
    } else {
      let roles: Array<PrivilegedRole> = childRoute.data['roles'];
      if (roles instanceof Array) {
        let found = false;
        roles.forEach(role => {
          if (this.authService.isInRole(role)) {
            found = true;
          }
        });
        return found;
      } else {
        throw Error('roles property is not an array');
      }
    }
  }
}
