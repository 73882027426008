import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faCalculatorAlt, faRecycle, faTag, faThList, } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { BulkItemFieldStore } from '../../service/bulk-item-field.store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BulkItemsService } from '../../../shared/bulk-items.service';
import { LocalUserStorageService } from '../../../../../shared/services/local-user-storage.service';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../shared/router-history';
import { ScanCodeStore } from '../../../../../shared/services/scan-code.store';
import { AssetType } from '../../../../../shared/contract/asset-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldLimit } from '../../../../../shared/enums/fieldLimit.enum';

declare const EB: any;

@UntilDestroy()
@Component({
  selector: 'bh-mobile-bulk-item-add-overall-data',
  templateUrl: './mobile-bulk-item-add-overall-data.component.html',
  styleUrls: ['./mobile-bulk-item-add-overall-data.component.scss']
})
export class MobileBulkItemAddOverallDataComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {


  @Input() form: UntypedFormGroup;
  @Input() showConsumable: boolean;
  @ViewChild('qrCode', { static: true }) private qrCodeInput: ElementRef<HTMLInputElement>;

  public readonly faTag: IconDefinition = faTag;
  public readonly faThList: IconDefinition = faThList;
  public readonly faCalculatorAlt: IconDefinition = faCalculatorAlt;
  public readonly faRecycle: IconDefinition = faRecycle;

  public readonly fieldLimit = FieldLimit;

  public scannerActive = false;
  public isEnterprise = false;
  public torchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public torchAvailable = false;
  public searchingBulkItemByQrCode = false;
  public alreadyPairedAsset: string;
  public alreadyPairedAssetType: AssetType;

  public categories: Observable<string[]>;

  private qrCodeResult: string;

  constructor(private matSnackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,
              private bulkItemsService: BulkItemsService,
              private bulkItemFieldStore: BulkItemFieldStore,
              private scanCodeStore: ScanCodeStore,
              protected keycloakService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(keycloakService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    if (LocalUserStorageService.getUserValue('isEnterprise', this.getUserUserId()) === 'true') {
      this.isEnterprise = true;
    }
    this.categories = this.bulkItemFieldStore.filteredCategories;
    this.bulkItemFieldStore.loadAllFields();
    this.form.get('bulkItemCategory')
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.bulkItemFieldStore.filterCategories(filterTerm));

    this.scanCodeStore.scanCodeInUse
      .pipe(untilDestroyed(this))
      .subscribe(scanEntry => {
        this.alreadyPairedAssetType = scanEntry.assetType;
        if (scanEntry && scanEntry.assetType === AssetType.BULK_ITEM) {
          this.bulkItemsService.getByScanCode(scanEntry.scanCode)
            .subscribe(bulkItem => this.alreadyPairedAsset = bulkItem.bulkItemName);
          this.getQrCodeField().setValue('');
        } else {
          this.alreadyPairedAsset = null;
        }
        this.searchingBulkItemByQrCode = false;
      });
    this.scanCodeStore.scanCodeInUseError
      .pipe(untilDestroyed(this))
      .subscribe((error: number) => {
        if (error && error === 404) {
          this.scannerActive = false;
          this.getQrCodeField().setValue(this.qrCodeResult);
          if (this.isEnterprise) {
            EB.Barcode.disable();
          }
        }
        this.searchingBulkItemByQrCode = false;
    });
  }

  public ngOnDestroy(): void {
  }

  get category() {
    return this.form.get('bulkItemCategory');
  }

  get internalNumber() {
    return this.form.get('bulkItemNumber');
  }

  get scanCode() {
    return this.form.get('qrCode');
  }

  get name() {
    return this.form.get('bulkItemName');
  }

  public toggleTorch(): void {
    this.torchEnabled.next(!this.torchEnabled.value);
  }

  public closeScanner(): void {
    if (this.isEnterprise) {
      EB.Barcode.disable();
    }
    this.scannerActive = false;
  }

  public parseCode(code: string): void {
    this.qrCodeResult = code;
    this.scanCodeStore.searchScanCodeInUse(code);
  }

  public activateQrEdit(event: MouseEvent): void {
    event.preventDefault();
    const qrCodeField: AbstractControl = this.getQrCodeField();

    if (qrCodeField.invalid) {
      return;
    }

    if (qrCodeField.disabled) {
      qrCodeField.enable();
      // Safari keyboard workaround. `click()` into the input to open the
      // keyboard and `focus()` to set the cursor into the input

      // This is only possible while being in a click-event.
      this.qrCodeInput.nativeElement.click();
      this.qrCodeInput.nativeElement.focus();
    } else {
      this.qrCodeResult = this.getQrCodeField().value;
      if (this.qrCodeResult) {
        this.scanCodeStore.searchScanCodeInUse(this.qrCodeResult);
      }
      qrCodeField.disable();
    }
  }

  public getQrCodeField(): AbstractControl {
    return this.form.get('qrCode');
  }

  public qrCodeFieldDisabled(): boolean {
    return this.getQrCodeField().disabled;
  }

  public showScanner(): void {
    if (this.isEnterprise) {
      EB.Barcode.enable({}, (code) => {
        this.parseCode(code.data);
      });
    }
    this.scannerActive = true;
  }
}
