import { EquipmentsService } from '../../modules/equipment/shared/equipments.service'
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export class InvoiceNumberTakenValidator {
  static isValid(equipmentsService: EquipmentsService, equipmentId: string, invoiceId: string) {
    return (value: string) => {
      if (value) {
        const params = {
          invoiceId: invoiceId,
          invoiceNumber: value
        };
        return equipmentsService.validInvoiceNumber(equipmentId, params)
          .pipe(map(res => {
          return res ? { invoiceNumberTaken: true } : null;
        }));
      } else {
        return of(null);
      }
    };
  }
}
