import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MapLoaderService } from '../../services/map-loader.service';


interface MapComponentSimpleChanges extends SimpleChanges {
  latitude: SimpleChange;
  longitude: SimpleChange;
}

@Component({
  selector: 'bh-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom = 10;
  @Output() readonly mapInitialized = new EventEmitter<google.maps.Map>();
  public readonly isLoaded = this.mapLoader.isLoaded;
  public center: google.maps.LatLngLiteral;

  constructor(private mapLoader: MapLoaderService) {}

  public ngOnChanges(changes: MapComponentSimpleChanges): void {
    if(changes.latitude || changes.longitude) {
      this.setCenter(this.latitude, this.longitude);
    }
  }

  private setCenter(lat: number, lng: number): void {
    this.center = { lat, lng };
  }
}
