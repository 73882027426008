<div fxLayoutAlign="row" class="main-grid">

  <div class="left-column-div" fxFlex="0 0 auto">
    <div class="left-column" fxLayout="column" fxLayoutAlign="start end">
      <label class="header-label">{{foundCount}} {{'general.result'|translate}}</label>
      <label class="categorie-label"
             *ngFor="let filter of (filters | searchFilterSorting)"
             (click)="scroll(filter.typeName)">
        {{filter.typeName | searchFilterDefaultStatusName | searchFilterType | uppercase}}
      </label>
    </div>
  </div>

  <div fxFlex="1 1 auto" fxLayout="column" class="right-column-div">
    <div class="search-container" fxLayoutAlign="start start">
      <div class="search-container__input-wrapper" fxLayout="row">
        <mat-icon class="search-container__input-wrapper__icon">search</mat-icon>
        <input class="search-container__input-wrapper__input" matInput fxFlex
                  [formControl]="searchTermControl"
                  [placeholder]="'shared.filterDialog.searchFilterParameters'|translate"
                  bhAutofocus [activateFocus]="isActivated">
      </div>
    </div>

    <div class="right-column" fxLayout="column" fxLayoutAlign="start start">
      <cdk-virtual-scroll-viewport #virtualScroll itemSize="45" class="viewport" minBufferPx="500" maxBufferPx="500">
        <div *cdkVirtualFor="let filter of filtersRaw | filterHidden; trackBy: trackByTrackId"
             [ngSwitch]="filter.type" class="filter-element">
          <label *ngSwitchCase="FilterItemType.TYPE"
                 [id]="filter.filterTypeName" class="title-label">
            {{(filter.item.displayNameType || filter.item.typeName) | searchFilterType | uppercase}}
            <span *ngIf="isEquipOrgFilterInGlobalTransferLog(filter.item.typeName)" class="equip-org-icon">
              <mat-icon [matTooltip]="'shared.filterDialog.EquipOrgFilterInfo'|translate" class="equip-org-tooltip">info</mat-icon>
            </span>
          </label>
          <div *ngIf="showSlider(filter.item)" class="or-and-label-container">
            <mat-button-toggle-group class="or-and-toggle-group">
              <mat-button-toggle [checked]="!matchAllLabels" (click)="sliderChanged()">
                {{'general.filterLabels.or'|translate}}
              </mat-button-toggle>
              <mat-button-toggle [checked]="matchAllLabels" (click)="sliderChanged()">
                {{'general.filterLabels.and'|translate}}
              </mat-button-toggle>
            </mat-button-toggle-group>
            <span class="or-and-display-inline">
              <mat-icon [matTooltip]="'shared.filterDialog.OrAndFilterInfo'|translate" class="or-and-tooltip">info</mat-icon>
            </span>
          </div>

          <label *ngSwitchCase="FilterItemType.GROUP"
                 [id]="filter.item.groupName" class="subtitle-label">
            {{filter.item.groupName | uppercase}} {{filter.item.groupTotal}}
          </label>

          <div *ngSwitchDefault
               [id]="filter.item.storeName"
               [ngClass]="getFilterItemClasses(filter)"
               [ngStyle]="{'margin-left': getTreeMargin(filter) + 'px'}">

            <ng-container
              [ngTemplateOutlet]="checkBoxWrapper"
              [ngTemplateOutletContext]="{ filter, state: getCheckBoxState(filter, filter.index) }">
            </ng-container>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
      <bh-time-range-filter #timeRangeFilter
                            *ngIf="showTimeRangeFilter"
                            class="w-100 p-20-0"
                            [timeRangeParams]="timeRangeParams"
                            [quickFilterParam]="quickFilterParam"
                            [showDateTypeControl]="showDateTypeControl"
                            (onTimeRangeData)="updateTimeRangeData($event)">
      </bh-time-range-filter>
    </div>
  </div>
</div>

<ng-template #checkBoxWrapper
  let-filter="filter"
  let-state="state">
  <mat-checkbox
    color="primary"
    class="custom-checkbox"
    [checked]="state?.checked"
    [indeterminate]="state?.indeterminate"
    (click)="onValueUpdated($event, filter, filter.index)"
    [ngClass]="{'mat-checkbox-indeterminate': state?.indeterminate}"
    [disableRipple]="true">
  {{filter.item.displayName | bulkItemTypesTranslator | chargeTypeTranslator | transferTypeTranslator}} {{filter.item.filterTotal}}
  </mat-checkbox>
</ng-template>
