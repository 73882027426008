import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';

export interface IAvailableTransportTaskState {
  state: TransportTaskState;
  availableStates: {
    description: string;
    availableState: TransportTaskState;
    disabled: boolean;
    show: boolean;
  }[];
}

const RETURNED: IAvailableTransportTaskState = {
  state: TransportTaskState.RETURNED,
  availableStates: [
    {
      availableState: TransportTaskState.DRAFT,
      description: 'modules.transportation.transportStateChange.stateXToDraft',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.PLANNABLE,
      description: 'modules.transportation.transportStateChange.stateXToPlannable',
      disabled: false,
      show: false,
    },
  ]
}

const IN_PROGRESS: IAvailableTransportTaskState = {
  state: TransportTaskState.IN_PROGRESS,
  availableStates: [
    {
      availableState: TransportTaskState.PLANNED,
      description: 'modules.transportation.transportStateChange.inProgressToPlanned',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.DONE,
      description: 'modules.transportation.transportStateChange.inProgressToDone',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.CANCELED,
      description: 'modules.transportation.transportStateChange.stateXToCancel',
      disabled: false,
      show: false,
    }
  ]
};

const CANCELED: IAvailableTransportTaskState = {
  state: TransportTaskState.CANCELED,
  availableStates: [
    {
      availableState: TransportTaskState.DRAFT,
      description: 'modules.transportation.transportStateChange.cancelToDraft',
      disabled: false,
      show: false,
    }
  ]
}

const PLANNED: IAvailableTransportTaskState = {
  state: TransportTaskState.PLANNED,
  availableStates: [
    {
      availableState: TransportTaskState.PLANNABLE,
      description: 'modules.transportation.transportStateChange.plannedToPlannable',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.CANCELED,
      description: 'modules.transportation.transportStateChange.stateXToCancel',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.IN_PROGRESS,
      description: 'modules.transportation.transportStateChange.plannedToInProgress',
      disabled: false,
      show: false,
    }
  ]
}

const DRAFT: IAvailableTransportTaskState = {
  state: TransportTaskState.DRAFT,
  availableStates: [
    {
      availableState: TransportTaskState.PLANNABLE,
      description: 'modules.transportation.transportStateChange.stateXToPlannable',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.CANCELED,
      description: 'modules.transportation.transportStateChange.stateXToCancel',
      disabled: false,
      show: false,
    }
  ]
}

const PLANNABLE: IAvailableTransportTaskState = {
  state: TransportTaskState.PLANNABLE,
  availableStates: [
    {
      availableState: TransportTaskState.DRAFT,
      description: 'modules.transportation.transportStateChange.stateXToDraft',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.CANCELED,
      description: 'modules.transportation.transportStateChange.stateXToCancel',
      disabled: false,
      show: false,
    }
  ]
}

const DONE: IAvailableTransportTaskState = {
  state: TransportTaskState.DONE,
  availableStates: [
    {
      availableState: TransportTaskState.ARCHIVED,
      description: 'modules.transportation.transportStateChange.doneToArchived',
      disabled: false,
      show: false,
    },
    {
      availableState: TransportTaskState.IN_PROGRESS,
      description: 'modules.transportation.transportStateChange.doneToInProgress',
      disabled: false,
      show: false,
    }
  ]
}

const ARCHIVED: IAvailableTransportTaskState = {
  state: TransportTaskState.ARCHIVED,
  availableStates: [
    {
      availableState: TransportTaskState.DONE,
      description: 'modules.transportation.transportStateChange.archivedToDone',
      disabled: false,
      show: false,
    },
  ]
}

export const AVAILABLE_OPTIONS: IAvailableTransportTaskState[] = [
  DRAFT,
  PLANNABLE,
  DONE,
  ARCHIVED,
  CANCELED,
  PLANNED,
  RETURNED,
  IN_PROGRESS
];






