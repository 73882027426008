<div class="partner-theme-items">

  <div class="partner-theme-item"
       *ngFor="let themeDefinition of availableThemes"
       (click)="onThemeSelect(themeDefinition.value)"
       [class.active]="selectedTheme === themeDefinition.value"
       [class.disabled]="disabled">

    <div class="partner-theme-item-icon"
         [ngClass]="themeDefinition.cssClass">
      <mat-icon class="ok" *ngIf="selectedTheme === themeDefinition.value">check_circle</mat-icon>
    </div>

    <div class="partner-theme-item-title">
      {{themeDefinition.name}}
    </div>
  </div>
</div>
