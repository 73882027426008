import { Clone } from './attached-image.interface';

export class AttachedDocument {
  fileDescription: string;
  fileName: string;
  fileType: string;
  documentKey: string;

  static fromData(data: Clone<AttachedDocument>): AttachedDocument {
    let instance = new AttachedDocument();
    instance.fileDescription = data.fileDescription;
    instance.fileName = data.fileName;
    instance.fileType = data.fileType;
    instance.documentKey = data.documentKey;
    return instance;
  }
}
