import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Modules } from '../../../shared/enums/modules.enum';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { ModuleWithProviders } from '@angular/core';
import { MobileAcceptDispositionComponent } from '../mobile/mobile-accept-disposition.component';
import { CanActivateChildMobile } from '../../../core/guards/can-activate-child-mobile';
import { MobileProjectListComponent } from '../projects/project-list/mobile/mobile-project-list.component';
import { MobileProjectMapComponent } from '../projects/project-map/mobile/mobile-project-map.component';
import { MobileProjectViewComponent } from '../projects/project-list/project-view/mobile/mobile-project-view.component';
import { MobileProjectViewDetailComponent } from '../projects/project-list/project-view/project-view-detail/mobile/mobile-project-view-detail.component';
import { MobileProjectViewDescriptionComponent } from '../projects/project-list/project-view/project-view-description/mobile-project-view-description.component';
import { MobileProjectViewMapComponent } from '../projects/project-list/project-view/project-view-map/mobile/mobile-project-view-map.component';
import { MobileProjectViewCommentsComponent } from '../projects/project-list/project-view/project-view-comments/mobile/mobile-project-view-comments.component';
import { MobileProjectViewAttachmentsComponent } from '../projects/project-list/project-view/project-view-attachments/mobile/mobile-project-view-attachments.component';
import { MobileProjectViewDispositionsComponent } from '../projects/project-list/project-view/project-view-dispositions/mobile/mobile-project-view-dispositions.component';
import { MobileChooseDispositionProcessComponent } from '../shared/mobile-choose-disposition-process/mobile-choose-disposition-process.component';
import { MobileBulkItemTransferComponent } from '../bulk-items/bulk-item-transfer/mobile/mobile-bulk-item-transfer/mobile-bulk-item-transfer.component';
import { MobileBulkItemAddComponent } from '../bulk-items/mobile-bulk-item-add/mobile-bulk-item-add.component';
import { BulkItemType } from '../contract/bulk-item-type';
import { CanActivateChildMobileWebApp } from 'app/core/guards/can-activate-child-mobile-web-app.guard';
import { CanActivateMobileWebApp } from 'app/core/guards/can-activate-mobile-web-app.guard';


const mobileNotificationRoutes: Routes = [
  {
    path: 'mobile/disposition',
    component: ContentComponent,
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    data: {
      modules: [Modules.DISPOSITION]
    },
    children: [
      {
        path: 'list',
        component: MobileAcceptDispositionComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.DISPOSITION]
        }
      },
      {path: '', redirectTo: 'general', pathMatch: 'full'},
      {path: '**', redirectTo: 'general'}
    ]
  },
  {
    path: 'mobile/projects',
    component: ContentComponent,
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobile, CanActivateChildMobileWebApp],
    data: {
      modules: [Modules.DISPOSITION]
    },
    children: [
      {
        path: 'list',
        component: MobileProjectListComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: 'map',
        component: MobileProjectMapComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: 'view/:id',
        component: MobileProjectViewComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.DISPOSITION]
        },
        children: [
          {
            path: 'general',
            component: MobileProjectViewDetailComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {
            path: 'description',
            component: MobileProjectViewDescriptionComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {
            path: 'map',
            component: MobileProjectViewMapComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {
            path: 'comments',
            component: MobileProjectViewCommentsComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {
            path: 'attachments',
            component: MobileProjectViewAttachmentsComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {
            path: 'assignments',
            component: MobileProjectViewDispositionsComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {path: '', redirectTo: 'general', pathMatch: 'full'},
          {path: '**', redirectTo: 'general'}
        ]
      }
    ]
  },
  {
    path: 'mobile/transfer',
    component: ContentComponent,
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobile, CanActivateChildMobileWebApp],
    data: {
      authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
      modules: [Modules.DISPOSITION]
    },
    children: [
      {
        path: 'choose-process',
        component: MobileChooseDispositionProcessComponent,
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER]
        }
      },
      {
        path: 'bulk-items',
        component: MobileBulkItemTransferComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.BULK_ITEM_MANAGEMENT]
        }
      }
    ]
  },
  {
    path: 'mobile/bulk-items/add',
    component: MobileBulkItemAddComponent,
    canActivate: [CanActivateModule, CanActivateMobileWebApp],
    data: {
      authorities: [Authorities.BULK_ITEMS_MANAGE],
      modules: [Modules.DISPOSITION],
      bulkItemType: BulkItemType.STANDARD
    }
  },
  {
    path: 'mobile/consumable-bulk-items/add',
    component: MobileBulkItemAddComponent,
    canActivate: [CanActivateModule, CanActivateMobileWebApp],
    data: {
      authorities: [Authorities.BULK_ITEMS_MANAGE],
      modules: [Modules.DISPOSITION],
      bulkItemType: BulkItemType.CONSUMABLE
    }
  }
];

export const mobileDispositionRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(mobileNotificationRoutes);
