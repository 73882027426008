import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceContractMileageType } from '../../contract/insurance-contract-mileage-type.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'insuranceContractMileageType'
})
export class InsuranceContractMileageTypePipe implements PipeTransform {
  private readonly translationKeys = {
    [InsuranceContractMileageType.OH]: 'general.operatingHour.pl',
    [InsuranceContractMileageType.KM]: 'general.mileage'
  };

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any, args?: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
