<div class="equipment-upload-images">

  <div class="equipment-upload-content">
    <div class="file-selector">
      <div class="blocking-drop-zone" *ngIf="uploading || fileProcessing > 0">
        <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
        <div *ngIf="uploading">
          {{'shared.fileUpload.isLoading'|translate}}
        </div>
        <div *ngIf="fileProcessing > 0">
          {{'shared.fileUpload.waitLoading'|translate}}
        </div>
      </div>
      <div ng2FileDrop
           [ngClass]="{'file-over-drop-zone': fileOverDropZone}"
           (fileOver)="onFileOverDropZone($event)"
           (onFileDrop)="onFileDropOrSelected($event)"
           [uploader]="fakeFileUploader"
           class="file-drop-zone">

        <div class="file-drop-zone-flex">
          <div class="drop-zone-text">
            {{'shared.fileUpload.dragFile'|translate}}
          </div>
          <div class="file-drop-zone-or-text">{{'general.or'|translate}} ...</div>
          <button mat-raised-button color="secondary">
            <label for="fileInput">
              {{'shared.fileUpload.chooseFiles'|translate}}
            </label>
          </button>
          <input id="fileInput"
                 ng2FileSelect
                 type="file"
                 [uploader]="fakeFileUploader"
                 (onFileSelected)="onFileDropOrSelected($event)"
                 [disabled]="uploading"
                 multiple/>
        </div>
        <div class="picture-upload-information-flex">
          <div class="file-size">{{'shared.fileUpload.maxSize'|translate}}: <b>100 MB</b></div>
        </div>
      </div>
    </div>
    <mat-progress-bar mode="determinate"
                      *ngIf="fileUploader.queue.length"
                      [value]="fileUploader.progress"></mat-progress-bar>
  </div>
</div>

