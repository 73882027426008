import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnDefinition } from 'app/shared/column-definition';
import { ColumnService } from 'app/shared/column.service';
import { DisplayService } from 'app/shared/display.service';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';

@Injectable()
export class ProjectColumnService extends ColumnService {
  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService) {
    super(null, [], [], [],
      { pageSize: userConfigurationService.userConfiguration.projectListConfiguration.pageSize },
      displayService,
      authService);
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.userConfigurationService.saveProjectListConfiguration(pageSize);
      super.selectColumns([], pageSize);
    }
  }
}
