import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '../../shared/users.service';
import { KeycloakService } from '../../../../core/keycloak';
import { CustomDeviceDetectorService} from '../../../../shared/services/custom-device-detector.service';
import { CustomThemeService } from '../../../../shared/services/custom-theme.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { RoleAuthorityGuardsComponent } from '../../../../shared/navigation-guards/role-authority-guards.component';
import { DebounceCounter } from '../../../../shared/utils/debounce-counter.class';
import { ResetUserPasswordCommand } from '../../contract/reset-user-password-command';
import { SaveUserTokensCommand } from '../../contract/save-user-tokens-command';
import { Observable } from 'rxjs';
import { QrCodeDialogParams } from '../../../../shared/components/qr-code-dialog/qr-code-dialog-params.interface';
import { QrCodeDialogComponent } from '../../../../shared/components/qr-code-dialog/qr-code-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';
import { environment } from '../../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-user-settings',
  templateUrl: 'user-settings.component.html',
  styleUrls: ['user-settings.component.scss']
})
export class UserSettingsComponent extends RoleAuthorityGuardsComponent implements OnDestroy, OnInit {

  public applicationName: string;
  public waiting = false;
  public resetEmailSent: boolean;
  private readonly titleIconClickAmount = 10;
  private readonly titleIconClickCounter = new DebounceCounter(this.titleIconClickAmount);

  public useEquipmentSearchV2Control = new UntypedFormControl(false);

  constructor(protected authService: KeycloakService,
              protected usersService: UsersService,
              protected deviceService: CustomDeviceDetectorService,
              protected customThemeService: CustomThemeService,
              protected userConfigurationService: UserConfigurationService,
              protected dialog: MatDialog) {
    super(authService);
    this.applicationName = customThemeService.getApplicationTitle();
  }

  public ngOnInit(): void {
    this.initTitleIconClickCounterListener();
    this.useEquipmentSearchV2Control.setValue(this.userConfigurationService.getEquipmentSearchV2Configuration().useSearchV2)
    this.useEquipmentSearchV2Control.valueChanges.subscribe(value => {
      this.userConfigurationService.saveEquipmentSearchV2Configuration({useSearchV2: value});
    })
  }

  public ngOnDestroy(): void {
  }

  public resetPassword(): void {
    this.waiting = true;
    const cmd: ResetUserPasswordCommand = new ResetUserPasswordCommand();
    cmd.userId = this.authService.getUserUserId();
    this.usersService.resetOwnPassword(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
        this.waiting = false;
        this.resetEmailSent = true;
      },
      error => {
        console.log('error sending reset password email', error);
        this.waiting = false;
        this.resetEmailSent = false;
      }
    );
  }

  public clickTitleIcon(): void {
    this.titleIconClickCounter.next();
  }

  private initTitleIconClickCounterListener(): void {
    this.titleIconClickCounter.message
    .pipe(untilDestroyed(this))
    .subscribe(() => this.provideImpersonateData());
  }

  private provideImpersonateData(): void {
    const token = this.authService.getToken();
    const userId = this.authService.getUserUserId();
    const refreshToken = this.authService.getRefreshToken();

    if (userId && token) {
      this.storeUserImpersonateData(new SaveUserTokensCommand(token, refreshToken))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.openQrCodeDialog(userId));
    }
  }

  private storeUserImpersonateData(command: SaveUserTokensCommand): Observable<string> {
    return this.usersService.saveCurrentUserImpersonateData(command);
  }

  private openQrCodeDialog(value: string): void {
    const data: QrCodeDialogParams = {value};
    this.dialog.open(QrCodeDialogComponent, {data});
  }

  protected readonly environment = environment;
}
