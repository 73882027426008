import { Pipe, PipeTransform } from '@angular/core';
import { Languages } from 'app/shared/enums/languages.enum';
import { TranslatableStringPipe } from './translatable-string.pipe';



@Pipe({ name: 'translatableStringArray' })
export class TranslatableStringArrayPipe implements PipeTransform {

  constructor(private resolver: TranslatableStringPipe) { }

  public transform(
    value: { [key in string]?: string }[],
    language?: Languages
  ): string[] {
    return (value ?? []).map(item => this.resolver.transform(item, language));
  }
}
