import {OspDateTime} from './osp-date-time';

export class CreateReminderCommand {

  constructor(
      public equipmentId: string,
      public reminderName: string,
      public reminderDate: OspDateTime) {
  }

}
