import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { SalesContractStatus } from '../../contract/sales-contract-status.enum';

@Pipe({
  name: 'salesContractStatus'
})
export class SalesContractStatusPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    switch (value) {
      case SalesContractStatus.SUSPENDED:
        return this.translate('shared.pipes.salesContractStatus.suspended');
      case SalesContractStatus.OPEN:
        return this.translate('shared.pipes.salesContractStatus.open');
      case SalesContractStatus.APPROVED:
        return this.translate('shared.pipes.salesContractStatus.approved');
      case SalesContractStatus.IN_PROCESS:
        return this.translate('shared.pipes.salesContractStatus.inProgress');
      case SalesContractStatus.CHANGED:
        return this.translate('shared.pipes.salesContractStatus.changed');
      case SalesContractStatus.COMPLETED:
        return this.translate('shared.pipes.salesContractStatus.completed');
      case SalesContractStatus.LOCKED:
        return this.translate('shared.pipes.salesContractStatus.locked');
      case SalesContractStatus.CANCELED:
        return this.translate('shared.pipes.salesContractStatus.canceled');
      case SalesContractStatus.RELEASED:
        return this.translate('shared.pipes.salesContractStatus.released');
      case SalesContractStatus.NOT_AVAILABLE:
        return this.translate('shared.pipes.salesContractStatus.notAvailable');

      default:
        return this.translate('general.unknown').toLowerCase();
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
