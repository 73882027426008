<bh-basic-widget
  [widget]="widget"
  [index]="index"
  (configurationSave)="onConfigurationSave($event)"
  (deleted)="onDeleted()">

  <ng-container widget-content>
    <div *ngIf="(equipmentStatuses | async | equipmentStatusPreparedData) as preparedData; else noEntries"
      fxLayout="row" fxLayoutAlign="center center" class="chart-wrapper">
      <canvas
        baseChart
        [data]="preparedData | equipmentStatusChartData"
        [options]="preparedData | equipmentStatusChartOptions : widget.displayType : labelClickCallback"
        [plugins]="plugins"
        [type]="barChartType"
        (chartClick)="chartClicked($event, preparedData)">
      </canvas>
    </div>

    <ng-template #noEntries>
      <div class="no-entries">
        <h3>{{ 'general.noAvailableEntries'|translate }}</h3>
      </div>
    </ng-template>
  </ng-container>

  <div widget-configuration>
    <mat-form-field [floatLabel]="organisationPlaceholder.floatLabel">
      <mat-label>{{ 'general.org.pl'|translate }}</mat-label>
      <input matInput
        [matAutocomplete]="organisations"
        [placeholder]="organisationPlaceholder.placeholder"
        (keypress)="preventDefaultEvent($event)">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #organisations="matAutocomplete"
        (opened)="organisationsSelectionTree.open()"
        (closed)="organisationsSelectionTree.getSelectedOptions()">
        <bh-selection-tree #organisationsSelectionTree
          [optionsList]="organisationList"
          (finalSelectedOptionsList)="organisationListClosed()">
        </bh-selection-tree>
        <mat-option style="display: none;">Unused option</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [floatLabel]="typePlaceholder.floatLabel">
      <mat-label>{{ 'general.types'|translate }}</mat-label>
      <input matInput
        [matAutocomplete]="types"
        [placeholder]="typePlaceholder.placeholder"
        (keypress)="preventDefaultEvent($event)">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #types="matAutocomplete"
        (opened)="typesSelectionTree.open()"
        (closed)="typesSelectionTree.getSelectedOptions()">
        <bh-selection-tree #typesSelectionTree
          [optionsList]="typeList"
          (finalSelectedOptionsList)="typeListClosed()">
        </bh-selection-tree>
        <mat-option style="display: none;">Unused option</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <section class="display-type">
      <label class="display-type__label">
        {{'modules.dashboard.equipmentStatusWidget.displayType'|translate}}
      </label>
      <mat-button-toggle-group [formControl]="displayTypeControl">
        <mat-button-toggle class="display-type__toggle"
          *ngFor="let displayType of displayTypeList"
          [value]="displayType.value">
          {{displayType.name}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </section>
  </div>

</bh-basic-widget>
