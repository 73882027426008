import { Pipe, PipeTransform } from '@angular/core';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';
import { ContactLocation } from 'app/modules/contact/contract/contact/contact-location.interface';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapItemContactLocation } from '../interfaces/map-list-item/map-item-search-contact-location.interface';
import { MapLocationType } from '../enums/map-location-type.enum';
import { MapInfoWindowTableViewContactComponent } from '../components/map-info-window-cluster-table-view/map-info-window-table-view-contact/map-info-window-table-view-contact';
import { CONTACT_COLUMN_DEF, GENERAL_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';

@Pipe({
  name: 'mapMarkersConvertContactLocations'
})
export class MapMarkersConvertContactLocationsPipe implements PipeTransform {

  private fieldMapper: Record<string, string> = {
    [CONTACT_COLUMN_DEF.CONTACT_NAME_LOCATION]: GENERAL_COLUMN_DEF.NAME,
    [CONTACT_COLUMN_DEF.CONTACT_INTERNAL_NUMBER_LOCATION]: CONTACT_COLUMN_DEF.IDENTIFIER,
  }

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: ContactLocation[], mapSettings: MapConfiguration): MapLocationMarker<MapItemContactLocation>[] {
    if (!Array.isArray(value)) {
      return null;
    }

    return value.filter(Boolean).map(item => ({
      id: item.contactId,
      location: item.location,
      icon: this.getIconUrl(item),
      item: { ...item, locationType: MapLocationType.CONTACT },
      infoWindow: { content: MapInfoWindowTableViewContactComponent },
      label: mapSettings?.contactColumn
        ? item[this.fieldMapper[mapSettings.contactColumn]]
        : null,
    }));
  }

  private getIconUrl(item: ContactLocation): string {
    return this.iconResolver.getIconContact(item);
  }

}
