import { EquipmentTask } from 'app/modules/equipment/contract/equipment-task';
import { MaintenanceTaskDataSource } from 'app/modules/equipment/shared/maintenance-task.datasource';
import { AdapterTabControllerDatasource } from './adapter-tab-controller-datasource.interface';

export class AdapterMaintenanceTaskDataSource implements AdapterTabControllerDatasource<EquipmentTask> {
  public readonly data = this.datasource.connect();
  constructor(private datasource: MaintenanceTaskDataSource) {}

  public loadById(id: string): void {
    this.datasource.loadTasksForEquipment(id);
  }

  public reset(id: string): void {
    this.datasource.reset(id);
  }
}
