import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';

export class EmployeeStaffNumberInUseValidator {
  static isValid(value: string, employeeManagementDatasource: EmployeeManagementDatasource, oldEmployeeStaffNumber?: string) {
    if (!value || value === oldEmployeeStaffNumber) {
      return of(null);
    }
    return employeeManagementDatasource.employeeStaffNumberInUse(value)
      .pipe(map(res => {
        return res ? { taken: true } : null;
      }));
  }
}
