<mat-card class="entity-creation">
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <span>{{'modules.reports.base.runReport'|translate}}</span>
    </div>

    <div *ngIf="!reportGenerating" class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="downloadReport()"
              [disabled]="!canGenerate">
        {{'modules.reports.reportGeneration.downloadReport'|translate}}
      </button>
      <button mat-raised-button (click)="navigateToView()">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button (click)="resetEntries()">{{'general.buttons.resetEntries'|translate}}</button>
    </div>

    <div class="entity-creation-actions entity-creation-spinner" fxFlex="100" *ngIf="reportGenerating">
      <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <form [formGroup]="generateReportForm" novalidate>
        <div class="entity-creation-form-left" fxFlex="100" fxLayout="column" fxLayoutAlign="start none">
          <div *ngIf="isShowDateFrom || isShowDateUntil" fxLayoutGap="15px"
                class="report-create__first-line mt-15" fxLayoutAlign="start center">
            <mat-form-field floatLabel="always" fxFlex="200px">
              <mat-label>{{'general.period'|translate}}</mat-label>
              <input matInput
                     [max]="getDate(formControlNameUntil)"
                     [formControlName]="formControlNameFrom"
                     [matDatepicker]="fromPicker"
                     [placeholder]="'general.from'|translate|titlecase"
                     autocomplete="off"
                     required>
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-icon class="arrow-icon w-30">keyboard_arrow_right</mat-icon>

            <mat-form-field fxFlex="200px">
              <input matInput
                     [min]="getDate(formControlNameFrom)"
                     [formControlName]="formControlNameUntil"
                     [matDatepicker]="untilPicker"
                     [placeholder]="'general.to'|translate|titlecase"
                     autocomplete="off"
                     required>
              <mat-datepicker-toggle matSuffix [for]="untilPicker"></mat-datepicker-toggle>
              <mat-datepicker #untilPicker></mat-datepicker>
            </mat-form-field>
            <ng-container *ngTemplateOutlet="resetIcon; context: {field: formControlNameFrom}"></ng-container>
          </div>

          <div class="report-create__normal-line" fxLayoutAlign="start stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlexFill fxLayoutAlign="start stretch" fxLayoutGap="15px">
              <div fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-checkbox *ngIf="isShowAllEquipments" color="primary"
                              (change)="toggleAll($event, formControlNameAllEquipments)"
                              [formControlName]="formControlNameAllEquipments">
                  {{'modules.reports.base.allEquipments'|translate}}
                </mat-checkbox>

                <mat-checkbox *ngIf="isShowAllProjects" color="primary"
                              (change)="toggleAll($event, formControlNameAllProjects)"
                              [formControlName]="formControlNameAllProjects">
                  {{'shared.pipes.reportParameterDisplay.allProjects'|translate}}
                </mat-checkbox>
              </div>

              <div *ngIf="isShowEquipmentTypes" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field [floatLabel]="equipmentTypesPlaceholder.floatLabel">
                  <mat-label>{{ 'general.equipmType.pl'|translate }}</mat-label>
                  <input matInput
                         class="disabled-bar"
                         [ngClass]="{'disabled-drop-down': isDisableEquipmentTypes}"
                         [disabled]="isDisableEquipmentTypes"
                         [matAutocomplete]="equipmentTypes"
                         [placeholder]="equipmentTypesPlaceholder.placeholder"
                         (keypress)="preventDefaultEvent($event)"
                         autocomplete="off">
                  <fa-icon class="caret-icon" matIconSuffix [icon]="faCaretDown"></fa-icon>
                  <mat-autocomplete #equipmentTypes="matAutocomplete"
                                    (opened)="equipmentTypesTree.open()"
                                    (closed)="equipmentTypesTree.getSelectedOptions()">
                    <bh-selection-tree #equipmentTypesTree
                                       class="fs-14"
                                       [isWorkWithCategories]="true"
                                       [optionsList]="filteredEquipmentTypesList"
                                       (finalSelectedOptionsList)="equipmentTypesListClose($event)">
                      <div class="multiple-option-no-checkbox select-option-custom" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <fa-icon [icon]="faSearch"></fa-icon>
                        <input class="search-input"
                               [placeholder]="'general.search'|translate"
                               [formControlName]="formControlNameEquipmentTypeSearch"
                               autocomplete="off">
                      </div>
                      <div *ngIf="isShowEmptyEquipmentTypesSearchMessage" class="select-option-custom">
                        {{'general.labels.noSearchResults'|translate}}
                      </div>
                    </bh-selection-tree>
                    <mat-option style="display: none;">Unused option</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="resetIcon; context: {field: formControlNameEquipmentTypes}"></ng-container>
              </div>

              <div *ngIf="isShowLabels" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field>
                  <mat-label>{{'general.label.pl'|translate}}</mat-label>
                  <mat-select [formControlName]="formControlNameEquipmentLabels" multiple bhSelectOverlayPanelClass>
                    <mat-option *ngFor="let label of labels" [value]="label.name">{{label.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="resetIcon; context: {field: formControlNameEquipmentLabels}"></ng-container>
              </div>

              <div *ngIf="isShowEquipments" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field>
                  <mat-label>{{'general.equipm.pl'|translate}}</mat-label>
                  <mat-select multiple bhSelectOverlayPanelClass
                              [formControlName]="formControlNameEquipments"
                              (closed)="toggleDropdown()"
                              (opened)="updateOnEquipmentSearch('')">
                    <mat-select-trigger>{{getSelectedEquipmentNames()}}</mat-select-trigger>
                    <mat-option>
                      <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                      <ngx-mat-select-search  [placeholderLabel]="'general.search'|translate"
                                              [formControlName]="formControlNameEquipmentSearch">
                      </ngx-mat-select-search>
                    </mat-option>
                    <div *ngIf="isShowEmptyEquipmentsSearchMessage" class="select-option-custom">
                      {{'general.labels.noSearchResults'|translate}}
                    </div>

                    <mat-option class="multiple-option-no-checkbox option-title" disabled>
                      <span fxFlex="0 0 200px">{{'modules.reports.base.manufacturerSerialNumber'|translate|uppercase}}</span>
                      <span fxFlex="0 0 200px">{{'general.designation'|translate|uppercase}}</span>
                      <span fxFlex="1 1 100%">{{'modules.reports.base.currentlyAssigned'|translate|uppercase}}</span>
                    </mat-option>

                    <mat-option *ngFor="let equipment of filteredEquipments" [value]="equipment">
                      <span fxFlex="0 0 200px">{{equipment.equipmentSerialNumber}}</span>
                      <span fxFlex="0 0 200px">{{equipment.equipmentName || equipment.equipmentModel}}</span>
                      <span fxFlex="1 1 100%">{{equipment.currentAddress?.city}}
                        <span *ngIf="equipment.currentAddress?.street">, {{equipment.currentAddress?.street}}</span>
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="resetIcon; context: {field: formControlNameEquipments}"></ng-container>
              </div>

              <div *ngIf="isShowOrganisations" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field>
                  <mat-label>{{'general.org.pl'|translate}}</mat-label>
                  <mat-select [formControlName]="formControlNameOrganisationIds" multiple bhSelectOverlayPanelClass>
                    <mat-option *ngFor="let organisation of organisations" [value]="organisation.organisationId">
                      {{organisation.organisationName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="resetIcon; context: {field: formControlNameOrganisationIds}"></ng-container>
              </div>

              <div *ngIf="isShowProjects" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field>
                  <mat-label>{{'general.project.pl'|translate}}</mat-label>
                  <mat-select multiple bhSelectOverlayPanelClass
                              [formControlName]="formControlNameProjects"
                              (closed)="toggleDropdown()"
                              (opened)="updateOnProjectSearch('')">
                    <mat-select-trigger>{{getSelectedProjectNames()}}</mat-select-trigger>
                    <mat-option>
                      <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                      <ngx-mat-select-search  [placeholderLabel]="'general.search'|translate"
                                              [formControlName]="formControlNameProjectSearch">
                      </ngx-mat-select-search>
                    </mat-option>
                    <div *ngIf="isShowEmptyProjectsSearchMessage" class="select-option-custom">
                      {{'general.labels.noSearchResults'|translate}}
                    </div>

                    <mat-option class="multiple-option-no-checkbox option-title" disabled>
                      <span fxFlex="0 0 300px">{{'general.projectNumber'|translate|uppercase}}</span>
                      <span fxFlex="1 1 100%">{{'general.designation'|translate|uppercase}}</span>
                    </mat-option>

                    <mat-option *ngFor="let project of filteredProjects" [value]="project">
                      <span fxFlex="0 0 300px">{{project.projectNumber}}</span>
                      <span fxFlex="1 1 100%">{{project.projectName}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngTemplateOutlet="resetIcon; context: {field: formControlNameProjects}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #resetIcon let-field="field">
  <mat-icon class="reset-icon"
            [matTooltip]="'general.buttons.resetFilterCriteria'|translate"
            (click)="resetFilterCriteria(field)">
    clear
  </mat-icon>
</ng-template>
