import { ProjectToConsumedTransferItem } from './project-to-consumed-transfer-item.interface';

export class CreateProjectToConsumedTransferCartCommand {
  constructor(
    public sourceProjectId: string,
    public transferItems: ProjectToConsumedTransferItem[],
    public employeeId?: string,
    public externalEmployee?: string,
    public comment?: string,
    public transferDate?: Date) {}
}
