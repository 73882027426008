import { CountryResolver } from '../country.resolver';
import { Pipe, PipeTransform } from '@angular/core';
import { CountryEnum } from '../country.enum';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {
  constructor(private countryResolver: CountryResolver) {}

  public transform(value: CountryEnum): string {
    return this.countryResolver.resolveName(value);
  }
}
