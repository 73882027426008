import { AbstractControl, ValidatorFn } from '@angular/forms';
import { LanguageService } from '../services/language.service';

export class LabelValidator {

  public static isValidLabel(propertyName: string, existingLabels: string[], langService: LanguageService): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;

      if (!value || value === '') {
        return null;
      }

      if (this.labelExists(existingLabels, value)) {
        return this.generateWarning('shared.validation.labelChips.labelExists', propertyName, value, langService);
      }

      if (this.hasLessThanMinimumLength(value)) {
        return this.generateWarning('shared.validation.labelChips.minLength', propertyName, value, langService);
      }

      if (this.hasMoreThanMaximumLength(value)) {
        return this.generateWarning('shared.validation.labelChips.maxLength', propertyName, value, langService);
      }

      return null;
    };
  }

  private static labelExists(labels: string[], value: string): boolean {
    const currentLabelDoesNotExist = labels.filter(label => label.toLowerCase() === value.trim().toLowerCase());
    return currentLabelDoesNotExist.length !== 0;
  }

  private static hasLessThanMinimumLength(label: string): boolean {
    return (label.trim().length < 3);
  }

  private static hasMoreThanMaximumLength(label: string): boolean {
    return (label.trim().length > 30);
  }

  private static generateWarning(message: string, propertyName: string, value: any, langService: LanguageService): object {
    propertyName = langService.getInstant(propertyName);
    return { labelExists: langService.getInstant(message, { propertyName, value }) };
  }
}

