import { EquipmentLastPosition } from './equipment-last-position.interface';
import { ViewEquipment } from 'app/modules/equipment/contract/view-equipment.interface';
import { EquipmentPositionSources } from './equipment-position-sources.enum';
import { LocationType } from '../enums/location-type.enum';

export class EquipmentLastPositionResolver {
  public static getEquipmentLastPosition(equipment: ViewEquipment): EquipmentLastPosition {
    return equipment ? this.EquipmentLastPositionMapper(this.getSourcePositionType(equipment), equipment) : null;
  }

  private static getSourcePositionType(equipment: ViewEquipment): EquipmentPositionSources {
    if (this.hasLiveTelematicsData(equipment)) {
      return EquipmentPositionSources.TELEMATIC;

    } else {
      return LocationType.PROJECT === equipment.transferDestinationType
        ? EquipmentPositionSources.DISPOSITION
        : EquipmentPositionSources.STOCK;

    }
  }

  private static hasLiveTelematicsData(equipment: ViewEquipment): boolean {
    return equipment.locatedByTelematics && !!equipment.lastLocationTime
  }

  private static EquipmentLastPositionMapper(positionSource: EquipmentPositionSources, equipment: ViewEquipment): EquipmentLastPosition {
    return !equipment ? null : {
      positionSource,
      ...equipment
    };
  }
}
