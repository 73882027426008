import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachedDocument } from '../../contract/attached-document.interface';
import { DocumentLink } from '../../contract/document-link.interface';
import { FileUtils } from '../../fileUtils';
import {FileDownloadService} from '../test-place/secured-image/services/file-download.service';

@Component({
  selector: 'bh-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent {

  @Input() file: AttachedDocument | DocumentLink;
  @Input() deletable = true;
  @Input() downloadable = false;
  @Output() fileRemove: EventEmitter<void> = new EventEmitter();

  constructor(private fileDownloadService: FileDownloadService) {}

  public removeFile(): void {
    this.fileRemove.emit();
  }

  public fileExists(): boolean {
    return FileUtils.isDocumentLink(this.file);
  }

  public downloadDocument(document: DocumentLink): void {
    this.fileDownloadService.saveFile(document);
  }
}
