import { RouterHistory } from '../../../../../../shared/router-history';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../shared/equipments.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { Observable } from 'rxjs';
import { DatesService } from '../../../../../../shared/services/dates.service';
import { BaseEquipmentLogLifecycle } from '../../base/base-equipment-log-lifecycle';
import { LifeCycle } from '../../../../contract/life-cycle';
import { SearchEquipment } from '../../../../contract/search-equipment.interface';
import { LogManualMileageCommand } from '../../../../contract/log-manual-mileage-command';
import { LifeCycleMileage } from '../../../../contract/life-cycle-mileage';
import { Directive, OnInit } from '@angular/core';
import { DimensionUnitPipe } from '../../../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../../../shared/pipes/dimension-unit-converter.pipe';
import { round } from 'lodash';

@Directive()
export abstract class BaseEquipmentLogMileage extends BaseEquipmentLogLifecycle implements OnInit {

  public newValueDescription;
  public newValuePattern = '[0-9.,]';

  constructor(protected authService: KeycloakService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected equipmentsService: EquipmentsService,
    protected equipmentStore: EquipmentsDataSource,
    protected equipmentStatusService: EquipmentCheckerService,
    protected routerHistory: RouterHistory,
    protected languageService: LanguageService,
    protected dimensionUnitPipe: DimensionUnitPipe,
    protected dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
    super(authService,
      router,
      route,
      formBuilder,
      equipmentsService,
      equipmentStore,
      equipmentStatusService,
      routerHistory,
      languageService);

    const mileageLabel = languageService.getInstant('modules.equipment.lifecycle.newMileage');
    const mileageUnit = dimensionUnitPipe.transform('km');
    this.newValueDescription = `${mileageLabel} (${mileageUnit})`.toUpperCase();
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected hasLiveData(equipment: SearchEquipment): boolean {
    return equipment.hasLiveMileage;
  }

  protected getLatestLifeCycle(equipmentId: string): Observable<LifeCycle> {
    return this.equipmentsService.getLatestMileage(equipmentId);
  }

  protected getLifeCycleValue(lifeCycle: LifeCycleMileage): number {
    return round(this.dimensionUnitConverterPipe.toUserDimensionUnit(lifeCycle.currentMileage, 'km'), 1);
  }

  protected sendCommand(): Observable<string> {
      let cmd = new LogManualMileageCommand();
      cmd.equipmentId = this.equipment.equipmentId;
      cmd.logDate = DatesService.ospDateTimeAtStartOfDay(this.logDate.value);
      cmd.newMileage = this.dimensionUnitConverterPipe.toSystemDimensionUnit(this.newValue.value, 'km');
      cmd.comment = this.comment.value || null;
      return this.equipmentsService.logManualMileage(cmd);
  }
}
