<div fxFlexFill fxLayout="column">
  <mat-form-field>
    <mat-label>{{'general.labels.search'|translate:{value: 'general.address.address'|translate} }}</mat-label>
    <input #search
      matInput
      (keydown.enter)="$event.preventDefault()"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text">
  </mat-form-field>

  <google-map *ngIf="isMapApiLoaded | async"
    fxFlex="1 1 100%"
    bhMapCustomOptions
    bhMapExtendedProps
    [initializeMapTypeControl]="initializeMapTypeControl"
    [panTo]="center"
    [zoom]="zoom"
    (mapClick)="mapClicked($event)"
    [height]="'100%'"
    [width]="'100%'"
    data-osp-test="map">
    <map-marker *ngIf="marker" [position]="marker" data-osp-test="map-marker"></map-marker>
    <map-circle *ngIf="geofence && marker" [center]="marker" [options]="circleGeofenceOptions" data-osp-test="map-circle"></map-circle>
  </google-map>

  <div class="position-status-container">
    <ng-container *ngIf="!(isGeocodingSuccessful | async); else markerPosition">
      <div class="position-info" fxLayoutAlign="row" >
        <mat-icon color="warn">report</mat-icon>
        <mat-error>{{'shared.locationSelect.invalidGeoResponse'|translate}}</mat-error>
      </div>
    </ng-container>
  </div>

  <ng-template #markerPosition>
    <div class="position-info" *ngIf="!marker && mandatory" fxLayoutAlign="row" data-osp-test="hint-invalid">
      <mat-icon color="warn">report</mat-icon>
      <mat-error>{{'shared.locationSelect.invalidPosition'|translate}}</mat-error>
    </div>
    <div class="position-info" *ngIf="marker" data-osp-test="hint-valid">
      <mat-icon color="primary">done_outline</mat-icon>
      <p class="position-confirmed">{{'shared.locationSelect.validPosition'|translate}}</p>
    </div>
  </ng-template>
</div>
