import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ColumnDefinition } from './column-definition';
import { isDefined } from './utils';
import { DisplayService } from './display.service';
import { KeycloakService } from 'app/core/keycloak';
import { PaginatorPageSizeOptions } from './paginator-page-size-options';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

export class ColumnService {
  private readonly defaultColumnWidth = 150;

  protected selectedColumnsSubject: BehaviorSubject<ColumnDefinition[]>;
  protected pageSizeSubject: BehaviorSubject<number>;

  private displayedColumnsSubject: BehaviorSubject<string[]>;
  private availableColumnsSubject: BehaviorSubject<ColumnDefinition[]>;
  protected columnConfigsSubject: BehaviorSubject<ColumnConfig[]>;
  protected transportListViewPageSizeSubject: BehaviorSubject<number>;
  protected transportBoardViewBoardPageSizeSubject: BehaviorSubject<number>;
  protected transportBoardViewListPageSizeSubject: BehaviorSubject<number>;
  protected dispositionBoardViewPageSizeSubject: BehaviorSubject<number>;
  protected dispositionEquipmentListViewPageSizeSubject: BehaviorSubject<number>;
  protected dispositionEmployeeListViewPageSizeSubject: BehaviorSubject<number>;
  private transferCartProjectViewPageSizeSubject: BehaviorSubject<number>;
  private transferCartStockViewPageSizeSubject: BehaviorSubject<number>;

  private filteredAvailableCols: ColumnDefinition[];

  constructor(protected overviewColumn: string,
              protected availableCols: ColumnDefinition[],
              protected selectedCols: string[],
              columnConfigs: ColumnConfig[],
              protected paginatorPageSizeOptions: PaginatorPageSizeOptions,
              protected displayService: DisplayService,
              protected authService: KeycloakService) {
    this.filteredAvailableCols = this.filterAvailableColumns(availableCols);
    this.displayedColumnsSubject = new BehaviorSubject<string[]>([]);
    this.availableColumnsSubject = new BehaviorSubject<ColumnDefinition[]>(_.cloneDeep(this.filteredAvailableCols));
    this.selectedColumnsSubject = new BehaviorSubject<ColumnDefinition[]>([]);
    this.initColumnConfigs(this.filteredAvailableCols, columnConfigs);

    this.pageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.pageSize ? paginatorPageSizeOptions.pageSize : 25
    );
    this.transportListViewPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.transportTaskPaginatorOptions?.listViewPageSize
        ? paginatorPageSizeOptions.transportTaskPaginatorOptions?.listViewPageSize
        : 25
    );
    this.transportBoardViewBoardPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.transportTaskPaginatorOptions?.boardViewBoardPageSize
        ? paginatorPageSizeOptions.transportTaskPaginatorOptions?.boardViewBoardPageSize
        : 25
    );
    this.transportBoardViewListPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.transportTaskPaginatorOptions?.boardViewListPageSize
        ? paginatorPageSizeOptions.transportTaskPaginatorOptions?.boardViewListPageSize
        : 25
    );
    this.dispositionBoardViewPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.dispositionListPaginatorOptions?.boardViewPageSize
        ? paginatorPageSizeOptions.dispositionListPaginatorOptions?.boardViewPageSize
        : 25
    );
    this.dispositionEquipmentListViewPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.dispositionListPaginatorOptions?.equipmentListPageSize
        ? paginatorPageSizeOptions.dispositionListPaginatorOptions?.equipmentListPageSize
        : 25
    );
    this.dispositionEmployeeListViewPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.dispositionListPaginatorOptions?.employeeListPageSize
        ? paginatorPageSizeOptions.dispositionListPaginatorOptions?.employeeListPageSize
        : 25
    );
    this.transferCartProjectViewPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.transferCartPaginatorOptions?.projectViewPageSize
        ? paginatorPageSizeOptions.transferCartPaginatorOptions?.projectViewPageSize
        : 25
    );
    this.transferCartStockViewPageSizeSubject = new BehaviorSubject<number>(
      paginatorPageSizeOptions.transferCartPaginatorOptions?.stockViewPageSize
        ? paginatorPageSizeOptions.transferCartPaginatorOptions?.stockViewPageSize
        : 25
    );
    this.selectedColumns.subscribe(columns => {
      if (this.displayService.isTableFullscreen()) {
        this.displayedColumnsSubject.next(columns.map(column => column.cdkColumnDef));
      }
    });
    this.selectedColumnsSubject.next(this.findMatchingColumns(selectedCols));
    displayService.displayOption.subscribe(option => {
      if (option === 'TABLE_FULLSCREEN') {
        this.displayedColumnsSubject.next(this.selectedColumnsSubject.value.map(c => c.cdkColumnDef));
      } else if (option === 'TABLE_AND_DETAILS') {
        this.displayedColumnsSubject.next([this.overviewColumn]);
      }
    })
  }

  get displayedColumns(): Observable<string[]> {
    return this.displayedColumnsSubject.asObservable();
  }

  get availableColumns(): Observable<ColumnDefinition[]> {
    return this.availableColumnsSubject.asObservable();
  }

  get selectedColumns(): Observable<ColumnDefinition[]> {
    return this.selectedColumnsSubject.asObservable();
  }

  get columnConfigs(): Observable<ColumnConfig[]> {
    return this.columnConfigsSubject.asObservable();
  }

  get pageSize(): Observable<number> {
    return this.pageSizeSubject.asObservable();
  }

  get transportListViewPageSize(): Observable<number> {
    return this.transportListViewPageSizeSubject.asObservable();
  }

  get transportBoardViewBoardPageSize(): Observable<number> {
    return this.transportBoardViewBoardPageSizeSubject.asObservable();
  }

  get transportBoardViewListPageSize(): Observable<number> {
    return this.transportBoardViewListPageSizeSubject.asObservable();
  }

  get dispositionBoardViewPageSize(): Observable<number> {
    return this.dispositionBoardViewPageSizeSubject.asObservable();
  }

  get dispositionEquipmentListViewPageSize(): Observable<number> {
    return this.dispositionEquipmentListViewPageSizeSubject.asObservable();
  }

  get dispositionEmployeeListViewPageSize(): Observable<number> {
    return this.dispositionEmployeeListViewPageSizeSubject.asObservable();
  }

  get transferCartProjectViewPageSize(): Observable<number> {
    return this.transferCartProjectViewPageSizeSubject.asObservable();
  }

  get transferCartStockViewPageSize(): Observable<number> {
    return this.transferCartStockViewPageSizeSubject.asObservable();
  }

  get columnLimit(): number {
    return 9;
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    const cnfMap = new Map(this.columnConfigsSubject.getValue().map(cnf => ([cnf.name, cnf])));
    configs.forEach(cnf => {
      const newCnf = {
        ...(cnfMap.get(cnf.name) ?? {}),
        ...cnf
      }
      cnfMap.set(newCnf.name, newCnf);
    });

    this.columnConfigsSubject.next(Array.from(cnfMap.values()));
  }

  public selectColumns(
    columns: ColumnDefinition[],
    pageSize?: number,
    paginatorPageSizeOptions?: PaginatorPageSizeOptions
  ): void {
    this.selectedColumnsSubject.next(this.removeDuplicateColumnDefinitions(columns));
    if (pageSize) {
      this.pageSizeSubject.next(pageSize);
    }
    if (paginatorPageSizeOptions?.transferCartPaginatorOptions?.stockViewPageSize) {
      this.transferCartStockViewPageSizeSubject.next(
        paginatorPageSizeOptions.transferCartPaginatorOptions.stockViewPageSize
      );
    }
    if (paginatorPageSizeOptions?.transferCartPaginatorOptions?.projectViewPageSize) {
      this.transferCartProjectViewPageSizeSubject.next(
        paginatorPageSizeOptions.transferCartPaginatorOptions.projectViewPageSize
      );
    }
    if (paginatorPageSizeOptions?.transportTaskPaginatorOptions?.listViewPageSize) {
      this.transportListViewPageSizeSubject.next(
        paginatorPageSizeOptions.transportTaskPaginatorOptions.listViewPageSize
      );
    }
    if (paginatorPageSizeOptions?.transportTaskPaginatorOptions?.boardViewBoardPageSize) {
      this.transportBoardViewBoardPageSizeSubject.next(
        paginatorPageSizeOptions.transportTaskPaginatorOptions.boardViewBoardPageSize
      );
    }
    if (paginatorPageSizeOptions?.transportTaskPaginatorOptions?.boardViewListPageSize) {
      this.transportBoardViewListPageSizeSubject.next(
        paginatorPageSizeOptions.transportTaskPaginatorOptions.boardViewListPageSize
      );
    }
    if (paginatorPageSizeOptions?.dispositionListPaginatorOptions?.equipmentListPageSize) {
      this.dispositionEquipmentListViewPageSizeSubject.next(
        paginatorPageSizeOptions.dispositionListPaginatorOptions.equipmentListPageSize
      );
    }
    if (paginatorPageSizeOptions?.dispositionListPaginatorOptions?.boardViewPageSize) {
      this.dispositionBoardViewPageSizeSubject.next(
        paginatorPageSizeOptions.dispositionListPaginatorOptions.boardViewPageSize
      );
    }
    if (paginatorPageSizeOptions?.dispositionListPaginatorOptions?.employeeListPageSize) {
      this.dispositionEmployeeListViewPageSizeSubject.next(
        paginatorPageSizeOptions.dispositionListPaginatorOptions.employeeListPageSize
      );
    }
  }

  private initColumnConfigs(availableColumns: ColumnDefinition[], columnConfigs: ColumnConfig[]): void {
    const cfsMap: Map<string, ColumnConfig> = new Map((columnConfigs || []).map(cnf => [cnf.name, cnf]));
    const columnConfigsInitial: ColumnConfig[] = availableColumns
      .map(({ cdkColumnDef: name }) => cfsMap.get(name) ?? { name, width: this.defaultColumnWidth });
    this.columnConfigsSubject = new BehaviorSubject(columnConfigsInitial);
  }

  private findMatchingColumns(columns: string[]): ColumnDefinition[] {
    return this.removeDuplicateColumnDefinitions(columns.map(column => this.filteredAvailableCols.find(cd => cd.cdkColumnDef === column))
      .filter(columnDef => isDefined(columnDef))
      .map(columnDef => _.cloneDeep(columnDef)));
  }

  private filterAvailableColumns(columns: ColumnDefinition[]): ColumnDefinition[] {
    return columns.filter(column => {
      return !column.module || this.authService.hasModule(column.module);
    });
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return false;
  }

  public isNonRemovable(column: ColumnDefinition): boolean {
    return false;
  }

  private removeDuplicateColumnDefinitions(columns: ColumnDefinition[]): ColumnDefinition[] {
    return [...new Map(columns.map((column) => ([column?.cdkColumnDef, column]))).values()];
  }
}
