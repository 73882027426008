export enum TransportHistoryFieldType {
  EMPTY = 'EMPTY',
  STRING = 'STRING',
  DATE = 'DATE',
  ADDRESS = 'ADDRESS',
  STATE = 'STATE',
  PRIORITY = 'PRIORITY',
  DURATION = 'DURATION',
  LOCATION = 'LOCATION',
  CONTACT_TYPE = 'CONTACT_TYPE',
  PROJECT_STATUS = 'PROJECT_STATUS',
  TRANSPORT_ITEM_TYPE = 'TRANSPORT_ITEM_TYPE',
}
