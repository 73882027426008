<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <mat-spinner class="spinner-event-update"
               [diameter]="120"
               [ngClass]="{ 'disabled': !isRequestInProgress }">
  </mat-spinner>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="task-header">
    <bh-title [text]="'modules.equipment.equipmentView.timeline'|translate"></bh-title>
    <mat-icon *ngIf="isActiveEquipment() && hasAvailableActions()"
              class="task-create-assign-trigger" [matMenuTriggerFor]="addTimelineEvent">
      add_box
    </mat-icon>
    <mat-menu xPosition="before" #addTimelineEvent="matMenu" class="task-create-assign-panel">
      <button mat-menu-item
              *ngIf="canAddEquipmentIncident()"
              (click)="equipmentAddIncident()"
              [disabled]="!isActiveEquipment()"
              [matTooltip]="'modules.equipment.incident.logIncident'|translate">
        <mat-icon fontSet="icon-general2_outline"></mat-icon>
        <span>{{'modules.equipment.incident.logIncident'|translate}}</span>
      </button>
      <button mat-menu-item
              *ngIf="canLogEquipmentOperatingHours()"
              (click)="equipmentLogOperatingHours()"
              [disabled]="!isActiveEquipment()"
              [matTooltip]="'modules.equipment.base.logOperatingHours'|translate">
        <mat-icon fontSet="icon-workinghours01_outline"></mat-icon>
        <span>{{'modules.equipment.base.logOperatingHours'|translate}}</span>
      </button>
      <button mat-menu-item
              *ngIf="canLogEquipmentMileage()"
              (click)="equipmentLogMileage()"
              [disabled]="!isActiveEquipment()"
              [matTooltip]="'modules.equipment.base.logMileage'|translate">
        <mat-icon fontSet="icon-odometer_outline"></mat-icon>
        <span>{{'modules.equipment.base.logMileage'|translate}}</span>
      </button>
      <button mat-menu-item
              *ngIf="canLogEquipmentMaliciousDamage()"
              (click)="equipmentAddDamage()"
              [disabled]="!isActiveEquipment()"
              [matTooltip]="'modules.equipment.damage.logMaliciousDamage'|translate">
        <mat-icon fontSet="icon-violence_outline"></mat-icon>
        <span>{{'modules.equipment.damage.logMaliciousDamage'|translate}}</span>
      </button>
    </mat-menu>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <form class="filter-form" fxLayoutGap="15px" [formGroup]="filterForm">
      <mat-form-field fxFlex="20%">
        <mat-select multiple
                    [placeholder]="'general.incident.pl'|translate"
                    formControlName="maintenanceTypes">
          <mat-option *ngFor="let eventType of lifeCycleTypes | keys" [value]="eventType">
            {{eventType | timelineEventTypePipe}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <bh-date-picker-input fxFlex="20%"
                            [control]="getDateStartControl()"
                            [placeholder]="'general.startDate'|translate"
                            [showClearIcon]="true">
      </bh-date-picker-input>

      <bh-date-picker-input fxFlex="20%"
                            [control]="getDateEndControl()"
                            [placeholder]="'general.endDate'|translate"
                            [showClearIcon]="true">
      </bh-date-picker-input>

      <div class="reminder-create" fxFlex="40%">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <mat-form-field flex="90%">
            <mat-label>{{'general.labels.searchTerm'|translate}}</mat-label>
            <input matInput formControlName="termFilter">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div fxFlex="80"
       class="lc-content-wrapper"
       fxLayout="column" fxLayoutAlign="start stretch">
    <div class="lc-content"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onLifecycleScroll()"
         [scrollWindow]="false">

      <mat-accordion multi>
        <bh-event *ngFor="let lc of lifeCycles; trackBy: trackByLifecycleId"
                  [lifeCycle]=lc
                  [eventIcon]="getIcon(lc)"
                  [eventHeaders]="getEventHeader(lc)"
                  [eventDescription]="getEventDescription(lc)"
                  [eventDetails]="getEventDetails(lc)"
                  [equipmentId]="this.equipment.equipmentId"
                  [deleteAllowed]="allowDelete(lc)"
                  [deleteDisabled]="!isActiveEquipment()"
                  [hasAttachments]="hasAttachments(lc)"
                  [editAllowed]="allowEdit(lc)"
                  [editDisabled]="!isActiveEquipment()"
                  (deleted)="eventDeleted()">
        </bh-event>
      </mat-accordion>

      <span *ngIf="!lifeCycles || lifeCycles.length === 0">
        &nbsp;({{'modules.equipment.equipmentDetailGeneral.noEntriesYet'|translate}})
      </span>
    </div>
  </div>

</div>
