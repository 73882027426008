<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <!--TODO uncomment once is implemented and discussed-->
      <!--<button mat-button class="search-bar-button-left" matTooltip="Filter" fxFlex="50px">-->
        <!--<mat-icon>filter_list</mat-icon>-->
      <!--</button>-->

      <div class="search-bar-input-container" fxFlex>
        <div class="search-bar-input" fxLayout="row">
          <input fxFlex matInput #filter>
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <button mat-button class="search-bar-button-right" fxFlex="50px" [matTooltip]="'modules.organisation.partnerList.createPartner'|translate"
              (click)="navigateToAdd()" *ngIf="hasAuthority(authorities.PARTNER_CREATE)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div fxFlex="60px" class="list-paginator">
        <mat-paginator #paginator
                      [length]="length"
                      [pageIndex]="pagingPartnersDataSource?.pageIndex"
                      [pageSize]="pagingPartnersDataSource?.pageSize"
                      [pageSizeOptions]="[5, 25, 50, 100]"
                      (page)="onPaginateChange($event)">
        </mat-paginator>
      </div>

      <div class="data-table-wrapper" fxFlex>
        <mat-table class="pagination-data-table" #table [dataSource]="pagingPartnersDataSource">
          <ng-container cdkColumnDef="partnerName">
            <mat-header-cell *cdkHeaderCellDef> {{'general.name.s'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.partnerName}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="partnerDetails">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="viewPartner(row)"
                      [matTooltip]="'general.buttons.view'|translate">
                <mat-icon>visibility</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="partnerEdit" *ngIf="hasAuthority(authorities.PARTNER_UPDATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="navigateToEdit(row.partnerId)"
                      [matTooltip]="'general.buttons.edit'|translate">
                <mat-icon>create</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="partnerEditModules" *ngIf="hasAuthority(authorities.PARTNER_UPDATE)
                        && this.hasAnyRole(this.priviledgedRole.Superadmin)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.partnerList.editModules'|translate">
              <button mat-button (click)="navigateToEditModules(row.partnerId)">
                <mat-icon>view_module</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="partnerDelete" *ngIf="hasAuthority(authorities.PARTNER_DELETE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="deletePartner(row)"
                      [matTooltip]="'general.buttons.delete'|translate">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="partnerAddUser" *ngIf="hasAuthority(authorities.PARTNERADMIN_CREATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button
                      [matTooltip]="'modules.organisation.partnerList.createPartnerAdmin'|translate"
                      (click)="navigateToAdd('add/partneradmin', {partnerId: row.partnerId}, 'users')">
                <mat-icon>person_add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
