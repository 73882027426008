import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { organisationRouting } from './organisation.routing';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrganisationListComponent } from './organisations/organisation-list/organisation-list.component';
import { OrganisationAddComponent } from './organisations/organisation-add/organisation-add.component';
import { OrganisationsService } from './shared/organisations.service';
import { CustomersService } from './shared/customers.service';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerAddComponent } from './customer/customer-add/customer-add.component';
import { CustomerEditComponent } from './customer/customer-edit/customer-edit.component';
import { CustomerViewComponent } from './customer/customer-view/customer-view.component';
import { PartnerAddComponent } from './partner/partner-add/partner-add.component';
import { PartnerEditComponent } from './partner/partner-edit/partner-edit.component';
import { PartnerDeleteDialogComponent } from './shared/partner-delete-dialog/partner-delete-dialog.component';
import { PartnerViewComponent } from './partner/partner-view/partner-view.component';
import { PartnerListComponent } from './partner/partner-list/partner-list.component';
import { PartnerThemeChooseComponent } from './partner/partner-theme/partner-theme-choose.component';
import { PartnerColumnService } from '../disposition/shared/services/partner-column.service';
import { PartnersService } from './shared/partners.service';
import { OrganisationEditComponent } from './organisations/organisation-edit/organisation-edit.component';
import { OrganisationAddToUserComponent } from './organisations/organisation-add-to-user/organisation-add-to-user.component';
import { OrganisationMoveComponent } from './organisations/organisation-move/organisation-move.component';
import { OrganisationViewComponent } from './organisations/organisation-view/organisation-view.component';
import { OrganisationDeleteComponent } from './shared/organisation-delete/organisation-delete.component';
import { CustomerModulesEditComponent } from './customer/customer-modules-edit/customer-modules-edit.component';
import { SharedModule } from '../../shared/shared.module';
import { CustomerRolesEditComponent } from './customer/customer-roles-edit/customer-roles-edit.component';
import { CustomerTypeDisplayPipe } from './shared/pipes/customer-type-display.pipe';
import { ShopIdEditDialogComponent } from './shared/shop-id-edit-dialog/shop-id-edit-dialog.component';
import { AutonumberEditDialogComponent } from './shared/autonumber-edit-dialog/autonumber-edit-dialog.component';
import { PartnerModulesEditComponent } from './partner/partner-modules-edit/partner-modules-edit.component';
import { CustomerLanguagesComponent } from './customer/customer-languages/customer-languages.component';
import { MainPipeModule } from 'app/shared/pipes/main-pipe.module';
import { SubCustomerListComponent } from './customer/sub-customer-list/sub-customer-list.component';
import { CustomerMoveComponent } from './customer/customer-move/customer-move.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CustomMaterialModule,
    SharedModule,
    TranslateModule,
    MainPipeModule,
    organisationRouting
  ],
  declarations: [
    OrganisationListComponent,
    OrganisationAddComponent,
    CustomerListComponent,
    CustomerAddComponent,
    CustomerEditComponent,
    CustomerViewComponent,
    SubCustomerListComponent,
    CustomerMoveComponent,
    PartnerListComponent,
    PartnerAddComponent,
    PartnerEditComponent,
    PartnerDeleteDialogComponent,
    PartnerViewComponent,
    PartnerThemeChooseComponent,
    PartnerModulesEditComponent,
    OrganisationEditComponent,
    OrganisationAddToUserComponent,
    OrganisationMoveComponent,
    OrganisationViewComponent,
    OrganisationDeleteComponent,
    CustomerModulesEditComponent,
    CustomerRolesEditComponent,
    CustomerTypeDisplayPipe,
    AutonumberEditDialogComponent,
    ShopIdEditDialogComponent,
    CustomerLanguagesComponent
  ],
  bootstrap: [],
  providers: [
    DatePipe,
    OrganisationsService,
    CustomersService,
    PartnerColumnService,
    PartnersService
  ]
})
export class OrganisationModule {
}
