import { EventEmitter } from '@angular/core';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterType } from './filter-type';
import { FilterTypes } from './filter-types.enum';
import { UpdateFilterCommand } from './update-filter-command.interface';

export abstract class FiltersBaseService<T> {
  public readonly onFiltersUpdated = new EventEmitter();
  public readonly filters: FilterType[] = [];
  public abstract updateFilters(): void;

  constructor(protected params: FilterParams) { }

  public getFilterParams(): T {
    return this.params.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.params.updateParams(commands);
    if (updateFilters) {
      this.updateFilters();
    }
  }

  public hasAnyFilter(): boolean {
    return this.params.hasAnyParam();
  }

  public getFilterSlice(categories: FilterTypes[]) {
    if ((categories ?? []).length === 0) {
      return this.filters;
    }
    const categoriesSet = new Set(categories);
    return this.filters.filter(({ typeName }) => categoriesSet.has(typeName));
  }

  protected mergeToResultFilters(convertedFilters: FilterType[]): void {
    convertedFilters.forEach((filterType: FilterType) => this.pushToResultFilters(filterType));
  }

  protected pushToResultFilters(filterType: FilterType): void {
    const indexOfFilterType = this.filters.findIndex(({ typeName }) => filterType.typeName === typeName);
    if (indexOfFilterType >= 0) {
      this.filters[indexOfFilterType] = filterType;
    } else {
      this.filters.push(filterType);
    }
  }

  protected orderByPattern<E>(pattern: E[]): (first: any, second: any) => number {
    return (first: E, second: E): number => {
      let firstIndex = (pattern ?? []).indexOf(first);
      let secondIndex = (pattern ?? []).indexOf(second);
      return (firstIndex === -1 ? 100 : firstIndex) - (secondIndex === -1 ? 100 : secondIndex);
    }
  }
}
