import { RoleAvailability } from '../../contract/role-availability.interface';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomersService } from '../../shared/customers.service'
import { ViewCustomer } from '../../contract/view-customer.interface';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '../../../userrole/shared/users.service';
import { ViewUser } from '../../../userrole/contract/view-user.interface';
import { RolesService } from '../../../userrole/shared/roles.service';
import { ViewRole } from '../../../userrole/contract/view-role.interface';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { UpdateCustomerRolesCommand } from '../../contract/update-customer-roles-command';
import { RouterHistory } from '../../../../shared/router-history';
import { BrzBlacklistAuthorities } from '../../../../shared/enums/brz-blacklist-authorities.enum';

@Component({
  selector: 'bh-customer-roles-edit',
  templateUrl: './customer-roles-edit.component.html',
  styleUrls: ['./customer-roles-edit.component.scss']
})
export class CustomerRolesEditComponent extends GuardedNavigableInputComponent implements OnInit {

  public customerRolesEditForm: UntypedFormGroup;
  public allRoles: ViewRole[] = [];
  public availableRoles: RoleAvailability[] = [];
  public customerUsers: ViewUser[] = [];
  public hasConflicts: boolean;

  private customer: ViewCustomer;

  constructor(private formBuilder: UntypedFormBuilder,
              protected router: Router,
              private customerService: CustomersService,
              private userService: UsersService,
              private roleService: RolesService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void  {
    this.buildForm();
    this.getCustomer();
  }

  public navigateBack(): void {
    this.router.navigate([`/customers/list`]);
  }

  public availableRolesList(): RoleAvailability[] {
    return this.availableRoles.filter(role => role.isAvailable);
  }

  public selectRole(roleName: string): void {
    if (!this.isFleetAdminRole(roleName) && !this.hasBrzBlacklistAuth(roleName)) {
      const role: RoleAvailability = this.availableRoles.find(r => r.name === roleName);
      role.isAvailable = !role.isAvailable;
      this.checkForConflicts();
    }
  }

  public hasBrzBlacklistAuth(roleName: string): boolean {
    if (!this.isFleetAdminRole(roleName) && this.customer.hasBrzConnector) {
      const role: ViewRole = this.allRoles.find(viewRole => viewRole.name === roleName);
      return role.authorities.filter(authority => Object.keys(BrzBlacklistAuthorities).includes(authority)).length > 0;
    }
    return false;
  }

  public isValid() {
    const fallbackRoleId = this.customerRolesEditForm.get('fallbackRoleId').value;
    return !this.hasConflicts ||
        (this.hasConflicts && fallbackRoleId && this.isRoleAvailable(fallbackRoleId))
  }

  public save(): void {
    if (this.isValid()) {
      const cmd = new UpdateCustomerRolesCommand();
      const formValue = this.customerRolesEditForm.getRawValue();
      cmd.customerId = formValue.customerId;
      cmd.fallbackRoleId = formValue.fallbackRoleId;
      cmd.roleIds = this.availableRoles
        .filter(role => !this.isFleetAdminRole(role.name) && role.isAvailable)
        .map(role => role.roleId);
      this.customerService.updateRoles(cmd).subscribe(
        () => {
          this.navigateAfterSubmit();
        },
        (error: HttpErrorResponse) => {
          console.log('edit customer error: ', error);
        }
      );
    }
  }

  private buildForm(): void  {
    this.customerRolesEditForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      fallbackRoleId: ['']
    });
  }

  private getRoles(): void  {
    this.roleService.getRolesByPartner(this.customer.partnerId).subscribe(
      viewRoles => {
        this.allRoles = viewRoles.filter(role => {
          return role.name !== PrivilegedRole.Superadmin.toString()
            && role.name !== PrivilegedRole.Partneradmin.toString()
            && role.name !== PrivilegedRole.Flottenadmin.toString()
        });

        this.setCurrentRoles();
        this.getUsers();
      },
      error => {
        console.log(error);
      }
    );
  }

  private getCustomer(): void  {
    this.route.params.subscribe(params => {
      const customerId = params['id'];
      this.customerService.getCustomer(customerId).subscribe(
        viewCustomer => {
          this.customer = viewCustomer;
          this.setCustomerId();
          this.getRoles();
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  private getUsers(): void  {
    this.userService.getUsersByCustomer(this.customer.customerId).subscribe(
      viewUsers => {
        this.customerUsers = viewUsers;
        this.checkForConflicts();
      },
      error => {
        console.log(error);
      }
    );
  }

  private setCustomerId(): void  {
    this.customerRolesEditForm.patchValue({
      customerId: this.customer.customerId
    });
  }

  private setCurrentRoles(): void  {
    this.availableRoles = this.allRoles.map(role => {
      const roleSelect = {
        roleId: role.roleId,
        name: role.name,
        isAvailable: false
      };

      if (this.isFleetAdminRole(role.name)) {
        roleSelect.isAvailable = true;
      }
      if (this.customer.roleIds &&
        this.customer.roleIds.some(roleId => role.roleId === roleId)) {
        roleSelect.isAvailable = true;
      }

      return roleSelect;
    });
  }

  private checkForConflicts(): void {
    this.hasConflicts = false;
    this.customerUsers.forEach(user => {
      const invalidRoles: number =  user.roles
        .filter(role => !this.isRoleAvailable(role.id) && !this.isFleetAdminRole(role.name))
        .length;
      if (invalidRoles === user.roles.length) {
        this.hasConflicts = true;
      }
    });
  }

  private isRoleAvailable(roleId: string): boolean {
    const role = this.availableRoles.find(r => r.roleId === roleId);
    return role && role.isAvailable;
  }

  private isFleetAdminRole(roleName: string): boolean {
    return roleName === PrivilegedRole.Flottenadmin.toString();
  }

}
