import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportParameter } from '../../contract/report-parameter.enum';

@Component({
  selector: 'bh-all-selected-warning-dialog',
  templateUrl: './all-selected-warning-dialog.component.html',
  styleUrls: ['./all-selected-warning-dialog.component.scss']
})
export class AllSelectedWarningDialogComponent {
  readonly reportParameter = ReportParameter;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }
}
