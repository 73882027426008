<div class="disposition-form-item" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">

  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
    <div class="equipment-name" fxFlex="90">
      {{ equipment.equipmentName || equipment.equipmentModel }}
    </div>
    <div fxFlex="10" fxLayout="row" fxLayoutAlign="end center">
      <div [matTooltip]="'modules.equipment.assignProject.equipmentNotDispose'|translate"
           (click)="remove()">
        <mat-icon class="remove-icon">delete</mat-icon>
      </div>
    </div>
  </div>

</div>
