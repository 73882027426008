import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MaintenanceTaskService } from './maintenance-task.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskInfo } from '../task-info.interface';
import { MaintenanceEquipmentService } from './maintenance-equipment.service';
import _ from 'lodash';

@Injectable()
export class MaintenanceTaskCompletedResolver implements Resolve<TaskInfo> {
  constructor(private maintenanceTaskService: MaintenanceTaskService, private maintenanceEquipmentService: MaintenanceEquipmentService,) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TaskInfo> | Promise<TaskInfo> | TaskInfo {
    return this.maintenanceTaskService.getById(route.paramMap.get('id'))
      .pipe(
        switchMap(task => combineLatest([
          of(task),
          this.maintenanceEquipmentService.getById(task.equipmentId)
        ])),
        map(([task, equipment]) => {
          return {
            task: task,
            ruleName: task.maintenanceTaskCompletionInfo.ruleNameAtCompletion,
            ruleDescription: task.maintenanceTaskCompletionInfo.ruleDescriptionAtCompletion,
            ruleHasMileageInterval: task.completedAtMileage > 0,
            ruleHasOperatingHoursInterval: task.completedAtOperatingHours > 0,
            maintenanceTypeName: task.maintenanceTaskCompletionInfo.typeNameAtCompletion,
            maintenanceTypeDescription: task.maintenanceTaskCompletionInfo.typeDescriptionAtCompletion,
            maintenanceCategory: task.maintenanceTaskCompletionInfo.categoryAtCompletion,
            equipmentName: equipment.equipmentName,
            maintenanceFieldValues: task.maintenanceTaskCompletionInfo.maintenanceFieldValues,
            currentEquipmentMileage: equipment.currentMileage,
            currentEquipmentOperatingHours: equipment.currentOperatingHours,
            completed: true,
            completedAtDate: task.completedAtDate,
            completedAtMileage: task.completedAtMileage,
            completedAtOperatingHours: task.completedAtOperatingHours,
          };
        })
      );
  }
}
