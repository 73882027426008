import { RequestStatus } from '../shared/enums/request-status.enum';
import { TransferItem } from '../shared/transfer/model/transfer-item';
import { ProjectTypeOfUseRequestCommand } from './project-type-of-use-request-command';

export class AcceptTypeOfUseRequest {

  constructor(public command: ProjectTypeOfUseRequestCommand,
     public status: RequestStatus,
     public transferItem: TransferItem,
     public errorText?: string) {}
}
