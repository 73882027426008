import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'bh-date-time-range-select',
  templateUrl: './date-time-range-select.component.html',
  styleUrls: ['./date-time-range-select.component.scss'],
})
export class DateTimeRangeSelectComponent implements OnInit, AfterViewInit {

  @Input()
  formGroup: UntypedFormGroup;
  @Input()
  defaultStartDateTime: Date;
  @Input()
  defaultEndDateTime: Date;
  @Input()
  endDateRequired = true;

  private _startDateTimeControl: AbstractControl;
  private _endDateTimeControl: AbstractControl;

  @Input()
  public set startDateTimeControl(control: AbstractControl) {
    this._startDateTimeControl = control;
  };

  @Input()
  public set endDateTimeControl(control: AbstractControl) {
    this._endDateTimeControl = control;
  };

  public get startDateTimeControl(): AbstractControl {
    return this._startDateTimeControl;
  }

  public get endDateTimeControl(): AbstractControl {
    return this._endDateTimeControl;
  }

  constructor() {}

  ngOnInit(): void {
    if (this.defaultStartDateTime) {
      this.startDateTimeControl.setValue(this.defaultStartDateTime);
    }

    if (this.defaultEndDateTime) {
      this.endDateTimeControl.setValue(this.defaultEndDateTime);
    }
  }

  ngAfterViewInit(): void {
    this.startDateTimeControl.updateValueAndValidity();
    this.endDateTimeControl.updateValueAndValidity();
  }

}
