import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { getHttpParameters } from '../../../shared/utils';
import { environment } from '../../../../environments/environment';
import { SearchEmployee } from '../contract/search-employee.interface';
import { ViewEmployee } from '../contract/view-employee.interface';
import { CreateEmployeeCommand } from '../contract/create-employee.command';
import { UpdateEmployeeCommand } from '../contract/update-employee.command';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { DeleteEmployeeDocumentCommand } from '../contract/delete-employee-document-command';
import { UpdateEmployeeDocumentDescriptionCommand } from '../contract/update-employee-document-description-command';
import { SetEmployeeThumbnailCommand } from '../contract/set-employee-thumbnail-command';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { UploadEmployeeDocumentCommand } from '../contract/upload-employee-document-command';
import { AddEmployeeLabelCommand } from '../contract/add-employee-label-command';
import { RemoveEmployeeLabelCommand } from '../contract/remove-employee-label-command';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { DeactivateEmployeeCommand } from '../contract/deactivate-employee-command';
import { DeleteEmployeeCommand } from '../contract/delete-employee.command';
import { AssignEmployeeToUserCommand } from '../contract/assign-employee-to-user-command';
import { UnassignEmployeeFromUserCommand } from '../contract/unassign-employee-from-user-command';
import { ViewEmployeeNote } from '../contract/view-employee-note.interface';
import { CreateEmployeeNoteCommand } from '../contract/create-employee-note-command';
import { UpdateEmployeeNoteCommand } from '../contract/update-employee-note.command';
import { DeleteEmployeeNoteCommand } from '../contract/delete-employee-note.command';
import { ActivateEmployeeCommand } from '../contract/activate-employee-command';
import { ViewEmployeeEquipmentAssignment } from '../contract/view-employee-equipment-assignment';
import { ViewEmployeeBasicData } from '../contract/view-employee-basicData';
import { EmployeeResponseFilterCollectionView } from 'app/shared/contract/filter/filter-view/employee-filter-collection/employee-response-filter-collection-view.interface';
import { UpdateEmployeeDocumentNameCommand } from '../contract/update-employee-document-name.command';
import { DeleteEmployeeDocumentRenewalCommand } from '../contract/delete-employee-document-renewal.command';
import { UpdateEmployeeDocumentNameRenewalCommand } from '../contract/update-employee-document-name-renewal.command';
import { UpdateEmployeeDocumentDescriptionRenewalCommand } from '../contract/update-employee-document-description-renewal.command';


@Injectable()
export class EmployeeManagementService {
  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/employees';
  private filterableLabelUrl = this.url + '/filterable-labels';
  public thumbnailPrefixUrl = this.url + '/thumbnail/';

  constructor(private http: HttpClient) {
  }

  public createEmployee(command: CreateEmployeeCommand): Observable<string> {
    return this.http.post(this.url + '/create', command, {responseType: 'text'});
  }

  public updateEmployee(command: UpdateEmployeeCommand): Observable<string> {
    return this.http.post(this.url + '/update', command, {responseType: 'text'})
  }

  public deleteEmployee(command: DeleteEmployeeCommand): Observable<string> {
    return this.http.post(this.url + '/delete', command, {responseType: 'text'})
  }

  public getEmployeeSearch(params: Object): Observable<PagedResponse<SearchEmployee>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchEmployee>>(`${this.url}/search/all`, {params: httpParams});
  }

  public getEmployee(employeeId: string): Observable<ViewEmployee> {
    return this.http.get<ViewEmployee>(this.url + '/' + employeeId);
  }

  public getEquipmentAssignedToEmployee(employeeId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/' + employeeId + '/equipments-assigned');
  }

  public getDocuments(employeeId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(this.url + '/' + employeeId + '/documents');
  }

  public getDocumentsRenewal(employeeId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(this.url + '/' + employeeId + '/qualifications/documents');
  }

  public addDocument(document: AttachedDocument, employeeId: string): Observable<string> {
    const command: UploadEmployeeDocumentCommand = new UploadEmployeeDocumentCommand(employeeId, document);
    return this.http.post(this.url + '/add-document', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteDocument(command: DeleteEmployeeDocumentCommand): Observable<string> {
    return this.http.post(this.url + '/delete-document', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteDocumentRenewal(command: DeleteEmployeeDocumentCommand): Observable<string> {
    const commandExt = DeleteEmployeeDocumentRenewalCommand.convert(command);
    return this.http.post(this.url + '/qualifications/delete-document', JSON.stringify(commandExt), {responseType: 'text'});
  }

  public updateDocumentName(command: UpdateEmployeeDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentNameRenewal(command: UpdateEmployeeDocumentNameCommand): Observable<string> {
    const commandExt = UpdateEmployeeDocumentNameRenewalCommand.convert(command);
    return this.http.post(`${this.url}/qualifications/update-document-name`, JSON.stringify(commandExt), {responseType: 'text'});
  }

  public updateDocumentDescription(command: UpdateEmployeeDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentDescriptionRenewal(command: UpdateEmployeeDocumentDescriptionCommand): Observable<string> {
    const commandExt = UpdateEmployeeDocumentDescriptionRenewalCommand.convert(command);
    return this.http.post(`${this.url}/qualifications/update-document-description`, JSON.stringify(commandExt), {responseType: 'text'});
  }

  public setThumbnail(command: SetEmployeeThumbnailCommand): Observable<string> {
    return this.http.post(this.url + '/set-thumbnail', JSON.stringify(command), {responseType: 'text'});
  }

  public getUrl(): string {
    return this.url;
  }

  public deactivateEmployee(command: DeactivateEmployeeCommand): Observable<string> {
    return this.http.post(this.url + '/deactivate', JSON.stringify(command), {responseType: 'text'});
  }

  public activateEmployee(command: ActivateEmployeeCommand): Observable<string> {
    return this.http.post(this.url + '/activate', JSON.stringify(command), {responseType: 'text'});
  }

  public saveLabel(command: AddEmployeeLabelCommand): Observable<string> {
    return this.http.put(this.url + '/add-label', JSON.stringify(command), {responseType: 'text'});
  }

  public removeLabel(command: RemoveEmployeeLabelCommand): Observable<string> {
    return this.http.put(this.url + '/remove-label', JSON.stringify(command), {responseType: 'text'});
  }

  public getCustomerLabels(params?: Object): Observable<CustomerLabel[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<CustomerLabel[]>(this.filterableLabelUrl, {params: httpParams});
  }

  public assignEmployeeToUser(command: AssignEmployeeToUserCommand): Observable<string> {
    return this.http.post(this.url + '/' + command.employeeId + '/assign-to-user', JSON.stringify(command),
      {responseType: 'text'});
  }

  public unassignEmployeeFromUser(command: UnassignEmployeeFromUserCommand): Observable<string> {
    return this.http.post(this.url + '/' + command.employeeId + '/unassign-from-user', JSON.stringify(command),
      {responseType: 'text'});
  }

  public getEmployeeFilters(params: Object): Observable<EmployeeResponseFilterCollectionView> {
    let httpParams = getHttpParameters(params);

    return this.http.get<EmployeeResponseFilterCollectionView>(this.url + '/filter', {params: httpParams});
  }

  public getNotes(employeeId: string): Observable<ViewEmployeeNote[]> {
    return this.http.get<ViewEmployeeNote[]>(`${this.url}/${employeeId}/notes`);
  }

  public addNote(command: CreateEmployeeNoteCommand): Observable<string> {
    return this.http.post(`${this.url}/add-note`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateNote(command: UpdateEmployeeNoteCommand): Observable<string> {
    return this.http.post(`${this.url}/update-note`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteNote(command: DeleteEmployeeNoteCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-note`, JSON.stringify(command), {responseType: 'text'});
  }

  public getEquipmentAssignments(employeeId: string): Observable<ViewEmployeeEquipmentAssignment[]> {
    return this.http.get<ViewEmployeeEquipmentAssignment[]>(`${this.url}/${employeeId}/equipment-assignments`);
  }

  public getAllEmployees(): Observable<ViewEmployee[]> {
    return this.http.get<ViewEmployee[]>(`${this.url}?allOrganisations=true`);
  }

  public getAllEmployeesForTransfer(): Observable<ViewEmployeeBasicData[]> {
    return this.http.get<ViewEmployeeBasicData []>(`${this.url}/employee-basic-data?allOrganisations=false`);
  }

  public employeeStaffNumberInUser(employeeStaffNumber: string): Observable<boolean> {
    let httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('employeeStaffNumber', employeeStaffNumber)
    };
    return this.http.get<boolean>(`${this.url}/employee-staff-number-in-use`, httpOptions);
  }

  public employeeAssignedToProject(employeeId: string): Observable<boolean> {
    let httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('employeeId', employeeId)
    };
    return this.http.get<boolean>(`${this.url}/project-assigned`, httpOptions);
  }
}

