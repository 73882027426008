import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { TechnicalFieldsListComponent } from '../technical-fields/technical-fields-list/technical-field-list.component';
import { TechnicalFieldEditComponent } from '../technical-fields/technical-fields-edit/technical-field-edit-component';
import { TechnicalFieldAddComponent } from '../technical-fields/technical-fields-add/technical-field-add-component';
import { CanActivateChildMobileWebApp } from '../../../core/guards/can-activate-child-mobile-web-app.guard';

const technicalFieldRoutes: Routes = [
  {
    path: 'technicalfields',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: TechnicalFieldsListComponent,
        data: {
          authorities: [Authorities.TECHNICAL_FIELD_VIEW]
        }
      }, {
        path: 'add',
        component: TechnicalFieldAddComponent,
        data: {
          authorities: [Authorities.TECHNICAL_FIELD_CREATE]
        }
      }, {
        path: 'edit/:id',
        component: TechnicalFieldEditComponent,
        data: {
          authorities: [Authorities.TECHNICAL_FIELD_UPDATE]
        }
      }
    ]
  }
];

export const technicalFieldRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(technicalFieldRoutes);
