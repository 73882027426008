import { Pipe, PipeTransform } from '@angular/core';
import { AllMeasuresUnits, } from 'convert-units/definitions/all';
import { UserConfigurationService } from '../services/user-configuration.service';
import { DimensionSystem } from '../enums/dimension-system.enum';
import { measurementConvert, normalizeUnitType, resolvePairImperialUnit } from './dimension-unit.utils';
import { round } from 'lodash';
import { TransferItemUnit } from '../../modules/disposition/contract/transfer-item-unit';

@Pipe({name: 'dimensionUnitConverter'})
export class DimensionUnitConverterPipe implements PipeTransform {

  constructor(private userConfigurationService: UserConfigurationService) {}

  public transform(value: number,
                   systemUnitDimension: string | AllMeasuresUnits | TransferItemUnit,
                   precision: number = 2): number {
    return this.toUserDimensionUnit(value, systemUnitDimension, precision);
  }

  public toUserDimensionUnit(value: number,
                             systemUnitDimension: string | AllMeasuresUnits | TransferItemUnit,
                             precision: number = 2): number {
    if (!value) {
      return value;
    }

    if (this.isMetricSystem()) {
      return round(value, precision);
    }

    let valueUnitType = normalizeUnitType(systemUnitDimension);
    let targetUnitType = resolvePairImperialUnit(valueUnitType);

    if (valueUnitType !== targetUnitType) {
      let convertedValue = measurementConvert(value).from(valueUnitType).to(targetUnitType);
      return round(convertedValue, precision);
    }

    return round(value, precision);
  }

  public toSystemDimensionUnit(value: number,
                               systemUnitDimension: string | AllMeasuresUnits | TransferItemUnit,
                               precision: number = 10): number {
    if (!value || this.isMetricSystem()) {
      return value;
    }

    let valueUnitType = normalizeUnitType(systemUnitDimension);
    let sourceUnitType = resolvePairImperialUnit(valueUnitType);

    if (valueUnitType !== sourceUnitType) {
      let convertedValue = measurementConvert(value).from(sourceUnitType).to(valueUnitType);
      return round(convertedValue, precision);
    }

    return round(value, precision);
  }

  private isMetricSystem(): boolean {
    return this.userConfigurationService.getUserDimensionSystem() === DimensionSystem.METRIC;
  }
}
