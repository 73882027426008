import { FilterValue } from './../filter-value';
import { FilterParams } from '../filter-params.class';
import { FilterTypes } from '../filter-types.enum';
import { ProjectFilterView } from './../filter-view/project-filter-view.interface';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';

export class ProjectFilterViewConverter extends BaseFilterViewConverter<ProjectFilterView> {
  constructor(
    originalFilters: ProjectFilterView[],
    selectedValues: FilterParams,
    type: FilterTypes
  ) {
    super(type, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: ProjectFilterView): FilterValue {
    return {
      displayName: this.getDisplayName(filterItem),
      storeName: filterItem.projectId,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.projectId),
      filterTotal: this.formatCount(filterItem.count)
    }
  }

  private getDisplayName({ projectName, projectCostCenter }: ProjectFilterView): string {
    return [
      projectName,
      projectCostCenter ? `(${projectCostCenter})` : null]
      .filter(Boolean)
      .join(' ');
  }
}
