import { ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { CustomTooltipComponent } from '../components/custom-tooltip/custom-tooltip.component';

@Directive({
  selector: '[bhOverflowTooltip]'
})
export class OverflowTooltipDirective implements OnDestroy {
  @Input('bhOverflowTooltip') public overflowTooltip: string;
  private componentRef: ComponentRef<CustomTooltipComponent>;

  constructor(private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) { }

  public ngOnDestroy(): void {
    this.destroyTooltip();
  }

  @HostListener('mouseenter') public mouseEnter(): void {
    if (this.overflowTooltip && !this.componentRef && this.isOverflow()) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') public mouseLeave(): void {
    this.destroyTooltip();
  }

  private isOverflow(): boolean {
    const { scrollWidth, clientWidth } = this.elementRef?.nativeElement ?? {};
    return scrollWidth > clientWidth;
  }

  private showTooltip(): void {
    if (!this.componentRef) {
      this.componentRef = this.viewContainerRef.createComponent(CustomTooltipComponent);
      this.componentRef.instance.tooltip = this.overflowTooltip;
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties(): void {
    if (this.componentRef) {
      const {left, right, bottom} = this.elementRef.nativeElement.getBoundingClientRect();
      this.componentRef.instance.left = (right - left) / 2 + left;
      this.componentRef.instance.top = bottom;
    }
  }

  private destroyTooltip(): void {
    if (this.componentRef) {
      this.viewContainerRef.remove();
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
