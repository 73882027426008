<mat-card class="entity-creation">

  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>edit</mat-icon>
      <span>{{'modules.organisation.partnerAddEdit.editPartner'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.save'|translate}} |
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.close'|translate}}</button>
    </div>

    <div class="entity-creation-form" fxFlex="100">

      <form [formGroup]="partnerEditForm" novalidate>
        <div fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="start">

          <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start">
            <div class="entity-creation-form-left" fxFlex="70">
              <div class="entity-creation-form-title">
                {{'modules.organisation.partnerAddEdit.partnerBaseData'|translate|uppercase}}
              </div>

              <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
                <mat-form-field fxFlex="50">
                  <mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
                  <input matInput bhRemoveWhitespaces formControlName="partnerName">

                  <mat-error
                    *ngIf="partnerName.hasError('required')">
                    {{'shared.validation.required'|translate:{value: 'general.name.s'|translate} }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div formGroupName="partnerAddress" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start"
                   fxFill>
                <mat-form-field fxFlex="25">
                  <mat-label>{{'general.address.street'|translate|uppercase}}</mat-label>
                  <input matInput
                         formControlName="street"
                         [maxlength]="fieldLimit.STREET">
                </mat-form-field>
                <mat-form-field fxFlex="25">
                  <mat-label>{{'general.address.houseNumber'|translate|uppercase}}</mat-label>
                  <input matInput
                         formControlName="streetNumber"
                         [maxlength]="fieldLimit.STREET_NUMBER">
                </mat-form-field>
                <mat-form-field fxFlex="25">
                  <mat-label>{{'general.address.postCode'|translate|uppercase}}</mat-label>
                  <input matInput
                         formControlName="postalCode"
                         [maxlength]="fieldLimit.POSTAL_CODE">
                </mat-form-field>
                <mat-form-field fxFlex="25">
                  <mat-label>{{'general.address.city'|translate|uppercase}}</mat-label>
                  <input matInput
                         formControlName="city"
                         [maxlength]="fieldLimit.CITY">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="isAllowedToManageWhitelabel()" fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex="70">
              <div class="entity-creation-form-left" fxFlex="25" fxFlex.lt-lg="50">
                <div class="entity-creation-form-title">
                  {{'modules.organisation.partnerAddEdit.choosePartnerTheme'|translate|uppercase}}*
                </div>
                <bh-partner-theme-choose formControlName="partnerTheme"></bh-partner-theme-choose>
              </div>

              <div class="entity-creation-form-left" fxFlex="25">
                <mat-form-field>
                  <mat-label>{{'modules.organisation.partnerAddEdit.choosePartnerMailServer'|translate|uppercase}}</mat-label>
                  <mat-select formControlName="partnerMailServer">
                    <mat-option *ngFor="let mailServer of mailServers" [value]="mailServer.value">
                      {{ mailServer.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="entity-creation-form-left" fxFlex="25">
                <mat-form-field>
                  <mat-label>{{'modules.organisation.partnerAddEdit.chooseRealm'|translate|uppercase}}*</mat-label>
                  <mat-select formControlName="partnerRealm" disabled>
                    <mat-option *ngFor="let realm of realms" [value]="realm.value">
                      {{ realm.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>

  </mat-card-content>

</mat-card>
