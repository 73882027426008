<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="confirmTitle">{{confirmTitle}}</h1>
  <div mat-dialog-content class="message">
    {{confirmMessage}}

    <div *ngIf="secondaryConfirmMessage" class="message secondary">
      {{secondaryConfirmMessage}}
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="isDenyButtonAvailable" mat-raised-button mat-dialog-close="no">{{denyLabel}}</button>
    <button mat-raised-button color="primary" mat-dialog-close="yes">{{confirmLabel}}</button>
  </div>
</div>
