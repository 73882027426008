import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { ManufacturerEditComponent } from '../manufacturers/manufacturer-edit/manufacturer-edit.component';
import { ManufacturerAddComponent } from '../manufacturers/manufacturer-add/manufacturer-add.component';
import { ManufacturerListComponent } from '../manufacturers/manufacturer-list/manufacturer-list.component';
import { CanActivateChildMobileWebApp } from '../../../core/guards/can-activate-child-mobile-web-app.guard';

const manufacturerRoutes: Routes = [
  {
    path: 'manufacturers',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: ManufacturerListComponent,
        data: {
          authorities: [Authorities.MANUFACTURER_VIEW]
        }
      },
      {
        path: 'add',
        component: ManufacturerAddComponent,
        data: {
          authorities: [Authorities.MANUFACTURER_CREATE]
        }
      },
      {
        path: 'edit/:id',
        component: ManufacturerEditComponent,
        data: {
          authorities: [Authorities.MANUFACTURER_UPDATE]
        }
      },
    ]
  }
];

export const manufacturerRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(manufacturerRoutes);
