import { AfterViewInit, Directive, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ColumnConfig } from '../../interfaces/column-config.interface';
import { ColumnConfigsController } from './controllers/column-config-controller.class';
import { MatSortController } from './controllers/mat-sort-controller.class';
import { MatTableController } from './controllers/mat-table-controller.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResizeColumnMouseDownEvent } from '../../interfaces/resize-column-mouse-down-event.interface';
import { ControllerFactory } from './controllers/controller-factory.class';
import { Observable } from 'rxjs';


@UntilDestroy()
@Directive({
  selector: '[bhResizeColumn]',
})
export class ResizeColumnDirective implements OnInit, AfterViewInit, OnDestroy {
  private readonly handlerOnResizeClass = 'on-resize';

  @Input() minWidth = 65;
  @Input() set resizeColumnConfigs(value: ColumnConfig[]) {
    this.columnConfigs = value;
    this.columnController.setColumnConfigs(this.columnConfigs);
  }
  @Output() resizeColumnEnd = new EventEmitter<ColumnConfig[]>();

  public readonly resizeColumnState: Observable<Record<string, any & ColumnConfig>>;

  private columnController: ColumnConfigsController;
  private sortController: MatSortController;
  private tableController: MatTableController;
  private columnConfigs: ColumnConfig[];
  private startX: number;
  private startWidth: number;
  private pressed = false;

  constructor(
    private matTable: MatTable<any>,
    private renderer: Renderer2,
    private zone: NgZone,
    private matSort: MatSort
  ) {
    this.columnController = ControllerFactory.createColumnConfigsController(this.matTable, this.renderer, this.zone);
    this.resizeColumnState = this.columnController.columnState.asObservable();
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public ngAfterViewInit(): void {
    this.tableController = ControllerFactory.createMatTableController(this.matTable);
    this.sortController = ControllerFactory.createMatSortController(this.matSort);
  }

  public ngOnDestroy(): void {
    this.columnController.destroy();
  }

  private initListeners(): void {
    this.matTable.contentChanged
      .pipe(untilDestroyed(this))
      .subscribe(() => setTimeout(() => this.columnController.checkChanges()));

    this.columnController.mouseDown
      .pipe(untilDestroyed(this))
      .subscribe(payload => this.onResizeStart(payload));
  }

  private onResizeStart({ column, handler, event }: ResizeColumnMouseDownEvent): void {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = handler.parentElement.clientWidth;

    this.sortController.off();
    this.tableController.fixWidth();
    this.renderer.addClass(handler, this.handlerOnResizeClass);
    this.initListenersResizing(handler, column);
  }

  private initListenersResizing(handler: HTMLElement, column: string): void {
    this.zone.runOutsideAngular(() => {
      const resizableMousemove = this.renderer.listen('document', 'mousemove', (event: MouseEvent) => {
        if (this.pressed && event.buttons) {
          const dx = event.pageX - this.startX;
          const width = this.startWidth + dx;
          this.setColumnWidth(column, width);
        }
      });

      const resizableMouseup = this.renderer.listen('document', 'mouseup', () => {
        if (this.pressed) {
          this.pressed = false;
          resizableMousemove();
          resizableMouseup();
          this.resizeEnd(handler, column);
        }
      });
    });
  }

  private setColumnWidth(column: string, width: number) {
    if (width > this.minWidth) {
      this.columnController.setWidth(column, width);
    }
  }

  private resizeEnd(handler: HTMLElement, column: string): void {
    setTimeout(() => {
      this.tableController.unfixWidth();
      this.sortController.on();
      this.renderer.removeClass(handler, this.handlerOnResizeClass);
    });
    this.resizeColumnEnd.emit([this.columnController.getConfigByName(column)]);
  }

}
