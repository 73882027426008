import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { LanguageService } from 'app/shared/services/language.service';
import _ from 'lodash';
import { BehaviorSubject, EMPTY, map, Observable, of, switchMap, tap } from 'rxjs';
import { MaintenanceCategory } from '../../../../../../shared/enums/maintenance-category.enum';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { dialogResults } from '../../../../../../shared/enums/dialogResults.enum';
import {
  DeleteReminderAfterCompletionCommand
} from '../../../../../equipment/contract/delete-reminder-after-completion.command';
import {
  DeleteMaintenanceTaskAfterCompletionCommand
} from '../../../../../equipment/contract/delete-maintenance-task-after-completion.command';
import {
  ConfirmationDialogComponent
} from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MaintenanceService } from '../../../../../equipment/shared/maintenance.service';
import { MaintenanceDatasourceMediatorService } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator.service';

export class MaintenanceTaskCompletedBaseDataSource {
  protected _pagination: BehaviorSubject<PaginationInfo> = new BehaviorSubject(this.getDefaultPagination());
  public readonly pagination = this._pagination.asObservable();

  constructor(
    protected router: Router,
    protected dialog: MatDialog,
    protected maintenanceService: MaintenanceService,
    protected maintenanceMediator: MaintenanceDatasourceMediatorService,
    private languageService: LanguageService,
    private defaultPageSize: number = 25,
  ) {}

  public getIndex(): Observable<number> {
    return this.pagination.pipe(map(({ index }) => index));
  }

  public getSize(): Observable<number> {
    return this.pagination.pipe(map(({ size }) => size));
  }

  public getLength(): Observable<number> {
    return this.pagination.pipe(map(({ totalElements }) => totalElements));
  }

  public resetPagination(): void {
    this._pagination.next(this.getDefaultPagination());
  }

  public editCompletedTask(taskId: string, taskCategory: MaintenanceCategory): void {
    if (Boolean(taskId)
      && (taskCategory === MaintenanceCategory.CHECK || taskCategory === MaintenanceCategory.CHECK_AND_REPAIR)) {
      this.router.navigate(['maintenance', 'tasks', 'edit', taskId]);
    }
  }

  public deleteCompletedTask(taskId: string, taskCategory: MaintenanceCategory, equipmentId: string): Observable<string> {
    let result: Observable<string>;
    switch (taskCategory) {
      case MaintenanceCategory.REMINDER:
        result = this.deleteCompletedReminder(taskId, equipmentId);
        break;
      case MaintenanceCategory.CHECK:
      case MaintenanceCategory.CHECK_AND_REPAIR:
        result = this.deleteCompletedMaintenanceTask(taskId);
        break;
    }

    return result
      ? result.pipe(tap(() => this.maintenanceMediator.notifyCompletedTaskDeleted(this, taskId)))
      : EMPTY;
  }

  private deleteCompletedReminder(reminderId: string, equipmentId: string): Observable<string> {
    return this.openConfirmationDialog(
      this.translate('modules.equipment.confirmation.title.deleteReminder'),
      this.translate('modules.equipment.confirmation.message.processIsIrrevocable'))
    .pipe(
      switchMap((dialogResult) => dialogResult === dialogResults.YES ? of(dialogResult) : EMPTY),
      switchMap(() => {
        const cmd = new DeleteReminderAfterCompletionCommand(reminderId, equipmentId);
        return this.maintenanceService.deleteReminderAfterCompletion(cmd);
      }),
    );
  }

  private deleteCompletedMaintenanceTask(maintenanceTaskId: string): Observable<string> {
    return this.openConfirmationDialog(
      this.translate('modules.equipment.confirmation.title.deleteMaintenanceEntry'),
      this.translate('modules.maintenance.confirmation.message.deleteCompletedTask'))
    .pipe(
      switchMap((dialogResult) => dialogResult === dialogResults.YES ? of(dialogResult) : EMPTY),
      switchMap(() => {
        const cmd = new DeleteMaintenanceTaskAfterCompletionCommand(maintenanceTaskId);
        return this.maintenanceService.deleteMaintenanceTaskAfterCompletion(cmd);
      }),
    );
  }

  private openConfirmationDialog(title: string, message: string): Observable<dialogResults> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = title;
    dialogRef.componentInstance.confirmMessage = message;
    return dialogRef.afterClosed();
  }

  protected updatePagination(index: number, size: number): void {
    if (_.isNil(index) || _.isNil(size)) {
      return;
    }
    this._pagination.next({
      ...this._pagination.getValue(),
      index,
      size
    });
  }

  protected updatePaginationFromResponse(response: PagedResponse<any>): void {
    const { totalElements, totalPages, size, number: index, numberOfElements, last } = response ?? {};
    this._pagination.next({ totalElements, totalPages, size, index, numberOfElements, last });
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private getDefaultPagination(): PaginationInfo {
    return {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.getValue().size || this.defaultPageSize,
      index: 0,
      numberOfElements: 0,
    };
  }
}
