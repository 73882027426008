import { KeycloakService } from 'app/core/keycloak';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormControl } from '@angular/forms';
import { DraggableItemType } from 'app/shared/enums/draggable-item-type.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { Sort } from '@angular/material/sort';
import { SortOrderOption } from '../../../../../shared/contract/sort-order-option.interface';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SortOrderComponent } from 'app/shared/components/sort-order/sort-order.component';
import { DragDropContainerIdentifier } from 'app/shared/enums/drag-drop-container-identifier.enum';
import { TransportSearch } from 'app/modules/transportation/contracts/transport/transport-search.interface';
import { TransportDetailViewService } from 'app/modules/transportation/shared/services/transport-detail-view.service';
import { TransportBoardDataSource } from '../../../shared/services/transport-board.datasource';
import { getBrowserName } from 'app/shared/utils';
import { BrowserName } from '../../../../../shared/enums/browser-name.enum';
import { TransportColumnService } from 'app/modules/transportation/shared/services/transport-column.service';
import { ColumnDefinition } from 'app/shared/column-definition';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'bh-transport-list-search',
  templateUrl: './transport-list-search.component.html',
  styleUrls: ['./transport-list-search.component.scss']
})
export class TransportListSearchComponent extends RoleAuthorityGuardsComponent implements OnInit {
  @ViewChild('sortOrder', { read: SortOrderComponent, static: true }) sortOrder: SortOrderComponent;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  public readonly transports = this.transportDataSource.transports;
  public readonly searchControl = new UntypedFormControl();
  public readonly containerIdentifiers = DragDropContainerIdentifier;
  public readonly dragType = DraggableItemType;
  public readonly pagination = this.transportDataSource.pagination;
  public selectedColumns: ColumnDefinition[];
  public readonly isDataLoading = combineLatest([
    this.transportDataSource.transportRequestInProgressChanges,
    this.transportDataSource.listingRequestInProgress
  ]).pipe(map((loadStatuses) => loadStatuses.some(Boolean)), distinctUntilChanged());

  private defaultSortOrderOption: SortOrderOption = {
    value: 'priority',
    label: this.translate('modules.transportation.base.priority')
  };
  public readonly sortOrderOptions: SortOrderOption[] = [
    this.defaultSortOrderOption,
    { value: 'dueDate', label: this.translate('modules.transportation.base.dueDate') },
    { value: 'title', label: this.translate('modules.transportation.base.title') },
  ];

  private dragIsEnd: boolean;
  private isFirefox: boolean;

  constructor(
    public transportDataSource: TransportBoardDataSource,
    protected authService: KeycloakService,
    private languageService: LanguageService,
    private columnService: TransportColumnService,
    private transportDetailViewService: TransportDetailViewService
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.setDefaultState();
    this.initListeners();
    this.columnService.selectedColumns
      .pipe(untilDestroyed(this))
      .subscribe((selectedColumns: ColumnDefinition[]) => this.selectedColumns = [...selectedColumns]);
    this.columnService.transportListViewPageSize
      .pipe(untilDestroyed(this))
      .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
    this.isFirefox = getBrowserName() === BrowserName.FIREFOX;
  }

  public onPaginateChange({ pageIndex, pageSize }: PageEvent): void {
    this.transportDataSource.updateListing(pageIndex, pageSize);
  }

  public onSortChange(event: Sort): void {
    this.transportDataSource.sort = event.direction ? event : null;
    this.transportDataSource.updateListing(0);
  }

  public onSearch(): void {
    this.searchByTerm(this.searchControl.value);
  }

  public onResetSearchTerm(): void {
    this.searchControl.reset();
  }

  public openDetailView(trasportTask: TransportSearch): void {
    if (!this.dragIsEnd) {
      this.transportDetailViewService.openDetailView(trasportTask.transportId);
    }
  }

  public dragEnded(): void {
    if (this.isFirefox) {
      this.dragIsEnd = true;
      setTimeout(() => {
        this.dragIsEnd = false;
      }, 200);
    }
  }

  private setDefaultState(): void {
    this.setDefaultSortOrder();
    this.setDefaultSearchTerm();
    this.transportDataSource.updateListing();
  }

  private setDefaultSortOrder(): void {
    this.transportDataSource.sort = this.transportDataSource.sort
      ||  { active: this.defaultSortOrderOption.value, direction: 'desc' };

    this.sortOrder.sort = this.transportDataSource.sort;
  }

  private setDefaultSearchTerm(): void {
    this.searchControl.setValue(this.transportDataSource.searchTerm, { emitEvent: false });
  }

  private initListeners(): void {
    this.initSearchTermListener();
  }

  private initSearchTermListener(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(searchTerm => this.searchByTerm(searchTerm));
  }

  private searchByTerm(searchTerm: string): void {
    this.transportDataSource.searchTerm = searchTerm;
    this.transportDataSource.updateListing(0);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
