import {KeycloakService} from '../../../../core/keycloak';
import {LanguageService} from '../../../../shared/services/language.service';
import { RealmType } from '../../../../shared/contract/realm-type';
import {GuardedNavigableInputComponent} from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterHistory} from '../../../../shared/router-history';
import {RoleRealmDefinition} from '../../shared/interfaces/role-realm-definition.interface';

export abstract class RoleBaseComponent extends GuardedNavigableInputComponent {
  public realms: RoleRealmDefinition[] = [];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, activatedRoute, routerHistory);

    this.realms = [
      {
        value: RealmType.DEFAULT,
        name: this.translate('modules.userrole.roleRealm.default')
      },
      {
        value: RealmType.WACKER_NEUSON,
        name: this.translate('modules.userrole.roleRealm.wackerNeuson')
      },
      {
        value: RealmType.TRIATHLON,
        name: this.translate('modules.userrole.roleRealm.triathlon')
      }
    ];
  }

  protected translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
