<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" >
  <div class="m-list-content-wrapper" fxFlex>
    <mat-list class="m-list-content"
              fxFlexFill
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="false">
      <mat-list-item class="m-list-content__item"
                     *ngFor="let equipment of equipmentList; let i = index">
        <p matListItemTitle class="line-one">{{equipment.equipmentTypeCategory2 | uppercase}}</p>
        <div fxLayout="row" fxLayoutAlign="end end">
          <button mat-button mat-icon-button [attr.aria-label]="'general.buttons.delete'|translate" (click)="deleteEquipment(i)">
            <mat-icon class="gray-color">delete_forever</mat-icon>
          </button>
        </div>
        <p matListItemLine class="line-two">{{equipment | equipmentName}}</p>
        <p matListItemLine class="line-three">{{equipment.equipmentCustomerSerialNumber
          ? equipment.equipmentCustomerSerialNumber
          : 'modules.equipment.assignMultipleEquipments.noSerialNumber'|translate}}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
  <a mat-fab color="primary" class="m-view-float-button" (click)="addEquipment()"><mat-icon>add</mat-icon></a>
  <a mat-button (click)="listFinished()" class="m-button-full-width" *ngIf="equipmentList.length > 0">
    {{'modules.equipment.assignMultipleEquipments.scheduling'|translate}}
    <mat-icon [attr.aria-label]="'modules.equipment.assignMultipleEquipments.scheduling'|translate">done</mat-icon>
  </a>
</div>
