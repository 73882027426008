import { Directive, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { FormControlStatus, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@UntilDestroy()
@Directive({
  selector: '[bhHasAsyncValidationError]'
})
export class HasAsyncValidationErrorDirective implements OnInit {
  @Input('bhHasAsyncValidationError') public trackErrors: string[] = [];
  @Output() public hasErrors = new EventEmitter<string[]>();

  constructor(@Optional()@Self() private ngControl: NgControl) {}

  public ngOnInit(): void {
    if (this.ngControl) {
      this.initStatusChangesListener();
    }
  }

  private initStatusChangesListener(): void {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(status => this.processStatus(status));
  }

  private processStatus(status: FormControlStatus): void {
    switch (status) {
      case 'VALID':
      case 'DISABLED': {
        this.hasErrors.emit([]);
        break;
      }
      case 'INVALID': {
        this.hasErrors.emit(
          (this.trackErrors || []).filter(error => this.ngControl.hasError(error))
        );
        break;
      }
    }
  }

}
