export class FixedDateInterval {

  static from(dateFormValue: any): FixedDateInterval {
    return new FixedDateInterval(
        dateFormValue.day,
        +dateFormValue.month.value,
        dateFormValue.mediumWarningThreshold,
        dateFormValue.highWarningThreshold);
  }

  constructor(
      public day: number,
      public month: number,
      public mediumWarningThreshold: number,
      public highWarningThreshold: number) {}
}
