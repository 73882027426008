import { TransportViewComponent } from './transport/transport-list/transport-view/transport-view.component';
import { SearchFilterComponent } from 'app/shared/components/search-filter-component/landscape/search-filter.component';
import { TransportationBoardTimelineComponent } from './transport/transportation-board/transportation-board-timeline/transportation-board-timeline.component';
import { ContentComponent } from 'app/shared/components/content-component/content.component';
import { TransportationBoardComponent } from './transport/transportation-board/transportation-board.component';
import { CanActivateModule } from '../../core/guards/can-activate-module';
import { ModuleWithProviders } from '@angular/core';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { CanActivateChildAuthority } from 'app/core/guards/can-activate-child-authority';
import { RouterModule, Routes } from '@angular/router';
import { Modules } from 'app/shared/enums/modules.enum';
import { TransportPageRerouteGuard } from './shared/guards/transport-page-reroute.guard';
import { TransportListComponent } from './transport/transport-list/transport-list.component';
import { TransportViewDetailComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-detail.component';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';


const transportationBoardChildrenDefault: Routes = [
  {
    path: '',
    component: SearchFilterComponent,
    data: {
      authorities: [Authorities.TRANSPORT_TASK_DISPOSITION],
      hideRouteButton: true
    },
    children: [
      {
        path: '',
        component: TransportationBoardTimelineComponent,
        data: {
          authorities: [Authorities.TRANSPORT_TASK_DISPOSITION]
        },
      }
    ]
  }
];

const transportationRoutes: Routes = [{
  path: 'transportation',
  component: ContentComponent,
  canActivate: [CanActivateModule, TransportPageRerouteGuard],
  canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
  data: {
    modules: [Modules.TRANSPORT_TASK_MANAGEMENT]
  },
  runGuardsAndResolvers: 'always',
  children: [
    {
      path: 'add',
      component: ContentComponent,
      data: {
        authorities: [Authorities.TRANSPORT_TASK_DISPOSITION, Authorities.TRANSPORT_TASK_MANAGE],
      },
    },
    {
      path: 'board',
      component: TransportationBoardComponent,
      data: {
        authorities: [Authorities.TRANSPORT_TASK_DISPOSITION]
      },
      children: [...transportationBoardChildrenDefault]
    },
    {
      path: 'board/add',
      component: TransportationBoardComponent,
      data: {
        authorities: [Authorities.TRANSPORT_TASK_DISPOSITION],
        create: true
      },
      children: [...transportationBoardChildrenDefault]
    },
    {
      path: 'list/add',
      component: SearchFilterComponent,
      data: {
        authorities: [Authorities.TRANSPORT_TASK_DISPOSITION, Authorities.TRANSPORT_TASK_MANAGE]
      },
      children: [
        {
          path: '',
          component: TransportListComponent,
          data: {
            authorities: [Authorities.TRANSPORT_TASK_DISPOSITION, Authorities.TRANSPORT_TASK_MANAGE],
            create: true
          },
        }
      ]
    },
    {
      path: 'list',
      component: SearchFilterComponent,
      data: {
        authorities: [
          Authorities.TRANSPORT_TASK_DISPOSITION,
          Authorities.TRANSPORT_TASK_MANAGE,
          Authorities.TRANSPORT_TASK_VIEW],
        hideRouteButton: true
      },
      children: [
        {
          path: '',
          component: TransportListComponent,
          data: {
            authorities: [
              Authorities.TRANSPORT_TASK_DISPOSITION,
              Authorities.TRANSPORT_TASK_MANAGE,
              Authorities.TRANSPORT_TASK_VIEW]
          },
          children: [{
            path: '',
            component: TransportViewComponent,
            data: {
              authorities: [
                Authorities.TRANSPORT_TASK_DISPOSITION,
                Authorities.TRANSPORT_TASK_MANAGE,
                Authorities.TRANSPORT_TASK_VIEW
              ],
            },
            children: [{
              path: ':id',
              component: TransportViewDetailComponent,
              data: {
                authorities: [
                  Authorities.TRANSPORT_TASK_DISPOSITION,
                  Authorities.TRANSPORT_TASK_MANAGE,
                  Authorities.TRANSPORT_TASK_VIEW
                ],
              },
            }]
          }]
        }
      ]
    },
    { path: '**', redirectTo: '' }
  ]
}];

export const transportationRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(transportationRoutes);
