import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faAngleDown, faAngleUp, faClipboardList, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { KeycloakService } from 'app/core/keycloak';
import { SearchEquipment } from 'app/modules/equipment/contract/search-equipment.interface';
import { EquipmentCheckerService } from 'app/modules/equipment/shared/services/equipment-checker.service';
import { ColumnDefinition } from 'app/shared/column-definition';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { DisplayService } from 'app/shared/display.service';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from 'app/shared/router-history';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EquipmentTransferDatasource } from '../../shared/equipment-transfer.datasource';
import { EquipmentTransferColumnService } from '../../shared/equipment-transfer-column.service';
import { TransferCartDatasource } from '../../shared/transfer-cart.datasource';
import { animate, state, style, transition, trigger } from '@angular/animations';
import _ from 'lodash';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { CustomerLabel } from 'app/shared/contract/customer-label.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SearchStockEquipmentAmount } from '../../contract/search-stock-equipment-amount.interface';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { TabService } from '../../../../shared/tab.service';
import { ListType } from '../../../../shared/enums/list-type.enum';
import { UpdateFilterCommand } from '../../../../shared/contract/filter/update-filter-command.interface';
import { SearchFilterActivatedView } from '../../../../shared/contract/activated-view.interface';
import { ButtonInfo } from '../../../../shared/contract/button-info';
import { environment } from '../../../../../environments/environment';
import { EquipmentLocationType } from 'app/modules/maintenance/shared/contract/equipment-location-type-enum';
import { COLUMN_TYPE } from '../../../../shared/constants/column-definition-constants';
import { TableConfigurationData, TableConfigurationDialogComponent } from '../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { ThumbnailSize } from '../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';
import { ColumnDefinitionKeys } from 'app/shared/enums/column-definition-keys.enum';


@UntilDestroy()
@Component({
  selector: 'bh-equipment-transfer-list',
  templateUrl: './equipment-transfer-list.component.html',
  styleUrls: ['./equipment-transfer-list.component.scss'],
  animations: [
    trigger('rowsExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', borderWidth: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
})
export class EquipmentTransferListComponent extends GuardedNavigableInputComponent implements SearchFilterActivatedView,
  OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  public selectedColumns: ColumnDefinition[];
  public searchForm: UntypedFormGroup;
  public displayedColumns: string[];
  public allAmounts: SearchStockEquipmentAmount[] = [];
  public expandedAmounts: SearchStockEquipmentAmount[] = [];
  public previousExpandedAmounts: SearchStockEquipmentAmount[] = [];
  public organisations: ViewOrganisation[] = [];
  public labels: CustomerLabel[] = [];
  public routeButton: ButtonInfo = null;
  public defaultSort: Sort = { active: null, direction: null };

  public readonly faClipBoardList: IconDefinition = faClipboardList;
  public readonly faAngleUp: IconDefinition = faAngleUp;
  public readonly faAngleDown: IconDefinition = faAngleDown;
  public readonly faCheckCircle: IconDefinition = faCheckCircle;
  public readonly faTimesCircle: IconDefinition = faTimesCircle;
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly equipmentLocationType = EquipmentLocationType;
  public readonly ThumbnailSize = ThumbnailSize;
  public readonly COLUMN_SUB_EQUIPMENT = ColumnDefinitionKeys.SUB_EQUIPMENT;
  public readonly searchSuggestionsField = 'equipmentTransferListSuggestions';


  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  @ViewChild('configBtn') private configBtn: HTMLElement;
  public filters: FilterType[] = this.equipmentTransferStore.filters;
  public onFiltersUpdated = this.equipmentTransferStore.onFilterUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  protected componentActive = true;
  private displayModeBeforeInfo = {table: false, split: false, details: false};

  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  constructor(public displayService: DisplayService,
    public equipmentCheckerService: EquipmentCheckerService,
    public columnService: EquipmentTransferColumnService,
    public equipmentTransferStore: EquipmentTransferDatasource,
    public transferCartStore: TransferCartDatasource,
    protected router: Router,
    protected authService: KeycloakService,
    protected route: ActivatedRoute,
    protected routerHistory: RouterHistory,
    private dialog: MatDialog,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private tabService: TabService) {
      super(authService, router, route, routerHistory);
    }

  ngOnInit() {
    this.equipmentTransferStore.updateFilters();
    this.equipmentTransferStore.updateListing();
    this.setDefaultSort();
    this.saveAndModifyDisplayMode();

    this.columnService.selectedColumns.pipe(untilDestroyed(this)).subscribe(selectedColumns => this.selectedColumns = selectedColumns);
    this.equipmentTransferStore.amounts.pipe(untilDestroyed(this)).subscribe(amounts => {
      this.allAmounts = amounts;
      if (this.equipmentTransferStore.searchTerms) {
        this.expandedAmounts = this.allAmounts.filter(equipment => equipment.subEquipmentList);
      } else {
        this.expandedAmounts = this.previousExpandedAmounts;
      }
      this.checkSubEquipmentColumn();
    });
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe(pageSize => this.paginator.pageSize = pageSize);
    this.columnService.displayedColumns.pipe(untilDestroyed(this)).subscribe((columns: string[]) => {
      this.displayedColumns = _.cloneDeep(columns);
      this.checkSubEquipmentColumn();
    });
  }

  public ngAfterViewInit(): void {
    this.tabService.changeTransferTab(ListType.EQUIPMENT_TRANSFER);
  }

  public ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.componentActive = false;
    this.resetDisplayMode();
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public selectEquipment(equipment: SearchStockEquipmentAmount): void {
    if (this.subequipmentsExist(equipment)) {
      let index = this.expandedAmounts.findIndex(amount => amount.equipmentId === equipment.equipmentId);
      index !== -1
        ? this.expandedAmounts.splice(index, 1)
        : this.expandedAmounts.push(equipment);
      this.previousExpandedAmounts = this.expandedAmounts;
    }
  };

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.equipmentTransferStore.updateFilterParams(commands);
    this.equipmentTransferStore.updateListing(0);
  }

  public hasAnySubequipment(): boolean {
    return this.allAmounts.some(eq => eq.subEquipmentList);
  }


  public openTransferCart(): void {
    if (!this.transferCartStore.transferCartEmpty()) {
      this.router.navigate(['transfer/stock-to-project-cart']);
    }
  }

  public openConfiguration(): void {
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {columnService: this.columnService} as TableConfigurationData,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((columns: ColumnDefinition[]) => {
      if (columns) {
        this.columnService.selectColumns(columns, this.paginator.pageSize);
        this.equipmentTransferStore.updateListing(0);
      }
      this.focusMonitor.stopMonitoring(this.configBtn);
    });
  }

  public sortData(sort: Sort): void {
    this.equipmentTransferStore.sort = sort;
    this.equipmentTransferStore.updateListing(this.paginator.pageIndex, this.paginator.pageSize);
  }

  public onPaginateChange(event: PageEvent): void {
    this.equipmentTransferStore.updateListing(event.pageIndex, event.pageSize);
  }

  public checkExpanded(element: SearchEquipment): boolean {
    return this.expandedAmounts.findIndex(amoun => amoun.equipmentId === element.equipmentId) !== -1;
  }

  public resolveArrowIcon(equipment: SearchEquipment): IconDefinition {
    return this.expandedAmounts.findIndex(amount => amount.equipmentId === equipment.equipmentId) !== -1
      ? this.faAngleUp
      : this.faAngleDown;
  }

  public subequipmentsExist(equipment: SearchStockEquipmentAmount): boolean {
    return equipment?.subEquipmentList?.length > 0;
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(searchTerm => this.updateOnSearch(searchTerm));
    this.searchForm.patchValue({terms: this.equipmentTransferStore.searchTerms}, {emitEvent: false});
  }

  public updateOnSearch(searchTerm: string): void {
    this.equipmentTransferStore.searchTerms = searchTerm;
    this.equipmentTransferStore.updateListing(0);
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public getFirstLabels(equipment: SearchEquipment, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.labels];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(equipment: SearchEquipment, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.labels];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }

  public setSearchTerms(emitEvent = true): void {
    this.searchForm.patchValue({terms: this.equipmentTransferStore.searchTerms}, {emitEvent});
  }

  private saveAndModifyDisplayMode(): void {
    this.displayModeBeforeInfo = {
      table: this.displayService.isTableFullscreen(),
      split: this.displayService.isTableAndDetails(),
      details: this.displayService.isDetailsFullscreen(),
    };
    this.displayService.displayTableFullscreen();
  }

  private resetDisplayMode(): void {
    if (this.displayModeBeforeInfo?.table) {
      this.displayService.displayTableFullscreen();
    } else if (this.displayModeBeforeInfo?.split) {
      this.displayService.displayTableAndDetails();
    } else if (this.displayModeBeforeInfo?.details) {
      this.displayService.displayDetailsFullscreen();
    }
  }

  private setDefaultSort(): void {
    this.defaultSort = {...this.equipmentTransferStore.sort ?? this.defaultSort};
  }

  private checkSubEquipmentColumn(): void {
    if (this.displayedColumns?.length > 0) {
      const hasSubEq = this.hasAnySubequipment();
      const hasSubEqColumn = this.displayedColumns.includes(ColumnDefinitionKeys.SUB_EQUIPMENT);
      if (hasSubEq && !hasSubEqColumn) {
        this.displayedColumns = [
          ...this.displayedColumns.slice(0, this.displayedColumns.length - 1),
          ColumnDefinitionKeys.SUB_EQUIPMENT,
          this.displayedColumns[this.displayedColumns.length - 1]
        ];
      } else if (!hasSubEq && hasSubEqColumn) {
        this.displayedColumns = this.displayedColumns.filter(col => col !== ColumnDefinitionKeys.SUB_EQUIPMENT);
      }
    }
  }
}
