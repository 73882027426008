<div fxLayout="row" fxLayoutAlign="end start">
  <div fxLayout="row" fxLayoutAlign="start end" fxFlex="150px">
    <fa-icon class="sort-icon" [icon]="faSortAlphaDown"></fa-icon>
    <mat-form-field class="no-margin-bottom">
      <mat-label>{{'general.labels.sortBy'|translate}}:</mat-label>
      <mat-select [(value)]="documentsSortParam" (selectionChange)="changeDocumentsSortParameter($event.value)">
        <mat-option [value]="sortOptions.FILE_NAME">{{'general.name.s'|translate}}
        </mat-option>
        <mat-option [value]="sortOptions.FILE_DESCRIPTION">{{'general.description'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="attachments" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="attachment-type-container">
    <ng-container *ngIf="isEditable">
      <bh-subtitle [text]="'general.labels.uploadDocPict'|translate"></bh-subtitle>
      <bh-file-upload
        [itemId]="transportId"
        uploaderType="TRANSPORT_TASK">
      </bh-file-upload>
    </ng-container>
    <ng-template #noUploadAllowed>
      <div class="no-upload">
        {{'shared.attachment.uploadNotAllowed' | translate}}
      </div>
    </ng-template>
  </div>

  <bh-attachment-list-category
    [documents]="documents"
    [deletable]="isDeleteEnabled()"
    [editable]="isEditable"
    [pendingChanges]="waiting"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="saveDescription($event)"
    [editableName]="isEditable"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-category>

  <bh-attachment-list-image-category
    [images]="images"
    [setDefaultEnable]="false"
    [pendingChanges]="waiting"
    [visibleDescription]="true"
    [editableDescription]="isEditable"
    (descriptionChanged)="saveDescription($event)"
    [editableName]="isEditable"
    (nameChanged)="saveFileName($event)"
    [deletable]="isDeleteEnabled()"
    (deleted)="deleteImage($event)">
  </bh-attachment-list-image-category>
</div>
