import { DataSource } from '@angular/cdk/table';
import { DigitalMatterProfile } from '../../../shared/assign-digital-matter-unit/digital-matter-profile';
import { BehaviorSubject, Observable } from 'rxjs';
import { TelematicsService } from '../../equipment/shared/telematics.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DigitalMatterProfileDatasource extends DataSource<DigitalMatterProfile> {
  public readonly data: BehaviorSubject<DigitalMatterProfile[]> = new BehaviorSubject<DigitalMatterProfile[]>([]);
  private dataSource = this.data.asObservable();

  constructor(private telematicsService: TelematicsService) {
    super();
  }

  public fetchAllDigitalMatterProfiles() {
    this.telematicsService.getAllDigitalMatterProfiles()
      .subscribe({
        next: res => this.data.next(res),
        error: () => this.data.next(null)
      });
  }

  public connect(): Observable<DigitalMatterProfile[]> {
    return this.dataSource;
  }

  public disconnect() {
  }
}
