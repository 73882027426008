import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { BaseProjectViewDispositionsComponent } from '../base/base-project-view-dispositions.component';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { MatTab } from '@angular/material/tabs';
import { BulkItemTypeResolver } from '../../../../../bulk-items/bulk-item-add-edit/bulk-item-type.resolver';
import {EquipmentsDataSource} from '../../../../../../equipment/shared/equipments.datasource';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'bh-mobile-project-view-assignments',
  templateUrl: './mobile-project-view-dispositions.component.html',
  styleUrls: ['./mobile-project-view-dispositions.component.scss']
})
@UntilDestroy()
export class MobileProjectViewDispositionsComponent extends BaseProjectViewDispositionsComponent implements OnInit, OnDestroy {

  selectedTab: MatTab;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              public equipmentStore: EquipmentsDataSource,
              private bulkItemTypeResolver: BulkItemTypeResolver,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory, projectStore, equipmentStore, languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }

  navigateToEquipmentAssignments(equipmentId: string): void {
    this.router.navigate([`mobile/equipments/view/${equipmentId}/general`]);
  }
}
