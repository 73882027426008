import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module'
import { SidenavComponent } from './sidenav/landscape/sidenav.component';
import { RouterModule } from '@angular/router';
import { AppInfoComponent } from './app-info/app-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MobileSidenavComponent } from './sidenav/mobile/mobile-sidenav.component';
import { SidenavService } from './sidenav/shared/sidenav.service';
import { ServiceHealthModule } from './service-health/service-health.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomMaterialModule,
    FlexLayoutModule,
    ServiceHealthModule,
    SharedModule,
    TranslateModule
  ],
  exports: [
    SidenavComponent,
    MobileSidenavComponent
  ],
  declarations: [
    SidenavComponent,
    MobileSidenavComponent,
    AppInfoComponent
  ],
  providers: [
    SidenavService
  ]
})
export class NavigationModule {
}
