import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import {BulkItemsService} from '../../modules/disposition/shared/bulk-items.service';

export class BulkItemNumberInUseValidator {
  static isValid(value: string, bulkItemsService: BulkItemsService, oldSerialNumber?: string) {
    if (!value || value === oldSerialNumber) {
      return of(null);
    }
    return bulkItemsService.internalNumberInUse(value)
      .pipe(map(res =>  res ? { taken: true } : null));
  }
}
