import { LanguageService } from './../../../../../../shared/services/language.service';
import { Component } from '@angular/core';
import { BaseBulkItemViewComponent } from '../base/base-bulk-item-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { DisplayService } from '../../../../../../shared/display.service';
import { BulkItemAddEditComponent } from '../../../bulk-item-add-edit/bulk-item-add-edit.component';
import { BulkItemDatasource } from '../../../../shared/bulk-item.datasource';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons';
import { CheckboxConfirmDialogComponent } from '../../../../../../shared/components/checkbox-confirm-dialog/checkbox-confirm-dialog.component';
import { DeleteBulkItemCommand } from '../../../../contract/delete-bulk-item-command';
import {dialogResults} from '../../../../../../shared/enums/dialogResults.enum';
import { BulkItemsService } from '../../../../shared/bulk-items.service';
import { RouterHistory } from '../../../../../../shared/router-history';

@Component({
  selector: 'bh-equipment-view',
  templateUrl: './bulk-item-view.component.html',
  styleUrls: ['./bulk-item-view.component.scss']
})
export class BulkItemViewComponent extends BaseBulkItemViewComponent {

  public readonly faWarehouse: IconDefinition = faWarehouse;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected bulkItemStore: BulkItemDatasource,
              protected bulkItemsService: BulkItemsService,
              protected routerHistory: RouterHistory,
              public displayService: DisplayService,
              private languageService: LanguageService) {
    super(authService, router, route, bulkItemStore, bulkItemsService, routerHistory);
  }


  public showMoreMenu(): boolean {
    return this.hasAuthority(this.authorities.BULK_ITEMS_MANAGE);
  }

  public edit(): void {
    let dialogRef = this.dialog.open(BulkItemAddEditComponent, {autoFocus: false});
    dialogRef.componentInstance.bulkItem = this.bulkItem;
  }

  public delete(bulkItemId: string): void {
    const dialogRef = this.dialog.open(CheckboxConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.bulkItemDelete');
    dialogRef.componentInstance.secondaryConfirmMessage = this.translate('modules.disposition.confirmation.message.bulkItemDeleteSecond');
    dialogRef.componentInstance.buttonMessage = this.translate('general.buttons.delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {

        const cmd = new DeleteBulkItemCommand(bulkItemId);
        this.bulkItemStore.deleteBulkItem(cmd);
      }
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
