import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { ViewTechnicalField } from '../../contract/view-technical-field.interface';
import { TechnicalFieldType } from '../../contract/technical-field-type';
import { TechnicalFieldUnit } from '../../contract/technical-field-unit';
import { UpdatedTechnicalFieldCommand } from '../../contract/updated-technical-field-command';
import { TechnicalFieldsDataSource } from '../../shared/technical-fields.datasource';
import { TooltipPosition } from '@angular/material/tooltip';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-technical-field-edit',
  templateUrl: 'technical-field-edit.component.html',
  styleUrls: ['technical-field-edit.component.scss']
})
export class TechnicalFieldEditComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public technicalFieldEditForm: UntypedFormGroup;
  public technicalFieldTypes: string[] = [];
  public technicalFieldUnits: string[] = [];
  public position: TooltipPosition = 'above';
  public viewTechnicalField: Observable<ViewTechnicalField>;
  public technicalFieldsInUse: Observable<boolean>;
  public readonly fieldLimit = FieldLimit;

  private technicalFieldId: string;

  public isSaveButtonDisabled = false;

  public get technicalFieldKey(): AbstractControl {
    return this.technicalFieldEditForm.controls.technicalFieldKey;
  }

  public get technicalFieldName(): AbstractControl {
    return this.technicalFieldEditForm.controls.technicalFieldName;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private technicalFieldsDataSource: TechnicalFieldsDataSource) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.technicalFieldTypes = (<any>Object).values(TechnicalFieldType);
    this.technicalFieldUnits = (<any>Object).values(TechnicalFieldUnit);
    this.buildForm();

    this.viewTechnicalField = this.technicalFieldsDataSource.currentTechnicalField;
    this.viewTechnicalFieldSubscription();

    this.technicalFieldsInUse = this.technicalFieldsDataSource.currentTechnicalFieldInUse;
    this.technicalFieldsInUseSubscription();

    this.routeParamsSubscription();
    this.disableSaveButtonWhilePrintingInKeyField();
    this.disableSaveButtonWhilePrintingInNameField();
    this.keyChangeSubscription();
    this.nameChangeSubscription();
  }

  ngOnDestroy(): void {
  }

  public navigateBack(): void {
    this.goBack('/technicalfields/list');
  }

  public save(): void {
    this.technicalFieldsDataSource.updateTechnicalField(this.getUpdateTechnicalFieldCommand())
      .subscribe(() => this.navigateAfterSubmit());
  }

  private buildForm() {
    this.technicalFieldEditForm = this.formBuilder.group({
      technicalFieldKey: ['', [Validators.required]],
      technicalFieldName: ['', [Validators.required]],
      technicalFieldType: [{value: '', disabled: false}, [Validators.required]],
      technicalFieldUnit: [{value: '', disabled: false}]
    });
  }

  private viewTechnicalFieldSubscription(): void {
    this.viewTechnicalField
      .pipe(untilDestroyed(this))
      .subscribe(technicalField => this.initializeTechnicalFieldEditForm(technicalField));
  }

  private technicalFieldsInUseSubscription(): void {
    this.technicalFieldsInUse
      .pipe(untilDestroyed(this))
      .subscribe(inUse => {
        if (inUse) {
          this.technicalFieldEditForm.get('technicalFieldType').disable();
          this.technicalFieldEditForm.get('technicalFieldUnit').disable();
        }
      });
  }

  private routeParamsSubscription(): void {
    this.route.params.subscribe(params => {
      const technicalFieldKey = params['id'];
      this.technicalFieldsDataSource.loadTechnicalField(technicalFieldKey);
    });
  }

  private initializeTechnicalFieldEditForm(technicalField: ViewTechnicalField): void {
    this.technicalFieldId = technicalField.technicalFieldId;
    this.technicalFieldEditForm.patchValue({
      technicalFieldKey: technicalField.technicalFieldKey,
      technicalFieldName: technicalField.technicalFieldName,
      technicalFieldType: technicalField.technicalFieldType,
      technicalFieldUnit: technicalField.technicalFieldUnit
    });
  }

  private disableSaveButtonWhilePrintingInKeyField(): void {
    this.technicalFieldKey.valueChanges.subscribe(() => this.isSaveButtonDisabled = true);
  }

  private disableSaveButtonWhilePrintingInNameField(): void {
    this.technicalFieldName.valueChanges.subscribe(() => this.isSaveButtonDisabled = true);
  }

  private keyChangeSubscription(): void {
    this.technicalFieldKey.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe(key => {
        if (key) {
          this.keyExistsCheck();
        }
      });
  }

  private keyExistsCheck(): void {
    this.technicalFieldsDataSource.keyExistsCheck(this.technicalFieldKey.value, true)
      .subscribe(isExist => {
        this.isSaveButtonDisabled = false;
        this.technicalFieldKey.setErrors(isExist ? {keyAlreadyExist: true} : null)
      });
  }

  private nameChangeSubscription(): void {
    this.technicalFieldName.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe(name => {
        if (name) {
          this.nameExistsCheck();
        }
      });
  }

  private nameExistsCheck(): void {
    this.technicalFieldsDataSource.nameExistsCheck(this.technicalFieldName.value, true)
      .subscribe(isExist => {
        this.isSaveButtonDisabled = false;
        this.technicalFieldName.setErrors(isExist ? {nameAlreadyExist: true} : null);
      });
  }

  private getUpdateTechnicalFieldCommand(): UpdatedTechnicalFieldCommand {
    const formValue = this.technicalFieldEditForm.getRawValue();
    const cmd = new UpdatedTechnicalFieldCommand();
    cmd.technicalFieldId = this.technicalFieldId;
    cmd.technicalFieldKey = formValue.technicalFieldKey;
    cmd.technicalFieldName = formValue.technicalFieldName;
    cmd.technicalFieldType = formValue.technicalFieldType;
    cmd.technicalFieldUnit = formValue.technicalFieldUnit;
    return cmd;
  }
}
