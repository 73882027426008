<div class="configurable-fields" fxLayout="row">
  <div fxLayoutAlign="center center" *ngIf="item.icon || item.thumbnailUrl">
    <mat-icon *ngIf="item.icon">item.icon</mat-icon>
    <img [src]="item.thumbnailUrl" *ngIf="item.thumbnailUrl">
  </div>
  <div class="configurable-fields-wrapper">
    <div *ngFor="let items of item.fields"
         fxLayout="row"
         fxLayoutAlign="space-between stretch"
         class="item-wrapper"
    >
      <div *ngFor="let item of items" class="item-container">
        <div *ngIf="!!item.value">
          <span [style.font-weight]="!!item.isBold ? 500: null"
                matTooltip="{{item.value}}"
                matTooltipPosition="above"
          >{{item.value}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
