import { AfterViewInit, Directive, Host, Input, Optional } from '@angular/core';
import { MapAnchorPoint, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Directive({
  selector: 'map-info-window[bhInfoWindowVisibility]',
})
export class InfoWindowVisibilityDirective implements AfterViewInit {
  @Input('marker') marker: MapMarker | google.maps.Marker;
  @Input('disableAutoPan') disableAutoPan = true;
  @Input('position') position: google.maps.LatLngLiteral;

  constructor(@Host() @Optional() private infoWindow: MapInfoWindow) {}

  public ngAfterViewInit(): void {
    if (this.marker) {
      this.infoWindow.options = { disableAutoPan: this.disableAutoPan };
      this.infoWindow.open(this.getMapAnchorPoint(), false);
    } else if (this.position) {
      this.infoWindow.options = {
        disableAutoPan: this.disableAutoPan,
        position: this.position,
        pixelOffset: new google.maps.Size(0, -40),
      };
      this.infoWindow.open(null, false);
    }
  }

  private getMapAnchorPoint(): MapAnchorPoint {
    return this.marker instanceof google.maps.Marker
      ? { getAnchor: () => <google.maps.Marker>this.marker }
      : this.marker;
  }
}
