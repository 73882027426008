import { MaintenanceRule } from '../contract/maintenance-rule';

export function createIntervalsText(rule: MaintenanceRule): string {
  const intervals = [];
  if (rule.fixedDateInterval) {
    intervals.push('Datum');
  }
  if (rule.periodInterval) {
    intervals.push('Zeitintervall');
  }
  if (rule.operatingHoursInterval) {
    intervals.push('Betriebsstunden');
  }
  if (rule.mileageInterval) {
    intervals.push('Kilometerleistung');
  }

  return intervals.join(', ');
}
