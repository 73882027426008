import { Injectable } from '@angular/core';
import { CountryEnum } from './country.enum';
import { LanguageService } from './services/language.service';

@Injectable()
export class CountryResolver {

  private translationKeys = {
    [CountryEnum.DE]: 'shared.country.germany',
    [CountryEnum.AT]: 'shared.country.austria',
    [CountryEnum.FR]: 'shared.country.france',
    [CountryEnum.CZ]: 'shared.country.czechRepublic',
    [CountryEnum.NL]: 'shared.country.netherlands',
    [CountryEnum.CH]: 'shared.country.switzerland',
  }
  constructor(private languageService: LanguageService) {}

  resolveName(option: CountryEnum): string {
    if (!option) {
      return '';
    }

    return this.languageService.getInstant(this.translationKeys[option]);
  }
}
