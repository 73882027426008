import { Component, Input } from '@angular/core';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@Component({
  selector: 'bh-contract-attachments',
  templateUrl: './contract-attachments.component.html',
  styleUrls: ['./contract-attachments.component.scss']
})

export class ContractAttachmentsComponent {

  @Input() documents: DocumentLink[];

  constructor(private fileDownloadService: FileDownloadService) {}

  public downloadDocument(document: DocumentLink): void {
    this.fileDownloadService.saveFile(document);
  }
}
