import { PipeTransform, Pipe } from '@angular/core';
import { ViewEquipmentProjectAssignment } from 'app/modules/equipment/contract/view-equipment-project-assignment.interface';
import { RequestedAssignment } from 'app/modules/equipment/shared/requested-assignment';
import * as moment from 'moment';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'formatAssignmentDateRange'
})
export class FormatAssignmentDateRangePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(assignment: ViewEquipmentProjectAssignment | RequestedAssignment, format = 'DD.MM.YYYY'): string {
    if (assignment && (assignment.startDate || assignment.endDate)) {
      return (assignment.startDate ? moment(assignment.startDate).format(format) : this.translate('general.labels.openStart'))
      + ' - '
      + (assignment.endDate ? moment(assignment.endDate).format(format) : this.translate('general.labels.openEnd'));
    } else {
      return '-';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
