import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import _ from 'lodash';

@Pipe({
  name: 'formatHoursAmount'
})
export class FormatHoursAmountPipe implements PipeTransform {

  private hoursLabel = this.translate('general.units.time.hour.abbr');

  constructor(private languageService: LanguageService) {}

  public transform(value: number): string {
    if (_.isNil(value) || value < 0) {
      return;
    }
    return `${Math.round(value * 100) / 100}${this.hoursLabel}`;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
