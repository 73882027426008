import { Pipe, PipeTransform } from '@angular/core';

type TPropertyName<T> = keyof T;

@Pipe({
  name: 'concatCollection'
})
export class ConcatCollectionPipe<T> implements PipeTransform {

  public transform(
    collection: T[],
    selectedKeyValues: string[],
    keyField: TPropertyName<T>,
    fields: TPropertyName<T>[]
  ): string {
    if (!(collection?.length > 0 && selectedKeyValues?.length > 0 && Boolean(keyField) && fields?.length > 0)) {
      return null;
    }

    const valuesSet = new Set(selectedKeyValues.filter(Boolean));
    return collection
      .filter(item => valuesSet.has(item?.[<string>keyField]))
      .map(item => this.toCollectionItemView(item, fields))
      .filter(Boolean)
      .join(', ');
  }

  private toCollectionItemView(item: T, fields: TPropertyName<T>[]): string {
    return fields
      .map(f => String(item[f] || '').trim())
      .filter(Boolean)
      .join(' ');
  }
}
