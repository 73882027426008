export enum SalesContractStatus {
  SUSPENDED = <any>'SUSPENDED',
  OPEN = <any>'OPEN',
  APPROVED = <any>'APPROVED',
  IN_PROCESS = <any>'IN_PROCESS',
  CHANGED = <any>'CHANGED',
  COMPLETED = <any>'COMPLETED',
  LOCKED = <any>'LOCKED',
  CANCELED = <any>'CANCELED',
  RELEASED = <any>'RELEASED',
  NOT_AVAILABLE = <any>'NOT_AVAILABLE'
}
