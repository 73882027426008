import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { SearchUser } from '../contract/search-user.interface';
import { UsersService } from './users.service';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { CreateUserCommand } from '../contract/create-user-command';
import { DeactivateUserCommand } from '../contract/deactivate-user-command';
import { ActivateUserCommand } from '../contract/activate-user-command';
import { DeleteUserCommand } from '../contract/delete-user-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { ViewUser } from '../contract/view-user.interface';
import { UpdateUserCommand } from '../contract/update-user-command';
import { CreateSuperAdminCommand } from '../contract/create-super-admin-command';
import { CreatePartnerAdminCommand } from '../contract/create-partner-admin-command';
import { ResetUserPasswordCommand } from '../contract/reset-user-password-command';
import { HttpErrorResponse } from '@angular/common/http';
import { delay, switchMap } from 'rxjs/operators';
import { SyncKeycloakCommand } from '../users/shared/sync-keycloak-command';
import { KeycloakInfo } from '../users/shared/keycloak-info';
import { UserConfigListColumnService } from './user-config-list-column.service';

@Injectable()
export class UsersStore extends DataSource<SearchUser> {

  private _users: BehaviorSubject<SearchUser[]> = new BehaviorSubject<SearchUser[]>([]);
  private _currentUser: BehaviorSubject<ViewUser> = new BehaviorSubject(null);
  private _syncKeycloakStatus: BehaviorSubject<KeycloakInfo[]> = new BehaviorSubject<KeycloakInfo[]>([]);
  private _pagination: PaginationInfo;
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);

  public readonly users: Observable<SearchUser[]> = this._users.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly syncKeycloakStatus: Observable<KeycloakInfo[]> = this._syncKeycloakStatus.asObservable();

  private getUserSubject: Subject<void> = new Subject<void>();
  private getUsersObs: Observable<void> = this.getUserSubject.asObservable();

  private _searchTerms: string;

  constructor(
    private _usersService: UsersService,
    private userConfigListColumnService: UserConfigListColumnService,
  ) {
    super();

    if (!this._pagination) {
      this.initPagination();
    }
    this.userConfigListColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
    this.getUsersObs.pipe()
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => {
      this.updateListing(this.pagination.index, this.pagination.size);
    });
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0
    };
  }

  get pagination(): PaginationInfo {
    return this._pagination;
  }

  set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  set searchTerms(searchTerms: string) {
    this._searchTerms = searchTerms;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  public connect(): Observable<SearchUser[]> {
    this.updateListing();
    return this._users;
  }

  public disconnect(): void {
  }

  public updateStoreData(res: PagedResponse<SearchUser>): void {
    this._users.next(res.content);
    this._currentTotalElements.next(res.totalElements);
  }

  public updateListing(index?: number, size?: number): void {
    this._pagination.index = index ? index : this._pagination.index;
    this._pagination.size = size ? size : this._pagination.size;

    if (this._searchTerms) {
      this._usersService
        .searchUsers(this._searchTerms, index, size)
        .subscribe(res => this.updateStoreData(res));
    } else {
      this._usersService
        .getUsers(index, size)
        .subscribe(res => this.updateStoreData(res));
    }

  }

  public getUsers(): void {
    const pageIndex: number = this._pagination ? this._pagination.index : 0;
    const pageSize: number = this._pagination ? this._pagination.size : 25;
    this._usersService
      .getUsers(pageIndex, pageSize)
      .subscribe(res => {
        this.getUser(res.content[0].userId);
        this.updateStoreData(res)
      });
  }

  public getUser(userId: string): Observable<ViewUser> {
    return this._usersService
      .getUser(userId)
      .pipe(switchMap((res: any) => {
        this._currentUser.next(res);
        return of(res);
      }));
  }

  public addUser(cmd: CreateUserCommand): Observable<string> {
    return this._usersService
      .addUser(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public addPartnerAdmin(cmd: CreatePartnerAdminCommand): Observable<string> {
    return this._usersService
      .addPartnerAdmin(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public addSuperAdmin(cmd: CreateSuperAdminCommand): Observable<string> {
    return this._usersService
      .addSuperAdmin(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public updateUser(cmd: UpdateUserCommand): Observable<string> {
    return this._usersService
      .updateUser(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public updateUsers(cmds: UpdateUserCommand[]): Observable<{}[]> {
    let requests: any[] = [];
    cmds.forEach( cmd =>
     requests.push(this._usersService.updateUser(cmd))
    );
    return forkJoin(requests);
    }

  public activateUser(cmd: ActivateUserCommand): Observable<string> {
    return this._usersService
      .activateUser(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public deactivateUser(cmd: DeactivateUserCommand): Observable<string> {
    return this._usersService
      .deactivateUser(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public deleteUser(cmd: DeleteUserCommand): Observable<string> {
    return this._usersService
      .deleteUser(cmd)
      .pipe(switchMap((res: string) => {
        this.getUserSubject.next();
        return of(res);
      }));
  }

  public resetPassword(cmd: ResetUserPasswordCommand): void {
    this._usersService
      .resetPassword(cmd)
      .subscribe((res: string) => {
          console.log(res);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      );
  }

  public emailAvailable(email: string): Observable<boolean> {
    return this._usersService.emailInUse(email)
      .pipe(switchMap( (res: boolean) => {
        return of(res);
      }));
  }

  public getUsersByRole(roleId: string): Observable<ViewUser[]> {
    return this._usersService.getUsersByRole(roleId);
  }

  public syncKeycloak(cmd?: SyncKeycloakCommand) {
    return this._usersService.syncKeycloak(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.fetchSyncKeycloakStatus());
  }

  public fetchSyncKeycloakStatus() {
    this._usersService.getSyncKeycloakStatus()
      .subscribe(keycloakInfo => {
        this._syncKeycloakStatus.next(keycloakInfo)
      });
  }
}
