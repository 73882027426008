export class ReportGenerationCommand {
  reportDefinitionId: string;
  organisationIds: string[];
  equipmentTypeIds: string[];
  equipmentLabels: string[];
  equipmentIds: string[];
  projectIds: string[];
  from: string;
  until: string;
  allEquipments: boolean;
  allProjects: boolean;
  timeZoneName: string;

  constructor(reportDefinitionId: string, organisationIds: string[], equipmentTypeIds: string[],
              equipmentLabels: string[], equipmentIds: string[], projectIds: string[], from: string,
              until: string, allEquipments: boolean, allProjects: boolean, timeZoneName: string) {
    this.reportDefinitionId = reportDefinitionId;
    this.organisationIds = organisationIds;
    this.equipmentTypeIds = equipmentTypeIds;
    this.equipmentLabels = equipmentLabels;
    this.equipmentIds = equipmentIds;
    this.projectIds = projectIds;
    this.from = from;
    this.until = until;
    this.allEquipments = allEquipments;
    this.allProjects = allProjects;
    this.timeZoneName = timeZoneName;
  }
}
