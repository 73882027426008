import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { delay, map, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddEquipmentLabelCommand } from '../../../../../contract/add-equipment-label-command';
import { RemoveEquipmentLabelCommand } from '../../../../../contract/remove-equipment-label-command';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { EquipmentAssignToOrganisationDialogComponent } from '../../../../../shared/equipment-assign-to-organisation-dialog/equipment-assign-to-organisation-dialog.component';
import { EquipmentLogOperatingHoursComponent } from '../../../../equipment-log-lifecycle/equipment-log-operating-hours/landscape/equipment-log-operating-hours.component';
import { EquipmentLogMileageComponent } from '../../../../equipment-log-lifecycle/equipment-log-mileage/landscape/equipment-log-mileage.component';
import { EquipmentTypeSelectComponent } from '../../../../../equipment-types/equipment-type-select/equipment-type-select.component';
import { ComponentType } from '@angular/cdk/overlay';
import { BaseEquipmentViewGeneralComponent } from '../base/base-equipment-view-general.component';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { ProjectsService } from '../../../../../../disposition/shared/project.service';
import { ViewProject } from '../../../../../../disposition/contract/view-project.interface';
import { LocationType } from '../../../../../../../shared/enums/location-type.enum';
import { ParentEquipment } from '../../../../../contract/parent-equipment';
import { OrganisationInfo } from '../../../../../contract/organisation-info.interface';
import { ScanCodeEditComponent } from 'app/shared/components/scan-code-edit/scan-code-edit.component';
import { IViewScanCodeData } from 'app/shared/components/scan-code-edit/scan-code.interface';
import { PairEquipmentScanCodeCommand } from 'app/modules/equipment/contract/pair-equipment-scan-code-command';
import { UnpairEquipmentScanCodeCommand } from 'app/modules/equipment/contract/unpair-equipment-scan-code-command';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileUtils } from '../../../../../../../shared/fileUtils';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-general',
  templateUrl: './equipment-view-general.component.html',
  styleUrls: ['./equipment-view-general.component.scss']
})
export class EquipmentViewGeneralComponent extends BaseEquipmentViewGeneralComponent implements OnInit, OnDestroy {

  parentEquipment: ParentEquipment | null;
  organisations: OrganisationInfo[];
  enableAssignOrgMenuItem: boolean;
  public customerLabels: Observable<string[]>;
  public equipmentLabels: Observable<string[]>;
  public assignedProject: Observable<ViewProject>;
  public imageKeys: string[] = [];

  public get scanCodeData(): IViewScanCodeData {
    return {
      objectName: this.equipment?.equipmentName,
      objectModel: this.equipment?.equipmentModel,
      objectSerialNumber: this.equipment.equipmentSerialNumber,
      scanCode: this.equipment?.equipmentScanCode,
    };
  }

  public get showEmployeeAssignments(): boolean {
    return this.hasAuthority(Authorities.EQUIPMENT_VIEW) || this.hasAuthority(Authorities.PROJECT_ASSIGNEE_VIEW)
  }

  private _isAllowedToSeeLink: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly isAllowedToSeeLink: Observable<boolean> = this._isAllowedToSeeLink.asObservable();
  protected componentActive = true;

  constructor(protected equipmentsService: EquipmentsService,
              protected projectService: ProjectsService,
              protected equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              public equipmentCheckerService: EquipmentCheckerService) {
    super(authService, router, route, routerHistory, equipmentCheckerService, languageService);
  }

  public ngOnInit(): void {
    this.subscribeToEquipment();
    this.customerLabels = this.equipmentStore.filteredCustomerLabels;
    this.equipmentStore.getCustomerLabels();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private subscribeToEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((viewEquipment: ViewEquipment) => {
      if (viewEquipment) {
        this.equipment = viewEquipment;
        this.getEquipmentImages(viewEquipment);
        this.getParentEquipment();
        if (this.hasAuthority(Authorities.EQUIPMENT_UPDATE)
          && this.equipmentCheckerService.isActiveEquipment(viewEquipment)) {
          this.getOrganisations();
        }
        this.getEmployeeAssignments(viewEquipment.equipmentId);
        this.equipmentLabels = this.equipmentStore.equipmentLabels;
        this.setGuaranteeEndDate();

        if (this.equipment?.transferDestinationType === LocationType.PROJECT) {
          this.assignedProject = this.projectService.getProject(this.equipment.projectId);
        }
        this._isAllowedToSeeLink.next(this.canSeeLinkOfStockOrProject(viewEquipment));
      }
    });
  }

  private getEmployeeAssignments(equipmentId: string): void {
    if (this.showEmployeeAssignments) {
      this.equipmentStore.getEmployeeAssignments(equipmentId);
    }
  }

  private getOrganisations(): void {
    this.equipmentsService.getOrganisations()
    .pipe(takeWhile(() => this.componentActive))
    .subscribe(
        res => {
          this.organisations = res.content;
          this.enableAssignOrgMenuItem = this.organisations.length >= 1;
        },
        error => {
          console.log('error on getting organisations', error);
        });
  }

  private getParentEquipment(): void {
    this.parentEquipment = null;
    if (this.equipment.containerId) {
      this.equipmentsService.getParentEquipment(this.equipment.containerId)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(res => this.parentEquipment = res);
    }
  }

  private getEquipmentImages(equipment: ViewEquipment): void {
    this.equipmentStore.getFiles(equipment.equipmentId)
    .pipe(
        takeWhile(() => this.componentActive),
        map(documentLinks => FileUtils.filterAndSortImages(documentLinks, equipment?.thumbnailKey)),)
    .subscribe(imageLinks => {
      this.imageKeys = (imageLinks || []).map(doc => doc.documentKey);
    });
  }

  public assignToOrganisation(): void {
    let dialogRef = this.dialog.open(EquipmentAssignToOrganisationDialogComponent);
    dialogRef.componentInstance.equipment = this.equipment;
    dialogRef.componentInstance.organisations = this.organisations;
  }

  public equipmentLogOperatingHours(): void {
    this.openDialog(EquipmentLogOperatingHoursComponent);
  }

  public equipmentLogMileage(): void {
    this.openDialog(EquipmentLogMileageComponent);
  }

  public equipmentEditScanCode(): void {
    this.dialog.open(ScanCodeEditComponent, {
      data: { scanCodeData: this.scanCodeData }
    }).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        switch (res.status) {
          case dialogResults.SAVE:
            this.saveScanCode(res.scanCode);
            break;
          case dialogResults.DELETE:
            this.deleteScanCode(res.scanCode);
            break;
        }
      });
  }

  public equipmentEditType(): void {
    this.dialog.open(EquipmentTypeSelectComponent, <MatDialogConfig>{
      data: {
        equipmentId: this.equipment.equipmentId,
        currentTypeId: this.equipment.equipmentTypeId
      }
    });
  }

  private openDialog(component: ComponentType<any>): void {
    const dialogRef = this.dialog.open(component, <MatDialogConfig>{
      disableClose: true
    });
    dialogRef.afterClosed()
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(value => {
      if ('saved' === value) {
        this.equipmentStore.updateCurrentEquipment();
        this.equipmentStore.updateListing();
      }
    });
  }

  public allowScanCodeEdit(): boolean {
    return this.hasAuthority(this.authorities.EQUIPMENT_SCANCODE_EDIT);
  }

  public allowTypeEdit(): boolean {
    return this.hasAuthority(this.authorities.EQUIPMENT_TYPE_CHANGE);
  }

  public navigateToParentContainer(): void {
    this.router.navigate(['assets/equipment/list', this.parentEquipment.equipmentId, 'general']);
  }

  public addLabel(label: string): void {
    let cmd = new AddEquipmentLabelCommand();
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.label = label;
    this.equipmentStore.saveLabel(cmd);
  }

  public deleteLabel(label: string): void {
    let cmd = new RemoveEquipmentLabelCommand();
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.label = label;
    this.equipmentStore.removeLabel(cmd);
  }

  public resolveTooltip(): string {
    if (this.equipment) {

      return this.translate(
        'general.labels.jumpTo',
        {value: this.translate(this.locationLinkKey())},
      );
    } else {
      return null;
    }
  }

  private locationLinkKey(): string {
    if (this.equipment?.transferDestinationType === LocationType.PROJECT) {
      return 'general.project.s';
    } else {
      return 'general.stock.s';
    }
  }

  private canSeeLinkOfStockOrProject(viewEquipment: ViewEquipment): boolean {
    return viewEquipment.currentUserHasAccessToCurrentEquipmentLocation && (
        viewEquipment.transferDestinationType === LocationType.PROJECT
        && this.authService.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])
        || viewEquipment.transferDestinationType === LocationType.STOCK && this.authService.hasAuthority(Authorities.STOCK_VIEW)
        || viewEquipment.transferDestinationType === null && this.authService.hasAuthority(Authorities.STOCK_VIEW)
      );
  }

  public navigateToStockOrProject(): void {
    if (this.equipment?.transferDestinationType === LocationType.PROJECT) {
      this.router.navigate(['sites/projects/list', this.equipment.projectId, 'general']);
      return;
    } else {
      this.router.navigate(['sites/stocks/list', this.equipment.stockId, 'general']);
      return;
    }
  }

  private saveScanCode(scanCode: string): void {
    const cmd: PairEquipmentScanCodeCommand = {
      equipmentId: this.equipment.equipmentId,
      equipmentScanCode: scanCode,
      landscapeEdit: true,
    };
    this.equipmentsService.pairScanCode(cmd).subscribe(res => {
      if (res) {
        this.equipment.equipmentScanCode = scanCode;
      }
    });
  }

  private deleteScanCode(scanCode: string): void {
    const cmd = new UnpairEquipmentScanCodeCommand(this.equipment.equipmentId);
    this.equipmentsService.unpairScanCode(cmd).subscribe(res => {
      if (res) {
        this.equipment.equipmentScanCode = scanCode;
      }
    });
  }
}
