import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, skip } from 'rxjs/operators';

@Injectable()
export class OnlineStatusService {

  private isOnlineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);
  onlineStatus: boolean;

  constructor() {
    window.addEventListener('online', () => this.setOnline());
    window.addEventListener('offline', () => this.setOffline());
    this.isOnline.subscribe(isOnline => this.onlineStatus = isOnline);
  }

  public setOnline() {
    this.isOnlineSubject.next(true);
  }

  public setOffline() {
    this.isOnlineSubject.next(false);
  }

  public get isOnline(): Observable<boolean> {
    return concat(
      this.isOnlineSubject.asObservable()
        .pipe(first()),
      this.isOnlineSubject.asObservable()
        .pipe(skip(1), debounceTime(5000), distinctUntilChanged()));
  }
}
