import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { AdditionalFieldType } from '../../modules/osp-ui/rich-components/bh-additional-field/additional-field';

@Pipe({
  name: 'additionalFieldTypeDisplay',
  pure: true
})
export class AdditionalFieldTypeDisplayPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case AdditionalFieldType.STRING:
        return this.translate('general.additionalFieldType.string');
      case AdditionalFieldType.NUMBER:
        return this.translate('general.additionalFieldType.number');
      case AdditionalFieldType.BOOLEAN:
        return this.translate('general.additionalFieldType.boolean');
      case AdditionalFieldType.SELECT:
        return this.translate('general.additionalFieldType.select');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
