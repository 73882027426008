import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PushToken } from '../contract/push-token';

@Injectable()
export class PushService {

  private url = environment.APP_PUSH_SERVICE_BASE_URL + '/api/v1/push';

  constructor(private http: HttpClient) {
  }

  register(pushToken: PushToken): Observable<string> {
    return this.http.post(`${this.url}/register`, pushToken, {responseType: 'text'});
  }

  unregister(pushToken: PushToken): Observable<string> {
    return this.http.post(`${this.url}/unregister`, pushToken, {responseType: 'text'});
  }

}
