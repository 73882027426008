import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { CanActivateChildMobile } from '../../../core/guards/can-activate-child-mobile';
import { Modules } from '../../../shared/enums/modules.enum';
import { MobileAlarmsComponent } from '../mobile-alarms/mobile-alarms.component';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { CanActivateChildMobileWebApp } from 'app/core/guards/can-activate-child-mobile-web-app.guard';


const mobileEquipmentRoutes: Routes = [
  {
    path: 'mobile/notifications',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobile, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'alarms',
        component: MobileAlarmsComponent,
        data: {
          authorities: [Authorities.NOTIFICATIONS_VIEW_ALARMS],
          modules: [Modules.ALARMS]
        }
      }
    ]
  }
];

export const mobileNotificationRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(mobileEquipmentRoutes);
