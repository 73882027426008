import { Component, ViewEncapsulation } from '@angular/core';
import { DispositionDataSource } from '../shared/disposition.datasource';
import { EmployeeDispositionDatasource } from '../shared/employee-disposition.datasource';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-disposition-board',
  templateUrl: 'disposition-board.component.html',
  styleUrls: ['disposition-board.component.scss']
})
export class DispositionBoardComponent {

  constructor(public dispositionStore: DispositionDataSource,
              public employeeDispositionStore: EmployeeDispositionDatasource) {
  }
}
