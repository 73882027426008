<div class="dialog-wrapper">
  <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <mat-icon>build</mat-icon>
    <span>{{'modules.equipment.rule.selectInspectionMaintenance'|translate}}</span>
  </h1>
  <div mat-dialog-content class="dialog-content" fxLayout="column">
    <form class="search-bar-input" fxLayout="row" novalidate>
      <input fxFlex matInput class="search-input" [placeholder]="'general.search'|translate" [formControl]="filterControl">
      <mat-icon [matTooltip]="'general.buttons.resetSearchTerm'|translate" fxFlex="24px" (click)="filterControl.reset()">
        clear
      </mat-icon>
    </form>
    <form [formGroup]="form" *ngIf="!loading; else load" fxLayout="column" fxFlex="100%">
      <cdk-virtual-scroll-viewport formArrayName="rules" fxFlex="100%" itemSize="85" minBufferPx="500" maxBufferPx="750">
        <div *cdkVirtualFor="let ruleGroup of (rules.controls | filterBy : ['enabled'] : true); trackBy: trackByRuleForm"
              class="rule" fxLayout="column"
              [formGroup]="ruleGroup"
              [bhScrollSelected]="ruleGroup.value.ruleId"
              [checkSelected]="currentSelectedRuleId | async">
          <div fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <mat-checkbox formControlName="selected" color="primary"
                          (change)="setCurrentSelectedRuleId($event, ruleGroup.value.ruleId)">
              <mat-icon class="rule-icon checkbox-component">
                {{ruleGroup.value.category | maintenanceCategory : 'icon'}}
              </mat-icon>
              <span fxFlex>{{ruleGroup.value.name}}</span>
            </mat-checkbox>
            <mat-icon class="rule-icon" *ngIf="ruleAdded(ruleGroup)"
                      [matTooltip]="'modules.equipment.rule.ruleAdded'|translate">add
            </mat-icon>
            <mat-icon class="rule-icon" *ngIf="ruleRemoved(ruleGroup)"
                      [matTooltip]="'modules.equipment.rule.ruleRemoved'|translate">remove
            </mat-icon>
          </div>
          <div class="interval-inputs" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div *ngIf="hasAnyInterval(ruleGroup) && ruleAdded(ruleGroup)"
                 fxLayout="row" fxLayoutAlign="end center" fxFlex="16">
              <span class="interval-title">
                {{'general.last'|translate}} {{ruleGroup.value.category | maintenanceCategory : 'text'}}
              </span>
            </div>

            <mat-form-field fxFlex="28" *ngIf="ruleGroup.controls.periodCalculationBase.enabled">
              <mat-label>{{'general.at'|translate}}</mat-label>
              <input matInput
                     required
                     formControlName="periodCalculationBase" autocomplete="off"
                     [matDatepicker]="periodStartPicker">
              <mat-datepicker-toggle matSuffix [for]="periodStartPicker"></mat-datepicker-toggle>
              <mat-datepicker #periodStartPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="28" class="center-interval" *ngIf="ruleGroup.controls.mileageCalculationBase.enabled">
              <mat-label>{{'modules.equipment.rule.atMileage'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
              <input matInput
                     required
                     type="number"
                     formControlName="mileageCalculationBase">
            </mat-form-field>

            <mat-form-field fxFlex="28" *ngIf="ruleGroup.controls.operatingHoursCalculationBase.enabled">
              <mat-label>{{'modules.equipment.rule.atOperatingHours'|translate}}</mat-label>
              <input matInput
                     required
                     type="number"
                     formControlName="operatingHoursCalculationBase">
            </mat-form-field>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </form>
  </div>

  <div mat-dialog-actions class="dialog-actions">
    <button mat-raised-button color="accent" (click)="onCancel()">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="assignRules()">{{'general.buttons.save'|translate}}</button>
  </div>
</div>

<ng-template #load>
  <div class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
