<bh-basic-widget
  [widget]="widget"
  [index]="index"
  (configurationSave)="onConfigurationSave($event)"
  (deleted)="onDeleted()"
  (titleIconClick)="onTitleIconClick()">

  <ng-container widget-content>

    <ng-container *ngIf="(tasks | async) as data; else noEntries">
      <bh-last-completed-tasks-table *ngIf="data.length > 0; else noEntries"
        [data]="data"
        (navigateToTaskCompleted)="onNavigateToTaskCompleted($event)">
      </bh-last-completed-tasks-table>
    </ng-container>

    <ng-template #noEntries>
      <div class="no-entries">
        <h3>{{ 'general.noAvailableEntries'|translate }}</h3>
      </div>
    </ng-template>

  </ng-container>

  <div widget-configuration>
    <mat-form-field [floatLabel]="organisationPlaceholder.floatLabel">
      <mat-label>{{ 'general.org.pl'|translate }}</mat-label>
      <input matInput
        [matAutocomplete]="organisations"
        [placeholder]="organisationPlaceholder.placeholder"
        (keypress)="preventDefaultEvent($event)">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #organisations="matAutocomplete"
        (opened)="organisationsSelectionTree.open()"
        (closed)="organisationsSelectionTree.getSelectedOptions()">
        <bh-selection-tree #organisationsSelectionTree
          [optionsList]="organisationList"
          (finalSelectedOptionsList)="organisationListClosed()">
        </bh-selection-tree>
        <mat-option style="display: none;">Unused option</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [floatLabel]="typePlaceholder.floatLabel">
      <mat-label>{{ 'general.types'|translate }}</mat-label>
      <input matInput
        [matAutocomplete]="types"
        [placeholder]="typePlaceholder.placeholder"
        (keypress)="preventDefaultEvent($event)">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #types="matAutocomplete"
        (opened)="typesSelectionTree.open()"
        (closed)="typesSelectionTree.getSelectedOptions()">
        <bh-selection-tree #typesSelectionTree
          [optionsList]="typeList"
          (finalSelectedOptionsList)="typeListClosed()">
        </bh-selection-tree>
        <mat-option style="display: none;">Unused option</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

</bh-basic-widget>
