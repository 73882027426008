<div fxLayout="column" fxLayoutAlign="start stretch" class="connector-list-container">
  <div fxLayout="row" fxLayoutAlign="space-between" class="header">
    <div>{{'modules.connector.connectorList.interfaces'|translate}}</div>
    <button mat-button [matTooltip]="'modules.connector.connectorList.update'|translate" class="update-button"
            (click)="loadConnectors()">
      <mat-icon>autorenew</mat-icon>
    </button>
  </div>

  <mat-card>
    <mat-card-header>

      <button mat-button class="create-button" (click)="addConnector(ConnectorType.AEMP_V2)"
              [matTooltip]="'modules.connector.base.newAempV2'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
        <span>{{'modules.connector.base.aempv2'|translate|uppercase}}</span>
      </button>
      <button mat-button class="create-button" (click)="addConnector(ConnectorType.MAIL)"
              [matTooltip]="'modules.connector.base.newMail'|translate">
          <mat-icon class="bh-mat-icon">add_box</mat-icon>
          <span>{{'modules.connector.base.mail'|translate|uppercase}}</span>
      </button>
      <button mat-button class="create-button" (click)="addConnector(ConnectorType.TRACKUNIT_CLASSIC)"
              [matTooltip]="'modules.connector.base.newTrackUnitClassic'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
        <span>{{'modules.connector.base.trackUnitClassic'|translate|uppercase}}</span>
      </button>
      <button mat-button class="create-button" (click)="addConnector(ConnectorType.GPS_OVER_IP)"
              [matTooltip]="'modules.connector.base.newGpsOverIp'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
        <span>{{'modules.connector.base.gpsOverIp'|translate|uppercase}}</span>
      </button>
      <button mat-button class="create-button" (click)="addConnector(ConnectorType.RIO)"
              [matTooltip]="'modules.connector.base.newRio'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
        <span>{{'modules.connector.base.rio'|translate|uppercase}}</span>
      </button>
      <button *ngIf="isSuperAdmin()"
              mat-button class="create-button" (click)="addConnector(ConnectorType.REIDL)"
              [matTooltip]="'modules.connector.base.newReidl'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
        <span>{{'modules.connector.base.reidl'|translate|uppercase}}</span>
      </button>
      <button *ngIf="isSuperAdmin()"
              mat-button class="create-button" (click)="addConnector(ConnectorType.SINOS_PORTAL_2)"
              [matTooltip]="'modules.connector.base.newSinos2'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
        <span>{{'modules.connector.base.sinos2'|translate|uppercase}}</span>
      </button>
    </mat-card-header>

    <mat-card-content *ngIf="scheduledConnectors?.length">
      <bh-generic-connector-list
        [connectors]="scheduledConnectors"
        (edit)="editConnector($event)"
        (activate)="activateConnector($event)"
        (update)="deactivateConnector($event)"
        (delete)="deleteConnector($event)">
      </bh-generic-connector-list>
    </mat-card-content>
  </mat-card>
</div>
