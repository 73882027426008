export enum ReportJobInterval {
  DAILY = <any>'DAILY',
  WEEKLY_MONDAY = <any>'WEEKLY_MONDAY',
  WEEKLY_TUESDAY = <any>'WEEKLY_TUESDAY',
  WEEKLY_WEDNESDAY = <any>'WEEKLY_WEDNESDAY',
  WEEKLY_THURSDAY = <any>'WEEKLY_THURSDAY',
  WEEKLY_FRIDAY = <any>'WEEKLY_FRIDAY',
  WEEKLY_SATURDAY = <any>'WEEKLY_SATURDAY',
  WEEKLY_SUNDAY = <any>'WEEKLY_SUNDAY',
  MONTHLY = <any>'MONTHLY'
}
