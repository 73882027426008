import { LanguageService } from 'app/shared/services/language.service';
import { Component, Inject, OnInit } from '@angular/core';
import { RoleAuthorityGuardsComponent } from '../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../core/keycloak';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { DateValidator } from '../../../../shared/custom-validators/date.validator';
import { TransportAssignmentExecutionCommand } from '../../contracts/transport/transport-assignment-execution.command';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';
import { DatesService } from '../../../../shared/services/dates.service';
import { TransportBoardDataSource } from '../../shared/services/transport-board.datasource';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { getBrowserName } from '../../../../shared/utils';
import { BrowserName } from '../../../../shared/enums/browser-name.enum';

@UntilDestroy()
@Component({
  selector: 'bh-transport-execution-dates',
  templateUrl: './transport-execution-dates-edit.component.html',
  styleUrls: ['./transport-execution-dates-edit.component.scss'],
})
export class TransportExecutionDatesEditComponent extends RoleAuthorityGuardsComponent implements OnInit {
  public transportTask: TransportView;
  public form: UntypedFormGroup;
  public canChangeEndDateTime: boolean;
  public datesValid: boolean;

  public readonly isFirefoxBrowser = getBrowserName() === BrowserName.FIREFOX;

  private startDateValue: Date;
  private endDateValue: Date;

  constructor(
      @Inject(MAT_DIALOG_DATA) data: TransportView,
      protected formBuilder: UntypedFormBuilder,
      private dialogRef: MatDialogRef<TransportExecutionDatesEditComponent>,
      private languageService: LanguageService,
      private transportDataSource: TransportBoardDataSource,
      private datePipe: DatePipe,
      authService: KeycloakService,
  ) {
    super(authService);
    this.transportTask = data;
    this.canChangeEndDateTime = this.transportTask.state !== TransportTaskState.IN_PROGRESS;

    const startTimestamp = data?.equipmentAssignmentDetails?.executionStartDate?.timestamp;
    const endTimestamp = data?.equipmentAssignmentDetails?.executionEndDate?.timestamp;
    this.startDateValue = startTimestamp ? new Date(startTimestamp) : null;
    this.endDateValue = endTimestamp ? new Date(endTimestamp) : null;

    this.checkDateValidity();
  }

  public get dataFormField(): UntypedFormGroup {
    return this.form.get('data') as UntypedFormGroup;
  }

  public get startDateTime(): UntypedFormGroup {
    return this.dataFormField.get('startDateTime') as UntypedFormGroup;
  }

  public get endDateTime(): UntypedFormGroup {
    return this.dataFormField.get('endDateTime') as UntypedFormGroup;
  }

  public get startDate(): AbstractControl {
    return this.startDateTime.get('startDate');
  }

  public get startTime(): AbstractControl {
    return this.startDateTime.get('startTime');
  }

  public get endDate(): AbstractControl {
    return this.endDateTime.get('endDate');
  }

  public get endTime(): AbstractControl {
    return this.endDateTime.get('endTime');
  }

  public ngOnInit(): void {
    this.buildForm();
    this.fillForm();
    this.initListeners();
  }

  public clearDate(start: boolean): void {
    start ? this.startDate.reset() : this.endDate.reset();
  }

  public clearTime(start: boolean): void {
    start ? this.startTime.reset() : this.endTime.reset();
  }

  public saveDateTime(): void {
    const command = this.getTransportAssignmentExecutionCommand();
    this.transportDataSource.updateExecutionDates(command)
    .pipe(untilDestroyed(this))
    .subscribe(() => this.dialogRef.close());
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      data: this.formBuilder.group({
        startDateTime: this.formBuilder.group({
          startDate: ['', DateValidator.isValidDate(this.languageService)],
          startTime: ['', Validators.required]
        }),
        endDateTime: this.formBuilder.group({
          endDate: '',
          endTime: ''
        }),
      })
    });
    if (this.canChangeEndDateTime) {
      this.endDate.setValidators([DateValidator.isValidDate(this.languageService)]);
      this.endTime.setValidators([Validators.required]);
    }
  }

  private fillForm(): void {
    const formData = {
      data: {
        startDateTime: {
          startDate: this.startDateValue ? this.startDateValue : null,
          startTime: this.startDateValue
              ? this.datePipe.transform(this.startDateValue, 'HH:mm', this.languageService.getCurrentLocale())
              : null
        },
        endDateTime: {
          endDate: this.endDateValue ? this.endDateValue : null,
          endTime: this.endDateValue
              ? this.datePipe.transform(this.endDateValue, 'HH:mm', this.languageService.getCurrentLocale())
              : null
        }
      },
    };
    this.form.patchValue(formData, {emitEvent: false});
  }

  private initListeners(): void {
    this.startDateTime.valueChanges.subscribe(({startDate: startDate, startTime: startTime}) => {
      if (startDate && startTime) {
        this.startDateValue = new Date(DatesService.formatToOspDateTimeEndOfDay(startDate, startTime)?.timestamp);
        this.checkDateValidity();
      }
    });
    this.endDateTime.valueChanges.subscribe(({endDate: endDate, endTime: endTime}) => {
      if (endDate && endTime) {
        this.endDateValue = new Date(DatesService.formatToOspDateTimeEndOfDay(endDate, endTime)?.timestamp);
        this.checkDateValidity();
      }
    });
  }

  private checkDateValidity(): void {
    this.datesValid = !this.canChangeEndDateTime && !!this.startDateValue
        || !!this.startDateValue && !!this.endDateValue && moment(this.startDateValue).isBefore(moment(this.endDateValue));
  }

  private getTransportAssignmentExecutionCommand(): TransportAssignmentExecutionCommand {
    return {
      transportId: this.transportTask.transportId,
      assignmentId: this.transportTask.equipmentAssignmentDetails?.assignmentId,
      executionStartDate: DatesService.ospDateTimeAtExactTime(this.startDateValue),
      executionEndDate: this.canChangeEndDateTime ? DatesService.ospDateTimeAtExactTime(this.endDateValue) : null
    };
  }
}
