<cdk-virtual-scroll-viewport
  class="scroll-view-port"
  [ngStyle]="{
    'max-height.px': listItemHeightPx * 6,
    'height.px': listItemHeightPx * (items || []).length
  }"
  [itemSize]="listItemHeightPx"
  [minBufferPx]="listItemHeightPx * 5"
  [maxBufferPx]="listItemHeightPx * 20">
  <bh-cluster-list-item-container *cdkVirtualFor="let item of items; trackBy:trackByItem"
    class="scroll-item"
    [ngStyle]="{
      'height.px': listItemHeightPx,
      'min-height.px': listItemHeightPx,
      'max-height.px': listItemHeightPx,
    }"
    class="list-item"
    [item]="item"
    (onNavigate)="navigateToDetails($event)">
  </bh-cluster-list-item-container>
</cdk-virtual-scroll-viewport>
