import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { ColumnDefinition } from 'app/shared/column-definition';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { BehaviorSubject, combineLatest, filter, map, Observable, Subject, tap } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { EquipmentTransferListRequestParams, EquipmentTransferParams } from '../contract/request-params/equipment-transfer-params.interface';
import { SearchStockEquipmentAmount } from '../contract/search-stock-equipment-amount.interface';
import { AmountsService } from './amounts.service';
import { EquipmentTransferColumnService } from './equipment-transfer-column.service';
import { EquipmentTransferFilterService } from './equipment-transfer-filter.service';

@Injectable()
export class EquipmentTransferDatasource extends DataSource<SearchStockEquipmentAmount> {
  private _data: BehaviorSubject<SearchStockEquipmentAmount[]> = new BehaviorSubject([]);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private _sort: Sort;
  private currentlySelectedColumns: string[];
  private amountsRequest = new Subject<EquipmentTransferParams>();
  private initialRequest = true;
  public readonly amounts: Observable<SearchStockEquipmentAmount[]> = this._data.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly filters = this.equipmentTransferFilterService.filters;
  public readonly onFilterUpdated = this.equipmentTransferFilterService.onFiltersUpdated;

  constructor(private equipmentService: EquipmentsService,
    private snackBar: MatSnackBar,
    private amountsService: AmountsService,
    private equipmentTransferColumnService: EquipmentTransferColumnService,
    private equipmentTransferFilterService: EquipmentTransferFilterService) {
      super();
      this.equipmentTransferColumnService.selectedColumns.subscribe((columns: ColumnDefinition[]) => {
        this.currentlySelectedColumns = columns.filter((c: ColumnDefinition) => c.cdkColumnDef !== 'intoTransferCart')
        .map((c: ColumnDefinition) => c.cdkColumnDef);
      });
      this.initPaginatorPageSizeListener();
      this.initDataRequestListener();
      this.setDefaultValues();
  }

  get searchTerms() {
    return this._searchTerms;
  }

  set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
    } else {
      this.snackBar.open('Eingabe zu lang. Suche nicht möglich', undefined, {duration: 5000});
    }
  }

  set sort(sort: Sort) {
    this._sort = sort;
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  connect(): Observable<SearchStockEquipmentAmount[]> {
    return this.amounts;
  }

  public disconnect(): void {
  }

  private setDefaultValues(): void {
    this.initPagination();
    this.sort = null;
    this.searchTerms = null;
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private initPaginatorPageSizeListener(): void {
    this.equipmentTransferColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }
  private initDataRequestListener(): void {
    combineLatest([
      this.amountsRequest,
      this.equipmentTransferFilterService.isReady
    ])
    .pipe(
      tap(([params, isReady]) => this.initialRequest = !isReady ? true : this.initialRequest),
      filter(([params, isReady]) => Boolean(isReady)),
      map(([params, isReady]) => !this.initialRequest ? params : this.defineTransportParams()),
      switchMap(params => params.terms
        ? this.amountsService.getEquipmentAmountsSearch(params)
        : this.amountsService.getEquipmentAmounts(params))
    )
    .subscribe(response => {
      this.initialRequest = false;
      this.updateStoreData(response);
    });
  }

  public updateFilters(): void {
    this.equipmentTransferFilterService.updateFilters();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.equipmentTransferFilterService.updateFilterParams(commands);
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.equipmentTransferColumnService.selectPageSize(size);
    }
    this.pagination.index = index;
    this.pagination.size = size;
    this.amountsRequest.next(this.defineTransportParams(index, size));
  }

  private defineTransportParams(page?: number, size?: number): EquipmentTransferParams {
    return {
      page: page ?? this.pagination.index,
      size: size ?? this.pagination.size,
      sort: this.getSortParam(this._sort),
      terms: this._searchTerms,
      searchColumns: this.currentlySelectedColumns,
      ...this.getFilterParams(),
    }
  }

  private getSortParam(sort: Sort): string | string[] {
    if (!sort) {
      return null;
    }

    let result = null;
    if (this._sort && this._sort.active === 'stockAddress') {
      // address is a special case
      result = [
        this.manualSortString('currentAddress.street', this._sort.direction),
        this.manualSortString('currentAddress.city', this._sort.direction),
      ];
    } else if (this._sort && (this._sort.active === 'assignedOwner' ||
     this._sort.active === 'assignedMaintenanceAssignee' ||
     this._sort.active === 'assignedPersonInCharge' ||
     this._sort.active === 'assignedDriver' ||
     this._sort.active === 'assignedPossessor')) {
      result = [
        this.manualSortString(`${this._sort.active}.firstName`, this._sort.direction),
        this.manualSortString(`${this._sort.active}.name`, this._sort.direction),
      ];
    } else if (this._sort && this._sort.direction && this._sort.active === 'equipmentConstructionYear') {
      // construction year is saved as object containing year and month
      result = [
        `${this._sort.active}.year,${this._sort.direction}`,
        `${this._sort.active}.month,${this._sort.direction}`
      ];
    } else if (this._sort) {
      result = this.sortToSortString(this._sort);
    }
    return result;
  }

  private getFilterParams(): EquipmentTransferListRequestParams {
    const { equipmentOrganisations, customerLabels, equipmentTypes, ...rest } =
      this.equipmentTransferFilterService.getFilterParams();
    return {
      ...rest,
      organisation: equipmentOrganisations,
      label: customerLabels,
      type: equipmentTypes,
    } as EquipmentTransferListRequestParams;
  }

  private updateStoreData(res: PagedResponse<SearchStockEquipmentAmount>): void {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  private sortToSortString(sort: Sort): string {
    return sort && sort.active && sort.direction
      ? `${sort.active}.raw,${sort.direction}` : null;
  }

  private manualSortString(model, direction): string {
    const sort: Sort = {
      active: model,
      direction: direction,
    };

    return this.sortToSortString(sort);
  }

}
