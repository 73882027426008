import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { TransferHistoryEntry } from '../../../projects/project-list/project-view/project-view-transfer-history/transfer-history-entry';
import { ViewTransferRequest } from '../../../projects/project-list/project-view/project-view-transfer-history/view-transfer-request';
import { Observable } from 'rxjs/internal/Observable';
import { StockViewTransfersService } from './stock-view-transfers.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { mergeMap } from 'rxjs/operators';
import { StockService } from '../../../shared/services/stock.service';
import { PageEvent } from '@angular/material/paginator';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class StockViewTransfersDatasourceService {
  private _stockId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _historyEntries: BehaviorSubject<TransferHistoryEntry[]> = new BehaviorSubject<TransferHistoryEntry[]>([]);
  private _selectedEntry: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _transferRequests: BehaviorSubject<ViewTransferRequest[]> = new BehaviorSubject<ViewTransferRequest[]>([]);

  private _length = new BehaviorSubject(0);
  private _pageIndex = new BehaviorSubject(0);
  private _pageSize = new BehaviorSubject(25);

  private _hasTransferRequestWorkflow: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public readonly historyEntries: Observable<TransferHistoryEntry[]> = this._historyEntries.asObservable();
  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly selectedEntry: Observable<string> = this._selectedEntry.asObservable();
  public readonly transferRequests: Observable<ViewTransferRequest[]> = this._transferRequests.asObservable();
  public readonly hasTransferRequestWorkflow: Observable<boolean> = this._hasTransferRequestWorkflow.asObservable();

  public set stockId(id: string) {
    this._stockId.next(id);
  }

  public get pageIndex(): number {
    return this._pageIndex.getValue();
  }

  constructor(private stockTransferService: StockViewTransfersService, private stockService: StockService, ) {
  }

  public hasStockTransferRequestWorkflow(): Observable<boolean> {
    return this.stockService.hasStockTransferRequestWorkflow();
  }

  public getTransferRequests(stockId: string, hasPermissionForTransferRequests: boolean): void {
    const archive = this.stockTransferService.getArchivedTransfers(stockId);

    const pending = hasPermissionForTransferRequests ? this.stockTransferService.getPendingTransfers(stockId) : of([]);

    combineLatest([pending, archive]).pipe(
      mergeMap(
        (data) => {
          return of(data[0].concat(data[1]))
        }),
      untilDestroyed(this)
    ).subscribe({
      next: (transfer) => {
        this._transferRequests.next(transfer);
      }
    });
  }

  public getLength(): Observable<number> {
    return this._length.asObservable();
  }

  public getPageSize(): Observable<number> {
    return this._pageSize.asObservable();
  }

  public getPageIndex(): Observable<number> {
    return this._pageIndex.asObservable();
  }

  public handlePageEvent(event: PageEvent) {
    if (this.isPageSizeChanged(event.pageSize) || this.isPageIndexChanged(event.pageIndex)) {
      this._pageIndex.next(event.pageIndex);
      this._pageSize.next(event.pageSize);
      this.updateStockTransfers();
    }
  }

  private isPageSizeChanged(pageSize: number): boolean {
    return pageSize !== this._pageSize.getValue();
  }

  private isPageIndexChanged(pageIndex: number): boolean {
    return pageIndex !== this._pageIndex.getValue();
  }

  public resetPageIndex(): void {
    this._pageIndex.next(0);
  }

  public updateStockTransfers(): void {
    if (this._loading.value) {
      return;
    }

    this._loading.next(true);

    this.stockTransferService
      .getStockTransfers(this._stockId.value, this._pageIndex.value, this._pageSize.value)
      .subscribe(res => {
        this._length.next(res.totalElements);
        this._historyEntries.next(res.content);
        this._loading.next(false);
      });
  }

  public updateLoadingStatus(status: boolean): void {
    this._loading.next(status);
  }

}
