import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { PagedResponse } from '../contract/page-response.interface';
import { PageEvent } from '@angular/material/paginator';

export type PageableDataSourceConfiguration<T> = {
  initialValue?: T[];
  initialPageSize?: number;
};

export type PageableParams = {
  page: number;
  size: number;
  sort: string | string[];
};

const DEFAULT_PAGE_SIZE = 25;

export abstract class PageableDataSource<T> extends DataSource<T> {

  public readonly _data: BehaviorSubject<T[]>;

  protected _pageIndex = 0;
  protected _pageSize;
  protected _totalElements = 0;
  protected _sort: string | string[] = '';

  protected constructor(private configurations?: PageableDataSourceConfiguration<T>) {
    super();

    this._data = new BehaviorSubject<T[]>(configurations?.initialValue ?? []);
    this._pageSize = configurations?.initialPageSize ?? DEFAULT_PAGE_SIZE;
  }

  public get pageIndex(): number {
    return this._pageIndex;
  }

  public get pageSize(): number {
    return this._pageSize;
  }

  public get sort(): string | string[] {
    return this._sort;
  }

  public get totalElements(): number {
    return this._totalElements;
  }

  public connect(): Observable<T[]> {
    return merge(this._data);
  }

  public disconnect() {}

  public changePage(page: PageEvent,
                    updateData: boolean = true): void {
    this._pageIndex = page?.pageIndex;
    this._pageSize = page?.pageSize;

    if (updateData) {
      this.updateListing();
    }
  }

  public changeSorting(sort: string | string[],
                       updateData: boolean = true): void {
    this._sort = sort;

    if (updateData) {
      this.updateListing();
    }
  }

  public updateListing(): void {
    const params: PageableParams = {
      page: this.pageIndex,
      size: this.pageSize,
      sort: this.sort
    };

    this.fetchData(params).subscribe((response: PagedResponse<T>) => {
      this._totalElements = response.totalElements;
      this._pageIndex = response.number;
      this._pageSize = response.size;
      this._data.next(response.content);
    });
  }

  protected abstract fetchData(params: PageableParams): Observable<PagedResponse<T>>;
}
