import { TransferItem } from '../model/transfer-item';

class TransferItemSearchTree {
  private allDescendants: TransferItem[] = [];

  constructor(private ancestor: TransferItem) {
  }

  public addDescendant(item: TransferItem): void {
    this.allDescendants.push(item);
  }

  public getMatchItems(term: string): TransferItem[] {
    if (this.isAncestorMatch(term)) {
      return this.getAllItems();
    }

    const descendants = this.getMatchDescendants(term);
    return descendants.length > 0 ? [this.ancestor, ...descendants] : [];
  }

  private isAncestorMatch(term: string): boolean {
    return this.transferItemSearchMatch(this.ancestor, term);
  }

  private getAllItems(): TransferItem[] {
    return [this.ancestor, ...this.allDescendants];
  }

  private getMatchDescendants(term: string): TransferItem[] {
    return this.allDescendants.filter(item => this.transferItemSearchMatch(item, term));
  }

  private transferItemSearchMatch(item: TransferItem, term: string): boolean {
    return item.matchesName(term)
      || item.matchesInternalNumber(term)
      || item.matchesSourceName(term);
  }
}

export class TransferItemsSearchMatcher {
  private itemsMap: Map<string, TransferItem>;
  private itemTrees = new Map<string, TransferItemSearchTree>();

  constructor(items: TransferItem[] = []) {
    this.itemsMap = new Map<string, TransferItem>(items.map(item => [item.transferId, item]));
    this.buildMapItemTrees(items);
  }

  public getMatchItemIds(term: string): Set<string> {
    const matchItems = !term
      ? [...this.itemsMap.values()]
      : [...this.itemTrees.values()]
          .reduce((acc, tree) => [...acc, ...tree.getMatchItems(term)], []);

    return new Set(matchItems.map(({ id }) => id));
  }

  private buildMapItemTrees(items: TransferItem[]): void {
    items.forEach((item: TransferItem) => {
      const ancestor = this.getAncestorForDescendant(item);
      this.addDescendantToTrees(ancestor, item);
    });
  }

  private getAncestorForDescendant(item: TransferItem): TransferItem {
    return this.itemsMap.get(item.equipmentContainerId) || item;
  }

  private addDescendantToTrees(ancestor: TransferItem, descendant: TransferItem): void {
    const tree = this.itemTrees.get(ancestor.transferId);
    if (tree) {
      tree.addDescendant(descendant);
    } else {
      this.createTreeAndAddDescendant(ancestor, descendant);
    }
  }

  private createTreeAndAddDescendant(root: TransferItem, descendant: TransferItem): void {
    const tree = new TransferItemSearchTree(root);
    tree.addDescendant(descendant);
    this.itemTrees.set(root.transferId, tree);
  }
}

