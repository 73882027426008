import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';
import { AdditionalFieldValue } from '../../../../../../osp-ui/rich-components/bh-additional-field/additional-field';

@Pipe({
  name: 'additionalFieldNameIncludes'
})
export class AdditionalFieldNameIncludesPipe implements PipeTransform {

  constructor(private translatableStringResolver: TranslatableStringPipe) {}

  public transform(term: string, field: AdditionalFieldValue): boolean {
    const name = this.translatableStringResolver.transform(field?.additionalFieldName);
    return (name || '').toLowerCase().includes((term || '').toLowerCase());
  }

}
