import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BasicWidget } from '../basic-widget/basic-widget';
import { TransferRequestWidgetStoreService } from './transfer-request-widget-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { DeclineTransferRequestCommand } from '../../../disposition/contract/decline-transfer-request-command';
import { environment } from '../../../../../environments/environment';
import { AcknowledgeTransferRequestCommand } from '../../../disposition/contract/acknowledge-transfer-request-command';
import { ProjectTransferHistoryService } from '../../../disposition/projects/project-list/project-view/project-view-transfer-history/project-transfer-history.service';
import { LanguageService } from '../../../../shared/services/language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewTransferRequest } from '../../../disposition/projects/project-list/project-view/project-view-transfer-history/view-transfer-request';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSync } from '@fortawesome/pro-light-svg-icons';

@UntilDestroy()
@Component({
  selector: 'bh-transfer-requests-widget',
  templateUrl: './transfer-request-widget.component.html',
  styleUrls: ['./transfer-request-widget.component.scss'],
  providers: [TransferRequestWidgetStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferRequestWidgetComponent extends BasicWidget implements OnInit, OnDestroy {
  public readonly faSync: IconDefinition = faSync;

  public endOfList: Observable<boolean>;
  public loading: Observable<boolean>;
  public noEntries: Observable<boolean>;

  public historyEntries: ViewTransferRequest[] = [];

  constructor(private store: TransferRequestWidgetStoreService,
              private cdr: ChangeDetectorRef,
              private projectTransferHistoryService: ProjectTransferHistoryService,
              private languageService: LanguageService,
              private snackBar: MatSnackBar,
  ) {
    super();
  }

  ngOnInit(): void {
    this.noEntries = this.store.noEntries;
    this.endOfList = this.store.endOfList;
    this.loading = this.store.loading;

    this.onScroll(true);
    this.subscribeToHistoryEntries();
  }

  ngOnDestroy(): void {
  }

  public onScroll(initialLoad?: boolean): void {
    this.store.scroll(initialLoad);
  }

  public declineTransferRequest(transferId: string): void {
    this.projectTransferHistoryService.declineTransferRequest(new DeclineTransferRequestCommand(transferId))
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        setTimeout(() => {
          this.snackBar.open(
            this.translate('modules.disposition.projectTransferHistory.transferRequestDeclined'),
            undefined,
            {duration: 2000});
          this.onScroll(true);
        }, environment.DELAY_SHORT), () =>
        this.snackBar.open(
          this.translate('modules.disposition.projectTransferHistory.transferRequestNotDeclined'),
          undefined,
          {duration: 2000}))
  }

  public acknowledgeTransferRequest(transferId: string) {
    this.projectTransferHistoryService.acknowledgeTransferRequest(new AcknowledgeTransferRequestCommand(transferId))
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        setTimeout(() => {
          this.snackBar.open(
            this.translate('modules.disposition.projectTransferHistory.transferRequestAccepted'),
            undefined,
            {duration: 2000});
          this.onScroll(true);
        }, environment.DELAY_SHORT), () =>
        this.snackBar.open(
          this.translate('modules.disposition.projectTransferHistory.transferRequestNotAccepted'),
          undefined,
          {duration: 2000}))
  }

  private subscribeToHistoryEntries(): void {
    this.store.historyEntries
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this.historyEntries = data;
          this.cdr.markForCheck();
        },
      });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
