<div class="step-dialog">

  <div matDialogTitle fxLayout="row" fxLayoutGap="15px">
    <fa-icon *ngIf="icon" class="title-icon" [icon]="icon"></fa-icon>
    <mat-icon *ngIf="fontSet" class="title-icon" [fontSet]="fontSet"></mat-icon>
    <span *ngIf="title">{{title}}</span>
  </div>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close="abort" aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">
    <ng-content></ng-content>
  </mat-dialog-content>
</div>
