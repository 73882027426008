<div class="transfers" fxLayout="column">
  <div class="transfers-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <bh-title fxFlex class="title-header" [text]="'modules.disposition.stockViewTransfer.stockTransfers' | translate"></bh-title>
  </div>

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms">
      <mat-tab [label]="'modules.disposition.stockViewTransfer.finished' | translate" [tabIndex]="0">
        <div class="transfer-history-wrapper" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
          <div class="transfer-history">
            <mat-accordion [@.disabled]="true">
              <bh-transfer-history-entry
                class="history-entry"
                *ngFor="let transfer of transfers"
                [transferType]="transfer.transferType"
                [isDeposit]="transfer.transactionType === 'DEPOSIT'"
                [transferDestination]="transfer.transferDestination"
                [targetProjectNumber]="transfer.targetProjectNumber"
                [targetProjectCostCenter]="transfer.targetProjectCostCenter"
                [targetStockCostCenter]="transfer.targetStockCostCenter"
                [timeStamp]="transfer.transferTimeStamp"
                [issuer]="transfer.createdBy"
                [acknowledgedBy]="transfer.acknowledgedBy"
                [acknowledgeDate]="transfer.acknowledgeDate"
                [transferItems]="transfer.items"
                [internalEmployeeFirstName]="transfer.employeeFirstName"
                [internalEmployeeLastName]="transfer.employeeLastName"
                [externalEmployee]="transfer.externalEmployee"
                [comment]="transfer.comment"
                [attr.id]="getCombinedId(transfer)"
                [transferId]="transfer.transferId"
                [transferCreateDate]="transfer.transferCreateDate"
                [transferDate]="transfer.transferDate"
                (onRevertRequest)="revertRequest($event)">
              </bh-transfer-history-entry>
              <bh-text-line *ngIf="!transfers.length" [text]="'modules.disposition.stockViewTransfer.noAvailableEntries'|translate"></bh-text-line>
            </mat-accordion>
          </div>
          <div class="loading-overlay-panel" *ngIf="loading | async">
            <div class="loading-overlay"
                 [attr.aria-label]="'modules.disposition.stockViewTransfer.loadedEvents'|translate"
                 [class.panel-visible]="loading | async">
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <mat-paginator
            *ngIf="stockViewTransfersDatasource && transfers?.length > 0"
            [length]="stockViewTransfersDatasource.getLength() | async"
            [pageIndex]="stockViewTransfersDatasource.getPageIndex() | async"
            [pageSize]="stockViewTransfersDatasource.getPageSize() | async"
            (page)="stockViewTransfersDatasource.handlePageEvent($event)"
            [pageSizeOptions]="[5, 25, 50, 100]">
          </mat-paginator>
      </div>
      </mat-tab>

      <mat-tab *ngIf="isRequestsTab"
               [label]="'modules.disposition.stockViewTransfer.requests' | translate"
               [tabIndex]="1">
        <div class="transfer-history-wrapper" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
          <div class="transfer-history">
            <mat-accordion>
              <div *ngIf="selectedTransferRequest">
                <bh-text-line [text]="'modules.disposition.projectTransferHistory.declinedRequestInfo' | translate"></bh-text-line>
                <bh-transfer-history-entry
                  class="history-entry"
                  [transferType]="selectedTransferRequest.transferType"
                  [timeStamp]="selectedTransferRequest.transferRequestDate"
                  [transferDestination]="stockName"
                  [transferItems]="selectedTransferRequest.transferEntries"
                  [issuer]="selectedTransferRequest.submittedBy"
                  [isDeposit]="false"
                  [internalEmployeeFirstName]="selectedTransferRequest.employeeFirstName"
                  [internalEmployeeLastName]="selectedTransferRequest.employeeLastName"
                  [externalEmployee]="selectedTransferRequest.externalEmployee"
                  [comment]="selectedTransferRequest.comment"
                  [transferId]="selectedTransferRequest.transferId"
                  [attr.id]="getCombinedId(selectedTransferRequest)"
                  (onDeclineTransferRequest)="declineTransferRequest($event)"
                  (onAcknowledgeTransferRequest)="acknowledgeTransferRequest($event)"
                  [transferRequestStateChangeDate]="selectedTransferRequest.transferRequestStateChangeDate"
                  [transferRequestChangedBy]="selectedTransferRequest.statusChangedBy"
                  [isRequest]="true"
                  [requestState]="selectedTransferRequest.state"
                  [isPermissionForTransferRequests]="isAcceptanceUser">
                </bh-transfer-history-entry>
                <bh-text-line [text]="'modules.disposition.projectTransferHistory.transferRequestOther' | translate"></bh-text-line>
              </div>

              <bh-transfer-history-entry
                class="history-entry"
                *ngFor="let transfer of transferRequests, let i = index"
                [transferType]="transfer.transferType"
                [timeStamp]="transfer.transferRequestDate"
                [transferDestination]="stockName"
                [targetProjectNumber]="transfer.targetProjectNumber"
                [targetProjectCostCenter]="transfer.targetProjectCostCenter"
                [targetStockCostCenter]="transfer.targetStockCostCenter"
                [transferItems]="transfer.transferEntries"
                [issuer]="transfer.submittedBy"
                [isDeposit]="false"
                [revertable]="false"
                [internalEmployeeFirstName]="transfer.employeeFirstName"
                [internalEmployeeLastName]="transfer.employeeLastName"
                [externalEmployee]="transfer.externalEmployee"
                [comment]="transfer.comment"
                [transferId]="transfer.transferId"
                [attr.id]="getCombinedId(transfer)"
                (onDeclineTransferRequest)="declineTransferRequest($event)"
                (onAcknowledgeTransferRequest)="acknowledgeTransferRequest($event)"
                [transferRequestStateChangeDate]="transfer.transferRequestStateChangeDate"
                [transferRequestChangedBy]="transfer.statusChangedBy"
                [isRequest]="true"
                [requestState]="transfer.state"
                [isPermissionForTransferRequests]="isAcceptanceUser">
              </bh-transfer-history-entry>
              <bh-text-line *ngIf="!transferRequests.length"
                            [text]="'modules.disposition.stockViewTransfer.noAvailableEntries'|translate">
              </bh-text-line>
            </mat-accordion>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
