import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateRange } from '../../../../../../shared/components/date-range-select-component/date-range';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewEquipmentProjectAssignment } from '../../../../contract/view-equipment-project-assignment.interface';
import { DatesService } from '../../../../../../shared/services/dates.service';
import moment from 'moment';

@UntilDestroy()
@Directive()
export class BaseEquipmentDispositionFormItem implements OnInit, OnDestroy {

  @Input() equipmentDisposition: UntypedFormGroup;
  @Input() removable: boolean;
  @Input() projectRange: DateRange;
  @Input() startDate: UntypedFormControl;

  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();
  @Output() onOverwriteChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  constructor() {
  }

  public ngOnInit(): void {
    this.equipmentDisposition.get('overwrite').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.onOverwriteChange.emit(this.equipmentDisposition);
      });

    this.startDate.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.equipmentDisposition.get('overwrite').value) {
          this.equipmentDisposition.patchValue({ 'overwrite': false }, {emitEvent: false});
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public get equipmentName(): string {
    return this.equipmentDisposition.get('equipmentName').value;
  }

  public isSubEquipment(): boolean {
    return this.equipmentDisposition.get('isSubEquipment').value;
  }

  public get conflicts(): ViewEquipmentProjectAssignment[] {
    return this.equipmentDisposition.getError('conflicts') ?
      this.equipmentDisposition.getError('conflicts') :
      [];
  }

  public get warnings(): ViewEquipmentProjectAssignment[] {
    return this.equipmentDisposition.get('warnings').value ?
      this.equipmentDisposition.get('warnings').value :
      [];
  }

  public get assignmentRequests(): ViewEquipmentProjectAssignment[] {
    return this.equipmentDisposition.get('assignmentRequests').value ?
      this.equipmentDisposition.get('assignmentRequests').value :
      [];
  }

  public noErrorsOrWarnings(): boolean {
    return (!this.equipmentDisposition.hasError('conflicts') && this.warnings.length === 0);
  }

  public noOpenAssignmentRequests(): boolean {
    return this.assignmentRequests.length === 0;
  }

  public remove() {
    this.onRemove.emit();
  }

}
