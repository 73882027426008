<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="end end" class="m-list-header">
    <div class="m-list-header__item">
      <div class="icon-wrapper start">
        <button mat-button mat-icon-button [attr.aria-label]="'general.map.s'|translate" (click)="navigateToMap()">
          <mat-icon fontSet="icon-map_outline"></mat-icon>
        </button>
      </div>
    </div>
    <form class="m-list-header__form" fxFlex [formGroup]="searchForm" novalidate>
      <mat-form-field>
        <input matInput placeholder="{{'general.search'|translate}} ..." formControlName="terms"
               (keydown.enter)="$event.preventDefault()">
        <button mat-button *ngIf="termsValue" matSuffix mat-icon-button [attr.aria-label]="'general.labels.clear'|translate"
                (click)="reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <div class="m-list-header__item">
      <div class="icon-wrapper end">
        <button mat-button matSuffix mat-icon-button [attr.aria-label]="'general.scanCode'|translate"
                (click)="openScanSearch()">
          <mat-icon svgIcon="scan-code-scan"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="m-list-content-wrapper" fxFlex>
    <mat-list class="m-list-content"
              fxFlexFill
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="false"
              (scrolled)="onScroll()">

      <div *ngFor="let mainEquipment of equipments">
        <mat-list-item class="m-list-content__item" (click)="selectEquipmentAndNavigate(mainEquipment.equipmentId)">
          <p matListItemTitle class="line-one">
            {{mainEquipment.equipmentType?.category2 | uppercase}}
            <mat-icon svgIcon="qr-code" class="linked-scan-code"
                      *ngIf="mainEquipment.equipmentScanCode"></mat-icon>
          </p>
          <p matListItemLine class="line-two">{{mainEquipment | equipmentName}}</p>
          <p matListItemLine class="line-three">
            {{ mainEquipment.equipmentSerialNumber ? mainEquipment.equipmentSerialNumber
              : 'modules.equipment.equipmentList.noSerialNumber'|translate }}
          </p>
          <mat-divider></mat-divider>
        </mat-list-item>

        <mat-list-item class="m-list-content__item"
                       *ngFor="let subEquipment of mainEquipment.subEquipmentList"
                       (click)="selectEquipmentAndNavigate(subEquipment.equipmentId)">
          <p matListItemTitle class="line-one">
            {{subEquipment.equipmentType?.category2 | uppercase}}
            <mat-icon svgIcon="qr-code" class="linked-scan-code"
                      *ngIf="subEquipment.equipmentScanCode"></mat-icon>
          </p>
          <p matListItemLine class="line-two">{{subEquipment | equipmentName}}</p>
          <p matListItemLine class="line-three">
            {{ subEquipment.equipmentSerialNumber ? subEquipment.equipmentSerialNumber
              : 'modules.equipment.equipmentList.noSerialNumber'|translate }}
          </p>
          <mat-divider></mat-divider>
        </mat-list-item>
      </div>

      <mat-list-item *ngIf="isLoading">
        <mat-spinner class="m-list-content__item__spinner" [strokeWidth]="3" [diameter]="40"></mat-spinner>
      </mat-list-item>

      <div *ngIf="!equipments || equipments.length === 0">
        <mat-list-item class="centered-text">
          {{'modules.equipment.equipmentList.noEquipmentFound'|translate}}
        </mat-list-item>
        <mat-list-item>
          <button class="big-button" (click)="navigateToCreateEquipment()">
            {{'modules.equipment.base.createNewEquipment'|translate}}
          </button>
        </mat-list-item>
      </div>
    </mat-list>
  </div>
</div>
