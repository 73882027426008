import { filter } from 'rxjs/operators';
import { EquipmentInvoice } from './../../../../../../contract/equipment-invoice.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LifeCycleDamage } from './../../../../../../contract/life-cycle-damage';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';

@UntilDestroy()
@Component({
  selector: 'bh-damage-event',
  templateUrl: './damage-event.component.html'
})
export class DamageEventComponent implements OnInit {
  @Input() equipmentId: string;
  @Input() damageLifeCycle: LifeCycleDamage;

  public invoice: EquipmentInvoice;

  constructor(private equipmentsService: EquipmentsService,
              private languageService: LanguageService) { }

  public ngOnInit(): void {
    if (this.equipmentId && this.damageLifeCycle) {
      const { equipmentCostId: invoiceId, invoiceNumberRef: invoiceNumber } = this.damageLifeCycle;
      this.getInvoice(this.equipmentId, invoiceId, invoiceNumber);
    }
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private getInvoice(equipmentId: string, invoiceId: string, invoiceNumber: string): void {
    if (equipmentId && invoiceId) {
      this.equipmentsService.getInvoiceDetails(equipmentId, invoiceId, invoiceNumber)
      .pipe(
        filter(Boolean),
        untilDestroyed(this))
      .subscribe(
        (res: EquipmentInvoice) => this.invoice = res,
        error => console.log('error get invoice details: ', error));
    }
  }
}
