import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { KeyValue } from '@angular/common';
import { DateValidator } from 'app/shared/custom-validators/date.validator';
import moment from 'moment';
import { faSync, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { Month } from 'app/shared/contract/month';
import { LanguageService } from 'app/shared/services/language.service';
import { AddQualificationDialogData } from 'app/modules/disposition/contract/add-qualification-dialog-data.interface';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { EmployeeQualification } from 'app/modules/disposition/contract/employee-qualification';
import { PeriodRetakeInterval } from 'app/modules/disposition/contract/period-retake-interval';
import { Retake } from 'app/modules/disposition/contract/retake.enum';
import { Qualification } from 'app/modules/disposition/contract/qualification';
import { IntervalRetake } from 'app/modules/disposition/contract/interval-retake';
import { PeriodType } from 'app/modules/disposition/contract/period-type';
import { FixedDateRetakeInterval } from 'app/modules/disposition/contract/fixed-date-retake-interval';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';
import { QualificationCreateCommand } from 'app/modules/disposition/contract/qualification-create-command';
import { EmployeeQualificationAssignCommand } from 'app/modules/disposition/contract/employee-qualification-assign-command';
import { QualificationUpdateCommand } from 'app/modules/disposition/contract/qualification-update-command';
import { UpdateEmployeeQualificationAssignmentCommand } from 'app/modules/disposition/contract/update-employee-qualification-assignment-command';
import { DatesService } from 'app/shared/services/dates.service';

@Component({
  selector: 'bh-qualification-add-edit',
  templateUrl: './qualification-add-edit.component.html',
  styleUrls: ['./qualification-add-edit.component.scss']
})
export class QualificationAddEditComponent implements OnInit {

  public readonly faSync: IconDefinition = faSync;

  @ViewChild('lastRenewalDateInput') set lastRenewalDateInput(content: ElementRef) {
    this.qualificationAddEditForm.get('qualificationLastRenewal').setValidators([Validators.required,
        DateValidator.isValidDate(this.langService),
        this.pastOrTodayDateValidator.bind(this)]);

    this.qualificationAddEditForm.get('qualificationAcquisitionDate').setValidators([
      DateValidator.isValidDate(this.langService),
      this.acquisitionValidator.bind(this)]);
  }

  @ViewChild('hightWarningThreshold') set hightWarningThreshold(content: ElementRef) {
    this.qualificationAddEditForm.get('qualificationHightWarningThreshold').setValidators([Validators.required,
       this.thresholdValidator.bind(this)]);
  }

  public currentQualification: EmployeeQualification;
  public months: Month[];

  public qualificationAddEditForm: UntypedFormGroup;
  public nameOptions: string[] = [];
  public retakeOptions: KeyValue<string, Retake>[];
  public periodOptions: string[] = [this.translate('modules.disposition.addQualification.days'),
  this.translate('modules.disposition.addQualification.months')];
  public eachYearDayMax: number;
  public eachYearMonthOptions: string[] = [];
  public filteredOptions: Observable<string[]>;
  public duplicateButtonDisabled: boolean;
  public maxEndDate: Date;

  private existedQualifications: EmployeeQualification[];
  private existedQualificationTypes: Qualification[] = [];
  private allQualificationTypes: Qualification[] = [];
  private duplicated: boolean;
  private employeeId: string;
  private customerId: string;


  constructor(public dialogRef: MatDialogRef<QualificationAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddQualificationDialogData,
    private formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    public employeeStore: EmployeeManagementDatasource,
    private langService: LanguageService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.retakeOptions = [{key: this.translate('modules.disposition.addQualification.noRepetition'),
     value: Retake.NO_RETAKE},
    {key: this.translate('modules.disposition.addQualification.afterTimeInterval'), value: Retake.INTERVAL},
    {key: this.translate('modules.disposition.addQualification.everyYear'), value: Retake.FIXED_DAY_IN_YEAR}]
    this.duplicateButtonDisabled = true;
    this.eachYearDayMax = 31;
    this.maxEndDate = new Date();
    this.duplicated = false;
    this.employeeId = this.data['employeeId'];
    this.customerId = this.data['customerId'];
  }

  private buildForm(): void {
    this.existedQualifications = this.data['employeeQualifications'];
    this.allQualificationTypes = this.data['allQualifications'];
    this.currentQualification = this.data['existedQualification'];

    this.setMonths(this.langService.getCurrentLanguage());

    this.qualificationAddEditForm = this.formBuilder.group({
      qualificationName: new UntypedFormControl('', [this.nameValidator.bind(this), this.emptyOrSpacesValidator.bind(this),
        Validators.maxLength(FieldLimit.LONG_IDENTIFIER), this.nameOnQualificationEditValidator.bind(this)]),
      qualificationDescription: ['', [Validators.maxLength(FieldLimit.LONG_DESCRIPTION)]],
      qualificationAcquisitionDate: new UntypedFormControl(new Date(), [DateValidator.isValidDate(this.langService),
        this.pastOrTodayDateValidator.bind(this)]),
      qualificationRetake: [Retake.NO_RETAKE],
      qualificationRetakeIntervalRegularity: [1, [Validators.min(1), Validators.max(999), Validators.required]],
      qualificationRetakeIntervallPeriod: [this.translate('modules.disposition.addQualification.days')],
      qualificationLastRenewal: new UntypedFormControl ({value: new Date(), disabled: this.currentQualification}),
      qualificationMediumWarningThreshold: [2, [Validators.min(1), Validators.required]],
      qualificationHightWarningThreshold: [1, [Validators.min(1), Validators.required]],
      qualificationRetakeEachYearMonth: ['01', [Validators.required]],
      qualificationRetakeEachYearDay: [1, [Validators.required, Validators.min(1),
         (control: AbstractControl) => Validators.max(this.eachYearDayMax)(control)]]
    });

    if (this.currentQualification) {
      this.fillForm(true);
    } else {
      this.setNamesOptions();
    }

    this.setExistedQualificationTypes();
  }

  private fillForm(withType: boolean): void {
    if (withType) {
      this.qualificationAddEditForm.patchValue({
        qualificationRetake: this.currentQualification.qualificationRetakeType,
      });
    }

    this.qualificationAddEditForm.patchValue({
      qualificationName: this.currentQualification.qualificationName,
      qualificationDescription: this.currentQualification.qualificationDescription,
      qualificationAcquisitionDate: this.currentQualification.acquisitionDate,
      qualificationLastRenewal: this.qualificationAddEditForm.controls['qualificationRetake'].value ===
       Retake.NO_RETAKE ? new Date() : this.currentQualification.lastRenewal
    });

    this.qualificationAddEditForm.controls['qualificationLastRenewal'].disable();

    switch (this.currentQualification.qualificationRetakeType) {
      case Retake.FIXED_DAY_IN_YEAR: {
        this.qualificationAddEditForm.patchValue({
          qualificationRetakeEachYearDay: this.currentQualification.eachYearRetakeDate,
          qualificationRetakeEachYearMonth: moment(this.currentQualification.eachYearRetakeMonth, 'M').format('MM'),
          qualificationMediumWarningThreshold: this.currentQualification.qualificationMediumWarningThreshold,
          qualificationHightWarningThreshold: this.currentQualification.qualificationHighWarningThreshold
        });
        break;
      }
      case Retake.INTERVAL: {
        this.qualificationAddEditForm.patchValue({
          qualificationRetakeIntervalRegularity: this.currentQualification.interval.regularity,
          qualificationRetakeIntervallPeriod: this.currentQualification.interval.period === PeriodType.day ?
           this.translate('modules.disposition.addQualification.days') :
           this.translate('modules.disposition.addQualification.months'),
          qualificationMediumWarningThreshold: this.currentQualification.qualificationMediumWarningThreshold,
          qualificationHightWarningThreshold: this.currentQualification.qualificationHighWarningThreshold
        });
        break;
      }
    }
  }

  private setNamesOptions(): void {
    this.nameOptions = [];
    this.allQualificationTypes.forEach(qualification => {
      this.nameOptions.push(qualification.qualificationName);
    })
  }

  private filterNamesOptions(name: string) {
    this.setNamesOptions() // reset nameOptions list
    this.nameOptions = this.nameOptions.filter(qualification => qualification.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  public setMonths(lang: string): void {
    moment.locale(lang);
    this.months = moment.months().map(month => {
      return {
        name: month,
        value: moment().month(month).format('MM')
      };
    });
  }

  public setMonthDayLimitation(chosenMonth: any): void {
    this.eachYearDayMax = moment(chosenMonth).daysInMonth();
    this.qualificationAddEditForm.controls['qualificationRetakeEachYearDay'].updateValueAndValidity();
  }

  public nameChanged(event: any): void {
    this.filterNamesOptions(event)
    const chosenQualification = this.getQualificationType(event);
    this.duplicated = false;
    if (chosenQualification !== undefined && !this.currentQualification) {
      this.changeDisableStatus(false);
      switch (chosenQualification.qualificationRetakeType) {
        case Retake.FIXED_DAY_IN_YEAR:
          this.qualificationAddEditForm.patchValue({
            qualificationRetakeEachYearMonth: moment(chosenQualification.fixedDateRetakeInterval.month, 'M').format('MM'),
            qualificationRetakeEachYearDay: chosenQualification.fixedDateRetakeInterval.day,
            qualificationHightWarningThreshold: chosenQualification.fixedDateRetakeInterval.highWarningThreshold,
            qualificationMediumWarningThreshold: chosenQualification.fixedDateRetakeInterval.mediumWarningThreshold,
          });
          break;
        case Retake.INTERVAL:
          const days = moment.duration(chosenQualification.periodRetakeInterval.retakeInterval).get('days');
          const months = moment.duration(chosenQualification.periodRetakeInterval.retakeInterval).get('months')
            + moment.duration(chosenQualification.periodRetakeInterval.retakeInterval).get('years') * 12;
          this.qualificationAddEditForm.patchValue({
            qualificationRetakeIntervalRegularity: months === 0 ? days : months,
            qualificationRetakeIntervallPeriod: months === 0 ?
             this.translate('modules.disposition.addQualification.days') :
             this.translate('modules.disposition.addQualification.months'),
            qualificationHightWarningThreshold: chosenQualification.periodRetakeInterval.highWarningThreshold,
            qualificationMediumWarningThreshold: chosenQualification.periodRetakeInterval.mediumWarningThreshold
          });
          break;
      }
      this.qualificationAddEditForm.patchValue({
        qualificationDescription: chosenQualification.qualificationDescription,
        qualificationRetake: chosenQualification.qualificationRetakeType
      });
      this.changeDisableStatus(true)
    } else {
      this.changeDisableStatus(false);
    }
  }

  public getQualificationType(name: string): Qualification {
    if (this.allQualificationTypes) {
      return this.allQualificationTypes.filter(qualification => qualification.qualificationName === name)[0];
    }
    return null;
  }

  public saveChanges(): void {
    if (this.isValid()) {
      const formValue = this.qualificationAddEditForm.getRawValue();
      let lastRenewalDate = new Date();
      const acquisitionDate = formValue.qualificationAcquisitionDate;
      let retakeType = Retake.NO_RETAKE;
      const qualificationName = formValue.qualificationName.trim();
      const qualificationDescription = formValue.qualificationDescription;
      let fixedDateRetakeInterval = null;
      let periodRetakeInterval = null;
      switch (formValue.qualificationRetake) {
        case Retake.INTERVAL:
          periodRetakeInterval = new PeriodRetakeInterval();
          retakeType = Retake.INTERVAL;
          lastRenewalDate = formValue.qualificationLastRenewal;
          let newInterval = new IntervalRetake();
          newInterval.period =
            formValue.qualificationRetakeIntervallPeriod === this.translate('modules.disposition.addQualification.months')
              ? PeriodType.month
              : PeriodType.day;
          newInterval.regularity = formValue.qualificationRetakeIntervalRegularity;
          if (newInterval.period === PeriodType.month) {
            periodRetakeInterval.retakeInterval = moment.duration(newInterval.regularity, 'months')
          } else {
            periodRetakeInterval.retakeInterval = moment.duration(newInterval.regularity, 'days')
          }
          periodRetakeInterval.mediumWarningThreshold = formValue.qualificationMediumWarningThreshold;
          periodRetakeInterval.highWarningThreshold = formValue.qualificationHightWarningThreshold;
          break;
        case Retake.FIXED_DAY_IN_YEAR:
          fixedDateRetakeInterval = new FixedDateRetakeInterval();
          retakeType = Retake.FIXED_DAY_IN_YEAR;
          lastRenewalDate = formValue.qualificationLastRenewal;
          fixedDateRetakeInterval.mediumWarningThreshold = formValue.qualificationMediumWarningThreshold;
          fixedDateRetakeInterval.highWarningThreshold = formValue.qualificationHightWarningThreshold;
          fixedDateRetakeInterval.month = formValue.qualificationRetakeEachYearMonth;
          fixedDateRetakeInterval.day = formValue.qualificationRetakeEachYearDay;
          break;
        case Retake.NO_RETAKE:
          retakeType = Retake.NO_RETAKE;
          break;
        default:
          retakeType = Retake.NO_RETAKE;
      }
      const qualificationId = this.getQualificationId(qualificationName);
      if (this.currentQualification) {
        if (this.employeeQualificationAssignmentUpdated(formValue) || this.qualificationUpdated(formValue)) {
          const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
          dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.editQualification.title');
          dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.editQualification.text');
          dialogRef.afterClosed().subscribe(result => {
            if (result === dialogResults.YES) {
              if (this.qualificationUpdated(formValue)) {
                const updateTypeCommand = new QualificationUpdateCommand(this.currentQualification.qualificationId,
                  qualificationName,
                  qualificationDescription,
                  retakeType,
                  fixedDateRetakeInterval,
                  periodRetakeInterval);
                this.employeeStore.updateQualification(updateTypeCommand).subscribe(() => {
                  if (this.employeeQualificationAssignmentUpdated(formValue)) {
                    const updateEmployeeQualificationCommand = new UpdateEmployeeQualificationAssignmentCommand(
                      this.currentQualification.qualificationId,
                      this.employeeId,
                      acquisitionDate.toISOString(),
                      DatesService.toIsoStartOfDay(lastRenewalDate));
                    this.employeeStore.updateQualificationAssignment(updateEmployeeQualificationCommand)
                      .subscribe(() => {
                        this.closeDialog();
                      }, error => {
                        this.closeDialog();
                      });
                  } else {
                    this.closeDialog();
                  }
                }, error => {
                  this.closeDialog();
                });
              } else if (this.employeeQualificationAssignmentUpdated(formValue)) {
                const updateEmployeeQualificationCommand = new UpdateEmployeeQualificationAssignmentCommand(
                  this.currentQualification.qualificationId,
                  this.employeeId,
                  acquisitionDate.toISOString(),
                  DatesService.toIsoStartOfDay(lastRenewalDate));
                this.employeeStore.updateQualificationAssignment(updateEmployeeQualificationCommand).subscribe(() => {
                  this.closeDialog();
                }, error => {
                  this.closeDialog();
                });
              }
            }
          });
        } else {
          this.closeDialog();
        }
      } else {
        if (qualificationId) {
          const assignCommand = new EmployeeQualificationAssignCommand(this.employeeId,
            qualificationId,
            acquisitionDate.toISOString(),
            DatesService.toIsoStartOfDay(lastRenewalDate));
          this.employeeStore.addQualificationToEmployee(assignCommand).subscribe(() => {
            this.closeDialog();
          }, error => {
            this.closeDialog();
          });
        } else {
          const newQualificationCommand = new QualificationCreateCommand(this.employeeId,
            qualificationId,
            this.customerId,
            qualificationName,
            qualificationDescription,
            retakeType,
            fixedDateRetakeInterval,
            periodRetakeInterval,
            acquisitionDate.toISOString(),
            DatesService.toIsoStartOfDay(lastRenewalDate));
          this.employeeStore.createQualification(newQualificationCommand).subscribe(() => {
            this.closeDialog();
          }, error => {
            this.closeDialog();
          });
        }
      }
    }
  }

  public isValid(): boolean {
    return this.qualificationAddEditForm.valid;
  }

  private qualificationUpdated(formValue: any): boolean {
    return this.currentQualification.qualificationDescription !== formValue.qualificationDescription ||
    this.currentQualification.qualificationName !== formValue.qualificationName.trim() ||
    this.currentQualification.qualificationRetakeType !== formValue.qualificationRetake ||
    this.eachYearDataUpdated(formValue) ||
    this.intervalDataUpdated(formValue);
  }

  private employeeQualificationAssignmentUpdated(formValue: any): boolean {
    return this.currentQualification.acquisitionDate.setHours(0, 0, 0, 0) !==
     formValue.qualificationAcquisitionDate.setHours(0, 0, 0, 0) ||
    this.currentQualification.lastRenewal.getDate() !== formValue.qualificationLastRenewal.getDate();
  }

  private eachYearDataUpdated(formValue: any): boolean {
    return this.currentQualification.qualificationRetakeType === Retake.FIXED_DAY_IN_YEAR && (
      this.currentQualification.qualificationHighWarningThreshold !== formValue.qualificationHightWarningThreshold ||
      this.currentQualification.qualificationMediumWarningThreshold !== formValue.qualificationMediumWarningThreshold ||
      this.currentQualification.eachYearRetakeDate !== formValue.qualificationRetakeEachYearDay ||
      moment(this.currentQualification.eachYearRetakeMonth, 'M').format('MM') !==
       formValue.qualificationRetakeEachYearMonth
    );
  }

  private intervalDataUpdated(formValue: any): boolean {
    return this.currentQualification.qualificationRetakeType === Retake.INTERVAL && (
      this.currentQualification.qualificationHighWarningThreshold !== formValue.qualificationHightWarningThreshold ||
      this.currentQualification.qualificationMediumWarningThreshold !== formValue.qualificationMediumWarningThreshold ||
      this.currentQualification.interval.period !== formValue.qualificationRetakeIntervallPeriod ||
      this.currentQualification.interval.regularity !== formValue.qualificationRetakeIntervalRegularity
    );
  }

  public clearFields(): void {
    this.qualificationAddEditForm.patchValue({
      qualificationRetakeIntervalRegularity: 1,
      qualificationRetakeIntervallPeriod: this.translate('modules.disposition.addQualification.days'),
      qualificationRetakeEachYearMonth: '01',
      qualificationRetakeEachYearDay: 1,
      qualificationLastRenewal: new Date(),
      qualificationMediumWarningThreshold: 2,
      qualificationHightWarningThreshold: 1
    });

    this.eachYearDayMax = 31;

    if (this.currentQualification) {
      this.fillForm(false);
    }
  }

  private checkExistedQualificationTypes(name: string): boolean {
    if (this.existedQualificationTypes) {
      return this.existedQualificationTypes.filter(qualification =>
         qualification.qualificationName === name).length !== 0;
    } else {
      return false;
    }
  }

  private checkAllQualificationTypes(name: string): boolean {
    if (this.allQualificationTypes) {
      return this.allQualificationTypes.filter(qualification =>
         qualification.qualificationName === name).length !== 0;
    } else {
      return false;
    }
  }

  private getQualificationId(name: string): string {
    const existedType = this.allQualificationTypes.filter(type => type.qualificationName === name)[0];
    return existedType ? existedType.qualificationId : null;
  }

  private setExistedQualificationTypes(): void {
    this.allQualificationTypes.forEach(type => {
      if (this.existedQualifications.filter(qualification => qualification.qualificationId ===
         type.qualificationId).length !== 0) {
        this.existedQualificationTypes.push(type);
      }
    })
  }

  private changeDisableStatus(disable: boolean): void {
    if (disable) {
      this.qualificationAddEditForm.controls['qualificationDescription'].disable();
      this.qualificationAddEditForm.controls['qualificationRetake'].disable();
      this.qualificationAddEditForm.controls['qualificationRetakeIntervallPeriod'].disable();
      this.qualificationAddEditForm.controls['qualificationRetakeIntervalRegularity'].disable();
      this.qualificationAddEditForm.controls['qualificationRetakeEachYearMonth'].disable();
      this.qualificationAddEditForm.controls['qualificationRetakeEachYearDay'].disable();
      this.qualificationAddEditForm.controls['qualificationMediumWarningThreshold'].disable();
      this.qualificationAddEditForm.controls['qualificationHightWarningThreshold'].disable();
      this.duplicateButtonDisabled = false;
    } else {
      this.qualificationAddEditForm.enable();
      if (this.currentQualification) {
        this.qualificationAddEditForm.controls['qualificationLastRenewal'].disable();
      }
      this.duplicateButtonDisabled = true;
    }
  }

  public duplicate(): void {
    this.duplicated = true;
    this.changeDisableStatus(false)
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public resetData(): void {
    this.qualificationAddEditForm.enable();
    this.qualificationAddEditForm.patchValue({
      qualificationRetakeIntervalRegularity: 1,
      qualificationRetakeIntervallPeriod: this.translate('modules.disposition.addQualification.days'),
      qualificationRetakeEachYearMonth: '01',
      qualificationRetakeEachYearDay: 1,
      qualificationAcquisitionDate: new Date(),
      qualificationLastRenewal: new Date(),
      qualificationMediumWarningThreshold: 2,
      qualificationHightWarningThreshold: 1,
      qualificationName: '',
      qualificationDescription: '',
      qualificationRetake: Retake.NO_RETAKE
    });

    this.filterNamesOptions('');

    if (this.currentQualification) {
      this.fillForm(false);
    }

    this.duplicated = false;
    this.duplicateButtonDisabled = true;
  }

  public mediumThresholdChanged(): void {
    this.qualificationAddEditForm.controls['qualificationHightWarningThreshold'].updateValueAndValidity();
  }

  public checkAcquisitionAndNextRenewalDates(): void {
    this.qualificationAddEditForm.controls['qualificationAcquisitionDate'].updateValueAndValidity();
  }

  public nameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control &&
      ((this.checkExistedQualificationTypes(control.value?.trim()) && !this.currentQualification) || this.duplicated)) {
      return {'nameInvalid': true}
    } else {
      return null;
    }
  }

  public nameOnQualificationEditValidator (control: AbstractControl): { [key: string]: boolean } | null {
    if (control && this.currentQualification && this.checkAllQualificationTypes(control.value) &&
       this.currentQualification.qualificationName !== control.value) {
      return {'nameOnEditInvalid': true}
    } else {
      return null;
    }
  }

  public emptyOrSpacesValidator(control: AbstractControl): {[key: string]: boolean} | null {
    if (control && !/\S/.test(control.value)) {
      return {'emptyOrSpaces': true}
    } else {
      return null;
    }
  }

  public pastOrTodayDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control && moment(control.value).isAfter(moment(new Date()), 'day')) {
      return {'pastOrTodayDateInvalid': true}
    } else {
      return null;
    }
  }

  public thresholdValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const mediumValue = this.qualificationAddEditForm.controls['qualificationMediumWarningThreshold'].value;
    if (control && mediumValue && control.value >= mediumValue) {
      return {'hightThresholdInvalid': true}
    } else {
        return null;
      }
  }

  public acquisitionValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let error = this.pastOrTodayDateValidator(control);
    return error === null ? this.notLaterThanLastRenewalValidator(control) : error;
  }

  private notLaterThanLastRenewalValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const lastRenewalValue = this.qualificationAddEditForm.controls['qualificationLastRenewal'].value;
    if (control && lastRenewalValue && moment(control.value).isAfter(moment(lastRenewalValue), 'day')) {
      return {'acquisitionDateInvalid': true}
    } else {
      return null;
    }
  }

  private translate(key: string): string {
    return this.langService.getInstant(key);
  }
}
