import { KeycloakService } from '../../core/keycloak';
import { RoleAuthorityGuardsComponent } from './role-authority-guards.component';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { RouterHistory } from '../router-history';

export abstract class GuardedNavigableInputComponent extends RoleAuthorityGuardsComponent {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(authService);
  }

  private get parentUrl(): string {
    const urlPath: UrlSegment[] = this.route.snapshot.parent.url;
    return urlPath[urlPath.length - 1].path;
  }

  public goBack(fallbackPath?: string): Observable<any> {
    this.routerHistory.goBack(fallbackPath);
    return new Observable<void>(observer => observer.complete());
  }

  public navigateToAdd(path = 'add', queryParams = {}, parentUrl = this.parentUrl): void {
    this.router.navigate([parentUrl, ...path.split('/')], {queryParams: queryParams});
  }

  public navigateToEdit(id: string): void {
    this.router.navigate([this.parentUrl, 'edit', id]);
  }

  public navigateAfterSubmit(path = 'list'): void {
    this.router.navigate([this.parentUrl, ...path.split('/')]);
  }

  public allowedLiveData(hasLiveData: boolean): boolean {
    return this.hasModule(this.modules.LIVE_DATA) || !hasLiveData;
  }
}
