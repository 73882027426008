import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ButtonBase } from '../base/button-base.class';


@Component({
  selector: 'bh-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent extends ButtonBase {
  @HostBinding('class')
  protected cssClass = 'osp-button osp-button-icon';
}
