<mat-sidenav-container #matSidenavContainer class="mobile-sidenav-container">
  <mat-sidenav #sidenav opened="false" mode="over" position="end" class="m-sidenav">
    <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between stretch">
      <div class="m-sidenav-menu__up" fxLayout="column" fxLayoutAlign="start stretch">
        <div class="m-sidenav-menu__item"
             [ngClass]="{'m-sidenav-menu__item--active': isItemActive(items.LANDINGPAGE)}"
             (click)="navigateTo(items.LANDINGPAGE)">
          <mat-icon>home</mat-icon>
        </div>
        <div class="m-sidenav-menu__item"
             [ngClass]="{'m-sidenav-menu__item--active': isItemActive(items.EQUIPMENTS), 'disabled': !isOnline}"
             *ngIf="hasAnyAuthority([authorities.EQUIPMENT_VIEW, authorities.PROJECT_ASSIGNEE_VIEW])"
             (click)="isOnline && navigateTo(items.EQUIPMENTS)">
          {{'general.equipm.s'|translate|uppercase}}
        </div>

        <div class="m-sidenav-menu__item"
             [ngClass]="{'m-sidenav-menu__item--active': isItemActive(items.PROJECTS), 'disabled': !isOnline}"
             *ngIf="hasModule(modules.DISPOSITION)
             && hasAnyAuthority([authorities.PROJECT_VIEW, authorities.PROJECT_ASSIGNEE_VIEW])"
             (click)="isOnline && navigateTo(items.PROJECTS)">
          {{'general.project.pl'|translate|uppercase}}
        </div>

        <div class="m-sidenav-menu__item"
             [ngClass]="{'m-sidenav-menu__item--active': isItemActive(items.ALARMS), 'disabled': !isOnline}"
             *ngIf="hasAuthority(authorities.NOTIFICATIONS_VIEW_ALARMS) && hasModule(modules.ALARMS)"
             (click)="isOnline && navigateTo(items.ALARMS)">
          {{'modules.navigation.sidebar.alarms'|translate|uppercase}}
          <div *ngIf="alarms?.length" class="badge">{{ alarms.length }}</div>
        </div>

        <div class="m-sidenav-menu__item"
             [ngClass]="{'m-sidenav-menu__item--active': isItemActive(items.DISPOSITION), 'disabled': !isOnline}"
             *ngIf="hasModule(modules.DISPOSITION)" (click)="isOnline && navigateTo(items.DISPOSITION)">
          {{'general.disposition.pl'|translate|uppercase}}
          <div *ngIf="assignmentRequests" class="badge">{{ assignmentRequests }}</div>
        </div>
      </div>
      <div>
        <div class="m-sidenav-menu__item bottom-first"
             [ngClass]="{'disabled': !isOnline}"
             (click)="isOnline && navigateTo(items.USER_SETTINGS)">
          <span>{{'modules.navigation.sidebar.administrationMenu.userSettings'|translate|uppercase}}</span>
        </div>
        <div class="m-sidenav-menu__item"
             [ngClass]="{'disabled': !isOnline}"
             (click)="isOnline && serviceHealth()"
             *ngIf="isInRole(priviledgedRole.Superadmin)">
          {{'modules.navigation.sidebar.healthService'|translate|uppercase}}
        </div>
        <div class="m-sidenav-menu__item"
             (click)="openShopLink()"
             *ngIf="hasAnyAuthority([authorities.ONESTOP_PRO_SHOP]) && (getShopUrl())">
          {{'modules.navigation.sidebar.shop'|translate|uppercase}}
        </div>
        <div class="m-sidenav-menu__item"
             (click)="appVersion()">
          {{'modules.navigation.sidebar.appVersion'|translate|uppercase}}
        </div>
        <div class="m-sidenav-menu__item bottom-last"
             [ngClass]="{'disabled': !isOnline}"
             (click)="isOnline && logoutUser($event)">
          {{'modules.navigation.sidebar.logout'|translate|uppercase}}
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
