import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RoleAuthorityGuardsComponent } from '../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../core/keycloak';
import { AssetListTypeResolver } from '../../../../shared/components/asset-list-component/asset-list-type-resolver';
import { ListType } from '../../../../shared/enums/list-type.enum';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../shared/enums/modules.enum';
import * as _ from 'lodash';
import { DispositionDataSource } from '../../shared/disposition.datasource';
import { DragAndDropService } from 'app/shared/services/drag-and-drop.service';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-disposition-board-search',
  templateUrl: 'disposition-board-search.component.html',
  styleUrls: ['disposition-board-search.component.scss']
})
export class DispositionBoardSearchComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  public readonly listType = ListType;
  public selectedAsset: string = ListType.NONE;

  constructor(protected authService: KeycloakService,
              public dispositionStore: DispositionDataSource,
              public optionResolver: AssetListTypeResolver,
              private dragAndDropService: DragAndDropService) {
    super(authService);
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public getViews(): string[] {
    const views: string[] = [];
    if (this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_DISPOSITION) &&
      this.authService.hasModule(Modules.DISPOSITION) &&
      this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      views.push(ListType.EQUIPMENTS);
    }
    if (this.authService.hasAuthority(Authorities.EMPLOYEE_MANAGE_DISPOSITION) &&
      this.authService.hasModule(Modules.STAFF_MANAGEMENT) &&
      this.authService.hasModule(Modules.DISPOSITION)) {
      views.push(ListType.EMPLOYEES);
    }
    return views;
  }

  public changeAssetSelection(assetSelected: string): void {
    this.selectedAsset = assetSelected;
    this.dragAndDropService.changeListType(<ListType>assetSelected);
  }

  public getSelectedAssetName(): string {
    return this.optionResolver.resolveName(this.selectedAsset);
  }
}
