import { Component, OnInit } from '@angular/core';
import { faArrowRight, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MobileTransferItem } from '../../../../../contract/mobile-transfer-item';
import { numberBiggerThanValidator } from 'app/shared/custom-validators/number-bigger-than.validator';

@Component({
  selector: 'bh-mobile-transfer-amount-input-dialog',
  templateUrl: './mobile-transfer-amount-input-dialog.component.html',
  styleUrls: ['./mobile-transfer-amount-input-dialog.component.scss']
})
export class MobileTransferAmountInputDialogComponent implements OnInit {

  public readonly faPlus: IconDefinition = faPlus;
  public readonly faMinus: IconDefinition = faMinus;
  public readonly faArrowRight: IconDefinition = faArrowRight;
  public bulkItemAmount: MobileTransferItem;
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  public get amount(): AbstractControl | null {
    return this.form.get('amount');
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      amount: [this.bulkItemAmount.amount, [Validators.max(this.getMaximumAmount()), numberBiggerThanValidator(0)]]
    });
  }

  public getMaximumAmount(): number {
    return this.bulkItemAmount.maxAmount;
  }

  public plus(): void {
    if (this.amount.value < this.getMaximumAmount()) {
      this.amount.patchValue(this.amount.value + 1);
    }
  }

  public minus(): void {
    if (this.amount.value > 1) {
      this.amount.patchValue(this.amount.value - 1);
    }
  }

}
