import { Component, OnInit } from '@angular/core';
import { BaseLandingpageListComponent } from '../base/base-landingpage-list.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingPageListItemsService } from '../../shared/landing-page-list-items.service';
import { LandingPageItem } from '../../shared/landing-page-item';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomDeviceDetectorService } from '../../../../shared/services/custom-device-detector.service';
import { RouterHistory } from '../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-landingpage-list',
  templateUrl: './mobile-landingpage-list.component.html',
  styleUrls: ['./mobile-landingpage-list.component.scss']
})
export class MobileLandingpageListComponent extends BaseLandingpageListComponent implements OnInit {

  items: Observable<LandingPageItem[]>;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private deviceService: CustomDeviceDetectorService,
              private landingPageListItemsService: LandingPageListItemsService) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    if (!this.deviceService.isMobile()) {
      return this.router.navigate(['landingpage']);
    }

    this.items = this.landingPageListItemsService.items
      .pipe(map(items => items.filter(item => (!item.module || this.hasModule(item.module)) &&
            ((this.hasAnyAuthority(item.authorities))))));
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  calcLayoutAlign(index: number): string {
    let layoutAlign = 'start center';
    switch (index) {
      case 0:
        layoutAlign = 'start center';
        break;
      case 1:
        layoutAlign = 'start center';
        break;
      case 2:
        layoutAlign = 'center center';
        break;
      case 3:
        layoutAlign = 'center center';
        break;
      case 4:
        layoutAlign = 'end center';
        break;
      case 5:
        layoutAlign = 'end center';
        break;
    }

    return layoutAlign;
  }
}
