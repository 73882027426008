import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { AddressLocation } from 'app/modules/organisation/contract/address-location';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';
import { RetryGeocoder } from 'app/shared/geolocation/retry-geocoder';
import { RetryGeocoderStatus } from 'app/shared/geolocation/retry-geocoder-status.enum';
import { Observable, filter, map, merge, take, tap } from 'rxjs';
import { MapLoaderService } from '../../services/map-loader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GeocoderResultRequest } from 'app/shared/geolocation/geocoder-result-request.interface';
import _ from 'lodash';

interface MapLocationSearchSimpleChanges extends SimpleChanges {
  address?: SimpleChange;
}

@UntilDestroy()
@Component({
  selector: 'bh-map-location-select',
  templateUrl: './map-location-select.component.html',
  styleUrls: ['./map-location-select.component.scss'],
})
export class MapLocationSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() geofence: boolean;
  @Input() address: Address;
  @Input() mandatory = true;
  @Input() set location(latLon: LatLon) {
    this.marker = latLon ? ({ lat: latLon.lat, lng: latLon.lon }) : null;
  }
  @Input() initializeMapTypeControl = true;

  @Output() addressChange: EventEmitter<AddressLocation> = new EventEmitter();
  @Output() streetNumberChange: EventEmitter<string> = new EventEmitter();
  @Output() routeChange: EventEmitter<string> = new EventEmitter();
  @Output() postalCodeChange: EventEmitter<string> = new EventEmitter();
  @Output() localityChange: EventEmitter<string> = new EventEmitter();
  @Output() geocoderStatus: EventEmitter<RetryGeocoderStatus> = new EventEmitter();

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  public marker: google.maps.LatLngLiteral;
  public center: google.maps.LatLngLiteral;
  public zoom: number;
  public isGeocodingSuccessful: Observable<boolean>;
  public readonly isMapApiLoaded = this.mapLoaderService.isLoaded;
  public readonly circleGeofenceOptions: google.maps.CircleOptions = {
    fillColor: 'red',
    fillOpacity: 0.3,
    radius: 100,
    strokeColor: 'red',
    strokeOpacity: 0.5,
    strokeWeight: 1,
    clickable: false,
  }

  private geocoderLocation: RetryGeocoder;
  private geocoderAddress: RetryGeocoder;
  private currentAddress = new AddressLocation();
  private autocompleteListener: google.maps.MapsEventListener;
  private readonly defaultPosition = { lat: 50.9333, lng: 10.5833, zoom: 6 };

  constructor(
    private mapLoaderService: MapLoaderService,
    private ngZone: NgZone
  ) { }

  public ngOnInit(): void {
    this.mapLoaderService.isLoaded
      .pipe(
        filter(Boolean),
        take(1),
        untilDestroyed(this))
      .subscribe(() => {
        this.geocoderLocation = new RetryGeocoder(new google.maps.Geocoder());
        this.geocoderAddress = new RetryGeocoder(new google.maps.Geocoder());
        this.setCurrentLocation();
        this.initGeocoderListener();
        this.initAutocompleteListener();
      });
  }

  public ngOnChanges(changes: MapLocationSearchSimpleChanges): void {
    if (changes.address && this.geocoderAddress) {
      this.updateAddress(changes.address.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this.autocompleteListener?.remove?.();
  }

  public mapClicked($event: google.maps.MapMouseEvent | { latLng: google.maps.LatLng | google.maps.LatLngLiteral}): void {
    this.moveMarkerTo($event.latLng);
    this.getLocation();
  }

  private setCurrentLocation(): void {
    if ('geolocation' in navigator && !this.marker) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.zoom = 11;
      }, () => {
        this.center = { lat: this.defaultPosition.lat, lng: this.defaultPosition.lng };
        this.zoom = this.defaultPosition.zoom;
      });
    } else if (this.marker) {
      this.center = { ...this.marker };
      this.zoom = 11;
    }
  }

  private initGeocoderListener(): void {
    this.geocoderLocation.geocodeResult
      .pipe(untilDestroyed(this))
      .subscribe(geocodeResult => this.locationHandler(geocodeResult));

    this.geocoderAddress.geocodeResult
      .pipe(untilDestroyed(this))
      .subscribe(geocodeResult => this.addressHandler(geocodeResult));

    this.isGeocodingSuccessful = merge(this.geocoderLocation.status, this.geocoderAddress.status)
      .pipe(
        tap(status => this.geocoderStatus.emit(status)),
        map(status => status !== RetryGeocoderStatus.BAD));
  }

  private initAutocompleteListener(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      { types: ['geocode'] }
    );
    this.autocompleteListener = autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (_.isNil(place.geometry)) {
          return;
        }
        this.moveMarkerTo(place.geometry.location);
        this.getLocation();
      });
    });
  }

  private locationHandler({ result, status }: GeocoderResultRequest): void {
    if (status === google.maps.GeocoderStatus.OK) {
      this.updateLocation(result);
      return;
    }
    this.addressChange.next(this.currentAddress);
  }

  private addressHandler({ result, status }: GeocoderResultRequest): void {
    if (status === google.maps.GeocoderStatus.OK) {
      this.moveMarkerTo(result.geometry.location);
      return;
    }
    this.marker = null;
  }

  private moveMarkerTo(location: google.maps.LatLng | google.maps.LatLngLiteral): void {
    const latLng = new google.maps.LatLng(location);
    this.center = this.marker = latLng.toJSON();
  }

  private updateLocation(place: google.maps.places.PlaceResult | google.maps.GeocoderResult) {
    place.address_components.forEach(comp => {
      comp.types.forEach(type => {
        switch (type) {
          case 'street_number':
            this.streetNumberChange.emit(comp.long_name);
            this.currentAddress.streetNumber = comp.long_name;
            break;
          case 'route':
            this.routeChange.emit(comp.long_name);
            this.currentAddress.street = comp.long_name;
            break;
          case 'postal_code':
            this.postalCodeChange.emit(comp.long_name);
            this.currentAddress.postalCode = comp.long_name;
            break;
          case 'locality':
            this.localityChange.emit(comp.long_name);
            this.currentAddress.city = comp.long_name;
            break;
        }
      });
    });

    this.addressChange.next(this.currentAddress);
  }

  private updateAddress(address: Address) {
    if (address) {
      this.geocoderAddress.geocodeRequest({ 'address': new AddressLocation(address).getAddressString() });
    }
  }

  private getLocation() {
    this.currentAddress.resetAddress();
    this.currentAddress.setLocation(this.center.lat, this.center.lng);
    this.geocoderLocation.geocodeRequest({ location: new google.maps.LatLng(this.marker.lat, this.marker.lng)});
  }

}
