<div *ngIf="!damage">
  <div class="equipment-damage-view-content">
    {{'general.labels.dataIsLoading'|translate}}
  </div>
</div>
<div *ngIf="damage">
  <div class="equipment-damage-view-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span class="title">{{'general.maliciousDamage.s'|translate}}</span>
      <span class="name">{{ damage.damageType | damageTypeDisplay }}</span>
    </div>
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <div class="equipment-damage-view-content" [@.disabled]="isFirefox">
    <mat-tab-group animationDuration="0ms">
      <mat-tab [label]="'general.data'|translate">
        <!--TODO: make this class general-->
        <div class="technical-field-list" fxLayout="row"
             fxLayoutAlign="space-between stretch">
          <div>
            <label>{{'general.date'|translate}}</label>
            <p>{{ damage.damageDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</p>
          </div>
          <div>
            <label>{{'general.address.city'|translate}}</label>
            <p>{{ damage.damageLocation }}</p>
          </div>
          <div>
            <label>{{'general.documentation'|translate}}</label>
            <p>{{ damage.documentation }}</p>
          </div>
          <div>
            <label>{{'general.labels.createdOn'|translate}}</label>
            <p>{{ damage.submitDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</p>
          </div>
          <div>
            <label>{{'general.labels.createdBy'|translate}}</label>
            <p>{{ damage.submittedBy}}</p>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'general.invoice.s'|translate" *ngIf="invoice && allowedToSeeInvoice">
        <div class="technical-field-list" fxLayout="row"
             fxLayoutAlign="space-between stretch">
          <div>
            <label>{{'general.date'|translate}}</label>
            <p>{{ invoice?.invoiceDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.costs'|translate}}</label>
            <p>{{ invoice?.invoiceAmount | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.invoiceNumber'|translate}}</label>
            <p>{{ getInvoiceNumber() }}</p>
          </div>
          <div>
            <label>{{'general.documentation'|translate}}</label>
            <p>{{ invoice?.documentation }}</p>
          </div>
        </div>
        <div class="technical-field-list" fxLayout="row"
             fxLayoutAlign="start stretch"
             *ngIf="invoice.documents && invoice.documents.length > 0">
          <div fxFlex="25" class="imageDisplay" *ngIf="invoiceImageDisplay">
            <img src="{{invoiceImageDisplay.url}}">
            <a class="viewImageCloseBtn" (click)="viewInvoiceImageClose()">
              <mat-icon [matTooltip]="'general.buttons.close'|translate">clear</mat-icon>
            </a>
          </div>
          <div fxFlex>
            <mat-table fxFlex="100" class="pagination-data-table left-body-list" #table
                       [dataSource]="invoiceDocumentsDataSource">

              <ng-container cdkColumnDef="fileIcon">
                <mat-header-cell *cdkHeaderCellDef>{{'general.icon'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <fa-icon [icon]="getFileTypeIcon(row)" size="2x"></fa-icon>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileName">
                <mat-header-cell *cdkHeaderCellDef>{{'general.file'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileName">
                  <span>{{ row.fileName }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileType">
                <mat-header-cell *cdkHeaderCellDef>{{'general.type'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileType">
                  <span>{{row.fileType}}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileDescription">
                <mat-header-cell *cdkHeaderCellDef>{{'general.description'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileDescription">
                  <span>{{ row.fileDescription }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="actions">
                <mat-header-cell *cdkHeaderCellDef>{{'general.actions'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <a *ngIf="row.url && isImageFile(row.fileName)" (click)="viewInvoiceImage(row)">
                    <mat-icon [matTooltip]="'general.buttons.view'|translate">visibility</mat-icon>
                  </a>
                  <a *ngIf="row.url" [href]="row.url" [download]="down" target="_blank">
                    <mat-icon [matTooltip]="'general.buttons.download'|translate">cloud_download</mat-icon>
                  </a>
                </mat-cell>
              </ng-container>

              <mat-header-row *cdkHeaderRowDef="displayColumns"></mat-header-row>
              <mat-row
                  *cdkRowDef="let myRowData; columns: displayColumns">
              </mat-row>
            </mat-table>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'modules.equipment.base.extendedInvoiceInfo'|translate" *ngIf="invoice && allowedToSeeInvoice">
        <div class="technical-field-list" fxLayout="row"
             fxLayoutAlign="space-between stretch">

          <div>
            <label>{{'modules.equipment.base.timeRequirement'|translate}}</label>
            <p>{{ getDuration() }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.callOutFee'|translate}}</label>
            <p>{{ invoice?.callOutFee | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.mileageCovered'|translate}}</label>
            <p>{{ invoice?.drive | dimensionUnitConverter: 'km' }} {{'km'|dimensionUnitDisplay}}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.allowance'|translate}}</label>
            <p>{{ invoice?.allowance | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.materialCosts'|translate}}</label>
            <p>{{ invoice?.materialCosts | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.deliveryAndFreightCosts'|translate}}</label>
            <p>{{ invoice?.freightCharges | currency:'EUR':'symbol':'1.2-2':getCurrentLocale() }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.supplierServiceProvider'|translate}}</label>
            <p>{{ invoice?.serviceContractor }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.serviceStaff'|translate}}</label>
            <p>{{ invoice?.serviceWorker }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.personnelCosts'|translate}}</label>
            <p>{{ invoice?.personnelCosts | currency:'EUR':'symbol':'1.2-2':'de' }}</p>
          </div>
          <div>
            <label>{{'modules.equipment.base.travelCosts'|translate}}</label>
            <p>{{ invoice?.travelCosts }}</p>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'general.attachment.pl'|translate" *ngIf="damage.documents">
        <div class="technical-field-list" fxLayout="row"
             fxLayoutAlign="start stretch">
          <div fxFlex="25" class="imageDisplay"
               *ngIf="imageDisplay">
            <bh-image *ngIf="imageDisplay.fileExists" [src]="imageDisplay.documentKey"></bh-image>
            <a class="viewImageCloseBtn" (click)="viewImageClose()">
              <mat-icon [matTooltip]="'general.buttons.close'|translate">clear</mat-icon>
            </a>
          </div>
          <div fxFlex>
            <mat-table fxFlex="100" class="pagination-data-table left-body-list" #table
                       [dataSource]="lcDocumentsDataSource">

              <ng-container cdkColumnDef="fileIcon">
                <mat-header-cell *cdkHeaderCellDef>{{'general.icon'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <fa-icon [icon]="getFileTypeIcon(row)" size="2x"></fa-icon>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileName">
                <mat-header-cell *cdkHeaderCellDef>{{'general.file'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileName">
                  <span>{{ row.fileName }} </span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileType">
                <mat-header-cell *cdkHeaderCellDef>{{'general.type'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileType">
                  <span>{{ row.fileType }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="fileDescription">
                <mat-header-cell *cdkHeaderCellDef>{{'general.description'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" [matTooltip]="row.fileDescription">
                  <span>{{ row.fileDescription }}</span>
                </mat-cell>
              </ng-container>

              <ng-container cdkColumnDef="actions">
                <mat-header-cell *cdkHeaderCellDef>{{'general.buttons.actions'|translate}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <a *ngIf="row.fileExists && isImageFile(row.fileName)" (click)="viewImage(row)">
                    <mat-icon [matTooltip]="'general.buttons.view'|translate">visibility</mat-icon>
                  </a>
                  <span *ngIf="row.fileExists">
                    <mat-icon (click)="downloadDocument(row)" [matTooltip]="'general.buttons.download'|translate">
                      cloud_download
                    </mat-icon>
                  </span>
                </mat-cell>
              </ng-container>

              <mat-header-row
                  *cdkHeaderRowDef="['fileIcon','fileName', 'fileDescription', 'fileType', 'actions']"></mat-header-row>
              <mat-row
                  *cdkRowDef="let myRowData; columns: ['fileIcon','fileName','fileDescription', 'fileType', 'actions']">
              </mat-row>
            </mat-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
