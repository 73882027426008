import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaintenanceChangedValue } from 'app/modules/maintenance/shared/contract/maintenance-task-completed-audit-response.interface';
import { MaintenanceHistoryFieldType } from 'app/modules/maintenance/shared/enums/maintenance-history-field-type.enum';
import { FieldType } from 'app/shared/enums/field-type';

@Component({
  selector: 'bh-maintenance-task-completed-history-item',
  templateUrl: './maintenance-task-completed-history-item.component.html',
  styleUrls: ['./maintenance-task-completed-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskCompletedHistoryItemComponent {
  @Input() type: MaintenanceHistoryFieldType;
  @Input() value: MaintenanceChangedValue | { changedValueType: FieldType, changedValue: MaintenanceChangedValue };
  @Input() emptyValue = '—';

  public readonly fieldType = { ...MaintenanceHistoryFieldType, ...FieldType };

}
