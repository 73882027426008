import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'bh-service-health',
  templateUrl: './service-health.component.html',
  styleUrls: ['./service-health.component.scss']
})
export class ServiceHealthComponent {

  services = [
    {
      name: 'communication-service',
      health_url: environment.APP_COMMUNICATION_SERVICE_BASE_URL
    },
    {
      name: 'disposition-service',
      health_url: environment.APP_DISPOSITION_SERVICE_BASE_URL
    },
    {
      name: 'equipment-audit-service',
      health_url: environment.APP_EQUIPMENT_AUDIT_SERVICE_BASE_URL
    },
    {
      name: 'equipment-service',
      health_url: environment.APP_EQUIPMENT_SERVICE_BASE_URL
    },
    {
      name: 'job-service',
      health_url: environment.APP_JOB_SERVICE_BASE_URL
    },
    {
      name: 'maintenance-service',
      health_url: environment.APP_MAINTENANCE_SERVICE_BASE_URL
    },
    {
      name: 'notification-service',
      health_url: environment.APP_NOTIFICATION_SERVICE_BASE_URL
    },
    {
      name: 'organisation-service',
      health_url: environment.APP_ORGANISATION_SERVICE_BASE_URL
    },
    {
      name: 'push-service',
      health_url: environment.APP_PUSH_SERVICE_BASE_URL
    },
    {
      name: 'report-service',
      health_url: environment.APP_REPORT_SERVICE_BASE_URL
    },
    {
      name: 'telematics-service',
      health_url: environment.APP_TELEMATICS_SERVICE_BASE_URL
    },
    {
      name: 'userrole-service',
      health_url: environment.APP_USERROLE_SERVICE_BASE_URL
    }
  ];

  constructor(public dialogRef: MatDialogRef<ServiceHealthComponent>) { }
}
