import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl, AbstractControl } from '@angular/forms';
import { CustomersService } from '../../shared/customers.service'
import { CreateCustomerCommand } from '../../contract/create-customer-command';
import { CustomerTypeEnum } from '../../contract/customer-type.enum';
import { PartnersService } from '../../shared/partners.service';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';
import { CustomerNumberInUseValidator } from '../../../../shared/custom-validators/customer-number-in-use.validator';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { CountryEnum } from '../../../../shared/country.enum';
import { CountryResolver } from '../../../../shared/country.resolver';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { CreateSubCustomerCommand } from '../../contract/create-sub-customer-command';

@UntilDestroy()
@Component({
  selector: 'bh-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent extends GuardedNavigableInputComponent implements OnInit {

  public customerAddForm: UntypedFormGroup;
  public countryEnum = CountryEnum;
  public customerType = [];
  public partners: any[];
  public parentCustomerId: string | null = null;

  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              protected router: Router,
              private partnersService: PartnersService,
              private customerService: CustomersService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private countryResolver: CountryResolver) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.buildForm();
    this.getCustomerType();
    this.getPartners();
    this.initAsyncValidators();
    this.customerEditFormChangeHandler();

    this.route.queryParams.subscribe(params => {
      this.parentCustomerId = params['parentCustomerId'] || null;
    });
  }

  public navigateBack(): void {
    if (this.parentCustomerId) {
      this.goBack('/customers/sub-customers/list');
    } else {
      this.goBack('/customers/list');
    }
  }

  buildForm() {
    this.customerAddForm = this.formBuilder.group({
      customerName: ['', [<any>Validators.required]],
      customerNumber: ['', [TrimValidator.hasWhitespaces]],
      customerAddress: this.formBuilder.group({
        country: ['', <any>Validators.required],
        street: '',
        streetNumber: '',
        postalCode: '',
        city: ''
      }),
      partnerId: ['', [<any>Validators.required]],
      customerTypeList: this.formBuilder.array([]),
      customerImported: false,
      hasAcknowledgeAssignmentWorkflow: false,
      hasTransferRequestWorkflow: false,
      hasTypeOfUseWorkflow: false,
      hasStockTransferRequestWorkflow: false,
      activateTransferRequestAutoAcceptance: [{value: false, disabled: true}],
      daysForTransferRequestAutoAcceptance: [{value: null, disabled: true},
        [Validators.min(1), Validators.max(365), Validators.pattern('^[0-9]*$')],
      ]
    }, {updateOn: 'change'});
  }

  get countryKeys() {
    return Object.keys(this.countryEnum);
  }

  get customerName(): AbstractControl {
    return this.customerAddForm.get('customerName');
  }

  get customerNumber(): AbstractControl {
    return this.customerAddForm.get('customerNumber');
  }

  get partnerId() {
    return this.customerAddForm.get('partnerId');
  }

  get customerTypeList() {
    return this.customerAddForm.get('customerTypeList') as UntypedFormArray;
  }

  get customerCountry(): AbstractControl {
    return this.customerAddForm.get('customerAddress').get('country');
  }

  get customerStreet(): AbstractControl {
    return this.customerAddForm.get('customerAddress').get('street');
  }

  get customerStreetNumber(): AbstractControl {
    return this.customerAddForm.get('customerAddress').get('streetNumber');
  }

  get customerPostalCode(): AbstractControl {
    return this.customerAddForm.get('customerAddress').get('postalCode');
  }

  get customerCity(): AbstractControl {
    return this.customerAddForm.get('customerAddress').get('city');
  }

  get activateTransferRequestAutoAcceptance(): AbstractControl {
    return this.customerAddForm.get('activateTransferRequestAutoAcceptance');
  }

  get daysForTransferRequestAutoAcceptance(): AbstractControl {
    return this.customerAddForm.get('daysForTransferRequestAutoAcceptance');
  }

  get hasStockTransferRequestWorkflow(): AbstractControl {
    return this.customerAddForm.get('hasStockTransferRequestWorkflow');
  }

  get hasTransferRequestWorkflow(): AbstractControl {
    return this.customerAddForm.get('hasTransferRequestWorkflow');
  }

  get isFleetAdminRole(): boolean {
    return this.authService.getRoles().includes(PrivilegedRole.Flottenadmin.toString())
  }

  public getPartners() {
    this.partnersService.getPartners().subscribe(
        data => {
          this.partners = data.content;
        },
        error => {
          console.log(error);
        }
    );
  }

  public getCountryName(option: CountryEnum): string {
    return this.countryResolver.resolveName(option);
  }

  save() {
    if (this.isValid()) {
      let customer = this.customerAddForm.getRawValue();
      let cmd = this.getCommand(customer);

      if (cmd instanceof CreateSubCustomerCommand) {
        cmd.parentCustomerId = this.parentCustomerId;
        this.customerService.addSubCustomer(cmd).subscribe(
          () => {
            this.navigateBack();
          },
          (error: HttpErrorResponse) => {
            console.log('save customer error: ', error);
          }
        );
      } else {
        this.customerService.addCustomer(cmd).subscribe(
          () => {
            this.navigateAfterSubmit();
          },
          (error: HttpErrorResponse) => {
            console.log('save customer error: ', error);
          }
        );
      }

    }
  }

  public getCommand(customer: any): CreateCustomerCommand | CreateSubCustomerCommand {
    let cmd = this.parentCustomerId ? new CreateSubCustomerCommand() : new CreateCustomerCommand();
    cmd.customerName = customer.customerName;
    cmd.customerNumber = customer.customerNumber;
    cmd.customerAddress = customer.customerAddress;
    cmd.customerTypeList = customer.customerTypeList;
    cmd.customerImported = customer.customerImported;
    cmd.hasAcknowledgeAssignmentWorkflow = customer.hasAcknowledgeAssignmentWorkflow;
    cmd.hasTransferRequestWorkflow = customer.hasTransferRequestWorkflow;
    cmd.hasTypeOfUseWorkflow = customer.hasTypeOfUseWorkflow;
    cmd.hasStockTransferRequestWorkflow = customer.hasStockTransferRequestWorkflow;
    cmd.activateTransferRequestAutoAcceptance = customer.activateTransferRequestAutoAcceptance;
    cmd.daysForTransferRequestAutoAcceptance = customer.daysForTransferRequestAutoAcceptance;
    cmd.partnerId = customer.partnerId;
    return cmd;
  }

  public isValid(): boolean {
    return this.customerAddForm.valid;
  }

  getCustomerType() {

    let customerTypeFromEnum = Object.keys(CustomerTypeEnum);
    customerTypeFromEnum.map(ct => {
      this.customerType.push({
        name: ct,
        isSelected: false
      })
    });
  }

  selectCostumerType(ct) {
    ct.isSelected = !ct.isSelected;

    if (ct.isSelected) {
      ct.isSelected = true;
      this.customerTypeList.push(new UntypedFormControl(ct.name));
    } else {
      let index: number = this.customerTypeList.value.findIndex(x => Object.is(ct.name, x));

      this.customerTypeList.removeAt(index);
    }
  }

  public resetForm() {
    this.customerTypeList.getRawValue().forEach(() => {
      this.customerTypeList.removeAt(0);
    });

    this.customerType.forEach((value) => {
      value.isSelected = false;
    });

    this.customerAddForm.reset();
  }

  private initAsyncValidators() {
    this.customerNumber.setAsyncValidators(
      asyncValidatorFactory((value) => {
        let customerId: string;
        let partnerId: string = this.partnerId.value;
        return CustomerNumberInUseValidator.isValid(value, customerId, partnerId, this.customerService);
      }));

    this.partnerId.valueChanges.subscribe(() => {
      this.customerNumber.updateValueAndValidity();
    })
  }
  private customerEditFormChangeHandler(): void {
    this.hasTransferRequestWorkflow.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe({
      next: value => {
        this.setFieldsStatuses();
      },
    });
    this.hasStockTransferRequestWorkflow.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe({
      next: value => {
        this.setFieldsStatuses();
      },
    });
    this.activateTransferRequestAutoAcceptance.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe({
      next: value => {
        this.setFieldsStatuses();
      },
    });
  }
  private setFieldsStatuses(): void {
    if (Boolean(this.hasStockTransferRequestWorkflow.value) || Boolean(this.hasTransferRequestWorkflow.value)) {
      this.activateTransferRequestAutoAcceptance.enable();
      if (Boolean(this.activateTransferRequestAutoAcceptance.value)) {
        this.daysForTransferRequestAutoAcceptance.enable();
      } else {
        this.daysForTransferRequestAutoAcceptance.disable();
      }
    } else {
      this.activateTransferRequestAutoAcceptance.patchValue(false, {emitEvent: false});
      this.activateTransferRequestAutoAcceptance.disable();
      this.daysForTransferRequestAutoAcceptance.disable();
    }
  }
}
