import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { ITransferItemWithValidityStatus, TransferItem } from '../model/transfer-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { LanguageService } from 'app/shared/services/language.service';
import { PartnerTheme } from '../../../../organisation/contract/partner-theme.enum';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';
import { TransferBackToStockDialogService } from '../../services/transfer-back-to-stock-dialog.service';
import { DimensionUnitConverterPipe } from '../../../../../shared/pipes/dimension-unit-converter.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-transfer-items',
  templateUrl: './transfer-items.component.html',
  styleUrls: ['./transfer-items.component.scss']
})
export class TransferItemsComponent implements OnInit, OnChanges {

  @Input() transferItems: TransferItem[];
  @Input() searchable = false;
  @Input() sourceLabel: string;
  @Input() targetLabel: string;
  @Input() invalid: boolean;
  @Input() itemsRemovable: boolean;
  @Input() removeAllAvailable: boolean;
  @Input() showSelectAll: boolean;
  @Input() showEquipmentMessage: boolean;
  @Input() typeOfUseChanging = false;
  @Input() transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];
  @Input() saveSelectionStatusesWhenFiltering: boolean;
  @Input() isLoadingItems: boolean;

  @Output() itemsCleared: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() amountUpdated: EventEmitter<TransferItem> = new EventEmitter<TransferItem>();
  @Output() searchFilterApplied: EventEmitter<string> = new EventEmitter<string>();

  public readonly faArrowRight: IconDefinition = faArrowRight;
  public readonly faTrashAlt: IconDefinition = faTrashAlt;
  public className = 'create-transfer-cart';
  public itemsFormArray: UntypedFormArray;
  private itemsSelectedStatus: Record<string, number> = {};

  constructor(private formBuilder: UntypedFormBuilder,
              private userConfigurationService: UserConfigurationService,
              public transferBackToStockDialogService: TransferBackToStockDialogService,
              protected langService: LanguageService,
              protected dimensionUnitConverterPipe: DimensionUnitConverterPipe
  ) {
  }

  public ngOnInit(): void {
    this.transferItems = this.groupSubEquipmentsToContainers(this.transferItems);
    this.createFormArrayFromItems();
    this.setTransferCartClassName();
  }

  public getTransferItemWithStatus(id: string): ITransferItemWithValidityStatus {
    return this.transferItemsWithStatus.find(item => item.transferItem.id === id);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.transferItems && !changes.transferItems.firstChange) {
      this.transferItems = this.groupSubEquipmentsToContainers(this.transferItems);

      if (this.transferBackToStockDialogService.getIsClearProjectTransfer()
        && this.transferItems.filter(transferItem => transferItem.transferAmount).length) {
        this.transferBackToStockDialogService.findOrganisationOfSourceStocksAndGetStocks(
          this.getSourceStockIdsOfTransferItems());
      }

      this.createFormArrayFromItems();
      this.itemsFormArray.updateValueAndValidity();
      if (this.saveSelectionStatusesWhenFiltering) {
        this.itemsFormArray.controls.forEach((control, i) => {
          if (this.itemsSelectedStatus[this.transferItems[i].id]) {
            control.setValue(this.itemsSelectedStatus[this.transferItems[i].id]);
          }
        });
      }
    }
  }

  public clearItems(): void {
    this.itemsCleared.emit();
  }

  public isInvalid(): boolean {
    const allZero = this.itemsFormArray.value.every(value => value <= 0);
    return this.invalid || this.itemsFormArray.length < 1 || this.itemsFormArray.invalid || allZero
      || !this.transferBackToStockDialogService.getIsSelectedStockVisible();
  }

  public removeItem(index: number): void {
    this.itemsFormArray.removeAt(index);
    this.itemRemoved.emit(this.transferItems[index].id);
  }

  public submit(): void {
    if (!this.isInvalid()) {
      this.submitted.emit();
    }
  }

  public selectAll(): void {
    const shouldSetMaximum = !this.isAllAmountsOnMaximum();

    this.transferBackToStockDialogService.setIsSelectAllUsed(true);

    this.transferItems
    .filter(({hiddenOption}) => !hiddenOption)
    .forEach(item => {
      if (this.saveSelectionStatusesWhenFiltering) {
        this.itemsSelectedStatus[item.id] = shouldSetMaximum
          ? this.dimensionUnitConverterPipe.toUserDimensionUnit(item.maxAmount, item.unit, 3)
          : this.dimensionUnitConverterPipe.toUserDimensionUnit(item.minAmount, item.unit, 3);
      }
      this.itemsFormArray
      .at(this.transferItems.indexOf(item))
      .setValue(shouldSetMaximum
        ? this.dimensionUnitConverterPipe.toUserDimensionUnit(item.maxAmount, item.unit, 3)
        : this.dimensionUnitConverterPipe.toUserDimensionUnit(item.minAmount, item.unit, 3));
    });

    if (this.transferBackToStockDialogService.getIsClearProjectTransfer()) {
      this.transferBackToStockDialogService.findOrganisationOfSourceStocksAndGetStocks(
        this.getSourceStockIdsOfTransferItems());
    }

    this.transferBackToStockDialogService.setIsSelectAllUsed(false);
  }

  public getButtonText(): string {
    return this.isAllAmountsOnMaximum() ? this.langService.getInstant('general.deselectAll') :
      this.langService.getInstant('general.selectAll');
  }

  public applyFilter(searchTerm: string) {
    this.searchFilterApplied.emit(searchTerm);
  }

  private isAllAmountsOnMaximum(): boolean {
    return this.transferItems
    .every(item => item.hiddenOption || this.isVisibleAndAmountOnMaximum(item));
  }

  private isVisibleAndAmountOnMaximum(item: TransferItem): boolean {
    if (!item.hiddenOption) {
      let maxAmount = this.dimensionUnitConverterPipe.toUserDimensionUnit(item.maxAmount, item.unit, 3);
      let transferAmount = this.dimensionUnitConverterPipe.toUserDimensionUnit(item.transferAmount, item.unit, 3);
      return maxAmount === transferAmount;
    }

    return false;
  }

  private createFormArrayFromItems(): void {
    this.itemsFormArray = this.formBuilder.array(this.transferItems.map(item => {
      if (this.typeOfUseChanging) {
        this.updateTransferAmountIfChangingTypeOfUse(item);
      }
      const group = this.formBuilder.control(
        this.dimensionUnitConverterPipe.toUserDimensionUnit(item.transferAmount, item.unit, 3),
        [
          Validators.required,
          Validators.min(this.dimensionUnitConverterPipe.toUserDimensionUnit(item.minAmount, item.unit, 3)),
          Validators.max(this.dimensionUnitConverterPipe.toUserDimensionUnit(item.maxAmount, item.unit, 3))
        ]);
      group.valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged())
      .subscribe(value => {

        if (value === this.dimensionUnitConverterPipe.toUserDimensionUnit(item.maxAmount, item.unit, 3)) {
          item.transferAmount = item.maxAmount;
        } else {
          item.transferAmount = this.dimensionUnitConverterPipe.toSystemDimensionUnit(value, item.unit);
        }

        this.amountUpdated.emit(item);
      });
      return group;
    }));
  }

  private updateTransferAmountIfChangingTypeOfUse(transferItem: TransferItem): void {
    if (transferItem.transferAmount > 0) {
      transferItem.transferAmount = transferItem.minAmount;
      this.amountUpdated.emit(transferItem);
    }
  }

  private groupSubEquipmentsToContainers(transfers: TransferItem[]): TransferItem[] {
    const subEquipments: TransferItem[] = [];
    const normalEquipmentsOrBulkItems: TransferItem[] = [];
    transfers.forEach((assignment: TransferItem) => {
      if (assignment.equipmentContainerId) {
        subEquipments.push(assignment);
      } else if (assignment) {
        normalEquipmentsOrBulkItems.push(assignment);
      }
    });
    while (subEquipments.length) {
      let subEquipment = subEquipments.pop();
      let index = normalEquipmentsOrBulkItems.findIndex((item: TransferItem) => item.transferId === subEquipment.equipmentContainerId);
      if (index === -1) {
        subEquipment.equipmentContainerId = undefined;
        normalEquipmentsOrBulkItems.push(subEquipment);
      } else {
        normalEquipmentsOrBulkItems.splice(index + 1, 0, subEquipment);
      }
    }
    return normalEquipmentsOrBulkItems;
  }

  public typeItemsExist(type: string): boolean {
    return this.transferItems && this.transferItems.filter(item => item.transferItemType === type).length > 0;
  }

  public isActiveContainer(id: string): boolean {
    return this.transferItems
      && this.transferItems.some(({ transferId, isActiveContainer }) => transferId === id && isActiveContainer);
  }

  public hasSubequipments(id: string): boolean {
    return this.transferItems && this.transferItems.filter(item => item.equipmentContainerId === id).length > 0;
  }

  public activateEquipment(item: TransferItem): void {
    item.isActiveContainer = !item.isActiveContainer;
  }

  public changeValue(index: number): void {
    let control = this.itemsFormArray.controls[index];
    control.setValue(control.value === 0 ? this.transferItems[index].maxAmount : 0);
    if (this.saveSelectionStatusesWhenFiltering) {
      this.itemsSelectedStatus[this.transferItems[index].id] = control.value;
    }
    if (control.value !== 0) {
      this.transferItems.forEach((item, subIndex) => {
        if (item.maxAmount > 0 && item.equipmentContainerId &&
          item.equipmentContainerId === this.transferItems[index].transferId) {
          this.itemsFormArray.controls[subIndex].setValue(this.transferItems[subIndex].maxAmount);
          if (this.saveSelectionStatusesWhenFiltering) {
            this.itemsSelectedStatus[item.id] = this.transferItems[subIndex].maxAmount;
          }
        }
      });
    }
  }

  private setTransferCartClassName(): void {
    let theme = this.userConfigurationService.getPartnerTheme()
    switch (theme) {
      case PartnerTheme.WACKER_NEUSON:
        this.className += '-wn';
        break;
      case PartnerTheme.DEFAULT_OSP:
      case PartnerTheme.UNLABLED:
      default:
        this.className += '';
        break;
    }
  }

  private getSourceStockIdsOfTransferItems(): string[] {
    let sourceStockIds = new Set<string>();
    this.transferItems.forEach(transferItem => {
      if (transferItem.transferAmount) {
        sourceStockIds.add(transferItem.sourceStockId);
      }
    });
    return [...sourceStockIds];
  }
}
