import { LanguageService } from '../../../../../shared/services/language.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControlStatus, UntypedFormGroup } from '@angular/forms';
import { TechnicalFieldValue } from '../../../contract/technical-field-value.interface';
import { EquipmentTypesService } from '../../../shared/equipmenttypes.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TechnicalFieldsService } from '../../../shared/technical-fields.service';

export interface EquipmentTechnicalData {
  status: FormControlStatus;
  fields: TechnicalFieldValue[];
}

@Component({
  selector: 'bh-mobile-equipment-add-technical-data',
  templateUrl: './mobile-equipment-add-technical-data.component.html',
  styleUrls: ['./mobile-equipment-add-technical-data.component.scss']
})
export class MobileEquipmentAddTechnicalDataComponent implements OnInit {
  public technicalDataFormGroup: UntypedFormGroup;
  public equipmentTypeId: string;
  public loadingFields = false;

  @Output() public OnChange: EventEmitter<EquipmentTechnicalData> = new EventEmitter<EquipmentTechnicalData>();

  constructor(private formBuilder: UntypedFormBuilder,
              private technicalFieldsService: TechnicalFieldsService,
              private equipmentTypeService: EquipmentTypesService,
              private matSnackBar: MatSnackBar,
              private languageService: LanguageService) {
  }

  public getAvailableTechnicalFields(): void {
    this.loadingFields = true;
  }

  public getInputTypeByTechnicalFieldUnit(technicalFieldUnit: string): string {
    if (technicalFieldUnit) {
      return 'number';
    }
    return 'text';
  }

  public ngOnInit(): void {
    this.buildForm();
  }

  public getFilteredControls(controlName: string): AbstractControl[] {
    const formArray = this.technicalDataFormGroup.get(controlName) as UntypedFormArray;
    return formArray.controls;
  }

  private addFieldsToForm(controlName: string, fields: TechnicalFieldValue[]): void {
    if (fields) {
      this.technicalDataFormGroup.removeControl(controlName);
      this.technicalDataFormGroup.addControl(
          controlName,
          this.formBuilder.array(fields
          .sort((tf1, tf2) => tf1.technicalFieldName.localeCompare(tf2.technicalFieldName))
          .map(field => this.formBuilder.group({
            technicalFieldId: field.technicalFieldId,
            technicalFieldName: field.technicalFieldName,
            technicalFieldUnit: field.technicalFieldUnit,
            value: field.value ? field.value : ''
          })))
      );
    }
    this.loadingFields = false;
  }

  private getAvailAbleTechnicalFields(): AbstractControl {
    return this.technicalDataFormGroup.get('availableTechnicalFields');
  }

  private getAllTechnicalFields(): TechnicalFieldValue[] {
    const availabletechnicalFields = this.getAvailAbleTechnicalFields();
    if (availabletechnicalFields) {
      return []
      .concat(this.technicalDataFormGroup.get('availableTechnicalFields').value)
      .filter(field => field.value && field.value.trim());
    }
    return [];
  }

  private buildForm(): void {
    this.technicalDataFormGroup = this.formBuilder.group({
      availableTechnicalFields: []
    });
    this.technicalDataFormGroup.statusChanges.subscribe((value) => {
      this.OnChange.emit({
        status: value,
        fields: this.getAllTechnicalFields()
      });
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
