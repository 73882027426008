import { ScanEquipment } from 'app/modules/equipment/shared/scan-equipment';
import { TransferItem } from '../shared/transfer/model/transfer-item';
import { TransferItemType } from '../shared/transfer/model/transfer-item-type.enum';
import { MobileTransferItem } from './mobile-transfer-item';
import { ViewAmount } from './view-amount';

export class ProjectToProjectTransferItem {

  public static fromTransferItem(source: TransferItem, sourceProjectId: string) {
    return new ProjectToProjectTransferItem(source.transferId,
       source.transferItemType,
       source.sourceStockId,
       sourceProjectId,
       source.transferAmount,
       source.id);
  }

  public static fromMobileTransferItem(source: MobileTransferItem, sourceProjectId: string) {
    return new ProjectToProjectTransferItem(source.transferId,
       TransferItemType.BULKITEM,
        source.sourceStockId,
        sourceProjectId,
        source.amount);
  }

  public static fromScanEquipment(source: ScanEquipment, sourceProjectId: string) {
    return new ProjectToProjectTransferItem(
      source.equipmentId,
      TransferItemType.EQUIPMENT,
      source.sourceStockId,
      sourceProjectId,
      1
    );
  }

  public static fromViewAmount(source: ViewAmount, transferAmount: number) {
    return new ProjectToProjectTransferItem(
      source.equipmentId,
      TransferItemType.EQUIPMENT,
      source.stockId,
      source.currentProjectId,
      transferAmount,
      source.amountId
    );
  }

  private constructor(
    public transferItemId: string,
    public transferItemType: TransferItemType,
    public sourceStockId: string,
    public sourceProjectId: string,
    public amount: number,
    public amountId?: string) {
  }
}
