import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, NgControl, UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { Component, Input, OnDestroy, Optional, Self, ElementRef, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TimeDuration } from 'app/shared/contract/time-duration.interface';


@Component({
  selector: 'bh-time-duration',
  templateUrl: './time-duration.component.html',
  styleUrls: ['./time-duration.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: TimeDurationComponent}],
})
export class TimeDurationComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<TimeDuration> {
  @HostBinding('attr.aria-describedby') public describedBy = '';
  @HostBinding() public id = `time-duration-${TimeDurationComponent.nextId++}`;
  @HostBinding('class.floating')
  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  public form: UntypedFormGroup;
  public stateChanges = new Subject<void>();
  public controlType = 'time-duration';

  private onChange = (_: any) => {};
  private onTouched = () => {};

  private static nextId = 0;
  private _placeholder: string;
  private _focused = false;
  private touched = false;
  private _required = false;
  private _disabled = false;

  public get value(): TimeDuration {
    return this.empty ? null : this.form.getRawValue();
  }
  public set value(timeDuration: TimeDuration) {
    this.form.setValue(timeDuration);
    this.stateChanges.next();
  }

  // Need to override a mat-field standard resolving
  public get placeholder(): string {
    return '';
  }
  public get customPlaceholder(): string {
    return this._placeholder;
  }
  @Input() public set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  public get required(): boolean {
    return this._required;
  }
  @Input() public set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.stateChanges.next();
  }

  public get focused(): boolean {
    return this._focused;
  }
  public set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }

  public get empty(): boolean {
    return this.hours.value === null && this.minutes.value === null;
  }

  public get errorState(): boolean {
    return (this.ngControl.control != null)
      ? this.ngControl.control.invalid && this.touched
      : false;
  }

  constructor(
    private fb: UntypedFormBuilder,
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef<HTMLElement>
  ) {
    this.buildForm();
    if (ngControl) {
      // Set the value accessor directly (instead of providing
      // NG_VALUE_ACCESSOR) to avoid running into a circular import
      this.ngControl.valueAccessor = this;
      ngControl.valueAccessor = this;
    }
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  public writeValue(timeDuration: TimeDuration | null): void {
    this.form.setValue(timeDuration || { hours: null, minutes: null });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  public onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(): void {
  }

  public handleChange(): void {
    this.onChange(this.value);
  }

  private buildForm(): void {
    this.form = this.fb.group({
      hours: null,
      minutes: null,
    });
  }

  private get hours(): AbstractControl {
    return this.form.get('hours');
  }
  private get minutes(): AbstractControl {
    return this.form.get('minutes');
  }
}
