import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { AdditionalFieldValue } from '../../../modules/osp-ui/rich-components/bh-additional-field/additional-field';

@UntilDestroy()
@Component({
  selector: 'bh-additional-field-assigned',
  templateUrl: './additional-field-assigned.component.html',
  styleUrls: ['./additional-field-assigned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdditionalFieldAssignedComponent),
      multi: true,
    }
  ]
})
export class AdditionalFieldAssignedComponent implements ControlValueAccessor {
  @Input() language: string;
  @Input() editMode = false;
  @Input() removable = true;
  @Output() onDelete = new EventEmitter();

  public control = new FormControl<string>(null);
  protected field: AdditionalFieldValue;

  public writeValue(field: AdditionalFieldValue): void {
    this.field = field;
    this.control.setValue(field?.value);
  }

  public registerOnChange(onChange: (value: AdditionalFieldValue) => {}): void {
    this.control.valueChanges
      .pipe(
        filter(() => Boolean(this.field)),
        untilDestroyed(this))
      .subscribe(value => {
        this.field.value = value;
        onChange(this.field);
      });
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
