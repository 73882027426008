import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'bh-role-conflict-snackbar',
  templateUrl: './role-conflict-snackbar.component.html',
  styleUrls: ['./role-conflict-snackbar.component.scss']
})
export class RoleConflictSnackbarComponent {

  constructor(private snackBarRef: MatSnackBar) { }

  public dismiss(): void {
    this.snackBarRef.dismiss()
  }
}
