import { Pipe, PipeTransform } from '@angular/core';
import { RentalContractCalculationBase } from '../../contract/rental-contract-calculation-base.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'rentalContractCalculationBase'
})
export class RentalContractCalculationBasePipe implements PipeTransform {
  private readonly translationKeys = {
    [RentalContractCalculationBase.CALENDAR_DAYS]: 'shared.pipes.rentalContractCalculationBase.calendarDays',
    [RentalContractCalculationBase.WORKING_DAYS]: 'shared.pipes.rentalContractCalculationBase.workingDays',
    [RentalContractCalculationBase.NOT_AVAILABLE]: 'shared.pipes.rentalContractCalculationBase.notAvailable'
  };

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any, args?: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
