<div class="geofences-content" [formGroup]="settingsForm" fxFlex fxLayout="column" fxLayoutGap="10px">
  <div fxFlex="0 1 0" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
      <mat-form-field>
        <mat-label>
          {{
          (globalGeofences?.length > 0)
            ? getFieldLabel([controlNames.SETTINGS_GLOBAL_GEOFENCE])
            : 'modules.notification.alarmRuleAddEdit.noGeofenceAvailable'|translate
          }}
        </mat-label>
        <mat-select [formControlName]="controlNames.SETTINGS_GLOBAL_GEOFENCE" required>
          <mat-option *ngFor="let geofence of globalGeofences" [value]="geofence.globalGeofenceId">
            {{geofence.globalGeofenceName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="geofenceControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{getFieldLabel([controlNames.SETTINGS_GEOFENCE_TRIGGER])}}</mat-label>
        <mat-select [formControlName]="controlNames.SETTINGS_GEOFENCE_TRIGGER" required>
          <mat-option [value]="triggers.ENTERING">{{'modules.notification.alarmRuleAddEdit.triggerEnter'|translate}}</mat-option>
          <mat-option [value]="triggers.LEAVING">{{'modules.notification.alarmRuleAddEdit.triggerLeave'|translate}}</mat-option>
        </mat-select>
        <mat-error *ngIf="geofenceTriggerControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
      </mat-form-field>
  </div>

  <div class="geofences-content__map" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="start stretch">
    <bh-map id="map"
      (mapInitialized)="mapReady($event)"
      [latitude]="mapCenterGermany.lat"
      [longitude]="mapCenterGermany.lng">
    </bh-map>
  </div>

  <div fxFlex="0 1 0" class="geofences-content__expl">
    <bh-explanation-card [text]="getFieldExplanation([controlNames.SETTINGS_GLOBAL_GEOFENCE])"></bh-explanation-card>
  </div>
</div>
