import { Pipe, PipeTransform } from '@angular/core';
import { DueDateInfo } from '../contract/due-date-info.interface';
import { LanguageService } from '../services/language.service';
import { TimezoneDatesService } from '../timezone/timezone-dates.service';

@Pipe({
  name: 'dueDate'
})
export class DueDatePipe implements PipeTransform {
  private dateFormat = 'DD.MM.yyyy';
  private maxDateFormat = '3000-01-01';
  private circaAbbr = this.languageService.getInstant('general.circa.abbr');

  constructor(
    private languageService: LanguageService,
    private timezoneDatesService: TimezoneDatesService
  ) {}

  public transform(dueDateInfo: DueDateInfo): string {
    if (dueDateInfo && dueDateInfo.dueDate && this.notMaxDate(dueDateInfo.dueDate)) {
      return [
        dueDateInfo.dueDateEstimated ? this.circaAbbr : null,
        this.timezoneDatesService.timeZonedTransform(dueDateInfo.dueDate, this.dateFormat),
      ]
      .filter(Boolean)
      .join(' ');
    }

    return null;
  }

  private notMaxDate(date: string): boolean {
    return date.indexOf(this.maxDateFormat) === -1;
  }

}
