<div class="m-view-lifecycle-details" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

  <bh-mobile-sub-header
      [title]="equipmentStore.currentTitle | async"
      [subtitle]="equipmentStore.currentSubtitle | async">
  </bh-mobile-sub-header>

  <div class="m-view-details-menu static-title" fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'modules.equipment.task.completed'|translate}} {{task?.category | maintenanceCategory}}</span>
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
    </button>
  </div>

  <div *ngIf="!allowedToSee" class="m-view-details-unauthorized"
       fxFlexFill fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
    <mat-icon aria-label="Close">no_encryption</mat-icon>
    <span>{{'modules.equipment.task.needPermissionsToViewInfo'|translate}}</span>
    <button mat-button (click)="navigateBack()">{{'general.buttons.back'|translate}}</button>
  </div>

  <div *ngIf="!task && allowedToSee" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
  </div>

  <div *ngIf="task && allowedToSee" class="m-view-lifecycle-details-content-wrapper">
    <div class="m-view-lifecycle-details-content">
      <mat-tab-group>

        <mat-tab [label]="'general.general.s'|translate">
          <div class="m-view-list-inputs">

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.labels.performedOutOn'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     [value]="task.completedAtDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy', timeFormat: ''} ">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.labels.performedOutAtMileage'|translate|uppercase}}</mat-label>
              <input type="number" matInput readonly
                     [value]="task.completedAtMileage">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.labels.performedOutAtOperatingHours'|translate|uppercase}}</mat-label>
              <input type="number" matInput readonly
                     [value]="task.completedAtOperatingHours">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     [value]="task.maintenanceTaskCompletionInfo.ruleNameAtCompletion">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.description'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     [value]="task.maintenanceTaskCompletionInfo.ruleDescriptionAtCompletion">
            </mat-form-field>

            <mat-form-field floatLabel="always" *ngFor="let field of task.maintenanceTaskCompletionInfo.maintenanceFieldValues">
              <input *ngIf="field.maintenanceFieldType === FieldType.DATE"
                     matInput readonly
                     [value]="field.value | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy', timeFormat: ''}"
                     placeholder="{{field.maintenanceTypeFieldName | uppercase}}">
              <input *ngIf="field.maintenanceFieldType !== FieldType.DATE"
                     matInput readonly
                     [value]="field.value"
                     placeholder="{{field.maintenanceTypeFieldName | uppercase}}">
            </mat-form-field>

            <div class="m-view-lifecycle-submit-info" fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <div>{{'general.labels.createdBy'|translate}}:</div>
                <div>{{lifeCycleTask.submittedBy}}</div>
              </div>
              <div>
                <div>{{'general.on'|translate|titlecase}}:</div>
                <div>{{lifeCycleTask.submitDate | formatTimezoneDateTime: {dateFormat: 'D. MMM yyyy', timeFormat: ''} }}</div>
              </div>
            </div>

          </div>
        </mat-tab>

        <mat-tab [label]="'general.attachment.pl'|translate">
          <div class="m-view-attachment m-view-tabs" fxLayout="row" fxLayoutAlign="start stretch">

            <mat-accordion displayMode="flat" fxFlex>
              <mat-expansion-panel *ngFor="let document of lcDocumentsDataSource.data" hideToggle="true">
                <mat-expansion-panel-header class="m-view-attachment__panel-header"
                                            collapsedHeight="70px"
                                            expandedHeight="70px">
                  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div (click)="openFile($event, document);"
                         class="file-info" fxFlex fxLayout="row"
                         fxLayoutAlign="start stretch">
                      <div fxFlex="50px" fxLayout="row" fxLayoutAlign="start center">
                        <fa-icon [icon]="getFileTypeIcon(document)" size="2x"></fa-icon>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="block-space" fxLayout="row">
                          <span class="shortening-filename">{{getFileName(document.fileName)}}</span>
                          <span>{{getFileNameExtension(document.fileName)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="icon" fxFlex="50px" fxLayout="column"
                         fxLayoutAlign="center stretch">
                      <span *ngIf="document.fileExists">
                        <mat-icon (click)="downloadDocument(document)"
                                  [matTooltip]="'general.buttons.download'|translate">cloud_download</mat-icon>
                      </span>
                    </div>
                  </div>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </mat-tab>

      </mat-tab-group>
    </div>

  </div>
</div>
