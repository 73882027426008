<div class="list-view-main__tabs" fxFlex>
  <mat-tab-group #detailTabGroup
    [disablePagination]="true"
    mat-stretch-tabs
    animationDuration="0ms"
    (selectedTabChange)="selectedTabChange($event)">

    <mat-tab #generalTab>
      <ng-template mat-tab-label>
        <fa-icon [icon]="faInfoCircle"></fa-icon> {{'general.baseData'|translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-transport-view-general [isActive]="generalTab.isActive"></bh-transport-view-general>
      </div>
    </mat-tab>

    <mat-tab #commentsTab>
      <ng-template mat-tab-label>
        <mat-icon fontSet="icon-comments_outline"></mat-icon>
        {{'general.comment.pl'|translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-transport-view-comments
          [transportId]="transportId"
          [isActive]="commentsTab.isActive">
        </bh-transport-view-comments>
      </div>
    </mat-tab>

    <mat-tab #historyTab>
      <ng-template mat-tab-label>
        <fa-icon [icon]="faHistory"></fa-icon>
        {{'general.history'|translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-transport-view-history [transportId]="transportId" [isActive]="historyTab.isActive"></bh-transport-view-history>
      </div>
    </mat-tab>

    <mat-tab #attachmentTab>
      <ng-template mat-tab-label>
        <mat-icon fontSet="icon-attachment_outline"></mat-icon>
        {{'general.attachment.pl'|translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-transport-view-attachment
          [transportId]="transportId"
          [isActive]="attachmentTab.isActive">
        </bh-transport-view-attachment>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>
