import { LanguageService } from './../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { AlarmLevel } from '../enums/alarm-level.enum';

@Pipe({
  name: 'alarmLevelDisplay'
})
export class AlarmLevelPipe implements PipeTransform {
  private translationKeys = {
    [AlarmLevel.HIGH]: 'shared.pipes.alarmLevelDisplay.high',
    [AlarmLevel.MID]: 'shared.pipes.alarmLevelDisplay.med',
    [AlarmLevel.LOW]: 'shared.pipes.alarmLevelDisplay.low',
  };

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any, args?: any): any {
    return this.translate(this.translationKeys[value] || 'shared.pipes.alarmLevelDisplay.unknown');
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
