<div fxFlexFill>
  <div class="header-wrapper"
       fxFlexAlign="start space-between" fxLayout="row">
    <div class="header-wrapper__left" fxFlex>
      <div class="app-logo" [routerLink]="['/']"></div>
      <div class="beutlhauser-logo"></div>
    </div>
    <div class="header-wrapper__right" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="header-wrapper__right-profile" fxLayout="row" fxLayoutAlign="center center"
           [matMenuTriggerFor]="profileMenuPopover">
        <fa-icon [icon]="faUserIcon" size="3x" class="profile-icon"></fa-icon>
        <div fxLayout="column" class="login-name-container" [ngClass]="userFullNameClassesHandler()">
          <span class="login-name" data-osp-test="label-user-first-name">{{userFirstName()}}</span>
          <span class="login-name" data-osp-test="label-user-last-name">{{userLastName()}}</span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="header-wrapper__right-icons">
        <div *ngIf="hasAuthority(authorities.MESSAGE_CENTER_USE) && hasModule(modules.COMMUNICATION)">
          <div *ngIf="unreadCount" class="badge">{{ unreadCount }}</div>
          <button id="chatBtn" #chatBtn mat-icon-button (click)="openChatOverview()">
            <mat-icon fontSet="icon-message_outline"></mat-icon>
          </button>
        </div>
        <div *ngIf="hasAuthority(authorities.NOTIFICATIONS_VIEW_ALARMS) && hasModule(modules.ALARMS)">
          <div *ngIf="warnings && warnings.length > 0" class="badge">{{ warningsTotalAmount }}</div>
          <button mat-icon-button [matMenuTriggerFor]="warningPopover"
                  [disabled]="!(warnings && warnings.length > 0)">
            <mat-icon fontSet="icon-main_alarms_solid"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-menu #warningPopover="matMenu" class="header-warning-menu">
  <div class="warnings-overlay">
    <bh-alarm-notification *ngFor="let alarm of warnings; trackBy:alarmTrackBy"
      [alarm]="alarm"
      (navigateUrl)="navigate($event)"
      (acknowledgeAlarm)="acknowledgeAlarm($event)"
    ></bh-alarm-notification>
  </div>
</mat-menu>

<mat-menu #profileMenuPopover="matMenu" yPosition="below" class="full-width">
  <div class="warnings-overlay">
    <div class="profile-menu" fxLayout="row" fxLayoutAlign="end center">
      <div class="profile-menu__left-col" fxLayout="column" fxFlex>
        <div class="profile-menu_left-col__title" fxLayout="row">
          <button mat-menu-item (click)="navigateTo(profileMenu.SETTINGS)">
            <mat-icon fontSet="icon-main_user_solid"></mat-icon>
            <span>{{'modules.navigation.sidebar.administrationMenu.userSettings'|translate}}</span>
          </button>
        </div>
        <div class="profile-menu__left-col__title" fxLayout="row">
          <button mat-menu-item href="#" (click)="logoutUser($event)">
            <mat-icon>power_settings_new</mat-icon>
            <span>{{'modules.navigation.sidebar.signOut'|translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-menu>

