import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WidgetRegistry } from '../widgets/widget-registry';
import { WidgetDefinition } from '../widgets/widget-definition';

@Component({
  selector: 'bh-add-widget-dialog',
  templateUrl: './add-widget-dialog.component.html',
  styleUrls: ['./add-widget-dialog.component.scss']
})
export class AddWidgetDialogComponent {
  widgetDefinition: WidgetDefinition;
  widgetDefinitions: WidgetDefinition[] = Array.from(this.widgetRegistry.widgetDefinitions.values())
    .filter(Boolean)
    .sort(this.sortWidgetDefinition);

  constructor(public dialogRef: MatDialogRef<AddWidgetDialogComponent>, private widgetRegistry: WidgetRegistry) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  private sortWidgetDefinition(a: WidgetDefinition, b: WidgetDefinition): number {
    return (a?.title || '').localeCompare(b?.title || '');
  }
}

