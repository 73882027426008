import { ChartDataTransformStrategy } from '../chart-data-transform-strategy.interface';
import { ChartDataset } from 'chart.js';
import { ChartUtils } from '../chart-utils.class';
import { EquipmentMultiseriesItem } from 'app/modules/equipment/contract/equipment-multiseries-item';
import { TransformStrategyDatasetOptions } from './transform-strategy-options.interface';

export class MultiSeriesTransformStrategy implements ChartDataTransformStrategy<EquipmentMultiseriesItem[]> {
  constructor(private options: TransformStrategyDatasetOptions[]) {}

  public isValidData(data: EquipmentMultiseriesItem[]): boolean {
    return data?.length > 0
      && this.options?.length > 0
      && this.options?.every(option => Boolean(option?.seriesName))
  }

  public getChartDataLabels(data: EquipmentMultiseriesItem[]): string[] {
    return this.isValidData(data)
      ? data.map(seriesItem => seriesItem?.name)
      : [];
  }

  public getChartDatasets(data: EquipmentMultiseriesItem[]): ChartDataset[] {
    if (this.isValidData(data)) {
      const datasets = ChartUtils.getMeasuresPerMultiSeries(
        data,
        this.options.map(option => option?.seriesName));
      if (datasets) {
        return this.options.map(option => this.getDataset(datasets[option.seriesName], option));
      }
    }
    return [];
  }

  private getDataset(data: number[], options: TransformStrategyDatasetOptions): ChartDataset {
    return {
      label: options.label,
      backgroundColor: options.color,
      hoverBackgroundColor: options.color,
      data
    };
  }

}
