import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, debounceTime } from 'rxjs/operators';
import { TransferStore } from '../../bulk-items/bulk-item-transfer/shared/transfer.store';
import { InsertProjectTypeOfUseTimelineEntryCommand } from '../../contract/insert-project-type-of-use-timeline-entry-command';
import { ChangeTypeOfUseRequest } from '../../contract/change-type-of-use-request';
import { ViewProject } from '../../contract/view-project.interface';
import { TypeOfUseStatusReason } from '../enums/type-of-use-status-reason.enum';
import { TypeOfUseStatus } from '../enums/type-of-use-status.enum';
import { RequestStatus } from '../enums/request-status.enum';
import { ChangeTypeOfUseRequestsDialogComponent } from '../change-type-of-use-requests-dialog/change-type-of-use-requests-dialog.component';
import { ProjectDataSource } from '../project.datasource';
import { TransferItem } from '../transfer/model/transfer-item';
import { DatesService } from 'app/shared/services/dates.service';
import { ChangeTypeOfUseIconsService } from '../change-type-of-use-icons.service';
import { TransferItemsSearchMatcher } from '../transfer/utils/transfer-items-search-matcher.class';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-change-type-of-use',
  templateUrl: './change-type-of-use.component.html',
  styleUrls: ['./change-type-of-use.component.scss']
})

@UntilDestroy()
export class ChangeTypeOfUseComponent implements OnInit {

  public readonly faSync: IconDefinition = faSync;

  public currentProject: ViewProject;
  public transferItems: Observable<TransferItem[]>;
  public transferItemsFiltered: Observable<TransferItem[]>;
  public statusTypes: TypeOfUseStatus[] = Object.keys(TypeOfUseStatus) as TypeOfUseStatus[];
  public statusReasons: TypeOfUseStatusReason[] = Object.keys(TypeOfUseStatusReason) as TypeOfUseStatusReason[];
  public statusForm: UntypedFormGroup;

  private filterControl: UntypedFormControl;
  private selectedTransferItems: TransferItem[] = [];
  private isItemsStatusLegal = false;

  constructor(public iconService: ChangeTypeOfUseIconsService,
              public projectDataSource: ProjectDataSource,
              private transferStore: TransferStore,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              protected dialog: MatDialog,
              protected router: Router,
              protected datesService: DatesService,
              protected dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
  }

  ngOnInit(): void {
    this.filterControl = new UntypedFormControl();
    this.subscribeToCurrentProject();

    this.transferItemsFiltered =
      combineLatest([
        this.transferItems,
        this.filterControl.valueChanges.pipe(debounceTime(500), startWith(''))])
      .pipe(
        map(([items, filterTerm]) => ([items, filterTerm, this.getSearchMatchTransferItemIds(items, filterTerm)])),
        map(([items, filterTerm, searchMatchItems]: [TransferItem[], string, Set<string>]) =>
          items.map((item: TransferItem) => {
            item.hiddenOption = !searchMatchItems.has(item.id);
            item.isActiveContainer = (filterTerm && searchMatchItems.has(item.id)) || item.isActiveContainer;
            return item;
          })
        )
      );
    this.buildForm();
    this.settingFormValidators();
  }

  get startDateTimeControl() {
    return this.statusForm.get('startDateTime');
  }

  get endDateTimeControl() {
    return this.statusForm.get('endDateTime');
  }

  get reasonControl() {
    return this.statusForm.get('reason');
  }

  get statusControl() {
    return this.statusForm.get('status');
  }

  get saveDisabled(): Observable<boolean> {
    return of(!this.statusForm.valid || !this.isItemsStatusLegal || this.selectedTransferItems.length === 0);
  }

  public applyItemsStatus(itemsStatus: boolean): void {
    this.isItemsStatusLegal = itemsStatus;
  }

  private buildForm(): void {
    this.statusForm = this.formBuilder.group({
      status: ['', [Validators.required]],
      reason: [null, [Validators.required]],
      startDateTime: [DatesService.fromIsoStartOfDay(new Date()), [Validators.required]],
      endDateTime: [null],
      comment: ['']
    });
  }

  private settingFormValidators(): void {
    this.statusForm.controls.status.valueChanges.subscribe(
      {
        next: value => {
          switch (value) {
            case TypeOfUseStatus.READY_TO_PICKUP:
              this.reasonControl.enable();
              this.reasonControl.setValidators(null);
              this.reasonControl.updateValueAndValidity();
              this.endDateTimeControl.disable();
              this.endDateTimeControl.patchValue(null, {emitEvent: false});
              break;
            case TypeOfUseStatus.FREE_OF_CHARGE:
              this.reasonControl.enable();
              this.endDateTimeControl.enable();
              this.reasonControl.setValidators([Validators.required]);
              this.reasonControl.updateValueAndValidity();
              break;
            case TypeOfUseStatus.IN_USE:
              this.reasonControl.patchValue(null, {emitEvent: false});
              this.reasonControl.disable();
              this.endDateTimeControl.disable();
              this.endDateTimeControl.patchValue(null, {emitEvent: false});
              break;
            case TypeOfUseStatus.STANDSTILL:
              this.reasonControl.enable();
              this.endDateTimeControl.enable();
              this.reasonControl.setValidators(null);
              this.reasonControl.updateValueAndValidity();
              break;
            default:
              break;
          }
          this.statusForm.markAllAsTouched();
        }
      }
    );

  }

  private subscribeToCurrentProject(): void {
    if (this.projectDataSource.noProjectSelected()) {
      this.projectDataSource.setCurrentProject(this.route.snapshot.params['projectId']);
    }

    this.projectDataSource.currentProject
    .pipe(
      filter(sourceProject => !!sourceProject),
      untilDestroyed(this))
    .subscribe((sourceProject: ViewProject) => {
      this.currentProject = sourceProject;
      this.transferStore.getProjectWithStatusPeriods(sourceProject.projectId);
    });

    this.transferItems = this.transferStore.transferItems;
  }

  public updateAmount(item: TransferItem): void {
    const index = this.selectedTransferItems
      .findIndex(selectedItem => selectedItem.transferId === item.transferId && selectedItem.sourceStockId === item.sourceStockId);
    const isNewItem = index === -1;

    if (item.transferAmount > 0 && isNewItem) {
      this.selectedTransferItems.push(item);
    } else if (item.transferAmount === 0 && !isNewItem) {
      this.selectedTransferItems.splice(index, 1);
    }

    this.projectDataSource.updateTransferItemToggleEvent();
  }

  public update(): void {
    let formValue = this.statusForm.getRawValue();
    let requests: ChangeTypeOfUseRequest[] = [];
    const dialogRef = this.dialog.open(ChangeTypeOfUseRequestsDialogComponent);
    this.selectedTransferItems.forEach((item: TransferItem) => {
      requests.push(new ChangeTypeOfUseRequest((new InsertProjectTypeOfUseTimelineEntryCommand(item.id,
        formValue.startDateTime,
        formValue.endDateTime,
        this.currentProject.projectId,
        formValue.status,
        formValue.reason,
        formValue.comment)), RequestStatus.PENDING, item));
    });
    dialogRef.componentInstance.requests = requests;
    dialogRef.afterClosed().subscribe((clear: boolean) => {
      if (clear) {
        this.clearForm();
        this.transferStore.getProjectWithStatusPeriods(this.currentProject.projectId);
      }
    });
  }

  public updateFilterControl(searchTerm: string): void {
    this.filterControl.patchValue(searchTerm.trim().toLowerCase());
  }

  public close(): void {
    this.router.navigate([`sites/projects/list/${this.currentProject.projectId}/change-type-of-use`]);
  }

  private clearForm(): void {
    this.selectedTransferItems = [];
    this.transferStore.deselectTransferItems();
  }

  public defaultStartDateTime(): Date {
    let date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  private getSearchMatchTransferItemIds(items: TransferItem[], filterTerm: string): Set<string> {
    if (!filterTerm) {
      return new Set((items || []).map(({id}) => id));
    }

    const searchMatcher = new TransferItemsSearchMatcher(items);
    return searchMatcher.getMatchItemIds(filterTerm);
  }
}
