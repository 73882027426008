import { TransportAssignmentsRequestParams } from 'app/modules/transportation/shared/contract/request-params/transport-assignments-request-params.interface';
import { LazyLoaderParamsBase } from './lazy-loader-params-base.class';

export class TransportAssignmentsLazyLoaderParams extends LazyLoaderParamsBase {
  private equipmentIdsHash: string;
  constructor(private equipmentIds: string[], startDate: Date, endDate?: Date) {
    super(startDate, endDate);
    this.equipmentIdsHash = this.getEquipmentIdsHash();
  }

  public copy(startDate: Date, endDate?: Date): TransportAssignmentsLazyLoaderParams {
    return new TransportAssignmentsLazyLoaderParams(this.equipmentIds, startDate, endDate);
  }

  public isSameExceptDateRange(params: TransportAssignmentsLazyLoaderParams): boolean {
    return this.equipmentIdsHash === params.getEquipmentIdsHash();
  }

  public getEquipmentIdsHash(): string {
    return (this.equipmentIds || []).sort().join('');
  }

  public toTransportAssignmentsRequestParams(): TransportAssignmentsRequestParams {
    return {
      equipmentId: this.equipmentIds,
      fromDate: this.startDate.toISOString(),
      toDate: this.endDate.toISOString()
    }
  }
}
