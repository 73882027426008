import { TransferItemUnit } from './transfer-item-unit';
import { BulkItemType } from './bulk-item-type';

export class CreateBulkItemCommand {

  constructor(
    public bulkItemType: BulkItemType,
    public bulkItemCategory: string,
    public bulkItemName: string,
    public bulkItemNumber: string,
    public bulkItemCostCenter: string,
    public bulkItemCostType: string,
    public manufacturerId: string,
    public manufacturerName: string,
    public supplierName: string,
    public supplierId: string,
    public bulkItemUnit: TransferItemUnit,
    public bulkItemWeight: number,
    public bulkItemScanCode: string,
    public bulkItemBGLCode: string,
    public bulkItemBALCode: string,
    public bulkItemDescription: string,
    public nonReturnable: boolean,
    public labels: string[]) {
  }
}
