<button
  mat-raised-button
  [color]="color"
  [disabled]="disabled"
  [ngClass]="{
    'no-label': (isLabelContainerEmpty | async)
  }">
  <span class="material-icons">
    <ng-content select="bh-icon"></ng-content>
  </span>
  <span class="label" #tmplLabel>
    <ng-content></ng-content>
  </span>
</button>

