import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeycloakService } from '../../../../core/keycloak';
import { ProjectDataSource } from '../../../../modules/disposition/shared/project.datasource';
import { ProjectsService } from '../../../../modules/disposition/shared/project.service';
import { EquipmentsDataSource } from '../../../../modules/equipment/shared/equipments.datasource';
import { EquipmentsService } from '../../../../modules/equipment/shared/equipments.service';
import { BaseFileUploadComponent } from '../base/base-file-upload.component';
import { MaintenanceService } from '../../../../modules/equipment/shared/maintenance.service';
import { BulkItemsService } from '../../../../modules/disposition/shared/bulk-items.service';
import { BulkItemDatasource } from '../../../../modules/disposition/shared/bulk-item.datasource';
import { StockService } from '../../../../modules/disposition/shared/services/stock.service';
import { EmployeeManagementService } from '../../../../modules/disposition/shared/employee-management.service';
import { EmployeeManagementDatasource } from '../../../../modules/disposition/shared/employee-management.datasource';
import { QualificationService } from 'app/modules/disposition/shared/qualification.service';
import { TransportService } from '../../../../modules/transportation/shared/services/transport.service';
import { TransportListDatasource } from 'app/modules/transportation/shared/services/transport-list.datasource';

@Component({
  selector: 'bh-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss']
})
export class FileUploadComponent extends BaseFileUploadComponent {

  constructor(qualificationService: QualificationService,
              protected equipmentService: EquipmentsService,
              protected bulkItemsService: BulkItemsService,
              protected employeeManagementService: EmployeeManagementService,
              protected equipmentsStore: EquipmentsDataSource,
              protected bulkItemStore: BulkItemDatasource,
              protected employeeManagementStore: EmployeeManagementDatasource,
              protected projectsService: ProjectsService,
              protected projectDataSource: ProjectDataSource,
              protected maintenanceService: MaintenanceService,
              protected stockService: StockService,
              protected transportService: TransportService,
              protected transportDataSource: TransportListDatasource,
              protected keycloak: KeycloakService,
              protected snackBar: MatSnackBar,
              protected languageService: LanguageService) {
      super(qualificationService,
      equipmentService,
      bulkItemsService,
      employeeManagementService,
      equipmentsStore,
      bulkItemStore,
      employeeManagementStore,
      projectsService,
      projectDataSource,
      maintenanceService,
      stockService,
      transportService,
      transportDataSource,
      keycloak,
      snackBar,
      languageService);
  }
}
