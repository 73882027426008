<mat-expansion-panel (opened)="isCollapsed = false" (closed)="isCollapsed = true" #matPanel>
  <mat-expansion-panel-header collapsedHeight="64px">
    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
      <span>{{'general.comment.s'|translate}}</span>
    </mat-panel-title>
    <mat-panel-description *ngIf="isCollapsed" [ngClass]="{'is-webkit': isWebkit}" data-osp-test="card-assignment-description">
      {{getEncodedComment()}}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <form [formGroup]="assignmentEditCommentFormGroup" novalidate
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="15px">

    <div fxLayout="row">
      <mat-form-field>
          <mat-label>{{'general.comment.s'|translate}}</mat-label>
          <textarea matInput [maxlength]="fieldLimit.LONG_TEXT" formControlName="comment"
                    [value]="assignment.comment ? getEncodedComment() : '-'"
                    [readonly]="readOnly">
          </textarea>
      </mat-form-field>
    </div>
  </form>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center" *ngIf="!readOnly">
    <button mat-button color="primary" (click)="cancelEdit()">{{'general.buttons.cancel'|translate}}</button>
    <button mat-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="saveDisabled()">
      {{'general.buttons.save'|translate}}
    </button>
  </mat-dialog-actions>
</mat-expansion-panel>
