import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { LocalUserStorageService } from '../../../../services/local-user-storage.service';
import { SearchSuggestionConfiguration } from './search-suggestion.configuration';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounce } from 'app/shared/utils/debounce';

@Component({
  selector: 'bh-search-suggestion',
  templateUrl: './search-suggestion.component.html',
  styleUrls: ['./search-suggestion.component.scss']
})
export class SearchSuggestionComponent implements OnInit {
  @Input() searchControl: FormControl;
  private _suggestionField: string;
  public options = new BehaviorSubject<string[]>([]);
  public filteredOptions: Observable<string[]>;

  constructor(private localStorageService: LocalUserStorageService) {}

  @Input()
  set suggestionField(value: string) {
    this._suggestionField = value;
    this.populateOptions();
  }

  get suggestionField(): string {
    return this._suggestionField;
  }

  ngOnInit() {
    this.filteredOptions = combineLatest([
      this.searchControl.valueChanges.pipe(
        startWith(this.searchControl.value),
        debounceTime(300),
        distinctUntilChanged()),
      this.options
    ]).pipe(
      map(([value, options]) => this.filterOptions(value, options))
    );
  }

  private filterOptions(value: string, options: string[]): string[] {
    if (!value || value.trim() === '') {
      return options;
    }
    return options.filter(option => option.toLowerCase().startsWith(value.toLowerCase()));
  }

  @debounce(100)
  public onBlur(): void {
    this.save();
  }

  public save(): void {
    const suggestionsField = this.suggestionField;
    if (!suggestionsField) {
      return;
    }

    let currentOptions = this.options.getValue();
    const valueToSave = this.searchControl.value;

    const index = currentOptions.indexOf(valueToSave);

    if (this.searchControl && valueToSave && valueToSave.trim()) {
      if (index > -1) {
        currentOptions.splice(index, 1);
      }

      const newOptions = [valueToSave, ...currentOptions.slice(0,
        SearchSuggestionConfiguration.MAX_SEARCH_VALUES - 1)];
      this.options.next(newOptions);

      if (suggestionsField) {
        this.localStorageService.setUserValue(suggestionsField, JSON.stringify(newOptions));
      }
    }
  }

  public populateOptions() {
    const suggestionsField = this.suggestionField;
    if (!suggestionsField) {
      return;
    }
    const suggestions = this.localStorageService.getUserValue(suggestionsField);
    if (suggestions) {
      this.options.next(JSON.parse(suggestions));
    } else {
      this.options.next([]);
    }
  }
}
