import { Component, OnInit } from '@angular/core';
import { OrganisationsService } from '../organisations.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewOrganisation } from '../../contract/view-organisation.interface';
import { DeleteOrganisationCommand } from '../../contract/delete-organisation-command';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bh-organisation-delete',
  templateUrl: './organisation-delete.component.html',
  styleUrls: ['./organisation-delete.component.scss']
})
export class OrganisationDeleteComponent implements OnInit {

  public organisationId: string;
  public customerId: string;
  public dialogRef: MatDialogRef<OrganisationDeleteComponent>;
  public organisationDeleteForm: UntypedFormGroup;
  public organisations: ViewOrganisation[];
  public deleteConfirmed = false;

  constructor(private organisationsService: OrganisationsService,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.buildForm();
    this.getOrganisations();
  }

  private buildForm() {
    this.organisationDeleteForm = this.formBuilder.group({
      organisationId: [this.organisationId, [<any>Validators.required]],
      relocationTargetId: [null, [<any>Validators.required]]
    });
  }

  get relocationTargetId() {
    return this.organisationDeleteForm.get('relocationTargetId');
  }

  public save() {
    if (this.isValid()) {
      let formValue = this.organisationDeleteForm.getRawValue();
      let cmd = new DeleteOrganisationCommand();
      cmd.organisationId = formValue.organisationId;
      cmd.relocationTargetId = formValue.relocationTargetId;

      this.organisationsService.deleteOrganisation(cmd).subscribe(
          data => {
            this.dialogRef.close(data);
          },
          (error: HttpErrorResponse) => {
            this.dialogRef.close();
          });
    }
  }

  public isValid(): boolean {
    return this.organisationDeleteForm.valid;
  }

  private getOrganisations() {
    this.organisationsService.getOrganisationsByCustomerId(this.customerId, false, this.organisationId).subscribe(
        res => {
          this.organisations = res;
        }, error => {
          console.log('error on getting organisations', error);
        });
  }
}
