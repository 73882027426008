import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseProjectViewAttachmentsComponent } from '../base/base-project-view-attachments.component';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-project-view-attachments',
  templateUrl: './project-view-attachments.component.html',
  styleUrls: ['./project-view-attachments.component.scss']
})
export class ProjectViewAttachmentsComponent extends BaseProjectViewAttachmentsComponent {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected userConfigurationService: UserConfigurationService,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService,
              public matSnackBar: MatSnackBar) {
    super(authService, router, route, dialog, userConfigurationService, routerHistory, projectStore, languageService, matSnackBar);
  }
}
