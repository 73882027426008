<div class="scrollable" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div>
      <bh-title [text]="'general.attachment.pl'|translate"></bh-title>
    </div>

    <div fxLayout="row" fxLayoutAlign="start end" fxFlex="150px">
      <fa-icon class="sort-icon" [icon]="faSortAlphaDown"></fa-icon>
      <mat-form-field class="no-margin-bottom">
        <mat-label>{{'general.labels.sortBy'|translate}}:</mat-label>
        <mat-select [(value)]="documentsSortParameter" (selectionChange)="changeDocumentsSortParameter($event.value)">
          <mat-option [value]="fileName">{{'general.name.s'|translate}}
          </mat-option>
          <mat-option [value]="fileDescription">{{'general.description'|translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch"
       class="attachment-type-container">
    <div *ngIf="hasAnyAuthority([authorities.PROJECT_UPDATE,
       authorities.PROJECT_ADD_ATTACHMENTS]); else noUploadAllowed">
      <bh-subtitle [text]="'general.labels.uploadDocPict'|translate"></bh-subtitle>
      <bh-file-upload
          [itemId]="project?.projectId"
          uploaderType="PROJECTS">
      </bh-file-upload>
    </div>
    <ng-template #noUploadAllowed>
      <div class="no-upload">
        {{'shared.attachment.uploadNotAllowed' | translate}}
      </div>
    </ng-template>
  </div>

  <bh-attachment-docuware
      *ngIf="hasConnector"
      [documents]="docuwareDocuments"
      [loading]="docuwareRequestPending"
      [serviceAvailable]="docuwareServiceAvailable"
      [pendingDownloads]="pendingDocuwareDownloads"
      (downloadRequested)="downloadDocuwareDocument($event)">
  </bh-attachment-docuware>

  <bh-attachment-topfact
      *ngIf="hasTopfactConnector"
      [documents]="topfactDocuments"
      [loading]="topfactRequestPending"
      [serviceAvailable]="topfactServiceAvailable"
      [pendingDownloads]="pendingTopfactDownloads"
      (downloadRequested)="downloadTopfactDocument($event)">
  </bh-attachment-topfact>

  <bh-attachment-list-category
    [documents]="documents"
    [pendingChanges]="waiting"
    [deletable]="canDeleteDocuments"
    [editable]="canUpdateDocuments"
    [editableName]="canUpdateDocuments"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="saveFileDescription($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-category>

  <bh-attachment-list-image-category
    [images]="images"
    [pendingChanges]="waiting"
    [setDefaultEnable]="false"
    [deletable]="canDeleteDocuments"
    [visibleDescription]="true"
    [editableDescription]="canUpdateDocuments"
    [editableName]="canUpdateDocuments"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="saveFileDescription($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-image-category>
</div>
