import * as moment from 'moment';
import { Section } from './section.interface';
import { unitOfTime } from 'moment';
import { TimelineEvent } from '../../modules/disposition/contract/timeline-event';
import { isProjectEvent } from '../../modules/disposition/contract/project-event';
import { isEquipmentAssignmentEvent } from '../../modules/disposition/contract/equipment-assignment-event';
import { isSubEquipmentSection } from 'app/modules/disposition/contract/custom-section-types/sub-equipment-section.interface';
import { isTeamMemberSection } from 'app/modules/disposition/contract/custom-section-types/team-member-section.interface';
import { isEmployeeSection } from 'app/modules/disposition/contract/custom-section-types/employee-section.interface';
import { isEmptyPlaceholderSection } from 'app/modules/disposition/contract/custom-section-types/empty-placeholder-section.interface';
import { ListType } from '../enums/list-type.enum';
import { isTeamHeaderSection } from 'app/modules/disposition/contract/custom-section-types/team-header-section.interface';
import { isTransferItemAssignmentEvent } from 'app/modules/disposition/contract/transfer-item-assignment-event';
import { TransportTaskEvent } from '../../modules/transportation/shared/contract/transportation-timeline-scheduler/transport-task-event.class';
import { TransportTaskChecker } from 'app/modules/transportation/shared/services/transport-task-checker.service';

export class SchedulerHighlighter {
  public static readonly cellDatetime = 'dhx_custom_datetime#';
  private static readonly projectDuration = 'dhx_custom_project-duration';
  private static readonly projectEvent = 'dhx_custom_project_event';
  private static readonly transferEvent = 'dhx_custom_transfer_event';
  private static readonly projectEventReadonly = 'dhx_custom_project_event_readonly';
  private static readonly projectStarting = 'dhx_custom_project_starting';
  private static readonly projectEnding = 'dhx_custom_project_ending';
  private static readonly eventVisibleStart = 'dhx_custom_visible_start';
  private static readonly eventVisibleEnd = 'dhx_custom_visible_end';
  private static readonly element = 'dhx_custom_element';
  private static readonly subelement = 'dhx_custom_subelement';
  private static readonly equipment = 'dhx_custom_equipment';
  private static readonly employee = 'dhx_custom_employee';
  private static readonly noEmployeeAssigned = 'dhx_custom_no_employee_assigned';
  private static readonly team = 'dhx_custom_team';
  private static readonly empty = 'dhx_custom_empty';
  private static readonly today = 'dhx_custom_today';
  private static readonly yesterday = 'dhx_custom_yesterday';
  private static readonly past = 'dhx_custom_past';
  private static readonly sunday = 'dhx_custom_sunday';
  private static readonly chargeDate = 'dhx_custom_charge_date';
  private static readonly weekday = 'dhx_custom_weekday';
  private static readonly weekend = 'dhx_custom_weekend';
  private static readonly hideCalendarWeek = 'dhx_custom_hide_calendar_week';
  private static readonly expanded = 'dhx_custom_expanded';
  private static readonly collapsed = 'dhx_custom_collapsed';
  private static readonly dateData = 'dhx_custom_date';
  private static readonly incompatibleTransportType = 'dhx_custom_incompatible_type';
  private static readonly cellSection = 'dhx_custom_section';

  public static event(start: Date, end: Date, event: TimelineEvent): string {
    const classes: string[] = [];
    SchedulerHighlighter.addProjectEvent(event, classes);
    SchedulerHighlighter.addStartingEndingDisposition(start, end, classes);
    return classes.join(' ');
  };

  public static eventTransportationTask(start: Date, end: Date, event: TransportTaskEvent): string {
    const classes: string[] = [];
    SchedulerHighlighter.addStartingEndingTransport(event, start, end, classes);
    SchedulerHighlighter.addEventTransportType(event, classes);
    return classes.join(' ');
  }

  public static monthCell(events: any[], dateToCheck: Date): string {
    const classes: string[] = [];
    SchedulerHighlighter.addToday(dateToCheck, 'month', classes);
    SchedulerHighlighter.addYesterday(dateToCheck, 'month', classes);
    SchedulerHighlighter.addPast(dateToCheck, 'month', classes);
    SchedulerHighlighter.addSubequipment(events, classes);
    return classes.join(' ');
  }

  public static dayCellDisposition(events: any[], dateToCheck: Date): string {
    const classes: string[] = [];
    SchedulerHighlighter.addChargeDate(events, dateToCheck, classes);
    SchedulerHighlighter.dayCell(events, dateToCheck, classes)
    return classes.join(' ');
  }

  public static dayCellTransportation(events: any[], dateToCheck: Date, section: any): string {
    const classes: string[] = [];
    SchedulerHighlighter.dayCell(events, dateToCheck, classes);
    SchedulerHighlighter.addSectionKey(section.key, classes);
    return classes.join(' ');
  }

  public static dayCell(events: any[], dateToCheck: Date, classes: string[]): string[] {
    SchedulerHighlighter.addToday(dateToCheck, 'day', classes);
    SchedulerHighlighter.addYesterday(dateToCheck, 'day', classes);
    SchedulerHighlighter.addPast(dateToCheck, 'day', classes);
    SchedulerHighlighter.addSunday(dateToCheck, classes);
    SchedulerHighlighter.addSubequipment(events, classes);
    SchedulerHighlighter.addDatetime(dateToCheck, classes);
    return classes;
  }

  public static hourCell(events: any[], dateToCheck: Date, section: any): string {
    const classes: string[] = [];
    SchedulerHighlighter.addPast(dateToCheck, 'day', classes);
    SchedulerHighlighter.addSectionKey(section.key, classes);
    SchedulerHighlighter.addDatetime(dateToCheck, classes);
    return classes.join(' ');
  }

  private static addSectionKey(key: string, classes: string[]): string[] {
    classes.push(SchedulerHighlighter.getCellSectionClassName(key));
    return classes
  }

  private static addDatetime(date: Date, classes: string[]): string[] {
    classes.push(`${SchedulerHighlighter.cellDatetime}${date.getTime()}`);
    return classes;
  }

  public static getCellSectionClassName(sectionKey: string): string {
    return `${SchedulerHighlighter.cellSection}-${sectionKey}`;
  }

  public static scaleY(key: string, label: string, section: Section): string {
    const classes: string[] = [];
    SchedulerHighlighter.addSubequipmentSection(section, classes);
    SchedulerHighlighter.addSectionExpanded(section, classes);
    return classes.join(' ');
  }

  public static monthScaleX(dateToCheck: Date): string {
    const classes: string[] = [];
    SchedulerHighlighter.addToday(dateToCheck, 'month', classes);
    SchedulerHighlighter.addYesterday(dateToCheck, 'month', classes);
    SchedulerHighlighter.addPast(dateToCheck, 'month', classes);
    SchedulerHighlighter.addDateMonth(dateToCheck, classes);
    return classes.join(' ');
  }

  public static dayScaleX(dateToCheck: Date): string {
    const classes: string[] = [];
    SchedulerHighlighter.addToday(dateToCheck, 'day', classes);
    SchedulerHighlighter.addYesterday(dateToCheck, 'day', classes);
    SchedulerHighlighter.addPast(dateToCheck, 'day', classes);
    SchedulerHighlighter.addWeekend(dateToCheck, classes);
    SchedulerHighlighter.addDateDay(dateToCheck, classes);
    return classes.join(' ');
  }

  public static halfHourScaleX(dateToCheck: Date): string {
    const classes: string[] = [];
    SchedulerHighlighter.addPast(dateToCheck, 'day', classes);
    SchedulerHighlighter.addDateHalfHour(dateToCheck, classes);
    return classes.join(' ');
  }

  public static daySecondScaleX(dateToCheck: Date): string {
    const classes: string[] = [];
    SchedulerHighlighter.addCalendarWeekHiding(dateToCheck, classes);
    return classes.join(' ');
  }

  private static addProjectEvent(event: TimelineEvent, classes: string[]): void {
    if (isProjectEvent(event)) {
      classes.push(this.projectDuration);
    } else if (event.transfer) {
      classes.push(this.transferEvent);
    } else {
      classes.push(event.readonly ? this.projectEventReadonly : this.projectEvent);
    }
  }

  private static addStartingEndingDisposition(start: Date, end: Date, classes: string[]): void {
    if (SchedulerHighlighter.isStartVisible(start)) {
      classes.push(SchedulerHighlighter.projectStarting);
    }
    if (SchedulerHighlighter.isEndVisible(end)) {
      classes.push(SchedulerHighlighter.projectEnding);
    }
  }

  private static addStartingEndingTransport(event: TransportTaskEvent, start: Date, end: Date, classes: string[]): void {
    if (!TransportTaskChecker.isInProgressAndBeyond(event?.assignment) && SchedulerHighlighter.isStartVisible(start)) {
      classes.push(SchedulerHighlighter.eventVisibleStart);
    }
    if (!TransportTaskChecker.isDoneOrArchived(event?.assignment) && SchedulerHighlighter.isEndVisible(end)) {
      classes.push(SchedulerHighlighter.eventVisibleEnd);
    }
  }

  private static isStartVisible(start: Date): boolean {
    return moment(scheduler.getState().min_date).isSameOrBefore(start);
  }

  private static isEndVisible(end: Date): boolean {
    return moment(scheduler.getState().max_date).isAfter(end);
  }

  private static addEventTransportType(event: TransportTaskEvent, classes: string[]): void {
    if (!event.isCompatibleType) {
      classes.push(this.incompatibleTransportType);
    }
  }

  private static addToday(dateToCheck: Date, granularity: unitOfTime.DurationConstructor, classes: string[]): void {
    if (moment().isSame(moment(dateToCheck), granularity)) {
      classes.push(this.today);
    }
  }

  private static addYesterday(dateToCheck: Date, granularity: unitOfTime.DurationConstructor, classes: string[]): void {
    if (moment().subtract(1, granularity).isSame(dateToCheck, granularity)) {
      classes.push(this.yesterday);
    }
  }

  private static addPast(dateToCheck: Date, granularity: unitOfTime.DurationConstructor, classes: string[]): void {
    if (moment().isAfter(dateToCheck, granularity)) {
      classes.push(this.past);
    }
  }

  private static addSunday(dateToCheck: Date, classes: string[]): void {
    if (moment(dateToCheck).isoWeekday() === 7) {
      classes.push(this.sunday);
    }
  }

  private static addChargeDate(events: any[], dateToCheck: Date, classes: string[]): void {
    if (events != null && events[0] != null && (isProjectEvent(events[0]) ||
      isEquipmentAssignmentEvent(events[0]) ||
      isTransferItemAssignmentEvent(events[0]))) {
      const chargeDate = events[0].charge_date;
      if (moment(chargeDate).isSame(moment(dateToCheck), 'day')) {
        classes.push(this.chargeDate);
      }
    }
  }

  private static addWeekend(dateToCheck: Date, classes: string[]): void {
    const dayOfWeek = moment(dateToCheck).isoWeekday();
    if (dayOfWeek <= 5) {
      classes.push(this.weekday);
    }
    if (dayOfWeek === 6 || dayOfWeek === 7) {
      classes.push(this.weekend);
    }
  }

  private static addCalendarWeekHiding(dateToCheck: Date, classes: string[]): void {
    const dayOfMonth = moment(dateToCheck);
    const endOfMonth = moment(dateToCheck).endOf('month');
    if (dayOfMonth.isoWeekday() === 7 || dayOfMonth.dayOfYear() === endOfMonth.dayOfYear()) {
      classes.push(this.hideCalendarWeek);
    }
  }

  private static addSubequipment(events: any[], classes: string[]): void {
    if (events && events.filter(event => event.assignment && (event.assignment.equipmentContainerId ||
      event.assignment.isTeamComplete)).length > 0) {
      classes.push(this.subelement);
    } else {
      classes.push(this.element);
    }
  }

  private static addSubequipmentSection(section: Section, classes: string[]): void {
    if (section && (isSubEquipmentSection(section) || isTeamMemberSection(section))) {
      classes.push(this.subelement);
    } else if (section && isEmployeeSection(section)) {
      classes.push(this.employee + ' ' + this.element);
    } else if (section && isTeamHeaderSection(section)) {
      classes.push(this.team + ' ' + this.element);
    } else if (section && (isEmptyPlaceholderSection(section) &&
      (section.types.length === 0 ||
        (section.types.includes(ListType.EQUIPMENTS) && section.types.includes(ListType.EMPLOYEES))))) {
      classes.push(this.empty + ' ' + this.element);
    } else if (section && isEmptyPlaceholderSection(section) && section.types.includes(ListType.EMPLOYEES)) {
      classes.push(this.noEmployeeAssigned + ' ' + this.element);
    } else {
      classes.push(section.children ? '' : this.equipment + ' ' + this.element);
    }
  }

  private static addSectionExpanded(section: Section, classes: string[]): void {
    if (section && section.open) {
      classes.push(this.expanded);
    } else {
      classes.push(this.collapsed);
    }
  }

  private static addDateHalfHour(dateToCheck: Date, classes: string[]): void {
    classes.push(this.getClassDateHalfHour(dateToCheck));
  }

  private static addDateDay(dateToCheck: Date, classes: string[]): void {
    classes.push(this.getClassDateDay(dateToCheck));
  }

  private static addDateMonth(dateToCheck: Date, classes: string[]): void {
    classes.push(this.getClassDateMonth(dateToCheck));
  }

  public static getClassDateHalfHour(dateToCheck: Date): string {
    return SchedulerHighlighter.dateData + '_'
      + dateToCheck.getHours() + '_'
      + (dateToCheck.getMinutes() >= 30 ? '30' : '00');
  }

  public static getClassDateDay(dateToCheck: Date): string {
    return `${SchedulerHighlighter.dateData}_${dateToCheck.getDate()}`;
  }

  public static getClassDateMonth(dateToCheck: Date): string {
    return `${SchedulerHighlighter.dateData}_${dateToCheck.getMonth()}`;
  }
}
