import { Address } from '../../organisation/contract/address.interface';
import { SalesContractStatus } from './sales-contract-status.enum';
import { SalesContractAmortizationType } from './sales-contract-amortization-type.enum';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';


export class AddSalesContractCommand {
  salesContractId: string;
  equipmentId: string;
  salesContractNumber: string;
  customerOrderNumber: string;
  cost: number;
  contractPartner: string;
  goodsRecipientName: string;
  goodsRecipientAddress: Address;
  invoiceCustomerName: string;
  invoiceCustomerAddress: Address;
  customerContact: string;
  plannedDeliveryDate: string;
  internalContact: string;
  externalContact: string;
  paymentCondition: string;
  status: SalesContractStatus;
  amortizationPeriod: string; // Period
  amortizationType: SalesContractAmortizationType;
  comment: string;
  documents: AttachedDocument[];

  // internal fields, invoice ids to link with corresponding invoice
  invoiceCompanyId: string;
  invoiceAccountingId: string;
  invoiceNumber: string;
  imported: boolean;
}
