import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { formatNumber } from '@angular/common';

export type VoltagePipeSettings = {
  sourceType: 'mV' | 'V';
  showType: boolean;
}

const DEFAULT_SETTINGS: VoltagePipeSettings = {
  sourceType: 'mV',
  showType: true
}

@Pipe({
  name: 'voltage',
  pure: true
})
export class VoltagePipe implements PipeTransform {

  constructor(private _languageService: LanguageService,
              @Inject(LOCALE_ID) private _browserLocale: string) {}

  public transform(value: any, settings?: VoltagePipeSettings): any {
    if (!settings) {
      settings = DEFAULT_SETTINGS;
    }

    if (!!value) {
      value = parseFloat(value);

      if (settings.sourceType === 'mV') {
        value = value * 0.001;
      }

      const formattedValue = formatNumber(parseFloat(value), this.getLocale(), '1.0-2');
      return settings.showType ? `${formattedValue} V` : formattedValue;
    }

    return value;
  }

  private getLocale(): string {
    let locale = this._languageService.getCurrentLocale();
    return !!locale ? locale : this._browserLocale;
  }
}
