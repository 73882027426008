import { MaintenanceTaskCompletedSearch } from 'app/modules/maintenance/tasks/shared/maintenance-task-completed-search.interface';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { Task } from '../../contract/task/task';

export class MaintenanceTaskAdapter {
  public id: string;
  public category: MaintenanceCategory;
  public equipmentId: string;
  constructor(private task: TaskCompleted | MaintenanceTaskCompletedSearch | Task) {
    if (task) {
      this.id = task.id;
      this.category = (<TaskCompleted>task).category || (<MaintenanceTaskCompletedSearch>task).maintenanceCategory;
      this.equipmentId = task.equipmentId;
    }
  }
}
