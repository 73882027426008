<div class="not-today" *ngIf="!isToday; else today">
  <ng-container *ngIf="!unknownRemaining; else unknown">
    <ng-container [ngSwitch]="getCurrentLocale()">

      <ng-container *ngSwitchCase="languages.DE">
        <ng-container *ngIf="dueDateInfo.remainingValue >= 0; then prepIn; else prepAgo"></ng-container>
        <ng-container *ngTemplateOutlet="remainingValue"></ng-container>
        <ng-container *ngTemplateOutlet="remainingValueType"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="languages.EN">
        <ng-container *ngIf="dueDateInfo.remainingValue >= 0 else expiredEN">
          <ng-container *ngTemplateOutlet="prepIn"></ng-container>
          <ng-container *ngTemplateOutlet="remainingValue"></ng-container>
          <ng-container *ngTemplateOutlet="remainingValueType"></ng-container>
        </ng-container>

        <ng-template #expiredEN>
          <ng-container *ngTemplateOutlet="remainingValue"></ng-container>
          <ng-container *ngTemplateOutlet="remainingValueType"></ng-container>
          <ng-container *ngTemplateOutlet="prepAgo"></ng-container>
        </ng-template>
      </ng-container>

    </ng-container>
  </ng-container>
</div>

<ng-template #today>
  <div #today class="today">{{'general.units.time.today'|translate}}</div>
</ng-template>
<ng-template #prepIn><span>{{'general.in'|translate}} </span></ng-template>
<ng-template #prepAgo><span>{{'general.ago'|translate}} </span></ng-template>
<ng-template #remainingValue><span class="remaining-value">{{getRemainingValue()}} </span></ng-template>
<ng-template #remainingValueType><span>{{dueDateInfo.remainingValueType | remainingValueType}} </span></ng-template>

<ng-template #unknown><span>--</span></ng-template>
