import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class AutonumberSchemaValidators {
  public static schemaTemplateValidator(isValidFunction: (value: string) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const valid = !control.value || isValidFunction(control.value);
      return valid
        ? null
        : { 'schemaTemplateInvalid': true };
    };
  }

  public static schemaMaxSymbolsValidator(isValidFunction: (value: string) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const valid = !control.value || isValidFunction(control.value);
      return valid
        ? null
        : { 'schemaMaxLengthExceeded': true };
    };
  }
}
