export class UpdateUserCommand {
  userId: string;
  firstName: string;
  name: string;
  mobileNumber: string;
  roleIds: string[];
  customerId: string;
  organisationIds: string[];
  validUntil: any;
}
