<div *ngIf="task?.rule" fxFlex fxLayout="column" class="wrapper-container">
  <div class="container-fields">
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.maintenanceTaskType' | translate}}</mat-label>
      <input matInput
        readonly
        [value]="task.rule.maintenanceCategory | maintenanceCategory | defaultValue : emptyValue"
        data-osp-test="input-maintenance-category">
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'general.equipm.s' | translate}}</mat-label>
      <input matInput
        readonly
        [value]="(task.equipmentName ? task.equipmentName : task.equipmentModel) | defaultValue : emptyValue"
        data-osp-test="input-maintenance-equipment-name">
      <mat-icon matSuffix
        (click)="navigateToEquipment(task.equipmentId)"
        [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.equipm.s'|translate }">
        open_in_new
      </mat-icon>
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'general.serviceType.s' | translate}}</mat-label>
      <input matInput
             readonly
             [value]="task.rule.maintenanceTypeName | defaultValue : emptyValue"
             data-osp-test="input-maintenance-type-name">
    </mat-form-field>
  </div>

  <div class="container-fields">
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'general.name.s' | translate}}</mat-label>
      <input matInput
             readonly
             [value]="task.rule.ruleName | defaultValue : emptyValue"
             data-osp-test="input-rule-name">
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.rules.itemGroup' | translate}}</mat-label>
      <input matInput
             readonly
             [value]="task.rule.articleGroup | defaultValue : emptyValue"
             data-osp-test="input-article-group">
    </mat-form-field>
  </div>

  <div class="container-fields">
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.task.due' | translate}}</mat-label>
      <input matInput
        readonly
        [value]="task.dueDateInfo | dueDateRemainingValue | defaultValue : emptyValue"
        data-osp-test="input-due-date-info">
        <mat-icon matSuffix
          *ngIf="canEditTask && !(isDueDateUpdating | async)"
          (click)="setDueDate($event)"
          data-osp-test="icon-due-date-edit">
          mode_edit
        </mat-icon>
        <mat-spinner *ngIf="canEditTask && (isDueDateUpdating | async)"
          matSuffix
          class="spinner-suffix"
          diameter="25">
        </mat-spinner>
    </mat-form-field>
    <mat-form-field class="field" floatLabel="always">
      <mat-label>{{'shared.maintenance.rules.lastCompletion' | translate}}</mat-label>
      <input matInput
        readonly
        [value]="(task.latestCompletionDate | formatTimezoneDateTime) | defaultValue : emptyValue"
        test-id="lastCompletion">
        <mat-icon *ngIf="task.latestCompletedTaskId as taskId"
          matSuffix
          (click)="navigateToCompletedTask(taskId)"
          [matTooltip]="'general.labels.jumpTo'|translate:{value: 'shared.maintenance.rules.completedTask'|translate}"
          data-osp-test="icon-jump-last-completed">
          open_in_new
        </mat-icon>
    </mat-form-field>
  </div>

  <mat-form-field class="field" floatLabel="always">
    <mat-label>{{'general.description' | translate}}</mat-label>
    <textarea matInput
      readonly
      [value]="task.rule.ruleDescription | defaultValue : emptyValue"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      data-osp-test="textarea-rule-description">
    </textarea>
  </mat-form-field>

  <div class="section-title">{{ 'modules.maintenance.maintenanceViewDetail.dueDateCalculationTitle'|translate }}</div>
  <div *ngIf="!(isDueCalculated | async)" class="section-subtitle">
    {{
      'modules.maintenance.maintenanceViewDetail.hintDueDateSetManually'|translate :
      { value: task.dueDateInfo.dueDate | formatTimezoneDateTime }
    }}
  </div>

  <div fxFlex fxLayout="row" fxLayoutGap="10px">
    <bh-interval-panel *ngIf="(task.rule | intervalPanelFormGroupFactory : formType.FIXED_DATE) as form"
      [displayMode]="displayMode.COMPACT"
      [inputType]="inputTypes.DATE"
      [maintenanceRule]="task.rule"
      [intervalGroup]="form"
      [readonly]="true"
      [disableAnimation]="true"
      [nextFixedDateThreshold]="task.nextFixedDateThreshold"
      [showNextDueDate]="true"
      [checkboxLabel]="'shared.maintenance.rules.alwaysOnSpecificDate'|translate"
      [inputLabel]="'shared.maintenance.rules.dueDate'|translate"
      [warningSuffix]="'general.units.time.day.pl'|translate"
      [disablePanelToggle]="!(isDueCalculated | async)"
      data-osp-test="panel-fixed-date">
    </bh-interval-panel>

    <bh-interval-panel *ngIf="(task.rule | intervalPanelFormGroupFactory : formType.PERIOD_INTERVAL) as form"
      [displayMode]="displayMode.COMPACT"
      [inputType]="inputTypes.PERIOD"
      [maintenanceRule]="task.rule"
      [intervalGroup]="form"
      [readonly]="true"
      [disableAnimation]="true"
      [nextPeriodThreshold]="task.nextPeriodThreshold"
      [showNextDueDate]="true"
      [checkboxLabel]="'shared.maintenance.rules.afterTimeInterval'|translate"
      [inputLabel]="'shared.maintenance.rules.dueInterval'|translate"
      [inputSuffix]="'general.units.time.month.pl'|translate"
      [warningSuffix]="'general.units.time.day.pl'|translate"
      [disablePanelToggle]="!(isDueCalculated | async)"
      data-osp-test="panel-period">
    </bh-interval-panel>

    <bh-interval-panel *ngIf="(task.rule | intervalPanelFormGroupFactory : formType.OPERATING_HOURS) as form"
      [displayMode]="displayMode.COMPACT"
      [inputType]="inputTypes.NUMBER"
      [maintenanceRule]="task.rule"
      [intervalGroup]="form"
      [readonly]="true"
      [disableAnimation]="true"
      [nextOperatingHoursThresholdDate]="task.nextOperatingHoursThresholdDate"
      [showNextDueDate]="true"
      [checkboxLabel]="'shared.maintenance.rules.afterOperatingHoursExpired'|translate"
      [inputLabel]="'general.operatingHour.abbr'|translate"
      [inputSuffix]="'general.operatingHour.abbr'|translate"
      [warningSuffix]="'general.operatingHour.abbr'|translate"
      [disablePanelToggle]="!(isDueCalculated | async)"
      data-osp-test="panel-operating-hours">
    </bh-interval-panel>

    <bh-interval-panel *ngIf="(task.rule | intervalPanelFormGroupFactory : formType.MILEAGE) as form"
      [displayMode]="displayMode.COMPACT"
      [inputType]="inputTypes.NUMBER"
      [maintenanceRule]="task.rule"
      [intervalGroup]="form"
      [readonly]="true"
      [disableAnimation]="true"
      [nextMileageThresholdDate]="task.nextMileageThresholdDate"
      [showNextDueDate]="true"
      [checkboxLabel]="'shared.maintenance.rules.afterMileageExpired'|translate"
      [inputLabel]="'shared.maintenance.rules.mileage'|translate"
      [inputSuffix]="'km'|dimensionUnitDisplay"
      [warningSuffix]="'km'|dimensionUnitDisplay"
      [disablePanelToggle]="!(isDueCalculated | async)"
      data-osp-test="panel-mileage">
    </bh-interval-panel>
  </div>

  <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span fxFlex="0 0 auto">{{'shared.maintenance.rules.ruleRepeatedCertainNumberOfTimes'|translate}}</span>
    <mat-form-field fxFlex="0 0 150px" floatLabel="always">
      <input matInput
        readonly
        [value]="task.rule.taskLimit | defaultValue : emptyValue"
        data-osp-test="input-task-limit">
    </mat-form-field>
  </div>
</div>

