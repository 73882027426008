import { Pipe, PipeTransform } from '@angular/core';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';
import _ from 'lodash';

@Pipe({
  name: 'locationToLatLngLiteral'
})
export class LocationToLatLngLiteralPipe implements PipeTransform {

  public transform(location: LatLon): google.maps.LatLngLiteral {
    return !(_.isNil(location?.lat) || _.isNil(location?.lon))
      ? { lat: location.lat, lng: location.lon }
      : null;
  }

}
