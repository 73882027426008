import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { getHttpParameters } from '../../../shared/utils';
import { environment } from '../../../../environments/environment';
import { SearchEmployeeDisposition } from '../contract/search-employee-disposition.interface';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { AddEmployeeToProjectAssignmentCommand } from '../contract/add-employee-project-assignment-command';
import { ViewEmployeeProjectAssignment } from '../contract/view-employee-project-assignment.interface';
import { map } from 'rxjs/operators';
import { ValidateEmployeeAssignmentParams } from '../contract/validate-employee-assignment-params.interface';
import { RemoveEmployeeToProjectAssignmentCommand } from '../contract/remove-employee-to-project-assignment-command';
import { UpdateEmployeeToProjectAssignmentCommand } from 'app/modules/equipment/contract/update-employee-to-project-assignment-command';
import { DTZ } from 'app/shared/timezone/date-timezone';


@Injectable()
export class EmployeeDispositionService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/employees';
  private filterableLabelUrl = this.url + '/filterable-labels';

  constructor(private http: HttpClient) {
  }

  public getEmployeeDispositionSearch(params: Object): Observable<PagedResponse<SearchEmployeeDisposition>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchEmployeeDisposition>>(`${this.url}/search/all-by-team`, {params: httpParams});
  }

  public getCustomerLabels(organisationFilter?: string): Observable<CustomerLabel[]> {
    let httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
    };
    if (organisationFilter) {
      httpOptions.params = httpOptions.params.append('organisationIds', organisationFilter);
    }
    return this.http.get<CustomerLabel[]>(this.filterableLabelUrl, httpOptions);
  }

  public assignToProject(command: AddEmployeeToProjectAssignmentCommand): Observable<string> {
    const cmdConvertedDates: AddEmployeeToProjectAssignmentCommand = {
      ...command,
      assignmentStartDate: DTZ.DateTimezone({ display: command.assignmentStartDate }).storeDate.toISOString(),
      assignmentEndDate: command.assignmentEndDate != null
        ? DTZ.DateTimezone({ display: command.assignmentEndDate }).storeDate.toISOString()
        : null,
    };
    return this.http.post(`${this.url}/request-project-assignment`, JSON.stringify(cmdConvertedDates), {responseType: 'text'})
  }

  public updateProjectAssignment(command: UpdateEmployeeToProjectAssignmentCommand): Observable<string> {
    const cmdConvertedDates: UpdateEmployeeToProjectAssignmentCommand = {
      ...command,
      newAssignmentStartDate: DTZ.DateTimezone({ display: command.newAssignmentStartDate }).storeDate.toISOString(),
      newAssignmentEndDate: command.newAssignmentEndDate != null
        ? DTZ.DateTimezone({ display: command.newAssignmentEndDate }).storeDate.toISOString()
        : null,
    };
    return this.http.post(`${this.url}/update-project-assignment`, JSON.stringify(cmdConvertedDates), {responseType: 'text'});
  }

  public removeProjectAssignment(command: RemoveEmployeeToProjectAssignmentCommand): Observable<string> {
    return this.http.post(`${this.url}/remove-project-assignment`, JSON.stringify(command), {responseType: 'text'});
  }

  public validateAssignment(employeeId: string, params: ValidateEmployeeAssignmentParams): Observable<ViewEmployeeProjectAssignment[]> {
    let options = new HttpParams({encoder: new CustomEncoder()});
    if (params.id) {
      options = options.set('id', params.id);
    }
    if (params.assignmentStart) {
      options = options.set('assignmentStart', params.assignmentStart.toString());
    }
    if (params.assignmentEnd) {
      options = options.set('assignmentEnd', params.assignmentEnd.toString());
    }
    if (params.assignmentId) {
      options = options.set('assignmentId', params.assignmentId.toString());
    }

    return this.http.get<ViewEmployeeProjectAssignment[] | undefined>(
        `${this.url}/${employeeId}/valid-assignment-timeframe`,
        {params: options})
    .pipe(map(res => res ? res : []));
  }
}
