import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TelematicUnitStore } from '../../shared/services/telematic-unit.store';

@Component({
  selector: 'bh-telematic-unit-list',
  templateUrl: './telematic-unit-list.component.html',
  styleUrls: ['./telematic-unit-list.component.scss']
})
@UntilDestroy()
export class TelematicUnitListComponent implements OnInit, OnDestroy {
  public readonly displayedColumns = [
    'telematicsUnitId',
    'telematicsUnitType',
    'assignedEquipmentInfo',
    'lastChangeDate',
    'trafficLight'
  ];
  public searchTermControl = new UntypedFormControl('');
  @ViewChild(MatSort, { static: true }) private sortLastChangeDate: MatSort;

  constructor(public telematicUnitStore: TelematicUnitStore) { }

  public ngOnInit(): void {
    this.onSearchTerm();
    this.setLastChangeDateSort();
    this.setSearchTerm();
  }

  public ngOnDestroy(): void {
  }

  public refresh(): void {
    this.telematicUnitStore.updateStore();
  }

  public resetSearch(): void {
    this.searchTermControl.setValue('');
  }

  public search(): void {
    this.telematicUnitStore.search(this.searchTermControl.value);
  }

  public sortChange({ active, direction }: Sort): void {
    if (active === 'lastChangeDate') {
      this.telematicUnitStore.sortByLastChangeDate(direction);
    }
  }

  private setSearchTerm(): void {
    this.searchTermControl.setValue(this.telematicUnitStore.searchTerm, { emitEvent: false });
  }

  private setLastChangeDateSort(): void {
    this.sortLastChangeDate.sort(<MatSortable>{
      id: 'lastChangeDate',
      start: this.telematicUnitStore.sortDirectionLastChangeDate,
    });
  }

  private onSearchTerm(): void {
    this.searchTermControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(searchTerm => this.telematicUnitStore.search(searchTerm));
  }

}
