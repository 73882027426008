import { KeycloakService } from '../../core/keycloak';
import { Authorities } from '../enums/authorities.enum';
import { UserRole } from '../../modules/userrole/shared/interfaces/user-role.interface';
import { PrivilegedRole } from '../enums/privileged-roles.enum';
import { Modules } from '../enums/modules.enum';

export abstract class RoleAuthorityGuardsComponent {

  priviledgedRole = PrivilegedRole;
  authorities = Authorities;
  modules = Modules;

  protected constructor(protected authService: KeycloakService) {
  }

  public isInRole(role) {
    return this.authService.isInRole(role)
  }

  public isSuperOrPartnerAdmin(): boolean {
    return this.authService.isSuperOrPartnerAdmin();
  }

  public hasAnyRole(...rolesToCheck) {
    return this.authService.isInRoles(rolesToCheck)
  }

  public hasAuthority(authority: Authorities): boolean {
    return this.authService.hasAuthority(authority);
  }

  public hasAnyAuthority(authoritiesToCheck: Authorities[]) {
    return this.authService.hasAnyAuthority(authoritiesToCheck)
  }

  public hasModule(module: Modules) {
    return this.authService.hasModule(module);
  }

  public hasAnyModule(modules: Modules[]) {
    let found = false;
    modules.forEach(module => {
      if (this.authService.hasModule(module)) {
        found = true;
      }
    });
    return found;
  }

  public getUserCustomerId() {
    return this.authService.getUserCustomerId();
  }

  public getUserPartnerId() {
    return this.authService.getUserPartnerId();
  }

  public getUserUserId() {
    return this.authService.getUserUserId();
  }

  public isUserPriviledgedAdmin(roles: UserRole[]): boolean {
    let isPrivilegedRole = false;
    roles.forEach(role => {
      if (this.isInPrivilegedRole(role.name)) {
        isPrivilegedRole = true;
      }
    });
    return isPrivilegedRole;
  }

  public isInPrivilegedRole(roleName: string) {
    return roleName in PrivilegedRole;
  }

  public isFleetAdmin() {
    let isFleetAdmin = false;
    this.authService.getRoles().forEach(roleName => {
      if (this.isInPrivilegedRole(roleName)) {
        isFleetAdmin = true;
      }
    });
    return isFleetAdmin;
  }

  public isSuperAdmin() {
    return this.isInRole(PrivilegedRole.Superadmin)
  }

  public isPartnerAdmin() {
    return this.isInRole(PrivilegedRole.Partneradmin)
  }

  public hasCustomerId() {
    return typeof this.getUserCustomerId() !== 'undefined';
  }

  public isAllowedToChangeOrganisation(organisationId: string): boolean {
    return this.isFleetAdmin() || this.authService.isUserAssignedToOrganisation(organisationId);
  }

  public hasFromStockOrFromProjectTransferPermission() {
    return this.hasAnyAuthority([this.authorities.ASSET_FROM_STOCK_TRANSFER, this.authorities.ASSET_FROM_PROJECT_TRANSFER])
  }

  public canCreateNewEntity(): boolean {
    return this.hasAnyAuthority([
      this.authorities.BULK_ITEMS_MANAGE,
      this.authorities.CONTACT_MANAGE,
      this.authorities.EMPLOYEE_MANAGE,
      this.authorities.EQUIPMENT_CREATE,
      this.authorities.PROJECT_CREATE,
      this.authorities.STOCK_MANAGE,
      this.authorities.TRANSPORT_TASK_MANAGE,
      this.authorities.TRANSPORT_TASK_DISPOSITION
    ]);
  }
}
