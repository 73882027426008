import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContactView } from 'app/modules/contact/contract/contact/contact-view.interface';
import { AddressService } from 'app/shared/services/address.service';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from 'app/core/keycloak';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'bh-contact-view-general',
  templateUrl: './contact-view-general.component.html',
  styleUrls: ['contact-view-general.component.scss']
})
export class ContactViewGeneralComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  public contact: ContactView;
  public customerLabels: Observable<string[]>;
  public contactLabels: Observable<string[]>;

  constructor(
    protected authService: KeycloakService,
    private contactDataSource: ContactDataSource,
    private addressService: AddressService
  ) {
      super(authService);
  }

  public ngOnInit(): void {
    this.getCustomerLabels();
    this.customerLabels = this.contactDataSource.filteredCustomerLabels;
    this.initListeners();
  }

  public ngOnDestroy(): void {
  }

  public addLabel(label: string): void {
    let command = {contactId: this.contact.contactId, label: label};
    this.contactDataSource.addContactLabel(command);
  }

  public removeLabel(label: string): void {
    let command = {contactId: this.contact.contactId, label: label};
    this.contactDataSource.removeContactLabel(command);
  }

  private getCustomerLabels() {
    this.contactDataSource.getCustomerLabels();
  }

  private initListeners(): void {
    this.currentContactListener();
  }

  private currentContactListener(): void {
    this.contactDataSource.currentContact
    .pipe(untilDestroyed(this))
    .subscribe(currentContact => {
      this.contact = currentContact;
      this.contactLabels = this.contactDataSource.contactLabels;
    });
  }

}
