import { Pipe, PipeTransform } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { Modules } from 'app/shared/enums/modules.enum';
import { MaintenanceTaskAdapter } from './maintenance-task-adapter.class';

@Pipe({
  name: 'isAllowDeleteCompletedTask'
})
export class IsAllowDeleteCompletedTaskPipe implements PipeTransform {
  constructor(private authService: KeycloakService) {}

  public transform(task: MaintenanceTaskAdapter): boolean {
    return Boolean(task)
      && (task.category === MaintenanceCategory.CHECK
        || task.category === MaintenanceCategory.CHECK_AND_REPAIR
        || task.category === MaintenanceCategory.REMINDER)
      && (this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE) && this.authService.hasModule(Modules.SERVICE));
  }

}
