import { Component, Input } from '@angular/core';
import { MaintenanceResult } from '../../enums/maintenance-result';

@Component({
  selector: 'bh-maintenance-result-badge',
  templateUrl: './maintenance-result-badge.component.html',
  styleUrls: ['./maintenance-result-badge.component.scss']
})
export class MaintenanceResultBadgeComponent {
  @Input() result: MaintenanceResult = null;
  @Input() hideText = false;
}
