import { LanguageService } from 'app/shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../../shared/router-history';
import { takeWhile } from 'rxjs/operators';
import { ViewEmployee } from '../../../../../../contract/view-employee.interface';
import { EmployeeManagementDatasource } from '../../../../../../shared/employee-management.datasource';
import { ViewEmployeeNote } from '../../../../../../contract/view-employee-note.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Authorities } from '../../../../../../../../shared/enums/authorities.enum';
import { ConfirmationDialogComponent } from '../../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../../../../../shared/enums/dialogResults.enum';
import { DeleteEmployeeNoteCommand } from '../../../../../../contract/delete-employee-note.command';
import { CreateEmployeeNoteCommand } from '../../../../../../contract/create-employee-note-command';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateEmployeeNoteCommand } from '../../../../../../contract/update-employee-note.command';
import { FieldLimit } from '../../../../../../../../shared/enums/fieldLimit.enum';
import { DatePipe } from '@angular/common';

@UntilDestroy()
@Directive()
export abstract class BaseEmployeeViewNotesComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public employee: ViewEmployee;
  public viewNotesAllowed = false;
  public manageNotesAllowed = false;
  public addMode = false;
  protected componentActive = true;
  public loading = false;

  public readonly fieldLimit = FieldLimit;

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected routerHistory: RouterHistory,
                        protected dialog: MatDialog,
                        protected employeeManagementStore: EmployeeManagementDatasource,
                        protected datePipe: DatePipe,
                        protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.viewNotesAllowed = this.authService.hasAuthority(Authorities.EMPLOYEE_VIEW_NOTES);
    this.manageNotesAllowed = this.authService.hasAuthority(Authorities.EMPLOYEE_MANAGE_NOTES);
    this.subscribeToCurrentEmployee();
    this.subscribeToNotes();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  public addNote(): void {
    this.addMode = true;
  }

  public hideAddNoteEditor(): void {
    this.addMode = false;
  }

  public saveNote(text: string): void {
    this.hideAddNoteEditor();

    const cmd: CreateEmployeeNoteCommand = new CreateEmployeeNoteCommand(this.employee.employeeId, text);
    this.loading = true;
    this.employeeManagementStore.addNote(cmd).pipe(untilDestroyed(this)).subscribe();
  }

  public updateNote(note: ViewEmployeeNote): void {
    const cmd: UpdateEmployeeNoteCommand = new UpdateEmployeeNoteCommand(this.employee.employeeId, note.noteId, note.text);
    this.employeeManagementStore.updateNote(cmd).pipe(untilDestroyed(this)).subscribe();
  }

  public deleteNote(note: ViewEmployeeNote): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.noteDelete');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.noteDelete');
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        const cmd: DeleteEmployeeNoteCommand = new DeleteEmployeeNoteCommand(this.employee.employeeId, note.noteId);
        this.loading = true;
        this.employeeManagementStore.deleteNote(cmd)
          .pipe(takeWhile(() => this.componentActive))
          .subscribe();
      }
    });
  }

  public getNoteInfo(note: ViewEmployeeNote): string {
    const noteDate = this.datePipe.transform(note.updateDate == null ?
       note.createDate : note.updateDate, 'dd.MM.yyyy HH:mm', '', this.getCurrentLocale())
    return [noteDate, '-', note.userFirstName, note.userName].join(' ');
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((res: ViewEmployee) => {
        if (res) {
          this.hideAddNoteEditor();
          this.employee = res;
          this.getNotes();
        }
      });
  }

  private subscribeToNotes(): void {
    this.employeeManagementStore.notes
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((res: ViewEmployeeNote[]) => {
        if (res) {
          this.loading = false;
        }
      });
  }

  private getNotes(): void {
    if (!!this.employee.employeeId && this.viewNotesAllowed) {
      this.employeeManagementStore.getNotes(this.employee.employeeId);
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }
}
