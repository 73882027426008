<mat-card class="view-select-card">
  <mat-card-content fxLayout="row">
    <button mat-button *ngFor="let option of options"
            matTooltip="{{ resolveOptionName(option) }}"
            [ngClass]="{'view-active': isSelected(option), 'change-list-view-button': true}"
            [matTooltipPosition]="'below'"
            fxLayoutAlign="center center"
            (click)="selectOption(option)">
      <fa-icon [icon]="resolveOptionIcon(option)"></fa-icon>
    </button>
    <span class="selected-asset-name">
      {{resolveOptionName(this.options[this.selectedOptionIndex])}}
    </span>
  </mat-card-content>
</mat-card>
