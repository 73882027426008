import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PagedResponse } from '../../../../../shared/contract/page-response.interface';
import { TransferHistoryEntry } from '../../../projects/project-list/project-view/project-view-transfer-history/transfer-history-entry';
import { ViewTransferRequest } from '../../../projects/project-list/project-view/project-view-transfer-history/view-transfer-request';
import { RevertTransferCartCommand } from '../../../contract/revert-transfer-cart-command';
import { AcknowledgeTransferRequestCommand } from '../../../contract/acknowledge-transfer-request-command';

@UntilDestroy()
@Injectable()
export class StockViewTransfersService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/';
  private stockTransferHistoryUrl = `${this.url}transfer-history/stocks/`;
  private stockTransferRequestUrl = `${this.url}transfer-request/stock/`;
  private transferRequestUrl = `${this.url}transfer-request/`;

  constructor(private http: HttpClient) { }

  getStockTransfers(stockId: string, page: number, pageSize: number): Observable<PagedResponse<TransferHistoryEntry>> {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('size', pageSize.toString());
    return this.http.get<PagedResponse<TransferHistoryEntry>>(`${this.stockTransferHistoryUrl}${stockId}`,
      {params: params});
  }

  public getLatestTransfer(stockId): Observable<Date> {
    return this.http.get<Date>(`${this.url}/stocks/${stockId}/latest-transfer-date`);
  }

  public getPendingTransfers(stockId): Observable<ViewTransferRequest[]> {
    const params = new HttpParams()
    .set('stockId', stockId);
    return this.http.get<ViewTransferRequest[]>(`${this.stockTransferRequestUrl}pending`, {params: params});
  }

  public getArchivedTransfers(stockId): Observable<ViewTransferRequest[]> {
    const params = new HttpParams()
    .set('stockId', stockId);
    return this.http.get<ViewTransferRequest[]>(`${this.stockTransferRequestUrl}archived`, {params: params});
  }

  public declineTransferRequest(command: RevertTransferCartCommand): Observable<string> {
    return this.http.post(this.transferRequestUrl + 'decline', command, {responseType: 'text'});
  }
  public acknowledgeTransferRequest(command: AcknowledgeTransferRequestCommand): Observable<string> {
    return this.http.post(this.transferRequestUrl + 'acknowledge', command, {responseType: 'text'});
  }
}
