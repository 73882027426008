<div *ngIf="isProjectNumberDisplay" class="wrapper" fxLayoutAlign="start center">
  <span [bhOverflowTooltip]="numberTooltip" class="number mr-5">
    {{number ? '# ' + number : noNumberPlaceholder}}
  </span>
</div>
<div *ngIf="isCostCenterDisplay" class="wrapper" fxLayoutAlign="start center">
  <fa-icon class="cost-center-icon mr-5" [icon]="faWallet"></fa-icon>
  <span [bhOverflowTooltip]="costCenterTooltip" class="cost-center">
    {{costCenter ? costCenter : noCostCenterPlaceholder}}
  </span>
</div>
