import { environment } from 'environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { faStar as faLightStar, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faPencil, faMinusCircle, faIdCard, faSignOut, faCheck, faStar as faSolidStar, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { KeycloakService } from 'app/core/keycloak';
import { ViewEmployee } from 'app/modules/disposition/contract/view-employee.interface';
import { CreateTeamCommand } from 'app/modules/disposition/contract/create-team-command';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { AddEmployeeToTeamCommand } from 'app/modules/disposition/contract/add-employee-to-team-command';
import { RemoveEmployeeFromTeamCommand } from 'app/modules/disposition/contract/remove-employee-from-team-command';
import { SetTeamNameCommand } from 'app/modules/disposition/contract/set-team-name-command';
import { debounceTime, delay, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { ViewTeam } from 'app/modules/disposition/contract/view-team.interface';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';
import { Observable } from 'rxjs';
import { TeamMember } from '../../../modules/disposition/contract/team-member.interface';
import { SetTeamLeaderCommand } from '../../../modules/disposition/contract/set-team-leader-command';
import { UnsetTeamLeaderCommand } from '../../../modules/disposition/contract/unset-team-leader-command';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EmployeeStatus } from '../../../modules/disposition/contract/employee-status.enum';

@UntilDestroy()
@Component({
  selector: 'bh-team-component',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {

  public readonly faPencil: IconDefinition = faPencil;
  public readonly faPlus: IconDefinition = faPlus;
  public readonly faMinusCircle: IconDefinition = faMinusCircle;
  public readonly faIdCard: IconDefinition = faIdCard;
  public readonly faSignOut: IconDefinition = faSignOut;
  public readonly faCheck: IconDefinition = faCheck;
  public readonly faLightStar: IconDefinition = faLightStar;
  public readonly faSolidStar: IconDefinition = faSolidStar;

  public teamForm: UntypedFormGroup;

  public currentTeam: ViewTeam;
  public currentEmployee: ViewEmployee;
  public existingTeamNames: string[] = [];
  public filteredFreeEmployees: Observable<ViewEmployee[]> = this.employeeManagementStore.filteredFreeEmployees;

  public addEditMode = false;
  public teamNameEditMode = false;

  public loading = false;
  protected componentActive = true;


  constructor(protected authService: KeycloakService,
              private formBuilder: UntypedFormBuilder,
              private employeeManagementStore: EmployeeManagementDatasource) { }

  public ngOnInit(): void {
    this.buildForm();

    this.subscribeToCurrentEmployee();
    this.subscribeToCurrentTeam();
    this.subscribeToTeamNames();

    this.getAllEmployees();
    this.getTeamNames();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private buildForm(): void {
    this.teamForm = this.formBuilder.group({
      teamName: new UntypedFormControl('', [
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.required,
        this.existingNameValidator.bind(this)]),
      newTeamMember: new UntypedFormControl('')
    });
    this.newTeamMemberControl.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.employeeManagementStore.filterFreeEmployees(filterTerm));
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployee) => {
      if (res) {
        this.currentEmployee = res;
        this.addEditMode = false;
        this.teamNameEditMode = false;
      }
    });
  }

  private subscribeToCurrentTeam(): void {
    this.employeeManagementStore.currentTeam
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewTeam) => {
      if (res) {
        this.currentTeam = res;
        this.teamNameControl.patchValue(res.teamName);
      }
    });
  }

  private subscribeToTeamNames(): void {
    this.employeeManagementStore.teamNames
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: string[]) => {
      if (res) {
        this.existingTeamNames = res;
      }
    });
  }

  private getTeam(teamId: string): void {
    this.employeeManagementStore.getTeam(teamId);
  }

  private getAllEmployees(): void {
    this.employeeManagementStore.getAllEmployees();
  }

  private getTeamNames(): void {
    this.employeeManagementStore.getTeamNames();
  }

  public addTeamMember(employeeId: string): void {
    if (!this.teamNameValid) {
      return;
    }
    this.newTeamMemberControl.reset();
    if (this.currentTeamExists) {
      this.addEmployeeToExistingTeam(employeeId);
    } else {
      this.createNewTeam(employeeId);
    }
  }

  public addEmployeeToExistingTeam(employeeId: string): void {
    const command = new AddEmployeeToTeamCommand(this.currentTeam.teamId, employeeId);
    this.loading = true;
    this.employeeManagementStore.addEmployeeToTeam(this.currentTeam.teamId, command)
      .pipe(delay(environment.DELAY_LONG))
      .subscribe((res) => {
        this.getTeam(res);
        this.getAllEmployees();
        this.loading = false;
      });
  }

  public createNewTeam(employeeId: string): void {
    const command = new CreateTeamCommand([this.currentEmployee.employeeId, employeeId], this.teamNameControl.value.trim());
    this.loading = true;
    this.employeeManagementStore.createTeam(command).pipe(delay(environment.DELAY_LONG)).subscribe(res => {
      this.getTeam(res);
      this.getAllEmployees();
      this.getTeamNames();
      this.teamNameEditMode = false;
      this.loading = false;
    });
  }

  public removeEmployeeFromTeam(employeeId: string): void {
    if (this.currentTeamExists && !this.loading) {
      this.loading = true;
      const command = new RemoveEmployeeFromTeamCommand(this.currentTeam.teamId, employeeId);
      this.employeeManagementStore.removeEmployeeFromTeam(this.currentTeam.teamId, command)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe((res) => {
          if (this.currentTeam.teamMembers.length === 2 || this.currentEmployee.employeeId === employeeId) {
            this.addEditMode = false;
            this.teamNameEditMode = false;
            this.currentEmployee.isTeamLeader = false;
            this.getTeam(null);
          } else {
            this.getTeam(res);
          }
          this.getAllEmployees();
          this.getTeamNames();
          this.loading = false;
        });
    }
  }

  public updateTeamName(): void {
    if (this.currentTeamExists && this.teamNameChanged) {
      this.loading = true;
      const teamName = this.teamNameControl.value;
      const nameCommand = new SetTeamNameCommand(this.currentTeam.teamId, teamName);
      this.employeeManagementStore.setTeamName(this.currentTeam.teamId, nameCommand)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe((res) => {
          this.getTeam(res);
          this.getTeamNames();
          this.loading = false;
          this.teamNameEditMode = false;
        });
    }
  }

  public setTeamLeader(employeeId): void {
    if (this.currentTeamExists && !this.loading) {
      this.loading = true;
      const leaderCommand = new SetTeamLeaderCommand(this.currentTeam.teamId, employeeId);
      this.employeeManagementStore.setTeamLeader(this.currentTeam.teamId, leaderCommand)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe((res) => {
          this.getTeam(res);
          this.currentEmployee.isTeamLeader = employeeId === this.currentEmployee.employeeId;
          this.loading = false;
        });
    }
  }

  public unsetTeamLeader(employeeId): void {
    if (this.currentTeamExists && !this.loading) {
      this.loading = true;
      const leaderCommand = new UnsetTeamLeaderCommand(this.currentTeam.teamId, employeeId);
      this.employeeManagementStore.unsetTeamLeader(this.currentTeam.teamId, leaderCommand)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe((res) => {
          this.getTeam(res);
          if (employeeId === this.currentEmployee.employeeId) {
            this.currentEmployee.isTeamLeader = false;
          }
          this.loading = false;
        });
    }
  }

  public enterAddEditMode(): void {
    this.addEditMode = true;
    if (!this.currentTeamExists) {
      const initialTeamName =
          'Team ' +
          (this.currentEmployee.employeeFirstName ? this.currentEmployee.employeeFirstName + ' ' : '') +
          this.currentEmployee.employeeName;
      this.teamNameControl.patchValue(initialTeamName);
      this.enterEditTeamNameMode();
    } else {
      this.teamNameControl.patchValue(this.currentTeam.teamName);
    }
  }

  public leaveAddEditMode(): void {
    this.addEditMode = false;
  }

  public enterEditTeamNameMode(): void {
    this.teamNameEditMode = true;
  }

  private existingNameValidator(control: AbstractControl) {
    const nameValue = control.value.trim();
    const nameBlank = nameValue === '';
    const nameExists = this.existingTeamNames.filter(name => name === nameValue).length > 0;
    const nameUnequalsCurrentName = (this.currentTeam && this.currentTeam.teamName !== nameValue) || !this.currentTeamExists;
    if (nameBlank || (nameExists && nameUnequalsCurrentName)) {
      return { invalidName: true };
    }
    return null;
  }

  public getEmployeeName(employee: ViewEmployee): string {
    return employee.employeeName + (employee.employeeFirstName ? ', ' + employee.employeeFirstName : '');
  }

  public getTeamMemberName(teamMember: TeamMember): string {
    return teamMember.employeeName + (teamMember.employeeFirstName ? ', ' + teamMember.employeeFirstName : '');
  }

  get canManageTeam(): boolean {
    return this.authService.hasAuthority(Authorities.EMPLOYEE_MANAGE);
  }

  get allowAddToTeam(): boolean {
    return this.currentEmployee && this.currentEmployee.employeeStatus === EmployeeStatus.ACTIVE;
  }

  get currentTeamExists(): boolean {
    return this.currentTeam && !!this.currentTeam.teamId;
  }

  get teamNameValid(): boolean {
    if (this.currentTeamExists) {
      return this.teamForm.get('teamName').valid &&
          this.teamForm.get('teamName').value &&
          this.teamForm.get('teamName').value.trim() &&
          (!this.teamNameEditMode || (this.teamNameEditMode && this.teamForm.get('teamName').value.trim() ===
           this.currentTeam.teamName.trim()));
    } else {
      return this.teamForm.get('teamName').valid &&
          this.teamForm.get('teamName').value &&
          this.teamForm.get('teamName').value.trim();
    }
  }

  get teamNameChanged(): boolean {
    if (this.currentTeamExists) {
      return this.teamForm.get('teamName').valid &&
          this.teamForm.get('teamName').value &&
          this.teamForm.get('teamName').value.trim() &&
          this.teamForm.get('teamName').value.trim() !== this.currentTeam.teamName.trim();
    } else {
      return this.teamForm.get('teamName').valid &&
          this.teamForm.get('teamName').value &&
          this.teamForm.get('teamName').value.trim();
    }
  }

  get teamNameControl(): AbstractControl {
    return this.teamForm.get('teamName');
  }

  get newTeamMemberControl(): AbstractControl {
    return this.teamForm.get('newTeamMember');
  }

}
