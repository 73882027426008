<bh-title [text]="'modules.equipment.equipmentDetailUsage.usageHistory'|translate"></bh-title>

<div *ngIf="usages.length" class="usage-search-bar">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>search</mat-icon>
    <mat-form-field flex="90%">
      <mat-label>{{'general.search'|translate}}</mat-label>
      <input matInput (input)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>
</div>

<div class="table-container">
  <table *ngIf="usages.length"
         mat-table
         [dataSource]="dataSource">
    <ng-container matColumnDef="projectName">
      <th mat-header-cell *matHeaderCellDef> {{'general.projectName'|translate}} </th>
      <td mat-cell *matCellDef="let usage" fxLayoutGap="5px">
        <span class="text-ellipsis" [bhOverflowTooltip]="usage.projectName">{{usage.projectName}}</span>
        <mat-icon *ngIf="usage.isAllowedToSeeLocation"
                  class="small-icon"
                  [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.project.s'|translate}"
                  matSuffix
                  [routerLink]="['/sites/projects/list', usage.projectId, 'general']">
          open_in_new
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="projectCostCenter">
      <th mat-header-cell *matHeaderCellDef> {{'general.costCenter'|translate}} </th>
      <td mat-cell *matCellDef="let usage">
        <span class="text-ellipsis" [bhOverflowTooltip]="usage.projectCostCenter">{{usage.projectCostCenter}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> {{'general.address.address'|translate}} </th>
      <td mat-cell *matCellDef="let usage">
        <span class="text-ellipsis" [bhOverflowTooltip]="getAddress(usage.projectAddress)">
          {{getAddress(usage.projectAddress)}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell class="mw-160" *matHeaderCellDef> {{'general.startDate'|translate}} </th>
      <td mat-cell class="mw-160" *matCellDef="let usage"> {{usage.startDate | formatTimezoneDateTime }} </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell class="mw-160" *matHeaderCellDef> {{'general.endDate'|translate}} </th>
      <td mat-cell class="mw-160" *matCellDef="let usage"> {{usage.endDate | formatTimezoneDateTime }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>

<div *ngIf="!usages.length" fxLayout="column" fxLayoutAlign="center center">
  <p> {{'modules.equipment.equipmentDetailUsage.noHistory'|translate}} </p>
</div>
