import { TransportListSearchComponent } from './transport/transportation-board/transport-list-search/transport-list-search.component';
import { TransportService } from './shared/services/transport.service';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomMaterialModule } from 'app/core/custom-material/custom-material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { transportationRouting } from './transportation.routing';
import { TransportationBoardComponent } from './transport/transportation-board/transportation-board.component';
import { TransportListItemComponent } from './transport/transportation-board/transport-list-search/transport-list-item/transport-list-item.component';
import { TransportItemTypeResolverPipe } from './shared/pipes/transport-item-type-resolver.pipe';
import { TransportPriorityPipe } from './shared/pipes/transport-priority.pipe';
import { TransportStatePipe } from './shared/pipes/transport-state.pipe';
import { TransportationTasksAddEditComponent } from './transport/transportation-tasks-add-edit/transportation-tasks-add-edit.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgPipesModule } from 'ngx-pipes';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { TransportLocationMapSelectionComponent } from './transport/transport-location-map-selection/transport-location-map-selection.component';
import { TransportationBoardTimelineComponent } from './transport/transportation-board/transportation-board-timeline/transportation-board-timeline.component';
import { TransportationTimelineSchedulerService } from './shared/services/transportation-timeline-scheduler.service';
import { VehicleDataSource } from './shared/services/vehicle.datasource';
import { TransportTimelineSchedulerHtmlBuilderService } from './shared/contract/transportation-timeline-scheduler/transport-timeline-scheduler-html-builder.class';
import { VehicleFiltersService } from './shared/services/vehicle-filters.service';
import { TransportDetailViewService } from './shared/services/transport-detail-view.service';
import { TransportDetailViewDialogComponent } from './transport/transport-detail-view-dialog/transport-detail-view-dialog.component';
import { TransportExecutionDatesEditComponent } from './transport/transport-execution-dates-edit/transport-execution-dates-edit.component';
import { TransportStateBadgeComponent } from './transport/transport-badges/transport-state-badge/transport-state-badge.component';
import { TransportPriorityBadgeComponent } from './transport/transport-badges/transport-priority-badge/transport-priority-badge.component';
import { TransportContextItemPipe } from './shared/pipes/transport-context/transport-context-item.pipe';
import { TransportContextProjectPipe } from './shared/pipes/transport-context/transport-context-project.pipe';
import { TransportViewAssignmentTitlePipe } from './shared/pipes/transport-view-assignment-title.pipe';
import { TransportViewComponent } from './transport/transport-list/transport-view/transport-view.component';
import { TransportListComponent } from './transport/transport-list/transport-list.component';
import { TransportBoardDataSource } from './shared/services/transport-board.datasource';
import { TransportListDatasource } from './shared/services/transport-list.datasource';
import { TransportPageRerouteGuard } from './shared/guards/transport-page-reroute.guard';
import { TransportStateChangeComponent } from './transport/transport-state-change/transport-state-change.component';
import { TransportViewDetailComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-detail.component';
import { TransportViewGeneralComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-general/transport-view-general.component';
import { FormatTitleFromTransportItemPipe } from './shared/pipes/format-title-from-transport-item.pipe';
import { FormatAddressFromTransportPipe } from './shared/pipes/format-address-from-transport.pipe';
import { FormatTitleFromRelatedProjectPipe } from './shared/pipes/format-title-from-related-project.pipe';
import { TransportDateTimeInputDialogComponent } from './transport/transport-date-input-dialog/transport-date-time-input-dialog.component';
import { TransportFilterService } from './shared/services/transport-filter.service';
import { TransportViewHistoryComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-history/transport-view-history.component';
import { TransportFieldsResolverPipe } from './shared/pipes/transport-fields-resolver.pipe';
import { TransportHistoryTableItemComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-history/transport-history-table-item/transport-history-table-item.component';
import { TransportListExportDialogComponent } from './transport/transport-list/transport-list-export-dialog/transport-list-export-dialog.component';
import { TransportViewAttachmentComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-attachment/transport-view-attachment.component';
import { TransportPlannedDurationPipe } from './transport/transport-detail-view-dialog/transport-planned-duration.pipe';
import { TransportEstimatedEndPipe } from './transport/transport-detail-view-dialog/transport-estimated-end.pipe';
import { IsTransportDueDatePassedPipe } from './shared/pipes/is-transport-due-date-passed.pipe';
import { TransportViewCommentsComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-comments/transport-view-comments.component';
import { TransportCommentComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-comments/transport-comment/transport-comment.component';
import { AddEditCommentDialogComponent } from './transport/transport-list/transport-view/transport-view-detail/transport-view-comments/transport-comment/add-edit-comment-dialog/add-edit-comment-dialog.component';
import { TransportAssignmentsLazyLoader } from './shared/services/lazy-loader/transport-assignments-lazy-loader.service';
import { MapModule } from 'app/shared/modules/map/map.module';
import { BhTableModule } from '../osp-ui/components/bh-table/bh-table.module';

@NgModule({
  imports: [
    transportationRouting,
    CommonModule,
    SharedModule,
    CustomMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DragDropModule,
    MainPipeModule,
    MatDialogModule,
    NgPipesModule,
    InfiniteScrollModule,
    ScrollingModule,
    MatBadgeModule,
    MapModule,
    BhTableModule,
  ],
  declarations: [
    TransportationBoardComponent,
    TransportListSearchComponent,
    TransportListItemComponent,
    TransportItemTypeResolverPipe,
    TransportPriorityPipe,
    TransportStatePipe,
    FormatTitleFromTransportItemPipe,
    FormatAddressFromTransportPipe,
    FormatTitleFromRelatedProjectPipe,
    TransportationTasksAddEditComponent,
    TransportLocationMapSelectionComponent,
    TransportationBoardTimelineComponent,
    TransportDetailViewDialogComponent,
    TransportExecutionDatesEditComponent,
    TransportStateBadgeComponent,
    TransportPriorityBadgeComponent,
    TransportContextItemPipe,
    TransportContextProjectPipe,
    TransportViewAssignmentTitlePipe,
    TransportListComponent,
    TransportStateChangeComponent,
    TransportViewComponent,
    TransportViewDetailComponent,
    TransportViewGeneralComponent,
    TransportDateTimeInputDialogComponent,
    TransportViewHistoryComponent,
    TransportFieldsResolverPipe,
    TransportHistoryTableItemComponent,
    TransportListExportDialogComponent,
    TransportViewAttachmentComponent,
    TransportPlannedDurationPipe,
    TransportEstimatedEndPipe,
    IsTransportDueDatePassedPipe,
    TransportViewCommentsComponent,
    TransportCommentComponent,
    AddEditCommentDialogComponent,
  ],
  providers: [
    TransportService,
    TransportListDatasource,
    TransportBoardDataSource,
    TransportItemTypeResolverPipe,
    VehicleDataSource,
    TransportationTimelineSchedulerService,
    VehicleDataSource,
    TransportStatePipe,
    FormatTitleFromTransportItemPipe,
    FormatAddressFromTransportPipe,
    FormatTitleFromRelatedProjectPipe,
    TransportPriorityPipe,
    TransportTimelineSchedulerHtmlBuilderService,
    VehicleFiltersService,
    TransportDetailViewService,
    TransportPageRerouteGuard,
    TransportFilterService,
    TransportAssignmentsLazyLoader
  ]
})
export class TransportationModule { }
