import { Component, Input } from '@angular/core';
import { ContactStatus } from '../../shared/enums/contact-status.enum';

@Component({
  selector: 'bh-contact-status-indicator',
  templateUrl: './contact-status-indicator.component.html',
  styleUrls: ['./contact-status-indicator.component.scss']
})
export class ContactStatusIndicatorComponent {
  @Input() status: ContactStatus;
  @Input() showTooltip = true;
}
