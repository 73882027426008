import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';



@Pipe({
  name: 'responsibleSort'
})

export class ResponsibleSortPipe implements PipeTransform {

  constructor() {}

  transform(value: any, args: any): any {
    let updatedList = _.orderBy(value, ['assigneeType', 'employeeName', 'employeeFirstName'], ['asc', 'asc', 'asc']);
    return updatedList;
  }

}
