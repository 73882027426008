import { Directive, Host, Input, OnChanges, Optional, SimpleChange, SimpleChanges } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { filter, merge, of } from 'rxjs';

interface MapExtendedPropsSimpleChanges extends SimpleChanges {
  panTo?: SimpleChange;
}

@Directive({
  selector: 'google-map[bhMapExtendedProps]'
})
export class MapExtendedPropsDirective implements OnChanges {

  @Input() panTo: google.maps.LatLng | google.maps.LatLngLiteral;

  private isLoaded = false;

  constructor(@Host() @Optional() private map: GoogleMap) {
    if (map) {
      merge(of(map.googleMap), map.mapInitialized)
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.isLoaded = true;
        this.applyAllProps();
      });
    }
  }

  public ngOnChanges(changes: MapExtendedPropsSimpleChanges): void {
    if(this.map && this.isLoaded) {
      if(changes?.panTo) {
        this.applyPanTo();
      }
    }
  }

  private applyAllProps(): void {
    this.applyPanTo();
  }

  private applyPanTo(): void {
    if (this.panTo) {
      this.map.panTo(this.panTo);
    }
  }

}
