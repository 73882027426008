<div class="assignments" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="dispositions-header" fxLayoutGap="10px">
    <bh-title [text]="'modules.disposition.projectDispositions.projectInventory'|translate" fxFlex class="title-header">
    </bh-title>
    <button mat-button *ngIf="hasAuthority(authorities.ASSET_FROM_PROJECT_TRANSFER)"
            [matMenuTriggerFor]="projectTransfer" id="edit-button" [ngClass]="{'edit-button': true}"
            [matTooltip]="'modules.disposition.projectDispositions.manageAssignments'|translate"
            [disabled]="transferDisabled()">
      <fa-icon [icon]="faExchange"></fa-icon>
    </button>

    <mat-menu #projectTransfer="matMenu">
      <div>
        <button mat-menu-item *ngIf="hasAuthority(authorities.ASSET_FROM_PROJECT_TRANSFER)"
                (click)="navigateToProjectToProjectTransfer()">
          <mat-icon><fa-icon [icon]="faMapMarkedAlt"></fa-icon></mat-icon>
          <span>{{'modules.disposition.projectDispositions.transferToProject'|translate}}</span>
        </button>
      </div>
      <div>
        <button mat-menu-item *ngIf="hasAuthority(authorities.ASSET_FROM_PROJECT_TRANSFER)"
                (click)="navigateToClearProject()">
          <mat-icon><fa-icon [icon]="faBroom"></fa-icon></mat-icon>
          <span>{{'modules.disposition.projectDispositions.clearProject'|translate}}</span>
        </button>
      </div>
      <div>
        <button mat-menu-item *ngIf="allowChangeTypeOfUse()"
                (click)="navigateToChangeChargePeriod()">
          <mat-icon><fa-icon [icon]="faPowerOff"></fa-icon></mat-icon>
          <span>{{'modules.disposition.projectDispositions.changeTypeOfUse'|translate}}</span>
        </button>
      </div>
    </mat-menu>
  </div>

  <div class="amount-search-bar">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>search</mat-icon>
      <mat-form-field flex="90%">
        <mat-label>{{'general.search'|translate}}</mat-label>
        <input matInput  tabindex="-1" [formControl]="searchFilter">
      </mat-form-field>
    </div>
  </div>

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                   *ngIf="hasModule(modules.BULK_ITEM_MANAGEMENT) && hasModule(modules.DISPOSITION); else noBulkItems">

      <mat-tab [label]="'general.equipm.pl'|translate">
        <div class="inventory" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
          <div class="inventory__list">
            <bh-equipment-table *ngIf="equipments.length > 0; else noAssignedItems"
                                [dataSource]="equipments">
            </bh-equipment-table>
          </div>
          <mat-paginator [pageIndex]="projectStore.getInventoryEquipmentsPageIndex() | async"
                         [pageSize]="projectStore.getInventoryEquipmentsPageSize() | async"
                         [length]="projectStore.getInventoryEquipmentsLength() | async"
                         [pageSizeOptions]="[5, 25, 50, 100]"
                         (page)="projectStore.inventoryEquipmentsPaginateEvent($event)">
          </mat-paginator>
        </div>
      </mat-tab>


      <mat-tab [label]="'general.bulkItems'|translate">
        <div class="inventory" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
          <div class="inventory__list">
            <bh-bulk-item-table *ngIf="bulkItems.length > 0; else noAssignedItems"
                                [dataSource]="bulkItems">
            </bh-bulk-item-table>
          </div>
          <mat-paginator [pageIndex]="projectStore.getInventoryBulkItemsPageIndex() | async"
                         [pageSize]="projectStore.getInventoryBulkItemsPageSize() | async"
                         [length]="projectStore.getInventoryBulkItemsLength() | async"
                         [pageSizeOptions]="[5, 25, 50, 100]"
                         (page)="projectStore.inventoryBulkItemsPaginateEvent($event)">
          </mat-paginator>
        </div>
      </mat-tab>

      <mat-tab *ngIf="(equipmentStore.requestedAssignments | async).length">
        <ng-template mat-tab-label>
          <span [matBadge]="(equipmentStore.requestedAssignments | async).length" style="font-weight: 500"
                matBadgeColor="warn"
                matBadgeOverlap="false"
                matBadgeDescription="{{'shared.assignment.pendingDispositions'|translate}}">
            {{'shared.assignment.pendingDispositions'|translate}}
          </span>
        </ng-template>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
            <bh-pending-assignment-acknowledgements [projectId]="project.projectId">
            </bh-pending-assignment-acknowledgements>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
    <ng-template #noAssignedItems>
      <div class="no-assignments">
        {{'modules.disposition.projectDispositions.noActiveAssignments'|translate}}
      </div>
    </ng-template>
  </div>

  <ng-template #noBulkItems>
    <ng-container *ngIf="hasModule(modules.DISPOSITION) else onlyBulkItems">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="no-bulk-items">
          <bh-equipment-table *ngIf="equipments.length > 0; else noAssignedItems"
                              [dataSource]="equipments">
          </bh-equipment-table>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #onlyBulkItems>
    <ng-container *ngIf="hasModule(modules.BULK_ITEM_MANAGEMENT) && hasAuthority(authorities.BULK_ITEMS_VIEW)">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
          <bh-bulk-item-table *ngIf="bulkItems.length > 0; else noAssignedItems"
                              [dataSource]="bulkItems">
          </bh-bulk-item-table>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
