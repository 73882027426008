<mat-form-field [floatLabel]="floatLabel">
  <mat-label>{{label}}</mat-label>
  <input matInput type="number" bhNumberField
         [formControl]="control"
         [placeholder]="placeholder"
         [readonly]="readonly"
         (blur)="onTouch()"
         (focus)="onTouch()">

  <span matSuffix>
    <ng-content ospSuffix></ng-content>
  </span>

  <mat-error>
    <bh-form-field-error [control]="control" [errorMessages]="errorMessages"></bh-form-field-error>
  </mat-error>
</mat-form-field>
