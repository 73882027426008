import { Component, Input, OnInit } from '@angular/core';
import { ViewProjectAmount } from '../../../../../contract/view-project-amount.interface';
import { DispositionBulkItem } from '../../../../../shared/disposition-bulk-item';

@Component({
  selector: 'bh-mobile-transfer-choose-amount-dialog',
  templateUrl: './mobile-transfer-choose-amount-dialog.component.html',
  styleUrls: ['./mobile-transfer-choose-amount-dialog.component.scss']
})
export class MobileTransferChooseAmountDialogComponent implements OnInit {

  @Input() amounts: ViewProjectAmount[] = [];
  @Input() bulkItem: DispositionBulkItem;

  public chosenAmount: ViewProjectAmount;

  constructor() { }

  ngOnInit() {
  }

  public chooseAmount(amount: ViewProjectAmount) {
    this.chosenAmount = amount;
  }

}
