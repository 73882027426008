import { EventEmitter } from '@angular/core';
import { TorchInfo } from './torch-info.interface';

export abstract class ScannerAdapter {
  abstract initializeScanner(): void;

  abstract destroy(): void;

  abstract toggleTorch(enable: boolean): void;

  abstract registerScanSuccessHandler(emitter: EventEmitter<string>): void;

  abstract registerScanErrorHandler(emitter: EventEmitter<string>): void;

  abstract registerScannerInitializeErrorHandler(emitter: EventEmitter<string>): void;

  abstract registerScannerInitializedHandler(emitter: EventEmitter<boolean>): void;

  abstract registerTorchHandler(emitter: EventEmitter<TorchInfo>): void;

  abstract get type(): string;

  abstract get viewportHook(): string;
}
