import { TranslateModule } from '@ngx-translate/core';
import {
  GeofenceSelectionSettingsComponent
} from './alarm-rule-add-edit-dialog/components/alarm-settings/geofence-selection-settings/geofence-selection-settings.component';
import {
  LimitPercentageSettingsComponent
} from './alarm-rule-add-edit-dialog/components/alarm-settings/limit-percentage-settings/limit-percentage-settings.component';
import {
  LimitSettingsComponent
} from './alarm-rule-add-edit-dialog/components/alarm-settings/limit-settings/limit-settings.component';
import { AlarmSettingsUtilsService } from './alarm-rule-add-edit-dialog/alarm-settings-utils.service';
import { AlarmTypeResolver } from './alarm-rule-add-edit-dialog/alarm-type.resolver';
import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { notificationsRouting } from './routing/notifications.routing';
import { mobileNotificationRouting } from './routing/mobile-notification.routing';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationDataSource } from './shared/services/notification.datasource';
import { NotificationService } from './shared/services/notification.service';
import { AlarmRuleAddEditDialogComponent } from './alarm-rule-add-edit-dialog/alarm-rule-add-edit-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AlarmLevelPipe } from './shared/pipes/alarm-level.pipe';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import { SharedModule } from '../../shared/shared.module';
import { MobileAlarmsComponent } from './mobile-alarms/mobile-alarms.component';
import { AlarmTypeService } from './shared/services/alarm-types.service';
import {
  AdditionalDataComponent
} from './alarm-rule-add-edit-dialog/components/additional-data/additional-data.component';
import { MapModule } from 'app/shared/modules/map/map.module';
import {
  TimefencingSettingsComponent
} from './alarm-rule-add-edit-dialog/components/alarm-settings/timefencing-settings/timefencing-settings.component';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import {
  InactiveTelematicUnitSettingsComponent
} from './alarm-rule-add-edit-dialog/components/alarm-settings/inactive-telematic-unit-settings/inactive-telematic-unit-settings.component';
import { OspUiPipesModule } from '../osp-ui/pipes/osp-ui-pipes.module';
import {
  TrackerBatteryStatusAlarmComponent
} from './alarm-rule-add-edit-dialog/components/alarm-settings/tracker-battery-percentage/tracker-battery-status.component';

@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  imports: [
    HammerModule,
    CommonModule,
    notificationsRouting,
    mobileNotificationRouting,
    FlexLayoutModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    MainPipeModule,
    MapModule,
    SharedModule,
    TranslateModule,
    OspUiPipesModule,
  ],
  declarations: [
    NotificationListComponent,
    AlarmRuleAddEditDialogComponent,
    AlarmLevelPipe,
    MobileAlarmsComponent,
    AlarmTypeResolver,
    LimitSettingsComponent,
    LimitPercentageSettingsComponent,
    GeofenceSelectionSettingsComponent,
    InactiveTelematicUnitSettingsComponent,
    AdditionalDataComponent,
    TimefencingSettingsComponent,
    TrackerBatteryStatusAlarmComponent
  ],
  providers: [
    NotificationDataSource,
    NotificationService,
    AlarmTypeService,
    AlarmTypeResolver,
    AlarmSettingsUtilsService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: AppHammerConfig }
  ]
})
export class NotificationModule {
}
