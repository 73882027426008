import { AttachedDocument } from '../../../shared/contract/attached-document.interface';

export class UploadEmployeeDocumentCommand {

  public employeeId: string;
  public document: AttachedDocument;

  constructor(employeeId: string, document: AttachedDocument) {
    this.employeeId = employeeId;
    this.document = document;
  }

}
