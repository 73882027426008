import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faWallet, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { LanguageService } from 'app/shared/services/language.service';

@Component({
  selector: 'bh-project-number-and-cost-center',
  templateUrl: './project-number-and-cost-center.component.html',
  styleUrls: ['./project-number-and-cost-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectNumberAndCostCenterComponent implements OnInit {
  @Input() number: string;
  @Input() costCenter: string;
  @Input() noNumberPlaceholder = '-';
  @Input() noCostCenterPlaceholder = '-';
  @Input() isProjectNumberDisplay = true;
  @Input() isCostCenterDisplay = true;

  public numberTooltip: string;
  public costCenterTooltip: string;

  public readonly faWallet: IconDefinition = faWallet;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.setTooltips();
  }

  private setTooltips(): void {
    this.number
      ? this.numberTooltip = `${this.languageService.getInstant('general.projectNumber')}: ${this.number}`
      : this.numberTooltip = '';

    this.costCenter
      ? this.costCenterTooltip = `${this.languageService.getInstant('general.costCenter')}: ${this.costCenter}`
      : this.costCenterTooltip = '';
  }
}

