import { Pipe, PipeTransform } from '@angular/core';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';
import { Utils } from 'app/shared/utils';

@Pipe({
  name: 'mapMovementArrowConvert'
})
export class MapMovementArrowConvertPipe implements PipeTransform {

  public transform(locations: LatLonLocation[]): google.maps.LatLng[][] {
    return locations
      ? this.toPaths(locations.filter(Utils.isValidLocation))
      : [];
  }

  private toPaths(locations: LatLonLocation[]): google.maps.LatLng[][] {
    if (locations?.length > 1) {
      return locations.slice(0, -1).map(({ location: { lat, lon } }, index) => ([
        new google.maps.LatLng(lat, lon),
        new google.maps.LatLng(
          locations[index + 1].location.lat,
          locations[index + 1].location.lon,
        ),
      ]))
    }

    return [];
  }

}
