import { LanguageService } from 'app/shared/services/language.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'bh-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent implements OnInit {

  public errorTime: moment.Moment;
  public currentLocate: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private snackBarRef: MatSnackBar,
              private languageService: LanguageService) { }

  ngOnInit() {
    this.errorTime = this.getCurrentTime();
    this.currentLocate = this.getCurrentLocale();
  }

  dismiss() {
    this.snackBarRef.dismiss()
  }

  getCurrentTime() {
    return moment();
  }

  getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
