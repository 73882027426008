<div class="secret-text">
  <span>{{ isHiddenText ? hiddenText : plainText }}</span>

  <div class="secret-text-actions">
    <button *ngIf="!hideCopyButton" mat-button (click)="copy()">
      {{ copied ? copiedButtonTitle : copyButtonTitle }}
    </button>
    <button *ngIf="!alwaysVisible" mat-button (click)="toggleVisibility()">
      <mat-icon>{{ isHiddenText ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>
  </div>
</div>
