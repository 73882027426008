import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MatDrawer } from '@angular/material/sidenav';
import { LanguageService } from 'app/shared/services/language.service';
import { EquipmentCheckerService } from 'app/modules/equipment/shared/services/equipment-checker.service';
import { ScanEquipment } from 'app/modules/equipment/shared/scan-equipment';
import { BehaviorSubject } from 'rxjs';
import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { ProjectCheckerService } from 'app/modules/disposition/shared/services/project-checker.service';
import { byLocationClosestToPosition } from 'app/shared/collection-utils';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { LocationType } from 'app/shared/enums/location-type.enum';
import { CreateProjectToProjectTransferCartCommand } from 'app/modules/disposition/contract/create-project-to-project-transfer-cart.command';
import { ProjectToProjectTransferItem } from 'app/modules/disposition/contract/project-to-project-transfer-item';
import { TransferStore } from 'app/modules/disposition/bulk-items/bulk-item-transfer/shared/transfer.store';
import { CreateStockToProjectTransferCartCommand } from 'app/modules/disposition/contract/create-stock-to-project-transfer-cart.command';
import { StockToProjectTransferItem } from 'app/modules/disposition/contract/stock-to-project-transfer-item';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { Point } from 'app/shared/geolocation/point';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { DispositionDataSource } from '../../shared/disposition.datasource';
import { KeycloakService } from 'app/core/keycloak';
import { EquipmentAssignToProjectUtils } from 'app/modules/equipment/equipments/equipment-assign-to-project/base/equipment-assign-to-project-utils';
import { OnlineStatusService } from 'app/shared/services/online-status.service';
import { ProjectDataSource } from '../../shared/project.datasource';
import { Html5NavigatorService } from 'app/modules/equipment/shared/services/html5-navigator.service';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { UsersService } from 'app/modules/userrole/shared/users.service';
import { RouterHistory } from 'app/shared/router-history';
@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-transfer-to-project',
  templateUrl: './mobile-equipment-transfer-to-project.component.html',
  styleUrls: ['./mobile-equipment-transfer-to-project.component.scss']
})
export class MobileEquipmentTransferToProjectComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  @ViewChild('drawer') drawer: MatDrawer;

  public transferToProjectForm: UntypedFormGroup;
  public projectSubForm: UntypedFormGroup;
  public additionalInfoForm: UntypedFormGroup;
  public projects: DispositionProject[];

  public filterControl: UntypedFormControl = new UntypedFormControl();
  public project: DispositionProject;
  public equipments: ScanEquipment[];
  public navigatorError: any;
  public loadingProjects: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  private filteredProjects: BehaviorSubject<DispositionProject[]> = new BehaviorSubject([]);
  public position: Point;
  public readonly fieldLimit = FieldLimit;

  public formatAddress = EquipmentAssignToProjectUtils.formatAddress;

  public readonly faTimes: IconDefinition = faTimes;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentStore: EquipmentsDataSource,
              protected dispositionStore: DispositionDataSource,
              protected projectStore: ProjectDataSource,
              protected formBuilder: UntypedFormBuilder,
              protected onlineStatusService: OnlineStatusService,
              protected dialog: MatDialog,
              private html5Navigator: Html5NavigatorService,
              protected equipmentsService: EquipmentsService,
              protected usersService: UsersService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected equipmentCheckerService: EquipmentCheckerService,
              private transferStore: TransferStore ) {
                super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    if (sessionStorage.getItem('equipments')) {
      this.equipments = JSON.parse(sessionStorage.getItem('equipments'));
    } else {
      this.equipmentStore.scanEquipmentsForTransfer.pipe(
        untilDestroyed(this))
      .subscribe((equipments: ScanEquipment[]) => {
        this.equipments = equipments;
        sessionStorage.setItem('equipments', JSON.stringify(this.equipments));
      });
    }

    this.buildForm();
    this.loadingProjects.next(true);
    this.subscribeToProjects();

    this.html5Navigator.getCurrentPosition()
    .subscribe(
      (position: GeolocationPosition) => {
        this.setPosition(Point.fromCoordinates(position.coords));
        this.loadProjects();
      },
      () => this.loadProjects()
    );
  }

  public ngOnDestroy(): void {
    sessionStorage.clear();
  }

  public save(): void {
    let stockSourceEquipments: ScanEquipment[] = [];
    let projectSourceEquipments: ScanEquipment[] = [];
    this.equipments.forEach((equipment: ScanEquipment) => {
      equipment.currentLocationType === LocationType.PROJECT ? projectSourceEquipments.push(equipment) :
       stockSourceEquipments.push(equipment);
    });
    if (stockSourceEquipments.length > 0 && projectSourceEquipments.length > 0) {
      const projectTransferItems: ProjectToProjectTransferItem[] = projectSourceEquipments.map(equipment =>
        ProjectToProjectTransferItem.fromScanEquipment(equipment, equipment.currentLocationId));
      let projectCommand: CreateProjectToProjectTransferCartCommand = new CreateProjectToProjectTransferCartCommand(this.project.projectId,
        projectTransferItems,
        null,
        null,
        this.comment.value === '' ? null : this.comment.value);
      this.transferStore.transferProjectToProject(projectCommand);
      const stockTransferItems: StockToProjectTransferItem[] = stockSourceEquipments.map(equipment =>
        StockToProjectTransferItem.fromScanEquipment(equipment));
      let stockCommand: CreateStockToProjectTransferCartCommand = new CreateStockToProjectTransferCartCommand(this.project.projectId,
        stockTransferItems,
        null,
        null,
        this.comment.value === '' ? null : this.comment.value);
      this.transferStore.transferStockToProject(stockCommand);
    } else if (stockSourceEquipments.length > 0) {
      const stockTransferItems: StockToProjectTransferItem[] = stockSourceEquipments.map(equipment =>
        StockToProjectTransferItem.fromScanEquipment(equipment));
      let stockCommand: CreateStockToProjectTransferCartCommand = new CreateStockToProjectTransferCartCommand(this.project.projectId,
        stockTransferItems,
        null,
        null,
        this.comment.value === '' ? null : this.comment.value);
      this.transferStore.transferStockToProject(stockCommand);
    } else {
      const projectTransferItems: ProjectToProjectTransferItem[] = projectSourceEquipments.map(equipment =>
        ProjectToProjectTransferItem.fromScanEquipment(equipment, equipment.currentLocationId));
      let projectCommand: CreateProjectToProjectTransferCartCommand = new CreateProjectToProjectTransferCartCommand(this.project.projectId,
        projectTransferItems,
        null,
        null,
        this.comment.value === '' ? null : this.comment.value);
      this.transferStore.transferProjectToProject(projectCommand);
    }

    this.equipmentStore.setScanEquipmentListForTransfer([]);
    this.goToLandingPage();
  }

  public getTitle(): string {
    if (this.equipments && this.equipments.length === 1) {
      return this.equipments[0].equipmentName;
    } else {
      return this.translate('general.transfer.s');
    }
  }

  private goToLandingPage(): void {
    this.router.navigate(['mobile/landing-page']);
  }

  public getSubtitle(): string {
    return this.equipments.length === 1 ? this.equipments[0].equipmentSerialNumber :
     `${this.equipments.length} ${this.translate('modules.equipment.assignProject.devices')}`;
    }

  protected subscribeToProjects(): void {
    this.projectStore.dispositionProjects
    .pipe(untilDestroyed(this))
    .subscribe((projects: DispositionProject[]) => {
      if (projects.length > 0) {
        this.projects = projects.filter(pr => !this.projectCheckerService.isProjectFinished(pr));
        this.filteredProjects.next(this.projects.slice());
        if (this.position) {
          this.projects.sort(byLocationClosestToPosition(this.position));
        }
      }

      this.loadingProjects.next(false);

    this.projectStore.currentDispositionProject
      .pipe(untilDestroyed(this))
      .subscribe((project: DispositionProject) => {
        this.project = project;
        this.projectSubForm.setValue({projectId: this.project.projectId});
      });
    });
  }

  public setPosition(position: Point): void {
    this.position = position;
  }

  public selectProject(projectId: string): void {
    this.projectStore.setCurrentDispositionProject(projectId);
    this.drawer.close();
  }

  public openProjectSelect(event: MouseEvent): void {
    event.preventDefault();
    this.drawer.open();
  }


  private buildForm(): void {
    this.projectSubForm = this.formBuilder.group({
      projectId: [this.project?.projectId || null, Validators.required],
    });

    this.loadingProjects.subscribe(loading => {
      const idControl = this.projectSubForm.controls['projectId'];
      loading ? idControl.disable() : idControl.enable();
    });

    this.additionalInfoForm = this.formBuilder.group({
      comment: [''],
    });

    this.transferToProjectForm = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.projectSubForm,
        this.additionalInfoForm,
      ]),
    });
  }

  private loadProjects(): void {
    this.projectStore.loadDispositionProjects();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public removeDisposition(equipmentId: string) {
    this.equipments = this.equipments.filter(eq => eq.equipmentId !== equipmentId);
    this.equipmentStore.setScanEquipmentListForTransfer(this.equipments);
  }

  public isValid(): boolean {
    return this.projectSubForm.valid && this.additionalInfoForm.valid;
  }

  get comment(): AbstractControl {
    return this.additionalInfoForm.get('comment');
  }
}


