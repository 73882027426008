import { ViewOrganisation } from '../../../../modules/organisation/contract/view-organisation.interface';
import { FilterValue } from '../filter-value';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes, FilterTypesDisplayNames } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { OrganisationFilterView } from '../filter-view/organisation-filter-view.interface';
import { FilterType, RenderStrategy } from '../filter-type';

export class OrganisationFilterViewConverter extends BaseFilterViewConverter<OrganisationFilterView> {
  constructor(
    originalFilters: OrganisationFilterView[],
    selectedValues: FilterParams,
    private organisationMap: Map<string, ViewOrganisation>,
    private filterTypesDisplayName?: FilterTypesDisplayNames
  ) {
    super(FilterTypes.ORGANISATION, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterType(filterItems: OrganisationFilterView[]): FilterType {
    return {
      typeName: this.type,
      displayNameType: this.filterTypesDisplayName,
      display: true,
      typeTotal: undefined,
      groups: this.convertToFilterGroups(filterItems),
      renderStrategy: RenderStrategy.RELATIONS_TREE
    };
  }

  protected convertToFilterValue(filterItem: OrganisationFilterView): FilterValue {
    return {
      displayName: filterItem.organisationName,
      storeName: filterItem.organisationId,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.organisationId),
      filterTotal: this.formatCount(filterItem.count),
      parentStoreName: this.organisationMap.get(filterItem.organisationId)?.parentOrganisationId
    }
  }
}
