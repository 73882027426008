<google-map bhMapCustomOptions
  *ngIf="isMapAPILoaded | async"
  [options]="defaultMapOptions"
  (mapInitialized)="onMapInitialized($event)"
  [height]="'100%'"
  [width]="'100%'">

  <bh-map-cluster *ngFor="let cluster of clusters"
    [clusterMarkers]="cluster.markers"
    [gridSize]="gridSize"
    [enableLabels]="enableLabels"
    (markerClicked)="markerClicked.emit($event)"
    (clusterClicked)="onClusterClick({ clickedCluster: $event, currentCluster: cluster })">
  </bh-map-cluster>

  <ng-container *ngFor="let info of infoWindows">
    <map-info-window *ngIf="info?.infoWindow?.content"
      bhInfoWindowVisibility
      [disableAutoPan]="disableAutoPan"
      [position]="info.location | locationToLatLngLiteral">
      <ng-template
        [bhInfoWindowComponentOutlet]="info.infoWindow.content"
        [bhInfoWindowComponentOutletValue]="info">
      </ng-template>
    </map-info-window>
  </ng-container>
</google-map>
