import { AfterViewInit, ChangeDetectorRef, Directive, Input, Type, ViewContainerRef } from '@angular/core';
import { InfoWindowComponent } from '../interfaces/info-window.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapLocationsCluster } from '../interfaces/map-location-cluster.interface';

@Directive({
  selector: '[bhInfoWindowComponentOutlet]'
})
export class InfoWindowComponentOutletDirective implements AfterViewInit {
  @Input('bhInfoWindowComponentOutlet') component: Type<InfoWindowComponent>;
  @Input('bhInfoWindowComponentOutletValue') value: MapLocationMarker | MapLocationsCluster;

  constructor(private viewContainerRef: ViewContainerRef) { }

  public ngAfterViewInit(): void {
    this.initComponent();
  }

  private initComponent(): void {
    if (this.component && this.value) {
      this.viewContainerRef.clear();

      const componentRef = this.viewContainerRef.createComponent<InfoWindowComponent>(this.component);
      componentRef.instance.value = this.value;
      componentRef.instance?.ngOnInit?.();
      componentRef.injector.get(ChangeDetectorRef)?.detectChanges();
    }
  }
}
