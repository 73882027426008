<!-- Main info -->
<div class="content-line fx-row fx-align-items-center gap-md">
  <div class="content-item-image">
    <bh-thumbnail *ngIf="item?.thumbnailKey; else tmpIconPlaceholder"
      class="image-main"
      [key]="item.thumbnailKey"
      [size]="ThumbnailSize.S">
    </bh-thumbnail>
    <ng-template #tmpIconPlaceholder>
      <bh-icon
        [icon]="iconPlaceholder"
        size="xxl"
        color="primary"></bh-icon>
    </ng-template>
  </div>
  <div class="content-item-description">
    <div class="content-item-manufacturer">{{item?.manufacturerName}}</div>
    <div class="content-item-name fx-row fx-align-items-center gap-sm">
      <div class="eq-name">{{item?.equipmentName || item?.equipmentModel}}</div>
      <bh-icon
        icon="open_in_new"
        color="primary"
        size="sm"
        [hover]="true"
        [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.equipm.s'|translate }"
        (click)="navigateToEquipment()">
      </bh-icon>
    </div>
    <div class="content-item-additional">
      <span>{{item?.equipmentCustomerSerialNumber}}</span>,
      <span>{{item?.equipmentModel}}</span>,
      <span>{{item?.equipmentType?.category1Name | translatableString}}</span>,
      <span>{{item?.equipmentType?.category2Name | translatableString}}</span>
    </div>
  </div>
</div>

<!-- Location -->
<div class="content-line">
  <div class="content-item-address">{{item?.currentAddress | mapInfoAddress}}</div>
  <div class="content-item-time">{{item?.lastTelematicsUpdate | formatTimezoneDateTime : { showTime: true } }}</div>
</div>

<!-- Telematic footer -->
<div *ngIf="item?.activityStatus || item?.operatingHoursLastDay || item?.currentOperatingHours"
  class="content-line fx-column gap-sm">
  <div class="content-item fx-row">
    <bh-activity-status *ngIf="item?.activityStatus as status" class="content-item-tlm" [status]="status"></bh-activity-status>
    <div *ngIf="item?.operatingHoursLastDay as opLastDay" class="content-item-tlm tlm-value">{{opLastDay | formatHoursAmount}}</div>
    <div *ngIf="item?.currentOperatingHours as opTotal" class="content-item-tlm tlm-value">{{opTotal | formatHoursAmount}}</div>
  </div>
  <div class="content-item fx-row">
    <div *ngIf="item?.activityStatus as status" class="content-item-tlm tlm-label">{{item?.activityStatus | activityStatus}}</div>
    <div *ngIf="item?.operatingHoursLastDay as opLastDay" class="content-item-tlm tlm-label">{{'shared.mapInfoWindow.equipment.opLastDay'|translate}}</div>
    <div *ngIf="item?.currentOperatingHours as opTotal" class="content-item-tlm tlm-label">{{'shared.mapInfoWindow.equipment.opTotal'|translate}}</div>
  </div>
</div>
