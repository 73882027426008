<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" >
  <div fxLayout="row" fxLayoutAlign="end end" class="m-list-header">
    <div class="m-list-header__item">
      <div class="icon-wrapper start">
        <button mat-button mat-icon-button [attr.aria-label]="'general.list'|translate" (click)="navigateToList()">
          <mat-icon>list</mat-icon>
        </button>
      </div>
    </div>
    <form class="m-list-header__form" fxFlex [formGroup]="searchForm" novalidate>
      <mat-form-field>
        <input fxFlex matInput placeholder="{{'general.search'|translate}} ..." formControlName="terms">
        <button mat-button *ngIf="termsValue" matSuffix mat-icon-button
                [attr.aria-label]="'general.labels.clear'|translate" (click)="reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>

  <div class="m-list-content-wrapper m-map-content-wrapper" fxFlex>
    <!-- Component "basic-map" is no longer used. Use "global-map" instead -->
    <!-- <bh-basic-map uniqueIdentifier="projects_mobile"
                  [showProjects]="true">
    </bh-basic-map> -->
  </div>
</div>
