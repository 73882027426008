import { Component, Input, OnInit } from '@angular/core';
import { AlarmSettingsBase } from '../alarm-settings-base.class';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AlarmSettingsFieldData } from '../../../../contract/alarm-field-data.type';
import { IntervalsDurationEnum } from '../../../../../maintenance/rules/shared/intervals-duration.enum';
import { TelematicsSelectorEnum } from './telematics-selector.enum';
import {
  EquipmentTelematicsUnitTypeResolver
} from '../../../../../equipment/shared/services/equipment-telematics-unit-type.resolver';
import { TelematicsUnitType } from '../../../../../equipment/contract/telematics-unit-type.enum';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { telematicsUnitsTypesWithoutBeacons } from './telematics-unit-types-without-beacons';

@Component({
  selector: 'bh-inactive-telematic-unit-settings',
  templateUrl: './inactive-telematic-unit-settings.component.html',
  styleUrls: ['./inactive-telematic-unit-settings.component.scss'],
})
export class InactiveTelematicUnitSettingsComponent extends AlarmSettingsBase implements OnInit {

  public readonly intervalDurations = [
    IntervalsDurationEnum.HOURS,
    IntervalsDurationEnum.DAYS,
    IntervalsDurationEnum.MONTHS
  ];

  public readonly telematicsSelectors = Object.keys(TelematicsSelectorEnum);

  public readonly telematicsUnitTypes = [
    TelematicsUnitType.TRACKUNIT_1,
    TelematicsUnitType.TELTONIKA_OBD_UNIT,
    TelematicsUnitType.TELTONIKA_CABLE_UNIT,
    TelematicsUnitType.CONFIDEX_ROUGH,
    TelematicsUnitType.CONFIDEX_MICRO,
    TelematicsUnitType.SIGFOX_TRACKERTYPL26_1,
    TelematicsUnitType.AEMP,
    TelematicsUnitType.GENERIC_2,
    TelematicsUnitType.GENERIC_3,
    TelematicsUnitType.GENERIC_4,
    TelematicsUnitType.GENERIC_5,
    TelematicsUnitType.GPS_OVER_IP,
    TelematicsUnitType.RIO,
    TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT
  ];

  @Input() set form(form: UntypedFormGroup) {
    this.settingsForm = form;
  }

  @Input() set fieldData(fieldData: AlarmSettingsFieldData) {
    this.alarmSettingsFieldData = fieldData;
  }

  constructor(private telematicUnitResolver: EquipmentTelematicsUnitTypeResolver,
              private languageService: LanguageService) {
    super();
  }

  public ngOnInit() {
    if (this.settingsForm) {
      this.onTelematicsSelectorChanged();
    }
  }

  public resolveTelematicsSelectorName(selector: string): string {
    switch (selector) {
      case TelematicsSelectorEnum.ALL:
        return this.languageService.getInstant('general.all');
      case TelematicsSelectorEnum.SELECTED:
        return this.languageService.getInstant('general.selected');
      case TelematicsSelectorEnum.ALL_TELEMATIC_UNITS_EXCEPT_BEACONS:
        return this.languageService.getInstant('modules.notification.alarmRuleAddEdit.inactiveTelematicsUnit.allTelematicUnitsExceptBeacons');
    }
  }

  public onTelematicsSelectorChanged() {
    const telematicsTypesControl = this.settingsForm.get(this.controlNames.SETTINGS_TELEMATICS_TYPES);

    if (this.allowSelectTelematicUnits()) {
      telematicsTypesControl.setValidators([Validators.required]);
    } else if(this.settingsForm.get(this.controlNames.SETTINGS_TELEMATICS_SELECTOR).value === TelematicsSelectorEnum.ALL_TELEMATIC_UNITS_EXCEPT_BEACONS) {
      telematicsTypesControl.setValue(telematicsUnitsTypesWithoutBeacons);
      telematicsTypesControl.setValidators(null);
    } else {
      telematicsTypesControl.setValue([]);
      telematicsTypesControl.setValidators(null);
    }

    telematicsTypesControl.updateValueAndValidity();
  }

  public resolveTelematicsUnitName(option: string) {
    const title =  this.telematicUnitResolver.resolveName(option);
    const subtitle  = this.telematicUnitResolver.resolveSubtitle(option);
    return !subtitle ? title : `${title} - ${subtitle}`;
  }

  public allowSelectTelematicUnits() {
    let telematicsSelector = this.settingsForm.get(this.controlNames.SETTINGS_TELEMATICS_SELECTOR).value;
    return !!telematicsSelector && TelematicsSelectorEnum.SELECTED === telematicsSelector;
  }
}
