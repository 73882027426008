import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FieldLimit } from '../../enums/fieldLimit.enum';
import { trim, isEmpty } from 'lodash';

@Component({
  selector: 'bh-inline-filename-edit',
  templateUrl: './inline-filename-edit.component.html',
  styleUrls: ['./inline-filename-edit.component.scss']
})
export class InlineFilenameEditComponent implements OnInit {

  public inputControl: UntypedFormControl;
  public readonly = true;
  public readonly fieldLimit = FieldLimit;

  @Input() public editable = false;
  @Input() public maxFileNameLength = 100;
  @Input() public showHint = false;
  @Input() public hint: string;
  @Output() public saveValue: EventEmitter<string> = new EventEmitter<string>();

  private _fileName: string;
  private _fileNamePart: string;
  private _fileExtensionPart: string;
  private readonly inputValidators: ValidatorFn[] = [
    Validators.required,
    Validators.minLength(1),
    Validators.pattern(/^[0-9a-zA-Z^&'@{}\[\],$=!\-#().%+~_ äöüÄÖÜß]+$/),
    this.maxFilenameLengthValidator(),
  ];

  @Input() set filename(fileName: string) {
    this._fileName = fileName;
    const extensionIndex = fileName?.lastIndexOf('.') ?? -1;
    this._fileNamePart = fileName?.substring(0, extensionIndex === -1 ? fileName.length : extensionIndex) ?? '';
    this._fileExtensionPart = extensionIndex === -1 ? '' : fileName.substring(extensionIndex);
  }

  public get fileNamePart(): string {
    return this._fileNamePart;
  }

  public get fileExtensionPart(): string {
    return this._fileExtensionPart;
  }

  private get currentValue(): string {
    return `${trim(this.inputControl.value)}${this._fileExtensionPart}`;
  }

  public ngOnInit(): void {
    this.inputControl = new UntypedFormControl(this._fileName);
    this.setValidators();
  }

  public edit(): void {
    this.inputControl.patchValue(this._fileNamePart);
    this.readonly = false;
    this.setValidators();
  }

  public cancel(): void {
    this.inputControl.patchValue(this._fileName);
    this.readonly = true;
    this.setValidators();
  }

  public save(): void {
    this.inputControl.markAsTouched();
    if (!this.inputControl.valid || isEmpty(trim(this.inputControl.value)) || this.readonly) {
      return
    }

    const newFileName = this.currentValue;
    if (this._fileName !== newFileName) {
      this.filename = newFileName;
      this.saveValue.emit(newFileName);
    }
    this.cancel();
  }

  private setValidators(): void {
    this.inputControl.setValidators(this.readonly ? [] : this.inputValidators);
    this.inputControl.updateValueAndValidity();
    this.inputControl.markAsTouched();
  }

  private maxFilenameLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      (control.value ?? '').length <= this.maxFileNameLength - this.fileExtensionPart.length
        ? null
        : { maxlength: true };
  }
}
