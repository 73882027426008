export class Serializable {
  fromJSON(json): any {
    for (let propName in json) {
      if (json) {
        this[propName] = json[propName];
      }
    }
    return this;
  }
}
