<div class="add-employee-assignment-dialog">
	<div class="dialog-close-icon">
		<button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
			<mat-icon aria-hidden="true">clear</mat-icon>
		</button>
	</div>
	<mat-dialog-content fxLayout="row" fxLayoutAlign="start start">
		<div class="employee-role-icon">
			<mat-icon *ngIf="!icon">add_circle_outline</mat-icon>
			<fa-icon [icon]="icon" *ngIf="icon"></fa-icon>
		</div>
		<div fxFlex="350px">
			<h4 *ngIf="editMode">{{'modules.equipment.employeeAssignment.editResponsible'|translate}}</h4>
			<h4 *ngIf="replaceMode">{{'modules.equipment.employeeAssignment.changeResponsible'|translate}}</h4>
      <h4 *ngIf="!editMode && !replaceMode">{{'modules.equipment.employeeAssignment.addResponsible'|translate}}</h4>
			<form [formGroup]="form">

				<mat-form-field>
					<mat-label>{{'general.role.s'|translate}}</mat-label>
					<mat-select formControlName="employeeRole" disableOptionCentering="true">
						<mat-option>
              <ngx-mat-select-search
                [formControl]="employeeRoleFilter"
								[placeholderLabel]="'general.labels.search'|translate:{value: 'general.role.s'|translate}"
                [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{value: 'general.role.pl'|translate}">
              </ngx-mat-select-search>
						</mat-option>
						<mat-option *ngFor="let employeeRole of employeeRoles" [value]="employeeRole">
							{{ employeeRole | equipmentEmployeeRole }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field>
					<mat-label>{{'general.employee.s'|translate}}</mat-label>
					<mat-select formControlName="employee" disableOptionCentering="true">
						<mat-option>
							<ngx-mat-select-search [formControl]="employeeFilter"
																		 [placeholderLabel]="'general.labels.search'|translate:{value: 'general.employee.s'|translate}"
                                     [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{value: 'general.employee.pl'|translate}">
              </ngx-mat-select-search>
						</mat-option>
						<mat-option *ngFor="let employee of availableEmployees" [value]="employee.employeeId">
							{{ employee.employeeName }}, {{ employee.employeeFirstName | defaultValue: '-' }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="hand-over-date">
					<mat-label>{{'modules.equipment.employeeAssignment.handOverDate'|translate}}</mat-label>
					<input matInput required
								 formControlName="handOverDate" autocomplete="off"
								 [min]="minHandOverDate"
                 [max]="maxHandOverDate"
								 [matDatepicker]="handOverDatePicker">
					<mat-datepicker-toggle matSuffix [for]="handOverDatePicker"></mat-datepicker-toggle>
					<mat-datepicker #handOverDatePicker></mat-datepicker>
					<mat-error *ngIf="handOverDate.hasError('required')">
						{{'shared.validation.required'|translate: {value: 'modules.equipment.employeeAssignment.handOverDate'|translate} }}
          </mat-error>
        </mat-form-field>

				<div class="last-value" *ngIf="latestLifeCycle">
					<h4>{{'general.lastChange'|translate}}</h4>
					<div *ngIf="isAddedLifeCycle(latestLifeCycle.lifeCycleType)">
						<div>{{ latestLifeCycle.logDate | date: 'dd.MM.yyyy' }}</div>
						<div>{{'general.new'|translate}} {{ latestLifeCycle.employeeRole | equipmentEmployeeRole }}</div>
						<div>{{ latestLifeCycle.employeeFirstName | defaultValue: '' }} {{ latestLifeCycle.employeeName }}</div>
					</div>
					<div *ngIf="isReplacedLifeCycle(latestLifeCycle.lifeCycleType)">
						<div>{{ latestLifeCycle.logDate | date: 'dd.MM.yyyy' }}</div>
						<div>
							{{'general.old'|translate}} {{ latestLifeCycle.employeeRole | equipmentEmployeeRole }}:
							{{ latestLifeCycle.oldEmployeeFirstName | defaultValue: '' }} {{ latestLifeCycle.oldEmployeeName }}
						</div>
						<div>
							{{'general.new'|translate}} {{ latestLifeCycle.employeeRole | equipmentEmployeeRole }}:
							{{ latestLifeCycle.newEmployeeFirstName | defaultValue: '' }} {{ latestLifeCycle.newEmployeeName }}
						</div>
					</div>
					<div *ngIf="isRemovedLifeCycle(latestLifeCycle.lifeCycleType)">
						<div>{{ latestLifeCycle.logDate | date: 'dd.MM.yyyy' }}</div>
						<div>{{ latestLifeCycle.employeeRole | equipmentEmployeeRole }} {{'modules.equipment.employeeAssignment.removed'|translate}}</div>
						<div>{{ latestLifeCycle.employeeFirstName | defaultValue: '' }} {{ latestLifeCycle.employeeName }}</div>
					</div>
        </div>

			</form>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
		<button mat-button mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
		<button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="saveDisabled">{{'general.buttons.save'|translate}}</button>
	</mat-dialog-actions>
</div>
