<div class="shopId-edit">
  <div class="dialog-close-icon"></div>
  <h1 mat-dialog-title>
    {{'modules.organisation.customerShopEdit.editShopId'| translate:{value: this.customerName} }}
  </h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>{{'modules.organisation.customerShopEdit.shopIdPlaceholder'| translate}}</mat-label>
      <input matInput [formControl]="shopIdControl">
    </mat-form-field>
  </div>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="confirmChange()"
            [disabled]=false>{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
