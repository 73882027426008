<mat-card fxLayout="column" fxFlex="0 0 100%">
  <div class="header">
    <div class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-icon svgIcon="api-keys"></mat-icon>
      <span>{{ 'modules.apiKeys.apiKeys' | translate }}</span>
    </div>
  </div>

  <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner diameter="64"></mat-spinner>
  </div>

  <div *ngIf="!connector && !loading" class="content">
    <h4>{{ 'modules.apiKeys.customerId' | translate }}: {{ getUserCustomerId() }}</h4>

    <button mat-raised-button
            *ngIf="hasAuthority(authorities.CONNECTOR_CREATE)"
            (click)="createApiKey()">
      {{ 'modules.apiKeys.createApiKey' | translate }}
    </button>
  </div>


  <div *ngIf="connector && !loading" class="content">
    <h4>{{ 'modules.apiKeys.customerApiKeys' | translate }}</h4>

    <table mat-table class="api-keys-table" [dataSource]="[connector]">

      <ng-container matColumnDef="clientId">
        <th mat-header-cell *matHeaderCellDef>{{ 'modules.apiKeys.apiKey' | translate }}</th>
        <td mat-cell *matCellDef="let connector">
          <bh-secret-text [text]="connector.clientId"
                          copyButtonTitle="{{ 'general.buttons.copy' | translate }}"
                          copiedButtonTitle="{{ 'general.buttons.copied' | translate }}">
          </bh-secret-text>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'modules.apiKeys.createdOn' | translate }}</th>
        <td mat-cell *matCellDef="let connector">
          {{ connector.createdDate | formatTimezoneDateTime: { showTime: true } }}
        </td>
      </ng-container>

      <ng-container matColumnDef="customerId">
        <th mat-header-cell *matHeaderCellDef>{{ 'modules.apiKeys.customerId' | translate }}</th>
        <td mat-cell *matCellDef="let connector"> {{ connector.customerId }} </td>
      </ng-container>

      <ng-container matColumnDef="action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let connector">
          <button mat-button
                  *ngIf="hasAuthority(authorities.CONNECTOR_DELETE)"
                  (click)="deleteApiKey()"
                  [matTooltip]="'general.buttons.delete'|translate">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-card>
