<div class="log-manual-telematics-dialog">
  <h2 mat-dialog-title>{{equipment?.equipmentName ? equipment?.equipmentName : equipment?.equipmentModel}}
    <span *ngIf="equipment?.equipmentSerialNumber">- {{equipment.equipmentSerialNumber}}</span>
  </h2>

  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="log-manual-telematics-dialog__content">
    <form [formGroup]="form" novalidate fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">
      <mat-form-field>
        <mat-label>{{'modules.equipment.lifecycle.logDate'|translate|uppercase}}</mat-label>
        <input matInput required
               formControlName="logDate" autocomplete="off"
               [matDatepicker]="picker"
               [min]="dateOfLastEntry"
               [max]="today">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="logDate.hasError('isValidDateInstance')">
          {{logDate.getError('isValidDateInstance')}}
        </mat-error>
        <mat-error *ngIf="logDate.hasError('inputDateNotBefore')">
          {{'modules.equipment.lifecycle.dateNotBefore'|translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{newValueDescription}}</mat-label>
        <input type="number" matInput required
               formControlName="newValue"
               [bhInputAllowedCharacters]="newValuePattern"
               [min]="currentValue + 1"
               [max]="fieldLimit.SEVEN_DIGITS_DECIMAL">
        <mat-error *ngIf="newValue.hasError('numberBigger')">
          {{ 'shared.validation.number.intBiggerThan'|translate }}
        </mat-error>
        <mat-error *ngIf="newValue.hasError('max')">
          {{ 'shared.validation.maxInteger'|translate:{value: fieldLimit.SEVEN_DIGITS_INTEGER} }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'general.comment.s'|translate|uppercase}}</mat-label>
        <textarea matInput #commentTextarea
                  [maxlength]="fieldLimit.MEDIUM_TEXT"
                  formControlName="comment">
        </textarea>
        <mat-hint align="end">{{commentTextarea.value.length}} / {{fieldLimit.MEDIUM_TEXT}}</mat-hint>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button color="accent" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()">
      {{'general.buttons.save'|translate}}
    </button>
    <mat-checkbox color="primary"
                  class="log-manual-telematics-dialog__checkbox"
                  *ngIf="!isLastEquipment()"
                  [(ngModel)]="loadNextEquipment">
      {{'modules.equipment.lifecycle.andNextEquipment'|translate}}
    </mat-checkbox>
  </mat-dialog-actions>
</div>
