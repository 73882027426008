<div fxFill class="transfer-cart-overview"
     fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">

  <bh-transfer-cart-header [lineOne]="sourceStock?.stockName"
                           [lineTwo]="'modules.disposition.bulkItemTransfer.moveInventoryDestination'|translate"
                           [icon]="faExchange"
                           (closed)="closeCart()"></bh-transfer-cart-header>

  <div class="transfer-cart-items" fxFlex="100"
       fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">

    <mat-card fxFlex="50">
      <mat-card-content class="card-content" fxLayout="column" fxLayoutAlign="start stretch">
        <bh-transfer-items [transferItems]="transferItems | async"
                           [sourceLabel]="'modules.disposition.bulkItemTransfer.inOriginalStock'|translate"
                           [targetLabel]="'modules.disposition.bulkItemTransfer.toTargetStock'|translate"
                           [invalid]="!targetStock"
                           [showSelectAll]="true"
                           [searchable]="true"
                           [isLoadingItems]="isLoadingStockAmounts"
                           (submitted)="confirmCart()"
                           (searchFilterApplied)="updateFilterControl($event)"
                           (amountUpdated)="updateAmount($event)"></bh-transfer-items>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <bh-bulk-item-transfer-stock-select #stockSelect
                                            [hiddenStockId]="sourceStock?.stockId"
                                            (selectedStock)="selectStock($event)"></bh-bulk-item-transfer-stock-select>
      </mat-card-content>
    </mat-card>

  </div>

</div>
