import { LanguageService } from './../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { RentalContractAmountInterval } from '../../contract/rental-contract-amount-interval.enum';

@Pipe({
  name: 'rentalContractAmountInterval'
})
export class RentalContractAmountIntervalPipe implements PipeTransform {
  private readonly translationKeys = {
    [RentalContractAmountInterval.TOTAL]: 'shared.pipes.rentalContractAmountInterval.total',
    [RentalContractAmountInterval.DAILY]: 'shared.pipes.rentalContractAmountInterval.daily',
    [RentalContractAmountInterval.WEEKLY]: 'shared.pipes.rentalContractAmountInterval.weekly',
    [RentalContractAmountInterval.MONTHLY]: 'shared.pipes.rentalContractAmountInterval.monthly',
    [RentalContractAmountInterval.OPERATIONAL_COUNTER]: 'shared.pipes.rentalContractAmountInterval.operationalCounter',
    [RentalContractAmountInterval.NOT_AVAILABLE]: 'shared.pipes.rentalContractAmountInterval.notAvailable'
  };

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any, args?: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
