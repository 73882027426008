import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { CountryResolver } from 'app/shared/country.resolver';

@Pipe({
  name: 'mapInfoAddress'
})
export class MapInfoAddressPipe implements PipeTransform {

  constructor(private countryResolver: CountryResolver) {}

  public transform(value: Address): string {
    const { country, street, streetNumber, postalCode, city } = value ?? {};
    const countryName = this.countryResolver.resolveName(country);
    const fullStreet = street ? this.concatAddressParts([street, streetNumber]) : null;

    return this.concatAddressParts([
      postalCode,
      city,
      countryName,
      fullStreet
    ], ', ');
  }

  private concatAddressParts(parts: string[], separator = ' '): string {
    return parts
      .map(value => (value || '').trim())
      .filter(Boolean)
      .join(separator);
  }

}
