import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { MaintenanceTypeField } from '../../shared/contract/maintenance-type-field.interface';

@Injectable()
export class MaintenanceTypeFieldService {

  private url = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/maintenance-type-fields';

  constructor(private http: HttpClient) {}

  getAll(): Observable<MaintenanceTypeField[]> {
    return this.http.get<MaintenanceTypeField[]>(this.url);
  }
}
