import { environment } from 'environments/environment';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CustomersService } from '../../shared/customers.service';
import { ViewCustomer } from '../../contract/view-customer.interface';
import { CustomerViewComponent } from '../customer-view/customer-view.component';
import { DeleteCustomerCommand } from '../../contract/delete-customer-command';
import { SearchCustomer } from '../../contract/search-customer.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { SecurityQueryDialogComponent } from 'app/shared/components/security-query-dialog/security-query-dialog.component';
import { SecurityQueryProcessType } from 'app/shared/enums/security-query-process-type.enum';
import { ShopIdEditDialogComponent } from '../../shared/shop-id-edit-dialog/shop-id-edit-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutonumberEditDialogComponent } from '../../shared/autonumber-edit-dialog/autonumber-edit-dialog.component';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faInputNumeric } from '@fortawesome/pro-light-svg-icons';
import { UserAddEditComponent } from 'app/modules/userrole/users/user-add-edit/user-add-edit.component';
import { CustomerConfigListColumnService } from 'app/modules/userrole/shared/customer-config-list-column.service';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { CustomerLanguagesComponent } from '../customer-languages/customer-languages.component';
import { UserAddEditConfigData } from 'app/modules/userrole/users/user-add-edit/user-add-edit-config-data.interface';

type IconColumnName = 'customerEdit'
  | 'customerEditShopId'
  | 'customerEditRoles'
  | 'customerEditAutoNumber'
  | 'customerEditModules'
  | 'customerDelete'
  | 'customerAddUser'
  | 'customerAddFleetadmin'
  | 'customerLanguages';

@Component({
  selector: 'bh-customer-list',
  templateUrl: 'customer-list.component.html',
  styleUrls: ['customer-list.component.scss']
})
@UntilDestroy()
export class CustomerListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy, AfterViewInit {

  displayedColumns = [
    'customerName',
    'customerNumber',
    'customerStreet',
    'customerPostalCode',
    'customerCity',
    'customerDetails'
  ];
  public pagingCustomersDataSource: PagingCustomersDataSource | null;
  public searchForm: UntypedFormGroup;
  public readonly faInputNumeric: IconDefinition = faInputNumeric;
  public readonly faLanguage = faLanguage;
  public readonly canEditLanguage = this.hasAuthority(this.authorities.CUSTOMER_UPDATE);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private customersService: CustomersService,
              private detector: ChangeDetectorRef,
              protected router: Router,
              protected route: ActivatedRoute,
              private dialog: MatDialog,
              private columnService: CustomerConfigListColumnService,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.addIconColumns();

    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl()
    });

    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });

    setTimeout(() => {
      this.pagingCustomersDataSource.getCustomers(0, this.paginator.pageSize);
    }, environment.DELAY_SHORT);
  }

  public ngAfterViewInit(): void {
    this.pagingCustomersDataSource = new PagingCustomersDataSource(this.customersService, this.columnService);
    // TODO: Remove this as it is a workaround to make the table visible when the page got reloaded
    this.detector.detectChanges();

    this.searchForm.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(() => this.onSearchFormSubmit());
  }

  public ngOnDestroy(): void {
  }

  public onSearchFormSubmit(): void {
    this.paginator.pageIndex = 0; // go back to the first page when a user searches or resets the search

    this.getCustomers();
  }

  public onPaginateChange(event: PageEvent): void {
    this.getCustomers();
    this.columnService.selectColumns([], event.pageSize);
  }

  public deleteCustomer(customer: ViewCustomer): void {
    const dialogRef = this.dialog.open(SecurityQueryDialogComponent);
    dialogRef.componentInstance.processType = SecurityQueryProcessType.CUSTOMER_DELETE;
    dialogRef.componentInstance.keyValue = customer.customerName;

    dialogRef.afterClosed().subscribe(result => {
      if (result.decision === dialogResults.YES) {

        let cmd = new DeleteCustomerCommand();
        cmd.customerId = customer.customerId;
        this.customersService.deleteCustomer(cmd).subscribe(
            () => {
              setTimeout(() => {
                if (this.pagingCustomersDataSource.data.value.length <= 1 && this.paginator.pageIndex !== 0) {
                  this.paginator.pageIndex = this.paginator.pageIndex - 1;
                }
                this.getCustomers();
              }, environment.DELAY_LONG);
            },
            (error: HttpErrorResponse) => {
              console.log('Delete Customer error');
            }
        );
      }
    });
  }

  public viewCustomer(customer: ViewCustomer): void {
    const dialogRef = this.dialog.open(CustomerViewComponent, {width: '400px', height: '650px'});
    dialogRef.componentInstance.customerId = customer.customerId;
  }

  public editAutoNumber(customerId: string) {
    const dialogRef = this.dialog.open(AutonumberEditDialogComponent, {width: '650px'});
    dialogRef.componentInstance.customerId = customerId;
  }

  public editShopId(customerId: string, customerName: string) {
    const dialogRef = this.dialog.open(ShopIdEditDialogComponent);
    dialogRef.componentInstance.customerId = customerId;
    dialogRef.componentInstance.customerName = customerName;
  }

  public navigateToEditModules(customerId: string): void {
    this.router.navigate([`customers/edit/${customerId}/modules`]);
  }

  public navigateToEditRoles(customerId: string): void {
    this.router.navigate([`customers/edit/${customerId}/roles`]);
  }

  private getCustomers(): void {
    this.pagingCustomersDataSource.getCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.searchForm.get('terms').value);
  }

  public addUser(customerId: string): void {
    const data: UserAddEditConfigData = { customerId };
    const dialogRef = this.dialog.open(UserAddEditComponent, { data });
    dialogRef
      .afterClosed()
      .subscribe((result: dialogResults) => {
        if (result === dialogResults.SAVE) {
          this.router.navigate(['/users']);
        }
      });
  }

  public editLanguages(customerId: string): void {
    this.customersService.getCustomer(customerId)
      .subscribe(customer => this.dialog.open(CustomerLanguagesComponent, { data: { customer }, autoFocus: false }));
  }

  private addIconColumns(): void {
    const allIconColumns: IconColumnName[] = [
      'customerEdit',
      'customerEditShopId',
      'customerEditAutoNumber',
      'customerEditModules',
      'customerLanguages',
      'customerEditRoles',
      'customerDelete',
      'customerAddUser',
      'customerAddFleetadmin',
    ];
    this.displayedColumns = [
      ...this.displayedColumns,
      ...allIconColumns.filter(column => this.isVisibleColumn(column))
    ];
  }

  private isVisibleColumn(column: IconColumnName): boolean {
    switch (column) {
      case 'customerEdit': return this.hasAuthority(this.authorities.CUSTOMER_UPDATE);
      case 'customerEditShopId':
      case 'customerEditModules':
      case 'customerEditRoles': return this.hasAuthority(this.authorities.CUSTOMER_UPDATE)
        && this.hasAnyRole(this.priviledgedRole.Partneradmin, this.priviledgedRole.Superadmin);
      case 'customerEditAutoNumber': return this.hasAuthority(this.authorities.CUSTOMER_UPDATE)
        && this.hasAnyRole(this.priviledgedRole.Partneradmin);
      case 'customerDelete': return this.hasAuthority(this.authorities.CUSTOMER_DELETE);
      case 'customerAddUser': return this.hasAuthority(this.authorities.USER_CREATE);
      case 'customerAddFleetadmin': return this.hasAuthority(this.authorities.FLEETADMIN_CREATE);
      case 'customerLanguages': return this.canEditLanguage;
    }
  }
}

export class PagingCustomersDataSource extends DataSource<any> {

  public readonly data: BehaviorSubject<SearchCustomer[]> = new BehaviorSubject<SearchCustomer[]>([]);
  private _pageIndex = 0;
  private _pageSize = 25;
  private _length = 0;
  private _searchTerm = '';

  constructor(
    private _customersService: CustomersService,
    private customerConfigListColumnService: CustomerConfigListColumnService,
  ) {
    super();
    this.customerConfigListColumnService.pageSize.subscribe((pageSize: number) => {
      this._pageSize = pageSize;
    });
  }

  public get pageIndex(): number {
    return this._pageIndex
  }

  public get pageSize(): number {
    return this._pageSize;
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public get length(): number {
    return this._length;
  }

  public connect(): Observable<any[]> {
    return merge(this.data);
  }

  public disconnect() {
    this.data.complete();
    this.data.observers = [];
  }

  public getCustomers(startIndex: number, size: number, searchTerm?: string) {
    (searchTerm
      ? this._customersService.getCustomersSearch(searchTerm, startIndex, size)
      : this._customersService.getCustomers(startIndex, size))
      .subscribe(({ content, totalElements: length, number: pageIndex, size: pageSize }: PagedResponse<SearchCustomer>) => {
        this._length = length;
        this._pageIndex = pageIndex;
        this._pageSize = pageSize;
        this.data.next(content);
      });
  }
}
