<div fxLayout="row" flex="100" fxLayoutAlign="space-between start" fxLayoutGap="15px" [formGroup]="formGroup">
  <bh-date-time-picker
    fxFlex="50"
    [formGroup]="formGroup"
    [timeFormControlName]="'startTime'"
    [maxDateTime]="endDateTimeControl?.value"
    [defaultDateTime]="defaultStartDateTime"
    [datePlaceholder]="'general.startDate' | translate"
    [timePlaceholder]="'general.startTime' | translate"
    [dateTimeControl]= "startDateTimeControl"
    [disabled]="startDateTimeControl.disabled"
  ></bh-date-time-picker>

  <bh-date-time-picker
    fxFlex="50"
    [formGroup]="formGroup"
    [timeFormControlName]="'endTime'"
    [minDateTime]="startDateTimeControl?.value"
    [defaultDateTime]="defaultEndDateTime"
    [datePlaceholder]="'general.endDate' | translate"
    [timePlaceholder]="'general.endTime' | translate"
    [dateTimeControl]=endDateTimeControl
    [required]="endDateRequired"
    [disabled]="endDateTimeControl.disabled"
  ></bh-date-time-picker>
</div>

