import { AfterViewInit, Directive, Input, OnDestroy, Self } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Directive({
  selector: 'mat-checkbox[bhMatCheckboxReadonly]'
})
export class MatCheckboxReadonlyDirective implements AfterViewInit, OnDestroy {
  @Input('bhMatCheckboxReadonly') public set readonly(value: boolean) {
    this.isReadOnly = Boolean(value);
    this.changeStyles();
  }
  public get readonly(): boolean {
    return this.isReadOnly;
  }
  private isReadOnly = false;
  private isReadonlyApplied = false;
  private pointerEventsPrev = 'auto';
  private pointerEventsReadOnly = 'none';
  private clickHandler = this.onEvent.bind(this);

  private get checkboxNativeElement(): HTMLElement {
    return this.checkboxComponent?._elementRef?.nativeElement;
  }

  constructor(@Self() private checkboxComponent: MatCheckbox) {}

  public ngAfterViewInit(): void {
    this.checkboxComponent._inputElement.nativeElement.addEventListener('click', this.clickHandler, true);
    this.changeStyles();
  }

  public ngOnDestroy(): void {
    this.checkboxComponent._inputElement.nativeElement.removeEventListener('click', this.clickHandler, true);
  }

  private onEvent(event: MouseEvent): void {
    if (this.isReadOnly) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private changeStyles(): void {
    if (this.checkboxNativeElement) {
      if (this.isReadOnly && !this.isReadonlyApplied) {
        this.savePreviousConfigs();
        this.applyReadOnlyConfigs();
      } else if (!this.isReadOnly && this.isReadonlyApplied) {
        this.applyPreviousConfigs();
      }
    }
  }

  private savePreviousConfigs(): void {
    this.pointerEventsPrev = this.checkboxNativeElement.style.pointerEvents;
  }

  private applyReadOnlyConfigs(): void {
    this.applyConfigs(this.pointerEventsReadOnly);
    this.isReadonlyApplied = true;
  }

  private applyPreviousConfigs(): void {
    this.applyConfigs(this.pointerEventsPrev);
    this.isReadonlyApplied = false;
  }

  private applyConfigs(pointerEvents: string): void {
    this.checkboxNativeElement.style.pointerEvents = pointerEvents;
  }
}
