<form  class="time-duration-input-container" fxLayout="row" fxLayoutAlign="space-between start" [formGroup]="form">
  <label class="time-duration-input-container__label"
    *ngIf="customPlaceholder"
    [ngClass]="{'label-error': errorState, 'label-focused': focused && !errorState }"
  >
    {{customPlaceholder}} {{required ? '*' : ''}}
  </label>
  <div class="time-duration-input-container__fields" fxLayout="row" fxLayoutAlign="space-between center">
    <input type="number"
      formControlName="hours"
      bhInputAllowedPattern="^$|^[0-9]$|^[1-9][0-9]{1,2}$"
      autocomplete="off"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      (input)="handleChange()"
      data-osp-test="input-hours">
    <label>{{'general.units.time.hour.abbr'|translate}}</label>

    <input type="number"
      formControlName="minutes"
      bhInputAllowedPattern="^$|^[0-9]$|^[1-5][0-9]$"
      bhMaxLength="2"
      autocomplete="off"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      (input)="handleChange()"
      data-osp-test="input-minutes">
    <label>{{'general.units.time.minute.abbr'|translate}}</label>
  </div>
</form>
