import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CustomEncoder } from '../custom-encoder';
import { ScanCodeEntry } from '../contract/scan-code-entry.interface';

@Injectable()
export class ScanCodeService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/scancodes';

  public constructor(
    private http: HttpClient) {}

  public isScanCodeInUse(scanCode: string): Observable<ScanCodeEntry> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('scanCode', scanCode)
    };
    return this.http.get<ScanCodeEntry>(`${this.url}/scan-code-in-use`, httpOptions);
  }

  public findAllScanCodesInUse(): Observable<ScanCodeEntry[]> {
    return this.http.get<ScanCodeEntry[]>(`${this.url}`);
  }

}
