import { DocumentLink } from '../../contract/document-link.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FileDownloadService } from '../test-place/secured-image/services/file-download.service';
import { IGalleryOptions } from '../test-place/secured-image/interfaces/gallery-options.interface';

@Component({
  selector: 'bh-attachment-list-image-category',
  templateUrl: './attachment-list-image-category.component.html',
  styleUrls: ['./attachment-list-image-category.component.scss']
})
export class AttachmentListImageCategoryComponent {
  @Input() public images: DocumentLink[];
  @Input() public galleryOptions: IGalleryOptions[] = this.getDefaultGalleryOptions();
  @Input() public defaultImage: DocumentLink;
  @Input() public setDefaultEnable = true;
  @Input() public deletable = true;
  @Input() public visibleDescription = false;
  @Input() public editableName = false;
  @Input() public editableDescription = false;
  @Input() public pendingChanges = false;
  @Output() public defaultImageChanged = new EventEmitter<DocumentLink>();
  @Output() public deleted = new EventEmitter<string>();
  @Output() nameChanged = new EventEmitter<DocumentLink>();
  @Output() descriptionChanged = new EventEmitter<DocumentLink>();

  constructor(private fileDownloadService: FileDownloadService) { }

  public isDefaultImage(image: DocumentLink): boolean {
    return image.documentKey === this.defaultImage?.documentKey;
  }

  public isSetDefaultEnabled(image: DocumentLink): boolean {
    return image.fileExists && this.setDefaultEnable && !this.isDefaultImage(image);
  }

  public changeDescription(description: string, document: DocumentLink): void {
    if (this.editableDescription) {
      this.descriptionChanged.emit({...document, fileDescription: description});
    }
  }

  public changeFileName(fileName: string, document: DocumentLink): void {
    if (this.editableName) {
      this.nameChanged.emit({...document, fileName: fileName});
    }
  }

  public downloadImage(image: DocumentLink): void {
    this.fileDownloadService.saveFile(image);
  }

  public imagesTrackBy(index: number, image: DocumentLink): string {
    return `${image.documentKey}_${image.fileType}_${image.fileName}_${image.fileDescription}`;
  }

  private getDefaultGalleryOptions(): IGalleryOptions[] {
    return this.galleryOptions = [{
      width: '100px',
      height: '100px',
      thumbnails: false,
      imageSize: 'contain',
      previewCloseOnClick: true,
      imageArrows: false,
      previewArrows: false
    }];
  }
}
