import { Component, ContentChild } from '@angular/core'

@Component({
  selector: 'bh-show-hide',
  template: `
		<ng-content></ng-content>
		<mat-icon [ngStyle]="{ 'color': isHidden()}" (mousedown)="showPassword()" (mouseup)="hidePassword()">remove_red_eye
		</mat-icon>`
})
export class ShowHideComponent {
  show = false;

  @ContentChild('showhideinput') input;

  constructor() {
  }

  // TODO refactor to use actual css
  isHidden() {
    if (this.show) {
      return '#a4d313';
    } else {
      return '#4c4d4f';
    }
  }

  showPassword() {
    this.show = true;
    this.input.nativeElement.type = 'text';
  }

  hidePassword() {
    this.show = false;
    this.input.nativeElement.type = 'password';
  }
}
