import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdditionalFieldValue } from '../../../../modules/osp-ui/rich-components/bh-additional-field/additional-field';

@Component({
  selector: 'bh-additional-field-view',
  templateUrl: './additional-field-view.component.html',
  styleUrls: ['./additional-field-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalFieldViewComponent {
  @Input() field: AdditionalFieldValue;
}
