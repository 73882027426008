import { Component, ViewEncapsulation } from '@angular/core';

// TODO: Move to OSP_UI module
@Component({
  selector: 'bh-mat-option-hint',
  templateUrl: './mat-option-hint.component.html',
  styleUrls: ['./mat-option-hint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatOptionHintComponent {
}
