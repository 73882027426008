import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Pageable } from '../../../../../shared/contract/task/pageable.interface';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { PagedResponse } from '../../../../../shared/contract/page-response.interface';
import { PageEvent } from '@angular/material/paginator';
import { UnassignMaintenanceRuleCommand } from '../../../rules/shared/contract/commands/unassign-maintenance-rule.command';
import { MaintenanceTaskSearch, MaintenanceTaskView } from '../maintenance-task';
import { MaintenanceTaskService } from './maintenance-task.service';
import { MaintenanceRuleService } from '../../../rules/shared/service/maintenance-rule-service';
import { DeleteReminderCommand } from '../../../../equipment/contract/delete-reminder-command';
import { CompleteReminderCommand } from '../../../../equipment/contract/complete-reminder-command';
import { MaintenanceFiltersService } from './maintenance-filters.service';
import { UpdateFilterCommand } from '../../../../../shared/contract/filter/update-filter-command.interface';
import _ from 'lodash';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { MaintenanceColumnService } from './maintenance-column.service';
import { MaintenanceRequestParams } from '../contract/request-params/maintenance-request-params.interface';
import { Sort } from '@angular/material/sort';
import { manualSortString, sortToSortString } from './maintenance-task-sort.utils';
import { MaintenanceExportRequestParams } from '../../../../../shared/contract/request-params/maintenance-export-request-params.interface';
import { Router } from '@angular/router';
import { MaintenanceMediatorReceiverTaskCompleted, MaintenanceMediatorReceiverTaskDeleted } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator-receiver.interface';
import { MaintenanceDatasourceMediatorService } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator.service';
import { CompleteMaintenanceTaskCommand } from '../contract/commands/complete-maintenance-task.command';

type MaintenanceMediatorReceiver = MaintenanceMediatorReceiverTaskCompleted & MaintenanceMediatorReceiverTaskDeleted;
import { SetDueDateCommand } from '../contract/commands/set-due-date.command';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';

type RefreshOnChanges = Partial<{
  unselectCurrent: boolean;
  refreshCurrent: boolean;
}>

@Injectable()
export class MaintenanceTaskDataSource
  extends DataSource<MaintenanceTaskSearch>
  implements Pageable, MaintenanceMediatorReceiver {

  private _currentTask = new BehaviorSubject<MaintenanceTaskView>(null);
  public readonly currentTask = this._currentTask.asObservable();

  protected _sort: Sort;
  protected _term: string;

  private initialRequest = true;
  private shouldSelectDefaultTask = false;
  private tasks: BehaviorSubject<MaintenanceTaskSearch[]> = new BehaviorSubject([]);
  private index: BehaviorSubject<number> = new BehaviorSubject(0);
  private size: BehaviorSubject<number> = new BehaviorSubject(25);
  private length: BehaviorSubject<number> = new BehaviorSubject(0);
  private dataRequest = new Subject<MaintenanceRequestParams>();
  private readonly baseListPath = '/maintenance/tasks/maintenance-list';
  private readonly requiredSearchColumns = [
    MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME,
    GENERAL_COLUMN_DEF.EQUIPMENT_NAME,
    GENERAL_COLUMN_DEF.EQUIPMENT_MODEL,
    GENERAL_COLUMN_DEF.EQUIPMENT_SERIAL_NUMBER,
    GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER,
  ];
  private searchColumns: string[] = [...this.requiredSearchColumns];

  constructor(
    private maintenanceTaskService: MaintenanceTaskService,
    private maintenanceRuleService: MaintenanceRuleService,
    private maintenanceFiltersService: MaintenanceFiltersService,
    private maintenanceColumnService: MaintenanceColumnService,
    private router: Router,
    maintenanceMediator: MaintenanceDatasourceMediatorService,
  ) {
    super();
    maintenanceMediator.addReceiver(this);
    this.initListeners();
    this.maintenanceColumnService.pageSize.subscribe((pageSize: number) => this.size.next(pageSize));
  }

  public connect(): Observable<MaintenanceTaskSearch[]> {
    return this.tasks;
  }

  public disconnect(): void {
  }

  public get term(): string {
    return this._term;
  }
  public set term(searchTerm: string) {
    this._term = searchTerm;
  }

  public get sort() {
    return this._sort;
  }

  public set sort(sort: Sort) {
    this._sort = sort;
  }

  public refreshOnChanges(params: RefreshOnChanges = { unselectCurrent: false, refreshCurrent: false }) {
    const { unselectCurrent, refreshCurrent } = params;
    if (!this.initialRequest) {
      if (unselectCurrent) {
        this._currentTask.next(null);
        this.shouldSelectDefaultTask = true;
        this.router.navigate([this.baseListPath]);
      } else if (refreshCurrent) {
        this.changeCurrentTask(this._currentTask.getValue()?.id);
      }

      this.loadTasks();
      this.maintenanceFiltersService.updateFilters();
    }
  }

  public loadTasks(page = this.index.value): void {
    this.index.next(page);
    this.dataRequest.next(this.defineMaintenanceCompletedParams(page, this.size.value));
  }

  public getIndex(): Observable<number> {
    return this.index.asObservable();
  }

  public getSize(): Observable<number> {
    return this.size.asObservable();
  }
  public getLength(): Observable<number> {
    return this.length.asObservable();
  }

  public resetPageIndex(): void {
    this.index.next(0);
  }

  public changeCurrentTask(taskId: string): void {
    if (taskId) {
      this.maintenanceTaskService.getMaintenanceView(taskId)
        .subscribe(task => this._currentTask.next(task));
    } else {
      this._currentTask.next(null);
    }
  }

  public selectCurrentOrDefault(): void {
    if (this._currentTask.value?.id) {
      this.router.navigate([this.baseListPath, this._currentTask.value.id]);
    } else {
      this.selectDefaultTask();
    }
  }

  private initListeners(): void {
    this.dataRequestListener();
    this.initColumnListener();
  }

  private defineMaintenanceCompletedParams(page: number, size: number): MaintenanceRequestParams {
    return {
      size,
      page: page || 0,
      sort: this.getSortRequest(),
      term: this.term,
      searchColumns: this.term ? this.searchColumns : null,
      ...this.maintenanceFiltersService.getFilterParams()
    };
  }

  private dataRequestListener(): void {
    combineLatest([
      this.dataRequest,
      this.maintenanceFiltersService.isReady,
    ])
    .pipe(
      tap(([params, isReady]) => this.initialRequest = !isReady ? true : this.initialRequest),
      filter(([params, isReady]) => Boolean(isReady)),
      map(([params, isReady]) => !this.initialRequest ? params : { ...params, ...this.maintenanceFiltersService.getFilterParams() }),
      switchMap(params => this.maintenanceTaskService.getTasks(params)))
    .subscribe((response: PagedResponse<MaintenanceTaskSearch>) => {
      this.initialRequest = false;
      this.updateStoreData(response);
    });
  }

  public updateFilters(): void {
    this.maintenanceFiltersService.updateFilters();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.maintenanceFiltersService.updateFilterParams(commands);
  }

  public completeTask(command: CompleteMaintenanceTaskCommand): Observable<string> {
    return this.maintenanceTaskService.completeTask(command)
      .pipe(tap(() => this.taskCompleted(command.maintenanceTaskId)));
  }

  public taskCompleted(taskId: string): void {
    this.removeTaskFromStore(taskId);
  }

  public taskDeleted(taskId: string): void {
    this.removeTaskFromStore(taskId);
  }

  private removeTaskFromStore(taskId: string): void {
    if (this._currentTask.getValue()?.id === taskId) {
      this.changeCurrentTask(null);
      this.shouldSelectDefaultTask = true;
    }

    const index = (this.tasks.getValue() || []).findIndex(({ id }) => taskId === id);
    if (index > -1) {
      const newData = [...this.tasks.getValue()];
      newData.splice(index, 1);
      this.tasks.next(newData);
    }
  }

  private updateStoreData(response: PagedResponse<MaintenanceTaskSearch>): void {
    this.length.next(response.totalElements);
    this.tasks.next(response.content);
    if (this.shouldSelectDefaultTask) {
      this.shouldSelectDefaultTask = false;
      this.selectCurrentOrDefault();
    }
  }

  public handlePageEvent(event: PageEvent) {
    if (event.pageSize !== this.size.getValue()) {
      this.maintenanceColumnService.selectPageSize(event.pageSize);
    }
    this.index.next(event.pageIndex);
    this.size.next(event.pageSize);
    this.loadTasks();
  }

  public unassignRule(command: UnassignMaintenanceRuleCommand): void {
    this.maintenanceRuleService.unassignRule(command)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.refreshOnChanges({ unselectCurrent: true }));
  }

  public completeReminder(command: CompleteReminderCommand): void {
    this.maintenanceTaskService.completeReminder(command)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.refreshOnChanges({ unselectCurrent: true }));
  }

  public deleteReminder(command: DeleteReminderCommand): void {
    this.maintenanceTaskService.deleteReminder(command)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.refreshOnChanges({ unselectCurrent: true }));
  }

  public resetSearchTerm(): void {
    this.resetPageIndex();
    this.term = null;
  }

  public setDueDate(command: SetDueDateCommand, updateListing = true): Observable<string> {
    return this.maintenanceTaskService.setDueDate(command)
      .pipe(
        delay(environment.DELAY_SHORTEST),
        switchMap(result => {
          if (updateListing) {
            this.refreshOnChanges({ refreshCurrent: this._currentTask.getValue()?.id === command.maintenanceTaskId });
          }
          return of(result);
        }));
  }

  private getSearchColumns(currentSelectedColumns: string[]): string[] {
    const columns = [...this.requiredSearchColumns, ...currentSelectedColumns];
    return [...new Set(columns)];
  }

  private initColumnListener(): void {
    this.maintenanceColumnService.selectedColumns.pipe(
        distinctUntilChanged(_.isEqual),
        map((columns: ColumnDefinition[]) => columns.map(c => c.cdkColumnDef)),
        tap((currentlySelectedColumns: string[]) => this.searchColumns = this.getSearchColumns(currentlySelectedColumns)),
        skip(1))
    .subscribe(() => this.loadTasks());
  }

  private selectDefaultTask(): void {
    this.tasks
    .pipe(
      filter((res: MaintenanceTaskSearch[]) => Boolean(res && res.length > 0 && res[0].id)),
      map((res: MaintenanceTaskSearch[]) => res[0].id),
      take(1))
    .subscribe((taskId: string) => this.router.navigate([this.baseListPath, taskId]));
  }

  public exportMaintenanceList(columns: string[], applyFilter = false, sortBy: string, sortDescending: boolean,
                               timezone: string): Observable<Blob> {
    const params: MaintenanceExportRequestParams = {
      term: this._term,
      columns:  Array.from(new Set<string>(columns)),
      searchColumns:  this.getSearchColumns(columns),
      sort: `${sortBy},${sortDescending ? 'desc' : 'asc'}`,
      zoneId: timezone,
      ...(applyFilter ? this.maintenanceFiltersService.getFilterParams() : {}),
    } as MaintenanceExportRequestParams;
    return this.maintenanceTaskService.exportMaintenanceList(params);
  }

  private getSortRequest(): any {
    if (this._sort?.active === 'currentAddress' || this._sort?.active === 'liveAddress') {
      return [
        manualSortString(`${this._sort.active}.street`, this._sort.direction),
        manualSortString(`${this._sort.active}.city`, this._sort.direction),
      ];

    } else if (this._sort?.active === 'assignedOwner'
      || this._sort?.active === 'assignedMaintenanceAssignee'
      || this._sort?.active === 'assignedPersonInCharge'
      || this._sort?.active === 'assignedDriver'
      || this._sort?.active === 'assignedPossessor') {
      return [
        manualSortString(`${this._sort.active}.firstName`, this._sort.direction),
        manualSortString(`${this._sort.active}.name`, this._sort.direction),
      ];

    } else if (this._sort?.direction && this._sort?.active === 'equipmentConstructionYear') {
      // construction year is saved as object containing year and month
      return [
        `${this._sort.active}.year,${this._sort.direction}`,
        `${this._sort.active}.month,${this._sort.direction}`
      ];

    } else if (this._sort?.active === 'status') {
      return manualSortString('equipmentStatus.rank', this._sort.direction, false);

    } else if (this._sort?.active === 'maintenanceCategory' || this._sort?.active === 'icon') {
      return manualSortString('maintenanceCategory', this._sort.direction, false);

    } else if (this._sort?.active === 'telematic') {
      // sort by assigned telematics and last update date
      return [
        manualSortString('assignedTelematicUnits', this._sort.direction, false),
        manualSortString('lastTelematicsUpdate', this._sort.direction, false),
      ];

    } else if (this._sort) {
      return sortToSortString(this._sort);
    }
  }
}
