import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEquipmentListComponent } from '../base/base-equipment-list-component';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../shared/equipments.service';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchEquipment } from '../../../contract/search-equipment.interface';
import { RouterHistory } from '../../../../../shared/router-history';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LanguageService } from 'app/shared/services/language.service';
import { EquipmentStatusCategoryPipe } from '../../../../../shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-list',
  templateUrl: './mobile-equipment-list.component.html',
  styleUrls: ['./mobile-equipment-list.component.scss']
})
export class MobileEquipmentListComponent extends BaseEquipmentListComponent implements OnInit, AfterViewInit {

  public equipments: SearchEquipment[];
  public isLoading = false;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              public equipmentStore: EquipmentsDataSource,
              protected equipmentService: EquipmentsService,
              protected userConfigurationService: UserConfigurationService,
              protected organisationService: OrganisationsService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
              protected equipmentStatusNamePipe: EquipmentStatusNamePipe) {
    super(authService,
       router,
       route,
       equipmentStore,
       equipmentService,
       userConfigurationService,
       organisationService,
       routerHistory,
       languageService,
       equipmentStatusCategoryPipe,
       equipmentStatusNamePipe
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.buildSearchForm();
    this.equipmentStore.equipments
      .pipe(untilDestroyed(this))
      .subscribe((equipments) => {
        this.isLoading = false;
        this.equipments = equipments;
      });
  }

  public ngAfterViewInit(): void {
    this.onSearchFormType();
  }

  public buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl('')
    });

    this.setSearchTerms();
  }

  public onScroll(): void {
    if (this.equipmentStore.lastEquipment) {
      return;
    }
    this.getEquipments();
  }

  public navigateToMap(): void {
    this.router.navigate(['mobile/equipments/map']);
  }

  public navigateToCreateEquipment(): void {
    const searchTerms = this.searchForm.get('terms').value;
    this.router.navigate(['mobile/equipments/add'], {
      state: {
        name: searchTerms,
        fromEquipmentList: true
      }
    });
  }

  public reset(): void {
    this.equipmentStore.reset();
    this.searchForm.reset();
  }

  public selectEquipmentAndNavigate(equipmentId: string): void {
    this.router.navigate([`mobile/equipments/view/${equipmentId}`]);
  }

  public openScanSearch(): void {
    this.router.navigate([`mobile/equipments/search-scancode`]);
  }

  private getEquipments(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.equipmentStore.pagination.size = this.equipmentStore.pagination.size + 25;
      this.equipmentStore.updateListing(0);
    }
  }
}
