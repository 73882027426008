import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BaseProjectViewCommentsComponent } from '../project-view-comments/base/base-project-view-comments.component';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { KeycloakService } from '../../../../../../core/keycloak';
import { RouterHistory } from '../../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-project-view-description',
  templateUrl: './mobile-project-view-description.component.html',
  styleUrls: ['./mobile-project-view-description.component.scss']
})

export class MobileProjectViewDescriptionComponent extends BaseProjectViewCommentsComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService) {
    super(authService, router, route, dialog, routerHistory, projectStore, languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
