import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  HostListener,
  Renderer2,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  SafeResourceUrl,
  SafeUrl,
  DomSanitizer,
  SafeStyle,
} from '@angular/platform-browser';
import {
  faRotateLeft,
  faArrowsAlt,
  faRotateRight,
  faSearchMinus,
  faSearchPlus,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'bh-gallery-preview',
  templateUrl: './gallery-preview.component.html',
  styleUrls: ['./gallery-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryPreviewComponent implements OnInit, OnDestroy {
  @Input() public images: string[] | SafeResourceUrl[];
  @Input() public arrows: boolean;
  @Input() public arrowsAutoHide: boolean;
  @Input() public fullscreen: boolean;
  @Input() public forceFullscreen: boolean;
  @Input() public closeOnClick: boolean;
  @Input() public closeOnEsc: boolean;
  @Input() public keyboardNavigation: boolean;
  @Input() public autoPlay: boolean;
  @Input() public autoPlayInterval: number;
  @Input() public autoPlayPauseOnHover: boolean;
  @Input() public infinityMove: boolean;
  @Input() public zoom: boolean;
  @Input() public zoomStep: number;
  @Input() public zoomMax: number;
  @Input() public zoomMin: number;
  @Input() public animation: boolean;
  @Input() public rotate: boolean;
  @Input() public download: boolean;
  @Input() public bullets: string;

  @Output() public onOpen = new EventEmitter();
  @Output() public onClose = new EventEmitter();
  @Output() public onActiveChange = new EventEmitter<number>();

  public src: SafeUrl;
  public srcIndex: number;
  public showSpinner = false;
  public positionLeft = 0;
  public positionTop = 0;
  public zoomValue = 1;
  public loading = false;
  public rotateValue = 0;
  public index = 0;

  public closeIcon = faTimesCircle;
  public fullscreenIcon = faArrowsAlt;
  public zoomInIcon = faSearchPlus;
  public zoomOutIcon = faSearchMinus;
  public rotateLeftIcon = faRotateLeft;
  public rotateRightIcon = faRotateRight;

  private isOpen = false;
  private timer;
  private initialX = 0;
  private initialY = 0;
  private initialLeft = 0;
  private initialTop = 0;
  private isMove = false;
  private keyDownListener: Function;

  constructor(
    private sanitization: DomSanitizer,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.arrows && this.arrowsAutoHide) {
      this.arrows = false;
    }
  }

  public ngOnDestroy() {
    if (this.keyDownListener) {
      this.keyDownListener();
    }
  }

  public onLoading(isImageLoading: boolean): void {
    setTimeout(() => {
      if (isImageLoading) {
        this.showSpinner = true;
      } else {
        this.loading = false;
        this.showSpinner = false;
        this.startAutoPlay();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  @HostListener('mouseenter') public onMouseEnter() {
    if (this.arrowsAutoHide && !this.arrows) {
      this.arrows = true;
    }
  }

  @HostListener('mouseleave') public onMouseLeave() {
    if (this.arrowsAutoHide && this.arrows) {
      this.arrows = false;
    }
  }

  public onKeyDown(e) {
    if (this.isOpen) {
      if (this.keyboardNavigation) {
        if (this.isKeyboardPrev(e)) {
          this.showPrev();
        } else if (this.isKeyboardNext(e)) {
          this.showNext();
        }
      }
      if (this.closeOnEsc && this.isKeyboardEsc(e)) {
        this.close();
      }
    }
  }

  public open(index: number): void {
    this.onOpen.emit();

    this.index = index;
    this.isOpen = true;
    this.show(true);

    if (this.forceFullscreen) {
      this.manageFullscreen();
    }

    this.keyDownListener = this.renderer.listen('window', 'keydown', (e) =>
      this.onKeyDown(e)
    );
  }

  public close(): void {
    this.isOpen = false;
    this.closeFullscreen();
    this.onClose.emit();

    this.stopAutoPlay();

    if (this.keyDownListener) {
      this.keyDownListener();
    }
  }

  public imageMouseEnter(): void {
    if (this.autoPlay && this.autoPlayPauseOnHover) {
      this.stopAutoPlay();
    }
  }

  public imageMouseLeave(): void {
    if (this.autoPlay && this.autoPlayPauseOnHover) {
      this.startAutoPlay();
    }
  }

  public startAutoPlay(): void {
    if (this.autoPlay) {
      this.stopAutoPlay();

      this.timer = setTimeout(() => {
        if (!this.showNext()) {
          this.index = -1;
          this.showNext();
        }
      }, this.autoPlayInterval);
    }
  }

  public stopAutoPlay(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  public showAtIndex(index: number): void {
    this.index = index;
    this.show();
  }

  public showNext(): boolean {
    if (this.canShowNext()) {
      this.index++;

      if (this.index === this.images.length) {
        this.index = 0;
      }

      this.show();
      return true;
    } else {
      return false;
    }
  }

  public showPrev(): void {
    if (this.canShowPrev()) {
      this.index--;

      if (this.index < 0) {
        this.index = this.images.length - 1;
      }

      this.show();
    }
  }

  public canShowNext(): boolean {
    if (this.loading) {
      return false;
    } else if (this.images) {
      return this.infinityMove || this.index < this.images.length - 1
        ? true
        : false;
    } else {
      return false;
    }
  }

  public canShowPrev(): boolean {
    if (this.loading) {
      return false;
    } else if (this.images) {
      return this.infinityMove || this.index > 0 ? true : false;
    } else {
      return false;
    }
  }

  public manageFullscreen(): void {
    if (this.fullscreen || this.forceFullscreen) {
      const doc = <any>document;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        this.openFullscreen();
      } else {
        this.closeFullscreen();
      }
    }
  }

  public zoomIn(): void {
    if (this.canZoomIn()) {
      this.zoomValue += this.zoomStep;

      if (this.zoomValue > this.zoomMax) {
        this.zoomValue = this.zoomMax;
      }
    }
  }

  public zoomOut(): void {
    if (this.canZoomOut()) {
      this.zoomValue -= this.zoomStep;

      if (this.zoomValue < this.zoomMin) {
        this.zoomValue = this.zoomMin;
      }

      if (this.zoomValue <= 1) {
        this.resetPosition();
      }
    }
  }

  public rotateLeft(): void {
    this.rotateValue -= 90;
  }

  public rotateRight(): void {
    this.rotateValue += 90;
  }

  public getTransform(): SafeStyle {
    return this.sanitization.bypassSecurityTrustStyle(
      'scale(' + this.zoomValue + ') rotate(' + this.rotateValue + 'deg)'
    );
  }

  public canZoomIn(): boolean {
    return this.zoomValue < this.zoomMax ? true : false;
  }

  public canZoomOut(): boolean {
    return this.zoomValue > this.zoomMin ? true : false;
  }

  public canDragOnZoom() {
    return this.zoom && this.zoomValue > 1;
  }

  public mouseDownHandler(e): void {
    if (this.canDragOnZoom()) {
      this.initialX = this.getClientX(e);
      this.initialY = this.getClientY(e);
      this.initialLeft = this.positionLeft;
      this.initialTop = this.positionTop;
      this.isMove = true;

      e.preventDefault();
    }
  }

  public mouseUpHandler(): void {
    this.isMove = false;
  }

  public mouseMoveHandler(e) {
    if (this.isMove) {
      this.positionLeft =
        this.initialLeft + (this.getClientX(e) - this.initialX);
      this.positionTop = this.initialTop + (this.getClientY(e) - this.initialY);
    }
  }

  private getClientX(e): number {
    return e.touches && e.touches.length ? e.touches[0].clientX : e.clientX;
  }

  private getClientY(e): number {
    return e.touches && e.touches.length ? e.touches[0].clientY : e.clientY;
  }

  private resetPosition() {
    if (this.zoom) {
      this.positionLeft = 0;
      this.positionTop = 0;
    }
  }

  private isKeyboardNext(e): boolean {
    return e.keyCode === 39 ? true : false;
  }

  private isKeyboardPrev(e): boolean {
    return e.keyCode === 37 ? true : false;
  }

  private isKeyboardEsc(e): boolean {
    return e.keyCode === 27 ? true : false;
  }

  private openFullscreen(): void {
    const element = <any>document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  }

  private closeFullscreen(): void {
    if (this.isFullscreen()) {
      const doc = <any>document;

      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
    }
  }

  private isFullscreen() {
    const doc = <any>document;

    return (
      doc.fullscreenElement ||
      doc.webkitFullscreenElement ||
      doc.mozFullScreenElement ||
      doc.msFullscreenElement
    );
  }

  private show(first = false) {
    this.loading = true;
    this.stopAutoPlay();

    this.onActiveChange.emit(this.index);

    setTimeout(() => this._show(), first || !this.animation ? 0 : 600);
  }

  private _show() {
    this.zoomValue = 1;
    this.rotateValue = 0;
    this.resetPosition();

    const newSrc = <string>this.images[this.index];
    if (newSrc === this.src) {
      this.loading = false;
      this.startAutoPlay();
    } else {
      this.src = <string>this.images[this.index];
      this.srcIndex = this.index;
    }
    this.changeDetectorRef.markForCheck();
  }
}
