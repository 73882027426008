export enum TransferItemUnit {
  PIECE = 'PIECE',
  METER = 'METER',
  CENTIMETER = 'CENTIMETER',
  KILOGRAM = 'KILOGRAM',
  GRAM = 'GRAM',
  LITER = 'LITER',
  MILLILITER = 'MILLILITER',
  TONNE = 'TONNE',
  CUBIC_METER = 'CUBIC_METER',
  PACKAGE = 'PACKAGE'
}
