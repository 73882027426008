<div class="add-label-dialog">
<h1 mat-dialog-title>{{'modules.equipment.equipmentAddLabelDialog.addLabels'|translate}}</h1>
<mat-dialog-content #scrollRepo>
         <div class="label-field">
           <bh-label-chips (labelAdded)="addLabel($event)"
                           (labelDeleted)="deleteLabel($event)"
                           [customerLabels]="customerLabels | async"
                           [hasAuthority]="hasAuthority"
                           [labels]="equipmentLabels | async">
          </bh-label-chips>
         </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" mat-dialog-close="abort">{{'general.buttons.cancel'|translate}}</button>
  <button type="button"
          mat-button
          bhTimeoutSaveButton
          color="primary"
          (throttledClick)="save()"
          [disabled]="disable()">{{'general.buttons.save'|translate}}</button>
</mat-dialog-actions>
</div>
