export enum LifeCycleType {
  OPERATING_HOURS_UPDATE = 'OPERATING_HOURS_UPDATE',
  MILEAGE_UPDATE = 'MILEAGE_UPDATE',
  EQUIPMENT_DAMAGE_EVENT = 'EQUIPMENT_DAMAGE_EVENT',
  EQUIPMENT_COST_EVENT = 'EQUIPMENT_COST_EVENT',
  EQUIPMENT_INCIDENT_EVENT = 'EQUIPMENT_INCIDENT_EVENT',
  COMPLETED_MAINTENANCE_TASK = 'COMPLETED_MAINTENANCE_TASK',
  COMPLETED_REMINDER = 'COMPLETED_REMINDER',
  EMPLOYEE_ASSIGNMENT_ADDED_EVENT = 'EMPLOYEE_ASSIGNMENT_ADDED_EVENT',
  EMPLOYEE_ASSIGNMENT_REPLACED_EVENT = 'EMPLOYEE_ASSIGNMENT_REPLACED_EVENT',
  EMPLOYEE_ASSIGNMENT_REMOVED_EVENT = 'EMPLOYEE_ASSIGNMENT_REMOVED_EVENT'
}
