<div fxFlex fxLayout="column" class="wrapper-container">
  <div class="container-fields" fxFlex fxLayout="row">
    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.transportation.base.identifier'|translate}}</mat-label>
      <input matInput
        readonly
        [value]="transport?.identifier | defaultValue : emptyValue">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.transportation.base.title'|translate}}</mat-label>
      <input matInput
        readonly
        [value]="transport?.title | defaultValue : emptyValue">
    </mat-form-field>
    <mat-form-field floatLabel="always" [ngClass]="{ 'form-field-warning': !isCompatibleTransportType }">
      <mat-label>{{'modules.transportation.base.transportType'|translate}}</mat-label>
      <input matInput
        readonly
        [value]="transport?.transportType | defaultValue : emptyValue">
      <mat-hint align="start" *ngIf="!isCompatibleTransportType">
        {{'modules.transportation.transportationTaskAddEdit.incompatibleTypeHint'|translate}}
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="container-fields" fxFlex fxLayout="row">
    <div class="full-width">
      <label class="label-task-state">{{'general.status'|translate}}</label>
      <bh-transport-state-change class="full-width"
        [state]="transport"
        [disabled]="!(isStateEditable | async)"
        (selectNewStatus)="updateTransportTaskState($event)"
        (hasDropdownOptions)="updateHasStateChangeOptions($event)"
      ></bh-transport-state-change>
    </div>

    <mat-form-field floatLabel="always" class="field__priority" [ngClass]="{'exclude-disable-style': isPriorityReadonly}">
      <mat-label>{{'modules.transportation.base.priority'|translate}}</mat-label>
      <mat-select
        [value]="transport?.priority"
        [disabled]="isPriorityReadonly">
        <mat-select-trigger>
          <bh-transport-priority-badge [priority]="transport?.priority"></bh-transport-priority-badge>
        </mat-select-trigger>
        <mat-option *ngFor="let priority of priorities" [value]="priority">
          <bh-transport-priority-badge [priority]="priority"></bh-transport-priority-badge>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.transportation.base.dueDate'|translate}}</mat-label>
      <input matInput
        readonly
        [value]="transport?.dueDate
          | formatTimezoneDateTime: {dateFormat: 'DD MMM YYYY', showTime: true, hideEndOfDayTime: true }
          | defaultValue : emptyValue"
        [ngClass]="{'due-date-time-passed': (transport | isTransportDueDatePassed)}">
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.transportation.base.estimatedDuration'|translate}}</mat-label>
      <input matInput
        readonly
        [value]="transport?.estimatedDuration | formatDuration | defaultValue : emptyValue">
    </mat-form-field>
  </div>

  <div class="container-fields">
    <mat-form-field>
      <mat-label>{{'general.description'|translate}}</mat-label>
      <textarea matInput
        readonly
        [value]="transport?.text | defaultValue : emptyValue"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </div>

  <div class="container-fields" fxFlex fxLayout="row" fxLayoutAlign="space-between start">
    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.transportation.base.assignedVehicle'|translate}}</mat-label>
      <input matInput
        readonly
        [value]="transport?.equipmentAssignmentDetails | transportViewAssignmentTitle | defaultValue : emptyValue">
        <span matSuffix *ngIf="transport?.equipmentAssignmentDetails as assignment">
          <mat-icon class="icon-navigation"
            *ngIf="hasAnyAuthority([authorities.EQUIPMENT_VIEW, authorities.PROJECT_ASSIGNEE_VIEW])
              && isUserOrganisationId(assignment.organisationId) && !assignment.isDeleted"
            (click)="navigateToEquipment(assignment.equipmentId)"
            [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.vehicle.s'|translate }">
            open_in_new
          </mat-icon>
        </span>
    </mat-form-field>

    <div fxFlex="100%" fxLayout="column">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.transportation.base.assignmentStartDate'|translate}}</mat-label>
        <input matInput
          readonly
          [value]="transport?.equipmentAssignmentDetails?.startDate | formatTimezoneDateTime: { dateFormat: 'DD MMM YYYY', showTime: true } | defaultValue : emptyValue">
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.transportation.base.actualTransportStart'|translate}}</mat-label>
        <input matInput
          readonly
          [value]="transport?.equipmentAssignmentDetails?.executionStartDate | formatTimezoneDateTime: { dateFormat: 'DD MMM YYYY', showTime: true } | defaultValue : emptyValue">
      </mat-form-field>
    </div>

    <div fxFlex="100%" fxLayout="column">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.transportation.base.assignmentEndDate'|translate}}</mat-label>
        <input matInput
          readonly
          [value]="transport?.equipmentAssignmentDetails?.endDate | formatTimezoneDateTime: { dateFormat: 'DD MMM YYYY', showTime: true } | defaultValue : emptyValue">
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.transportation.base.actualTransportEnd'|translate}}</mat-label>
        <input matInput
          readonly
          [value]="transport?.equipmentAssignmentDetails?.executionEndDate | formatTimezoneDateTime: { dateFormat: 'DD MMM YYYY', showTime: true } | defaultValue : emptyValue">
      </mat-form-field>
    </div>
  </div>

  <div class="container-fields" fxFlex fxLayout="row">
    <div fxFlexLayout="column" fxFlex="48%">
      <ng-container
        [ngTemplateOutlet]="transportItem"
        [ngTemplateOutletContext]="transport | transportContextItem
          : transportItemLocationType.START
          : isUserOrganisationId(transport?.startItem?.organisationId, transport?.startItem)">
      </ng-container>

      <ng-container
        [ngTemplateOutlet]="transportItem"
        [ngTemplateOutletContext]="transport | transportContextItem
          : transportItemLocationType.TARGET
          : isUserOrganisationId(transport?.targetItem?.organisationId, transport?.targetItem)">
      </ng-container>

      <ng-container
        [ngTemplateOutlet]="transportItem"
        [ngTemplateOutletContext]="transport | transportContextProject
          : isUserOrganisationId(transport?.projectInfo?.organisationId)">
      </ng-container>
    </div>
    <bh-map-locations
      class="items-location"
      fxFlex="48%"
      [locations]="transport | mapMarkersConvertTransportView">
    </bh-map-locations>
  </div>
</div>


<ng-template #transportItem
  let-locationType="locationType"
  let-itemType="itemType"
  let-name="name"
  let-address="address"
  let-navigationInfo="navigationInfo"
  let-entityType="entityType"
  let-state="state">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="transport-item">
    <bh-icon-resolver *ngIf="resolveIconTransportItem(itemType, entityType) as iconPayload"
      class="icon-label"
      [iconPayload]="iconPayload"
      matPrefix></bh-icon-resolver>

    <div fxFlex="100%" fxLayout="column" class="text-container">
      <label>{{getTransportItemPlaceholder(locationType)}}</label>
      <div fxLayout="column" class="text-container__lines">
        <span [attr.data-osp-test]="'field-address-name-'+locationType">{{name | defaultValue : emptyValue}}</span>
        <span [attr.data-osp-test]="'field-address-'+locationType">{{address | defaultValue : emptyValue}}</span>
      </div>
    </div>

    <ng-container *ngIf="navigationInfo?.url && canSeeLink(itemType)">
      <mat-icon class="icon-navigation"
        (click)="navigateTo(navigationInfo.url)"
        [matTooltip]="getTooltipLink(itemType)">
        open_in_new
      </mat-icon>
      <mat-icon class="icon-info"
        *ngIf="navigationInfo.isLatestItem"
        [matTooltip]="getTooltipLatestItemInfo(state, itemType)">
        info
      </mat-icon>
    </ng-container>
  </div>
</ng-template>

