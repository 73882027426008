import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core';
import { TransportItem, TransportItemTyped } from 'app/modules/equipment/shared/transport-items.interface';
import _ from 'lodash';
import { MapClusteredComponent } from 'app/shared/modules/map/components/map-clustered/map-clustered.component';
import { MapLocationMarker } from 'app/shared/modules/map/interfaces/map-location-marker.interface';
import { MapInfoTransportLocationTypeComponent } from 'app/shared/modules/map/components/map-info-window/map-info-transport-location-type/map-info-transport-location-type.component';


interface MapTransportLocationSelectionSimpleChanges {
  locations?: SimpleChange;
  displayInfo?: SimpleChange;
  selectedItems?: SimpleChange;
}

@Component({
  selector: 'bh-transport-location-map-selection',
  templateUrl: './transport-location-map-selection.component.html',
  styleUrls: ['./transport-location-map-selection.component.scss']
})
export class TransportLocationMapSelectionComponent implements OnChanges, AfterViewInit {

  @ViewChild(MapClusteredComponent) map: MapClusteredComponent;
  @Input() locations: TransportItem[] = [];
  @Input() displayInfo: TransportItemTyped[] = [];
  @Input() selectedItems: TransportItem[] = [];
  @Output() selected = new EventEmitter<TransportItem>();

  public readonly defaultZoom = 6;
  private readonly selectionMapZoom = 15;
  private googleMap: google.maps.Map;
  private isMapAPILoaded = false;
  private isMapViewInitialized = false;
  private prevSelectedItemIds = new Set<string>();

  private get isReady(): boolean {
    return this.isMapAPILoaded && this.isMapViewInitialized;
  }

  private get currentZoom(): number {
    return this.googleMap ? this.googleMap.getZoom() : this.defaultZoom;
  }

  public ngAfterViewInit(): void {
    this.isMapViewInitialized = true;
    this.applyInputs();
  }

  public onMapInitialized(map: google.maps.Map): void {
    this.googleMap = map;
    this.isMapAPILoaded = true;
    this.applyInputs();
  }

  public ngOnChanges(changes: MapTransportLocationSelectionSimpleChanges): void {
    if (this.isReady && changes.displayInfo) {
      this.displayInfoItemOnMap(changes.displayInfo.currentValue);
    }
    if (this.isReady && changes.selectedItems) {
      this.moveToSelectedItems(changes.selectedItems.currentValue);
    }
  }

  public onMarkerClick(marker: MapLocationMarker<TransportItem>): void {
    this.moveToSelectedItems([marker.item], this.currentZoom);
    this.selected.emit(marker.item);
  }

  private applyInputs(): void {
    if (this.isReady) {
      this.displayInfoItemOnMap(this.displayInfo);
      this.moveToSelectedItems(this.selectedItems);
    }
  }

  private displayInfoItemOnMap(items: TransportItemTyped[]): void {
    this.closeAllWindows();
    if (items?.length > 0) {
      this.openInfoWindows(items);
    }
  }

  private moveToSelectedItems(selectedItems: TransportItem[], singleItemZoom = this.selectionMapZoom): void {
    const items = (selectedItems || []);
    if (!this.isSameSelectedItems(items)) {
      this.saveMoveToItem(items);
      this.moveTo(items, singleItemZoom);
    }
  }

  private isSameSelectedItems(items: TransportItem[]): boolean {
    return items.length === this.prevSelectedItemIds.size &&
      items.every(({ id }) => this.prevSelectedItemIds.has(id));
  }

  private saveMoveToItem(items: TransportItem[]): void {
    this.prevSelectedItemIds = new Set(items.map(({ id }) => id));
  }

  private moveTo(items: TransportItem[], singleItemZoom: number): void {
    if (items.length === 0) {
      this.map.setDefaultPosition();
    } else if (items.length === 1) {
      this.map.moveTo(items[0].location, singleItemZoom);
    } else {
      this.map.fitBounds(items);
    }
  }

  private closeAllWindows(): void {
    this.map.closeAllWindows();
  }

  private openInfoWindows(items: TransportItemTyped[]): void {
    this.map.openInfoWindows(this.convertToMarkerContent(items));
  }

  private convertToMarkerContent(items: TransportItemTyped[]): MapLocationMarker<TransportItemTyped>[] {
    return items.map(item => ({
      id: item.id,
      infoWindow: { content: MapInfoTransportLocationTypeComponent, isOpened: true },
      location: item.location,
      item
    }));
  }

}
