import { WidgetDefinition } from '../widget-definition';
import { FleetCompositionWidgetComponent } from './fleet-composition-widget.component';
import { WidgetConfigParams } from '../contract/widget-config-param';
import { WidgetType } from '../contract/widget-type.enum';

export class FleetCompositionWidgetConfiguration extends WidgetDefinition {
  organisationIds: string[] = [];
  equipmentTypeCategories: string[] = [];

  constructor({ title }: WidgetConfigParams) {
    super();
    this.widgetType = WidgetType.FLEET_COMPOSITION;
    this.type = FleetCompositionWidgetComponent;
    this.title = title;
    this.minItemCols = 7;
    this.minItemRows = 5;
    this.cols = 13;
    this.rows = 8;
    this.x = 0;
    this.y = 0;
  }
}
