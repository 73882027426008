import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentAlarmRuleSelectionType } from '../../modules/notifications/contract/equipment-alarm-rule-selection-type.enum';
import { AlarmType } from '../../modules/notifications/shared/enums/alarm-type.enum';

@Pipe({
  name: 'alarmSelectionType'
})
export class AlarmSelectionTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(value: string, type?: AlarmType): string {
    switch (value) {
      case EquipmentAlarmRuleSelectionType.LABEL:
        return this.translate('shared.pipes.alarmSelectionType.labels');
      case EquipmentAlarmRuleSelectionType.EQUIPMENT_TYPE:
        return this.translate('shared.pipes.alarmSelectionType.equipmentTypes');
      case EquipmentAlarmRuleSelectionType.ALL_EQUIPMENT:
        if (AlarmType.INACTIVE_TELEMATICS_UNIT === type) {
          return this.translate('shared.pipes.alarmSelectionType.allEquipmentsWithTelematicsUnit');
        } else {
          return this.translate('shared.pipes.alarmSelectionType.allEquipments');
        }
      default:
        return this.translate('shared.pipes.alarmSelectionType.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
