import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

enum OspUITheme {
  DEFAULT = 'osp-ui-theme-default',
  WN = 'osp-ui-theme-wn',
  TR = 'osp-ui-theme-tr',
}

@Injectable()
export class OspThemeSwitcherService {
  private attrName = 'data-osp-theme';

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.setThemeDefault();
  }

  public setThemeDefault(): void {
    this.setTheme(OspUITheme.DEFAULT);
  }

  public setThemeWN(): void {
    this.setTheme(OspUITheme.WN);
  }

  public setThemeTR(): void {
    this.setTheme(OspUITheme.TR);
  }

  private setTheme(theme: OspUITheme): void {
    this.document.body.setAttribute(this.attrName, theme);
  }

}
