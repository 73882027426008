import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CreateStockToProjectTransferCartCommand } from '../contract/create-stock-to-project-transfer-cart.command';
import { Observable } from 'rxjs';
import { CreateProjectToStockTransferCartCommand } from '../contract/create-project-to-stock-transfer-cart-command';
import { CreateProjectToProjectTransferCartCommand } from '../contract/create-project-to-project-transfer-cart.command';
import { CreateStockToStockTransferCartCommand } from '../contract/create-stock-to-stock-transfer-cart-command';
import { CreateProjectToConsumedTransferCartCommand } from '../contract/create-project-to-consumed-transfer-cart-command';
import { Queueable } from '../../../shared/services/queueable.decorator';
import { QueuedActionHandler } from '../../../shared/services/queued-action-handler';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { tap } from 'rxjs/operators';
import { OfflineTransferAmountsService } from '../../../shared/services/offline-transfer-amounts.service';
import { RevertTransferCartCommand } from '../contract/revert-transfer-cart-command';
import { AcknowledgeTransferRequestCommand } from '../contract/acknowledge-transfer-request-command';
import { ITransfer } from '../contract/transfer.interface';
import { TransferRevertStatus } from '../contract/transferRevertStatus';

@Injectable()
export class TransferService extends QueuedActionHandler {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/transfer';
  private urlV2 = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v2/transfer';

  constructor(private http: HttpClient,
              protected onlineStatusService: OnlineStatusService,
              private offlineTransferAmountsService: OfflineTransferAmountsService) {
    super(TransferService.name, onlineStatusService, 500)
  }

  @Queueable()
  public transferStockToProject(command: CreateStockToProjectTransferCartCommand): Observable<ITransfer> {
    return this.http.post<ITransfer>(this.urlV2 + '/stock-to-project', command)
      .pipe(tap( () => {
          this.offlineTransferAmountsService.clearPlannedAmounts();
        }));
  }

  @Queueable()
  public transferProjectToStock(command: CreateProjectToStockTransferCartCommand): Observable<ITransfer> {
    return this.http.post<ITransfer>(this.urlV2 + '/project-to-stock', command)
      .pipe(tap( () => {
        this.offlineTransferAmountsService.clearPlannedAmounts();
      }));
  }

  @Queueable()
  public transferProjectToProject(command: CreateProjectToProjectTransferCartCommand): Observable<ITransfer> {
    return this.http.post<ITransfer>(this.urlV2 + '/project-to-project', command)
      .pipe(tap( () => {
        this.offlineTransferAmountsService.clearPlannedAmounts();
      }));
  }

  @Queueable()
  public transferStockToStock(command: CreateStockToStockTransferCartCommand): Observable<ITransfer> {
    return this.http.post<ITransfer>(this.urlV2 + '/stock-to-stock', command)
      .pipe(tap( () => {
        this.offlineTransferAmountsService.clearPlannedAmounts();
      }));
  }

  @Queueable()
  public transferProjectToConsumed(command: CreateProjectToConsumedTransferCartCommand): Observable<ITransfer> {
    return this.http.post<ITransfer>(this.urlV2 + '/project-to-consumed', command)
      .pipe(tap( () => {
        this.offlineTransferAmountsService.clearPlannedAmounts();
      }));
  }

  public getAllExernalEmployees(): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/external-employees`);
  }

  public transferRevertPossible(transferId: string): Observable<TransferRevertStatus> {
    return this.http.get<TransferRevertStatus>(`${this.url}/revert-possible/${transferId}`);
  }

  public revertTransfer(command: RevertTransferCartCommand): Observable<string> {
    return this.http.post(this.urlV2 + '/revert-transfer', command, { responseType: 'text'});
  }

  public createThumbnailUrl(thumbnailKey: string): string {
    return !!thumbnailKey
      ? environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/bulk-items/thumbnail/' + thumbnailKey
      : null;
  }

  public acknowledgeTransferRequest(command: AcknowledgeTransferRequestCommand): Observable<string> {
    return this.http.post(this.url + '/acknowledge-transfer', command, { responseType: 'text'});
  }

}
