import { TransportItemType } from '../enums/transport-item-type.enum';
import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { TransportItem } from '../../../equipment/shared/transport-items.interface';
import { ContactTypeResolverPipe } from '../../../../shared/pipes/contact-type-resolver.pipe';
import { ContactType } from 'app/modules/contact/shared/enums/contact-type.enum';
import { IconPayload, IconType } from '../../../../shared/contract/icon-payload.interface';
import { StockType } from 'app/shared/enums/stock-type';
import { StockTypeResolver } from 'app/shared/pipes/stock-type.resolver';
import { TransportContextBase } from './transport-context/transport-context-base.class';

@Pipe({
  name: 'transportItemType'
})
export class TransportItemTypeResolverPipe extends TransportContextBase implements PipeTransform {
  private definitionSet = {
    [TransportItemType.CONTACT]: {
      name: this.translate('general.contact.s')
    },
    [TransportItemType.PROJECT]: {
      name: this.translate('general.project.s')
    },
    [TransportItemType.STOCK]: {
      name: this.translate('general.stock.s')
    }
  }

  constructor(private languageService: LanguageService,
    private contactTypeResolverPipe: ContactTypeResolverPipe,
    private stockTypeResolver: StockTypeResolver
  ) {
    super();
  }

  public transform(value: TransportItemType | string): string {
    return this.resolveName(value);
  }

  public resolveName(value: TransportItemType | string): string {
    return (this.definitionSet[value] && this.definitionSet[value].name) || null;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public resolveIcon(option: TransportItem): IconPayload {
    return this.resolveIconByType(option.transportItemType, this.getEntityType(option));
  }

  public resolveIconByType(itemType: TransportItemType, entityType: ContactType | StockType): IconPayload {
    switch (itemType) {
      case TransportItemType.PROJECT:
        return { type: IconType.FONT_SET, icon: 'icon-main_projects02_maps_solid' };
      case TransportItemType.STOCK:
        return { type: IconType.FA, icon: this.stockTypeResolver.resolveIcon(entityType) };
      case TransportItemType.CONTACT:
        return { type: IconType.FA, icon: this.contactTypeResolverPipe.resolveIcon(entityType) };
      default:
        return null;
    }
  }
}
