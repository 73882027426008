import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FloatLabelType } from '@angular/material/form-field';
import { BaseControl } from 'app/modules/osp-ui/directives/host-control/base-control.directive';
import { HostControlDirective } from 'app/modules/osp-ui/directives/host-control/host-control.directive';


@Component({
  selector: 'bh-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    HostControlDirective.hostDirectiveProvider()
  ],
})
export class InputComponent extends BaseControl {
  @HostBinding('class')
  protected cssClass = 'osp-input';

  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() trimInputTextEnabled = true;

}
