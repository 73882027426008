import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContactColumnDefinitionService } from 'app/modules/contact/shared/services/contact-column-definition.service';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { ColumnDefinition } from 'app/shared/column-definition';
import { CONTACT_COLUMN_DEF, GENERAL_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { LanguageService } from 'app/shared/services/language.service';
import { saveAs } from 'file-saver';
import moment from 'moment';

interface SortableField {
  name: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'bh-contact-list-export-dialog',
  templateUrl: './contact-list-export-dialog.component.html',
  styleUrls: ['./contact-list-export-dialog.component.scss']
})
export class ContactListExportDialogComponent implements OnInit {

  public selectAllColumns = false;
  public applyCurrentFilter = true;
  public sortDescending = false;
  public pendingRequest = false;
  public selectedColumns: string[] = [];
  public sortableFields: SortableField[] = [];
  public sortBy: string = GENERAL_COLUMN_DEF.NAME;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { selectedColumns: ColumnDefinition[] },
    private dialogRef: MatDialogRef<ContactListExportDialogComponent>,
    private contactColumnDefinitionService: ContactColumnDefinitionService,
    private contactDataSource: ContactDataSource,
    private languageService: LanguageService
  ) { }

  public ngOnInit(): void {
    this.sortableFields = this.getSortableFields();
    this.setSelectedColumns(this.data?.selectedColumns);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public exportContactList(): void {
    this.pendingRequest = true;
    const columns: string[] = this.selectAllColumns
      ? this.getAllDisplayableColumns()
      : this.selectedColumns.filter(this.isDisplayableColumn);
    this.contactDataSource.exportContactList(columns, this.applyCurrentFilter, this.sortBy, this.sortDescending)
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (data: Blob) => this.saveList(data),
      error: () => this.pendingRequest = false
    });
  }

  private getSortableFields(): SortableField[] {
    return this.contactColumnDefinitionService.availableColumns
      .filter(column => this.isSortableColumn(column))
      .map(({ readableName: name, cdkColumnDef: value }) => ({ name, value }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  private setSelectedColumns(selected: ColumnDefinition[]): void {
    this.selectedColumns = this.convertColumnDefinitions(selected);
  }

  private isSortableColumn({ cdkColumnDef }: ColumnDefinition): boolean {
    return cdkColumnDef === GENERAL_COLUMN_DEF.NAME
      || cdkColumnDef === CONTACT_COLUMN_DEF.TYPE
      || cdkColumnDef === CONTACT_COLUMN_DEF.IDENTIFIER
      || cdkColumnDef === GENERAL_COLUMN_DEF.ORGANISATION;
  }

  private isDisplayableColumn(columnName: string): boolean {
    return columnName
      && columnName !== GENERAL_COLUMN_DEF.OPEN_DETAILS
      && columnName !== GENERAL_COLUMN_DEF.ICON;
  }

  private getAllDisplayableColumns(): string[] {
    return this.convertColumnDefinitions(this.contactColumnDefinitionService.availableColumns);
  }

  private convertColumnDefinitions(columns: ColumnDefinition[]): string[] {
    return columns
      .map(({ cdkColumnDef }) => cdkColumnDef)
      .filter(this.isDisplayableColumn)
  }

  private saveList(data: Blob): void {
    this.pendingRequest = false;
    saveAs(data, this.createFileName());
    this.dialogRef.close();
  }

  private createFileName(): string {
    return this.languageService.getInstant('modules.contact.contactListExport.exportFileName')
      + `_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`;
  }

}
