<div fxFlex class="task-list-component" fxLayout="column" fxLayoutAlign="start stretch">
  <ng-container *ngIf="tasks?.length > 0; else noTasks">
    <div fxFlex class="scrollable-table">

      <table mat-table [dataSource]="tasks">

        <ng-container matColumnDef="icon">
          <th mat-header-cell class="icon-column" *matHeaderCellDef></th>
          <td mat-cell class="icon-column" *matCellDef="let task">
          <span class="maintenance-icon">
            <mat-icon>{{task.category | maintenanceCategory: 'icon'}}</mat-icon>
          </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{'general.name.s'|translate}}</th>
          <td mat-cell *matCellDef="let task">
            <span class="name">{{ task.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="maintenance-type">
          <th mat-header-cell *matHeaderCellDef>{{'general.type'|translate}}</th>
          <td mat-cell *matCellDef="let task">
            <span>{{task.category | maintenanceCategory}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="equipment">
          <th mat-header-cell *matHeaderCellDef>{{'general.equipm.s'|translate}}</th>
          <td mat-cell *matCellDef="let task">
            <span class="name navigation" (click)="navigateToEquipmentTasks(task.equipmentId)">{{ task.equipmentName ? task.equipmentName : task.equipmentModel }}
              <mat-icon class="navigation-icon"
                        [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.equipm.s'|translate}">
                open_in_new
              </mat-icon>
            </span>
          </td>
        </ng-container>

      <ng-container matColumnDef="due-date">
        <th mat-header-cell *matHeaderCellDef>{{'shared.maintenance.task.due'|translate}}</th>
        <td mat-cell *matCellDef="let task">
          <span *ngIf="task.dueDateInfo.dueDate && notMaxDate(task.dueDateInfo.dueDate); else noDate">
            <span *ngIf="task.dueDateInfo.dueDateEstimated">{{'general.circa.abbr'|translate}}</span>
            {{ task.dueDateInfo.dueDate | formatTimezoneDateTime }}
          </span>
          <ng-template #noDate>
            <span [matTooltip]="'shared.maintenance.task.insufficientDataForDate'|translate">--.--.----</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="task-remaining">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let task">
          <bh-task-remaining [dueDateInfo]="task.dueDateInfo"></bh-task-remaining>
        </td>
      </ng-container>

      <ng-container matColumnDef="task-actions">
        <th mat-header-cell class="actions-column" *matHeaderCellDef></th>
        <td mat-cell class="actions-column" *matCellDef="let task">
          <mat-menu #appMenu>
            <div *ngIf="!isCategory(task, MaintenanceCategory.REMINDER)">
              <button mat-menu-item (click)="viewRule(task)" *ngIf="allowedToSee">
                <mat-icon>open_in_new</mat-icon>
                <span>{{'general.details'|translate}}</span>
              </button>
              <button mat-menu-item (click)="completeTask(task.id)" *ngIf="allowedToManage">
                <mat-icon>assignment_turned_in</mat-icon>
                <span>{{'general.buttons.complete'|translate}}</span>
              </button>
              <button mat-menu-item (click)="unassignRule(task, task.maintenanceRuleId)" *ngIf="allowedToManage">
                <mat-icon>delete_forever</mat-icon>
                <span>{{'general.buttons.removeAssignment'|translate}}</span>
              </button>
            </div>
            <div *ngIf="isCategory(task, MaintenanceCategory.REMINDER)">
              <button mat-menu-item (click)="completeReminder(task)" *ngIf="allowedToManage">
                <mat-icon>assignment_turned_in</mat-icon>
                {{'general.buttons.complete'|translate}}
              </button>
              <button mat-menu-item (click)="deleteReminder(task.id)" *ngIf="allowedToManage">
                <mat-icon>delete_forever</mat-icon>
                {{'general.buttons.remove'|translate}}
              </button>
            </div>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="appMenu" *ngIf="showMenu(task)">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.dueDateInfo.warningLevel | lowercase"></tr>
      </table>
    </div>
    <mat-paginator #taskListPaginator
                   *ngIf="tasks.length > 0"
                   [length]="pageable.getLength() | async"
                   [pageIndex]="pageable.getIndex() | async"
                   [pageSize]="pageable.getSize() | async"
                   (page)="pageable.handlePageEvent($event)"
                   [pageSizeOptions]="[5, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </ng-container>

  <ng-template #noTasks>
    <div class="no-tasks" fxLayout="row" fxLayoutAlign="center center">
      <span>{{'shared.maintenance.task.noUpcomingInspectionOrMaintenance'|translate}}</span>
    </div>
  </ng-template>

</div>
