<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{'modules.maintenance.task.alignEquipmentStatus.dialogTitle' | translate}}</h1>

  <div mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10">
      <p>{{'modules.maintenance.task.alignEquipmentStatus.currentMaintenanceResult' | translate}}:</p>
      <bh-maintenance-result-badge [result]="data.maintenanceResult"></bh-maintenance-result-badge>
    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10">
      <p>{{'modules.maintenance.task.alignEquipmentStatus.currentEquipmentStatus' | translate}}:</p>
      <bh-equipment-status-badge [_status]="data.equipmentStatus"></bh-equipment-status-badge>
    </div>

    <div fxFlex="100">
      <p>{{'modules.maintenance.task.alignEquipmentStatus.changeEquipmentStatus' | translate}}:</p>
      <div class="bh-form-field" fxLayout="column">
        <label>{{'general.status'|translate}}</label>
        <div class="underline">
          <div [matMenuTriggerFor]="equipmentStatusDropdown.menu" class="select"
               fxLayout="row" fxLayoutAlign="space-between center">
            <div class="overflow-wrapper">
              <bh-equipment-status-badge *ngIf="newEquipmentStatus; else placeholder"
                                         [_status]="newEquipmentStatus">
              </bh-equipment-status-badge>
              <ng-template #placeholder>
                {{'modules.maintenance.task.alignEquipmentStatus.noSelectedEquipmentStatus' | translate}}
              </ng-template>
            </div>
            <div class="mat-mdc-select-arrow-wrapper">
              <div class="mat-mdc-select-arrow custom-select-arrow"></div>
            </div>
          </div>
        </div>
      </div>

      <bh-equipment-status-dropdown #equipmentStatusDropdown
                                    [equipment]="data.equipment"
                                    [categoryFilter]="getSameCategoryLikeMaintenanceResultFilter()"
                                    (onStatusChange)="onEquipmentStatusChange($event)"
                                    noStatusItemText="{{'modules.maintenance.task.alignEquipmentStatus.noSelectedEquipmentStatus' | translate}}">
      </bh-equipment-status-dropdown>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button mat-button (click)="onCancel()">
      {{'general.buttons.cancel'|translate}}
    </button>

    <button mat-raised-button mat-button color="primary" (click)="onSave()" cdkFocusInitial>
      {{'general.buttons.save'|translate}}
    </button>
  </div>
</div>
