import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

export function dayMonthValidator(group: UntypedFormGroup) {
  if (group) {
    const day = group.get('day').value;
    const month = group.get('month').value ? group.get('month').value.value : -1;

    if (+day === 29 && +month === 2) {
      return {leapYear: true}
    }

    if (day > 31 || day < 1 || month < 1 || month > 12) {
      return {invalidDate: true};
    }

    const date = moment(`${month}-${day}`, 'MM-DD');
    if (!date.isValid()) {
      return {invalidDate: true};
    }
  }

  return null;
}
