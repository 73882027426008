import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { OspDateTime } from '../../../modules/equipment/contract/osp-date-time';
import * as momenttz from 'moment-timezone';
import { TimezoneDatesService } from '../../timezone/timezone-dates.service';
import { TimezoneUtils } from '../../timezone/timezone-utils';
import { TimezoneFormatLocaleDate } from '../../timezone/timezone-format-locale-date.interface';


@Pipe({
  name: 'formatTimezoneDateTime'
})
export class FormatTimezoneDateTimePipe implements PipeTransform {

  constructor(private timezoneDatesService: TimezoneDatesService, private languageService: LanguageService) {
  }

  public transform(date: OspDateTime | Date | string, config: TimezoneFormatLocaleDate = {}): string {
    const timestamp = (<OspDateTime>date)?.timestamp || <string | Date>date;
    if (!timestamp) {
      return null;
    }
    const localTimeZone: string = this.timezoneDatesService.getSelectedTimezone();
    const { dateFormat, showTime, hideEndOfDayTime } = { ...TimezoneUtils.defaultConfig, ...(config || {}) };

    const converted = momenttz(timestamp)
      .tz(TimezoneUtils.timezoneMappings[localTimeZone] || localTimeZone)
      .locale(this.languageService.getCurrentLocale());
    let datePart = dateFormat ? converted.format(dateFormat) : '';
    let timePart = showTime && !(hideEndOfDayTime && this.isEndOfDaySameTimeZone(timestamp))
      ? converted.format('LT') : '';

    return `${datePart} ${timePart}`.trim();
  }

  private isEndOfDaySameTimeZone(date: Date | string): boolean {
    return date && this.timezoneDatesService.isEndOfDaySameTimeZone(date);
  }
}
