import { Component, Input } from '@angular/core';
import { NavigationOptionResolver } from './navigation-option.resolver';
import { Router } from '@angular/router';

@Component({
  selector: 'bh-mobile-navigation-list',
  templateUrl: './mobile-navigation-list.component.html',
  styleUrls: ['./mobile-navigation-list.component.scss']
})
export class MobileNavigationListComponent {

  @Input() columns = 1;
  @Input() navigationOptions: string[];
  @Input() navigationOptionResolver: NavigationOptionResolver;

  constructor(private router: Router) { }

  public navigateTo(option: string): void {
    this.router.navigate([this.navigationOptionResolver.resolveUrl(option)])
  }

}
