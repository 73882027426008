import { Pipe, PipeTransform } from '@angular/core';
import { ViewProject } from 'app/modules/disposition/contract/view-project.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';

@Pipe({
  name: 'mapMarkerConvertProjectView'
})
export class MapMarkerConvertProjectViewPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: ViewProject[]): MapLocationMarker<ViewProject>[] {
    return Array.isArray(value) ? this.getMapLocationMarkers(value) : null;
  }

  private getMapLocationMarkers(items: ViewProject[]): MapLocationMarker<ViewProject>[] {
    return items.filter(Boolean).map(item => ({
      id: item.projectId,
      location: item.location,
      icon: this.getIconUrl(item),
      item
    }));
  }

  private getIconUrl(item: ViewProject): string {
    return this.iconResolver.getIconProject();
  }

}
