import { Component, OnInit } from '@angular/core';
import { ProcessCreateType } from './process-create-type';
import { ProcessCreateTypeResolver } from './process-create-type.resolver';
import { KeycloakService } from '../../../core/keycloak';
import { Modules } from '../../../shared/enums/modules.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'bh-mobile-choose-creation-process',
  templateUrl: './mobile-choose-creation-process.component.html',
  styleUrls: ['./mobile-choose-creation-process.component.scss']
})
export class MobileChooseCreationProcessComponent implements OnInit {

  public options: string[] = Object.keys(ProcessCreateType);

  constructor(public resolver: ProcessCreateTypeResolver,
              private authService: KeycloakService,
              private router: Router) { }

  public ngOnInit(): void {
    if (!this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      this.router.navigate([this.resolver.resolveUrl(ProcessCreateType.EQUIPMENT)]);
    }
  }
}
