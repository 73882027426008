<div class="list-view-main__tabs" fxFlex>
  <mat-tab-group mat-stretch-tabs>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon [icon]="faInfoCircle"></fa-icon>
        {{'general.generalData' | translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-maintenance-task-view-general [task]="task"></bh-maintenance-task-view-general>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>
