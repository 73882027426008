import { ThemeSettings } from '../theme.settings';

export const WACKER_NEUSON_THEME: ThemeSettings = {
  themeDirName: 'wacker-neuson',

  mapIconFrameColor: '#FFD511',
  mapIconPrimaryColor: '#575757',
  mapIconSecondaryColor: 'rgba(87,87,87,0.4)',

  chartPrimaryColor: '#FFD511',
  chartSecondaryColor: '#fd241f',
  chartPositiveValueColor: '#1ba7f0',
  chartNegativeValueColor: '#fd241f',

  fleetCompositionWidgetColors: [
    '#FFD511',
    '#C90621',
    '#277110',
    '#A68500',
    '#830214',
    '#7ED762',
    '#411BEA',
    '#fde796',
    '#911EB4',
    '#F58231',
    '#4EAE30',
    '#42D4F4',
    '#808000',
    '#FFD8B1',
    '#F032E6',
    '#469990',
    '#FABEBE',
    '#FFE119',
    '#8080FF',
    '#ED7651',
    '#808080',
    '#BF40BF',
    '#F0C80E',
    '#362698',
    '#7E7EB8',
    '#008000',
    '#A0A424',
    '#19CB97',
    '#E43E35',
    '#1EAC41',
    '#FF1493',
    '#1E90FF'
  ]
};
