export enum EquipmentStatusCategory {
  OPERATIONAL = 'OPERATIONAL',
  OPERATIONAL_WITH_RESTRICTIONS = 'OPERATIONAL_WITH_RESTRICTIONS',
  NOT_OPERATIONAL = 'NOT_OPERATIONAL',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export function isEquipmentStatusCategory(state: string): boolean {
  return EquipmentStatusCategory.OPERATIONAL === state
      || EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS === state
      || EquipmentStatusCategory.NOT_OPERATIONAL === state
      || EquipmentStatusCategory.NOT_AVAILABLE === state;
}
