<div class="timeline-event-details" *ngIf="damageLifeCycle?.equipmentCostId">
  <h2 class="timeline-event-details__title">{{'modules.equipment.base.invoiceInfo'|translate}}</h2>
  <div class="timeline-event-details__content">
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.performanceType'|translate}}</mat-label>
        <input matInput
              readonly
              [value]="invoice?.performanceType | performanceType">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.date'|translate}}</mat-label>
        <input matInput
              readonly
              [value]="invoice?.invoiceDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'}">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.costs'|translate}}</mat-label>
        <input matInput
              readonly
              [value]="invoice?.invoiceAmount | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.documentation'|translate}}</mat-label>
        <input matInput
              readonly
              [value]="invoice?.documentation">
      </mat-form-field>
    </div>
  </div>

  <bh-extended-invoice-info *ngIf="invoice" [invoice]="invoice"></bh-extended-invoice-info>
</div>
