<div class="m-view-tabs" fxFlexFill>
  <mat-tab-group fxFlexFill (selectedTabChange)="selectedTab = $event.tab" animationDuration="0ms">
    <mat-tab [label]="'general.equipm.pl'|translate|uppercase">
      <div class="m-view-list-inputs m-view-list-inputs-overflow assignments">
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" class="assignments">
          <mat-card class="assignments-card" *ngFor="let equipment of equipments">
            <mat-card-content>
              <div class="assignment">

                <div class="link" (click)="navigateToEquipmentAssignments(equipment.transferItemId)">
                  <b>{{equipment.transferItemName}}</b>
                </div>
                <div *ngIf="equipment.equipmentCustomerSerialNumber">
                  {{equipment.equipmentCustomerSerialNumber}}
                </div>
                <div *ngIf="equipment.transferItemCostCenter">
                  {{equipment.transferItemCostCenter}}
                </div>
                <div *ngIf="equipment.transferredBy">
                  {{'general.labels.disposedBy'|translate}} {{equipment.transferredBy}}
                </div>
                <div *ngIf="equipment.transferCreatedDate">
                  {{'general.at'|translate}} {{equipment.transferCreatedDate | date:'EEEE, d. MMM y':'UTC':getCurrentLocale()}}
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayoutAlign="end end">
                <span class="submission-meta-data">
                  {{'general.at'|translate}}: {{equipment.transferDate | date:'EEEE, d. MMM y':'UTC':getCurrentLocale()}}
                </span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="'general.bulkItems'|translate|uppercase">
      <div class="bulk-item" *ngFor="let bulkItem of bulkItems" fxLayoutGap="20px">
          <fa-icon fxFlex="20px" [icon]="bulkItemTypeResolver.resolveIcon(bulkItem.bulkItemType)"></fa-icon>
          <span fxFlex="100%">{{ bulkItem.transferItemName }}</span>
          <span>{{ bulkItem.amount }}</span>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
