import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: 'mat-select[bhMatSelectReadonly]'
})
export class MatSelectReadonlyDirective {
  @Input('bhMatSelectReadonly') public set readonly(value: boolean) {
    if (this.isReadOnly !== Boolean(value) && this.elementRef) {
      this.isReadOnly = Boolean(value);
      this.changeStyles();
    }
  }
  public get readonly(): boolean {
    return this.isReadOnly;
  }
  private isReadOnly = false;
  private selector = '.mat-mdc-select-trigger';
  private pointerEventsPrev: string;
  private handleKeydownPrev: (event: KeyboardEvent) => void;
  private pointerEventsReadOnly = 'none';

  constructor(@Self() private selectComponent: MatSelect) {}

  private get elementRef(): ElementRef {
    return this.selectComponent?._elementRef;
  }

  @HostListener('click', ['$event'])
  public onEvent(event: MouseEvent | KeyboardEvent): void {
    if (this.isReadOnly) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private changeStyles(): void {
    if (this.isReadOnly) {
      this.savePreviousConfigs();
      this.applyReadOnlyConfigs();
    } else {
      this.applyPreviousConfigs();
    }
  }

  private savePreviousConfigs(): void {
    if(this.elementRef) {
      this.handleKeydownPrev = this.selectComponent._handleKeydown;
      const select = this.elementRef.nativeElement.querySelector(this.selector);
      this.pointerEventsPrev = select.style.pointerEvents;
    }
  }

  private applyReadOnlyConfigs(): void {
    this.applyConfigs(this.pointerEventsReadOnly, () => {});
  }

  private applyPreviousConfigs(): void {
    this.applyConfigs(this.pointerEventsPrev, this.handleKeydownPrev);
  }

  private applyConfigs(pointerEvents: string, handleKeydown: (event: KeyboardEvent) => void): void {
    this.selectComponent._handleKeydown = handleKeydown;
    const checkbox = this.elementRef.nativeElement.querySelector(this.selector);
    checkbox.style.pointerEvents = pointerEvents;
  }

}
