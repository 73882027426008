import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceHealthResponse } from '../interface/service-health-response.interface';

@Injectable()
export class ServiceHealthService {

  constructor(private http: HttpClient) { }

  getServiceHealth(url: string): Observable<ServiceHealthResponse> {
    return this.http.get<ServiceHealthResponse>(url + '/actuator/health');
  }
}
