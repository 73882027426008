import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocuwareDocument } from '../../../shared/contract/docuware-document.interface';
import { getHttpParameters } from '../../../shared/utils';

@Injectable()
export class DocuwareService {

  private url = environment.APP_JOB_SERVICE_BASE_URL + '/api/v1/docuware';

  constructor(private http: HttpClient) {
  }

  public connectorExists(params: Object): Observable<boolean> {
    let httpParams = getHttpParameters(params);
    return this.http.get<boolean>(this.url + '/connector/exist', {params: httpParams});
  }

  public getDocuments(params: Object): Observable<DocuwareDocument[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<DocuwareDocument[]>(this.url + '/find-documents', {params: httpParams});
  }

  public getDocument(objectId: string, documentId: string): Observable<Blob> {
    return this.http.get(this.url + `/${objectId}/${documentId}`, {responseType: 'blob'});
  }
}
