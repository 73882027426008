import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {KeycloakService} from './app/core/keycloak';
import {UserAdfsSyncService} from './app/shared/services/user-adfs-sync.service';
import {UserConfigurationInitService} from './app/shared/services/user-configuration-init-service';
import {LocalUserStorageService} from './app/shared/services/local-user-storage.service';
import {UserConfiguration} from './app/shared/contract/user-configuration/user-configuration';
import {MobileTransferConfiguration} from './app/shared/contract/user-configuration/mobile-transfer-configuration';
import {User} from './app/shared/contract/user';
import {Authorities} from './app/shared/enums/authorities.enum';
import {PartnerTheme} from './app/modules/organisation/contract/partner-theme.enum';
import {appendApplicationManifest} from './manifest-resolver';
import { Languages } from 'app/shared/enums/languages.enum';


declare const EB: any;

function getMobileOperatingSystem(): string {
  const userAgent: string = navigator.userAgent || navigator.vendor;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(<any>window).MSStream) {
    return 'iOS';
  }
  try {
    EB.Barcode.enumerate();
    return 'Enterprise';
  } catch (e) {
    return 'others';
  }
}

function bootstrap(theme: PartnerTheme): void {
  appendApplicationManifest(theme, getMobileOperatingSystem());

  if (document['browserStatus'] === 'OK' || !document['browserStatus']) {
    bootstrapAppModule();
  } else {
    document.addEventListener('browser-check', function(e) {
      if (e['detail'] === 'OK') {
        bootstrapAppModule();
      }
    });
  }
}

function bootstrapAppModule(): void {
  platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator && environment.enableServiceWorker) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }
  });
}

function setEnterpriseValue(userId: string) {
  const sys = getMobileOperatingSystem();

  if (sys === 'Enterprise') {
    LocalUserStorageService.setUserValue('isEnterprise', 'true', userId);
  } else {
    LocalUserStorageService.setUserValue('isEnterprise', 'false', userId);
  }
}

if (environment.production) {
  enableProdMode();
}

KeycloakService.init(10000, 1).then(
    async (user: User) => {

      if (user.authorities.includes(Authorities.USER_UPDATE_ADFS)) {
        try {
          user = await UserAdfsSyncService.sync(user.email);
        } catch (e) {
          document.getElementById('main-loading-ring').remove();
          document.getElementById('main-loading-error').append('ADFS Integration Failed: ' + e);
          return;
        }
      }

      UserConfigurationInitService.loadUserConfiguration(user.userId).then(userConfig => {
        let parsedUserConfig: UserConfiguration = JSON.parse(LocalUserStorageService.getUserValue('user-configuration', user.userId));
        parsedUserConfig = {...parsedUserConfig, ...JSON.parse(userConfig)};

        if (!parsedUserConfig.wizardConfigurations) {
          parsedUserConfig.wizardConfigurations = {};
        }
        if (!parsedUserConfig.mobileEquipmentAddSelections) {
          parsedUserConfig.mobileEquipmentAddSelections = {};
        }
        if (!parsedUserConfig.mobileTransferConfiguration) {
          parsedUserConfig.mobileTransferConfiguration = new MobileTransferConfiguration(0, 0);
        }
        if (!parsedUserConfig.pushToken) {
          parsedUserConfig.pushToken = {};
        }
        if (!parsedUserConfig.additionalInfo?.partnerTheme || !parsedUserConfig.additionalInfo?.fallbackLanguage) {
          parsedUserConfig.additionalInfo = {
            partnerTheme: parsedUserConfig.additionalInfo?.partnerTheme ?? PartnerTheme.DEFAULT_OSP,
            fallbackLanguage: parsedUserConfig.additionalInfo?.fallbackLanguage ?? Languages.DEFAULT,
          }
        }
        LocalUserStorageService.setUserValue('user-configuration', JSON.stringify(parsedUserConfig), user.userId);
        bootstrap(parsedUserConfig.additionalInfo.partnerTheme);
        setEnterpriseValue(user.userId);
      })
      .catch(reason => {
        console.log('could not get user configuration' + reason);
        bootstrap(PartnerTheme.DEFAULT_OSP); // app should still bootstrap without user configuration endpoint available
      });
    },
    x => {
      window.location.reload();
    }
);
