import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EquipmentTelematicsDataseriesItem } from 'app/modules/equipment/contract/equipment-telematics-dataseries-item';
import { SingleSeriesRatioTransformStrategy } from 'app/shared/pipes/chart/strategies/single-series-ratio-transform-strategy.class';
import { TransformStrategyOptions } from 'app/shared/pipes/chart/strategies/transform-strategy-options.interface';
import { CustomThemeService } from 'app/shared/services/custom-theme.service';
import { LanguageService } from 'app/shared/services/language.service';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'bh-equipment-telematics-operating-hours-ratio',
  templateUrl: './equipment-telematics-operating-hours-ratio.component.html',
  styleUrls: ['../equipment-chart.style.scss', './equipment-telematics-operating-hours-ratio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentTelematicsOperatingHoursRatioComponent implements OnInit {
  @Input() set value (value: EquipmentTelematicsDataseriesItem) {
    this.data = value;
  }
  @Input() public subtitle = '';

  public data: EquipmentTelematicsDataseriesItem;
  public type: ChartType = 'doughnut';
  public strategy: SingleSeriesRatioTransformStrategy;
  public readonly options: ChartOptions<'doughnut'> = {
    cutout: '75%',
    plugins: {
      datalabels: <any>false,
    }
  };

  constructor(
    private customThemeService: CustomThemeService,
    private languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.strategy = new SingleSeriesRatioTransformStrategy(this.getStrategyOptions());
  }

  private getStrategyOptions(): TransformStrategyOptions {
    const themeSettings = this.customThemeService.getThemeSettings();
    return {
      labels: [this.translate('general.operatingHour.pl'), this.translate('modules.equipment.telematics.idleOperatingHours')],
      dataset: [{
        color: [themeSettings.chartPrimaryColor, themeSettings.chartSecondaryColor]
      }]
    }
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
