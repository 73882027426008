import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PagedResponse } from '../../../../../../shared/contract/page-response.interface';
import { TransferHistoryEntry } from './transfer-history-entry';
import { ViewTransferRequest } from './view-transfer-request';
import { RevertTransferCartCommand } from '../../../../contract/revert-transfer-cart-command';
import { AcknowledgeTransferRequestCommand } from '../../../../contract/acknowledge-transfer-request-command';
import { LastTransferDateResponse } from './last-transfer-date-response';
import { MinimalPossibleTransferDateRequest } from './minimal-possible-transfer-date-request';

@Injectable({ providedIn: 'root' })
export class ProjectTransferHistoryService {
  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/transfer-history';
  private requestUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/transfer-request';

  constructor(private http: HttpClient) {}

  public getProjectTransfers(projectId: string, page: number, pageSize: number): Observable<PagedResponse<TransferHistoryEntry>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString());
    return this.http.get<PagedResponse<TransferHistoryEntry>>(`${this.url}/projects/${projectId}`, {params: params});
  }

  public getLatestTransfer(projectId): Observable<Date> {
    return this.http.get<Date>(`${this.url}/projects/${projectId}/latest-transfer-date`);
  }

  public getPendingTransfers(projectId): Observable<ViewTransferRequest[]> {
    const params = new HttpParams()
      .set('projectId', projectId);
    return this.http.get<ViewTransferRequest[]>(`${this.requestUrl}/pending`, {params: params});
  }

  public getArchivedTransfers(projectId): Observable<ViewTransferRequest[]> {
    const params = new HttpParams()
      .set('projectId', projectId);
    return this.http.get<ViewTransferRequest[]>(`${this.requestUrl}/archived`, {params: params});
  }

  public getMinimalPossibleTransferDate(request: MinimalPossibleTransferDateRequest): Observable<LastTransferDateResponse> {
    return this.http.post<LastTransferDateResponse>(this.requestUrl + '/minimal-possible-transfer-date', request);
  }

  public declineTransferRequest(command: RevertTransferCartCommand): Observable<string> {
    return this.http.post(this.requestUrl + '/decline', command, {responseType: 'text'});
  }

  public acknowledgeTransferRequest(command: AcknowledgeTransferRequestCommand): Observable<string> {
    return this.http.post(this.requestUrl + '/acknowledge', command, {responseType: 'text'});
  }

}

