import { TransferItem } from '../shared/transfer/model/transfer-item';
import { TransferItemType } from '../shared/transfer/model/transfer-item-type.enum';
import { MobileTransferItem } from './mobile-transfer-item';

export class StockToStockTransferItem {

  public static fromTransferItem(item: TransferItem) {
    return new StockToStockTransferItem(
        item.sourceStockId,
        item.transferId,
        item.transferItemType,
        item.transferAmount,
        item.id
    );
  }

  public static fromMobileTransferItem(item: MobileTransferItem, sourceStockId: string) {
    return new StockToStockTransferItem(
      sourceStockId,
      item.transferId,
      TransferItemType.BULKITEM,
      item.amount
    );
  }

  private constructor(
      public sourceStockId: string,
      public transferItemId: string,
      public transferItemType: TransferItemType,
      public amount: number,
      public amountId?: string
  ) {}

}
