import { ExpandingTreeOption } from './expanding-tree-option.interface';
import { TreeItemStatus } from './tree-item-status.class';

export class ExpandingTreeMapItem extends TreeItemStatus implements ExpandingTreeOption {
  public id: string;
  public parentId: string;
  public childrenIds: string[];
  public hidden: boolean;
  public expanded: boolean;
  public offset = 0;
  public optionValue: ExpandingTreeOption;
  public children: ExpandingTreeOption[];
  public disabled?: boolean;
  public virtualStatus = new TreeItemStatus();

  constructor(option: ExpandingTreeOption, keyFieldName: string, parentId: string) {
    super();
    this.id = option[keyFieldName];
    this.disabled = option.disabled;
    this.parentId = parentId;
    this.childrenIds = option.children && option.children.length > 0
      ? option.children.map(child => child[keyFieldName])
      : [];
    this.hidden = false;
    this.expanded = false;
    this.optionValue = { ...option };
    this.children = option.children && option.children.length > 0 ? [...option.children] : [];
  }

  public hasChildren(): boolean {
    return this.childrenIds && this.childrenIds.length > 0;
  }

  public setIndeterminate(forced: boolean = false): void {
    this.virtualStatus.setIndeterminate();
    if (!this.disabled || forced) {
      super.setIndeterminate();
      this.expanded = true;
    }
  }

  public setIndeterminateIfUnchecked(forced: boolean = false): void {
    if (!this.checked) {
      this.setIndeterminate(forced);
    }
  }

  public setSelection(checked: boolean, forced: boolean = false): void {
    this.virtualStatus.setSelection(checked);
    if (!this.disabled || forced) {
      super.setSelection(checked);
    }
  }

  public setChecked(forced: boolean = false): void {
    this.virtualStatus.setChecked();
    if (!this.disabled || forced) {
      super.setChecked();
    }
    this.expanded = true;
  }

  public setUnchecked(forced: boolean = false): void {
    this.virtualStatus.setUnchecked();
    if (!this.disabled || forced) {
      super.setUnchecked();
    }
  }
}

