import { ConnectorInformation } from '../connector-information';
import { ScheduleInformation } from '../schedule-information';
import { AempV2ConnectionInformation } from './aemp-v2-connection-information';

export class CreateAempV2ConnectorCommand {

  connector: ConnectorInformation;
  schedule: ScheduleInformation;
  connection: AempV2ConnectionInformation;
}
