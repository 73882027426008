<div class="delete-partner">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{'modules.organisation.partnerDelete.deletePartner'|translate}}</h1>
  <div mat-dialog-content>
    {{'modules.organisation.partnerDelete.deletePartnerMessage'|translate}}
    <div class="secondary-text">
      {{'modules.organisation.partnerDelete.deletePartnerMessageSecondary'|translate}}
    </div>
  </div>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">{{'general.buttons.no'|translate|uppercase}}</button>
    <button mat-raised-button color="primary" mat-dialog-close="yes">{{'general.buttons.yes'|translate|uppercase}}</button>
  </mat-dialog-actions>
</div>
