import { Component, Input } from '@angular/core';
import { BatteryStatus } from '../../enums/battery-status.enum';

@Component({
  selector: 'bh-tracker-battery-status',
  templateUrl: './tracker-battery-status.component.html',
  styleUrls: ['./tracker-battery-status.component.scss']
})
export class TrackerBatteryStatusComponent {

  batteryIcon = 'battery_unknown';
  _batteryStatus: BatteryStatus;

  @Input() set batteryStatus(value: BatteryStatus) {
    this._batteryStatus = Object.values(BatteryStatus).includes(value) ? value : BatteryStatus.UNKNOWN;
    switch (this._batteryStatus) {
      case BatteryStatus.HIGH:
        this.batteryIcon = 'battery_full';
        break;
      case BatteryStatus.LOW:
        this.batteryIcon = 'battery_4_bar';
        break;
      case BatteryStatus.CRITICAL:
        this.batteryIcon = 'battery_1_bar';
        break;
      case BatteryStatus.UNKNOWN:
      default:
        this.batteryIcon = 'battery_unknown';
    }
  }

  get batteryStatus(): BatteryStatus {
    return this._batteryStatus;
  }
}
