import { TransportItem } from '../../../../equipment/shared/transport-items.interface';
import { Pipe, PipeTransform } from '@angular/core';
import { TransportContextBase } from './transport-context-base.class';
import { TransportItemLocationType } from '../../enums/transport-item-location-type.enum';
import { TransportItemContext } from '../../contract/transport-item-context.interface';
import { TransportItemType } from '../../enums/transport-item-type.enum';
import { TransportView } from 'app/modules/transportation/contracts/transport/transport-view.interface';

@Pipe({
  name: 'transportContextItem'
})
export class TransportContextItemPipe extends TransportContextBase implements PipeTransform {

  public transform(transport: TransportView, locationType: TransportItemLocationType, showLink = true): TransportItemContext {
    const itemInfo = this.getItemInfo(transport, locationType);
    let context: TransportItemContext = {
      locationType,
      state: transport?.state,
      itemType: null, entityType: null, name: null, address: null, navigationInfo: null
    };

    if (itemInfo?.item) {
      const { id, address, transportItemType } = itemInfo.item;
      const { id: latestItemId, transportItemType: latestItemTransportType } = itemInfo?.latestItem || {};
      let navigationInfo = null;
      if (showLink && !itemInfo?.isItemDeleted) {
        navigationInfo = {
          url: itemInfo?.latestItem
            ? this.getNavigationUrl(latestItemId, latestItemTransportType)
            : this.getNavigationUrl(id, transportItemType),
          isLatestItem: Boolean(itemInfo?.latestItem)
        };
      }
      context = {
        ...context,
        itemType: transportItemType,
        name: this.getNameTitleTransportItem(itemInfo.item),
        address: this.getAddressTitle(address),
        navigationInfo,
        entityType: this.getEntityType(itemInfo.item)
      }
    }
    return context;
  }

  private getNameTitleTransportItem(item: TransportItem): string {
    const titleSecondPart = ((item.transportItemType === TransportItemType.CONTACT
      ? item.contactIdentifier
      : item.costCenter) || '').trim();
    return item.name.trim() + (titleSecondPart ? ` (${titleSecondPart})` : '');
  }

}
