import { LanguageService } from 'app/shared/services/language.service';

import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { ViewEmployee } from 'app/modules/disposition/contract/view-employee.interface';
import { Qualification } from 'app/modules/disposition/contract/qualification';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';
import { takeWhile } from 'rxjs/operators';
import { EmployeeQualification } from 'app/modules/disposition/contract/employee-qualification';
import { Retake } from 'app/modules/disposition/contract/retake.enum';
import { IntervalRetake } from 'app/modules/disposition/contract/interval-retake';
import moment from 'moment';
import { PeriodType } from 'app/modules/disposition/contract/period-type';
import { QualificationEmployeeAssignments } from 'app/modules/disposition/contract/qualification-employee-assignments';
import { DatesService } from 'app/shared/services/dates.service';

@Directive()
export abstract class EmployeeViewQualificationBase implements OnInit, OnDestroy {

    public employeeQualifications: EmployeeQualification[] = [];
    public qualifications: Qualification[];
    public authorities = Authorities;

    protected currentEmployee: ViewEmployee;
    protected componentActive = true;

    protected constructor(protected employeeStore: EmployeeManagementDatasource,
      protected languageService: LanguageService) {}

    ngOnInit(): void {
      this.subscribeToCurrentEmployee();
      this.subscribeToQualifications();
      this.subscribeToEmployeeQualifications();
      this.updateData();
    }

    protected updateData(): void {
      this.employeeStore.getQualifications().subscribe();
    }

    private mapToQualifications(assignment: QualificationEmployeeAssignments, employeeId: string): EmployeeQualification {
        let newQualification = new EmployeeQualification();
        newQualification.employeeId = employeeId;
        newQualification.acquisitionDate = new Date(assignment.qualificationAcquisitionDate);

        newQualification.lastRenewal = DatesService.fromIsoStartOfDay(assignment.qualificationLastRenewal);
        newQualification.retakeDate = new Date(assignment.qualificationNextRenewal);
        newQualification.qualificationId = assignment.qualificationId;
        newQualification.qualificationDescription = assignment.qualificationDescription;
        newQualification.qualificationName = assignment.qualificationName;
        newQualification.qualificationRetakeType = assignment.qualificationRetakeType;
        switch (assignment.qualificationRetakeType) {
          case Retake.INTERVAL:
            newQualification.interval = new IntervalRetake();
            let days = moment.duration(assignment.periodRetakeInterval.retakeInterval)['_days'];
            let months = moment.duration(assignment.periodRetakeInterval.retakeInterval)['_months'];
            newQualification.qualificationMediumWarningThreshold = assignment.periodRetakeInterval.mediumWarningThreshold;
            newQualification.qualificationHighWarningThreshold = assignment.periodRetakeInterval.highWarningThreshold;
            newQualification.interval.regularity = months === 0 ? days : months;
            newQualification.interval.period = months === 0 ? PeriodType.day : PeriodType.month;
            break;
          case Retake.FIXED_DAY_IN_YEAR:
            newQualification.qualificationMediumWarningThreshold = assignment.fixedDateRetakeInterval.mediumWarningThreshold;
            newQualification.qualificationHighWarningThreshold = assignment.fixedDateRetakeInterval.highWarningThreshold;
            newQualification.eachYearRetakeDate = assignment.fixedDateRetakeInterval.day;
            newQualification.eachYearRetakeMonth = assignment.fixedDateRetakeInterval.month;
            break;
        }
        return newQualification;
    }

    private subscribeToCurrentEmployee() {
      this.employeeStore.currentEmployee
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(value => {
        if (value) {
          this.currentEmployee = value;
          this.updateData();
        }
      });
    }

    private subscribeToEmployeeQualifications() {
      this.employeeStore.employeeQualifications
        .pipe(takeWhile(() => this.componentActive))
        .subscribe(employeeQualifications => {
          this.employeeQualifications = [];
          if (employeeQualifications && employeeQualifications.qualifications) {
            this.qualifications.forEach(qualification => {
              if (employeeQualifications.qualifications[qualification.qualificationId]) {
                this.employeeQualifications.push(this.mapToQualifications(
                  employeeQualifications.qualifications[qualification.qualificationId],
                  employeeQualifications.employeeId));
              }
            });
          }
        });
      }

      private subscribeToQualifications() {
        this.employeeStore.qualifications
        .pipe(takeWhile(() => this.componentActive))
        .subscribe(qualification => {
          this.qualifications = qualification;
          if (this.currentEmployee) {
            this.employeeStore.getEmployeeQualifications(this.currentEmployee.employeeId).subscribe();
          }
        });
      }

      public ngOnDestroy(): void {
        this.componentActive = false;
      }

    protected translate(key: string): string {
      return this.languageService.getInstant(key);
    }
}
