<div class="gallery-arrow-wrapper gallery-arrow-left">
  <div class="gallery-icon gallery-arrow"
    aria-hidden="true"
    (click)="handlePrevClick()"
    [class.gallery-disabled]="prevDisabled"
    data-osp-test="arrow-prev">
    <fa-icon class="gallery-icon-content" [icon]="prev"></fa-icon>
  </div>
</div>

<div class="gallery-arrow-wrapper gallery-arrow-right">
  <div class="gallery-icon gallery-arrow"
    aria-hidden="true"
    (click)="handleNextClick()"
    [class.gallery-disabled]="nextDisabled"
    data-osp-test="arrow-next">
    <fa-icon class="gallery-icon-content" [icon]="next"></fa-icon>
  </div>
</div>
