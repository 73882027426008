<div class="query-dialog">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content>
    {{message}}
    <mat-form-field *ngIf="keyValue">
      <mat-label>{{placeholder}}</mat-label>
      <input matInput [formControl]="keyValueControl">
      <mat-error>
        {{ 'shared.securityQueryDialog.errorValidation'|translate:{ value: keyValue } }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="entities && entities.length > 0">
      <mat-label>{{'modules.organisation.subCustomer.selectNewParent'|translate}}</mat-label>
      <mat-select [(value)]="selectedValue" required>
        <mat-option *ngFor="let entity of entities" [value]="entity.customerId">
          {{entity.customerName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button color="primary" (click)="onConfirmClick()" [disabled]="confirmDisabled()">
      {{'general.buttons.confirm'|translate}}
    </button>
  </div>
</div>
