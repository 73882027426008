import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SecurityQueryValidator } from 'app/shared/custom-validators/security-query.validator';
import { SecurityQueryProcessType } from './../../enums/security-query-process-type.enum';
import { SecurityQueryProcessResolver } from './security-query-process-resolver';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bh-security-query-dialog',
  templateUrl: './security-query-dialog.component.html',
  styleUrls: ['./security-query-dialog.component.scss']
})
export class SecurityQueryDialogComponent implements OnInit {

  private _keyValue: string;
  private _processType: SecurityQueryProcessType = SecurityQueryProcessType.DEFAULT;
  public keyValueControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public entities: any = [];
  public selectedValue: string = null;

  constructor(private securityQueryProcessResolver: SecurityQueryProcessResolver,
              private dialogRef: MatDialogRef<SecurityQueryDialogComponent>) {
  }

  // set in parent component
  public set processType(processType: SecurityQueryProcessType) {
    this._processType = processType;
  }

  public set keyValue(value: string) {
    this._keyValue = value;
    this.updateControlSettings();
  }

  public get keyValue(): string {
    return this._keyValue;
  }

  public get title(): string {
    return this.securityQueryProcessResolver.resolveDialogTitle(this._processType);
  }

  public get message(): string {
    return this.securityQueryProcessResolver.resolveDialogMessage(this._processType, this._keyValue);
  }

  public get placeholder(): string {
    return this.securityQueryProcessResolver.resolvePlaceholder(this._processType, this._keyValue);
  }

  public ngOnInit(): void {
    this.updateControlSettings();
  }

  private updateControlSettings(): void {
    if (!this._keyValue) {
      this.keyValueControl.disable();
      return;
    }
    this.keyValueControl.enable();
    this.keyValueControl.setValidators([Validators.required, SecurityQueryValidator.isSameValue(this._keyValue)]);
  }

  onNoClick(): void {
    this.dialogRef.close({ decision: 'no' });
  }

  onConfirmClick(): void {
    this.dialogRef.close({ decision: 'yes', selectedValue: this.selectedValue });
  }

  confirmDisabled(): boolean {
    if (this.keyValueControl.invalid === true) {
      return true;
    }
    return this.entities.length > 0 && this.selectedValue === null;
  }

}
