import { LanguageService } from './../../../shared/services/language.service';
import { ProcessCreateType } from './process-create-type';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { faPalletAlt, faQuestion, faTrafficCone } from '@fortawesome/pro-light-svg-icons';
import { NavigationOptionResolver } from '../../../shared/components/mobile-navigation-list/navigation-option.resolver';
import { Injectable } from '@angular/core';

@Injectable()
export class ProcessCreateTypeResolver implements NavigationOptionResolver {

  constructor(private languageService: LanguageService) {
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case ProcessCreateType.EQUIPMENT:
        return faTruckPlow;
      case ProcessCreateType.BULK_ITEM_STANDARD:
        return faTrafficCone;
      case ProcessCreateType.BULK_ITEM_CONSUMABLE:
        return faPalletAlt;
      default:
        return faQuestion;
    }
  }

  public resolveName(option: string): string {
    switch (option) {
      case ProcessCreateType.EQUIPMENT:
        return this.translate('general.equipm.s');
      case ProcessCreateType.BULK_ITEM_STANDARD:
        return this.translate('general.bulkItemType.standard');
      case ProcessCreateType.BULK_ITEM_CONSUMABLE:
        return this.translate('general.bulkItemType.consumable');
      default: return '';
    }
  }

  public resolveUrl(option: string): string {
    switch (option) {
      case ProcessCreateType.EQUIPMENT:
        return '/mobile/equipments/add';
      case ProcessCreateType.BULK_ITEM_STANDARD:
        return '/mobile/bulk-items/add';
      case ProcessCreateType.BULK_ITEM_CONSUMABLE:
        return '/mobile/consumable-bulk-items/add';
      default:
        return '';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
