import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[bhInputAllowedPattern],textarea[bhInputAllowedPattern]'
})
export class InputAllowedPatternDirective {
  private previousValue = '';
  @Input() set bhInputAllowedPattern(pattern: string) {
    this.regex = pattern ? new RegExp(pattern) : null;
  }
  private regex: RegExp;
  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const target = <HTMLInputElement>event.target;
    if (!target.validity.valid
      || (this.regex && !this.regex.test(target.value))
      && target.value !== this.previousValue
    ) {
      target.value = this.previousValue;
      target.dispatchEvent(new InputEvent(event.type));
    } else {
      this.previousValue = target.value;
    }
  }
}
