import { environment } from 'environments/environment';
import { BehaviorSubject, forkJoin, Observable, Subject, throwError } from 'rxjs';
import { AmountsService } from '../../../shared/amounts.service';
import { CreateProjectToProjectTransferCartCommand } from '../../../contract/create-project-to-project-transfer-cart.command';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { ITransferItemWithValidityStatus, TransferItem } from '../../../shared/transfer/model/transfer-item';
import { Injectable } from '@angular/core';
import { CreateProjectToStockTransferCartCommand } from '../../../contract/create-project-to-stock-transfer-cart-command';
import { CreateStockToProjectTransferCartCommand } from '../../../contract/create-stock-to-project-transfer-cart.command';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateProjectToConsumedTransferCartCommand } from '../../../contract/create-project-to-consumed-transfer-cart-command';
import { CreateStockToStockTransferCartCommand } from '../../../contract/create-stock-to-stock-transfer-cart-command';
import { OnlineStatusService } from '../../../../../shared/services/online-status.service';
import { OfflineTransferAmountsService } from '../../../../../shared/services/offline-transfer-amounts.service';
import { StockService } from 'app/modules/disposition/shared/services/stock.service';
import { TransferCartConfirmationComponent } from '../landscape/transfer-cart-confirmation/transfer-cart-confirmation.component';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { TransferService } from 'app/modules/disposition/shared/transfer.service';
import { MinimalPossibleTransferDateRequest } from '../../../projects/project-list/project-view/project-view-transfer-history/minimal-possible-transfer-date-request';
import { LocationType } from '../../../../../shared/enums/location-type.enum';
import { TransferItemTransferDateRequest } from '../../../projects/project-list/project-view/project-view-transfer-history/transfer-item-transfer-date-request';
import { TransferType } from 'app/modules/disposition/projects/project-list/project-view/project-view-transfer-history/transfer-type';
import { TransferCartSummaryComponent } from '../landscape/transfer-cart-summary/transfer-cart-summary.component';
import { TransferProject } from '../landscape/bulk-item-transfer-project-select/transfer-project';
import { TransferStock } from '../landscape/bulk-item-transfer-stock-select/transfer-stock';
import { WaitingComponent } from '../../../shared/waiting/waiting.component';
import { ErrorMessageComponent } from '../../../shared/error-message/error-message.component';
import { LanguageService } from '../../../../../shared/services/language.service';
import moment from 'moment';
import { RequestStatus } from '../../../shared/enums/request-status.enum';
import { ITransfer } from '../../../contract/transfer.interface';
import { TransferCartDatasource } from 'app/modules/disposition/shared/transfer-cart.datasource';
import { StockToStockTransferItem } from 'app/modules/disposition/contract/stock-to-stock-transfer-item.interface';
import { TransferCartItem } from 'app/modules/disposition/contract/transfer-cart-item';
import { BulkItemTransferDatasource } from 'app/modules/disposition/shared/bulk-item-transfer.datasource';
import { IAmountsRequestParams } from 'app/modules/disposition/stocks/shared/contrancts/amounts-request-params.interface';

@Injectable()
export class TransferStore {
  private _transferItems: BehaviorSubject<TransferItem[]> = new BehaviorSubject([]);
  public readonly transferItems: Observable<TransferItem[]> = this._transferItems.asObservable();
  private _transferFailed: Subject<void> = new Subject<void>();
  public readonly transferFailed: Observable<void> = this._transferFailed.asObservable();
  private _transferSuccess: Subject<TransferType> = new Subject<TransferType>();
  public readonly transferSuccess: Observable<TransferType> = this._transferSuccess.asObservable();
  public transfer: ITransfer;
  public waitingDialogRef: MatDialogRef<WaitingComponent>;
  public transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];
  private _loadingStockAmounts: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _loadingProjectAmounts: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly loadingStockAmounts: Observable<boolean> = this._loadingStockAmounts.asObservable();
  public readonly loadingProjectAmounts: Observable<boolean> = this._loadingProjectAmounts.asObservable();

  private transferAmounts: Map<string, number> = new Map();

  constructor(private transferService: TransferService,
              private amountsService: AmountsService,
              private equipmentsService: EquipmentsService,
              private stockService: StockService,
              private onlineStatusService: OnlineStatusService,
              private offlineTransferAmountsService: OfflineTransferAmountsService,
              private languageService: LanguageService,
              private transferCartDatasource: TransferCartDatasource,
              private bulkItemTransferDatasource: BulkItemTransferDatasource,
              private dialog: MatDialog) {
  }

  public setErrorStatus(items: ITransferItemWithValidityStatus[], id: string, message: string): void {
    const ind = items.findIndex(item => item.transferItem.transferId === id);
    if (ind !== -1) {
      items[ind].status = RequestStatus.FAILED;
      items[ind].errorText = message;
    }
  }

  public transferProjectToProject(command: CreateProjectToProjectTransferCartCommand, projectId?: string): void {
    this.transfer = null;
    if (command.transferItems.length === 0) {
      this._transferSuccess.next(TransferType.PROJECT_TO_PROJECT);
      if (this.waitingDialogRef) {
        this.waitingDialogRef.close();
      }
      return;
    }
    if (!this.onlineStatusService.onlineStatus) {
      command.transferItems.forEach(item => {
        this.offlineTransferAmountsService.offlineProjectAmountDecrease(item.transferItemId,
          item.sourceStockId,
          item.sourceProjectId,
          item.amount);
        this.offlineTransferAmountsService.offlineProjectAmountIncrease(item.transferItemId,
          item.sourceStockId,
          command.targetProjectId,
          item.amount);
      });
    }
    this.transferService.transferProjectToProject(command)
    .pipe(
      delay(environment.DELAY_LONG),
      tap(res => {
        this.transfer = res;
        this._transferSuccess.next(TransferType.PROJECT_TO_PROJECT);
        if (projectId) {
          this.getProjectAmounts(projectId, true);
        }
        if (this.waitingDialogRef) {
          this.waitingDialogRef.close();
        }
      }),
      catchError(err => {
        this._transferFailed.next();
        if (projectId) {
          this.getProjectAmounts(projectId, true);
        }
        if (this.waitingDialogRef) {
          setTimeout(() => {
            this.errorDialog(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'));
            this.waitingDialogRef.close();
          }, environment.DELAY_SHORTEST);
        }
        return throwError(err)
      }))
    .subscribe();
  }

  public transferProjectToStock(sourceProjectId: string, command: CreateProjectToStockTransferCartCommand): void {
    if (!this.onlineStatusService.onlineStatus) {
      command.transferItems.forEach(item => {
        this.offlineTransferAmountsService.offlineProjectAmountDecrease(item.transferItemId,
          item.sourceStockId,
          sourceProjectId,
          item.amount);
        this.offlineTransferAmountsService.offlineAmountIncrease(item.transferItemId, item.sourceProjectId, item.amount);
      });
    }
    this.transferService.transferProjectToStock(command)
    .pipe(
      delay(environment.DELAY_LONG),
      tap(res => {
        this.transfer = res;
        this._transferSuccess.next(TransferType.PROJECT_TO_STOCK);
        this.getProjectAmounts(sourceProjectId, true);
        this.transferAmounts.clear();
        if (this.waitingDialogRef) {
          this.waitingDialogRef.close();
        }
      }),
      catchError(err => {
        this._transferFailed.next();
        this.getProjectAmounts(sourceProjectId);
        if (this.waitingDialogRef) {
          setTimeout(() => {
            this.errorDialog(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'));
            this.waitingDialogRef.close();
          }, environment.DELAY_SHORTEST);
        }
        return throwError(err);
      }))
    .subscribe();
  }

  public transferProjectToConsumed(projectId: string, command: CreateProjectToConsumedTransferCartCommand): void {
    if (!this.onlineStatusService.onlineStatus) {
      command.transferItems.forEach(item => {
        this.offlineTransferAmountsService.offlineProjectAmountDecrease(item.transferItemId, item.sourceStockId, projectId, item.amount);
      });
    }
    this.transferService.transferProjectToConsumed(command)
    .pipe(
      delay(environment.DELAY_LONG),
      tap(() => {
        this._transferSuccess.next(null);
        this.getProjectAmounts(projectId, true);
        this.transferAmounts.clear();
        if (this.waitingDialogRef) {
          this.waitingDialogRef.close();
        }
      }),
      catchError(err => {
        this._transferFailed.next(null);
        this.getProjectAmounts(projectId);
        if (this.waitingDialogRef) {
          setTimeout(() => {
            this.errorDialog(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'));
            this.waitingDialogRef.close();
          }, environment.DELAY_SHORTEST);
        }
        return throwError(err);
      }))
    .subscribe();
  }

  public transferStockToStock(stockId: string, command: CreateStockToStockTransferCartCommand): void {
    if (!this.onlineStatusService.onlineStatus) {
      command.transferItems.forEach(item => {
        this.offlineTransferAmountsService.offlineAmountDecrease(item.transferItemId, stockId, item.amount);
        this.offlineTransferAmountsService.offlineAmountIncrease(item.transferItemId, command.targetStockId, item.amount);
      });
    }
    this.transferService.transferStockToStock(command)
    .pipe(delay(environment.DELAY_LONG),
      tap(res => {
        this.transfer = res;
        this._transferSuccess.next(null);
        const currentTransferCartData: Map<string, TransferCartItem> = this.transferCartDatasource._data.getValue();

        Array.from(currentTransferCartData.keys()).forEach((transferId: string) => {
          const foundedTrasnferIndex: number = command.transferItems
            .findIndex((transferItem: StockToStockTransferItem) => transferItem.amountId === transferId);
          if (foundedTrasnferIndex !== -1) {
            currentTransferCartData.delete(transferId);
            this.transferCartDatasource._data.next(currentTransferCartData);
          }
        });

        this.getStockAmounts(stockId, true);
        this.transferAmounts.clear();
        if (this.waitingDialogRef) {
          this.waitingDialogRef.close();
        }
      }),
      catchError(err => {
        this._transferFailed.next();
        this.getStockAmounts(stockId);
        if (this.waitingDialogRef) {
          setTimeout(() => {
            this.errorDialog(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'));
            this.waitingDialogRef.close();
          }, environment.DELAY_SHORTEST);
        }
        return throwError(err);
      }))
    .subscribe();
  }

  public transferStockToProject(command: CreateStockToProjectTransferCartCommand): void {
    if (!this.onlineStatusService.onlineStatus) {
      command.transferItems.forEach(item => {
        this.offlineTransferAmountsService.offlineAmountDecrease(item.transferItemId, item.sourceStockId, item.amount);
        this.offlineTransferAmountsService.offlineProjectAmountIncrease(item.transferItemId,
          item.sourceStockId,
          command.targetProjectId,
          item.amount);
      });
    }
    this.transferService.transferStockToProject(command)
    .pipe(delay(environment.DELAY_LONG),
      tap(res => {
        this.transfer = res;
        this._transferSuccess.next(TransferType.STOCK_TO_PROJECT);
        this.bulkItemTransferDatasource.updateListing();
        if (this.waitingDialogRef) {
          this.waitingDialogRef.close();
        }
      }),
      catchError(err => {
        this._transferFailed.next();
        if (this.waitingDialogRef) {
          setTimeout(() => {
            this.errorDialog(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'));
            this.waitingDialogRef.close();
          }, environment.DELAY_SHORTEST);
        }
        return throwError(err);
      }))
    .subscribe();
  }

  public getProjectAmounts(projectId: string, flushAmounts?: boolean): void {
    let params = {
      requestDate: new Date(moment.now()).toISOString(),
    };
    this._loadingProjectAmounts.next(true);

    const projectAmountsRequest = this.amountsService.getProjectAmounts(projectId, params)
      .pipe(
        map(items => items.map(item => {
          const amount = flushAmounts ? 0 : this.getCurrentTransferAmount(item.amountId);
          return TransferItem.fromViewProjectAmount(item, amount, 0);
        }))
      );

    projectAmountsRequest.subscribe(
      (res: TransferItem[]) => {
        this._transferItems.next(res);
      },
      (error) => {
      },
      () => {
        this._loadingProjectAmounts.next(false);
      }
    );
  }

  public getProjectWithStatusPeriods(projectId: string, flushAmounts?: boolean): void {
    let params = {
      requestDate: new Date(moment.now()).toISOString(),
    };
    this.amountsService
      .getProjectWithStatusPeriods(projectId, params)
      .pipe(map(items => items.map(item => {
        const amount = flushAmounts ? 0 : this.getCurrentTransferAmount(item.amountId);
        return TransferItem.fromViewProjectAmount(item, amount, 0)
      })))
      .subscribe((res: TransferItem[]) => this._transferItems.next(res));
  }

  public getStockAmounts(stockId: string, flushAmounts?: boolean): void {
    this._loadingStockAmounts.next(true);

    const bulkItemsAmountsRequest = this.amountsService
      .getStockAmounts(stockId)
      .pipe(map(items => items.content.map(item =>
        TransferItem.fromViewAmount(item, flushAmounts ? 0 : this.getCurrentTransferAmount(item.amountId), 0))));

    if (stockId) {
      const equipmentAmounts_firstPage = this.stockService
        .getEquipmentAmounts(stockId, this.getEquipmentAmountsRequestParams(0))
        .pipe(map(items => items.content.map(item => TransferItem.fromViewEquipmentAmount(item))));

      const equipmentAmounts_secondPage = this.stockService
        .getEquipmentAmounts(stockId, this.getEquipmentAmountsRequestParams(1))
        .pipe(map(items => items.content.map(item => TransferItem.fromViewEquipmentAmount(item))));

      const totalEquipmentAmounts = forkJoin([equipmentAmounts_firstPage, equipmentAmounts_secondPage])
        .pipe(map(data => data.reduce((result, arr) => [...result, ...arr], [])));

      forkJoin([bulkItemsAmountsRequest, totalEquipmentAmounts]).subscribe(
        res => {
          this._transferItems.next(res[0].concat(res[1]));
        },
        error => {
        },
        () => {
          this._loadingStockAmounts.next(false);
        }
      );
    }
  }

  private getEquipmentAmountsRequestParams(page: number): IAmountsRequestParams {
    return {
      page: page,
      size: 2000,
    }
  }

  public updateTransferAmount(amountId: string, transferAmount: number): void {
    this.transferAmounts.set(amountId, transferAmount);
  }

  public confirmationDialog(confirmLabel: string,
                            denyLabel: string,
                            confirmTitle: string,
                            confirmMessage: string,
                            targetLocationType: LocationType,
                            targetLocationId: string,
                            transferItems: TransferItemTransferDateRequest[]): MatDialogRef<any> {
    const dialogRef = this.dialog.open(TransferCartConfirmationComponent, {
      maxWidth: 800,
      autoFocus: false,
      disableClose: true
    });
    dialogRef.componentInstance.confirmLabel = confirmLabel;
    dialogRef.componentInstance.denyLabel = denyLabel;
    dialogRef.componentInstance.confirmTitle = confirmTitle;
    dialogRef.componentInstance.confirmMessage = confirmMessage;
    dialogRef.componentInstance.minimalPossibleTransferDateRequest
      = new MinimalPossibleTransferDateRequest(targetLocationId, targetLocationType, transferItems);

    return dialogRef;
  }

  public waitingDialog(): MatDialogRef<WaitingComponent> {
    return this.dialog.open(WaitingComponent, {minWidth: 800, maxWidth: 800, height: '500px', disableClose: true});
  }

  public errorDialog(errorMessage: string): MatDialogRef<ErrorMessageComponent> {
    return this.dialog.open(ErrorMessageComponent, {
      data: {message: errorMessage},
      minWidth: 800,
      maxWidth: 800,
      height: '400px',
      disableClose: false,
    });
  }

  public summaryDialog(executedTransfers: ITransfer[],
                       transferItems: TransferItem[],
                       transferDestinationType: LocationType,
                       transferTarget: TransferProject | TransferStock): MatDialogRef<any> {

    const dialogRef = this.dialog.open(TransferCartSummaryComponent,
      {minWidth: 800, maxWidth: 800, height: 'auto', disableClose: true, autoFocus: false});

    dialogRef.componentInstance.executedTransfers = executedTransfers;
    dialogRef.componentInstance.transferItems = transferItems;
    dialogRef.componentInstance.transferDestinationType = transferDestinationType;
    dialogRef.componentInstance.transferTarget = transferTarget;
    dialogRef.componentInstance.transferItemsWithStatus = this.transferItemsWithStatus;

    return dialogRef;
  }

  public deselectTransferItems(): void {
    let transferItemsState = [...this._transferItems.getValue()];
    transferItemsState.forEach((transferItem: TransferItem) => {
      if (transferItem.transferAmount > 0) {
        transferItem.transferAmount = 0;
      }
    });
    this._transferItems.next(transferItemsState);
  }

  private getCurrentTransferAmount(amountId: string): number {
    return this.transferAmounts.get(amountId) ? this.transferAmounts.get(amountId) : 0;
  }

  private translate(key: string, interpolateParams?: any): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
