import { environment } from 'environments/environment';
import { LanguageService } from './../../../../shared/services/language.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardOptions } from '../../../../shared/wizard-options.interface';
import { EquipmentOverallData } from './mobile-equipment-add-overall-information-component/mobile-equipment-add-overall-data.component';
import {
  EquipmentGeneralData,
  MobileEquipmentAddGeneralDataComponent,
} from './mobile-equipment-add-general-information-component/mobile-equipment-add-general-data-component';
import {
  EquipmentTechnicalData,
  MobileEquipmentAddTechnicalDataComponent,
} from './mobile-equipment-add-technical-data-component/mobile-equipment-add-technical-data.component';
import { CreateEquipmentCommand } from '../../contract/create-equipment-command';
import { EquipmentsService } from '../../shared/equipments.service';
import { duration } from 'moment';
import { DeferredUploadService } from '../../../../shared/services/deferred-upload.service';
import { DatesService } from '../../../../shared/services/dates.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PairEquipmentScanCodeCommand } from '../../contract/pair-equipment-scan-code-command';
import { UpdateEquipmentTechnicalFieldsCommand } from '../../contract/update-equipment-technical-fields-command';
import { RouterHistory } from '../../../../shared/router-history';
import { AddFileUploadComponentChangeEvent } from '../../../../shared/contract/add-file-upload-component-change-event';
import { MobileEquipmentAddFileUploadComponent } from './mobile-equipment-add-file-upload-component/mobile-equipment-add-file-upload.component';
import { Router } from '@angular/router';
import { Modules } from '../../../../shared/enums/modules.enum';
import { RoleAuthorityGuardsComponent } from '../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../core/keycloak';

@Component({
  selector: 'bh-mobile-equipment-add',
  templateUrl: './mobile-equipment-add.component.html',
  styleUrls: ['./mobile-equipment-add.component.scss'],
  providers: [{
    provide: DeferredUploadService,
    useClass: DeferredUploadService,
    deps: [
      EquipmentsService
    ]
  }]
})
export class MobileEquipmentAddComponent extends RoleAuthorityGuardsComponent implements OnInit {

  @ViewChild(MobileEquipmentAddGeneralDataComponent, { static: true }) generalTab: MobileEquipmentAddGeneralDataComponent;
  @ViewChild(MobileEquipmentAddFileUploadComponent, { static: true }) fileUploadTab: MobileEquipmentAddFileUploadComponent;
  @ViewChild(MobileEquipmentAddTechnicalDataComponent, { static: true }) technicalTab: MobileEquipmentAddTechnicalDataComponent;
  public nextOrFinishEnabled = true;
  public backOrCancelEnabled = true;
  public wizardOptions: WizardOptions = {
    'equipment-add': [
      {
        key: 'overall-data',
        shortName: this.translate('general.baseData')
      },
      {
        key: 'general-data',
        shortName: this.translate('general.generalData'),
        optional: true
      },
      {
        key: 'technical-data',
        shortName: this.translate('modules.equipment.base.technicalData'),
        optional: true
      },
      {
        key: 'file-upload',
        shortName: this.translate('general.attachment.pl'),
        optional: true
      }
    ]
  };
  private overallData: EquipmentOverallData;
  private generalData: EquipmentGeneralData;
  private technicalData: EquipmentTechnicalData;
  private isUploading = false;
  private fromEquipmentList = false;

  constructor(protected authService: KeycloakService,
              private equipmentsService: EquipmentsService,
              private deferredUploadService: DeferredUploadService,
              private matSnackBar: MatSnackBar,
              private routerHistory: RouterHistory,
              private router: Router,
              private languageService: LanguageService) {
    super(authService);
  }

  public ngOnInit(): void {
    this.nextOrFinishEnabled = false;
    const {fromEquipmentList} = window.history.state;
    if (fromEquipmentList) {
      this.fromEquipmentList = true;
    }
  }

  public overallDataValuesChanged(equipmentOverallData: EquipmentOverallData): void {
    if (equipmentOverallData.name && this.generalTab.getModel() !== equipmentOverallData.name) {
      this.generalTab.setModel(equipmentOverallData.name);
    }

    if (!this.overallData || this.overallData.equipmentTypeId !== equipmentOverallData.equipmentTypeId) {
      this.generalTab.getManufacturers(equipmentOverallData.equipmentTypeId);
      this.technicalTab.equipmentTypeId = equipmentOverallData.equipmentTypeId;
      this.technicalTab.getAvailableTechnicalFields();
    }
    this.overallData = equipmentOverallData;
    this.nextOrFinishEnabled = this.formsValid();
  }

  public generalDataValuesChanged(equipmentGeneralData: EquipmentGeneralData): void {
    this.generalData = equipmentGeneralData;
    this.nextOrFinishEnabled = this.formsValid();
  }

  public technicalDataValuesChanged(equipmentTechnicalData: EquipmentTechnicalData): void {
    this.technicalData = equipmentTechnicalData;
  }

  public uploaderChanged(addFileUploadComponentChangeEvent: AddFileUploadComponentChangeEvent): void {
    this.isUploading = addFileUploadComponentChangeEvent.uploadInProgress;
  }

  public onCancel(): void {
    if (this.fromEquipmentList || !this.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      this.router.navigate(['mobile/equipments/list']);
    } else {
      this.router.navigate([`mobile/choose-create`]);
    }
  }

  public onFinish(): void {
    this.createEquipment();
  }

  private formsValid(): boolean {
    return !!(this.overallData && this.overallData.status === 'VALID'
        && this.generalData && this.generalData.status === 'VALID'
        && this.technicalData && this.technicalData.status === 'VALID');
  }

  private createEquipment(): void {
    const createEquipmentCommand = new CreateEquipmentCommand();
    createEquipmentCommand.equipmentSerialNumber = this.generalData.serialNumber || this.overallData.internalSerialNumber;
    createEquipmentCommand.equipmentCustomerSerialNumber = this.overallData.internalSerialNumber;
    createEquipmentCommand.equipmentName = this.overallData.name;
    createEquipmentCommand.equipmentModel = this.generalData.model || this.overallData.name;
    createEquipmentCommand.equipmentProductClass = this.generalData.productClass;
    createEquipmentCommand.equipmentCostCenter = this.generalData.costCenter;
    createEquipmentCommand.equipmentLicensePlate = this.generalData.licensePlate;
    createEquipmentCommand.equipmentGuarantee = duration(this.generalData.equipmentGuaranteeValue,
       this.generalData.equipmentGuaranteeUnit).toISOString();
    createEquipmentCommand.equipmentGuaranteeOperatingHoursLimit = null;
    if (this.generalData.constructionYear && this.generalData.constructionYear) {
      createEquipmentCommand.equipmentConstructionYear = this.generalData.constructionYear +
          '-' + this.generalData.constructionMonth;
    }
    createEquipmentCommand.equipmentDeliveryDate = DatesService.ospDateTimeAtStartOfDay(this.generalData.deliveryDate);
    createEquipmentCommand.equipmentTypeId = this.overallData.equipmentTypeId;
    createEquipmentCommand.manufacturerId = this.generalData.manufacturerId;
    createEquipmentCommand.organisationId = this.overallData.organisationId;
    createEquipmentCommand.stockId = this.overallData.stockId;

    this.equipmentsService.addEquipment(createEquipmentCommand).subscribe((equipmentId: string) => {
      this.deferredUploadService.updateQueueEntry(this.fileUploadTab.entryNumber, {
        directUpload: true,
        referencedEntityId: equipmentId
      });
      setTimeout(() => {
        this.addQrCode(equipmentId);
        this.addTechnicalFields(equipmentId);
        this.deferredUploadService.uploadAll();
        this.matSnackBar.open(
          this.translate('modules.equipment.equipmentAddEdit.wasCreated', { value: this.overallData.name }),
          this.translate('general.buttons.okay'),
          { duration: 3000 });
        if (this.fromEquipmentList || !this.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
          this.router.navigate([`mobile/equipments/view/${equipmentId}`]);
        } else {
          this.router.navigate([`mobile/choose-create`]);
        }
      }, environment.DELAY_LONG);
    }, () => {
      this.matSnackBar.open(
        this.translate('modules.equipment.equipmentAddEdit.errorDuringCreating'),
        this.translate('general.buttons.okay'),
        { duration: 3000 });
    });
  }

  private addTechnicalFields(equipmentId: string): void {
    if (this.technicalData) {
      const updateEquipmentTechnicalFieldsCommand = new UpdateEquipmentTechnicalFieldsCommand();
      updateEquipmentTechnicalFieldsCommand.equipmentId = equipmentId;
      updateEquipmentTechnicalFieldsCommand.technicalFields = Object.create(null);
      this.technicalData.fields.forEach(field => {
        updateEquipmentTechnicalFieldsCommand.technicalFields[field.technicalFieldId] = field.value;
      });
      this.equipmentsService.updateEquipmentTechnicalFields(updateEquipmentTechnicalFieldsCommand).subscribe(() => {
        console.log('technical fields add successfully');
      }, () => {
        this.matSnackBar.open(
          this.translate('modules.equipment.equipmentAddEdit.errorSavingTechFields'),
          this.translate('general.buttons.okay'),
          { duration: 3000 });
      });
    }
  }

  private addQrCode(equipmentId: string): void {
    if (this.overallData.qrCode) {
      const pairEquipmentScanCodeCommand = new PairEquipmentScanCodeCommand();
      pairEquipmentScanCodeCommand.equipmentId = equipmentId;
      pairEquipmentScanCodeCommand.equipmentScanCode = this.overallData.qrCode;
      this.equipmentsService.pairScanCode(pairEquipmentScanCodeCommand).subscribe(() => {
        this.routerHistory.goBack('');
      }, () => {
        this.matSnackBar.open(
          this.translate('modules.equipment.equipmentAddEdit.errorAddQRCode'),
          this.translate('general.buttons.okay'),
          { duration: 3000 });
      });
    }
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
