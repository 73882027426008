export class EmployeeQualificationAssignCommand {
  qualificationId: string;
  employeeId: string;
  qualificationAcquisitionDate: string;
  qualificationLastRenewal: string;

  constructor(employeeId: string,
      qualificationId: string,
      qualificationAcquisitionDate: string,
      qualificationLastRenewal: string) {
          this.employeeId = employeeId;
          this.qualificationId = qualificationId;
          this.qualificationAcquisitionDate = qualificationAcquisitionDate;
          this.qualificationLastRenewal = qualificationLastRenewal;
      }
}
