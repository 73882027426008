import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ViewRole } from '../contract/view-role.interface';
import { RolesService } from './roles.service';
import { DeleteRoleCommand } from '../contract/delete-role-command';
import { CreateRoleCommand } from '../contract/create-role-command';
import { UpdateRoleCommand } from '../contract/update-role-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class RolesStore extends DataSource<ViewRole> {

  private _roles: BehaviorSubject<ViewRole[]> = new BehaviorSubject<ViewRole[]>([]);
  private _currentRole: BehaviorSubject<ViewRole> = new BehaviorSubject(null);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _filterChange: any = new BehaviorSubject<any>('');
  private _filteredData: BehaviorSubject<ViewRole[]> = new BehaviorSubject<ViewRole[]>([]);

  public readonly roles: Observable<ViewRole[]> = this._roles.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();

  constructor(private _rolesService: RolesService) {
    super();
  }

  get data(): ViewRole[] {
    return this._roles.value;
  }

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  public connect(): Observable<ViewRole[]> {
    this.updateListing();
    return this._filteredData;
  }

  public disconnect() {

  }

  public updateListing(): void {
    if (this.filter) {
      const filteredData = this.data.slice().filter((item: ViewRole) => {
        let searchTerm = (item.name).toLowerCase();
        return searchTerm.indexOf(this.filter.toLowerCase()) !== -1;
      });
      this._filteredData.next(filteredData.slice());
    } else {
      this._filteredData.next(this.data);
    }
  }

  public getRoles(): void {
    this._rolesService
      .getRoles()
      .subscribe((res: PagedResponse<ViewRole>) => {
        this._roles.next(res.content);
        this.updateListing();
      });
  }

  public getRole(roleId: string): Observable<ViewRole> {
    return this._rolesService
      .getRole(roleId)
      .pipe(switchMap((res: ViewRole) => {
        this._currentRole.next(res);
        return of(res);
      }));
  }

  public addRole(cmd: CreateRoleCommand): Observable<string> {
    return this._rolesService
      .addRole(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getRoles();
        }, environment.DELAY_LONG);
        return of(res);
      }));
  }

  public updateRole(cmd: UpdateRoleCommand): Observable<string> {
    return this._rolesService
      .updateRole(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getRoles();
        }, environment.DELAY_LONG);
        return of(res);
      }));
  }

  public deleteRole(cmd: DeleteRoleCommand): Observable<string> {
    return this._rolesService
      .deleteRole(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getRoles();
        }, environment.DELAY_LONG);
        return of(res);
      }));
  }

  public getIsBrzRole(roleId: string): Observable<boolean> {
    return this._rolesService.getIsBrzRole(roleId);
  }

  public roleNameAvailable(roleName: string, realmType: string): Observable<boolean> {
    return this._rolesService.roleNameInUse(roleName, realmType);
  }
}
