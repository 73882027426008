import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bh-interval-calculation-base-dialog',
  styleUrls: ['./interval-calculation-base-dialog.component.scss'],
  templateUrl: './interval-calculation-base-dialog.component.html'
})
export class IntervalCalculationBaseDialogComponent implements OnInit {

  periodIntervalActive: boolean;
  mileageIntervalActive: boolean;
  operatingHoursIntervalActive: boolean;

  form: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<IntervalCalculationBaseDialogComponent>, private formBuilder: UntypedFormBuilder) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      periodCalculationBase: [{value: new Date(), disabled: !this.periodIntervalActive}],
      mileageCalculationBase: [{value: 0, disabled: !this.mileageIntervalActive}, [Validators.required, Validators.min(0)]],
      operatingHoursCalculationBase: [{value: 0, disabled: !this.operatingHoursIntervalActive}, [Validators.required, Validators.min(0)]]
    });
  }
}
