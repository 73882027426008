<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch" class="list-view">
  <mat-card class="list-view-main-container no-rounded-corners-left"
            fxFlex="{{ (displayService.isTableFullscreenChanges | async) ? 100 : 35}}"
            [ngClass]="{
      'list-item-hidden': (displayService.isDetailsFullscreenChanges | async),
      'list-item-margin': (displayService.isTableAndDetailsChanges | async) }">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div fxLayout="row" [fxLayoutAlign]="multiselectActive ||
        (!multiselectActive && !hasAuthority(authorities.TRANSPORT_TASK_DISPOSITION))? 'end center' : 'space-between center'"
             class="list-view-main__left-header">
          <button mat-button class="toggle-multiselect" fxLayout="row" fxLayoutAlign="center center"
                  [matTooltip]="'modules.equipment.equipmentList.activateSelection'|translate"
                  *ngIf="!multiselectActive && hasAuthority(authorities.TRANSPORT_TASK_DISPOSITION)"
                  (click)="toggleMultiselect(); multiMenu.toggle()">
            <mat-icon> playlist_add_check </mat-icon>
          </button>
          <div *ngIf="displayService.isTableFullscreenChanges | async" fxLayout="row" fxLayoutAlign="end center">

            <bh-page-switcher
              *ngIf="hasAuthority(authorities.TRANSPORT_TASK_DISPOSITION)"
              [active]="pageType.TRANSPORT_LIST">
            </bh-page-switcher>
            <div *ngIf="(displayService.isTableFullscreenChanges | async) && !multiselectActive"
                 fxFlex="60px"
                 fxLayout="row"
                 fxLayoutAlign="center center"
                 class="change-display-mode"
                 (click)="openDetails()"
                 [matTooltip]="'general.labels.detailView.expand'|translate">
              <mat-icon>chevron_left</mat-icon>
            </div>
          </div>
          </div>
        <div *ngIf="!(displayService.isTableFullscreenChanges | async)"
          class="list-view-main__left-body"
          fxFlex
          fxLayout="column"
          fxLayoutAlign="start stretch"
        >
          <ng-container *ngIf="(transports | async).length > 0; else noResult">
            <div *ngFor="let transport of transports | async"
              [routerLink]="[transport.transportId]"
              routerLinkActive="left-body-item--active">
              <bh-transport-list-item
                class="transport-list-search__result__item"
                [transport]="transport"
                [listView]="true">
                <div class="drag-item-preview" *cdkDragPreview fxLayout="row" fxLayoutAlign="start center">
                  <span class="drag-item-preview__title">{{transport?.title}}</span>
                </div>
              </bh-transport-list-item>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="displayService.isTableFullscreenChanges | async"
          class="list-view-main__left-body scrollable-table-container"
          fxFlex
          fxLayout="row"
          fxLayoutGap="15px"
          fxLayoutAlign="start stretch"
        >

          <mat-table class="pagination-data-table left-body-list"
            [dataSource]="transports"
            fxFlex
            matSort
            (matSortChange)="onSortChange($event)"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.columnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

            <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of selectedColumns">

              <ng-container *ngIf="column.type === COLUMN_TYPE.SELECT">
                <mat-header-cell *cdkHeaderCellDef class="transport-list-column select-column table-column-50 header-border-right"></mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="transport-list-column select-column table-column-50">
                  <mat-checkbox color="primary"
                                (click)="$event.stopPropagation()"
                                (change)="onCheckboxToggle(row, $event)"
                                [checked]="selected(row)"
                                [disabled]="inActiveTransport(row.state)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header [disabled]="column.disableSort" bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span>{{ column.valueCallback(row) }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.OTHER">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header [disabled]="column.disableSort" bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span class="task-number">#{{column.valueCallback(row)}}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">

                  <span [ngClass]="{'due-date-time-passed': column.cdkColumnDef === dueDateColumnDefinition && (row | isTransportDueDatePassed)}">
                    <span>
                      {{column.valueCallback(row) | formatTimezoneDateTime:{dateFormat: 'DD MMM YYYY', showTime: true, hideEndOfDayTime: true} }}
                    </span>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.TIME">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span>{{column.valueCallback(row) | formatDuration}}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span>
                    <bh-transport-state-badge [state]="column.valueCallback(row)"></bh-transport-state-badge>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.PRIORITY">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span>
                    <bh-transport-priority-badge [priority]="column.valueCallback(row)"></bh-transport-priority-badge>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <ng-container *ngIf="column.valueCallback(row) as transportItem">
                    <span *ngIf="(transportItem | formatTitleFromTransportItem) as transportItemTitle">
                      <span>{{transportItemTitle}}</span>
                      <span *ngIf="(transportItem | formatAddressFromTransport) as transportItemAddress">, {{transportItemAddress}}</span>
                    </span>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.RELATED_PROJECT">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <ng-container *ngIf="column.valueCallback(row) as relatedProject">
                    <span *ngIf="(relatedProject | formatTitleFromRelatedProject) as relatedProjectTitle">
                      <span>{{relatedProjectTitle}}</span>
                      <span *ngIf="(relatedProject | formatAddressFromTransport) as relatedProjectAddress">, {{relatedProjectAddress}}</span>
                    </span>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.EQUIPMENT_NAME">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="pagination-data-table__cell">
                  <span>{{column.valueCallback(row) | transportViewAssignmentTitle}}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  [routerLink]="[row.transportId]"
                  (click)="openDetails()"
                  [matTooltip]="'general.labels.openDetails'|translate">
                  <mat-icon>info</mat-icon>
                </mat-cell>
              </ng-container>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns"
              [routerLink]="[row.transportId]"
              (dblclick)="openDetails()"
              routerLinkActive="left-body-item--active">
            </mat-row>
          </mat-table>
        </div>

        <div class="list-view-main__left-body-paginator" fxLayout="row wrap" fxLayoutAlign="end">
          <div class="button-wrapper" fxFlex="80px">
            <button mat-icon-button class="rotatable" (click)="openConfiguration()"
                    [matTooltip]="'general.labels.confColumnArrangement'|translate">
              <mat-icon>settings</mat-icon>
            </button>
            <button mat-icon-button (click)="openTransportTaskListExport()"
                    [matTooltip]="'modules.transportation.transportList.exportTransportTasksList'|translate">
              <fa-icon [icon]="faFileExcel" size="2x"></fa-icon>
            </button>
          </div>
          <mat-paginator fxFlex="0"
            [length]="(pagination | async).totalElements"
            [pageIndex]="(pagination | async).index"
            [pageSize]="(pagination | async).size"
            [pageSizeOptions]="[5, 25, 50, 100]"
            bhCurrentItemPaginator
            [data]="transports | async"
            [currentItemId]="(currentTransport | async)?.transportId"
            (paginationChanges)="onPaginateChange($event)">
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="list-view-main-container"
            fxFlex="{{ (displayService.isDetailsFullscreenChanges | async) ? 100 : 65}}"
            [ngClass]="{'list-item-hidden': (displayService.isTableFullscreenChanges | async)}">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-drawer-container [ngClass]="{'drawer_multiselect': multiselectActive}">
    <mat-drawer #multiMenu mode="side" opened="false">
      <div class="multi-drawer-header" fxLayout="column" fxLayoutAlign="start stretch">
        <button mat-raised-button class="multi-drawer-header_select-all" (click)="selectAll()" color="primary">
          <span>{{'general.buttons.selectAll'|translate}}</span>
        </button>
      </div>
      <div class="functions-buttons" fxLayout="column" fxLayoutAlign="start">
        <div class="drawer_transport-count">{{selectedTransports.size
        + ' ' + ('modules.transportation.transportList.transportsSelected'|translate)}}</div>
        <button id="labelButton" mat-raised-button (click)="openLabelDialogue()" [disabled]="!elementsSelected()">
          {{'modules.transportation.transportStateChange.doneToArchiveBulk'|translate}}
        </button>
        <button mat-raised-button (click)="emptySelection()" [disabled]="!elementsSelected()">
          {{'general.buttons.deselect'|translate}}
        </button>
        <button mat-raised-button (click)="toggleMultiselect()">
          {{'general.buttons.cancel'|translate}}
        </button>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>

<ng-template #noResult>
  <div class="list-view-main__no-result" fxFlex fxLayout="row"
       fxLayoutAlign="center center">
    <div>{{'general.labels.noSearchResults'|translate}}</div>
  </div>
</ng-template>
