import { Component, Input } from '@angular/core';

@Component({
  selector: 'bh-configurable-dialog-fields',
  templateUrl: './configurable-dialog-fields.component.html',
  styleUrls: ['./configurable-dialog-fields.component.scss']
})
export class ConfigurableDialogFieldsComponent {
  @Input() item: any;
}
