<div class="m-subheader" fxLayout="row" fxLayoutAlign="start center">
  <div class="m-subheader__back-icon" fxFlex="46px">
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon [attr.aria-label]="'general.buttons.back'|translate">keyboard_arrow_left</mat-icon>
    </button>
  </div>

  <div class="m-subheader__text">
    <div class="title">
      {{title}}
    </div>
    <div class="subtitle">
      {{subtitle}}
    </div>
  </div>
</div>
