import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  CanActivate
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeycloakService } from '../keycloak';
import { PrivilegedRole } from '../../shared/enums/privileged-roles.enum';

@Injectable()
export class CanActivateRole implements CanActivate {
  constructor(private authService: KeycloakService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!route.data.hasOwnProperty('roles')) {
      throw Error('Route has no roles property');
    } else {
      let roles: Array<PrivilegedRole> = route.data['roles'];
      if (roles instanceof Array) {
        let found = false;
        roles.forEach(role => {
          if (this.authService.isInRole(role)) {
            found = true;
          }
        });
        return found;
      } else {
        throw Error('roles property is not an array');
      }
    }
  }
}
