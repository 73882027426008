import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceTask } from '../../tasks/shared/maintenance-task';
import { EquipmentLocationType } from '../contract/equipment-location-type-enum';

@Pipe({
  name: 'maintenanceTaskLocationTypeResolver'
})
export class MaintenanceTaskLocationTypeResolverPipe implements PipeTransform {

  public transform(task: MaintenanceTask): EquipmentLocationType {
    if (task.projectId) {
      return EquipmentLocationType.PROJECT;
    } else if (task.stockId) {
      return EquipmentLocationType.STOCK;
    }
    return null;
  }

}
