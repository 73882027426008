import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../equipment/shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ScanEquipment } from '../../../../equipment/shared/scan-equipment';
import { RouterHistory } from '../../../../../shared/router-history';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-assign-multiple-equipments',
  templateUrl: './mobile-transfer-multiple-equipments.component.html',
  styleUrls: ['./mobile-transfer-multiple-equipments.component.scss']
})
export class MobileTransferMultipleEquipmentsComponent implements OnInit, OnDestroy {

  equipmentList: ScanEquipment[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected equipmentStore: EquipmentsDataSource,
    protected routerHistory: RouterHistory) { }

  ngOnInit() {
    this.equipmentStore.scanEquipmentsForTransfer.pipe(untilDestroyed(this)).subscribe((equipments: ScanEquipment[]) => {
      this.equipmentList = equipments;
      if (equipments.length < 1) {
        this.routerHistory.goBack('');
      }
    });
  }

  ngOnDestroy(): void { }

  deleteEquipment(index: number): void {
    this.equipmentStore.removeScanEquipmentFromTransferList(index);
  }

  addEquipment(): void {
    this.router.navigate([`mobile/equipments/transfer-to-project/scan-code`], {queryParams: {listExists: true}});
  }

  listFinished(): void {
    this.equipmentStore.setScanEquipmentListForTransfer(this.equipmentList);
    if (this.equipmentList.length === 1) {
      this.router.navigate([`mobile/equipments/${this.equipmentList[0].equipmentId}/transfer-to-project`]);
    } else {
      this.router.navigate([`mobile/equipments/transfer-equipments-to-project`]);
    }
  }
}
