import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomersService } from '../../organisation/shared/customers.service';
import { SearchCustomer } from '../../organisation/contract/search-customer.interface';
import { AssignCustomerTelematicUnitToCustomerCommand } from '../shared/commands/action/assign-customer-telematic-unit-to-customer-command';
import { TelematicUnitAdministrationDatasource } from '../services/telematic-unit-administration.datasource';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
import { TelematicUnitListItem } from '../shared/telematic-unit-list.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AddressService } from '../../../shared/services/address.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { LanguageService } from '../../../shared/services/language.service';

@Component({
  selector: 'bh-telematic-unit-administration-assign',
  templateUrl: './telematic-unit-administration-assign.component.html',
  styleUrls: ['./telematic-unit-administration-assign.component.scss']
})
export class TelematicUnitAdministrationAssignComponent implements OnInit {
  public assignForm: UntypedFormGroup;
  private customers: Observable<SearchCustomer[]> = this.telematicUnitStore.filteredCustomersNames;
  public telematicsUnit: TelematicUnitListItem;
  public sucessIcon: IconDefinition = faCheckCircle;
  public showForm = true;
  public customerName: string;
  public selectedValue = {} as SearchCustomer;
  public customersNameFilter: UntypedFormControl = new UntypedFormControl();
  public customersDropdownList: any[];
  public selectedCustomer = {} as SearchCustomer;

  constructor(private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private customersService: CustomersService,
              private telematicUnitStore: TelematicUnitAdministrationDatasource,
              private addressService: AddressService,
              private languageService: LanguageService,
  ) {
    this.customers.subscribe(value => {
      this.customersDropdownList = [...value].map(item => {
        const address = item.customerAddress ? this.addressService.formatFullAddressSingleLine(
          item.customerAddress.postalCode,
          item.customerAddress.city,
          item.customerAddress.street,
          item.customerAddress.streetNumber
        ) : '';
        return {
          ...item,
          fields: [
            [{isBold: true, value: item.customerName}, {value: item.customerNumber}],
            [{value: address}, {value: item.shopId}]
          ]
        }
      });
    })
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.telematicsUnit.customerName) {
      this.selectedValue = {
        customerId: this.telematicsUnit.customerId,
        customerName: this.telematicsUnit.customerName
      } as SearchCustomer;
    }
    this.telematicUnitStore.getCustomers(this.telematicsUnit.partnerId).subscribe();
    this.filterCustomersOnInput();
  }

  private buildForm(): void {
    this.assignForm = this.formBuilder.group({
      customer: ['', Validators.required]
    })
  }

  public onCustomerChange(event: SearchCustomer) {
    this.selectedCustomer =
      this.customersDropdownList.find(customer => customer.customerId === event.customerId);
  }

  comparer(obj1: any, obj2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return obj1 && obj2 ? obj1.customerId === obj2.customerId : false;
  }

  private filterCustomersOnInput(): void {
    this.customersNameFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.telematicUnitStore.filterCustomersName(filterTerm));
  }

  public isDisabled() {
    if (this.selectedValue) {
      return this.selectedValue === this.assignForm.get('customer').value;
    }
  }

  public isValid(): boolean {
    return this.assignForm.valid;
  }


  public save() {
    if (this.isValid()) {
      if (this.telematicsUnit.customerId
        && this.telematicsUnit.equipmentIds
        && this.telematicsUnit.equipmentIds.length > 0) {
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
        dialogRef.componentInstance.confirmTitle = this.translate('modules.telematic.telematicAssign.reassign');
        dialogRef.componentInstance.confirmMessage = this.translate('modules.telematic.reassignWarning');
        dialogRef.afterClosed()
        .subscribe(result => {
          if (result === dialogResults.YES) {
            const formValue = this.assignForm.getRawValue();
            this.customerName = formValue.customer.customerName;
            const command: AssignCustomerTelematicUnitToCustomerCommand = new AssignCustomerTelematicUnitToCustomerCommand(
              this.telematicsUnit.customerTelematicsUnitId,
              formValue.customer.customerId,
            );
            this.telematicUnitStore.assignTelematicUnitToCustomer(command)
            .subscribe(() => {
              this.showForm = false;
            }, (error: HttpErrorResponse) => {
              console.log('ERROR: Error on adding Telematic Item: ', error);
            });
          }
        });

      } else {
        const formValue = this.assignForm.getRawValue();
        this.customerName = formValue.customer.customerName;
        const command: AssignCustomerTelematicUnitToCustomerCommand = new AssignCustomerTelematicUnitToCustomerCommand(
          this.telematicsUnit.customerTelematicsUnitId,
          formValue.customer.customerId,
        );
        this.telematicUnitStore.assignTelematicUnitToCustomer(command)
        .subscribe(() => {
          this.showForm = false;
        }, (error: HttpErrorResponse) => {
          console.log('ERROR: Error on adding Telematic Item: ', error);
        });
      }
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
