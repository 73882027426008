export enum Languages {
  DEFAULT = 'de-DE',
  EN = 'en-US',
  DE = 'de-DE',
  DK = 'da-DK',
  CZ = 'cs-CZ',
  NL = 'nl-NL',
  FR = 'fr-FR',
  SE = 'sv-SE',
  HU = 'hu-HU',
  IT = 'it-IT',
  NO = 'nb-NO',
  PL = 'pl-PL',
  PT = 'pt-PT',
  RU = 'ru-RU',
  RO = 'ro-RO',
  ES = 'es-ES',
  FI = 'fi-FI',
  JP = 'ja-JP',
  TH = 'th-TH',
  DEV = 'dev-DEV'
}

export enum LanguageMapper {
  en = 'EN',
  de = 'DE',
  dk = 'DK',
  cz = 'CZ',
  nl = 'NL',
  fr = 'FR',
  se = 'SE',
  hu = 'HU',
  it = 'IT',
  no = 'NO',
  pl = 'PL',
  pt = 'PT',
  ru = 'RU',
  ro = 'RO',
  es = 'ES',
  fi = 'FI',
  jp = 'JP',
  th = 'TH',
  dev = 'DEV',
}

export const LANGUAGE_LABEL_MAP = {
  [Languages.DEV]: 'DEV',
  [Languages.EN]: 'English',       // English
  [Languages.DE]: 'Deutsch',       // German
  [Languages.DK]: 'Dansk',         // Danish
  [Languages.CZ]: 'Čeština',       // Czech
  [Languages.NL]: 'Nederlands',    // Dutch
  [Languages.FR]: 'Français',      // French
  [Languages.SE]: 'Svenska',       // Swedish
  [Languages.HU]: 'Magyar',        // Hungarian (Magyar)
  [Languages.IT]: 'Italiano',      // Italian
  [Languages.NO]: 'Norsk',         // Norwegian
  [Languages.PL]: 'Polski',        // Polish
  [Languages.PT]: 'Português',     // Portuguese
  [Languages.RU]: 'Русский',       // Russian
  [Languages.RO]: 'Român',         // Romanian
  [Languages.ES]: 'Español',       // Spanish
  [Languages.FI]: 'Suomen kieli',  // Finnish
  [Languages.JP]: '日本語',         // Japanese
  [Languages.TH]: 'ภาษาไทย',       // Thai
}

export const LANGUAGE_SORT_KEY_MAP = {
  [Languages.EN]: 'EN',
  [Languages.DE]: 'DE',
  [Languages.DK]: 'DK',
  [Languages.CZ]: 'CZ',
  [Languages.NL]: 'NL',
  [Languages.FR]: 'FR',
  [Languages.SE]: 'SE',
  [Languages.HU]: 'HU',
  [Languages.IT]: 'IT',
  [Languages.NO]: 'NO',
  [Languages.PL]: 'PL',
  [Languages.PT]: 'PT',
  [Languages.RU]: 'RU',
  [Languages.RO]: 'RO',
  [Languages.ES]: 'ES',
  [Languages.FI]: 'FI',
  [Languages.JP]: 'JP',
  [Languages.TH]: 'TH',
}
