import { Component, Input } from '@angular/core';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';

type StatusNameMap = {
  [key in EquipmentStatusCategory]: string;
}

@Component({
  selector: 'bh-equipment-status-category-badge',
  templateUrl: './equipment-status-category-badge.component.html',
  styleUrls: ['./equipment-status-category-badge.component.scss']
})
export class EquipmentStatusCategoryBadgeComponent {
  @Input() set category(category: EquipmentStatusCategory) {
    this.statusCategory = category;
    this.className = this.statusNameMap[category];
  }
  public className: string;
  public statusCategory: EquipmentStatusCategory;

  private readonly statusNameMap: StatusNameMap = {
    [EquipmentStatusCategory.OPERATIONAL]: 'status-operational',
    [EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS]: 'status-operational-with-restrictions',
    [EquipmentStatusCategory.NOT_OPERATIONAL]: 'status-not-operational',
    [EquipmentStatusCategory.NOT_AVAILABLE]: 'status-not-available',
  };
}
