import { Component, EventEmitter, Output } from '@angular/core';
import {
  DeferredUploadEntry,
  EnrichedAttachedDocument,
} from '../../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DeferredUploadService } from '../../../../../shared/services/deferred-upload.service';
import { FileUtils } from '../../../../../shared/fileUtils';
import { AddFileUploadComponentChangeEvent } from '../../../../../shared/contract/add-file-upload-component-change-event';

@Component({
  selector: 'bh-mobile-equipment-add-file-upload',
  templateUrl: './mobile-equipment-add-file-upload.component.html',
  styleUrls: ['./mobile-equipment-add-file-upload.component.scss']
})
export class MobileEquipmentAddFileUploadComponent {

  public entryNumber: number = this.deferredUploadService.registerUpload();
  @Output() public OnChange: EventEmitter<AddFileUploadComponentChangeEvent> = new EventEmitter<AddFileUploadComponentChangeEvent>();
  public documents: EnrichedAttachedDocument[] = [];

  constructor(private domSanitizer: DomSanitizer,
              private deferredUploadService: DeferredUploadService) {
  }

  public onAllUploaded() {
    this.OnChange.emit({
      uploadInProgress: false,
    });
  }

  public getSafeStyle(url: string): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle('url("' + url + '")');
  }

  public deleteFile(enrichedAttachedDocument: EnrichedAttachedDocument): void {
    this.documents = this.documents.filter(currentDocument => {
      return currentDocument.documentKey !== enrichedAttachedDocument.documentKey;
    });
    this.deferredUploadService.removeEntry(this.entryNumber, enrichedAttachedDocument.documentKey);
  }

  public onFileUploaded(enrichedAttachedDocument: EnrichedAttachedDocument): void {
    enrichedAttachedDocument.url = FileUtils.getUrlFromFileItem(enrichedAttachedDocument.fileItem);
    this.documents.push(enrichedAttachedDocument);
    this.OnChange.emit({
      uploadInProgress: true
    });
  }

  public onDeferredUploadQueued(entry: DeferredUploadEntry): void {
    this.deferredUploadService.pushEntry(entry.deferredEntryNumber, entry.file);
  }
}
