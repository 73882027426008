import { TranslateModule } from '@ngx-translate/core';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { maintenanceRouting } from './routing/maintenance.routing';
import { MaintenanceTypeListComponent } from './types/maintenance-type-list/maintenance-type-list.component';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaintenanceTypeStore } from './types/maintenance-type-list/service/maintenance-type.store';
import { CommonModule } from '@angular/common';
import { MaintenanceTypeFieldService } from './types/maintenance-type-add-edit/service/maintenance-type-field.service';
import { MaintenanceTypeResolve } from './types/shared/service/maintenance-type.resolve';
import { MaintenanceTypeAddEditComponent } from './types/maintenance-type-add-edit/maintenance-type-add-edit.component';
import { MaintenanceRuleResolve } from './rules/shared/service/maintenance-rule.resolve';
import { SharedModule } from '../../shared/shared.module';
import { MaintenanceRuleListComponent } from './rules/maintenance-rule-list/maintenance-rule-list.component';
import { MaintenanceRuleStore } from './rules/shared/service/maintenance-rule.store';
import { MaintenanceTaskCompleteComponent } from './tasks/maintenance-task-complete/maintenance-task-complete.component';
import { MaintenanceTaskService } from './tasks/shared/service/maintenance-task.service';
import { MaintenanceTaskResolver } from './tasks/shared/service/maintenance-task.resolver';
import { MaintenanceEquipmentService } from './tasks/shared/service/maintenance-equipment.service';
import { MaintenanceRuleAssignedEquipmentsDialogComponent } from './rules/maintenance-rule-assigned-equipments-dialog/maintenance-rule-assigned-equipments-dialog.component';
import { MaintenanceTaskListComponent } from './tasks/maintenance-task-list/maintenance-task-list.component';
import { MaintenanceTaskDataSource } from './tasks/shared/service/maintenance-task.datasource';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from '../../shared/custom-date-adapter';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import { MaintenanceColumnService } from './tasks/shared/service/maintenance-column.service';
import { MaintenanceColumnDefinitionService } from './tasks/shared/service/maintenance-column-definition.service';
import { MaintenanceTaskViewComponent } from './tasks/maintenance-task-list/maintenance-task-view/maintenance-task-view.component';
import { MaintenanceFiltersService } from './tasks/shared/service/maintenance-filters.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MaintenanceTaskListTableComponent
} from './tasks/maintenance-task-list/maintenance-task-list-table/maintenance-task-list-table.component';
import {
  MaintenanceListExportDialogComponent
} from './tasks/maintenance-task-list/maintenance-list-export-dialog/maintenance-list-export-dialog.component';
import { MaintenanceConfigColumnService } from './shared/maintenance-config-column.service';
import { EquipmentLocationsFilterViewConverterFactoryService } from './tasks/shared/service/equipment-locations-filter-view-converter-factory.service';
import { MaintenanceTaskLocationTypeResolverPipe } from './shared/pipes/maintenance-task-location-type-resolver.pipe';
import { MaintenanceTaskCompletedListComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-list.component';
import { MaintenanceTaskCompletedListTableComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-list-table/maintenance-task-completed-list-table.component';
import { MaintenanceTaskCompletedDataSource } from './tasks/shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import { MaintenanceCompletedColumnService } from './tasks/shared/service/maintenance-completed-column.service';
import { MaintenanceCompletedColumnDefinitionService } from './tasks/shared/service/maintenance-completed-column-definition.service';
import { MaintenanceCompletedFiltersService } from './tasks/shared/service/maintenance-completed-filters.service';
import { MaintenanceTaskCompletedExportDialogComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-export-dialog/maintenance-task-completed-export-dialog.component';
import { MaintenanceTaskCompletedViewComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view.component';
import { MaintenanceTaskCompletedListItemComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-list-table/maintenance-task-completed-list-item/maintenance-task-completed-list-item.component';
import { MaintenanceTaskCompletedViewDetailComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view-detail/maintenance-task-completed-view-detail.component';
import { SortMaintenanceFieldsPipe } from './tasks/shared/pipes/sort-maintenance-fields.pipe';
import { MaintenanceTaskCompletedViewGeneralComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view-detail/maintenance-task-completed-view-general/maintenance-task-completed-view-general.component';
import { MaintenanceTaskCompletedViewAttachmentComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view-detail/maintenance-task-completed-view-attachment/maintenance-task-completed-view-attachment.component';
import { MaintenanceTaskCompletedViewHistoryComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view-detail/maintenance-task-completed-view-history/maintenance-task-completed-view-history.component';
import { MaintenanceTaskCompletedHistoryTableConverterPipe } from './shared/pipes/maintenance-task-completed-history-table-converter/maintenance-task-completed-history-table-converter.pipe';
import { MaintenanceTaskCompletedHistoryItemComponent } from './tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view-detail/maintenance-task-completed-view-history/maintenance-task-completed-history-item/maintenance-task-completed-history-item.component';
import { MaintenanceTaskCompletedHistoryFieldTranslationPipe } from './shared/pipes/maintenance-task-completed-history-table-converter/maintenance-task-completed-history-field-translation.pipe';
import { UpdateEquipmentStatusDialogComponent } from './tasks/maintenance-task-complete/update-equipment-status-dialog/update-equipment-status-dialog.component';
import { MaintenanceTaskListItemComponent } from './tasks/maintenance-task-list/maintenance-task-list-table/maintenance-task-list-item/maintenance-task-list-item.component';
import { MaintenanceTaskViewDetailComponent } from './tasks/maintenance-task-list/maintenance-task-view/maintenance-task-view-detail/maintenance-task-view-detail.component';
import { MaintenanceTaskViewGeneralComponent } from './tasks/maintenance-task-list/maintenance-task-view/maintenance-task-view-detail/maintenance-task-view-general/maintenance-task-view-general.component';
import { MaintenanceTaskViewGeneralRuleComponent } from './tasks/maintenance-task-list/maintenance-task-view/maintenance-task-view-detail/maintenance-task-view-general/maintenance-task-view-general-rule/maintenance-task-view-general-rule.component';
import { MaintenanceTaskViewGeneralReminderComponent } from './tasks/maintenance-task-list/maintenance-task-view/maintenance-task-view-detail/maintenance-task-view-general/maintenance-task-view-general-reminder/maintenance-task-view-general-reminder.component';
import { IntervalPanelFormGroupFactoryPipe } from './tasks/shared/service/interval-panel-form-group-factory/interval-panel-form-group-factory.pipe';
import { MaintenanceTaskDueDateEditComponent } from './tasks/shared/components/maintenance-task-due-date-edit/maintenance-task-due-date-edit.component';
import { MaintenanceTaskCompletedResolver } from './tasks/shared/service/maintenance-task-completed.resolver';
import { BhTableModule } from '../osp-ui/components/bh-table/bh-table.module';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    maintenanceRouting,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedModule,
    TranslateModule,
    MainPipeModule,
    DragDropModule,
    BhTableModule,
  ],
  declarations: [
    MaintenanceTypeListComponent,
    MaintenanceTypeAddEditComponent,
    MaintenanceRuleListComponent,
    MaintenanceTaskCompleteComponent,
    UpdateEquipmentStatusDialogComponent,
    MaintenanceRuleAssignedEquipmentsDialogComponent,
    MaintenanceTaskListComponent,
    MaintenanceTaskListTableComponent,
    MaintenanceTaskViewComponent,
    MaintenanceListExportDialogComponent,
    MaintenanceTaskLocationTypeResolverPipe,
    MaintenanceTaskCompletedListComponent,
    MaintenanceTaskCompletedListTableComponent,
    MaintenanceTaskCompletedExportDialogComponent,
    MaintenanceTaskCompletedViewComponent,
    MaintenanceTaskCompletedListItemComponent,
    MaintenanceTaskCompletedViewDetailComponent,
    MaintenanceTaskCompletedViewGeneralComponent,
    SortMaintenanceFieldsPipe,
    MaintenanceTaskCompletedViewAttachmentComponent,
    MaintenanceTaskCompletedViewHistoryComponent,
    MaintenanceTaskCompletedHistoryTableConverterPipe,
    MaintenanceTaskCompletedHistoryItemComponent,
    MaintenanceTaskCompletedHistoryFieldTranslationPipe,
    MaintenanceTaskViewDetailComponent,
    MaintenanceTaskListItemComponent,
    MaintenanceTaskViewGeneralComponent,
    MaintenanceTaskViewGeneralRuleComponent,
    MaintenanceTaskViewGeneralReminderComponent,
    IntervalPanelFormGroupFactoryPipe,
    MaintenanceTaskDueDateEditComponent,
  ],
  providers: [
    MaintenanceTypeStore,
    MaintenanceTypeFieldService,
    MaintenanceTypeResolve,
    MaintenanceRuleResolve,
    MaintenanceRuleStore,
    MaintenanceConfigColumnService,
    MaintenanceTaskService,
    MaintenanceTaskResolver,
    MaintenanceTaskCompletedResolver,
    MaintenanceEquipmentService,
    MaintenanceTaskDataSource,
    MaintenanceColumnService,
    MaintenanceColumnDefinitionService,
    MaintenanceFiltersService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    EquipmentLocationsFilterViewConverterFactoryService,
    MaintenanceTaskCompletedHistoryFieldTranslationPipe,
  ]
})
export class MaintenanceModule {
  static forRoot(): ModuleWithProviders<MaintenanceModule> {
    return {
      ngModule: MaintenanceModule,
      providers: [
        MaintenanceTaskCompletedDataSource,
        MaintenanceCompletedFiltersService,
        MaintenanceCompletedColumnDefinitionService,
        MaintenanceCompletedColumnService,
      ]
    }
  }
}
