import { ExpandingTreeSelectionMode } from 'app/shared/components/expanding-tree/contracts/expanding-tree-selection-mode.enum';
import { environment } from 'environments/environment';
import { StepSelectionPayload } from '../../../../shared/directives/step-header-overlay.directive';
import { UpdateUserCommand } from '../../contract/update-user-command';
import { ExpandingTreeComponent } from 'app/shared/components/expanding-tree/component/expanding-tree.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, delay, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'app/core/keycloak';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { emailValidator } from 'app/shared/custom-validators/email.validator';
import { validateCombinations } from 'app/shared/enums/authorities.enum';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { ViewUser } from '../../contract/view-user.interface';
import { ViewCustomer } from 'app/modules/organisation/contract/view-customer.interface';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { ViewRoleCustom } from '../../contract/view-role-custom.interface';
import { RolesService } from '../../shared/roles.service';
import { UsersStore } from '../../shared/users.store';
import { CustomersService } from 'app/modules/organisation/shared/customers.service';
import { PrivilegedRole } from 'app/shared/enums/privileged-roles.enum';
import { ViewRole } from '../../contract/view-role.interface';
import { asyncValidatorFactory } from 'app/shared/custom-validators/async-validator.factory';
import { EmailInUseValidator } from 'app/shared/custom-validators/email-in-use.validator';
import { CreateUserCommand } from '../../contract/create-user-command';
import { RoleConflictSnackbarComponent } from 'app/shared/components/role-conflict-snackbar/role-conflict-snackbar.component';
import { UserAddEditStepHeader } from './user-add-edit-step-header.enum';
import { UserAddEditConfigData } from './user-add-edit-config-data.interface';
import { UsersService } from '../../shared/users.service';

@UntilDestroy()
@Component({
  selector: 'bh-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss']
})
export class UserAddEditComponent implements OnInit, OnDestroy {
  @ViewChild('tree') tree: ExpandingTreeComponent;
  public customerId: string;
  public user: ViewUser;
  public selectionMode = ExpandingTreeSelectionMode.DENY_PARENT_AUTO_DESELECT;
  public faUserPlus = faUserPlus;
  public customer: ViewCustomer;
  public today: Date = new Date();
  public organisations: ViewOrganisation[];
  public searchTermOrgControl = new UntypedFormControl();
  public searchTermOrg: string;
  public customizedRoles: ViewRoleCustom[] = [];
  public searchTermRoleControl = new UntypedFormControl();
  public userForm: UntypedFormGroup;
  public fieldLimit = FieldLimit;
  public stepHeaders = [
    UserAddEditStepHeader.GENERAL,
    UserAddEditStepHeader.ORGANISATIONS,
    UserAddEditStepHeader.ROLES];
  public readonly isCurrentUser: boolean;
  private rolesIdsHidden: string[] = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) dialogData: UserAddEditConfigData,
      private usersService: UsersService,
      public authService: KeycloakService,
      private formBuilder: UntypedFormBuilder,
      private rolesService: RolesService,
      private usersStore: UsersStore,
      private snackBar: MatSnackBar,
      private dialogRef: MatDialogRef<UserAddEditComponent>,
      private customersService: CustomersService,
      private languageService: LanguageService,
  ) {
    this.customerId = dialogData?.customerId;
    this.user = dialogData?.user;
    this.isCurrentUser = this.user?.userId === this.authService.getUserUserId();
  }

  public get generalDataControl(): AbstractControl {
    return this.userForm.get('generalData');
  }

  public get emailControl(): AbstractControl {
    return this.generalDataControl.get('email');
  }

  get lastNameControl(): AbstractControl {
    return this.generalDataControl.get('lastName');
  }

  get validUntilControl(): AbstractControl {
    return this.generalDataControl.get('validUntil');
  }

  public get organisationIdsControl(): AbstractControl {
    return this.userForm.get('organisationIds');
  }

  get roleIdsControl(): UntypedFormArray {
    return this.userForm.get('roleIds') as UntypedFormArray;
  }

  public ngOnInit(): void {
    this.getCustomer();
    this.buildForm();

    this.initListeners();
    this.getRoles();

    if(!this.isCurrentUser) {
      this.getOrganisations();
    }
  }

  public ngOnDestroy(): void {
  }

  public getDialogTitle(): string {
    const customerName = this.customer && this.customer.customerName;
    if (this.user) {
      return this.translate('modules.userrole.base.editUser');
    } else {
      return customerName
        ? this.translate('modules.userrole.userAddEdit.createNewUser') + ' '
          + this.translate('modules.userrole.userAddEdit.forCustomer') + ' '
          + customerName
        : this.translate('modules.userrole.userAddEdit.createNewUser');
    }
  }

  public isValid(): boolean  {
    return this.userForm.valid;
  }

  public isRoleHidden({ roleId }: ViewRoleCustom): boolean {
    return this.rolesIdsHidden.includes(roleId);
  }

  public areRolesNotFound(): boolean {
    return (this.rolesIdsHidden && this.customizedRoles)
      && (this.customizedRoles.length === 0 || this.rolesIdsHidden.length === this.customizedRoles.length);
  }

  public selectRole(role: ViewRoleCustom): void {
    if (!role) {
      return;
    }
    let roleConflicts: string = this.rolesHaveAuthorityConflict(role);
    if (roleConflicts !== '' && !role.isSelected) {
      this.openRoleAuthConflictSnackbar();
    } else {
      role.isSelected = !role.isSelected;

      if (role.isSelected) {
        role.isSelected = true;
        this.roleIdsControl.push(new UntypedFormControl(role.roleId));
      } else {
        let index: number = this.roleIdsControl.value.findIndex(roleId => role.roleId === roleId);
        this.roleIdsControl.removeAt(index);
      }

      this.roleIdsControl.markAsDirty();
      this.roleIdsControl.markAsTouched();
    }
  }

  public save(): void {
    if (this.isValid()) {
      let formValue = this.userForm.getRawValue();
      const cmd = this.getUserCommand(formValue);

      if (this.user) {
        this.updateUser(<UpdateUserCommand>cmd);
      } else {
        this.createUser(<CreateUserCommand>cmd);
      }
    }
  }

  private getUserCommand(formValue: any): CreateUserCommand | UpdateUserCommand {
    const validUntil = formValue.generalData.validUntil
      ? new Date(formValue.generalData.validUntil).toISOString()
      : null;
    let cmd = {
      firstName: this.trim(formValue.generalData.firstName),
      name: this.trim(formValue.generalData.lastName),
      mobileNumber: this.trim(formValue.generalData.mobileNumber),
      roleIds: formValue.roleIds,
      organisationIds: formValue.organisationIds,
      validUntil: validUntil,
      customerId: this.customerId
    }

    if (this.user) {
      return { ...cmd, userId: this.user.userId };
    } else {
      return { ...cmd, email: this.trim(formValue.generalData.email) };
    }
  }

  private createUser(cmd: CreateUserCommand): void {
    this.usersStore.addUser(cmd)
      .pipe(delay(environment.DELAY_SHORT), untilDestroyed(this))
      .subscribe(
        () => this.dialogRef.close(dialogResults.SAVE),
        (error: HttpErrorResponse) => console.log('save user error: ', error)
      );
  }

  private updateUser(cmd: UpdateUserCommand): void {
    this.usersStore.updateUser(cmd)
      .pipe(delay(environment.DELAY_SHORT), untilDestroyed(this))
      .subscribe(
        () => this.dialogRef.close(dialogResults.SAVE),
        (error: HttpErrorResponse) => console.log('update user error: ', error)
      );
  }

  public searchPredicate(item: ViewOrganisation, term: string): boolean {
    return !term || item.organisationName.toLowerCase().includes(term.toLowerCase());
  }

  public tooltipTextPredicate = (item: ViewOrganisation) => item?.disabled
    ? this.translate('modules.userrole.base.nonBelongedOrganisationWarning')
    : null;

  public headerClick(stepSelectionPayload: StepSelectionPayload) {
    this.checkAndUpdateOrganisationIdsControl(stepSelectionPayload);
    this.checkAndUpdateRoleIdsControl(stepSelectionPayload);
  }

  private checkAndUpdateOrganisationIdsControl({ previous, current, clicked }: StepSelectionPayload): void {
    if (previous === UserAddEditStepHeader.ORGANISATIONS
      || (current === UserAddEditStepHeader.ORGANISATIONS && clicked !== current)) {
      this.organisationIdsControl.markAsTouched();
    }
  }

  private checkAndUpdateRoleIdsControl({ previous }: StepSelectionPayload): void {
    if (previous === UserAddEditStepHeader.ROLES) {
      this.roleIdsControl.markAsTouched();
    }
  }

  private initListeners(): void {
    this.searchTermOrgControl.valueChanges
    .pipe(
      untilDestroyed(this),
      debounceTime(300))
    .subscribe((term: string) => this.searchTermOrg = this.trim(term));

    this.searchTermRoleControl.valueChanges
    .pipe(
      untilDestroyed(this),
      debounceTime(300))
    .subscribe((term: string) => this.updateRoleIdsHidden(this.trim(term)));
  }

  private buildForm(): void {
    this.userForm = this.formBuilder.group({
      generalData: this.formBuilder.group({
        firstName: null,
        lastName: [null, [Validators.required, Validators.pattern('^.*[\\S].*$')]],
        email: [null, [Validators.required, emailValidator()]],
        mobileNumber: null,
        validUntil: null
      }),
      organisationIds: [null, Validators.required],
      roleIds: this.formBuilder.array([], Validators.compose([Validators.required]))
    });

    this.setValidators();
    this.patchForm();
  }

  private patchForm(): void {
    if (this.user) {
      this.userForm.patchValue({
        generalData: {
          firstName: this.user.firstName,
          lastName: this.user.name,
          email: this.user.email,
          mobileNumber: this.user.mobileNumber,
          validUntil: this.user.validUntil
        },
        organisationIds: this.user.organisationList ? [...this.user.organisationList] : null
      });

      if (this.user.roles) {
        this.customizedRoles = this.customizedRoles.map(role => ({ ...role, isSelected: false }));
        this.user.roles.forEach(({ id }) => this.roleIdsControl.push(new UntypedFormControl(id)));
      }
    }
  }

  private setValidators(): void {
    this.emailControl.setAsyncValidators(asyncValidatorFactory(
      (value) => EmailInUseValidator.isValid(value, this.usersStore, this.user ? this.user.email : undefined)));
  }

  private getCustomer(): void {
    this.customerId = this.getCustomerId();
    this.customersService.getCustomer(this.customerId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewCustomer) => this.customer = res);
  }

  private getCustomerId(): string {
    return this.user && this.user.customer
      ? this.user.customer
      : this.customerId || this.authService.getUserCustomerId();
  }

  private getOrganisations(): void {
    this.usersService.getOrganisationsTreeByCustomer(this.customerId)
      .pipe(untilDestroyed(this))
      .subscribe(res => this.organisations = res);
  }

  private getRoles(): void {
    const customerRoles = this.authService.isSuperOrPartnerAdmin()
      ? this.rolesService.getRolesByCustomer(this.customerId)
      : this.rolesService.getRolesByUserCustomer();

    customerRoles
      .pipe(
        untilDestroyed(this),
        map((res: ViewRole[]) => [...res.filter(({ name }) => !(name in PrivilegedRole))]))
      .subscribe((res: ViewRole[]) => this.customizedRoles =
        res.map(role => ({ ...role, isSelected: this.hasUserRole(role.roleId) })));
  }

  private hasUserRole(roleId: string): boolean {
    return this.user && this.user.roles && this.user.roles.some(({ id }) => id === roleId);
  }

  private updateRoleIdsHidden(term: string): void {
    this.rolesIdsHidden = this.customizedRoles
      ? this.customizedRoles
          .filter(({ name }) => !name.toLowerCase().includes(term.toLowerCase()))
          .map(({ roleId }) => roleId)
      : [];
  }

  private trim(value: string): string {
    return value && value.trim();
  }

  private openRoleAuthConflictSnackbar() {
    this.snackBar.openFromComponent(RoleConflictSnackbarComponent, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'roleConflictSnackBar',
    });
  }

  private rolesHaveAuthorityConflict(newRole: ViewRoleCustom): string {
    const selectedRoles: ViewRoleCustom[] =
      this.customizedRoles.filter(role => role.isSelected || role.roleId === newRole.roleId);
    const totalAuthorities =
      selectedRoles.reduce((acc, { authorities }) => [...acc, ...(authorities ? authorities : [])], []);

    return validateCombinations(totalAuthorities).join();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
