import { Address } from '../../organisation/contract/address.interface';
import { ViewProject } from '../contract/view-project.interface';
import { OrganisationInfo } from './services/organisation-info.interface';
import { LatLon } from '../../../shared/geolocation/lat-lon.interface';

export class DispositionProject {

  static fromViewProject(viewProject: ViewProject): DispositionProject {
    return new DispositionProject(
      viewProject.projectId,
      viewProject.projectName,
      viewProject.projectStart,
      viewProject.projectEnd,
      viewProject.chargeDate,
      viewProject.projectStatus,
      viewProject.projectNumber,
      viewProject.projectCostCenter,
      viewProject.projectAddress,
      viewProject.organisation,
      viewProject.location
    );
  }

  constructor(public projectId: string,
              public projectName: string,
              public projectStart: Date,
              public projectEnd: Date,
              public chargeDate: Date,
              public projectStatus: string,
              public projectNumber: string,
              public projectCostCenter: string,
              public projectAddress: Address,
              public organisation: OrganisationInfo,
              public location: LatLon) {}

}

export function instanceOfDispositionProject(object: any): object is DispositionProject {
  return 'projectId' in object
    && 'projectName' in object
    && 'organisation' in object
    && 'location' in object;
}
