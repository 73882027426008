<bh-basic-widget [widget]="widget"
                 [index]="index"
                 (deleted)="onDeleted()">
  <ng-container widget-content>
    <div #scrollContainer class="widget-main-content">
      <div class="transfer-history"
           infiniteScroll
           [scrollWindow]="false"
           [infiniteScrollDistance]="3"
           [infiniteScrollThrottle]="150"
           [infiniteScrollContainer]="scrollContainer"
           (scrolled)="onScroll()">
        <bh-type-of-use-change-request *ngFor="let request of requests"
                                       [request]="request"
                                       [showAcceptAndDeclineBtns]="showAcceptAndDeclineBtns"
                                       [showDeleteBtn]="showDeleteBtn">
        </bh-type-of-use-change-request>
        <bh-text-line *ngIf="endOfList | async" [text]="'modules.disposition.projectTransferHistory.endOfList'|translate">
        </bh-text-line>
        <bh-text-line *ngIf="noEntries | async" [text]="'modules.disposition.projectTransferHistory.noAvailableEntries'|translate">
        </bh-text-line>
      </div>
    </div>
  </ng-container>
</bh-basic-widget>
