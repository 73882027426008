import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { UserConfigurationService } from '../services/user-configuration.service';
import { DimensionSystem } from '../enums/dimension-system.enum';
import { normalizeUnitType } from './dimension-unit.utils';
import { TransferItemUnit } from '../../modules/disposition/contract/transfer-item-unit';
import { AllMeasuresUnits } from 'convert-units/definitions/all';

@Pipe({name: 'dimensionUnitDisplay'})
export class DimensionUnitPipe implements PipeTransform {

  readonly dimensionsMap = {
    ['pcs']: {
      metric: this.languageService.getInstant('general.units.piece.s_pl'),
      imperial: this.languageService.getInstant('general.units.piece.s_pl')
    },
    ['km']: {
      metric: this.languageService.getInstant('general.units.distance.km.abbr'),
      imperial: this.languageService.getInstant('general.units.distance.mi.abbr')
    },
    ['m']: {
      metric: this.languageService.getInstant('general.units.distance.m.s'),
      imperial: this.languageService.getInstant('general.units.distance.ft.abbr')
    },
    ['cm']: {
      metric: this.languageService.getInstant('general.units.distance.cm.abbr'),
      imperial: this.languageService.getInstant('general.units.distance.in.abbr')
    },
    ['mt']: {
      metric: this.languageService.getInstant('general.units.weight.t.abbr'),
      imperial: this.languageService.getInstant('general.units.weight.t.abbr')
    },
    ['kg']: {
      metric: this.languageService.getInstant('general.units.weight.kg.abbr'),
      imperial: this.languageService.getInstant('general.units.weight.lb.abbr')
    },
    ['g']: {
      metric: this.languageService.getInstant('general.units.weight.g.abbr'),
      imperial: this.languageService.getInstant('general.units.weight.oz.abbr')
    },
    ['l']: {
      metric: this.languageService.getInstant('general.units.volume.l.abbr'),
      imperial: this.languageService.getInstant('general.units.volume.qt.abbr')
    },
    ['ml']: {
      metric: this.languageService.getInstant('general.units.volume.ml.abbr'),
      imperial: this.languageService.getInstant('general.units.volume.flOz.abbr')
    },
    ['m3']: {
      metric: this.languageService.getInstant('general.units.volume.cbm.abbr'),
      imperial: this.languageService.getInstant('general.units.volume.ft3.abbr')
    },
    ['ream']: {
      metric: this.languageService.getInstant('general.units.pack.s_pl'),
      imperial: this.languageService.getInstant('general.units.pack.s_pl')
    }
  };

  constructor(private languageService: LanguageService,
              private userConfigurationService: UserConfigurationService) {}

  public transform(unit: string | AllMeasuresUnits | TransferItemUnit): string {
    return this.getUserDimensionUnit(unit);
  }

  public getUserDimensionUnit(unit: string | AllMeasuresUnits | TransferItemUnit): string {
    let valueUnitType = normalizeUnitType(unit);
    let dimensionUnitNames = this.dimensionsMap[valueUnitType];

    if (!!dimensionUnitNames) {
      return this.isMetricSystem()
        ? dimensionUnitNames.metric
        : dimensionUnitNames.imperial;
    }

    return unit;
  }

  private isMetricSystem(): boolean {
    return this.userConfigurationService.getUserDimensionSystem() === DimensionSystem.METRIC;
  }
}
