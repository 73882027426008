<div class="many-requests-dialog">
  <div class="header-row" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="dialog-close-icon">
      <button mat-icon-button aria-label="clear" (click)="close()" tabindex="-1">
        <mat-icon aria-hidden="true">clear</mat-icon>
      </button>
    </div>
    <h1 mat-dialog-title>{{"modules.disposition.assetChangeTypeOfUse.selectFreeOfChargeStatus"| translate}}</h1>
  </div>
  <div mat-dialog-content fxLayout="column" class="requests-content" fxLayoutAlign="start stretch">
    <div fxLayout="row" class="request-div" fxLayoutAlign="space-between center">
      <div>
        {{"general.asset"| translate}}
      </div>
      <span>
        {{"general.quantity"| translate}}
      </span>
      <span>
        {{"general.status"| translate}}
      </span>
    </div>

    <div *ngIf="!isDialogForAcceptance()">
      <div *ngFor="let request of requests" fxLayout="row" class="request-div" fxLayoutAlign="space-between center">
        <div class="request-column" fxFlex="45" fxLayout="column" fxLayoutAlign="start start">
        <span class="upper-row">
          {{request.transferItem.name}}
        </span>
          <span class="lower-row">
          {{request.transferItem.sourceName}}
        </span>
        </div>
        <div class="request-column" fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
        <span>
          {{request.transferItem.transferAmount}}
        </span>
        </div>
        <div fxFlex="45" fxLayout="column">
          <div fxFlex="60" fxLayout="column" fxLayoutAlign="end end">
            <mat-spinner class="request-spinner"  fxLayout="column" fxLayoutAlign="end end"
                         *ngIf="request.status === 'PENDING'" [diameter]="20" [strokeWidth]="3">
            </mat-spinner>
            <div *ngIf="request.status !== 'PENDING'" fxLayout="column" fxLayoutAlign="end end">
              <fa-icon [class]="resolveIconClass(request.status)" [icon]="resolveStatusIcon(request.status)">
              </fa-icon>
            </div>
          </div>
          <div fxFlex="40" fxLayout="column" fxLayoutAlign="end end">
          <span *ngIf="request.errorText" class="error-span">
            {{request.errorText}}
          </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isDialogForAcceptance()">
      <div *ngFor="let request of requestsAccept" fxLayout="row" class="request-div" fxLayoutAlign="space-between center">
        <div class="request-column" fxFlex="45" fxLayout="column" fxLayoutAlign="start start">
        <span class="upper-row">
          {{request.transferItem.name}}
        </span>
          <span class="lower-row">
          {{request.transferItem.sourceName}}
        </span>
        </div>
        <div class="request-column" fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
        <span>
          {{request.transferItem.transferAmount}}
        </span>
        </div>
        <div fxFlex="45" fxLayout="column">
          <div fxFlex="60" fxLayout="column" fxLayoutAlign="end end">
            <mat-spinner class="request-spinner"  fxLayout="column" fxLayoutAlign="end end"
                         *ngIf="request.status === 'PENDING'" [diameter]="20" [strokeWidth]="3">
            </mat-spinner>
            <div *ngIf="request.status !== 'PENDING'" fxLayout="column" fxLayoutAlign="end end">
              <fa-icon [class]="resolveIconClass(request.status)" [icon]="resolveStatusIcon(request.status)">
              </fa-icon>
            </div>
          </div>
          <div fxFlex="40" fxLayout="column" fxLayoutAlign="end end">
          <span *ngIf="request.errorText" class="error-span">
            {{request.errorText}}
          </span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button *ngIf="requestCount===0" (click)="close()"
            class="mat-primary mat-mdc-raised-button mdc-button mdc-button--raised" color="primary">
      {{'general.buttons.ok'|translate}}
    </button>
  </div>
</div>
