<bh-step-dialog [icon]="faFileContract"
                [title]="updatedContractId
                  ? ('modules.equipment.contract.editContract'|translate)
                  : ('modules.equipment.contract.addContract'|translate)">
  <mat-horizontal-stepper linear labelPosition="bottom">
    <!-- Contract type -->
    <mat-step [stepControl]="contractTypeControl" *ngIf="!updatedContractId" >
      <ng-template matStepLabel>{{'modules.equipment.contract.stepTitles.contractType'|translate}} *</ng-template>
      <div class="step-content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="25px">
        <bh-card-select class="type-select"
          [options]="contractTypesOptions"
          [optionResolver]="equipmentContractTypeResolver"
          [formControl]="contractTypeControl"
          ngDefaultControl>
        </bh-card-select>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
        <div class="button-wrapper" fxLayoutGap="15px">
          <button fxLayoutAlign="end center"
                  mat-raised-button
                  [disabled]="contractTypeControl.invalid"
                  matStepperNext>
            {{'general.buttons.continue'|translate}}
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Settings -->
    <mat-step [stepControl]="getContractForm()">
      <ng-template matStepLabel>{{'modules.equipment.contract.stepTitles.settings'|translate}} *</ng-template>
      <div class="step-content" fxFlex="65" fxLayout="column">
        <!--Full Service / Rental Contract Form-->
        <div *ngIf="isContractType(equipmentContractTypes.RENTAL_FULL_SERVICE_CONTRACT)
               || isContractType(equipmentContractTypes.RENTAL_CONTRACT)"
             class="contract-creation-form"
             fxLayout="row"
             fxLayoutAlign="space-between center">
          <form [formGroup]="rentalContractForm" novalidate fxFlexFill
                fxLayout="column"
                fxFlexAlign="space-between center"
                fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="rentalContractNumber"
                       [maxlength]="fieldLimit.LONG_IDENTIFIER">
                <mat-error *ngIf="rentalContractForm.get('rentalContractNumber').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.contractNumber'|translate) } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
                <input matInput
                       bhRemoveWhitespaces
                       formControlName="contractPartner"
                       [maxlength]="fieldLimit.SHORT_TEXT">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.labels.beginning'|translate}}</mat-label>
                <input matInput required
                       formControlName="rentalStartDate" autocomplete="off"
                       [matDatepicker]="rentalStartDatePicker"
                       [max]="getRentalEndDate()">
                <mat-datepicker-toggle matSuffix [for]="rentalStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #rentalStartDatePicker></mat-datepicker>
                <mat-error *ngIf="rentalContractForm.get('rentalStartDate').hasError('required')">
                  {{'shared.validation.required'|translate:{ value: ('general.startDate'|translate) } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.labels.end'|translate}}</mat-label>
                <input matInput required
                       formControlName="rentalEndDate" autocomplete="off"
                       [matDatepicker]="rentalEndDatePicker"
                       [min]="getRentalStartDate()">
                <mat-datepicker-toggle matSuffix [for]="rentalEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #rentalEndDatePicker></mat-datepicker>
                <mat-error *ngIf="rentalContractForm.get('rentalEndDate').hasError('required')">
                  {{'shared.validation.required'|translate:{ value: ('general.endDate'|translate) } }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.operatingHoursIncluded'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="rentalMileageLimitPeriod"
                       min="0"
                       bhMaxLength="7">
                <mat-error *ngIf="rentalContractForm.get('rentalMileageLimitPeriod').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.period'|translate}}</mat-label>
                <mat-select formControlName="period">
                  <mat-option *ngFor="let option of rentalOptions" [value]="option">{{option.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{(isContractType(equipmentContractTypes.RENTAL_CONTRACT)
                       ? 'modules.equipment.contract.rentalRate'
                       : 'modules.equipment.contract.fullServiceRate')|translate}}</mat-label>
                <input matInput type="number"
                       formControlName="amount"
                       min="1"
                       bhMaxLength="15">
                <mat-error *ngIf="rentalContractForm.get('amount').hasError('min')">
                  {{'shared.validation.number.greaterThanZero'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47" *ngIf="isContractType(equipmentContractTypes.RENTAL_CONTRACT)">
                <mat-label>
                  {{'modules.equipment.contract.shortTerm'|translate}} / {{'modules.equipment.contract.longTerm'|translate}}
                </mat-label>
                <mat-select formControlName="rentalContractType" required>
                  <mat-option [value]="rentalContractTypes.RENTAL_CONTRACT">
                    {{'modules.equipment.contract.shortTerm'|translate}}
                  </mat-option>
                  <mat-option [value]="rentalContractTypes.RENTAL_CONTRACT_LONG_TERM">
                    {{'modules.equipment.contract.longTerm'|translate}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="rentalContractForm.get('rentalContractType').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.rentalContractType'|translate) } }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47" *ngIf="isContractType(equipmentContractTypes.RENTAL_FULL_SERVICE_CONTRACT)">
                <mat-label>{{'modules.equipment.contract.composedOf'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="composedOf"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
              <mat-form-field [fxFlex]="isContractType(equipmentContractTypes.RENTAL_FULL_SERVICE_CONTRACT) ? 47 : 100">
                <mat-label>{{'general.comment.s'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="comment"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>

        <!--Sales Contract Form-->
        <div *ngIf="isContractType(equipmentContractTypes.SALES_CONTRACT)"
             class="contract-creation-form"
             fxLayout="row"
             fxLayoutAlign="space-between center">
          <form [formGroup]="salesContractForm" novalidate fxFlexFill
                fxLayout="column"
                fxFlexAlign="space-between center"
                fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="salesContractNumber"
                       [maxlength]="fieldLimit.LONG_IDENTIFIER">
                <mat-error *ngIf="salesContractForm.get('salesContractNumber').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.contractNumber'|translate) } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
                <input matInput
                       bhRemoveWhitespaces
                       formControlName="contractPartner"
                       [maxlength]="fieldLimit.SHORT_TEXT">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.price'|translate}}</mat-label>
                <input matInput type="number" required
                       formControlName="cost"
                       min="0"
                       bhMaxLength="15">
                <mat-error *ngIf="salesContractForm.get('cost').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.price'|translate) } }}
                </mat-error>
                <mat-error *ngIf="salesContractForm.get('cost').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.amortizationType'|translate}}</mat-label>
                <mat-select
                            formControlName="amortizationType">
                  <mat-option *ngFor="let at of amortizationTypes" [value]="at">
                    {{at | salesContractAmortizationType}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.amortization'|translate}}</mat-label>
                <input type="number" matInput
                        formControlName="amortizationPeriod"
                        min="0"
                        bhMaxLength="4">
                <mat-error *ngIf="salesContractForm.get('amortizationPeriod').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.period'|translate}}</mat-label>
                <mat-select formControlName="period">
                  <mat-option *ngFor="let option of options" [value]="option">{{option.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field>
                <mat-label>{{'general.comment.s'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="comment"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>

        <!--Service Contract Form-->
        <div *ngIf="isContractType(equipmentContractTypes.SERVICE_CONTRACT)"
             class="contract-creation-form"
             fxLayout="row"
             fxLayoutAlign="space-between center">
          <form [formGroup]="serviceContractForm" novalidate fxFlexFill
                fxLayout="column"
                fxFlexAlign="space-between center"
                fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="serviceContractNumber"
                       [maxlength]="fieldLimit.LONG_IDENTIFIER">
                <mat-error *ngIf="serviceContractForm.get('serviceContractNumber').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.contractNumber'|translate) } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
                <input matInput
                       bhRemoveWhitespaces
                       formControlName="contractPartner"
                       [maxlength]="fieldLimit.SHORT_TEXT">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.monthlyRate'|translate}}</mat-label>
                <input matInput type="number"
                       formControlName="monthlyRate"
                       min="0"
                       bhMaxLength="7">
                <mat-error *ngIf="serviceContractForm.get('monthlyRate').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.composedOf'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="composedOf"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.comment.s'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="comment"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>

        <!--Insurance Contract Form-->
        <div *ngIf="isContractType(equipmentContractTypes.INSURANCE_CONTRACT)"
              class="contract-creation-form"
              fxLayout="row"
              fxLayoutAlign="space-between center">
          <form [formGroup]="insuranceContractForm" novalidate fxFlexFill
                fxLayout="column"
                fxFlexAlign="space-between center"
                fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.insuranceContractNumber'|translate}}</mat-label>
                <input  matInput required
                        bhRemoveWhitespaces
                        formControlName="insuranceContractNumber"
                        [maxlength]="fieldLimit.LONG_IDENTIFIER">
                <mat-error *ngIf="insuranceContractForm.get('insuranceContractNumber').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.insuranceContractNumber'|translate) } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.insuranceContractType'|translate}}</mat-label>
                <mat-select
                            formControlName="insuranceContractType">
                  <mat-option *ngFor="let ict of insuranceContractTypes" [value]="ict">
                    {{ict | insuranceContractType}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.labels.beginning'|translate}}</mat-label>
                <input matInput
                        [max]="getInsuranceEndDate()"
                        [matDatepicker]="contractStartDatePicker"
                        formControlName="contractStartDate">
                <mat-datepicker-toggle matSuffix [for]="contractStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #contractStartDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.labels.end'|translate}}</mat-label>
                <input matInput
                        [min]="getInsuranceStartDate()"
                        [matDatepicker]="contractEndDatePicker"
                        formControlName="contractEndDate">
                <mat-datepicker-toggle matSuffix [for]="contractEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #contractEndDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.mileageLimit'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
                <input type="number" matInput
                        formControlName="mileageLimitPerYear"
                        min="0"
                        bhMaxLength="7">
                <mat-error *ngIf="insuranceContractForm.get('mileageLimitPerYear').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-select formControlName="insuranceContractMileageType">
                  <mat-option *ngFor="let icmt of insuranceContractMileageTypes" [value]="icmt">
                    {{icmt | insuranceContractMileageType}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.costsPerMonth'|translate}}</mat-label>
                <input matInput type="number"
                        formControlName="costsPerMonth"
                        min="0"
                        bhMaxLength="7">
                <mat-error *ngIf="insuranceContractForm.get('costsPerMonth').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field>
                <mat-label>{{'general.comment.s'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="comment"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>

        <!--Leasing Contract Form-->
        <div *ngIf="isContractType(equipmentContractTypes.LEASING_CONTRACT)"
              class="contract-creation-form"
              fxLayout="row"
              fxLayoutAlign="space-between center">
          <form [formGroup]="leasingContractForm" novalidate fxFlexFill
                fxLayout="column"
                fxFlexAlign="space-between center"
                fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="leasingContractNumber"
                       [maxLength]="fieldLimit.LONG_IDENTIFIER">
                <mat-error *ngIf="leasingContractForm.get('leasingContractNumber').hasError('required')">
                  {{'shared.validation.required'|translate:
                    { value: ('modules.equipment.contract.contractNumber'|translate) } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
                <input matInput
                       bhRemoveWhitespaces
                       formControlName="contractPartner"
                       [maxlength]="fieldLimit.NAME">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.labels.start'|translate}}</mat-label>
                <input matInput required
                       [max]="getLeasingEndDate()"
                       [matDatepicker]="leasingStartDatePicker"
                       [matDatepickerFilter]="leasingDateFilter"
                       formControlName="contractStartDate">
                <mat-datepicker-toggle matSuffix [for]="leasingStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #leasingStartDatePicker></mat-datepicker>
                <mat-error *ngIf="leasingContractForm.get('contractStartDate').hasError('required')">
                  {{'shared.validation.required'|translate: { value: ('general.startDate'|translate) } }}
                </mat-error>
                <mat-error *ngIf="leasingContractForm.get('contractStartDate').hasError('rangeViolation')">
                  {{'modules.equipment.contract.leasingOverlappedIntervalError'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.labels.end'|translate}}</mat-label>
                <input matInput required
                       [min]="getLeasingStartDate()"
                       [matDatepicker]="leasingEndDatePicker"
                       [matDatepickerFilter]="leasingDateFilter"
                       formControlName="contractEndDate">
                <mat-datepicker-toggle matSuffix [for]="leasingEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #leasingEndDatePicker></mat-datepicker>
                <mat-error *ngIf="leasingContractForm.get('contractEndDate').hasError('required')">
                  {{'shared.validation.required'|translate: { value: ('general.endDate'|translate) } }}
                </mat-error>
                <mat-error *ngIf="leasingContractForm.get('contractEndDate').hasError('rangeViolation')">
                  {{'modules.equipment.contract.leasingOverlappedIntervalError'|translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.includedMileage'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
                <input type="number" matInput
                       formControlName="mileageLimit"
                       min="0"
                       bhMaxLength="7">
                <mat-error *ngIf="leasingContractForm.get('mileageLimit').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="47">
                <mat-label>{{'modules.equipment.contract.includedOperatingHours'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="operatingHoursLimit"
                       min="0"
                       bhMaxLength="7">
                <mat-error *ngIf="leasingContractForm.get('operatingHoursLimit').hasError('min')">
                  {{'shared.validation.number.notNegative'|translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="47">
                <mat-label>{{'general.period'|translate}}</mat-label>
                <mat-select formControlName="limitationPeriod">
                  <mat-option *ngFor="let option of limitationPeriodOptions" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="leasingContractForm.get('limitationPeriod').hasError('required')">
                  {{'modules.equipment.contract.limitationPeriodError'|translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field>
                <mat-label>{{'general.comment.s'|translate}}</mat-label>
                <textarea matInput
                          bhRemoveWhitespaces
                          formControlName="comment"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
        <div class="button-wrapper" fxLayout="row" xLayoutAlign="start center" fxLayoutGap="15px">
          <button *ngIf="!updatedContractId" mat-raised-button matStepperPrevious>
            {{'general.buttons.back'|translate}}
          </button>
        </div>
        <div class="button-wrapper" fxLayout="row" xLayoutAlign="end center" fxLayoutGap="15px">
          <button mat-raised-button
                  color="primary"
                  (click)="save()"
                  [disabled]="getContractForm()?.invalid || isSavingProcess">
            {{'general.buttons.save'|translate}}
          </button>
          <button mat-raised-button
                  [disabled]="getContractForm()?.invalid"
                  matStepperNext>
            {{'general.buttons.continue'|translate}}
          </button>
        </div>
      </div>
    </mat-step>

    <!-- attachments -->
    <mat-step>
      <ng-template matStepLabel>{{'general.attachment.pl'|translate}}</ng-template>
      <div class="step-content" fxFlex="50" fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="start center">
        <div class="contract-creation-form" fxLayout="column" fxLayoutGap="15px">
          <bh-file-upload
            [itemId]="equipment?.equipmentId"
            uploaderType="EQUIPMENT_ADD_EDIT"
            (onFileUploaded)="onFileUploaded($event)">
          </bh-file-upload>

          <div class="file-container" fxLayout="column" fxLayoutGap="5px">
            <bh-file *ngFor="let document of documents" [file]="document" (fileRemove)="removeDocument(document)">
            </bh-file>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
        <div class="button-wrapper" fxLayout="row" xLayoutAlign="start center" fxLayoutGap="15px">
          <button mat-raised-button matStepperPrevious>
            {{'general.buttons.back'|translate}}
          </button>
        </div>
        <div class="button-wrapper" fxLayout="row" xLayoutAlign="end center" fxLayoutGap="15px">
          <button mat-raised-button
                  color="primary"
                  (click)="save()"
                  [disabled]="isSavingProcess">
            {{'general.buttons.save'|translate}}
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</bh-step-dialog>
