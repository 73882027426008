import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { CanActivateChildModule } from '../../../core/guards/can-activate-child-module';
import { Modules } from '../../../shared/enums/modules.enum';
import { CanActivateChildMobileWebApp } from '../../../core/guards/can-activate-child-mobile-web-app.guard';

const notificationRoutes: Routes = [
  {
    path: 'notifications',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildModule, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'list',
        component: NotificationListComponent,
        data: {
          authorities: [Authorities.NOTIFICATIONS_MANAGE_ALARMS],
          modules: [Modules.ALARMS]
        }
      },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
    ]
  }
];

export const notificationsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(notificationRoutes);
