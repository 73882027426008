<div fxFlexFill class="renew-dialog" fxLayout="column" fxLayoutAlign="start stretch">
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center center">
      <label>{{'modules.disposition.qualificationRenew.pleaseDate' |translate}}</label>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="renewForm">
      <mat-form-field class="date-field">
        <mat-label>{{'modules.disposition.qualificationRenew.enterDate' |translate}}</mat-label>
        <input matInput [matDatepicker]="singleDate" formControlName="renewDate" required>
        <mat-error *ngIf="renewForm.controls['renewDate'].hasError('invalidDate')">
          {{'modules.disposition.qualificationRenew.dateError' |translate}}
        </mat-error>
        <mat-error *ngIf="renewForm.controls['renewDate'].hasError('required')">
          {{'shared.validation.required'|translate:{ value: ('general.date'|translate) } }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="singleDate"></mat-datepicker-toggle>
        <mat-datepicker #singleDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="attachment-type-container">
      <bh-subtitle text="{{'modules.disposition.qualificationRenew.loadDocumentsOrImages' |translate}}"></bh-subtitle>
      <bh-file-upload [itemId]="employeeId" [qualificationId]="qualification.qualificationId" uploaderType="QUALIFICATION">
      </bh-file-upload>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="renewForm">
      <button class="cancel-button" (click)="close()" mat-raised-button>{{'general.buttons.cancel' |translate}}</button>
      <button class="upload-button"
              color="primary"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="renewDate()"
              [disabled]="!isValid()">{{'general.buttons.save' |translate}}</button>
    </div>
  </mat-dialog-content>
</div>
