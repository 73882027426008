import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceCategory } from '../enums/maintenance-category.enum';

@Pipe({
  name: 'maintenanceCategory'
})
export class MaintenanceCategoryPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  iconOrText = (formatText: string, iconName: string, text: string) =>
      formatText === 'icon' ? iconName : text;

  transform(value: any, format = 'text'): any {
    switch (value) {
      case MaintenanceCategory.REMINDER:
        return this.iconOrText(format, 'assignment_late', this.translate('general.reminder'));
      case MaintenanceCategory.CHECK:
        return this.iconOrText(format, 'verified_user', this.translate('general.inspection.s'));
      case MaintenanceCategory.CHECK_AND_REPAIR:
        return this.iconOrText(format, 'build', this.translate('general.maintenance.s'));
      default:
        return '';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
