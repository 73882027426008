import { WidgetConfigParams } from '../contract/widget-config-param';
import { TypeOfUseChangeRequestsWidgetComponent } from './type-of-use-change-requests-widget.component';
import { WidgetDefinition } from '../widget-definition';
import { WidgetType } from '../contract/widget-type.enum';

export class TypeOfUseChangeRequestsWidgetComponentConfiguration extends WidgetDefinition {
  organisationIds: string[] = [];

  constructor({ title }: WidgetConfigParams) {
    super();
    this.widgetType = WidgetType.TYPE_OF_USE_CHANGE_REQUESTS;
    this.type = TypeOfUseChangeRequestsWidgetComponent;
    this.title = title;
    this.minItemCols = 20;
    this.minItemRows = 16;
    this.cols = 20;
    this.rows = 18;
    this.x = 12;
    this.y = 0;
  }
}
