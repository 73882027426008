import { Pipe, PipeTransform } from '@angular/core';

type TPropertyName<T> = keyof T;

@Pipe({
  name: 'filterCollection'
})
export class FilterCollectionPipe<T> implements PipeTransform {

  public transform(collection: T[], fields: TPropertyName<T>[], term: string): T[] {
    if(!(collection?.length > 0 && fields?.length > 0 && Boolean(term))) {
      return collection;
    }

    return collection.filter(item =>
      fields.some(field => this.hasSubstring(String(item[field]), term))
    );
  }

  private hasSubstring(str: string, term: string): boolean {
    return (str || '').toLowerCase().indexOf((term || '').toLowerCase()) > -1;
  }

}
