import { TransportAssignment } from './../../contracts/transport/transport-assignment.interface';
import { TransportAssignmentMap } from './../../contracts/transport/transport-assignment-map.interface';
import { PagedResponse } from './../../../../shared/contract/page-response.interface';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject, forkJoin, of, merge, combineLatest } from 'rxjs';
import { TransportService } from './transport.service';
import { Injectable } from '@angular/core';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { VehicleSearch } from 'app/modules/transportation/contracts/transport/vehicle-search.interface';
import { VehicleFiltersService } from './vehicle-filters.service';
import { VehicleRequestParams } from '../contract/request-params/vehicle-request-params.interface';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { TransportColumnService } from './transport-column.service';
import { TimelineViewDateRange } from '../contract/transportation-timeline-scheduler/timeline-view-date-range.interface';
import { TransportAssignmentsDateRangeParams } from '../contract/request-params/transport-assignments-date-range-params.interface';
import { TransportAssignmentPayload } from '../contract/transportation-timeline-scheduler/transport-assignment-payload.class';
import { TransportTaskState } from '../enums/transport-task-status.enum';
import { TransportAssignmentsLazyLoader } from './lazy-loader/transport-assignments-lazy-loader.service';
import { TransportAssignmentsLazyLoaderParams } from './lazy-loader/contract/lazy-loader-params/transport-assignments-lazy-loader-params.class';
import { TransportView } from '../../contracts/transport/transport-view.interface';

@Injectable()
export class VehicleDataSource {
  private _data = new BehaviorSubject<VehicleSearch[]>([]);
  private _assignments = new Subject<TransportAssignment[]>();
  private dataRequest = new Subject<VehicleRequestParams>();
  private _pagination: PaginationInfo;
  private _searchTerm: string;
  private _totalCount = new BehaviorSubject<number>(0);
  private assignmentsRequestParams: TransportAssignmentsDateRangeParams = {
    date: new Date(),
    equipmentIds: []
  };
  private initialRequest = true;
  public readonly filters = this.vehicleFiltersService.filters;
  public readonly onFiltersUpdated = this.vehicleFiltersService.onFiltersUpdated;
  public readonly totalCount = this._totalCount.asObservable();
  public readonly currentRequestLoading = this.assignmentLazyLoader.currentRequestLoading;

  public readonly vehicles = this._data.asObservable();
  public readonly assignments = merge(this.assignmentLazyLoader.data, this._assignments.asObservable());

  public get pagination(): PaginationInfo {
    return this._pagination || this.initAndReturnPagination();
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }
  public set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
  }

  constructor(
    private transportService: TransportService,
    public transportColumnService: TransportColumnService,
    private vehicleFiltersService: VehicleFiltersService,
    private assignmentLazyLoader: TransportAssignmentsLazyLoader
  ) {
    this.initListeners();
    this.transportColumnService.transportBoardViewBoardPageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  public updateListing(page: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.transportColumnService.selectBoardViewBoardPageSize(size);
    }
    this.pagination.index = page;
    this.pagination.size = size;

    const params: VehicleRequestParams = {
      size,
      page,
      terms: this._searchTerm,
      ...this.vehicleFiltersService.getFilterParams()
    }
    this.dataRequest.next(params);
  }

  public changeAssignmentDateRange({ timelineType, date }: TimelineViewDateRange): void {
    this.assignmentsRequestParams = {
      ...this.assignmentsRequestParams,
      date
    };
    this.getTransportAssignments();
  }

  public createAssignment(assignmentPayload: TransportAssignmentPayload): Observable<TransportAssignment> {
    return forkJoin([
      this.transportService.createAssignment(assignmentPayload.getCreateCommandStore()),
      of(assignmentPayload)
    ]).pipe(
      tap(([assignmentId, payload]) => this.assignmentLazyLoader.addItem({
        ...payload.assignment,
        ...payload.getCreateCommandStore(),
        assignmentId,
        state: TransportTaskState.PLANNED
      })),
      map(([assignmentId, payload]) => ({
        ...payload.assignment,
        ...payload.getCreateCommandDisplay(),
        assignmentId,
        state: TransportTaskState.PLANNED
      })),
    );
  }

  public updateAssignment(assignmentPayload: TransportAssignmentPayload): Observable<TransportAssignment> {
    return forkJoin([
      this.transportService.updateAssignment(assignmentPayload.getUpdateCommandStore()),
      of(assignmentPayload)
    ]).pipe(
      tap(([_, payload]) => this.assignmentLazyLoader.updateItem({
        ...payload.assignment,
        ...payload.getUpdateCommandStore()})
      ),
      map(([_, payload]) => ({...payload.assignment, ...payload.getUpdateCommandDisplay()})),
    );
  }

  public hasVehicleAssignment(vehicleId: string, checkAssignment: TransportAssignment): Observable<boolean> {
    return this.transportService.getTransportAssignmentsByEquipmentId(vehicleId)
    .pipe(
      map(assignments =>
        this.toTransportAssignments(assignments).some(({ transportId }) => transportId === checkAssignment.transportId))
    );
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.vehicleFiltersService.updateFilterParams(commands);
  }

  public updateFilters(): void {
    this.vehicleFiltersService.updateFilters();
  }

  public clearTransportAssignmentsStore(): void {
    this.assignmentLazyLoader.clearStore();
  }

  public refreshAssignmentIfNecessary(transport: TransportView): void {
    const assignment = this.toTransportAssignment(transport);
    if (assignment) {
      this.assignmentLazyLoader.updateItem(assignment);
    }
  }

  private toTransportAssignment(transport: TransportView): TransportAssignment {
    if (!transport.equipmentAssignmentDetails) {
      return null;
    }
    return {
      assignmentId: transport.equipmentAssignmentDetails.assignmentId,
      equipmentId: transport.equipmentAssignmentDetails.equipmentId,
      startItemType: transport.startItem.transportItemType,
      startItemName: transport.startItem.name,
      targetItemType: transport.targetItem.transportItemType,
      targetItemName: transport.targetItem.name,
      startDate: transport.equipmentAssignmentDetails.startDate,
      endDate: transport.equipmentAssignmentDetails.endDate,
      executionStartDate: transport.equipmentAssignmentDetails.executionStartDate,
      executionEndDate: transport.equipmentAssignmentDetails.executionEndDate,
      transportId: transport.transportId,
      identifier: transport.identifier,
      title: transport.title,
      dueDate: transport.dueDate,
      priority: transport.priority,
      state: transport.state,
      transportType: transport.transportType,
      estimatedDuration: transport.estimatedDuration,
      customerId: transport.customerId,
      startItemId: transport.startItemId,
      targetItemId: transport.targetItemId,
      projectId: transport.projectId
    }
  }

  private initListeners(): void {
    this.dataRequestListener();
  }

  private dataRequestListener(): void {
    combineLatest([
      this.dataRequest,
      this.vehicleFiltersService.isReady
    ])
    .pipe(
      tap(([params, isReady]) => this.initialRequest = !isReady ? true : this.initialRequest),
      filter(([params, isReady]) => Boolean(isReady)),
      map(([params, isReady]) => !this.initialRequest ? params : { ...params, ...this.vehicleFiltersService.getFilterParams() }),
      switchMap(params => this.transportService.getVehicles(params))
    )
    .subscribe((response: PagedResponse<VehicleSearch>) => {
      this.initialRequest = false;
      this.updateStoreData(response)
    });
  }

  private getTransportAssignments(): void {
    if (this.assignmentsRequestParams?.equipmentIds?.length > 0) {
      this.assignmentLazyLoader.load(this.toLazyLoaderParams(this.assignmentsRequestParams));
    } else {
      this.assignmentLazyLoader.clearStore();
      this._assignments.next([]);
    }
  }

  private toTransportAssignments(assignmentMap: TransportAssignmentMap): TransportAssignment[] {
    return Object.keys(assignmentMap).reduce((acc, key) =>
      [...acc, ...(assignmentMap[key] || [])],
      []);
  }

  private getEquipmentIdsFromVehicleResponse(vehicles: PagedResponse<VehicleSearch>): string[] {
    return vehicles.content.map(({ equipmentId }) => equipmentId);
  }

  private toLazyLoaderParams({ date, equipmentIds }: TransportAssignmentsDateRangeParams): TransportAssignmentsLazyLoaderParams {
    return new TransportAssignmentsLazyLoaderParams(equipmentIds, date);
  }

  private updateStoreData(response: PagedResponse<VehicleSearch>): void {
    this.updatePagination(response);
    this.assignmentsRequestParams.equipmentIds = this.getEquipmentIdsFromVehicleResponse(response);
    this.getTransportAssignments();
    this._data.next(response.content);
    this._totalCount.next(response.totalElements);
  }

  private initAndReturnPagination(): PaginationInfo {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0,
    };
    return this._pagination
  }

  private updatePagination(response: PagedResponse<any>): void {
    this._pagination = {
      totalElements: response.totalElements,
      totalPages: response.totalPages,
      size: response.size,
      index: response.number,
      numberOfElements: response.numberOfElements,
    }
  }
}
