import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import moment from 'moment';

/**
 * This adapter solve the issue of manual date input for locale de-De
 * based on:
 * https://github.com/angular/material2/issues/5792
 * https://plnkr.co/edit/GFTZ2Y7GfbUlNUWE3KLR?p=preview
 *
 * TODO Check if this is not required with new versions.
 */
@Injectable()
export class CustomDateAdapterNoDeserialize extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: any): Date | null {

    if ((typeof value === 'string') && (value.indexOf('.') > -1) && value.split('.').length >= 3) {
      const str = value.split('.');

      let year = Number(this.transformYear((str[2])));
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      if (!this.isInRange(date, 1, 31) ||
        !this.isInRange(month, 0, 11) ||
        year <= 1) {
        return null;
      }

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  transformYear(year: string): string {
    if (year.length === 2) {
      const preDigits = moment().year().toString().substr(0, 2);
      year = preDigits.concat(year.toString());
    }
    return year;
  }

  format(date: Date, displayFormat: Object): string {
    date = new Date(Date.UTC(
        date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
        date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    displayFormat = {...displayFormat, timeZone: 'utc'};


    const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
    return dtf.format(date).replace(/[\u200e\u200f]/g, '');
  }

  private isInRange(nr: number, from: number, to: number) {
    return nr >= from && nr <= to;
  }
}
