import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { TransferListItemType } from 'app/modules/disposition/contract/transfer-list-item-type';

@Pipe({
  name: 'itemType'
})
export class ItemTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(type: string, args?: any): string {
    switch (type) {
      case TransferListItemType.CONSUMABLE_BULK_ITEM:
        return this.translate('general.bulkItemType.consumableBulkItem');
      case TransferListItemType.STANDARD_BULK_ITEM:
        return this.translate('general.bulkItemType.standardBulkItem');
      case TransferListItemType.EQUIPMENT:
        return this.translate('general.equipm.s');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
