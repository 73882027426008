import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ViewEmployee } from '../../../../../contract/view-employee.interface';
import { takeWhile } from 'rxjs/operators';
import { EmployeeManagementDatasource } from '../../../../../shared/employee-management.datasource';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';

@Directive()
export abstract class BaseEmployeeViewDetailComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public employee: ViewEmployee;

  protected componentActive = true;

  public canViewNotes = false;

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected routerHistory: RouterHistory,
                        protected employeeManagementStore: EmployeeManagementDatasource) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.canViewNotes = this.authService.hasAuthority(Authorities.EMPLOYEE_VIEW_NOTES);
    this.subscribeToCurrentEmployee();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployee) => {
      if (res) {
        this.employee = res;
      }
    });
  }

}
