import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { RoleAuthorityGuardsComponent } from '../../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { BehaviorSubject } from 'rxjs';
import { ViewEmployeeBasicData } from '../../../../contract/view-employee-basicData';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmployeeManagementService } from '../../../../shared/employee-management.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TransferService } from 'app/modules/disposition/shared/transfer.service';
import { ProjectTransferHistoryService } from '../../../../projects/project-list/project-view/project-view-transfer-history/project-transfer-history.service';
import { MinimalPossibleTransferDateRequest } from '../../../../projects/project-list/project-view/project-view-transfer-history/minimal-possible-transfer-date-request';
import { LastTransferDateResponse } from '../../../../projects/project-list/project-view/project-view-transfer-history/last-transfer-date-response';
import { FieldLimit } from '../../../../../../shared/enums/fieldLimit.enum';

@UntilDestroy()
@Component({
  selector: 'bh-transfer-cart-confirmation',
  templateUrl: './transfer-cart-confirmation.component.html',
  styleUrls: ['./transfer-cart-confirmation.component.scss']
})
export class TransferCartConfirmationComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  @ViewChild(MatSlideToggle) slideToggle: MatSlideToggle;

  public readonly faExchange: IconDefinition = faExchange;
  public readonly faTimes: IconDefinition = faTimes;
  public readonly fieldLimit = FieldLimit;

  private _confirmLabel: string;
  private _denyLabel: string;

  public confirmMessage: string;
  public confirmTitle: string;
  public transferForm: UntypedFormGroup;
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public externalEmployees: string[] = [];
  public filteredExternalEmployees: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public employees: ViewEmployeeBasicData[] = [];
  public filteredEmployees: BehaviorSubject<ViewEmployeeBasicData[]> = new BehaviorSubject<ViewEmployeeBasicData[]>([]);
  public isHistoricTransfer = false;
  public minimalPossibleTransferDateRequest: MinimalPossibleTransferDateRequest;
  public lastTransferDateResponse: LastTransferDateResponse;
  public historicTransferTime: string;
  public minHistoricTransferDate: Date;
  public maxHistoricTransferDate: Date;
  public maxHistoricTransferDatePlusOneMinute: Date;

  constructor(private langService: LanguageService,
              protected authService: KeycloakService,
              private formBuilder: UntypedFormBuilder,
              private transferService: TransferService,
              private employeeService: EmployeeManagementService,
              private projectTransferHistoryService: ProjectTransferHistoryService,
              private languageService: LanguageService) {
    super(authService);
  }

  public get confirmLabel(): string {
    return this._confirmLabel || this.langService.getInstant('general.buttons.yes');
  }

  public set confirmLabel(value: string) {
    this._confirmLabel = value;
  }

  public get denyLabel(): string {
    return this._denyLabel || this.langService.getInstant('general.buttons.no');
  }

  public set denyLabel(value: string) {
    this._denyLabel = value;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.getEmployees();
    this.getExternalEmployees();
    this.filterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterEmployees();
      });
  }

  public ngOnDestroy() {
  }

  public clear(): void {
    this.transferForm.get('employeeId').reset();
    this.transferForm.get('externalEmployee').reset();
    this.transferForm.get('employeeId').enable();
    this.transferForm.get('externalEmployee').enable();
  }

  private buildForm(): void {
    this.transferForm = this.formBuilder.group({
      employeeId: [''],
      externalEmployee: [''],
      comment: [''],
      historicTransferDateTime: [this.maxHistoricTransferDate, [Validators.required]]
    });

    this.subscribeToInputChanges();
  }

  get historicTransferDateTimeControl() {
    return this.transferForm.get('historicTransferDateTime');
  }

  private subscribeToInputChanges(): void {
    this.transferForm.get('externalEmployee').valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value !== '' && value !== null) {
          this.transferForm.get('employeeId').disable();
        } else {
          this.transferForm.get('employeeId').enable();
        }
        if (!value) {
          this.filteredExternalEmployees.next(this.externalEmployees.slice());
          return;
        } else {
          value = value.toLowerCase();
        }
        this.filteredExternalEmployees.next(
          this.externalEmployees.filter(employee => this.searchMatchesExternalEmployee(value, employee))
        );
      });

    this.transferForm.get('employeeId').valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value !== '' && value !== null) {
          this.transferForm.get('externalEmployee').disable();
        } else {
          this.transferForm.get('externalEmployee').enable();
        }
      });
  }

  private getEmployees(): void {
    if (this.hasModule(this.modules.STAFF_MANAGEMENT)) {
      this.employeeService.getAllEmployeesForTransfer().subscribe(employees => {
        if (employees) {
          this.employees = employees;
          this.filteredEmployees.next(employees);
        }
      });
    }
  }

  private getExternalEmployees(): void {
    this.transferService.getAllExernalEmployees().subscribe(externalEmployees => {
      this.externalEmployees = externalEmployees;
      this.filteredExternalEmployees.next(externalEmployees);
    });
  }

  private filterEmployees(): void {
    if (!this.employees) {
      return;
    }
    let search = this.filterControl.value;
    if (!search) {
      this.filteredEmployees.next(this.employees.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEmployees.next(
      this.employees.filter(employee => this.searchMatchesEmployee(search, employee))
    );
  }

  private searchMatchesEmployee(search: string, employee: ViewEmployeeBasicData): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0
        && (employee.employeeFirstName.toLowerCase().indexOf(param) === -1)
        && (employee.employeeName.toLowerCase().indexOf(param) === -1)
        && (employee.organisationName.toLowerCase().indexOf(param) === -1)) {
        return false;
      }
    }
    return true;
  }

  private searchMatchesExternalEmployee(search: string, employee: string): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0
        && (employee.toLowerCase().indexOf(param) === -1)) {
        return false;
      }
    }
    return true;
  }

  public switchToHistoricTransfer(): void {
    this.isHistoricTransfer = !this.isHistoricTransfer;
    this.updateLastTransferDateInfo();
  }

  private updateLastTransferDateInfo(): void {
    if (this.isHistoricTransfer && !this.lastTransferDateResponse) {
      this.projectTransferHistoryService.getMinimalPossibleTransferDate(this.minimalPossibleTransferDateRequest).pipe(
        tap((res) => {
          this.minHistoricTransferDate = new Date(res.lastTransferDate);
          this.maxHistoricTransferDate = new Date();
          this.maxHistoricTransferDatePlusOneMinute = new Date(this.maxHistoricTransferDate);
          this.maxHistoricTransferDatePlusOneMinute.setMinutes(this.maxHistoricTransferDatePlusOneMinute.getMinutes() + 1);
        })
      )
        .subscribe(res => this.lastTransferDateResponse = res);
    }
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
