<mat-card fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="report-card-header" fxLayout="row" fxLayoutAlign="space-between center">
    <!--<div fxLayout="row" fxLayoutAlign="space-between center">-->
      <div class="report-card-header__text report-job">{{'modules.reports.reportJobs.automaticReports'|translate|uppercase}}</div>
      <button mat-icon-button (click)="navigateToAddReportJob()" [matTooltip]="'modules.reports.reportJobs.createAutoReport'|translate">
        <mat-icon class="bh-mat-icon">add_box</mat-icon>
      </button>
    <!--</div>-->
  </div>

  <mat-card-content fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
    <mat-accordion fxFlex class="report-list report-list__jobs">
      <mat-expansion-panel class="report-expansion-panel" hideToggle="true" *ngFor="let reportJob of reportJobs">
        <mat-expansion-panel-header>
          <mat-panel-title class="report-expansion-panel__title" fxLayout="row" fxLayoutAlign="space-between stretch">
            <div>{{reportJob.reportJobName}}</div>

            <div>
              <button mat-icon-button (click)="editReportJob($event, reportJob.reportJobId)" [matTooltip]="'general.buttons.edit'|translate">
                <mat-icon class="bh-mat-icon">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteReportJob($event, reportJob.reportJobId)" [matTooltip]="'general.buttons.delete'|translate">
                <mat-icon class="bh-mat-icon">delete</mat-icon>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'general.report.s'|translate|uppercase}}</div>
          <div fxFlex>{{reportJob.reportJobName}}</div>
        </div>

        <div *ngIf="reportJob.equipmentTypeCategory2 && reportJob.equipmentTypeCategory2.length > 0" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'general.equipmType.pl'|translate|uppercase}}</div>
          <div fxFlex>{{joinNames(reportJob.equipmentTypeCategory2)}}</div>
        </div>

        <div *ngIf="reportJob.equipmentLabels && reportJob.equipmentLabels.length > 0" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'modules.reports.base.equipmentLabels'|translate|uppercase}}</div>
          <div fxFlex>{{joinNames(reportJob.equipmentLabels)}}</div>
        </div>

        <div *ngIf="reportJob.equipmentModels && reportJob.equipmentModels.length > 0" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'general.equipm.pl'|translate|uppercase}}</div>
          <div fxFlex>{{joinNames(reportJob.equipmentModels)}}</div>
        </div>

        <div *ngIf="reportJob.organisationNames && reportJob.organisationNames.length > 0" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'general.org.pl'|translate|uppercase}}</div>
          <div fxFlex>{{joinNames(reportJob.organisationNames)}}</div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'general.interval'|translate|uppercase}}</div>
          <div fxFlex>{{reportJob.reportJobInterval | reportJobIntervalDisplay}}</div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'modules.reports.base.receiver'|translate|uppercase}}</div>
          <div fxFlex>{{joinNames(reportJob.reportJobRecipientUserNames)}}</div>
        </div>

        <div *ngIf="reportJob.projectNames && reportJob.projectNames.length > 0" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
          <div class="report-expansion-panel__content" fxFlex="30">{{'general.project.pl'|translate|uppercase}}</div>
          <div fxFlex>{{joinNames(reportJob.projectNames)}}</div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

    <div class="report-card-footer">
      <span>{{reportJobs?.length}}</span>
      {{'modules.reports.reportJobs.autoReportsCreated'|translate}}
    </div>
  </mat-card-content>
</mat-card>
