<ng-container [ngTemplateOutlet]="isNoSearchResult || itemMap.size === 0 ? notFound : optionBranch"
              [ngTemplateOutletContext]="setContent(itemMap | expandingTreeRootItems)">
</ng-container>

<ng-template #notFound>
  <div class="empty-list-warning">
    <p>{{'shared.expandingTree.notFound'|translate}}</p>
  </div>
</ng-template>

<ng-template #optionBranch let-treeItems>
  <mat-accordion multi>
    <ng-container *ngFor="let item of treeItems"
                  [ngTemplateOutlet]="expTemplate"
                  [ngTemplateOutletContext]="{treeItem: item, isItemLeaf: isLeaf(item)}">

      <ng-template #expTemplate let-treeItem="treeItem" let-isItemLeaf="isItemLeaf">
        <mat-expansion-panel *ngIf="!treeItem.hidden"
                             [disabled]="isItemLeaf || alwaysExpanded"
                             (opened)="treeItem.expanded = true"
                             (closed)="treeItem.expanded = false"
                             [expanded]="searchTerm || treeItem.expanded || alwaysExpanded"
                             class="item"
                             [ngClass]="{ 'item-root-parent': !treeItem.parentId, 'item-leaf': isItemLeaf }">
          <mat-expansion-panel-header class="item__header">
            <div fixLayout="row" fxLayoutAlign="start center" class="item__header__content">
              <mat-checkbox (click)="onChangeSelectionClick($event, treeItem)"
                            [checked]="treeItem.checked"
                            [indeterminate]="treeItem.indeterminate"
                            color="primary"
                            class="item__header__content__checkbox">
              </mat-checkbox>
              <div class="item__header__content__title"
                   [ngClass]="{ 'cursor-pointer': alwaysExpanded }"
                   (click)="alwaysExpanded && onChangeSelectionClick($event, treeItem)">
                <ng-container [ngTemplateOutlet]="contentTemplate"
                              [ngTemplateOutletContext]="setContent(treeItem.optionValue)">
                </ng-container>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div *ngIf="!isItemLeaf" class="item__children">
            <ng-container [ngTemplateOutlet]="optionBranch"
                          [ngTemplateOutletContext]="setContent(itemMap | expandingTreeItems : treeItem.childrenIds)">
            </ng-container>
          </div>
        </mat-expansion-panel>
      </ng-template>

    </ng-container>
  </mat-accordion>
</ng-template>
