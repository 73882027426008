<ng-container *ngIf="(auditData | async | maintenanceTaskCompletedHistoryTableConverter) as data; else noEntries">
  <ng-container *ngIf="data.length > 0; else noEntries">
    <bh-history-table [data]="data" [cellTemplate]="cellTemplate">
      <ng-template #cellTemplate let-type="type" let-value="value">
        <bh-maintenance-task-completed-history-item
          [type]="type"
          [value]="value">
        </bh-maintenance-task-completed-history-item>
      </ng-template>
    </bh-history-table>
  </ng-container>
</ng-container>

<ng-template #noEntries>
  <div class="placeholder-message">{{'general.noAvailableEntries'|translate}}</div>
</ng-template>
