import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DisplayService } from 'app/shared/display.service';
import { filter, Observable } from 'rxjs';
import { MaintenanceTask } from '../../shared/maintenance-task';
import {
  MaintenanceTaskCompletedDataSource,
} from '../../shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import {
  MaintenanceTaskAdapter,
} from '../../../../../shared/components/maintenance-completed-task-action-button/maintenance-task-adapter.class';
import { delay } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { Authorities } from '../../../../../shared/enums/authorities.enum';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-completed-view',
  templateUrl: './maintenance-task-completed-view.component.html',
  styleUrls: ['./maintenance-task-completed-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskCompletedViewComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public readonly currentTask: Observable<MaintenanceTask> = this.maintenanceDataSource.currentTask;
  public showMenu = false;

  constructor(authService: KeycloakService,
              public displayService: DisplayService,
              private route: ActivatedRoute,
              private maintenanceDataSource: MaintenanceTaskCompletedDataSource) {
    super(authService);
  }

  public ngOnInit(): void {
    this.initListeners();
    this.maintenanceDataSource.refreshOnChanges();
    this.updateMenuVisibility();
  }

  private initListeners(): void {
    this.routeParamsListener();
  }

  private updateMenuVisibility(): void {
    this.showMenu = this.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE);
  }

  private routeParamsListener(): void {
    this.route.params
      .pipe(
        filter((params: Params) => params.id),
        untilDestroyed(this))
      .subscribe((params: Params) => this.maintenanceDataSource.changeCurrentTask(params.id));
  }

  public editCompletedTask(task: MaintenanceTaskAdapter): void {
    this.maintenanceDataSource.editCompletedTask(task.id, task.category);
  }

  public deleteCompletedTask(task: MaintenanceTaskAdapter): void {
    this.maintenanceDataSource.deleteTask(task.id, task.category, task.equipmentId);
  }
}
