import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ITransferItemWithValidityStatus, TransferItem } from '../../model/transfer-item';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { DecimalNumberValidityService } from '../../../../../../shared/services/decimal-number-validity.service';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { ChangeTypeOfUseIconsService } from '../../../change-type-of-use-icons.service';
import { RequestStatus } from '../../../enums/request-status.enum';
import { faCheck, faTimes, faRecycle, faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { TypeOfUseStatus } from '../../../enums/type-of-use-status.enum';
import { ThumbnailSize } from '../../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';
import { DimensionUnitConverterPipe } from '../../../../../../shared/pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-transfer-item',
  templateUrl: './transfer-item.component.html',
  styleUrls: ['./transfer-item.component.scss']
})
export class TransferItemComponent {

  @Input() itemControl: UntypedFormControl;
  @Input() item: TransferItem;
  @Input() sourceLabel: string;
  @Input() targetLabel: string;
  @Input() removable: boolean;
  @Input() isActiveContainer: boolean;
  @Input() hasChildren: boolean;
  @Input() typeOfUseChanging?: boolean;
  @Input() validatedItem: ITransferItemWithValidityStatus;

  @Output() changeValue: EventEmitter<void> = new EventEmitter<void>();
  @Output() removed: EventEmitter<void> = new EventEmitter<void>();
  @Output() activateContainer: EventEmitter<void> = new EventEmitter<void>();

  public readonly faRecycle: IconDefinition = faRecycle;
  public readonly faAngleUp: IconDefinition = faAngleUp;
  public readonly faAngleDown: IconDefinition = faAngleDown;
  public readonly faMinusCircle: IconDefinition = faMinusCircle;
  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;
  public readonly RequestStatus = RequestStatus;
  public readonly ThumbnailSize = ThumbnailSize;

  constructor(public iconService: ChangeTypeOfUseIconsService,
              public decimalNumberValidityService: DecimalNumberValidityService,
              private languageService: LanguageService,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              ) { }

  public remove(): void {
    this.removed.emit();
  }

  public toggleItem(element: any): void {
    this.changeValue.emit();
    element._elementRef.nativeElement.classList.remove('cdk-focused');
  }

  public resolveArrowIcon(): IconDefinition {
    return this.isActiveContainer ? this.faAngleUp : this.faAngleDown;
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public resolveStatusClass(reason: TypeOfUseStatus): string {
    switch (reason) {
      case TypeOfUseStatus.STANDSTILL:
        return 'standstill';
      case TypeOfUseStatus.FREE_OF_CHARGE:
        return 'free-of-charge';
      case TypeOfUseStatus.IN_USE:
        return 'in-use';
      case TypeOfUseStatus.READY_TO_PICKUP:
        return 'ready-to-pickup';
    }
  }

  public resolveIconClass(status: RequestStatus): string {
    return status === RequestStatus.FAILED ? 'error' : 'primary';
  }

  public isValidAmount(): boolean {
    let {minAmount, maxAmount, unit} = this.item;
    let min = this.dimensionUnitConverterPipe.toUserDimensionUnit(minAmount, unit, 3);
    let max = this.dimensionUnitConverterPipe.toUserDimensionUnit(maxAmount, unit, 3);
    let transferAmount = this.dimensionUnitConverterPipe.toUserDimensionUnit(this.item.transferAmount, unit, 3);
    return transferAmount >= min && transferAmount <= max;
  }
}
