import {Component, Input, OnInit} from '@angular/core';
import {SearchEquipment} from '../../../../contract/search-equipment.interface';
import {KeycloakService} from '../../../../../../core/keycloak';
import {RoleAuthorityGuardsComponent} from '../../../../../../shared/navigation-guards/role-authority-guards.component';
import {EquipmentCheckerService} from '../../../../shared/services/equipment-checker.service';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';


@Component({
  selector: 'bh-equipment-info',
  templateUrl: './equipment-info.component.html',
  styleUrls: ['./equipment-info.component.scss']
})
export class EquipmentInfoComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public readonly faTruck: IconDefinition = faTruck;

  @Input() equipment: SearchEquipment;

  constructor(public equipmentCheckerService: EquipmentCheckerService, protected authService: KeycloakService) {
    super(authService)
  }

  ngOnInit(): void {
  }

  public getTransportTypes(equipment: SearchEquipment): string {
    return equipment?.transportTypes?.join(', ');
  }

}
