export class MailConnectionInformation {

  server: string;
  port: number;
  protocol: string;
  encryption: string;
  user: string;
  password: string;
  directory: string;
  destinationDirectory: string;
  sender: string;
  filePattern: string;
  subjectPattern: string;
  datePattern: string;
  profileKey: string;
}
