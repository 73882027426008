import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskInfo } from '../shared/task-info.interface';
import { FieldType } from '../../../../shared/enums/field-type';
import { groupBy } from '../../../../shared/collection-utils';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MaintenanceTaskService } from '../shared/service/maintenance-task.service';
import { delay, filter, flatMap, map } from 'rxjs/operators';
import {
  byTypeFieldName,
  MaintenanceFieldValue,
} from '../../../../shared/contract/task-completion/maintenance-field-value';
import {
  MaintenanceTaskCompletionInfo,
} from '../../../../shared/contract/task-completion/maintenance-task-completion-info';
import {
  UpdateMaintenanceTaskAfterCompletionCommand,
} from '../shared/contract/commands/update-maintenance-task-after-completion.command';
import { CompleteMaintenanceTaskCommand } from '../shared/contract/commands/complete-maintenance-task.command';
import { AttachedDocument } from '../../../../shared/contract/attached-document.interface';
import { UploadMaintenanceTaskFileCommand } from '../shared/contract/commands/upload-maintenance-task-file.command';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { DeleteMaintenanceTaskFileCommand } from '../shared/contract/commands/delete-maintenance-task-file.command';
import {
  EnrichedAttachedDocument,
} from '../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { DatesService } from '../../../../shared/services/dates.service';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TelematicsSnapshot } from '../shared/telematics-snapshot.interface';
import { isDefined } from '../../../../shared/utils';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { RouterHistory } from '../../../../shared/router-history';
import { DateValidator } from '../../../../shared/custom-validators/date.validator';
import { MAINTENANCE_RESULTS, MaintenanceResult } from '../../../../shared/enums/maintenance-result';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { EquipmentsService } from '../../../equipment/shared/equipments.service';
import { ViewEquipment } from '../../../equipment/contract/view-equipment.interface';
import { EquipmentStatusCategory } from '../../../equipment/contract/equipment-status-category.enum';
import { MatDialog } from '@angular/material/dialog';
import {
  UpdateEquipmentStatusDialogComponent, UpdateEquipmentStatusDialogResult,
} from './update-equipment-status-dialog/update-equipment-status-dialog.component';
import { MaintenanceTaskCompleteNavigationExtras } from '../shared/contract/maintenance-task-complete-extra-state.interface';
import { MaintenanceTaskDataSource } from '../shared/service/maintenance-task.datasource';
import _ from 'lodash';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';

interface FieldParams {
  isVisible: boolean;
  isDisabled: boolean;
  isLastIteration: boolean;
}

@Component({
  selector: 'bh-maintenance-task-complete',
  templateUrl: './maintenance-task-complete.component.html',
  styleUrls: ['./maintenance-task-complete.component.scss']
})
export class MaintenanceTaskCompleteComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit {

  taskInfo: TaskInfo;
  FieldType = FieldType;
  fieldTypeToFields: Map<string, MaintenanceFieldValue[]>;
  form: UntypedFormGroup;
  completed: boolean;
  documents: AttachedDocument[] = [];
  documentsChanged: boolean;
  completionFields = [
    'completedAtDate',
    'completedAtMileage',
    'completedAtOperatingHours',
    'maintenanceResult',
    'nextDueDate',
  ];
  public today: Date = new Date();
  public nextDueDateParams: FieldParams;
  public formIsReady = false;

  public readonly maintenanceResults = MAINTENANCE_RESULTS;
  public readonly defaultMinDate = new Date('1900-01-02');
  private navigationAfterComplete: string;

  public get completedAtDateControl(): AbstractControl {
    return this.form.controls.completedAtDate;
  }

  public get nextDueDateControl(): AbstractControl {
    return this.form.controls.nextDueDate;
  }

  public get latestCompletionDate(): Date {
    return this.taskInfo.latestCompletionDate
  }

  constructor(
      private formBuilder: UntypedFormBuilder,
      private maintenanceTaskService: MaintenanceTaskService,
      private equipmentsService: EquipmentsService,
      private dialog: MatDialog,
      private maintenanceTaskDataSource: MaintenanceTaskDataSource,
      protected snackBar: MatSnackBar,
      protected authService: KeycloakService,
      protected router: Router,
      protected routerHistory: RouterHistory,
      protected route: ActivatedRoute,
      protected languageService: LanguageService,
      private dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
    super(authService, router, route, routerHistory);
    this.getExtraParam();
  }

  public ngOnInit() {
    this.taskInfo = this.route.snapshot.data['taskInfo'];
    this.completed = this.route.snapshot.url[0].path === 'edit';
    if (this.completed && !this.taskInfo.task.completed) {
      this.router.navigate(['/']);
    }
    this.getNextDueDateParams();
    this.fieldTypeToFields = groupBy(this.taskInfo.maintenanceFieldValues.sort(byTypeFieldName()), 'maintenanceFieldType');
    this.createForm();
    this.subscribeToDateChange();

    if (this.completed) {
      this.maintenanceTaskService.getFilesForTask(this.taskInfo.task.id).subscribe(documents => {
        this.documents = documents || [];
      });
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.formIsReady = true, 0);
  }

  public async save() {
    if (this.completed) {
      this.updateCompletedTask();
    } else {
      await this.completeTask();
    }
  }


  onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.documents.push(AttachedDocument.fromData(attachedDocument));
    this.documentsChanged = true;
  }

  removeDocument(document: AttachedDocument) {
    this.maintenanceTaskService.deleteDocument(new DeleteMaintenanceTaskFileCommand(this.taskInfo.task.id, document.documentKey))
    .subscribe(() => {
      const index = this.documents.indexOf(document);
      if (index !== -1) {
        this.documents.splice(index, 1);
        this.documentsChanged = true;
      }
    });
  }

  public navigateBack(): void {
    if (this.taskInfo && this.taskInfo.task && this.taskInfo.task.equipmentId) {
      this.goBack(`/assets/equipment/list/${this.taskInfo.task.equipmentId}/tasks`);
    } else {
      this.goBack('/maintenance/tasks/list');
    }
  }

  public addOneDay(date: Date): Date {
    if (DatesService.isValidDate(date)) {
      return moment(date).add(1, 'day').toDate();
    }
  }

  private getExtraParam(): void {
    const extras: MaintenanceTaskCompleteNavigationExtras = this.router.getCurrentNavigation()?.extras;
    this.navigationAfterComplete = extras?.state?.navigationAfterComplete;
  }

  private getNextDueDateParams(): void {
    const isLastIteration = !_.isNil(this.taskInfo?.taskLimit)
      && this.taskInfo.task.iteration >= this.taskInfo.taskLimit;

    this.nextDueDateParams = {
      isVisible: !this.completed,
      isDisabled: this.completed || isLastIteration,
      isLastIteration,
    }
  }

  private async completeTask() {
    const formValues = this.form.value;

    const completionInfo: MaintenanceTaskCompletionInfo = new MaintenanceTaskCompletionInfo(
        this.getMaintenanceFieldKeys().map(key => {
          const value = formValues[key];
          const field = this.taskInfo.maintenanceFieldValues.find(f => f.maintenanceTypeFieldName === key);
          return new MaintenanceFieldValue(field.maintenanceTypeFieldName,
             field.maintenanceFieldType,
             value,
             field.maxLength,
             field.mandatory);
        }),
        this.documents,
        this.taskInfo.ruleName,
        this.taskInfo.maintenanceCategory,
        this.taskInfo.ruleDescription,
        this.taskInfo.maintenanceTypeName,
        this.taskInfo.maintenanceTypeDescription
    );

    const command = new CompleteMaintenanceTaskCommand(
        this.taskInfo.task.id,
        this.taskInfo.task.equipmentId,
        completionInfo,
        DatesService.ospDateTimeAtStartOfDay(formValues.completedAtDate),
        this.dimensionUnitConverterPipe.toSystemDimensionUnit(formValues.completedAtMileage, 'km'),
        formValues.completedAtOperatingHours,
        formValues.maintenanceResult,
        DatesService.ospDateTimeAtStartOfDay(formValues.nextDueDate));

    if (this.hasAnyAuthority([Authorities.EQUIPMENT_UPDATE,
      Authorities.EQUIPMENT_STATUS_UPDATE_ANY,
      Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE])) {
      const equipment = await firstValueFrom(this.equipmentsService.getEquipment(this.taskInfo.task.equipmentId));
      if (this.isEquipmentRequiresStatusChanges(equipment, command)) {
        const dialogResult = await this.openEquipmentStatusChangeDialog(formValues.maintenanceResult, equipment);
        if (dialogResult.result === 'update' && equipment.status.equipmentStatusId !== dialogResult.equipmentStatusId) {
            await firstValueFrom(this.equipmentsService.updateEquipmentChangeStatus({
              equipmentId: equipment.equipmentId,
              equipmentStatusId: dialogResult.equipmentStatusId,
            }));
        } else if (dialogResult.result === 'abort') {
          // Do nothing when dialog is canceled
          return
        }
      }
    }

    this.completeMaintenanceTask(command);
  }

  private async openEquipmentStatusChangeDialog(result: MaintenanceResult,
                                                equipment: ViewEquipment): Promise<UpdateEquipmentStatusDialogResult> {
    return await firstValueFrom(this.dialog.open(UpdateEquipmentStatusDialogComponent, {
      width: '700px',
      panelClass: 'padded-dialog',
      data: {
        maintenanceResult: result,
        equipmentStatus: equipment.status,
        equipment: equipment
      }
    }).afterClosed());
  }

  private completeMaintenanceTask(command: CompleteMaintenanceTaskCommand): void {
    this.maintenanceTaskDataSource.completeTask(command)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => {
      this.uploadFiles()
      .subscribe(() => this.navigateAfterComplete());
    });
  }

  private navigateAfterComplete(): void {
    if (this.navigationAfterComplete) {
      this.router.navigate([this.navigationAfterComplete]);
    } else {
      this.goBack();
    }
  }

  private updateCompletedTask(): void {
    const formValues = this.form.value;

    const command = new UpdateMaintenanceTaskAfterCompletionCommand(
        this.taskInfo.task.id,
        this.getMaintenanceFieldKeys().map(key => {
          const value = formValues[key];
          const field = this.taskInfo.maintenanceFieldValues.find(f => f.maintenanceTypeFieldName === key);
          return new MaintenanceFieldValue(field.maintenanceTypeFieldName,
             field.maintenanceFieldType,
             value, field.maxLength,
             field.mandatory);
        }),
        this.documents.map(document => AttachedDocument.fromData(document)),
        DatesService.ospDateTimeAtStartOfDay(formValues.completedAtDate),
        this.dimensionUnitConverterPipe.toSystemDimensionUnit(formValues.completedAtMileage, 'km'),
        formValues.completedAtOperatingHours,
        formValues.maintenanceResult);

    this.maintenanceTaskService.updateTaskAfterCompletion(command).pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => {
      this.uploadFiles()
      .subscribe(() => this.goBack());
    });
  }

  private uploadFiles(): Observable<string[]> {
    const uploadObs = this.documents
    .filter(document => document instanceof AttachedDocument)
    .map(document => this.maintenanceTaskService.uploadFile(new UploadMaintenanceTaskFileCommand(this.taskInfo.task.id, document)));
    if (uploadObs.length === 0) {
      return of([]);
    } else {
      return forkJoin(uploadObs);
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      completedAtDate: [
        this.getInitialDateValue,
        [
          Validators.required,
          DateValidator.isValidDate(this.languageService),
          DateValidator.noFutureDate(this.languageService),
          (DatesService.isValidDate(this.latestCompletionDate)
            ? DateValidator.inputDateNotBefore(this.addOneDay(this.latestCompletionDate))
            : null)
        ].filter(Boolean)
      ],
      nextDueDate: [{
        value: null,
        disabled: this.nextDueDateParams.isDisabled
      }],
      completedAtMileage: [this.getInitialMileageValue, this.ruleHasMileageInterval ? Validators.required : []],
      completedAtOperatingHours: [
        this.roundMeasure(this.getInitialOperatingHoursValue),
        this.ruleHasOperatingHoursInterval ? Validators.required : []],
      maintenanceResult: [this.getMaintenanceResultInitialValue, Validators.required]
    });

    this.addValidatorsNextDueDateControl();
    this.addMaintenanceFieldsControls();

    this.form.markAllAsTouched();
  }

  private addValidatorsNextDueDateControl(): void {
    this.nextDueDateControl.addValidators(DateValidator.controlDateNotBefore(this.completedAtDateControl, 1));
  }

  private addMaintenanceFieldsControls(): void {
    this.taskInfo.maintenanceFieldValues.forEach((fieldValue: MaintenanceFieldValue) => {

      const validators = [];
      if (fieldValue.mandatory) {
        validators.push(Validators.required);
      }
      if (fieldValue.maxLength) {
        switch (fieldValue.maintenanceFieldType) {
          case FieldType.TEXT:
          case FieldType.LONG_TEXT:
            validators.push(Validators.maxLength(fieldValue.maxLength));
            break;
          case FieldType.NUMBER:
            validators.push(Validators.max(fieldValue.maxLength));
            break;
        }
      }

      this.form.addControl(
        fieldValue.maintenanceTypeFieldName,
        new UntypedFormControl(this.getMaintenanceFieldValue(fieldValue), validators)
      );
    });
  }

  private getMaintenanceFieldValue(fieldValue: MaintenanceFieldValue): any {
    return fieldValue.maintenanceFieldType !== FieldType.DATE ? fieldValue.value :
      fieldValue.value == null || fieldValue.value.toString() === '' ? null : new Date(fieldValue.value);
  }

  private subscribeToDateChange(): void {
    this.form.get('completedAtDate').valueChanges.pipe(
        map((newDateInput: string) => DatesService.date(newDateInput)),
        filter((newDate: string) => isDefined(newDate) && !moment(newDate).isAfter(Date.now())),
        flatMap((newDate: string) => this.maintenanceTaskService.getTelematicsAtDate(this.taskInfo.task.equipmentId, newDate)),
        filter((telematics: TelematicsSnapshot) => this.shouldUpdateTelematicsData(telematics)))
    .subscribe(telematics => {
      this.form.patchValue({
        completedAtMileage: this.dimensionUnitConverterPipe.toUserDimensionUnit(telematics.mileage, 'km'),
        completedAtOperatingHours: telematics.operatingHours
      });
      this.snackBar.open(
          this.languageService.getInstant('modules.maintenance.task.hoursMileageUpdated'),
          undefined,
          {duration: 3000});
    });
  }

  private shouldUpdateTelematicsData(telematics: TelematicsSnapshot): boolean {
    return (this.showCompletedAtMileage || this.showCompletedAtOperatingHours) &&
        (this.valueHasChanged(this.completedAtMileage, telematics.mileage) ||
        this.valueHasChanged(this.completedAtOperatingHours, telematics.operatingHours));
  }

  private valueHasChanged(oldValue: number, newValue: number): boolean {
    return (oldValue > 0 || newValue > 0) && (oldValue !== newValue);
  }

  private getMaintenanceFieldKeys(): string[] {
    return Object.keys(this.form.value).filter(key => !this.completionFields.includes(key));
  }

  get getInitialDateValue(): Date {
    return this.taskInfo.completedAtDate ? new Date(this.taskInfo.completedAtDate) : moment().toDate();
  }

  get getInitialMileageValue(): number {
    let initialMileage = this.taskInfo.completed
        ? this.taskInfo.completedAtMileage
        : (this.taskInfo.currentEquipmentMileage ? this.taskInfo.currentEquipmentMileage : 0);
    return this.dimensionUnitConverterPipe.toUserDimensionUnit(initialMileage, 'km');
  }

  get getInitialOperatingHoursValue(): number {
    return this.taskInfo.completed
        ? this.taskInfo.completedAtOperatingHours
        : (this.taskInfo.currentEquipmentOperatingHours ? this.taskInfo.currentEquipmentOperatingHours : 0);
  }

  get getMaintenanceResult(): MaintenanceResult {
    return this.taskInfo.task.maintenanceResult;
  }

  get getMaintenanceResultInitialValue(): MaintenanceResult {
    if (this.completed) {
      return this.getMaintenanceResult;
    } else {
      return this.taskInfo.task.category === MaintenanceCategory.CHECK_AND_REPAIR ? MaintenanceResult.OK : null;
    }
  }

  get ruleHasMileageInterval(): boolean {
    return this.taskInfo.ruleHasMileageInterval;
  }

  get ruleHasOperatingHoursInterval(): boolean {
    return this.taskInfo.ruleHasOperatingHoursInterval;
  }

  get showCompletedAtMileage(): boolean {
    return this.taskInfo.ruleHasMileageInterval ||
        this.taskInfo.currentEquipmentMileage > 0;
  }

  get showCompletedAtOperatingHours(): boolean {
    return this.taskInfo.ruleHasOperatingHoursInterval ||
        this.taskInfo.currentEquipmentOperatingHours > 0;
  }

  get completedAtMileage(): number {
    return this.form.value.completedAtMileage;
  }

  get completedAtOperatingHours(): number {
    return this.form.value.completedAtOperatingHours;
  }

  private isEquipmentRequiresStatusChanges(equipment: ViewEquipment, command: CompleteMaintenanceTaskCommand): boolean {
    const result = command.maintenanceResult;
    const category = equipment.status.category;

    if (MaintenanceResult.NO_RESULT_AVAILABLE === result) {
      return false;

    }
    return (EquipmentStatusCategory.OPERATIONAL === category && MaintenanceResult.OK !== result)
      || (EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS === category && MaintenanceResult.OK_WITH_RESTRICTIONS !== result)
      || (EquipmentStatusCategory.NOT_OPERATIONAL === category && MaintenanceResult.NOT_OK !== result);
  }

  private roundMeasure(measure: number | string): number | string {
    const result = _.round(Number(measure), 2);
    return !_.isNaN(result) ? result : measure;
  }
}
