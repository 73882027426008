import { Chip } from 'app/shared/contract/chip';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { FilterChipsConverterBase } from './base/filter-chips-converter-base.class';

export class FilterChipsConverterCommon extends FilterChipsConverterBase {

  protected convertFromFilterType(filterType: FilterType): Chip[] {
    return this.createChipsFilterType(filterType);
  }

}
