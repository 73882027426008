import { environment } from 'environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, of } from 'rxjs';
import { MaintenanceType } from '../../types/shared/contract/maintenance-type.interface';
import { MaintenanceTypeService } from '../../types/shared/service/maintenance-type.service';
import { MaintenanceRuleService } from '../shared/service/maintenance-rule-service';
import { delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CreateMaintenanceRuleCommand } from '../shared/contract/commands/create-maintenance-rule.command';
import { MaintenanceCategory } from '../../../../shared/enums/maintenance-category.enum';
import { FixedDateInterval } from '../shared/contract/intervals/fixed-date-interval';
import { PeriodInterval } from '../shared/contract/intervals/period-interval';
import { OperatingHoursInterval } from '../shared/contract/intervals/operating-hours-interval';
import { MileageInterval } from '../shared/contract/intervals/mileage-interval';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceRule } from '../shared/contract/maintenance-rule';
import { UpdateMaintenanceRuleCommand } from '../shared/contract/commands/update-maintenance-rule.command';
import { ViewEquipment } from '../../../equipment/contract/view-equipment.interface';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { NameInUseValidator } from '../../shared/validators/name-in-use.validator';
import { AssignMaintenanceRuleCommand } from '../shared/contract/commands/assign-maintenance-rule.command';
import { MatDialog } from '@angular/material/dialog';
import { IntervalCalculationBaseDialogComponent } from './interval-calculation-base-dialog/interval-calculation-base-dialog.component';
import { IntervalCalculationBases } from '../shared/interval-calculation-bases';
import { DatesService } from '../../../../shared/services/dates.service';
import { RouterHistory } from '../../../../shared/router-history';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { isEmpty } from 'lodash';
import { mediumWarningThresholdValidator } from '../shared/validators/medium-warning-threshold.validator';
import { highWarningThresholdValidator } from '../shared/validators/high-warning-threshold.validator';
import { IntervalPanelFormGroupFactoryService } from 'app/shared/services/interval-panel-form-group-factory/interval-panel-form-group-factory.service';
import { IntervalPanelInputType } from '../interval-panel/interval-panel-input-type.enum';
import { IntervalPanelFormType } from 'app/shared/services/interval-panel-form-group-factory/interval-panel-form-type.enum';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';

const INTERVALS = 'intervals';

@Component({
  selector: 'bh-maintenance-rule-add-edit',
  templateUrl: './maintenance-rule-add-edit.component.html',
  styleUrls: ['./maintenance-rule-add-edit.component.scss']
})
export class MaintenanceRuleAddEditComponent extends GuardedNavigableInputComponent implements OnInit{
  @Input() public disabled = false;
  @Input() public maintenanceRule: MaintenanceRule;
  @Input() public nextFixedDateThreshold: string;
  @Input() public nextPeriodThreshold: string;
  @Input() public nextOperatingHoursThresholdDate: string;
  @Input() public nextMileageThresholdDate: string;

  public equipment: ViewEquipment;
  public form: UntypedFormGroup;
  public intervalCountToggle: UntypedFormControl;
  public MaintenanceCategory = MaintenanceCategory;
  public maintenanceTypes: Observable<MaintenanceType[]>;
  public articleGroups: Observable<string[]>;

  public savedForm = false;

  public readonly fieldLimit = FieldLimit;
  public readonly inputTypes = IntervalPanelInputType;

  constructor(private formBuilder: UntypedFormBuilder,
              private maintenanceTypeService: MaintenanceTypeService,
              private maintenanceRuleService: MaintenanceRuleService,
              private dialog: MatDialog,
              private formFactoryService: IntervalPanelFormGroupFactoryService,
              private languageService: LanguageService,
              protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    if (!this.disabled) {
      this.maintenanceRule = this.route.snapshot.data['maintenanceRule'];
      this.equipment = this.route.snapshot.data['equipment'];
    }

    this.buildForm();

    this.handleIntervalCountToggle();
    this.maintenanceTypes = this.maintenanceTypeService.getAll();
    this.articleGroups = this.maintenanceRuleService.getAllArticleGroups()
    .pipe(map(articleGroupCounts => articleGroupCounts.map(count => count.articleGroup)));

    if (this.maintenanceRule) {
      this.patchForm();
    }
  }

  public get name(): AbstractControl {
    return this.form.get('name');
  }

  public get articleGroup(): AbstractControl {
    return this.form.get('articleGroup');
  }

  public get fixedDateInterval(): AbstractControl {
    return this.form.get(INTERVALS).get('fixedDate');
  }

  public get periodInterval(): AbstractControl {
    return this.form.get(INTERVALS).get('period');
  }

  public get operatingHoursInterval(): AbstractControl {
    return this.form.get(INTERVALS).get('operatingHours');
  }

  public get mileageInterval(): AbstractControl {
    return this.form.get(INTERVALS).get('mileage');
  }

  public get atLeastOneIntervalEnabled(): boolean {
    return (this.fixedDateInterval.enabled && this.fixedDateInterval.valid)
      || (this.periodInterval.enabled && this.periodInterval.valid && !!this.periodInterval.get('interval').value)
      || (this.operatingHoursInterval.enabled && this.operatingHoursInterval.valid)
      || (this.mileageInterval.enabled && this.mileageInterval.valid)
  }

  public navigateBack(): void {
    this.equipment ? this.navigateToEquipment() : this.navigateToList();
  }

  public baseCalculationIntervalEnabled(): boolean {
    return this.periodInterval.enabled
        || this.operatingHoursInterval.enabled
        || this.mileageInterval.enabled
  }

  public save(): void {
    if (this.isValid()) {
      const formValue = this.form.value;
      if (this.equipment) {
        this.saveAndAssignRule(formValue)
          .pipe(delay(environment.DELAY_SHORT))
          .subscribe(() => this.navigateToEquipment());
      } else {
        this.saveOrUpdate(formValue)
          .pipe(delay(environment.DELAY_SHORT))
          .subscribe(() => this.navigateToList());
      }
    }
  }

  public isValid(): boolean {
    return !this.form.pristine && this.form.valid && this.atLeastOneIntervalEnabled;
  }

  private saveAndAssignRule(formValue: any): Observable<string> {
    if (this.baseCalculationIntervalEnabled()) {
      return this.saveWithCalculationBases(formValue)
    }

    return this.assignAfterSave(this.saveMaintenanceRule(formValue));
  }

  private saveWithCalculationBases(formValue: any): Observable<string> {
    const dialogRef = this.dialog.open(IntervalCalculationBaseDialogComponent);
    dialogRef.componentInstance.periodIntervalActive = formValue.intervals.period;
    dialogRef.componentInstance.mileageIntervalActive = formValue.intervals.mileage;
    dialogRef.componentInstance.operatingHoursIntervalActive = formValue.intervals.operatingHours;
    return dialogRef.afterClosed()
    .pipe(
        filter(result => result),
        tap(() => this.savedForm = true),
        mergeMap((calculationBases: IntervalCalculationBases) => this.assignAfterSave(this.saveOrUpdate(formValue), calculationBases)));
  }

  private assignAfterSave(sourceObservable: Observable<string>, calculationBases?: IntervalCalculationBases): Observable<string> {
    return sourceObservable
    .pipe(
        tap(async () => this.savedForm = true),
        delay(environment.DELAY_SHORT),
        mergeMap(ruleId => this.assignRuleToEquipment(ruleId, calculationBases)));
  }

  private saveOrUpdate(formValue: any): Observable<string> {
    return this.maintenanceRule ? this.updateMaintenanceRule(formValue) : this.saveMaintenanceRule(formValue);
  }

  private updateMaintenanceRule(formValue: any): Observable<string> {
    const intervals = formValue.intervals;
    const command = new UpdateMaintenanceRuleCommand(
      this.maintenanceRule.maintenanceRuleId,
      formValue.maintenanceTypeId,
      formValue.articleGroup,
      formValue.category,
      formValue.name,
      formValue.description,
      formValue.taskLimit,
      !isEmpty(intervals.fixedDate) ? FixedDateInterval.from(intervals.fixedDate) : null,
      !isEmpty(intervals.period) ? PeriodInterval.months(intervals.period) : null,
      !isEmpty(intervals.mileage) ? this.toMileageInterval(intervals.mileage) : null,
      !isEmpty(intervals.operatingHours) ? OperatingHoursInterval.from(intervals.operatingHours) : null
    );

    return this.checkCanUpdateMaintenanceRule(this.maintenanceRule.maintenanceRuleId).pipe(
      switchMap(res => res ? this.maintenanceRuleService.updateMaintenanceRule(command) : EMPTY)
    );
  }

  private checkCanUpdateMaintenanceRule(maintenanceRuleId: string): Observable<boolean> {
    return this.maintenanceRuleService.getAssignedEquipments(maintenanceRuleId)
      .pipe(
        map(res => res?.length > 0),
        switchMap(isAssigned => isAssigned ? this.confirmUpdateAssignedMaintenanceRule() : of(true))
      )
  }

  private confirmUpdateAssignedMaintenanceRule(): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.maintenance.confirmation.title.editAssignedRule');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.maintenance.confirmation.message.editAssignedRule');
    return dialogRef.afterClosed().pipe(
      map((res: dialogResults) => res === dialogResults.YES)
    );
  }

  private saveMaintenanceRule(formValue: any): Observable<string> {
    const intervals = formValue.intervals;
    const command = new CreateMaintenanceRuleCommand(
        formValue.maintenanceTypeId,
        formValue.articleGroup,
        formValue.category,
        formValue.name,
        formValue.description,
        formValue.taskLimit,
      !isEmpty(intervals.fixedDate) ? FixedDateInterval.from(intervals.fixedDate) : null,
      !isEmpty(intervals.period) ? PeriodInterval.months(intervals.period) : null,
      !isEmpty(intervals.mileage) ? this.toMileageInterval(intervals.mileage) : null,
      !isEmpty(intervals.operatingHours) ? OperatingHoursInterval.from(intervals.operatingHours) : null
    );

    return this.maintenanceRuleService.createMaintenanceRule(command);
  }

  private assignRuleToEquipment(ruleId: string, calculationBases: IntervalCalculationBases): Observable<string> {
    const command = new AssignMaintenanceRuleCommand(ruleId,
      this.equipment.equipmentId,
      DatesService.ospDateTimeAtStartOfDay(calculationBases?.periodCalculationBase),
      calculationBases?.mileageCalculationBase,
      calculationBases?.operatingHoursCalculationBase);
    return this.maintenanceRuleService.assignMaintenanceRuleToEquipment(command);
  }

  public evaluateTypeIcon(): string {
    switch (this.form.get('category').value) {
      case MaintenanceCategory.CHECK: return 'verified_user';
      case MaintenanceCategory.CHECK_AND_REPAIR: return 'build';
      default: return '';
    }
  }

  private buildForm() {
    const intervalControlName = 'interval'
    const intervalDurationControlName = 'intervalDuration'

    const id = this.maintenanceRule ? this.maintenanceRule.maintenanceRuleId : '-1';
    this.form = this.formBuilder.group({
      category: [null, Validators.required],
      name: [
        null,
        [Validators.required, Validators.maxLength(FieldLimit.MEDIUM_IDENTIFIER), TrimValidator.hasWhitespaces],
        asyncValidatorFactory(value => NameInUseValidator.isValid(id, value, this.maintenanceRuleService))
      ],
      maintenanceTypeId: [null, Validators.required],
      articleGroup: [null, Validators.maxLength(FieldLimit.MEDIUM_IDENTIFIER)],
      description: [null, Validators.maxLength(FieldLimit.LONG_DESCRIPTION)],
      taskLimit: [null, [Validators.required, Validators.min(1)]],
      intervals: this.formBuilder.group({
        fixedDate: this.formFactoryService.buildForm(IntervalPanelFormType.FIXED_DATE),
        period: this.formFactoryService.buildForm(IntervalPanelFormType.PERIOD_INTERVAL),
        operatingHours: this.formFactoryService.buildForm(IntervalPanelFormType.OPERATING_HOURS),
        mileage: this.formFactoryService.buildForm(IntervalPanelFormType.MILEAGE),
      })
    });

    let mediumValidator = mediumWarningThresholdValidator(intervalControlName, intervalDurationControlName);
    let highValidator = highWarningThresholdValidator(intervalControlName, intervalDurationControlName);
    this.periodInterval.get('mediumWarningThreshold').addValidators(mediumValidator)
    this.periodInterval.get('highWarningThreshold').addValidators(highValidator)

    this.form.get('taskLimit').disable();

    this.fixedDateInterval.disable();
    this.periodInterval.disable();
    this.operatingHoursInterval.disable();
    this.mileageInterval.disable();
  }

  private patchForm(): void {
    this.form.patchValue({
      category: this.maintenanceRule.maintenanceCategory,
      name: this.maintenanceRule.ruleName,
      maintenanceTypeId: this.maintenanceRule.maintenanceTypeId,
      articleGroup: this.maintenanceRule.articleGroup,
      description: this.maintenanceRule.ruleDescription,
      taskLimit: this.maintenanceRule.taskLimit,
    });

    if (this.maintenanceRule.taskLimit > 0) {
      this.intervalCountToggle.setValue(true);
    }

    this.fixedDateInterval.patchValue(
      this.formFactoryService.getPatchValue(IntervalPanelFormType.FIXED_DATE, this.maintenanceRule));
    this.periodInterval.patchValue(
      this.formFactoryService.getPatchValue(IntervalPanelFormType.PERIOD_INTERVAL, this.maintenanceRule));
    this.operatingHoursInterval.patchValue(
      this.formFactoryService.getPatchValue(IntervalPanelFormType.OPERATING_HOURS, this.maintenanceRule));
    this.mileageInterval.patchValue(
      this.formFactoryService.getPatchValue(IntervalPanelFormType.MILEAGE, this.maintenanceRule));

    if (this.disabled) {
      this.form.disable();
      this.intervalCountToggle.disable();
      this.form.get('taskLimit').disable();
    }
  }

  private handleIntervalCountToggle() {
    this.intervalCountToggle = new UntypedFormControl();
    this.intervalCountToggle.valueChanges.subscribe(value => {
      const control = this.form.get('taskLimit');
      if (value) {
        control.enable();
      } else {
        control.disable();
        control.reset();
      }
    });
  }

  private navigateToList(): void {
    this.router.navigate(['maintenance', 'rules', 'list']);
  }

  private navigateToEquipment(): void {
    this.router.navigate(['equipments', 'list', this.equipment.equipmentId, 'tasks'])
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private toMileageInterval(mileageFormValue: any): MileageInterval {
    return new MileageInterval(
      this.dimensionUnitConverterPipe.toSystemDimensionUnit(mileageFormValue.interval, 'km'),
      this.dimensionUnitConverterPipe.toSystemDimensionUnit(mileageFormValue.mediumWarningThreshold, 'km'),
      this.dimensionUnitConverterPipe.toSystemDimensionUnit(mileageFormValue.highWarningThreshold, 'km'));
  }
}
