import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { EquipmentsDataSource } from '../shared/equipments.datasource';
import { EquipmentStatusCategoryPipe } from '../../../shared/pipes/equipment-status-category.pipe';
import { LanguageService } from '../../../shared/services/language.service';
import { ViewEquipment } from '../contract/view-equipment.interface';
import { Status } from '../contract/status.interface';
import { EquipmentStatusCategory } from '../contract/equipment-status-category.enum';
import {
  ConfirmationDialogComponent,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { UpdateEquipmentChangeStatusCommand } from '../contract/update-equipment-change-status-command';

export abstract class AbstractEquipmentStatusStateChangeComponent {

  disabled = false;
  equipment: ViewEquipment;

  protected constructor(protected dialog: MatDialog,
                        protected langService: LanguageService,
                        protected equipmentStore: EquipmentsDataSource,
                        protected equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe) {}

  public onStatusButtonClick(equipmentStatus: Status): void {
    if (this.isNotAvailableStatusCategory(equipmentStatus.category) &&
        !this.isNotAvailableStatusCategory(this.equipmentStore.getCurrentEquipment().status.category)
    ) {
      this.confirmStatusChange(equipmentStatus.equipmentStatusId, equipmentStatus.category);
    } else {
      this.changeStatus(equipmentStatus.equipmentStatusId);
    }
  }

  protected translate(key: string): string {
    return this.langService.getInstant(key);
  }

  private confirmStatusChange(equipmentStatusId: string, category: string) {
    this.equipmentStore.hasUnfulfilledTransportsByEquipmentId(this.equipment.equipmentId)
    .pipe(switchMap(hasUnfulfilledTransports => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
      const hasAssignedTelematics = this.equipment.assignedTelematicsUnits?.length > 0;
      dialogRef.componentInstance.confirmMessage
        = this.getConfirmationMessageEquipmentChangeStatus(hasUnfulfilledTransports, hasAssignedTelematics, category);
      return dialogRef.afterClosed();
    })).subscribe(result => {
      if (result === dialogResults.YES) {
        this.changeStatus(equipmentStatusId);
      }
    });
  }

  private changeStatus(equipmentStatusId: string) {
    let cmd: UpdateEquipmentChangeStatusCommand = new UpdateEquipmentChangeStatusCommand();
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.equipmentStatusId = equipmentStatusId;
    this.equipmentStore.updateEquipmentChangeStatus(cmd).subscribe({
      error: (error: HttpErrorResponse) => {
        console.log('update equipment error', error);
      },
    });
  }

  private getConfirmationMessageEquipmentChangeStatus(hasUnfulfilledTransports: boolean,
                                                      hasAssignedTelematicsUnit: boolean,
                                                      category: string): string {
    const mainPart = this.langService.getInstant('modules.equipment.confirmation.message.equipmChangeStateWithCategoryNotAvailable',
      {category: this.equipmentStatusCategoryPipe.transform(category)});
    return mainPart + this.getUnfulfilledTransportsMessagePart(hasUnfulfilledTransports)
      + this.getAssignedTelematicsUnitsMessagePart(hasAssignedTelematicsUnit);
  }

  private getUnfulfilledTransportsMessagePart(hasUnfulfilledTransports: boolean): string {
    return hasUnfulfilledTransports
      ? `\n${this.translate('modules.equipment.confirmation.message.equipmChangeStateWithCategoryNotAvailableUnfulfilledTransports')}`
      : '';
  }

  private getAssignedTelematicsUnitsMessagePart(hasTelematicsUnitAssigned: boolean) {
    return hasTelematicsUnitAssigned
      ? `\n${this.translate('modules.equipment.confirmation.message.unassingTelematicUnitAddon')}`
      : '';
  }

  private isNotAvailableStatusCategory(category: string): boolean {
    return category === EquipmentStatusCategory.NOT_AVAILABLE;
  }
}
