<bh-step-dialog
  class="contact-dialog"
  [icon]="faIconTitle"
  [title]="(contact
    ? 'modules.contact.contactAddEdit.editContact'
    : 'modules.contact.contactAddEdit.createContact')|translate">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="contactForm">
      <mat-horizontal-stepper
        linear
        labelPosition="bottom"
        fxFlex="100"
        fxLayout="column"
        fxLayoutAlign="start stretch">

        <!-- TYPE -->
        <mat-step [stepControl]="typeControl">
          <ng-template matStepLabel>{{'modules.contact.contactAddEdit.chooseType'|translate}}*</ng-template>
          <div class="stepper-content" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="main-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="85">
              <bh-card-select
                class="main-container__type-select main-container__content-padding"
                [options]="contactTypeOptions"
                [optionResolver]="contactTypeResolver"
                [formControl]="typeControl"
                ngDefaultControl>
              </bh-card-select>
            </div>
            <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
              <button mat-raised-button matStepperNext type="button" [disabled]="typeControl.invalid">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>

        <!-- GENERAL DATA -->
        <mat-step [stepControl]="generalDataControl" [formGroup]="generalDataControl">
          <ng-template matStepLabel>{{'general.generalData'|translate}}*</ng-template>
          <div class="stepper-content" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="main-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="85">
              <div class="main-container__content-padding" fxLayout="row" fxLayoutAlign="space-around start">
                <div fxFlex="40">
                  <mat-form-field>
                    <mat-label>{{'general.identifier'|translate}}</mat-label>
                    <input
                      matInput
                      type="text"
                      bhRemoveWhitespaces
                      [formControl]="identifierControl"
                      [maxlength]="fieldLimit.NAME"
                      autocomplete="off">
                    <mat-error *ngIf="identifierControl.hasError('contactIdentifierInUse')">
                      {{'modules.contact.contactAddEdit.uniqueIdentifier'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.name.s'|translate}}</mat-label>
                    <input
                      matInput
                      type="text"
                      bhRemoveWhitespaces
                      [formControl]="nameControl"
                      [maxlength]="fieldLimit.NAME"
                      autocomplete="off"
                      required>
                    <mat-error *ngIf="nameControl.hasError('required') || nameControl.hasError('pattern')">
                      {{'shared.validation.required'|translate:{value: 'general.name.s'|translate} }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.org.s'|translate}}</mat-label>
                    <mat-select
                      formControlName="organisationId"
                      disableOptionCentering="true"
                      required>
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="organisationFilter"
                          [placeholderLabel]="'general.labels.search'|translate:{ value: ('general.org.s'|translate) }"
                          [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:
                            { value: ('general.org.pl'|translate) }"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let organisation of filteredOrganisations | async"
                        [value]="organisation.organisationId">
                        {{ organisation.organisationName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="organisationControl.hasError('required')">
                      {{'shared.validation.required'|translate:{value: 'general.org.s'|translate} }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="40">
                  <mat-form-field>
                    <mat-label>{{'modules.contact.base.contactPerson'|translate}}</mat-label>
                    <input
                      matInput
                      type="text"
                      bhRemoveWhitespaces
                      [formControl]="contactPersonControl"
                      [maxlength]="fieldLimit.NAME"
                      autocomplete="off">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.email'|translate}}</mat-label>
                    <input
                      matInput
                      type="email"
                      bhRemoveWhitespaces
                      [formControl]="emailControl"
                      autocomplete="off">
                    <mat-error *ngIf="emailControl.hasError('validEmail')">
                      {{'shared.validation.enterValidEmail'|translate}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'modules.contact.base.phoneNumber'|translate}}</mat-label>
                    <input
                      matInput
                      type="text"
                      bhRemoveWhitespaces
                      [formControl]="phoneNumberControl"
                      [maxlength]="fieldLimit.PHONE_NUMBER"
                      autocomplete="off">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row"  class="label-container" fxLayoutAlign="space-between start">
                <div class="label-field" fxFlex="100">
                  <bh-label-chips *ngIf="!contact"
                                  (labelAdded)="addLabel($event)"
                                  (labelDeleted)="removeLabel($event)"
                                  [customerLabels]="customerLabels | async"
                                  [labels]="contactLabels | async"
                                  [hasAuthority]="hasAuthority(authorities.CONTACT_MANAGE)">
                  </bh-label-chips>
                </div>
              </div>
            </div>
            <div class="button-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <div fxLayoutGap="15px">
                <ng-container *ngIf="contact" [ngTemplateOutlet]="saveButton"></ng-container>
                <button mat-raised-button matStepperNext type="button" [disabled]="!generalDataControl.valid">
                  {{'general.buttons.continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <!-- ADDRESS -->
        <mat-step [stepControl]="addressControl" [formGroup]="addressControl">
          <ng-template matStepLabel>{{'general.address.address'|translate}}*</ng-template>
          <div class="stepper-content" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="main-container" fxLayout="row" fxLayoutAlign="space-around stretch" fxFlex="85">
              <div fxFlex="45" fxLayout="column">
                <bh-map-location-select
                  fxFlex="100"
                  [mandatory]="false"
                  [address]="enteredAddress"
                  [location]="contact?.location"
                  (addressChange)="updateAddress($event)"
                  (geocoderStatus)="geocoderStatus = $event"
                  data-osp-test="map-location-select">
                </bh-map-location-select>
              </div>
              <div class="input-container"fxFlex="45" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{'general.address.street'|translate}}</mat-label>
                  <input
                    matInput
                    bhRemoveWhitespaces
                    [formControl]="streetControl"
                    [maxlength]="fieldLimit.STREET"
                    autocomplete="off">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'general.address.number'|translate}}</mat-label>
                  <input
                    matInput
                    bhRemoveWhitespaces
                    [formControl]="streetNumberControl"
                    [maxlength]="fieldLimit.STREET_NUMBER"
                    autocomplete="off">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'general.address.zipcode'|translate}}</mat-label>
                  <input
                    matInput
                    bhRemoveWhitespaces
                    [formControl]="postalCodeControl"
                    [maxlength]="fieldLimit.POSTAL_CODE"
                    autocomplete="off">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'general.address.city'|translate}}</mat-label>
                  <input
                    matInput
                    bhRemoveWhitespaces
                    [formControl]="cityControl"
                    [maxlength]="fieldLimit.CITY"
                    autocomplete="off">
                </mat-form-field>
                <div [formGroup]="addressLocationControl">
                  <mat-form-field>
                    <mat-label>{{'general.location.latitude'|translate}}</mat-label>
                    <input
                      matInput
                      type="number"
                      [min]="geoLocationLimit.LAT_MIN"
                      [max]="geoLocationLimit.LAT_MAX"
                      [step]="geoLocationChangeStep"
                      formControlName="lat"
                      [maxlength]="fieldLimit.GEO_COORDINATE"
                      autocomplete="off">
                    <mat-error *ngIf="latitudeControl.hasError('min') || latitudeControl.hasError('max')">
                      {{'shared.validation.range'|translate:
                        {minValue: geoLocationLimit.LAT_MIN, maxValue: geoLocationLimit.LAT_MAX}
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.location.longitude'|translate}}</mat-label>
                    <input
                      matInput
                      type="number"
                      [min]="geoLocationLimit.LON_MIN"
                      [max]="geoLocationLimit.LON_MAX"
                      [step]="geoLocationChangeStep"
                      formControlName="lon"
                      [maxlength]="fieldLimit.GEO_COORDINATE"
                      autocomplete="off">
                    <mat-error *ngIf="longitudeControl.hasError('min') || longitudeControl.hasError('max')">
                      {{'shared.validation.range'|translate:
                        {minValue: geoLocationLimit.LON_MIN, maxValue: geoLocationLimit.LON_MAX}
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="error" *ngIf="addressControl.hasError('notResolved') && addressControl.dirty">
                  {{'modules.contact.contactAddEdit.addressCannotBeResolved'|translate}}
                </div>
              </div>
            </div>
            <div class="button-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>

<ng-template #saveButton>
  <button
    mat-raised-button type="button"
    color="primary"
    (click)="save()"
    [disabled]="!isValid() || addressControl.pristine || saveInProgress"
    data-osp-test="button-save">
    {{'general.buttons.save'|translate}}
  </button>
</ng-template>
