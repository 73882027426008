import {DataSource} from '@angular/cdk/table';
import {TelematicUnitListItem} from '../shared/telematic-unit-list.interface';
import {Pageable} from '../../../shared/contract/task/pageable.interface';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {TelematicUnitAdministrationService} from './telematic-unit-administration.service';
import {Injectable} from '@angular/core';
import {PagedResponse} from '../../../shared/contract/page-response.interface';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {delay, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {
  CreateCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/create-customer-telematic-unit-command';
import {
  UpdateCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/update-customer-telematic-unit-command';
import {
  AssignCustomerTelematicUnitToCustomerCommand
} from '../shared/commands/action/assign-customer-telematic-unit-to-customer-command';
import {DeleteCustomerTelematicUnitCommand} from '../shared/commands/customer-telematic-unit/delete-customer-telematic-unit-command';
import {ActivateCustomerTelematicUnitCommand} from '../shared/commands/action/activate-customer-telematic-unit-command';
import {
  DeactivateCustomerTelematicUnitCommand
} from '../shared/commands/action/deactivate-customer-telematic-unit-command';
import {PartnerInfo} from '../shared/partner-info.interface';
import {SearchCustomer} from '../../organisation/contract/search-customer.interface';
import {UnassignCustomerTelematicUnitCommand} from '../shared/commands/action/unassign-customer-telematic-unit-command';
import {filterByTerm, filterMultipleFieldsByTerm} from '../../../shared/collection-utils';
import {TelematicsUnitColumnService} from 'app/modules/disposition/shared/services/telematics-unit-column.service';
import {CustomerTelematicUnitTypeEnum} from '../shared/customer-telematic-unit-type.enum';
import {TelematicUnitImportResultInterface} from '../shared/telematic-unit-import-result.interface';
import {TelematicsUnitType} from '../../equipment/contract/telematics-unit-type.enum';

@Injectable()
export class TelematicUnitAdministrationDatasource extends DataSource<TelematicUnitListItem> implements Pageable {
  private telematicUnits: BehaviorSubject<TelematicUnitListItem[]> = new BehaviorSubject([]);
  private index: BehaviorSubject<number> = new BehaviorSubject(0);
  private size: BehaviorSubject<number> = new BehaviorSubject(25);
  private length: BehaviorSubject<number> = new BehaviorSubject(0);
  private simCardProviders: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private simCardProvidersFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private partnersInfo: BehaviorSubject<PartnerInfo[]> = new BehaviorSubject<PartnerInfo[]>([]);
  private partnersFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private customersInfo: BehaviorSubject<SearchCustomer[]> = new BehaviorSubject<SearchCustomer[]>([]);

  private _sort: Sort;
  private _telematicType: string;
  private _simCardProvider: string;
  private _assignedPartner: string;
  private _customer: string;
  private _term: string;
  private customerNameFilter: BehaviorSubject<string> = new BehaviorSubject('');

  public readonly filteredCustomersNames: Observable<SearchCustomer[]> = combineLatest([
    this.customersInfo,
    this.customerNameFilter]
  ).pipe(
    map(([customersNames, filterTerm]) => filterMultipleFieldsByTerm(
      customersNames,
      filterTerm,
      [
        'customerName',
        'customerNumber',
        'shopId',
        ['customerAddress', 'postalCode'],
        ['customerAddress', 'city'],
        ['customerAddress', 'street'],
        ['customerAddress', 'streetNumber']
      ])));


  public readonly filteredSimCardProviders: Observable<string[]> = combineLatest([
    this.simCardProviders,
    this.simCardProvidersFilter]
  ).pipe(
    map(([simCardProvider, filterTerm]) => filterByTerm(simCardProvider, filterTerm)));

  public readonly filteredPartners: Observable<PartnerInfo[]> = combineLatest([
    this.partnersInfo,
    this.partnersFilter]
  ).pipe(
    map(([partners, filterTerm]) => filterByTerm(partners, filterTerm, 'partnerName')));


  constructor(
    private telematicUnitAdministrationService: TelematicUnitAdministrationService,
    public telematicsUnitColumnService: TelematicsUnitColumnService
  ) {
    super();
    this.telematicsUnitColumnService.pageSize.subscribe((pageSize: number) => {
      this.size.next(pageSize);
    });
  }

  get sort() {
    return this._sort;
  }

  set sort(sort: Sort) {
    this._sort = sort;
  }

  get telematicType() {
    return this._telematicType;
  }

  set telematicType(value: string) {
    this._telematicType = value;
  }

  get simCardProvider() {
    return this._simCardProvider;
  }

  set simCardProvider(value: string) {
    this._simCardProvider = value;
  }

  get customer() {
    return this._customer;
  }

  set customer(value: string) {
    this._customer = value;
  }

  get assignedPartner() {
    return this._assignedPartner;
  }

  set assignedPartner(value: string) {
    this._assignedPartner = value;
  }

  get term() {
    return this._term;
  }

  set term(value: string) {
    this._term = value;
  }

  connect(): Observable<TelematicUnitListItem[]> {
    return this.telematicUnits;
  }

  disconnect(): void {
  }

  public loadAllFields(): void {
    this.reset();
    this.getSimCardProviders();
    this.getPartners();
  }

  loadTelematicUnits(): void {
    const params = {
      sort: this.sort ? `${this.sort.active},${this.sort.direction}` : 'addedDate,desc',
      types: this.telematicType ? this.telematicType : null,
      simCardProviders: this.simCardProvider ? this.simCardProvider : null,
      partnerIds: this.assignedPartner ? this.assignedPartner : null,
      customerFilter: this.customer ? this.customer : null,
      terms: this.term ? this.term : null,
      page: this.index.value,
      size: this.size.value
    }
    this.telematicUnitAdministrationService
    .getTelematicUnits(params)
    .subscribe((res: PagedResponse<TelematicUnitListItem>) => {
      this.length.next(res.totalElements);
      this.telematicUnits.next(res.content);
    });
  }

  getIndex(): Observable<number> {
    return this.index.asObservable();
  }

  getSize(): Observable<number> {
    return this.size.asObservable();
  }
  getLength(): Observable<number> {
    return this.length.asObservable();
  }

  public resetPageIndex(): void {
    this.index.next(0);
  }

  public filterSimCardProvider(value: string): void {
    this.simCardProvidersFilter.next(value);
  }

  public filterPartners(value: string): void {
    this.partnersFilter.next(value);
  }

  public filterCustomersName(value: string): void {
    this.customerNameFilter.next(value);
  }

  handlePageEvent(event: PageEvent) {
    this.index.next(event.pageIndex);
    this.size.next(event.pageSize);
    this.loadTelematicUnits();
    this.telematicsUnitColumnService.selectColumns([], event.pageSize);
  }

  public addCustomerTelematicUnit(cmd: CreateCustomerTelematicUnitCommand): Observable<string> {
    let observable: Observable<string> = this.telematicUnitAdministrationService.addCustomerTelematicUnit(cmd);

    return observable.pipe(
      switchMap((res: any) => {
        setTimeout(() => {
          this.updateListing();
        }, environment.DELAY_SHORT);
        return of(res);
      })
    );
  }

  public updateCustomerTelematicsUnit(cmd: UpdateCustomerTelematicUnitCommand, type: TelematicsUnitType): Observable<string> {
    let observable: Observable<string> = this.telematicUnitAdministrationService.updateCustomerTelematicUnit(cmd, type);

    return observable.pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.updateListing();
        }, environment.DELAY_LONG);
        return of(res);
      }));
  }

  public assignTelematicUnitToCustomer(cmd: AssignCustomerTelematicUnitToCustomerCommand): Observable<string> {
    return this.telematicUnitAdministrationService.assignTelematicUnitToCustomer(cmd)
    .pipe(switchMap((res: any) => {
      setTimeout(() => {
        this.updateListing();
      }, environment.DELAY_LONG);
      return of(res);
    }));

  }

  public unAssignTelematicUnitFromCustomer(cmd: UnassignCustomerTelematicUnitCommand): Observable<string> {
    return this.telematicUnitAdministrationService.unAssignTelematicUnitFromCustomer(cmd)
    .pipe(switchMap((res: any) => {
      setTimeout(() => {
        this.updateListing();
      }, environment.DELAY_LONG);
      return of(res);
    }));

  }

  public updateListing(): void {
    this.loadTelematicUnits();
  }

  public deleteCustomerTelematicUnitCommand(command: DeleteCustomerTelematicUnitCommand, type: CustomerTelematicUnitTypeEnum): void {
    let observable: Observable<string> = this.telematicUnitAdministrationService.deleteCustomerTelematicUnit(command, type);

    observable.pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.loadTelematicUnits()),
      )
      .subscribe();
  }

  public activateTelematicUnit(command: ActivateCustomerTelematicUnitCommand): void {
    this.telematicUnitAdministrationService.activateTelematicUnit(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.loadTelematicUnits()),
    )
    .subscribe();
  }

  public deactivateTelematicUnit(command: DeactivateCustomerTelematicUnitCommand): void {
    this.telematicUnitAdministrationService.deactivateTelematicUnit(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.loadTelematicUnits()),
    )
    .subscribe();
  }

  customerBulkImport(data: CreateCustomerTelematicUnitCommand[]) {
    let observable: Observable<TelematicUnitImportResultInterface[]>
      = this.telematicUnitAdministrationService.bulkImportCustomerTelematicUnit(data);

    return observable.pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.loadTelematicUnits())
      )
  }

  getSimCardProviders(): void {
    this.telematicUnitAdministrationService.getSimCardProviders()
      .subscribe(data => this.simCardProviders.next(data));
  }

  getPartners(): Observable<PartnerInfo[]> {
    this.telematicUnitAdministrationService.getPartnersInfo()
      .subscribe(data => this.partnersInfo.next(data));
    return this.partnersInfo.asObservable();
  }

  getCustomers(partnerId?: string): Observable<SearchCustomer[]> {
    this.telematicUnitAdministrationService.getAllCustomers(partnerId)
      .subscribe(data => this.customersInfo.next(data));
    return this.customersInfo.asObservable();
  }

  private reset(): void {
    this.filterPartners('');
    this.filterSimCardProvider('');
  }
}
