import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { EmployeeManagementDatasource } from '../../../../../shared/employee-management.datasource';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { BaseEmployeeViewPrivateDataComponent } from './base/base-employee-view-private-data.component';

@Component({
  selector: 'bh-employee-view-private-data',
  templateUrl: './employee-view-private-data.component.html',
  styleUrls: ['./employee-view-private-data.component.scss']
})
export class EmployeeViewPrivateDataComponent extends BaseEmployeeViewPrivateDataComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected userConfigurationService: UserConfigurationService,
              public employeeManagementStore: EmployeeManagementDatasource) {
    super(authService, router, route, routerHistory, employeeManagementStore, userConfigurationService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
