import { Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from '../shared/services/reports.service';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsDataSource } from '../shared/services/reports.datasource';
import { CreateReportDefinitionCommand } from '../contract/create-report-definition-command';
import { ReportParameter } from '../contract/report-parameter.enum';
import { ReportType } from '../contract/report-type.enum';
import { ReportExportFormat } from '../contract/report-export-format.enum';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CustomersService } from '../../organisation/shared/customers.service';
import { SearchCustomer } from '../../organisation/contract/search-customer.interface';
import { ViewReportDefinition } from '../contract/view-report-definition.interface';
import { UpdateReportDefinitionCommand } from '../contract/update-report-definition-command';
import { Subscription } from 'rxjs';
import { RouterHistory } from '../../../shared/router-history';
import {
  FileDownloadService
} from '../../../shared/components/test-place/secured-image/services/file-download.service';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { split, last } from 'lodash';

@Component({
  selector: 'bh-report-add-edit',
  templateUrl: './report-add-edit.component.html',
  styleUrls: ['./report-add-edit.component.scss']
})
export class ReportAddEditComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  reportDefinitionForm: UntypedFormGroup;
  documents: AttachedDocument[] = [];
  uploaderDocument: FileUploader = this.reportService.getTemplateUploader();
  templateUploadFilename: string;
  reportParameters: string[];
  reportTypes: string[];
  reportExportFormats: string[];
  customers: SearchCustomer[];
  reportDefinition: ViewReportDefinition;

  private customersSub: Subscription;
  private reportDefinitionSub: Subscription;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private customerService: CustomersService,
              private reportService: ReportsService,
              private reportStore: ReportsDataSource,
              private fileDownloadService: FileDownloadService) {

    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    let reportDefinitionId = this.route.snapshot.params['id'];

    this.reportParameters = Object.keys(ReportParameter);
    this.reportTypes = Object.keys(ReportType);
    this.reportExportFormats = Object.keys(ReportExportFormat);

    this.buildForm();
    this.getCustomers();
    this.getReportDefinition(reportDefinitionId);
  }

  ngOnDestroy(): void {
    if (this.customersSub) {
      this.customersSub.unsubscribe();
    }

    if (this.reportDefinitionSub) {
      this.reportDefinitionSub.unsubscribe();
    }
  }

  public navigateBack(): void {
    this.goBack('/reportdefinitions/list');
  }

  getCustomers(): void {
    this.customersSub = this.customerService.getCustomers(0, 500).subscribe(res => {
      if (res) {
        this.customers = res.content;
      }
    })
  }

  getReportDefinition(id: string): void {
    if (id) {
      this.reportDefinitionSub = this.reportStore.getReportDefinition(id).subscribe(res => {
        if (res) {
          this.reportDefinition = res;
          this.setFormValues();
        }
      });
    }
  }

  uploadDocument(): void {
    this.uploaderDocument.authToken = this.authService.getTokenForHeader('Bearer');
    this.uploaderDocument.uploadAll();

    this.uploaderDocument.onSuccessItem = (item: FileItem, response: any) => {
      this.reportDefinitionForm.patchValue({ reportDefinitionTemplateKey: response });
      this.templateUploadFilename = item._file.name;
    };
  }

  resetForm(): void {
    this.reportDefinitionForm.reset();
    this.templateUploadFilename = null;
  }

  save(): void {
    if (this.isValid()) {
      if (this.reportDefinition) {
        this.updateReportDefinition();
      } else {
        this.saveReportDefinition();
      }
    }
  }

  public isValid(): boolean {
    return this.reportDefinitionForm.valid;
  }

  private saveReportDefinition(): void {
    let cmd: CreateReportDefinitionCommand = new CreateReportDefinitionCommand();
    let formData = this.reportDefinitionForm.getRawValue();

    cmd.reportDefinitionName = formData.reportDefinitionName;
    cmd.reportDefinitionCustomers = formData.reportDefinitionCustomers;
    cmd.reportDefinitionTemplateKey = formData.reportDefinitionTemplateKey;
    cmd.reportDefinitionExportFormat = formData.reportDefinitionExportFormat;
    cmd.reportDefinitionParameters = formData.reportDefinitionParameters;
    cmd.reportIncludesLocations = formData.reportIncludesLocations;

    this.reportStore.addReportDefinition(cmd).subscribe(
      () => {
        this.router.navigate(['reportdefinitions/list']);
      },
      () => {
      });
  }

  private updateReportDefinition(): void {
    let cmd: UpdateReportDefinitionCommand = new UpdateReportDefinitionCommand();
    let formData = this.reportDefinitionForm.getRawValue();

    cmd.reportDefinitionId = this.reportDefinition.reportDefinitionId;
    cmd.reportDefinitionName = formData.reportDefinitionName;
    cmd.reportDefinitionCustomers = formData.reportDefinitionCustomers;
    cmd.reportDefinitionTemplateKey = formData.reportDefinitionTemplateKey;
    cmd.reportDefinitionExportFormat = formData.reportDefinitionExportFormat;
    cmd.reportDefinitionParameters = formData.reportDefinitionParameters;
    cmd.reportIncludesLocations = formData.reportIncludesLocations;

    this.reportStore.updateReportDefinition(cmd).subscribe(
      () => {
        this.router.navigate(['reportdefinitions/list']);
      },
      () => {
      });
  }

  private buildForm(): void {
    this.reportDefinitionForm = this.formBuilder.group({
      reportDefinitionName: ['', [<any>Validators.required]],
      reportDefinitionExportFormat: ['', [<any>Validators.required]],
      reportDefinitionTemplateKey: ['', [<any>Validators.required]],
      reportDefinitionCustomers: ['', [<any>Validators.required]],
      reportDefinitionParameters: ['', [<any>Validators.required]],
      reportIncludesLocations: [false, [<any>Validators.required]]
    });
  }

  private setFormValues(): void {
    this.reportDefinitionForm.patchValue({
      reportDefinitionName: this.reportDefinition.reportDefinitionName,
      reportDefinitionExportFormat: this.reportDefinition.reportDefinitionExportFormat,
      reportDefinitionTemplateKey: this.reportDefinition.reportDefinitionTemplateKey,
      reportDefinitionCustomers: this.reportDefinition.reportDefinitionCustomers,
      reportDefinitionParameters: this.reportDefinition.reportDefinitionParameters,
      reportIncludesLocations: !!this.reportDefinition.reportIncludesLocations
    });
  }

  public downloadReportTemplate(): void {
    const templateKey = this.reportDefinition.reportDefinitionTemplateKey;
    const templateName = last(split(templateKey, '/'));

    this.fileDownloadService.saveFile({
      documentKey: `global/${templateKey}`,
      fileName: templateName
    } as DocumentLink);
  }
}
