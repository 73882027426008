import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faMapMarkedAlt, faTrashAlt, faWarehouse, faFilePdf } from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarPlus, faCheck, faQuestion, faTimes, faWallet } from '@fortawesome/pro-light-svg-icons';
import { LanguageService } from '../../../../../shared/services/language.service';
import { ReportsService } from '../../../../reports/shared/services/reports.service';
import { TransferRequestState } from '../../../../disposition/projects/project-list/project-view/project-view-transfer-history/transfer-request-state-enum';
import { TransferType } from '../../../../disposition/projects/project-list/project-view/project-view-transfer-history/transfer-type';
import { environment } from '../../../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { FileUtils } from '../../../../../shared/fileUtils';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ViewTransferRequest } from '../../../../disposition/projects/project-list/project-view/project-view-transfer-history/view-transfer-request';
import { faLongArrowAltDown } from '@fortawesome/pro-solid-svg-icons';
import { TransferRequestEntry } from '../../../../disposition/projects/project-list/project-view/project-view-transfer-history/transfer-request-entry';
import { LocationType } from 'app/shared/enums/location-type.enum';

@Component({
  selector: 'bh-transfer-request',
  templateUrl: './transfer-request.component.html',
  styleUrls: ['./transfer-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferRequestComponent implements OnInit {
  @ViewChild('panel', {static: true}) panel: MatExpansionPanel;

  @Input() transfer: ViewTransferRequest;

  @Output() onRevert: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDeclineTransferRequest: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAcknowledgeTransferRequest: EventEmitter<string> = new EventEmitter<string>();

  public readonly faLongArrowAltDown: IconDefinition = faLongArrowAltDown;
  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;
  public readonly faCalendarPlus: IconDefinition = faCalendarPlus;
  public readonly faFilePdf: IconDefinition = faFilePdf;
  public readonly faWallet: IconDefinition = faWallet;
  public isDeclined: boolean;
  public isAcknowledged: boolean;
  public isPending: boolean;
  public transferRequestIsProcessing = false;
  public panelClass: string;

  public groupedTransferItems:
    {
      sourceId: string;
      sourceLocationIsAccessibleForUser: boolean;
      sourceCostCenter: string;
      sourceNumber: string;
      sourceName: string;
      items: TransferRequestEntry[];
      toolTip: string;
    }[];

  public sourceIcon: IconDefinition;
  public sourceLabel: string;
  public sourceName: string;
  public sourceNumber: string;
  public sourceCostCenter: string;
  public isSourceProjectNumberDisplay: boolean;
  private sourceType: string;

  public targetIcon: IconDefinition;
  public targetLabel: string;
  public targetName: string;
  public targetNumber: string;
  public targetCostCenter: string;
  public isTargetProjectNumberDisplay: boolean;
  private targetType: string;

  public downloading: boolean;

  private readonly stockIcon: IconDefinition = faWarehouse;
  private readonly projectIcon: IconDefinition = faMapMarkedAlt;
  private readonly consumedIcon: IconDefinition = faTrashAlt;
  private readonly defaultIcon: IconDefinition = faQuestion;

  public get internalEmployee(): string {
    return [this.transfer.employeeFirstName, this.transfer.employeeLastName]
    .filter(Boolean)
    .join(' ');
  }

  constructor(public elem: ElementRef,
              private languageService: LanguageService,
              private reportStore: ReportsService,
              private cdr: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.setInitOptions();
  }

  public open(): void {
    this.panel.open();
  }

  public revertTransfer(event: MouseEvent, transferId: string) {
    event.stopPropagation();
    this.onRevert.emit(transferId);
  }

  public declineTransferRequest(event: MouseEvent, transferId: string) {
    event.stopPropagation();
    this.disableTransferRequestButtons();
    this.onDeclineTransferRequest.emit(transferId);
  }

  public acknowledgeTransferRequest(event: MouseEvent, transferId: string) {
    event.stopPropagation();
    this.disableTransferRequestButtons();
    this.onAcknowledgeTransferRequest.emit(transferId);
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public getAmountOfPreviousItems(groupIndex: number): number {
    return this.groupedTransferItems
    .slice(0, groupIndex)
    .reduce((acc, group) => acc + group.items.length, 0);
  }

  public exportToPdf(event: Event, transferId: string): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.downloading) {
      return;
    }

    this.downloading = true;
    this.reportStore
    .downloadDeliveryNote(transferId)
    .pipe(
      finalize(() => {
        this.downloading = false;
        this.cdr.markForCheck();
      }),
    )
    .subscribe((res: HttpResponse<Blob>) => FileUtils.downloadFile(res, true));
  }

  private setPanelClass(): void {
    this.panelClass = this.transfer.state === TransferRequestState.OPEN ? 'pending' : 'archived';
  }

  private setObjectTypes(): void {
    switch (this.transfer.transferType) {
      case TransferType.STOCK_TO_STOCK:
        this.sourceType = LocationType.STOCK;
        this.targetType = LocationType.STOCK;
        break;
      case TransferType.STOCK_TO_PROJECT:
        this.sourceType = LocationType.STOCK;
        this.targetType = LocationType.PROJECT;
        break;
      case TransferType.PROJECT_TO_PROJECT:
        this.sourceType = LocationType.PROJECT;
        this.targetType = LocationType.PROJECT;
        break;
      case TransferType.PROJECT_TO_STOCK:
        this.sourceType = LocationType.PROJECT;
        this.targetType = LocationType.STOCK;
        break;
      case TransferType.PROJECT_TO_CONSUMED:
        this.sourceType = LocationType.PROJECT;
        this.targetType = LocationType.CONSUMED;
        break;
    }
  }

  private setIconsAndLabels(): void {
    switch (this.sourceType) {
      case LocationType.STOCK:
        this.sourceIcon = this.stockIcon;
        this.sourceLabel = `${this.translate('general.from')} ${this.translate('general.stock.s')}`;
        break;
      case LocationType.PROJECT:
        this.sourceIcon = this.projectIcon;
        this.sourceLabel = `${this.translate('general.from')} ${this.translate('general.project.s')}`;
        break;
      default:
        this.sourceIcon = this.defaultIcon;
        this.sourceLabel = this.translate('modules.disposition.projectTransferHistory.unknownTransferType');
    }

    switch (this.targetType) {
      case LocationType.STOCK:
        this.targetIcon = this.stockIcon;
        this.targetLabel = `${this.translate('general.toLocation')} ${this.translate('general.stock.s')}`;
        break;
      case LocationType.PROJECT:
        this.targetIcon = this.projectIcon;
        this.targetLabel = `${this.translate('general.toLocation')} ${this.translate('general.project.s')}`;
        break;
      case LocationType.CONSUMED:
        this.targetIcon = this.consumedIcon;
        this.targetLabel = this.translate('modules.disposition.projectTransferHistory.consumed');
        break;
      default:
        this.targetIcon = this.defaultIcon;
        this.targetLabel = this.translate('modules.disposition.projectTransferHistory.unknownTransferType');
    }
  }

  private setData(): void {
    switch (this.sourceType) {
      case LocationType.STOCK:
        this.sourceName = this.transfer.transferItems[0].transferSourceName;
        this.sourceNumber = null;
        this.sourceCostCenter = this.transfer.transferItems[0].transferSourceCostCenter;
        this.isSourceProjectNumberDisplay = false;
        break;
      case LocationType.PROJECT:
        this.sourceName = this.transfer.transferItems[0].transferSourceName;
        this.sourceNumber = this.transfer.transferItems[0].transferSourceNumber;
        this.sourceCostCenter = this.transfer.transferItems[0].transferSourceCostCenter;
        this.isSourceProjectNumberDisplay = true;
        break;
      default:
        this.sourceName = null;
        this.sourceNumber = null;
        this.sourceCostCenter = null;
        this.isSourceProjectNumberDisplay = false;
    }

    switch (this.targetType) {
      case LocationType.STOCK:
        this.targetName = this.transfer.targetStockName;
        this.targetNumber = null;
        this.targetCostCenter = this.transfer.targetStockCostCenter;
        this.isTargetProjectNumberDisplay = false;
        break;
      case LocationType.PROJECT:
        this.targetName = this.transfer.targetProjectName;
        this.targetNumber = this.transfer.targetProjectNumber;
        this.targetCostCenter = this.transfer.targetProjectCostCenter;
        this.isTargetProjectNumberDisplay = true;
        break;
      case LocationType.CONSUMED:
      default:
        this.targetName = null;
        this.targetNumber = null;
        this.targetCostCenter = null;
        this.isTargetProjectNumberDisplay = false;
    }
  }

  private disableTransferRequestButtons() {
    this.transferRequestIsProcessing = true;
    setTimeout(() => {
      this.transferRequestIsProcessing = false;
    }, environment.DELAY_SHORT);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private groupTransferItemsAccordingToSource(): void {
    switch (this.sourceType) {
      case LocationType.STOCK:
        let sourceStockIds = [...new Set(this.transfer.transferItems.map(transferItem => transferItem.sourceStockId))];
        this.groupedTransferItems = sourceStockIds.map(sourceStockId => {
          return {
            sourceId: sourceStockId,
            sourceLocationIsAccessibleForUser:
            this.transfer.transferItems.find(transferItem => transferItem.sourceStockId === sourceStockId)
              .sourceLocationIsAccessibleForUser,
            sourceCostCenter: this.transfer.transferItems
            .find(transferItem => transferItem.sourceStockId === sourceStockId).sourceStockCostCenter,
            sourceNumber: null,
            sourceName: this.transfer.transferItems
            .find(transferItem => transferItem.sourceStockId === sourceStockId).sourceStockName,
            items: this.transfer.transferItems.filter(transferItem => transferItem.sourceStockId === sourceStockId),
            toolTip: this.translate('general.labels.jumpTo', {value: this.translate('general.stock.s')}),
            objects: 'stocks',
          };
        });
        break;
      case LocationType.PROJECT:
        let sourceProjectIds = [...new Set(this.transfer.transferItems.map(transferItem => transferItem.transferSourceId))];
        this.groupedTransferItems = sourceProjectIds.map(sourceProjectId => {
          return {
            sourceId: sourceProjectId,
            sourceLocationIsAccessibleForUser:
            this.transfer.transferItems.find(transferItem => transferItem.transferSourceId === sourceProjectId)
              .sourceLocationIsAccessibleForUser,
            sourceCostCenter: this.transfer.transferItems
            .find(transferItem => transferItem.transferSourceId === sourceProjectId).transferSourceCostCenter,
            sourceNumber: this.transfer.transferItems
            .find(transferItem => transferItem.transferSourceId === sourceProjectId).transferSourceNumber,
            sourceName: this.transfer.transferItems
            .find(transferItem => transferItem.transferSourceId === sourceProjectId).transferSourceName,
            items: this.transfer.transferItems.filter(transferItem => transferItem.transferSourceId === sourceProjectId),
            toolTip: this.translate('general.labels.jumpTo', {value: this.translate('general.project.s')}),
            objects: 'projects',
          };
        });
        break;
      default:
        this.groupedTransferItems =
          [{
            sourceId: '',
            sourceLocationIsAccessibleForUser: false,
            sourceCostCenter: '',
            sourceNumber: '',
            sourceName: '',
            items: this.transfer.transferItems,
            toolTip: '',
          }];
    }
  }

  private setInitOptions(): void {
    this.isDeclined = this.transfer.state === TransferRequestState.DECLINED;
    this.isAcknowledged = this.transfer.state === TransferRequestState.ACCEPTED;
    this.isPending = this.transfer.state === TransferRequestState.OPEN;
    this.setPanelClass();
    this.setObjectTypes();
    this.groupTransferItemsAccordingToSource();
    this.setIconsAndLabels();
    this.setData();
  }
}
