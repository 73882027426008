import { Injectable } from '@angular/core';
import { AttachedImage } from '../contract/attached-image.interface';
import { AttachedDocument } from '../contract/attached-document.interface';
import { FileUtils } from '../fileUtils';
import { UploadService } from './upload-service/upload-service';

interface UploadQueue {
  directUpload: boolean;
  referencedEntityId?: string;
  entries: (AttachedImage | AttachedDocument)[];
}

@Injectable()
export class DeferredUploadService {
  private _uploadQueue: UploadQueue[] = [];
  private running = false;

  constructor(private uploadService: UploadService) {
  }

  public registerUpload(): number {
    return this._uploadQueue.push({directUpload: false, entries: []}) - 1;
  }

  public pushEntry(entryNumber: number, attachedImage: AttachedImage | AttachedDocument): void {
    this._uploadQueue[entryNumber].entries.push(attachedImage);
    this.uploadAll();
  }

  public updateQueueEntry(entryNumber: number, updatedUploadQueue: Partial<UploadQueue>): void {
    const uploadQueue: UploadQueue = this._uploadQueue[entryNumber];
    if (uploadQueue) {
      uploadQueue.referencedEntityId = updatedUploadQueue.referencedEntityId;
      uploadQueue.directUpload = updatedUploadQueue.directUpload;
    }
  }

  public uploadAll(): void {
    if (this.running) {
      return;
    }
    this.uploadItem();
  }

  public removeEntry(entryNumber: number, key: string): void {
    const uploadQueue = this._uploadQueue[entryNumber];
    if (uploadQueue) {
      uploadQueue.entries = uploadQueue.entries.filter((entry) => {
        if (FileUtils.isAttachedImage(entry)) {
          return entry.imageKey !== key;
        }
        return entry.documentKey !== key;
      });
    }
  }

  private getFirstUploadQueue(): UploadQueue | undefined {
    let uploadQueue: UploadQueue;

    for (let i = 0; i < this._uploadQueue.length; i++) {
      const queue: UploadQueue = this._uploadQueue[i];
      if (queue && queue.directUpload && queue.entries.length > 0) {
        uploadQueue = queue;
        break;
      }
    }
    return uploadQueue;
  }

  private uploadItem(): void {
    const uploadQueue: UploadQueue = this.getFirstUploadQueue();

    if (uploadQueue) {
      const imageOrDocument: AttachedImage | AttachedDocument = uploadQueue.entries.shift();

      if (FileUtils.isAttachedImage(imageOrDocument)) {
        this.uploadImage(imageOrDocument, uploadQueue.referencedEntityId);
      } else {
        this.uploadDocument(imageOrDocument, uploadQueue.referencedEntityId);
      }
    } else {
      this.running = false;
    }
  }

  private uploadDocument(document: AttachedDocument, referencedEntityId: string): void {
    this.uploadService.addDocument(document, referencedEntityId).toPromise()
    .then(() => {
      this.uploadItem();
    })
    .catch(e => {
      console.error('Deferred-Upload-Service - Document: error uploading', e);
      this.uploadItem();
    });
  }

  private uploadImage(image: AttachedImage, referencedEntityId: string): void {

    this.uploadService.addImage(image, referencedEntityId).toPromise()
    .then(() => {
      this.uploadItem();
    })
    .catch(e => {
      console.error('Deferred-Upload-Service - Image: error uploading', e);
      this.uploadItem();
    });
  }
}
