import { MatTable } from '@angular/material/table';

export class MatTableController {
  private get tableElement(): HTMLElement {
    return this.matTable['_elementRef'].nativeElement;
  }

  constructor(private matTable: MatTable<any>) {}

  public fixWidth(): void {
    const actualWidth = this.tableElement.scrollWidth;
    this.tableElement.style.minWidth = `${actualWidth}px`;
  }

  public unfixWidth(): void {
    this.tableElement.style.minWidth = 'unset';
  }
}
