<div class="delete-equipment">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{'modules.equipment.deleteEquipm.title'|translate}}</h1>
  <div mat-dialog-content>{{data.confirmationMessage}}</div>
  <div mat-dialog-actions>
    <button mat-button color="primary" mat-dialog-close="no">{{'general.buttons.no'|translate}}</button>
    <button mat-button color="primary" mat-dialog-close="yes">{{'general.buttons.yes'|translate}}</button>
  </div>
</div>
