import { OspDateTime } from 'app/modules/equipment/contract/osp-date-time';

export class TransportAssignmentExecutionStartCommand {
  constructor(
    public transportId: string,
    public assignmentId: string,
    public executionStartDate: OspDateTime,
  ) {
  }
}
