import { LanguageService } from 'app/shared/services/language.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { TransferStock } from '../bulk-item-transfer-stock-select/transfer-stock';
import { ActivatedRoute, Router } from '@angular/router';
import { TransferItem } from '../../../../shared/transfer/model/transfer-item';
import { filter, map, startWith, debounceTime } from 'rxjs/operators';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { BulkItemTransferStockSelectComponent } from '../bulk-item-transfer-stock-select/bulk-item-transfer-stock-select.component';
import { StockToStockTransferItem } from '../../../../contract/stock-to-stock-transfer-item.interface';
import { StockStore } from '../../../../stocks/shared/stock.store';
import { ViewStock } from '../../../../contract/view-stock';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransferStore } from '../../shared/transfer.store';
import { CreateStockToStockTransferCartCommand } from '../../../../contract/create-stock-to-stock-transfer-cart-command';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocationType } from '../../../../../../shared/enums/location-type.enum';
import { TransferItemTransferDateRequest } from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-item-transfer-date-request';
import { TransferItemsSearchMatcher } from 'app/modules/disposition/shared/transfer/utils/transfer-items-search-matcher.class';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-stock-to-stock-transfer-cart',
  templateUrl: './bulk-item-stock-to-stock-transfer-cart.component.html',
  styleUrls: ['./bulk-item-stock-to-stock-transfer-cart.component.scss']
})
export class BulkItemStockToStockTransferCartComponent implements OnInit, OnDestroy {

  @ViewChild(BulkItemTransferStockSelectComponent, { static: true }) stockSelect: BulkItemTransferStockSelectComponent;

  public readonly faExchange: IconDefinition = faExchange;
  public sourceStock: ViewStock;
  public targetStock: TransferStock;
  public transferItems: Observable<TransferItem[]>;
  public isLoadingStockAmounts = false;

  private isLoadingStockAmountsSubscription: Subscription;
  private filterControl: UntypedFormControl;
  private stockToStockTransferItems: StockToStockTransferItem[] = [];
  private summaryTransferItems: TransferItem[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private stockStore: StockStore,
              private transferStore: TransferStore,
              private snackBar: MatSnackBar,
              private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.filterControl = new UntypedFormControl();
    if (this.stockStore.noStockSelected()) {
      this.stockStore.selectStock(this.route.snapshot.params['sourceId']);
    }

    this.isLoadingStockAmountsSubscription = this.transferStore.loadingStockAmounts.subscribe((loading: boolean) => {
      this.isLoadingStockAmounts = loading;
    });

    this.stockStore.selectedStock
      .pipe(
        filter((stock: ViewStock) => !!stock),
        untilDestroyed(this))
      .subscribe((stock: ViewStock) => {
      this.sourceStock = stock;
      this.transferStore.getStockAmounts(stock.stockId);
    });

    this.transferItems =
      combineLatest([
        this.transferStore.transferItems,
        this.filterControl.valueChanges.pipe(debounceTime(500), startWith(''))])
      .pipe(
        map(([items, filterTerm]) => ([items, filterTerm, this.getSearchMatchTransferItemIds(items, filterTerm)])),
        map(([items, filterTerm, searchMatchItems]: [TransferItem[], string, Set<string>]) =>
          items.map((item: TransferItem) => {
            item.hiddenOption = !searchMatchItems.has(item.id);
            item.isActiveContainer = (filterTerm && searchMatchItems.has(item.id)) || item.isActiveContainer;
            return item;
          })
        )
      );

    this.transferStore.transferFailed.pipe(untilDestroyed(this)).subscribe(() => {
      this.snackBar.open(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'),
        this.translate('general.buttons.okay'), {
            duration: 3000
          });
    });

    this.transferStore.transferSuccess.pipe(untilDestroyed(this)).subscribe(() => {
        this.transferStore.summaryDialog(
          Array.of(this.transferStore.transfer),
          this.summaryTransferItems.filter(item => item.transferAmount > 0),
          LocationType.STOCK,
          this.targetStock,
        )
        .afterClosed()
        .pipe(filter(result => result !== 'cancel'))
        .subscribe(() => {
            this.stockSelect.reset();
          },
        );
    });

    this.transferItems.pipe(
      untilDestroyed(this))
    .subscribe(items => {
      this.summaryTransferItems = items;
      this.stockToStockTransferItems = items.map(item => StockToStockTransferItem.fromTransferItem(item));
    });
  }

  public ngOnDestroy(): void {
      this.isLoadingStockAmountsSubscription.unsubscribe();
  }

  public updateAmount(item: TransferItem): void {
    this.transferStore.updateTransferAmount(item.id, item.transferAmount);
    this.stockToStockTransferItems
      .filter(transferItem => item.matchesStockAndBulkItem(transferItem.sourceStockId, transferItem.transferItemId))
      .forEach(transferItem => transferItem.amount = item.transferAmount);
    this.summaryTransferItems
    .filter(transferItem => item.matchesStockAndBulkItem(transferItem.sourceStockId, transferItem.id))
    .forEach(transferItem => transferItem.transferAmount = item.transferAmount);
  }

  public updateFilterControl(searchTerm: string): void {
    this.filterControl.patchValue(searchTerm.trim().toLowerCase());
  }

  public confirmCart(): void {
    this.transferStore.confirmationDialog('' +
      this.translate('modules.disposition.bulkItemTransfer.moveInventory'),
      this.translate('general.buttons.cancel'),
      this.translate('modules.disposition.confirmation.title.confirm'),
      this.translate('modules.disposition.confirmation.message.transferToStock',
        { value: this.targetStock.stockName }),
      LocationType.STOCK,
      this.targetStock.stockId,
      this.calculateTransferItems())
      .afterClosed()
      .pipe(filter(result => result !== 'cancel'))
      .subscribe({next: res => {
          this.transferStore.waitingDialogRef = this.transferStore.waitingDialog();
          this.createCart(res);
        }
      });
  }

  public closeCart(): void {
    this.router.navigate([`sites/stocks/list/${this.sourceStock.stockId}/inventory`]);
  }

  public selectStock(stock: TransferStock): void {
    this.targetStock = stock;
  }

  private getSearchMatchTransferItemIds(items: TransferItem[], filterTerm: string): Set<string> {
    if (!filterTerm) {
      return new Set((items || []).map(({ id }) => id));
    }

    const searchMatcher = new TransferItemsSearchMatcher(items);
    return searchMatcher.getMatchItemIds(filterTerm);
  }

  private createCart(form: UntypedFormGroup): void {
    const cmd: CreateStockToStockTransferCartCommand = new CreateStockToStockTransferCartCommand(
      this.targetStock.stockId,
      this.stockToStockTransferItems.filter(item => item.amount > 0),
      form.get('employeeId').value ? form.get('employeeId').value : null,
      form.get('externalEmployee').value ? form.get('externalEmployee').value : null,
      form.get('comment').value ? form.get('comment').value : null,
      form.get('historicTransferDateTime').value ? form.get('historicTransferDateTime').value : null);

    this.transferStore.transferStockToStock(this.sourceStock.stockId, cmd);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private calculateTransferItems(): TransferItemTransferDateRequest[] {
    return this.stockToStockTransferItems
      .filter(item => item.amount > 0)
      .map(item => TransferItemTransferDateRequest.fromStockToStockTransferItem(item));
  }
}
