import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSlice'
})
export class CustomSlicePipe implements PipeTransform {

  transform(value: string, start: number, count: number, suffix = ''): string {
    if (value.length > (start + count)) {
      return value.slice(start, count) + suffix;
    }
    return value;
  }

}
