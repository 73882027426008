import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomDeviceDetectorService } from '../../shared/services/custom-device-detector.service';

@Injectable()
export class CanActivateChildMobile implements CanActivateChild {
  constructor(private deviceService: CustomDeviceDetectorService,
              private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = state.url;
    let navigateUrl = url.replace('/mobile/', '').replace('view', 'list');

    if (this.deviceService.isMobile()) {
      return true;
    }

    this.router.navigate([navigateUrl]);
    return false;
  }
}
