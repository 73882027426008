import { MaintenanceTaskCompletionInfo } from '../../../../../../shared/contract/task-completion/maintenance-task-completion-info';
import { OspDateTime } from '../../../../../equipment/contract/osp-date-time';
import { MaintenanceResult } from '../../../../../../shared/enums/maintenance-result';

export class CompleteMaintenanceTaskCommand {
  constructor(
      public maintenanceTaskId: string,
      public equipmentId: string,
      public maintenanceTaskCompletionInfo: MaintenanceTaskCompletionInfo,
      public completedAtDate: OspDateTime,
      public completedAtMileage: number,
      public completedAtOperatingHours: number,
      public maintenanceResult: MaintenanceResult,
      public nextDueDate: OspDateTime) {
  }
}
