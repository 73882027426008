import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';
import {
  AdditionalFieldType,
  AdditionalFieldValue,
} from 'app/modules/osp-ui/rich-components/bh-additional-field/additional-field';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'additionalFieldValue'
})
export class AdditionalFieldValuePipe implements PipeTransform {
  private translationBoolean = {
    YES: this.languageService.getInstant('general.yes'),
    NO: this.languageService.getInstant('general.no'),
  }

  constructor(
    private languageService: LanguageService,
    private translatableStringResolver: TranslatableStringPipe,
  ) {}

  public transform(field: AdditionalFieldValue): string {
    if (field?.additionalFieldType === AdditionalFieldType.BOOLEAN) {
      return field.value ? this.translationBoolean.YES : this.translationBoolean.NO;
    } else if (field?.additionalFieldType === AdditionalFieldType.SELECT) {
      const translatedNames = field.additionalFieldSelectOptions?.find(item => item?.optionKey === field.value)?.optionName;
      return this.translatableStringResolver.transform(translatedNames);
    }
    return field.value;
  }

}
