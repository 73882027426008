import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { ProjectFilterStatusView } from '../filter-view/project-filter-collection-view.interface';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import { FilterValue } from 'app/shared/contract/filter/filter-value';
import { PipeTransform } from '@angular/core';

export class ProjectStatusFilterViewConverter extends BaseFilterViewConverter<ProjectFilterStatusView> {
  constructor(
    originalFilters: ProjectFilterStatusView[],
    selectedValues: FilterParams,
    private displayNameResolver?: PipeTransform
  ) {
    super(FilterTypes.PROJECT_STATE, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: ProjectFilterStatusView): FilterValue {
    return {
      displayName: this.displayNameResolver ? this.displayNameResolver.transform(filterItem.status) : filterItem.status,
      storeName: filterItem.status,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.status),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
