import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UpdateEquipmentChangeTypeCommand} from '../../contract/update-equipment-change-type-command';
import {EquipmentsDataSource} from '../../shared/equipments.datasource';
import { EquipmentSubTypeFilterView, EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import {logErrors} from '../../../../shared/utils';
import * as _ from 'lodash';
import {EquipmentTypesService} from '../../shared/equipmenttypes.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-type-select',
  templateUrl: 'equipment-type-select.component.html',
  styleUrls: ['equipment-type-select.component.scss']
})
export class EquipmentTypeSelectComponent implements OnInit {

  public selectedTypeId: string;
  public equipmentTypeSearchControl: UntypedFormControl;
  public filteredEquipmentTypes: EquipmentTypeFilterView[] = [];
  private allEquipmentTypes: EquipmentTypeFilterView[] = [];

  constructor(private router: Router,
              private equipmentStore: EquipmentsDataSource,
              private equipmentTypesService: EquipmentTypesService,
              private dialogRef: MatDialogRef<EquipmentTypeSelectComponent>,
              private translatableStringResolver: TranslatableStringPipe,
              @Inject(MAT_DIALOG_DATA) private data: { equipmentId: string, currentTypeId: string }) {
  }

  public ngOnInit(): void {
    this.equipmentTypeSearchControl = new UntypedFormControl('');
    this.getEquipmentTypes();
    this.subscribeToSearchValueChanges();
  }

  private getEquipmentTypes(): void {
    this.equipmentTypesService.getAllEquipmentTypesGroupedByCategory1()
      .pipe(untilDestroyed(this))
      .subscribe((equipmentTypes: EquipmentTypeFilterView[]) => {
        this.allEquipmentTypes = equipmentTypes;
        this.filterTypes('');
      });
  }

  private subscribeToSearchValueChanges(): void {
    this.equipmentTypeSearchControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((searchTerm: string) => {
        this.selectedTypeId = null;
        this.filterTypes(searchTerm);
      });
  }

  private filterTypes(searchTerm: string): void {
    this.filteredEquipmentTypes = _.cloneDeep(this.allEquipmentTypes).map(typeGroup => {
      typeGroup.subTypes = typeGroup.subTypes.filter(subType => this.isMatchingSubtype(subType, searchTerm));
      return typeGroup;
    }).filter(typeGroup => typeGroup.subTypes.length > 0);
  }

  private isMatchingSubtype(subType: EquipmentSubTypeFilterView, searchTerm: string): boolean {
    if (this.isEditMode && subType.equipmentTypeId === this.data.currentTypeId) {
      return false;
    }
    return this.translatableStringResolver
      .transform(subType.category2Name)
      .toLowerCase()
      .indexOf(searchTerm.toLowerCase()) !== -1;
  }

  public save(): void {
    if (this.selectedTypeId) {
      if (this.isEditMode) {
        const cmd = new UpdateEquipmentChangeTypeCommand(this.data.equipmentId, this.selectedTypeId);
        this.equipmentStore.changeEquipmentType(cmd).subscribe(logErrors('update equipment type'));
      } else {
        this.router.navigate(['/equipments/add/' + this.selectedTypeId]);
      }
      this.dialogRef.close();
    }
  }

  public get isEditMode(): boolean {
    return this.data && !!this.data.equipmentId;
  }

  public selectType(equipmentTypeId: string): void {
    this.selectedTypeId = equipmentTypeId;
  }
}
