import { Component, Input } from '@angular/core';

@Component({
  selector: 'bh-text-line',
  templateUrl: './text-line.component.html',
  styleUrls: ['./text-line.component.scss']
})
export class TextLineComponent {

  @Input() text: string
  @Input() highlightText;

  constructor() { }

}
