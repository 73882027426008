import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { OptionResolver } from '../card-select/option.resolver';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { PartnerTheme } from 'app/modules/organisation/contract/partner-theme.enum';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CardSelectComponent } from '../card-select/card-select.component';

@Component({
  selector: 'bh-card-select-alarm-types',
  templateUrl: './card-select-alarm-types.component.html',
  styleUrls: ['./card-select-alarm-types.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardSelectAlarmTypesComponent),
      multi: true
    },
    {provide: MatFormFieldControl, useExisting: CardSelectAlarmTypesComponent}
  ]
})
export class CardSelectAlarmTypesComponent extends CardSelectComponent {

  @Input() options: string[];
  @Input() optionResolver: OptionResolver;
  @Input() _selectedOption: string;
  @Input() readOnly: boolean;

  @Output() optionSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

  propagateChange = (_: any) => {};
  constructor(private userConfigurationService: UserConfigurationService) {
    super();
  }

  public isWNTheme() {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.WACKER_NEUSON;
  }
}
