import { Authorities } from 'app/shared/enums/authorities.enum';
import { ModuleWithProviders } from '@angular/core';
import { TelematicUnitListComponent } from './../telematic-unit/telematic-unit-list/telematic-unit-list.component';
import { ContentComponent } from './../../../shared/components/content-component/content.component';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateChildAuthority } from 'app/core/guards/can-activate-child-authority';
import { CanActivateChildMobileWebApp } from '../../../core/guards/can-activate-child-mobile-web-app.guard';

const telematicUnitRoutes: Routes = [{
  path: 'telematic-unit',
  component: ContentComponent,
  canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'list'
    },
    {
      path: 'list',
      component: TelematicUnitListComponent,
      data: {
        authorities: [Authorities.SUPERADMIN_VIEW]
      }
    }
  ]
}];

export const telematicUnitRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(telematicUnitRoutes);
