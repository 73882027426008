import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faBadgeCheck, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { ViewStockEmployeeAssignment } from '../../contract/view-stock-employee-assignment.interface';
import { StockStore } from '../../stocks/shared/stock.store';

@Component({
  selector: 'bh-stock-employee-assignment-view-dialog',
  templateUrl: './stock-employee-assignment-view-dialog.component.html',
  styleUrls: ['./stock-employee-assignment-view-dialog.component.scss']
})
export class StockEmployeeAssignmentViewDialogComponent implements OnInit {

  employeeAssignment: ViewStockEmployeeAssignment;
  icon: IconDefinition;
  public hasStockTransferRequestWorkflow: boolean;

  public readonly faBadgeCheck: IconDefinition = faBadgeCheck;

  constructor(public dialogRef: MatDialogRef<StockEmployeeAssignmentViewDialogComponent>,
    public stockStore: StockStore) { }

  ngOnInit(): void {
    this.subscribeToStockTransferRequestWorkflow();
  }

  private subscribeToStockTransferRequestWorkflow(): void {
    this.stockStore.hasStockTransferRequestWorkflow.subscribe((hasStockTransferRequestWorkflow: boolean) => {
      this.hasStockTransferRequestWorkflow = hasStockTransferRequestWorkflow;
    });
    this.stockStore.updateStockTransferRequestsWorkflow();
  }

}
