import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddEquipmentLabelCommand } from '../../contract/add-equipment-label-command';
import { EquipmentsDataSource } from '../equipments.datasource';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { KeycloakService } from '../../../../core/keycloak';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { map } from 'rxjs/operators';
import { CustomerLabel } from '../../../../shared/contract/customer-label.interface';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-add-label-dialog',
  templateUrl: './equipment-add-label-dialog.component.html',
  styleUrls: ['./equipment-add-label-dialog.component.scss']
})
export class EquipmentAddLabelDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollRepo', { static: true }) scrollRepo: ElementRef<HTMLDivElement>;

  public equipmentList: Set<string>;
  public dialogRef: MatDialogRef<EquipmentAddLabelDialogComponent>; // self reference
  public customerLabels: Observable<string[]>;
  private _equipmentLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public equipmentLabels: Observable<string[]> = this._equipmentLabels.asObservable();
  public hasAuthority: boolean;

  constructor(private equipmentStore: EquipmentsDataSource,
              protected authService: KeycloakService) {
  }

  public ngOnInit(): void {
    this.customerLabels = this.equipmentStore.labels
      .pipe(map((labels: CustomerLabel[]) => labels.map(label => label.name)));
    this.hasAuthority = this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_LABELS);
  }

  public ngOnDestroy(): void {
  }

  public save() {
    if (this.equipmentList) {
      let commandList: AddEquipmentLabelCommand[] = [];
      this.equipmentList.forEach( equipmentId => {
          this.equipmentLabels.subscribe((labels) => {
            commandList.push(...labels.map(label => {
                  let command: AddEquipmentLabelCommand = new AddEquipmentLabelCommand();
                  command.equipmentId = equipmentId;
                  command.label = label;
                  return command;
            }));
          });
      });
      this.equipmentStore.addLabelsToMultipleEquipments(commandList);
      this.dialogRef.close();
    }
  }

  public addLabel(label: string): void {
    const newLabels = [...this._equipmentLabels.value];
    newLabels.push(label);
    this._equipmentLabels.next(newLabels);
    this.filterCustomerLabels(this.customerLabels);
    setTimeout(() => {
      this.scrollRepo.nativeElement.scrollTop = this.scrollRepo.nativeElement.scrollHeight;
    });
  }

  public deleteLabel(label: string): void {
    const newLabels = [...this._equipmentLabels.value];
    newLabels.splice(newLabels.indexOf(label), 1);
    this._equipmentLabels.next(newLabels);
    this.filterCustomerLabels(this.equipmentStore.filteredCustomerLabels);
  }

  public disable(): boolean {
   return this._equipmentLabels.value.length === 0;
  }

  private filterCustomerLabels(_customerLabels: Observable<string[]>): void {
    let filteredCustomerLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);

    combineLatest(this._equipmentLabels, _customerLabels)
    .subscribe(([equipmentLabels, customerLabels]) => {
      const currentCustomerLabels = customerLabels.map(label => label)
      .filter(label => !equipmentLabels.includes(label));
      filteredCustomerLabels.next(currentCustomerLabels);
    });

    this.customerLabels = filteredCustomerLabels;
  }
}
