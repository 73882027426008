<div fxFlexFill class="list-view" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-search-bar" fxFlex="60px" fxLayout="row"
       fxLayoutAlign="space-between center">
    <div fxFlex="1 1 100%" class="search-row" fxLayoutAlign="space-between center">
      <button *ngIf="showRouteButton()" mat-button class="list-view-search-bar__left-icon"
       [matTooltip]='routeButton.tooltip' fxFlex="50px" (click)="navigate()">
        <i class="material-icons mat-icon">{{routeButton.iconName}}</i>
      </button>
      <div [ngClass]="{'no-route-button': !showRouteButton()}" class="list-view-search-bar__input-container" fxFlex>
        <form class="list-view-search-bar__input" fxLayout="row" [formGroup]="searchForm"
              novalidate>
          <bh-search-suggestion fxFlex [searchControl]="getForm()" suggestionField="bulkItemTransferListSuggestions">
          </bh-search-suggestion>
          <button mat-button
            (click)="search()"
            [matTooltip]="'general.search'|translate"
            fxFlex="40px">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-button
            (click)="reset()"
            [matTooltip]="'general.clearSearch'|translate"
            fxFlex="40px">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </div>
    </div>
    <div fxLayout="row" *ngIf="showAddButton()" class="create-row" fxLayoutAlign="end center">
      <button mat-raised-button  class="list-view-search-bar__create" color="primary"
              (click)="openAddMenu()">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    </div>
  </div>

  <div fxFlex fxLayout="row" class="content">
    <bh-vertical-tab-select *ngIf="options.length"
                            [optionResolver]="optionResolver"
                            [options]="options">
    </bh-vertical-tab-select>

    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</div>
