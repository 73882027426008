import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatArrays'
})
export class ConcatArraysPipe implements PipeTransform {

  public transform(...arrays: Array<unknown>[]): Array<unknown> {
    return arrays
      .filter(array => Array.isArray(array))
      .reduce((acc, array) => ([ ...acc, ...array ]) , []);
  }

}
