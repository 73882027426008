import { AttachedDocument } from '../attached-document.interface';
import { MaintenanceCategory } from '../../enums/maintenance-category.enum';
import { MaintenanceFieldValue } from './maintenance-field-value';

export class MaintenanceTaskCompletionInfo {
  constructor(
      public maintenanceFieldValues: MaintenanceFieldValue[],
      public documents: AttachedDocument[],
      public ruleNameAtCompletion: string,
      public categoryAtCompletion: MaintenanceCategory,
      public ruleDescriptionAtCompletion: string,
      public typeNameAtCompletion: string,
      public typeDescriptionAtCompletion: string) {
  }
}
