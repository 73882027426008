import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateValidator } from '../../../custom-validators/date.validator';
import { LanguageService } from '../../../services/language.service';
import { now } from 'moment';

@Component({
  selector: 'bh-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, private languageService: LanguageService) {
  }

  @Input() formGroup: UntypedFormGroup;
  @Input() timeFormControlName: string;
  @Input() defaultDateTime: Date;
  @Input() datePlaceholder: string;
  @Input() timePlaceholder: string;
  @Input() required = true;

  private _parentDateTimeControl: AbstractControl;
  private _dateControl: AbstractControl = new UntypedFormControl();
  private _timeControl: AbstractControl = new UntypedFormControl();
  private _maxDateTime: Date;
  private _minDateTime: Date;

  @Input() private allowEquals: boolean;
  @Input()
  public set dateTimeControl(control: AbstractControl) {
    this._parentDateTimeControl = control;
  }

  @Input()
  public set disabled(status: boolean) {
    if (status) {
      this.dateControl.disable();
      this.timeControl.disable();
      this.timeControl.patchValue(null, {emitEvent: false, onlySelf: true});
      this.dateControl.setValue(null, {emitEvent: false, onlySelf: true});
      this.dateControl.updateValueAndValidity();
      this.timeControl.updateValueAndValidity();
    } else {
      this.dateControl.enable();
      this.timeControl.enable();
      this.dateControl.updateValueAndValidity();
      this.timeControl.updateValueAndValidity();
      this.ngOnInit();
    }
  }

  @Input() set minDateTime(minDateTime: Date) {
    this._minDateTime = minDateTime ? new Date(minDateTime) : null;
    this.addTimeValidator();
    this.timeControl.updateValueAndValidity();
  }

  @Input() set maxDateTime(maxDateTime: Date) {
    this._maxDateTime = maxDateTime ? new Date(maxDateTime) : null;
    this.addTimeValidator();
    this.timeControl.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.addTimeValidator();
    this.dateTimeControl.setValidators(this.dateTimeIsValidValidator(this.dateControl, this.timeControl));
    this.timeControl.markAllAsTouched();

    if (this.defaultDateTime) {
      this.dateTimeControl.setValue(new Date(this.defaultDateTime));
      this._dateControl.setValue(new Date(this.defaultDateTime));
      let hours = this.defaultDateTime.getHours();
      let minutes = this.defaultDateTime.getMinutes();
      this._timeControl.setValue(DateValidator.formatTime(hours, minutes));
    }

    this.dateControl.valueChanges.subscribe(value => {
      if (value) {
        let oldDate = new Date(this._parentDateTimeControl.value);
        let newDate = new Date(value);
        newDate.setHours(oldDate.getHours());
        newDate.setMinutes(oldDate.getMinutes());
        if (!this.timeControl.value) {
          this.timeControl.patchValue('00:00', {emitEvent: false, onlySelf: true});
        }
        this.timeControl.updateValueAndValidity();
        this.dateTimeControl.setValue(newDate);
      } else {
        this.timeControl.patchValue(null, {emitEvent: false, onlySelf: true});
        this.dateTimeControl.setValue(null);
        this.dateTimeControl.updateValueAndValidity();
      }
    });

    this.timeControl.valueChanges.subscribe(value => {
      if (value) {
        if (!this.required && !this.dateControl.value) {
          this.dateControl.patchValue(new Date(now()), {emitEvent: false, onlySelf: true});
          this.dateTimeControl.patchValue(new Date(now()), {emitEvent: false, onlySelf: true});
        }
        let oldDate = new Date(this.dateTimeControl.value);
        oldDate.setHours(value.split(':')[0]);
        oldDate.setMinutes(value.split(':')[1]);
        this.dateTimeControl.setValue(oldDate);
      } else {
        if (!this.required) {
          this.dateControl.patchValue(null, {emitEvent: false, onlySelf: true});
          this.dateTimeControl.setValue(null);
        }
        this.dateTimeControl.updateValueAndValidity();
      }
    });
  }

  public get dateTimeControl(): AbstractControl {
    return this._parentDateTimeControl;
  }

  public get dateControl(): AbstractControl {
    return this._dateControl;
  }

  public get timeControl(): AbstractControl {
    return this._timeControl;
  }

  public getMinDateTime(): Date {
    return this._minDateTime;
  }

  public getMaxDateTime(): Date {
    return this._maxDateTime;
  }

  private addTimeValidator(): void {
    this.timeControl.clearValidators();
    const validators = [];

    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.dateTimeControl && this.getMaxDateTime()) {
      validators.push(DateValidator.maxDateTime(
        this.dateControl,
        this.timeControl,
        this.getMaxDateTime(),
        this.languageService,
        this.allowEquals));
    }

    if (this.dateTimeControl && this.getMinDateTime()) {
      validators.push(DateValidator.minDateTime(
        this.dateControl,
        this.timeControl,
        this.getMinDateTime(),
        this.languageService,
        this.allowEquals));
    }

    this.timeControl.setValidators(validators);
  }

  public get errorMessageTime(): string {
    let errorMessage = ''

    if (this.timeControl.hasError('minTimeViolated')) {
      errorMessage = this.timeControl.getError('mineTimeViolated');
    }

    if (this.timeControl.hasError('maxTimeViolated')) {
      errorMessage = this.timeControl.getError('maxTimeViolated');
    }

    if (this.timeControl.hasError('required')) {
      errorMessage = this.translate('shared.validation.requiredField')
    }

    return errorMessage;
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private dateTimeIsValidValidator(dateControl: AbstractControl, timeControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return dateControl.valid && timeControl.valid ?
        null : {dateTimeInvalid: true};
    };
  }
}
