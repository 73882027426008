import { TransportAssignment } from '../../../contracts/transport/transport-assignment.interface';
import { VehicleSearch } from 'app/modules/transportation/contracts/transport/vehicle-search.interface';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportPriorityPipe } from '../../pipes/transport-priority.pipe';
import { TransportStatePipe } from '../../pipes/transport-state.pipe';
import { DatesService } from 'app/shared/services/dates.service';
import { TransportTaskChecker } from '../../services/transport-task-checker.service';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';

interface SectionBuilderConfig {
  vehicle: VehicleSearch;
  widthPx: number;
  attrNameTransportType: string;
  attrNameEquipmentId: string;
  attrStatusName: string;
}

type StatusNameMap = {
  [key in EquipmentStatusCategory]: string;
}

@Injectable()
export class TransportTimelineSchedulerHtmlBuilderService {
  private readonly eventWidthStandard = 'dhx_custom_wight_standard';
  private readonly eventWidthSmall = 'dhx_custom_wight_small';
  private readonly eventWidthVerySmall = 'dhx_custom_wight_very_small';
  private readonly eventWidthExtremelySmall = 'dhx_custom_wight_extremely_small';
  private readonly statusNameMap: StatusNameMap = {
    [EquipmentStatusCategory.OPERATIONAL]: 'status-operational',
    [EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS]: 'status-operational-with-restrictions',
    [EquipmentStatusCategory.NOT_OPERATIONAL]: 'status-not-operational',
    [EquipmentStatusCategory.NOT_AVAILABLE]: 'status-not-available',
  };

  constructor(
    private transportPriorityPipe: TransportPriorityPipe,
    private transportStatePipe: TransportStatePipe,
    private datePipe: DatePipe,
    private languageService: LanguageService,
    private equipmentStatusNameResolver: EquipmentStatusNamePipe
  ) { }

  public buildSectionTemplate({
    vehicle,
    widthPx,
    attrNameTransportType,
    attrNameEquipmentId,
    attrStatusName
  }: SectionBuilderConfig): string {
    const statusName = this.equipmentStatusNameResolver.transform(vehicle.status);
    return `
      <div class="section" style="width:${widthPx}px"
        ${attrNameTransportType && `${attrNameTransportType}="${vehicle.transportTypes.join(', ')}"`}
        ${attrStatusName && `${attrStatusName}="${statusName}"`}>
        <span class="material-icons section__icon ${attrStatusName && this.resolveClassNameByStatus(vehicle.status?.category)}">
          local_shipping
        </span>
        <div class="section__vehicle">
          <div class="section__vehicle__name">
            <div class="section__vehicle__name__title section-text">
              ${vehicle.equipmentName ? vehicle.equipmentName : vehicle.equipmentModel}
            </div>
            ${attrNameEquipmentId
              ? `<span ${attrNameEquipmentId}="${vehicle.equipmentId}" class="material-icons icon-link enable-pointer-events">
                  open_in_new
                </span>`
              : ''}
          </div>
          <div class="section__vehicle__int-number section-text">${vehicle.equipmentCustomerSerialNumber}</div>
          <div class="section__vehicle__license section-text">${vehicle.equipmentLicensePlate}</div>
        </div>
      </div>`;
  }

  public buildEventTemplate(
    assignment: TransportAssignment,
    contentWidthPx: number,
    marginLeftPx = 0,
    marginRightPx = 0
  ): string {
    const { identifier, title, dueDate, state, startItemName, targetItemName, priority } = assignment;
    const dueDateTimestamp = dueDate && dueDate.timestamp;

    const dueDatePart = !dueDateTimestamp
      ? ''
      : `
      <div class="task-due-date hide-when-width-small${TransportTaskChecker.isDueDatePassed(assignment) ? ' due-date-time-passed' : ''}">
        <span class="material-icons task-due-date__icon">access_time</span>
        <span>${this.datePipe.transform(dueDateTimestamp, 'd MMM y', '', this.getCurrentLocale())}</span>
        ${!DatesService.isEndOfDaySameTimeZone(dueDateTimestamp)
          ? `<span class="task-due-date__time-part">
              ${this.datePipe.transform(dueDateTimestamp, 'shortTime', '', this.getCurrentLocale())}
            </span>`
          : ''}
      </div>`

    return `
      <div class="task task-state-${state || 'NONE'} ${this.getEventWidthMark(contentWidthPx)}"
           style="margin-left: ${marginLeftPx}px; margin-right: ${marginRightPx}px">

        <!-- FIRST LINE -->
        <div class="task__line gap-5 hide-when-width-extremely-small">
          <div class="task-number hide-when-width-very-small"># ${identifier}</div>
          <div class="task-title">${title}</div>
          ${dueDatePart}
        </div>

        <!-- SECOND LINE -->
        <div class="task__line gap-15 hide-when-width-extremely-small">
          <!-- state -->
          <div class="task-state hide-when-width-very-small">
            <div class="task-state__mark"></div>
            <div class="task-state__title">${(this.transportStatePipe.transform(state) || '')}</div>
          </div>

          <div class="task-asset hide-when-width-small">${startItemName || ''}</div>
          <div class="task-asset hide-when-width-small">${targetItemName || ''}</div>

          <div class="task-priority">
            <div class="task-priority__mark priority-${priority}">
              ${(this.transportPriorityPipe.transform(priority) || '').toUpperCase()}
            </div>
          </div>
        </div>

        <!-- HIDDEN LINE -->
        <div class="task__line show-when-width-extremely-small">
          <div class="task-title">${title}</div>
        </div>
      </div>`;
  }

  public buildTooltipContent(status: string, type: string): string {
    return [status, type]
      .filter(Boolean)
      .map(str => `<span>${str}</span>`)
      .join(`<br>`);
  }

  private getEventWidthMark(width: number): string {
    if (!width || width < 100) {
      return this.eventWidthExtremelySmall;
    } else if (width < 190) {
      return this.eventWidthVerySmall;
    } else if (width < 300) {
      return this.eventWidthSmall;
    } else if (width >= 300) {
      return this.eventWidthStandard;
    }
  }

  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private resolveClassNameByStatus(status: EquipmentStatusCategory): string {
    return this.statusNameMap[status] || this.statusNameMap.NOT_AVAILABLE;
  }
}
