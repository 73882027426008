import { OnInit, EventEmitter, Input, Output, OnDestroy, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewEmployeeProjectAssignment } from 'app/modules/disposition/contract/view-employee-project-assignment.interface';
import { EmployeeDispositionTeamRelation } from 'app/modules/disposition/contract/employee-disposition-relation';
import { LanguageService } from 'app/shared/services/language.service';

@UntilDestroy()
@Directive()
export class BaseEmployeeDispositionFormItem implements OnInit, OnDestroy {

  @Input() employeeDisposition: UntypedFormGroup;
  @Input() removable: boolean;

  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();
  @Output() onOverwriteChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  constructor(private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.employeeDisposition.get('overwrite').valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.onOverwriteChange.emit(this.employeeDisposition);
    });
  }

  public ngOnDestroy() {
  }

  public get employeeOrTeamName(): string {
    return this.employeeDisposition.get('employeeOrTeamName').value;
  }

  public get teamStatus(): string {
    return this.employeeDisposition.get('teamStatus').value;
  }

  public get conflicts(): ViewEmployeeProjectAssignment[] {
    return this.employeeDisposition.getError('conflicts') ?
        this.employeeDisposition.getError('conflicts') :
        [];
  }

  public get warnings(): ViewEmployeeProjectAssignment[] {
    return this.employeeDisposition.get('warnings').value ?
        this.employeeDisposition.get('warnings').value :
        [];
  }

  public isTeamMember(): boolean {
    return this.employeeDisposition.value['teamStatus'] === 'MEMBER';
  }

  public noErrorsOrWarnings(): boolean {
    return (!this.employeeDisposition.hasError('conflicts') && this.warnings.length === 0);
  }

  public getRemoveTooltip(): string {
    return this.teamStatus !== EmployeeDispositionTeamRelation.TEAM ?
     this.languageService.getInstant('modules.disposition.addEmployeeDisposition.notDisposed') :
     this.languageService.getInstant('modules.disposition.addEmployeeDisposition.teamNotDisposed');
  }

  public remove(): void {
    this.onRemove.emit();
  }
}
