import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssetListTypeResolver } from '../asset-list-component/asset-list-type-resolver';
import { ListType } from '../../enums/list-type.enum';
import { CardSelectComponent } from '../card-select/card-select.component';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { KeycloakService } from '../../../core/keycloak';
import { Authorities } from '../../enums/authorities.enum';
import { Modules } from '../../enums/modules.enum';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { LanguageService } from 'app/shared/services/language.service';

@UntilDestroy()
@Component({
  selector: 'bh-choose-add-process-dialog',
  templateUrl: 'choose-add-process-dialog.component.html',
  styleUrls: ['choose-add-process-dialog.component.scss']
})
export class ChooseAddProcessDialogComponent implements OnInit, OnDestroy {
  public readonly confirmTitle = `${this.languageService.getInstant('general.labels.add')}...`;
  public options = [
    ListType.EQUIPMENTS,
    ListType.BULK_ITEMS,
    ListType.STOCKS,
    ListType.PROJECTS,
    ListType.EMPLOYEES,
    ListType.CONTACTS,
    ListType.TRANSPORTATION_TASKS,
  ];

  public form: UntypedFormGroup;

  @ViewChild('select', { static: true }) select: CardSelectComponent;

  constructor(public resolver: AssetListTypeResolver,
              private router: Router,
              private dialogRef: MatDialogRef<ChooseAddProcessDialogComponent>,
              private authService: KeycloakService,
              private formBuilder: UntypedFormBuilder,
              private languageService: LanguageService) {
  }

  get process(): AbstractControl {
    return this.form.get('process');
  }

  public ngOnInit(): void {
    this.filterOptions();
    this.form = this.formBuilder.group({
      process: ['']
    });
    this.subscribeToClick();
  }

  public ngOnDestroy(): void {
  }

  private filterOptions(): void {
    if (!this.authService.hasAuthority(Authorities.BULK_ITEMS_MANAGE) ||
        !this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      this.options = this.options.filter(value => value !== ListType.BULK_ITEMS);
    }
    if (!this.authService.hasAuthority(Authorities.STOCK_MANAGE) ||
        !this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      this.options = this.options.filter(value => value !== ListType.STOCKS);
    }
    if (!this.authService.hasAuthority(Authorities.EQUIPMENT_CREATE)) {
      this.options = this.options.filter(value => value !== ListType.EQUIPMENTS);
    }
    if (!this.authService.hasAuthority(Authorities.PROJECT_CREATE)
        || !this.authService.hasModule(Modules.DISPOSITION)) {
      this.options = this.options.filter(value => value !== ListType.PROJECTS);
    }
    if (!this.authService.hasAuthority(Authorities.EMPLOYEE_MANAGE)) {
      this.options = this.options.filter(value => value !== ListType.EMPLOYEES);
    }
    if (!(this.authService.hasAuthority(Authorities.CONTACT_MANAGE)
        && this.authService.hasModule(Modules.CONTACT_MANAGEMENT))) {
      this.options = this.options.filter(value => value !== ListType.CONTACTS);
    }
    if (!(this.authService.hasAnyAuthority([Authorities.TRANSPORT_TASK_MANAGE, Authorities.TRANSPORT_TASK_DISPOSITION])
      && this.authService.hasModule(Modules.TRANSPORT_TASK_MANAGEMENT))) {
      this.options = this.options.filter(value => value !== ListType.TRANSPORTATION_TASKS);
    }
  }

  private subscribeToClick(): void {
    this.process.valueChanges.pipe(untilDestroyed(this))
      .subscribe(option => {
        this.dialogRef.close();
        switch (option) {
          case ListType.PROJECTS:
            this.router.navigate(['sites/projects/add']);
            break;
          case ListType.BULK_ITEMS:
            this.router.navigate(['assets/bulk-item/add']);
            break;
          case ListType.EQUIPMENTS:
            this.router.navigate(['assets/equipment/add']);
            break;
          case ListType.STOCKS:
            this.router.navigate(['sites/stocks/add']);
            break;
          case ListType.EMPLOYEES:
            this.router.navigate(['assets/employee/add']);
            break;
          case ListType.TRANSPORTATION_TASKS:
            this.router.navigate(['transportation/add']);
            break;
          case ListType.CONTACTS: {
            this.router.navigate(['assets/contact/add']);
            break;
          }
        }
      });
  }
}
