import { LanguageService } from './../../../../../../../shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { filter } from 'rxjs';
import { ViewEquipmentProjectAssignment } from '../../../../../contract/view-equipment-project-assignment.interface';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentViewDispositionComponent extends GuardedNavigableInputComponent implements OnInit {

  equipment: ViewEquipment;
  assignments: ViewEquipmentProjectAssignment[];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public getAssignments(equipmentId: string): void {
    if (equipmentId) {
      this.equipmentStore.getAssignments(equipmentId);
    }
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private initListeners(): void {
    this.initListenerAssignments();
    this.initListenerCurrentEquipment();
  }

  private initListenerAssignments(): void {
    this.equipmentStore.assignments
      .pipe(
        filter(Boolean),
        untilDestroyed(this))
      .subscribe(res => this.assignments = res)
  }

  private initListenerCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
      .pipe(
        filter(Boolean),
        untilDestroyed(this))
      .subscribe(res => {
        this.equipment = res;
        this.getAssignments(this.equipment.equipmentId);
      });
  }
}
