import { Component, OnInit, OnDestroy } from '@angular/core';
import { ViewProject } from '../../../contract/view-project.interface';
import { DatesService } from '../../../../../shared/services/dates.service';
import * as moment from 'moment';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateRange } from '../../../../../shared/components/date-range-select-component/date-range';
import { ViewEmployeeProjectAssignment } from '../../../contract/view-employee-project-assignment.interface';
import { UpdateEmployeeToProjectAssignmentCommand } from '../../../../equipment/contract/update-employee-to-project-assignment-command';
import { EmployeeDispositionDatasource } from '../../../shared/employee-disposition.datasource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { Address } from '../../../../organisation/contract/address.interface';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';

@UntilDestroy()
@Component({
  selector: 'bh-employee-assignment-edit-dialog',
  templateUrl: './employee-assignment-edit-dialog.component.html',
  styleUrls: ['./employee-assignment-edit-dialog.component.scss'],
})
export class EmployeeAssignmentEditDialogComponent implements OnInit, OnDestroy {

  public assignmentEditForm: UntypedFormGroup;
  public startDateControl: UntypedFormControl;
  public endDateControl: UntypedFormControl;
  public assignment: ViewEmployeeProjectAssignment;
  public project: ViewProject;
  public conflicts: ViewEmployeeProjectAssignment[] = [];
  public readonly fieldLimit = FieldLimit;

  constructor(private employeeStore: EmployeeDispositionDatasource,
              private dialogRef: MatDialogRef<EmployeeAssignmentEditDialogComponent>) {
  }

  public ngOnInit(): void {
    this.assignmentEditForm = new UntypedFormGroup({
      description: new UntypedFormControl(decodeURIComponent(this.assignment.comment)),
    });
    this.startDateControl = new UntypedFormControl(new Date(this.assignment.assignmentStartDate));
    this.endDateControl = this.assignment.assignmentEndDate ?
      new UntypedFormControl(new Date(this.assignment.assignmentEndDate)) : new UntypedFormControl();
  }

  get description(): AbstractControl {
    return this.assignmentEditForm.get('description');
  }

  public ngOnDestroy(): void {
  }

  public updateConflicts(dateRange: DateRange, assignment: ViewEmployeeProjectAssignment): void {
    this.updateConflictsEmployees(dateRange, assignment);
  }

  private updateConflictsEmployees(dateRange: DateRange, assignment: ViewEmployeeProjectAssignment): void {
    if (!this.startDateControl.valid || !this.endDateControl.valid) {
      return;
    }

    this.employeeStore.getAssignmentCollisions(
      assignment.employeeId,
      DatesService.sameTimeZoneAtStartDateUTC(dateRange.startDate),
      DatesService.sameTimeZoneAtEndDateUTC(dateRange.endDate),
      assignment.assignmentId)
    .pipe(untilDestroyed(this))
    .subscribe((conflictingAssignments: ViewEmployeeProjectAssignment[]) => {
      this.conflicts = conflictingAssignments;
    });
  }

  public evaluateMinStartDate(assignment: ViewEmployeeProjectAssignment, project: ViewProject): Date {
    if (moment.utc(assignment.assignmentStartDate).isBefore(moment.utc())) {
      return new Date(assignment.assignmentStartDate);
    } else {
      return new Date(moment.max(moment.utc(project.projectStart), moment.utc()).format());
    }
  }

  public evaluateMaxStartDate(assignment: ViewEmployeeProjectAssignment, project: ViewProject): Date {
    if (moment.utc(assignment.assignmentStartDate).isBefore(moment.utc())) {
      return new Date(assignment.assignmentStartDate);
    } else if (this.endDateControl.value) {
      return new Date(this.endDateControl.value);
    } else {
      return project.projectEnd ? DatesService.utcDate(project.projectEnd) : null;
    }
  }

  public evaluateMinEndDate(assignment: ViewEmployeeProjectAssignment): Date {
    if (assignment.assignmentEndDate && moment.utc(assignment.assignmentEndDate).isBefore(moment.utc())) {
      return new Date(assignment.assignmentEndDate);
    } else {
      return new Date(this.startDateControl.value);
    }
  }

  public evaluateMaxEndDate(assignment: ViewEmployeeProjectAssignment, project: ViewProject): Date {
    if (assignment.assignmentEndDate && moment.utc(assignment.assignmentEndDate).isBefore(moment.utc())) {
      return new Date(assignment.assignmentEndDate);
    } else {
      return project.projectEnd ? DatesService.utcDate(project.projectEnd) : null;
    }
  }

  public save(): void {
    let cmd = new UpdateEmployeeToProjectAssignmentCommand();
    cmd.employeeId = this.assignment.employeeId;
    cmd.assignmentId = this.assignment.assignmentId;
    cmd.projectId = this.project.projectId;
    cmd.comment = encodeURIComponent(this.description.value);
    cmd.newAssignmentStartDate = this.startDateControl.value.getTime() === new Date(this.assignment.assignmentStartDate).getTime() ?
      this.startDateControl.value.toISOString() :
      DatesService.sameTimeZoneAtStartDateUTC(this.startDateControl.value);
    if (this.endDateControl.value) {
      cmd.newAssignmentEndDate =
        this.assignment.assignmentEndDate && this.endDateControl.value.getTime()
      === new Date(this.assignment.assignmentEndDate).getTime()
        ? this.endDateControl.value.toISOString()
        : DatesService.sameTimeZoneAtEndDateUTC(this.endDateControl.value);
    } else {
      cmd.newAssignmentEndDate = null;
    }

    this.dialogRef.close(cmd);
  }

  public isValid(): boolean {
    return this.assignmentEditForm.valid &&
      this.startDateControl.valid &&
      this.endDateControl.valid &&
      (this.conflicts.length === 0);
  }

  public printProject(project: ViewEmployeeProjectAssignment) {
    const {projectNumber, projectName} = project;
    return [projectNumber, projectName].filter(this.isExist).join(', ');
  }

  public printAddress(address: Address) {
    const {street, streetNumber, postalCode, city} = address;
    return [street, streetNumber, postalCode, city].filter(this.isExist).join(', ');
  }

  private isExist(element) {
    return !!element;
  }

  public printConflictTimeRange(conflictProject: ViewEmployeeProjectAssignment) {
    const format = 'YYYY-MM-DD';
    const {assignmentStartDate: start, assignmentEndDate: end} = conflictProject;

    const currentStart = this.startDateControl.value;
    const conflictStartDay = moment.max(moment(currentStart, format), moment(start, format));

    const currentEnd = this.endDateControl.value;
    const conflictEndDay = moment.min(moment(currentEnd, format), moment(end, format));

    return conflictStartDay.isSame(conflictEndDay) ?
      conflictStartDay.format(format) :
      conflictStartDay.format(format) + ' - ' + conflictEndDay.format(format);
  }
}
