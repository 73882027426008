import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Directive, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';

@UntilDestroy()
@Directive({
  selector: 'mat-paginator[bhCurrentItemPaginator]'
})
export class CurrentItemPaginatorDirective<T> implements OnInit, OnDestroy {
  @Input() data: T[];
  @Input() currentItemId: string;
  @Input() isIdentifierOfItem: (item: T, itemId: string) => boolean;
  @Output() paginationChanges = new EventEmitter<PageEvent>();

  private currentPageSize: number;

  constructor(private paginator: MatPaginator) {
  }

  public ngOnInit(): void {
    this.currentPageSize = this.paginator.pageSize;
    this.initListeners();
  }

  public ngOnDestroy(): void {
  }

  private initListeners(): void {
    this.paginator.page
      .pipe(untilDestroyed(this))
      .subscribe((pageEvent: PageEvent) => {
        let result = { ...pageEvent };
        if (pageEvent.pageSize !== this.currentPageSize) {
          this.currentPageSize = pageEvent.pageSize;
          result = this.patchPageEvent(pageEvent);
        }
        this.paginationChanges.next(result);
      });
  }

  private patchPageEvent(pageEvent: PageEvent): PageEvent {
    const indexInList = this.getIndexInListSafe(this.data, this.currentItemId);
    if (indexInList < 1) {
      return pageEvent;
    }

    return {
      ...pageEvent,
      pageIndex: pageEvent.pageIndex + (Math.floor(indexInList / pageEvent.pageSize))
    }
  }

  private getIndexInListSafe(items: T[], itemId: string): number {
    if (items && items.length > 0 && itemId) {
      try {
        return this.getIndexInList(items, itemId);
      } catch {
        return 0;
      }
    }

    return 0;
  }

  private getIndexInList(items: T[], itemId: string): number {
    return items.findIndex(item => this.isIdentifierOfItem(item, itemId));
  }

}
