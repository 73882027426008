import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { SEPARATOR } from './separator';

export abstract class FilterParams {
  protected params: { [key in FilterTypes]?: Set<string> } = {};

  public abstract getFilterParams(): any;

  public getFilterTypeParams(type: FilterTypes): string[] {
    return [...(this.params[type] || [])];
  }

  public hasAnyParam(): boolean {
    return Object.keys(this.params).some(key => this.params[key].size > 0);
  }

  public hasParam(type: FilterTypes, param: any): boolean {
    return Boolean(this.params[type] &&
      param.split(SEPARATOR).some((p: string) => this.params[type].has(p)));
  }

  public updateParams(commands: UpdateFilterCommand[]): void {
    commands.forEach(({ filterType, storeName, newValue }) => {
      const splitStoreName = storeName.split(SEPARATOR);
      if (newValue) {
        splitStoreName.forEach(name => this.addParam(filterType as FilterTypes, name));
      } else {
        splitStoreName.forEach(name => this.deleteParam(filterType as FilterTypes, name));
      }
    });
  }

  public reset(): void {
    this.params = {};
  }

  private addParam(filterType: FilterTypes, value: string): void {
    if (!this.params[filterType]) {
      this.params = { ...this.params, [filterType]: new Set<string>() };
    }
    this.params[filterType].add(value);
  }

  private deleteParam(filterType: FilterTypes, value: string): void {
    if (this.params[filterType]) {
      this.params[filterType].delete(value);
    }
  }
}
