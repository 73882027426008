<mat-card class="board" fxFlexFill>
  <div fxLayout="column" fxFlexFill>
    <div class="search-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <div class="disposition-list" fxLayout="row">
        <div *ngFor="let option of options" fxLayout="row" matTooltip="{{ resolveOptionName(option) }}"
          [ngClass]="{'active': isSelected(option), 'select-type-div': true, 'hidden': !checkAuthority(option)}"
          [matTooltipPosition]="'above'">
          <fa-icon class="fa-icon" (click)="activateView(option)" [icon]="resolveOptionIcon(option)"></fa-icon>
          <mat-slide-toggle class="type-toggle" color="primary" (change)="activateView(option)"
            [checked]="isSelected(option)">
          </mat-slide-toggle>
        </div>
      </div>
      <mat-form-field>
        <input matInput [placeholder]="'modules.disposition.dispositionTimeline.projectListSearch'|translate" [formControl]="termsControl" />
        <mat-icon matSuffix (click)="termsControl.reset('')">close</mat-icon>
      </mat-form-field>
      <form [formGroup]="filterForm" fxFlex="50" fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <mat-form-field>
          <mat-select formControlName="selectedLabels" multiple [placeholder]="'general.label.s'|translate">
            <mat-option *ngFor="let label of labels | async" [value]="label.name">
              {{label.name}} - ({{label.count}})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="selectedProjectAssignees" multiple [placeholder]="'general.responsible'|translate">
            <mat-option *ngFor="let assignee of projectAssignees | async" [value]="assignee.id">
              {{assignee.name}} - ({{assignee.count}})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="showFinished" [placeholder]="'modules.disposition.dispositionTimeline.completed'|translate">
            <mat-option [value]="false">{{'modules.disposition.dispositionTimeline.activeProjectsOnly'|translate}}</mat-option>
            <mat-option [value]="true">{{'modules.disposition.dispositionTimeline.activeAndCompletedProjects'|translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div #scheduler class="dhx_cal_container" [ngClass]="{ 'drop-container': isDragging() }">
      <div class="dhx_cal_navline">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
          <div class="disposition-list" fxLayout="row">
            <div [matTooltip]="(dispositionStore.fullScreen ? 'general.labels.detailView.reduce' : 'general.labels.detailView.enlarge')|translate"
              (click)="toggleFullScreen()" class="full-screen" [ngClass]="{'active': dispositionStore.fullScreen}">
              <mat-icon class="bh-mat-icon" *ngIf="!dispositionStore.fullScreen"
                fontSet="icon-fullscreen_lauch_outline">
              </mat-icon>
              <mat-icon class="bh-mat-icon" *ngIf="dispositionStore.fullScreen" fontSet="icon-fullscreen_exit_outline">
              </mat-icon>
            </div>

            <div *ngIf="checkAuthority('EQUIPMENTS')" [matTooltip]="'general.list'|translate" class="full-screen" (click)="dispositionStore.toggleTimeline()">
              <mat-icon class="bh-mat-icon" fontSet="icon-main_equipmentlist02_solid"></mat-icon>
            </div>
          </div>

          <div fxLayoutAlign="start center" class="dhx_button_group">
            <div class="dhx_cal_tab" name="timeline_year_tab"></div>
            <div class="dhx_cal_tab" name="timeline_month_tab"></div>
            <div class="dhx_cal_tab" name="timeline_week_tab"></div>
          </div>

          <div class="dhx_cal_date"></div>
          <div class="dhx_custom_cal_date" fxFlex fxLayout="row" fxLayoutAlign="center center">
            {{ timelineDate }}
          </div>

          <div fxLayoutAlign="end center" class="dhx_button_group">
            <div class="dhx_cal_today_button"></div>
            <div class="dhx_cal_prev_button"></div>
            <div class="dhx_cal_next_button"></div>
          </div>
        </div>
      </div>

      <div class="dhx_cal_header"
           bhSchedulerDragHighlighter
           [highlightClassName]="'dhx_custom_highlight'"
           [date]="mousePositionDate"
           [highlightClassNamePredicate]="highlightPredicate">
      </div>

      <div [id]="containerIdentifiers.DISPOSITION_SCHEDULER"
           class="scheduler-drop-container"
           cdkDropList
           [cdkDropListConnectedTo]="dragAndDropContainerIds"
           (cdkDropListDropped)="dropContainerDropped($event)"
           (cdkDropListExited)="dropContainerExited()">
        <mat-spinner *ngIf="isLoadingData |async"
          class="spinner-update"
          [diameter]="120"
          data-osp-test="spinner-update">
        </mat-spinner>
        <div id="scheduler-container" class="dhx_cal_data">
        </div>
      </div>
    </div>
    <div class="footer">
      <mat-checkbox color="primary" (change)="updateSections()"
        [(ngModel)]="dispositionStore.showIrrelevantAssignments">
        {{'modules.disposition.dispositionTimeline.showWithoutActiveDisposition'|translate}}
      </mat-checkbox>

      <mat-paginator #paginator [length]="dispositionStore.totalProjects | async"
        [pageIndex]="dispositionStore.pageIndex | async" [pageSize]="dispositionStore.pageSize | async"
        [pageSizeOptions]="[5, 25, 50, 100]" (page)="onPaginateChange($event)"
        (paginationChanges)="onPaginateChange($event)">
      </mat-paginator>
    </div>
  </div>
</mat-card>
