import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'bh-success-snackbar',
  templateUrl: './success-snackbar.component.html',
  styleUrls: []
})
export class SuccessSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string }, private snackBarRef: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  dismiss(): void {
    this.snackBarRef.dismiss()
  }
}
