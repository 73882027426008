<div class="alarm-list" fxFlexFill>
  <div class="alarm-row" *ngFor="let alarm of alarms; let i = index" fxLayout="row"
       fxLayoutAlign="start center"
       (pan)="onPan($event, container.offsetWidth, i)" [style.left.px]="getPos(i)" #container>

    <mat-icon class="alarm-icon" [ngClass]="getAlarmLevelColor(alarm.alarmLevel)">warning</mat-icon>

    <div class="alarm-content" fxLayout="column" fxFill>

      <div class="alarm-equipment-info" (click)="navigateToEquipment(alarm.equipmentId)">
        <a>{{ alarm.equipmentModel }} ({{alarm.equipmentSerialNumber}})</a>
        <mat-icon>open_in_new</mat-icon>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start" class="alarm-info">
        <p fxLayout="row">{{alarm.alarmType | alarmTypeDisplay}}</p>
        <span fxLayout="row">{{alarm.alarmMessage}}</span>
      </div>

      <div *ngIf="isGeofenceAlarm(alarm)" class="alarm-equipment-info"
           (click)="navigateToProject(alarm.projectId)">
        <a>{{'general.project.s'|translate}} #{{alarm.projectNumber}} - {{alarm.projectName}}</a>
        <mat-icon>open_in_new</mat-icon>
      </div>
    </div>

    <mat-icon *ngIf="isAcknowledgeable(container.offsetWidth, i)" class="align-right red">
      delete_forever
    </mat-icon>
  </div>

  <span *ngIf="alarms.length === 0" class="no-alarms" fxLayout="row" fxLayoutAlign="center stretch">
    {{'modules.notification.alarms.noAlarms'|translate}}
  </span>
</div>
