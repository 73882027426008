<div class="autonumber-edit">
  <div class="dialog-close-icon"></div>
  <h1 mat-dialog-title>
    {{'modules.organisation.customerList.editAutoNumber'| translate}}
  </h1>
  <div mat-dialog-content>
    <button
      mat-raised-button
      class="insert-autonumber-button"
      [disabled]="autoNumberControl.invalid"
      (click)="insertAutonumberToEndOfLine()"
    >
      {{'modules.organisation.customerAutoNumberEdit.insertAutoNumber'| translate}}
    </button>
    <mat-form-field>
      <mat-label>{{'modules.organisation.customerAutoNumberEdit.autonumberPlaceholder'| translate}}</mat-label>
      <input matInput
             bhRemoveWhitespaces
             [formControl]="autoNumberControl"
             [errorStateMatcher]="errorMatcher"
             [maxLength]="fieldLimit.AUTONUMBER_SCHEMA"
      >
      <mat-error *ngIf="autoNumberControl.hasError('schemaMaxLengthExceeded')">
        {{'shared.validation.autoNumberSchema.schemaMaxLengthExceeded'|translate}}
      </mat-error>
      <mat-error *ngIf="autoNumberControl.hasError('schemaTemplateInvalid')">
        {{'shared.validation.autoNumberSchema.schemaTemplateInvalid'|translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="autoNumberControl.invalid">{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
