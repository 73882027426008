export enum EquipmentSubMenuLink {
  GENERAL = 'general',
  TIMELINE = 'timeline',
  COST = 'cost',
  TASKS = 'tasks',
  TELEMATIC = 'telematic',
  DISPOSITION = 'disposition',
  USAGE = 'usage',
  SUB_EQUIPMENTS = 'sub-equipments',
  CONTRACTS = 'contracts',
  CARD = 'card'
}
