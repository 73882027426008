import { HorizontalScrollOffset } from 'app/shared/contract/horizontal-scroll-offset.interface';
interface ContentShift {
  left: number;
  right: number;
  width: number;
}
export class SchedulerEventContentShift {
  public static getCalculatedContentShift(hScrollOffset: HorizontalScrollOffset, start: number, end: number): ContentShift {
    let left = 0;
    let right = 0;
    if (hScrollOffset) {
      left = SchedulerEventContentShift.normalizeShift(hScrollOffset.left - start, end - start);
      right = SchedulerEventContentShift.normalizeShift(hScrollOffset.right - (hScrollOffset.scrollSize - end), end - start);
    }
    return { left, right, width: (end - start) - (left + right) };
  }

  private static normalizeShift(value: number, width: number): number {
    if (value < 0) {
      return 0;
    }
    return value < width ? value : width;
  }
}
