<div class="list-view-main__right" fxFlexFill fxLayout="column">
  <div class="list-view-main__right-header"
    fxFlex="60px"
    fxLayout="row"
    fxLayoutAlign="space-between center">

    <div *ngIf="displayService.isTableAndDetailsChanges | async"
      class="full-screen"
      fxFlex="0 0 70px"
      fxLayout="row"
      fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.enlarge' | translate"
      (click)="displayService.displayDetailsFullscreen()" >
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div *ngIf="displayService.isDetailsFullscreenChanges | async"
      class="full-screen"
      fxFlex="0 0 70px"
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltip]="'general.labels.detailView.reduce' | translate"
      (click)="displayService.displayTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>

    <div class="name" fxFlex>
      <span>
        {{(currentTask | async)?.name}}
      </span>
    </div>

    <div *ngIf="allowedToManage && (currentTask | async)" class="more" fxFlex="0 0 20px">
      <button mat-icon-button [matMenuTriggerFor]="more" data-osp-test="button-more">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #more="matMenu">
        <ng-container *ngIf="(currentTask | async) as task">
          <div *ngIf="(currentTask | async).category === maintenanceCategory.REMINDER; else taskMenu">
            <button mat-menu-item (click)="completeReminder(task)" data-osp-test="complete-reminder">
              <mat-icon>assignment_turned_in</mat-icon>
              <span>{{'general.buttons.complete'|translate}}</span>
            </button>
            <button mat-menu-item (click)="deleteReminder(task)" data-osp-test="delete-reminder">
              <mat-icon>delete_forever</mat-icon>
              <span>{{'general.buttons.delete'|translate}}</span>
            </button>
          </div>
          <ng-template #taskMenu>
            <div>
              <button mat-menu-item (click)="completeTask(task)" data-osp-test="complete-task">
                <mat-icon>assignment_turned_in</mat-icon>
                <span>{{'general.buttons.complete'|translate}}</span>
              </button>
              <button mat-menu-item (click)="unassignRule(task)" data-osp-test="delete-task">
                <mat-icon>delete_forever</mat-icon>
                <span>{{'general.buttons.removeAssignment'|translate}}</span>
              </button>
            </div>
          </ng-template>
        </ng-container>
      </mat-menu>
    </div>

    <div *ngIf="!(displayService.isTableFullscreenChanges | async)"
      class="change-display-mode"
      fxFlex="0 0 60px"
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltip]="'general.labels.detailView.collapse' | translate"
      (click)="displayService.displayTableFullscreen()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="content" fxFlex>
      <bh-maintenance-task-view-detail [task]="currentTask | async"></bh-maintenance-task-view-detail>
    </div>
  </div>
</div>
