import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../equipments.datasource';
import { getBrowserName, Utils } from '../../../../../shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseEquipmentInvoiceDetailsComponent } from '../base/base-equipment-invoice-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { BrowserName } from 'app/shared/enums/browser-name.enum';
import { RouterHistory } from '../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-invoice-details',
  templateUrl: './equipment-invoice-details.component.html',
  styleUrls: ['./equipment-invoice-details.component.scss']
})
export class EquipmentInvoiceDetailsComponent extends BaseEquipmentInvoiceDetailsComponent implements OnInit {

  public isFirefox: boolean;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected equipmentStore: EquipmentsDataSource,
              protected utils: Utils,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(router, route, authService, equipmentStore, utils, dialog, routerHistory, languageService,
      fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getInvoice();
    this.isFirefox = getBrowserName() === BrowserName.FIREFOX;
  }

}
