import { PerformanceType } from '../../contract/performance-type.enum';
import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'performanceType'
})
export class PerformanceTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(performanceType: PerformanceType): string {
    switch (performanceType) {
      case PerformanceType.Energiekosten:
        return this.translate('modules.equipment.performanceType.energyCosts');
      case PerformanceType.Finanzierung:
        return this.translate('modules.equipment.performanceType.financing');
      case PerformanceType.Freimeldung:
        return this.translate('modules.equipment.performanceType.vacancy');
      case PerformanceType.Fullservice:
        return this.translate('modules.equipment.performanceType.fullService');
      case PerformanceType.Gewaltschaden:
        return this.translate('general.maliciousDamage.s');
      case PerformanceType.Kauf:
        return this.translate('modules.equipment.performanceType.purchase');
      case PerformanceType.Leasing:
        return this.translate('modules.equipment.performanceType.leasing');
      case PerformanceType.Maschinenbruchversicherung:
        return this.translate('modules.equipment.performanceType.machineBreakdownInsurance.s');
      case PerformanceType.Miete:
        return this.translate('modules.equipment.performanceType.rentalFee');
      case PerformanceType.Nachrüstung:
        return this.translate('modules.equipment.performanceType.retrofitting');
      case PerformanceType.Prüfung:
        return this.translate('general.inspection.s');
      case PerformanceType.Reifenschaden:
        return this.translate('modules.equipment.performanceType.tireDamage.s');
      case PerformanceType.Reparatur:
        return this.translate('modules.equipment.performanceType.repair.s');
      case PerformanceType.Sonstiges:
        return this.translate('modules.equipment.performanceType.others');
      case PerformanceType.Ersatzteile:
        return this.translate('modules.equipment.performanceType.spareParts');
      case PerformanceType.Verschleiss:
        return this.translate('modules.equipment.performanceType.wear');
      case PerformanceType.Versicherung:
        return this.translate('modules.equipment.performanceType.insurance');
      case PerformanceType.Wartung:
        return this.translate('general.maintenance.pl');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
