import { Component } from '@angular/core';
import { BaseEquipmentViewDetailComponent } from '../../equipment-view-detail/base/base-equipment-view-detail.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-equipment-view-description',
  templateUrl: './mobile-equipment-view-description.component.html',
  styleUrls: ['./mobile-equipment-view-description.component.scss']
})

export class MobileEquipmentViewDescriptionComponent extends BaseEquipmentViewDetailComponent {

  constructor(public equipmentStore: EquipmentsDataSource,
              protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(equipmentStore, authService, router, route, routerHistory);
  }
}
