import { TelematicsUnitType } from '../../../../../equipment/contract/telematics-unit-type.enum';

export const telematicsUnitsTypesWithoutBeacons: TelematicsUnitType[] = [
  TelematicsUnitType.AEMP,
  TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT,
  TelematicsUnitType.GENERIC_2,
  TelematicsUnitType.GENERIC_3,
  TelematicsUnitType.GENERIC_4,
  TelematicsUnitType.GENERIC_5,
  TelematicsUnitType.GPS_OVER_IP,
  TelematicsUnitType.SIGFOX_TRACKERTYPL26_1,
  TelematicsUnitType.TELTONIKA_CABLE_UNIT,
  TelematicsUnitType.TELTONIKA_OBD_UNIT,
  TelematicsUnitType.TRACKUNIT_1
];
