<div class="dialog" *ngIf="customer">

  <div class="dialog-view-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title">{{customer.customerName}}</span>

    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <mat-tab-group animationDuration="0ms">

    <mat-tab [label]="'general.customer.s'|translate" bodyClass="preview-dialog-content">

      <div class="technical-field-list" fxLayout="row" fxLayoutAlign="space-between stretch">

        <div>
          <label>{{'general.name.s'|translate}}</label>
          <p>{{customer.customerName}}</p>
        </div>

        <div>
          <label>{{'general.units.number'|translate}}</label>
          <p>{{customer.customerNumber}}</p>
        </div>

        <div>
          <label>{{'general.address.street'|translate}}</label>
          <p>{{customer.customerAddress.street}}</p>
        </div>

        <div>
          <label>{{'general.address.houseNumber'|translate}}</label>
          <p>{{customer.customerAddress.streetNumber}}</p>
        </div>

        <div>
          <label>{{'general.address.postCode'|translate}}</label>
          <p>{{customer.customerAddress.postalCode}}</p>
        </div>

        <div>
          <label>{{'general.address.city'|translate}}</label>
          <p>{{customer.customerAddress.city}}</p>
        </div>

        <div>
          <label>{{'general.address.country'|translate}}</label>
          <p>{{ getCountryName(customer.customerAddress.country) }}</p>
        </div>

        <div>
          <label>{{'modules.organisation.customerView.defaultLanguage'|translate}}</label>
          <p>{{ customer.defaultLanguage | uppercase | defaultValue : '-' }}</p>
        </div>

        <div class="full-width">
          <label>{{'modules.organisation.customerView.otherLanguages'|translate}}</label>
          <p>{{ (customer.allLanguages || []).join(', ') | uppercase | defaultValue : '-' }}</p>
        </div>

        <div class="full-width">
          {{'modules.organisation.customerView.linkedCustomerNumbers'|translate}}
        </div>

        <div *ngIf="!customer.linkedCustomerNumbers || customer.linkedCustomerNumbers.length === 0">
          <span>-</span>
        </div>

        <div *ngFor="let nr of customer.linkedCustomerNumbers">
          <p>{{nr}}</p>
        </div>

        <div class="full-width">
          {{'modules.organisation.base.customerType.pl'|translate}}
        </div>

        <div *ngIf="!customer.customerTypeList || customer.customerTypeList.length === 0">
          <span>-</span>
        </div>

        <div *ngFor="let customerType of customer.customerTypeList">
          <p class="multiple">{{ customerType }}</p>
        </div>

        <div class="full-width">
          {{'modules.organisation.base.customerModules'|translate}}
        </div>

        <div *ngIf="!customer.modules || customer.modules.length === 0">
          <span>-</span>
        </div>

        <div *ngFor="let mod of customer.modules">
          <p class="multiple">{{ mod }}</p>
        </div>

      </div>

    </mat-tab>
    <mat-tab [label]="'general.report.pl'|translate" bodyClass="preview-dialog-content">

      <div class="data-table-wrapper" fxFlex>
        <mat-table #table [dataSource]="reportStore" fxFlex class="data-table">

          <ng-container cdkColumnDef="reportDefinitionName">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.organisation.customerView.reportTypeName'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row">{{row.reportDefinitionName}} </mat-cell>
          </ng-container>


          <ng-container cdkColumnDef="reportDefinitionExportFormat">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.organisation.customerView.reportTypeFileFormat'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row">{{row.reportDefinitionExportFormat}} </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div class="report-count">{{'general.units.number'|translate}}: {{reportDefinitions.length}}</div>
    </mat-tab>

  </mat-tab-group>

</div>

<div class="dialog" *ngIf="!customer">

  <p>{{'general.labels.notFound'|translate:{ value: 'general.customer.pl'|translate } }}</p>

</div>
