<div class="attachment-type-container">
  <bh-subtitle [text]="'general.image.pl'|translate"></bh-subtitle>
  <div class="attachment-item"
       *ngFor="let image of images; trackBy: imagesTrackBy"
       fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="20px">
    <div class="gallery-container">

    <bh-image-view [key]="image.documentKey"></bh-image-view>


    </div>
    <div class="file-name" fxFlex="1 1 100%">
      <bh-inline-filename-edit
          [filename]="image.fileName"
          [editable]="editableName"
          (saveValue)="changeFileName($event, image)"
          [showHint]="isDefaultImage(image)"
          hint="({{'general.standardImage'|translate}})">
      </bh-inline-filename-edit>
    </div>

    <div *ngIf="visibleDescription || editableDescription" fxLayoutGap="20px" fxFlex="1 1 100%">
      <bh-inline-text-edit
        [initialValue]="image.fileDescription"
        [editable]="editableDescription"
        (saveValue)="changeDescription($event, image)">
      </bh-inline-text-edit>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" fxFlex="0 0 {{setDefaultEnable ? 100 : 65}}px">
      <div *ngIf="isSetDefaultEnabled(image)">
        <mat-icon class="bh-mat-icon"
                  [matTooltip]="'general.labels.markAsStandardImage'|translate"
                  (click)="defaultImageChanged.emit(image)">
          settings_system_daydream
        </mat-icon>
      </div>
      <div *ngIf="image.fileExists">
        <mat-icon class="bh-mat-icon"
                  (click)="downloadImage(image)"
                  [matTooltip]="'general.buttons.download'|translate">
          cloud_download
        </mat-icon>
      </div>
      <div *ngIf="!image.fileExists">
        <mat-icon class="bh-mat-icon hover-red"
                  [matTooltip]="'general.labels.notFound'|translate:{value: 'general.file'|translate}">
          cloud_off
        </mat-icon>
      </div>
      <div *ngIf="deletable">
        <mat-icon *ngIf="!pendingChanges; else pendingDelete"
                  class="bh-mat-icon hover-red"
                  [matTooltip]="'general.buttons.delete'|translate"
                  (click)="deleted.emit(image.documentKey)">
          delete_forever
        </mat-icon>
        <ng-template #pendingDelete>
          <mat-icon class="bh-mat-icon hover-red"
                    [matTooltip]="'general.buttons.deleteDisabled'|translate">
            auto_delete
          </mat-icon>
        </ng-template>
      </div>
    </div>
  </div>
</div>
