import { Pipe, PipeTransform } from '@angular/core';
import { UserStatusPipe } from 'app/shared/pipes/user-status.pipe';
import { UserStatus } from '../enums/user-status.enum';
import { SubmitterInfo } from '../contract/submitter-info.interface';

@Pipe({
  name: 'submitterInfo'
})
export class SubmitterInfoPipe implements PipeTransform {
  constructor(private userStatusResolver: UserStatusPipe) {}

  public transform(submitter: SubmitterInfo): string {
    if (submitter) {
      switch (submitter?.status) {
        case UserStatus.ACTIVE:
          return this.formatName(submitter) || this.userStatusResolver.transform(UserStatus.UNKNOWN);
        case UserStatus.UNKNOWN:
          return submitter?.firstName || this.userStatusResolver.transform(UserStatus.UNKNOWN);
        default:
          return this.userStatusResolver.transform(submitter?.status);
      }
    }

    return '';
  }

  private formatName(submitter: SubmitterInfo): string {
    return [submitter?.firstName, submitter?.name]
      .filter(Boolean)
      .join(' ');
  }

}
