import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[bhChangeableInputAllowedPattern], textarea[bhChangeableInputAllowedPattern]'
})
export class ChangeableInputAllowedPatternDirective {

  private regExp;
  @Input() set bhChangeableInputAllowedPattern(pattern: string) {
    this.regExp = new RegExp(pattern)
  }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (!this.regExp.test(value)) {
      inputElement.value = value.slice(0, -1);
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text/plain');

    // Get the current cursor position
    const inputElement = event.target as HTMLInputElement;
    const cursorStart = inputElement.selectionStart || 0;
    const cursorEnd = inputElement.selectionEnd || 0;

    // Remove the selected portion from the existing value
    const currentValue = inputElement.value;
    const newValue = currentValue.slice(0, cursorStart) + pastedText + currentValue.slice(cursorEnd);

    if (!this.regExp.test(newValue)) {
      event.preventDefault();
    }
  }
}
