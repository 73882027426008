<div class="add-employee-assignment-dialog">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content fxLayout="row" fxLayoutAlign="start start">
    <div class="employee-role-icon">
      <mat-icon *ngIf="!icon">add_circle_outline</mat-icon>
      <fa-icon [icon]="icon" *ngIf="icon"></fa-icon>
    </div>
    <div fxFlex="350px">
      <h4>{{'modules.equipment.employeeAssignment.addResponsible'|translate}}</h4>
      <form [formGroup]="form">

        <mat-form-field>
          <mat-label>{{'general.role.s'|translate}}</mat-label>
          <mat-select formControlName="employeeRole"
            disableOptionCentering="true">
            <mat-option>
              <ngx-mat-select-search [formControl]="employeeRoleFilter"
                [placeholderLabel]="'general.labels.search'|translate:{value: 'general.role.s'|translate}"
                [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{value: 'general.role.pl'|translate}">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let employeeRole of employeeRoles" [value]="employeeRole">
              {{ employeeRole | assigneeType }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{'general.employee.s'|translate}}</mat-label>
          <mat-select (selectionChange)="employeeSelected($event.value)" formControlName="employee" disableOptionCentering="true">
            <mat-option>
              <ngx-mat-select-search [formControl]="employeeFilter"
                [placeholderLabel]="'general.labels.search'|translate:{value: 'general.employee.s'|translate}"
                [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{value: 'general.employee.pl'|translate}">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">
              {{ employee.employeeName }}, {{ employee.employeeFirstName | defaultValue: '-' }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['employee'].hasError('assignmentExisted')">
            {{ "modules.disposition.projectAssignees.existedAssignmentError" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-checkbox *ngIf="hasAssignmentOrTransferRequestWorkflow" color="primary"
                      formControlName="isAcceptance">
          {{'modules.disposition.projectAssignees.isAcceptance' | translate}}
        </mat-checkbox>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button bhTimeoutSaveButton color="primary" (throttledClick)="save()"
      [disabled]="saveDisabled">{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
