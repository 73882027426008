<div class="m-view-lifecycle-details" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

  <bh-mobile-sub-header
      [title]="equipmentStore.currentTitle | async"
      [subtitle]="equipmentStore.currentSubtitle | async">
  </bh-mobile-sub-header>

  <div class="m-view-details-menu static-title" fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'general.invoice.s'|translate}}</span>
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
    </button>
  </div>

  <div *ngIf="!allowedToSee" class="m-view-details-unauthorized"
       fxFlexFill fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
    <mat-icon aria-label="Close">no_encryption</mat-icon>
    <span>{{'modules.equipment.invoice.needPermissionsToViewInfo'|translate}}</span>
    <button mat-button (click)="goBack()">{{'general.buttons.back'|translate}}</button>
  </div>

  <div *ngIf="!invoice && allowedToSee" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
  </div>

  <div *ngIf="invoice && allowedToSee" class="m-view-lifecycle-details-content-wrapper">
    <div class="m-view-lifecycle-details-content">
      <mat-tab-group>

        <mat-tab [label]="'general.general.s'|translate">
          <div class="m-view-list-inputs">

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.date'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.invoiceDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.invoice.performanceDate'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.performanceDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.performanceType'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.performanceType}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.invoiceNumber'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{getInvoiceNumber()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.costs'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.invoiceAmount | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'general.documentation'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.documentation}}">
            </mat-form-field>

            <div class="m-view-lifecycle-submit-info" fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <div>{{'general.labels.createdBy'|translate}}:</div>
                <div>{{invoice.submittedBy}}</div>
              </div>
              <div>
                <div>{{'general.on'|translate|titlecase}}:</div>
                <div>{{invoice.submitDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}</div>
              </div>
            </div>

          </div>
        </mat-tab>

        <mat-tab [label]="'general.attachment.pl'|translate">
          <div class="m-view-attachment m-view-tabs" fxLayout="row" fxLayoutAlign="start stretch">

            <mat-accordion displayMode="flat" fxFlex>
              <mat-expansion-panel *ngFor="let document of lcDocumentsDataSource.data" hideToggle="true">
                <mat-expansion-panel-header class="m-view-attachment__panel-header"
                                            collapsedHeight="70px"
                                            expandedHeight="70px">
                  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div (click)="openFile($event, document);"
                         class="file-info" fxFlex fxLayout="row"
                         fxLayoutAlign="start stretch">
                      <div fxFlex="50px" fxLayout="row" fxLayoutAlign="start center">
                        <fa-icon [icon]="getFileTypeIcon(document)" size="2x"></fa-icon>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="block-space" fxLayout="row">
                          <span class="shortening-filename">{{getFileName(document.fileName)}}</span>
                          <span>{{getFileNameExtension(document.fileName)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="icon" fxFlex="50px" fxLayout="column"
                         fxLayoutAlign="center stretch">
                      <span *ngIf="document?.fileExists">
                        <mat-icon (click)="downloadDocument(document)"
                                  [matTooltip]="'general.buttons.download'|translate">cloud_download</mat-icon>
                      </span>
                    </div>
                  </div>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </mat-tab>

        <mat-tab [label]="'modules.equipment.base.extendedInvoiceInfo'|translate">
          <div class="m-view-list-inputs">

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.timeRequirement'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{getDuration()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.callOutFee'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.callOutFee | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.mileageCovered'|translate|uppercase}} ({{'km'|dimensionUnitDisplay}})</mat-label>
              <input matInput readonly
                     value="{{invoice.drive | dimensionUnitConverter: 'km'}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.allowance'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice?.allowance | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.materialCosts'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.materialCosts | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.deliveryAndFreightCosts'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.freightCharges | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.supplierServiceProvider'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.serviceContractor}}">
            </mat-form-field>

            <mat-form-field floatLabel="always">
              <mat-label>{{'modules.equipment.base.serviceStaff'|translate|uppercase}}</mat-label>
              <input matInput readonly
                     value="{{invoice.serviceWorker}}">
            </mat-form-field>

          </div>
        </mat-tab>

      </mat-tab-group>
    </div>

  </div>
</div>
