import { OnInit, Directive } from '@angular/core';
import { ViewEmployee } from 'app/modules/disposition/contract/view-employee.interface';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from 'app/core/keycloak';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterHistory } from 'app/shared/router-history';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';

@Directive()
export class EmployeeViewDispositionBase extends GuardedNavigableInputComponent implements OnInit {

    protected currentEmployee: ViewEmployee;

    constructor(protected authService: KeycloakService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected routerHistory: RouterHistory,
        protected employeeManagementStore: EmployeeManagementDatasource) {
        super(authService, router, route, routerHistory);
    }

    public ngOnInit(): void {
        this.subscribeToCurrentEmployee();
    }

    private subscribeToCurrentEmployee(): void {
        this.employeeManagementStore.currentEmployee.subscribe((employee: ViewEmployee) => {
            this.currentEmployee = employee;
        })
    }
}
