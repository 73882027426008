import { HttpContext, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import { HttpStatusCodes } from 'app/shared/enums/http-status-codes.enum';
import { CONTEXT_TOKEN, CONTEXT_TOKEN_VALUE } from 'app/core/interceptors/request-error-handlers/context-token-collection';

type EventSelectorSubscription = (sender: Observable<HttpErrorResponse>) => Subscription;

@Injectable({
  providedIn: 'root'
})
export class RequestErrorHandlerEquipmentService {

  private static readonly contextToken = CONTEXT_TOKEN.ERROR_HANDLER;
  private static readonly contextTokenValue = CONTEXT_TOKEN_VALUE.GET_EQUIPMENT;
  private senders: Subject<HttpErrorResponse>[] = [];

  public static getHttpContext(httpContext?: HttpContext): HttpContext {
    const context = httpContext ?? new HttpContext();
    context.set(
      RequestErrorHandlerEquipmentService.contextToken,
      RequestErrorHandlerEquipmentService.contextTokenValue
    );
    return context;
  }

  public selectEventSender(cbEventSelector: EventSelectorSubscription): void {
    if (cbEventSelector instanceof Function) {
      const sender = this.createNewSender();
      this.initEventSelectorSubscription(cbEventSelector, sender);
    }
  }

  public handleError(error: HttpErrorResponse, req: HttpRequest<any>): Observable<HttpErrorResponse | never> {
    if (this.shouldHandle(error, req)) {
      this.senders.forEach(sender => sender.next(error));
      return EMPTY;
    }
    return of(error);
  }

  private createNewSender(): Subject<HttpErrorResponse> {
    const sender = new Subject<HttpErrorResponse>();
    this.senders.push(sender);
    return sender;
  }

  private initEventSelectorSubscription(
    cbEventSelector: EventSelectorSubscription,
    sender: Subject<HttpErrorResponse>
  ): void {
    cbEventSelector(sender).add(() => {
      const index = this.senders.findIndex(s => s === sender);
      if (index > -1) {
        this.senders[index].complete();
        this.senders.splice(index, 1);
      }
    })
  }

  private shouldHandle(error: HttpErrorResponse, req: HttpRequest<any>): boolean {
    return this.isCorrespondingErrorHandlerType(req)
      && error.status === HttpStatusCodes.NOT_FOUND
      && this.senders?.length > 0;
  }

  private isCorrespondingErrorHandlerType(req: HttpRequest<any>): boolean {
    return req.context.get(RequestErrorHandlerEquipmentService.contextToken) === RequestErrorHandlerEquipmentService.contextTokenValue;
  }

}
