import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OrganisationCoreDataService {

  private url = `${environment.APP_CORE_DATA_SERVICE_BASE_URL}/api/v1/organisations`;

  constructor(private http: HttpClient) {
  }

  public getOrganisations(): Observable<any> {
    return this.http.get<any>(this.url);
  }
}
