<div class="dialog-wrapper" fxFill>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <qr-code *ngIf="value; else noData"
    size="280"
    [value]="value">
  </qr-code>
</div>

<ng-template #noData>
  <span id="no-data">{{'general.noData'|translate}}</span>
</ng-template>
