<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{confirmTitle | translate}}</h1>
  <div mat-dialog-content class="message" style="overflow:hidden;">
    {{(confirmMessage | translate)}}
    {{(alternativeRolesMessage | translate)}}
    <mat-form-field appearance="fill">
      <mat-label>{{'modules.navigation.sidebar.administrationMenu.userRoles' | translate }}</mat-label>
      <mat-select [(ngModel)]=selectedRoles multiple [value]="0">
        <mat-option *ngFor="let role of alternativeRoles" [value]=role>
          {{role.name}}
          <span *ngIf="isBrzRole(role)" style="font-size:12px">
            ({{'modules.userrole.roleAddEdit.notAsssignableToBRZ' | translate}})
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="collisionCheck()">
      {{'modules.userrole.roleAddEdit.invalidRightsCombination' | translate }}
    </mat-error>
    <mat-error *ngIf="onlyBRZRolesSelected() && this.selectedRoles.length > 0">
      {{'modules.userrole.roleAddEdit.atLeastOneBRZRole' | translate}}
    </mat-error>
    <section>
      <p *ngIf="brzUsers.length > 0" style="font-size: 10px; color: grey">
        {{'modules.userrole.roleAddEdit.usersWithBRZ' | translate}} (
        <span *ngFor="let user of brzUsers,let i = index">
          {{user.name}},{{user.firstName}}
          {{(i < brzUsers.length - 1) ? ',' : ''}}
        </span>
        )
      </p>
      <p *ngIf="brzUsers.length <= 0" style="font-size: 10px; color: grey">
        {{'modules.userrole.roleAddEdit.noBRZUser' | translate}}
      </p>
    </section>
    <hr/>
    <section style='color:red;margin-top: 5px;'>
      <mat-checkbox color="warn" (change)="toggleCheckbox()">
        {{(secondaryConfirmMessage | translate)}}
      </mat-checkbox>
    </section>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">
      {{'general.buttons.cancel'|translate}}
    </button>
    <div
      matTooltip="{{('modules.userrole.roleList.selectRolesTooltip' | translate)}}"
      [matTooltipDisabled]="!disabled()">
      <button mat-raised-button color="primary" [disabled]='disabled()' [mat-dialog-close]="['yes',selectedRoles]">
        {{'general.buttons.save'|translate}}
      </button>
    </div>
  </div>
</div>
