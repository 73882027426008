import { LanguageService } from 'app/shared/services/language.service';
import { OptionResolver } from '../components/card-select/option.resolver';
import { StockType } from '../enums/stock-type';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faContainerStorage,
  faGlobe,
  faMapMarkedAlt,
  faPersonCarry,
  faTruckRampBox,
  faWarehouse
} from '@fortawesome/pro-duotone-svg-icons';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockType'
})
export class StockTypeResolver implements OptionResolver, PipeTransform {
  private translationKeys = {
    [StockType.PERSON]: 'shared.pipes.stockType.person',
    [StockType.CONTAINER]: 'shared.pipes.stockType.container',
    [StockType.POSITION]: 'general.location.s',
    [StockType.VEHICLE]: 'shared.pipes.stockType.vehicle',
    [StockType.STOCK]: 'general.stock.s',
    [StockType.MAIN]: 'shared.pipes.stockType.mainStock',
  }

  constructor(private languageService: LanguageService) {}

  public resolveName(option: string): string {
    return this.translate(this.translationKeys[option] || 'general.unknown');
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case StockType.PERSON:
        return faPersonCarry;
      case StockType.CONTAINER:
        return faContainerStorage;
      case StockType.POSITION:
        return faMapMarkedAlt;
      case StockType.VEHICLE:
        return faTruckRampBox;
      case StockType.STOCK:
        return faWarehouse;
      case StockType.MAIN:
        return faGlobe;
      default:
        return null;
    }
  }

  public transform(value: any): any {
    return this.resolveName(value);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
