import {Pipe, PipeTransform} from '@angular/core';
import {EquipmentHistoryColumn} from 'app/modules/equipment/contract/equipment-history-column.enum';
import {LanguageService} from 'app/shared/services/language.service';
import {ConstructionYearFormatPipe} from '../../../../shared/pipes/construction-year.pipe';
import {EquipmentTelematicsUnitTypeResolver} from '../../../../shared/services/equipment-telematics-unit-type.resolver';
import {KeyValuePair} from '../../../../contract/key-value-pair';
import {TimezoneDatesService} from '../../../../../../shared/timezone/timezone-dates.service';

@Pipe({
  name: 'equipmentHistoryValueFormat'
})
export class EquipmentHistoryValueFormatPipe implements PipeTransform {
  private datePartFormat = 'DD MMM YYYY';
  constructor(private languageService: LanguageService,
              private timezoneDatesService: TimezoneDatesService,
              private constructionYearPipe: ConstructionYearFormatPipe,
              private equipmentTelematicsUnitTypeResolver: EquipmentTelematicsUnitTypeResolver) {
  }

  public transform(historyFieldValue: string, historyField: EquipmentHistoryColumn, type?: string): string {
    switch (historyField) {
      case EquipmentHistoryColumn.EQUIPMENT_DELIVERY_DATE:
      case EquipmentHistoryColumn.REGISTRATION_DATE:
        return this.timezoneDatesService.timeZonedTransform(historyFieldValue, this.datePartFormat,
          this.getCurrentLocale());
      case EquipmentHistoryColumn.EQUIPMENT_CONSTRUCTION_YEAR:
        return this.constructionYearPipe.transform(historyFieldValue);
      case EquipmentHistoryColumn.TELEMATICS_UNIT_ID:
        return this.equipmentTelematicsUnitTypeResolver.getTelematicsUnitId({key: type, value: historyFieldValue} as KeyValuePair);
      case EquipmentHistoryColumn.TELEMATICS_UNIT_TYPE:
        return this.equipmentTelematicsUnitTypeResolver.resolveName(historyFieldValue);
      default:
        return historyFieldValue;
    }
  }

  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
