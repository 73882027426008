import { TransferItemType } from '../../../../shared/transfer/model/transfer-item-type';
import { TypeOfUseStatus } from '../../../../shared/enums/type-of-use-status.enum';
import { TypeOfUseStatusReason } from '../../../../shared/enums/type-of-use-status-reason.enum';

export interface ISearchProjectTypeOfUseEntry {
  id: string;
  projectId: string;
  transferItemId: string;
  transferItemName: string;
  transferItemInternalNumber: string;
  transferItemType: TransferItemType;
  status: TypeOfUseStatus;
  reason: TypeOfUseStatusReason;
  statusStartDate: Date;
  statusEndDate: Date;
}

export enum ChangeTypeOfUseKey {
  Id = 'id',
  ProjectId = 'projectId',
  TransferItemId = 'transferItemId',
  TransferItemName = 'transferItemName',
  TransferItemInternalNumber = 'transferItemInternalNumber',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  TransferItemType = 'transferItemType',
  Status = 'status',
  Reason = 'reason',
  StatusStartDate = 'statusStartDate',
  Separator = 'separator',
  StatusEndDate = 'statusEndDate',
  Edit = 'edit'
}

export interface IHeaderCell {
  label: string;
  sortable: boolean;
  width: string;
  show: boolean;
}
