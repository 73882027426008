import { Injectable } from '@angular/core';
import { TelematicsUnitTypeAssignment } from '../../equipment/contract/telematics-unit-type-assignment.enum';
import { LanguageService } from '../../../shared/services/language.service';

@Injectable()
export class TelematicUnitsAdministrationResolver {

  constructor(private languageService: LanguageService) { }

  public resolveName(option: string): string {
    switch (option) {
      case TelematicsUnitTypeAssignment.AEMP:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.iso');
      case TelematicsUnitTypeAssignment.GENERIC_2:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.aemp');
      case TelematicsUnitTypeAssignment.GENERIC_3:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.goldfunk');
      case TelematicsUnitTypeAssignment.GENERIC_4:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.sinos');
      case TelematicsUnitTypeAssignment.GENERIC_5:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.twinline');
      case TelematicsUnitTypeAssignment.SIGFOX_TRACKERTYPL26_1:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.sigfox');
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW:
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.trackunit');
      case TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.cableUnit');
      case TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.obdUnit');
      case TelematicsUnitTypeAssignment.GPS_OVER_IP:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.gps');
      case TelematicsUnitTypeAssignment.CONFIDEX_CLASSIC:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexClassic');
      case TelematicsUnitTypeAssignment.CONFIDEX_ROUGH:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexTough');
      case TelematicsUnitTypeAssignment.CONFIDEX_MICRO:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexMicro');
    }

    return option;
  }
}
