import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { TypeOfUseStatus } from '../enums/type-of-use-status.enum';

@Pipe({
  name: 'typeOfUseStatus'
})

export class TypeOfUseStatusPipe implements PipeTransform {

  constructor (protected translateService: LanguageService) {}

  public transform (value: any): string {
    switch (value) {
      case TypeOfUseStatus.STANDSTILL:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.standstill');
      case TypeOfUseStatus.FREE_OF_CHARGE:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.freeOfCharge');
      case TypeOfUseStatus.IN_USE:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.inUse');
      case TypeOfUseStatus.READY_TO_PICKUP:
        return this.translateService.getInstant('modules.disposition.assetChangeTypeOfUse.readyToPickup');
      default:
        return '';
    }
  }
}
