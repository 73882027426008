import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHttpParameters } from 'app/shared/utils';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ViewAssignableEmployee } from '../../contract/view-assignable-employee';

@Injectable({
  providedIn: 'root'
})

export class EmployeeService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/employees';

  constructor(private http: HttpClient) { }

  public getAssignableEmployees(searchTerm?: string): Observable<ViewAssignableEmployee[]> {
    let params = {
      searchTerm: searchTerm
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<ViewAssignableEmployee[]>(this.url + '/assignable-employees', {params: httpParams});
  }
}
