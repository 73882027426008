import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateProjectCommentCommand } from '../../../../../contract/create-project-comment-command';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { ViewProjectComment } from '../../../../../contract/view-project-comment.interface';
import { UpdateProjectCommentCommand } from '../../../../../contract/update-project-comment-command';
import { MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { FieldLimit } from '../../../../../../../shared/enums/fieldLimit.enum';

@UntilDestroy()
@Component({
  selector: 'bh-add-edit-comment-dialog',
  templateUrl: './add-edit-comment-dialog.component.html',
  styleUrls: ['./add-edit-comment-dialog.component.scss']
})
export class AddEditCommentDialogComponent implements OnInit {

  public readonly fieldLimit = FieldLimit;
  public commentsForm: UntypedFormGroup;
  public dialogRef: MatDialogRef<AddEditCommentDialogComponent>;
  public comment: ViewProjectComment;

  constructor(public projectStore: ProjectDataSource,
              protected formBuilder: UntypedFormBuilder) { }

  public ngOnInit(): void {
    this.buildForm();
    if (this.comment) {
      this.setFormValues();
    }
  }

  public save(): void {
    if (this.isValid()) {
      if (this.commentsForm.valid) {
        this.comment ? this.updateComment() : this.saveComment();
      }
    }
  }

  public isValid(): boolean {
    return this.commentsForm.valid;
  }

  private saveComment(): void {
    let formData = this.commentsForm.getRawValue();
    let cmd: CreateProjectCommentCommand = new CreateProjectCommentCommand();

    cmd.text = formData.comment;
    cmd.projectId = this.project.projectId;

    this.projectStore.addComment(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
        this.dialogRef.close();
      }
    );
  }

  private updateComment(): void {
    let formData = this.commentsForm.getRawValue();
    let cmd: UpdateProjectCommentCommand = new UpdateProjectCommentCommand();

    cmd.text = formData.comment;
    cmd.projectId = this.project.projectId;
    cmd.commentId = this.comment.commentId;

    this.projectStore.updateComment(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
        this.dialogRef.close();
      }
    );
  }

  private buildForm(): void {
    this.commentsForm = this.formBuilder.group({
      comment: ['', [<any>Validators.required]]
    });
  }

  private setFormValues(): void {
    this.commentsForm.patchValue({
      comment: this.comment.text,
    });
  }

  get project(): ViewProject {
    return this.projectStore.project();
  }

}
