<div *ngIf="(data | chartData : strategy) as transformData" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch">
  <bh-subtitle [text]="'modules.equipment.telematics.historyOperatingHours'|translate"></bh-subtitle>
  <div class="chart-container">
    <canvas baseChart
          [type]="type"
          [data]="transformData"
          [options]="options">
    </canvas>
  </div>
</div>
