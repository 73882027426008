import { Injectable } from '@angular/core';
import { MaintenanceTypeFieldService } from './maintenance-type-field.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceTypeField } from '../../shared/contract/maintenance-type-field.interface';

@Injectable()
export class MaintenanceTypeFieldStore {

  private maintenanceTypeFieldsSubject: Subject<MaintenanceTypeField[]> = new Subject();
  private filterTermSubject: BehaviorSubject<string> = new BehaviorSubject('');

  public readonly availableMaintenanceTypeFields: Observable<MaintenanceTypeField[]> = this.maintenanceTypeFieldsSubject.asObservable();
  public readonly filteredMaintenanceTypeFields: Observable<MaintenanceTypeField[]> = combineLatest(
      this.maintenanceTypeFieldsSubject,
      this.filterTermSubject
  )
  .pipe(
      map(([types, filterTerm]) =>
          types.filter((field: MaintenanceTypeField) => {
            const searchTerm = (field.name).toLowerCase();
            return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
          })));

  constructor(private maintenanceTypeFieldService: MaintenanceTypeFieldService) {
  }

  loadTypeFields(): void {
    this.maintenanceTypeFieldService.getAll()
      .subscribe((typeFields: MaintenanceTypeField[]) => this.maintenanceTypeFieldsSubject.next(typeFields));
  }

  filterTypeFieldsBy(filterTerm: string): void {
    this.filterTermSubject.next(filterTerm);
  }
}
