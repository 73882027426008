import { Pipe, PipeTransform } from '@angular/core';
import { IntervalsDurationEnum } from '../../rules/shared/intervals-duration.enum';
import { LanguageService } from '../../../../shared/services/language.service';

@Pipe({
  name: 'intervalDurationTranslation'
})
export class IntervalDurationTranslationPipe implements PipeTransform {
  private readonly translationKeys = {
    [IntervalsDurationEnum.HOURS]: 'general.units.time.hour.pl',
    [IntervalsDurationEnum.DAYS]: 'general.units.time.day.pl',
    [IntervalsDurationEnum.MONTHS]: 'general.units.time.month.pl',
    [IntervalsDurationEnum.YEARS]: 'general.units.time.year.pl'
  }

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
