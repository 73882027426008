import { TransportTaskState } from '../enums/transport-task-status.enum';
import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transportState'
})
export class TransportStatePipe implements PipeTransform {
  private definitionSet = {
    [TransportTaskState.DRAFT]: this.translate('modules.transportation.transportState.draft'),
    [TransportTaskState.RETURNED]: this.translate('modules.transportation.transportState.returned'),
    [TransportTaskState.PLANNABLE]: this.translate('modules.transportation.transportState.plannable'),
    [TransportTaskState.PLANNED]: this.translate('modules.transportation.transportState.planned'),
    [TransportTaskState.IN_PROGRESS]: this.translate('modules.transportation.transportState.inProgress'),
    [TransportTaskState.DONE]: this.translate('modules.transportation.transportState.done'),
    [TransportTaskState.ARCHIVED]: this.translate('modules.transportation.transportState.archived'),
    [TransportTaskState.CANCELED]: this.translate('modules.transportation.transportState.canceled'),
    [TransportTaskState.OPEN]: this.translate('modules.transportation.transportState.open'),
    [TransportTaskState.ACTIVE]: this.translate('modules.transportation.transportState.active'),
    [TransportTaskState.FINISHED]: this.translate('modules.transportation.transportState.finished'),
  }

  constructor(private languageService: LanguageService) {
  }

  public transform(value: TransportTaskState | string): string {
    return (!this.isDeprecatedStatus(value) && this.definitionSet[value])
      || this.translate('general.unknown');
  }

  private isDeprecatedStatus(value: TransportTaskState | string): boolean {
    return value === TransportTaskState.NEW || value === TransportTaskState.POSTPONED;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
