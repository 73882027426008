import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { EquipmentTypesService } from 'app/modules/equipment/shared/equipmenttypes.service';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { GroupedEquipmentStatusCount } from 'app/shared/contract/grouped-equipment-status-count.interface';
import { BehaviorSubject, skip, take, zip } from 'rxjs';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { EquipmentCountByStatusCommand } from 'app/modules/equipment/contract/equipment-count-by-status.command';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { EquipmentStatusGridsterItem } from '../contract/equipment-status-gridster-item.interface';
import { GroupedEquipmentType } from 'app/shared/contract/grouped-equipment-type.interface';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';


@Injectable()
export class EquipmentStatusWidgetDatasource {

  private _availableOrganisations = new BehaviorSubject<ViewOrganisation[]>([]);
  private _availableEquipmentTypeCategories = new BehaviorSubject<GroupedEquipmentType[]>([]);
  private _equipmentStatuses = new BehaviorSubject<GroupedEquipmentStatusCount[]>([]);
  public readonly availableOrganisations = this._availableOrganisations.asObservable();
  public readonly availableEquipmentTypeCategories = this._availableEquipmentTypeCategories.asObservable();
  public readonly equipmentStatuses = this._equipmentStatuses.asObservable();

  private selectedOrganisationIds: string[] = [];
  private selectedEquipmentTypeIds: string[] = [];
  private groupedTypes: EquipmentTypeFilterView[] = [];

  constructor(
    private equipmentsDataSource: EquipmentsDataSource,
    private equipmentsService: EquipmentsService,
    private equipmentTypesService: EquipmentTypesService,
    private authService: KeycloakService,
    private translatableStringResolver: TranslatableStringPipe
  ) { }

  public initWidgetData(widget: EquipmentStatusGridsterItem): void {
    this._equipmentStatuses.next([]);
    zip([
      this._availableOrganisations.pipe(skip(1), take(1)),
      this._availableEquipmentTypeCategories.pipe(skip(1), take(1)),
    ])
    .pipe(take(1))
    .subscribe(() => this.loadWidgetData(widget));

    this.loadAvailableOrganisations();
    this.loadAvailableEquipmentTypeCategories();
  }

  public loadWidgetData({ organisationIds, equipmentTypeCategories }: EquipmentStatusGridsterItem): void {
    this.selectedOrganisationIds = organisationIds;
    this.selectedEquipmentTypeIds = this.getCategoriesSubTypeIds(equipmentTypeCategories);
    this.loadEquipmentStatuses({
      organisationIds: this.selectedOrganisationIds,
      equipmentTypeIds: this.selectedEquipmentTypeIds,
    });
  }

  public navigateToEquipmentList(equipmentStatusId: string): void {
    this.equipmentsDataSource.navigateToFilteredEquipment(
      [
        ...this.getFilterUpdateCommands(FilterTypes.EQUIPMENT_STATE, [equipmentStatusId]),
        ...this.getFilterUpdateCommands(FilterTypes.ORGANISATION, this.selectedOrganisationIds),
        ...this.getFilterUpdateCommands(FilterTypes.EQUIPMENT_CLASS, this.selectedEquipmentTypeIds),
      ].filter(Boolean)
    );
  }

  private getCategoriesSubTypeIds(equipmentTypeCategories: string[]): string[] {
    const categoriesSet = new Set(equipmentTypeCategories);
    return this.groupedTypes.reduce(
      (acc, category) => {
        return [
          ...acc,
          ...(categoriesSet.has(category.category1) ? category.subTypes.map(t => t.equipmentTypeId) : [])
        ]
      }, []);
  }

  private loadAvailableOrganisations(): void {
    this.equipmentsService.getOrganisationsTreeByCustomer(this.authService.getUserCustomerId())
      .subscribe(orgs => this._availableOrganisations.next(orgs));
  }

  private loadAvailableEquipmentTypeCategories(): void {
    this.equipmentTypesService.getAllEquipmentTypesGroupedByCategory1()
      .subscribe(groupedTypes => {
        this.groupedTypes = groupedTypes;
        this._availableEquipmentTypeCategories.next(groupedTypes.map(group => ({
          ...group,
          category1TranslatedName: this.translatableStringResolver.transform(group.category1Name),
        })));
      });
  }

  private loadEquipmentStatuses(cmd: EquipmentCountByStatusCommand): void {
    this.equipmentsService.countsEquipmentByStatus(cmd)
      .subscribe(data => this._equipmentStatuses.next(data));
  }

  private getFilterUpdateCommands(filterType: FilterTypes, ids: string[]): UpdateFilterCommand[] {
    return (ids || []).map(storeName => ({ filterType, storeName, newValue: true }));
  }
}
