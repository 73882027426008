import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterPipe } from './search-filter.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { DamageTypeDisplayPipe } from './damage-type.pipe';
import { AlarmTypePipe } from './alarm-type.pipe';
import { EquipmentNamePipe } from './equipment-name.pipe';
import { DefaultValuePipe } from './default-value.pipe';
import { AlarmSelectionTypePipe } from './alarm-selection-type.pipe';
import { AssetTypePipe } from './asset-type.pipe';
import { EquipmentEmployeeRolePipe } from './equipment-employee-role.pipe';
import { AddressStreetPipe } from './address-street.pipe';
import { AddressCityPipe } from './address-city.pipe';
import { TeamMemberSortPipe } from './team-members-sort.pipe';
import { QualificationPipe } from './qualification.pipe';
import { FormatDurationPipe } from './format-date/format-duration.pipe';
import { SearchFilterSortingPipe } from './search-filter-sorting.pipe';
import { CountryNamePipe } from './country-name.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { EquipmentStatusNamePipe } from './equipment-status-name.pipe';
import { EquipmentStatusCategoryPipe } from './equipment-status-category.pipe';
import { EquipmentLocationTypePipe } from '../../modules/maintenance/shared/pipes/equipment-location-type.pipe';
import { VoltagePipe } from './voltage.pipe';
import { FilterSingleOptionDisplayNamePipe } from './filter-single-option-display-name.pipe';
import { MaintenanceTypeFieldTranslationPipe } from './maintenance-type-field-translation.pipe';
import { UserStatusPipe } from './user-status.pipe';
import { IsNilPipe } from './is-nil.pipe';
import { SubmitterInfoPipe } from './submitter-info.pipe';
import { DueDateRemainingValuePipe } from './due-date-remaining-value.pipe';
import { DueDatePipe } from './due-date.pipe';
import { IntervalDurationTranslationPipe } from '../../modules/maintenance/shared/pipes/interval-duration-resolver.pipe';
import { EquipmentLocationTypeResolverPipe } from 'app/modules/maintenance/shared/pipes/equipment-location-type-resolver.pipe';
import { IsAllowedDynamicField } from './is-allowed-dynamic-field.pipe';
import { ChartDataPipe } from './chart/chart-data.pipe';
import { AddressPipe } from './address.pipe';
import { ConcatArraysPipe } from './concat-arrays.pipe';
import { JoinPipe } from './join.pipe';
import { TransferTypePipe } from './transfer-type.pipe';
import { ChargeTypePipe } from './charge-type.pipe';
import { ItemTypePipe } from './item-type.pipe';
import { FullNamePipe } from './full-name.pipe';
import { DimensionSystemPipe } from './dimension-system.pipe';
import { DimensionUnitConverterPipe } from './dimension-unit-converter.pipe';
import { DimensionUnitPipe } from './dimension-unit.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SearchFilterPipe,
    MonthNamePipe,
    DamageTypeDisplayPipe,
    AlarmTypePipe,
    AssetTypePipe,
    EquipmentNamePipe,
    DefaultValuePipe,
    AlarmSelectionTypePipe,
    IsAllowedDynamicField,
    EquipmentEmployeeRolePipe,
    AddressStreetPipe,
    AddressCityPipe,
    TeamMemberSortPipe,
    QualificationPipe,
    FormatDurationPipe,
    SearchFilterSortingPipe,
    CountryNamePipe,
    SafeUrlPipe,
    EquipmentStatusCategoryPipe,
    EquipmentStatusNamePipe,
    EquipmentLocationTypePipe,
    EquipmentLocationTypeResolverPipe,
    VoltagePipe,
    FilterSingleOptionDisplayNamePipe,
    MaintenanceTypeFieldTranslationPipe,
    UserStatusPipe,
    IsNilPipe,
    SubmitterInfoPipe,
    DueDateRemainingValuePipe,
    DueDatePipe,
    IntervalDurationTranslationPipe,
    ChartDataPipe,
    AddressPipe,
    ConcatArraysPipe,
    JoinPipe,
    TransferTypePipe,
    ChargeTypePipe,
    ItemTypePipe,
    FullNamePipe,
    DimensionSystemPipe,
    DimensionUnitConverterPipe,
    DimensionUnitPipe,
  ],
  exports: [
    SearchFilterPipe,
    MonthNamePipe,
    DamageTypeDisplayPipe,
    AlarmTypePipe,
    AssetTypePipe,
    EquipmentNamePipe,
    DefaultValuePipe,
    AlarmSelectionTypePipe,
    IsAllowedDynamicField,
    EquipmentEmployeeRolePipe,
    AddressStreetPipe,
    AddressCityPipe,
    TeamMemberSortPipe,
    QualificationPipe,
    FormatDurationPipe,
    SearchFilterSortingPipe,
    SafeUrlPipe,
    CountryNamePipe,
    EquipmentStatusCategoryPipe,
    EquipmentStatusNamePipe,
    EquipmentLocationTypePipe,
    EquipmentLocationTypeResolverPipe,
    VoltagePipe,
    MaintenanceTypeFieldTranslationPipe,
    UserStatusPipe,
    IsNilPipe,
    SubmitterInfoPipe,
    DueDateRemainingValuePipe,
    DueDatePipe,
    IntervalDurationTranslationPipe,
    ChartDataPipe,
    AddressPipe,
    ConcatArraysPipe,
    JoinPipe,
    TransferTypePipe,
    ChargeTypePipe,
    ItemTypePipe,
    FullNamePipe,
    DimensionSystemPipe,
    DimensionUnitConverterPipe,
    DimensionUnitPipe,
  ],
  providers: [
    AlarmTypePipe,
    EquipmentEmployeeRolePipe,
    EquipmentStatusCategoryPipe,
    SearchFilterSortingPipe,
    EquipmentLocationTypePipe,
    EquipmentLocationTypeResolverPipe,
    VoltagePipe,
    EquipmentStatusNamePipe,
    FilterSingleOptionDisplayNamePipe,
    MaintenanceTypeFieldTranslationPipe,
    UserStatusPipe,
    DueDatePipe,
    DueDateRemainingValuePipe,
    IntervalDurationTranslationPipe,
    DimensionUnitConverterPipe,
    DimensionUnitPipe,
    AddressPipe,
  ]
})
export class MainPipeModule {
}
