import { Point } from './geolocation/point';
import { DispositionProject } from '../modules/disposition/shared/disposition-project';
import { ViewStock } from '../modules/disposition/contract/view-stock';
import { DispositionStock } from '../modules/disposition/shared/disposition-stock';

export function groupBy<T, K extends keyof T>(list: Array<T>, fieldName: K): Map<string, T[]> {
  return list.reduce((groups: Map<string, T[]>, item: T) => {
    const key = item[fieldName].toString();
    const group = groups.has(key) ? groups.get(key) : [];
    group.push(item);
    groups.set(key, group);
    return groups;
  }, new Map<string, T[]>());
}

export function filterByTerm<T, K extends keyof  T>(array: T[], filterTerm: string, fieldName?: K): T[] {
  return array.filter((item: T) => {
    if (fieldName && !item[fieldName]) {
      return false;
    } else {
      const searchTerm = (fieldName ? item[fieldName] : item).toString().toLowerCase();
      return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
    }
  });
}

export function filterMultipleFieldsByTerm<T, K extends keyof  T>(array: any, filterTerm: string, fieldName?: any[]): any {
  const newArray = new Set();
  let searchTerm: string;
  fieldName.forEach(field => {
    array.forEach((item: any) => {
      if (Array.isArray(field)) {
        searchTerm = (field && item[field[0]] && item[field[0]][field[1]] ? item[field[0]][field[1]] : item).toString().toLowerCase();
      } else {
        searchTerm = (field && item[field] ? item[field] : item).toString().toLowerCase();
      }
      if (!filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1) {
        newArray.add(item);
      }
    })
  })

  return newArray;
}


export function byLocationClosestToPosition(position: Point) {
  return (itemA: DispositionProject | DispositionStock, itemB: DispositionProject | DispositionStock) => {
    if (itemA.location === null && itemB.location) {
      return 1;
    }

    if (itemB.location === null && itemA.location) {
      return -1;
    }

    if (itemA.location === null && itemB.location === null) {
      if (isStock(itemA) && isStock(itemB)) {
        return itemA.stockName.localeCompare(itemB.stockName);
      } else if (isProject(itemA) && isProject(itemB)) {
          return itemA.projectName.localeCompare(itemB.projectName);
      }
    }

    return position.distanceTo(Point.fromLatLon(itemA.location)) - position.distanceTo(Point.fromLatLon(itemB.location));
  };
}

function isStock(location: Object): location is ViewStock {
  return (location as ViewStock).stockName !== undefined;
}

function isProject(location: Object): location is DispositionProject {
  return (location as DispositionProject).projectName !== undefined;
}
