<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="data-table-wrapper" fxFlex>
        <mat-table class="pagination-data-table" [dataSource]="subCustomers">

          <ng-container cdkColumnDef="customerName">
            <mat-header-cell *cdkHeaderCellDef fxFlex="45">{{'general.name.s'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="45" class="tree-header">
              <mat-icon *ngIf="row.level > 0"
                        class="sub-customer-icon"
                        [style.marginLeft.px]="row.level * 22">
                subdirectory_arrow_right
              </mat-icon>
              <div>{{row.customerName}}</div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerNumber">
            <mat-header-cell *cdkHeaderCellDef>{{'general.units.number'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <span class="customer-number">
                {{row.customerNumber }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerDetails">
            <mat-header-cell *cdkHeaderCellDef fxLayoutAlign="end stretch"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxLayoutAlign="end center">
              <button mat-button
                      [matTooltip]="'modules.organisation.base.addSubCustomer'|translate"
                      *ngIf="hasAuthority(authorities.SUB_CUSTOMER_MANAGE) && userCanCreate(row)"
                      (click)="navigateToAdd('add', {parentCustomerId: row.customerId})">
                <mat-icon>add_circle</mat-icon>
              </button>
              <button mat-button
                      [matTooltip]="'modules.organisation.base.editCustomer'|translate"
                      (click)="navigateToEditSubCustomer(row.customerId)"
                      *ngIf="hasAuthority(authorities.SUB_CUSTOMER_MANAGE)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-button
                      [matTooltip]="'modules.organisation.base.moveSubCustomer'|translate"
                      (click)="moveCustomer(row)"
                      *ngIf="hasAuthority(authorities.SUB_CUSTOMER_MANAGE) && userCanMove(row)">
                <mat-icon>import_export</mat-icon>
              </button>
              <button mat-button
                      *ngIf="hasAuthority(authorities.SUB_CUSTOMER_MANAGE) && userCanDelete(row) "
                      (click)="deleteCustomer(row)"
                      [matTooltip]="'modules.organisation.base.deleteCustomer'|translate">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

