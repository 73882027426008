<div class="employee-home-details" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">

  <div class="employee-view-home-detail__content" fxFlex="65" fxLayout="column" fxLayoutAlign="start stretch">

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.firstName'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeFirstName}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'general.lastName'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeName}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'modules.disposition.employeePrivateData.phonePrivate'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeHomePhoneNumber}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex floatLabel="always">
        <mat-label>{{'modules.disposition.employeePrivateData.mobilePhonePrivate'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeHomeMobileNumber}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex="75" floatLabel="always">
        <mat-label>{{'general.address.street'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeAddress?.street}}">
      </mat-form-field>
      <mat-form-field fxFlex="25" floatLabel="always">
        <mat-label>{{'general.address.houseNumber'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeAddress?.streetNumber}}">
      </mat-form-field>
    </div>

    <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
      <mat-form-field fxFlex="25" floatLabel="always">
        <mat-label>{{'general.address.zipcode'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeAddress?.postalCode}}">
      </mat-form-field>
      <mat-form-field fxFlex="75" floatLabel="always">
        <mat-label>{{'general.address.city'|translate}}</mat-label>
        <input matInput readonly value="{{employee?.employeeAddress?.city}}">
      </mat-form-field>
    </div>
  </div>

  <bh-map-locations
    class="employee-view-home-detail__map"
    fxFlex="35"
    fxLayout="column"
    [locations]="[employee] | mapMarkerConvertEmployeeView">
  </bh-map-locations>

</div>
