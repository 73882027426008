<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="maintenance-card entity-creation" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="card-title-panel" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="card-title-icon maintenance-list-icon">assignment_turned_in</mat-icon>
        <span class="maintenance-card-title">{{taskInfo.equipmentName}} <span class="rule-name">{{taskInfo.task.name}}</span></span>
      </div>
      <div class="entity-creation-actions">
        <button color="primary"
                class="create-button"
                bhTimeoutSaveButton
                mat-raised-button
                [disabled]="!formIsReady || !form || form.invalid || (completed && (form.pristine && !documentsChanged))"
                (throttledClick)="save()">
          {{(completed ? 'general.buttons.saveAndClose' : 'modules.maintenance.task.completeInspection')|translate}}
        </button>
        <button mat-raised-button (click)="navigateBack()">{{'general.buttons.cancel'|translate}}</button>
        <button *ngIf="!completed" mat-raised-button (click)="form.reset()">
          {{'modules.maintenance.task.resetEntries'|translate}}
        </button>
      </div>
      <div fxFlex class="content">
        <form [formGroup]="form" class="entity-creation-form">
          <p class="quiet">{{taskInfo.ruleDescription}}</p>
          <div fxLayout="row" class="field-columns" fxLayoutGap="25px">
            <section fxFlex="40">
              <div fxLayout="column" fxLayoutGap="10px">

                <bh-title [text]="'modules.maintenance.task.completion'|translate"></bh-title>
                <mat-form-field fxFlex="20">
                  <mat-label>{{'general.labels.performedOutOn'|translate}}</mat-label>
                  <input matInput
                         autocomplete="off"
                         bhInputAllowedCharacters="[0-9./]"
                         [matDatepicker]="picker"
                         [max]="today"
                         [min]="addOneDay(latestCompletionDate) || defaultMinDate"
                         formControlName="completedAtDate"
                         [required]="true">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="completedAtDateControl.errors as errors">
                    <span *ngIf="errors.isValidDateInstance || (errors.matDatepickerMin && !errors.inputDateNotBefore)">
                      {{'shared.validation.date.invalidDate'|translate}}
                    </span>
                    <span *ngIf="errors.futureDateSelected as errorMessage">{{errorMessage}}</span>
                    <span *ngIf="errors.inputDateNotBefore" data-osp-test="error-current-completion-after-last-one">
                      {{ 'modules.maintenance.task.validationShouldBeAfterLastCompletionDate'|translate }}
                      ({{ latestCompletionDate | date : 'dd.MM.yyyy' }})
                    </span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="20" floatLabel="always" *ngIf="nextDueDateParams.isVisible">
                  <mat-label>{{'modules.maintenance.task.nextDueDate'|translate}}</mat-label>
                  <input matInput
                    autocomplete="off"
                    bhInputAllowedCharacters="[0-9./]"
                    formControlName="nextDueDate"
                    [min]="addOneDay(completedAtDateControl.value)"
                    [placeholder]="(nextDueDateParams.isLastIteration
                      ? 'modules.maintenance.task.nextDueDatePlaceholderNoNextDueDate'
                      : 'modules.maintenance.task.nextDueDatePlaceholderCalculated')|translate"
                    [matDatepicker]="picker"
                    bhEmptyOrValidDate
                    data-osp-test="input-next-due-date">
                  <mat-datepicker-toggle matSuffix [for]="picker" data-osp-test="toggle-next-due-date-picker"></mat-datepicker-toggle>
                  <button
                    matSuffix mat-icon-button
                    (click)="nextDueDateControl.setValue(null)"
                    [disabled]="nextDueDateParams.isDisabled"
                    data-osp-test="icon-button-next-due-date-clear">
                    <mat-icon class="suffix-icon-themed">clear</mat-icon>
                  </button>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="nextDueDateControl.errors?.isValidDateInstance as errorMessage">
                    {{ errorMessage }}
                  </mat-error>
                  <mat-error *ngIf="nextDueDateControl.errors?.inputDateNotBefore" data-osp-test="error-next-due-date-after-completion">
                    {{ 'modules.maintenance.task.validationShouldBeAfterCompletionDate'|translate }}
                    {{ completedAtDateControl.value ? '(' + (completedAtDateControl.value | date : 'dd.MM.yyyy') + ')' : ''}}
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="20" *ngIf="showCompletedAtMileage">
                  <mat-label>{{'general.labels.performedOutAtMileage'|translate}} ({{ 'km' | dimensionUnitDisplay }})</mat-label>
                  <input matInput
                         type="number"
                         autocomplete="off"
                         onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                         formControlName="completedAtMileage"
                         [required]="ruleHasMileageInterval"
                         [maxLength]="100">
                </mat-form-field>
                <mat-form-field fxFlex="20" *ngIf="showCompletedAtOperatingHours">
                  <mat-label>{{'general.labels.performedOutAtOperatingHours'|translate}}</mat-label>
                  <input matInput
                         type="number"
                         autocomplete="off"
                         onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                         formControlName="completedAtOperatingHours"
                         [required]="ruleHasOperatingHoursInterval"
                         [maxLength]="100">
                </mat-form-field>

                <mat-form-field fxFlex="20" floatLabel="always">
                  <mat-label>{{ 'modules.maintenance.task.result'|translate }}</mat-label>
                  <mat-select #maintenanceResultSelect
                              formControlName="maintenanceResult"
                              [placeholder]="'modules.maintenance.task.resultPlaceholder'|translate"
                              required="true"
                              [disableOptionCentering]="true">
                    <mat-select-trigger *ngIf="!maintenanceResultSelect.empty">
                      <bh-maintenance-result-badge [result]="maintenanceResultSelect.value"></bh-maintenance-result-badge>
                    </mat-select-trigger>
                    <mat-option *ngFor="let maintenanceResult of maintenanceResults" [value]="maintenanceResult">
                      <bh-maintenance-result-badge [result]="maintenanceResult"></bh-maintenance-result-badge>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div *ngIf="this.taskInfo.maintenanceFieldValues.length > 0">
                  <bh-title class="maintenance-type-fields-title" [text]="'general.labels.inspectionForm'|translate"></bh-title>
                  <mat-form-field *ngFor="let field of fieldTypeToFields.get(FieldType.TEXT)">
                    <mat-label>{{field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</mat-label>
                    <input matInput
                           autocomplete="off"
                           [formControlName]="field.maintenanceTypeFieldName"
                           [required]="field.mandatory"
                           [attr.maxLength]="field.maxLength">
                  </mat-form-field>
                    <bh-date-picker-input  *ngFor="let field of fieldTypeToFields.get(FieldType.DATE)"
                      [control]="this.form.get(field.maintenanceTypeFieldName)"
                                          [required]="field.mandatory"
                                          [placeholder]="field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation">
                    </bh-date-picker-input>
                  <mat-form-field *ngFor="let field of fieldTypeToFields.get(FieldType.NUMBER)">
                    <mat-label>{{field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</mat-label>
                    <input matInput
                           type="number"
                           autocomplete="off"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                           [formControlName]="field.maintenanceTypeFieldName"
                           [required]="field.mandatory"
                           [attr.maxLength]="field.maxLength">
                  </mat-form-field>
                  <mat-form-field *ngFor="let field of fieldTypeToFields.get(FieldType.LONG_TEXT)">
                  <mat-label>{{field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</mat-label>
                  <textarea matInput
                            class="long-text"
                            autocomplete="off"
                            [formControlName]="field.maintenanceTypeFieldName"
                            [required]="field.mandatory"
                            [attr.maxLength]="field.maxLength"></textarea>
                  </mat-form-field>
                </div>

              </div>
            </section>

            <section fxFlex="60">
              <bh-title text="{{'general.document.pl'|translate}} {{'general.and'|translate}} {{'general.image.pl'|translate}}"></bh-title>
              <div fxFlexFill fxLayout="row">
                <bh-file-upload [itemId]="taskInfo.task.id"
                                uploaderType="MAINTENANCE_TASK"
                                (onFileUploaded)="onFileUploaded($event)">
                </bh-file-upload >
                <div class="files" fxLayout="column" fxLayoutGap="10px" fxFlex>
                  <bh-file *ngFor="let document of documents" [file]="document" (fileRemove)="removeDocument(document)"></bh-file>
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
