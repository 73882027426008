import { Pipe, PipeTransform } from '@angular/core';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';
import { ViewStock } from 'app/modules/disposition/contract/view-stock';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapItemViewStock } from '../interfaces/map-list-item/map-item-search-view-stock.interface';
import { MapLocationType } from '../enums/map-location-type.enum';

@Pipe({
  name: 'mapMarkersConvertStockLocations'
})
export class MapMarkersConvertStockLocationsPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: ViewStock[], mapSettings: MapConfiguration): MapLocationMarker<MapItemViewStock>[] {
    if (!Array.isArray(value)) {
      return null;
    }

    return value.filter(Boolean).map(item => ({
      id: item.stockId,
      location: item.location,
      icon: this.getUrlIcon(item),
      item: { ...item, locationType: MapLocationType.STOCK },
      label: item[mapSettings?.stockColumn],
    }));
  }

  private getUrlIcon(item: ViewStock): string {
    return this.iconResolver.getIconStock(item);
  }

}
