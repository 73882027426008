import { Component, OnInit } from '@angular/core';
import { MaintenanceRule } from '../shared/contract/maintenance-rule';

@Component({
  selector: 'bh-maintenance-rule-view-dialog',
  templateUrl: './maintenance-rule-view-dialog.component.html',
  styleUrls: ['./maintenance-rule-view-dialog.component.scss']
})
export class MaintenanceRuleViewDialogComponent implements OnInit {

  public rule: MaintenanceRule;
  public nextFixedDateThreshold: string;
  public nextPeriodThreshold: string;
  public nextOperatingHoursThresholdDate: string;
  public nextMileageThresholdDate: string;

  constructor() { }

  ngOnInit() {
  }

}
