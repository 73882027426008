<form (ngSubmit)="onSearchFormSubmit()" [formGroup]="searchForm" fxLayout="column" fxLayoutAlign="start stretch"
      novalidate>

  <div class="disposition-board-employee-search__search-bar">
    <div fxLayout="row">
      <input formControlName="terms" fxFlex [placeholder]="'modules.disposition.base.enterSearchTerm'|translate"/>
      <button mat-icon-button [matTooltip]="'general.buttons.search'|translate">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>

  <div class="disposition-board-employee-search__filter" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <form [formGroup]="filterForm" fxFlexFill fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-form-field>
        <mat-select formControlName="selectedLabels" multiple [placeholder]="'general.label.s'|translate">
          <mat-option *ngFor="let label of labels" [value]="label.name">
            {{label.name}} ({{label.count}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div class="disposition-board-employee-search__availability-filter" fxLayout="row" fxLayoutAlign="start stretch">
    <div (click)="selectFilter(FILTER_ALL)"
         [ngClass]="{'type-filter-selected': FILTER_ALL === currentFilter}">
      {{'general.filterLabels.all'|translate|uppercase}}
    </div>
    <div (click)="selectFilter(FILTER_AVAILABLE)"
         [ngClass]="{'type-filter-selected': FILTER_AVAILABLE === currentFilter}">
      {{'general.filterLabels.available'|translate|uppercase}}
    </div>
    <div (click)="selectFilter(FILTER_UNAVAILABLE)"
         [ngClass]="{'type-filter-selected': FILTER_UNAVAILABLE === currentFilter}">
      {{'general.filterLabels.disposed'|translate|uppercase}}
    </div>
  </div>

  <div *ngIf="currentFilter !== FILTER_ALL" class="disposition-board-employee-search__date-filter" fxLayout="row"
       fxLayoutAlign="start stretch">
    <bh-date-range-select
      [startDateControl]="startDateControl"
      [endDateControl]="endDateControl"
      [resettable]="true">
    </bh-date-range-select>
  </div>

  <div *ngIf="selectedEmployees.length"
       class="disposition-board-employee-search__selection"
       fxLayout="row"
       fxLayoutAlign="space-between center">

    <div class="selection__clear" [matTooltip]="'modules.disposition.base.clearSelection'|translate">
      <mat-icon (click)="deselectAllEmployees()" class="bh-mat-icon">clear</mat-icon>
    </div>

    <div class="selection__total">
      {{ selectedEmployees.length }} {{'modules.disposition.employeeSearch.employeeSelected'|translate}}
    </div>

    <div class="selection__button">
      <button (click)="openDispositionDialog()" fxFlex="40px" mat-button
              [matTooltip]="'modules.disposition.employeeSearch.disposeEmployee'|translate">
        <mat-icon>fast_forward</mat-icon>
      </button>
    </div>
  </div>

</form>

<div class="disposition-board-employee-search__results" fxFlex fxLayout="column" fxLayoutAlign="start stretch">

  <mat-accordion [id]="containerIdentifiers.DISPOSITION_TEAM" *ngIf="employeeDisposition && employeeDisposition.length"
                 cdkDropList
                 cdkDropListSortingDisabled
                 [cdkDropListConnectedTo]="containerIdentifiers.DISPOSITION_SCHEDULER"
                 [cdkDropListData]="employeeDisposition"
                 [cdkDropListDisabled]="!hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
                 multi>
    <mat-expansion-panel *ngFor="let et of employeeTeamStructure"
                         [@.disabled]="!viewChecked"
                         class="employee-expansion-panel"
                         cdkDrag
                         bhPreventExpandAfterDrag
                         [bhDraggableItemType]="dragType.EMPLOYEE"
                         [dropCellClassName]="'dhx_matrix_cell'"
                         [cdkDragData]="et">

      <div class="drag-item-preview" *cdkDragPreview fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container [ngTemplateOutlet]="dragPreview"
                      [ngTemplateOutletContext]="{icon: faUsersClass, title: et.teamName}">
        </ng-container>
      </div>


      <mat-expansion-panel-header collapsedHeight="90px" expandedHeight="90px" class="expansion-panel-team-header">
        <div class="disposition-board-employee-search__results--item" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox
            (change)="onTeamCheckboxToggle(et, $event)"
            (click)="$event.stopPropagation()"
            [checked]="employeeTeamFullySelected(et)"
            [indeterminate]="employeeTeamPartialSelected(et)"
            [disabled]="!hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
            [matTooltipDisabled]="hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
            color="primary"
            [matTooltip]="'modules.disposition.employeeSearch.forbiddenToDispose'|translate">
          </mat-checkbox>

          <div class="employee-team-icon-container">
            <fa-icon [icon]="faUsersClass"></fa-icon>
          </div>

          <div class="title" fxFlex>
            <div class="name-employee-team single-line-ellipsis">
                    <span [matTooltip]="getTeamNameTooltip(et)" class="single-line-ellipsis">
                      {{et.teamName}}
                    </span>
            </div>
            <div class="team-leader-name single-line-ellipsis">
              {{getTeamLeader(et)}}
            </div>
            <div class="number-of-team-members single-line-ellipsis">
              {{'modules.disposition.employeeSearch.teamMembers'|translate}}: {{et.teamMembers.length}}
            </div>
          </div>

        </div>
      </mat-expansion-panel-header>

        <mat-accordion cdkDropList
                       cdkDropListSortingDisabled
                       [cdkDropListConnectedTo]="containerIdentifiers.DISPOSITION_SCHEDULER"
                       [cdkDropListData]="et.teamMembers"
                       [cdkDropListDisabled]="!hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)">
          <mat-expansion-panel *ngFor="let e of et.teamMembers"
                               class="employee-expansion-panel"
                               disabled
                               hideToggle
                               cdkDrag
                               [bhDraggableItemType]="dragType.EMPLOYEE"
                               [dropCellClassName]="'dhx_matrix_cell'"
                               [cdkDragData]="getDragDataEmployee(e)">
            <div class="drag-item-preview"
                 *cdkDragPreview
                 fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="10px">
              <ng-container [ngTemplateOutlet]="dragPreview"
                            [ngTemplateOutletContext]="{
                              imgKey: e.thumbnailImageKey,
                              icon: faUser,
                              title: e.employeeName + ', ' + e.employeeFirstName
                            }">
              </ng-container>
            </div>

            <mat-expansion-panel-header collapsedHeight="90px" expandedHeight="90px"
                                        class="expansion-panel-employee-header">
              <div class="disposition-board-employee-search__results--item" fixLayout="row"
                   fxLayoutAlign="space-between center">
                <mat-checkbox
                  (change)="onEmployeeCheckboxToggle(e, $event)"
                  (click)="$event.stopPropagation()"
                  [checked]="employeeSelected(e)"
                  [disabled]="!hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
                  [matTooltipDisabled]="hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
                  [matTooltip]="'modules.disposition.employeeSearch.forbiddenToDispose'|translate"
                  color="primary">
                </mat-checkbox>

                <div class="employee-team-icon-container">
                  <bh-thumbnail *ngIf="e.thumbnailImageKey" [key]="e.thumbnailImageKey" [size]="ThumbnailSize.XS"></bh-thumbnail>
                  <fa-icon *ngIf="!e.thumbnailImageKey" [icon]="faUser"></fa-icon>
                </div>

                <div class="title" fxFlex>
                  <div class="name-employee-team single-line-ellipsis">
                    <span *ngIf="e.isTeamLeader"
                          class="team-lead-flag"
                          [matTooltip]="'modules.disposition.employeeSearch.employeeIsTeamLeader'|translate">
                      <fa-icon [icon]="faSolidStar"></fa-icon>
                    </span>
                    <span [matTooltip]="getEmployeeNameTooltip(e)" class="single-line-ellipsis">
                      {{e.employeeName}}, {{e.employeeFirstName}}
                    </span>
                    <mat-icon (click)="navigateToEmployee(e.employeeId)"
                              [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.employee.s'|translate}">
                      open_in_new
                    </mat-icon>
                  </div>
                  <div [matTooltip]="getEmployeeOrganisationAddressTooltip(e)"
                       class="number-of-team-members single-line-ellipsis">
                    {{e.organisationAddress.postalCode}} {{e.organisationAddress.city}}
                  </div>
                </div>

              </div>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>

    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion [id]="containerIdentifiers.DISPOSITION_EMPLOYEE" *ngIf="employeeDisposition && employeeDisposition.length" fxFlex
                 cdkDropList
                 cdkDropListSortingDisabled
                 [cdkDropListConnectedTo]="containerIdentifiers.DISPOSITION_SCHEDULER"
                 [cdkDropListData]="employeeDisposition"
                 [cdkDropListDisabled]="!hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)">
    <mat-expansion-panel *ngFor="let e of employeeEmployeeStructure"
                         class="employee-expansion-panel"
                         disabled
                         hideToggle
                         cdkDrag
                         [bhDraggableItemType]="dragType.EMPLOYEE"
                         [dropCellClassName]="'dhx_matrix_cell'"
                         [cdkDragData]="getDragDataEmployee(e)">
      <div class="drag-item-preview" *cdkDragPreview fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container [ngTemplateOutlet]="dragPreview"
                      [ngTemplateOutletContext]="{
                        imgKey: e.thumbnailImageKey,
                        icon: faUser,
                        title: e.employeeName + ', ' + e.employeeFirstName
                      }">
        </ng-container>
      </div>

      <mat-expansion-panel-header collapsedHeight="90px" expandedHeight="90px" class="expansion-panel-team-header">
        <div class="disposition-board-employee-search__results--item" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox
            (change)="onEmployeeCheckboxToggle(e, $event)"
            (click)="$event.stopPropagation()"
            [checked]="employeeSelected(e)"
            [disabled]="!hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
            [matTooltipDisabled]="hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
            color="primary"
            [matTooltip]="'modules.disposition.employeeSearch.forbiddenToDispose'|translate">
          </mat-checkbox>

          <div class="employee-team-icon-container">
            <bh-thumbnail *ngIf="e.thumbnailImageKey" [key]="e.thumbnailImageKey" [size]="ThumbnailSize.XS"></bh-thumbnail>
            <fa-icon *ngIf="!e.thumbnailImageKey" [icon]="faUser"></fa-icon>
          </div>


          <div class="title" fxFlex>
            <div class="name-employee-team single-line-ellipsis">
              <span [matTooltip]="getEmployeeNameTooltip(e)" class="single-line-ellipsis">
                {{e.employeeName}}, {{e.employeeFirstName}}
              </span>
              <mat-icon (click)="navigateToEmployee(e.employeeId)"
                        [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.employee.s'|translate}">
                open_in_new
              </mat-icon>
            </div>
            <div [matTooltip]="getEmployeeOrganisationAddressTooltip(e)"
                 class="number-of-team-members single-line-ellipsis">
              {{e.organisationAddress.postalCode}} {{e.organisationAddress.city}}
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="employeeDisposition && employeeDisposition.length === 0" class="disposition-board-employee-search__no_results"
       fxFlex fxLayout="row" fxLayoutAlign="center center">
    <div>{{'general.labels.noSearchResults'|translate}}</div>
  </div>

</div>


<div class="list-view-main__left-body-paginator" fxFlex="60px">
  <mat-paginator #paginator
                 (page)="onPaginateChange($event)"
                 [length]="employeeDispositionStore.length | async"
                 [pageIndex]="employeeDispositionStore.pagination.index"
                 [pageSizeOptions]="[5, 25, 50, 100]"
                 [pageSize]="employeeDispositionStore.pagination.size">
  </mat-paginator>
</div>

<!-- Drag&Drop preview -->
<ng-template #dragPreview let-imgKey="imgKey" let-icon="icon" let-title="title">
    <div class="drag-item-preview__image-container" fxLayoutAlign="center center">
      <bh-thumbnail *ngIf="imgKey" [key]="imgKey" [size]="ThumbnailSize.XS"></bh-thumbnail>
      <fa-icon *ngIf="!imgKey" [icon]="icon"></fa-icon>
    </div>
    <div class="drag-item-preview__title">{{title}}</div>
</ng-template>
