import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LifeCycleEmployeeAdded } from 'app/modules/equipment/contract/life-cycle-employee-added';
import { LifeCycleEmployeeRemoved } from 'app/modules/equipment/contract/life-cycle-employee-removed';
import { LocationType } from 'app/shared/enums/location-type.enum';
import { StockAssigneeType } from 'app/shared/enums/stock-assignee-type.enum';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddStockResponsibleEmployeeCommand } from '../../contract/add-stock-responsible-employee-command';
import { ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand } from '../../contract/change-stock-responsible-employee-acceptance-flag.command';
import { ViewAssignableEmployee } from '../../contract/view-assignable-employee';
import { ViewStock } from '../../contract/view-stock';
import { ViewStockEmployeeAssignment } from '../../contract/view-stock-employee-assignment.interface';
import { StockStore } from '../../stocks/shared/stock.store';
import { AssigneeTypePipe } from '../pipes/assignee-type.pipe';
import { EmployeeService } from '../services/employee.service';

@UntilDestroy()
@Component({
  selector: 'bh-stock-employee-assignment-manage-dialog',
  templateUrl: './stock-employee-assignment-manage-dialog.component.html',
  styleUrls: ['./stock-employee-assignment-manage-dialog.component.scss']
})
export class StockEmployeeAssignmentManageDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public icon: IconDefinition;
  public assignment: ViewStockEmployeeAssignment;
  public employeeRoleFilter: UntypedFormControl = new UntypedFormControl();
  public employeeFilter: UntypedFormControl = new UntypedFormControl();
  public employees: ViewAssignableEmployee[] = [];
  public editMode: boolean;
  public hasStockTransferRequestWorkflow = true;

  formDisabled = false;
  stockId: string;
  existingEmployeeAssignments: ViewStockEmployeeAssignment[];
  latestLifeCycle: LifeCycleEmployeeAdded | LifeCycleEmployeeRemoved;

  constructor(public dialogRef: MatDialogRef<StockEmployeeAssignmentManageDialogComponent>,
              public assigneeTypePipe: AssigneeTypePipe,
              private stockStore: StockStore,
              private formBuilder: UntypedFormBuilder,
              private employeeService: EmployeeService) {}

  ngOnInit() {
    this.buildForm();
    this.subscribeToStockTransferRequestWorkflow();
    this.subscribeToFormChanges();
    this.subscribeToCurrentStock();
    this.getEmployees();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      employee: [{value: '', disabled: this.editMode}, [<any>Validators.required, this.existedCombinationValidator.bind(this)]],
      employeeRole: [{value: '', disabled: this.editMode}, [<any>Validators.required]],
      isAcceptance: [{value: false, disabled: this.isAcceptanceDisabled}]
    });
    if (this.editMode) {
      this.form.patchValue({
        employee: this.assignment.employeeId,
        employeeRole: this.assignment.assigneeType,
        isAcceptance: this.assignment.isAcceptanceUser
      });
    }
  }

  private subscribeToFormChanges(): void {
    this.filterEmployeesOnInput();
    this.subscribeToRoleChanges();
  }


  private filterEmployeesOnInput(): void {
    this.employeeFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.getEmployees(filterTerm));
  }

  private subscribeToRoleChanges(): void {
    this.employeeRole
    .valueChanges
    .subscribe(() => this.form.controls['employee'].updateValueAndValidity());
  }


  private getEmployees(searchTerm?: string): void {
    this.employeeService.getAssignableEmployees(searchTerm).subscribe(employees => {
      this.employees = employees;
    });
  }

  private subscribeToCurrentStock(): void {
    this.stockStore.selectedStock
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewStock) => {
      if (res) {
        this.stockId = res.stockId;
      }
    });
  }

  public employeeSelected(employeeId: any): void {
    let existedAssignment = this.existingEmployeeAssignments.find(assignment => assignment.employeeId === employeeId);
    if (existedAssignment) {
      this.form.patchValue({isAcceptance: existedAssignment.isAcceptanceUser});
    }
  }

  public save(): void {
    const formData = this.form.getRawValue();
    this.formDisabled = true;
    if (this.editMode && formData.isAcceptance !== this.assignment.isAcceptanceUser) {
      const command: ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand  =
       new ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand (
        this.stockId,
        formData.employee,
        formData.isAcceptance,
      );
      this.stockStore.changeProjectRespnsibleEmployeeAcceptanceUser(command).subscribe(
        res => {
          this.dialogRef.close();
          }, error => {
            this.dialogRef.close();
      });
    } else if (!this.editMode) {
      const command: AddStockResponsibleEmployeeCommand = new AddStockResponsibleEmployeeCommand(
        this.stockId,
        formData.employee,
        formData.employeeRole,
        formData.isAcceptance);
      this.stockStore.addResponsibleEmployee(command).subscribe(
        res => {
          this.dialogRef.close();
          }, error => {
            console.log('error on assign employee to equipment: ', error);
            this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  get employeeRoles(): string[] {
    let employeeRoles = Object.keys(StockAssigneeType);
    if (this.employeeRoleFilter.value) {
      employeeRoles = employeeRoles.filter(employeeRole => this.employeeRoleContainsSearchTerm(employeeRole));
    }
    return employeeRoles;
  }

  private employeeRoleContainsSearchTerm(employeeRole: any): boolean {
    return this.assigneeTypePipe.transform(employeeRole, LocationType.PROJECT).toLocaleLowerCase()
      .includes(this.employeeRoleFilter.value.toLocaleLowerCase());
  }

  private subscribeToStockTransferRequestWorkflow(): void {
    this.stockStore.hasStockTransferRequestWorkflow.subscribe((hasStockTransferRequestWorkflow: boolean) => {
      this.hasStockTransferRequestWorkflow = hasStockTransferRequestWorkflow;
    });
    this.stockStore.updateStockTransferRequestsWorkflow();
  }

  get saveDisabled(): boolean {
    return !this.form.valid ||
     this.formDisabled ||
     (this.assignment && this.form.value['isAcceptance'] === this.assignment.isAcceptanceUser);
  }

  get isAcceptanceDisabled(): boolean {
    return (!this.editMode && this.existingEmployeeAssignments.filter(employee => employee.employeeId === this.employeeId).length > 0);
  }

  get employeeRole(): AbstractControl {
    return this.form?.get('employeeRole');
  }


  get employeeId(): string {
    return this.form?.get('employee').value;
  }

  public existedCombinationValidator(control: UntypedFormControl): string|any {
    if (control.value && this.employeeRole.value && !this.editMode) {
      let assignmentExisted = this.existingEmployeeAssignments.filter(assignment =>
         assignment.employeeId === control.value &&
         this.employeeRole.value === assignment.assigneeType.toString()).length > 0;
      return assignmentExisted ? {'assignmentExisted' : true} : null;
    } else {
      return null;
    }
  }
}
