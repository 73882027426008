import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectDataSource } from '../../../../disposition/shared/project.datasource';
import { Observable } from 'rxjs';
import { DispositionProject } from '../../../../disposition/shared/disposition-project';
import { EquipmentAssignToProjectUtils } from '../base/equipment-assign-to-project-utils';
import { Point } from '../../../../../shared/geolocation/point';
import { LatLon } from '../../../../../shared/geolocation/lat-lon.interface';
import { map } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { byLocationClosestToPosition } from '../../../../../shared/collection-utils';
import { ViewportRuler } from '@angular/cdk/overlay';

@Component({
  selector: 'bh-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss']
})
export class ProjectSelectComponent implements OnInit {

  @Input() position: Point;
  @Output() selected: EventEmitter<string> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  formatAddress = EquipmentAssignToProjectUtils.formatAddress;

  projects: Observable<DispositionProject[]>;

  termControl = new UntypedFormControl();

  constructor(private projectStore: ProjectDataSource,
              public ruler: ViewportRuler) {
  }

  ngOnInit(): void {
    this.termControl.valueChanges.subscribe(term => this.projectStore.filterDispositionProjects(term));
    this.termControl.setValue('');

    this.projects = this.projectStore.filteredDispositionProjects
      .pipe(map(projects => this.sortProjects(projects)));

    this.projectStore.loadDispositionProjects();
  }

  selectProject(projectId: string): void {
    this.selected.emit(projectId);
  }

  distanceToPosition(location: LatLon): number {
    return Math.round(this.position.distanceTo(Point.fromLatLon(location)) / 1000);
  }

  cancel(): void {
    this.reset();
    this.canceled.emit();
  }

  reset(): void {
    this.termControl.reset('')
  }

  private sortProjects(projects: DispositionProject[]): DispositionProject[] {
    if (!this.termControl.value && this.position) {
      return projects.sort(byLocationClosestToPosition(this.position));
    }

    return projects.sort((p1, p2) => p1.projectName.toLocaleLowerCase().localeCompare(p2.projectName));
  }
}
