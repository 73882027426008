import { Component, Input, NgZone, OnInit } from '@angular/core';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';
import { MapLocationsCluster } from '../../../interfaces/map-location-cluster.interface';
import { MapLocationType } from '../../../enums/map-location-type.enum';
import { NavigationMapperService } from 'app/shared/services/navigation-mapper.service';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';
import { AddressPipe } from 'app/shared/pipes/address.pipe';
import { ListItemFactory } from './list-item-factory.class';
import { DisplayService } from 'app/shared/display.service';
import { MapClusterGlobalListItem } from '../../../interfaces/map-list-item/map-cluster-list-item.interface';
import { StockTypeResolver } from 'app/shared/pipes/stock-type.resolver';
import { LanguageService } from 'app/shared/services/language.service';
import { MapClusterItem } from '../../../interfaces/map-cluster-item.interface';


@Component({
  selector: 'bh-map-info-window-cluster-global-list',
  templateUrl: './map-info-window-cluster-global-list.component.html',
  styleUrls: ['./map-info-window-cluster-global-list.component.scss']
})
export class MapInfoWindowClusterGlobalListComponent implements OnInit, InfoWindowComponent {

  @Input() public value: MapLocationsCluster<MapClusterItem>;
  public items: MapClusterGlobalListItem[];
  public LocationType = MapLocationType;
  public listItemHeightPx = 55;

  private navigationMapper = {
    [MapLocationType.EQUIPMENT]: (id: string) => this.navigationMapperService.navigateToEquipmentViewDetails(id),
    [MapLocationType.CONTACT]: (id: string) => this.navigationMapperService.navigateToContactViewDetails(id),
    [MapLocationType.PROJECT]: (id: string) => this.navigationMapperService.navigateToProjectViewDetails(id),
    [MapLocationType.STOCK]: (id: string) => this.navigationMapperService.navigateToStockViewDetails(id),
  }

  private listItemFactory = new ListItemFactory(
    this.contactTypeResolver,
    this.translatableStringResolver,
    this.stockTypeResolver,
    this.addressResolver,
    this.languageService,
  );

  constructor(
    private navigationMapperService: NavigationMapperService,
    private contactTypeResolver: ContactTypeResolverPipe,
    private translatableStringResolver: TranslatableStringPipe,
    private stockTypeResolver: StockTypeResolver,
    private addressResolver: AddressPipe,
    private languageService: LanguageService,
    private displayService: DisplayService,
    private ngZone: NgZone,
  ) {}

  public ngOnInit(): void {
    this.items = this.value ? this.getItems(this.value) : [];
  }

  public navigateToDetails(item: MapClusterGlobalListItem): void {
    if (!(item?.id && this.navigationMapper[item?.type])) {
      return;
    }

    this.ngZone.run(() => {
      this.navigationMapper[item.type](item.id)
        .then(() => this.displayService.displayTableAndDetails());
    });
  }

  public trackByItem(index: number, item: MapClusterGlobalListItem): string {
    return item.id;
  }

  private getItems(value: MapLocationsCluster<MapClusterItem>): MapClusterGlobalListItem[] {
    return value.markers.map(({ item }) => this.listItemFactory.getItem(item));
  }

}
