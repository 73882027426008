import * as moment from 'moment';

export class EquipmentTelematicsDataseriesItem {

  public static fromData(data: EquipmentTelematicsDataseriesItem) {
    return data.series && data.series.length
      ? new EquipmentTelematicsDataseriesItem(data.name, Series.formatSeries(data.series))
      : null;
  }

  constructor(
    public name: string,
    public series: Array<Series>) {}

}

export class Series {

  public static formatSeries(data: Array<Series>) {
    return data.map(series => new Series(series.name, series.value));
  }

  constructor(
    public name: any,
    public value: number) {
    if (name.startsWith('KW ')) {
      this.name = name;
    }
    if (moment(name, 'YYYY-MM', true).isValid()) {
      this.name = moment(name, 'YYYY-MM').format('MMM YYYY');
    }
    if (moment(name, 'DD.MM.YYYY', true).isValid()) {
      this.name = moment(name, 'DD.MM.YYYY').format('DD.MM.YYYY');
    }
    this.value = value;
  }
}
