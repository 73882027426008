import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseProjectListComponent } from '../base/base-project-list.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDataSource } from '../../../shared/project.datasource';
import { CustomDeviceDetectorService } from '../../../../../shared/services/custom-device-detector.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProjectSubscriptionsService } from '../../../shared/project-subscriptions.service';

@Component({
  selector: 'bh-mobile-project-list',
  templateUrl: './mobile-project-list.component.html',
  styleUrls: ['./mobile-project-list.component.scss']
})
export class MobileProjectListComponent extends BaseProjectListComponent implements OnInit, AfterViewInit {

  constructor(protected router: Router,
              protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private deviceService: CustomDeviceDetectorService,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService,
              protected subscriptions: ProjectSubscriptionsService,
              ) {
    super(authService, router, route, routerHistory, projectStore, languageService, subscriptions);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.buildSearchForm();
  }

  public ngAfterViewInit(): void {
    this.onSearchFormType();
    this.handleRouter();
  }

  public onScroll(): void {
    if (!this.projectStore.isLastProject) {
      this.getProjects();
    }
  }

  public setSearchTerms(): void {
    this.searchForm.patchValue({terms: this.projectStore.searchTerms});
  }

  private buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl(this.projectStore.searchTerms)
    });

    this.setSearchTerms();
  }

  public navigateToMap(): void {
    this.router.navigate(['mobile/projects/map']);
  }

  public selectProject(projectId: string): void {
    this.projectStore.setCurrentProject(projectId);
    this.router.navigate([`mobile/projects/view/${projectId}`]);
  }

  public reset(): void {
    this.searchForm.reset();
  }

  private getProjects(): void {
    this.projectStore.pagination.size = this.projectStore.pagination.size + 25;
    this.projectStore.updateListing(0, this.projectStore.pagination.size)
  }

  private handleRouter(): void {
    if (!this.deviceService.isMobile()) {
      this.router.navigate(['projects/list']);
    }
  }
}
