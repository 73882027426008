import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/shared/utils';
import moment from 'moment';
import { TransportView } from '../../contracts/transport/transport-view.interface';

@Pipe({
  name: 'transportEstimatedEnd'
})
export class TransportEstimatedEndPipe implements PipeTransform {
  public transform(transport: TransportView): Date {
    if (!transport) {
      return null;
    }
    const duration = moment.duration(Utils.differenceMs(
      transport?.equipmentAssignmentDetails?.startDate?.timestamp,
      transport?.equipmentAssignmentDetails?.endDate?.timestamp));
    return moment(transport?.equipmentAssignmentDetails?.executionStartDate?.timestamp).add(duration).toDate();
  }

}
