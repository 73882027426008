import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { BehaviorSubject } from 'rxjs';

export interface Button {
  fontSet?: boolean,
  icon?: string,
  faIcon?: IconDefinition,
  static?: boolean,
  tooltip: string,
  type: ButtonType,
  value: BehaviorSubject<boolean>
}

export enum ButtonType {
  EQUIPMENT,
  PROJECT,
  ORGANISATION,
  CONTACT,
  GEOFENCE,
  SETTINGS,
  STOCK ,
}

export interface ButtonEvent {
  hide?: boolean,
  type: ButtonType,
  value: boolean
}
