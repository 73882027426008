import { Injectable } from '@angular/core';
import { TransportDataSource } from './transport.datasource';
import { TransportService } from './transport.service';
import { TransportSearch } from '../../contracts/transport/transport-search.interface';
import { switchMap } from 'rxjs/operators';
import { TransportParams } from '../contract/request-params/transport-params.interface';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { TransportColumnService } from './transport-column.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { VehicleDataSource } from './vehicle.datasource';
import { UpdateTransportTaskCommand } from '../../contracts/transport/update-transport-task.command';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { TransportTaskState } from '../enums/transport-task-status.enum';
import { OspDateTime } from 'app/modules/equipment/contract/osp-date-time';
import { TransportAssignmentExecutionCommand } from '../../contracts/transport/transport-assignment-execution.command';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportStatePipe } from '../pipes/transport-state.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TransportBoardDataSource extends TransportDataSource<TransportSearch> {
  private removedFromList: { item: TransportSearch, index: number } = null;
  private _transportRequestInProgress = new BehaviorSubject<boolean>(false);

  public readonly transportRequestInProgressChanges = this._transportRequestInProgress.asObservable();

  public get transportRequestInProgress(): boolean {
    return this._transportRequestInProgress.getValue();
  }

  public set transportRequestInProgress(value: boolean) {
    this._transportRequestInProgress.next(value);
  }

  constructor(
    public transportService: TransportService,
    public transportColumnService: TransportColumnService,
    private vehicleDataSource: VehicleDataSource,
    protected languageService: LanguageService,
    dialog: MatDialog,
    transportStatePipe: TransportStatePipe,
    snackBar: MatSnackBar
  ) {
    super(transportService, dialog, languageService, transportStatePipe, transportColumnService, snackBar);
    this.initListPageSizeListener();
  }

  protected transportRequestListener(): void {
    this.transportRequest
    .pipe(switchMap((params: TransportParams) => this.transportService.getTransportsSearch(params)))
    .subscribe((response: PagedResponse<TransportSearch>) => this.updateStoreData(response));
  }

  public removeFromListById(transportId: string): void {
    const transportList = [...this._data.value];
    const index = transportList.findIndex(({transportId: id}) => id === transportId);
    if (index > -1) {
      this.removedFromList = {item: transportList[index], index};
      transportList.splice(index, 1);
      this._data.next(transportList);
    }
  }

  public revertRemovedFromList(): void {
    if (this.removedFromList) {
      const transportList = [...this._data.value];
      transportList.splice(this.removedFromList.index, 0, this.removedFromList.item);
      this._data.next(transportList);
      this.removedFromList = null;
    }
  }

  public setNewStatusOfTransportTask(transport: TransportView, state: TransportTaskState, date: OspDateTime): Observable<string> {
    this.vehicleDataSource.clearTransportAssignmentsStore();
    return super.setNewStatusOfTransportTask(transport, state, date);
  }

  public updateTransport(command: UpdateTransportTaskCommand): Observable<string> {
    this.vehicleDataSource.clearTransportAssignmentsStore();
    return super.updateTransport(command);
  }

  public updateExecutionDates(command: TransportAssignmentExecutionCommand): Observable<string> {
    this.vehicleDataSource.clearTransportAssignmentsStore();
    return super.updateExecutionDates(command);
  }

  public moveDoneToArchive(fromDate: string, toDate: string): Observable<Object> {
    this.vehicleDataSource.clearTransportAssignmentsStore();
    return this.transportService.moveDoneToArchive(fromDate, toDate);
  }

  private initListPageSizeListener(): void {
    this.transportColumnService.transportListViewPageSize.subscribe((pageSize: number) => {
      this.setPageSize(pageSize);
    });
  }
}
