import { AfterContentInit, Component, Input } from '@angular/core';
import { OptionResolver } from '../card-select/option.resolver';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ListType } from '../../enums/list-type.enum';
import { TabService } from '../../tab.service';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from 'assets/matomo/matomo-categories.enum';
import { matomoActions } from 'assets/matomo/matomo-actions.enum';
import { IconPayload, IconType } from 'app/shared/contract/icon-payload.interface';



@Component({
  selector: 'bh-vertical-tab-select',
  templateUrl: './vertical-tab-select.component.html',
  styleUrls: ['./vertical-tab-select.component.scss']
})
export class VerticalTabSelectComponent implements AfterContentInit {

  @Input() options: string[];
  @Input() optionResolver: OptionResolver;
  public iconType = IconType;
  private _selectedView: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected tabService: TabService,
              private matomoTracker: MatomoTracker) {
  }

  public ngAfterContentInit(): void {
    this.getActivatedView(this.route.snapshot);
  }

  public isSelected(option: string): boolean {
    return option === this._selectedView.getValue();
  }

  public resolveOptionIcon(option: string): IconPayload {
    return this.optionResolver.resolveIconPayload(option);
  }

  public resolveOptionName(option: string): string {
    return this.optionResolver.resolveName(option);
  }

  public navigateToView(option: string): void {
    if (!this.isSelected(option)) {
      this.activateView(option);
    }
  }

  private activateView(option: string): void {
    // TODO add more possible routes
    this._selectedView.next(option);
    switch (option) {
      case ListType.BULK_ITEMS:
        this.tabService.changeAssetTab(ListType.BULK_ITEMS);
        this.router.navigate(['assets/bulk-item/list']);
        break;
      case ListType.EQUIPMENTS:
        this.tabService.changeAssetTab(ListType.EQUIPMENTS);
        this.router.navigate(['assets/equipment/list']);
        break;
      case ListType.STOCKS:
        this.tabService.changeSiteTab(ListType.STOCKS);
        this.router.navigate(['sites/stocks/list']);
        break;
      case ListType.PROJECTS:
        this.tabService.changeSiteTab(ListType.PROJECTS);
        this.router.navigate(['sites/projects/list']);
        break;
      case ListType.EMPLOYEES:
        this.tabService.changeStaffTab(ListType.EMPLOYEES);
        this.router.navigate(['assets/employee/list']);
        break;
      case ListType.EQUIPMENT_TRANSFER:
        this.tabService.changeTransferTab(ListType.EQUIPMENT_TRANSFER);
        this.router.navigate(['transfer/equipment-transfer-list']);
        this.matomoTracker.trackEvent(matomoCategories.TRANSFER_LIST, matomoActions.TRANSFER_EQUIPMENT_OPEN);
        break;
      case ListType.BULK_ITEM_TRANSFER:
        this.tabService.changeTransferTab(ListType.BULK_ITEM_TRANSFER);
        this.router.navigate(['transfer/bulk-item-transfer-list']);
        this.matomoTracker.trackEvent(matomoCategories.TRANSFER_LIST, matomoActions.TRANSFER_BULK_ITEM_OPEN);
        break;
      case ListType.CONTACTS:
        this.tabService.changeStaffTab(ListType.CONTACTS);
        this.router.navigate(['assets/contact/list']);
        break;
      case ListType.MAINTENANCE_TASKS_CURRENT:
        this.router.navigate(['maintenance', 'tasks', 'maintenance-list']);
        break;
      case ListType.MAINTENANCE_TASKS_COMPLETED:
        this.router.navigate(['maintenance', 'tasks', 'maintenance-completed-list']);
        break;
      case ListType.ANALYTICS_TRANSFER_LOG:
        this.router.navigate(['analytics/transfer-log']);
        break;
      case ListType.ANALYTICS_USAGE_HISTORY:
        this.router.navigate(['analytics/usage-history']);
        break;
      default:
        this.router.navigate(['landingpage']);
        break;
    }
  }

  private getActivatedView(route: ActivatedRouteSnapshot): void {
    switch (route.url[0].path) {
      case 'equipment':
        this._selectedView.next(ListType.EQUIPMENTS);
        break;
      case 'bulk-item':
        this._selectedView.next(ListType.BULK_ITEMS);
        break;
      case 'projects':
        this._selectedView.next(ListType.PROJECTS);
        break;
      case 'stocks':
        this._selectedView.next(ListType.STOCKS);
        break;
      case 'employee':
        this._selectedView.next(ListType.EMPLOYEES);
        break;
      case 'equipment-transfer-list':
        this._selectedView.next(ListType.EQUIPMENT_TRANSFER);
        break;
      case 'bulk-item-transfer-list':
        this._selectedView.next(ListType.BULK_ITEM_TRANSFER);
        break;
      case 'contact':
        this._selectedView.next(ListType.CONTACTS);
        break;
      case 'maintenance-list':
        this._selectedView.next(ListType.MAINTENANCE_TASKS_CURRENT);
        break;
      case 'maintenance-completed-list':
        this._selectedView.next(ListType.MAINTENANCE_TASKS_COMPLETED);
        break;
      case 'transfer-log':
        this._selectedView.next(ListType.ANALYTICS_TRANSFER_LOG);
        break;
      case 'usage-history':
        this._selectedView.next(ListType.ANALYTICS_USAGE_HISTORY);
        break;
      default:
        break;
    }
  }

}
