export class UpdateEmployeeQualificationAssignmentCommand {
  qualificationId: string;
  employeeId: string;
  qualificationAcquisitionDate: string;
  qualificationLastRenewal: string;

  constructor(qualificationId: string,
      employeeId: string,
      qualificationAcquisitionDate: string,
      qualificationLastRenewal: string) {
          this.qualificationId = qualificationId;
          this.employeeId = employeeId;
          this.qualificationAcquisitionDate = qualificationAcquisitionDate;
          this.qualificationLastRenewal = qualificationLastRenewal;
      }
}
