export enum TelematicsUnitTypeAssignment {
  TRACKUNIT_1_RAW = 'TRACKUNIT_1_RAW',
  TRACKUNIT_1_SPOT = 'TRACKUNIT_1_SPOT',
  TRACKUNIT_1_SPOT_RAW = 'TRACKUNIT_1_SPOT_RAW',
  AEMP = 'AEMP',
  SIGFOX_TRACKERTYPL26_1 = 'SIGFOX_TRACKERTYPL26_1',
  GENERIC_2 = 'GENERIC_2',
  GENERIC_3 = 'GENERIC_3',
  GENERIC_4 = 'GENERIC_4',
  GENERIC_5 = 'GENERIC_5',
  TELTONIKA_CABLE_UNIT = 'TELTONIKA_CABLE_UNIT',
  TELTONIKA_OBD_UNIT = 'TELTONIKA_OBD_UNIT',
  GPS_OVER_IP = 'GPS_OVER_IP',
  RIO = 'RIO',
  CONFIDEX_CLASSIC = 'CONFIDEX_CLASSIC',
  CONFIDEX_ROUGH = 'CONFIDEX_ROUGH',
  CONFIDEX_MICRO = 'CONFIDEX_MICRO',
  DIGITAL_MATTER_OYSTER3_UNIT = 'DIGITAL_MATTER_OYSTER3_UNIT'
}
