import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeycloakService } from '../../../../../core/keycloak';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { ConnectorStatusCode } from '../../../contract/connector-status-code.enum';
import { ScheduledConnector } from '../../../contract/scheduled-connector/scheduled-connector';

@Component({
  selector: 'bh-generic-connector-list',
  templateUrl: 'generic-connector-list.component.html',
  styleUrls: ['generic-connector-list.component.scss'],
})
export class GenericConnectorListComponent extends RoleAuthorityGuardsComponent {

  @Input() public connectors: ScheduledConnector[];

  @Output() public edit: EventEmitter<ScheduledConnector> = new EventEmitter();
  @Output() public delete: EventEmitter<ScheduledConnector> = new EventEmitter();
  @Output() public activate: EventEmitter<ScheduledConnector> = new EventEmitter();
  @Output() public update: EventEmitter<ScheduledConnector> = new EventEmitter();

  public readonly displayedColumns = [
    'name',
    'connectorType',
    'active',
    'statusCode',
    'running',
    'lastRunSuccessful',
    'connectionSuccessful',
    'lastWaitDuration',
    'lastRunDuration',
    'lastStarted',
    'nextStart',
    'menu'];

  constructor(protected authService: KeycloakService) {
    super(authService);
  }

  public getStatusIcon(statusCode: ConnectorStatusCode): string {
    switch (statusCode) {
      case ConnectorStatusCode.OK:
        return 'check_circle';
      case ConnectorStatusCode.WARNING:
        return 'warning';
      case ConnectorStatusCode.ERROR:
        return 'error';
    }
  }

  public formatDuration(duration: string): string {
    let date = new Date(0);
    date.setSeconds(+duration);
    return date.toISOString().substr(11, 8);
  }
}
