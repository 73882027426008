import { ContactColumnService } from 'app/modules/contact/shared/services/contact-column.service';
import { ContactColumnDefinitionService } from './shared/services/contact-column-definition.service';
import { ContactService } from './shared/services/contacts.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from 'app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAddEditComponent } from './contacts/contact-add-edit/contact-add-edit.component';
import { CustomMaterialModule } from 'app/core/custom-material/custom-material.module';
import { ContactDataSource } from './shared/services/contact.datasource';
import { contactRouting } from './routing/contact.routing';
import { ContactListComponent } from './contacts/contact-list/contact-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MainPipeModule } from 'app/shared/pipes/main-pipe.module';
import { OrganisationCoreDataService } from '../../shared/services/organisation-core-data.service';
import { ContactViewComponent } from './contacts/contact-list/contact-view/contact-view.component';
import { ContactViewDetailComponent } from './contacts/contact-list/contact-view-detail/contact-view-detail.component';
import { ContactViewGeneralComponent } from './contacts/contact-list/contact-view-general/contact-view-general.component';
import { ContactMapComponent } from './contacts/contact-map/contact-map.component';
import { ContactFiltersService } from './shared/services/contact-filters.service';
import { ContactStatusPipe } from './shared/pipes/contact-status.pipe';
import { ContactStatusIndicatorComponent } from './contacts/contact-status-indicator/contact-status-indicator.component';
import { ContactStatusColorPipe } from './contacts/contact-status-indicator/contact-status-color.pipe';
import { ContactListExportDialogComponent } from './contacts/contact-list/contact-list-export-dialog/contact-list-export-dialog.component';
import { MapModule } from 'app/shared/modules/map/map.module';
import { BhTableModule } from '../osp-ui/components/bh-table/bh-table.module';



@NgModule({
  declarations: [
    ContactAddEditComponent,
    ContactListComponent,
    ContactViewComponent,
    ContactViewDetailComponent,
    ContactViewGeneralComponent,
    ContactMapComponent,
    ContactStatusIndicatorComponent,
    ContactStatusPipe,
    ContactStatusColorPipe,
    ContactListExportDialogComponent,
  ],
  imports: [
    contactRouting,
    CommonModule,
    SharedModule,
    CustomMaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    DragDropModule,
    MainPipeModule,
    FormsModule,
    MapModule,
    BhTableModule,
  ],
  providers: [
    ContactService,
    ContactDataSource,
    OrganisationCoreDataService,
    ContactColumnService,
    ContactColumnDefinitionService,
    ContactFiltersService,
    ContactStatusPipe
  ]
})
export class ContactModule { }
