<div class="disable-scroll-bars">
  <div #wrapper (click)="onWrapperClicked()" class="wrapper" [ngClass]="{'force-close': disableSwiping}">
    <div class="swipeable">
      <ng-content></ng-content>
    </div>
    <div class="options">
      <ng-content select="bh-swipe-option"></ng-content>
    </div>
  </div>
</div>
