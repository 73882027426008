<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="header-card">
    <mat-card-content class="organisations-header">
      <div>
        <span>{{customerName}}</span>
      </div>
    </mat-card-content>
  </mat-card>
  <!--TODO uncomment when search will be implemented-->

<!--  <mat-card class="search-bar">-->
<!--    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">-->

<!--            <div class="search-bar-input-container" fxFlex>-->
<!--              <form class="search-bar-input" fxLayout="row"-->
<!--                    novalidate>-->
<!--                <input fxFlex matInput>-->
<!--                <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px">-->
<!--                  <mat-icon>search</mat-icon>-->
<!--                </button>-->
<!--              </form>-->
<!--            </div>-->

<!--            <button mat-button class="search-bar-button-right" [matTooltip]="'general.new'|translate" fxFlex="50px" (click)="navigateToAdd()"-->
<!--                    *ngIf="hasAuthority(authorities.CUSTOMER_CREATE)">-->
<!--              <mat-icon>add_circle_outline</mat-icon>-->
<!--            </button>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="organisations-add-button">
        <button mat-button (click)="navigateToAdd()"
                *ngIf="hasAuthority(authorities.ORGANISATION_CREATE) && hasCustomerId()">
          {{'modules.organisation.organisationList.addNewOrganisation'|translate}}
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <div class="data-table-wrapper" fxFlex>
        <mat-table class="pagination-data-table" [dataSource]="organisations">

          <ng-container cdkColumnDef="organizationName">
            <mat-header-cell *cdkHeaderCellDef fxFlex="45"> {{'modules.organisation.organisationViewAddEdit.organisationName'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="45" class="tree-header">
              <mat-icon *ngIf="row.level > 0"
                        class="sub-organisation-icon"
                        [style.marginLeft.px]="row.level * 22"
              >
                subdirectory_arrow_right
              </mat-icon>
                {{row.organisationName}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="organizationNumber">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.organisation.organisationViewAddEdit.organisationNumber'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <span class="organisation-number"
                    matTooltipClass="organisation-number-tooltip"
                    [matTooltip]="getToolTip(row.organisationNumber)">
                {{row.organisationNumber | maxCharactersLimit: limitNumber}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="organizationType">
            <mat-header-cell *cdkHeaderCellDef> {{'modules.organisation.organisationViewAddEdit.organisationType'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.organisationType | organisationTypeDisplay}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="organizationDetails">
            <mat-header-cell *cdkHeaderCellDef fxLayoutAlign="end stretch"></mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxLayoutAlign="end center">
              <button mat-button
                [matTooltip]="'modules.organisation.organisationViewAddEdit.viewDetails'|translate"
                (click)="viewOrganisation(row.organisationId)"
                data-osp-test="button-organisation-details"
              >
                <mat-icon>visibility</mat-icon>
              </button>
              <button mat-button
                [matTooltip]="'modules.organisation.organisationViewAddEdit.addOrganisation'|translate"
                (click)="navigateToAdd('add', {parentOrganisationId: row.organisationId})"
                *ngIf="hasAuthority(authorities.ORGANISATION_CREATE) && hasCustomerId() &&
                      isAllowedToChangeOrganisation(row.organisationId)"
              >
                <mat-icon>add_circle</mat-icon>
              </button>
              <button mat-button
                [matTooltip]="'modules.organisation.organisationViewAddEdit.editOrganisation'|translate"
                (click)="navigateToEdit(row.organisationId)"
                *ngIf="hasAuthority(authorities.ORGANISATION_UPDATE) && hasCustomerId() &&
                      isAllowedToChangeOrganisation(row.organisationId)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-button
                [matTooltip]="'modules.organisation.organisationViewAddEdit.moveOrganisation'|translate"
                (click)="moveOrganisation(row.organisationId)"
                *ngIf="hasAuthority(authorities.ORGANISATION_UPDATE) && hasCustomerId() &&
                      isAllowedToChangeOrganisation(row.organisationId)"
              >
                  <mat-icon>import_export</mat-icon>
              </button>
              <button mat-button
                [matTooltip]="'modules.organisation.organisationViewAddEdit.deleteOrganisation'|translate"
                (click)="deleteOrganisation(row.organisationId)"
                *ngIf="hasAuthority(authorities.ORGANISATION_DELETE) && hasCustomerId() &&
                      isAllowedToChangeOrganisation(row.organisationId)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
