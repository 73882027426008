import { Injectable } from '@angular/core';
import { CustomThemeService } from 'app/shared/services/custom-theme.service';

@Injectable()
export class MapIconResolverProject {
  private fileName = null;

  constructor(themeService: CustomThemeService) {
    const dirName = themeService.getThemeSettings()?.themeDirName;
    this.fileName = dirName ? `assets/styles/themes/${dirName}/assets/map/Project_256px_40hw.svg` : null;
  }

  public getSvgIcon(): string {
    return this.fileName;
  }
}
