import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MaintenanceType } from '../contract/maintenance-type.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { DeleteMaintenanceTypeCommand } from '../contract/commands/delete-maintenance-type.command';
import { CreateMaintenanceTypeCommand } from '../contract/commands/create-maintenance-type.command';
import { UpdateMaintenanceTypeCommand } from '../contract/commands/update-maintenance-type.command';
import { NameInUseService } from '../../../shared/api/name-in-use.service.interface';

@Injectable()
export class MaintenanceTypeService implements NameInUseService {

  private url: string = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance';

  constructor(private http: HttpClient) {}

  getAll(): Observable<MaintenanceType[]> {
    return this.http.get<MaintenanceType[]>(`${this.url}/maintenance-types`);
  }

  getFilterableMaintenanceTypes(): Observable<MaintenanceType[]> {
    return this.http.get<MaintenanceType[]>(`${this.url}/maintenance-types/filterable-maintenance-types`);
  }

  getById(id: string): Observable<MaintenanceType> {
    return this.http.get<MaintenanceType>(`${this.url}/maintenance-types/${id}`);
  }

  delete(command: DeleteMaintenanceTypeCommand): Observable<string> {
    return this.http.post(`${this.url}/maintenance-types/delete`, command, {responseType: 'text'});
  }

  createMaintenanceType(command: CreateMaintenanceTypeCommand): Observable<string> {
    return this.http.post(`${this.url}/maintenance-types/create`, command, {responseType: 'text'});
  }

  updateMaintenanceType(command: UpdateMaintenanceTypeCommand): Observable<string> {
    return this.http.post(`${this.url}/maintenance-types/update`, command, {responseType: 'text'});
  }

  nameInUse(id: string, name: string): Observable<boolean> {
    const params = new HttpParams().set('id', id).set('name', name);
    return this.http.get<boolean>(`${this.url}/maintenance-types/name-in-use`, {params: params});
  }

  canDelete(typeId: string): Observable<boolean> {
    const params = new HttpParams().set('id', typeId);
    return this.http.get<boolean>(`${this.url}/maintenance-types/can-delete`, {params: params});
  }
}
