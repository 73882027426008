import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'textarea[bhInputFitHeight]'
})
export class InputFitHeightDirective implements AfterViewInit {
  @Input() minRows: number;
  @Input() maxRowsDefault: number;

  private fontSize: number;
  private defaultFontSize = 16;
  private defaultLineHeightCoeff = 1.2;
  private extraPx = 2;
  private rowHeightPx = 0;
  private elem: HTMLElement;
  private initialHeightPx = 0;
  private disableAutoResizing = false;
  private heightBeforeResizing: number = null;
  private isManualResizeHappened = false;

  private get minPx(): number {
    return this.rowsPx(this.minRows);
  }

  private get maxPxRowsDefault(): number {
    return this.rowsPx(this.maxRowsDefault);
  }

  private get maxPxInitial(): number {
    const calculatedMaxInitial = this.maxPxRowsDefault;
    return this.initialHeightPx > calculatedMaxInitial ? calculatedMaxInitial : this.initialHeightPx;
  }

  private get isOverflown(): boolean {
    return this.elem.scrollHeight > this.elem.clientHeight;
  }

  private get isCurrentHeightGreaterThanMax(): boolean {
    return parseInt(this.elem.style.height, 10) > this.maxPxRowsDefault;
  }

  constructor(elem: ElementRef) {
    this.elem = elem.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.fontSize = parseInt(window.getComputedStyle(this.elem).fontSize, 10) || this.defaultFontSize;
    this.rowHeightPx = parseInt(window.getComputedStyle(this.elem).lineHeight, 10) || this.fontSize * this.defaultLineHeightCoeff;
    this.initialHeightPx = this.elem.scrollHeight;

    this.setInitialStyles();
  }

  @HostListener('input') public onInput(): void {
    if (this.isOverflown && !this.disableAutoResizing) {
      if (this.isCurrentHeightGreaterThanMax && this.isManualResizeHappened) {
        this.fitToRealHeight();
      } else {
        this.fitUntilMaxHeight();
      }
    }
  }

  @HostListener('mousedown') onmousedown(): void {
    this.heightBeforeResizing = this.elem.clientHeight;
  }

  @HostListener('mouseup') onmouseup(): void {
    if (this.heightBeforeResizing && this.heightBeforeResizing !== this.elem.clientHeight) {
      this.disableAutoResizing = this.isOverflown;
      this.isManualResizeHappened = true;
    }
    this.heightBeforeResizing = null;
  }

  private fitToRealHeight(): void {
    this.elem.style.height = `${this.elem.scrollHeight}px`;
  }

  private fitUntilMaxHeight(): void {
    const calcualtedHeight = this.elem.scrollHeight > this.maxPxRowsDefault ? this.maxPxRowsDefault : this.elem.scrollHeight;
    this.elem.style.height = `${calcualtedHeight}px`;
  }

  private setInitialStyles(): void {
    this.elem.style.resize = 'vertical';
    if (this.minRows) {
      this.elem.style.minHeight = `${this.minPx}px`;
    }
    if (this.maxRowsDefault) {
      this.elem.style.height = `${this.maxPxInitial}px`;
    }
  }

  private rowsPx(rowsAmount: number): number {
    return rowsAmount * this.rowHeightPx + this.extraPx;
  }
}
