import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { SingleFilterOption } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { EquipmentFilterRequestParams } from '../../../../shared/contract/filter/equipment/equipment-filter-request-params.interface';
import { EquipmentFilterType } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-type.interface';

export class EquipmentTransferFilterParams extends FilterParams {
  public getFilterParams(): EquipmentFilterRequestParams {
    return {
      [EquipmentFilterType.ORGANISATION]: this.getFilterTypeParams(FilterTypes.ORGANISATION),
      [EquipmentFilterType.ASSIGNED_EMPLOYEE]: this.getFilterTypeParams(FilterTypes.RESPONSIBLE_PERSON),
      [EquipmentFilterType.LABEL]: this.getFilterTypeParams(FilterTypes.LABEL),
      [EquipmentFilterType.CLASS]: this.getFilterTypeParams(FilterTypes.EQUIPMENT_CLASS),
      [EquipmentFilterType.WITHOUT_SUBEQUIPMENTS]:
        !(this.getFilterTypeParams(FilterTypes.SUBEQUIPMENT).some(value => value === SingleFilterOption.WITHOUT_SUBEQUIPMENTS)),
      [EquipmentFilterType.MATCH_ALL_LABELS]: this.getFilterTypeParams(FilterTypes.MATCH_ALL_LABELS)
        .some(value => value === SingleFilterOption.ALL_LABELS),
      [EquipmentFilterType.STATUS]: this.getFilterTypeParams(FilterTypes.EQUIPMENT_STATE)
        .filter(value => !EquipmentStatusCategory[value]),
    }
  }
}
