import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { EquipmentsDataSource } from '../equipments.datasource';
import { ViewStock } from '../view-stock.interface';
import { BehaviorSubject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { StockService } from '../stock-service';
import { AssignEquipmentToStockCommand } from '../../contract/assign-equipment-to-stock-command';
import { MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-assign-to-organisation-dialog',
  templateUrl: './equipment-assign-to-stock-dialog.component.html',
  styleUrls: ['./equipment-assign-to-stock-dialog.component.scss']
})
export class EquipmentAssignToStockDialogComponent implements OnInit, OnDestroy {

  public filterControl: UntypedFormControl = new UntypedFormControl();
  public stockForm: UntypedFormGroup;
  public stocks: ViewStock[] = [];
  public filteredStocks: BehaviorSubject<ViewStock[]> = new BehaviorSubject([]);
  public equipment: ViewEquipment;

  constructor(private equipmentStore: EquipmentsDataSource,
              private stockService: StockService,
              private formBuilder: UntypedFormBuilder,
              private dialogRef: MatDialogRef<EquipmentAssignToStockDialogComponent>) {
  }

  public ngOnInit(): void {
    this.stockService.getStocks()
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewStock[]) => {
        this.stocks = res;
        this.filteredStocks.next(res);
      })
    this.buildForm();
    this.filterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterStocks();
      });
    if (this.equipment) {
      this.stockForm.patchValue({stockId: this.equipment.stockId});
    }
  }

  public ngOnDestroy(): void {
  }

  public save(): void {
    if (this.isValid()) {
      if (this.equipment.stockId !== this.stockForm.get('stockId').value) {
        const cmd = new AssignEquipmentToStockCommand(this.equipment.equipmentId, this.stockForm.get('stockId').value);

        this.equipmentStore.assignEquipmentToStock(cmd).subscribe(() => {
          this.dialogRef.close();
        });
      } else {
        this.dialogRef.close();
      }
    }
  }

  public isValid(): boolean {
    return this.stockForm.valid;
  }

  private buildForm(): void {
    this.stockForm = this.formBuilder.group({
      stockId: ['', Validators.required]
    });
  }

  private filterStocks(): void {
    if (!this.stocks) {
      return;
    }
    let search = this.filterControl.value;
    if (!search) {
      this.filteredStocks.next(this.stocks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStocks.next(
      this.stocks.filter(stock => this.searchMatchesStock(search, stock))
    );
  }

  private searchMatchesStock(search: string, stock: ViewStock): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0 && (stock.stockName.toLowerCase().indexOf(param) === -1) &&
        param.length > 0 && (stock.organisationName.toLowerCase().indexOf(param) === -1)) {
        return false;
      }
    }
    return true;
  }
}
