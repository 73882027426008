import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { PublicApiConnectorData, PublicApiConnectorService } from '../public-api-connector.service';

@UntilDestroy()
@Component({
  selector: 'bh-create-public-api-connector-dialog',
  templateUrl: './create-public-api-connector-dialog.component.html',
  styleUrls: ['./create-public-api-connector-dialog.component.scss']
})
export class CreatePublicApiConnectorDialogComponent implements OnInit {

  public creating = true;
  public connector: PublicApiConnectorData;

  constructor(private publicApiConnectorService: PublicApiConnectorService) {}

  public ngOnInit(): void {
    this.creating = true;
    this.publicApiConnectorService.createPublicApiConnector()
      .pipe(untilDestroyed(this))
      .pipe(finalize(() => this.creating = false))
      .subscribe(connector => this.connector = connector);
  }
}
