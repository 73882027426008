import { PartnerTheme } from './app/modules/organisation/contract/partner-theme.enum';

export function appendApplicationManifest(theme: PartnerTheme, os: string) {
  let filename: string = resolveManifestFilename(theme, os);
  appendManifestLink(`manifests/${filename}`);
  setThemeBorderColor(theme);
}

function resolveManifestFilename(theme: PartnerTheme, os: string): string {
  const themePrefix: string = getThemePrefix(theme);

  if (os === 'iOS') {
    return `${themePrefix}-manifest-ios.json`;
  } else {
    return `${themePrefix}-manifest.json`;
  }
}

function getThemePrefix(theme: PartnerTheme): string {
  switch (theme) {
    case PartnerTheme.WACKER_NEUSON:
      return 'wn';
    case PartnerTheme.DEFAULT_OSP:
    default:
      return 'osp';
  }
}

function appendManifestLink(filename: string) {
  let manifestLink: HTMLLinkElement = document.createElement('link');
  manifestLink.rel = 'manifest';
  manifestLink.href = filename;
  document.head.appendChild(manifestLink);
}

function setThemeBorderColor(theme: PartnerTheme): void {
  let color: string;
  switch (theme) {
    case PartnerTheme.WACKER_NEUSON:
      color = '#FFD511';
      break;
    case PartnerTheme.DEFAULT_OSP:
    case PartnerTheme.UNLABLED:
    default:
      color = '#a4d313';
      break;
  }
  document.querySelector('meta[name="theme-color"]').setAttribute('content', color);
}
