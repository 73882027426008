import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MaintenanceTaskCompletedAuditResponse } from 'app/modules/maintenance/shared/contract/maintenance-task-completed-audit-response.interface';
import { MaintenanceTask } from 'app/modules/maintenance/tasks/shared/maintenance-task';
import { MaintenanceTaskCompletedDataSource } from 'app/modules/maintenance/tasks/shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bh-maintenance-task-completed-view-history',
  templateUrl: './maintenance-task-completed-view-history.component.html',
  styleUrls: ['./maintenance-task-completed-view-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceTaskCompletedViewHistoryComponent implements OnChanges {
  @Input() public isActive = false;
  @Input() public task: MaintenanceTask;

  private readonly _auditData = new BehaviorSubject<MaintenanceTaskCompletedAuditResponse>(null);
  public readonly auditData = this._auditData.asObservable();

  constructor(private maintenanceDataSource: MaintenanceTaskCompletedDataSource) { }

  public ngOnChanges(): void {
    if (this.task?.id && this.isActive) {
      this.maintenanceDataSource.getAuditData(this.task.id)
        .subscribe(data => this._auditData.next(data));
    }
  }

}
