import { Pipe, PipeTransform } from '@angular/core';
import { IconPosition, MapIcon } from '../contracts/map-icon.class';

@Pipe({
  name: 'mapIconBuilder'
})
export class MapIconBuilderPipe implements PipeTransform {

  public transform(icon: string | MapIcon): google.maps.Icon {
    return icon ? this.getIcon(icon) : null;
  }

  private getIcon(icon: string | MapIcon): google.maps.Icon {
    let mapIcon = null;
    if (MapIcon.isMapIcon(icon)) {
      mapIcon = icon;
    } else if (typeof icon === 'string') {
      mapIcon = new MapIcon({ url: icon, size: 40, position: IconPosition.BOTTOM });
    }

    return mapIcon ? this.convertToIcon(mapIcon) : null;
  }

  private convertToIcon(icon: MapIcon): google.maps.Icon {
    return {
      url: icon.url,
      anchor: icon.anchor ? new google.maps.Point(icon.anchor.x, icon.anchor.y) : null,
      scaledSize: icon.scaledSize ? new google.maps.Size(icon.scaledSize.width, icon.scaledSize.height) : null,
      size: icon.size ? new google.maps.Size(icon.size.width, icon.size.height) : null,
    }
  }

}
