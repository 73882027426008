import { Component, Input } from '@angular/core';
import { Color } from './color.enum';

@Component({
  selector: 'bh-color-indicator',
  templateUrl: './color-indicator.component.html',
  styleUrls: ['./color-indicator.component.scss']
})
export class ColorIndicatorComponent {
  @Input() color: Color = Color.GRAY;
}
