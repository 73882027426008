import { TransportItem, TransportItemTyped } from 'app/modules/equipment/shared/transport-items.interface';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { TransportItemType } from '../../shared/enums/transport-item-type.enum';
import { TransportItemLocationType } from '../../shared/enums/transport-item-location-type.enum';
import _ from 'lodash';

type TransportItemValue = TransportItem | string;

export class TransportSelectionController {

  private _transportItems = new BehaviorSubject<TransportItem[]>([]);
  private _projectItems = new BehaviorSubject<TransportItem[]>([]);

  private _startAddressFilterTerm = new BehaviorSubject<string>('');
  private _endAddressFilterTerm = new BehaviorSubject<string>('');
  private _projectAddressFilterTerm = new BehaviorSubject<string>('');

  private _selectedStartAddress = new BehaviorSubject<TransportItemValue>(null);
  private _selectedEndAddress = new BehaviorSubject<TransportItemValue>(null);
  private _selectedProjectAddress = new BehaviorSubject<TransportItemValue>(null);

  private _mapDisplayedInfo = new BehaviorSubject<TransportItemTyped[]>([]);
  private _mapMoveStartAddress = new BehaviorSubject<TransportItem[]>([]);
  private _mapMoveEndAddress = new BehaviorSubject<TransportItem[]>([]);
  private _mapMoveProjectAddress = new BehaviorSubject<TransportItem[]>([]);

  public readonly transportItems = this._transportItems.asObservable();
  public readonly projectItems = this._projectItems.asObservable();

  public readonly startAddressOptions = combineLatest([ this._startAddressFilterTerm.asObservable(), this.transportItems])
    .pipe(map(([term, items]) => this.getFilteredTransportItems(term, items)));
  public readonly endAddressOptions = combineLatest([this._endAddressFilterTerm.asObservable(), this.transportItems])
    .pipe(map(([term, items]) => this.getFilteredTransportItems(term, items)));
  public readonly projectAddressOptions = combineLatest([this._projectAddressFilterTerm.asObservable(), this.projectItems])
    .pipe(map(([term, items]) => this.getFilteredTransportItems(term, items)));

  public readonly selectedStartAddress = this._selectedStartAddress.asObservable();
  public readonly selectedEndAddress = this._selectedEndAddress.asObservable();
  public readonly selectedProjectAddress = this._selectedProjectAddress.asObservable();

  public readonly mapDisplayedInfo = this._mapDisplayedInfo.asObservable();
  public readonly mapMoveStartAddress = this._mapMoveStartAddress.asObservable();
  public readonly mapMoveEndAddress = this._mapMoveEndAddress.asObservable();
  public readonly mapMoveProjectAddress = this._mapMoveProjectAddress.asObservable();

  private get currentStartAddressItem(): TransportItem {
    const value = this._selectedStartAddress.getValue();
    return this.isTransportItem(value) ? value : null;
  }

  private get currentEndAddressItem(): TransportItem {
    const value = this._selectedEndAddress.getValue();
    return this.isTransportItem(value) ? value : null;
  }

  public setTransportItems(items: TransportItem[]): void {
    this.rangeTransportItemsByType(items);
  }

  public reset(): void {
    this._startAddressFilterTerm.next('');
    this._endAddressFilterTerm.next('');
    this._projectAddressFilterTerm.next('');
    this._selectedStartAddress.next(null);
    this._selectedEndAddress.next(null);
    this._selectedProjectAddress.next(null);
    this._mapDisplayedInfo.next([]);
    this._mapMoveStartAddress.next([]);
    this._mapMoveEndAddress.next([]);
    this._mapMoveProjectAddress.next([]);
  }

  public selectStartAddress(value: TransportItemValue): void {
    this._selectedStartAddress.next(value);
    if (this.isString(value)) {
      this.filterStartAddresses(value);
    }

    let toMove: TransportItem = this.currentStartAddressItem;
    if (toMove) {
      if (!this.currentEndAddressItem) {
        this.moveEndAddress(toMove);
      }
    } else {
      toMove = this.currentEndAddressItem;
    }
    this.moveStartAddress(toMove);

    this.emitMapDisplayedInfo();
    this.selectProjectAddress(this.getProjectBasedOnStartEnd(), true);
  }

  public selectEndAddress(value: TransportItemValue): void {
    this._selectedEndAddress.next(value);
    if (this.isString(value)) {
      this.filterEndAddresses(value);
    }

    let toMove: TransportItem = this.currentEndAddressItem;
      if (toMove) {
        if (!this.currentStartAddressItem) {
          this.moveStartAddress(toMove);
        }
      } else {
        toMove = this.currentStartAddressItem;
      }
      this.moveEndAddress(toMove);

      this.emitMapDisplayedInfo();
      this.selectProjectAddress(this.getProjectBasedOnStartEnd(), true);
  }

  public selectProjectAddress(value: TransportItemValue, moveIfNotSelected = false): void {
    this._selectedProjectAddress.next(value);
    if (this.isString(value)) {
      this.filterProjectAddresses(value);
    }

    if (this.isTransportItem(value)) {
      if (value) {
        this.moveProjectAddress([value]);
      } else if (moveIfNotSelected) {
        this.moveProjectAddress(this.getProjectMoveBasedOnStartEnd());
      }
    }
  }

  private filterStartAddresses(term: string): void {
    this._startAddressFilterTerm.next(term);
  }
  private filterEndAddresses(term: string): void {
    this._endAddressFilterTerm.next(term);
  }
  private filterProjectAddresses(term: string): void {
    this._projectAddressFilterTerm.next(term);
  }

  private moveStartAddress(item: TransportItem): void {
    if (item?.location) {
      this._mapMoveStartAddress.next([item]);
    }
  }
  private moveEndAddress(item: TransportItem): void {
    if (item?.location) {
      this._mapMoveEndAddress.next([item]);
    }
  }
  private moveProjectAddress(items: TransportItem[]): void {
    const validItems = items.filter(item => item?.location);
    if (validItems?.length > 0) {
      this._mapMoveProjectAddress.next(items);
    }
  }

  private emitMapDisplayedInfo(): void {
    const toDisplay: TransportItemTyped[] = _.uniqBy([
      this.currentStartAddressItem,
      this.currentEndAddressItem,
    ], 'id')
    .filter(item => Boolean(item) && item.location)
    .map(item => ({ ...item, locationType: this.getLocationsTypeById(item.id) }));

    this._mapDisplayedInfo.next(toDisplay);
  }

  private getProjectBasedOnStartEnd(): TransportItem {
    if (this.currentEndAddressItem?.transportItemType === TransportItemType.PROJECT) {
      return this.currentEndAddressItem;
    } else if (this.currentStartAddressItem?.transportItemType === TransportItemType.PROJECT) {
      return this.currentStartAddressItem;
    }
    return null;
  }

  private getProjectMoveBasedOnStartEnd(): TransportItem[] {
    return [this.currentStartAddressItem, this.currentEndAddressItem].filter(Boolean);
  }

  private getLocationsTypeById(itemId: string): TransportItemLocationType[] {
    return [
      itemId === this.currentStartAddressItem?.id ? TransportItemLocationType.START : null,
      itemId === this.currentEndAddressItem?.id ? TransportItemLocationType.TARGET : null,
    ].filter(Boolean);
  }

  private rangeTransportItemsByType(items: TransportItem[]): void {
    this._transportItems.next(items);
    this._projectItems.next(items.filter(({ transportItemType }) => transportItemType === TransportItemType.PROJECT));
  }

  private getFilteredTransportItems(searchTerm: string, originData: TransportItem[]): TransportItem[] {
    const searchSubStrings = [...new Set(
      searchTerm
        ? searchTerm
          .replace(/[.,;]/g, ' ')
          .trim()
          .split(' ')
          .filter(Boolean)
          .map(value => value.toLowerCase())
        : []
    )];

    return searchSubStrings.length < 1
      ? [...originData]
      : originData.filter(option => {
          const fieldValues = this.getSearchFieldValues(option);
          return searchSubStrings.every(searchSubString => this.isMatchSearchValue(searchSubString, fieldValues))
        });
  }

  private getSearchFieldValues(item: TransportItem): string[] {
    return [
      item.name,
      ...(item.address ? [item.address.city, item.address.postalCode] : []),
      item.transportItemType === TransportItemType.CONTACT ? item.contactIdentifier : item.costCenter
    ]
    .filter(Boolean)
    .map(value => value.toLowerCase());
  }

  private isMatchSearchValue(searchTerm: string, fieldValues: string[]): boolean {
    return !searchTerm || fieldValues.some(fieldValue => fieldValue.includes(searchTerm));
  }

  private isString(value: TransportItemValue): value is string {
    return typeof value === 'string';
  }

  private isTransportItem(value: TransportItemValue): value is TransportItem {
    return !this.isString(value);
  }

}
