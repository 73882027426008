import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faInfoCircle, faEllipsisV, faCheck, faPlus, faCheckCircle, faSync} from '@fortawesome/pro-light-svg-icons';
import { QualificationAddEditComponent } from './qualification-add-edit/qualification-add-edit.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { faSync as faSyncBold } from '@fortawesome/pro-solid-svg-icons';
import { QualificationRenewComponent } from './qualification-renew/qualification-renew.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { EmployeeViewQualificationBase } from './base/employee-view-qualification-base';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { EmployeeQualification } from 'app/modules/disposition/contract/employee-qualification';
import { Retake } from 'app/modules/disposition/contract/retake.enum';
import { faCheckDouble } from '@fortawesome/pro-duotone-svg-icons';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';
import { EmployeeQualificationAssignmentRemoveCommand } from 'app/modules/disposition/contract/employee-qualification-assignment-remove-command';

@Component({
  selector: 'bh-employee-view-qualification',
  templateUrl: './employee-view-qualification.component.html',
  styleUrls: ['./employee-view-qualification.component.scss']
})
export class EmployeeViewQualificationComponent extends EmployeeViewQualificationBase {

  public readonly faInfoCircle: IconDefinition = faInfoCircle;
  public readonly faEllipsisV: IconDefinition = faEllipsisV;
  public readonly faCheckCircle: IconDefinition = faCheckCircle;
  public readonly faCheck: IconDefinition = faCheck;
  public readonly faCheckDouble: IconDefinition = faCheckDouble;
  public readonly faPlus: IconDefinition = faPlus;

  constructor(protected dialog: MatDialog,
              protected datePipe: DatePipe,
              protected employeeStore: EmployeeManagementDatasource,
              protected authService: KeycloakService,
              protected languageService: LanguageService) {
    super(employeeStore, languageService);
  }

  public addEditQualificationOpen(existedQualification?: EmployeeQualification): void {
    this.dialog.open(QualificationAddEditComponent, {
      minWidth: '60vw',
      autoFocus: false,
      data: {
        employeeQualifications: this.employeeQualifications,
        allQualifications: this.qualifications,
        existedQualification: existedQualification,
        employeeId: this.currentEmployee.employeeId,
        customerId: this.currentEmployee.customerId
      }
    });
  }

  public renew(qualificationId: string): void {
    const chosenQualification = this.employeeQualifications.filter(qualification => qualification.qualificationId === qualificationId)[0];

    this.employeeStore.changeCurrentQualification(chosenQualification);

    this.dialog.open(QualificationRenewComponent, {
      minWidth: '35vw',
      data: {
        employeeId: this.currentEmployee.employeeId
      }
    });
  }

  public getDelay(retakeDate: Date): boolean {
    return retakeDate < new Date();
  }

  public checkDelay(qualification: EmployeeQualification): string {
    const today = new Date();
    const difference = (qualification.retakeDate.getTime() - today.getTime()) / (1000 * 3600 * 24);
    if (difference < qualification.qualificationHighWarningThreshold) {
      return 'red-icon';
    } else if (difference < qualification.qualificationMediumWarningThreshold) {
      return 'new-icon'
    } else {
      return 'gray-icon'
    }
  }

  public checkOverdue(retakeDate: Date): IconDefinition {
    const today = new Date();
    if (retakeDate < today) {
      return faSyncBold;
    } else {
      return faSync;
    }
  }

  public getCommentText(qualification: EmployeeQualification): string {
    let period = '';
    let status = '';

    switch (qualification.qualificationRetakeType) {
      case Retake.NO_RETAKE:
        return '';
      case Retake.FIXED_DAY_IN_YEAR:
        period = this.translate('modules.disposition.qualifications.wholeYear');
        break;
      case Retake.INTERVAL:
        period = this.translate('general.interval');
    }
    status = this.getDelay(qualification.retakeDate)
      ? this.translate('modules.disposition.qualifications.overdue')
      : this.translate('modules.disposition.qualifications.dueOn');
    return period + ' - ' + status + ' (' + this.datePipe.transform(qualification.retakeDate, 'dd.MM.yyyy') + ')';
  }

  public qualificationDelete(qualificationIdParam: string): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.deleteQualific.title');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.deleteQualific.text');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd: EmployeeQualificationAssignmentRemoveCommand = { qualificationId: qualificationIdParam,
           employeeId: this.currentEmployee.employeeId }
        this.employeeStore.removeQualificationAssignment(cmd).pipe().subscribe(() => {
        })
      }
    });
  }

  public allowManageQualifications() {
    return this.authService.hasAuthority(Authorities.EMPLOYEE_MANAGE_QUALIFICATIONS) &&
     this.authService.hasAuthority(Authorities.EMPLOYEE_VIEW_QUALIFICATIONS);
  }

  public showQualifications() {
    return this.authService.hasAuthority(Authorities.EMPLOYEE_VIEW_QUALIFICATIONS);
  }

  public showRenewButton() {
    return this.authService.hasAuthority(Authorities.EMPLOYEE_RENEW_QUALIFICATIONS);
  }

  public hasRetake(qualification: EmployeeQualification) {
    return qualification && qualification.qualificationRetakeType !== Retake.NO_RETAKE;
  }

  public isValidRetake(qualification: EmployeeQualification): boolean {
    return qualification.retakeDate
      && qualification.retakeDate instanceof Date
      && !isNaN(qualification.retakeDate.getTime());
  }
}
