import { Directive, HostListener, Input, } from '@angular/core';

@Directive({
  selector: 'input[type=number][bhMinValue],textarea[type=number][bhMinValue]'
})
export class InputMinValueDirective {
  @Input() bhMinValue: number;

  constructor() {
  }

  @HostListener('input', ['$event'])
  changeListener(event): void {
    let target = <HTMLInputElement>event.target;
    if (+(target.value) < this.bhMinValue) {
      target.value = this.bhMinValue.toString();
      target.dispatchEvent(new InputEvent(event.type));
    }
  }
}
