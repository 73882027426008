import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { StockStore } from '../../../shared/stock.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { ViewStock } from '../../../../contract/view-stock';
import { StockViewTransfersService } from '../stock-view-transfers.service';
import {
  TransferHistoryEntry,
} from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-history-entry';
import { TransferListItemType } from '../../../../contract/transfer-list-item-type';
import { StockViewTransfersDatasourceService } from '../stock-view-transfers-datasource.service';
import { debounceTime, delay } from 'rxjs/operators';
import { TransferHistoryEntryComponent } from '../../../../../../shared/components/transfer-history-entry/transfer-history-entry.component';
import { environment } from '../../../../../../../environments/environment';
import { ViewTransferRequest } from '../../../../projects/project-list/project-view/project-view-transfer-history/view-transfer-request';
import { TransferRequestEntry } from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-request-entry';
import { TransferEntry } from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-entry';
import { TransferItemType } from '../../../../shared/transfer/model/transfer-item-type';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ViewStockEmployeeAssignment } from '../../../../contract/view-stock-employee-assignment.interface';
import { DeclineTransferRequestCommand } from '../../../../contract/decline-transfer-request-command';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { AcknowledgeTransferRequestCommand } from '../../../../contract/acknowledge-transfer-request-command';
import { ConfirmationDialogComponent } from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { RevertTransferCartCommand } from '../../../../contract/revert-transfer-cart-command';
import { MatDialog } from '@angular/material/dialog';
import { TransferService } from '../../../../shared/transfer.service';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { StockType } from 'app/shared/enums/stock-type';
import {PrivilegedRole} from '../../../../../../shared/enums/privileged-roles.enum';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';

@UntilDestroy()
@Component({
  selector: 'bh-stock-view-transfers',
  templateUrl: './stock-view-transfers.component.html',
  styleUrls: ['./stock-view-transfers.component.scss'],
})

export class StockViewTransfersComponent implements OnInit, AfterViewInit {
  @ViewChildren(TransferHistoryEntryComponent) transferEntries: QueryList<TransferHistoryEntryComponent>;

  public loading: Observable<boolean>;
  public transfers: TransferHistoryEntry[] = [];
  public transferRequests: ViewTransferRequest[] = [];
  public selectedTransferRequest: ViewTransferRequest = null;
  public stockName: string;
  public isAcceptanceUser = false;
  public isRequestsTab = false;

  private stockId: string;
  private stockOrganisationId: string;
  private stockCustomerId: string;
  private stockType: string;
  private selectedTransferId;

  constructor(private stockViewTransfersService: StockViewTransfersService,
              private stockStore: StockStore,
              protected stockViewTransfersDatasource: StockViewTransfersDatasourceService,
              private authService: KeycloakService,
              private snackBar: MatSnackBar,
              private languageService: LanguageService,
              private dialog: MatDialog,
              private transferService: TransferService
  ) { }

  ngOnInit(): void {
    this.loading = this.stockViewTransfersDatasource.loading;

    this.subscribeToCurrentStock();
    this.subscribeToHistoryEntries();
    this.hasStockTransferRequestWorkFlow();
    }

  ngAfterViewInit(): void {
    combineLatest([
      this.stockViewTransfersDatasource.selectedEntry,
      this.stockViewTransfersDatasource.historyEntries.pipe(debounceTime(environment.DELAY_SHORTEST))
    ]).pipe(untilDestroyed(this), delay(environment.DELAY_SHORTEST))
    .subscribe(() => {});
  }

  public getCombinedId(transfer: TransferHistoryEntry | ViewTransferRequest): string {
    return transfer.transferId;
  }

  public revertRequest(transferId: string): void {
    this.stockViewTransfersDatasource.updateLoadingStatus(true);
    this.transferService.transferRevertPossible(transferId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.stockViewTransfersDatasource.updateLoadingStatus(false);
        res.revertPossible
          ? this.openConfirmTransferDialog(transferId)
          : this.showNotice('modules.disposition.projectTransferHistory.transferNotReversed');
      });
  }

  private openConfirmTransferDialog(transferId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.revertTransfer');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.revertTransfer');
    dialogRef.componentInstance.confirmLabel = this.translate('general.buttons.confirm');
    dialogRef.componentInstance.denyLabel = this.translate('general.buttons.cancel');

    dialogRef.afterClosed().subscribe(res => {
      if (res === dialogResults.YES) {
        this.revertTransfer(transferId);
      }
    });
  }

  private revertTransfer(transferId: string): void {
    this.transferService.revertTransfer(new RevertTransferCartCommand(transferId))
      .pipe(untilDestroyed(this))
      .subscribe(() => setTimeout(() => {
        this.showNotice('modules.disposition.projectTransferHistory.transferReversed');
        this.updateTransferData();
      }, environment.DELAY_SHORT));
  }

  private showNotice(message: string): void {
    this.snackBar.open(this.translate(message), undefined, { duration: environment.DELAY_LONG });
  }

  private subscribeToHistoryEntries(): void {
    this.stockViewTransfersDatasource.historyEntries
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.transfers = data.sort(({ transferDate: dateFirst }, { transferDate: dateSecond }) => {
          dateFirst = new Date(dateFirst) || new Date(0);
          dateSecond = new Date(dateSecond) || new Date(0);
          return dateSecond.getTime() - dateFirst.getTime();
        })
      });
  }

  public subscribeToTransferRequests(): void {
    this.stockViewTransfersDatasource.transferRequests
    .pipe(untilDestroyed(this))
    .subscribe(data => {
      if (this.isRequestsTab) {
        this.transferRequests = data;
        this.transferRequests.forEach(transfer => {
          transfer.transferEntries = this.getTransferEntry(transfer.transferItems);
        });
        if (this.selectedTransferId) {
          this.selectedTransferRequest = this.transferRequests.find(transfer => transfer.transferId === this.selectedTransferId);
        }
      }
    });
  }


  public getTransferEntry(transferItems: TransferRequestEntry[]): TransferEntry[] {
    let transferEntries = [];
    transferItems.forEach(transferItem => {
      const transferEntry: TransferEntry = {
        sourceStockId: transferItem.sourceStockId,
        sourceStockName: transferItem.sourceStockName,
        sourceStockCostCenter: transferItem.sourceStockCostCenter,
        sourceProjectId: transferItem.transferSourceId,
        sourceProjectName: transferItem.transferSourceName,
        sourceProjectNumber: transferItem.transferSourceNumber,
        sourceProjectCostCenter: transferItem.transferSourceCostCenter,
        transferItemAmount: transferItem.amount,
        transferItemId: transferItem.transferItemId,
        transferItemName: transferItem.transferItemName,
        transferItemNumber: transferItem.transferItemInternalNumber,
        transferListItemType: transferItem.transferItemType === TransferItemType.EQUIPMENT
                                ? TransferListItemType.EQUIPMENT
                                : TransferListItemType.STANDARD_BULK_ITEM,
        transferItemUnit: transferItem.transferItemUnit,
        itemIsAccessibleForUser: transferItem.itemIsAccessibleForUser,
        sourceLocationIsAccessibleForUser : transferItem.sourceLocationIsAccessibleForUser,
      }
      transferEntries.push(transferEntry);
    });
    return transferEntries;
  }

  public declineTransferRequest(transferId: string): void {
    this.stockViewTransfersService.declineTransferRequest(new DeclineTransferRequestCommand(transferId))
    .pipe(untilDestroyed(this))
    .subscribe(() =>
      setTimeout(() => {
        this.snackBar.open(
          this.languageService.getInstant('modules.disposition.stockViewTransfer.transferRequestDeclined'),
          undefined,
          {duration: environment.DELAY_LONG});
        this.getTransferRequests();
        this.updateTransferData();
      }, environment.DELAY_SHORT), () =>
      this.snackBar.open(
        this.languageService.getInstant('modules.disposition.stockViewTransfer.transferRequestNotDeclined'),
        undefined,
        {duration: environment.DELAY_LONG}));
  }

  public acknowledgeTransferRequest(transferId: string) {
    this.stockViewTransfersService.acknowledgeTransferRequest(new AcknowledgeTransferRequestCommand(transferId))
    .pipe(untilDestroyed(this))
    .subscribe(() =>
      setTimeout(() => {
        this.snackBar.open(
          this.languageService.getInstant('modules.disposition.stockViewTransfer.transferRequestAccepted'),
          undefined,
          {duration: environment.DELAY_LONG});
        this.getTransferRequests();
        this.updateTransferData();
      }, environment.DELAY_SHORT), () =>
      this.snackBar.open(
        this.languageService.getInstant('modules.disposition.stockViewTransfer.transferRequestNotAccepted'),
        undefined,
        {duration: environment.DELAY_LONG}))
  }

  private subscribeToEmployeeAssignments(): void {
    this.stockStore.employeeAssignments
      .pipe(untilDestroyed(this))
      .subscribe(employeeAssignments => {
        if (employeeAssignments) {
          const userId = this.authService.getUserUserId();
          this.isAcceptanceUser = this.hasAcceptanceUser(employeeAssignments, userId);
          this.isRequestsTab = this.hasRequestsTab(this.stockOrganisationId, this.stockCustomerId);
          if (this.isRequestsTab) {
            this.getTransferRequests();
          }
        }
      });
  }

  private hasAcceptanceUser(employeeAssignments: ViewStockEmployeeAssignment[], userId: string): boolean {
    return employeeAssignments.some(el => el.assignedUserId === userId && el.isAcceptanceUser);
  }

  private subscribeToCurrentStock(): void {
    this.stockStore.selectedStock
      .pipe(untilDestroyed(this))
      .subscribe((stock: ViewStock) => {
        if (stock?.stockId) {
          this.stockId = stock.stockId;
          this.stockName = stock.stockName;
          this.stockType = stock.stockType;
          this.stockOrganisationId = stock.organisationId;
          this.stockCustomerId = stock.customerId;
          this.stockViewTransfersDatasource.stockId = stock.stockId;
          this.stockViewTransfersDatasource.resetPageIndex();
          this.stockViewTransfersDatasource.updateStockTransfers();
          this.stockStore.getEmployeeAssignments(stock.stockId);
        }
      });
  }

  private updateTransferData(): void {
    this.stockStore.selectStock(this.stockId);
    this.stockViewTransfersDatasource.updateStockTransfers();
  }

  private hasStockTransferRequestWorkFlow(): void {
    this.stockViewTransfersDatasource.hasStockTransferRequestWorkflow().subscribe(res => {
      if (res) {
        this.subscribeToEmployeeAssignments();
        this.subscribeToTransferRequests();
      }
    })
  }

  private hasRequestsTab(stockOrganisationId: string, stockCustomerId: string): boolean {
    return this.authService.hasAuthority(Authorities.STOCK_VIEW) &&
           (this.hasUsersOrganisationOwnsStock(stockOrganisationId, stockCustomerId) ||
             this.stockType === StockType.MAIN);
  }

  private hasUsersOrganisationOwnsStock(stockOrganisationId: string, stockCustomerId: string): boolean {
    return this.authService.getRoles().includes(PrivilegedRole.Flottenadmin.toString()) ?
      this.authService.getUserCustomerId() === stockCustomerId :
      this.authService.getUserOrganisations().includes(stockOrganisationId);
  }

  private getTransferRequests(): void {
    this.stockViewTransfersDatasource.getTransferRequests(this.stockId, true);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
