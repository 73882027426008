import { Injectable } from '@angular/core';
import { QUICK_FILTERS } from 'app/shared/contract/quick-filters';
import { TIME_RANGE_DATE_TYPES } from 'app/shared/contract/time-range-date-types';
import { ITimeRangeParams } from 'app/shared/contract/time-range-params.interface';
import { ITimeFrameParams } from 'app/shared/contract/time-frame-params.interface';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

const defaultTimeFrameParams: ITimeFrameParams = {
  fromDate: moment().subtract(30, 'day').startOf('day').toISOString(),
  toDate: moment().endOf('day').toISOString(),
}

const defaultTimeRangeParams: ITimeRangeParams = {
  dateType: TIME_RANGE_DATE_TYPES.TRANSFER_DATE,
  ...defaultTimeFrameParams,
};

const defaultQuickFilterParam: string = QUICK_FILTERS.LAST_THIRTY_DAYS;

@Injectable({providedIn: 'root'})
export class TimeRangeFilterDatasource {

  private _showTimeRangeFilter = new BehaviorSubject<boolean>(false);
  public readonly showTimeRangeFilter = this._showTimeRangeFilter.asObservable();

  private _showDateTypeControl = new BehaviorSubject<boolean>(true);

  private _transferLogQuickFilterParam = new BehaviorSubject<string>(defaultQuickFilterParam);
  private _transferLogTimeRangeParams = new BehaviorSubject<ITimeRangeParams>(defaultTimeRangeParams);
  public readonly transferLogTimeRangeParams = this._transferLogTimeRangeParams.asObservable();

  private _usageHistoryQuickFilterParam = new BehaviorSubject<string>(defaultQuickFilterParam);
  private _usageHistoryTimeFrameParams = new BehaviorSubject<ITimeFrameParams>(defaultTimeFrameParams);
  public readonly usageHistoryTimeFrameParams = this._usageHistoryTimeFrameParams.asObservable();

  private _clearFilterEvent = new BehaviorSubject<boolean>(true);
  public readonly clearFilterEvent = this._clearFilterEvent.asObservable();

  get defaultTimeRangeParams(): ITimeRangeParams {
    return defaultTimeRangeParams;
  }

  get defaultQuickFilterParam(): string {
    return defaultQuickFilterParam;
  }

  get showDateTypeControl(): boolean {
    return this._showDateTypeControl.value;
  }

  constructor() { }

  public changeDatePickerDisplayStatus(status: boolean): void {
    this._showTimeRangeFilter.next(status);
  }

  public changeDateTypeControlDisplayStatus(status: boolean): void {
    this._showDateTypeControl.next(status);
  }

  public triggerClearFilterEvent(): void {
    this._clearFilterEvent.next(true);
  }

  public updateTransferLogTimeRangeParams(params: ITimeRangeParams, quickParam: string): void {
    this._transferLogTimeRangeParams.next(params);
    this._transferLogQuickFilterParam.next(quickParam);
  }

  public updateUsageHistoryTimeFrameParams(params: ITimeFrameParams, quickParam: string): void {
    this._usageHistoryTimeFrameParams.next(params);
    this._usageHistoryQuickFilterParam.next(quickParam);
  }

  public getTransferLogTimeRangeParams(): ITimeRangeParams {
    return this._transferLogTimeRangeParams.value;
  }

  public getTransferLogQuickFilterParam(): string {
    return this._transferLogQuickFilterParam.value;
  }

  public getUsageHistoryTimeFrameParams(): ITimeFrameParams {
    return this._usageHistoryTimeFrameParams.value;
  }

  public getUsageHistoryQuickFilterParam(): string {
    return this._usageHistoryQuickFilterParam.value;
  }

}
