import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StockStore } from '../../../shared/stock.store';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { RouterHistory } from '../../../../../../shared/router-history';
import { DisplayService } from '../../../../../../shared/display.service';
import { ViewEquipmentAmount } from '../../../../contract/view-equipment-amount.interface';
import { PageEvent } from '@angular/material/paginator';
import { byStockName, ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';
import { ViewStock } from 'app/modules/disposition/contract/view-stock';
import { TransferCartTypes } from 'app/shared/enums/transfer-cart-types.enum';
import { UntypedFormControl } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  selector: 'bh-stock-view-inventory',
  templateUrl: './stock-view-inventory.component.html',
  styleUrls: ['./stock-view-inventory.component.scss']
})
@UntilDestroy()
export class StockViewInventoryComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public bulkItemAmounts: Observable<ViewBulkItemAmount[]>;
  public equipmentAmounts: Observable<ViewEquipmentAmount[]>;
  public availableAmountsLength: number;
  public equipmentAmountsLength: number;
  public readonly equipmentColumns: string[] = [
      'name',
      'dispositionDate'
  ];
  public searchTerm = new UntypedFormControl('');

  public readonly faExchange: IconDefinition = faExchange;

  private selectedStock: ViewStock;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected  routerHistory: RouterHistory,
              public stockStore: StockStore,
              private displayService: DisplayService,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.selectedStockSubscription();
    this.synchronizeSearchTermValue();
    this.bulkItemAmounts = this.stockStore.selectedStockBulkItemAmounts.pipe(map(amounts => amounts.sort(byStockName())));
    this.selectedStockBulkItemAmountsSubscription();
    this.equipmentAmounts = this.stockStore.selectedStockEquipmentAmounts.pipe(map(amounts => amounts.sort(byStockName())));
    this.selectedStockEquipmentAmountsSubscription();
    this.searchTermSubscription();
  }

  public updateAmounts(): void {
    this.stockStore.getAmounts(this.selectedStock.stockId);
  }

  public navigateToStockToStockTransfer(): void {
    this.router.navigate(['transfer', this.selectedStock.stockId, TransferCartTypes.STOCK_TO_STOCK_CART]);
  }

  public stockToStockButtonDisabled(): boolean {
    return !this.availableAmountsLength && !this.equipmentAmountsLength;
  }

  public navigateToEquipmentAssignments(equipmentId: string): void {
    this.displayService.displayTableAndDetails();
    this.router.navigate(['/assets/equipment/list', equipmentId, 'general']);
  }

  public ngOnDestroy(): void {
  }

  public onEquipmentPaginateChange(event: PageEvent) {
    this.stockStore.setEquipmentPaginationInfo(event.pageIndex, event.pageSize);
    this.stockStore.updateEquipmentAmounts();
  }

  public onBulkItemPaginateChange(event: PageEvent) {
    this.stockStore.setBulkItemPaginationInfo(event.pageIndex, event.pageSize);
    this.stockStore.updateBulkItemAmounts();
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private selectedStockSubscription(): void {
    this.stockStore.selectedStock
      .pipe(untilDestroyed(this))
      .subscribe(currentStock => this.selectedStock = currentStock);
  }

  private selectedStockBulkItemAmountsSubscription(): void {
    this.stockStore.selectedStockBulkItemAmounts
      .pipe(untilDestroyed(this))
      .subscribe(amounts => this.availableAmountsLength = amounts.filter(amount => amount.amountAvailable > 0).length);
  }

  private selectedStockEquipmentAmountsSubscription(): void {
    this.stockStore.selectedStockEquipmentAmounts
      .pipe(untilDestroyed(this))
      .subscribe(equipmentAmounts => this.equipmentAmountsLength = equipmentAmounts.length);
  }

  private searchTermSubscription(): void {
    this.searchTerm.valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged())
      .subscribe(term => {
        this.stockStore.inventorySearchTerm = term;
        this.stockStore.resetPaginationIndexes();
        this.updateAmounts();
      })
  }

  private synchronizeSearchTermValue(): void {
    this.searchTerm.setValue(this.stockStore.inventorySearchTerm);
  }
}
