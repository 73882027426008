import { Injectable } from '@angular/core';
import { EquipmentMaintenanceRuleService } from './equipment-maintenance-rule.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceRule } from '../../../maintenance/rules/shared/contract/maintenance-rule';

@Injectable()
export class EquipmentMaintenanceRuleStore {

  private maintenanceRulesSubject: Subject<MaintenanceRule[]> = new Subject();
  private filterTermSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly availableMaintenanceRules: Observable<MaintenanceRule[]> = this.maintenanceRulesSubject.asObservable();
  public readonly filteredMaintenanceRules: Observable<MaintenanceRule[]> = combineLatest(
      this.maintenanceRulesSubject,
      this.filterTermSubject
  )
  .pipe(
      map(([rules, filterTerm]) =>
        rules.filter((rule: MaintenanceRule) => {
          const searchTerm = rule.ruleName.toLocaleLowerCase();
          return !filterTerm || searchTerm.indexOf(filterTerm.toLocaleLowerCase()) !== -1;
        })));

  constructor(private maintenanceRuleService: EquipmentMaintenanceRuleService) {}

  loadMaintenanceRules(): void {
    this.maintenanceRuleService.getAll().subscribe(rules => this.maintenanceRulesSubject.next(rules));
  }

  filterTypesBy(filterTerm: string): void {
    this.filterTermSubject.next(filterTerm);
  }
}
