import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { RequestStatus } from '../../enums/request-status.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { ITransferItemWithValidityStatus, TransferItem } from '../../transfer/model/transfer-item';
import { UntypedFormGroup } from '@angular/forms';
import { TypeOfUseStatus } from '../../enums/type-of-use-status.enum';
import { ProjectDataSource } from '../../project.datasource';
import { LanguageService } from '../../../../../shared/services/language.service';
import { TypeOfUseStatusPipe } from '../../pipes/type-of-use-status.pipe';
import { DatePipe } from '@angular/common';
import { ViewProject } from '../../../contract/view-project.interface';
import { IAmountStatusPeriodWithId } from '../../../contract/view-project-amount.interface';
import { LastTransferDateReasonPipe } from '../../../bulk-items/bulk-item-transfer/landscape/transfer-cart-confirmation/last-transfer-date-reason-pipe';
import { LastTransferDateResponseMessageType } from '../../../projects/project-list/project-view/project-view-transfer-history/last-transfer-date-response-message-type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-view-validity-change-type-of-use',
  templateUrl: './view-validity-change-type-of-use.component.html',
  styleUrls: ['./view-validity-change-type-of-use.component.scss'],
  providers: [TypeOfUseStatusPipe, DatePipe, LastTransferDateReasonPipe],
})
export class ViewValidityChangeTypeOfUseComponent implements AfterViewInit {
  @Input() statusForm: UntypedFormGroup;

  @Input() set transferItems(value: TransferItem[]) {
    value.forEach((item) => {
      item.amountStatusPeriodArray = [];
      if (item.amountStatusPeriods) {
        for (let key of Object.keys(item.amountStatusPeriods)) {
          item.amountStatusPeriodArray.push({id: key, ...item.amountStatusPeriods[key]});
        }
        item.amountStatusPeriodArray.sort((a, b) => {
          const dateA = new Date(a.statusStartDate).getTime();
          const dateB = new Date(b.statusStartDate).getTime();
          return dateA > dateB ? 1 : -1;
        });
      }
    });
    this._transferItems = value;
    this.transferItemsWithStatus = this.createTransferItemsWithValidityStatus(this._transferItems);
    setTimeout(() => {
      this.validate();
    }, 0);
  }

  @Input() project: ViewProject;
  @Output() isItemsStatusLegal: EventEmitter<boolean> = new EventEmitter<false>();

  get transferItems(): TransferItem[] {
    return this._transferItems;
  }

  _transferItems: TransferItem[];

  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;

  public transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];

  constructor(private data: ProjectDataSource,
              private languageService: LanguageService,
              private typeOfUseStatus: TypeOfUseStatusPipe,
              private date: DatePipe,
              private lastTransferDateReasonPipe: LastTransferDateReasonPipe,
  ) {
    this.transferItemToggleEventSubscription();
  }

  ngAfterViewInit(): void {
    this.statusForm.valueChanges.subscribe(() => this.validate());
  }

  private transferItemToggleEventSubscription(): void {
    this.data.transferItemToggleEvent.pipe(untilDestroyed(this)).subscribe(() => this.validate());
  }

  private createTransferItemsWithValidityStatus(transferItems: TransferItem[]): ITransferItemWithValidityStatus[] {
    const transferItemsWithStatus = transferItems.map((item: TransferItem) => {
      return {
        status: RequestStatus.SUCCESS,
        transferItem: item,
        errorText: '',
      };
    });
    return transferItemsWithStatus;
  }


  public resolveStatusIcon(status: RequestStatus): IconDefinition {
    switch (status) {
      case RequestStatus.FAILED:
        return faTimes;
      case RequestStatus.SUCCESS:
        return faCheck;
    }
  }

  public resolveIconClass(status: RequestStatus): string {
    return status === RequestStatus.FAILED ? 'error' : 'primary';
  }

  public isShow(): boolean {
    return this.transferItemsWithStatus.some(item => item.transferItem.transferAmount > 0);
  }

  private validate(): ITransferItemWithValidityStatus[] {
    this.transferItemsWithStatus.forEach((item) => {
      const startDateTime = new Date(this.statusForm.controls.startDateTime.value).getTime();
      const endDateTime = this.statusForm.controls.endDateTime.value
                              ? new Date(this.statusForm.controls.endDateTime.value).getTime()
                              : null;
      item.status = RequestStatus.SUCCESS;
      item.errorText = '';

      if (item.status === RequestStatus.SUCCESS && !this.chargeDateValidate(startDateTime, new Date(this.project?.chargeDate).getTime())) {
        item.status = RequestStatus.FAILED;
        item.errorText = this.statusRangeIllegal(this.project?.chargeDate ? new Date(this.project.chargeDate) : new Date());
      }

      if (item.status === RequestStatus.SUCCESS &&
        !this.lastTransferDateValidate(startDateTime, new Date(item.transferItem.lastTransferDate).getTime())) {
        item.status = RequestStatus.FAILED;
        item.errorText = this.lastTransferDateReasonPipe.transform({
          type: LastTransferDateResponseMessageType.ASSET_LAST_TRANSFER_DATE,
          transferItemsInfo: [{
            transferItemName: item.transferItem.name,
            transferItemNumber: item.transferItem.transferItemSerialNumber,
          }],
        },
          item.transferItem.lastTransferDate,
          this.languageService.getCurrentLocale(),
          );
      }

      if (item.status === RequestStatus.SUCCESS) {
        const periodFrom = this.getStartPeriod(startDateTime, item.transferItem);
        const periodTo = this.getEndPeriod(endDateTime, item.transferItem);
        this.speciallValidationCases(
          periodFrom ? periodFrom.status : null,
          periodTo ? periodTo.status : null,
          this.statusForm.controls.status.value,
          startDateTime,
          endDateTime,
          item,
        )
      }
    });
    this.data.transferItemsWithStatus = [...this.transferItemsWithStatus];
    this.isItemsStatusLegal.emit(this.allSelectedItemsHaveSuccessStatus(this.transferItemsWithStatus));
    return this.transferItemsWithStatus;
  }

  private allSelectedItemsHaveSuccessStatus(items): boolean {
    return items.filter(el => el.transferItem.transferAmount > 0).every(item => item.status === RequestStatus.SUCCESS);
  }

  private lastTransferDateValidate(startDateTime: number, lastTransferDate: number): boolean {
    return lastTransferDate < startDateTime;
  }

  private chargeDateValidate(
    startDateTime: number,
    projectChargeDate: number,
  ): boolean {
    return projectChargeDate === null || projectChargeDate < startDateTime;
  }

  private speciallValidationCases(
    periodStartStatus: TypeOfUseStatus,
    periodEndStatus: TypeOfUseStatus,
    newStatus: TypeOfUseStatus,
    startDateTime: number,
    endDateTime: number,
    transferItem: ITransferItemWithValidityStatus,
  ): void {
    if ((periodStartStatus === null || this.periodStatusInUse(newStatus) !== this.periodStatusInUse(periodStartStatus)) &&
      (endDateTime === null || this.periodStatusInUse(newStatus) !== this.periodStatusInUse(periodEndStatus))) {
      transferItem.status = RequestStatus.SUCCESS;
      transferItem.errorText = '';
    } else if (periodStartStatus === null || this.periodStatusInUse(newStatus) !== this.periodStatusInUse(periodStartStatus)) {
      transferItem.status = RequestStatus.FAILED;
      transferItem.errorText = this.getAtEndErrorMessage(periodEndStatus, newStatus, endDateTime);
    } else if (endDateTime === null || this.periodStatusInUse(newStatus) !== this.periodStatusInUse(periodEndStatus)) {
      transferItem.status = RequestStatus.FAILED;
      transferItem.errorText = this.getAtStartErrorMessage(periodStartStatus, newStatus, startDateTime);
    } else {
      transferItem.status = RequestStatus.FAILED;
      transferItem.errorText = this.getAtStartAndEndErrorMessage(periodEndStatus, periodStartStatus, newStatus, startDateTime, endDateTime);
    }
  }

  private getEndPeriod(endDateTime: number, transferItem: TransferItem): IAmountStatusPeriodWithId {
    if (endDateTime === null) {
      return transferItem.amountStatusPeriodArray[transferItem.amountStatusPeriodArray.length - 1];
    }
    for (let i = 0; i < transferItem.amountStatusPeriodArray.length; i++) {
      const periodStartDate = new Date(transferItem.amountStatusPeriodArray[i].statusStartDate).getTime();
      const periodEndDate = transferItem.amountStatusPeriodArray[i].statusEndDate ?
        new Date(transferItem.amountStatusPeriodArray[i].statusEndDate).getTime() : null;
      if (periodStartDate < endDateTime && (periodEndDate === null || periodEndDate > endDateTime)) {
        return transferItem.amountStatusPeriodArray[i];
      }
      if (periodStartDate < endDateTime && (periodEndDate === endDateTime)) {
        return transferItem.amountStatusPeriodArray[i + 1];
      }
    }
  }

  private getStartPeriod(startDateTime: number, transferItem: TransferItem): IAmountStatusPeriodWithId {
    for (let i = 0; i < transferItem.amountStatusPeriodArray.length; i++) {
      const periodStartDate = new Date(transferItem.amountStatusPeriodArray[i].statusStartDate).getTime();
      const periodEndDate = transferItem.amountStatusPeriodArray[i].statusEndDate ?
        new Date(transferItem.amountStatusPeriodArray[i].statusEndDate).getTime() : null;
      if (startDateTime > periodStartDate && (periodEndDate === null || startDateTime < periodEndDate)) {
        return transferItem.amountStatusPeriodArray[i];
      }
      if (startDateTime === periodEndDate) {
        return transferItem.amountStatusPeriodArray[i];
      }
    }
  }


  private periodStatusInUse(status: TypeOfUseStatus): boolean {
    if (status) {
      return status === TypeOfUseStatus.IN_USE;
    }
    return null;
  }

  private statusRangeIllegal(currentChargeDate: Date): string {
    return this.translate('modules.disposition.validityChangeTypeOfUse.statusRangeIllegal') + ' ' +
      this.date.transform(currentChargeDate, 'MM.dd.yyyy HH:mm', this.getCurrentLocale());
  }

  private getAtStartErrorMessage(oldStartStatus: string, newStatus: string, startTime: number): string {
    return this.translate('modules.disposition.validityChangeTypeOfUse.isNotAllowed.atStart',
            { oldStartStatus: this.typeOfUseStatus.transform(oldStartStatus).toLocaleLowerCase(),
              newStatus: this.typeOfUseStatus.transform(newStatus).toLocaleLowerCase(),
              startTime: this.getTimeString(startTime) });
  }

  private getAtEndErrorMessage(oldEndStatus: string, newStatus: string, endTime: number): string {
    return this.translate('modules.disposition.validityChangeTypeOfUse.isNotAllowed.atEnd',
            { oldEndStatus: this.typeOfUseStatus.transform(oldEndStatus).toLocaleLowerCase(),
              newStatus: this.typeOfUseStatus.transform(newStatus).toLocaleLowerCase(),
              endTime: this.getTimeString(endTime) });
  }

  private getAtStartAndEndErrorMessage(
    oldStartStatus: string,
    oldEndStatus: string,
    newStatus: string,
    startTime: number,
    endTime: number,
  ): string {
    return this.translate('modules.disposition.validityChangeTypeOfUse.isNotAllowed.atStartAndEnd',
            { oldStartStatus: this.typeOfUseStatus.transform(oldStartStatus).toLocaleLowerCase(),
              oldEndStatus: this.typeOfUseStatus.transform(oldEndStatus).toLocaleLowerCase(),
              newStatus: this.typeOfUseStatus.transform(newStatus).toLocaleLowerCase(),
              startTime: this.getTimeString(startTime),
              endTime: this.getTimeString(endTime) });
  }

  private getTimeString(time: number): string {
    return this.date.transform(time, 'MM.dd.yyyy HH:mm', this.getCurrentLocale());
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }
}
