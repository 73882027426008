<div class="gallery-image-wrapper gallery-animation-{{animation}} gallery-image-size-{{size}}">
    <bh-image class="gallery-image" #imageComponent
      *ngFor="
        let image of (images | currentGalleryImages : selectedIndex : infinityMove);
        let i = index;
        trackBy: imageTrackBy"
      [ngClass]="{
        'gallery-active': selectedIndex === image.index,
        'gallery-inactive-left': selectedIndex > image.index,
        'gallery-inactive-right': selectedIndex < image.index,
        'gallery-clickable': clickable === !imageComponent.disabled
      }"
      [src]="image.src"
      (click)="handleClick($event, image.index, imageComponent)"
      data-osp-test="gallery-image">
    </bh-image>
</div>
<bh-gallery-bullets *ngIf="bullets"
  [count]="images.length"
  [active]="selectedIndex"
  (onChange)="show($event)">
</bh-gallery-bullets>
<bh-gallery-arrows class="gallery-image-size-{{size}}"
  *ngIf="arrows && images?.length > 1"
  (onPrevClick)="showPrev()"
  (onNextClick)="showNext()"
  [prevDisabled]="!canShowPrev()"
  [nextDisabled]="!canShowNext()"
  [arrowPrevIcon]="arrowPrevIcon"
  [arrowNextIcon]="arrowNextIcon">
</bh-gallery-arrows>
