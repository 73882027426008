import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[bhMaxLength],textarea[bhMaxLength]'
})
export class InputMaxLengthDirective {
  @Input() bhMaxLength: number;

  @HostListener('input', ['$event'])
  changeListener(event): void {
    let target = <HTMLInputElement>event.target;
    if (target.value.length > this.bhMaxLength) {
      target.value = target.value.substr(0, this.bhMaxLength);
      target.dispatchEvent(new InputEvent(event.type));
    }
  }
}
