<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="title">{{title}}</h1>
  <form [formGroup]=dateTimeFormControlGroup (ngSubmit)="submit(dateTimeFormControlGroup)">
    <mat-dialog-content>
      <div fxLayout="row" fxLayoutGap="30px" fxFlex="100" class="dialog-content">

        <ng-container [ngSwitch]=isStartDate>
          <ng-container *ngSwitchCase="true">
            <mat-form-field fxFlex="49">
              <mat-label>{{dateLabel}}</mat-label>
              <input matInput
                    bhInputAllowedCharacters="[0-9./]"
                    [matDatepicker]="picker"
                    maxLength="20"
                    autocomplete="off"
                    [formControlName]=formControlNameDate>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix (click)="clearDate()">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameDate].hasError('isValidDateInstance')">
                {{dateTimeFormControlGroup.controls[formControlNameDate].getError('isValidDateInstance')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="49">
              <mat-label>{{timeLabel}}</mat-label>
              <input type="time"
                    matInput
                    [formControlName]=formControlNameTime>
              <mat-datepicker-toggle *ngIf="!isFirefoxBrowser" matSuffix (click)="clearTime()">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameTime].hasError('required')">
                {{'shared.validation.requiredField' | translate}}
              </mat-error>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameTime].hasError('time-without-valid-date')">
                {{'shared.validation.date.timeWithoutValidDate' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="false">
            <mat-form-field fxFlex="49">
              <mat-label>{{dateLabel}}</mat-label>
              <input matInput
                    bhInputAllowedCharacters="[0-9./]"
                    [matDatepicker]="picker"
                    [min]="startDate"
                    maxLength="20"
                    autocomplete="off"
                    [formControlName]=formControlNameDate>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix (click)="clearDate()">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameDate].hasError('isValidDateInstance')">
                {{dateTimeFormControlGroup.controls[formControlNameDate].getError('isValidDateInstance')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="49">
              <mat-label>{{timeLabel}}</mat-label>
              <input type="time"
                    matInput
                    [formControlName]=formControlNameTime>
              <mat-datepicker-toggle *ngIf="!isFirefoxBrowser" matSuffix (click)="clearTime()">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameTime].hasError('required')">
                {{'shared.validation.requiredField' | translate}}
              </mat-error>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameTime].hasError('time-without-valid-date')">
                {{'shared.validation.date.timeWithoutValidDate' | translate}}
              </mat-error>
              <mat-error *ngIf="dateTimeFormControlGroup.controls[formControlNameTime].hasError('maxTimeViolated')">
                {{'shared.validation.date.executionStartBeforeEnd' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end" class="actions">
      <button mat-button mat-dialog-close>{{'general.buttons.cancel' | translate }}</button>
      <button mat-raised-button type="submit" [disabled]=hasFormGroupError color="primary">
        {{confirmText}}
      </button>
    </mat-dialog-actions>
  </form>
</div>

