import { FilterValue } from './../filter-value';
import { FilterParams } from '../filter-params.class';
import { FilterTypes } from '../filter-types.enum';
import { AssignedVehicleFilterView } from '../filter-view/assigned-vehicle-filter-view.interface';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';

export class AssignedVehicleFilterViewConverter extends BaseFilterViewConverter<AssignedVehicleFilterView> {
  constructor(
    originalFilters: AssignedVehicleFilterView[],
    selectedValues: FilterParams,
    type: FilterTypes
  ) {
    super(type, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: AssignedVehicleFilterView): FilterValue {
    return {
      displayName: this.getDisplayName(filterItem),
      storeName: filterItem.equipmentId,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.equipmentId),
      filterTotal: this.formatCount(filterItem.count)
    }
  }

  private getDisplayName({
    equipmentName,
    equipmentModel,
    equipmentLicensePlate,
    equipmentCustomerSerialNumber
  }: AssignedVehicleFilterView): string {
    return [
      equipmentName ? equipmentName : equipmentModel,
      equipmentLicensePlate,
      equipmentCustomerSerialNumber]
      .filter(Boolean)
      .join(', ');
  }
}
