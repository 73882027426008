<div class="disposition-form-item" [formGroup]="equipmentDisposition"
     fxLayout="row" fxLayoutAlign="start center">


  <div fxFlex="40">
    <mat-icon [matTooltip]="'modules.equipment.assignProject.subequipment'|translate" *ngIf="isSubEquipment()">subdirectory_arrow_right</mat-icon>
    {{ equipmentName }}
  </div>

  <div fxFlex="30">
    <div class="conflict" *ngFor="let assignmentRequest of assignmentRequests"
         fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon [matTooltip]="'modules.equipment.assignProject.outstandingDispoInquiries'|translate">error_outline</mat-icon>
      <div class="project-data">
        <div class="project-name">{{assignmentRequest.projectName}}</div>
        <div>{{'modules.equipment.assignProject.requestOf'|translate}} {{assignmentRequest.requestedBy}}</div>
        <div>{{assignmentRequest | formatAssignmentDateRange}}</div>
      </div>
    </div>

    <div class="no-conflicts" *ngIf="noErrorsOrWarnings() && noOpenAssignmentRequests()">
      <mat-icon [matTooltip]="'modules.equipment.assignProject.noConflicts'|translate">done</mat-icon>
    </div>

    <div class="conflict" *ngFor="let conflict of conflicts"
         fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon [matTooltip]="'general.conflict.pl'|translate">error_outline</mat-icon>
      <div class="project-data">
        <div class="project-name">{{conflict.projectName}}</div>
        <span>{{conflict | formatAssignmentDateRange}}</span>
      </div>
    </div>

    <div class="warning" *ngFor="let warning of warnings" fxLayout="row"
         fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon class="warnings-icon" [matTooltip]="'modules.equipment.assignProject.conflictAutoResolved'|translate">compare_arrows</mat-icon>
      <div class="project-data">
        <div class="project-name">{{warning.projectName}}</div>
        <span>{{warning | formatAssignmentDateRange}}</span>
      </div>
    </div>
  </div>

  <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center"
    [matTooltip]="'modules.equipment.assignProject.noConflicts'|translate"
    [matTooltipDisabled]="!(noErrorsOrWarnings() && noOpenAssignmentRequests())">
    <mat-checkbox color="primary"
                  formControlName="overwrite"
                  [hidden]="assignmentRequests.length > 0 || conflicts.length === 0 && warnings.length === 0"
                  [disabled]="noErrorsOrWarnings() && noOpenAssignmentRequests()"
                  data-osp-test="checkbox-solve-conflicts">
    </mat-checkbox>
  </div>

  <div  fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="removable"
         [matTooltip]="'modules.equipment.assignProject.equipmentNotDispose'|translate"
         (click)="remove()">
      <mat-icon class="remove-icon">delete</mat-icon>
    </div>
  </div>

</div>
