export enum MaintenanceDynamicField {
  INSPECTION_DURATION = 'Dauer der Wartung/Prüfung',
  LAST_INSPECTION = 'Datum der letzten Prüfung',
  INSPECTOR = 'Prüfer',
  PERFORMED_BY = 'Wartung durchgeführt von',
  NEXT_INSPECTION_DATE = 'Datum der nächsten Prüfung',
  DESCRIPTION = 'Beschreibung',
  PLANNED_DURATION = 'Geplante Dauer',
  TRANSACTION_NUMBER = 'Vorgangsnummer',
  REPORT = 'Befund',
  DENSITY_TO = 'Dichte bis',
  DENSITY_FROM = 'Dichte von',
  SIGN_BVG = 'Kennzeichen BVG A3',
  SIGN_VOLTAGE = 'Kennzeichen Spannung',
  SIGN_ACID = 'Kennzeichen Säuredichte',
  VOLTAGE = 'Spannung',
  CELL_VOLTAGE_TO = 'Zellspannung bis',
  CELL_VOLTAGE_FROM = 'Zellspannung von',
  STATUS = 'Zustand',
}

export enum BEUT_19720_filter_out_vars {
  LAST_INSPECTION = MaintenanceDynamicField.LAST_INSPECTION,
  NEXT_INSPECTION_DATE = MaintenanceDynamicField.NEXT_INSPECTION_DATE,
}

