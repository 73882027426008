import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransportTaskPriority } from 'app/modules/transportation/shared/enums/transport-task-priority.enum';

@Component({
  selector: 'bh-transport-priority-badge',
  templateUrl: 'transport-priority-badge.component.html',
  styleUrls: ['./transport-priority-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportPriorityBadgeComponent {
  @Input() priority: TransportTaskPriority = null;
}
