import { ChatMessage } from './chat-message';

export class ChatMessageView {

  answeredMessage: string;
  chatMessageId: string;
  message: string;
  messageDate: Date;
  read: true;
  recipientId: string;
  replyOptions: string[];
  // senderId: string;
  newDateLine: string;
  newLine: boolean;

  constructor(data?: ChatMessage, private yourId?: string) {
    this.chatMessageId = data.chatMessageId;
    this.message = data.message;
    this.recipientId = data.recipientId;
    this.answeredMessage = data.answeredMessage;
    this.messageDate = data.messageDate;
    this.read = data.read;
    this.replyOptions = data.replyOptions;
  }

  public get yours(): boolean {
    return this.yourId === this.recipientId;
  }

}
