import { ReportJobInterval } from './report-job-interval.enum';

export class UpdateReportJobCommand {
  constructor(
    public reportJobId: string,
    public reportJobName: string,
    public reportJobRecipientUserIds: string[],
    public reportJobInterval: ReportJobInterval,
    public organisationIds: string[],
    public equipmentTypeIds: string[],
    public equipmentLabels: string[],
    public equipmentIds: string[],
    public projectIds: string[],
    public allEquipments: boolean,
    public allProjects: boolean
  ) {}
}
