import { Pipe, PipeTransform } from '@angular/core';
import { CurrentEquipmentStatus } from 'app/modules/equipment/contract/current-equipment-status.interface';
import { EquipmentTelematicsDataseriesItem } from 'app/modules/equipment/contract/equipment-telematics-dataseries-item';

@Pipe({
  name: 'convertStatusHoursToSeries'
})
export class ConvertStatusHoursToSeriesPipe implements PipeTransform {

  public transform(status: CurrentEquipmentStatus): EquipmentTelematicsDataseriesItem {
    return {
      name: null,
      series: [
        { name: null, value: status?.cumulativeOperatingHours ?? null },
        { name: null, value: status?.cumulativeIdleNonOperatingHours ?? null },
      ],
    };
  }

}
