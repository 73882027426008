import { BehaviorSubject, Observable, zip } from 'rxjs';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { Injectable } from '@angular/core';
import { FilterTypes, FilterTypesDisplayNames } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import {
  CommonFilterViewConverter,
} from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import { FiltersBaseService } from 'app/shared/contract/filter/filters-base-service.class';
import { MaintenanceTaskService } from './maintenance-task.service';
import { MaintenanceFilterParams } from '../filter/maintenance-filter-params.class';
import {
  ArticleGroupsFilterViewConverter,
} from '../../../../../shared/contract/filter/filter-converter/article-groups-filter-view-converter.class';
import {
  EquipmentTypeFilterViewConverter,
} from '../../../../../shared/contract/filter/filter-converter/equipment-type-filter-view-converter.class';
import { LanguageService } from '../../../../../shared/services/language.service';
import {
  OrganisationFilterViewConverter,
} from '../../../../../shared/contract/filter/filter-converter/organisation-filter-view-converter.class';
import { ViewOrganisation } from '../../../../organisation/contract/view-organisation.interface';
import { UpdateFilterCommand } from '../../../../../shared/contract/filter/update-filter-command.interface';
import {
  EquipmentStatusViewConverter,
} from '../../../../../shared/contract/filter/filter-converter/equipment-status-view-converter.class';
import { EquipmentStateFilterView } from 'app/shared/contract/filter/filter-view/equipment-state-filter-view.interface';
import { EquipmentStatusCategoryPipe } from '../../../../../shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from '../../../../../shared/pipes/equipment-status-name.pipe';
import { KeycloakService } from '../../../../../core/keycloak';
import { map } from 'rxjs/operators';
import { CommonFilterView } from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import { OrganisationFilterView } from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import { ArticleGroupsFilterView } from 'app/shared/contract/filter/filter-view/article-groups-filter-view.interface';
import {
  MaintenanceTypeFilterView,
} from 'app/shared/contract/filter/filter-view/maintenance-type-filter-view.interface';
import {
  MaintenanceStatusFilterView,
} from 'app/shared/contract/filter/filter-view/maintenance-status-filter-view.interface';
import {
  AssignedEmployeeFilterView,
} from 'app/shared/contract/filter/filter-view/assigned-employee-filter-view.interface';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import {
  ResponsiblePersonFilterViewConverter,
} from 'app/shared/contract/filter/filter-converter/responsible-person-filter-view-converter.class';
import {
  MaintenanceTypeFilterViewConverter,
} from 'app/shared/contract/filter/filter-converter/maintenance-type-filter-view-converter.class';
import {
  MaintenanceCategoryFilterViewConverter,
} from 'app/shared/contract/filter/filter-converter/maintenance-category-filter-view-converter.class';
import {
  MaintenanceCategoryFilterView,
} from 'app/shared/contract/filter/filter-view/maintenance-category-filter-view.interface';
import { MaintenanceCategoryPipe } from 'app/shared/pipes/maintenance-category.pipe';
import {
  MaintenanceCompletedFilterRequestParams,
} from '../filter/maintenance-completed-filter-request-params.interface';
import {
  MaintenanceCompletedFilterCollectionView,
} from '../../../shared/contract/maintenance-completed-filter-collection-view.interface';
import { MaintenanceCompletedFilterType } from '../filter/maintenance-completed-filter-type.enum';
import {
  MaintenanceResultFilterViewConverter,
} from '../../../../../shared/contract/filter/filter-converter/maintenance-result-filter-view-converter.class';
import {
  MaintenanceResultFilterView,
} from '../../../../../shared/contract/filter/filter-view/maintenance-result-filter-view.interface';
import { MaintenanceResultPipe } from '../../../../../shared/pipes/maintenance-result.pipe';
import { FilterSingleOptionDisplayNamePipe } from '../../../../../shared/pipes/filter-single-option-display-name.pipe';
import {
  SingleFilterOption
} from '../../../../../shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { SearchFilterMediatorReceiverEquipmentStatusesChanged } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator-receiver.interface';
import { SearchFilterMediatorService } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator.service';
import { MaintenanceCompletedFilterReadinessChecker } from 'app/shared/contract/filter/readiness-checker/core/checker/maintenance-completed-filter-readiness-checker.class';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { FilterUtils } from '../../../../../shared/contract/filter/filter-utils.class';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

type MaintenanceCompletedFilterItemView = (
  CommonFilterView |
  OrganisationFilterView |
  ArticleGroupsFilterView |
  MaintenanceTypeFilterView |
  MaintenanceStatusFilterView |
  AssignedEmployeeFilterView |
  EquipmentTypeFilterView |
  EquipmentStateFilterView |
  MaintenanceCategoryFilterView |
  MaintenanceResultFilterView);

@Injectable()
export class MaintenanceCompletedFiltersService
  extends FiltersBaseService<MaintenanceCompletedFilterRequestParams>
  implements SearchFilterMediatorReceiverEquipmentStatusesChanged {

  private readonly filterOrderPattern: MaintenanceCompletedFilterType[] = [
    MaintenanceCompletedFilterType.ORGANISATION,
    MaintenanceCompletedFilterType.MAINTENANCE_TYPES,
    MaintenanceCompletedFilterType.ARTICLE_GROUPS,
    MaintenanceCompletedFilterType.TYPES,
    MaintenanceCompletedFilterType.LABEL,
    MaintenanceCompletedFilterType.RESPONSIBLE_PERSON,
    MaintenanceCompletedFilterType.EQUIPMENT_STATUS,
    MaintenanceCompletedFilterType.MAINTENANCE_RESULTS,
    MaintenanceCompletedFilterType.ONLY_LATEST_COMPLETED_FOR_RULE,
    MaintenanceCompletedFilterType.MATCH_ALL_LABELS,
    MaintenanceCompletedFilterType.MAINTENANCE_CATEGORY,
  ];

  private readonly _isReady = new BehaviorSubject<boolean>(false);
  public readonly isReady = this._isReady.asObservable();
  private organisationMap = new Map<string, ViewOrganisation>();
  private filterChecker = new MaintenanceCompletedFilterReadinessChecker(this.params, this.maintenanceTaskService);

  constructor(
    private authService: KeycloakService,
    private organisationsService: OrganisationsService,
    private maintenanceTaskService: MaintenanceTaskService,
    private equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    private equipmentStatusNamePipe: EquipmentStatusNamePipe,
    private maintenanceCategoryPipe: MaintenanceCategoryPipe,
    private maintenanceResultPipe: MaintenanceResultPipe,
    private singleOptionDisplayNameResolver: FilterSingleOptionDisplayNamePipe,
    protected translatableStringPipe: TranslatableStringPipe,
    protected languageService: LanguageService,
    private filterMediator: SearchFilterMediatorService,
  ) {
    super(new MaintenanceFilterParams());
    this.filterMediator.addReceiver(this);
    this.initIsReadyListener();
  }

  public getFilterParams(): MaintenanceCompletedFilterRequestParams {
    this.filterChecker.isReadyCheck();
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.filterChecker.isReadyCheck();
    super.updateFilterParams(commands, updateFilters);
  }

  public updateFilters(): void {
    if (!this._isReady.value) {
      this.filterChecker.isReadyCheck();
    } else {
      zip(
        this.getFilters(),
        this.getOrganisationMap())
      .subscribe(([filters, organisationMap]) => {
        this.organisationMap = organisationMap;
        filters[MaintenanceCompletedFilterType.MATCH_ALL_LABELS] = [{ name: SingleFilterOption.ALL_LABELS, count: null }];
        FilterUtils.pushMissingParentOrganisationToFilters(filters[MaintenanceCompletedFilterType.ORGANISATION], this.organisationMap);
        const convertedFilters = this.convertToFilterTypes(filters);
        this.mergeToResultFilters(convertedFilters);
        this.onFiltersUpdated.emit();
      });
    }
  }

  public equipmentStatusesChanged(): void {
    this.filterChecker.equipmentStatusesChanged();
  }

  public resetFilters(commands?: UpdateFilterCommand[], updateFilters = true): void {
    this.params.reset();
    this.filterChecker.overrideDefaultFilter(commands);
    this.updateFilterParams(commands || [], updateFilters);
  }

  private initIsReadyListener(): void {
    this.filterChecker.isReady
      .subscribe(({ isReady, commands }) => {
        if (isReady) {
          this.updateFilterParams(commands ?? [], false);
          this._isReady.next(isReady);
          this.updateFilters();
        } else {
          this._isReady.next(isReady);
        }
      });
  }

  private getFilters(): Observable<MaintenanceCompletedFilterCollectionView> {
    return this.maintenanceTaskService.getCompletedTasksFilter(this.params.getFilterParams())
    .pipe(
      map(filters => {
        if (filters.onlyLatestCompletedForRule === undefined) {
          filters.onlyLatestCompletedForRule = [{ name: SingleFilterOption.ONLY_LATEST_COMPLETED_FOR_RULE, count: null }];
        }

        return filters;
      }),
    );
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
    .getOrganisationsByCustomerId(this.getUserCustomerId(), true)
    .pipe(
      map(FilterUtils.toFlatList),
      map(FilterUtils.convertToOrganisationMap));
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }

  private convertToFilterTypes(maintenanceFilters: MaintenanceCompletedFilterCollectionView): FilterType[] {
    return Object.keys(maintenanceFilters)
    .sort(this.orderByPattern(this.filterOrderPattern))
    .map((key: MaintenanceCompletedFilterType) => this.createConverter(key, maintenanceFilters[key]))
    .map(filterConverter => filterConverter && filterConverter.getFilters())
    .filter(Boolean);
  }

  private createConverter(type: MaintenanceCompletedFilterType,
                          maintenanceFilters: MaintenanceCompletedFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case MaintenanceCompletedFilterType.MAINTENANCE_TYPES:
        return new MaintenanceTypeFilterViewConverter(
          maintenanceFilters as MaintenanceTypeFilterView[],
          this.params,
        );
      case MaintenanceCompletedFilterType.ARTICLE_GROUPS:
        return new ArticleGroupsFilterViewConverter(
          maintenanceFilters as ArticleGroupsFilterView[],
          this.params,
        );
      case MaintenanceCompletedFilterType.LABEL:
        return new CommonFilterViewConverter(maintenanceFilters as CommonFilterView[], this.params, FilterTypes.LABEL);
      case MaintenanceCompletedFilterType.ORGANISATION:
        return new OrganisationFilterViewConverter(maintenanceFilters as OrganisationFilterView[], this.params,
          this.organisationMap, FilterTypesDisplayNames.ORGANISATION_EQUIPMENT);
      case MaintenanceCompletedFilterType.RESPONSIBLE_PERSON:
        return new ResponsiblePersonFilterViewConverter(maintenanceFilters as AssignedEmployeeFilterView[], this.params);
      case MaintenanceCompletedFilterType.TYPES:
        return new EquipmentTypeFilterViewConverter(
          maintenanceFilters as EquipmentTypeFilterView[],
          this.params,
          this.translatableStringPipe);
      case MaintenanceCompletedFilterType.EQUIPMENT_STATUS:
        return new EquipmentStatusViewConverter(
          maintenanceFilters as EquipmentStateFilterView[],
          this.params,
          this.equipmentStatusCategoryPipe,
          this.equipmentStatusNamePipe,
        );
      case MaintenanceCompletedFilterType.MAINTENANCE_CATEGORY:
        return new MaintenanceCategoryFilterViewConverter(
          maintenanceFilters as MaintenanceCategoryFilterView[],
          this.params,
          this.maintenanceCategoryPipe,
        );
      case MaintenanceCompletedFilterType.MAINTENANCE_RESULTS:
        return new MaintenanceResultFilterViewConverter(
          maintenanceFilters as MaintenanceResultFilterView[],
          this.params,
          this.maintenanceResultPipe,
        );
      case MaintenanceCompletedFilterType.ONLY_LATEST_COMPLETED_FOR_RULE:
        return new CommonFilterViewConverter(
          maintenanceFilters as CommonFilterView[],
          this.params,
          FilterTypes.ONLY_LATEST_COMPLETED_FOR_RULE,
          this.singleOptionDisplayNameResolver
        );
      case MaintenanceCompletedFilterType.MATCH_ALL_LABELS:
        return new CommonFilterViewConverter(maintenanceFilters as CommonFilterView[], this.params, FilterTypes.MATCH_ALL_LABELS);
    }
  }

}
