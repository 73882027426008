import { Section } from '../../../../shared/scheduler/section.interface';
import { EquipmentSection } from './equipment-section.interface';

export interface SubEquipmentSection extends EquipmentSection {
  equipmentContainerId?: string;
}

export function isSubEquipmentSection(section: Section): section is SubEquipmentSection {
  return (<SubEquipmentSection>section).equipmentContainerId !== undefined;
}
