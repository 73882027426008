<div fxFill fxLayout="column" class="info-window-table-view">
  <h2>{{(items.length > 1 ? 'general.project.pl' : 'general.project.s')|translate}}</h2>
  <table fxFill fxLayout="column">
    <tr fxFill fxLayout="row">
      <th class="cell-text" fxFlex="1 1 0">{{'general.project.intNumberAbbr'|translate}}</th>
      <th class="cell-text" fxFlex="1 1 0">{{'general.name.s'|translate}}</th>
      <th class="cell-text" fxFlex="1 1 0">{{'general.costCenter'|translate}}</th>
      <th class="cell-text padded_column" fxFlex="1 1 100px">{{'general.labels.start'|translate}}</th>
      <th class="cell-text padded_column" fxFlex="1 1 100px">{{'general.labels.end'|translate}}</th>
      <th class="padded_column" fxFlex="0 0 66px">{{'general.details'|translate}}</th>
    </tr>
    <tr *ngFor="let pr of items" fxFill fxLayout="row">
      <td class="cell-text project-number-cell" fxFlex="1 1 0">
        <pre *ngIf="pr.projectNumber" class="project-number"># {{pr.projectNumber?.trim()}}</pre>
      </td>
      <td class="cell-text" fxFlex="1 1 0">{{pr.projectName}}</td>
      <td class="cell-text" fxFlex="1 1 0">{{pr.projectCostCenter}}</td>
      <td class="cell-text padded_column" fxFlex="1 1 100px">{{ formatDate(pr.projectStart) }}</td>
      <td class="cell-text padded_column" fxFlex="1 1 100px">{{formatDate(pr.projectEnd) }}</td>
      <td class="padded_column" fxFlex="0 0 66px">
        <button mat-icon-button class="mat-icon-button-details"
          (click)="navigateToViewDetails(pr.projectId)">
          <mat-icon class="bh-mat-icon">visibility</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>
