import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ProjectAssigneeType } from '../../../../shared/enums/project-assignee-type.enum';
import { StockAssigneeType } from 'app/shared/enums/stock-assignee-type.enum';

@Pipe({
  name: 'assigneeType'
})
export class AssigneeTypePipe implements PipeTransform {
  private projectStockTranslationKeys = {
    [ProjectAssigneeType.CONSTRUCTION_MANAGER]: 'shared.pipes.assigneeType.constructionManager',
    [ProjectAssigneeType.FOREMAN]: 'shared.pipes.assigneeType.foreman',
    [ProjectAssigneeType.OTHER]: 'shared.pipes.assigneeType.other',
    [StockAssigneeType.STOCK_MANAGER]: 'shared.pipes.assigneeType.stockManager',
    [StockAssigneeType.OTHER]: 'shared.pipes.assigneeType.other'
  };

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    return this.projectStockTranslationKeys[value] ? this.translate(this.projectStockTranslationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
