import { EquipmentEmployeeRole } from './equipment-employee-role.enum';

export class RemoveEquipmentEmployeeAssignmentCommand {

  public constructor(
      public equipmentId: string,
      public employeeAssignmentId: string,
      public employeeId: string,
      public employeeRole: EquipmentEmployeeRole) {
  }

}

