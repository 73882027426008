<div class="user-assign-dialog">
	<div class="dialog-close-icon">
		<button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
			<mat-icon aria-hidden="true">clear</mat-icon>
		</button>
	</div>

	<mat-dialog-content fxLayout="row" fxLayoutAlign="start start">
		<div class="user-icon">
			<mat-icon>link</mat-icon>
		</div>
		<div fxFlex="350px">
			<h4>{{'modules.disposition.employeeView.createLink'|translate}}</h4>
			<form [formGroup]="form">
				<mat-form-field>
					<mat-label>{{'general.user'|translate}}</mat-label>
					<mat-select formControlName="user" disableOptionCentering="true">
						<mat-option>
							<ngx-mat-select-search [formControl]="userFilter"
                                     [clearSearchInput]="false"
																		 [placeholderLabel]="'general.labels.search'|translate:{value: 'general.user'|translate}"
																		 [noEntriesFoundLabel]="'modules.disposition.employeeView.userNotFound'|translate"></ngx-mat-select-search>
						</mat-option>
						<mat-option *ngFor="let user of users | async" [value]="user">
              {{user.name}}, {{user.firstName}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</form>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
		<button mat-button mat-dialog-close="{{abort}}">{{'general.buttons.cancel'|translate}}</button>
		<button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()">
      {{'general.buttons.save'|translate}}
    </button>
	</mat-dialog-actions>
</div>
