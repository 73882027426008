import { TransportView } from '../../contracts/transport/transport-view.interface';
import { TransportAssignmentExecutionCommand } from '../../contracts/transport/transport-assignment-execution.command';
import { ChangeTransportStateCommand } from '../../contracts/transport/transport-task-change-state.command';
import { TransportAssignmentExecutionFinishCommand } from '../../contracts/transport/transport-assignment-execution-finish.command';
import { TransportAssignmentExecutionResetCommand } from '../../contracts/transport/transport-assignment-execution-reset.command';
import { TransportAssignmentDeleteCommand } from '../../contracts/transport/transport-assignment-delete.command';
import { TransportAssignmentExecutionStartCommand } from '../../contracts/transport/transport-assignment-execution-start.command';
import { TransportTaskState } from '../enums/transport-task-status.enum';
import { environment } from 'environments/environment';
import { delay, skip, switchMap, take, tap } from 'rxjs/operators';
import { TransportCopyCommand } from '../../contracts/transport/transport-copy.command';
import { Sort } from '@angular/material/sort';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { TransportService } from './transport.service';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { BehaviorSubject, EMPTY, iif, Observable, of, Subject } from 'rxjs';
import { TransportParams } from '../contract/request-params/transport-params.interface';
import { OspDateTime } from 'app/modules/equipment/contract/osp-date-time';
import { UpdateTransportTaskCommand } from '../../contracts/transport/update-transport-task.command';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TransportTaskChecker } from './transport-task-checker.service';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportStatePipe } from '../pipes/transport-state.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransportColumnService } from './transport-column.service';

export abstract class TransportDataSource<T> {
  private _sort: Sort;

  protected _currentTransport: BehaviorSubject<TransportView> = new BehaviorSubject(null);
  protected _totalCount = new BehaviorSubject<number>(0);
  protected _data = new BehaviorSubject<T[]>([]);
  protected _updatedTransportId: BehaviorSubject<string> = new BehaviorSubject(null);
  protected transportRequest = new Subject<TransportParams>();
  protected _searchTerm: string;
  protected _pagination = new BehaviorSubject<PaginationInfo>(this.initAndReturnPagination());
  protected _listingRequestInProgress = new BehaviorSubject<boolean>(false);

  public readonly currentTransport = this._currentTransport.asObservable();
  public readonly updatedTransportId = this._updatedTransportId.asObservable();
  public readonly listingRequestInProgress = this._listingRequestInProgress.asObservable();
  public readonly transports = this._data.asObservable();
  public readonly totalCount = this._totalCount.asObservable();
  public readonly pagination = this._pagination.asObservable();

  protected abstract transportRequestListener(): void;

  public get sort(): Sort {
    return this._sort;
  }
  public set sort(value: Sort) {
    this._sort = value;
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public transport(): TransportView {
    return this._currentTransport.getValue();
  }

  public set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
  }
  constructor(
    protected transportService: TransportService,
    protected dialog: MatDialog,
    protected languageService: LanguageService,
    protected transportStatePipe: TransportStatePipe,
    protected columnService: TransportColumnService,
    private snackBar: MatSnackBar,
  ) {
    this.initListeners();
  }

  public updateListing(page: number = this._pagination.value.index, size: number = this._pagination.value.size): void {
    this._listingRequestInProgress.next(true);
    if (this._data.value.length === 0 && this._pagination.value.last && page !== 0) {
      page--;
    }
    if (size !== this._pagination.getValue().size) {
      this.columnService.selectListViewPageSize(size);
    }
    this._pagination.next({...this._pagination.value, index: page, size});

    const params: TransportParams = {
      size,
      page,
      sort: this.getSortParam(this.sort),
      terms: this._searchTerm
    }

    this.transportRequest.next(params);
  }

  public changeCurrentTransport(transportId: string): void {
    if (transportId) {
      this.transportService.getTransportTaskById(transportId)
      .subscribe(transport => this._currentTransport.next(transport));
    } else {
      this._currentTransport.next(null);
    }
  }

  public updateCurrentTransport(): void {
    if (this._currentTransport.getValue()) {
      this.transportService.getTransportTaskById(this._currentTransport.getValue().transportId)
      .subscribe(transport => this._currentTransport.next(transport));
    }
  }

  public updateTransport(command: UpdateTransportTaskCommand): Observable<string> {
    this.transportService.updateTransportationTask(command)
    .subscribe(transportId => this._updatedTransportId.next(transportId));
    return this.updatedTransportId.pipe(skip(1), take(1));
  }

  public updateCurrentAndListing(): void {
    this.updateListing();
    this.updateCurrentTransport();
  }

  private initListeners(): void {
    this.transportRequestListener();
  }

  protected updateStoreData(response: PagedResponse<T>): void {
    this.updatePagination(response);
    this._data.next(response.content);
    this._totalCount.next(response.totalElements);
    this._listingRequestInProgress.next(false);
  }

  private initAndReturnPagination(): PaginationInfo {
    return {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  protected updatePagination(response: PagedResponse<T>): void {
    this._pagination.next({
      totalElements: response.totalElements,
      totalPages: response.totalPages,
      size: response.size,
      index: response.number,
      numberOfElements: response.numberOfElements,
      last: response.last
    });
  }

  protected setPageSize(size: number): void {
    this._pagination.next({
      ...this._pagination.getValue(),
      size
    });
  }

  public copyTransport(transport: TransportView): Observable<TransportView> {
    const copyCommand = new TransportCopyCommand(transport.transportId);

    if (TransportTaskChecker.hasChangedLatestRelatedItems(transport)) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.translate(
        'modules.transportation.base.warningLatestRelatedItemsStatusChange',
        { state: this.transportStatePipe.transform(transport.state) })
      return dialogRef.afterClosed()
        .pipe(
          switchMap(result => iif(
            () => result === dialogResults.YES,
            this.transportService.copyTransport(copyCommand),
            EMPTY)),
          delay(environment.DELAY_SHORTEST),
          tap(transportId => this.changeCurrentTransport(transportId)),
          tap(() => this.showNotificationTaskCopied()),
          switchMap(() => this.currentTransport.pipe(skip(1), take(1)))
        );
    } else {
      this.transportService.copyTransport(copyCommand)
        .pipe(
          delay(environment.DELAY_SHORTEST),
          tap(() => this.showNotificationTaskCopied()))
        .subscribe(transportId => this.changeCurrentTransport(transportId));

      return this.currentTransport.pipe(skip(1), take(1));
    }
  }

  protected getSortParam(sort: Sort): string {
    return this.isSortValid(sort) ? this.toSortString(sort) : null
  }

  protected isSortValid(sort: Sort): boolean {
    return Boolean(sort && sort.active && sort.direction);
  }

  protected toSortString(sort: Sort): string {
    return `${sort.active},${sort.direction}`;
  }

  public setNewStatusOfTransportTask(transport: TransportView, state: TransportTaskState, date: OspDateTime): Observable<string> {
    switch (transport.state) {
      case TransportTaskState.PLANNED:
        return this.fromPlanned(transport, state, date);
      case TransportTaskState.IN_PROGRESS:
        return this.fromInProgress(transport, state, date);
      case TransportTaskState.DONE:
        return this.fromDone(transport, state);
      case TransportTaskState.CANCELED:
        return this.fromCanceled(transport, state);
      default:
        return this.setStatusOfTransportTask(new ChangeTransportStateCommand(transport.transportId, state));
    }
  }

  private fromPlanned(transport: TransportView, state: TransportTaskState, date: OspDateTime): Observable<string> {
    switch (state) {
      case TransportTaskState.IN_PROGRESS:
        const transportAssignmentExecutionStartCommand = new TransportAssignmentExecutionStartCommand(
          transport.transportId,
          transport.equipmentAssignmentDetails.assignmentId,
          date,
        );
        return this.startExecutionAssignment(transportAssignmentExecutionStartCommand);
      case TransportTaskState.PLANNABLE:
      case TransportTaskState.CANCELED:
        const transportAssignmentDeleteCommand = new TransportAssignmentDeleteCommand(
          transport.transportId,
          transport.equipmentAssignmentDetails.assignmentId,
          state,
        );
        return this.deleteTransportAssignment(transportAssignmentDeleteCommand);
    }
  }

  private fromInProgress(transport: TransportView, state: TransportTaskState, endDate: OspDateTime): Observable<string> {
    switch (state) {
      case TransportTaskState.PLANNED:
        const transportAssignmentExecutionResetCommand = new TransportAssignmentExecutionResetCommand(
          transport.transportId,
          transport.equipmentAssignmentDetails.assignmentId,
        )
        return this.resetExecutionAssignment(transportAssignmentExecutionResetCommand);
      case TransportTaskState.CANCELED:
        const transportAssignmentDeleteCommand = new TransportAssignmentDeleteCommand(
          transport.transportId,
          transport.equipmentAssignmentDetails.assignmentId,
          state,
        );
        return this.deleteTransportAssignment(transportAssignmentDeleteCommand);
      case TransportTaskState.DONE:
        const transportAssignmentExecutionFinishCommand = new TransportAssignmentExecutionFinishCommand(
          transport.transportId,
          transport.equipmentAssignmentDetails.assignmentId,
          endDate,
        );
        return this.finishExecutionAssignment(transportAssignmentExecutionFinishCommand);
    }
  }

  private fromDone(transport: TransportView, state: TransportTaskState): Observable<string> {
    switch (state) {
      case TransportTaskState.IN_PROGRESS:
      return this.checkRelatedItemsForStateInProgress(transport)
        .pipe(
          switchMap(() => this.resetFinishedExecutionAssignment(new TransportAssignmentExecutionResetCommand(
              transport.transportId,
              transport.equipmentAssignmentDetails.assignmentId))
            ));
      default:
        return this.setStatusOfTransportTask(new ChangeTransportStateCommand(transport.transportId, state));
    }
  }

  private fromCanceled(transport: TransportView, state: TransportTaskState): Observable<string> {
    switch (state) {
      case TransportTaskState.DRAFT:
      return this.checkRelatedItemsForStateDraft(transport)
        .pipe(switchMap(() => this.setStatusOfTransportTask(new ChangeTransportStateCommand(transport.transportId, state))));
      default:
        return this.setStatusOfTransportTask(new ChangeTransportStateCommand(transport.transportId, state));
    }
  }

  private checkRelatedItemsForStateInProgress(transport: TransportView): Observable<TransportView> {
    if (TransportTaskChecker.hasDeletedLatestRelatedItems(transport)) {
      return this.warningStatusChangeLatestRelatedItemDeleted(transport);
    }
    if (TransportTaskChecker.hasChangedLatestRelatedItems(transport)) {
      return this.confirmationStatusChangeLatestRelatedItemChanged(transport);
    }
    return of(transport);
  }

  private checkRelatedItemsForStateDraft(transport: TransportView): Observable<TransportView> {
    if (TransportTaskChecker.hasChangedLatestRelatedItems(transport)) {
      return this.confirmationStatusChangeLatestRelatedItemChanged(transport);
    }
    return of(transport);
  }

  private warningStatusChangeLatestRelatedItemDeleted(transport: TransportView): Observable<never> {
    const dialogRef = this.dialog.open(WarningDialogComponent);
    dialogRef.componentInstance.message = this.translate(
      'modules.transportation.base.warningLatestRelatedItemsStatusChangeImpossible',
      {
        state: this.transportStatePipe.transform(transport.state),
        impossibleState: this.transportStatePipe.transform(TransportTaskState.IN_PROGRESS)
      }
    );
    return EMPTY;
  }

  private confirmationStatusChangeLatestRelatedItemChanged(transport: TransportView): Observable<TransportView> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate(
      'modules.transportation.base.warningLatestRelatedItemsStatusChange',
      { state: this.transportStatePipe.transform(transport.state) }
    );
    return dialogRef.afterClosed()
      .pipe(switchMap(res => iif(() => res === dialogResults.YES, of(transport), EMPTY)));
  }

  protected setStatusOfTransportTask(command: ChangeTransportStateCommand): Observable<string> {
    return this.transportService.setStatusOfTransportTask(command);
  }

  private deleteTransportAssignment(command: TransportAssignmentDeleteCommand): Observable<string> {
    return this.transportService.deleteAssignment(command);
  }

  private startExecutionAssignment(command: TransportAssignmentExecutionStartCommand): Observable<string> {
    return this.transportService.startExecutionAssignment(command);
  }

  private resetExecutionAssignment(command: TransportAssignmentExecutionResetCommand): Observable<string> {
    return this.transportService.resetExecutionAssignment(command);
  }

  private finishExecutionAssignment(command: TransportAssignmentExecutionFinishCommand): Observable<string> {
    return this.transportService.finishExecutionAssignment(command);
  }

  public updateExecutionDates(command: TransportAssignmentExecutionCommand): Observable<string> {
    return this.transportService.updateExecutionDates(command);
  }

  private resetFinishedExecutionAssignment(command: TransportAssignmentExecutionResetCommand): Observable<string> {
    return this.transportService.resetFinishedExecutionAssignment(command);
  }

  private showNotification(message: string, actionLabel: string): void {
    this.snackBar.open(message, actionLabel, { duration: 3000 });
  }

  private showNotificationTaskCopied(): void {
    this.showNotification(
      this.translate('modules.transportation.transportDetailView.notificationTaskCopied'),
      this.translate('general.buttons.okay'));
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
