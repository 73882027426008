<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
  <div class="sort-select" fxLayout="row" fxFlex="1 1 100%" fxLayoutAlign="center center">
    <div class="sort-select__label">{{'general.labels.sortBy'|translate}}:</div>
    <mat-form-field class="sort-select__field-container">
      <mat-select
        [placeholder]="'modules.transportation.transportList.selectOption'|translate"
        [formControl]="ordersControl"
      >
        <mat-option *ngFor="let option of sortOrderOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix *ngIf="showClearSuffix" (click)="onClear($event)">clear</mat-icon>
    </mat-form-field>
  </div>

  <button
    class="sort-direction"
    mat-button (click)="directionChange()"
    [disabled]="currentSortOrderOption?.disableSort"
  >
    <fa-icon
      class="sort-direction__icon"
      [icon]="faIconSortAsc"
      [ngClass]="{ 'direction-desc': sortDirection === 'desc' }"
    ></fa-icon>
  </button>
</div>
