import { FilterParams } from '../../../filter-params.class';
import { EquipmentStateFilterView } from '../../../filter-view/equipment-state-filter-view.interface';
import { FilterTypes } from '../../../filter-types.enum';
import { UpdateFilterCommand } from '../../../update-filter-command.interface';


export class EquipmentStatusFilterConsistencyResolver {

  private type = FilterTypes.EQUIPMENT_STATE;

  constructor(
    private originalFilters: EquipmentStateFilterView[],
    private filterParams: FilterParams
  ) {}

  public getCommandsToRestoreConsistency(): UpdateFilterCommand[] {
    return [
      ...this.getCommandsDeleteNotExistingSelected(),
      ...this.getCommandsSelectNecessary()
    ];
  }

  private getCommandsDeleteNotExistingSelected(): UpdateFilterCommand[] {
    const allFilterValues = new Set<string>(this.getAllValuesFromFilter());
    const valuesToUnselect = this.filterParams
      .getFilterTypeParams(this.type)
      .filter(v => !allFilterValues.has(v));
    return valuesToUnselect.map(v => this.getCommand(v, false));
  }

  private getAllValuesFromFilter(): string[] {
    return this.originalFilters.reduce((acc, filter) => ([
      ...acc,
      filter.category,
      ...filter.statuses.map(({ equipmentStatusId }) => equipmentStatusId)
    ]), []);
  }

  private getCommandsSelectNecessary(): UpdateFilterCommand[] {
    const needToSelect = this.originalFilters?.reduce((acc, filter) => ([
      ...acc,
      ...this.getNeedToSelect(filter)
    ]), []);

    return needToSelect.map(v => this.getCommand(v, true));
  }

  private getNeedToSelect(filter: EquipmentStateFilterView): string[] {
    const unselectedParent = this.getUnselectedParent(filter);
    const unselectedChildren = this.getUnselectedChildren(filter);
    const isParentSelected = !Boolean(unselectedParent);
    const isAllChildrenSelected = unselectedChildren.length === 0;

    if (isParentSelected && !isAllChildrenSelected) {
      return unselectedChildren;
    } else if (!isParentSelected && isAllChildrenSelected) {
      return [unselectedParent];
    }
    return [];
  }

  private getUnselectedParent(filter: EquipmentStateFilterView): string {
    return !this.filterParams.hasParam(this.type, filter.category) ? filter.category : null;
  }

  private getUnselectedChildren(filter: EquipmentStateFilterView): string[] {
    return filter.statuses
      .filter(({ equipmentStatusId }) => !this.filterParams.hasParam(this.type, equipmentStatusId))
      .map(({ equipmentStatusId }) => equipmentStatusId);
  }

  private getCommand(storeName: string, newValue: boolean, filterType = this.type): UpdateFilterCommand {
    return { filterType, storeName, newValue };
  }

}
