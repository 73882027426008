<div class="table-container">
  <table mat-table [dataSource]="connectors">

    <ng-container matColumnDef="connectorType" sticky>
      <th mat-header-cell *matHeaderCellDef>{{'general.type'|translate}}</th>
      <td mat-cell *matCellDef="let connector"> {{connector.connectorType}} </td>
    </ng-container>

    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef>{{'general.name.s'|translate}}</th>
      <td mat-cell *matCellDef="let connector"> {{connector.name}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.active'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        <mat-icon class="ok" *ngIf="connector.active">done</mat-icon>
        <mat-icon class="error" *ngIf="!connector.active">clear</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="statusCode">
      <th mat-header-cell *matHeaderCellDef>{{'general.status'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        <mat-icon [ngClass]="connector.statusCode.toLowerCase()"
                  matTooltip="{{connector.statusMessage}}">
          {{getStatusIcon(connector.statusCode)}}
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="running">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.isRunning'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        <mat-icon class="ok" *ngIf="connector.running">done</mat-icon>
        <span *ngIf="!connector.running">-</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastRunSuccessful">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.lastRunSuccessful'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        <span *ngIf="!connector.active">-</span>
        <mat-icon class="ok" *ngIf="connector.lastRunSuccessful && connector.active">done</mat-icon>
        <mat-icon class="error" *ngIf="!connector.lastRunSuccessful  && connector.active">clear</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="connectionSuccessful">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.connectionSuccessful'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        <span *ngIf="!connector.active">-</span>
        <mat-icon class="ok" *ngIf="connector.connectionSuccessful && connector.active">done</mat-icon>
        <mat-icon class="error" *ngIf="!connector.connectionSuccessful && connector.active">clear</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastWaitDuration">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.lastWaitDuration'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        {{ formatDuration(connector.lastWaitDuration) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastRunDuration">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.lastRunDuration'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        {{ formatDuration(connector.lastRunDuration) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastStarted">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.lastStarted'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        {{ connector.lastStarted | date:'dd.MM.yyyy - HH:mm:ss' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="nextStart">
      <th mat-header-cell *matHeaderCellDef>{{'modules.connector.genericConnectorList.nextStart'|translate}}</th>
      <td mat-cell *matCellDef="let connector">
        <span *ngIf="!connector.active || connector.running">-</span>
        <span *ngIf="connector.active && !connector.running">
          {{ connector.nextStart | date:'dd.MM.yyyy - HH:mm:ss' }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let connector">
        <div class="buttons-horizontal">
          <button mat-button
                  (click)="edit.emit(connector)"
                  [matTooltip]="'general.buttons.change'|translate">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button mat-button
                  *ngIf="connector.active"
                  (click)="update.emit(connector)"
                  [matTooltip]="'general.buttons.deactivate'|translate">
            <mat-icon>pause</mat-icon>
          </button>
          <button mat-button
                  *ngIf="!connector.active"
                  (click)="activate.emit(connector)"
                  [matTooltip]="'general.buttons.activate'|translate">
            <mat-icon>play_arrow</mat-icon>
          </button>
          <button mat-button
                  (click)="delete.emit(connector)"
                  [matTooltip]="'general.buttons.delete'|translate">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
