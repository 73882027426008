import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

export type DetectScrollEvent = Pick<HTMLDivElement, 'scrollTop' | 'scrollHeight' | 'clientHeight'>;

@Directive({
  selector: '[bhScrollPosition]',
})
export class ScrollPositionDirective {
  @Output() detectScrollPosition = new EventEmitter<DetectScrollEvent>();

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  detectScroll(): void {
    const element = this.el.nativeElement as HTMLDivElement;
    this.detectScrollPosition.emit({
      scrollTop: element.scrollTop,
      scrollHeight: element.scrollHeight,
      clientHeight: element.clientHeight,
    });
  }
}
