import { DayOfWeekNumberIso } from './../../enums/day-of-week.enum';
import { BehaviorSubject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TimefencingData, TimeUnit, TimeUnitValue } from 'app/shared/contract/timefencing-data.interface';
import { TimeConfiguration, WeekDayConfiguration } from 'app/shared/contract/week-day-configuration.type';
import { DayOfWeek } from 'app/shared/enums/day-of-week.enum';

@Component({
  selector: 'bh-timefence',
  templateUrl: './timefence.component.html',
  styleUrls: ['./timefence.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimefenceComponent),
      multi: true
    },
    {provide: MatFormFieldControl, useExisting: TimefenceComponent}
  ]
})
@UntilDestroy()
export class TimefenceComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() timezone: string;
  private interval = 30;
  private sampleTimeUnits: TimeUnit[];
  private _columns: string[];
  private _onlyTimeColumns: string[];

  private tableData: TimefencingData[] = [];
  private tableDataSubject = new BehaviorSubject<TimefencingData[]>([]);
  public readonly tableData$ = this.tableDataSubject.asObservable();

  private weekDays: WeekDayConfiguration;
  private weekDaysSubject = new BehaviorSubject<WeekDayConfiguration>(null);
  public readonly weekDays$ = this.weekDaysSubject.asObservable();

  public propagateChange = (value: any) => {};

  constructor() { }

  public ngOnInit() {
    this.reset();

    this.weekDays$.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.propagateChange(value));
  }

  public ngOnDestroy(): void { }

  public get columns(): string[] {
    return this._columns;
  }
  public get onlyTimeColumns(): string[] {
    return this._onlyTimeColumns;
  }

  public set weekDayConfiguration(value: WeekDayConfiguration) {
    _.keys(value).forEach(day => {
      value[day].forEach((time: TimeConfiguration) =>
       this.setValueSelected({ day: <DayOfWeek>day, hour: time.hour, minutes: time.minutes }, true))
    });

    this.weekDays = value;
    this.weekDaysSubject.next(this.weekDays);
    this.tableDataSubject.next(this.tableData);
  }

  public writeValue(obj: any): void {
    this.weekDayConfiguration = obj;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void { }

  public selectTime(rowIndex: number, timeColumn: string): void {
    this.changeSelection(rowIndex, timeColumn);
  }

  public reset(): void {
    this.weekDays = null;
    this.weekDaysSubject.next(null);
    this.generateTableData();
  }

  public changeSelection(index: number, timeColumn: string): void {
    if (!(this.tableData[index] && this.tableData[index][timeColumn])) {
      return;
    }
    const selectedTimeUnit: TimeUnit = (<TimeUnit>this.tableData[index][timeColumn]);
    selectedTimeUnit.isSelected = !selectedTimeUnit.isSelected;
    this.tableDataSubject.next([...this.tableData]);

    this.changeWeekDayConfiguration(selectedTimeUnit.value, selectedTimeUnit.isSelected);
  }

  private changeWeekDayConfiguration(timeUnitValue: TimeUnitValue, isSelected: boolean): void {
    if (isSelected) {
      this.addTimeConfiguration(timeUnitValue);
    } else {
      this.removeTimeConfiguration(timeUnitValue);
    }
  }

  private addTimeConfiguration({ day, hour, minutes }: TimeUnitValue): void {
    if (!this.weekDays || !this.weekDays[day]) {
      this.weekDays = { ...(this.weekDays ? this.weekDays : {}), [day]: [] };
    }
    this.weekDays[day].push({ hour, minutes });
    this.weekDaysSubject.next(this.weekDays);
  }

  private removeTimeConfiguration({ day, hour, minutes }: TimeUnitValue): void {
    const configurationIndex = this.weekDays[day].findIndex(({ hour: h, minutes: m }) => h === hour && m === minutes)
    this.weekDays[day].splice(configurationIndex, 1);

    if (this.weekDays[day].length === 0) {
      this.weekDays = _.pick(this.weekDays, _.keys(this.weekDays).filter(key => key !== day));
    }
    if (_.keys(this.weekDays).length === 0) {
      this.weekDays = null;
    }

    this.weekDaysSubject.next(this.weekDays);
  }

  private setValueSelected(timeUnitValue: TimeUnitValue, isSelected: boolean): void {
    const time = this.timeUnitValueToLocal(timeUnitValue);
    const timeUnitLabel = this.getLabel(time.hour, time.minutes);
    const index = this.tableData.findIndex(({ day }) => day === time.day);

    if (!(this.tableData[index] && this.tableData[index][timeUnitLabel])) {
      return;
    }
    (<TimeUnit>this.tableData[index][timeUnitLabel]).isSelected = isSelected;
  }

  private timeUnitToUtc({ day, hour, minutes }: TimeUnitValue): TimeUnitValue {
    const dateUtc = moment
      .tz(this.timezone ?? null)
      .hours(hour)
      .minutes(minutes)
      .seconds(0)
      .isoWeekday(day)
      .utc();
    return this.momentToTimeUnitValue(dateUtc);
  }

  private timeUnitValueToLocal({ day, hour, minutes }: TimeUnitValue): TimeUnitValue {
    const dateUtc = moment
      .utc(`${hour}:${minutes}`, 'hh:mm')
      .isoWeekday(DayOfWeekNumberIso[day])
      .tz(this.timezone ?? null);
    return this.momentToTimeUnitValue(dateUtc);
  }

  private momentToTimeUnitValue(date: moment.Moment): TimeUnitValue {
    const weekDay = _.findKey(DayOfWeekNumberIso, (v) => v === date.isoWeekday());
    return { day: DayOfWeek[weekDay],
      hour: date.hours(),
      minutes: date.minutes()
    };
  }

  // Table data generation section
  private generateTableData() {
    this.sampleTimeUnits = [...this.generateTimeUnits('00:00', this.interval, 'm')];
    this._onlyTimeColumns = this.sampleTimeUnits.map(({ label }) => label);
    this._columns = ['day', ...this._onlyTimeColumns];
    this.tableData = _.values(DayOfWeek).map(day => this.generateTimefencingDay(<DayOfWeek>day));
    this.tableDataSubject.next(this.tableData);
  }

  private generateTimeUnits(desiredStartTime: string, interval: number, period: moment.unitOfTime.Base): TimeUnit[] {
    const periodsInADay = moment.duration(1, 'day').as(period);

    const timeLabels: TimeUnit[] = [];
    const startTimeMoment = moment(desiredStartTime, 'hh:mm');
    for (let i = 0; i < periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      const hour = startTimeMoment.hour();
      const minutes = startTimeMoment.minute();

      timeLabels.push({ label: this.getLabel(hour, minutes), isSelected: false, value: { day: null, hour, minutes } });
    }

    return timeLabels;
  };

  private getLabel(hour: number, minute: number): string {
    return moment(`${hour}:${minute}`, 'hh:mm').format('HH:mm');
  }

  private generateTimefencingDay(dayName: DayOfWeek): TimefencingData {
    return this.sampleTimeUnits.reduce((acc, { label, isSelected, value: { hour, minutes } }) => {
      const timeUnitValue = this.timeUnitToUtc({ day: dayName, hour, minutes });

      return { ...acc, [label]: { label, isSelected, value: timeUnitValue } };
    },
    { day: dayName });
  }
  // END OF: Table data generation section
}
