import { Injectable } from '@angular/core';
import { BaseColumnDefinitionService } from '../../../../shared/services/base-column-definition.service';
import { ColumnDefinitionService } from '../../../../shared/contract/column-definition-service.interface';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { LanguageService } from '../../../../shared/services/language.service';
import { OperatingHoursPipe } from '../../../../shared/pipes/operating-hours.pipe';
import { ContactTypeResolverPipe } from '../../../../shared/pipes/contact-type-resolver.pipe';
import { ColumnDefinitionKeys } from '../../../../shared/enums/column-definition-keys.enum';
import { ConstructionYearFormatPipe } from '../../../equipment/shared/pipes/construction-year.pipe';
import { DimensionUnitPipe } from '../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable({
  providedIn: 'root'
})
export class TransportColumnDefinitionService  extends BaseColumnDefinitionService implements ColumnDefinitionService {

  private _availableColumns: ColumnDefinition[];

  constructor(
    protected dimensionUnitPipe: DimensionUnitPipe,
    protected dimensionUnitConverterPipe: DimensionUnitConverterPipe,
    protected languageService: LanguageService,
    protected operatingHoursPipe: OperatingHoursPipe,
    protected contactTypeResolverPipe: ContactTypeResolverPipe,
    protected constructionYearPipe: ConstructionYearFormatPipe,
    protected translatableStringPipe: TranslatableStringPipe,
  ) {
    super(languageService,
      dimensionUnitPipe,
      dimensionUnitConverterPipe,
      operatingHoursPipe,
      contactTypeResolverPipe,
      constructionYearPipe,
      translatableStringPipe);
    this.initColumnDefinitions();
  }

  public get availableColumns(): ColumnDefinition[] {
    return this._availableColumns;
  }

  protected initColumnDefinitions(): void {
    this._availableColumns = [
      ColumnDefinitionKeys.TRANSPORT_IDENTIFIER,
      ColumnDefinitionKeys.TRANSPORT_STATE,
      ColumnDefinitionKeys.TRANSPORT_PRIORITY,
      ColumnDefinitionKeys.TRANSPORT_DUE_DATE,
      ColumnDefinitionKeys.TRANSPORT_TITLE,
      ColumnDefinitionKeys.TRANSPORT_DESCRIPTION,
      ColumnDefinitionKeys.TRANSPORT_START_ITEM,
      ColumnDefinitionKeys.TRANSPORT_TARGET_ITEM,
      ColumnDefinitionKeys.TRANSPORT_EQUIPMENT_DISPLAY_NAME,
      ColumnDefinitionKeys.TRANSPORT_ESTIMATED_DURATION,
      ColumnDefinitionKeys.TRANSPORT_RELATED_PROJECT,
      ColumnDefinitionKeys.TRANSPORT_START_DATE,
      ColumnDefinitionKeys.TRANSPORT_END_DATE,
      ColumnDefinitionKeys.TRANSPORT_EXECUTION_START_DATE,
      ColumnDefinitionKeys.TRANSPORT_EXECUTION_END_DATE,
      ColumnDefinitionKeys.TRANSPORT_TYPE,
      ColumnDefinitionKeys.GENERAL_OPEN_DETAILS]
    .reduce((acc, defKey) => ([ ...acc, this.columnDefinitions[defKey] ]), [])
    .filter(Boolean);
  }
}

