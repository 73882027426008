<div class="app-info-component">
  <div class="app-info-component-header">
    <div class="app-info-component-header-img"></div>
    <div class="dialog-close-icon">
      <button mat-icon-button mat-dialog-close tabindex='-1'>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="app-info-component-info">
    <p>{{'modules.navigation.appInfo.helpText'|translate:{ value:(applicationName) } }}</p>
    <div [ngClass]="{'app-info-component-support-landscape': !isMobile, 'app-info-component-support-mobile': isMobile}">
      <div *ngIf="!isTriathlonTheme()" class="app-info-component-support-content">
        <mat-icon class="app-info-component-icon" color="primary">phone</mat-icon>
        <a class="info-link" href="{{supportInfo.phone.href}}">{{supportInfo.phone.value}}</a>
      </div>
      <div class="app-info-component-support-content">
        <mat-icon class="app-info-component-icon" color="primary">email</mat-icon>
        <a class="info-link" href="{{supportInfo.mail.href}}">{{supportInfo.mail.value}}</a>
      </div>
      <div *ngIf="!isWNTheme() && !isTriathlonTheme()" class="app-info-component-support-content">
        <mat-icon class="app-info-component-icon" color="primary">chat</mat-icon>
        <a class="info-link" href='{{ contactLink }}' target='_blank'>{{'modules.navigation.appInfo.linkContactForm'|translate}}</a>
      </div>
    </div>

    <div *ngIf="!isWNTheme() && !isTriathlonTheme()" class="app-info-component-releaseinfo">
      {{'modules.navigation.appInfo.releaseInfo'|translate}}
      <a href='{{ link }}' target='_blank'>{{'modules.navigation.appInfo.linkHere'|translate}}</a>
    </div>

    <div class="app-info-component-about">
      <div class="app-info-component-about-section">
        <p class="app-info-component-about-content" *ngIf="version">{{'modules.navigation.appInfo.labelVersion'|translate}}: {{ version }}</p>
        <p cdkAriaLive="app-info-component-about-content">{{'modules.navigation.appInfo.labelGitHash'|translate}}: {{ commit }}</p>
      </div>
      <p *ngIf="ABGLink as link" class="app-info-component-about-content">
        <a class="info-link" [href]="link" target='_blank'>{{'modules.navigation.appInfo.agbTitle'|translate}}</a>
      </p>
    </div>
  </div>
</div>
