import { Injectable } from '@angular/core';
import { KeycloakService } from '../../core/keycloak';

@Injectable()
export class LocalUserStorageService {

  public static USER_PREFIX = 'US';
  /**
   * store user specific value in local storage
   */
  public static setUserValue(key: string, value: string, userId: string) {
    if (!userId) {
      throw new Error('No user id available');
    }
    let storageKey = LocalUserStorageService.USER_PREFIX + ':' + userId + ':' + key;
    LocalUserStorageService.setValue(storageKey, value);
  }

  public static getUserValue(key: string, userId: string): string {
    if (!userId) {
      throw new Error('No user id available');
    }
    return LocalUserStorageService.getValue(LocalUserStorageService.USER_PREFIX + ':' + userId + ':' + key);
  }

  /**
   * set persistent value
   */
  static setValue(key: string, value: string) {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }

  /**
   * get persistent value
   */
  static getValue(key: string): string {
    return localStorage.getItem(key);
  }

  static getValueDefault<T>(key: string, defaultValue: T): T {
    let value = JSON.parse(localStorage.getItem(key));

    if (!value) {
      value = defaultValue;
    }

    return value;
  }

  constructor(private authService: KeycloakService) {
  }

  /**
   * get user specific persistent value from local storage
   */
  public getUserValue(key: string): string {
    const userId = this.authService.getUserUserId();
    if (!userId) {
      throw new Error('No user id available');
    }
    return LocalUserStorageService.getValue(LocalUserStorageService.USER_PREFIX + ':' + userId + ':' + key);
  }

  /**
   * store user specific value in local storage
   */
  public setUserValue(key: string, value: string) {
    const userId = this.authService.getUserUserId();
    if (!userId) {
      throw new Error('No user id available');
    }
    LocalUserStorageService.setUserValue(key, value, userId);
  }

}
