<div class="error-snackbar" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <mat-icon color="warn">error_outline</mat-icon>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <div>{{'modules.userrole.userAddEdit.roleAuthConflict' | translate}}</div>
    <div>{{'modules.userrole.userAddEdit.roleAuthConflictSecondary' | translate}}</div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch">
    <button class="error-snackbar__dismiss" mat-icon-button (click)="dismiss()">
      <mat-icon class="bh-mat-icon">close</mat-icon>
    </button>
  </div>
</div>
