import { EventEmitter, Input, Output, Directive } from '@angular/core';
import {GridsterItem} from 'angular-gridster2';

@Directive()
export class BasicWidget {
  @Input() widget: GridsterItem;
  @Input() index: number;
  @Output() deleted: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  onDeleted(): void {
    this.deleted.emit(this.index);
  }
}
