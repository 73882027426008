import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { ChargeType } from '../enums/charge-type.enum';

@Pipe({
  name: 'chargeTypeTranslator'
})
export class ChargeTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(value: string, args?: any): string {
    switch (value) {
      case ChargeType.BAD_WEATHER:
        return this.translate('general.chargeType.badWeather');
      case ChargeType.FREE_OF_CHARGE:
        return this.translate('general.chargeType.freeOfCharge');
      case ChargeType.IN_CHARGE:
        return this.translate('general.chargeType.inCharge');
      case ChargeType.IN_USE:
        return this.translate('general.chargeType.inUse');
      case ChargeType.READY_TO_PICKUP:
        return this.translate('general.chargeType.readyToPickup');
      case ChargeType.RECOMMISSIONING:
        return this.translate('general.chargeType.recommissioning');
      case ChargeType.STANDSTILL:
        return this.translate('general.chargeType.standstill');
      case ChargeType.STOCK:
        return this.translate('general.chargeType.stock');
      case ChargeType.WITHOUT_CHARGE:
        return this.translate('general.chargeType.withoutCharge');
      case null:
        return '-';
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
