import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { MaintenanceTaskCompletedDataSource } from '../../../maintenance-task-completed.datasource';
import { AdapterTabControllerDatasource } from './adapter-tab-controller-datasource.interface';

export class AdapterMaintenanceTaskCompletedDataSource implements AdapterTabControllerDatasource<TaskCompleted> {
  public readonly data = this.datasource.completedTasks;
  constructor(private datasource: MaintenanceTaskCompletedDataSource) {}

  public loadById(id: string): void {
    this.datasource.loadTasksForEquipment(id);
  }

  public reset(id: string): void {
    this.datasource.reset(id);
  }
}
