import { EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { OspThemeSwitcherService } from './services/osp-theme-switcher.service';
import { OspUiPipesModule } from './pipes/osp-ui-pipes.module';


@NgModule({
  declarations: [],
  imports: [
    OspUiPipesModule,
  ]
})
export class OspUiModule {
  static forRoot(providers?: Array<Provider | EnvironmentProviders>): ModuleWithProviders<OspUiModule> {
    return {
      ngModule: OspUiModule,
      providers: [
        ...(providers ?? []),
        OspThemeSwitcherService
      ]
    }
  }
}
