import { Component, OnInit } from '@angular/core';
import { BaseEmployeeDispositionFormItem } from '../../base/form-item/base-employee-disposition-form-item.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LanguageService } from 'app/shared/services/language.service';

@UntilDestroy()
@Component({
  selector: 'bh-employee-disposition-form-item',
  templateUrl: './employee-disposition-form-item.component.html',
  styleUrls: ['./employee-disposition-form-item.component.scss']
})
export class EmployeeDispositionFormItemComponent extends BaseEmployeeDispositionFormItem implements OnInit {

  constructor(languageService: LanguageService) {
    super(languageService);
   }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
