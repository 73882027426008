import { FilterType, RenderStrategy } from '../../../contract/filter/filter-type';
import { FilterGroup } from '../../../contract/filter/filter-group';
import { FilterValue } from '../../../contract/filter/filter-value';
import { each, groupBy, keyBy, memoize, omit } from 'lodash';
import { FilterTypes } from '../../../contract/filter/filter-types.enum';

export enum FilterItemType {
  TYPE = 0,
  GROUP = 1,
  FILTER = 2
}

export type FilterItem = {
  track: string,
  type: FilterItemType,
  renderStrategy: RenderStrategy,
  offset: number,
  filterTypeName?: string,
  filterGroupName?: string,
  filterGroupType?: string,
  item: FilterType | FilterGroup | FilterValue,
  hidden?: boolean,
  index?: number,
};

let convertFilterType = memoize((filterItem: FilterType): FilterItem[] => {
  const filterRaw: FilterItem[] = [];

  let arrayLength = 0;
  if (filterItem.display !== false) {
    filterRaw.push({
      track: `type-${filterItem.typeName}`,
      type: FilterItemType.TYPE,
      renderStrategy: filterItem.renderStrategy,
      offset: 0,
      filterTypeName: filterItem.typeName,
      item: filterItem,
      hidden: filterItem.typeName === FilterTypes.MATCH_ALL_LABELS,
    });

    arrayLength = filterRaw.length;
  }

  filterItem.groups.forEach(filterGroup => {
    if (filterGroup.groupName !== '' && filterGroup.display !== false) {
      filterRaw.push({
        track: `type-${filterItem.typeName}-group-${filterGroup.groupName}`,
        type: FilterItemType.GROUP,
        renderStrategy: filterItem.renderStrategy,
        offset: 0,
        filterTypeName: filterItem.typeName,
        filterGroupName: filterGroup.groupName,
        filterGroupType: filterGroup.groupType,
        item: filterGroup,
        hidden: filterItem.typeName === FilterTypes.MATCH_ALL_LABELS,
      });
    }
    if (filterItem.renderStrategy === RenderStrategy.GROUPED_TREE
      || filterItem.renderStrategy === RenderStrategy.RELATIONS_TREE) {
      appendTree(filterRaw, filterItem, filterGroup, filterGroup.filters);
    } else {
      filterGroup.filters.forEach(filter => {
        if (filter.display !== false) {
          filterRaw.push({
            track: filter.storeName,
            type: FilterItemType.FILTER,
            renderStrategy: filterItem.renderStrategy,
            offset: 0,
            filterTypeName: filterItem.typeName,
            filterGroupName: filterGroup.groupName,
            filterGroupType: filterGroup.groupType,
            item: filter,
            hidden: filterItem.typeName === FilterTypes.MATCH_ALL_LABELS,
          });
        }
      });
    }
  });

  if (arrayLength === filterRaw.length) {
    filterRaw.splice(filterRaw.length - 1, 1)
  }

  return filterRaw;
});

export function toFilterItemsArray(filters: FilterType[]): FilterItem[] {
  const filterRaw: FilterItem[] = [];

  (filters || [])
  .flatMap(filterItem => convertFilterType(filterItem))
  .forEach((filterItem, index) => filterRaw.push({ ...filterItem, index }));

  return filterRaw;
}

function appendTree(filterRaw: FilterItem[], filterType: FilterType, filterGroup: FilterGroup, filterValues: FilterValue[]) {
  let catsById: any = keyBy(filterValues, (value: FilterValue) => value.storeName || '');
  let groupedByParents: any = groupBy(filterValues, (value: FilterValue) => {
    if (!value.parentStoreName || !catsById[value.parentStoreName]) {
      return '';
    } else {
      return value.parentStoreName;
    }
  });

  each(omit(groupedByParents, ''), (children, parentId) => {
    if (!!catsById[parentId]) {
      catsById[parentId].children = children;
    }
  });

  return (groupedByParents[''] || []).forEach(node => appendTreeNode(filterRaw, filterType, filterGroup, node));
}

function appendTreeNode(filterRaw: FilterItem[],
                        filterType: FilterType,
                        filterGroup: FilterGroup,
                        treeNode: FilterValue & any,
                        offset: number = 0) {

  treeNode.display = treeNode.display || isVisibleChild(treeNode.children);

  if (filterType.renderStrategy === RenderStrategy.GROUPED_TREE || treeNode.display) {
    filterRaw.push({
      track: treeNode.storeName,
      type: FilterItemType.FILTER,
      renderStrategy: filterType.renderStrategy,
      offset: offset,
      filterTypeName: filterType.typeName,
      filterGroupName: filterGroup.groupName,
      filterGroupType: filterGroup.groupType,
      item: treeNode,
      hidden: !treeNode.display,
    });
  }

  if (treeNode.children && treeNode.children.length > 0) {
    treeNode.children.forEach(node => appendTreeNode(filterRaw, filterType, filterGroup, node, offset + 1));
    treeNode.children = undefined;
  }
}

function isVisibleChild(children: any[]): boolean {
  return (children || []).some(node => node.display || isVisibleChild(node.children));
}
