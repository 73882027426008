import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewStock } from '../../../../contract/view-stock';
import { StockStore } from '../../../shared/stock.store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from '../../../../../../shared/router-history';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { isLocationType, StockType } from '../../../../../../shared/enums/stock-type';
import { ScanCodeEditComponent } from 'app/shared/components/scan-code-edit/scan-code-edit.component';
import { IViewScanCodeData } from 'app/shared/components/scan-code-edit/scan-code.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { PairStockScanCodeCommand } from 'app/modules/disposition/contract/pair-stock-scan-code-command';
import { StockService } from 'app/modules/disposition/shared/services/stock.service';
import { UnpairStockScanCodeCommand } from 'app/modules/disposition/contract/unpair-stock-scan-code-command';

@UntilDestroy()
@Component({
  selector: 'bh-stock-view-detail',
  templateUrl: './stock-view-detail.component.html',
  styleUrls: ['./stock-view-detail.component.scss']
})
export class StockViewDetailComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public stock: ViewStock;

  public get scanCodeData(): IViewScanCodeData {
    return {
      objectName: this.stock.stockName,
      scanCode: this.stock?.scanCode,
    };
  }

  constructor(private stockStore: StockStore,
              private dialog: MatDialog,
              private stockService: StockService,
              protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToCurrentStock();
  }

  public isLocationType(stockType: StockType): boolean {
    return isLocationType(stockType);
  }

  public editScanCode(): void {
    this.dialog.open(ScanCodeEditComponent, {
      data: { scanCodeData: this.scanCodeData }
    }).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        switch (res.status) {
          case dialogResults.SAVE:
            this.saveScanCode(res.scanCode);
            break;
          case dialogResults.DELETE:
            this.deleteScanCode(res.scanCode);
            break;
        }
      });
  }

  public subscribeToCurrentStock(): void {
    this.stockStore.selectedStock
        .pipe(untilDestroyed(this))
        .subscribe(stock => {
          this.stock = stock;
          if (stock.stockId) {
            this.stockStore.getEmployeeAssignments(stock.stockId);
          }
        });
  }

  private saveScanCode(scanCode: string): void {
    const cmd: PairStockScanCodeCommand = {
      stockId: this.stock.stockId,
      scanCode: scanCode,
    };
    this.stockService.pairScanCode(cmd).subscribe(res => {
      if (res) {
        this.stock.scanCode = scanCode;
      }
    });
  }

  private deleteScanCode(scanCode: string): void {
    const cmd: UnpairStockScanCodeCommand = {
      stockId: this.stock.stockId,
    };
    this.stockService.unpairScanCode(cmd).subscribe(res => {
      if (res) {
        this.stock.scanCode = scanCode;
      }
    });
  }

  public ngOnDestroy(): void {
  }
}
