<mat-table [dataSource]="data" *ngIf="data && data.length > 0" fxFlex="100%" >

  <ng-container [matColumnDef]="columnNames.DATE">
    <mat-header-cell *cdkHeaderCellDef class="width-100">{{'general.date'|translate}}</mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="width-100 cell-date">
      <bh-date-local [date]="row[columnNames.DATE]"></bh-date-local>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.USER">
    <mat-header-cell *cdkHeaderCellDef class="width-150">{{'general.labels.changedBy'|translate}}</mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="width-150 cell-user-name">
      <span *ngIf="row[columnNames.USER]">{{row[columnNames.USER] | submitterInfo}}</span>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.FIELD">
    <mat-header-cell *cdkHeaderCellDef class="width-150">{{'general.property'|translate}}</mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="width-150 cell-field-name">
      {{row[columnNames.FIELD]}}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.OLD">
    <mat-header-cell *cdkHeaderCellDef class="header-cell-value-old">{{'general.old'|translate}}</mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="cell-value-old">
      <ng-container
        [ngTemplateOutlet]="cellTemplate || defaultCellTemplate"
        [ngTemplateOutletContext]="row[columnNames.OLD]">
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.NEW">
    <mat-header-cell *cdkHeaderCellDef class="header-cell-value-new">{{'general.new'|translate}}</mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="cell-value-new">
      <ng-container
        [ngTemplateOutlet]="cellTemplate || defaultCellTemplate"
        [ngTemplateOutletContext]="row[columnNames.NEW]">
      </ng-container>
    </mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *cdkRowDef="let row; columns: displayedColumns;"
    [ngClass]="{ 'odd': row[columnNames.ALTERNATE_COLOR] }">
  </mat-row>

</mat-table>

<ng-template #defaultCellTemplate let-value="value">
  <div>{{value | json}}</div>
</ng-template>
