<mat-expansion-panel #toolbar
                     [@.disabled]="!viewChecked"
                     class="dashboard-toolbar-panel"
                     [hideToggle]="true"
                     (opened)="setEditMode(true)"
                     (closed)="setEditMode(false)">
  <mat-expansion-panel-header *ngIf="!dashboardEdit">
    <mat-panel-title>
      <mat-icon id="dashboard-toggle-icon">edit</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-toolbar>
    <button mat-icon-button (click)="openAddWidgetDialog()"
            [attr.aria-label]="'modules.dashboard.dashboard.addWidget'|translate">
      <mat-icon>add_circle</mat-icon>
    </button>
    <mat-divider [vertical]="true" class="style-fix"></mat-divider>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()">{{"general.buttons.save"|translate}}
    </button>
    <button mat-raised-button (click)="closeToolbar()">{{"general.buttons.cancel"|translate}}</button>
  </mat-toolbar>
</mat-expansion-panel>

<gridster [options]="options">
  <gridster-item [item]="widget" *ngFor="let widget of dashboard; let i=index">
    <ndc-dynamic
      [ndcDynamicComponent]="loadWidgetComponent(widget)"
      [ndcDynamicInputs]="{widget: widget, index: i}"
      [ndcDynamicOutputs]="{deleted: deleteWidget}">
    </ndc-dynamic>
  </gridster-item>
</gridster>
