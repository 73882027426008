import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { MaintenanceTypeService } from '../../shared/service/maintenance-type.service';
import { MaintenanceType } from '../../shared/contract/maintenance-type.interface';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { DeleteMaintenanceTypeCommand } from '../../shared/contract/commands/delete-maintenance-type.command';
import { delay, map, tap } from 'rxjs/operators';

@Injectable()
export class MaintenanceTypeStore {

  private maintenanceTypesSubject: Subject<MaintenanceType[]> = new Subject();
  private filterTermSubject: BehaviorSubject<string> = new BehaviorSubject('');
  private deletableTypes: Map<string, boolean> = new Map();
  public readonly filteredMaintenanceTypes: Observable<MaintenanceType[]> = combineLatest(
      this.maintenanceTypesSubject,
      this.filterTermSubject
  )
  .pipe(
      map(([types, filterTerm]) =>
          types.filter((type: MaintenanceType) => {
            const searchTerm = (type.name).toLowerCase();
            return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
          })));

  constructor(private maintenanceTypeService: MaintenanceTypeService) {
    this.maintenanceTypesSubject.pipe(
        tap(types =>
            types.map(type =>
                this.maintenanceTypeService.canDelete(type.id).subscribe(canDelete => this.deletableTypes.set(type.id, canDelete)))))
    .subscribe();
  }

  loadMaintenanceTypes(): void {
    this.maintenanceTypeService.getAll()
    .subscribe((types: MaintenanceType[]) => this.maintenanceTypesSubject.next(types));
  }

  deleteType(command: DeleteMaintenanceTypeCommand): void {
    this.maintenanceTypeService.delete(command)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe(() => this.loadMaintenanceTypes());
  }

  filterTypesBy(filterTerm: string): void {
    this.filterTermSubject.next(filterTerm);
  }

  canDelete(typeId: string): boolean {
    return this.deletableTypes.get(typeId);
  }

  get searchTerms() {
    return this.filterTermSubject.value;
  }
}
