<mat-card class="entity-creation" fxFlexFill>
  <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-icon>group_add</mat-icon>
      <span>{{'modules.userrole.roleAddEdit.createRole'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.add'|translate}} |
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-raised-button (click)="goBack()">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button (click)="resetForm()">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form" fxFlex>
      <form [formGroup]="rolesAddForm" novalidate>
        <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="entity-creation-form-left" fxFlex="70">
            <div class="entity-creation-form-title">{{'modules.userrole.roleAddEdit.roleBaseData'|translate|uppercase}}</div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start" fxFill>
              <mat-form-field fxFlex="50">
                <input matInput bhRemoveWhitespaces placeholder="{{'general.name.s'|translate|uppercase}}" formControlName="roleName">

                <mat-error *ngIf="roleName.hasError('required')">
                  {{'modules.userrole.roleAddEdit.enterRoleName'|translate}}
                </mat-error>
                <mat-error *ngIf="roleName.hasError('taken')">
                  {{'modules.userrole.roleAddEdit.roleNameInUse'|translate}}
                </mat-error>
                <mat-error *ngIf="roleName.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                  {{'shared.validation.string.trimSpaces'|translate|translate}}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="50">
                <mat-label>{{'general.description'|translate|uppercase}}</mat-label>
                <input matInput formControlName="roleDescription">
              </mat-form-field>
            </div>
            <div *ngIf="isSuperAdmin()" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start" fxFill>
              <mat-form-field fxFlex="50">
                <mat-select formControlName="realmType" (selectionChange)="validateForm()"
                            placeholder="{{'modules.userrole.roleAddEdit.chooseRealm'|translate|uppercase}}*">
                  <mat-option *ngFor="let realm of realms" [value]="realm.value">
                    {{ realm.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="entity-creation-form-right" fxFlex="30">
            <div class="entity-creation-form-title">{{'modules.userrole.roleAddEdit.rights'|translate|uppercase}} *</div>

            <mat-error *ngIf="authoritiesFA.hasError('required') && !authoritiesFA.pristine">
              {{'shared.validation.required'|translate:{ value: 'modules.userrole.roleAddEdit.atLeastOneRight'|translate } }}
            </mat-error>
            <mat-error *ngIf="authoritiesFA.hasError('invalidCombinations')">
              <mat-error *ngFor="let combination of authoritiesFA.errors.invalidCombinations">
                {{'modules.userrole.roleAddEdit.invalidRightsCombination'|translate}}: {{combination}}
              </mat-error>
            </mat-error>
            <mat-list formArrayName="authorities">
              <mat-list-item class="entity-creation-form-right__list-item"
                            [ngClass]="{'entity-creation-form-right__list-item--selected': authority.isSelected,
                            'entity-creation-form-right__list-item--invalid': validationConflict(authority)}"
                            *ngFor="let authority of roleAuthorities;"
                            (click)="selectAuthority(authority)">
                <mat-icon matListItemIcon>
                  {{validationConflict(authority) ? 'error_outline' : 'check_circle'}}
                </mat-icon>
                <p matListItemTitle>{{authority.name}}</p>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
