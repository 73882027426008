import { SearchProjectOrProjectGroup } from '../contract/search-project.interface';
import { ViewProject } from '../contract/view-project.interface';
import { SearchProjectIncludingAssignments } from '../contract/search-project-including-assignments.interface';

export class ViewProjectMapper {

  public static fromSearchProject(searchProject: SearchProjectOrProjectGroup): ViewProject {
    return {
      projectId: searchProject.projectId,
      projectName: searchProject.projectName,
      projectNumber: searchProject.projectNumber,
      projectType: searchProject.projectType,
      projectCostCenter: searchProject.projectCostCenter,
      projectStart: searchProject.projectStart,
      projectEnd: searchProject.projectEnd,
      chargeDate: searchProject.chargeDate,
      projectAddress: searchProject.projectAddress,
      location: searchProject.location,
      organisation: searchProject.organisation,
      projectStatus: searchProject.projectStatus,
      projectCreateDate: searchProject.projectCreateDate,
      description: null,
      labels: null,
      scanCode: searchProject.scanCode,
      projectGroupId: searchProject.projectGroupId,
      projectStructureType: null,
      subProjects: [],
      customerId: searchProject.customerId
    };
  }

  public static fromSearchProjectsIncludingAssignments
  (searchProject: SearchProjectIncludingAssignments): ViewProject {
    return {
      projectId: searchProject.projectData.projectId,
      projectName: searchProject.projectData.projectName,
      projectNumber: searchProject.projectData.projectNumber,
      projectType: searchProject.projectData.projectType,
      projectCostCenter: searchProject.projectData.projectCostCenter,
      projectStart: searchProject.projectData.projectStart,
      projectEnd: searchProject.projectData.projectEnd,
      chargeDate: searchProject.projectData.chargeDate,
      projectAddress: searchProject.projectData.projectAddress,
      location: searchProject.projectData.location,
      organisation: searchProject.projectData.organisation,
      projectStatus: searchProject.projectData.projectStatus,
      projectCreateDate: searchProject.projectData.projectCreateDate,
      description: null,
      labels: null,
      equipmentAssignments: searchProject.assignedEquipment,
      employeeAssignments: searchProject.assignedEmployees,
      timelineEntries: searchProject.timelineEntries,
      scanCode: searchProject.projectData.scanCode,
      projectGroupId: searchProject.projectData.projectGroupId,
      projectStructureType: null,
      subProjects: [],
      customerId: searchProject.projectData.customerId
    };
  }

}
