import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { DisplayService } from 'app/shared/display.service';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from 'app/core/keycloak';
import { ContactAddEditComponent } from '../../contact-add-edit/contact-add-edit.component';
import { ContactAddEditParams } from 'app/modules/contact/contract/contact/contact-add-edit-params.interface';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { DeleteContactCommand } from 'app/modules/contact/contract/contact/delete-contact-command.interface';
import { DeactivateContactCommand } from 'app/modules/contact/contract/contact/deactivate-contact-command.interface';
import { ActivateContactCommand } from 'app/modules/contact/contract/contact/activate-contact-command.interface';
import { ContactStatus } from 'app/modules/contact/shared/enums/contact-status.enum';
import { tap } from 'rxjs/operators';
import { ContactView } from 'app/modules/contact/contract/contact/contact-view.interface';

@UntilDestroy()
@Component({
  selector: 'bh-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss']
})
export class ContactViewComponent extends RoleAuthorityGuardsComponent implements OnInit {
  public contact: ContactView;

  constructor(public displayService: DisplayService,
    protected authService: KeycloakService,
    private contactDataSource: ContactDataSource,
    private dialog: MatDialog,
    private languageService: LanguageService
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.initListener();
  }

  public edit(): void {
    const data: ContactAddEditParams = { contact: this.contact };
    this.dialog.open(ContactAddEditComponent, { data });
  }

  public delete(): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.contact.contactDelete.title');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.contact.contactDelete.message');
    dialogRef.afterClosed()
    .subscribe(result => {
      if (result === dialogResults.YES) {
        const command: DeleteContactCommand = { contactId: this.contact.contactId };
        this.contactDataSource.deleteContact(command);
      }
    });
  }

  public deactivate(): void {
    const command: DeactivateContactCommand = { contactId: this.contact.contactId };
    this.contactDataSource.deactivateContact(command)
    .pipe(
      tap(() => this.contactDataSource.updateListing()),
      untilDestroyed(this))
    .subscribe(() => {this.contactDataSource.updateCurrentContact()});
  }

  public activate(): void {
    const command: ActivateContactCommand = { contactId: this.contact.contactId };
    this.contactDataSource.activateContact(command)
    .pipe(
      tap(() => this.contactDataSource.updateListing()),
      untilDestroyed(this))
    .subscribe(() => {this.contactDataSource.updateCurrentContact()});
  }

  public isActive(): boolean {
    return this.contact && this.contact.status === ContactStatus.ACTIVE;
  }

  private initListener(): void {
    this.currentContactListener();
  }

  private currentContactListener(): void {
    this.contactDataSource.currentContact
    .pipe(untilDestroyed(this))
    .subscribe((currentContact: ContactView) => this.contact = currentContact);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
