<div id="dialog" class="dialog-wrapper">
  <h1 mat-dialog-title>{{'shared.maintenance.maintenanceListExport.title'|translate}}</h1>
  <div mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center stretch">

      <div class="dialog-row" fxLayout="row" [ngClass]="{'hidden': pendingRequest}">
        <mat-icon>view_column</mat-icon>
        <mat-slide-toggle color="primary" [(ngModel)]="selectAllColumns" fxFlex>
          {{'shared.export.allColumns'|translate}}
        </mat-slide-toggle>
        <mat-icon
          class="hover"
          fxFlex="10"
          [matTooltip]="'shared.export.allColumnsHelp'|translate">
          help
        </mat-icon>
      </div>

      <div class="dialog-row" fxLayout="row" [ngClass]="{'hidden': pendingRequest}">
        <mat-icon>filter_list</mat-icon>
        <mat-slide-toggle color="primary" [(ngModel)]="applyCurrentFilter" fxFlex>
          {{'shared.maintenance.maintenanceListExport.applyCurrent'|translate}}
        </mat-slide-toggle>
        <mat-icon
          class="hover"
          fxFlex="10"
          [matTooltip]="'shared.maintenance.maintenanceListExport.applyCurrentHelp'|translate">
          help
        </mat-icon>
      </div>

      <div class="dialog-row" fxLayout="row" [ngClass]="{'hidden': pendingRequest}">
        <mat-icon>sort_by_alpha</mat-icon>
        <mat-slide-toggle color="primary" [(ngModel)]="sortDescending" fxFlex>
          {{(sortDescending ? 'shared.export.sortDsc' : 'shared.export.sortAsc')|translate}}
        </mat-slide-toggle>
      </div>

      <div class="dialog-row" fxLayout="row" [ngClass]="{'hidden': pendingRequest}">
        <mat-icon>sort</mat-icon>
        <mat-form-field>
          <mat-label>{{'general.labels.sortBy'|translate}}</mat-label>
          <mat-select required [(ngModel)]="sortBy">
            <mat-option *ngFor="let field of sortableFields" [value]="field.value">
              {{field.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-spinner diameter="64" [attr.aria-label]="'shared.maintenance.maintenanceListExport.listIsCreated'|translate"
                   *ngIf="pendingRequest">
      </mat-spinner>

    </div>
  </div>
  <div mat-dialog-actions>
    <div fxFlex="row" fxLayoutAlign="space-between center">
      <button mat-raised-button color="accent" (click)="cancel()">
        {{'general.buttons.cancel'|translate}}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="pendingRequest"
        (click)="exportMaintenanceList()">
        {{'general.buttons.download'|translate}}
      </button>
    </div>
  </div>
</div>
