import { TranslateConfiguration } from '../contract/language.interfaces';
import { SaveLanguageConfigurationCommand } from '../../modules/userrole/contract/save-language-configuration-command';
import { Injectable } from '@angular/core';
import { LocalUserStorageService } from './local-user-storage.service';
import { UsersService } from '../../modules/userrole/shared/users.service';
import { GridsterItem } from 'angular-gridster2';
import { UserConfiguration } from '../contract/user-configuration/user-configuration';
import { SaveEquipmentListConfigurationCommand } from '../../modules/userrole/contract/save-equipment-list-configuration-command';
import { SaveProjectAddConfigurationCommand } from '../../modules/equipment/contract/save-project-add-configuration-command';
import { SaveStockAddConfigurationCommand } from '../../modules/equipment/contract/save-stock-add-configuration-command';
import { WizardSettings } from '../wizard-settings.interface';
import { MobileEquipmentAddSelections } from '../mobile-equipment-add-selections.interface';
import { PushTokenConfiguration } from '../contract/user-configuration/push-token-configuration';
import { SaveMobileTransferConfigurationCommand } from '../../modules/equipment/contract/save-mobile-transfer-configuration-command';
import { MobileTransferConfiguration } from '../contract/user-configuration/mobile-transfer-configuration';
import { DocumentsSortConfiguration } from '../contract/user-configuration/documents-sort-configuration';
import { SaveBulkItemListConfigurationCommand } from '../../modules/userrole/contract/save-bulk-item-list-configuration-command';
import { SaveTransferListConfigurationCommand } from '../../modules/userrole/contract/save-transfer-list-configuration-command';
import { SaveEmployeeListConfigurationCommand } from '../../modules/userrole/contract/save-employee-list-configuration-command';
import { Observable } from 'rxjs';
import { SaveEquipmentTransferListConfigurationCommand } from 'app/modules/userrole/contract/save-equipment-transfer-list-configuration-command';
import { map, tap } from 'rxjs/operators';
import { SaveContactListConfigurationCommand } from 'app/modules/userrole/contract/save-contact-list-configuration-command';
import {PartnerTheme} from '../../modules/organisation/contract/partner-theme.enum';
import {
  SaveTransportTaskListConfigurationCommand
} from '../../modules/userrole/contract/save-transport-task-list-configuration-command';
import {
  SaveMaintenanceListConfigurationCommand
} from '../../modules/userrole/contract/save-maintenance-list-configuration-command';
import { SaveProjectListConfigurationCommand } from 'app/modules/userrole/contract/save-project-list-configuration-command';
import { SaveStockListConfigurationCommand } from 'app/modules/userrole/contract/save-stock-list-configuration-command';
import { SavePartnerListConfigurationCommand } from 'app/modules/userrole/contract/save-partner-list-configuration-command';
import { SaveEquipmentTypeListConfigurationCommand } from 'app/modules/userrole/contract/save-equipment-type-list-configuration-command';
import { SaveManufacturerListConfigurationCommand } from 'app/modules/userrole/contract/save-manufacturer-list-configuration-command';
import { SaveTelematicsUnitListConfigurationCommand } from 'app/modules/userrole/contract/save-telematics-unit-administration-list-configuration-command';
import { SaveTransferCartConfigurationCommand } from 'app/modules/userrole/contract/save-transfer-cart-configuration-command';
import { SaveUserConfigListConfigurationCommand } from 'app/modules/userrole/contract/save-user-configuration-command';
import { SaveCustomerConfigListConfigurationCommand } from 'app/modules/userrole/contract/save-customer-configuration-command';
import { SaveDispositionListConfigurationCommand } from 'app/modules/userrole/contract/save-disposition-list-configuration-command';
import { SaveMaintenanceConfigListConfigurationCommand } from 'app/modules/userrole/contract/save-maintenance-config-list-configuration-command';
import { SaveTransferLogListConfigurationCommand } from 'app/modules/userrole/contract/save-transfer-log-list-configuration-command';
import { COLUMN_TYPE } from '../constants/column-definition-constants';
import { Languages } from '../enums/languages.enum';
import { MapConfiguration } from '../contract/user-configuration/map-configuration.interface';
import { WidgetConfiguration } from '../contract/user-configuration/dashboard-configuration';
import { DimensionSystem } from '../enums/dimension-system.enum';
import {
  SaveDimensionUnitConfigurationCommand
} from '../../modules/userrole/contract/save-dimension-unit-configuration-command';
import { DimensionUnitConfiguration } from '../contract/user-configuration/dimension-unit-configuration';
import {
  SaveTimezoneConfigurationCommand
} from '../../modules/userrole/contract/save-timezone-configuration-command';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';
import { UserLanguageConfigProvider } from '../../modules/osp-ui/types/user-language-config.provider';
import { SaveUsageHistoryListConfigurationCommand } from 'app/modules/userrole/contract/save-usage-history-list-configuration-command';
import { setConstructorDTZ } from '../timezone/date-timezone';
import {
  EquipmentSearchConfiguration
} from '../contract/user-configuration/equipment-search-configuration';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserConfigurationService extends UserLanguageConfigProvider {
  private readonly translateConfigurationKey = 'translate-configuration';
  private readonly defaultTranslateConfiguration: TranslateConfiguration = { devPrefix: '#' };   // default prefix option

  constructor(private localStorageService: LocalUserStorageService,
              private userService: UsersService) {
    super();
  }

  public get userConfiguration(): UserConfiguration {
    return JSON.parse(this.localStorageService?.getUserValue('user-configuration') ?? null);
  }

  public saveDashboardConfiguration(dashboard: GridsterItem[]): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.dashboardConfiguration.dashboard = dashboard;
    this.saveUserConfiguration(userConfiguration);
  }

  public saveEquipmentListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.equipmentListConfiguration.columns = filteredColumns;
    userConfiguration.equipmentListConfiguration.pageSize = pageSize;
    userConfiguration.equipmentListConfiguration.columnConfigs = columnConfigs;

    const command = new SaveEquipmentListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.saveUserConfiguration(userConfiguration);
    this.userService.saveEquipmentListConfiguration(command).subscribe();
  }

  public saveMaintenanceListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.maintenanceListConfiguration.columns = filteredColumns;
    userConfiguration.maintenanceListConfiguration.columnConfigs = columnConfigs;
    userConfiguration.maintenanceListConfiguration.pageSize = pageSize;

    const command = new SaveMaintenanceListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.userService.saveMaintenanceListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveCompletedMaintenanceListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.completedMaintenanceListConfiguration.columns = filteredColumns;
    userConfiguration.completedMaintenanceListConfiguration.columnConfigs = columnConfigs;
    userConfiguration.completedMaintenanceListConfiguration.pageSize = pageSize;

    const command = new SaveMaintenanceListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.userService.saveCompletedMaintenanceListConfiguration(command)
      .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveCompletedMaintenanceListConfigurationColumns(columns: string[]): void {
    const { columnConfigs, pageSize } = this.userConfiguration.completedMaintenanceListConfiguration;
    this.saveCompletedMaintenanceListConfiguration(columns, columnConfigs, pageSize);
  }

  public saveCompletedMaintenanceListConfigurationPageSize(pageSize: number): void {
    const { columns, columnConfigs } = this.userConfiguration.completedMaintenanceListConfiguration;
    this.saveCompletedMaintenanceListConfiguration(columns, columnConfigs, pageSize);
  }

  public saveTransferLogListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.globalTransferLogListConfiguration.columns = filteredColumns;
    userConfiguration.globalTransferLogListConfiguration.columnConfigs = columnConfigs;
    userConfiguration.globalTransferLogListConfiguration.pageSize = pageSize;

    const command = new SaveTransferLogListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.saveUserConfiguration(userConfiguration);
    this.userService.saveTransferLogListConfiguration(command).subscribe();
  }

  public saveUsageHistoryListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.usageHistoryListConfiguration.columns = filteredColumns;
    userConfiguration.usageHistoryListConfiguration.columnConfigs = columnConfigs;
    userConfiguration.usageHistoryListConfiguration.pageSize = pageSize;

    const command = new SaveUsageHistoryListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.saveUserConfiguration(userConfiguration);
    this.userService.saveUsageHistoryListConfiguration(command).subscribe();
  }

  public saveProjectListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.projectListConfiguration.pageSize = pageSize;

    const command = new SaveProjectListConfigurationCommand(pageSize);
    this.userService.saveProjectListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveStockListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.stockListConfiguration.pageSize = pageSize;

    const command = new SaveStockListConfigurationCommand(pageSize);
    this.userService.saveStockListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public savePartnerListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.partnerConfigurationListConfiguration.pageSize = pageSize;

    const command = new SavePartnerListConfigurationCommand(pageSize);
    this.userService.savePartnerListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveManufacturerListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.manufacturerConfigurationListConfiguration.pageSize = pageSize;

    const command = new SaveManufacturerListConfigurationCommand(pageSize);
    this.userService.saveManufacturerListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveEquipmentTypeListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.equipmentTypeListConfiguration.pageSize = pageSize;

    const command = new SaveEquipmentTypeListConfigurationCommand(pageSize);
    this.userService.saveEquipmentTypeListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveTelematicsUnitListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.telematicsUnitAdministrationConfigurationListConfiguration.pageSize = pageSize;

    const command = new SaveTelematicsUnitListConfigurationCommand(pageSize);
    this.userService.saveTelematicsUnitListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveTransferCartConfiguration(
    projectViewPageSize: number,
    stockViewPageSize: number
  ): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    if (projectViewPageSize) {
      userConfiguration.transferCartPageSizeUserConfiguration.projectViewPageSize = projectViewPageSize;
    }
    if (stockViewPageSize) {
      userConfiguration.transferCartPageSizeUserConfiguration.stockViewPageSize = stockViewPageSize;
    }

    const command = new SaveTransferCartConfigurationCommand(
      userConfiguration.transferCartPageSizeUserConfiguration.projectViewPageSize,
      userConfiguration.transferCartPageSizeUserConfiguration.stockViewPageSize
    );
    this.userService.saveTransferCartConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveMaintenanceConfigListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.maintenanceConfigurationListConfiguration.pageSize = pageSize;

    const command = new SaveMaintenanceConfigListConfigurationCommand(pageSize);
    this.userService.saveMaintenanceConfigListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveDispositionListConfiguration(
    boardViewPageSize: number,
    equipmentListPageSize: number,
    employeeListPageSize: number
  ): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    if (boardViewPageSize) {
      userConfiguration.dispositionListConfiguration.boardViewPageSize = boardViewPageSize;
    }
    if (equipmentListPageSize) {
      userConfiguration.dispositionListConfiguration.equipmentListPageSize = equipmentListPageSize;
    }
    if (employeeListPageSize) {
      userConfiguration.dispositionListConfiguration.employeeListPageSize = employeeListPageSize;
    }

    const command = new SaveDispositionListConfigurationCommand(
      userConfiguration.dispositionListConfiguration.boardViewPageSize,
      userConfiguration.dispositionListConfiguration.equipmentListPageSize,
      userConfiguration.dispositionListConfiguration.employeeListPageSize
    );
    this.userService.saveDispositionListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveUserConfigListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.userConfigurationListConfiguration.pageSize = pageSize;

    const command = new SaveUserConfigListConfigurationCommand(pageSize);
    this.userService.saveUserConfigListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveCustomerConfigListConfiguration(pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.customerConfigurationListConfiguration.pageSize = pageSize;

    const command = new SaveCustomerConfigListConfigurationCommand(pageSize);
    this.userService.saveCustomerConfigListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveBulkItemListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.bulkItemListConfiguration.columns = filteredColumns;
    userConfiguration.bulkItemListConfiguration.pageSize = pageSize;
    userConfiguration.bulkItemListConfiguration.columnConfigs = columnConfigs;

    this.saveUserConfiguration(userConfiguration)
    const command = new SaveBulkItemListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.userService.saveBulkItemListConfiguration(command).subscribe();
  }

  public saveEmployeeListConfiguration(columnConfigs: ColumnConfig[], pageSize: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.employeeListConfiguration.columnConfigs = columnConfigs;
    userConfiguration.employeeListConfiguration.pageSize = pageSize;

    const command = new SaveEmployeeListConfigurationCommand(columnConfigs, pageSize);
    this.userService.saveEmployeeListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveEquipmentTransferListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.equipmentTransferListConfiguration.columns = filteredColumns;
    userConfiguration.equipmentTransferListConfiguration.pageSize = pageSize;
    userConfiguration.equipmentTransferListConfiguration.columnConfigs = columnConfigs;

    this.saveUserConfiguration(userConfiguration);
    const command = new SaveEquipmentTransferListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.userService.saveEquipmentTransferListConfiguration(command).subscribe();
  }

  public saveBulkItemTransferListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.transferListConfiguration.columns = filteredColumns;
    userConfiguration.transferListConfiguration.columnConfigs = columnConfigs;
    userConfiguration.transferListConfiguration.pageSize = pageSize;

    this.saveUserConfiguration(userConfiguration);
    const command = new SaveTransferListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.userService.saveTransferListConfiguration(command).subscribe();
  }

  public saveContactListConfiguration(columns: string[], columnConfigs: ColumnConfig[], pageSize: number): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.contactListConfiguration.columns = filteredColumns;
    userConfiguration.contactListConfiguration.pageSize = pageSize;
    userConfiguration.contactListConfiguration.columnConfigs = columnConfigs;

    this.saveUserConfiguration(userConfiguration);
    const command = new SaveContactListConfigurationCommand(filteredColumns, columnConfigs, pageSize);
    this.userService.saveContactListConfiguration(command).subscribe();
  }

  public saveTransportTaskListConfiguration(
    columns: string[],
    columnConfigs: ColumnConfig[],
    listViewPageSize: number,
    boardViewBoardPageSize: number,
    boardViewListPageSize: number,
  ): void {
    const filteredColumns = this.filterColumnsBeforeSave(columns);
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.transportTaskListConfiguration.columns = filteredColumns;
    userConfiguration.transportTaskListConfiguration.columnConfigs = columnConfigs;
    if (listViewPageSize) {
      userConfiguration.transportTaskListConfiguration.listViewPageSize = listViewPageSize;
    }
    if (boardViewBoardPageSize) {
      userConfiguration.transportTaskListConfiguration.boardViewBoardPageSize = boardViewBoardPageSize;
    }
    if (boardViewListPageSize) {
      userConfiguration.transportTaskListConfiguration.boardViewListPageSize = boardViewListPageSize;
    }

    const command = new SaveTransportTaskListConfigurationCommand(
      filteredColumns,
      columnConfigs,
      userConfiguration.transportTaskListConfiguration.listViewPageSize,
      userConfiguration.transportTaskListConfiguration.boardViewBoardPageSize,
      userConfiguration.transportTaskListConfiguration.boardViewListPageSize
    );
    this.userService.saveTransportTaskListConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveProjectAddConfiguration(createGeofence: boolean): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.projectAddConfiguration.createGeofence = createGeofence;

    const command = new SaveProjectAddConfigurationCommand(createGeofence);
    this.userService.saveProjectAddConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveStockAddConfiguration(createGeofence: boolean): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.stockAddConfiguration.createGeofence = createGeofence;

    const command = new SaveStockAddConfigurationCommand(createGeofence);
    this.userService.saveStockAddConfiguration(command)
    .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveMobileTransferConfiguration(sourceTab: number, targetTab: number): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.mobileTransferConfiguration.sourceTab = sourceTab;
    userConfiguration.mobileTransferConfiguration.targetTab = targetTab;

    const command = new SaveMobileTransferConfigurationCommand(sourceTab, targetTab);
    this.userService.saveMobileTransferConfiguration(command)
      .subscribe(() => this.saveUserConfiguration(userConfiguration));
  }

  public saveWizardSettings(value: WizardSettings): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.wizardConfigurations = value;
    this.saveUserConfiguration(userConfiguration);
  }

  public getWizardSettings(): WizardSettings {
    return this.userConfiguration.wizardConfigurations;
  }

  public saveUserConfiguration(configuration: UserConfiguration): void {
    this.localStorageService.setUserValue('user-configuration', JSON.stringify(configuration));
  }

  public saveMobileEquipmentAddOrganisationSelection(organisationId: string): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.mobileEquipmentAddSelections.organisationId = organisationId;
    this.saveUserConfiguration(userConfiguration);
  }

  public saveMobileEquipmentAddEquipmentTypeSelection(equipmentTypeId: string): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.mobileEquipmentAddSelections.equipmentTypeId = equipmentTypeId;
    this.saveUserConfiguration(userConfiguration);
  }

  public getMobileEquipmentAddSelections(): MobileEquipmentAddSelections {
    return this.userConfiguration.mobileEquipmentAddSelections;
  }

  public getMobileTransferConfig(): MobileTransferConfiguration {
    return this.userConfiguration.mobileTransferConfiguration;
  }

  public savePushTokenToUserConfiguration(pushToken: string, deviceUuid?: string): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.pushToken.pushToken = pushToken;
    if (deviceUuid) {
      userConfiguration.pushToken.deviceUuid = deviceUuid;
    }
    this.saveUserConfiguration(userConfiguration);
  }

  public getPushTokenConfiguration(): PushTokenConfiguration | undefined {
    return !!this.userConfiguration.pushToken && Object.keys(this.userConfiguration.pushToken).length
      ? this.userConfiguration.pushToken
      : undefined;
  }

  public erasePushToken(): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.pushToken = null;
    this.saveUserConfiguration(userConfiguration);
  }

  public saveDocumentsSortConfiguration(sortParameter: string): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.documentsSortConfiguration = new DocumentsSortConfiguration(sortParameter);
    this.saveUserConfiguration(userConfiguration);
  }

  public getDocumentsSortConfiguration(): DocumentsSortConfiguration {
    return this.userConfiguration.documentsSortConfiguration;
  }

  public saveTransportDocumentsSortConfiguration(sortParameter: string): void {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.transportDocumentsSortConfiguration = new DocumentsSortConfiguration(sortParameter);
    this.saveUserConfiguration(userConfiguration);
  }

  public getTransportDocumentsSortConfiguration(): DocumentsSortConfiguration {
    return this.userConfiguration.transportDocumentsSortConfiguration;
  }

  public getUserLanguage(): string {
    return this.userConfiguration.languageConfiguration ? this.userConfiguration.languageConfiguration.language : '';
  }

  public saveUserLanguage(language: string): Observable<any> {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.languageConfiguration.language = language;

    const command = new SaveLanguageConfigurationCommand(language);
    return this.userService.saveLanguageConfiguration(command)
      .pipe(tap(() => this.saveUserConfiguration(userConfiguration)));
  }

  public saveDimensionUnit(dimensionUnit: DimensionSystem): Observable<any> {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.dimensionUnitConfiguration.dimensionUnit = dimensionUnit;

    const command = new SaveDimensionUnitConfigurationCommand(dimensionUnit);
    return this.userService.saveDimensionUnitConfiguration(command)
    .pipe(tap(() => this.saveUserConfiguration(userConfiguration)));
  }

  public getUserTimezone(): string {
    if (!this.userConfiguration?.timezoneConfiguration?.lastUpdated) {
      this.applyAndSaveBrowserTimezone().subscribe();
    }
    return this.userConfiguration?.timezoneConfiguration?.timezone || '';
  }

  public saveUserTimezone(timezone: string): Observable<any> {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    userConfiguration.timezoneConfiguration.timezone = timezone;

    const command = new SaveTimezoneConfigurationCommand(timezone);
    return this.userService.saveTimezoneConfiguration(command)
    .pipe(
      tap(() => this.saveUserConfiguration(userConfiguration)),
      tap(() => this.setupDTZ())
    );
  }

  public applyAndSaveBrowserTimezone(): Observable<any> {
    const userConfiguration: UserConfiguration = this.userConfiguration;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    userConfiguration.timezoneConfiguration.timezone = timezone;
    userConfiguration.timezoneConfiguration.lastUpdated = new Date();
    this.saveUserConfiguration(userConfiguration)

    const command = new SaveTimezoneConfigurationCommand(timezone);
    return this.userService.saveTimezoneConfiguration(command);
  }

  public getUserTranslateConfiguration(): TranslateConfiguration {
    let translateConfiguration: TranslateConfiguration;
    try {
      translateConfiguration = JSON.parse(this.localStorageService.getUserValue(this.translateConfigurationKey));
    } catch {
      translateConfiguration = { ...this.defaultTranslateConfiguration };
    }
    translateConfiguration = { ...this.defaultTranslateConfiguration, ...(translateConfiguration || {}) };

    this.localStorageService.setUserValue(this.translateConfigurationKey, JSON.stringify(translateConfiguration));
    return translateConfiguration;
  }

  public saveUserTranslateConfiguration(translateConfiguration: TranslateConfiguration): void {
    this.localStorageService.setUserValue(this.translateConfigurationKey, JSON.stringify(translateConfiguration));
  }

  public getPartnerTheme(): PartnerTheme {
    return this.userConfiguration?.additionalInfo?.partnerTheme || PartnerTheme.DEFAULT_OSP;
  }

  public getFallbackLanguage(): Languages {
    return this.userConfiguration?.additionalInfo?.fallbackLanguage || Languages.DEFAULT;
  }

  public getDefaultLanguage(): Languages {
    return Languages.DEFAULT;
  }

  public getLanguageSettings(): Observable<{ defaultLanguage: Languages, availableLanguages: Languages[] }> {
    const defaultLanguage = this.getFallbackLanguage();
    return this.userService.getAvailableLanguages()
      .pipe(
        map(languages => languages
          .filter(lang => lang !== defaultLanguage && lang !== Languages.DEV)
          .sort()
        ),
        map(languages => [defaultLanguage, ...languages]),
        map(availableLanguages => ({ defaultLanguage, availableLanguages }))
      );
  }

  private filterColumnsBeforeSave(columns: string[]): string[] {
    return columns.filter(column => column !== COLUMN_TYPE.SELECT);
  }

  // Map configuration
  public getDashboardConfiguration(): WidgetConfiguration[] {
    return this.userConfiguration?.dashboardConfiguration?.dashboard ?? [];
  }

  public getMapConfigurationWidget(index: number): MapConfiguration {
    return this.userConfiguration?.dashboardConfiguration?.dashboard[index]?.mapConfiguration;
  }

  public saveMapConfigurationDashboard(config: MapConfiguration, index: number): void {
    if (this.userConfiguration?.dashboardConfiguration?.dashboard[index]) {
      const userConfiguration = this.userConfiguration;
      userConfiguration.dashboardConfiguration.dashboard[index].mapConfiguration = config;
      this.saveUserConfiguration(userConfiguration);
    }
  }

  public getMapConfigurationEquipment(): MapConfiguration {
    return this.userConfiguration.equipmentMapConfiguration;
  }

  public saveMapConfigurationEquipment(equipmentMapConfiguration: MapConfiguration): void {
    this.saveUserConfiguration({ ... this.userConfiguration, equipmentMapConfiguration });
  }

  public getMapConfigurationContact(): MapConfiguration {
    return this.userConfiguration.contactMapConfiguration;
  }

  public saveMapConfigurationContact(contactMapConfiguration: MapConfiguration): void {
    this.saveUserConfiguration({ ... this.userConfiguration, contactMapConfiguration });
  }

  public getMapConfigurationProject(): MapConfiguration {
    return this.userConfiguration.projectMapConfiguration;
  }

  public saveMapConfigurationProject(projectMapConfiguration: MapConfiguration): void {
    this.saveUserConfiguration({ ... this.userConfiguration, projectMapConfiguration });
  }

  public getMapConfigurationStock(): MapConfiguration {
    return this.userConfiguration.stockMapConfiguration;
  }

  public saveMapConfigurationStock(stockMapConfiguration: MapConfiguration): void {
    this.saveUserConfiguration({ ...this.userConfiguration, stockMapConfiguration });
  }

  public getDimensionUnitConfiguration(): DimensionUnitConfiguration {
    return this.userConfiguration.dimensionUnitConfiguration;
  }

  public getUserDimensionSystem(): DimensionSystem {
    let dimensionUnitConfiguration = this.getDimensionUnitConfiguration();
    return dimensionUnitConfiguration.dimensionUnit ?? DimensionSystem.METRIC;
  }

  public getEquipmentSearchV2Configuration(): EquipmentSearchConfiguration {
    if (!environment.EQUIPMENT_SEARCH_V2_TOGGLE_AVAILABLE) {
      return {useSearchV2: false}
    }
    if (!this.userConfiguration?.equipmentSearchConfiguration) {
      this.saveEquipmentSearchV2Configuration({useSearchV2: false});
    }
    return this.userConfiguration.equipmentSearchConfiguration;
  }

  public saveEquipmentSearchV2Configuration(equipmentSearchConfiguration: EquipmentSearchConfiguration): void {
    if (!environment.EQUIPMENT_SEARCH_V2_TOGGLE_AVAILABLE) {
      return;
    }
    this.saveUserConfiguration({ ...this.userConfiguration, equipmentSearchConfiguration });
  }

  public setupDTZ(): void {
    setConstructorDTZ(this.getUserTimezone());
  }
}
