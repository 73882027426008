import { Injectable } from '@angular/core';
import { TypeOfUseStatusReason } from './enums/type-of-use-status-reason.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { TypeOfUseStatus } from './enums/type-of-use-status.enum';
import { faCaretCircleRight, faCheckCircle, faCloudShowers, faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { faBolt, faHourglass, faSync } from '@fortawesome/pro-regular-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class ChangeTypeOfUseIconsService {

  public readonly faSync: IconDefinition = faSync;
  public readonly faCloudShower: IconDefinition = faCloudShowers;
  public readonly faHourglass: IconDefinition = faHourglass;
  public readonly faBolt: IconDefinition = faBolt;
  public readonly faMinusCircle: IconDefinition = faMinusCircle;
  public readonly faCheckCircle: IconDefinition = faCheckCircle;
  public readonly faCaretCircleRight: IconDefinition = faCaretCircleRight;

  constructor() { }

  public resolveReasonIcon(reason: TypeOfUseStatusReason): IconDefinition {
    switch (reason) {
      case TypeOfUseStatusReason.BAD_WEATHER:
        return this.faCloudShower;
      case TypeOfUseStatusReason.ACCIDENT:
        return this.faBolt;
      case TypeOfUseStatusReason.CONSTRUCTION_DELAY:
        return this.faHourglass;
      case TypeOfUseStatusReason.OTHER:
        return this.faHourglass;
    }
  }

  public resolveStatusIcon(reason: TypeOfUseStatus): IconDefinition {
    switch (reason) {
      case TypeOfUseStatus.STANDSTILL:
        return this.faCloudShower;
      case TypeOfUseStatus.FREE_OF_CHARGE:
        return this.faMinusCircle;
      case TypeOfUseStatus.IN_USE:
        return this.faCaretCircleRight;
      case TypeOfUseStatus.READY_TO_PICKUP:
        return this.faCheckCircle;
    }
  }
}
