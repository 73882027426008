import { StockToStockTransferItem } from './stock-to-stock-transfer-item.interface';

export class CreateStockToStockTransferCartCommand {
  constructor(
      public targetStockId: string,
      public transferItems: StockToStockTransferItem[],
      public employeeId?: string,
      public externalEmployee?: string,
      public comment?: string,
      public transferDate?: Date) {}
}
