import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ArticleGroupCount } from '../../../shared/contract/article-group-count';
import { CreateMaintenanceRuleCommand } from '../contract/commands/create-maintenance-rule.command';
import { MaintenanceRule } from '../contract/maintenance-rule';
import { UpdateMaintenanceRuleCommand } from '../contract/commands/update-maintenance-rule.command';
import { NameInUseService } from '../../../shared/api/name-in-use.service.interface';
import { AssignMaintenanceRuleCommand } from '../contract/commands/assign-maintenance-rule.command';
import { PagedResponse } from '../../../../../shared/contract/page-response.interface';
import { UnassignMaintenanceRuleCommand } from '../contract/commands/unassign-maintenance-rule.command';
import { DeleteMaintenanceRuleCommand } from '../contract/commands/delete-maintenance-rule.command';
import { getHttpParameters } from '../../../../../shared/utils';
import { MaintenanceFilterCollectionView } from '../../../shared/contract/maintenance-filter-collection-view.interface';

@Injectable()
export class MaintenanceRuleService implements NameInUseService {

  private url = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/rules';
  private articleGroupUrl = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/article-groups';

  constructor(private http: HttpClient) {
  }

  createMaintenanceRule(command: CreateMaintenanceRuleCommand): Observable<string> {
    return this.http.post(this.url + '/create', command, {responseType: 'text'});
  }

  updateMaintenanceRule(command: UpdateMaintenanceRuleCommand): Observable<string> {
    return this.http.post(this.url + '/update', command, {responseType: 'text'});
  }

  getById(id: string): Observable<MaintenanceRule> {
    return this.http.get<MaintenanceRule>(`${this.url}/${id}`);
  }

  getAllArticleGroups(): Observable<ArticleGroupCount[]> {
    return this.http.get<ArticleGroupCount[]>(this.articleGroupUrl);
  }

  getMaintenanceRuleFilter(maintenanceTypeIds: string[], articleGroups: string[]): Observable<MaintenanceFilterCollectionView> {
    let httpParams = getHttpParameters({
      maintenanceTypeIds: maintenanceTypeIds,
      articleGroups: articleGroups
    });

    return this.http.get<MaintenanceFilterCollectionView>(`${this.url}/filter`,
      {params: httpParams});
  }

  nameInUse(id: string, name: string): Observable<boolean> {
    const params = new HttpParams().set('id', id).set('name', name);
    return this.http.get<boolean>(`${this.url}/name-in-use`, {params: params});
  }

  assignMaintenanceRuleToEquipment(command: AssignMaintenanceRuleCommand): Observable<string> {
    return this.http.post(this.url + '/assign', command, {responseType: 'text'});
  }

  getAll(): Observable<PagedResponse<MaintenanceRule>> {
    return this.http.get<PagedResponse<MaintenanceRule>>(this.url);
  }

  loadRules(pageIndex: number, pageSize: number, term: string, maintenanceTypeIds: string[], articleGroups: string[]):
   Observable<PagedResponse<MaintenanceRule>> {
    let params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('size', pageSize.toString())
    .set('term', term);

    params = this.appendParamSet(params, 'maintenanceTypes', maintenanceTypeIds);
    params = this.appendParamSet(params, 'articleGroups', articleGroups);

    return this.http.get<PagedResponse<MaintenanceRule>>(`${this.url}/search`, {params: params});
  }

  private appendParamSet(params: HttpParams, paramName: string, valueSet: string[]): HttpParams {
    params = valueSet.reduce((p, value) => {
      return p.append(paramName, value)
    }, params);
    return params;
  }

  unassignRule(command: UnassignMaintenanceRuleCommand): Observable<string> {
    return this.http.post(`${this.url}/unassign`, command, {responseType: 'text'});
  }

  deleteRule(command: DeleteMaintenanceRuleCommand): Observable<string> {
    return this.http.post(`${this.url}/delete`, command, {responseType: 'text'});
  }

  getAssignedEquipments(maintenanceRuleId: string): Observable<string[]> {
    const params = new HttpParams().set('ruleId', maintenanceRuleId);
    return this.http.get<string[]>(`${this.url}/assigned-equipments`, {params: params});
  }
}
