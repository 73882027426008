<div class="attachment-container">
  <bh-title [text]="'general.attachment.pl'|translate"></bh-title>

  <div class="attachments" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="attachment-type-container">
      <div *ngIf="hasAnyAuthority([authorities.BULK_ITEMS_ADD_ATTACHMENTS,
     authorities.BULK_ITEMS_MANAGE]); else noUploadAllowed">
        <bh-subtitle [text]="'general.labels.uploadDocPict'|translate"></bh-subtitle>
        <bh-file-upload [itemId]="bulkItem?.bulkItemId"
                        uploaderType="BULK_ITEM">
        </bh-file-upload>
      </div>
      <ng-template #noUploadAllowed>
        <div class="no-upload">
          {{'shared.attachment.uploadNotAllowed' | translate}}
        </div>
      </ng-template>
    </div>


    <bh-attachment-list-category
      [documents]="documents"
      [deletable]="isDeletable"
      [editable]="isEditable"
      [editableName]="isEditable"
      [pendingChanges]="waiting"
      (nameChanged)="saveFileName($event)"
      (deleted)="deleteDocument($event, false)"
      (descriptionChanged)="saveDescription($event)">
    </bh-attachment-list-category>

    <bh-attachment-list-image-category
      [images]="images"
      [setDefaultEnable]="isSetDefaultEnabled"
      [defaultImage]="defaultImage"
      (defaultImageChanged)="setStandardImage($event.documentKey)"
      [pendingChanges]="waiting"
      [visibleDescription]="true"
      [editableDescription]="isEditable"
      [editableName]="isEditable"
      [deletable]="isDeletable"
      (deleted)="deleteDocument($event, true)"
      (nameChanged)="saveFileName($event)"
      (descriptionChanged)="saveDescription($event)">
    </bh-attachment-list-image-category>
  </div>
</div>
