import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { PageSegment } from 'app/shared/enums/page-segment.enum';
import { Observable } from 'rxjs';

@Injectable()
export class TransportPageRerouteGuard implements CanActivate {
  private defaultPage: string;
  constructor(private authService: KeycloakService, private router: Router) {
    this.defaultPage = this.getDefaultPageSegment();
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.shouldBeRedirectedToDefaultPage(state)) {
      return this.redirectToDefaultPage();
    }

    if (this.shouldBeRedirectedToCreationPage(route)) {
      return this.redirectToCreationPage();
    }

    this.setLastVisitedAsDefault(route);
    return true;
  }

  private getDefaultPageSegment(): PageSegment {
    return this.authService.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION)
      ? PageSegment.TRANSPORT_BOARD
      : PageSegment.TRANSPORT_LIST;
  }

  private shouldBeRedirectedToDefaultPage(state: RouterStateSnapshot): boolean {
    return state.url === `/${PageSegment.TRANSPORT_ROOT}`;
  }

  private redirectToDefaultPage(): Promise<boolean> {
    return this.router.navigate([PageSegment.TRANSPORT_ROOT, this.defaultPage]);
  }

  private shouldBeRedirectedToCreationPage(route: ActivatedRouteSnapshot): boolean {
    return route.firstChild.url[0].path === PageSegment.TRANSPORT_ADD;
  }

  private redirectToCreationPage(): Promise<boolean> {
    return this.router.navigate([PageSegment.TRANSPORT_ROOT, this.defaultPage, PageSegment.TRANSPORT_ADD]);
  }

  private setLastVisitedAsDefault(route: ActivatedRouteSnapshot): void {
    const segment = <PageSegment>route.firstChild.url[0].path;
    if (this.isPossibleToVisit(segment)) {
      this.defaultPage = segment;
    }
  }

  private isPossibleToVisit(segment: PageSegment): boolean {
    switch (segment) {
      case PageSegment.TRANSPORT_BOARD:
        return this.authService.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION);
      case PageSegment.TRANSPORT_LIST:
        return this.authService.hasAnyAuthority([
          Authorities.TRANSPORT_TASK_DISPOSITION,
          Authorities.TRANSPORT_TASK_MANAGE,
          Authorities.TRANSPORT_TASK_VIEW
        ]);
      default:
        return false;
    }
  }
}
