import { BulkItemTransferColumnDefinitionService } from './services/bulk-item-transfer-column-definition.service';
import { UserConfigurationService } from '../../../shared/services/user-configuration.service';
import { Injectable } from '@angular/core';
import { ColumnService } from '../../../shared/column.service';
import { DisplayService } from '../../../shared/display.service';
import { ColumnDefinition } from '../../../shared/column-definition';
import { KeycloakService } from 'app/core/keycloak';
import { Observable, map } from 'rxjs';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

@Injectable()
export class BulkItemTransferColumnService extends ColumnService {

  public filteredColumnConfigs: Observable<ColumnConfig[]>;

  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService,
              transferColumnDefinitionService: BulkItemTransferColumnDefinitionService) {
    super(null,
      transferColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.transferListConfiguration.columns,
      userConfigurationService.userConfiguration.transferListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.transferListConfiguration.pageSize },
      displayService,
      authService);

    this.filteredColumnConfigs = this.columnConfigs.pipe(map(this.filterColumnConfigs));
  }

  get columnLimit(): number {
    return undefined;
  }

  get selectedColumnsList(): string[] {
    return this.userConfigurationService.userConfiguration.transferListConfiguration.columns;
  }

  public selectColumns(columns: ColumnDefinition[], pageSize: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === 'thumbnail'
      || column.cdkColumnDef === 'bulkItemNumber'
      || column.cdkColumnDef === 'bulkItemName'
      || column.cdkColumnDef === 'stockName'
      || column.cdkColumnDef === 'amountAvailable'
      || column.cdkColumnDef === 'bulkItemType'
      || column.cdkColumnDef === 'bulkItemCategory'
      || column.cdkColumnDef === 'bulkItemBALCode'
      || column.cdkColumnDef === 'intoTransferCart';
  }

  public isNonRemovable(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === 'intoTransferCart';
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveBulkItemTransferListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.filterColumnConfigs(this.columnConfigsSubject.getValue()),
      this.pageSizeSubject.getValue(),
    );
  }

  private filterColumnConfigs(configs: ColumnConfig[]): ColumnConfig[] {
    return (configs || []).filter(({ name }) => name !== 'intoTransferCart');
  }
}
