import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { EmployeeDispositionService } from './employee-disposition.service';
import { Sort } from '@angular/material/sort';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { DataSource } from '@angular/cdk/collections';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import * as moment from 'moment';
import { SearchEmployeeDisposition } from '../contract/search-employee-disposition.interface';
import { DateUtils } from '../../../shared/dateUtils';
import { AddEmployeeToProjectAssignmentCommand } from '../contract/add-employee-project-assignment-command';
import { switchMap } from 'rxjs/operators';
import { ViewEmployeeProjectAssignment } from '../contract/view-employee-project-assignment.interface';
import { ValidateEmployeeAssignmentParams } from '../contract/validate-employee-assignment-params.interface';
import { DatesService } from 'app/shared/services/dates.service';
import { RemoveEmployeeToProjectAssignmentCommand } from '../contract/remove-employee-to-project-assignment-command';
import { UpdateEmployeeToProjectAssignmentCommand } from 'app/modules/equipment/contract/update-employee-to-project-assignment-command';
import { EmployeeStatus } from '../contract/employee-status.enum';
import { DispositionColumnService } from './services/disposition-column.service';



@Injectable()
export class EmployeeDispositionDatasource extends DataSource<SearchEmployeeDisposition> {

  private _data: BehaviorSubject<SearchEmployeeDisposition[]> = new BehaviorSubject([]);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);

  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private _searchLabels: string[] = [];
  private _organisationFilter: string[] = [];
  private _employeeStatusesFilter: string[] = [EmployeeStatus.ACTIVE];
  private _searchAvailableFilter: boolean = null;
  private _searchAvailableFilterStart: Date = null;
  private _searchAvailableFilterEnd: Date = null;
  private _sort: Sort;

  public readonly employeeDisposition: Observable<SearchEmployeeDisposition[]> = this._data.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();

  constructor(private employeeDispositionService: EmployeeDispositionService,
              private dispositionColumnService: DispositionColumnService,
              private snackBar: MatSnackBar) {
    super();
    this.dispositionColumnService.dispositionEmployeeListViewPageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
    } else {
      this.snackBar.open('Eingabe zu lang. Suche nicht möglich', undefined, {duration: 5000});
    }
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchLabels(searchLabels: string[]) {
    this._searchLabels = searchLabels;
  }

  get searchLabels(): string[] {
    return this._searchLabels;
  }

  set searchAvailableFilter(searchAvailableFilter: boolean) {
    this._searchAvailableFilter = searchAvailableFilter;
  }

  get searchAvailableFilter(): boolean {
    return this._searchAvailableFilter;
  }

  set searchAvailableFilterStart(searchAvailableFilterStart: Date) {
    this._searchAvailableFilterStart = searchAvailableFilterStart;
  }

  get searchAvailableFilterStart(): Date {
    return this._searchAvailableFilterStart;
  }

  set searchAvailableFilterEnd(searchAvailableFilterEnd: Date) {
    this._searchAvailableFilterEnd = searchAvailableFilterEnd;
  }

  get searchAvailableFilterEnd(): Date {
    return this._searchAvailableFilterEnd;
  }

  connect(): Observable<SearchEmployeeDisposition[]> {
    return this.employeeDisposition;
  }

  disconnect(): void {
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.dispositionColumnService.selectEmployeeListPageSize(size);
    }
    this.pagination.index = index;
    this.pagination.size = size;

    let params = {
      page: index,
      size: size,
      labels: this._searchLabels,
      organisations: this._organisationFilter,
      status: this._employeeStatusesFilter,
      terms: null,
      sort: null,
      available: this._searchAvailableFilter,
      availableStart: null,
      availableEnd: null
    };

    if (this._sort) {
      params.sort = this.sortToSortString(this._sort);
    }

    if (this._searchTerms) {
      params.terms = this._searchTerms;
    }

    if (params.available !== null) {
      params.availableStart = moment(this._searchAvailableFilterStart).format('YYYY-MM-DD');
      params.availableEnd = moment(DateUtils.endDateOrDefault(this._searchAvailableFilterEnd)).format('YYYY-MM-DD');
    }

    this.employeeDispositionService.getEmployeeDispositionSearch(params)
    .subscribe(res => {
      this.updateStoreData(res);
    });
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private sortToSortString(sort: Sort): string {
    return sort && sort.active && sort.direction ? `${sort.active}.raw,${sort.direction}` : null;
  }

  private updateStoreData(res: PagedResponse<SearchEmployeeDisposition>): void {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  public getCustomerLabels(organisationFilter?: string): Observable<CustomerLabel[]> {
    this.employeeDispositionService
    .getCustomerLabels(organisationFilter)
    .subscribe((labels: CustomerLabel[]) => this._labels.next(labels));

    return this.labels;
  }

  public assignEmployeeToProject(cmd: AddEmployeeToProjectAssignmentCommand) {
    return this.employeeDispositionService
    .assignToProject(cmd)
    .pipe(switchMap((resultAssignmentId: string) => {
      return of(resultAssignmentId);
    }));
  }

  public updateEmployeeToProjectAssignment(cmd: UpdateEmployeeToProjectAssignmentCommand): Observable<any>  {
    return this.employeeDispositionService.updateProjectAssignment(cmd)
    .pipe(switchMap((res: any) => {
      return of(res);
    }))
  }

  public removeEmployeeToProjectAssignment(cmd: RemoveEmployeeToProjectAssignmentCommand): Observable<any> {
    return this.employeeDispositionService.removeProjectAssignment(cmd)
    .pipe(switchMap((res: any) => {
      return of(res);
    }))
  }

  public getAssignmentCollisions(employeeId: string,
     startDate: string,
     endDate?: string,
     assignmentId?: string): Observable<ViewEmployeeProjectAssignment[]> {
    const params: ValidateEmployeeAssignmentParams = {
      id: employeeId,
      assignmentStart: moment(DatesService.sameTimeZoneAtStartDateUTC(startDate)).toISOString(),
      assignmentEnd: endDate ? moment(DatesService.sameTimeZoneAtEndDateUTC(endDate)).toISOString() : null,
      assignmentId: assignmentId,
    };
    return this.employeeDispositionService.validateAssignment(employeeId, params);
  }

}
