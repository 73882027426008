import { Retake } from './retake.enum';
import { FixedDateRetakeInterval } from './fixed-date-retake-interval';
import { PeriodRetakeInterval } from './period-retake-interval';


export class QualificationUpdateCommand {
    qualificationId: string;
    qualificationName: string;
    qualificationDescription: string;
    qualificationRetakeType: Retake;
    fixedDateRetakeInterval: FixedDateRetakeInterval;
    periodRetakeInterval: PeriodRetakeInterval;
    constructor(qualificationId: string,
         qualificationName: string,
         qualificationDescription: string,
         qualificationRetakeType: Retake,
         fixedDateRetakeInterval: FixedDateRetakeInterval,
         periodRetakeInterval: PeriodRetakeInterval) {
            this.qualificationId = qualificationId;
            this.qualificationName = qualificationName;
            this.qualificationDescription = qualificationDescription;
            this.qualificationRetakeType = qualificationRetakeType;
            this.fixedDateRetakeInterval = fixedDateRetakeInterval;
            this.periodRetakeInterval = periodRetakeInterval;
         }
}
