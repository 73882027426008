import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Quill from 'quill';

@Component({
  selector: 'bh-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})

export class DescriptionComponent implements OnInit {

  public editMode: boolean;
  public editingAllowed: boolean;
  public revertedChanges: boolean;
  private descriptionCache: string;

  @Input() maxLength: number;
  @Input() description: string;
  @Input() isActive = true;
  @Input() isEditingAllowed = true;

  @Output() descriptionSave: EventEmitter<string> = new EventEmitter<string>();
  @Output() descriptionChange: EventEmitter<string> = new EventEmitter<string>();

  config = [
    ['bold', 'italic', 'underline', 'strike', 'link'], // toggled buttons
    ['blockquote'],

    [{'header': 1}, {'header': 2}], // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}], // outdent/indent
    [{'direction': 'rtl'}], // text direction

    [{'size': ['small', false, 'large', 'huge']}], // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}], // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],

    ['clean'], // remove formatting button
  ];


  constructor() {
  }

  ngOnInit(): void {
    const Link = Quill.import('formats/link');
    Link.sanitize = function (url: string): string {
      return url.match('^https?:\/\/') ? url : 'http://' + url;
    };
    this.editMode = false;
    this.editingAllowed = this.isEditingAllowed;
    this.revertedChanges = false;
  }

  save() {
    this.editMode = false;
    this.revertedChanges = false;
    this.descriptionChange.emit(this.description);
    this.descriptionSave.emit(this.description);
  }

  cancel() {
    this.editMode = false;
    this.revertedChanges = false;
    this.description = this.descriptionCache;
  }

  editDescription() {
    this.editMode = !this.editMode;
    this.descriptionCache = this.description;
  }

  /*
   * Checks if html string exceeds max length on editing.
   * If yes, revert last changes and show hint.
   */
  textChanged(event) {
    if (event.html && (event.html.length > this.maxLength)) {
      const oldDelta = event.oldDelta;
      const selectionRange = event.editor.selection.savedRange;
      this.revertedChanges = true;
      event.editor.setContents(oldDelta);
      event.editor.setSelection(selectionRange);
    } else if (event.source === 'user') {
      this.revertedChanges = false;
    }
    this.descriptionChange.emit(this.description);
  }
}
