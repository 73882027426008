import { LanguageService } from './../../services/language.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { MobileWizardTabComponent } from './mobile-wizard-tab-component/mobile-wizard-tab.component';
import { MobileWizardSettingsService } from '../../services/mobile-wizard-settings.service';
import { WizardOptions } from '../../wizard-options.interface';
import { WizardTabOptions } from '../../wizard-tab-options.interface';
import { WizardSettings } from '../../wizard-settings.interface';
import { WizardTabSettings } from '../../wizard-tab-settings.interface';

@Component({
  selector: 'bh-mobile-wizard',
  styleUrls: ['./mobile-wizard.component.scss'],
  templateUrl: './mobile-wizard.component.html'
})
export class MobileWizardComponent implements AfterViewInit {

  @Input() public wizardOptions: WizardOptions;
  @Input() public title: string;
  @Input() public nextEnabled = true;
  @Input() public backEnabled = true;
  @Input() public settingsEnabled = true;
  @Input() public confirmLabel = this.languageService.getInstant('general.buttons.done');
  public settingsOpen = false;
  public currentStep = 0;
  public totalSteps: number;
  @ContentChildren(MobileWizardTabComponent) public tabs: QueryList<MobileWizardTabComponent>;
  @Output() private onFinish: EventEmitter<void> = new EventEmitter<void>();
  @Output() private onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() private tabChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor(private mobileWizardSettingsService: MobileWizardSettingsService,
              private changeDetectorRef: ChangeDetectorRef,
              private languageService: LanguageService) {
    this.mobileWizardSettingsService.settingsChanged.subscribe((wizardSettings: WizardSettings) => {
      this.currentStep = 0;
      this.reindexTabs(wizardSettings);
    });
  }

  public ngAfterViewInit(): void {
    if (!this.wizardOptions) {
      return console.error('bh-mobile-wizard - wizardOptions` have to be set to use this component');
    }

    const key: string = this.getWizardOptionsKey();
    const tabs: WizardTabOptions[] = this.getWizardTabOptions();
    const wizardSettings: WizardSettings = this.mobileWizardSettingsService.registerWizard(key, tabs);
    this.reindexTabs(wizardSettings);
  }

  public getWizardOptionsKey(): string {
    return Object.keys(this.wizardOptions)[0];
  }

  public nextTab(): void {
    if (this.currentStep !== this.getWizardTabOptions().length - 1) {
      this.currentStep++;
      this.passCurrentStepToTabs();
      this.tabChanged.emit();
    }
  }

  public previousTab(): void {
    if (this.currentStep !== 0) {
      this.currentStep--;
      this.passCurrentStepToTabs();
      this.tabChanged.emit();
    }
  }

  public openSettings(): void {
    this.mobileWizardSettingsService.showSettings(this.getWizardOptionsKey());
  }

  public finishWizard(): void {
    this.onFinish.emit();
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public goToTab(key: string): void {
    const desiredTab = this.tabs.filter(tab => tab.key === key);
    if (desiredTab.length > 0) {
      const index = desiredTab[0].getIndex();
      if (index > 0 && index !== this.currentStep) {
        this.currentStep = index;
        this.passCurrentStepToTabs();
      }
    }
  }

  private passCurrentStepToTabs(): void {
    this.tabs.forEach((mobileWizardTabComponent: MobileWizardTabComponent) => {
      mobileWizardTabComponent.setCurrentStep(this.currentStep);
    });
  }

  private getWizardTabOptions(): WizardTabOptions[] {
    const key: string = this.getWizardOptionsKey();
    return this.wizardOptions[key];
  }

  private reindexTabs(wizardSettings: WizardSettings): void {
    let counter = 0;
    this.tabs.forEach((mobileWizardTabComponent: MobileWizardTabComponent) => {
      const currentTabKey: string = mobileWizardTabComponent.key;
      const tabSettings: WizardTabSettings = wizardSettings[this.getWizardOptionsKey()].tabs[currentTabKey];
      if (tabSettings.show === true) {
        mobileWizardTabComponent.setCurrentStep(this.currentStep);
        mobileWizardTabComponent.setIndex(counter);
        this.totalSteps = counter;
        counter++;
      } else {
        mobileWizardTabComponent.setIndex(undefined);
      }
    });
    this.changeDetectorRef.detectChanges();
  }
}
