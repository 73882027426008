import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapLoaderService } from '../../services/map-loader.service';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';
import { MapLocationMarker } from '../../interfaces/map-location-marker.interface';
import { LocationToLatLngLiteralPipe } from '../../pipes/location-to-lat-lng-literal.pipe';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';
import { MapLocationsCluster } from '../../interfaces/map-location-cluster.interface';

interface ClusterClickEvent {
  clickedCluster: MapLocationsCluster;
  currentCluster: MapLocationsCluster;
}

@Component({
  selector: 'bh-map-clustered',
  templateUrl: './map-clustered.component.html',
  styleUrls: ['./map-clustered.component.scss']
})
export class MapClusteredComponent implements OnInit {
  @Input() clusters: MapLocationsCluster[] = [];
  @Input() gridSize: number;
  @Input() disableAutoPan = true;
  @Input() enableLabels = false;
  @Output() markerClicked = new EventEmitter<MapLocationMarker>();
  @Output() mapInitialized = new EventEmitter<google.maps.Map>();
  @Output() clusterClicked = new EventEmitter<MapLocationsCluster>();

  public defaultMapOptions: google.maps.MapOptions = {
    restriction: { strictBounds: true, latLngBounds: { north: 85, south: -85, west: -180, east: 180 } }
  }
  public readonly isMapAPILoaded = this.mapLoaderService.isLoaded;
  public infoWindows: Partial<MapLocationMarker | MapLocationsCluster>[] = [];
  public map: google.maps.Map;

  private readonly defaultNormalizedMapZoom = 15;
  public readonly defaultPosition = { location: { lat: 50.9333, lon: 10.5833 }, zoom: 5 };

  constructor(
    private mapLoaderService: MapLoaderService,
    private positionConverter: LocationToLatLngLiteralPipe,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.isMapAPILoaded.subscribe(() => {
      this.setDefaultMapOptions();
    });
  }

  public onMapInitialized(map: google.maps.Map): void {
    this.map = map;
    this.mapInitialized.emit(map);
    this.setDefaultPosition();
  }

  public moveTo(location: LatLon, zoom?: number): void {
    if (zoom) {
      this.map.setZoom(zoom);
    }
    this.map.panTo({ lat: location.lat, lng: location.lon });
  }

  public fitBounds(locations: LatLonLocation[]): void {
    if (locations?.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      locations
        .map(loc => this.positionConverter.transform(loc?.location))
        .filter(Boolean)
        .forEach(position => bounds.extend(position));

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => this.normalizeZoom(locations));

      this.map.fitBounds(bounds);
    }
  }

  public openInfoWindows(markers: Partial<MapLocationMarker | MapLocationsCluster>[]): void {
    this.infoWindows = markers;
    this.cdr.detectChanges();
  }

  public closeAllWindows(): void {
    this.infoWindows = [];
    this.cdr.detectChanges();
  }

  public onClusterClick({ clickedCluster, currentCluster }: ClusterClickEvent): void {
    this.clusterClicked.emit({ ...clickedCluster, infoWindow: currentCluster.infoWindow });
  }

  public setDefaultPosition(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.moveTo(this.convertCoordsToLatLon(position.coords), this.defaultPosition.zoom),
        () => this.moveTo(this.defaultPosition.location, this.defaultPosition.zoom)
      );
    } else {
      this.moveTo(this.defaultPosition.location, this.defaultPosition.zoom);
    }
  }

  private normalizeZoom(locations: LatLonLocation[]): void {
    this.map.setZoom(locations.length > 1 ? this.map.getZoom() - 1 : this.defaultNormalizedMapZoom);
  }

  private convertCoordsToLatLon(coords: GeolocationCoordinates): LatLon {
    return { lat: coords.latitude, lon: coords.longitude };
  }

  private setDefaultMapOptions(): void {
    this.defaultMapOptions = {
      restriction: { strictBounds: true, latLngBounds: { north: 85, south: -85, west: -180, east: 180 } },
      fullscreenControl: true,
      fullscreenControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
      zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM }
    };
  }

}
