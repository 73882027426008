import { LanguageService } from 'app/shared/services/language.service';
import {
  GuardedNavigableInputComponent,
} from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { Directive, EventEmitter, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentsService } from '../../../shared/equipments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RouterHistory } from '../../../../../shared/router-history';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { environment } from 'environments/environment';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';

@Directive()
export abstract class BaseEquipmentMapComponent extends GuardedNavigableInputComponent implements OnInit {

  length: number;
  public filters: FilterType[] = [];
  public onFiltersUpdated: EventEmitter<any> = this.equipmentStore.onFiltersUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  public routeButton: ButtonInfo = {
    route: 'assets/equipment/list/',
    tooltip: this.languageService.getInstant('general.list'),
    iconName: 'list'
  };
  private _searchForm: UntypedFormGroup;
  private readonly usedFilterTypes: FilterTypes[] = [
    FilterTypes.ORGANISATION,
    FilterTypes.LABEL,
    FilterTypes.EQUIPMENT_CLASS,
    FilterTypes.MATCH_ALL_LABELS
  ]

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentStore: EquipmentsDataSource,
              protected equipmentService: EquipmentsService,
              protected organisationService: OrganisationsService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.equipmentStore.equipmentLocations
      .pipe(untilDestroyed(this))
      .subscribe(value => this.onTotalCountUpdated.emit(value.locations.length));

    this.onFiltersUpdated
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setFilters());
    this.setFilters();
  }

  public get searchForm(): UntypedFormGroup {
    return this._searchForm;
  }

  public set searchForm(searchForm: UntypedFormGroup) {
    this._searchForm = searchForm;
    this.onSearchFormType();
  }

  public buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl()
    });

    this.setSearchTerms();
  }

  public setSearchTerms(emitEvent = true): void {
    this.searchForm.patchValue({terms: this.equipmentStore.searchTerms}, { emitEvent });
  }

  public onSearchFormType(): void {
    this.searchForm.get('terms').valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(() => this.updateOnSearch());
    this.setSearchTerms(false);
  }

  public updateOnSearch(): Observable<string> {
    this.equipmentStore.searchTerms = this.termsValue;
    this.equipmentStore.updateMap(true);
    return of('');
  }

  public updateFilters(commands: UpdateFilterCommand[]) {
    this.equipmentStore.updateFilterParams(commands);
    this.equipmentStore.updateMap(true);
  }

  get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  private setFilters(): void {
    this.filters = this.equipmentStore.getFilterSlice(this.usedFilterTypes);
  }
}
