import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { EquipmentTypesService } from '../../../../../shared/equipmenttypes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { OrganisationsService } from '../../../../../../organisation/shared/organisations.service';
import { BaseEquipmentViewDetailComponent } from '../base/base-equipment-view-detail.component';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-equipment-view-detail',
  templateUrl: './mobile-equipment-view-detail.component.html',
  styleUrls: ['./mobile-equipment-view-detail.component.scss']
})
export class MobileEquipmentViewDetailComponent extends BaseEquipmentViewDetailComponent implements OnInit {

  constructor(protected equipmentsService: EquipmentsService,
              protected equipmentTypesService: EquipmentTypesService,
              public equipmentStore: EquipmentsDataSource,
              protected organisationsService: OrganisationsService,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected equipmentCheckerService: EquipmentCheckerService,
              protected routerHistory: RouterHistory) {
    super(equipmentStore, authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
  }

}
