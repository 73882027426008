<div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
<div class="list-view-main__left-body scrollable-table-container"
  fxFlex
  fxLayout="row"
  fxLayoutGap="15px"
  fxLayoutAlign="start stretch">

  <mat-table [dataSource]="data"
    bhMatTableLongHeaderTooltip
    class="scrollable-table">

    <ng-container *ngFor="let column of columnDefs"
      [cdkColumnDef]="column.cdkColumnDef">

      <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
        <mat-header-cell *cdkHeaderCellDef class="table-column-100">
          {{column.title}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="table-column-100">
          <span class="text-overflow-ellipsis-nowrap" [bhOverflowTooltip]="column.valueCallback(row)">{{ column.valueCallback(row) }}</span>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
        <mat-header-cell *cdkHeaderCellDef class="table-column-100">
          {{column.title}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="table-column-100">
          {{ column.valueCallback(row) | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.type === COLUMN_TYPE.EQUIPMENT_NAME">
        <mat-header-cell *cdkHeaderCellDef class="table-column-100">
          {{column.title}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="table-column-100">
          <span *ngIf="(row.equipmentName || row.equipmentModel) as equipmentName"
          class="text-overflow-ellipsis-nowrap" [bhOverflowTooltip]="equipmentName">
            {{ equipmentName }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.type === COLUMN_TYPE.OTHER">
        <ng-container [ngSwitch]="column.cdkColumnDef">

          <ng-container *ngSwitchCase="COLUMN_DEFINITION.MAINTENANCE_CATEGORY">
            <mat-header-cell *cdkHeaderCellDef class="table-column-100">{{column.title}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="table-column-100">
              <span class="text-overflow-ellipsis-nowrap" [bhOverflowTooltip]="column.valueCallback(row) | maintenanceCategory">
                {{ column.valueCallback(row) | maintenanceCategory }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="COLUMN_DEFINITION.MAINTENANCE_RESULT">
            <mat-header-cell *cdkHeaderCellDef class="table-column-maintenance-result">{{column.title}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="table-column-maintenance-result">
              <bh-maintenance-result-badge *ngIf="column.valueCallback(row) as result"
                [result]="result"
                [hideText]="true"
                class="result-badge">
              </bh-maintenance-result-badge>
            </mat-cell>
          </ng-container>

        </ng-container>
      </ng-container>

      <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
        <mat-header-cell *cdkHeaderCellDef class="table-column-50"></mat-header-cell>
        <mat-cell *cdkCellDef="let row"
            class="table-column-50"
            [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.completedMaintenanceTask.s'|translate }"
            (click)="onNavigateToTaskCompleted(row)">
          <mat-icon>open_in_new</mat-icon>
        </mat-cell>
      </ng-container>

    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>


</div>
</div>
