<div class="mobile-chat-component" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="header">
    <div class="header__mobile" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon *ngIf="addUserListVisible" fxFlex="10" class="header__mobil-close-chat"
                (click)="closeUserList()">
        keyboard_arrow_left
      </mat-icon>
      <span *ngIf="addUserListVisible" fxFlex="80">{{'modules.communication.chat.startNewChat'|translate}}</span>
      <span *ngIf="!addUserListVisible " class="header__mobil-my-chats"
            fxFlex="90">{{'modules.communication.chat.myChats'|translate}}</span>
      <mat-icon fxFlex="10" (click)="goToLandingPage()">close</mat-icon>
    </div>
  </div>
  <div class="chat-list" #chatList fxFlex>
    <div *ngIf="!addUserListVisible">
      <mat-list>
        <mat-list-item *ngFor="let chat of communicationDataSource.chats | async" (click)="goToMobileChat(chat.chatPartnerId)">
          <div fxLayout="row" fxLayoutAlign="end center" fxFill>
            <div fxLayout="column" fxFlex="90">
              <div class="chat-list__chat-title">
                <i>{{chat.chatPartnerStatus | ChatUserStatus}}</i>{{chat.chatPartnerFullName}}
              </div>
              <div class="chat-message">{{ chat.lastMessage }}</div>
            </div>
            <div fxFlex="10" fxLayout="column" fxLayoutAlign="end center">
              <div class="chat-list__unread-count" *ngIf="chat.unreadCount > 0">
                {{ chat.unreadCount }}
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div *ngIf="addUserListVisible">
      <mat-list>
        <mat-list-item *ngFor="let user of suggestedChatUsers | async" (click)="goToMobileChat(user.userId)">
          {{ user.firstName }} {{ user.name }}
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="chat-component_add-contact" *ngIf="!addUserListVisible">
    <button mat-fab (click)="openUserList()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="chat-component_add-contact" *ngIf="addUserListVisible">
    <button mat-fab (click)="closeUserList()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>
</div>
