import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { MaintenanceTaskAdapter } from './maintenance-task-adapter.class';
import {
  MaintenanceTaskCompletedSearch
} from '../../../modules/maintenance/tasks/shared/maintenance-task-completed-search.interface';
import { Task } from '../../contract/task/task';

@Component({
  selector: 'bh-maintenance-completed-task-action-button',
  templateUrl: './maintenance-completed-task-action-button.component.html',
  styleUrls: ['./maintenance-completed-task-action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceCompletedTaskActionButtonComponent {
  public taskAdapted: MaintenanceTaskAdapter;
  @Input() public set task(value: TaskCompleted | MaintenanceTaskCompletedSearch | Task) {
    this.taskAdapted = new MaintenanceTaskAdapter(value);
    this.cdr.markForCheck();
  }
  @Input() showDetailsButton = true;

  @Output() public edit = new EventEmitter<MaintenanceTaskAdapter>();
  @Output() public delete = new EventEmitter<MaintenanceTaskAdapter>();
  @Output() public details = new EventEmitter<MaintenanceTaskAdapter>();

  constructor(private cdr: ChangeDetectorRef) {}
}
