<mat-card fxFlexFill class="entity-creation">
  <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>view_module</mat-icon>
      <span>{{'modules.organisation.customerRolesEdit.editActivatedRoles'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.save'|translate}} |
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.cancel'|translate}}</button>
    </div>

    <div class="entity-creation-form" fxFlex>
      <form [formGroup]="customerRolesEditForm" novalidate>
          <div class="entity-creation-form-right" fxFlex="40">
            <div class="entity-creation-form-title">{{'general.role.pl'|translate|uppercase}}</div>

            <mat-list>
              <mat-list-item class="entity-creation-form-right__list-item"
                             [matTooltip]="'modules.organisation.customerRolesEdit.fleetAdminAvailable'|translate"
                             [matTooltipDisabled]="isFleetAdmin()"
                             [ngClass]="{'entity-creation-form-right__list-item--selected': role.isAvailable,
                                         'disabled_role': hasBrzBlacklistAuth(role.name)}"
                             *ngFor="let role of availableRoles;"
                             (click)="selectRole(role.name)">
                <mat-icon matListItemIcon>check_circle</mat-icon>
                <p matListItemTitle>{{role.name}} </p>
                <p matListItemLine *ngIf="hasBrzBlacklistAuth(role.name)">({{'modules.organisation.customerRolesEdit.notPossibleWithBrz'|translate}})</p>
              </mat-list-item>
            </mat-list>
          </div>

          <div class="entity-creation-form-right" fxFlex="40" *ngIf="hasConflicts">
            <div class="entity-creation-form-title">{{'general.conflict.s'|translate|uppercase}}</div>

            <div>
              {{'modules.organisation.customerRolesEdit.youRevokedRole'|translate}}
              {{'modules.organisation.customerRolesEdit.selectRoleToBeAssigned'|translate}}
            </div>
            <div>
              <mat-form-field>
                <mat-label>{{'modules.organisation.customerRolesEdit.replacementRole'|translate}}</mat-label>
                <mat-select formControlName="fallbackRoleId">
                  <mat-option *ngFor="let role of availableRolesList()" [value]="role.roleId">
                    {{role.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
      </form>
    </div>

  </mat-card-content>
</mat-card>
