import { LanguageService } from './../../../../../../shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { LifeCycleReminder } from '../../../../contract/life-cycle-reminder';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../../../shared/enums/modules.enum';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { RouterHistory } from '../../../../../../shared/router-history';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-view-completed-reminder',
  templateUrl: './mobile-equipment-view-completed-reminder.component.html',
  styleUrls: ['./mobile-equipment-view-completed-reminder.component.scss']
})
export class MobileEquipmentViewCompletedReminderComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public completedReminder: Observable<LifeCycleReminder>;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    this.completedReminder = this.route.params.pipe(
        flatMap(params => this.equipmentStore.getLifeCycleDetails(params['id'], params['lifecycleId'])),
        map(lifeCycle => lifeCycle as LifeCycleReminder));
  }

  ngOnDestroy(): void {
  }

  get allowedToSee(): boolean {
    return this.hasAuthority(Authorities.MAINTENANCE_TASK_VIEW) && this.hasModule(Modules.SERVICE);
  }

  public navigateBack(): void {
    this.goBack(`/mobile/equipments/view/${this.route.snapshot.params.id}/timeline`);
  }
  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }
}
