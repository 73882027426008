<ng-container *ngIf="item?.value; else emptyValueTempl">
  <ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="transportHistoryFieldType.DATE">
      <bh-date-local [date]="item.value"></bh-date-local>
    </ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.DURATION">
      {{item.value | formatDuration}}
    </ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.STATE">{{item.value | transportState}}</ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.PRIORITY">{{item.value | transportPriority}}</ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.ADDRESS">
      <div class="text-overflow" *ngIf="item.value.country">{{'general.address.country'|translate}}: {{item.value.country | countryName | defaultValue : emptyValue}}</div>
      <div class="text-overflow" *ngIf="item.value.street">{{'general.address.street'|translate}}: {{item.value.street | defaultValue : emptyValue}}</div>
      <div class="text-overflow" *ngIf="item.value.streetNumber">{{'general.address.number'|translate}}: {{item.value.streetNumber | defaultValue : emptyValue}}</div>
      <div class="text-overflow" *ngIf="item.value.postalCode">{{'general.address.postCode'|translate}}: {{item.value.postalCode | defaultValue : emptyValue}}</div>
      <div class="text-overflow" *ngIf="item.value.city">{{'general.address.city'|translate}}: {{item.value.city | defaultValue : emptyValue}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.LOCATION">
      <div class="text-overflow">{{'general.location.latitude'|translate}}: {{item.value.lat | defaultValue : emptyValue}}</div>
      <div class="text-overflow">{{'general.location.longitude'|translate}}: {{item.value.lon | defaultValue : emptyValue}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.CONTACT_TYPE">{{item.value | contactType}}</ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.PROJECT_STATUS">{{item.value | projectStatus}}</ng-container>
    <ng-container *ngSwitchCase="transportHistoryFieldType.TRANSPORT_ITEM_TYPE">{{item.value | transportItemType}}</ng-container>
    <ng-container *ngSwitchDefault>{{item.value | defaultValue : emptyValue}}</ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyValueTempl>{{emptyValue}}</ng-template>
