import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { TelematicUnitLastChangesType } from 'app/modules/equipment/contract/telematic-unit-traffic-last-changes.enum';
import { TelematicUnitTrafficBase } from './telematic-unit-traffic-base.class';


@Pipe({
  name: 'telematicUnitTrafficTooltip'
})
export class TelematicUnitTrafficTooltipPipe extends TelematicUnitTrafficBase implements PipeTransform {

  constructor(private languageService: LanguageService) {
    super();
  }

  public transform(lastDate: Date): string {
    switch (this.getTrafficLastChangesType(lastDate)) {
      case TelematicUnitLastChangesType.TWO_MONTHS_AGO:
        return this.translate('modules.equipment.telematicsUnitsList.trafficWarning.twoMonthsAgo');
      case TelematicUnitLastChangesType.WEEK_AGO:
        return this.translate('modules.equipment.telematicsUnitsList.trafficWarning.weekAgo');
      default:
        return this.translate('modules.equipment.telematicsUnitsList.trafficWarning.lastWeek');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
