import { filter, scan, Subject, Subscription, tap, timer } from 'rxjs';

export class DebounceCounter {
  private _message = new Subject<any>();
  public readonly message = this._message.asObservable();

  private timeIntervalSubscr: Subscription;
  private counterSubscr: Subscription;
  private nextSubject = new Subject<any>();
  private readonly defaultAmount = 1;
  private readonly defaultTimeInterval = 500;


  constructor(private amount?: number, private timeInterval?: number) {
    this.amount = amount || this.defaultAmount;
    this.timeInterval = (timeInterval && timeInterval >= 0) ? timeInterval : this.defaultTimeInterval;
    this.resetCounter();
  }

  public next(): void {
    this.resetTimer();
    this.nextSubject.next(true);
  }

  private resetTimer(): void {
    if (this.timeIntervalSubscr) {
      this.timeIntervalSubscr.unsubscribe();
    }
    this.timeIntervalSubscr = timer(this.timeInterval)
      .subscribe(() => this.resetCounter());
  }

  private resetCounter(): void {
    if (this.counterSubscr) {
      this.counterSubscr.unsubscribe();
    }
    this.counterSubscr = this.nextSubject
      .pipe(
        scan(i => ++i, 0),
        filter(i => i >= this.amount))
      .subscribe(() => {
        this._message.next(true);
        this.fullReset();
      });
  }

  private fullReset(): void {
    this.resetCounter();
    this.resetTimer();
  }
}
