import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum DeleteDataResult {
  CANCEL,
  DELETE_DATA,
  KEEP_DATA
}

export interface ConfirmationDeleteDataDialogData {
  confirmTitle: string;
  confirmMessage: string;
  cancelButtonLabel: string;
  keepDataButtonLabel: string;
  deleteDataButtonLabel: string;
}

@Component({
  selector: 'bh-confirmation-delete-data-dialog',
  templateUrl: './confirmation-delete-data-dialog.component.html',
  styleUrls: ['./confirmation-delete-data-dialog.component.scss']
})
export class ConfirmationDeleteDataDialogComponent {

  readonly DeleteDataResult = DeleteDataResult;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDeleteDataDialogData) {}
}
