import { environment } from 'environments/environment';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SearchEquipment } from '../../../../equipment/contract/search-equipment.interface';
import { DispositionDataSource } from '../../../shared/disposition.datasource';
import {
  EquipmentAssignToProjectDialogComponent
} from '../../../../equipment/equipments/equipment-assign-to-project/dialog/equipment-assign-to-project-dialog.component';
import { CustomerLabel } from '../../../../../shared/contract/customer-label.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AssignEquipmentToProjectCommand } from '../../../../equipment/contract/assign-equipment-to-project-command';
import { DispositionEquipment } from '../../../../equipment/shared/disposition-equipment';
import { DisplayService } from '../../../../../shared/display.service';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { forkJoin, merge, Observable, pipe } from 'rxjs';
import * as _ from 'lodash';
import { LanguageService } from '../../../../../shared/services/language.service';
import { isDefined } from '../../../../../shared/utils';
import { DraggableItemType } from 'app/shared/enums/draggable-item-type.enum';
import { DragDropContainerIdentifier } from 'app/shared/enums/drag-drop-container-identifier.enum';
import { DispositionColumnService } from 'app/modules/disposition/shared/services/disposition-column.service';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { ThumbnailSize } from '../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';
import { AdditionalFieldValue } from '../../../../osp-ui/rich-components/bh-additional-field/additional-field';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-disposition-board-search-equipment',
  templateUrl: 'disposition-board-search-equipment.component.html',
  styleUrls: ['disposition-board-search-equipment.component.scss']
})
export class DispositionBoardSearchEquipmentComponent extends RoleAuthorityGuardsComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  public FILTER_ALL = 'ALL';
  public FILTER_AVAILABLE = 'AVAILABLE';
  public FILTER_UNAVAILABLE = 'UNAVAILABLE';
  public readonly dragType = DraggableItemType;
  public readonly ThumbnailSize = ThumbnailSize;

  public labels: Observable<CustomerLabel[]> = this.dispositionStore.searchLabelsAvailable;
  public searchForm: UntypedFormGroup;
  public startDateControl: UntypedFormControl;
  public endDateControl: UntypedFormControl;
  public filterForm: UntypedFormGroup;
  public equipments: SearchEquipment[];
  public additionalFields: AdditionalFieldValue[];
  public selectedEquipments: SearchEquipment[] = [];
  public currentFilter = this.FILTER_ALL;
  public viewChecked = false;
  public groupedEquipmentTypes: Observable<EquipmentTypeFilterView[]> = this.dispositionStore.searchEquipmentTypesAvailable;
  public readonly containerIdentifiers = DragDropContainerIdentifier;

  private termsControl: UntypedFormControl;

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  constructor(private formBuilder: UntypedFormBuilder,
              public dispositionStore: DispositionDataSource,
              protected router: Router,
              private dialog: MatDialog,
              private columnService: DispositionColumnService,
              private displayService: DisplayService,
              protected authService: KeycloakService,
              protected langService: LanguageService) {
    super(authService);
  }

  public get startDate(): Date {
    return moment(this.startDateControl.value).startOf('day').toDate();
  }

  public get endDate(): Date {
    const endDate = this.endDateControl.value;
    return endDate && moment(endDate).endOf('day').toDate();
  }

  public ngOnInit(): void {
    this.columnService.dispositionEquipmentListViewPageSize.subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });
    this.dispositionStore.equipments
      .pipe(untilDestroyed(this))
      .subscribe((res: SearchEquipment[]) => {
        this.equipments = res;
        this.viewChecked = false;
      });
    this.dispositionStore.additionalFields
      .pipe(untilDestroyed(this))
      .subscribe((res: AdditionalFieldValue[]) => this.additionalFields = res);
    this.buildSearchForm();
    this.buildFilterForm();

    this.dispositionStore.updateAvailableSearchLabel();
    this.dispositionStore.updateAvailableEquipmentTypes();

    this.applySearchFilters();
    this.dispositionStore.updateListing();
  }

  public ngOnDestroy(): void {
    // DON'T DELETE! https://github.com/NetanelBasal/ngx-take-until-destroy
  }

  public ngAfterViewInit(): void {
    this.onSearchFormType();
  }

  public selectFilter(filter: string): void {
    this.currentFilter = filter;
    this.onSearchFormSubmit();
  }

  public onSearchFormSubmit(): void {
    this.dispositionStore.searchTerms = this.termsControl.value;
    this.dispositionStore.searchEquipmentTypeIds = this.filterForm.get('selectedEquipmentTypes').value;
    this.dispositionStore.searchLabels = this.filterForm.get('selectedLabels').value;

    if (this.currentFilter === this.FILTER_ALL) {
      this.dispositionStore.searchAvailableFilter = null;
    }
    if (this.currentFilter === this.FILTER_AVAILABLE) {
      if (this.searchForm.valid) {
        this.dispositionStore.searchAvailableFilter = true;
        this.dispositionStore.searchAvailableFilterStart = this.startDate;
        this.dispositionStore.searchAvailableFilterEnd = this.endDate;
      }
    }
    if (this.currentFilter === this.FILTER_UNAVAILABLE) {
      if (this.searchForm.valid) {
        this.dispositionStore.searchAvailableFilter = false;
        this.dispositionStore.searchAvailableFilterStart = this.startDate;
        this.dispositionStore.searchAvailableFilterEnd = this.endDate;
      }
    }
    this.dispositionStore.updateListing(0, this.paginator.pageSize);
    this.paginator.firstPage();

    if (!this.dispositionStore.isTimeline) {
      this.dispositionStore.getEquipmentsTransportSearch();
    }
  }

  public onPaginateChange(event: PageEvent): void {
    this.dispositionStore.updateListing(event.pageIndex, event.pageSize);
    if (!this.dispositionStore.isTimeline) {
      this.dispositionStore.getEquipmentsTransportSearch();
    }
  }

  public onCheckboxToggle(equipment: SearchEquipment, event: MatCheckboxChange): void {
    if (event.checked) {
      if (this.selectedEquipments.map(eq => eq.equipmentId).indexOf(equipment.equipmentId) === -1) {
        this.selectedEquipments.push(equipment);
      }
    } else {
      this.selectedEquipments = this.selectedEquipments
        .filter(eq => eq.equipmentId !== equipment.equipmentId);
    }
  }

  public equipmentSelected(equipment: SearchEquipment): boolean {
    return this.selectedEquipments.map(eq => eq.equipmentId).includes(equipment.equipmentId);
  }

  public equipmentCollapse(): void {
    this.dispositionStore.resetAdditionalFields();
  }

  public equipmentExpand(equipmentId: string): void {
    this.dispositionStore.loadAdditionalFields(equipmentId);
  }

  public deselectAllEquipments(): void {
    this.selectedEquipments = [];
  }

  public openDispositionDialog(): void {
    const dialogRef = this.dialog.open(EquipmentAssignToProjectDialogComponent, <MatDialogConfig>{
      disableClose: true
    });

    dialogRef.componentInstance.equipments = this.selectedEquipments.map(searchEquipment =>
      DispositionEquipment.fromSearchEquipment(searchEquipment));
    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.afterClosed()
      .subscribe((commands: AssignEquipmentToProjectCommand[]) =>
        this.onDispositionDialogClosed(commands)
      );
  }

  private onDispositionDialogClosed(commands: AssignEquipmentToProjectCommand[]): void {
    const userCanceledDialog = !(commands?.length > 0);
    if (userCanceledDialog) {
      return;
    }
    this.deselectAllEquipments();
    const assignmentSubscriptions =
      commands.map(command => this.dispositionStore.assignEquipmentToProject(command));
    forkJoin(assignmentSubscriptions)
      .subscribe(() => this.updateProjectsAndEquipmentList());
  }

  private updateProjectsAndEquipmentList() {
    setTimeout(() => {
      this.dispositionStore.getProjectsWithAssignments();
      this.dispositionStore.updateListing();
    }, environment.DELAY_LONG)
  }

  /*
   * TODO: Remove when updating angular material solves the problem
   * The current version of angular material produces a flash of unstyled content when expansion panels are being rendered.
   * Disabling the animation on the panels fixes the problem, however, the animations are desired nonetheless.
   * To prevent the UI from exposing unexpected behavior while still keeping animations, the animations are activated on the
   * AfterViewChecked lifecycle hook, after the iterable elements have been initialized.
   *
   * Possibly related issue: https://github.com/angular/material2/issues/13870
   */
  public ngAfterViewChecked(): void {
    if (!this.viewChecked && this.equipments.length > 0) {
      // Changing the value in setTimeout prevents expressionchangedafterithasbeencheckederror
      setTimeout(() => this.viewChecked = true);
    }
  }

  public navigateToEquipmentDispo(equipmentId: string): void {
    this.displayService.displayTableAndDetails();
    this.router.navigate([`assets/equipment/list/${equipmentId}/disposition`]);
  }

  public getEquipmentNameOrModel(equipment: SearchEquipment): string {
    return equipment.equipmentName || equipment.equipmentModel
      || this.translate('modules.disposition.equipmentSearch.noNameOrModel');
  }

  public getNameModelTooltip(equipment: SearchEquipment): string {
    let info = equipment.equipmentName ? this.translate('modules.equipment.base.name')
      : this.translate('modules.equipment.base.model.s');
    let nameModelString = this.getEquipmentNameOrModel(equipment);
    return info + (nameModelString.length > 25 ? ': ' + nameModelString : '');
  }

  public getIntNrAndSerialNumber(equipment: SearchEquipment): string {
    return (equipment.equipmentCustomerSerialNumber || this.translate('general.notSpecified'))
      + ' / ' + (equipment.equipmentSerialNumber || this.translate('general.notSpecified'));
  }

  public getProjectNameOrDefault(equipment: SearchEquipment): string {
    return equipment.currentLocationName || this.translate('modules.disposition.equipmentSearch.noCurrentProject');
  }

  public getProjectTooltip(equipment: SearchEquipment): string {
    const projectName = this.getProjectNameOrDefault(equipment);
    return this.translate('modules.disposition.equipmentSearch.currentProject')
      + (projectName.length > 35 ? ': ' + projectName : '');
  }

  private onSearchFormType(): void {
    const operators = pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(_.isEqual));

    merge(
      this.searchForm.valueChanges.pipe(operators),
      this.filterForm.valueChanges.pipe(operators)
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onSearchFormSubmit());
  }

  private buildSearchForm(): void {
    this.startDateControl = new UntypedFormControl(new Date());
    this.endDateControl = new UntypedFormControl(null);
    this.termsControl = new UntypedFormControl('');

    this.searchForm = this.formBuilder.group({
      terms: this.termsControl,
      startDate: this.startDateControl,
      endDate: this.endDateControl
    });
  }

  private buildFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      selectedEquipmentTypes: null,
      selectedLabels: null,
    });
  }

  private applySearchFilters(): void {
    const {
      searchTerms,
      searchEquipmentTypeIds,
      searchLabels,
      searchAvailableFilter,
      searchAvailableFilterStart,
      searchAvailableFilterEnd
    } = this.dispositionStore;

    this.filterForm.patchValue({ selectedEquipmentTypes: searchEquipmentTypeIds, selectedLabels: searchLabels });

    const searchFormValues = { terms: searchTerms, startDate: new Date(), endDate: null };
    if (isDefined(searchAvailableFilter)) {
      searchFormValues.startDate = searchAvailableFilterStart;
      searchFormValues.endDate = searchAvailableFilterEnd;
      this.currentFilter = searchAvailableFilter ? this.FILTER_AVAILABLE : this.FILTER_UNAVAILABLE;
    }
    this.searchForm.patchValue(searchFormValues);
  }

  private translate(key: string): string {
    return this.langService.getInstant(key);
  }
}
