import { map } from 'rxjs/operators';
import { VehicleRequestParams } from '../contract/request-params/vehicle-request-params.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { Injectable } from '@angular/core';
import { TransportSearch } from '../../contracts/transport/transport-search.interface';
import { environment } from 'environments/environment';
import { TransportParams } from '../contract/request-params/transport-params.interface';
import { getHttpParameters } from 'app/shared/utils';
import { CreateTransportTaskCommand } from '../../contracts/transport/create-transport-task.command';
import { UpdateTransportTaskCommand } from '../../contracts/transport/update-transport-task.command';
import { TransportItem } from '../../../equipment/shared/transport-items.interface';
import { TransportTitleCount } from '../../contracts/transport/transport-title-count.interface';
import { UploadTransportDocumentCommand } from '../../contracts/transport/upload-transport-document.command';
import { VehicleSearch } from '../../contracts/transport/vehicle-search.interface';
import { TransportAssignmentCreateCommand } from '../contract/transportation-timeline-scheduler/transport-assignment-create.command';
import { TransportAssignmentUpdateCommand } from '../contract/transportation-timeline-scheduler/transport-assignment-update.command';
import { TransportAssignmentMap } from '../../contracts/transport/transport-assignment-map.interface';
import { VehicleFilterCollectionView } from 'app/shared/contract/filter/filter-view/vehicle-filter-collection/vehicle-filter-collection-view.interface';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { ChangeTransportStateCommand } from '../../contracts/transport/transport-task-change-state.command';
import { TransportAssignmentDeleteCommand } from '../../contracts/transport/transport-assignment-delete.command';
import { TransportViewResponse } from '../../contracts/transport/transport-view-response.interface';
import { TransportListItemView } from '../../contracts/transport/transport-list-view.interface';
import { TransportAssignmentExecutionStartCommand } from '../../contracts/transport/transport-assignment-execution-start.command';
import { TransportAssignmentExecutionResetCommand } from '../../contracts/transport/transport-assignment-execution-reset.command';
import { TransportAssignmentExecutionCommand } from '../../contracts/transport/transport-assignment-execution.command';
import { TransportAssignmentExecutionFinishCommand } from '../../contracts/transport/transport-assignment-execution-finish.command';
import { TransportFilterCollectionView } from 'app/shared/contract/filter/filter-view/transport-filter-collection/transport-filter-collection-view.interface';
import { DeleteTransportTaskCommand } from '../../contracts/transport/delete-transport-task.command';
import { TransportCopyCommand } from '../../contracts/transport/transport-copy.command';
import { TransportAuditResponse } from '../../contracts/transport/transport-audit-response.interface';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { UpdateTransportDocumentDescriptionCommand } from '../../contracts/transport/update-transport-document-description.command';
import { DeleteTransportDocumentCommand } from '../../contracts/transport/delete-transport-document.command';
import { TransportComment } from '../../contracts/transport/transport-comment.interface';
import { TransportCommentCreateCommand } from '../../contracts/transport/transport-comment-create.command';
import { TransportCommentUpdateCommand } from '../../contracts/transport/transport-comment-update.command';
import { TransportCommentDeleteCommand } from '../../contracts/transport/transport-comment-delete.command';
import { TransportAssignmentsRequestParams } from '../contract/request-params/transport-assignments-request-params.interface';
import { TransportAssignment } from '../../contracts/transport/transport-assignment.interface';
import { UpdateTransportDocumentNameCommand } from '../../contracts/transport/update-transport-document-name.command';

@Injectable()
export class TransportService {
  private transportsUrl = `${environment.APP_TRANSPORT_SERVICE_BASE_URL}/api/v1/transports`;
  private transportsItemsUrl = `${environment.APP_TRANSPORT_SERVICE_BASE_URL}/api/v1/transport-items`;
  private vehicleUrl = `${environment.APP_EQUIPMENT_SERVICE_BASE_URL}/api/v1/transport-vehicles`;
  private assignmentsUrl = `${environment.APP_TRANSPORT_SERVICE_BASE_URL}/api/v1/assignments`;
  private fileUrl = `${environment.APP_FILE_SERVICE_BASE_URL}/api/v1/file`;

  constructor(private http: HttpClient) {
  }

  public getAllTransports(params: TransportParams): Observable<PagedResponse<TransportListItemView>> {
    const httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<TransportListItemView>>(this.transportsUrl + '/search/all', {params: httpParams});
  }

  public getTransportsSearch(params: TransportParams): Observable<PagedResponse<TransportSearch>> {
    const httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<TransportSearch>>(this.transportsUrl + '/search/unplanned', {params: httpParams});
  }

  public getVehicles(params: VehicleRequestParams): Observable<PagedResponse<VehicleSearch>> {
    const httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<VehicleSearch>>(`${this.vehicleUrl}/search`, {params: httpParams});
  }

  public createTransportationTask(command: CreateTransportTaskCommand): Observable<string> {
    return this.http.post(this.transportsUrl + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  public setStatusOfTransportTask(command: ChangeTransportStateCommand): Observable<string> {
    return this.http.post(this.transportsUrl + '/state', JSON.stringify(command), {responseType: 'text'});
  }

  public updateTransportationTask(command: UpdateTransportTaskCommand): Observable<string> {
    return this.http.post(this.transportsUrl + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  public getTransportationTasksType(): Observable<string[]> {
    return this.http.get<string[]>(this.transportsUrl + '/transport-types');
  }

  public getTitles(term: string): Observable<TransportTitleCount[]> {
    const httpParams = getHttpParameters({term: term});
    return this.http.get<TransportTitleCount[]>(this.transportsUrl + '/find-titles', {params: httpParams});
  }

  public getTransportationTasksLocationGeneralData(transportId?: string): Observable<TransportItem[]> {
    const httpParams = getHttpParameters({transportId: transportId});
    return this.http.get<TransportItem[]>(this.transportsItemsUrl, {params: httpParams});
  }

  public deleteTempDocument(documentKey: string): Observable<string> {
    let httpParams = getHttpParameters({
      key: documentKey
    });
    return this.http.delete(this.fileUrl, {params: httpParams, responseType: 'text'});
  }

  public addDocument(command: UploadTransportDocumentCommand): Observable<string> {
    return this.http.post(this.transportsUrl + '/add-document', JSON.stringify(command), {responseType: 'text'});
  }

  // TODO: Remake it in a getting assignments by a SINGLE equipmentID on the backend side
  // (since we don't need getting by many IDs after a lazy-laoding implementation)
  public getTransportAssignmentsByEquipmentId(equipmentId: string): Observable<TransportAssignmentMap> {
    const httpParams = getHttpParameters({ equipmentId });
    return this.http.get<TransportAssignmentMap>(this.assignmentsUrl, {params: httpParams});
  }

  public getTransportAssignmentsByTimespan(params: TransportAssignmentsRequestParams): Observable<TransportAssignment[]> {
    const httpParams = getHttpParameters(params);
    return this.http.get<TransportAssignmentMap>(`${this.assignmentsUrl}/by-timespan`, {params: httpParams})
      .pipe(map(this.toTransportAssignments));
  }

  private toTransportAssignments(assignmentMap: TransportAssignmentMap): TransportAssignment[] {
    return Object.keys(assignmentMap).reduce((acc, key) =>
      [...acc, ...(assignmentMap[key] || [])],
      []);
  }

  public createAssignment(command: TransportAssignmentCreateCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateAssignment(command: TransportAssignmentUpdateCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteAssignment(command: TransportAssignmentDeleteCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateExecutionDates(command: TransportAssignmentExecutionCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/execution/dates`, JSON.stringify(command), {responseType: 'text'});
  }

  public startExecutionAssignment(command: TransportAssignmentExecutionStartCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/execution/start`, JSON.stringify(command), {responseType: 'text'});
  }

  public resetExecutionAssignment(command: TransportAssignmentExecutionResetCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/execution/reset-start`, JSON.stringify(command), {responseType: 'text'});
  }

  public resetFinishedExecutionAssignment(command: TransportAssignmentExecutionResetCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/execution/reset-finish`, JSON.stringify(command), {responseType: 'text'});
  }

  public finishExecutionAssignment(command: TransportAssignmentExecutionFinishCommand): Observable<string> {
    return this.http.post(`${this.assignmentsUrl}/execution/finish`, JSON.stringify(command), {responseType: 'text'});
  }

  public getVehicleFilters(params: Object): Observable<VehicleFilterCollectionView> {
    const httpParams = getHttpParameters(params);
    return this.http.get<VehicleFilterCollectionView>(`${this.vehicleUrl}/filter`, {params: httpParams});
  }

  public getTransportTaskById(transportId: string): Observable<TransportView> {
    return this.http.get<TransportViewResponse>(`${this.transportsUrl}/${transportId}`)
      .pipe(map(response => this.toTransportView(response)));
  }

  public hasTransportType(type: string): Observable<boolean> {
    const httpParams = getHttpParameters({transportType: type});
    return this.http.get<boolean>(this.transportsUrl + '/transport-type-exists', {params: httpParams});
  }

  public getTransportFilters(params: Object): Observable<TransportFilterCollectionView> {
    const httpParams = getHttpParameters(params);
    return this.http.get<TransportFilterCollectionView>(`${this.transportsUrl}/filter`, {params: httpParams});
  }

  public deleteTransport(command: DeleteTransportTaskCommand): Observable<string> {
    return this.http.post(this.transportsUrl + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public copyTransport(command: TransportCopyCommand): Observable<string> {
    return this.http.post(`${this.transportsUrl}/copy`, JSON.stringify(command), {responseType: 'text'});
  }

  public getAuditData(transportId: string): Observable<TransportAuditResponse> {
    return this.http.get<TransportAuditResponse>(`${this.transportsUrl}/history?transportId=${transportId}`);
  }

  public exportTransportList(params: Object): Observable<Blob> {
    const httpParams = getHttpParameters(params);
    return this.http.get(`${this.transportsUrl}/search/export`, {
      params: httpParams,
      responseType: 'blob',
    });
  }

  public getDocuments(transportId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(this.transportsUrl + '/' + transportId + '/documents');
  }

  public deleteDocument(command: DeleteTransportDocumentCommand): Observable<string> {
    return this.http.post(this.transportsUrl + '/delete-document', JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentDescription(command: UpdateTransportDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.transportsUrl}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentName(command: UpdateTransportDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.transportsUrl}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public getComments(transportId: string): Observable<TransportComment[]> {
    return this.http.get<TransportComment[]>(this.transportsUrl + '/' + transportId + '/comments');
  }

  public addComment(command: TransportCommentCreateCommand): Observable<string> {
    return this.http.post(`${this.transportsUrl}/add-comment`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateComment(command: TransportCommentUpdateCommand): Observable<string> {
    return this.http.post(`${this.transportsUrl}/update-comment`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteComment(command: TransportCommentDeleteCommand): Observable<string> {
    return this.http.post(`${this.transportsUrl}/remove-comment`, JSON.stringify(command), {responseType: 'text'});
  }

  public moveDoneToArchive(fromDate: string, toDate: string): Observable<Object> {
    return this.http.post(`${this.transportsUrl}/archive-for-date?fromDate=${fromDate}&toDate=${toDate}`,
      {responseType: 'object'});
  }

  public moveSelectedDoneToArchive(selectedTransports: string[]): Observable<Object> {
    return this.http.post(`${this.transportsUrl}/archive-selected`, JSON.stringify(selectedTransports),
      {responseType: 'json'});
  }

  private toTransportView(transport: TransportViewResponse): TransportView {
    return {
      ...transport,
      startItemId: transport.startItem?.id,
      targetItemId: transport.targetItem?.id
    }
  }
}
