import { Address } from '../../organisation/contract/address.interface';
import { LatLon } from '../../../shared/geolocation/lat-lon.interface';

export class DispositionStock {

  constructor(public stockId: string,
              public stockName: string,
              public stockCostCenter: string,
              public organisationId: string,
              public organisationName: string,
              public stockAddress?: Address,
              public location?: LatLon) {}
}

export function instanceOfDispositionStock(object: any): object is DispositionStock {
  return 'stockId' in object
    && 'stockName' in object
    && 'stockCostCenter' in object
    && 'organisationId' in object
    && 'organisationName' in object;
}
