import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BulkItemTransferDatasource } from '../../../../shared/bulk-item-transfer.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITransferItemWithValidityStatus, TransferItem } from '../../../../shared/transfer/model/transfer-item';
import { CreateStockToProjectTransferCartCommand } from '../../../../contract/create-stock-to-project-transfer-cart.command';
import { StockToProjectTransferItem } from '../../../../contract/stock-to-project-transfer-item';
import { TransferProject } from '../bulk-item-transfer-project-select/transfer-project';
import { TransferStore } from '../../shared/transfer.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import { TransferCartItem } from '../../../../contract/transfer-cart-item';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TransferCartDatasource } from 'app/modules/disposition/shared/transfer-cart.datasource';
import { EquipmentTransferDatasource } from 'app/modules/disposition/shared/equipment-transfer.datasource';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from 'app/core/keycloak';
import { RouterHistory } from 'app/shared/router-history';
import { ProjectToProjectTransferItem } from 'app/modules/disposition/contract/project-to-project-transfer-item';
import { CreateProjectToProjectTransferCartCommand } from 'app/modules/disposition/contract/create-project-to-project-transfer-cart.command';
import { LocationType } from '../../../../../../shared/enums/location-type.enum';
import { TransferItemTransferDateRequest } from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-item-transfer-date-request';
import { TransferItemsSearchMatcher } from 'app/modules/disposition/shared/transfer/utils/transfer-items-search-matcher.class';
import { RequestStatus } from '../../../../shared/enums/request-status.enum';
import { TransferItemType } from '../../../../shared/transfer/model/transfer-item-type.enum';
import {ITransfer} from '../../../../contract/transfer.interface';
import { TransferTargetProjectDatasource } from 'app/modules/disposition/shared/transfer-target-project.datasource';
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-stock-to-project-transfer-cart',
  templateUrl: './bulk-item-stock-to-project-transfer-cart.component.html',
  styleUrls: ['./bulk-item-stock-to-project-transfer-cart.component.scss']
})
export class BulkItemStockToProjectTransferCartComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public readonly faExchange: IconDefinition = faExchange;
  public project: TransferProject;
  public executedTransfers: ITransfer[] = [];
  public transferItems: BehaviorSubject<TransferItem[]> = new BehaviorSubject<TransferItem[]>([]);
  public transferItemsFiltered: Observable<TransferItem[]>;
  public transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];

  private transferItemsFilteredValue: TransferItem[] = [];
  private summaryTransferItems: TransferItem[];
  private filterControl: UntypedFormControl;
  private stockToProjectTransferItems: StockToProjectTransferItem[] = [];
  private projectToProjectTransferItems: ProjectToProjectTransferItem[] = [];
  private twoTransferTypes = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public bulkItemTransferDatasource: BulkItemTransferDatasource,
              public equipmentTransferDatasource: EquipmentTransferDatasource,
              private transferStore: TransferStore,
              private transferCartDatasource: TransferCartDatasource,
              private transferTargetProjectStore: TransferTargetProjectDatasource,
              private snackBar: MatSnackBar,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.filterControl = new UntypedFormControl();
    this.transferCartSubscription();
    this.transferSuccessSubscription();
    this.transferFailedSubscription();

    this.transferItemsFiltered =
      combineLatest([this.transferItems, this.filterControl.valueChanges.pipe(debounceTime(environment.DELAY_SHORTEST), startWith(''))])
      .pipe(
        map(([items, filterTerm]) => ([items, filterTerm, this.getSearchMatchTransferItemIds(items, filterTerm)])),
        map(([items, filterTerm, searchMatchItems]: [TransferItem[], string, Set<string>]) =>
          items.map((item: TransferItem) => {
            item.hiddenOption = !searchMatchItems.has(item.id);
            item.isActiveContainer = (filterTerm && searchMatchItems.has(item.id)) || item.isActiveContainer;
            return item;
          })
        ),
        tap(items => this.transferItemsFilteredValue = items),
      );
  }

  public ngOnDestroy(): void {
  }

  private transferCartSubscription(): void {
    this.transferCartDatasource.transferCart
    .pipe(untilDestroyed(this))
    .subscribe(items => {
      this.clearAllTransferItems();
      [this.projectToProjectTransferItems, this.stockToProjectTransferItems] = this.splitItemsBySourceLocation(items);
      if (this.hasChangeItemCount(items, this.transferItems.value)) {
        this.transferItems.next(this.convertItemsFromViewAmount(items));
      }
      this.checkTwoTransferTypes();
    });
  }

  private transferSuccessSubscription(): void {
    this.transferStore.transferSuccess.pipe(untilDestroyed(this)).subscribe(() => {
      this.executedTransfers.push(this.transferStore.transfer)

      if (!this.twoTransferTypes) {
        this.openSummaryDialog();
      }

      if (this.twoTransferTypes && this.executedTransfers.length === 2) {
        this.openSummaryDialog();
        this.twoTransferTypes = false;
      }
    });
  }

  private transferFailedSubscription(): void {
    this.transferStore.transferFailed.pipe(untilDestroyed(this)).subscribe(() => {
      this.snackBar.open(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'),
        this.translate('general.buttons.okay'), {
          duration: environment.DELAY_LONGEST
        });

      this.bulkItemTransferDatasource.updateListing();
      this.equipmentTransferDatasource.updateListing();
    });
  }

  private openSummaryDialog(): void {
    this.transferStore.summaryDialog(
      this.executedTransfers,
      this.summaryTransferItems,
      LocationType.PROJECT,
      this.project,
    ).afterClosed()
    .pipe(filter(result => result !== 'cancel'))
    .subscribe(() => {
        this.transferCartDatasource.removeAllTransferCartItems();
        this.goBack('transfer/equipment-transfer-list');
      }
    );
  }

  private getSearchMatchTransferItemIds(items: TransferItem[], filterTerm: string): Set<string> {
    if (!filterTerm) {
      return new Set((items || []).map(({id}) => id));
    }

    const searchMatcher = new TransferItemsSearchMatcher(items);
    return searchMatcher.getMatchItemIds(filterTerm);
  }

  private splitItemsBySourceLocation(items: TransferCartItem[]): [ProjectToProjectTransferItem[], StockToProjectTransferItem[]] {
    let projectToProjectItems: ProjectToProjectTransferItem[] = [];
    let stockToProjectItems: StockToProjectTransferItem[] = [];
    items.forEach(item => {
      item.amount.currentProjectId
        ? projectToProjectItems.push(ProjectToProjectTransferItem.fromViewAmount(item.amount, item.transferAmount))
        : stockToProjectItems.push(StockToProjectTransferItem.from(item.amount, item.transferAmount));
    });
    return [projectToProjectItems, stockToProjectItems];
  }

  private clearAllTransferItems(): void {
    this.projectToProjectTransferItems = [];
    this.stockToProjectTransferItems = [];
  }

  private convertItemsFromViewAmount(items: TransferCartItem[]): TransferItem[] {
    return items.map(item => TransferItem.fromViewAmount(item.amount, item.transferAmount, 0));
  }

  private checkTwoTransferTypes(): void {
    this.twoTransferTypes = this.hasTransferItems(this.projectToProjectTransferItems) &&
                            this.hasTransferItems(this.stockToProjectTransferItems);
  }

  private hasTransferItems(items: ProjectToProjectTransferItem[] | StockToProjectTransferItem[]): boolean {
    return items.map(item => item.amount).some(amount => amount);
  }

  private hasChangeItemCount(newList: TransferCartItem[], currentList: TransferItem[]): boolean {
    return newList.length !== currentList.length;
  }

  private validateTransferItems(items: TransferItem[], project: TransferProject): ITransferItemWithValidityStatus[] {
    return items.map((item) => {
      const newItem: ITransferItemWithValidityStatus = {
        transferItem: item,
        status: RequestStatus.SUCCESS,
        errorText: '',
      };
      if (item.currentProjectId === project.projectId && item.transferItemType === TransferItemType.EQUIPMENT) {
        newItem.status = RequestStatus.FAILED;
        newItem.errorText = '';
      }
      return newItem;
    });
  }

  public confirmCart(): void {
    this.transferStore.confirmationDialog(
      this.translate('modules.disposition.bulkItemTransfer.fulfillTransfer'),
      this.translate('general.buttons.cancel'),
      this.translate('modules.disposition.confirmation.title.transfer'),
      this.translate('modules.disposition.confirmation.message.transferToProject', {value: this.project.projectName}),
      LocationType.PROJECT,
      this.project.projectId,
      this.calculateTransferItems())
    .afterClosed()
    .pipe(filter(result => result !== 'cancel'))
    .subscribe({
      next: res => {
        this.transferStore.waitingDialogRef = this.transferStore.waitingDialog();
        this.transferTargetProjectStore.resetSearchLabels();
        this.createCart(res);
      }
    });
  }

  public clearCart(): void {
    this.transferCartDatasource.removeAllTransferCartItems();
    this.closeCart();
  }

  public closeCart(): void {
    this.transferTargetProjectStore.resetSearchLabels();
    this.goBack('transfer/equipment-transfer-list');
  }

  public remove(amountId: string) {
    this.transferCartDatasource.removeTransferCartItem(amountId);
  }

  public selectProject(project: TransferProject): void {
    this.transferItemsWithStatus = this.validateTransferItems(this.transferItemsFilteredValue, project);
    this.transferStore.transferItemsWithStatus = this.transferItemsWithStatus;
    this.project = project;
  }

  public updateAmount(item: TransferItem): void {
    this.transferCartDatasource.updateTransferItem(item.id, item.transferAmount);
  }

  public updateFilterControl(searchTerm: string): void {
    this.filterControl.patchValue(searchTerm.trim().toLowerCase());
  }

  public getTransferItemWithStatus(id: string): ITransferItemWithValidityStatus {
    return this.transferItemsWithStatus.find(item => item.transferItem.id === id);
  }

  private createCart(form: UntypedFormGroup): void {
    this.summaryTransferItems = this.transferItems.getValue().filter(item => item.transferAmount > 0);
    if (this.stockToProjectTransferItems.filter(item => item.amount > 0).length > 0) {
      const cmd: CreateStockToProjectTransferCartCommand = new CreateStockToProjectTransferCartCommand(
        this.project.projectId,
        this.stockToProjectTransferItems.filter(item => item.amount > 0),
        form.get('employeeId').value ? form.get('employeeId').value : null,
        form.get('externalEmployee').value ? form.get('externalEmployee').value : null,
        form.get('comment').value ? form.get('comment').value : null,
        form.get('historicTransferDateTime').value ? form.get('historicTransferDateTime').value : null);

      this.transferStore.transferStockToProject(cmd);
    }

    if (this.projectToProjectTransferItems.filter(
      item => item.amount > 0 &&
        (!Boolean(this.getTransferItemWithStatus(item.transferItemId)) ||
          this.getTransferItemWithStatus(item.transferItemId).status !== RequestStatus.FAILED))
      .length > 0
    ) {
      const cmd: CreateProjectToProjectTransferCartCommand = new CreateProjectToProjectTransferCartCommand(
        this.project.projectId,
        this.projectToProjectTransferItems.filter(item => item.amount > 0 &&
          (!Boolean(this.getTransferItemWithStatus(item.amountId)) ||
            this.getTransferItemWithStatus(item.amountId).status !== RequestStatus.FAILED)),
        form.get('employeeId').value ? form.get('employeeId').value : null,
        form.get('externalEmployee').value ? form.get('externalEmployee').value : null,
        form.get('comment').value ? form.get('comment').value : null,
        form.get('historicTransferDateTime').value ? form.get('historicTransferDateTime').value : null);

      this.transferStore.transferProjectToProject(cmd);
    }
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private calculateTransferItems(): TransferItemTransferDateRequest[] {
    let stockToProjectTransferItems: TransferItemTransferDateRequest[] = this.stockToProjectTransferItems.reduce((items, item) => {
      if (item.amount) {
        items.push(TransferItemTransferDateRequest.fromStockToProjectTransferItem(item));
      }
      return items;
    }, []);

    let projectToProjectTransferItems: TransferItemTransferDateRequest[] = this.projectToProjectTransferItems.reduce((items, item) => {
      if (item.amount) {
        items.push(TransferItemTransferDateRequest.fromProjectToProjectTransferItemInTransferList(item));
      }
      return items;
    }, []);
    return [...stockToProjectTransferItems, ...projectToProjectTransferItems];
  }
}
