<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{ bulkItem?.bulkItemName }}: {{'modules.disposition.changeAmount.editInventory'|translate}}</h1>
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <form [formGroup]="amountForm" fxLayout="column">
      <h4 *ngIf="stockReadonly">1. {{'general.stock.s'|translate}}:</h4>
      <h4 *ngIf="!stockReadonly">1. {{'modules.disposition.changeAmount.chooseStock'|translate}}:</h4>
      <div class="select-search">
        <mat-form-field>
          <mat-select [placeholder]="'general.labels.search'|translate:{value: 'general.stock.pl'|translate}"
                      disableOptionCentering="true"
                      formControlName="stockId"
                      [disabled]="stockReadonly">
            <mat-option class="mat-select-search">
              <ngx-mat-select-search [formControl]="filterControl"
                                     [placeholderLabel]="'general.labels.search'|translate:{value: 'general.stock.pl'|translate}"
                                     [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{value: 'general.stock.s'|translate}">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let stock of filteredStocks | async" [value]="stock?.stockId">
              {{stock?.stockName}} /
              {{stock?.organisationName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div>{{'modules.disposition.changeAmount.currentInventory'|translate}}:
          {{ getAvailable() !== null ? getAvailable() : '-'}}
          {{'general.from'|translate}}
          {{ getTotal() !== null ? getTotal() : '-' }} {{'general.available'|translate|lowercase}}
        </div>
      </div>
      <h4>2. {{'modules.disposition.changeAmount.editInventory'|translate}}:</h4>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <mat-form-field fxFlex="35" class="action-select">
          <mat-select formControlName="action" required>
            <mat-option value="add">
              {{'general.buttons.add'|translate}} ({{ bulkItem.bulkItemUnit | dimensionUnitDisplay }})
            </mat-option>
            <mat-option value="delete">
              {{'general.buttons.remove'|translate}} ({{ bulkItem.bulkItemUnit | dimensionUnitDisplay }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="20">
          <input matInput
                 formControlName="amount"
                 type="number"
                 class="align-right"
                 min="0"
                 (input)="decimalNumberValidityService.setInputDecimalDigitsNumberValidity($event.target.value, amount)"
                 [max]="getMaximumAmount()"
          >
          <mat-error *ngIf="amount.hasError('numberBigger') || amount.hasError('max')">
            {{'modules.disposition.changeAmount.quantityNotValidRange'|translate}}
          </mat-error>
          <mat-error *ngIf="amount.hasError('decimalDigitsNumberInvalid')">
            {{'shared.validation.maxDecimals'|translate:{value: 3} }}
          </mat-error>
        </mat-form-field>

        <fa-icon [icon]="faArrowRight" class="arrow-right"></fa-icon>

        <div fxLayout="column" fxFlex="30" class="new-amount">
          <span>{{'modules.disposition.base.newInventory'|translate}}: </span>
          <span class="new-amount-total-and-available">
            {{getNewAvailable() !== null ? getNewAvailable() : '-'}} {{'general.from'|translate}}
            {{getNewTotal() !== null ? getNewTotal() : '-'}} {{'general.available'|translate|lowercase}}
          </span>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="checkbox-button-section" fxLayout="row" fxLayoutAlign="end">
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            [disabled]="!isValid()"
            (throttledClick)="save()">
      {{'general.buttons.save'|translate}}
    </button>
  </div>
</div>
