import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import {DashboardStore} from '../../dashboard/dashboard.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridsterItem } from 'angular-gridster2';

@UntilDestroy()
@Component({
  selector: 'bh-basic-widget',
  templateUrl: './basic-widget.component.html',
  styleUrls: ['./basic-widget.component.scss']
})
export class BasicWidgetComponent implements OnInit, OnDestroy {
  @Input() index: number;
  @Input() widget: GridsterItem;
  @Input() form: UntypedFormGroup;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() configurationSave: EventEmitter<number> = new EventEmitter<number>();
  @Output() openedConfigurations: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() titleIconClick: EventEmitter<any> = new EventEmitter<any>();

  formValueSnapshot: any;
  editMode: boolean;
  showEdit: boolean;
  constructor(private dashboardStore: DashboardStore, private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }
    this.dashboardStore.editMode
      .pipe(untilDestroyed(this))
      .subscribe((editMode: boolean) => {
        this.cdr.markForCheck();
        this.showEdit = editMode;
        if (!this.showEdit) {
          this.editMode = false;
        }
      });
  }

  toggleEditMode(templateRef: TemplateRef<any>): void {
    this.form.addControl('title', new UntypedFormControl(this.widget.title));
    this.formValueSnapshot = {...this.form.value};
    this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'padded-dialog'
    });
  }

  deleteWidget(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.deleted.emit();
  }

  ngOnDestroy(): void {
  }

  public openConfiguration(event: boolean): void {
    this.openedConfigurations.emit(event);
  }

  closeConfiguration(save: boolean): void {
    if (save) {
      this.widget.title = this.form.get('title').value;
      this.configurationSave.emit(this.index);

    } else {
      if (this.formValueSnapshot) {
        this.form.patchValue(this.formValueSnapshot);
        this.formValueSnapshot = undefined;
      }
    }

    this.form.removeControl('title');

    this.dialog.closeAll();
  }
}
