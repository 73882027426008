import { ElementRef } from '@angular/core';
import { SubMenuTab } from 'app/modules/disposition/shared/interfaces/sub-menu-tab.interface';

const HIDDEN_TAB_CLASS_NAME = 'hidden-tab';

export class SubmenuTabsResizeHandler {
  private submenu: ElementRef;
  private tabsHeight: number[] = [];
  private tabsHeightLength: number;
  private moreButtonHeight: number;
  private shownTabsHeightSum: number;
  private subMenuContainerHeight: number;
  private tabsArray: any[] = [];
  private hiddenTabs: SubMenuTab[] = [];
  private subMenuTabs: SubMenuTab[] = [];

  constructor(submenuRef: ElementRef, subMenuTabs: SubMenuTab[]) {
    this.submenu = submenuRef;
    this.subMenuTabs = subMenuTabs;
    this.submenu.nativeElement.lastElementChild.classList.remove(HIDDEN_TAB_CLASS_NAME);

    this.defineSubMenuTabsHeightArray();
    this.moreButtonHeight = this.submenu.nativeElement.lastElementChild.offsetHeight;
    this.toggleMoreMenuButton();
  }

  private defineSubMenuTabsHeightArray(): void {
    this.tabsHeight = Array
      .from(this.submenu.nativeElement.firstChild.children)
      .map((tab: any) => tab.offsetHeight);
    this.tabsHeightLength = this.submenu.nativeElement.firstChild.children.length;
  }

  public onSubMenuResize = (): SubMenuTab[] => {
    this.subMenuContainerHeight = this.submenu.nativeElement.offsetHeight;
    this.tabsArray = Array.from(this.submenu.nativeElement.firstChild.children);
    this.shownTabsHeightSum = this.calculateShownTabsContainerHeight();

    if (this.subMenuContainerHeight < this.shownTabsHeightSum) {
      this.hideSubmenuTabs();
    } else {
      this.showSubmenuTabs();
    }

    this.toggleMoreMenuButton();
    return this.hiddenTabs;
  }

  public onTabsHeightArrayLengthChange(): void {
    if (this.tabsHeightLength !== this.submenu.nativeElement.firstChild.children.length) {
      this.defineSubMenuTabsHeightArray();
      this.onSubMenuResize();
    }
  }

  private toggleMoreMenuButton(): void {
    if (this.hiddenTabs.length) {
      this.submenu.nativeElement.lastElementChild.classList.remove(HIDDEN_TAB_CLASS_NAME);
    } else {
      this.submenu.nativeElement.lastElementChild.classList.add(HIDDEN_TAB_CLASS_NAME);
    }
  }

  private calculateShownTabsContainerHeight(): number {
    const sum = this.tabsArray.reduce((currentSum: number, tab: any) => {
        return tab.classList.contains(HIDDEN_TAB_CLASS_NAME)
          ? currentSum
          : currentSum + tab.offsetHeight;
      }, 0);
    return this.hiddenTabs.length ? sum + this.moreButtonHeight : sum;
  }

  private hideSubmenuTabs(): void {
    for (let index = this.tabsArray.length - 1; index >= 0; index--) {
      const curentElementClass = this.tabsArray[index].classList;
      if (!curentElementClass.contains(HIDDEN_TAB_CLASS_NAME)) {
        this.submenu.nativeElement.firstChild.children[index].classList.add(HIDDEN_TAB_CLASS_NAME);
        this.addHiddenTabToArray(index);
        this.shownTabsHeightSum = this.calculateShownTabsContainerHeight();
        if (this.subMenuContainerHeight >= this.shownTabsHeightSum) {
          break;
        }
      }
    }
  }

  private addHiddenTabToArray(tabIndex: number): void {
    const tabName: string = this.getTabRouterName(tabIndex);
    const tabNameToHide: SubMenuTab = this.subMenuTabs.find(tab => tab.name === tabName);
    if (tabNameToHide) {
      this.hiddenTabs.unshift(tabNameToHide);
    }
  }

  private getTabRouterName(tabIndex: number): string {
    const tabElementChildren: HTMLCollection[] = this.submenu.nativeElement.firstChild.children[tabIndex].children;
    const tabNameStartIndex = 1;
    return Array
      .from(tabElementChildren)
      .map((child: any) => child.textContent)
      .slice(tabNameStartIndex)
      .join(' ');
  }

  private showSubmenuTabs(): void {
    for (let index = 0; index < this.tabsArray.length; index++) {
      if (this.tabsArray[index].classList.contains(HIDDEN_TAB_CLASS_NAME)) {
        const tabHeight: number = this.tabsHeight[index];

        if (this.canShowTab(tabHeight)) {
          this.submenu.nativeElement.firstChild.children[index].classList.remove(HIDDEN_TAB_CLASS_NAME);
          this.removeHiddenTabFromArray(index);
          this.shownTabsHeightSum = this.calculateShownTabsContainerHeight();
        } else {
          break;
        }
      }
    }
  }

  private canShowTab(tabHeight: number): boolean {
    return this.subMenuContainerHeight >= this.shownTabsHeightSum + tabHeight
      || this.hiddenTabs.length === 1
      && this.isEnoughSpaceToShowLastTab(tabHeight);
  }

  private removeHiddenTabFromArray(tabIndex: number): void {
    const tabName: string = this.getTabRouterName(tabIndex);
    const indexOfTabNameToShow: number = this.hiddenTabs.findIndex(tab => tab.name === tabName);
    if (indexOfTabNameToShow !== -1) {
      this.hiddenTabs.splice(indexOfTabNameToShow, 1);
    }
  }

  private isEnoughSpaceToShowLastTab(tabHeight: number): boolean {
    const newSum: number = this.shownTabsHeightSum - this.moreButtonHeight + tabHeight;
    return this.subMenuContainerHeight >= newSum;
  }
}
