<div *ngIf="!getOrganisationInProgress">

  <div class="organisation-view-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span class="title">{{'modules.organisation.organisationViewAddEdit.dataSheet'|translate}}</span>
      <span class="name">{{organisation.organisationName}}</span>
    </div>

    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1" data-osp-test="button-close">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>

  </div>

  <mat-tab-group>

    <mat-tab [label]="'modules.organisation.organisationViewAddEdit.organisationData'|translate">

      <div class="technical-field-list" fxLayout="row"
           fxLayoutAlign="space-between stretch">

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.organisationName'|translate}}</label>
          <p data-osp-test="input-organisation-name">{{organisation.organisationName}}</p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.organisationNumber'|translate}}</label>
          <p data-osp-test="input-organisation-number">{{organisation.organisationNumber}}</p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.organisationType'|translate}}</label>
          <p data-osp-test="input-organisation-type">{{organisation.organisationType | organisationTypeDisplay}}</p>
        </div>

        <div *ngIf="organisation.parentOrganisationId">
          <label>{{'modules.organisation.organisationViewAddEdit.parentOrganisation'|translate}}</label>
          <p data-osp-test="input-parent-organisation"><span *ngIf="parentOrganisation">
            {{parentOrganisation.organisationName}}</span></p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.customerName'|translate}}</label>
          <p data-osp-test="input-customer-name">{{organisation.customerName}}</p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.customerNumber'|translate}}</label>
          <p data-osp-test="input-customer-number">{{organisation.customerNumber}}</p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.contactName'|translate}}</label>
          <p data-osp-test="input-contact-name"><span *ngIf="organisation.organisationContact">
            {{organisation.organisationContact.name}}</span></p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.phoneNumber'|translate}}</label>
          <p data-osp-test="input-phone-number"><span *ngIf="organisation.organisationContact">
            {{organisation.organisationContact.phoneNumber}}</span></p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.mobileNumber'|translate}}</label>
          <p data-osp-test="input-mobile-number"><span *ngIf="organisation.organisationContact">
            {{organisation.organisationContact.mobileNumber}}</span></p>
        </div>

        <div>
          <label>{{'modules.organisation.organisationViewAddEdit.faxNumber'|translate}}</label>
          <p data-osp-test="input-fax-number"><span *ngIf="organisation.organisationContact">
            {{organisation.organisationContact.faxNumber}}</span></p>
        </div>

        <div>
          <label>{{'general.email'|translate}}</label>
          <p data-osp-test="input-email"><span *ngIf="organisation.organisationContact">
            {{organisation.organisationContact.email}}</span></p>
        </div>

        <div>
          <label>{{'general.address.street'|translate}}</label>
          <p data-osp-test="input-street"><span *ngIf="organisation.organisationAddress">
            {{organisation.organisationAddress.street}}</span></p>
        </div>

        <div>
          <label>{{'general.address.houseNumber'|translate}}</label>
          <p data-osp-test="input-house-number"><span *ngIf="organisation.organisationAddress">
            {{organisation.organisationAddress.streetNumber}}</span></p>
        </div>

        <div>
          <label>{{'general.address.postCode'|translate}}</label>
          <p data-osp-test="input-post-code"><span *ngIf="organisation.organisationAddress">
            {{organisation.organisationAddress.postalCode}}</span></p>
        </div>

        <div>
          <label>{{'general.address.city'|translate}}</label>
          <p data-osp-test="input-city"><span *ngIf="organisation.organisationAddress">
            {{organisation.organisationAddress.city}}</span></p>
        </div>

        <div>
          <label>{{'general.address.country'|translate}}</label>
          <p data-osp-test="input-country"><span *ngIf="organisation.organisationAddress">
            {{getCountryName(organisation.organisationAddress.country)}}</span></p>
        </div>

      </div>

    </mat-tab>
  </mat-tab-group>
</div>

<div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="getOrganisationInProgress">
  <mat-spinner [strokeWidth]="5" *ngIf="getOrganisationInProgress"></mat-spinner>

</div>
