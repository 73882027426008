import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DispositionDataSource } from '../../../../disposition/shared/disposition.datasource';
import { BaseEquipmentAssignToProjectComponent } from '../base/base-equipment-assign-to-project-component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDataSource } from '../../../../disposition/shared/project.datasource';
import { OnlineStatusService } from '../../../../../shared/services/online-status.service';
import { EquipmentAssignToProjectUtils } from '../base/equipment-assign-to-project-utils';
import { DispositionProject } from '../../../../disposition/shared/disposition-project';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EquipmentsService } from '../../../shared/equipments.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { FormatDispositionProjectDateRangePipe } from 'app/shared/pipes/format-date/format-disposition-project-date-range.pipe';
import { LanguageService } from 'app/shared/services/language.service';
import { UsersService } from '../../../../userrole/shared/users.service';
import { EquipmentDispositionMessageResolver } from 'app/shared/components/disposition-assign-confirmation-message/disposition-message-resolver/equipment-disposition-message-resolver.class';
import { EquipmentCheckerService } from 'app/modules/equipment/shared/services/equipment-checker.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-assign-to-project-dialog',
  templateUrl: './equipment-assign-to-project-dialog.component.html',
  styleUrls: ['./equipment-assign-to-project-dialog.component.scss']
})
export class EquipmentAssignToProjectDialogComponent extends BaseEquipmentAssignToProjectComponent implements OnInit, OnDestroy {

  @ViewChild('assignToProjectStepper', { static: true }) stepper: MatStepper;

  public formatAddress = EquipmentAssignToProjectUtils.formatAddress;
  public formatCostCenter = EquipmentAssignToProjectUtils.formatCostCenter;
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public messageResolver: EquipmentDispositionMessageResolver;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentStore: EquipmentsDataSource,
              protected dispositionStore: DispositionDataSource,
              protected projectStore: ProjectDataSource,
              protected formBuilder: UntypedFormBuilder,
              protected onlineStatusService: OnlineStatusService,
              protected dialog: MatDialog,
              protected equipmentsService: EquipmentsService,
              protected usersService: UsersService,
              protected routerHistory: RouterHistory,
              private formatDispositionProjectDateRangePipe: FormatDispositionProjectDateRangePipe,
              protected languageService: LanguageService,
              protected equipmentCheckerService: EquipmentCheckerService) {
    super(authService,
      router,
      route,
      equipmentStore,
      dispositionStore,
      projectStore,
      formBuilder,
      onlineStatusService,
      dialog,
      equipmentsService,
      usersService,
      routerHistory,
      languageService,
      equipmentCheckerService)
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadProjects();
    this.filterControl.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.filterProjects();
    });
    this.messageResolver = new EquipmentDispositionMessageResolver(this.languageService);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public get saveWarning(): string {
    if (this.stepper.selectedIndex > 0 && !this.project) {
      return this.translate('modules.equipment.assignProject.noProjectSelected');
    }
    if (this.hasConflicts()) {
      return this.translate('general.labels.conflictsFound');
    }
    return '';
  }

  public hasConflicts(): boolean {
    return this.equipmentDispositions
    .filter(disposition => disposition.hasError('conflicts'))
      .length > 0;
  }

  public close(): void {
    this.dialogRef.close();
  }

  private filterProjects(): void {
    if (!this.projects) {
      return;
    }
    let search = this.filterControl.value;
    if (!search) {
      this.filteredProjects.next(this.projects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredProjects.next(
      this.projects.filter(project => this.searchMatchesProject(search, project))
    );
  }

  private searchMatchesProject(search: string, project: DispositionProject): boolean {
    const params = search.split(' ');
    for (let param of params) {
      if (param.length > 0 && (project.projectName.toLowerCase().indexOf(param) === -1
        && this.formatAddress(project.projectAddress).toLowerCase().indexOf(param) === -1
        && this.formatDispositionProjectDateRangePipe.transform(project).toLowerCase().indexOf(param) === -1
        && this.formatCostCenter(project.projectCostCenter).toLowerCase().indexOf(param) === -1)) {
        return false;
      }
    }
    return true;
  }

}
