import { TimezoneFormatLocaleDate } from './timezone-format-locale-date.interface';

export class TimezoneUtils {

  public static readonly timezoneMappings: { [key: string]: string } = {
    'ACT': 'Australia/Darwin',
    'AET': 'Australia/Sydney',
    'AGT': 'America/Argentina/Buenos_Aires',
    'ART': 'Africa/Cairo',
    'AST': 'America/Anchorage',
    'BET': 'America/Sao_Paulo',
    'BST': 'Asia/Dhaka',
    'CAT': 'Africa/Harare',
    'CET': 'Europe/Paris',
    'CNT': 'America/St_Johns',
    'CST': 'America/Chicago',
    'CST6CDT': 'America/Chicago',
    'CTT': 'Asia/Shanghai',
    'Cuba': 'America/Havana',
    'EAT': 'Africa/Nairobi',
    'ECT': 'Europe/Paris',
    'EET': 'Europe/Athens',
    'EST': 'America/New_York',
    'EST5EDT': 'America/New_York',
    'Egypt': 'Africa/Cairo',
    'Eire': 'Europe/Dublin',
    'GB': 'Europe/London',
    'GB-Eire': 'Europe/London',
    'VST': 'Asia/Ho_Chi_Minh',
    'W-SU': 'Europe/Moscow',
    'WET': 'Europe/Lisbon',
  };

  public static readonly defaultConfig: TimezoneFormatLocaleDate = {
    dateFormat: 'DD.MM.YYYY',
    showTime: false,
    hideEndOfDayTime: false
  }
}
