<div class="wrapper">
  <div class="header a-c">
    <div class="fs-24 c-g-d0">{{'general.filterTimerange.filterName'|translate | uppercase}}</div>

    <div *ngIf="showDateTypeControl" class="a-c">
      <mat-button-toggle-group [formControl]="timeRangeDateTypeControl">
        <mat-button-toggle [value]="TIME_RANGE_DATE_TYPES.CREATION_DATE">
          {{'general.filterTimerange.byCreationDate'|translate}}
        </mat-button-toggle>
        <mat-button-toggle [value]="TIME_RANGE_DATE_TYPES.TRANSFER_DATE">
          {{'general.filterTimerange.byTransferDate'|translate}}
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-icon [matTooltip]="'general.filterTimerange.dateTypeInfoTooltip'|translate">info</mat-icon>
    </div>
  </div>

  <div class="content">
    <mat-form-field appearance="fill">
      <mat-label>{{'general.filterTimerange.timeRange'|translate}}</mat-label>
      <mat-date-range-input [formGroup]="timeRangeForm" [rangePicker]="picker">
        <input  #fromDateInputRef
                matStartDate
                formControlName="fromDate"
                bhInputAllowedCharacters="[0-9./]"
                placeholder="{{'general.startDate'|translate}}">
        <input  #toDateInputRef
                matEndDate
                formControlName="toDate"
                bhInputAllowedCharacters="[0-9./]"
                placeholder="{{'general.endDate'|translate}}">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="timeRangeForm.controls.fromDate.hasError('required') &&
                        !timeRangeForm.controls.fromDate.hasError('isValidDateInstance')">
        {{'shared.validation.required'|translate:{ value: 'general.startDate'|translate } }}
      </mat-error>
      <mat-error *ngIf="timeRangeForm.controls.toDate.hasError('required') &&
                        !timeRangeForm.controls.toDate.hasError('isValidDateInstance')">
        {{'shared.validation.required'|translate:{ value: 'general.endDate'|translate } }}
      </mat-error>

      <mat-error *ngIf="timeRangeForm.controls.fromDate.hasError('matStartDateInvalid') ||
                        timeRangeForm.controls.toDate.hasError('matEndDateInvalid')">
        {{'shared.validation.date.incorrectTimeRange'|translate}}
      </mat-error>
      <mat-error *ngIf="timeRangeForm.controls.fromDate.hasError('isValidDateInstance') ||
                        timeRangeForm.controls.toDate.hasError('isValidDateInstance')">
        {{'shared.validation.date.invalidDateFormat'|translate}}.
        {{'shared.validation.date.useFormat'|translate:{ value: 'DD.MM.YYYY - DD.MM-YYYY' } }}
      </mat-error>
    </mat-form-field>

    <mat-button-toggle-group [formControl]="quickFilterControl">
      <mat-button-toggle [value]="QUICK_FILTERS.CURRENT_MONTH">{{'general.filterTimerange.currentMonth'|translate}}</mat-button-toggle>
      <mat-button-toggle [value]="QUICK_FILTERS.PREVIOUS_MONTH">{{'general.filterTimerange.previousMonth'|translate}}</mat-button-toggle>
      <mat-button-toggle [value]="QUICK_FILTERS.LAST_THIRTY_DAYS">{{'general.filterTimerange.lastThirtyDays'|translate}}</mat-button-toggle>
      <mat-button-toggle [value]="QUICK_FILTERS.LAST_NINETY_DAYS">{{'general.filterTimerange.lastNinetyDays'|translate}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
