<div class="file-upload-wrapper">
  <div class="m-view-attachment">
    <bh-mobile-file-upload
        [deferredEntryNumber]="entryNumber"
        (onFileUploaded)="onFileUploaded($event)"
        (onAllUploaded)="onAllUploaded()"
        (onDeferredUploadQueued)="onDeferredUploadQueued($event)"
        uploaderType="MOBILE_EQUIPMENT_ADD">
    </bh-mobile-file-upload>
  </div>
  <bh-title [text]="'modules.disposition.bulkItemAddEdit.chooseFiles'|translate"></bh-title>
  <div class="file-list">
    <bh-mobile-swipe-to-options *ngFor="let file of documents"
                                (swipeAction)="deleteFile(file)">
      <div class="file-info">
        <div class="file-preview"
             [style.background-image]="getSafeStyle(file.url)"></div>
        <div class="file-name">
          {{ file.fileItem.file.name }}
        </div>
      </div>
      <bh-swipe-option (onClick)="deleteFile(file)" appendClass="red">
        <mat-icon>delete</mat-icon>
      </bh-swipe-option>
    </bh-mobile-swipe-to-options>
  </div>
</div>
