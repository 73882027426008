import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PartnersService } from '../../shared/partners.service'
import { UpdatePartnerCommand } from '../../contract/update-partner-command';
import { ViewPartner } from '../../contract/view-partner.interface';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { PartnerBaseComponent } from '../common/partner-base.component';
import { LanguageService } from '../../../../shared/services/language.service';
import { PartnerMailServer } from '../../contract/partner-mail-server.enum';
import { RealmType } from 'app/shared/contract/realm-type';

@Component({
  selector: 'bh-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.scss']
})
export class PartnerEditComponent extends PartnerBaseComponent implements OnInit {

  public partnerEditForm: UntypedFormGroup;

  private partner: ViewPartner;
  public partnerId: string;

  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              private partnerService: PartnersService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(router, route, authService, routerHistory, languageService);
  }

  ngOnInit() {
    this.buildForm();
    this.getPartner();
  }

  public navigateBack(): void {
    this.router.navigate(['/partners/list']);
  }

  buildForm() {
    this.partnerEditForm = this.formBuilder.group({
      partnerId: ['', [<any>Validators.required]],
      partnerName: ['', [<any>Validators.required]],
      partnerTheme: ['', this.isAllowedToManageWhitelabel() ? [Validators.required] : []],
      partnerMailServer: [PartnerMailServer.DEFAULT_OSP, this.isAllowedToManageWhitelabel() ? [Validators.required] : []],
      partnerRealm: [RealmType.DEFAULT, []],
      partnerAddress: this.formBuilder.group({
        street: '',
        streetNumber: '',
        postalCode: '',
        city: ''
      }),
    });
  }

  get partnerName() {
    return this.partnerEditForm.get('partnerName');
  }

  save() {
    if (this.isValid()) {
      let formValue = this.partnerEditForm.getRawValue();
      let cmd = new UpdatePartnerCommand();
      cmd.partnerId = formValue.partnerId;
      cmd.partnerName = formValue.partnerName;
      cmd.partnerTheme = formValue.partnerTheme;
      cmd.partnerMailServer = formValue.partnerMailServer;
      cmd.partnerAddress = formValue.partnerAddress;
      this.partnerService.updatePartner(cmd).subscribe(
        () => {
            this.navigateAfterSubmit();
          },
        (error: HttpErrorResponse) => {
            console.log('edit partner error: ', error);
          }
      );
    }
  }

  public isValid(): boolean {
    return this.partnerEditForm.valid;
  }

  getPartner() {
    this.route.params.subscribe(params => {
      /* tslint:disable:no-string-literal */
      const id = params['id'];
      this.partnerId = id;
      /* tslint:enable:no-string-literal */
      this.partnerService.getPartner(id).subscribe(
          data => {
            this.partner = data;
            this.setFormValues();
          },
          error => {
            console.log(error);
          }
      );
    });
  }

  private setFormValues() {
    this.partnerEditForm.patchValue({
      partnerId: this.partner.partnerId,
      partnerName: this.partner.partnerName,
      partnerTheme: this.partner.partnerTheme,
      partnerMailServer: this.partner.partnerMailServer,
      partnerRealm: this.partner.partnerRealm,
      partnerAddress: {
        street: this.partner.partnerAddress.street,
        streetNumber: this.partner.partnerAddress.streetNumber,
        postalCode: this.partner.partnerAddress.postalCode,
        city: this.partner.partnerAddress.city
      }
    });
  }

  isAllowedToManageWhitelabel(): boolean {
    return this.isInRole(PrivilegedRole.Superadmin)
  }
}
