import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';

@Component({
  selector: 'bh-date-local',
  templateUrl: './date-local.component.html',
  styleUrls: ['./date-local.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateLocalComponent {
  @Input() date: Date;
  public readonly currentLocale = this.languageService.getCurrentLocale();

  constructor(private languageService: LanguageService) { }
}
