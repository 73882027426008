import { environment } from 'environments/environment';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ITypeOfUseChangeRequest } from '../type-of-use-change-request.interface';
import { MatExpansionPanel } from '@angular/material/expansion';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck, faExternalLink, faTimes, faTrash, faWallet } from '@fortawesome/pro-light-svg-icons';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { LanguageService } from '../../../../../shared/services/language.service';
import { ChangeTypeOfUseIconsService } from '../../../../disposition/shared/change-type-of-use-icons.service';
import { TypeOfUseChangeRequestsWidgetStoreService } from '../type-of-use-change-requests-widget-store.service';
import { TransferItem } from '../../../../disposition/shared/transfer/model/transfer-item';
import { RequestStatus } from '../../../../disposition/shared/enums/request-status.enum';
import { ChangeTypeOfUseRequestsDialogComponent } from '../../../../disposition/shared/change-type-of-use-requests-dialog/change-type-of-use-requests-dialog.component';
import { AcceptTypeOfUseRequest } from '../../../../disposition/contract/accept-type-of-use-request';
import { MatDialog } from '@angular/material/dialog';
import { ProjectTypeOfUseRequestCommand } from '../../../../disposition/contract/project-type-of-use-request-command';
import { TypeOfUseChangeRequestsWidgetApiService } from '../type-of-use-change-requests-widget-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from '../../../../../core/keycloak';

@UntilDestroy()
@Component({
  selector: 'bh-type-of-use-change-request',
  templateUrl: './type-of-use-change-request.component.html',
  styleUrls: ['./type-of-use-change-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeOfUseChangeRequestComponent implements OnInit {
  @ViewChild('panel', {static: true}) panel: MatExpansionPanel;

  @Input() request: ITypeOfUseChangeRequest;
  @Input() showAcceptAndDeclineBtns: boolean;
  @Input() showDeleteBtn: boolean;

  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;
  public readonly faTrash: IconDefinition = faTrash;
  public readonly faSync: IconDefinition = faSync;
  public readonly faExternalLink: IconDefinition = faExternalLink;
  public readonly faWallet: IconDefinition = faWallet;
  public deleteBtnIsShow: boolean;

  constructor(public iconService: ChangeTypeOfUseIconsService,
              public typeOfUseChangeRequestStore: TypeOfUseChangeRequestsWidgetStoreService,
              private changeTypeOfUseService: TypeOfUseChangeRequestsWidgetApiService,
              protected dialog: MatDialog,
              private snackBar: MatSnackBar,
              private auth: KeycloakService,
              private languageService: LanguageService,
  ) {
  }

  ngOnInit() {
    this.deleteBtnIsShow = this.request.submitterId === this.auth.getUserUserId();
  }

  acknowledgeRequest(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    let requests: AcceptTypeOfUseRequest[] = [];

    const dialogRef = this.dialog.open(ChangeTypeOfUseRequestsDialogComponent);
    requests.push(new AcceptTypeOfUseRequest((new ProjectTypeOfUseRequestCommand(
      this.request.amountId,
      this.request.projectId,
      this.request.amountStatusPeriodId,
      )),
      RequestStatus.PENDING,
      TransferItem.fromITypeOfUseChangeRequest(this.request),
    ));

    dialogRef.componentInstance.requestsAccept = requests;
    dialogRef.afterClosed().subscribe(() => this.typeOfUseChangeRequestStore.scroll(true));
  }

  declineRequest(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.changeTypeOfUseService
      .declineTypeOfUseChangeRequest(
        new ProjectTypeOfUseRequestCommand(this.request.amountId,
          this.request.projectId,
          this.request.amountStatusPeriodId)
      )
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        setTimeout(() => {
          this.snackBar.open(
            this.translate('modules.disposition.projectTypeOfUsePeriods.typeOfUseRequestDeclined'),
            undefined,
            {duration: environment.DELAY_LONG});
          this.typeOfUseChangeRequestStore.scroll(true)
        }, environment.DELAY_SHORT), () =>
        this.snackBar.open(
          this.translate('modules.disposition.projectTypeOfUsePeriods.typeOfUseRequestDeclineFailed'),
          undefined,
          {duration: environment.DELAY_LONG}))
  }

  removeRequest(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.changeTypeOfUseService
      .deleteTypeOfUseChangeRequest(
        new ProjectTypeOfUseRequestCommand(
          this.request.amountId,
          this.request.projectId,
          this.request.amountStatusPeriodId
        ))
      .pipe(untilDestroyed(this))
      .subscribe(
        {
          next: () => {
            setTimeout(() => {
              this.snackBar.open(
                this.translate('modules.disposition.projectTypeOfUsePeriods.typeOfUseRequestDeleted'),
                undefined,
                {duration: environment.DELAY_LONG});
              this.typeOfUseChangeRequestStore.scroll(true);
            }, environment.DELAY_SHORT)
          },
          error: () => {
            this.snackBar.open(
              this.translate('modules.disposition.projectTypeOfUsePeriods.typeOfUseRequestDeleteFailed'),
              undefined,
              {duration: environment.DELAY_LONG});
            this.typeOfUseChangeRequestStore.scroll(true);
          },
        });
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
