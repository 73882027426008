import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bh-equipment-type-delete-dialog',
  templateUrl: 'equipment-type-delete-dialog.component.html',
  styleUrls: ['equipment-type-delete-dialog.component.scss']
})
export class EquipmentTypeDeleteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EquipmentTypeDeleteDialogComponent>) { }

  ngOnInit() {
  }

}
