<div class="container" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header" fxLayoutGap="10px">
    <bh-title [text]="'modules.disposition.projectTypeOfUsePeriods.title' | translate" fxFlex
              class="title-header">
    </bh-title>
    <button mat-raised-button color="primary"
      *ngIf="allowChangeTypeOfUse()"
      (click)="navigateToChangeChargePeriod()">
      <span>
        {{'modules.disposition.projectTypeOfUsePeriods.changeTypeOfUse' | translate}}
      </span>
    </button>
  </div>

  <div #tableContainer class="list-view-main__tabs" fxFlex>
    <div class="transfer-history-wrapper" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-table *ngIf="displayColumnsMap && (periods| async)"
                 fxFlex matSort
                 class="left-body-list"
                 [dataSource]="periods | async"
                 (matSortChange)="sortData($event)"

                 infiniteScroll (scrolled)="onScroll()"
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="3"
                 [infiniteScrollThrottle]="150"
                 [infiniteScrollContainer]="tableContainer"
      >
        <mat-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <ng-container *ngFor="let key of displayedColumns">
          <ng-container [matColumnDef]="key">
            <ng-container>
              <mat-header-cell
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [disabled]="!displayColumnsMap.get(key).sortable"
                [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
              >
                {{displayColumnsMap.get(key).label}}
              </mat-header-cell>
            </ng-container>
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="key===ChangeTypeOfUseKey.TransferItemType&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  <fa-icon *ngIf="row[key] === TransferItemType.EQUIPMENT"
                           class="type-icon"
                           [icon]="faTruckPlow"
                           [matTooltipPosition]="'right'"
                           [matTooltip]="'general.equipm.pl'|translate"></fa-icon>
                  <fa-icon
                    *ngIf="row[key] === TransferItemType.BULKITEM"
                    class="type-icon"
                    [icon]="faTrafficCone"
                    [matTooltipPosition]="'right'"
                    [matTooltip]="'general.bulkItems'|translate"></fa-icon>
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchCase="key===ChangeTypeOfUseKey.Edit&&displayColumnsMap.get(key).show">
                <mat-cell mat-cell *matCellDef="let row"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  <div fxFlex="0 1 40px"
                       class="more"
                  >
                    <button mat-icon-button [matMenuTriggerFor]="periodMenu">
                      <mat-icon class="color-light-gray">more_vert</mat-icon>
                    </button>
                  </div>
                  <mat-menu #periodMenu="matMenu">
                    <div>
                      <button mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>{{'modules.disposition.projectTypeOfUsePeriods.editEntry' | translate}}</span>
                      </button>
                    </div>

                    <div>
                      <button mat-menu-item>
                        <mat-icon>delete_forever</mat-icon>
                        <span>{{'modules.disposition.projectTypeOfUsePeriods.deleteEntry' | translate}}</span>
                      </button>
                    </div>
                  </mat-menu>
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchCase="key===ChangeTypeOfUseKey.TransferItemInternalNumber&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                          [matTooltip]="row[key]"

                >
                  {{row[key] | customSlice:0:12 : '...'}}
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchCase="key===ChangeTypeOfUseKey.TransferItemName&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  <div (click)="navigateToEquipment(row[ChangeTypeOfUseKey.TransferItemId], row[ChangeTypeOfUseKey.TransferItemType])" class="link">
                    {{row[key]}}
                  </div>
                  <mat-icon
                    [matTooltip]="'general.labels.jumpTo'|translate:
                    { 'value': row[ChangeTypeOfUseKey.TransferItemType] === TransferItemType.BULKITEM ? ('general.bulkItems'|translate) : ('general.equipm.s'|translate) }"
                    (click)="navigateToEquipment(row[ChangeTypeOfUseKey.TransferItemId], row[ChangeTypeOfUseKey.TransferItemType])"
                    class="link_icon"
                  >
                    open_in_new
                  </mat-icon>
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchCase="key === ChangeTypeOfUseKey.Status&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                          [matTooltip]="row.comment"
                >
                    <span *ngIf="row[key]; else emptyTemplate"
                          class="status {{resolveStatusClass(row[key])}}"
                    >
                      <fa-icon class="mr-5 custom-icon" [icon]="resolveStatusIcon(row[key])"></fa-icon>
                      {{row[key] | typeOfUseStatus}}
                    </span>
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchCase="key === ChangeTypeOfUseKey.Reason&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  <ng-container *ngIf="row[key]; else emptyTemplate">
                    <fa-icon class="mr-5 custom-icon" [icon]="resolveReasonIcon(row[key])"></fa-icon>
                    {{row[key] | typeOfUseStatusReason}}
                  </ng-container>
                </mat-cell>
              </ng-container>
              <ng-container
                *ngSwitchCase="(key===ChangeTypeOfUseKey.StatusEndDate || key===ChangeTypeOfUseKey.StatusStartDate)&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  <ng-container *ngIf="row[key]; else emptyTemplate">
                    {{row[key] | date:'dd.MM.yyyy HH:mm':'':getCurrentLocale()}}
                  </ng-container>
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchCase="key === ChangeTypeOfUseKey.Separator&&displayColumnsMap.get(key).show">
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  |
                </mat-cell>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <mat-cell *matCellDef="let row"
                          mat-cell
                          class="color-gray"
                          [style.flex]="'0 0 '+displayColumnsMap.get(key).width"
                >
                  <ng-container *ngIf="row[key]; else emptyTemplate">
                    {{row[key]}}
                  </ng-container>
                </mat-cell>
              </ng-container>
              <ng-template #emptyTemplate>
                <ng-container *ngIf="displayColumnsMap.get(key).show">
                  -
                </ng-container>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-table>
    </div>
  </div>
</div>
