import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryPreviewComponent } from './components/gallery/components/gallery-preview/gallery-preview.component';
import { GalleryImageComponent } from './components/gallery/components/gallery-image/gallery-image.component';
import { GalleryThumbnailsComponent } from './components/gallery-thumbnails/gallery-thumbnails.component';
import { GalleryActionComponent } from './components/gallery/components/gallery-action/gallery-action.component';
import { GalleryArrowsComponent } from './components/gallery/components/gallery-arrows/gallery-arrows.component';
import { GalleryBulletsComponent } from './components/gallery/components/gallery-bullets/gallery-bullets.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageComponent } from './components/image/image.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { FileDownloadService } from './services/file-download.service';
import { CurrentGalleryImagesPipe } from './components/gallery/components/gallery-image/current-gallery-images.pipe';
import { CurrentThumbnailsPipe } from './components/gallery-thumbnails/current-thumbnails.pipe';
import { GalleryDetailViewComponent } from './components/gallery-detail-view/gallery-detail-view.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import { ImageKeyFormatPipe } from './pipes/image-key-format.pipe';

@NgModule({
  declarations: [
    GalleryComponent,
    GalleryPreviewComponent,
    GalleryImageComponent,
    GalleryThumbnailsComponent,
    GalleryActionComponent,
    GalleryArrowsComponent,
    GalleryBulletsComponent,
    ImageComponent,
    ImageViewComponent,
    CurrentGalleryImagesPipe,
    CurrentThumbnailsPipe,
    GalleryDetailViewComponent,
    ThumbnailComponent,
    ImageKeyFormatPipe,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    TranslateModule,
  ],
  exports: [
    GalleryComponent,
    ImageComponent,
    ImageViewComponent,
    GalleryDetailViewComponent,
    ThumbnailComponent,
  ],
  providers: [FileDownloadService]
})
export class SecuredImageModule { }
