<div class="dialog-wrapper" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title>{{'general.labels.importTeltonikaTelematicUnits'|translate}}</h1>

  <mat-dialog-content fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
    <form [formGroup]="form" novalidate>
      <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
        <div fxFlex="80" fxLayout="row" fxLayoutAlign="space-around stretch">
          <div fxFlex="45" fxLayout="column">
            <mat-form-field>
              <mat-label>{{'modules.telematic.telematicList.telematicType'|translate}}</mat-label>
              <mat-select formControlName="typeId" [required]="true">
                <mat-option>
                  <ngx-mat-select-search [formControl]="unitTypeFilter"
                                         [placeholderLabel]="'general.labels.search'|translate:{ value: ('modules.telematic.telematicList.telematicType'|translate) }"
                                         [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('modules.telematic.telematicList.telematicType'|translate) }"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let telematicUnit of filteredtTelematicsUnitTypes | async" [value]="telematicUnit">
                  {{resolveName(telematicUnit)}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="typeId.hasError('required')">
                {{'shared.validation.requiredField'|translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'modules.telematic.telematicList.simCardProvider'|translate}}</mat-label>
              <input matInput
                     formControlName="simCardProvider"
                     [matAutocomplete]="simCardProviderAuto">
              <mat-autocomplete #simCardProviderAuto="matAutocomplete">
                <mat-option *ngFor="let sim of simCardProvidersList | async" [value]="sim">{{ sim }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div fxFlex="45" fxLayout="column">
            <mat-form-field>
              <mat-label>{{'modules.telematic.assignPartner'|translate}}</mat-label>
              <mat-select
                formControlName="partnerId">
                <mat-option>
                  <ngx-mat-select-search [formControl]="partnersFilter"
                                         [placeholderLabel]="'general.labels.search'|translate:{ value: ('modules.telematic.telematicAddEdit.partner'|translate) }"
                                         [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('modules.telematic.telematicAddEdit.partner'|translate) }"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let partner of partners | async"
                            [value]="partner.partnerId"
                            [matTooltip]="partner.partnerName"
                            matTooltipPosition="above">
                  {{partner.partnerName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <input
              type="file"
              accept=".csv"
              [disabled]="!typeId.valid"
              (change)="onFileUpload($event)"
            />
            <span *ngIf="!uploadSuccessfull && errorMessage" class="error" [innerHTML]="errorMessage"></span>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="{{abort}}">
      {{'general.buttons.cancel'|translate}}
    </button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            [disabled]="!isValid()"
            (throttledClick)="save()">
      {{'general.buttons.next'|translate}}
    </button>
  </mat-dialog-actions>
</div>
