export const GENERAL_COLUMN_DEF = {
  OPEN_DETAILS: 'openDetails',
  SELECT: 'select',
  NAME: 'name',
  EMAIL: 'email',
  ADDRESS: 'address',
  ORGANISATION: 'organisationName',
  EQUIPMENT_COST_CENTER: 'equipmentCostCenter',
  EQUIPMENT_CONSTRUCTION_YEAR: 'equipmentConstructionYear',
  EQUIPMENT_TYPE_CATEGORY1: 'equipmentType.category1',
  EQUIPMENT_TYPE_CATEGORY2: 'equipmentType.category2',
  CURRENT_LOCATION_NAME: 'currentLocationName',
  CURRENT_LOCATION_NUMBER: 'currentLocationNumber',
  CURRENT_LOCATION_COST_CENTER: 'currentLocationCostCenter',
  IMAGE: 'image',
  ICON: 'icon',
  DETAILS: 'details',
  INFO: 'info',
  EQUIPMENT_INFO: 'equipmentInfo',
  EQUIPMENT_FAVOURITE: 'equipmentFavourite',
  STATUS: 'status',
  EQUIPMENT_SERIAL_NUMBER: 'equipmentSerialNumber',
  EQUIPMENT_CUSTOMER_SERIAL_NUMBER: 'equipmentCustomerSerialNumber',
  ASSIGNED_OWNER: 'assignedOwner',
  ASSIGNED_MAINTENANCE_ASSIGNEE: 'assignedMaintenanceAssignee',
  ASSIGNED_PERSON_IN_CHARGE: 'assignedPersonInCharge',
  ASSIGNED_DRIVER: 'assignedDriver',
  ASSIGNED_POSSESSOR: 'assignedPossessor',
  EQUIPMENT_MODEL: 'equipmentModel',
  EQUIPMENT_NAME: 'equipmentName',
  EQUIPMENT_LICENSE_PLATE: 'equipmentLicensePlate',
  PROJECT_NAME: 'projectName',
  STOCK_NAME: 'stockName',
  STOCK_COST_CENTER: 'stockCostCenter',

  EQUIPMENT_NONE: 'equipmentNone',
  ORGANISATION_NONE: 'organisationNone',
  STOCK_NONE: 'stockNone',
  CONTACT_NONE: 'contactNone',
}

export const TRANSPORT_COLUMN_DEF = {
  IDENTIFIER: 'identifier',
  DUE_DATE: 'dueDate',
  STATE: 'state',
  TITLE: 'title',
  DESCRIPTION: 'text',
  PRIORITY: 'priority',
  START_ITEM: 'startItem',
  TARGET_ITEM: 'targetItem',
  EQUIPMENT_DISPLAY_NAME: 'equipmentDisplayName',
  ESTIMATED_DURATION: 'estimatedDuration',
  PROJECT_INFO: 'projectInfo',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  EXECUTION_START_DATE: 'executionStartDate',
  EXECUTION_END_DATE: 'executionEndDate',
  TRANSPORT_TYPE: 'transportType',
}

export const CONTACT_COLUMN_DEF = {
  IDENTIFIER: 'identifier',
  TYPE: 'type',
  PHONE_NUMBER: 'phoneNumber',
  CONTACT_PERSON: 'contactPerson',
  CONTACT_NAME_LOCATION: 'contactName',
  CONTACT_INTERNAL_NUMBER_LOCATION: 'contactInternalNumber',
}

export const MAINTENANCE_TASK_COLUMN_DEF = {
  MAINTENANCE_NAME: 'maintenanceName',
  MAINTENANCE_CATEGORY: 'maintenanceCategory',
  CURRENT_LOCATION_NAME: 'currentLocationName',
  CURRENT_LOCATION_NUMBER: 'currentLocationNumber',
  CURRENT_LOCATION_COST_CENTER: 'currentLocationCostCenter',
  TELEMATIC: 'telematic',
  LIVE_ADDRESS: 'liveAddress',
  EQUIPMENT_MANUFACTURER_NAME: 'equipmentManufacturerName',
  DUE_DATE: 'dueDateInfo.dueDate',
  REMAINING_VALUE: 'dueDateInfo.remainingValue',
  TASK_ACTION: 'taskAction',
  CURRENT_ADDRESS: 'currentAddress',
  MAINTENANCE_TYPE: 'maintenanceTypeName',
  COMPLETED_BY: 'completedBy',
  COMPLETED_AT_MILEAGE: 'completedAtMileage',
  COMPLETED_AT_OPERATING_HOURS: 'completedAtOperatingHours',
  COMPLETED_AT_DATE: 'completedAtDate',
  EQUIPMENT_IMAGE: 'thumbnailEquipmentImage',
  MAINTENANCE_RESULT: 'maintenanceResult',
}

export const ANALYTICS_COLUMN_DEF = {
  ACCEPTANCE_DATE: 'acceptanceDate',
  ACCEPTANCE_USER: 'acceptanceUser',
  AMOUNT: 'amount',
  CHANGE_DATE: 'changeDate',
  CHARGE_TYPE: 'chargeType',
  CREATION_DATE: 'creationDate',
  CREATOR_USER: 'creatorUser',
  DELIVERY_NOTE_NUMBER: 'deliveryNoteNumber',
  EQUIPMENT_BDL_CODE: 'equipmentBGLCode',
  EQUIPMENT_CHANGE_DATE: 'equipmentChangeDate',
  EQUIPMENT_CHARGE_TYPE: 'equipmentChargeType',
  EQUIPMENT_COST_CENTER: 'equipmentCostCenter',
  EQUIPMENT_CREATION_DATE: 'equipmentCreationDate',
  EQUIPMENT_DAYS: 'equipmentDays',
  EQUIPMENT_DRIVER: 'equipmentDriver',
  EQUIPMENT_END_DATE: 'equipmentEndDate',
  EQUIPMENT_GUID_ID: 'equipmentGuidId',
  EQUIPMENT_INTERNAL_NUMBER: 'equipmentInternalNumber',
  EQUIPMENT_LABELS: 'equipmentLabels',
  EQUIPMENT_LICENSE_PLATE: 'equipmentLicensePlate',
  EQUIPMENT_LOCATION_ADDRESS: 'equipmentLocationAddress',
  EQUIPMENT_LOCATION_COST_CENTER: 'equipmentLocationCostCenter',
  EQUIPMENT_LOCATION_ID: 'equipmentLocationId',
  EQUIPMENT_LOCATION_NAME: 'equipmentLocationName',
  EQUIPMENT_LOCATION_INTERNAL_NUMBER: 'equipmentLocationInternalNumber',
  EQUIPMENT_MANUFACTURER_NAME: 'equipmentManufacturerName',
  EQUIPMENT_MAINTENANCE_ASSIGNEE: 'equipmentMaintenanceAssignee',
  EQUIPMENT_MODEL: 'equipmentModel',
  EQUIPMENT_NAME: 'equipmentName',
  EQUIPMENT_ORGANISATION: 'equipmentOrganisation',
  EQUIPMENT_OWNER: 'equipmentOwner',
  EQUIPMENT_POSSESSOR: 'equipmentPossessor',
  EQUIPMENT_PERSON_IN_CHARGE: 'equipmentPersonInCharge',
  EQUIPMENT_SCAN_CODE: 'equipmentScanCode',
  EQUIPMENT_START_DATE: 'equipmentStartDate',
  EQUIPMENT_STATUS: 'equipmentStatus',
  EQUIPMENT_TRANSFER_COMMENT: 'equipmentTransferComment',
  EQUIPMENT_TRANSFER_EXTERNAL_PERSON_NAME: 'equipmentTransferExternalPersonName',
  EQUIPMENT_TRANSFER_INTERNAL_EMPLOYEE_NAME: 'equipmentTransferInternalEmployee',
  EQUIPMENT_TYPE_CATEGORY1: 'equipmentType.category1',
  EQUIPMENT_TYPE_CATEGORY2: 'equipmentType.category2',
  EQUIPMENT_WORKING_DAYS: 'equipmentWorkingDays',
  SOURCE_ADDRESS: 'sourceAddress',
  SOURCE_COST_CENTER: 'sourceCostCenter',
  SOURCE_GUID_ID: 'sourceGuidId',
  SOURCE_EXTERNAL_ID: 'sourceExternalId',
  SOURCE_INT_NR: 'sourceIntNr',
  SOURCE_NAME: 'sourceName',
  TARGET_ADDRESS: 'targetAddress',
  TARGET_COST_CENTER: 'targetCostCenter',
  TARGET_EXTERNAL_ID: 'targetExternalId',
  TARGET_GUID_ID: 'targetGuidId',
  TARGET_INT_NR: 'targetIntNr',
  TARGET_NAME: 'targetName',
  TRANSFER_COMMENT: 'transferComment',
  TRANSFER_DATE: 'transferDate',
  TRANSFER_EXTERNAL_PERSON_NAME: 'transferExternalPersonName',
  TRANSFER_INTERNAL_PERSON_NAME: 'transferInternalPersonName',
  TRANSFER_ID: 'transferId',
  TRANSFER_ITEM_COST_CENTER: 'transferItemCostCenter',
  TRANSFER_ITEM_EXTERNAL_ID: 'transferItemExternalId',
  TRANSFER_ITEM_GUID_ID: 'transferItemGuidId',
  TRANSFER_ITEM_INT_NR: 'transferItemIntNr',
  TRANSFER_ITEM_NAME: 'transferItemName',
  TRANSFER_ITEM_TYPE: 'transferItemType',
  TRANSFER_ITEM_ORGANISATION_EXTERNAL_ID: 'transferItemOrganisationExternalId',
  TRANSFER_ITEM_ORGANISATION_GUID_ID: 'transferItemOrganisationGuidId',
  TRANSFER_ITEM_ORGANISATION_NR: 'transferItemOrganisationNr',
  TRANSFER_ITEM_ORGANISATION_NAME: 'transferItemOrganisationName',
  TRANSFER_ORGANISATION_EXTERNAL_ID: 'transferOrganisationExternalId',
  TRANSFER_ORGANISATION_GUID_ID: 'transferOrganisationGuidId',
  TRANSFER_ORGANISATION_NR: 'transferOrganisationNr',
  TRANSFER_TYPE_NAME: 'transferTypeName',
  TRANSFER_ITEM_UNIT: 'transferItemUnit',
}

export const PROJECT_COLUMN_DEF = {
  PROJECT_NUMBER: 'projectNumber',
  PROJECT_NUMBER_AND_PROJECT_NAME_LOCATION: 'projectNumberAndProjectName',
  PROJECT_COST_CENTER: 'projectCostCenter',

  PROJECT_NONE: 'projectNone',
}
