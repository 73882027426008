import { filter } from 'rxjs/operators';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { LifeCycleCost } from '../../../../../../contract/lifecycle-cost';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EquipmentInvoice } from '../../../../../../contract/equipment-invoice.interface';
import { LanguageService } from '../../../../../../../../shared/services/language.service';
import { Utils } from 'app/shared/utils';

@UntilDestroy()
@Component({
  selector: 'bh-cost-event',
  templateUrl: './cost-event.component.html'
})
export class CostEventComponent implements OnInit, OnDestroy {

  @Input() equipmentId: string;
  @Input() costLifeCycle: LifeCycleCost;

  public invoice: EquipmentInvoice;

  constructor(private equipmentsService: EquipmentsService,
              private utils: Utils,
              private languageService: LanguageService) { }

  public ngOnInit(): void {
    if (this.equipmentId && this.costLifeCycle && this.costLifeCycle.invoiceId) {
      this.getInvoice(this.equipmentId, this.costLifeCycle.invoiceId)
    }
  }

  public ngOnDestroy(): void {
  }

  private getInvoice(equipmentId, invoiceId): void {
    this.equipmentsService.getInvoiceDetails(equipmentId, invoiceId)
      .pipe(
        untilDestroyed(this),
        filter(Boolean))
      .subscribe(
        (res: EquipmentInvoice) => this.invoice = res,
        error => console.log('error get invoice details: ', error)
      )
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }
}
