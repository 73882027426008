import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from 'app/core/keycloak';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterHistory } from 'app/shared/router-history';
import { OnInit, Directive } from '@angular/core';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import { BehaviorSubject } from 'rxjs';
import { ProjectCheckerService } from 'app/modules/disposition/shared/services/project-checker.service';
import { byLocationClosestToPosition } from 'app/shared/collection-utils';
import { Point } from 'app/shared/geolocation/point';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, UntypedFormArray } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import moment from 'moment';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { AddEmployeeToProjectAssignmentCommand } from 'app/modules/disposition/contract/add-employee-project-assignment-command';
import { DispositionEmployee } from 'app/modules/disposition/contract/disposition-employee';
import { EmployeeAssignToProjectUtils } from './employee-assign-to-project-utils';
import { EmployeeDispositionDatasource } from '../../employee-disposition.datasource';
import { OnlineStatusService } from 'app/shared/services/online-status.service';
import { EmployeeDispositionTeamRelation } from 'app/modules/disposition/contract/employee-disposition-relation';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { DatesService } from 'app/shared/services/dates.service';
import { LanguageService } from 'app/shared/services/language.service';
import { ViewEmployeeProjectAssignment } from 'app/modules/disposition/contract/view-employee-project-assignment.interface';
import { UsersService } from '../../../../userrole/shared/users.service';
import { DispositionAssignmentPayload } from 'app/modules/equipment/contract/disposition-assignment-payload.interface';
import { SearchEmployeeDisposition } from 'app/modules/disposition/contract/search-employee-disposition.interface';
import { DateUtils } from 'app/shared/dateUtils';

@Directive()
export class BaseEmployeeAssignToProjectComponent extends GuardedNavigableInputComponent implements OnInit {

  public projectSubForm: UntypedFormGroup;
  public dispositionsSubForm: UntypedFormGroup;
  public commentSubForm: UntypedFormGroup;
  public assignToProjectForm: UntypedFormGroup;

  public dialogRef;

  public minStartDate: Date;
  public maxEndDate: Date;
  public project: DispositionProject;
  public projects: DispositionProject[];
  public teams: string[] = [];
  public employees: DispositionEmployee[] = [];
  public filteredProjects: BehaviorSubject<DispositionProject[]> = new BehaviorSubject([]);
  public overwriteEnabled = false;
  public outsideAssignmentData: DispositionAssignmentPayload<SearchEmployeeDisposition> = null;

  private projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  private position: Point;
  private loadingProjects: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private static overrideStartDate(start: Date, end: Date): boolean {
    if (!start && !end) {
      return true;
    }
    if (start) {
      const today = moment();
      return today.isAfter(start) && (!end || today.isBefore(end));
    }
    return false;
  }

  constructor(protected authService: KeycloakService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected employeeDispositionStore: EmployeeDispositionDatasource,
    protected routerHistory: RouterHistory,
    protected projectStore: ProjectDataSource,
    protected onlineStatusService: OnlineStatusService,
    protected dialog: MatDialog,
    protected formBuilder: UntypedFormBuilder,
    protected usersService: UsersService,
    protected langService: LanguageService) {
    super(authService, router, route, routerHistory)
  }

  public ngOnInit(): void {
    this.overwriteEnabled = this.route.snapshot.queryParams.overwrite;
    this.buildForm();
    this.subscribeToProjects();
    this.subscribeToProjectSearch();
  }

  public get startDateControl(): AbstractControl {
    return this.dispositionsSubForm.get('startDate');
  }

  public get endDateControl(): AbstractControl {
    return this.dispositionsSubForm.get('endDate');
  }

  public get employeeDispositions(): AbstractControl[] {
    const formArray = this.dispositionsSubForm.get('employeeDispositions') as UntypedFormArray;
    return formArray.controls;
  }

  public setAssignmentData(data: DispositionAssignmentPayload<SearchEmployeeDisposition>): void {
    this.outsideAssignmentData = {...data};
    this.project = {...data.project};
    this.teams = data.assignment.teamName ? [data.assignment.teamName] : [];
    this.employees = [...data.assignment.teamMembers.map(DispositionEmployee.fromSearchEmployee)];
    this.setDateRange();
  }

  protected buildForm(): void {
    this.projectSubForm = this.formBuilder.group({
      projectId: [(this.project && this.project.projectId) || null , Validators.required]
    });

    this.loadingProjects.subscribe(loading => {
      const idControl = this.projectSubForm.controls['projectId'];
      loading ? idControl.disable() : idControl.enable();
    });

    this.dispositionsSubForm = this.formBuilder.group({
      startDate: [
        (this.outsideAssignmentData && this.outsideAssignmentData.startDate) || '',
        Validators.required],
      endDate: [(this.outsideAssignmentData && this.outsideAssignmentData.endDate) || ''],
      employeeDispositions: this.formBuilder.array(this.employees.map(employee => this.formBuilder.group({
        employeeId: [employee.employeeId],
        employeeOrTeamName: [EmployeeAssignToProjectUtils.formatEmployeeIdentifier(employee)],
        warnings: [''],
        overwrite: this.overwriteEnabled,
        teamStatus: [this.checkTeamRelation(employee)],
        teamName: [employee.teamName]
      })))
    });

    this.commentSubForm = this.formBuilder.group({
      comment: ['', Validators.maxLength(FieldLimit.LONG_TEXT)]
    });

    this.assignToProjectForm = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.projectSubForm,
        this.dispositionsSubForm,
        this.commentSubForm
      ])
    });

    this.loadTeamsToForm();
  }

  private loadTeamsToForm(): void {
    let existedDispositions = this.dispositionsSubForm.controls['employeeDispositions'] as UntypedFormArray;
    this.teams.forEach(team => {
      existedDispositions.push(this.formBuilder.group({
        teamStatus: [EmployeeDispositionTeamRelation.TEAM],
        employeeOrTeamName: [team],
        warnings: [],
        teamName: [team],
        overwrite: [false]
      }))
    })
  }

  protected loadProjects(): void {
    this.projectStore.loadDispositionProjects();
  }

  private checkTeamRelation(employee: DispositionEmployee): EmployeeDispositionTeamRelation {
    if (employee.teamName && this.teams.includes(employee.teamName)) {
      return EmployeeDispositionTeamRelation.MEMBER;
    } else {
      return EmployeeDispositionTeamRelation.EMPLOYEE;
    }
  }

  public updateAllConflicts(): void {
    if (this.project && this.employees && this.startDateControl.valid && this.endDateControl.valid) {
      this.employeeDispositions.forEach(disposition => {
        this.updateConflicts(disposition);
      }
      );
    }
  }

  public updateConflicts(employeeDisposition: AbstractControl): void {
    if (this.onlineStatusService.onlineStatus && employeeDisposition.value.employeeId) {
      this.employeeDispositionStore.getAssignmentCollisions(
        employeeDisposition.value.employeeId,
        this.startDateControl.value,
        this.endDateControl.value)
        .pipe(map(conflicts => conflicts.length === 0 ? undefined : conflicts))
        .subscribe((conflictingAssignments: ViewEmployeeProjectAssignment[]) => {

          const overwriteConflicts = employeeDisposition.value.overwrite;

          if (!conflictingAssignments) {
            employeeDisposition.patchValue({ warnings: [] });
          }
          if (conflictingAssignments && overwriteConflicts) {
            employeeDisposition.patchValue({ warnings: conflictingAssignments });
          }
          if (conflictingAssignments && !overwriteConflicts) {
            employeeDisposition.patchValue({ warnings: [] });
            employeeDisposition.setErrors({ 'conflicts': conflictingAssignments });
          }
        });
    } else if (employeeDisposition.value.teamStatus !== EmployeeDispositionTeamRelation.TEAM) {
      employeeDisposition.patchValue({ overwrite: true });
    }
  }

  protected subscribeToProjects(): void {
    this.projectStore.dispositionProjects.subscribe(projects => {
      if (projects.length > 0) {
        this.projects = projects.filter(pr => !this.projectCheckerService.isProjectFinished(pr));
        this.filteredProjects.next(this.projects.slice());
        if (this.position) {
          this.projects.sort(byLocationClosestToPosition(this.position));
        }
      }

      this.loadingProjects.next(false);
    });

    this.projectStore.currentDispositionProject.subscribe(project => {
      this.project = project;
      this.projectSubForm.setValue({ projectId: this.project.projectId });
      this.fillDateFields();
    });
  }

  private subscribeToProjectSearch(): void {
    this.projectSubForm.get('projectId').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(value => value))
      .subscribe((value: string) => {
        this.getProjectAndPrimaryAssigneeData(value);
      });
  }

  private fillDateFields(): void {
    this.setDateRange();

    if (this.outsideAssignmentData) {
      const {startDate, endDate} = this.outsideAssignmentData;
      this.dispositionsSubForm.patchValue({
        startDate: DateUtils.minDate(startDate, this.maxEndDate),
        endDate: DateUtils.maxDate(endDate, this.minStartDate)
      }, {emitEvent: false});
    } else {
      this.dispositionsSubForm.patchValue({
        startDate: this.minStartDate,
        endDate: this.maxEndDate
      });

      if (BaseEmployeeAssignToProjectComponent.overrideStartDate(
        this.project.projectStart,
        this.project.projectEnd)) {
        this.dispositionsSubForm.patchValue({ startDate: moment().toDate() });
      }
    }

    this.dispositionsSubForm.markAllAsTouched();
    this.updateAllConflicts();
  }

  private setDateRange(): void {
    this.minStartDate = this.project.projectStart ? moment(this.project.projectStart).toDate() : null;
    this.maxEndDate = this.project.projectEnd ? moment(this.project.projectEnd).toDate() : null;
  }

  private getProjectAndPrimaryAssigneeData(projectId: string) {
    this.projectStore.setCurrentDispositionProject(projectId);
  }

  public isValid(): boolean {
    return this.projectSubForm.valid && this.dispositionsSubForm.valid && this.commentSubForm.valid;
  }

  isContinueToDispositionDisabled(): boolean {
    return !this.projectSubForm.valid;
  }

  isContinueToCommentDisabled(): boolean {
    return !this.projectSubForm.valid || !this.dispositionsSubForm.valid;
  }

  public save(): void {
    if (this.isValid()) {
      const commands: AddEmployeeToProjectAssignmentCommand[] = this.employeeDispositions.filter(
        disposition => disposition.value['teamStatus'] !== EmployeeDispositionTeamRelation.TEAM)
        .map(employeeDisposition => {
          let cmd: AddEmployeeToProjectAssignmentCommand = new AddEmployeeToProjectAssignmentCommand(
            employeeDisposition.get('employeeId').value,
            this.project.projectId,
            DatesService.sameTimeZoneAtStartDateUTC(this.startDateControl.value),
            DatesService.sameTimeZoneAtEndDateUTC(this.endDateControl.value),
            this.commentSubForm.get('comment').value,
            employeeDisposition.get('overwrite').value === null ? false : employeeDisposition.get('overwrite').value
          );
          return cmd;
        });

      this.dialogRef.close(commands);
    }
  }

  public removeDisposition(employeeDisposition: AbstractControl): void {
    if (employeeDisposition.value['teamStatus'] === EmployeeDispositionTeamRelation.TEAM) {

      if (this.numberOfTeamsAndEmployees() <= 1) {
        this.removeTeamDispositionWithMembers(employeeDisposition);
      } else {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent);
        dialogRef.componentInstance.confirmTitle =
          this.langService.getInstant('modules.disposition.addEmployeeDisposition.removeTeamMembers');
        dialogRef.componentInstance.confirmMessage =
          this.langService.getInstant('modules.disposition.addEmployeeDisposition.notReversed');

        dialogRef.afterClosed().subscribe((dialogResult: string) => {
          if (dialogResult === dialogResults.YES) {
            this.removeTeamDispositionWithMembers(employeeDisposition);
          } else if (dialogResult === dialogResults.NO) {
            this.removeTeamDispositionWithoutMembers(employeeDisposition);
          }
        });
      }
    } else if (employeeDisposition.value['teamStatus'] === EmployeeDispositionTeamRelation.EMPLOYEE) {
      const employeeId = employeeDisposition.get('employeeId').value;
      const formArray = this.dispositionsSubForm.get('employeeDispositions') as UntypedFormArray;
      const index = formArray.controls.indexOf(employeeDisposition);

      formArray.removeAt(index);
      this.employees = this.employees.filter(employee => employee.employeeId !== employeeId);
    } else {

      let employeeId = employeeDisposition.get('employeeId').value;
      const formArray = this.dispositionsSubForm.get('employeeDispositions') as UntypedFormArray;
      const index = formArray.controls.indexOf(employeeDisposition);

      formArray.removeAt(index);
      this.employees = this.employees.filter(employee => employee.employeeId !== employeeId);
      let teamDisposition = formArray.controls.filter(control =>
        control.get('employeeOrTeamName').value === employeeDisposition.get('teamName').value)[0];
      this.removeTeamDispositionWithoutMembers(teamDisposition);
    }
  }

  public removeTeamDispositionWithMembers(employeeDisposition: AbstractControl): void {
    const teamName = employeeDisposition.get('employeeOrTeamName').value;
    const formArray = this.dispositionsSubForm.get('employeeDispositions') as UntypedFormArray;
    const index = formArray.controls.indexOf(employeeDisposition);

    this.teams = this.teams.filter(team => team !== teamName);
    formArray.removeAt(index);

    const teamMembers: AbstractControl[] = formArray.controls.filter(control => control.get('teamName').value === teamName);
    teamMembers.forEach(member => {
      let indexTeamMember = formArray.controls.indexOf(member);
      formArray.removeAt(indexTeamMember);
    })
  }

  public removeTeamDispositionWithoutMembers(employeeDisposition: AbstractControl): void {
    const teamName = employeeDisposition.get('employeeOrTeamName').value;
    const formArray = this.dispositionsSubForm.get('employeeDispositions') as UntypedFormArray;
    const index = formArray.controls.indexOf(employeeDisposition);

    this.teams = this.teams.filter(team => team !== teamName);
    formArray.removeAt(index);

    for (let control of formArray.controls) {
      if (control.get('teamName').value === teamName) {
        const indexTeamMember = formArray.controls.indexOf(control);
        formArray.setControl(indexTeamMember, this.formBuilder.group({
          employeeId: control.get('employeeId'),
          employeeOrTeamName: control.get('employeeOrTeamName'),
          warnings: control.get('warnings'),
          assignmentRequests: control.get('assignmentRequests'),
          overwrite: control.get('overwrite'),
          teamStatus: EmployeeDispositionTeamRelation.EMPLOYEE,
          teamName: control.get('teamName'),
        }));
      }
    }

    this.updateAllConflicts();
  }

  private numberOfTeamsAndEmployees(): number {
    return this.employeeDispositions.filter(
      disposition => disposition.get('teamStatus').value === EmployeeDispositionTeamRelation.EMPLOYEE ||
        disposition.get('teamStatus').value === EmployeeDispositionTeamRelation.TEAM).length;
  }

  private numberOfMembers(): number {
    return this.employeeDispositions.filter(
      disposition => disposition.get('teamStatus').value === EmployeeDispositionTeamRelation.MEMBER).length;
  }

  public isEmployeeOrTeamRemovable(teamStatus: string): boolean {
    let res = (this.numberOfTeamsAndEmployees() > 1) ||
      (this.numberOfMembers() > 1 && teamStatus === EmployeeDispositionTeamRelation.MEMBER);
    return res;
  }
}
