import { TimelineEvent } from './timeline-event';
import moment from 'moment';
import { ViewEquipmentProjectAssignment } from 'app/modules/equipment/contract/view-equipment-project-assignment.interface';

export class EquipmentAssignmentEvent extends TimelineEvent {

  public assignment: ViewEquipmentProjectAssignment;

  constructor(assignment: ViewEquipmentProjectAssignment) {
    super(assignment.projectId + '/' + assignment.assignmentId,
      assignment.projectId + '/' + assignment.equipmentId,
      assignment.startDate,
      assignment.endDate,
      assignment.chargeDate ? new Date(moment.utc(assignment.chargeDate).format('YYYY-MM-DD')) : null,
      '',
      false,
      false,
      'red');
    this.assignment = assignment;
  }
}

export function isEquipmentAssignmentEvent(event: TimelineEvent): event is EquipmentAssignmentEvent {
  return (<EquipmentAssignmentEvent>event).assignment !== undefined &&
   (<EquipmentAssignmentEvent>event).assignment.equipmentId !== undefined;
}
