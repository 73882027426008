<ng-container [ngTemplateOutlet]="isNoSearchResult || itemMap.size === 0 ? notFound : optionBranch"
              [ngTemplateOutletContext]="setContent(itemMap)">
</ng-container>

<ng-template #notFound>
  <div class="empty-list-warning">
    <p>{{'shared.expandingTree.notFound'|translate}}</p>
  </div>
</ng-template>

<ng-template #optionBranch let-treeItems>
  <cdk-virtual-scroll-viewport itemSize="50" class="viewport" minBufferPx="300" maxBufferPx="300">
    <div *cdkVirtualFor="let treeItem of (treeItems | expandingTreeFlatItems: alwaysExpanded); let i = index; trackBy: trackById"
         [ngStyle]="{'margin-left': (treeItem.offset * 28) + 'px'}"
         class="item"
         [matTooltip]="tooltipTextPredicate(treeItem)"
         data-osp-test="tree-item">

      <div fxLayout="row"
           fxLayoutAlign="start center"
           class="item__header__content"
           (click)="toggleExpanding(treeItem)"
           [ngClass]="getItemClasses(treeItem)">
        <mat-checkbox fxFlex="none"
                      (click)="onChangeSelectionClick($event, treeItem)"
                      [checked]="treeItem.checked"
                      [indeterminate]="treeItem.indeterminate"
                      [disabled]="treeItem.disabled"
                      color="primary"
                      class="item__header__content__checkbox">
        </mat-checkbox>

        <div class="item__header__content__title"
             fxFlex
             [ngClass]="{ 'cursor-pointer': alwaysExpanded }"
             (click)="alwaysExpanded && onChangeSelectionClick($event, treeItem)">
          <ng-container [ngTemplateOutlet]="contentTemplate"
                        [ngTemplateOutletContext]="setContent(treeItem.optionValue)">
          </ng-container>
        </div>

        <div *ngIf="!isLeaf(treeItem)"
             fxFlex="nogrow"
             (click)="toggleExpanding(treeItem)"
             class="item__header__content__expand_icon">
          <mat-icon *ngIf="!treeItem.expanded && !alwaysExpanded" (click)="toggleExpanding(treeItem)">
            expand_more
          </mat-icon>
          <mat-icon *ngIf="treeItem.expanded || alwaysExpanded" (click)="toggleExpanding(treeItem)">
            expand_less
          </mat-icon>
        </div>
      </div>

    </div>
  </cdk-virtual-scroll-viewport>
</ng-template>
