import { MaintenanceCategory } from './../../../../shared/enums/maintenance-category.enum';
import { LifeCycleCost } from './../../contract/lifecycle-cost';
import { LifeCycleType } from './../../contract/lifecycle-type.enum';
import { LifeCycle } from './../../contract/life-cycle';
import { LanguageService } from './../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { PerformanceTypePipe } from './performance-type.pipe';

@Pipe({
  name: 'lifeCycleTitle'
})
export class LifeCycleTitlePipe implements PipeTransform {
  constructor(
    private performanceTypePipe: PerformanceTypePipe,
    private languageService: LanguageService
  ) {}

  transform(lifeCycle: LifeCycle, isMobile = false): string {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        return this.translate('general.maliciousDamage.s');
      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return this.getCostTitle(lifeCycle);
      case LifeCycleType.OPERATING_HOURS_UPDATE:
        return this.translate(!isMobile
          ? 'shared.pipes.lifeCycleTitle.manualOperatingHours'
          : 'general.operatingHour.pl');
      case LifeCycleType.MILEAGE_UPDATE:
        return this.translate(!isMobile
          ? 'shared.pipes.lifeCycleTitle.manualMileage'
          : 'general.mileage');
      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        return this.translate('general.incident.s');
      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        return lifeCycle.category === MaintenanceCategory.CHECK
          ? this.translate('shared.pipes.lifeCycleTitle.completedInspection')
          : this.translate('shared.pipes.lifeCycleTitle.completedMaintenance');
      case LifeCycleType.COMPLETED_REMINDER:
        return this.translate('general.reminder');
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        return this.translate('shared.pipes.lifeCycleTitle.employeeAssignmentAdded');
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        return this.translate('shared.pipes.lifeCycleTitle.employeeAssignmentReplaced');
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        return this.translate('shared.pipes.lifeCycleTitle.employeeAssignmentRemoved');
    }
  }

  private getCostTitle(lifeCycle: LifeCycle): string {
    const performanceType = this.getCostSpecificTitle(lifeCycle);
    return `${this.translate('general.invoice.s')}${performanceType ? ` - ${performanceType}` : ``}`;
  }

  private getCostSpecificTitle(lifeCycle: LifeCycle): string {
    const costLifeCycle = <LifeCycleCost>lifeCycle;
    return this.performanceTypePipe.transform(costLifeCycle.performanceType);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
