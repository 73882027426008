import { Injectable } from '@angular/core';
import { RetryGeocoder } from '../../../geolocation/retry-geocoder';
import { BehaviorSubject, Observable, filter, take } from 'rxjs';
import { Address } from '../../../../modules/organisation/contract/address.interface';
import { GeocoderResultRequest } from '../../../geolocation/geocoder-result-request.interface';
import { MapLoaderService } from 'app/shared/modules/map/services/map-loader.service';

type Location = {
  lat: number;
  lon: number;
  address?: Address;
}

@Injectable()
export class LocationAddressResolver {

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly loading: Observable<boolean> = this._loading.asObservable();
  private _address: BehaviorSubject<Address> = new BehaviorSubject(undefined);
  public readonly address: Observable<Address> = this._address.asObservable();

  private geocoderLocation: RetryGeocoder;

  constructor(private mapLoaderService: MapLoaderService) {
    this.mapLoaderService.isLoaded
      .subscribe(() => {
        this.geocoderLocation = new RetryGeocoder(new google.maps.Geocoder());
        this.geocoderLocation.geocodeResult
          .subscribe(geocodeResult => this.locationHandler(geocodeResult));
      });
  }

  public resolveLocationAddress(location: Location): void {
    if (!!location.address) {
      this._address.next(location.address);
      this._loading.next(false);
    } else {
      this._loading.next(true);
      this.geocoderLocation.geocodeRequest({ location: new google.maps.LatLng(location.lat, location.lon)});
    }
  }

  private locationHandler({ result, status }: GeocoderResultRequest): void {
    if (status === google.maps.GeocoderStatus.OK) {
      this._address.next(this.parseAddress(result));
    } else {
      this._address.next(undefined);
    }

    this._loading.next(false);
  }

  private parseAddress(place: google.maps.places.PlaceResult | google.maps.GeocoderResult): Address {
    let address: Address = {} as Address;

    place.address_components.forEach(comp => {
      comp.types.forEach(type => {
        switch (type) {
          case 'street_number':
            address.streetNumber = comp.long_name;
            break;
          case 'route':
            address.street = comp.long_name;
            break;
          case 'postal_code':
            address.postalCode = comp.long_name;
            break;
          case 'locality':
            address.city = comp.long_name;
            break;
          default:
            break;
        }
      });
    });

    return address;
  }
}
