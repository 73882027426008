import { Pipe, PipeTransform } from '@angular/core';
import { ExpandingTreeMapItem } from '../contracts/expanding-tree-map-item.class';

@Pipe({
  name: 'expandingTreeFlatItems',
  pure: false
})
export class ExpandingTreeFlatItemsPipe implements PipeTransform {

  transform(itemMap: Map<string, ExpandingTreeMapItem>, alwaysExpanded: boolean): ExpandingTreeMapItem[] {
    const flatTreeItems: ExpandingTreeMapItem[] = [];
    this.getRootItems(itemMap)
      .forEach(item => this.appendExpandingTreeMapItem(itemMap, flatTreeItems, item, alwaysExpanded));
    return flatTreeItems;
  }

  private appendExpandingTreeMapItem(itemMap: Map<string, ExpandingTreeMapItem>,
                                     flatTree: ExpandingTreeMapItem[],
                                     item: ExpandingTreeMapItem,
                                     alwaysExpanded: boolean,
                                     offset: number = 0) {
    if (!item.hidden) {
      item.offset = offset;
      flatTree.push(item);
    }

    if ((item.expanded || alwaysExpanded) && item.childrenIds) {
      this.getChildItems(itemMap, item.childrenIds)
        .forEach(child => this.appendExpandingTreeMapItem(itemMap, flatTree, child, alwaysExpanded, offset + 1));
    }
  }

  private getRootItems(itemMap: Map<string, ExpandingTreeMapItem>): ExpandingTreeMapItem[] {
    return [...itemMap.values()].filter(item => !item.parentId)
  }

  private getChildItems(itemMap: Map<string, ExpandingTreeMapItem>, ids: string[]): ExpandingTreeMapItem[] {
    return (ids || []).map(id => itemMap.get(id));
  }
}
