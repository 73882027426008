import { Injectable } from '@angular/core';
import { UsersService } from '../../userrole/shared/users.service';
import { UserConfigurationService } from '../../../shared/services/user-configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { Languages } from '../../../shared/enums/languages.enum';
import { LanguageSwitcherDialogComponent } from './language-switcher-dialog.component';

@Injectable({providedIn: 'root'})
export class LanguageSwitcherDialogService {

  constructor(private dialog: MatDialog,
              private usersService: UsersService,
              private userConfigurationService: UserConfigurationService) {
  }

  public checkUserLanguage() {
    const userLanguage: Languages = this.userConfigurationService.getUserLanguage() as Languages;

    if (!!userLanguage) {
      this.usersService.getAvailableLanguages().subscribe(availableLanguages => {
        if (![...availableLanguages].includes(userLanguage)) {
          this.dialog.open(LanguageSwitcherDialogComponent, {width: '600px'});
        }
      })
    }
  }
}
