<mat-card class="entity-creation">
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>language</mat-icon>
      <span>{{'modules.organisation.organisationViewAddEdit.editOrganisation'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.save'|translate}} |
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.toOverview'|translate}}</button>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <form [formGroup]="organisationEditForm" novalidate fxLayout="row" fxLayoutAlign="space-between stretch">
        <div class="entity-creation-form-left border-right" fxFlex="33">

          <h4>{{'general.baseData'|translate}}</h4>

          <mat-form-field>
            <mat-label>{{'modules.organisation.organisationViewAddEdit.organisationName'|translate|uppercase}}</mat-label>
            <input matInput required
                   bhRemoveWhitespaces
                   [formControl]="organisationName"
                   [maxlength]="fieldLimit.NAME">
            <mat-error *ngIf="organisationName.hasError('required')">
              {{'shared.validation.required'|translate:{ value: 'modules.organisation.organisationViewAddEdit.organisationName'|translate } }}
            </mat-error>
            <mat-error *ngIf="organisationName.hasError('taken')">
              {{'modules.organisation.organisationViewAddEdit.organisationNameAlreadyExists' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'modules.organisation.organisationViewAddEdit.organisationNumber'|translate|uppercase}}</mat-label>
            <input matInput
                   bhRemoveWhitespaces
                   [formControl]="organisationNumber"
                   [maxlength]="fieldLimit.LONG_IDENTIFIER">
            <mat-error *ngIf="organisationNumber.hasError('taken')">
              {{'modules.organisation.organisationViewAddEdit.organisationNumberAlreadyExists' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'modules.organisation.organisationViewAddEdit.organisationType'|translate|uppercase}}</mat-label>
            <mat-select formControlName="organisationType" required>
              <mat-option *ngFor="let ot of organisationTypes" [value]="ot">
                {{ot | organisationTypeDisplay}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="organisationType.hasError('required')">
              {{'shared.validation.required'|translate:{ value:'modules.organisation.organisationViewAddEdit.organisationType'|translate } }}
            </mat-error>
          </mat-form-field>

        </div>
        <div class="entity-creation-form-left border-right" fxFlex="33">

          <h4>{{'modules.organisation.organisationViewAddEdit.organisationContact'|translate}}</h4>

          <div formGroupName="organisationContact">
            <mat-form-field>
              <mat-label>{{'modules.organisation.organisationViewAddEdit.contactName'|translate|uppercase}}</mat-label>
              <input matInput required
                     bhRemoveWhitespaces
                     [formControl]="organisationContactName"
                     [maxlength]="fieldLimit.NAME">
              <mat-error *ngIf="organisationContactName.hasError('required')">
                {{'shared.validation.required'|translate:{ value:'modules.organisation.organisationViewAddEdit.contactName'|translate } }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'modules.organisation.organisationViewAddEdit.phoneNumber'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationPhoneNumber"
                     [maxlength]="fieldLimit.PHONE_NUMBER">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'modules.organisation.organisationViewAddEdit.mobileNumber'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationMobileNumber"
                     [maxlength]="fieldLimit.PHONE_NUMBER">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'modules.organisation.organisationViewAddEdit.faxNumber'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationFaxNumber"
                     [maxlength]="fieldLimit.FAX_NUMBER">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'general.email'|translate|uppercase}}</mat-label>
              <input type="email" matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationContactEmail"
                     [maxlength]="fieldLimit.EMAIL">
              <mat-error *ngIf="organisationContactEmail.hasError('validEmail')">
                {{'shared.validation.enterValidEmail'|translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="entity-creation-form-left" fxFlex="33">

          <h4>{{'modules.organisation.organisationViewAddEdit.organisationAddress'|translate}}</h4>

          <div formGroupName="organisationAddress">
            <mat-form-field>
              <mat-select placeholder="{{'general.address.country'|translate|uppercase}}"
                          formControlName="country">
                <mat-option *ngFor="let key of countryKeys" [value]="key">
                  {{ getCountryName(countryEnum[key]) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="organisationCountry.hasError('required')">
                {{'shared.validation.required'|translate:{ value: 'general.address.country'|translate } }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'general.address.street'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationStreet"
                     [maxlength]="fieldLimit.STREET">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'general.address.houseNumber'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationStreetNumber"
                     [maxlength]="fieldLimit.STREET_NUMBER">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'general.address.postCode'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationPostalCode"
                     [maxlength]="fieldLimit.POSTAL_CODE">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{'general.address.city'|translate|uppercase}}</mat-label>
              <input matInput
                     bhRemoveWhitespaces
                     [formControl]="organisationCity"
                     [maxlength]="fieldLimit.CITY">
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>

