import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit } from '@angular/core';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';
import { MapLocationMarker } from '../../../interfaces/map-location-marker.interface';
import { SearchEquipmentLocation } from 'app/modules/equipment/contract/search-equipment-location.interface';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { ThumbnailSize } from 'app/shared/components/test-place/secured-image/enums/thumbnail-size.enum';
import { NavigationMapperService } from 'app/shared/services/navigation-mapper.service';
import { DisplayService } from 'app/shared/display.service';

@Component({
  selector: 'bh-map-info-equipment-details',
  templateUrl: './map-info-equipment-details.component.html',
  styleUrls: ['./map-info-equipment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapInfoEquipmentDetailsComponent implements OnInit, InfoWindowComponent {

  @Input() public value: MapLocationMarker<SearchEquipmentLocation>;

  protected ThumbnailSize = ThumbnailSize;
  protected iconPlaceholder = faTruckPlow;

  protected item: SearchEquipmentLocation;

  constructor(
    private ngZone: NgZone,
    private navigationMapperService: NavigationMapperService,
    private displayService: DisplayService,
  ) {}

  public ngOnInit(): void {
    this.item = this.value?.item;
  }

  public navigateToEquipment(): void {
    const id = this.item?.equipmentId;
    if (id) {
      this.ngZone.run(() => {
        this.navigationMapperService.navigateToEquipmentViewDetails(id)
          .then(() => this.displayService.displayTableAndDetails());
      });
    }
  }

}
