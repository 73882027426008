import {AssetListActivatedView} from 'app/shared/contract/activated-view.interface';
import {ButtonInfo} from 'app/shared/contract/button-info';
import {BaseAssetListComponent} from '../base/base-asset-list.component';
import {KeycloakService} from '../../../../core/keycloak';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AssetListTypeResolver} from '../asset-list-type-resolver';
import {MatDialog} from '@angular/material/dialog';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {ChooseAddProcessDialogComponent} from '../../choose-add-process-dialog/choose-add-process-dialog.component';
import {RouterHistory} from '../../../router-history';
import {MatomoTracker} from 'ngx-matomo';
import {matomoCategories} from '../../../../../assets/matomo/matomo-categories.enum';
import {matomoActions} from '../../../../../assets/matomo/matomo-actions.enum';
import { TabService } from '../../../../shared/tab.service';
import {FormControl} from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'bh-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent extends BaseAssetListComponent implements OnInit {
  public readonly faPlus: IconDefinition = faPlus;
  public routeButton: ButtonInfo;
  public options: string[] = [];
  private child: AssetListActivatedView;
  private hideRouteButton = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected optionResolver: AssetListTypeResolver,
              protected tabService: TabService,
              private dialog: MatDialog,
              private matomoTracker: MatomoTracker) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.hideRouteButton = Boolean(this.route.snapshot.data['hideRouteButton']);
    this.routeButton = new ButtonInfo();
    this.tabService.setAvailableTabs(this.routerHistory.getCurrentUrl());
    this.optionsSubscription();
    super.ngOnInit();
  }

  public navigate(): void {
    this.router.navigate([this.routeButton.route]);
  }

  public reset(): void {
    this.child.reset();
  }

  public search(): void {
    this.child.onSearchFormClick();
  }

  public onActivate(componentRef: AssetListActivatedView): void {
    setTimeout(() => this.processChildComponent(componentRef), 0);
  }

  public openAddMenu(): void {
    this.matomoTracker.trackEvent(matomoCategories.ASSET_LIST, matomoActions.ADD_ASSET_DIALOG_OPEN);
    this.dialog.open(ChooseAddProcessDialogComponent);
  }

  public showAddButton(): boolean {
    return this.canCreateNewEntity() && this.isNotTransferPage();
  }

  public showRouteButton(): boolean {
    return !this.hideRouteButton && Boolean(this.routeButton?.route);
  }

  private processChildComponent(componentRef: AssetListActivatedView): void {
    this.child = componentRef;
    this.child.searchForm = this.searchForm;
    if (this.child.onSearchFormType) {
      this.child.onSearchFormType(0);
    }
    this.routeButton = this.child.routeButton;
  }

  private optionsSubscription(): void {
    this.tabService.availableTabs.pipe(untilDestroyed(this)).subscribe(list => this.options = list);
  }

  getForm(): any {
    return this.searchForm.get('terms') as FormControl;
  }

}
