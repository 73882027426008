<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <bh-mobile-sub-header [title]="getTitle()" [subtitle]="getSubtitle()">
  </bh-mobile-sub-header>

  <mat-drawer-container fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
    <div class="content-panel" fxLayout="column">
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="1 0 auto">
        <div class="form-panel">
          <form [formGroup]="transferToProjectForm" novalidate class="project-form">
            <div class="m-view-details-menu static-title" fxLayout="row" fxLayoutAlign="space-between center">
              <span>{{'modules.equipment.base.assignProject'|translate|uppercase}}</span>
              <button mat-icon-button (click)="goBack()">
                <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
              </button>
            </div>
            <div class="m-view-list-inputs" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">

              <form [formGroup]="projectSubForm" novalidate>
                <p *ngIf="navigatorError">{{navigatorError.customMessage}}</p>
                <mat-drawer mode="over" opened="false" #drawer>
                  <bh-project-select [position]="position" (selected)="selectProject($event)"
                    (canceled)="drawer.close()"></bh-project-select>
                </mat-drawer>
                <mat-form-field class="project-name" (click)="openProjectSelect($event)">
                  <mat-icon class="info-icon" matPrefix fontSet="icon-projects01_maps_solid"></mat-icon>
                  <input [attr.label]="'general.project.s'|translate" readonly matInput
                    [value]="project ? project.projectName : 'modules.equipment.assignProject.selectProject'|translate">
                  <mat-icon matSuffix>arrow_drop_down</mat-icon>
                </mat-form-field>
                <div *ngIf="project" class="info-row">
                  <mat-icon class="info-icon">house</mat-icon>
                  <span>{{formatAddress(project.projectAddress) | defaultValue: '-'}}</span>
                </div>
                <div *ngIf="project" class="info-row">
                  <mat-icon class="info-icon">date_range</mat-icon>
                  <span>{{project | formatDispositionProjectDateRange}}</span>
                </div>
              </form>
              <section>
                <div novalidate>
                  <div class="assign-equipment-project-stepper-content">
                    <bh-mobile-transfer-form-item *ngFor="let equipment of equipments" [equipment]="equipment"
                      (onRemove)="removeDisposition(equipment.equipmentId)">
                    </bh-mobile-transfer-form-item>
                  </div>
                </div>
              </section>
              <form [formGroup]="additionalInfoForm">
                <mat-form-field>
                  <mat-label>{{'general.comment.s'|translate}}</mat-label>
                  <textarea [attr.label]="'general.comment.s'|translate" matInput [maxlength]="fieldLimit.LONG_TEXT"
                    formControlName="comment"></textarea>
                </mat-form-field>
              </form>
            </div>
          </form>
        </div>

        <div id="loading-overlay" *ngIf="(loadingProjects | async)" [class.panel-visible]="(loadingProjects | async)">
          <mat-spinner></mat-spinner>
        </div>
      </div>

      <button mat-button class="m-button-full-width border-top" bhTimeoutSaveButton (throttledClick)="save()"
        [disabled]="!isValid()" *ngIf="equipments.length > 0">
        {{'general.buttons.save'|translate|uppercase}}
      </button>
    </div>
  </mat-drawer-container>
</div>
