import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableString } from 'app/shared/contract/translatable-string.type';
import { Languages } from 'app/shared/enums/languages.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'optionLabels'
})
export class OptionLabelsPipe implements PipeTransform {

  private label = this.languageService.getInstant('modules.fieldManagement.additionalFieldManagement.optionValue');
  constructor(private languageService: LanguageService) {}

  public transform(languages: Languages[]): TranslatableString {
    return (languages ?? []).reduce((acc, lang) => ({ ...acc, [lang]: `${this.label} (${lang})` }), {});
  }

}
