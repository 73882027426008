import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { PushToken } from './contract/push-token';
import { PushService } from './services/push-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Payload } from './contract/payload';
import { ChatUrlParser } from './services/chat-url-parser';
import { KeycloakService } from '../core/keycloak';
import { UserConfigurationService } from './services/user-configuration.service';
import { CustomDeviceDetectorService } from './services/custom-device-detector.service';
import { map } from 'rxjs';


@Injectable()
export class MessagingService {

  private clickAction: string;

  static parseDispositionRoute(url: string): string {
    return url.substring(url.indexOf('assets'));
  }

  constructor(
      protected router: Router,
      private pushService: PushService,
      private snackBar: MatSnackBar,
      private deviceDetectorService: CustomDeviceDetectorService,
      private chatUrlParser: ChatUrlParser,
      private angularFireMessaging: AngularFireMessaging,
      private authService: KeycloakService,
      private userConfigurationService: UserConfigurationService) {
  }

  /**
   * update token in firebase database
   *
   * @param token token as a value
   */
  updateToken(token: string): void {
    if (this.authService.isSuperOrPartnerAdmin()) {
      return;
    }

    let deviceId: string;
    if (this.userConfigurationService.getPushTokenConfiguration()) {
      deviceId = this.userConfigurationService.getPushTokenConfiguration().deviceUuid;
    }

    let pushTokenForRequest: PushToken = PushToken.generatePushTokenForFirebase(token);

    if (deviceId) {
      pushTokenForRequest.uuid = deviceId;
      this.pushService.register(pushTokenForRequest).subscribe(
          () => this.userConfigurationService.savePushTokenToUserConfiguration(token, deviceId),
          () => console.error('Could not save push token to backend')
      );
    } else {
      this.pushService.register(pushTokenForRequest).subscribe(
          () => this.userConfigurationService.savePushTokenToUserConfiguration(token, pushTokenForRequest.uuid.toString()),
          () => console.error('Could not save push token to backend')
      );
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   */
  requestPermission(): void {
    this.angularFireMessaging.requestToken.subscribe(
        token => {
          if (token) {
            this.updateToken(token);
          }
        },
        (err) => {
          console.warn('Unable to get permission to notify.', err.message);
        }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage(): void {
    this.angularFireMessaging.messages
    .pipe(map(payload => payload as unknown as Payload))
    .subscribe((payload: Payload) => {
          if ((!this.router.url.includes('chat') && this.clickAction.includes('chat'))
              || (!this.router.url.includes('disposition') && this.clickAction.includes('disposition'))) {
            this.snackbarInfo(payload.notification.title + ': ' + payload.notification.body, 'Gehe zu');
            this.clickAction = payload.notification['click_action'];
          }
        });
  }

  unregister(): void {
    if (this.authService.isSuperOrPartnerAdmin() || !this.userConfigurationService.getPushTokenConfiguration()) {
      this.logoutAndErasePushToken();
      return;
    }

    const pushToken: PushToken = PushToken.generatePushTokenForFirebase(
        this.userConfigurationService.getPushTokenConfiguration().pushToken,
        this.userConfigurationService.getPushTokenConfiguration().deviceUuid);

    this.pushService.unregister(pushToken).subscribe(
        () => this.logoutAndErasePushToken(),
        () => this.logoutAndErasePushToken()
    );
  }

  private logoutAndErasePushToken(): void {
    this.userConfigurationService.erasePushToken();
    KeycloakService.logout();
  }

  private snackbarInfo(message: string, action: string) {
    let snackBarRef = this.snackBar.open(message, action, {duration: 4000});

    snackBarRef.onAction().subscribe(() => {
      if (this.deviceDetectorService.isMobile() && this.clickAction.includes('chat')) {
        this.router.navigate(['mobile/' + this.chatUrlParser.parseChatRoute(this.clickAction)]);
      } else if (this.clickAction.includes('chat')) {
        this.router.navigate(['.', {
          outlets: {sidenav: this.chatUrlParser.parseChatRoute(this.clickAction)}
        }], {skipLocationChange: true});
      }

      if (this.deviceDetectorService.isMobile() && this.clickAction.includes('disposition')) {
        this.router.navigate(['mobile/disposition/list']);
      } else if (this.clickAction.includes('disposition')) {
        this.router.navigate([MessagingService.parseDispositionRoute(this.clickAction)]);
      }
    });
  }
}
