import { TelematicUnitLastChangesType } from 'app/modules/equipment/contract/telematic-unit-traffic-last-changes.enum';
import moment from 'moment';

export class TelematicUnitTrafficBase {

  private now = moment(new Date());
  private weekAgo = this.now.clone().subtract(1, 'week');
  private twoMonthsAgo = this.now.clone().subtract(2, 'months');

  protected getTrafficLastChangesType(lastDate: Date): TelematicUnitLastChangesType {
    const lastDateMoment = moment(lastDate || 0);
    if (lastDateMoment.isBefore(this.twoMonthsAgo)) {
      return TelematicUnitLastChangesType.TWO_MONTHS_AGO;
    } else if (lastDateMoment.isBefore(this.weekAgo)) {
      return TelematicUnitLastChangesType.WEEK_AGO;
    }
    return TelematicUnitLastChangesType.LAST_WEEK;
  }
}
