export enum MaintenanceResult {
  OK = 'OK',
  OK_WITH_RESTRICTIONS = 'OK_WITH_RESTRICTIONS',
  NOT_OK = 'NOT_OK',
  NO_RESULT_AVAILABLE = 'NO_RESULT_AVAILABLE'
}

export const MAINTENANCE_RESULTS = [
  MaintenanceResult.OK,
  MaintenanceResult.OK_WITH_RESTRICTIONS,
  MaintenanceResult.NOT_OK,
  MaintenanceResult.NO_RESULT_AVAILABLE
]
