<form (ngSubmit)="onSearchFormSubmit()" [formGroup]="searchForm" fxLayout="column" fxLayoutAlign="start stretch"
      novalidate>

  <div class="disposition-board-equipment-search__search-bar">
    <div fxLayout="row">
      <input formControlName="terms" fxFlex [placeholder]="'modules.disposition.base.enterSearchTerm'|translate">
      <button mat-icon-button [matTooltip]="'general.buttons.search'|translate">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>

  <div class="disposition-board-equipment-search__filter" fxLayout="row" fxLayoutAlign="start stretch"
       fxLayoutGap="15px">
    <form [formGroup]="filterForm" fxFlexFill fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-form-field>
        <mat-select formControlName="selectedLabels" multiple [placeholder]="'general.label.s'|translate">
          <mat-option *ngFor="let label of labels | async" [value]="label.name">
            {{label.name}} ({{label.count}})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="selectedEquipmentTypes" multiple [placeholder]="'general.type'|translate">
          <mat-optgroup *ngFor="let group of groupedEquipmentTypes | async"
            label="{{group.category1Name | translatableString }} ({{group.total}})">
            <mat-option *ngFor="let subType of group.subTypes" [value]="subType.equipmentTypeId">
              {{ subType.category2Name | translatableString }} ({{ subType.count }})
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div class="disposition-board-equipment-search__availability-filter" fxLayout="row" fxLayoutAlign="start stretch">
    <div (click)="selectFilter(FILTER_ALL)"
         [ngClass]="{'type-filter-selected': FILTER_ALL === currentFilter}">
      {{'general.filterLabels.all'|translate|uppercase}}
    </div>
    <div (click)="selectFilter(FILTER_AVAILABLE)"
         [ngClass]="{'type-filter-selected': FILTER_AVAILABLE === currentFilter}">
      {{'general.filterLabels.available'|translate|uppercase}}
    </div>
    <div (click)="selectFilter(FILTER_UNAVAILABLE)"
         [ngClass]="{'type-filter-selected': FILTER_UNAVAILABLE === currentFilter}">
      {{'general.filterLabels.disposed'|translate|uppercase}}
    </div>
  </div>

  <div *ngIf="currentFilter !== FILTER_ALL" class="disposition-board-equipment-search__date-filter" fxLayout="row"
       fxLayoutAlign="start stretch">
    <bh-date-range-select
      [startDateControl]="startDateControl"
      [endDateControl]="endDateControl"
      [resettable]="true">
    </bh-date-range-select>
  </div>

  <div *ngIf="selectedEquipments.length"
       class="disposition-board-equipment-search__selection"
       fxLayout="row"
       fxLayoutAlign="space-between center">

    <div class="selection__clear" [matTooltip]="'modules.disposition.base.clearSelection'|translate">
      <mat-icon (click)="deselectAllEquipments()" class="bh-mat-icon">clear</mat-icon>
    </div>

    <div class="selection__total">
      {{ selectedEquipments.length }} {{'modules.disposition.equipmentSearch.equipmentSelected'|translate}}
    </div>

    <div class="selection__button">
      <button (click)="openDispositionDialog()" fxFlex="40px" mat-button
              [matTooltip]="'modules.disposition.equipmentSearch.disposeEquipment'|translate">
        <mat-icon>fast_forward</mat-icon>
      </button>
    </div>
  </div>

</form>

<div class="disposition-board-equipment-search__results" fxFlex fxLayout="row" fxLayoutAlign="start stretch">

  <mat-accordion [id]="containerIdentifiers.DISPOSITION_EQUIPMENT" *ngIf="equipments && equipments.length"
                 fxFlexFill
                 cdkDropList
                 cdkDropListSortingDisabled
                 [cdkDropListConnectedTo]="containerIdentifiers.DISPOSITION_SCHEDULER"
                 [cdkDropListData]="equipments"
                 [cdkDropListDisabled]="!hasAuthority(authorities.EQUIPMENT_MANAGE_DISPOSITION)">
    <mat-expansion-panel (closed)="equipmentCollapse()"
                         (opened)="equipmentExpand(e.equipmentId)"
                         *ngFor="let e of equipments"
                         [@.disabled]="!viewChecked"
                         class="equipment-expansion-panel"
                         cdkDrag
                         bhPreventExpandAfterDrag
                         [bhDraggableItemType]="dragType.EQUIPMENT"
                         [dropCellClassName]="'dhx_matrix_cell'"
                         [cdkDragData]="e">

      <div class="drag-item-preview" *cdkDragPreview fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="drag-item-preview__image-container" fxLayoutAlign="center center">
          <bh-thumbnail *ngIf="e.thumbnailKey" [key]="e.thumbnailKey" [size]="ThumbnailSize.XS"></bh-thumbnail>
          <svg *ngIf="!e.thumbnailKey" height="50" width="50">
            <circle cx="25" cy="25" fill="#ff3a20" r="20" stroke-width="0">
            </circle>
            <text dominant-baseline="central" fill="white" text-anchor="middle" x="25" y="25">
              {{ e.equipmentType.category2Name | translatableString | slice:0:1 }}
            </text>
          </svg>
        </div>
        <div class="drag-item-preview__title">{{getEquipmentNameOrModel(e)}}</div>
      </div>

      <mat-expansion-panel-header collapsedHeight="90px" expandedHeight="90px">
        <div class="disposition-board-equipment-search__results--item" fxLayout="row"
             fxLayoutAlign="space-between center">
          <mat-checkbox
            (change)="onCheckboxToggle(e, $event)"
            (click)="$event.stopPropagation()"
            [checked]="equipmentSelected(e)"
            [disabled]="!hasAuthority(authorities.EQUIPMENT_MANAGE_DISPOSITION)"
            [matTooltipDisabled]="hasAuthority(authorities.EQUIPMENT_MANAGE_DISPOSITION)"
            color="primary"
            [matTooltip]="'modules.disposition.equipmentSearch.forbiddenToDispose'|translate">
          </mat-checkbox>

          <div *ngIf="(e.equipmentType.category2Name | translatableString) as eqTypeName"
            class="equipment-type-icon-container"
            [matTooltip]="eqTypeName">
            <bh-thumbnail *ngIf="e.thumbnailKey" [key]="e.thumbnailKey" [size]="ThumbnailSize.XS"></bh-thumbnail>
            <!-- Default -->
            <svg *ngIf="!e.thumbnailKey" height="50" width="50">
              <!-- TODO BEUT-271 color code needs to be defined by equipment type -->
              <circle cx="25" cy="25" fill="#ff3a20" r="20" stroke-width="0">
              </circle>
              <text dominant-baseline="central" fill="white" text-anchor="middle" x="25" y="25">
                {{ eqTypeName | slice:0:1 }}
              </text>
            </svg>
          </div>
          <div *ngIf="!e.equipmentType" class="equipment-type-icon-container"
               [matTooltip]="'modules.disposition.equipmentSearch.unknownEquipmentType'|translate">
            <svg height="50" width="50">
              <circle cx="25" cy="25" fill="#bfbfbf" r="20" stroke-width="0">
              </circle>
              <text dominant-baseline="central" fill="white" text-anchor="middle" x="25" y="25">
                ?
              </text>
            </svg>
          </div>

          <div class="title" fxFlex>
            <div class="name-model single-line-ellipsis">
                    <span [matTooltip]="getNameModelTooltip(e)"
                          class="single-line-ellipsis">
                      {{getEquipmentNameOrModel(e)}}
                    </span>
              <mat-icon (click)="navigateToEquipmentDispo(e.equipmentId)"
                        [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.equipm.s'|translate}">
                open_in_new
              </mat-icon>
            </div>
            <div class="serial-number single-line-ellipsis intNumberAndSerialNumberTextfield">
              <bh-equipment-status-icon
                [status]="e.status">
              </bh-equipment-status-icon>
              <span [matTooltip]="getIntNrAndSerialNumber(e)">
                  {{getIntNrAndSerialNumber(e)}}
              </span>
            </div>
            <div [matTooltip]="getProjectTooltip(e)"
                 class="project-name single-line-ellipsis">
              {{getProjectNameOrDefault(e)}}
            </div>
          </div>

        </div>
      </mat-expansion-panel-header>

      <div *ngIf="!additionalFields || additionalFields.length === 0">
        {{'modules.disposition.equipmentSearch.noTechnicalFields'|translate}}
      </div>

      <div *ngIf="additionalFields" fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="100" fxLayout="column">
          <p *ngFor="let field of additionalFields">
            <span class="t-bold-light">{{ field.additionalFieldName | translatableString }}: </span> {{ field.value }} {{ field.additionalFieldUnit }}
          </p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="equipments && equipments.length === 0" class="disposition-board-equipment-search__no_results" fxFlex
       fxLayout="row"
       fxLayoutAlign="center center">
    <div>{{'general.labels.noSearchResults'|translate}}</div>
  </div>
</div>

<div class="list-view-main__left-body-paginator" fxFlex="60px">
  <mat-paginator #paginator
                 (page)="onPaginateChange($event)"
                 [length]="dispositionStore.length | async"
                 [pageIndex]="dispositionStore.pagination.index"
                 [pageSizeOptions]="[5, 25, 50, 100]"
                 [pageSize]="dispositionStore.pagination.size">
  </mat-paginator>
</div>
