import { Address } from './address.interface';
import { PartnerTheme } from './partner-theme.enum';
import { PartnerMailServer } from './partner-mail-server.enum';
import { RealmType } from 'app/shared/contract/realm-type';

export class CreatePartnerCommand {
  partnerName: string;
  partnerLogoBase64: string;
  partnerAddress: Address;
  partnerTheme: PartnerTheme;
  partnerMailServer: PartnerMailServer;
  partnerRealm: RealmType;
}
