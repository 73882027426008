import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewManufacturer } from '../contract/view-manufacturer.interface';
import { EquipmentsService } from './equipments.service';
import { EquipmentTypesService } from './equipmenttypes.service';
import { SpecificationService } from './specification.service';
import { filterByTerm } from '../../../shared/collection-utils';
import { ViewStock } from './view-stock.interface';
import { StockService } from './stock-service';
import { OrganisationInfo } from '../contract/organisation-info.interface';

@Injectable()
export class EquipmentFieldStore {

  private allOrganisations: BehaviorSubject<OrganisationInfo[]> = new BehaviorSubject<OrganisationInfo[]>([]);
  private organisationFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allManufacturers: BehaviorSubject<ViewManufacturer[]> = new BehaviorSubject<ViewManufacturer[]>([]);
  private manufacturerFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allCostCenters: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private costCenterFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allBglCodes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private bglCodeFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allStocks: BehaviorSubject<ViewStock[]> = new BehaviorSubject<ViewStock[]>([]);
  private stockFilter: BehaviorSubject<string> = new BehaviorSubject('');

  public readonly filteredOrganisations: Observable<OrganisationInfo[]> = combineLatest([
    this.allOrganisations,
    this.organisationFilter]
  ).pipe(
    map(([organisations, filterTerm]) => filterByTerm(organisations, filterTerm, 'name')));

  public readonly filteredManufacturers: Observable<ViewManufacturer[]> = combineLatest([
    this.allManufacturers,
    this.manufacturerFilter]
  ).pipe(
    map(([manufacturers, filterTerm]) => filterByTerm(manufacturers, filterTerm, 'manufacturerName')));

  public readonly filteredCostCenters: Observable<string[]> = combineLatest([
    this.allCostCenters,
    this.costCenterFilter]
  ).pipe(
    map(([costCenters, filterTerm]) => filterByTerm(costCenters, filterTerm)));

  public filteredBglCodes: Observable<string[]> = combineLatest([
    this.allBglCodes,
    this.bglCodeFilter]
  ).pipe(
    map(([codes, filterTerm]) => filterByTerm(codes, filterTerm)));

  public filteredStocks: Observable<ViewStock[]> = combineLatest([
      this.allStocks,
      this.stockFilter]
  ).pipe(
      map(([stocks, filterTerm]) => filterByTerm(stocks, filterTerm, 'stockName')));

  constructor(private equipmentTypeService: EquipmentTypesService,
              private specificationService: SpecificationService,
              private equipmentService: EquipmentsService,
              private stockService: StockService) {
  }

  public loadAllFields(): void {
    this.reset();
    this.loadOrganisations();
    this.loadManufacturers();
    this.loadCostCenters();
    this.loadBglCodes();
    this.loadStocks();
  }

  public loadBglCodes(): void {
    this.equipmentService
      .getBGLCodes()
      .subscribe(codes => this.allBglCodes.next(codes.filter(code => code !== '')));
  }

  public loadOrganisations(): void {
    this.equipmentService.getOrganisations(0, 1000)
      .subscribe(orgas => this.allOrganisations.next(orgas.content));
  }

  public loadManufacturers(): void {
    this.specificationService.getManufacturers('stub')
      .subscribe(man => this.allManufacturers.next(man));
  }

  public loadCostCenters(): void {
    this.equipmentService.getCostCenters()
      .subscribe(res => this.allCostCenters.next(res));
  }

  public loadStocks(): void {
    this.stockService.getStocks().subscribe(res => this.allStocks.next(res));
  }

  public filterOrganisations(value: string): void {
    this.organisationFilter.next(value);
  }

  public filterManufacturers(value: string): void {
    this.manufacturerFilter.next(value);
  }

  public filterCostCenters(value: string): void {
    this.costCenterFilter.next(value);
  }

  public filterBglCodes(value: string): void {
    this.bglCodeFilter.next(value);
  }

  public filterStocks(value: string): void {
    this.stockFilter.next(value);
  }

  private reset(): void {
    this.filterOrganisations('');
    this.filterManufacturers('');
    this.filterCostCenters('');
    this.filterBglCodes('');
    this.filterStocks('');
  }
}
