import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrganisationInfo } from './organisation-info.interface';

@Injectable()
export class OrganisationInfoService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/organisations';

  constructor(private http: HttpClient) {
  }

  public getOrganisations(): Observable<OrganisationInfo[]> {
    return this.http.get<OrganisationInfo[]>(this.url);
  }
}
