<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex>
        <div class="search-bar-input" fxLayout="row">
          <input fxFlex matInput [formControl]="searchTermControl">
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px" (click)="search()">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-button
            fxFlex="40px" type="reset"
            (click)="reset()"
            [matTooltip]="'general.labels.resetSearchFilters'|translate">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <button mat-button class="search-bar-button-right"
              *ngIf="isPermissionOfCreatingTechnicalField"
              (click)="navigateToAdd()"
              [matTooltip]="'general.buttons.add'|translate">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="data-table-wrapper" fxFlex>
        <mat-table #table [dataSource]="dataSourceFields | async" class="data-table">
          <ng-container cdkColumnDef="technicalFieldKey">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.equipment.base.key'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.technicalFieldKey}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="technicalFieldName">
            <mat-header-cell *cdkHeaderCellDef>{{'general.name.s'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.technicalFieldName}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="technicalFieldUnit">
            <mat-header-cell *cdkHeaderCellDef>{{'general.units.unit'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.technicalFieldUnit}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="technicalFieldType">
            <mat-header-cell *cdkHeaderCellDef>{{'modules.equipment.technicalField.dataType'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.technicalFieldType}} </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>

          <ng-container cdkColumnDef="technicalFieldEdit">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="navigateToEdit(row.technicalFieldKey)"
                      [matTooltip]="'general.buttons.edit'|translate">
                <mat-icon>create</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
