import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EquipmentsDataSource } from '../shared/equipments.datasource';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrentTabReroute implements CanActivate {
  constructor(private router: Router,
              private equipmentStore: EquipmentsDataSource) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): false {
    this.router.navigate([state.url, this.equipmentStore.currentViewTab]);
    return false;
  }
}
