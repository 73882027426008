import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { BaseEquipmentLogOperatingHours } from '../base/base-equipment-log-operating-hours';
import { KeycloakService } from '../../../../../../core/keycloak';
import { EquipmentsService } from '../../../../shared/equipments.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { RouterHistory } from '../../../../../../shared/router-history';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-log-operating-hours',
  templateUrl: '../../landscape/equipment-log-lifecycle.component.html',
  styleUrls: ['../../landscape/equipment-log-lifecycle.component.scss']
})
export class EquipmentLogOperatingHoursComponent extends BaseEquipmentLogOperatingHours implements OnInit {

  constructor(authService: KeycloakService,
              router: Router,
              route: ActivatedRoute,
              formBuilder: UntypedFormBuilder,
              equipmentsService: EquipmentsService,
              equipmentStore: EquipmentsDataSource,
              equipmentStatusService: EquipmentCheckerService,
              routerHistory: RouterHistory,
              public dialogRef: MatDialogRef<EquipmentLogOperatingHoursComponent>,
              protected languageService: LanguageService) {
    super(authService,
      router,
      route,
      formBuilder,
      equipmentsService,
      equipmentStore,
      equipmentStatusService,
      routerHistory,
      languageService);
  }

}
