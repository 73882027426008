import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { MaintenanceTaskView } from '../../../shared/maintenance-task';

@Component({
  selector: 'bh-maintenance-task-view-detail',
  templateUrl: './maintenance-task-view-detail.component.html',
  styleUrls: ['./maintenance-task-view-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskViewDetailComponent implements OnInit {
  @Input() public task: MaintenanceTaskView;
  public readonly faInfoCircle = faInfoCircle;

  public ngOnInit(): void {
  }
}
