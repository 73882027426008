import { environment } from 'environments/environment';
import { LatLon } from '../../../../shared/geolocation/lat-lon.interface';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StockType } from '../../../../shared/enums/stock-type';
import { combineLatest, Observable, of, EMPTY } from 'rxjs';
import { delay, map, startWith, debounceTime, switchMap, filter, finalize } from 'rxjs/operators';
import { StockTypeResolver } from '../../../../shared/pipes/stock-type.resolver';
import { ViewStock } from '../../contract/view-stock';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateStockCommand } from '../../contract/create-stock.command';
import { StockService } from '../../shared/services/stock.service';
import { faWarehouseAlt } from '@fortawesome/pro-duotone-svg-icons';
import { UpdateStockCommand } from '../../contract/update-stock.command';
import { Address } from '../../../organisation/contract/address.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBuilding, faFileInvoiceDollar, faTag } from '@fortawesome/pro-light-svg-icons';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AddressLocation } from 'app/modules/organisation/contract/address-location';
import { MapLoaderService } from 'app/shared/modules/map/services/map-loader.service';
import { AddressLocationValidator } from 'app/shared/custom-validators/address-location.validator'
import * as _ from 'lodash';
import { OrganisationInfoService } from '../../shared/services/organisation-info.service';
import { OrganisationInfo } from '../../shared/services/organisation-info.interface';
import { RetryGeocoder } from 'app/shared/geolocation/retry-geocoder';
import { RetryGeocoderStatus } from 'app/shared/geolocation/retry-geocoder-status.enum';
import {StockStore} from '../shared/stock.store';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';
import { KeycloakService } from '../../../../core/keycloak';
import { RoleAuthorityGuardsComponent } from '../../../../shared/navigation-guards/role-authority-guards.component';
import { Modules } from '../../../../shared/enums/modules.enum';
import { Authorities } from '../../../../shared/enums/authorities.enum';


@UntilDestroy()
@Component({
  selector: 'bh-stock-add-edit',
  templateUrl: './stock-add-edit.component.html',
  styleUrls: ['./stock-add-edit.component.scss']
})
export class StockAddEditComponent extends RoleAuthorityGuardsComponent implements OnInit {
  private isManualAddressInput = true;

  public stockForm: UntypedFormGroup;
  public isStockGeofence = false;
  public stockTypes: string[] = [];
  public organisations: Observable<OrganisationInfo[]>;
  public filterControl: UntypedFormControl;
  public stock: ViewStock;

  public readonly faWarehouseAlt = faWarehouseAlt;
  public readonly faTag: IconDefinition = faTag;
  public readonly faBuilding: IconDefinition = faBuilding;
  public readonly faFileInvoiceDollar: IconDefinition = faFileInvoiceDollar;
  public enteredAddress: Observable<Address>;
  public stockLocation: LatLon;
  public geocoderStatus: RetryGeocoderStatus;
  public saveInProgress = false;

  public readonly fieldLimit = FieldLimit;

  constructor(private dialogRef: MatDialogRef<StockAddEditComponent>,
              private formBuilder: UntypedFormBuilder,
              private userConfigurationService: UserConfigurationService,
              protected authService: KeycloakService,
              private organisationsService: OrganisationInfoService,
              public stockTypeResolver: StockTypeResolver,
              private stockService: StockService,
              private cdr: ChangeDetectorRef,
              private mapLoaderService: MapLoaderService,
              private stockStore: StockStore) {
                super(authService);
  }

  public ngOnInit(): void {
    if (this.stock && this.stock.stockType === StockType.MAIN) {
      this.stockTypes = Object.keys(StockType);
    } else {
      this.stockTypes = Object.keys(StockType).filter(key => key !== StockType.MAIN);
    }

    this.filterControl = new UntypedFormControl();
    this.buildForm();

    this.stockForm.get('type').valueChanges.pipe(untilDestroyed(this)).subscribe(type => {
      if (type !== StockType.STOCK && type !== StockType.POSITION && type !== StockType.MAIN) {
        this.address.disable();
      } else {
        this.address.enable();
      }
    });

    if (this.stock) {
      this.patchForm();
    }
    this.organisations = combineLatest(
        this.organisationsService.getOrganisations(),
        this.filterControl.valueChanges.pipe(startWith('')))
    .pipe(
      map(([organisations, filterTerm]) =>
        organisations.filter(org => org.name.toLowerCase().includes(filterTerm.toLowerCase()))));
    this.initAddressChangeListener();

    this.mapLoaderService.isLoaded
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.address.setAsyncValidators(AddressLocationValidator(new RetryGeocoder(new google.maps.Geocoder())));
        this.address.updateValueAndValidity({ emitEvent: false });
      });
  }

  private initAddressChangeListener(): void {

    this.stockForm.statusChanges.pipe(
      filter(status => status !== 'PENDING'),
      untilDestroyed(this)
    ).subscribe(v => this.cdr.detectChanges());

    this.enteredAddress = this.address.valueChanges.pipe(debounceTime(500));
    this.enteredAddress.pipe(
      debounceTime(500),
      switchMap(value => this.isManualAddressInput ? of(value) : this.switchToManualEntry()),
      untilDestroyed(this)
    ).subscribe((value: AddressLocation) => this.clearLocation(value));
  }

  private switchToManualEntry(): Observable<never> {
    this.isManualAddressInput = true;
    return EMPTY;
  }

  private clearLocation({ location }: AddressLocation): void {
    if (this.locationExists(location)) {
      this.address.patchValue({ location: { lat: null, lon: null } });
      this.cdr.detectChanges();
    }
  }

  public get address(): AbstractControl | null {
    return this.stockForm.get('address');
  }

  public get street(): AbstractControl | null {
    return this.address.get('street');
  }

  public get streetNumber(): AbstractControl | null {
    return this.address.get('streetNumber');
  }

  public get city(): AbstractControl | null {
    return this.address.get('city');
  }

  public get postalCode(): AbstractControl | null {
    return this.address.get('postalCode');
  }

  public get name(): AbstractControl | null {
    return this.stockForm.get('data').get('name');
  }

  public get costCenter(): AbstractControl | null {
    return this.stockForm.get('data').get('costCenter');
  }

  public get organisation(): AbstractControl | null {
    return this.stockForm.get('data').get('organisation');
  }

  public save(): void {
    if (this.isValid()) {
      const formValue = this.stockForm.getRawValue();
      let stockAddressLocation: AddressLocation = new AddressLocation({
        street: null,
        streetNumber: null,
        postalCode: null,
        city: null
      });
      if (formValue.type === StockType.POSITION || formValue.type === StockType.STOCK || formValue.type === StockType.MAIN) {
        stockAddressLocation = new AddressLocation(_.pick(formValue.address, ['street', 'streetNumber', 'postalCode', 'city']));
        stockAddressLocation.setLocation(formValue.address.location.lat, formValue.address.location.lon);
      }

      if (!this.stock) {
        const command: CreateStockCommand = {
          stockName: formValue.data.name,
          organisationId: formValue.data.organisation,
          stockAddress: stockAddressLocation.address,
          stockCostCenter: formValue.data.costCenter,
          stockType: formValue.type,
          showInDispositionBoard: formValue.data.displayInDispositionBoard,
          createGeofence: this.authService.hasModule(Modules.AUTOMATED_TRANSFERS)
                          && this.authService.hasAuthority(Authorities.GEOFENCE_MANAGE)
                          && !this.noLocationType()
                          && this.isStockGeofence,
          location: stockAddressLocation.locationExists() ? stockAddressLocation.location : null
        };

        this.saveInProgress = true;
        this.stockStore.createStock(command, (id: string) => {
          this.dialogRef.close(dialogResults.SAVE);
          this.saveInProgress = false;
        });
      } else {
        const command: UpdateStockCommand = {
          stockId: this.stock.stockId,
          stockName: formValue.data.name,
          organisationId: formValue.data.organisation,
          stockAddress: stockAddressLocation.address,
          stockCostCenter: formValue.data.costCenter,
          stockType: formValue.type,
          showInDispositionBoard: formValue.data.displayInDispositionBoard,
          location: stockAddressLocation.locationExists() ? stockAddressLocation.location : null
        };

        this.saveInProgress = true;
        this.stockService.updateStock(command)
          .pipe(
            delay(environment.DELAY_LONGEST),
            finalize(() => this.saveInProgress = false),
            untilDestroyed(this))
          .subscribe(() => {
            this.stockStore.update();
            this.dialogRef.close();
          });
      }
    }
  }

  public isValid(): boolean {
    return this.stockForm.valid && !this.stockForm.pending && !(this.geocoderStatus === RetryGeocoderStatus.PENDING);
  }

  public noLocationType(): boolean {
    const type = this.stockForm.get('type').value;
    return type !== StockType.STOCK && type !== StockType.POSITION && type !== StockType.MAIN;
  }

  public isMainStock(): boolean {
    return this.stock?.stockType === StockType.MAIN;
  }

  private buildForm() {
    this.stockForm = this.formBuilder.group({
      type: ['', Validators.required],
      data: this.formBuilder.group({
        name: ['', [Validators.maxLength(FieldLimit.NAME), Validators.required]],
        costCenter: ['', Validators.maxLength(FieldLimit.COST_CENTER)],
        displayInDispositionBoard: [''],
        organisation: [
          { value: '', disabled: this.isMainStock() },
          this.isMainStock() ? null : Validators.required,
        ]
      }),
      address: this.formBuilder.group({
        street: ['', Validators.maxLength(FieldLimit.STREET)],
        streetNumber: ['', Validators.maxLength(FieldLimit.STREET_NUMBER)],
        postalCode: ['', Validators.maxLength(FieldLimit.POSTAL_CODE)],
        city: ['', Validators.maxLength(FieldLimit.CITY)],
        stockGeofenceCreation: this.authService.hasModule(Modules.AUTOMATED_TRANSFERS)
                                && this.authService.hasAuthority(Authorities.GEOFENCE_MANAGE)
                                && this.userConfigurationService.userConfiguration.stockAddConfiguration.createGeofence,
        location: this.formBuilder.group({
          lat: [null],
          lon: [null]
        })
      })
    });

    if (this.stock) {
      this.isStockGeofence = this.authService.hasModule(Modules.AUTOMATED_TRANSFERS) &&
                              this.authService.hasAuthority(Authorities.GEOFENCE_MANAGE) &&
                              this.userConfigurationService.userConfiguration.stockAddConfiguration.createGeofence;
    } else {
      this.isStockGeofence = false;
    }
  }

  private patchForm(): void {
    this.stockForm.patchValue({
      type: this.stock.stockType,
      data: {
        name: this.stock.stockName,
        costCenter: this.stock.stockCostCenter,
        displayInDispositionBoard: this.stock.showInDispositionBoard,
        organisation: this.stock.organisationId
      }
    });

    if (this.stock.stockAddress) {
      this.stockForm.patchValue({
        address: {
          street: this.stock.stockAddress.street,
          streetNumber: this.stock.stockAddress.streetNumber,
          postalCode: this.stock.stockAddress.postalCode,
          city: this.stock.stockAddress.city
        }
      });
    }

    if (this.locationExists(this.stock.location)) {
      this.stockLocation = { ...this.stock.location };
      this.stockForm.patchValue({
        address: {
          location: { ...this.stock.location }
        }
      });
    }
  }

  private locationExists(location: any): boolean {
    return location && Number.isFinite(location.lat) && Number.isFinite(location.lon);
  }

  public updateAddress(addressLocation: AddressLocation): void {
    this.isManualAddressInput = false;
    this.address.patchValue(addressLocation, { emitEvent: false });
    this.address.markAllAsTouched();
  }

  public changeGeofenceStatus(): void {
    this.isStockGeofence = !this.isStockGeofence;
    this.userConfigurationService.saveStockAddConfiguration(this.isStockGeofence);
  }
}
