import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';

export interface MapLocationsPayload<LocType = LatLonLocation> {
  locations: LocType[],
  centerMap: boolean;
}

export const EMPTY_MAP_LOCATIONS_PAYLOAD: MapLocationsPayload<any> = {
  centerMap: false,
  locations: []
}
