import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TelematicsUnitType } from '../../equipment/contract/telematics-unit-type.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PartnersService } from '../../organisation/shared/partners.service';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { LanguageService } from '../../../shared/services/language.service';
import { TelematicUnitAdministrationDatasource } from '../services/telematic-unit-administration.datasource';
import { TelematicUnitsAdministrationResolver } from '../shared/telematic-units-administration.resolver';
import { PartnerInfo } from '../shared/partner-info.interface';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import {
  CreateCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/create-customer-telematic-unit-command';
import {
  TelematicUnitsAdministrationBulkImportResultComponent
} from '../telematic-units-administration-bulk-import-result/telematic-units-administration-bulk-import-result.component';

@Component({
  selector: 'bh-telematic-units-administration-bulk-import',
  templateUrl: './telematic-units-administration-bulk-import.component.html',
  styleUrls: ['./telematic-units-administration-bulk-import.component.scss']
})
export class TelematicUnitsAdministrationBulkImportComponent implements OnInit, OnDestroy {

  public form: UntypedFormGroup;
  public errorMessage: string;
  public partners: Observable<PartnerInfo[]> = this.telematicUnitStore.filteredPartners;
  public csvDataArray: any[] = [];
  public uploadSuccessfull = false;
  public csvHeaderArray: string[] = [];
  public telematicsUnitTypes = [TelematicsUnitType.TELTONIKA_CABLE_UNIT, TelematicsUnitType.TELTONIKA_OBD_UNIT,
  TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT];
  public filteredtTelematicsUnitTypes: Observable<TelematicsUnitType[]>;
  public defaultHeadersList: string[] = ['Box No.', 'Box No.', 'S/N', 'IMEI', 'MAC', 'IccId', 'Info A'];
  public simCardProvidersList: Observable<string[]> = this.telematicUnitStore.filteredSimCardProviders;
  public partnersFilter: UntypedFormControl = new UntypedFormControl();
  public unitTypeFilter: UntypedFormControl = new UntypedFormControl();

  constructor(protected formBuilder: UntypedFormBuilder,
              private partnersService: PartnersService,
              private languageService: LanguageService,
              private telematicUnitStore: TelematicUnitAdministrationDatasource,
              private telematicUnitResolver: TelematicUnitsAdministrationResolver,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<TelematicUnitsAdministrationBulkImportComponent>
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.telematicUnitStore.loadAllFields();
    this.filterSimCardProvidersOnInput();
    this.filterPartnersOnInput();
    this.filteredtTelematicsUnitTypes = this.filterUnitTypeOnInput();
  }

  ngOnDestroy(): void { }

  protected buildForm(): void {
    this.form = this.formBuilder.group({
      typeId: ['', Validators.required],
      partnerId: [''],
      simCardProvider: ['']
    });
  }

  public get typeId(): AbstractControl {
    return this.form.get('typeId');
  }

  public get partnerId(): AbstractControl {
    return this.form.get('partnerId');
  }

  public get simCardProvider(): AbstractControl {
    return this.form.get('simCardProvider');
  }

  private filterSimCardProvidersOnInput(): void {
    this.simCardProvider
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.telematicUnitStore.filterSimCardProvider(filterTerm));
  }

  private filterPartnersOnInput(): void {
    this.partnersFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.telematicUnitStore.filterPartners(filterTerm));
  }

  private filterUnitTypeOnInput() {
    return this.unitTypeFilter
    .valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterUnitTypes(value)));
  }

  private filterUnitTypes(value: string): TelematicsUnitType[] {
    const filterValue = value.toLowerCase();

    return this.telematicsUnitTypes.filter(option => option.toLowerCase().includes(filterValue));
  }

  onFileUpload(event): void {
    this.csvHeaderArray = []
    this.csvDataArray = []
    let file = event.target.files[0];

    if (file !== undefined && this.isValidCsv(file)) {
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        let csvData = reader.result;
        this.processCSVData(csvData);

        let isValidHeaders = this.isValidHeaders();
        let isValidCSVData = this.isValidCSVData();

        if (isValidHeaders && isValidCSVData) {
          this.uploadSuccessfull = true;
        } else {
          this.uploadSuccessfull = false;
          this.errorMessage = '';

          if (!isValidHeaders) {// Headers do not match
            this.errorMessage += this.translate('shared.validation.telematic.invalidHeaders');
          }
          if (!isValidCSVData) {// Duplicate IMEI found
            this.errorMessage += (this.errorMessage)
              ? '<br />' + this.translate('shared.validation.telematic.duplicateImeiFound')
              : this.translate('shared.validation.telematic.duplicateImeiFound');
          }
        }
      };
    } else {
      this.uploadSuccessfull = false;
      this.errorMessage = this.translate('shared.validation.telematic.invalidFileFormat');
    }
  }

  processCSVData(csvData: any): void {
    csvData = (<string>csvData).split(/\r\n|\n/);

    this.csvHeaderArray = (<string>csvData[0]).split(';');
    let emptyRow = Array(this.csvHeaderArray.length).fill('').toString();

    for (let i = 1; i < csvData.length - 1; i += 1) {
      let row: string[] = (<string>csvData[i]).split(';');
      if (this.csvHeaderArray.length === row.length && emptyRow !== row.toString()) { // Handle empty record in CSV.
        this.csvDataArray.push(row);
      }
    }
  }

  get abort(): string {
    return dialogResults.ABORT;
  }

  isValidCsv(file: any): boolean {
    return file.name.toLowerCase().endsWith('.csv');
  }

  isValidHeaders(): boolean {
    return (this.defaultHeadersList[0].toLowerCase() === this.csvHeaderArray[0].toLowerCase()
    && this.defaultHeadersList[1].toLowerCase() === this.csvHeaderArray[1].toLowerCase()
    && this.defaultHeadersList[2].toLowerCase() === this.csvHeaderArray[2].toLowerCase()
    && this.defaultHeadersList[3].toLowerCase() === this.csvHeaderArray[3].toLowerCase()
    && this.defaultHeadersList[4].toLowerCase() === this.csvHeaderArray[4].toLowerCase()
    && this.defaultHeadersList[5].toLowerCase() === this.csvHeaderArray[5].toLowerCase()
    && this.defaultHeadersList[6].toLowerCase() === this.csvHeaderArray[6].toLowerCase());
  }

  isValidCSVData(): boolean {
    let arr = []
    this.csvDataArray.forEach(data => arr.push(data[this.defaultHeadersList.indexOf('IMEI')]))
    return arr.length === new Set(arr).size;
  }

  public isValid(): boolean {
    return this.uploadSuccessfull && this.form.valid;
  }

  public reset(): void {
    this.form.reset();
  }

  public save(): void {
    if (this.isValid()) {
      this.createBulkImport();
    }
  }

  private createBulkImport(): void {
    let formValue = this.form.getRawValue();

    let customerData: CreateCustomerTelematicUnitCommand[] = [];
    for (let arr of this.csvDataArray) {
      let telematicsUnitType = formValue.typeId;
      let partnerId = formValue.partnerId === '' ? undefined : formValue.partnerId;
      let simCardProvider = formValue.simCardProvider;
      let boxNo = arr[0] + arr[1];
      let serialNumber = arr[2];
      let imei = arr[3];
      let mac = arr[4];
      let eSim1IccId = arr[5];
      let gsmNumber = arr[6];
      let eSim1Imsi = arr[7];

      customerData.push(new CreateCustomerTelematicUnitCommand(
        telematicsUnitType,
        imei,
        boxNo,
        serialNumber,
        mac,
        partnerId,
        simCardProvider,
        eSim1IccId,
        eSim1Imsi,
        gsmNumber
      ));
    }

    this.telematicUnitStore.customerBulkImport(customerData)
      .subscribe((result) => {
        if (!!result && result.length > 0) {
          this.dialog.open(TelematicUnitsAdministrationBulkImportResultComponent, {
            width: '800px',
            disableClose: true,
            data: { result: result },
          }).afterClosed().subscribe();
        }

        this.dialogRef.close(dialogResults.SAVE)
      });
  }

  public resolveName(option: string) {
    return this.telematicUnitResolver.resolveName(option);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
