export enum ListType {
  EQUIPMENTS = 'EQUIPMENTS',
  BULK_ITEMS = 'BULK_ITEMS',
  STOCKS = 'STOCKS',
  PROJECTS = 'PROJECTS',
  EMPLOYEES = 'EMPLOYEES',
  EQUIPMENT_TRANSFER = 'EQUIPMENT_TRANSFER',
  BULK_ITEM_TRANSFER = 'BULK_ITEM_TRANSFER',
  CONTACTS = 'CONTACTS',
  TRANSPORTATION_TASKS = 'TRANSPORTATION_TASKS',
  MAINTENANCE_TASKS_CURRENT = 'MAINTENANCE_TASKS_CURRENT',
  MAINTENANCE_TASKS_COMPLETED = 'MAINTENANCE_TASKS_COMPLETED',
  ANALYTICS_TRANSFER_LOG = 'ANALYTICS_TRANSFER_LOG',
  ANALYTICS_USAGE_HISTORY = 'ANALYTICS_USAGE_HISTORY',
  NONE = 'NONE'
}
