import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[bhNumberField]',
})
export class NumberFieldDirective {

  // Allow decimal numbers. The \. is only allowed once to occur
  private regex = new RegExp(/^[0-9]+(\.[0-9]*)?$/g);

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete'];
  private shiftSpecialKeys: Array<string> = ['ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (!this.isSpecialKeyCombinations(event)) {
      let current: string = this.el.nativeElement.value;
      let next: string = current.concat(event.key);
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text/plain');

    // Get the current cursor position
    const cursorStart: number = this.el.nativeElement.selectionStart || 0;
    const cursorEnd: number = this.el.nativeElement.selectionEnd || 0;

    // Remove the selected portion from the existing value
    const current: string = this.el.nativeElement.value;
    const next: string = current.slice(0, cursorStart) + pastedText + current.slice(cursorEnd);

    if (!String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  private isSpecialKeyCombinations(event: KeyboardEvent): boolean {
    return event.ctrlKey
      || (event.shiftKey && this.shiftSpecialKeys.indexOf(event.key) !== -1)
      || (this.specialKeys.indexOf(event.key) !== -1);
  }
}
