import { MatSort } from '@angular/material/sort';

export class MatSortController {
  private lastState: boolean;

  constructor(private matSort: MatSort) {}

  public off(): void {
    this.lastState = this.matSort.disabled;
    this.matSort.disabled = true;
  }

  public on(): void {
    this.matSort.disabled = this.lastState;
  }
}
