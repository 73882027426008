import { MaintenanceCategory } from '../../../../../../shared/enums/maintenance-category.enum';
import { FixedDateInterval } from '../intervals/fixed-date-interval';
import { PeriodInterval } from '../intervals/period-interval';
import { MileageInterval } from '../intervals/mileage-interval';
import { OperatingHoursInterval } from '../intervals/operating-hours-interval';

export class CreateMaintenanceRuleCommand {
  constructor(
      public maintenanceTypeId: string,
      public articleGroup: string,
      public maintenanceCategory: MaintenanceCategory,
      public ruleName: string,
      public ruleDescription: string,
      public taskLimit: number,
      public fixedDateInterval: FixedDateInterval,
      public periodInterval: PeriodInterval,
      public mileageInterval: MileageInterval,
      public operatingHoursInterval: OperatingHoursInterval
      ) {}
}
