import { EquipmentTelematicsDataseriesItem } from 'app/modules/equipment/contract/equipment-telematics-dataseries-item';
import { ChartDataTransformStrategy } from '../chart-data-transform-strategy.interface';
import { ChartDataset } from 'chart.js';
import { Utils } from 'app/shared/utils';
import _ from 'lodash';
import { TransformStrategyOptions } from './transform-strategy-options.interface';

export class SingleSeriesRatioTransformStrategy implements ChartDataTransformStrategy<EquipmentTelematicsDataseriesItem> {
  constructor(private options: TransformStrategyOptions) {}

  public isValidData(data: EquipmentTelematicsDataseriesItem): boolean {
    return data?.series?.length > 0;
  }

  public getChartDataLabels(data: EquipmentTelematicsDataseriesItem): string[] {
    if (this.isValidData(data)) {
      const firstLabel = this.options?.labels?.[0] ?? data?.series?.[0]?.name;
      const secondLabel = this.options?.labels?.[1] ?? data?.series?.[1]?.name;
      return [firstLabel || '', secondLabel || ''];
    }
    return [];
  }

  public getChartDatasets(data: EquipmentTelematicsDataseriesItem): ChartDataset[] {
    const colors = this.options?.dataset?.[0]?.color;
    return this.isValidData(data)
    ? [{
        data: data?.series?.slice(0, 2).map(item => Utils.round(item?.value, 1)) || [],
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
      }]
    : [];
  }

}
