import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressStreet'
})
export class AddressStreetPipe implements PipeTransform {

  transform(address: any, args?: any): any {
    if (!address || (!address.street && !address.streetNumber)) {
      return '';
    }
    const streetRow = [];
    if (address.street) {
      streetRow.push(address.street);
    }
    if (address.streetNumber) {
      streetRow.push(address.streetNumber);
    }
    return streetRow.join(' ');
  }

}
