import { LifeCycleType } from './../../contract/lifecycle-type.enum';
import { LanguageService } from './../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timelineEventTypePipe'
})
export class TimelineEventTypesPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(type: LifeCycleType, isMobile = false): string {
    switch (type) {
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        return this.translate('general.maliciousDamage.s');
      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return `${this.translate('general.invoice.s')}`;
      case LifeCycleType.OPERATING_HOURS_UPDATE:
        return this.translate(!isMobile
          ? 'shared.pipes.lifeCycleTitle.manualOperatingHours'
          : 'general.operatingHour.pl');
      case LifeCycleType.MILEAGE_UPDATE:
        return this.translate(!isMobile
          ? 'shared.pipes.lifeCycleTitle.manualMileage'
          : 'general.mileage');
      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        return this.translate('general.incident.s');
      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        return this.translate('shared.pipes.lifeCycleTitle.completedInspection')
          + ' / '
          + this.translate('shared.pipes.lifeCycleTitle.completedMaintenance');
      case LifeCycleType.COMPLETED_REMINDER:
        return this.translate('general.reminder');
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        return this.translate('shared.pipes.lifeCycleTitle.employeeAssignmentAdded');
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        return this.translate('shared.pipes.lifeCycleTitle.employeeAssignmentReplaced');
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        return this.translate('shared.pipes.lifeCycleTitle.employeeAssignmentRemoved');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
