<bh-step-dialog
  class="project-dialog"
  [icon]="faMapMarkedAlt"
  [title]="(project
    ? 'modules.disposition.projectAddEdit.projectEdit'
    : 'modules.disposition.projectAddEdit.createNewProject')|translate">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="projectForm">
      <mat-horizontal-stepper
        linear
        labelPosition="bottom"
        fxFlex="100"
        fxLayout="column">

        <!-- GENERAL DATA -->
        <mat-step [stepControl]="generalDataControl" [formGroup]="$any(generalDataControl)">
          <ng-template matStepLabel>{{'general.generalData'|translate}}*</ng-template>
          <div class="stepper-content" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="main-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="85">
              <div class="main-container__content-padding" fxLayout="row" fxLayoutAlign="space-around start">
                <div class="entity-creation-form-left border-right">
                  <bh-text-input [control]="$any(projectName)"
                                 [label]="'modules.disposition.projectAddEdit.projectName'|translate">
                  </bh-text-input>
                  <bh-date-range-select
                    flexOrientation="'column'"
                    [startDateControl]="$any(projectStart)"
                    [endDateControl]="$any(projectEnd)"
                    [minEndDate]="getFormattedMinEndDate()"
                    [startDateDisabledFlagToDisablingReasonMessageTuple]=
                      "[projectHasActiveTransfers(), 'general.labels.startDateDisabledDueToTransfers']"
                    (onDateChange)="updateValidation()"
                    ngDefaultControl>
                  </bh-date-range-select>

                  <p class="mat-error" *ngIf="projectStart.hasError('conflicts')">
                    {{projectStart.getError('conflicts')}}
                  </p>

                  <mat-form-field *ngIf="!project">
                    <mat-label>{{'general.org.s'|translate}}</mat-label>
                    <mat-select required
                                disableOptionCentering="true"
                                formControlName="organisationId">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="organisationFilter"
                                              [placeholderLabel]="'general.labels.search'|translate:{ value: ('general.org.s'|translate) }"
                                              [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('general.org.pl'|translate) }"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let o of organisations | async" [value]="o.organisationId">
                        {{o.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="organisationId.hasError('required')">
                      {{'shared.validation.required'|translate:{value: 'general.org.s'|translate} }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{'general.type'|translate}}</mat-label>
                    <mat-select required
                                formControlName="projectType"
                                [disableOptionCentering]="true"
                                [matTooltipClass]="'mat-tooltip-multiline'"
                                matTooltipPosition="after"
                                matTooltip="
                                {{'modules.disposition.projectAddEdit.projectTypeTooltip.info'|translate}}&#13;
                                {{'modules.disposition.projectAddEdit.projectTypeTooltip.fix'|translate}}&#13;
                                {{'modules.disposition.projectAddEdit.projectTypeTooltip.mobile'|translate}}&#13;
                                {{'modules.disposition.projectAddEdit.projectTypeTooltip.mobileGeo'|translate}}">
                      <mat-option value="Fix">{{'modules.disposition.projectAddEdit.fix'|translate}}</mat-option>
                      <mat-option value="Mobil">{{'modules.disposition.projectAddEdit.mobile'|translate}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="projectType.hasError('required')">
                      {{'shared.validation.required'|translate:{value: 'general.type'|translate} }}
                    </mat-error>
                  </mat-form-field>

                  <bh-text-input [control]="$any(projectCostCenter)"
                                 [label]="'general.costCenter'|translate">
                  </bh-text-input>
                </div>
              </div>
            </div>
            <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
              <button mat-raised-button matStepperNext type="button" [disabled]="generalDataControl.invalid">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>

        <!-- ADDRESS -->
        <mat-step [stepControl]="addressControl" [formGroup]="$any(addressControl)">
          <ng-template matStepLabel>{{'general.address.address'|translate}}*</ng-template>
          <div class="stepper-content" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div class="main-container" fxLayout="row" fxLayoutAlign="space-around stretch" fxFlex="85">
              <div fxFlex="45" fxLayout="column">
                <bh-map-location-select
                  fxFlex="100"
                  [geofence]="projectGeofence"
                  [mandatory]="false"
                  [address]="enteredAddress"
                  [location]="project?.location"
                  (addressChange)="updateAddress($event)"
                  (geocoderStatus)="geocoderStatus = $event"
                  data-osp-test="map-location-select">
                </bh-map-location-select>
              </div>
              <div class="input-container"fxFlex="45" fxLayout="column">
                <bh-text-input [control]="$any(projectStreet)"
                               [label]="'general.address.street'|translate">
                </bh-text-input>

                <bh-text-input [control]="$any(projectStreetNumber)"
                               [label]="'general.address.number'|translate">
                </bh-text-input>

                <bh-text-input [control]="$any(projectPostalCode)"
                               [label]="'general.address.zipcode'|translate">
                </bh-text-input>

                <bh-text-input [control]="$any(projectCity)"
                               [label]="'general.address.city'|translate">
                </bh-text-input>

                <div [formGroup]="$any(addressLocationControl)">
                  <mat-form-field>
                    <mat-label>{{'general.location.latitude'|translate}}</mat-label>
                    <input
                      matInput
                      type="number"
                      [min]="geoLocationLimit.LAT_MIN"
                      [max]="geoLocationLimit.LAT_MAX"
                      [step]="geoLocationChangeStep"
                      formControlName="lat"
                      [maxlength]="fieldLimit.GEO_COORDINATE"
                      autocomplete="off">
                    <mat-error *ngIf="latitudeControl.hasError('min') || latitudeControl.hasError('max')">
                      {{'shared.validation.range'|translate:
                        {minValue: geoLocationLimit.LAT_MIN, maxValue: geoLocationLimit.LAT_MAX}
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'general.location.longitude'|translate}}</mat-label>
                    <input
                      matInput
                      type="number"
                      [min]="geoLocationLimit.LON_MIN"
                      [max]="geoLocationLimit.LON_MAX"
                      [step]="geoLocationChangeStep"
                      formControlName="lon"
                      [maxlength]="fieldLimit.GEO_COORDINATE"
                      autocomplete="off">
                    <mat-error *ngIf="longitudeControl.hasError('min') || longitudeControl.hasError('max')">
                      {{'shared.validation.range'|translate:
                        {minValue: geoLocationLimit.LON_MIN, maxValue: geoLocationLimit.LON_MAX}
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="error" *ngIf="addressControl.hasError('notResolved') && addressControl.dirty">
                  {{'modules.contact.contactAddEdit.addressCannotBeResolved'|translate}}
                </div>

                <ng-container *ngIf="showGeofenceCheckbox()">
                  <mat-checkbox color="primary" *ngIf="hasAuthority(authorities.GEOFENCE_MANAGE)"
                                formControlName="projectGeofenceCreation"
                                [matTooltip]="'modules.disposition.projectAddEdit.selectedGeoDiameter200m'|translate">
                    {{'modules.disposition.projectAddEdit.autoGeo'|translate}}
                  </mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div class="button-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <button
                mat-raised-button type="button"
                color="primary"
                (click)="save()"
                [disabled]="!isValid() || addressControl.pristine || saveInProgress"
                data-osp-test="button-save">
                {{'general.buttons.save'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>
