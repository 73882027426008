<div class="disposition-board-search" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
  <mat-card fxFlex fxLayout="column" fxLayoutAlign="start stretch">
    <bh-horizontal-tab-select [optionResolver]="optionResolver"
                              [options]="getViews()"
                              (optionSelectionChanged)="changeAssetSelection($event)">
    </bh-horizontal-tab-select>

    <div class="disposition-board-asset-search" fxFlex fxLayout="column" fxLayoutAlign="start stretch"
         *ngIf="selectedAsset === listType.NONE || selectedAsset === listType.EQUIPMENTS">
      <bh-disposition-board-search-equipment fxFlex fxLayout="column"></bh-disposition-board-search-equipment>
    </div>

    <div class="disposition-board-asset-search" fxFlex fxLayout="column" fxLayoutAlign="start stretch"
         *ngIf="selectedAsset === listType.EMPLOYEES">
      <bh-disposition-board-search-employee fxFlex fxLayout="column"></bh-disposition-board-search-employee>
    </div>
  </mat-card>
</div>
