import * as moment from 'moment';

const isDate = date => date && !isNaN(date.getTime());

export class DateUtils {

  public static getGmt0DateInMilliseconds(date: Date): number {
    return date !== null ? new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000 : null;
  }

  public static endDateOrDefault(date: Date): Date {
    if (date) {
      return moment(date).endOf('day').toDate();
    }
    return moment().add(100, 'years').endOf('day').toDate();
  }

  public static minDate(...dates: Array<Date | undefined | null>): Date | null {
    const validDatesAsMilliseconds = this.getValidDatesInMilliseconds(dates);
    if (0 === validDatesAsMilliseconds.length) {
      return null;
    }
    return dates
      .filter(isDate)
      .find(date => date.getTime() === Math.min(...validDatesAsMilliseconds));
  }

  public static maxDate(...dates: Array<Date | undefined | null>): Date | null {
    const validDatesAsMilliseconds = this.getValidDatesInMilliseconds(dates);
    if (0 === validDatesAsMilliseconds.length) {
      return null;
    }
    return dates
      .filter(isDate)
      .find(date => date.getTime() === Math.max(...validDatesAsMilliseconds));
  }

  public static isBetween(dateToCheck: Date, start: Date, end: Date): boolean {
    if (dateToCheck && start) {
      return !end || (moment(dateToCheck).isAfter(moment(start)));
    }
    return false;
  }

  private static getValidDatesInMilliseconds(dates: Array<Date | undefined | null>): number[] {
    return dates
      .filter(isDate)
      .map(date => date.getTime());
  }
}
