export enum MaintenanceCompletedFilterType {
  ARTICLE_GROUPS = 'articleGroups',
  LABEL = 'equipmentLabels',
  ORGANISATION = 'organisations',
  MAINTENANCE_TYPES = 'maintenanceTypes',
  TYPES = 'equipmentTypes',
  RESPONSIBLE_PERSON = 'employees',
  EQUIPMENT_STATUS = 'equipmentStatuses',
  MAINTENANCE_CATEGORY = 'maintenanceCategories',
  MAINTENANCE_RESULTS = 'maintenanceResults',
  ONLY_LATEST_COMPLETED_FOR_RULE = 'onlyLatestCompletedForRule',
  MATCH_ALL_LABELS = 'matchAllLabels',
}
