import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceContractType } from '../../contract/insurance-contract-type.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'insuranceContractType'
})
export class InsuranceContractTypePipe implements PipeTransform {
  private readonly translationKeys = {
    [InsuranceContractType.CASUALTY]: 'shared.pipes.insuranceContractType.casualty',
    [InsuranceContractType.PARTIALLY_COMPREHENSIVE]: 'shared.pipes.insuranceContractType.partiallyComprehensive',
    [InsuranceContractType.FULLY_COMPREHENSIVE]: 'shared.pipes.insuranceContractType.fullyComprehensive'
  };

  constructor(private languageService: LanguageService) {
  }

  public transform(value: any, args?: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
