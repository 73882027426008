<div class="stocks" fxFlexFill
     fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="stocks-header">
    <bh-title [text]="'modules.disposition.base.inventory.pl'|translate" fxFlex class="title-header"></bh-title>
    <button mat-raised-button *ngIf="hasAuthority(authorities.STOCK_MANAGE) && editVisible" (click)="openAmountDialog()"
            id="edit-button" class="edit-button" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms" (selectedTabChange)="changeTab($event)">
      <mat-tab [label]="'general.stock.s'|translate">
        <bh-bulk-item-stocks fxFlex
                             [amounts]="amounts"
                             (updatedAmount)="getStockAmounts()" type="bulk-item"></bh-bulk-item-stocks>
      </mat-tab>
      <mat-tab [label]="'general.project.pl'|translate">
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
            <table mat-table class="stocks-projects__table"
                   [dataSource]="projectAmounts"
                   *ngIf="projectAmounts.length > 0; else noAssignments">

              <ng-container matColumnDef="projectData">
                <th mat-header-cell *matHeaderCellDef>{{'general.project.s'|translate}}</th>
                <td mat-cell class="data-cell name-cell" *matCellDef="let projectAmount">
                  <div fxLayout="row" fxLayoutGap="5px">
                    <div fxLayout="column" fxLayoutAlign="space-between start" fxFlex="95" class="project-cell">
                      <div *ngIf="!projectAmount.unknownProject" class="project-name link"
                           (click)="navigateToProject(projectAmount.projectId)">
                        {{ projectAmount.projectName }}
                        <mat-icon [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.project.s'|translate}"
                                  (click)="navigateToProject(projectAmount.projectId)">
                          open_in_new
                        </mat-icon>
                      </div>
                      <div *ngIf="projectAmount.unknownProject" class="project-name">
                        {{ projectAmount.projectName }}
                      </div>
                      <div class="address">{{ addressService.formatAddressSingleLine(projectAmount.projectAddress.city,
                          projectAmount.projectAddress.street, projectAmount.projectAddress.streetNumber) }}</div>
                    </div>
                    <div fxFlex="5" fxLayoutAlign="center center">
                      <fa-icon [icon]="faCalendar" class="calendar-icon"
                               matTooltip="{{projectAmount.projectStart | date: 'dd.MM.yyyy'}} -
                                           {{projectAmount.projectEnd ? (projectAmount.projectEnd |
                                            date: 'dd.MM.yyyy'): 'general.labels.openEnd'|translate}}"></fa-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="time">
                <th mat-header-cell class="data-cell time-cell" *matHeaderCellDef></th>
                <td mat-cell class="data-cell time-cell" *matCellDef="let assignment">
                  <div>
                    {{'general.labels.start'|translate}}: {{ assignment.projectStart | date: 'dd.MM.yyyy' }}
                  </div>
                  <div *ngIf="assignment.projectEnd; else noEnd ">
                    {{'general.labels.end'|translate}}: {{ assignment.projectEnd | date: 'dd.MM.yyyy'}}
                  </div>
                  <ng-template #noEnd>
                    <div>{{'general.labels.openEnd'|translate}}</div>
                  </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="stock">
                <th mat-header-cell *matHeaderCellDef>{{'general.stock.s'|translate}}</th>
                <td mat-cell class="data-cell stock-cell" *matCellDef="let assignment">
                  <div fxLayout="column" fxLayoutAlign="space-between start">
                    <div class="address">
                      {{'modules.disposition.base.originStock'|translate}}:
                    </div>
                    <div *ngIf="!assignment.unknownStock" class="project-name link"
                         (click)="navigateToStock(assignment.stockId)">
                      {{ assignment.stockName }}
                      <mat-icon [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.stock.s'|translate}"
                                (click)="navigateToStock(assignment.stockId)">
                        open_in_new
                      </mat-icon>
                    </div>
                    <div *ngIf="assignment.unknownStock" class="project-name">
                      {{ assignment.stockName }}
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>{{'general.quantity'|translate}}</th>
                <td mat-cell class="data-cell amount-cell" *matCellDef="let amount">
                  <div>
                    {{ amount.amount | dimensionUnitConverter: bulkItem.bulkItemUnit:3 }} {{ bulkItem.bulkItemUnit | dimensionUnitDisplay }}
                  </div>
                </td>
              </ng-container>
              <tr mat-row *matRowDef="let row; columns: projectColumns;"></tr>
            </table>
            <ng-template #noAssignments>
              <div class="no-assignments">
                {{'general.labels.notAvailable.assignments'|translate}}
              </div>
            </ng-template>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
