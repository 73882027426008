import { LanguageService } from 'app/shared/services/language.service';
import { Directive, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { ViewBulkItem } from '../../../../../contract/view-bulk-item.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class BaseBulkItemViewAttachmentComponent extends GuardedNavigableInputComponent implements OnInit {

  public documents: DocumentLink[] = [];
  public images: DocumentLink[] = [];
  public defaultImage: DocumentLink;
  public bulkItem: ViewBulkItem;
  public waiting: boolean;

  constructor(protected bulkItemStore: BulkItemDatasource,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.initListeners();
    this.waiting = false;
  }

  public getFileTypeIcon(document: DocumentLink): IconDefinition {
    return FileUtils.getAttachmentFileTypeIcon(document);
  }

  private initListeners(): void {
    this.initCurrentBulkItemListener();
    this.initDocumentsListener();
  }

  private initCurrentBulkItemListener(): void {
    this.bulkItemStore.currentBulkItem
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.bulkItem = res;
          this.bulkItemStore.getDocuments(res.bulkItemId);
        }
      });
  }

  private initDocumentsListener(): void {
    this.bulkItemStore.documents
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        const { documents, images } = FileUtils.splitDocumentLinks(res);
        this.images = images;
        this.documents = documents;
        this.defaultImage = this.images.find(doc => this.isDefaultImage(doc));
      });
  }

  private isDefaultImage(image: DocumentLink): boolean {
    return image.documentKey === this.bulkItem.thumbnail;
  }
}
