<div class="timeline-event-details">
  <h2 class="timeline-event-details__title">{{'modules.equipment.base.invoiceInfo'|translate}}</h2>
  <div class="timeline-event-details__content">
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.performanceType'|translate}}</mat-label>
        <input matInput
              readonly
              [value]="invoice?.performanceType | performanceType">
      </mat-form-field>
    </div>
    <div class="timeline-event-details__content__field">
      <mat-form-field floatLabel="always">
        <mat-label>{{'modules.equipment.base.invoiceNumber'|translate}}</mat-label>
        <input matInput
              readonly
              [value]="invoice?.invoiceNumber">
      </mat-form-field>
    </div>
  </div>

  <bh-extended-invoice-info *ngIf="invoice" [invoice]="invoice"></bh-extended-invoice-info>
</div>

