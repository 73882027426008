<div fxFlex="40px">
  <div class="m-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div [routerLink]="['/']">
      OneStop Pro
    </div>
    <div class="m-header__sidenav-icon">
    <span
        *ngIf="isOnline && hasAuthority(authorities.MESSAGE_CENTER_USE) && hasModule(modules.COMMUNICATION)">
      <span *ngIf="unreadCount" class="badge">{{ unreadCount }}</span>
      <button mat-icon-button class="bh-chat-icon" (click)="openChat()">
        <mat-icon fontSet="icon-message_outline"></mat-icon>
      </button>
    </span>
      <button *ngIf="!isOnline" mat-icon-button class="bh-chat-icon offline">
        <mat-icon>offline_bolt</mat-icon>
      </button>
      <button mat-button matSuffix mat-icon-button aria-label="Toggle" (click)="sidenavToggle()">
        <mat-icon>view_headline</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="m-header overlay" *ngIf="editMode" fxLayout="row" fxLayoutAlign="space-between center">
  <div>{{ selected }}</div>
  <div class="actions">
    <button mat-icon-button (click)="deleteAll()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="approveAll()">
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>

