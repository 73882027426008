import { Pipe, PipeTransform } from '@angular/core';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapLocationsCluster } from '../interfaces/map-location-cluster.interface';
import { MapInfoWindowClusterGlobalListComponent } from '../components/map-info-window/map-info-window-cluster-global-list/map-info-window-cluster-global-list.component';
import { MapClusterItem } from '../interfaces/map-cluster-item.interface';


@Pipe({
  name: 'mapClusterGlobal'
})
export class MapClusterGlobalPipe implements PipeTransform {

  public transform(markers: MapLocationMarker<MapClusterItem>[]): MapLocationsCluster<MapClusterItem> {
    return Array.isArray(markers) ? this.getMapCluster(markers) : null;
  }

  private getMapCluster(markers: MapLocationMarker<MapClusterItem>[]): MapLocationsCluster<MapClusterItem> {
    return {
      markers,
      infoWindow: { content: MapInfoWindowClusterGlobalListComponent },
    };
  }

}
