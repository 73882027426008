import { ViewEquipment } from '../../../contract/view-equipment.interface';
import { Address } from '../../../../organisation/contract/address.interface';
import { DispositionEquipment } from '../../../shared/disposition-equipment';
import { ScanEquipment } from 'app/modules/equipment/shared/scan-equipment';

export class EquipmentAssignToProjectUtils {

  static formatEquipmentIdentifier(equipment: ViewEquipment | DispositionEquipment | ScanEquipment): string {
    if (equipment) {
      return  equipment.equipmentSerialNumber
          ? `${this.getNameOrModel(equipment)} / ${equipment.equipmentSerialNumber}`
          : `${this.getNameOrModel(equipment)}`
    }

    return '';
  }

  private static getNameOrModel(equipment: ViewEquipment | DispositionEquipment | ScanEquipment) {
    return equipment.equipmentName ? equipment.equipmentName : equipment.equipmentModel;
  }

  static formatAddress(projectAddress: Address): string {
    if (projectAddress) {
      return '' +
          (projectAddress.postalCode ? projectAddress.postalCode + ' ' : '') +
          (projectAddress.city ? projectAddress.city : '');
    } else {
      return '-';
    }
  }

  static formatCostCenter(costCenter: string): string {
    return costCenter || '-';
  }

}
