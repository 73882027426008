import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { BulkItemFilterType } from 'app/shared/contract/filter/filter-view/bulk-item-filter-collection/bulk-item-filter-type.enum';
import { BulkItemFilterParams } from './bulk-item-filter-params.interface';

export class BulkItemFilterParamsClass extends FilterParams {
  protected paramValuesMapper: { [key in BulkItemFilterType]?: () => any } = {
    [BulkItemFilterType.LABEL]: () => this.getFilterTypeParams(FilterTypes.LABEL),
    [BulkItemFilterType.TYPE]: () => this.getFilterTypeParams(FilterTypes.TYPE),
    [BulkItemFilterType.CATEGORY]: () => this.getFilterTypeParams(FilterTypes.CATEGORY),
  };

  public getFilterParams(): BulkItemFilterParams {
    return {
      [BulkItemFilterType.LABEL]: this.getFilterTypeParams(FilterTypes.LABEL),
      [BulkItemFilterType.TYPE]: this.getFilterTypeParams(FilterTypes.TYPE),
      [BulkItemFilterType.CATEGORY]: this.getFilterTypeParams(FilterTypes.CATEGORY),
    }
  }

  public hasParamValue(type: BulkItemFilterType): boolean {
    return this.paramValuesMapper[type].length > 0;
  }
}
