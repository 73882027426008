import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getHttpParameters } from '../../../shared/utils';
import { TopfactDocument } from '../../../shared/contract/topfact-document.interface';

@Injectable()
export class TopfactService {

  private url = environment.APP_JOB_SERVICE_BASE_URL + '/api/v1/topfact';

  constructor(private http: HttpClient) {
  }

  public connectorExists(): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/connector/exist');
  }

  public getDocuments(params: Object): Observable<TopfactDocument[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<TopfactDocument[]>(this.url + '/find-documents', {params: httpParams});
  }

  public getDocument(docId: string, fileId: string): Observable<Blob> {
    return this.http.get(this.url + `/document/${docId}/${fileId}`, {responseType: 'blob'});
  }
}
