import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { getHttpParameters } from '../../../shared/utils';
import { ViewBulkItemAmount } from '../contract/view-bulk-item-amount.interface';
import { AmountTypeCount } from '../contract/amount-type-count';
import { AmountCategoryCount } from '../contract/amount-category-count';
import { AmountLabelCount } from '../contract/amount-label-count';
import { ViewProjectAmount } from '../contract/view-project-amount.interface';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { DispositionProject } from './disposition-project';
import { DispositionStock } from './disposition-stock';
import { SearchStockEquipmentAmount } from '../contract/search-stock-equipment-amount.interface';
import { EquipmentAmountInfo } from '../contract/equipment-amount-info';
import { InventoryRequestParams } from './interfaces/inventoryRequestParams.interface';

@Injectable()
export class AmountsService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/amounts';
  private equipmentAmountUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/amounts/equipment';
  private projectAmountUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/project-amounts';

  constructor(private http: HttpClient) {
  }

  public getAmounts(params: Object): Observable<PagedResponse<ViewBulkItemAmount>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<ViewBulkItemAmount>>(this.url + '/search/all', {params: httpParams});
  }

  public getEquipmentAmounts(params: Object): Observable<PagedResponse<SearchStockEquipmentAmount>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<SearchStockEquipmentAmount>>(this.equipmentAmountUrl, {params: httpParams});
  }

  public getEquipmentAmountsSearch(params: Object): Observable<PagedResponse<SearchStockEquipmentAmount>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<SearchStockEquipmentAmount>>(this.equipmentAmountUrl + '/search/all', {params: httpParams});
  }

  public getFilterableAmountTypes(params: Object): Observable<AmountTypeCount[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<AmountTypeCount[]>(`${this.url}/filterable-amount-types`, {params: httpParams});
  }

  public getFilterableAmountCategories(params: Object): Observable<AmountCategoryCount[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<AmountCategoryCount[]>(`${this.url}/filterable-amount-categories`, {params: httpParams});
  }

  public getFilterableAmountLabels(params: Object): Observable<AmountLabelCount[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<AmountLabelCount[]>(`${this.url}/filterable-amount-labels`, {params: httpParams});
  }

  public getProjectAmounts(projectId: string, params?: Object): Observable<ViewProjectAmount[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<ViewProjectAmount[]>(`${this.projectAmountUrl}/project/${projectId}`, {params: httpParams});
  }

  public getProjectWithStatusPeriods(projectId: string, params?: Object): Observable<ViewProjectAmount[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<ViewProjectAmount[]>(`${this.projectAmountUrl}/project-with-status-periods/${projectId}`, {params: httpParams});
  }

  public getStockAmounts(stockId: string): Observable<PagedResponse<ViewBulkItemAmount>> {
    let params = getHttpParameters({
      page: 0,
      size: 600
    });
    return this.http.get<PagedResponse<ViewBulkItemAmount>>(`${this.url}/stock/${stockId}`, {params: params});
  }

  public getBulkItemAmounts(bulkItemId: string, allOrganisations?: boolean): Observable<ViewBulkItemAmount[]> {
    let params = new HttpParams();
    if (allOrganisations) {
      params = params.set('allOrganisations', 'true');
    }
    return this.http.get<ViewBulkItemAmount[]>(`${this.url}/bulk-item/${bulkItemId}`, {params: params});
  }

  public getProjectAmountsForTransfer(bulkItemId: string, source: DispositionProject): Observable<ViewProjectAmount[]> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('bulkItemId', bulkItemId)
        .set('projectId', source.projectId)
    };
    return this.http.get<ViewProjectAmount[]>(`${this.projectAmountUrl}/by-bulk-item-and-project`, httpOptions);
  }

  public getAmountForTransfer(bulkItemId: string, source: DispositionStock): Observable<ViewBulkItemAmount> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('bulkItemId', bulkItemId)
        .set('stockId', source.stockId)
    };
    return this.http.get<ViewBulkItemAmount>(`${this.url}/by-bulk-item-and-stock`, httpOptions);
  }

  public getDispositionAmounts(): Observable<ViewBulkItemAmount[]> {
    return this.http.get<ViewBulkItemAmount[]>(`${this.url}/disposition`);
  }

  public getDispositionProjectAmounts(): Observable<ViewProjectAmount[]> {
    return this.http.get<ViewProjectAmount[]>(`${this.projectAmountUrl}/disposition`);
  }

  public hasAmounts(projectId: string): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('projectId', projectId)
    };

    return this.http.get<boolean>(`${this.projectAmountUrl}/has-amounts`, httpOptions);
  }

  public getEquipmentAmountById(equipmentId: string): Observable<EquipmentAmountInfo> {
    return this.http.get<EquipmentAmountInfo>(`${this.equipmentAmountUrl}/${equipmentId}`);
  }

  public getInventoryEquipments(projectId: string, params: InventoryRequestParams): Observable<PagedResponse<ViewProjectAmount>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<ViewProjectAmount>>(`${this.projectAmountUrl}/equipments/project/${projectId}`, {params: httpParams});
  }

  public getInventoryBulkItems(projectId: string, params: InventoryRequestParams): Observable<PagedResponse<ViewProjectAmount>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<ViewProjectAmount>>(`${this.projectAmountUrl}/bulk-items/project/${projectId}`, {params: httpParams});
  }
}
