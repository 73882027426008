import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartWidget } from '../basic-widget/chart-widget';
import { ChartType, Plugin } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { IOptionsList, IOptionPlaceholder } from 'app/shared/contract/selection-tree-options.interface';
import { EquipmentStatusWidgetDatasource } from './equipment-status-widget.datasource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import {
  applySelectionToOptionList,
  convertGroupedEquipmentTypesToOptionList,
  defaultPlaceholder,
  getOptionPlaceholder,
  getSelectedFromOptionList,
  viewOrganisationToOptionsList
} from 'app/shared/services/selection-tree.converter';
import { FormControl } from '@angular/forms';
import _ from 'lodash';
import { CustomPluginFactory } from '../chart-custom-plugin/chart-custom-plugin-factory.service';
import { ChartCustomPluginId } from '../chart-custom-plugin/enums/chart-custom-plugin-id.enum';
import { EquipmentStatusChartDataPrepared } from '../contract/equipment-status-chart-data-prepared.interface';
import { EquipmentStatusGridsterItem } from '../contract/equipment-status-gridster-item.interface';
import { ChartBarDisplayType } from '../contract/chart-bar-display-type.enum';
import { DashboardStore } from '../../dashboard/dashboard.store';
import { LanguageService } from 'app/shared/services/language.service';


@UntilDestroy()
@Component({
  selector: 'bh-equipment-status-widget',
  templateUrl: './equipment-status-widget.component.html',
  styleUrls: ['./equipment-status-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentStatusWidgetComponent extends ChartWidget implements OnInit {
  @Input() public override widget: EquipmentStatusGridsterItem;

  public barChartType: ChartType = 'bar';
  public readonly equipmentStatuses = this.widgetDatasource.equipmentStatuses;
  public organisationList: IOptionsList[] = [];
  public organisationPlaceholder: IOptionPlaceholder = defaultPlaceholder();
  public typeList: IOptionsList[] = [];
  public typePlaceholder: IOptionPlaceholder = defaultPlaceholder();
  public displayTypeControl = new FormControl();
  public plugins: Plugin[] = [];
  public displayTypeList: { value: ChartBarDisplayType, name: string }[] =
    Object.keys(ChartBarDisplayType).map(key => ({
      value: ChartBarDisplayType[key],
      name: this.translateDisplayType(ChartBarDisplayType[key])
    }));

  constructor(
    private widgetDatasource: EquipmentStatusWidgetDatasource,
    private dashboardStore: DashboardStore,
    private customPluginFactory: CustomPluginFactory,
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public labelClickCallback = (equipmentStatusId: string) => {
    this.navigateToFilteredEquipments(equipmentStatusId);
  }

  public ngOnInit(): void {
    this.initListeners();
    this.plugins = [
      DataLabelsPlugin,
      this.customPluginFactory.getPlugin(ChartCustomPluginId.CUSTOM_DATA_CATEGORY_LABELS)
    ];
    this.widgetDatasource.initWidgetData(this.widget);
    this.widget.displayType = this.widget.displayType ?? ChartBarDisplayType.LINEAR;
    this.displayTypeControl.setValue(this.widget.displayType, { emitEvent: false });
  }

  public onConfigurationSave(index: number): void {
    if (this.index === index) {
      this.widget.organisationIds = getSelectedFromOptionList(this.organisationList);
      this.widget.equipmentTypeCategories = getSelectedFromOptionList(this.typeList);
      this.widget.displayType = this.displayTypeControl.value;
      this.widgetDatasource.loadWidgetData(this.widget);
    }
  }

  public preventDefaultEvent(event: Event): void {
    event.preventDefault();
  }

  public chartClicked({ active }: { active: Array<any> }, preparedData: EquipmentStatusChartDataPrepared): void {
    if (active?.length) {
      const [{ datasetIndex, index }] = active;
      this.navigateToFilteredEquipments(preparedData?.[datasetIndex]?.[index]?.equipmentStatusId);
    }
  }

  public organisationListClosed(): void {
    this.organisationPlaceholder = getOptionPlaceholder(this.organisationList);
  }

  public typeListClosed(): void {
    this.typePlaceholder = getOptionPlaceholder(this.typeList);
  }

  private navigateToFilteredEquipments(equipmentStatusId: string): void {
    if (equipmentStatusId) {
      this.widgetDatasource.navigateToEquipmentList(equipmentStatusId);
    }
  }

  private initListeners(): void {
    this.initListenerOrganisations();
    this.initListenerEquipmentTypes();
    this.initListenerEditModeChange();
  }

  private initListenerOrganisations(): void {
    this.widgetDatasource.availableOrganisations
      .pipe(
        map(viewOrganisationToOptionsList),
        map(list => applySelectionToOptionList(list, this.widget.organisationIds)),
        untilDestroyed(this))
      .subscribe(list => {
        this.organisationList = list;
        this.organisationPlaceholder = getOptionPlaceholder(list);
      });
  }

  private initListenerEquipmentTypes(): void {
    this.widgetDatasource.availableEquipmentTypeCategories
      .pipe(
        map(convertGroupedEquipmentTypesToOptionList),
        map(list => applySelectionToOptionList(list, this.widget.equipmentTypeCategories)),
        untilDestroyed(this))
      .subscribe(list => {
        this.typeList = list;
        this.typePlaceholder = getOptionPlaceholder(list);
      });
  }

  private initListenerEditModeChange(): void {
    this.dashboardStore.editMode
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());
  }

  private translateDisplayType(type: ChartBarDisplayType): string {
    switch(type) {
      case ChartBarDisplayType.LINEAR: return this.translate('modules.dashboard.chartBarDisplayType.linear').toLocaleUpperCase();
      case ChartBarDisplayType.LOGARITHMIC: return this.translate('modules.dashboard.chartBarDisplayType.logarithmic').toLocaleUpperCase();
      default: return this.translate('general.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
