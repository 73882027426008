<div fxLayout="row">
  <mat-icon color="primary" fxFlex="10">check_circle</mat-icon>

  <div fxFlex>{{data.message}}</div>

  <div fxFlex="10">
    <button mat-icon-button (click)="dismiss()">
      <mat-icon class="bh-mat-icon">close</mat-icon>
    </button>
  </div>

</div>
