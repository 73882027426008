import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ViewOrganisation } from '../../../../organisation/contract/view-organisation.interface';
import { ReportGenerationCommand } from '../../../contract/report-generation-command';
import { DatesService } from '../../../../../shared/services/dates.service';
import { ReportsDataSource } from '../../../shared/services/reports.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchEquipment } from '../../../../equipment/contract/search-equipment.interface';
import { SearchProjectOrProjectGroup } from '../../../../disposition/contract/search-project.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewReportDefinition } from '../../../contract/view-report-definition.interface';
import { ReportParameter } from '../../../contract/report-parameter.enum';
import { CustomerLabel } from '../../../../../shared/contract/customer-label.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorSnackbarComponent } from '../../../../../shared/components/error-snackbar/error-snackbar.component';
import { AllSelectedWarningDialogComponent } from '../../all-selected-warning-dialog/all-selected-warning-dialog.component';
import { FileUtils } from '../../../../../shared/fileUtils';
import { EquipmentTypeCount, EquipmentTypeCountTranslated } from '../../../contract/equipment-type-count';
import { ReportsService } from '../../../shared/services/reports.service';
import { get } from 'lodash';
import { ReportParameters } from '../../../contract/report-parameters';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from 'assets/matomo/matomo-categories.enum';
import { matomoActions } from 'assets/matomo/matomo-actions.enum';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { environment } from 'environments/environment';
import { IconDefinition, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { IOptionPlaceholder, IOptionsList } from 'app/shared/contract/selection-tree-options.interface';
import {
  changeParentOption,
  convertEquipmentTypeCountToOptionList,
  defaultPlaceholder,
  getOptionPlaceholder,
  setOptionIndex
} from 'app/shared/services/selection-tree.converter';
import { TimezoneDatesService } from '../../../../../shared/timezone/timezone-dates.service';

type GeneralReportFormFieldValue =
  boolean |
  string[] |
  EquipmentTypeCount[] |
  SearchProjectOrProjectGroup[] |
  SearchEquipment[];

@UntilDestroy()
@Component({
  selector: 'bh-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss']
})
export class GenerateReportComponent implements OnInit, AfterViewChecked {

  public generateReportForm: UntypedFormGroup;
  // public transformedEquipmentTypes: EquipmentTypeCount[];   // this
  public labels: CustomerLabel[] = [];
  public organisations: ViewOrganisation[];
  public filteredEquipments: SearchEquipment[];
  public filteredProjects: SearchProjectOrProjectGroup[];
  public reportGenerating: boolean;
  public isShowDateFrom = false;
  public isShowDateUntil = false;
  public isShowEquipments = false;
  public isShowLabels = false;
  public isShowProjects = false;
  public isShowAllEquipments = false;
  public isShowAllProjects = false;
  public isShowOrganisations = false;
  public isShowEquipmentTypes = false;
  public isDisableEquipmentTypes = false;
  public isShowEmptyEquipmentTypesSearchMessage = false;
  public isShowEmptyEquipmentsSearchMessage = false;
  public isShowEmptyProjectsSearchMessage = false;

  public equipmentTypesPlaceholder: IOptionPlaceholder = defaultPlaceholder();  // this
  public filteredEquipmentTypesList: IOptionsList[] = [];   // this

  private baseEquipmentTypesList: IOptionsList[] = [];    // this
  private currentSelectedEquipments: SearchEquipment[] = [];
  private currentSelectedProjects: SearchProjectOrProjectGroup[] = [];
  private reportDefinition: ViewReportDefinition;
  private reportDefinitionId: string;
  private maxTermLength = 400;

  public readonly faCaretDown: IconDefinition = faCaretDown;
  public readonly formControlNameAllEquipments = 'allEquipments';
  public readonly formControlNameAllProjects = 'allProjects';
  public readonly formControlNameEquipmentTypes = 'equipmentTypes';
  public readonly formControlNameEquipmentTypeSearch = 'equipmentTypeSearch';
  public readonly formControlNameEquipmentLabels = 'equipmentLabels';
  public readonly formControlNameEquipments = 'equipments';
  public readonly formControlNameEquipmentSearch = 'equipmentSearch';
  public readonly formControlNameOrganisationIds = 'organisationIds';
  public readonly formControlNameProjects = 'projects';
  public readonly formControlNameProjectSearch = 'projectSearch';
  public readonly formControlNameUntil = 'until';
  public readonly formControlNameFrom = 'from';
  public readonly faSearch: IconDefinition = faSearch;

  public get canGenerate(): boolean {
    const formData = this.generateReportForm.getRawValue();
    const validDates = (!this.isShowDateFrom || (formData.from != null && formData.from instanceof Date)) &&
                      (!this.isShowDateUntil || (formData.until != null && formData.until instanceof Date));
    return validDates &&
      (formData.allEquipments === true ||
      formData.allProjects === true ||
      formData.organisationIds.length ||
      formData.equipmentTypes.length ||
      formData.equipmentLabels.length ||
      formData.equipments.length ||
      formData.projects.length);
  }

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected snackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,
              private detector: ChangeDetectorRef,
              private reportStore: ReportsDataSource,
              private reportService: ReportsService,
              private dialog: MatDialog,
              private matomoTracker: MatomoTracker,
              private languageService: LanguageService,
              private timezoneDatesService: TimezoneDatesService) {
  }

  public ngOnInit(): void {
    this.reportDefinitionId = this.route.snapshot.params['reportDefinitionId'];

    this.buildForm();
    this.getReportDefinition();
  }

  public ngAfterViewChecked(): void {
    this.detector.detectChanges();
  }

  public downloadReport(): void {
    let formData = this.generateReportForm.getRawValue();
    if (this.generateReportForm.valid) {
      let cmd: ReportGenerationCommand = new ReportGenerationCommand(
        this.reportDefinitionId,
        formData[this.formControlNameOrganisationIds],
        formData[this.formControlNameEquipmentTypes],
        formData[this.formControlNameEquipmentLabels],
        formData[this.formControlNameEquipments].map(equipment => equipment.equipmentId),
        formData[this.formControlNameProjects].map(project => project.projectId),
        DatesService.sameTimeZoneAtStartDateUTC(formData[this.formControlNameFrom]),
        DatesService.sameTimeZoneAtEndDateUTC(formData[this.formControlNameUntil]),
        formData[this.formControlNameAllEquipments],
        formData[this.formControlNameAllProjects],
        this.timezoneDatesService.getSelectedTimezone()
      );
      this.reportGenerating = true;
      const reportName = this.reportDefinition.reportDefinitionName;
      this.trackMatomoGenerateReport(matomoActions.START_GENERATE_REPORT);
      this.trackMatomoGenerateReport(`${matomoActions.START_GENERATE_REPORT}-${reportName}`);
      this.reportStore.generateReport(cmd).subscribe(
        (res) => {
          FileUtils.downloadFile(res);
          this.reportGenerating = false;
          this.trackMatomoGenerateReport(matomoActions.END_GENERATE_REPORT);
          this.trackMatomoGenerateReport(`${matomoActions.END_GENERATE_REPORT}-${reportName}`);
          this.navigateToView();
        },
        () => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            data: {message: this.translate('modules.reports.reportGeneration.errorGenerating')}
          });
          this.reportGenerating = false;
        }
      );
    }
  }

  public getDate(fieldName: string): Date {
    return this.generateReportForm.get(fieldName).value;
  }

  public navigateToView(): void {
    this.router.navigate(['/reports/view']);
  }

  public toggleAll(event: MatCheckboxChange, formControlName: string): void {
    this.toggleControls(event.checked, formControlName);
    if (event.checked) {
      this.dialog.open
        (AllSelectedWarningDialogComponent, {data: this.mapFormControlNameToReportParameterEnum(formControlName), autoFocus: false})
      .afterClosed().subscribe(warningAcknowledged => {
        if (!warningAcknowledged) {
          event.source.checked = false;
          this.toggleControls(false, formControlName);
          this.generateReportForm.get(formControlName).setValue(false);
        }
      });
    }
  }

  public toggleDropdown(): void {
    this.generateReportForm.get(this.formControlNameEquipmentSearch).reset('');
    this.filteredEquipments = this.currentSelectedEquipments;
    this.generateReportForm.get(this.formControlNameProjectSearch).reset('');
    this.filteredProjects = this.currentSelectedProjects;
  }

  public resetEntries(): void {
    this.resetGeneralReportFormField(this.formControlNameAllEquipments, false);
    this.toggleControls(false, this.formControlNameAllEquipments);
    this.resetGeneralReportFormField(this.formControlNameAllProjects, false);
    this.toggleControls(false, this.formControlNameAllProjects);
    this.resetGeneralReportFormField(this.formControlNameEquipmentLabels, []);
    this.resetGeneralReportFormField(this.formControlNameOrganisationIds, []);
    this.resetFilterCriteria(this.formControlNameProjects);
    this.resetFilterCriteria(this.formControlNameEquipmentTypes);
    this.resetFilterCriteria(this.formControlNameEquipments);
    this.resetFilterCriteria(this.formControlNameFrom);
  }

  public resetFilterCriteria(field: string): void {
    switch (field) {
      case this.formControlNameEquipmentTypes:
        this.resetGeneralReportFormField(field, []);
        this.resetGeneralReportFormField(this.formControlNameEquipmentTypeSearch, '');
        this.filteredEquipmentTypesList = this.baseEquipmentTypesList.map(el => ({...el, selected: false}));
        this.equipmentTypesPlaceholder = getOptionPlaceholder(this.filteredEquipmentTypesList);
        break;
      case this.formControlNameEquipmentLabels:
        this.resetGeneralReportFormField(field, []);
        break;
      case this.formControlNameEquipments:
        this.resetGeneralReportFormField(field, []);
        this.resetGeneralReportFormField(this.formControlNameEquipmentSearch, '');
        break;
      case this.formControlNameOrganisationIds:
        this.resetGeneralReportFormField(field, []);
        break;
      case this.formControlNameProjects:
        this.resetGeneralReportFormField(field, []);
        this.resetGeneralReportFormField(this.formControlNameProjectSearch, '');
        break;
      case this.formControlNameFrom:
        this.resetGeneralReportFormField(field, ['']);
        this.resetGeneralReportFormField(this.formControlNameUntil, ['']);
        break;
    }
  }

  public equipmentTypesListClose(selectedOptionsList: IOptionsList[]): void {
    this.equipmentTypesPlaceholder = getOptionPlaceholder(this.filteredEquipmentTypesList);
    this.patchGeneralReportFormFieldValue(this.formControlNameEquipmentTypes, this.getOptionsIds(selectedOptionsList));
  }

  public equipmentTypesSearchEvent(term: string): void {
    if (term.length <= this.maxTermLength) {
      let currentSelectedOptions = this.getSelectedOptionsBaseIndexes(this.filteredEquipmentTypesList);
      this.filteredEquipmentTypesList = this.updateSearchList(this.baseEquipmentTypesList, currentSelectedOptions, term);
      this.selectSelectedOptionsByBaseIndex(this.filteredEquipmentTypesList, currentSelectedOptions);
      this.setOptionIndex(this.filteredEquipmentTypesList);
      this.isShowEmptyEquipmentTypesSearchMessage = term && this.filteredEquipmentTypesList.length === 0;
    } else if (term.length > this.maxTermLength) {
      this.snackBar.open(this.translate('general.snackBarMessages.tooLongCannotSearch'), undefined, { duration: 5000 });
    }
  }

  public preventDefaultEvent(event: Event): void {
    event.preventDefault();
  }

  private setAllParameters(): void {
    this.isShowAllEquipments = this.hasReportParameter(ReportParameter.ALL_EQUIPMENTS);
    this.isShowAllProjects = this.hasReportParameter(ReportParameter.ALL_PROJECTS);
    this.isShowEquipments = this.hasReportParameter(ReportParameter.EQUIPMENT_ID_LIST);
    this.isShowProjects = this.hasReportParameter(ReportParameter.PROJECT_ID_LIST);
    this.isShowOrganisations = this.hasReportParameter(ReportParameter.ORGANISATIONS);
    this.isShowEquipmentTypes = this.hasReportParameter(ReportParameter.EQUIPMENT_TYPES);
    this.isShowLabels = this.hasReportParameter(ReportParameter.EQUIPMENT_LABELS);
    this.isShowDateFrom = this.hasReportParameter(ReportParameter.DATE_FROM);
    this.isShowDateUntil = this.hasReportParameter(ReportParameter.DATE_UNTIL);
  }

  private hasReportParameter(param: ReportParameter): boolean {
    return this.reportDefinition?.reportDefinitionParameters.includes(param);
  }

  private getReportDefinition(): void {
    if (this.reportDefinitionId) {
      combineLatest([
        this.reportStore.getReportDefinition(this.reportDefinitionId),
        this.reportStore.getReportParameters(this.reportDefinitionId)])
      .pipe(untilDestroyed(this))
      .subscribe(([reportDefinition, reportParameters]) => {
        if (reportDefinition && reportParameters) {
          this.reportDefinition = reportDefinition;
          this.setAllParameters();
          this.getEquipments(reportParameters);
          this.getOrganisations(reportParameters);
          if (this.isShowEquipmentTypes) {
            this.equipmentTypesSubscription(reportParameters);
            this.reportStore.getEquipmentTypes();
            this.equipmentTypesSearchSubscription();
          }
          this.getLabels(reportParameters);
          this.getProjects(reportParameters);
          this.updateFormValues(reportParameters);
        }
      });
    }
  }

  private patchGeneralReportFormFieldValue(field: string, value: GeneralReportFormFieldValue): void {
    let control: AbstractControl = this.generateReportForm.get(field);
    if (!!control) {
      control.patchValue(value);
    }
  }

  private resetGeneralReportFormField(field: string, value: string | boolean | string[]): void {
    this.generateReportForm.get(field).reset(value);
  }

  public getSelectedEquipmentNames(): string {
    return this.currentSelectedEquipments?.map(el => el.equipmentName || el.equipmentModel).join(', ');
  }

  public getSelectedProjectNames(): string {
    return this.currentSelectedProjects?.map(el => el.projectName).join(', ');
  }

  private getEquipments(parameters: ReportParameters): void {
    if (this.isShowEquipments) {
      if (!!parameters && !!parameters.equipmentIds && parameters.equipmentIds.length > 0) {
        let params = {
          size: 100,
          page: 0,
          ids: parameters.equipmentIds,
          excludedEquipmentStatusCategory: [EquipmentStatusCategory.NOT_AVAILABLE]
        };

        this.reportService.searchEquipments(params).subscribe(res => {
          this.currentSelectedEquipments = res.content;
          this.filteredEquipments = res.content;
          this.patchGeneralReportFormFieldValue(this.formControlNameEquipments, res.content)
        });
      }


      this.generateReportForm.get(this.formControlNameEquipmentSearch).valueChanges
        .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(), untilDestroyed(this))
        .subscribe((term: string) => {
          this.updateOnEquipmentSearch(term);
        });
    }
  }

  public updateOnEquipmentSearch(term: string): void {
    if (term.length <= this.maxTermLength) {
      let params = {
        size: 100,
        page: 0,
        term: term,
        excludedEquipmentStatusCategory: [EquipmentStatusCategory.NOT_AVAILABLE]
      };
      this.reportService.searchEquipments(params).subscribe(result => {
        const filteredResult = result.content.filter(equipment =>
          !this.currentSelectedEquipments.map(e => e.equipmentId).includes(equipment.equipmentId));
        this.filteredEquipments = this.currentSelectedEquipments.concat(filteredResult);
        this.isShowEmptyEquipmentsSearchMessage = term && this.filteredEquipments.length === 0;
      });
    } else if (term.length > this.maxTermLength) {
      this.snackBar.open(this.translate('general.snackBarMessages.tooLongCannotSearch'), undefined, { duration: 5000 });
    }
  }

  private getOrganisations(parameters: ReportParameters): void {
    if (this.isShowOrganisations) {
      this.reportStore.getOrganisations()
        .pipe(untilDestroyed(this))
        .subscribe((organisations: ViewOrganisation[]) => {
          this.organisations = organisations;

          let selectedOrganisation = get(parameters, this.formControlNameOrganisationIds, []);
          if (!!selectedOrganisation) {
            this.patchGeneralReportFormFieldValue(this.formControlNameOrganisationIds, [...selectedOrganisation]);
          }
        });
    }
  }

  private getLabels(parameters: ReportParameters): void {
    if (this.isShowLabels) {
      this.reportStore.getCustomerLabels()
        .pipe(untilDestroyed(this))
        .subscribe((labels: CustomerLabel[]) => {
          this.labels = labels;

          let equipmentLabels = get(parameters, this.formControlNameEquipmentLabels, []);
          if (!!equipmentLabels) {
            this.patchGeneralReportFormFieldValue(this.formControlNameEquipmentLabels, [...equipmentLabels]);
          }
        });
    }
  }

  private equipmentTypesSubscription(parameters: ReportParameters): void {
    this.reportStore.reportEquipmentTypes
      .pipe(untilDestroyed(this))
      .subscribe((equipmentTypes: EquipmentTypeCountTranslated[]) => {
        if (!!equipmentTypes) {
          let equipmentTypesList = this.convertEquipmentTypeCountToOptionList(equipmentTypes)
          this.baseEquipmentTypesList = equipmentTypesList.map(el => ({...el, selected: false}));
          this.filteredEquipmentTypesList = equipmentTypesList;

          const selectedEquipmentTypeIds: Set<string> = new Set(get(parameters, 'equipmentTypeIds', []));
          if (selectedEquipmentTypeIds.size) {
            this.selectSelectedOptionsById(this.filteredEquipmentTypesList, selectedEquipmentTypeIds);
            this.equipmentTypesPlaceholder = getOptionPlaceholder(this.filteredEquipmentTypesList);
            this.patchGeneralReportFormFieldValue(this.formControlNameEquipmentTypes, [...selectedEquipmentTypeIds]);
          }
      }
    });
  }

  private equipmentTypesSearchSubscription(): void {
    this.generateReportForm.get(this.formControlNameEquipmentTypeSearch).valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST), untilDestroyed(this))
      .subscribe(term => this.equipmentTypesSearchEvent(term));
  }

  private updateSearchList(baseList: IOptionsList[], selectedList: Set<number>, term: string): IOptionsList[] {
    const filterableList = new Set<IOptionsList>();
    baseList.forEach(el => {
      if (selectedList.has(el.baseIndex)) {
        filterableList.add(el);
      } else if (el.item.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())){
        filterableList.add(el);
        this.getAllChildOptions(baseList, el).forEach(option => filterableList.add(option));
      }
    })
    return this.addEquipmentTypeCategoriesInList([...filterableList], baseList);
  }

  private getAllChildOptions(list: IOptionsList[], option: IOptionsList): IOptionsList[] {
    let searchArray = list.slice(option.baseIndex + 1);
    let lastChildrenIndex = this.getLastChildrenIndex(searchArray, option.offset);
    return [...searchArray.slice(0, lastChildrenIndex)];
  }

  private getLastChildrenIndex(list: IOptionsList[], offset: number): number {
    let lastChildrenIndex = list.findIndex(el => {
      if (el.offset <= offset) {
        return el.baseIndex;
      }
    });
    return lastChildrenIndex !== -1 ? lastChildrenIndex : list.length;
  }

  private addEquipmentTypeCategoriesInList(filterableList: IOptionsList[], baseList: IOptionsList[]): IOptionsList[] {
    return filterableList.reduce((acc, el) => {
      if (el.offset) {
        let category = this.getOptionCategory(baseList, el.baseIndex);
        if (!acc.some(cat => cat.baseIndex === category.baseIndex)) {
          acc.push(category);
        }
      }
      acc.push(el);
      return acc;
    }, []);
  }

  private getOptionCategory(list: IOptionsList[], index: number): IOptionsList {
    return list[index].offset ? this.getOptionCategory(list, index - 1) : {...list[index]};
  }

  private getOptionsIds(list: IOptionsList[]): string[] {
    return list.reduce((acc, el) => {
      if (el.item.id) {
        acc.push(el.item.id);
      }
      return acc;
    }, []);
  }

  private getSelectedOptionsBaseIndexes(list: IOptionsList[]): Set<number> {
    return list.reduce((acc, option) => {
      if (option.selected) {
        acc.add(option.baseIndex);
      }
      return acc;
    }, new Set<number>());
  }

  private selectSelectedOptionsByBaseIndex(list: IOptionsList[], selectedOptionsList: Set<number>): void {
    list.forEach(el => {
      if (selectedOptionsList.has(el.baseIndex)) {
        el.selected = true;
      }
    });
  }

  private selectSelectedOptionsById(list: IOptionsList[], ids: Set<string>): void {
    list.forEach(el => {
      if (ids.has(el.item.id)) {
        el.selected = true;
        changeParentOption(this.filteredEquipmentTypesList, el);
      }
    });
  }

  private getProjects(parameters: ReportParameters): void {
    if (this.isShowProjects) {
      if (!!parameters && !!parameters.projectIds && parameters.projectIds.length > 0) {
        let params = {
          size: 100,
          page: 0,
          ids: parameters.projectIds
        };

        this.reportService.searchProjects(params).subscribe(res => {
          this.currentSelectedProjects = res.content;
          this.filteredProjects = res.content;
          this.patchGeneralReportFormFieldValue(this.formControlNameProjects, res.content)
        });
      }

      this.generateReportForm.get(this.formControlNameProjectSearch).valueChanges
        .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(), untilDestroyed(this))
        .subscribe(term => this.updateOnProjectSearch(term));
    }
  }

  public updateOnProjectSearch(term: string): void {
    if (term.length <= this.maxTermLength) {
      let params = {
        size: 100,
        page: 0,
        term: term
      };
      this.reportService.searchProjects(params).subscribe(result => {
        const filteredResult = result.content.filter(project =>
          !this.currentSelectedProjects.map(p => p.projectId).includes(project.projectId));
        this.filteredProjects = this.currentSelectedProjects.concat(filteredResult);
        this.isShowEmptyProjectsSearchMessage = term && this.filteredProjects.length === 0;
      });
    } else if (term.length > this.maxTermLength) {
      this.snackBar.open(this.translate('general.snackBarMessages.tooLongCannotSearch'), undefined, { duration: 5000 });
    }
  }

  private buildForm(): void {
    this.generateReportForm = this.formBuilder.group({
      [this.formControlNameOrganisationIds]: [[]],
      [this.formControlNameEquipmentTypes]: [[]],
      [this.formControlNameEquipmentLabels]: [[]],
      [this.formControlNameEquipments]: [[]],
      [this.formControlNameProjects]: [[]],
      [this.formControlNameFrom]: [''],
      [this.formControlNameUntil]: [''],
      [this.formControlNameEquipmentSearch]: [''],
      [this.formControlNameEquipmentTypeSearch]: [''],
      [this.formControlNameProjectSearch]: [''],
      [this.formControlNameAllEquipments]: false,
      [this.formControlNameAllProjects]: false
    });

    this.generateReportForm.get(this.formControlNameEquipments).valueChanges.pipe(untilDestroyed(this))
      .subscribe((equipments: SearchEquipment[]) => this.currentSelectedEquipments = equipments);
    this.generateReportForm.get(this.formControlNameProjects).valueChanges.pipe(untilDestroyed(this))
      .subscribe((projects: SearchProjectOrProjectGroup[]) => this.currentSelectedProjects = projects);
  }

  private toggleControls(disableControls: boolean, formControlName: string): void {
    const abstractControls = [];
    if (formControlName === this.formControlNameAllProjects) {
      abstractControls.push(this.formControlNameProjects);
    } else if (formControlName === this.formControlNameAllEquipments) {
      abstractControls.push(this.formControlNameEquipmentLabels, this.formControlNameEquipments, this.formControlNameOrganisationIds);
      this.isDisableEquipmentTypes = !this.isDisableEquipmentTypes;
    }
    abstractControls.forEach((abstractControlName: string) => {
      const abstractControl = this.generateReportForm.get(abstractControlName);
      if (abstractControl != null) {
        disableControls ? abstractControl.disable() : abstractControl.enable();
      }
    });
  }

  private trackMatomoGenerateReport(matomoAction: string): void {
    this.matomoTracker.trackEvent(matomoCategories.GENERATE_REPORTING, matomoAction);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private updateFormValues(parameters: ReportParameters): void {
    if (!!parameters) {
      let formValues = {
        [this.formControlNameFrom]: this.isShowDateFrom ? this.toDate(get(parameters, this.formControlNameFrom)) : '',
        [this.formControlNameUntil]: this.isShowDateUntil ? this.toDate(get(parameters, this.formControlNameUntil)) : '',
        [this.formControlNameAllEquipments]: this.isShowAllEquipments ? get(parameters, this.formControlNameAllEquipments, false) : false,
        [this.formControlNameAllProjects]: this.isShowAllProjects ? get(parameters, this.formControlNameAllProjects, false) : false,
      };

      if (parameters.allEquipments) {
        this.toggleControls(parameters.allEquipments, this.formControlNameAllEquipments);
      }

      if (parameters.allProjects) {
        this.toggleControls(parameters.allProjects, this.formControlNameAllProjects);
      }

      this.generateReportForm.patchValue(formValues);
    }
  }

  private toDate(date: string): string | Date {
    if (!date) {
      return ''
    }

    return moment.utc(date).toDate();
  }

  private mapFormControlNameToReportParameterEnum(formControlName: string): string {
    switch (formControlName) {
      case this.formControlNameAllEquipments:
        return ReportParameter.ALL_EQUIPMENTS;
      case this.formControlNameAllProjects:
        return ReportParameter.ALL_PROJECTS
      default:
        return ''
    }
  }

  private convertEquipmentTypeCountToOptionList(options: EquipmentTypeCountTranslated[]): IOptionsList[] {
    return convertEquipmentTypeCountToOptionList(options);
  }

  private setOptionIndex(list: IOptionsList[]): void {
    setOptionIndex(list);
  }
}
