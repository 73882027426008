import { period } from '../period';

export class PeriodInterval {

  static months(monthFormValue: any): PeriodInterval {
    return new PeriodInterval(
        period(monthFormValue.interval, monthFormValue.intervalDuration),
        monthFormValue.mediumWarningThreshold,
        monthFormValue.highWarningThreshold);
  }

  constructor(
      public requiredIncrease: string,
      public mediumWarningThreshold: number,
      public highWarningThreshold: number,
  ) {}

}
