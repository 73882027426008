import { Component, OnInit } from '@angular/core';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faUser, faUserHardHat, faUsers, faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import { KeycloakService } from 'app/core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from 'app/shared/router-history';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'app/shared/services/language.service';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { ViewProjectEmployeeAssignment } from 'app/modules/disposition/contract/view-project-employee-assignment.interface';
import { ProjectEmployeeAssignmentViewDialogComponent } from 'app/modules/disposition/shared/project-employee-assignment-view-dialog/project-employee-assignment-view-dialog.component';
import { ProjectAssigneeType } from 'app/shared/enums/project-assignee-type.enum';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { RemoveProjectResponsibleEmployeeCommand } from 'app/modules/disposition/contract/remove-project-responsible-employee-command';
import { ProjectEmployeeAssignmentManageDialogComponent } from 'app/modules/disposition/shared/project-employee-assignment-manage-dialog/project-employee-assignment-manage-dialog.component';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'bh-project-employee-assignee-cards',
  templateUrl: './project-employee-assignee-cards.component.html',
  styleUrls: ['./project-employee-assignee-cards.component.scss']
})
export class ProjectEmployeeAssigneeCardsComponent extends GuardedNavigableInputComponent implements OnInit {
  public readonly faUsers: IconDefinition = faUsers;
  public readonly faBadgeCheck: IconDefinition = faBadgeCheck;

  public employeeAssignments: ViewProjectEmployeeAssignment[] = [];
  public employeesAvailable = false;
  public hasAssignmentOrTransferRequestWorkflow: boolean;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              private dialog: MatDialog,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToEmployeeAssignments();
    this.subscribeToAssignmentOrTransferRequestWorkflow();
  }

  subscribeToEmployeeAssignments(): void {
    this.projectStore.employeeAssignments.subscribe(employeeAssignments => {
      if (employeeAssignments) {
        this.employeeAssignments = employeeAssignments;
        this.employeesAvailable = true;
      }
    });
  }

  openViewAssignmentDialog(employeeAssignment: ViewProjectEmployeeAssignment): void {
    let dialogRef = this.dialog.open(ProjectEmployeeAssignmentViewDialogComponent);
    dialogRef.componentInstance.employeeAssignment = employeeAssignment;
    dialogRef.componentInstance.icon = this.getRoleIcon(employeeAssignment.assigneeType);
  }

  openDeleteAssignmentDialog(employeeAssignment: ViewProjectEmployeeAssignment): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.equipmentDetailGeneral.deleteAssignmentConfirm');
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        const command: RemoveProjectResponsibleEmployeeCommand = new RemoveProjectResponsibleEmployeeCommand(
            employeeAssignment.projectId,
            employeeAssignment.employeeAssignmentId,
            employeeAssignment.employeeId,
            employeeAssignment.assigneeType,
            );
        this.projectStore.removeEmployeeAssignment(command);
      }
    });
  }

  openAddAssignmentDialog(): void {
    if (this.employeesAvailable) {
      let dialogRef = this.dialog.open(ProjectEmployeeAssignmentManageDialogComponent);
      dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
      dialogRef.componentInstance.editMode = false;
    }
  }

  public openUpdateAssignmentDialog(employeeAssignment: ViewProjectEmployeeAssignment): void {
    if (this.employeesAvailable) {
      let dialogRef = this.dialog.open(ProjectEmployeeAssignmentManageDialogComponent);
      dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
      dialogRef.componentInstance.editMode = true;
      dialogRef.componentInstance.assignment = employeeAssignment;
    }
  }

  getRoleIcon(role: ProjectAssigneeType): IconDefinition {
    switch (role) {
      case ProjectAssigneeType.CONSTRUCTION_MANAGER:
        return faUserTie;
      case ProjectAssigneeType.FOREMAN:
        return faUserHardHat;
      case ProjectAssigneeType.OTHER:
        return faUser;
      default:
        return null;
    }
  }

  getPhoneNumber(employeeAssignment: ViewProjectEmployeeAssignment): string {
    const phone = employeeAssignment.employeeOfficePhoneNumber || employeeAssignment.employeeOfficeMobileNumber;
    return phone  || '-';
  }

  private subscribeToAssignmentOrTransferRequestWorkflow(): void {
    this.projectStore.hasAssignmentOrTransferRequestWorkflow.subscribe((hasAssignmentOrTransferRequestWorkflow: boolean) => {
      this.hasAssignmentOrTransferRequestWorkflow = hasAssignmentOrTransferRequestWorkflow;
    });
    this.projectStore.updateAssignmentOrTransferRequestWorkflow();
  }

  get allowAddEdit(): boolean {
     return this.hasAnyAuthority([Authorities.PROJECT_UPDATE, Authorities.PROJECT_ASSIGNEE_MANAGE]);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
