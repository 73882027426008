import { SearchEquipment } from 'app/modules/equipment/contract/search-equipment.interface';
import { SearchEmployee } from 'app/modules/disposition/contract/search-employee.interface';
import { SearchBulkItem } from 'app/modules/disposition/contract/search-bulk-item.interface';
import { SearchProjectOrProjectGroup } from 'app/modules/disposition/contract/search-project.interface';
import { SearchStock } from 'app/modules/disposition/stocks/shared/search-stock';
import { ContactSearch } from 'app/modules/contact/contract/contact/contact-search.interface';

export class CurrentItemPaginatorUtils {
  public static isIdentifierOfEquipment(item: SearchEquipment, id: string): boolean {
    return item &&
      (item.equipmentId === id ||
      (item.subEquipmentList && item.subEquipmentList.some(({ equipmentId }) => equipmentId === id)));
  }

  public static isIdentifierOfBulkItem(item: SearchBulkItem, id: string): boolean {
    return item && item.bulkItemId === id;
  }

  public static isIdentifierOfEmployee(item: SearchEmployee, id: string): boolean {
    return item && item.employeeId === id;
  }

  public static isIdentifierOfProject(item: SearchProjectOrProjectGroup, id: string): boolean {
    return item && item.projectId === id;
  }

  public static isIdentifierOfStock(item: SearchStock, id: string): boolean {
    return item && item.stockId === id;
  }

  public static isIdentifierOfContact(item: ContactSearch, id: string): boolean {
    return item && item.contactId === id;
  }
}
