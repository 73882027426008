<bh-step-dialog class="alarm-dialog"
                [title]="(isEditMode ? 'modules.notification.alarmRuleAddEdit.edit' : 'modules.notification.alarmRuleAddEdit.add')|translate"
                [icon]="faExclamationTriangle">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="alarmRuleForm">
    <mat-horizontal-stepper linear labelPosition="bottom"
      fxFlex="100" fxLayout="column" [class.wnt-mat-stepper]="isWNTheme()">

      <mat-step [stepControl]="alarmTypeControl" *ngIf="!isEditMode">
        <ng-template matStepLabel>{{'modules.notification.alarmRuleAddEdit.selectAlarmType'|translate}}</ng-template>
        <div class="alarm-step" fxLayout="column" fxLayoutAlign="space-around stretch">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
            <bh-card-select-alarm-types class="alarm-step__type-select"
                            [options]="allowedAlarmTypeOptions"
                            [optionResolver]="alarmTypeResolver"
                            [formControlName]="controlNames.ALARM_TYPE">
            </bh-card-select-alarm-types>
          </div>
          <div fxFlex="10" fxLayout="row" fxLayoutAlign="end center" class="alarm-step__button-container">
            <button mat-raised-button matStepperNext [disabled]="alarmTypeControl.invalid" mat-raised-button>
              {{'general.buttons.continue'|translate}}
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="settingsForm">
        <ng-template matStepLabel>{{'modules.notification.alarmRuleAddEdit.alarmSettings'|translate}}</ng-template>
        <div class="alarm-step" fxLayout="column" fxLayoutAlign="space-around stretch">
          <div class="alarm-step__content" [ngSwitch]="currentSettingsType()" fxFlex fxLayout="row" fxLayoutAlign="center start">
            <ng-container *ngSwitchCase="settingsTypes.LIMIT">
              <bh-limit-settings class="alarm-step__content__small" fxFlex="70"
                                 [form]="settingsForm"
                                 [fieldData]="getAlarmSettingsFieldData()"
                                 [isWNTTheme]="isWNTheme()">
              </bh-limit-settings>
            </ng-container>
            <ng-container *ngSwitchCase="settingsTypes.LIMIT_PERCENTAGE">
              <bh-limit-percentage-settings class="alarm-step__content__small" fxFlex="70"
                                            [form]="settingsForm"
                                            [fieldData]="getAlarmSettingsFieldData()">
              </bh-limit-percentage-settings>
            </ng-container>
            <ng-container *ngSwitchCase="settingsTypes.GEOFENCE">
              <bh-geofence-selection-settings class="alarm-step__content__full-height" fxFlex="70"
                                              [form]="settingsForm"
                                              [fieldData]="getAlarmSettingsFieldData()"
                                              [globalGeofences]="globalGeofences">
              </bh-geofence-selection-settings>
            </ng-container>
            <ng-container *ngSwitchCase="settingsTypes.TIMEFENCING">
              <bh-timefencing-settings fxFlex="100"
                                       [form]="settingsForm"
                                       [fieldData]="getAlarmSettingsFieldData()">
              </bh-timefencing-settings>
            </ng-container>
            <ng-container *ngSwitchCase="settingsTypes.TRACKER_BATTERY_STATUS">
              <bh-tracker-battery-alarm-status fxFlex="100"
                                       [form]="settingsForm"
                                       [fieldData]="getAlarmSettingsFieldData()">
              </bh-tracker-battery-alarm-status>
            </ng-container>
            <ng-container *ngSwitchCase="settingsTypes.TELEMATICS_UNIT">
              <bh-inactive-telematic-unit-settings class="alarm-step__content__small" fxFlex="70"
                                                   [form]="settingsForm"
                                                   [fieldData]="getAlarmSettingsFieldData()">
              </bh-inactive-telematic-unit-settings>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <bh-explanation-card class="alarm-step__content__small" [text]="getAlarmTypeDefaultExploration(alarmTypeControl.value)"></bh-explanation-card>
            </ng-container>
          </div>

          <div fxFlex="10" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="alarm-step__button-container">
              <button *ngIf="!isEditMode" mat-raised-button matStepperPrevious mat-raised-button>
                {{'general.buttons.back'|translate}}
              </button>
            </div>
            <div class="alarm-step__button-container">
              <button mat-raised-button matStepperNext [disabled]="settingsForm.invalid" mat-raised-button>
                {{'general.buttons.continue'|translate}}
              </button>
              <button mat-raised-button
                      color="primary"
                      bhTimeoutSaveButton
                      (throttledClick)="save()"
                      [disabled]="!isValid()">
                {{'general.buttons.save'|translate}}
              </button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="dataForm">
        <ng-template matStepLabel>{{'modules.notification.alarmRuleAddEdit.additionalData'|translate}}</ng-template>
        <div class="alarm-step" fxLayout="column" fxLayoutAlign="space-around stretch">
          <div class="alarm-step__content" fxFlex fxLayout="row" fxLayoutAlign="center start">
            <bh-additional-data fxFlex="70"
                                [alarmType]="alarmTypeControl.value"
                                [form]="dataForm"
                                [labelOptions]="labels"
                                [equipmentTypeOptions]="equipmentTypes"
                                [emailRecipients]="this.alarmRule?.emailRecipientsFilledWithInfo"
                                [roleRecipients]="this.alarmRule?.userRolesEmailRecipientsFilledWithInfo">
            </bh-additional-data>
          </div>

          <div fxFlex="10" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="alarm-step__button-container">
              <button mat-raised-button matStepperPrevious mat-raised-button>{{'general.buttons.back'|translate}}</button>
            </div>
            <div class="alarm-step__button-container">
              <button mat-raised-button
                      color="primary"
                      bhTimeoutSaveButton
                      (throttledClick)="save()"
                      [disabled]="!isValid()">
                {{'general.buttons.save'|translate}}
              </button>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>
