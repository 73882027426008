import { LanguageService } from './../../../../../../../shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { getBrowserName, Utils } from '../../../../../../../shared/utils';
import { BaseEquipmentViewDamageComponent } from '../base/base-equipment-view-damage.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BrowserName } from 'app/shared/enums/browser-name.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-damage',
  templateUrl: './equipment-view-damage.component.html',
  styleUrls: ['./equipment-view-damage.component.scss']
})
export class EquipmentViewDamageComponent extends BaseEquipmentViewDamageComponent implements OnInit {

  public isFirefox: boolean;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected utils: Utils,
              protected equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, utils, equipmentStore, dialog, routerHistory, languageService,
      fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getLifeCycleDetails();
    this.isFirefox = getBrowserName() === BrowserName.FIREFOX;
  }

}
