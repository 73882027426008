import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { MaintenanceTypeFilterView } from 'app/shared/contract/filter/filter-view/maintenance-type-filter-view.interface';
import { FilterValue } from '../filter-value';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';

export class MaintenanceTypeFilterViewConverter extends BaseFilterViewConverter<MaintenanceTypeFilterView> {
  constructor(
    originalFilters: MaintenanceTypeFilterView[],
    selectedValues: FilterParams
  ) {
    super(FilterTypes.MAINTENANCE_TYPE, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: MaintenanceTypeFilterView): FilterValue {
    return {
      displayName: filterItem.maintenanceTypeName,
      storeName: filterItem.maintenanceTypeId,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.maintenanceTypeId),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
