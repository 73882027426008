import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { BaseEquipmentViewDetailComponent } from '../base/base-equipment-view-detail.component';
import { SetEquipmentDescriptionCommand } from '../../../../../contract/set-equipment-description.command';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { LocalUserStorageService } from '../../../../../../../shared/services/local-user-storage.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faHistory, faInfoCircle, faStickyNote } from '@fortawesome/pro-light-svg-icons';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../../assets/matomo/matomo-actions.enum';

@Component({
  selector: 'bh-equipment-view-detail',
  templateUrl: './equipment-view-detail.component.html',
  styleUrls: ['./equipment-view-detail.component.scss'],
})
export class EquipmentViewDetailComponent extends BaseEquipmentViewDetailComponent implements OnInit, AfterViewInit {

  private readonly localStorageKey: string = 'equipment-details-tab';
  public readonly faStickyNote: IconDefinition = faStickyNote;
  public readonly faHistory: IconDefinition = faHistory;
  public readonly faInfoCircle: IconDefinition = faInfoCircle;
  public readonly maxDescriptionLength = FieldLimit.ENTITY_DESCRIPTION;

  @ViewChild('detailTabGroup', { static: true })
  public detailTabGroup: MatTabGroup;

  constructor(private localStorageService: LocalUserStorageService,
              protected equipmentsService: EquipmentsService,
              protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              public equipmentCheckerService: EquipmentCheckerService,
              private matomoTracker: MatomoTracker) {
    super(equipmentStore, authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_GERNERAL);
  }

  public ngAfterViewInit(): void {
    const selectedTab = Number(this.localStorageService.getUserValue(this.localStorageKey));
    if (selectedTab >= 0 && selectedTab <= this.detailTabGroup._tabs.length) {
      this.detailTabGroup.selectedIndex = selectedTab;
    } else {
      this.localStorageService.setUserValue(this.localStorageKey, '0');
    }
  }

  public updateTab(event: MatTabChangeEvent): void {
    this.localStorageService.setUserValue(this.localStorageKey, `${event.index}`);
  }

  public saveEquipmentDescription(description: string): void {
    const cmd = new SetEquipmentDescriptionCommand(this.equipment.equipmentId, description);
    this.equipmentsService.setDescription(cmd).subscribe();
  }

}
