<mat-card class="entity-creation">
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>edit</mat-icon>
      <span>{{roleName}} {{'general.labels.change'|translate|lowercase}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.save'|translate}} |<mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.close'|translate}}</button>
      <button mat-raised-button (click)="resetPassword()">
        {{'modules.userrole.userAddEdit.newPassGenerated'|translate}}
      </button>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <form [formGroup]="userEditAdminForm" novalidate>
        <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="entity-creation-form-left" fxFlex="100">
            <div class="entity-creation-form-title" fxLayout="row" fxLayoutAlign="start stretch">
              <span>{{'modules.userrole.userAddEdit.userBaseData'|translate|uppercase}}</span>
              <mat-spinner class="spinner" *ngIf="requestInProgress"></mat-spinner>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <mat-form-field fxFlex="33">
                <mat-label>{{'general.firstName'|translate|uppercase}}</mat-label>
                <input matInput
                       formControlName="firstName"
                       [maxlength]="fieldLimit.NAME">
              </mat-form-field>

              <mat-form-field fxFlex="33">
                <mat-label>{{'general.lastName'|translate|uppercase}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="name"
                       [maxlength]="fieldLimit.NAME">
                <mat-error *ngIf="name.hasError('required')">
                  {{'shared.validation.required'|translate:{ value: 'general.lastName'|translate } }}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="33">
                <mat-label>{{'general.email'|translate|uppercase}}</mat-label>
                <input type="email" matInput
                       formControlName="email"
                       [readonly]="true">
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start" fxFill>
              <mat-form-field fxFlex="33">
                <mat-label>{{'modules.userrole.base.validUntil'|translate|uppercase}}</mat-label>
                <input matInput
                       formControlName="validUntil" autocomplete="off"
                       [matDatepicker]="picker"
                       [min]="today">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field fxFlex="33">
                <mat-label>{{'general.phone'|translate|uppercase}}</mat-label>
                <input type="tel" matInput
                       formControlName="mobileNumber"
                       [maxlength]="fieldLimit.PHONE_NUMBER">
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
