import { LanguageService } from 'app/shared/services/language.service';
import {Component} from '@angular/core';
import {BaseEquipmentViewTimelineComponent} from '../base/base-equipment-view-timeline.component';
import {ActivatedRoute, Router} from '@angular/router';
import {EquipmentsDataSource} from '../../../../../shared/equipments.datasource';
import {MatDialog} from '@angular/material/dialog';
import {KeycloakService} from 'app/core/keycloak';
import {DamageTypeDisplayPipe} from '../../../../../../../shared/pipes/damage-type.pipe';
import {LifeCycle} from '../../../../../contract/life-cycle';
import {LifeCycleType} from '../../../../../contract/lifecycle-type.enum';
import {LifeCycleDamage} from '../../../../../contract/life-cycle-damage';
import {LifeCycleOperatingHours} from '../../../../../contract/life-cycle-operating-hours';
import {LifeCycleMileage} from '../../../../../contract/life-cycle-mileage';
import {MaintenanceCategoryPipe} from '../../../../../../../shared/pipes/maintenance-category.pipe';
import {LifeCycleIncident} from '../../../../../contract/life-cycle-incident';
import {LifeCycleTask} from '../../../../../contract/life-cycle-task';
import {LifeCycleReminder} from '../../../../../contract/life-cycle-reminder';
import {RouterHistory} from '../../../../../../../shared/router-history';
import {LifeCycleEmployeeAdded} from '../../../../../contract/life-cycle-employee-added';
import {LifeCycleEmployeeRemoved} from '../../../../../contract/life-cycle-employee-removed';
import {LifeCycleEmployeeReplaced} from '../../../../../contract/life-cycle-employee-replaced';
import {LifeCycleCost} from '../../../../../contract/lifecycle-cost';
import { EquipmentEmployeeRolePipe } from '../../../../../../../shared/pipes/equipment-employee-role.pipe';
import { EquipmentsService } from '../../../../../shared/equipments.service';

@Component({
  selector: 'bh-mobile-equipment-view-timeline',
  templateUrl: './mobile-equipment-view-timeline.component.html',
  styleUrls: ['./mobile-equipment-view-timeline.component.scss']
})
export class MobileEquipmentViewTimelineComponent extends BaseEquipmentViewTimelineComponent {

  constructor(protected equipmentStore: EquipmentsDataSource,
              protected equipmentsService: EquipmentsService,
              protected employeeRolePipe: EquipmentEmployeeRolePipe,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected damageTypeDisplayPipe: DamageTypeDisplayPipe,
              protected maintenanceCategoryPipe: MaintenanceCategoryPipe,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(equipmentStore,
      equipmentsService,
      employeeRolePipe,
      router,
      route,
      authService,
      dialog,
      maintenanceCategoryPipe,
      routerHistory,
      languageService);
  }

  public getSubtitle(lifeCycle: LifeCycle): string {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        return this.damageTypeDisplayPipe.transform((<LifeCycleDamage>lifeCycle).damageType);

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return (<LifeCycleCost>lifeCycle).invoiceAmount + '€';

      case LifeCycleType.OPERATING_HOURS_UPDATE:
        return (<LifeCycleOperatingHours>lifeCycle).currentOperatingHours + '';

      case LifeCycleType.MILEAGE_UPDATE:
        return (<LifeCycleMileage>lifeCycle).currentMileage + '';

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        return (<LifeCycleIncident>lifeCycle).subject + '';

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        return (<LifeCycleTask>lifeCycle).name + '';

      case LifeCycleType.COMPLETED_REMINDER:
        return (<LifeCycleReminder>lifeCycle).reminderName + '';

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        const employeeAddedLifecycle = <LifeCycleEmployeeAdded>lifeCycle;
        return this.employeeRolePipe.transform(employeeAddedLifecycle.employeeRole) + ': ' +
            this.getFullName(employeeAddedLifecycle.employeeFirstName, employeeAddedLifecycle.employeeName);

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        const employeeReplacedLifecycle = <LifeCycleEmployeeReplaced>lifeCycle;
        return this.employeeRolePipe.transform(employeeReplacedLifecycle.employeeRole) + ': ' +
            this.getFullName(employeeReplacedLifecycle.newEmployeeFirstName, employeeReplacedLifecycle.newEmployeeName);

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        const employeeRemovedLifecycle = <LifeCycleEmployeeRemoved>lifeCycle;
        return this.employeeRolePipe.transform(employeeRemovedLifecycle.employeeRole) + ': ' +
            this.getFullName(employeeRemovedLifecycle.employeeFirstName, employeeRemovedLifecycle.employeeName);
    }
  }

  public openDetails(lifeCycle: LifeCycle): string {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/damages/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/invoice-details/${(<LifeCycleCost>lifeCycle).invoiceId}`]);
        return;

      case LifeCycleType.OPERATING_HOURS_UPDATE:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/operating-hours/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.MILEAGE_UPDATE:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/mileage/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/incidents/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/completed-maintenance-tasks/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.COMPLETED_REMINDER:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/completed-reminders/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/employee-assignment-added/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/employee-assignment-replaced/${lifeCycle.lifecycleId}`]);
        return;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        this.router.navigate([`mobile/equipments/${this.equipment.equipmentId}/employee-assignment-removed/${lifeCycle.lifecycleId}`]);
        return;
    }
  }

}
