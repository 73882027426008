import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnDefinition } from 'app/shared/column-definition';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { MaintenanceCompletedColumnDefinitionService } from '../../shared/service/maintenance-completed-column-definition.service';
import { MaintenanceTaskCompletedDataSource } from '../../shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import { saveAs } from 'file-saver';
import { LanguageService } from 'app/shared/services/language.service';
import moment from 'moment';
import { finalize } from 'rxjs';
import { TimezoneDatesService } from '../../../../../shared/timezone/timezone-dates.service';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-completed-export-dialog',
  templateUrl: './maintenance-task-completed-export-dialog.component.html',
  styleUrls: ['./maintenance-task-completed-export-dialog.component.scss']
})
export class MaintenanceTaskCompletedExportDialogComponent extends RoleAuthorityGuardsComponent implements OnInit {
  public selectAllColumns = false;
  public readonly selectedColumns: string[] = [];
  public applyCurrentFilter = true;
  public readonly sortableFields: { name: string, value: string }[] = [];
  public sortBy = MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_DATE;
  public sortDescending = false;
  public pendingRequest = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { selectedColumns: ColumnDefinition[] },
    private dialogRef: MatDialogRef<MaintenanceTaskCompletedExportDialogComponent>,
    authService: KeycloakService,
    private columnDefinitionService: MaintenanceCompletedColumnDefinitionService,
    private maintenanceDataSource: MaintenanceTaskCompletedDataSource,
    private languageService: LanguageService,
    private timezoneDatesService: TimezoneDatesService,
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.columnDefinitionService.availableColumns
    .filter(column => this.isSortableColumn(column))
    .forEach(column => this.sortableFields.push({
      name: column.readableName,
      value: column.cdkColumnDef
    }));
    this.sortableFields.sort((a, b) => a.name.localeCompare(b.name));
    this.data.selectedColumns.filter(column => this.isDisplayableColumn(column)).forEach(column => {
      this.selectedColumns.push(column.cdkColumnDef);
    });
  }

  public exportMaintenanceList(): void {
    this.pendingRequest = true;
    const columns: string[] = this.selectAllColumns ? this.getAllDisplayableColumns() : this.selectedColumns;
    this.maintenanceDataSource.exportMaintenanceCompletedList(columns, this.applyCurrentFilter, this.sortBy,
      this.sortDescending, this.timezoneDatesService.getSelectedTimezone())
    .pipe(
      untilDestroyed(this),
      finalize(() => this.pendingRequest = false))
    .subscribe((data: Blob) => this.saveList(data));
  }

  private isSortableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_NAME
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_COST_CENTER
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_CONSTRUCTION_YEAR
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_CATEGORY
      || columnDefinition.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_DATE
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY2
      || columnDefinition.cdkColumnDef === GENERAL_COLUMN_DEF.STATUS;
  }

  private isDisplayableColumn(columnDefinition: ColumnDefinition): boolean {
    return columnDefinition.cdkColumnDef !== GENERAL_COLUMN_DEF.OPEN_DETAILS
      && columnDefinition.type !== GENERAL_COLUMN_DEF.IMAGE
      && columnDefinition.type !== GENERAL_COLUMN_DEF.ICON
      && columnDefinition.type !== GENERAL_COLUMN_DEF.DETAILS
      && columnDefinition.type !== GENERAL_COLUMN_DEF.INFO
      && columnDefinition.cdkColumnDef !== MAINTENANCE_TASK_COLUMN_DEF.TASK_ACTION
      && columnDefinition.cdkColumnDef !== GENERAL_COLUMN_DEF.EQUIPMENT_INFO;
  }

  private getAllDisplayableColumns(): string[] {
    return this.columnDefinitionService.availableColumns
    .filter(column => this.isDisplayableColumn(column))
    .map(column => column.cdkColumnDef);
  }

  private saveList(data: Blob): void {
    this.pendingRequest = false;
    saveAs(data, this.createFileName());
    this.dialogRef.close();
  }

  private createFileName(): string {
    return this.languageService.getInstant('shared.maintenance.maintenanceListExport.maintenanceCompletedListFileName')
      + `_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`;
  }

}
