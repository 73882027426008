import { Component, Input, NgZone, OnInit } from '@angular/core';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';
import { MapLocationMarker } from '../../../interfaces/map-location-marker.interface';
import { MapLocationsCluster } from '../../../interfaces/map-location-cluster.interface';
import { ContactLocation } from 'app/modules/contact/contract/contact/contact-location.interface';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { NavigationMapperService } from 'app/shared/services/navigation-mapper.service';
import { DisplayService } from 'app/shared/display.service';

interface ContactLocationExtended extends ContactLocation {
  icon: IconDefinition;
}

type TableViewPayload = MapLocationsCluster<ContactLocation> | MapLocationMarker<ContactLocation>;

@Component({
  selector: 'bh-map-info-window-table-view-contact',
  templateUrl: './map-info-window-table-view-contact.html',
  styleUrls: ['../map-info-window-table-view.scss']
})
export class MapInfoWindowTableViewContactComponent implements OnInit, InfoWindowComponent {

  @Input() value: TableViewPayload;

  public items: ContactLocationExtended[] = [];

  constructor(
    private contactTypeResolver: ContactTypeResolverPipe,
    private navigationMapperService: NavigationMapperService,
    private displayService: DisplayService,
    private ngZone: NgZone,
  ) { }

  public ngOnInit(): void {
    this.items = this.value ? this.getItems(this.value) : [];
  }

  public navigateToViewDetails(id: string): void {
    this.ngZone.run(() => {
      this.navigationMapperService.navigateToContactViewDetails(id)
        .then(() => this.displayService.displayTableAndDetails());
    });
  }

  private getItems(value: TableViewPayload): ContactLocationExtended[] {
    let items: ContactLocation[];
    if (this.isCluster(value)) {
      items = value.markers.filter(m => Boolean(m?.item)).map(m => m.item);
    } else {
      items = value.item ? [value.item] : [];
    }
    return items.map(item => this.toExtendFormat(item));
  }

  private toExtendFormat(value: ContactLocation): ContactLocationExtended {
    return {
      ...value,
      icon: this.contactTypeResolver.resolveIcon(value.type),
    }
  }

  private isCluster(value: MapLocationsCluster | MapLocationMarker): value is MapLocationsCluster {
    return Boolean((<MapLocationsCluster>value).markers);
  }

}
