import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Languages } from '../../../shared/enums/languages.enum';
import { finalize } from 'rxjs/operators';
import { UsersService } from '../../userrole/shared/users.service';
import { faPenField } from '@fortawesome/pro-solid-svg-icons';
import { DigitalMatterProfileDatasource } from '../services/digital-matter-profile.datasource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DigitalMatterProfile } from '../../../shared/assign-digital-matter-unit/digital-matter-profile';
import { UserConfigurationService } from '../../../shared/services/user-configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  TelematicsUnitProfileEditComponent
} from '../telematics-unit-profile-edit/telematics-unit-profile-edit.component';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';

@UntilDestroy()
@Component({
  selector: 'bh-telematics-unit-profile-list',
  templateUrl: './telematics-unit-profile-list.component.html',
  styleUrls: ['./telematics-unit-profile-list.component.scss']
})
export class TelematicsUnitProfileListComponent implements OnInit, OnDestroy {

  protected readonly faPenField = faPenField;

  protected isLoadingLanguages = new BehaviorSubject<boolean>(true);

  public defaultLanguage: Languages;
  public availableLanguages: Languages[] = [];

  protected readonly displayedColumns = [
    'profileName',
    'profileDescription',
    'profileTemplateId',
    'profileFieldEdit',
  ];

  constructor(private usersService: UsersService,
              private userConfigurationService: UserConfigurationService,
              protected digitalMatterProfileDatasource: DigitalMatterProfileDatasource,
              protected route: ActivatedRoute,
              protected router: Router,
              protected dialog: MatDialog,
  ) {
    const edit = this.route.snapshot.data['edit'];

    if (edit) {
      this.dialog.open(TelematicsUnitProfileEditComponent, {
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['telematic-unit-profile/list']);
        }
      })
    }
  }

  public ngOnInit(): void {
    this.defaultLanguage = this.userConfigurationService.getFallbackLanguage();

    this.fetchLanguages();

    this.digitalMatterProfileDatasource.fetchAllDigitalMatterProfiles();
  }

  public ngOnDestroy(): void {}

  private fetchLanguages(): void {
    this.isLoadingLanguages.next(true);
    this.usersService.getAvailableLanguages()
      .pipe(untilDestroyed(this))
      .pipe(finalize(() => this.isLoadingLanguages.next(false)))
      .subscribe(languages => this.updateLanguages(languages));
  }

  private updateLanguages(languages: Languages[]): void {
    this.defaultLanguage = this.userConfigurationService.getFallbackLanguage();
    languages = languages
      .filter(lang => lang !== this.defaultLanguage)
      .sort((a, b) => a.localeCompare(b));
    this.availableLanguages = [this.defaultLanguage, ...languages];
  }

  public openEditProfileDialog(profileItem: DigitalMatterProfile, currentLanguage: Languages): void {
    const dialogRef = this.dialog.open(TelematicsUnitProfileEditComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profileItem: profileItem,
        currentLanguage: currentLanguage
      }
    });
    dialogRef.afterClosed().subscribe((hasBeenUpdated: boolean) => {
      if (hasBeenUpdated) {
        this.digitalMatterProfileDatasource.fetchAllDigitalMatterProfiles();
      }
    })
  }
}
