import { AfterContentInit, Directive, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateValidator } from '../custom-validators/date.validator';
import { LanguageService } from '../services/language.service';

@Directive({
  selector: '[bhEmptyOrValidDate]'
})
export class EmptyOrValidDateDirective implements AfterContentInit {

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private elementRef: ElementRef,
    private languageService: LanguageService,
  ) {}

  public ngAfterContentInit(): void {
    if (this.ngControl?.control) {
      this.addValidation();
    }
  }

  private addValidation(): void {
    this.ngControl.control.addValidators(DateValidator.isValidDateOrEmpty(
      this.elementRef,
      this.languageService
    ));
  }

}
