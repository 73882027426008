<div class="mobile-equipment-upload-image-component">

  <div class="upload-flex-container" fxFlexFill>
    <button mat-button class="m-view-attachment__button-add">
      <label for="fileInput">
        {{'shared.fileUpload.addFiles'|translate|uppercase}}
      </label>
    </button>
  </div>

  <input id="fileInput"
         ng2FileSelect
         type="file"
         [uploader]="fakeFileUploader"
         (onFileSelected)="onFileDropOrSelected($event)"
         [disabled]="uploading"
         multiple/>

  <mat-progress-bar mode="determinate"
                    *ngIf="fileUploader.queue.length"
                    [value]="fileUploader.progress">
  </mat-progress-bar>
</div>
