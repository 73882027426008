import { LanguageService } from './../services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from 'app/modules/disposition/shared/enums/project-status.enum';

@Pipe({
  name: 'projectStatus'
})
export class ProjectStatusPipe implements PipeTransform {
  private translationMap = {
    [ProjectStatus.ACTIVE]: this.translate('modules.disposition.projectStatus.active'),
    [ProjectStatus.DELETED]: this.translate('modules.disposition.projectStatus.deleted'),
    [ProjectStatus.FINISHED]: this.translate('modules.disposition.projectStatus.finished'),
    [ProjectStatus.PAUSED]: this.translate('modules.disposition.projectStatus.paused'),
  }

  constructor(private languageService: LanguageService) {}

  public transform(value: ProjectStatus): string {
    return this.translationMap[value] || value;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
