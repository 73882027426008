import {GuardedNavigableInputComponent} from '../../../navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import {KeycloakService} from '../../../../core/keycloak';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { RouterHistory } from '../../../router-history';

@Directive()
export abstract class BaseAssetListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public searchForm: UntypedFormGroup;
  private componentActive = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    this.buildSearchForm();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  protected isNotTransferPage(): boolean {
    return !this.router.url.includes('transfer/bulk-item-transfer-list')
      && !this.router.url.includes('transfer/equipment-transfer-list');
  }

  public buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl('')
    });
  }
}
