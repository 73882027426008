import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { BaseAttachmentTopfactComponent } from '../base/base-attachment-topfact.component';
import { TopfactDocument } from '../../../contract/topfact-document.interface';

@Component({
  selector: 'bh-attachment-topfact',
  templateUrl: './attachment-topfact.component.html',
  styleUrls: ['./attachment-topfact.component.scss']
})
export class AttachmentTopfactComponent extends BaseAttachmentTopfactComponent {

  constructor(private languageService: LanguageService) {
    super();
  }

  public getToolTip(document: TopfactDocument): string {
    return `${this.languageService.getInstant('shared.attachment.fileFormat')}: ${document.contentType}`;
  }
}
