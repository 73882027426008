import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { DimensionSystem } from '../enums/dimension-system.enum';

@Pipe({name: 'dimensionSystemDisplay'})
export class DimensionSystemPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {}

  public transform(value: DimensionSystem | string): string {
    switch (value) {
      case DimensionSystem.METRIC:
        return this.translate('shared.pipes.dimensionUnitDisplay.metric');
      case DimensionSystem.IMPERIAL:
        return this.translate('shared.pipes.dimensionUnitDisplay.imperial');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
