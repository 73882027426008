<div class="alarm" fxLayout="row" fxLayoutAlign="end center">
  <div class="alarm__left-col" fxLayout="column" fxFlex>
    <div class="alarm__left-col__title" fxLayout="row"
      (click)="navigate(alarm.navigateUrl)"
      [matTooltip]="alarm.navigateUrlTooltip">
      <div class="alarm__left-col__title__text">{{ alarm.title }}</div>
      <mat-icon class="icon-navigation">open_in_new</mat-icon>
    </div>
    <div class="alarm__left-col__content" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="level-icon" [ngClass]="getAlarmLevel(alarm.alarmLevel).color">
        warning
      </mat-icon>
      <div class="description" fxLayout="column">
        <div class="description__subtitle" fxLayout="row">
          <div class="description__subtitle__text">
            {{alarm.alarmType | alarmTypeDisplay}}
            <ng-container *ngIf="isGeoFenceAlarmWithProjectNameAndNumber()">
              <span class="description__subtitle__text__project-name">(</span>
              <pre class="description__subtitle__text__project-number"
              >{{ '# ' + alarm.projectNumber?.trim() }}</pre>
              <span class="description__subtitle__text__project-name">
                {{ alarm.projectName + ')' }}
              </span>
            </ng-container>
          </div>
          <mat-icon *ngIf="alarm.navigateUrlSubtitle"
            class="icon-navigation"
            [matTooltip]="alarm.navigateUrlSubtitleTooltip"
            (click)="navigate(alarm.navigateUrlSubtitle)">
            open_in_new
          </mat-icon>
        </div>
        <div class="description__message">{{alarm.message}}</div>
      </div>
    </div>
  </div>
  <mat-icon class="alarm__right-col icon-navigation"
    (click)="acknowledge($event, alarm.alarmId)"
    [matTooltip]="'modules.header.header.acknowledgeCloseAlarm'|translate">
    check_circle
  </mat-icon>
</div>
