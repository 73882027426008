import { Component, OnInit } from '@angular/core';
import {
  EquipmentsService, SearchDispositionEquipmentsBody,
  SearchDispositionEquipmentsRequest,
} from '../../../../../shared/equipments.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { SearchEquipment } from '../../../../../contract/search-equipment.interface';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { AssignSubEquipmentCommand } from '../../../../../contract/assign-sub-equipment-command';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isString } from 'lodash';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';


@UntilDestroy()
@Component({
  selector: 'bh-add-sub-equipment-dialog',
  templateUrl: './assign-sub-equipment-dialog.component.html',
  styleUrls: ['./assign-sub-equipment-dialog.component.scss']
})
export class AssignSubEquipmentDialogComponent implements OnInit {

  subEquipmentForm: UntypedFormGroup;
  equipments: SearchEquipment[] = [];
  selectedEquipments: ViewEquipment[] = [];
  assignedSubEquipments: ViewEquipment[] = [];
  equipment: ViewEquipment;

  constructor(
    private equipmentStore: EquipmentsDataSource,
    private equipmentService: EquipmentsService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AssignSubEquipmentDialogComponent>,
  ) {}

  public ngOnInit() {
    this.buildForm();
    this.searchFormSubscription();
    this.getAssignedSubEquipments();
  }

  private buildForm() {
    this.subEquipmentForm = this.formBuilder.group({
      equipmentIds:  ['', [<any>Validators.required]]
    });
  }

  getAssignedSubEquipments() {
    this.equipmentStore.subEquipments
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          if (res) {
            this.assignedSubEquipments = res;
          }
        });
  }

  private searchFormSubscription(): void {
    this.subEquipmentForm.get('equipmentIds').valueChanges
      .pipe(untilDestroyed(this), debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged())
      .subscribe(term => {
        if (!term || !isString(term)) {
          return this.equipments = [];
        }

        this.equipmentService.getEquipmentsSearch(this.getEquipmentSearchRequestParams(term))
          .subscribe(res => {
            this.equipments = res.content.filter(eq => {
              return [...this.selectedEquipments || [], ...this.assignedSubEquipments || [], this.equipment]
                .map(item => item && item.equipmentId)
                .indexOf(eq.equipmentId) === -1;
            });
          });
      });
  }

  private getEquipmentSearchRequestParams(term: string): SearchDispositionEquipmentsRequest {
    return {
      body: {
        terms: term,
        container: false,
        excludedEquipmentStatusCategory: [EquipmentStatusCategory.NOT_AVAILABLE],
      } as SearchDispositionEquipmentsBody,
    }
  }

  addEquipment(event: MatAutocompleteSelectedEvent, input: any): void {
    const equipment = event.option.value;

    this.selectedEquipments.push(equipment);

    if (input) {
      input.value = '';
    }
    this.subEquipmentForm.updateValueAndValidity();
  }

  removeEquipment(equipment: any): void {
    let index = this.selectedEquipments.indexOf(equipment);

    if (index >= 0) {
      this.selectedEquipments.splice(index, 1);
    }
  }

  public save() {
    if (this.isValid) {
      let cmd = new AssignSubEquipmentCommand();
      cmd.equipmentId = this.equipment.equipmentId;
      cmd.subEquipmentIds = this.selectedEquipments.map(item => item.equipmentId);
      this.equipmentStore.assignSubEquipment(cmd)
        .subscribe({
          next: () => this.dialogRef.close(),
          error: () => this.dialogRef.close(),
        });
    }
  }

  public isValid(): boolean {
    return this.subEquipmentForm.valid && this.selectedEquipments.length > 0;
  }

}
