import { environment } from 'environments/environment';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CustomerLabel } from '../../../../../shared/contract/customer-label.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DisplayService } from '../../../../../shared/display.service';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { SearchEmployee } from '../../../contract/search-employee.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faUser, faUsersClass } from '@fortawesome/pro-duotone-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons';
import { forkJoin, merge, pipe } from 'rxjs';
import * as _ from 'lodash';
import { EmployeeDispositionDatasource } from '../../../shared/employee-disposition.datasource';
import { LanguageService } from '../../../../../shared/services/language.service';
import { SearchEmployeeDisposition } from '../../../contract/search-employee-disposition.interface';
import {
  EmployeeAssignToProjectDialogComponent
} from 'app/modules/disposition/shared/employee-assign-to-project-dialog/landscape/employee-assign-to-project-dialog.component';
import { DispositionEmployee } from 'app/modules/disposition/contract/disposition-employee';
import {
  AddEmployeeToProjectAssignmentCommand
} from 'app/modules/disposition/contract/add-employee-project-assignment-command';
import { DispositionDataSource } from 'app/modules/disposition/shared/disposition.datasource';
import { isDefined } from '../../../../../shared/utils';
import { EmployeeManagementService } from '../../../shared/employee-management.service';
import { DraggableItemType } from 'app/shared/enums/draggable-item-type.enum';
import { DragDropContainerIdentifier } from 'app/shared/enums/drag-drop-container-identifier.enum';
import { DispositionColumnService } from 'app/modules/disposition/shared/services/disposition-column.service';
import { ThumbnailSize } from '../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-disposition-board-search-employee',
  templateUrl: 'disposition-board-search-employee.component.html',
  styleUrls: ['disposition-board-search-employee.component.scss']
})
export class DispositionBoardSearchEmployeeComponent extends RoleAuthorityGuardsComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  public readonly faUser: IconDefinition = faUser;
  public readonly faUsersClass: IconDefinition = faUsersClass;
  public readonly faSolidStar: IconDefinition = faSolidStar;

  public FILTER_ALL = 'ALL';
  public FILTER_AVAILABLE = 'AVAILABLE';
  public FILTER_UNAVAILABLE = 'UNAVAILABLE';
  public readonly dragType = DraggableItemType;
  public readonly ThumbnailSize = ThumbnailSize;

  public labels: CustomerLabel[] = [];
  public searchForm: UntypedFormGroup;
  public startDateControl: UntypedFormControl;
  public endDateControl: UntypedFormControl;
  public filterForm: UntypedFormGroup;
  public employeeDisposition: SearchEmployeeDisposition[];
  public selectedCompleteTeams: string[] = [];
  public selectedEmployees: SearchEmployee[] = [];
  public currentFilter = this.FILTER_ALL;
  public viewChecked = false;
  public readonly containerIdentifiers = DragDropContainerIdentifier;

  private termsControl: UntypedFormControl;

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  constructor(private formBuilder: UntypedFormBuilder,
              public dispositionStore: DispositionDataSource,
              private employeeManagementService: EmployeeManagementService,
              public employeeDispositionStore: EmployeeDispositionDatasource,
              private columnService: DispositionColumnService,
              protected router: Router,
              private dialog: MatDialog,
              private displayService: DisplayService,
              protected authService: KeycloakService,
              protected langService: LanguageService) {
    super(authService);
  }

  public get startDate(): Date {
    return moment(this.startDateControl.value).startOf('day').toDate();
  }

  public get endDate(): Date {
    const endDate = this.endDateControl.value;
    return endDate && moment(endDate).endOf('day').toDate();
  }

  public get employeeTeamStructure(): SearchEmployeeDisposition[] {
    return this.employeeDisposition.filter(team => team.teamName != null);
  }

  public get employeeEmployeeStructure(): SearchEmployee[] {
    return this.employeeDisposition
      .filter(team => team.teamName == null)
      .map(function (team) {
        return team.teamMembers[0];
      });
  }

  public ngOnInit(): void {
    this.columnService.dispositionEmployeeListViewPageSize.subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });
    this.employeeDispositionStore.employeeDisposition
      .pipe(untilDestroyed(this))
      .subscribe((res: SearchEmployeeDisposition[]) => {
        this.viewChecked = false;
        this.employeeDisposition = res;
      });

    this.buildSearchForm();
    this.buildFilterForm();

    this.getLabels();
    this.applySearchFilters();
    this.employeeDispositionStore.updateListing();
  }

  public ngOnDestroy(): void {
    // DON'T DELETE! https://github.com/NetanelBasal/ngx-take-until-destroy
  }

  public ngAfterViewInit(): void {
    this.onSearchFormType();
  }

  public selectFilter(filter: string): void {
    this.currentFilter = filter;
    this.onSearchFormSubmit();
  }

  public onSearchFormSubmit(): void {
    this.employeeDispositionStore.searchTerms = this.termsControl.value;
    this.employeeDispositionStore.searchLabels = this.filterForm.get('selectedLabels').value;
    if (this.currentFilter === this.FILTER_ALL) {
      this.employeeDispositionStore.searchAvailableFilter = null;
    }
    if (this.currentFilter === this.FILTER_AVAILABLE) {
      if (this.searchForm.valid) {
        this.employeeDispositionStore.searchAvailableFilter = true;
        this.employeeDispositionStore.searchAvailableFilterStart = this.startDate;
        this.employeeDispositionStore.searchAvailableFilterEnd = this.endDate;
      }
    }
    if (this.currentFilter === this.FILTER_UNAVAILABLE) {
      if (this.searchForm.valid) {
        this.employeeDispositionStore.searchAvailableFilter = false;
        this.employeeDispositionStore.searchAvailableFilterStart = this.startDate;
        this.employeeDispositionStore.searchAvailableFilterEnd = this.endDate;
      }
    }
    this.employeeDispositionStore.updateListing(0, this.paginator.pageSize);
    this.paginator.firstPage();
  }

  public onPaginateChange(event: PageEvent): void {
    this.employeeDispositionStore.updateListing(event.pageIndex, event.pageSize);
  }

  public onEmployeeCheckboxToggle(employee: SearchEmployee, event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectEmployee(employee);
      if (employee.teamName) {
        this.trySelectTeam(employee.teamName);
      }
    } else {
      this.deselectEmployee(employee.employeeId);
      if (employee.teamName) {
        this.deselectTeam(employee.teamName);
      }
    }
  }

  public onTeamCheckboxToggle(team: SearchEmployeeDisposition, event: MatCheckboxChange): void {
    if (event.checked) {
      team.teamMembers.forEach(employee => {
        this.selectEmployee(employee);
      });
      this.selectTeam(team.teamName);
    } else {
      team.teamMembers.forEach(employee => {
        this.deselectEmployee(employee.employeeId);
      });
      this.deselectTeam(team.teamName);
    }
  }

  public getDragDataEmployee(employee: SearchEmployee): SearchEmployeeDisposition {
    return {
      teamName: null,
      teamMembers: [employee]
    };
  }

  private selectEmployee(employee: SearchEmployee): void {
    if (this.selectedEmployees.map(e => e.employeeId).indexOf(employee.employeeId) === -1) {
      this.selectedEmployees.push(employee);
    }
  }

  private deselectEmployee(employeeId: string): void {
    this.selectedEmployees = this.selectedEmployees.filter(e => e.employeeId !== employeeId);
  }

  public deselectAllEmployees(): void {
    this.selectedEmployees = [];
  }

  private deselectAllTeams(): void {
    this.selectedCompleteTeams = [];
  }

  private trySelectTeam(teamName: string): void {
    const chosenTeam = this.employeeTeamStructure.filter(team => team.teamName = teamName)[0];
    let teamMemberIds = [];
    chosenTeam.teamMembers.forEach(teamMember => {
      teamMemberIds.push(teamMember.employeeId);
    });
    let selectedEmployeeIds = [];
    this.selectedEmployees.forEach(employee => {
      selectedEmployeeIds.push(employee.employeeId);
    });
    if (teamMemberIds.every(id => selectedEmployeeIds.includes(id))) {
      this.selectTeam(chosenTeam.teamName);
    }
  }

  private selectTeam(teamName: string): void {
    this.selectedCompleteTeams.push(teamName);
  }

  private deselectTeam(teamName: string): void {
    this.selectedCompleteTeams = this.selectedCompleteTeams.filter(name => name !== teamName);
  }

  public employeeSelected(employee: SearchEmployee): boolean {
    return this.selectedEmployees.map(e => e.employeeId).includes(employee.employeeId);
  }

  public employeeTeamFullySelected(team: SearchEmployeeDisposition): boolean {
    return team.teamMembers.filter(employee => this.selectedEmployees.indexOf(employee) === -1).length === 0;
  }

  public employeeTeamPartialSelected(team: SearchEmployeeDisposition): boolean {
    const numberOfSelectedTeamMembers = team.teamMembers.filter(employee =>
      this.selectedEmployees.indexOf(employee) !== -1).length;
    return numberOfSelectedTeamMembers > 0 && numberOfSelectedTeamMembers < team.teamMembers.length;
  }

  public openDispositionDialog(): void {
    const dialogRef = this.dialog.open(EmployeeAssignToProjectDialogComponent, <MatDialogConfig>{
      disableClose: true
    });

    dialogRef.componentInstance.employees = this.selectedEmployees.map(searchEmployee =>
      DispositionEmployee.fromSearchEmployee(searchEmployee));
    dialogRef.componentInstance.teams = this.selectedCompleteTeams;
    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.afterClosed()
      .subscribe((commands: AddEmployeeToProjectAssignmentCommand[]) =>
        this.onDispositionDialogClosed(commands)
      );
  }

  private onDispositionDialogClosed(commands: AddEmployeeToProjectAssignmentCommand[]): void {
    const userCanceledDialog = !(commands?.length > 0);
    if (userCanceledDialog) {
      return;
    }
    this.deselectAllTeams();
    this.deselectAllEmployees();
    const assignmentSubscriptions =
      commands.map(command => this.employeeDispositionStore.assignEmployeeToProject(command));
    forkJoin(assignmentSubscriptions)
      .subscribe(() => this.updateProjectsAndEmployeeList());
  }

  private updateProjectsAndEmployeeList() {
    setTimeout(() => {
      this.dispositionStore.getProjectsWithAssignments();
      this.employeeDispositionStore.updateListing();
    }, environment.DELAY_LONG)
  }

  /*
   * TODO: Remove when updating angular material solves the problem
   * The current version of angular material produces a flash of unstyled content when expansion panels are being rendered.
   * Disabling the animation on the panels fixes the problem, however, the animations are desired nonetheless.
   * To prevent the UI from exposing unexpected behavior while still keeping animations, the animations are activated on the
   * AfterViewChecked lifecycle hook, after the iterable elements have been initialized.
   *
   * Possibly related issue: https://github.com/angular/material2/issues/13870
   */
  public ngAfterViewChecked(): void {
    if (!this.viewChecked && this.employeeDisposition.length > 0) {
      // Changing the value in setTimeout prevents expressionchangedafterithasbeencheckederror
      setTimeout(() => this.viewChecked = true);
    }
  }

  public navigateToEmployee(employeeId: string): void {
    this.displayService.displayTableAndDetails();
    this.router.navigate([`assets/employee/list/${employeeId}/general`]);
  }

  public getEmployeeNameTooltip(employee: SearchEmployee): string {
    const info = this.translate('general.employee.s');
    let employeeName = `: ${employee.employeeName}, ${employee.employeeFirstName}`;
    if (employeeName.length > 25) {
      employeeName = '';
    }
    return `${info} ${employeeName}`;
  }

  public getTeamLeader(team: SearchEmployeeDisposition): string {
    let teamLeader = team.teamMembers.find(employee => employee.isTeamLeader);
    return teamLeader ? (teamLeader.employeeName + ', ' + teamLeader.employeeFirstName)
      : this.translate('modules.disposition.employeeSearch.noTeamLeader');
  }

  public getTeamNameTooltip(team: SearchEmployeeDisposition): string {
    const info = this.translate('modules.disposition.base.team');
    let teamName = `: ${team.teamName}`;
    if (teamName.length > 25) {
      teamName = '';
    }
    return `${info} ${teamName}`;
  }

  public getEmployeeOrganisationAddressTooltip(employee: SearchEmployee): string {
    const info = this.translate('modules.disposition.employeeSearch.organisationAddress');
    let organisationAddress = `: ${employee.organisationAddress.postalCode} ${employee.organisationAddress.city}`;
    if (organisationAddress && organisationAddress.length > 25) {
      organisationAddress = '';
    }
    return `${info} ${organisationAddress}`;
  }

  private getLabels(): void {
    this.employeeDispositionStore.getCustomerLabels()
      .pipe(untilDestroyed(this))
      .subscribe((labels: CustomerLabel[]) => this.labels = labels);
  }

  private onSearchFormType(): void {
    const operators = pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged(_.isEqual));

    merge(
      this.searchForm.valueChanges.pipe(operators),
      this.filterForm.valueChanges.pipe(operators)
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onSearchFormSubmit());
  }

  private buildSearchForm(): void {
    this.startDateControl = new UntypedFormControl(new Date());
    this.endDateControl = new UntypedFormControl(null);
    this.termsControl = new UntypedFormControl('');

    this.searchForm = this.formBuilder.group({
      terms: this.termsControl,
      startDate: this.startDateControl,
      endDate: this.endDateControl
    });
  }

  private buildFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      selectedLabels: ''
    });
  }

  private applySearchFilters(): void {
    const {
      searchTerms,
      searchLabels,
      searchAvailableFilter,
      searchAvailableFilterStart,
      searchAvailableFilterEnd
    } = this.employeeDispositionStore;

    this.filterForm.patchValue({ selectedLabels: searchLabels });

    const searchFormValues = { terms: searchTerms, startDate: new Date(), endDate: null };
    if (isDefined(searchAvailableFilter)) {
      searchFormValues.startDate = searchAvailableFilterStart;
      searchFormValues.endDate = searchAvailableFilterEnd;
      this.currentFilter = searchAvailableFilter ? this.FILTER_AVAILABLE : this.FILTER_UNAVAILABLE;
    }
    this.searchForm.patchValue(searchFormValues);
  }

  private translate(key: string): string {
    return this.langService.getInstant(key);
  }

}
