import { GENERAL_COLUMN_DEF, PROJECT_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';

export class MapConsts {
  public static readonly MIN_ZOOM = 0;
  public static readonly MAX_ZOOM = 22;
  public static readonly DEFAULT_CLUSTER_RADIUS = 60;
  public static readonly MIN_AUTO_REFRESH_MS = 5000;
  public static readonly GOOGLE_MAP_URL = 'https://www.google.com/maps';

  public static getDefaultMapConfiguration(): MapConfiguration {
    return {
      showEquipments: false,
      showProjects: false,
      showOrganisations: false,
      showContacts: false,
      showStocks: false,
      equipmentColumn: GENERAL_COLUMN_DEF.EQUIPMENT_NONE,
      projectColumn: PROJECT_COLUMN_DEF.PROJECT_NONE,
      organisationColumn: GENERAL_COLUMN_DEF.ORGANISATION_NONE,
      stockColumn: GENERAL_COLUMN_DEF.STOCK_NONE,
      contactColumn: GENERAL_COLUMN_DEF.CONTACT_NONE,
      gridSize: 50,
    }
  }
}
