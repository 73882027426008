import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ScheduledConnector} from '../contract/scheduled-connector/scheduled-connector';
import {AempV2Connector} from '../contract/aemp-v2/aemp-v2-connector';
import {MailConnector} from '../contract/mail/mail-connector';
import {CreateAempV2ConnectorCommand} from '../contract/aemp-v2/create-aemp-v2-connector-command';
import {CreateMailConnectorCommand} from '../contract/mail/create-mail-connector-command';
import {UpdateAempV2ConnectorCommand} from '../contract/aemp-v2/update-aemp-v2-connector-command';
import {ActivateScheduledConnectorCommand} from '../contract/scheduled-connector/activate-scheduled-connector-command';
import {
  DeactivateScheduledConnectorCommand
} from '../contract/scheduled-connector/deactivate-scheduled-connector-command';
import {Observable} from 'rxjs';
import {UpdateMailConnectorCommand} from '../contract/mail/update-mail-connector-command';
import {DeleteAempV2ConnectorCommand} from '../contract/aemp-v2/delete-aemp-v2-connector-command';
import {DeleteMailConnectorCommand} from '../contract/mail/delete-mail-connector-command';
import {
  CreateTrackUnitClassicConnectorCommand
} from '../contract/trackunit-classic/create-track-unit-classic-connector-command';
import {
  UpdateTrackUnitClassicConnectorCommand
} from '../contract/trackunit-classic/update-track-unit-classic-connector-command';
import {TrackUnitClassicConnector} from '../contract/trackunit-classic/track-unit-classic-connector';
import {
  DeleteTrackUnitClassicConnectorCommand
} from '../contract/trackunit-classic/delete-track-unit-classic-connector-command';
import {DeleteGpsOverIpConnectorCommand} from '../contract/gpsoverip/delete-gps-over-ip-connector-command';
import {CreateGpsOverIpConnectorCommand} from '../contract/gpsoverip/create-gps-over-ip-connector-command';
import {UpdateGpsOverIpConnectorCommand} from '../contract/gpsoverip/update-gps-over-ip-connector-command';
import {GpsOverIpConnector} from '../contract/gpsoverip/gps-over-ip-connector';
import {CreateRioConnectorCommand} from '../contract/rio/create-rio-connector-command';
import {UpdateRioConnectorCommand} from '../contract/rio/update-rio-connector-command';
import {DeleteRioConnectorCommand} from '../contract/rio/delete-rio-connector-command';
import {RioConnector} from '../contract/rio/rio-connector';
import {CreateReidlConnectorCommand} from '../contract/reidl/create-reidl-connector-command';
import {UpdateReidlConnectorCommand} from '../contract/reidl/update-reidl-connector-command';
import {ReidlConnector} from '../contract/reidl/reidl-connector';
import {DeleteReidlConnectorCommand} from '../contract/reidl/delete-reidl-connector-command';
import {CreateSinos2ConnectorCommand} from '../contract/sinos2/create-sinos2-connector-command';
import {UpdateSinos2ConnectorCommand} from '../contract/sinos2/update-sinos2-connector-command';
import {Sinos2Connector} from '../contract/sinos2/sinos2-connector';

@Injectable()
export class ConnectorService {

  private url = environment.APP_JOB_SERVICE_BASE_URL + '/api/v1/connector';

  constructor(private http: HttpClient) {
  }

  public getScheduledConnectors(): Observable<ScheduledConnector[]> {
    const url = this.url + '/scheduled';
    return this.http.get<ScheduledConnector[]>(url);
  }

  public activateScheduledConnector(command: ActivateScheduledConnectorCommand) {
    return this.http.post(this.url + '/scheduled/activate', JSON.stringify(command), {responseType: 'text'});
  }

  public deactivateScheduledConnector(command: DeactivateScheduledConnectorCommand) {
    return this.http.post(this.url + '/scheduled/deactivate', JSON.stringify(command), {responseType: 'text'});
  }

  public getAEMPv2Connector(connectorId: string): Observable<AempV2Connector> {
    const url = this.url + '/aemp-v2/' + connectorId;
    return this.http.get<AempV2Connector>(url);
  }

  public addAEMPv2Connector(command: CreateAempV2ConnectorCommand) {
    return this.http.post(this.url + '/aemp-v2/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateAEMPv2Connector(command: UpdateAempV2ConnectorCommand) {
    return this.http.post(this.url + '/aemp-v2/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteAEMPv2Connector(command: DeleteAempV2ConnectorCommand): Observable<string> {
    return this.http.post(this.url + '/aemp-v2/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public getMailConnector(connectorId: string): Observable<MailConnector> {
    const url = this.url + '/mail/' + connectorId;
    return this.http.get<MailConnector>(url)
  }

  public addMailConnector(command: CreateMailConnectorCommand) {
    return this.http.post(this.url + '/mail/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateMailConnector(command: UpdateMailConnectorCommand) {
    return this.http.post(this.url + '/mail/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteMailConnector(command: DeleteMailConnectorCommand) {
    return this.http.post(this.url + '/mail/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public getTrackUnitClassicConnector(connectorId: string): Observable<TrackUnitClassicConnector> {
    const url = this.url + '/trackunit-classic/' + connectorId;
    return this.http.get<TrackUnitClassicConnector>(url)
  }

  public addTrackUnitClassicConnector(command: CreateTrackUnitClassicConnectorCommand) {
    return this.http.post(this.url + '/trackunit-classic/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateTrackUnitClassicConnector(command: UpdateTrackUnitClassicConnectorCommand) {
    return this.http.post(this.url + '/trackunit-classic/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteTrackUnitClassicConnector(command: DeleteTrackUnitClassicConnectorCommand) {
    return this.http.post(this.url + '/trackunit-classic/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public getGpsOverIpConnector(connectorId: string): Observable<GpsOverIpConnector> {
    const url = this.url + '/gps-over-ip/' + connectorId;
    return this.http.get<GpsOverIpConnector>(url)
  }

  public addGpsOverIpConnector(command: CreateGpsOverIpConnectorCommand) {
    return this.http.post(this.url + '/gps-over-ip/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateGpsOverIpConnector(command: UpdateGpsOverIpConnectorCommand) {
    return this.http.post(this.url + '/gps-over-ip/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteGpsOverIpConnector(command: DeleteGpsOverIpConnectorCommand) {
    return this.http.post(this.url + '/gps-over-ip/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public getRioConnector(connectorId: string): Observable<RioConnector> {
    return this.http.get<RioConnector>(`${this.url}/rio/${connectorId}`)
  }

  public addRioConnector(command: CreateRioConnectorCommand) {
    return this.http.post(this.url + '/rio/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateRioConnector(command: UpdateRioConnectorCommand) {
    return this.http.post(this.url + '/rio/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteRioConnector(command: DeleteRioConnectorCommand) {
    return this.http.post(this.url + '/rio/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public addReidlConnector(command: CreateReidlConnectorCommand) {
    return this.http.post(this.url + '/reidl/create', JSON.stringify(command), {responseType: 'text'});
  }

  public addSinos2Connector(command: CreateSinos2ConnectorCommand) {
    return this.http.post(this.url + '/sinos2/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateReidlConnector(command: UpdateReidlConnectorCommand) {
    return this.http.post(this.url + '/reidl/update', JSON.stringify(command), {responseType: 'text'});
  }

  public updateSinos2Connector(command: UpdateSinos2ConnectorCommand) {
    return this.http.post(this.url + '/sinos2/update', JSON.stringify(command), {responseType: 'text'});
  }

  public getReidlConnector(connectorId: string): Observable<ReidlConnector> {
    return this.http.get<ReidlConnector>(`${this.url}/reidl/${connectorId}`)
  }

  public getSinos2Connector(connectorId: string): Observable<Sinos2Connector> {
    return this.http.get<Sinos2Connector>(`${this.url}/sinos2/${connectorId}`)
  }

  public deleteReidlConnector(command: DeleteReidlConnectorCommand) {
    return this.http.post(this.url + '/reidl/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteSinos2Connector(command: DeleteReidlConnectorCommand) {
    return this.http.post(this.url + '/sinos2/delete', JSON.stringify(command), {responseType: 'text'});
  }
}
