<p fxLayout="row" fxLayoutAlign="start center" class="details" fxLayoutGap="3px">
  <bh-equipment-status-icon class="status-icon" [status]="equipment?.status"></bh-equipment-status-icon>
  <mat-icon *ngIf="equipmentCheckerService.isSearchEquipmentLinkedToTelematicsUnit(equipment)"
            fontSet="icon-telematics_outline" class="telematics-link-icon"
            [matTooltip]="(equipmentCheckerService.hasEquipmentCurrentTelematicsData(equipment)
                        ? 'modules.equipment.equipmentList.telematicData'
                        : 'modules.equipment.equipmentList.telematicNoData')
                        |translate"
            [ngClass]="{'no-data': !equipmentCheckerService.hasEquipmentCurrentTelematicsData(equipment)}">
  </mat-icon>
  <mat-icon [matTooltip]="'modules.equipment.base.rentalEquipment'|translate"
            *ngIf="equipmentCheckerService.isRentalEquipment(equipment)" fontSet="icon-rent_outline"></mat-icon>
  <bh-equipment-transport-types-icon [equipment]="equipment"></bh-equipment-transport-types-icon>
  <span [ngStyle]="{'width': 'auto'}" class="text-overflow-ellipsis-nowrap">
                        {{equipment.equipmentSerialNumber ? (equipment.equipmentSerialNumber)
    : 'modules.equipment.equipmentList.noSerialNumber'|translate}}
    {{equipment.equipmentCustomerSerialNumber ? '/ ' +  equipment.equipmentCustomerSerialNumber : ''}}
                      </span>
</p>
