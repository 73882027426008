<bh-step-dialog class="employee-dialog" [ngClass]="{'dialog-employee' : employee}"
                [title]="(employee
                  ? 'modules.disposition.employeeAddEdit.editEmployee'
                  : 'modules.disposition.employeeAddEdit.createNewEmployee')|translate"
                [icon]="faUser">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="employeeForm">
      <mat-horizontal-stepper labelPosition="bottom" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch"
                              linear [ngClass]="{'no-display-header': employee}">
        <mat-step [stepControl]="employeeForm.get('coreData')"  [formGroup]="employeeForm.get('coreData')">
          <ng-template matStepLabel>{{'modules.disposition.employeeAddEdit.coreData'|translate}}*</ng-template>
          <div class="employee-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxFlex="80" fxLayout="row" class="employee-add-dialog-base-data" fxLayoutAlign="space-around stretch">
              <div fxFlex="45" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="10px">
                  <mat-form-field>
                    <mat-label>{{'modules.disposition.employeeAddEdit.firstName'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="employeeFirstName"
                           [maxlength]="fieldLimit.NAME">
                    <fa-icon matSuffix [icon]="faTag"></fa-icon>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'modules.disposition.employeeAddEdit.lastName'|translate}}</mat-label>
                    <input matInput required
                           bhRemoveWhitespaces
                           [formControl]="employeeName"
                           [onlySelfControlChanges]="false"
                           [maxlength]="fieldLimit.NAME">
                    <mat-error *ngIf="employeeName.hasError('required')">
                      {{'shared.validation.required'|translate:{value: 'modules.disposition.employeeAddEdit.lastName'|translate} }}
                    </mat-error>
                    <fa-icon matSuffix [icon]="faTag"></fa-icon>
                  </mat-form-field>
                </div>
                <mat-form-field>
                  <mat-label>{{'modules.disposition.employeeAddEdit.email'|translate}}</mat-label>
                  <input matInput
                         bhRemoveWhitespaces
                         [formControl]="employeeEmail"
                         [maxlength]="fieldLimit.EMAIL">
                  <mat-error *ngIf="employeeEmail.hasError('validEmail')">
                    {{'modules.disposition.employeeAddEdit.enterValidMailAddress'|translate}}
                  </mat-error>
                  <mat-error *ngIf="employeeEmail.hasError('taken')">
                    {{'shared.validation.alreadyAssigned'|translate:{value: 'general.email'|translate} }}
                  </mat-error>
                  <fa-icon matSuffix [icon]="faAt"></fa-icon>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'modules.disposition.employeeAddEdit.phoneNumberOffice'|translate}}</mat-label>
                  <input matInput
                         bhRemoveWhitespaces
                         [formControl]="employeeOfficePhoneNumber"
                         [maxlength]="fieldLimit.PHONE_NUMBER">
                  <fa-icon matSuffix [icon]="faPhone"></fa-icon>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'modules.disposition.employeeAddEdit.mobileNumberOffice'|translate}}</mat-label>
                  <input matInput
                         bhRemoveWhitespaces
                         [formControl]="employeeOfficeMobileNumber"
                         [maxlength]="fieldLimit.PHONE_NUMBER">
                  <fa-icon matSuffix [icon]="faPhone"></fa-icon>
                </mat-form-field>
              </div>
              <div fxFlex="45" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{'modules.disposition.employeeAddEdit.staffNumber'|translate}}</mat-label>
                  <input matInput required
                         bhRemoveWhitespaces
                         [formControl]="employeeStaffNumber"
                         [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                  <mat-error *ngIf="employeeStaffNumber.hasError('taken')">
                    {{'shared.validation.alreadyAssigned'|translate:{value: 'modules.disposition.employeeAddEdit.staffNumber'|translate} }}
                  </mat-error>
                  <mat-error *ngIf="employeeStaffNumber.hasError('required')">
                    {{'shared.validation.required'|translate:{value: 'modules.disposition.employeeAddEdit.staffNumber'|translate} }}
                  </mat-error>
                  <mat-error *ngIf="employeeStaffNumber.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                    {{'shared.validation.string.trimSpaces'|translate|translate}}
                  </mat-error>
                  <fa-icon matSuffix [icon]="faCalculatorAlt"></fa-icon>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{'general.org.s'|translate}}</mat-label>
                  <mat-select formControlName="organisation"
                              disableOptionCentering="true">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="filterControl"
                                             [placeholderLabel]="'modules.disposition.employeeAddEdit.searchOrganisation'|translate"
                                             [noEntriesFoundLabel]="'modules.disposition.employeeAddEdit.organisationsNotFound'|translate">

                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let organisation of organisations | async"
                                [value]="organisation.organisationId">
                      {{ organisation.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="organisation.hasError('required')">
                    {{'shared.validation.required'|translate:{value: 'general.org.s'|translate} }}
                  </mat-error>
                  <fa-icon matSuffix [icon]="faBuilding"></fa-icon>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-around stretch">
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <button class="bottom-button" mat-raised-button matStepperNext type="button"
                      [disabled]="!employeeForm.get('coreData').valid">
                {{'general.buttons.continue'|translate}}
              </button>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="employeeForm.get('privateData')" [formGroup]="employeeForm.get('privateData')">
          <ng-template matStepLabel>{{'modules.disposition.employeeAddEdit.privateData'|translate}}</ng-template>
          <div class="employee-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxFlex="80" fxLayout="row" class="employee-add-dialog-base-data" fxLayoutAlign="space-around stretch">
              <div fxFlex="45" fxLayout="column">
                <bh-map-location-select
                    fxFlex="100"
                    [initializeMapTypeControl]="false"
                    [address]="employeeAddress.value"
                    [mandatory]="false"
                    (localityChange)="updateCity($event)"
                    (postalCodeChange)="updatePostalCode($event)"
                    (routeChange)="updateStreet($event)"
                    (streetNumberChange)="updateStreetNumber($event)"
                    data-osp-test="map-location-select">
                  </bh-map-location-select>
              </div>
              <div fxFlex="45" fxLayout="column">
                <div fxLayout="column">
                  <div fxLayout fxLayoutGap="10px" formGroupName="employeeAddress">
                    <div fxLayoutAlign="row" fxFlex="70">
                      <mat-form-field>
                        <mat-label>{{'general.address.street'|translate}}</mat-label>
                        <input matInput
                               bhRemoveWhitespaces
                               [formControl]="street"
                               [maxlength]="fieldLimit.STREET">
                        <fa-icon matSuffix [icon]="faMapMarkerAlt"></fa-icon>
                      </mat-form-field>
                    </div>
                    <div fxLayoutAlign="row" fxFlex="30">
                      <mat-form-field>
                        <mat-label>{{'general.address.number'|translate}}</mat-label>
                        <input matInput
                               bhRemoveWhitespaces
                               [formControl]="streetNumber"
                               [maxlength]="fieldLimit.STREET_NUMBER">
                        <fa-icon matSuffix [icon]="faMapMarkerAlt"></fa-icon>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout fxLayoutGap="10px" formGroupName="employeeAddress">
                    <div fxLayoutAlign="row" fxFlex="30">
                      <mat-form-field>
                        <mat-label>{{'general.address.zipcode'|translate}}</mat-label>
                        <input matInput
                               bhRemoveWhitespaces
                               [formControl]="postalCode"
                               [maxlength]="fieldLimit.POSTAL_CODE">
                        <fa-icon matSuffix [icon]="faMapMarkerAlt"></fa-icon>
                      </mat-form-field>
                    </div>
                    <div fxLayoutAlign="row" fxFlex="70">
                      <mat-form-field>
                        <mat-label>{{'general.address.city'|translate}}</mat-label>
                        <input matInput
                               bhRemoveWhitespaces
                               [formControl]="city"
                               [maxlength]="fieldLimit.CITY">
                        <fa-icon matSuffix [icon]="faMapMarkerAlt"></fa-icon>
                      </mat-form-field>
                    </div>
                  </div>
                  <mat-form-field>
                    <mat-label>{{'modules.disposition.employeeAddEdit.phoneNumberHome'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="employeeHomePhoneNumber"
                           [maxlength]="fieldLimit.PHONE_NUMBER">
                    <fa-icon matSuffix [icon]="faPhone"></fa-icon>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{'modules.disposition.employeeAddEdit.mobileNumberHome'|translate}}</mat-label>
                    <input matInput
                           bhRemoveWhitespaces
                           [formControl]="employeeHomeMobileNumber"
                           [maxlength]="fieldLimit.PHONE_NUMBER">
                    <fa-icon matSuffix [icon]="faPhone"></fa-icon>
                  </mat-form-field>
                </div>

                <div class="error" *ngIf="employeeAddress.hasError('notResolved') && employeeAddress.dirty">
                  {{'modules.disposition.employeeAddEdit.addressCannotBeResolved'|translate}}
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-around stretch">
              <button class="bottom-button" mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>

<ng-template #saveButton>
  <button class="bottom-button"
          mat-raised-button
          (click)="save()"
          [disabled]="!isValid() || saveInProgress"
          color="primary" type="button"
          data-osp-test="button-save">
    {{'general.buttons.save'|translate}}
  </button>
</ng-template>
