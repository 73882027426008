import { MatSort } from '@angular/material/sort';
import { MatSortController } from './mat-sort-controller.class';
import { MatTable } from '@angular/material/table';
import { MatTableController } from './mat-table-controller.class';
import { ColumnConfigsController } from './column-config-controller.class';
import { NgZone, Renderer2 } from '@angular/core';

export class ControllerFactory {
  public static createMatTableController(table: MatTable<any>): MatTableController {
    return new MatTableController(table);
  }

  public static createMatSortController(sort: MatSort): MatSortController {
    return new MatSortController(sort);
  }

  public static createColumnConfigsController(table: MatTable<any>, renderer: Renderer2, zone: NgZone): ColumnConfigsController {
    return new ColumnConfigsController(table, renderer, zone);
  }
}
