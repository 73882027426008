import { environment } from 'environments/environment';
import { EquipmentProjectAssignments } from '../contract/equipment-project-assignments.interface';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { DataSource } from '@angular/cdk/table';
import { SearchEquipment } from '../../equipment/contract/search-equipment.interface';
import {
  EquipmentsService,
  SearchDispositionEquipmentsBody,
  SearchDispositionEquipmentsRequest,
} from '../../equipment/shared/equipments.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ProjectsService } from './project.service';
import { ViewProject } from '../contract/view-project.interface';
import { AssignEquipmentToProjectCommand } from '../../equipment/contract/assign-equipment-to-project-command';
import { DeleteEquipmentAssignmentCommand } from '../../equipment/contract/delete-equipment-assignment-command';
import { SearchEquipmentTransport } from '../../equipment/contract/search-equipment-transport';
import { map, switchMap, tap } from 'rxjs/operators';
import { ViewProjectMapper } from './view-project-mapper';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { AssigneeCount } from '../contract/assignee-count';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { DateUtils } from 'app/shared/dateUtils';
import { ListType } from 'app/shared/enums/list-type.enum';
import { SearchProjectIncludingAssignments } from '../contract/search-project-including-assignments.interface';
import { EmployeeProjectAssignments } from '../contract/employee-project-assignments.interface';
import { KeycloakService } from '../../../core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { IViewProjectFilter } from '../contract/view-project-filter.interface';
import { DispositionColumnService } from './services/disposition-column.service';
import { EquipmentStatusCategory } from '../../equipment/contract/equipment-status-category.enum';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { EquipmentFilterRequestParams } from 'app/shared/contract/filter/equipment/equipment-filter-request-params.interface';
import { EquipmentFilterType } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-type.interface';
import _ from 'lodash';
import { EquipmentAdditionalFieldService } from '../../equipment/shared/services/equipment-additional-fields.service';
import { AdditionalFieldValue } from '../../osp-ui/rich-components/bh-additional-field/additional-field';

@Injectable()
export class DispositionDataSource extends DataSource<SearchEquipment> {

  private _data: BehaviorSubject<SearchEquipment[]> = new BehaviorSubject([]);
  public readonly equipments: Observable<SearchEquipment[]> = this._data.asObservable();
  private _equipmentsTransport: BehaviorSubject<SearchEquipmentTransport[]> = new BehaviorSubject([]);
  public readonly equipmentsTransport: Observable<SearchEquipmentTransport[]> = this._equipmentsTransport.asObservable();
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _additionalFields: BehaviorSubject<AdditionalFieldValue[]> = new BehaviorSubject([]);
  public readonly additionalFields: Observable<AdditionalFieldValue[]> = this._additionalFields.asObservable();
  private _projectsWithAssignments: BehaviorSubject<ViewProject[]> = new BehaviorSubject([]);
  public readonly projectsWithAssignments: Observable<ViewProject[]> = this._projectsWithAssignments.asObservable();
  private _terms: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly terms: Observable<string> = this._terms.asObservable();
  private _selectedTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public readonly selectedTypes: Observable<string[]> = this._selectedTypes.asObservable();
  private _showFinished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly showFinished: Observable<boolean> = this._showFinished.asObservable();
  private _customerLabels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  public readonly customerLabels: Observable<CustomerLabel[]> = this._customerLabels.asObservable();
  private _labels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public readonly labels: Observable<string[]> = this._labels.asObservable();
  private _pageIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly pageIndex: Observable<number> = this._pageIndex.asObservable();
  private _pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(25);
  public readonly pageSize: Observable<number> = this._pageSize.asObservable();
  private _totalProjects: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly totalProjects: Observable<number> = this._totalProjects.asObservable();
  private _projectAssignees: BehaviorSubject<AssigneeCount[]> = new BehaviorSubject<AssigneeCount[]>([]);
  public readonly projectAssignees: Observable<AssigneeCount[]> = this._projectAssignees.asObservable();
  private _selectedProjectAssignees: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public readonly selectedProjectAssignees: Observable<string[]> = this._selectedProjectAssignees.asObservable();
  private _assignmentsByEquipmentIds: BehaviorSubject<EquipmentProjectAssignments> = new BehaviorSubject(null);
  public readonly assignmentsByEquipmentIds: Observable<EquipmentProjectAssignments> = this._assignmentsByEquipmentIds.asObservable();
  private _assignmentsByEmployeeIds: BehaviorSubject<EmployeeProjectAssignments> = new BehaviorSubject(null);
  public readonly assignmentsByEmployeeIds: Observable<EmployeeProjectAssignments> = this._assignmentsByEmployeeIds.asObservable();

  private _searchLabelsAvailable = new Subject<CustomerLabel[]>();
  private _searchEquipmentTypesAvailable = new Subject<EquipmentTypeFilterView[]>();
  public readonly searchLabelsAvailable = this._searchLabelsAvailable.asObservable();
  public readonly searchEquipmentTypesAvailable = this._searchEquipmentTypesAvailable.asObservable();
  public showIrrelevantAssignments = false;
  private _pagination: PaginationInfo;
  private _searchTerms: string = null;
  private _searchEquipmentTypeIds: string[] = [];
  private _searchExcludedEquipmentStatusCategories: EquipmentStatusCategory[] = [EquipmentStatusCategory.NOT_AVAILABLE];
  private _searchAvailableFilter: boolean = null;
  private _searchAvailableFilterStart: Date = null;
  private _searchAvailableFilterEnd: Date = null;
  private _searchTransportStart: Date = null;
  private _searchTransportEnd: Date = null;
  private _searchLabels: string[] = [];
  private _fullScreen = false;
  private _isTimeline = true;

  private readonly isLoadingProjects = new BehaviorSubject<boolean>(false);
  private readonly isLoadingEquipmentProjectAssignments = new BehaviorSubject<boolean>(false);
  private readonly isLoadingEmployeeProjectAssignments = new BehaviorSubject<boolean>(false);
  public readonly isLoadingDispositionTimeline = combineLatest([
    this.isLoadingProjects.asObservable(),
    this.isLoadingEquipmentProjectAssignments.asObservable(),
    this.isLoadingEmployeeProjectAssignments.asObservable(),
  ]).pipe(map(states => states.some(Boolean)));


  constructor(private _equipmentsService: EquipmentsService,
              private _equipmentAdditionalFieldService: EquipmentAdditionalFieldService,
              private _projectsService: ProjectsService,
              private _authService: KeycloakService,
              private dispositionColumnService: DispositionColumnService,
              onlineStatusService: OnlineStatusService) {
    super();
    if (onlineStatusService.onlineStatus) {
      this.initPagination();
    }
    this.dispositionColumnService.dispositionEquipmentListViewPageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
    this.dispositionColumnService.dispositionBoardViewPageSize.subscribe((pageSize: number) => {
      this._pageSize.next(pageSize);
    });
  }

  get pagination(): PaginationInfo {
    return this._pagination;
  }

  set searchTerms(searchTerms: string) {
    this._searchTerms = searchTerms;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchEquipmentTypeIds(searchEquipmentTypeIds: string[]) {
    if (_.isEqual(this._searchEquipmentTypeIds, searchEquipmentTypeIds)) {
      this._searchEquipmentTypeIds = searchEquipmentTypeIds;
    } else {
      this._searchEquipmentTypeIds = searchEquipmentTypeIds;
      this.updateAvailableSearchLabel();
    }
  }

  get searchEquipmentTypeIds(): string[] {
    return this._searchEquipmentTypeIds;
  }

  set searchAvailableFilter(searchAvailableFilter: boolean) {
    this._searchAvailableFilter = searchAvailableFilter;
  }

  get searchAvailableFilter(): boolean {
    return this._searchAvailableFilter;
  }

  set searchAvailableFilterStart(searchAvailableFilterStart: Date) {
    this._searchAvailableFilterStart = searchAvailableFilterStart;
  }

  get searchAvailableFilterStart(): Date {
    return this._searchAvailableFilterStart;
  }

  set searchAvailableFilterEnd(searchAvailableFilterEnd: Date) {
    this._searchAvailableFilterEnd = searchAvailableFilterEnd;
  }

  get searchAvailableFilterEnd(): Date {
    return this._searchAvailableFilterEnd;
  }

  set searchTransportStart(searchTransportStart: Date) {
    this._searchTransportStart = searchTransportStart;
  }

  set searchTransportEnd(searchTransportEnd: Date) {
    this._searchTransportEnd = searchTransportEnd;
  }

  set searchLabels(searchLabels: string[]) {
    if (_.isEqual(this._searchLabels, searchLabels)) {
      this._searchLabels = searchLabels;
    } else {
      this._searchLabels = searchLabels;
      this.updateAvailableEquipmentTypes();
    }
  }

  get searchLabels(): string[] {
    return this._searchLabels;
  }

  get fullScreen(): boolean {
    return this._fullScreen;
  }

  get isTimeline(): boolean {
    return this._isTimeline;
  }

  get viewProjectsWithAssignments(): ViewProject[] {
    return this._projectsWithAssignments.value;
  }

  public toggleTimeline(): void {
    this._isTimeline = !this._isTimeline;
  }

  public toggleFullScreen(): void {
    this._fullScreen = !this._fullScreen;
  }

  public connect(): Observable<SearchEquipment[]> {
    return this.equipments;
  }

  public disconnect(): void {
  }

  public loadAdditionalFields(equipmentId: string): void {
    this._equipmentAdditionalFieldService
      .getAssignedFields(equipmentId)
      .subscribe(res => this._additionalFields.next(res));
  }

  public resetAdditionalFields(): void {
    this._additionalFields.next([]);
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.dispositionColumnService.selectEquipmentListPageSize(size);
    }
    this.pagination.size = size;
    this.pagination.index = index;

    let params: SearchDispositionEquipmentsRequest = {
      page: index,
      size: size,
      body: {
        terms: this._searchTerms,
        excludedEquipmentStatusCategory: this._searchExcludedEquipmentStatusCategories,
        equipmentTypes: this._searchEquipmentTypeIds,
        customerLabels: this._searchLabels,
        available: this._searchAvailableFilter,
      } as SearchDispositionEquipmentsBody,
    };

    if (params.body.available !== null) {
      params.body.availableStart = moment(this._searchAvailableFilterStart).format('YYYY-MM-DD');
      params.body.availableEnd = moment(DateUtils.endDateOrDefault(this._searchAvailableFilterEnd)).format('YYYY-MM-DD');
    }

    this._equipmentsService
    .getEquipmentsSearch(params)
    .subscribe(res => this.updateStoreData(res));
  }

  public updateAvailableSearchLabel(): void {
    this._equipmentsService.getCustomerLabels(this.getEquipmentFilterRequestParams())
      .subscribe(labels => this._searchLabelsAvailable.next(labels));
  }

  public updateAvailableEquipmentTypes(): void {
    this._equipmentsService.getGroupedEquipmentTypeCounts(this.getEquipmentFilterRequestParams())
      .subscribe(types => this._searchEquipmentTypesAvailable.next(types));
  }

  public getEquipmentsTransportSearch(): void {
    let params = {
      page: this.pagination.index,
      size: this.pagination.size,

      terms: this._searchTerms,
      type: this._searchEquipmentTypeIds,
      excludedEquipmentStatusCategory: this._searchExcludedEquipmentStatusCategories,
      label: this._searchLabels,
      available: this._searchAvailableFilter,
      availableStart: null,
      availableEnd: null,
      transportStart: moment(this._searchTransportStart).format('YYYY-MM-DD'),
      transportEnd: this.getValidEndDate(this._searchTransportEnd).format('YYYY-MM-DD'),
    };

    if (params.available !== null) {
      params.availableStart = moment(this._searchAvailableFilterStart).format('YYYY-MM-DD');
      params.availableEnd = moment(DateUtils.endDateOrDefault(this._searchAvailableFilterEnd)).format('YYYY-MM-DD');
    }

    if (this._authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this._equipmentsService
      .getEquipmentsTransportSearch(params)
      .subscribe(res => {
        this._equipmentsTransport.next(res);
      });
    }
  }

  public assignEquipmentToProject(cmd: AssignEquipmentToProjectCommand): Observable<string> {
    return this._equipmentsService
    .assignToProject(cmd)
    .pipe(switchMap((res: any) => {
      return of(res);
    }));
  }

  public deleteAssignment(cmd: DeleteEquipmentAssignmentCommand): Observable<string> {
    return this._equipmentsService
    .deleteAssignment(cmd)
    .pipe(switchMap((res: any) => {
      setTimeout(() => {
        this.getProjectsWithAssignments();
      }, environment.DELAY_LONG);
      return of(res);
    }));
  }

  public getProjectsWithAssignments(): void {
    const params = {
      page: this._pageIndex.value,
      size: this._pageSize.value,
      labels: this._labels.value,
      hideFinished: this._showFinished.value,
      assigneeIds: this._selectedProjectAssignees.value,
      terms: this._terms.value,
      showEquipments: this._selectedTypes.value.includes(ListType.EQUIPMENTS),
      showEmployees: this._selectedTypes.value.includes(ListType.EMPLOYEES),
    };

    this.isLoadingProjects.next(true);
    this._projectsService.searchProjectsIncludingAssignments(params)
      .pipe(
        tap(({ totalElements }) => this._totalProjects.next(totalElements)),
        map((projects: PagedResponse<SearchProjectIncludingAssignments>) => (projects?.content ?? [])),
        map(projects => projects.map(ViewProjectMapper.fromSearchProjectsIncludingAssignments)))
      .subscribe({
        next: projects => {
          this._projectsWithAssignments.next(projects);
          this.isLoadingProjects.next(false);
        },
        error: () => this.isLoadingProjects.next(false),
      })
  }

  public getAllAssignmentsForEquipmentsByProjectIds(projectIds: string[]): void {
    if (!projectIds || projectIds.length === 0) {
      this._assignmentsByEquipmentIds.next(null);
      return;
    }

    this.isLoadingEquipmentProjectAssignments.next(true);
    this._projectsService.getAllAssignmentsForEquipmentsByProjectIds(projectIds)
      .subscribe({
        next: resp => {
          this._assignmentsByEquipmentIds.next(resp);
          this.isLoadingEquipmentProjectAssignments.next(false);
        },
        error: () => this.isLoadingEquipmentProjectAssignments.next(false),
      })
  }

  public getAssignmentByEmployeeIds(employeeIds: string[]): void {
    if (!employeeIds || employeeIds.length === 0) {
      this._assignmentsByEmployeeIds.next(null);
      return;
    }

    this.isLoadingEmployeeProjectAssignments.next(true);
    this._projectsService.getAssignmentsByEmployeeIds(employeeIds)
      .subscribe({
        next: resp => {
          this._assignmentsByEmployeeIds.next(resp);
          this.isLoadingEmployeeProjectAssignments.next(false);
        },
        error: () => this.isLoadingEmployeeProjectAssignments.next(false),
      })
  }

  public changeSelectedType(selectedType: string[]) {
    this._selectedTypes.next(selectedType);
  }

  changePage(pageIndex: number, pageSize: number) {
    if (pageSize !== this._pageSize.getValue()) {
      this.dispositionColumnService.selectBoardViewPageSize(pageSize);
    }
    this._pageIndex.next(pageIndex);
    this._pageSize.next(pageSize);
  }

  changeSearchTerms(terms: any) {
    this._terms.next(terms);
  }

  changeHideFinished(hideFinished: boolean) {
    this._showFinished.next(hideFinished);
  }

  changeLabels(labels: string[]): void {
    this._labels.next(labels);
  }

  getFilterableCustomerLabels(): void {
    this._projectsService.getFilterableCustomerLabels(this.getCurrentFilters())
    .subscribe(labels => this._customerLabels.next(labels));
  }

  getFilterableProjectAssignees(): void {
    this._projectsService.getFilterableProjectAssignees(this.getCurrentFilters())
    .subscribe(projectAssignees => this._projectAssignees.next(projectAssignees));
  }

  changeProjectAssignees(projectAssigneeIds: string[]): void {
    this._selectedProjectAssignees.next(projectAssigneeIds);
  }

  private getCurrentFilters(): IViewProjectFilter {
    return {
      labels: this._searchLabels,
      assignedEmployeeIds: this._selectedProjectAssignees.value,
      hideFinished: this._showFinished.value,
    };
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private getValidEndDate(date: Date): Moment {
    const maxDate = moment('9999-12-31');
    const endDate = moment(date);
    return endDate.isAfter(maxDate) ? maxDate : endDate;
  }


  private updateStoreData(res: PagedResponse<SearchEquipment>): void {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  private getEquipmentFilterRequestParams(): EquipmentFilterRequestParams {
    return {
      [EquipmentFilterType.ORGANISATION]: null,
      [EquipmentFilterType.ASSIGNED_EMPLOYEE]: null,
      [EquipmentFilterType.LABEL]: this.searchLabels,
      [EquipmentFilterType.CLASS]: this.searchEquipmentTypeIds,
      [EquipmentFilterType.WITHOUT_SUBEQUIPMENTS]: false,
      [EquipmentFilterType.TRANSPORT_VEHICLE_ONLY]: false,
    }
  }
}
