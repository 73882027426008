import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { UsersStore } from '../../shared/users.store';
import { EmailInUseValidator } from '../../../../shared/custom-validators/email-in-use.validator';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';

export class UserAddBaseComponent extends GuardedNavigableInputComponent {

  public readonly fieldLimit = FieldLimit;

  userAddForm: UntypedFormGroup;


  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected usersStore: UsersStore,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  get email(): AbstractControl | null {
    return this.userAddForm.get('email');
  }

  get name(): AbstractControl | null {
    return this.userAddForm.get('name');
  }

  setEmailInUseValidator(): void {
    this.email.setAsyncValidators(
      asyncValidatorFactory((value) => EmailInUseValidator.isValid(value, this.usersStore))
    );
  }
}
