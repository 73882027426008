import { environment } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { ViewOrganisation, ViewOrganisationFlatNode } from '../../contract/view-organisation.interface';
import { OrganisationsService } from '../../shared/organisations.service';
import { KeycloakService } from '../../../../core/keycloak';
import { CustomersService } from '../../shared/customers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationMoveComponent } from '../organisation-move/organisation-move.component';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationViewArgs, OrganisationViewComponent } from '../organisation-view/organisation-view.component';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OrganisationDeleteComponent } from '../../shared/organisation-delete/organisation-delete.component';
import { RouterHistory } from '../../../../shared/router-history';
import { delay } from 'rxjs/operators';
import { state, style, trigger } from '@angular/animations';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'bh-organisation-list',
  templateUrl: 'organisation-list.component.html',
  styleUrls: ['organisation-list.component.scss'],
})
export class OrganisationListComponent extends GuardedNavigableInputComponent implements OnInit {
  public customerName: string;
  public displayedColumns: string[];
  public treeControl: FlatTreeControl<ViewOrganisationFlatNode>;
  public treeFlattener: MatTreeFlattener<ViewOrganisation, ViewOrganisationFlatNode>;
  public organisations: MatTreeFlatDataSource<ViewOrganisation, ViewOrganisationFlatNode>;

  public limitNumber = 30;

  constructor(private organisationsService: OrganisationsService,
              private customersService: CustomersService,
              protected authService: KeycloakService,
              private dialog: MatDialog,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);

    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
      this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<ViewOrganisationFlatNode>(this._getLevel, this._isExpandable);
    this.organisations = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }
  private _getLevel = (node: ViewOrganisationFlatNode) => { return node.level; };

  private _isExpandable = (node: ViewOrganisationFlatNode) => { return node.expandable; };

  private _getChildren = (node: ViewOrganisation): Observable<ViewOrganisation[]> => { return of(node.children); };

  ngOnInit() {
    this.setupDisplayedColumns();
    this.getOrganisationsByCustomerId();
    this.getCustomer();
  }

  private transformer = (node: ViewOrganisation, level: number) => {
    return {
      ...node,
      expandable: !!node.children && node.children.length > 0,
      level: level,
    };
  }

  private setupDisplayedColumns() {
    this.displayedColumns = [
      'organizationName',
      'organizationNumber',
      'organizationType',
      'organizationDetails',
    ];
  }

  getToolTip(toolTipString: string) {
    if (!toolTipString) {
      return;
    }

    if (toolTipString.length > this.limitNumber) {
      return toolTipString;
    }

    return null;
  }

  getOrganisationsByCustomerId() {
    this.organisationsService
    .getOrganisationsByCustomerId(this.getUserCustomerId(), true)
    .subscribe(
      res => {
        this.organisations.data = res;
        this.treeControl.expandAll();
      }, error => {
        console.log('error on getting organisations', error);
      });
  }

  getCustomer() {
    this.customersService.getCustomerName(this.getUserCustomerId())
    .subscribe(
      data => {
        this.customerName = data;
      },
      error => {
        console.log('get customer error: ', error);
      }
    );
  }

  viewOrganisation(organisationId: string) {
    let args: OrganisationViewArgs = {organisationId};
    this.dialog.open(OrganisationViewComponent, {width: '1100px', data: args});
  }

  moveOrganisation(organisationId: string) {
    const dialogRef = this.dialog.open(OrganisationMoveComponent, {
      disableClose: true,
      width: '350px'
    });

    dialogRef.componentInstance.organisationId = organisationId;
    dialogRef.componentInstance.customerId = this.getUserCustomerId();
    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORTEST)).subscribe(result => {
      if (result) {
        this.getOrganisationsByCustomerId();
      }
    });
  }

  deleteOrganisation(organisationId: string) {
    const dialogRef = this.dialog.open(OrganisationDeleteComponent, {
      disableClose: true,
      width: '350px',
      autoFocus: false
    });
    dialogRef.componentInstance.organisationId = organisationId;
    dialogRef.componentInstance.customerId = this.getUserCustomerId();
    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOrganisationsByCustomerId();
      }
    });
  }
}
