<div class="technical-data-wrapper">
  <form [formGroup]="technicalDataFormGroup">
    <div class="loading-overlay" *ngIf="loadingFields">
      <mat-spinner></mat-spinner>
    </div>
    <bh-title [text]="'modules.equipment.base.technicalData'|translate"></bh-title>
    <mat-form-field *ngFor="let fieldControl of getFilteredControls('availableTechnicalFields')">
      <div class="technical-field-item" [formGroup]="fieldControl">
        <input matInput placeholder="{{ fieldControl.value.technicalFieldName }}"
               type="{{ getInputTypeByTechnicalFieldUnit(fieldControl.value.technicalFieldUnit) }}"
               value="{{ fieldControl.value.value }}"
               formControlName="value">
        <div class="technical-field-unit">{{ fieldControl.value.technicalFieldUnit }}</div>
      </div>
    </mat-form-field>
  </form>
</div>
