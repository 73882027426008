import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { KeycloakService } from '../../../../core/keycloak';
import { AttachedDocument } from '../../../../shared/contract/attached-document.interface';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { DatesService } from '../../../../shared/services/dates.service';
import { AddEquipmentIncidentCommand } from '../../contract/add-equipment-incident-command';
import { LifeCycleIncident } from '../../contract/life-cycle-incident';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { EquipmentsService } from '../../shared/equipments.service';
import { EnrichedAttachedDocument } from '../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-equipment-incident-add',
  templateUrl: './equipment-incident-add-edit.component.html',
  styleUrls: ['./equipment-incident-add-edit.component.scss']
})
export class EquipmentIncidentAddEditComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewChecked {

  @ViewChild(MatAutocompleteTrigger) trigger;

  public incidentAddForm: UntypedFormGroup;
  public equipmentIdParam: string;
  public lifecycleId: UUID;
  public documents: AttachedDocument[] = [];
  public isEditMode = false;
  public incidentDetails: LifeCycleIncident;
  public readonly fieldLimit = FieldLimit;

  constructor(protected formBuilder: UntypedFormBuilder,
              protected equipmentService: EquipmentsService,
              protected equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected cdRef: ChangeDetectorRef,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              protected dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
    super(authService, router, activatedRoute, routerHistory);
  }

  get incidentDate() {
    return this.incidentAddForm.get('incidentDate');
  }

  get subject() {
    return this.incidentAddForm.get('subject');
  }

  ngOnInit() {

    this.buildForm();
    this.activatedRoute.params.subscribe(params => {
      this.equipmentIdParam = params['id'];
      if (params['lifecycleId']) {
        this.isEditMode = true;
        this.lifecycleId = params['lifecycleId'];
        this.getIncidentDetails(this.equipmentIdParam, this.lifecycleId.toString());
      }
    });
  }

  ngAfterViewChecked() {
    // TODO: check if there is another solution
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges();
  }

  save() {
    if (this.isValid()) {
      let formValue = this.incidentAddForm.getRawValue();
      let cmd = new AddEquipmentIncidentCommand();
      cmd.lifecycleId = this.lifecycleId;
      cmd.equipmentId = this.equipmentIdParam;
      cmd.logDate = DatesService.ospDateTimeAtStartOfDay(formValue.incidentDate);
      cmd.currentOperatingHours = formValue.currentOperatingHours ? formValue.currentOperatingHours : null;
      cmd.currentMileage = formValue.currentMileage
        ? this.dimensionUnitConverterPipe.toSystemDimensionUnit(formValue.currentMileage, 'km')
        : null;
      cmd.subject = formValue.subject ? formValue.subject : null;
      cmd.description = formValue.description ? formValue.description : null;
      cmd.documents = this.documents ? this.documents : [];
      this.isEditMode ? this.editIncident(cmd) : this.addIncident(cmd);
    }
  }

  public isValid(): boolean {
    return this.incidentAddForm.valid;
  }

  onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.documents.push(AttachedDocument.fromData(attachedDocument));
  }

  getIncidentDetails(equipmentId: string, lifecycleId: string) {
    this.equipmentStore.getLifeCycleDetails(equipmentId, lifecycleId)
    .subscribe(
        res => {
          if (res) {
            this.incidentDetails = res as LifeCycleIncident;
            this.setFormValues();
            this.fillExistingDocuments();
          }
        },
        error => {
          console.log('error get invoice details: ', error);
        });
  }

  resetForm() {
    this.incidentAddForm.reset();
    this.documents = [];
  }

  removeDocument(document: AttachedDocument) {
    const index = this.documents.indexOf(document);
    if (index !== -1) {
      this.documents.splice(index, 1);
    }
  }

  public navigateBack(): void {
    this.goBack(`/assets/equipment/list/${this.equipmentIdParam}/timeline`);
  }

  private buildForm() {
    this.incidentAddForm = this.formBuilder.group({
      incidentDate: ['', [<any>Validators.required]],
      currentOperatingHours: ['', [<any>Validators.min(0)]],
      currentMileage: ['', [<any>Validators.min(0)]],
      subject: ['', [<any>Validators.required]],
      description: [''],
      documents: ['']
    });
  }

  private setFormValues() {
    this.incidentAddForm.patchValue({
      incidentDate: this.incidentDetails.logDate,
      currentOperatingHours: this.incidentDetails.currentOperatingHours,
      currentMileage: this.dimensionUnitConverterPipe.toUserDimensionUnit(this.incidentDetails.currentMileage, 'km', 2),
      subject: this.incidentDetails.subject,
      description: this.incidentDetails.description
    });
  }

  private fillExistingDocuments() {
    this.documents = [];
    if (this.incidentDetails.documents) {
      this.incidentDetails.documents.forEach((obj) => {
        // delete obj.url; NEVER USE THIS
        this.documents.push(AttachedDocument.fromData(obj));
      });
    }
  }

  private addIncident(cmd: AddEquipmentIncidentCommand) {
    this.equipmentStore.addIncident(cmd).subscribe(
        () => {
          this.goBack();
        }
    );
  }

  private editIncident(cmd: AddEquipmentIncidentCommand) {
    this.equipmentStore.updateIncident(cmd).subscribe(
        () => {
          this.goBack();
        }
    );
  }

}
