import { Component } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'bh-checkbox-confirm-dialog',
  templateUrl: 'checkbox-confirm-dialog.component.html',
  styleUrls: ['checkbox-confirm-dialog.component.scss']
})
export class CheckboxConfirmDialogComponent extends ConfirmationDialogComponent {

  public buttonMessage: string;

}
