import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { ColumnDefinitionKeys } from 'app/shared/enums/column-definition-keys.enum';
import { ColumnDefinition, ColumnDefinitionSet } from 'app/shared/column-definition';
import { LanguageService } from 'app/shared/services/language.service';
import { BulkItemType } from 'app/modules/disposition/contract/bulk-item-type';
import { OperatingHoursPipe } from '../pipes/operating-hours.pipe';
import { ANALYTICS_COLUMN_DEF, CONTACT_COLUMN_DEF, GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF, TRANSPORT_COLUMN_DEF } from '../constants/base-column-definition-constants';
import { COLUMN_TYPE } from '../constants/column-definition-constants';
import { ConstructionYearFormatPipe } from '../../modules/equipment/shared/pipes/construction-year.pipe';
import { isNumber } from 'lodash';
import { DimensionUnitPipe } from '../pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../pipes/dimension-unit-converter.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

export class BaseColumnDefinitionService {
  protected columnDefinitions: ColumnDefinitionSet = {};

  constructor(
    protected languageService: LanguageService,
    protected dimensionUnitPipe: DimensionUnitPipe,
    protected dimensionUnitConverterPipe: DimensionUnitConverterPipe,
    protected operatingHoursPipe: OperatingHoursPipe,
    protected contactTypeResolverPipe: ContactTypeResolverPipe,
    protected constructionYearPipe: ConstructionYearFormatPipe,
    protected translatableStringPipe: TranslatableStringPipe,
  ) {
    this.initAllColumns();
  }

  protected getColumnDefinitions(defs: ColumnDefinitionKeys[]): ColumnDefinition[] {
    return defs
      .map(defKey => this.columnDefinitions[defKey])
      .filter(Boolean);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private initAllColumns(): void {
    this.columnDefinitions = {
      ...this.getGeneralColumns(),
      ...this.getEquipmentColumns(),
      ...this.getBulkItemColumns(),
      ...this.getTransferColumns(),
      ...this.getEmployeeColumns(),
      ...this.getContactColumns(),
      ...this.getTransportColumns(),
      ...this.getMaintenanceColumns(),
      ...this.getAnalyticsColumns(),
    }
  }

  private getGeneralColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.GENERAL_THUMBNAIL]: {
        cdkColumnDef: 'thumbnail',
        type: COLUMN_TYPE.IMAGE,
        title: '',
        readableName: this.translate('general.image.s'),
        valueCallback: row => row.thumbnailKey
      },
      [ColumnDefinitionKeys.GENERAL_STATUS]: {
        cdkColumnDef: 'status',
        type: COLUMN_TYPE.STATUS,
        title: '',
        readableName: this.translate('general.status'),
        valueCallback: () => ''
      },
      [ColumnDefinitionKeys.GENERAL_OPEN_DETAILS]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.OPEN_DETAILS,
        type: COLUMN_TYPE.DETAILS,
        title: '',
        readableName: this.translate('general.details'),
        valueCallback: () => ''
      },
      [ColumnDefinitionKeys.GENERAL_ORGANISATION]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ORGANISATION,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.org.s'),
        readableName: this.translate('general.org.s'),
        valueCallback: row => row.organisationName
      },
      [ColumnDefinitionKeys.GENERAL_LABELS]: {
        cdkColumnDef: 'labels',
        type: COLUMN_TYPE.LABELS,
        title: this.translate('general.label.pl'),
        readableName: this.translate('general.label.pl'),
        valueCallback: null
      },
      [ColumnDefinitionKeys.GENERAL_NAME]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.name.s'),
        readableName: this.translate('general.name.s'),
        valueCallback: row => row.name
      },
      [ColumnDefinitionKeys.GENERAL_EMAIL]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EMAIL,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.email'),
        readableName: this.translate('general.email'),
        valueCallback: row => row.email
      },
      [ColumnDefinitionKeys.GENERAL_ADDRESS]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ADDRESS,
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('general.address.address'),
        readableName: this.translate('general.address.address'),
        valueCallback: row => row.address
      },
    };
  }

  private getEquipmentColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.EQUIPMENT_IMAGE]: {
        cdkColumnDef: 'equipmentImage',
        type: COLUMN_TYPE.IMAGE,
        title: '',
        readableName: this.translate('general.image.s'),
        valueCallback: row => row.thumbnailKey
      },
      [ColumnDefinitionKeys.EQUIPMENT_CUSTOMER_SERIAL_NUMBER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.intNumber.abbr'),
        readableName: this.translate('general.intNumber.abbr'),
        valueCallback: row => row.equipmentCustomerSerialNumber
      },
      [ColumnDefinitionKeys.EQUIPMENT_SERIAL_NUMBER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_SERIAL_NUMBER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.equipment.base.manufacturerSerialNumberColumn'),
        readableName: this.translate('modules.equipment.base.manufacturerSerialNumberColumn'),
        valueCallback: row => row.equipmentSerialNumber
      },
      [ColumnDefinitionKeys.EQUIPMENT_LABELS]: {
        cdkColumnDef: 'equipmentLabels',
        type: COLUMN_TYPE.LABELS,
        title: this.translate('general.label.s'),
        readableName: this.translate('general.label.s'),
        valueCallback: () => ''
      },
      [ColumnDefinitionKeys.EQUIPMENT_MODEL]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_MODEL,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.equipment.base.model.s'),
        readableName: this.translate('modules.equipment.base.model.s'),
        valueCallback: row => row.equipmentModel
      },
      [ColumnDefinitionKeys.MANUFACTURER_NAME]: {
        cdkColumnDef: 'manufacturerName',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.manufacturer'),
        readableName: this.translate('general.manufacturer'),
        valueCallback: row => row.manufacturerName
      },
      [ColumnDefinitionKeys.EQUIPMENT_TYPE_CATEGORY1]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY1,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.type'),
        readableName: this.translate('general.type'),
        valueCallback: row => this.translatableStringPipe.transform(row?.equipmentType?.category1Name)
          || this.translate('general.unknown')
      },
      [ColumnDefinitionKeys.EQUIPMENT_TYPE_CATEGORY2]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY2,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.class'),
        readableName: this.translate('general.class'),
        valueCallback: row => this.translatableStringPipe.transform(row?.equipmentType?.category2Name)
        || this.translate('general.unknown')
      },
      [ColumnDefinitionKeys.EQUIPMENT_TYPE_CATEGORY2_FOR_TRANSFER]: {
        cdkColumnDef: 'equipmentTypeCategory2',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.class'),
        readableName: this.translate('general.class'),
        valueCallback: row => this.translatableStringPipe.transform(row?.equipmentTypeCategory2Name)
        || this.translate('general.unknown'),
      },
      [ColumnDefinitionKeys.CURRENT_ADDRESS]: {
        cdkColumnDef: 'address',
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('general.address.address'),
        readableName: this.translate('general.address.address'),
        valueCallback: row => row.currentAddress
      },

      [ColumnDefinitionKeys.EQUIPMENT_NAME]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.name.s'),
        readableName: this.translate('general.name.s'),
        valueCallback: row => row.equipmentName || row.equipmentModel
      },
      [ColumnDefinitionKeys.EQUIPMENT_BEUTLHAUSER_ID]: {
        cdkColumnDef: 'equipmentBeutlhauserId',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.equipment.base.beutlhauserId'),
        readableName: this.translate('modules.equipment.base.beutlhauserId'),
        valueCallback: row => row.equipmentBeutlhauserId
      },
      [ColumnDefinitionKeys.EQUIPMENT_COST_CENTER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_COST_CENTER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.costCenter'),
        readableName: this.translate('general.costCenter'),
        valueCallback: row => row.equipmentCostCenter
      },
      [ColumnDefinitionKeys.EQUIPMENT_CONSTRUCTION_YEAR]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_CONSTRUCTION_YEAR,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.equipment.base.constrYear'),
        readableName: this.translate('modules.equipment.base.constrYear'),
        valueCallback: row => this.constructionYearPipe.transform(row.equipmentConstructionYear)
      },
      [ColumnDefinitionKeys.CURRENT_OPERATING_HOURS]: {
        cdkColumnDef: 'currentOperatingHours',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.operatingHour.pl'),
        readableName: this.translate('general.operatingHour.pl'),
        valueCallback: row => {
          if (!isNumber(row.currentOperatingHours)) {
            return this.translate('general.noOperatingHours');
          }

          return this.operatingHoursPipe.transform(row.currentOperatingHours)
        }
      },
      [ColumnDefinitionKeys.EQUIPMENT_CURRENT_LOCATION_NAME]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.CURRENT_LOCATION_NAME,
        type: COLUMN_TYPE.CURRENT_LOCATION_NAME,
        title: this.translate('general.currentLocation.currentLocationName'),
        readableName: this.translate('general.currentLocation.currentLocationName'),
        valueCallback: row => row.currentLocationName
      },
      [ColumnDefinitionKeys.EQUIPMENT_CURRENT_LOCATION_NUMBER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.CURRENT_LOCATION_NUMBER,
        type: COLUMN_TYPE.CURRENT_LOCATION_NUMBER,
        title: this.translate('general.currentLocation.currentLocationNumber'),
        readableName: this.translate('general.currentLocation.currentLocationNumber'),
        valueCallback: row => row.currentLocationNumber
      },
      [ColumnDefinitionKeys.EQUIPMENT_CURRENT_LOCATION_COST_CENTER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER,
        type: COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER,
        title: this.translate('general.currentLocation.currentLocationCostCenter'),
        readableName: this.translate('general.currentLocation.currentLocationCostCenter'),
        valueCallback: row => row.currentLocationCostCenter
      },
      [ColumnDefinitionKeys.EQUIPMENT_SCAN_CODE]: {
        cdkColumnDef: 'equipmentScanCode',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.scanCode'),
        readableName: this.translate('general.scanCode'),
        valueCallback: row => row.equipmentScanCode
      },
      [ColumnDefinitionKeys.EQUIPMENT_BGL_CODE]: {
        cdkColumnDef: 'equipmentBGLCode',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.BGLCode'),
        readableName: this.translate('general.BGLCode'),
        valueCallback: row => row.equipmentBGLCode
      },
      [ColumnDefinitionKeys.EQUIPMENT_LICENSE_PLATE]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_LICENSE_PLATE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.equipment.base.licensePlate'),
        readableName: this.translate('modules.equipment.base.licensePlate'),
        valueCallback: row => row.equipmentLicensePlate
      },
      [ColumnDefinitionKeys.EQUIPMENT_REGISTRATION_DATE]: {
        cdkColumnDef: 'equipmentRegistrationDate',
        type: COLUMN_TYPE.DATE,
        title: this.translate('modules.equipment.base.firstRegistration'),
        readableName: this.translate('modules.equipment.base.firstRegistration'),
        valueCallback: row => row.equipmentRegistrationDate
      },
      [ColumnDefinitionKeys.ASSIGNED_OWNER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ASSIGNED_OWNER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.pipes.equipmentEmployeeRole.owner'),
        readableName: this.translate('shared.pipes.equipmentEmployeeRole.owner'),
        valueCallback: row => row.assignedOwner ? row.assignedOwner.firstName + ' ' + row.assignedOwner.name : ''
      },
      [ColumnDefinitionKeys.ASSIGNED_DRIVER]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ASSIGNED_DRIVER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.pipes.equipmentEmployeeRole.driver'),
        readableName: this.translate('shared.pipes.equipmentEmployeeRole.driver'),
        valueCallback: row => row.assignedDriver ? row.assignedDriver.firstName + ' ' + row.assignedDriver.name : ''
      },
      [ColumnDefinitionKeys.ASSIGNED_PERSON_IN_CHARGE]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ASSIGNED_PERSON_IN_CHARGE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.pipes.equipmentEmployeeRole.personInCharge'),
        readableName: this.translate('shared.pipes.equipmentEmployeeRole.personInCharge'),
        valueCallback: row => row.assignedPersonInCharge
          ? row.assignedPersonInCharge.firstName + ' ' +  row.assignedPersonInCharge.name
          : ''
      },
      [ColumnDefinitionKeys.ASSIGNED_MAINTENANCE_ASSIGNEE]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ASSIGNED_MAINTENANCE_ASSIGNEE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.pipes.equipmentEmployeeRole.maintenanceAssignee'),
        readableName: this.translate('shared.pipes.equipmentEmployeeRole.maintenanceAssignee'),
        valueCallback: row => row.assignedMaintenanceAssignee ?
         row.assignedMaintenanceAssignee.firstName + ' ' + row.assignedMaintenanceAssignee.name : ''
      },
      [ColumnDefinitionKeys.ASSIGNED_POSSESSOR]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ASSIGNED_POSSESSOR,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.pipes.equipmentEmployeeRole.possessor'),
        readableName: this.translate('shared.pipes.equipmentEmployeeRole.possessor'),
        valueCallback: row => row.assignedPossessor ? row.assignedPossessor.firstName + ' ' + row.assignedPossessor.name : ''
      },
      [ColumnDefinitionKeys.EQUIPMENT_INFO]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_INFO,
        type: COLUMN_TYPE.STATUS,
        title: this.translate('general.info'),
        readableName: this.translate('general.info'),
        valueCallback: () => ''
      },
      [ColumnDefinitionKeys.EQUIPMENT_FAVOURITE]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_FAVOURITE,
        type: COLUMN_TYPE.ICON,
        title: this.translate('modules.equipment.base.favourite'),
        readableName: this.translate('modules.equipment.base.favourite'),
        valueCallback: () => ''
      },
    }
  }

  private getBulkItemColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.BULK_ITEM_ICON]: {
        cdkColumnDef: 'bulkItemIcon',
        type: COLUMN_TYPE.ICON,
        title: '',
        readableName: this.translate('general.icon'),
        valueCallback: row => row.bulkItemType,
        disableSort: true
      },
      [ColumnDefinitionKeys.BULK_ITEM_NUMBER]: {
        cdkColumnDef: 'bulkItemNumber',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.intNumber.abbr'),
        readableName: this.translate('general.intNumber.abbr'),
        valueCallback: row => row.bulkItemNumber
      },
      [ColumnDefinitionKeys.BULK_ITEM_NAME]: {
        cdkColumnDef: 'bulkItemName',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.name.s'),
        readableName: this.translate('general.name.s'),
        valueCallback: row => row.bulkItemName
      },
      [ColumnDefinitionKeys.BULK_ITEM_TYPE]: {
        cdkColumnDef: 'bulkItemType',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.columnNames.type'),
        readableName: this.translate('general.columnNames.type'),
        valueCallback: row => {
          switch (row.bulkItemType) {
            case BulkItemType.CONSUMABLE:
              return this.translate('general.bulkItemType.consumable');
            case BulkItemType.STANDARD:
              return this.translate('general.bulkItemType.standard');
            default:
              return this.translate('modules.disposition.bulkItemList.typeUnknown');
          }
        }
      },
      [ColumnDefinitionKeys.BULK_ITEM_CATEGORY]: {
        cdkColumnDef: 'bulkItemCategory',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.disposition.base.category.s'),
        readableName: this.translate('modules.disposition.base.category.s'),
        valueCallback: row => row.bulkItemCategory
      },
      [ColumnDefinitionKeys.BULK_ITEM_BGL_CODE]: {
        cdkColumnDef: 'bulkItemBGLCode',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.BGLCode'),
        readableName: this.translate('general.BGLCode'),
        valueCallback: row => row.bulkItemBGLCode
      },
      [ColumnDefinitionKeys.BULK_ITEM_BAL_CODE]: {
        cdkColumnDef: 'bulkItemBALCode',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.disposition.base.BALCode'),
        readableName: this.translate('modules.disposition.base.BALCode'),
        valueCallback: row => row.bulkItemBALCode
      },
      [ColumnDefinitionKeys.BULK_ITEM_SCAN_CODE]: {
        cdkColumnDef: 'bulkItemScanCode',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.scanCode'),
        readableName: this.translate('general.scanCode'),
        valueCallback: row => row.bulkItemScanCode
      },
      [ColumnDefinitionKeys.BULK_ITEM_COST_CENTER]: {
        cdkColumnDef: 'bulkItemCostCenter',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.costCenter'),
        readableName: this.translate('general.costCenter'),
        valueCallback: row => row.bulkItemCostCenter,
        disableSort: true
      },
      [ColumnDefinitionKeys.BULK_ITEM_COST_TYPE]: {
        cdkColumnDef: 'bulkItemCostType',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.disposition.base.costType'),
        readableName: this.translate('modules.disposition.base.costType'),
        valueCallback: row => row.bulkItemCostType
      },
      [ColumnDefinitionKeys.MANUFACTURER_ID]: {
        cdkColumnDef: 'manufacturerId',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.disposition.base.manufacturerNumber'),
        readableName: this.translate('modules.disposition.base.manufacturerNumber'),
        valueCallback: row => row.manufacturerId,
        disableSort: true
      },
      [ColumnDefinitionKeys.SUPPLIER_NAME]: {
        cdkColumnDef: 'supplierName',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.disposition.base.supplier'),
        readableName: this.translate('modules.disposition.base.supplier'),
        valueCallback: row => row.supplierName,
        disableSort: true
      },
      [ColumnDefinitionKeys.SUPPLIER_ID]: {
        cdkColumnDef: 'supplierId',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.disposition.base.supplierNumber'),
        readableName: this.translate('modules.disposition.base.supplierNumber'),
        valueCallback: row => row.supplierId,
        disableSort: true
      },
      [ColumnDefinitionKeys.BULK_ITEM_UNIT]: {
        cdkColumnDef: 'bulkItemUnit',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.units.unit'),
        readableName: this.translate('general.units.unit'),
        valueCallback: row => this.dimensionUnitPipe.getUserDimensionUnit(row.bulkItemUnit),
        disableSort: true
      },
      [ColumnDefinitionKeys.BULK_ITEM_WEIGHT]: {
        cdkColumnDef: 'bulkItemWeight',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.units.weight.weight'),
        readableName: `${this.translate('modules.disposition.base.weightIn')} ${this.dimensionUnitPipe.getUserDimensionUnit('kg')}`,
        valueCallback: row => {
          if (!row.bulkItemWeight) {
            return '';
          }

          let weight = this.dimensionUnitConverterPipe.toUserDimensionUnit(row.bulkItemWeight, 'kg');
          let unit = this.dimensionUnitPipe.getUserDimensionUnit('kg');
          return `${weight} ${unit}`;
        },
        disableSort: true
      }
    };
  }

  private getTransferColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.CURRENT_LOCATION_NUMBER]: {
        cdkColumnDef: 'currentLocationNumber',
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.currentLocation.currentLocationNumber'),
        readableName: this.translate('general.currentLocation.currentLocationNumber'),
        valueCallback: row => row.currentLocation?.number
      },
      [ColumnDefinitionKeys.CURRENT_LOCATION_NAME]: {
        cdkColumnDef: 'currentLocationName',
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.currentLocation.currentLocationName'),
        readableName: this.translate('general.currentLocation.currentLocationName'),
        valueCallback: row => row.currentLocation?.name
      },
      [ColumnDefinitionKeys.CURRENT_LOCATION_COST_CENTER]: {
        cdkColumnDef: 'currentLocationCostCenter',
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.currentLocation.currentLocationCostCenter'),
        readableName: this.translate('general.currentLocation.currentLocationCostCenter'),
        valueCallback: row => row.currentLocation?.costCenter
      },
      [ColumnDefinitionKeys.STOCK_NAME]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.STOCK_NAME,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.stock.s'),
        readableName: this.translate('general.stock.s'),
        valueCallback: row => row.stockName
      },
      [ColumnDefinitionKeys.AMOUNT_AVAILABLE]: {
        cdkColumnDef: 'amountAvailable',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.available'),
        readableName: this.translate('modules.disposition.base.availableNumber'),
        valueCallback: row => {
          let available = this.dimensionUnitConverterPipe.toUserDimensionUnit(row.amountAvailable, row.bulkItemUnit, 3);
          let total = this.dimensionUnitConverterPipe.toUserDimensionUnit(row.amountTotal, row.bulkItemUnit, 3);
          return `${available} ${this.translate('general.from')} ${total}`;
        }
      },
      [ColumnDefinitionKeys.EQUIPMENT_AMOUNT_AVAILABLE]: {
        cdkColumnDef: 'equipmentAmountAvailable',
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.available'),
        readableName: this.translate('modules.disposition.base.availableNumber'),
        valueCallback: row => row.amountAvailable
      },
      [ColumnDefinitionKeys.TRANSFER_BUIK_ITEM_TYPE]: {
        cdkColumnDef: 'bulkItemType',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.columnNames.type'),
        readableName: this.translate('modules.disposition.base.bulkItemType'),
        valueCallback: row => {
          switch (row.bulkItemType) {
            case BulkItemType.CONSUMABLE:
              return this.translate('general.bulkItemType.consumable');
            case BulkItemType.STANDARD:
              return this.translate('general.bulkItemType.standard');
            default:
              return this.translate('modules.disposition.bulkItemList.typeUnknown');
          }
        }
      },
      [ColumnDefinitionKeys.INFO_TRANSFER_CART]: {
        cdkColumnDef: 'intoTransferCart',
        type: COLUMN_TYPE.OTHER,
        title: this.translate('modules.disposition.base.inTransferBasket'),
        readableName: '',
        valueCallback: null
      },
      [ColumnDefinitionKeys.TRANSFER_BULK_ITEM_COST_CENTER]: {
        cdkColumnDef: 'bulkItemCostCenter',
        type: COLUMN_TYPE.VALUE,
        title: `${this.translate('general.costCenter')} (${this.translate('general.bulkItems')})`,
        readableName: `${this.translate('general.costCenter')} (${this.translate('general.bulkItems')})`,
        valueCallback: row => row.bulkItemCostCenter
      },
      [ColumnDefinitionKeys.TRANSFER_LABELS]: {
        cdkColumnDef: 'bulkItemLabels',
        type: COLUMN_TYPE.LABELS,
        title: this.translate('general.label.pl'),
        readableName: this.translate('general.label.pl'),
        valueCallback: () => ''
      },
      [ColumnDefinitionKeys.STOCK_COST_CENTER]: {
        cdkColumnDef: 'stockCostCenter',
        type: COLUMN_TYPE.VALUE,
        title: `${this.translate('general.costCenter')} (${this.translate('general.stock.s')})`,
        readableName: `${this.translate('general.costCenter')} (${this.translate('general.stock.s')})`,
        valueCallback: row => row.stockCostCenter
      },
      [ColumnDefinitionKeys.STOCK_ADDRESS]: {
        cdkColumnDef: 'stockAddress',
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('general.address.address'),
        readableName: `${this.translate('general.address.address')} ${this.translate('general.stock.s')}`,
        valueCallback: row => row.stockAddress
      },
      [ColumnDefinitionKeys.STOCK_ORGANISATION]: {
        cdkColumnDef: 'stockOrganisation',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.org.s'),
        readableName: this.translate('general.org.s'),
        valueCallback: row => row.stockOrganisationName
      },
      [ColumnDefinitionKeys.CURRENT_PROJECT]: {
        cdkColumnDef: 'currentProject',
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.project.s'),
        readableName: this.translate('general.project.s'),
        valueCallback: null
      }
    };
  }

  private getEmployeeColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.EMPLOYEE_ICON]: {
        cdkColumnDef: 'employeeIcon',
        type: COLUMN_TYPE.ICON,
        title: '',
        readableName: this.translate('general.icon'),
        valueCallback: row => row.thumbnailKey
      },
      [ColumnDefinitionKeys.EMPLOYEE_STAFF_NUMBER]: {
        cdkColumnDef: 'employeeStaffNumber',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.personalNumber'),
        readableName: this.translate('general.personalNumber'),
        valueCallback: row => row.employeeStaffNumber
      },
      [ColumnDefinitionKeys.EMPLOYEE_FIRST_NAME]: {
        cdkColumnDef: 'employeeFirstName',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.firstName'),
        readableName: this.translate('general.firstName'),
        valueCallback: row => row.employeeFirstName
      },
      [ColumnDefinitionKeys.EMPLOYEE_LAST_NAME]: {
        cdkColumnDef: 'employeeName',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.lastName'),
        readableName: this.translate('general.lastName'),
        valueCallback: row => row.employeeName
      },
      [ColumnDefinitionKeys.EMPLOYEE_EMAIL]: {
        cdkColumnDef: 'employeeEmail',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.email'),
        readableName: this.translate('general.email'),
        valueCallback: row => row.employeeEmail
      },
      [ColumnDefinitionKeys.EMPLOYEE_OFFICE_PHONE_NUMBER]: {
        cdkColumnDef: 'employeeOfficePhoneNumber',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.phone'),
        readableName: this.translate('general.phone'),
        valueCallback: row => row.employeeOfficePhoneNumber
      },
      [ColumnDefinitionKeys.EMPLOYEE_OFFICE_MOBILE_NUMBER]: {
        cdkColumnDef: 'employeeOfficeMobileNumber',
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.phoneMobile'),
        readableName: this.translate('general.phoneMobile'),
        valueCallback: row => row.employeeOfficeMobileNumber
      }
    };
  }

  private getContactColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.CONTACT_ICON]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ICON,
        type: COLUMN_TYPE.ICON,
        title: '',
        readableName: this.translate('general.icon'),
        valueCallback: row => row.type
      },
      [ColumnDefinitionKeys.CONTACT_IDENTIFIER]: {
        cdkColumnDef: CONTACT_COLUMN_DEF.IDENTIFIER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.identifier'),
        readableName: this.translate('general.identifier'),
        valueCallback: row => row.identifier
      },
      [ColumnDefinitionKeys.CONTACT_TYPE]: {
        cdkColumnDef: CONTACT_COLUMN_DEF.TYPE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.columnNames.type'),
        readableName: this.translate('general.columnNames.type'),
        disableSort: true,
        valueCallback: row => this.contactTypeResolverPipe.resolveName(row.type)
      },
      [ColumnDefinitionKeys.CONTACT_PHONE_NUMBER]: {
        cdkColumnDef: CONTACT_COLUMN_DEF.PHONE_NUMBER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.contact.base.phoneNumber'),
        readableName: this.translate('modules.contact.base.phoneNumber'),
        valueCallback: row => row.phoneNumber
      },
      [ColumnDefinitionKeys.CONTACT_PERSON]: {
        cdkColumnDef: CONTACT_COLUMN_DEF.CONTACT_PERSON,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.contact.base.contactPerson'),
        readableName: this.translate('modules.contact.base.contactPerson'),
        valueCallback: row => row.contactPerson
      }
    }
  }

  private getTransportColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.TRANSPORT_IDENTIFIER]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.IDENTIFIER,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('modules.transportation.base.taskNumber'),
        readableName: this.translate('modules.transportation.base.taskNumber'),
        valueCallback: row => row.identifier
      },
      [ColumnDefinitionKeys.TRANSPORT_DUE_DATE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.DUE_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('modules.transportation.base.dueDate'),
        readableName: this.translate('modules.transportation.base.dueDate'),
        valueCallback: row => row.dueDate?.timestamp
      },
      [ColumnDefinitionKeys.TRANSPORT_STATE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.STATE,
        type: COLUMN_TYPE.STATUS,
        title: this.translate('modules.transportation.base.status'),
        readableName: this.translate('modules.transportation.base.status'),
        valueCallback: row => row.state
      },
      [ColumnDefinitionKeys.TRANSPORT_TITLE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.TITLE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.transportation.base.taskTitle'),
        readableName: this.translate('modules.transportation.base.taskTitle'),
        valueCallback: row => row.title
      },
      [ColumnDefinitionKeys.TRANSPORT_DESCRIPTION]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.DESCRIPTION,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.transportation.transportationTaskAddEdit.description'),
        readableName: this.translate('modules.transportation.transportationTaskAddEdit.description'),
        disableSort: true,
        valueCallback: row => row.text
      },
      [ColumnDefinitionKeys.TRANSPORT_PRIORITY]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.PRIORITY,
        type: COLUMN_TYPE.PRIORITY,
        title: this.translate('modules.transportation.base.priority'),
        readableName: this.translate('modules.transportation.base.priority'),
        valueCallback: row => row.priority
      },
      [ColumnDefinitionKeys.TRANSPORT_START_ITEM]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.START_ITEM,
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('modules.transportation.base.startAddress'),
        readableName: this.translate('modules.transportation.base.startAddress'),
        valueCallback: row => row.startItem
      },
      [ColumnDefinitionKeys.TRANSPORT_TARGET_ITEM]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.TARGET_ITEM,
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('modules.transportation.base.destinationAddress'),
        readableName: this.translate('modules.transportation.base.destinationAddress'),
        valueCallback: row => row.targetItem
      },
      [ColumnDefinitionKeys.TRANSPORT_EQUIPMENT_DISPLAY_NAME]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.EQUIPMENT_DISPLAY_NAME,
        type: COLUMN_TYPE.EQUIPMENT_NAME,
        title: this.translate('modules.transportation.base.assignedVehicle'),
        readableName: this.translate('modules.transportation.base.assignedVehicle'),
        valueCallback: row => row.equipmentAssignmentDetails
      },
      [ColumnDefinitionKeys.TRANSPORT_ESTIMATED_DURATION]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.ESTIMATED_DURATION,
        type: COLUMN_TYPE.TIME,
        title: this.translate('modules.transportation.base.estimatedDuration'),
        readableName: this.translate('modules.transportation.base.estimatedDuration'),
        valueCallback: row => row.estimatedDuration
      },
      [ColumnDefinitionKeys.TRANSPORT_RELATED_PROJECT]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.PROJECT_INFO,
        type: COLUMN_TYPE.RELATED_PROJECT,
        title: this.translate('modules.transportation.base.relatedProject'),
        readableName: this.translate('modules.transportation.base.relatedProject'),
        valueCallback: row => row.projectInfo
      },
      [ColumnDefinitionKeys.TRANSPORT_START_DATE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.START_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('modules.transportation.base.assignmentStartDate'),
        readableName: this.translate('modules.transportation.base.assignmentStartDate'),
        valueCallback: row => row.equipmentAssignmentDetails?.startDate?.timestamp
      },
      [ColumnDefinitionKeys.TRANSPORT_END_DATE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.END_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('modules.transportation.base.assignmentEndDate'),
        readableName: this.translate('modules.transportation.base.assignmentEndDate'),
        valueCallback: row => row.equipmentAssignmentDetails?.endDate?.timestamp
      },
      [ColumnDefinitionKeys.TRANSPORT_EXECUTION_START_DATE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.EXECUTION_START_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('modules.transportation.base.actualTransportStart'),
        readableName: this.translate('modules.transportation.base.actualTransportStart'),
        valueCallback: row => row.equipmentAssignmentDetails?.executionStartDate?.timestamp
      },
      [ColumnDefinitionKeys.TRANSPORT_EXECUTION_END_DATE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.EXECUTION_END_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('modules.transportation.base.actualTransportEnd'),
        readableName: this.translate('modules.transportation.base.actualTransportEnd'),
        valueCallback: row => row.equipmentAssignmentDetails?.executionEndDate?.timestamp
      },
      [ColumnDefinitionKeys.TRANSPORT_TYPE]: {
        cdkColumnDef: TRANSPORT_COLUMN_DEF.TRANSPORT_TYPE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('modules.transportation.base.transportType'),
        readableName: this.translate('modules.transportation.base.transportType'),
        valueCallback: row => row.transportType
      }
    }
  }

  private getMaintenanceColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.MAINTENANCE_TASK_NAME]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.maintenance.task.maintenanceTaskName'),
        readableName: this.translate('shared.maintenance.task.maintenanceTaskName'),
        valueCallback: row => row.maintenanceName
      },
      [ColumnDefinitionKeys.MAINTENANCE_TASK_TYPE]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_CATEGORY,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('shared.maintenance.task.maintenanceTaskType'),
        readableName: this.translate('shared.maintenance.task.maintenanceTaskType'),
        valueCallback: row => row.maintenanceCategory
      },
      [ColumnDefinitionKeys.MAINTENANCE_EQUIPMENT]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.EQUIPMENT_NAME,
        type: COLUMN_TYPE.EQUIPMENT_NAME,
        title: this.translate('shared.maintenance.task.equipmentName'),
        readableName: this.translate('shared.maintenance.task.equipmentName'),
        valueCallback: row => row
      },
      [ColumnDefinitionKeys.MAINTENANCE_CURRENT_LOCATION_NAME]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.CURRENT_LOCATION_NAME,
        type: COLUMN_TYPE.CURRENT_LOCATION_NAME,
        title: this.translate('general.currentLocation.currentLocationName'),
        readableName: this.translate('general.currentLocation.currentLocationName'),
        valueCallback: row => row
      },
      [ColumnDefinitionKeys.MAINTENANCE_CURRENT_LOCATION_NUMBER]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.CURRENT_LOCATION_NUMBER,
        type: COLUMN_TYPE.CURRENT_LOCATION_NUMBER,
        title: this.translate('general.currentLocation.currentLocationNumber'),
        readableName: this.translate('general.currentLocation.currentLocationNumber'),
        valueCallback: row => row.currentLocationNumber
      },
      [ColumnDefinitionKeys.MAINTENANCE_CURRENT_LOCATION_COST_CENTER]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER,
        type: COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER,
        title: this.translate('general.currentLocation.currentLocationCostCenter'),
        readableName: this.translate('general.currentLocation.currentLocationCostCenter'),
        valueCallback: row => row.currentLocationCostCenter
      },
      [ColumnDefinitionKeys.MAINTENANCE_TELEMATIC]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.TELEMATIC,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.telematic'),
        readableName: this.translate('general.telematic'),
        valueCallback: row => row.assignedTelematicUnits > 0
          ? this.translate('general.yes')
          : this.translate('general.no')
      },
      [ColumnDefinitionKeys.MAINTENANCE_TELEMATIC_LOCATION]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.LIVE_ADDRESS,
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('shared.maintenance.task.telematicAddress'),
        readableName: this.translate('shared.maintenance.task.telematicAddress'),
        valueCallback: row => row.liveAddress
      },
      [ColumnDefinitionKeys.MAINTENANCE_CURRENT_PROJECT_NAME]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.PROJECT_NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.project.s'),
        readableName: this.translate('general.project.s'),
        valueCallback: row => row.projectName
      },
      [ColumnDefinitionKeys.MAINTENANCE_EQUIPMENT_MANUFACTURER_NAME]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.EQUIPMENT_MANUFACTURER_NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.manufacturer'),
        readableName: this.translate('general.manufacturer'),
        valueCallback: row => row.equipmentManufacturerName
      },
      [ColumnDefinitionKeys.MAINTENANCE_IMAGE]: {
        cdkColumnDef: GENERAL_COLUMN_DEF.ICON,
        type: COLUMN_TYPE.ICON,
        title: '',
        readableName: this.translate('general.icon'),
        valueCallback: row => row.maintenanceCategory
      },
      [ColumnDefinitionKeys.MAINTENANCE_DUE_DATE]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.DUE_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('shared.maintenance.task.due'),
        readableName: this.translate('shared.maintenance.task.due'),
        valueCallback: row => row.dueDateInfo
      },
      [ColumnDefinitionKeys.MAINTENANCE_TASK_REMAINING]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.REMAINING_VALUE,
        type: COLUMN_TYPE.TIME,
        title: this.translate('shared.maintenance.task.taskTimeRemaining'),
        readableName: this.translate('shared.maintenance.task.taskTimeRemaining'),
        valueCallback: row => row.dueDateInfo
      },
      [ColumnDefinitionKeys.MAINTENANCE_TASK_ACTION]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.TASK_ACTION,
        type: COLUMN_TYPE.ACTION,
        title: '',
        readableName: this.translate('shared.maintenance.task.taskAction'),
        valueCallback: row => row
      },
      [ColumnDefinitionKeys.MAINTENANCE_CURRENT_ADDRESS]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.CURRENT_ADDRESS,
        type: COLUMN_TYPE.ADDRESS,
        title: this.translate('general.address.address'),
        readableName: this.translate('general.address.address'),
        valueCallback: row => row.currentAddress
      },
      [ColumnDefinitionKeys.MAINTENANCE_TYPE_NAME]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_TYPE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.maintenance.task.serviceType'),
        readableName: this.translate('shared.maintenance.task.serviceType'),
        valueCallback: row => row.maintenanceTypeName,
      },
      [ColumnDefinitionKeys.MAINTENANCE_COMPLETED_BY]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_BY,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('shared.maintenance.task.completedBy'),
        readableName: this.translate('shared.maintenance.task.completedBy'),
        valueCallback: row => row.completedByInfo
      },
      [ColumnDefinitionKeys.MAINTENANCE_COMPLETED_AT_MILEAGE]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_MILEAGE,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.maintenance.task.completedAtMileage'),
        readableName: this.translate('shared.maintenance.task.completedAtMileage'),
        valueCallback: row => row.completedAtMileage
      },
      [ColumnDefinitionKeys.MAINTENANCE_COMPLETED_AT_OPERATING_HOURS]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_OPERATING_HOURS,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('shared.maintenance.task.completedAtOperatingHours'),
        readableName: this.translate('shared.maintenance.task.completedAtOperatingHours'),
        valueCallback: row => row.completedAtOperatingHours
      },
      [ColumnDefinitionKeys.MAINTENANCE_COMPLETED_AT_DATE]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('shared.maintenance.task.completionDate'),
        readableName: this.translate('shared.maintenance.task.completionDate'),
        valueCallback: row => row.completedAtDate
      },
      [ColumnDefinitionKeys.MAINTENANCE_RESULT]: {
        cdkColumnDef: MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_RESULT,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('shared.maintenance.task.maintenanceResult'),
        readableName: this.translate('shared.maintenance.task.maintenanceResult'),
        valueCallback: row => row.maintenanceResult
      }
    }
  }

  private getAnalyticsColumns(): ColumnDefinitionSet {
    return {
      [ColumnDefinitionKeys.ANALYTICS_ACCEPTANCE_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.ACCEPTANCE_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.acceptanceDate'),
        readableName: this.translate('general.columnNames.acceptanceDate'),
        disableSort: true,
        valueCallback: row => row.acceptanceDate
      },
      [ColumnDefinitionKeys.ANALYTICS_ACCEPTANCE_USER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.ACCEPTANCE_USER,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.columnNames.acceptanceUser'),
        readableName: this.translate('general.columnNames.acceptanceUser'),
        valueCallback: row => row.acceptanceUserEmail
      },
      [ColumnDefinitionKeys.ANALYTICS_AMOUNT]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.AMOUNT,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.quantity'),
        readableName: this.translate('general.quantity'),
        valueCallback: row => row.transferItem.amount
      },
      [ColumnDefinitionKeys.ANALYTICS_CHANGE_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.CHANGE_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.changeDate'),
        readableName: this.translate('general.columnNames.changeDate'),
        disableSort: true,
        valueCallback: row => row.changeDate
      },
      [ColumnDefinitionKeys.ANALYTICS_CHARGE_TYPE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.CHARGE_TYPE,
        type: COLUMN_TYPE.CHARGE_TYPE,
        title: this.translate('general.columnNames.chargeType'),
        readableName: this.translate('general.columnNames.chargeType'),
        valueCallback: row => row.chargeType
      },
      [ColumnDefinitionKeys.ANALYTICS_CREATION_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.CREATION_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.creationDate'),
        readableName: this.translate('general.columnNames.creationDate'),
        disableSort: true,
        valueCallback: row => row.creationDate
      },
      [ColumnDefinitionKeys.ANALYTICS_CREATOR_USER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.CREATOR_USER,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.columnNames.creatorUser'),
        readableName: this.translate('general.columnNames.creatorUser'),
        valueCallback: row => row.creatorUserEmail
      },
      [ColumnDefinitionKeys.ANALYTICS_DELIVERY_NOTE_NUMBER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.DELIVERY_NOTE_NUMBER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.columnNames.deliveryNoteNumber'),
        readableName: this.translate('general.columnNames.deliveryNoteNumber'),
        valueCallback: row => row.deliveryNoteNumber
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_BDL_CODE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_BDL_CODE,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.bglCode'),
        readableName: this.translate('general.columnNames.bglCode'),
        valueCallback: row => row.equipment.bglCode
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_CHANGE_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_CHANGE_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.changeDate'),
        readableName: this.translate('general.columnNames.changeDate'),
        valueCallback: row => row.changeDate
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_CHARGE_TYPE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_CHARGE_TYPE,
        type: COLUMN_TYPE.CHARGE_TYPE,
        title: this.translate('general.columnNames.chargeType'),
        readableName: this.translate('general.columnNames.chargeType'),
        valueCallback: row => row.chargeType
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_COST_CENTER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_COST_CENTER,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.costCenter'),
        readableName: this.translate('general.columnNames.costCenter'),
        valueCallback: row => row.equipment.costCenter
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_CREATION_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_CREATION_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.creationDate'),
        readableName: this.translate('general.columnNames.creationDate'),
        valueCallback: row => row.creationDate
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_DAYS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_DAYS,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.days'),
        readableName: this.translate('general.columnNames.days'),
        valueCallback: row => row.days
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_WORKING_DAYS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_WORKING_DAYS,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.workingDays'),
        readableName: this.translate('general.columnNames.workingDays'),
        valueCallback: row => row.workingDays
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_DRIVER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_DRIVER,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.driver'),
        readableName: this.translate('general.columnNames.driver'),
        valueCallback: row => row.equipment.driver
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_END_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_END_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.endDate'),
        readableName: this.translate('general.columnNames.endDate'),
        valueCallback: row => row.endDate
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_GUID_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_GUID_ID,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate(
          'general.columnNames.idValue',
          {value: this.translate('general.columnNamesValue.guid')},
        ),
        readableName: this.translate(
          'general.columnNames.idValue',
          {value: this.translate('general.columnNamesValue.guid')},
        ),
        valueCallback: row => row.equipment.id
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_INTERNAL_NUMBER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_INTERNAL_NUMBER,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.internalNumber'),
        readableName: this.translate('general.columnNames.internalNumber'),
        valueCallback: row => row.equipment.internalNumber
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LABELS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LABELS,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.columnNames.labels'),
        readableName: this.translate('general.columnNames.labels'),
        valueCallback: row => row.equipment.labels
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LICENSE_PLATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LICENSE_PLATE,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.licensePlate'),
        readableName: this.translate('general.columnNames.licensePlate'),
        valueCallback: row => row.equipment.licensePlate
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LOCATION_ADDRESS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LOCATION_ADDRESS,
        type: COLUMN_TYPE.LOCATION_ADDRESS,
        title: this.translate(
          'general.columnNames.addressValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        readableName: this.translate(
          'general.columnNames.addressValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        valueCallback: row => row.location
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LOCATION_COST_CENTER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LOCATION_COST_CENTER,
        type: COLUMN_TYPE.LOCATION_VALUE,
        title: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        readableName: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        valueCallback: row => row.location.costCenter,
        locationTypeCallback: row => row.location.type,
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LOCATION_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LOCATION_ID,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate(
          'general.columnNames.idValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        readableName: this.translate(
          'general.columnNames.idValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        valueCallback: row => row.location.id
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LOCATION_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LOCATION_NAME,
        type: COLUMN_TYPE.LOCATION_VALUE,
        title: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        readableName: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        valueCallback: row => row.location.name,
        locationTypeCallback: row => row.location.type,
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_LOCATION_INTERNAL_NUMBER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_LOCATION_INTERNAL_NUMBER,
        type: COLUMN_TYPE.LOCATION_VALUE,
        title: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        readableName: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.location')},
        ),
        valueCallback: row => row.location.number,
        locationTypeCallback: row => row.location.type,
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_MAINTENANCE_ASSIGNEE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_MAINTENANCE_ASSIGNEE,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.maintenanceAssignee'),
        readableName: this.translate('general.columnNames.maintenanceAssignee'),
        valueCallback: row => row.equipment.maintenanceAssignee
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_MANUFACTURER_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_MANUFACTURER_NAME,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.manufacturerName'),
        readableName: this.translate('general.columnNames.manufacturerName'),
        valueCallback: row => row.equipment.manufacturer?.name
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_MODEL]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_MODEL,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.model'),
        readableName: this.translate('general.columnNames.model'),
        valueCallback: row => row.equipment.model
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_NAME,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.name'),
        readableName: this.translate('general.columnNames.name'),
        disableSort: false,
        valueCallback: row => row.equipment.name
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_ORGANISATION]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_ORGANISATION,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.organisation'),
        readableName: this.translate('general.columnNames.organisation'),
        valueCallback: row => row.equipment.organisation.name
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_OWNER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_OWNER,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.owner'),
        readableName: this.translate('general.columnNames.owner'),
        valueCallback: row => row.equipment.owner
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_POSSESSOR]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_POSSESSOR,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.possessor'),
        readableName: this.translate('general.columnNames.possessor'),
        valueCallback: row => row.equipment.possessor
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_PERSON_IN_CHARGE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_PERSON_IN_CHARGE,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.personInCharge'),
        readableName: this.translate('general.columnNames.personInCharge'),
        valueCallback: row => row.equipment.personInCharge
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_SCAN_CODE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_SCAN_CODE,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.scanCode'),
        readableName: this.translate('general.columnNames.scanCode'),
        valueCallback: row => row.equipment.scanCode
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_START_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_START_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.startDate'),
        readableName: this.translate('general.columnNames.startDate'),
        valueCallback: row => row.startDate
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_STATUS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_STATUS,
        type: COLUMN_TYPE.STATUS,
        title: this.translate('general.columnNames.status'),
        readableName: this.translate('general.columnNames.status'),
        valueCallback: row => row.equipment.status
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_TRANSFER_COMMENT]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_TRANSFER_COMMENT,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.transferComment'),
        readableName: this.translate('general.columnNames.transferComment'),
        valueCallback: row => row.transferComment
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_TRANSFER_EXTERNAL_PERSON_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_TRANSFER_EXTERNAL_PERSON_NAME,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.transferExternalPersonName'),
        readableName: this.translate('general.columnNames.transferExternalPersonName'),
        valueCallback: row => row.transferExternalPersonName
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_TRANSFER_INTERNAL_EMPLOYEE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_TRANSFER_INTERNAL_EMPLOYEE_NAME,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.transferInternalEmployee'),
        readableName: this.translate('general.columnNames.transferInternalEmployee'),
        valueCallback: row => row.transferInternalEmployee
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_TYPE_CATEGORY1]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY1,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.type'),
        readableName: this.translate('general.columnNames.type'),
        disableSort: true,
        valueCallback: row => row.equipment.type.category1
      },
      [ColumnDefinitionKeys.ANALYTICS_EQUIPMENT_TYPE_CATEGORY2]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY2,
        type: COLUMN_TYPE.SORTABLE_VALUE,
        title: this.translate('general.columnNames.class'),
        readableName: this.translate('general.columnNames.class'),
        valueCallback: row => row.equipment.type.category2
      },
      [ColumnDefinitionKeys.ANALYTICS_SOURCE_ADDRESS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.SOURCE_ADDRESS,
        type: COLUMN_TYPE.LOCATION_ADDRESS,
        title: this.translate(
          'general.columnNames.addressValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        readableName: this.translate(
          'general.columnNames.addressValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        valueCallback: row => row.sourceLocation
      },
      [ColumnDefinitionKeys.ANALYTICS_SOURCE_COST_CENTER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.SOURCE_COST_CENTER,
        type: COLUMN_TYPE.SOURCE_LOCATION,
        title: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        readableName: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        valueCallback: row => row.sourceLocation.costCenter
      },
      [ColumnDefinitionKeys.ANALYTICS_SOURCE_GUID_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.SOURCE_GUID_ID,
        type: COLUMN_TYPE.SOURCE_LOCATION,
        title: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        readableName: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        valueCallback: row => row.sourceLocation.id
      },
      [ColumnDefinitionKeys.ANALYTICS_SOURCE_EXTERNAL_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.SOURCE_EXTERNAL_ID,
        type: COLUMN_TYPE.SOURCE_LOCATION,
        title: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        readableName: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        valueCallback: row => row.sourceLocation.externalPublicId
      },
      [ColumnDefinitionKeys.ANALYTICS_SOURCE_INT_NR]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.SOURCE_INT_NR,
        type: COLUMN_TYPE.SOURCE_LOCATION,
        title: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        readableName: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        valueCallback: row => row.sourceLocation.intNr
      },
      [ColumnDefinitionKeys.ANALYTICS_SOURCE_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.SOURCE_NAME,
        type: COLUMN_TYPE.SOURCE_LOCATION,
        title: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        readableName: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.source')},
        ),
        valueCallback: row => row.sourceLocation.name
      },
      [ColumnDefinitionKeys.ANALYTICS_TARGET_ADDRESS]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TARGET_ADDRESS,
        type: COLUMN_TYPE.LOCATION_ADDRESS,
        title: this.translate(
          'general.columnNames.addressValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        readableName: this.translate(
          'general.columnNames.addressValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        valueCallback: row => row.targetLocation
      },
      [ColumnDefinitionKeys.ANALYTICS_TARGET_COST_CENTER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TARGET_COST_CENTER,
        type: COLUMN_TYPE.TARGET_LOCATION,
        title: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        readableName: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        valueCallback: row => row.targetLocation.costCenter
      },
      [ColumnDefinitionKeys.ANALYTICS_TARGET_EXTERNAL_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TARGET_EXTERNAL_ID,
        type: COLUMN_TYPE.TARGET_LOCATION,
        title: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        readableName: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        valueCallback: row => row.targetLocation.externalPublicId
      },
      [ColumnDefinitionKeys.ANALYTICS_TARGET_GUID_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TARGET_GUID_ID,
        type: COLUMN_TYPE.TARGET_LOCATION,
        title: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        readableName: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        valueCallback: row => row.targetLocation.id
      },
      [ColumnDefinitionKeys.ANALYTICS_TARGET_INT_NR]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TARGET_INT_NR,
        type: COLUMN_TYPE.TARGET_LOCATION,
        title: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        readableName: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        valueCallback: row => row.targetLocation.intNr
      },
      [ColumnDefinitionKeys.ANALYTICS_TARGET_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TARGET_NAME,
        type: COLUMN_TYPE.TARGET_LOCATION,
        title: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        readableName: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.target')},
        ),
        valueCallback: row => row.targetLocation.name
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_COMMENT]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_COMMENT,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.columnNames.transferComment'),
        readableName: this.translate('general.columnNames.transferComment'),
        valueCallback: row => row.transferComment
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_DATE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_DATE,
        type: COLUMN_TYPE.DATE,
        title: this.translate('general.columnNames.transferDate'),
        readableName: this.translate('general.columnNames.transferDate'),
        disableSort: true,
        valueCallback: row => row.transferDate
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_EXTERNAL_PERSON_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_EXTERNAL_PERSON_NAME,
        type: COLUMN_TYPE.OTHER,
        title: this.translate('general.columnNames.transferExternalPersonName'),
        readableName: this.translate('general.columnNames.transferExternalPersonName'),
        valueCallback: row => row.transferExternalPersonName
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_INTERNAL_PERSON_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_INTERNAL_PERSON_NAME,
        type: COLUMN_TYPE.NAME,
        title: this.translate('general.columnNames.transferInternalPersonName'),
        readableName: this.translate('general.columnNames.transferInternalPersonName'),
        valueCallback: row => row.transferInternalEmployee
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate('general.columnNames.transferId'),
        readableName: this.translate('general.columnNames.transferId'),
        valueCallback: row => row.transferId
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_COST_CENTER]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_COST_CENTER,
        type: COLUMN_TYPE.VALUE,
        title: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        readableName: this.translate(
          'general.columnNames.costCenterValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        valueCallback: row => row.transferItem.costCenter
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_EXTERNAL_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_EXTERNAL_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        readableName: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        valueCallback: row => row.transferItem.externalPublicId
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_GUID_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_GUID_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        readableName: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        valueCallback: row => row.transferItem.id
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_INT_NR]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_INT_NR,
        type: COLUMN_TYPE.VALUE,
        title: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        readableName: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        valueCallback: row => row.transferItem.intNr
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        readableName: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        valueCallback: row => row.transferItem.name
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_TYPE]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_TYPE,
        type: COLUMN_TYPE.ITEM_TYPE,
        title: this.translate(
          'general.columnNames.typeValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        readableName: this.translate(
          'general.columnNames.typeValue',
          {value: this.translate('general.columnNamesValue.item')},
        ),
        valueCallback: row => row.transferItem.type
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_ORGANISATION_EXTERNAL_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_ORGANISATION_EXTERNAL_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        readableName: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        valueCallback: row => row.transferItem.organisation?.externalPublicId
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_ORGANISATION_GUID_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_ORGANISATION_GUID_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        readableName: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        valueCallback: row => row.transferItem.organisation?.id
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_ORGANISATION_NR]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_ORGANISATION_NR,
        type: COLUMN_TYPE.VALUE,
        title: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        readableName: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        valueCallback: row => row.transferItem.organisation?.number
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_ORGANISATION_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_ORGANISATION_NAME,
        type: COLUMN_TYPE.VALUE,
        title: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        readableName: this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.equipmentOrganisation')},
        ),
        valueCallback: row => row.transferItem.organisation?.name
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ORGANISATION_EXTERNAL_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ORGANISATION_EXTERNAL_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.organisation')},
        ),
        readableName: this.translate(
          'general.columnNames.externalIdValue',
          {value: this.translate('general.columnNamesValue.organisation')},
        ),
        valueCallback: row => row.transferOrganisation.externalPublicId
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ORGANISATION_GUID_ID]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ORGANISATION_GUID_ID,
        type: COLUMN_TYPE.ID,
        title: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.organisation')},
        ),
        readableName: this.translate(
          'general.columnNames.guidValue',
          {value: this.translate('general.columnNamesValue.organisation')},
        ),
        valueCallback: row => row.transferOrganisation.id
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ORGANISATION_NR]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ORGANISATION_NR,
        type: COLUMN_TYPE.VALUE,
        title: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.organisation')},
        ),
        readableName: this.translate(
          'general.columnNames.internalNumberValue',
          {value: this.translate('general.columnNamesValue.organisation')},
        ),
        valueCallback: row => row.transferOrganisation.number
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_TYPE_NAME]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_TYPE_NAME,
        type: COLUMN_TYPE.TRANSFER_TYPE,
        title: this.translate('general.columnNames.transferType'),
        readableName: this.translate('general.columnNames.transferType'),
        valueCallback: row => row.transferType
      },
      [ColumnDefinitionKeys.ANALYTICS_TRANSFER_ITEM_UNIT]: {
        cdkColumnDef: ANALYTICS_COLUMN_DEF.TRANSFER_ITEM_UNIT,
        type: COLUMN_TYPE.VALUE,
        title: this.translate('general.units.unit'),
        readableName: this.translate('general.units.unit'),
        valueCallback: row => this.dimensionUnitPipe.getUserDimensionUnit(row.transferItem.unit)
      },
    }
  }
}
