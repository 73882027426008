import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Pageable } from '../../../../../shared/contract/task/pageable.interface';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from '../../../../../core/keycloak';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../assets/matomo/matomo-actions.enum';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { MaintenanceCategory } from '../../../../../shared/enums/maintenance-category.enum';
import { COLUMN_TYPE } from '../../../../../shared/constants/column-definition-constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { DisplayService } from '../../../../../shared/display.service';
import { MaintenanceColumnService } from '../../shared/service/maintenance-column.service';
import { EquipmentCheckerService } from '../../../../equipment/shared/services/equipment-checker.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  GuardedNavigableInputComponent
} from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from '../../../../../shared/router-history';
import { ViewEquipment } from '../../../../equipment/contract/view-equipment.interface';
import { TableConfigurationDialogComponent } from '../../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { faWarehouse } from '@fortawesome/pro-duotone-svg-icons';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import {
  MaintenanceListExportDialogComponent
} from '../maintenance-list-export-dialog/maintenance-list-export-dialog.component';
import { MaintenanceTaskDataSource } from '../../shared/service/maintenance-task.datasource';
import { switchMap, take, tap } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { EquipmentLocationType } from 'app/modules/maintenance/shared/contract/equipment-location-type-enum';
import { MaintenanceTaskSearch } from '../../shared/maintenance-task';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { BehaviorSubject } from 'rxjs';
import {ThumbnailSize} from '../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-list-table',
  templateUrl: './maintenance-task-list-table.component.html',
  styleUrls: ['../../shared/assets/maintenance-list-table-shared.scss', './maintenance-task-list-table.component.scss'],
})
export class MaintenanceTaskListTableComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  @Input() public hideEquipmentColumn = false;
  @Input() public tasks: MaintenanceTaskSearch[];
  @Input() public pageable: Pageable;
  @Input() public routerLinkField: string = null;
  @ViewChild('taskListPaginator') public paginator: MatPaginator;

  @Output() public rowDoubleClick = new EventEmitter();

  private _singleOverviewColumn = new BehaviorSubject<boolean>(false);
  public readonly singleOverviewColumn = this._singleOverviewColumn.asObservable();


  public selectedColumns: ColumnDefinition[];
  public displayedColumns: string[];
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly COLUMN_DEFINITION = { ...GENERAL_COLUMN_DEF, ...MAINTENANCE_TASK_COLUMN_DEF };
  public readonly MaintenanceCategory: typeof MaintenanceCategory = MaintenanceCategory;
  public readonly faTruck: IconDefinition = faTruck;
  public readonly faFileExcel: IconDefinition = faFileExcel;
  public readonly stockIcon: IconDefinition = faWarehouse;
  public readonly locationType = EquipmentLocationType;
  public readonly overviewColumnName = MaintenanceColumnService.overviewColumnName;

  constructor(
    protected router: Router,
    private dialog: MatDialog,
    protected route: ActivatedRoute,
    protected routerHistory: RouterHistory,
    public equipmentCheckerService: EquipmentCheckerService,
    public authService: KeycloakService,
    private focusMonitor: FocusMonitor,
    public columnService: MaintenanceColumnService,
    public maintenanceTaskDataSource: MaintenanceTaskDataSource,
    public displayService: DisplayService,
    private matomoTracker: MatomoTracker,
    private cdr: ChangeDetectorRef,
  ) {
    super(authService, router, route, routerHistory)
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public ngOnDestroy() {
  }

  public sortData(sort: Sort): void {
    this.maintenanceTaskDataSource.sort = sort;
    this.maintenanceTaskDataSource.loadTasks();
  }

  public getFirstLabels(equipment: any, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.equipmentLabels || []];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(equipment: any, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.equipmentLabels || []];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }

  public concatTransportTypes(equipment: ViewEquipment): string {
    return equipment?.transportTypes?.join(', ');
  }

  public notMaxDate(dueDate: string): boolean {
    if (!dueDate) {
      return true;
    }

    return dueDate.indexOf('3000-01-01') === -1;
  }

  public navigateToEquipmentTasks(equipmentId: string): void {
    if (equipmentId) {
      this.router.navigate(['assets', 'equipment', 'list', equipmentId, 'tasks'], {
        queryParams: {tabIndex: 0},
      });
    }
  }

  public navigateToProject(projectId: string): void {
    this.router.navigate(['sites', 'projects', 'list', projectId]);
  }

  public navigateToStock(stockId: string): void {
    this.router.navigate(['sites', 'stocks', 'list', stockId]);
  }

  public openConfiguration(): void {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.COLUMN_CONFIG_OPEN);
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {columnService: this.columnService},
      restoreFocus: false,
      autoFocus: false,
    });

    let columns: ColumnDefinition[];

    dialogRef.afterClosed()
    .pipe(
      untilDestroyed(this),
      tap((selectedColumns: ColumnDefinition[]) => columns = selectedColumns),
      switchMap(() => this.maintenanceTaskDataSource.getSize()),
      take(1),
    ).subscribe((pageSize: number) => {
      if (columns) {
        this.columnService.selectColumns(columns, pageSize);
      }
      this.focusMonitor.stopMonitoring(document.getElementById('configBtn'));
    });
  }

  public openMaintenanceListExport(): void {
      this.matomoTracker.trackEvent(matomoCategories.MAINTENANCE_LIST, matomoActions.EXPORT_MAINTENANCE_OPEN);
      this.dialog.open(MaintenanceListExportDialogComponent, {
        autoFocus: false,
        data: {
          selectedColumns: this.selectedColumns,
        },
      });
      this.focusMonitor.stopMonitoring(document.getElementById('exportBtn'));
  }

  public isEmptyAddress(address: any): boolean {
    return !address || (!address.postalCode && !address.city && !address.street && !address.streetNumber)
  }

  private initListeners(): void {
    this.initSelectedColumnsListener();
    this.initDisplayedColumnsListener();
  }

  private initSelectedColumnsListener(): void {
    this.columnService.selectedColumns
      .pipe(untilDestroyed(this))
      .subscribe((selectedColumns: ColumnDefinition[]) => {
        this.selectedColumns = selectedColumns;
        this.cdr.markForCheck();
      });
  }

  private initDisplayedColumnsListener(): void {
    this.columnService.displayedColumns
      .pipe(untilDestroyed(this))
      .subscribe((columns: string[]) => {
        this._singleOverviewColumn.next(this.isOverviewColumnOnly(columns));
        this.displayedColumns = _.cloneDeep(columns);
        this.cdr.markForCheck();
      });
  }

  private isOverviewColumnOnly(columns: string[]): boolean {
    return columns && columns?.length === 1 && columns[0] === MaintenanceColumnService.overviewColumnName;
  }

    protected readonly ThumbnailSize = ThumbnailSize;
}
