import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewChild,
  HostBinding,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { GalleryOptions } from '../../models/gallery-options.class';
import { GalleryImage } from '../../models/gallery-image.class';
import { GalleryOrderedImage } from '../../models/gallery-ordered-image.class';
import { GalleryPreviewComponent } from './components/gallery-preview/gallery-preview.component';
import { GalleryImageComponent } from './components/gallery-image/gallery-image.component';
import { GalleryThumbnailsComponent } from '../gallery-thumbnails/gallery-thumbnails.component';
import { GalleryLayout } from '../../enums/gallery-layout.enum';


interface GalleryComponentSimpleChanges extends SimpleChanges {
  options?: SimpleChange;
  images?: SimpleChange;
}

@Component({
  selector: 'bh-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public options: GalleryOptions[] = [];
  @Input() public images: GalleryImage[] = [];

  @Output() public imagesReady = new EventEmitter();
  @Output() public onChange = new EventEmitter<{
    index: number;
    image: GalleryImage;
  }>();
  @Output() public previewOpen = new EventEmitter();
  @Output() public previewClose = new EventEmitter();
  @Output() public previewChange = new EventEmitter<{
    index: number;
    image: GalleryImage;
  }>();

  public smallImages: string[] | SafeResourceUrl[] = [];
  public mediumImages: GalleryOrderedImage[] = [];
  public bigImages: string[] | SafeResourceUrl[] = [];
  public links: string[] = [];
  public labels: string[] = [];

  public selectedIndex = 0;
  public previewEnabled: boolean;

  public currentOptions: GalleryOptions;

  private breakpoint: number | undefined = undefined;
  private prevBreakpoint: number | undefined = undefined;
  private fullWidthTimeout: any;

  @ViewChild(GalleryPreviewComponent) public preview: GalleryPreviewComponent;
  @ViewChild(GalleryImageComponent) public image: GalleryImageComponent;
  @ViewChild(GalleryThumbnailsComponent) public thubmnails: GalleryThumbnailsComponent;

  @HostBinding('style.width') public width: string;
  @HostBinding('style.height') public height: string;
  @HostBinding('style.left') public left: string;

  constructor(private elementRef: ElementRef) {}

  public ngOnInit() {
    this.options = this.options.map((opt) => new GalleryOptions(opt));
    this.sortOptions();
    this.setBreakpoint();
    this.setOptions();
    this.checkFullWidth();
    if (this.currentOptions) {
      this.selectedIndex = <number>this.currentOptions.startIndex;
    }
  }

  public ngOnChanges(changes: GalleryComponentSimpleChanges): void {
    if (changes.images && !this.isEqualImages(changes.images.previousValue, changes.images.currentValue)) {
      this.refreshImages();
      return;
    }

    if (changes.options) {
      this.refreshOptions();
    }
  }

  public ngAfterViewInit(): void {
    this.checkFullWidth();
  }

  @HostListener('window:resize') public onResize() {
    this.setBreakpoint();

    if (this.prevBreakpoint !== this.breakpoint) {
      this.setOptions();
      this.resetThumbnails();
    }

    if (this.currentOptions && this.currentOptions.fullWidth) {
      if (this.fullWidthTimeout) {
        clearTimeout(this.fullWidthTimeout);
      }

      this.fullWidthTimeout = setTimeout(() => {
        this.checkFullWidth();
      }, 200);
    }
  }

  public getImageHeight(): string {
    return this.currentOptions && this.currentOptions.thumbnails
      ? this.currentOptions.imagePercent + '%'
      : '100%';
  }

  public getThumbnailsHeight(): string {
    if (this.currentOptions && this.currentOptions.image) {
      return (
        'calc(' +
        this.currentOptions.thumbnailsPercent +
        '% - ' +
        this.currentOptions.thumbnailsMargin +
        'px)'
      );
    } else {
      return '100%';
    }
  }

  public getThumbnailsMarginTop(): string {
    if (
      this.currentOptions &&
      this.currentOptions.layout === GalleryLayout.THUMBNAILS_BOTTOM
    ) {
      return this.currentOptions.thumbnailsMargin + 'px';
    } else {
      return '0px';
    }
  }

  public getThumbnailsMarginBottom(): string {
    if (
      this.currentOptions &&
      this.currentOptions.layout === GalleryLayout.THUMBNAILS_TOP
    ) {
      return this.currentOptions.thumbnailsMargin + 'px';
    } else {
      return '0px';
    }
  }

  public openPreview(index: number): void {
    if (this.currentOptions.previewCustom) {
      this.currentOptions.previewCustom(index);
    } else {
      this.previewEnabled = true;
      this.preview.open(index);
    }
  }

  public onPreviewOpen(): void {
    this.previewOpen.emit();

    if (this.image && this.image.autoPlay) {
      this.image.stopAutoPlay();
    }
  }

  public onPreviewClose(): void {
    this.previewEnabled = false;
    this.previewClose.emit();

    if (this.image && this.image.autoPlay) {
      this.image.startAutoPlay();
    }
  }

  public selectFromImage(index: number) {
    this.select(index);
  }

  public selectFromThumbnails(index: number) {
    this.select(index);

    if (
      this.currentOptions &&
      this.currentOptions.thumbnails &&
      this.currentOptions.preview &&
      (!this.currentOptions.image ||
        this.currentOptions.thumbnailsRemainingCount)
    ) {
      this.openPreview(this.selectedIndex);
    }
  }

  public show(index: number): void {
    this.select(index);
  }

  public showNext(): void {
    this.image.showNext();
  }

  public showPrev(): void {
    this.image.showPrev();
  }

  public canShowNext(): boolean {
    if (this.images && this.currentOptions) {
      return this.currentOptions.imageInfinityMove ||
        this.selectedIndex < this.images.length - 1
        ? true
        : false;
    } else {
      return false;
    }
  }

  public canShowPrev(): boolean {
    if (this.images && this.currentOptions) {
      return this.currentOptions.imageInfinityMove || this.selectedIndex > 0
        ? true
        : false;
    } else {
      return false;
    }
  }

  public previewSelect(index: number) {
    this.previewChange.emit({ index, image: this.images[index] });
  }

  public moveThumbnailsRight() {
    if (this.thubmnails) {
      this.thubmnails.moveRight();
    }
  }

  public moveThumbnailsLeft() {
    if (this.thubmnails) {
      this.thubmnails.moveLeft();
    }
  }

  public canMoveThumbnailsRight() {
    return this.thubmnails ? this.thubmnails.canMoveRight() : false;
  }

  public canMoveThumbnailsLeft() {
    return this.thubmnails ? this.thubmnails.canMoveLeft() : false;
  }

  private refreshImages(): void {
    this.setImages();
    this.refreshOptions();
  }

  private refreshOptions(): void {
    this.setOptions();
    if (this.images.length) {
      this.imagesReady.emit();
    }

    this.image?.reset(<number>this.currentOptions.startIndex);

    if (
      this.currentOptions.thumbnailsAutoHide &&
      this.currentOptions.thumbnails &&
      this.images.length <= 1
    ) {
      this.currentOptions.thumbnails = false;
      this.currentOptions.imageArrows = false;
    }

    this.resetThumbnails();
  }

  private isEqualImages(firstImages: GalleryImage[], secondImages: GalleryImage[]): boolean {
    return firstImages === secondImages
      || (!Boolean(firstImages) && !Boolean(secondImages))
      || (firstImages?.length === secondImages?.length
        && !(firstImages.some((image, index) => !GalleryImage.isEqual(image, secondImages[index]))));
  }

  private resetThumbnails() {
    if (this.thubmnails) {
      this.thubmnails.reset(<number>this.currentOptions.startIndex);
    }
  }

  private select(index: number) {
    this.selectedIndex = index;

    this.onChange.emit({
      index,
      image: this.images[index],
    });
  }

  private checkFullWidth(): void {
    if (this.currentOptions && this.currentOptions.fullWidth) {
      this.width = document.body.clientWidth + 'px';
      this.left =
        -1 * (document.body.clientWidth - this.elementRef.nativeElement.parentNode.innerWidth) / 2
        + 'px';
    }
  }

  private setImages(): void {
    this.smallImages = this.images.map((img) => <string>img.small);
    this.mediumImages = this.images.map(
      (img, i) =>
        new GalleryOrderedImage({
          src: img.medium,
          index: i,
        })
    );
    this.bigImages = this.images.map((img) => <string>img.big);
    this.links = this.images.map((img) => <string>img.url);
    this.labels = this.images.map((img) => <string>img.label);
  }

  private setBreakpoint(): void {
    this.prevBreakpoint = this.breakpoint;
    let breakpoints;

    if (typeof window !== 'undefined') {
      breakpoints = this.options
        .filter((opt) => opt.breakpoint >= window.innerWidth)
        .map((opt) => opt.breakpoint);
    }

    if (breakpoints && breakpoints.length) {
      this.breakpoint = breakpoints.pop();
    } else {
      this.breakpoint = undefined;
    }
  }

  private sortOptions(): void {
    this.options = [
      ...this.options.filter((a) => a.breakpoint === undefined),
      ...this.options
        .filter((a) => a.breakpoint !== undefined)
        .sort((a, b) => b.breakpoint - a.breakpoint),
    ];
  }

  private setOptions(): void {
    this.currentOptions = new GalleryOptions({});

    this.options
      .filter((opt) =>
        opt.breakpoint === undefined || opt.breakpoint >= this.breakpoint
      )
      .map((opt) => this.combineOptions(this.currentOptions, opt));

    this.width = <string>this.currentOptions.width;
    this.height = <string>this.currentOptions.height;
  }

  private combineOptions(first: GalleryOptions, second: GalleryOptions) {
    Object.keys(second).map((val) =>
      (first[val] = second[val] !== undefined ? second[val] : first[val])
    );
  }
}
