import { AbstractControl, ValidatorFn } from '@angular/forms';

export function numberBiggerThanValidator(minimum: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const valid = Number.isFinite(control.value) && control.value > minimum;
    return valid
      ? null
      : { numberBigger: 'Weniger als zulässiger Wert'};
  };
}
