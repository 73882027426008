<table mat-table
  *ngIf="historyTable && historyTable.length > 0; else noEntries"
  class="history-table"
  [dataSource]="historyTable">
  <ng-container [matColumnDef]="columnNames.DATE">
    <th mat-header-cell *matHeaderCellDef> {{'general.date'|translate}} </th>
    <td mat-cell *matCellDef="let row">
      <bh-transport-history-table-item
        [item]="{ type: TransportHistoryFieldType.DATE, value: row[columnNames.DATE] }"
        [emptyValue]="''">
      </bh-transport-history-table-item>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.USER">
    <th mat-header-cell *matHeaderCellDef> {{'general.labels.changedBy'|translate}} </th>
    <td mat-cell *matCellDef="let row">
      <ng-container *ngIf="row[columnNames.USER]">
        <ng-container *ngIf="row[columnNames.USER].status === userStatus.ACTIVE; else notActiveUser">
          <div class="text-overflow">{{row[columnNames.USER].firstName}}</div>
          <div class="text-overflow">{{row[columnNames.USER].name}}</div>
        </ng-container>
        <ng-template #notActiveUser>
          {{row[columnNames.USER].status | userStatus}}
        </ng-template>
      </ng-container>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.FIELD">
    <th mat-header-cell *matHeaderCellDef> {{'general.property'|translate}} </th>
    <td mat-cell *matCellDef="let row">
      <div class="text-overflow" *ngFor="let fieldNameLine of row[columnNames.FIELD] | transportFields">{{fieldNameLine}}</div>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.OLD">
    <th mat-header-cell *matHeaderCellDef class="column-value-old"> {{'general.old'|translate}} </th>
    <td mat-cell *matCellDef="let row" class="column-value column-value-old">
      <bh-transport-history-table-item
        [item]="row[columnNames.OLD]"
        [ngClass]="{'scrollable-container': isScrollableCell(row[columnNames.FIELD])}"
      ></bh-transport-history-table-item>
    </td>
  </ng-container>

  <ng-container [matColumnDef]="columnNames.NEW">
    <th mat-header-cell *matHeaderCellDef class="column-value-new"> {{'general.new'|translate}} </th>
    <td mat-cell *matCellDef="let row" class="column-value column-value-new">
      <bh-transport-history-table-item
        [item]="row[columnNames.NEW]"
        [ngClass]="{'scrollable-container': isScrollableCell(row[columnNames.FIELD])}">
      </bh-transport-history-table-item>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{ 'odd': row[columnNames.ALTERNATE_COLOR] }"></tr>
</table>

<ng-template #noEntries>
  <span>({{'modules.transportation.transportHistory.noEntriesYet'|translate}})</span>
</ng-template>

