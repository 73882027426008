import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Authorities} from '../../../../shared/enums/authorities.enum';
import {CreateRoleCommand} from '../../contract/create-role-command';
import {PrivilegedAuthorities} from '../../../../shared/enums/privileged-authorities.enum';
import {KeycloakService} from '../../../../core/keycloak';
import {RolesStore} from '../../shared/roles.store';
import {HttpErrorResponse} from '@angular/common/http';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {RoleAuthority} from '../../shared/interfaces/role-authority.interface';
import {validAuthCombinationsValidator} from '../../../../shared/custom-validators/valid-auth-combinations.validator';
import {RouterHistory} from '../../../../shared/router-history';
import {RoleNameInUseValidator} from 'app/shared/custom-validators/role-name-in-use.validator';
import {asyncValidatorFactory} from 'app/shared/custom-validators/async-validator.factory';
import {RoleBaseComponent} from '../common/role-base.component';
import {LanguageService} from '../../../../shared/services/language.service';
import {RealmType} from '../../../../shared/contract/realm-type';
import {PrivilegedRole} from '../../../../shared/enums/privileged-roles.enum';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';

@UntilDestroy()
@Component({
  selector: 'bh-role-add',
  templateUrl: 'role-add.component.html',
  styleUrls: ['role-add.component.scss']
})
export class RoleAddComponent extends RoleBaseComponent implements OnInit, OnDestroy {

  rolesAddForm: UntypedFormGroup;
  roleAuthorities: RoleAuthority[] = [];

  constructor(protected router: Router,
              protected authService: KeycloakService,
              protected activatedRoute: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected keycloakService: KeycloakService,
              private formBuilder: UntypedFormBuilder,
              private rolesStore: RolesStore) {
    super(authService, router, activatedRoute, routerHistory, languageService);
  }

  ngOnInit(): void {
    this.buildForm();
    this.getAuthorities();
  }

  ngOnDestroy(): void {
  }

  save(): void {
    if (this.isValid()) {
      if (this.rolesAddForm.valid) {
        let cmd: CreateRoleCommand = new CreateRoleCommand();
        let formValue = this.rolesAddForm.value;

        cmd.roleName = formValue.roleName;
        cmd.roleDescription = formValue.roleDescription;
        cmd.authorities = formValue.authorities;
        cmd.realmType = formValue.realmType;

        this.rolesStore.addRole(cmd).pipe(untilDestroyed(this)).subscribe(
          () => {
            this.router.navigate(['/roles/list']);
          },
          (error: HttpErrorResponse) => {
            console.log('role user error: ', error);
          }
        );
      }
    }
  }

  public isValid(): boolean {
    return this.rolesAddForm.valid;
  }

  selectAuthority(authority: RoleAuthority): void {
    authority.isSelected = !authority.isSelected;
    this.authoritiesFA.markAsDirty();

    if (authority.isSelected) {
      authority.isSelected = true;
      this.authoritiesFA.push(new UntypedFormControl(authority.name));
    } else {
      let index: number = this.authoritiesFA.value.findIndex(au => authority.name === au);

      this.authoritiesFA.removeAt(index);
    }
  }

  resetForm(): void {
    this.authoritiesFA.getRawValue().forEach(() => {
      this.authoritiesFA.removeAt(0);
    });

    this.roleAuthorities.forEach((value: RoleAuthority) => {
      value.isSelected = false;
    });

    if (this.isSuperAdmin()) {
      this.rolesAddForm.reset();
    } else {
      this.rolesAddForm.reset({realmType: this.getCurrentRealm()});
    }
  }

  private buildForm(): void {
    this.rolesAddForm = this.formBuilder.group({
      roleName: [
        '',
        [<any>Validators.required, TrimValidator.hasWhitespaces],
        [asyncValidatorFactory((value) => RoleNameInUseValidator.isValid(value, this.realmType.value, this.rolesStore))]
      ],
      roleDescription: '',
      realmType: this.getCurrentRealm(),
      authorities: this.formBuilder.array([], Validators.compose(
        [<any>Validators.required, validAuthCombinationsValidator()]))
    });
  }

  private getCurrentRealm(): RealmType {
    if (this.authService.isInRole(PrivilegedRole.Superadmin)) {
      return RealmType.DEFAULT;
    }
    return KeycloakService.getRealmType();
  }

  private getAuthorities(): void {
    let authorities: string[] = Object.keys(Authorities);
    let privilegedAuthorities: string[] = Object.keys(PrivilegedAuthorities);

    authorities.map((authority: string) => {
      let privilegedAuthority: string = privilegedAuthorities.find(pa => authority === pa);
      if (!privilegedAuthority) {
        this.roleAuthorities.push({
          name: authority,
          isSelected: false
        })
      }
    });
  }

  validationConflict(authority): boolean {
    let conflicting = false;

    if (this.authoritiesFA.errors
      && this.authoritiesFA.errors.invalidCombinations) {
      this.authoritiesFA.errors.invalidCombinations.forEach(comb => {
        if (comb.includes(authority.name)) {
          conflicting = true;
        }
      })
    }
    return conflicting;
  }

  get roleName(): AbstractControl {
    return this.rolesAddForm.get('roleName')
  }

  get realmType(): AbstractControl {
    return this.rolesAddForm.get('realmType')
  }

  get authoritiesFA(): UntypedFormArray {
    return this.rolesAddForm.get('authorities') as UntypedFormArray;
  }
}
