import { OspDateTime } from './osp-date-time';

export class CreateEquipmentCommand {
  equipmentSerialNumber: string;
  equipmentCustomerSerialNumber: string;
  equipmentName: string;
  equipmentModel: string;
  equipmentProductClass: string;
  equipmentCostCenter: string;
  equipmentLicensePlate: string;
  equipmentGuarantee: string;
  equipmentGuaranteeOperatingHoursLimit: number;
  equipmentConstructionYear: string;
  equipmentDeliveryDate: OspDateTime;
  equipmentTypeId: string;
  manufacturerId: string;
  organisationId: string;
  technicalFields: Map<string, string>;
  equipmentBGLCode: string;
  labels: string[];
  equipmentRegistrationDate: OspDateTime;
  stockId: string;
}
