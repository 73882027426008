import { environment } from '../../../environments/environment';
import { LocalUserStorageService } from './local-user-storage.service';
import { KeycloakService } from '../../core/keycloak';
import { MultiTenancy } from '../../core/multi-tenancy';

export class UserConfigurationInitService {

  /** load the user configuration as early as possible **/
  static loadUserConfiguration(userId: string): Promise<string> {

    return new Promise((resolve, reject) => {

      if (navigator.onLine) {
        let userRoleServiceUrl = MultiTenancy.prepareUrl(environment.APP_USERROLE_SERVICE_BASE_URL);

        const token = localStorage.getItem('token');
        const options = {
          method: 'GET',
          url: userRoleServiceUrl + '/api/v1/users/user-configuration',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-Realm': KeycloakService.getRealm()
          },
          body: ''
        };

        let xhr = new XMLHttpRequest();
        xhr.open(options.method || 'GET', options.url);
        if (options.headers) {
          Object.keys(options.headers).forEach(key => {
            xhr.setRequestHeader(key, options.headers[key]);
          });
        }
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(xhr.statusText);
          }
        };
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(options.body);
      } else {
        resolve(LocalUserStorageService.getUserValue('user-configuration', userId));
      }
    });

  }

}
