export enum FieldLimit {
  SHORTER_IDENTIFIER = 12,
  SHORT_IDENTIFIER = 30,
  MEDIUM_IDENTIFIER = 50,
  LONG_IDENTIFIER = 100,

  SHORT_TEXT = 150,
  MEDIUM_TEXT = 200,
  LONG_TEXT = 300,

  SHORT_DESCRIPTION = 1000,
  MEDIUM_DESCRIPTION = 2000,
  LONG_DESCRIPTION = 5000,
  ENTITY_DESCRIPTION = 28000,

  THREE_DIGITS_DECIMAL = 999.99,
  FOUR_DIGITS_DECIMAL = 9999.99,
  SEVEN_DIGITS_DECIMAL = 9999999.99,
  TEN_DIGITS_DECIMAL = 9999999999.99,

  SEVEN_DIGITS_INTEGER = 9999999,

  EMAIL = 250,
  NAME = 100,
  PHONE_NUMBER = 30,
  FAX_NUMBER = 30,
  STREET = 100,
  STREET_NUMBER = 20,
  POSTAL_CODE = 15,
  CITY = 100,
  COUNTRY = 100,
  SCAN_CODE = 100,
  LICENSE_PLATE = 20,
  COST_CENTER = 50,
  SEARCH_TERM = 100,
  GEO_COORDINATE = 20,
  EXTERNAL_EMPLOYEE = 150,
  COMMENT = 5000,
  AUTONUMBER_SCHEMA = 60
}
