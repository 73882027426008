import { ContactMapComponent } from '../contacts/contact-map/contact-map.component';
import { ContactViewComponent } from '../contacts/contact-list/contact-view/contact-view.component';
import { ContactListComponent } from '../contacts/contact-list/contact-list.component';
import { ModuleWithProviders } from '@angular/core';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { SearchFilterComponent } from 'app/shared/components/search-filter-component/landscape/search-filter.component';
import { CanActivateChildLandscape } from '../../../core/guards/can-activate-child-landscape';
import { CanActivateChildAuthority } from 'app/core/guards/can-activate-child-authority';
import { RouterModule, Routes } from '@angular/router';
import { ContactViewDetailComponent } from '../contacts/contact-list/contact-view-detail/contact-view-detail.component';
import { ContentComponent } from 'app/shared/components/content-component/content.component';

export const contactRoutes: Routes = [
  {
    path: 'assets',
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape],
    children: [
      {
        path: 'contact',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]
        },
        children: [
          {
            path: 'add',
            component: ContactListComponent,
            data: {
              authorities: [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE],
              create: true
            },
          },
          {
            path: 'list',
            component: ContactListComponent,
            data: {
              authorities: [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]
            },
            children: [
              {
                path: '',
                component: ContactViewComponent,
                data: {
                  authorities: [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]
                },
                children: [
                  {
                    path: ':id',
                    component: ContactViewDetailComponent,
                    data: {
                      authorities: [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]
                    }
                  },
                ]
              }
            ]
          },
        ]
      }
    ]
  },
  {
    path: 'contact',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape],
    children: [
      {
        path: 'map',
        component: SearchFilterComponent,
        data: { authorities: [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE] },
        children: [
          {
            path: '',
            component: ContactMapComponent
          }
        ]
      }
    ]
  }
];

export const contactRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(contactRoutes);
