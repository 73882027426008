import { Pipe, PipeTransform } from '@angular/core';
import { ChartData } from 'chart.js';
import { EquipmentStatusChartDataPrepared } from '../contract/equipment-status-chart-data-prepared.interface';
import { EquipmentStatusColor } from 'app/modules/equipment/shared/contracts/equipment-status-color.enum';

@Pipe({
  name: 'equipmentStatusChartData'
})
export class EquipmentStatusChartDataPipe implements PipeTransform {

  private backgroundColor = [
    EquipmentStatusColor.OPERATIONAL,
    EquipmentStatusColor.OPERATIONAL_WITH_RESTRICTIONS,
    EquipmentStatusColor.NOT_OPERATIONAL,
    EquipmentStatusColor.NOT_AVAILABLE,
  ];
  private hoverBackgroundColor = ['#83a80f', '#edb611', '#e41d1d', '#686868'];

  public transform(data: EquipmentStatusChartDataPrepared): ChartData<'bar'> {
    return data && data?.length > 0
      ? this.getChartData(data)
      : null
  }

  private getChartData(data: EquipmentStatusChartDataPrepared): ChartData<'bar'> {
    const labels = (data?.[0] || []).map(item => item.category);

    return {
      labels,
      datasets: data.map(dataset => ({
        data: dataset.map(item => item?.count),
        backgroundColor: this.backgroundColor,
        hoverBackgroundColor: this.hoverBackgroundColor,
      })),
    };
  }

}

