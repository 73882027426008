<div class="rental-board-search" fxLayout="row" fxLayoutAlign="space-between center">
  <form fxFlex="70" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
        [formGroup]="searchForm" novalidate>
    <mat-form-field fxFlex="20">
      <input matInput [placeholder]="'modules.disposition.base.enterSearchTerm'|translate" formControlName="terms" />
      <button mat-button *ngIf="searchForm.value.terms" matSuffix mat-icon-button aria-label="Clear" (click)="reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFlex="10">
      <mat-select multiple [placeholder]="'general.label.s'|translate" formControlName="selectedLabels">
        <mat-option *ngFor="let label of labels" [value]="label.name">
          {{label.name}} ({{label.count}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="15">
      <mat-select [placeholder]="'general.type'|translate" multiple formControlName="selectedEquipmentTypes">
        <mat-optgroup *ngFor="let group of groupedEquipmentTypes" label="{{group.category1}} ({{group.total}})">
          <mat-option *ngFor="let subType of group.subTypes" [value]="subType.equipmentTypeId">
            {{ subType.category2 }} ({{ subType.count }})
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>


    <mat-form-field fxFlex="15">
      <mat-select [placeholder]="'modules.disposition.rentalBoard.availability'|translate" formControlName="availabilityFilter">
        <mat-option value="{{FILTER_ALL}}">{{'general.filterLabels.all'|translate|uppercase}}</mat-option>
        <mat-option value="{{FILTER_AVAILABLE}}">{{'general.filterLabels.available'|translate|uppercase}}</mat-option>
        <mat-option value="{{FILTER_UNAVAILABLE}}">{{'general.filterLabels.rented'|translate|uppercase}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="10" *ngIf="displayDateFields()">
      <input matInput formControlName="startDate" [placeholder]="'general.from'|translate|titlecase" autocomplete="off"
             [matDatepicker]="startPicker">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>

      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="10" *ngIf="displayDateFields()">
      <input matInput formControlName="endDate" [placeholder]="'general.to'|translate|titlecase" autocomplete="off"
             [matDatepicker]="endPicker">
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>

      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>

    <div *ngIf="displayDateFields()" fxFlex="10">
      <mat-icon class="bh-mat-icon" [matTooltip]="'general.buttons.resetDateFields'|translate" (click)="clearDateFilter()">clear</mat-icon>
    </div>
  </form>

  <mat-paginator #paginator
                 [length]="rentalStore.length | async"
                 [pageIndex]="rentalStore.pagination.index"
                 [pageSize]="rentalStore.pagination.size"
                 [pageSizeOptions]="[5, 25, 50, 100]"
                 (page)="onPaginateChange($event)">
  </mat-paginator>
</div>
