import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ViewStock } from './view-stock.interface';
import { environment } from '../../../../environments/environment';

@Injectable()
export class StockService {
  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/stocks';

  constructor(private http: HttpClient) {
  }

  public getStocks(): Observable<ViewStock[]> {
    return this.http.get<ViewStock[]>(this.url);
  }
}
