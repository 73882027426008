import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseEquipmentViewComponent } from '../base/base-equipment-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { DisplayService } from '../../../../../../shared/display.service';
import { RouterHistory } from '../../../../../../shared/router-history';
import { LanguageService } from 'app/shared/services/language.service';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'bh-mobile-view-equipment',
  templateUrl: './mobile-view-equipment.component.html',
  styleUrls: ['./mobile-view-equipment.component.scss']
})
  export class MobileViewEquipmentComponent extends BaseEquipmentViewComponent implements OnInit {

  @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel;

  public currentViewMenu: ViewMenus;
  public viewMenus: ViewMenus[] = [
    {
      name: this.translate('general.generalData').toUpperCase(),
      url: 'general',
      condition: true
    },
    {
      name: this.translate('general.description').toUpperCase(),
      url: 'description',
      condition: true
    },
    {
      name: this.translate('modules.equipment.base.technicalData').toUpperCase(),
      url: 'technical-field',
      condition: true
    },
    {
      name: this.translate('modules.equipment.equipmentView.timeline').toUpperCase(),
      url: 'timeline',
      condition: true
    },
    {
      name: this.translate('general.attachment.pl').toUpperCase(),
      url: 'attachment',
      condition: true
    },
    {
      name: this.translate('modules.equipment.equipmentView.currentLocation').toUpperCase(),
      url: 'current-location',
      condition: true
    },
    {
      name: this.translate('general.disposition.s').toUpperCase(),
      url: 'disposition',
      condition: this.hasModule(this.modules.DISPOSITION)
    }
  ];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              public displayService: DisplayService,
              protected langService: LanguageService,
              protected matomoTracker: MatomoTracker) {
    super(router, route, authService, dialog, routerHistory, equipmentStore, displayService, langService, matomoTracker);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setCurrentViewMenu();
  }

  public setCurrentViewMenu() {
    const currentMenuUrl = this.route.snapshot.firstChild.url[0].path;
    this.currentViewMenu = this.viewMenus.find((vm) => vm.url === currentMenuUrl);
  }

  public setMenu(menu: ViewMenus) {
    this.currentViewMenu = menu;
    this.expansionPanel.close();
  }

}

interface ViewMenus {
  name: string,
  url: string,
  condition: boolean
}
