import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeQualification } from '../contract/employee-qualification';
import { Qualification } from '../contract/qualification';
import { RenewEmployeeQualificationCommand } from '../contract/employee-qualification-renew-command';
import { QualificationCreateCommand } from '../contract/qualification-create-command';
import { EmployeeQualificationAssignCommand } from '../contract/employee-qualification-assign-command';
import { UpdateEmployeeQualificationAssignmentCommand } from '../contract/update-employee-qualification-assignment-command';
import { ViewEmployeeQualification } from '../contract/view-employee-qualification';
import { QualificationUpdateCommand } from '../contract/qualification-update-command';
import { EmployeeQualificationAssignmentRemoveCommand } from '../contract/employee-qualification-assignment-remove-command';
import { EmployeeQualificationDocumentUpdateCommand } from '../contract/employee-qualification-document-update-command';

@Injectable()
export class QualificationService {
  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/employees';
  private qualificationUrl = this.url + '/qualifications';


  constructor(private http: HttpClient) {
  }

  public getEmployeeQualifications(employeeId: string): Observable<ViewEmployeeQualification> {
    return this.http.get<ViewEmployeeQualification>(this.url + '/' + employeeId + '/qualifications');
  }

  public getQualificationById(qualificationId: string): Observable<EmployeeQualification> {
    return this.http.get<EmployeeQualification>(this.qualificationUrl + '/' + qualificationId);
  }

  public createQualification(command: QualificationCreateCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/create', command, { responseType: 'text' });
  }

  public addQualificationToEmployee(command: EmployeeQualificationAssignCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/assign-to-employee', command, { responseType: 'text' });
  }

  public updateQualification(command: QualificationUpdateCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/update', command, {responseType: 'text'});
  }

  public updateQualificationAssignment(command: UpdateEmployeeQualificationAssignmentCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/update-assignment', command, {responseType: 'text'});
  }

  public removeQualificationAssignment(command: EmployeeQualificationAssignmentRemoveCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/remove-assignment', command, {responseType: 'text'});
  }

  public getQualifications(): Observable<Qualification[]> {
    return this.http.get<Qualification[]>(this.qualificationUrl);
  }

  public renewQualification(command: RenewEmployeeQualificationCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/renew', command, { responseType: 'text' });
  }

  public addDocument(cmd: EmployeeQualificationDocumentUpdateCommand): Observable<string> {
    return this.http.post(this.qualificationUrl + '/add-document', JSON.stringify(cmd), {responseType: 'text'})
  }

  public getUrl(): string {
    return this.url;
  }
}
