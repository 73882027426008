import {Component, Input} from '@angular/core';

@Component({
  selector: 'bh-error-card',
  templateUrl: './error-card.component.html',
  styleUrls: ['./error-card.component.scss']
})
export class ErrorCardComponent {
  @Input() protected text: string;
}
