import { AttachedDocument } from 'app/shared/contract/attached-document.interface';

export class EmployeeQualificationDocumentUpdateCommand {
  public qualificationId: string;
  public employeeId: string;
  public uploadDocument: AttachedDocument;

  constructor (qualificationId: string, employeeId: string, uploadDocument: AttachedDocument) {
    this.qualificationId = qualificationId;
    this.employeeId = employeeId;
    this.uploadDocument = uploadDocument;
  }
}
