import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon/icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { IconParamsTransformPipe } from './pipes/icon-params-transform.pipe';


@NgModule({
  declarations: [
    IconComponent,
    IconParamsTransformPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FontAwesomeModule,
  ],
  exports: [
    IconComponent,
  ]
})
export class BhIconModule { }
