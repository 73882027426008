import { Pipe, PipeTransform } from '@angular/core';
import { GeofenceListItem } from './geofence-list-item';

@Pipe({
  name: 'hasAnyDeletable'
})
export class HasAnyDeletablePipe implements PipeTransform {

  public transform(items: GeofenceListItem[]): boolean {
    return items?.length > 0 && items.some(item => !Boolean(item.readOnly));
  }

}
