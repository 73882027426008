import { TimelineEvent } from './timeline-event';
import { ViewProject } from './view-project.interface';
import * as moment from 'moment';

export class ProjectEvent extends TimelineEvent {

  public isProjectEvent = true;

  constructor(project: ViewProject) {
    super(project.projectId,
      project.projectId,
      project.projectStart,
      project.projectEnd,
      project.chargeDate ? new Date(moment.utc(project.chargeDate).format('YYYY-MM-DD')) : null,
      '',
      true,
      false,
      '#f2f2f2'); {
        this.start_date = this.normalizeProjectDate(project.projectStart, null);
        this.end_date = this.normalizeProjectDate(project.projectEnd, moment(this.start_date).add(10, 'years').toDate());
      }
  }

  private normalizeProjectDate(date: Date | null, fallback: Date | null): Date | null {
    return date ? new Date(moment.utc(date).format('YYYY-MM-DD')) : fallback;
  }
}

export function isProjectEvent(event: TimelineEvent): event is ProjectEvent {
  return (<ProjectEvent>event).isProjectEvent !== undefined;
}
