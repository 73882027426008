import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewEquipmentEmployeeAssignment } from '../../contract/view-equipment-employee-assignment.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'bh-employee-assignment-view-dialog',
  templateUrl: 'equipment-employee-assignment-view-dialog.component.html',
  styleUrls: ['equipment-employee-assignment-view-dialog.component.scss']
})
export class EquipmentEmployeeAssignmentViewDialogComponent implements OnInit {

  employeeAssignment: ViewEquipmentEmployeeAssignment;
  icon: IconDefinition;

  constructor(public dialogRef: MatDialogRef<EquipmentEmployeeAssignmentViewDialogComponent>) { }

  ngOnInit() {
  }

}
