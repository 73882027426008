import { AbstractControl } from '@angular/forms';

export function thresholdValidator(lowerThresholdControlName: string, upperThresholdControlName: string) {
  return (control: AbstractControl) => {
    if (control) {
      return control.get(lowerThresholdControlName).value > control.get(upperThresholdControlName).value ?
       null : {warningLessThanError: true}
    }

    return null;
  }
}
