import { Component, OnInit } from '@angular/core';
import { MaintenanceType } from '../shared/contract/maintenance-type.interface';
import { Observable } from 'rxjs';
import { MaintenanceTypeStore } from './service/maintenance-type.store';
import { DeleteMaintenanceTypeCommand } from '../shared/contract/commands/delete-maintenance-type.command';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'bh-maintenance-type-list',
  templateUrl: './maintenance-type-list.component.html',
  styleUrls: ['./maintenance-type-list.component.scss']
})
export class MaintenanceTypeListComponent implements OnInit {

  maintenanceTypes: Observable<MaintenanceType[]>;
  filterControl: UntypedFormControl;

  constructor(private maintenanceTypeStore: MaintenanceTypeStore, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.maintenanceTypes = this.maintenanceTypeStore.filteredMaintenanceTypes;
    this.maintenanceTypeStore.loadMaintenanceTypes();
    this.filterEvent();
  }

  public editType(id: string): void {
    this.router.navigate(['../edit/' + id], { relativeTo: this.route});
  }

  public deleteType(type: MaintenanceType): void {
    this.maintenanceTypeStore.deleteType(new DeleteMaintenanceTypeCommand(type.id));
  }

  private filterEvent(): void {
    this.filterControl = new UntypedFormControl(this.maintenanceTypeStore.searchTerms);
    this.filterControl.valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.maintenanceTypeStore.filterTypesBy(filterTerm));
  }

  canDeleteType(taskId: string): boolean {
    return this.maintenanceTypeStore.canDelete(taskId);
  }
}
