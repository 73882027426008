import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Authorities, validateCombinations } from '../enums/authorities.enum';

export function validAuthCombinationsValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const selection: Authorities[] = control.value;
    const invalidCombinations = validateCombinations(selection);

    return invalidCombinations.length === 0 ? null : { invalidCombinations: invalidCombinations };
  };
}
