<div class="info-window__header">{{value?.item?.equipmentName}}</div>

<div>
  <p>{{'general.address.address' | translate}}:
    <ng-container *ngIf="!(loading | async); else spinner">
      <span>{{(address | async | address) || '-'}}</span>
    </ng-container>
 </p>
</div>

<ng-container *ngFor="let line of infoDates">
  <p *ngIf="line?.date">
    {{ line.title | titlecase}}: {{line.date | date:'EEEE, d. MMM y HH:mm':'':currentLocale}}
  </p>
</ng-container>

<ng-template #spinner>
  <mat-spinner
    [strokeWidth]="2"
    [diameter]="14"
    style="display: inline-block;">
  </mat-spinner>
</ng-template>
