import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(nameObject: any, args?: any): string {
    return nameObject ? nameObject.firstName + ' ' + getNameOrLastName(nameObject) : '';
  }
}

function getNameOrLastName(object: any): string {
  return object.lastName ? object.lastName : object.name;
}
