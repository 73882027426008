import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThumbnailSize } from '../../enums/thumbnail-size.enum';
import { FileUtils } from '../../../../../fileUtils';

@Component({
  selector: 'bh-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailComponent {
  private _key: string;
  @Input() size: ThumbnailSize = ThumbnailSize.S;
  @Input() altText = '';

  @Input()
  set key(key: string) {
    if (key && FileUtils.globalDirectories.some(dir => key.startsWith(dir))) {
      this._key = `global/${key}`;
    } else {
      this._key = key;
    }
  }

  get key(): string {
    return this._key;
  }
}
