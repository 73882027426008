<div class="dialog-wrapper add-edit-additional-field">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>
    <mat-icon svgIcon="api-keys"></mat-icon>
    <span>{{ 'modules.apiKeys.createApiKey' | translate }}</span>
  </h1>

  <div mat-dialog-content>
    <div *ngIf="!connector" fxLayout="column" fxLayoutAlign="center center">
      <mat-spinner diameter="64"></mat-spinner>
    </div>

    <div *ngIf="connector"
         class="api-keys-data"
         fxLayout="column" fxLayoutAlign="space-between stretch">
      <div fxLayout="row">
        <h4>{{ 'modules.apiKeys.copyApiKeyAlarm' | translate }}</h4>
      </div>

      <div fxLayout="row">
        <div fxFlex="20">{{ 'modules.apiKeys.apiKey' | translate }}:</div>
        <div fxFlex>
          <bh-secret-text [text]="connector.clientId"
                          copyButtonTitle="{{ 'general.buttons.copy' | translate }}"
                          copiedButtonTitle="{{ 'general.buttons.copied' | translate }}">
          </bh-secret-text>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="20">{{ 'modules.apiKeys.apiSecret' | translate }}:</div>
        <div fxFlex>
          <bh-secret-text [text]="connector.secret"
                          copyButtonTitle="{{ 'general.buttons.copy' | translate }}"
                          copiedButtonTitle="{{ 'general.buttons.copied' | translate }}">
          </bh-secret-text>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="20">{{ 'modules.apiKeys.authUrl' | translate }}:</div>
        <div fxFlex>
          <bh-secret-text [text]="connector.accessTokenUrl"
                          hideCopyButton="true"
                          alwaysVisible="true">
          </bh-secret-text>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button mat-dialog-close>{{ 'general.buttons.close' | translate }}</button>
  </div>
</div>
