import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewEquipmentEmployeeAssignment } from '../contract/view-equipment-employee-assignment.interface';
import { DeleteEquipmentEmployeeAddedLifecycleCommand } from '../contract/delete-equipment-employee-added-lifecycle.command';
import { DeleteEquipmentEmployeeRemovedLifecycleCommand } from '../contract/delete-equipment-employee-removed-lifecycle.command';
import { RemoveEquipmentEmployeeAssignmentCommand } from '../contract/remove-equipment-employee-assignment-command';
import { ReplaceEquipmentEmployeeAssignmentCommand } from '../contract/replace-equipment-employee-assignment-command';
import { UpdateEquipmentEmployeeAssignmentCommand } from '../contract/update-equipment-employee-assignment-command';
import { getHttpParameters } from '../../../shared/utils';
import { EquipmentEmployeeRole } from '../contract/equipment-employee-role.enum';
import { LifeCycleEmployeeAdded } from '../contract/life-cycle-employee-added';
import { LifeCycleEmployeeRemoved } from '../contract/life-cycle-employee-removed';
import { DeleteEquipmentEmployeeReplacedLifecycleCommand } from '../contract/delete-equipment-employee-replaced-lifecycle.command';
import { AddEquipmentEmployeeAssignmentCommand } from '../contract/add-equipment-employee-assignment-command';
import { ViewAssignableEmployee } from '../../disposition/contract/view-assignable-employee';

@Injectable()
export class EquipmentEmployeeService {

  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/equipments';

  constructor(private http: HttpClient) {
  }

  public getEmployees(searchTerm?: string): Observable<ViewAssignableEmployee[]> {
    let params = {
      searchTerm: searchTerm
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<ViewAssignableEmployee[]>(this.url + '/assignable-employees', {params: httpParams});
  }

  public getLatestLifeCycle(equipmentId: string,
     employeeRole: EquipmentEmployeeRole,
     excludeAssignmentId?: string): Observable<LifeCycleEmployeeAdded | LifeCycleEmployeeRemoved> {
    let params = new HttpParams();
    params = params.set('employeeRole', employeeRole.toString());
    if (excludeAssignmentId) {
      params = params.set('excludeAssignmentId', excludeAssignmentId);
    }
    return this.http.get<LifeCycleEmployeeAdded | LifeCycleEmployeeRemoved>(this.url +
       '/' + equipmentId + '/latest-employee-event', {params: params});
  }

  public getEmployeeAssignments(equipmentId: string): Observable<ViewEquipmentEmployeeAssignment[]> {
    return this.http.get<ViewEquipmentEmployeeAssignment[]>(this.url + '/' + equipmentId + '/employee-assignments');
  }

  public addEquipmentEmployeeAssignment(command: AddEquipmentEmployeeAssignmentCommand): Observable<string> {
    return this.http.post(this.url + '/add-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public replaceEquipmentEmployeeAssignment(command: ReplaceEquipmentEmployeeAssignmentCommand): Observable<string> {
    return this.http.post(this.url + '/replace-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public updateEquipmentEmployeeAssignment(command: UpdateEquipmentEmployeeAssignmentCommand): Observable<string> {
    return this.http.post(this.url + '/update-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public removeEmployeeAssignment(command: RemoveEquipmentEmployeeAssignmentCommand): Observable<string> {
    return this.http.post(this.url + '/remove-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public replaceEmployeeAssignment(command: ReplaceEquipmentEmployeeAssignmentCommand): Observable<string> {
    return this.http.post(this.url + '/replace-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public updateEmployeeAssignment(command: UpdateEquipmentEmployeeAssignmentCommand): Observable<string> {
    return this.http.post(this.url + '/update-employee-assignment', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteEquipmentEmployeeAddedLifecycle(command: DeleteEquipmentEmployeeAddedLifecycleCommand): Observable<string> {
    return this.http.post(this.url + '/delete-employee-added-lifecycle', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteEquipmentEmployeeReplacedLifecycle(command: DeleteEquipmentEmployeeReplacedLifecycleCommand): Observable<string> {
    return this.http.post(this.url + '/delete-employee-replaced-lifecycle', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteEquipmentEmployeeRemovedLifecycle(command: DeleteEquipmentEmployeeRemovedLifecycleCommand): Observable<string> {
    return this.http.post(this.url + '/delete-employee-removed-lifecycle', JSON.stringify(command), {responseType: 'text'});
  }

}
