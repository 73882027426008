import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EquipmentTelematicsDataseriesItem } from 'app/modules/equipment/contract/equipment-telematics-dataseries-item';
import { SingleSeriesLineTransformStrategy } from 'app/shared/pipes/chart/strategies/single-series-line-transform-strategy.class';
import { TransformStrategyDatasetOptions } from 'app/shared/pipes/chart/strategies/transform-strategy-options.interface';
import { CustomThemeService } from 'app/shared/services/custom-theme.service';
import { LanguageService } from 'app/shared/services/language.service';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'bh-equipment-telematics-tank-level-history',
  templateUrl: './equipment-telematics-tank-level-history.component.html',
  styleUrls: ['../equipment-chart.style.scss', './equipment-telematics-tank-level-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentTelematicsTankLevelHistoryComponent implements OnInit {
  @Input() set value (value: EquipmentTelematicsDataseriesItem) {
    this.data = value;
  }
  @Input() lastUpdate: string | Date;
  @Input() subtitle = '';

  public data: EquipmentTelematicsDataseriesItem;
  public type: ChartType = 'line';
  public strategy: SingleSeriesLineTransformStrategy;
  public readonly options: ChartOptions = {
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.2
      }
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: this.translate('general.units.volume.l.pl'),
          align: 'center'
        },
        suggestedMin: 0,
      },
    },
    plugins: {
      tooltip: {
        intersect: false
      },
      datalabels: <any>false,
    }
  };

  constructor(
    private customThemeService: CustomThemeService,
    private languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.strategy = new SingleSeriesLineTransformStrategy(this.getStrategyOptions());
  }

  private getStrategyOptions(): TransformStrategyDatasetOptions {
    const themeSettings = this.customThemeService.getThemeSettings();
    return { color: themeSettings.chartPrimaryColor };
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
