import { RentalContractType } from './rental-contract-type.enum';
import {AttachedDocument} from '../../../shared/contract/attached-document.interface';

export class UpdateRentalContractCommand {
  equipmentId: string;
  rentalContractId: string;
  rentalContractNumber: string;
  importContractIdentifier: string;
  customerNumber: string;
  contractPartner: string;
  amount: number;
  rentalMileageLimit: number;
  rentalMileageLimitPeriod: string; // Period
  composedOf: string;
  rentalStartDate: string;
  rentalEndDate: string;
  rentalContractType: RentalContractType;
  comment: string;
  documents: AttachedDocument[];
  imported: boolean;
}
