import { Pipe, PipeTransform } from '@angular/core';
import { Color } from 'app/shared/components/color-indicator/color.enum';
import { ContactStatus } from '../../shared/enums/contact-status.enum';

@Pipe({
  name: 'contactStatusColor'
})
export class ContactStatusColorPipe implements PipeTransform {
  private readonly colorMap = {
    [ContactStatus.ACTIVE]: Color.GREEN,
    [ContactStatus.INACTIVE]: Color.GRAY
  }

  transform(status: ContactStatus): Color {
    return this.colorMap[status];
  }

}
