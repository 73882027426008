import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutonumberSchemaValidators } from 'app/shared/custom-validators/autonumber-schema.validator';
import { FieldTouchedErrorMatcher } from 'app/shared/custom-validators/field-touched-error-matcher';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { SetCustomerAutoIncrementCommand } from '../../contract/set-customer-autonumber-schema-command';
import { CustomersService } from '../customers.service';

const PROJECT_NUMBER_LENGTH = 6;
const MAX_SCHEMA_LENGTH = 30;
const PROJECT_NUMBER_REPLACEMENT_SCHEMA = '{autoNumber}';
const PROJECT_NUMBER_REPLACEMENT_SCHEMA_REGEXP = /{autoNumber}/g;

@UntilDestroy()
@Component({
  selector: 'bh-autonumber-edit-dialog',
  templateUrl: './autonumber-edit-dialog.component.html',
  styleUrls: ['./autonumber-edit-dialog.component.scss']
})
export class AutonumberEditDialogComponent implements OnInit {
  public customerId: string;
  public autoNumberControl: UntypedFormControl = new UntypedFormControl(null);
  public errorMatcher: ErrorStateMatcher = new FieldTouchedErrorMatcher();
  public fieldLimit = FieldLimit;

  constructor(public dialogRef: MatDialogRef<AutonumberEditDialogComponent>,
              private customerService: CustomersService) {
  }

  ngOnInit() {
    this.setAutoNumberControlValidators();
    this.getCustomerAutoIncrementSchema();
  }

  private setAutoNumberControlValidators(): void {
    this.autoNumberControl.setValidators([
      AutonumberSchemaValidators.schemaMaxSymbolsValidator(this.sanitizedSchemaHasValidLength.bind(this)),
      AutonumberSchemaValidators.schemaTemplateValidator(this.schemaHasNoInvalidCharacters.bind(this))
    ]);
  }

  private getCustomerAutoIncrementSchema(): void {
    this.customerService.getCustomerAutoIncrementSchema(this.customerId)
      .pipe(untilDestroyed(this))
      .subscribe((autoNumberSchema: string) => {
        this.autoNumberControl.setValue(autoNumberSchema)},
        (error: HttpErrorResponse) => {
          console.log('edit customer error: ', error);
        }
      );
  }

  public save(): void {
    if (this.autoNumberControl.valid && this.isValid(this.autoNumberControl.value)) {
      this.setAutoNumberSchema();
      this.dialogRef.close();
    }
  }

  private setAutoNumberSchema(): void {
    let cmd = new SetCustomerAutoIncrementCommand();
    cmd.customerId = this.customerId;
    cmd.projectNumberSchema = this.autoNumberControl.value;
    this.customerService.updateCustomerAutoIncrementSchema(cmd)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => console.log(result),
        (error: HttpErrorResponse) => console.log('edit customer error: ', error)
      );
  }

  public insertAutonumberToEndOfLine(): void {
    const currentSchemaValue = this.autoNumberControl.value;
    if (this.isValid(currentSchemaValue)) {
      currentSchemaValue
        ? this.autoNumberControl.setValue(currentSchemaValue + PROJECT_NUMBER_REPLACEMENT_SCHEMA)
        : this.autoNumberControl.setValue(PROJECT_NUMBER_REPLACEMENT_SCHEMA);
      this.autoNumberControl.markAsTouched();
    }
  }

  public isValid(value: string): boolean {
    return !value ||
      (this.sanitizedSchemaHasValidLength(value) && this.schemaHasNoInvalidCharacters(value));
  }

  private sanitizedSchemaHasValidLength(value: string): boolean {
    return this.countProjectNumberReplacementSchemaLengths(value)
      + this.countLengthWithoutProjectNumberReplacement(value) <= MAX_SCHEMA_LENGTH;
  }

  private countProjectNumberReplacementSchemaLengths(value: string): number {
    return (value?.split(PROJECT_NUMBER_REPLACEMENT_SCHEMA).length - 1) * PROJECT_NUMBER_LENGTH;
  }

  private countLengthWithoutProjectNumberReplacement(value: string): number {
    return value?.replace(PROJECT_NUMBER_REPLACEMENT_SCHEMA_REGEXP, '').length;
  }

  private schemaHasNoInvalidCharacters(value: string): boolean {
    return !value?.replace(PROJECT_NUMBER_REPLACEMENT_SCHEMA_REGEXP, '').includes('{')
      && !value?.replace(PROJECT_NUMBER_REPLACEMENT_SCHEMA_REGEXP, '').includes('}');
  }
}
