import { WeekDayConfiguration } from '../../../shared/contract/week-day-configuration.type';
import { EquipmentAlarmRuleSelectionType } from './equipment-alarm-rule-selection-type.enum';
import { AlarmLevel } from '../shared/enums/alarm-level.enum';
import { GeofenceAlarmTrigger } from '../shared/enums/geofence-alarm-trigger.enum';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { TelematicsUnitType } from '../../equipment/contract/telematics-unit-type.enum';

export class UpdateAlarmRuleCommand {
  alarmType: AlarmType;
  limit: number;
  limitDurationUnit?: string;
  timeframeInDays: number;
  globalGeofenceId: string;
  geofenceAlarmTrigger: GeofenceAlarmTrigger;
  alarmLevel: AlarmLevel;
  alarmMessage: string;
  alarmRuleId: string;
  emailRecipients: string[];
  userRolesEmailRecipients?: string[];
  batteryStatus?: string;
  selectionType: EquipmentAlarmRuleSelectionType;
  labels?: string[];
  equipmentTypeIds?: string[];
  timefenceConfiguration?: {
    operatingHoursLimit?: number;
    distanceLimit?: number;
    weekDayConfiguration: WeekDayConfiguration;
  };
  telematicsUnitTypes: TelematicsUnitType[];

  public toEquipmentCommand(): UpdateEquipmentAlarmRuleCommand {
    const { alarmType, ...rest } = this;
    return {
      ...rest
    }
  }

  public toTransportCommand(): UpdateTransportAlarmRuleCommand {
    const { alarmRuleId, alarmLevel, alarmMessage, userRolesEmailRecipients, emailRecipients } = this;
    return {
      alarmRuleId,
      alarmLevel,
      alarmMessage,
      userRolesEmailRecipients,
      emailRecipients
    };
  }
}

export class UpdateEquipmentAlarmRuleCommand {
  limit: number;
  limitDurationUnit?: string;
  timeframeInDays: number;
  globalGeofenceId: string;
  geofenceAlarmTrigger: GeofenceAlarmTrigger;
  alarmLevel: AlarmLevel;
  alarmMessage: string;
  alarmRuleId: string;
  emailRecipients: string[];
  userRolesEmailRecipients?: string[];
  selectionType: EquipmentAlarmRuleSelectionType;
  labels?: string[];
  equipmentTypeIds?: string[];
  timefenceConfiguration?: {
    operatingHoursLimit?: number;
    distanceLimit?: number;
    weekDayConfiguration: WeekDayConfiguration;
  };
  telematicsUnitTypes: TelematicsUnitType[];
}
export class UpdateTransportAlarmRuleCommand {
  alarmRuleId: string;
  alarmLevel: AlarmLevel;
  alarmMessage: string;
  userRolesEmailRecipients: string[];
  emailRecipients: string[];
}
