import { Utils } from 'app/shared/utils';
import { Pipe, PipeTransform } from '@angular/core';
import { TransportViewAssignment } from '../../contracts/transport/transport-view-assignment.interface';

@Pipe({
  name: 'transportViewAssignmentTitle'
})
export class TransportViewAssignmentTitlePipe implements PipeTransform {
  public transform(assignment: TransportViewAssignment): string {
    if (!assignment) {
      return null;
    }
    const displayedName = (assignment.equipmentName?.trim()
      || assignment.equipmentModel?.trim()
      || assignment.equipmentDisplayName?.trim());
    return Utils.concatTitleText([
      displayedName,
      assignment.equipmentCustomerSerialNumber,
      assignment.equipmentLicensePlate
    ], ', ');
  }
}
