import { Injectable } from '@angular/core';
import { UpdateFilterCommand } from '../../../contract/filter/update-filter-command.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FilterType, RenderStrategy } from '../../../contract/filter/filter-type';
import { FilterItem, FilterItemType, toFilterItemsArray } from './search-filter.converter';
import { FilterValue } from '../../../contract/filter/filter-value';
import { filterItemsByTerm } from './search-filter.filter';
import { SearchFilterSortingPipe } from '../../../pipes/search-filter-sorting.pipe';

@Injectable({providedIn: 'root'})
export class SearchFilterDatasource {

  /* Original filter data */
  private _filters: BehaviorSubject<FilterType[]>;
  public readonly filtersChange: Observable<FilterType[]>;
  /* Raw filter data for virtual scrolling */
  private _filtersRaw: BehaviorSubject<FilterItem[]>;
  public readonly filtersRawChange: Observable<FilterItem[]>;
  /* Modify filter commands */
  public updateFilters: (commands: UpdateFilterCommand[]) => void;
  /* Filter term */
  private _filterTerm: BehaviorSubject<string>;
  private readonly filterTermChange: Observable<string>;

  public constructor(private searchFilterSortingPipe: SearchFilterSortingPipe) {

    this._filters = new BehaviorSubject<FilterType[]>([]);
    this._filtersRaw = new BehaviorSubject<FilterItem[]>([]);
    this._filterTerm = new BehaviorSubject<string>('');

    this.filtersChange = this._filters.asObservable();
    this.filtersRawChange = this._filtersRaw.asObservable();
    this.filterTermChange = this._filterTerm.asObservable();

    combineLatest([this.filtersChange, this.filterTermChange]).subscribe(([filter, term]) => {
      filter = filterItemsByTerm(filter, term);
      filter = searchFilterSortingPipe.transform(filter);
      this._filtersRaw.next(toFilterItemsArray(filter));
    })
  }

  public getFilter(): FilterType[] {
    return this._filters.getValue();
  }

  public pushFilters(filters: FilterType[]) {
    this._filters.next(filters);
  }

  public clearFilter() {
    this._filters.next([]);
    this._filterTerm.next('')
  }

  public resetFilter(): Promise<boolean> {
    return new Promise(resolve => {
      let commands: UpdateFilterCommand[] = [];
      let filters = this._filters.getValue();

      filters.forEach(type => {
        type.groups.forEach(group => {
          group.filters.forEach(filterItem => {
            if (filterItem.value === true) {
              filterItem.value = false;

              commands.push({
                filterType: type.typeName,
                storeName: filterItem.storeName,
                newValue: false
              })
            }
          });
        });
      });

      this.changeFilter(commands);
      this._filterTerm.next('')
      resolve(true);
    });
  }

  public setSearchTerm(term: string) {
    this._filterTerm.next(term);
  }

  public changeFilterValue(newValue: boolean, index: number) {
    const filtersRaw = this._filtersRaw.getValue();
    const commands = this.updateTreeValues(filtersRaw[index], newValue, index);

    if (this.updateFilters) {
      this.updateFilters(commands);
    }
  }

  public changeFilter(commands: UpdateFilterCommand[]) {
    let fullCommands = [];
    const filtersRaw = this._filtersRaw.getValue();

    (commands || []).forEach(command => {
      const index = filtersRaw.findIndex(filter => filter.type === FilterItemType.FILTER
        && filter.filterTypeName === command.filterType
        && (filter.item as FilterValue).storeName === command.storeName);

      if (index !== -1) {
        fullCommands = [...fullCommands, ...this.updateTreeValues(filtersRaw[index], command.newValue, index)];
      } else {
        fullCommands.push(command);
      }
    });

    if (this.updateFilters) {
      this.updateFilters(fullCommands);
    }
  }

  private toUpdatesCommand(item: FilterItem): UpdateFilterCommand {
    return {
      filterType: item.filterTypeName,
      storeName: (item.item as FilterValue).storeName,
      newValue: (item.item as FilterValue).value,
      filterGroupType: item.filterGroupType
    }
  }

  private updateTreeValues(item: FilterItem, newValue: boolean, index: number): UpdateFilterCommand[] {
    (item.item as FilterValue).value = newValue;
    let commands: UpdateFilterCommand[] = [this.toUpdatesCommand(item)];

    // Update child nodes for tree items
    if (item.renderStrategy === RenderStrategy.GROUPED_TREE
      || item.renderStrategy === RenderStrategy.RELATIONS_TREE) {
      commands = [...commands, ...this.updateChildTreeValues(newValue, index)];
    }

    // Update parent nodes
    if (item.renderStrategy === RenderStrategy.GROUPED_TREE) {
      commands = [...commands, ...this.updateParentTreeValuesFor(index)];
    }

    return commands;
  }

  private updateChildTreeValues(newValue: boolean, index: number): UpdateFilterCommand[] {
    const filtersRaw = this._filtersRaw.getValue();

    const commands: UpdateFilterCommand[] = [];
    const filterItem: FilterItem = filtersRaw[index];

    // Update child selections
    for (let i = index + 1; i < filtersRaw.length; i++) {
      let childFilterItem: FilterItem = filtersRaw[i];
      if (filterItem.offset >= childFilterItem.offset) {
        break;
      }

      if ((childFilterItem.item as FilterValue).value !== newValue) {
        (childFilterItem.item as FilterValue).value = newValue;
        commands.push(this.toUpdatesCommand(childFilterItem));
      }
    }

    return commands;
  }

  private updateParentTreeValuesFor(index: number): UpdateFilterCommand[] {
    const filtersRaw = this._filtersRaw.getValue();
    const filterItem: FilterItem = filtersRaw[index];

    // Update parent nodes
    if (filterItem.offset > 0) {
      const isAllNeighboringItemsSelected = this.isAllNeighboringItemsSelected(index);
      const parentIndex = this.getParentItemIndex(index);
      const parentFilterItem: FilterItem = filtersRaw[parentIndex];

      if ((parentFilterItem.item as FilterValue).value !== isAllNeighboringItemsSelected) {
        (parentFilterItem.item as FilterValue).value = isAllNeighboringItemsSelected;
        return [this.toUpdatesCommand(parentFilterItem), ...this.updateParentTreeValuesFor(parentIndex)];
      }
    }

    return [];
  }

  private isAllNeighboringItemsSelected(index): boolean {
    const filtersRaw = this._filtersRaw.getValue();

    let clickedFilterItem: FilterItem = filtersRaw[index];
    let allSelected = (clickedFilterItem.item as FilterValue).value === true;

    for (let i = index - 1; i >= 0; i--) {
      let filterItem: FilterItem = filtersRaw[i];
      if (clickedFilterItem.offset === filterItem.offset) {
        allSelected = allSelected && (filterItem.item as FilterValue).value === true;
      } else if (clickedFilterItem.offset > filterItem.offset) {
        break;
      }
    }

    for (let i = index + 1; i < filtersRaw.length; i++) {
      let filterItem: FilterItem = filtersRaw[i];
      if (clickedFilterItem.offset === filterItem.offset) {
        allSelected = allSelected && (filterItem.item as FilterValue).value === true;
      } else if (clickedFilterItem.offset > filterItem.offset) {
        break;
      }
    }

    return allSelected;
  }

  private getParentItemIndex(index: number): number {
    const filtersRaw = this._filtersRaw.getValue();
    const filterItem: FilterItem = filtersRaw[index];

    if (filterItem.offset > 0) {
      for (let i = index - 1; i >= 0; i--) {
        let previousFilterItem: FilterItem = filtersRaw[i];
        if (previousFilterItem.offset < filterItem.offset) {
          return i;
        }
      }
    }

    return -1;
  }
}
