<div class="edit-equipment-assignment">

  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title>{{'modules.equipment.assignmentEdit.editDisposition'|translate}}</h1>
  <div mat-dialog-content>
    <bh-date-range-select
        flexOrientation="row"
        [startDateControl]="startDateControl"
        [endDateControl]="endDateControl"
        [minStartDate]="evaluateMinStartDate(assignment, project)"
        [maxStartDate]="evaluateMaxStartDate(assignment, project)"
        [minEndDate]="evaluateMinEndDate(assignment)"
        [maxEndDate]="evaluateMaxEndDate(assignment, project)"
        [endRequired]= false
        (onDateChange)="updateConflicts($event, assignment)">
    </bh-date-range-select>

    <form [formGroup]="assignmentEditForm" novalidate>
      <div class="description">
        <label for="description">{{'general.description' | translate}}</label>
        <textarea id='description' formControlName="description" [maxlength]="fieldLimit.LONG_TEXT"></textarea>
      </div>
    </form>

    <h4 *ngIf="conflicts.length">
      {{'general.labels.conflictsFound'|translate}}:
    </h4>

    <div class="conflict" *ngFor="let conflict of conflicts"
         fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon [matTooltip]="'general.conflict.s'|translate">error_outline</mat-icon>
      <div class="project-description">
        <div>{{printProject(conflict)}}</div>
        <div *ngIf="conflict.projectAddress">{{printAddress(conflict.projectAddress)}}</div>
        <div class="conflict-range">{{printConflictTimeRange(conflict)}}</div>
      </div>
    </div>
  </div>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="accent" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()">
      {{'general.buttons.save'|translate}}
    </button>
  </mat-dialog-actions>
</div>
