import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { MaintenanceRuleService } from './maintenance-rule-service';
import { DataSource } from '@angular/cdk/table';
import { MaintenanceRule } from '../contract/maintenance-rule';
import { CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { createIntervalsText } from './create-intervals-text';
import { delay, tap } from 'rxjs/operators';
import { PagedResponse } from '../../../../../shared/contract/page-response.interface';
import { DeleteMaintenanceRuleCommand } from '../contract/commands/delete-maintenance-rule.command';
import { MaintenanceFilterCollectionView, EMPTY_MAINTENANCE_VIEW_FILTER } from '../../../shared/contract/maintenance-filter-collection-view.interface';
import { MaintenanceConfigColumnService } from 'app/modules/maintenance/shared/maintenance-config-column.service';

@Injectable()
export class MaintenanceRuleStore implements DataSource<MaintenanceRule> {

  private maintenanceRulesFilterSub: BehaviorSubject<MaintenanceFilterCollectionView> = new BehaviorSubject(EMPTY_MAINTENANCE_VIEW_FILTER);
  private maintenanceRules: BehaviorSubject<MaintenanceRule[]> = new BehaviorSubject([]);
  private lengthSub: BehaviorSubject<number> = new BehaviorSubject(0);
  private indexSub: BehaviorSubject<number> = new BehaviorSubject(0);
  private sizeSub: BehaviorSubject<number> = new BehaviorSubject(25);
  private ruleDeleteSub: Subject<void> = new Subject();
  private editableRules: Map<string, boolean> = new Map();
  private assignedEquipments: Map<string, string[]> = new Map();

  public readonly ruleIntervals: Map<string, string> = new Map();
  public readonly maintenanceRulesFilter: Observable<MaintenanceFilterCollectionView> = this.maintenanceRulesFilterSub.asObservable();
  public readonly length: Observable<number> = this.lengthSub.asObservable();
  public readonly index: Observable<number> = this.indexSub.asObservable();
  public readonly size: Observable<number> = this.sizeSub.asObservable();
  public readonly ruleDelete: Observable<void> = this.ruleDeleteSub.asObservable();

  private _maintenanceTypeIds: string[] = null;
  private _articleGroups: string[] = null;

  constructor(
    private maintenanceRuleService: MaintenanceRuleService,
    private maintenanceConfigColumnService: MaintenanceConfigColumnService,
  ) {
    this.maintenanceConfigColumnService.pageSize.subscribe((pageSize: number) => {
      this.sizeSub.next(pageSize);
    });
    this.maintenanceRules
    .pipe(
        tap(rules => rules.forEach(rule =>
            this.ruleIntervals.set(rule.maintenanceRuleId, createIntervalsText(rule)))),
        tap(rules =>
          rules.map(rule =>
            this.maintenanceRuleService.getAssignedEquipments(rule.maintenanceRuleId).subscribe(equipments =>
               this.assignedEquipments.set(rule.maintenanceRuleId, equipments)))))
    .subscribe();
  }

  connect(collectionViewer: CollectionViewer): Observable<MaintenanceRule[] | ReadonlyArray<MaintenanceRule>> {
    return this.maintenanceRules.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  loadRules(pageIndex: number, pageSize: number, term: string, maintenanceTypeIds: string[], articleGroups: string[]): void {
    if (pageSize !== this.sizeSub.getValue()) {
      this.maintenanceConfigColumnService.selectColumns([], pageSize);
    }
    this._maintenanceTypeIds = maintenanceTypeIds;
    this._articleGroups = articleGroups;

    this.maintenanceRuleService.loadRules(pageIndex, pageSize, term, maintenanceTypeIds, articleGroups)
    .pipe(
        tap((pagedRules: PagedResponse<MaintenanceRule>) => this.lengthSub.next(pagedRules.totalElements)),
        tap((pagedRules: PagedResponse<MaintenanceRule>) => this.indexSub.next(pagedRules.number)),
        tap((pagedRules: PagedResponse<MaintenanceRule>) => this.sizeSub.next(pagedRules.size)),
        tap((pagedRules: PagedResponse<MaintenanceRule>) => pagedRules.content.forEach(rule =>
            this.ruleIntervals.set(rule.maintenanceRuleId, createIntervalsText(rule)))),
        tap(pagedRules => this.maintenanceRules.next(pagedRules.content)))
    .subscribe();

    this.updateMaintenanceRuleFilter();
  }

  getRuleIntervals(ruleId: string): string {
    return this.ruleIntervals.get(ruleId);
  }

  deleteRule(ruleId: string): void {
    const command = new DeleteMaintenanceRuleCommand(ruleId);
    this.maintenanceRuleService.deleteRule(command)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.ruleDeleteSub.next());
  }

  canEdit(ruleId: string): boolean {
    return this.editableRules.get(ruleId);
  }

  assignedEquipmentsFor(maintenanceRuleId: string): string[] {
    return this.assignedEquipments.get(maintenanceRuleId) || [];
  }

  public updateMaintenanceRuleFilter(): void {
    this.maintenanceRuleService
      .getMaintenanceRuleFilter(this._maintenanceTypeIds, this._articleGroups)
      .subscribe((res: MaintenanceFilterCollectionView) => this.maintenanceRulesFilterSub.next(res));
  }
}
