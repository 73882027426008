<bh-step-dialog class="telematic-item-dialog" [ngClass]="{'dialog-telematicUnit' : telematicItem}"
                [title]="(telematicItem ? 'modules.telematic.telematicAddEdit.titleEdit' : 'modules.telematic.telematicAddEdit.titleAdd')|translate"
               [fontSet]="'icon-telematics_outline'">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
    <div *ngIf="!hideSuccessForm"
         class="telematic-item-add-stepper-content"
         fxLayout="column"
         fxLayoutAlign="center center"
         fxLayoutGap="15px">
      <fa-icon class="center-icon success-icon" [icon]="sucessIcon"></fa-icon>
      <h3>{{(telematicItem ?
        'modules.telematic.telematicAddEdit.successfulUpdated' :
        'modules.telematic.telematicAddEdit.successfulAdded')|translate}}</h3>
      <button mat-raised-button color="primary" (click)="returnToList()">
        {{'modules.telematic.telematicAddEdit.returnToList'|translate}}
      </button>
    </div>
    <div *ngIf="!hideErrorForm"
         class="telematic-item-add-stepper-content"
         fxLayout="column"
         fxLayoutAlign="center center"
         fxLayoutGap="15px">
      <fa-icon class="center-icon error-icon" [icon]="errorIcon"></fa-icon>
      <h3>{{errorMessage ? errorMessage : ('shared.requestInterceptorService.techError'|translate)}}</h3>
      <button mat-raised-button color="primary" (click)="returnToCreation()">
        {{'modules.telematic.telematicAddEdit.back'|translate}}
      </button>
    </div>
    <form *ngIf="hideSuccessForm && hideErrorForm" [formGroup]="telematicUnitForm" novalidate>
      <mat-horizontal-stepper labelPosition="bottom" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch"
                              linear #stepper>
        <mat-step *ngIf="!telematicItem" [stepControl]="telematicUnitForm.get('telematicItemType')">
          <ng-template matStepLabel>{{'modules.telematic.telematicAddEdit.unitType'|translate}}*</ng-template>
          <div class="telematic-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
              <bh-card-select class="type-select"
                              [options]="telematicUnitTypes"
                              [optionResolver]="telematicUnitAdministrationTypeResolver"
                              formControlName="telematicItemType"
                              ></bh-card-select>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
              <div class="button-wrapper" fxLayoutGap="15px">
                <button fxLayoutAlign="end center"
                        mat-raised-button
                        matStepperNext
                        [disabled]="telematicUnitForm.get('telematicItemType').invalid">
                  {{'general.buttons.continue'|translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step
          [stepControl]="telematicUnitForm.get('data')"
          [formGroup]="telematicUnitForm.get('data')"
        >
          <ng-template matStepLabel>{{'modules.telematic.telematicAddEdit.generalData'|translate}}*</ng-template>
          <div class="telematic-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxFlex="80" fxLayout="row" class="telematic-item-add-dialog-base-data"
                 fxLayoutAlign="space-around stretch">
              <div fxFlex="30" fxLayoutGap="15px">
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicList.serialNumber'|translate}}</mat-label>
                    <input matInput
                           formControlName="serialNumber"
                           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                  </mat-form-field>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicList.mac'|translate}}</mat-label>
                    <input matInput
                           formControlName="mac"
                           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex="30" fxLayoutGap="15px">
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicList.boxNumber'|translate}}</mat-label>
                    <input matInput
                           formControlName="boxNumber"
                           [maxlength]="fieldLimit.NAME">
                  </mat-form-field>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicList.imei'|translate}}</mat-label>
                    <input matInput required
                           formControlName="imei"
                           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                    <mat-error *ngIf="telematicUnitForm.get('data').get('imei').hasError('required')">
                      {{'shared.validation.requiredField'|translate}}
                    </mat-error>
                    <mat-error *ngIf="telematicUnitForm.get('data').get('imei').hasError('pattern')">
                      {{('shared.validation.string.hexDecimal'|translate: {value: '0-9'})}}
                    </mat-error>
                    <mat-error *ngIf="telematicUnitForm.get('data').get('imei').hasError('maxlength')">
                      {{'shared.validation.string.maxLength'|translate: {value: 15} }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <button *ngIf="!telematicItem" class="bottom-button" mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <button class="bottom-button" mat-raised-button matStepperNext type="button"
                      [disabled]="telematicUnitForm.get('data').invalid">
                {{'general.buttons.continue'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step [formGroup]="telematicUnitForm.get('additionalData')">
          <ng-template matStepLabel>{{'modules.telematic.telematicAddEdit.additionalData'|translate}}*</ng-template>
          <div class="telematic-item-add-stepper-content"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
            <div fxFlex="80" fxLayout="row" class="telematic-item-add-dialog-base-data"
                 fxLayoutAlign="space-around stretch">
              <div fxFlex="30" fxLayoutGap="15px">
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicList.simCardProvider'|translate}}</mat-label>
                    <input matInput
                           formControlName="simCardProvider"
                           [matAutocomplete]="providers"
                           >
                    <mat-autocomplete #providers="matAutocomplete">
                      <mat-option *ngFor="let provider of simCardProvidersList | async"
                                  [value]="provider">
                        {{ provider }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicAddEdit.imsi'|translate}}</mat-label>
                    <input matInput
                           formControlName="eSimImsi"
                           [maxlength]="fieldLimit.NAME">
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex="30" fxLayoutGap="15px">
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicAddEdit.partner'|translate}}</mat-label>
                    <mat-select formControlName="partner" disableOptionCentering="true">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="partnersFilter"
                                               [placeholderLabel]="'general.labels.search'|translate:{ value: ('modules.telematic.telematicAddEdit.partner'|translate) }"
                                               [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('modules.telematic.telematicAddEdit.partner'|translate) }"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let partner of partnersList | async"
                                  [value]="partner.partnerId"
                                  [matTooltip]="partner.partnerName"
                                  matTooltipPosition="above">
                        {{ partner.partnerName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayoutAlign="row" class="field-wrapper">
                  <mat-form-field>
                    <mat-label>{{'modules.telematic.telematicAddEdit.iccId'|translate}}</mat-label>
                    <input matInput
                           formControlName="eSimIccId"
                           [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <button class="bottom-button" mat-raised-button matStepperPrevious type="button">
                {{'general.buttons.back'|translate}}
              </button>
              <div class="fill-row" fxLayoutAlign="start center"></div>
              <button class="bottom-button"
                      mat-raised-button
                      bhTimeoutSaveButton
                      (throttledClick)="save()"
                      [disabled]="!isValid()"
                      color="primary"
                      type="submit">
                {{'general.buttons.save'|translate}}
              </button>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>
