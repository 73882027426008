import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TelematicUnitFotaWebData} from '../shared/telematic-unit-fota-web-data';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import moment from 'moment';

interface FotaWebDataFormGroup {
  imei: FormControl<string>;
  iccid: FormControl<string>;
  unitType: FormControl<string>;
  serialNumber: FormControl<string>;
  currentConfig: FormControl<string>;
  currentFirmware: FormControl<string>;
  lastConnected: FormControl<string>;
}

@Component({
  selector: 'bh-telematic-unit-fota-web-data-dialog',
  templateUrl: './telematic-unit-fota-web-data-dialog.component.html',
  styleUrls: ['./telematic-unit-fota-web-data-dialog.component.scss']
})
export class TelematicUnitFotaWebDataDialogComponent implements OnInit {
  public fotaWebDataForm: FormGroup<FotaWebDataFormGroup>;
  public telematicUnitFotaWebData: TelematicUnitFotaWebData;

  constructor(private dialogRef: MatDialogRef<TelematicUnitFotaWebDataDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: {
                telematicUnitFotaWebData: TelematicUnitFotaWebData
              }) {
    this.telematicUnitFotaWebData = data.telematicUnitFotaWebData;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.fillFotaWebDataForm();
  }

  private buildForm(): void {
    this.fotaWebDataForm = new FormGroup<FotaWebDataFormGroup>({
      imei: new FormControl({value: '', disabled: true}),
      iccid: new FormControl({value: '', disabled: true}),
      unitType: new FormControl({value: '', disabled: true}),
      serialNumber: new FormControl({value: '', disabled: true}),
      currentConfig: new FormControl({value: '', disabled: true}),
      currentFirmware: new FormControl({value: '', disabled: true}),
      lastConnected: new FormControl({value: '', disabled: true}),
    });
  }

  private fillFotaWebDataForm(): void {
    this.fotaWebDataForm.patchValue({
      imei: this.telematicUnitFotaWebData.imei,
      iccid: this.telematicUnitFotaWebData.iccid,
      unitType: this.telematicUnitFotaWebData.unitType,
      serialNumber: this.telematicUnitFotaWebData.serialNumber.toString(),
      currentConfig: this.telematicUnitFotaWebData.currentConfig,
      currentFirmware: this.telematicUnitFotaWebData.currentFirmware,
      lastConnected: moment(this.telematicUnitFotaWebData.lastConnected).format('DD.MM.YYYY HH:mm:ss'),
    });
  }
}
