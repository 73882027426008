<div fxFill fxLayout="column" class="info-window-table-view">
  <h2>{{(items.length > 1 ? 'general.org.pl' : 'general.org.s')|translate}}</h2>
  <table fxFill fxLayout="column">
    <tr fxFill fxLayout="row">
      <th class="cell-text" fxFlex="1 1 0">{{'general.org.s'|translate}}</th>
      <th class="cell-text padded_column" fxFlex="1 1 0">{{'shared.basicMap.contact'|translate}}</th>
      <th class="padded_column" fxFlex="0 0 66px">{{'general.details'|translate}}</th>
    <tr *ngFor="let org of items" fxFill fxLayout="row">
      <td class="cell-text" fxFlex="1 1 0">{{org.organisationName}}</td>
      <td class="cell-text padded_column" fxFlex="1 1 0">{{org.organisationContact?.name}}</td>
      <td class="padded_column" fxFlex="0 0 66px">
        <button mat-icon-button class="mat-icon-button-details"
          (click)="navigateToViewDetails(org.organisationId)">
          <mat-icon class="bh-mat-icon">visibility</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>
