import { SchedulerLocaleLoaderService } from './shared/scheduler/scheduler-locale-loader.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './modules/navigation/sidenav/shared/sidenav.service';
import { RoleAuthorityGuardsComponent } from './shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from './core/keycloak';
import { PlatformLocation } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MessagingService } from './shared/messaging.service';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { ChatUrlParser } from './shared/services/chat-url-parser';
import { SwUpdate } from '@angular/service-worker';
import { PrivilegedRole } from './shared/enums/privileged-roles.enum';
import { RouterHistory } from './shared/router-history';
import {
  WindowEventEmitterEvent,
  WindowEventService
} from './shared/services/window-event-service';
import { CustomDeviceDetectorService } from './shared/services/custom-device-detector.service';
import { LanguageService } from './shared/services/language.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { CustomThemeService } from './shared/services/custom-theme.service';
import { LanguageSwitcherDialogService } from './modules/header/language-switcher/language-switcher-dialog.service';
import { environment } from 'environments/environment';
import { Languages } from './shared/enums/languages.enum';
import { UserConfigurationService } from './shared/services/user-configuration.service';

@UntilDestroy()
@Component({
  selector: 'bh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends RoleAuthorityGuardsComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'app';
  isMobile: boolean;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  private static isDispositionRoute(url: string): boolean {
    return url.includes('disposition');
  }

  constructor(public dateAdapter: DateAdapter<NativeDateAdapter>,
              public iconRegistry: MatIconRegistry,
              public sanitizer: DomSanitizer,
              private deviceService: CustomDeviceDetectorService,
              protected sidenavService: SidenavService,
              protected authService: KeycloakService,
              protected location: PlatformLocation,
              private messagingService: MessagingService,
              private router: Router,
              private chatUrlParser: ChatUrlParser,
              private swUpdate: SwUpdate,
              private routerHistory: RouterHistory,
              private windowEventService: WindowEventService,
              private languageService: LanguageService,
              private schedulerLocaleLoaderService: SchedulerLocaleLoaderService,
              private customThemeService: CustomThemeService,
              private languageSwitcherDialogService: LanguageSwitcherDialogService,
              private userConfigurationService: UserConfigurationService) {
    super(authService);

    routerHistory.init();
    customThemeService.applyUserTheme();

    this.isMobile = this.deviceService.isMobile();

    this.location.onPopState(() => {
      if (this.isMobile && this.sidenavService.opened) {
        this.sidenavService.close();
      }
    });

    iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('assets/fonts/onestoppro-controls.svg'));
    iconRegistry.addSvgIcon('scan-code-scan', sanitizer.bypassSecurityTrustResourceUrl('assets/fonts/scan-code-scan.svg'));
    iconRegistry.addSvgIcon('qr-code', sanitizer.bypassSecurityTrustResourceUrl('assets/fonts/qr-code.svg'));
    iconRegistry.addSvgIcon(
      'transport-start',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/transportation/transportation-start.svg'));
    iconRegistry.addSvgIcon(
      'transport-target',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/transportation/transportation-target.svg'));
    iconRegistry.addSvgIcon('engine', sanitizer.bypassSecurityTrustResourceUrl('assets/telematic-unit/engine.svg'));
    iconRegistry.addSvgIcon('api-keys', sanitizer.bypassSecurityTrustResourceUrl('assets/fonts/api-keys.svg'));

    this.router.events
    .pipe(
        filter(e => e instanceof NavigationStart),
        take(1))
    .subscribe((e: NavigationStart) => {
      if (this.chatUrlParser.isChatRoute(e.url)) {
        if (this.isMobile) {
          this.router.navigate(['mobile'].concat(this.chatUrlParser.parseChatRoute(e.url).split('/')), {skipLocationChange: true});
        }
      }

      if (AppComponent.isDispositionRoute(e.url) && this.isMobile) {
          this.router.navigate(['mobile/disposition/list']);
      }
    });

    this.languageService.setDefaultLanguage(environment.FALLBACK_LANGUAGE ?? Languages.EN);
    this.dateAdapter.setLocale(this.languageService.getCurrentLocale());
    this.languageService.onLanguageChange.pipe(
      untilDestroyed(this)
    ).subscribe(({ lang }: LangChangeEvent) => this.dateAdapter.setLocale(lang));

    this.languageSwitcherDialogService.checkUserLanguage();
  }

  ngOnInit(): void {
    if (this.isMobile) {
      // BEUT-1206: iOS bottom bar issue
      // Safari has a scroll gesture where the bottom bar disappears when you scroll. Because of that
      // safari calculates this lower bar into the view height, but sadly the lower content will be
      // hidden under the bottom bar leading to a scrollbar.
      // `innerHeight` does not have this bottom bar into this calculation. And to be able to scale
      // onto this height I apply this height to the body element. This also means, that we can not
      // use the `vh` oder `vw` anymore and we need to work with `%` values.
      this.windowEventService.getObservable().subscribe((windowEventEmitterEvent: WindowEventEmitterEvent) => {
        if (windowEventEmitterEvent.eventType === 'onresize') {
          document.body.style.height = window.innerHeight + 'px';
        }
      });
      window.onresize(null);
    }

    if (this.swUpdate.isEnabled) {
      if (this.swUpdate.isEnabled && environment.production) {
        this.swUpdate.versionUpdates.subscribe((evt) => {
          if (evt.type === 'VERSION_READY') {
            this.swUpdate.activateUpdate().then(() => {
              document.location.reload();
            });
          }
        });
      }
    }
    if (!this.authService.isInRole(PrivilegedRole.Superadmin)
        && !this.authService.isInRole(PrivilegedRole.Partneradmin)) {
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
    }

    this.schedulerLocaleLoaderService.setCurrentLocale();
    this.userConfigurationService.setupDTZ();
  }

  ngAfterViewInit(): void {
    if (!this.isMobile) {
      this.sidenavService.setSidenav(this.sidenav);
    }
  }

  ngOnDestroy(): void {
  }
}
