import {LocationType} from '../../../../../../shared/enums/location-type.enum';
import { TransferItemTransferDateRequest } from './transfer-item-transfer-date-request';

export class MinimalPossibleTransferDateRequest {

  constructor(
    public targetLocationId: string,
    public targetLocationType: LocationType,
    public transferItems: TransferItemTransferDateRequest[]) {
  }

}
