import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { OspDateTime } from '../../modules/equipment/contract/osp-date-time';
import { Utils } from '../utils';

@Injectable()
export class DatesService {
  static format = 'YYYY-MM-DD';
  static zonedDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  static utcFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
  private static halfHourMilliseconds = 30 * 60 * 1000;
  private static hourMilliseconds = 60 * 60 * 1000;
  private static dayMilliseconds = 24 * (60 * 60 * 1000);

  static date(date: string | Date, format?: string): string | null {
    if (date) {
      format = format ? format : this.format;
      return this.isValidDate(date) ? moment(date).format(format) : null;
    }
    return null;
  }

  static getLocalTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static ospDateTimeAtExactTime(date: string | Date): OspDateTime | null {
    const instant = this.isValidDate(date) ? moment(date).utc().format(this.utcFormat) : null;
    if (instant) {
      return {
        timestamp: instant,
        zoneId: this.getLocalTimeZone(),
      };
    }
    return null;
  }

  static ospDateTimeAtStartOfDay(date: string | Date): OspDateTime | null {
    const instant = this.isValidDate(date) ? moment(date).startOf('day').utc().format(this.utcFormat) : null;
    if (instant) {
      return {
        timestamp: instant,
        zoneId: this.getLocalTimeZone(),
      };
    }
    return null;
  }

  static ospDateTimeAtEndOfDay(date: string | Date): OspDateTime | null {
    const instant = (date && moment(date).isValid() && moment(date).isAfter('1900-01-01') && moment(date).isBefore('9999-12-31'))
      ? moment(date).endOf('day').utc().format(this.utcFormat) : null;
    if (instant) {
      return {
        timestamp: instant,
        zoneId: this.getLocalTimeZone(),
      };
    }
    return null;
  }

  static utcDate(date: string | Date): Date {
    if (date) {
      return moment.utc(date).subtract(moment(date).utcOffset(), 'minutes').toDate();
    }
    return null;
  }

  static sameTimeZoneAtStartDateUTC(date: string | Date): string | null {
    if (date) {
      const utcDate = moment.utc(moment(date).startOf('day'));
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneAtEndDateUTC(date: string | Date): string | null {
    if (date) {
      const utcDate = moment.utc(moment(date).endOf('day'));
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static atStartOfDayWithoutTimeZone(date: string | Date): string | null {
    if (!this.isValidDate(date)) {
      return null;
    }
    const timeZoneFreeDate = moment(date).utcOffset(0, true).startOf('day');
    return timeZoneFreeDate.format(this.zonedDateTimeFormat);
  }

  static atEndOfDayWithoutTimeZone(date: string | Date): string | null {
    if (!this.isValidDate(date)) {
      return null;
    }
    const timeZoneFreeDate = moment(date).utcOffset(0, true).endOf('day');
    return timeZoneFreeDate.format(this.zonedDateTimeFormat);
  }

  static sameTimeZoneAtStartMonthDateUTC(date: string | Date): string | null {
    if (date) {
      const utcDate = moment.utc(moment(date).startOf('month'));
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneAtEndMonthDateUTC(date: string | Date): string | null {
    if (date) {
      const utcDate = moment.utc(moment(date).endOf('month'));
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneAtStartHourDateUTC(date: string | Date): string | null {
    if (date) {
      const utcDate = moment.utc(moment(date).startOf('hour'));
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneAtEndHourDateUTC(date: string | Date): string | null {
    if (date) {
      const utcDate = moment.utc(moment(date).endOf('hour'));
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneAtStartHalfHourDateUTC(date: string | Date): string | null {
    if (date) {
      const currentDate = moment(date);
      const utcDate = moment.utc(Math.floor((+currentDate) / this.halfHourMilliseconds) * this.halfHourMilliseconds);
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneAtEndHalfHourDateUTC(date: string | Date): string | null {
    if (date) {
      const currentDate = moment(date);
      const utcDate = moment
        .utc(Math.ceil((+currentDate) / this.halfHourMilliseconds) * this.halfHourMilliseconds)
        .add(-1, 'm');
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static sameTimeZoneRoundDayDateUTC(date: string | Date): string | null {
    return this.sameTimeZoneRoundDateIntervalUTC(date, this.dayMilliseconds, false);
  }

  static sameTimeZoneRoundHourDateUTC(date: string | Date, isEndDate = false): string | null {
    return this.sameTimeZoneRoundDateIntervalUTC(date, this.hourMilliseconds, isEndDate);
  }

  static sameTimeZoneRoundHalfHourDateUTC(date: string | Date): string | null {
    return this.sameTimeZoneRoundDateIntervalUTC(date, this.halfHourMilliseconds, false);
  }

  private static sameTimeZoneRoundDateIntervalUTC(date: string | Date, intervalMs: number, isEndDate = false): string | null {
    if (date) {
      const currentDate = moment(date);
      const utcOffsetMs = currentDate.utcOffset() * 60000;
      const utcDate = moment
        .utc(Math.round((+currentDate + utcOffsetMs) / intervalMs) * intervalMs - utcOffsetMs)
        .add(isEndDate ? -1 : 0, 'm');
      return this.isValidDate(date) ? utcDate.format(this.zonedDateTimeFormat) : null;
    }
    return null;
  }

  static toIsoStartOfDay(date: Date): string {
    date.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
    return date.toISOString();
  }

  static fromIsoStartOfDay(date: string | Date): Date {
    let newDate = new Date(date);
    newDate.setFullYear(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setHours(0);
    return newDate;
  }

  static fromIsoEndOfDay(date: string | Date): Date {
    let newDate = new Date(date);
    newDate.setFullYear(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    newDate.setHours(23);
    return newDate;
  }

  static withHoursAndMinutes(date: Date, hour: number, minutes: number): Date {
    let newDate = new Date(date);
    newDate.setMinutes(minutes);
    newDate.setHours(hour);
    newDate.setSeconds(0);
    return newDate;
  }

  static isValidDate(date: string | Date): boolean {
    return Boolean(date)
      && (_.isDate(date) || _.isString(date))
      && moment(date).isValid()
      && moment(date).isAfter('1900-01-01')
      && moment(date).isBefore('9999-12-31');
  }

  static isEndOfDaySameTimeZone(date: string | Date): boolean {
    const currentDate = new Date(date);
    return this.isValidDate(currentDate) && currentDate.getHours() === 23 && currentDate.getMinutes() === 59;
  }

  static formatToOspDateTimeEndOfDay(date: Date, time: string): OspDateTime {
    if (date) {
      const dateTime = new Date(date);
      const timeParts = time ? time.split(':').map(Number) : [];
      const [hours, minutes] = timeParts.length === 2 && timeParts.every(Utils.isValidNumber) ? timeParts : [23, 59];
      dateTime.setHours(hours);
      dateTime.setMinutes(minutes);
      return {
        timestamp: dateTime.toISOString(),
        zoneId: this.getLocalTimeZone(),
      };
    }
    return null;
  }

  static formatTimeLineDayToInstantStartOfDay(timeLineDate: string, locale: string): string {
    return moment(timeLineDate, 'dddd, DD.MM.YYYY', locale).startOf('day').format(this.utcFormat);
  };

  static formatTimeLineDayToInstantEndOfDay(timeLineDate: string, locale: string): string {
    return moment(timeLineDate, 'dddd, DD.MM.YYYY', locale).endOf('day').format(this.utcFormat);
  };

}
