<div class="attachment-type-container">
  <bh-subtitle [text]="'general.topfact'|translate"></bh-subtitle>

  <ng-container *ngIf="!loading">
    <div fxLayout="row" fxLayoutAlign="start center"
         fxLayoutGap="20px" *ngFor="let document of documents">
      <div fxFlex="30px">
        <fa-icon [icon]="getDocumentTypeIcon(document)" size="2x"
                 [matTooltip]="getToolTip(document)"></fa-icon>
      </div>
      <span fxFlex="400px">{{ document.docId + ' / ' + document.fileId }}</span>
      <div fxFlex="0 1 400px">
        <mat-form-field>
          <mat-label>{{'general.description'|translate}}</mat-label>
          <input class="shortening-description" matInput readonly [value]="document.title">
        </mat-form-field>
      </div>
      <div>
        <a *ngIf="!isPendingDocument(document)"
           (click)="downloadDocument(document)" target="_blank">
          <mat-icon class="bh-mat-icon"
                    [matTooltip]="'general.buttons.download'|translate">
            cloud_download
          </mat-icon>
        </a>
        <mat-spinner *ngIf="isPendingDocument(document)"
                     [matTooltip]="'shared.attachment.docsLoading'|translate"
                     [strokeWidth]="2" [diameter]="25"></mat-spinner>
      </div>
    </div>
  </ng-container>
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center">
    <mat-spinner [matTooltip]="'shared.attachment.docsLoading'|translate"
                 [strokeWidth]="4" [diameter]="50"></mat-spinner>
  </div>
  <span *ngIf="!serviceAvailable && !loading" class="warning">
    {{'shared.attachment.topServiceNotAvailable'|translate}}
  </span>
</div>
