import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { AmountsService } from './amounts.service';
import { BulkItemCategoryCount } from '../contract/category-count.interface';
import { BulkItemTypeCount } from '../contract/type-count.interface';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { TransferCartItem } from '../contract/transfer-cart-item';
import { ViewBulkItemAmount } from '../contract/view-bulk-item-amount.interface';
import { BulkItemTransferColumnService } from './bulk-item-transfer-column.service';
import { IBulkItemRequestParams } from '../contract/bulk-item-request-params.interface';

@Injectable()
export class BulkItemTransferDatasource extends DataSource<ViewBulkItemAmount> {

  private _data: BehaviorSubject<ViewBulkItemAmount[]> = new BehaviorSubject([]);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  private _categories: BehaviorSubject<BulkItemCategoryCount[]> = new BehaviorSubject([]);
  private _bulkItemTypes: BehaviorSubject<BulkItemTypeCount[]> = new BehaviorSubject([]);

  private _pagination: PaginationInfo;
  private _searchTerms: string = null;
  private _searchLabels: string[] = [];
  private _bulkItemTypesFilter: string[] = [];
  private _categoryFilter: string[] = [];
  private _sort: Sort = null;

  public _openedAmounts: Map<string, number> = new Map();
  public _transferCart: BehaviorSubject<Map<string, TransferCartItem>> = new BehaviorSubject(new Map());

  public readonly amounts: Observable<ViewBulkItemAmount[]> = this._data.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly categories: Observable<BulkItemCategoryCount[]> = this._categories.asObservable();
  public readonly bulkItemTypes: Observable<BulkItemTypeCount[]> = this._bulkItemTypes.asObservable();

  constructor(private amountsService: AmountsService,
              private bulkItemTransferColumnService: BulkItemTransferColumnService,
              private snackBar: MatSnackBar) {
    super();
    this.setDefaultValues();
    this.paginatorPageSizeListener();
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  get searchLabels(): string[] {
    return this._searchLabels;
  }

  set searchLabels(searchLabels: string[]) {
    this._searchLabels = searchLabels;
  }

  set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
    } else {
      this.snackBar.open('Eingabe zu lang. Suche nicht möglich', undefined, {duration: 5000});
    }
  }

  get sort(): Sort {
    return this._sort;
  }

  set sort(sort: Sort) {
    this._sort = sort;
  }

  set bulkItemTypesFilter(bulkItemTypesFilter: string[]) {
    this._bulkItemTypesFilter = bulkItemTypesFilter;
  }

  get bulkItemTypesFilter(): string[] {
    return this._bulkItemTypesFilter;
  }

  set categoryFilter(categoryFilter: string[]) {
    this._categoryFilter = categoryFilter;
  }

  get categoryFilter(): string[] {
    return this._categoryFilter;
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  connect(): Observable<ViewBulkItemAmount[]> {
    return this.amounts;
  }

  public disconnect(): void {
  }

  public reset(): void {
    this.setDefaultValues();
    this.updateListing();
  }

  public amountsEmpty(): boolean {
    return this._data && this._data.value.length === 0;
  }

  public getCategories(): Observable<BulkItemCategoryCount[]> {
    let params = {
      bulkItemTypes: this.bulkItemTypesFilter,
      labels: this.searchLabels
    }

    this.amountsService
    .getFilterableAmountCategories(params)
    .subscribe((categories: BulkItemCategoryCount[]) => this._categories.next(categories));

    return this.categories;
  }

  public getTypes(): Observable<BulkItemTypeCount[]> {
    let params = {
      categories: this.categoryFilter,
      labels: this.searchLabels
    }

    this.amountsService
    .getFilterableAmountTypes(params)
    .subscribe((types: BulkItemTypeCount[]) => this._bulkItemTypes.next(types));

    return this.bulkItemTypes;
  }

  public getBulkItemLabels(): Observable<CustomerLabel[]> {
    let params = {
      bulkItemTypes: this.bulkItemTypesFilter,
      categories: this.categoryFilter
    }

    this.amountsService
    .getFilterableAmountLabels(params)
    .subscribe((labels: CustomerLabel[]) => this._labels.next(labels));

    return this.labels;
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.bulkItemTransferColumnService.selectPageSize(size);
    }
    this.pagination.index = index;
    this.pagination.size = size;

    this.amountsService
      .getAmounts(this.getBulkItemsRequestParams())
      .subscribe(res => this.updateStoreData(res));
  }

  public getFilterOptions(): void {
    this.getCategories();
    this.getTypes();
    this.getBulkItemLabels();
    this.updateListing();
  }

  private setDefaultValues(): void {
    this.initPagination();
    this.searchTerms = null;
    this.searchLabels = [];
    this.bulkItemTypesFilter = [];
    this.categoryFilter = [];
    this.sort = null;
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private paginatorPageSizeListener(): void {
    this.bulkItemTransferColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  private sortToSortString(sort: Sort): string {
    return sort && sort.active && sort.direction ? `${sort.active},${sort.direction}` : null;
  }

  private updateStoreData(res: PagedResponse<ViewBulkItemAmount>): void {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  private getBulkItemsRequestParams(): IBulkItemRequestParams {
    return {
      page: this.pagination.index,
      size: this.pagination.size,
      labels: this._searchLabels,
      types: this._bulkItemTypesFilter,
      categories: this._categoryFilter,
      terms: this._searchTerms,
      searchColumns: this.bulkItemTransferColumnService.selectedColumnsList,
      sort: this._sort ? this.sortToSortString(this._sort) : null,
    };
  }

}
