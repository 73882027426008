import { LanguageService } from 'app/shared/services/language.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationDataSource } from '../shared/services/notification.datasource';
import { GuardedNavigableInputComponent } from '../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AlarmRuleAddEditDialogComponent } from '../alarm-rule-add-edit-dialog/alarm-rule-add-edit-dialog.component';
import { ViewAlarmRule } from '../contract/view-alarm-rule.interface';
import {
  ConfirmationDialogComponent,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewGlobalGeofence } from '../../equipment/contract/view-global-geofence.interface';
import { GlobalGeofencesDataSource } from '../../equipment/shared/global-geofences.datasource';
import { caseInsensitiveCompare } from '../../../shared/utils';
import {
  EquipmentSubTypeFilterView,
  EquipmentTypeFilterView,
} from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { EquipmentAlarmRuleSelectionType } from '../contract/equipment-alarm-rule-selection-type.enum';
import { AlarmTypeService } from '../shared/services/alarm-types.service';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { Modules } from '../../../shared/enums/modules.enum';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../shared/router-history';
import { environment } from 'environments/environment';
import { delay } from 'rxjs/operators';
import { DeleteAlarmRuleCommand } from '../contract/delete-alarm-rule-command';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  @ViewChild('addButton', { read: ElementRef }) addButton: ElementRef;

  public displayedColumns = [
    'alarmType',
    'selectionType',
    'labelsOrEquipmentTypes',
    'alarmLevel',
    'alarmMessage',
    'editAlarmRule',
    'deleteAlarmRule'
  ];
  public disableAdding = true;

  private labels: string[];
  private equipmentTypes: EquipmentTypeFilterView[];
  private globalGeofences: ViewGlobalGeofence[];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              private globalGeofenceStore: GlobalGeofencesDataSource,
              public notificationStore: NotificationDataSource,
              public alarmTypeService: AlarmTypeService,
              private languageService: LanguageService,
              private translationResolver: TranslatableStringPipe) {

    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.getLabels();
    this.getEquipmentTypes();
    if (this.hasModule(Modules.LIVE_DATA) && this.hasAuthority(Authorities.GEOFENCE_VIEW)) {
      this.getGlobalGeofences();
    }
  }

  public ngOnDestroy(): void {
    // for @TakeUntilDestroy
  }

  public addEditAlarmRule(alarmRule: ViewAlarmRule | null): void {
    const dialogRef: MatDialogRef<AlarmRuleAddEditDialogComponent> = this.dialog.open(
      AlarmRuleAddEditDialogComponent,
      <MatDialogConfig>{ disableClose: true }
    );
    dialogRef.componentInstance.labels = this.labels;
    dialogRef.componentInstance.equipmentTypes = this.equipmentTypes;
    dialogRef.componentInstance.globalGeofences = this.globalGeofences;
    dialogRef.componentInstance.alarmRule = alarmRule;
    dialogRef.afterClosed()
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe(() => {
        this.addButton.nativeElement.blur();
    });
  }

  public getEditTooltip(alarmType: AlarmType): string {
    return this.alarmTypeService.isTypeAllowed(alarmType)
      ? this.translate('modules.notification.notificationList.editAlarmRule')
      : this.translate('modules.notification.notificationList.noRequiredModule');
  }

  public deleteAlarmRule(alarmRule: ViewAlarmRule): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.notification.notificationList.deleteAlarmRuleConfirmationMessage');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd: DeleteAlarmRuleCommand = new DeleteAlarmRuleCommand();
        cmd.alarmRuleId = alarmRule.alarmRuleId;
        cmd.alarmType = alarmRule.alarmType;

        this.notificationStore.removeAlarmRule(cmd);
      }
    });
  }

  public getSelection(alarmRule: ViewAlarmRule): string {
    if (!alarmRule?.selectionType) {
      return '';
    }
    switch (alarmRule.selectionType) {
      case EquipmentAlarmRuleSelectionType.LABEL:
        return alarmRule.labels.join('\n');
      case EquipmentAlarmRuleSelectionType.EQUIPMENT_TYPE:
        return this.mapToCategory2Names(alarmRule.equipmentTypeIds).join('\n');
      case EquipmentAlarmRuleSelectionType.ALL_EQUIPMENT:
        return '-';
      default:
        return this.translate('general.unknown').toLowerCase();
    }
  }

  private mapToCategory2Names(equipmentTypeIds: string[]): string[] {
    const typeNames: string[] = [];
    this.equipmentTypes.forEach((typeGroup: EquipmentTypeFilterView) => {
      typeGroup.subTypes.forEach((subType: EquipmentSubTypeFilterView) => {
        if (equipmentTypeIds.includes(subType.equipmentTypeId)) {
          typeNames.push(this.translationResolver.transform(subType.category2Name));
        }
      })
    });
    return typeNames.filter(Boolean);
  }

  private getLabels(): void {
    this.notificationStore.getDistinctLabels().pipe(untilDestroyed(this)).subscribe(
      (labels: string[]) => {
        this.labels = labels.sort(caseInsensitiveCompare);
        this.updateAddingAbility();
      });
  }

  private getEquipmentTypes(): void {
    this.notificationStore.getDistinctEquipmentTypes().pipe(untilDestroyed(this)).subscribe(
      (equipmentTypes: EquipmentTypeFilterView[]) => {
        this.equipmentTypes = equipmentTypes;
        this.updateAddingAbility();
      });
  }

  private getGlobalGeofences(): void {
    this.globalGeofenceStore.globalGeofences.pipe(untilDestroyed(this)).subscribe(
      (res: ViewGlobalGeofence[]) => {
        this.globalGeofences = res.sort((geofence1, geofence2) =>
         caseInsensitiveCompare(geofence1.globalGeofenceName, geofence2.globalGeofenceName));
      });
    this.globalGeofenceStore.getGlobalGeofences();
  }

  private updateAddingAbility(): void {
    this.disableAdding = (!this.labels || !this.labels.length)
      && (!this.equipmentTypes || !this.equipmentTypes.length);
  }

  public get isAddEditAllowed(): boolean {
    return this.hasAuthority(this.authorities.NOTIFICATIONS_MANAGE_ALARMS) && this.hasModule(this.modules.ALARMS)
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
