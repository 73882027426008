import { AfterViewInit, ComponentRef, Directive, ElementRef, EmbeddedViewRef, Input, OnChanges, Renderer2, ViewContainerRef } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { CustomTooltipComponent } from '../components/custom-tooltip/custom-tooltip.component';

interface HeaderConfigs {
  element: HTMLElement;
  linteners?: (() => void)[];
}

@UntilDestroy()
@Directive({
  selector: 'table[mat-table][bhMatTableLongHeaderTooltip]'
})
export class MatTableLongHeaderTooltipDirective implements AfterViewInit, OnChanges {
  @Input() textContainerElementClassName = 'mat-sort-header-content';

  private headerConfigs: HeaderConfigs[];
  private isAfterViewInitDone = false;
  private componentRef: ComponentRef<CustomTooltipComponent>;
  private textContainerElements: HTMLElement[];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    matTable: MatTable<any>
  ) {
    matTable.contentChanged
      .pipe(
        filter(() => this.isAfterViewInitDone),
        untilDestroyed(this))
      .subscribe(() => this.setup());
  }

  public ngOnChanges(): void {
    if (this.isAfterViewInitDone) {
      this.setup();
    }
  }

  public ngAfterViewInit(): void {
    this.isAfterViewInitDone = true;
    this.setup();
  }

  private setup(): void {
    this.unlisten();
    this.getTextContainerElements();
    this.applyTextContainerCSSStyles();
    this.pickUpOverflownTextContainerElements();
    this.listen();
  }

  private getTextContainerElements(): void {
    this.textContainerElements = Array
      .from(this.elementRef.nativeElement.getElementsByClassName(this.textContainerElementClassName) as HTMLElement[]);
  }

  private applyTextContainerCSSStyles(): void {
    if (this.textContainerElements?.length > 0) {
      this.textContainerElements.forEach(element => {
        element.parentElement.style.width = '100%';
        element.style.textOverflow = 'ellipsis';
        element.style.overflow = 'hidden';
        element.style.display = 'block';
        element.style.overflowWrap = 'normal';
      });
    }
  }

  private pickUpOverflownTextContainerElements(): void {
    if (this.textContainerElements?.length) {
      this.headerConfigs = this.textContainerElements
        .filter(element => element.scrollWidth > element.clientWidth)
        .map(element => ({ element }));
    }
  }

  private unlisten(): void {
    if (this.headerConfigs?.length > 0) {
      this.headerConfigs.forEach(conf => conf.linteners.forEach(unlistenCallback => unlistenCallback()));
    }
  }

  private listen(): void {
    if (this.headerConfigs?.length > 0) {
      this.headerConfigs = this.headerConfigs.map(conf => ({
        ...conf,
        linteners: [
          this.renderer.listen(conf.element, 'mouseenter', () => this.enterHandler(conf.element)),
          this.renderer.listen(conf.element, 'mouseleave', () => this.leaveHandler())
        ]
      }))
    }
  }

  private enterHandler(element: HTMLElement): void {
    if (!this.componentRef) {
      this.componentRef = this.viewContainerRef.createComponent(CustomTooltipComponent);
      this.componentRef.instance.tooltip = element.innerText;
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties(element);
    }
  }

  private setTooltipComponentProperties(element: HTMLElement): void {
    if (this.componentRef) {
      this.componentRef.instance.tooltip = element.innerText;
      const {left, right, bottom} = element.getBoundingClientRect();
      this.componentRef.instance.left = (right - left) / 2 + left;
      this.componentRef.instance.top = bottom;
    }
  }

  private leaveHandler(): void {
    this.destroy();
  }

  private destroy(): void {
    if (this.componentRef !== null) {
      this.viewContainerRef.remove();
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
