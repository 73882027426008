import { Injectable } from '@angular/core';
import { ColumnService } from '../../../../shared/column.service';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';
import { DisplayService } from '../../../../shared/display.service';
import { KeycloakService } from '../../../../core/keycloak';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { TransportColumnDefinitionService } from './transport-column-definition.service';
import {
  GENERAL_COLUMN_DEF,
  TRANSPORT_COLUMN_DEF,
} from '../../../../shared/constants/base-column-definition-constants';
import { PaginatorPageSizeOptions } from 'app/shared/paginator-page-size-options';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

@Injectable({
  providedIn: 'root'
})
export class TransportColumnService extends ColumnService {

  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService,
              protected transportColumnDefinitionService: TransportColumnDefinitionService) {
    super('transportOverview',
      transportColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.transportTaskListConfiguration.columns,
      userConfigurationService.userConfiguration.transportTaskListConfiguration.columnConfigs,
      {
        transportTaskPaginatorOptions: {
          listViewPageSize: userConfigurationService.userConfiguration.transportTaskListConfiguration.listViewPageSize,
          boardViewBoardPageSize: userConfigurationService.userConfiguration.transportTaskListConfiguration.boardViewBoardPageSize,
          boardViewListPageSize: userConfigurationService.userConfiguration.transportTaskListConfiguration.boardViewListPageSize,
        }
      },
      displayService,
      authService);
  }

  get columnLimit(): number {
    return undefined;
  }

  public selectColumns(
    columns: ColumnDefinition[],
    pageSize?: number,
    paginatorPageSizeOptions?: PaginatorPageSizeOptions
  ): void {
    super.selectColumns(columns, pageSize, paginatorPageSizeOptions);
    this.saveListConfiguration();
  }

  public selectBoardViewBoardPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.transportBoardViewBoardPageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), null, {
        transportTaskPaginatorOptions: { boardViewBoardPageSize: pageSize }
      });
    }
  }

  public selectListViewPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.transportListViewPageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), null, {
        transportTaskPaginatorOptions: { listViewPageSize: pageSize }
      });
    }
  }

  public selectBoardViewListPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.transportBoardViewListPageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), null, {
        transportTaskPaginatorOptions: { boardViewListPageSize: pageSize }
      });
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === TRANSPORT_COLUMN_DEF.IDENTIFIER
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.STATE
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.PRIORITY
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.DUE_DATE
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.TITLE
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.START_ITEM
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.TARGET_ITEM
      || column.cdkColumnDef === TRANSPORT_COLUMN_DEF.EQUIPMENT_DISPLAY_NAME
      || column.cdkColumnDef === GENERAL_COLUMN_DEF.OPEN_DETAILS;
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveTransportTaskListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.columnConfigsSubject.getValue(),
      this.transportListViewPageSizeSubject.getValue(),
      this.transportBoardViewBoardPageSizeSubject.getValue(),
      this.transportBoardViewListPageSizeSubject.getValue()
    );
  }
}
