<div [formGroup]="settingsForm">
  <p>{{getFieldLabel([controlNames.SETTINGS_LIMIT])}} *</p>
  <mat-slider
    color="primary"
    [max]="100"
    [min]="0"
    [step]="1"
    [discrete]="true"
    [displayWith]="formatLabel">
    <input matSliderThumb [formControlName]="controlNames.SETTINGS_LIMIT">
  </mat-slider>
  <div fxLayout="row" fxLayoutAlign="space-between start" class="slider-range">
    <p class="slider-range__label">0%</p>
    <p class="slider-range__label">100%</p>
  </div>
  <div class="explanation">
    <bh-explanation-card [text]="getFieldExplanation([controlNames.SETTINGS_LIMIT])"></bh-explanation-card>
  </div>
</div>
