export enum PerformanceType {
  Wartung = 'Wartung',
  Reparatur = 'Reparatur',
  Reifenschaden = 'Reifenschaden',
  Verschleiss = 'Verschleiss',
  Gewaltschaden = 'Gewaltschaden',
  Nachrüstung = 'Nachrüstung',
  Miete = 'Miete',
  Kauf = 'Kauf',
  Prüfung = 'Prüfung',
  Freimeldung = 'Freimeldung',
  Fullservice = 'Fullservice',
  Finanzierung = 'Finanzierung',
  Leasing = 'Leasing',
  Versicherung = 'Versicherung',
  Maschinenbruchversicherung = 'Maschinenbruchversicherung',
  Energiekosten = 'Energiekosten',
  Sonstiges = 'Sonstiges',
  Ersatzteile = 'Ersatzteile',
}

