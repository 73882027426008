import { Injectable } from '@angular/core';

@Injectable()
export class AddressService {

  public formatAddressSingleLine(city: string, street: string, streetNumber: string) {
    let address = city ? city : '';
    if (city && (street || streetNumber)) {
      address = address.concat(', ');
    }
    return address.concat(street ? street : '', streetNumber ? ' ' + streetNumber : '');
  }

  public formatFullAddressSingleLine(postalCode: string, city: string, street: string, streetNumber: string): string {
    const addressFragments = [
      `${postalCode || ''} ${city || ''}`,
      `${street ? street + ' ' : ''}${streetNumber || ''}`
    ];
    return addressFragments
      .map(item => item && item.trim())
      .filter(Boolean)
      .join(', ');
  }

  public formatFullAddressSingleLineOrderByStreetThenCity(postalCode: string, city: string, street: string, streetNumber: string): string {
    const addressFragments = [
      `${street || ''} ${streetNumber || ''}`,
      `${postalCode ? postalCode + ' ' : ''}${city || ''}`
    ];
    return addressFragments
    .map(item => item && item.trim())
    .filter(Boolean)
    .join(', ');
  }

  constructor() { }

}
