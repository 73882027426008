import {LanguageService} from 'app/shared/services/language.service';
import {Component, OnInit} from '@angular/core';
import {Authorities} from 'app/shared/enums/authorities.enum';
import {KeycloakService} from '../../../../../core/keycloak';
import {Modules} from '../../../../enums/modules.enum';
import { CONTACT_COLUMN_DEF, GENERAL_COLUMN_DEF, PROJECT_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';

@Component({
  selector: 'bh-map-settings',
  templateUrl: './map-settings.component.html',
  styleUrls: ['./map-settings.component.scss']
})
export class MapSettingsComponent implements OnInit {

  public readonly equipmentFields: Field[] = [
    {name: this.translate('general.name.s'), value: GENERAL_COLUMN_DEF.EQUIPMENT_NAME},
    {name: this.translate('shared.mapSettings.model'), value: GENERAL_COLUMN_DEF.EQUIPMENT_MODEL},
    {name: this.translate('shared.mapSettings.licensePlate'), value: GENERAL_COLUMN_DEF.EQUIPMENT_LICENSE_PLATE},
    {name: this.translate('general.costCenter'), value: GENERAL_COLUMN_DEF.EQUIPMENT_COST_CENTER},
    {name: this.translate('general.intNumber.abbr'), value: GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER},
    {name: this.translate('shared.mapSettings.manufacturerSerialNumber'), value: GENERAL_COLUMN_DEF.EQUIPMENT_SERIAL_NUMBER},
    {name: this.translate('shared.pipes.mapNone'), value: GENERAL_COLUMN_DEF.EQUIPMENT_NONE}
  ];

  public readonly projectFields: Field[] = [
    {name: this.translate('general.name.s'), value: GENERAL_COLUMN_DEF.PROJECT_NAME},
    {name: this.translate('general.projectNumber'), value: PROJECT_COLUMN_DEF.PROJECT_NUMBER},
    {
      name: `${this.translate('general.projectNumber')} + ${this.translate('general.name.s')}`,
      value: PROJECT_COLUMN_DEF.PROJECT_NUMBER_AND_PROJECT_NAME_LOCATION
    },
    {name: this.translate('general.costCenter'), value: PROJECT_COLUMN_DEF.PROJECT_COST_CENTER},
    {name: this.translate('shared.pipes.mapNone'), value: PROJECT_COLUMN_DEF.PROJECT_NONE}
  ];

  public readonly organisationFields: Field[] = [
    {name: this.translate('general.name.s'), value: GENERAL_COLUMN_DEF.ORGANISATION},
    {name: this.translate('shared.pipes.mapNone'), value: GENERAL_COLUMN_DEF.ORGANISATION_NONE}
  ];

  public readonly stockFields: Field[] = [
    {name: this.translate('general.name.s'), value: GENERAL_COLUMN_DEF.STOCK_NAME},
    {name: this.translate('general.costCenter'), value: GENERAL_COLUMN_DEF.STOCK_COST_CENTER},
    {name: this.translate('shared.pipes.mapNone'), value: GENERAL_COLUMN_DEF.STOCK_NONE}
  ];

  public readonly contactFields: Field[] = [
    {name: this.translate('general.name.s'), value: CONTACT_COLUMN_DEF.CONTACT_NAME_LOCATION},
    {name: this.translate('general.identifier'), value: CONTACT_COLUMN_DEF.CONTACT_INTERNAL_NUMBER_LOCATION},
    {name: this.translate('shared.pipes.mapNone'), value: GENERAL_COLUMN_DEF.CONTACT_NONE}
  ];

  public selectedEquipmentField: string;
  public selectedProjectField: string;
  public selectedOrganisationField: string;
  public selectedStockField: string;
  public selectedContactField: string;
  public gridSize: number;

  public canSeeLinkEquipment = false;
  public canSeeLinkProject = false;
  public canSeeLinkContact = false;

  constructor(private languageService: LanguageService,
              private authService: KeycloakService) {
  }


  public ngOnInit(): void {
    if (this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this.canSeeLinkEquipment = true;
    }
    if (this.authService.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this.canSeeLinkProject = true;
    }
    if (this.authService.hasAnyAuthority([Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE])
      && this.authService.hasModule(Modules.CONTACT_MANAGEMENT)) {
      this.canSeeLinkContact = true;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}

interface Field {
  name: string,
  value: string
}
