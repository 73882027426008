<div class="header-wrapper"
      fxFlexAlign="start space-between" fxLayout="row">
  <div class="header-wrapper__lef" fxFlex>
    <div class="app-logo" [ngClass]="{
      'wnt-logo': isWNTheme(),
      'triathlon-logo': isTriathlonTheme()
    }"></div>
  </div>
  <div *ngIf="showCloseIcon()" class="dialog-close-icon"
    [ngClass]="{'triathlon-close-icon': isTriathlonTheme()}">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
</div>

<div class="body-wrapper">
  <h1 mat-dialog-title>{{'shared.mobileAttention.title'|translate}}</h1>
  <h4>
    {{'shared.mobileAttention.availableUntil'|translate}}
    <span class="date-span">{{'shared.mobileAttention.availableDate'|translate}}</span>
  </h4>
  <h4>{{'shared.mobileAttention.recommendation'|translate}}</h4>

  <div class="dialog-actions">
    <div class="store-links-container">
      <a class="store-link" [href]="
        (isWackerNeusonDomain()
          ? 'market://details?id=com.wackerneuson.equipcarepro'
          : 'market://details?id=com.onestoppro.app') | safeUrl
      ">
        <img class="store-link" src="assets/google-play-badge.png">
      </a>
      <a class="store-link" [href]="
        (isWackerNeusonDomain()
          ? 'itms-apps://itunes.apple.com/us/app/apple-store/id1604194800?mt=8'
          : 'itms-apps://itunes.apple.com/us/app/apple-store/id1543227491?mt=8') | safeUrl
      ">
        <img class="store-link app-store-link" src="assets/app-store-badge.png">
      </a>
    </div>

    <a class="open-mobile-app-link" [href]="
      (isWackerNeusonDomain()
        ? 'equipcareproapp://home'
        : 'onestopproapp://home') | safeUrl
    ">
      <button mat-raised-button class="open-mobile-app-button" color="primary">
        {{'shared.mobileAttention.openMobileApp'|translate:{
        value: (isWackerNeusonDomain()
            ? 'general.applicationTitle.wackerNeuson'
            : 'general.applicationTitle.defaultOsp') |translate
      } }}
      </button>
    </a>
  </div>
</div>

