import { ExpandingTreeMapItem } from './expanding-tree-map-item.class';

export class ExpandingTreeLeavesStatistic {
  private countVisible = 0;
  private countHidden = 0;
  private selectedVisible = 0;
  private itemMap: Map<string, ExpandingTreeMapItem>;

  public collectLeafStatistic(item: ExpandingTreeMapItem, itemMap: Map<string, ExpandingTreeMapItem>): void {
    this.countVisible = 0;
    this.countHidden = 0;
    this.selectedVisible = 0;
    this.itemMap = itemMap;

    this.collect(item);
  }

  private collect(item: ExpandingTreeMapItem): void {
    if (item.hasChildren()) {
      item.childrenIds.forEach(childId => this.collect(this.itemMap.get(childId)));
    } else {
      this.addMetrics(item);
    }
  }

  private addMetrics(item: ExpandingTreeMapItem): void {
    if (item.hidden) {
      this.countHidden++;
    } else {
      this.countVisible++;
      if (item.checked) {
        this.selectedVisible++;
      }
    }
  }

  public areAllVisibleSelected(): boolean {
    return this.countVisible === this.selectedVisible;
  }

  public hasHidden(): boolean {
    return this.countHidden > 0;
  }
}
