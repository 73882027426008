<div fxFill fxLayout="column" fxLayoutAlign="start stretch">

  <mat-card class="list-view-main-container" fxFlex="100">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div class="list-view-main__left-header" fxLayout="column" fxLayoutAlign="start stretch">
          <div fxFlex="70px" fxLayout="row" fxLayoutAlign="space-between stretch">
            <form [formGroup]="filterForm" fxFlex fxLayoutGap="15px" fxLayoutAlign="start center">
              <mat-form-field fxFlex="15">
                <mat-select multiple [placeholder]="'general.type'|translate" formControlName="selectedBulkItemTypes"
                            (selectionChange)="selectBulkItemTypeAndUpdate()">
                  <mat-option *ngFor="let group of types | async" [value]="group.bulkItemType">
                    {{ group.bulkItemType | bulkItemType }} ({{ group.count }})
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex="15">
                <mat-select multiple [placeholder]="'modules.disposition.base.category.pl'|translate"
                            formControlName="selectedCategories"
                            (selectionChange)="selectCategoryAndUpdate()">
                  <mat-option *ngFor="let cat of categories | async" [value]="cat.bulkItemCategory">
                    {{ cat.bulkItemCategory }} ({{ cat.count }})
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex="15">
                <mat-select #labelSelector multiple [placeholder]="'general.label.s'|translate"
                            formControlName="selectedLabels"
                            (selectionChange)="selectLabelsAndUpdate(labelSelector.value)">
                  <mat-option *ngFor="let label of labels | async" [value]="label.name">
                    {{ label.name }} ({{ label.count }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>

            <div fxFlex="70px" (click)="openTransferCart()"
                 class="open-transfer-cart" [ngClass]="{'empty-cart': transferCartStore.transferCartEmpty()}"
                 fxLayout="column" fxLayoutAlign="center center">
              <fa-icon [icon]="faClipBoardList"></fa-icon>
              <span>{{transferCartStore.transferCartLength | async}}</span>
            </div>
          </div>

        </div>

        <div class="list-view-main__left-body scrollable-table-container"
          fxFlex
          fxLayoutGap="15px"
          fxLayout="row"
          fxLayoutAlign="start stretch">

          <div fxFlex *ngIf="bulkItemTransferStore.amountsEmpty()" class="no-results"
               fxLayout="row" fxLayoutAlign="center center">
            {{ 'general.labels.noAnyFound'|translate:{ value: 'modules.disposition.base.inventory.pl'|translate } }}
          </div>

          <mat-table #table
            fxFlex
            matSort
            class="pagination-data-table left-body-list"
            [dataSource]="bulkItemTransferStore"
            [ngClass]="{'hidden': bulkItemTransferStore.amountsEmpty()}"
            (matSortChange)="sortData($event)"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.filteredColumnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

            <ng-container [cdkColumnDef]="column.cdkColumnDef"
                          *ngFor="let column of displayedColumns">

              <ng-container *ngIf="column.cdkColumnDef === 'thumbnail'">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="icon-column"></mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                    <bh-thumbnail *ngIf="column.valueCallback(row)"
                                  [key]="column.valueCallback(row)" [size]="ThumbnailSize.XS"></bh-thumbnail>

                    <fa-icon *ngIf="!column.valueCallback(row)"
                            [icon]="optionResolver.resolveIcon(row.bulkItemType)"></fa-icon>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === 'bulkItemName'">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <span>
                    {{row.bulkItemName}}
                    <fa-icon *ngIf="row.nonReturnable"
                      [icon]="faRecycle"
                      [matTooltip]="'modules.disposition.bulkItemTransfer.autoConsumedToProject'|translate">
                    </fa-icon>
                    <mat-icon [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.bulkItems'|translate }"
                      (click)="navigateToBulkItem(row.bulkItemId)">
                      open_in_new
                    </mat-icon>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === 'stockName'">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <span>
                    {{row.stockName}}
                    <mat-icon *ngIf="hasAccessToStock()"
                      [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.stock.s'|translate }"
                      (click)="navigateToStock(row.stockId)">
                      open_in_new
                    </mat-icon>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <span>{{column.valueCallback(row)}}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <mat-chip-listbox>
                    <mat-chip-option [disabled]="true"
                              *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip-option>
                    <mat-chip-option *ngIf="getRemainingLabels(row).length > 0"
                              matTooltipClass="mat-tooltip-multiline"
                              [disabled]="true" [matTooltip]="getRemainingLabels(row).join('\n')">
                      {{ '+' + getRemainingLabels(row).length }}
                    </mat-chip-option>
                  </mat-chip-listbox>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === 'intoTransferCart'">
                <mat-header-cell class="into-transfer-cart header-border-right" *cdkHeaderCellDef>
                  {{'modules.disposition.base.inTransferBasket'|translate}}
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="into-transfer-cart" fxLayout="row" fxLayoutAlign="end center">

                  <mat-slide-toggle color="primary"
                                    *ngIf="!transferCartStore.isAmountOpened(row.amountId)"
                                    (change)="transferCartStore.switchAmountOpened(row.amountId)"
                                    [checked]="transferCartStore.isAmountInTransferCart(row.amountId)">
                  </mat-slide-toggle>

                  <div *ngIf="transferCartStore.isAmountOpened(row.amountId)" fxLayout="row" fxLayoutAlign="start stretch">

                    <div class="cancel" fxLayout="row" fxLayoutAlign="center center">
                      <mat-icon (click)="transferCartStore.closeAmount(row.amountId)">
                        cancel
                      </mat-icon>
                    </div>

                    <div class="select-amount-form"
                         [ngClass]="{'invalid': !transferCartStore.isValidTransferAmount(row)}">
                      <input matInput
                             fxFlex="100px"
                             step="any"
                             type="number"
                             autocomplete="off"
                             [value]="transferCartStore.getTransferAmount(row.amountId)"
                             (input)="transferCartStore.updateTransferAmount(row.amountId, $event.target.value)"
                             [min]="1"
                             [max]="row.amountAvailable | dimensionUnitConverter: row.bulkItemUnit: 3"
                             [matTooltip]="'shared.validation.maxDecimals'|translate:{value: 3}"
                             [matTooltipDisabled]='transferCartStore.checkDecimalDigitsNumber(row.amountId)'>
                      <div class="unit" fxLayout="row" fxLayoutAlign="center center">
                        {{row.bulkItemUnit | dimensionUnitDisplay}}
                      </div>
                      <div (click)="transferCartStore.putAmountIntoTransferCart(row)"
                           fxFlex="36px" class="ok" fxLayout="row" fxLayoutAlign="center center">
                        OK
                      </div>
                    </div>

                  </div>
                </mat-cell>
              </ng-container>

            </ng-container>

            <mat-header-row *cdkHeaderRowDef="columnService.displayedColumns | async" class="custom-header"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: columnService.displayedColumns | async;">
            </mat-row>

          </mat-table>
        </div>

        <div class="list-view-main__left-body-paginator" fxFlex="60px">
          <div id="list-settings">
            <button id="configBtn"
                    mat-icon-button
                    class="rotatable"
                    (click)="openConfiguration()"
                    [matTooltip]="'general.labels.confColumnArrangement'|translate">
              <mat-icon class="rotatable">settings</mat-icon>
            </button>
          </div>
          <mat-paginator fxFlex="grow"
                         [length]="bulkItemTransferStore.length | async"
                         [pageIndex]="bulkItemTransferStore.pagination.index"
                         [pageSize]="bulkItemTransferStore.pagination.size"
                         [pageSizeOptions]="[5, 25, 50, 100]"
                         (page)="onPaginateChange($event)">
          </mat-paginator>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>
