<mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                         (change)="changeSortParameterRequested($event.value)">
  <mat-button-toggle class="sort-button-toggle" [checked]="sortParameter === 'fileName'"
                     fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" value="fileName">
    <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
    <span>{{'general.id'|translate}}</span>
  </mat-button-toggle>
  <mat-button-toggle class="sort-button-toggle" [checked]="sortParameter === 'fileDescription'"
                     fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" value="fileDescription">
    <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
    <span>{{'general.description'|translate}}</span>
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-accordion displayMode="flat">
  <div *ngIf="!serviceAvailable && !loading" class="warning">
    {{'shared.attachment.docServiceNotAvailable'|translate}}
  </div>
  <ng-container *ngIf="!loading">
    <mat-expansion-panel *ngFor="let document of documents" hideToggle="true">
      <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                  expandedHeight="70px">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
          <div class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
               fxLayoutAlign="start stretch">
            <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
              <fa-icon [icon]="getDocumentTypeIcon(document)" size="2x"></fa-icon>
            </div>
            <div class="file-text" fxFlex="calc(100% - 60px)" fxLayout="column" fxLayoutAlign="center">
              <span class="line-one shortening-filename">{{document.id}}</span>
              <div class="line-two" fxLayout="row">
                <span class="shortening-filename">{{document.title}}</span>
                <span>.{{getFileExtension(document)}}</span>
              </div>
            </div>
          </div>
          <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
            <button mat-icon-button>
              <mat-icon aria-label="more">more_vert</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="m-view-attachment__actions" fxLayout="row"
           fxLayoutAlign="center stretch">
        <button *ngIf="!isPendingDocument(document)"
                class="download" fxFlexFill mat-button (click)="downloadDocument(document)">
          <span>{{'general.buttons.download'|translate|uppercase}}</span>
        </button>
        <button *ngIf="isPendingDocument(document)"
                class="download" fxFlexFill fxLayoutAlign="center center" mat-button>
          <mat-spinner [matTooltip]="'shared.attachment.docLoading'|translate"
                       [strokeWidth]="2" [diameter]="30"></mat-spinner>
        </button>
      </div>
    </mat-expansion-panel>
  </ng-container>
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center">
    <mat-spinner [matTooltip]="'shared.attachment.docsLoading'|translate"
                 [strokeWidth]="2" [diameter]="20"></mat-spinner>
  </div>
</mat-accordion>
