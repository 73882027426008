import { NgModule } from '@angular/core';
import { MapWidgetComponent } from './map-widget/map-widget.component';
import { ClockWidgetComponent } from './clock-widget/clock-widget.component';
import { NextRepairsWidgetComponent } from './next-repairs-widget/next-repairs-widget.component';
import { FleetCompositionWidgetComponent } from './fleet-composition-widget/fleet-composition-widget.component';
import { BasicWidgetComponent } from './basic-widget/basic-widget.component';
import { CustomMaterialModule } from '../../../core/custom-material/custom-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetRegistry } from './widget-registry';
import { BasicWidgetConfigurationDialogComponent } from './basic-widget/basic-widget-configuration-dialog/basic-widget-configuration-dialog.component';
import { SharedModule } from '../../../shared/shared.module';
import { NgPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { TypeOfUseChangeRequestsWidgetComponent } from './type-of-use-change-requests-widget/type-of-use-change-requests-widget.component';
import { TypeOfUseChangeRequestComponent } from './type-of-use-change-requests-widget/type-of-use-change-request/type-of-use-change-request.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DispositionModule } from '../../disposition/disposition.module';
import { TransferRequestWidgetComponent } from './transfer-request-widget/transfer-request-widget.component';
import { TransferRequestComponent } from './transfer-request-widget/transfer-request/transfer-request.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MapModule } from 'app/shared/modules/map/map.module';
import { EquipmentStatusWidgetComponent } from './equipment-status-widget/equipment-status-widget.component';
import { EquipmentStatusWidgetDatasource } from './equipment-status-widget/equipment-status-widget.datasource';
import { EquipmentStatusPreparedDataPipe } from './equipment-status-widget/equipment-status-prepared-data.pipe';
import { EquipmentStatusChartDataPipe } from './equipment-status-widget/equipment-status-chart-data.pipe';
import { EquipmentStatusChartOptionsPipe } from './equipment-status-widget/equipment-status-chart-options.pipe';
import { CustomPluginFactory } from './chart-custom-plugin/chart-custom-plugin-factory.service';
import { LastCompletedTasksWidgetComponent } from './last-completed-tasks-widget/last-completed-tasks-widget.component';
import { LastCompletedTasksWidgetDatasource } from './last-completed-tasks-widget/last-completed-tasks-widget.datasource';
import { LastCompletedTasksTableComponent } from './last-completed-tasks-widget/last-completed-tasks-table/last-completed-tasks-table.component';
import { MainPipeModule } from '../../../shared/pipes/main-pipe.module';

@NgModule({
  declarations: [
    BasicWidgetComponent,
    ClockWidgetComponent,
    MapWidgetComponent,
    NextRepairsWidgetComponent,
    FleetCompositionWidgetComponent,
    BasicWidgetConfigurationDialogComponent,
    TypeOfUseChangeRequestsWidgetComponent,
    TypeOfUseChangeRequestComponent,
    TransferRequestWidgetComponent,
    TransferRequestComponent,
    EquipmentStatusWidgetComponent,
    EquipmentStatusPreparedDataPipe,
    EquipmentStatusChartDataPipe,
    EquipmentStatusChartOptionsPipe,
    LastCompletedTasksWidgetComponent,
    LastCompletedTasksTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    NgPipesModule,
    SharedModule,
    TranslateModule,
    InfiniteScrollModule,
    FlexModule,
    RouterModule,
    DispositionModule,
    ScrollingModule,
    MapModule,
    MainPipeModule,
  ],
  providers: [
    WidgetRegistry,
    EquipmentStatusWidgetDatasource,
    CustomPluginFactory,
    LastCompletedTasksWidgetDatasource,
  ]
})
export class WidgetModule {
}
