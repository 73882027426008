<mat-card class="stock-card">
  <mat-card-content>
    <div class="list-view-main__left-container">
      <div class="list-view-main__left" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <div class="list-view-main__left-body">
          <div *ngFor="let stock of stocks"
               class="left-body-list stock-list-item"
               fxLayout="row"
               fxLayoutAlign="space-between center"
               [ngClass]="{'left-body-item--active': selectedId === stock.stockId}"
               (click)="selectStock(stock.stockId)">

            <div class="stock-icon">
              <fa-icon *ngIf="stock.stockType === 'MAIN'" class="main-stock" [icon]="faStar"></fa-icon>
              <fa-icon class="stock-type" [icon]="resolveIcon(stock.stockType)"></fa-icon>
            </div>

            <div class="stock-data" fxFlex fxLayout="column"
                 fxLayoutAlign="center start">
              <div class="name">
                {{stock.stockName}}
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <span>{{stock.costCenter ? stock.costCenter : '-'}}</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <span>{{formatAddress(stock.stockAddress)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
