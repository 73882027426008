<div class="list">
  <div class="m-view-details-menu static-title">
    <span>{{'modules.equipment.assignProject.projectSelection'|translate|uppercase}}</span>
    <button mat-icon-button (click)="cancel()">
      <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
    </button>
  </div>
  <div class="m-list-header__form">
    <mat-form-field>
      <mat-label>{{'general.search'|translate}} ...</mat-label>
      <input matInput
             [attr.label]="'modules.equipment.assignProject.projectSearch'|translate"
             autocomplete="off"
             autofocus="false"
             [formControl]="termControl">
      <mat-icon matSuffix (click)="reset()">close</mat-icon>
    </mat-form-field>
  </div>
  <ng-container *ngIf="projects | async; let data">
    <virtual-scroller #projectScroll [items]="data" [bufferAmount]="10"
                      [style.height.px]="ruler.getViewportSize().height - 240"
                      [enableUnequalChildrenSizes]="true">
      <div fxLayout="column" *ngFor="let project of projectScroll.viewPortItems"
           class="project" (click)="selectProject(project.projectId)">
        <div fxLayout="row" fxLayoutGap="10px">
          <span class="project-number">#{{project.projectNumber}}</span>
          <div fxLayout="column" fxFlex="1 1 100%">
            <span class="project-name">{{project.projectName}}</span>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span class="project-address">{{formatAddress(project.projectAddress) | defaultValue: '-'}}</span>
              <div class="distance" *ngIf="position">
                <mat-icon>near_me</mat-icon>
                <span>{{distanceToPosition(project.location)}} {{'general.units.distance.km.abbr'|translate|lowercase}}</span>
              </div>
            </div>
            <span>{{project | formatDispositionProjectDateRange}}</span>
          </div>
        </div>
      </div>
    </virtual-scroller>
  </ng-container>
</div>
