import { Pipe, PipeTransform } from '@angular/core';
import { GalleryOrderedImage } from '../../../../models/gallery-ordered-image.class';

@Pipe({
  name: 'currentGalleryImages'
})
export class CurrentGalleryImagesPipe implements PipeTransform {

  public transform(images: GalleryOrderedImage[], selectedIndex: number, infinityMove?: boolean): GalleryOrderedImage[] {
    if (!(images?.length > 0)) {
      return [];
    }

    const indexes = [selectedIndex];

    const prevIndex = selectedIndex - 1;
    if (prevIndex === -1 && infinityMove) {
      indexes.push(images.length - 1)
    } else if (prevIndex >= 0) {
      indexes.push(prevIndex);
    }

    const nextIndex = selectedIndex + 1;
    if (nextIndex === images.length && infinityMove) {
      indexes.push(0);
    } else if (nextIndex < images.length) {
      indexes.push(nextIndex);
    }

    indexes.sort();
    return indexes.map(index => images[index]);
  }

}
