import { LanguageService } from 'app/shared/services/language.service';
import { Injectable } from '@angular/core';
import { SecurityQueryProcessType } from 'app/shared/enums/security-query-process-type.enum';

@Injectable()
export class SecurityQueryProcessResolver {
  constructor(private languageService: LanguageService) { }

  public resolveDialogTitle(processType: SecurityQueryProcessType): string {
    switch (processType) {
      case SecurityQueryProcessType.CUSTOMER_DELETE:
        return this.translate('shared.securityQueryDialog.title.deleteCustomer');
      case SecurityQueryProcessType.DEFAULT:
      default:
        return this.translate('shared.securityQueryDialog.title.default');
    }
  }

  public resolveDialogMessage(processType: SecurityQueryProcessType, keyValue?: string): string {
    switch (processType) {
      case SecurityQueryProcessType.CUSTOMER_DELETE:
        return this.translate('shared.securityQueryDialog.message.deleteCustomer', { value: keyValue });
      case SecurityQueryProcessType.DEFAULT:
      default:
        return this.translate('shared.securityQueryDialog.message.default');
    }
  }

  public resolvePlaceholder(processType: SecurityQueryProcessType, keyValue?: string): string {
    switch (processType) {
      case SecurityQueryProcessType.CUSTOMER_DELETE:
        return this.translate('shared.securityQueryDialog.placeholder.deleteCustomer', { value: keyValue });
      case SecurityQueryProcessType.DEFAULT:
      default:
        return this.translate('shared.securityQueryDialog.placeholder.default', { value: keyValue });
    }
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
