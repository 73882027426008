<div class="employee-details-wrapper" fxLayout="column">

  <div class="employee-details-header" fxLayout="row" fxLayoutAlign="space-between">
    <bh-title fxFlex="100" class="title-text" text="{{'general.disposition.pl'| translate}}">
    </bh-title>
    <div class="plus-div" *ngIf="hasAuthority(authorities.EMPLOYEE_MANAGE_DISPOSITION)"
      [matTooltip]="'modules.disposition.employeeDisposition.addDisposition' |translate" (click)="assignToProject()"
      fxLayoutAlign="center">
      <fa-icon fxFlex="10" class="plus-icon" [icon]="faPlus"></fa-icon>
    </div>
  </div>
</div>
