import { environment } from 'environments/environment';
import { LanguageService } from '../../../../../../../shared/services/language.service';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { DeleteEquipmentDocumentCommand } from '../../../../../contract/delete-equipment-document-command';
import { DeleteEquipmentImageCommand } from '../../../../../contract/delete-equipment-image-command';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { SetEquipmentStandardImageCommand } from '../../../../../contract/set-equipment-standard-image-command';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { ContractDocumentLink } from '../../../../../contract/contract-document-link.interface';
import { LifeCycleDocumentLink } from '../../../../../contract/lifeCycleDocumentLink.interface';
import { LifeCycleType } from '../../../../../contract/lifecycle-type.enum';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { DocuwareDocument } from '../../../../../../../shared/contract/docuware-document.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TopfactDocument } from '../../../../../../../shared/contract/topfact-document.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectorDocument } from '../../../../../../../shared/contract/connector-document';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentViewAttachment
  extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public readonly faSortAlphaDown: IconDefinition = faSortAlphaDown;

  public images: DocumentLink[];
  public defaultImage: DocumentLink;
  public documents: DocumentLink[];
  public costDocuments: DocumentLink[];
  public contractDocuments: ContractDocumentLink[];
  public lifeCycleDamageDocuments: DocumentLink[];
  public lifeCycleMaintenanceDocuments: DocumentLink[];
  public lifeCycleIncidentDocuments: DocumentLink[];
  public docuwareDocuments: DocuwareDocument[];
  public topfactDocuments: TopfactDocument[];
  public equipment: ViewEquipment;
  public waiting: boolean;
  public image404 = 'assets/image_404.png';
  public documentsSortParameter: string;

  readonly fileName: string = 'fileName';
  readonly fileDescription: string = 'fileDescription';
  public hasDocuwareConnector = false;
  public hasTopfactConnector = false;
  public docuwareServiceAvailable = true;
  public topfactServiceAvailable = true;
  public docuwareRequestPending: boolean;
  public topfactRequestPending: Observable<boolean>;
  public pendingDocuwareDownloads: DocuwareDocument[] = [];
  public pendingTopfactDownloads: TopfactDocument[] = [];

  constructor(protected equipmentsStore: EquipmentsDataSource,
              protected equipmentsService: EquipmentsService,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected userConfigurationService: UserConfigurationService,
              protected routerHistory: RouterHistory,
              public equipmentCheckerService: EquipmentCheckerService,
              protected languageService: LanguageService,
              protected matSnackBar: MatSnackBar) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.equipmentsStore.updateEquipmentDocuwareConnectorExists();
    this.equipmentsStore.updateEquipmentTopfactConnectorExists();
    this.documentsSortParameter = this.getDocumentsSortParameter();
    this.getEquipment();
    this.checkDocuwareConnectorExistsAndGetDocuments();
    this.checkTopfactConnectorExistsAndGetDocuments();
    this.waiting = false;
  }

  public ngOnDestroy(): void {
  }

  public pendingChanges(): boolean {
    return this.waiting;
  }

  public setStandardImage(objectKey: string): void {
    this.waiting = true;
    let cmd: SetEquipmentStandardImageCommand = new SetEquipmentStandardImageCommand();
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.imageKey = objectKey;
    this.equipmentsStore.setStandardImage(cmd)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
      setTimeout(() => {
        this.waiting = false;
      }, environment.DELAY_LONGEST);
    }, error => {
      this.waiting = false;
    });
  }

  public deleteImage(objectKey: string): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.deleteImage');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        this.waiting = true;
        let cmd: DeleteEquipmentImageCommand = new DeleteEquipmentImageCommand();
        cmd.equipmentId = this.equipment.equipmentId;
        cmd.imageKey = objectKey;
        cmd.newThumbnailImageKey = objectKey === this.equipment.thumbnailKey
          ? this.getNewThumbnailKey(objectKey)
          : this.equipment.thumbnailKey;
        this.equipmentsStore.deleteImage(cmd).subscribe(() => {
          setTimeout(() => {
            this.waiting = false;
          }, environment.DELAY_LONGEST);
        });
      }
    });
  }

  public deleteDocument(objectKey: string): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.deleteDoc');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        this.waiting = true;
        let cmd: DeleteEquipmentDocumentCommand = new DeleteEquipmentDocumentCommand();
        cmd.equipmentId = this.equipment.equipmentId;
        cmd.objectKey = objectKey;
        this.equipmentsStore.deleteDocument(cmd).subscribe(() => {
          setTimeout(() => {
            this.waiting = false;
          }, environment.DELAY_SHORT);
        });
      }
    });
  }

  public getFileTypeIcon(document: DocumentLink): IconDefinition {
    return FileUtils.getAttachmentFileTypeIcon(document);
  }

  public getFileName(filename: string): string {
    return filename.substring(0, this.lastIndexOfSearchTerm(filename, '.'));
  }

  public getFileNameExtension(filename: string): string {
    return filename.substring(this.lastIndexOfSearchTerm(filename, '.'), filename.length);
  }

  public getDocumentsSortParameter(): string {
    if (this.userConfigurationService.getDocumentsSortConfiguration()) {
      return this.userConfigurationService.getDocumentsSortConfiguration().sortParameter;
    }
    return this.fileName;
  }

  public changeDocumentsSortParameter(sortParam: string): void {
    this.documentsSortParameter = sortParam;
    this.userConfigurationService.saveDocumentsSortConfiguration(sortParam);
    this.sortDocumentLinkList(this.images, sortParam);
    this.sortDocumentLinkList(this.documents, sortParam);
    this.sortDocumentLinkList(this.costDocuments, sortParam);
    this.sortDocumentLinkList(this.contractDocuments, sortParam);
    this.sortDocumentLinkList(this.lifeCycleDamageDocuments, sortParam);
    this.sortDocumentLinkList(this.lifeCycleIncidentDocuments, sortParam);
    this.sortDocumentLinkList(this.lifeCycleMaintenanceDocuments, sortParam);
    this.sortDocuwareDocumentList(this.docuwareDocuments, sortParam);
  }

  public downloadDocuwareDocument(docuwareDocument: DocuwareDocument) {
    this.pendingDocuwareDownloads.push(docuwareDocument);
    this.equipmentsStore.getDocuwareDocument(this.equipment.equipmentId, docuwareDocument.id)
      .pipe(untilDestroyed(this))
      .subscribe((blob: Blob) => {
        this.handleBlob(blob, docuwareDocument);
        const index = this.pendingDocuwareDownloads.indexOf(docuwareDocument);
        if (index > -1) {
          this.pendingDocuwareDownloads.splice(index, 1);
        }
      });
  }

  public downloadTopfactDocument(document: TopfactDocument) {
    this.pendingTopfactDownloads.push(document);
    this.equipmentsStore.getTopfactDocument(document.docId, document.fileId)
      .pipe(untilDestroyed(this))
      .subscribe((blob: Blob) => {
        this.handleBlob(blob, document);
        const index = this.pendingTopfactDownloads.indexOf(document);
        if (index > -1) {
          this.pendingTopfactDownloads.splice(index, 1);
        }
      }, () => {
        this.matSnackBar.open(
          this.translate('shared.attachment.topfactDownloadFailed'),
          this.translate('general.buttons.okay'),
          { duration: 3000 });
        const index = this.pendingTopfactDownloads.indexOf(document);
        if (index > -1) {
          this.pendingTopfactDownloads.splice(index, 1);
        }
      });
  }

  private handleBlob(blob: Blob, document: ConnectorDocument): void {
    if (blob) {
      let documentBlob = new Blob([blob], {type: this.resolveContentType(document.contentType)});
      const fileURL = URL.createObjectURL(documentBlob);
      window.open(fileURL, '_blank');
    }
  }

  private resolveContentType(contentType: string) {
    if (contentType.includes('/')) {
      return contentType;
    } else if (contentType.includes('png') || contentType.includes('jpg') || contentType.includes('jpeg')) {
      return 'image/' + contentType;
    } else if (contentType.includes('xls')) {
      return 'application/vnd.ms-excel';
    } else if (contentType.includes('xlsx')) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (contentType.includes('doc')) {
      return 'application/msword';
    } else if (contentType.includes('docx')) {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (contentType.includes('odt')) {
      return 'application/vnd.oasis.opendocument.text';
    } else if (contentType.includes('ods')) {
      return 'application/vnd.oasis.opendocument.spreadsheet';
    } else if (contentType.includes('pdf')) {
      return 'application/' + contentType;
    } else {
      return contentType;
    }
  }

  private checkDocuwareConnectorExistsAndGetDocuments() {
    this.equipmentsStore.docuwareRequestPending.subscribe(pending => this.docuwareRequestPending = pending);
    this.equipmentsStore.docuwareServiceAvailable
      .pipe(untilDestroyed(this))
      .subscribe((available: boolean) => this.docuwareServiceAvailable = available);
    combineLatest([this.equipmentsStore.currentEquipment, this.equipmentsStore.hasEquipmentDocuwareConnector])
        .pipe(untilDestroyed(this))
        .subscribe(([equipment, hasConnector]) => {
          this.hasDocuwareConnector = hasConnector;
          if (equipment && hasConnector) {
            this.equipmentsStore.getDocuwareDocuments(equipment.equipmentId)
              .pipe(untilDestroyed(this))
              .subscribe((res: DocuwareDocument[]) => {
                this.docuwareDocuments = res;
                this.sortDocuwareDocumentList(this.docuwareDocuments, this.documentsSortParameter);
              });
          }
        });
  }

  private checkTopfactConnectorExistsAndGetDocuments() {
    this.topfactRequestPending = this.equipmentsStore.topfactRequestPending;
    this.equipmentsStore.topfactDocuments
      .pipe(untilDestroyed(this))
      .subscribe((res: TopfactDocument[]) => {
        this.topfactDocuments = res;
        this.sortTopfactDocumentList(this.topfactDocuments, this.documentsSortParameter);
      });
    this.equipmentsStore.topfactServiceAvailable
      .pipe(untilDestroyed(this))
      .subscribe((available: boolean) => this.topfactServiceAvailable = available);
    combineLatest([this.equipmentsStore.currentEquipment, this.equipmentsStore.hasEquipmentTopfactConnector])
      .pipe(untilDestroyed(this))
      .subscribe(([equipment, hasConnector]) => {
        this.hasTopfactConnector = hasConnector;
        if (equipment && hasConnector) {
          this.equipmentsStore.getTopfactDocuments(equipment.equipmentId);
        }
      });
  }

  private getEquipment(): void {
    this.equipmentsStore.currentEquipment
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewEquipment) => {
        if (res) {
          this.equipment = res;
          this.getFiles(res);
          this.getCostDocuments(res);
          this.getContractDocuments(res);
          this.getLifeCycleDocuments(res);
        }
      });
  }

  private getFiles(equipment: ViewEquipment): void {
    if (equipment) {
      this.images = [];
      this.documents = [];
      this.equipmentsStore.getFiles(equipment.equipmentId)
        .pipe(untilDestroyed(this))
        .subscribe((res: DocumentLink[]) => {
          if (res) {
            this.sortDocumentLinkList(res, this.documentsSortParameter);
            const { documents, images } = FileUtils.splitDocumentLinks(res);
            this.documents = documents;
            this.images = images;
            this.defaultImage = this.images.find(doc => doc.documentKey === this.equipment.thumbnailKey);
          }
        });
    }
  }

  private getCostDocuments(equipment: ViewEquipment): void {
    if (equipment && this.hasModule(this.modules.COSTS) && this.hasAuthority(Authorities.EQUIPMENT_VIEW_COSTS)) {
      this.costDocuments = [];
      this.equipmentsStore.getInvoicesDocuments(equipment.equipmentId)
        .pipe(untilDestroyed(this))
        .subscribe((res: DocumentLink[]) => {
          this.costDocuments = res;
          this.sortDocumentLinkList(this.costDocuments, this.documentsSortParameter);
        });
    }
  }

  private getContractDocuments(equipment: ViewEquipment): void {
    if (equipment && this.hasModule(this.modules.COSTS) && this.hasAuthority(Authorities.EQUIPMENT_VIEW_CONTRACTS)) {
      this.contractDocuments = [];
      this.equipmentsStore.getContractDocuments(equipment.equipmentId)
        .pipe(untilDestroyed(this))
        .subscribe((res: ContractDocumentLink[]) => {
          this.contractDocuments = res;
          this.sortDocumentLinkList(this.contractDocuments, this.documentsSortParameter);
        });
    }
  }

  private getLifeCycleDocuments(equipment: ViewEquipment): void {
    this.equipmentsStore.getLifeCycleDocuments(equipment.equipmentId)
      .pipe(untilDestroyed(this))
      .subscribe((lifeCycleDocuments: LifeCycleDocumentLink[]) => {
        this.lifeCycleDamageDocuments = [];
        this.lifeCycleIncidentDocuments = [];
        this.lifeCycleMaintenanceDocuments = [];
        lifeCycleDocuments
          // All documents of this type are already displayed in the "Invoice" category.
          .filter(({ lifeCycleType }) => lifeCycleType !== LifeCycleType.EQUIPMENT_COST_EVENT)
          .forEach((lifeCycleDocumentLink: LifeCycleDocumentLink) => {
            switch (lifeCycleDocumentLink.lifeCycleType) {
              case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
                this.lifeCycleDamageDocuments.push(lifeCycleDocumentLink);
                break;
              case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
                this.lifeCycleIncidentDocuments.push(lifeCycleDocumentLink);
                break;
              case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
                this.lifeCycleMaintenanceDocuments.push(lifeCycleDocumentLink);
                break;
              default:
                console.log('Unhandled LifecycleDocumentType:', lifeCycleDocumentLink.lifeCycleType);
            }
          });
        this.sortDocumentLinkList(this.lifeCycleDamageDocuments, this.documentsSortParameter);
        this.sortDocumentLinkList(this.lifeCycleIncidentDocuments, this.documentsSortParameter);
        this.sortDocumentLinkList(this.lifeCycleMaintenanceDocuments, this.documentsSortParameter);
      });
  }

  private getNewThumbnailKey(currentThumbnailKey: string): string {
    let result = null;
    let index = this.images.findIndex(image => {
      return image.documentKey !== currentThumbnailKey;
    });
    if (index !== -1) {
      result = this.images[index].documentKey;
    }
    return result;
  }

  private lastIndexOfSearchTerm(filename: string, term: string): number {
    return filename.lastIndexOf(term);
  }

  private sortDocumentLinkList(documentList: DocumentLink[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.fileName.localeCompare(b.fileName);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (!a.fileDescription || a.fileDescription === '') {
          return 1;
        }
        if (!b.fileDescription || b.fileDescription === '') {
          return -1;
        }
        return a.fileDescription.localeCompare(b.fileDescription);
      });
    }
  }

  private sortDocuwareDocumentList(documentList: DocuwareDocument[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.id.toString().localeCompare(b.id);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (a.title === '') {
          return 1;
        }
        if (b.title === '') {
          return -1;
        }
        return a.title.toString().localeCompare(b.title);
      });
    }
  }

  private sortTopfactDocumentList(documentList: TopfactDocument[], sortParam: string): void {
    if (!documentList) {
      return;
    }
    if (sortParam === this.fileName) {
      documentList.sort((a, b) => {
        return a.fileId.toString().localeCompare(b.fileId);
      });
    } else if (sortParam === this.fileDescription) {
      documentList.sort((a, b) => {
        if (a.title === '') {
          return 1;
        }
        if (b.title === '') {
          return -1;
        }
        return a.title.toString().localeCompare(b.title);
      });
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
