import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bh-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent {
  public tooltip = '';
  public left = 0;
  public top = 0;
}
