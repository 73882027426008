<mat-card class="process-wrapper">
  <mat-card-content>
    <div class="process-wrapper-inner" fxLayout="row">
      <div *ngFor="let option of navigationOptions"
           class="process"
           fxFlex="1 1 {{ 100 / columns }}%"
           fxLayout="column">
        <div class="process-inner" (click)="navigateTo(option)">
          <fa-icon [icon]="navigationOptionResolver.resolveIcon(option)"></fa-icon>
          <span>{{navigationOptionResolver.resolveName(option)}}</span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

