import { OptionResolver } from 'app/shared/components/card-select/option.resolver';
import { LanguageService } from 'app/shared/services/language.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Pipe, PipeTransform } from '@angular/core';
import { ContactType } from '../../modules/contact/shared/enums/contact-type.enum';
import {
  faAddressCard,
  faBuilding,
  faDigging,
  faMapMarkedAlt,
  faQuestion,
  faShovel,
  faTrash
} from '@fortawesome/pro-duotone-svg-icons';
import { cfaTrashShovel } from 'assets/icons/custom-fa-icons';

@Pipe({
  name: 'contactType'
})
export class ContactTypeResolverPipe implements OptionResolver, PipeTransform {
  private definitionSet = {
    [ContactType.GENERIC]: {
      name: this.translate('modules.contact.base.contactType.generic'),
      icon: faAddressCard
    },
    [ContactType.COMPANY]: {
      name: this.translate('modules.contact.base.contactType.company'),
      icon: faBuilding
    },
    [ContactType.CO_CONTRACTOR]: {
      name: this.translate('modules.contact.base.contactType.coContractor'),
      icon: faDigging
    },
    [ContactType.GRAVEL_PLANT]: {
      name: this.translate('modules.contact.base.contactType.gravelPlant'),
      icon: faShovel
    },
    [ContactType.LANDFILL]: {
      name: this.translate('modules.contact.base.contactType.landfill'),
      icon: faTrash
    },
    [ContactType.LOCATION]: {
      name: this.translate('modules.contact.base.contactType.location'),
      icon: faMapMarkedAlt
    },
    [ContactType.LANDFILL_GRAVEL_PLANT]: {
      name: this.translate('modules.contact.base.contactType.landfill')
        + ' / '
        + this.translate('modules.contact.base.contactType.gravelPlant'),
      icon: cfaTrashShovel
    }
  }

  constructor(private languageService: LanguageService) { }

  public resolveName(value: string): string {
    return this.definitionSet[value] ? this.definitionSet[value].name : this.translate('general.unknown');
  }

  public resolveIcon(option: string): IconDefinition {
    return this.definitionSet[option] ? this.definitionSet[option].icon : faQuestion;
  }

  public transform(value: any): any {
    return this.resolveName(value);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
