import { LanguageService } from 'app/shared/services/language.service';
import { Directive, OnInit } from '@angular/core';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImageLink } from '../../contract/image-link.interface';
import { FileUtils } from '../../../../shared/fileUtils';
import { EquipmentMobileImageDialogComponent } from '../../equipments/equipment-mobile-image-dialog-component/equipment-mobile-image-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { DocumentLink } from '../../../../shared/contract/document-link.interface';
import { RouterHistory } from '../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../shared/components/test-place/secured-image/services/file-download.service';

@Directive()
export abstract class EquipmentAttachmentDetailsComponent extends GuardedNavigableInputComponent implements OnInit {

  protected lcDocumentsDataSource: MatTableDataSource<any>;
  protected invoiceDocumentsDataSource: MatTableDataSource<any>;
  protected invoiceImageDisplay: DocumentLink = null;
  protected imageDisplay: DocumentLink = null;
  protected displayColumns = ['fileIcon', 'fileName', 'fileDescription', 'fileType', 'actions'];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    this.lcDocumentsDataSource = new MatTableDataSource();
    this.invoiceDocumentsDataSource = new MatTableDataSource();
  }

  protected viewImage(doc: DocumentLink): void {
    this.imageDisplay = doc;
  }

  protected viewImageClose(): void {
    this.imageDisplay = null;
  }

  protected viewInvoiceImage(doc: DocumentLink): void {
    this.invoiceImageDisplay = doc;
  }

  protected viewInvoiceImageClose(): void {
    this.invoiceImageDisplay = null;
  }

  protected getFileTypeIcon(document: DocumentLink): IconDefinition {
    return FileUtils.getAttachmentFileTypeIcon(document);
  }

  protected getFileName(filename: string): string {
    return filename.substring(0, this.lastIndexOfSearchTerm(filename, '.'))
  }

  protected getFileNameExtension(filename: string): string {
    return filename.substring(this.lastIndexOfSearchTerm(filename, '.'), filename.length);
  }

  protected lastIndexOfSearchTerm(filename: string, term: string): number {
    return filename.lastIndexOf(term);
  }

  protected openFile(event: MouseEvent, image: ImageLink | DocumentLink): void {
    event.stopPropagation();

    if (FileUtils.isPicture(image)) {
      return this.openImageDialog(<ImageLink>image);
    }
    this.directDownloadDocument(<DocumentLink>image);
  }

  protected openImageDialog(image: ImageLink): void {
    event.stopPropagation();
    let dialogRef = this.dialog.open(EquipmentMobileImageDialogComponent, {
      height: '80vh',
      width: '80vw'
    });
    dialogRef.componentInstance.image = image;
  }

  protected directDownloadDocument(document: DocumentLink): void {
    this.downloadDocument(document);
  }

  protected isImageFile(document: DocumentLink): boolean {
    return FileUtils.isPicture(document);
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public downloadDocument(document: DocumentLink): void {
    this.fileDownloadService.saveFile(document);
  }
}
