import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnDefinition } from 'app/shared/column-definition';
import { ColumnService } from 'app/shared/column.service';
import { DisplayService } from 'app/shared/display.service';
import { PaginatorPageSizeOptions } from 'app/shared/paginator-page-size-options';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';

@Injectable()
export class TransferCartColumnService extends ColumnService {
  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService) {
    super(null, [], [], [],
      {
        transferCartPaginatorOptions: {
          projectViewPageSize: userConfigurationService.userConfiguration.transferCartPageSizeUserConfiguration.projectViewPageSize,
          stockViewPageSize: userConfigurationService.userConfiguration.transferCartPageSizeUserConfiguration.stockViewPageSize,
        }
      },
      displayService,
      authService);
  }

  public selectColumns(
    columns: ColumnDefinition[] = [],
    pageSize?: number,
    paginatorPageSizeOptions?: PaginatorPageSizeOptions
  ): void {
    this.userConfigurationService.saveTransferCartConfiguration(
      paginatorPageSizeOptions?.transferCartPaginatorOptions?.projectViewPageSize,
      paginatorPageSizeOptions?.transferCartPaginatorOptions?.stockViewPageSize,
    );
    super.selectColumns(columns, pageSize, paginatorPageSizeOptions);
  }
}
