export enum matomoCategories {
  ASSET_LIST = 'asset-list',
  EQUIPMENT_LIST = 'equipment-list',
  EQUIPMENT_TRANSFER_LIST = 'equipment-transfer-list',
  EQUIPMENT_STATUS = 'equipment-status',
  EQUIPMENT_INFO = 'equipment-info',
  OSP_SHOP = 'osp-shop',
  TRANSFER_LIST = 'transfer-list',
  MAINTENANCE_LIST = 'maintenance-list',
  GENERATE_REPORTING = 'generate-reporting',
  GLOBAL_TRANSFER_LOG_LIST = 'global-transfer-log-list',
  USAGE_HISTORY_LIST = 'usage-history-list',
  TELEMATIC = 'telematic',
  TELEMATIC_ASSIGN_DIALOG = 'telematic-assign-dialog',
  DIGITAL_MATTER_PROFILE = 'digital-matter-profile'
}
