import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlarmLevel } from 'app/modules/notifications/shared/enums/alarm-level.enum';
import { AlarmType } from 'app/modules/notifications/shared/enums/alarm-type.enum';

import { AlarmNotification } from './alarm-notification.interface';


@Component({
  selector: 'bh-alarm-notification',
  templateUrl: './alarm-notification.component.html',
  styleUrls: ['./alarm-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmNotificationComponent {
  @Input() alarm: AlarmNotification;
  @Output() navigateUrl = new EventEmitter<string>();
  @Output() acknowledgeAlarm = new EventEmitter<string>();

  private readonly alarmLevel = [
    {
      type: AlarmLevel.HIGH,
      color: 'red',
    }, {
      type: AlarmLevel.MID,
      color: 'yellow',
    }, {
      type: AlarmLevel.LOW,
      color: 'green',
    },
  ];

  public isGeoFenceAlarmWithProjectNameAndNumber(): boolean {
    return this.alarm.alarmType === AlarmType.GEOFENCE_VIOLATION
      && Boolean(this.alarm.projectNumber)
      && Boolean(this.alarm.projectName);
  }

  public navigate(url: string): void {
    if (url) {
      this.navigateUrl.next(url);
    }
  }

  public getAlarmLevel(type: string): any {
    return this.alarmLevel.find(level => Object.is(type, level.type));
  }

  public acknowledge($event: Event, alarmId: string): void {
    $event.stopPropagation();
    if (alarmId) {
      this.acknowledgeAlarm.next(alarmId);
    }
  }
}
