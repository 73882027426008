import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddEmployeeToTeamCommand } from '../../contract/add-employee-to-team-command';
import { CreateTeamCommand } from '../../contract/create-team-command';
import { RemoveEmployeeFromTeamCommand } from '../../contract/remove-employee-from-team-command';
import { SetTeamNameCommand } from '../../contract/set-team-name-command';
import { ViewTeam } from '../../contract/view-team.interface';
import { SetTeamLeaderCommand } from '../../contract/set-team-leader-command';
import { UnsetTeamLeaderCommand } from '../../contract/unset-team-leader-command';

@Injectable()
export class TeamService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/teams';

  constructor(private http: HttpClient) {
  }

  public getTeamById(teamId: string): Observable<ViewTeam> {
    return this.http.get<ViewTeam>(this.url + '/' + teamId);
  }

  public createTeam(command: CreateTeamCommand): Observable<string> {
    return this.http.post(this.url + '/create', command, { responseType: 'text' });
  }

  public addEmployeeToTeam(teamId: string, command: AddEmployeeToTeamCommand): Observable<string> {
    return this.http.post(this.url + '/' + teamId + '/add-employee', command, { responseType: 'text' });
  }

  public removeEmployeeFromTeam(teamId: string, command: RemoveEmployeeFromTeamCommand): Observable<string> {
    return this.http.post(this.url + '/' + teamId + '/remove-employee', command, { responseType: 'text' });
  }

  public setTeamName(teamId: string, command: SetTeamNameCommand): Observable<string> {
    return this.http.post(this.url + '/' + teamId + '/set-name', command, { responseType: 'text' });
  }

  public setTeamLeader(teamId: string, command: SetTeamLeaderCommand): Observable<string> {
    return this.http.post(this.url + '/' + teamId + '/set-leader', command, { responseType: 'text' });
  }

  public unsetTeamLeader(teamId: string, command: UnsetTeamLeaderCommand): Observable<string> {
    return this.http.post(this.url + '/' + teamId + '/unset-leader', command, { responseType: 'text' });
  }

  public getTeamNames(): Observable<string[]> {
    return this.http.get<string[]>(this.url + '/names');
  }
}
