<bh-step-dialog class="assignment-dialog" [icon]="faRouter"
                [title]="'modules.equipment.assignTelematicUnit.titleAssignmentMode'|translate">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" novalidate>
    <form [formGroup]="telematicsUnitForm">
      <mat-horizontal-stepper (selectionChange)="onStepChange($event)" labelPosition="bottom" fxFlex="100"
                              fxLayout="column" fxLayoutAlign="start stretch" linear #stepper>
        <mat-step [stepControl]="telematicsUnitTypeControl">
          <ng-template matStepLabel>{{ 'modules.equipment.assignTelematicUnit.interfaceTypeTitle'|translate }}*
          </ng-template>
          <div class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
              <bh-card-select class="type-select"
                              [options]="telematicsUnitOptions"
                              [optionResolver]="telematicsUnitResolver"
                              [formControl]="telematicsUnitTypeControl">
              </bh-card-select>
            </div>
            <div fxFlex="10" fxLayout="row" fxLayoutAlign="end center" class="button-container">
              <button mat-raised-button
                      [disabled]="telematicsUnitTypeControl.invalid"
                      (click)="getTelematicsUnitNumbers();
                      trackMatomoEvent_Telematic_SelectedUnitType();
                      trackMatomoEvent_Telematic_NextStep('general-data')"
                      matStepperNext>
                {{ 'general.buttons.continue'|translate }}
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step [formGroup]="generalDataControl" [stepControl]="generalDataControl">
          <ng-template matStepLabel>{{ 'modules.equipment.assignTelematicUnit.generalDataTitle'|translate }}*
          </ng-template>
          <div class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
            <div fxLayout="column" fxFlex="90" fxLayoutAlign="start center" class="general-data" fxLayoutGap="50px">

              <div fxFlex="50px" fxLayoutGap="50px" fxFlexOffset="20px">
                <div class="telematic-logo-container" fxFlexFill
                     *ngIf="getTelematicUnitLogo(this.telematicsUnitTypeControl.value) && !showTeltonikaOrDigitalMatterSteps(); else unitName">
                  <img class="telematic-logo" [src]="getTelematicUnitLogo(this.telematicsUnitTypeControl.value)">
                </div>
                <ng-template #unitName>
                  <span class="unit-name">{{ getTelematicUnitName(this.telematicsUnitTypeControl.value) }}</span>
                </ng-template>
              </div>

              <mat-form-field class="unit-data-field" *ngIf="showRioSteps()">
                <mat-label>{{ 'modules.equipment.telematicsUnitsList.telematicsIdentificationType'|translate }}</mat-label>
                <mat-select required formControlName="telematicsIdentificationType" data-osp-test="identification-type">
                  <mat-option *ngFor="let type of getTelematicsIdentificationTypes()"
                              [value]="type">{{ type }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="telematicsIdentificationTypeControl.hasError('required')">
                  {{ 'shared.validation.required'|translate: { value: 'modules.equipment.telematicsUnitsList.telematicsIdentificationType'|translate } }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="unit-data-field" *ngIf="!showTeltonikaOrDigitalMatterSteps()">
                <mat-label>{{ getTelematicsIdentifierLabel() }}</mat-label>
                <input matInput
                       bhRemoveWhitespaces
                       [onlySelfControlChanges]="false"
                       formControlName="telematicsUnitId"
                       [minlength]="isConfidexBeacon() ? fieldLimit.SHORTER_IDENTIFIER : 1"
                       [maxlength]="isConfidexBeacon() ? fieldLimit.SHORTER_IDENTIFIER : fieldLimit.LONG_IDENTIFIER"
                       data-osp-test="serial-number">
                <mat-error *ngIf="telematicsUnitIdControl.hasError('required')">
                  {{ 'shared.validation.required'|translate: { value: getTelematicsIdentifierLabel() } }}
                </mat-error>
                <mat-error *ngIf="telematicsUnitIdControl.hasError('pattern')">
                  {{
                    isConfidexBeacon()
                      ? ('shared.validation.string.hexDecimal'|translate: { value: 'A-F, 0-9' })
                      : ('shared.validation.string.trimSpaces'|translate)
                  }}
                </mat-error>
                <mat-error *ngIf="telematicsUnitIdControl.hasError('minlength') && isConfidexBeacon()">
                  {{ 'shared.validation.string.minLength'|translate: { value: fieldLimit.SHORTER_IDENTIFIER } }}
                </mat-error>
                <mat-error *ngIf="telematicsUnitIdControl.hasError('maxlength') && isConfidexBeacon()">
                  {{ 'shared.validation.string.maxLength'|translate: { value: fieldLimit.SHORTER_IDENTIFIER } }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="unit-data-field" *ngIf="showTeltonikaOrDigitalMatterSteps()">
                <mat-label>{{ 'modules.equipment.base.imei'|translate }}</mat-label>
                <mat-select required
                            formControlName="telematicsUnitId"
                            (selectionChange)="showTeltonikaOrDigitalMatterSteps()"
                            disableOptionCentering="true"
                            data-osp-test="select-teltonika-id">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="teltonikaIdsFilter"
                                           [placeholderLabel]="'general.labels.search'|translate:{ value: ('modules.equipment.base.imei'|translate) }"
                                           [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('modules.equipment.base.imei'|translate) }"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let id of teltonikaIds | async" [value]="id.telematicsUnitId">
                    {{ id.telematicsUnitId }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="telematicsUnitIdControl.hasError('required')"
                           data-osp-test="error-required-telematic-unit-id">
                  {{ 'shared.validation.required'|translate: { value: 'modules.equipment.base.imei'|translate } }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="button-container" fxLayout="row" fxFlex="10"
                 fxLayoutGap="15px" fxLayoutAlign="space-between center">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious (click)="trackMatomoEvent_Telematic_StepBack('unit-type-selection')">
                  {{ 'general.buttons.back'|translate }}
                </button>
              </div>
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button *ngIf="!showTeltonikaOrDigitalMatterSteps()" mat-raised-button
                        color="primary"
                        bhTimeoutSaveButton
                        #timeoutButton="bhTimeoutSaveButton"
                        [throttleTime]="buttonThrottle"
                        (throttledClick)="save()"
                        [disabled]="telematicsUnitForm.invalid || (timeoutButton.throttleOn | async)">
                  {{ 'general.buttons.assign'|translate }}
                </button>
                <button *ngIf="showTeltonikaSteps()"
                        mat-raised-button
                        matStepperNext
                        [disabled]="telematicsUnitIdControl.invalid"
                        (click)="fetchClearData();
                        trackMatomoEvent_Telematic_NextStep('equipment-data')">
                  {{ 'general.buttons.continue'|translate }}
                </button>

                <button *ngIf="showDigitalMatterSteps()"
                        mat-raised-button
                        matStepperNext
                        [disabled]="telematicsUnitIdControl.invalid"
                        (click)="trackMatomoEvent_Telematic_NextStep('profile-selection')">
                  {{ 'general.buttons.continue'|translate }}
                </button>

              </div>
            </div>
          </div>
        </mat-step>


        <mat-step *ngIf="showDigitalMatterSteps()" [formGroup]="profileDataControl" [stepControl]="profileDataControl">

          <ng-template matStepLabel>{{ 'modules.equipment.assignTelematicUnit.profileDataLabel'|translate }}
          </ng-template>

          <div class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
            <div fxLayout="column" fxFlex="90" fxLayoutAlign="start center" class="general-data" fxLayoutGap="50px">

              <div fxFlex="50px" fxLayoutGap="50px" fxFlexOffset="20px">
                <div class="telematic-logo-container" fxFlexFill
                     *ngIf="getTelematicUnitLogo(this.telematicsUnitTypeControl.value) && !showTeltonikaOrDigitalMatterSteps(); else unitName">
                  <img class="telematic-logo" [src]="getTelematicUnitLogo(this.telematicsUnitTypeControl.value)">
                </div>
                <ng-template #unitName>
                  <span class="unit-name">{{ getTelematicUnitName(this.telematicsUnitTypeControl.value) }}</span>
                </ng-template>
              </div>

              <div class="profile-form">
                <mat-radio-group required formControlName="profileId" data-osp-test="profile-id">
                  <div class="grid">
                    <ng-container *ngFor="let profile of digitalMatterProfiles">
                      <div>
                        <mat-radio-button [value]="profile.profileId" color="primary">
                          <h2>{{ profile.translations.get(selectedLanguage).displayName }}</h2>
                        </mat-radio-button>

                        <div>{{ profile.translations.get(selectedLanguage).description }}</div>
                      </div>

                    </ng-container>
                  </div>
                </mat-radio-group>
              </div>
            </div>

            <div class="button-container" fxLayout="row" fxFlex="10"
                 fxLayoutGap="15px" fxLayoutAlign="space-between center">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious (click)="trackMatomoEvent_Telematic_StepBack('general-data')">
                  {{ 'general.buttons.back'|translate }}
                </button>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <p>
                  {{ 'modules.equipment.assignTelematicUnit.delayProfileAssignNote'|translate }}
                </p>
              </div>

              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button *ngIf="!showTeltonikaOrDigitalMatterSteps()" mat-raised-button
                        color="primary"
                        bhTimeoutSaveButton
                        #timeoutButton="bhTimeoutSaveButton"
                        [throttleTime]="buttonThrottle"
                        (throttledClick)="save()"
                        [disabled]="telematicsUnitForm.invalid || (timeoutButton.throttleOn | async)">
                  {{ 'general.buttons.assign'|translate }}
                </button>
                <button *ngIf="showTeltonikaOrDigitalMatterSteps()"
                        mat-raised-button
                        matStepperNext
                        [disabled]="telematicsUnitIdControl.invalid"
                        (click)="fetchClearData(); trackMatomoEvent_Telematic_ProfileSelection();
                        trackMatomoEvent_Telematic_NextStep('equipment-data')">
                  {{ 'general.buttons.continue'|translate }}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <mat-step *ngIf="showTeltonikaOrDigitalMatterSteps()"
                  [stepControl]="telematicsUnitForm.get('equipmentData')"
                  [formGroup]="telematicsUnitForm.get('equipmentData')">

          <ng-template matStepLabel>{{ 'modules.equipment.base.equipmData'|translate }}</ng-template>

          <div class="equipment-stepper-content equipment-live-preview"
               fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
              <div fxFlex="auto" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
                  <div class="field-wrapper" fxFlex="50%">
                    <bh-text-input
                      [control]="$any(equipmentDataControl.get('lastUnitData'))"
                      [label]="'modules.equipment.assignTelematicUnit.lastUnitData'|translate">
                    </bh-text-input>
                  </div>

                  <div fxFlex="50%" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
                    <div class="field-wrapper" fxFlex="auto">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('lastGPSFix'))"
                        [label]="'modules.equipment.assignTelematicUnit.lastGPSFix'|translate">
                      </bh-text-input>
                    </div>

                    <div fxFlex="50px">
                      <button mat-raised-button class="refresh-data-button"
                              (click)="fetchClearData()"
                              [disabled]="isTelematicLoading()"
                              [matTooltip]="getRefreshButtonTooltip()">
                        <mat-icon *ngIf="isTelematicReady()">cached</mat-icon>
                        <mat-icon *ngIf="isTelematicLoading()" class="loading">cached</mat-icon>
                        <mat-icon *ngIf="isTelematicError()" class="error">warning</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
                  <div class="field-wrapper" fxFlex="auto">
                    <bh-text-input
                      [control]="$any(equipmentDataControl.get('mobileSignal'))"
                      [label]="'modules.equipment.assignTelematicUnit.mobileSignal'|translate">
                    </bh-text-input>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
                  <div fxFlex="50%" fxLayout="column">
                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('gpsSignal'))"
                        [label]="'modules.equipment.assignTelematicUnit.gpsSignal'|translate">
                      </bh-text-input>
                    </div>

                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('powerVoltage'))"
                        [label]="'modules.equipment.assignTelematicUnit.powerVoltage'|translate">
                      </bh-text-input>
                    </div>

                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('digitalInputOne'))"
                        [label]="'modules.equipment.assignTelematicUnit.digitalInputOne'|translate">
                      </bh-text-input>
                    </div>

                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('engineOperatingHours'))"
                        [label]="'modules.equipment.assignTelematicUnit.engineOperatingHours'|translate">
                      </bh-text-input>
                    </div>
                  </div>

                  <div class="field-wrapper" fxFlex="50%" *ngIf="showTeltonikaSteps()">
                    <div class="field-wrapper">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('satellites'))"
                        [label]="'modules.equipment.assignTelematicUnit.satellites'|translate">
                      </bh-text-input>
                    </div>

                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('batteryVoltage'))"
                        [label]="'modules.equipment.assignTelematicUnit.batteryVoltage'|translate">
                      </bh-text-input>
                    </div>

                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('digitalInputTwo'))"
                        [label]="'modules.equipment.assignTelematicUnit.digitalInputTwo'|translate">
                      </bh-text-input>
                    </div>

                    <div class="field-wrapper" *ngIf="showTeltonikaSteps()">
                      <bh-text-input
                        [control]="$any(equipmentDataControl.get('currentStateMileage'))"
                        label="{{'modules.equipment.assignTelematicUnit.currentStateMileage'|translate}} ({{'km'|dimensionUnitDisplay}})">
                      </bh-text-input>
                    </div>
                  </div>
                </div>
              </div>

              <bh-map-locations
                fxFlex="350px"
                fxLayout="column"
                class="teltonika__map-address"
                [locations]="locationsForMap">
              </bh-map-locations>
            </div>

            <div class="button-container" fxLayout="row" fxFlex="10"
                 fxLayoutGap="15px" fxLayoutAlign="space-between center">
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <button mat-raised-button matStepperPrevious (click)="trackMatomoEvent_Telematic_StepBack(showDigitalMatterSteps() ? 'profile-selection' : 'general-data')">
                  {{ 'general.buttons.back'|translate }}
                </button>
              </div>
              <div class="button-wrapper" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button *ngIf="!showTeltonikaOrDigitalMatterSteps()" mat-raised-button
                        color="primary"
                        bhTimeoutSaveButton
                        #timeoutButton="bhTimeoutSaveButton"
                        [throttleTime]="buttonThrottle"
                        (throttledClick)="saveDigitalMatter()"
                        [disabled]="telematicsUnitForm.invalid || (timeoutButton.throttleOn | async)">
                  {{ 'general.buttons.assign'|translate }}
                </button>

                <button *ngIf="!showTeltonikaSteps()" mat-raised-button
                        color="primary"
                        bhTimeoutSaveButton
                        #timeoutButton="bhTimeoutSaveButton"
                        [throttleTime]="buttonThrottle"
                        (throttledClick)="showTeltonikaOrDigitalMatterSteps() ? saveDigitalMatter() : save()"
                        [disabled]="telematicsUnitForm.invalid || (timeoutButton.throttleOn | async)">
                  {{ 'general.buttons.assign' | translate }}
                </button>
                <button
                  *ngIf="showTeltonikaSteps()"
                  mat-raised-button
                  matStepperNext
                  (click)="trackMatomoEvent_Telematic_NextStep('calibrate')">
                  {{ 'general.buttons.continue'|translate }}
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <mat-step *ngIf="showTeltonikaSteps()" [stepControl]="telematicsUnitForm.get('calibrateData')"
                  [formGroup]="telematicsUnitForm.get('calibrateData')">
          <ng-template matStepLabel>{{ 'modules.equipment.assignTelematicUnit.calibrateUnit'|translate }}*</ng-template>

          <ng-container *ngIf="!calibrating">
            <div class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
              <div fxLayout="column" fxFlex="90" fxLayoutAlign="start center" class="general-data" fxLayoutGap="50px">

                <div fxFlex="50px" fxFlexOffset="20px">
                  <div>
                    <h2 fxFlexFill fxLayoutAlign="center center" class="mat-dialog-title">
                      {{ 'modules.equipment.assignTelematicUnit.calibrateUnitTitle'|translate }}
                    </h2>
                    <span fxFlexFill class="small-text" fxLayoutAlign="center center">
                    {{ 'modules.equipment.assignTelematicUnit.calibrateUnitDescription'|translate }}
                  </span>
                  </div>
                  <div fxLayoutAlign="space-between start">
                    <mat-form-field fxFlex="40">
                      <mat-label>{{ 'general.operatingHour.pl'|translate }}</mat-label>
                      <input matInput
                             formControlName="operatingHours"
                             type="number"
                             [maxlength]="fieldLimit.SHORT_IDENTIFIER">
                      <mat-error *ngIf="calibrateDataControl.get('operatingHours').hasError('required')">
                        {{ 'shared.validation.requiredField'|translate }}
                      </mat-error>
                      <mat-error *ngIf="calibrateDataControl.get('operatingHours').hasError('min')">
                        {{ 'shared.validation.number.notNegative'|translate }}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40">
                      <mat-label>{{ 'general.mileage'|translate }} ({{ 'km'|dimensionUnitDisplay }})</mat-label>
                      <input matInput
                             formControlName="mileAge"
                             type="number"
                             [maxlength]="fieldLimit.LONG_IDENTIFIER">
                      <mat-error *ngIf="calibrateDataControl.get('mileAge').hasError('required')">
                        {{ 'shared.validation.requiredField'|translate }}
                      </mat-error>
                      <mat-error *ngIf="calibrateDataControl.get('mileAge').hasError('min')">
                        {{ 'shared.validation.number.notNegative'|translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="button-container" fxLayout="row" fxFlex="10"
                   fxLayoutGap="15px" fxLayoutAlign="space-between center">
                <div class="button-wrapper" fxLayout="row" xLayoutAlign="start center" fxLayoutGap="15px">
                  <button mat-raised-button matStepperPrevious (click)="trackMatomoEvent_Telematic_StepBack('equipment-data')">
                    {{ 'general.buttons.back'|translate }}
                  </button>
                </div>
                <div class="button-wrapper" fxLayout="row" xLayoutAlign="end center" fxLayoutGap="15px">
                  <button mat-raised-button
                          color="primary"
                          bhTimeoutSaveButton
                          #timeoutButton="bhTimeoutSaveButton"
                          [throttleTime]="buttonThrottle"
                          (throttledClick)="save()"
                          [disabled]="telematicsUnitForm.invalid || (timeoutButton.throttleOn | async)">
                    {{ 'general.buttons.finish'|translate }}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="calibrating">
            <div class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
              <div fxLayout="column" fxFlex="100" fxLayoutAlign="start center" class="general-data" fxLayoutGap="50px">
                <div fxFlex="40px" fxLayoutGap="20px">
                  <div fxLayoutAlign="center center">
                    <h2 mat-dialog-title>
                      {{ 'modules.equipment.equipmentView.calibratingTelematicUnit' | translate }}
                    </h2>
                  </div>
                  <div fxLayoutAlign="center center">
                    <span class="small-text">
                      {{ 'modules.equipment.equipmentView.calibratingTelematicUnitHint' | translate }}
                    </span>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">
                  <mat-spinner [strokeWidth]="4" [diameter]="50"></mat-spinner>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</bh-step-dialog>
