import { ProjectToStockTransferItem } from './project-to-stock-transfer-item';
import { MobileTransferItem } from './mobile-transfer-item';
import { TransferItemType } from '../shared/transfer/model/transfer-item-type.enum';

export class ProjectToConsumedTransferItem {

  public static fromProjectToStockTransferItem(item: ProjectToStockTransferItem) {
    return new ProjectToConsumedTransferItem(
        item.sourceStockId,
        item.transferItemId,
        item.amount,
        item.sourceProjectId,
        item.transferItemType
    );
  }

  public static fromMobileTransferItem(item: MobileTransferItem) {
    return new ProjectToConsumedTransferItem(
      item.sourceStockId,
      item.transferId,
      item.amount,
      item.sourceProjectId,
      TransferItemType.BULKITEM
    );
  }

  private constructor(
      public sourceStockId: string,
      public transferItemId: string,
      public amount: number,
      public sourceProjectId: string,
      public transferItemType: TransferItemType
  ) {}
}
