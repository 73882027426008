import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersService } from '../../shared/customers.service'
import { ViewCustomer } from '../../contract/view-customer.interface';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { Modules } from '../../../../shared/enums/modules.enum';
import { UpdateCustomerModulesCommand } from '../../contract/update-customer-modules-command';
import { RouterHistory } from '../../../../shared/router-history';
import { PartnersService } from '../../shared/partners.service';
import { ViewPartner } from '../../contract/view-partner.interface';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from 'app/shared/services/language.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';

@Component({
  selector: 'bh-customer-modules-edit',
  templateUrl: './customer-modules-edit.component.html',
  styleUrls: ['./customer-modules-edit.component.scss']
})
export class CustomerModulesEditComponent extends GuardedNavigableInputComponent implements OnInit {
  public modulesList: Module[] = [];
  private modulesEnum = Modules;
  private customer: ViewCustomer;
  private partner: ViewPartner;

  private get automatedTransfersMod(): Module {
    return this.modulesList.find(el => el.name === Modules.AUTOMATED_TRANSFERS);
  }

  constructor(protected router: Router,
              private customerService: CustomersService,
              private partnersService: PartnersService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              private languageService: LanguageService,
  ) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.getCustomer();
  }

  public navigateBack(): void {
    this.router.navigate([`/customers/list`]);
  }

  public save() {
    let modules = this.modulesList.reduce((acc, mod) => {
      if (mod.isSelected) {
        acc.push(mod.name)
      }
      return acc;
    }, []);

    let cmd = new UpdateCustomerModulesCommand();
    cmd.customerId = this.customer.customerId;
    cmd.modules = modules;
    this.customerService.updateModules(cmd).subscribe(
      () => {
        this.navigateAfterSubmit();
      },
      (error: HttpErrorResponse) => {
        console.log('edit customer error: ', error);
      }
    );
  }

  public selectModules(mod: Module) {
    // cannot remove "BASE" module
    if (this.isNotBaseOrDisabledModule(mod)) {
      if (mod.name === Modules.LIVE_DATA) {
        this.changeLiveDataModuleDependencies(mod);
      } else {
        mod.isSelected = !mod.isSelected;
      }
    }
  }

  private isNotBaseOrDisabledModule(mod: Module): boolean {
    return mod.name !== Modules.BASE.toString() && !mod.disabled;
  }

  private changeLiveDataModuleDependencies(mod: Module): void {
    if (mod.isSelected) {
      if (this.automatedTransfersMod?.isSelected) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent);
        dialogRef.componentInstance.confirmMessage = this.translate('modules.organisation.customerModulesEdit.disableAllDependentModules');
        dialogRef.afterClosed().subscribe(res => {
          if(res === dialogResults.YES) {
            mod.isSelected = !mod.isSelected;
            this.disableAutomatedTransfersModule(this.automatedTransfersMod);
          }
        })
      } else {
        mod.isSelected = !mod.isSelected;
        this.disableAutomatedTransfersModule(this.automatedTransfersMod);
      }
    } else {
      mod.isSelected = !mod.isSelected;
      this.enableAutomatedTransfersModule(this.automatedTransfersMod);
    }
  }

  private disableAutomatedTransfersModule(mod: Module): void {
    mod.isSelected = false;
    mod.disabled = true;
    mod.tooltip = this.translate(
      'modules.organisation.customerModulesEdit.blockedByModule',
      {value: Modules.LIVE_DATA},
    );
  }

  private enableAutomatedTransfersModule(mod: Module): void {
    mod.isSelected = false;
    mod.disabled = false;
    mod.tooltip = '';
  }

  private getCustomer() {
    this.route.params.subscribe(params => {
      const customerId = params['id'];
      this.customerService.getCustomer(customerId).subscribe(
          data => {
            this.customer = data;
            this.getPartner();
          },
          error => {
            console.log(error);
          }
      );
    });
  }

  private getPartner() {
    this.partnersService.getPartner(this.customer.partnerId)
      .subscribe(partner => {
        this.partner = partner;
        this.setDefaultModulesList();
        this.setModulesProperties();
      })
  }

  private setDefaultModulesList(): void {
    const modules = Object.keys(this.modulesEnum);
    modules.forEach(name => {
      let isSelected = this.isEnabledModuleForCustomer(name);
      let isAvailable = this.isAllowedModuleByPartner(name);
      if (isAvailable || isSelected) {
        this.modulesList.push(this.setDefaultModule(name, isSelected, isAvailable))
      }
    });
  }

  private setDefaultModule(name: string, isSelected: boolean, isAvailable: boolean): Module {
    return {
      name,
      isSelected,
      isAvailable,
      disabled: false,
      tooltip: '',
    }
  }

  private setModulesProperties(): void {
    this.modulesList.forEach(mod => {
      if (mod.name === this.modules.BASE) {
        mod.tooltip = this.translate('modules.organisation.customerModulesEdit.baseModuleMustSelected');
      } else if(!mod.isAvailable) {
        mod.tooltip = this.translate('modules.organisation.customerModulesEdit.moduleDisabledByPartner');
      }

      if (mod.name === Modules.LIVE_DATA && !mod.isSelected) {
        this.disableAutomatedTransfersModule(this.automatedTransfersMod);
      }
    });
  }

  private isAllowedModuleByPartner(name: string): boolean {
    return this.partner.modules.includes(name);
  }

  private isEnabledModuleForCustomer(name: string): boolean {
    return this.customer.modules.includes(name);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}

interface Module {
  name: string,
  isSelected: boolean,
  isAvailable: boolean,
  disabled: boolean,
  tooltip: string,
}
