import { Subscription } from 'rxjs';

export class SubscriptionCollector {
  private subscriptions: Subscription[] = [];

  public set newSubscription (value: Subscription) {
    this.subscriptions.push(value);
  }

  public unsubscribeAll(): void {
    this.subscriptions.forEach(s => s?.unsubscribe && s.unsubscribe());
    this.subscriptions = [];
  }
}
