import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeycloakService } from '../keycloak';
import { Authorities } from '../../shared/enums/authorities.enum';

@Injectable()
export class CanActivateChildAuthority implements CanActivateChild {
  constructor(private authService: KeycloakService,
              private router: Router) {
  }

  private checkAuthorities(authorities: Array<Authorities>): boolean {
    return this.noAuthRequired(authorities) || authorities.some((authority: Authorities) => {
      return this.authService.hasAuthority(authority);
    });
  }

  private noAuthRequired(authorities: Array<Authorities>): boolean {
    return !authorities.length;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;

    if (!childRoute.data.hasOwnProperty('authorities')) {
      throw Error('Route has no authorities property');
    } else {
      let authorities: Array<Authorities> = childRoute.data['authorities'];
      if (authorities instanceof Array) {
        if (this.checkAuthorities(authorities)) {
          canActivate = true;
        } else {
          canActivate = false;
        }
      } else {
        throw Error('authorities property is not an array');
      }
    }

    if (childRoute.data.hasOwnProperty('authoritiesDisallowed')) {
      let disallowedAuthorities: Array<Authorities> = childRoute.data['authoritiesDisallowed'];
      if (disallowedAuthorities instanceof Array) {
        if (this.checkAuthorities(disallowedAuthorities)) {
          canActivate = false;
        } else {
          canActivate =  true;
        }
      } else {
        throw Error('authorities property is not an array');
      }
    }

    if (!canActivate) {
      this.router.navigate(['/landingpage']);
    }
    return canActivate;
  }
}
