import { BehaviorSubject, Observable } from 'rxjs';
import { ListType } from './enums/list-type.enum';
import { TabNavigation } from './contract/tab-navigation';
import { Injectable } from '@angular/core';
import { KeycloakService } from '../core/keycloak';
import { Authorities } from './enums/authorities.enum';
import { Modules } from './enums/modules.enum';
import { RouterHistory } from './router-history';

@Injectable()
export class TabService {

  private assetNavigationTabs: TabNavigation[] = [
    {
      type: ListType.EQUIPMENTS,
      urlFragment: 'equipment'
    },
    {
      type: ListType.BULK_ITEMS,
      urlFragment: 'bulk-item'
    }
  ];
  private siteNavigationTabs: TabNavigation[] = [
    {
      type: ListType.STOCKS,
      urlFragment: 'stocks'
    },
    {
      type: ListType.PROJECTS,
      urlFragment: 'projects'
    }
  ];
  private staffNavigationTabs: TabNavigation[] = [
    {
      type: ListType.EMPLOYEES,
      urlFragment: 'employee'
    }
  ];
  private transferNavigationTabs: TabNavigation[] = [
    {
      type: ListType.EQUIPMENT_TRANSFER,
      urlFragment: 'equipment-transfer-list'
    },
    {
      type: ListType.BULK_ITEM_TRANSFER,
      urlFragment: 'bulk-item-transfer-list'
    }
  ];
  private contactNavigationTabs: TabNavigation[] = [
    {
      type: ListType.CONTACTS,
      urlFragment: 'contact'
    }
  ];
  private maintenanceNavigationTabs: TabNavigation[] = [
    {
      type: ListType.MAINTENANCE_TASKS_CURRENT,
      urlFragment: 'maintenance-list'
    },
    {
      type: ListType.MAINTENANCE_TASKS_COMPLETED,
      urlFragment: 'maintenance-completed-list'
    },
  ];

  private lastAssetTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private lastSiteTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private lastStaffTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private lastTransferTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _views: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private tabs: Map<ListType, string> = new Map();
  public readonly views: Observable<string[]> = this._views as Observable<string[]>;
  private _availableTabs = new BehaviorSubject<string[]>([]);
  public readonly availableTabs: Observable<string[]> = this._availableTabs.asObservable();

  constructor(protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    this.assetNavigationTabs.concat(
      this.siteNavigationTabs,
      this.staffNavigationTabs,
      this.transferNavigationTabs,
      this.contactNavigationTabs)
      .forEach(navTab => this.tabs.set(navTab.type, navTab.urlFragment));
    this.changeSiteTab(
      authService.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])
        ? ListType.PROJECTS : ListType.STOCKS);
    this.changeStaffTab(ListType.EMPLOYEES);
    this.changeTransferTab(authService.hasModule(Modules.DISPOSITION)
      ? ListType.EQUIPMENT_TRANSFER : ListType.BULK_ITEM_TRANSFER);
    this.setTabs();
  }

  public getLastAssetTab(): string {
    return this.lastAssetTab.value;
  }

  public changeAssetTab(tab: ListType): void {
    this.lastAssetTab.next(this.tabs.get(tab));
  }

  public getLastSiteTab(): string {
    return this.lastSiteTab.value;
  }

  public changeSiteTab(tab: ListType): void {
    this.lastSiteTab.next(this.tabs.get(tab));
  }

  public changeStaffTab(tab: ListType): void {
    this.lastStaffTab.next(this.tabs.get(tab));
  }

  public getLastTransferTab(): string {
    return this.lastTransferTab.value;
  }

  public changeTransferTab(tab: ListType): void {
    this.lastTransferTab.next(this.tabs.get(tab));
  }

  private setTabs() {
    let views = []
    if (this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW,
      Authorities.PROJECT_ASSIGNEE_VIEW])) {
      views.push(ListType.EQUIPMENTS);
    }
    if (this.authService.hasAuthority(Authorities.BULK_ITEMS_VIEW) &&
      this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      views.push(ListType.BULK_ITEMS);
    }
    if (this.authService.hasAuthority(Authorities.EMPLOYEE_VIEW) &&
        this.authService.hasModule(Modules.STAFF_MANAGEMENT)) {
      views.push(ListType.EMPLOYEES);
    }
    if (this.authService.hasAnyAuthority([Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]) &&
      this.authService.hasModule(Modules.CONTACT_MANAGEMENT)) {
      views.push(ListType.CONTACTS);
    }
    if (this.authService.hasAnyAuthority([Authorities.PROJECT_VIEW,
      Authorities.PROJECT_ASSIGNEE_VIEW])) {
      views.push(ListType.PROJECTS);
    }
    if (this.authService.hasAuthority(Authorities.STOCK_VIEW) &&
      this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      views.push(ListType.STOCKS);
    }
    if (this.authService.hasAnyAuthority([Authorities.ASSET_FROM_PROJECT_TRANSFER,
      Authorities.ASSET_FROM_STOCK_TRANSFER]) && this.authService.hasModule(Modules.DISPOSITION)) {
      views.push(ListType.EQUIPMENT_TRANSFER);
    }
    if (this.authService.hasAnyAuthority([Authorities.ASSET_FROM_PROJECT_TRANSFER,
      Authorities.ASSET_FROM_STOCK_TRANSFER]) && this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT)) {
      views.push(ListType.BULK_ITEM_TRANSFER);
    }
    if (this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_VIEW) && this.authService.hasModule(Modules.SERVICE)) {
      views.push(ListType.MAINTENANCE_TASKS_CURRENT, ListType.MAINTENANCE_TASKS_COMPLETED);
    }
    if (this.authService.hasModule(Modules.WIP_GLOBAL_TRANSFER_LOG)
      && this.authService.hasAuthority(Authorities.GLOBAL_TRANSFER_LOG_VIEW)) {
      views.push(ListType.ANALYTICS_TRANSFER_LOG);
    }
    if (this.authService.hasModule(Modules.EQUIPMENT_USAGE_HISTORY)
      && this.authService.hasAuthority(Authorities.EQUIPMENT_USAGE_HISTORY_VIEW)) {
      views.push(ListType.ANALYTICS_USAGE_HISTORY);
  }

    this._views.next(views);
  }

  public setAvailableTabs(url: string): void {
    let availableViews: string[] = [];

    this._views.value.forEach(view => {
      if ((url.includes('assets') &&
          (view === ListType.EQUIPMENTS || view === ListType.BULK_ITEMS ||
            view === ListType.EMPLOYEES || view === ListType.CONTACTS)) ||

        (url.includes('sites') &&
          (view === ListType.PROJECTS || view === ListType.STOCKS)) ||

        ((url.includes('equipment-transfer') ||
            url.includes('bulk-item-transfer')) &&
          (view === ListType.EQUIPMENT_TRANSFER || view === ListType.BULK_ITEM_TRANSFER)) ||

        (url.includes('maintenance') &&
          (view === ListType.MAINTENANCE_TASKS_CURRENT || view === ListType.MAINTENANCE_TASKS_COMPLETED)) ||

        (url.includes('analytics') &&
          (view === ListType.ANALYTICS_TRANSFER_LOG || view === ListType.ANALYTICS_USAGE_HISTORY))
      ) {
        availableViews.push(view);
      }
    });
    this._availableTabs.next(availableViews);
  }
}
