export enum IconPosition {
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

interface MapIconArg {
  url?: string;
  size?: number;
  position?: IconPosition;
}

export class MapIcon {
  public url: string;
  public anchor: { x: number, y: number };
  public scaledSize: { width: number, height: number };
  public size: { width: number, height: number };

  constructor({ url, size, position }: MapIconArg = {}) {
    this.url = url;
    position = position || IconPosition.BOTTOM;
    size = (size && size > 0) ? size : 40;
    this.size = { width: size, height: size };
    this.scaledSize = { ...this.size };
    switch (position) {
      case IconPosition.CENTER:
        this.anchor = {x: size / 2, y: size / 2};
        break;
      case IconPosition.BOTTOM:
        this.anchor = {x: size / 2, y: size};
        break;
    }
  }

  public static isMapIcon(value: unknown): value is MapIcon {
    return Boolean(
      value instanceof MapIcon ||
      (value && typeof value === 'object' && 'url' in value)
    );
  }
}
