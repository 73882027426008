import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DragStatus } from '../contract/drag-item-state.interface';
import { DragOverPayload } from '../contract/drag-over-payload.interface';
import { DragItemState } from '../enums/drag-item-state.enum';
import { DraggableItemType } from '../enums/draggable-item-type.enum';
import { ListType } from '../enums/list-type.enum';

@Injectable()
export class DragAndDropService {
  private drag = new BehaviorSubject<DragStatus>(null);
  public readonly dragStatus = this.drag.asObservable();

  private dragOverContainer = new BehaviorSubject<DragOverPayload>(null);
  public dragOver = this.dragOverContainer.asObservable();

  private activeListType = new BehaviorSubject<ListType>(ListType.NONE);
  public activeListTypeChanges = this.activeListType.asObservable();

  private currentDragging: DragStatus;

  public changeListType(listType: ListType): void {
    this.activeListType.next(listType);
  }

  public dragStart(type: DraggableItemType): void {
    this.emitDragEvent(type, DragItemState.START);
    this.setDraggingStyles();
  }

  public dragEnd(): void {
    if (this.currentDragging.state !== DragItemState.CANCELED) {
      this.emitDragEvent(this.currentDragging.type, DragItemState.END);
      this.removeDraggingStyles();
    }
  }

  public dragCancel(): void {
    if (this.currentDragging) {
      this.emitDragEvent(this.currentDragging.type, DragItemState.CANCELED);
      this.removeDraggingStyles();
    }
  }

  public emitDragEvent(type: DraggableItemType, state: DragItemState): void {
    this.currentDragging = { type, state };
    this.drag.next(this.currentDragging);
  }

  public emitDragOver(payload: DragOverPayload): void {
    this.dragOverContainer.next(payload);
  }

  private setDraggingStyles(): void {
    document.body.classList.add('dragging');
  }

  private removeDraggingStyles(): void {
    document.body.classList.remove('dragging');
  }
}
