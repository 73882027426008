export class MediatorBaseService<T> {
  private receivers = new Set<T>();

  public addReceiver(receiver: T): void {
    this.receivers.add(receiver);
  }

  protected processNotification(
    sender: any,
    receiverCb: (receiver: T) => void
  ): void {
    this.receivers.forEach(receiver => {
      if (sender !== receiver) {
        receiverCb(receiver);
      }
    });
  }
}
