import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { EquipmentTask } from '../contract/equipment-task';
import { MaintenanceService } from './maintenance.service';
import { CreateReminderCommand } from '../contract/create-reminder-command';
import { DeleteReminderCommand } from '../contract/delete-reminder-command';
import { DataSource } from '@angular/cdk/table';
import { delay, map } from 'rxjs/operators';
import { UnassignMaintenanceRuleCommand } from '../../maintenance/rules/shared/contract/commands/unassign-maintenance-rule.command';
import { Pageable } from '../../../shared/contract/task/pageable.interface';
import { PageEvent } from '@angular/material/paginator';
import { Task } from '../../../shared/contract/task/task';
import { CompleteReminderCommand } from '../contract/complete-reminder-command';
import { MaintenanceDatasourceMediatorService } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator.service';

@Injectable()
export class MaintenanceTaskDataSource extends DataSource<Task> implements Pageable {

  private _equipmentId: string;
  private tasks: BehaviorSubject<EquipmentTask[]> = new BehaviorSubject([]);
  private index: BehaviorSubject<number> = new BehaviorSubject(0);
  private size: BehaviorSubject<number> = new BehaviorSubject(25);
  private length: BehaviorSubject<number> = new BehaviorSubject(0);
  private _isLoading = new BehaviorSubject<boolean>(false);

  public readonly isLoading = this._isLoading.asObservable();

  public get equipmentId(): string {
    return this._equipmentId;
  }

  public set equipmentId(id: string) {
    this._equipmentId = id;
  }

  constructor(
    private maintenanceService: MaintenanceService,
    private maintenanceMediator: MaintenanceDatasourceMediatorService,
  ) {
    super();
  }

  connect(): Observable<EquipmentTask[]> {
    return this.tasks;
  }

  disconnect() {
  }

  getTasksForEquipment(equipmentId: string): Observable<EquipmentTask[]> {
    return this.tasks
    .pipe(map((tasks: EquipmentTask[]) =>
        tasks.filter(task => task.equipmentId === equipmentId)));
  }

  loadTasksForEquipment(equipmentId: string): void {
    this._isLoading.next(true);
    this.equipmentId = equipmentId;
    this.maintenanceService
    .getTasksForEquipment(equipmentId, this.index.value, this.size.value)
    .subscribe((res: PagedResponse<EquipmentTask>) => {
      this.length.next(res.totalElements);
      this.tasks.next(res.content);
      this._isLoading.next(false);
    });
  }

  createReminder(cmd: CreateReminderCommand, equipmentId: string): void {
    this.maintenanceService
    .createReminder(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.loadTasksForEquipment(equipmentId));
  }

  completeReminder(cmd: CompleteReminderCommand): void {
    this.maintenanceService
    .completeReminder(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => {
      this.maintenanceMediator.notifyTaskCompleted(this, cmd.reminderId);
      this.loadTasksForEquipment(cmd.equipmentId);
    });
  }

  deleteReminder(cmd: DeleteReminderCommand, equipmentId: string): void {
    this.maintenanceService
    .deleteReminder(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => {
      this.maintenanceMediator.notifyTaskDeleted(this, cmd.reminderId);
      this.loadTasksForEquipment(equipmentId)
    });
  }

  resetPagination(): void {
    this.index.next(0);
    this.size.next(25);
    this.length.next(0);
  }

  getIndex(): Observable<number> {
    return this.index.asObservable();
  }
  getSize(): Observable<number> {
    return this.size.asObservable();
  }
  getLength(): Observable<number> {
    return this.length.asObservable();
  }
  handlePageEvent(event: PageEvent) {
    this.index.next(event.pageIndex);
    this.size.next(event.pageSize);
    this.loadTasksForEquipment(this.equipmentId);
  }

  unassignRule(taskId: string, command: UnassignMaintenanceRuleCommand): void {
    this.maintenanceService.unassignRule(command)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => {
      this.maintenanceMediator.notifyTaskDeleted(this, taskId);
      this.loadTasksForEquipment(command.equipmentId)
    });
  }

  public reset(equipmentId: string = this.equipmentId): void {
    this.equipmentId = equipmentId;
    this.resetPagination();
    this.loadTasksForEquipment(this.equipmentId);
  }
}
