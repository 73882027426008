import { LanguageService } from 'app/shared/services/language.service';
import { AlarmType } from '../shared/enums/alarm-type.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Pipe, PipeTransform } from '@angular/core';
import { OptionResolver } from '../../../shared/components/card-select/option.resolver';
import {
  faAlarmClock,
  faBatteryBolt,
  faCarCrash,
  faCheckCircle,
  faFileContract,
  faFileSignature,
  faGasPump,
  faMapMarkerExclamation,
  faStopwatch,
  faStopwatch20,
  faTankWater,
  faTools,
  faWallet
} from '@fortawesome/pro-regular-svg-icons';
import { faBatteryExclamation, faTruck, faTruckBolt } from '@fortawesome/pro-light-svg-icons';

@Pipe({
  name: 'alarmType'
})
export class AlarmTypeResolver implements OptionResolver, PipeTransform {

  constructor(private languageService: LanguageService) {}

  public resolveName(option: string): any {
    switch (option) {
      case AlarmType.OPERATING_HOURS:
        return this.translate('modules.notification.base.alarmTypes.operatingHours');
      case AlarmType.OPERATING_HOURS_CONTRACT:
        return this.translate('modules.notification.base.alarmTypes.contractOperatingHours');
      case AlarmType.TANK_FILL_LEVEL:
        return this.translate('modules.notification.base.alarmTypes.tankLevel');
      case AlarmType.TOTAL_COSTS:
        return this.translate('modules.notification.base.alarmTypes.totalCost');
      case AlarmType.DAMAGE_COSTS:
        return this.translate('modules.notification.base.alarmTypes.violenceDamageCosts');
      case AlarmType.REPAIR_COSTS:
        return this.translate('modules.notification.base.alarmTypes.repairCosts');
      case AlarmType.GUARANTEE:
        return this.translate('modules.notification.base.alarmTypes.guarantee');
      case AlarmType.FULL_SERVICE_CONTRACT_END:
        return this.translate('modules.notification.base.alarmTypes.fullServiceContractEnd');
      case AlarmType.LEASING_SERVICE_CONTRACT_END:
        return this.translate('modules.notification.base.alarmTypes.leasingOrRentalContractEnd');
      case AlarmType.GLOBAL_GEOFENCE_VIOLATION:
        return this.translate('modules.notification.base.alarmTypes.globalGeofenceViolation');
      case AlarmType.TIMEFENCING:
        return this.translate('modules.notification.base.alarmTypes.timefence');
      case AlarmType.TRANSPORT_TASK_CREATION:
        return this.translate('modules.notification.base.alarmTypes.transportCreation');
      case AlarmType.TRANSPORT_TASK_RETURNED:
        return this.translate(
          'modules.notification.base.alarmTypes.transportStatusChanged',
          { value: this.translate('modules.transportation.transportState.returned') });
      case AlarmType.NEW_DAMAGE_ADDED:
        return this.translate('modules.notification.base.alarmTypes.newDamageAdded');
      case AlarmType.INACTIVE_TELEMATICS_UNIT:
        return this.translate('modules.notification.base.alarmTypes.inactiveTelematicsUnit');
      case AlarmType.BATTERY_VOLTAGE:
        return this.translate('modules.notification.base.alarmTypes.batteryVoltage');
      case AlarmType.DEF_TANK_LEVEL:
        return this.translate('modules.notification.base.alarmTypes.defTankLevel');
      case AlarmType.TELEMATICS_UNIT_BATTERY_STATUS:
        return this.translate('modules.notification.base.alarmTypes.trackerBatteryStatus');
      default:
        return this.translate('general.unknown');
    }
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case AlarmType.OPERATING_HOURS: return faStopwatch;
      case AlarmType.OPERATING_HOURS_CONTRACT: return faStopwatch20;
      case AlarmType.TANK_FILL_LEVEL: return faGasPump;
      case AlarmType.TOTAL_COSTS: return faWallet;
      case AlarmType.DAMAGE_COSTS: return faCarCrash;
      case AlarmType.REPAIR_COSTS: return faTools;
      case AlarmType.GUARANTEE: return faCheckCircle;
      case AlarmType.FULL_SERVICE_CONTRACT_END: return faFileSignature;
      case AlarmType.LEASING_SERVICE_CONTRACT_END: return faFileContract;
      case AlarmType.GLOBAL_GEOFENCE_VIOLATION: return faMapMarkerExclamation;
      case AlarmType.TIMEFENCING: return faAlarmClock;
      case AlarmType.TRANSPORT_TASK_CREATION: return faTruck;
      case AlarmType.TRANSPORT_TASK_RETURNED: return faTruckBolt;
      case AlarmType.BATTERY_VOLTAGE: return faBatteryBolt;
      case AlarmType.DEF_TANK_LEVEL: return faTankWater;
      case AlarmType.TELEMATICS_UNIT_BATTERY_STATUS: return faBatteryExclamation;
      default: return null;
    }
  }

  public resolveMatIcon(option: string): string {
    switch (option) {
      case AlarmType.NEW_DAMAGE_ADDED: return 'icon-violence_outline';
      case AlarmType.INACTIVE_TELEMATICS_UNIT: return 'icon-telematics_outline';
      default: return null;
    }
  }

  public transform(value: any): any {
    return this.resolveName(value);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
