import { Component, EventEmitter, Host, Input, NgZone, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChange } from '@angular/core';
import { MapLocationMarker } from '../../../interfaces/map-location-marker.interface';
import { GoogleMap } from '@angular/google-maps';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Cluster, MarkerClusterer, Renderer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { filter, merge, of } from 'rxjs';
import { MapIcon } from '../../../contracts/map-icon.class';
import { MapConsts } from '../../../map.consts';
import _ from 'lodash';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';
import { MapLocationsCluster } from '../../../interfaces/map-location-cluster.interface';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';

interface MapClusterComponentSimpleChanges {
  clusterMarkers?: SimpleChange;
}

interface MapMarkerExtended extends google.maps.Marker {
  markerId: string;
}

@UntilDestroy()
@Component({
  selector: 'bh-map-cluster',
  templateUrl: './map-cluster.component.html',
})
export class MapClusterComponent  implements OnInit, OnChanges, OnDestroy {

  @Input() clusterMarkers: MapLocationMarker[];
  @Input() set gridSize(value: number) {
    const radius = 2 * value;
    if (Number.isFinite(radius) && radius > 0 &&  this.radius !== radius) {
      this.radius = radius;
      if (this.map) {
        this.recreateCluster();
      }
    }
  }
  @Input() enableLabels = false;
  @Output() markerClicked = new EventEmitter<MapLocationMarker>();
  @Output() clusterClicked = new EventEmitter<MapLocationsCluster>();

  public markerRecords: Record<string, { clusterMarker: MapLocationMarker, googleMarker: MapMarkerExtended }> = {};
  public visibleMarkers: MapLocationMarker<LatLonLocation>[] = [];
  private map: google.maps.Map;
  private clusterMarker: MarkerClusterer;
  private unclusteredMarkers: MapMarkerExtended[] = [];
  private clusterListeners: google.maps.MapsEventListener[] = [];
  private radius = MapConsts.DEFAULT_CLUSTER_RADIUS;

  constructor(@Host() @Optional() private googleMapComponent: GoogleMap, private ngZone: NgZone) {}

  public ngOnInit(): void {
    this.map = this.googleMapComponent.googleMap;
    if (this.googleMapComponent) {
      merge(of(this.googleMapComponent.googleMap), this.googleMapComponent.mapInitialized)
        .pipe(filter(Boolean), untilDestroyed(this))
        .subscribe((googleMap: google.maps.Map) => {
          this.map = googleMap;
          this.refreshCluster();
        });
    }
  }

  public ngOnChanges(changes: MapClusterComponentSimpleChanges): void {
    if (changes.clusterMarkers && this.map) {
      this.refreshCluster();
    }
  }

  public ngOnDestroy(): void {
    this.removeCluster();
  }

  private removeCluster(): void {
    this.clusterMarker?.clearMarkers();
    this.removeMarkerListeners();
    this.clusterMarker = null;
  }

  private initCluster(): void {
    const renderer = {
      render: ({ count, position }: any) => {
        const { index, size } = this.getIconStyles(count);

        return new google.maps.Marker({
          position,
          icon: {
            url: `assets/icons/googlemaps-cluster-icons/m${index}.png`,
            scaledSize: new google.maps.Size(size, size),
          },
          label: {
            text: String(count),
            color: 'rgba(0, 0, 0, 0.9)',
            fontSize: '12px',
            fontWeight: '500',
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      }
    };

    this.clusterMarker = this.generateMarkerClusterer(this.map, this.getGoogleMarkers(), renderer);
  }

  private generateMarkerClusterer(map: google.maps.Map, markers: google.maps.Marker[], renderer: Renderer): MarkerClusterer {
    return new MarkerClusterer({
      map,
      markers,
      renderer,
      algorithmOptions: null,
      algorithm: new SuperClusterAlgorithm({ radius: this.radius, maxZoom: MapConsts.MAX_ZOOM }),
      onClusterClick: (_event, cluster, _map) => this.ngZone.run(() => this.onClusterClick(cluster)),
    });
  }

  private onClusterClick(cluster: Cluster): void {
    const location: LatLon = { lat: cluster.position.lat(), lon: cluster.position.lng() };
    const markers = (cluster?.markers || [])
      .map(({ markerId }: MapMarkerExtended) => this.markerRecords[markerId].clusterMarker)
      .filter(Boolean);
    this.clusterClicked.emit({ location, markers });
  }

  private generateMarkers(): void {
    this.removeMarkerListeners();

    const markerRecords: Record<string, { clusterMarker: MapLocationMarker, googleMarker: MapMarkerExtended }> = {};
    if (this.clusterMarkers?.length > 0) {
      this.clusterMarkers
        .filter(({ location }) => Boolean(location))
        .forEach(marker => {
          markerRecords[marker.id] = {
            clusterMarker: marker,
            googleMarker: this.generateMarker(marker)
          };
          this.clusterListeners.push(
            markerRecords[marker.id].googleMarker.addListener('click', () =>
              this.ngZone.run(() => this.onMarkerClicked(markerRecords[marker.id].googleMarker)))
          );
        });
    }
    this.markerRecords = markerRecords;
  }

  private removeMarkerListeners(): void {
    this.clusterListeners.forEach(listener => listener.remove());
    this.clusterListeners = [];
  }

  private generateMarker(marker: MapLocationMarker): MapMarkerExtended {
    return <MapMarkerExtended>new google.maps.Marker(
      <google.maps.MarkerOptions>{
        position: {
          lat: marker.location.lat,
          lng: marker.location.lon
        },
        icon: this.convertToIcon(marker.icon),
        markerId: marker.id,
      });
  }

  private refreshCluster(): void {
    this.generateMarkers();
    if (!this.clusterMarker) {
      this.initCluster();
      this.initMapEventListeners();
    } else {
      this.clusterMarker.clearMarkers();
      this.clusterMarker.addMarkers(this.getGoogleMarkers());
      this.clusterMarker.render();
    }
  }

  private recreateCluster(): void {
    this.removeCluster();
    this.refreshCluster();
  }

  private initMapEventListeners(): void {
    if (this.clusterMarker && this.map) {
      const mapBoundsChangedListener = google.maps.event.addListener(
        this.map,
        'bounds_changed',
        () => this.ngZone.run(() => this.onMapBoundsChanged()));
      const clusterClickedListener = google.maps.event.addListener(
        this.clusterMarker,
        'clusteringend',
        () => this.ngZone.run(() => this.onClusteringEnd()));
      this.clusterListeners.push(mapBoundsChangedListener, clusterClickedListener);
    }
  }

  private onMarkerClicked(marker: MapMarkerExtended): void {
    this.markerClicked.emit({ ...this.markerRecords[marker.markerId].clusterMarker });
  }

  private onMapBoundsChanged(): void {
    if (this.enableLabels) {
      this.calculateVisibleLocations();
    }
  }

  private onClusteringEnd(): void {
    if (this.enableLabels) {
      this.unclusteredMarkers = this.clusterMarker['clusters']
        .filter(cls => cls?.markers?.length === 1)
        .reduce((acc, cls) => ([...acc, ...cls.markers]), []);
      this.calculateVisibleLocations();
    }
  }

  private calculateVisibleLocations(): void {
    const bounds = this.map.getBounds();
    const visible = this.unclusteredMarkers.filter(m => bounds.contains(m.getPosition()));
    this.visibleMarkers = visible.map(v => this.markerRecords[v.markerId].clusterMarker);
  }

  private getGoogleMarkers(): google.maps.Marker[] {
    return Object.values(this.markerRecords).map(({ googleMarker }) => googleMarker);
  }

  private getIconStyles(count: number): { index: number, size: number } {
    if (count > 5000) {
      return { index: 5, size: 85 };
    } else if (count > 1000) {
      return { index: 4, size: 75 };
    } else if (count > 100) {
      return { index: 3, size: 65 };
    } else if (count > 10) {
      return { index: 2, size: 55 };
    }
    return { index: 1, size: 52 };
  }

  private convertToIcon(icon: string | MapIcon): google.maps.Icon {
    if (icon) {
      return MapIcon.isMapIcon(icon)
        ? {
          url: icon.url,
          anchor: icon.anchor ? new google.maps.Point(icon.anchor.x, icon.anchor.y) : null,
          scaledSize: icon.scaledSize ? new google.maps.Size(icon.scaledSize.width, icon.scaledSize.height) : null,
          size: icon.size ? new google.maps.Size(icon.size.width, icon.size.height) : null,
        } : {
          url: icon,
          anchor: new google.maps.Point(20, 40),
          scaledSize: new google.maps.Size(40, 40),
          size: new google.maps.Size(40, 40),
        }
    }
    return null;
  }

}
