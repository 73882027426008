import { WidgetDefinition } from './widget-definition';
import { FleetCompositionWidgetConfiguration } from './fleet-composition-widget/fleet-composition-widget-configuration';
import { GridsterItem } from 'angular-gridster2';
import { MapWidgetConfiguration } from './map-widget/map-widget-configuration';
import { Injectable } from '@angular/core';
import { KeycloakService } from '../../../core/keycloak';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { TypeOfUseChangeRequestsWidgetComponentConfiguration } from './type-of-use-change-requests-widget/type-of-use-change-requests-widget.configuration';
import { TypeOfUseChangeRequestsWidgetStoreService } from './type-of-use-change-requests-widget/type-of-use-change-requests-widget-store.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Modules } from '../../../shared/enums/modules.enum';
import { TransferRequestWidgetComponentConfiguration } from './transfer-request-widget/transfer-request-widget.configuration';
import { ProjectDataSource } from '../../disposition/shared/project.datasource';
import { StockStore } from '../../disposition/stocks/shared/stock.store';
import { EquipmentStatusWidgetConfiguration } from './equipment-status-widget/equipment-status-widget-configuration.class';
import { WidgetType } from './contract/widget-type.enum';
import { LastCompletedTasksWidgetConfiguration } from './last-completed-tasks-widget/last-completed-tasks-widget-configuration.class';

@Injectable()
export class WidgetRegistry {
  private readonly _widgetsInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public widgetsInitialized: Observable<boolean> = this._widgetsInitialized.asObservable();

  public get widgetsInitializedValue(): boolean {
    return this._widgetsInitialized.value;
  }

  widgetDefinitions: Map<WidgetType, WidgetDefinition> = new Map();

  constructor(
    private authService: KeycloakService,
    private languageService: LanguageService,
    private typeOfUseChangeRequestsWidgetStoreService: TypeOfUseChangeRequestsWidgetStoreService,
    private projectStore: ProjectDataSource,
    private stockStore: StockStore,
  ) {
    this.setWidgets();
  }

  private async setWidgets() {
    this.widgetDefinitions.set(WidgetType.MAP, this.getMapWidgetConfiguration());
    if (this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this.widgetDefinitions.set(WidgetType.FLEET_COMPOSITION, this.getFleetCompositionWidgetConfiguration());
    }
    await this.typeOfUseChangeRequestsWidgetStoreService.setHasTypeOfUseWorkflow();
    if (this.typeOfUseChangeRequestsWidgetStoreService.hasTypeOfUseWorkflow.value
      && this.hasAuthorityToSeeTypeOfUseChangeRequests()
      && this.hasModuleToSeeTypeOfUseChangeRequests()) {
      this.widgetDefinitions.set(WidgetType.TYPE_OF_USE_CHANGE_REQUESTS,
        this.getTypeOfUseChangeRequestsWidgetComponentConfiguration()
      );
    }
    await this.stockStore.setStockTransferRequestWorkflow();
    await this.projectStore.setTransferRequestsWorkflow();

    if (this.hasModuleToSeeTransferRequests() && this.hasAuthoritySeeTransferRequests() &&
      (this.projectStore.getTransferRequestsWorkflow() || this.stockStore.getStockTransferRequestWorkflow())
    ) {
      this.widgetDefinitions.set(WidgetType.TRANSFER_REQUEST,
        this.getTransferRequestWidgetComponentConfiguration()
      );
    }

    if (this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW])) {
      this.widgetDefinitions.set(WidgetType.EQUIPMENT_STATUS, this.getEquipmentStatusWidgetConfiguration());
    }

    if (this.authService.hasModule(Modules.SERVICE)
      && this.authService.hasAnyAuthority([Authorities.MAINTENANCE_TASK_VIEW, Authorities.MAINTENANCE_TASK_MANAGE])) {
      this.widgetDefinitions.set(WidgetType.LAST_COMPLETED_TASKS, this.getLastCompletedTasksWidgetConfiguration());
    }

    this._widgetsInitialized.next(true);
  }

  private getMapWidgetConfiguration(): MapWidgetConfiguration {
    return new MapWidgetConfiguration({title: this.languageService.getInstant('modules.dashboard.widget.mapWidgetName')});
  }

  private getFleetCompositionWidgetConfiguration(): FleetCompositionWidgetConfiguration {
    return new FleetCompositionWidgetConfiguration({
      title: this.languageService.getInstant('modules.dashboard.widget.fleetCompositionWidgetName')
    });
  }

  private getTypeOfUseChangeRequestsWidgetComponentConfiguration(): TypeOfUseChangeRequestsWidgetComponentConfiguration {
    return new TypeOfUseChangeRequestsWidgetComponentConfiguration({
      title: this.languageService.getInstant('modules.dashboard.widget.typeOfUseChangeRequestsWidgetName'),
    });
  }

  private getTransferRequestWidgetComponentConfiguration(): TransferRequestWidgetComponentConfiguration {
    return new TransferRequestWidgetComponentConfiguration({
      title: this.languageService.getInstant('modules.dashboard.widget.transferRequestWidgetName'),
    });
  }

  private getEquipmentStatusWidgetConfiguration(): EquipmentStatusWidgetConfiguration {
    return new EquipmentStatusWidgetConfiguration({
      title: this.languageService.getInstant('modules.dashboard.widget.equipmentStatusWidgetName')
    });
  }

  private getLastCompletedTasksWidgetConfiguration(): LastCompletedTasksWidgetConfiguration {
    return new LastCompletedTasksWidgetConfiguration({
      title: this.languageService.getInstant('modules.dashboard.widget.lastCompletedTasksWidgetName'),
      titleIcon: {
        content: 'open_in_new',
        tooltip: this.languageService.getInstant('modules.dashboard.lastCompletedTasksWidget.openDetails')
      }
    });
  }

  createDefaultConfiguration(widgetType: WidgetType): WidgetDefinition {
    return {...this.widgetDefinitions.get(widgetType)};
  }

  getFullConfiguration(widget: GridsterItem): GridsterItem {
    return {...this.widgetDefinitions.get(widget.widgetType), ...widget};
  }

  private hasAuthorityToSeeTypeOfUseChangeRequests(): boolean {
    return this.authService.hasAuthority(Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE)
      || this.authService.hasAuthority(Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE);
  }

  private hasModuleToSeeTypeOfUseChangeRequests(): boolean {
    return this.authService.hasModule(Modules.DISPOSITION)
      || this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT);
  }

  private hasModuleToSeeTransferRequests(): boolean {
    return this.authService.hasModule(Modules.DISPOSITION)
      || this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT);
  }

  private hasAuthoritySeeTransferRequests(): boolean {
    return this.authService.hasAuthority(Authorities.STOCK_VIEW)
      || this.authService.hasAuthority(Authorities.PROJECT_VIEW)
      || this.authService.hasAuthority(Authorities.PROJECT_ASSIGNEE_VIEW);
  }

}
