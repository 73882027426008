import { environment } from 'environments/environment';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { map, take, delay } from 'rxjs/operators';
import { AddressLocation } from 'app/modules/organisation/contract/address-location';
import { RetryGeocoder } from '../geolocation/retry-geocoder';

export function AddressLocationValidator(geocoder: RetryGeocoder, isRequired = true): AsyncValidatorFn {
  let controlValue = Subscription.EMPTY;
  const geocodeResult = geocoder.geocodeResult;

  return (control: AbstractControl): Observable<ValidationErrors> => {
    if (locationExists(control.value) || !isRequired && isEmptyAddress(control)) {
      return of(null);
    }

    controlValue.unsubscribe();
    controlValue = of(control.value).pipe(
      delay(environment.DELAY_SHORT)
    ).subscribe(address => geocoder.geocodeRequest({'address': new AddressLocation(address).getAddressString()}));

    return geocodeResult.pipe(
      take(1),
      map(({ status }) => status === google.maps.GeocoderStatus.OK ? null : { notResolved: true })
    );

  }
}

function locationExists({ location }: AddressLocation): boolean {
  return location && Number.isFinite(location.lat) && Number.isFinite(location.lon);
}

function isEmptyAddress(control: AbstractControl): boolean {
  return Object.values(control.value).every(val => val === '');
}
