import { RetryGeocoderStatus } from './retry-geocoder-status.enum';
import { GeocoderResultRequest } from './geocoder-result-request.interface';
import { switchMap } from 'rxjs/operators';
import { Subject, timer, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';

export class RetryGeocoder {
  private geocoderResultRequest = new Subject<GeocoderResultRequest>();
  private geocoderStatusRequest = new BehaviorSubject<RetryGeocoderStatus>(RetryGeocoderStatus.OK);

  public readonly geocodeResult = this.geocoderResultRequest.asObservable();
  public readonly status = this.geocoderStatusRequest.asObservable();

  private currentGeocoderRequest: google.maps.GeocoderRequest;
  private retryMode = false;
  private retryGeocoderRequest = new Subject();
  private requestBackoffCounter = new RequestBackoffCounter();

  constructor(private geocoder: google.maps.Geocoder) {
  }

  public geocodeRequest(request: google.maps.GeocoderRequest): void {
    this.currentGeocoderRequest = request;
    if (this.retryMode) {
      return;
    }

    this.geocoder.geocode(this.currentGeocoderRequest, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK || status === google.maps.GeocoderStatus.ZERO_RESULTS) {
        this.sendGeocoderResult(results && results[0], status, RetryGeocoderStatus.OK);
      } else {
        this.geocoderStatusRequest.next(RetryGeocoderStatus.PENDING);
        this.runRetryProcess();
      }
    });
  }

  private sendGeocoderResult(
    result: google.maps.GeocoderResult,
    status: google.maps.GeocoderStatus,
    requestStatus: RetryGeocoderStatus
  ): void {
    this.geocoderResultRequest.next({ result, status });
    this.geocoderStatusRequest.next(requestStatus);
  }

  private runRetryProcess(): void {
    this.retryGeocoderRequest.complete();
    this.retryMode = true;
    this.retryGeocoderRequest = new Subject();
    this.subscribeToRetryRequest();
    this.requestBackoffCounter.reset();
    this.retryRequest();
  }

  private subscribeToRetryRequest(): void {
    this.retryGeocoderRequest.pipe(
      switchMap(() => timer(this.requestBackoffCounter.delay))
    ).subscribe(() => {
      this.geocoder.geocode(this.currentGeocoderRequest, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK || status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          this.sendGeocoderResult(results && results[0], status, RetryGeocoderStatus.OK);
          this.retryMode = false;
          this.retryGeocoderRequest.complete();
        } else {
          if (this.requestBackoffCounter.isCompleted) {
            this.sendGeocoderResult(results && results[0], status, RetryGeocoderStatus.BAD);
            this.retryMode = false;
            this.retryGeocoderRequest.complete();
          } else {
            this.retryRequest();
          }
        }
      })
    });
  }

  private retryRequest(): void {
    this.requestBackoffCounter.next();
    this.retryGeocoderRequest.next(null);
  }
}


class RequestBackoffCounter {
  private currentDelay: number;
  private currentAttempt: number;

  constructor(private minDelay = environment.DELAY_SHORT,
              private maxDelay = environment.DELAY_LONG,
              private coefficient = 1.5,
              private attemptsAmount = 5) {
    this.reset();
  }

  public get delay(): number {
    return this.currentDelay;
  }

  public get isCompleted(): boolean {
    return this.currentAttempt >= this.attemptsAmount;
  }

  public reset(): void {
    this.currentDelay = 0;
    this.currentAttempt = -1;
  }

  public next(): void {
    if (this.isCompleted) {
      return;
    }
    this.currentAttempt++;
    this.currentDelay = this.currentAttempt === 0
      ? this.minDelay
      : Math.min(this.currentDelay * this.coefficient, this.maxDelay);
  }
}
