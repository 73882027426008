<div class="assign-equipment-organisation-dialog">
  <h2 mat-dialog-title>{{equipment?.equipmentName}}
    <span *ngIf="equipment.equipmentSerialNumber">- {{equipment.equipmentSerialNumber}}</span>
  </h2>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close [attr.aria-label]="'general.labels.clear'|translate" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p>{{'modules.equipment.equipmentAssignToStockDialog.selectStock'|translate}}</p>
    <form [formGroup]="stockForm">
      <mat-form-field>
        <mat-label>{{'general.stock.s'|translate}}</mat-label>
        <mat-select formControlName="stockId"
                    disableOptionCentering="true" required>
          <mat-option>
            <ngx-mat-select-search [formControl]="filterControl"
                                   [placeholderLabel]="'modules.equipment.equipmentAssignToStockDialog.searchStock'|translate"
                                   [noEntriesFoundLabel]="'modules.equipment.equipmentAssignToStockDialog.noStocksFound'|translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let stock of filteredStocks | async"
                      [value]="stock.stockId">
            {{ stock.stockName }} ({{stock.organisationName}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button color="accent" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()">{{'general.buttons.save'|translate}}
    </button>
  </mat-dialog-actions>
</div>
