import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { AdditionalField, AdditionalFieldSelectOption, AdditionalFieldType } from '../additional-field';
import { ControlErrorMessage } from '../../../types/control-error-message.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatableString } from '../../../../../shared/contract/translatable-string.type';

@UntilDestroy()
@Component({
  selector: 'bh-additional-field',
  templateUrl: './additional-field.component.html',
  styleUrls: ['./additional-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdditionalFieldComponent),
      multi: true,
    },
  ],
})
export class AdditionalFieldComponent implements OnInit, ControlValueAccessor {

  readonly AdditionalFieldType = AdditionalFieldType;

  @Input() field: AdditionalField;
  @Input() language: string;
  @Input() errorMessages: ControlErrorMessage = {};
  @Input() readonly = false;

  @Output() onKeyPress = new EventEmitter<KeyboardEvent>();

  public control: UntypedFormControl = new UntypedFormControl();

  get fieldType(): AdditionalFieldType {
    return this.field.additionalFieldType;
  }

  get fieldUnit(): string {
    return this.field?.additionalFieldUnit;
  }

  get fieldOptions(): AdditionalFieldSelectOption[] {
    return this.field?.additionalFieldSelectOptions || [];
  }

  public ngOnInit(): void {
    if (this.field.additionalFieldDefaultValue) {
      this.control.patchValue(this.field.additionalFieldDefaultValue);
    }
  }

  public writeValue(value: any): void {
    this.control.patchValue(value);
  }

  public registerOnChange(onChange: Function) {
    this.control.registerOnChange(onChange);
    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => onChange(value));
  }

  public registerOnTouched(onTouched: Function) {
    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => onTouched());
  }

  public setDisabledState(disabled: boolean) {
    if (disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public getBoolValue(): string {
    return this.control.value === true || this.control.value === 'true'
      ? 'general.yes'
      : 'general.no';
  }

  public getOptionValue(): TranslatableString {
    if (this.field.additionalFieldSelectOptions) {
      return this.field.additionalFieldSelectOptions.find(option => option.optionKey === this.control.value)?.optionName;
    }

    return null;
  }

  public handleKeyPress($event: KeyboardEvent): void {
    this.onKeyPress.emit($event);
  }
}
