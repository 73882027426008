import { PageType } from './../../enums/page-type.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Component, Input } from '@angular/core';
import { faListUl } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';
import { LanguageService } from 'app/shared/services/language.service';

interface SwitcherPageOption {
  type: PageType;
  icon: IconDefinition;
  url: string;
  tooltip: string;
}

@Component({
  selector: 'bh-page-switcher',
  templateUrl: './page-switcher.component.html',
  styleUrls: ['./page-switcher.component.scss']
})
export class PageSwitcherComponent {
  @Input() active: PageType;

  constructor(private languageService: LanguageService) {
  }

  public readonly options: SwitcherPageOption[] = [
    {
      type: PageType.TRANSPORT_LIST,
      icon: faListUl,
      url: '/transportation/list',
      tooltip: this.languageService.getInstant('shared.pageSwitcher.listView')
    },
    {
      type: PageType.TRANSPORT_BOARD,
      icon: faCalendarWeek,
      url: '/transportation/board',
      tooltip: this.languageService.getInstant('shared.pageSwitcher.boardView')
    }
  ];
}
