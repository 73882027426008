import { Pipe, PipeTransform } from '@angular/core';
import { UserStatus } from 'app/shared/enums/user-status.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'userStatus'
})
export class UserStatusPipe implements PipeTransform {
  private readonly translationKeys = {
    [UserStatus.DELETED]: this.translate('shared.pipes.userStatus.deleted'),
    [UserStatus.SYSTEM]: this.translate('shared.pipes.userStatus.system'),
    [UserStatus.OSP_CONNECTOR]: this.translate('shared.pipes.userStatus.ospConnector'),
    [UserStatus.BRZ_CONNECTOR]: this.translate('shared.pipes.userStatus.brzConnector'),
    [UserStatus.UNKNOWN]: this.translate('general.unknown'),
  }

  constructor(private languageService: LanguageService) {}

  public transform(value: UserStatus): string {
    return this.translationKeys[value] || this.translationKeys[UserStatus.UNKNOWN];
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
