import { TransportTaskState } from '../../../shared/enums/transport-task-status.enum';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bh-transport-state-badge',
  templateUrl: './transport-state-badge.component.html',
  styleUrls: ['./transport-state-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportStateBadgeComponent {
  @Input() state: TransportTaskState = null;
}
