import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { MatDialog } from '@angular/material/dialog';
import { BaseEquipmentViewAttachment } from '../base/base-equipment-view-attachment';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { AssetDocumentType } from '../../../../../contract/document-type.enum';
import { UpdateEquipmentDocumentDescriptionCommand } from '../../../../../contract/update-equipment-document-description-command';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { ContractDocumentLink } from '../../../../../contract/contract-document-link.interface';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UpdateMaintenanceTaskDocumentDescriptionCommand
} from '../../../../../../maintenance/tasks/shared/update-maintenance-task-document-description.command';
import {UpdateEquipmentDocumentNameCommand} from '../../../../../contract/update-equipment-document-name-command';
import {
  UpdateMaintenanceTaskDocumentNameCommand
} from '../../../../../../maintenance/tasks/shared/update-maintenance-task-document-name.command';
import {Authorities} from '../../../../../../../shared/enums/authorities.enum';

@Component({
  selector: 'bh-equipment-view-attachment',
  templateUrl: './equipment-view-attachment.component.html',
  styleUrls: ['./equipment-view-attachment.component.scss']
})
@UntilDestroy()
export class EquipmentViewAttachmentComponent extends BaseEquipmentViewAttachment implements OnDestroy {

  public EquipmentDocumentType = AssetDocumentType;

  constructor(protected equipmentsStore: EquipmentsDataSource,
              protected equipmentsService: EquipmentsService,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected userConfigurationService: UserConfigurationService,
              protected routerHistory: RouterHistory,
              public equipmentCheckerService: EquipmentCheckerService,
              protected languageService: LanguageService,
              protected matSnackBar: MatSnackBar) {
    super(equipmentsStore,
      equipmentsService,
      dialog,
      route,
      authService,
      router,
      userConfigurationService,
      routerHistory,
      equipmentCheckerService,
      languageService,
      matSnackBar);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public saveName(updatedDocument: DocumentLink | ContractDocumentLink, documentType?: AssetDocumentType): void {
    if (documentType === undefined && this.instanceOfContractDocumentLink(updatedDocument)) {
      documentType = updatedDocument.documentType;
    }

    if (this.isInLifeCycleMaintenanceDocuments(updatedDocument.documentKey)) {
      const cmd = new UpdateMaintenanceTaskDocumentNameCommand(
        this.getEntityIdFromDocumentKey(updatedDocument.documentKey),
        updatedDocument.documentKey,
        updatedDocument.fileName
      );
      this.equipmentsStore.updateMaintenanceTaskName(cmd, this.equipment.equipmentId, documentType);
    } else {
      const cmd = new UpdateEquipmentDocumentNameCommand(
        this.equipment.equipmentId,
        updatedDocument.documentKey,
        updatedDocument.fileName,
        documentType
      );
      this.equipmentsStore.updateDocumentName(cmd);
    }
  }

  public saveDescription(updatedDocument: DocumentLink | ContractDocumentLink, documentType?: AssetDocumentType): void {
    if (documentType === undefined && this.instanceOfContractDocumentLink(updatedDocument)) {
      documentType = updatedDocument.documentType;
    }
    const cmd = new UpdateEquipmentDocumentDescriptionCommand(
      this.equipment.equipmentId,
      updatedDocument.documentKey,
      updatedDocument.fileDescription,
      documentType
    );
    this.equipmentsStore.updateDocumentDescription(cmd);
  }

  public saveMaintenanceDescription(updatedDocument: DocumentLink, documentType?: AssetDocumentType): void {
    if (documentType === undefined && this.instanceOfContractDocumentLink(updatedDocument)) {
      documentType = updatedDocument.documentType;
    }
    const cmd = new UpdateMaintenanceTaskDocumentDescriptionCommand(
      this.getEntityIdFromDocumentKey(updatedDocument.documentKey),
      updatedDocument.documentKey,
      updatedDocument.fileDescription
    );
    this.equipmentsStore.updateMaintenanceTaskDescription(cmd, this.equipment.equipmentId, documentType);
  }

  public isUploadEnabled(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment)
      && this.hasAnyAuthority([
        Authorities.EQUIPMENT_CREATE,
        Authorities.EQUIPMENT_UPDATE,
        Authorities.EQUIPMENT_ADD_ATTACHMENTS])
  }

  public isDeleteEnabled(): boolean {
    return !this.waiting
      && this.hasAnyAuthority([this.authorities.EQUIPMENT_UPDATE, this.authorities.EQUIPMENT_DELETE_ATTACHMENTS])
      && this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public isNameAndDescriptionEditable(): boolean {
    return this.hasAnyAuthority([this.authorities.EQUIPMENT_UPDATE, this.authorities.EQUIPMENT_ADD_ATTACHMENTS])
      && this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public isSetDefaultEnabled(): boolean {
    return this.hasAnyAuthority([this.authorities.EQUIPMENT_UPDATE, this.authorities.EQUIPMENT_ADD_ATTACHMENTS]) &&
        !this.equipmentCheckerService.isInactiveEquipment(this.equipment) &&
        !this.equipmentCheckerService.isNotAvailableAnymore(this.equipment);
  }

  private instanceOfContractDocumentLink(documentLink: DocumentLink): documentLink is ContractDocumentLink {
    return 'documentType' in documentLink;
  }

  private getEntityIdFromDocumentKey(documentKey: string): string {
    const codeIndex = documentKey.indexOf('/') + 1;
    const code = documentKey.substring(codeIndex);
    return code.split('/')[0];
  }

  private isInLifeCycleMaintenanceDocuments(documentKey: string): boolean {
    return this.lifeCycleMaintenanceDocuments.some(doc => doc.documentKey === documentKey);
  }

  public canManageDamages(): boolean {
    return this.isActiveEquipment() && this.hasAnyAuthority([
        Authorities.EQUIPMENT_MANAGE_DAMAGES,
        Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS,
        Authorities.EQUIPMENT_UPDATE
    ]);
  }

  public canManageIncidents(): boolean {
    return this.isActiveEquipment() && this.hasAnyAuthority([
      Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
      Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS,
      Authorities.EQUIPMENT_UPDATE
    ]);
  }

  public canManageMaintenance(): boolean {
    return this.isActiveEquipment() && this.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE);
  }

  public canManageCosts(): boolean {
    return this.isActiveEquipment() && this.hasAuthority(Authorities.EQUIPMENT_MANAGE_COSTS);
  }

  private isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }
}
