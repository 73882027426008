export const OnestopProSupportInfo = {
  phone: {
    href: 'tel:+4985170006600',
    value: '+49 (0)851 7000-6600'
  },
  mail: {
    href: 'mailto:support@onestop-pro.com',
    value: 'support@onestop-pro.com'
  }
};

export const EquipCareSupportInfo = {
  phone: {
    href: 'tel:+4989354021943',
    value: '+49 (0) 89 35 402-1943'
  },
  mail: {
    href: 'mailto:support.equipcare@wackerneuson.com',
    value: 'support.equipcare@wackerneuson.com'
  }
};

export const TriathlonSupportInfo = {
  phone: {
    href: '',
    value: ''
  },
  mail: {
    href: 'mailto:trivent-support@triview.cloud',
    value: 'trivent-support@triview.cloud'
  }
};
