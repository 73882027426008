import { LanguageService } from './../services/language.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from '../enums/day-of-week.enum';

@Pipe({
  name: 'dayName'
})

@Injectable()
export class DayNamePipe implements PipeTransform {
  private translationKeys = {
    [DayOfWeek.MONDAY]: 'general.units.time.day.names.mon',
    [DayOfWeek.TUESDAY]: 'general.units.time.day.names.tue',
    [DayOfWeek.WEDNESDAY]: 'general.units.time.day.names.wed',
    [DayOfWeek.THURSDAY]: 'general.units.time.day.names.thu',
    [DayOfWeek.FRIDAY]: 'general.units.time.day.names.fri',
    [DayOfWeek.SATURDAY]: 'general.units.time.day.names.sat',
    [DayOfWeek.SUNDAY]: 'general.units.time.day.names.sun',
  }

  constructor(private languageService: LanguageService) {
  }

  transform(value: DayOfWeek): string {
    return this.translate(this.translationKeys[value] || 'general.unknown');
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
