import { EquipmentTelematicsDataseriesItem } from './equipment-telematics-dataseries-item';

export class EquipmentTelematicsDataseries {
  public static fromData(data: EquipmentTelematicsDataseries) {
    return new EquipmentTelematicsDataseries(
      EquipmentTelematicsDataseriesItem.fromData(data.defRemainingSeries),
      EquipmentTelematicsDataseriesItem.fromData(data.fuelRemainingSeries),
      EquipmentTelematicsDataseriesItem.fromData(data.idleNonOperatingHoursSeries),
      EquipmentTelematicsDataseriesItem.fromData(data.fuelUsedSeries),
      EquipmentTelematicsDataseriesItem.fromData(data.fuelUsedAverageSeries),
      EquipmentTelematicsDataseriesItem.fromData(data.operatingHoursSeries),
      EquipmentTelematicsDataseriesItem.fromData(data.operatingHoursWorkingIdleRatio),
      EquipmentTelematicsDataseriesItem.fromData(data.engineStatusSeries)
    );
  }
  constructor(
    public defRemainingSeries?: EquipmentTelematicsDataseriesItem,
    public fuelRemainingSeries?: EquipmentTelematicsDataseriesItem,
    public idleNonOperatingHoursSeries?: EquipmentTelematicsDataseriesItem,
    public fuelUsedSeries?: EquipmentTelematicsDataseriesItem,
    public fuelUsedAverageSeries?: EquipmentTelematicsDataseriesItem,
    public operatingHoursSeries?: EquipmentTelematicsDataseriesItem,
    public operatingHoursWorkingIdleRatio?: EquipmentTelematicsDataseriesItem,
    public engineStatusSeries?: EquipmentTelematicsDataseriesItem) {}

}
