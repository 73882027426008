import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingpageListComponent } from './landingpage-list/landscape/landingpage-list.component';
import { MobileLandingpageListComponent } from './landingpage-list/mobile/mobile-landingpage-list.component';
import { MobileChooseCreationProcessComponent } from './mobile-choose-creation-process/mobile-choose-creation-process.component';
import { CanActivateChildMobileWebApp } from 'app/core/guards/can-activate-child-mobile-web-app.guard';

const landingpageRoutes: Routes = [
  {
    path: 'landingpage',
    component: LandingpageListComponent
  },
  {
    path: 'mobile',
    canActivateChild: [CanActivateChildMobileWebApp],
    children: [
      {
        path: 'landingpage',
        component: MobileLandingpageListComponent
      },
      {
        path: 'choose-create',
        component: MobileChooseCreationProcessComponent
      }
    ]
  }
];

export const landingpageRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(landingpageRoutes);
