<div class="list">
  <div class="m-list-header__form search-bar-mobile">
    <mat-form-field>
      <input matInput
             placeholder="{{ (isSourceTab()
             ? 'modules.disposition.transferChooseLocation.searchStartPoint'
             : 'modules.disposition.transferChooseLocation.searchTarget') | translate }} ..."
             autocomplete="off"
             autofocus="false"
             [formControl]="termControl">
    </mat-form-field>
  </div>
  <mat-tab-group mat-stretch-tabs class="location-tabs">
    <mat-tab [label]="'general.project.pl'|translate">
      <ng-container *ngIf="projects | async; let data">
        <virtual-scroller #projectScroll [items]="data" [bufferAmount]="10"
                          [style.height.px]="ruler.getViewportSize().height - 240"
                          [enableUnequalChildrenSizes]="true">
          <div fxLayout="column" *ngFor="let project of projectScroll.viewPortItems" class="item"
               (click)="selectProject(project)"
               [ngClass]="{'selected': project.projectId === selectedProject?.projectId}">
            <div fxLayout="row" fxLayoutGap="10px">
              <span class="project-number">#{{project.projectNumber}}</span>
              <div fxLayout="column" fxFlex="1 1 100%">
                <span class="item-name">{{project.projectName}}</span>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="item-address">{{formatAddress(project.projectAddress) | defaultValue: '-'}}</span>
                  <div class="distance" *ngIf="position">
                    <mat-icon>near_me</mat-icon>
                    <span>
                      {{distanceToPosition(project.location)}} {{'general.units.distance.km.abbr'|translate}}
                    </span>
                  </div>
                </div>
                <span>{{project | formatDispositionProjectDateRange}}</span>
              </div>
            </div>
          </div>
        </virtual-scroller>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="'general.stock.s'|translate">
      <ng-container *ngIf="stocks | async; let data">
        <virtual-scroller #stockScroll [items]="data" [bufferAmount]="10"
                          [style.height.px]="ruler.getViewportSize().height - 240"
                          [enableUnequalChildrenSizes]="true">
          <div fxLayout="column" *ngIf="source && isProject(source)" class="item" (click)="selectConsumed()"
               [ngClass]="{'selected': consumedSelected}">
            <div fxLayout="row" fxLayoutGap="10px">
              <fa-icon [icon]="faTrash" class="stock-icon"></fa-icon>
              <div fxLayout="column" fxFlex="1 1 100%">
                <span class="consumed-name">
                  {{'modules.disposition.transferChooseLocation.consumed'|translate}} /
                  {{'modules.disposition.transferChooseLocation.lost'|translate}}
                </span>
                <span class="consumed-desc">
                  {{'modules.disposition.transferChooseLocation.notPostedToStock'|translate}}
                </span>
              </div>
            </div>
          </div>
          <div fxLayout="column" *ngFor="let stock of stockScroll.viewPortItems" class="item" (click)="selectStock(stock)"
               [ngClass]="{'selected': stock.stockId === selectedStock?.stockId}">
            <div fxLayout="row" fxLayoutGap="10px">
              <fa-icon [icon]="stockTypeResolver.resolveIcon(stock.stockType)" class="stock-icon"></fa-icon>
              <div fxLayout="column" fxFlex="1 1 100%">
                <span class="item-name">{{stock.stockName}}</span>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="item-address">{{formatAddress(stock.stockAddress) | defaultValue: '-'}}</span>
                  <div class="distance" *ngIf="position && stock.location">
                    <mat-icon>near_me</mat-icon>
                    <span>{{distanceToPosition(stock.location)}} {{'general.units.distance.km.abbr'|translate}}</span>
                  </div>
                </div>
                <span>{{stock.stockCostCenter | defaultValue: '-'}}</span>
              </div>
            </div>
          </div>
        </virtual-scroller>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
