import { of } from 'rxjs';
import { UsersStore } from '../../modules/userrole/shared/users.store';
import { map } from 'rxjs/operators';

export class EmailInUseValidator {
  static isValid(value: string, userStore: UsersStore, oldEmail?: string) {

    if (oldEmail && value === oldEmail) {
      return of(null);
    }
    return userStore.emailAvailable(value)
      .pipe(map(res => {
        return res ? { taken: true } : null;
      }));

  }
}
