import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { BulkItemType } from 'app/modules/disposition/contract/bulk-item-type';

@Pipe({
  name: 'bulkItemTypesTranslator'
})
export class BulkItemTypesPipe implements PipeTransform {
  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case BulkItemType.CONSUMABLE:
        return this.translate('general.bulkItemType.consumable');
      case BulkItemType.STANDARD:
        return this.translate('general.bulkItemType.standard');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
