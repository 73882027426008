import { LanguageService } from './../services/language.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DamageType } from '../../modules/equipment/contract/damage-type';

@Pipe({
  name: 'damageTypeDisplay'
})

@Injectable()
export class DamageTypeDisplayPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  transform(value: DamageType): string {
    switch (value) {
      case DamageType.VANDALISM:
        return this.translate('shared.pipes.damageTypeDisplay.vandalism');
      case DamageType.STONE_CHIP:
        return this.translate('shared.pipes.damageTypeDisplay.fallingRocks');
      case DamageType.MISUSE:
        return this.translate('shared.pipes.damageTypeDisplay.abusiveUsing');
      case DamageType.THIRD_PARTY_ACCIDENT:
        return this.translate('shared.pipes.damageTypeDisplay.thirdPartyAccident');
      case DamageType.PERSONAL_ACCIDENT:
        return this.translate('shared.pipes.damageTypeDisplay.selfInflictedAccident');
      case DamageType.DAMAGE_CAUSED_BY_WEATHER:
        return this.translate('shared.pipes.damageTypeDisplay.weatherRelatedDamage');
      case DamageType.DAMAGE:
        return this.translate('shared.pipes.damageTypeDisplay.damage');
      default:
        return this.translate('shared.pipes.damageTypeDisplay.unknown');
    }
  }
}
