<cdk-virtual-scroll-viewport #virtualScroll itemSize="45" class="viewport" minBufferPx="300" maxBufferPx="300">
  <ng-content></ng-content>
  <ul class="options__wrapper">
    <li *cdkVirtualFor="let option of optionsList" class="option"
        [style.padding-left]="option.offset * 25 + 'px'"
        [ngClass]="{'child-option': option.offset}">
      <mat-checkbox color="primary"
                    [checked]="option.selected"
                    [disabled]="option.item.disabled"
                    [indeterminate]="option.indeterminate"
                    (click)="toggleCheckbox($event, option)">
        {{option.item.name}}
      </mat-checkbox>
    </li>
  </ul>
</cdk-virtual-scroll-viewport>
