import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { BaseBulkItemViewAttachmentComponent } from '../base/base-bulk-item-view-attachment.component';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { UpdateBulkItemDocumentDescriptionCommand } from '../../../../../contract/update-bulk-item-document-description-command';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { SetBulkItemThumbnailCommand } from '../../../../../contract/set-bulk-item-thumbnail-command';
import { delay } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { DeleteBulkItemDocumentCommand } from 'app/modules/disposition/contract/delete-bulk-item-document-command';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { UpdateBulkItemDocumentNameCommand } from 'app/modules/disposition/contract/update-bulk-item-document-name.command';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'bh-bulk-item-view-attachment',
  templateUrl: './bulk-item-view-attachment.component.html',
  styleUrls: ['./bulk-item-view-attachment.component.scss']
})
export class BulkItemViewAttachmentComponent extends BaseBulkItemViewAttachmentComponent {

  public readonly isEditable = this.hasAnyAuthority([
    Authorities.BULK_ITEMS_MANAGE,
    Authorities.BULK_ITEMS_ADD_ATTACHMENTS
  ]);
  public readonly isDeletable = this.hasAnyAuthority([
    Authorities.BULK_ITEMS_MANAGE,
    Authorities.BULK_ITEMS_DELETE_ATTACHMENTS
  ]);
  public readonly isSetDefaultEnabled = this.hasAnyAuthority([
    Authorities.BULK_ITEMS_MANAGE,
    Authorities.BULK_ITEMS_ADD_ATTACHMENTS
  ]);

  constructor(protected bulkItemStore: BulkItemDatasource,
              protected dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(bulkItemStore, dialog, route, authService, router, routerHistory, languageService);
  }

  public saveDescription(updatedDocument: DocumentLink): void {
    const cmd = new UpdateBulkItemDocumentDescriptionCommand(
      this.bulkItem.bulkItemId,
      updatedDocument.documentKey,
      updatedDocument.fileDescription
    );
    this.bulkItemStore.updateDocumentDescription(cmd);
  }

  public saveFileName(updatedDocument: DocumentLink): void {
    const cmd = new UpdateBulkItemDocumentNameCommand(
      this.bulkItem.bulkItemId,
      updatedDocument.documentKey,
      updatedDocument.fileName
    );
    this.bulkItemStore.updateDocumentName(cmd);
  }

  public deleteDocument(objectKey: string, isImage: boolean): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate(
      isImage ? 'modules.equipment.confirmation.message.deleteImage' : 'modules.equipment.confirmation.message.deleteDoc');
    dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result === dialogResults.YES) {
          this.waiting = true;
          let cmd: DeleteBulkItemDocumentCommand = new DeleteBulkItemDocumentCommand();
          cmd.bulkItemId = this.bulkItem.bulkItemId;
          cmd.objectKey = objectKey;
          this.bulkItemStore.deleteDocument(cmd).subscribe(() => {
            setTimeout(() => {
              this.waiting = false;
              if (isImage && objectKey === this.bulkItem.thumbnail && this.images.length > 1) {
                this.setStandardImage(this.images.find(image =>
                  image.documentKey !== this.bulkItem.thumbnail).documentKey);
              }
            }, 1000);
          });
        }
      });
  }

  public setStandardImage(objectKey: string): void {
    this.waiting = true;
    let cmd: SetBulkItemThumbnailCommand = new SetBulkItemThumbnailCommand();
    cmd.bulkItemId = this.bulkItem.bulkItemId;
    cmd.thumbnailKey = objectKey;
    this.bulkItemStore.setThumbnail(cmd).pipe(
      delay(environment.DELAY_SHORT)
    ).subscribe(data => {
        this.bulkItemStore.updateListing();
        this.bulkItemStore.updateCurrentBulkItem();
        this.waiting = false;
    }, error => {
      this.waiting = false;
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
