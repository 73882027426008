import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TransportComment } from 'app/modules/transportation/contracts/transport/transport-comment.interface';
import { TransportService } from 'app/modules/transportation/shared/services/transport.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddEditCommentDialogComponent } from './transport-comment/add-edit-comment-dialog/add-edit-comment-dialog.component';
import { LanguageService } from '../../../../../../../shared/services/language.service';
import { TransportView } from '../../../../../contracts/transport/transport-view.interface';
import { TransportListDatasource } from '../../../../../shared/services/transport-list.datasource';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { KeycloakService } from 'app/core/keycloak';

@UntilDestroy()
@Component({
  selector: 'bh-transport-view-comments',
  templateUrl: './transport-view-comments.component.html',
  styleUrls: ['./transport-view-comments.component.scss']
})
export class TransportViewCommentsComponent extends RoleAuthorityGuardsComponent implements OnChanges, OnInit {
  @Input() transportId: string;
  @Input() isActive: boolean;
  public comments: TransportComment[];
  public readonly canAddComment = this.hasAnyAuthority([Authorities.TRANSPORT_TASK_MANAGE, Authorities.TRANSPORT_TASK_DISPOSITION]);

  constructor(private transportService: TransportService,
              private languageService: LanguageService,
              private transportDataSource: TransportListDatasource,
              private dialog: MatDialog,
              authService: KeycloakService
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.transportDataSource.comments.subscribe((comments) => {
      this.comments = comments;
    });
  }
  public ngOnChanges(): void {
    if (this.transportId && this.isActive) {
      this.getComments();
    }
  }

  public get transport(): TransportView {
    return this.transportDataSource.transport();
  }

  private getComments(): void {
    this.transportDataSource.getComments(this.transportId);
  }

  public addEditComment(comment: TransportComment | null): void {
    let dialogRef: MatDialogRef<AddEditCommentDialogComponent> = this.dialog.open(AddEditCommentDialogComponent, { restoreFocus: false });
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.comment = comment;
    dialogRef.componentInstance.transport = this.transport;
  }

}
