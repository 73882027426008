import { WidgetConfigParams } from '../contract/widget-config-param';
import { WidgetType } from '../contract/widget-type.enum';
import { WidgetDefinition } from '../widget-definition';
import { TransferRequestWidgetComponent } from './transfer-request-widget.component';

export class TransferRequestWidgetComponentConfiguration extends WidgetDefinition {

  constructor({ title }: WidgetConfigParams) {
    super();
    this.widgetType = WidgetType.TRANSFER_REQUEST;
    this.type = TransferRequestWidgetComponent;
    this.title = title;
    this.minItemCols = 20;
    this.minItemRows = 16;
    this.cols = 20;
    this.rows = 18;
    this.x = 32;
    this.y = 0;
  }
}
