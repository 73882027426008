import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { RemainingValueType } from '../enums/remaining-value-type.enum';
import { UserConfigurationService } from '../services/user-configuration.service';
import { DimensionSystem } from '../enums/dimension-system.enum';

@Pipe({
  name: 'remainingValueType'
})
export class RemainingValueTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService,
              private userConfigurationService: UserConfigurationService) {
  }

  transform(value: any, ...args): any {
    switch (value) {
      case RemainingValueType.DAYS:
        return this.translate('general.units.time.day.pl');
      case RemainingValueType.MILEAGE:
        return this.isMetricSystem()
          ? this.translate('general.units.distance.km.pl')
          : this.translate('general.units.distance.mi.pl');
      case RemainingValueType.OPERATING_HOURS:
        return this.translate('general.operatingHour.pl');
      default:
        return '';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private isMetricSystem(): boolean {
    return this.userConfigurationService.getUserDimensionSystem() === DimensionSystem.METRIC;
  }
}
