import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';

export class UniqueContactIdentifier {
  static inUse(value: string, contactDataSource: ContactDataSource, oldValue?: string) {
    if (value) {
      value = value.trim();
    }
    if (!value || value === oldValue) {
      return of(null);
    }
    return contactDataSource.identifierInUse(value.trim())
      .pipe(map(res => {
        return res ? { contactIdentifierInUse: true } : null;
      }));
  }
}
