<div [formGroup]="teamForm" fxLayout="column">

  <div *ngIf="!currentTeamExists && !addEditMode" class="no-team" fxLayout="column" fxLayoutGap="15px">
    <div>{{'shared.team.noTeam' |translate}}</div>
    <div *ngIf="canManageTeam && allowAddToTeam"
         class="row-button" (click)="enterAddEditMode()" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="plus"><fa-icon [icon]="faPlus"></fa-icon></div>
      <label>{{'shared.team.addTeam' |translate}}</label>
    </div>
  </div>

  <div class="name" fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="{'name-add-edit-mode': addEditMode}">

    <div *ngIf="!addEditMode" class="name-readonly" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <label fxFlex>{{currentTeam?.teamName}}</label>
      <div>
        <fa-icon [icon]="faPencil" class="leave-edit-button" *ngIf="currentTeamExists && canManageTeam"
                 [matTooltip]="'shared.team.editTeam' |translate" (click)="enterAddEditMode()">
        </fa-icon>
      </div>
    </div>

    <div *ngIf="addEditMode" fxFlex="60">
      <div *ngIf="!teamNameEditMode" class="name-edit" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <label>{{currentTeam?.teamName}}</label>
        <fa-icon [icon]="faPencil" class="edit-name-button" *ngIf="currentTeamExists"
                 [matTooltip]="'shared.team.editTeamName' |translate" (click)="enterEditTeamNameMode()">
        </fa-icon>
      </div>
      <div *ngIf="teamNameEditMode" fxFlex fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="6px">
        <mat-form-field class="hide-bottom-wrapper">
          <mat-label>{{'shared.team.teamName' |translate}}</mat-label>
          <input matInput required
                 formControlName="teamName"
                 [readonly]="loading">
          <mat-error *ngIf="teamNameControl.hasError('invalidName')">
            {{'shared.team.nameError' |translate}}
          </mat-error>
        </mat-form-field>
        <fa-icon [icon]="faCheck" class="save-name-button" *ngIf="currentTeamExists && !loading"
                 [matTooltip]="'general.buttons.save' |translate" (click)="updateTeamName()"
                 [matTooltipDisabled]="!teamNameChanged"
                 [ngClass]="{'inactive': !teamNameChanged}">
        </fa-icon>
      </div>
    </div>

    <div *ngIf="addEditMode">
      <div class="leave-edit-button" fxLayout="row" fxLayoutAlign="center center">
        <fa-icon [icon]="faSignOut" *ngIf="addEditMode && !loading && canManageTeam"
                 [matTooltip]="'shared.team.leaveEditTeam' |translate" (click)="leaveAddEditMode()">
        </fa-icon>
        <mat-spinner *ngIf="loading" [strokeWidth]="2" [diameter]="16"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="add-employee" *ngIf="addEditMode"
       fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">

    <mat-form-field>
      <mat-label>{{'shared.team.addTeamMember' |translate}}</mat-label>
      <input matInput
             formControlName="newTeamMember"
             [matAutocomplete]="freeEmployeesAutocomplete"
             [matAutocompleteDisabled]="!teamNameValid || loading"
             [matTooltip]="'shared.team.addNameInvalid' |translate"
             [matTooltipDisabled]="teamNameValid"
             [readonly]="!teamNameValid || loading"
             autocomplete="off">
      <mat-autocomplete #freeEmployeesAutocomplete="matAutocomplete" (optionSelected)="addTeamMember($event.option.value)">
        <mat-option *ngFor="let freeEmployee of filteredFreeEmployees | async" [value]="freeEmployee.employeeId">
          {{ getEmployeeName(freeEmployee) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="center center" class="plus">
      <fa-icon [icon]="faPlus"></fa-icon>
    </div>
  </div>

  <div *ngIf="addEditMode && !currentTeamExists" class="team-member" fxLayout="row" fxLayoutAlign="center">
    <div class="team-member-name" fxLayout="row" fxFlex fxLayoutAlign="start center">
      <label>{{ getEmployeeName(currentEmployee) }}</label>
    </div>
    <div class="team-member-buttons" fxFlex="25px" fxLayoutAlign="center center" fxLayout="row">
      <fa-icon [icon]="faIdCard" class="current-employee"></fa-icon>
    </div>
  </div>

  <div *ngFor="let member of currentTeam.teamMembers | teamMemberSort:currentTeam.teamLeaderEmployeeId"
       class="team-member" fxLayout="row" fxLayoutAlign="center">

    <div class="team-leader" fxFlex="22px" *ngIf="member.employeeIsTeamLeader || addEditMode"
         fxLayout="row" fxLayoutAlign="end center">

      <span *ngIf="loading">
        <fa-icon [icon]="faLightStar" class="team-leader-inactive"></fa-icon>
      </span>

      <span *ngIf="!addEditMode && member.employeeIsTeamLeader">
        <fa-icon [icon]="faSolidStar" class="team-leader-solid"></fa-icon>
      </span>

      <div *ngIf="addEditMode && !loading">
        <span *ngIf="member.employeeIsTeamLeader" class="is-team-leader">
          <fa-icon [icon]="faSolidStar" class="team-leader-solid"></fa-icon>
          <fa-icon [icon]="faLightStar" class="team-leader-light"
                   [matTooltip]="'shared.team.unsetTeamLeader' |translate"
                   (click)="unsetTeamLeader(member.employeeId)"></fa-icon>
        </span>
        <span *ngIf="!member.employeeIsTeamLeader" class="make-team-leader">
          <fa-icon [icon]="faLightStar" class="team-leader-light"></fa-icon>
          <fa-icon [icon]="faSolidStar" class="team-leader-solid"
                   [matTooltip]="'shared.team.setTeamLeader' |translate"
                   (click)="setTeamLeader(member.employeeId)"></fa-icon>
        </span>
      </div>

    </div>

    <div class="team-member-name" fxLayout="row" fxFlex fxLayoutAlign="start center">
      <label>{{ getTeamMemberName(member) }}</label>
    </div>

    <div class="team-member-buttons" fxFlex="25px" fxLayoutAlign="center center" fxLayout="row">
      <fa-icon [icon]="faIdCard"
               [ngClass]="{'current-employee': currentEmployee.employeeId === member.employeeId}"></fa-icon>
    </div>

    <div *ngIf="addEditMode" fxFlex="25px" fxLayout="row" fxLayoutAlign="center center">
      <fa-icon [icon]="faMinusCircle" class="delete-icon" [matTooltip]="'general.buttons.remove' |translate"
               (click)="removeEmployeeFromTeam(member.employeeId)"
               [ngClass]="{'inactive': loading}"></fa-icon>
    </div>
  </div>

</div>
