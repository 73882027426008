import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges, HostListener } from '@angular/core';
import { SafeResourceUrl, DomSanitizer, SafeStyle } from '@angular/platform-browser';


@Component({
  selector: 'bh-gallery-thumbnails',
  templateUrl: './gallery-thumbnails.component.html',
  styleUrls: ['./gallery-thumbnails.component.scss'],
})
export class GalleryThumbnailsComponent implements OnChanges {
  @Input() public images: string[] | SafeResourceUrl[];
  @Input() public links: string[];
  @Input() public labels: string[];
  @Input() public linkTarget: string;
  @Input() public columns: number;
  @Input() public rows: number;
  @Input() public arrows: boolean;
  @Input() public arrowsAutoHide: boolean;
  @Input() public margin: number;
  @Input() public selectedIndex: number;
  @Input() public clickable: boolean;
  @Input() public size: string;
  @Input() public arrowPrevIcon: string;
  @Input() public arrowNextIcon: string;
  @Input() public moveSize: number;
  @Output() public onActiveChange = new EventEmitter();

  public thumbnailsLeft: string;
  public thumbnailsMarginLeft: string;
  public mouseenter: boolean;
  public index = 0;

  constructor(private sanitization: DomSanitizer) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedIndex']) {
      this.validateIndex();
    }
  }

  @HostListener('mouseenter') public onMouseEnter() {
    this.mouseenter = true;
  }

  @HostListener('mouseleave') public onMouseLeave() {
    this.mouseenter = false;
  }

  public reset(index: number): void {
    this.selectedIndex = index;
    this.setDefaultPosition();

    this.index = 0;
    this.validateIndex();
  }

  public handleClick(event: Event, index: number): void {
    if (!this.hasLink(index)) {
      this.selectedIndex = index;
      this.onActiveChange.emit(index);

      event.stopPropagation();
      event.preventDefault();
    }
  }

  public hasLink(index: number): boolean {
    if (this.links && this.links.length && this.links[index]) { return true; }
  }

  public moveRight(): void {
    if (this.canMoveRight()) {
      this.index += this.moveSize;
      let maxIndex = this.getMaxIndex() - this.columns;

      if (this.index > maxIndex) {
        this.index = maxIndex;
      }

      this.setThumbnailsPosition();
    }
  }

  public moveLeft(): void {
    if (this.canMoveLeft()) {
      this.index -= this.moveSize;

      if (this.index < 0) {
        this.index = 0;
      }

      this.setThumbnailsPosition();
    }
  }

  public canMoveRight(): boolean {
    return this.index + this.columns < this.getMaxIndex() ? true : false;
  }

  public canMoveLeft(): boolean {
    return this.index !== 0 ? true : false;
  }

  public getThumbnailLeft(index: number): SafeStyle {
    const calculatedIndex = Math.floor(index / this.rows);
    return this.getThumbnailPosition(calculatedIndex, this.columns);
  }

  public getThumbnailTop(index: number): SafeStyle {
    const calculatedIndex = index % this.rows;
    return this.getThumbnailPosition(calculatedIndex, this.rows);
  }

  public getThumbnailWidth(): SafeStyle {
    return this.getThumbnailDimension(this.columns);
  }

  public getThumbnailHeight(): SafeStyle {
    return this.getThumbnailDimension(this.rows);
  }

  public setThumbnailsPosition(): void {
    this.thumbnailsLeft = - ((100 / this.columns) * this.index) + '%'

    this.thumbnailsMarginLeft = - ((this.margin - (((this.columns - 1)
      * this.margin) / this.columns)) * this.index) + 'px';
  }

  public setDefaultPosition(): void {
    this.thumbnailsLeft = '0px';
    this.thumbnailsMarginLeft = '0px';
  }

  public canShowArrows(): boolean {
    return Boolean(this.arrows
      && this.images
      && this.images.length > this.getVisibleCount()
      && (!this.arrowsAutoHide || this.mouseenter))
  }

  public validateIndex(): void {
    if (this.images) {
      const newIndex = Math.floor(this.selectedIndex / this.rows);

      if (newIndex < this.index || newIndex >= this.index + this.columns) {
        const maxIndex = this.getMaxIndex() - this.columns;
        this.index = newIndex > maxIndex ? maxIndex : newIndex;

        this.setThumbnailsPosition();
      }
    }
  }

  public thumbnailTrackBy(index: number, thumbnail: string | SafeResourceUrl): string | SafeResourceUrl {
    return thumbnail;
  }

  private getThumbnailPosition(index: number, count: number): SafeStyle {
    return this.getSafeStyle('calc(' + ((100 / count) * index) + '% + '
      + ((this.margin - (((count - 1) * this.margin) / count)) * index) + 'px)');
  }

  private getThumbnailDimension(count: number): SafeStyle {
    if (this.margin !== 0) {
      return this.getSafeStyle('calc(' + (100 / count) + '% - '
        + (((count - 1) * this.margin) / count) + 'px)');
    } else {
      return this.getSafeStyle('calc(' + (100 / count) + '% + 1px)');
    }
  }

  private getMaxIndex(): number {
    return Math.ceil(this.images.length / this.rows);
  }

  private getVisibleCount(): number {
    return this.columns * this.rows;
  }

  private getSafeStyle(value: string): SafeStyle {
    return this.sanitization.bypassSecurityTrustStyle(value);
  }
}
