import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bh-rental-equipment-warning-dialog',
  templateUrl: './rental-equipment-warning-dialog.component.html',
  styleUrls: ['./rental-equipment-warning-dialog.component.scss']
})
export class RentalEquipmentWarningDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
