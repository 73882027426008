import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewManufacturer } from '../contract/view-manufacturer.interface';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { map } from 'rxjs/operators';

@Injectable()
export class SpecificationService {
  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/specifications';

  constructor(private http: HttpClient) {
  }

  public getManufacturers(specificationSource: string): Observable<ViewManufacturer[]> {
    const url = this.url + '/manufacturers?source=' + specificationSource;
    return this.http.get<ViewManufacturer[]>(url).pipe(map(manufacturerList => manufacturerList.sort((a, b) => {
      return a.manufacturerName.localeCompare(b.manufacturerName, undefined, {sensitivity: 'base'});
    })));
  }

  public getModels(specificationSource: string, manufacturer: string): Observable<string[]> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('source', specificationSource)
      .set('manufacturer', manufacturer)
    };
    return this.http.get<string[]>(`${this.url}/models`, httpOptions);
  }

  public search(specificationSource: string, searchterm: string): Observable<string[]> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('source', specificationSource)
      .set('searchterm', searchterm)
    };
    return this.http.get<string[]>(`${this.url}/search`, httpOptions);
  }
}
