import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MobileTransferItem } from '../../../../../contract/mobile-transfer-item';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { BulkItemTypeResolver } from '../../../../bulk-item-add-edit/bulk-item-type.resolver';
import { faHorizontalRule, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MobileTransferAmountInputDialogComponent } from '../mobile-transfer-amount-input-dialog/mobile-transfer-amount-input-dialog.component';

@Component({
  selector: 'bh-mobile-transfer-list',
  templateUrl: './mobile-transfer-list.component.html',
  styleUrls: ['./mobile-transfer-list.component.scss']
})
export class MobileTransferListComponent implements OnInit {

  @Input() bulkItems: MobileTransferItem[] = [];
  @Output() entryDeleted: EventEmitter<MobileTransferItem> = new EventEmitter<MobileTransferItem>();
  @Output() entryUpdated: EventEmitter<MobileTransferItem> = new EventEmitter<MobileTransferItem>();

  public readonly faTrash: IconDefinition = faTrash;
  public readonly faHorizontalRule: IconDefinition = faHorizontalRule;
  public readonly faPencil: IconDefinition = faPencil;

  constructor(public bulkItemTypeResolver: BulkItemTypeResolver,
              private dialog: MatDialog,
              private languageService: LanguageService) { }

  ngOnInit() {
  }

  public edit(bulkItem: MobileTransferItem) {
    const dialogRef = this.dialog.open(MobileTransferAmountInputDialogComponent);
    dialogRef.componentInstance.bulkItemAmount = bulkItem;
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        bulkItem.amount = res;
        this.entryUpdated.emit(bulkItem);
      }
    });
  }

  public delete(bulkItem: MobileTransferItem) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.deleteTransferItem');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.deleteTransferItem');
    dialogRef.componentInstance.confirmLabel = this.translate('general.buttons.delete');
    dialogRef.componentInstance.denyLabel = this.translate('general.buttons.cancel');

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'yes') {
        this.entryDeleted.emit(bulkItem);
      }
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
