import { AbstractControl } from '@angular/forms';

export class GeofenceNameExistValidator {
  static isValid(geofenceNames: string[]) {
    return (control: AbstractControl) => {
      if (control.value) {
        let geofence = geofenceNames.find(geofenceName =>
            geofenceName.toLowerCase() === control.value.toLowerCase()
        );

        return geofence ? { geofenceExists: control.value } : null;
      }

      return null;
    };
  }
}
