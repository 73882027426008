import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TelematicUnitAdministrationTypeResolver } from '../shared/telematic-unit-administration-type.resolver';
import { FieldLimit } from '../../../shared/enums/fieldLimit.enum';
import { CreateCustomerTelematicUnitCommand } from '../shared/commands/customer-telematic-unit/create-customer-telematic-unit-command';
import { PartnersService } from '../../organisation/shared/partners.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { TelematicUnitAdministrationDatasource } from '../services/telematic-unit-administration.datasource';
import { TelematicsUnitType } from '../../equipment/contract/telematics-unit-type.enum';
import {
  UpdateCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/update-customer-telematic-unit-command';
import { TelematicUnitListItem } from '../shared/telematic-unit-list.interface';
import { PartnerInfo } from '../shared/partner-info.interface';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { LanguageService } from '../../../shared/services/language.service';
@Component({
  selector: 'bh-telematic-unit-administration-add-edit',
  templateUrl: './telematic-unit-administration-add-edit.component.html',
  styleUrls: ['./telematic-unit-administration-add-edit.component.scss']
})
export class TelematicUnitAdministrationAddEditComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  public telematicUnitForm: UntypedFormGroup;
  public telematicItem: TelematicUnitListItem;
  public telematicUnitTypes = [TelematicsUnitType.TELTONIKA_CABLE_UNIT, TelematicsUnitType.TELTONIKA_OBD_UNIT,
    TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT
  ];
  public readonly fieldLimit = FieldLimit;
  public partnersList: Observable<PartnerInfo[]> = this.telematicUnitStore.filteredPartners;
  public hideSuccessForm = true;
  public hideErrorForm = true;
  public sucessIcon: IconDefinition = faCheckCircle;
  public errorIcon: IconDefinition = faTimesCircle;
  public simCardProvidersList: Observable<string[]> = this.telematicUnitStore.filteredSimCardProviders;
  public errorMessage: string;
  public partnersFilter: UntypedFormControl = new UntypedFormControl();

  constructor(private formBuilder: UntypedFormBuilder,
              protected dialog: MatDialog,
              protected router: Router,
              protected route: ActivatedRoute,
              private partnerService: PartnersService,
              public telematicUnitStore: TelematicUnitAdministrationDatasource,
              private dialogRef: MatDialogRef<TelematicUnitAdministrationAddEditComponent>,
              public telematicUnitAdministrationTypeResolver: TelematicUnitAdministrationTypeResolver,
              private languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.telematicUnitStore.loadAllFields();
    this.buildForm();
    if (this.telematicItem) {
      this.fillForm();
      this.disableControls();
    }
    this.filterSimCardProvidersOnInput();
    this.filterPartnersOnInput();
  }

  private filterSimCardProvidersOnInput(): void {
    this.telematicUnitForm.get('additionalData').get('simCardProvider')
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.telematicUnitStore.filterSimCardProvider(filterTerm));
  }

  private filterPartnersOnInput(): void {
    this.partnersFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.telematicUnitStore.filterPartners(filterTerm));
  }

  private disableControls(): void {
    this.telematicUnitForm.get('telematicItemType').disable();

    if (!this.isEditableImei()) {
      this.telematicUnitForm.get('data').get('imei').disable();
    }
  }

  public isValid(): boolean {
    return this.telematicUnitForm.valid;
  }

  public isEditableImei(): boolean {
    return !this.telematicItem;
  }

  public save(): void {
    if (this.isValid()) {
      const formValue = this.telematicUnitForm.getRawValue();
      if (!this.telematicItem) {
        const createCustomerTelematicUnitCommand = new CreateCustomerTelematicUnitCommand(
          formValue.telematicItemType,
          formValue.data.imei,
          formValue.data.boxNumber,
          formValue.data.serialNumber,
          formValue.data.mac,
          formValue.additionalData.partner,
          formValue.additionalData.simCardProvider,
          formValue.additionalData.eSimIccId,
          formValue.additionalData.eSimImsi,
          null
        );

        this.telematicUnitStore.addCustomerTelematicUnit(createCustomerTelematicUnitCommand).pipe(
          delay(environment.DELAY_SHORT),
        )
          .subscribe(() => {
            this.hideSuccessForm = false;
          }, (error: HttpErrorResponse) => {
            this.hideErrorForm = false;
            this.errorMessage = error.error;
            console.log('ERROR: Error on adding Telematic Item: ', error);
          });
      } else {
        const updateCustomerTelematicUnitCommand = new UpdateCustomerTelematicUnitCommand(
          this.telematicItem.customerTelematicsUnitId,
          formValue.data.boxNumber,
          formValue.data.serialNumber,
          formValue.data.mac,
          formValue.additionalData.partner,
          formValue.additionalData.simCardProvider,
          formValue.additionalData.eSimIccId,
          formValue.additionalData.eSimImsi
        );

        if (this.telematicItem.partnerId !== updateCustomerTelematicUnitCommand.partnerId && !!this.telematicItem.customerId) {
          const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
          dialogRef.componentInstance.confirmTitle = this.translate('modules.telematic.telematicChangePartner.title');
          dialogRef.componentInstance.confirmMessage = this.translate('modules.telematic.telematicChangePartner.warning');
          dialogRef.afterClosed()
            .subscribe(result => {
              if (result === dialogResults.YES) {
                this.updateCustomerTelematicsUnit(updateCustomerTelematicUnitCommand, formValue.telematicItemType);
              }
            });
        } else {
          this.updateCustomerTelematicsUnit(updateCustomerTelematicUnitCommand, formValue.telematicItemType);
        }
      }
    }
  }

  private updateCustomerTelematicsUnit(command: UpdateCustomerTelematicUnitCommand, type: TelematicsUnitType): void {
    this.telematicUnitStore.updateCustomerTelematicsUnit(command, type).subscribe(() => {
      this.hideSuccessForm = false;
    }, (error: HttpErrorResponse) => {
      this.hideErrorForm = false;
      this.errorMessage = error.error;
      console.log('ERROR: Error on updating Telematic Item: ', error);
    });
  }

  protected buildForm(): void {
    this.telematicUnitForm = this.formBuilder.group({
      telematicItemType: ['', Validators.required],
      data: this.formBuilder.group({
        imei: ['', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.maxLength(15)]],
        serialNumber: [''],
        boxNumber: [''],
        mac: ['']
      }),
      additionalData: this.formBuilder.group({
        partner: [null],
        simCardProvider: [''],
        eSimImsi: [''],
        eSimIccId: ['']
      })
    });
  }

  private fillForm(): void {
    this.telematicUnitForm.get('data').patchValue({
      imei: this.telematicItem.telematicsUnitId,
      serialNumber: this.telematicItem.serialNumber,
      boxNumber: this.telematicItem.boxNo,
      mac: this.telematicItem.mac
    });
    this.telematicUnitForm.get('additionalData').patchValue({
      partner: this.telematicItem.partnerId,
      simCardProvider: this.telematicItem.simCardProvider,
      eSimImsi: this.telematicItem.eSim1Imsi,
      eSimIccId: this.telematicItem.eSim1IccId
    });
    this.telematicUnitForm.get('telematicItemType').patchValue(this.telematicItem.telematicsUnitType);
  }

  public returnToList(): void {
    this.router.navigate(['telematic-unit-administration/list'])
    this.dialogRef.close();
  }

  public returnToCreation(): void {
    this.hideErrorForm = true;
    setTimeout(() => {
      this.myStepper.selectedIndex = 1;
    }, 0)
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
