import { Address } from './address.interface';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';

export class AddressLocation implements Address, LatLonLocation {
  public street: string;
  public streetNumber: string;
  public postalCode: string;
  public city: string;
  public location: {
    lat: number;
    lon: number;
  }

  constructor(address?: Address) {
    this.resetAddress(address);
  }

  public get address(): Address {
    return {
      street: this.street,
      streetNumber: this.streetNumber,
      postalCode: this.postalCode,
      city: this.city
    }
  }

  public resetAddress(address?: Address): void {
    this.street = address ? address.street : '';
    this.streetNumber = address ? address.streetNumber : '';
    this.postalCode = address ? address.postalCode : '';
    this.city = address ? address.city : '';
    this.location = {
      lat: null,
      lon: null
    }
  }

  public getAddressString(): string {
    return `${this.street || ''} ${this.streetNumber || ''} ${this.postalCode || ''} ${this.city || ''}`;
  }

  public setLocation(lat: number, lon: number): void {
    this.location.lat = lat;
    this.location.lon = lon;
  }

  public locationExists(): boolean {
    return this.location && Number.isFinite(this.location.lat) && Number.isFinite(this.location.lon);
  }
}
