<div class="edit-transport-type-dialog">
  <h1 mat-dialog-title>{{'modules.equipment.equipmentAddTransportTypesDialog.addTransportTypes'|translate}}</h1>
  <mat-dialog-content #scrollRepo fxLayout="column">
    <mat-error class="not-exist" *ngIf="labelChips?.labelForm?.hasError('transport-type-not-exist')">
      {{'modules.transportation.base.errorCannotCreateNewType'|translate}}
    </mat-error>
    <div class="transport-type-field">
      <mat-label>{{'modules.equipment.equipmentAddTransportTypesDialog.transportTypeChips.placeholder'|translate}}</mat-label>
      <bh-label-chips #labelChips
                      (labelAdded)="addTransportType($event)"
                      (labelDeleted)="removeTransportType($event)"
                      [customerLabels]="transportTypes | async"
                      [hasAuthority]="hasAuthority"
                      [labels]="editedTransportTypes | async"
                      [tooltip]="'modules.equipment.equipmentAddTransportTypesDialog.transportTypeChips.tooltip'|translate"
                      [propertyName]="'general.type'">
      </bh-label-chips>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-raised-button mat-dialog-close="abort">{{'general.buttons.cancel'|translate}}</button>
    <button type="button"
            mat-button
            mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="disable()">{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
