import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PartnerTheme } from 'app/modules/organisation/contract/partner-theme.enum';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { SelectListItem } from './select-list-item.interface';

export interface SelectListItemView extends SelectListItem<any> {
  selected: boolean;
}

@Component({
  selector: 'bh-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectListComponent),
    multi: true
  }]
})
export class SelectListComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() itemTemplate: TemplateRef<any>;
  @Input() set options(options: SelectListItem<any>[]) {
    this.inputOptions = options;
    this.getListItems();
  };
  @Input() set multiselect(value: boolean) {
    if (!value) {
      this.reset();
    }
    this.isMultiselect = value;
  }
  @Input() preventFullDeselection = true;
  @Input() manualOnItemClickHandle = false;
  @Output() onItemClick = new EventEmitter<SelectListItemView>();

  public listItems: SelectListItemView[];
  private inputOptions: SelectListItem<any>[];
  private value: string[];
  private isMultiselect = false;
  private onChange = (value: string[]) => {};
  private onTouched = () => {};

  constructor(private userConfigurationService: UserConfigurationService) { }

  public ngAfterViewInit(): void {
    this.getListItems();
  }

  public ngOnChanges(): void {
    this.getListItems();
  }

  public writeValue(value: string[]): void {
    this.value = value;
    if (!this.isMultiselect && this.value?.length > 1) {
      this.value = [this.value[0]];
      this.onChange(this.value);
    }
    this.getListItems();
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  private getValueAndEmitChange(): void {
    this.value = this.getValue();
    this.onChange(this.value);
    this.onTouched();
  }

  public isWNTheme() {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.WACKER_NEUSON;
  }

  public onClick(item: SelectListItemView): void {
    if (this.manualOnItemClickHandle === true) {
      this.onItemClick.emit(item);
    } else {
      if (item.selected) {
        this.deselect(item);
      } else {
        this.select(item);
      }
    }
  }

  public select({ id: selectedId }: SelectListItemView): void {
    const index = this.listItems.findIndex(({ id }) => id === selectedId);
    if (!this.isMultiselect) {
      this.deselectAll();
    }
    this.listItems.splice(index, 1, { ...this.listItems[index], selected: true });
    this.getValueAndEmitChange();
  }

  public deselect({ id: selectedId }: SelectListItemView): void {
    const index = this.listItems.findIndex(({ id }) => id === selectedId);
    this.listItems.splice(index, 1, { ...this.listItems[index], selected: false });
    this.getValueAndEmitChange();
  }

  public deselectAll(): void {
    this.listItems = this.listItems.map(item => ({ ...item, selected: false }));
  }

  private reset(): void {
    this.deselectAll();
    this.getValueAndEmitChange();
  }

  private getListItems(): void {
    const valueSet = new Set(this.value);
    this.listItems = (this.inputOptions ?? []).map(option => ({ ...option, selected: valueSet.has(option.id) }));
  }

  private getValue(): string[] {
    return this.listItems
      .filter(({ selected }) => selected)
      .map(({ id }) => id);
  }

}
