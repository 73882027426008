import { Component, Input, OnInit } from '@angular/core';
import { ITransferItemWithValidityStatus, TransferItem } from '../../../../../shared/transfer/model/transfer-item';
import { TransferItemType } from '../../../../../shared/transfer/model/transfer-item-type.enum';
import { RequestStatus } from '../../../../../shared/enums/request-status.enum';

@Component({
  selector: 'bh-transfer-summary-table',
  templateUrl: './transfer-summary-table.component.html',
  styleUrls: ['./transfer-summary-table.component.scss']
})
export class TransferSummaryTableComponent implements OnInit {

  @Input()
  public transferItems: TransferItem[];
  @Input()
  public transferItemsWithStatus: ITransferItemWithValidityStatus[] = [];

  public equipmentTransferItems: TransferItem[];
  public bulkItemTransferItems: TransferItem[];
  public displayedColumns = ['name', 'transferAmount'];

  constructor() { }

  ngOnInit(): void {
    this.equipmentTransferItems =
      this.transferItems.filter(item => item.transferItemType === TransferItemType.EQUIPMENT);
    this.bulkItemTransferItems =
      this.transferItems.filter(item => item.transferItemType === TransferItemType.BULKITEM);
  }

  public isSkipped(item: TransferItem): boolean {
    const itemWithStatus = this.transferItemsWithStatus.find(value => value.transferItem.id === item.id);
    return itemWithStatus && itemWithStatus.status === RequestStatus.FAILED;
  }

}
