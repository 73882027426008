import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../core/guards/can-activate-child-authority';
import { Authorities } from '../../shared/enums/authorities.enum';
import { DispositionBoardComponent } from './disposition-board/disposition-board.component';
import { CanActivateModule } from '../../core/guards/can-activate-module';
import { Modules } from '../../shared/enums/modules.enum';
import { CanActivateChildLandscape } from '../../core/guards/can-activate-child-landscape';
import { ProjectListComponent } from './projects/project-list/landscape/project-list.component';
import { ProjectViewComponent } from './projects/project-list/project-view/landscape/project-view.component';
import { ProjectViewDetailComponent } from './projects/project-list/project-view/project-view-detail/landscape/project-view-detail.component';
import { ProjectViewGeofencesComponent } from './projects/project-list/project-view/project-view-geofences/project-view-geofences.component';
import { ProjectViewMapComponent } from './projects/project-list/project-view/project-view-map/landscape/project-view-map.component';
import { ProjectViewCommentsComponent } from './projects/project-list/project-view/project-view-comments/landscape/project-view-comments.component';
import { ProjectViewAttachmentsComponent } from './projects/project-list/project-view/project-view-attachments/landscape/project-view-attachments.component';
import { ProjectViewDispositionsComponent } from './projects/project-list/project-view/project-view-dispositions/landscape/project-view-dispositions.component';
import { ProjectMapComponent } from './projects/project-map/landscape/project-map.component';
import { AssetListComponent } from '../../shared/components/asset-list-component/landscape/asset-list.component';
import { BulkItemViewStocksComponent } from './bulk-items/bulk-item-list/bulk-item-view/bulk-item-view-stocks/landscape/bulk-item-view-stocks.component';
import { BulkItemViewAttachmentComponent } from './bulk-items/bulk-item-list/bulk-item-view/bulk-item-view-attachment/landscape/bulk-item-view-attachment.component';
import { BulkItemViewDetailComponent } from './bulk-items/bulk-item-list/bulk-item-view/bulk-item-view-detail/landscape/bulk-item-view-detail.component';
import { BulkItemViewComponent } from './bulk-items/bulk-item-list/bulk-item-view/landscape/bulk-item-view.component';
import { BulkItemListComponent } from './bulk-items/bulk-item-list/bulk-item-list.component';
import { CurrentBulkItemTabReroute } from './routing/current-bulk-item-tab-reroute';
import { BulkItemTransferListComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-transfer-list/bulk-item-transfer-list.component';
import { StockListComponent } from './stocks/stock-list/stock-list.component';
import { StockViewComponent } from './stocks/stock-list/stock-view/stock-view.component';
import { StockViewDetailComponent } from './stocks/stock-list/stock-view/stock-view-detail/stock-view-detail.component';
import { StockViewAttachmentComponent } from './stocks/stock-list/stock-view/stock-view-attachment/stock-view-attachment.component';
import { StockViewInventoryComponent } from './stocks/stock-list/stock-view/stock-view-inventory/stock-view-inventory.component';
import { BulkItemStockToProjectTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-stock-to-project-transfer-cart/bulk-item-stock-to-project-transfer-cart.component';
import { BulkItemProjectToProjectTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-project-to-project-transfer-cart/bulk-item-project-to-project-transfer-cart.component';
import { BulkItemProjectToStockTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-project-to-stock-transfer-cart/bulk-item-project-to-stock-transfer-cart.component';
import { BulkItemStockToStockTransferCartComponent } from './bulk-items/bulk-item-transfer/landscape/bulk-item-stock-to-stock-transfer-cart/bulk-item-stock-to-stock-transfer-cart.component';
import { ProjectViewTransferHistoryComponent } from './projects/project-list/project-view/project-view-transfer-history/project-view-transfer-history.component';
import { EmployeeListComponent } from './staff/employee-list/employee-list.component';
import { EmployeeViewComponent } from './staff/employee-list/employee-view/employee-view.component';
import { EmployeeViewDetailComponent } from './staff/employee-list/employee-view/employee-view-detail/employee-view-detail.component';
import { CurrentEmployeeTabReroute } from './routing/current-employee-tab-reroute';
import { EmployeeViewQualificationComponent } from './staff/employee-list/employee-view/employee-view-qualification/employee-view-qualification.component';
import { SearchFilterComponent } from 'app/shared/components/search-filter-component/landscape/search-filter.component';
import { EquipmentTransferListComponent } from './equipments/equipment-transfer-list/equipment-transfer-list.component';
import { ChangeTypeOfUseComponent } from './shared/change-type-of-use/change-type-of-use.component';
import { StockViewTransfersComponent } from './stocks/stock-list/stock-view/stock-view-transfers/stock-view-transfers.component';
import { ProjectViewChangeTypeOfUseComponent } from './projects/project-list/project-view/project-view-change-type-of-use/project-view-change-type-of-use.component';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';
import { StockMapComponent } from './stocks/stock-map/stock-map.component';
import {
  StockViewGeofencesComponent
} from './stocks/stock-list/stock-view/stock-view-geofences/stock-view-geofences.component';

const dispositionRoutes: Routes = [
  {
    path: 'assets',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'bulk-item',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.BULK_ITEMS_VIEW],
          modules: [Modules.BULK_ITEM_MANAGEMENT],
          hideRouteButton: true
        },
        children: [
          {
            path: 'add',
            component: BulkItemListComponent,
            data: {
              authorities: [Authorities.BULK_ITEMS_MANAGE],
              create: true
            },
          },
          {
            path: 'list',
            component: BulkItemListComponent,
            canActivate: [CanActivateModule],
            data: {
              authorities: [Authorities.BULK_ITEMS_VIEW],
              modules: [Modules.BULK_ITEM_MANAGEMENT]
            },
            children: [
              {
                path: ':id',
                component: BulkItemViewComponent,
                data: {
                  authorities: [Authorities.BULK_ITEMS_VIEW]
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    children: [],
                    canActivate: [CurrentBulkItemTabReroute] // walkaround to achieve dynamic rerouting
                  },
                  {
                    path: 'general',
                    component: BulkItemViewDetailComponent,
                    data: {
                      authorities: [Authorities.BULK_ITEMS_VIEW]
                    }
                  },
                  {
                    path: 'attachment',
                    component: BulkItemViewAttachmentComponent,
                    data: {
                      authorities: [Authorities.BULK_ITEMS_VIEW]
                    }
                  },
                  {
                    path: 'stocks',
                    component: BulkItemViewStocksComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.BULK_ITEMS_VIEW],
                      modules: [Modules.BULK_ITEM_MANAGEMENT]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'employee',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.EMPLOYEE_VIEW],
          modules: [Modules.BASE],
          hideRouteButton: true
        },
        children: [
          {
            path: 'add',
            component: EmployeeListComponent,
            data: {
              authorities: [Authorities.EMPLOYEE_MANAGE],
              create: true
            },
          },
          {
            path: 'list',
            component: EmployeeListComponent,
            canActivate: [CanActivateModule],
            data: {
              authorities: [Authorities.EMPLOYEE_VIEW],
              modules: [Modules.BASE]
            },
            children: [
              {
                path: ':id',
                component: EmployeeViewComponent,
                data: {
                  authorities: [Authorities.EMPLOYEE_VIEW]
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    children: [],
                    canActivate: [CurrentEmployeeTabReroute] // walkaround to achieve dynamic rerouting
                  },
                  {
                    path: 'general',
                    component: EmployeeViewDetailComponent,
                    data: {
                      authorities: [Authorities.EMPLOYEE_VIEW]
                    }
                  },
                  {
                    path: 'qualification',
                    component: EmployeeViewQualificationComponent,
                    data: {
                      authorities: [Authorities.EMPLOYEE_VIEW]
                    }
                  },
                  // disabled until there is no story for displaying dispositions in the detail-view of an employee
                  /*{
                    path: 'disposition',
                    component: EmployeeViewDispositionComponent,
                    data: {
                      authorities: [Authorities.EMPLOYEE_VIEW]
                    }
                  }*/
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'projects',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'map',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.DISPOSITION]
        },
        children: [
          {
            path: '',
            component: ProjectMapComponent
          }
        ]
      }
    ]
  },
  {
    path: 'stocks',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape],
    children: [
      {
        path: 'map',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE]
        },
        children: [
          {
            path: '',
            component: StockMapComponent
          }
        ]
      }
    ]
  },
  {
    path: 'sites',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'projects',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.DISPOSITION]
        },
        children: [
          {
            path: 'list',
            component: ProjectListComponent,
            data: {
              authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            },
            children: [
              {
                path: ':id',
                component: ProjectViewComponent,
                data: {
                  authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                  modules: [Modules.DISPOSITION]
                },
                children: [
                  {
                    path: 'general',
                    component: ProjectViewDetailComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'geofences',
                    component: ProjectViewGeofencesComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.LIVE_DATA]
                    }
                  },
                  {
                    path: 'map',
                    component: ProjectViewMapComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'comments',
                    component: ProjectViewCommentsComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'attachments',
                    component: ProjectViewAttachmentsComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'assignments',
                    component: ProjectViewDispositionsComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'transfer-history',
                    component: ProjectViewTransferHistoryComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
                    }
                  },
                  {
                    path: 'change-type-of-use',
                    component: ProjectViewChangeTypeOfUseComponent,
                    data: {
                      authorities: [Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
                    }
                  },
                ]
              }
            ]
          },
          {
            path: 'add',
            component: ProjectListComponent,
            data: {
              authorities: [Authorities.PROJECT_CREATE, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION],
              create: true
            }
          },
        ]
      },
      {
        path: 'stocks',
        component: SearchFilterComponent,
        canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape, CanActivateChildMobileWebApp],
        data: {
          authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE],
          hideFilterForm: true
        },
        children: [
          {
            path: 'add',
            component: StockListComponent,
            data: {
              authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE],
              create: true
            }
          },
          {
            path: 'list',
            component: StockListComponent,
            data: {
              authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE]
            },
            children: [
              {
                path: ':id',
                component: StockViewComponent,
                data: {
                  modules: [Modules.DISPOSITION],
                  authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE]
                },
                children: [
                  {
                    path: 'general',
                    component: StockViewDetailComponent,
                    data: {
                      modules: [Modules.DISPOSITION],
                      authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE]
                    }
                  },
                  {
                    path: 'geofences',
                    component: StockViewGeofencesComponent,
                    data: {
                      authorities: [Authorities.STOCK_VIEW],
                      modules: [Modules.AUTOMATED_TRANSFERS]
                    }
                  },
                  {
                    path: 'attachments',
                    component: StockViewAttachmentComponent,
                    data: {
                      modules: [Modules.DISPOSITION],
                      authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE]
                    }
                  },
                  {
                    path: 'inventory',
                    component: StockViewInventoryComponent,
                    data: {
                      modules: [Modules.DISPOSITION],
                      authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE]
                    }
                  },
                  {
                    path: 'transfer',
                    component: StockViewTransfersComponent,
                    data: {
                      authorities: [Authorities.STOCK_VIEW, Authorities.STOCK_MANAGE],
                      modules: [Modules.DISPOSITION]
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'disposition',
    component: ContentComponent,
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    data: {
      modules: [Modules.DISPOSITION]
    },
    children: [
      {
        path: 'list',
        component: DispositionBoardComponent,
        data: {
          authorities: [Authorities.PROJECT_VIEW],
          authoritiesDisallowed: [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.SUPERADMIN_VIEW, Authorities.PARTNER_VIEW],
          modules: [Modules.DISPOSITION]
        }
      },
      {path: '', redirectTo: 'general', pathMatch: 'full'},
      {path: '**', redirectTo: 'general'}
    ]
  },
  {
    path: 'transfer',
    component: ContentComponent,
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    data: {
      modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'equipment-transfer-list'
      },
      {
        path: 'change-type-of-use/:projectId',
        component: ChangeTypeOfUseComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE,
            Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE],
        }
      },
      {
        path: 'equipment-transfer-list',
        component: SearchFilterComponent,
        data: {
          authorities: []
        },
        children: [
          {
            path: '',
            component: EquipmentTransferListComponent,
            data: {
              authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
              modules: [Modules.DISPOSITION]
            }
          }
        ]
      },
      {
        path: 'bulk-item-transfer-list',
        component: AssetListComponent,
        data: {
          authorities: []
        },
        children: [
          {
            path: '',
            component: BulkItemTransferListComponent,
            data: {
              authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
              modules: [Modules.BULK_ITEM_MANAGEMENT]
            }
          }
        ]
      },
      {
        path: 'stock-to-project-cart',
        component: BulkItemStockToProjectTransferCartComponent,
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
        }
      },
      {
        path: ':sourceId/clear-project',
        component: BulkItemProjectToStockTransferCartComponent,
        data: {
          authorities: [Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
        }
      },
      {
        path: ':sourceId/project-to-project-cart',
        component: BulkItemProjectToProjectTransferCartComponent,
        data: {
          authorities: [Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
        }
      },
      {
        path: ':sourceId/stock-to-stock-cart',
        component: BulkItemStockToStockTransferCartComponent,
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER],
          modules: [Modules.DISPOSITION, Modules.BULK_ITEM_MANAGEMENT]
        }
      },
      {path: '', redirectTo: 'general', pathMatch: 'full'},
      {path: '**', redirectTo: 'general'}
    ]
  }
];


export const dispositionRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(dispositionRoutes);
