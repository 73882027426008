export enum BrowserName {
    CHROME = 'CHROME',
    EDGE = 'EDGE',
    FIREFOX = 'FIREFOX',
    IE = 'IE',
    OPERA = 'OPERA',
    OTHER = 'OTHER',
    SAFARI = 'SAFARI',
    TRIDENT = 'TRIDENT'
  }
