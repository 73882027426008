import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WarningLevel } from 'app/shared/enums/warning-level.enum';
import { MaintenanceTaskSearch } from '../../../shared/maintenance-task';

@Component({
  selector: 'bh-maintenance-task-list-item',
  templateUrl: './maintenance-task-list-item.component.html',
  styleUrls: ['./maintenance-task-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskListItemComponent {
  @Input() public task: MaintenanceTaskSearch;
  public warningLevel = WarningLevel;
  constructor() { }
}
