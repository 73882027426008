import { Pipe, PipeTransform } from '@angular/core';
import { ViewStock } from 'app/modules/disposition/contract/view-stock';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';

@Pipe({
  name: 'mapMarkerConvertStockView'
})
export class MapMarkerConvertStockViewPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: ViewStock[]): MapLocationMarker<ViewStock>[] {
    return Array.isArray(value) ? this.getMapLocationMarkers(value) : null;
  }

  private getMapLocationMarkers(items: ViewStock[]): MapLocationMarker<ViewStock>[] {
    return items.filter(Boolean).map(item => ({
      id: item.stockId,
      location: item.location,
      item,
      icon: this.getIconUrl(item),
    }));
  }

  private getIconUrl(item: ViewStock): string {
    return this.iconResolver.getIconStock(item);
  }
}
