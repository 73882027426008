import { Pipe, PipeTransform } from '@angular/core';
import { TransportAssignment } from '../../contracts/transport/transport-assignment.interface';
import { TransportBase } from '../../contracts/transport/transport-base.interface';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { TransportTaskChecker } from '../services/transport-task-checker.service';

@Pipe({
  name: 'isTransportDueDatePassed'
})
export class IsTransportDueDatePassedPipe implements PipeTransform {
  public transform(transport: TransportView | TransportAssignment | TransportBase): boolean {
    return TransportTaskChecker.isDueDatePassed(transport);
  }
}
