<bh-basic-widget [widget]="widget"
                 [index]="index"
                 (configurationSave)="onConfigurationSave($event)"
                 (openedConfigurations)="openedConfigurations($event)"
                 (deleted)="onDeleted()">
  <ng-container widget-content>
    <div id="pie-container" *ngIf="equipmentsCount.length">
      <canvas baseChart
              [type]="'doughnut'"
              [data]="chartData"
              [options]="chartOptions">
      </canvas>
    </div>

    <div *ngIf="!equipmentsCount.length" class="no-entries">
      <h3>{{ 'general.noAvailableEntries'|translate }}</h3>
    </div>
  </ng-container>
  <div widget-configuration>
    <mat-form-field [floatLabel]="organisationsPlaceholder.floatLabel">
      <mat-label>{{ 'general.org.pl'|translate }}</mat-label>
      <input matInput
             [matAutocomplete]="organisations"
             [placeholder]="organisationsPlaceholder.placeholder"
             (keypress)="preventDefaultEvent($event)">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #organisations="matAutocomplete"
                        (opened)="organisationsSelectionTree.open()"
                        (closed)="organisationsSelectionTree.getSelectedOptions()">
        <bh-selection-tree #organisationsSelectionTree
                           [optionsList]="organisationsList"
                           (finalSelectedOptionsList)="organisationsListClosed()">
        </bh-selection-tree>
        <mat-option style="display: none;">Unused option</mat-option>
      </mat-autocomplete>
    </mat-form-field>




    <mat-form-field [floatLabel]="typesPlaceholder.floatLabel">
      <mat-label>{{ 'general.types'|translate }}</mat-label>
      <input matInput
             [matAutocomplete]="types"
             [placeholder]="typesPlaceholder.placeholder"
             (keypress)="preventDefaultEvent($event)">
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #types="matAutocomplete"
                        (opened)="typesSelectionTree.open()"
                        (closed)="typesSelectionTree.getSelectedOptions()">
        <bh-selection-tree #typesSelectionTree
                           [optionsList]="typesList"
                           (finalSelectedOptionsList)="typesListClosed()">
        </bh-selection-tree>
        <mat-option style="display: none;">Unused option</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</bh-basic-widget>
