<div id="dialog" class="dialog-wrapper">
  <h1 mat-dialog-title>{{ruleName}}</h1>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <span class="quiet">{{'modules.maintenance.maintenanceRuleAssignedEquipm.assignedEquipments'|translate}}</span>
  <div mat-dialog-content>
    <div id="dialog-content">
      <mat-list>
        <mat-list-item class="list-item" *ngFor="let equipment of equipments">
          {{equipment}}
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
