import { Modules } from './../../../../shared/enums/modules.enum';
import { TransportItemType } from './../../shared/enums/transport-item-type.enum';
import { Router } from '@angular/router';
import { KeycloakService } from 'app/core/keycloak';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TransportItemTypeResolverPipe } from '../../shared/pipes/transport-item-type-resolver.pipe';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportItem } from '../../../equipment/shared/transport-items.interface';
import { StockType } from '../../../../shared/enums/stock-type';
import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';
import { TransportStatePipe } from '../../shared/pipes/transport-state.pipe';

export class TransportDetailViewBase extends RoleAuthorityGuardsComponent {
  private readonly canSeeTransportItemLinkMap = {
    [TransportItemType.CONTACT]:
      this.hasModule(Modules.CONTACT_MANAGEMENT) && this.hasAnyAuthority([Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]),
    [TransportItemType.PROJECT]:
      this.hasModule(Modules.DISPOSITION)
      && this.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]),
    [TransportItemType.STOCK]:
      this.hasModule(Modules.BULK_ITEM_MANAGEMENT) && this.hasAuthority(Authorities.STOCK_VIEW),
  };

  constructor(
    private router: Router,
    protected languageService: LanguageService,
    protected transportItemTypeResolver: TransportItemTypeResolverPipe,
    protected transportStatePipe: TransportStatePipe,
    authService: KeycloakService
  ) {
    super(authService);
  }

  public isUserOrganisationId(organisationId: string, transportItem?: TransportItem): boolean {
    return (transportItem?.transportItemType === TransportItemType.STOCK && transportItem?.stockType === StockType.MAIN)
        || this.isAllowedToChangeOrganisation(organisationId);
  }

  public navigateToEquipment(equipmentId: string): Promise<any> | void {
    if (equipmentId) {
      return this.router.navigateByUrl(`assets/equipment/list/${equipmentId}`);
    }
  }

  public navigateToTransport(transportId: string): Promise<any> | void {
    if (transportId) {
      return this.router.navigateByUrl(`transportation/list/${transportId}`);
    }
  }

  public navigateTo(url: string): Promise<any> | void {
    if (url) {
      return this.router.navigateByUrl(url);
    }
  }

  public canSeeLink(type: TransportItemType): boolean {
    return Boolean(this.canSeeTransportItemLinkMap[type]);
  }

  public getTooltipLink(type: TransportItemType): string {
    return this.translate('general.labels.jumpTo', {
      value: this.transportItemTypeResolver.transform(type)
    });
  }

  public getTooltipLatestItemInfo(state: TransportTaskState, type: TransportItemType): string {
    return this.translate(
      'modules.transportation.transportDetailView.warningRelatedItemChangedNavigate',
      {
        state: this.transportStatePipe.transform(state),
        type: this.transportItemTypeResolver.transform(type)
      }
    );
  }

  protected translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  protected getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
