import { Address } from '../../../../../organisation/contract/address.interface';
import { TransportSearch } from '../../../../contracts/transport/transport-search.interface';
import { Component, Input, OnInit } from '@angular/core';
import { AddressService } from 'app/shared/services/address.service';

enum TransportItemDirectionType {
  SOURCE = 'SOURCE',
  TARGET = 'TARGET'
}

interface TransportItemContext {
  directionType: TransportItemDirectionType;
  name: string;
  address: string;
}

@Component({
  selector: 'bh-transport-list-item',
  templateUrl: './transport-list-item.component.html',
  styleUrls: ['./transport-list-item.component.scss']
})
export class TransportListItemComponent implements OnInit {
  @Input() transport: TransportSearch;
  @Input() listView = false;

  public readonly transportItemDirectionType = TransportItemDirectionType;

  constructor(private addressService: AddressService) {
  }

  public ngOnInit(): void {
  }

  public getTransportStartContext(): TransportItemContext {
    return this.getTransportItemContext(
      TransportItemDirectionType.SOURCE,
      this.transport.startItemName,
      this.transport.startItemAddress
    );
  }

  public getTransportTargetContext(): TransportItemContext {
    return this.getTransportItemContext(
      TransportItemDirectionType.TARGET,
      this.transport.targetItemName,
      this.transport.targetItemAddress
    );
  }

  private getTransportItemContext(
    directionType: TransportItemDirectionType,
    transportItemName: string,
    address: Address
  ): TransportItemContext {
    return {
      directionType,
      name: transportItemName || '-',
      address: this.formatAddress(address)
    }
  }

  private formatAddress(address: Address): string {
    return address
      ? this.addressService.formatFullAddressSingleLine(
          address.postalCode,
          address.city,
          address.street,
          address.streetNumber)
      : '-';
  }
}
