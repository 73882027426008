import { environment } from 'environments/environment';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentsDataSource } from '../../equipments.datasource';
import { BehaviorSubject, Subscription } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { PairEquipmentScanCodeCommand } from '../../../contract/pair-equipment-scan-code-command';
import { ScanEquipment } from '../../scan-equipment';
import { TorchInfo } from '../../../../../shared/components/scanner/torch-info.interface';
import { RouterHistory } from '../../../../../shared/router-history';
import { LocalUserStorageService } from '../../../../../shared/services/local-user-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ScanCodeStore } from '../../../../../shared/services/scan-code.store';
import { ScanCodeEntry } from '../../../../../shared/contract/scan-code-entry.interface';
import { AssetType } from '../../../../../shared/contract/asset-type.enum';
import { EquipmentAmountInfo } from 'app/modules/disposition/contract/equipment-amount-info';

declare const EB: any;

@UntilDestroy()
@Component({
  selector: 'bh-equipment-scan-code-scanner',
  templateUrl: './mobile-equipment-scan-code-scanner.component.html',
  styleUrls: ['./mobile-equipment-scan-code-scanner.component.scss']
})
export class MobileEquipmentScanCodeScannerComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public cameraInitialized = false;
  public torchAvailable = false;
  public torchEnabled = false;
  public torch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public scanResultString: string;
  public transferMode = false;
  public addOpHoursMode = false;
  public addDamageMode = false;
  public alreadyPaired = false;
  public codeNotFound = false;
  public amountNotFound = false;
  public equipmentReserved = false;
  public wrongAssetType: AssetType = null;
  public equipmentForbidden = false;
  public equipmentInactive = false;
  public pairMode = false;
  public getByScanCodeSucceed = false;
  public duplicatedEntry = false;
  public foundEquipment: ScanEquipment;
  public equipmentList: ScanEquipment[] = [];
  public equipmentHasLiveOperatingHours = false;
  public pairScanCodeSucceed = false;
  public cameraAccessFailed = false;
  public isEnterprise = false;
  public zebraReady = false;

  private target: string;
  private scanCodeRecognised = false;
  private pairScanCodeSub: Subscription;
  private pairingActive = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected scanCodeStore: ScanCodeStore,
              protected equipmentsStore: EquipmentsDataSource,
              protected routerHistory: RouterHistory,
              protected detector: ChangeDetectorRef) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.target = this.route.snapshot.url[0].path;
    this.pairMode = [
      'add-damage',
      'search-scancode',
      'transfer-to-project',
      'operating-hours'
    ].indexOf(this.target) === -1;
    this.transferMode = this.target === 'transfer-to-project';
    this.addOpHoursMode = this.target === 'operating-hours';
    this.addDamageMode = this.target === 'add-damage';

    this.subscribeToScanCodeInUse();
    this.subscribeToEquipmentByScanCode();
    this.subscribeToEquipmentAmountInfoError();

    this.route.queryParams
    .pipe(filter(params => params.listExists))
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.equipmentsStore.scanEquipmentsForTransfer.pipe(untilDestroyed(this)).subscribe(
        (scanEquipmentsForDisposition: ScanEquipment[]) => {
        this.equipmentList = scanEquipmentsForDisposition;
        this.foundEquipment = this.equipmentList[this.equipmentList.length - 1];
        if (this.equipmentList.length > 0) {
          this.getByScanCodeSucceed = true;
        }
      });
    });

    if (LocalUserStorageService.getUserValue('isEnterprise', this.getUserUserId()) === 'true') {
      this.isEnterprise = true;
      this.activateZebraScanner();
    }
  }

  private subscribeToScanCodeInUse(): void {
    this.scanCodeStore.scanCodeInUse
    .pipe(untilDestroyed(this))
    .subscribe((scanCodeEntry: ScanCodeEntry) => {
      if (scanCodeEntry.assetType === AssetType.EQUIPMENT) {
        this.equipmentsStore.searchScanCode(scanCodeEntry.scanCode, this.transferMode);
        this.wrongAssetType = null;
      } else {
        this.wrongAssetType = scanCodeEntry.assetType
      }
    });

    this.scanCodeStore.scanCodeInUseError
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.codeNotFound = true;
      this.detector.detectChanges();
    });
  }

  private subscribeToEquipmentByScanCode(): void {
    this.equipmentsStore.equipmentByScanCode
    .pipe(untilDestroyed(this))
    .subscribe((scanEquipment: ScanEquipment) => {
      this.foundEquipment = scanEquipment;
      this.transferMode ? this.equipmentsStore.getEquipmentAmountInfo(scanEquipment.equipmentId) :
       this.equipmentReceived();
    });

    this.equipmentsStore.equipmentByScanCodeErrors
    .pipe(untilDestroyed(this))
    .subscribe((error: HttpErrorResponse) => {
      if (error.status === 403) {
        this.codeNotFound = false;
        this.equipmentForbidden = true;
      } else if (error.status === 423) {
        this.equipmentInactive = true;
      } else {
        this.codeNotFound = true;
        this.equipmentForbidden = false;
      }
      this.detector.detectChanges();
    });
  }

  private subscribeToEquipmentAmountInfoError(): void {
    this.equipmentsStore.equipmentAmountInfo
    .pipe(untilDestroyed(this))
    .subscribe((info: EquipmentAmountInfo) => {
      if (info.amountReserved > 0) {
        this.equipmentReserved = true;
      } else {
        this.foundEquipment.currentLocationId = info.currentLocationId;
        this.foundEquipment.currentLocationType = info.currentLocationType;
        this.foundEquipment.sourceStockId = info.sourceStockId;
        this.equipmentReceived();
      }
    });

  this.equipmentsStore.equipmentAmountInfoError
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.amountNotFound = true;
      this.detector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    if (this.isEnterprise) {
      EB.Barcode.disable();
    }
    this.turnOffTorch();
  }

  public toggleTorch(): void {
    this.torchAvailable = false;
    this.torch.next(!this.torch.value);
  }

  public closeCameraView(): void {
    this.turnOffTorch();
    if (this.transferMode) {
      this.equipmentsStore.setScanEquipmentListForTransfer([]);
      this.router.navigate(['']);
    } else if (this.addOpHoursMode || this.addDamageMode) {
      this.router.navigate(['']);
    } else {
      this.router.navigate([this.routerHistory.getPreviousUrl()]);
    }
  }

  public navigateToEquipmentAdd() {
    this.router.navigate(['mobile/equipments/add'], {
      state: {
        qrCode: this.scanResultString,
        fromEquipmentList: true
      }
    });
  }

  public handleScanCodeResult(resultString: string): void {
    if (this.alreadyPaired && this.scanResultString !== resultString) {
      this.alreadyPaired = false;
    }
    this.scanCodeRecognised = true;
    setTimeout(() => {
      this.scanResultString = resultString;
      this.getByScanCodeSucceed = false;
      this.scanCodeStore.searchScanCodeInUse(this.scanResultString);
    }, environment.DELAY_SHORTEST);
    setTimeout(() => {
      this.scanCodeRecognised = false;
    }, environment.DELAY_SHORT);
  }

  public handleScannerInitializeError(error: string): void {
    this.cameraAccessFailed = true;
  }

  public handleCameraInitialized(cameraInitialized: boolean): void {
    this.cameraInitialized = cameraInitialized;
    this.cameraAccessFailed = false;
  }

  public handleTorchUpdate(torch: TorchInfo): void {
    this.torchAvailable = torch.torchAvailable;
    this.torchEnabled = torch.torchEnabled;
  }

  public editTransferList(): void {
    this.reset();
    if (this.equipmentList.length === 1) {
      this.equipmentsStore.setScanEquipmentListForTransfer([this.foundEquipment]);
      this.router.navigate([`mobile/equipments/${this.foundEquipment.equipmentId}/${this.target}`]);
    } else {
      this.equipmentsStore.setScanEquipmentListForTransfer(this.equipmentList);
      this.router.navigate([`mobile/equipments/transfer-equipments-to-project-list`]);
    }
  }

  public pairingAllowed(): boolean {
    return this.pairMode && this.scanResultString && !this.alreadyPaired && !this.pairingActive && !this.wrongAssetType;
  }

  public pairScanCode(): void {
    this.pairingActive = true;
    const cmd = new PairEquipmentScanCodeCommand();
    cmd.equipmentId = this.target;
    cmd.equipmentScanCode = this.scanResultString;
    this.pairScanCodeSub = this.equipmentsStore.pairScanCode(cmd).pipe(untilDestroyed(this)).subscribe(res => {
      if (res) {
        this.pairScanCodeSucceed = true;
        setTimeout(() => {
          this.turnOffTorch();
          this.router.navigate([`mobile/equipments/view/${this.target}/general`]);
          this.pairingActive = false;
        }, environment.DELAY_SHORT);
      }
    });
  }

  public navigateToSupport(): void {
    window.open('https://www.onestop-pro.de/support/');
  }

  private navigateTo(): void {

    this.duplicatedEntry = !!this.equipmentList
    .find(scanEquipment => scanEquipment.equipmentId === this.foundEquipment.equipmentId);

    if (this.transferMode) {
      if (!this.duplicatedEntry) {
        this.equipmentList.push(this.foundEquipment);
      }
    } else {
      this.equipmentsStore.changeCurrentEquipment(this.foundEquipment.equipmentId);

      if (this.target !== 'search-scancode') {
        this.reset();
        this.router.navigate([`mobile/equipments/${this.foundEquipment.equipmentId}/${this.target}`]);
      } else {
        this.reset();
        this.router.navigate([`mobile/equipments/view/${this.foundEquipment.equipmentId}`]);
      }
    }
  }

  private turnOffTorch(): void {
    this.torch.next(false);
  }

  private reset(): void {
    this.turnOffTorch();
    this.scanResultString = null;
    this.scanCodeRecognised = false;
    this.getByScanCodeSucceed = false;
    this.alreadyPaired = false;
  }

  private activateZebraScanner() {
    EB.Barcode.enable({}, (code) => {
      this.handleScanCodeResult(code.data);
    });

    this.zebraReady = true;
  }

  public equipmentCreateAllowed(): boolean {
    return !this.transferMode
      && !this.pairMode
      && !this.wrongAssetType
  }

  public equipmentReceived(): void {
    this.codeNotFound = false;
    this.amountNotFound = false;
    this.equipmentReserved = false;
    this.equipmentForbidden = false;
    this.getByScanCodeSucceed = true;
    this.equipmentHasLiveOperatingHours = this.foundEquipment.hasLiveOperatingHours && this.addOpHoursMode;

    if (this.pairMode) {
      this.alreadyPaired = true;
    } else if (!this.equipmentHasLiveOperatingHours) {
        this.navigateTo();
      }
  }
}

