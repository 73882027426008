import { environment } from 'environments/environment';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import {
  GuardedNavigableInputComponent
} from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MaintenanceTaskDataSource } from '../../../../shared/maintenance-task.datasource';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { CreateReminderCommand } from '../../../../contract/create-reminder-command';
import { DatesService } from '../../../../../../shared/services/dates.service';
import { MaintenanceService } from '../../../../shared/maintenance.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AssignMaintenanceRuleDialogComponent
} from './assign-maintenance-rule-dialog/assign-maintenance-rule-dialog.component';
import { DeleteReminderCommand } from '../../../../contract/delete-reminder-command';
import {
  UnassignMaintenanceRuleCommand
} from '../../../../../maintenance/rules/shared/contract/commands/unassign-maintenance-rule.command';
import { delay } from 'rxjs/operators';
import { TaskRule } from '../../../../../../shared/contract/task/task-rule';
import { CompleteReminderCommand } from '../../../../contract/complete-reminder-command';
import { ReminderTask } from '../../../../../maintenance/tasks/shared/reminder-task';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../../../shared/enums/modules.enum';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { RouterHistory } from '../../../../../../shared/router-history';
import { FieldLimit } from '../../../../../../shared/enums/fieldLimit.enum';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import {
  MaintenanceTaskCompletedDataSource
} from 'app/modules/equipment/shared/services/maintenance-task-completed.datasource';
import { LocalUserStorageService } from 'app/shared/services/local-user-storage.service';
import {
  EquipmentMaintenanceTaskTabContentControllerService
} from 'app/modules/equipment/shared/services/tab-content-controller/equipment-maintenance-task-tab-content-controller.service';
import {
  EquipmentMaintenanceTaskTab
} from 'app/modules/equipment/shared/contracts/equipment-maintenance-task-tab.enum';
import { Task } from 'app/shared/contract/task/task';
import { asyncValidatorFactory } from 'app/shared/custom-validators/async-validator.factory';
import { ReminderNameInUseValidator } from 'app/shared/custom-validators/reminder-name-in-use.validator';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-tasks',
  templateUrl: './equipment-view-tasks.component.html',
  styleUrls: ['./equipment-view-tasks.component.scss']
})
export class EquipmentViewTasksComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit {
  @ViewChild('detailTabGroup', { static: true }) public detailTabGroup: MatTabGroup;
  private equipment: ViewEquipment;
  public reminderForm: UntypedFormGroup;
  public today: Date = new Date();
  public readonly tasks = this.tabContentController.tasks;
  public readonly completedTasks = this.tabContentController.completedTasks;
  public readonly fieldLimit = FieldLimit;
  public isReminderNameInUse = false;
  private readonly localStorageKey: string = 'equipment-view-task-tab';
  public readonly allowedToCreateReminder = this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE)
    && this.authService.hasModule(Modules.SERVICE);
  private readonly reminderNameInUseErrorName = 'reminderNameInUse';
  public isTaskLoading = this.maintenanceTaskStore.isLoading;
  public isCompletedTaskLoading = this.maintenanceTaskCompletedStore.isLoading;

  constructor(public equipmentStore: EquipmentsDataSource,
              public maintenanceTaskStore: MaintenanceTaskDataSource,
              public maintenanceTaskCompletedStore: MaintenanceTaskCompletedDataSource,
              protected maintenanceService: MaintenanceService,
              protected formBuilder: UntypedFormBuilder,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              private equipmentCheckerService: EquipmentCheckerService,
              private localStorageService: LocalUserStorageService,
              private tabContentController: EquipmentMaintenanceTaskTabContentControllerService,
              private matomoTracker: MatomoTracker) {
    super(authService, router, route, routerHistory);
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_MAINTENANCE);
  }

  public isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.subscribeToEquipment();
  }

  public ngAfterViewInit(): void {
    let queryParams = this.route.snapshot.queryParamMap;
    if (!!queryParams && queryParams.has('tabIndex')) {
      this.selectTab(Number(queryParams.get('tabIndex')));
    } else {
      this.restoreSelectedTab();
    }
  }

  public saveReminder(): void {
    const formValue = this.reminderForm.getRawValue();
    const name = formValue.reminderName;
    const date = DatesService.ospDateTimeAtExactTime(formValue.reminderDate);
    const equipmentId = this.equipment.equipmentId;
    const command: CreateReminderCommand = new CreateReminderCommand(equipmentId, name, date);

    this.maintenanceTaskStore.createReminder(command, this.equipment.equipmentId);
    this.resetForm();
  }

  public createRule(): void {
    this.router.navigate(['maintenance', 'rules', 'assign', this.equipment.equipmentId]);
  }

  public assignRules(): void {
    const dialogRef = this.dialog.open(AssignMaintenanceRuleDialogComponent);
    dialogRef.componentInstance.equipment = this.equipment;
    dialogRef.afterClosed()
      .subscribe(() => this.maintenanceTaskStore.loadTasksForEquipment(this.equipment.equipmentId));
  }

  public completeTask(taskId: string): void {
    this.router.navigate(['maintenance', 'tasks', 'complete', taskId]);
  }

  public completeReminder(task: ReminderTask): void {
    const completeReminderCommand = new CompleteReminderCommand(task.id, task.equipmentId);
    this.maintenanceTaskStore.completeReminder(completeReminderCommand);
    this.tabContentController.needUpdate(EquipmentMaintenanceTaskTab.HISTORY);
  }

  public deleteReminder(taskId: string): void {
    const deleteReminderCommand = new DeleteReminderCommand(taskId);
    this.maintenanceTaskStore.deleteReminder(deleteReminderCommand, this.equipment.equipmentId);
  }

  public unassignRule(taskRule: TaskRule): void {
    const command = new UnassignMaintenanceRuleCommand(taskRule.ruleId, this.equipment.equipmentId);
    this.maintenanceTaskStore.unassignRule(taskRule.task.id, command);
  }

  public editCompletedTask(task: TaskCompleted): void {
    this.maintenanceTaskCompletedStore.editCompletedTask(task.id, task.category);
  }

  public deleteCompletedTask(task: TaskCompleted): void {
    this.maintenanceTaskCompletedStore.deleteCompletedTask(task.id, task.category, task.equipmentId)
      .pipe(
        delay(environment.DELAY_SHORT),
        untilDestroyed(this))
      .subscribe(() => this.maintenanceTaskCompletedStore.loadTasksForEquipment(this.equipment.equipmentId));
  }

  public navigateTaskCompletedDetails(task: TaskCompleted): void {
    if (task?.id) {
      this.router.navigate(['maintenance', 'tasks', 'maintenance-completed-list', task.id]);
    }
  }

  public navigateTaskDetails(task: Task): void {
    if (task?.id) {
      this.router.navigate(['maintenance', 'tasks', 'maintenance-list', task.id]);
    }
  }

  public hasAsyncErrors(errors: string[]): void {
    this.isReminderNameInUse = errors.some(err => err === this.reminderNameInUseErrorName);
  }

  private restoreSelectedTab(): void {
    this.selectTab(Number(this.localStorageService.getUserValue(this.localStorageKey)));
  }

  private selectTab(index: number): void {
    if (index >= 0 && index <= this.detailTabGroup._tabs.length) {
      this.detailTabGroup.selectedIndex = index;
      if (index === 0) {
        this.tabContentController.tabChanged(this.getTabByIndex(0));
      }
    } else {
      this.localStorageService.setUserValue(this.localStorageKey, '0');
    }
  }

  private buildForm(): void {
    this.reminderForm = this.formBuilder.group({
      reminderName: [
        '',
        [Validators.required, Validators.maxLength(50)],
        [asyncValidatorFactory(value => ReminderNameInUseValidator.isValid(this.equipment.equipmentId, value, this.maintenanceService))]
      ],
      reminderDate: [{ value: moment().toDate(), disabled: !this.allowedToCreateReminder }, [Validators.required]]
    });
  }

  private subscribeToEquipment(): void {
    this.equipmentStore.currentEquipment
      .pipe(untilDestroyed(this))
      .subscribe((currentEquipment: ViewEquipment) => {
        if (currentEquipment) {
          this.equipment = currentEquipment;
          this.tabContentController.equipmentIdChanged(this.equipment.equipmentId);
        }
      });
  }

  private resetForm(): void {
    this.reminderForm.reset({reminderDate: moment().toDate()});
  }

  public updateTab(event: MatTabChangeEvent): void {
    this.localStorageService.setUserValue(this.localStorageKey, `${event.index}`);
    this.tabContentController.tabChanged(this.getTabByIndex(event.index));
  }

  private getTabByIndex(index: number): EquipmentMaintenanceTaskTab {
    return index === 0 ? EquipmentMaintenanceTaskTab.CURRENT : EquipmentMaintenanceTaskTab.HISTORY;
  }

}
