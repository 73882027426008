<div [hidden]="(equipmentStore.requestedAssignments | async).length < 1" class="assignment-requests">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" >

      <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <table mat-table [dataSource]="equipmentStore.requestedAssignments"
               *ngIf="(equipmentStore.requestedAssignments | async).length > 0">
          <ng-container matColumnDef="name">
            <td mat-cell class="data-cell name-cell" *matCellDef="let assignmentRequest" fxLayout="row">
              <div fxLayout="column" fxLayoutAlign="space-between start" fxFlex="95">

              <span class="item-name link ellipsis"
                    *ngIf="isEquipmentView"
                    (click)="navigateToProjectAssignments(assignmentRequest.projectId)">
                    {{ assignmentRequest.projectName }}
                <mat-icon [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.project.s'|translate}"
                          (click)="navigateToProjectAssignments(assignmentRequest.projectId)">
                    open_in_new
                </mat-icon>
              </span>
              <span class="item-name link ellipsis"
                      *ngIf="isProjectView"
                      (click)="navigateToEquipmentAssignments(assignmentRequest.equipmentId)">
                    {{ assignmentRequest.equipmentName ? assignmentRequest.equipmentName : assignmentRequest.equipmentModel}}
                  <mat-icon [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.equipm.s'|translate}"
                            (click)="navigateToEquipmentAssignments(assignmentRequest.equipmentId)">
                    open_in_new
                </mat-icon>
              </span>
                <div class="model">
                  <span>{{printRequestedDetailsInfo(assignmentRequest)}}</span>
                </div>
                <div class="model">{{'shared.assignment.requestedBy'|translate}}
                <span>{{printRequestedUserInfo(assignmentRequest)}}</span>
                </div>
                <div class="model">
                  {{'general.at'|translate}}: {{assignmentRequest.requestDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="timespan">
            <td mat-cell class="data-cell time-cell" *matCellDef="let assignmentRequest">
              <div>
                {{'general.labels.start'|translate}}: {{ assignmentRequest.startDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
              </div>
              <div *ngIf="assignmentRequest.endDate; else noEnd ">
                {{'general.labels.end'|translate}}: {{ assignmentRequest.endDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
              </div>
              <ng-template #noEnd>
                <div>
                  {{'general.labels.openEnd'|translate}}
                </div>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <td mat-cell class="data-cell delete-cell" *matCellDef="let assignmentRequest">
              <mat-icon (click)="cancelAssignment(assignmentRequest)">delete</mat-icon>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: equipmentColumns;"></tr>
        </table>
</div>


