import { Directive, ElementRef, Input, OnInit } from '@angular/core';
@Directive({
  selector: '[bhFirstColumn]'
})

export class FirstColumnDirective implements OnInit {
  @Input() orderNumber: number;
  constructor(private elementRef: ElementRef) {
  }

  public ngOnInit() {
    if (this.orderNumber === 0) {
      this.elementRef.nativeElement.insertAdjacentHTML(
        'afterbegin',
         `<mat-icon _ngcontent-c27 class="mat-icon notranslate material-icons mat-icon-no-color"
           role="img" aria-hidden="true">subdirectory_arrow_right
         </mat-icon>`);
    }
  }
}

