import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { OptionResolver } from './option.resolver';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CardSelectContentData } from './card-select-content-data.interface';

@Component({
  selector: 'bh-card-select',
  templateUrl: './card-select.component.html',
  styleUrls: ['./card-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardSelectComponent),
      multi: true
    },
    {provide: MatFormFieldControl, useExisting: CardSelectComponent}
  ]
})
export class CardSelectComponent implements ControlValueAccessor {

  @Input() options: string[];
  @Input() optionResolver: OptionResolver;
  @Input() _selectedOption: string;
  @Input() readOnly: boolean;

  @Output() optionSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

  propagateChange = (_: any) => {};

  constructor() { }

  get selectedOption(): string {
    return this._selectedOption;
  }

  set selectedOption(option: string) {
    this.writeValue(option);
  }

  public select(option: string): void {
    this.selectedOption = option;
  }

  public writeValue(obj: any): void {
    this._selectedOption = obj;
    if (this._selectedOption) {
      this.optionSelectionChanged.emit(this._selectedOption);
    }
    this.propagateChange(this._selectedOption);
  }

  public resolveOptionName(option: string): string {
    return this.optionResolver.resolveName(option);
  }

  public resolveOptionIcon(option: string): IconDefinition {
    return this.optionResolver.resolveIcon(option);
  }

  public isDisabledOption(optionValue: string): boolean {
    return this.optionResolver.isDisabledOption
      ? this.optionResolver.isDisabledOption(optionValue)
      : false;
  }

  public getDisabledOptionTooltip(optionValue: string): string {
    return this.optionResolver.getDisabledOptionTooltip
      ? this.optionResolver.getDisabledOptionTooltip(optionValue)
      : null;
  }

  public isSelected(option: string): boolean {
    return option === this.selectedOption;
  }

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {}

  public getCardContentDate(option: string): CardSelectContentData {
    return {
      logo: this.optionResolver.resolveLogo && this.optionResolver.resolveLogo(option),
      icon: this.optionResolver.resolveIcon(option),
      iconImage: this.optionResolver.resolveIconImage && this.optionResolver.resolveIconImage(option)
    }
  }

  public resolveCardClassName(option: string): string {
    return this.optionResolver.resolveClassName ? this.optionResolver.resolveClassName(option) : null;
  }

  public resolveSubtitle(option: string): string {
    return this.optionResolver.resolveSubtitle ? this.optionResolver.resolveSubtitle(option) : null;
  }
}
