<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <bh-mobile-sub-header [title]="equipment?.equipmentName"
                        [subtitle]="equipment?.equipmentSerialNumber">
  </bh-mobile-sub-header>

  <div fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
    <div class="m-view-details-menu static-title" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{title}}</span>
      <button mat-icon-button (click)="navigateBack()">
        <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
      </button>
    </div>

    <div class="m-view-list-inputs">
      <form [formGroup]="form" novalidate>
        <mat-form-field>
          <mat-label>{{'modules.equipment.lifecycle.logDate'|translate|uppercase}}</mat-label>
          <input matInput required
                 formControlName="logDate" autocomplete="off"
                 [matDatepicker]="picker"
                 [min]="dateOfLastEntry"
                 [max]="today">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker bhAutoModeSwitch #picker></mat-datepicker>
          <mat-error *ngIf="logDate.hasError('isValidDateInstance')">
            {{logDate.getError('isValidDateInstance')}}
          </mat-error>
          <mat-error *ngIf="logDate.hasError('inputDateNotBefore')">
            {{'modules.equipment.lifecycle.dateNotBefore'|translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{newValueDescription}}</mat-label>
          <input type="number" matInput required
                 formControlName="newValue"
                 [min]="currentValue + 1"
                 bhMaxLength="7">
          <mat-error *ngIf="newValue.hasError('invalidNumber')">
            {{newValue.getError('invalidNumber')}}
          </mat-error>
          <mat-error *ngIf="newValue.hasError('max')">
            {{ 'shared.validation.maxInteger'|translate:{value: fieldLimit.SEVEN_DIGITS_INTEGER} }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{'general.comment.s'|translate|uppercase}}</mat-label>
          <textarea matInput #commentTextarea
                    formControlName="comment"
                    [maxlength]="fieldLimit.MEDIUM_TEXT">
          </textarea>
          <mat-hint align="end">{{commentTextarea.value.length}} / {{fieldLimit.MEDIUM_TEXT}}</mat-hint>
        </mat-form-field>
      </form>
    </div>

    <button mat-button
            class="m-button-full-width border-top"
            bhTimeoutSaveButton
            (throttledClick)="save()"
            [disabled]="!isValid()">
      {{'general.buttons.save'|translate|uppercase}}
    </button>
  </div>
</div>
