import { ColumnDefinition } from 'app/shared/column-definition';
import { ColumnDefinitionKeys } from 'app/shared/enums/column-definition-keys.enum';
import { BaseColumnDefinitionService } from 'app/shared/services/base-column-definition.service';
import { Injectable } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { ColumnDefinitionService } from 'app/shared/contract/column-definition-service.interface';
import { OperatingHoursPipe } from 'app/shared/pipes/operating-hours.pipe';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { ConstructionYearFormatPipe } from '../../../equipment/shared/pipes/construction-year.pipe';
import { DimensionUnitPipe } from '../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class EquipmentTransferColumnDefinitionService extends BaseColumnDefinitionService implements ColumnDefinitionService {
  private _availableColumns: ColumnDefinition[];

  constructor(
    protected dimensionUnitPipe: DimensionUnitPipe,
    protected dimensionUnitConverterPipe: DimensionUnitConverterPipe,
    protected languageService: LanguageService,
    protected operatingHoursPipe: OperatingHoursPipe,
    protected contactTypeResolverPipe: ContactTypeResolverPipe,
    protected constructionYearPipe: ConstructionYearFormatPipe,
    protected translatableStringPipe: TranslatableStringPipe,
  ) {
    super(languageService,
      dimensionUnitPipe,
      dimensionUnitConverterPipe,
      operatingHoursPipe,
      contactTypeResolverPipe,
      constructionYearPipe,
      translatableStringPipe);
    this.initColumnDefinitions();
  }

  public get availableColumns(): ColumnDefinition[] {
    return this._availableColumns;
  }

  protected initColumnDefinitions(): void {
    this._availableColumns = [
      ColumnDefinitionKeys.EQUIPMENT_IMAGE,
      ColumnDefinitionKeys.CURRENT_LOCATION_NUMBER,
      ColumnDefinitionKeys.CURRENT_LOCATION_NAME,
      ColumnDefinitionKeys.CURRENT_LOCATION_COST_CENTER,
      ColumnDefinitionKeys.EQUIPMENT_CUSTOMER_SERIAL_NUMBER,
      ColumnDefinitionKeys.EQUIPMENT_SERIAL_NUMBER,
      ColumnDefinitionKeys.GENERAL_STATUS,
      ColumnDefinitionKeys.EQUIPMENT_NAME,
      ColumnDefinitionKeys.EQUIPMENT_MODEL,
      ColumnDefinitionKeys.INFO_TRANSFER_CART,
      ColumnDefinitionKeys.EQUIPMENT_BEUTLHAUSER_ID,
      ColumnDefinitionKeys.EQUIPMENT_SCAN_CODE,
      ColumnDefinitionKeys.MANUFACTURER_NAME,
      ColumnDefinitionKeys.GENERAL_ORGANISATION,
      ColumnDefinitionKeys.EQUIPMENT_LABELS,
      ColumnDefinitionKeys.EQUIPMENT_BGL_CODE,
      ColumnDefinitionKeys.EQUIPMENT_LICENSE_PLATE,
      ColumnDefinitionKeys.EQUIPMENT_COST_CENTER,
      ColumnDefinitionKeys.ASSIGNED_PERSON_IN_CHARGE,
      ColumnDefinitionKeys.ASSIGNED_DRIVER,
      ColumnDefinitionKeys.ASSIGNED_MAINTENANCE_ASSIGNEE,
      ColumnDefinitionKeys.ASSIGNED_OWNER,
      ColumnDefinitionKeys.ASSIGNED_POSSESSOR,
      // TODO: remake a logic of available equipment displaying on the transfer page
      // ColumnDefinitionKeys.EQUIPMENT_AMOUNT_AVAILABLE,
      ColumnDefinitionKeys.EQUIPMENT_TYPE_CATEGORY2_FOR_TRANSFER,
      ColumnDefinitionKeys.EQUIPMENT_CONSTRUCTION_YEAR,
      ColumnDefinitionKeys.EQUIPMENT_REGISTRATION_DATE]
    .reduce((acc, defKey) => ([ ...acc, this.columnDefinitions[defKey] ]), [])
    .filter(Boolean);
  }
}
