import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewEquipmentType } from '../contract/view-equipment-type.interface';
import { EquipmentTypesService } from './equipmenttypes.service';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { DataSource } from '@angular/cdk/table';
import { FilteredEquipmentTypesParams } from './equipmenttypes.service.interfaces';
import { EquipmentTypeColumnService } from 'app/modules/disposition/shared/services/equipment-type-column.service';

@Injectable()
export class EquipmentTypesDataSource extends DataSource<ViewEquipmentType> {
  private _equipmentTypes: BehaviorSubject<ViewEquipmentType[]> = new BehaviorSubject([]);
  private _pagination: PaginationInfo;
  private _filterChange: BehaviorSubject<string> = new BehaviorSubject('');

  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);

  public readonly length: Observable<number> = this._currentTotalElements.asObservable();

  constructor(
    private equipmentTypesService: EquipmentTypesService,
    private equipmentTypeColumnService: EquipmentTypeColumnService
  ) {
    super();
    this.updateListing();
    this.equipmentTypeColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  public get equipmentTypes(): Observable<ViewEquipmentType[]> {
    return this._equipmentTypes.asObservable();
  }

  public get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  public set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  public get filter(): string {
    return this._filterChange.value;
  }

  public set filter(filter: string) {
    this._filterChange.next(filter);
    this.updateListing(0);
  }

  public connect(): Observable<ViewEquipmentType[]> {
    return this._equipmentTypes = new BehaviorSubject([]);
  }

  public disconnect(): void {
    this._equipmentTypes.complete();
    this._equipmentTypes.observers = [];
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    this.pagination.index = index;
    this.pagination.size = size;

    const params: FilteredEquipmentTypesParams = {
      searchTerm: this.filter,
      page: this.pagination.index,
      size: this.pagination.size
    };

    this.equipmentTypesService.getFilteredEquipmentTypes(params)
    .subscribe((viewEquipmentTypePagedResponse: PagedResponse<ViewEquipmentType>) => {
      this._equipmentTypes.next(viewEquipmentTypePagedResponse.content);
      this._currentTotalElements.next(viewEquipmentTypePagedResponse.totalElements);
      this._pagination.numberOfElements = viewEquipmentTypePagedResponse.numberOfElements;
    });
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0
    };
  }
}
