import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { OfflineDataStore } from '../../../shared/services/offline-data.store';
import { AmountsService } from './amounts.service';
import { DispositionStock } from './disposition-stock';
import { ViewBulkItemAmount } from '../contract/view-bulk-item-amount.interface';
import { ViewProjectAmount } from '../contract/view-project-amount.interface';
import { DispositionProject } from './disposition-project';
import { map } from 'rxjs/operators';
import { OfflineTransferAmountsService } from '../../../shared/services/offline-transfer-amounts.service';
import * as _ from 'lodash';

@Injectable()
export class AmountStore {

  constructor(private amountsService: AmountsService,
              private onlineStatusService: OnlineStatusService,
              private offlineTransferAmountsService: OfflineTransferAmountsService,
              private offlineDataStore: OfflineDataStore) {
  }

  public getAmountForTransfer(bulkItemId: string, source: DispositionStock): Observable<ViewBulkItemAmount> {
    if (this.onlineStatusService.onlineStatus) {
      return this.amountsService.getAmountForTransfer(bulkItemId, source);
    } else {
      const plannedAmount = this.offlineTransferAmountsService.getPlannedAmount(bulkItemId, source.stockId);
      return this.offlineDataStore.offlineDispositionAmounts
        .pipe(
          map(res => {
            const amounts = _.cloneDeep(res);
            let found = amounts.find(amount => amount.bulkItemId === bulkItemId && amount.stockId === source.stockId);
            if (found && plannedAmount) {
              found.amountAvailable += plannedAmount.amountAvailable;
            } else if (!found && plannedAmount) {
              found = plannedAmount;
            }
            return found;
          })
        );
    }
  }

  public getProjectAmountsForTransfer(bulkItemId: string, source: DispositionProject): Observable<ViewProjectAmount[]> {
    if (this.onlineStatusService.onlineStatus) {
      return this.amountsService.getProjectAmountsForTransfer(bulkItemId, source);
    } else {
      const plannedAmounts = this.offlineTransferAmountsService.getPlannedProjectAmounts(bulkItemId, source.projectId);
      return this.offlineDataStore.offlineDispositionProjectAmounts
        .pipe(
          map(res => {
            const amounts = _.cloneDeep(res);
            let foundAmounts = amounts.filter(amount => amount.transferItemId === bulkItemId && amount.projectId === source.projectId);
            foundAmounts.forEach(item => {
              const additionalAmount = plannedAmounts.find(planned => planned.stockId === item.stockId);
              if (additionalAmount) {
                item.amount += additionalAmount.amount;
              }
            });
            foundAmounts = foundAmounts.concat(plannedAmounts.filter(amount => {
              return foundAmounts.length > 0 ? foundAmounts.every(item => item.stockId !== amount.stockId) : true;
            }));
            return foundAmounts.filter(item => item.amount > 0);
          })
        );
    }
  }
}
