export class TransportDateTimeInputDialogConfig {
  isStartDate: boolean;
  title: string;
  dateLabel: string;
  timeLabel: string;
  confirmText: string;
  startDate: Date;

  constructor(isStartDate: boolean, title: string, dateLabel: string, timeLabel: string,
              confirmText: string, startDate: Date) {
    this.isStartDate = isStartDate;
    this.title = title;
    this.dateLabel = dateLabel;
    this.timeLabel = timeLabel;
    this.confirmText = confirmText;
    this.startDate = startDate;
  }

}
