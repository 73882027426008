import { environment } from 'environments/environment';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseHeaderComponent } from '../base/base-header.component';
import { KeycloakService } from '../../../core/keycloak';
import { Alarm } from 'app/modules/notifications/shared/interfaces/alarm.interface';
import { NotificationDataSource } from '../../notifications/shared/services/notification.datasource';
import { Router } from '@angular/router';
import { DisplayService } from '../../../shared/display.service';
import { SidenavService } from '../../navigation/sidenav/shared/sidenav.service';
import { AcknowledgeAlarmCommand } from '../../notifications/contract/acknowledge-alarm-command';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { AlarmType } from '../../notifications/shared/enums/alarm-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommunicationService } from '../../communication/shared/communication.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatButton } from '@angular/material/button';
import { LanguageService } from 'app/shared/services/language.service';
import { AlarmNotification } from '../alarm-notification/alarm-notification.interface';
import {MessagingService} from '../../../shared/messaging.service';


@UntilDestroy()
@Component({
  selector: 'bh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseHeaderComponent implements OnInit, AfterViewInit {

  public readonly faUserIcon: IconDefinition = faUserCircle;
  public readonly profileMenu = {
    SETTINGS: '/users/settings'
  };
  public warnings: AlarmNotification[] = [];
  public warningsTotalAmount = 0;
  private readonly jumpToEquipmentText = this.translate('general.labels.jumpTo', { value: this.translate('general.equipm.s') });
  private readonly jumpToTransportText = this.translate('general.labels.jumpTo', { value: this.translate('general.transport') });
  private readonly jumpToProjectText = this.translate('general.labels.jumpTo', { value: this.translate('general.project.s') });

  @ViewChild(MatMenuTrigger) warningMenu: MatMenuTrigger;
  @ViewChild('chatBtn') chatBtn: MatButton;

  constructor(protected authService: KeycloakService,
              protected onlineStatusService: OnlineStatusService,
              protected communicationService: CommunicationService,
              protected notificationStore: NotificationDataSource,
              protected router: Router,
              protected displayService: DisplayService,
              protected sidenavService: SidenavService,
              protected messagingService: MessagingService,
              private focusMonitor: FocusMonitor,
              private languageService: LanguageService
  ) {
    super(authService, onlineStatusService, communicationService, notificationStore);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.getWarnings();
  }

  public ngAfterViewInit(): void {
    if (this.chatBtn) {
      this.focusMonitor.stopMonitoring(this.chatBtn._elementRef.nativeElement);
    }
  }

  private isGeoFenceAlarm(alarm: Alarm): boolean {
    return alarm.alarmType === AlarmType.GEOFENCE_VIOLATION;
  }

  public acknowledgeAlarm(/*$event, */id: string): void {
    this.closeWarningMenu();
    const cmd = new AcknowledgeAlarmCommand(id);
    this.notificationStore.acknowledgeAlarm(cmd).subscribe(res => {
      this.notificationStore.getCurrentActive();
    });
  }

  public navigate(url: string): void {
    if (url) {
      this.displayService.displayTableAndDetails();
      this.router.navigate([url]);
    }
  }

  private closeWarningMenu(): void {
    if (this.warnings.length === 1) {
      setTimeout(() => {
        this.warningMenu.closeMenu();
      }, environment.DELAY_SHORT);
    }
  }

  public openChatOverview(): void {
    if (!this.sidenavService.opened) {
      this.router.navigate([{outlets: {sidenav: ['chat']}}], {skipLocationChange: true});
    }
  }

  public alarmTrackBy(index, alarm: AlarmNotification) {
    return alarm.alarmId;
  }

  private getWarnings(): void {
    this.notificationStore.currentActive
      .pipe(untilDestroyed(this))
      .subscribe(warnings => {
        if (warnings) {
          this.warnings = this.alarmsToNotifications(warnings);
        }
      });

    this.notificationStore.currentActiveTotalCount
      .pipe(untilDestroyed(this))
      .subscribe((totalCount: number) =>
        this.warningsTotalAmount = totalCount);
  }

  private alarmsToNotifications(alarms: Alarm[]): AlarmNotification[] {
    return alarms.map(alarm => {
      const alarmNotification =
        alarm.alarmType !== AlarmType.TRANSPORT_TASK_CREATION && alarm.alarmType !== AlarmType.TRANSPORT_TASK_RETURNED
          ? this.equipmentAlarmToNotification(alarm)
          : this.transportAlarmToNotification(alarm)

      if (this.isGeoFenceAlarm(alarm) && alarm.projectNumber && alarm.projectName) {
        alarmNotification.projectName = alarm.projectName;
        alarmNotification.projectNumber = alarm.projectNumber;
      }
      return alarmNotification;
    });
  }

  private equipmentAlarmToNotification(alarm: Alarm): AlarmNotification {
    return {
      alarmId: alarm.alarmId,
      alarmRaisedDate: new Date(alarm.alarmRaisedDate),
      title: `${alarm.equipmentName || alarm.equipmentModel}` +
        ` ${alarm.equipmentCustomerSerialNumber ? `(${alarm.equipmentCustomerSerialNumber})` : ''}`,
      message: alarm.alarmMessage,
      alarmLevel: alarm.alarmLevel,
      alarmType: alarm.alarmType,
      navigateUrl: alarm.entityId ? `assets/equipment/list/${alarm.entityId}/general` : null,
      navigateUrlTooltip: alarm.entityId ? this.jumpToEquipmentText : null,
      navigateUrlSubtitle: alarm.projectId ? `sites/projects/list/${alarm.projectId}/general` : null,
      navigateUrlSubtitleTooltip: alarm.projectId ? this.jumpToProjectText : null
    };
  }

  private transportAlarmToNotification(alarm: Alarm): AlarmNotification {
    return {
      alarmId: alarm.alarmId,
      alarmRaisedDate: new Date(alarm.alarmRaisedDate),
      title: alarm.transportTitle,
      message: alarm.alarmMessage,
      alarmLevel: alarm.alarmLevel,
      alarmType: alarm.alarmType,
      navigateUrl: alarm.entityId ? `transportation/list/${alarm.entityId}` : null,
      navigateUrlTooltip: alarm.entityId ? this.jumpToTransportText : null,
      navigateUrlSubtitle:  null,
      navigateUrlSubtitleTooltip: null
    };
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  logoutUser(event: Event): void {
    event.preventDefault();
    this.messagingService.unregister();
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
