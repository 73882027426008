import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConfigurationService } from './user-configuration.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Timezone } from '../contract/timezone';

@Injectable()
export class TimezoneService {

  private url: string = environment.APP_USERROLE_SERVICE_BASE_URL + '/api/v1/users/available-timezones';

  constructor(private userConfigurationService: UserConfigurationService,
              private http: HttpClient) {
  }

  public getTimezones(filterTerm: string): Observable<Timezone[]> {
    let filterParams = {
      filterTerm: filterTerm
    }
    return this.http.get<Timezone[]>(`${this.url}`, {params: filterParams});
  }

  public getUserTimezone(): string {
    return this.userConfigurationService.getUserTimezone();
  }

  public setUserTimezone(timezone: string): Observable<any> {
    return this.userConfigurationService.saveUserTimezone(timezone);
  }

}
