import * as momenttz from 'moment-timezone';

type TimezoneDateType = {
  store?: Date | string,
  display?: Date | string,
};

export function setConstructorDTZ(zone: string): void {
  DTZ.DateTimezone = function(date?: TimezoneDateType): DateTimezone {
    return new DateTimezone(zone, date);
  }
}

export const DTZ: {
  DateTimezone: (date?: TimezoneDateType) => DateTimezone
} = {
  DateTimezone: () => null,
};


export class DateTimezone {
  private timezonesDiffMinutes = 0;
  private _storeDate: momenttz.Moment;
  private _displayDate: momenttz.Moment;

  public get storeDate(): Date {
    return this._storeDate.toDate();
  }
  public set storeDate(date: Date | string) {
    this._storeDate = date ? momenttz(date) : momenttz();
    this._displayDate = momenttz(this._storeDate).add(this.timezonesDiffMinutes, 'minutes');
  }

  public get displayDate(): Date {
    return this._displayDate.toDate();
  }
  public set displayDate(date: Date | string) {
    this._displayDate = date ? momenttz(date) : momenttz();
    this._storeDate = momenttz(this._displayDate).add(-1 * this.timezonesDiffMinutes, 'minutes');
  }

  constructor(
    private userTimezone: string,
    date: TimezoneDateType,
  ) {
    this.initSettings(date);
  }

  private initSettings(date: TimezoneDateType): void {
    this.setTimezonesDiffMinutes();
    const { store, display } = date ?? {};
    if (store) {
      this.storeDate = store;
    } else {
      this.displayDate = display ?? new Date();
    }
  }

  private setTimezonesDiffMinutes(): void {
    const actualOffset = momenttz().utcOffset();
    const zonedOffset = momenttz().tz(this.userTimezone).utcOffset();
    this.timezonesDiffMinutes = zonedOffset - actualOffset;
  }
}
