import { TransportView } from '../../contracts/transport/transport-view.interface';
import { TransportTaskState } from 'app/modules/transportation/shared/enums/transport-task-status.enum';
import { Utils } from 'app/shared/utils';
import { TransportBase } from '../../contracts/transport/transport-base.interface';
import { TransportAssignment } from '../../contracts/transport/transport-assignment.interface';

export class TransportTaskChecker {
  public static hasNecessaryDataForAssignment(transport: TransportBase): boolean {
    const { title, transportType, priority, startItemId, targetItemId, projectId } = transport;
    return [title, transportType, priority, startItemId, targetItemId, projectId].every(Utils.isNotEmpty);
  }

  public static isCompatibleAssignedVehicleType(
    { equipmentAssignmentDetails: vehicle }: TransportView,
    transportType: string
  ): boolean {
    if (transportType && vehicle && vehicle.equipmentTransportTypes && vehicle.equipmentTransportTypes.length > 0) {
      return vehicle.equipmentTransportTypes.some(vehicleType => vehicleType === transportType);
    }
    return true;
  }

  public static isDraftOrReturned ({ state }: TransportBase): boolean {
    return state === TransportTaskState.DRAFT || state === TransportTaskState.RETURNED;
  }

  public static isPlannableAndBeyond(transport: TransportBase): boolean {
    return !TransportTaskChecker.isDraftOrReturned(transport);
  }

  public static isPlannedOrInProgress({ state }: TransportBase): boolean {
    return state === TransportTaskState.PLANNED || state === TransportTaskState.IN_PROGRESS;
  }

  public static isInProgressAndBeyond(transport: TransportBase): boolean {
    return transport.state === TransportTaskState.IN_PROGRESS || TransportTaskChecker.isDoneOrArchived(transport);
  }

  public static isDoneOrArchived({ state }: TransportBase): boolean {
    return state === TransportTaskState.DONE || state === TransportTaskState.ARCHIVED;
  }

  public static isDeletable({ state }: TransportBase): boolean {
    return state === TransportTaskState.DRAFT
      || state === TransportTaskState.RETURNED
      || state === TransportTaskState.PLANNABLE
      || state === TransportTaskState.CANCELED;
  }

  public static isEditableForManageRole({ state }: TransportBase): boolean {
    return state === TransportTaskState.DRAFT
      || state === TransportTaskState.RETURNED
      || state === TransportTaskState.PLANNABLE;
  }

  public static isFinishedTask({ state }: TransportView): boolean {
    return state === TransportTaskState.DONE
      || state === TransportTaskState.CANCELED
      || state === TransportTaskState.ARCHIVED;
  }

  public static isDueDatePassed(transport: TransportView | TransportAssignment | TransportBase): boolean {
    switch (transport?.state) {
      case TransportTaskState.PLANNED:
      case TransportTaskState.IN_PROGRESS: {
        const endDate = (<TransportView>transport)?.equipmentAssignmentDetails?.endDate?.timestamp
          || (<TransportAssignment>transport)?.endDate?.timestamp;
        return new Date(endDate) > new Date(transport?.dueDate?.timestamp);
      }
      case TransportTaskState.DRAFT:
      case TransportTaskState.PLANNABLE:
      case TransportTaskState.RETURNED:
        return new Date() > new Date(transport?.dueDate?.timestamp);
    }
    return false;
  }

  public static hasChangedLatestRelatedItems(transport: TransportView): boolean {
    return Boolean(transport?.latestRelatedItemDetails?.latestStartItem
      || transport?.latestRelatedItemDetails?.latestTargetItem
      || this.hasDeletedLatestRelatedItems(transport));
  }

  public static hasDeletedLatestRelatedItems(transport: TransportView): boolean {
    return Boolean(transport?.latestRelatedItemDetails?.isStartItemDeleted
      || transport?.latestRelatedItemDetails?.isTargetItemDeleted);
  }
}
