import { FilterParams } from '../../../filter-params.class';
import { EquipmentStatusFilterReadinessCheckerBase } from '../../base/equipment-status/equipment-status-filter-readiness-checker-base.class';
import { Observable, map } from 'rxjs';
import { EquipmentStateFilterView } from '../../../filter-view/equipment-state-filter-view.interface';
import { IVehicleFilterReadinessChecker } from '../../interfaces/equipment-filter-readiness-checker.interface';
import { TransportService } from 'app/modules/transportation/shared/services/transport.service';

export class VehicleFilterReadinessChecker
  extends EquipmentStatusFilterReadinessCheckerBase
  implements IVehicleFilterReadinessChecker {

  constructor(params: FilterParams, private transportService: TransportService) {
    super(params);
  }

  protected getEquipmentStatusFilters(): Observable<EquipmentStateFilterView[]> {
    return this.transportService.getVehicleFilters(this.params.getFilterParams())
      .pipe(map(collection => collection?.filterableEquipmentStatuses ?? []));
  }
}
