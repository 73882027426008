import { Pipe, PipeTransform } from '@angular/core';
import { UserLanguageConfigProvider } from 'app/modules/osp-ui/types/user-language-config.provider';
import { Languages } from 'app/shared/enums/languages.enum';



@Pipe({ name: 'translatableString' })
export class TranslatableStringPipe implements PipeTransform {

  constructor(private userLanguageConfigService: UserLanguageConfigProvider) { }

  public transform(
    value: { [key in string]?: string } | string,
    language?: Languages,
    fallback?: string
  ): string {
    if (!value) {
      return fallback ?? '';
    } else if (typeof value === 'string') {
      return value ?? fallback;
    }

    return value[language]
      ?? value[this.userLanguageConfigService.getUserLanguage()]
      ?? value[this.userLanguageConfigService.getFallbackLanguage()]
      ?? value[this.userLanguageConfigService.getDefaultLanguage()]
      ?? fallback
      ?? '';
  }
}
