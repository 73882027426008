import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../../../equipment/shared/equipments.datasource';
import { BaseProjectViewDispositionsComponent } from '../base/base-project-view-dispositions.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FocusMonitor } from '@angular/cdk/a11y';
import { faArrowRight, faBroom, faExchange } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faMapMarkedAlt, faPowerOff } from '@fortawesome/pro-duotone-svg-icons';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ViewEquipmentProjectAssignment } from 'app/modules/equipment/contract/view-equipment-project-assignment.interface';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TransferCartTypes } from 'app/shared/enums/transfer-cart-types.enum';

@UntilDestroy()
@Component({
  selector: 'bh-project-view-assignments',
  templateUrl: './project-view-dispositions.component.html',
  styleUrls: ['./project-view-dispositions.component.scss']
})
export class ProjectViewDispositionsComponent extends BaseProjectViewDispositionsComponent implements OnInit, OnDestroy, AfterViewInit {

  public readonly faExchange: IconDefinition = faExchange;
  public readonly faBroom: IconDefinition = faBroom;
  public readonly faMapMarkedAlt: IconDefinition = faMapMarkedAlt;
  public readonly faArrowRight: IconDefinition = faArrowRight;
  public readonly faPowerOff: IconDefinition = faPowerOff;

  constructor(protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              public projectStore: ProjectDataSource,
              private focusMonitor: FocusMonitor,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory, projectStore, equipmentStore, languageService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnChange(): void {
  }

  public ngAfterViewInit(): void {
    this.focusMonitor.stopMonitoring(document.getElementById('edit-button'));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public navigateToProjectToProjectTransfer(): void {
    this.router.navigate(['transfer', this.projectStore.project().projectId, TransferCartTypes.PROJECT_TO_PROJECT_CART]);
  }

  public navigateToClearProject(): void {
    this.router.navigate(['transfer',  this.projectStore.project().projectId, TransferCartTypes.CLEAR_PROJECT]);
  }

  public navigateToChangeChargePeriod(): void {
    this.router.navigate(['transfer/change-type-of-use',  this.projectStore.project().projectId]);
  }

  public transferDisabled(): boolean {
    return !this.bulkItems.length && !this.equipments.length;
  }

  public latestEquipmentAssignment(assignment: ViewEquipmentProjectAssignment): boolean {
    return assignment.latest;
  }

  public allowChangeTypeOfUse(): boolean {
    return this.hasAnyAuthority([
      Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE,
      Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE,
    ]);
  }
}
