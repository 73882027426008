import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  private defaultEmptyValue = '';

  public transform(value: Array<unknown>, separator?: string): string {
    if (value instanceof Array && value?.length > 0) {
      return value.filter(Boolean).join(separator) || this.defaultEmptyValue;
    }
    return this.defaultEmptyValue;
  }

}
