<bh-step-dialog
  class="contact-dialog"
  [icon]="faLanguage"
  [title]="'modules.organisation.customerLanguages.title'|translate">
  <div class="main-content" fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="form" class="main-content__form" fxLayout="row" fxFlex="1 1 auto"
          fxLayoutAlign="space-evenly none">
      <div fxLayout="column" fxFlex="0 1 auto" fxLayoutAlign="start start">
        <p class="list-title">{{'modules.organisation.customerLanguages.selectDefault'|translate}}</p>
        <bh-select-list class="list" formControlName="default" [options]="optionsListDefault"></bh-select-list>
      </div>
      <div fxLayout="column" fxFlex="0 1 auto" fxLayoutAlign="start start">
        <p class="list-title">{{'modules.organisation.customerLanguages.selectOther'|translate}}</p>
        <bh-select-list #enabledLanguagesSelect
                        class="list"
                        formControlName="enabled"
                        [itemTemplate]="langTemplate"
                        [options]="optionsListEnabled" [multiselect]="true"
                        [manualOnItemClickHandle]="true" (onItemClick)="onEnabledLanguagesClick($event)">
          <ng-template #langTemplate let-itemId="itemId" let-item="item">
            {{ item }}
            <span class="language-usage-stats"
                  matTooltip="{{ 'modules.organisation.customerLanguages.usageTooltip'|translate:{
                      number: languageUsageStats[itemId] ?? 0,
                      language: itemId
                    } }}">
                <mat-icon>person</mat-icon>
                <span class="language-usage-stats__count">{{ languageUsageStats[itemId] ?? 0 }}</span>
              </span>
          </ng-template>
        </bh-select-list>
      </div>
    </form>

    <div class="button-container" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="15px">
      <button
        mat-raised-button type="button"
        color="primary"
        bhTimeoutSaveButton
        (throttledClick)="save()"
        [disabled]="isLoading">
        {{'general.buttons.save'|translate}}
      </button>
    </div>
  </div>
</bh-step-dialog>
