import { AbstractControl, ValidatorFn, Validators, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { LanguageService } from 'app/shared/services/language.service';
import * as moment from 'moment';
import { Duration } from 'moment';
import { ElementRef } from '@angular/core';
import * as _ from 'lodash';

export interface DateRange {
  start: Date;
  end: Date;
}

export class DateValidator {

  public static isValidDate(languageService: LanguageService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const dateValid = control.value instanceof Date;
      return dateValid ? null : {isValidDateInstance: languageService.getInstant('shared.validation.date.invalidDate')};
    };
  }

  // control.value === null does NOT mean, that the input is empty
  // Any random string that is not a valid date evaluates to null!
  // Therefore we have to check the value of the native html element
  public static isValidDateOrEmpty(elementRef: ElementRef, languageService: LanguageService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!elementRef) {
        return null;
      }
      const date = moment(control.value);
      const isEmpty = !control.value && !elementRef.nativeElement.value;
      const isValid = date.isValid() && date.isAfter('1900-01-01') && date.isBefore('9999-12-31');
      return isEmpty || isValid
        ? null
        : { isValidDateInstance: languageService.getInstant('shared.validation.date.invalidDate') };
    };
  }

  static inputEndDateNotBeforeStartDate(projectEndDate: Date, dateToCompare: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!dateToCompare) {
        return null;
      }
      const dateValid = !moment(control.value).isBefore(moment(dateToCompare), 'day')
        && !moment(control.value).isAfter(moment(projectEndDate), 'day');

      return dateValid ? null : {inputEndDateNotBeforeStartDate: control.value};
    };
  }

  static inputDateNotBefore(dateToCompare: Date): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      const dateValid = !moment(control.value).isBefore(dateToCompare, 'day');

      return dateValid ? null : {inputDateNotBefore: dateToCompare};
    };
  }

  static inputDateNotAfter(dateToCompare: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!dateToCompare) {
        return null;
      }
      const dateValid = !moment(control.value).isAfter(moment(dateToCompare), 'day');

      return dateValid ? null : {inputDateNotAfter: control.value};
    };
  }

  static controlDateNotBefore(controlToCompare: AbstractControl, daysShift = 0): ValidatorFn {
    const duration = moment.duration(daysShift, 'days');
    return (control: AbstractControl): { [key: string]: any } => {
      const dateValid = controlToCompare.value
        && !moment(control.value).isBefore(moment(controlToCompare.value).add(duration), 'day');

      return dateValid ? null : {inputDateNotBefore: control.value};
    };
  }

  public static maxInterval(
    controlToCompare: AbstractControl,
    maxInterval: Duration,
    languageService: LanguageService,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!controlToCompare || !controlToCompare.value || !control.value || !maxInterval) {
        return null;
      }

      const earlierDate = moment(Math.min(control.value, controlToCompare.value));
      const laterDate = moment(Math.max(control.value, controlToCompare.value));

      const isInRange = earlierDate.add(maxInterval).isSameOrAfter(laterDate, 'days');
      return isInRange ? null : {intervalTooLong: languageService.getInstant('shared.validation.date.intervalTooLong')};
    };
  }

  public static noFutureDate(languageService: LanguageService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return moment(control.value).isSameOrBefore(moment(), 'days') ?
        null : {futureDateSelected: languageService.getInstant('shared.validation.date.futureDate')};
    };
  }

  public static yearWrongFormat(controlToCompare: AbstractControl, languageService: LanguageService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!controlToCompare.value) {
        return null;
      } else {
        return moment(control.value).isAfter('1900-01-01') ?
          null : {
            futureDateSelected: languageService.getInstant(
              'shared.validation.date.cannotBeBefore', {value: '01.01.1900'},
            ),
          };
      }
    };
  }

  public static maxDateTime(dateControl: AbstractControl,
                        timeControl: AbstractControl,
                        maxDateTime: Date,
                        languageService: LanguageService,
                        allowEquals = false,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (!dateControl.value || !timeControl.value || !maxDateTime) {
        return null;
      } else {
        let dateWithoutSeconds = new Date(dateControl.value);
        dateWithoutSeconds.setHours(timeControl.value.split(':')[0]);
        dateWithoutSeconds.setMinutes(timeControl.value.split(':')[1]);
        dateWithoutSeconds.setSeconds(0, 0);
        let maxDateWithoutSeconds = new Date(maxDateTime);
        maxDateWithoutSeconds.setSeconds(0, 0);

        if (dateWithoutSeconds < maxDateWithoutSeconds || allowEquals && (dateWithoutSeconds <= maxDateWithoutSeconds)) {
          return null;
        } else {

          return {
            maxTimeViolated: languageService.getInstant(
              'shared.validation.time.cannotBeAfter',
              {value: this.formatTime(maxDateTime.getHours(), maxDateTime.getMinutes())},
            ),
          };
        }
      }
    };
  }

  public static minDateTime(dateControl: AbstractControl,
                        timeControl: AbstractControl,
                        minDateTime: Date,
                        languageService: LanguageService,
                        allowEquals = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (!dateControl.value || !timeControl.value || !minDateTime) {
        return null;
      } else {
        let dateWithoutSeconds = new Date(dateControl.value);
        dateWithoutSeconds.setHours(timeControl.value.split(':')[0]);
        dateWithoutSeconds.setMinutes(timeControl.value.split(':')[1]);
        dateWithoutSeconds.setSeconds(0, 0);
        let minDateWithoutSeconds = new Date(minDateTime);
        minDateWithoutSeconds.setSeconds(0, 0);

        if (dateWithoutSeconds > minDateWithoutSeconds || allowEquals && (dateWithoutSeconds >= minDateWithoutSeconds)) {
          return null;
        } else {

          return {
            maxTimeViolated: languageService.getInstant(
              'shared.validation.time.cannotBeBefore',
              {value: this.formatTime(minDateTime.getHours(), minDateTime.getMinutes())},
            ),
          };
        }
      }
    };
  }

  static isValidForDeleteWithChargeDate(chargeDate: Date, startDate: Date, endDate: Date) {
    if (chargeDate === null) {
      return true;
    }
    let startDateIsAfterChargeDate = moment(startDate).isSameOrAfter(moment(chargeDate));
    let endDateIsAfterChargeDate = endDate === null || moment(endDate).isAfter(moment(chargeDate));
    return startDateIsAfterChargeDate && endDateIsAfterChargeDate;
  }

  static isValidForUpdateWithChargeDate(chargeDate: Date, startDate: Date, endDate: Date, newStartDate: Date, newEndDate: Date): boolean {
    if (chargeDate === null) {
      return true;
    }
    return this.assignmentBoundariesAreNotBeforeChargeDate(chargeDate, startDate, endDate)
      && this.newEndDateIsAfterChargeDate(chargeDate, newEndDate)
      && this.ifStartDateIsBeforeChargeDateNewStartDateNotChanged(chargeDate, startDate, newStartDate);
  }

  private static assignmentBoundariesAreNotBeforeChargeDate(chargeDate: Date, startDate: Date, endDate: Date): boolean {
    let startDateIsAfterChargeDate = moment(startDate).isSameOrAfter(moment(chargeDate));
    let endDateIsAfterChargeDate = endDate == null || moment(endDate).isAfter(moment(chargeDate));
    return startDateIsAfterChargeDate || endDateIsAfterChargeDate;
  }

  private static newEndDateIsAfterChargeDate(chargeDate: Date, newEndDate: Date): boolean {
    return newEndDate == null || moment(newEndDate).isSameOrAfter(moment(chargeDate));
  }

  private static ifStartDateIsBeforeChargeDateNewStartDateNotChanged(chargeDate: Date, startDate: Date, newStartDate: Date): boolean {
    if (newStartDate == null) {
      return true;
    }
    if (moment(startDate).isBefore(chargeDate)) {
      return this.isSameDay(startDate, newStartDate);
    }
    return true;
  }

  private static isSameDay(firstDate: Date, secondDate: Date): boolean {
    return firstDate.getFullYear() === secondDate.getFullYear()
      && firstDate.getMonth() === secondDate.getMonth()
      && firstDate.getDate() === secondDate.getDate();
  }

  static controlDateNotAfter(controlToCompare: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!controlToCompare.value) {
        return null;
      }
      const dateValid = !moment(control.value).isAfter(moment(controlToCompare.value), 'day');

      return dateValid ? null : {inputDateNotAfter: control.value};
    };
  }

  static controlDateValidAndNotAfter(controlToCompare: AbstractControl, languageService: LanguageService): ValidatorFn {
    return Validators.compose([
      DateValidator.controlDateNotAfter(controlToCompare),
      DateValidator.isValidDate(languageService),
    ]);
  }

  static intervalOverlapsDateRange(startControlName: string, endControlName: string, range: DateRange[]): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      if (!group.controls[startControlName].value || !group.controls[endControlName].value) {
        return;
      }

      const startControl = group.controls[startControlName];
      const endControl = group.controls[endControlName];
      const startDate = new Date(startControl.value);
      const endDate = new Date(endControl.value);
      const overlappedRange = range.find(({start, end}) =>
        (startDate <= start && endDate >= start)
        || (startDate <= end && endDate >= end)
        || (startDate >= start && endControl.value <= end));

      if (overlappedRange) {
        startControl.setErrors({...startControl.errors, rangeViolation: overlappedRange});
        endControl.setErrors({...endControl.errors, rangeViolation: overlappedRange});
        return;
      }
      const startControlErrors = _.omit({...startControl.errors}, 'rangeViolation');
      const endControlErrors = _.omit({...startControl.errors}, 'rangeViolation');
      startControl.setErrors(_.isEmpty(startControlErrors) ? null : startControlErrors);
      endControl.setErrors(_.isEmpty(endControlErrors) ? null : endControlErrors);
    };
  }

  public static formatTime(hour: number, minute: number): string {
    return hour.toLocaleString('en-US', {minimumIntegerDigits: 2}) + ':'
      + minute.toLocaleString('en-US', {minimumIntegerDigits: 2});
  }

  public static timeDurationNotZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }
      const { hours, minutes } = control.value;
      return ((hours || 0) + (minutes || 0) === 0) ? { 'duration-zero': true } : null;
    }
  }

}
