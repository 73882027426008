import { DateFormat } from './enums/date-format.enum';
import { Languages } from './enums/languages.enum';

export function DateFormatByLocation(location: string): string {
  switch(location) {
    case Languages.DE:
    case Languages.DK:
    case Languages.CZ:
    case Languages.NO:
    case Languages.PL:
    case Languages.RU:
    case Languages.RO:
    case Languages.FI:
    case Languages.DEFAULT:
      return DateFormat.DEFAULT;
    case Languages.EN:
    case Languages.FR:
    case Languages.IT:
    case Languages.PT:
    case Languages.ES:
    case Languages.TH:
    case Languages.DEV:
      return DateFormat.EN;
    case Languages.NL:
    case Languages.SE:
      return DateFormat.NL;
    case Languages.HU:
      return DateFormat.HU;
    case Languages.JP:
      return DateFormat.JP;
  }
}
