import { ColumnDefinition } from 'app/shared/column-definition';
import { BaseColumnDefinitionService } from 'app/shared/services/base-column-definition.service';
import { LanguageService } from '../../../../shared/services/language.service';
import { Injectable } from '@angular/core';
import { ColumnDefinitionService } from 'app/shared/contract/column-definition-service.interface';
import { ColumnDefinitionKeys } from 'app/shared/enums/column-definition-keys.enum';
import { OperatingHoursPipe } from 'app/shared/pipes/operating-hours.pipe';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { ConstructionYearFormatPipe } from '../pipes/construction-year.pipe';
import { DimensionUnitPipe } from '../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class EquipmentColumnDefinitionService extends BaseColumnDefinitionService implements ColumnDefinitionService {
  private _availableColumns: ColumnDefinition[];

  constructor(
    protected dimensionUnitPipe: DimensionUnitPipe,
    protected dimensionUnitConverterPipe: DimensionUnitConverterPipe,
    protected languageService: LanguageService,
    protected operatingHoursPipe: OperatingHoursPipe,
    protected contactTypeResolverPipe: ContactTypeResolverPipe,
    protected constructionYearPipe: ConstructionYearFormatPipe,
    protected translatableStringPipe: TranslatableStringPipe,
  ) {
    super(languageService,
      dimensionUnitPipe,
      dimensionUnitConverterPipe,
      operatingHoursPipe,
      contactTypeResolverPipe,
      constructionYearPipe,
      translatableStringPipe);
    this.initColumnDefinitions();
  }

  public get availableColumns(): ColumnDefinition[] {
    return this._availableColumns;
  }

  private initColumnDefinitions(): void {
    this._availableColumns = [
      ColumnDefinitionKeys.EQUIPMENT_IMAGE,
      ColumnDefinitionKeys.EQUIPMENT_CUSTOMER_SERIAL_NUMBER,
      ColumnDefinitionKeys.EQUIPMENT_SERIAL_NUMBER,
      ColumnDefinitionKeys.EQUIPMENT_LABELS,
      ColumnDefinitionKeys.EQUIPMENT_MODEL,
      ColumnDefinitionKeys.MANUFACTURER_NAME,
      ColumnDefinitionKeys.EQUIPMENT_TYPE_CATEGORY1,
      ColumnDefinitionKeys.EQUIPMENT_TYPE_CATEGORY2,
      ColumnDefinitionKeys.CURRENT_ADDRESS,
      ColumnDefinitionKeys.GENERAL_STATUS,
      ColumnDefinitionKeys.GENERAL_OPEN_DETAILS,
      ColumnDefinitionKeys.EQUIPMENT_NAME,
      ColumnDefinitionKeys.EQUIPMENT_BEUTLHAUSER_ID,
      ColumnDefinitionKeys.EQUIPMENT_COST_CENTER,
      ColumnDefinitionKeys.EQUIPMENT_CONSTRUCTION_YEAR,
      ColumnDefinitionKeys.EQUIPMENT_CURRENT_LOCATION_NAME,
      ColumnDefinitionKeys.EQUIPMENT_CURRENT_LOCATION_NUMBER,
      ColumnDefinitionKeys.EQUIPMENT_CURRENT_LOCATION_COST_CENTER,
      ColumnDefinitionKeys.CURRENT_OPERATING_HOURS,
      ColumnDefinitionKeys.GENERAL_ORGANISATION,
      ColumnDefinitionKeys.EQUIPMENT_SCAN_CODE,
      ColumnDefinitionKeys.EQUIPMENT_BGL_CODE,
      ColumnDefinitionKeys.EQUIPMENT_LICENSE_PLATE,
      ColumnDefinitionKeys.EQUIPMENT_REGISTRATION_DATE,
      ColumnDefinitionKeys.ASSIGNED_OWNER,
      ColumnDefinitionKeys.ASSIGNED_PERSON_IN_CHARGE,
      ColumnDefinitionKeys.ASSIGNED_MAINTENANCE_ASSIGNEE,
      ColumnDefinitionKeys.ASSIGNED_DRIVER,
      ColumnDefinitionKeys.ASSIGNED_POSSESSOR,
      ColumnDefinitionKeys.EQUIPMENT_INFO,
      ColumnDefinitionKeys.EQUIPMENT_FAVOURITE,
    ]
    .reduce((acc, defKey) => ([ ...acc, this.columnDefinitions[defKey] ]), [])
    .filter(Boolean);
  }
}
