import { LanguageService } from './../../../../../../../shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { BaseEquipmentViewDispositionComponent } from '../base/base-equipment-view-disposition.component';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-mobile-equipment-view-disposition',
  templateUrl: './mobile-equipment-view-disposition.component.html',
  styleUrls: ['./mobile-equipment-view-disposition.component.scss']
})
export class MobileEquipmentViewDispositionComponent extends BaseEquipmentViewDispositionComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory, equipmentStore, languageService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  navigateToProjectAssignments(projectId) {
    this.router.navigate([`mobile/projects/view/${projectId}/assignments`],
      { queryParams: { backRoute: this.router.url } });
  }
}
