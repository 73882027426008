import { AsyncValidatorFn, FormArray, FormControl, ValidationErrors } from '@angular/forms';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdditionalFieldsService } from './additional-fields.service';
import { AdditionalFieldEntity } from '../../../osp-ui/rich-components/bh-additional-field/additional-field';
import {
  FormArrayValidatorErrorType,
} from '../../../../shared/components/draggable-list/form-array-validator-error-type.enum';
import { Utils } from '../../../../shared/utils';
import { FormTypeAdditionalFieldSelectOptions } from './add-edit-additional-field-dialog/add-edit-additional-field-dialog.component';


type EntryCounter = { amount: number, indexes: number[] };


export class AdditionalFieldsValidators {

  static uniqueAdditionalFieldKey(additionalFieldService: AdditionalFieldsService,
                                  additionalFieldEntity: AdditionalFieldEntity,
                                  additionalFieldId?: string): AsyncValidatorFn {
    return asyncValidatorFactory((additionalFieldKey) => {
      if (!additionalFieldKey || !additionalFieldKey.trim()) {
        return of(null);
      }

      return additionalFieldService
        .isAdditionalFieldKeyInUse(additionalFieldKey.trim(), additionalFieldEntity, additionalFieldId)
        .pipe(map(res => res ? {taken: true} : null));
    });
  }

  public static uniqueOptionKeyField(formControl: FormControl<string>): ValidationErrors {
    const formArray = <FormArray<FormTypeAdditionalFieldSelectOptions>>formControl?.parent?.parent;

    if (!formArray) {
      return null;
    }

    const optionKeyControls = formArray?.controls?.map(group => group.controls.optionKey);
    optionKeyControls.forEach(control => Utils.removeError(control, FormArrayValidatorErrorType.DUPLICATES));

    let optionCounterMap = optionKeyControls
      .map(control => control.value ?? '')
      .reduce((acc, option, index) =>
        AdditionalFieldsValidators.addEntryToCounter(option, index, acc),
        new Map<string, EntryCounter>());

    if (optionCounterMap && optionCounterMap.size > 0) {
      optionKeyControls.forEach(control => {
        if (optionCounterMap.get(control.value ?? '')?.amount > 1) {
          Utils.addError(control, {[FormArrayValidatorErrorType.DUPLICATES]: true});
        }
      });

      if (optionCounterMap.get(formControl.value ?? '')?.amount > 1) {
        return { [FormArrayValidatorErrorType.DUPLICATES]: true };
      }
    }

    return null;
  }

  private static addEntryToCounter(value: string, index: number, counter: Map<string, EntryCounter>): Map<string, EntryCounter> {
    if (value) {
      let { amount, indexes } = counter.get(value) ?? { amount: 1, indexes: [index] };
      if (counter.has(value)) {
        amount++;
        indexes = [...indexes, index];
      }
      counter.set(value, { amount, indexes });
    }
    return counter;
  }
}
