<div class="m-view-list-inputs m-view-list-inputs-overflow" fxFlexFill>
  <mat-form-field floatLabel="always">
    <mat-label>{{'general.intNumber.s'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentCustomerSerialNumber}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.manufacturerSerialNumber'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentSerialNumber}}">
  </mat-form-field>

  <mat-form-field floatLabel="always" *ngIf="equipment?.equipmentBeutlhauserId">
    <mat-label>{{'modules.equipment.base.beutlhauserId'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentBeutlhauserId}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'general.costCenter'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentCostCenter}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.firstRegistration'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.equipmentRegistrationDate | date: 'dd.MM.yyyy'}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'general.org.s'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.organisationName}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'general.manufacturer'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.manufacturer?.manufacturerName}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.model.s'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentModel}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.constrYear'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentConstructionYear}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'general.type'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.equipmentType ? equipment?.equipmentType.category1 : ''}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'general.class'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.equipmentType ? equipment?.equipmentType.category2 : 'general.unknown'|translate}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.deliveryDate'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.equipmentDeliveryDate | date: 'dd.MM.yyyy'}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.warrantyPeriod'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{transformGuaranteeString()}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'modules.equipment.base.warrantyPeriodEnd'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{guaranteeEndDate | date: 'dd.MM.yyyy'}}">
  </mat-form-field>

  <mat-form-field floatLabel="always" *ngIf="equipment?.equipmentGuaranteeOperatingHoursLimit">
    <mat-label>{{'modules.equipment.base.operatingHoursLimit'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentGuaranteeOperatingHoursLimit}}">
  </mat-form-field>

  <mat-form-field floatLabel="always" *ngIf="allowedLiveData(equipment?.hasLiveOperatingHours)">
    <mat-label>{{'general.operatingHour.pl'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.currentOperatingHours ? (equipment.currentOperatingHours | operatingHours) : 'general.noData'|translate}}">
  </mat-form-field>

  <mat-form-field floatLabel="always" *ngIf="allowedLiveData(equipment?.hasLiveMileage)">
    <mat-label>{{'general.mileage'|translate|uppercase}}</mat-label>
    <input matInput readonly
           value="{{equipment?.currentMileage ? (equipment?.currentMileage | mileage) : 'general.noData'|translate}}">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>{{'general.scanCode'|translate|uppercase}}</mat-label>
    <input matInput readonly value="{{equipment?.equipmentScanCode ? equipment?.equipmentScanCode : ''}}">
  </mat-form-field>
</div>

<button mat-fab color="primary"
        *ngIf="isAnyMenuItemActive()"
        class="m-view-float-button"
        [matMenuTriggerFor]="floatingButtonMenu">
  <mat-icon>more_horiz</mat-icon>
</button>

<mat-menu #floatingButtonMenu="matMenu" overlapTrigger="false" class="m-view-floating-button-menu">
  <span *ngFor="let item of floatingButtonMenuItems">
    <button mat-menu-item *ngIf="item.enabled" (click)="navigateToPath(item.path)">
      {{item.label}}
    </button>
  </span>
</mat-menu>
