<div class="general-data-wrapper">
  <form [formGroup]="generalInformationFormGroup">
    <bh-title [text]="'general.generalData'|translate"></bh-title>

    <mat-form-field>
      <mat-label>{{'general.manufacturer'|translate}}</mat-label>
      <mat-select formControlName="manufacturerId">
        <mat-option>
          <ngx-mat-select-search [formControl]="manufacturerFilter"
                                 [placeholderLabel]="'general.labels.search'|translate:{ value: ('general.manufacturer'|translate) }"
                                 [noEntriesFoundLabel]="'modules.equipment.equipmentAddEdit.noManufacturersFound.'|translate">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let manufacturer of manufacturers | async" [value]="manufacturer.manufacturerName">
          {{ manufacturer.manufacturerName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'modules.equipment.base.manufacturerSerialNumber'|translate}}</mat-label>
      <input type="text" matInput
             formControlName="serialNumber"/>
      <mat-error *ngIf="serialNumber.hasError('taken')">
        {{'shared.validation.alreadyAssigned'|translate:
        { value: 'modules.equipment.base.manufacturerSerialNumber'|translate } }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'modules.equipment.base.model.s'|translate}}</mat-label>
      <input type="text" matInput formControlName="model"
             required/>
      <mat-error *ngIf="equipmentModel.hasError('required')">
        {{'shared.validation.required'|translate:{ value: 'modules.equipment.base.model.s'|translate } }}
      </mat-error>
    </mat-form-field>

    <section fxLayout="row" fxLayoutGap="15px">
      <mat-form-field>
        <mat-select placeholder="{{'modules.equipment.base.constrYear'|translate}} /
                    {{'general.units.time.month.s'|translate}}"
                    formControlName="constructionMonth"
                    (selectionChange)="constructionMonthSelected()">
          <mat-option *ngFor="let m of months" [value]="m.value">
            {{ m.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="{{'modules.equipment.base.constrYear'|translate}} /
                  {{'general.units.time.year.s'|translate}}"
                  formControlName="constructionYear"
                  (selectionChange)="constructionYearSelected()">
        <mat-option *ngFor="let y of years" [value]="y">
          {{ y }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </section>

    <mat-form-field>
      <mat-label>{{'modules.equipment.base.mark'|translate}}</mat-label>
      <input type="text" matInput
             formControlName="licensePlate"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'modules.equipment.base.productClass'|translate}}</mat-label>
      <input type="text" matInput
             formControlName="productClass"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'general.costCenter'|translate}}</mat-label>
      <input matInput formControlName="costCenter"
             list="costCenterList" type="text"/>
      <datalist id="costCenterList">
        <option *ngFor="let costCenter of filteredCostCenters | async"
                [value]="costCenter | slice:0:30">
          {{ costCenter }}
        </option>
      </datalist>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'modules.equipment.base.deliveryDate'|translate}}</mat-label>
      <input matInput type="date"
             formControlName="deliveryDate">
    </mat-form-field>
  </form>
</div>
