import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../../shared/equipments.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { BaseEquipmentLogMileage } from '../base/base-equipment-log-mileage';
import { RouterHistory } from '../../../../../../shared/router-history';
import { DimensionUnitPipe } from '../../../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../../../shared/pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-mobile-equipment-log-operating-hours',
  templateUrl: '../../mobile/mobile-equipment-log-lifecycle.component.html'
})
export class MobileEquipmentLogMileageComponent extends BaseEquipmentLogMileage {

  public title = this.languageService.getInstant('modules.equipment.base.logMileage').toUpperCase();

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected formBuilder: UntypedFormBuilder,
              protected equipmentsService: EquipmentsService,
              protected equipmentStore: EquipmentsDataSource,
              protected equipmentStatusService: EquipmentCheckerService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected dimensionUnitPipe: DimensionUnitPipe,
              protected dimensionUnitConverterPipe: DimensionUnitConverterPipe) {
    super(authService,
      router,
      route,
      formBuilder,
      equipmentsService,
      equipmentStore,
      equipmentStatusService,
      routerHistory,
      languageService,
      dimensionUnitPipe,
      dimensionUnitConverterPipe);
  }
}
