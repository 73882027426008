import { Pipe, PipeTransform } from '@angular/core';
import { CurrentEquipmentStatus } from 'app/modules/equipment/contract/current-equipment-status.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { ViewEquipment } from 'app/modules/equipment/contract/view-equipment.interface';
import { MarkerItemCurrentEquipmentStatus } from '../interfaces/marker-item-current-equipment-status.interface';
import { MapInfoTelematicsEquipmentStatusComponent } from '../components/map-info-window/map-info-telematics/map-info-telematics-equipment-status/map-info-telematics-equipment-status';
import { Utils } from 'app/shared/utils';

@Pipe({
  name: 'mapMarkersConvertTelematicStatus'
})
export class MapMarkersConvertTelematicStatusPipe implements PipeTransform {

  private equipment: ViewEquipment;

  public transform(
    value: CurrentEquipmentStatus,
    equipment: ViewEquipment
  ): MapLocationMarker<MarkerItemCurrentEquipmentStatus>[] {
    this.equipment = equipment;
    return this.isValidLocationStatus(value)
      ? this.getMapLocationMarkers(value)
      : null;
  }

  private getMapLocationMarkers(status: CurrentEquipmentStatus): MapLocationMarker<MarkerItemCurrentEquipmentStatus>[] {
    return [{
      id: status.equipmentId,
      location: status.latLng,
      item: this.getItem(status),
      infoWindow: {
        content: MapInfoTelematicsEquipmentStatusComponent
      }
    }]
  }

  private getItem(item: CurrentEquipmentStatus): MarkerItemCurrentEquipmentStatus {
    return item
      ? {
        ...item,
        equipmentName: this.equipment?.equipmentName || this.equipment?.equipmentModel || null
      } : null;
  }

  private isValidLocationStatus(value: CurrentEquipmentStatus): boolean {
    return Utils.isValidLocation({ location: { lat: value?.latLng?.lat, lon: value?.latLng?.lon } });
  }

}
