import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatableStringPipe } from './translatable-string/translatable-string.pipe';
import { TranslatableStringArrayPipe } from './translatable-string/translatable-string-array.pipe';


@NgModule({
  declarations: [
    TranslatableStringPipe,
    TranslatableStringArrayPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslatableStringPipe,
    TranslatableStringArrayPipe,
  ],
  providers: [
    TranslatableStringPipe,
    TranslatableStringArrayPipe,
  ]
})
export class OspUiPipesModule { }
