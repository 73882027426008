import { LanguageService } from '../../../../shared/services/language.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MaintenanceRuleStore } from '../shared/service/maintenance-rule.store';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MaintenanceRuleAssignedEquipmentsDialogComponent } from '../maintenance-rule-assigned-equipments-dialog/maintenance-rule-assigned-equipments-dialog.component';
import { MaintenanceRule } from '../shared/contract/maintenance-rule';
import { CheckboxConfirmDialogComponent } from '../../../../shared/components/checkbox-confirm-dialog/checkbox-confirm-dialog.component';
import { MaintenanceRuleViewDialogComponent } from '../maintenance-rule-view-dialog/maintenance-rule-view-dialog.component';
import { ArticleGroupCount } from '../../shared/contract/article-group-count';
import { MaintenanceTypeCount } from '../../shared/contract/maintenance-type-count';
import { MaintenanceFilterCollectionView } from '../../shared/contract/maintenance-filter-collection-view.interface';
import { MaintenanceConfigColumnService } from '../../shared/maintenance-config-column.service';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-rule-list',
  templateUrl: './maintenance-rule-list.component.html',
  styleUrls: ['./maintenance-rule-list.component.scss']
})
export class MaintenanceRuleListComponent implements OnInit, AfterViewInit {

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  termControl: UntypedFormControl;
  filterForm: UntypedFormGroup;

  maintenanceTypes: Observable<MaintenanceTypeCount[]>;
  articleGroups: Observable<ArticleGroupCount[]>;
  maintenanceRulesFilter: Observable<MaintenanceFilterCollectionView>;

  constructor(public ruleStore: MaintenanceRuleStore,
              private formBuilder: UntypedFormBuilder,
              private columnService: MaintenanceConfigColumnService,
              private dialog: MatDialog,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.maintenanceRulesFilter = this.ruleStore.maintenanceRulesFilter;
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => this.paginator.pageSize = pageSize);

    this.createForms();
    this.handleLoadRules();
  }

  ngAfterViewInit(): void {
    this.loadRules();
    this.termControl.valueChanges.subscribe(() => this.paginator.firstPage())
  }

  public onPaginateChange(event: PageEvent): void {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
  }

  loadRules(): void {
    this.ruleStore.loadRules(this.paginator.pageIndex,
        this.paginator.pageSize,
        this.termControl.value,
        this.getMaintenanceTypeIds(),
        this.getArticleGroups());
  }

  getMaintenanceTypeIds(): string[] {
    return this.filterForm.value.maintenanceTypes || [];
  }

  getArticleGroups(): string[] {
    return this.filterForm.value.articleGroups || [];
  }

  deleteRule(ruleId: string): void {
    const dialogRef = this.dialog.open(CheckboxConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = `${this.translate('modules.maintenance.confirmation.message.deleteRulePart1')}
    ${this.translate('modules.maintenance.confirmation.message.deleteRulePart2')}`;
    dialogRef.componentInstance.secondaryConfirmMessage =
    this.translate('modules.maintenance.confirmation.message.deleteRulePartSecondary');
    dialogRef.componentInstance.buttonMessage = this.translate('general.buttons.delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.ruleStore.deleteRule(ruleId);
      }
    });
  }

  showAssignedEquipments(rule: MaintenanceRule): void {
    const dialogRef: MatDialogRef<MaintenanceRuleAssignedEquipmentsDialogComponent> =
     this.dialog.open(MaintenanceRuleAssignedEquipmentsDialogComponent);
    dialogRef.componentInstance.ruleName = rule.ruleName;
    dialogRef.componentInstance.equipments = this.ruleStore.assignedEquipmentsFor(rule.maintenanceRuleId);
  }

  noAssignedEquipments(maintenanceRuleId: any): boolean {
    return this.ruleStore.assignedEquipmentsFor(maintenanceRuleId).length === 0;
  }

  private handleLoadRules(): void {

    merge(
        this.ruleStore.ruleDelete,
        this.filterForm.valueChanges,
        this.termControl.valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged()),
        this.paginator.page)
    .pipe(
        untilDestroyed(this),
        tap(() => this.loadRules()))
    .subscribe();
  }

  private createForms(): void {
    this.filterForm = this.formBuilder.group({
      maintenanceTypes: [],
      articleGroups: []
    });

    this.termControl = new UntypedFormControl('');
  }

  openRuleView(rule: MaintenanceRule): void {
    const dialogRef = this.dialog.open(MaintenanceRuleViewDialogComponent);
    dialogRef.componentInstance.rule = rule;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
