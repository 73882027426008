import { inject, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../core/guards/can-activate-child-authority';
import { Authorities } from '../../shared/enums/authorities.enum';
import { ConnectorListComponent } from './connectors/connector-list/connector-list.component';
import { ConnectorAddComponent } from './connectors/connector-add/connector-add.component';
import { ConnectorEditComponent } from './connectors/connector-edit/connector-edit.component';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';
import { PublicApiConnectorComponent } from './public-api-connector/public-api-connector.component';
import { KeycloakService } from '../../core/keycloak';

const connectorRoutes: Routes = [
  {
    path: 'connectors',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'list',
        component: ConnectorListComponent,
        data: {
          authorities: [Authorities.JOBS_MANAGE]
        }
      },
      {
        path: 'add',
        component: ConnectorAddComponent,
        data: {
          authorities: [Authorities.JOBS_MANAGE]
        }
      },
      {
        path: 'edit',
        component: ConnectorEditComponent,
        data: {
          authorities: [Authorities.JOBS_MANAGE]
        }
      }
    ]
  },

  {
    path: 'api-keys',
    component: PublicApiConnectorComponent,
    canActivate: [() => inject(KeycloakService).hasAuthority(Authorities.CONNECTOR_VIEW)]
  }
];

export const connectorRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(connectorRoutes);
