import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserListComponent } from './users/user-list/user-list.component';
import { userroleRouting } from './userrole.routing';
import { UsersService } from './shared/users.service';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { RolesService } from './shared/roles.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { RoleAddComponent } from './roles/role-add/role-add.component';
import { UserAddSuperadminComponent } from './users/user-add-superadmin/user-add-superadmin.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { UserEditAdminComponent } from './users/user-edit-admin/user-edit-admin.component';
import { UserAddPartneradminComponent } from './users/user-add-partneradmin/user-add-partneradmin.component';
import { PartnersService } from '../organisation/shared/partners.service';
import { UserAddFleetadminComponent } from './users/user-add-fleetadmin/user-add-fleetadmin.component';
import { UsersStore } from './shared/users.store';
import { RolesStore } from './shared/roles.store';
import { SharedModule } from '../../shared/shared.module';
import { LanguageSwitcherComponent } from '../header/language-switcher/language-switcher.component';
import { UserAddEditComponent } from './users/user-add-edit/user-add-edit.component';
import { UserSyncKeycloakComponent } from './users/user-sync-keycloak/user-sync-keycloak.component';
import { UserConfigListColumnService } from './shared/user-config-list-column.service';
import { CustomerConfigListColumnService } from './shared/customer-config-list-column.service';
import { LanguageSwitcherDialogComponent } from '../header/language-switcher/language-switcher-dialog.component';
import {
  DimensionUnitSwitcherComponent
} from './users/user-settings/dimension-unit-switcher/dimension-unit-switcher.component';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import {
  TimezoneSwitcherComponent
} from './users/user-settings/timezone-switcher/timezone-switcher.component';
import { TimezoneService } from '../../shared/services/timezone.service';
import { SecuredImageModule } from 'app/shared/components/test-place/secured-image/secured-image.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CustomMaterialModule,
    SharedModule,
    TranslateModule,
    MainPipeModule,
    userroleRouting,
    SecuredImageModule,
  ],
  declarations: [
    UserListComponent,
    RoleListComponent,
    RoleAddComponent,
    RoleEditComponent,
    UserAddSuperadminComponent,
    UserEditAdminComponent,
    UserAddPartneradminComponent,
    UserAddFleetadminComponent,
    UserSettingsComponent,
    LanguageSwitcherComponent,
    LanguageSwitcherDialogComponent,
    UserAddEditComponent,
    UserSyncKeycloakComponent,
    DimensionUnitSwitcherComponent,
    TimezoneSwitcherComponent,
  ],
  bootstrap: [],
  providers: [
    UsersService,
    TimezoneService,
    RolesService,
    PartnersService,
    UsersStore,
    UserConfigListColumnService,
    CustomerConfigListColumnService,
    RolesStore
  ]
})
export class UserRoleModule {
}
