import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, forkJoin, map, of } from 'rxjs';
import { UpdateFilterCommand } from '../../../update-filter-command.interface';
import { FilterTypes } from '../../../filter-types.enum';
import { FilterParams } from '../../../filter-params.class';
import { EquipmentStateFilterView } from '../../../filter-view/equipment-state-filter-view.interface';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { FilterReadinessChecker } from '../../interfaces/filter-readiness-checker.interface';
import { ReadinessCheckerPayload } from '../../interfaces/filter-readiness-checker-payload.interface';
import { EquipmentStatusFilterConsistencyResolver } from '../consistency-resolver/equipment-status-filter-consistency-resolver.class';


export abstract class EquipmentStatusFilterReadinessCheckerBase implements FilterReadinessChecker {

  private isFiltersChecked = false;
  private isDefaultFiltersApplied = new BehaviorSubject<boolean>(false);
  private isConsistencyUpdateApplied = new BehaviorSubject<boolean>(true);
  private _isReady = new BehaviorSubject<ReadinessCheckerPayload>({ isReady: false });
  private overriddenDefaultFilters: UpdateFilterCommand[] = null;
  public readonly isReady = this._isReady.asObservable();

  protected abstract getEquipmentStatusFilters(): Observable<EquipmentStateFilterView[]>;

  constructor(protected params: FilterParams){
    this.initIsReadyListener();
  }

  public overrideDefaultFilter(commands: UpdateFilterCommand[]): void {
    this.overriddenDefaultFilters = commands;
  }

  public isReadyCheck(): void {
    if (!this.isFiltersChecked) {
      this.isFiltersChecked = true;
      this.getInitialDefaultFilterCommands()
        .subscribe(commands => {
          this.isDefaultFiltersApplied.next(true);
          this.isConsistencyUpdateApplied.next(true);
          this._isReady.next({ isReady: true, commands })
        });
    }
  }

  public equipmentStatusesChanged(): void {
    this.isConsistencyUpdateApplied.next(false);
    this._isReady.next({ isReady: false });
  }

  private getInitialDefaultFilterCommands(): Observable<UpdateFilterCommand[]> {
    return this.overriddenDefaultFilters
      ? this.getOverriddenDefaultFilterCommands()
      : this.getDefaultFilterCommands();
  }

  private getDefaultFilterCommands(): Observable<UpdateFilterCommand[]> {
    return forkJoin([
      this.getFilterDefaultInitCommands(),
      this.getFilterConsistenceCommands(),
    ])
    .pipe(map(commandsArray => commandsArray.reduce((acc, commands) => [...acc, ...commands], [])));
  }

  private getOverriddenDefaultFilterCommands(): Observable<UpdateFilterCommand[]> {
    const commands = this.overriddenDefaultFilters;
    this.overriddenDefaultFilters = null;
    return of(commands);
  }

  private initIsReadyListener(): void {
    combineLatest([
      this.isDefaultFiltersApplied.asObservable(),
      this.isConsistencyUpdateApplied.asObservable(),
    ])
    .pipe(
      map(states => states.every(Boolean)),
      distinctUntilChanged())
    .subscribe(isReady => this.isFiltersChecked = isReady);
  }

  private getFilterDefaultInitCommands(): Observable<UpdateFilterCommand[]> {
    return !this.isDefaultFiltersApplied.value
      ? this.getEquipmentStatusFilters()
        .pipe(map(filters => this.convertToDefaultStatusesUpdateCommands(filters)))
      : of([]);
  }

  private getFilterConsistenceCommands(): Observable<UpdateFilterCommand[]> {
    return !this.isConsistencyUpdateApplied.value && this.isDefaultFiltersApplied.value
      ? this.getEquipmentStatusFilters()
        .pipe(map((filters => this.convertToConsistentStatusesUpdateCommand(filters))))
      : of([]);
  }

  private convertToConsistentStatusesUpdateCommand(statuses: EquipmentStateFilterView[]): UpdateFilterCommand[] {
    return statuses?.length > 0
      ? new EquipmentStatusFilterConsistencyResolver(statuses, this.params).getCommandsToRestoreConsistency()
      : [];
  }

  private convertToDefaultStatusesUpdateCommands(statuses: EquipmentStateFilterView[]): UpdateFilterCommand[] {
    return statuses
      .filter(({ category }) => category !== EquipmentStatusCategory.NOT_AVAILABLE)
      .reduce((acc, categoryGroup) => ([
        ...acc,
        categoryGroup.category,
        ...categoryGroup.statuses.map(({ equipmentStatusId }) => equipmentStatusId)
      ]), [])
      .map(value => ({
        filterType: FilterTypes.EQUIPMENT_STATE,
        storeName: value,
        newValue: true
      }));
  }

}
