import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'constructionYearFormat'
})
export class ConstructionYearFormatPipe implements PipeTransform {

  transform(constrYear: string): string {
    return (constrYear && constrYear.match(/\d*-00/)) ? constrYear.slice(0, -3) : constrYear;
  }
}
