import { ProjectToStockTransferItem } from './project-to-stock-transfer-item';

export class CreateProjectToStockTransferCartCommand {
  constructor(
    public targetStockId: string,
    public transferItems: ProjectToStockTransferItem[],
    public employeeId?: string,
    public externalEmployee?: string,
    public comment?: string,
    public transferDate?: Date) {}
}
