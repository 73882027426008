import { TimeUnit } from './../contract/timefencing-data.interface';
import { TimefencingData } from 'app/shared/contract/timefencing-data.interface';
import { Pipe, Injectable, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'timefenceSelectedDay'
})

@Injectable()
export class TimefenceSelectedDayPipe implements PipeTransform {
  transform(row: TimefencingData, tableData: TimefencingData[]): boolean {
    return _.values(row).some((value) => (value instanceof Object) && (<TimeUnit>value).isSelected);
  }
}
