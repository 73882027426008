import { Component, Input, OnInit } from '@angular/core';
import { ViewEquipment } from '../../../equipment/contract/view-equipment.interface';
import { EquipmentsService } from '../../../equipment/shared/equipments.service';
import { BasicWidget } from '../basic-widget/basic-widget';

@Component({
  selector: 'bh-next-repairs-tile',
  templateUrl: './next-repairs-widget.component.html'
})
export class NextRepairsWidgetComponent extends BasicWidget implements OnInit {

  minCols = 4;
  minRows = 4;

  @Input() equipmentIds: string[] = [];
  equipments: ViewEquipment[] = [];

  constructor(private equipmentsService: EquipmentsService) {
    super();
  }

  ngOnInit() {
    this.loadEquipments();
  }

  loadEquipments() {
    let that = this;
    this.equipmentIds.forEach(eqId => {
      that.equipmentsService.getEquipment(eqId).subscribe(
        res => {
          this.equipments.push(res);
        })
    });
  }
}
