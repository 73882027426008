import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentMaintenanceTaskService } from '../../../../../shared/services/equipment-maintenance-task.service';
import { BaseEquipmentViewCompletedTaskComponent } from '../base/base-equipment-view-completed-task.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RouterHistory } from '../../../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@Component({
  selector: 'bh-equipment-view-completed-task',
  templateUrl: './equipment-view-completed-task.component.html',
  styleUrls: ['./equipment-view-completed-task.component.scss']
})
export class EquipmentViewCompletedTaskComponent extends BaseEquipmentViewCompletedTaskComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentMaintenanceTaskService: EquipmentMaintenanceTaskService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, equipmentMaintenanceTaskService, dialog, routerHistory, languageService,
      fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getMaintenanceTask();
  }

}
