import { Address } from './address.interface';
import { PartnerTheme } from './partner-theme.enum';
import { PartnerMailServer } from './partner-mail-server.enum';

export class UpdatePartnerCommand {
  partnerId: string;
  partnerName: string;
  partnerLogoBase64: string;
  partnerAddress: Address;
  partnerTheme: PartnerTheme;
  partnerMailServer: PartnerMailServer;
}
