import { TransferItem } from '../shared/transfer/model/transfer-item';
import { ViewProjectAmount } from './view-project-amount.interface';
import { MobileTransferItem } from './mobile-transfer-item';
import { TransferItemType } from '../shared/transfer/model/transfer-item-type.enum';

export class ProjectToStockTransferItem {

  public static fromTransferItem(item: TransferItem) {
    return new ProjectToStockTransferItem(
        item.sourceStockId,
        '',
        item.transferId,
        item.transferItemType,
        item.transferAmount,
        item.id
    );
  }

  public static fromMobileTransferItem(item: MobileTransferItem, sourceProjectId: string) {
    return new ProjectToStockTransferItem(
        item.sourceStockId,
        sourceProjectId,
        item.transferId,
        TransferItemType.BULKITEM,
        item.amount
    );
  }

  public static fromViewProjectAmount(item: ViewProjectAmount, sourceProjectId: string, transferAmount: number) {
    return new ProjectToStockTransferItem(
        item.stockId,
        sourceProjectId,
        item.transferItemId,
        item.transferItemType,
        transferAmount,
        item.amountId
    );
  }

  private constructor(
      public sourceStockId: string,
      public sourceProjectId: string,
      public transferItemId: string,
      public transferItemType: TransferItemType,
      public amount: number,
      public amountId?: string
  ) {
  }
}
