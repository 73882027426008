import { EquipmentEmployeeRole } from './equipment-employee-role.enum';

export class ReplaceEquipmentEmployeeAssignmentCommand {

  public constructor(
      public equipmentId: string,
      public oldEmployeeAssignmentId: string,
      public oldEmployeeId: string,
      public newEmployeeId: string,
      public employeeRole: EquipmentEmployeeRole,
      public handOverDate: string) {
  }

}

