import { LanguageService } from 'app/shared/services/language.service';
import { Component } from '@angular/core';
import { ProjectDataSource } from '../../../shared/project.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { DispositionBasicMapComponent } from 'app/modules/disposition/shared/disposition-basic-map.component';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { MapConsts } from 'app/shared/modules/map/map.consts';

@UntilDestroy()
@Component({
  selector: 'bh-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss']
})
export class ProjectMapComponent extends DispositionBasicMapComponent implements SearchFilterActivatedView {
  public readonly mapSettings: MapConfiguration = this.getInitialMapSettings();
  public routeButton: ButtonInfo = {
    route: 'sites/projects/list/',
    tooltip: this.languageService.getInstant('general.list'),
    iconName: 'list'
  };
  public readonly searchSuggestionsField = '';


  constructor(
    projectStore: ProjectDataSource,
    router: Router,
    authService: KeycloakService,
    route: ActivatedRoute,
    organisationService: OrganisationsService,
    routerHistory: RouterHistory,
    languageService: LanguageService,
    equipmentStore: EquipmentsDataSource,
    contactDataSource: ContactDataSource)
  {
    super(
      projectStore,
      router,
      authService,
      route,
      organisationService,
      routerHistory,
      languageService,
      equipmentStore,
      contactDataSource);
  }

  public saveMapConfigs(mapConfig: MapConfiguration): void {
    this.projectStore.saveMapConfiguration(mapConfig);
  }

  private getInitialMapSettings(): MapConfiguration {
    return {
      ...MapConsts.getDefaultMapConfiguration(),
      showProjects: true,
      ...(this.projectStore.getMapConfiguration() || {}),
    }
  }
}
