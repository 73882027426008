<mat-card fxFlexFill class="entity-creation">
<mat-card fxFlexFill class="entity-creation">
  <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-icon>developer_board</mat-icon>
      <span *ngIf="!isEditMode">{{'modules.equipment.damage.logMaliciousDamage'|translate}}</span>
      <span *ngIf="isEditMode">{{'modules.equipment.damage.editMaliciousDamage'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid() || saving">
        <span *ngIf="!isEditMode">{{'general.buttons.add'|translate}}</span>
        <span *ngIf="isEditMode">{{'general.buttons.save'|translate}}</span> |
        <mat-icon *ngIf="saving"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        <mat-icon *ngIf="!saving && !isEditMode">add_circle</mat-icon>
        <mat-icon *ngIf="!saving && isEditMode">save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()" [disabled]="saving">
        <span *ngIf="!isEditMode">{{'general.buttons.cancel'|translate}}</span>
        <span *ngIf="isEditMode">{{'general.buttons.toOverview'|translate}}</span>
      </button>
      <button *ngIf="!isEditMode" mat-raised-button (click)="resetForm()" [disabled]="saving">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form" fxFlex>
      <form [formGroup]="damageAddForm" novalidate fxLayout="row" fxLayoutAlign="space-between stretch">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
          <div class="entity-creation-form-left border-right" fxFlex="25">
            <div class="card-group-title">{{'modules.equipment.damage.damageCaseData'|translate}}</div>
            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <mat-form-field>
                <mat-label>{{'modules.equipment.damage.damageType'|translate}}</mat-label>
                <mat-select required
                            formControlName="damageType"
                            [disableOptionCentering]="true">
                  <mat-option *ngFor="let dt of damageTypes" [value]="dt">{{dt | damageTypeDisplay}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'general.date'|translate}}</mat-label>
                <input matInput required
                       formControlName="damageDate" autocomplete="off"
                       [matDatepicker]="damageDatePicker">
                <mat-datepicker-toggle matSuffix [for]="damageDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #damageDatePicker></mat-datepicker>
                <mat-error *ngIf="damageDate.hasError('required')">
                  {{'shared.validation.required'|translate:{value: 'modules.equipment.damage.damageDate'|translate} }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.damage.damageLocation'|translate}}</mat-label>
                <input matInput
                       formControlName="damageLocation"
                       [maxlength]="fieldLimit.SHORT_TEXT">
              </mat-form-field>

              <mat-form-field *ngIf="hasModule(modules.COSTS) && hasAuthority(authorities.EQUIPMENT_VIEW_COSTS)">
                <mat-label>{{'general.invoice.s'|translate}}</mat-label>
                <input matInput
                       formControlName="invoiceId"
                       [maxlength]="fieldLimit.LONG_IDENTIFIER"
                       [matAutocomplete]="invoiceNumberAuto">
                <mat-autocomplete #invoiceNumberAuto="matAutocomplete" [displayWith]="displayInvoiceNumber.bind(this)">
                  <mat-option *ngFor="let invoice of filteredInvoices | async" [value]="invoice.invoiceId">
                    {{ invoice.invoiceNumber }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="invoiceId.hasError('invoiceExists')">
                  {{'modules.equipment.damage.invoiceNumberInvalid'|translate}}
                </mat-error>
              </mat-form-field>

              <div *ngIf="canUpdateEquipmentStatus()" fxFlex="100" class="equipment-status-select">
                <div class="bh-form-field" fxLayout="column">
                  <label>{{'general.status'|translate}}</label>
                  <div class="underline">
                    <div [matMenuTriggerFor]="equipmentStatusDropdown.menu" class="select"
                         fxLayout="row" fxLayoutAlign="space-between center">
                      <div class="overflow-wrapper">
                        <bh-equipment-status-badge *ngIf="newEquipmentStatus; else placeholder"
                                                   [_status]="newEquipmentStatus">
                        </bh-equipment-status-badge>
                        <ng-template #placeholder>
                          {{'modules.maintenance.task.alignEquipmentStatus.noSelectedEquipmentStatus' | translate}}
                        </ng-template>
                      </div>
                      <div class="mat-mdc-select-arrow-wrapper">
                        <div class="mat-mdc-select-arrow custom-select-arrow"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <bh-equipment-status-dropdown #equipmentStatusDropdown
                                              [equipment]="{ status: newEquipmentStatus }"
                                              [categoryFilter]="getAllExceptNotOperationalCategory()"
                                              (onStatusChange)="onEquipmentStatusChange($event)">
                </bh-equipment-status-dropdown>
              </div>

              <mat-form-field>
                <mat-label>{{'general.documentation'|translate}}</mat-label>
                <textarea matInput #documentTextarea
                          formControlName="documentation"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="entity-creation-form-left" fxFlex>
            <div class="card-group-title">{{'general.document.pl'|translate}}</div>
            <bh-file-upload
              [itemId]="equipmentId"
              uploaderType="DAMAGE"
              (onFileUploaded)="onFileUploaded($event)">
            </bh-file-upload>
            <div fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="space-between start">
              <bh-file *ngFor="let document of documents" [file]="document" (fileRemove)="removeDocument(document)"></bh-file>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
