<div class="header" fxLayoutAlign="space-between center">
  <span class="header__title">{{ 'general.comment.pl'|translate }}</span>
  <button *ngIf="canAddComment"
    mat-raised-button
    (click)="addEditComment(null)"
    color="primary">
    {{'general.buttons.addComment'|translate}}
  </button>
</div>
<div class="body">
  <bh-transport-comment class="body__comment-item"
    *ngFor="let comment of comments"
    [comment]="comment">
  </bh-transport-comment>
</div>
