import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { MobileEquipmentListComponent } from '../equipments/equipment-list/mobile/mobile-equipment-list.component';
import { MobileEquipmentMapComponent } from '../equipments/equipment-map/mobile/mobile-equipment-map.component';
import { MobileViewEquipmentComponent } from '../equipments/equipment-list/equipment-view/mobile/mobile-view-equipment.component';
import { MobileEquipmentViewDetailComponent } from '../equipments/equipment-list/equipment-view/equipment-view-detail/mobile/mobile-equipment-view-detail.component';
import { MobileEquipmentViewCurrentLocationComponent } from '../equipments/equipment-list/equipment-view/equipment-view-current-location/mobile/mobile-equipment-view-current-location.component';
import { MobileEquipmentViewAttachmentComponent } from '../equipments/equipment-list/equipment-view/equipment-view-attachment/mobile/mobile-equipment-view-attachment.component';
import { CanActivateChildMobile } from '../../../core/guards/can-activate-child-mobile';
import { MobileEquipmentViewDispositionComponent } from '../equipments/equipment-list/equipment-view/equipment-view-disposition/mobile/mobile-equipment-view-disposition.component';
import { MobileEquipmentViewTimelineComponent } from '../equipments/equipment-list/equipment-view/equipment-view-timeline/mobile/mobile-equipment-view-timeline.component';
import { MobileEquipmentLogOperatingHoursComponent } from '../equipments/equipment-log-lifecycle/equipment-log-operating-hours/mobile/mobile-equipment-log-operating-hours.component';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { Modules } from '../../../shared/enums/modules.enum';
import { MobileEquipmentScanCodeScannerComponent } from '../shared/equipment-scan-code-scanner/mobile/mobile-equipment-scan-code-scanner.component';
import { MobileTransferMultipleEquipmentsComponent } from '../../disposition/equipments/mobile/mobile-transfer-multiple-equipments/mobile-transfer-multiple-equipments.component';
import { MobileEquipmentViewDescriptionComponent } from '../equipments/equipment-list/equipment-view/equipment-view-description/mobile/mobile-equipment-view-description.component';
import { MobileEquipmentLogMileageComponent } from '../equipments/equipment-log-lifecycle/equipment-log-mileage/mobile/mobile-equipment-log-mileage.component';
import { MobileEquipmentAddComponent } from '../equipments/mobile-equipment-add/mobile-equipment-add.component';
import { ProjectSelectComponent } from '../equipments/equipment-assign-to-project/project-select/project-select.component';
import { MobileEquipmentViewDamageComponent } from '../equipments/equipment-list/equipment-view/equipment-view-damage/mobile/mobile-equipment-view-damage.component';
import { MobileEquipmentViewIncidentComponent } from '../equipments/equipment-list/equipment-view/equipment-view-incident/mobile/mobile-equipment-view-incident.component';
import { MobileEquipmentViewMileageComponent } from '../equipments/equipment-list/equipment-view/equipment-view-mileage/mobile-equipment-view-mileage.component';
import { MobileEquipmentViewOperatingHoursComponent } from '../equipments/equipment-list/equipment-view/equipment-view-operating-hours/mobile-equipment-view-operating-hours.component';
import { MobileEquipmentViewCompletedReminderComponent } from '../equipments/equipment-list/equipment-view/equipment-view-completed-reminder/mobile-equipment-view-completed-reminder.component';
import { MobileEquipmentInvoiceDetailsComponent } from '../shared/equipment-invoice-details/mobile/mobile-equipment-invoice-details.component';
import { MobileEquipmentViewCompletedTaskComponent } from '../equipments/equipment-list/equipment-view/equipment-view-completed-task/mobile/mobile-equipment-view-completed-task.component';
import { MobileEquipmentViewEmployeeAssignmentAddedComponent } from '../equipments/equipment-list/equipment-view/equipment-view-employee-assignment-added/mobile-equipment-view-employee-assignment-added.component';
import { MobileEquipmentViewEmployeeAssignmentRemovedComponent } from '../equipments/equipment-list/equipment-view/equipment-view-employee-assignment-removed/mobile-equipment-view-employee-assignment-removed.component';
import { MobileEquipmentViewEmployeeAssignmentReplacedComponent } from '../equipments/equipment-list/equipment-view/equipment-view-employee-assignment-replaced/mobile-equipment-view-employee-assignment-replaced.component';
import { MobileEquipmentTransferToProjectComponent } from 'app/modules/disposition/equipments/mobile/mobile-equipment-transfer-to-project.component';
import { CanActivateChildMobileWebApp } from 'app/core/guards/can-activate-child-mobile-web-app.guard';


const mobileEquipmentRoutes: Routes = [
  {
    path: 'mobile/equipments',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobile, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/mobile/landingpage'
      },
      {
        path: 'list',
        component: MobileEquipmentListComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
        }
      },
      {
        path: 'map',
        component: MobileEquipmentMapComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
        }
      },
      {
        path: 'search-scancode',
        component: MobileEquipmentScanCodeScannerComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
        }
      },
      {
        path: 'view/:id',
        component: MobileViewEquipmentComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
        },
        children: [
          {
            path: 'general',
            component: MobileEquipmentViewDetailComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
            }
          },
          {
            path: 'description',
            component: MobileEquipmentViewDescriptionComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
            }
          },
          {
            path: 'timeline',
            component: MobileEquipmentViewTimelineComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
            }
          },
          {
            path: 'attachment',
            component: MobileEquipmentViewAttachmentComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
            }
          },
          {
            path: 'current-location',
            component: MobileEquipmentViewCurrentLocationComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
            }
          },
          {
            path: 'disposition',
            component: MobileEquipmentViewDispositionComponent,
            canActivate: [CanActivateModule],
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
              modules: [Modules.DISPOSITION]
            }
          },
          {path: '', redirectTo: 'general', pathMatch: 'full'},
          {path: '**', redirectTo: 'general'}
        ]
      },
      {
        path: ':id/pair-scancode',
        component: MobileEquipmentScanCodeScannerComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/operating-hours',
        component: MobileEquipmentLogOperatingHoursComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_OPERATING_HOURS,
            Authorities.EQUIPMENT_UPDATE],
        }
      },
      {
        path: ':id/mileage',
        component: MobileEquipmentLogMileageComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_MILEAGE,
            Authorities.EQUIPMENT_UPDATE],
        }
      },
      {
        path: ':id/transfer-to-project',
        component: MobileEquipmentTransferToProjectComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: ':id/damages/:lifecycleId',
        component: MobileEquipmentViewDamageComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_VIEW,
            Authorities.EQUIPMENT_MANAGE_DAMAGES,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/incidents/:lifecycleId',
        component: MobileEquipmentViewIncidentComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_VIEW,
            Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/mileage/:lifecycleId',
        component: MobileEquipmentViewMileageComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_VIEW,
            Authorities.EQUIPMENT_MANAGE_MILEAGE,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/operating-hours/:lifecycleId',
        component: MobileEquipmentViewOperatingHoursComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_VIEW,
            Authorities.EQUIPMENT_MANAGE_OPERATING_HOURS,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/completed-reminders/:lifecycleId',
        component: MobileEquipmentViewCompletedReminderComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW]
        }
      },
      {
        path: ':id/employee-assignment-added/:lifecycleId',
        component: MobileEquipmentViewEmployeeAssignmentAddedComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW]
        }
      },
      {
        path: ':id/employee-assignment-replaced/:lifecycleId',
        component: MobileEquipmentViewEmployeeAssignmentReplacedComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW]
        }
      },
      {
        path: ':id/employee-assignment-removed/:lifecycleId',
        component: MobileEquipmentViewEmployeeAssignmentRemovedComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW]
        }
      },
      {
        path: ':id/invoice-details/:invoiceId',
        component: MobileEquipmentInvoiceDetailsComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW]
        }
      },
      {
        path: ':id/completed-maintenance-tasks/:lifecycleId',
        component: MobileEquipmentViewCompletedTaskComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW],
          modules: [Modules.COSTS]
        }
      },
      {
        path: 'operating-hours/scan-code',
        component: MobileEquipmentScanCodeScannerComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_OPERATING_HOURS,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: 'add-damage/scan-code',
        component: MobileEquipmentScanCodeScannerComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_UPDATE,
            Authorities.EQUIPMENT_MANAGE_DAMAGES,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: 'transfer-to-project/scan-code',
        component: MobileEquipmentScanCodeScannerComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: 'assign-to-project/projects',
        component: ProjectSelectComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.EQUIPMENT_MANAGE_DISPOSITION],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: 'transfer-equipments-to-project-list',
        component: MobileTransferMultipleEquipmentsComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: 'transfer-equipments-to-project',
        component: MobileEquipmentTransferToProjectComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.ASSET_FROM_STOCK_TRANSFER, Authorities.ASSET_FROM_PROJECT_TRANSFER],
          modules: [Modules.DISPOSITION]
        }
      },
      {
        path: 'add',
        component: MobileEquipmentAddComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_CREATE]
        }
      }
    ]
  }
];

export const mobileEquipmentRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(mobileEquipmentRoutes);
