import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectSubscriptionsService {

  public projectListSubscription: Subscription = null;
  public currentProjectSubscription: Subscription = null;
  public searchProjectsFormSubscription: Subscription = null;

  constructor() { }
}
