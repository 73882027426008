import { FieldType } from '../../enums/field-type';

export function byTypeFieldName() {
  return (fieldValueA: MaintenanceFieldValue, fieldValueB: MaintenanceFieldValue) =>
   fieldValueA.maintenanceTypeFieldName.localeCompare(fieldValueB.maintenanceTypeFieldName);
}

export class MaintenanceFieldValue {
  constructor(
      public maintenanceTypeFieldName: string,
      public maintenanceFieldType: FieldType,
      public value: any,
      public maxLength: number,
      public mandatory: boolean) {
  }
}
