<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="entity-creation" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="entity-creation-title">
        <span>{{
          "modules.disposition.addQualification.createNewQualification" | translate
        }}</span>
      </div>
      <div class="entity-creation-form">
        <form [formGroup]="qualificationAddEditForm" novalidate>
          <div class="form-column" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start">
              <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" class="custom-column">
                <label class="title-label">{{"modules.disposition.addQualification.general" | translate | uppercase
                }}</label>
                <label class="subtitle-label">{{
                  "modules.disposition.addQualification.general" | translate | uppercase
                }}</label>
                <mat-form-field>
                  <mat-label>{{'modules.disposition.addQualification.namePlaceholder' | translate}}</mat-label>
                  <input (input)="nameChanged($event.target.value.trim())" type="text"
                    formControlName="qualificationName" aria-label="Number" matInput [matAutocomplete]="nameAuto"
                    bhRemoveWhitespaces
                    required />
                  <mat-error *ngIf="qualificationAddEditForm.controls['qualificationName'].hasError('nameInvalid')">
                    {{ "modules.disposition.addQualification.nameError" | translate }}
                  </mat-error>
                  <mat-error *ngIf="qualificationAddEditForm.controls['qualificationName'].hasError('nameOnEditInvalid')">
                    {{ "modules.disposition.addQualification.nameOnEditError" | translate }}
                  </mat-error>
                  <mat-error *ngIf="qualificationAddEditForm.controls['qualificationName'].hasError('required')">
                    {{'shared.validation.required'|translate:{ value: ('modules.disposition.addQualification.name'|translate) } }}
                  </mat-error>
                  <mat-autocomplete autoActiveFirstOption (optionSelected)="nameChanged($event.option.value)"
                    #nameAuto="matAutocomplete">
                    <mat-option *ngFor="let option of nameOptions" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" class="custom-column">
                <label class="title-label">{{
                  "modules.disposition.addQualification.repetition" | translate | uppercase
                }}</label>
                <label class="subtitle-label">{{
                  "modules.disposition.addQualification.repeats" | translate | uppercase
                }}</label>
                <mat-form-field>
                  <mat-select (selectionChange)="clearFields()" formControlName="qualificationRetake">
                    <mat-option *ngFor="let option of retakeOptions" [value]="option.value">
                      {{ option.key }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="33" fxLayout="column" fxLayoutAlign="start start" class="custom-column display-none">
                <label #saveButton
                  class="title-label">{{"modules.disposition.addQualification.documents" | translate | uppercase}}</label>
                <label
                  class="subtitle-label">{{"modules.disposition.addQualification.documentsEvidence"| translate| uppercase}}</label>
                <button class="document-button" mat-raised-button>
                  {{ "general.buttons.addDocument" | translate }}
                </button>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start">
              <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" class="custom-column">
                <label class="subtitle-label">
                  {{"modules.disposition.addQualification.description" | translate | uppercase}}
                </label>
                <mat-form-field>
                  <textarea matInput class="custom-textarea" fxFlex="100"
                            [placeholder]="'modules.disposition.addQualification.descriptionPlaceholder' | translate"
                            formControlName="qualificationDescription">
                  </textarea>
                </mat-form-field>
              </div>
              <div fxFlex="50" fxLayout="column" fxLayoutAlign="start start" class="custom-column">
                <div fxLayout="row" class="long-div">
                  <div fxLayout="column" fxLayoutAlign="start start" fxFlex="50">
                    <mat-form-field>
                      <mat-label>{{'modules.disposition.addQualification.acquisitionDate' |translate}}</mat-label>
                      <input (dateChange)="checkAcquisitionAndNextRenewalDates()" matInput #acquisitionDateInput
                        [matDatepicker]="acquisitionDate" [max]="maxEndDate"
                        formControlName="qualificationAcquisitionDate" required />
                      <mat-error
                        *ngIf="qualificationAddEditForm.controls['qualificationAcquisitionDate'].hasError('acquisitionDateInvalid')">
                        {{ "shared.validation.date.invalidDate" | translate }}
                      </mat-error>
                      <mat-error
                        *ngIf="qualificationAddEditForm.controls['qualificationAcquisitionDate'].hasError('isValidDateInstance')">
                        {{ "shared.validation.date.invalidDate" | translate }}
                      </mat-error>
                      <mat-error
                        *ngIf="qualificationAddEditForm.controls['qualificationAcquisitionDate'].hasError('pastOrTodayDateInvalid')">
                        {{'modules.disposition.qualifications.dateEarlierError' |translate}}
                      </mat-error>
                      <mat-error
                        *ngIf="qualificationAddEditForm.controls['qualificationAcquisitionDate'].hasError('required')">
                        {{'shared.validation.required'|translate:{ value: ('modules.disposition.addQualification.acquisitionDate'|translate) } }}
                      </mat-error>
                      <mat-datepicker-toggle matSuffix [for]="acquisitionDate"></mat-datepicker-toggle>
                      <mat-datepicker #acquisitionDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field
                      [matTooltip]="'modules.disposition.addQualification.customerInfoLastRenewal'|translate"
                      [matTooltipDisabled]="!this.currentQualification"
                      *ngIf="qualificationAddEditForm.controls['qualificationRetake']?.value === 'FIXED_DAY_IN_YEAR' ||
                 qualificationAddEditForm.controls['qualificationRetake']?.value === 'INTERVAL'">
                      <mat-label>{{'modules.disposition.addQualification.lastRenewal' |translate}}</mat-label>
                      <input
                        matInput #lastRenewalDateInput
                        [matDatepicker]="lastRenewal"
                        [max]="maxEndDate"
                        formControlName="qualificationLastRenewal" required
                        (dateChange)="checkAcquisitionAndNextRenewalDates()"
                      />
                      <mat-error
                        *ngIf="qualificationAddEditForm.controls['qualificationLastRenewal'].hasError('pastOrTodayDateInvalid')">
                        {{'modules.disposition.qualifications.dateEarlierError' |translate}}
                      </mat-error>
                      <mat-error
                        *ngIf="qualificationAddEditForm.controls['qualificationLastRenewal'].hasError('required')">
                        {{'shared.validation.required'|translate:{ value: ('modules.disposition.addQualification.lastRenewal'|translate) } }}
                      </mat-error>
                      <mat-datepicker-toggle matSuffix [for]="lastRenewal"></mat-datepicker-toggle>
                      <mat-datepicker #lastRenewal></mat-datepicker>
                    </mat-form-field>

                  </div>
                  <div *ngIf="qualificationAddEditForm.controls['qualificationRetake']?.value === 'FIXED_DAY_IN_YEAR' ||
                  qualificationAddEditForm.controls['qualificationRetake']?.value === 'INTERVAL'" fxLayout="column" fxFlex="50"
                    fxLayoutAlign="start start" class="treshold-div">
                    <div fxLayoutAlign="space-between center" class="long-div" fxLayout="row">
                      <div fxFlex="15">
                        <fa-icon class="yellow-icon" [icon]="faSync"></fa-icon>
                      </div>
                      <mat-form-field>
                        <mat-label>{{'modules.disposition.qualifications.mediumThreshold' |translate}}</mat-label>
                        <input (input)="mediumThresholdChanged()" matInput type="number"
                          formControlName="qualificationMediumWarningThreshold" required />
                        <span matSuffix>{{'modules.disposition.addQualification.dayUnit'|translate}}</span>
                        <mat-error
                          *ngIf="qualificationAddEditForm.controls['qualificationMediumWarningThreshold'].hasError('required')">
                          {{'shared.validation.requiredField'|translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayoutAlign="space-between center" class="long-div" fxLayout="row">
                      <div fxFlex="15">
                        <fa-icon class="red-icon" [icon]="faSync"></fa-icon>
                      </div>
                      <mat-form-field>
                        <mat-label>{{'modules.disposition.qualifications.highThreshold' |translate}}</mat-label>
                        <input #hightWarningThreshold matInput type="number"
                          formControlName="qualificationHightWarningThreshold" required />
                        <span matSuffix>{{'modules.disposition.addQualification.dayUnit'|translate}}</span>
                        <mat-error
                          *ngIf="qualificationAddEditForm.controls['qualificationHightWarningThreshold'].hasError('required')">
                          {{'shared.validation.required'|translate:{ value: ('modules.disposition.qualifications.highThreshold'|translate) } }}
                        </mat-error>
                        <mat-error
                          *ngIf="qualificationAddEditForm.controls['qualificationHightWarningThreshold'].hasError('hightThresholdInvalid')">
                          {{'shared.validation.date.invalidDate' |translate}}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div *ngIf="qualificationAddEditForm.controls['qualificationRetake']?.value === 'INTERVAL'"
                  class="long-div">
                  <mat-label>{{"modules.disposition.addQualification.timeInterval" | translate}}</mat-label>
                  <mat-form-field>
                    <mat-label>{{"modules.disposition.addQualification.number" | translate}}</mat-label>
                    <input matInput type="number" formControlName="qualificationRetakeIntervalRegularity" required max="999" min="1"/>
                    <mat-error
                      *ngIf="qualificationAddEditForm.controls['qualificationRetakeIntervalRegularity'].hasError('min')">
                      {{ "modules.disposition.addQualification.minError" | translate }}
                    </mat-error>
                    <mat-error
                      *ngIf="qualificationAddEditForm.controls['qualificationRetakeIntervalRegularity'].hasError('max')">
                      {{ "modules.disposition.addQualification.maxError" | translate }}
                    </mat-error>
                    <mat-error
                      *ngIf="qualificationAddEditForm.controls['qualificationRetakeIntervalRegularity'].hasError('required')">
                      {{'shared.validation.required'|translate:{ value: ('modules.disposition.addQualification.regularity'|translate) } }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{"modules.disposition.addQualification.unit" | translate}}</mat-label>
                    <mat-select formControlName="qualificationRetakeIntervallPeriod">
                      <mat-option *ngFor="let option of periodOptions" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="qualificationAddEditForm.controls['qualificationRetake']?.value === 'FIXED_DAY_IN_YEAR'"
                  class="long-div">
                  <mat-form-field>
                    <mat-label>{{"modules.disposition.addQualification.day" | translate}}</mat-label>
                    <input type="number" min="1" matInput formControlName="qualificationRetakeEachYearDay" required />
                    <mat-error
                      *ngIf="qualificationAddEditForm.controls['qualificationRetakeEachYearDay'].hasError('max') ||qualificationAddEditForm.controls['qualificationRetakeEachYearDay'].hasError('min')">
                      {{ "shared.validation.date.invalidDate" | translate }}
                    </mat-error>
                    <mat-error
                      *ngIf="qualificationAddEditForm.controls['qualificationRetakeEachYearDay'].hasError('required')">
                      {{'shared.validation.required'|translate:{ value: ('modules.disposition.addQualification.day'|translate) } }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{"modules.disposition.addQualification.month" | translate}}</mat-label>
                    <mat-select (selectionChange)="setMonthDayLimitation($event.value)"
                      formControlName="qualificationRetakeEachYearMonth">
                      <mat-option *ngFor="let month of months" [value]="month.value">
                        {{ month.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxLayout="row">
              <div class="actions-div" fxLayout="row">
                <div fxLayout="column" fxFlex="35" fxLayoutAlign="start">
                  <button #cancelButton class="cancel-button" mat-raised-button (click)="closeDialog()">
                    {{ "general.buttons.cancel" | translate }}
                  </button>
                </div>
                <div fxLayout="row" fxFlex="65" fxLayoutAlign="end" class="button-right-div">
                  <button mat-raised-button class="action-button" [disabled]="duplicateButtonDisabled"
                    (click)="duplicate()">
                    {{ "general.buttons.duplicate" | translate }}
                  </button>
                  <button mat-raised-button class="action-button" (click)="resetData()">
                    {{ "general.buttons.resetData" | translate }}
                  </button>
                  <button  mat-raised-button
                           bhTimeoutSaveButton
                           class="action-button"
                           color="primary"
                           [disabled]="!isValid()"
                           onclick="this.blur()"
                           (throttledClick)="saveChanges();
                            $event.preventDefault()">
                    {{ "general.buttons.saveAndClose" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
