import { Utils } from 'app/shared/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {
  constructor(private utils: Utils) {
  }

  public transform(duration: string): string {
    return this.utils.durationISOToString(duration);
  }
}
