<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{'general.labels.confColumnArrangement'|translate}}</h1>
  <div mat-dialog-content>
    <div class="dialog-content" fxLayout="row" fxLayoutAlign="space-between stretch">

      <div class="list-container"
           cdkDropList
           #available="cdkDropList"
           [cdkDropListData]="columnsAvailableWithoutSelected"
           [cdkDropListConnectedTo]="[selected]"
           (cdkDropListDropped)="drop($event)"
           id="available">
        <h4>
          {{'general.available'|translate}}
          <small>{{columnsAvailableWithoutSelected.length}} {{'general.from'|translate}} {{columnsAvailable.length}}</small>
        </h4>
        <div class="list-item-container">
          <div *ngFor="let item of columnsAvailableWithoutSelected" class="column" cdkDrag>{{item.readableName}}</div>
        </div>
      </div>

      <div class="list-container"
           cdkDropList
           #selected="cdkDropList"
           [cdkDropListData]="columnsSelected"
           [cdkDropListConnectedTo]="[available]"
           (cdkDropListDropped)="drop($event)"
           id="selected"
      >
        <h4>
          {{'general.selected'|translate}}
          <small>{{columnsSelected.length}} {{'general.from'|translate}} {{columnLimit}}</small></h4>
        <div class="list-item-container">
          <ng-container *ngFor="let item of columnsSelected">
            <ng-container [ngSwitch]="isColumnTypeSelect(item)">
              <div *ngSwitchCase="true" cdkDrag [cdkDragDisabled]="true"
                   class="column" fxLayout="row" fxLayoutGap="10px">
                {{item.readableName}}
              </div>

              <div *ngSwitchCase="false" cdkDrag [cdkDragDisabled]="columnService.isNonRemovable(item)"
                   class="column" fxLayout="row" fxLayoutGap="10px">
                {{item.readableName}}
                <mat-icon class="item-remove" (click)="remove(item)">remove_circle</mat-icon>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="reset()">{{'general.buttons.default'|translate}}</button>

    <button mat-raised-button mat-button (click)="cancel()">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            [disabled]="!isValid()"
            (throttledClick)="save()">
      {{'general.buttons.save'|translate}}
    </button>
  </div>
</div>
