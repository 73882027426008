import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { TransportComment } from 'app/modules/transportation/contracts/transport/transport-comment.interface';
import { UserStatus } from 'app/shared/enums/user-status.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../../../../../shared/enums/dialogResults.enum';
import { TransportCommentDeleteCommand } from '../../../../../../contracts/transport/transport-comment-delete.command';
import { TransportListDatasource } from '../../../../../../shared/services/transport-list.datasource';
import { TransportView } from '../../../../../../contracts/transport/transport-view.interface';
import { AddEditCommentDialogComponent } from './add-edit-comment-dialog/add-edit-comment-dialog.component';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';


@Component({
  selector: 'bh-transport-comment',
  templateUrl: './transport-comment.component.html',
  styleUrls: ['./transport-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportCommentComponent extends RoleAuthorityGuardsComponent implements OnChanges {
  @Input() comment: TransportComment;
  public isEditable = false;
  public isActiveUser = false;

  constructor(authService: KeycloakService,
              private languageService: LanguageService,
              private transportDataSource: TransportListDatasource,
              private dialog: MatDialog,
  ) {
    super(authService);
  }
  public get currentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public ngOnChanges({ comment }: SimpleChanges): void {
    if (comment) {
      const { userId, status } = (<TransportComment>comment?.currentValue)?.submitterInfo || {};
      this.isActiveUser = status === UserStatus.ACTIVE;
      this.isEditable = this.hasAnyAuthority([Authorities.TRANSPORT_TASK_MANAGE, Authorities.TRANSPORT_TASK_DISPOSITION])
        && userId === this.getUserUserId()
        && this.isActiveUser;
    }
  }

  public get transport(): TransportView {
    return this.transportDataSource.transport();
  }

  public addEditComment(comment: TransportComment | null): void {
    let dialogRef: MatDialogRef<AddEditCommentDialogComponent> = this.dialog.open(AddEditCommentDialogComponent, {restoreFocus: false});
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.comment = comment;
    dialogRef.componentInstance.transport = this.transport;
  }

  public deleteComment(comment: TransportComment): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.commentDelete');
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        let cmd: TransportCommentDeleteCommand = new TransportCommentDeleteCommand();
        cmd.commentId = comment.commentId;
        cmd.transportId = this.transport.transportId;
        this.transportDataSource.deleteComment(cmd);
      }
    });
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
