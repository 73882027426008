<div class="disposition-form-item" [formGroup]="employeeDisposition" fxLayout="row" fxLayoutAlign="start center">

  <div fxFlex="40">
    <mat-icon matTooltip="{{'modules.disposition.addEmployeeDisposition.teamMember'| translate}}" *ngIf="isTeamMember()">
      subdirectory_arrow_right</mat-icon>
    {{ employeeOrTeamName }}
  </div>

  <div fxFlex="30">

    <div class="no-conflicts" *ngIf="noErrorsOrWarnings() && teamStatus !== 'TEAM'">
      <mat-icon matTooltip="{{'modules.disposition.addEmployeeDisposition.noConflicts'| translate}}">done</mat-icon>
    </div>

    <div class="conflict" *ngFor="let conflict of conflicts" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="20px">
      <mat-icon matTooltip="{{'general.conflict.pl'| translate}}">error_outline</mat-icon>
      <div class="project-data">
        <div class="project-name">{{conflict.projectName}}</div>
        <span>{{conflict | formatAssignmentEmployeeProjectDateRange}}</span>
      </div>
    </div>

    <div class="warning" *ngFor="let warning of warnings" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="20px">
      <mat-icon class="warnings-icon" matTooltip="{{'modules.disposition.addEmployeeDisposition.conflictResolved'| translate}}">
        compare_arrows</mat-icon>
      <div class="project-data">
        <div class="project-name">{{warning.projectName}}</div>
        <span>{{warning | formatAssignmentEmployeeProjectDateRange}}</span>
      </div>
    </div>
  </div>

  <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center"
    *ngIf="teamStatus !== 'TEAM'"
    [matTooltip]="'modules.disposition.addEmployeeDisposition.noConflicts'|translate"
    [matTooltipDisabled]="!(noErrorsOrWarnings())">
    <mat-checkbox color="primary" formControlName="overwrite"
      [hidden]="conflicts.length === 0 && warnings.length === 0"
      [disabled]="noErrorsOrWarnings()">
    </mat-checkbox>
  </div>

  <div fxFlex="10" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="removable" [matTooltip]="getRemoveTooltip()" (click)="remove()">
      <mat-icon class="remove-icon">delete</mat-icon>
    </div>
  </div>

</div>
