import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bh-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {message: string}, private dialogRef: MatDialogRef<ErrorMessageComponent>) { }

  close(): void {
    this.dialogRef.close();
  }

}
