import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocuwareDocument } from '../../../contract/docuware-document.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import { BaseAttachmentDocuwareComponent } from '../base/base-attachment-docuware.component';

@Component({
  selector: 'bh-mobile-attachment-docuware',
  templateUrl: './mobile-attachment-docuware.component.html',
  styleUrls: ['./mobile-attachment-docuware.component.scss']
})
export class MobileAttachmentDocuwareComponent extends BaseAttachmentDocuwareComponent {

  public readonly faSortAlphaDown: IconDefinition = faSortAlphaDown;

  @Input() sortParameter: string;
  @Output() changeSortParameter = new EventEmitter<string>();

  public changeSortParameterRequested(sortParam: string) {
    this.sortParameter = sortParam;
    this.changeSortParameter.emit(sortParam);
  }

  public getFileExtension(document: DocuwareDocument) {
    return `.${document.contentType.split('/')[1]}`;
  }
}
