import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { environment } from 'environments/environment';
import _ from 'lodash';

// TODO: OSP UI: Remove directive after migration is done.
// "TrimInputTextDirective" will be used instead

@Directive({
  selector: 'input[bhRemoveWhitespaces], textarea[bhRemoveWhitespaces]',
})
export class RemoveWhitespacesDirective implements OnInit {
  private _formControl: AbstractControl;
  @Input() onlySelfControlChanges = false;
  @Input() trimWithShortTimeout = false;

  constructor(private control: NgControl, private inputElement: ElementRef) {}

  public ngOnInit(): void {
    this._formControl = this.control.control;
    setTimeout(() => this.trimTextFieldValue(), environment.DELAY_SHORTEST);
  }

  @HostListener('blur')
  public onBlur() {
    if (this.trimWithShortTimeout) {
      setTimeout(() => this.trimTextFieldValue(), environment.DELAY_SHORTEST);
    } else {
      this.trimTextFieldValue();
    }
  }

  public trimTextFieldValue(): void {
    const currentControlValue = this._formControl.value;
    if (!_.isNil(currentControlValue)) {
      if (this.isInputTypeEmail) {
        this._formControl.setValue('', { emitEvent: false, onlySelf: true });
        this._formControl.setValue(currentControlValue.trim());
      } else if (this.needTrim(currentControlValue)) {
        this._formControl.setValue(currentControlValue.trim(), { onlySelf: this.onlySelfControlChanges });
      }
    }
  }

  private get isInputTypeEmail(): boolean {
    return (this.inputElement?.nativeElement?.type ?? '').toLowerCase() === 'email'
  }

  private needTrim(value: string): boolean {
    return value !== value.trim();
  }
}
