<div *ngIf="viewNotesAllowed" class="scrollable" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-card class="note-control-panel">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="center">
          <button class="add-button" (click)="addNote()"
                  *ngIf="manageNotesAllowed && !addMode"
                  fxFlex="nogrow">
            {{'modules.disposition.employeeNotes.addNote'|translate}}
          </button>
        </div>
      </mat-card>
      <bh-text-edit class="add-note-text-editor" *ngIf="addMode"
                    [maxLength]="fieldLimit.LONG_DESCRIPTION"
                    [isEditingAllowed]="manageNotesAllowed"
                    [isActive]="true"
                    [isEditMode]="true"
                    [placeholder]="'modules.disposition.employeeNotes.enterNoteHere'|translate"
                    (textSave)="saveNote($event)"
                    (cancelEvent)="hideAddNoteEditor()"
      ></bh-text-edit>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-card class="note-card" *ngFor="let note of employeeManagementStore.notes | async">
        <mat-card-content class="note-card__content">
          <bh-text-edit *ngIf="note"
                        [maxLength]="fieldLimit.LONG_DESCRIPTION"
                        [(text)]="note.text"
                        [textInfo]="getNoteInfo(note)"
                        [isEditingAllowed]="manageNotesAllowed"
                        [isActive]="true"
                        [placeholder]="'modules.disposition.employeeNotes.enterNoteHere'|translate"
                        (textSave)="updateNote(note)"
                        (deleteEvent)="deleteNote(note)"></bh-text-edit>
        </mat-card-content>
      </mat-card>
      <div *ngIf="loading" class="note-spinner" fxLayoutAlign="center center">
        <mat-spinner [strokeWidth]="2" [diameter]="16"></mat-spinner>
      </div>
    </div>
  </div>
</div>
