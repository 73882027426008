export enum PrivilegedAuthorities {
  SUPERADMIN_VIEW = <any>'SUPERADMIN_VIEW',
  SUPERADMIN_CREATE = <any>'SUPERADMIN_CREATE',
  SUPERADMIN_UPDATE = <any>'SUPERADMIN_UPDATE',
  SUPERADMIN_DELETE = <any>'SUPERADMIN_DELETE',
  SUPERADMIN_CHANGE_STATUS = <any>'SUPERADMIN_CHANGE_STATUS',
  PARTNERADMIN_VIEW = <any>'PARTNERADMIN_VIEW',
  PARTNERADMIN_CREATE = <any>'PARTNERADMIN_CREATE',
  PARTNERADMIN_UPDATE = <any>'PARTNERADMIN_UPDATE',
  PARTNERADMIN_DELETE = <any>'PARTNERADMIN_DELETE',
  PARTNERADMIN_CHANGE_STATUS = <any>'PARTNERADMIN_CHANGE_STATUS',
  FLEETADMIN_VIEW = <any>'FLEETADMIN_VIEW',
  FLEETADMIN_CREATE = <any>'FLEETADMIN_CREATE',
  FLEETADMIN_UPDATE = <any>'FLEETADMIN_UPDATE',
  FLEETADMIN_DELETE = <any>'FLEETADMIN_DELETE',
  FLEETADMIN_CHANGE_STATUS = <any>'FLEETADMIN_CHANGE_STATUS',
  PARTNER_VIEW = <any>'PARTNER_VIEW',
  PARTNER_CREATE = <any>'PARTNER_CREATE',
  PARTNER_UPDATE = <any>'PARTNER_UPDATE',
  PARTNER_DELETE = <any>'PARTNER_DELETE',
  PARTNER_TELEMATIC_UNITS_MANAGE = <any>'PARTNER_TELEMATIC_UNITS_MANAGE'
}
