<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
  <div fxFlex="0 0 150px">
    <mat-form-field>
      <mat-select [formControl]="switcherControl">
        <mat-option *ngFor="let unit of options" [value]="unit">
          {{ unit | dimensionSystemDisplay }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
