import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AssetType } from '../contract/asset-type.enum';
import { ScanCodeEntry } from '../contract/scan-code-entry.interface';
import { ScanCodeService } from '../services/scan-code.service';

export class ScanCodeInUseValidator {

  public static isValid(
      scanCode: string,
      scanCodeService: ScanCodeService,
      currentScanCode?: string,
      validateSameAsError = true
  ) {
    const newScanCode = (scanCode || '').trim();
    if (!Boolean(newScanCode)) {
      return of(null);
    }

    if (newScanCode === currentScanCode) {
      return validateSameAsError ? of({same: true}) : of(null);
    }

    return scanCodeService.isScanCodeInUse(newScanCode)
      .pipe(
        map(res => this.evaluateResponse(res)),
        catchError(error => of(null)));
  }

  private static evaluateResponse(scanCodeEntry: ScanCodeEntry): any {
    if (scanCodeEntry.assetType === AssetType.BULK_ITEM) {
      return {alreadyPairedToBulkItem: scanCodeEntry.assetId};
    } else if (scanCodeEntry.assetType === AssetType.PROJECT) {
      return {alreadyPairedToProject: scanCodeEntry.assetId};
    } else if (scanCodeEntry.assetType === AssetType.STOCK) {
      return {alreadyPairedToStock: scanCodeEntry.assetId};
    } else {
      return {alreadyPairedToEquipment: scanCodeEntry.assetId};
    }
  }
}
