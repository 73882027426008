import { LanguageService } from './../../../shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { ReportsDataSource } from '../shared/services/reports.datasource';
import { GuardedNavigableInputComponent } from '../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ViewReportDefinition } from '../contract/view-report-definition.interface';
import { MatDialog } from '@angular/material/dialog';
import { DeleteReportDefinitionCommand } from '../contract/delete-report-definition-command';
import {dialogResults} from '../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../shared/router-history';

@Component({
  selector: 'bh-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent extends GuardedNavigableInputComponent implements OnInit {

  displayedColumns = [
    'reportDefinitionName',
    'reportDefinitionExportFormat',
    'editReportDefinition',
    'deleteReportDefinition'
  ];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              public reportStore: ReportsDataSource,
              private languageService: LanguageService) {

    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
  }

  deleteReportDefinition(reportDefinition: ViewReportDefinition) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage =
      this.languageService.getInstant('modules.reports.reportList.deleteReportTypeMessage');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd: DeleteReportDefinitionCommand = new DeleteReportDefinitionCommand();
        cmd.reportDefinitionId = reportDefinition.reportDefinitionId;

        this.reportStore.deleteReportDefinition(cmd).subscribe(
          () => {},
          () => {}
        )
      }
    });
  }

  navigateToEdit(id: string) {
    this.router.navigate([`/reportdefinitions/edit/${id}`]);
  }

  navigateToAdd() {
    this.router.navigate([`/reportdefinitions/add`]);
  }

}
