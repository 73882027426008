import { Component, Input } from '@angular/core';

@Component({
  selector: 'bh-shortening-filename',
  templateUrl: './shortening-filename.component.html',
  styleUrls: ['./shortening-filename.component.scss']
})
export class ShorteningFilenameComponent {

  private _filename: string;
  private _filenameExtension: string;

  @Input()
  public set filename(filename: string) {
    const index = filename.lastIndexOf('.');

    if (index > 0) {
      this._filename = filename.substr(0, index);
      this._filenameExtension = filename.substr(index);
    } else {
      this._filename = filename;
      this._filenameExtension = '';
    }
  }

  public get filename(): string {
    return this._filename;
  }

  public get filenameExtension(): string {
    return this._filenameExtension;
  }
}
