<div class="equipment-add-wrapper">
  <bh-mobile-wizard [title]="'modules.equipment.equipmentAddEdit.addEquipment'|translate"
                    [wizardOptions]="wizardOptions"
                    (onFinish)="onFinish()"
                    (onCancel)="onCancel()"
                    [nextEnabled]="nextOrFinishEnabled"
                    [backEnabled]="backOrCancelEnabled">

    <bh-mobile-wizard-tab key="overall-data">
      <bh-mobile-equipment-add-overall-data (OnChange)="overallDataValuesChanged($event)">
      </bh-mobile-equipment-add-overall-data>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="general-data">
      <bh-mobile-equipment-add-general-data (OnChange)="generalDataValuesChanged($event)">
      </bh-mobile-equipment-add-general-data>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="technical-data">
      <bh-mobile-equipment-add-technical-data (OnChange)="technicalDataValuesChanged($event)">
      </bh-mobile-equipment-add-technical-data>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="file-upload">
      <bh-mobile-equipment-add-file-upload (OnChange)="uploaderChanged($event)">
      </bh-mobile-equipment-add-file-upload>
    </bh-mobile-wizard-tab>
  </bh-mobile-wizard>
</div>
