import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MaintenanceType } from '../contract/maintenance-type.interface';
import { MaintenanceTypeService } from './maintenance-type.service';
import { Observable } from 'rxjs';

@Injectable()
export class MaintenanceTypeResolve implements Resolve<MaintenanceType> {
  constructor(private maintenanceTypeService: MaintenanceTypeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
   Observable<MaintenanceType> | Promise<MaintenanceType> | MaintenanceType {
    return this.maintenanceTypeService.getById(route.paramMap.get('id'));
  }
}
