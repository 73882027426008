<div class="assign-equipment-organisation-dialog">
  <h2 mat-dialog-title *ngIf="([equipment?.equipmentName, equipment?.equipmentSerialNumber] | join : ' - ') as titleText">
    {{ titleText }}
  </h2>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p>{{'modules.equipment.organisationAssignment.title'|translate}}</p>
      <mat-form-field>
        <mat-label>{{'general.org.s'|translate|uppercase}}</mat-label>
        <mat-select
          [formControl]="organisationName"
          (selectionChange)="selectOrganisation($event.value)"
          disableOptionCentering="true" required>
          <mat-option>
            <ngx-mat-select-search [formControl]="organisationFilter"
              [placeholderLabel]="'general.labels.search'|translate:{ value: ('general.org.s'|translate) }"
              [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: ('general.org.pl'|translate) }">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let organisation of filteredOrganisations | async"
            [value]="organisation">
            {{ organisation.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="organisationName.hasError('alreadyAssignedError')">
          {{'modules.equipment.organisationAssignment.errorOrganisationAssignment' | translate}}
        </mat-error>
        <mat-error *ngIf="organisationName.hasError('required')">
          {{'shared.validation.required'|translate:{value:'general.org.s'|translate} }}
        </mat-error>
      </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button color="accent" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="saveDisabled">{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
