import { ViewRole } from '../../userrole/contract/view-role.interface';

export class RoleRecipient {

  roleId: string;
  name: string;

  public static from(role: ViewRole): RoleRecipient {
    return new RoleRecipient(
      role.roleId,
      role.name
    )
  }

  private constructor(roleId: string, name: string) {
    this.roleId = roleId;
    this.name = name;
  }
}
