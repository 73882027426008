import { HttpContextToken } from '@angular/common/http';


const getDefaultToken = () => new HttpContextToken(() => -1);

export const CONTEXT_TOKEN = {
  ERROR_HANDLER: getDefaultToken(),
}

export enum CONTEXT_TOKEN_VALUE {
  GET_EQUIPMENT = 1,
}
