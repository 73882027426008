import { environment } from 'environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisplayService } from '../../../../../shared/display.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { KeycloakService } from '../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../shared/router-history';
import { ViewEmployee } from '../../../contract/view-employee.interface';
import { EmployeeManagementDatasource } from '../../../shared/employee-management.datasource';
import { BaseEmployeeViewComponent } from './base/base-employee-view.component';
import { EmployeeAddEditComponent } from '../../employee-add-edit/employee-add-edit.component';
import { DeactivateEmployeeCommand } from '../../../contract/deactivate-employee-command';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { EmployeeStatus } from 'app/modules/disposition/contract/employee-status.enum';
import { delay } from 'rxjs/operators';
import { dialogResults } from '../../../../../shared/enums/dialogResults.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteEmployeeCommand } from '../../../contract/delete-employee.command';
import { faCertificate, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { UserAssignDialogComponent } from '../../../shared/user-assign-dialog/user-assign-dialog.component';
import { UnassignEmployeeFromUserCommand } from '../../../contract/unassign-employee-from-user-command';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivateEmployeeCommand } from '../../../contract/activate-employee-command';
import { LanguageService } from '../../../../../shared/services/language.service';

@UntilDestroy()
@Component({
  selector: 'bh-employee-view',
  templateUrl: './employee-view.component.html',
  styleUrls: ['./employee-view.component.scss']
})
export class EmployeeViewComponent extends BaseEmployeeViewComponent implements OnInit, OnDestroy {

  public readonly faCertificate: IconDefinition = faCertificate;

  public employee: ViewEmployee;

  protected componentActive = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public employeeManagementStore: EmployeeManagementDatasource,
              protected dialog: MatDialog,
              protected snackBar: MatSnackBar,
              public displayService: DisplayService,
              protected langService: LanguageService
  ) {
    super(authService, router, route, routerHistory, employeeManagementStore);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }

  public showMoreMenu(): boolean {
    return this.hasAuthority(this.authorities.EMPLOYEE_MANAGE);
  }

  public isActiveEmployeeAndHasAuthority(authority: Authorities, employee?: ViewEmployee): boolean {
    return this.hasAuthority(authority) && employee && employee.employeeStatus === EmployeeStatus.ACTIVE;
  }

  public isInactiveEmployeeAndHasAuthority(authority: Authorities, employee?: ViewEmployee): boolean {
    return this.hasAuthority(authority) && employee && employee.employeeStatus === EmployeeStatus.INACTIVE;
  }

  public openEditDialog() {
    const dialogRef: MatDialogRef<EmployeeAddEditComponent> = this.dialog.open(EmployeeAddEditComponent);
    dialogRef.componentInstance.employee = this.employee;
    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORT)).subscribe(result => {
      if (result !== dialogResults.ABORT) {
        this.employeeManagementStore.updateListing();
        this.employeeManagementStore.updateCurrentEmployee();
      }
    })
  }

  public openUserAssignDialog(employeeId: string): void {
    const dialogRef: MatDialogRef<UserAssignDialogComponent> = this.dialog.open(UserAssignDialogComponent);
    dialogRef.componentInstance.employeeId = this.employee.employeeId;
    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORT)).subscribe(result => {
      if (result === dialogResults.SAVE) {
        this.employeeManagementStore.updateCurrentEmployee();
      }
    })
  }

  public openUserUnassignDialog(employee: ViewEmployee): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.linkDelete');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.linkDelete');
    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORT)).subscribe(result => {
      if (result === dialogResults.YES) {
        this.unassignFromUser(employee);
      }
    })
  }

  private unassignFromUser(employee: ViewEmployee): void {
    const cmd: UnassignEmployeeFromUserCommand = new UnassignEmployeeFromUserCommand(
      employee.employeeId, employee.assignedUserId);
    this.employeeManagementStore.unassignEmployeeFromUser(cmd).pipe(delay(environment.DELAY_SHORT)).subscribe(
      res => {
        this.employeeManagementStore.updateCurrentEmployee();
      }, error => {
        console.log('error unassigning employee from user', error);
      }
    )
  }

  public openDeleteDialog(employeeId: string): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.employeeDelete');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.employeeDelete');
    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORT)).subscribe(result => {
      if (result === dialogResults.YES) {
        const cmd: DeleteEmployeeCommand = { employeeId: employeeId };
        this.employeeManagementStore.deleteEmployee(cmd);
      }
    });
  }

  public employeeDeactivate(employeeId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.employeeDeactivate');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.employeeDeactivate');

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        const command: DeactivateEmployeeCommand = { employeeId: employeeId };
        this.employeeManagementStore.deactivateEmployee(command);
      }
    });
  }

  public employeeActivate(employeeId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.employeeActivate');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.employeeActivate');

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        const command: ActivateEmployeeCommand = { employeeId: employeeId };
        this.employeeManagementStore.activateEmployee(command);
      }
    });
  }

  get isDeletable(): boolean {
    return !this.equipmentsAssigned &&
      !this.projectAssigned &&
      this.employee &&
      !this.employee.assignedUserId &&
      !this.employee.teamId;
  }

  public showDisposition(): boolean {
    // false until there is no story for displaying dispositions in the detail-view of an employee
    return this.hasModule(this.modules.STAFF_MANAGEMENT) && false;
  }

  private translate(key: string): string {
    return this.langService.getInstant(key);
  }

  public showQualificationTab(): boolean {
    return this.hasModule(this.modules.STAFF_MANAGEMENT) &&
      this.authService.hasAuthority(Authorities.EMPLOYEE_VIEW_QUALIFICATIONS);
  }
}
