import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputConfirmDialogConfig } from './input-confirm-dialog-config';

@Component({
  selector: 'bh-input-confirm-dialog',
  templateUrl: './input-confirm-dialog.component.html',
  styleUrls: ['./input-confirm-dialog.component.scss']
})
export class InputConfirmDialogComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() buttonOkText: string;
  @Input() buttonCancelText: string;
  @Input() control: AbstractControl;
  @Input() errorMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InputConfirmDialogConfig) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.placeholder = this.data.placeholder;
      this.buttonOkText = this.data.buttonOkText;
      this.buttonCancelText = this.data.buttonCancelText;
      this.errorMessage = this.data.errorMessage;
      this.control = this.data.control;
    }
  }

}
