import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog.component';
import { LanguageService } from 'app/shared/services/language.service';
import { ViewRole } from '../../../../modules/userrole/contract/view-role.interface';
import { validateCombinations } from '../../../enums/authorities.enum';
import { BrzBlacklistAuthorities } from '../../../enums/brz-blacklist-authorities.enum';
import { ViewUser } from '../../../../modules/userrole/contract/view-user.interface';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ViewCustomer } from '../../../../modules/organisation/contract/view-customer.interface';

@UntilDestroy()
@Component({
  selector: 'bh-confirmation-dialog-delete-user-role',
  templateUrl: './confirmation-dialog-delete-user-role.component.html',
  styleUrls: ['./confirmation-dialog-delete-user-role.component.scss']
})

export class ConfirmationDialogDeleteUserRoleComponent extends ConfirmationDialogComponent implements OnInit, OnDestroy {

  public alternativeRoles: ViewRole[];
  public affectedUsers: ViewUser[];
  public affectedCustomers: ViewCustomer[];
  public confirmTitle = 'modules.userrole.roleList.deleteRole';
  public alternativeRolesMessage = 'modules.userrole.roleList.deleteRoleAvailableAlternativeRoles';
  public confirmMessage = 'modules.userrole.roleList.deleteRoleInUseMessage';
  public secondaryConfirmMessage = 'modules.userrole.roleList.deleteRoleInUseMessageSecondary';

  public brzUsers: ViewUser[] = [];
  public selectedRoles: ViewRole[] = [];
  private checked = false;

  constructor(public dialog: MatDialog, private languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit(): void {
    this.buildBrzUsers();
  }

  ngOnDestroy(): void {
  }

  public disabled(): boolean {
    return this.selectedRoles.length <= 0 || !this.checked || this.collisionCheck() || this.onlyBRZRolesSelected();
  }

  public openDialog(): void {
  }

  public toggleCheckbox(): void {
    this.checked = !this.checked;
  }

  public collisionCheck(): boolean {
    let combinedAuth = [];
    this.selectedRoles.forEach(role =>
      role.authorities.forEach(auth =>
        combinedAuth.push(auth),
      ),
    );
    return ((validateCombinations(combinedAuth)).length > 0);
  }

  public onlyBRZRolesSelected(): boolean {
    if (this.brzUsers.length <= 0) {
      return false;
    }

    return this.selectedRoles.every(this.isBrzRole);
  }

  public isBrzRole(role: ViewRole): boolean {
    return role.authorities.some(authority => Object.keys(BrzBlacklistAuthorities).includes(authority));
  }

  private buildBrzUsers(): void {
    this.affectedCustomers.forEach(customer => {
      if (customer.hasBrzConnector) {
        this.brzUsers = this.brzUsers.concat(
          this.affectedUsers.filter(user => user.customer === customer.customerId));
      }
    });
  }
}
