import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { BasicWidget } from '../basic-widget/basic-widget';
import { EquipmentsDataSource } from '../../../equipment/shared/equipments.datasource';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { DashboardStore } from '../../dashboard/dashboard.store';
import { WidgetConfiguration } from 'app/shared/contract/user-configuration/dashboard-configuration';
import { MapConsts } from 'app/shared/modules/map/map.consts';

interface MapWidgetSimpleChanges extends SimpleChanges {
  widget: SimpleChange;
}

@Component({
  selector: 'bh-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss']
})
export class MapWidgetComponent extends BasicWidget implements OnInit, OnChanges {
  @Input() widget: WidgetConfiguration;
  public mapSettings: MapConfiguration = this.getInitialMapSettings();
  public readonly REFRESH_MAP_RATE_MS = 60000;

  constructor(
    private equipmentDataSource: EquipmentsDataSource,
    private projectDataSource: ProjectDataSource,
    private contactDataSource: ContactDataSource,
    private dashboardStore: DashboardStore
  ) {
    super();
  }

  public ngOnInit(): void {
    this.clearStoredLocations();
  }

  public ngOnChanges(changes: MapWidgetSimpleChanges): void {
    if (changes.widget) {
      this.mapSettings = this.getInitialMapSettings();
    }
  }

  public get showRefreshButton(): boolean {
    return this.equipmentDataSource.canGetEquipmentLocations
  }

  private getInitialMapSettings(): MapConfiguration {
    return {
      ...MapConsts.getDefaultMapConfiguration(),
      showContacts: true,
      showEquipments: true,
      showOrganisations: true,
      showProjects: true,
      showStocks: true,
      ...(this.widget?.mapConfiguration || {}),
    }
  }

  private clearStoredLocations(): void {
    this.equipmentDataSource.clearStoredLocations();
    this.projectDataSource.clearStoredLocations();
    this.contactDataSource.clearStoredLocations();
  }

  public saveMapConfigs(mapConfig: MapConfiguration): void {
    this.widget.mapConfiguration = mapConfig;
    this.dashboardStore.saveWidgetMapConfig(mapConfig, this.index);
  }
}
