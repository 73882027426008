<div class="chips-wrapper">
  <ng-container [ngSwitch]="hasAuthority">
    <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="chipsList"></ng-container>
    <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="chipsGrid"></ng-container>
  </ng-container>
  <div class="errors">
    <div class="error-wrapper">
      <mat-error *ngIf="labelForm.hasError('labelExists')">{{labelForm.getError('labelExists')}}</mat-error>
      <mat-error *ngIf="labelForm.hasError('minLength')">{{labelForm.getError('minLength')}}</mat-error>
      <mat-error *ngIf="labelForm.hasError('maxLength')">{{labelForm.getError('maxLength')}}</mat-error>
    </div>
  </div>
</div>

<ng-template #chipsList>
  <mat-chip-listbox #chipList aria-label="Label selection">
    <mat-chip-option *ngFor="let label of labels" [selectable]="selectable">
      {{label}}
    </mat-chip-option>
  </mat-chip-listbox>
</ng-template>


<ng-template #chipsGrid>
  <mat-form-field>
    <mat-chip-grid #chipList aria-label="Label selection">
      <mat-chip-row *ngFor="let label of labels"
                (removed)="remove(label)"
                [removable]="removable"
                [selectable]="selectable">
        {{label}}
        <mat-icon *ngIf="removable && hasAuthority" matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    </mat-chip-grid>
    <span class="new-label-chip" fxLayoutAlign="space-between center">
      <input #labelInput
             (matChipInputTokenEnd)="add($event)"
             [formControl]="labelForm"
             [matAutocomplete]="auto"
             [matChipInputAddOnBlur]="addOnBlur"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="labelForm.valid ? separatorKeysCodes : []"
             [placeholder]="placeholder|translate"
             [matTooltip]="tooltip|translate"
             (blur)="resetLabelForm()">
      <mat-icon *ngIf="!labelForm.pending; else pendingSpinner" (click)="activateAutocompleteList($event)">label</mat-icon>
      <ng-template #pendingSpinner>
        <mat-spinner class="mr-6" [diameter]="18"></mat-spinner>
      </ng-template>
    </span>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="md-autocomplete" panelWidth="auto">
      <mat-option *ngFor="let label of filteredLabels" [value]="label">
        {{label}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

