import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { AddEquipmentInvoiceCommand } from '../../contract/add-equipment-invoice-command';
import { PerformanceType } from '../../contract/performance-type.enum';
import { UUID } from 'angular2-uuid';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { EquipmentsService } from '../../shared/equipments.service';
import { EquipmentInvoice } from '../../contract/equipment-invoice.interface';
import { AttachedDocument } from '../../../../shared/contract/attached-document.interface';
import { InvoiceNumberTakenValidator } from '../../../../shared/custom-validators/invoice-number-taken.validator';
import { Utils } from '../../../../shared/utils';
import { DatesService } from '../../../../shared/services/dates.service';
import { EnrichedAttachedDocument } from '../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { asyncValidatorFactory } from 'app/shared/custom-validators/async-validator.factory';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-equipment-invoice-add-edit',
  templateUrl: './equipment-invoice-add-edit.component.html',
  styleUrls: ['./equipment-invoice-add-edit.component.scss']
})
export class EquipmentInvoiceAddEditComponent extends GuardedNavigableInputComponent implements OnInit {

  public invoiceAddForm: UntypedFormGroup;
  public equipmentIdParam: string;
  public invoiceId: string;
  public performanceTypes: string[];
  public documents: AttachedDocument[] = [];
  public isEditMode = false;
  private invoiceDetails: EquipmentInvoice;
  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              private equipmentStore: EquipmentsDataSource,
              private equipmentService: EquipmentsService,
              private dialog: MatDialog,
              protected router: Router,
              private activatedRoute: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              private utils: Utils) {
    super(authService, router, activatedRoute, routerHistory);
  }

  public get invoiceDate(): AbstractControl {
    return this.invoiceAddForm.get('invoiceDate');
  }

  public get invoiceAmount(): AbstractControl {
    return this.invoiceAddForm.get('invoiceAmount');
  }

  public get invoiceNumber(): AbstractControl {
    return this.invoiceAddForm.get('invoiceNumber');
  }

  private get performanceDate(): AbstractControl {
    return this.invoiceAddForm.get('performanceDate');
  }

  public ngOnInit(): void {
    this.buildForm();
    this.invoiceId = UUID.UUID();
    this.performanceTypes = Object.keys(PerformanceType).sort();
    this.activatedRoute.params.subscribe(params => {
      this.equipmentIdParam = params['id'];

      if (params['invoiceId']) {
        this.isEditMode = true;
        this.invoiceId = params['invoiceId'];
        this.getInvoiceDetails(this.equipmentIdParam, this.invoiceId);
      } else {
        this.setInvoiceNumberValidator();
      }
    });
  }

  public changePerformanceDate(event: MatDatepickerInputEvent<Date>): void {
    if (!this.performanceDate.value) {
      this.performanceDate.setValue(event.value);
    }
  }

  public navigateBack(): void {
    this.goBack(`/assets/equipment/list/${this.equipmentIdParam}/cost`);
  }

  public save(): void {
    if (this.isValid()) {
      let formValue = this.invoiceAddForm.getRawValue();
      let cmd = new AddEquipmentInvoiceCommand();
      cmd.invoiceId = this.invoiceId;
      cmd.equipmentId = this.equipmentIdParam;
      cmd.documents = this.documents;
      cmd.invoiceNumber = formValue.invoiceNumber && formValue.invoiceNumber.length > 0 ? formValue.invoiceNumber : null;
      cmd.invoiceDate = DatesService.ospDateTimeAtStartOfDay(formValue.invoiceDate);
      cmd.performanceType = formValue.performanceType;
      cmd.invoiceAmount = formValue.invoiceAmount;
      cmd.performanceDate = DatesService.ospDateTimeAtStartOfDay(formValue.performanceDate);
      cmd.documentation = formValue.documentation;
      cmd.performanceDuration = this.utils.parseToDuration(formValue.performanceHours, formValue.performanceMinutes);
      cmd.callOutFee = formValue.callOutFee;
      cmd.drive = this.dimensionUnitConverterPipe.toSystemDimensionUnit(formValue.drive, 'km');
      cmd.allowance = formValue.allowance;
      cmd.personnelCosts = formValue.personnelCosts;
      cmd.travelCosts = formValue.travelCosts;
      cmd.materialCosts = formValue.materialCosts;
      cmd.freightCharges = formValue.freightCharges;
      cmd.serviceContractor = formValue.serviceContractor;
      cmd.serviceWorker = formValue.serviceWorker;
      if (!this.isEditMode) {
        cmd.imported = false;
      }

      this.isEditMode ? this.editInvoice(cmd) : this.addInvoice(cmd);
    }
  }

  public isValid(): boolean {
    return this.invoiceAddForm.valid;
  }

  public onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.documents.push(AttachedDocument.fromData(attachedDocument));
  }

  public resetForm(): void {
    this.invoiceAddForm.reset();
  }

  public removeDocument(document: AttachedDocument): void {
    const index = this.documents.indexOf(document);

    if (index !== -1) {
      this.documents.splice(index, 1);
    }
  }

  private getInvoiceDetails(equipmentId: string, invoiceId: string): void {
    this.equipmentStore.getInvoiceDetails(equipmentId, invoiceId)
    .subscribe(
        res => {
          if (res) {
            this.invoiceDetails = res;
            this.setFormValues();
            this.fillExistingDocuments();
            this.setInvoiceNumberValidator();
          }
        },
        error => {
          console.log('error get invoice details: ', error);
        });
  }

  private addInvoice(cmd: AddEquipmentInvoiceCommand): void {
    this.equipmentStore.addInvoice(cmd).subscribe(
        () => {
          this.goBack();
        }
    );
  }

  private editInvoice(cmd: AddEquipmentInvoiceCommand): void {
    this.equipmentStore.updateInvoice(cmd).subscribe(
        () => {
          this.goBack();
        }
    );
  }

  private buildForm(): void {
    this.invoiceAddForm = this.formBuilder.group({
      invoiceDate: ['', [<any>Validators.required]],
      invoiceNumber: ['', [TrimValidator.hasWhitespaces]],
      performanceDate: [''],
      performanceType: ['', [<any>Validators.required]],
      invoiceAmount: ['', [<any>Validators.required]],
      documentation: [''],
      performanceHours: [''],
      performanceMinutes: [''],
      callOutFee: [''],
      drive: [''],
      allowance: [''],
      personnelCosts: [''],
      travelCosts: [''],
      materialCosts: [''],
      freightCharges: [''],
      serviceContractor: [''],
      serviceWorker: [''],
      documents: []
    });
  }

  private setFormValues(): void {
    this.invoiceAddForm.patchValue({
      invoiceDate: this.invoiceDetails.invoiceDate,
      invoiceNumber: this.invoiceDetails.invoiceNumber,
      performanceDate: this.invoiceDetails.performanceDate,
      performanceType: this.invoiceDetails.performanceType,
      invoiceAmount: this.invoiceDetails.invoiceAmount,
      documentation: this.invoiceDetails.documentation,
      callOutFee: this.invoiceDetails.callOutFee,
      drive: this.dimensionUnitConverterPipe.toUserDimensionUnit(this.invoiceDetails.drive, 'km'),
      allowance: this.invoiceDetails.allowance,
      personnelCosts: this.invoiceDetails.personnelCosts,
      travelCosts: this.invoiceDetails.travelCosts,
      materialCosts: this.invoiceDetails.materialCosts,
      freightCharges: this.invoiceDetails.freightCharges,
      serviceContractor: this.invoiceDetails.serviceContractor,
      serviceWorker: this.invoiceDetails.serviceWorker
    });

    this.patchPerformanceDuration();
  }

  private patchPerformanceDuration(): void {
    const duration = this.utils.parseFromDuration(this.invoiceDetails.performanceDuration);

    if (duration) {
      this.invoiceAddForm.patchValue({
        performanceHours: duration.hours,
        performanceMinutes: duration.minutes
      });
    }
  }

  private fillExistingDocuments(): void {
    this.documents = [];
    if (this.invoiceDetails.documents) {
      this.invoiceDetails.documents.forEach((obj) => {
        // delete obj.url; NEVER USE THIS
        this.documents.push(AttachedDocument.fromData(obj));
      });
    }
  }

  private setInvoiceNumberValidator(): void {
    this.invoiceNumber.setAsyncValidators(
      asyncValidatorFactory(value =>
        InvoiceNumberTakenValidator.isValid(
          this.equipmentService,
          this.equipmentIdParam,
          this.invoiceId
        )(value)
      )
    );
  }
}
