import { MaintenanceDynamicField } from 'app/shared/enums/maintenance-dynamic-field.enum';

export enum MaintenanceHistoryColumnMainFields {
  CREATED = 'created',
  COMPLETED_AT_DATE = 'completedAtDate',
  COMPLETED_AT_MILEAGE = 'completedAtMileage',
  COMPLETED_AT_OPERATING_HOURS = 'completedAtOperatingHours',
  MAINTENANCE_RESULT = 'maintenanceResult',
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export const MaintenanceHistoryColumn = { ...MaintenanceHistoryColumnMainFields, ...MaintenanceDynamicField };
export type MaintenanceHistoryColumnType = MaintenanceHistoryColumnMainFields | MaintenanceDynamicField;
