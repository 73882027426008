import { Address } from '../../organisation/contract/address.interface';
import { RentalContractCalculationBase } from './rental-contract-calculation-base.enum';
import { RentalContractAmountInterval } from './rental-contract-amount-interval.enum';
import { RentalContractType } from './rental-contract-type.enum';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';

export class AddRentalContractCommand {
  equipmentId: string;
  rentalContractId: string;
  rentalContractNumber: string;
  importContractIdentifier: string;
  customerNumber: string;
  customerOrderNumber: string;
  contractPartner: string;
  deliveryAddressCompanyName: string;
  deliveryAddress: Address;
  quantity: number;
  amount: number;
  rentalMileageLimit: number;
  rentalMileageLimitPeriod: string; // Period
  composedOf: string;
  calculationBase: RentalContractCalculationBase;
  amountInterval: RentalContractAmountInterval;
  rentalStartDate: string;
  rentalEndDate: string;
  furtherInformation: string;
  rentalContractTypeDescription: string;
  rentalContractTypeId: string;
  rentalContractType: RentalContractType;
  comment: string;
  documents: AttachedDocument[];
  imported: boolean;
}
