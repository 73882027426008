import { DateRange } from '../../components/date-range-select-component/date-range';
import { AssignmentPeriodType } from '../../enums/assignment-period-type.enum';
import { DatesService } from '../dates.service';
import { DispositionProject } from '../../../modules/disposition/shared/disposition-project';
import { Observable } from 'rxjs';

export abstract class DispositionAssignment<Assignment, AssignmentCommand> {
  public getAssignEquipmentCommand(
    assignment: Assignment,
    project: DispositionProject,
    assignmentDate: Date,
    assignmentPeriodType: AssignmentPeriodType = AssignmentPeriodType.DAY
  ): Observable<AssignmentCommand> {
    const { startDate, endDate } = this.getAssignmentDateRange(assignmentDate, assignmentPeriodType);
    return this.checkForConflicts(assignment, project, startDate, endDate);
  }

  protected abstract checkForConflicts(
    assignment: Assignment,
    project: DispositionProject,
    startDate: Date,
    endDate: Date
  ): Observable<AssignmentCommand>;

  protected hasDateRangeConflict(project: DispositionProject, startDate: Date, endDate: Date): boolean {
    const projectStart = project.projectStart && DatesService.fromIsoStartOfDay(project.projectStart);
    const projectEnd = project.projectEnd && DatesService.fromIsoEndOfDay(project.projectEnd);
    return !(this.isInsideDateRange(startDate, projectStart, projectEnd)
      && this.isInsideDateRange(endDate, projectStart, projectEnd));
  }

  protected isInsideDateRange(date: Date, startDateRange: Date, endDateRange: Date): boolean {
    return !(startDateRange && date < startDateRange) && !(endDateRange && date > endDateRange);
  }

  private getAssignmentDateRange(date: Date, assignmentPeriodType: AssignmentPeriodType): DateRange {
    switch (assignmentPeriodType) {
      case AssignmentPeriodType.MONTH:
        return {
          startDate: new Date(DatesService.sameTimeZoneAtStartMonthDateUTC(date)),
          endDate:  new Date(DatesService.sameTimeZoneAtEndMonthDateUTC(date))
        };
      case AssignmentPeriodType.DAY:
      default:
        return {
          startDate: new Date(DatesService.sameTimeZoneAtStartDateUTC(date)),
          endDate:  new Date(DatesService.sameTimeZoneAtEndDateUTC(date))
        };
    }
  }
}
