import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from 'app/modules/organisation/contract/address.interface';

@Component({
  selector: 'bh-table-cell-address',
  templateUrl: './table-cell-address.component.html',
  styleUrls: ['./table-cell-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellAddressComponent {
  @Input() address: Address;
}
