import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { LimitationPeriod } from './limitation-period.enum';

export class UpdateLeasingContractCommand {
  equipmentId: string;
  leasingContractId: string;
  leasingContractNumber: number;
  contractPartner: string;
  contractStartDate: string;
  contractEndDate: string;
  mileageLimit: number;
  operatingHoursLimit: number;
  limitationPeriod: LimitationPeriod;
  comment: string;
  documents: AttachedDocument[];
  imported: boolean;
}
