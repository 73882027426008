import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalFieldComponent } from './input-component/additional-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BhFormFieldErrorModule } from '../../components/common/bh-form-field-error/bh-form-field-error.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { BhInputModule } from '../../components/bh-input/bh-input.module';
import { OspUiPipesModule } from '../../pipes/osp-ui-pipes.module';
import { AdditionalFieldInlineComponent } from './inline-edit-component/additional-field-inline.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AdditionalFieldComponent,
    AdditionalFieldInlineComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    BhInputModule,
    BhFormFieldErrorModule,
    OspUiPipesModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [
    AdditionalFieldComponent,
    AdditionalFieldInlineComponent,
  ],
})

export class BhAdditionalFieldModule {}
