import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { ManufacturerNameValidator } from '../../../../shared/custom-validators/manufacturer-name.validator';
import { ManufacturerService } from '../manufacturer.service';
import { CreateManufacturerCommand } from '../../contract/create-manufacturer-command';
import { MatDialogRef } from '@angular/material/dialog';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';

@Component({
  selector: 'bh-manufacturer-create-dialog',
  templateUrl: 'manufacturer-create-dialog.component.html',
  styleUrls: ['manufacturer-create-dialog.component.scss']
})
export class ManufacturerCreateDialogComponent implements OnInit {

  public manufacturerForm: UntypedFormGroup;
  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              private manufacturerService: ManufacturerService,
              private dialogRef: MatDialogRef<ManufacturerCreateDialogComponent>) {
  }

  public get manufacturerName(): AbstractControl | null {
    return this.manufacturerForm.get('manufacturerName');
  }

  public get manufacturerAbbreviation(): AbstractControl | null {
    return this.manufacturerForm.get('manufacturerAbbreviation');
  }

  public ngOnInit(): void {
    this.buildForm();
  }

  public save(): void {
    const cmd: CreateManufacturerCommand = new CreateManufacturerCommand();
    cmd.manufacturerAbbreviation =  this.manufacturerAbbreviation.value;
    cmd.manufacturerName = this.manufacturerName.value;
    this.manufacturerService.addManufacturer(cmd).subscribe(res => {
      this.dialogRef.close(res);
    });
  }

  private buildForm(): void {
    this.manufacturerForm = this.formBuilder.group({
      manufacturerName: ['', [
        Validators.required,
        Validators.maxLength(FieldLimit.MEDIUM_IDENTIFIER),
        TrimValidator.hasWhitespaces]],
      manufacturerAbbreviation: ['']
    });

    this.setManufacturerNameValidator();
  }

  private setManufacturerNameValidator() {
    this.manufacturerName.setAsyncValidators(
      asyncValidatorFactory((value) => ManufacturerNameValidator.isValid(value, this.manufacturerService))
    );
  }
}
