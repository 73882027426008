import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaintenanceTaskCompletedSearch } from '../../../shared/maintenance-task-completed-search.interface';

@Component({
  selector: 'bh-maintenance-task-completed-list-item',
  templateUrl: './maintenance-task-completed-list-item.component.html',
  styleUrls: ['./maintenance-task-completed-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskCompletedListItemComponent {
  @Input() public task: MaintenanceTaskCompletedSearch;
  constructor() { }
}
