import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { Observable, of } from 'rxjs';
import { CreateBulkItemCommand } from '../contract/create-bulk-item-command';
import { UpdateBulkItemCommand } from '../contract/update-bulk-item-command';
import { UploadBulkItemDocumentCommand } from '../contract/upload-bulk-item-document-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { getHttpParameters } from '../../../shared/utils';
import { SearchBulkItem } from '../contract/search-bulk-item.interface';
import { ViewBulkItem } from '../contract/view-bulk-item.interface';
import { AddBulkItemLabelCommand } from '../contract/add-bulk-item-label-command';
import { DeleteBulkItemLabelCommand } from '../contract/delete-bulk-item-label-command';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { DeleteBulkItemDocumentCommand } from '../contract/delete-bulk-item-document-command';
import { UpdateBulkItemDocumentDescriptionCommand } from '../contract/update-bulk-item-document-description-command';
import { DeleteBulkItemCommand } from '../contract/delete-bulk-item-command';
import { SetBulkItemDescriptionCommand } from '../contract/set-bulk-item-description-command';
import { ViewProjectAmount } from '../contract/view-project-amount.interface';
import { DispositionBulkItem } from './disposition-bulk-item';
import { UploadService } from '../../../shared/services/upload-service/upload-service';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { AttachedImage } from '../../../shared/contract/attached-image.interface';
import { SetBulkItemThumbnailCommand } from '../contract/set-bulk-item-thumbnail-command';
import { ViewBulkItemAmount } from '../contract/view-bulk-item-amount.interface';
import { BulkItemFilterCollectionView } from 'app/shared/contract/filter/filter-view/bulk-item-filter-collection/bulk-item-filter-collection-view.interface';
import { UpdateBulkItemDocumentNameCommand } from '../contract/update-bulk-item-document-name.command';

@Injectable()
export class BulkItemsService implements UploadService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/bulk-items';
  private filterableLabelUrl = this.url + '/filterable-labels';
  private manufacturerUrl = this.url + '/manufacturers';
  private supplierUrl = this.url + '/suppliers';
  private costTypeUrl = this.url + '/cost-types';
  private costCenterUrl = this.url + '/cost-centers';
  private bglcodeUrl = this.url + '/bgl-codes';
  private categoryUrl = this.url + '/categories';
  public thumbnailPrefixUrl = this.url + '/thumbnail/';
  private amountUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/amounts/bulk-item';
  private projectAmountUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/project-amounts';


  constructor(private http: HttpClient) {
  }

  public getBulkItem(bulkItemId: string): Observable<ViewBulkItem> {
    return this.http.get<ViewBulkItem>(this.url + '/' + bulkItemId);
  }

  public getDispositionBulkItems(): Observable<DispositionBulkItem[]> {
    return this.http.get<DispositionBulkItem[]>(this.url + '/disposition');
  }

  public getByScanCode(code: string): Observable<DispositionBulkItem> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('code', code)
    };

    return this.http.get<DispositionBulkItem>(this.url + '/by-scan-code', httpOptions);
  }

  public getCustomerLabels(params?: Object): Observable<CustomerLabel[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<CustomerLabel[]>(this.filterableLabelUrl, {params: httpParams});
  }

  public saveLabel(command: AddBulkItemLabelCommand): Observable<string> {
    return this.http.put(this.url + '/add-label', JSON.stringify(command), {responseType: 'text'});
  }

  public removeLabel(command: DeleteBulkItemLabelCommand): Observable<string> {
    return this.http.put(this.url + '/delete-label', JSON.stringify(command), {responseType: 'text'});
  }

  public getBGLCodes(): Observable<string[]> {
    return this.http.get<string[]>(this.bglcodeUrl);
  }

  public getSuppliers(): Observable<string[]> {
    return this.http.get<string[]>(this.supplierUrl);
  }

  public getManufacturers(): Observable<string[]> {
    return this.http.get<string[]>(this.manufacturerUrl);
  }

  public getCostTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.costTypeUrl);
  }

  public getCategories(): Observable<string[]> {
    return this.http.get<string[]>(this.categoryUrl);
  }

  public getBulkItemFilters(params: Object): Observable<BulkItemFilterCollectionView> {
    const httpParams = getHttpParameters(params);

    return this.http.get<BulkItemFilterCollectionView>(`${this.url}/filter`, {params: httpParams});
  }

  public getCostCenters(): Observable<string[]> {
    return this.http.get<string[]>(this.costCenterUrl);
  }

  public getBulkItemProjectAmounts(bulkItemId: string): Observable<ViewProjectAmount[]> {
    return this.http.get<ViewProjectAmount[]>(this.projectAmountUrl + '/bulk-item/' + bulkItemId);
  }

  public deleteDocument(command: DeleteBulkItemDocumentCommand): Observable<string> {
    return this.http.post(this.url + '/delete-document', JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentName(command: UpdateBulkItemDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public getDocuments(bulkItemId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(this.url + '/' + bulkItemId + '/documents');
  }

  public addBulkItem(command: CreateBulkItemCommand): Observable<string> {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateBulkItem(command: UpdateBulkItemCommand): Observable<string> {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  public setThumbnail(command: SetBulkItemThumbnailCommand): Observable<string> {
    return this.http.post(this.url + '/set-thumbnail', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteBulkItem(command: DeleteBulkItemCommand): Observable<string> {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public addDocument(document: AttachedDocument, bulkItemId: string): Observable<string> {
    const command: UploadBulkItemDocumentCommand = new UploadBulkItemDocumentCommand(bulkItemId, document);
    return this.http.post(this.url + '/add-document', JSON.stringify(command), {responseType: 'text'});
  }

  public addImage(image: AttachedImage, bulkItemId: string): Observable<string> {
    return this.addDocument({
      fileType: image.mimeType,
      fileName: image.fileName,
      documentKey: image.imageKey,
      fileDescription: ''
    }, bulkItemId);
  }

  public updateDocumentDescription(command: UpdateBulkItemDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public getBulkItemsSearch(params: Object): Observable<PagedResponse<SearchBulkItem>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchBulkItem>>(`${this.url}/search/all`, {params: httpParams});
  }

  public internalNumberInUse(internalNumber: string): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('bulkItemNumber', internalNumber)
    };
    return this.http.get<boolean>(`${this.url}/internal-number-in-use`, httpOptions);
  }

  public getAmounts(bulkItemId: string, allOrganisations?: boolean): Observable<ViewBulkItemAmount[]> {
    let params = new HttpParams();
    if (allOrganisations) {
      params = params.set('allOrganisations', 'true');
    }
    return this.http.get<ViewBulkItemAmount[]>(this.amountUrl + '/' + bulkItemId, {params: params});
  }

  public isDeletable(bulkItemId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/' + bulkItemId  + '/deletable');
  }

  public getUrl(): string {
    return this.url;
  }

  public setDescription(command: SetBulkItemDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/set-bulk-item-description`, JSON.stringify(command), {responseType: 'text'});
  }
}
