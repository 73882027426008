import { LanguageService } from 'app/shared/services/language.service';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { RouterHistory } from '../../../../../../../shared/router-history';
import moment from 'moment';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TelematicsUnitType } from '../../../../../contract/telematics-unit-type.enum';

@Directive()
export abstract class BaseEquipmentViewGeneralComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public equipment: ViewEquipment;
  public guaranteeEndDate: Date;
  protected componentActive = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected equipmentCheckerService: EquipmentCheckerService,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  public allowManualLifecycle(isLiveData: boolean, authority: Authorities): boolean {
    return !isLiveData
        && (this.hasAuthority(this.authorities.EQUIPMENT_ADD_LIFECYCLE_DATA)
        || this.hasAuthority(this.authorities.EQUIPMENT_UPDATE) || this.hasAuthority(authority))
        && this.equipmentCheckerService.isActiveEquipment(this.equipment)
        && !this.equipmentCheckerService.isRentedEquipment(this.equipment)
        && !this.hasTelematicsLiveData();
  }

  public hasTelematicsLiveData(): boolean {
    return this.isAssignedToTeltonikaTelematicUnit()
      && this.equipmentCheckerService.hasEquipmentCurrentTelematicsData(this.equipment);
  }

  private isAssignedToTeltonikaTelematicUnit(): boolean {
    if (this.equipmentCheckerService.isViewEquipmentLinkedToTelematicsUnit(this.equipment)) {
      return this.equipment.assignedTelematicsUnits[0].key === TelematicsUnitType.TELTONIKA_OBD_UNIT
        || this.equipment.assignedTelematicsUnits[0].key === TelematicsUnitType.TELTONIKA_CABLE_UNIT;
    }

    return false;
  }

  public setGuaranteeEndDate(): void {
    if (!(this.equipment && this.equipment.equipmentDeliveryDate && this.equipment.equipmentGuarantee)) {
      return this.guaranteeEndDate = null;
    } else {
      let months = moment.duration(this.equipment.equipmentGuarantee).asMonths();
      let endDate = moment(this.equipment.equipmentDeliveryDate);
      endDate.add(months, 'months');
      endDate.subtract(1, 'day');

      this.guaranteeEndDate = endDate.toDate();
    }
  }

  public transformGuaranteeString(): string {
    if (!(this.equipment && this.equipment.equipmentGuarantee)) {
      return null;
    }
    const months = moment.duration(this.equipment.equipmentGuarantee).asMonths();
    if (months % 12 === 0) {
      let years = months / 12;
      return years === 1
        ? `${years} ${this.translate('general.units.time.year.s')}`
        : `${years} ${this.translate('general.units.time.year.pl')}`;
    } else {
      return months === 1
        ? `${months} ${this.translate('general.units.time.month.s')}`
        : `${months} ${this.translate('general.units.time.month.pl')}`;
    }
  }

  protected translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
