<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="title">{{title}}</h1>
  <div *ngIf="control" mat-dialog-content>
    <mat-form-field>
      <mat-label>{{placeholder}}</mat-label>
      <input matInput
             bhRemoveWhitespaces
             [formControl]="control"
             required>
      <mat-error *ngIf="control.invalid">
        {{errorMessage}}
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close="no">{{buttonCancelText}}</button>
    <button [disabled]="control.invalid" mat-raised-button color="primary" mat-dialog-close="yes">{{buttonOkText}}</button>
  </div>
</div>
