import { FilterValue } from '../filter-value';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { FilterGroup } from '../filter-group';
import { EquipmentSubTypeFilterView, EquipmentTypeFilterView } from '../filter-view/equipment-type-filter-view.interface';
import { FilterType, RenderStrategy } from '../filter-type';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

export class EquipmentTypeFilterViewConverter extends BaseFilterViewConverter<EquipmentTypeFilterView> {
  constructor(
    originalFilters: EquipmentTypeFilterView[],
    selectedValues: FilterParams,
    private translatableStringPipe: TranslatableStringPipe,
  ) {
    super(FilterTypes.EQUIPMENT_CLASS, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterType(filterItems: EquipmentTypeFilterView[]): FilterType {
    return {
      typeName: this.type,
      displayNameType: undefined,
      display: true,
      typeTotal: undefined,
      groups: this.convertToFilterGroups(filterItems),
      renderStrategy: RenderStrategy.GROUPED_TREE
    };
  }

  protected convertToFilterGroups(filterItems: EquipmentTypeFilterView[]): FilterGroup[] {
    return [{
      groupName: '',
      display: true,
      groupTotal: undefined,
      filters: this.convertToFilterValues(filterItems),
    }];
  }

  private convertToFilterValues(filterItems: EquipmentTypeFilterView[]): FilterValue[] {
    return filterItems.reduce((acc, item) => ([
      ...acc,
      this.convertToFilterValue(item),
      ...this.convertToDescendantFilterValues(item)
    ]), []);
  }

  protected convertToFilterValue({ category1, category1Name, total, subTypes }: EquipmentTypeFilterView): FilterValue {
    return {
      displayName: this.translatableStringPipe.transform(category1Name),
      storeName: category1,
      display: true,
      value: this.resolveFilterValueSelection(this.type, category1) || this.isAllDescendantsSelected(subTypes),
      filterTotal: this.formatCount(total),
    };
  }

  private convertToDescendantFilterValues(filterItem: EquipmentTypeFilterView): FilterValue[] {
    return filterItem.subTypes.map(subType => ({
      displayName: this.translatableStringPipe.transform(subType.category2Name),
      storeName: subType.equipmentTypeId,
      display: true,
      value: this.resolveFilterValueSelection(this.type, subType.equipmentTypeId),
      filterTotal: this.formatCount(subType.count),
      parentStoreName: filterItem.category1,
    }));
  }

  private isAllDescendantsSelected(subTypes: EquipmentSubTypeFilterView[]): boolean {
    return (subTypes ?? []).every(({ equipmentTypeId }) => this.resolveFilterValueSelection(this.type, equipmentTypeId));
  }

}
