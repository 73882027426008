import { Component, OnInit } from '@angular/core';
import { PublicApiConnector, PublicApiConnectorService } from './public-api-connector.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleAuthorityGuardsComponent } from '../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../core/keycloak';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LanguageService } from '../../../shared/services/language.service';
import {
  CreatePublicApiConnectorDialogComponent
} from './create-public-api-connector-dialog/create-public-api-connector-dialog.component';
import { delay, finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-public-api-connector',
  templateUrl: 'public-api-connector.component.html',
  styleUrls: ['public-api-connector.component.scss']
})
export class PublicApiConnectorComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public loading = true;
  public connector: PublicApiConnector;

  public readonly displayedColumns = ['clientId', 'createdDate', 'customerId', 'action'];

  constructor(protected authService: KeycloakService,
              private matDialog: MatDialog,
              private languageService: LanguageService,
              private publicApiConnectorService: PublicApiConnectorService) {
    super(authService);
  }

  public ngOnInit(): void {
    this.fetchPublicApiConnector();
  }

  private fetchPublicApiConnector(): void {
    this.connector = null;
    this.publicApiConnectorService.getPublicApiConnector()
      .pipe(untilDestroyed(this))
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: connector => this.connector = connector,
        error: () => this.connector = null
      });
  }

  public createApiKey(): void {
    let dialogRef = this.matDialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.apiKeys.createApiKeyQuestion');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        this.openCreateAPiKeyDialog();
      }
    });
  }

  private openCreateAPiKeyDialog(): void {
    const config: MatDialogConfig = {
      width: '950px',
      disableClose: true,
      autoFocus: false
    };

    this.matDialog.open(CreatePublicApiConnectorDialogComponent, config).afterClosed()
      .pipe(untilDestroyed(this))
      .pipe(delay(environment.DELAY_LONG))
      .subscribe(() => this.fetchPublicApiConnector());
  }

  public deleteApiKey(): void {
    let dialogRef = this.matDialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.apiKeys.deleteApiKey');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        this.publicApiConnectorService.deletePublicApiConnector()
          .pipe(untilDestroyed(this))
          .pipe(delay(environment.DELAY_LONGEST))
          .subscribe(() => this.fetchPublicApiConnector());
      }
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
