<div fxFill fxLayout="column" class="info-window-table-view">
  <h3>{{(items.length > 1 ? 'general.contact.pl' : 'general.contact.s')|translate}}</h3>
  <table fxFill fxLayout="column">
    <tr fxFill fxLayout="row">
      <th class="cell-text" fxFlex="0 0 66px">{{'general.type'|translate}}</th>
      <th class="cell-text padded_column" fxFlex="1 1 0">{{'general.identifier'|translate}}</th>
      <th class="cell-text padded_column" fxFlex="1 1 0">{{'general.name.s'|translate}}</th>
      <th class="cell-text padded_column" fxFlex="1 1 0">
        {{'modules.contact.base.contactPerson'|translate}}
      </th>
      <th class="cell-text padded_column" fxFlex="1 1 0">
        {{'modules.contact.base.phoneNumber'|translate}}
      </th>
      <th class="cell-text padded_column" fxFlex="0 0 66px">{{'general.details'|translate}}</th>
    <tr *ngFor="let contact of items" fxFill fxLayout="row">
      <td class="cell-icon" fxFlex="0 0 66px">
        <fa-icon [icon]="contact.icon"></fa-icon>
      </td>
      <td class="cell-text padded_column" fxFlex="1 1 0">{{contact.identifier}}</td>
      <td class="cell-text padded_column" fxFlex="1 1 0">{{contact.name}}</td>
      <td class="cell-text padded_column" fxFlex="1 1 0">{{contact.contactPerson}}</td>
      <td class="cell-text padded_column" fxFlex="1 1 0">
        <a href="tel:{{contact.phoneNumber}}">{{contact.phoneNumber}}</a>
      </td>
      <td class="padded_column" fxFlex="0 0 66px" fxLayoutAlign="center center">
        <button mat-icon-button class="mat-icon-button-details"
                (click)="navigateToViewDetails(contact.contactId)">
          <mat-icon class="bh-mat-icon">visibility</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>
