import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationType } from '../enums/organisation-type.enum';

@Pipe({
  name: 'organisationTypeDisplay'
})
export class OrganisationTypeDisplayPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(value: any, ...args): any {
    switch (value) {
      case OrganisationType.Department:
      case OrganisationType.Abteilung:
        return this.translate('shared.pipes.organisationType.department');
      case OrganisationType.Area:
      case OrganisationType.Bereich:
        return this.translate('shared.pipes.organisationType.area');
      case OrganisationType.Company:
      case OrganisationType.Firma:
        return this.translate('shared.pipes.organisationType.company');
      case OrganisationType.Location:
      case OrganisationType.Standort:
        return this.translate('shared.pipes.organisationType.location');
      case OrganisationType.Subcompany:
      case OrganisationType.Subfirma:
        return this.translate('shared.pipes.organisationType.subcompany');
      default:
        return '';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
