<div class="stocks" fxFlexFill
     fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="stocks-header">
    <bh-title [text]="'modules.disposition.base.inventory.pl'|translate"></bh-title>
    <button mat-button *ngIf="hasAuthority(authorities.ASSET_FROM_STOCK_TRANSFER)"
            (click)="navigateToStockToStockTransfer()"
            class="stock-to-stock-button"
            [matTooltip]="'modules.disposition.stockInventory.moveInventory'|translate"
            [disabled]="stockToStockButtonDisabled()">
      <fa-icon [icon]="faExchange"></fa-icon>
    </button>
  </div>

  <div class="inventory-search-bar">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>search</mat-icon>
      <mat-form-field flex="90%">
        <mat-label>{{'general.search'|translate}}</mat-label>
        <input matInput [formControl]="searchTerm">
      </mat-form-field>
    </div>
  </div>

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms">

      <mat-tab [label]="'general.equipm.pl'|translate" fxLayout="column">
        <div fxLayout="column" fxFlexFill>
          <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" class="scrollable">
            <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
              <table mat-table [dataSource]="equipmentAmounts | async"
                     *ngIf="(equipmentAmounts | async).length > 0; else noAssignedItems">
                <ng-container matColumnDef="name">
                  <th mat-header-cell class="data-cell" *matHeaderCellDef>{{'general.equipm.s'|translate}}</th>
                  <td mat-cell class="data-cell name-cell" *matCellDef="let assignment" fxLayout="row">
                    <div fxLayout="column" fxLayoutAlign="space-between start" fxFlex="100">
                      <div class="equipment-name" [class.link]="assignment.currentUserHasAccessToEquipment"
                        (click)="assignment.currentUserHasAccessToEquipment && navigateToEquipmentAssignments(assignment.equipmentId)">
                        {{ (assignment.equipmentName && assignment.equipmentName.trim().length > 0)
                        ? assignment.equipmentName : assignment.equipmentModel }}
                        <mat-icon *ngIf="assignment.currentUserHasAccessToEquipment"
                                  [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.equipm.s'|translate }"
                                  (click)="navigateToEquipmentAssignments(assignment.equipmentId)">
                          open_in_new
                        </mat-icon>
                      </div>
                      <div class="model">{{ assignment.equipmentModel ? assignment.equipmentModel :
                          assignment.equipmentName }}</div>
                      <div class="model">{{'modules.disposition.stockInventory.assignedBy'|translate}}: {{assignment.submittedBy}}</div>
                    </div>
                    <div class="comment-icon" fxFlex="5" *ngIf="assignment.comment" fxLayoutAlign="center center">
                      <mat-icon matTooltip="{{ assignment.comment }}">info</mat-icon>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="dispositionDate">
                  <th mat-header-cell class="data-cell" *matHeaderCellDef>{{'general.period'|translate}}</th>
                  <td mat-cell class="data-cell time-cell" *matCellDef="let assignment">
                    <div>
                      {{'modules.disposition.stockInventory.assignedOn'|translate}}:
                      {{ assignment.submitTimestamp | date:'EEEE, MMMM d, y':'':getCurrentLocale() }}
                    </div>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: equipmentColumns;"></tr>
              </table>
            </div>
          </div>

          <div fxFlex="62px" class="inventory-view__body-paginator">
            <mat-paginator #paginator
                           [length]="stockStore.equipmentPaginationInfo.totalElements"
                           [pageIndex]="stockStore.equipmentPaginationInfo.index"
                           [pageSize]="stockStore.equipmentPaginationInfo.size"
                           [pageSizeOptions]="[5, 25, 50, 100]"
                           (page)="onEquipmentPaginateChange($event)">
            </mat-paginator>
          </div>
        </div>

      </mat-tab>

      <mat-tab [label]="'general.bulkItems'|translate">
        <div fxLayout="column" fxFlexFill>
          <bh-bulk-item-stocks fxFlex
                               [amounts]="bulkItemAmounts | async"
                               [stockReadonly]="true"
                               type="stock"
                               (updatedAmount)="updateAmounts()">
          </bh-bulk-item-stocks>

          <div fxFlex="62px" class="inventory-view__body-paginator">
            <mat-paginator #paginator
                           [length]="stockStore.bulkItemPaginationInfo.totalElements"
                           [pageIndex]="stockStore.bulkItemPaginationInfo.index"
                           [pageSize]="stockStore.bulkItemPaginationInfo.size"
                           [pageSizeOptions]="[5, 25, 50, 100]"
                           (page)="onBulkItemPaginateChange($event)">
            </mat-paginator>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
    <ng-template #noAssignedItems>
      <div class="no-assignments">
        {{'modules.disposition.stockInventory.noActiveAssignments'|translate}}
      </div>
    </ng-template>
  </div>
</div>
