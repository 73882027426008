<div fxFlex [fxLayoutGap]="flexOrientation === 'row' ? '15px' : '0'" [fxLayout]="flexOrientation">
  <bh-date-picker-input [fxFlex]="flexOrientation === 'row' ? '1 1 50%' : '1 1 100%'"
                        [control]="startDateControl"
                        [required]="startRequired"
                        [requiredMessageFieldName]="startRequired ? ('general.startDate' | translate) : null"
                        [min]="getMinStartValue()"
                        [max]="getMaxStartValue()"
                        [placeholder]="'general.startDate'|translate"
                        [showErrors]="showErrors"
                        [disabled]="startDisabled()"
                        [errorMatcher]="errorMatcher"
                        [matTooltip]="startDisabled()
                          ? ('shared.dateRange.dateCannotBeAdjusted'|translate:{value: 'general.startDate'|translate})
                          : null">
  </bh-date-picker-input>

  <div id="start-date-disabled-tooltip" *ngIf="startDateDisabledFlagToDisablingReasonMessageTuple[0]"
       fxFlex="1 1 auto"
       fxFlexAlign="center">
    <mat-icon [matTooltip]="startDateDisabledFlagToDisablingReasonMessageTuple[1] | translate">info</mat-icon>
  </div>

  <bh-date-picker-input [fxFlex]="flexOrientation === 'row' ? '1 1 50%' : '1 1 100%'"
                        [control]="endDateControl"
                        [required]="endRequired"
                        [requiredMessageFieldName]="endRequired ? ('general.endDate' | translate) : null"
                        [min]="getMinEndValue()"
                        [max]="getMaxEndValue()"
                        [placeholder]="'general.endDate'|translate"
                        [showErrors]="showErrors"
                        [disabled]="endDisabled()"
                        [errorMatcher]="errorMatcher"
                        [matTooltip]="endDisabled()
                          ? ('shared.dateRange.dateCannotBeAdjusted'|translate:{value: 'general.endDate'|translate})
                          : null">
  </bh-date-picker-input>

  <div id="date-reset" *ngIf="resettable" fxFlex="1 1 auto" fxFlexAlign="center">
    <mat-icon [matTooltip]="'general.buttons.resetDateFields'|translate" (click)="reset()">clear</mat-icon>
  </div>
</div>
