import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AdditionalField,
  AdditionalFieldValue,
} from 'app/modules/osp-ui/rich-components/bh-additional-field/additional-field';
import { AdditionalFieldsEquipmentAssignCommand } from '../../contract/additional-fields-equipment-assign.command';
import { AdditionalFieldsEquipmentUnassignCommand } from '../../contract/additional-fields-equipment-unassign.command';
import { AdditionalFieldsEquipmentUpdateCommand } from '../../contract/update-equipment-additional-fields.command';
import { environment } from '../../../../../environments/environment';
import { getHttpParameters } from '../../../../shared/utils';
import { map } from 'rxjs/operators';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { UpdateEquipmentGeneralFieldCommand } from '../../contract/update-equipment-general-field.command';

@Injectable()
export class EquipmentAdditionalFieldService {

  private equipmentBaseUrl = `${environment.APP_EQUIPMENT_SERVICE_BASE_URL}/api/v1/equipments`;
  private additionalFieldsBaseUrl = `${environment.APP_EQUIPMENT_SERVICE_BASE_URL}/api/v1/additional-fields`;
  private readonly maxPageSize = 1000;

  constructor(private http: HttpClient) {}

  private getFields(page: number, size: number): Observable<AdditionalField[]> {
    const params = getHttpParameters({ page, size });
    return this.http.get<PagedResponse<AdditionalField>>(`${this.additionalFieldsBaseUrl}/additional`, {params})
      .pipe(map(response => response.content));
  }

  public getAllAdditionalFields(): Observable<AdditionalField[]> {
    return this.getFields(0, this.maxPageSize);
  }

  public getAllGeneralFields(): Observable<AdditionalField[]> {
    const params = getHttpParameters({ page: 0, size: this.maxPageSize });
    return this.http.get<PagedResponse<AdditionalField>>(`${this.additionalFieldsBaseUrl}/general`, {params})
      .pipe(map(response => response.content));
  }

  public getAssignedFields(equipmentId: string): Observable<AdditionalFieldValue[]> {
    return this.http.get<AdditionalFieldValue[]>(`${this.equipmentBaseUrl}/${equipmentId}/additional-fields`);
  }

  public assignFields(command: AdditionalFieldsEquipmentAssignCommand): Observable<string> {
    return this.http.post(`${this.equipmentBaseUrl}/assign-additional-fields`, command, {responseType: 'text'});
  }

  public unassignFields(command: AdditionalFieldsEquipmentUnassignCommand): Observable<string> {
    return this.http.post(`${this.equipmentBaseUrl}/unassign-additional-fields`, command, {responseType: 'text'});
  }

  public updateFieldValues(command: AdditionalFieldsEquipmentUpdateCommand): Observable<string> {
    return this.http.post(`${this.equipmentBaseUrl}/update-additional-fields`, command, {responseType: 'text'});
  }

  public updateGeneralFieldValue(command: UpdateEquipmentGeneralFieldCommand): Observable<string> {
    return this.http.post(`${this.equipmentBaseUrl}/update-general-field`, command, {responseType: 'text'});
  }

}
