import { FilterValue } from '../filter-value';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes, FilterTypesDisplayNames } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { FilterType, RenderStrategy } from '../filter-type';
import { EquipmentStateFilterView, EquipmentSubStatusCount } from '../filter-view/equipment-state-filter-view.interface';
import { FilterGroup } from '../filter-group';
import { EquipmentStatusCategoryPipe } from 'app/shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';

export class EquipmentStatusViewConverter extends BaseFilterViewConverter<EquipmentStateFilterView> {
  constructor(
    originalFilters: EquipmentStateFilterView[],
    selectedValues: FilterParams,
    private equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    private equipmentStatusNamePipe: EquipmentStatusNamePipe,
    private filterTypesDisplayName?: FilterTypesDisplayNames
  ) {
    super(FilterTypes.EQUIPMENT_STATE, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterType(filterItems: EquipmentStateFilterView[]): FilterType {
    return {
      typeName: this.type,
      displayNameType: this.filterTypesDisplayName ?? null,
      display: true,
      typeTotal: undefined,
      groups: this.convertToFilterGroups(filterItems),
      renderStrategy: RenderStrategy.GROUPED_TREE,
    };
  }

  protected convertToFilterGroups(filterItems: EquipmentStateFilterView[]): FilterGroup[] {
    return [{
      groupName: '',
      display: true,
      groupTotal: undefined,
      filters: this.convertGroupedStatusesToFilterValues(filterItems)
    }];
  }

  protected convertGroupedStatusesToFilterValues(filterItems: EquipmentStateFilterView[]): FilterValue[] {
    return filterItems.reduce((acc, item) => ([
      ...acc,
      this.convertToFilterValue(item),
      ...this.convertToDescendantFilterValues(item)
    ]), []);
  }

  protected convertToFilterValue({ category, total, statuses }: EquipmentStateFilterView): FilterValue {
    return {
      displayName: this.equipmentStatusCategoryPipe.transform(category),
      storeName: category,
      filterTotal: this.formatCount(total),
      display: true,
      value: this.resolveFilterValueSelection(this.type, category) || this.isAllDescendantsSelected(statuses),
    };
  }

  protected convertToDescendantFilterValues(filterItem: EquipmentStateFilterView): FilterValue[] {
    return filterItem.statuses.map((status, index) => ({
      displayName: this.equipmentStatusNamePipe.transform({ statusName: status.statusName }),
      storeName: status.equipmentStatusId,
      parentStoreName: filterItem.category,
      filterTotal: this.formatCount(status.count),
      display: true,
      value: this.resolveFilterValueSelection(this.type, status.equipmentStatusId),
      order: index
    }));
  }

  private isAllDescendantsSelected(descendants: EquipmentSubStatusCount[]): boolean {
    return (descendants ?? []).every(({ equipmentStatusId }) => this.resolveFilterValueSelection(this.type, equipmentStatusId));
  }
}
