<div fxLayout="column" fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px" [formGroup]="formGroup">
    <bh-date-picker-input
      fxFlex="50"
      [control]="dateControl"
      [min]="getMinDateTime()"
      [max]="getMaxDateTime()"
      [required]="required"
      [placeholder]="datePlaceholder |translate">
    </bh-date-picker-input>

    <mat-form-field fxFlex="50">
      <mat-label>{{timePlaceholder |translate}}</mat-label>
      <input type="time"
             [formControl]="timeControl"
             pattern="[0-9]{2}:[0-9]{2}"
             autocomplete="off"
             matInput>
      <mat-error *ngIf="timeControl.invalid">
        {{errorMessageTime}}
      </mat-error>
    </mat-form-field>
  </div>
</div>


