import { BulkItemType } from './bulk-item-type';
import { TransferItemUnit } from './transfer-item-unit';
import { Address } from '../../organisation/contract/address.interface';
import { StockType } from 'app/shared/enums/stock-type';
import { ViewEquipmentAmount } from './view-equipment-amount.interface';

export function byStockName() {
  return (amountA: ViewBulkItemAmount, amountB: ViewBulkItemAmount) => amountA.stockName.localeCompare(amountB.stockName);
}

export interface ViewBulkItemAmount {
  amountId?: string;
  customerId?: string;
  equipmentId?: string;
  equipmentName?: string;
  equipmentContainerId?: string;
  subEquipmentList?: ViewEquipmentAmount[];
  bulkItemId?: string;
  bulkItemName?: string;
  bulkItemNumber?: string;
  nonReturnable?: boolean;
  bulkItemCategory?: string;
  bulkItemBGLCode?: string;
  bulkItemBALCode?: string;
  bulkItemType?: BulkItemType;
  bulkItemUnit?: TransferItemUnit;
  manufacturerId?: string;
  supplierId?: string;
  bulkItemLabels?: string[];
  stockId?: string;
  stockName?: string;
  stockType?: StockType;
  stockOrganisationId?: string;
  stockOrganisationName?: string;
  stockAddress?: Address;
  amountAvailable?: number;
  amountTotal?: number;
  unknownStock?: boolean;
  thumbnailUrl?: string;
  thumbnailKey?: string;
}

export function instanceOfViewBulkItemAmount(object: any): object is ViewBulkItemAmount {
  return 'amountAvailable' in object && 'bulkItemId' in object && 'stockId' in object;
}
