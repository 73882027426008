<div class="full-size">
  <div class="m-scan-code scanner" *ngIf="!isEnterprise">

    <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between stretch" *ngIf="!isEnterprise">

      <div class="m-scan-code__results pair-success transfer-scan"
           fxLayout="row" fxLayoutAlign="start space-between">
        <button mat-icon-button *ngIf="torchAvailable" (click)="toggleTorch()">
          <mat-icon>{{ (torchEnabled | async) ? 'flash_on' : 'flash_off'}}</mat-icon>
        </button>
        <div class="line-two">
          {{'modules.disposition.transferScanner.numberOfScanned'|translate}}: {{ bulkItems.length }}
        </div>
      </div>

      <div *ngIf="!bulkItemFound && scanResultString"
           class="m-scan-code__results duplicate"
           fxLayout="column" fxLayoutAlign="start stretch">
        <div class="line-one">
          {{'modules.disposition.transferScanner.unknownCode'|translate}}: {{ scanResultString }}
        </div>
      </div>

      <div *ngIf="bulkItemFound && duplicatedEntry"
           class="m-scan-code__results duplicate"
           fxLayout="column" fxLayoutAlign="start stretch">
        <div class="line-one">
          {{ searchResult.bulkItemName }} {{'modules.disposition.transferScanner.hasAlreadyBeenScanned'|translate}}
        </div>
      </div>

      <div *ngIf="bulkItemFound && noAmount"
           class="m-scan-code__results duplicate"
           fxLayout="column" fxLayoutAlign="start stretch">
        <div class="line-one">
          {{ searchResult.bulkItemName }} {{'modules.disposition.transferScanner.isNotAvailableHere'|translate}}
        </div>
      </div>

      <div class="searchingBulkItem" *ngIf="searchingByQrCode">
        <mat-spinner [strokeWidth]="2" [diameter]="16"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="scanner m-scan-code" *ngIf="isEnterprise" fxLayoutAlign="space-between stretch" fxLayout="column">
    <div class="icon_container_zebra" fxLayout="column">
      <mat-icon class="zebra_scanner_icon_add" color="primary">view_week</mat-icon>
      <div class="scan_message_zebra">{{'general.buttons.pressToScan'|translate}}</div>
    </div>

    <div *ngIf="!bulkItemFound && scanResultString"
         class="m-scan-code__results duplicate"
         fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">
        {{'modules.disposition.transferScanner.unknownCode'|translate}}: {{ scanResultString }}
      </div>
    </div>

    <div *ngIf="bulkItemFound && duplicatedEntry"
         class="m-scan-code__results duplicate"
         fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">
        {{ searchResult.bulkItemName }} {{'modules.disposition.transferScanner.hasAlreadyBeenScanned'|translate}}
      </div>
    </div>

    <div *ngIf="bulkItemFound && noAmount"
         class="m-scan-code__results duplicate"
         fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">
        {{ searchResult.bulkItemName }} {{'modules.disposition.transferScanner.isNotAvailableHere'|translate}}
      </div>
    </div>

    <div class="m-scan-code__results pair-success"
         fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-two">
        {{'modules.disposition.transferScanner.numberOfScanned'|translate}}: {{ bulkItems.length }}
      </div>
    </div>

    <div class="searchingBulkItem" *ngIf="searchingByQrCode">
      <mat-spinner [strokeWidth]="2" [diameter]="16"></mat-spinner>
    </div>
  </div>
</div>
