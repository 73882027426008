import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { EquipmentsService } from '../equipments.service';

@Injectable()
export class EquipmentResolver implements Resolve<Observable<ViewEquipment>> {
  constructor(private equipmentService: EquipmentsService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.equipmentService.getEquipment(route.paramMap.get('id'));
  }
}
