import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { ScanCodeEntry } from '../contract/scan-code-entry.interface';
import { OnlineStatusService } from './online-status.service';
import { OfflineDataStore } from './offline-data.store';
import { ScanCodeService } from './scan-code.service';

@Injectable()
export class ScanCodeStore {

  private scanCodeInUseSubject: Subject<ScanCodeEntry> = new Subject();
  private scanCodeInUseErrorSubject: Subject<number> = new Subject();
  public readonly scanCodeInUse: Observable<ScanCodeEntry> = this.scanCodeInUseSubject.asObservable();
  public readonly scanCodeInUseError: Observable<number> = this.scanCodeInUseErrorSubject.asObservable();

  private scanCodesInUse: ScanCodeEntry[];

  public constructor(
    private http: HttpClient,
    private scanCodeService: ScanCodeService,
    private onlineStatusService: OnlineStatusService,
    private offlineDataStore: OfflineDataStore) {
    this.offlineDataStore.offlineScanCodesInUse.subscribe(scanCodeEntries => this.scanCodesInUse = scanCodeEntries);
  }

  public searchScanCodeInUse(scanCode: string): void {
    if (!this.onlineStatusService.onlineStatus) {
      const scanCodeFound = this.scanCodesInUse.find(entry => entry.scanCode === scanCode);
      if (scanCodeFound) {
        this.scanCodeInUseSubject.next(scanCodeFound);
      } else {
        this.scanCodeInUseErrorSubject.next(404);
      }
    } else {
      this.scanCodeService.isScanCodeInUse(scanCode)
        .subscribe(
          res => this.scanCodeInUseSubject.next(res),
          (error: HttpErrorResponse) => {
            this.scanCodeInUseErrorSubject.next(error.status);
          });
    }
  }

}
