import { OnDestroy, OnInit, Directive } from '@angular/core';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { takeWhile } from 'rxjs/operators';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Directive()
export abstract class BaseEquipmentViewDetailComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public equipment: ViewEquipment;
  protected componentActive = true;

  constructor(public equipmentStore: EquipmentsDataSource,
              protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  // TODO: move unused methods to landscape
  ngOnInit(): void {
    this.subscribeToEquipment();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  subscribeToEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(takeWhile(() => this.componentActive))
    .subscribe(res => {
      if (res) {
        this.equipment = res;
      }
    });
  }

}
