import { Directive, HostListener, Input } from '@angular/core';

interface MapOptions {
  mapTypeId: string;
  fullscreenControl: boolean;
  mapTypeControl: boolean;
  mapTypeControlOptions: {
    mapTypeIds: string[]
  };
  streetViewControl: boolean;
  fullscreenControlOptions: any;
}

@Directive({
  selector: 'google-map[bhMapCustomOptions]'
})
export class MapCustomOptionsDirective {
  @Input('initializeMapTypeControl') initializeMapTypeControl = true;
  private map: any;
  private static options: MapOptions = {
    mapTypeControl: true,
    mapTypeId: 'roadmap',
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'hybrid']
    },

    fullscreenControl: true,
    streetViewControl: false,
    fullscreenControlOptions: {}
  };

  @HostListener('mapInitialized', ['$event'])
  onMapReady(map: google.maps.Map): void {
    this.map = map;
    this.map.setOptions(this.calculateOptions());
  }

  @HostListener('maptypeidChanged')
  onMapTypeIdChanged(): void {
    this.keepOptions({ mapTypeId: this.map.getMapTypeId() || google.maps.MapTypeId.ROADMAP });
  }

  private calculateOptions(): Partial<MapOptions> {
    const baseOptions = this.initializeMapTypeControl ? MapCustomOptionsDirective.options
      : { mapTypeControl: false, streetViewControl: false, };

    return {
      ...baseOptions,
      fullscreenControl: true,
      fullscreenControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
    };
  }

  private keepOptions(options: Partial<MapOptions> = {}): void {
    MapCustomOptionsDirective.options = {
      ...MapCustomOptionsDirective.options,
      ...(options || {})
    };
  }

}
