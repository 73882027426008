export enum TelematicsUnitTypeInfo {
  AEMP = 'ISO-15143-3',
  SIGFOX_TRACKERTYPL26_1 = 'Sigfox',
  SIGFOX_TRACKERTYPL26_2 = 'Sigfox Typ 2',
  SIGFOX_TRACKERTYPL26_3 = 'Sigfox Typ 3',
  BOSCH_MYTOOLS_1 = 'Bosh MyTools',
  BOSCH_MYTOOLS_2 = 'Bosh MyTools Typ 2',
  BOSCH_CLOUD_1 = 'Bosh Cloud',
  BOSCH_CLOUD_2 = 'Bosh Cloud Typ 2',
  TRACKUNIT_1 = 'TrackUnit',
  TRACKUNIT_2 = 'TrackUnit AEMPv1',
  RUPTELA_1 = 'Ruptela',
  RUPTELA_2 = 'Ruptela Typ 2',
  UBINAM_1 = 'Ubinam',
  UBINAM_2 = 'Ubinam Typ 2',
  TRUSTED_1 = 'Trusted',
  TRUSTED_2 = 'Trusted Typ 2',
  GENERIC_1 = 'Cumulocity',
  GENERIC_2 = 'AEMPv1',
  GENERIC_3 = 'Goldfunk',
  GENERIC_4 = 'Sinos',
  GENERIC_5 = 'Generic 5',
  GENERIC_6 = 'Generic 6',
  GENERIC_7 = 'Generic 7',
  GENERIC_8 = 'Generic 8',
  GENERIC_9 = 'Generic 9',
  GENERIC_10 = 'Generic 10',
  TELTONIKA_CABLE_UNIT = 'Teltonika Cable',
  TELTONIKA_OBD_UNIT = 'Teltonika OBD',
  GPS_OVER_IP = 'GPSoverIP',
  ELA_PUCK = 'ELA PUCK',
  ELA_COIN = 'ELA COIN',
  CONFIDEX_CLASSIC = 'CONFIDEX_CLASSIC',
  CONFIDEX_ROUGH = 'CONFIDEX_ROUGH',
  CONFIDEX_MICRO = 'CONFIDEX_MICRO',
  RIO = 'RIO',
  DIGITAL_MATTER_OYSTER3_UNIT = 'Digital Matter Oyster3'
}
