<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <!--TODO uncomment once is implemented and discussed-->
      <!--<button mat-button class="search-bar-button-left" matTooltip="Filter" fxFlex="50px">-->
        <!--<mat-icon>filter_list</mat-icon>-->
      <!--</button>-->

      <div class="search-bar-input-container" fxFlex>
        <form class="search-bar-input" fxLayout="row" (ngSubmit)="onSearchFormSubmit()" [formGroup]="searchForm"
             novalidate>
          <input fxFlex matInput formControlName="terms">
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>

      <button mat-button class="search-bar-button-right" [matTooltip]="'general.new'|translate" fxFlex="50px" (click)="navigateToAdd()"
              *ngIf="hasAuthority(authorities.CUSTOMER_CREATE)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div fxFlex="60px" class="list-paginator">
        <mat-paginator #paginator
                      [length]="pagingCustomersDataSource?.length"
                      [pageIndex]="pagingCustomersDataSource?.pageIndex"
                      [pageSize]="pagingCustomersDataSource?.pageSize"
                      [pageSizeOptions]="[5, 25, 50, 100]"
                      (page)="onPaginateChange($event)">
        </mat-paginator>
      </div>

      <div class="data-table-wrapper" fxFlex>
        <mat-table class="pagination-data-table" #table [dataSource]="pagingCustomersDataSource">

          <ng-container cdkColumnDef="customerName">
            <mat-header-cell *cdkHeaderCellDef> {{'general.name.s'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.customerName}}</mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerNumber">
            <mat-header-cell *cdkHeaderCellDef> {{'general.units.number'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.customerNumber}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerStreet">
            <mat-header-cell *cdkHeaderCellDef> {{'general.address.street'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.customerAddress?.street}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerPostalCode">
            <mat-header-cell *cdkHeaderCellDef> {{'general.address.zipcode'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.customerAddress?.postalCode}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerCity">
            <mat-header-cell *cdkHeaderCellDef> {{'general.address.city'|translate}} </mat-header-cell>
            <mat-cell *cdkCellDef="let row"> {{row.customerAddress?.city}} </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerDetails">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row"  [matTooltip]="'modules.organisation.customerList.viewDetails'|translate">
              <button mat-button (click)="viewCustomer(row)">
                <mat-icon>visibility</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerEdit" *ngIf="hasAuthority(authorities.CUSTOMER_UPDATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.base.editCustomer'|translate">
              <button mat-button (click)="navigateToEdit(row.customerId)">
                <mat-icon>create</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerEditAutoNumber" *ngIf="hasAuthority(authorities.CUSTOMER_UPDATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row"
                      [matTooltip]="'modules.organisation.customerList.editAutoNumber'|translate">
              <button mat-button (click)="editAutoNumber(row.customerId)">
                <fa-icon [icon]="faInputNumeric"></fa-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerEditShopId" *ngIf="hasAuthority(authorities.CUSTOMER_UPDATE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row"
                      [matTooltip]="'modules.organisation.customerShopEdit.editShopId'|translate: {value: row.customerName}">
              <button mat-button (click)="editShopId(row.customerId, row.customerName)">
                <mat-icon>shopping_cart</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerEditModules" *ngIf="hasAuthority(authorities.CUSTOMER_UPDATE)
                        && this.hasAnyRole(this.priviledgedRole.Partneradmin, this.priviledgedRole.Superadmin)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.customerList.editModules'|translate">
              <button mat-button (click)="navigateToEditModules(row.customerId)">
                <mat-icon>view_module</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerLanguages" *ngIf="canEditLanguage">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.customerList.changeLanguages'|translate">
              <button mat-button (click)="editLanguages(row.customerId)">
                <fa-icon [icon]="faLanguage"></fa-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerEditRoles" *ngIf="hasAuthority(authorities.CUSTOMER_UPDATE)
                        && this.hasAnyRole(this.priviledgedRole.Partneradmin, this.priviledgedRole.Superadmin)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.base.editRole'|translate">
              <button mat-button (click)="navigateToEditRoles(row.customerId)">
                <mat-icon>group</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerDelete" *ngIf="hasAuthority(authorities.CUSTOMER_DELETE)">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.base.deleteCustomer'|translate">
              <button mat-button (click)="deleteCustomer(row)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerAddUser"
                        *ngIf="hasAnyAuthority([authorities.USER_CREATE])">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" [matTooltip]="'modules.organisation.customerList.createUser'|translate">
              <button mat-button (click)="addUser(row.customerId)">
                <mat-icon>person_add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="customerAddFleetadmin"
                        *ngIf="hasAnyAuthority([authorities.FLEETADMIN_CREATE])">
            <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button [matTooltip]="'modules.organisation.customerList.createFleetAdmin'|translate"
                      (click)="navigateToAdd('add/fleetadmin', {customerId: row.customerId}, 'users')">
                <mat-icon>group_add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
