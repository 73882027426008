import { GuardedNavigableInputComponent } from '../../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../../shared/router-history';
import { map, takeWhile } from 'rxjs/operators';
import { ViewEmployee } from '../../../../../../contract/view-employee.interface';
import { EmployeeManagementDatasource } from '../../../../../../shared/employee-management.datasource';
import { Observable } from 'rxjs';
import { FileUtils } from '../../../../../../../../shared/fileUtils';
import { UserInfoService } from '../../../../../../shared/userInfo.service';
import { EmployeeUser } from '../../../../../../contract/employee-user.interface';

@Directive()
export abstract class BaseEmployeeViewGeneralComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public employee: ViewEmployee;
  public customerLabels: Observable<string[]>;
  public employeeLabels: Observable<string[]>;
  public imageKeys: string[] = [];
  public employeeUser: EmployeeUser;

  protected componentActive = true;

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected routerHistory: RouterHistory,
                        protected employeeManagementStore: EmployeeManagementDatasource,
                        protected userInfoService: UserInfoService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToCurrentEmployee();
    this.customerLabels = this.employeeManagementStore.filteredCustomerLabels;
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployee) => {
      if (res) {
        this.employee = res;
        this.employeeLabels = this.employeeManagementStore.employeeLabels;
        this.getImages(res);
        this.getUser();
      }
    });
  }

  private getImages(employee: ViewEmployee): void {
    this.employeeManagementStore.getDocuments(employee.employeeId)
    .pipe(
        takeWhile(() => this.componentActive),
        map(documentLinks => FileUtils.filterAndSortImages(documentLinks, employee?.defaultImage)),)
    .subscribe(imageLinks => {
      this.imageKeys = (imageLinks || []).map(doc => doc.documentKey);
    });
  }

  private getUser(): void {
    if (this.employee.assignedUserId) {
      this.userInfoService.getEmployeeUser(this.employee.assignedUserId)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(res => {
        this.employeeUser = res;
      })
    }
  }
}
