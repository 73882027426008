import { Point } from './point';
import { degToRad, EARTH_RADIUS } from './geo-constants';
import { PointDistanceCalculation } from './point-distance-calculation.interface';

export class GreatCircleCalculation implements PointDistanceCalculation {

  calculateDistanceBetween(pointA: Point, pointB: Point): number {
    const latRadA = degToRad(pointA.lat);
    const latRadB = degToRad(pointB.lat);
    const latDifference = degToRad(pointB.lat - pointA.lat);
    const lonDifference = degToRad(pointB.lon - pointA.lon);

    const a = Math.sin(latDifference / 2)
      * Math.sin(latDifference / 2)
      + Math.cos(latRadA)
      * Math.cos(latRadB)
      * Math.sin(lonDifference / 2)
      * Math.sin(lonDifference / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return EARTH_RADIUS * c;
  }
}
