import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export enum IconType {
  FA = 'FA',
  FONT_SET = 'FONT_SET',
  MAT = 'MAT'
}

export interface IconPayload {
  type: IconType;
  icon: IconDefinition | string;
}
