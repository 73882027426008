import { Retake } from './retake.enum';
import { FixedDateRetakeInterval } from './fixed-date-retake-interval';
import { PeriodRetakeInterval } from './period-retake-interval';


export class QualificationCreateCommand {
    qualificationId: string;
    employeeId: string;
    customerId: string;
    qualificationName: string;
    qualificationDescription: string;
    qualificationRetakeType: Retake;
    fixedDateRetakeInterval: FixedDateRetakeInterval;
    periodRetakeInterval: PeriodRetakeInterval;
    qualificationAcquisitionDate: string;
    qualificationLastRenewal: string;

    constructor(employeeId: string,
        qualificationId: string,
        customerId: string,
        qualificationName: string,
        qualificationDescription: string,
        qualificationRetakeType: Retake,
        fixedDateRetakeInterval: FixedDateRetakeInterval,
        periodRetakeInterval: PeriodRetakeInterval,
        qualificationAcquisitionDate: string,
        qualificationLastRenewal: string) {
            this.employeeId = employeeId;
            this.qualificationId = qualificationId;
            this.qualificationId = qualificationId;
            this.customerId = customerId;
            this.qualificationName = qualificationName;
            this.qualificationDescription = qualificationDescription;
            this.qualificationRetakeType = qualificationRetakeType;
            this.fixedDateRetakeInterval = fixedDateRetakeInterval;
            this.periodRetakeInterval = periodRetakeInterval;
            this.qualificationAcquisitionDate = qualificationAcquisitionDate;
            this.qualificationLastRenewal = qualificationLastRenewal;
        }
}
