import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LatLonLocation } from './contract/lat-lon-location.interface';
import { ErrorObserver, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import _, { pickBy } from 'lodash';
import { dialogResults } from './enums/dialogResults.enum';
import { BrowserName } from './enums/browser-name.enum';
import { TimeDuration } from './contract/time-duration.interface';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export interface ParsedPeriod {
  value: string;
  period: string;
}

export function joinNonEmpty(separator = ', ', ...values: string[]): string {
  return values.filter(value => !(value === null || value === undefined) && value !== '').join(separator);
}

export function isDefined(object: any): boolean {
  return !(object === null || object === undefined);
}

export function generateUniqueNumber(): string {
  return Date.now().toString().slice(-8);
}

// If the result of a subscription doesn't matter and you only want to
// log errors to the console. usage: something.subscribe(logErrors('optional message'));
export function logErrors(msg?: string): ErrorObserver<any> {
  if (typeof msg === 'string') {
    return {error: (error: any) => console.log(`${msg} failed:`, error)};
  }
  return {error: (error: any) => console.log(error)};
}

export function ifDialogYes(source: Observable<string>): Observable<string> {
  return source.pipe(filter(dialogResult => dialogResult === dialogResults.YES));
}

export function caseInsensitiveCompare(s1: string, s2: string): number {
  return s1.localeCompare(s2, undefined, {sensitivity: 'base'});
}

function removeEmpty<T>(array: any[] | T): any[] | T {
  if (Array.isArray(array)) {
    array = array.filter(entry => isDefined(entry));
  }
  return array;
}

function paramIsDefined(param: any): boolean {
  if (Array.isArray(param)) {
    return param.length > 0;
  }
  return param !== undefined && param !== null && param !== '';
}

function convertToHttpParameter<T>(object: T): HttpParameter<T> {
  if (!object) {
    return {} as HttpParameter<T>;
  }

  Object.keys(object).forEach(key => {
    object[key] = removeEmpty(object[key]);
  });
  const cleanParams = pickBy<T>(<any>object, paramIsDefined);
  // any necessary to typecast.
  return <any>cleanParams as HttpParameter<T>;
}

export type HttpParameter<T> = { [P in keyof T]: string | string[] };

export function getHttpParameters<T>(params: T): HttpParams {
  const httpGetParameter: HttpParameter<T> = convertToHttpParameter(params);
  return new HttpParams({fromObject: httpGetParameter});
}

export function getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase()
  switch (true) {
    case agent.indexOf('edge') > -1:
      return BrowserName.EDGE;
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return BrowserName.OPERA;
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return BrowserName.CHROME;
    case agent.indexOf('trident') > -1:
      return BrowserName.TRIDENT;
    case agent.indexOf('firefox') > -1:
      return BrowserName.FIREFOX;
    case agent.indexOf('safari') > -1:
      return BrowserName.SAFARI;
    default:
      return BrowserName.OTHER;
  }
}

@Injectable()
export class Utils {

  private durationHoursMinsRegex = /PT((\d+)H)?(\d+)?M?/;
  public static readonly durationISORegex =
    /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;

  public static isValidLocation(value: LatLonLocation): boolean {
    return Boolean(
      value?.location &&
      Number.isFinite(value.location?.lat) && Math.abs(value.location.lat) <= 90 &&
      Number.isFinite(value.location?.lon) && Math.abs(value.location.lon) <= 180
    );
  }

  static isNotBlank(text: string): boolean {
    return text !== null && text.match(/^ *$/) === null;
  }

  public static isNotEmpty(value: any): boolean {
    return !_.isNil(value) && !_.isNaN(value) && String(value).match(/^ *$/) === null;
  }

  public static isValidNumber(value: number): boolean {
    return _.isNumber(value) && !_.isNaN(value) && !_.isNil(value);
  }

  public static uniqueFilter<T>(value: T, index: number, array: T[]): boolean {
    return array.indexOf(value) === index;
  }

  public static round(value: number, digits: number): number {
    return parseFloat((+value).toFixed(digits));
  }

  public parseToPeriod(value: number, period: string): string {
    return `P${value}${period}`;
  }

  public parseFromPeriod(periodValue: string): ParsedPeriod {
    if (periodValue) {
      return {
        value: periodValue.substr(1).slice(0, -1),
        period: periodValue.slice(-1)
      };
    }

    return {
      value: '',
      period: ''
    };
  }

  public parseToDuration(hours: number, minutes: number): string {
    return 'PT' + (hours ? hours : '0') + 'H' + (minutes ? minutes : '0') + 'M';
  }

  public parseFromDuration(duration: string): TimeDuration {
    if (this.durationHoursMinsRegex.test(duration)) {
      const hours = Math.floor(moment.duration(duration).asHours());
      const minutes = moment.duration(duration).get('m');
      return {
        hours: hours,
        minutes: minutes
      };
    } else {
      return null;
    }
  }

  public parseFromDurationToString(duration: string): string {
    let timeDuration = this.parseFromDuration(duration);

    if (!timeDuration) {
      return null;
    }

    const hours = timeDuration.hours;
    const minutes = timeDuration.minutes;
    return (hours ?? 0) + 'h ' + (minutes ?? 0) + 'm';
  }

  public durationISOToString(duration: string): string {
    return this.durationHoursMinsRegex.test(duration)
      ? this.durationToString(moment.duration(duration))
      : null;
  }

  public durationBetweenDatesToString(start: Date | string, end: Date | string): string {
    const duration = moment.duration(Utils.differenceMs(start, end));
    return this.durationToString(duration);
  }

  private durationToString(duration: moment.Duration): string {
    if (!duration) {
      return null;
    }
    const hours = Math.floor(duration.asHours());
    const minutes = duration.get('m');
    return hours + 'h ' + minutes + 'm';
  }

  public static isISODuration(duration: string): boolean {
    return Utils.durationISORegex.test(duration);
  }

  public static concatTitleText(parts: string[], character = ' '): string {
    return parts
      .map(value => (value || '').trim())
      .filter(Boolean)
      .join(character);
  }

  public static differenceMs(start: Date | string, end: Date | string): number {
    return start && end ? moment(new Date(end)).diff(moment(new Date(start))) : null;
  }

  public static removeError(control: AbstractControl, errorType: string, opts?: any): void {
    const errors = _.omit(control.errors, errorType);
    control.setErrors(_.isEmpty(errors) ? null : errors, opts || { emitEvent: true });
  }

  public static addError(control: AbstractControl, error: ValidationErrors, opts?: any): void {
    control.setErrors({ ...(control.errors ?? {}), ...error }, opts || { emitEvent: true });
  }

  public static isFunction(value: any): boolean {
    return Boolean(value
      && (Object.prototype.toString.call(value) === '[object Function]'
      || typeof value === 'function'
      || value instanceof Function));
  }
}
