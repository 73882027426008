import { LatLon } from './lat-lon.interface';
import { GreatCircleCalculation } from './great-circle-calculation';
import { PointDistanceCalculation } from './point-distance-calculation.interface';

const pointDistanceCalculation: PointDistanceCalculation = new GreatCircleCalculation();

export class Point {

  static fromLatLon(location: LatLon): Point {
    return new Point(location.lat, location.lon);
  }

  static fromCoordinates(coordinates: GeolocationCoordinates): Point {
    return new Point(coordinates.latitude, coordinates.longitude);
  }

  constructor(public lat: number, public lon: number) {}

  distanceTo(otherPoint: Point): number {
    return pointDistanceCalculation.calculateDistanceBetween(this, otherPoint);
  }
}
