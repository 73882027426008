import { TelematicUnitTypeEnum } from '../../telematic-unit-type.enum';

export class CreateCustomerTelematicUnitCommand {
  constructor(
    public telematicsUnitType: TelematicUnitTypeEnum,
    public imei: string,
    public boxNo: string,
    public serialNumber: string,
    public mac: string,
    public partnerId: string,
    public simCardProvider: string,
    public eSim1IccId: string,
    public eSim1Imsi: string,
    public gsmNumber: string
  ) {}
}
