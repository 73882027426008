import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';

import { ViewTelematicUnit } from '../view-telematic-unit.interface';
import { TelematicsService } from './../telematics.service';

enum SortDirectionType {
  ASC = 'asc',
  DESC = 'desc'
};

@Injectable()
export class TelematicUnitStore extends DataSource<any> {

  private defaultPaginationParams = {
    page: 0,
    size: 200
  };
  private telematicUnitsSubject = new BehaviorSubject<ViewTelematicUnit[]>([]);
  private telematicUnitsSource = this.telematicUnitsSubject.asObservable();
  private _searchTerm: string;
  private _sortDirectionLastChangeDate: SortDirection = SortDirectionType.ASC;
  private updateStoreEvent = new Subject<any>();

  constructor(private telematicsService: TelematicsService) {
    super();
    this.onUpdateStore();
    this.updateStoreEvent.next(null);
  }

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public get sortDirectionLastChangeDate(): SortDirection {
    return this._sortDirectionLastChangeDate;
  }

  public connect(): Observable<ViewTelematicUnit[]> {
    return this.telematicUnitsSource;
  }

  public disconnect(): void {
  }

  public updateStore(): void {
    this.updateStoreEvent.next(null);
  }

  public search(searchTerm: string): void {
    this._searchTerm = searchTerm;
    this.updateStoreEvent.next(null);
  }

  public sortByLastChangeDate(sortDirection: SortDirection): void {
    this._sortDirectionLastChangeDate = sortDirection;
    this.updateStoreEvent.next(null);
  }

  private onUpdateStore(): void {
    this.updateStoreEvent
      .pipe(
        switchMap(() => this.telematicsService.getTelematicUnits(this.defaultPaginationParams)),
        pluck('content'),
        map(res => this.telematicsUnitsConvertDates(res)),
        map(res => this.filterBySearchTermOperation(res)),
        map(res => this.sortByLastChangeDateOperation(res))
      )
      .subscribe(res => this.telematicUnitsSubject.next(res));
  }

  private telematicsUnitsConvertDates(units: ViewTelematicUnit[]): ViewTelematicUnit[] {
    return units.map(unit => ({ ...unit, lastChangeDate: unit.lastChangeDate ? new Date(unit.lastChangeDate) : null }));
  }

  private filterBySearchTermOperation(units: ViewTelematicUnit[]): ViewTelematicUnit[] {
    return this._searchTerm ? units.filter(unit => this.hasSearchTerm(unit, this._searchTerm)) : units;
  }

  private hasSearchTerm({ telematicsUnitId, assignedEquipments, telematicsUnitType }: ViewTelematicUnit, searchTerm: string): boolean {
    return (telematicsUnitId && telematicsUnitId.indexOf(searchTerm) > -1)
      || (telematicsUnitType && telematicsUnitType.indexOf(searchTerm) > -1)
      || (assignedEquipments && assignedEquipments.some(
        ({ name, equipmentSerialNumber, customerName, equipmentCustomerSerialNumber }) => {
          return (name && name.indexOf(searchTerm) > -1)
            || (equipmentSerialNumber && equipmentSerialNumber.indexOf(searchTerm) > -1)
            || (customerName && customerName.indexOf(searchTerm) > -1)
            || (equipmentCustomerSerialNumber && equipmentCustomerSerialNumber.indexOf(searchTerm) > -1)
        }));
  }

  private sortByLastChangeDateOperation(units: ViewTelematicUnit[]): ViewTelematicUnit[] {
    return units.sort(({ lastChangeDate: dateFirst }, { lastChangeDate: dateSecond }) => {
      dateFirst = dateFirst || new Date(0);
      dateSecond = dateSecond || new Date(0);
      switch (this._sortDirectionLastChangeDate) {
        case SortDirectionType.ASC:
          return dateFirst.getTime() - dateSecond.getTime();
        case SortDirectionType.DESC:
        default:
          return dateSecond.getTime() - dateFirst.getTime();
      }
    })
  }
}
