import { LanguageService } from 'app/shared/services/language.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { Utils } from '../../../../../../../shared/utils';
import { LifeCycleIncident } from '../../../../../contract/life-cycle-incident';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentAttachmentDetailsComponent } from '../../../../../shared/equipment-attachment-details/equipment-attachment-details.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { RouterHistory } from '../../../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentViewIncidentComponent extends EquipmentAttachmentDetailsComponent implements OnInit, OnDestroy {

  public incident: LifeCycleIncident;
  public equipmentId: string;
  public lifeCycleId: string;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected utils: Utils,
              protected equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, dialog, routerHistory, languageService, fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }

  protected getLifeCycleDetails(): void {
    this.equipmentStore.getLifeCycleDetails(this.equipmentId, this.lifeCycleId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.incident = res as LifeCycleIncident;
          this.lcDocumentsDataSource.data = this.incident.documents;
        }
      },
      error => {
        console.log('error get lifecycle details: ', error);
      });
  }

}
