import { FilterParams } from '../filter-params.class';
import { FilterTypes } from '../filter-types.enum';
import { FilterValue } from '../filter-value';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { MaintenanceResultFilterView } from '../filter-view/maintenance-result-filter-view.interface';
import { MaintenanceResultPipe } from '../../../pipes/maintenance-result.pipe';

export class MaintenanceResultFilterViewConverter extends BaseFilterViewConverter<MaintenanceResultFilterView> {
  constructor(
    originalFilters: MaintenanceResultFilterView[],
    selectedValues: FilterParams,
    private maintenanceResultPipe: MaintenanceResultPipe,
  ) {
    super(FilterTypes.MAINTENANCE_RESULT, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: MaintenanceResultFilterView): FilterValue {
    return {
      displayName: this.maintenanceResultPipe.transform(filterItem.maintenanceResult),
      storeName: filterItem.maintenanceResult,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.maintenanceResult),
      filterTotal: this.formatCount(filterItem.count),
    };
  }
}
