import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'bh-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {

  @Input()
  control: FormControl;

  @Input()
  placeholder = '';

  @Input()
  label = '';

  @Input()
  errorStateMatcher: ErrorStateMatcher;

  @Input()
  type: string;

  @Input()
  autoCompleteOptions: string[];

  @Input()
  icon: IconDefinition;

  @Input()
  customValidationErrors: Map<string, string>;

  maxLength = 524288; // 524288 is default for maxLength, == ca. 512kB


  constructor() {
  }

  ngOnInit(): void {
    this.setMaxLengthBasedOnValidatorConfiguration();
  }

  private setMaxLengthBasedOnValidatorConfiguration() {
    // workaround because it is actually not possible to access requiredLength of validators otherwise
    const validatorFn = this.control?.validator;
    if (!validatorFn) {
      return;
    }
    const dummyErrors = validatorFn(new FormControl({ length: Infinity }));
    const allowedLength = dummyErrors?.maxlength?.requiredLength;
    if (!allowedLength) {
      return;
    }
    // allow one more char so that the UI message concerning the max amount of chars is shown
    this.maxLength = allowedLength + 1;
  }

  getTranslationKeyOfFirstFoundCustomValidationError(): string {
    if (!this.customValidationErrors || !this.control?.errors) {
      return;
    }
    const foundErrorKey = Object.keys(this.control?.errors)?.find(error => this.customValidationErrors?.has(error));
    return foundErrorKey ? this.customValidationErrors.get(foundErrorKey) : undefined;
  }
}
