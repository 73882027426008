<div #timeline class="dhx_cal_container" (window:resize)="renderEvents()">

  <div class="dhx_cal_navline">
    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
      <div class="dhx_cal_prev_button" [style.visibility]="!hasPrevious ? 'hidden': null"></div>
      <div class="header-information">{{ headerInformation }}</div>
      <div class="dhx_cal_next_button" [style.visibility]="!hasNext ? 'hidden': null"></div>
      <div class="dhx_cal_date"></div>
    </div>
  </div>

  <div class="dhx_cal_header"></div>
  <div class="dhx_cal_data"></div>

</div>
