import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapLocationMarker } from '../../interfaces/map-location-marker.interface';

@Component({
  selector: 'bh-map-marker-labels',
  templateUrl: './map-marker-labels.component.html'
})
export class MapMarkerLabelsComponent {

  @Input() markers: MapLocationMarker[] = [];
  @Output() clicked = new EventEmitter<MapLocationMarker>();

  constructor() { }

  public trackByMarker(index: number, marker: MapLocationMarker): string {
    return (marker?.id && marker?.label) ? `${marker.id}_${marker.label}` : null;
  }
}
