import { AddEditEquipmentContractComponent } from './add-edit-equipment-contract/add-edit-equipment-contract.component';
import { LanguageService } from './../../../../../../shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentRentalContract } from '../../../../contract/equipment-rental-contract.interface';
import { EquipmentSalesContract } from '../../../../contract/equipment-sales-contract.interface';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { Subscription } from 'rxjs';
import { EquipmentServiceContract } from '../../../../contract/equipment-service-contract.interface';
import { EquipmentInsuranceContract } from '../../../../contract/equipment-insurance-contract.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ConfirmationDialogComponent
} from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteRentalContractCommand } from '../../../../contract/delete-rental-contract-command';
import { MatDialog } from '@angular/material/dialog';
import { DeleteSalesContractCommand } from '../../../../contract/delete-sales-contract-command';
import { DeleteServiceContractCommand } from '../../../../contract/delete-service-contract-command';
import { DeleteInsuranceContractCommand } from '../../../../contract/delete-insurance-contract-command';
import { RentalContractType } from '../../../../contract/rental-contract-type.enum';
import { ParsedPeriod, Utils } from '../../../../../../shared/utils';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { dialogResults } from '../../../../../../shared/enums/dialogResults.enum';
import { EquipmentLeasingContract } from 'app/modules/equipment/contract/equipment-leasing-contract.interface';
import { LimitationPeriod } from 'app/modules/equipment/contract/limitation-period.enum';
import {
  DeleteEquipmentLeasingContractCommand
} from 'app/modules/equipment/contract/delete-equipment-leasing-contract-command';
import { EquipmentContractType } from 'app/modules/equipment/contract/equipment-contract-type.enum';
import {
  faKey,
  faKeySkeleton,
  faPencil,
  faShieldCheck,
  faSuitcase,
  faTrash,
  faWallet,
  faWrench,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { KeycloakService } from 'app/core/keycloak';
import { Modules } from 'app/shared/enums/modules.enum';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-contracts',
  templateUrl: './equipment-view-contracts.component.html',
  styleUrls: ['./equipment-view-contracts.component.scss']
})
export class EquipmentViewContractsComponent implements OnInit, OnDestroy {
  private equipmentId: string;
  private currentEquipmentSub: Subscription;
  private contractsSub: Subscription;
  private equipment: ViewEquipment;
  private deleteConfirmMessage = this.translate('modules.equipment.confirmation.message.deleteContract');
  public equipmentRentalContracts: EquipmentRentalContract[];
  public equipmentSalesContract: EquipmentSalesContract;
  public equipmentServiceContracts: EquipmentServiceContract[];
  public equipmentInsuranceContracts: EquipmentInsuranceContract[];
  public equipmentLeasingContracts: EquipmentLeasingContract[];

  public contractTypes = EquipmentContractType;
  public rentalContractTypes = RentalContractType;
  public limitationPeriods = LimitationPeriod;

  public readonly faKey: IconDefinition = faKey;
  public readonly faPencil: IconDefinition = faPencil;
  public readonly faTrash: IconDefinition = faTrash;
  public readonly faWallet: IconDefinition = faWallet;
  public readonly faWrench: IconDefinition = faWrench;
  public readonly faShieldCheck: IconDefinition = faShieldCheck;
  public readonly faSuitcase: IconDefinition = faSuitcase;
  public readonly faKeySkeleton: IconDefinition = faKeySkeleton;

  constructor(protected equipmentsStore: EquipmentsDataSource,
              private dialog: MatDialog,
              private utils: Utils,
              private equipmentCheckerService: EquipmentCheckerService,
              private languageService: LanguageService,
              private authService: KeycloakService,
              private matomoTracker: MatomoTracker) {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_CONTRACT);
  }

  public ngOnInit(): void {
    this.currentEquipmentSub = this.equipmentsStore.currentEquipment.subscribe(res => {
      if (res) {
        this.equipment = res;
        this.equipmentId = res.equipmentId;
        this.getContracts();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.currentEquipmentSub) {
      this.currentEquipmentSub.unsubscribe();
    }

    if (this.contractsSub) {
      this.contractsSub.unsubscribe();
    }
  }

  public isActiveEquipment(text?: any): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public addContract(): void {
    const dialogRef = this.dialog.open(AddEditEquipmentContractComponent);
    dialogRef.componentInstance.equipment = this.equipment;
  }

  public editContract(contractId: string, contractType: EquipmentContractType): void {
    const dialogRef = this.dialog.open(AddEditEquipmentContractComponent);
    dialogRef.componentInstance.equipment = this.equipment;
    dialogRef.componentInstance.updatedContractId = contractId;
    dialogRef.componentInstance.updatedContractType = contractType;
  }

  public hasContracts(): boolean {
    return !!(this.equipmentSalesContract
        || (this.equipmentRentalContracts && this.equipmentRentalContracts.length)
        || (this.equipmentServiceContracts && this.equipmentServiceContracts.length)
        || (this.equipmentInsuranceContracts && this.equipmentInsuranceContracts.length)
        || (this.equipmentLeasingContracts && this.equipmentLeasingContracts.length));
  }

  public deleteRentalContract(event: MouseEvent, rentalContract: EquipmentRentalContract): void {
    event.stopPropagation();
    if (rentalContract) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.deleteConfirmMessage;
      dialogRef.afterClosed().subscribe(result => {
        if (result === dialogResults.YES) {
          let cmd = new DeleteRentalContractCommand();
          cmd.equipmentId = rentalContract.rentedEquipmentId;
          cmd.rentalContractId = rentalContract.rentalContractId;

          this.equipmentsStore.deleteRentalContract(cmd).subscribe(() => {
          });
        }
      });
    }
  }

  public deleteSalesContract(event: MouseEvent, salesContract: EquipmentSalesContract): void {
    event.stopPropagation();
    if (salesContract) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.deleteConfirmMessage;
      dialogRef.afterClosed().subscribe(result => {
        if (result === dialogResults.YES) {
          let cmd = new DeleteSalesContractCommand();
          cmd.equipmentId = salesContract.equipmentId;
          cmd.salesContractId = salesContract.salesContractId;

          this.equipmentsStore.deleteSalesContract(cmd).subscribe(() => {
          });
        }
      });
    }
  }

  public deleteServiceContract(event: MouseEvent, serviceContract: EquipmentServiceContract): void {
    event.stopPropagation();
    if (serviceContract) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.deleteConfirmMessage;
      dialogRef.afterClosed().subscribe(result => {
        if (result === dialogResults.YES) {
          let cmd = new DeleteServiceContractCommand();
          cmd.equipmentId = serviceContract.equipmentId;
          cmd.serviceContractId = serviceContract.serviceContractId;

          this.equipmentsStore.deleteServiceContract(cmd).subscribe(() => {
          });
        }
      });
    }
  }

  public deleteInsuranceContract(event: MouseEvent, insuranceContract: EquipmentInsuranceContract): void {
    event.stopPropagation();
    if (insuranceContract) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.deleteConfirmMessage;
      dialogRef.afterClosed().subscribe(result => {
        if (result === dialogResults.YES) {
          let cmd = new DeleteInsuranceContractCommand();
          cmd.equipmentId = insuranceContract.equipmentId;
          cmd.insuranceContractId = insuranceContract.insuranceContractId;

          this.equipmentsStore.deleteInsuranceContract(cmd).subscribe(() => {
          });
        }
      });
    }
  }

  public deleteLeasingContract(event: MouseEvent, leasingContract: EquipmentLeasingContract): void {
    event.stopPropagation();
    if (leasingContract) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.deleteConfirmMessage;
      dialogRef.afterClosed().subscribe(result => {
        if (result === dialogResults.YES) {
          let cmd = new DeleteEquipmentLeasingContractCommand();
          cmd.equipmentId = leasingContract.equipmentId;
          cmd.leasingContractId = leasingContract.leasingContractId;

          this.equipmentsStore.deleteLeasingContract(cmd).subscribe(() => {
          });
        }
      });
    }
  }

  public getParsedPeriod(valuePeriod: string): ParsedPeriod {
    return this.utils.parseFromPeriod(valuePeriod);
  }

  public periodToString(valuePeriod: string): string {
    const { value, period } = this.getParsedPeriod(valuePeriod);
    if (value && period) {
      const periodName = this.translate(period.toLocaleUpperCase() === 'Y'
        ? 'general.units.time.year.s_pl'
        : 'general.units.time.month.s_pl');
      return `${value} ${periodName}`;
    }
    return null;
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public isAllowedToAddEdit(): boolean {
    return this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_COSTS)
      && this.authService.hasModule(Modules.COSTS);
  }

  private getContracts(): void {
    this.contractsSub = this.equipmentsStore.getContracts(this.equipmentId).subscribe(res => {
      if (res) {
        this.equipmentRentalContracts = res.equipmentRentalContracts;
        this.equipmentSalesContract = res.equipmentSalesContract;
        this.equipmentServiceContracts = res.equipmentServiceContracts;
        this.equipmentInsuranceContracts = res.equipmentInsuranceContracts;
        this.equipmentLeasingContracts = res.equipmentLeasingContracts;
      }
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
