import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ReportExportFormat } from '../../contract/report-export-format.enum';

@Pipe({
  name: 'reportExportFormatDisplay'
})
export class ReportExportFormatPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    switch (value) {
      case ReportExportFormat.CSV:
        return '.csv';
      case ReportExportFormat.PDF:
        return '.pdf';
      case ReportExportFormat.XLSX:
        return '.xlsx';

      default:
        return this.languageService.getInstant('shared.pipes.reportExportFormatDisplay.unknown');
    }
  }

}
