<div class="header" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon fxFlex="10" class="header__mobile-close-chat" (click)="navigateToMobileChatList()">
    keyboard_arrow_left
  </mat-icon>
  <span fxFlex="80">
    <i>{{recipient?.status | ChatUserStatus}}</i>{{recipient?.firstName}} {{recipient?.name}}
  </span>
  <mat-icon fxFlex="10" (click)="goToLandingPage()">close</mat-icon>
</div>

<div class="chat-list" fxFlex #chatScroll [scrollTop]="scrollTop">
  <div *ngIf="messages.length === 0" class="no-data-info-text">
    <p>{{'modules.communication.chat.noMessages'|translate}}</p>
  </div>
  <div *ngFor="let msg of messages" class="message-container">
    <div class="new-line" *ngIf="msg.newLine">
      {{'general.new'|translate}}
    </div>
    <div class="new-date-line" *ngIf="msg.newDateLine">
      {{msg.newDateLine | date:'dd. MMMM yyyy'}}
    </div>
    <div *ngIf="!msg.yours" class="message message--theirs" fxLayout="row"
         fxLayoutAlign="end center">
      <div class="message__message-wrapper" [ngClass]="{ 'hasAnswerButton' : msg.replyOptions}"
           fxLayout="row" fxLayoutAlign="end stretch">
        <div *ngIf="msg.replyOptions" class="answer-question-button"
             (click)="detailMsg = msg">
          <button mat-icon-button [matMenuTriggerFor]="answerPopover" *ngIf="chatPartnerActive">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <div class="message-wrapper-text">
          {{msg.message}}
        </div>
      </div>
    </div>
    <div *ngIf="msg.yours" class="message message--yours" fxLayout="row"
         fxLayoutAlign="start center">
      <div class="message-wrapper-text">
        <div *ngIf="msg.answeredMessage" class="message-wrapper-text__answered">
          {{ getMessageById(msg.answeredMessage).message }}
        </div>
        {{msg.message}}
        <mat-icon *ngIf="msg.replyOptions">more_vert</mat-icon>
      </div>
    </div>
  </div>
</div>

<div class="chat-input-box" fxLayout="column" fxFlexAlign="end center" *ngIf="chatPartnerActive">
  <div fxLayout="row" fxFlexAlign="end center">
    <input class="chat-input-box__input" fxFlex="90" matInput placeholder=""
           [(ngModel)]="chatInput" (keyup.enter)="sendMessageClick()">
    <mat-icon fxFlex="10" class="chat-input-box__send" (click)="sendMessageClick()">
      send
    </mat-icon>
  </div>
</div>

<mat-menu #answerPopover="matMenu">
  <div class="answer-popover">
    <div *ngIf="detailMsg" fxLayout="column"
         fxFlexAlign="space-around stretch">
      <button *ngFor="let detail of detailMsg.replyOptions"
              (click)="replyToMsg(detail, detailMsg.chatMessageId)">{{ detail }}
      </button>
    </div>
  </div>
</mat-menu>
