<div *ngIf="(data | chartData : strategy) as transformedData" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="chart-container">
    <canvas baseChart
        [type]="type"
        [data]="transformedData"
        [options]="options"
        [legend]="true">
    </canvas>
  </div>
</div>
