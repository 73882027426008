import { ContactLocation } from 'app/modules/contact/contract/contact/contact-location.interface';
import { environment } from 'environments/environment';
import { OrganisationCoreDataService } from '../../../../shared/services/organisation-core-data.service';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSource } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { filterByTerm } from 'app/shared/collection-utils';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, map, skip, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { CreateContactCommand } from '../../contract/contact/create-contact-command.interface';
import { ContactService, SearchContactsRequest } from './contacts.service';
import { ContactSearch } from '../../contract/contact/contact-search.interface';
import { PaginationInfo } from 'app/shared/contract/pagination-info-interface';
import { LanguageService } from 'app/shared/services/language.service';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { Router } from '@angular/router';
import { ContactView } from '../../contract/contact/contact-view.interface';
import { UpdateContactCommand } from '../../contract/contact/update-contact-command.interface';
import { DeleteContactCommand } from '../../contract/contact/delete-contact-command.interface';
import { DeactivateContactCommand } from '../../contract/contact/deactivate-contact-command.interface';
import { ActivateContactCommand } from '../../contract/contact/activate-contact-command.interface';
import { AddContactLabelCommand } from '../../contract/contact/add-contact-label-command.interface';
import { RemoveContactLabelCommand } from '../../contract/contact/remove-contact-label-command.interface';
import { CustomerLabel } from 'app/shared/contract/customer-label.interface';
import { ContactFiltersService } from './contact-filters.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { ContactLocationParams } from '../contract/contact-request-params/contact-location-params.interface';
import { ContactColumnService } from './contact-column.service';
import { SetContactDescriptionCommand } from '../../contract/contact/set-contact-description-command.interface';
import { ExportContactRequest } from '../contract/contact-request-params/export-contact-request.interface';
import { ContactStatus } from '../enums/contact-status.enum';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { UsersService } from 'app/modules/userrole/shared/users.service';
import { EMPTY_MAP_LOCATIONS_PAYLOAD, MapLocationsPayload } from 'app/shared/modules/map/interfaces/map-locations-payload.interface';

@Injectable()
export class ContactDataSource extends DataSource<ContactSearch> {
  private _data: BehaviorSubject<ContactSearch[]> = new BehaviorSubject([]);
  private _currentContact: BehaviorSubject<ContactView> = new BehaviorSubject(null);
  private contactsRequest = new Subject<SearchContactsRequest>();
  private allOrganisations = new BehaviorSubject<ViewOrganisation[]>([]);
  private organisationFilter = new BehaviorSubject<string>('');
  private _contactLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private _sort: Sort = { active: null, direction: null };
  private _totalCount = new BehaviorSubject<number>(0);
  private _contactLocations = new BehaviorSubject<MapLocationsPayload<ContactLocation>>(EMPTY_MAP_LOCATIONS_PAYLOAD);
  private _totalCountLocations = new BehaviorSubject<number>(0);
  private _ignoreLocationSearchTerms = false;

  public readonly contacts = this._data.asObservable();
  public readonly currentContact = this._currentContact.asObservable();
  public readonly contactLabels: Observable<string[]> = this._contactLabels.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly filters = this.contactFiltersService.filters;
  public readonly onFiltersUpdated = this.contactFiltersService.onFiltersUpdated;
  public readonly totalCount = this._totalCount.asObservable();
  public readonly contactLocations = this._contactLocations.asObservable();
  public readonly totalCountLocations = this._totalCountLocations.asObservable();

  public readonly filteredOrganisations = combineLatest([this.allOrganisations, this.organisationFilter])
    .pipe(map(([organisations, filterTerm]) => filterByTerm(organisations, filterTerm, 'organisationName')));
  public readonly availableCustomerLabels: Observable<string[]> = this.labels
  .pipe(map((availableLabels) =>
    availableLabels.map(label => label.name)
  ));
  public readonly filteredCustomerLabels: Observable<string[]> = combineLatest(this._labels, this._contactLabels)
  .pipe(map(([availableLabels, selectedLabels]) =>
    availableLabels.map(label => label.name)
    .filter(label => !selectedLabels.includes(label)),
  ));

  public get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  public set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  public get searchTerms() {
    return this._searchTerms;
  }

  public set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
    } else {
      this.snackBar.open(
        this.languageService.getInstant('general.snackBarMessages.tooLongCannotSearch'),
        undefined,
        {duration: 5000});
    }
  }

  public get ignoreLocationSearchTerms(): boolean {
    return this._ignoreLocationSearchTerms;
  }

  public set ignoreLocationSearchTerms(ignoreLocationSearchTerms: boolean) {
    this._ignoreLocationSearchTerms = ignoreLocationSearchTerms;
  }

  public get sort(): Sort {
    return this._sort;
  }

  public set sort(sort: Sort) {
    this._sort = sort;
  }

  public get selectedColumnsList(): string[] {
    return this.userConfigurationService.userConfiguration.contactListConfiguration.columns;
  }

  constructor(
    private contactService: ContactService,
    private contactColumnService: ContactColumnService,
    private organisationCoreDataService: OrganisationCoreDataService,
    private snackBar: MatSnackBar,
    private languageService: LanguageService,
    private router: Router,
    private contactFiltersService: ContactFiltersService,
    private userConfigurationService: UserConfigurationService,
    private usersService: UsersService
  ) {
    super();
    this.initListeners();
  }

  public connect(): Observable<ContactSearch[]> {
    return this.contacts;
  }

  public disconnect(): void {
  }

  public selectCurrentContactOrDefault(): void {
    if (this._currentContact.value) {
      this.router.navigate(['assets/contact/list', this._currentContact.value.contactId]);
    } else {
      this.selectDefaultContact();
    }
  }

  public updateCurrentContact(): void {
    if (this._currentContact.value) {
      this.changeCurrentContact(this._currentContact.value.contactId);
    } else {
      this.selectCurrentContactOrDefault();
    }
  }

  public changeCurrentContact(contactId: string): void {
    if (contactId) {
      this.contactService.getContact(contactId)
      .subscribe((res: ContactView) => {
        this._currentContact.next(res);
        this._contactLabels.next(res.labels ? res.labels : []);
      });
    } else {
      this._currentContact.next(null);
      this._contactLabels.next([]);
    }
  }

  public updateListing(page: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.contactColumnService.selectPageSize(size);
    }
    this.pagination.index = page;
    this.pagination.size = size;

    this.contactsRequest.next(this.getContactsRequestParams());
  }

  public updateMap(centerMap: boolean = false): void {
    const params: ContactLocationParams = this.ignoreLocationSearchTerms ? this.emptyContactLocationsParams() : {
      terms: this._searchTerms,
      ...this.contactFiltersService.getContactSearchParams()
    };
    this.getContactLocations(params, centerMap);
  }

  public clearStoredLocations(): void {
    this._contactLocations.next(EMPTY_MAP_LOCATIONS_PAYLOAD);
  }

  private getContactLocations(searchParams: ContactLocationParams, centerMap: boolean = false): void {
    this.getContactLocationsResponse(searchParams).subscribe((locations: ContactLocation[]) => {
      this._contactLocations.next({ locations, centerMap });
      this._totalCountLocations.next(locations.length);
    });
  }

  private getContactLocationsResponse(searchParams: ContactLocationParams): Observable<ContactLocation[]> {
    return searchParams.terms
      ? this.contactService.getContactLocationsSearch(searchParams)
      : this.contactService.getContactLocations(searchParams);
  }

  public getMapConfiguration(): MapConfiguration {
    return this.userConfigurationService.getMapConfigurationContact();
  }

  public saveMapConfiguration(mapConfig: MapConfiguration): void {
    this.userConfigurationService.saveMapConfigurationContact(mapConfig);
    this.usersService.saveMapConfigurationContact(mapConfig).subscribe();
  }

  public addContact(command: CreateContactCommand): Observable<string> {
    return this.contactService.addContact(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.updateFilters()));
  }

  public updateContact(command: UpdateContactCommand): Observable<string> {
    return this.contactService.updateContact(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => {
        this.updateCurrentContact();
        this.updateFilters();
      }));
  }

  public deleteContact(command: DeleteContactCommand): void {
    this.contactService.deleteContact(command)
    .pipe(
      delay(environment.DELAY_LONG),
      tap(() => this.updateListing()),
      switchMapTo(this.contacts),
      skip(1),
      take(1)
    )
    .subscribe((res: ContactSearch[]) => {
      this.getCustomerLabels();
      this.updateFilters();
      if (res.length > 0) {
        this.router.navigate(['assets/contact/list', res[0].contactId]);
      } else {
        this.changeCurrentContact(null);
        this.router.navigate(['assets/contact/list']);
      }
    });
  }

  public deactivateContact(command: DeactivateContactCommand): Observable<string> {
    return this.contactService.deactivateContact(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.updateFilters()));
  }

  public activateContact(command: ActivateContactCommand): Observable<string> {
    return this.contactService.activateContact(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.updateFilters()));
  }

  public addContactLabel(command: AddContactLabelCommand): void {
    this.contactService.addContactLabel(command)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe(() => {
      this.pushNewLabel(command.label);
      this.contactFiltersService.updateFilters();
      this.getCustomerLabels();
      this.updateFilters();
    });
  }

  public removeContactLabel(command: RemoveContactLabelCommand): void {
    this.contactService.removeContactLabel(command)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe(() => {
      this.removeLabel(command.label);
      this.contactFiltersService.updateFilters();
      this.getCustomerLabels();
      this.updateFilters();
    });
  }

  public filterOrganisations(value: string): void {
    this.organisationFilter.next(value);
  }

  public identifierInUse(identifier: string): Observable<boolean> {
    return this.contactService.identifierInUse(identifier);
  }

  public getOrganisations(): void {
    this.organisationCoreDataService.getOrganisations()
      .subscribe(organisations => this.allOrganisations.next(organisations));
  }

  public getCustomerLabels(): Observable<CustomerLabel[]> {
    this.contactService
    .getCustomerLabels()
    .subscribe((labels: CustomerLabel[]) => this._labels.next(labels));

    return this.labels;
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.contactFiltersService.updateFilterParams(commands);
  }

  public updateFilters(): void {
    this.contactFiltersService.updateFilters();
  }

  public setDescription(command: SetContactDescriptionCommand): Observable<string> {
    return this.contactService.setDescription(command);
  }

  private selectDefaultContact(): void {
    this.contacts.pipe(
      filter((res: ContactSearch[]) => res && res.length > 0),
      take(1),
    ).subscribe((res: ContactSearch[]) => {
      this.router.navigate(['assets/contact/list', res[0].contactId]);
    });
  }

  private pushNewLabel(label: string): void {
    const labels = [...this._contactLabels.value];
    labels.push(label);
    this._contactLabels.next(labels);
  }

  private removeLabel(label: string): void {
    const labels = [...this._contactLabels.value];
    labels.splice(labels.indexOf(label), 1);
    this._contactLabels.next(labels);
  }

  private initListeners(): void {
    this.contactsRequestListener();
    this.paginatorPageSizeListener();
  }

  private contactsRequestListener(): void {
    this.contactsRequest
    .pipe(switchMap((params: SearchContactsRequest) => this.contactService.getContactsSearch(params)))
    .subscribe(res => this.updateStoreData(res));
  }

  private paginatorPageSizeListener(): void {
    this.contactColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  private updateStoreData(response: PagedResponse<ContactSearch>): void {
    this.updatePaginator(response);
    this._data.next(response.content);
    this._totalCount.next(response.totalElements);
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private updatePaginator(response: PagedResponse<any>): void {
    this.pagination = {
      totalElements: response.totalElements,
      totalPages: response.totalPages,
      size: response.size,
      index: response.number,
      numberOfElements: response.numberOfElements,
    }
  }

  private getSortParam(sort: Sort): string | string[] {
    return sort && sort.active && sort.direction ? this.toSortString(sort) : null
  }

  public exportContactList(columns: string[], applyFilter: boolean, sortBy: string, sortDescending: boolean): Observable<Blob> {
    const paramPart = applyFilter
      ? { terms: this.searchTerms, ...this.contactFiltersService.getContactSearchParams() }
      : { status: [ContactStatus.ACTIVE] };

    const params: ExportContactRequest = {
      columns,
      sort: this.toSortString({ active: sortBy, direction: sortDescending ? 'desc' : 'asc' }),
      ...paramPart
    }

    return this.contactService.exportContactList(params);
  }

  private toSortString(sort: Sort): string {
    return sort && sort.active && sort.direction
      ? `${sort.active},${sort.direction}`
      : null;
  }

  private emptyContactLocationsParams(): ContactLocationParams {
    return  {
      organisation: [],
      type: [],
      label: [],
      // We only want to display active contacts
      status: [ContactStatus.ACTIVE],
      terms: ''
    }
  }

  private getContactsRequestParams(): SearchContactsRequest {
    return {
      page: this.pagination.index,
      size: this.pagination.size,
      sort: this.getSortParam(this._sort),
      body: {
        terms: this._searchTerms,
        searchColumns: this.selectedColumnsList,
        ...this.contactFiltersService.getContactSearchParams(),
      },
    };
  }
}
