import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'app/shared/utils';
import { TransportView } from '../../contracts/transport/transport-view.interface';

@Pipe({
  name: 'transportPlannedDuration'
})
export class TransportPlannedDurationPipe implements PipeTransform {
  constructor(private utils: Utils) {}

  public transform(transport: TransportView): string {
    if (!transport) {
      return null;
    }

    return this.utils.durationBetweenDatesToString(
      transport?.equipmentAssignmentDetails?.startDate?.timestamp,
      transport?.equipmentAssignmentDetails?.endDate?.timestamp);
  }
}
