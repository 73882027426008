import { NgModule } from '@angular/core';
import { ResizeColumnDirective } from './directives/resize-column/resize-column.directive';
import { HeaderCellTitleComponent } from './components/header-cell-title/header-cell-title.component';
import { ResizeColumnCellDirective } from './directives/resize-column/resize-column-cell.directive';

@NgModule({
  declarations: [
    ResizeColumnDirective,
    HeaderCellTitleComponent,
    ResizeColumnCellDirective,
  ],
  imports: [
  ],
  exports: [
    // TODO: OSP UI: Remove some exports when a custom table component is done
    // (there isn't sense in providing this to the public)
    ResizeColumnDirective,
    ResizeColumnCellDirective,
    HeaderCellTitleComponent,
  ],
})
export class BhTableModule {

}
