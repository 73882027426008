export class RenewEmployeeQualificationCommand {
    qualificationId: string;
    employeeId: string;
    qualificationRenewalDate: string;

    constructor(qualificationId: string,
        employeeId: string,
        qualificationRenewalDate: string) {
            this.qualificationId = qualificationId;
            this.employeeId = employeeId;
            this.qualificationRenewalDate = qualificationRenewalDate;
        }
}
