import { Component } from '@angular/core';
import { CommunicationDataSource } from '../../shared/communication.datasource';
import { KeycloakService } from '../../../../core/keycloak';
import { BaseChatListComponent } from '../base-chat-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OnlineStatusService } from '../../../../shared/services/online-status.service';
import { RouterHistory } from '../../../../shared/router-history';

@Component({
  selector: 'bh-chat',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent extends BaseChatListComponent {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected communicationDataSource: CommunicationDataSource,
              protected routerHistory: RouterHistory,
              public onlineStatusService: OnlineStatusService) {
    super(authService, router, activatedRoute, communicationDataSource, routerHistory);
  }
}
