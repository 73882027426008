<div class="sync-keycloak-dialog">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title>{{'modules.userrole.roleRealm.syncRealm.title'|translate}}</h1>

  <div class="sync-keycloak-dialog-content">
    <table mat-table [dataSource]="realms" class="sync-status-table">
      <ng-container matColumnDef="realmType">
        <th mat-header-cell *matHeaderCellDef>{{'modules.userrole.roleRealm.syncRealm.realm'|translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.realmName}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="lastSyncStarted">
        <th mat-header-cell *matHeaderCellDef>{{'modules.userrole.roleRealm.syncRealm.lastSyncStarted'|translate}}</th>
        <td mat-cell *matCellDef="let element"> {{ element.lastSyncStarted | date:'dd.MM.yyyy HH:mm:ss' }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="lastSyncFinished">
        <th mat-header-cell *matHeaderCellDef>{{'modules.userrole.roleRealm.syncRealm.lastSyncFinished'|translate}}</th>
        <td mat-cell *matCellDef="let element"> {{ element.lastSyncFinished | date:'dd.MM.yyyy HH:mm:ss' }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell class="status-cell" *matHeaderCellDef></th>
        <td mat-cell class="status-cell" *matCellDef="let element">
          <button *ngIf="!isSyncInProcess(element)"
                  id="syncUsersButton"
                  mat-raised-button color="primary"
                  (click)="sync(element)">
            {{'general.buttons.sync'|translate|uppercase}}
          </button>

          <mat-icon *ngIf="isSyncInProcess(element)" class="sync-spinner">
            autorenew
          </mat-icon>
        </td>
        <td mat-footer-cell class="status-cell" *matFooterCellDef>
          <button mat-raised-button color="primary"
                  (click)="syncAll()"
                  [disabled]="isAnySyncInProcess()">
            {{'general.buttons.syncAll'|translate|uppercase}}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>
