import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentUsageRecord } from '../../../../contract/equipment-usage-record';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { MatTableDataSource } from '@angular/material/table';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-usage',
  templateUrl: './equipment-view-usage.component.html',
  styleUrls: ['./equipment-view-usage.component.scss']
})
export class EquipmentViewUsageComponent implements OnInit, OnDestroy {

  private equipment: ViewEquipment;
  public usages: EquipmentUsageRecord[];
  public dataSource: MatTableDataSource<EquipmentUsageRecord>;

  public readonly displayedColumns = ['projectName', 'projectCostCenter', 'address', 'startDate', 'endDate'];

  constructor(protected equipmentStore: EquipmentsDataSource,
              protected router: Router,
              private languageService: LanguageService,
              private matomoTracker: MatomoTracker) {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_USAGE);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.subscribeToEquipment();
    this.subscribeToUsages();
    this.dataSource.filterPredicate = (data: EquipmentUsageRecord, filter: string) =>
      data.projectName.toLowerCase().indexOf(filter) !== -1
    || (data.projectCostCenter !== null && data.projectCostCenter.toLowerCase().indexOf(filter) !== -1);
  }

  public ngOnDestroy(): void {
    this.dataSource.filter = null;
  }

  private subscribeToUsages(): void {
    this.equipmentStore.equipmentUsage
      .pipe(untilDestroyed(this))
      .subscribe((usages: EquipmentUsageRecord[]) => {
        this.usages = usages;
        this.dataSource.data = usages;
      });
  }

  public getAddress(address: Address): string {
    let street = address.street ? address.street + ' ' + address.streetNumber + ' ' : '';
    let postalCode = address.postalCode ? address.postalCode + ' ' : '';
    let city = address.city ? address.city : '';
    return street + postalCode + city;
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  private subscribeToEquipment(): void {
    this.equipmentStore.currentEquipment
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.equipment = res;
          this.equipmentStore.updateEquipmentUsage(this.equipment.equipmentId);
          this.dataSource.filter = null;
        }
      });
  }

}
