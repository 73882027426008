import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class RouterHistory {
  private history: string[] = [];
  private readonly maxHistoryEntries = 100;
  private initialized = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private location: Location) {
  }

  public init(): void {
    if (this.initialized) {
      return;
    }
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(({urlAfterRedirects}: NavigationEnd) => {

      this.history.push(urlAfterRedirects);
      if (this.history.length > this.maxHistoryEntries) {
        this.history.shift();
      }
    });
    this.initialized = true;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '';
  }

  public getCurrentUrl(): string {
    return this.history[this.history.length - 1] || '';
  }

  public isFirstVisit(): boolean {
    return this.history.length < 2;
  }

  public goBack(fallbackPath?: string): void {
    if (this.isFirstVisit()) {
      if (this.route.parent) {
        this.router.navigate(['.'], {relativeTo: this.route.parent});
        return;
      } else if (fallbackPath) {
        this.history.shift();
        this.router.navigate([fallbackPath]);
        return;
      }
      const path: string = Location.stripTrailingSlash(this.location.path());
      this.router.navigate([path.substring(0, path.lastIndexOf('/'))]);
    } else {
      this.router.navigate([this.getPreviousUrl()]);
    }
  }
}
