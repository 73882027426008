<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <bh-mobile-sub-header [title]="project?.projectName"
                        [subtitle]="project?.projectAddress?.city"
                        [navigateToList]="true"
                        [cancelRoute]="backRoute">
  </bh-mobile-sub-header>

  <mat-expansion-panel class="m-view-details-menu">
    <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
      {{currentViewMenu.name}}
    </mat-expansion-panel-header>

    <div class="m-view-details-menu__content">
      <div *ngFor="let menu of viewMenus">
        <div class="menu-item" *ngIf="project?.projectAddress || menu.url !== viewMenus[1].url"
             (click)="navigateToMenu(menu)">
          <span>{{menu.name}}</span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <div class="m-view-wrapper" fxFlex>
    <router-outlet></router-outlet>
  </div>
</div>
