import { Component, OnInit } from '@angular/core';
import { BaseBulkItemViewDetailComponent } from '../base/base-bulk-item-view-detail.component';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { BulkItemFieldStore } from '../../../../service/bulk-item-field.store';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { AddBulkItemLabelCommand } from '../../../../../contract/add-bulk-item-label-command';
import { DeleteBulkItemLabelCommand } from '../../../../../contract/delete-bulk-item-label-command';
import { BulkItemTypeResolver } from '../../../../bulk-item-add-edit/bulk-item-type.resolver';
import { SetBulkItemDescriptionCommand } from '../../../../../contract/set-bulk-item-description-command';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { BulkItemType } from '../../../../../contract/bulk-item-type';
import { OrganisationInfoService } from '../../../../../shared/services/organisation-info.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-detail',
  templateUrl: './bulk-item-view-detail.component.html',
  styleUrls: ['./bulk-item-view-detail.component.scss']
})
export class BulkItemViewDetailComponent extends BaseBulkItemViewDetailComponent implements OnInit {

  constructor(protected bulkItemFieldStore: BulkItemFieldStore,
              protected bulkItemStore: BulkItemDatasource,
              protected organisationsService: OrganisationInfoService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory,
              public bulkItemTypeResolver: BulkItemTypeResolver) {
    super(bulkItemStore, bulkItemFieldStore, organisationsService, route, authService, router, routerHistory);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public addLabel(label: string): void {
    const cmd = new AddBulkItemLabelCommand(this.bulkItem.bulkItemId, label);
    this.bulkItemStore.saveLabel(cmd);
  }

  public deleteLabel(label: string): void {
    const cmd = new DeleteBulkItemLabelCommand(this.bulkItem.bulkItemId, label);
    this.bulkItemStore.removeLabel(cmd);
  }

  public saveBulkItemDescription(description: string): void {
    const cmd = new SetBulkItemDescriptionCommand(this.bulkItem.bulkItemId, description);
    this.bulkItemStore.setDescription(cmd);
  }

  public isConsumable(): boolean {
    return this.bulkItem && this.bulkItem.bulkItemType === BulkItemType.CONSUMABLE;
  }

  public hasShortName() {
    return this.bulkItem && this.bulkItem.bulkItemShortName;
  }
}
