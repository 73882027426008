import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportHistoryColumn } from '../enums/transport-history-column.enum';

@Pipe({
  name: 'transportFields'
})
export class TransportFieldsResolverPipe implements PipeTransform {
  private fieldTranslations = {
    [TransportHistoryColumn.TRANSPORT_CREATED]: [this.translate('shared.pipes.transportFieldsResolver.transportCreated')],
    [TransportHistoryColumn.DUE_DATE]: [this.translate('modules.transportation.base.dueDate')],
    [TransportHistoryColumn.STATE]: [this.translate('modules.transportation.base.status')],
    [TransportHistoryColumn.TITLE]: [this.translate('modules.transportation.base.taskTitle')],
    [TransportHistoryColumn.DESCRIPTION]: [this.translate('modules.transportation.transportationTaskAddEdit.description')],
    [TransportHistoryColumn.PRIORITY]: [this.translate('modules.transportation.base.priority')],
    [TransportHistoryColumn.ESTIMATED_DURATION]: [this.translate('modules.transportation.base.estimatedDuration')],
    [TransportHistoryColumn.TRANSPORT_TYPE]: [this.translate('modules.transportation.base.transportType')],
    [TransportHistoryColumn.IMAGE]: [this.translate('general.image.s')],
    [TransportHistoryColumn.DOCUMENT]: [this.translate('general.document.s')],

    [TransportHistoryColumn.VEHICLE_CUSTOMER_SERIAL_NUMBER]: [
      this.translate('modules.transportation.base.assignedVehicle'),
      this.translate('general.identifier')],
    [TransportHistoryColumn.VEHICLE_LICENSE_PLATE]: [
      this.translate('modules.transportation.base.assignedVehicle'),
      this.translate('modules.equipment.base.licensePlate')],
    [TransportHistoryColumn.VEHICLE_MODEL]: [
      this.translate('modules.transportation.base.assignedVehicle'),
      this.translate('modules.equipment.base.model.s')],
    [TransportHistoryColumn.VEHICLE_NAME]: [
      this.translate('modules.transportation.base.assignedVehicle'),
      this.translate('general.name.s')],
    [TransportHistoryColumn.VEHICLE_PLANNED_START_DATE]: [this.translate('modules.transportation.base.assignmentStartDate')],
    [TransportHistoryColumn.VEHICLE_PLANNED_END_DATE]: [this.translate('modules.transportation.base.assignmentEndDate')],
    [TransportHistoryColumn.VEHICLE_ACTUAL_START_DATE]: [this.translate('modules.transportation.base.actualTransportStart')],
    [TransportHistoryColumn.VEHICLE_ACTUAL_END_DATE]: [this.translate('modules.transportation.base.actualTransportEnd')],

    [TransportHistoryColumn.START_ITEM_NAME]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('general.name.s')],
    [TransportHistoryColumn.START_ITEM_ADDRESS]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('general.address.address')],
    [TransportHistoryColumn.START_ITEM_LOCATION]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('general.location.s')],
    [TransportHistoryColumn.START_ITEM_CONTACT_TYPE]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('shared.pipes.transportFieldsResolver.contactType')],
    [TransportHistoryColumn.START_ITEM_CONTACT_IDENTIFIER]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('shared.pipes.transportFieldsResolver.contactIdentifier')],
    [TransportHistoryColumn.START_ITEM_COST_CENTER]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('general.costCenter')],
    [TransportHistoryColumn.START_ITEM_TYPE]: [
      this.translate('shared.pipes.transportFieldsResolver.startPosition'),
      this.translate('shared.pipes.transportFieldsResolver.itemType')],

    [TransportHistoryColumn.TARGET_ITEM_NAME]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('general.name.s')],
    [TransportHistoryColumn.TARGET_ITEM_ADDRESS]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('general.address.address')],
    [TransportHistoryColumn.TARGET_ITEM_LOCATION]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('general.location.s')],
    [TransportHistoryColumn.TARGET_ITEM_CONTACT_TYPE]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('shared.pipes.transportFieldsResolver.contactType')],
    [TransportHistoryColumn.TARGET_ITEM_CONTACT_IDENTIFIER]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('shared.pipes.transportFieldsResolver.contactIdentifier')],
    [TransportHistoryColumn.TARGET_ITEM_COST_CENTER]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('general.costCenter')],
    [TransportHistoryColumn.TARGET_ITEM_TYPE]: [
      this.translate('shared.pipes.transportFieldsResolver.targetPosition'),
      this.translate('shared.pipes.transportFieldsResolver.itemType')],

    [TransportHistoryColumn.RELATED_PROJECT_NAME]: [
      this.translate('modules.transportation.base.relatedProject'),
      this.translate('general.name.s')],
    [TransportHistoryColumn.RELATED_PROJECT_STATUS]: [
      this.translate('modules.transportation.base.relatedProject'),
      this.translate('shared.pipes.transportFieldsResolver.projectStatus')],
    [TransportHistoryColumn.RELATED_PROJECT_COST_CENTER]: [
      this.translate('modules.transportation.base.relatedProject'),
      this.translate('general.costCenter')],
    [TransportHistoryColumn.RELATED_PROJECT_ADDRESS]: [
      this.translate('modules.transportation.base.relatedProject'),
      this.translate('general.address.address')],
    [TransportHistoryColumn.RELATED_PROJECT_LOCATION]: [
      this.translate('modules.transportation.base.relatedProject'),
      this.translate('general.location.s')],
  };

  constructor(private languageService: LanguageService) {}

  public transform(column: string): string[] {
    return this.fieldTranslations[column]
      || [this.translate('shared.pipes.transportFieldsResolver.typeNotFound', { value: column })];
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
