import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, startWith, debounceTime } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TransferItem } from '../../../../shared/transfer/model/transfer-item';
import { TransferProject } from '../bulk-item-transfer-project-select/transfer-project';
import { ProjectToProjectTransferItem } from '../../../../contract/project-to-project-transfer-item';
import { CreateProjectToProjectTransferCartCommand } from '../../../../contract/create-project-to-project-transfer-cart.command';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { ViewProject } from '../../../../contract/view-project.interface';
import { faExchange, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { BulkItemTransferProjectSelectComponent } from '../bulk-item-transfer-project-select/bulk-item-transfer-project-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransferStore } from '../../shared/transfer.store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocationType } from '../../../../../../shared/enums/location-type.enum';
import { TransferItemTransferDateRequest } from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-item-transfer-date-request';
import { TransferItemsSearchMatcher } from 'app/modules/disposition/shared/transfer/utils/transfer-items-search-matcher.class';
import { TransferTargetProjectDatasource } from 'app/modules/disposition/shared/transfer-target-project.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-project-to-project-transfer-cart',
  templateUrl: './bulk-item-project-to-project-transfer-cart.component.html',
  styleUrls: ['./bulk-item-project-to-project-transfer-cart.component.scss'],
})
export class BulkItemProjectToProjectTransferCartComponent implements OnInit, OnDestroy {

  @ViewChild(BulkItemTransferProjectSelectComponent, {static: true}) projectSelect: BulkItemTransferProjectSelectComponent;

  public readonly faExchange: IconDefinition = faExchange;
  public sourceProject: ViewProject;
  public targetProject: TransferProject;
  public transferItems: Observable<TransferItem[]>;
  public isLoadingProjectAmounts = false;

  public filterControl: UntypedFormControl;
  private isLoadingProjectAmountsSubscription: Subscription;
  private projectToProjectTransferItems: ProjectToProjectTransferItem[] = [];
  private summaryTransferItems: TransferItem[];

  constructor(
    private projectStore: ProjectDataSource,
    private transferStore: TransferStore,
    private transferTargetProjectStore: TransferTargetProjectDatasource,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.filterControl = new UntypedFormControl();

    this.isLoadingProjectAmountsSubscription = this.transferStore.loadingProjectAmounts.subscribe((loading: boolean) => {
      this.isLoadingProjectAmounts = loading;
    });

    if (this.projectStore.noProjectSelected()) {
      this.projectStore.setCurrentProject(this.route.snapshot.params['sourceId']);
    }
    this.projectStore.currentProject
    .pipe(
      filter(sourceProject => !!sourceProject),
      untilDestroyed(this))
    .subscribe((sourceProject: ViewProject) => {
      this.sourceProject = sourceProject;
      this.transferStore.getProjectAmounts(sourceProject.projectId);
    });

    this.transferItems =
      combineLatest([
        this.transferStore.transferItems,
        this.filterControl.valueChanges.pipe(debounceTime(500), startWith(''))])
      .pipe(
        map(([items, filterTerm]) => ([items, filterTerm, this.getSearchMatchTransferItemIds(items, filterTerm)])),
        map(([items, filterTerm, searchMatchItems]: [TransferItem[], string, Set<string>]) =>
          items.map((item: TransferItem) => {
            item.hiddenOption = !searchMatchItems.has(item.id);
            item.isActiveContainer = (filterTerm && searchMatchItems.has(item.id)) || item.isActiveContainer;
            return item;
          })
        )
      );

    this.transferStore.transferFailed.pipe(untilDestroyed(this)).subscribe(() => {
      this.snackBar.open(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'),
        this.translate('general.buttons.okay'), {
          duration: 3000,
        });
    });

    this.transferStore.transferSuccess.pipe(untilDestroyed(this)).subscribe(() => {
      this.transferStore.summaryDialog(
        Array.of(this.transferStore.transfer),
        this.summaryTransferItems.filter(item => item.transferAmount > 0),
        LocationType.PROJECT,
        this.targetProject,
      )
      .afterClosed()
      .pipe(filter(result => result !== 'cancel'))
      .subscribe(() => {
          this.projectSelect.reset();
        },
      );
    });

    this.transferItems.pipe(
      untilDestroyed(this))
    .subscribe(items => {
      this.summaryTransferItems = items;
      this.projectToProjectTransferItems = items.map(item =>
        ProjectToProjectTransferItem.fromTransferItem(item, this.sourceProject.projectId));
    });
  }

  public ngOnDestroy(): void {
    this.isLoadingProjectAmountsSubscription.unsubscribe();
  }

  public updateAmount(item: TransferItem): void {
    this.transferStore.updateTransferAmount(item.id, item.transferAmount);
    this.projectToProjectTransferItems
    .filter(transferItem => item.matchesStockAndBulkItem(transferItem.sourceStockId, transferItem.transferItemId))
    .forEach(transferItem => transferItem.amount = item.transferAmount);
    this.summaryTransferItems
    .filter(transferItem => item.matchesStockAndBulkItem(transferItem.sourceStockId, transferItem.id))
    .forEach(transferItem => transferItem.transferAmount = item.transferAmount);
  }

  public updateFilterControl(searchTerm: string): void {
    this.filterControl.patchValue(searchTerm.trim().toLowerCase());
  }

  public confirmCart(): void {
    this.transferStore.confirmationDialog(
      this.translate('modules.disposition.bulkItemTransfer.fulfillTransfer'),
      this.translate('general.buttons.cancel'),
      this.translate('modules.disposition.confirmation.title.transfer'),
      this.translate('modules.disposition.confirmation.message.transferToProject', {value: this.targetProject.projectName}),
      LocationType.PROJECT,
      this.targetProject.projectId,
      this.calculateTransferItems())
    .afterClosed()
    .pipe(filter(result => result !== 'cancel'))
    .subscribe({next: res => {
        this.transferStore.waitingDialogRef = this.transferStore.waitingDialog();
        this.createCart(res);
      }
    });
  }

  public closeCart(): void {
    this.transferTargetProjectStore.resetSearchLabels();
    this.router.navigate(['sites/projects/list/' + this.sourceProject.projectId + '/assignments']);
  }

  public selectProject(project: TransferProject): void {
    this.targetProject = project;
  }

  private getSearchMatchTransferItemIds(items: TransferItem[], filterTerm: string): Set<string> {
    if (!filterTerm) {
      return new Set((items || []).map(({ id }) => id));
    }

    const searchMatcher = new TransferItemsSearchMatcher(items);
    return searchMatcher.getMatchItemIds(filterTerm);
  }

  private createCart(form: UntypedFormGroup): void {
    const cmd: CreateProjectToProjectTransferCartCommand = new CreateProjectToProjectTransferCartCommand(
      this.targetProject.projectId,
      this.projectToProjectTransferItems.filter(item => item.amount > 0),
      form.get('employeeId').value ? form.get('employeeId').value : null,
      form.get('externalEmployee').value ? form.get('externalEmployee').value : null,
      form.get('comment').value ? form.get('comment').value : null,
      form.get('historicTransferDateTime').value ? form.get('historicTransferDateTime').value : null);
    this.transferStore.transferProjectToProject(cmd, this.sourceProject.projectId);
  }

  private translate(key: string, interpolateParams?: any): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

  private calculateTransferItems(): TransferItemTransferDateRequest[] {
    return this.projectToProjectTransferItems
      .filter(item => item.amount > 0)
      .map(item => TransferItemTransferDateRequest.fromProjectToProjectTransferItem(item));
  }

}
