import { AssetListActivatedView } from 'app/shared/contract/activated-view.interface';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { DisplayService } from '../../../../shared/display.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EmployeeManagementDatasource } from '../../shared/employee-management.datasource';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EmployeeColumnService } from '../service/employee-column.service';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { MatSort, Sort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../shared/router-history';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeAddEditComponent } from '../employee-add-edit/employee-add-edit.component';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faPortrait } from '@fortawesome/pro-regular-svg-icons';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { CurrentItemPaginatorUtils } from 'app/shared/directives/current-item-paginator/current-item-paginator-utils.class';
import { TabService } from 'app/shared/tab.service';
import { ListType } from 'app/shared/enums/list-type.enum';
import { COLUMN_TYPE } from '../../../../shared/constants/column-definition-constants';
import { environment } from 'environments/environment';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import {ThumbnailSize} from '../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';

@UntilDestroy()
@Component({
  selector: 'bh-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent extends GuardedNavigableInputComponent implements AssetListActivatedView, OnInit {

  public readonly faPortrait: IconDefinition = faPortrait;
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly ThumbnailSize = ThumbnailSize;

  public searchForm: UntypedFormGroup;
  public shownColumns: ColumnDefinition[] = [];
  public routeButton = new ButtonInfo();
  public readonly isIdentifierOfEmployee = CurrentItemPaginatorUtils.isIdentifierOfEmployee;
  public filters: FilterType[] = this.employeeManagementStore.filters;
  public onFiltersUpdated: EventEmitter<any> = this.employeeManagementStore.onFiltersUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  public readonly searchSuggestionsField = 'employeeListSuggestions';
  private searchTermSubscription = new Subscription();

  @ViewChild('paginator', { static: true }) private paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              public displayService: DisplayService,
              public employeeManagementStore: EmployeeManagementDatasource,
              public columnService: EmployeeColumnService,
              private tabService: TabService) {
    super(authService, router, route, routerHistory);
    this.employeeManagementStore.updateFilters();
    this.employeeManagementStore.updateListing();
    if (this.route.snapshot.data['create']) {
      this.dialog.open(EmployeeAddEditComponent, {disableClose: true}).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['assets/employee/list']);
        } else {
          this.employeeManagementStore.updateListing();
        }
      })
    } else if (!this.route.snapshot.firstChild) {
      employeeManagementStore.selectCurrentEmployeeOrDefault();
    }
  }

  public ngOnInit(): void {
    this.tabService.changeAssetTab(ListType.EMPLOYEES);
    this.columnService.selectedColumns
      .pipe(untilDestroyed(this))
      .subscribe((shownColumns: ColumnDefinition[]) => this.shownColumns = shownColumns);
    this.columnService.pageSize
      .pipe(untilDestroyed(this))
      .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
    this.employeeManagementStore.updateListing();
  }

  public onSearchFormType(): void {
    this.initSearchTermListener();
  }

  private initSearchTermListener(): void {
    this.searchTermSubscription.unsubscribe();
    this.searchTermSubscription = this.searchForm.get('terms').valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(() => this.updateOnSearch());
    this.setSearchTerms(false);
  }

  public onSearchFormClick(): void {
    this.employeeManagementStore.updateListing(0, this.paginator.pageSize);
  }

  public openDetails(): void {
    this.displayService.displayTableAndDetails();
  }

  public onPaginateChange(event: PageEvent): void {
    this.employeeManagementStore.updateListing(event.pageIndex, event.pageSize);
  }

  public sortData(sort: Sort): void {
    this.employeeManagementStore.sort = sort;
    this.employeeManagementStore.updateListing();
  }

  public reset(): void {
    this.employeeManagementStore.reset();
    this.initSearchTermListener();
    this.setSort();
  }

  public updateOnSearch(): void {
    this.employeeManagementStore.searchTerms = this.searchForm.get('terms').value;
    this.employeeManagementStore.updateListing(0);
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.employeeManagementStore.updateFilterParams(commands);
    this.employeeManagementStore.updateListing(0);
  }

  private setSearchTerms(emitEvent: boolean = true): void {
    this.searchForm.patchValue({terms: this.employeeManagementStore.searchTerms}, { emitEvent });
  }

  private setSort(): void {
    const { active, direction } = this.employeeManagementStore.sort ?? {};
    this.sort.active = active;
    this.sort.direction = direction;
    this.sort._stateChanges.next();
  }

}
