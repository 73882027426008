import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalUserStorageService } from '../../../../../../../shared/services/local-user-storage.service';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileTransferItem } from '../../../../../contract/mobile-transfer-item';
import { DispositionBulkItem } from '../../../../../shared/disposition-bulk-item';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TorchInfo } from '../../../../../../../shared/components/scanner/torch-info.interface';

declare const EB: any;

@UntilDestroy()
@Component({
  selector: 'bh-mobile-transfer-scanner',
  templateUrl: './mobile-transfer-scanner.component.html',
  styleUrls: ['./mobile-transfer-scanner.component.scss']
})
export class MobileTransferScannerComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  @Output() onScan: EventEmitter<DispositionBulkItem> = new EventEmitter<DispositionBulkItem>();

  @Input() bulkItems: MobileTransferItem[] = [];

  public isEnterprise = false;
  public torchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public torchAvailable = false;
  public searchingByQrCode = false;
  public scanResultString: string;
  public bulkItemFound: boolean;
  public searchResult: DispositionBulkItem;
  public duplicatedEntry: boolean;
  public noAmount: boolean;

  constructor(private bulkItemsStore: BulkItemDatasource,
              private matSnackBar: MatSnackBar,
              private keycloakService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(keycloakService, router, route, routerHistory)
  }

  public ngOnInit(): void {
    if (LocalUserStorageService.getUserValue('isEnterprise', this.getUserUserId()) === 'true') {
      this.isEnterprise = true;
    }

    if (this.isEnterprise) {
      EB.Barcode.enable({}, (code) => {
        this.parseCode(code.data);
      });
    }
  }

  public ngOnDestroy(): void {
  }

  public parseCode(code: string): void {
    if (!this.searchingByQrCode) {
      this.searchingByQrCode = true;
      if (this.duplicatedEntry && this.scanResultString !== code) {
        this.duplicatedEntry = false;
      }
      this.scanResultString = code;
      this.bulkItemsStore.getByScanCode(code)
      .pipe(untilDestroyed(this))
      .subscribe(bulkItem => {
        if (bulkItem) {
          this.bulkItemFound = true;
          this.searchResult = bulkItem;
          this.onScan.emit(bulkItem);
        } else {
          this.bulkItemFound = false;
          this.searchingByQrCode = false;
        }}, err => {
        console.log(err);
        this.searchingByQrCode = false;
      });
    }
  }

  public toggleTorch(): void {
    this.torchEnabled.next(!this.torchEnabled.value);
    this.torchAvailable = !this.torchAvailable;
  }

  public handleTorchUpdate(torch: TorchInfo): void {
    this.torchAvailable = torch.torchAvailable;
  }

}
