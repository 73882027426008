import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { ISelectionTreeOptions, IOptionsList, IOptionPlaceholder } from '../contract/selection-tree-options.interface';
import { FloatLabelStatus } from 'app/modules/dashboard/widgets/contract/float-label-status.interface';
import { EquipmentTypeCountTranslated } from 'app/modules/reports/contract/equipment-type-count';
import { GroupedEquipmentType } from '../contract/grouped-equipment-type.interface';

export function viewOrganisationToOptionsList(options: ViewOrganisation[]): IOptionsList[] {
  return selectionTreeOptionsToOptionsList(convertChildren(options));
}

export function selectionTreeOptionsToOptionsList(options: ISelectionTreeOptions[]): IOptionsList[] {
  let optionsList = convertTreeToArray(options);
  return setOptionIndex(optionsList);
}

export function flatListToOptionList(items: string[]): IOptionsList[] {
  return items.map(option => ({
    selected: false,
    indeterminate: false,
    offset: 0,
    item: { disabled: false, id: option, name: option }
  }));
}

export function convertEquipmentTypeCountToOptionList(options: EquipmentTypeCountTranslated[]): IOptionsList[] {
  let optionsList = equipmentTypeCountToOptionList(options);
  setBaseOptionIndex(optionsList);
  return setOptionIndex(optionsList);
}

export function convertGroupedEquipmentTypesToOptionList(options: GroupedEquipmentType[]): IOptionsList[] {
  return options.map(({ category1: id, category1TranslatedName: name }) => ({
    selected: false,
    indeterminate: false,
    offset: 0,
    item: { id, name, disabled: false },
  }));
}

export function setOptionIndex(optionsList: IOptionsList[]): IOptionsList[] {
  optionsList.forEach((option, index) => option.index = index);
  return optionsList;
}

function setBaseOptionIndex(optionsList: IOptionsList[]): IOptionsList[] {
  optionsList.forEach((option, index) => option.baseIndex = index);
  return optionsList;
}

export function getSelectedFromOptionList(list: IOptionsList[]): string[] {
  return list
    .filter(option => option.selected)
    .map(({ item: { id } }) => id);
}

export function applySelectionToOptionList(list: IOptionsList[], keys: string[]): IOptionsList[] {
  const keysSet = new Set<string>(keys || []);
  return (list || [])
    .map(option => ({  ...option, selected: keysSet.has(option.item.id) }));
}

export function getOptionPlaceholder(list: IOptionsList[]): IOptionPlaceholder {
  const selectedOptions = (list ?? []).filter(item => item?.selected);
  return selectedOptions.length
    ? {
      placeholder: selectedOptions.map(el => el.item.name).join(', '),
      floatLabel: FloatLabelStatus.always,
    }
    : defaultPlaceholder();
}

export function defaultPlaceholder(): IOptionPlaceholder {
  return { placeholder: '', floatLabel: FloatLabelStatus.auto };
}

export function changeParentOption(list: IOptionsList[], option: IOptionsList): void {
  if (option.offset && !option.item.disabled) {
    let parentOption = getParentOption(list, option.index, option.offset);
    changeParentSelectedStatus(list, parentOption.index , parentOption.index + 1);
    changeParentOption(list, parentOption);
  }
}

function convertChildren(options: ViewOrganisation[]): ISelectionTreeOptions[] {
  return options.map(option => convertChild(option));
}

function convertChild(option: ViewOrganisation): ISelectionTreeOptions {
  return {
    children: option.children?.length ? convertChildren(option.children) : [],
    disabled: option.disabled,
    name: option.organisationName,
    id: option.organisationId,
  }
}

function convertTreeToArray(options: ISelectionTreeOptions[], offset: number = 0, result: IOptionsList[] = []): IOptionsList[] {
  options.forEach(option => {
    result.push(convertToOption(option, offset));
    if (option.children.length) {
      convertTreeToArray(option.children, offset + 1, result);
    }
  });
  return result;
}

function convertToOption(option: ISelectionTreeOptions, offset: number): IOptionsList {
  return {
    offset: offset,
    index: 0,
    selected: false,
    indeterminate: false,
    item: {
      name: option.name,
      id: option.id,
      disabled: option.disabled,
    }
  }
}

function equipmentTypeCountToOptionList(types: EquipmentTypeCountTranslated[]): IOptionsList[] {
  const collection: Record<string, { parent: IOptionsList, children: IOptionsList[] }> = {};

  types.forEach(type => {
    if (!collection[type.category1]) {
      collection[type.category1] = { parent: getOptionListItem(null, type.category1NameTranslated, 0), children: [] };
    }
    collection[type.category1].children = [
      ...collection[type.category1].children,
      getOptionListItem(type.equipmentTypeId, type.category2NameTranslated, 1)
    ];
  });

  return Object.values(collection)
    .sort(({ parent: a }, { parent: b }) => sortOptionsListItems(a, b))
    .reduce((acc, { parent, children }) => ([ ...acc, parent, ...(children.sort(sortOptionsListItems)) ]), []);
}

function getOptionListItem(id: string, name: string, offset: number): IOptionsList {
  return {
    selected: false,
    indeterminate: false,
    offset,
    item: { id, name, disabled: false },
  }
}

function sortOptionsListItems(a: IOptionsList, b: IOptionsList): number {
  return (a?.item?.name ?? '').localeCompare(b?.item?.name ?? '');
}

function getParentOption(list: IOptionsList[], index: number, offset: number): IOptionsList {
  return offset > list[index - 1].offset
    ? {...list[index - 1]}
    : getParentOption(list, index - 1, offset);
}

function changeParentSelectedStatus(
  list: IOptionsList[],
  parentIndex: number,
  nextIndex: number,
  childrenList: IOptionsList[] = []
  ): void {
  if (isFirstElValueBiggerThanSecondElValue(list, parentIndex, nextIndex, 'offset')) {
    if (isEveryOptionHasSelectedStatus(childrenList, false)) {
      list[parentIndex].selected = false;
      list[parentIndex].indeterminate = false;
    } else if (isEveryOptionHasSelectedStatus(childrenList, true)) {
      list[parentIndex].selected = true;
      list[parentIndex].indeterminate = false;
    } else {
      list[parentIndex].selected = false;
      list[parentIndex].indeterminate = true;
    }
  } else {
    childrenList.push(list[nextIndex]);
    changeParentSelectedStatus(list, parentIndex, nextIndex + 1, childrenList);
  }
}

function isFirstElValueBiggerThanSecondElValue(list: IOptionsList[], firstElIndex: number, secondElIndex: number, param: string): boolean {
  return list[secondElIndex]
    ? list[firstElIndex][param] >= list[secondElIndex][param]
    : true;
}

function isEveryOptionHasSelectedStatus(list: IOptionsList[], status: boolean): boolean {
  return list.every(el => el.selected === status);
}
