import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { InputConfirmDialogConfig } from './input-confirm-dialog-config';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class InputConfirmDialogService {
  open<InputConfirmDialogComponent, D = InputConfirmDialogConfig, R = any>(
    component: ComponentType<InputConfirmDialogComponent>,
    config: MatDialogConfig<InputConfirmDialogConfig>,
    dialog: MatDialog,
  ): MatDialogRef<InputConfirmDialogComponent, R> {
    return dialog.open(component, config);
  }
}
