import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { UserConfigurationService } from '../../../../../../../shared/services/user-configuration.service';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { EmployeeManagementDatasource } from '../../../../../shared/employee-management.datasource';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { UpdateEmployeeDocumentDescriptionCommand } from '../../../../../contract/update-employee-document-description-command';
import { delay, switchMap } from 'rxjs/operators';
import { SetEmployeeThumbnailCommand } from '../../../../../contract/set-employee-thumbnail-command';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { DeleteEmployeeDocumentCommand } from '../../../../../contract/delete-employee-document-command';
import { BaseEmployeeViewAttachmentComponent } from './base/base-employee-view-attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import { UpdateEmployeeDocumentNameCommand } from '../../../../../contract/update-employee-document-name.command';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NEVER, Observable, of } from 'rxjs';

enum DocumentType {
  COMMON = 'COMMON',
  RENEWAL = 'RENEWAL',
}

@UntilDestroy()
@Component({
  selector: 'bh-employee-view-attachment',
  templateUrl: './employee-view-attachment.component.html',
  styleUrls: ['./employee-view-attachment.component.scss']
})
export class EmployeeViewAttachmentComponent extends BaseEmployeeViewAttachmentComponent implements  OnInit {

  public readonly faSortAlphaDown: IconDefinition = faSortAlphaDown;
  public readonly documentType = DocumentType;
  public waiting = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected employeeManagementStore: EmployeeManagementDatasource,
              protected userConfigurationService: UserConfigurationService,
              protected dialog: MatDialog,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory, employeeManagementStore, userConfigurationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public isDeleteEnabled(): boolean {
    return !this.waiting && this.isEditable;
  }

  public isDeleteEnableDocsRenewal(): boolean {
    return !this.waiting && this.isEditableDocsRenewal;
  }

  public saveFileName(updatedDocument: DocumentLink): void {
    const cmd = new UpdateEmployeeDocumentNameCommand(
      this.employee.employeeId, updatedDocument.documentKey, updatedDocument.fileName);
    this.employeeManagementStore.updateDocumentName(cmd);
  }

  public saveFileNameRenewal(updatedDocument: DocumentLink): void {
    const cmd = new UpdateEmployeeDocumentNameCommand(
      this.employee.employeeId, updatedDocument.documentKey, updatedDocument.fileName);
    this.employeeManagementStore.updateDocumentNameRenewal(cmd);
  }

  public saveDescription(updatedDocument: DocumentLink): void {
    const cmd = new UpdateEmployeeDocumentDescriptionCommand(
      this.employee.employeeId, updatedDocument.documentKey, updatedDocument.fileDescription);
    this.employeeManagementStore.updateDocumentDescription(cmd);
  }

  public saveDescriptionRenewal(updatedDocument: DocumentLink): void {
    const cmd = new UpdateEmployeeDocumentDescriptionCommand(
      this.employee.employeeId, updatedDocument.documentKey, updatedDocument.fileDescription);
    this.employeeManagementStore.updateDocumentDescriptionRenewal(cmd);
  }

  public deleteDocument(objectKey: string, isImage: boolean): void {
    this.deleteDocumentDialog()
      .subscribe(() => {
        this.waiting = true;
        const cmd: DeleteEmployeeDocumentCommand = new DeleteEmployeeDocumentCommand(this.employee.employeeId, objectKey);
        this.employeeManagementStore.deleteDocument(cmd)
          .pipe(delay(environment.DELAY_LONG))
          .subscribe(() => {
            this.waiting = false;
            if (isImage && objectKey === this.employee.defaultImage && this.images.length >= 1) {
              this.setStandardImage(this.images.find(image =>
                  image.documentKey !== this.employee.defaultImage).documentKey);
            } else {
              this.employeeManagementStore.updateListing();
              this.employeeManagementStore.updateCurrentEmployee();
            }
          })
      })
  }

  public deleteDocumentRenewal(objectKey: string): void {
    this.deleteDocumentDialog()
      .subscribe(() => {
        this.waiting = true;
        const cmd: DeleteEmployeeDocumentCommand = new DeleteEmployeeDocumentCommand(this.employee.employeeId, objectKey);
        this.employeeManagementStore.deleteDocumentRenewal(cmd)
          .pipe(delay(environment.DELAY_LONG))
          .subscribe(() => this.waiting = false);
      })
  }

  public changeDocumentsSortParameter(sortParam: string): void {
    this.documentsSortParameter = sortParam;
    this.userConfigurationService.saveDocumentsSortConfiguration(sortParam);
    this.sortDocumentLinkList(this.images, sortParam);
    this.sortDocumentLinkList(this.documents, sortParam);
    this.sortDocumentLinkList(this.documentsRenewal, sortParam);
  }

  public setStandardImage(objectKey: string): void {
    this.waiting = true;
    let cmd: SetEmployeeThumbnailCommand = new SetEmployeeThumbnailCommand(this.employee.employeeId, objectKey);
    this.employeeManagementStore.setThumbnail(cmd).pipe(
      delay(environment.DELAY_SHORT)
    ).subscribe(data => {
        this.employeeManagementStore.updateListing();
        this.employeeManagementStore.updateCurrentEmployee();
        this.waiting = false;
      }, error => {
        this.waiting = false;
      });
  }

  private deleteDocumentDialog(): Observable<dialogResults> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.fileDelete');
    return dialogRef.afterClosed()
      .pipe(switchMap(result => result === dialogResults.YES ? of(result) : NEVER));
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
