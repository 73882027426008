import { VehicleSearch } from '../../contracts/transport/vehicle-search.interface';
import { TransportAssignment } from '../../contracts/transport/transport-assignment.interface';
import { TransportAssignmentPayload } from '../contract/transportation-timeline-scheduler/transport-assignment-payload.class';
import { map, auditTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DragOverPayload } from '../../../../shared/contract/drag-over-payload.interface';
import { DragItemState } from '../../../../shared/enums/drag-item-state.enum';
import { Subject, BehaviorSubject } from 'rxjs';
import { LanguageService } from '../../../../shared/services/language.service';
import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import moment from 'moment';
import { SchedulerHighlighter } from 'app/shared/scheduler/scheduler-highlighter';
import { Section } from 'app/shared/scheduler/section.interface';
import { DragStatus } from 'app/shared/contract/drag-item-state.interface';
import { DragAndDropService } from 'app/shared/services/drag-and-drop.service';
import { TransportTimelineType } from '../enums/transport-timeline-type.enum';
import { TransportTaskEvent } from '../contract/transportation-timeline-scheduler/transport-task-event.class';
import { TransportTimelineDateLimitFactory } from '../contract/transportation-timeline-scheduler/transport-timeline-date-limit-factory.class';
import { TransportTimelineSchedulerHtmlBuilderService } from '../contract/transportation-timeline-scheduler/transport-timeline-scheduler-html-builder.class';
import * as _ from 'lodash';
import { SchedulerMoveHighlighter } from './scheduler-move-highlighter.class';
import { ScrollTrackerService } from 'app/shared/services/scroll-tracker.service';
import { SchedulerEventContentShift } from './scheduler-event-content-shift.class';
import { SchedulerEventPositionTracker } from 'app/shared/scheduler/scheduler-event-position-tracker.class';
import { HorizontalScrollOffset } from 'app/shared/contract/horizontal-scroll-offset.interface';
import { TransportTaskChecker } from './transport-task-checker.service';
import { TimelineViewDateRange } from '../contract/transportation-timeline-scheduler/timeline-view-date-range.interface';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';

interface SchedulerTooltip {
  delay: (showCallback: () => {}, tooltip: SchedulerTooltip, data: [MouseEvent, string], delayTimeMs?: number) => {};
  show: () => {};
  hide: () => {};
}

interface TimelineCellData {
  section: string;
  date: Date;
}

enum SchedulerEventDragMode {
  MOVE = 'move',
  RESIZE = 'resize'
}


@Injectable()
export class TransportationTimelineSchedulerService {
  private timelinePrefixName = 'transport_timeline';
  private matrixCellClassName = 'dhx_matrix_cell';
  private timelineMonthName = `${this.timelinePrefixName}_month`;
  private timelineWeekName = `${this.timelinePrefixName}_week`;
  private timelineDayName = `${this.timelinePrefixName}_day`;
  private highlightPredicateMap = {
    [this.timelineMonthName]: SchedulerHighlighter.getClassDateDay,
    [this.timelineWeekName]: SchedulerHighlighter.getClassDateDay,
    [this.timelineDayName]: SchedulerHighlighter.getClassDateHalfHour,
  };
  private readonly dayViewMinColumnWidthPx = 20;
  private _highlightPredicate = new BehaviorSubject<(date: Date) => string>(SchedulerHighlighter.getClassDateHalfHour);
  private _dateHeaderChanges = new Subject<string>();
  private _equipmentNavigate = new Subject<string>();
  private _draggingStatus = new BehaviorSubject<DragStatus>(null);
  private _mousePositionDate = new BehaviorSubject<Date>(null);
  private _assignmentCreate = new Subject<TransportAssignmentPayload>();
  private _assignmentUpdate = new Subject<TransportAssignmentPayload>();
  private _assignmentClick = new Subject<TransportAssignment>();
  private _dayViewColumnWidthPx = new BehaviorSubject<number>(this.dayViewMinColumnWidthPx);
  private _viewDateRangeChanges = new BehaviorSubject<TimelineViewDateRange>(null);

  public readonly highlightPredicateChange = this._highlightPredicate.asObservable();
  public readonly dateHeaderChanges = this._dateHeaderChanges.asObservable().pipe(distinctUntilChanged());
  public readonly equipmentNavigate = this._equipmentNavigate.asObservable();
  public readonly isDraggingChanges = this._draggingStatus
    .asObservable()
    .pipe(map(dragStatus => dragStatus && this.isStartDragState(dragStatus.state)));
  public readonly mousePositionDateChanges = this._mousePositionDate.asObservable();
  public readonly assignmentCreate = this._assignmentCreate.asObservable();
  public readonly assignmentUpdate = this._assignmentUpdate.asObservable();
  public readonly assignmentClick = this._assignmentClick.asObservable();
  public readonly dayViewColumnWidthPx = this._dayViewColumnWidthPx.asObservable();
  public readonly viewDateRangeChanges = this._viewDateRangeChanges.asObservable().pipe(filter(Boolean), distinctUntilChanged(_.isEqual));

  private get currentDraggingStatus(): DragItemState {
    return this._draggingStatus.value && this._draggingStatus.value.state;
  }

  private get mousePositionDate(): Date {
    return this._mousePositionDate.value;
  }
  private set mousePositionDate(date: Date) {
    this._mousePositionDate.next(date);
  }

  private schedulerContainer: ElementRef;
  private readonly attributeEquipmentId = 'data-equipment-id';
  private readonly attributeTransportType = 'data-transport-task-type';
  private readonly attributeStatus = 'data-status';
  private readonly tooltipDelay = 50;

  private readonly rowHeightPx = 60;
  private readonly eventHeightPx = this.rowHeightPx - 5;
  private readonly rowHeaderHeightPx = 40;
  private readonly sectionWidthPx = 250;

  private lastActionData: TimelineCellData = null;
  private lastDragOverElement: any;
  private readonly dragOverCellClassName = 'drag-over-cell';
  private currentSchedulerEventDragMode: SchedulerEventDragMode = null;

  private sections: Section[] = [];
  private events: TransportTaskEvent[] = [];
  private originalEvent: TransportTaskEvent;
  private vehiclesMap: Map<string, VehicleSearch>;
  private renderer: Renderer2;
  private rendererUnlisteners: (() => void)[] = [];
  private schedulerMoveHighlighter: SchedulerMoveHighlighter;
  private scrollTrackerService: ScrollTrackerService;
  private dayViewVisibleColumnAmount = (19 - 5) * 2;     // 2 columns for every hour from 05:00 to 19:00
  private dayViewColumnsAmountOffset = 5 * 2;            // 2 columns for every hour from 00:00 to 05:00
  private schedulerScrollWrapperSelector = '.dhx_timeline_data_wrapper';
  private schedulerEventPositionTracker: SchedulerEventPositionTracker;
  private hScroll: HorizontalScrollOffset;
  private currentViewStartDate: Date;
  private currentViewEndDate: Date;
  private canSeeEquipment: boolean = this.authService.hasAuthority(Authorities.EQUIPMENT_VIEW);

  constructor(
    private languageService: LanguageService,
    private dndService: DragAndDropService,
    private transportTimelineSchedulerHtmlBuilder: TransportTimelineSchedulerHtmlBuilderService,
    private rendererFactory: RendererFactory2,
    private authService: KeycloakService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public buildViews(schedulerContainer: ElementRef): void {
    this.preInitTimelineScheduler(schedulerContainer);
    this.initTimelineScheduler();
    this.initListeners();
    this.initTemplateEventListeners(this.schedulerContainer);
  }

  public setVehicles(vehicles: VehicleSearch[]): void {
    setTimeout(() => {
      this.vehiclesMap = new Map<string, VehicleSearch>(vehicles.map(vehicle => [vehicle.equipmentId, vehicle]));
      this.sections = vehicles.map(vehicle => this.buildSection(vehicle.equipmentId, vehicle));
      this.schedulerLoadData();
    }, 0);
  }

  public setTransportAssignment(transportAssignments: TransportAssignment[]): void {
    setTimeout(() => {
      this.events = transportAssignments.map(assignment => new TransportTaskEvent(
        this.vehiclesMap.get(assignment.equipmentId),
        assignment
      ));
      this.schedulerLoadData();
    }, 0);
  }

  public addTransportAssignment(assignment: TransportAssignment): void {
    const newEvent = new TransportTaskEvent(this.vehiclesMap.get(assignment.equipmentId), assignment);
    this.events.push(newEvent);
    scheduler.addEvent(newEvent);
    this.updateSchedulerView();
  }

  public updateTransportAssignment(assignment: TransportAssignment): void {
    scheduler.deleteEvent(assignment.assignmentId);
    this.addTransportAssignment(assignment);
  }

  public deleteTransportAssignment(assignmentId: string): void {
    scheduler.deleteEvent(assignmentId);
    this.events = this.events.filter(({ id }) => id !== assignmentId);
    this.updateSchedulerView();
  }

  public revertOriginalEvent(): void {
    if (this.originalEvent) {
      scheduler.deleteEvent(this.originalEvent.id);
      scheduler.addEvent(this.originalEvent);
      this.originalEvent = null;
      this.updateSchedulerView();
    }
  }

  public clearSchedulerData(): void {
    this.events = [];
    this.sections = [];
    if (scheduler.getState().mode) {
      scheduler.clearAll();
    }
    this.hideTooltip();
    this.templateEventUnlisten();
    if (this.scrollTrackerService) {
      this.scrollTrackerService.releaseData();
    }
    if (this.schedulerEventPositionTracker) {
      this.schedulerEventPositionTracker.releaseData();
    }
  }

  private preInitTimelineScheduler(schedulerContainer: ElementRef): void {
    this.clearSchedulerData();
    this.schedulerContainer = schedulerContainer;
    this.calculateDayViewColumnWidthPx();
    this.initScrollTracker();
    this.schedulerMoveHighlighter = new SchedulerMoveHighlighter(this.schedulerContainer);
  }

  private calculateDayViewColumnWidthPx(): void {
    const calculatedDayViewColumnWidthPx =
      Math.round((this.schedulerContainer?.nativeElement?.clientWidth - this.sectionWidthPx) / this.dayViewVisibleColumnAmount);
    this._dayViewColumnWidthPx.next(
      calculatedDayViewColumnWidthPx > this.dayViewMinColumnWidthPx
        ? calculatedDayViewColumnWidthPx
        : this.dayViewMinColumnWidthPx);
  }

  private initScrollTracker(): void {
    const scrollOffset = this._dayViewColumnWidthPx.getValue() * this.dayViewColumnsAmountOffset;
    this.scrollTrackerService = new ScrollTrackerService({ top: 0, left: scrollOffset });
  }

  private initListeners(): void {
    this.dragStatusListener();
    this.mouseMoveExternalListener();
    this.scrollPositionListener();
    this.horizontalScrollOffsetListener();
  }

  private initTemplateEventListeners(template: ElementRef): void {
    this.rendererUnlisteners = [this.initTemplateMouseLeaveListener(template)];
  }

  private templateEventUnlisten(): void {
    this.rendererUnlisteners
      .filter(listener => typeof listener === 'function')
      .forEach(listener => listener());
    this.rendererUnlisteners = [];
  }

  private initTemplateMouseLeaveListener(template: ElementRef): () => void {
    return this.renderer.listen(template.nativeElement, 'mouseleave', () => {
      this.hideTooltip();
    });
  }

  private hideTooltip(): void {
    const tooltip: SchedulerTooltip = scheduler['dhtmlXTooltip'];
    tooltip.hide();
  }

  private buildSection(sectionId: string, vehicle: VehicleSearch): Section {
    return {
      key: sectionId,
      label: this.transportTimelineSchedulerHtmlBuilder.buildSectionTemplate({
        vehicle,
        widthPx: this.sectionWidthPx - 1,
        attrNameTransportType: this.attributeTransportType,
        attrNameEquipmentId: this.canSeeEquipment ? this.attributeEquipmentId : null,
        attrStatusName: this.attributeStatus
      })
    };
  }

  private buildEvent(event: TransportTaskEvent): string {
    if (!(event && event.assignment)) {
      return null;
    }
    const { xStart, xEnd } = this.schedulerEventPositionTracker.getEventPosition(event.id);
    const { left, right, width } = this.isScrollableView()
      ? SchedulerEventContentShift.getCalculatedContentShift(this.hScroll, xStart, xEnd)
      : { left: 0, right: 0, width: xEnd - xStart };
    return this.transportTimelineSchedulerHtmlBuilder.buildEventTemplate(event.assignment, width, left, right);
  }

  private schedulerLoadData(): void {
    scheduler.updateCollection('sections', this.sections);
    this.removeNotExistingEvents();
    scheduler.parse(this.events, 'json');
    this.updateSchedulerView();
  }

  private removeNotExistingEvents(): void {
    const eventIdsSet = new Set(this.events.map(({ id }) => id));
    scheduler.getEvents()
      .filter(({ id }) => !eventIdsSet.has(id))
      .forEach(({ id }) => scheduler.deleteEvent(id));
  }

  private initTimelineScheduler(): void {
    scheduler = Scheduler.getSchedulerInstance();
    this.initSchedulerListeners();
    this.createViews();
    this.schedulerInit(this.getCurrentTimelineToSet());
    this.viewDateRangeChange();
    this.schedulerSetCustomCssClasses();
  }

  private createViews(): void {
    this.schedulerCreateTimelineMonth();
    this.schedulerCreateTimelineWeek();
    this.schedulerCreateTimelineDay();
    this.getCurrentViewDateLimits();
    this.schedulerEventPositionTracker = new SchedulerEventPositionTracker();
  }

  private getCurrentViewDateLimits(): void {
    const { min_date, max_date } = scheduler.getState();
    this.currentViewStartDate = min_date;
    this.currentViewEndDate = max_date;
  }

  private schedulerCreateTimelineMonth(): void {
    scheduler.createTimelineView({
      name: this.timelineMonthName,
      render: 'bar',
      y_property: 'section_id',
      y_unit: scheduler.serverList('sections'),
      x_unit: 'day',
      x_date: '%D <br/> %j',
      x_step: 1,
      x_size: 31,
      section_autoheight: false,
      resize_events: false,
      dy: this.rowHeightPx,
      dx: this.sectionWidthPx,
      event_dy: this.eventHeightPx,
      second_scale: {
        x_unit: 'week',
        x_date: '%W',
      }
    });
    scheduler.date[`${this.timelineMonthName}_start`] = scheduler.date.month_start;
    scheduler.date['add_' + this.timelineMonthName] = this.shiftMonths;
    scheduler.locale.labels[`${this.timelineMonthName}_tab`] = this.translate('general.units.time.month.s');
  }

  private schedulerCreateTimelineWeek(): void {
    scheduler.createTimelineView({
      name: this.timelineWeekName,
      render: 'bar',
      y_property: 'section_id',
      y_unit: scheduler.serverList('sections'),
      x_unit: 'day',
      x_date: '%l <br/> %M %d',
      x_step: 1,
      x_size: 7,
      section_autoheight: false,
      resize_events: false,
      dy: this.rowHeightPx,
      dx: this.sectionWidthPx,
      event_dy: this.eventHeightPx,
      second_scale: {
        x_unit: 'week',
        x_date: `${this.translate('general.units.time.week.calendarWeek')} %W`,
      }
    });
    scheduler.date[`${this.timelineWeekName}_start`] = scheduler.date.week_start;
    scheduler.locale.labels[`${this.timelineWeekName}_tab`] = this.translate('general.units.time.week.s');
  }

  private schedulerCreateTimelineDay(): void {
    scheduler.createTimelineView({
      name: this.timelineDayName,
      render: 'bar',
      y_property: 'section_id',
      y_unit: scheduler.serverList('sections'),
      x_unit: 'minute',
      x_date: '%H:%i',
      x_step: 30,
      x_size: 48,
      section_autoheight: false,
      resize_events: false,
      dy: this.rowHeightPx,
      dx: this.sectionWidthPx,
      event_dy: this.eventHeightPx,
      scrollable: true,
      column_width: this._dayViewColumnWidthPx.getValue(),
      autoscroll: {
        range_x: 50,
        speed_x: 10,
      }
    });
    scheduler.date[`add_${this.timelineDayName}`] = this.shiftDays;
    scheduler.locale.labels[`${this.timelineDayName}_tab`] = this.translate('general.units.time.day.s');
  }

  private shiftDays(date: Date, days: number): Date {
    return date && moment(date).add(days, 'd').toDate();
  }

  private shiftMonths(date: Date, step: number) {
    if (step > 0) {
      step = 1;
    } else if (step < 0) {
      step = -1;
    }
    return scheduler.date.add(date, step, 'month');
  }

  private schedulerInit(defaultShownTimeline: string): void {
    scheduler.config.container_autoresize = false;
    scheduler.xy.scale_height = this.rowHeaderHeightPx;

    // Disable creating new events
    scheduler.config.drag_create = false;
    scheduler.config.dblclick_create = false;

    // Disable dragging of the end date to the left of the start date (same for the start date)
    scheduler.config['timeline_swap_resize'] = false;

    scheduler.templates.event_bar_text = (start: Date, end: Date, event: TransportTaskEvent) => this.buildEvent(event);

    scheduler.init(this.schedulerContainer.nativeElement, this.getCurrentDateToSet(), defaultShownTimeline);
    this.updateTimelineDateHeader();
  }

  private updateSchedulerView(): void {
    this.getCurrentViewDateLimits();
    scheduler.updateView();

    if (this.isScrollableView()) {
      this.scrollTrackerService.subscribeToScrollEvent(
        this.schedulerContainer.nativeElement.querySelector(this.schedulerScrollWrapperSelector));
      this.scrollTrackerService.setLastScrollPositionHorizontal();
    }
  }

  private updateTimelineDateHeader(): void {
    const timelineDateHeader = this.getTimelineDateHeader();
    if (timelineDateHeader) {
      this._dateHeaderChanges.next(timelineDateHeader);
    }
  }

  private getTimelineDateHeader(): string {
    const state = scheduler.getState();
    if (state) {
      const viewName = state.mode;
      const date = moment(state.date).locale(this.getCurrentLocale());
      this.setSession(date.format('DD.MM.YYYY HH:mm'), this.getTimelineType());

      switch (viewName) {
        case this.timelineMonthName:
          return date.format('MMMM YYYY');
        case this.timelineWeekName:
          const minDate = moment(scheduler.getState().min_date);
          const maxDate = moment(scheduler.getState().max_date);
          return minDate.format('DD.MM.YYYY') + ' - ' + maxDate.format('DD.MM.YYYY');
        case this.timelineDayName:
          return date.format('dddd, DD.MM.YYYY');
        default:
          return '';
      }
    }
    return '';
  }

  private schedulerSetCustomCssClasses(): void {
    scheduler.templates.event_class = SchedulerHighlighter.eventTransportationTask;

    // Custom CSS classes for "cells"
    // cells in this context are all table cells of the disposition board, that are not part of the header (which shows
    // the date timeframe, i.e. current month, current week, etc) or the first column (which shows projects)
    scheduler.templates[this.timelineMonthName + '_cell_class'] = SchedulerHighlighter.dayCellTransportation;
    scheduler.templates[this.timelineWeekName + '_cell_class'] = SchedulerHighlighter.dayCellTransportation;
    scheduler.templates[this.timelineDayName + '_cell_class'] = SchedulerHighlighter.hourCell;

    // Custom CSS classes for "scalex"
    // scalex is the header, which shows the date timeframe, i.e. current month, current week, etc
    scheduler.templates[this.timelineMonthName + '_scalex_class'] = SchedulerHighlighter.dayScaleX;
    scheduler.templates[this.timelineWeekName + '_scalex_class'] = SchedulerHighlighter.dayScaleX;
    scheduler.templates[this.timelineDayName + '_scalex_class'] = SchedulerHighlighter.halfHourScaleX;

    // Custom CSS classes for "second_scalex"
    // second_scalex is the header line displaying calendar weeks in the month timeline view
    scheduler.templates[this.timelineMonthName + '_second_scalex_class'] = SchedulerHighlighter.daySecondScaleX;
  }

  private initSchedulerListeners(): void {
    this.schedulerOnEmptyClickListener();
    this.schedulerOnMouseMoveListener();
    this.schedulerOnViewChangeListener();
    this.schedulerOnBeforeEventChangedListener();
    this.schedulerOnEventDragListener();
    this.schedulerOnSchedulerResizeListener();
    this.schedulerOnBeforeViewChangeListener();
    this.schedulerOnBeforeDragListener();
    this.schedulerOnDragEndListener();
    this.schedulerOnClickListener();

    this.schedulerPreventEventDoubleClick();
    this.schedulerPreventCollisionChecking();
    this.schedulerPreventTooltip();
  }

  private schedulerOnEmptyClickListener(): void {
    scheduler.attachEvent('onEmptyClick', (date, event) => this.checkSchedulerEventEquipmentNavigation(event));
  }

  private schedulerOnClickListener(): void {
    scheduler.attachEvent('onClick', (eventId: string, event: MouseEvent) => {
      const transportTask: TransportTaskEvent = scheduler.getEvent(eventId);
      this._assignmentClick.next(transportTask.assignment);
    });
  }

  private schedulerOnMouseMoveListener(): void {
    scheduler.attachEvent('onMouseMove', (id, event) => {
      this.checkSchedulerEventShowTooltip(event);
      this.onMouseMoveTimeline(event);
    });
  }

  private schedulerOnViewChangeListener(): void {
    scheduler.attachEvent('onViewChange', (mode: string, newDate: Date) => {
      this.onTimelineViewChange(mode);
      this.updateSchedulerView();
    });
  }

  private schedulerOnBeforeDragListener(): void {
    scheduler.attachEvent('onBeforeDrag', (eventId: string, mode: SchedulerEventDragMode, event: MouseEvent) => {
      if (mode === SchedulerEventDragMode.MOVE && !this.isTransportTaskMovable(eventId)) {
        return false;
      }

      this.currentSchedulerEventDragMode = mode;
      if (mode !== SchedulerEventDragMode.MOVE) {
        this.schedulerToggleMovingAbility(false);
      }
      return true;
    });
  }

  private isTransportTaskMovable(eventId: string): boolean {
    return !TransportTaskChecker.isInProgressAndBeyond((<TransportTaskEvent>scheduler.getEvent(eventId))?.assignment);
  }

  private schedulerOnDragEndListener(): void {
    scheduler.attachEvent('onDragEnd', () => {
      this.schedulerMoveHighlighter.removeClass();
      this.schedulerToggleMovingAbility(true);
    });
  }

  private schedulerOnEventDragListener(): void {
    scheduler.attachEvent('onEventDrag', (eventId: string, mode: string, event: MouseEvent) => {
      if (mode === SchedulerEventDragMode.MOVE) {
        this.schedulerMoveHighlighter.appendClass(scheduler.getActionData(event).section, eventId);
      }
    });
  }

  private schedulerOnBeforeEventChangedListener(): void {
    scheduler.attachEvent('onBeforeEventChanged', (
      changed: TransportTaskEvent,
      event,
      isNew: boolean,
      original: TransportTaskEvent
    ) => {
      if (!isNew) {
        this.keepOriginalEvent(original);
        const updatedAssignment = this.normalizeTransportTaskForUpdate(changed, original);
        if (updatedAssignment) {
          this.emitUpdateAssignment(updatedAssignment);
        } else {
          this.revertOriginalEvent();
        }
        this.currentSchedulerEventDragMode = null;
      }
      return true;
    });
  }

  private schedulerOnSchedulerResizeListener(): void {
    scheduler.attachEvent('onSchedulerResize', () => {
      this.updateSchedulerView();
    });
  }

  private schedulerOnBeforeViewChangeListener(): void {
    scheduler.attachEvent('onBeforeViewChange', (
      oldTimelineView: string,
      oldDate: Date,
      newTimelineView: string,
      newDate: Date
    ) => {
      this.schedulerPreventDaysOutsideMonthShowing(newTimelineView, newDate);
      return true;
    });
  }

  private schedulerPreventEventDoubleClick(): void {
    scheduler.attachEvent('onDblClick', () => false);
  }

  private schedulerPreventCollisionChecking(): void {
    scheduler.attachEvent('onEventCollision', () => false);
  }

  private schedulerPreventTooltip(): void {
    scheduler.attachEvent('onBeforeTooltip', () => false);
  }

  private scrollPositionListener(): void {
    this.scrollTrackerService.positionLeftChange
      .pipe(auditTime(300))
      .subscribe(() => this.schedulerMoveHighlighter.refreshClassIfInMove());
  }

  private horizontalScrollOffsetListener(): void {
    this.scrollTrackerService.hScrollOffset
      .pipe(distinctUntilChanged(_.isEqual))
      .subscribe(hScroll => {
        this.hScroll = hScroll;
        scheduler
          .getEvents(this.currentViewStartDate, this.currentViewEndDate)
          .forEach(({ id }: { id: string }) => scheduler.updateEvent(id));
      });
  }

  private originalUpdateTimelineSectionCallback = scheduler['_update_timeline_section'];
  // FALSE: prevent moving events to a different sections
  // TRUE: enable an original callback
  private schedulerToggleMovingAbility(enable: boolean): void {
    scheduler.config.drag_move = enable;
    if (enable) {
      scheduler['_update_timeline_section'] = this.originalUpdateTimelineSectionCallback;
    } else {
      const old_timeline_section = scheduler['_update_timeline_section'];
      scheduler['_update_timeline_section'] = function () {
        if (scheduler.getState().drag_mode === SchedulerEventDragMode.MOVE) {
          return old_timeline_section.apply(this, arguments);
        }
      };
    }
  }

  private keepOriginalEvent(event: TransportTaskEvent): void {
    this.originalEvent = event;
  }

  private normalizeTransportTaskForUpdate(
    changed: TransportTaskEvent,
    original: TransportTaskEvent
  ): TransportTaskEvent {
    switch (this.currentSchedulerEventDragMode) {
      case SchedulerEventDragMode.MOVE:
        return this.normalizeDisplacedTransportTaskEvent(changed, original);
      case SchedulerEventDragMode.RESIZE:
        return this.normalizeResizedTransportTaskEvent(changed, original);
      default:
        return null;
    }
  }

  private normalizeResizedTransportTaskEvent(
    changed: TransportTaskEvent,
    original: TransportTaskEvent
  ): TransportTaskEvent {
    const isStartDateChanged = this.isStartDateChanged(changed, original);
    const isEndDateChanged = this.isEndDateChanged(changed, original);
    if (isStartDateChanged || isEndDateChanged) {
      const type = this.getTimelineType();
      const result = { ...changed };
      if (isStartDateChanged) {
        result.start_date = TransportTimelineDateLimitFactory.normalizeStartDate(type, result);
      }
      if (isEndDateChanged) {
        result.end_date = TransportTimelineDateLimitFactory.normalizeEndDate(type, result);
      }
      return result;
    }
    return null;
  }

  private normalizeDisplacedTransportTaskEvent(
    changed: TransportTaskEvent,
    original: TransportTaskEvent
  ): TransportTaskEvent {
    const isMovedToAnotherSection = this.isMovedToAnotherSection(changed, original);
    const isStartDateChanged = this.isStartDateChanged(changed, original);
    const isEndDateChanged = this.isEndDateChanged(changed, original);
    if (isMovedToAnotherSection || isStartDateChanged || isEndDateChanged) {
      const type = this.getTimelineType();
      return TransportTimelineDateLimitFactory.roundDateLimits(type, changed);
    }
    return null;
  }

  private isMovedToAnotherSection(changed: TransportTaskEvent, original: TransportTaskEvent): boolean {
    return changed.section_id !== original.section_id;
  }

  private isStartDateChanged(changed: TransportTaskEvent, original: TransportTaskEvent): boolean {
    return changed.start_date.getTime() !== original.start_date.getTime();
  }

  private isEndDateChanged(changed: TransportTaskEvent, original: TransportTaskEvent): boolean {
    return changed.end_date.getTime() !== original.end_date.getTime();
  }

  private checkSchedulerEventEquipmentNavigation(event: any): void {
    if (event.target && event.target.hasAttribute(this.attributeEquipmentId)) {
      this._equipmentNavigate.next(event.target.getAttribute(this.attributeEquipmentId));
    }
  }

  private checkSchedulerEventShowTooltip(event: any): void {
    if (event.target.hasAttribute(this.attributeTransportType)) {
      const tooltip: SchedulerTooltip = scheduler['dhtmlXTooltip'];
      const status = event.target.getAttribute(this.attributeStatus);
      const transportType = event.target.getAttribute(this.attributeTransportType);
      tooltip.delay(
        tooltip.show,
        tooltip,
        [event, this.transportTimelineSchedulerHtmlBuilder.buildTooltipContent(status, transportType)],
        this.tooltipDelay);
    }
  }

  private onTimelineViewChange(mode: string): void {
    this.updateTimelineDateHeader();
    this.viewDateRangeChange();
    this._highlightPredicate.next(this.highlightPredicateMap[mode]);
  }

  private viewDateRangeChange(): void {
    this._viewDateRangeChanges.next({
      timelineType: this.getTimelineType(),
      date: scheduler.getState().date
    });
  }

  private schedulerPreventDaysOutsideMonthShowing(newTimelineView: string, newDate: Date): void {
    if (newTimelineView === this.timelineMonthName) {
      scheduler['matrix'][this.timelineMonthName].x_size = moment(newDate).daysInMonth();
    }
  }

  private emitUpdateAssignment(transportTaskEvent: TransportTaskEvent): void {
    this._assignmentUpdate.next(this.getUpdateAssignmentPayload(transportTaskEvent));
  }

  private getUpdateAssignmentPayload(
    { section_id, assignment, start_date, end_date }: TransportTaskEvent
  ): TransportAssignmentPayload {
    return new TransportAssignmentPayload(section_id, assignment, start_date, end_date);
  }

  private emitCreateAssignment(cellData: TimelineCellData, assignment: TransportAssignment): void {
    this._assignmentCreate.next(this.getCreationAssignmentPayload(cellData.section, assignment, cellData.date));
  }

  private getCreationAssignmentPayload(
    vehicleId: string,
    assignment: TransportAssignment,
    start: Date
  ): TransportAssignmentPayload {
    const { startDate, endDate } =
    TransportTimelineDateLimitFactory.getDefaultDateRange(
      this.getTimelineType(),
      start,
      assignment.estimatedDuration
    );
    return new TransportAssignmentPayload(vehicleId, assignment, startDate, endDate);
  }

  private getTimelineType(): TransportTimelineType {
    const { mode } = scheduler.getState();
    switch (mode) {
      case this.timelineMonthName:
        return TransportTimelineType.MONTH;
      case this.timelineWeekName:
        return TransportTimelineType.WEEK;
      case this.timelineDayName:
      default:
        return TransportTimelineType.DAY;
    }
  }

  public setSession(headerDate: string, timeTimeLineType: TransportTimelineType) {
    sessionStorage.setItem('transportTimelineMode', timeTimeLineType)
    sessionStorage.setItem('transportTimelineDateHeader', headerDate);
  }

  public getCurrentTimelineToSet(): string {
    const sessionTimeline = sessionStorage.getItem('transportTimelineMode');
    if (sessionTimeline) {
      switch (sessionTimeline) {
        case TransportTimelineType.MONTH:
          return this.timelineMonthName;
        case TransportTimelineType.WEEK:
          return this.timelineWeekName;
        case TransportTimelineType.DAY:
        default:
          return this.timelineDayName;
      }
    }

    return this.timelineDayName;
  }

  public getCurrentDateToSet(): any {
    const sessionDate = sessionStorage.getItem('transportTimelineDateHeader');
    if (sessionDate) {
      return moment(sessionDate, 'DD.MM.YYYY HH:mm').locale(this.getCurrentLocale());
    }
    return new Date();
  }

  public isInDayMode(): boolean {
    return this.getTimelineType() === TransportTimelineType.DAY;
  }

  private isScrollableView(): boolean {
    return this.getTimelineType() === TransportTimelineType.DAY;
  }

  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  // Drag&Drop
  public dropped(assignment: TransportAssignment): void {
    if (this.currentDraggingStatus === DragItemState.CANCELED) {
      return;
    }
    if (this.lastActionData && assignment) {
      this.emitCreateAssignment(this.lastActionData, assignment);
    }
  }

  public exited(): void {
    this.dragDataReset();
  }

  private dragStatusListener(): void {
    this.dndService.dragStatus
    .subscribe(status => {
      this._draggingStatus.next(status);
      if (this.currentDraggingStatus === DragItemState.CANCELED) {
        this.dragDataReset();
      }
    });
  }

  private mouseMoveExternalListener(): void {
    this.dndService.dragOver
      .subscribe(payload => this.onMouseMoveExternal(payload));
  }

  private onMouseMoveTimeline(event: MouseEvent): void {
    if (!this.isDragging()) {
      return;
    }

    if (this.isTimelineCell(<Element>event.target)) {
      this.mouseMoveProcessing({ event, dragOverElement: <Element>event.target });
    } else {
      this.dragDataReset();
    }
  }

  private onMouseMoveExternal(payload: DragOverPayload): void {
    if (!this.isDragging()) {
      return;
    }

    if (payload && payload.dragOverElement) {
      this.mouseMoveProcessing(payload);
    } else {
      this.dragDataReset();
    }
  }

  private isDragging(): boolean {
    return this.isStartDragState(this.currentDraggingStatus);
  }

  private isStartDragState(state: DragItemState): boolean {
    return state === DragItemState.START;
  }

  private isTimelineCell(element: Element): boolean {
    return element.classList.contains(this.matrixCellClassName) ||
      element.parentElement.classList.contains(this.matrixCellClassName);
  }

  private mouseMoveProcessing(payload: DragOverPayload): void {
    this.lastActionData = this.getDateFromCell(payload);
    const isNewCellDate = this.isNewCellDate(this.mousePositionDate, this.lastActionData.date);
    if (isNewCellDate || this.lastDragOverElement !== payload.dragOverElement) {
      this.mousePositionDate = this.lastActionData.date;
      this.setMouseOverCell(payload.dragOverElement);
    }
  }

  private getDateFromCell(payload: DragOverPayload): TimelineCellData {
    const actionData: TimelineCellData = scheduler.getActionData(payload.event);
    const datetime = Number(
      ([...Array.from(payload.dragOverElement.classList), ...Array.from(payload.dragOverElement.parentElement.classList)]
      .find(className => className.startsWith(SchedulerHighlighter.cellDatetime)) || '')
      .split('#')[1]
    );
    return {
      section: actionData.section,
      date: datetime ? new Date(datetime) : actionData.date
    };
  }

  private setMouseOverCell(cell: Element): void {
    if (cell) {
      if (this.lastDragOverElement) {
        this.lastDragOverElement.classList.remove(this.dragOverCellClassName);
      }
      this.lastDragOverElement = cell;
      this.lastDragOverElement.classList.add(this.dragOverCellClassName);
    }
  }

  private isNewCellDate(previous: Date, current: Date): boolean {
    return !previous || previous.getDate() !== current.getDate();
  }

  private dragDataReset(): void {
    this.mousePositionDate = null;
    this.lastActionData = null;
    if (this.lastDragOverElement) {
      this.lastDragOverElement.classList.remove(this.dragOverCellClassName);
    }
    this.lastDragOverElement = null;
  }
}
