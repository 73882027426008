import { TransportStatePipe } from '../pipes/transport-state.pipe';
import { TransportPriorityPipe } from '../pipes/transport-priority.pipe';
import { OrganisationFilterViewConverter } from 'app/shared/contract/filter/filter-converter/organisation-filter-view-converter.class';
import { FilterType } from '../../../../shared/contract/filter/filter-type';
import { Injectable } from '@angular/core';
import { FiltersBaseService } from 'app/shared/contract/filter/filters-base-service.class';
import { TransportFilterRequestParams } from '../contract/request-params/transport-filter-request-params.interface';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { TransportFilterParams } from '../contract/request-params/transport-filter-params.class';
import { Observable, zip } from 'rxjs';
import { TransportFilterCollectionView } from 'app/shared/contract/filter/filter-view/transport-filter-collection/transport-filter-collection-view.interface';
import { TransportService } from './transport.service';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { KeycloakService } from 'app/core/keycloak';
import { map } from 'rxjs/operators';
import { TransportFilterType } from 'app/shared/contract/filter/filter-view/transport-filter-collection/transport-filter-type.enum';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import { CommonFilterViewConverter } from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import { FilterTypes, FilterTypesDisplayNames } from 'app/shared/contract/filter/filter-types.enum';
import { CommonFilterView } from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import { OrganisationFilterView } from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import { ProjectFilterViewConverter } from 'app/shared/contract/filter/filter-converter/project-filter-view-converter.class';
import { ProjectFilterView } from 'app/shared/contract/filter/filter-view/project-filter-view.interface';
import { AssignedVehicleFilterViewConverter } from 'app/shared/contract/filter/filter-converter/assigned-vehicle-view-converter.class';
import { AssignedVehicleFilterView } from 'app/shared/contract/filter/filter-view/assigned-vehicle-filter-view.interface';
import { TransportTaskState } from '../enums/transport-task-status.enum';
import { FilterUtils } from 'app/shared/contract/filter/filter-utils.class';
import { TransportStateFilterViewConverter } from 'app/shared/contract/filter/filter-converter/transport-state-filter-view-converter.class';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { TransportStateHierarchy } from '../contract/transport-state-hierarchy.class';


type TransportFilterItemView = (CommonFilterView | OrganisationFilterView | ProjectFilterView | AssignedVehicleFilterView);

@Injectable()
export class TransportFilterService extends FiltersBaseService<TransportFilterRequestParams> {
  private organisationMap = new Map<string, ViewOrganisation>();
  private isFiltersInit = false;

  constructor(
    private transportService: TransportService,
    private organisationsService: OrganisationsService,
    private authService: KeycloakService,
    private transportPriorityPipe: TransportPriorityPipe,
    private transportStatePipe: TransportStatePipe
  ) {
    super(new TransportFilterParams());
  }

  public getFilterParams(): TransportFilterRequestParams {
    this.checkFilterInit();
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.checkFilterInit();
    const transportStateCommands = commands.filter(({ filterType }) => filterType === FilterTypes.TRANSPORT_STATE);
    const transportStateUpdateSet = new Set(transportStateCommands.map(({ storeName }) => storeName));
    const transportStateCommandsNeedPropagate = transportStateCommands
      .filter(({ storeName, newValue }) => !newValue
        && TransportStateHierarchy.isParentState(storeName)
        && !TransportStateHierarchy.getChildren(storeName).some(childState => transportStateUpdateSet.has(childState)))

    const updateCommands = [
      ...commands,
      ...transportStateCommandsNeedPropagate.reduce((acc, command) => [...acc, ...this.getPropagatedCommandsTransportState(command)], [])
    ];

    super.updateFilterParams(updateCommands);
  }

  private getPropagatedCommandsTransportState(command: UpdateFilterCommand): UpdateFilterCommand[] {
    return [
      command,
      ...TransportStateHierarchy.getChildren(command.storeName).map(state => ({
        filterType: FilterTypes.TRANSPORT_STATE,
        newValue: command.newValue,
        storeName: state
      }))
    ];
  }

  public updateFilters(): void {
    this.checkFilterInit();
    zip(
      this.getFilters(),
      this.getOrganisationMap())
    .subscribe(([filters, organisationMap]) => {
      this.organisationMap = organisationMap;
      FilterUtils.pushMissingParentOrganisationToFilters(filters[TransportFilterType.ORGANISATION], this.organisationMap);
      const convertedFilters = this.convertToFilterTypes(filters);
      this.mergeToResultFilters(convertedFilters);
      this.onFiltersUpdated.emit();
    });
  }

  private checkFilterInit(): void {
    if (!this.isFiltersInit) {
      this.isFiltersInit = true;
      this.setDefaultFilters();
    }
  }

  private setDefaultFilters(): void {
    this.updateFilters();
    this.params.updateParams([
      TransportTaskState.OPEN,
      TransportTaskState.ACTIVE,
      TransportTaskState.DRAFT,
      TransportTaskState.PLANNABLE,
      TransportTaskState.RETURNED,
      TransportTaskState.PLANNED,
      TransportTaskState.IN_PROGRESS,
      TransportTaskState.DONE
    ].map(state => ({
      filterType: FilterTypes.TRANSPORT_STATE,
      storeName: state,
      newValue: true
    })));
  }

  private getFilters(): Observable<TransportFilterCollectionView> {
    return this.transportService.getTransportFilters(this.params.getFilterParams());
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
      .getOrganisationsByCustomerId(this.getUserCustomerId(), false)
      .pipe(map(FilterUtils.convertToOrganisationMap));
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }

  private convertToFilterTypes(transportFilters: TransportFilterCollectionView): FilterType[] {
    return Object.keys(transportFilters)
      .map((key: TransportFilterType) => this.createConverter(key, transportFilters[key] as TransportFilterItemView[]))
      .map(filterConverter => filterConverter && filterConverter.getFilters())
      .filter(Boolean);
  }

  private createConverter(type: TransportFilterType, vehicleFilters: TransportFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case TransportFilterType.ORGANISATION:
        return new OrganisationFilterViewConverter(vehicleFilters as OrganisationFilterView[], this.params, this.organisationMap,
            FilterTypesDisplayNames.ORGANISATION_RELATED_PROJECT);
      case TransportFilterType.ASSIGNED_VEHICLE:
        return new AssignedVehicleFilterViewConverter(
          vehicleFilters as AssignedVehicleFilterView[],
          this.params,
          FilterTypes.TRANSPORT_ASSIGNED_VEHICLE);
      case TransportFilterType.PRIORITY:
        return new CommonFilterViewConverter(
          vehicleFilters as CommonFilterView[],
          this.params,
          FilterTypes.PRIORITY,
          this.transportPriorityPipe);
      case TransportFilterType.RELATED_PROJECT:
        return new ProjectFilterViewConverter(vehicleFilters as ProjectFilterView[], this.params, FilterTypes.TRANSPORT_RELATED_PROJECT);
      case TransportFilterType.STATE:
        return new TransportStateFilterViewConverter(
          vehicleFilters as CommonFilterView[],
          this.params,
          this.transportStatePipe);
      case TransportFilterType.TRANSPORT_TYPE:
        return new CommonFilterViewConverter(vehicleFilters as CommonFilterView[], this.params, FilterTypes.TRANSPORT_TYPE);
    }
  }

}
