import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'bh-transfer-cart-header',
  templateUrl: './transfer-cart-header.component.html',
  styleUrls: ['./transfer-cart-header.component.scss']
})
export class TransferCartHeaderComponent {
  @Input() icon: IconDefinition;
  @Input() lineOne: string;
  @Input() lineTwo: string;

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  public close(): void {
    this.closed.emit();
  }

}
