import { DateRange } from 'app/shared/custom-validators/date.validator';
import { DatesService } from 'app/shared/services/dates.service';
import moment from 'moment';
import { ILazyLoaderParams } from './lazy-loader-params.interface';


export abstract class LazyLoaderParamsBase implements ILazyLoaderParams {
  private pages: Map<string, DateRange> = new Map();
  private readonly dateHashKeyFormat = 'YYYYMMDD';
  protected startDate: Date;
  protected endDate: Date;

  constructor(startDate: Date, endDate?: Date) {
    this.startDate = new Date(DatesService.sameTimeZoneAtStartMonthDateUTC(startDate))
    this.endDate =  new Date(DatesService.sameTimeZoneAtEndMonthDateUTC(endDate || startDate)),
    this.calculateLoadingPages();
  }

  public abstract copy(startDate: Date, endDate?: Date): ILazyLoaderParams;
  public abstract isSameExceptDateRange(params: ILazyLoaderParams): boolean;

  private calculateLoadingPages(): void {
    this.pages = new Map();
    const start = moment(this.startDate);
    const end = moment(this.endDate);

    while (start.isBefore(end)) {
      this.pages.set(
        this.calculateHashKey(start.toDate()),
        { start: start.toDate(), end: new Date(DatesService.sameTimeZoneAtEndMonthDateUTC(start.toDate())) });
      start.add(1, 'month')
    }
  }

  public getStartDate(): Date {
    return this.startDate;
  }

  public getEndDate(): Date {
    return this.endDate;
  }

  public getPages(): Map<string, DateRange> {
    return this.pages;
  }

  public hasPage(key: string): boolean {
    return this.pages.has(key);
  }

  private calculateHashKey(date: Date): string {
    const startDate =  new Date(DatesService.sameTimeZoneAtStartDateUTC(date));
    return moment(startDate).format(this.dateHashKeyFormat);
  }
}
