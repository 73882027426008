import { EquipmentTelematicsDataseriesItem } from 'app/modules/equipment/contract/equipment-telematics-dataseries-item';
import { Utils } from 'app/shared/utils';
import { Chart, LegendItem } from 'chart.js';

export class ChartUtils {
  public static generateLabels(chart: Chart): LegendItem[] {
    const data = chart.data;
    if (data.labels.length && data.datasets.length) {

      return data.labels.map((label: string, i: number) => {
        const meta = chart.getDatasetMeta(0);
        const style = meta.controller.getStyle(i, true);

        return {
          index: i,
          text: `${label}: ${chart.data.datasets[0].data[i]}`,
          fillStyle: style.backgroundColor,
          strokeStyle: style.borderColor,
          hidden: !chart.getDataVisibility(i),
        };
      });
    }
    return [];
  }

  public static getMeasuresPerMultiSeries(
    seriesData: EquipmentTelematicsDataseriesItem[],
    measureNames: string[]
  ): { [key: typeof measureNames[number]]: number[] } {
    if (measureNames?.length > 0) {
      let result: { [key: string]: number[] } = measureNames.reduce((acc, key) => ({ ...acc, [key]: null }), {});

      (seriesData || []).forEach(seriesBunch => {
        const flatFormat: { [key: string]: number } = (seriesBunch?.series ?? [])
          .reduce((acc, { name, value }) => ({
              ...acc,
              [name]: value
            }), {});

        measureNames.forEach(resultKey => {
          result[resultKey] = [
            ...(result[resultKey] || []),
            Utils.round((flatFormat[resultKey] ?? 0), 1)
          ]
        });
      });

      return result;
    }

    return null;
  }
}
