<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="end end" class="m-list-header">
    <div class="m-list-header__item">
      <div class="icon-wrapper start">
        <button mat-button mat-icon-button [attr.aria-label]="'general.map.s'|translate" (click)="navigateToMap()">
          <mat-icon fontSet="icon-map_outline"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="m-list-content-wrapper" fxFlex>
    <mat-list class="m-list-content"
              fxFlexFill
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="false"
              (scrolled)="onScroll()">
      <mat-list-item class="m-list-content__item"
                     *ngFor="let project of projectStore.projects | async"
                     (click)="selectProject(project.projectId)">
        <p matListItemTitle class="line-one">{{project.projectType | uppercase}}</p>
        <p matListItemLine class="line-two">{{project.projectName}}</p>
        <p matListItemLine class="line-three">{{project.projectAddress?.city}}</p>
        <p matListItemLine class="line-four">
          {{project.projectStart}} - {{project.projectEnd | defaultValue: 'general.labels.openEnd'|translate}}
        </p>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
</div>
