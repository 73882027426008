import { BulkItemType } from './bulk-item-type';
import { ViewBulkItemAmount } from './view-bulk-item-amount.interface';
import { ViewProjectAmount } from './view-project-amount.interface';

export class MobileTransferItem {
  public static fromViewProjectAmount(item: ViewProjectAmount, transferAmount: number): MobileTransferItem {
    return new MobileTransferItem(
        item.transferItemId,
        transferAmount,
        item.transferItemName,
        item.bulkItemType,
        false,
        null,
        item.amount,
        item.stockId,
        item.stockName,
        item.projectId
    );
  }

  public static fromViewAmount(item: ViewBulkItemAmount, transferAmount: number): MobileTransferItem {
    return new MobileTransferItem(
        item.bulkItemId ? item.bulkItemId : item.equipmentId,
        transferAmount,
        item.bulkItemName,
        item.bulkItemType,
        item.nonReturnable,
        item.bulkItemCategory,
        item.amountAvailable,
        null,
        null,
        null
    );
  }

  constructor(public transferId: string,
              public amount: number,
              public bulkItemName: string,
              public bulkItemType: BulkItemType,
              public nonReturnable: boolean,
              public bulkItemCategory: string,
              public maxAmount: number,
              public sourceStockId?: string,
              public sourceStockName?: string,
              public sourceProjectId?: string) {
  }
}
