<mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    <input #inputRef matInput autocomplete="off"
           [required]="required"
           [min]="min"
           [max]="max"
           bhInputAllowedCharacters="[0-9./]"
           maxLength="20"
           [matDatepicker]="datePicker"
           [formControl]="control"
           tabindex="-1"
           [errorStateMatcher]="errorMatcher"
           [readonly]="disabled">

    <mat-datepicker-toggle matSuffix [for]="datePicker" [disabled]="disabled"></mat-datepicker-toggle>
    <mat-datepicker-toggle *ngIf="showClearIcon" matSuffix (click)="clear()">
      <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker bhAutoModeSwitch #datePicker></mat-datepicker>
  <mat-error *ngIf="control.invalid && showErrors">{{errorMessage}}</mat-error>
</mat-form-field>
