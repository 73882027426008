import { Chip } from 'app/shared/contract/chip';
import { FilterGroup } from 'app/shared/contract/filter/filter-group';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { FilterValue } from 'app/shared/contract/filter/filter-value';


export abstract class FilterChipsConverterBase {
  protected typeName: FilterTypes;
  protected abstract convertFromFilterType(filterType: FilterType): Chip[]

  public convert(filterType: FilterType): Chip[] {
    this.typeName = filterType.typeName;
    return this.convertFromFilterType(filterType);
  }

  protected createChipsFilterType(filterType: FilterType, options?: Partial<Chip>): Chip[] {
    return (filterType?.groups || [])
      .map(group => this.createChipsFilterGroup(group, options))
      .flat();
  }

  protected createChipsFilterGroup(group: FilterGroup, options?: Partial<Chip>): Chip[] {
    return (group?.filters || [])
      .filter(({ value }) => Boolean(value))
      .map(filter => this.createChipFilterValue(filter, options));
  }

  protected createChipFilterValue(filter: FilterValue, options?: Partial<Chip>): Chip {
    return {
      filterType: this.typeName,
      storeFilter: filter?.storeName,
      displayFilter: filter?.displayName,
      ...(options ?? {})
    };
  }
}
