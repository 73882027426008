import { AssignmentResolveReason } from 'app/shared/enums/assignment-resolve-reason.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { DispositionMessageResolver } from './disposition-message-resolver.class';
import { DispositionMessages } from './disposition-messages.interface';

export class EmployeeDispositionMessageResolver extends DispositionMessageResolver {
  constructor(languageService: LanguageService) {
    super(languageService);
  }

  public getMessages(reasons: AssignmentResolveReason[]): DispositionMessages {
    if (reasons && reasons.length > 0) {
      let confirmationMessage: string;
      if (reasons.length > 1) {
        confirmationMessage = this.translate('modules.disposition.confirmation.message.dispositionConflicts');
      } else {
        const reason = reasons[0];
        switch (reason) {
          case AssignmentResolveReason.HAS_DATE_RANGE_CONFLICT: {
            confirmationMessage =
              this.translate('modules.disposition.confirmation.message.dispositionConflictProjectDateRange');
            break;
          };
          case AssignmentResolveReason.HAS_ASSIGNMENT_COLLISION: {
            confirmationMessage =
              this.translate('modules.disposition.confirmation.message.dispositionConflictEmployeeAssigned');
            break;
          };
          case AssignmentResolveReason.IS_EMPLOYEE_TEAM: {
            confirmationMessage =
              this.translate('modules.disposition.confirmation.message.dispositionConflictEmployeeTeam');
            break;
          }
          default: this.translate('modules.disposition.confirmation.message.dispositionConflicts');
        }
      }

      return { confirmationMessage };
    }
  }
}
