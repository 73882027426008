<div class="transfer-cart-header" fxLayout="row" fxLayoutAlign="space-between center">
<div class="title" fxLayout="row" fxLayoutGap="20px">
  <fa-icon [icon]="icon"></fa-icon>
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5px">
      <span>{{ lineOne }}</span>
    </div>
    <span class="title-text-line-two">{{lineTwo}}</span>
  </div>
</div>
<div class="close" (click)="close()">
  <mat-icon>
    clear
  </mat-icon>
</div>
</div>
