import { TransferItemType } from '../../../../shared/transfer/model/transfer-item-type.enum';
import { LocationType } from '../../../../../../shared/enums/location-type.enum';
import { ProjectToProjectTransferItem } from '../../../../contract/project-to-project-transfer-item';
import { StockToProjectTransferItem } from '../../../../contract/stock-to-project-transfer-item';
import { ProjectToStockTransferItem } from '../../../../contract/project-to-stock-transfer-item';
import { StockToStockTransferItem } from '../../../../contract/stock-to-stock-transfer-item.interface';

export class TransferItemTransferDateRequest {

  constructor(public amountId: string,
              public transferItemType: TransferItemType,
              public sourceLocationId: string,
              public sourceLocationType: LocationType,
              public sourceStockId?: string,
              public transferItemId?: string) {
  }

  public static fromProjectToProjectTransferItem(item: ProjectToProjectTransferItem) {
    return new TransferItemTransferDateRequest(item.amountId,
      item.transferItemType,
      item.sourceProjectId,
      LocationType.PROJECT,
      item.sourceStockId,
      item.transferItemId);
  }

  public static fromProjectToProjectTransferItemInTransferList(item: ProjectToProjectTransferItem) {
    return new TransferItemTransferDateRequest(item.amountId,
      item.transferItemType,
      item.sourceProjectId,
      LocationType.PROJECT,
      item.sourceStockId,
      item.transferItemId);
  }

  public static fromStockToProjectTransferItem(item: StockToProjectTransferItem) {
    return new TransferItemTransferDateRequest(item.amountId,
      item.transferItemType,
      item.sourceStockId,
      LocationType.STOCK,
      item.sourceStockId,
      item.transferItemId);
  }

  public static fromProjectToStockTransferItem(item: ProjectToStockTransferItem) {
    return new TransferItemTransferDateRequest(item.amountId,
      item.transferItemType,
      item.sourceProjectId,
      LocationType.PROJECT,
      item.sourceStockId,
      item.transferItemId);
  }

  public static fromStockToStockTransferItem(item: StockToStockTransferItem) {
    return new TransferItemTransferDateRequest(item.amountId,
      item.transferItemType,
      item.sourceStockId,
      LocationType.STOCK,
      item.sourceStockId,
      item.transferItemId);
  }

}
