import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';

@Component({
  selector: 'bh-map-marker-label',
  templateUrl: './map-marker-label.component.html',
  styleUrls: ['./map-marker-label.component.scss']
})
export class MapMarkerLabelComponent {

  @Input() location: LatLon;
  @Input() label: string;
  @Output() clicked = new EventEmitter();

}
