import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceRuleService } from './maintenance-rule-service';
import { MaintenanceRule } from '../contract/maintenance-rule';

@Injectable()
export class MaintenanceRuleResolve implements Resolve<MaintenanceRule> {
  constructor(private maintenanceRuleService: MaintenanceRuleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
   Observable<MaintenanceRule> | Promise<MaintenanceRule> | MaintenanceRule {
    return this.maintenanceRuleService.getById(route.paramMap.get('id'));
  }
}
