<div *ngIf="!isMobile">

  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" position="end" *ngIf="hasAuthority(authorities.MESSAGE_CENTER_USE)">
      <router-outlet name="sidenav"></router-outlet>
    </mat-sidenav>
    <mat-sidenav-content class="container">

      <div fxFlexFill fxLayout="column" fxLayoutAlign="start none">
        <bh-header class="header" fxFlex="85px"></bh-header>
        <div class="main-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
          <bh-sidenav></bh-sidenav>
          <div class="content" fxFlex>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>

<div class="m-container" *ngIf="isMobile">
  <div fxFlexFill fxLayout="column" fxLayoutAlign="start none">
    <bh-mobile-header fxFlex="40px"></bh-mobile-header>
    <!--main content renders with router outleet inside sidenav content-->
    <bh-mobile-sidenav class="m-main-content"></bh-mobile-sidenav>
  </div>
</div>
