import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[type=number][bhMaxValue],textarea[type=number][bhMaxValue]'
})
export class InputMaxValueDirective {
  @Input() bhMaxValue: number;

  @HostListener('input', ['$event'])
  changeListener(event): void {
    let target = <HTMLInputElement>event.target;
    if (+(target.value) > this.bhMaxValue) {
      target.value = this.bhMaxValue.toString();
      target.dispatchEvent(new InputEvent(event.type));
    }
  }
}
