import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';
import { MapLocationMarker } from '../../../interfaces/map-location-marker.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportItemLocationType } from 'app/modules/transportation/shared/enums/transport-item-location-type.enum';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';

interface TransportLocationType extends LatLonLocation {
  locationType: TransportItemLocationType[];
}

@Component({
  selector: 'bh-map-info-transport-location-type',
  template: `<div *ngFor="let title of titles">{{title}}</div>`,
  styleUrls: ['./map-info-transport-location-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapInfoTransportLocationTypeComponent implements InfoWindowComponent {
  @Input() set value(value: MapLocationMarker<TransportLocationType>) {
    this.titles = (value?.item?.locationType
      ?.map(type => this.translationKeys[type])
      ?.filter(Boolean) || []);
  }

  private translationKeys = {
    [TransportItemLocationType.START]: this.translate('modules.transportation.base.startAddress'),
    [TransportItemLocationType.TARGET]: this.translate('modules.transportation.base.destinationAddress'),
    [TransportItemLocationType.PROJECT]: this.translate('modules.transportation.base.relatedProject'),
  };

  public titles: string[] = [];

  constructor(private languageService: LanguageService) { }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
