import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { FilterType } from './../contract/filter/filter-type';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilterSorting',
  pure: false
})
export class SearchFilterSortingPipe implements PipeTransform {
  private sortOrder = {
    [FilterTypes.ORGANISATION]: 1,
    [FilterTypes.TRANSPORT_STATE]: 2,
    OTHER: 100
  }

  private compareFn(first: FilterType, second: FilterType): number {
    return (this.sortOrder[first.typeName] || this.sortOrder.OTHER) - (this.sortOrder[second.typeName] || this.sortOrder.OTHER);
  }

  public transform(value: FilterType[]): FilterType[] {
    return value ? value.sort((first, second) => this.compareFn(first, second)) : value;
  }

}
