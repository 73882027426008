<div fxLayout="row" fxLayoutAlign="space-between start">
  <bh-title [text]="'general.subEquipm.pl'|translate"></bh-title>
  <span [matTooltip]="'modules.equipment.subequipment.subequipmentInactiveEquipment'|translate"
        [matTooltipDisabled]="isActiveEquipment()">
    <button mat-button mat-raised-button color="primary"
            *ngIf="canAssignSubEquipments"
            (click)="assignSubEquipment()"
            [disabled]="!isActiveEquipment()">
      {{'general.buttons.assign'|translate}}
    </button>
  </span>
</div>

<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="end end"></div>

  <div *ngIf="equipment?.containerId">{{'modules.equipment.subequipment.isSubequipment' | translate}}</div>

  <div *ngIf="!hasSubEquipments && !equipment?.containerId">
    {{'general.labels.noAnyFound'|translate:{value: 'general.subEquipm.pl'|translate} }}</div>

  <mat-card class="sub-equipments" *ngIf="hasSubEquipments">
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel class="expansion-panel" *ngFor="let subEquipment of subEquipments">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span *ngIf="subEquipment.equipmentCustomerSerialNumber">
                {{subEquipment.equipmentCustomerSerialNumber}} /
              </span>
              <span *ngIf="subEquipment.equipmentName">{{subEquipment.equipmentName}}</span>
              <span *ngIf="!subEquipment.equipmentName">{{subEquipment.equipmentModel}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="row" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex="50">
              <p *ngIf="subEquipment.equipmentCustomerSerialNumber">
                <span class="t-bold-light">{{'modules.equipment.base.serialNumber'|translate}}: </span>
                <span class="link" (click)="goToEquipmentDetails(subEquipment.equipmentId)">
                  {{subEquipment.equipmentCustomerSerialNumber}}
                </span>
              </p>
              <p>
                <span class="t-bold-light">{{'modules.equipment.base.model.s'|translate}}: </span>
                <span *ngIf="!subEquipment.equipmentCustomerSerialNumber" class="link"
                      (click)="goToEquipmentDetails(subEquipment.equipmentId)">
                  {{subEquipment.equipmentModel}}
                </span>
                <span *ngIf="subEquipment.equipmentCustomerSerialNumber">
                  {{subEquipment.equipmentModel}}
                </span>
              </p>
              <p>
                <span class="t-bold-light">{{'general.class'|translate}}: </span>
                {{subEquipment.equipmentType?.category2Name | translatableString}}
              </p>
            </div>
            <div fxLayout="column" fxFlex="50">
              <p>
                <span class="t-bold-light">{{'modules.equipment.base.manufacturerSerialNumber'|translate}}: </span>
                {{subEquipment.equipmentSerialNumber}}
              </p>
              <p>
                <span class="t-bold-light">{{'general.manufacturer'|translate}}: </span>
                {{subEquipment.manufacturer?.manufacturerName}}
              </p>
            </div>
          </div>

          <mat-action-row *ngIf="canUnassignSubEquipments">
            <span [matTooltip]="'modules.equipment.subequipment.cannotBeChangedInactiveEquipment'|translate"
                  [matTooltipDisabled]="isActiveEquipment()">
              <button mat-button color="primary"
                      (click)="unAssignSubEquipment(subEquipment.equipmentId)"
                      [disabled]="!isActiveEquipment()">
                {{'modules.equipment.subequipment.removeSubequipments'|translate}}
              </button>
            </span>
          </mat-action-row>

        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</div>
