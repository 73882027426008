import {Component, Input, OnInit} from '@angular/core';
import {Status} from '../../../../modules/equipment/contract/status.interface';

@Component({
  selector: 'bh-equipment-status-badge',
  templateUrl: './equipment-status-badge.component.html',
  styleUrls: ['./equipment-status-badge.component.scss']
})
export class EquipmentStatusBadgeComponent implements OnInit {

  constructor() { }

  @Input() _status: Status;

  ngOnInit(): void {
  }

  public get status(): Status {
    return this._status;
  }
}
