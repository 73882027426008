<div class="select"
     [ngClass]="{ 'select-disabled': disabled }"
     fxLayout="row"
     fxLayoutAlign="space-between center"
     (click)="!disabled && open()"
>
  <bh-transport-state-badge [state]="currentState?.state"></bh-transport-state-badge>
  <div *ngIf="!disabled" class="mat-mdc-select-arrow-wrapper">
    <div class="mat-mdc-select-arrow custom-select-arrow"></div>
  </div>
</div>
<div *ngIf="isOpened && transportTask && !disabled" class="select-list">
  <ng-container *ngFor="let availableStates of currentState.availableStates">
    <div *ngIf="availableStates.show"
         class="select-list__item"
         fxLayout="row"
         fxLayoutAlign="start center"
         [class.select-list__item_disabled]="availableStates.disabled"
         (click)="!availableStates.disabled && setNewState(availableStates.availableState)"
    >
      <span>
        {{ availableStates.description | translate }}
      </span>
      <span class="arrow-margin"> → </span>
      <bh-transport-state-badge [state]="availableStates.availableState"></bh-transport-state-badge>
    </div>
  </ng-container>
</div>
<div *ngIf="isOpened" class="modal-bg" (click)="close()"></div>
