import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EmployeeManagementDatasource } from '../shared/employee-management.datasource';

@Injectable()
export class CurrentEmployeeTabReroute implements CanActivate {
  constructor(private router: Router,
              private employeeManagementStore: EmployeeManagementDatasource) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): false {
    this.router.navigate([state.url, this.employeeManagementStore.currentViewTab]);
    return false;
  }
}
