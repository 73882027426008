import { LanguageService } from './../services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeStatus } from '../../modules/disposition/contract/employee-status.enum';

@Pipe({
  name: 'employeeStatusDisplay'
})
export class EmployeeStatusPipe implements PipeTransform {
  private translateKeys = {
    [EmployeeStatus.ACTIVE]: 'shared.pipes.employeeStatusDisplay.active',
    [EmployeeStatus.INACTIVE]: 'shared.pipes.employeeStatusDisplay.inactive'
  }

  constructor(private languageService: LanguageService) {
  }

  public transform(value: string): string {
    return this.translate(this.translateKeys[value] || 'shared.pipes.employeeStatusDisplay.unknown');
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
