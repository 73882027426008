import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { MapLocationMarker } from '../../interfaces/map-location-marker.interface';
import { MapLoaderService } from '../../services/map-loader.service';
import { LocationToLatLngLiteralPipe } from '../../pipes/location-to-lat-lng-literal.pipe';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';

interface MapLocationsComponentSimpleChanges extends SimpleChanges {
  locations?: SimpleChange;
  movements?: SimpleChange;
  singleInfoWindow?: SimpleChange;
}

@Component({
  selector: 'bh-map-locations',
  templateUrl: './map-locations.component.html',
  styleUrls: ['./map-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLocationsComponent implements OnChanges {
  @Input() locations: MapLocationMarker[];
  @Input() movements: LatLonLocation[];
  @Input() singleInfoWindow = false;
  public isMapAPILoaded = this.mapLoader.isLoaded;
  private readonly defaultMapZoom = 15;
  private map: google.maps.Map;

  constructor(
    private cdr: ChangeDetectorRef,
    private mapLoader: MapLoaderService,
    private positionConverter: LocationToLatLngLiteralPipe
  ) { }

  public ngOnChanges(changes: MapLocationsComponentSimpleChanges): void {
    if(this.map && (changes.locations || changes.movements)) {
      this.refreshPosition();
    }
  }

  public mapInitialized(map: google.maps.Map): void {
    this.map = map;
    this.refreshPosition();
  }

  public markerClick(index: number): void {
    if (this.locations[index]?.infoWindow) {
      if (!this.locations[index].infoWindow.isOpened) {
        if (this.singleInfoWindow) {
          this.closeAllInfoWindows();
        }
        this.locations[index].infoWindow.isOpened = true;
        this.cdr.markForCheck();
      }
    }
  }

  public refreshPosition(): void {
    if (this.isAnyPosition()) {
      this.fitBounds();
    }
  }

  public onCloseInfoWindow(index: number): void {
    if (this.locations[index]?.infoWindow) {
      this.locations[index].infoWindow.isOpened = false;
      this.cdr.markForCheck();
    }
  }

  private fitBounds(): void {
    if (this.isAnyPosition()) {
      const bounds = new google.maps.LatLngBounds();
      [
        ...(this.locations || []),
        ...(this.movements?.length > 1 ? this.movements : [])
      ]
        .map(loc => this.positionConverter.transform(loc?.location))
        .filter(Boolean)
        .forEach(position => bounds.extend(position));

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => this.normalizeZoom());

      this.map.fitBounds(bounds);
    }
  }

  private normalizeZoom(): void {
    this.map.setZoom(this.locations.length > 1 ? this.map.getZoom() - 1 : this.defaultMapZoom);
  }

  private isAnyPosition(): boolean {
    return this.locations?.length > 0 || this.movements?.length > 1;
  }

  private closeAllInfoWindows(): void {
    this.locations.forEach(loc => {
      if(loc?.infoWindow) {
        loc.infoWindow.isOpened = false;
      }
    });
  }

}
