<div fxLayout="row" fxLayoutAlign="end start">
  <div fxLayout="row" fxLayoutAlign="start end" fxFlex="150px">
    <fa-icon class="sort-icon" [icon]="faSortAlphaDown"></fa-icon>
    <mat-form-field class="no-margin-bottom">
      <mat-label>{{'general.labels.sortBy' | translate}}:</mat-label>
      <mat-select [(value)]="documentsSortParameter" (selectionChange)="changeDocumentsSortParameter($event.value)">
        <mat-option [value]="fileName">{{'general.name.s' | translate}}
        </mat-option>
        <mat-option [value]="fileDescription">{{'general.description' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="attachments" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="attachment-type-container">
    <ng-container *ngIf="hasAuthority(authorities.MAINTENANCE_TASK_MANAGE); else noUploadAllowed">
      <bh-subtitle [text]="'general.labels.uploadDocPict' | translate"></bh-subtitle>
      <bh-file-upload
        [itemId]="task?.id"
        [uploaderType]="uploaderType"
        (onFileUploaded)="onFileUploaded($event)"
        (onAllUploaded)="onAllUploaded()">
      </bh-file-upload>
    </ng-container>
    <ng-template #noUploadAllowed>
      <div class="no-upload">{{'shared.attachment.uploadNotAllowed' | translate}}</div>
    </ng-template>
  </div>

  <bh-attachment-list-image-category
    [images]="images"
    [setDefaultEnable]="false"
    [deletable]="isDeleteEnabled | async"
    [visibleDescription]="true"
    [editableName]="isDeleteEnabled | async"
    [editableDescription]="isDescriptionEditEnabled | async"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="saveDescription($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-image-category>

  <bh-attachment-list-category
    [documents]="documents"
    [deletable]="isDeleteEnabled | async"
    [editable]="isDescriptionEditEnabled | async"
    [editableName]="isDeleteEnabled | async"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="saveDescription($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-category>

</div>
