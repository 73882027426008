import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePlay, faCircleQuestion, faCircleStop, faClock, faSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { IconSize } from 'app/modules/osp-ui/components/bh-icon/type/icon-size.type';
import { ActivityStatus } from 'app/shared/contract/activity-status.enum';

@Component({
  selector: 'bh-activity-status',
  templateUrl: './activity-status.component.html',
  styleUrls: ['./activity-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStatusComponent implements OnChanges {

  @Input() status: ActivityStatus = ActivityStatus.UNKNOWN;
  public icon: IconDefinition;
  public iconSize: IconSize = 'lg';

  private statusIcons: Record<ActivityStatus, IconDefinition> = {
    [ActivityStatus.WORKING]: faCirclePlay,
    [ActivityStatus.STOPPED]: faCircleStop,
    [ActivityStatus.IDLE]: faClock,
    [ActivityStatus.AT_PROJECT]: faSquareArrowUpRight,
    [ActivityStatus.UNKNOWN]: faCircleQuestion,
  }

  public ngOnChanges(): void {
    this.update();
  }

  private update(): void {
    this.icon = this.statusIcons[this.status || ActivityStatus.UNKNOWN];
  }
}
