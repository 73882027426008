<div class="select-content" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start none">
  <mat-card *ngFor="let option of options"
            class="option-content"
            [ngClass]="resolveCardClassName(option)"
            [class.active]="isSelected(option)"
            [class.disabled]="readOnly || isDisabledOption(option)"
            [class.wnt-card]="isWNTheme()"
            (click)="!readOnly && !isDisabledOption(option) ? select(option) : null"
            [matTooltip]="getDisabledOptionTooltip(option)"
            [matTooltipDisabled]="!getDisabledOptionTooltip(option)">
    <mat-card-title-group>
    <mat-card-header>
      <div class="option" fxLayout="column" fxLayoutAlign="space-evenly center">
        <fa-icon *ngIf="optionResolver.resolveIcon(option) as icon" fxLayoutAlign="center center" [icon]="icon"></fa-icon>
        <mat-icon *ngIf="optionResolver.resolveMatIcon(option) as icon" fxLayoutAlign="center center" [fontSet]="icon"></mat-icon>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-card-title data-osp-test="card-title">{{resolveOptionName(option)}}</mat-card-title>
    </mat-card-content>
    </mat-card-title-group>
  </mat-card>
</div>

