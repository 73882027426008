<mat-list class="mat-select-list">
  <mat-list-item *ngFor="let listItem of listItems"
    [ngClass]="{
      selected: listItem.selected,
      'selected-wnt': isWNTheme() && listItem.selected
    }" (click)="onClick(listItem)">
    <mat-icon matListItemIcon class="label-icon">check_circle</mat-icon>
    <span *ngIf="!itemTemplate" matListItemTitle class="simple-content">{{listItem?.item}}</span>
    <span *ngIf="itemTemplate" matListItemTitle>
      <ng-container [ngTemplateOutlet]="itemTemplate"
                    [ngTemplateOutletContext]="{ itemId: listItem?.id, item: listItem?.item }">
      </ng-container>
    </span>
  </mat-list-item>
</mat-list>
