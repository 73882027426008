import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { MaintenanceStatusFilterView } from 'app/shared/contract/filter/filter-view/maintenance-status-filter-view.interface';
import { FilterValue } from '../filter-value';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';

export class MaintenanceStatusFilterViewConverter extends BaseFilterViewConverter<MaintenanceStatusFilterView> {
  constructor(
    originalFilters: MaintenanceStatusFilterView[],
    selectedValues: FilterParams
  ) {
    super(FilterTypes.DUE_DATE, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: MaintenanceStatusFilterView): FilterValue {
    return {
      displayName: filterItem.displayName,
      storeName: filterItem.warningLevel,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.warningLevel),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
