import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BhFormFieldErrorModule } from '../common/bh-form-field-error/bh-form-field-error.module';
import { HostControlDirective } from '../../directives/host-control/host-control.directive';
import { BhButtonModule } from '../bh-button/bh-button.module';
import { TrimInputTextModule } from '../../directives/trim-input-text/trim-input-text.module';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { NumberFieldDirective } from './directives/number-field.directive';

@NgModule({
  declarations: [
    InputComponent,
    InputNumberComponent,
    NumberFieldDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    BhFormFieldErrorModule,
    BhButtonModule,
    HostControlDirective,
    TrimInputTextModule,
  ],
  exports: [
    InputComponent,
    InputNumberComponent,
    NumberFieldDirective,
  ],
})
export class BhInputModule { }
