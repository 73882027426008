import { UUID } from 'angular2-uuid';
import { DamageType } from './damage-type';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import {OspDateTime} from './osp-date-time';

export class AddEquipmentDamageCommand {
  lifecycleId: UUID;
  equipmentId: string;
  damageType: DamageType;
  damageDate: OspDateTime;
  equipmentCostId: string;
  invoiceNumberRef: string;
  damageLocation: string;
  documentation: string;
  documents: AttachedDocument[];
}
