<div class="gallery-layout {{currentOptions?.layout}}">

  <bh-gallery-image
    *ngIf="currentOptions?.image"
    [style.height]="getImageHeight()"
    [images]="mediumImages"
    [clickable]="currentOptions?.preview"
    [selectedIndex]="selectedIndex"
    [arrows]="currentOptions?.imageArrows"
    [arrowsAutoHide]="currentOptions?.imageArrowsAutoHide"
    [arrowPrevIcon]="currentOptions?.arrowPrevIcon"
    [arrowNextIcon]="currentOptions?.arrowNextIcon"
    [animation]="currentOptions?.imageAnimation"
    [size]="currentOptions?.imageSize"
    [autoPlay]="currentOptions?.imageAutoPlay"
    [autoPlayInterval]="currentOptions?.imageAutoPlayInterval"
    [autoPlayPauseOnHover]="currentOptions?.imageAutoPlayPauseOnHover"
    [infinityMove]="currentOptions?.imageInfinityMove"
    [bullets]="currentOptions?.imageBullets"
    (onClick)="openPreview($event)"
    (onActiveChange)="selectFromImage($event)">
  </bh-gallery-image>

  <bh-gallery-thumbnails
    *ngIf="currentOptions?.thumbnails"
    [style.marginTop]="getThumbnailsMarginTop()"
    [style.marginBottom]="getThumbnailsMarginBottom()"
    [style.height]="getThumbnailsHeight()"
    [images]="smallImages"
    [links]="currentOptions?.thumbnailsAsLinks ? links : []"
    [labels]="labels"
    [linkTarget]="currentOptions?.linkTarget"
    [selectedIndex]="selectedIndex"
    [columns]="currentOptions?.thumbnailsColumns"
    [rows]="currentOptions?.thumbnailsRows"
    [margin]="currentOptions?.thumbnailMargin"
    [arrows]="currentOptions?.thumbnailsArrows"
    [arrowsAutoHide]="currentOptions?.thumbnailsArrowsAutoHide"
    [arrowPrevIcon]="currentOptions?.arrowPrevIcon"
    [arrowNextIcon]="currentOptions?.arrowNextIcon"
    [clickable]="currentOptions?.image || currentOptions?.preview"
    [size]="currentOptions?.thumbnailSize"
    [moveSize]="currentOptions?.thumbnailsMoveSize"
    [order]="currentOptions?.thumbnailsOrder"
    [remainingCount]="currentOptions?.thumbnailsRemainingCount"
    (onActiveChange)="selectFromThumbnails($event)">
  </bh-gallery-thumbnails>

  <bh-gallery-preview
    [images]="bigImages"
    [arrows]="currentOptions?.previewArrows"
    [arrowsAutoHide]="currentOptions?.previewArrowsAutoHide"
    [fullscreen]="currentOptions?.previewFullscreen"
    [forceFullscreen]="currentOptions?.previewForceFullscreen"
    [closeOnClick]="currentOptions?.previewCloseOnClick"
    [closeOnEsc]="currentOptions?.previewCloseOnEsc"
    [keyboardNavigation]="currentOptions?.previewKeyboardNavigation"
    [animation]="currentOptions?.previewAnimation"
    [autoPlay]="currentOptions?.previewAutoPlay"
    [autoPlayInterval]="currentOptions?.previewAutoPlayInterval"
    [autoPlayPauseOnHover]="currentOptions?.previewAutoPlayPauseOnHover"
    [infinityMove]="currentOptions?.previewInfinityMove"
    [zoom]="currentOptions?.previewZoom"
    [zoomStep]="currentOptions?.previewZoomStep"
    [zoomMax]="currentOptions?.previewZoomMax"
    [zoomMin]="currentOptions?.previewZoomMin"
    [rotate]="currentOptions?.previewRotate"
    [download]="currentOptions?.previewDownload"
    [bullets]="currentOptions?.previewBullets"
    (onClose)="onPreviewClose()"
    (onOpen)="onPreviewOpen()"
    (onActiveChange)="previewSelect($event)"
    [class.gallery-active]="previewEnabled">
  </bh-gallery-preview>
</div>
