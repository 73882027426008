import { ColumnDefinition } from 'app/shared/column-definition';
import { BaseColumnDefinitionService } from 'app/shared/services/base-column-definition.service';
import { LanguageService } from '../../../../shared/services/language.service';
import { Injectable } from '@angular/core';
import { ColumnDefinitionService } from 'app/shared/contract/column-definition-service.interface';
import { ColumnDefinitionKeys } from 'app/shared/enums/column-definition-keys.enum';
import { OperatingHoursPipe } from 'app/shared/pipes/operating-hours.pipe';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { ConstructionYearFormatPipe } from '../../../equipment/shared/pipes/construction-year.pipe';
import { DimensionUnitPipe } from '../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class BulkItemColumnDefinitionService extends BaseColumnDefinitionService implements ColumnDefinitionService {
  private _availableColumns: ColumnDefinition[];

  constructor(
    protected dimensionUnitPipe: DimensionUnitPipe,
    protected dimensionUnitConverterPipe: DimensionUnitConverterPipe,
    protected languageService: LanguageService,
    protected operatingHoursPipe: OperatingHoursPipe,
    protected contactTypeResolverPipe: ContactTypeResolverPipe,
    protected constructionYearPipe: ConstructionYearFormatPipe,
    protected translatableStringPipe: TranslatableStringPipe,
  ) {
    super(languageService,
      dimensionUnitPipe,
      dimensionUnitConverterPipe,
      operatingHoursPipe,
      contactTypeResolverPipe,
      constructionYearPipe,
      translatableStringPipe);
    this.initColumnDefinitions();
  }

  public get availableColumns(): ColumnDefinition[] {
    return this._availableColumns;
  }

  private initColumnDefinitions(): void {
    this._availableColumns = [
      ColumnDefinitionKeys.BULK_ITEM_ICON,
      ColumnDefinitionKeys.BULK_ITEM_NUMBER,
      ColumnDefinitionKeys.BULK_ITEM_NAME,
      ColumnDefinitionKeys.BULK_ITEM_TYPE,
      ColumnDefinitionKeys.BULK_ITEM_CATEGORY,
      ColumnDefinitionKeys.BULK_ITEM_BGL_CODE,
      ColumnDefinitionKeys.BULK_ITEM_BAL_CODE,
      ColumnDefinitionKeys.BULK_ITEM_SCAN_CODE,
      ColumnDefinitionKeys.GENERAL_OPEN_DETAILS,
      ColumnDefinitionKeys.GENERAL_LABELS,
      ColumnDefinitionKeys.BULK_ITEM_COST_CENTER,
      ColumnDefinitionKeys.BULK_ITEM_COST_TYPE,
      ColumnDefinitionKeys.MANUFACTURER_ID,
      ColumnDefinitionKeys.MANUFACTURER_NAME,
      ColumnDefinitionKeys.SUPPLIER_ID,
      ColumnDefinitionKeys.SUPPLIER_NAME,
      ColumnDefinitionKeys.BULK_ITEM_UNIT,
      ColumnDefinitionKeys.BULK_ITEM_WEIGHT]
    .reduce((acc, defKey) => ([ ...acc, this.columnDefinitions[defKey] ]), [])
    .filter(Boolean);
  }
}
