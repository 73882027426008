import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, HostListener } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { DateValidator } from '../../../custom-validators/date.validator';
import { ErrorStateMatcher } from '@angular/material/core';
import { Utils } from 'app/shared/utils';

@Component({
  selector: 'bh-date-picker-input',
  templateUrl: './date-picker-input.component.html'
})
export class DatePickerInputComponent implements AfterViewInit {

  @Input() control: AbstractControl;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() requiredMessageFieldName: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() showErrors = true;
  @Input() disabled = false;
  @Input() showClearIcon = false;
  @Input() errorMatcher: ErrorStateMatcher = new ErrorStateMatcher();
  @Input() errorMessageMatcher: (control: AbstractControl) => string;

  @ViewChild('inputRef', { static: true }) inputRef: ElementRef;

  constructor(private languageService: LanguageService) {
  }

  ngAfterViewInit() {
    if (!this.required) {
      this.control.setValidators([
        this.control.validator,
        DateValidator.isValidDateOrEmpty(this.inputRef, this.languageService)
      ]);
    }
  }

  // Trick for situation:
  // The input does not fire a value change event if an invalid string (not a valid date)
  // was entered manually before, and then this value was deleted
  @HostListener('input') changeListener(): void {
    if (!this.inputRef.nativeElement.value) {
      this.control.updateValueAndValidity();
    }
  }

  public clear(): void {
    this.control.setValue(null);
  }

  public get errorMessage(): string {
    let errorMessage = '';
    if (this.control.hasError('matDatepickerMin')) {
      errorMessage = this.translate('shared.validation.date.cannotBeBefore',
        { value: moment(this.control.errors.matDatepickerMin.min).format('DD.MM.YYYY') });
    }

    if (this.control.hasError('matDatepickerMax')) {
      errorMessage = this.translate('shared.validation.date.cannotBeAfter',
        { value: moment(this.control.errors.matDatepickerMax.max).format('DD.MM.YYYY') });
    }

    if (this.control.hasError('intervalTooLong')) {
      errorMessage = this.control.getError('intervalTooLong');
    }

    if (this.control.hasError('inputDateNotAfter')) {
      errorMessage = this.translate('shared.validation.date.startAfterEnd');
    }

    if (this.control.hasError('futureDateSelected')) {
      errorMessage = this.control.getError('futureDateSelected');
    }

    if (this.control.hasError('isValidDateInstance')) {
      errorMessage = this.control.getError('isValidDateInstance');
    }

    if (this.control.hasError('required') && !this.inputRef.nativeElement.value) {
      errorMessage = this.requiredMessageFieldName
        ? this.translate('shared.validation.required', { value: this.requiredMessageFieldName })
        : this.translate('shared.validation.requiredField');
    }

    if (Utils.isFunction(this.errorMessageMatcher)) {
      errorMessage = this.errorMessageMatcher(this.control) || errorMessage;
    }

    return errorMessage;
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
