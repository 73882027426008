import { Authorities } from 'app/shared/enums/authorities.enum';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateChildAuthority } from 'app/core/guards/can-activate-child-authority';
import { ContentComponent } from '../../shared/components/content-component/content.component';
import { TelematicUnitsAdministrationListComponent } from './telematic-units-administration-list/telematic-units-administration-list.component';
import { CanActivateChildMobileWebApp } from '../../core/guards/can-activate-child-mobile-web-app.guard';

const telematicUnitsAdministrationRoutes: Routes = [
  {
    path: 'telematic-unit-administration',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: TelematicUnitsAdministrationListComponent,
        data: {
          authorities: [Authorities.SUPERADMIN_VIEW, Authorities.PARTNER_TELEMATIC_UNITS_MANAGE],
        },
      },
      {
        path: 'add',
        component: TelematicUnitsAdministrationListComponent,
        data: {
          authorities: [Authorities.SUPERADMIN_VIEW],
          create: true
        }
      },
      {
        path: 'assign',
        component: TelematicUnitsAdministrationListComponent,
        data: {
          authorities: [Authorities.PARTNER_TELEMATIC_UNITS_MANAGE],
          assign: true
        }
      },
    ],
  },
];

export const telematicUnitsAdministrationRouting: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(telematicUnitsAdministrationRoutes);
