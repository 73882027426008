<div class="label-field">
  <bh-label-chips (labelAdded)="addLabel($event)"
                  (labelDeleted)="removeLabel($event)"
                  [customerLabels]="customerLabels | async"
                  [labels]="contactLabels | async"
                  [hasAuthority]="hasAuthority(authorities.CONTACT_MANAGE)">
  </bh-label-chips>
</div>

<div fxLayoutAlign="space-between start" fxLayoutGap="15px">
  <div fxLayout="column" fxFlex="100">
    <bh-title [text]="'general.generalData'|translate"></bh-title>
    <div fxLayout="row" fxLayoutGap="15px">
      <div fxFlex fxLayout="column">
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.identifier'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.identifier">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.type'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.type|contactType">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.name.s'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.name">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.org.s'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.organisationName">
        </mat-form-field>
      </div>
      <div fxFlex fxLayout="column">
        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.contact.base.contactPerson'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.contactPerson">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.contact.base.phoneNumber'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.phoneNumber">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.email'|translate}}</mat-label>
          <input matInput readonly [value]="contact?.email">
        </mat-form-field>
      </div>
    </div>

    <bh-subtitle [text]="'general.location.s'|translate"></bh-subtitle>
    <div fxFlex fxLayout="row" fxLayoutGap="15px">
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.address.zipcode'|translate}}</mat-label>
        <input matInput readonly [value]="contact?.address?.postalCode">
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.address.city'|translate}}</mat-label>
        <input matInput readonly [value]="contact?.address?.city">
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.address.street'|translate}}</mat-label>
        <input matInput readonly [value]="contact?.address?.street">
      </mat-form-field>
      <mat-form-field floatLabel="always">
        <mat-label>{{'general.address.number'|translate}}</mat-label>
        <input matInput readonly [value]="contact?.address?.streetNumber">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" fxFlex="40">
    <bh-map-locations
      class="contact-map"
      [locations]="[contact] | mapMarkerConvertContactView">
    </bh-map-locations>
    <div class="contact-map-jump">
      <bh-map-jump-google
        *ngIf="[contact] | mapMarkerConvertContactView | isValidLocations"
        [lat]="this.contact.location.lat" [lon]="this.contact.location.lon">
      </bh-map-jump-google>
    </div>
  </div>

</div>
