import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceService } from '../../modules/equipment/shared/maintenance.service';

export class ReminderNameInUseValidator {
  static isValid(equipmentId: string, name: string, service: MaintenanceService) {
    if (equipmentId && service && Boolean(name?.trim())) {
      return service.reminderNameInUse(equipmentId, name?.trim())
        .pipe(map(inUse => inUse ? {reminderNameInUse: true} : null));
    } else {
      return of(null);
    }
  }
}
