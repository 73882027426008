import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  ChangeTypeOfUseKey,
  IHeaderCell,
  ISearchProjectTypeOfUseEntry
} from './project-view-change-type-of-use.models';
import { ProjectViewChangeTypeOfUseApiService } from './project-view-change-type-of-use-api.service';
import { Sort } from '@angular/material/sort';
import { LanguageService } from '../../../../../../shared/services/language.service';

@Injectable()
export class ProjectViewChangeTypeOfUseStoreService {
  public sort: Sort;

  private pageSize = 25;
  private page = 0;
  private numberOfPages = 0;

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _periods: BehaviorSubject<ISearchProjectTypeOfUseEntry[]> = new BehaviorSubject<ISearchProjectTypeOfUseEntry[]>([]);
  private _endOfList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _noEntries: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasTypeOfUseWorkflow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly periods: Observable<ISearchProjectTypeOfUseEntry[]> = this._periods.asObservable();
  public readonly endOfList: Observable<boolean> = this._endOfList.asObservable();
  public readonly noEntries: Observable<boolean> = this._noEntries.asObservable();

  public displayedColumns: ChangeTypeOfUseKey[] = [
    ChangeTypeOfUseKey.TransferItemType,
    ChangeTypeOfUseKey.TransferItemInternalNumber,
    ChangeTypeOfUseKey.TransferItemName,
    ChangeTypeOfUseKey.Status,
    ChangeTypeOfUseKey.Reason,
    ChangeTypeOfUseKey.StatusStartDate,
    ChangeTypeOfUseKey.Separator,
    ChangeTypeOfUseKey.StatusEndDate,
    ChangeTypeOfUseKey.Edit,
  ];
  public displayColumnsMap: Map<ChangeTypeOfUseKey, IHeaderCell> = new Map<ChangeTypeOfUseKey, IHeaderCell>([
    [ChangeTypeOfUseKey.TransferItemType,
      {
        label: '',
        sortable: false,
        width: '3%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.TransferItemInternalNumber,
      {
        label: this.translate('general.intNumber.abbr'),
        sortable: false,
        width: '9%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.TransferItemName,
      {
        label: this.translate('general.name.s'),
        sortable: true,
        width: '29%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.Status,
      {
        label: this.translate('general.status'),
        sortable: true,
        width: '18%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.Reason,
      {
        label: this.translate('modules.disposition.projectTypeOfUsePeriods.reason'),
        sortable: true,
        width: '14%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.StatusStartDate,
      {
        label: this.translate('general.from'),
        sortable: true,
        width: '11%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.Separator,
      {
        label: '',
        sortable: false,
        width: '1%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.StatusEndDate,
      {
        label: this.translate('general.to'),
        sortable: true,
        width: '11%',
        show: true,
      }
    ],
    [ChangeTypeOfUseKey.Edit,
      {
        label: '',
        sortable: false,
        width: '4%',
        show: false,
      }
    ],
  ]);

  constructor(private api: ProjectViewChangeTypeOfUseApiService, private languageService: LanguageService) {
  }

  public scroll(projectId: string, initialLoad?: boolean): void {
    if (initialLoad) {
      this.init();
    }
    if (this._loading.value && !initialLoad) {
      return;
    }

    if (this.page === (this.numberOfPages - 1) && !initialLoad) {
      return;
    }

    this._loading.next(true);

    if (this.page < this.numberOfPages && !initialLoad) {
      this.page++;
    }

    this.api.getProjectPeriodsTypeOfUse(projectId, this.page, this.pageSize)
    .subscribe(res => {
      const noElements = res.totalElements < 1;
      this._noEntries.next(noElements);
      this.numberOfPages = res.totalPages;
      this._loading.next(false);
      this._periods.next([...this._periods.value, ...res.content]);
      this._endOfList.next(res.last && !noElements);
    });
  }

  public init(): void {
    this.page = 0;
    this._periods.next([]);
  }

  public updateListing(): void {
  }

  private manualSortString(model, direction): string {
    const sort: Sort = {
      active: model,
      direction: direction,
    };

    return this.sortToSortString(sort);
  }

  private sortToSortString(sort: Sort): string {
    return sort && sort.active && sort.direction
      ? `${sort.active}.raw,${sort.direction}` : null;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }


}
