import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { TranslatableString } from 'app/shared/contract/translatable-string.type';
import { Languages } from 'app/shared/enums/languages.enum';
import { Subscription } from 'rxjs';


type FormType = {
  [key in Languages]?: FormControl<string>
}

@Component({
  selector: 'bh-translation-section',
  templateUrl: './translation-section.component.html',
  styleUrls: ['./translation-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TranslationSectionComponent),
      multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TranslationSectionComponent),
      multi: true
    }
  ]
})
export class TranslationSectionComponent implements ControlValueAccessor, Validator {

  @Input() public set defaultLanguage(language: Languages) {
    if (language === this._defaultLanguage) {
      return;
    }
    this._defaultLanguage = language;
    this.otherLanguages = this._otherLanguages;
    this.reset();
  }

  @Input() public set otherLanguages(languages: Languages[]) {
    const newLanguages = (languages ?? []).filter(lang => lang !== this._defaultLanguage);
    if (newLanguages?.length === this._otherLanguages.length
      && newLanguages.toString() === this._otherLanguages.toString()) {
      return;
    }
    this._otherLanguages = newLanguages;
    this.reset();
  }

  @Input() public labels: TranslatableString = {};

  private get allLanguages(): Languages[] {
    return [this._defaultLanguage, ...this._otherLanguages];
  }

  public form: FormGroup<FormType> = new FormGroup({});
  protected _defaultLanguage: Languages = null;
  protected _otherLanguages: Languages[] = [];
  private originalValue: TranslatableString = {};
  private formChangeSubscription: Subscription;

  private onChange: (value: TranslatableString) => void;
  private onTouched: () => void;

  public writeValue(value: TranslatableString): void {
    this.originalValue = value ?? {};
    this.patchForm();
  }

  public registerOnChange(fn: (value: TranslatableString) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  public validate(_: AbstractControl): ValidationErrors {
    if (this.form.valid) {
      return null;
    }
    return Object.assign({}, ...(this.allLanguages.map(key => this.form.controls[key].errors)));
  }

  public onBlur(): void {
    this.onTouched();
  }

  private reset(): void {
    this.listenerUnsubscribeFormChange();

    this.buildForm();
    this.patchForm();
    this.initListenerFormChange();
    this.onChange?.(this.form.value);
  }

  private patchForm(): void {
    this.form.patchValue(this.originalValue);
  }

  private buildForm(): void {
    const formGroupControls = this.allLanguages
      .map(this.buildFormControl)
      .filter(Boolean)
      .reduce((acc, tuple) => ({ ...acc, ...tuple }), {})

    this.form = new FormGroup(formGroupControls);
  }

  private buildFormControl(language: Languages): { [key in Languages]?: FormControl<string> } {
    if (!language) {
      return null;
    }
    return { [language]: new FormControl<string>('', [Validators.required]) }
  }

  private initListenerFormChange(): void {
    this.listenerUnsubscribeFormChange();
    this.formChangeSubscription = this.form.valueChanges.subscribe(value => {
      this.originalValue = { ...this.originalValue, ...value };
      this.onChange?.(value);
    });
  }

  private listenerUnsubscribeFormChange(): void {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

}
