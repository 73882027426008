import { MatTabGroup, MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TransportListDatasource } from 'app/modules/transportation/shared/services/transport-list.datasource';
import { faHistory, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { LocalUserStorageService } from 'app/shared/services/local-user-storage.service';

@UntilDestroy()
@Component({
  selector: 'bh-transport-view-detail',
  templateUrl: './transport-view-detail.component.html',
  styleUrls: ['./transport-view-detail.component.scss']
})
export class TransportViewDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('detailTabGroup', { static: true }) public detailTabGroup: MatTabGroup;

  public readonly faHistory = faHistory;
  public readonly faInfoCircle = faInfoCircle;
  public transportId: string = null;

  private readonly localStorageKey: string = 'transport-details-tab';

  constructor(
    private route: ActivatedRoute,
    private transportDataSource: TransportListDatasource,
    private localStorageService: LocalUserStorageService
  ) { }

  public ngOnInit(): void {
    this.initListeners();
  }

  public ngAfterViewInit(): void {
    const selectedTab = Number(this.localStorageService.getUserValue(this.localStorageKey));
    if (selectedTab >= 0 && selectedTab <= this.detailTabGroup._tabs.length) {
      this.detailTabGroup.selectedIndex = selectedTab;
    } else {
      this.localStorageService.setUserValue(this.localStorageKey, '0');
    }
  }

  public selectedTabChange(event: MatTabChangeEvent): void {
    this.localStorageService.setUserValue(this.localStorageKey, `${event.index}`);
  }

  private initListeners(): void {
    this.handleRouting();
  }

  private handleRouting(): void {
    this.route.params
    .pipe(untilDestroyed(this))
    .subscribe((params: Params) => {
      if (params.id) {
        this.transportId = params.id;
        this.transportDataSource.changeCurrentTransport(this.transportId);
      }
    });
  }

}
