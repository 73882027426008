import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DimensionSystem } from '../../../../../shared/enums/dimension-system.enum';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';

@UntilDestroy()
@Component({
  selector: 'bh-dimension-unit-switcher',
  templateUrl: './dimension-unit-switcher.component.html',
  styleUrls: ['./dimension-unit-switcher.component.scss']
})
export class DimensionUnitSwitcherComponent implements OnInit, OnDestroy{

  public switcherControl = new UntypedFormControl();
  public options: DimensionSystem[] = [
    DimensionSystem.METRIC,
    DimensionSystem.IMPERIAL,
  ];

  private selectedDimensionUnit: DimensionSystem;

  constructor(private userConfigurationService: UserConfigurationService) { }

  public ngOnInit(): void {
    this.configControl();
  }

  public ngOnDestroy(): void {}

  private configControl(): void {
    let dimensionUnitConfiguration = this.userConfigurationService.getDimensionUnitConfiguration();
    this.selectedDimensionUnit = dimensionUnitConfiguration.dimensionUnit;

    this.switcherControl.setValue(this.selectedDimensionUnit);

    this.switcherControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((dimensionUnit: DimensionSystem) => this.switchDimensionUnit(dimensionUnit));
  }

  public switchDimensionUnit(dimensionUnit: DimensionSystem = this.selectedDimensionUnit): void {
    this.userConfigurationService
      .saveDimensionUnit(dimensionUnit)
      .subscribe(_ => this.selectedDimensionUnit = dimensionUnit);
  }
}
