<mat-card class="entity-creation">
    <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
      <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
        <span>{{'modules.reports.addEditReportJob.automaticReport'|translate}}</span>
      </div>

      <div class="entity-creation-actions" fxFlex="100">
        <button class="create-button"
                mat-raised-button
                bhTimeoutSaveButton
                (throttledClick)="save()"
                [disabled]="!isValid()">
          {{'general.buttons.save'|translate}}
        </button>
        <button mat-raised-button (click)="navigateToView()">{{'general.buttons.cancel'|translate}}</button>
      </div>

      <div class="entity-creation-form" fxFlex="100">
        <div class="entity-creation-form-left" fxFlex="100" fxLayout="column" fxLayoutAlign="start none" fxLayoutGap="15px">
          <form [formGroup]="reportJobForm" novalidate>
            <mat-form-field>
              <mat-label>{{'general.name.s'|translate}}</mat-label>
              <input matInput required
                     [formControlName]="formControlNameReportJobName"
                     [maxlength]="fieldLimit.NAME">
            </mat-form-field>

            <div class="report-create__first-line" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
              <mat-form-field fxFlex="25">
                <mat-label>{{'modules.reports.addEditReportJob.reportType'|translate}}</mat-label>
                <mat-select #rd required
                            [formControlName]="formControlNameReportDefinitionId"
                            (selectionChange)="selectReportDefinition(rd.value)">
                  <mat-option *ngFor="let rd of reportDefinitions" [value]="rd.reportDefinitionId">
                    {{rd.reportDefinitionName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex="25">
                <mat-label>{{'general.interval'|translate}}</mat-label>
                <mat-select [formControlName]="formControlNameReportJobInterval" required>
                  <mat-option *ngFor="let ri of reportJobIntervals" [value]="ri">
                    {{ri | reportJobIntervalDisplay}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>{{'modules.reports.base.receiver'|translate}}</mat-label>
                <mat-select #jobRecipientUserIds
                  multiple
                  required
                  bhSelectOverlayPanelClass
                  [formControlName]="formControlNameReportJobRecipientUserIds">

                  <mat-select-trigger>
                    {{((users | async) | concatCollection : jobRecipientUserIds.value : userKeyField : userFilterFields)}}
                  </mat-select-trigger>

                  <mat-option>
                    <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                    <ngx-mat-select-search #jobRecipientUserSearch
                      [placeholderLabel]="'general.search'|translate"
                      [formControlName]="formControlNameJobRecipientUserSearch">
                    </ngx-mat-select-search>
                  </mat-option>

                  <mat-option class="multiple-option-no-checkbox option-title" disabled>
                    <span fxFlex="0 0 200px">{{'general.firstName'|translate|uppercase}}</span>
                    <span fxFlex="1 1 100%">{{'general.name.s'|translate|uppercase}}</span>
                  </mat-option>

                  <ng-container *ngIf="((users | async) | filterCollection : userFilterFields : jobRecipientUserSearch.value) as list; else noSearchResult">
                    <ng-container *ngIf="list?.length > 0; else noSearchResult">
                      <mat-option *ngFor="let user of list" [value]="user.userId">
                        <span fxFlex="0 0 200px">{{user.firstName}}</span>
                        <span fxFlex="1 1 100%">{{user.name}}</span>
                      </mat-option>
                    </ng-container>
                  </ng-container>

                </mat-select>
              </mat-form-field>
            </div>

            <div class="report-create__normal-line" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
              <div fxLayout="column" fxFlexFill fxLayoutAlign="start stretch" fxLayoutGap="15px">

                <div fxLayout="row" fxLayoutGap="15px">
                  <mat-checkbox *ngIf="isShowAllEquipments" color="primary"
                                (change)="toggleAll($event, formControlNameAllEquipments)"
                                [formControlName]="formControlNameAllEquipments">
                    {{'modules.reports.base.allEquipments'|translate}}
                  </mat-checkbox>

                  <mat-checkbox *ngIf="isShowAllProjects" color="primary"
                                (change)="toggleAll($event, formControlNameAllProjects)"
                                [formControlName]="formControlNameAllProjects">
                    {{'shared.pipes.reportParameterDisplay.allProjects'|translate}}
                  </mat-checkbox>
                </div>

                <!-- EQ TYPES -->
                <div *ngIf="isShowEquipmentTypes" fxLayoutAlign="start center" fxLayoutGap="5px">
                  <mat-form-field>
                    <mat-label>{{'general.equipmType.pl'|translate}}</mat-label>
                    <mat-select multiple bhSelectOverlayPanelClass #typesControl [formControlName]="formControlNameEquipmentTypeIds">
                      <mat-select-trigger>
                        {{
                          equipmentTypes
                          | async
                          | filterBy: ['equipmentTypeId']: typesControl.value
                          | pluck: 'category2Name'
                          | translatableStringArray
                          | join: ', '
                        }}
                      </mat-select-trigger>

                      <mat-option>
                        <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                        <ngx-mat-select-search #typesSearchControl
                          [placeholderLabel]="'general.search'|translate"
                          [formControlName]="formControlNameEquipmentTypeSearch">
                        </ngx-mat-select-search>
                      </mat-option>
                      <ng-container *ngIf="typesSearchControl.value && (equipmentTypeOptions | async | keys).length === 0"
                        [ngTemplateOutlet]="noSearchResult">
                      </ng-container>

                      <mat-option class="multiple-option-no-checkbox option-title" disabled>
                        <span fxFlex="0 0 300px">{{'modules.reports.base.equipmentClass'|translate|uppercase}}</span>
                        <span fxFlex="1 1 100%">{{'modules.reports.base.amountOfEquipments'|translate|uppercase}}</span>
                      </mat-option>

                      <ng-container *ngIf="(equipmentTypeOptions | async) as typeOptions">
                        <mat-optgroup *ngFor="let category1 of typeOptions | keys;"
                          [label]="typeOptions[category1][0].category1Name | translatableString">
                          <mat-option *ngFor="let equipmentType of typeOptions[category1]"
                                      [value]="equipmentType.equipmentTypeId">
                            <span fxFlex="0 0 300px">{{equipmentType.category2Name | translatableString}}</span>
                            <span fxFlex="1 1 100%">{{equipmentType.count}}</span>
                          </mat-option>
                        </mat-optgroup>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- LABELS -->
                <mat-form-field *ngIf="isShowLabels">
                  <mat-label>{{'general.label.pl'|translate}}</mat-label>
                  <mat-select multiple [formControlName]="formControlNameEquipmentLabels" bhSelectOverlayPanelClass>
                    <mat-option *ngFor="let label of labels | async" [value]="label.name">{{label.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- EQUIPMENT -->
                <div *ngIf="isShowEquipments" fxLayoutAlign="start center" fxLayoutGap="5px">
                  <mat-form-field>
                    <mat-label>{{'general.equipm.pl'|translate}}</mat-label>
                    <mat-select #equipmentsControl
                      multiple bhSelectOverlayPanelClass
                      [formControlName]="formControlNameEquipmentIds">
                      <mat-select-trigger>
                        {{
                          equipmentOptions
                          | async
                          | filterBy: ['equipmentId']: equipmentsControl.value
                          | pluck: 'labelFieldName'
                          | join: ', '
                        }}
                      </mat-select-trigger>

                      <mat-option>
                        <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                        <ngx-mat-select-search #equipmentsSearchControl
                          [placeholderLabel]="'general.search'|translate"
                          [formControlName]="formControlNameEquipmentSearch">
                        </ngx-mat-select-search>
                      </mat-option>
                      <ng-container *ngIf="equipmentsSearchControl.value && (equipmentOptions | async).length === 0"
                        [ngTemplateOutlet]="noSearchResult">
                      </ng-container>

                      <mat-option class="multiple-option-no-checkbox option-title" disabled>
                        <span fxFlex="0 0 200px">{{'modules.reports.base.manufacturerSerialNumber'|translate|uppercase}}</span>
                        <span fxFlex="0 0 200px">{{'general.designation'|translate|uppercase}}</span>
                        <span fxFlex="1 1 100%">{{'modules.reports.base.currentlyAssigned'|translate|uppercase}}</span>
                      </mat-option>

                      <mat-option *ngFor="let equipment of (equipmentOptions | async)"
                        [value]="equipment.equipmentId">
                        <span fxFlex="0 0 200px">{{equipment.equipmentSerialNumber}}</span>
                        <span fxFlex="1 1 200px">{{equipment.equipmentName || equipment.equipmentModel}}</span>
                        <span fxFlex="1 1 100%">{{equipment.currentAddress?.city}}
                          <span *ngIf="equipment.currentAddress?.street">, {{equipment.currentAddress?.street}}</span>
                        </span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- ORGANISATIONS -->
                <mat-form-field *ngIf="isShowOrganisations">
                  <mat-label>{{'general.org.pl'|translate}}</mat-label>
                  <mat-select [formControlName]="formControlNameOrganisationIds" multiple bhSelectOverlayPanelClass>
                    <mat-option *ngFor="let organisation of organisations | async" [value]="organisation.organisationId">
                      {{organisation.organisationName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- PROJECTS -->
                <div *ngIf="isShowProjects" fxLayoutAlign="start center" fxLayoutGap="5px">
                  <mat-form-field>
                    <mat-label>{{'general.project.pl'|translate}}</mat-label>
                    <mat-select #projectsControl
                      multiple bhSelectOverlayPanelClass
                      [formControlName]="formControlNameProjectIds">
                      <mat-select-trigger>
                        {{
                          projectOptions
                          | async
                          | filterBy: ['projectId']: projectsControl.value
                          | pluck: 'projectName'
                          | join: ', '
                        }}
                      </mat-select-trigger>

                      <mat-option>
                        <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                        <ngx-mat-select-search #projectsSearchControl
                          [placeholderLabel]="'general.search'|translate"
                          [formControlName]="formControlNameProjectSearch">
                        </ngx-mat-select-search>
                      </mat-option>
                      <ng-container *ngIf="projectsSearchControl.value && (projectOptions | async).length === 0"
                        [ngTemplateOutlet]="noSearchResult">
                      </ng-container>

                      <mat-option class="multiple-option-no-checkbox option-title" disabled>
                        <span fxFlex="0 0 300px">{{'general.projectNumber'|translate|uppercase}}</span>
                        <span fxFlex="1 1 100%">{{'general.designation'|translate|uppercase}}</span>
                      </mat-option>

                      <mat-option *ngFor="let project of projectOptions | async"
                        [value]="project.projectId">
                        <span fxFlex="0 0 300px">{{project.projectNumber}}</span>
                        <span fxFlex="1 1 100%">{{project.projectName}}</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
</mat-card>

<ng-template #noSearchResult>
  <div class="select-option-custom"> {{'general.labels.noSearchResults'|translate}}</div>
</ng-template>
