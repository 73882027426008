import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';

export class ChangeTransportStateCommand {
  transportId: string
  state: TransportTaskState;

  constructor(transportId: string, state: TransportTaskState) {
    this.transportId = transportId;
    this.state = state;
  }
}
