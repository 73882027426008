import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserInfo } from '../contract/userInfo.interface'
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { Observable } from 'rxjs';
import { EmployeeUser } from '../contract/employee-user.interface';

@Injectable()
export class UserInfoService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/users';

  constructor(private http: HttpClient) {
  }

  public getUsers(page = 0, size = 200): Observable<PagedResponse<UserInfo>> {
    const pagingUrl = this.url + '?page=' + page + '&size=' + size;

    return this.http.get<PagedResponse<UserInfo>>(pagingUrl);
  }

  public getEmployeeUser(userId: string): Observable<EmployeeUser> {
    return this.http.get<EmployeeUser>(`${this.url}/employee-user/${userId}`);
  }
}
