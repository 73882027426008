import { SafeResourceUrl } from '@angular/platform-browser';
import { IGalleryImage } from '../interfaces/gallery-image.interface';


export class GalleryImage implements IGalleryImage {
    public small?: string | SafeResourceUrl;
    public medium?: string | SafeResourceUrl;
    public big?: string | SafeResourceUrl;
    public url?: string;
    public label?: string;

    constructor(obj: IGalleryImage) {
      this.small = obj.small;
      this.medium = obj.medium;
      this.big = obj.big;
      this.url = obj.url;
      this.label = obj.label;
    }

    public static isEqual(first: IGalleryImage, second: IGalleryImage): boolean {
      return first === second ||
        (
          first?.small === second?.small &&
          first?.medium === second?.medium &&
          first?.big === second?.big &&
          first?.url === second?.url &&
          first?.label === second?.label
        );
    }
}
