import { Injectable } from '@angular/core';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { SearchStock } from '../../stocks/shared/search-stock';
import { StockService } from './stock.service';
import { ViewStock } from '../../contract/view-stock';

@Injectable()
export class TransferBackToStockDialogService {

  private _stocks: BehaviorSubject<SearchStock[]> = new BehaviorSubject<SearchStock[]>([]);
  public readonly stocks: Observable<SearchStock[]> = this._stocks.asObservable();

  private _targetOrganisationIds = [];
  private _isSelectAllUsed = false;
  private _isClearProjectTransfer = false;
  private _sourceStocks: ViewStock[] = [];
  private _isSelectedStockVisible = true;
  private _searchValue = '';
  private isBrzConnector: boolean | null = null;
  private paginatorParameters = {
    pageIndex: 0,
    pageSize: 100,
    length: 0,
  }

  constructor(private stockService: StockService) { }

  public getStocks(sourceStockIds: string[] = null): void {
    if (this.isBrzConnector === null) {
      this.hasBrzConnector(sourceStockIds);
      return;
    }
    if (this.isBrzConnector && sourceStockIds?.length > 0) {
      this.findOrganisationOfSourceStocksAndGetStocks(sourceStockIds);
      return;
    }
    this.searchTransferStocksObservable().subscribe(res => {
      if (this._isClearProjectTransfer) {
        if (res.totalElements < this.paginatorParameters.pageSize*res.number) {
          this.paginatorParameters.pageIndex = 0;
          this.getStocks();
        } else {
          this.paginatorParameters.pageIndex = res.number;
        }
        this.paginatorParameters.length = res.totalElements;
      }
      this._stocks.next(res.content)
    });
  }

  public setPaginatorIndex(index: number): void {
    this.paginatorParameters.pageIndex = index;
  }

  public getPaginatorIndex(): number {
    return this.paginatorParameters.pageIndex;
  }

  public setPaginatorSize(size: number): void {
    this.paginatorParameters.pageSize = size;
  }

  public setPaginatorSizeAndGetStocks(size: number): void {
    this.paginatorParameters.pageSize = size;
    this.getStocks();
  }

  public getPaginatorLength(): number {
    return this.paginatorParameters.length;
  }

  public setSearchValue(value: string): void {
    this._searchValue = value;
    this.getStocks();
  }

  public getIsSelectAllUsed(): boolean {
    return this._isSelectAllUsed;
  }

  public setIsSelectAllUsed(isSelectAllUsed: boolean) {
    this._isSelectAllUsed = isSelectAllUsed;
  }

  public getIsClearProjectTransfer(): boolean {
    return this._isClearProjectTransfer;
  }

  public setIsClearProjectTransfer(isClearProjectTransfer: boolean): void {
    this._isClearProjectTransfer = isClearProjectTransfer;
  }

  public getSourceStocks(): ViewStock[] {
    return this._sourceStocks;
  }

  public setIsSelectedStockVisible(stockVisible: boolean): void {
    this._isSelectedStockVisible = stockVisible;
  }

  public getIsSelectedStockVisible(): boolean {
    return this._isSelectedStockVisible;
  }

  public clearStocks(): void {
    this._stocks.next([]);
  }

  private searchTransferStocksObservable(): Observable<PagedResponse<SearchStock>> {
    return this.isBrzConnector
      ? this.getSearchTransferStocksObservable(this._targetOrganisationIds)
      : this.getSearchTransferStocksObservable();
  }

  private getSearchTransferStocksObservable(targetOrganisationIds: string[] = []): Observable<PagedResponse<SearchStock>> {
    return this.stockService.searchTransferStocks(this.paginatorParameters.pageIndex.toString(),
                                                  this.paginatorParameters.pageSize.toString(),
                                                  this._searchValue,
                                                  '',
                                                  targetOrganisationIds);
  }

  public findOrganisationOfSourceStocksAndGetStocks(sourceStockIds: string[]) {
    if (this.isBrzConnector === null) {
      this.hasBrzConnector(sourceStockIds)
      return;
    }

    if (this.isBrzConnector) {
      this._sourceStocks = [];

      if (!sourceStockIds.length) {
        this._targetOrganisationIds = [];
        this.getStocks();
      } else {
        let organisationIds = new Set<string>();

        let sourceStockObservables = sourceStockIds.map(sourceStockId =>
          this.stockService.getTransferSourceStockById(sourceStockId));

        forkJoin(sourceStockObservables).subscribe(sourceStocks => {
          sourceStocks.forEach(sourceStock => {
            this._sourceStocks.push(sourceStock);
            if (sourceStock.organisationId) {
              organisationIds.add(sourceStock.organisationId);
            }
          });

          if (organisationIds.size !== this._targetOrganisationIds.length) {
            this._targetOrganisationIds = [...organisationIds];
            this.getStocks();
          }
        })
      }
    }
  }

  private hasBrzConnector(sourceStockIds: string[] = null): void {
    this.stockService.hasBrzConnector().subscribe(isBrzConnector => {
      this.isBrzConnector = isBrzConnector;
      this.getStocks(sourceStockIds);
    });
  }

  public reset(): void {
    this._searchValue = '';
    this._targetOrganisationIds = [];
  }
}
