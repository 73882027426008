<div class="m-landingpage-wrapper" fxFlexFill>
  <mat-card class="m-landingpage">
    <mat-card-content class="m-landingpage-content"
                      fxLayout="row" fxLayoutAlign="space-between start">
      <ng-container *ngFor="let item of items | async; let i = index">
        <div class="m-landingpage-item"
             [ngClass]="{'disabled': !item.enabled}"
             (click)="item.enabled && navigateTo(item.path)"
             fxFlex="50" fxLayout="column" fxLayoutAlign="{{calcLayoutAlign(i)}}">
          <mat-icon fontSet="{{item.icon}}" *ngIf="item.customIcon"></mat-icon>
          <mat-icon *ngIf="!item.customIcon">{{item.icon}}</mat-icon>
          <span class="title">{{item.title}}</span>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
