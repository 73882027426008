<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
  <div fxFlex="0 0 150px">
    <mat-form-field>
      <mat-select [formControl]="switcherControl">
        <mat-option *ngFor="let lang of options | async" [value]="lang.value">
          {{lang.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="isDevCurrentLanguage" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
    <mat-form-field>
      <mat-label>{{'modules.userrole.userSettings.devPrefixPlaceholder'|translate}}</mat-label>
      <input matInput maxLength="1"
             [formControl]="devPrefixControl"
             [matTooltip]="'modules.userrole.userSettings.devPrefixTooltip'|translate">
      <mat-error *ngIf="devPrefixControl.hasError('pattern')">
        {{'modules.userrole.userSettings.devPrefixValidationForbiddenCharacter'|translate}}
      </mat-error>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="switchLanguage()" [disabled]="devPrefixControl.invalid">
      {{'general.buttons.accept'|translate}}
    </button>
  </div>
</div>
