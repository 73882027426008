import { Component, Input, OnInit } from '@angular/core';
import { AssignmentResolveReason } from 'app/shared/enums/assignment-resolve-reason.enum';
import { DispositionMessageResolver } from './disposition-message-resolver/disposition-message-resolver.class';

@Component({
  selector: 'bh-disposition-assign-confirmation-message',
  templateUrl: './disposition-assign-confirmation-message.component.html',
  styleUrls: ['./disposition-assign-confirmation-message.component.scss']
})
export class DispositionAssignConfirmationMessageComponent implements OnInit {
  @Input() resolveReasons: AssignmentResolveReason[];
  @Input() messageResolver: DispositionMessageResolver;
  public confirmationMessage: string;
  public warningMessage: string;

  constructor() { }

  public ngOnInit(): void {
    if (this.messageResolver) {
      const messages = this.messageResolver.getMessages(this.resolveReasons);
      this.confirmationMessage = messages.confirmationMessage;
      this.warningMessage = messages.warningMessage;
    }
  }

}
