import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Injectable()
export class DecimalNumberValidityService {
  public readonly regExp = new RegExp('^[0-9]*(?:\.[0-9]{1,3})?$');

  constructor() { }
  public setInputDecimalDigitsNumberValidity(value: string, control: UntypedFormControl | AbstractControl): void {
    if (!this.regExp.test(value)) {
      control.setErrors({ ...(control.errors || {}), 'decimalDigitsNumberInvalid': true });
      control.markAsDirty();
      control.markAsTouched();
    }
  }
}
