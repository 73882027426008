<mat-card class="entity-creation" fxFlexFill>
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
    <div class="entity-creation-title" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>developer_board</mat-icon>
      <span *ngIf="!isEditMode">{{'modules.equipment.invoice.logCostsInvoice'|translate}}</span>
      <span *ngIf="isEditMode">{{'modules.equipment.invoice.editCostsInvoice'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        <span *ngIf="!isEditMode">{{'general.buttons.add'|translate}}</span>
        <span *ngIf="isEditMode">{{'general.buttons.save'|translate}}</span> |
        <mat-icon *ngIf="!isEditMode">add_circle</mat-icon>
        <mat-icon *ngIf="isEditMode">save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">
        <span *ngIf="!isEditMode">{{'general.buttons.cancel'|translate}}</span>
        <span *ngIf="isEditMode">{{'general.buttons.toOverview'|translate}}</span>
      </button>
      <button mat-raised-button (click)="resetForm()" *ngIf="!isEditMode">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form invoice-form-wrapper" fxFlex>
      <form [formGroup]="invoiceAddForm" novalidate fxLayout="row" fxLayoutAlign="space-between stretch">
        <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start" fxFlexFill>
          <div class="entity-creation-form-left border-right" fxFlex="30">
            <div class="card-group-title">{{'modules.equipment.base.invoiceInfo'|translate}}</div>
            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <mat-form-field>
                <mat-label>{{'modules.equipment.invoice.invoiceDate'|translate}}</mat-label>
                <input matInput required
                       formControlName="invoiceDate" autocomplete="off"
                       (dateChange)="changePerformanceDate($event)"
                       [matDatepicker]="invoiceDatePicker">
                <mat-datepicker-toggle matSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                <mat-error *ngIf="invoiceDate.hasError('required')">
                  {{'shared.validation.required'|translate:{value:'modules.equipment.invoice.invoiceDate'|translate} }}
                </mat-error>
              </mat-form-field>
              <mat-datepicker #invoiceDatePicker></mat-datepicker>

              <mat-form-field>
                <mat-label>{{'modules.equipment.invoice.performanceDate'|translate}}</mat-label>
                <input matInput
                       formControlName="performanceDate" autocomplete="off"
                       [matDatepicker]="performanceDatePicker">
                <mat-datepicker-toggle matSuffix [for]="performanceDatePicker"></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker #performanceDatePicker></mat-datepicker>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.performanceType'|translate}}</mat-label>
                <mat-select required
                            formControlName="performanceType">
                  <mat-option *ngFor="let pt of performanceTypes" [value]="pt">{{pt|performanceType}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.invoiceNumber'|translate}}</mat-label>
                <input matInput bhRemoveWhitespaces
                       formControlName="invoiceNumber"
                       [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
                <mat-error *ngIf="invoiceNumber.hasError('invoiceNumberTaken')">
                  {{'modules.equipment.invoice.invoiceNumberExistsForEquipment'|translate}}
                </mat-error>
                <mat-error *ngIf="invoiceNumber.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                  {{'shared.validation.string.trimSpaces'|translate}}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.invoiceAmount'|translate}}</mat-label>
                <input type="number" matInput required
                       formControlName="invoiceAmount"
                       [bhMaxValue]="fieldLimit.TEN_DIGITS_DECIMAL"
                       [bhMinValue]="fieldLimit.TEN_DIGITS_DECIMAL * -1">
                <mat-error *ngIf="invoiceAmount.hasError('required')">
                  {{'shared.validation.required'|translate:{value:'modules.equipment.base.invoiceAmount'|translate} }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'general.documentation'|translate}}</mat-label>
                <textarea matInput
                          formControlName="documentation"
                          [maxlength]="fieldLimit.LONG_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="entity-creation-form-left border-right" fxFlex="30">
            <div class="card-group-title">{{'modules.equipment.base.extendedInvoiceInfo'|translate}}</div>
            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>

              <div fxLayoutAlign="space-between start">
                <mat-form-field>
                  <mat-label>{{'general.units.time.hour.pl'|translate}}</mat-label>
                  <span class="input-prefix" matPrefix>{{'modules.equipment.base.timeRequirement'|translate}}</span>
                  <input type="number" matInput
                         formControlName="performanceHours"
                         bhMaxLength="3"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field fxFlex="110px">
                  <mat-label>{{'general.units.time.minute.pl'|translate}}</mat-label>
                  <input type="number" matInput
                         formControlName="performanceMinutes"
                         bhMaxLength="2"
                         autocomplete="off">
                </mat-form-field>
              </div>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.callOutFee'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="callOutFee"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.mileageCovered'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
                <input type="number" matInput
                       formControlName="drive"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.allowance'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="allowance"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.personnelCosts'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="personnelCosts"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.travelCosts'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="travelCosts"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.materialCosts'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="materialCosts"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.deliveryAndFreightCosts'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="freightCharges"
                       bhMaxLength="10">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.supplierServiceProvider'|translate}}</mat-label>
                <input matInput bhRemoveWhitespaces
                       formControlName="serviceContractor"
                       [maxlength]="fieldLimit.SHORT_TEXT">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.equipment.base.serviceStaff'|translate}}</mat-label>
                <input matInput bhRemoveWhitespaces
                       formControlName="serviceWorker"
                       [maxlength]="fieldLimit.SHORT_TEXT">
              </mat-form-field>
            </div>
          </div>

          <div class="entity-creation-form-left" fxFlex>
            <div class="card-group-title">{{'general.document.pl'|translate}}</div>
            <div fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="space-between stretch">
              <div>
                <bh-file-upload
                  [itemId]="equipmentIdParam"
                  uploaderType="EQUIPMENT_INVOICE"
                  (onFileUploaded)="onFileUploaded($event)">
                </bh-file-upload>
              </div>

              <bh-file *ngFor="let document of documents" [file]="document" (fileRemove)="removeDocument(document)"></bh-file>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
