import { FilterType, RenderStrategy } from 'app/shared/contract/filter/filter-type';
import { FilterChipsConverterCommon } from './converters/filter-chips-converter-common.class';
import { FilterChipsConverterEquipmentType } from './converters/filter-chips-converter-equipment-type.class';
import { FilterChipsConverterOrganisation } from './converters/filter-chips-converter-organisation.class';
import { FilterChipsConverterGroupedTree } from './converters/filter-chips-converter-grouped-tree.class';
import { Chip } from 'app/shared/contract/chip';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';

export class SearchFilterChipsFactory {
  private chipsConverterCommon = new FilterChipsConverterCommon();
  private chipsConverterOrganisation = new FilterChipsConverterOrganisation();
  private chipsConverterGroupedTree = new FilterChipsConverterGroupedTree();
  private chipsConverterEquipmentType = new FilterChipsConverterEquipmentType();
  private methodsMap = {
    [RenderStrategy.LIST]: (filterType: FilterType) => this.convertFlatListFilters(filterType),
    [RenderStrategy.RELATIONS_TREE]: (filterType: FilterType) => this.convertRelationTreeFilters(filterType),
    [RenderStrategy.GROUPED_TREE]: (filterType: FilterType) => this.convertGroupedTreeFilters(filterType),
  }

  public convert(filterTypes: FilterType[]): Chip[] {
    return filterTypes
      .map(filterType =>
        this.methodsMap[filterType?.renderStrategy]
        ? this.methodsMap[filterType.renderStrategy](filterType)
        : this.methodsMap[RenderStrategy.LIST](filterType))
      .flat();
  }

  private convertFlatListFilters(filterType: FilterType): Chip[] {
    return this.chipsConverterCommon.convert(filterType);
  }

  private convertRelationTreeFilters(filterType: FilterType): Chip[] {
    if (filterType?.typeName === FilterTypes.ORGANISATION) {
      return this.chipsConverterOrganisation.convert(filterType);
    }
    return this.chipsConverterCommon.convert(filterType);
  }

  private convertGroupedTreeFilters(filterType: FilterType): Chip[] {
    if (filterType?.typeName === FilterTypes.EQUIPMENT_CLASS) {
      return this.chipsConverterEquipmentType.convert(filterType);
    }
    return this.chipsConverterGroupedTree.convert(filterType);
  }
}
