import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CreateEquipmentTypeCommand } from '../contract/create-equipment-type-command';
import { ViewEquipmentType } from '../contract/view-equipment-type.interface';
import { UpdateEquipmentTypeCommand } from '../contract/update-equipment-type-command';
import { DeleteEquipmentTypeCommand } from '../contract/delete-equipment-type-command';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { KeycloakService } from '../../../core/keycloak';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { ImageLink } from '../contract/image-link.interface';
import { DeleteEquipmentTypeThumbnailCommand } from '../contract/delete-equipment-type-thumbnail.command';
import { DeleteEquipmentTypeImageCommand } from '../contract/delete-equipment-type-image.command';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { getHttpParameters } from '../../../shared/utils';
import { FilteredEquipmentTypesParams } from './equipmenttypes.service.interfaces';
import { UploadEquipmentTypeThumbnailCommand } from '../contract/upload-equipment-type-thumbnail-command';
import { UploadEquipmentTypeImageCommand } from '../contract/upload-equipment-type-image-command';
import { TranslatableString } from '../../../shared/contract/translatable-string.type';

export type EquipmentTypeCategory = {
  category1: string;
  category1Name: TranslatableString;
}

@Injectable()
export class EquipmentTypesService {

  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/equipmenttypes';
  private readonly fileUploadUrl = environment.APP_FILE_SERVICE_BASE_URL + '/api/v1/file/upload';

  constructor(private http: HttpClient, private keycloak: KeycloakService) {
  }

  public getEquipmentTypes(startIndex = 0, pageSize = 2000): Observable<PagedResponse<ViewEquipmentType>> {
    const pagingUrl = this.url + '/?page=' + startIndex + '&size=' + pageSize;
    return this.http.get<PagedResponse<ViewEquipmentType>>(pagingUrl);
  }

  public getFilteredEquipmentTypes(params: FilteredEquipmentTypesParams): Observable<PagedResponse<ViewEquipmentType>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<ViewEquipmentType>>(`${this.url}/find-by-categories`, {
      params: httpParams,
    });
  }

  public getAllEquipmentCategories(): Observable<EquipmentTypeCategory[]> {
    return this.http.get<EquipmentTypeCategory[]>(`${this.url}/category1`);
  }

  public getEquipmentTypeImage(equipmentId: string): Observable<ImageLink> {
    return this.http.get<ImageLink>(this.url + '/image/' + equipmentId);
  }

  public getUploader(): FileUploader {
    let options: FileUploaderOptions = {
      isHTML5: true,
      maxFileSize: 100 * 1024 * 1024, // 100MB
      removeAfterUpload: true,
      allowedMimeType: ['image/jpeg', 'image/png'],
      authToken: this.keycloak.getTokenForHeader('Bearer'),
      headers: [{name: 'X-Realm', value: KeycloakService.getRealm()}],
      url: this.fileUploadUrl,
    };

    let uploader =  new FileUploader(options);
    uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };
    return uploader;
  }

  public getAllEquipmentTypesGroupedByCategory1(): Observable<EquipmentTypeFilterView[]> {
    return this.http.get<EquipmentTypeFilterView[]>(`${this.url}/grouped-by-category1`);
  }

  public deleteEquipmentTypeImage(command: DeleteEquipmentTypeImageCommand) {
    return this.http.post(this.url + '/delete-image', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteEquipmentTypeThumbnail(command: DeleteEquipmentTypeThumbnailCommand) {
    return this.http.post(this.url + '/delete-thumbnail', JSON.stringify(command), {responseType: 'text'});
  }

  public getEquipmentType(equipmentTypesId: string): Observable<ViewEquipmentType> {
    return this.http.get<ViewEquipmentType>(this.url + '/' + equipmentTypesId);
  }

  public addEquipmentType(command: CreateEquipmentTypeCommand) {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateEquipmentType(command: UpdateEquipmentTypeCommand) {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteEquipmentType(command: DeleteEquipmentTypeCommand) {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public updateEquipmentTypeThumbnail(command: UploadEquipmentTypeThumbnailCommand): Observable<string> {
    return this.http.post(this.url + '/update-thumbnail', JSON.stringify(command), {responseType: 'text'});
  }

  public updateEquipmentTypeImage(command: UploadEquipmentTypeImageCommand): Observable<string> {
    return this.http.post(this.url + '/update-image', JSON.stringify(command), {responseType: 'text'});
  }

  public isCategory2InUse(category2: string, category1: string): Observable<boolean> {
    let params = getHttpParameters({category1, category2});
    return this.http.get<boolean>(`${this.url}/exists-by-category1-and-category2`, {params});
  }
}
