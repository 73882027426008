import { Injectable } from '@angular/core';
import { TransportAssignment } from 'app/modules/transportation/contracts/transport/transport-assignment.interface';
import { Observable } from 'rxjs';
import { TransportService } from '../transport.service';
import { TransportAssignmentsLazyLoaderParams } from './contract/lazy-loader-params/transport-assignments-lazy-loader-params.class';
import { LazyLoaderBase } from './core/lazy-loader-base.class';


@Injectable()
export class TransportAssignmentsLazyLoader extends LazyLoaderBase<TransportAssignment> {
  constructor(private transportService: TransportService) {
    super('assignmentId');
  }

  protected getAsyncData(params: TransportAssignmentsLazyLoaderParams): Observable<TransportAssignment[]> {
    return this.transportService.getTransportAssignmentsByTimespan(params.toTransportAssignmentsRequestParams());
  }
}
