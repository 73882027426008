import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MaintenanceTaskService } from './maintenance-task.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskInfo } from '../task-info.interface';
import { MaintenanceEquipmentService } from './maintenance-equipment.service';
import { MaintenanceTypeService } from '../../../types/shared/service/maintenance-type.service';
import { MaintenanceFieldValue } from '../../../../../shared/contract/task-completion/maintenance-field-value';
import _ from 'lodash';

@Injectable()
export class MaintenanceTaskResolver implements Resolve<TaskInfo> {
  constructor(
    private maintenanceTaskService: MaintenanceTaskService,
    private maintenanceEquipmentService: MaintenanceEquipmentService,
    private maintenanceTypeService: MaintenanceTypeService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TaskInfo> | Promise<TaskInfo> | TaskInfo {
    return this.maintenanceTaskService.getMaintenanceView(route.paramMap.get('id'))
      .pipe(
        switchMap(task => combineLatest([
          of(task),
          this.maintenanceEquipmentService.getById(task.equipmentId),
        ])),
        switchMap(([task, equipment]) => combineLatest([
          of(task),
          of(equipment),
          this.maintenanceTypeService.getById(task.rule.maintenanceTypeId)
        ])),
        map(([task, equipment, type]) => {
          return {
            task: task,
            equipmentName: equipment.equipmentName,
            ruleName: task.rule.ruleName,
            ruleDescription: task.rule.ruleDescription,
            ruleHasMileageInterval: !!task.rule.mileageInterval,
            ruleHasOperatingHoursInterval: !!task.rule.operatingHoursInterval,
            maintenanceTypeName: type.name,
            maintenanceTypeDescription: type.description,
            maintenanceCategory: task.rule.maintenanceCategory,
            maintenanceFieldValues: (type.typeFields || []).map(field =>
              new MaintenanceFieldValue(field.name, field.fieldType, null, field.maxLength, field.mandatory)),
            currentEquipmentMileage: equipment.currentMileage,
            currentEquipmentOperatingHours: equipment.currentOperatingHours,
            completed: false,
            completedAtDate: task.completedAtDate,
            completedAtMileage: task.completedAtMileage,
            completedAtOperatingHours: task.completedAtOperatingHours,
            latestCompletionDate: task.latestCompletionDate,
            taskLimit: task.rule.taskLimit,
          };
        })
      );
  }
}
