import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class Html5NavigatorService {
  private _options: PositionOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  constructor() {
  }

  set options(options: PositionOptions) {
    this._options = options;
  }

  private humanizeGeolocationErrorMsg(error: GeolocationPositionError) {
    let errorMessage = 'An unknown error occurred';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = 'User denied the request for Geolocation';
        break;
      case error.POSITION_UNAVAILABLE:
        errorMessage = 'Location information is unavailable';
        break;
      case error.TIMEOUT:
        errorMessage = 'The request to get user location timed out';
        break;
    }

    return errorMessage;
  }

  getCurrentPosition() {
    return new Observable((observer) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          observer.next(position);
        }, error => {
          const errorObj = {
            error: error,
            customMessage: this.humanizeGeolocationErrorMsg(error)
          };
          observer.error(errorObj);
        }, this._options);
      } else {
        const errorObj = {
          customMessage: 'Your browser doesn\'t support html5 Geolocation'
        };
        observer.error(errorObj);
      }
    });
  }
}
