import { EventEmitter, Injectable } from '@angular/core';
import { ScannerAdapter } from '../scanner.adapter';
import { environment } from '../../../../../environments/environment';
import { TorchInfo } from '../torch-info.interface';
import { CameraUtils } from '../camera-utils';

@Injectable()
export class DSScannerAdapter extends ScannerAdapter {

  private key: string = environment.DS_SCANNER_LICENCE_KEY;
  private initializeErrorEmitter: EventEmitter<string>;
  private torchEmitter: EventEmitter<TorchInfo>;
  private torchAvailable = false;

  public windowHeight: number = window.innerHeight;
  public windowWidth: number = window.innerWidth;

  constructor() {
    super();
  }

  public registerScanSuccessHandler(successEmitter: EventEmitter<string>): void {
    DSScanner.addEventListener('onBarcode', codes => {
      const code = DSScanner.bin2String(codes[0]);

      if (code && code.length > 0) {
        successEmitter.emit(code);
      }
    });
  }

  public registerScanErrorHandler(errorEmitter: EventEmitter<string>): void {
  }

  public registerScannerInitializedHandler(initializedEmitter: EventEmitter<boolean>): void {
    DSScanner.addEventListener('onScannerReady', () => {
      DSScanner.StartScanner();
      initializedEmitter.emit(true);
    });
  }

  public registerScannerInitializeErrorHandler(errorEmitter: EventEmitter<string>): void {
    this.initializeErrorEmitter = errorEmitter;
    DSScanner.addEventListener('onError', err => errorEmitter.emit(err.message));
  }

  public registerTorchHandler(torchEmitter: EventEmitter<TorchInfo>): void {
    this.torchEmitter = torchEmitter;
  }

  public initializeScanner(): void {
    DSScanner.getVideoDevices((devices: Device[]) => {
      const rearCameraDevice: Device = CameraUtils.getRearCameraDevice(devices);
      if (!rearCameraDevice) {
        this.initializeErrorEmitter.emit('no camera device found');
        return;
      }

      CameraUtils.isTorchCompatible(rearCameraDevice.id, this.setTorchCompatible.bind(this));
      DSScanner.Create(this.createConfiguration(rearCameraDevice));
    });
  }

  public destroy(): void {
    DSScanner.Destroy();
  }

  public toggleTorch(enable: boolean): void {
    if (!this.torchAvailable || !DSScanner.IsScannerReady()) {
      return;
    }
    DSScanner.setScannerSettings({scanner: {light: enable}});
    this.torchEmitter.emit({torchAvailable: true, torchEnabled: enable});
  }

  public get type(): string {
    return DSScannerAdapter.name;
  }

  public get viewportHook(): string {
    return '<div id="scanner-viewport" style="overflow:hidden;display:block;width:100%; height:100%;"></div>';
  }

  private createConfiguration(device: Device): ScannerSettings {
    const cameraConfig = this.createCameraConfiguration(device);
    const rectDimension = 200;
    const left = (this.windowWidth / 2) - (rectDimension / 2);
    const top = (this.windowHeight / 2) - (rectDimension / 2);
    return {
      scanner: {
        key: this.key,
        frameTimeout: 100,
        barcodeTimeout: 1000,
        beep: true,
        barcodeAtPoint: false,
        scanningRect: {
          left: left,
          top: top,
          width: rectDimension,
          height: rectDimension
        },
      },
      viewport: {
        id: 'scanner-viewport',
        height: this.windowHeight,
      },
      camera: cameraConfig,
      barcode: {
        barcodeTypes: ['Code39', 'Code128', 'DataMatrix', 'QRCode', 'QRCodeUnrecognized'],
        QRCodeDecSpeed: 1
      },
    };
  }

  private createCameraConfiguration(device: Device): DsScannerCamera {
    return {
      id: device ? device.id : null,
      label: device ? device.label : null,
      facingMode: 'environment',
      resx: this.windowHeight,
      resy: this.windowWidth
    };
  }

  private setTorchCompatible(compatible: boolean): void {
    this.torchAvailable = compatible;
    this.torchEmitter.emit({torchAvailable: this.torchAvailable, torchEnabled: false});
  }
}
