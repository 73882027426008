import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { AssignedEmployeeFilterView } from 'app/shared/contract/filter/filter-view/assigned-employee-filter-view.interface';
import { FilterValue } from '../filter-value';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';

export class ResponsiblePersonFilterViewConverter extends BaseFilterViewConverter<AssignedEmployeeFilterView> {
  constructor(
    originalFilters: AssignedEmployeeFilterView[],
    selectedValues: FilterParams
  ) {
    super(FilterTypes.RESPONSIBLE_PERSON, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: AssignedEmployeeFilterView): FilterValue {
    const displayName = `${filterItem.employeeName} ${filterItem.employeeFirstName}`
    return {
      displayName: displayName,
      storeName: filterItem.employeeId,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.employeeId),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
