import { TimelineEvent } from './timeline-event';
import { ViewEmployeeProjectAssignment } from './view-employee-project-assignment.interface';

export class EmployeeNotAvailableEvent extends TimelineEvent {

  public conflictingAssignment: ViewEmployeeProjectAssignment;

  constructor(projectId: string, conflictingAssignment: ViewEmployeeProjectAssignment) {
    super(projectId + '/' + conflictingAssignment.assignmentId,
      projectId + '/' + conflictingAssignment.employeeId,
      conflictingAssignment.assignmentStartDate,
      conflictingAssignment.assignmentEndDate,
      null,
      conflictingAssignment.projectName,
      true,
      false,
      'white');
    this.conflictingAssignment = conflictingAssignment;
  }
}

export function isEmployeeNotAvailableEvent(event: TimelineEvent): event is EmployeeNotAvailableEvent {
  return (<EmployeeNotAvailableEvent>event).conflictingAssignment !== undefined &&
   (<EmployeeNotAvailableEvent>event).conflictingAssignment.employeeId !== undefined;
}
