import {
  OnInit,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OnlineStatusService } from '../../../../../shared/services/online-status.service';
import { Observable } from 'rxjs';
import { ScanEquipment } from 'app/modules/equipment/shared/scan-equipment';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-transfer-form-item',
  templateUrl: './mobile-transfer-form-item.component.html',
  styleUrls: ['./mobile-transfer-form-item.component.scss']
})
export class MobileDispositionFormItemComponent implements OnInit {

  @Input() equipment: ScanEquipment;

  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();

  isOnline: Observable<boolean>;

  constructor(private onlineStatusService: OnlineStatusService) {
    this.isOnline = onlineStatusService.isOnline;
  }


  ngOnInit() {
  }

  public remove() {
    this.onRemove.emit();
  }
}
