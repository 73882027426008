import { Component, OnInit } from '@angular/core';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { BaseProjectViewDetailComponent } from '../base/base-project-view-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectsService } from '../../../../../shared/project.service';
import { SetProjectDescriptionCommand } from '../../../../../../equipment/contract/set-project-description-command';
import { MatDialog } from '@angular/material/dialog';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { ProjectStructureType } from '../../../../../shared/enums/project-structure-type';
import { ScanCodeEditComponent } from 'app/shared/components/scan-code-edit/scan-code-edit.component';
import { IViewScanCodeData } from 'app/shared/components/scan-code-edit/scan-code.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { PairProjectScanCodeCommand } from 'app/modules/disposition/contract/pair-project-scan-code-command';
import { UnpairProjectScanCodeCommand } from 'app/modules/disposition/contract/unpair-project-scan-code-command';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-project-view-detail',
  templateUrl: './project-view-detail.component.html',
  styleUrls: ['./project-view-detail.component.scss']
})
export class ProjectViewDetailComponent extends BaseProjectViewDetailComponent implements OnInit {

  public canEditProject = false;
  public canEditLabel = false;
  public canEditScancode = false;
  public ProjectStructureType = ProjectStructureType;

  public get scanCodeData(): IViewScanCodeData {
    return {
      objectName: this.project.projectName,
      scanCode: this.project.scanCode,
    }
  }

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              public projectStore: ProjectDataSource,
              protected projectsService: ProjectsService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory, projectStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.projectStore.currentProject.subscribe((res: ViewProject) => {
      if (res) {
        this.canEditProject = this.authService.hasAuthority(Authorities.PROJECT_UPDATE)
          && !this.projectCheckerService.isProjectFinished(res);
        this.canEditLabel = this.authService.hasAuthority(Authorities.PROJECT_MANAGE_LABELS)
          && !this.projectCheckerService.isProjectFinished(res);
        this.canEditScancode = this.authService.hasAuthority(Authorities.PROJECT_MANAGE_SCAN_CODE)
          && !this.projectCheckerService.isProjectFinished(res);
      }
    });
  }

  public saveDescription(description: string) {
    const cmd = new SetProjectDescriptionCommand(this.project.projectId, description);
    this.projectsService.setDescription(cmd).subscribe();
  }

  public addLabel(label: string): void {
    this.projectStore.addProjectLabel(this.project.projectId, label);
  }

  public deleteLabel(label: string): void {
    this.projectStore.deleteProjectLabel(this.project.projectId, label);
  }

  public editScanCode(): void {
    this.dialog.open(ScanCodeEditComponent, {
      data: { scanCodeData: this.scanCodeData }
    }).afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        switch (res.status) {
          case dialogResults.SAVE:
            this.saveScanCode(res.scanCode);
            break;
          case dialogResults.DELETE:
            this.deleteScanCode(res.scanCode);
            break;
        }
      });
  }

  private deleteScanCode(scanCode: string): void {
    const cmd: UnpairProjectScanCodeCommand = {
      projectId: this.project.projectId,
    };
    this.projectsService.unpairScanCode(cmd).subscribe(res => {
      if (res) {
        this.project.scanCode = scanCode;
      }
    });
  }

  private saveScanCode(scanCode: string): void {
    const cmd: PairProjectScanCodeCommand = {
      projectId: this.project.projectId,
      scanCode: scanCode,
    };
    this.projectsService.pairScanCode(cmd).subscribe(res => {
      if (res) {
        this.project.scanCode = scanCode;
      };
    });
  }
}
