<div fxFill fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <mat-card class="list-view-main-container no-rounded-corners-left"
    [ngClass]="{
      'list-item-hidden': displayService.isDetailsFullscreen(),
      'list-item-margin': displayService.isTableAndDetails()
    }"
    [fxFlex]="displayService.isTableFullscreen() ? 100 : 35">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">

      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div class="list-view-main__left-header" fxFlex="60px" fxLayout="row" fxLayoutAlign="end stretch">
          <div *ngIf="displayService.isTableFullscreen()"
            class="change-display-mode"
            fxFlex="60px" fxLayout="row"
            fxLayoutAlign="center center"
            [matTooltip]="'general.labels.detailView.expand'|translate"
            (click)="openDetails()">
            <mat-icon>chevron_left</mat-icon>
          </div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start stretch"
          class="list-view-main__left-body scrollable-table-container">
          <div fxFlex class="not-found" *ngIf="!bulkItemStore.bulkItems">
            {{'general.labels.noAnyFound'|translate:{value: 'general.bulkItems'|translate} }}
          </div>
          <mat-table #table
            fxFlex
            [ngClass]="{'hide-header': !displayService.isTableFullscreen()}"
            class="pagination-data-table left-body-list scrollable-table exclude-initial-fixed-basis-size"
            [dataSource]="bulkItemStore"
            matSort
            (matSortChange)="sortData($event)"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.columnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

            <ng-container cdkColumnDef="bulkItemOverview">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row" fxFlex class="left-body-list__overview-cell">
                <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                  <ng-container [ngTemplateOutlet]="bulkItemThumbnail" [ngTemplateOutletContext]="{row: row}"></ng-container>
                </div>
                <div class="left-body-list__info" fxFlex fxLayout="column">
                  <p class="bulk-item-list-column">
                    <span class="name">{{row.bulkItemName}}</span>
                  </p>
                  <p class="details">
                    <span>{{row.bulkItemNumber}}</span>
                  </p>
                  <mat-chip-set *ngIf="row.labels">
                    <mat-chip *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                    <mat-chip *ngIf="getRemainingLabels(row, true).length > 0"
                      matTooltipClass="mat-tooltip-multiline"
                      [matTooltip]="getRemainingLabels(row, true).join('\n')">
                      {{ '+' + getRemainingLabels(row, true).length }}
                    </mat-chip>
                  </mat-chip-set>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of shownColumns">

              <ng-container *ngIf="column.type === COLUMN_TYPE.ICON">
                <mat-header-cell *cdkHeaderCellDef
                  class="icon-column"
                  mat-sort-header
                  [disabled]="column.disableSort"
                  bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="bulk-item-list-column icon-column">
                  <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                    <ng-container [ngTemplateOutlet]="bulkItemThumbnail" [ngTemplateOutletContext]="{row: row}"></ng-container>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef
                  mat-sort-header
                  [disabled]="column.disableSort"
                  bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="bulk-item-list-column">
                  <span>{{column.valueCallback(row)}}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <mat-chip-set>
                    <mat-chip *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                    <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                      matTooltipClass="mat-tooltip-multiline"
                      [matTooltip]="getRemainingLabels(row).join('\n')">
                      {{ '+' + getRemainingLabels(row).length }}
                    </mat-chip>
                  </mat-chip-set>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  (click)="openDetails()"
                  [routerLink]="[row.bulkItemId]"
                  [matTooltip]="'general.labels.openDetails'|translate">
                  <mat-icon>info</mat-icon>
                </mat-cell>
              </ng-container>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="columnService.displayedColumns | async"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: columnService.displayedColumns | async"
              [routerLink]="[row.bulkItemId]"
              routerLinkActive="left-body-item--active"
              (dblclick)="openDetails()">
            </mat-row>


          </mat-table>
        </div>

        <div class="list-view-main__left-body-paginator" fxLayout="row wrap">
          <div id="list-settings" fxFlex="60px">
            <button id="configBtn"
              class="rotatable"
              mat-icon-button
              (click)="openConfiguration()"
              [matTooltip]="'general.labels.confColumnArrangement'|translate">
              <mat-icon class="rotatable">settings</mat-icon>
            </button>
          </div>
          <mat-paginator #paginator
            fxFlex="0"
            [length]="bulkItemStore.length | async"
            [pageIndex]="bulkItemStore.pagination.index"
            [pageSize]="bulkItemStore.pagination.size"
            [pageSizeOptions]="[5, 25, 50, 100]"
            bhCurrentItemPaginator
            [data]="bulkItemStore.bulkItems | async"
            [currentItemId]="(this.bulkItemStore.currentBulkItem | async)?.bulkItemId"
            [isIdentifierOfItem]="isIdentifierOfBulkItem"
            (paginationChanges)="onPaginateChange($event)">
          </mat-paginator>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="list-view-main-container"
    [fxFlex]="displayService.isDetailsFullscreen() ? 100 : 65"
    [ngClass]="{'list-item-hidden': displayService.isTableFullscreen()}">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #bulkItemThumbnail let-row="row">
  <bh-thumbnail *ngIf="row.thumbnailImageKey; else thumbnailIcon" [key]="row.thumbnailImageKey"></bh-thumbnail>
  <ng-template #thumbnailIcon>
    <fa-icon [icon]="optionResolver.resolveIcon(row.bulkItemType)"></fa-icon>
  </ng-template>
</ng-template>
