<div [ngClass]="[
  ('priority-' + (transport?.priority || 'NONE')),
  ('state-' + (transport?.state || 'NONE'))
]">
  <div
    class="transport-record"
    fxLayout="column"
    fxFlex="1 1 100%"
    fxLayoutGap="10px"
    [ngClass]="{'list-view': listView}"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <ng-container *ngIf="transport?.state">
        <bh-transport-state-badge [state]="transport.state"></bh-transport-state-badge>
      </ng-container>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 0 auto" fxLayoutGap="5px">
        <div
          *ngIf="transport?.dueDate?.timestamp"
          class="transport-record__due-date"
          [ngClass]="{'due-date-time-passed': (transport | isTransportDueDatePassed)}"
          data-osp-test="record-due-date">
          {{transport?.dueDate | formatTimezoneDateTime :{dateFormat: 'ddd, DD MMM YYYY', showTime: true, hideEndOfDayTime: true} }}
        </div>
        <bh-transport-priority-badge [priority]="transport.priority"></bh-transport-priority-badge>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="transport-record__number"># {{transport?.identifier}}</div>
      <div class="transport-record__title" fxFlex="1 1 0%" data-osp-test="record-transport-title">{{transport?.title}}</div>
    </div>

    <div fxLayout="row" fxFlex="1 1 auto" fxLayoutGap="10px">
      <div class="transport-record__item" fxLayout="column" fxFlex="1 1 0%" fxLayoutGap="5px">
        <ng-container
          [ngTemplateOutlet]="transportItem"
          [ngTemplateOutletContext]="getTransportStartContext()"
        ></ng-container>
        <ng-container
          [ngTemplateOutlet]="transportItem"
          [ngTemplateOutletContext]="getTransportTargetContext()"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #transportItem let-directionType="directionType" let-name="name" let-address="address">
  <div class="transport-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <div fxFlex="1 1 24px" fxLayoutAlign="center center">
      <mat-icon
        *ngIf="directionType"
        class="transport-item__icon"
        [svgIcon]="directionType === transportItemDirectionType.SOURCE ? 'transport-start' : 'transport-target'"
      ></mat-icon>
    </div>
    <div fxFlex="1 1 0%" fxLayout="column" class="transport-item__data">
      <div class="transport-item__data__property item-name" [attr.data-osp-test]="'record-address-name-'+directionType">
        {{name}}
      </div>
      <div class="transport-item__data__property item-address" [attr.data-osp-test]="'record-address-'+directionType">
        {{address}}
      </div>
    </div>
  </div>
</ng-template>
