import { environment } from 'environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { StockService } from '../../shared/services/stock.service';
import { SearchStock } from './search-stock';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { Pageable } from '../../../../shared/contract/task/pageable.interface';
import { PageEvent } from '@angular/material/paginator';
import { Page } from '../../../../shared/contract/page';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ViewStock } from '../../contract/view-stock';
import { DeleteStockDocumentCommand } from '../../contract/delete-stock-document-command';
import { DocumentLink } from '../../../../shared/contract/document-link.interface';
import { UpdateStockDocumentDescriptionCommand } from '../../contract/update-stock-document-description-command';
import { UploadStockDocumentCommand } from '../../contract/upload-stock-document-command';
import { Router } from '@angular/router';
import { DeleteStockCommand } from '../../contract/delete-stock-command';
import { ViewBulkItemAmount } from '../../contract/view-bulk-item-amount.interface';
import { DispositionStock } from '../../shared/disposition-stock';
import { OnlineStatusService } from '../../../../shared/services/online-status.service';
import { OfflineDataStore } from '../../../../shared/services/offline-data.store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewEquipmentAmount } from '../../contract/view-equipment-amount.interface';
import * as _ from 'lodash';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { PaginationInfo } from '../../../../shared/contract/pagination-info-interface';
import { ViewStockEmployeeAssignment } from '../../contract/view-stock-employee-assignment.interface';
import { StockEmployeeService } from '../../shared/services/stock-employee.service';
import { ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand } from '../../contract/change-stock-responsible-employee-acceptance-flag.command';
import { AddStockResponsibleEmployeeCommand } from '../../contract/add-stock-responsible-employee-command';
import { RemoveStockResponsibleEmployeeCommand } from '../../contract/remove-stock-responsible-employee-command';
import { KeycloakService } from '../../../../core/keycloak';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../shared/enums/modules.enum';
import { StockColumnService } from '../../shared/services/stock-column.service';
import { CreateStockCommand } from '../../contract/create-stock.command';
import { TransferCartColumnService } from '../../shared/services/transfer-target-column.service';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { UsersService } from 'app/modules/userrole/shared/users.service';
import { UpdateStockDocumentNameCommand } from '../../contract/update-stock-document-name-command';
import { IAmountsRequestParams } from './contrancts/amounts-request-params.interface';
import { ViewGeofence } from '../../contract/view-geofence.interface';
import { CreateGeofenceCommand } from '../../contract/create-geofence-command';
import {
  GenericUpdateGeofenceCommand
} from '../../../../shared/modules/map/components/geofence-sidebar-component/generic-update-geofence-command';
import { DeleteGeofenceCommand } from '../../contract/delete-geofence-command';
import { ChangeGeofenceStatusCommand } from '../../contract/change-geofence-status-command';

@UntilDestroy()
@Injectable()
export class StockStore implements Pageable, OnDestroy {
  private _geofences: BehaviorSubject<ViewGeofence[]> = new BehaviorSubject([]);
  private _fullScreen: boolean;
  private detailTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>('general');
  private length: BehaviorSubject<number> = new BehaviorSubject(0);
  private pageSubject: BehaviorSubject<Page> = new BehaviorSubject<Page>({index: 0, size: 25});
  private stockTransferPageSubject: BehaviorSubject<Page> = new BehaviorSubject<Page>({index: 0, size: 25});
  private searchStocksSubject: BehaviorSubject<SearchStock[]> = new BehaviorSubject<SearchStock[]>([]);
  private searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private selectedStockSubject: BehaviorSubject<ViewStock> = new BehaviorSubject<ViewStock>(ViewStock.empty());
  private selectedStockDeletableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private documentsSubject: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private selectedStockBulkItemAmountsSubject: BehaviorSubject<ViewBulkItemAmount[]> = new BehaviorSubject<ViewBulkItemAmount[]>([]);
  private _employeeAssignments: BehaviorSubject<ViewStockEmployeeAssignment[]> = new BehaviorSubject(null);
  private selectedStockEquipmentAmountsSubject: BehaviorSubject<ViewEquipmentAmount[]> = new BehaviorSubject<ViewEquipmentAmount[]>([]);
  private allDispositionStocksSubject: BehaviorSubject<DispositionStock[]> = new BehaviorSubject<DispositionStock[]>([]);
  private _hasStockTransferRequestWorkflow: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private stocksSearchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private withoutRestrictionFilter = false;
  private _equipmentPaginationInfo: PaginationInfo;
  private _bulkItemPaginationInfo: PaginationInfo;
  private _routedStockId: string;
  private _inventorySearchTerm = new BehaviorSubject<string>('');

  public readonly geofences: Observable<ViewGeofence[]> = this._geofences.asObservable();
  public readonly detailTab: Observable<string> = this.detailTabSubject.asObservable();
  public readonly searchStocks: Observable<SearchStock[]> = this.searchStocksSubject.asObservable();
  public readonly selectedStock: Observable<ViewStock> = this.selectedStockSubject.asObservable();
  public readonly selectedStockDeletable: Observable<boolean> = this.selectedStockDeletableSubject.asObservable();
  public readonly employeeAssignments: Observable<ViewStockEmployeeAssignment[]> = this._employeeAssignments.asObservable();
  public readonly documents: Observable<DocumentLink[]> = this.documentsSubject.asObservable();
  public readonly selectedStockBulkItemAmounts: Observable<ViewBulkItemAmount[]> = this.selectedStockBulkItemAmountsSubject.asObservable();
  public readonly selectedStockEquipmentAmounts: Observable<ViewEquipmentAmount[]> =
   this.selectedStockEquipmentAmountsSubject.asObservable();
  public readonly hasStockTransferRequestWorkflow: Observable<boolean> = this._hasStockTransferRequestWorkflow.asObservable();

  public readonly filteredDispositionStocks: Observable<DispositionStock[]> =
   combineLatest([this.allDispositionStocksSubject, this.stocksSearchTerm])
    .pipe(map(([stocks, term]) => stocks.filter(stock => stock.stockName.toLowerCase().includes(term.toLowerCase()))));

  constructor(private stockService: StockService,
              private stockEmployeeService: StockEmployeeService,
              private router: Router,
              private onlineStatusService: OnlineStatusService,
              public stockColumnService: StockColumnService,
              private offlineStore: OfflineDataStore,
              private transferCartColumnService: TransferCartColumnService,
              private authService: KeycloakService,
              private userConfigurationService: UserConfigurationService,
              private usersService: UsersService) {
    combineLatest(
        this.pageSubject.pipe(distinctUntilChanged(_.isEqual)),
        this.stockTransferPageSubject.pipe(distinctUntilChanged(_.isEqual)),
        this.searchTerm.pipe(debounceTime(350), distinctUntilChanged()),
        (...values) => values) // Workaround for https://github.com/ReactiveX/rxjs/issues/1686
    .subscribe(([page, stockTransferPage, searchTerm]) => {
      if (!router.url.includes('mobile/transfer') && this.isAllowedToSearchForStocks()) {
        if (router.url.includes('transfer')) {
          this.search(stockTransferPage.index, stockTransferPage.size, searchTerm, true);
        } else {
          this.search(page.index, page.size, searchTerm);
        }
      }
    });
    this.stockTransferPaginatorPageSizeListener();
    this.paginatorPageSizeListener();
    this.initEquipmentPagination();
    this.initBulkItemPagination();
    this.selectedStockSubject.pipe(
        filter(stock => stock.stockId !== ''),
        tap(() => this.getDocuments()),
        tap(stock => {
          this.resetPaginationIndexes();
          this.getAmounts(stock.stockId);
        }))
    .subscribe();
  }

  public ngOnDestroy(): void {
  }

  get fullScreen(): boolean {
    return this._fullScreen;
  }

  get searchTerms(): string {
    return this.searchTerm.value;
  }

  set withoutRestriction(value: boolean) {
    this.withoutRestrictionFilter = value;
  }

  get equipmentPaginationInfo(): PaginationInfo {
    return this._equipmentPaginationInfo;
  }

  get bulkItemPaginationInfo(): PaginationInfo {
    return this._bulkItemPaginationInfo;
  }

  get getEmployeeAssignmentsValue(): ViewStockEmployeeAssignment[] {
    return this._employeeAssignments.value;
  }

  get currentStock(): ViewStock {
    return this.selectedStockSubject.getValue();
  }

  public get currentDetailTab(): string {
    return this.detailTabSubject.getValue();
  }

  public changeTab(tab: string): void {
    this.detailTabSubject.next(tab);
  }

  set routedStockId(value: string) {
    this._routedStockId = value;
  }

  public setRoutedStockId(value: string) {
    this._routedStockId = value;
  }

  get routedStockId(): string {
    return this._routedStockId;
  }

  set inventorySearchTerm(term: string) {
    this._inventorySearchTerm.next(term);
  }

  get inventorySearchTerm(): string {
    return this._inventorySearchTerm.value;
  }

  public delete(command: DeleteStockCommand): void {
    this.stockService.deleteStock(command)
    .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.selectedStockSubject.next(ViewStock.empty())))
    .subscribe(() => this.update());
  }

  public deleteDocument(command: DeleteStockDocumentCommand): Observable<string> {
    return this.stockService.deleteDocument(command)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap(res => {
          this.getDocuments();
          return res;
        }));
  }

  public getGeofences(): void {
    this.stockService
      .getGeofences(this.currentStock.stockId)
      .subscribe((res: ViewGeofence[]) => this._geofences.next(res));
  }

  public createGeofence(cmd: CreateGeofenceCommand): void {
    this.stockService
      .createGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public updateGeofence(cmd: GenericUpdateGeofenceCommand): void {
    this.stockService
      .updateGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public deleteGeofence(cmd: DeleteGeofenceCommand): void {
    this.stockService
      .deleteGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public changeGeofenceStatus(cmd: ChangeGeofenceStatusCommand): void {
    this.stockService
      .changeGeofenceStatus(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGeofences());
  }

  public getAmounts(stockId?: string): void {
    if (stockId) {
      this.updateEquipmentAmounts(stockId);
      this.updateBulkItemAmounts(stockId);
    }
  }

  public updateEquipmentAmounts(stockId?: string): void {
    this.stockService.getEquipmentAmounts(stockId || this.selectedStockSubject.value.stockId, this.getEquipmentAmountsRequestParams())
    .subscribe((amounts: PagedResponse<ViewEquipmentAmount> ) => {
      this.selectedStockEquipmentAmountsSubject.next(amounts.content);
      this._equipmentPaginationInfo.totalElements = amounts.totalElements;
    });
  }

  public updateBulkItemAmounts(stockId?: string): void {
    this.stockService.getBulkItemAmounts(stockId || this.selectedStockSubject.value.stockId, this.getBulkItemAmountsRequestParams())
    .subscribe(amounts => {
      this.selectedStockBulkItemAmountsSubject.next(amounts.content);
      this._bulkItemPaginationInfo.totalElements = amounts.totalElements;
    });
  }

  public getDocuments(): void {
    this.stockService.getDocuments(this.selectedStockSubject.value.stockId).subscribe(documentLinks =>
       this.documentsSubject.next(documentLinks));
  }

  public getIndex(): Observable<number> {
    return this.pageSubject.pipe(map(page => page.index));
  }

  public getLength(): Observable<number> {
    return this.length.asObservable();
  }

  public getSize(): Observable<number> {
    return this.pageSubject.pipe(map(page => page.size));
  }

  public getStockTransferPageIndex(): Observable<number> {
    return this.stockTransferPageSubject.pipe(map(page => page.index));
  }

  public getStockTransferPageSize(): Observable<number> {
    return this.stockTransferPageSubject.pipe(map(page => page.size));
  }

  public handlePageEvent(event: PageEvent): void {
    if (this.pageSubject.getValue().size !== event.pageSize) {
      this.stockColumnService.selectPageSize(event.pageSize);
    }
    this.pageSubject.next({index: event.pageIndex, size: event.pageSize});
  }

  public handleStockTransferPageEvent(event: PageEvent): void {
    this.stockTransferPageSubject.next({index: event.pageIndex, size: event.pageSize});
    this.transferCartColumnService.selectColumns([], null, {
      transferCartPaginatorOptions: { stockViewPageSize: event.pageSize }
    });
  }

  public reset(): void {
    this.searchTerm.next(null);
    this.stockColumnService.pageSize.pipe(take(1)).subscribe((pageSize: number) => {
      this.pageSubject.next({index: 0, size: pageSize});
    });
  }

  public search(index: number, size: number, searchTerm: string, fromTransfer: boolean = false): void {
    if (fromTransfer) {
      this.stockService.searchTransferStocks(index.toString(), size.toString(), searchTerm)
      .subscribe(stockPage => {
        this.updateStockSubscription(stockPage);
      });

    } else {
      this.stockService.search(index.toString(), size.toString(), searchTerm, this.withoutRestrictionFilter)
      .subscribe(stockPage => {
        this.updateStockSubscription(stockPage);
      });
    }
  }

  public searchFor(terms: string): void {
    this.pageSubject.next({ index: 0, size: this.pageSubject.value.size });
    this.searchTerm.next(terms);
  }

  public stockTransferSearchFor(terms: string): void {
    this.stockTransferPageSubject.next({ index: 0, size: this.stockTransferPageSubject.value.size });
    this.searchTerm.next(terms);
  }

  public toggleFullScreen(): void {
    this._fullScreen = !this._fullScreen;
  }

  public selectStock(id: string): void {
    this.stockService.getById(id).subscribe(stock => {
      this.selectedStockSubject.next(stock);
      this.checkDeletable(stock.stockId);
      this.routedStockId = null;
    });
  }

  private checkDeletable(stockId: string): void {
    this.stockService.isDeletable(stockId).subscribe(deletable => {
      this.selectedStockDeletableSubject.next(deletable);
    });
  }

  public update(): void {
    this.search(this.pageSubject.value.index, this.pageSubject.value.size, this.searchTerm.value);
  }

  public updateStockTransfers(): void {
    this.search(
      this.stockTransferPageSubject.value.index,
      this.stockTransferPageSubject.value.size,
      this.searchTerm.value,
      true
    );
  }

  public uploadDocument(command: UploadStockDocumentCommand): void {
    this.stockService.addDocument(command).pipe(delay(environment.DELAY_SHORT)).subscribe(() => this.getDocuments());
  }

  public updateDocumentName(cmd: UpdateStockDocumentNameCommand): void {
    this.stockService.updateDocumentName(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocuments());
  }

  public updateDocumentDescription(cmd: UpdateStockDocumentDescriptionCommand): void {
    this.stockService.updateDocumentDescription(cmd).pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getDocuments()))
    .subscribe();
  }

  public getDispositionStocks(): void {
    if (this.onlineStatusService.onlineStatus) {
      this.stockService.getDispositionStocks()
        .subscribe(res => this.allDispositionStocksSubject.next(res));
    } else {
      this.offlineStore.offlineDispositionStocks
        .pipe(untilDestroyed(this))
        .subscribe(stocks => this.allDispositionStocksSubject.next(stocks));
    }
  }

  public getEmployeeAssignments(stockId: string): Observable<any> {
    this.stockEmployeeService
      .getEmployeeAssignments(stockId)
      .subscribe(res => {
        this._employeeAssignments.next(res);
      }, error => {
        console.log('error get employee assignments: ', error);
      });
    return merge(this._employeeAssignments);
  }

  public addResponsibleEmployee(command: AddStockResponsibleEmployeeCommand): Observable<any> {
    return this.stockEmployeeService.addStockEmployeeAssignments(command).pipe(
      delay(environment.DELAY_SHORT),
      switchMap((res: string) => {
        this.getEmployeeAssignments(command.stockId);
        return of(res);
      }));
  }

  removeEmployeeAssignment(cmd: RemoveStockResponsibleEmployeeCommand): void {
    this.stockEmployeeService
    .removeStockEmployeeAssignments(cmd)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe((res: string) => {
      this.getEmployeeAssignments(cmd.stockId);
    });
  }

  public changeProjectRespnsibleEmployeeAcceptanceUser(
    command: ChangeStockResponsibleEmployeeAcceptanceUserFlagCommand ): Observable<any> {
    return this.stockEmployeeService.changeStockRespnsibleEmployeeAcceptanceUser(command).pipe(
      delay(environment.DELAY_SHORT),
      switchMap((res: string) => {
        this.getEmployeeAssignments(command.stockId);
        return of(res);
      }));
  }

  public filterStocks(term: string): void {
    this.stocksSearchTerm.next(term);
  }

  public updateStockTransferRequestsWorkflow(): void {
    this.stockService.hasStockTransferRequestWorkflow()
    .subscribe(res => this._hasStockTransferRequestWorkflow.next(res));
  }

  public async setStockTransferRequestWorkflow(): Promise<void> {
    await this.stockService.hasStockTransferRequestWorkflow().toPromise().then(value => {
      this._hasStockTransferRequestWorkflow.next(value);
    })
  }

  public getStockTransferRequestWorkflow(): boolean {
    return this._hasStockTransferRequestWorkflow.value;
  }

  public noStockSelected(): boolean {
    return this.selectedStockSubject.value.stockId === '';
  }

  public setEquipmentPaginationInfo(index: number, size: number) {
    this._equipmentPaginationInfo.index = index;
    this._equipmentPaginationInfo.size = size;
  }

  public setBulkItemPaginationInfo(index: number, size: number) {
    this._bulkItemPaginationInfo.index = index;
    this._bulkItemPaginationInfo.size = size;
  }

  public createStock(cmd: CreateStockCommand, afterCreate: (id: string) => void): void {
    this.stockService.createStock(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe((res: string) => {
      this.changeTab('general');
      this.selectStock(res);
      this.update();
      afterCreate(res);
    });
  }

  private getDefaultPagination(pageSize: number) {
    return {
      totalElements: 0,
      totalPages: 0,
      size: pageSize || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private initEquipmentPagination() {
    this._equipmentPaginationInfo  = this.getDefaultPagination(this._equipmentPaginationInfo?.size);
  }

  private initBulkItemPagination() {
    this._bulkItemPaginationInfo = this.getDefaultPagination(this._bulkItemPaginationInfo?.size);
  }

  private stockTransferPaginatorPageSizeListener(): void {
    this.transferCartColumnService.transferCartStockViewPageSize
    .pipe(distinctUntilChanged())
    .subscribe((pageSize: number) => {
      this.stockTransferPageSubject.next({index: this.stockTransferPageSubject.value.index, size: pageSize});
    });
  }

  private paginatorPageSizeListener(): void {
    this.stockColumnService.pageSize.pipe(distinctUntilChanged()).subscribe((pageSize: number) => {
      this.pageSubject.next({index: this.pageSubject.value.index, size: pageSize});
    });
  }

  public resetPaginationIndexes() {
    this._equipmentPaginationInfo.index = 0;
    this._bulkItemPaginationInfo.index = 0;
  }

  private isAllowedToSearchForStocks(): boolean {
    return this.authService.hasAuthority(Authorities.STOCK_VIEW)
      && this.authService.hasModule(Modules.BASE)
      && (this.authService.hasModule(Modules.DISPOSITION)
      || this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT));
  }

  private updateStockSubscription(stockPage: PagedResponse<SearchStock>): void {
    this.length.next(stockPage.totalElements);
    this.searchStocksSubject.next(stockPage.content);
    if (!this.router.url.includes('clear-project')
      && !this.router.url.includes('stock-to-stock-cart')
      && !this.router.url.includes('stock-to-project-cart')
      && !this.router.url.includes('dashboard')) {
      this.selectFirstIfNecessary();
    }
  }

  private selectFirstIfNecessary(): void {
    if (!this.selectedStockSubject.value.stockId && (this.searchStocksSubject.getValue() ?? [])[0]?.stockId &&
      (!this.routedStockId || !this.isRoutedStockAccessibleByUser())) {
      this.selectStock(this.searchStocksSubject.getValue()[0].stockId);
    }
  }

  private isRoutedStockAccessibleByUser(): boolean {
    return this.searchStocksSubject.getValue().filter(value => value.stockId === this.routedStockId).length > 0;
  }

  public getMapConfiguration(): MapConfiguration {
    return this.userConfigurationService.getMapConfigurationStock();
  }

  public saveMapConfiguration(mapConfig: MapConfiguration): void {
    this.userConfigurationService.saveMapConfigurationStock(mapConfig);
    this.usersService.saveMapConfigurationStock(mapConfig).subscribe();
  }

  private getEquipmentAmountsRequestParams(): IAmountsRequestParams {
    return {
      searchTerm: this.inventorySearchTerm,
      page: this._equipmentPaginationInfo.index,
      size: this._equipmentPaginationInfo.size,
    }
  }

  private getBulkItemAmountsRequestParams(): IAmountsRequestParams {
    return {
      searchTerm: this.inventorySearchTerm,
      page: this._bulkItemPaginationInfo.index,
      size: this._bulkItemPaginationInfo.size,
    }
  }
}
