import {Component, Input, OnInit} from '@angular/core';
import {MatMenuItemButtonConfig} from './mat-menu-item-button-config';

@Component({
  selector: 'bh-mat-menu-item-button',
  templateUrl: './mat-menu-item-button.component.html',
  styleUrls: ['./mat-menu-item-button.component.scss']
})
export class MatMenuItemButtonComponent implements OnInit {

  @Input() config: MatMenuItemButtonConfig
  constructor() { }

  ngOnInit(): void {
  }

}
