import { ColumnService } from '../../../../../shared/column.service';
import { Injectable } from '@angular/core';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';
import { DisplayService } from '../../../../../shared/display.service';
import { KeycloakService } from '../../../../../core/keycloak';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { MaintenanceColumnDefinitionService } from './maintenance-column-definition.service';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';


@Injectable()
export class MaintenanceColumnService extends ColumnService {
  public static overviewColumnName = 'maintenanceOverview';

  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected maintenanceColumnDefinitionService: MaintenanceColumnDefinitionService,
              protected authService: KeycloakService) {
    super(MaintenanceColumnService.overviewColumnName,
      maintenanceColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.maintenanceListConfiguration.columns,
      userConfigurationService.userConfiguration.maintenanceListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.maintenanceListConfiguration.pageSize },
      displayService,
      authService);
  }

  get columnLimit(): number {
    return undefined;
  }

  public selectColumns(columns: ColumnDefinition[], pageSize: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_CATEGORY
      || column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER
      || column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_NAME
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.DUE_DATE
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.REMAINING_VALUE
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.CURRENT_ADDRESS
      || column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_TYPE_CATEGORY2;
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveMaintenanceListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.columnConfigsSubject.getValue(),
      this.pageSizeSubject.getValue(),
    );
  }
}
