interface EventPosition {
  xStart: number;
  xEnd: number;
}

interface EventBase {
  id: string;
}

export class SchedulerEventPositionTracker {
  private positionMap: { [key: string]: EventPosition } = {};
  private original = scheduler['_timeline_getX'];

  constructor() {
    this.plugin();
  }

  public getEventPosition(id: string): EventPosition {
    return this.positionMap[id];
  }

  public releaseData(): void {
    scheduler['_timeline_getX'] = this.original;
  }

  private plugin(): void {
    this.xGetPlugin();
  }

  private xGetPlugin(): void {
    const originalBinded = this.original.bind(scheduler);

    const decorator = (event: EventBase, isEnd: boolean, schedulerView: any): number => {
      const xCoord = originalBinded(event, isEnd, schedulerView);
      this.positionMap[event.id] = {
        ...(this.positionMap[event.id] || { xStart: 0, xEnd: 0 }),
        ...(isEnd ? { xEnd: xCoord } : { xStart: xCoord })
      };
      return xCoord;
    }

    scheduler['_timeline_getX'] = decorator;
  }
}

