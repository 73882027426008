<div fxFlex class="amount-list-component" fxLayout="column" fxLayoutAlign="start stretch">
  <div *ngIf="amounts && amounts.length > 0; else noAmounts"
       fxFlex
       class="scrollable-table">

    <table mat-table [dataSource]="amounts">

      <ng-container matColumnDef="icon">
        <th mat-header-cell class="icon-column" *matHeaderCellDef>{{'general.stock.s'|translate}}</th>
        <td mat-cell class="icon-column" *matCellDef="let amount">
          <span class="icon">
            <fa-icon [icon]="getIcon(amount)"></fa-icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let amount" class="stock-column">
          <span>{{ getName(amount) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell class="amount-column" *matHeaderCellDef>{{'modules.disposition.base.inventory.s'|translate}}</th>
        <td mat-cell class="amount-column" *matCellDef="let amount">
          <span>
            {{amount.amountAvailable | dimensionUnitConverter: amount.bulkItemUnit: 3}}
            {{'general.from'|translate|uppercase}}
            {{amount.amountTotal | dimensionUnitConverter: amount.bulkItemUnit: 3}}
            {{'general.available'|translate|uppercase}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell class="actions-column" *matHeaderCellDef></th>
        <td mat-cell class="actions-column" *matCellDef="let amount">
          <button mat-icon-button (click)="openAmountDialog(amount)"
                  *ngIf="hasAuthority(authorities.STOCK_MANAGE)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <ng-template #noAmounts>
    <div class="no-amounts" fxLayout="row" fxLayoutAlign="center center">
      {{ noAmountsMessage }}
    </div>
  </ng-template>

</div>
