<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <mat-card class="search-bar">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="search-bar-input-container" fxFlex>
        <div class="search-bar-input" fxLayout="row">
          <input fxFlex matInput [formControl]="searchTermControl">
          <button mat-button [matTooltip]="'general.search'|translate" fxFlex="40px" (click)="search()">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-button [matTooltip]="'general.labels.resetSearchFilters'|translate" fxFlex="40px"
                  (click)="resetSearch()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <button mat-button class="search-bar-button-right" fxFlex="50px" (click)="refresh()"
              [matTooltip]="'modules.equipment.telematicsUnitsList.refresh'|translate">
        <mat-icon>refresh</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
      <div class="data-table-wrapper" fxFlex>
        <mat-table class="data-table" [dataSource]="telematicUnitStore" fxFlex matSort
                  (matSortChange)="sortChange($event)">
          <ng-container cdkColumnDef="telematicsUnitId">
            <mat-header-cell *cdkHeaderCellDef fxFlex="30">
              {{'modules.equipment.telematicsUnitsList.telematicsUnitId'|translate}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="30" class="data-table-unit-id">
              <p>
                <span>{{row.telematicsUnitId}}</span>
              </p>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="telematicsUnitType">
            <mat-header-cell *cdkHeaderCellDef fxFlex="15">
              {{'modules.equipment.telematicsUnitsList.telematicsUnitType'|translate}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="15">
              <span>{{row.telematicsUnitType}}</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="assignedEquipmentInfo">
            <mat-header-cell *cdkHeaderCellDef fxFlex="45">
              {{'modules.equipment.telematicsUnitsList.assignedEquipmentInfo'|translate}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="45" fxLayout="column" fxLayoutAlign="start start">
              <div *ngFor="let assignedEquipment of row.assignedEquipments" class="data-table-assignment">
                <p class="data-table-assignment-line" *ngIf="assignedEquipment.name">
                  <span class="data-table-assignment-line-title">{{'general.equipm.s'|translate}}: </span>
                  <span>{{assignedEquipment.name}}</span>
                </p>
                <p class="data-table-assignment-line" *ngIf="assignedEquipment.equipmentSerialNumber">
                  <span class="data-table-assignment-line-title">{{'modules.equipment.base.serialNumber'|translate}}: </span>
                  <span>{{assignedEquipment.equipmentSerialNumber}}</span>
                </p>
                <p class="data-table-assignment-line" *ngIf="assignedEquipment.equipmentCustomerSerialNumber">
                  <span class="data-table-assignment-line-title">{{'general.intNumber.s'|translate}}: </span>
                  <span>{{assignedEquipment.equipmentCustomerSerialNumber}}</span>
                </p>
                <p class="data-table-assignment-line" *ngIf="assignedEquipment.customerName">
                  <span class="data-table-assignment-line-title">{{'general.customer.s'|translate}}: </span>
                  <span>{{assignedEquipment.customerName}}</span>
                </p>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="lastChangeDate">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header disableClear start="asc" fxFlex="15">
              {{'modules.equipment.telematicsUnitsList.lastSendDate'|translate}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="15">
              <span>
                {{row.lastChangeDate | date:'dd.MM.yyyy HH:mm:ss'
                  | defaultValue : ('modules.equipment.telematicsUnitsList.noDate'|translate)}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="trafficLight">
            <mat-header-cell *cdkHeaderCellDef fxFlex="5">{{'general.status'|translate}}</mat-header-cell>
            <mat-cell *cdkCellDef="let row" fxFlex="5" fxLayoutAlign="center center">
              <div class="color-preview" [ngClass]="row.lastChangeDate | telematicUnitTrafficLight"
                   [matTooltip]="row.lastChangeDate | telematicUnitTrafficTooltip"></div>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
