<div class="overall-data-wrapper">

  <div class="scanner" *ngIf="scannerActive && !isEnterprise">
    <div class="close-scanner">
      <button mat-icon-button *ngIf="torchAvailable" (click)="toggleTorch()">
        <mat-icon *ngIf="torchEnabled">flash_on</mat-icon>
        <mat-icon *ngIf="!torchEnabled">flash_off</mat-icon>
      </button>
      <button mat-icon-button (click)="closeScanner()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="alreadyPairedAssetType" class="already-paired"
         fxLayoutAlign="start stretch" fxLayout="column">
      <span>
        {{'modules.equipment.equipmentAddEdit.codeUsedBy'|translate:{ value:(alreadyPairedAssetType | assetType) } }}
      </span>
      <span *ngIf="alreadyPairedAsset" class="already-paired-name">{{alreadyPairedAsset}}</span>
    </div>
    <div class="searchingEquipById" *ngIf="searchingEquipmentByQrCode">
      <mat-spinner [strokeWidth]="2" [diameter]="16"></mat-spinner>
    </div>
  </div>

  <div class="scanner" *ngIf="isEnterprise && scannerActive" fxLayoutAlign="start stretch" fxLayout="column">
    <div class="close-scanner">
      <button mat-icon-button (click)="closeScanner()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="icon_container_zebra">
      <mat-icon class="zebra_scanner_icon_add" color="primary">view_week</mat-icon>
    </div>
    <div class="scan_message_zebra">{{'general.buttons.pressToScan'|translate}}</div>
  </div>

  <form [formGroup]="overallInformationFormGroup">
    <bh-title [text]="'general.baseData'|translate"></bh-title>
    <div class="qr-code-details required">
      <div class="centered">
        <button mat-icon-button (click)="showScanner()">
          <mat-icon svgIcon="scan-code-scan"></mat-icon>
        </button>
      </div>
      <mat-form-field>
        <mat-label>{{'modules.equipment.equipmentAddEdit.qrCode'|translate}}</mat-label>
        <input #qrCode type="text" matInput formControlName="qrCode">
        <mat-error *ngIf="scanCode.hasError('alreadyPairedToBulkItem')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToBulkItem'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCode.hasError('alreadyPairedToEquipment')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToEquipment'|translate}}
        </mat-error>
        <mat-error  *ngIf="scanCode.hasError('alreadyPairedToStock')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToStock'|translate}}
        </mat-error>
        <mat-error  *ngIf="scanCode.hasError('alreadyPairedToProject')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToProject'|translate}}
        </mat-error>
      </mat-form-field>
      <div class="centered">
        <button mat-icon-button (click)="activateQrEdit($event)">
          <mat-icon *ngIf="qrCodeFieldDisabled()">create</mat-icon>
          <mat-icon *ngIf="!qrCodeFieldDisabled() && scanCode.valid" color="primary">check</mat-icon>
          <mat-icon *ngIf="scanCode.invalid && scanCode.touched" color="error">error</mat-icon>
        </button>
      </div>
    </div>

    <mat-form-field>
      <mat-label>{{'general.internalSerialNumber'|translate}}</mat-label>
      <input type="text" matInput
             formControlName="internalSerialNumber"
             required/>
      <mat-error *ngIf="serialNumber.hasError('taken')">
        {{'shared.validation.alreadyAssigned'|translate:{ value: 'general.internalSerialNumber'|translate } }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'general.name.s'|translate}}</mat-label>
      <input type="text" matInput
             formControlName="name"
             required/>
    </mat-form-field>

    <div class="select-equipment-types required">
      <mat-form-field>
        <mat-label>{{'general.type'|translate}}</mat-label>
        <select matNativeControl required formControlName="equipmentTypeId">
          <optgroup *ngFor="let group of allEquipmentTypes"
                    label="{{group.category1}} ({{group.total}})">
            <option *ngFor="let subType of group.subTypes"
                    [value]="subType.equipmentTypeId">
              {{ subType.category2 }}
            </option>
          </optgroup>
        </select>
      </mat-form-field>
    </div>

    <div class="select-organisation required">
      <mat-form-field>
        <mat-label>{{'general.org.s'|translate}}</mat-label>
        <select matNativeControl required formControlName="organisationId">
          <option *ngFor="let organisation of allOrganisations"
                  [value]="organisation.organisationId">
            {{ organisation.organisationName }}
          </option>
        </select>
      </mat-form-field>
    </div>

    <div class="select-stock required">
      <mat-form-field>
        <mat-label>{{'general.stock.s'|translate}}</mat-label>
        <select matNativeControl required formControlName="stockId">
          <option *ngFor="let stock of allStocks"
                  [value]="stock.stockId">
            {{ stock.stockName }}
          </option>
        </select>
      </mat-form-field>
    </div>
  </form>
</div>
