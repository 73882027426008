<div fxLayout="row" fxLayoutAlign="space-between start">
  <bh-title [text]="'modules.equipment.contract.contractData'|translate"></bh-title>
  <span>
    <button mat-button mat-raised-button color="primary" [disabled]="!isActiveEquipment() || !isAllowedToAddEdit()"
      [matTooltip]="'modules.equipment.contract.addContract'|translate" (click)="addContract()">
      {{'general.buttons.add'|translate}}
    </button>
  </span>
</div>

<div *ngIf="hasContracts(); else noContracts" fxLayout="column" fxLayoutAlign="start stretch">

  <!--Rental Contract-->
  <div *ngIf="equipmentRentalContracts && equipmentRentalContracts.length">
    <mat-expansion-panel *ngFor="let equipmentRentalContract of equipmentRentalContracts" class="custom-panel">
      <mat-expansion-panel-header class="custom-panel-header">
        <mat-panel-title *ngIf="equipmentRentalContract.rentalContractType ===
        rentalContractTypes.FULL_SERVICE; else rentalContract" class="custom-panel-title" fxFlex="33" fxLayout="row"
          fxLayoutAlign="start center">
          <div class="circle-div" fxLayoutAlign="center center">
            <fa-icon class="white-icon" [icon]="faSuitcase">
            </fa-icon>
          </div>
          <span class="panel-title-text black-text">
            {{'modules.equipment.contract.contractTypes.fullServiceContract'|translate|uppercase}}
          </span>
        </mat-panel-title>
        <ng-template #rentalContract>
          <mat-panel-title class="custom-panel-title" fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
            <div class="circle-div" fxLayoutAlign="center center">
              <fa-icon class="white-icon" [icon]="faKeySkeleton">
              </fa-icon>
            </div>
            <span class="panel-title-text black-text">
              {{'modules.equipment.contract.contractTypes.rentalContract'|translate|uppercase}}
            </span>
          </mat-panel-title>
        </ng-template>
        <mat-panel-description class="custom-panel-description" fxFlex="66"
         fxLayout="row" fxLayoutAlign="start center">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="description-div">
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between">
              <div>
                <span>{{'general.units.number'|translate}}: </span>
                <span class="black-text">{{equipmentRentalContract.rentalContractNumber}}</span>
              </div>
            </div>
            <div class="icons-row" fxFlex="50" fxLayout="row" fxLayoutAlign="end">
              <fa-icon [matTooltip]="'general.buttons.edit'|translate"
                *ngIf="equipmentRentalContract.rentalContractType ===
                  rentalContractTypes.FULL_SERVICE && isActiveEquipment() &&
                  isAllowedToAddEdit() ; else editLongTermOrRental"
                (click)="editContract(equipmentRentalContract.rentalContractId,
                 contractTypes.RENTAL_FULL_SERVICE_CONTRACT)"
                class="action-icon" [icon]="faPencil"></fa-icon>
              <ng-template #editLongTermOrRental>
                <fa-icon *ngIf="(equipmentRentalContract.rentalContractType ===
                  rentalContractTypes.RENTAL_CONTRACT_LONG_TERM || equipmentRentalContract.rentalContractType ===
                  rentalContractTypes.RENTAL_CONTRACT) && isActiveEquipment() && isAllowedToAddEdit()"
                  (click)="editContract(equipmentRentalContract.rentalContractId, contractTypes.RENTAL_CONTRACT)"
                  class="action-icon" [icon]="faPencil"></fa-icon>
              </ng-template>
              <fa-icon *ngIf="isActiveEquipment() && isAllowedToAddEdit()"
                [matTooltip]="'general.buttons.delete'|translate"
                (click)="deleteRentalContract($event, equipmentRentalContract)" class="action-icon" [icon]="faTrash">
              </fa-icon>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-custom-content">
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.deliveryAddressCompanyName}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.deliveryAddress.companyName'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.deliveryAddressCompanyName}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.deliveryAddress}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.deliveryAddress.street'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.deliveryAddress?.street}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.deliveryAddress?.postalCode &&
             !equipmentRentalContract?.deliveryAddress?.city}" floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.deliveryAddress.zipCodeCity'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.deliveryAddress?.postalCode}}
              {{equipmentRentalContract?.deliveryAddress?.city}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.furtherInformation}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.furtherInformation'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.furtherInformation}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalContractNumber}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'general.units.number'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.rentalContractNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.customerOrderNumber}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.orderNumber'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.customerOrderNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.contractPartner}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.contractPartner}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalContractTypeId}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.rentalContractType'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.rentalContractTypeId}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalContractTypeDescription}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractTypeDescription'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.rentalContractTypeDescription}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.amountInterval}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.amountInterval'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.amountInterval | rentalContractAmountInterval}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.calculationBase}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.calculationBase'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentRentalContract?.calculationBase | rentalContractCalculationBase}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.quantity}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.quantity'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.quantity}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.amount}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.amount'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentRentalContract?.amount | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalStartDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.validFrom'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentRentalContract?.rentalStartDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalEndDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.validUntil'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentRentalContract?.rentalEndDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalMileageLimit}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.agreedPeriod'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentRentalContract?.rentalMileageLimit}}">
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="33">
            <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalMileageLimitPeriod}"
              class="custom-field">
              <mat-label>{{'modules.equipment.contract.agreedPeriodUnit'|translate}}</mat-label>
              <input type="number" readonly
                matInput min="0" value="{{getParsedPeriod(equipmentRentalContract?.rentalMileageLimitPeriod).value}}">
            </mat-form-field>

            <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.rentalMileageLimitPeriod}"
              class="custom-field">
              <mat-select value="{{getParsedPeriod(equipmentRentalContract?.rentalMileageLimitPeriod).period}}"
                disabled>
                <mat-option value="M">{{'general.units.time.month.s_pl'|translate}}</mat-option>
                <mat-option value="Y">{{'general.units.time.year.s_pl'|translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.composedOf}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.composedOf'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentRentalContract?.composedOf}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentRentalContract?.comment}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.comment.s'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentRentalContract?.comment}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="equipmentRentalContract.documents.length > 0">
        <bh-contract-attachments [documents]="equipmentRentalContract.documents">
        </bh-contract-attachments>
      </div>
    </mat-expansion-panel>
  </div>

  <!--Sales Contract-->
  <div *ngIf="equipmentSalesContract">
    <mat-expansion-panel class="custom-panel">
      <mat-expansion-panel-header class="custom-panel-header">
        <mat-panel-title class="custom-panel-title" fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
          <div class="circle-div" fxLayoutAlign="center center">
            <fa-icon class="white-icon" [icon]="faWallet"></fa-icon>
          </div>
          <span class="panel-title-text black-text">
            {{'modules.equipment.contract.contractTypes.purchaseContract'|translate|uppercase}}
          </span>
        </mat-panel-title>
        <mat-panel-description class="custom-panel-description" fxFlex="66"
         fxLayout="row" fxLayoutAlign="start center">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="description-div">
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between">
              <div>
                <span>{{'general.units.number'|translate}}: </span>
                <span class="black-text">{{equipmentSalesContract.salesContractNumber}}</span>
              </div>
            </div>
            <div class="icons-row"
              *ngIf="!equipmentSalesContract.imported && isActiveEquipment() && isAllowedToAddEdit()" fxFlex="50"
              fxLayout="row" fxLayoutAlign="end">
              <fa-icon class="action-icon" [matTooltip]="'general.buttons.edit'|translate"
                (click)="editContract(equipmentSalesContract.salesContractId, contractTypes.SALES_CONTRACT)"
                [icon]="faPencil"></fa-icon>
              <fa-icon [matTooltip]="'general.buttons.delete'|translate" class="action-icon"
                (click)="deleteSalesContract($event, equipmentSalesContract)" [icon]="faTrash">
              </fa-icon>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-custom-content" fxLayout="row wrap" fxLayoutAlign="start start">
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.goodsRecipientName}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.recipientName'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.goodsRecipientName}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.goodsRecipientAddress?.street}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.recipientAddress.street'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.goodsRecipientAddress?.street}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.goodsRecipientAddress?.postalCode &&
             !equipmentSalesContract?.goodsRecipientAddress?.city}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.recipientAddress.zipCodeCity'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.goodsRecipientAddress?.postalCode}}
               {{equipmentSalesContract?.goodsRecipientAddress?.city}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.invoiceCustomerName}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.invoiceCustomerName'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.invoiceCustomerName}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.invoiceCustomerAddress?.street}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.invoiceCustomerAddress.street'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.invoiceCustomerAddress?.street}}">
          </mat-form-field>

          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.invoiceCustomerAddress?.postalCode &&
             !equipmentSalesContract?.invoiceCustomerAddress?.city}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.invoiceCustomerAddress.zipCodeCity'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.invoiceCustomerAddress?.postalCode}}
               {{equipmentSalesContract?.invoiceCustomerAddress?.city}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.customerOrderNumber}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.orderNumber'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.customerOrderNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.customerContact}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.customerContact'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.customerContact}}">
          </mat-form-field>

          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.plannedDeliveryDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.plannedDeliveryDate'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentSalesContract?.plannedDeliveryDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>

          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.internalContact}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.internalContact'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.internalContact}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.externalContact}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.externalContact'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.externalContact}}">
          </mat-form-field>

          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.paymentCondition}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.paymentCondition'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.paymentCondition}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.salesContractNumber}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.salesContractNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.cost}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.price'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentSalesContract?.cost | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.status}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.status'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.status | salesContractStatus}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.contractPartner}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.contractPartner}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.amortizationType}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.amortizationType'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentSalesContract?.amortizationType}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.amortizationPeriod}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.amortization'|translate}}</mat-label>
            <input matInput readonly value="{{periodToString(equipmentSalesContract?.amortizationPeriod)}}">
          </mat-form-field>
        </div>
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentSalesContract?.comment}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.comment.s'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentSalesContract?.comment}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="equipmentSalesContract.documents.length > 0">
        <bh-contract-attachments [documents]="equipmentSalesContract.documents">
        </bh-contract-attachments>
      </div>
    </mat-expansion-panel>
  </div>

  <!--Service Contract-->
  <div *ngIf="equipmentServiceContracts">
    <mat-expansion-panel *ngFor="let equipmentServiceContract of equipmentServiceContracts" class="custom-panel">
      <mat-expansion-panel-header class="custom-panel-header">
        <mat-panel-title class="custom-panel-title" fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
          <div class="circle-div" fxLayoutAlign="center center">
            <fa-icon class="white-icon" [icon]="faWrench"></fa-icon>
          </div>
          <span class="panel-title-text black-text">
            {{'modules.equipment.contract.contractTypes.serviceContract'|translate|uppercase}}
          </span>
        </mat-panel-title>
        <mat-panel-description class="custom-panel-description" fxFlex="66"
         fxLayout="row" fxLayoutAlign="start center">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="description-div">
            <div fxFlex="50">
              <div>
                <span>{{'general.units.number'|translate}}: </span>
                <span>{{equipmentServiceContract.serviceContractNumber}}</span>
              </div>
            </div>
            <div *ngIf="!equipmentServiceContract.imported && isActiveEquipment() && isAllowedToAddEdit()" fxFlex="50"
              fxLayout="row" fxLayoutAlign="end">
              <div class="icons-row" fxLayout="row" fxLayoutAlign="start center">
                <fa-icon [matTooltip]="'general.buttons.edit'|translate" class="action-icon" [icon]="faPencil"
                  (click)="editContract(equipmentServiceContract.serviceContractId, contractTypes.SERVICE_CONTRACT)">
                </fa-icon>
                <fa-icon [matTooltip]="'general.buttons.delete'|translate" class="action-icon"
                  (click)="deleteServiceContract($event, equipmentServiceContract)" [icon]="faTrash">
                </fa-icon>
              </div>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-custom-content" fxLayout="row wrap" fxLayoutAlign="start start">
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentServiceContract?.serviceContractNumber}"
           floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentServiceContract?.serviceContractNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentServiceContract?.contractPartner}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentServiceContract?.contractPartner}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentServiceContract?.monthlyRate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.monthlyRate'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentServiceContract?.monthlyRate}}">
          </mat-form-field>
        </div>
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentServiceContract?.composedOf}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.composedOf'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentServiceContract?.composedOf}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentServiceContract?.comment}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.comment.s'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentServiceContract?.comment}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="equipmentServiceContract && equipmentServiceContract.documents.length > 0">
        <bh-contract-attachments [documents]="equipmentServiceContract.documents">
        </bh-contract-attachments>
      </div>
    </mat-expansion-panel>
  </div>

  <!--Insurance Contract-->
  <div *ngIf="equipmentInsuranceContracts">
    <mat-expansion-panel *ngFor="let equipmentInsuranceContract of equipmentInsuranceContracts" class="custom-panel">
      <mat-expansion-panel-header class="custom-panel-header">
        <mat-panel-title class="custom-panel-title" fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
          <div class="circle-div" fxLayoutAlign="center center">
            <fa-icon class="white-icon" [icon]="faShieldCheck"></fa-icon>
          </div>
          <span class="panel-title-text black-text">
            {{'modules.equipment.contract.contractTypes.insuranceContract'|translate|uppercase}}
          </span>
        </mat-panel-title>
        <mat-panel-description class="custom-panel-description"
         fxFlex="66"
         fxLayout="row"
         fxLayoutAlign="start center">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="description-div">
            <div fxFlex="50">
              <div>
                <span>{{'general.units.number'|translate}}: </span>
                <span>{{equipmentInsuranceContract.insuranceContractNumber}}</span>
              </div>
            </div>
            <div *ngIf="!equipmentInsuranceContract.imported && isActiveEquipment() &&
             isAllowedToAddEdit()" fxFlex="50"
              fxLayout="row" fxLayoutAlign="end">
              <div class="icons-row" fxLayout="row" fxLayoutAlign="start center">
                <fa-icon [matTooltip]="'general.buttons.edit'|translate"
                 class="action-icon"
                 (click)="editContract(equipmentInsuranceContract.insuranceContractId,
                  contractTypes.INSURANCE_CONTRACT)" [icon]="faPencil"></fa-icon>
                <fa-icon [matTooltip]="'general.buttons.delete'|translate" class="action-icon"
                  (click)="deleteInsuranceContract($event, equipmentInsuranceContract)" [icon]="faTrash"></fa-icon>
              </div>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-custom-content" fxLayout="row wrap" fxLayoutAlign="start start">
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.insuranceContractNumber}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.insuranceContractNumber'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentInsuranceContract?.insuranceContractNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.contractStartDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'general.labels.beginning'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentInsuranceContract?.contractStartDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.contractEndDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'general.labels.end'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentInsuranceContract?.contractEndDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.costsPerMonth}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.costsPerMonth'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentInsuranceContract?.costsPerMonth | currency:'EUR':'symbol':'1.2-2':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.insuranceContractMileageType}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.mileageLimit'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentInsuranceContract?.insuranceContractMileageType | insuranceContractMileageType}}">
          </mat-form-field>

          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.mileageLimitPerYear}"
           floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.mileageLimitPerYear'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
            <input matInput readonly value="{{equipmentInsuranceContract?.mileageLimitPerYear | dimensionUnitConverter: 'km'}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.insuranceContractType}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.insuranceContractType'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentInsuranceContract?.insuranceContractType | insuranceContractType}}">
          </mat-form-field>
        </div>
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentInsuranceContract?.comment}"
            floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'general.comment.s'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentInsuranceContract?.comment}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="equipmentInsuranceContract.documents.length > 0">
        <bh-contract-attachments [documents]="equipmentInsuranceContract.documents">
        </bh-contract-attachments>
      </div>
    </mat-expansion-panel>
  </div>

  <!--Leasing Contract-->
  <div *ngIf="equipmentLeasingContracts">
    <mat-expansion-panel *ngFor="let equipmentLeasingContract of equipmentLeasingContracts" class="custom-panel">
      <mat-expansion-panel-header class="custom-panel-header">
        <mat-panel-title class="custom-panel-title" fxFlex="33" fxLayout="row" fxLayoutAlign="start center">
          <div class="circle-div" fxLayoutAlign="center center">
            <fa-icon class="white-icon" [icon]="faKey"></fa-icon>
          </div>
          <span class="panel-title-text black-text">
            {{'modules.equipment.contract.contractTypes.leasingContract'|translate|uppercase}}
          </span>
        </mat-panel-title>
        <mat-panel-description class="custom-panel-description"
         fxFlex="66" fxLayout="row" fxLayoutAlign="start center">
          <div fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="description-div">
            <div fxFlex="50">
              <div>
                <span>{{'general.units.number'|translate}}: </span>
                <span>{{equipmentLeasingContract.leasingContractNumber}}</span>
              </div>
            </div>
            <div *ngIf="!equipmentLeasingContract.imported && isActiveEquipment() && isAllowedToAddEdit()" fxFlex="50"
              fxLayout="row" fxLayoutAlign="end">
              <div class="icons-row" fxLayout="row" fxLayoutAlign="start center">
                <fa-icon [matTooltip]="'general.buttons.edit'|translate" class="action-icon"
                 (click)="editContract(equipmentLeasingContract.leasingContractId, contractTypes.LEASING_CONTRACT)"
                  [icon]="faPencil"></fa-icon>
                <fa-icon [matTooltip]="'general.buttons.delete'|translate" class="action-icon"
                  (click)="deleteLeasingContract($event, equipmentLeasingContract)" [icon]="faTrash"></fa-icon>
              </div>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-custom-content" fxLayout="row wrap" fxLayoutAlign="start start">
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.leasingContractNumber}"
           floatLabel="always" fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractNumber'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentLeasingContract?.leasingContractNumber}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.contractPartner}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'modules.equipment.contract.contractPartner'|translate}}</mat-label>
            <input matInput readonly value="{{equipmentLeasingContract?.contractPartner}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.contractStartDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'general.labels.beginning'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentLeasingContract?.contractStartDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.contractEndDate}" floatLabel="always"
            fxFlex="33" class="custom-field">
            <mat-label>{{'general.labels.end'|translate}}</mat-label>
            <input matInput readonly
              value="{{equipmentLeasingContract?.contractEndDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}">
          </mat-form-field>
          <mat-form-field fxFlex="33" [ngClass]="{'invisible':!equipmentLeasingContract?.operatingHoursLimit}"
            class="custom-field">
            <mat-label>{{'modules.equipment.contract.operatingHoursLimit'|translate}}</mat-label>
            <input type="number" readonly
              matInput min="0" value="{{equipmentLeasingContract?.operatingHoursLimit}}">
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="33">
            <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.mileageLimit}" class="custom-field">
              <mat-label>{{'modules.equipment.contract.agreedPeriod'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
              <input
               type="number" readonly matInput
                min="0" value="{{equipmentLeasingContract?.mileageLimit | dimensionUnitConverter:'km'}}">
            </mat-form-field>

            <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.limitationPeriod ||
            (!equipmentLeasingContract?.mileageLimit && !equipmentLeasingContract?.operatingHoursLimit)}"
            class="custom-field">
              <mat-select value="{{equipmentLeasingContract?.limitationPeriod}}" disabled>
                <mat-option value="{{limitationPeriods.MONTHLY}}">
                  {{'modules.equipment.contract.contractPeriodTypes.monthly'|translate}}</mat-option>
                <mat-option value="{{limitationPeriods.YEARLY}}">
                  {{'modules.equipment.contract.contractPeriodTypes.yearly'|translate}}</mat-option>
                <mat-option value="{{limitationPeriods.CONTRACT_PERIOD}}">
                  {{'modules.equipment.contract.contractPeriodTypes.contractPeriod'|translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="fields-div" fxLayout="row wrap" fxLayoutAlign="start start">
          <mat-form-field [ngClass]="{'invisible':!equipmentLeasingContract?.comment}" floatLabel="always" fxFlex="33"
            class="custom-field">
            <mat-label>{{'general.comment.s'|translate}}</mat-label>
            <textarea matInput readonly value="{{equipmentLeasingContract?.comment}}"
              cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4">
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="equipmentLeasingContract.documents.length > 0">
        <bh-contract-attachments [documents]="equipmentLeasingContract.documents">
        </bh-contract-attachments>
      </div>
    </mat-expansion-panel>
  </div>
</div>

<ng-template #noContracts>
  <span class="bold-text">{{'general.labels.noAnyFound'|translate:
    {value: 'modules.equipment.contract.contractData'|translate} }}</span>
</ng-template>
