import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MaintenanceTaskCompletedDataSource } from '../shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import { SearchFilterActivatedView } from '../../../../shared/contract/activated-view.interface';
import { FilterType } from '../../../../shared/contract/filter/filter-type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateFilterCommand } from '../../../../shared/contract/filter/update-filter-command.interface';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ButtonInfo } from '../../../../shared/contract/button-info';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DisplayService } from 'app/shared/display.service';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-completed-list',
  templateUrl: './maintenance-task-completed-list.component.html',
  styleUrls: ['./maintenance-task-completed-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceTaskCompletedListComponent implements SearchFilterActivatedView, OnInit {

  public filters: FilterType[] = this.maintenanceDataSource.filters;
  public readonly onFiltersUpdated: EventEmitter<any> = this.maintenanceDataSource.onFiltersUpdated;
  public readonly onTotalCountUpdated: EventEmitter<number> = new EventEmitter<number>();
  public routeButton: ButtonInfo = null;
  public searchForm: UntypedFormGroup;
  public readonly searchSuggestionsField = 'maintenanceTaskCompletedListSuggestions';


  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  constructor(
    public maintenanceDataSource: MaintenanceTaskCompletedDataSource,
    public displayService: DisplayService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.maintenanceDataSource.updateListing();
    this.totalCountListener();
    this.handleRouting();
  }

  public openDetails(): void {
    this.displayService.displayTableAndDetails();
  }

  public pageChange({ pageIndex, pageSize }: PageEvent): void {
    this.maintenanceDataSource.updateListing(pageIndex, pageSize);
  }

  public sortData(sort: Sort): void {
    this.maintenanceDataSource.sort = sort;
    this.maintenanceDataSource.updateListing();
  }

  private handleRouting(): void {
    if (!this.isCurrentTaskSelected()) {
      this.maintenanceDataSource.selectCurrentOrDefault();
    } else {
      this.displayService.displayTableAndDetails();
    }
  }

  private isCurrentTaskSelected(): boolean {
    return Boolean(this.route.snapshot.firstChild?.params?.id);
  }

  private totalCountListener(): void {
    this.maintenanceDataSource.totalCount
    .pipe(untilDestroyed(this))
    .subscribe(count => this.onTotalCountUpdated.emit(count));
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(term => this.updateOnSearch(term));

    this.searchForm.patchValue({terms: this.maintenanceDataSource.searchTerm}, { emitEvent: false });
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.maintenanceDataSource.updateFilterParams(commands);
    this.maintenanceDataSource.updateListing(0);
  }

  private updateOnSearch(searchTerm: string): void {
    this.maintenanceDataSource.searchTerm = searchTerm;
    this.maintenanceDataSource.updateListing(0);
  }
}
