<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="bulkItemName">
    <th mat-header-cell class="custom-cell name-cell" *matHeaderCellDef>
      {{'general.name.s'|translate}}</th>
    <td mat-cell class="custom-cell name-cell"
        *matCellDef="let amount" fxLayout="row"
        fxLayoutAlign="space-between space-between">
      <div fxLayout="column" fxLayoutAlign="space-evenly start">
        <div class="item-name" [class.link]="canSeeLinkBulkItem"
             (click)="canSeeLinkBulkItem && navigateToBulkItemStocks(amount.transferItemId)">
            <span>{{ amount.transferItemName }}</span>
          <mat-icon *ngIf="canSeeLinkBulkItem"
            [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.bulkItems'|translate }"
            (click)="navigateToBulkItemStocks(amount.transferItemId)" class="link-icon">
            open_in_new
          </mat-icon>
        </div>
        <div>
          {{ amount.transferItemNumber }}
        </div>
        <div class="item-stock" [ngClass]="{'unknown-stock': amount.unknownStock}">
          {{'general.origin'|translate}}: {{ amount.stockName }}
          <mat-icon *ngIf="!amount.unknownStock && canSeeLinkStock"
                    [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.stock.s'|translate }"
                    (click)="navigateToStock(amount.stockId)" class="link-icon">
            open_in_new
          </mat-icon>
        </div>
      </div>

      <div *ngIf="amount.currentChargePeriod" fxLayout="column"  fxLayoutAlign="start flex-end">
        <div *ngIf="amount.currentChargePeriod.status"
             class="amount-status {{resolveStatusClass(amount.currentChargePeriod.status)}}"
             [matTooltip]="amount.currentChargePeriod.comment"
             [matTooltipPosition]="'above'"
        >
          {{ amount.currentChargePeriod.status | typeOfUseStatus }}
        </div>
        <div *ngIf="amount.currentChargePeriod.statusStartDate" class="equipment-field" fxLayout="row">  {{"general.from" | translate}}: {{ amount.currentChargePeriod.statusStartDate | date:'dd.MM.yyyy HH:mm':'':getCurrentLocale() }}</div>
        <div *ngIf="amount.currentChargePeriod.statusEndDate" class="equipment-field" fxLayout="row">  {{"general.to" | translate}}: {{ amount.currentChargePeriod.statusEndDate | date:'dd.MM.yyyy HH:mm':'':getCurrentLocale() }}</div>
        <div class="equipment-field" *ngIf="amount.currentChargePeriod.reason" fxLayout="row">
          <fa-icon class="mr-5 custom-icon" [icon]="iconService.resolveReasonIcon(amount.currentChargePeriod.reason)"></fa-icon>
          {{amount.currentChargePeriod.reason | typeOfUseStatusReason}}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell class="custom-cell amount-cell" *matHeaderCellDef>
      {{'general.quantity'|translate}}</th>
    <td mat-cell class="custom-cell amount-cell" *matCellDef="let rowItem">
      <div>
        {{ rowItem.amountAvailable | dimensionUnitConverter: getUnit(rowItem): 3 }} {{ getUnit(rowItem) | dimensionUnitDisplay }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell class="custom-cell edit-cell" *matHeaderCellDef>{{'general.actions'|translate}}
    </th>
    <td mat-cell class="custom-cell edit-cell" [ngClass]="isFirefox && 'edit-cell__firefox'" *matCellDef="let rowItem">
      <button mat-button (click)="openTransferToStock(rowItem)">
        <div fxLayout="row">
                      <span fxFlex="80">
                        {{'modules.disposition.projectDispositions.backTo'|translate:{ value:
                          'general.stock.s'|translate } }}
                      </span>
          <fa-icon [icon]="faArrowRight" fxFlex="20"></fa-icon>
        </div>
      </button>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: bulkItemColumns;"></tr>
</table>
