<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{'modules.dashboard.widget.title'|translate}}</h1>
  <div mat-dialog-content>
    <ng-content></ng-content>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button mat-button (click)="onClose(false)">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button mat-button color="primary" (click)="onClose(true)" cdkFocusInitial>
      {{'general.buttons.ok'|translate}}
    </button>
  </div>
</div>
