import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { KeycloakService } from '../../../../../../core/keycloak';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { BulkItemTransferDatasource } from '../../../../shared/bulk-item-transfer.datasource';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faClipboardList, faExchange } from '@fortawesome/pro-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { CustomerLabel } from '../../../../../../shared/contract/customer-label.interface';
import { BulkItemCategoryCount } from '../../../../contract/category-count.interface';
import { BulkItemTypeCount } from '../../../../contract/type-count.interface';
import { DisplayService } from '../../../../../../shared/display.service';
import { RouterHistory } from '../../../../../../shared/router-history';
import { ColumnDefinition } from '../../../../../../shared/column-definition';
import { BulkItemTransferColumnService } from '../../../../shared/bulk-item-transfer-column.service';
import { MatDialog } from '@angular/material/dialog';
import { faRecycle } from '@fortawesome/pro-light-svg-icons';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BulkItemTypeResolver } from '../../../bulk-item-add-edit/bulk-item-type.resolver';
import { ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';
import { TransferCartDatasource } from 'app/modules/disposition/shared/transfer-cart.datasource';
import { ListType } from '../../../../../../shared/enums/list-type.enum';
import { TabService } from '../../../../../../shared/tab.service';
import { COLUMN_TYPE } from '../../../../../../shared/constants/column-definition-constants';
import {
  TableConfigurationData,
  TableConfigurationDialogComponent,
} from '../../../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { environment } from 'environments/environment';
import { ThumbnailSize } from '../../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-transfer-list',
  templateUrl: 'bulk-item-transfer-list.component.html',
  styleUrls: ['bulk-item-transfer-list.component.scss'],
})
export class BulkItemTransferListComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly faClipBoardList: IconDefinition = faClipboardList;
  public readonly faExchange: IconDefinition = faExchange;
  public readonly faRecycle: IconDefinition = faRecycle;
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly ThumbnailSize = ThumbnailSize;

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private sort: MatSort;

  public searchForm: UntypedFormGroup;
  public filterForm: UntypedFormGroup;
  public labelsSearch: string[] = [];
  public labels: Observable<CustomerLabel[]>;
  public categories: Observable<BulkItemCategoryCount[]>;
  public types: Observable<BulkItemTypeCount[]>;
  public displayedColumns: ColumnDefinition[];
  public defaultSort: Sort = { active: null, direction: null };

  protected componentActive = true;
  private displayModeBeforeInfo = {table: false, split: false, details: false};
  private searchTermSubscription = new Subscription();

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private displayService: DisplayService,
              public bulkItemTransferStore: BulkItemTransferDatasource,
              public transferCartStore: TransferCartDatasource,
              public columnService: BulkItemTransferColumnService,
              private focusMonitor: FocusMonitor,
              private dialog: MatDialog,
              public optionResolver: BulkItemTypeResolver,
              private tabService: TabService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.saveAndModifyDisplayMode();
    this.buildFilterForm();
    this.getFilterOptions();
    this.columnService.selectedColumns.pipe(untilDestroyed(this)).subscribe((selectedColumns: ColumnDefinition[]) => {
      this.displayedColumns = selectedColumns;
    });
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });
    this.setSort();
  }

  public ngAfterViewInit(): void {
    this.tabService.changeTransferTab(ListType.BULK_ITEM_TRANSFER);
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
    this.resetDisplayMode();
  }

  public setSearchTerms(emitEvent: boolean = true): void {
    this.searchForm.patchValue({terms: this.bulkItemTransferStore.searchTerms}, { emitEvent });
  }

  public openConfiguration(): void {
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {columnService: this.columnService} as TableConfigurationData,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((columns: ColumnDefinition[]) => {
      if (columns) {
        this.columnService.selectColumns(columns, this.paginator.pageSize);
        this.bulkItemTransferStore.updateListing();
      }
      this.focusMonitor.stopMonitoring(document.getElementById('configBtn'));
    });
  }

  public getFirstLabels(amount: ViewBulkItemAmount): string[] {
    const labelsClone: string[] = [...amount.bulkItemLabels || []];
    if (this.displayedColumns.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(amount: ViewBulkItemAmount): string[] {
    const labelsClone: string[] = [...amount.bulkItemLabels || []];
    if (this.displayedColumns.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }

  private buildFilterForm(): void {
    this.filterForm = new UntypedFormGroup({
      selectedLabels: new UntypedFormControl(),
      selectedBulkItemTypes: new UntypedFormControl(),
      selectedCategories: new UntypedFormControl(),
    });
    this.setLabelsSearch();
    this.setSelectedCategories();
    this.setSelectedBulkItemTypes();
  }

  private setLabelsSearch(): void {
    this.labelsSearch = this.bulkItemTransferStore.searchLabels;
    this.filterForm.patchValue({selectedLabels: this.bulkItemTransferStore.searchLabels});
  }

  private setSelectedCategories(): void {
    this.filterForm.patchValue({selectedCategories: this.bulkItemTransferStore.categoryFilter})
  }

  private setSelectedBulkItemTypes(): void {
    this.filterForm.patchValue({selectedBulkItemTypes: this.bulkItemTransferStore.bulkItemTypesFilter})
  }

  private getLabels(): void {
    this.labels = this.bulkItemTransferStore.getBulkItemLabels().pipe(
        untilDestroyed(this),
        tap(() => this.setLabelsSearch())
    );
  }

  private getCategories(): void {
    this.categories = this.bulkItemTransferStore.getCategories().pipe(untilDestroyed(this));
  }

  private getTypes(): void {
    this.types = this.bulkItemTransferStore.getTypes().pipe(untilDestroyed(this));
  }

  public onSearchFormType(): void {
    this.initSearchTermListener();
  }

  private initSearchTermListener(): void {
    this.searchTermSubscription.unsubscribe();
    this.searchTermSubscription = this.searchForm.get('terms').valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(() => this.updateOnSearch());
    this.setSearchTerms(false);
  }

  private setSort(): void {
    const { active, direction } = this.bulkItemTransferStore.sort ?? {};
    this.sort.active = active;
    this.sort.direction = direction;
    this.sort._stateChanges.next();
  }

  public onSearchFormClick(): void {
    this.bulkItemTransferStore.updateListing(0, this.paginator.pageSize);
  }

  public updateOnSearch(): void {
    this.bulkItemTransferStore.searchTerms = this.termsValue;
    this.bulkItemTransferStore.updateListing(0);
  }

  public selectBulkItemTypeAndUpdate(): void {
    this.selectBulkItemType();
    this.resetPagination();
  }

  public selectLabelsAndUpdate(labelNames: string[]): void {
    this.selectLabels(labelNames);
    this.resetPagination();
  }

  public selectCategoryAndUpdate(): void {
    this.selectCategory();
    this.resetPagination();
  }

  private selectBulkItemType() {
    this.bulkItemTransferStore.bulkItemTypesFilter = this.filterForm.controls['selectedBulkItemTypes'].value;
    this.getLabels();
    this.getCategories();
  }

  private selectLabels(labelNames: string[]): void {
    this.labelsSearch = labelNames;
    this.bulkItemTransferStore.searchLabels = this.labelsSearch;
    this.getTypes();
    this.getCategories();
  }

  private selectCategory() {
    this.bulkItemTransferStore.categoryFilter = this.filterForm.controls['selectedCategories'].value;
    this.getTypes();
    this.getLabels();
  }

  public reset(): void {
    this.bulkItemTransferStore.reset();
    this.initSearchTermListener();
    this.filterForm.reset({}, { emitEvent: false });
    this.setSort();
    this.getFilterOptions();
  }

  public onPaginateChange(event: PageEvent): void {
    this.bulkItemTransferStore.updateListing(event.pageIndex, event.pageSize);
  }

  public sortData(sort: Sort): void {
    this.bulkItemTransferStore.sort = sort;
    this.bulkItemTransferStore.updateListing(this.paginator.pageIndex, this.paginator.pageSize);
  }

  private resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.bulkItemTransferStore.updateListing(this.paginator.pageIndex, this.paginator.pageSize);
  }

  public openTransferCart(): void {
    if (!this.transferCartStore.transferCartEmpty()) {
      this.router.navigate(['transfer/stock-to-project-cart']);
    }
  }

  public navigateToBulkItem(bulkItemId: string): void {
    this.displayService.displayTableAndDetails();
    this.router.navigate([`assets/bulk-item/list/${bulkItemId}/general`]);
  }

  public navigateToStock(stockId: string): void {
    this.router.navigate([`sites/stocks/list/${stockId}/general`]);
  }

  get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  public getFilterOptions(): void {
    this.bulkItemTransferStore.getFilterOptions();
    this.getCategories();
    this.getLabels();
    this.getTypes();
  }

  private saveAndModifyDisplayMode(): void {
    this.displayModeBeforeInfo = {
      table: this.displayService.isTableFullscreen(),
      split: this.displayService.isTableAndDetails(),
      details: this.displayService.isDetailsFullscreen(),
    };
    this.displayService.displayTableFullscreen();
  }

  public hasAccessToStock(): boolean {
    return this.authService.hasAuthority(Authorities.STOCK_VIEW);
  }

  private resetDisplayMode(): void {
    if (this.displayModeBeforeInfo?.table) {
      this.displayService.displayTableFullscreen();
    } else if (this.displayModeBeforeInfo?.split) {
      this.displayService.displayTableAndDetails();
    } else if (this.displayModeBeforeInfo?.details) {
      this.displayService.displayDetailsFullscreen();
    }
  }
}
