import { EquipmentTelematicsDataseries } from './equipment-telematics-dataseries';
import { EquipmentTelematicsMultiseries } from './equipment-telematics-multiseries';

export class EquipmentTelematics {

  public static fromData(data: EquipmentTelematics) {
    return data && (data.dataSeries || data.multiSeries)
        ? new EquipmentTelematics(EquipmentTelematicsDataseries.fromData(data.dataSeries),
         EquipmentTelematicsMultiseries.fromData(data.multiSeries))
        : null;
  }

  constructor(
      public dataSeries: EquipmentTelematicsDataseries,
      public multiSeries: EquipmentTelematicsMultiseries) {
  }

}
