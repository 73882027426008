import { StockType } from '../../../shared/enums/stock-type';
import { BulkItemType } from './bulk-item-type';
import { Address } from '../../organisation/contract/address.interface';
import { CurrentLocationInfo, SearchStockEquipmentAmount } from './search-stock-equipment-amount.interface';
import { TransferItemUnit } from './transfer-item-unit';
import { IAmountStatusPeriod } from './view-project-amount.interface';
import { EquipmentLocationType } from 'app/modules/maintenance/shared/contract/equipment-location-type-enum';

export class ViewAmount {

  private constructor(
   public amountId?: string,
   public customerId?: string,
   public equipmentId?: string,
   public equipmentName?: string,
   public equipmentContainerId?: string,
   public equipmentCustomerSerialNumber?: string,
   public bulkItemId?: string,
   public bulkItemName?: string,
   public bulkItemNumber?: string,
   public nonReturnable?: boolean,
   public bulkItemCategory?: string,
   public bulkItemBGLCode?: string,
   public bulkItemBALCode?: string,
   public bulkItemType?: BulkItemType,
   public bulkItemUnit?: TransferItemUnit,
   public manufacturerId?: string,
   public supplierId?: string,
   public bulkItemLabels?: string[],
   public currentLocation?: CurrentLocationInfo,
   public stockId?: string,
   public stockName?: string,
   public stockType?: StockType,
   public stockOrganisationId?: string,
   public stockOrganisationName?: string,
   public stockAddress?: Address,
   public amountAvailable?: number,
   public amountTotal?: number,
   public unknownStock?: boolean,
   public submittedBy?: string,
   public submitTimestamp?: string,
   public subEquipmentList?: ViewAmount[],
   public thumbnailKey?: string,
   public equipmentTypeCategory2?: string,
   public currentProjectId?: string,
   public currentProjectName?: string,
   public currentChargePeriod?: IAmountStatusPeriod,
   public amountStatusPeriods?: Record<string, IAmountStatusPeriod>,
  ) {}

  public static fromSearchStockEquipmentAmount(amount: SearchStockEquipmentAmount): ViewAmount {
    let subEquipments: ViewAmount[] = [];
    if (amount.subEquipmentList) {
      amount.subEquipmentList.forEach(subEquipment => {
        subEquipments.push(this.fromSearchStockEquipmentAmount(subEquipment));
      });
    }
    return new ViewAmount(
      amount.amountId,
      amount.customerId,
      amount.equipmentId,
      amount.equipmentName || amount.equipmentModel,
      amount.containerId,
      amount.equipmentCustomerSerialNumber,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      amount.manufacturerId,
      null,
      null,
      amount.currentLocation,
      amount.stockId,
      null,
      null,
      null,
      null,
      amount.stockAddress,
      amount.amountAvailable,
      amount.amountTotal,
      false,
      null,
      null,
      subEquipments,
      amount.thumbnailKey,
      amount.equipmentTypeCategory2,
      amount.currentLocation?.type === EquipmentLocationType.PROJECT ? amount.currentLocation?.id : null,
      null,
      amount.currentChargePeriod,
      amount.amountStatusPeriods,
    )
  }
}
