import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { Sort } from '@angular/material/sort';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { DisplayService } from '../../../../../shared/display.service';
import { KeyCodes } from '../../../../../shared/enums/key-codes.enum';
import { BaseEquipmentListComponent } from '../base/base-equipment-list-component';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { EquipmentsService } from '../../../shared/equipments.service';
import { EquipmentCheckerService } from '../../../shared/services/equipment-checker.service';
import { ColumnDefinition } from '../../../../../shared/column-definition';
import { UserConfigurationService } from '../../../../../shared/services/user-configuration.service';
import { SearchEquipment } from '../../../contract/search-equipment.interface';
import { EquipmentAddLabelDialogComponent } from '../../../shared/equipment-add-label-dialog/equipment-add-label-dialog.component';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { EquipmentListExportDialogComponent } from '../equipment-list-export-dialog/equipment-list-export-dialog.component';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { EquipmentColumnService } from '../../../shared/equipment-column.service';
import { ListType } from '../../../../../shared/enums/list-type.enum';
import { TabService } from '../../../../../shared/tab.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { EquipmentAddEditComponent } from '../../equipment-add-edit/equipment-add-edit.component';
import { dialogResults } from '../../../../../shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { matomoCategories } from '../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../assets/matomo/matomo-actions.enum';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import {faAngleUp, faTruck} from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-duotone-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatomoTracker } from 'ngx-matomo';
import _ from 'lodash';
import { CurrentItemPaginatorUtils } from 'app/shared/directives/current-item-paginator/current-item-paginator-utils.class';
import { COLUMN_TYPE } from '../../../../../shared/constants/column-definition-constants';
import { TableConfigurationData, TableConfigurationDialogComponent } from '../../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { EquipmentStatusCategoryPipe } from '../../../../../shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { GENERAL_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import {ThumbnailSize} from '../../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';
import { ColumnDefinitionKeys } from 'app/shared/enums/column-definition-keys.enum';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-list',
  templateUrl: 'equipment-list.component.html',
  styleUrls: ['equipment-list.component.scss'],
  animations: [
    trigger('rowsExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', borderWidth: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
})
export class EquipmentListComponent extends BaseEquipmentListComponent
 implements SearchFilterActivatedView, OnInit, AfterViewInit, OnDestroy {
  public selectedColumns: ColumnDefinition[];
  public displayedColumns: string[];
  public readonly columnConfigs = this.columnService.columnConfigs;
  public selectedEquipments: Set<string> = new Set();
  public multiselectActive: boolean;
  public allEquipments: SearchEquipment[] = [];
  public expandedEquipments: SearchEquipment[] = [];
  public previousExpandedEquipments: SearchEquipment[] = [];
  public readonly faFileExcel: IconDefinition = faFileExcel;
  public readonly faAngleUp: IconDefinition = faAngleUp;
  public readonly faAngleDown: IconDefinition = faAngleDown;
  public readonly faTruck: IconDefinition = faTruck;
  public readonly isIdentifierOfEquipment = CurrentItemPaginatorUtils.isIdentifierOfEquipment;
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly GENERAL_COLUMN_DEF = GENERAL_COLUMN_DEF;
  public readonly ThumbnailSize = ThumbnailSize;
  public readonly COLUMN_SUB_EQUIPMENT = ColumnDefinitionKeys.SUB_EQUIPMENT;
  public readonly searchSuggestionsField = 'equipmentListSuggestions';

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  @ViewChild('multiMenu', { static: true }) private multiMenu: MatDrawer;

  constructor(public equipmentStore: EquipmentsDataSource,
              protected columnService: EquipmentColumnService,
              public displayService: DisplayService,
              public equipmentCheckerService: EquipmentCheckerService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected equipmentService: EquipmentsService,
              protected userConfigurationService: UserConfigurationService,
              protected organisationService: OrganisationsService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
              private dialog: MatDialog,
              private focusMonitor: FocusMonitor,
              private tabService: TabService,
              private detector: ChangeDetectorRef,
              private matomoTracker: MatomoTracker,
              protected equipmentStatusNamePipe: EquipmentStatusNamePipe) {
    super(
      authService,
      router,
      route,
      equipmentStore,
      equipmentService,
      userConfigurationService,
      organisationService,
      routerHistory,
      languageService,
      equipmentStatusCategoryPipe,
      equipmentStatusNamePipe
    );
    const create = this.route.snapshot.data['create'];
    if (create) {
      this.dialog.open(EquipmentAddEditComponent, {disableClose: true}).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['assets/equipment/list']);
        }
      });
    } else if (!this.route.snapshot.firstChild) {
      equipmentStore.selectCurrentEquipmentOrDefault();
    } else {
      displayService.displayTableAndDetails();
    }
  }

  public get isSearchCriteriaApplied(): boolean {
    return this.equipmentStore.isSearchFiltersApplied || Boolean(this.equipmentStore.searchTerms);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tabService.changeAssetTab(ListType.EQUIPMENTS);
    this.columnService.selectedColumns.pipe(untilDestroyed(this)).subscribe((selectedColumns: ColumnDefinition[]) => {
      this.selectedColumns = selectedColumns;
    });
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
    this.multiselectActive = false;
    this.equipmentStore.equipments.pipe(untilDestroyed(this)).subscribe((equipments: SearchEquipment[]) => {
      this.allEquipments = equipments;

      if (this.isSearchCriteriaApplied) {
        this.expandedEquipments = [];
        this.allEquipments
          .filter(equipment => equipment.numberOfSubequipments > 0)
          .forEach(equipment => this.expandedEquipments.push(equipment))
      } else {
        this.expandedEquipments = this.previousExpandedEquipments;
      }
      this.checkSubEquipmentColumn();
    });
    this.columnService.displayedColumns.pipe(untilDestroyed(this)).subscribe((columns: string[]) => {
      this.displayedColumns = _.cloneDeep(columns);
      this.checkSubEquipmentColumn();
    });
  }

  public ngAfterViewInit(): void {
    // prevent button from staying focused after action
    this.focusMonitor.stopMonitoring(document.getElementById('labelButton'));
    this.expandDetailsIfFirstView();
  }

  public hasAnySubequipment(): boolean {
    let hasSubequipment = false;
    this.allEquipments.forEach((equipment: SearchEquipment) => {
      if (equipment.subEquipmentList && equipment.subEquipmentList.length !== 0) {
        hasSubequipment = true;
      }
    });
    return hasSubequipment;
  }

  public ngOnDestroy(): void {
    if (this.multiselectActive) {
      this.selectedEquipments.clear();
      this.selectedColumns = this.selectedColumns.reverse();
      this.selectedColumns.pop();
      this.selectedColumns.reverse();
      this.columnService.selectColumns(this.selectedColumns, this.paginator.pageSize);
      this.multiMenu.close();
    }
  }

  public onPaginateChange(event: PageEvent): void {
    this.equipmentStore.updateListing(event.pageIndex, event.pageSize);
  }

  public openDetails(): void {
    if (!this.multiselectActive) {
      this.displayService.displayTableAndDetails();
    }
  }

  public onSearchFormClick(): void {
    this.equipmentStore.updateListing(0, this.paginator.pageSize);
  }

  public sortData(sort: Sort): void {
    this.equipmentStore.sort = sort;
    this.equipmentStore.updateListing();
  }

  public openConfiguration(): void {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.COLUMN_CONFIG_OPEN);
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {
        columnService: this.columnService,
        multiselectActive: this.multiselectActive,
        matomoCategory: matomoCategories.EQUIPMENT_LIST,
      } as TableConfigurationData,
      restoreFocus: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((columns: ColumnDefinition[]) => {
      if (columns) {
        this.columnService.selectColumns(columns, this.paginator.pageSize);
        this.equipmentStore.updateListing();
      }
      this.focusMonitor.stopMonitoring(document.getElementById('configBtn'));
    });
  }

  public getFirstLabels(equipment: SearchEquipment, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.labels || []];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(equipment: SearchEquipment, limitToTwo?: boolean): string[] {
    if (this.selectedColumns == null) {
      return [];
    }
    const labelsClone: string[] = [...equipment.labels || []];
    if (limitToTwo === true || this.selectedColumns.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }

  public toggleMultiselect(): void {
    this.multiselectActive = !this.multiselectActive;
    if (this.multiselectActive) {
      this.displayService.displayTableFullscreen();
      const select: ColumnDefinition = {
        cdkColumnDef: 'select',
        type: 'select',
        title: 'select',
        readableName: 'select',
        valueCallback: null,
      };
      this.selectedColumns.unshift(select);
    } else {
      this.emptySelection();
      this.selectedColumns.shift();
      this.multiMenu.toggle();
    }
    this.columnService.selectColumns(this.selectedColumns, this.paginator.pageSize);
  }

  public elementsSelected(): boolean {
    return this.selectedEquipments.size > 0;
  }

  public hasManageLabelsAuthority(): boolean {
    return this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_LABELS);
  }

  public selectEquipment(equipment: SearchEquipment): void {
    if (this.subequipmentsExist(equipment)) {
      let index = this.expandedEquipments.findIndex(expandedEquipment => expandedEquipment.equipmentId ===
        equipment.equipmentId);
      if (index !== - 1) {
        this.expandedEquipments.splice(index, 1);
        this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.EQUIPMENT_SUBEQUIPMENT_COLLAPSE);
      } else {
        this.expandedEquipments.push(equipment);
        this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.EQUIPMENT_SUBEQUIPMENT_ELAPSE);
      }
      this.previousExpandedEquipments = this.expandedEquipments;
    }
  }

  public checkMultiselect(equipment: SearchEquipment): void {
    if (this.multiselectActive) {
      if (this.selectedEquipments.has(equipment.equipmentId)) {
        this.selectedEquipments.delete(equipment.equipmentId);
      } else {
        this.selectedEquipments.add(equipment.equipmentId);
      }
    }
  }

  public emptySelection(): void {
    this.selectedEquipments.clear();
  }

  public onCheckboxToggle(equipment: SearchEquipment, event: MatCheckboxChange): void {
    if (event.checked) {
      this.checkMultiselect(equipment);
    } else {
      this.selectedEquipments.delete(equipment.equipmentId);
    }
  }

  public selectAll(): void {
    this.equipmentStore.getIds().pipe().subscribe(result => {
      for (let id of result) {
        if (
          (this.equipmentCheckerService.isActiveEquipment(this.allEquipments.find(eq => eq.equipmentId === id))
            || this.equipmentCheckerService.isActiveEquipment(this.getAllSubEquipments().find(subeq => subeq.equipmentId === id)
            ))
          && !this.selectedEquipments.has(id)) {
          this.selectedEquipments.add(id);
        }
      }
    });
  }

  public openLabelDialogue(): void {
    let dialogRef = this.dialog.open(EquipmentAddLabelDialogComponent, {disableClose: true});
    dialogRef.componentInstance.equipmentList = this.selectedEquipments;
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== dialogResults.ABORT) {
        let dialogConf = this.dialog.open(ConfirmationDialogComponent, {disableClose: true});
        dialogConf.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.labelsAdded');
        dialogConf.afterClosed().subscribe(res => {
          if (res === 'no') {
            this.toggleMultiselect();
          }
        });
        this.columnService.selectColumns(this.selectedColumns, this.paginator.pageSize);
      }
    });
  }

  public selected(equipment: SearchEquipment): boolean {
    let res = false;
    this.selectedEquipments.forEach(function (element) {
      if (element === equipment.equipmentId) {
        res = true;
      }
    });
    return res;
  }

  public openEquipmentListExport(): void {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.EXPORT_EQUIPMENT_OPEN);
    this.dialog.open(EquipmentListExportDialogComponent, {
      autoFocus: false,
      width: '400px',
      data: {
        selectedColumns: this.selectedColumns,
      },
    });
    this.focusMonitor.stopMonitoring(document.getElementById('exportBtn'));
  }

  @HostListener('window:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.keyCode === KeyCodes.ESC_KEY) {
      this.displayService.displayTableFullscreen();
    }
  }

  private expandDetailsIfFirstView(): void {
    if (!this.routerHistory.isFirstVisit()) {
      return;
    }

    // find equipment detail tab names dynamically
    const child: string = this.router.config
    .filter(route => route.path === 'assets').map(route => route.children).flat()
    .find(route => route.path === 'equipment').children
    .find(route => route.path === 'list').children
    .find(route => route.path === ':id').children
    .map(route => route.path)
    .filter(path => path.length)
    .find(path => this.routerHistory.getCurrentUrl().endsWith(path));

    if (child) {
      this.displayService.displayTableAndDetails();
    }
  }

  public subequipmentsExist(equipment: SearchEquipment): boolean {
    return equipment.subEquipmentList && equipment.subEquipmentList.length > 0;
  }

  public checkExpanded(element: SearchEquipment): boolean {
    return this.expandedEquipments.findIndex(expandedEquipment => expandedEquipment.equipmentId ===
       element.equipmentId) !== -1;
  }

  public resolveArrowIcon(equipment: SearchEquipment): IconDefinition {
    return this.expandedEquipments.findIndex(expandedEquipment => expandedEquipment.equipmentId ===
       equipment.equipmentId) !== -1 ? this.faAngleUp : this.faAngleDown;
  }

  private getAllSubEquipments(): SearchEquipment[] {
    if (!this.allEquipments) { return []; }
    return this.allEquipments.reduce((subEquipments: SearchEquipment[], equipment: SearchEquipment) =>
      subEquipments.concat(equipment.subEquipmentList || []), []);
  }

  public selectedCell(equipment): void {
    if (this.equipmentCheckerService.isSearchEquipmentLinkedToTelematicsUnit(equipment)) {
      this.equipmentStore.getEquipmentLastTelematicsUpdate(equipment.equipmentId)
        .subscribe((res: Date) => {
          equipment.lastTelematicsUpdate = res;
          this.detector.markForCheck();
        })
    }
  }

  public isFavourite(equipment: SearchEquipment): boolean {
    return equipment.favourite === true;
  }

  private checkSubEquipmentColumn(): void {
    if (this.displayedColumns?.length > 0 && this.displayService.isTableFullscreen()) {
      const hasSubEq = this.hasAnySubequipment();
      const hasSubEqColumn = this.displayedColumns.includes(ColumnDefinitionKeys.SUB_EQUIPMENT);

      if (hasSubEq && !hasSubEqColumn) {
        this.displayedColumns = [...this.displayedColumns, ColumnDefinitionKeys.SUB_EQUIPMENT];
      } else if (!hasSubEq && hasSubEqColumn) {
        this.displayedColumns = this.displayedColumns.filter(col => col !== ColumnDefinitionKeys.SUB_EQUIPMENT);
      }
    }
  }
}
