import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface WindowEventEmitterEvent {
  eventType: keyof GlobalEventHandlers;
  event: WindowEventMap[keyof WindowEventMap];
}

@Injectable()
export class WindowEventService {
  private eventEmitter: EventEmitter<WindowEventEmitterEvent> = new EventEmitter<WindowEventEmitterEvent>();

  constructor() {
    this.attachWindowEvents();
  }

  private attachWindowEvents(): void {
    window.onresize = (event: UIEvent) => {
      this.eventEmitter.emit({
        eventType: 'onresize',
        event: event
      });
    };
  }

  public getObservable(): Observable<WindowEventEmitterEvent> {
    return this.eventEmitter.asObservable();
  }
}
