import { Pipe, PipeTransform } from '@angular/core';
import { ViewEmployee } from 'app/modules/disposition/contract/view-employee.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';

@Pipe({
  name: 'mapMarkerConvertEmployeeView'
})
export class MapMarkerConvertEmployeeViewPipe implements PipeTransform {

  public transform(value: ViewEmployee[]): MapLocationMarker<ViewEmployee>[] {
    return Array.isArray(value) ? this.getMapLocationMarkers(value) : null;
  }

  private getMapLocationMarkers(items: ViewEmployee[]): MapLocationMarker<ViewEmployee>[] {
    return items.filter(Boolean).map(item => ({
      id: item.employeeId,
      location: item?.employeeLocation,
      item,
    }));
  }

}
