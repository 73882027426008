import { ConnectorInformation } from '../connector-information';
import { ScheduleInformation } from '../schedule-information';
import { GpsOverIpConnectionInformation } from './gps-over-ip-connection-information';

export class CreateGpsOverIpConnectorCommand {

  connector: ConnectorInformation;
  schedule: ScheduleInformation;
  connection: GpsOverIpConnectionInformation;
}
