import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { EquipmentTypesService } from 'app/modules/equipment/shared/equipmenttypes.service';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { BehaviorSubject, map, skip, take, zip } from 'rxjs';
import { LastCompletedTasksGridsterItem } from '../contract/last-completed-tasks-gridster-item.interface';
import { MaintenanceTaskService } from 'app/modules/maintenance/tasks/shared/service/maintenance-task.service';
import { MaintenanceCompletedRequestParams } from 'app/modules/maintenance/tasks/shared/contract/request-params/maintenance-completed-request-params.interface';
import { MaintenanceTaskCompletedSearch } from 'app/modules/maintenance/tasks/shared/maintenance-task-completed-search.interface';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { MaintenanceTaskCompletedDataSource } from 'app/modules/maintenance/tasks/shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { GroupedEquipmentType } from 'app/shared/contract/grouped-equipment-type.interface';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class LastCompletedTasksWidgetDatasource {
  private _availableOrganisations = new BehaviorSubject<ViewOrganisation[]>([]);
  private _availableEquipmentTypeCategories = new BehaviorSubject<GroupedEquipmentType[]>([]);
  private _tasks = new BehaviorSubject<MaintenanceTaskCompletedSearch[]>([]);
  public readonly availableOrganisations = this._availableOrganisations.asObservable();
  public readonly availableEquipmentTypeCategories = this._availableEquipmentTypeCategories.asObservable();
  public readonly tasks = this._tasks.asObservable();

  private selectedOrganisationIds: string[] = [];
  private selectedEquipmentTypeIds: string[] = [];
  private groupedTypes: EquipmentTypeFilterView[] = [];
  private readonly defaultParams: MaintenanceCompletedRequestParams = {
    size: 10,
    page: 0,
    sort: 'completedAtDate,desc',
    term: null,
    searchColumns: [],
    organisations: [],
    equipmentStatuses: [],
    articleGroups: [],
    maintenanceTypes: [],
    responsiblePersons: [],
    warningLevels: [],
    labels: [],
    equipmentTypes: [],
    maintenanceCategories: [],
    onlyLatestCompletedForRule: false,
    matchAllLabels: false,
  }

  constructor(
    private maintenanceTaskService: MaintenanceTaskService,
    private equipmentsService: EquipmentsService,
    private equipmentTypesService: EquipmentTypesService,
    private authService: KeycloakService,
    private maintenanceTaskCompletedDataSource: MaintenanceTaskCompletedDataSource,
    private translatableStringResolver: TranslatableStringPipe,
  ) { }

  public initWidgetData(widget: LastCompletedTasksGridsterItem): void {
    this._tasks.next([]);
    zip([
      this._availableOrganisations.pipe(skip(1), take(1)),
      this._availableEquipmentTypeCategories.pipe(skip(1), take(1)),
    ])
    .pipe(take(1))
    .subscribe(() => this.loadWidgetData(widget));

    this.loadAvailableOrganisations();
    this.loadAvailableEquipmentTypeCategories();
  }

  public loadWidgetData({ organisationIds, equipmentTypeCategories }: LastCompletedTasksGridsterItem): void {
    this.selectedOrganisationIds = organisationIds;
    this.selectedEquipmentTypeIds = this.getCategoriesSubTypeIds(equipmentTypeCategories);
    this.loadCompletedTasks({
      ...this.defaultParams,
      organisations: this.selectedOrganisationIds,
      equipmentTypes: this.selectedEquipmentTypeIds,
    });
  }

  public navigateToTaskCompletedList(taskId?: string): void {
    this.maintenanceTaskCompletedDataSource.navigateToTaskCompletedList(
      taskId,
      [
        ...this.getFilterUpdateCommands(FilterTypes.ORGANISATION, this.selectedOrganisationIds),
        ...this.getFilterUpdateCommands(FilterTypes.EQUIPMENT_CLASS, this.selectedEquipmentTypeIds),
      ].filter(Boolean)
    );
  }

  private getCategoriesSubTypeIds(equipmentTypeCategories: string[]): string[] {
    const categoriesSet = new Set(equipmentTypeCategories);
    return this.groupedTypes.reduce(
      (acc, category) => {
        return [
          ...acc,
          ...(categoriesSet.has(category.category1) ? category.subTypes.map(t => t.equipmentTypeId) : [])
        ]
      }, []);
  }

  private loadAvailableOrganisations(): void {
    this.equipmentsService.getOrganisationsTreeByCustomer(this.authService.getUserCustomerId())
      .subscribe(orgs => this._availableOrganisations.next(orgs));
  }

  private loadAvailableEquipmentTypeCategories(): void {
    this.equipmentTypesService.getAllEquipmentTypesGroupedByCategory1()
      .subscribe(groupedTypes => {
        this.groupedTypes = groupedTypes;
        this._availableEquipmentTypeCategories.next(groupedTypes.map(type => ({
          ...type,
          category1TranslatedName: this.translatableStringResolver.transform(type.category1Name)
        })));
      });
  }

  private loadCompletedTasks(params: MaintenanceCompletedRequestParams): void {
    this.maintenanceTaskService.getCompletedTasks(params)
      .pipe(map(({ content }) => content))
      .subscribe(content => this._tasks.next(content));
  }

  private getFilterUpdateCommands(filterType: FilterTypes, ids: string[]): UpdateFilterCommand[] {
    return (ids || []).map(storeName => ({ filterType, storeName, newValue: true }));
  }
}
