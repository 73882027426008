import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { CustomersService } from '../../shared/customers.service';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from '../../../../core/keycloak';
import { RouterHistory } from '../../../../shared/router-history';
import { SubCustomer, SubCustomerFlatNode } from '../../contract/sub-customer.interface';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { firstValueFrom, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CustomerMoveComponent } from '../customer-move/customer-move.component';
import { ViewCustomer } from '../../contract/view-customer.interface';
import {
  SecurityQueryDialogComponent
} from '../../../../shared/components/security-query-dialog/security-query-dialog.component';
import { SecurityQueryProcessType } from '../../../../shared/enums/security-query-process-type.enum';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { DeleteCustomerCommand } from '../../contract/delete-customer-command';

@Component({
  selector: 'bh-sub-customer-list',
  templateUrl: 'sub-customer-list.component.html',
  styleUrls: ['sub-customer-list.component.scss']
})
@UntilDestroy()
export class SubCustomerListComponent extends GuardedNavigableInputComponent implements OnInit {

  public displayedColumns: string[] = [
    'customerName',
    'customerNumber',
    'customerDetails',
  ];

  public treeControl: FlatTreeControl<SubCustomerFlatNode>;
  public treeFlattener: MatTreeFlattener<SubCustomer, SubCustomerFlatNode>;
  public subCustomers: MatTreeFlatDataSource<SubCustomer, SubCustomerFlatNode>;
  public subCustomersList: SubCustomer[];

  constructor(private customersService: CustomersService,
              protected router: Router,
              protected route: ActivatedRoute,
              private dialog: MatDialog,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );

    this.treeControl = new FlatTreeControl<SubCustomerFlatNode>(this.getLevel, this.isExpandable);
    this.subCustomers = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  public ngOnInit(): void {
    this.getCustomers();
  }

  private transformer = (node: SubCustomer, level: number) => {
    return {
      ...node,
      expandable: !!node.children && node.children.length > 0,
      level: level
    };
  };

  private getLevel = (node: SubCustomerFlatNode) => node.level;

  private isExpandable = (node: SubCustomerFlatNode) => node.expandable;

  private getChildren = (node: SubCustomer): Observable<SubCustomer[]> => of(node.children);

  public transformToTree(customers: SubCustomer[]): SubCustomer[] {
    const map = new Map<string, SubCustomer>();
    const roots: SubCustomer[] = [];
    customers.forEach((customer) => {
      map.set(customer.customerId, { ...customer, children: [] });
    });

    map.forEach((customer) => {
      if (customer.parentCustomerId !== null && map.get(customer.parentCustomerId) !== undefined) {
        const parent = map.get(customer.parentCustomerId);
        parent?.children?.push(customer);
      } else {
        roots.push(customer);
      }
    });
    return roots;
  }

  public getChildCustomerIds(customerId: string): string[] {
    const tree = this.subCustomers.data;

    const findNode = (id: string, nodes: SubCustomer[]): SubCustomer | null => {
      for (let node of nodes) {
        if (node.customerId === id) {
          return node;
        }
        const foundChild = findNode(id, node.children || []);
        if (foundChild) {
          return foundChild;
        }
      }
      return null;
    };

    const collectChildIds = (node: SubCustomer): string[] => {
      let ids: string[] = [];
      let stack = [node];

      while (stack.length > 0) {
        const current = stack.pop();
        if (current && current.children) {
          for (let child of current.children) {
            ids.push(child.customerId);
            stack.push(child);
          }
        }
      }

      return ids;
    };

    const rootNode = findNode(customerId, tree);
    return rootNode ? collectChildIds(rootNode) : [];
  }

  private getCustomers(): void {
    this.customersService
      .getAllCustomers()
      .subscribe(
        res => {
          this.subCustomersList = res;
          this.subCustomers.data = this.transformToTree(this.subCustomersList);
          this.treeControl.expandAll();
        });
  }

  public userCanCreate(customer: SubCustomer): boolean {
    return this.isSuperOrPartnerAdmin()
      || (this.getUserCustomerId() && this.getUserCustomerId() === customer.customerId);
  }

  public userCanMove(customer: SubCustomer): boolean {
    return this.isSuperOrPartnerAdmin()
      || (this.getUserCustomerId() && this.getUserCustomerId() !== customer.customerId);
  }

  public userCanDelete(customer: SubCustomer): boolean {
    return this.isSuperOrPartnerAdmin()
      || (this.getUserCustomerId() && this.getUserCustomerId() !== customer.customerId);
  }

  public moveCustomer(customer: SubCustomer) {
    const dialogRef = this.dialog.open(CustomerMoveComponent, {
      disableClose: true,
      width: '450px'
    });

    dialogRef.componentInstance.customerId = customer.customerId;
    dialogRef.componentInstance.partnerId = customer.partnerId;
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.childCustomerIds = this.getChildCustomerIds(customer.customerId);
    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORTEST)).subscribe(result => {
      this.getCustomers();
    });
  }

  public async deleteCustomer(customer: ViewCustomer) {
    let hasSubCustomer = await firstValueFrom(this.customersService.hasSubCustomers(customer.customerId));
    let childCustomerIds = this.getChildCustomerIds(customer.customerId);

    const dialogRef = this.dialog.open(SecurityQueryDialogComponent);
    dialogRef.componentInstance.processType = SecurityQueryProcessType.SUB_CUSTOMER_MANAGE;
    dialogRef.componentInstance.keyValue = customer.customerName;
    if (hasSubCustomer) {
      dialogRef.componentInstance.entities = this.subCustomersList
        .filter((subCustomer) => subCustomer.customerId !== customer.customerId
          && subCustomer.partnerId === customer.partnerId
          && !childCustomerIds.includes(subCustomer.customerId),
        );
    }

    let result = await firstValueFrom(dialogRef.afterClosed());
    if (result.decision === dialogResults.YES) {
      let cmd = new DeleteCustomerCommand();
      cmd.customerId = customer.customerId;
      cmd.newParentCustomerId = hasSubCustomer ? result.selectedValue : null;

      this.customersService.deleteCustomer(cmd)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe(() => this.getCustomers());
    }
  }

  public navigateToEditSubCustomer(id: string) {
    const urlPath: UrlSegment[] = this.route.snapshot.parent.url;
    const path = urlPath[urlPath.length - 1].path;
    this.router.navigate([path, 'edit', id], {queryParams: {editSubCustomer:true}});
  }

}
