import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ViewStock } from '../../../../../contract/view-stock';
import { ViewBulkItem } from '../../../../../contract/view-bulk-item.interface';
import { StockService } from '../../../../../shared/services/stock.service';
import { BulkItemsService } from '../../../../../shared/bulk-items.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewProjectAmount } from '../../../../../contract/view-project-amount.interface';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { OrganisationInfoService } from '../../../../../shared/services/organisation-info.service';
import { ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';

@UntilDestroy()
@Directive()
export abstract class BaseBulkItemViewStocksComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public stocks: ViewStock[] = [];
  public amounts: ViewBulkItemAmount[] = [];
  public projectAmounts: ViewProjectAmount[] = [];
  public bulkItem: ViewBulkItem;

  constructor(protected bulkItemStore: BulkItemDatasource,
              protected stockService: StockService,
              protected organisationsService: OrganisationInfoService,
              protected bulkItemsService: BulkItemsService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.getBulkItem();
  }

  public ngOnDestroy(): void {
  }

  public getStockAmounts(): void {
    this.bulkItemsService.getAmounts(this.bulkItem.bulkItemId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewBulkItemAmount[]) => {
        if (res) {
          this.amounts = res;
        }
        this.sortAndFillStockAmounts();
      });
  }

  private getStocks(): void {
    this.stockService.getStocks()
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewStock[]) => {
        if (res) {
          this.stocks = res;
          this.getStockAmounts();
        }
      });
  }

  private getProjectAmounts(): void {
    this.bulkItemsService.getBulkItemProjectAmounts(this.bulkItem.bulkItemId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewProjectAmount[]) => {
        if (res) {
          this.projectAmounts = res;
        }
      });
  }

  private getBulkItem(): void {
    this.bulkItemStore.currentBulkItem
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewBulkItem) => {
        if (res) {
          this.bulkItem = res;
          this.getStocks();
          this.getProjectAmounts();
        }
      });
  }

  private sortAndFillStockAmounts(): void {
    this.amounts.sort((a, b) => {
      return a.stockName.localeCompare(b.stockName);
    });
    this.amounts.push(...this.getEmptyStocks().map(amount => {
      return {
        amountAvailable: 0,
        amountTotal: 0,
        bulkItemId: this.bulkItem.bulkItemId,
        nonReturnable: this.bulkItem.nonReturnable,
        stockId: amount.stockId,
        stockName: amount.stockName,
        stockOrganisationName: amount.organisationName,
        stockType: amount.stockType
      }
    }));
  }

  private getEmptyStocks(): ViewStock[] {
    return this.stocks.filter(stock => !this.amounts
      .find(amount => amount.stockId === stock.stockId))
      .sort((a, b) => {
        return a.stockName.localeCompare(b.stockName);
      });
  }
}
