<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-search-bar" fxFlex="60px" fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="list-view-search-bar__create" color="primary"
              (click)="navigateToAdd()"
              *ngIf="hasAuthority(authorities.CUSTOMER_VIEW)">
        <span>{{'modules.reports.reportList.createNewReportType'|translate}}</span>
      </button>
    </div>
  </div>

  <mat-card class="search-results" fxFlex>
    <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

      <div class="data-table-wrapper" fxFlex>
        <mat-table #table [dataSource]="reportStore" fxFlex class="data-table">

          <ng-container cdkColumnDef="reportDefinitionName">
            <mat-header-cell *cdkHeaderCellDef>
              {{'modules.reports.reportList.reportTypeName'|translate}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{row.reportDefinitionName}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="reportDefinitionExportFormat">
            <mat-header-cell *cdkHeaderCellDef>
              {{'modules.reports.reportList.reportTypeFileFormat'|translate}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              {{row.reportDefinitionExportFormat | reportExportFormatDisplay}}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="editReportDefinition">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button (click)="navigateToEdit(row.reportDefinitionId)"
                      [matTooltip]="'modules.reports.reportList.editReportType'|translate">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="deleteReportDefinition">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-button [matTooltip]="'modules.reports.reportList.deleteReportType'|translate"
                      (click)="deleteReportDefinition(row)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
