import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'formatDispositionProjectDateRange'
})
export class FormatDispositionProjectDateRangePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(project: DispositionProject, format = 'DD.MM.YYYY'): string {
    if (project && (project.projectStart || project.projectEnd)) {
      return (project.projectStart ? moment(project.projectStart).format(format) : this.translate('general.labels.openStart'))
      + ' - '
      + (project.projectEnd ? moment(project.projectEnd).format(format) : this.translate('general.labels.openEnd'));
    } else {
      return '-';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
