import { LanguageService } from 'app/shared/services/language.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SearchEquipment } from '../../../contract/search-equipment.interface';
import { RentalDatasource } from '../../../shared/rental.datasource';
import * as moment from 'moment';
import { HistoryEntry } from '../contract/history-entry';
import { RentalEquipmentEntry } from '../contract/rental-equipment-entry';
import { RentalEvent } from '../contract/rental-event';
import { groupBy } from '../../../../../shared/collection-utils';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { isDefined } from '../../../../../shared/utils';
import { SchedulerLocaleLoaderService } from 'app/shared/scheduler/scheduler-locale-loader.service';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-rental-board-timeline',
  templateUrl: './rental-board-timeline.component.html',
  styleUrls: ['./rental-board-timeline.component.scss']
})
export class RentalBoardTimelineComponent implements OnInit, OnDestroy {

  @ViewChild('scheduler_rental', { static: true }) schedulerContainer: ElementRef;

  private sections: any = [];
  private events: RentalEvent[] = [];
  private openSections: number[] = [];
  private timelineYearName = 'timeline_year';
  private timelineMonthName = 'timeline_month';
  private timelineWeekName = 'timeline_week';

  constructor(public rentalStore: RentalDatasource,
    private schedulerLocaleLoaderService: SchedulerLocaleLoaderService,
    private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    scheduler = Scheduler.getSchedulerInstance();
    this.schedulerLocaleLoaderService.isLoaded
      .pipe(
        filter(Boolean),
        take(1),
        untilDestroyed(this))
      .subscribe(() => {
        this.createTimelineViews();
        this.schedulerInit();
        this.schedulerSetCustomCssClass();
        this.schedulerEvents();
        this.schedulerTooltipText();

        this.getRentalEquipments();
      });
  }

  public ngOnDestroy(): void {
    // for @TakeUntilDestroy
  }

  private getRentalEquipments(): void {
    this.rentalStore.equipments
      .pipe(
        untilDestroyed(this),
        filter(isDefined))
      .subscribe((equipments: SearchEquipment[]) => {
        this.clearSchedulerData();
        this.setEvents(equipments);
        this.schedulerLoadData();
      });
  }

  private schedulerInit(): void {
    // increase size of scale row
    scheduler.xy.scale_height = 40;

    // Disable creating new events
    scheduler.config.drag_create = false;
    scheduler.config.dblclick_create = false;

    // initialize the timeline
    scheduler.init(this.schedulerContainer.nativeElement, new Date(), this.timelineMonthName);
  }

  private schedulerLoadData(): void {
    scheduler.updateCollection('sections', this.sections);
    scheduler.parse(this.events, 'json');
    scheduler.setCurrentView();
  }

  private createTimelineViews(): void {
    this.schedulerCreateTimelineYear();
    this.schedulerCreateTimelineMonth();
    this.schedulerCreateTimelineWeek();

    this.schedulerOnBeforeViewChange();
  }

  private schedulerCreateTimelineYear(): void {
    scheduler.createTimelineView({
      name: this.timelineYearName,
      x_unit: 'month',
      x_date: '%M <br/> %Y',
      x_step: 1,
      x_size: 12,
      y_unit: scheduler.serverList('sections'),
      y_property: 'section_id',
      render: 'tree',
      folder_dy: 40,
      dy: 40,
      dx: 300,
      event_dy: 5,
      section_autoheight: false
    });

    scheduler.date[this.timelineYearName + '_start'] = scheduler.date.year_start;
    scheduler.locale.labels[this.timelineYearName + '_tab'] = this.translate('general.units.time.year.s');
  }

  private schedulerCreateTimelineMonth(): void {
    scheduler.createTimelineView({
      name: this.timelineMonthName,
      x_unit: 'day',
      x_date: '%D <br/> %j',
      x_step: 1,
      x_size: 31,
      y_unit: scheduler.serverList('sections'),
      y_property: 'section_id',
      render: 'tree',
      folder_dy: 40,
      dy: 40,
      dx: 300,
      event_dy: 5,
      section_autoheight: false,
      round_position: true
    });

    scheduler.date[this.timelineMonthName + '_start'] = scheduler.date.month_start;
    scheduler.locale.labels[this.timelineMonthName + '_tab'] = this.translate('general.units.time.month.s');
  }

  private schedulerCreateTimelineWeek(): void {
    scheduler.createTimelineView({
      name: this.timelineWeekName,
      x_unit: 'day',
      x_date: '%l <br/> %M %d',
      x_step: 1,
      x_size: 7,
      y_unit: scheduler.serverList('sections'),
      y_property: 'section_id',
      render: 'tree',
      folder_dy: 40,
      dy: 40,
      dx: 300,
      event_dy: 5,
      section_autoheight: false,
      round_position: true
    });

    scheduler.date[this.timelineWeekName + '_start'] = scheduler.date.week_start;
    scheduler.locale.labels[this.timelineWeekName + '_tab'] = this.translate('general.units.time.week.s');
  }

  private schedulerOnBeforeViewChange(): void {
    // custom logic to go to next/previous month, because default behaviour may skip one
    scheduler.date['add_' + this.timelineMonthName] = function (date: Date, step: number) {
      if (step > 0) {
        step = 1;
      } else if (step < 0) {
        step = -1;
      }
      return scheduler.date.add(date, step, 'month')
    };

    scheduler.attachEvent('onBeforeViewChange', (old_timelineView: string,
                                                 old_date: Date,
                                                 new_timelineView: string,
                                                 new_date: Date) => {
      if (new_timelineView === this.timelineMonthName) {
        scheduler['matrix'][this.timelineMonthName].x_size = moment(new_date).daysInMonth();
      }
      return true;
    });
  }

  private setEvents(equipments: SearchEquipment[]): void {
    const groupedEquipments = this.groupByOrganisations(equipments);
    let orgIndex = 1;
    groupedEquipments.forEach((entries, organisationName) => {

      const section: Object = {
        key: orgIndex,
        label: `<div class="row">
                      <span class="name">${organisationName} (${entries.length})</span>
                    </div>`,
        open: this.wasSectionOpen(orgIndex),
        children: this.appendEquipmentRows(orgIndex, entries)
      };

      this.sections.push(section);
      orgIndex = orgIndex + 1;
    });
  }

  private appendEquipmentRows(orgIndex: number, entries: RentalEquipmentEntry[]): { key: number, label: string }[] {
    return entries.map((equipmentEntry: RentalEquipmentEntry, index) => {
      const id = parseInt('' + (orgIndex) + (index + 1), 10);

      equipmentEntry.rentalHistory.forEach((historyEntry: HistoryEntry) => {
        this.events.push(RentalEvent.from(id, historyEntry));
      });

      return {
        key: id,
        label: `<div class="row-label" style="border-color: #65cccb">
                        <div>${equipmentEntry.equipment.equipmentName}</div>
                        <div>${equipmentEntry.equipment.equipmentSerialNumber}</div>
                      </div>`
      }
    });
  }

  private clearSchedulerData(): void {
    this.saveOpenSections();
    this.events = [];
    this.sections = [];
    scheduler.clearAll();
  }

  private saveOpenSections(): void {
    this.openSections = this.sections
      .filter(section => section.open)
      .map(section => section.key);
  }

  private wasSectionOpen(key: number): boolean {
    return this.openSections.includes(key);
  }

  private groupByOrganisations(equipments: SearchEquipment[]): Map<string, RentalEquipmentEntry[]> {
    const equipmentList = equipments.map(equipment => {
      const groupedEquipment = RentalEquipmentEntry.from(equipment);

      if (equipment.rentalByHistory && equipment.rentalByHistory.length) {
        groupedEquipment.rentalHistory =
          equipment.rentalByHistory.map((history) => HistoryEntry.from(history));
      } else {
        groupedEquipment.rentalHistory.push(HistoryEntry.dummyElement());
      }

      return groupedEquipment;
    });

    return groupBy(equipmentList, 'organisationName');
  }

  private schedulerEvents(): void {
    this.schedulerOnDrag();
    this.schedulerOnLightbox();
  }

  private schedulerOnDrag(): void {
    scheduler.attachEvent('onBeforeDrag', (id) => {
      return false;
    });
  }

  private schedulerOnLightbox(): void {
    scheduler.attachEvent('onBeforeLightbox', (id) => {
      return false;
    });
  }

  private schedulerSetCustomCssClass(): void {
    // set general custom CSS classes
    scheduler.templates.event_class = (start, end, event) => {
      return 'event-rental-readonly';
    };

    // set custom CSS classes for highlighting current day and past
    // outdated. the disposition board was refactored in BEUT-2591, but the rental board was excluded from that story

    // scheduler.templates[this.timelineYearName + '_cell_class'] = SchedulerHighlighter.monthBasedHighlighter;
    // scheduler.templates[this.timelineYearName + '_scalex_class'] = SchedulerHighlighter.monthBasedHighlighterTitle;

    // scheduler.templates[this.timelineMonthName + '_cell_class'] = SchedulerHighlighter.dayBasedHighlighter;
    // scheduler.templates[this.timelineMonthName + '_scalex_class'] = SchedulerHighlighter.dayBasedHighlighterTitle;

    // scheduler.templates[this.timelineWeekName + '_cell_class'] = SchedulerHighlighter.dayBasedHighlighter;
    // scheduler.templates[this.timelineWeekName + '_scalex_class'] = SchedulerHighlighter.dayBasedHighlighterTitle;
  }

  private schedulerTooltipText(): void {
    scheduler.templates.tooltip_text = (start, end, event) => {
      return event.text +
        '<br/><b>Start date:</b> ' + moment(start).format('DD.MM.YYYY') +
        '<br/><b>End date:</b> ' + moment(end).format('DD.MM.YYYY');
    };
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
