import _ from 'lodash';
import {OspDateTime} from '../../../../../equipment/contract/osp-date-time';

export class AssignMaintenanceRuleCommand {
  public periodCalculationBase?: OspDateTime;
  public mileageCalculationBase?: number;
  public operatingHoursCalculationBase?: number;

  constructor(
    public maintenanceRuleId: string,
    public equipmentId: string,
    periodCalculationBase?: OspDateTime,
    mileageCalculationBase?: number,
    operatingHoursCalculationBase?: number
  ) {
    if (!_.isNil(periodCalculationBase)) {
      this.periodCalculationBase = periodCalculationBase;
    }
    if (!_.isNil(mileageCalculationBase)) {
      this.mileageCalculationBase = mileageCalculationBase;
    }
    if (!_.isNil(operatingHoursCalculationBase)) {
      this.operatingHoursCalculationBase = operatingHoursCalculationBase;
    }
  }
}
