import { environment } from 'environments/environment';
import { DataSource } from '@angular/cdk/collections';
import { BulkItemsService } from './bulk-items.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, combineLatest, Subscription } from 'rxjs';
import { SearchBulkItem } from '../contract/search-bulk-item.interface';
import { ViewBulkItem } from '../contract/view-bulk-item.interface';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { delay, filter, map, skip, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { AddBulkItemLabelCommand } from '../contract/add-bulk-item-label-command';
import { Router } from '@angular/router';
import { DeleteBulkItemLabelCommand } from '../contract/delete-bulk-item-label-command';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { DeleteBulkItemDocumentCommand } from '../contract/delete-bulk-item-document-command';
import { UpdateBulkItemDocumentDescriptionCommand } from '../contract/update-bulk-item-document-description-command';
import { BulkItemCategoryCount } from '../contract/category-count.interface';
import { BulkItemTypeCount } from '../contract/type-count.interface';
import { CreateBulkItemCommand } from '../contract/create-bulk-item-command';
import { UpdateBulkItemCommand } from '../contract/update-bulk-item-command';
import { DeleteBulkItemCommand } from '../contract/delete-bulk-item-command';
import { HttpErrorResponse } from '@angular/common/http';
import { DispositionBulkItem } from './disposition-bulk-item';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { OfflineDataStore } from '../../../shared/services/offline-data.store';
import { SetBulkItemThumbnailCommand } from '../contract/set-bulk-item-thumbnail-command';
import { SetBulkItemDescriptionCommand } from '../contract/set-bulk-item-description-command';
import { BulkItemColumnService } from '../bulk-items/service/bulk-item-column.service';
import { BulkItemFilterService } from './services/bulk-item-filter.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { BulkItemSearchFilterRequestParams } from './bulk-item-request-params/bulk-item-search-filter-request-params.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateBulkItemDocumentNameCommand } from '../contract/update-bulk-item-document-name.command';

const emptyBulkItem: ViewBulkItem = {
  bulkItemBALCode: '',
  bulkItemBGLCode: '',
  bulkItemCategory: '',
  bulkItemCostCenter: '',
  bulkItemCostType: '',
  bulkItemDescription: '',
  bulkItemId: '',
  bulkItemName: '',
  bulkItemShortName: '',
  bulkItemNumber: '',
  bulkItemScanCode: '',
  bulkItemType: null,
  bulkItemUnit: null,
  bulkItemWeight: 0,
  labels: [],
  manufacturerId: '',
  manufacturerName: '',
  nonReturnable: false,
  supplierId: '',
  supplierName: '',
  thumbnail: ''
};

@UntilDestroy()
@Injectable()
export class BulkItemDatasource extends DataSource<SearchBulkItem> {

  private _data: BehaviorSubject<SearchBulkItem[]> = new BehaviorSubject([]);
  private _currentBulkItem: BehaviorSubject<ViewBulkItem> = new BehaviorSubject(null);
  private _currentBulkItemDeletable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  private _categories: BehaviorSubject<BulkItemCategoryCount[]> = new BehaviorSubject([]);
  private _bulkItemTypes: BehaviorSubject<BulkItemTypeCount[]> = new BehaviorSubject([]);
  private _currentViewTab = 'general';
  private _bulkItemLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _documents: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private deleteBulkItem$: Subscription;

  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private _searchLabels: string[] = [];
  private _sort: Sort;

  public readonly bulkItems: Observable<SearchBulkItem[]> = this._data.asObservable();
  public readonly currentBulkItem: Observable<ViewBulkItem> = this._currentBulkItem.asObservable();
  public readonly currentBulkItemDeletable: Observable<boolean> = this._currentBulkItemDeletable.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly bulkItemLabels: Observable<string[]> = this._bulkItemLabels.asObservable();
  public readonly documents: Observable<DocumentLink[]> = this._documents.asObservable();
  public readonly categories: Observable<BulkItemCategoryCount[]> = this._categories.asObservable();
  public readonly bulkItemTypes: Observable<BulkItemTypeCount[]> = this._bulkItemTypes.asObservable();
  public readonly filters = this.bulkItemFilterService.filters;
  public readonly onFiltersUpdated = this.bulkItemFilterService.onFiltersUpdated;

  public readonly filteredCustomerLabels: Observable<string[]> = combineLatest([this._labels, this._bulkItemLabels])
    .pipe(
      map(([availableLabels, selectedLabels]) =>
        availableLabels.map(label => label.name)
          .filter(label => !selectedLabels.includes(label))
      ));

  constructor(private bulkItemsService: BulkItemsService,
              private snackBar: MatSnackBar,
              private router: Router,
              private onlineStatusService: OnlineStatusService,
              private bulkItemColumnService: BulkItemColumnService,
              private offlineDataStore: OfflineDataStore,
              private bulkItemFilterService: BulkItemFilterService,
  ) {
    super();
    this.setDefaultValues();
    this.paginatorPageSizeListener();
    this.labelsSubscription();
  }

  get searchLabels(): string[] {
    return this._searchLabels;
  }

  set searchLabels(searchLabels: string[]) {
    this._searchLabels = searchLabels;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
    } else {
      this.snackBar.open('Eingabe zu lang. Suche nicht möglich', undefined, {duration: 5000});
    }
  }

  set sort(sort: Sort) {
    this._sort = sort;
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  get currentViewTab(): string {
    return this._currentViewTab;
  }

  set currentViewTab(currentViewTab: string) {
    this._currentViewTab = currentViewTab;
  }

  public connect(): Observable<SearchBulkItem[]> {
    return this.bulkItems;
  }

  public disconnect(): void {
  }

  public updateCurrentBulkItem(): void {
    if (this._currentBulkItem.value) {
      this.changeCurrentBulkItem(this._currentBulkItem.value.bulkItemId);
    } else {
      this.selectCurrentBulkItemOrDefault();
    }
  }

  public setThumbnail(cmd: SetBulkItemThumbnailCommand): Observable<string> {
    return this.bulkItemsService.setThumbnail(cmd);
  }

  public setDescription(cmd: SetBulkItemDescriptionCommand): void {
    this.bulkItemsService.setDescription(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe(() => {
        this.updateCurrentBulkItem();
      })
  }

  public getByScanCode(code: string): Observable<DispositionBulkItem> {
    if (this.onlineStatusService.onlineStatus) {
      return this.bulkItemsService.getByScanCode(code);
    } else {
      return this.offlineDataStore.offlineDispositionBulkItems
        .pipe(map(bulkItems => bulkItems.find(item => item.bulkItemScanCode === code)));
    }
  }

  public selectCurrentBulkItemOrDefault(): void {
    if (this._currentBulkItem.value && this._currentBulkItem.value.bulkItemId !== '') {
      this.router.navigate(['assets/bulk-item/list', this._currentBulkItem.value.bulkItemId]);
    } else {
      this.bulkItems.pipe(
        filter((res: SearchBulkItem[]) => res && res.length > 0),
        take(1),
      ).subscribe((res: SearchBulkItem[]) => {
        this.router.navigate(['assets/bulk-item/list', res[0].bulkItemId]);
      });
    }
  }

  public changeCurrentBulkItem(bulkItemId: string): void {
    this.bulkItemsService
      .getBulkItem(bulkItemId)
      .subscribe((res: ViewBulkItem) => {
        this._currentBulkItem.next(res);
        this._bulkItemLabels.next(res.labels ? res.labels : []);
        this.updateDeletableStatus(res.bulkItemId);
      });
  }

  public updateDeletableStatus(bulkItemId: string): void {
    setTimeout(() => {
      this.bulkItemsService.isDeletable(bulkItemId).subscribe(deletable => {
        this._currentBulkItemDeletable.next(deletable);
      });
    }, environment.DELAY_SHORTEST);
  }

  public addBulkItem(cmd: CreateBulkItemCommand): Observable<string> {
    return this.bulkItemsService
      .addBulkItem(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.updateListing();
        }, environment.DELAY_SHORT);
        return of(res);
      }));
  }

  public updateBulkItem(cmd: UpdateBulkItemCommand): Observable<string> {
    return this.bulkItemsService
      .updateBulkItem(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.updateCurrentBulkItem();
          this.updateListing();
        }, environment.DELAY_SHORT);
        return of(res);
      }));
  }

  private labelsSubscription(): void {
    this.bulkItemFilterService.labels
      .pipe(untilDestroyed(this))
      .subscribe(labels => this._labels.next(labels));
  }

  public deleteBulkItem(cmd: DeleteBulkItemCommand): void {
    this.deleteBulkItem$ = this.bulkItemsService.deleteBulkItem(cmd).pipe(
      delay(environment.DELAY_LONG),
      tap(() => this.updateListing(0)),
      switchMapTo(this.bulkItems),
      skip(1),
      take(1)
    )
      .subscribe(
        (res: SearchBulkItem[]) => {
          if (res.length > 0) {
            this.router.navigate(['assets/bulk-item/list', res[0].bulkItemId]);
          } else {
            this._currentBulkItem.next(emptyBulkItem);
            this.router.navigate(['assets/bulk-item/list']);
          }
          this.deleteBulkItem$.unsubscribe();
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.deleteBulkItem$.unsubscribe();
        },
      );
  }

  public saveLabel(cmd: AddBulkItemLabelCommand): void {
    this.addToCurrentBulkItemLabels(cmd.label);
    this.bulkItemsService
      .saveLabel(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe({
        next: () => {
          this.updateFilterLabel();
          this.updateListing()
        },
        error: error => {
          console.log('error on removing label', error);
        },
      })
  }

  public deleteDocument(cmd: DeleteBulkItemDocumentCommand): Observable<string> {
    return this.bulkItemsService
      .deleteDocument(cmd)
      .pipe(switchMap((res: any) => {
        setTimeout(() => {
          this.updateListing();
          this.updateCurrentBulkItem();
        }, environment.DELAY_LONG);
        return of(res);
      }));
  }

  public updateDocumentName(cmd: UpdateBulkItemDocumentNameCommand): void {
    this.bulkItemsService.updateDocumentName(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocuments(cmd.bulkItemId));
  }

  public reset(): void {
    this.setDefaultValues();
    this.updateListing();
  }

  public updateDocumentDescription(cmd: UpdateBulkItemDocumentDescriptionCommand): void {
    this.bulkItemsService.updateDocumentDescription(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.refreshFileDescriptions(cmd.bulkItemId)))
      .subscribe();
  }

  public removeLabel(cmd: DeleteBulkItemLabelCommand): void {
    const newLabels = [...this._bulkItemLabels.value];
    newLabels.splice(newLabels.indexOf(cmd.label), 1);
    this._bulkItemLabels.next(newLabels);

    // find the CustomerLabel that will be decremented
    let customerLabel = this._labels.getValue().find(
      (currentLabel) => cmd.label.localeCompare(currentLabel.name) === 0);
    // if count is 1, the label will be removed completely, so we also remove the filter
    if (customerLabel && customerLabel.count === 1) {
      this.searchLabels = this.searchLabels.filter((filterLabel) => cmd.label.localeCompare(filterLabel) !== 0);
    }

    this.bulkItemsService
      .removeLabel(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe({
        next: () => {
          this.updateFilterLabel();
          this.updateListing();
        },
        error: error => {
          console.log('error on removing label', error);
        },
      })
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.bulkItemColumnService.selectPageSize(size);
    }
    this.pagination.index = index;
    this.pagination.size = size;

    this.bulkItemsService
      .getBulkItemsSearch(this.defineBulkItemSearchParams())
      .subscribe(res => this.updateStoreData(res));
  }

  public getDocuments(bulkItemId: string): Observable<DocumentLink[]> {
    if (bulkItemId === '') {
      return merge(of([]));
    }
    this.bulkItemsService
      .getDocuments(bulkItemId)
      .subscribe(res => {
        this._documents.next(res);
      });
    return merge(this.documents);
  }

  public updateFilters(): void {
    this.bulkItemFilterService.updateFilters();
  }

  private updateFilterLabel(): void {
    this.bulkItemFilterService.updateFilterLabel();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.bulkItemFilterService.updateFilterParams(commands);
  }

  private setDefaultValues(): void {
    this.initPagination();
    this.searchTerms = null;
    this.searchLabels = [];
    this.sort = null;
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private paginatorPageSizeListener(): void {
    this.bulkItemColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  private refreshFileDescriptions(bulkItemId: string): void {
    this.getDocuments(bulkItemId)
  }

  private addToCurrentBulkItemLabels(newLabel: string) {
    const newLabels = [...this._bulkItemLabels.value];
    newLabels.push(newLabel);
    this._bulkItemLabels.next(newLabels);
  }

  private sortToSortString(sort: Sort): string {
    return sort && sort.active && sort.direction
      ? `${sort.active}.raw,${sort.direction}` : null;
  }

  private updateStoreData(res: PagedResponse<SearchBulkItem>): void {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  private defineBulkItemSearchParams(): BulkItemSearchFilterRequestParams {
    let params: BulkItemSearchFilterRequestParams = {
      ...this.bulkItemFilterService.getFilterParams(),
      page: this.pagination.index,
      size: this.pagination.size,
      searchColumns: this.bulkItemColumnService.selectedColumnsList,
      terms: null,
      sort: null,
    };

    if (this._sort && this._sort.direction && this._sort.active === 'bulkItemType') {
      params.sort = [
        `${this._sort.active},${this._sort.direction}`
      ];
    } else if (this._sort) {
      params.sort = this.sortToSortString(this._sort);
    }

    if (this._searchTerms) {
      params.terms = this._searchTerms;
    }

    return params;
  }
}
