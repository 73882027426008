<mat-card class="entity-creation" fxFlexFill>
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
    <div class="entity-creation-title" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>trending_up</mat-icon>
      <span *ngIf="!reportDefinition">{{'modules.reports.reportAddEdit.createNewReportType'|translate}}</span>
      <span *ngIf="reportDefinition">{{'modules.reports.reportAddEdit.editReportType'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        <span>{{'general.buttons.save'|translate}}</span> |
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">
        <span>{{'general.buttons.cancel'|translate}}</span>
      </button>
      <button *ngIf="!reportDefinition" mat-raised-button (click)="resetForm()">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form report-form-wrapper" fxFlex="100">
      <form [formGroup]="reportDefinitionForm" novalidate>
        <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start">

          <div class="entity-creation-form-left border-right" fxFlex="50">
            <div class="card-group-title">{{'general.baseData'|translate}}</div>
            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <mat-form-field>
                <mat-label>{{'general.name.s'|translate}}</mat-label>
                <input matInput formControlName="reportDefinitionName" required>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.reports.reportAddEdit.exportFormat'|translate}}</mat-label>
                <mat-select formControlName="reportDefinitionExportFormat" required>
                  <mat-option *ngFor="let ref of reportExportFormats" [value]="ref">
                    {{ ref | reportExportFormatDisplay }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.reports.reportAddEdit.parameter'|translate}}</mat-label>
                <mat-select multiple formControlName="reportDefinitionParameters" required>
                  <mat-option *ngFor="let rp of reportParameters" [value]="rp">
                    {{ rp | reportParameterDisplay }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'modules.reports.reportAddEdit.unlockedForCustomers'|translate}}</mat-label>
                <mat-select multiple formControlName="reportDefinitionCustomers" required>
                  <mat-option *ngFor="let c of customers" [value]="c.customerId">
                    {{ c.customerName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox color="primary" formControlName="reportIncludesLocations"
                            [matTooltip]="'modules.reports.reportAddEdit.reportIncludesLocations'|translate">
                {{'modules.reports.reportAddEdit.containsLocationInfo'|translate}}
              </mat-checkbox>
            </div>
          </div>

          <div class="entity-creation-form-left" fxFlex="50">
            <div class="card-group-title">{{'modules.reports.reportAddEdit.jasperTemplate'|translate}} (.jrxml)</div>

            <div fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="space-between stretch">
              <div>
                <p>
                  <mat-spinner [strokeWidth]="3" [diameter]="20" *ngIf="uploaderDocument.isUploading"></mat-spinner>
                  <input type="file" #fileInput ng2FileSelect [uploader]="uploaderDocument" (change)="uploadDocument()" *ngIf="!uploaderDocument.isUploading" />
                </p>
              </div>
            </div>

            <div *ngIf="reportDefinition && !templateUploadFilename">
              <p>
                {{'modules.reports.reportAddEdit.currentTemplate'|translate}}:
                {{reportDefinition.reportDefinitionTemplateKey}}
              </p>
              <p>
                <button mat-stroked-button (click)="downloadReportTemplate()">
                  {{'general.buttons.download'|translate}}
                </button>
              </p>
              <p>
                {{'general.lastChange'|translate}}:
                {{ reportDefinition?.reportDefinitionTemplateKeyUploadDate | formatTimezoneDateTime: {dateFormat: 'DD.MM.yyyy - HH.mm:ss'} }}
              </p>
            </div>

            <div *ngIf="templateUploadFilename">
              <p>
                {{'general.selected'|translate}}: {{templateUploadFilename}}
              </p>
            </div>

          </div>

        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
