import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { Utils } from '../../../../../../../shared/utils';
import { BaseEquipmentViewDamageComponent } from '../base/base-equipment-view-damage.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { RouterHistory } from '../../../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-view-damage',
  templateUrl: './mobile-equipment-view-damage.component.html',
  styleUrls: ['./mobile-equipment-view-damage.component.scss']
})
export class MobileEquipmentViewDamageComponent extends BaseEquipmentViewDamageComponent implements OnInit, OnDestroy {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected utils: Utils,
              public equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, utils, equipmentStore, dialog, routerHistory, languageService,
      fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.equipmentId = params['id'];
      this.lifeCycleId = params['lifecycleId'];
      this.getLifeCycleDetails();
    });
  }

  ngOnDestroy(): void {
  }

  public navigateBack(): void {
    this.goBack(`/mobile/equipments/view/${this.route.snapshot.params.id}/timeline`);
  }
}
