import { Component, Input, OnInit } from '@angular/core';
import { MapLocationsCluster } from '../../../interfaces/map-location-cluster.interface';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { MapLocationMarker } from '../../../interfaces/map-location-marker.interface';
import { OrganisationViewArgs, OrganisationViewComponent } from 'app/modules/organisation/organisations/organisation-view/organisation-view.component';
import { MatDialog } from '@angular/material/dialog';
import { InfoWindowComponent } from '../../../interfaces/info-window.interface';

type TableViewPayload = MapLocationsCluster<ViewOrganisation> | MapLocationMarker<ViewOrganisation>;

@Component({
  selector: 'bh-map-info-window-table-view-organisation',
  templateUrl: './map-info-window-table-view-organisation.component.html',
  styleUrls: ['../map-info-window-table-view.scss']
})
export class MapInfoWindowTableViewOrganisationComponent implements OnInit, InfoWindowComponent {

  @Input() value: TableViewPayload;

  public items: ViewOrganisation[] = [];

  constructor(private dialog: MatDialog) { }

  public ngOnInit(): void {
    this.items = this.value ? this.getItems(this.value) : [];
  }

  public navigateToViewDetails(organisationId: string): void {
    let args: OrganisationViewArgs = { organisationId };
    this.dialog.open(OrganisationViewComponent, {width: '1100px', data: args});
  }

  private getItems(value: TableViewPayload): ViewOrganisation[] {
    if (this.isCluster(value)) {
      return value.markers.filter(m => Boolean(m?.item)).map(m => m.item);
    } else {
      return value.item ? [value.item] : [];
    }
  }

  private isCluster(value: TableViewPayload): value is MapLocationsCluster<ViewOrganisation> {
    return Boolean((<MapLocationsCluster<ViewOrganisation>>value).markers);
  }

}
