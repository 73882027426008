import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import _ from 'lodash';

@Directive({
  selector: 'input[bhTrimInputText], textarea[bhTrimInputText]'
})
export class TrimInputTextDirective implements OnChanges {
  @Input('bhTrimInputText') isEnabled = true;
  @Input('formControl') trimInputTextControl: AbstractControl;

  constructor(private inputElement: ElementRef) { }

  public ngOnChanges(): void {
    this.trimTextValue();
  }

  @HostListener('blur')
  public onBlur() {
    this.trimTextValue();
  }

  public trimTextValue(): void {
    if (this.isEnabled) {
      const currentControlValue = this.trimInputTextControl?.value;
      if (!_.isNil(currentControlValue)) {
        if (this.isInputTypeEmail) {
          this.trimInputTextControl.setValue('', { emitEvent: false, onlySelf: true });
          this.trimInputTextControl.setValue(currentControlValue.trim());
        } else if (this.needTrim(currentControlValue)) {
          this.trimInputTextControl.setValue(currentControlValue.trim());
        }
      }
    }
  }

  private get isInputTypeEmail(): boolean {
    return (this.inputElement?.nativeElement?.type ?? '').toLowerCase() === 'email'
  }

  private needTrim(value: string): boolean {
    return value !== value.trim();
  }

}
