<div fxLayout="row" fxLayoutAlign="end start">
  <div fxLayout="row" fxLayoutAlign="start end" fxFlex="150px">
    <fa-icon class="sort-icon" [icon]="faSortAlphaDown"></fa-icon>
    <mat-form-field class="no-margin-bottom">
      <mat-label>{{'general.labels.sortBy'|translate}}:</mat-label>
      <mat-select [(value)]="documentsSortParameter" (selectionChange)="changeDocumentsSortParameter($event.value)">
        <mat-option [value]="fileName">{{'general.name.s'|translate}}
        </mat-option>
        <mat-option [value]="fileDescription">{{'general.description'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="attachments" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="attachment-type-container">
    <div *ngIf="isUploadEnabled(); else noUploadAllowed">
      <bh-subtitle [text]="'general.labels.uploadDocPict'|translate"></bh-subtitle>
      <bh-file-upload
        [itemId]="equipment?.equipmentId"
        uploaderType="EQUIPMENT">
      </bh-file-upload>
    </div>
    <ng-template #noUploadAllowed>
      <div class="no-upload">
        {{(equipmentCheckerService.isInactiveEquipment(equipment) || equipmentCheckerService.isNotAvailableAnymore(equipment)
          ? 'shared.attachment.uploadNotAllowedInactive'
          : 'shared.attachment.uploadNotAllowed') | translate}}
      </div>
    </ng-template>
  </div>

  <bh-attachment-docuware *ngIf="hasDocuwareConnector"
                          [documents]="docuwareDocuments"
                          [loading]="docuwareRequestPending"
                          [serviceAvailable]="docuwareServiceAvailable"
                          [pendingDownloads]="pendingDocuwareDownloads"
                          (downloadRequested)="downloadDocuwareDocument($event)">
  </bh-attachment-docuware>

  <bh-attachment-topfact *ngIf="hasTopfactConnector"
                          [documents]="topfactDocuments"
                          [loading]="topfactRequestPending | async"
                          [serviceAvailable]="topfactServiceAvailable"
                          [pendingDownloads]="pendingTopfactDownloads"
                          (downloadRequested)="downloadTopfactDocument($event)">
  </bh-attachment-topfact>

  <bh-attachment-list-category [documents]="documents"
                               [deletable]="isDeleteEnabled()"
                               [editableName]="isNameAndDescriptionEditable()"
                               [editable]="isNameAndDescriptionEditable()"
                               [pendingChanges]="pendingChanges()"
                               (deleted)="deleteDocument($event)"
                               (descriptionChanged)="saveDescription($event, EquipmentDocumentType.MISCELLANEOUS)"
                               (nameChanged)="saveName($event, EquipmentDocumentType.MISCELLANEOUS)">
  </bh-attachment-list-category>

  <bh-attachment-list-image-category [images]="images"
                                     [pendingChanges]="waiting"
                                     [setDefaultEnable]="isSetDefaultEnabled()"
                                     [defaultImage]="defaultImage"
                                     (defaultImageChanged)="setStandardImage($event.documentKey)"
                                     [deletable]="isDeleteEnabled()"
                                     (deleted)="deleteImage($event)"
                                     [visibleDescription]="true"
                                     [editableDescription]="isNameAndDescriptionEditable()"
                                     [editableName]="isNameAndDescriptionEditable()"
                                     (nameChanged)="saveName($event, EquipmentDocumentType.MISCELLANEOUS)"
                                     (descriptionChanged)="saveDescription($event, EquipmentDocumentType.MISCELLANEOUS)">
  </bh-attachment-list-image-category>

  <bh-attachment-list-category *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW_COSTS) && hasModule(modules.COSTS)"
                               [category]="'general.invoice.pl'|translate"
                               [documents]="costDocuments"
                               [editableName]="canManageCosts()"
                               [editable]="canManageCosts()"
                               (nameChanged)="saveName($event, EquipmentDocumentType.INVOICE)"
                               (descriptionChanged)="saveDescription($event, EquipmentDocumentType.INVOICE)">
  </bh-attachment-list-category>

  <bh-attachment-list-category *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW_CONTRACTS) && hasModule(modules.COSTS)"
                               [category]="'general.contract.pl'|translate"
                               [documents]="contractDocuments"
                               [editableName]="canManageCosts()"
                               [editable]="canManageCosts()"
                               (nameChanged)="saveName($event)"
                               (descriptionChanged)="saveDescription($event)">
  </bh-attachment-list-category>

  <bh-attachment-list-category (descriptionChanged)="saveDescription($event, EquipmentDocumentType.LIFECYCLE)"
                               (nameChanged)="saveName($event, EquipmentDocumentType.LIFECYCLE)"
                               [documents]="lifeCycleDamageDocuments"
                               [editableName]="canManageDamages()"
                               [editable]="canManageDamages()"
                               [category]="'general.maliciousDamage.pl'|translate">
  </bh-attachment-list-category>

  <bh-attachment-list-category (descriptionChanged)="saveDescription($event, EquipmentDocumentType.LIFECYCLE)"
                               (nameChanged)="saveName($event, EquipmentDocumentType.LIFECYCLE)"
                               [documents]="lifeCycleIncidentDocuments"
                               [editableName]="canManageIncidents()"
                               [editable]="canManageIncidents()"
                               [category]="'general.incident.pl'|translate">
  </bh-attachment-list-category>

  <bh-attachment-list-category [documents]="lifeCycleMaintenanceDocuments"
                               [editableName]="canManageMaintenance()"
                               [editable]="canManageMaintenance()"
                               [category]="'general.maintenance.pl'|translate"
                               (descriptionChanged)="saveMaintenanceDescription($event, EquipmentDocumentType.LIFECYCLE)"
                               (nameChanged)="saveName($event, EquipmentDocumentType.LIFECYCLE)">
  </bh-attachment-list-category>
</div>
