import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { TransferRequestWidgetApiService } from './transfer-request-widget-api.service';
import { ViewTransferRequest } from '../../../disposition/projects/project-list/project-view/project-view-transfer-history/view-transfer-request';

@Injectable({
  providedIn: 'root'
})
export class TransferRequestWidgetStoreService {
  private pageSize = 15;
  private page = 0;
  private numberOfPages = 0;

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _historyEntries: BehaviorSubject<ViewTransferRequest[]> = new BehaviorSubject<ViewTransferRequest[]>([]);
  private _endOfList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _noEntries: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly historyEntries: Observable<ViewTransferRequest[]> = this._historyEntries.asObservable();
  public readonly endOfList: Observable<boolean> = this._endOfList.asObservable();
  public readonly noEntries: Observable<boolean> = this._noEntries.asObservable();

  constructor(private api: TransferRequestWidgetApiService) { }

  public scroll(initialLoad?: boolean): void {
    if (initialLoad) {
      this.page = 0;
      this._historyEntries.next([]);
    }
    if (this._loading.value && !initialLoad) {
      return;
    }

    if (this.page === (this.numberOfPages - 1) && !initialLoad) {
      return;
    }

    this._loading.next(true);

    if (this.page < this.numberOfPages && !initialLoad) {
      this.page++;
    }

    this.api.getAllPendingTransfers(this.page, this.pageSize)
      .subscribe(res => {
        const noElements = res.totalElements < 1;
        this._noEntries.next(noElements);
        this.numberOfPages = res.totalPages;
        this._loading.next(false);
        this._historyEntries.next([...this._historyEntries.value, ...res.content]);
        this._endOfList.next(res.last && !noElements);
      });
  }
}
