import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationsService } from '../shared/organisations.service';
import { AsyncValidatorFn } from '@angular/forms';
import { asyncValidatorFactory } from '../../../shared/custom-validators/async-validator.factory';

export class OrganisationValidators {

  static uniqueOrganisationName(organisationsService: OrganisationsService, organisationId?: string): AsyncValidatorFn {
    return asyncValidatorFactory((organisationName) => {
      if (!organisationName || !organisationName.trim()) {
        return of(null);
      }

      return organisationsService.organisationNameInUse(organisationName.trim(), organisationId)
        .pipe(map(res => res ? {taken: true} : null));
    });
  }

  static uniqueOrganisationNumber(organisationsService: OrganisationsService, organisationId?: string): AsyncValidatorFn {
    return asyncValidatorFactory((organisationNumber) => {
      if (!organisationNumber || !organisationNumber.trim()) {
        return of(null);
      }

      return organisationsService.organisationNumberInUse(organisationNumber.trim(), organisationId)
        .pipe(map(res => res ? {taken: true} : null));
    });
  }
}
