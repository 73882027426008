import {LanguageService} from '../../../../shared/services/language.service';
import {Component} from '@angular/core';
import {ConnectorService} from '../../shared/connector.service';
import {ScheduledConnector} from '../../contract/scheduled-connector/scheduled-connector';
import {MatDialog} from '@angular/material/dialog';
import {ConnectorAddComponent} from '../connector-add/connector-add.component';
import {ConnectorEditComponent} from '../connector-edit/connector-edit.component';
import {
  ConfirmationDialogComponent
} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ConnectorType} from '../../contract/connector-types.enum';
import {ScheduledConnectorCommand} from '../../contract/scheduled-connector/scheduled-connector-command';
import {dialogResults} from '../../../../shared/enums/dialogResults.enum';
import {DeleteAempV2ConnectorCommand} from '../../contract/aemp-v2/delete-aemp-v2-connector-command';
import {DeleteMailConnectorCommand} from '../../contract/mail/delete-mail-connector-command';
import {
  DeleteTrackUnitClassicConnectorCommand
} from '../../contract/trackunit-classic/delete-track-unit-classic-connector-command';
import {DeleteGpsOverIpConnectorCommand} from '../../contract/gpsoverip/delete-gps-over-ip-connector-command';
import {DeleteRioConnectorCommand} from '../../contract/rio/delete-rio-connector-command';
import {DeleteReidlConnectorCommand} from '../../contract/reidl/delete-reidl-connector-command';
import {RoleAuthorityGuardsComponent} from '../../../../shared/navigation-guards/role-authority-guards.component';
import {KeycloakService} from '../../../../core/keycloak';
import {DeleteSinos2ConnectorCommand} from '../../contract/sinos2/delete-sinos2-connector-command';

@Component({
  selector: 'bh-job-list',
  templateUrl: 'connector-list.component.html',
  styleUrls: ['connector-list.component.scss'],
})
export class ConnectorListComponent extends RoleAuthorityGuardsComponent {

  public ConnectorType = ConnectorType;
  public scheduledConnectors: ScheduledConnector[];

  constructor(authService: KeycloakService,
              private connectorService: ConnectorService,
              private dialog: MatDialog,
              private languageService: LanguageService) {
    super(authService);
    this.loadConnectors();
  }

  public loadConnectors(): void {
    this.connectorService.getScheduledConnectors().subscribe(
      scheduledConnectors => this.scheduledConnectors = scheduledConnectors);
  }

  public addConnector(type: ConnectorType): void {
    const dialogRef = this.dialog.open(ConnectorAddComponent, {
      width: '700px',
      panelClass: 'popup-container'
    });
    dialogRef.componentInstance.type = type;
    dialogRef.afterClosed().subscribe(() => this.loadConnectors());
  }

  public editConnector(connector: ScheduledConnector): void {
    const dialogRef = this.dialog.open(ConnectorEditComponent, {
      width: '700px',
      panelClass: 'popup-container'});
    dialogRef.componentInstance.type = connector.connectorType;
    dialogRef.componentInstance.connectorId = connector.connectorId;
    dialogRef.afterClosed().subscribe(() => this.loadConnectors());
  }

  public deleteConnector(connector: ScheduledConnector): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.connector.confirmation.message.interfaceDelete');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {

        switch (connector.connectorType) {
          case ConnectorType.AEMP_V2:
            this.connectorService.deleteAEMPv2Connector(new DeleteAempV2ConnectorCommand(connector.connectorId))
            .subscribe(() => this.loadConnectors());
            break;

          case ConnectorType.MAIL:
            this.connectorService.deleteMailConnector(new DeleteMailConnectorCommand(connector.connectorId))
            .subscribe(() => this.loadConnectors());
            break;

          case ConnectorType.TRACKUNIT_CLASSIC:
            this.connectorService.deleteTrackUnitClassicConnector(
              new DeleteTrackUnitClassicConnectorCommand(connector.connectorId))
            .subscribe(() => this.loadConnectors());
            break;

          case ConnectorType.GPS_OVER_IP:
            this.connectorService.deleteGpsOverIpConnector(new DeleteGpsOverIpConnectorCommand(connector.connectorId))
              .subscribe(() => this.loadConnectors());
            break;

          case ConnectorType.RIO:
            this.connectorService.deleteRioConnector(new DeleteRioConnectorCommand(connector.connectorId))
              .subscribe(() => this.loadConnectors());
            break;

          case ConnectorType.REIDL:
            this.connectorService.deleteReidlConnector(new DeleteReidlConnectorCommand(connector.connectorId))
              .subscribe(() => this.loadConnectors());
            break;

          case ConnectorType.SINOS_PORTAL_2:
            this.connectorService.deleteSinos2Connector(new DeleteSinos2ConnectorCommand(connector.connectorId))
              .subscribe(() => this.loadConnectors());
            break;
        }
      }
    });
  }

  public activateConnector(connector: ScheduledConnector): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.connector.confirmation.message.interfaceActivate');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        const command = new ScheduledConnectorCommand(connector.connectorId);
        this.connectorService.activateScheduledConnector(command).subscribe(() => this.loadConnectors());
      }
    });
  }

  public deactivateConnector(connector: ScheduledConnector): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.connector.confirmation.message.interfaceDeactivate');
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        const command = new ScheduledConnectorCommand(connector.connectorId);
        this.connectorService.deactivateScheduledConnector(command).subscribe(() => this.loadConnectors());
      }
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
