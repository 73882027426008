import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  AdditionalFieldsService,
  CreateAdditionalFieldRequest, DeleteAdditionalFieldRequest,
  UpdateAdditionalFieldRequest,
} from './additional-fields.service';
import { PageableDataSource, PageableParams } from 'app/shared/datasources/pageable.datasource';
import { AdditionalField } from 'app/modules/osp-ui/rich-components/bh-additional-field/additional-field';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdditionalFieldsDatasource extends PageableDataSource<AdditionalField> {

  constructor(private additionalFieldsService: AdditionalFieldsService) {
    super();
  }

  protected fetchData(params: PageableParams): Observable<PagedResponse<AdditionalField>> {
    return this.additionalFieldsService.getAll(params);
  }

  public create(request: CreateAdditionalFieldRequest): Observable<string> {
    return this.additionalFieldsService.create(request);
  }

  public update(request: UpdateAdditionalFieldRequest): Observable<string> {
    return this.additionalFieldsService.update(request);
  }

  public delete(request: DeleteAdditionalFieldRequest): void {
    this.additionalFieldsService.delete(request)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.updateListing())
  }
}
