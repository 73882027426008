<div class="list-view-main__right" fxFlexFill fxLayout="column">
  <div class="list-view-main__right-header"
       fxFlex="60px"
       fxLayout="row"
       fxLayoutAlign="space-between center">

    <div *ngIf="displayService.isTableAndDetailsChanges | async"
         class="full-screen"
         fxFlex="0 0 70px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.enlarge'|translate"
         (click)="displayService.displayDetailsFullscreen()" >
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div *ngIf="displayService.isDetailsFullscreenChanges | async"
         class="full-screen"
         fxFlex="0 0 70px"
         fxLayout="row"
         fxLayoutAlign="center center"
         [matTooltip]="'general.labels.detailView.reduce'|translate"
         (click)="displayService.displayTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>

    <div class="name" fxFlex="10 1 50%">
      <span>{{ transport?.title}}</span>
    </div>
    <div  fxFlex="1 1 50%" fxLayoutAlign="end">
      <bh-page-switcher
        *ngIf="hasAuthority(authorities.TRANSPORT_TASK_DISPOSITION)"
        [active]="pageType.TRANSPORT_LIST">
      </bh-page-switcher>
    </div>

    <div *ngIf="showMenu"
      class="more"
      fxFlex="0 0 20px"
      [matMenuTriggerFor]="transportMore"
      [matTooltip]="'general.buttons.actions'|translate">
      <mat-icon>more_vert</mat-icon>
    </div>
    <mat-menu #transportMore="matMenu" xPosition="before">
      <ng-container *ngFor="let menuOption of menuOptions">
        <div *ngIf="menuOption" [matTooltip]="menuOption.tooltipForDisabled" [matTooltipDisabled]="!menuOption.disabled">
          <button *ngIf="menuOption.show"
            mat-menu-item
            [disabled]="menuOption.disabled"
            (click)="menuOption.onClick()">
            <mat-icon *ngIf="menuOption.matIcon">{{menuOption.matIcon}}</mat-icon>
            <mat-icon *ngIf="menuOption.faIcon"><fa-icon [icon]="menuOption.faIcon"></fa-icon></mat-icon>
            <span>{{menuOption.label}}</span>
          </button>
        </div>
      </ng-container>
    </mat-menu>

    <div *ngIf="!(displayService.isTableFullscreenChanges | async)"
         class="change-display-mode"
         fxFlex="0 0 60px"
         fxLayout="row"
         fxLayoutAlign="center center"
         [matTooltip]="'general.labels.detailView.collapse'|translate"
         (click)="displayService.displayTableFullscreen()">

      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
