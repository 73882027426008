<div class="inline-edit-container">
  <bh-additional-field [field]="field"
                       [formControl]="control"
                       [readonly]="readonly"
                       (onKeyPress)="onKeyPress($event)">
  </bh-additional-field>

  <div class="inline-action-buttons">
    <mat-icon *ngIf="readonly && editable"
              class="bh-mat-icon"
              (click)="edit()"
              [matTooltip]="'general.buttons.change'|translate">
      mode_edit
    </mat-icon>
    <mat-icon *ngIf="!readonly && control.valid"
              class="bh-mat-icon"
              (click)="save()"
              [matTooltip]="'general.buttons.save'|translate">
      check
    </mat-icon>
    <mat-icon *ngIf="!readonly"
              class="bh-mat-icon hover-red"
              (click)="cancel()"
              [matTooltip]="'general.buttons.cancel'|translate">
      close
    </mat-icon>
  </div>
</div>
