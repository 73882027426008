<div class="mobile-content-header">
  {{'modules.disposition.transferSummary.completeTransfer'|translate}}
</div>
<div class="content-wrapper">
  <mat-card class="summary-card" *ngIf="source">
    <div>
      {{'general.from'|translate|titlecase}}:
    </div>
    <div class="summary-card-content" fxLayout="row">
      <fa-icon [icon]="source.projectId ? faMapMarkedAlt : faWareHouse"></fa-icon>
      {{ getName(source) }}
    </div>
  </mat-card>
  <mat-card class="summary-card target" *ngIf="target" (click)="goToTargetSelect()">
    <div fxLayout="row">
      <div class="grow-container">
        {{'modules.disposition.transferSummary.to'|translate}}:
      </div>
      <fa-icon class="edit-icon" [icon]="faPencil"></fa-icon>
    </div>
    <div class="summary-card-content" fxLayout="row">
      <fa-icon [icon]="target.projectId ? faMapMarkedAlt : faWareHouse"></fa-icon>
      {{ getName(target) }}
    </div>
  </mat-card>
  <mat-card class="summary-card target" *ngIf="consumed">
    <div fxLayout="row">
      <div class="grow-container">
        {{'modules.disposition.transferSummary.articlesAreConsumed'|translate}}
      </div>
    </div>
    <div class="summary-card-content" fxLayout="row">
      {{'modules.disposition.transferSummary.itemsNotBackToStock'|translate}}
    </div>
  </mat-card>
  <mat-expansion-panel class="summary-card">
    <mat-expansion-panel-header fxLayout="row" fxLayoutGap="10px" collapsedHeight="*" expandedHeight="*">
      <div fxLayout="column" fxFlexFill>
        <div class="grow-container">
          {{'general.bulkItems'|translate}}:
        </div>
        <div class="summary-card-content" fxLayout="row">
          <fa-icon [icon]="faTrafficCone"></fa-icon>
          {{ bulkItems.length }} {{'modules.disposition.transferSummary.articleCaptured'|translate}}
        </div>
      </div>
    </mat-expansion-panel-header>
    <bh-mobile-swipe-to-options swipeLimitInPercent="40" fxLayout="column" *ngFor="let item of bulkItems"
                                class="option-line">
      <div fxLayout="row" fxLayoutGap="10px" class="item">
        <span class="item-type">
         <fa-icon [icon]="bulkItemTypeResolver.resolveIcon(item.bulkItemType)"></fa-icon>
        </span>
        <div fxLayout="column" class="bulk-item">
          <span class="item-name ellipsis">{{ item.bulkItemName }}</span>
          <span *ngIf="item.sourceStockId" class="ellipsis">
            {{'modules.disposition.transferSummary.fromStock'|translate}}: {{ item.sourceStockName }}
          </span>
          <span *ngIf="!item.sourceStockId" class="ellipsis">{{ item.bulkItemCategory }}</span>
        </div>
        <div class="amount">
          x {{ item.amount }}
        </div>
        <div class="option-reveal" fxLayout="column">
          <mat-icon>dehaze</mat-icon>
        </div>
      </div>
      <bh-swipe-option class="swipe-option" appendClass="blue" (onClick)="edit(item)">
        <fa-icon [icon]="faPencil"></fa-icon>
      </bh-swipe-option>
      <bh-swipe-option class="swipe-option" appendClass="red" (onClick)="delete(item)">
        <fa-icon [icon]="faTrash"></fa-icon>
      </bh-swipe-option>
    </bh-mobile-swipe-to-options>

  </mat-expansion-panel>

  <form [formGroup]="additionalInfoForm">
    <mat-card class="summary-card" *ngIf="hasModule(modules.STAFF_MANAGEMENT)">
      <div fxLayout="row">
        <div class="grow-container">
          {{'general.employee.s' | translate}}
        </div>
      </div>
      <div class="summary-card-content" fxLayout="row">
        <mat-form-field *ngIf="hasModule(modules.STAFF_MANAGEMENT)" class="input-field">
          <mat-select [placeholder]="'general.employee.s'|translate"
                      formControlName="employeeId"
                      disableOptionCentering="true"
                      [disabled]="employeeDisabled"
                      [value]="additionalInfoForm.get('employeeId')">
            <mat-option>
              <ngx-mat-select-search [formControl]="filterControl"
                                     [placeholderLabel]="'general.labels.search'|translate:{ value: 'general.employee.s'|translate }"
                                     [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: 'general.employee.pl'|translate }">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let employee of filteredEmployees | async"
                        [value]="employee.employeeId">
              {{ employee.employeeName}} {{ employee.employeeFirstName }} / {{ employee.organisationName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <fa-icon [icon]="faTimes" (click)="clear()">
        </fa-icon>
      </div>
    </mat-card>
    <mat-card class="summary-card">
      <div fxLayout="row">
        <div class="grow-container">
          {{'modules.disposition.base.externalEmployee' | translate}}
        </div>
      </div>
      <div class="summary-card-content" fxLayout="row">
        <mat-form-field class="input-field">
          <input matInput
                 formControlName="externalEmployee"
                 [placeholder]="'modules.disposition.base.externalEmployee'|translate"
                 [matAutocomplete]="employeeAuto">
          <mat-autocomplete #employeeAuto="matAutocomplete">
            <mat-option *ngFor="let externalEmployee of externalEmployees"
                        [value]="externalEmployee | slice:0:30">
              {{ externalEmployee }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <fa-icon [icon]="faTimes" (click)="clear()">
        </fa-icon>
      </div>
    </mat-card>
    <mat-card class="summary-card">
      <div fxLayout="row">
        <div class="grow-container">
          {{'general.comment.s' | translate}}
        </div>
      </div>
      <div class="summary-card-content" fxLayout="row">
        <mat-form-field class="input-field">
          <input matInput
                 formControlName="comment"
                 [placeholder]="'general.comment.s'|translate"
                 [matAutocomplete]="employeeAuto">
        </mat-form-field>
        <fa-icon [icon]="faTimes" (click)="clearComment()">
        </fa-icon>
      </div>
    </mat-card>
  </form>
</div>
