import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';
import { UntypedFormControl } from '@angular/forms';
import { AVAILABLE_OPTIONS, IAvailableTransportTaskState } from './available-transport-task-state';
import { TransportView } from '../../contracts/transport/transport-view.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TransportDetailViewDialogComponent } from '../transport-detail-view-dialog/transport-detail-view-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ifDialogYes } from '../../../../shared/utils';
import { LanguageService } from '../../../../shared/services/language.service';
import { ISelectStatusEvent } from './select-status-event';
import { TransportDateTimeInputDialogConfig } from '../transport-date-input-dialog/transport-date-time-input-dialog-config';
import { TransportDateTimeInputDialogComponent } from '../transport-date-input-dialog/transport-date-time-input-dialog.component';
import { OspDateTime } from '../../../equipment/contract/osp-date-time';

@UntilDestroy()
@Component({
  selector: 'bh-transport-state-change',
  templateUrl: './transport-state-change.component.html',
  styleUrls: ['./transport-state-change.component.scss'],
})
export class TransportStateChangeComponent implements OnInit {
  @Input()
  public set state(value: TransportView) {
    this.setCurrentState(value);
    this.transportTask = value;
  }

  @Input() parent: MatDialogRef<TransportDetailViewDialogComponent>;
  @Input() disabled = false;

  @Output() selectNewStatus: EventEmitter<ISelectStatusEvent> = new EventEmitter<ISelectStatusEvent>();
  @Output() hasDropdownOptions: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.stopPropagation();
    this.close();
  }

  public availableOptions = AVAILABLE_OPTIONS;
  public currentState: IAvailableTransportTaskState;
  public TransportTaskState = TransportTaskState;
  public selectFormControl = new UntypedFormControl(null);
  public isOpened: boolean;
  public transportTask: TransportView;

  private taskTitle: string;

  constructor(private languageService: LanguageService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.initFormControl();
  }

  public setNewState(state: TransportTaskState): void {
    this.selectFormControl.patchValue(state);
    this.isOpened = false;
  }

  public open(): void {
    if (this.currentState.availableStates.length > 0) {
      if (this.parent) {
        this.parent.disableClose = true;
      }
      this.isOpened = true;
    }
  }

  public close(): void {
    if (this.parent) {
      this.parent.disableClose = false;
    }
    this.isOpened = false;
  }

  private initFormControl(): void {
    this.selectFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: TransportTaskState) => {
      switch (this.currentState.state) {
        case TransportTaskState.DONE:
          this.fromDone(value);
          break;
        case TransportTaskState.PLANNED:
          this.fromPlanned(value);
          break;
        case TransportTaskState.IN_PROGRESS:
          this.fromInProgress(value);
          break;
        case TransportTaskState.RETURNED:
          this.fromReturned(value);
          break;
        default:
          this.selectNewStatus.emit({state: value});
          this.close();
      }
    });
  }

  private fromPlanned(value: TransportTaskState): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> | MatDialogRef<TransportDateTimeInputDialogComponent>;
    this.close();
    switch (value) {
      case TransportTaskState.IN_PROGRESS:
        dialogRef = this.dialog.open(TransportDateTimeInputDialogComponent, this.createInputDialogConfig(
            true,
            'modules.transportation.transportStateChange.enterActualTransportStart',
            'modules.transportation.base.actualStartDate',
            'modules.transportation.base.actualStartTime',
            'modules.transportation.transportStateChange.startTask',
            new Date(this.transportTask.equipmentAssignmentDetails.startDate.timestamp)));
        dialogRef.afterClosed().pipe().subscribe((dialogResult: OspDateTime) => {
          if (dialogResult) {
            this.selectNewStatus.emit({state: value, date: dialogResult});
          }
        });
        break;
      case TransportTaskState.PLANNABLE:
      case TransportTaskState.CANCELED:
        dialogRef = this.dialog.open(ConfirmationDialogComponent);
        dialogRef.componentInstance.confirmMessage = this.translate((value === TransportTaskState.PLANNABLE ?
                'modules.transportation.base.warningPlannedToPlannable' :
                'modules.transportation.transportStateChange.plannedToCancelAsk'),
            {value: this.taskTitle},
        );
        dialogRef.afterClosed().pipe(ifDialogYes).subscribe({
          next: () => {
            this.selectNewStatus.emit({state: value});
          },
        });
        break;
    }
  }

  private fromInProgress(value: TransportTaskState): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> | MatDialogRef<TransportDateTimeInputDialogComponent>;
    switch (value) {
      case TransportTaskState.DONE:
        dialogRef = this.dialog.open(TransportDateTimeInputDialogComponent, this.createInputDialogConfig(
            false,
            'modules.transportation.transportStateChange.enterActualTransportEnd',
            'modules.transportation.base.actualEndDate',
            'modules.transportation.base.actualEndTime',
            'modules.transportation.transportStateChange.finishTask',
            new Date(this.transportTask.equipmentAssignmentDetails.executionStartDate.timestamp)));
        dialogRef.afterClosed().pipe().subscribe((dialogResult: OspDateTime) => {
          if (dialogResult) {
            this.selectNewStatus.emit({state: value, date: dialogResult});
          }
        });
        break;
      default:
        this.selectNewStatus.emit({state: value});
        break;
    }
  }

  private fromDone(value: TransportTaskState): void {
    switch (value) {
      case TransportTaskState.IN_PROGRESS:
        this.selectNewStatus.emit({state: value});
        break;
      case TransportTaskState.ARCHIVED:
        this.selectNewStatus.emit({state: value});
        break;
    }
  }

  private fromReturned(value: TransportTaskState): void {
    this.selectNewStatus.emit({state: value});
  }

  private setCurrentState(transportTask: TransportView): void {
    this.taskTitle = transportTask?.title;
    this.currentState = this.availableOptions.find(availableOption => availableOption.state === transportTask?.state);
    this.currentState?.availableStates.forEach((state) => {
      state.disabled = !transportTask.transitionOptions?.[state.availableState];
      state.show = (Object.keys(transportTask.transitionOptions) as TransportTaskState[])?.some(value =>
          value === state.availableState);
    });
    this.hasDropdownOptions.emit(this.currentState?.availableStates?.some(value => value.show));
  }

  private createInputDialogConfig(isStartDate: boolean, title: string, dateLabel: string, timeLabel: string,
                                  confirmText: string, startDate: Date): MatDialogConfig {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = '250px';
    dialogConfig.data = new TransportDateTimeInputDialogConfig(
        isStartDate, this.translate(title), this.translate(dateLabel),
        this.translate(timeLabel), this.translate(confirmText), startDate);
    return dialogConfig;
  }

  private translate(key: string, interpolateParams?: Record<string, string>): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
