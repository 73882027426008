import { SingleFilterOption } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { FilterType } from '../../../contract/filter/filter-type';

let filterByTerm = (filterType: FilterType, term: string): FilterType => {
  let groupDisplayCount = 0;
  filterType.groups.forEach(group => {
    let filterDisplayCount = 0;
    group.filters.forEach(filterItem => {
      if (term !== '') {
        if (filterItem.displayName.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
            filterItem.displayName === SingleFilterOption.ALL_LABELS) {
          filterItem.display = true;
          filterDisplayCount++;
        } else {
          filterItem.display = false;
        }
      } else {
        filterItem.display = true;
        filterDisplayCount++;
      }
    });
    if (filterDisplayCount !== 0) {
      group.display = true;
      groupDisplayCount++;
    } else {
      group.display = false;
    }
  });

  filterType.display = groupDisplayCount !== 0;
  return {...filterType};
};

export function filterItemsByTerm(filters: FilterType[], term: string): FilterType[] {
  const filterSorted: FilterType[] = [];
  (filters || []).forEach(filterItem => filterSorted.push(filterByTerm(filterItem, term)));
  return filterSorted;
}
