import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bh-manufacturer-delete-dialog',
  templateUrl: 'manufacturer-delete-dialog.component.html',
  styleUrls: ['manufacturer-delete-dialog.component.scss']
})
export class ManufacturerDeleteDialogComponent {
  public deletable: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: { deletable: boolean },
              public dialogRef: MatDialogRef<ManufacturerDeleteDialogComponent>) {

    this.deletable = dialogData?.deletable;
  }

}
