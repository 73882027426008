<div [formGroup]="settingsForm" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
    <mat-form-field fxFlex="50">
      <mat-label>{{ getFieldLabel([controlNames.SETTINGS_LIMIT]) }}</mat-label>
      <input matInput [formControlName]="controlNames.SETTINGS_LIMIT"
             bhChangeableInputAllowedPattern="^[1-9][0-9]*$"
             autocomplete="off"
             required [min]="0">
      <mat-error *ngIf="limitControl.hasError('required')">
        {{ 'shared.validation.requiredField' | translate }}
      </mat-error>
      <mat-error *ngIf="limitControl.hasError('min')">
        {{ 'shared.validation.number.notNegative' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="50">
      <mat-label>{{ getFieldLabel([controlNames.SETTINGS_LIMIT_UNIT]) }}</mat-label>
      <mat-select [formControlName]="controlNames.SETTINGS_LIMIT_UNIT" required>
        <mat-option *ngFor="let interval of intervalDurations" [value]="interval">
          {{ interval | intervalDurationTranslation }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="limitControl.hasError('required')">
        {{ 'shared.validation.requiredField' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
    <div fxFlex="100">
      <bh-explanation-card class="explanation"
                           text="{{ 'modules.notification.alarmRuleAddEdit.expl.inactiveTelematicsUnit' | translate }}">
      </bh-explanation-card>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px" style="padding-top: 10px">
    <mat-form-field fxFlex="50">
      <mat-label>{{ getFieldLabel([controlNames.SETTINGS_TELEMATICS_SELECTOR]) }}</mat-label>
      <mat-select [formControlName]="controlNames.SETTINGS_TELEMATICS_SELECTOR"
                  (selectionChange)="onTelematicsSelectorChanged()"
                  required>
        <mat-option *ngFor="let selector of telematicsSelectors" [value]="selector">
          {{ resolveTelematicsSelectorName(selector) }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="limitControl.hasError('required')">
        {{ 'shared.validation.requiredField' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="allowSelectTelematicUnits()" fxFlex="50">
      <mat-label>{{ getFieldLabel([controlNames.SETTINGS_TELEMATICS_TYPES]) }}</mat-label>
      <mat-select [formControlName]="controlNames.SETTINGS_TELEMATICS_TYPES" multiple required>
        <mat-option *ngFor="let unitType of telematicsUnitTypes" [value]="unitType">
          {{ resolveTelematicsUnitName(unitType) }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="limitControl.hasError('required')">
        {{ 'shared.validation.requiredField' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
