import { Utils } from '../../../../../shared/utils';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { TransportItemType } from '../../enums/transport-item-type.enum';
import { TransportItem } from 'app/modules/equipment/shared/transport-items.interface';
import { ContactType } from 'app/modules/contact/shared/enums/contact-type.enum';
import { StockType } from 'app/shared/enums/stock-type';
import { TransportView } from 'app/modules/transportation/contracts/transport/transport-view.interface';
import { TransportItemLocationType } from '../../enums/transport-item-location-type.enum';

interface TransportItemInfo {
  item: TransportItem;
  latestItem: TransportItem;
  isItemDeleted: boolean;
}

export class TransportContextBase {
  private readonly navigationMap = {
    [TransportItemType.CONTACT]: 'assets/contact/list/',
    [TransportItemType.PROJECT]: 'sites/projects/list/',
    [TransportItemType.STOCK]: 'sites/stocks/list/',
  };

  protected getAddressTitle(address: Address): string {
    if (address && (address.street || address.postalCode || address.city)) {
      return Utils.concatTitleText([
        address.street,
        Utils.concatTitleText([address.postalCode, address.city], ' ')
      ], ', ');
    }
    return null;
  }

  protected getNavigationUrl(id: string, type: TransportItemType): string {
    let url = (type && id) ? `${this.navigationMap[type]}${id}` : null;
    if (url && type !== TransportItemType.CONTACT) {
      url = `${url}/general`;
    }
    return url;
  }

  protected getEntityType(item: TransportItem): ContactType | StockType {
    switch (item.transportItemType) {
      case TransportItemType.CONTACT: return item.contactType;
      case TransportItemType.STOCK: return item.stockType;
      default: return null;
    }
  }

  protected getItemInfo(transport: TransportView, locationType: TransportItemLocationType): TransportItemInfo {
    switch (locationType) {
      case TransportItemLocationType.START: {
        return {
          item: transport?.startItem,
          latestItem: transport?.latestRelatedItemDetails?.latestStartItem,
          isItemDeleted: Boolean(transport?.latestRelatedItemDetails?.isStartItemDeleted)
        }
      }
      case TransportItemLocationType.TARGET: {
        return {
          item: transport?.targetItem,
          latestItem: transport?.latestRelatedItemDetails?.latestTargetItem,
          isItemDeleted: Boolean(transport?.latestRelatedItemDetails?.isTargetItemDeleted)
        }
      }
    }
  }
}
