import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdditionalField } from 'app/modules/osp-ui/rich-components/bh-additional-field/additional-field';
import { BehaviorSubject, Observable, combineLatest, debounceTime, filter, map, startWith, switchMap } from 'rxjs';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { AddEditAdditionalFieldDialogComponent } from 'app/modules/field-management/components/additional-fields-management/add-edit-additional-field-dialog/add-edit-additional-field-dialog.component';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-additional-field-assignment-dialog',
  templateUrl: './additional-field-assignment-dialog.component.html',
  styleUrls: ['./additional-field-assignment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalFieldAssignmentDialogComponent {
  public readonly isLoading = new BehaviorSubject<boolean>(false);
  public readonly control = new FormControl<string[]>([]);
  public readonly searchControl = new FormControl<string>('');

  public filteredFields: Observable<AdditionalField[]>;
  public selectedFields: Observable<AdditionalField[]>;
  public fieldsMap: Record<string, AdditionalField> = {};
  public canCreateField = this.authService.hasAuthority(Authorities.ADDITIONAL_FIELD_MANAGE);

  private readonly fields = new BehaviorSubject<AdditionalField[]>([]);

  constructor(
    private matDialogRef: MatDialogRef<AdditionalFieldAssignmentDialogComponent, any>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: AdditionalField[],
    private translatableStringResolver: TranslatableStringPipe,
    private authService: KeycloakService,
    private userConfigurationService: UserConfigurationService,
  ) {
    this.fields.next(data || []);
    this.initListeners();
  }

  public remove(option: AdditionalField): void {
    this.control.setValue(this.control.value.filter(fieldId => fieldId !== option.additionalFieldId));
  }

  public save(): void {
    const fieldsIds = new Set(this.control.value || []);
    const assignedFields = this.fields.getValue().filter(({ additionalFieldId }) => fieldsIds.has(additionalFieldId));
    const dialogResult = assignedFields.length > 0 ? assignedFields : null;
    this.matDialogRef.close(dialogResult);
  }

  public createNewAdditionalField(): void {
    this.userConfigurationService.getLanguageSettings()
      .pipe(
        switchMap(data => this.matDialog
          .open(AddEditAdditionalFieldDialogComponent, {data, autoFocus: false})
          .afterClosed()),
        filter(Boolean),
        untilDestroyed(this))
      .subscribe((field: AdditionalField) => this.addNewField(field));
  }

  private initListeners(): void {
    this.initListenerAvailableFieldsChange();
    this.initListenerFilteredFields();
    this.initListenerSelectedFields();
  }

  private initListenerAvailableFieldsChange(): void {
    this.fields.asObservable()
    .pipe(untilDestroyed(this))
    .subscribe(fields => {
      this.fieldsMap = this.convertToFieldsMap(fields)
      this.checkSelectedConsistency(this.control.value, fields);
    });
  }

  private initListenerFilteredFields(): void {
    this.filteredFields = combineLatest([
      this.fields.asObservable(),
      this.control.valueChanges.pipe(startWith(<string[]>[])),
      this.searchControl.valueChanges.pipe(startWith(''), debounceTime(300))
    ]).pipe(
      map(([fields, selected, term]) => this.filterFields(fields, selected, term)),
      untilDestroyed(this)
    );
  }

  private initListenerSelectedFields(): void {
    this.selectedFields = this.control.valueChanges
    .pipe(
      startWith(<string[]>[]),
      map(fieldIds => fieldIds.map(id => this.fieldsMap[id])),
      untilDestroyed(this)
    );
  }

  private addNewField(field: AdditionalField): void {
    this.fields.next([field, ...this.fields.getValue()]);
    this.control.setValue([...this.control.value, field.additionalFieldId]);
  }

  private filterFields(fields: AdditionalField[], selected: string[], term: string): AdditionalField[] {
    const selectedSet = new Set(selected);
    return fields.filter(field => selectedSet.has(field.additionalFieldId)
      || this.fieldNameLowerCase(field)?.includes(term.toLowerCase())
    ).sort((a,b) =>
      this.fieldNameLowerCase(a) < this.fieldNameLowerCase(b) ? -1 : 1
    );
  }

  private fieldNameLowerCase(field: AdditionalField): string {
    return this.translatableStringResolver.transform(field?.additionalFieldName)?.toLowerCase();
  }

  private checkSelectedConsistency(selected: string[], fields: AdditionalField[]): void {
    const allFieldIds = new Set((fields ?? []).map(({ additionalFieldId }) => additionalFieldId));
    const newSelected = (selected ?? []).filter(fieldId => allFieldIds.has(fieldId));
    if (selected.length !== newSelected.length) {
      this.control.setValue(newSelected);
    }
  }

  private convertToFieldsMap(fields: AdditionalField[]): Record<string, AdditionalField> {
    return fields.reduce((acc, field) => ({
      ...acc,
      [field.additionalFieldId]: field
    }), {});
  }

}
