import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WizardOptions } from '../../../../../../shared/wizard-options.interface';
import {
  DispositionProject,
  instanceOfDispositionProject
} from '../../../../shared/disposition-project';
import { MobileWizardComponent } from '../../../../../../shared/components/mobile-wizard-component/mobile-wizard.component';
import { MobileTransferAmountInputDialogComponent } from './mobile-transfer-amount-input-dialog/mobile-transfer-amount-input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MobileTransferItem } from '../../../../contract/mobile-transfer-item';
import { MobileTransferScannerComponent } from './mobile-transfer-scanner/mobile-transfer-scanner.component';
import { MobileTransferChooseLocationComponent } from './mobile-transfer-choose-location/mobile-transfer-choose-location.component';
import { DispositionBulkItem } from '../../../../shared/disposition-bulk-item';
import {
  instanceOfViewProjectAmount,
  ViewProjectAmount
} from '../../../../contract/view-project-amount.interface';
import { Router } from '@angular/router';
import { DispositionStock, instanceOfDispositionStock } from '../../../../shared/disposition-stock';
import { CreateStockToProjectTransferCartCommand } from '../../../../contract/create-stock-to-project-transfer-cart.command';
import { StockToProjectTransferItem } from '../../../../contract/stock-to-project-transfer-item';
import { ProjectToStockTransferItem } from '../../../../contract/project-to-stock-transfer-item';
import { TransferStore } from '../../shared/transfer.store';
import { ProjectToProjectTransferItem } from '../../../../contract/project-to-project-transfer-item';
import { CreateProjectToProjectTransferCartCommand } from '../../../../contract/create-project-to-project-transfer-cart.command';
import { StockToStockTransferItem } from '../../../../contract/stock-to-stock-transfer-item.interface';
import { CreateStockToStockTransferCartCommand } from '../../../../contract/create-stock-to-stock-transfer-cart-command';
import { CreateProjectToStockTransferCartCommand } from '../../../../contract/create-project-to-stock-transfer-cart-command';
import { MobileTransferChooseAmountDialogComponent } from './mobile-transfer-choose-amount-dialog/mobile-transfer-choose-amount-dialog.component';
import { ConfirmationDialogComponent } from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UserConfigurationService } from '../../../../../../shared/services/user-configuration.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ProjectToConsumedTransferItem } from '../../../../contract/project-to-consumed-transfer-item.interface';
import { CreateProjectToConsumedTransferCartCommand } from '../../../../contract/create-project-to-consumed-transfer-cart-command';
import { AmountStore } from '../../../../shared/amount.store';
import {dialogResults} from '../../../../../../shared/enums/dialogResults.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { instanceOfViewBulkItemAmount, ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';
import { AmountsService } from 'app/modules/disposition/shared/amounts.service';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-bulk-item-transfer',
  templateUrl: './mobile-bulk-item-transfer.component.html',
  styleUrls: ['./mobile-bulk-item-transfer.component.scss']
})
export class MobileBulkItemTransferComponent implements OnInit, OnDestroy {
  @ViewChild(MobileWizardComponent, { static: true }) wizard: MobileWizardComponent;
  @ViewChild('sourceSelect') sourceSelect: MobileTransferChooseLocationComponent;
  @ViewChild('targetSelect') targetSelect: MobileTransferChooseLocationComponent;
  @ViewChild(MobileTransferScannerComponent) scanner: MobileTransferScannerComponent;

  public wizardOptions: WizardOptions = {
    'transfer': [
      {
        key: 'choose-source',
        shortName: this.translate('modules.disposition.bulkItemTransfer.selectStartPoint')
      },
      {
        key: 'scanner',
        shortName: this.translate('modules.disposition.bulkItemTransfer.scanBulkItems'),
      },
      {
        key: 'item-list',
        shortName: this.translate('modules.disposition.bulkItemTransfer.scannedArticles'),
      },
      {
        key: 'choose-target',
        shortName: this.translate('modules.disposition.bulkItemTransfer.chooseDestination'),
      },
      {
        key: 'summary',
        shortName: this.translate('modules.disposition.bulkItemTransfer.summary'),
      },
    ]
  };

  public currentStepValid = false;
  public backEnabled = false;
  public bulkItems: MobileTransferItem[] = [];
  public additionalInfoForm: UntypedFormGroup;
  public source: DispositionStock | DispositionProject;
  public target: DispositionStock | DispositionProject;
  public consumedSelected: boolean;
  public startOfProcess = true;
  public sourceTab = 0;
  public targetTab = 0;

  private amountMode: boolean;

  constructor(private dialog: MatDialog,
              private bulkItemAmountsService: AmountsService,
              private amountStore: AmountStore,
              private router: Router,
              private transferStore: TransferStore,
              private userConfigurationService: UserConfigurationService,
              private languageService: LanguageService,
              private formBuilder: UntypedFormBuilder) {
  }

  public ngOnInit(): void {
    this.buildAdditionalInformationForm();
    const mobileTransferConfig = this.userConfigurationService.getMobileTransferConfig();
    if (mobileTransferConfig) {
      this.targetTab = mobileTransferConfig.targetTab;
      this.sourceTab = mobileTransferConfig.sourceTab;
    }
  }

  public ngOnDestroy(): void {
  }

  public selectSource(source: DispositionStock | DispositionProject): void {
    this.source = source;
    this.setToValid();
    this.startOfProcess = false;
  }

  public selectTarget(target: DispositionStock | DispositionProject): void {
    this.target = target;
    this.consumedSelected = false;
    this.setToValid();
    this.wizard.tabs.toArray()[this.wizard.currentStep + 1].isValid = true;
  }

  public selectTargetTab(tab: number) {
    this.targetTab = tab;
  }

  public selectSourceTab(tab: number) {
    this.sourceTab = tab;
  }

  public selectConsumed(): void {
    this.target = null;
    this.setToValid();
    this.wizard.tabs.toArray()[this.wizard.currentStep + 1].isValid = true;
    this.consumedSelected = true;
  }

  public goToLandingPage(): void {
    this.router.navigate(['mobile/landing-page']);
  }

  private goBackToScanner(): void {
    this.wizard.goToTab('scanner');
  }

  private buildAdditionalInformationForm(): void {
    this.additionalInfoForm = this.formBuilder.group({
      employeeId: [null],
      externalEmployee: [null],
      comment: [null]
    });
  }

  public setValidity(): void {
    this.currentStepValid = this.wizard.tabs.toArray()[this.wizard.currentStep].isValid;
    this.backEnabled = (this.wizard.currentStep === 1) && this.bulkItems.length > 0 ? false : true;
  }

  public finish(): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.completeTransfer');
    dialogRef.componentInstance.confirmLabel = this.translate('general.buttons.confirm');
    dialogRef.componentInstance.denyLabel = this.translate('general.buttons.cancel');

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'yes') {
        this.userConfigurationService.saveMobileTransferConfiguration(this.sourceTab, this.targetTab);
        this.createTransfer();
      }
    });
  }

  public createTransfer(): void {
    if (instanceOfDispositionProject(this.source) && this.consumedSelected) {
      this.transferProjectToConsumed(this.source);
    } else if (instanceOfDispositionStock(this.source) && instanceOfDispositionProject(this.target)) {
      this.transferStockToProject(this.source, this.target);
    } else if (instanceOfDispositionStock(this.source) && instanceOfDispositionStock(this.target)) {
      this.transferStockToStock(this.source, this.target);
    } else if (instanceOfDispositionProject(this.source) && instanceOfDispositionProject(this.target)) {
      this.transferProjectToProject(this.source, this.target);
    } else if (instanceOfDispositionProject(this.source) && instanceOfDispositionStock(this.target)) {
      this.transferProjectToStock(this.source, this.target);
    }
  }

  public entryDeleted(item: MobileTransferItem, isSummary: boolean): void {
    this.bulkItems = this.bulkItems.filter(bulkItem => item.transferId !== bulkItem.transferId
      || item.sourceStockId !== bulkItem.sourceStockId);
    if (this.bulkItems.length < 1) {
      if (!isSummary) {
        this.wizard.tabs.toArray()[this.wizard.currentStep].isValid = false;
        this.wizard.tabs.toArray()[this.wizard.currentStep - 1].isValid = false;
        this.wizard.previousTab();
      } else {
        this.wizard.tabs.toArray()[this.wizard.currentStep - 2].isValid = false;
        this.wizard.tabs.toArray()[this.wizard.currentStep - 3].isValid = false;
        this.goBackToScanner();
      }
    }
  }

  public entryUpdated(item: MobileTransferItem): void {
    this.bulkItems.map(bulkItem => {
      if (bulkItem.transferId === item.transferId && bulkItem.sourceStockId === item.sourceStockId) {
        bulkItem.amount = item.amount;
      }
    })
  }

  public bulkItemScanned(item: DispositionBulkItem) {
    if (!this.amountMode && this.wizard.currentStep === 1) {
      this.amountMode = true;
      if (instanceOfDispositionStock(this.source)) {
        this.handleSourceStock(item, this.source);
      } else if (instanceOfDispositionProject(this.source)) {
        this.handleSourceProject(item, this.source);
      }
    } else {
      this.scanner.searchingByQrCode = false;
    }
  }

  private handleSourceStock(item: DispositionBulkItem, source: DispositionStock): void {
    if (this.bulkItems.find(bulkItem => bulkItem.transferId === item.bulkItemId)) {
      this.scanner.duplicatedEntry = true;
      this.scanner.searchingByQrCode = false;
      this.amountMode = false;
      setTimeout(() => this.scanner.duplicatedEntry = false, environment.DELAY_LONGEST);
    } else {
      this.amountStore.getAmountForTransfer(item.bulkItemId, source)
        .pipe(untilDestroyed(this))
        .subscribe(amount => {
          this.scanner.searchingByQrCode = false;
          this.handleAmount(amount);
        });
    }
  }

  private handleSourceProject(item: DispositionBulkItem, source: DispositionProject): void {
    this.amountStore.getProjectAmountsForTransfer(item.bulkItemId, source)
      .pipe(untilDestroyed(this))
      .subscribe(amounts => {
        if (amounts) {
          this.scanner.searchingByQrCode = false;
          const foundAmounts = amounts.filter(amount => {
            return !this.amountInUse(amount);
          });
          if (foundAmounts.length > 1) {
            this.openChooseAmountProcess(foundAmounts, item);
          } else {
            if (this.amountInUse(foundAmounts[0])) {
              this.scanner.duplicatedEntry = true;
              this.amountMode = false;
              setTimeout(() => this.scanner.duplicatedEntry = false, environment.DELAY_LONGEST);
            } else {
              this.scanner.duplicatedEntry = false;
              this.handleAmount(foundAmounts[0]);
            }
          }
        } else {
          this.scanner.noAmount = true;
          this.amountMode = false;
          this.scanner.searchingByQrCode = false;
          setTimeout(() => this.scanner.noAmount = false, environment.DELAY_LONGEST);
        }
      });
  }

  private amountInUse(amount: ViewProjectAmount): boolean {
    return !!this.bulkItems.find(bulkItem => bulkItem.sourceStockId === amount.stockId
      && bulkItem.transferId === amount.transferItemId);
  }

  private openChooseAmountProcess(foundAmounts: ViewProjectAmount[], item: DispositionBulkItem): void {
    const dialogRef = this.dialog.open(MobileTransferChooseAmountDialogComponent);
    dialogRef.componentInstance.amounts = foundAmounts;
    dialogRef.componentInstance.bulkItem = item;
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.ABORT) {
        this.amountMode = false;
      } else if (instanceOfViewProjectAmount(result)) {
        this.handleAmount(result);
      }
    });
  }

  private handleAmount(amount: ViewBulkItemAmount | ViewProjectAmount): void {
    if (amount && this.amountIsNotEmpty(amount)) {
      this.scanner.noAmount = false;
      const transferItem = instanceOfViewBulkItemAmount(amount) ? MobileTransferItem.fromViewAmount(amount, 1)
        : MobileTransferItem.fromViewProjectAmount(amount, 1);

      let dialogRef = this.dialog.open(MobileTransferAmountInputDialogComponent, {disableClose: true});
      dialogRef.componentInstance.bulkItemAmount = transferItem;
      dialogRef.afterClosed().subscribe(transferAmount => {
        this.amountMode = false;
        if (transferAmount) {
          transferItem.amount = transferAmount;
          this.bulkItems.push(transferItem);
          this.setToValid();
          this.wizard.tabs.toArray()[this.wizard.currentStep].isValid = true;
          this.wizard.tabs.toArray()[this.wizard.currentStep + 1].isValid = true;
          this.setValidity();
        }
      });
    } else {
      this.amountMode = false;
      this.scanner.noAmount = true;
      setTimeout(() => this.scanner.noAmount = false, environment.DELAY_LONGEST);
    }
  }

  private transferStockToProject(source: DispositionStock, target: DispositionProject): void {
    const transferItems: StockToProjectTransferItem[] = this.bulkItems.map(item =>
       StockToProjectTransferItem.fromMobileTransferItem(item, source.stockId));
    const cmd = new CreateStockToProjectTransferCartCommand(target.projectId,
      transferItems,
      this.additionalInfoForm.get('employeeId').value === '' ?  null : this.additionalInfoForm.get('employeeId').value,
      this.additionalInfoForm.get('externalEmployee').value === '' ?  null : this.additionalInfoForm.get('externalEmployee').value,
      this.additionalInfoForm.get('comment').value === '' ?  null : this.additionalInfoForm.get('comment').value)
    this.transferStore.transferStockToProject(cmd);
    this.goToLandingPage();
  }

  private transferProjectToProject(source: DispositionProject, target: DispositionProject): void {
    const transferItems: ProjectToProjectTransferItem[] = this.bulkItems.map(item =>
       ProjectToProjectTransferItem.fromMobileTransferItem(item, source.projectId));
    const cmd = new CreateProjectToProjectTransferCartCommand(target.projectId, transferItems,
      this.additionalInfoForm.get('employeeId').value === '' ?  null : this.additionalInfoForm.get('employeeId').value,
      this.additionalInfoForm.get('externalEmployee').value === '' ?  null : this.additionalInfoForm.get('externalEmployee').value,
      this.additionalInfoForm.get('comment').value === '' ?  null : this.additionalInfoForm.get('comment').value)
    this.transferStore.transferProjectToProject(cmd, source.projectId);
    this.goToLandingPage();
  }

  private transferStockToStock(source: DispositionStock, target: DispositionStock): void {
    const transferItems: StockToStockTransferItem[] = this.bulkItems.map(item =>
       StockToStockTransferItem.fromMobileTransferItem(item, source.stockId));
    const cmd = new CreateStockToStockTransferCartCommand(target.stockId, transferItems,
      this.additionalInfoForm.get('employeeId').value === '' ?  null : this.additionalInfoForm.get('employeeId').value,
      this.additionalInfoForm.get('externalEmployee').value === '' ?  null : this.additionalInfoForm.get('externalEmployee').value,
      this.additionalInfoForm.get('comment').value === '' ?  null : this.additionalInfoForm.get('comment').value)
    this.transferStore.transferStockToStock(source.stockId, cmd);
    this.goToLandingPage();
  }

  private transferProjectToStock(source: DispositionProject, target: DispositionStock): void {
    const transferItems: ProjectToStockTransferItem[] = this.bulkItems.map(item =>
       ProjectToStockTransferItem.fromMobileTransferItem(item, source.projectId));
    const cmd = new CreateProjectToStockTransferCartCommand(target.stockId, transferItems,
      this.additionalInfoForm.get('employeeId').value === '' ?  null : this.additionalInfoForm.get('employeeId').value,
      this.additionalInfoForm.get('externalEmployee').value === '' ?  null : this.additionalInfoForm.get('externalEmployee').value,
      this.additionalInfoForm.get('comment').value === '' ?  null : this.additionalInfoForm.get('comment').value)
    this.transferStore.transferProjectToStock(source.projectId, cmd);
    this.goToLandingPage();
  }

  private transferProjectToConsumed(source: DispositionProject): void {
    const transferItems: ProjectToConsumedTransferItem[] = this.bulkItems.map(item =>
       ProjectToConsumedTransferItem.fromMobileTransferItem(item));
    const cmd = new CreateProjectToConsumedTransferCartCommand(source.projectId, transferItems,
      this.additionalInfoForm.get('employeeId').value === '' ?  null : this.additionalInfoForm.get('employeeId').value,
      this.additionalInfoForm.get('externalEmployee').value === '' ?  null : this.additionalInfoForm.get('externalEmployee').value,
      this.additionalInfoForm.get('comment').value === '' ?  null : this.additionalInfoForm.get('comment').value)
    this.transferStore.transferProjectToConsumed(source.projectId, cmd);
    this.goToLandingPage();
  }

  private setToValid(): void {
    this.currentStepValid = true;
    this.wizard.tabs.toArray()[this.wizard.currentStep].isValid = true;
  }

  private amountIsNotEmpty(amount: ViewBulkItemAmount | ViewProjectAmount): boolean {
    if (instanceOfViewBulkItemAmount(amount)) {
      return amount.amountAvailable > 0;
    } else if (instanceOfViewProjectAmount(amount)) {
      return amount.amount > 0;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
