import { ActivatedRoute, Router } from '@angular/router';
import { ListType } from 'app/shared/enums/list-type.enum';
import { DisplayService } from 'app/shared/display.service';
import { Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ContactDataSource } from '../../shared/services/contact.datasource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { MatDialog } from '@angular/material/dialog';
import { ColumnDefinition } from 'app/shared/column-definition';
import { ContactColumnService } from '../../shared/services/contact-column.service';
import { environment } from 'environments/environment';
import { TabService } from 'app/shared/tab.service';
import { ContactAddEditComponent } from '../contact-add-edit/contact-add-edit.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { CurrentItemPaginatorUtils } from 'app/shared/directives/current-item-paginator/current-item-paginator-utils.class';
import { AddressService } from 'app/shared/services/address.service';
import { ContactType } from '../../shared/enums/contact-type.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { LanguageService } from 'app/shared/services/language.service';
import { COLUMN_TYPE } from '../../../../shared/constants/column-definition-constants';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  TableConfigurationData,
  TableConfigurationDialogComponent,
} from '../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { ContactListExportDialogComponent } from './contact-list-export-dialog/contact-list-export-dialog.component';


@UntilDestroy()
@Component({
  selector: 'bh-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements SearchFilterActivatedView, OnInit {
  public readonly filters: FilterType[] = this.contactDataSource.filters;
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly faFileExcel: IconDefinition = faFileExcel;
  public searchForm: UntypedFormGroup;
  public routeButton: ButtonInfo = {
    route: 'contact/map',
    tooltip: this.translate('general.map.s'),
    iconName: 'map'
  };
  public selectedColumns: ColumnDefinition[];
  public displayedColumns: string[];
  public readonly isIdentifierOfContact = CurrentItemPaginatorUtils.isIdentifierOfContact;

  private _showTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly showTable: Observable<boolean> = this._showTable.asObservable();

  public onFiltersUpdated = this.contactDataSource.onFiltersUpdated;
  public onTotalCountUpdated = new EventEmitter<number>();
  private readonly baseComponentPath = '/assets/contact/list';
  public readonly searchSuggestionsField = 'contactListSuggestions';


  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  constructor(
    public displayService: DisplayService,
    public contactDataSource: ContactDataSource,
    public contactTypeResolver: ContactTypeResolverPipe,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    protected columnService: ContactColumnService,
    private tabService: TabService,
    private addressService: AddressService,
    private languageService: LanguageService) { }

  public ngOnInit(): void {
    this.handleRouterParams();
    this.initListeners();
    this.tabService.changeAssetTab(ListType.CONTACTS);
    this.contactDataSource.updateFilters();
    this.contactDataSource.updateListing();
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.contactDataSource.updateFilterParams(commands);
    this.contactDataSource.updateListing(0);
  }

  public getContactTypeIcon(type: ContactType): IconDefinition {
    return this.contactTypeResolver.resolveIcon(type);
  }

  public formatAddress(address: Address): string {
    return address
      ? this.addressService.formatFullAddressSingleLine(
          address.postalCode,
          address.city,
          address.street,
          address.streetNumber)
      : '-';
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(term => this.updateOnSearch(term));

    this.searchForm.patchValue({terms: this.contactDataSource.searchTerms}, { emitEvent: false });
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public sortData(sort: Sort): void {
    this.contactDataSource.sort = sort;
    this.contactDataSource.updateListing();
  }

  public onPaginateChange({ pageIndex, pageSize }: PageEvent): void {
    this.contactDataSource.updateListing(pageIndex, pageSize);
  }

  public openConfiguration(): void {
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: {columnService: this.columnService} as TableConfigurationData,
      restoreFocus: false,
      autoFocus: false
    });
    dialogRef.afterClosed()
    .pipe(
      untilDestroyed(this),
      filter(Boolean))
    .subscribe((columns: ColumnDefinition[]) => {
      if (columns) {
        this.columnService.selectColumns(columns, this.paginator.pageSize)
        this.contactDataSource.updateListing();
      }
    });
  }

  public openDetails(): void {
    this.displayService.displayTableAndDetails();
  }

  public openContactListExport(): void {
    this.dialog.open(ContactListExportDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { selectedColumns: this.selectedColumns },
    });
  }

  private handleRouterParams(): void {
    if (this.route.snapshot.data['create']) {
      this.openContactAddEditDialog();
    } else if (!this.isCurrentContactSelected()) {
      this.contactDataSource.selectCurrentContactOrDefault();
    } else {
      this.displayService.displayTableAndDetails();
    }
  }

  private isCurrentContactSelected(): boolean {
    return Boolean(this.router.url !== this.baseComponentPath);
  }

  private openContactAddEditDialog(): void {
    this.dialog.open(ContactAddEditComponent, {disableClose: true})
    .afterClosed()
    .pipe(untilDestroyed(this))
    .subscribe(result => {
      if (result === dialogResults.ABORT) {
        this.router.navigate(['assets/contact/list']);
      }
    });
  }

  private initListeners(): void {
    this.selectedColumnsListener();
    this.pageSizeListener();
    this.displayedColumnsListener();
    this.totalCountListener();
    this.showTableListener();
  }

  private selectedColumnsListener(): void {
    this.columnService.selectedColumns
    .pipe(untilDestroyed(this))
    .subscribe((selectedColumns: ColumnDefinition[]) => this.selectedColumns = [...selectedColumns]);
  }

  private pageSizeListener(): void {
    this.columnService.pageSize
    .pipe(untilDestroyed(this))
    .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
  }

  private displayedColumnsListener(): void {
    this.columnService.displayedColumns
    .pipe(untilDestroyed(this))
    .subscribe((columns: string[]) => this.displayedColumns = [...columns]);
  }

  private totalCountListener(): void {
    this.contactDataSource.totalCount
    .pipe(untilDestroyed(this))
    .subscribe(count => this.onTotalCountUpdated.emit(count));
  }

  private showTableListener(): void {
    combineLatest([this.contactDataSource.contacts, this.displayService.isTableAndDetailsChanges])
    .pipe(untilDestroyed(this))
    .subscribe(([contacts, isSplitScreen]) =>
        this._showTable.next(contacts?.length > 0 || !isSplitScreen));
  }

  private updateOnSearch(searchTerm: string): void {
    this.contactDataSource.searchTerms = searchTerm;
    this.contactDataSource.updateListing(0);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
