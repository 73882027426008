import moment from 'moment';
import { BehaviorSubject, distinctUntilChanged, map, Observable, Subscription } from 'rxjs';
import { ILazyLoaderParams } from '../contract/lazy-loader-params/lazy-loader-params.interface';

enum PageLoadingStatus {
  LOADED = 'LOADED',
  PENDING = 'PENDING'
}

interface PageState {
  key: string;
  status: PageLoadingStatus;
  lastUpdate: Date;
}

export abstract class LazyLoaderBase<T> {
  private store = new Map<string, T>();
  private _data = new BehaviorSubject<T[]>([]);
  private currentRequestParams: ILazyLoaderParams;
  private _currentRequestLoading = new BehaviorSubject<boolean>(false);
  private pageStates = new Map<string, PageState>();
  private loadingSubscriptions: Subscription[] = [];
  private readonly pageDelta = 1;

  public readonly data = this._data.asObservable();
  public readonly currentRequestLoading = this._currentRequestLoading.asObservable().pipe(distinctUntilChanged());

  constructor(private keyField: string) {}

  protected abstract getAsyncData(params: ILazyLoaderParams): Observable<T[]>;

  public load(params: ILazyLoaderParams): void {
    if (!params) {
      return;
    }
    this.clearStoreIfNecessary(params);
    this.currentRequestParams = params;

    const expandedParams = this.getExpandedParams(this.currentRequestParams);
    const filteredParams = this.excludeExistingPages(expandedParams);
    if (filteredParams) {
      this.startLoading(filteredParams);
    }
  }

  public clearStore(): void {
    this.store = new Map();
    this._currentRequestLoading.next(false);
    this.pageStates = new Map();
    this.loadingSubscriptions.forEach(subscr => subscr.unsubscribe());
    this.loadingSubscriptions = [];
  }

  public addItem(item: T): void {
    this.setItem(item);
  }

  public updateItem(item: T): void {
    this.setItem(item);
  }

  private setItem(item: T): void {
    this.store.set(item[this.keyField], item);
    this.emitData();
  }

  private startLoading(params: ILazyLoaderParams): void {
    this.setPageStatesPending(params);
    this._currentRequestLoading.next(this.isCurrentRequestLoading());

    const subscr = this.getAsyncData(params)
      .pipe(map(items => items.map((item) => [ item[this.keyField], item ])))
      .subscribe((newData: [string, T][]) => {
        this.store = new Map([...this.store, ...newData]);
        this.setPageStatesLoaded(params);
        this._currentRequestLoading.next(this.isCurrentRequestLoading());
        this.emitData();
      });
    this.loadingSubscriptions.push(subscr);
  }

  private setPageStatesPending(params: ILazyLoaderParams): void {
    params.getPages()
      .forEach((range, key) => this.pageStates.set(key, { key, status: PageLoadingStatus.PENDING, lastUpdate: null }));
  }

  private setPageStatesLoaded(params: ILazyLoaderParams): void {
    params.getPages()
      .forEach((range, key) => this.pageStates.set(key, { key, status: PageLoadingStatus.LOADED, lastUpdate: new Date() }));
  }

  private isCurrentRequestLoading(): boolean {
    return [...this.currentRequestParams.getPages().keys()]
      .some(key => this.pageStates.get(key)?.status === PageLoadingStatus.PENDING);
  }

  private clearStoreIfNecessary(params: ILazyLoaderParams): void {
    if (Boolean((!this.currentRequestParams || !this.currentRequestParams.isSameExceptDateRange(params)))) {
      this.clearStore();
    }
  }

  private getExpandedParams(requested: ILazyLoaderParams): ILazyLoaderParams {
    const start = moment(requested.getStartDate()).add(-1 * this.pageDelta, 'month').toDate();
    const end = moment(requested.getEndDate()).add(this.pageDelta, 'month').toDate();
    return requested.copy(start, end);
  }

  private excludeExistingPages(params: ILazyLoaderParams): ILazyLoaderParams {
    const { min, max } = [...params.getPages()]
      .filter(([key]) =>  !this.pageStates.has(key))
      .reduce((acc, [key, { start, end }]) => ({
        min: (acc.min && acc.min < start) ? acc.min : start,
        max: (acc.max && acc.max > end) ? acc.max : end
      }), { min: null, max: null });
    return (min && max) ? params.copy(min, max) : null;
  }

  private emitData(): void {
    this._data.next([...this.store.values()]);
  }
}
