import { Pipe, PipeTransform } from '@angular/core';
import { FilterItem } from '../service/search-filter.converter';

@Pipe({
  name: 'filterHidden'
})
export class FilterHiddenPipe implements PipeTransform {

  public transform(items: FilterItem[]): FilterItem[] {
    return items.reduce((acc, item) => (item.hidden ? acc : [...acc, item]), []);
  }

}
