<div fxFill class="transfer-cart-overview"
     fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">

  <bh-transfer-cart-header [lineOne]="'general.transfer.s'|translate"
                           [lineTwo]="'modules.disposition.bulkItemTransfer.transferStockProject'|translate"
                           [icon]="faExchange"
                           (closed)="closeCart()"></bh-transfer-cart-header>

  <div class="transfer-cart-items" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <bh-transfer-items [transferItems]="transferItemsFiltered | async"
                           [itemsRemovable]="true"
                           [removeAllAvailable]="true"
                           [sourceLabel]="'modules.disposition.bulkItemTransfer.inStock'|translate"
                           [targetLabel]="'modules.disposition.bulkItemTransfer.toProject'|translate"
                           [invalid]="!project"
                           (submitted)="confirmCart()"
                           (itemsCleared)="clearCart()"
                           (itemRemoved)="remove($event)"
                           (searchFilterApplied)="updateFilterControl($event)"
                           (amountUpdated)="updateAmount($event)"></bh-transfer-items>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="50">
      <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
        <bh-bulk-item-transfer-project-select (projectSelected)="selectProject($event)"></bh-bulk-item-transfer-project-select>
      </mat-card-content>
    </mat-card>

  </div>

</div>
