import { Component, Inject, OnInit } from '@angular/core';
import { ViewOrganisation } from '../../contract/view-organisation.interface';
import { OrganisationsService } from '../../shared/organisations.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CountryResolver} from '../../../../shared/country.resolver';
import {CountryEnum} from '../../../../shared/country.enum';

export interface OrganisationViewArgs {
  organisationId: string;
}

@Component({
  selector: 'bh-organisation-view',
  templateUrl: './organisation-view.component.html',
  styleUrls: ['./organisation-view.component.scss']
})
export class OrganisationViewComponent implements OnInit {

  public organisation: ViewOrganisation;
  public parentOrganisation: ViewOrganisation;
  public getOrganisationInProgress: boolean;

  constructor(private organisationsService: OrganisationsService,
              private countryResolver: CountryResolver,
              @Inject(MAT_DIALOG_DATA) public data: OrganisationViewArgs) { }

  ngOnInit() {
    this.getOrganisationInProgress = true;
    this.getOrganisation();
  }

  getOrganisation() {
    this.organisationsService.getOrganisation(this.data.organisationId).subscribe(
        data => {
          this.organisation = data;
          this.getOrganisationInProgress = false;

          if (this.organisation.parentOrganisationId) {
            this.getParentOrganisation();
          }
        },
        error => {
          console.log('get organisation error: ', error);
        }
    );
  }

  getParentOrganisation() {
    this.organisationsService.getOrganisation(this.organisation.parentOrganisationId).subscribe(
        data => {
          this.parentOrganisation = data;
        },
        error => {
          console.log('get organisation error: ', error);
        }
    );
  }

  public getCountryName(option: CountryEnum): string {
    return this.countryResolver.resolveName(option);
  }
}
