<div class="dialog-wrapper">
  <h1 mat-dialog-title>{{'modules.dashboard.addWidget.title'|translate}}</h1>
  <div mat-dialog-content>
    <p>{{'modules.dashboard.addWidget.labelChooseWidget'|translate}}</p>
    <div class="form-container">
      <mat-form-field>
        <mat-label>{{'modules.dashboard.addWidget.placeholderWidgetType'|translate}}</mat-label>
        <mat-select
          [(value)]="widgetDefinition"
          [disableOptionCentering]="true">
          <mat-option *ngFor="let wt of widgetDefinitions" [value]="wt">
            {{wt.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button mat-button (click)="onCancel()">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button mat-button color="primary"
            [mat-dialog-close]="widgetDefinition?.widgetType"
            [disabled]="!widgetDefinition" cdkFocusInitial>
      {{'general.buttons.ok'|translate}}
    </button>
  </div>
</div>
