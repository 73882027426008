import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { matomoCategories } from '../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';

@Injectable()
export class DisplayService {

  private TABLE_FULLSCREEN = 'TABLE_FULLSCREEN';
  private TABLE_AND_DETAILS = 'TABLE_AND_DETAILS';
  private DETAILS_FULLSCREEN = 'DETAILS_FULLSCREEN';
  private _displayOption: BehaviorSubject<string> = new BehaviorSubject<string>(this.TABLE_FULLSCREEN);
  public readonly displayOption: Observable<string> = this._displayOption.asObservable();
  public readonly isTableFullscreenChanges = this.displayOption.pipe(map(() => this.isTableFullscreen()));
  public readonly isTableAndDetailsChanges = this.displayOption.pipe(map(() => this.isTableAndDetails()));
  public readonly isDetailsFullscreenChanges = this.displayOption.pipe(map(() => this.isDetailsFullscreen()));

  constructor(private matomoTracker: MatomoTracker) {
  }

  public displayTableFullscreen(): void {
    this._displayOption.next(this.TABLE_FULLSCREEN);
  }

  public displayTableAndDetails(): void {
    this._displayOption.next(this.TABLE_AND_DETAILS);
    window.dispatchEvent(new Event('resize'));
  }

  public displayDetailsFullscreen(): void {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_LIST, matomoActions.FULLSCREEN);
    this._displayOption.next(this.DETAILS_FULLSCREEN);
    window.dispatchEvent(new Event('resize'));
  }

  public isTableFullscreen(): boolean {
    return this._displayOption.value === this.TABLE_FULLSCREEN;
  }

  public isTableAndDetails(): boolean {
    return this._displayOption.value === this.TABLE_AND_DETAILS;
  }

  public isDetailsFullscreen(): boolean {
    return this._displayOption.value === this.DETAILS_FULLSCREEN;
  }
}
