<div class="organisation-add-to-user">
  <h2 mat-dialog-title>{{'modules.organisation.organisationAddToUser.activateUser'|translate}}</h2>
  <mat-dialog-content>
    <form [formGroup]="organisationAddToUserForm" novalidate>
      <div *ngFor="let user of userIds.controls; let i=index">
        <mat-checkbox color="primary" [formControl]="user">
          {{users[i].name}}<span *ngIf="users[i].firstName">, {{users[i].firstName}}</span>, {{users[i].email}}
        </mat-checkbox>
      </div>
      <mat-error *ngIf="userIds.hasError('required')">
        {{'shared.validation.required'|translate:{value: 'general.user'|translate} }}
      </mat-error>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="column">
    <div class="error-container" fxLayout="column">
      <div *ngIf="userIds.hasError(errorTypes.NONE_SELECTED)" class="error-line" >
        {{'modules.organisation.organisationAddToUser.errorNoneSelected'|translate}}
      </div>
    </div>

    <div fxLayout="row" fxFlexAlign="end" fxLayoutAlign="end center">
      <button mat-raised-button
        *ngIf="!loading && isSelectMeButtonVisible"
        (click)="selectMe()"
        [disabled]="isSelectMeButtonDisbled | async">
        {{'modules.organisation.organisationAddToUser.selectMe'|translate}}
      </button>
      <button mat-raised-button *ngIf="!loading" (click)="changeSelectedStatus()">
        {{selectAllCheckboxes ? ('general.selectAll'|translate) : ('general.deselectAll'|translate) }}
      </button>
      <button mat-raised-button *ngIf="!loading" [mat-dialog-close]="true">
        {{'general.buttons.cancel'|translate}}
      </button>
      <button mat-raised-button
        *ngIf="!loading"
        bhTimeoutSaveButton
        color="primary"
        (throttledClick)="save()"
        [disabled]="!isValid()">
        {{'general.buttons.save'|translate}}
      </button>
      <mat-spinner fxLayout="row" fxLayoutAlign="end center"
        [strokeWidth]="3" [diameter]="25" *ngIf="loading">
      </mat-spinner>
    </div>
  </mat-dialog-actions>
</div>
