import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { DisplayService } from '../../../../../shared/display.service';
import { KeycloakService } from '../../../../../core/keycloak';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TransportListDatasource } from '../../../shared/services/transport-list.datasource';
import { TransportView } from '../../../contracts/transport/transport-view.interface';
import { PageType } from '../../../../../shared/enums/page-type.enum';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteTransportTaskCommand } from 'app/modules/transportation/contracts/transport/delete-transport-task.command';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { TransportTaskChecker } from 'app/modules/transportation/shared/services/transport-task-checker.service';
import { TransportStatePipe } from 'app/modules/transportation/shared/pipes/transport-state.pipe';
import { MenuOptionConfigs } from 'app/modules/transportation/contracts/transport/menu-option-configs.class';
import { delay, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TransportationTasksAddEditComponent } from '../../transportation-tasks-add-edit/transportation-tasks-add-edit.component';
import { ifDialogYes } from 'app/shared/utils';
import { TransportDialogEventType } from 'app/modules/transportation/shared/enums/transport-dialog-event-type.enum';
import { environment } from 'environments/environment';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';


@UntilDestroy()
@Component({
  selector: 'bh-transport-view',
  templateUrl: './transport-view.component.html',
  styleUrls: ['./transport-view.component.scss']
})
export class TransportViewComponent extends RoleAuthorityGuardsComponent implements OnInit {
  public transport: TransportView;
  public readonly pageType = PageType;
  public showMenu = false;
  public menuOptions: MenuOptionConfigs[] = [];

  constructor(public displayService: DisplayService,
              protected authService: KeycloakService,
              private transportDataSource: TransportListDatasource,
              private dialog: MatDialog,
              private languageService: LanguageService,
              private transportStatePipe: TransportStatePipe
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.initListener();
  }

  private initListener(): void {
    this.currentTransportListener();
  }

  private currentTransportListener(): void {
    this.transportDataSource.currentTransport
    .pipe(untilDestroyed(this))
    .subscribe((currentTransport: TransportView) => {
      this.transport = currentTransport;
      this.updateMenuConfigs();
    });
  }

  private updateMenuConfigs(): void {
    this.menuOptions = [];
    if (this.transport) {
      this.menuOptions = [
        this.getMenuOptionConfigsEdit(),
        this.getMenuOptionConfigsCopy(),
        this.getMenuOptionConfigsDelete(),
      ];
    }
    this.updateMenuVisability();
  }

  private getMenuOptionConfigsDelete(): MenuOptionConfigs {
    const isDeletable = TransportTaskChecker.isDeletable(this.transport);
    return {
      show: this.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION),
      disabled: !isDeletable,
      tooltipForDisabled: isDeletable ? null : this.translate(
        'modules.transportation.transportDetailView.cannotBeDeleted',
        { value: this.transportStatePipe.transform(this.transport.state) }),
      onClick: () => this.deleteTransport(),
      label: this.translate('general.buttons.delete'),
      matIcon: 'delete_forever'
    };
  }

  private getMenuOptionConfigsEdit(): MenuOptionConfigs {
    const isEditable = this.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION)
      || (this.hasAuthority(Authorities.TRANSPORT_TASK_MANAGE) && TransportTaskChecker.isEditableForManageRole(this.transport));
    return {
      show: this.hasAnyAuthority([Authorities.TRANSPORT_TASK_MANAGE, Authorities.TRANSPORT_TASK_DISPOSITION]),
      disabled: !isEditable,
      tooltipForDisabled: isEditable ? null : this.translate('modules.transportation.transportDetailView.cannotBeEdited'),
      onClick: () => this.editTransport(),
      label: this.translate('general.buttons.edit'),
      matIcon: 'edit'
    };
  }

  private getMenuOptionConfigsCopy(): MenuOptionConfigs {
    return {
      show: this.hasAnyAuthority([Authorities.TRANSPORT_TASK_MANAGE, Authorities.TRANSPORT_TASK_DISPOSITION]),
      disabled: false,
      onClick: () => this.copyTransport(),
      label: this.translate('general.buttons.copy'),
      faIcon: faCopy
    };
  }

  private updateMenuVisability(): void {
    this.showMenu = this.transport
      ? this.menuOptions.some(({ show }) => show)
      : false;
  }

  private deleteTransport(): void {
    this.confirmationDialog(
      this.translate('modules.transportation.transportDetailView.deleteMessage', { value: this.transport.title }))
    .pipe(ifDialogYes, untilDestroyed(this))
    .subscribe(() => this.transportDataSource.deleteTransport(new DeleteTransportTaskCommand(this.transport.transportId)));
  }

  private editTransport(): void {
    if (TransportTaskChecker.isDraftOrReturned(this.transport)) {
      this.openEditDialog();
      return;
    }

    this.confirmationDialog(this.translate(
      'modules.transportation.transportDetailView.warningAboutStateBeforeEdit',
      { value: this.transportStatePipe.transform(this.transport.state) }))
    .pipe(ifDialogYes, untilDestroyed(this))
    .subscribe(() => {
      this.openEditDialog();
    });
  }

  private openEditDialog(): void {
    this.dialog.open(
      TransportationTasksAddEditComponent,
      { disableClose: true, data: this.transport })
    .afterClosed()
    .pipe(
      filter(({ status }) => status === TransportDialogEventType.EDIT_SUCCEEDED),
      delay(environment.DELAY_SHORT),
      untilDestroyed(this))
    .subscribe(() => this.transportDataSource.updateCurrentAndListing());
  }

  private copyTransport(): void {
    this.transportDataSource.copyTransport(this.transport)
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.transportDataSource.updateListing();
      this.transportDataSource.selectCurrentTransportOrDefault();
    });
  }

  private confirmationDialog(message: string): Observable<dialogResults> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = message;
    return dialogRef.afterClosed();
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
