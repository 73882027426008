
<div *ngIf="editMode; else tmpView" class="edit-mode-container">
  <bh-additional-field
    [field]="field"
    [language]="language"
    [formControl]="control">
  </bh-additional-field>
  <bh-icon icon="delete" color="primary" (click)="onDelete.next($event)"></bh-icon>
</div>

<ng-template #tmpView>
  <bh-additional-field-view [field]="field" class="field-view"></bh-additional-field-view>
</ng-template>
