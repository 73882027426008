import { environment } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../../userrole/shared/users.service';
import { SearchUser } from '../../../userrole/contract/search-user.interface';
import { AddOrganisationToUserCommand } from '../../../userrole/contract/add-organisation-to-user-command';
import { SearchUserCustom } from '../../../userrole/contract/search-user-custom.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { KeycloakService } from '../../../../core/keycloak';
import { delay, map } from 'rxjs/operators';
import { CreateOrganisationCommand } from '../../contract/create-organisation-command';
import { OrganisationsService } from '../../shared/organisations.service';
import { FormArrayValidator } from 'app/shared/custom-validators/form-array.validator';
import { FormArrayValidatorErrorType } from 'app/shared/components/draggable-list/form-array-validator-error-type.enum';
import { PrivilegedRole } from 'app/shared/enums/privileged-roles.enum';
import { merge, Observable, of } from 'rxjs';

@Component({
  selector: 'bh-organisation-add-to-user',
  templateUrl: './organisation-add-to-user.component.html',
  styleUrls: ['./organisation-add-to-user.component.scss'],
})
export class OrganisationAddToUserComponent implements OnInit {
  public readonly errorTypes = FormArrayValidatorErrorType;
  public organisationAddToUserForm: UntypedFormGroup;
  public createOrganisationCommand: CreateOrganisationCommand;
  public customerId: string;
  public dialogRef: MatDialogRef<OrganisationAddToUserComponent>;
  public users: SearchUser[];
  public authUserId: string;
  public selectAllCheckboxes = false;
  public loading = false;
  public isSelectMeButtonVisible = false;
  public isSelectMeButtonDisbled = new Observable<boolean>();
  private currentUserIndex = -1;

  constructor(private formBuilder: UntypedFormBuilder,
              private usersService: UsersService,
              private organisationsService: OrganisationsService,
              private router: Router,
              private keycloackService: KeycloakService) {
  }

  get userIds(): UntypedFormArray {
    return this.organisationAddToUserForm.get('userIds') as UntypedFormArray;
  }

  public ngOnInit() {
    this.buildForm();
    this.buildUserIds();
    this.getUserId();
    this.setSettings();
  }

  public save() {
    this.loading = true;
    if (this.isValid()) {
      let formValue = this.organisationAddToUserForm.value;
      this.organisationsService.addOrganisation(this.createOrganisationCommand)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe(data => {
          this.addOrganizationToUsers(formValue, data);
        });
    }
  }

  public isValid(): boolean {
    return this.organisationAddToUserForm.valid;
  }

  public selectMe(): void {
    if (this.currentUserIndex > -1) {
      this.userIds.at(this.currentUserIndex).setValue(true);
    }
  }

  private setSettings(): void {
    this.currentUserIndex = this.users.findIndex(user => user.userId === this.authUserId);
    this.isSelectMeButtonVisible = !this.keycloackService.isInRole(PrivilegedRole.Flottenadmin)
      && this.currentUserIndex > -1;

    this.isSelectMeButtonDisbled = merge(
      of(true),
      this.userIds.valueChanges.pipe(map((values: boolean[]) => values[this.currentUserIndex]))
    );
  }

  private addOrganizationToUsers(value, organisationId) {
    let cmd = new AddOrganisationToUserCommand();
    cmd.customerId = value.customerId;
    cmd.organisationId = organisationId;
    cmd.userIds = [];
    value.userIds.map((isSelected, i) => {
      if (isSelected) {
        cmd.userIds.push(this.users[i].userId);
      }
    });

    this.usersService.addOrganisationToUsers(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(
        () => {
          KeycloakService.refreshTokenForced().then(res => {
            this.router.navigate(['organisations/list']);
            this.dialogRef.close();
          });
          this.loading = false;
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          console.log('add organisation to users error: ', error);
        });
  }

  private buildForm() {
    this.organisationAddToUserForm = this.formBuilder.group({
      customerId: [this.customerId, [<any>Validators.required]],
      userIds: this.formBuilder.array([], [FormArrayValidator.atLeastOneIsSelected]),
    });
  }

  private buildUserIds() {
    this.users.map(user => {
      let customizedUser: SearchUserCustom = <SearchUserCustom>user;
      customizedUser.isSelected = true;
      this.userIds.push(new UntypedFormControl(customizedUser.isSelected));
    });
  }

  private getUserId() {
    this.authUserId = this.keycloackService.getUserUserId();
  }

  public changeSelectedStatus(): void {
    this.userIds.controls.forEach(control => {
      control.setValue(this.selectAllCheckboxes);
    });
    this.selectAllCheckboxes = !this.selectAllCheckboxes;
  }

}
