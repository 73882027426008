import { AbstractControl } from '@angular/forms';
import { IntervalsDurationEnum } from '../intervals-duration.enum';

export function highWarningThresholdValidator(intervalControlName: string, intervalDurationControlName: string) {
  return (control: AbstractControl): { [key: string]: any } => {
    let parent = control.parent;
    const interval = parent.get(intervalControlName).value;
    const intervalDuration = parent.get(intervalDurationControlName).value;

    let invalid = false;
    if (intervalDuration === IntervalsDurationEnum.DAYS) {
      if (interval === 1 && control.value !== 0) {
        invalid = true;
      } else if (interval !== 1 && control.value === 0) {
        invalid = true;
      }
    } else {
      invalid = control.value === 0;
    }
    return invalid ? {invalidUpper: invalid} : null;
  };
}
