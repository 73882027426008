<div class="list-view-main__tabs" fxFlex>
  <mat-tab-group mat-stretch-tabs
    #detailTabGroup
    animationDuration="0ms"
    (selectedTabChange)="updateTab($event)">

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon [icon]="faInfoCircle"></fa-icon>
        {{'general.generalData' | translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-maintenance-task-completed-view-general [task]="task"></bh-maintenance-task-completed-view-general>
      </div>
    </mat-tab>

    <mat-tab #historyTab *ngIf="task?.category !== reminderCategory">
      <ng-template mat-tab-label>
        <fa-icon [icon]="faHistory"></fa-icon>
        {{'general.history'|translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-maintenance-task-completed-view-history
          [isActive]="historyTab.isActive"
          [task]="task">
        </bh-maintenance-task-completed-view-history>
      </div>
    </mat-tab>

    <mat-tab #attachmentTab *ngIf="task?.category !== reminderCategory">
      <ng-template mat-tab-label>
        <mat-icon fontSet="icon-attachment_outline"></mat-icon>
        {{'general.attachment.pl' | translate}}
      </ng-template>
      <div class="list-view-main__tab-content">
        <bh-maintenance-task-completed-view-attachment
          [isActive]="attachmentTab.isActive"
          [task]="task">
        </bh-maintenance-task-completed-view-attachment>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>
