import { Directive, ElementRef, AfterViewInit, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'input[bhAutofocus]'
})

export class AutofocusDirective implements OnInit, AfterViewInit {
  @Input('focusAfterViewInit') focusAfterViewInit = true;
  @Input('activateFocus') set activateFocus(activate: boolean) {
    this.focusHandle(activate);
  }
  private nativeElement: any;

  constructor(element: ElementRef) {
    this.nativeElement = element && element.nativeElement;
  }

  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    if (this.focusAfterViewInit) {
      this.focusHandle(true);
    }
  }

  private focusHandle(activate: boolean): void {
    if (!this.nativeElement) {
      return;
    }

    if (activate) {
      this.nativeElement.focus();
    } else {
      this.nativeElement.blur();
    }
  }

}
