import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { CanActivateLanguage } from './core/guards/can-activate-language.guard';
import { ContentComponent } from './shared/components/content-component/content.component';
import { CanActivateMobileWebApp } from './core/guards/can-activate-mobile-web-app.guard';

// Route Configuration
const routes: Routes = [
  {
    path: 'en',
    canActivate: [CanActivateLanguage],
    children: [{ path: '**', component: ContentComponent }]
  },
  {
    path: 'de',
    canActivate: [CanActivateLanguage],
    children: [{ path: '**', component: ContentComponent }]
  },
  {
    path: 'dev',
    canActivate: [CanActivateLanguage],
    children: [{ path: '**', component: ContentComponent }]
  },
  {
    path: 'cs',
    canActivate: [CanActivateLanguage],
    children: [{ path: '**', component: ContentComponent }]
  },
  {
    path: 'nl',
    canActivate: [CanActivateLanguage],
    children: [{ path: '**', component: ContentComponent }]
  },
  {
    path: 'fr',
    canActivate: [CanActivateLanguage],
    children: [{ path: '**', component: ContentComponent }]
  },
  {
    path: 'dashboard',
    canActivate: [CanActivateMobileWebApp],
    component: DashboardComponent
  },
  {
    path: 'landingpage',
    loadChildren: () => import('./modules/landingpage/landingpage.module').then(m => m.LandingpageModule)
  },
  {
    path: 'equipment',
    loadChildren: () => import('./modules/equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: 'organisations',
    loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/userrole/userrole.module').then(m => m.UserRoleModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('./modules/userrole/userrole.module').then(m => m.UserRoleModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/connector/connector.module').then(m => m.ConnectorModule)
  },
  {
    path: 'disposition',
    loadChildren: () => import('./modules/disposition/disposition.module').then(m => m.DispositionModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./modules/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'transportation',
    loadChildren: () => import('./modules/transportation/transportation.module').then(m => m.TransportationModule)
  },
  {
    path: 'field-management',
    loadChildren: () => import('./modules/field-management/field-management.module').then(m => m.FieldManagementModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule)
  },
  { path: '', redirectTo: 'landingpage', pathMatch: 'full' },
  { path: '**', redirectTo: 'landingpage', pathMatch: 'full' }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
});
