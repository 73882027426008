import { GridsterModule } from 'angular-gridster2';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicModule } from 'ng-dynamic-component';
import { WidgetModule } from './widgets/widget.module';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddWidgetDialogComponent } from './add-widget-dialog/add-widget-dialog.component';
import { DashboardStore } from './dashboard/dashboard.store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [DashboardComponent, AddWidgetDialogComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    DynamicModule,
    WidgetModule,
    GridsterModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
  ],
  exports: [
    DashboardComponent,
  ],
  providers: [DashboardStore]
})
export class DashboardModule {
}

