import { TransportAuditResponse, TransportHistoryEntry, TransportChange } from '../../../../../contracts/transport/transport-audit-response.interface';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TransportService } from 'app/modules/transportation/shared/services/transport.service';
import { TransportHistoryFieldType } from 'app/modules/transportation/shared/enums/transport-history-field-type.enum';
import { TransportHistoryChangedValue } from 'app/modules/transportation/contracts/transport/transport-history-changed-value.interface';
import { UserStatus } from 'app/shared/enums/user-status.enum';
import { TransportHistoryColumn } from 'app/modules/transportation/shared/enums/transport-history-column.enum';
import { TransportListDatasource } from 'app/modules/transportation/shared/services/transport-list.datasource';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SubmitterInfo } from 'app/shared/contract/submitter-info.interface';


enum HistoryDisplayedColumns {
  DATE = 'DATE',
  USER = 'USER',
  FIELD = 'FIELD',
  OLD = 'OLD',
  NEW = 'NEW',
  ALTERNATE_COLOR = 'ALTERNATE_COLOR'
}

interface HistoryTableRow {
  [HistoryDisplayedColumns.DATE]: string;
  [HistoryDisplayedColumns.USER]: SubmitterInfo;
  [HistoryDisplayedColumns.FIELD]: string;
  [HistoryDisplayedColumns.OLD]: TransportHistoryChangedValue;
  [HistoryDisplayedColumns.NEW]: TransportHistoryChangedValue;
  [HistoryDisplayedColumns.ALTERNATE_COLOR]: boolean;
}

@UntilDestroy()
@Component({
  selector: 'bh-transport-view-history',
  templateUrl: './transport-view-history.component.html',
  styleUrls: ['./transport-view-history.component.scss']
})
export class TransportViewHistoryComponent implements OnInit, OnChanges {
  @Input() public transportId: string;
  @Input() public isActive: boolean;
  public historyTable: HistoryTableRow[] = [];
  public readonly displayedColumns = [
    HistoryDisplayedColumns.DATE,
    HistoryDisplayedColumns.USER,
    HistoryDisplayedColumns.FIELD,
    HistoryDisplayedColumns.OLD,
    HistoryDisplayedColumns.NEW
  ];
  public readonly emptyValue = '—';
  public readonly columnNames = HistoryDisplayedColumns;
  public readonly TransportHistoryFieldType = TransportHistoryFieldType;
  public readonly userStatus = UserStatus;

  constructor(
    private transportService: TransportService,
    private transportDatasource: TransportListDatasource
  ) { }

  public ngOnInit(): void {
    this.transportUpdateListener();
  }

  public ngOnChanges(): void {
    if (this.transportId && this.isActive) {
      this.getTransportHistory();
    }
  }

  public isScrollableCell(field: string): boolean {
    return field === TransportHistoryColumn.DESCRIPTION
      || field === TransportHistoryColumn.TITLE;
  }

  private transportUpdateListener(): void {
    this.transportDatasource.updatedTransportId
    .pipe(
      filter(transportId => transportId === this.transportId && this.isActive),
      delay(environment.DELAY_SHORTEST),
      untilDestroyed(this))
    .subscribe(() => this.getTransportHistory());
  }

  private getTransportHistory(): void {
    this.transportService.getAuditData(this.transportId)
    .subscribe(auditDataResponse => this.createHistoryTable(auditDataResponse));
  }

  private createHistoryTable(auditDataResponse: TransportAuditResponse): void {
    this.historyTable = auditDataResponse?.history
      ? this.convertTransportAuditResponseToTable(auditDataResponse)
      : [];
  }

  private convertTransportAuditResponseToTable(auditDataResponse: TransportAuditResponse): HistoryTableRow[] {
    return auditDataResponse.history.reduce(
      (result: HistoryTableRow[], historyEntry: TransportHistoryEntry, index: number) => {
        return [
          ...result,
          ...this.convertTransportHistoryToTable(historyEntry, index % 2 === 1)
        ];
      },
      []);
  }

  private convertTransportHistoryToTable(historyEntry: TransportHistoryEntry, alternateBgColor: boolean): HistoryTableRow[] {
    return historyEntry.transportChanges.reduce((result: HistoryTableRow[], change: TransportChange, index: number) => {
      return [
        ...result,
        {
          [HistoryDisplayedColumns.DATE]: 0 === index ? historyEntry.changeDate : null,
          [HistoryDisplayedColumns.USER]: 0 === index ? historyEntry.submitterInfo : null,
          [HistoryDisplayedColumns.FIELD]: change.field,
          [HistoryDisplayedColumns.OLD]: {
            type: change.type,
            value: change.oldValue
          },
          [HistoryDisplayedColumns.NEW]: {
            type: change.type,
            value: change.newValue
          },
          [HistoryDisplayedColumns.ALTERNATE_COLOR]: alternateBgColor,
        }
      ];
    }, []);
  }
}
