<mat-menu class="mat-menu-dropdown">
  <button *ngIf="noStatusItemText"
          mat-menu-item (click)="onNoStatusButtonClick()">
    {{ noStatusItemText }}
  </button>

  <ng-container *ngFor="let category of categories">
    <p class="mat-menu-item-header">{{category | equipmentStatusCategoryDisplay}}</p>
    <div *ngFor="let status of statusesByCategory(category)" [matTooltip]="getStatusTooltip(status)">
      <button mat-menu-item
              [disabled]="shouldBeDisabled(status)"
              (click)="onStatusButtonClick(status)">
        <bh-equipment-status-badge [_status]="status"></bh-equipment-status-badge>
      </button>
    </div>
  </ng-container>
</mat-menu>
