import { Component, OnDestroy, OnInit, Sanitizer} from '@angular/core';
import { ImageLink } from '../../contract/image-link.interface';
import { MatDialogRef } from '@angular/material/dialog';
import {
  WindowEventEmitterEvent,
  WindowEventService
} from '../../../../shared/services/window-event-service';

enum ScaleDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}

@Component({
  selector: 'bh-mobile-image-dialog',
  templateUrl: './equipment-mobile-image-dialog.component.html',
  styleUrls: ['./equipment-mobile-image-dialog.component.scss']
})
export class EquipmentMobileImageDialogComponent implements OnInit, OnDestroy {

  public scaleDirection: ScaleDirection;

  public image: ImageLink;
  public ScaleDirections = ScaleDirection;


  constructor(public dialogRef: MatDialogRef<EquipmentMobileImageDialogComponent>,
              private sanitizer: Sanitizer,
              private windowEventService: WindowEventService) {
  }

  public ngOnInit(): void {
    this.windowEventService.getObservable().subscribe((windowEventEmitterEvent: WindowEventEmitterEvent) => {
      if (windowEventEmitterEvent.eventType === 'onresize') {
        this.windowResized();
      }
    });
    this.windowResized();
  }

  private windowResized(): void {
    if (window.innerHeight > window.innerWidth) {
      this.scaleDirection = ScaleDirection.HORIZONTAL;
    } else {
      this.scaleDirection = ScaleDirection.VERTICAL;
    }
  }

  public ngOnDestroy(): void {
    window.removeEventListener('onresize', this.windowResized.bind(this));
  }
}
