import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PartnersService } from '../../../organisation/shared/partners.service'
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { CreatePartnerAdminCommand } from '../../contract/create-partner-admin-command';
import { KeycloakService } from '../../../../core/keycloak';
import { UsersStore } from '../../shared/users.store';
import { HttpErrorResponse } from '@angular/common/http';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ViewPartner } from '../../../organisation/contract/view-partner.interface';
import { UserAddBaseComponent } from '../user-add/user-add-base.component';
import { RouterHistory } from '../../../../shared/router-history';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'bh-user-add-partneradmin',
  templateUrl: './user-add-partneradmin.component.html',
  styleUrls: ['./user-add-partneradmin.component.scss']
})
export class UserAddPartneradminComponent extends UserAddBaseComponent implements OnInit, OnDestroy {
  userAddForm: UntypedFormGroup;
  partnerName: string;
  tomorrow: Date = new Date(moment().add(1, 'days').toString());

  private partnerId: string;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private formBuilder: UntypedFormBuilder,
              protected usersStore: UsersStore,
              private partnersService: PartnersService) {
    super(router, route, authService, usersStore, routerHistory);
  }

  ngOnInit(): void {
    this.partnerId = this.route.snapshot.queryParams['partnerId'];
    this.buildForm();
    this.setEmailInUseValidator();
    if (this.partnerId) {
      this.setFromPartnerId();
      this.getPartner(this.partnerId);
    }
  }

  ngOnDestroy(): void {
  }

  public save(): void {
    if (this.isValid()) {
      let formValue = this.userAddForm.getRawValue();
      let validUntil = formValue.validUntil ? formValue.validUntil.toISOString() : null;
      let cmd: CreatePartnerAdminCommand = new CreatePartnerAdminCommand();

      cmd.email = formValue.email;
      cmd.firstName = formValue.firstName;
      cmd.name = formValue.name;
      cmd.mobileNumber = formValue.mobileNumber;
      cmd.partnerId = formValue.partnerId;
      cmd.validUntil = validUntil;

      this.usersStore.addPartnerAdmin(cmd).pipe(untilDestroyed(this)).subscribe(
        () => {
          this.router.navigate(['/users/list']);
        },
        (error: HttpErrorResponse) => {
          console.log('save user error: ', error);
        }
      );
    }
  }

  public isValid(): boolean {
    return this.userAddForm.valid;
  }

  resetForm(): void {
    this.userAddForm.reset();
    this.setFromPartnerId();
  }

  private getPartner(partnerId: string): void {
    this.partnersService.getPartner(partnerId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewPartner) => {
        this.partnerName = res.partnerName;
      }, error => {
        console.log('error on get partner: ', error);
      });
  }

  private buildForm(): void {
    this.userAddForm = this.formBuilder.group({
      email: ['', [<any>Validators.required, emailValidator()]],
      name: ['', [<any>Validators.required]],
      partnerId: ['', [<any>Validators.required]],
      firstName: '',
      mobileNumber: '',
      validUntil: ''
    });
  }

  private setFromPartnerId(): void {
    this.userAddForm.patchValue({
      partnerId: this.partnerId
    });
  }

}
