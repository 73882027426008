<div fxFill fxLayout="row" fxLayoutAlign="start stretch">
  <mat-card class="list-view-main-container" fxFlex="100">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">

      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div class="list-view-main__left-header" fxFlex="70px" fxLayout="row" fxLayoutAlign="end start">
          <div class="open-transfer-cart" fxLayout="column" fxFlex="70px" fxLayoutAlign="center center"
               [ngClass]="{'empty-cart': transferCartStore.transferCartEmpty()}"
               (click)="openTransferCart()">
            <fa-icon [icon]="faClipBoardList"></fa-icon>
            <span>{{transferCartStore.transferCartLength | async}}</span>
          </div>
        </div>
        <div class="list-view-main__left-body scrollable-table-container"
          fxFlex
          fxLayout="row"
          fxLayoutGap="15px"
          fxLayoutAlign="start stretch">

          <mat-table #table
            fxFlex
            [ngClass]="{'hide-header': !displayService.isTableFullscreen()}"
            multiTemplateDataRows
            class="pagination-data-table left-body-list scrollable-table exclude-initial-fixed-basis-size"
            [dataSource]="equipmentTransferStore"
            matSort
            (matSortChange)="sortData($event)"
            [matSortActive]="defaultSort.active"
            [matSortDirection]="defaultSort.direction"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.filteredColumnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

            <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of selectedColumns">

              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <span>{{ column.valueCallback(row) }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <bh-equipment-status-badge [_status]="row.status"></bh-equipment-status-badge>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.IMAGE">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                    <bh-thumbnail *ngIf="row.thumbnailKey"
                      [key]="row.thumbnailKey"
                      [size]="ThumbnailSize.XS">
                    </bh-thumbnail>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  class="sub-table-cell equipment-list-column"
                  [innerHTML]="column.valueCallback(row) | date: 'dd.MM.yyyy'">
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.CURRENT_LOCATION_NUMBER">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-location-column-data
                    [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_NUMBER"
                    [value]="row.currentLocation?.number"
                    [locationType]="row.currentLocation?.type"
                    [locationId]="row.currentLocation?.id"
                    [isAllowedToSeeLocation]="(row.isCurrentLocationAvailableForCurrentUser && row.currentLocation?.number && row.isAvailableForCurrentUser)"
                    [isAvailableForCurrentUser]="row.isAvailableForCurrentUser">
                  </bh-location-column-data>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.CURRENT_LOCATION_NAME">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-location-column-data
                    [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_NAME"
                    [value]="row.currentLocation?.name"
                    [locationType]="row.currentLocation?.type"
                    [locationId]="row.currentLocation?.id"
                    [isAllowedToSeeLocation]="(row.isCurrentLocationAvailableForCurrentUser && row.currentLocation?.name && row.isAvailableForCurrentUser)"
                    [isAvailableForCurrentUser]="row.isAvailableForCurrentUser">
                  </bh-location-column-data>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-location-column-data
                    [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER"
                    [value]="row.currentLocation?.costCenter"
                    [locationType]="row.currentLocation?.type"
                    [locationId]="row.currentLocation?.id"
                    [isAllowedToSeeLocation]="(row.isCurrentLocationAvailableForCurrentUser && row.currentLocation?.costCenter && row.isAvailableForCurrentUser)"
                    [isAvailableForCurrentUser]="row.isAvailableForCurrentUser">
                  </bh-location-column-data>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <mat-chip-set>
                    <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                    <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                              matTooltipClass="mat-tooltip-multiline"
                              [disabled]="true" [matTooltip]="getRemainingLabels(row).join('\n')">
                      {{ '+' + getRemainingLabels(row).length }}
                    </mat-chip>
                  </mat-chip-set>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.INTO_TRANSFER_CART">
                <mat-header-cell class="into-transfer-cart header-border-right" *cdkHeaderCellDef>{{column.title}}</mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="into-transfer-cart">
                  <mat-checkbox color="primary"
                                (change)="transferCartStore.switchEquipmentAmountOpened(row)"
                                [checked]="transferCartStore.isAmountInTransferCart(row.amountId)"
                                [disabled]="!row.isAvailableForCurrentUser"
                                [indeterminate]="transferCartStore.isEquipmentAmountSubequipmentsInTransferCart(row)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.EQUIPMENT_AMOUNT_AVAILABLE">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell
                  class="equipment-list-column">
                  <fa-icon [ngClass]="row.amountAvailable > 0 ? 'green-icon' : 'red-icon'"
                           [icon]="row.amountAvailable > 0 ? faCheckCircle : faTimesCircle">
                  </fa-icon>
                </mat-cell>
              </ng-container>
            </ng-container>

            <ng-container [cdkColumnDef]="COLUMN_SUB_EQUIPMENT">
              <mat-header-cell *cdkHeaderCellDef class="header-border-right"></mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <div *ngIf="subequipmentsExist(row)"
                     data-osp-test="subequipment-header-column"
                     class="sub-arrow"
                     (click)="selectEquipment(row)">
                  <span class="badge sub-equipment-number-badge"
                        matTooltip="{{'modules.equipment.equipmentList.numberOfSubequipments'|translate}}: {{row.subEquipmentList.length}}">
                    {{row.subEquipmentList.length}}
                  </span>
                  <fa-icon [icon]="resolveArrowIcon(row)"></fa-icon>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="subTable">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let element" class="expandable-cell"
                        [@rowsExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
                <div class="expandable-div">
                  <mat-table class="sub-table pagination-data-table left-body-list"
                             [dataSource]="element.subEquipmentList">

                    <ng-container *ngFor="let column of selectedColumns; let i = index"
                                  [cdkColumnDef]="column.cdkColumnDef">

                      <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <span>{{ column.valueCallback(row) }}</span>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell>
                          <bh-equipment-status-badge [_status]="row.status"></bh-equipment-status-badge>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.IMAGE">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <bh-thumbnail *ngIf="row.thumbnailKey"
                            [key]="row.thumbnailKey"
                            [size]="ThumbnailSize.XS">
                          </bh-thumbnail>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column"
                          [innerHTML]="column.valueCallback(row) | date: 'dd.MM.yyyy'">
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.CURRENT_LOCATION_NUMBER">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="equipment-list-column">
                          <bh-location-column-data
                            [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_NUMBER"
                            [value]="row.currentLocation?.number"
                            [locationType]="row.currentLocation?.type"
                            [locationId]="row.currentLocation?.id"
                            [isAllowedToSeeLocation]="(row.isCurrentLocationAvailableForCurrentUser && row.currentLocation?.number && row.isAvailableForCurrentUser)"
                            [isAvailableForCurrentUser]="row.isAvailableForCurrentUser">
                          </bh-location-column-data>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.CURRENT_LOCATION_NAME">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="equipment-list-column">
                          <bh-location-column-data
                            [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_NAME"
                            [value]="row.currentLocation?.name"
                            [locationType]="row.currentLocation?.type"
                            [locationId]="row.currentLocation?.id"
                            [isAllowedToSeeLocation]="(row.isCurrentLocationAvailableForCurrentUser && row.currentLocation?.name && row.isAvailableForCurrentUser)"
                            [isAvailableForCurrentUser]="row.isAvailableForCurrentUser">
                          </bh-location-column-data>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="equipment-list-column">
                          <bh-location-column-data
                            [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER"
                            [value]="row.currentLocation?.costCenter"
                            [locationType]="row.currentLocation?.type"
                            [locationId]="row.currentLocation?.id"
                            [isAllowedToSeeLocation]="(row.isCurrentLocationAvailableForCurrentUser && row.currentLocation?.costCenter && row.isAvailableForCurrentUser)"
                            [isAvailableForCurrentUser]="row.isAvailableForCurrentUser">
                          </bh-location-column-data>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <mat-chip-set>
                            <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                            <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                                      matTooltipClass="mat-tooltip-multiline"
                                      [matTooltip]="getRemainingLabels(row).join('\n')"
                                      [disabled]="true">
                              {{ '+' + getRemainingLabels(row).length }}
                            </mat-chip>
                          </mat-chip-set>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.INTO_TRANSFER_CART">
                        <mat-header-cell class="into-transfer-cart header-border-right" *cdkHeaderCellDef>
                          {{'modules.disposition.base.inTransferBasket'|translate}}
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                                  bhFirstColumn [orderNumber]="i"
                                  class="into-transfer-cart">
                          <mat-checkbox color="primary"
                                        (change)="transferCartStore.switchEquipmentAmountOpened(row)"
                                        [disabled]="!row.isAvailableForCurrentUser"
                                        [checked]="transferCartStore.isAmountInTransferCart(row.amountId)">
                          </mat-checkbox>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === COLUMN_TYPE.EQUIPMENT_AMOUNT_AVAILABLE">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          {{'modules.disposition.base.inTransferBasket'|translate}}
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <fa-icon [ngClass]="row.amountAvailable > 0 ? 'green-icon' : 'red-icon'"
                                   [icon]="row.amountAvailable > 0 ? faCheckCircle : faTimesCircle">
                          </fa-icon>
                        </mat-cell>
                      </ng-container>

                    </ng-container>

                    <ng-container [cdkColumnDef]="COLUMN_SUB_EQUIPMENT">
                      <mat-header-cell *cdkHeaderCellDef class="header-border-right"></mat-header-cell>
                      <mat-cell *cdkCellDef="let row"></mat-cell>
                    </ng-container>

                    <mat-row *cdkRowDef="let row; columns: displayedColumns"
                             class="sub-table-row"
                             [ngClass]="{'not-available-for-user': !row.isAvailableForCurrentUser}"
                             routerLinkActive="left-body-item--active"
                             [matTooltip]="'general.labels.assignedToDifferentOrganisation'|translate"
                             [matTooltipDisabled]="row.isAvailableForCurrentUser">
                    </mat-row>

                  </mat-table>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row class="custom-header" *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns;"
                     [ngClass]="{'not-available-for-user': !row.isAvailableForCurrentUser}"
                     routerLinkActive="left-body-item--active"
                     [matTooltip]="'general.labels.assignedToDifferentOrganisation'|translate"
                     [matTooltipDisabled]="row.isAvailableForCurrentUser">
            </mat-row>
            <mat-row *cdkRowDef="let row; columns: ['subTable']" class="expandable-row"></mat-row>

          </mat-table>

        </div>

        <div class="list-view-main__left-body-paginator" fxLayout="row wrap">
          <div id="list-settings" fxFlex="80px">
            <button #configBtn mat-icon-button
                    class="rotatable"
                    (click)="openConfiguration()"
                    [matTooltip]="'general.labels.confColumnArrangement'|translate">
              <mat-icon class="rotatable">settings</mat-icon>
            </button>
          </div>
          <mat-paginator #paginator
                         fxFlex="0"
                         [length]="equipmentTransferStore.length | async"
                         [pageIndex]="equipmentTransferStore.pagination.index"
                         [pageSize]="equipmentTransferStore.pagination.size"
                         [pageSizeOptions]="[5, 25, 50, 100]"
                         (page)="onPaginateChange($event)">
          </mat-paginator>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>
