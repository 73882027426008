import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentStatusCategory } from '../../modules/equipment/contract/equipment-status-category.enum';

@Pipe({
  name: 'equipmentStatusCategoryDisplay',
})
export class EquipmentStatusCategoryPipe implements PipeTransform {

  constructor(protected languageService: LanguageService) {
  }

  public transform(value: string): string {
    switch (value) {
      case EquipmentStatusCategory.OPERATIONAL:
        return this.translate('shared.pipes.equipmentStatusCategory.operational');
      case EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS:
        return this.translate('shared.pipes.equipmentStatusCategory.operationalWithRestrictions');
      case EquipmentStatusCategory.NOT_OPERATIONAL:
        return this.translate('shared.pipes.equipmentStatusCategory.notOperational');
      case EquipmentStatusCategory.NOT_AVAILABLE:
        return this.translate('shared.pipes.equipmentStatusCategory.notAvailable');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
