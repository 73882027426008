import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { QUICK_FILTERS } from 'app/shared/contract/quick-filters';
import { TIME_RANGE_DATE_TYPES } from 'app/shared/contract/time-range-date-types';
import { ITimeRangeData } from 'app/shared/contract/time-range-data.interface';
import { ITimeRangeParams } from 'app/shared/contract/time-range-params.interface';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { environment } from 'environments/environment';
import { DateValidator } from 'app/shared/custom-validators/date.validator';
import { LanguageService } from 'app/shared/services/language.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { matDateFormatFactory } from 'app/shared/mat-date-format-factory';

@UntilDestroy()
@Component({
  selector: 'bh-time-range-filter',
  templateUrl: './time-range-filter.component.html',
  styleUrls: ['./time-range-filter.component.scss'],
  providers: [
    {provide: DateAdapter,  useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useFactory: matDateFormatFactory, deps: [LanguageService]},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRangeFilterComponent implements OnInit {

  @Input() public timeRangeParams: ITimeRangeParams;
  @Input() public quickFilterParam: string;
  @Input() public showDateTypeControl: boolean;
  @Output() public onTimeRangeData = new EventEmitter<ITimeRangeData>();

  @ViewChild('fromDateInputRef', { static: true }) fromDateInputRef: ElementRef;
  @ViewChild('toDateInputRef', { static: true }) toDateInputRef: ElementRef;

  public timeRangeForm: UntypedFormGroup;
  public timeRangeDateTypeControl: UntypedFormControl;
  public quickFilterControl: UntypedFormControl;

  public readonly QUICK_FILTERS = QUICK_FILTERS;
  public readonly TIME_RANGE_DATE_TYPES = TIME_RANGE_DATE_TYPES;

  private get fromDateMoment(): moment.Moment {
    return moment(this.timeRangeForm.value.fromDate);
  }

  private get toDateMoment(): moment.Moment {
    return moment(this.timeRangeForm.value.toDate);
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.buildForms();
    this.timeRangeChangeSubscription();
    this.dateTypeChangeSubscription();
    this.quickFilterChangeSubscription();
  }

  public setTimeRangeParams(timeRangeParams: ITimeRangeParams, quickParam: string): void {
    this.timeRangeDateTypeControl.patchValue(timeRangeParams.dateType);
    this.quickFilterControl.patchValue(quickParam);
  }

  private buildForms(): void {
    this.timeRangeForm = this.formBuilder.group({
      fromDate: [
        this.timeRangeParams.fromDate,
        [Validators.required, DateValidator.isValidDateOrEmpty(this.fromDateInputRef, this.languageService)],
      ],
      toDate: [
        this.timeRangeParams.toDate,
        [Validators.required, DateValidator.isValidDateOrEmpty(this.toDateInputRef, this.languageService)],
      ],
    });

    this.timeRangeDateTypeControl = new UntypedFormControl(this.timeRangeParams.dateType);
    this.quickFilterControl = new UntypedFormControl(this.quickFilterParam);
  }

  private timeRangeChangeSubscription(): void {
    this.timeRangeForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(environment.DELAY_SHORTEST))
      .subscribe(() => {
        if (!this.timeRangeForm.pristine) {
          this.quickFilterControl.patchValue('');
          this.timeRangeForm.markAsPristine();
        }

        if (this.hasStartOrEndDateInvalidError() && this.isValidTimeRange()) {
          this.removeStartAndEndDateInvalidError();
        }

        if (this.timeRangeForm.valid) {
          this.onTimeRangeData.emit(this.getTimeRangeParams());
        }
      });
  }

  private dateTypeChangeSubscription(): void {
    this.timeRangeDateTypeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.timeRangeForm.valid) {
          this.onTimeRangeData.emit(this.getTimeRangeParams());
        }
      });
  }

  private quickFilterChangeSubscription(): void {
    this.quickFilterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(quickFilterControl => {
        if (quickFilterControl) {
          this.setTimeRangeAccordingToQuickFilter(quickFilterControl);
        }
      });
  }

  private setTimeRangeAccordingToQuickFilter(quickFilter: string): void {
    let fromDate = moment();
    let toDate = moment();
    switch (quickFilter) {
      case QUICK_FILTERS.CURRENT_MONTH:
        fromDate.startOf('month');
        break;
      case QUICK_FILTERS.LAST_NINETY_DAYS:
        fromDate.subtract(90, 'day');
        break;
      case QUICK_FILTERS.LAST_THIRTY_DAYS:
        fromDate.subtract(30, 'day');
        break;
      case QUICK_FILTERS.PREVIOUS_MONTH:
        fromDate.subtract(1, 'month').startOf('month');
        toDate.subtract(1, 'month').endOf('month');
        break;
      }
    this.patchTimeRangeForm(fromDate, toDate);
  }

  private patchTimeRangeForm(fromDate: moment.Moment, toDate: moment.Moment): void {
    this.timeRangeForm.patchValue({
      fromDate: fromDate.startOf('day').toISOString(),
      toDate: toDate.endOf('day').toISOString(),
    });
  }

  private getTimeRangeParams(): ITimeRangeData {
    return {
      dateType: this.timeRangeDateTypeControl.value,
      fromDate: this.fromDateMoment.toISOString(),
      toDate: this.toDateMoment.toISOString(),
      quickFilterParam: this.quickFilterControl.value,
    }
  }

  private hasStartOrEndDateInvalidError(): boolean {
    return  this.timeRangeForm.controls.fromDate.hasError('matStartDateInvalid') ||
            this.timeRangeForm.controls.toDate.hasError('matEndDateInvalid');
  }

  private isValidTimeRange(): boolean {
    return this.fromDateMoment <= this.toDateMoment;
  }

  private removeStartAndEndDateInvalidError(): void {
    this.timeRangeForm.controls.fromDate.setErrors({'matStartDateInvalid': false});
    this.timeRangeForm.controls.toDate.setErrors({'matEndDateInvalid': false});
    this.patchTimeRangeForm(this.fromDateMoment, this.toDateMoment);
  }
}
