import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ViewAlarmRule } from '../../contract/view-alarm-rule.interface';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { CreateAlarmRuleCommand } from '../../contract/create-alarm-rule-command';
import { UpdateAlarmRuleCommand } from '../../contract/update-alarm-rule-command';
import { AcknowledgeAlarmCommand } from '../../contract/acknowledge-alarm-command';
import { environment } from '../../../../../environments/environment';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { Alarm } from 'app/modules/notifications/shared/interfaces/alarm.interface';
import { DeleteAlarmRuleCommand } from '../../contract/delete-alarm-rule-command';

@Injectable()
export class NotificationService {

  private urlBase = environment.APP_NOTIFICATION_SERVICE_BASE_URL + '/api/v1/notifications/alarms';
  private urlEquipment = environment.APP_NOTIFICATION_SERVICE_BASE_URL + '/api/v1/notifications/equipmentalarms';
  private urlTransport = environment.APP_NOTIFICATION_SERVICE_BASE_URL + '/api/v1/notifications/transportalarms';

  constructor(private http: HttpClient) {
  }

  public getAlarmRules(): Observable<PagedResponse<ViewAlarmRule>> {
    return this.http.get<PagedResponse<ViewAlarmRule>>(`${this.urlBase}/rules`);
  }

  public getCurrentActive(): Observable<PagedResponse<Alarm>> {
    return this.http.get<PagedResponse<Alarm>>(`${this.urlBase}/current-active`);
  }

  public acknowledgeAlarm(command: AcknowledgeAlarmCommand): Observable<string> {
    return this.http.put(`${this.urlBase}/${command.id}/acknowledge`, JSON.stringify(command), {responseType: 'text'});
  }

  // EQUIPMENT ALARM METHODS
  public saveEquipmentAlarmRule(command: CreateAlarmRuleCommand): Observable<string> {
    return this.http.post(`${this.urlEquipment}/rules/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateEquipmentAlarmRule(command: UpdateAlarmRuleCommand): Observable<string> {
    const equipmentCommand = command.toEquipmentCommand();
    return this.http.post(`${this.urlEquipment}/rules/update`, JSON.stringify(equipmentCommand), {responseType: 'text'});
  }

  public removeEquipmentAlarmRule({ alarmRuleId }: DeleteAlarmRuleCommand): Observable<string> {
    return this.http.post(`${this.urlEquipment}/rules/delete`, JSON.stringify({ alarmRuleId }), {responseType: 'text'});
  }

  public getDistinctLabels(): Observable<string[]> {
    return this.http.get<string[]>(`${this.urlEquipment}/distinct-labels`);
  }

  public getDistinctEquipmentTypes(): Observable<EquipmentTypeFilterView[]> {
    return this.http.get<EquipmentTypeFilterView[]>(`${this.urlEquipment}/grouped-equipment-types`);
  }

  // TRANSPORT ALARM METHODS
  public saveTransportAlarmRule(command: CreateAlarmRuleCommand): Observable<string> {
    const transportCommand = command.toTransportCommand();
    return this.http.post(`${this.urlTransport}/rules/create`, JSON.stringify(transportCommand), {responseType: 'text'});
  }

  public updateTransportAlarmRule(command: UpdateAlarmRuleCommand): Observable<string> {
    const transportCommand = command.toTransportCommand();
    return this.http.post(`${this.urlTransport}/rules/update`, JSON.stringify(transportCommand), {responseType: 'text'});
  }

  public removeTransportAlarmRule({ alarmRuleId }: DeleteAlarmRuleCommand): Observable<string> {
    return this.http.post(`${this.urlTransport}/rules/delete`, JSON.stringify({ alarmRuleId }), {responseType: 'text'});
  }
}
