import { TimelineEvent } from './timeline-event';
import { ViewEmployeeProjectAssignment } from './view-employee-project-assignment.interface';

export class EmployeeAssignmentEvent extends TimelineEvent {

  public assignment: ViewEmployeeProjectAssignment;

  constructor(assignment: ViewEmployeeProjectAssignment) {
    super(assignment.projectId + '/' + assignment.assignmentId,
      assignment.projectId + '/' + assignment.employeeId,
      assignment.assignmentStartDate,
      assignment.assignmentEndDate,
      null,
      '',
      false,
      false,
      'red');
    this.assignment = assignment;
  }
}

export function isEmployeeAssignmentEvent(event: TimelineEvent): event is EmployeeAssignmentEvent {
  return (<EmployeeAssignmentEvent>event).assignment !== undefined &&
   (<EmployeeAssignmentEvent>event).assignment.employeeId !== undefined;
}
