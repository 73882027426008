<div class="calibrate-equipment-dialog" fxLayout="column">

  <h2 mat-dialog-title>
    {{'modules.equipment.equipmentView.calibrateTelematicUnitHeader' |translate}}
  </h2>

  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close tabindex="-1">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-content" fxFlex="100" fxLayoutAlign="center center">

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">

      <div *ngIf="!calibrating"
           class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
        <div fxLayout="column" fxFlex="90" fxLayoutAlign="start center" class="general-data" fxLayoutGap="50px">

          <div fxFlex="40px" fxLayoutGap="20px">
            <div fxLayoutAlign="center center">
              <h2 mat-dialog-title>
                {{'modules.equipment.equipmentView.calibrateTelematicUnit' |translate}}
              </h2>
            </div>
            <div fxLayoutAlign="center center">
              <span class="small-text">{{'modules.equipment.equipmentView.calibrateTelematicUnitHint' |translate}}</span>
            </div>
          </div>
          <form [formGroup]="calibrationForm" novalidate fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">
            <mat-form-field *ngIf="isCalibrationOperatingHoursAvailable">
              <mat-label>{{'modules.equipment.equipmentView.operatingHours'|translate}}</mat-label>
              <input matInput
                     type="number"
                     formControlName="calibratedOperatingHours"
                     required>
              <mat-error *ngIf="calibrationForm.get('calibratedOperatingHours').hasError('min')">
                {{'shared.validation.number.notNegative'|translate}}
              </mat-error>
              <mat-error *ngIf="calibrationForm.get('calibratedOperatingHours').hasError('required')">
                {{'shared.validation.requiredField'|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="isCalibrationMileageAvailable">
              <mat-label>{{'modules.equipment.equipmentView.mileage'|translate}} ({{'km'|dimensionUnitDisplay}})</mat-label>
              <input matInput
                     type="number"
                     formControlName="calibratedMileage"
                     required>
              <mat-error *ngIf="calibrationForm.get('calibratedMileage').hasError('min')">
                {{'shared.validation.number.notNegative'|translate}}
              </mat-error>
              <mat-error *ngIf="calibrationForm.get('calibratedMileage').hasError('required')">
                {{'shared.validation.requiredField'|translate}}
              </mat-error>
            </mat-form-field>
          </form>
        </div>
        <div class="button-wrapper">
          <button class="bottom-button"
                  mat-raised-button
                  bhTimeoutSaveButton
                  color="primary"
                  (throttledClick)="save()"
                  [disabled]="!isValid()">
            {{'general.buttons.calibrate'|translate}}
          </button>
        </div>
      </div>

      <!-- CALIBRATION PRELOADER -->
      <div *ngIf="calibrating" class="step-content" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="25px">
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="start center" class="general-data" fxLayoutGap="50px">
          <div fxFlex="40px" fxLayoutGap="20px">
            <div fxLayoutAlign="center center">
              <h2 mat-dialog-title>
                {{ 'modules.equipment.equipmentView.calibratingTelematicUnit' | translate }}
              </h2>
            </div>
            <div fxLayoutAlign="center center">
              <span class="small-text">
                {{ 'modules.equipment.equipmentView.calibratingTelematicUnitHint' | translate }}
              </span>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">
            <mat-spinner [strokeWidth]="4" [diameter]="50"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
