<div class="error-snackbar" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <mat-icon>report_problem</mat-icon>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <div class="error-message">{{data.message}}</div>
    <div>{{'shared.errorSnackbar.contactSupport'|translate}}</div>
    <div>
      {{'shared.errorSnackbar.timeOfFailure'|translate}}:
      {{ errorTime | date:'EEEE, MMMM d, y, HH:mm:ss':'':currentLocate }}
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch">
    <button class="error-snackbar__dismiss" mat-icon-button (click)="dismiss()">
      <mat-icon class="bh-mat-icon">close</mat-icon>
    </button>
  </div>
</div>
