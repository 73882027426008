export enum BrzBlacklistAuthorities {
  EQUIPMENT_CREATE,
  EQUIPMENT_UPDATE,
  EQUIPMENT_TYPE_CHANGE,
  EQUIPMENT_DELETE,

  EQUIPMENT_STATUS_UPDATE_ANY,

  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_DELETE,

  BULK_ITEMS_MANAGE,

  STOCK_MANAGE
}
