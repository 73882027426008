import { isNull, isUndefined } from 'lodash';


export function hashCodeOf(value: any): number {
  if (isNull(value) || isUndefined(value)) {
    return 0;
  }

  if (typeof value.getTime === 'function') {
    return value.getTime();

  } else if (typeof value === 'object') {
    return hashCodeOfObj(value);

  } else {
    value = value.toString();
    return hashCodeOfStr(value);
  }
}

function hashCodeOfObj(value: object): number {
  if (isNull(value) || isUndefined(value)) {
    return 0;
  }

  let result = 0;

  for (let property in value) {
    if (value.hasOwnProperty(property)) {
      result += hashCodeOfStr(property + hashCodeOf(value[property]));
    }
  }

  return result;
}

function hashCodeOfStr(value: string): number {
  if (isNull(value) || isUndefined(value)) {
    return 0;
  }

  let result = 0;
  for (let i = 0; i < value.length; i++) {
    /* tslint:disable:no-bitwise */
    result = (((result << 5) - result) + value.charCodeAt(i)) & 0xFFFFFFFF;
    /* tslint:enable:no-bitwise */
  }
  return result;
}
