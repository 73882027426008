<div class="service-health-component">
  <div class="service-health-component-header">{{'modules.navigation.healthService.title'|translate}}</div>
  <div mat-dialog-content class="service-health-component-info">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{'modules.navigation.healthService.columnName'|translate}}</span>
      <span>{{'general.status'|translate}}</span>
    </div>
      <bh-health-check
        *ngFor="let service of services" [serviceName]="service.name" [serviceUrl]="service.health_url">
      </bh-health-check>
  </div>
</div>
