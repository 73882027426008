import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bh-equipment-assignment-delete-dialog',
  templateUrl: './equipment-assignment-delete-dialog.component.html',
  styleUrls: ['./equipment-assignment-delete-dialog.component.scss']
})
export class EquipmentAssignmentDeleteDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
