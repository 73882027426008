import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BasicWidget } from '../basic-widget/basic-widget';
import { TypeOfUseChangeRequestsWidgetStoreService } from './type-of-use-change-requests-widget-store.service';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITypeOfUseChangeRequest } from './type-of-use-change-request.interface';
import { KeycloakService } from '../../../../core/keycloak';
import { Authorities } from '../../../../shared/enums/authorities.enum';

@UntilDestroy()
@Component({
  selector: 'bh-type-of-use-change-requests-widget',
  templateUrl: './type-of-use-change-requests-widget.component.html',
  styleUrls: ['./type-of-use-change-requests-widget.component.scss'],
  providers: [TypeOfUseChangeRequestsWidgetStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeOfUseChangeRequestsWidgetComponent extends BasicWidget implements OnInit {
  public endOfList: Observable<boolean>;
  public loading: Observable<boolean>;
  public noEntries: Observable<boolean>;

  public requests: ITypeOfUseChangeRequest[] = [];
  public showAcceptAndDeclineBtns: boolean;
  public showDeleteBtn: boolean;

  constructor(private authService: KeycloakService,
              private store: TypeOfUseChangeRequestsWidgetStoreService,
              private cdr: ChangeDetectorRef,
              ) {
    super();
  }

  ngOnInit(): void {
    this.noEntries = this.store.noEntries;
    this.endOfList = this.store.endOfList;
    this.loading = this.store.loading;
    this.showAcceptAndDeclineBtns = this.authService.hasAuthority(Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE);
    this.showDeleteBtn = this.authService.hasAuthority(Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE);

    this.onScroll(true);

    this.subscribeTorequests();
  }

  public onScroll(initialLoad?: boolean): void {
    this.store.scroll(initialLoad);
  }

  private subscribeTorequests(): void {
    this.store.requests
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (data) => {
        this.requests = data;
        this.cdr.markForCheck();
      },
    });
  }

}
