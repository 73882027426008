<div id="dialog" class="dialog-wrapper">
  <h1 mat-dialog-title>{{'modules.transportation.transportListExport.title'|translate}}</h1>
  <div mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutAlign="center stretch">
      <ng-container *ngIf="!pendingRequest">
        <div class="dialog-row" fxLayout="row">
          <mat-icon>view_column</mat-icon>
          <mat-slide-toggle color="primary" [(ngModel)]="selectAllColumns" fxFlex>
            {{'shared.export.allColumns'|translate}}
          </mat-slide-toggle>
          <mat-icon
            class="hover"
            fxFlex="10"
            [matTooltip]="'shared.export.allColumnsHelp'|translate">
            help
          </mat-icon>
        </div>

        <div class="dialog-row" fxLayout="row">
          <mat-icon>filter_list</mat-icon>
          <mat-slide-toggle color="primary" [(ngModel)]="applyCurrentFilter" fxFlex>
            {{'modules.equipment.equipmListExport.applyCurrent'|translate}}
          </mat-slide-toggle>
          <mat-icon
            class="hover"
            fxFlex="10"
            [matTooltip]="'modules.transportation.transportListExport.applyCurrentHelp'|translate">
            help
          </mat-icon>
        </div>

        <div class="dialog-row" fxLayout="row">
          <mat-icon>sort_by_alpha</mat-icon>
          <mat-slide-toggle color="primary" [(ngModel)]="sortDescending" fxFlex>
            {{(sortDescending ? 'shared.export.sortDsc' : 'shared.export.sortAsc')|translate}}
          </mat-slide-toggle>
        </div>

        <div class="dialog-row" fxLayout="row">
          <mat-icon>sort</mat-icon>
          <mat-form-field>
            <mat-label>{{'general.labels.sortBy'|translate}}</mat-label>
            <mat-select required [(ngModel)]="sortBy">
              <mat-option *ngFor="let field of sortableFields" [value]="field.value">
                {{field.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </ng-container>

      <mat-spinner diameter="64" [attr.aria-label]="'modules.transportation.transportListExport.listIsCreated'|translate"
                   *ngIf="pendingRequest">
      </mat-spinner>

    </div>
  </div>
  <div mat-dialog-actions>
    <div fxFlex="row" fxLayoutAlign="space-between center">
      <button mat-raised-button color="accent" (click)="cancel()">
        {{'general.buttons.cancel'|translate}}
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="pendingRequest"
        (click)="exportTransportList()">
        {{'general.buttons.download'|translate}}
      </button>
    </div>
  </div>
</div>
