<div class="equipment-type-dialog">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close="abort" tabindex="-1">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title>{{'modules.equipment.equipmentTypeSelect.title'|translate}}</h1>

  <mat-dialog-content>
    <div class="warning" *ngIf="isEditMode">
      <mat-icon>warning</mat-icon>
      <p>
        {{'modules.equipment.equipmentTypeSelect.warning'|translate}}
      </p>
    </div>

    <mat-form-field>
      <mat-label>{{'general.labels.searchFor'|translate}}</mat-label>
      <input type="text" matInput [formControl]="equipmentTypeSearchControl">
    </mat-form-field>

    <mat-list>
      <section *ngFor="let typeGroup of filteredEquipmentTypes">
        <h3 matSubheader>
          {{typeGroup.category1Name | translatableString }}
        </h3>
        <mat-list-item *ngFor="let subtype of typeGroup.subTypes"
                       (click)="selectType(subtype.equipmentTypeId)"
                       [ngClass]="{'selected': subtype.equipmentTypeId === selectedTypeId}">
          {{subtype.category2Name | translatableString}}
        </mat-list-item>
      </section>
    </mat-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="accent" mat-dialog-close="abort">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!selectedTypeId">
      {{(isEditMode ? 'general.buttons.save' : 'general.buttons.continue')|translate}}
    </button>
  </mat-dialog-actions>
</div>
