import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { FilterParams } from '../../../filter-params.class';
import { EquipmentStatusFilterReadinessCheckerBase } from '../../base/equipment-status/equipment-status-filter-readiness-checker-base.class';
import { Observable } from 'rxjs';
import { EquipmentStateFilterView } from '../../../filter-view/equipment-state-filter-view.interface';
import { IEquipmentFilterReadinessChecker } from '../../interfaces/equipment-filter-readiness-checker.interface';

export class EquipmentStatusNewFilterReadinessChecker
  extends EquipmentStatusFilterReadinessCheckerBase
  implements IEquipmentFilterReadinessChecker {

  constructor(params: FilterParams, private equipmentsService: EquipmentsService) {
    super(params);
  }

  protected getEquipmentStatusFilters(): Observable<EquipmentStateFilterView[]> {
    return this.equipmentsService.getEquipmentNewStatuses(this.params.getFilterParams());
  }
}
