import { StockType } from '../../../shared/enums/stock-type';
import { Address } from '../../organisation/contract/address.interface';
import { LatLon } from '../../../shared/geolocation/lat-lon.interface';

export class ViewStock {

  static empty(): ViewStock {
    return new ViewStock('', '', null, '', '', '', null);
  }

  constructor(public stockId: string,
              public stockName: string,
              public stockType: StockType,
              public organisationId: string,
              public customerId: string,
              public organisationName: string,
              public location: LatLon,
              public stockCostCenter?: string,
              public stockAddress?: Address,
              public showInDispositionBoard?: boolean,
              public scanCode?: string,
              public chargeDate?: Date) {}
}

export function instanceOfViewStock(object: any): object is ViewStock {
  return 'stockId' in object && 'stockName' in object && 'stockType' in object && 'organisationId' in object;
}
