import { environment } from 'environments/environment';
import { Component } from '@angular/core';
import { EmployeeViewDispositionBase } from './base/employee-view-disposition-base';
import { faPlus, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployeeAssignToProjectDialogComponent } from 'app/modules/disposition/shared/employee-assign-to-project-dialog/landscape/employee-assign-to-project-dialog.component';
import { DispositionEmployee } from 'app/modules/disposition/contract/disposition-employee';
import { AddEmployeeToProjectAssignmentCommand } from 'app/modules/disposition/contract/add-employee-project-assignment-command';
import { KeycloakService } from 'app/core/keycloak';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterHistory } from 'app/shared/router-history';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';
import { EmployeeDispositionDatasource } from 'app/modules/disposition/shared/employee-disposition.datasource';

@Component({
  selector: 'bh-employee-view-disposition',
  templateUrl: './employee-view-disposition.component.html',
  styleUrls: ['./employee-view-disposition.component.scss']
})
export class EmployeeViewDispositionComponent extends EmployeeViewDispositionBase {

  public readonly faPlus: IconDefinition = faPlus;

  constructor(protected authService: KeycloakService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerHistory: RouterHistory,
    protected dialog: MatDialog,
    protected employeeManagementStore: EmployeeManagementDatasource,
    protected employeeDispositionStore: EmployeeDispositionDatasource) {
    super(authService, router, route, routerHistory, employeeManagementStore);
   }

   assignToProject(): void {
    let dialogRef = this.dialog.open(EmployeeAssignToProjectDialogComponent, <MatDialogConfig>{
      disableClose: true
    });

    dialogRef.componentInstance.employees.push(DispositionEmployee.fromViewEmployee(this.currentEmployee));
    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.afterClosed().subscribe((commands: AddEmployeeToProjectAssignmentCommand[]) => {
      if (commands) {
        commands.forEach((cmd) => {
          this.employeeDispositionStore.assignEmployeeToProject(cmd).subscribe(res => {
            // BEUT ... (story for showing dispositions in employee detail view)
          });
        });
        setTimeout(() => this.employeeDispositionStore.updateListing(), environment.DELAY_LONG);
      }
    });
   }
}
