import { Component } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';

@Component({
  selector: 'bh-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  private _confirmLabel: string;
  private _denyLabel: string;

  public confirmMessage: string;
  public confirmTitle: string;
  public secondaryConfirmMessage: string;
  public isDenyButtonAvailable = true;

  public set confirmLabel(value: string) {
    this._confirmLabel = value;
  }
  public get confirmLabel(): string {
    return this._confirmLabel || this.langService.getInstant('general.buttons.yes');
  }
  public set denyLabel(value: string) {
    this._denyLabel = value;
  }
  public get denyLabel(): string {
    return this._denyLabel || this.langService.getInstant('general.buttons.no');
  }

  constructor(private langService: LanguageService) {
  }
}
