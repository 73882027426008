import { LanguageService } from 'app/shared/services/language.service';
import { RentalEquipmentWarningDialogComponent } from '../../equipment-list/equipment-view/equipment-view-disposition/rental-equipment-warning-dialog/rental-equipment-warning-dialog.component';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ViewEquipment } from '../../../contract/view-equipment.interface';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { AssignEquipmentToProjectCommand } from '../../../contract/assign-equipment-to-project-command';
import { MatDialog } from '@angular/material/dialog';
import { DatesService } from '../../../../../shared/services/dates.service';
import { DispositionDataSource } from '../../../../disposition/shared/disposition.datasource';
import * as moment from 'moment';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectCheckerService } from '../../../../disposition/shared/services/project-checker.service';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { ProjectDataSource } from '../../../../disposition/shared/project.datasource';
import { OnlineStatusService } from '../../../../../shared/services/online-status.service';
import { EquipmentAssignToProjectUtils } from './equipment-assign-to-project-utils';
import { DispositionProject } from '../../../../disposition/shared/disposition-project';
import { DispositionEquipment } from '../../../shared/disposition-equipment';
import { Point } from '../../../../../shared/geolocation/point';
import { BehaviorSubject } from 'rxjs';
import { RequestedAssignment } from '../../../shared/requested-assignment';
import { EquipmentsService } from '../../../shared/equipments.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../../shared/enums/dialogResults.enum';
import { byLocationClosestToPosition } from '../../../../../shared/collection-utils';
import { RouterHistory } from '../../../../../shared/router-history';
import { FieldLimit } from '../../../../../shared/enums/fieldLimit.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewEquipmentProjectAssignment } from '../../../contract/view-equipment-project-assignment.interface';
import { UsersService } from '../../../../userrole/shared/users.service';
import { DispositionAssignmentPayload } from '../../../contract/disposition-assignment-payload.interface';
import { DateUtils } from 'app/shared/dateUtils';
import { DispositionEquipmentPayload } from 'app/shared/contract/disposition-equipment-payload.interface';
import { EquipmentCheckerService } from './../../../shared/services/equipment-checker.service';

@UntilDestroy()
@Directive()
export class BaseEquipmentAssignToProjectComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  assignToProjectForm: UntypedFormGroup;
  projectSubForm: UntypedFormGroup;
  dispositionsSubForm: UntypedFormGroup;
  commentSubForm: UntypedFormGroup;

  dialogRef;
  project: DispositionProject;
  projects: DispositionProject[];
  equipments: DispositionEquipment[] = []; // they have no scan code in landscape, because they are casted from SearchEquipments
  minStartDate: Date;
  maxEndDate: Date;
  loadingProjects: BehaviorSubject<boolean> = new BehaviorSubject(false);
  position: Point;
  filteredProjects: BehaviorSubject<DispositionProject[]> = new BehaviorSubject([]);
  overwriteEnabled: boolean;
  private animationInProgress = true;
  private projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  private acknowledgeAssignmentOrTransferWorkflow: boolean;
  private openAssignmentRequest = false;
  public readonly fieldLimit = FieldLimit;
  public outsideAssignmentData: DispositionAssignmentPayload<DispositionEquipmentPayload> = null;

  private overrideStartDate(start: Date, end: Date): boolean {
    if (!start && !end) {
      return true;
    }
    if (start) {
      const today = moment();
      return today.isAfter(start) && (!end || today.isBefore(end));
    }
    return false;
  }

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentStore: EquipmentsDataSource,
              protected dispositionStore: DispositionDataSource,
              protected projectStore: ProjectDataSource,
              protected formBuilder: UntypedFormBuilder,
              protected onlineStatusService: OnlineStatusService,
              protected dialog: MatDialog,
              protected equipmentsService: EquipmentsService,
              protected  usersService: UsersService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected equipmentCheckerService: EquipmentCheckerService) {
    super(authService, router, route, routerHistory);
  }

  get projectId() {
    return this.projectSubForm.get('projectId');
  }

  get equipmentDispositions(): AbstractControl[] {
    const formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    return formArray.controls;
  }

  get startDateControl(): AbstractControl {
    return this.dispositionsSubForm.get('startDate');
  }

  get endDateControl(): AbstractControl {
    return this.dispositionsSubForm.get('endDate');
  }

  public ngOnInit(): void {
    this.overwriteEnabled = this.route.snapshot.queryParams.overwrite;
    this.buildForm();
    this.subscribeToProjects();
    this.subscribeToProjectSearch();
    this.projectStore.hasAssignmentOrTransferRequestWorkflow
    .pipe(untilDestroyed(this))
    .subscribe((value: boolean) => this.acknowledgeAssignmentOrTransferWorkflow = value);
    this.onlineStatusService.isOnline
    .pipe(
      untilDestroyed(this),
      filter((isOnline: boolean) => isOnline),
      tap(() => this.projectStore.updateAssignmentOrTransferRequestWorkflow()),
      tap(() => this.updateAllConflicts()))
    .subscribe();

    const subEquipments = this.outsideAssignmentData
      && this.outsideAssignmentData.assignment
      && this.outsideAssignmentData.assignment.subEquipments;
    if (!subEquipments) {
      this.subscribeToSubEquipments();
    } else {
      subEquipments.forEach(subEquipment => this.addDisposition(subEquipment, true));
    }
  }

  public ngOnDestroy() {
  }

  public setAssignmentData(data: DispositionAssignmentPayload<DispositionEquipmentPayload>): void {
    this.outsideAssignmentData = {...data};
    this.project = {...data.project};
    this.equipments = [data.assignment.equipment];
  }

  private subscribeToSubEquipments(): void {
    if (!this.onlineStatusService.onlineStatus) {
      return;
    }

    const equipmentIds: string[] = [];
    this.equipments.forEach(function (element) {
      equipmentIds.push(element.equipmentId);
    });

    this.equipmentsService.getSubEquipments(equipmentIds)
    .pipe(
      untilDestroyed(this),
      map((res: ViewEquipment[]) => res && res.filter(equipment => this.equipmentCheckerService.isActiveEquipment(equipment))))
    .subscribe(
      (res: ViewEquipment[]) => {
        if (res) {
          res.forEach(subEquipment => this.addDisposition(subEquipment, true));
        }
      },
      (error: HttpErrorResponse) => console.log('error get sub-equipments: ', error),
    );
  }

  fillDateFields(): void {
    this.minStartDate = this.evaluateMinStartDate();
    this.maxEndDate = this.project.projectEnd ? moment(this.project.projectEnd).toDate() : null;

    if (this.outsideAssignmentData) {
      const {startDate, endDate} = this.outsideAssignmentData;
      this.dispositionsSubForm.patchValue({
        startDate: DateUtils.minDate(startDate, this.maxEndDate),
        endDate: DateUtils.maxDate(endDate, this.minStartDate)
      }, {emitEvent: false});
    } else {
      this.dispositionsSubForm.patchValue({
        startDate: this.minStartDate,
        endDate: this.maxEndDate,
      }, {emitEvent: false});

      if (this.overrideStartDate(this.project.projectStart, this.project.projectEnd)) {
        this.dispositionsSubForm.patchValue({startDate: moment().toDate()}, {emitEvent: false});
      }
    }
    this.equipmentDispositions.forEach(disposition =>
      disposition.patchValue({overwrite: this.overwriteEnabled}, {emitEvent: false}));
    this.dispositionsSubForm.markAllAsTouched();

    this.updateAllConflicts();
  }

  updateAllConflicts(): void {
    if (this.project && this.equipments && this.startDateControl.valid && this.endDateControl.valid) {
      this.equipmentDispositions.forEach(disposition => {
          if (this.acknowledgeAssignmentOrTransferWorkflow) {
            this.existsAssignmentRequests(disposition);
          } else if (!this.openAssignmentRequest) {
            this.updateConflicts(disposition);
          }
        },
      );
    }
  }

  existsAssignmentRequests(equipmentDisposition: AbstractControl): void {
    this.equipmentStore.getOpenAssignmentRequests(equipmentDisposition.value.equipmentId)
    .pipe(untilDestroyed(this))
    .subscribe((assignmentRequests: RequestedAssignment[]) => {
      if (this.openAssignmentRequest === true && assignmentRequests.length > 0) {
        equipmentDisposition.patchValue({assignmentRequests: assignmentRequests});
        return;
      }

      if (this.openAssignmentRequest === false && assignmentRequests.length > 0) {
        this.openAssignmentRequest = assignmentRequests.length > 0;
      }

      if (this.openAssignmentRequest) {
        equipmentDisposition.patchValue({assignmentRequests: assignmentRequests});
      } else {
        this.updateConflicts(equipmentDisposition);
      }
    });
  }

  updateConflicts(equipmentDisposition: AbstractControl): void {
    if (this.onlineStatusService.onlineStatus) {
      this.equipmentStore.getAssignmentCollisions(
        equipmentDisposition.value.equipmentId,
        DatesService.sameTimeZoneAtStartDateUTC(this.startDateControl.value),
        DatesService.sameTimeZoneAtEndDateUTC(this.endDateControl.value))
      .pipe(
        untilDestroyed(this),
        map((conflicts: ViewEquipmentProjectAssignment[]) => conflicts.length === 0 ? undefined : conflicts))
      .subscribe(conflictingAssignments => {

        const overwriteConflicts = equipmentDisposition.value.overwrite;

        if (!conflictingAssignments) {
          equipmentDisposition.patchValue({warnings: []});
        }
        if (conflictingAssignments && overwriteConflicts) {
          equipmentDisposition.patchValue({warnings: conflictingAssignments});
        }
        if (conflictingAssignments && !overwriteConflicts) {
          equipmentDisposition.patchValue({warnings: []});
          equipmentDisposition.setErrors({'conflicts': conflictingAssignments});
        }
      });
    } else {
      equipmentDisposition.patchValue({overwrite: true});
    }
  }

  save(): void {
    if (this.isValid) {
      const commands: AssignEquipmentToProjectCommand[] = this.equipmentDispositions.map(equipmentDisposition => {
        let cmd: AssignEquipmentToProjectCommand = new AssignEquipmentToProjectCommand();
        cmd.projectId = this.project.projectId;
        cmd.equipmentId = equipmentDisposition.get('equipmentId').value;
        cmd.startDate = DatesService.sameTimeZoneAtStartDateUTC(this.startDateControl.value);
        cmd.endDate = DatesService.sameTimeZoneAtEndDateUTC(this.endDateControl.value);
        cmd.comment = this.commentSubForm.get('comment').value;
        cmd.overwrite =  equipmentDisposition.get('overwrite').value === null ? false : equipmentDisposition.get('overwrite').value;
        return cmd;
      });

      if (commands.find(cmd => this.showRentalWarning(cmd.endDate))) {
        let dialog = this.dialog.open(RentalEquipmentWarningDialogComponent);
        dialog.afterClosed().subscribe(result => {
          if (result === dialogResults.YES) {
            this.dialogRef.close(commands);
          } else {
            dialog.close();
          }
        });
      } else {
        this.dialogRef.close(commands);
      }
    }
  }

  public isValid(): boolean {
    return this.projectSubForm.valid && this.dispositionsSubForm.valid && this.commentSubForm.valid
      && !this.openAssignmentRequest;
  }

  showRentalWarning(assignmentEndDate: string): boolean {
    return this.equipments.find((equipment: ViewEquipment) => {
      return equipment.currentRental && moment(equipment.currentRental.endDate).isBefore(moment(assignmentEndDate));
    }) !== undefined;
  }

  isContinueToDispositionDisabled(): boolean {
    return !this.projectSubForm.valid;
  }

  isContinueToCommentDisabled(): boolean {
    return !this.projectSubForm.valid || !this.dispositionsSubForm.valid;
  }

  addDisposition(equipmentDisposition: ViewEquipment, isSubEquipment: boolean) {
    let equipment = this.formBuilder.group({
      equipmentId: [equipmentDisposition.equipmentId],
      equipmentName: [EquipmentAssignToProjectUtils.formatEquipmentIdentifier(equipmentDisposition)],
      warnings: [],
      assignmentRequests: [],
      overwrite: this.overwriteEnabled,
      isSubEquipment: isSubEquipment,
      containerId: [equipmentDisposition.containerId],
    });

    let formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    const isDuplicateEquipment = disposition => disposition.get('equipmentId').value === equipment.get('equipmentId').value;

    if (this.equipmentDispositions.filter(isDuplicateEquipment).length === 0) {
      formArray.push(equipment);
    }
  }

  removeDisposition(equipmentDisposition: AbstractControl) {
    const equipmentId = equipmentDisposition.get('equipmentId').value;
    const formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    const index = formArray.controls.indexOf(equipmentDisposition);

    let hasSubEquipment = formArray.controls.filter(control => control.get('containerId').value === equipmentId).length > 0;

    if (hasSubEquipment && this.numberOfEquipments() <= 1) {
      this.removeEquipmentDispositionWithoutSubEquipments(equipmentDisposition);

    } else if (hasSubEquipment && this.numberOfEquipments() > 1) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmTitle = this.translate('modules.equipment.confirmation.title.deleteSubequipmentAlso');
      dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.deleteSubequipmentAlso');

      dialogRef.afterClosed().subscribe((dialogResult: string) => {
        if (dialogResult === dialogResults.YES) {
          this.removeEquipmentDispositionWithSubEquipments(equipmentDisposition);
        } else if (dialogResult === dialogResults.NO) {
          this.removeEquipmentDispositionWithoutSubEquipments(equipmentDisposition);
        }
      });
    } else {
      this.equipments = this.equipments.filter(eq => eq.equipmentId !== equipmentId);
      formArray.removeAt(index);

      this.openAssignmentRequest = false;
      for (let control of formArray.controls) {
        if (control.get('assignmentRequests').value && control.get('assignmentRequests').value.length > 0) {
          this.openAssignmentRequest = true;
        }
      }
    }
  }

  removeEquipmentDispositionWithoutSubEquipments(equipmentDisposition: AbstractControl) {
    const equipmentId = equipmentDisposition.get('equipmentId').value;
    const formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    const index = formArray.controls.indexOf(equipmentDisposition);

    this.equipments = this.equipments.filter(eq => eq.equipmentId !== equipmentId);
    formArray.removeAt(index);

    for (let control of formArray.controls) {
      if (control.get('containerId').value === equipmentId) {
        const indexSubEquipment = formArray.controls.indexOf(control);
        formArray.setControl(indexSubEquipment, this.formBuilder.group({
          equipmentId: control.get('equipmentId'),
          equipmentName: control.get('equipmentName'),
          warnings: control.get('warnings'),
          assignmentRequests: control.get('assignmentRequests'),
          overwrite: control.get('overwrite'),
          isSubEquipment: false,
          containerId: [''],
        }));
      }
    }

    this.updateAllConflicts();
  }

  removeEquipmentDispositionWithSubEquipments(equipmentDisposition: AbstractControl) {
    const equipmentId = equipmentDisposition.get('equipmentId').value;
    let formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    const index = formArray.controls.indexOf(equipmentDisposition);

    formArray.removeAt(index);
    this.equipments = this.equipments.filter(eq => eq.equipmentId !== equipmentId);

    const subEquipments: AbstractControl[] = formArray.controls.filter(control => control.get('containerId').value === equipmentId);
    for (let subEquipment of subEquipments) {
      formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
      let indexSubEquipment = formArray.controls.indexOf(subEquipment);
      formArray.removeAt(indexSubEquipment);
    }
  }

  numberOfEquipments(): number {
    const formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    return formArray.controls.filter(control => !control.get('isSubEquipment').value).length;
  }

  numberOfSubEquipments(): number {
    const formArray = this.dispositionsSubForm.get('equipmentDispositions') as UntypedFormArray;
    return formArray.controls.filter(control => control.get('isSubEquipment').value).length;
  }

  isEquipmentRemovable(): boolean {
    return (this.numberOfEquipments() + this.numberOfSubEquipments() > 1);
  }

  startAnimation(): void {
    this.animationInProgress = true;
  }

  endAnimation(): void {
    this.animationInProgress = false;
  }

  protected buildForm(): void {

    this.projectSubForm = this.formBuilder.group({
      projectId: [(this.outsideAssignmentData
        && this.outsideAssignmentData.project
        && this.outsideAssignmentData.project.projectId) || null, Validators.required],
    });

    this.loadingProjects.subscribe(loading => {
      const idControl = this.projectSubForm.controls['projectId'];
      loading ? idControl.disable() : idControl.enable();
    });

    this.dispositionsSubForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: [''],
      equipmentDispositions: this.formBuilder.array(this.equipments.map(equipment => this.formBuilder.group({
        equipmentId: [equipment.equipmentId],
        equipmentName: [EquipmentAssignToProjectUtils.formatEquipmentIdentifier(equipment)],
        warnings: [],
        assignmentRequests: [],
        overwrite: this.overwriteEnabled,
        isSubEquipment: false,
        containerId: [''],
      }))),
    });

    this.commentSubForm = this.formBuilder.group({
      comment: [''],
    });

    this.assignToProjectForm = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.projectSubForm,
        this.dispositionsSubForm,
        this.commentSubForm,
      ]),
    });
  }

  protected loadProjects(): void {
    this.projectStore.loadDispositionProjects();
  }

  protected subscribeToProjects(): void {
    this.projectStore.dispositionProjects
    .pipe(untilDestroyed(this))
    .subscribe((projects: DispositionProject[]) => {
      if (projects.length > 0) {
        this.projects = projects.filter(pr => !this.projectCheckerService.isProjectFinished(pr));
        this.filteredProjects.next(this.projects.slice());
        if (this.position) {
          this.projects.sort(byLocationClosestToPosition(this.position));
        }
      }

      this.loadingProjects.next(false);
    });

    this.projectStore.currentDispositionProject
    .pipe(untilDestroyed(this))
    .subscribe((project: DispositionProject) => {
      this.project = project;
      this.projectSubForm.setValue({projectId: this.project.projectId});
      this.fillDateFields();
    });
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private subscribeToProjectSearch(): void {
    this.projectSubForm.get('projectId').valueChanges
    .pipe(
      untilDestroyed(this),
      debounceTime(300),
      distinctUntilChanged(),
      filter((value: any) => value))
    .subscribe((value: string) => {
      this.getProjectAndPrimaryAssigneeData(value);
    });
  }

  private getProjectAndPrimaryAssigneeData(projectId: string): void {
    this.projectStore.setCurrentDispositionProject(projectId);
  }

  evaluateMinStartDate(): Date {
    if (this.project) {
      let projectStart = this.project.projectStart ?
        this.project.projectStart : null;
      let chargeDate = this.project.chargeDate ?
        this.project.chargeDate : projectStart;
      let momentPS = moment.utc(projectStart);
      let momentCD = moment.utc(chargeDate);
      if (momentCD.isAfter(momentPS)) {
        return new Date(momentCD.format());
      } else {
        return DatesService.utcDate(momentPS.format());
      }
    }
    return null;
  }
}
