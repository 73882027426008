import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { ViewTeltonikaUnit } from './view-teltonika-unit.interface';
import { TelematicsService } from './telematics.service';
import { TelematicLiveDataDatasource } from './telematic-datasource.interface';
import { TelematicsLoadingState } from './telematics-loading-state.enum';

@Injectable()
export class TeltonikaDatasource implements TelematicLiveDataDatasource<ViewTeltonikaUnit> {

  private _teltonikaData: BehaviorSubject<ViewTeltonikaUnit> = new BehaviorSubject(null);
  private _teltonikaDataLoading: BehaviorSubject<TelematicsLoadingState> = new BehaviorSubject(TelematicsLoadingState.READY);

  public readonly teltonikaData: Observable<ViewTeltonikaUnit> = this._teltonikaData.asObservable();
  public readonly teltonikaDataLoading: Observable<TelematicsLoadingState> = this._teltonikaDataLoading.asObservable();

  private updateSubs: Subscription;
  private currentImei: string;

  constructor(private telematicsService: TelematicsService) {
  }

  fetchLiveData(imei: string): void {
    this.fetchTeltonikaLiveData(imei);
  }

  startAutoUpdate(intervalMs: number, updateInitially?: boolean): void {
    this.teltonicaAutoUpdateStart(intervalMs, updateInitially);
  }

  stopAutoUpdate(): void {
    this.teltonikaAutoUpdateStop();
  }

  getLiveData(): Observable<ViewTeltonikaUnit> {
    return this.teltonikaData;
  }

  getLoadingState(): Observable<TelematicsLoadingState> {
    return this.teltonikaDataLoading;
  }

  public setCurrentImei(imei: string): void {
    this.currentImei = imei;
  }

  public fetchTeltonikaLiveData(imei: string) {
    this._teltonikaDataLoading.next(TelematicsLoadingState.LOADING);

    this.telematicsService.getTeltonikaData(imei).subscribe(
      res => {
        this._teltonikaData.next(res);
        this._teltonikaDataLoading.next(TelematicsLoadingState.READY);
      },
      () => {
        this._teltonikaDataLoading.next(TelematicsLoadingState.ERROR);
      });
  }

  public teltonicaAutoUpdateStart(intervalMs: number, updateInitially = true): void {
    this.teltonikaAutoUpdateStop();
    this.updateSubs = timer(updateInitially ? 0 : intervalMs, intervalMs)
      .subscribe(() => {
        if (this.currentImei) {
          this.fetchTeltonikaLiveData(this.currentImei);
        }
      });
  }

  public teltonikaAutoUpdateStop(): void {
    if (this.updateSubs) {
      this.updateSubs.unsubscribe();
    }
  }
}
