import { AbstractControl } from '@angular/forms';
import { IntervalsDurationEnum } from '../intervals-duration.enum';

export function intervalThresholdCombinationValidator(intervalControlName: string, intervalDurationControlName: string,
                                                      lowerThresholdControlName: string, upperThresholdControlName: string){
  return (control: AbstractControl): { [key: string]: any } => {
    const interval = control.get(intervalControlName).value;
    const lowerThreshold = control.get(lowerThresholdControlName).value;
    const upperThreshold = control.get(upperThresholdControlName).value;
    const intervalDuration = control.get(intervalDurationControlName).value;
    let invalid = false
    if(intervalDuration === IntervalsDurationEnum.DAYS && interval === 1) {
      if(lowerThreshold === 0 && upperThreshold !== 0){
        invalid = true;
      }
    } else if(lowerThreshold <= upperThreshold) {
      invalid = true;
    }
    return invalid? {invalidCombination: invalid}: null;
  };
}
