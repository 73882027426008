import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'bh-secret-text',
  templateUrl: './bh-secret-text.component.html',
  styleUrls: ['./bh-secret-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecretTextComponent {

  @Input() public hideCopyButton = false;
  @Input() public copyButtonTitle = 'Copy';
  @Input() public copiedButtonTitle = 'Copied';
  @Input() public alwaysVisible = false;

  public copied = false;

  private _text: string;
  private _hiddenMask = '***';
  private _hideText = true;
  private copyingTimeout;

  constructor(private cdr: ChangeDetectorRef,
              private clipboard: Clipboard) {
  }

  @Input() set text(text: string) {
    this._text = text;

    if (text) {
      this._hiddenMask = '*'.repeat(text.length);
    }
  }

  get plainText(): string {
    return this._text;
  }

  get hiddenText(): string {
    return this._hiddenMask;
  }

  get isHiddenText(): boolean {
    return !this.alwaysVisible && this._hideText;
  }

  public copy(): void {
    this.clipboard.copy(this.plainText);
    this.copied = true;
    this.resetCopyButton();
    this.cdr.detectChanges();
  }

  private resetCopyButton(): void {
    clearTimeout(this.copyingTimeout);
    this.copyingTimeout = setTimeout(() => {
      this.copied = false;
      this.cdr.detectChanges();
    }, 1500);
  }

  public toggleVisibility(): void {
    this._hideText = !this._hideText;
  }
}
