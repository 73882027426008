<div class="nextrepairs-widget">
  <mat-expansion-panel *ngFor="let equipment of equipments">
    <mat-expansion-panel-header>
      <mat-panel-title>{{equipment.equipmentName}}</mat-panel-title>
      <mat-panel-description>
        In einem Tag
      </mat-panel-description>
    </mat-expansion-panel-header>
    {{equipment.customerId}}<br>
    {{equipment.equipmentBeutlhauserId}}<br>
    {{equipment.currentOperatingHours}}<br>
  </mat-expansion-panel>
</div>
