import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressCity'
})
export class AddressCityPipe implements PipeTransform {

  transform(address: any, args?: any): any {
    if (!address || (!address.postalCode && !address.city)) {
      return '';
    }
    const cityRow = [];
    if (address.postalCode) {
      cityRow.push(address.postalCode);
    }
    if (address.city) {
      cityRow.push(address.city);
    }
    return cityRow.join(' ');
  }

}
