<div class="content" fxLayout="column" [formGroup]="searchForm">
  <div class="row-container" fxFlex="60px" fxLayout="row" fxLayoutAlign="start center">
    <div class="row-element header-row-element" fxFlex="1 1 100%">
      <div class="checkbox-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="1 1 100%">
        <mat-checkbox formControlName="hideFinished" color="primary" fxFlex="1 1 100%"
                      labelPosition="after">
          {{'modules.disposition.bulkItemTransfer.hideCompletedProjects'|translate}}
        </mat-checkbox>
      </div>
    </div>
    <div class="row-element header-row-element" fxFlex="1 1 100%">
      <mat-form-field>
        <mat-select #labelSelector
                    multiple
                    formControlName="labels"
                    [placeholder]="'modules.disposition.bulkItemTransfer.selectLabel'|translate">
          <mat-option *ngFor="let label of projectLabels | async"
                      [value]="label.name">
            {{label.name}} - ({{label.count}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row-container" fxFlex="60px" fxLayout="row" fxLayoutAlign="start center">
    <input formControlName="terms"
        class="search-input"
        [placeholder]="'general.search'|translate"
    />
  </div>

  <div class="projects" fxFlex fxLayout="column">
    <div *ngFor="let project of projects | async"
         class="row-container"
         [ngClass]="{'selected': selectedProjectId === project.projectId}"
         fxFlex="105px"
         fxLayout="row"
         fxLayoutAlign="start center"
         (click)="selectProject(project)">
      <div class="row-element" fxLayout="column">
          <pre
            class="upper-row project-number"
            [ngClass]="{'selected-project-number': selectedProjectId === project.projectId}"
          ># {{ project.projectNumber?.trim() }}</pre>
          <span class="upper-row">{{ project.projectName }}</span>
          <span class="lower-row">
            {{ project.projectCostCenter ? project.projectCostCenter : 'modules.disposition.bulkItemTransfer.costCenterNotSet'|translate }}
          </span>
          <span class="lower-row">{{ project.projectAddress.street?.concat(' -')}}
            {{ project.projectAddress.streetNumber?.concat(' -')}}
            {{ project.projectAddress.postalCode }}
            {{ project.projectAddress.city }}
          </span>
      </div>
      <div class="row-element" fxLayout="column">
          <span class="upper-row uppercase">{{'general.to'|translate}}</span>
          <span class="lower-row">{{ project.projectEnd ? project.projectEnd : 'general.labels.openEnd'|translate }}</span>
      </div>
      <div class="row-element row-assignee" fxLayout="column">
          <span class="upper-row uppercase">{{'general.responsible'|translate|lowercase}}</span>
          <span class="lower-row">{{ project.primaryAssigneeFullName }}</span>
      </div>
    </div>
  </div>
  <div class="row-container paginator-container">
    <mat-paginator #paginator
                   (page)="onPaginateChange($event)"
                   [length]="transferTargetProjectStore.length | async"
                   [pageIndex]="transferTargetProjectStore.pagination.index"
                   [pageSizeOptions]="[5, 25, 50, 100]"
                   [pageSize]="transferTargetProjectStore.pagination.size">
    </mat-paginator>
  </div>
</div>
