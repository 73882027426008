import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { DueDateInfo } from '../contract/due-date-info.interface';
import { RemainingValueType } from '../enums/remaining-value-type.enum';
import { WarningLevel } from '../enums/warning-level.enum';
import { LanguageService } from '../services/language.service';
import { DueDatePipe } from './due-date.pipe';
import { RemainingValueTypePipe } from './remaining-value-type.pipe';
import { DimensionUnitConverterPipe } from './dimension-unit-converter.pipe';

@Pipe({
  name: 'dueDateRemainingValue'
})
export class DueDateRemainingValuePipe implements PipeTransform {
  private overdueTranslationKey = 'shared.pipes.dueDateRemainingValue.formattedTextOverdue';
  private notOverdueTranslationKey = 'shared.pipes.dueDateRemainingValue.formattedTextNotOverdue';
  private emptyDate = '--.--.----';

  constructor(
    private languageService: LanguageService,
    private remainingValueTypePipe: RemainingValueTypePipe,
    private dueDatePipe: DueDatePipe,
    private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
  ) {}

  public transform(dueDateInfo: DueDateInfo): string {
    if (!dueDateInfo) {
      return null;
    }

    const datePart = this.getDatePart(dueDateInfo);
    const remainingValuePart = this.getRemainingValuePart(dueDateInfo);
    const measurePart = this.getMeasurePart(dueDateInfo);
    const overdue = dueDateInfo.warningLevel === WarningLevel.OVERDUE;

    return this.formatResultText(datePart, remainingValuePart, measurePart, overdue);
  }

  private getDatePart(dueDateInfo: DueDateInfo): string {
    return this.dueDatePipe.transform(dueDateInfo) || this.emptyDate;
  }

  private getRemainingValuePart({ dueDate, remainingValue, remainingValueType }: DueDateInfo): number {
    if (remainingValueType === RemainingValueType.DAYS) {
      return (this.getRemainingDays(dueDate) || remainingValue)
    } else if (remainingValueType === RemainingValueType.MILEAGE) {
      return this.dimensionUnitConverterPipe.toUserDimensionUnit(remainingValue, 'km', 0)
    } else {
      return remainingValue;
    }
  }

  private getRemainingDays(date: string): number {
    const dueDate = moment(date).startOf('day');
    const currentDate = moment().startOf('day');
    return dueDate.diff(currentDate, 'days') || null;
  }

  private getMeasurePart({ remainingValueType }: DueDateInfo): string {
    return this.remainingValueTypePipe.transform(remainingValueType);
  }

  private formatResultText(
    date: string,
    remainingValue: number,
    measure: string,
    overdue: boolean,
  ): string {
    return [
      date,
      _.isNil(remainingValue) || _.isNil(measure)
        ? null
        : `(${this.formatRemaining(remainingValue, measure, overdue)})`
    ]
    .filter(Boolean)
    .join(' ');
  }

  private formatRemaining(
    value: number,
    measure: string,
    overdue: boolean,
  ): string {
    return this.languageService.getInstant(
      overdue ? this.overdueTranslationKey : this.notOverdueTranslationKey,
      { value: Math.abs(value), measure }
    );
  }

}
