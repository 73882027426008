import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { UserStatus } from 'app/shared/enums/user-status.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { HistoryTableColumn } from './history-table-column.enum';
import { HistoryTableRow } from './history-table-row.interface';

@Component({
  selector: 'bh-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryTableComponent<T> {
  @Input() public cellTemplate: TemplateRef<any>;
  @Input() public data: HistoryTableRow<T, T>[];
  @Input() public displayedColumns: HistoryTableColumn[] = [
    HistoryTableColumn.DATE,
    HistoryTableColumn.USER,
    HistoryTableColumn.FIELD,
    HistoryTableColumn.OLD,
    HistoryTableColumn.NEW,
  ];

  public readonly columnNames = HistoryTableColumn;
  public readonly currentLocale = this.languageService.getCurrentLocale();
  public readonly userStatus = UserStatus;

  constructor(private languageService: LanguageService) { }

}
