import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'qualification'
})

@Injectable()
export class QualificationPipe implements PipeTransform {
    transform(items: any[]) {
        items.sort((a, b) =>  a.qualificationName.localeCompare(b.qualificationName))
        return items;
    }
}
