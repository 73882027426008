import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { BaseBulkItemViewStocksComponent } from '../base/base-bulk-item-view-stocks.component';
import { StockService } from '../../../../../shared/services/stock.service';
import { BulkItemsService } from '../../../../../shared/bulk-items.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { ChangeAmountDialogComponent } from '../../../../../shared/change-amount-dialog/change-amount-dialog.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { AddressService } from '../../../../../../../shared/services/address.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { OrganisationInfoService } from '../../../../../shared/services/organisation-info.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-stocks',
  templateUrl: './bulk-item-view-stocks.component.html',
  styleUrls: ['./bulk-item-view-stocks.component.scss']
})
export class BulkItemViewStocksComponent extends BaseBulkItemViewStocksComponent implements OnInit, AfterViewInit {

  public readonly projectColumns: string[] = [
    'projectData',
    'time',
    'stock',
    'amount'
  ];

  public readonly faCalendar: IconDefinition = faCalendar;

  public editVisible = true;

  constructor(protected bulkItemStore: BulkItemDatasource,
              protected stockService: StockService,
              protected organisationsService: OrganisationInfoService,
              protected bulkItemsService: BulkItemsService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected focusMonitor: FocusMonitor,
              protected dialog: MatDialog,
              protected addressService: AddressService,
              protected routerHistory: RouterHistory) {
    super(bulkItemStore, stockService, organisationsService, bulkItemsService, route, authService,
      router, routerHistory);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.focusMonitor.stopMonitoring(document.getElementById('edit-button'));
  }

  public openAmountDialog(): void {
    let dialogRef = this.dialog.open(ChangeAmountDialogComponent);
    dialogRef.componentInstance.bulkItem = this.bulkItem;
    dialogRef.afterClosed().subscribe(() => {
      this.getStockAmounts();
    });
  }

  public navigateToStock(stockId: string) {
    this.router.navigate(['sites/stocks/list', stockId, 'inventory']);
  }

  public navigateToProject(projectId: string) {
    this.router.navigate(['sites/projects/list', projectId, 'assignments']);
  }

  public changeTab(event: MatTabChangeEvent) {
    if (event.index > 0) {
      this.editVisible = false;
    } else {
      this.editVisible = true;
    }
  }
}
