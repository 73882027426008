import { ExpandingTreeMapItem } from '../contracts/expanding-tree-map-item.class';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expandingTreeItems'
})
export class ExpandingTreeItemsPipe implements PipeTransform {
  transform(itemMap: Map<string, ExpandingTreeMapItem>, ids: string[]): ExpandingTreeMapItem[] {
    return ids.map(id => itemMap.get(id));
  }
}
