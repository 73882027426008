import { LanguageService } from './../../../../../../../shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { EquipmentPositionSources } from '../../../../../../../shared/contract/equipment-position-sources.enum';
import { EquipmentLastPosition } from '../../../../../../../shared/contract/equipment-last-position.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EquipmentLastPositionResolver } from '../../../../../../../shared/contract/equipment-last-position-resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-view-current-location',
  templateUrl: './mobile-equipment-view-current-location.component.html',
  styleUrls: ['./mobile-equipment-view-current-location.component.scss']
})
export class MobileEquipmentViewCurrentLocationComponent implements OnInit {

  public readonly faWarehouse: IconDefinition = faWarehouse;
  public readonly lastPositionConfigs = {
    'STOCK': {
      type: EquipmentPositionSources.STOCK,
      iconTooltip: this.translate('general.stock.s')
    },
    'DISPOSITION': {
      type: EquipmentPositionSources.DISPOSITION,
      icon: 'icon-disposition_outline',
      iconTooltip: this.translate('general.disposition.s')
    },
    'TELEMATIC': {
      type: EquipmentPositionSources.TELEMATIC,
      icon: 'icon-telematics_outline',
      iconTooltip: this.translate('general.liveData')
    }
  }

  public equipmentLastPosition: EquipmentLastPosition;

  constructor(public equipmentStore: EquipmentsDataSource, private languageService: LanguageService) { }

  ngOnInit() {
    this.getEquipment();
  }

  private getEquipment() {
    this.equipmentStore.currentEquipment
      .pipe(untilDestroyed(this))
      .subscribe(res => {
      if (res) {
        this.setEquipmentLastPosition(EquipmentLastPositionResolver.getEquipmentLastPosition(res));
      }
    });
  }

  public setEquipmentLastPosition(lastPosition: EquipmentLastPosition): void {
    this.equipmentLastPosition = lastPosition ? { ...lastPosition } : null;
  }

  public lastPositionStockName(): string {
    return this.equipmentLastPosition && this.equipmentLastPosition.stockName
      ? this.equipmentLastPosition.stockName
      : null;
  }

  public lastPositionProjectName(): string {
    return this.equipmentLastPosition && this.equipmentLastPosition.projectName
      ? this.equipmentLastPosition.projectName
      : null;
  }

  public lastPositionTelematicDate(): Date {
    return this.equipmentLastPosition && this.equipmentLastPosition.lastLocationTime
      ? this.equipmentLastPosition.lastLocationTime
      : null;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
