import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkItemsService } from '../../shared/bulk-items.service';
import { map } from 'rxjs/operators';
import { CustomerLabel } from '../../../../shared/contract/customer-label.interface';
import { OrganisationInfoService } from '../../shared/services/organisation-info.service';
import { OrganisationInfo } from '../../shared/services/organisation-info.interface';

@Injectable()
export class BulkItemFieldStore {

  private allCostTypes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private costTypeFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allCostCenters: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private costCenterFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allBglCodes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private bglCodeFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allCategories: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private categoryFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allManufacturers: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private manufacturerFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allSuppliers: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private supplierFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allOrganisations: BehaviorSubject<OrganisationInfo[]> = new BehaviorSubject<OrganisationInfo[]>([]);
  private organisationFilter: BehaviorSubject<string> = new BehaviorSubject('');

  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  private _bulkItemLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public readonly filteredCustomerLabels: Observable<string[]> = combineLatest(this._labels, this._bulkItemLabels)
    .pipe(map(([availableLabels, selectedLabels]) =>
      availableLabels.map(label => label.name)
        .filter(label => !selectedLabels.includes(label))
    ));

  public filteredCostTypes: Observable<string[]> = combineLatest(
    this.allCostTypes,
    this.costTypeFilter
  ).pipe(
    map(([types, filterTerm]) =>
      types.filter((type: string) => {
        const searchTerm = type.toLowerCase();
        return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
      })));

  public filteredSuppliers: Observable<string[]> = combineLatest(
    this.allSuppliers,
    this.supplierFilter
  ).pipe(
      map(([suppliers, filterTerm]) =>
        suppliers.filter((supplier: string) => {
          const searchTerm = supplier.toLowerCase();
          return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
        })));

  public filteredManufacturers: Observable<string[]> = combineLatest(
    this.allManufacturers,
    this.manufacturerFilter
  ).pipe(
      map(([manufacturers, filterTerm]) =>
        manufacturers.filter((manufacturer: string) => {
          const searchTerm = manufacturer.toLowerCase();
          return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
        })));

  public filteredCostCenters: Observable<string[]> = combineLatest(
    this.allCostCenters,
    this.costCenterFilter
  ).pipe(
    map(([centers, filterTerm]) =>
      centers.filter((center: string) => {
        const searchTerm = center.toLowerCase();
        return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
      })));

  public filteredBglCodes: Observable<string[]> = combineLatest(
    this.allBglCodes,
    this.bglCodeFilter
  ).pipe(
    map(([codes, filterTerm]) =>
      codes.filter((code: string) => {
        const searchTerm = code.toLowerCase();
        return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
      })));

  public filteredCategories: Observable<string[]> = combineLatest(
    this.allCategories,
    this.categoryFilter
  ).pipe(
    map(([categories, filterTerm]) =>
      categories.filter((category: string) => {
        const searchTerm = category.toLowerCase();
        return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
      })));

  public readonly filteredOrganisations: Observable<OrganisationInfo[]> = combineLatest(
    this.allOrganisations,
    this.organisationFilter
  ).pipe(
    map(([organisations, filterTerm]) =>
      organisations.filter((organisation: OrganisationInfo) => {
        const searchTerm = organisation.name.toLowerCase();
        return !filterTerm || searchTerm.indexOf(filterTerm.toLowerCase()) !== -1;
      })));

  constructor(private bulkItemService: BulkItemsService,
              private organisationsService: OrganisationInfoService) {
  }

  loadAllFields(): void {
    this.loadCostTypes();
    this.loadCostCenters();
    this.loadBglCodes();
    this.loadCategories();
    this.loadManufacturers();
    this.loadSuppliers();
    this.getCustomerLabels();
    this.loadOrganisations();
    // Necessary to leave old filter state
    this.filterOrganisations('');
    this.filterSuppliers('');
    this.filterManufacturer('');
    this.filterCategories('');
    this.filterBglCodes('');
    this.filterCostCenters('');
    this.filterCostTypes('');
  }

  public filterCostTypes(value: string): void {
    this.costTypeFilter.next(value);
  }

  public getCustomerLabels(): Observable<CustomerLabel[]> {
    this.bulkItemService
      .getCustomerLabels()
      .subscribe((labels: CustomerLabel[]) => this._labels.next(labels));

    return this.labels;
  }

  public filterSuppliers(value: string): void {
    this.supplierFilter.next(value);
  }

  public filterManufacturer(value: string): void {
    this.manufacturerFilter.next(value);
  }

  public filterCategories(value: string): void {
    this.categoryFilter.next(value);
  }

  public filterCostCenters(value: string): void {
    this.costCenterFilter.next(value);
  }

  public filterBglCodes(value: string): void {
    this.bglCodeFilter.next(value);
  }

  public filterOrganisations(value: string): void {
    this.organisationFilter.next(value);
  }

  private loadBglCodes(): void {
    this.bulkItemService
      .getBGLCodes()
      .subscribe(codes => {
        this.allBglCodes.next(codes.filter(code => code !== ''));
      });
  }

  private loadOrganisations(): void {
    this.organisationsService.getOrganisations()
      .subscribe(orgas => {
        this.allOrganisations.next(orgas);
      });
  }

  private loadCostTypes(): void {
    this.bulkItemService
    .getCostTypes()
    .subscribe(types => {
      this.allCostTypes.next(types.filter(type => type !== ''));
    });
  }

  private loadSuppliers(): void {
    this.bulkItemService
    .getSuppliers()
    .subscribe(suppliers => {
      this.allSuppliers.next(suppliers.filter(supplier => supplier !== ''));
    });
  }

  private loadManufacturers(): void {
    this.bulkItemService
    .getManufacturers()
    .subscribe(manufacturers => {
      this.allManufacturers.next(manufacturers.filter(manufacturer => manufacturer !== ''));
    });
  }

  private loadCostCenters(): void {
    this.bulkItemService
      .getCostCenters()
      .subscribe(costCenters => {
        this.allCostCenters.next(costCenters.filter(costCenter => costCenter !== ''));
      });
  }

  private loadCategories(): void {
    this.bulkItemService
      .getCategories()
      .subscribe(categories => {
        this.allCategories.next(categories.filter(category => category !== ''));
      });
  }
}
