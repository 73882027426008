import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { KeycloakService } from '../../core/keycloak';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavService } from '../navigation/sidenav/shared/sidenav.service';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import { ChatListComponent } from './chat-list/landscape/chat-list.component';
import { CommunicationService } from './shared/communication.service';
import { CommunicationDataSource } from './shared/communication.datasource';
import { FormsModule } from '@angular/forms';
import { mobileChatRouting } from './routing/mobile-chat.routing';
import { chatRouting } from './routing/chat.routing';
import { ChatContainerComponent } from './chat.container.component';
import { ChatComponent } from './chat/landscape/chat.component';
import { MobileChatComponent } from './chat/mobile/mobile-chat.component';
import { MobileChatListComponent } from './chat-list/mobile/mobile-chat-list.component';
import { MobileChatContainerComponent } from './mobile-chat.container.component';
import { ChatUserStatusPipe } from './shared/chat-user-status.pipe';


@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    FlexLayoutModule,
    MainPipeModule,
    FormsModule,
    TranslateModule,
    mobileChatRouting,
    chatRouting
  ],
  declarations: [
    ChatListComponent,
    MobileChatContainerComponent,
    MobileChatListComponent,
    MobileChatComponent,
    ChatContainerComponent,
    ChatComponent,
    ChatUserStatusPipe
  ],
  exports: [
    ChatListComponent,
    MobileChatListComponent,
    ChatUserStatusPipe
  ],
  providers: [
    KeycloakService,
    SidenavService,
    CommunicationService,
    CommunicationDataSource
  ]
})
export class ChatModule {
}
