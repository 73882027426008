import { Address } from '../../organisation/contract/address.interface';
import { TransferItemType } from '../shared/transfer/model/transfer-item-type.enum';
import { BulkItemType } from './bulk-item-type';
import { TransferItemUnit } from './transfer-item-unit';
import { TypeOfUseStatusReason } from '../shared/enums/type-of-use-status-reason.enum';
import { TypeOfUseStatus } from '../shared/enums/type-of-use-status.enum';

export interface ViewProjectAmount {
  amountId?: string;
  currentUserHasAccessToEquipment?: boolean;
  projectId?: string;
  projectName?: string;
  projectStart?: Date;
  projectEnd?: Date;
  projectAddress?: Address;
  transferItemId?: string;
  transferItemName?: string;
  transferItemUnit?: TransferItemUnit;
  transferItemType?: TransferItemType;
  transferItemCostCenter?: string;
  transferredBy?: string;
  transferCreatedDate?: Date;
  transferDate?: Date;
  bulkItemType?: BulkItemType;
  transferItemNumber?: string;
  stockId?: string;
  stockName?: string;
  stockOrganisationId?: string;
  amount?: number;
  amountAvailable?: number;
  amountReservedForRequest?: number;
  unknownStock?: boolean;
  unknownProject?: boolean;
  thumbnailKey?: string;
  thumbnailUrl?: string;
  equipmentContainerId?: string;
  equipmentCustomerSerialNumber?: string;
  inChargeAtRequestDate?: boolean;
  currentChargePeriod?: IAmountStatusPeriod;
  amountStatusPeriods?: Record<string, IAmountStatusPeriod>;
  currentProjectId?: string;
  lastTransferDate?: Date;
}

export interface IAmountStatusPeriod {
  amountAtStatus: number;
  amountStatusPeriodId: string;
  comment: string;
  reason: TypeOfUseStatusReason;
  status: TypeOfUseStatus;
  statusEndDate: Date;
  statusStartDate: Date;
  transferFromStateId: string;
  transferToStateId: string;
}

export interface IAmountStatusPeriodWithId extends IAmountStatusPeriod {
  id: string;
}

export function instanceOfViewProjectAmount(object: any): object is ViewProjectAmount {
  return 'amount' in object && 'bulkItemId' in object && 'stockId' in object && 'projectId' in object;
}
