import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AdditionalFieldValue } from '../additional-field';
import { ControlErrorMessage } from '../../../types/control-error-message.interface';
import { FieldLimit } from '../../../../../shared/enums/fieldLimit.enum';
import { KeyCodes } from '../../../../../shared/enums/key-codes.enum';

@UntilDestroy()
@Component({
  selector: 'bh-additional-field-inline',
  templateUrl: './additional-field-inline.component.html',
  styleUrls: ['./additional-field-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalFieldInlineComponent implements OnInit {

  @Input() field: AdditionalFieldValue;
  @Input() errorMessages: ControlErrorMessage;
  @Input() editable = true;
  @Output() editField = new EventEmitter<AdditionalFieldValue>();

  public readonly = true;
  public control: UntypedFormControl = new UntypedFormControl(null, [Validators.maxLength(FieldLimit.SHORT_TEXT)]);

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setControlValue();
  }

  private setControlValue(): void {
    const {value, additionalFieldDefaultValue} = this.field;
    this.control.patchValue(value ?? additionalFieldDefaultValue);
  }

  public edit(): void {
    this.readonly = false;
    this.cdr.markForCheck();
  }

  public save(): void {
    if (this.control.valid) {
      this.readonly = true;
      this.editField.emit({...this.field, value: this.control.value});
    }
    this.cdr.markForCheck();
  }

  public cancel(): void {
    this.setControlValue();
    this.readonly = true;
    this.cdr.markForCheck();
  }

  public onKeyPress(event: KeyboardEvent): void {
    if (!this.readonly && this.editable) {
      if (event.keyCode === KeyCodes.ESC_KEY) {
        event.preventDefault();
        event.stopPropagation();
        this.cancel();
      } else if (event.keyCode === KeyCodes.ENTER_KEY) {
        this.save();
      }
    }
  }
}
