<div class="dialog-wrapper">
  <div class="title" fxLayout="column">
    <span>{{ bulkItemAmount.bulkItemName }}</span>
  </div>
  <div class="content-wrapper">
    <form [formGroup]="form">
      <div class="content" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="5px">
        <button mat-button (click)="minus()" fxFlex="15">
          <fa-icon [icon]="faMinus"></fa-icon>
        </button>
        <input matInput type="number" [min]="0" [max]="getMaximumAmount()"
               formControlName="amount" fxFlex="70" [ngClass]="{'amount-input': true,
            'error-input': amount.hasError('numberBigger') || amount.hasError('max')}">
        <button mat-button (click)="plus()" fxFlex="15">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </form>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">
      <div class="amounts">
        <div *ngIf="amount.hasError('max') || amount.hasError('numberBigger')" class="amount-error">
          {{'modules.disposition.transferAmountInput.invalidNumber'|translate}}
        </div>
        <div *ngIf="amount.valid">{{ amount.value }}</div>
        <div class="start" *ngIf="amount.valid">{{'modules.disposition.transferAmountInput.selected'|translate}}</div>
      </div>
      <fa-icon [icon]="faArrowRight" class="amount-arrow"></fa-icon>
      <div class="amounts">
        <div>{{ amount.hasError('max') || amount.hasError('numberBigger') ? ' - ' : bulkItemAmount.maxAmount - amount.value }}</div>
        <div class="end">{{'modules.disposition.transferAmountInput.available'|translate}}</div>
      </div>
    </div>
    <div class="footer" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">
      <button mat-raised-button mat-dialog-close color="accent" fxFlex="45">
        {{'general.buttons.cancel'|translate}}
      </button>
      <button mat-raised-button [matDialogClose]="amount.value" color="primary" fxFlex="45"
              [disabled]="amount.hasError('numberBigger') || amount.hasError('max') || !amount.value">
        {{'general.buttons.save'|translate}}
      </button>
    </div>
  </div>
</div>
