import { faChartMixed, faPenField, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseSidenavComponent } from '../base/base-sidenav.component';
import { SidenavService } from '../shared/sidenav.service';
import { MessagingService } from '../../../../shared/messaging.service';
import { TabService } from '../../../../shared/tab.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faExchange, faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import { CustomDeviceDetectorService } from '../../../../shared/services/custom-device-detector.service';
import { CustomersService } from '../../../organisation/shared/customers.service';
import { MatomoTracker } from 'ngx-matomo';
import { ListType } from '../../../../shared/enums/list-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Authorities } from '../../../../shared/enums/authorities.enum';

@UntilDestroy()
@Component({
  selector: 'bh-sidenav',
  templateUrl: 'sidenav.component.html',
  styleUrls: ['sidenav.component.scss']
})
export class SidenavComponent extends BaseSidenavComponent implements OnInit {

  items = {
    ANALYTICS: '/analytics',
    DASHBOARD: '/dashboard',
    EQUIPMENTS: '/assets',
    PROJECT: '/sites',
    TRANSPORTATION: '/transportation',
    DISPOSITION: '/disposition/list',
    RENTAL: '/rental/list',
    NOTIFICATION: '/notifications/list',
    REPORTS_VIEW: '/reports/view',
    MAINTENANCE_TASKS: '/maintenance/tasks/maintenance-list',
    TRANSFER: '/transfer',
    MENU: {
      USERS: '/users/list',
      ROLES: '/roles/list',
      CUSTOMERS: '/customers/list',
      SUB_CUSTOMERS: '/customers/sub-customers/list',
      PARTNERS: '/partners/list',
      ORGANIZATIONS: '/organisations/list',
      TECHNICAL_FIELDS: '/technicalfields/list',
      MANUFACTURERS: '/manufacturers/list',
      CONNECTORS: '/connectors/list',
      REPORTS: '/reportdefinitions/list',
      FIELD_MANAGEMENT: '/field-management',
      SETTINGS: '/users/settings',
      MAINTENANCE_RULES: '/maintenance/rules/list',
      MAINTENANCE_TYPES: '/maintenance/types/list',
      TELEMATIC_UNITS: '/telematic-unit/list',
      TELEMATIC_UNITS_LIST: '/telematic-unit-administration/list',
      API_KEYS: '/api-keys',
      TELEMATIC_UNITS_PROFILES: '/telematic-unit-profile/list'
    }
  };

  public readonly faExchange: IconDefinition = faExchange;
  public readonly faShoppingCart: IconDefinition = faShoppingCart;
  public readonly faTruck: IconDefinition = faTruck;
  public readonly faPenField: IconDefinition = faPenField;
  public readonly faChartMixed: IconDefinition = faChartMixed;
  private views: string[];

  constructor(protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected router: Router,
              protected sidenavService: SidenavService,
              protected deviceService: CustomDeviceDetectorService,
              protected messagingService: MessagingService,
              protected customersService: CustomersService,
              public tabService: TabService,
              protected matomoTracker: MatomoTracker
              ) {
    super(authService, dialog, router, sidenavService, messagingService, deviceService, customersService, matomoTracker);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initSubscription();
  }

  isMenuActive(): boolean {
    let menuItems: string[] = Object.keys(this.items.MENU);
    return menuItems.some(menuItem => this.router.url.includes(this.items.MENU[menuItem]));
  }

  isAllowedToSeeGeneralTab(): boolean {
    return this.views.includes(ListType.EQUIPMENTS) ||
      this.views.includes(ListType.BULK_ITEMS) ||
      this.views.includes(ListType.EMPLOYEES) ||
      this.views.includes(ListType.CONTACTS)
  }

  isAllowedToSeeProjectTab(): boolean {
    return this.views.includes(ListType.PROJECTS) ||
      this.views.includes(ListType.STOCKS)
  }

  isAllowedToSeeTransferTab(): boolean {
    return this.views.includes(ListType.EQUIPMENT_TRANSFER) ||
      this.views.includes(ListType.BULK_ITEM_TRANSFER)
  }

  getAvailableTab(location: string): string {
    switch (location) {
      case this.items.EQUIPMENTS:
        if (this.views.includes(ListType.EQUIPMENTS)) {
          return 'equipment';
        } else if (this.views.includes(ListType.BULK_ITEMS)) {
          return 'bulk-item';
        } else if (this.views.includes(ListType.EMPLOYEES)) {
          return 'employee';
        } else if (this.views.includes(ListType.CONTACTS)) {
          return 'contact';
        }
        break;
      case this.items.PROJECT:
        if (this.views.includes(ListType.PROJECTS)) {
          return 'projects';
        } else if (this.views.includes(ListType.STOCKS)) {
          return 'stocks';
        }
        break;
      case this.items.TRANSFER:
        if (this.views.includes(ListType.EQUIPMENT_TRANSFER)) {
          return 'equipment-transfer-list';
        } else if (this.views.includes(ListType.BULK_ITEM_TRANSFER)) {
          return 'bulk-item-transfer-list';
        }
        break;
      case this.items.ANALYTICS:
        if (this.views.includes(ListType.ANALYTICS_TRANSFER_LOG)) {
          return 'transfer-log';
        } else if (this.views.includes(ListType.ANALYTICS_USAGE_HISTORY)) {
          return 'usage-history';
        }
        break;
    }
  }

  private initSubscription(): void {
    this.tabService.views.pipe(untilDestroyed(this)).subscribe((viewsFromTabService: string[]) => {
      this.views = viewsFromTabService;
    });
  }

  public isVisibleFieldManagementMenu(): boolean {
    return this.hasAnyAuthority([
      Authorities.EQUIPMENT_STATUS_MANAGE,
      Authorities.ADDITIONAL_FIELD_VIEW
    ]);
  }
}
