<mat-expansion-panel #panel class="panel mb-5" [ngClass]="panelClass">
  <mat-expansion-panel-header class="panel-header" [expandedHeight]="'155px'" [collapsedHeight]="'155px'">
    <mat-panel-title>
      <div class="header-panel" fxLayout="row" fxFlex>
        <div class="p-10-20 br-1gl0" fxFlex="165px" fxLayout="column" fxLayoutAlign="center start">
          <span class="transfer-elements text-ellipsis fs-16 fw-500 mb-5">
              {{ transfer.transferItems?.length }}
            {{(transfer.transferItems?.length > 1
            ? 'general.element.pl'
            : 'general.element.s')|translate}}
          </span>
          <span class="text-ellipsis fs-13">
              {{ transfer.transferRequestDate | formatTimezoneDateTime: {showTime: true} }}
          </span>
          <span class="text-ellipsis fs-13" *ngIf="transfer.submittedBy"
                matTooltip=" {{ 'modules.disposition.projectTransferHistory.issuer' | translate:{issuer: transfer.submittedBy} }}">
            {{ 'modules.disposition.projectTransferHistory.issuer' | translate:{issuer: transfer.submittedBy} | customSlice:0:19 : '...'  }}
          </span>
        </div>

        <div class="text-ellipsis p-10-20" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="fs-18" fxFlex="20px" fxLayout="column">
            <fa-icon class="h-20" [icon]="sourceIcon"></fa-icon>
            <div class="h-20"></div>
            <fa-icon class="h-16" [icon]="faLongArrowAltDown"></fa-icon>
            <div class="h-20"></div>
            <fa-icon class="h-20" [icon]="targetIcon"></fa-icon>
            <div class="h-40"></div>
          </div>

          <div class="fs-13 fw-500" fxFlex="120px" fxLayout="column">
            <div class="text-ellipsis fw-500 h-20"> {{ sourceLabel }} </div>
            <div class="h-40"></div>
            <div class="h-16"></div>
            <div class="text-ellipsis fw-500 h-20"> {{ targetLabel }} </div>
            <div class="h-40"></div>
          </div>

          <div class="text-ellipsis fs-13" fxLayout="column">
            <ng-container *ngIf="groupedTransferItems.length > 1; then multipleSources else singleSource"></ng-container>
            <ng-template #multipleSources>
              <div class="h-20">{{ 'modules.disposition.bulkItemTransfer.differentLocations'|translate }}</div>
              <div class="h-40"></div>
            </ng-template>
            <ng-template #singleSource>
              <div class="text-ellipsis fw-500 h-20" [bhOverflowTooltip]="sourceName">{{ sourceName }}</div>
              <bh-project-number-and-cost-center class="h-40" fxLayoutAlign="start start"
                                                 [number]="sourceNumber"
                                                 [costCenter]="sourceCostCenter"
                                                 [isProjectNumberDisplay]="isSourceProjectNumberDisplay">
              </bh-project-number-and-cost-center>
            </ng-template>
            <div class="h-16"></div>
            <div class="text-ellipsis fw-500 h-20" [bhOverflowTooltip]="targetName">{{ targetName }}</div>
            <bh-project-number-and-cost-center class="h-40" fxLayoutAlign="start start"
                                               [number]="targetNumber"
                                               [costCenter]="targetCostCenter"
                                               [isProjectNumberDisplay]="isTargetProjectNumberDisplay">
            </bh-project-number-and-cost-center>
          </div>
        </div>

        <div>
          <div *ngIf="isPending else archived" fxLayout="row" fxFlex="nogrow" fxLayoutAlign="center center">
            <ng-container [ngTemplateOutlet]="downloadBtn"></ng-container>
            <button (click)="acknowledgeTransferRequest($event, transfer.transferId)"
                    [disabled]="transferRequestIsProcessing"
                    [class.disabled]="transferRequestIsProcessing"
                    class="transfer-request-button request-accept mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                    [matTooltip]="'general.labels.transferRequest.accept' | translate">
              <fa-icon [icon]="faCheck" class="accept-icon"></fa-icon>
            </button>
            <button (click)="declineTransferRequest($event, transfer.transferId)"
                    [disabled]="transferRequestIsProcessing"
                    [class.disabled]="transferRequestIsProcessing"
                    class="transfer-request-button request-decline mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                    [matTooltip]="'general.labels.transferRequest.decline' | translate">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>

          <ng-template #archived>
            <div *ngIf="isDeclined" fxFlex="nogrow" fxLayoutAlign="center center">
              <fa-icon [icon]="faTimes" class="declined-transfer-request"
                       [matTooltip]="'general.labels.transferRequest.declined' | translate">
              </fa-icon>
            </div>
          </ng-template>
          <ng-template #downloadBtn>
            <button fxLayoutAlign="center center"
                    class="mat-mdc-focus-indicator mat-mdc-raised-button mat-mdc-button-base mdc-button mdc-button--raised mat-unthemed custom-btn"
                    [matTooltip]="!downloading ? ('modules.disposition.projectTransferHistory.deliveryNote'|translate) : ''"
                    [class.download-disabled]="downloading"
                    [disabled]="downloading"
                    (click)="exportToPdf($event, transfer.transferId)">
              <fa-icon [icon]="faFilePdf"></fa-icon>
              <span class="button-label">
                <ng-container *ngIf="!downloading">{{'modules.disposition.projectTransferHistory.deliveryNote'|translate}}</ng-container>
                <ng-container *ngIf="downloading">{{'modules.disposition.projectTransferHistory.downloading'|translate}}</ng-container>
              </span>
            </button>
          </ng-template>
          <ng-template #finished>
            <div fxFlex="nogrow" fxLayoutAlign="center center">
              <ng-container [ngTemplateOutlet]="downloadBtn"></ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div *ngFor="let transferSource of groupedTransferItems; let groupIndex = index">
    <div class="p-10-20 bt-1gl0 br-1gl0" fxLayoutAlign="start center" fxLayoutGap="5px">
      <span class="mw-fc"> {{ sourceLabel }} </span>
      <span class="source-number" *ngIf="transferSource.sourceNumber"> # {{ transferSource.sourceNumber }}</span>
      <fa-icon [icon]="faWallet"></fa-icon>
      <span class="fw-300">{{ transferSource.sourceCostCenter ? transferSource.sourceCostCenter : '-' }}</span>
      <span class="text-ellipsis fw-500">{{ transferSource.sourceName }}</span>
      <mat-icon *ngIf="transferSource.sourceLocationIsAccessibleForUser"
                class="link-icon"
                [matTooltip]="transferSource.toolTip"
                [routerLink]="['/sites', transferSource.objects, 'list', transferSource.sourceId, 'general']">
        open_in_new
      </mat-icon>
    </div>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div *ngFor="let item of transferSource.items;let i = index"
           class="bt-1gl0 mh-100" fxLayoutAlign="stretch" fxFill>
        <div class="p-10-20 fs-16 br-1gl0" fxFlex="165px" fxLayoutAlign="center center">
          #{{ getAmountOfPreviousItems(groupIndex) + (i + 1) }}
        </div>

        <div class="p-10-20 br-1gl0 overflow-h" fxFlex fxLayout="column" fxLayoutAlign="start">
          <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <span class="text-ellipsis fw-500 fs-18">{{ item.transferItemName }}</span>
            <mat-icon *ngIf="item.itemIsAccessibleForUser"
                      class="link-icon"
                      [matTooltip]="'general.labels.jumpTo'|translate:{ value: (item.transferItemType | transferItem: 'TOOLTIP') }"
                      [routerLink]="(item.transferItemType | transferItem: 'URL') + item.transferItemId">
              open_in_new
            </mat-icon>
          </div>
          <span>{{ item.transferItemInternalNumber }}</span>
        </div>
        <div fxFlex="90px" fxLayout="column" fxLayoutAlign="center center">
          {{ item.amount }} <span class="fs-12">{{ item.transferItemUnit | dimensionUnitDisplay }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- BREAK HERE FROM index -->

  <div class="bt-1gl0 mh-100" fxFill>
    <div class="fs-16 ta-c br-1gl0" fxFlex="165px" fxLayoutAlign="center center">
      {{ 'modules.disposition.projectTransferHistory.additionalInformation' | translate }}
    </div>

    <div fxLayout="column" fxFlex="calc(100% - 165px)">
      <div fxLayout="row">
        <div class="p-10-20 br-1gl0" fxFlex="50">
            <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.internalEmployee' | translate }}:</span>
          <span *ngIf="internalEmployee as employeeName; else noAssignedEmployee">{{employeeName}}</span>
        </div>
        <div class="p-10-20" fxFlex="50">
            <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.externalEmployee' | translate }}:</span>
          <span *ngIf="transfer.externalEmployee as employeeName; else noAssignedEmployee">{{employeeName}}</span>
        </div>
      </div>

      <div *ngIf="isAcknowledged || isDeclined" class="bt-1gl0" fxLayout="row">
        <div class="p-10-20 br-1gl0" fxFlex="50">
              <span class="text-ellipsis">{{ (isAcknowledged
                ? 'modules.disposition.projectTransferHistory.transferAcceptanceDate'
                : 'modules.disposition.projectTransferHistory.transferDeclineDate') | translate }}:
              </span>
          {{ transfer.transferRequestStateChangeDate | formatTimezoneDateTime: {showTime: true} }}
        </div>
        <div class="p-10-20" fxFlex="50">
              <span class="text-ellipsis">{{ (isAcknowledged
                ? 'modules.disposition.projectTransferHistory.transferAcceptedBy'
                : 'modules.disposition.projectTransferHistory.transferDeclinedBy') | translate }}:
              </span>
          {{ transfer.statusChangedBy }}
        </div>
      </div>

      <div class="p-10-20 bt-1gl0" fxLayout="row" fxLayoutGap="10px">
        <div class="">{{ 'modules.disposition.projectTransferHistory.comment' | translate }}:</div>
        <div class="text-ellipsis">{{transfer.comment}}</div>
      </div>
    </div>
  </div>
</mat-expansion-panel>


<ng-template #noAssignedEmployee>
  <span>{{ 'modules.disposition.projectTransferHistory.noAssignedEmployee' | translate }}</span>
</ng-template>
