<mat-expansion-panel #panel class="panel" [ngClass]="panelClass">
  <mat-expansion-panel-header class="h-155 p-0">
    <mat-panel-title>
      <div class="header-panel" fxLayout="row" fxFlex>
        <div class="p-10-20 br-1gl0" fxFlex="165px" fxLayout="column" fxLayoutAlign="start start">
          <span class="transfer-elements fs-16 mb-5">
            {{ transferItems?.length }}
            {{(transferItems?.length > 1
                ? 'general.element.pl'
                : 'general.element.s')|translate}}
          </span>
          <span *ngIf="!transferDate && !transferCreateDate; else stockTmp" class="fs-13">
              {{ timeStamp | formatTimezoneDateTime: {showTime: true} }}
          </span>
          <ng-template #stockTmp>
            <span *ngIf="transferDate"
                  class="fs-13" fxFlex fxLayoutAlign="start start" fxLayoutGap="5px"
                  [matTooltip]="'modules.disposition.projectTransferHistory.transferDate'|translate"
                  [matTooltipPosition]="'above'">
              <fa-icon [icon]="faCalendarAlt"></fa-icon>
              <span>{{ transferDate | formatTimezoneDateTime: {showTime: true} }}</span>
            </span>
            <span *ngIf="transferCreateDate"
                  [matTooltip]="'modules.disposition.projectTransferHistory.creationDate'|translate"
                  [matTooltipPosition]="'below'"
                  class="fs-13" fxFlex fxLayoutAlign="start center" fxLayoutGap="5px">
              <fa-icon [icon]="faCalendarPlus"></fa-icon>
              <span>{{ transferCreateDate | formatTimezoneDateTime: {showTime: true} }}</span>
            </span>
          </ng-template>
          <span *ngIf="issuer"
                class="text-ellipsis fs-13"
                matTooltip=" {{ 'modules.disposition.projectTransferHistory.issuer' | translate:{issuer: issuer} }}">
            {{ 'modules.disposition.projectTransferHistory.issuer' | translate:{issuer: issuer} | customSlice:0:19 : '...'  }}
          </span>
        </div>

        <div class="text-ellipsis p-10-20" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="fs-18" fxFlex="20px" fxLayout="column">
            <fa-icon class="h-20" [icon]="sourceIcon"></fa-icon>
            <div class="h-20"></div>
            <fa-icon class="h-16" [icon]="faLongArrowAltDown"></fa-icon>
            <div class="h-20"></div>
            <fa-icon class="h-20" [icon]="targetIcon"></fa-icon>
            <div class="h-40"></div>
          </div>
          <div class="fw-500 fs-13" fxFlex="120px" fxLayout="column">
            <div class="text-ellipsis fw-500 h-20"> {{ sourceLabel }} </div>
            <div class="h-40"></div>
            <div class="h-16"></div>
            <div class="text-ellipsis fw-500 h-20"> {{ targetLabel }} </div>
            <div class="h-40"></div>
          </div>
          <div class="text-ellipsis fs-13" fxLayout="column">
            <ng-container *ngIf="groupedTransferItems.length > 1; then multipleSources else singleSource"></ng-container>
            <ng-template #multipleSources>
              <div class="h-20">{{ 'modules.disposition.bulkItemTransfer.differentLocations'|translate }}</div>
              <div class="h-40"></div>
            </ng-template>
            <ng-template #singleSource>
              <div class="text-ellipsis fw-500 h-20" [bhOverflowTooltip]="sourceName">{{ sourceName }}</div>
              <bh-project-number-and-cost-center class="h-40" fxLayoutAlign="start start"
                                                 [number]="sourceNumber"
                                                 [costCenter]="sourceCostCenter"
                                                 [isProjectNumberDisplay]="isSourceProjectNumberDisplay">
              </bh-project-number-and-cost-center>
            </ng-template>
            <div class="h-16"></div>
            <div class="text-ellipsis fw-500 h-20" [bhOverflowTooltip]="targetName">{{ targetName }}</div>
            <bh-project-number-and-cost-center class="h-40" fxLayoutAlign="start start"
                                               [number]="targetNumber"
                                               [costCenter]="targetCostCenter"
                                               [isProjectNumberDisplay]="isTargetProjectNumberDisplay">
            </bh-project-number-and-cost-center>
          </div>
        </div>

        <div *ngIf="isRequest else finished" fxLayoutAlign="center center">
          <div *ngIf="isPending else archived" fxLayout="row" fxFlex="nogrow" fxLayoutAlign="center center">
            <ng-container [ngTemplateOutlet]="downloadBtn"></ng-container>
            <button *ngIf="isPermissionForTransferRequests"
                    (click)="acknowledgeTransferRequest($event, transferId)"
                    [disabled]="transferRequestIsProcessing"
                    [class.disabled]="transferRequestIsProcessing"
                    class="transfer-request-button request-accept mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                    [matTooltip]="'general.labels.transferRequest.accept' | translate">
              <fa-icon [icon]="faCheck" class="accept-icon"></fa-icon>
            </button>
            <button *ngIf="isPermissionForTransferRequests"
                    (click)="declineTransferRequest($event, transferId)"
                    [disabled]="transferRequestIsProcessing"
                    [class.disabled]="transferRequestIsProcessing"
                    class="transfer-request-button request-decline mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                    [matTooltip]="'general.labels.transferRequest.decline' | translate">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>

          <ng-template #archived>
            <div *ngIf="isDeclined" fxLayout="row" fxFlex="nogrow" fxLayoutAlign="center center">
              <fa-icon [icon]="faTimes" class="declined-transfer-request"
                       [matTooltip]="'general.labels.transferRequest.declined' | translate">
              </fa-icon>
            </div>
          </ng-template>
        </div>

        <ng-template #downloadBtn>
          <button fxLayout="row" fxLayoutAlign="center center"
                  class="mat-mdc-focus-indicator mat-mdc-raised-button mat-mdc-button-base mdc-button mdc-button--raised mat-unthemed custom-btn"
                  [matTooltip]="!downloading ? ('modules.disposition.projectTransferHistory.deliveryNote'|translate) : ''"
                  [class.download-disabled]="downloading"
                  [disabled]="downloading"
                  (click)="exportToPdf($event, transferId)">
            <fa-icon [icon]="faFilePdf"></fa-icon>
            <span class="button-label">
              <ng-container *ngIf="!downloading">{{'modules.disposition.projectTransferHistory.deliveryNote'|translate}}</ng-container>
              <ng-container *ngIf="downloading">{{'modules.disposition.projectTransferHistory.downloading'|translate}}</ng-container>
            </span>
          </button>
        </ng-template>
        <ng-template #finished>
          <div fxFlex="nogrow" fxLayoutAlign="center center" fxLayoutGap="10px">
            <ng-container [ngTemplateOutlet]="downloadBtn"></ng-container>
            <div *ngIf="isProjectTransfersRevertAuthority"
                 [matTooltip]="rollbackTooltip">
              <button class="revert-transfer mat-primary mat-mdc-raised-button mdc-button mdc-button--raised custom-btn"
                      fxLayout="row" fxLayoutAlign="center center"
                      [disabled]="isRevertDisabled"
                      (click)="revertTransfer($event)">
                <fa-icon [icon]="faUndo"></fa-icon>
                <span class="button-label">{{'modules.disposition.projectTransferHistory.revertTransfer'|translate}}</span>
              </button>
            </div>
          </div>
        </ng-template>
      </div>

    </mat-panel-title>
  </mat-expansion-panel-header>

  <div *ngFor="let transferSource of groupedTransferItems; let groupIndex = index">
    <div class="p-10-20 bt-1gl0 br-1gl0" fxLayoutGap="5px" fxLayoutAlign="start center">
      <span class="full-width"> {{ sourceLabel }} </span>
      <span *ngIf="transferSource.sourceNumber" class="source-number"> # {{ transferSource.sourceNumber }}</span>
      <fa-icon [icon]="faWallet"></fa-icon>
      <span class="full-width">{{ transferSource.sourceCostCenter ? transferSource.sourceCostCenter : '-' }}</span>
      <span class="text-ellipsis fw-500">{{ transferSource.sourceName }}</span>
      <mat-icon *ngIf="transferSource.sourceLocationIsAccessibleForUser"
                class="link-icon"
                [matTooltip]="sourceTooltip"
                [routerLink]="['/sites', linkObjects, 'list', transferSource.sourceId, 'general']">
        open_in_new
      </mat-icon>
    </div>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div *ngFor="let item of transferSource.items; let i = index" fxLayoutAlign="stretch" fxFill class="mh-100 bt-1gl0">
        <div class="p-10-20 fs-16 br-1gl0" fxFlex="165px" fxLayoutAlign="center center">
          #{{ getAmountOfPreviousItems(groupIndex) + (i + 1) }}
        </div>

        <div class="p-10-20 br-1gl0 overflow-h" fxFlex fxLayout="column" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <span class="text-ellipsis fw-500 fs-18">{{ item.transferItemName }}</span>
            <div *ngIf="item.transferItemDeleted; else itemLink">
              <mat-icon class="h-16 fs-16 c-gd1"
                        [matTooltip]="'modules.disposition.projectTransferHistory.transferItemDeleted'|translate">
                delete_forever
              </mat-icon>
            </div>
          </div>
          <ng-template #itemLink>
            <mat-icon *ngIf="item.itemIsAccessibleForUser"
                      class="link-icon"
                      [routerLink]="(item.transferListItemType | transferItem: 'URL') + item.transferItemId"
                      [matTooltip]="'general.labels.jumpTo'|translate:{ value: (item.transferListItemType | transferItem: 'TOOLTIP') }">
              open_in_new
            </mat-icon>
          </ng-template>
          <div class="text-ellipsis"> {{ item.transferItemNumber }} </div>
        </div>
        <div fxFlex="90px" fxLayout="column" fxLayoutAlign="center center">
          {{ item.transferItemAmount | dimensionUnitConverter: item.transferItemUnit: 3 }}
          <span class="fs-13">{{ item.transferItemUnit | dimensionUnitDisplay }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="mh-100 bt-1gl0" fxLayout="row" fxFill>
    <div class="fs-16 ta-c br-1gl0" fxFlex="165px" fxLayoutAlign="center center">
      {{ 'modules.disposition.projectTransferHistory.additionalInformation' | translate }}
    </div>

    <div fxLayout="column" fxFlex="calc(100% - 165px)">
      <div fxLayout="row">
        <div class="p-10-20 br-1gl0" fxFlex="50">
          <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.internalEmployee' | translate }}:</span>
          <span *ngIf="internalEmployee as employeeName; else noAssignedEmployee">{{employeeName}}</span>
        </div>
        <div class="p-10-20" fxFlex="50">
          <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.externalEmployee' | translate }}:</span>
          <span *ngIf="externalEmployee as employeeName; else noAssignedEmployee">{{employeeName}}</span>
        </div>
      </div>

      <div *ngIf="!isRequest && (acknowledgeDate || acknowledgedBy); else requestState" class="bt-1gl0" fxLayout="row">
        <div class="p-10-20 br-1gl0" fxFlex="50">
          <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.acceptDate' | translate }}:</span>
          {{ acknowledgeDate | formatTimezoneDateTime: {showTime: true} }}
        </div>
        <div class="p-10-20" fxFlex="50">
          <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.acceptUser' | translate }}:</span>
          {{acknowledgedBy}}
        </div>
      </div>
      <ng-template #requestState>
        <div *ngIf="isAcknowledged || isDeclined" class="bt-1gl0" fxLayout="row">
          <div class="p-10-20 br-1gl0" fxFlex="50">
            <span class="text-ellipsis">
              {{ (isAcknowledged
              ? 'modules.disposition.projectTransferHistory.transferAcceptanceDate'
              : 'modules.disposition.projectTransferHistory.transferDeclineDate') | translate }}:
            </span>
            {{ transferRequestStateChangeDate | formatTimezoneDateTime: {showTime: true} }}
          </div>
          <div class="p-10-20" fxFlex="50">
            <span class="text-ellipsis">
              {{ (isAcknowledged
              ? 'modules.disposition.projectTransferHistory.transferAcceptedBy'
              : 'modules.disposition.projectTransferHistory.transferDeclinedBy') | translate }}:
            </span>
            {{ transferRequestChangedBy }}
          </div>
        </div>

        <div *ngIf="acknowledgeDate || acknowledgedBy" class="bt-1gl0" fxLayout="row">
          <div class="p-10-20 br-1gl0" fxFlex="50">
            <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.transferAcceptanceDate' | translate }}:</span>
            {{ acknowledgeDate | formatTimezoneDateTime: {showTime: true} | defaultValue : '-' }}
          </div>
          <div class="p-10-20" fxFlex="50">
            <span class="text-ellipsis">{{ 'modules.disposition.projectTransferHistory.transferAcceptedBy' | translate }}:</span>
            {{ acknowledgedBy | defaultValue : '-' }}
          </div>
        </div>
      </ng-template>

      <div class="p-10-20 bt-1gl0" fxLayout="row" fxLayoutGap="10px">
        <div>{{ 'modules.disposition.projectTransferHistory.comment' | translate }}:</div>
        <div class="text-ellipsis">{{comment}}</div>
      </div>
    </div>
  </div>
</mat-expansion-panel>


<ng-template #noAssignedEmployee>
  <span>{{ 'modules.disposition.projectTransferHistory.noAssignedEmployee' | translate }}</span>
</ng-template>
