import { SearchUser } from '../../userrole/contract/search-user.interface';

export class EmailRecipient {

  userId: string;
  name: string;

  public static from(user: SearchUser): EmailRecipient {
    return new EmailRecipient(
        user.userId,
        [user.firstName, user.name].filter(Boolean).join(' ')
    )
  }

  private constructor(userId: string, name: string) {
    this.userId = userId;
    this.name = name;
  }

}
