export enum AlarmType {
  OPERATING_HOURS = 'OPERATING_HOURS',
  OPERATING_HOURS_CONTRACT = 'OPERATING_HOURS_CONTRACT',
  TANK_FILL_LEVEL = 'TANK_FILL_LEVEL',
  FAULTS = 'FAULTS',
  TOTAL_COSTS = 'TOTAL_COSTS',
  DAMAGE_COSTS = 'DAMAGE_COSTS',
  REPAIR_COSTS = 'REPAIR_COSTS',
  GUARANTEE = 'GUARANTEE',
  FULL_SERVICE_CONTRACT_END = 'FULL_SERVICE_CONTRACT_END',
  LEASING_SERVICE_CONTRACT_END = 'LEASING_SERVICE_CONTRACT_END',
  GEOFENCE_VIOLATION = 'GEOFENCE_VIOLATION',
  GLOBAL_GEOFENCE_VIOLATION = 'GLOBAL_GEOFENCE_VIOLATION',
  TIMEFENCING = 'TIMEFENCING',
  TRANSPORT_TASK_CREATION = 'TRANSPORT_TASK_CREATION',
  TRANSPORT_TASK_RETURNED = 'TRANSPORT_TASK_RETURNED',
  NEW_DAMAGE_ADDED = 'NEW_DAMAGE_ADDED',
  INACTIVE_TELEMATICS_UNIT = 'INACTIVE_TELEMATICS_UNIT',
  BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
  DEF_TANK_LEVEL = 'DEF_TANK_LEVEL',
  TELEMATICS_UNIT_BATTERY_STATUS = 'TELEMATICS_UNIT_BATTERY_STATUS',
}
