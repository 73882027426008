import { ViewEmployeeProjectAssignment } from 'app/modules/disposition/contract/view-employee-project-assignment.interface';
import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { LanguageService } from 'app/shared/services/language.service';

@Pipe({
  name: 'formatAssignmentEmployeeProjectDateRange'
})
export class FormatAssignmentEmployeeProjectDateRangePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(assignment: ViewEmployeeProjectAssignment, format = 'DD.MM.YYYY'): string {
    if (assignment && (assignment.assignmentStartDate || assignment.assignmentEndDate)) {
      return (assignment.assignmentStartDate
        ? moment(assignment.assignmentStartDate).format(format)
        : this.translate('general.labels.openStart'))
      + ' - '
      + (assignment.assignmentEndDate
        ? moment(assignment.assignmentEndDate).format(format)
        : this.translate('general.labels.openEnd'));
    } else {
      return '-';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
