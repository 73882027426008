<div fxLayout="column" fxFlexFill>
  <div #scheduler
    class="dhx_cal_container"
    [ngClass]="{
      'drop-container': isDragging | async,
      'small-width': (schedulerDayViewColumnWidthPx | async) < schedulerDayViewColumnSmallWidthPx
    }">
    <div class="dhx_cal_navline">
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
        <div class="dhx_cal_tabs" fxLayoutAlign="start center" fxFlex="0 1 auto">
          <div class="dhx_cal_tab" name="transport_timeline_month_tab"></div>
          <div class="dhx_cal_tab" name="transport_timeline_week_tab"></div>
          <div class="dhx_cal_tab" name="transport_timeline_day_tab"></div>
        </div>
        <div class="dhx_cal_date"></div>
        <div class="dhx_custom_cal_date" fxFlex fxLayout="row" fxLayoutAlign="center center">
          {{ timelineDateHeader }}
        </div>
        <div fxLayoutAlign="end center">
          <div class="dhx_cal_today_button"></div>
          <div class="dhx_cal_prev_button"></div>
          <div class="dhx_cal_next_button"></div>
          <ng-container *ngIf="isDaySelected()">
            <button mat-raised-button
                    (click)="archiveAll()"
                    class="done-to-archive-button"
                    color="primary">
                <span>
                  {{'modules.transportation.transportStateChange.doneToArchiveBulk'|translate}}
                </span>
            </button>

            <button mat-button class="done-to-archive-icon"
                    (click)="archiveAll()">
              <fa-icon [icon]="faBoxArchive"
                       [matTooltip]="'modules.transportation.transportStateChange.doneToArchiveBulk'|translate"
              ></fa-icon>
            </button>
          </ng-container>
          <bh-page-switcher [active]="pageType.TRANSPORT_BOARD"></bh-page-switcher>
        </div>
      </div>
    </div>

    <div class="dhx_cal_header"
      bhSchedulerDragHighlighter
      [highlightClassName]="'dhx_custom_highlight'"
      [date]="mousePositionDate | async"
      [highlightClassNamePredicate]="highlightPredicate | async">
    </div>

    <div [id]="containerIdentifiers.TRANSPORT_SCHEDULER"
      class="scheduler-drop-container"
      cdkDropList
      [cdkDropListConnectedTo]="containerIdentifiers.TRANSPORT_TASK"
      (cdkDropListDropped)="dropContainerDropped($event)"
      (cdkDropListExited)="dropContainerExited()">
      <div *ngIf="isDataLoading | async" class="spinner-wrapper">
        <mat-spinner class="spinner-update" [diameter]="120"></mat-spinner>
      </div>
      <div class="dhx_cal_data"></div>
    </div>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="end center">
    <mat-paginator
      (page)="onPaginateChange($event)"
      [length]="pagination.totalElements"
      [pageIndex]="pagination.index"
      [pageSizeOptions]="[5, 25, 50, 100]"
      [pageSize]="pagination.size">
    </mat-paginator>
  </div>
</div>
