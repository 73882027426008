<mat-form-field floatLabel="always">
  <mat-label>{{'general.name.s'|translate}}</mat-label>
  <div class="input-container">
    <div class="input-wrapper">
      <input matInput
             bhAutoSizeInput [minWidth]="5"
             bhRemoveWhitespaces
             [readonly]="readonly"
             [formControl]="inputControl"
             (keydown.enter)="save()"
             (keydown.escape)="cancel()">
    </div>

    <span *ngIf="!readonly" class="file-extension">{{fileExtensionPart}}</span>
  </div>

  <mat-error *ngIf="inputControl.hasError('minlength') || inputControl.hasError('required')">
    {{ 'shared.validation.string.minLength'|translate:{value: 1} }}
  </mat-error>
  <mat-error *ngIf="inputControl.hasError('maxlength')">
    {{ 'shared.validation.string.maxLength'|translate:{value: maxFileNameLength} }}
  </mat-error>
  <mat-error *ngIf="inputControl.hasError('pattern')">
    {{ 'shared.validation.filename.pattern'|translate }}
  </mat-error>

  <mat-hint *ngIf="showHint">{{ hint }}</mat-hint>

  <mat-icon matSuffix
            class="bh-mat-icon"
            *ngIf="readonly && editable"
            (click)="edit()"
            [matTooltip]="'general.buttons.change'|translate">
    mode_edit
  </mat-icon>
  <mat-icon matSuffix
            class="bh-mat-icon"
            *ngIf="!readonly && inputControl.valid"
            (click)="save()"
            [matTooltip]="'general.buttons.save'|translate">
    check
  </mat-icon>
  <mat-icon matSuffix
            class="bh-mat-icon hover-red"
            *ngIf="!readonly"
            (click)="cancel()"
            [matTooltip]="'general.buttons.cancel'|translate">
    close
  </mat-icon>
</mat-form-field>
