import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class NavigationMapperService {
  private urlCommandsMapper = {
    equipmentViewDetailsUrl: (id: string) => ['assets', 'equipment', 'list', id, 'general'],
    projectViewDetailsUrl: (id: string) => ['sites', 'projects', 'list', id, 'general'],
    contactViewDetailsUrl: (id: string) => ['assets', 'contact', 'list', id],
    stockViewDetailsUrl: (id: string) => ['sites', 'stocks', 'list', id, 'general']
  }

  constructor(private router: Router) {}

  public navigateToEquipmentViewDetails(id: string): Promise<boolean> {
    return this.router.navigate(this.urlCommandsMapper.equipmentViewDetailsUrl(id));
  }

  public navigateToProjectViewDetails(id: string): Promise<boolean> {
    return this.router.navigate(this.urlCommandsMapper.projectViewDetailsUrl(id));
  }

  public navigateToContactViewDetails(id: string): Promise<boolean> {
    return this.router.navigate(this.urlCommandsMapper.contactViewDetailsUrl(id));
  }

  public navigateToStockViewDetails(id: string): Promise<boolean> {
    return this.router.navigate(this.urlCommandsMapper.stockViewDetailsUrl(id));
  }

}
