import { AlarmSettingsUtilsService } from './../../../alarm-settings-utils.service';
import { AlarmSettingsFieldData } from './../../../../contract/alarm-field-data.type';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AlarmSettingsBase } from '../alarm-settings-base.class';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bh-limit-percentage-settings',
  templateUrl: './limit-percentage-settings.component.html',
  styleUrls: ['./limit-percentage-settings.component.scss']
})
export class LimitPercentageSettingsComponent extends AlarmSettingsBase implements OnInit {
  @Input() set form(form: UntypedFormGroup) {
    this.settingsForm = form;
  }
  @Input() set fieldData(fieldData: AlarmSettingsFieldData) {
    this.alarmSettingsFieldData = fieldData;
  }

  constructor(private alarmSettingsUtilsService: AlarmSettingsUtilsService) {
    super();
  }

  public ngOnInit(): void {
    this.alarmSettingsUtilsService.alarmTypeChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => this.setDefaultValue());
  }

  public formatLabel(value: number): string {
    return `${value}%`;
  }

  private setDefaultValue(): void {
    this.limitControl.setValue((this.limitControl && this.limitControl.value) ? this.limitControl.value : 0);
  }
}
