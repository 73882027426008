import { environment } from 'environments/environment';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { PartnersService } from '../../shared/partners.service';
import { ViewPartner } from '../../contract/view-partner.interface';
import { PartnerViewComponent } from '../partner-view/partner-view.component';
import { PartnerDeleteDialogComponent } from '../../shared/partner-delete-dialog/partner-delete-dialog.component';
import { DeletePartnerCommand } from '../../contract/delete-partner-command';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime, delay, distinctUntilChanged, map } from 'rxjs/operators';
import {dialogResults} from '../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PartnerColumnService } from 'app/modules/disposition/shared/services/partner-column.service';

@UntilDestroy()
@Component({
  selector: 'bh-partner-list',
  templateUrl: 'partner-list.component.html',
  styleUrls: ['partner-list.component.scss']
})
export class PartnerListComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit {

  displayedColumns = [
    'partnerName',
    'partnerDetails'
  ];
  pagingPartnersDataSource: PagingPartnersDataSource | null;
  length: number;

  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private partnersService: PartnersService,
              private detector: ChangeDetectorRef,
              protected router: Router,
              protected route: ActivatedRoute,
              public columnService: PartnerColumnService,
              private dialog: MatDialog,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.columnPermissionHandler();
    this.getPartners();
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });
    this.filterEvent();
  }

  ngAfterViewInit() {
    this.pagingPartnersDataSource = new PagingPartnersDataSource(this.partnersService, this.paginator, this.columnService);

    // TODO: Remove this as it is a workaround to make the table visible when the page got reloaded
    this.detector.detectChanges();
  }

  public navigateToEditModules(partnerId: string): void {
    this.router.navigate([`partners/edit/${partnerId}/modules`]);
  }

  columnPermissionHandler() {
    if (this.hasAuthority(this.authorities.PARTNER_UPDATE)) {
      this.displayedColumns.push('partnerEdit');
    }

    if (this.hasAuthority(this.authorities.PARTNER_UPDATE) && this.hasAnyRole(this.priviledgedRole.Superadmin)) {
      this.displayedColumns.push('partnerEditModules');
    }

    if (this.hasAuthority(this.authorities.PARTNER_DELETE)) {
      this.displayedColumns.push('partnerDelete');
    }

    if (this.hasAuthority(this.authorities.PARTNERADMIN_CREATE)) {
      this.displayedColumns.push('partnerAddUser');
    }
  }

  filterEvent() {
    fromEvent(this.filter.nativeElement, 'keyup')
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(() => {
      if (!this.pagingPartnersDataSource) {
        return;
      }
      this.pagingPartnersDataSource.filter = this.filter.nativeElement.value;
    });
  }

  getPartners() {
    this.partnersService.getPartners(this.paginator.pageIndex, this.paginator.pageSize).subscribe(res => {
      this.length = res.totalElements;
      this.pagingPartnersDataSource.subject.next(res.content);
    });
  }

  onPaginateChange(event) {
    this.partnersService.getPartners(event.pageIndex, event.pageSize).subscribe(res => {
      this.length = res.totalElements;
      this.pagingPartnersDataSource.subject.next(res.content);
      this.columnService.selectColumns([], event.pageSize);
    });
  }

  deletePartner(partner: ViewPartner) {
    let dialogRef = this.dialog.open(PartnerDeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd = new DeletePartnerCommand();
        cmd.partnerId = partner.partnerId;
        this.partnersService.deletePartner(cmd)
        .pipe(delay(environment.DELAY_SHORT))
        .subscribe(() => {
              this.getPartners();
              this.router.navigate(['/partners/list']);
            },
            (error: HttpErrorResponse) => {
              console.log('Delete Partner error', error.message);
            }
        );
      }
    });
  }

  viewPartner(partner: ViewPartner) {
    const dialogRef = this.dialog.open(PartnerViewComponent, <MatDialogConfig>{
      width: '400px',
      height: '650px'
    });
    dialogRef.componentInstance.partnerId = partner.partnerId;
  }
}

export class PagingPartnersDataSource extends DataSource<any> {
  filteredData: ViewPartner[] = [];
  subject: BehaviorSubject<ViewPartner[]>;
  _filterChange = new BehaviorSubject('');

  constructor(
    private _partnersService: PartnersService,
    private _paginator: MatPaginator,
    public partnerColumnService: PartnerColumnService
  ) {
    super();
    this.getData();
    this.partnerColumnService.pageSize.subscribe((pageSize: number) => {
      this._paginator.pageSize = pageSize;
    });
  }

  public get pageIndex(): number {
    return this._paginator.pageIndex;
  }

  public get pageSize(): number {
    return this._paginator.pageSize;
  }

  get data(): ViewPartner[] {
    return this.subject.value;
  }

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  connect(): Observable<ViewPartner[]> {
    this.subject = new BehaviorSubject<ViewPartner[]>([]);
    const displayDataChanges = [
      this.subject,
      this._filterChange,
      this._paginator.page
    ];

    return merge(...displayDataChanges)
    .pipe(map(() => {
      this.filteredData = this.data.slice().filter((item: ViewPartner) => {
        let searchTerm = (item.partnerName).toLowerCase();
        return searchTerm.indexOf(this.filter.toLowerCase()) !== -1;
      });

      return this.filteredData.slice();
    }));
  }

  disconnect() {
    this.subject.complete();
    this.subject.observers = [];
  }

  private getData() {
    const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
    const pageSize = this._paginator.pageSize;
    this._partnersService.getPartners(startIndex, pageSize)
    .subscribe(res => {
      this.subject.next(res.content)
    });
  }
}
