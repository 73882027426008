<div class="mobile-content-header">
  {{'modules.disposition.bulkItemList.recordedBulkItems'|translate}}
</div>
<bh-mobile-swipe-to-options swipeLimitInPercent="40" fxLayout="column" *ngFor="let item of bulkItems"
                            class="option-line">
  <div fxLayout="row" fxLayoutGap="10px" class="item">
    <span class="item-type">
      <fa-icon [icon]="bulkItemTypeResolver.resolveIcon(item.bulkItemType)"></fa-icon>
    </span>
    <div fxLayout="column" class="bulk-item">
      <span class="item-name ellipsis">{{ item.bulkItemName }}</span>
      <span *ngIf="item.sourceStockId" class="ellipsis">
        {{'modules.disposition.bulkItemList.fromStock'|translate}}: {{ item.sourceStockName }}
      </span>
      <span *ngIf="!item.sourceStockId" class="ellipsis">{{ item.bulkItemCategory }}</span>
    </div>
    <div class="amount-options-container" fxFlex fxLayout="row" fxLayoutGap="5px">
      <div class="amount">
        x {{ item.amount }}
      </div>
      <div class="option-reveal" fxLayout="column">
        <mat-icon>dehaze</mat-icon>
      </div>
    </div>
  </div>
  <bh-swipe-option class="swipe-option" appendClass="blue" (onClick)="edit(item)">
    <fa-icon [icon]="faPencil"></fa-icon>
  </bh-swipe-option>
  <bh-swipe-option class="swipe-option" appendClass="red" (onClick)="delete(item)">
    <fa-icon [icon]="faTrash"></fa-icon>
  </bh-swipe-option>
</bh-mobile-swipe-to-options>
