<div class="disposition-board" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">

  <div class="disposition-board__left" fxFlex="400px" *ngIf="!dispositionStore.fullScreen">
    <bh-disposition-board-search></bh-disposition-board-search>
  </div>

  <mat-card class="disposition-board__right" fxFlex [ngSwitch]="dispositionStore.isTimeline">
    <bh-disposition-board-timeline *ngSwitchCase="true"></bh-disposition-board-timeline>
    <bh-disposition-board-list *ngSwitchCase="false"></bh-disposition-board-list>
  </mat-card>

</div>
