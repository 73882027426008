import { LanguageService } from 'app/shared/services/language.service';
import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Month } from '../../../../shared/contract/month';
import { months } from '../shared/months';
import { CrossFieldErrorMatcher } from '../../shared/validators/cross-field-error-matcher';
import { MaintenanceRule } from '../shared/contract/maintenance-rule';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscriptionCollector } from './subscription-collector.class';
import { IntervalPanelDisplayMode } from './interval-panel-display-mode.enum';
import { IntervalPanelInputType } from './interval-panel-input-type.enum';
import { IntervalsDurationEnum } from '../shared/intervals-duration.enum';
import { filter } from 'rxjs';

interface IntervalGroupForm extends UntypedFormGroup {
  controls: {
    mediumWarningThreshold?: UntypedFormControl;
    highWarningThreshold?: AbstractControl;
    activated?: AbstractControl;
    day?: AbstractControl;
    month?: AbstractControl;
    interval?: AbstractControl;
    intervalDuration?: AbstractControl;
  }
}

interface SimpleChangesPanelComponent extends SimpleChanges {
  maintenanceRule?: SimpleChange;
  intervalGroup?: SimpleChange;
  disabled?: SimpleChange;
  readonly?: SimpleChange;
  disablePanelToggle?: SimpleChange;
}

@UntilDestroy()
@Component({
  selector: 'bh-interval-panel',
  templateUrl: './interval-panel.component.html',
  styleUrls: ['./interval-panel.component.scss']
})
export class IntervalPanelComponent implements OnChanges {

  @Input() maintenanceRule: MaintenanceRule;
  @Input() intervalGroup: IntervalGroupForm;
  @Input() checkboxLabel: string;
  @Input() inputType: IntervalPanelInputType;
  @Input() inputLabel: string;
  @Input() inputSuffix: string;
  @Input() warningSuffix: string;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() disableAnimation = false;
  @Input() displayMode: IntervalPanelDisplayMode = IntervalPanelDisplayMode.STANDARD;
  @Input() nextFixedDateThreshold: string;
  @Input() nextPeriodThreshold: string;
  @Input() nextOperatingHoursThresholdDate: string;
  @Input() nextMileageThresholdDate: string;
  @Input() showNextDueDate = false;
  @Input() disablePanelToggle = false;

  public panelToggle = new UntypedFormControl();
  public months: Month[] = months(this.languageService.getCurrentLocale());
  public crossFieldDayMonthErrorMatcher: CrossFieldErrorMatcher = new CrossFieldErrorMatcher(['invalidDate', 'leapYear']);
  public crossFieldThresholdErrorMatcher: CrossFieldErrorMatcher = new CrossFieldErrorMatcher(['invalidUpper',
    'invalidMedium', 'invalidCombination']);
  public compareFn = (monthA: Month, monthB: Month) => monthA && monthB && monthA.value === monthB.value;
  public readonly inputTypes = IntervalPanelInputType;
  public readonly displayModes = IntervalPanelDisplayMode;
  public readonly intervalDurations = [
    IntervalsDurationEnum.DAYS,
    IntervalsDurationEnum.MONTHS,
    IntervalsDurationEnum.YEARS,
  ];

  private subCollector = new SubscriptionCollector();

  constructor(private languageService: LanguageService) {
  }

  public ngOnChanges({ maintenanceRule, intervalGroup, disabled, readonly, disablePanelToggle }: SimpleChangesPanelComponent): void {
    if (maintenanceRule || intervalGroup || disabled || readonly || disablePanelToggle) {
      this.updatePanel();
    }
  }

  private updatePanel(): void {
    this.updatePanelToggleValue();
    this.updateControlsAvailability();
    this.updateFormDefaults();
    this.updateFormSubscriptions();
  }

  private updatePanelToggleValue(): void {
    this.panelToggle.setValue(this.intervalGroup.controls.activated.value);
  }

  private updateControlsAvailability(): void {
    this.updatePanelToggleAvailability();
    this.updatePanelAvailability();
  }

  private updatePanelToggleAvailability(): void {
    if (this.disablePanelToggle || this.disabled) {
      this.panelToggle.disable();
    } else {
      this.panelToggle.enable();
    }
  }

  private updatePanelAvailability(): void {
    if (this.disabled || !Boolean(this.panelToggle.value)) {
      this.intervalGroup.disable();
    } else {
      this.intervalGroup.enable();
    }
  }

  private updateFormDefaults(): void {
    if (this.inputType === IntervalPanelInputType.PERIOD && !this.intervalDuration.value) {
      this.intervalDuration.setValue(IntervalsDurationEnum.MONTHS);
    }
  }

  private updateFormSubscriptions(): void {
    this.subCollector.unsubscribeAll();
    this.updatePanelToggleSubscription();
    if (this.inputType === IntervalPanelInputType.PERIOD) {

      this.subCollector.newSubscription = this.mediumWarningThreshold.valueChanges
        .pipe(
          filter(() => this.intervalGroup.enabled),
          untilDestroyed(this))
        .subscribe(() => this.highWarningThreshold.updateValueAndValidity({emitEvent: false}));

      this.subCollector.newSubscription = this.highWarningThreshold.valueChanges
        .pipe(
          filter(() => this.intervalGroup.enabled),
          untilDestroyed(this))
        .subscribe(() => this.mediumWarningThreshold.updateValueAndValidity({emitEvent: false}));
    }
  }

  private updatePanelToggleSubscription(): void {
    if (!this.disabled) {
      this.subCollector.newSubscription = this.panelToggle.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          if (value) {
            this.intervalGroup.enable();
          } else {
            this.intervalGroup.disable();
          }
        });
    }
  }

  public normalizePeriodValues(): void {
    const interval = IntervalPanelComponent.getIntervalDays(this.interval.value, this.intervalDuration.value);
    const mediumWarningThreshold: number = this.mediumWarningThreshold.value;
    const highWarningThreshold: number = this.highWarningThreshold.value;

    if (interval) {
      if (interval === 1) {
        this.mediumWarningThreshold.setValue(0);
      } else if (interval < mediumWarningThreshold || mediumWarningThreshold === 0) {
        this.mediumWarningThreshold.setValue(Math.min(30, interval));
      } else if (this.maintenanceRule && this.maintenanceRule.periodInterval) {
        this.mediumWarningThreshold.setValue(this.maintenanceRule.periodInterval.mediumWarningThreshold);
      }

      if (interval === 1) {
        this.highWarningThreshold.setValue(0);
      } else if (interval <= highWarningThreshold || highWarningThreshold === 0) {
        this.highWarningThreshold.setValue(Math.min(7, interval - 1));
      } else if (this.maintenanceRule && this.maintenanceRule.periodInterval) {
        this.highWarningThreshold.setValue(this.maintenanceRule.periodInterval.highWarningThreshold);
      }
    }
  }

  getCurrentDueDate(): string {
    if (this.nextFixedDateThreshold !== null && this.nextFixedDateThreshold !== undefined) {
      return this.nextFixedDateThreshold;
    }
    if (this.nextPeriodThreshold !== null && this.nextPeriodThreshold !== undefined) {
      return this.nextPeriodThreshold;
    }
    if (this.nextOperatingHoursThresholdDate !== null && this.nextOperatingHoursThresholdDate !== undefined) {
      return this.nextOperatingHoursThresholdDate;
    }
    if (this.nextMileageThresholdDate !== null && this.nextMileageThresholdDate !== undefined) {
      return this.nextMileageThresholdDate;
    }
    return null;
  }

  invalidDueDate(): boolean {
    return new Date(this.nextFixedDateThreshold).getFullYear() === 3000 ||
      new Date(this.nextPeriodThreshold).getFullYear() === 3000 ||
      new Date(this.nextOperatingHoursThresholdDate).getFullYear() === 3000 ||
      new Date(this.nextMileageThresholdDate).getFullYear() === 3000;
  }

  get day(): AbstractControl {
    return this.intervalGroup.controls.day;
  }

  get month(): AbstractControl {
    return this.intervalGroup.controls.month;
  }

  get interval(): AbstractControl {
    return this.intervalGroup.controls.interval;
  }

  get intervalDuration(): AbstractControl {
    return this.intervalGroup.controls.intervalDuration;
  }

  get mediumWarningThreshold(): AbstractControl {
    return this.intervalGroup.controls.mediumWarningThreshold;
  }

  get highWarningThreshold(): AbstractControl {
    return this.intervalGroup.controls.highWarningThreshold;
  }

  private static getIntervalDays(interval: number, duration: IntervalsDurationEnum): number {
    switch (duration) {
      case IntervalsDurationEnum.MONTHS:
        return interval * 30;
      case IntervalsDurationEnum.YEARS:
        return interval * 365;
      case IntervalsDurationEnum.DAYS:
      default:
        return interval;
    }
  }
}
