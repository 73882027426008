import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'isNil'
})
export class IsNilPipe implements PipeTransform {

  public transform(value: any): boolean {
    return _.isNil(value);
  }

}
