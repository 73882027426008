export enum StockType {
  PERSON = 'PERSON',
  VEHICLE = 'VEHICLE',
  CONTAINER = 'CONTAINER',
  POSITION = 'POSITION',
  STOCK = 'STOCK',
  MAIN = 'MAIN'
}

export function isLocationType(stockType: StockType): boolean {
  return stockType === StockType.STOCK || stockType === StockType.POSITION || stockType === StockType.MAIN ;
}
