import { Directive, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { RouterHistory } from 'app/shared/router-history';
import { LanguageService } from 'app/shared/services/language.service';
import { BaseProjectMapComponent } from '../projects/project-map/base/base-project-map.component';
import { ProjectDataSource } from './project.datasource';

@Directive()
export class DispositionBasicMapComponent extends BaseProjectMapComponent implements OnInit {

  public readonly filters: FilterType[] = this.projectStore.filters;
  public readonly onFiltersUpdated: EventEmitter<any> = this.projectStore.onFiltersUpdated;
  public readonly onTotalCountUpdated: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    projectStore: ProjectDataSource,
    router: Router,
    authService: KeycloakService,
    route: ActivatedRoute,
    organisationService: OrganisationsService,
    routerHistory: RouterHistory,
    languageService: LanguageService,
    equipmentStore: EquipmentsDataSource,
    contactDataSource: ContactDataSource
  ) {
    super(
      authService,
      router,
      route,
      projectStore,
      organisationService,
      routerHistory,
      languageService,
      equipmentStore,
      contactDataSource
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.projectStore.updateListing();
    this.totalCountListener();
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.projectStore.updateFilterParams(commands);
    this.projectStore.updateMap(true);
    this.projectStore.updateListing();
  }

  public onSearchFormClick(): void {
    this.projectStore.updateMap(true);
  }

  private totalCountListener(): void {
    this.projectStore.totalCount
      .pipe(untilDestroyed(this))
      .subscribe(count => this.onTotalCountUpdated.emit(count));
  }
}
