import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'operatingHours'
})
export class OperatingHoursPipe implements PipeTransform {

  constructor(private _languageService: LanguageService,
              @Inject(LOCALE_ID) private _browserLocale: string) {}

  public transform(value: any, args?: any): any {
    return formatNumber(parseFloat(value), this.getLocale(), '1.0-1');
  }

  private getLocale(): string {
    let locale = this._languageService.getCurrentLocale();
    return !!locale ? locale : this._browserLocale;
  }
}
