<div *ngIf="isShow()" class="many-requests-dialog">
  <div mat-dialog-content fxLayout="column" class="requests-content" fxLayoutAlign="start stretch">
    <div fxLayout="row" class="request-div" fxLayoutAlign="space-between center">
      <div>
        {{"general.asset"| translate}}
      </div>
      <span>
        {{"general.quantity"| translate}}
      </span>
      <span>
        {{"general.status"| translate}}
      </span>
    </div>
    <ng-container *ngFor="let item of transferItemsWithStatus">
      <div *ngIf="item.transferItem.transferAmount>0" fxLayout="row" class="request-div" fxLayoutAlign="space-between center">
        <div class="request-column" fxFlex="45" fxLayout="column" fxLayoutAlign="start start">
        <span class="upper-row">
          {{item.transferItem.name}}
        </span>
          <span class="lower-row">
          {{item.transferItem.sourceName}}
        </span>
        </div>
        <div class="request-column" fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
        <span>
          {{item.transferItem.transferAmount}}
        </span>
        </div>
        <div fxFlex="45" fxLayout="column">
          <div fxFlex="60" fxLayout="column" fxLayoutAlign="end end">
            <div fxLayout="column" fxLayoutAlign="end end">
              <fa-icon [class]="resolveIconClass(item.status)" [icon]="resolveStatusIcon(item.status)">
              </fa-icon>
            </div>
          </div>
          <div fxFlex="40" fxLayout="column" fxLayoutAlign="end end">
          <span *ngIf="item.errorText" class="error-span">
            {{item.errorText | translate}}
          </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
