<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
<mat-card class="maintenance-card entity-creation" fxFlex>
  <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="card-title-panel">
      <mat-icon class="card-title-icon maintenance-list-icon">assignment_turned_in</mat-icon>
      <span class="maintenance-card-title">{{(maintenanceType ?
        'modules.maintenance.maintenanceTypeAddEdit.editServiceType' :
        'modules.maintenance.maintenanceTypeAddEdit.createNewServiceType')|translate}}</span>
    </div>
    <div class="entity-creation-actions">
      <button color="primary"
              class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        {{'general.buttons.save'|translate}} {{'general.and'|translate}} {{'general.buttons.close'|translate|lowercase}}
      </button>
      <button mat-raised-button routerLink="/maintenance/types/list">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button (click)="resetForm()">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>
    <div fxFlex>
      <form class="entity-creation-form" [formGroup]="form">
        <div class="entity-creation-form-panel entity-creation-form-left">
          <div class="entity-creation-form-title">
            {{'general.generalData'|translate|uppercase}}
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
              <input matInput bhRemoveWhitespaces formControlName="name">
              <mat-error *ngIf="form.get('name').hasError('nameInUse')">
                {{'shared.validation.alreadyAssigned'|translate:{ value:'general.name.s'|translate } }}
              </mat-error>
              <mat-error *ngIf="form.get('name').hasError('maxlength')">
                {{'shared.validation.maxCharacters'|translate:{ value:fieldLimit.MEDIUM_IDENTIFIER } }}</mat-error>
              <mat-error *ngIf="form.get('name').hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
              <mat-error *ngIf="form.get('name').hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                {{'shared.validation.string.trimSpaces'|translate|translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
            <mat-label>{{'general.description'|translate|uppercase}}</mat-label>
            <textarea matInput class="maintenance-description" formControlName="description">
            </textarea>
            <mat-error *ngIf="form.get('description').hasError('maxlength')">
              {{'shared.validation.maxCharacters'|translate:{ value:fieldLimit.SHORT_DESCRIPTION } }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="entity-creation-form-panel entity-creation-form-right">
          <div class="entity-creation-form-title">
            {{'modules.maintenance.maintenanceTypeAddEdit.availableFields'|translate|uppercase}}
          </div>
          <div fxFlex fxLayout="column">
            <mat-form-field>
              <mat-label>{{'general.search'|translate|uppercase}}</mat-label>
              <input matInput [formControl]="filterControl">
                <mat-icon fxFlex [matTooltip]="'general.labels.resetSearchFilters'|translate" (click)="filterControl.reset()">clear</mat-icon>
            </mat-form-field>
            <div class="type-fields-panel" fxFlex>
              <div class="type-fields">
                <ng-container *ngFor="let typeField of typeFieldControls">
                  <div [class.hidden]="typeField.disabled"  class="maintenance-type-field"
                       *ngIf="((typeField.get('name').value | isAllowedDynamicField) || typeField.get('checked').value)">
                    <mat-checkbox color="primary" [formControl]="typeField.get('checked')"
                                  data-osp-test="checkbox-maintenance-type-field">
                      {{typeField.get('name').value | maintenanceTypeFieldTranslation}}
                    </mat-checkbox>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
</div>
