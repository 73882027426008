import { LanguageService } from 'app/shared/services/language.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Languages } from '../enums/languages.enum';

@Injectable()
export class SchedulerLocaleLoaderService {
  private isLoadedSubject = new BehaviorSubject<boolean>(false);
  public readonly isLoaded = this.isLoadedSubject.asObservable();

  constructor(private languageService: LanguageService) {
  }

  public setCurrentLocale(): void {
    const script = document.createElement('script');
    script.src = this.getSchedulerFileLocaleName();
    script.onload = this.onLoad.bind(this);
    document.querySelector('head').appendChild(script);
  }

  private onLoad(): void {
    this.isLoadedSubject.next(true);
  }

  private getSchedulerFileLocaleName(): string {
    switch (this.languageService.getCurrentLocale()) {
      case Languages.EN:
      case Languages.TH:
        return 'assets/scheduler/locale/locale_en.js';
      case Languages.DE:
        return 'assets/scheduler/locale/locale_de.js';
      case Languages.DK:
        return 'assets/scheduler/locale/locale_da.js';
      case Languages.CZ:
        return 'assets/scheduler/locale/locale_cs.js';
      case Languages.NL:
        return 'assets/scheduler/locale/locale_nl.js';
      case Languages.FR:
        return 'assets/scheduler/locale/locale_fr.js';
      case Languages.SE:
        return 'assets/scheduler/locale/locale_sv.js';
      case Languages.HU:
        return 'assets/scheduler/locale/locale_hu.js';
      case Languages.IT:
        return 'assets/scheduler/locale/locale_it.js';
      case Languages.NO:
        return 'assets/scheduler/locale/locale_no.js';
      case Languages.PL:
        return 'assets/scheduler/locale/locale_pl.js';
      case Languages.PT:
        return 'assets/scheduler/locale/locale_pt.js';
      case Languages.RU:
        return 'assets/scheduler/locale/locale_ru.js';
      case Languages.RO:
        return 'assets/scheduler/locale/locale_ro.js';
      case Languages.ES:
        return 'assets/scheduler/locale/locale_es.js';
      case Languages.FI:
        return 'assets/scheduler/locale/locale_fi.js';
      case Languages.JP:
        return 'assets/scheduler/locale/locale_jp.js';
      default:
        return 'assets/scheduler/locale/locale_de.js';
    }
  }
}
