<div class="editable-list">
    <mat-tab-group mat-align-tabs="center" (selectedTabChange)="disableEditMode()" animationDuration="0ms">
      <mat-tab [label]="'modules.disposition.acceptDisposition.requests'|translate">
        <div class="m-edit-header" *ngIf="!editMode">
          <button mat-button (click)="enableEditMode()">{{'general.buttons.change'|translate}}</button>
        </div>
        <div class="m-edit-header" *ngIf="editMode">
          <button mat-button (click)="disableEditMode()">{{'general.buttons.cancel'|translate}}</button>
        </div>
        <bh-mobile-swipe-to-options (onClick)="toggleSelection(assignment)"
                                    *ngFor="let assignment of requestedPendingAssignments"
                                    [disableSwiping]="editMode"
                                    [overScroll]="false"
                                    swipeLimitInPercent="40">
          <div class="disposition" fxFlex fxLayout="row" fxFlexAlign="start center"
               fxLayoutGap="20px">
            <div class="edit-mode" *ngIf="editMode" fxFlex="16px">
              <mat-checkbox color="primary" [checked]="assignment.checked"></mat-checkbox>
            </div>
            <div class="short-info" fxFlex fxLayout="column" fxFlexAlign="start center">
              <div class="equipment-name ellipsis">
                {{ assignment.equipmentName || 'modules.disposition.acceptDisposition.unknown'|translate }}
              </div>
              <div fxFlex fxLayout="row" fxLayoutGap="10px" fxFlexAlign="start center">
                <div class="ellipsis current-project-name">
                  {{ assignment.currentProjectName || 'modules.disposition.acceptDisposition.unknown'|translate }}
                </div>
                <mat-icon>arrow_right_alt</mat-icon>
                <div class="ellipsis project-name">{{ assignment.projectName }}</div>
              </div>
              <div fxFlex fxLayout="row" fxFlexAlign="start center">
                <div class="requested-by ellipsis">{{ assignment.requestedBy }}</div>
                <div
                    class="request-date ellipsis">{{ assignment.requestDate | date:'dd.MM.yyyy' }}</div>
              </div>
            </div>
          </div>

          <bh-swipe-option appendClass="blue"
                           (onClick)="acknowledgeAssignment(assignment)">
            <mat-icon>check</mat-icon>
          </bh-swipe-option>
          <bh-swipe-option appendClass="red" (onClick)="deleteAssignment(assignment)">
            <mat-icon>delete</mat-icon>
          </bh-swipe-option>
        </bh-mobile-swipe-to-options>
      </mat-tab>
      <mat-tab [label]="'modules.disposition.acceptDisposition.recentlyConfirmed'|translate">
        <bh-mobile-swipe-to-options (onClick)="toggleSelection(assignment)"
                                    *ngFor="let assignment of acknowledgeAssignments"
                                    disableSwiping="true">
          <div class="disposition" fxFlex fxLayout="row" fxFlexAlign="start center"
               fxLayoutGap="20px">
            <div class="edit-mode" *ngIf="editMode" fxFlex="16px">
              <mat-checkbox color="primary" [checked]="assignment.checked"></mat-checkbox>
            </div>
            <div class="short-info" fxFlex fxLayout="column" fxFlexAlign="start center">
              <div class="equipment-name ellipsis">
                {{ assignment.equipmentName || 'modules.disposition.acceptDisposition.unknown'|translate }}
              </div>
              <div fxFlex fxLayout="row" fxLayoutGap="10px" fxFlexAlign="start center">
                <div class="ellipsis current-project-name">
                  {{ assignment.currentProjectName || 'modules.disposition.acceptDisposition.unknown'|translate }}
                </div>
                <mat-icon>arrow_right_alt</mat-icon>
                <div class="ellipsis project-name">{{ assignment.projectName }}</div>
              </div>
              <div fxFlex fxLayout="row" fxFlexAlign="start center">
                <div class="requested-by ellipsis">{{ assignment.requestedBy }}</div>
                <div
                    class="request-date ellipsis">{{ assignment.requestDate | date:'dd.MM.yyyy' }}</div>
              </div>
            </div>
          </div>
        </bh-mobile-swipe-to-options>
      </mat-tab>
    </mat-tab-group>
</div>


