<div fxLayout="column">
  <div fxLayout="row">
    <div fxLayoutAlign="start center" fxFlex="60">
      <bh-title [text]="'general.telematics'|translate"></bh-title>
    </div>
    <div fxLayoutAlign="space-between center" fxFlex="40" *ngIf="isDigitalMatterUnit() && digitalMatterProfile" data-osp-test="digital-matter-profile">
      <span class="digital-matter-profile-text">{{"modules.equipment.assignTelematicUnit.trackerProfileDropdown" | translate}}</span>
      <div class="bh-form-field" fxLayout="column">
        <div class="underline">
        <div>
          <div [matMenuTriggerFor]="digitalMatterProfileDropdownComponent.menu" class="select"
               (click)="trackMatomoEvent_Telematic_DigitalMatterProfileChangeStarted()">
          <div
               fxLayout="row" fxLayoutAlign="space-between center">
            <div class="overflow-wrapper">
              <bh-digital-matter-profile-status-badge [profile]="digitalMatterProfile"
                                                      [status]="digitalMatterProfileDeployment.deploymentState"
                                                      [cancelReason]="digitalMatterProfileDeployment.deploymentCancelReason"
                                                      [selectedLanguage]="selectedLanguage"></bh-digital-matter-profile-status-badge>
            </div>
            <div class="mat-mdc-select-arrow-wrapper">
              <div class="mat-mdc-select-arrow custom-select-arrow"></div>
            </div>
          </div>
        </div>
      </div>
          </div>
      <bh-digital-matter-profile-dropdown #digitalMatterProfileDropdownComponent
                                          [selectedLanguage]="selectedLanguage"
                                          [status]="digitalMatterProfileDeployment.deploymentState"
                                          [allDigitalMatterProfiles]="allDigitalMatterProfiles"
                                          [selectedDigitalMatterProfile]="digitalMatterProfile"
                                          (onProfileChange)="profileChanged($event)"
      ></bh-digital-matter-profile-dropdown>
        </div>

      <bh-tracker-battery-status [batteryStatus]="batteryStatus"></bh-tracker-battery-status>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <div fxFlex="40" class="info-card-container">
      <ng-container *ngIf="isDigitalMatterUnit()">
        <bh-info-card *ngIf="isDigitalMatterProfileUpdating()" class="info-card"
                      [text]="'modules.equipment.assignTelematicUnit.trackerProfileUpdateInfo' | translate">
        </bh-info-card>
        <bh-error-card *ngIf="wasDigitalMatterProfileCanceled()" class="info-card"
                      [text]="'modules.equipment.assignTelematicUnit.trackerProfileUpdateCanceled' | translate">
        </bh-error-card>
      </ng-container>
    </div>
  </div>
</div>


<div class="assigned-unit" *ngIf="assignedTelematicUnit" fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row">
    <div fxFlex>
      <div *ngIf="getTelematicUnitLogo(); else unitName">
        <img
          class="assigned-unit__logo"
          [src]="getTelematicUnitLogo()"
          [matTooltip]="getTelematicUnitName()"
        >
      </div>
      <ng-template #unitName>
        <span class="assigned-unit__name">{{getTelematicUnitName()}}</span>
      </ng-template>
    </div>

    <div class="assigned-unit__status" fxFlex="150px" fxLayout="row" fxLayoutAlign="flex-end">
      <div *ngIf="equipmentStatus?.latestChangeDate && isTeltonikaTelematicsUnit()"
           class="assigned-unit__engine-status"
           [class.running]="equipmentStatus?.engineRunning">
        <mat-icon class="assigned-unit__engine-icon" svgIcon="engine"></mat-icon>
        <span *ngIf="equipmentStatus?.engineRunning; else engineOff">
          {{'modules.equipment.telematics.engineOn'|translate}}
        </span>
        <ng-template #engineOff>
          <span>{{'modules.equipment.telematics.engineOff'|translate}}</span>
        </ng-template>
      </div>
    </div>
  </div>

  <div>
    <span class="assigned-unit__property-name">{{isTeltonikaTelematicsUnit() ? ('modules.equipment.base.imei'|translate) : 'modules.equipment.base.serialNumber'|translate}}:</span>
    <span>{{assignedTelematicUnit.value}}</span>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="center center"
     *ngIf="!equipmentStatus?.latestChangeDate">
  <p> {{'modules.equipment.telematics.noTelematicsData'|translate}} </p>
</div>

<div *ngIf="equipmentStatus?.latestChangeDate" fxLayout="column" fxLayoutAlign="start stretch" data-osp-test="chart-section">

  <div fxLayout="row" fxLayoutAlign="start end">
    <div class="telematic-item telematic-header-item" fxFlex="{{fxFlexValue()}}">
      <bh-subtitle [text]="'modules.equipment.telematics.equipmentStatus'|translate"></bh-subtitle>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.telematics.lastTransfer'|translate}}</mat-label>
          <input matInput readonly
                 value="{{equipmentStatus?.latestChangeDate | formatTimezoneDateTime: {showTime: true} }}">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.telematics.timeSinceLastTransfer'|translate}}</mat-label>
          <input matInput readonly
                 value="{{ calculateDiffChangeDateUntilNow(equipmentStatus?.latestChangeDate) }}">
        </mat-form-field>
      </div>
    </div>

    <div class="telematic-item telematic-header-item" fxFlex="{{fxFlexValue()}}"
         *ngIf="isNotBeaconTelematicUnit() && !isNotConnectedToEngineTelematicUnit()">
      <bh-subtitle [text]="'general.operatingHour.pl'|translate"></bh-subtitle>
      <div fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="15px">
        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.telematics.totalOperatingHours'|translate}}</mat-label>
          <input matInput readonly [value]="equipmentStatus?.cumulativeOperatingHours
                                    ? cumulativeOperatingHours : 'general.noData'|translate">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.telematics.idleOperatingHours'|translate}}</mat-label>
          <input matInput readonly [value]="equipmentStatus?.cumulativeIdleNonOperatingHours
                                    ? cumulativeIdleNonOperatingHours : 'general.noData'|translate">
        </mat-form-field>
      </div>
    </div>

    <div class="telematic-item telematic-header-item" fxFlex="{{fxFlexValue()}}"
         *ngIf="!isNotBeaconTelematicUnit()">
      <div fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="15px">
        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.telematics.rssi'|translate}}</mat-label>
          <input matInput readonly [value]="equipmentStatus?.rssi
                                    ? equipmentStatus.rssi : 'general.noData'|translate">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>{{'modules.equipment.telematics.imei'|translate}}</mat-label>
          <input matInput readonly [value]="equipmentStatus?.baseTelematicsUnitId
                                    ? equipmentStatus.baseTelematicsUnitId : 'general.unknown'|translate">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="!isNotBeaconTelematicUnit()">
    <div class="telematic-item" fxFlex>
      <span>{{'modules.equipment.telematics.baseEquipment'|translate}}: </span>
      <span class="link" (click)="navigateToBaseEquipment()" *ngIf="equipmentStatus.baseEquipmentId">
          {{equipmentStatus.baseEquipmentName}}
        </span>
      <span *ngIf="!equipmentStatus.baseEquipmentId">{{'general.unknown'|translate}}</span>
    </div>
  </div>

  <ng-container *ngIf="isNotBeaconTelematicUnit()">
    <div class="telematic-filter" *ngIf="!isDigitalMatterUnit()">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <bh-subtitle [text]="'modules.equipment.telematics.evaluationPeriod'|translate"></bh-subtitle>
        <mat-spinner [strokeWidth]="2" [diameter]="18" *ngIf="isLoading() | async"></mat-spinner>
      </div>
      <form [formGroup]="telematicFiltersForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
        <bh-date-range-select
          fxFlex="350px"
          fxLayoutGap="20px"
          flexOrientation="row"
          [startDateControl]="fromDateControl"
          [startRequired]="true"
          [endDateControl]="toDateControl"
          [endRequired]="true"
          [resettable]="true"
          [allowFutureDates]="false"
          [maxInterval]="maxInterval"
          (onDateChange)="onTelematicDateFilterChange()">
        </bh-date-range-select>
        <div fxFlex="1 1 100%">
          <mat-chip-listbox>
            <mat-chip-option (click)="onQuickfilterClick(1)">1 {{'general.units.time.month.s'|translate}}</mat-chip-option>
            <mat-chip-option (click)="onQuickfilterClick(6)">6 {{'general.units.time.month.pl'|translate}}</mat-chip-option>
            <mat-chip-option (click)="onQuickfilterClick(12)">12 {{'general.units.time.month.pl'|translate}}</mat-chip-option>
          </mat-chip-listbox>
        </div>
      </form>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center"
         *ngIf="!isDigitalMatterUnit() && !(displayOperatingHours() || displayFuelUsed() || displayFuelRemaining() || displayEngineStatus()
         || displayExternalVoltage())">
      <p> {{'modules.equipment.telematics.noDataAvailableThisPeriod'|translate}} </p>
    </div>

    <div *ngIf="displayOperatingHours()" fxLayout="row" fxLayoutAlign="start start">
      <bh-title [text]="'general.operatingHour.pl'|translate"></bh-title>
      <p class="last-update big">{{ equipmentStatus?.lastCumulativeOperatingHoursUpdate | telematicsFormatDate }}</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <bh-equipment-telematic-operating-hours-history
        *ngIf="isValidMultiSeries(equipmentMultiseries?.dailyOperatingHoursMultiSeries)"
        class="telematic-item" fxFlex="grow" fxLayout="column" fxLayoutAlign="start stretch"
        [value]="equipmentMultiseries?.dailyOperatingHoursMultiSeries"
        data-osp-test="chart-op-hours-history">
      </bh-equipment-telematic-operating-hours-history>

      <bh-equipment-telematics-operating-hours-ratio
        *ngIf="isValidTelematicsDataSeries(equipmentTelematics?.operatingHoursWorkingIdleRatio)"
        class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
        fxLayoutAlign="start stretch"
        [value]="equipmentTelematics?.operatingHoursWorkingIdleRatio"
        [subtitle]="'modules.equipment.telematics.ratioOperatingHoursPeriod'|translate"
        data-osp-test="chart-op-hours-ratio-period">
      </bh-equipment-telematics-operating-hours-ratio>

      <bh-equipment-telematics-operating-hours-ratio
        *ngIf="isValidStatus(equipmentStatus?.cumulativeOperatingHours, equipmentStatus?.cumulativeIdleNonOperatingHours)"
        class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
        fxLayoutAlign="start stretch"
        [value]="equipmentStatus | convertStatusHoursToSeries"
        [subtitle]="'modules.equipment.telematics.ratioOperatingHoursPeriodTotal'|translate"
        data-osp-test="chart-op-hours-ratio-total">
      </bh-equipment-telematics-operating-hours-ratio>
    </div>

    <bh-title *ngIf="displayFuelRemaining()" [text]="'modules.equipment.telematics.level'|translate"></bh-title>
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <bh-equipment-telematics-tank-level-history
        *ngIf="isValidTelematicsDataSeries(equipmentTelematics?.fuelRemainingSeries)"
        class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
        fxLayoutAlign="start stretch"
        [value]="equipmentTelematics?.fuelRemainingSeries"
        [lastUpdate]="equipmentStatus?.lastFuelRemainingUpdate"
        [subtitle]="'modules.equipment.telematics.tankLevelHistory'|translate"
        data-osp-test="chart-tank-level">
      </bh-equipment-telematics-tank-level-history>

      <bh-equipment-telematics-tank-level-history
        *ngIf="isValidTelematicsDataSeries(equipmentTelematics?.defRemainingSeries)"
        class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
        fxLayoutAlign="start stretch"
        [value]="equipmentTelematics?.defRemainingSeries"
        [lastUpdate]="equipmentStatus?.lastDefRemainingUpdate"
        [subtitle]="'modules.equipment.telematics.adBlueLevelCurve'|translate"
        data-osp-test="chart-tank-level-ad-blue">
      </bh-equipment-telematics-tank-level-history>

      <bh-equipment-telematics-single-bar
        *ngIf="isValidStatus(equipmentStatus?.fuelTankCapacityInLitre, equipmentStatus?.fuelRemainingPercent)"
        class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
        fxLayoutAlign="start stretch"
        [value]="equipmentStatus?.fuelRemainingPercent"
        [subtitle]="'modules.equipment.telematics.currentTankLevel'|translate"
        [lastUpdate]="equipmentStatus?.lastFuelRemainingUpdate"
        [label]="'modules.equipment.telematics.fueledInPercent'|translate"
        data-osp-test="chart-current-tank-level">
      </bh-equipment-telematics-single-bar>

      <bh-equipment-telematics-single-bar
        *ngIf="isValidStatus(equipmentStatus?.defTankCapacityInLitre, equipmentStatus?.defRemainingPercent)"
        class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
        fxLayoutAlign="start stretch"
        [value]="equipmentStatus?.defRemainingPercent"
        [subtitle]="'modules.equipment.telematics.currentAdBlueLevel'|translate"
        [lastUpdate]="equipmentStatus?.lastDefRemainingUpdate"
        [label]="'modules.equipment.telematics.fueledInPercent'|translate"
        data-osp-test="chart-current-tank-level-ad-blue">
      </bh-equipment-telematics-single-bar>
    </div>

    <ng-container *ngIf="displayFuelUsed()">
      <bh-title [text]="'modules.equipment.telematics.consumption'|translate"></bh-title>
      <div fxLayout="row" fxLayoutAlign="start start">

        <div class="telematic-item" fxFlex="{{fxFlexValue()}}" fxLayout="column"
             fxLayoutAlign="start stretch"
             *ngIf="isValidTelematicsDataSeries(equipmentTelematics?.fuelUsedAverageSeries)">
          <div fxLayout="row" fxLayoutAlign="start start">
            <bh-subtitle [text]="'modules.equipment.telematics.averageConsumption'|translate"></bh-subtitle>
            <p class="last-update">{{ equipmentStatus?.lastFuelUsedUpdate | telematicsFormatDate }}</p>
          </div>
          <div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
              <fa-icon [icon]="faBurn" size="2x" class="green-icon"></fa-icon>
              <span>{{ averageFuelUsed() }} {{'general.units.volume.l.s'|translate}} / {{'general.units.time.hour.abbr'|translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="displayEngineStatus()">
      <bh-title [text]="'modules.equipment.telematics.engineStatus'|translate"></bh-title>
      <bh-day-timeline class="engine-status-timeline" fxLayout="row"
                       [timeline-display-days]="7"
                       [timeline-display-date]="currentDate"
                       [timeline-limit-start]="fromDate"
                       [timeline-limit-end]="toDate"
                       [timeline-events]="equipmentEngineStatusEvents"></bh-day-timeline>
    </ng-container>

    <ng-container *ngIf="displayExternalVoltage()">
      <div fxLayout="row" fxLayoutAlign="start start">
        <bh-title [text]="'modules.equipment.telematics.externalVoltage'|translate"></bh-title>
        <p class="last-update big">{{ equipmentStatus?.lastExternalVoltageUpdate | telematicsFormatDate }}</p>
      </div>
      <bh-equipment-telematics-external-voltage
        [value]="equipmentMultiseries?.externalVoltageStatusMultiSeries"
        data-osp-test="chart-voltage">
      </bh-equipment-telematics-external-voltage>
    </ng-container>

    <ng-container *ngIf="!isDigitalMatterUnit()">
      <bh-title [text]="'general.fault.pl'|translate"></bh-title>
      <div class="equipment-view-faults" fxFlex>
        <mat-card>
          <mat-card-content>

            <div class="content__body">

              <div class="content__body-fault" fxLayout="row" fxLayoutAlign="space-between center"
                   *ngIf="equipmentFaults?.length > 0">
                <div class="content__body-fault-data" fxFlex fxLayout="row"
                     fxLayoutAlign="space-between center">
                  <div fxFlex="5">
                    <span>{{'modules.equipment.telematics.level'|translate}}</span>
                  </div>
                  <div fxFlex="15">
                    <span>{{'general.date'|translate}}</span>
                  </div>
                  <div fxFlex="15">
                    <span>{{'general.identifier'|translate}}</span>
                  </div>
                  <div fxFlex="65">
                    <span>{{'general.description'|translate}}</span>
                  </div>
                </div>
              </div>

              <div class="content__body-fault"
                   fxLayout="row"
                   fxLayoutAlign="space-between center"
                   *ngFor="let fault of equipmentFaults">

                <div class="content__body-fault-data" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="5">
                    <mat-icon class="fault_error" *ngIf="isError(fault.faultCodeSeverity)">
                      warning
                    </mat-icon>
                    <mat-icon class="fault_warning" *ngIf="isWarning(fault.faultCodeSeverity)">
                      warning
                    </mat-icon>
                    <mat-icon class="fault_info" *ngIf="isInformation(fault.faultCodeSeverity)">
                      warning
                    </mat-icon>
                  </div>
                  <div fxFlex="15">
                    <span>{{fault.date | date: 'dd.MM.yyyy HH:mm'}}</span>
                  </div>
                  <div fxFlex="15">
                    <span>{{fault.faultCodeIdentifier ? fault.faultCodeIdentifier : '-' }}</span>
                  </div>
                  <div fxFlex="65">
                    <span>{{fault.faultCodeDescription ? fault.faultCodeDescription : '-' }}</span>
                  </div>
                </div>
              </div>

              <div class="content__body-fault-next" fxLayout="row" fxLayoutAlign="center center"
                   *ngIf="equipmentHasNextFaults" (click)="onLoadNextFaultsClick()">
                <span>{{'modules.equipment.telematics.loadMore'|translate}}</span>
              </div>

              <div class="content__body-fault" fxLayout="row" fxLayoutAlign="center center"
                   *ngIf="!equipmentFaults || equipmentFaults?.length === 0">
                <div class="content__body-fault-data">
                  <div class="content__body-fault-no-data">
                    <span>{{'modules.equipment.telematics.noFaults'|translate}}</span>
                  </div>
                </div>
              </div>

            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>

    <ng-container *ngIf="isTeltonikaTelematicsUnit() || isDigitalMatterUnit()">
      <bh-title [text]="'general.beacon.pl'|translate"></bh-title>
      <div class="equipment-view-beacons" fxFlex>
        <mat-card>
          <mat-card-content>
            <div class="content__body">

              <div class="content__body-beacon" fxLayout="row" fxLayoutAlign="space-between center"
                   *ngIf="equipmentBeacons?.length > 0">
                <div class="content__body-fault-data" fxFlex fxLayout="row"
                     fxLayoutAlign="space-between center">
                  <div fxFlex="33">
                    <span>{{'general.date'|translate}}</span>
                  </div>
                  <div fxFlex="33">
                    <span>{{'modules.equipment.telematics.instanceId'|translate}}</span>
                  </div>
                  <div fxFlex="34">
                    <span>{{'modules.equipment.telematics.rssi'|translate}}</span>
                  </div>
                </div>
              </div>

              <div class="content__body-beacon"
                   fxLayout="row"
                   fxLayoutAlign="space-between center"
                   *ngFor="let beacon of equipmentBeacons">

                <div class="content__body-beacon-data" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="33">{{ beacon.date | date: 'dd.MM.yyyy HH:mm' }}</div>
                  <div fxFlex="33">{{ beacon.instanceId }}</div>
                  <div fxFlex="34">{{ beacon.rssi }}</div>
                </div>
              </div>

              <div class="content__body-beacon" fxLayout="row" fxLayoutAlign="center center"
                   *ngIf="!equipmentBeacons || equipmentBeacons?.length === 0">
                <div class="content__body-beacon-data">
                  <div class="content__body-beacon-no-data">
                    <span>{{'modules.equipment.telematics.noBeacons'|translate}}</span>
                  </div>
                </div>
              </div>

            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </ng-container>

</div>
