import { UpdateTransportDocumentDescriptionCommand } from '../../contracts/transport/update-transport-document-description.command';
import { DeleteTransportDocumentCommand } from '../../contracts/transport/delete-transport-document.command';
import { DocumentLink } from '../../../../shared/contract/document-link.interface';
import { environment } from 'environments/environment';
import { TransportFilterService } from './transport-filter.service';
import { Injectable } from '@angular/core';
import { TransportDataSource } from './transport.datasource';
import { TransportService } from './transport.service';
import { distinctUntilChanged, filter, skip, switchMap, take, tap, delay, share } from 'rxjs/operators';
import { TransportParams } from '../contract/request-params/transport-params.interface';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { Sort } from '@angular/material/sort';
import { TransportListItemView } from '../../contracts/transport/transport-list-view.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { TransportColumnService } from './transport-column.service';
import { ColumnDefinition } from '../../../../shared/column-definition';
import _ from 'lodash';
import { TransportTaskState } from '../enums/transport-task-status.enum';
import { DeleteTransportTaskCommand } from '../../contracts/transport/delete-transport-task.command';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { TransportCommentCreateCommand } from '../../contracts/transport/transport-comment-create.command';
import { TransportCommentUpdateCommand } from '../../contracts/transport/transport-comment-update.command';
import { TransportCommentDeleteCommand } from '../../contracts/transport/transport-comment-delete.command';
import { TransportComment } from '../../contracts/transport/transport-comment.interface';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportStatePipe } from '../pipes/transport-state.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateTransportDocumentNameCommand } from '../../contracts/transport/update-transport-document-name.command';

@Injectable()
export class TransportListDatasource extends TransportDataSource<TransportListItemView> {
  protected _comments: BehaviorSubject<TransportComment[]> = new BehaviorSubject([]);
  private _documents: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);

  public readonly filters = this.transportFilterService.filters;
  public readonly comments = this._comments.asObservable();
  public readonly documents = this._documents.asObservable();
  public readonly onFiltersUpdated = this.transportFilterService.onFiltersUpdated;

  private currentlySelectedColumns: string[] = [];

  constructor(
    public transportService: TransportService,
    private router: Router,
    private transportFilterService: TransportFilterService,
    private transportColumnService: TransportColumnService,
    private projectDataSource: ProjectDataSource,
    dialog: MatDialog,
    languageService: LanguageService,
    transportStatePipe: TransportStatePipe,
    snackBar: MatSnackBar
  ) {
    super(transportService, dialog, languageService, transportStatePipe, transportColumnService, snackBar);
    this.initProjectDeletedListener();
    this.initColumnListener();
    this.initListPageSizeListener();
  }

  public selectCurrentTransportOrDefault(): void {
    if (this._currentTransport.value) {
      this.router.navigate(['transportation/list', this._currentTransport.value.transportId]);
    } else {
      this.selectDefaultTransport();
    }
  }

  public deleteTransport(command: DeleteTransportTaskCommand): void {
    this.transportService.deleteTransport(command)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap(() => this.transports),
        tap(() => this.updateListing()),
        skip(1),
        take(1))
      .subscribe(transports => {
        const defaultTransportId = (transports || [])[0]?.transportId;
        if (defaultTransportId) {
          this.router.navigate(['transportation', 'list', defaultTransportId]);
        } else {
          this.changeCurrentTransport(null);
          this.router.navigate(['transportation', 'list']);
        }
      });
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.transportFilterService.updateFilterParams(commands);
  }

  public updateFilters(): void {
    this.transportFilterService.updateFilters();
  }

  public getDocuments(transportId: string = this._currentTransport.value?.transportId): void {
    if (!transportId) {
      this._documents.next([]);
    }
    this.transportService.getDocuments(transportId)
      .subscribe(documents => this._documents.next(documents));
  }

  public deleteDocument(cmd: DeleteTransportDocumentCommand): Observable<DocumentLink[]> {
    this.transportService.deleteDocument(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocuments(cmd.transportId));
    return this.documents.pipe(skip(1), take(1));
  }

  public updateDocumentDescription(cmd: UpdateTransportDocumentDescriptionCommand): void {
    this.transportService.updateDocumentDescription(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.getDocuments(cmd.transportId));
  }

  public updateDocumentName(cmd: UpdateTransportDocumentNameCommand): void {
    this.transportService.updateDocumentName(cmd)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.getDocuments(cmd.transportId));
  }

  // Comments Data Source
  public getComments(transportId: string): void {
    this.transportService.getComments(transportId)
    .subscribe(comments => this._comments.next(comments));
  }

  public addComment(cmd: TransportCommentCreateCommand): Observable<string> {
    let observable = this.transportService.addComment(cmd).pipe(share());
    observable.subscribe(() => this.getComments(cmd.transportId));
    return observable
  }

  public updateComment(cmd: TransportCommentUpdateCommand): Observable<string> {
    let observable = this.transportService.updateComment(cmd).pipe(share());
    observable.subscribe(() => this.getComments(cmd.transportId));
    return observable;
  }

  public deleteComment(cmd: TransportCommentDeleteCommand): void {
    this.transportService.deleteComment(cmd).subscribe(() => this.getComments(cmd.transportId));
  }

  protected transportRequestListener(): void {
    this.transportRequest
    .pipe(switchMap((params: TransportParams) => this.transportService.getAllTransports(params)))
    .subscribe((response: PagedResponse<TransportListItemView>) => this.updateStoreData(response));
  }

  protected getSortParam(sort: Sort): string {
    return this.isSortValid(sort) ?
        this.toSortString(sort) :
        'priority,desc,duedate,desc'
  }

  public updateListing(page: number = this._pagination.value.index, size: number = this._pagination.value.size): void {
    this._listingRequestInProgress.next(true);
    if (this._data.value.length === 0 && this._pagination.value.last && page !== 0) {
      page--;
    }
    if (size !== this._pagination.getValue().size) {
      this.columnService.selectBoardViewListPageSize(size);
    }
    this._pagination.next({...this._pagination.value, index: page, size});
    this.transportRequest.next(this.defineTransportParams(page, size));
  }

  protected updateStoreData(response: PagedResponse<TransportListItemView>): void {
    this.updatePagination(response);
    this._data.next(response.content);
    this._totalCount.next(response.totalElements);
    this._listingRequestInProgress.next(false);
  }

  private selectDefaultTransport(): void {
    this.transports.pipe(
      filter((res: TransportListItemView[]) => res && res.length > 0),
      take(1),
    ).subscribe((res: TransportListItemView[]) => {
      this.router.navigate(['transportation/list', res[0].transportId]);
    });
  }

  private initColumnListener(): void {
    this.transportColumnService.selectedColumns.pipe(
        distinctUntilChanged(_.isEqual),
        tap((columns: ColumnDefinition[]) => this.currentlySelectedColumns = columns.map(c => c.cdkColumnDef)),
        skip(1))
    .subscribe(() => this.updateListing());
  }

  private initListPageSizeListener(): void {
    this.transportColumnService.transportBoardViewListPageSize.subscribe((pageSize: number) => {
      this.setPageSize(pageSize);
    });
  }

  private initProjectDeletedListener(): void {
    this.projectDataSource.projectDeleted.subscribe(projectId => {
      this.checkRelatedProjectDeleted(projectId)
    });
  }

  private checkRelatedProjectDeleted(projectId: string): void {
    if (this.isCurrentTransportRelatedProject(projectId)) {
      this._currentTransport.next(null);
      this.updateListing();
    }
  }

  private isCurrentTransportRelatedProject(projectId: string): boolean {
    return this._currentTransport.getValue()?.projectInfo?.projectId === projectId;
  }

  private defineTransportParams(page?: number, size?: number): TransportParams {
    return {
      size,
      page: page || 0,
      sort: this.getSortParam(this.sort),
      terms: this._searchTerm,
      searchColumns: this._searchTerm ? this.currentlySelectedColumns : null,
      ...this.transportFilterService.getFilterParams(),
    }
  }

  public exportTransportList(columns: string[], applyFilter: boolean, sortBy: string, sortDescending: boolean,
                             timezone: string): Observable<Blob> {
    let params: Object = this.defineTransportParams();
    if (!applyFilter) {
      params = {
        state: [
          TransportTaskState.DRAFT,
          TransportTaskState.PLANNABLE,
          TransportTaskState.RETURNED,
          TransportTaskState.PLANNED,
          TransportTaskState.IN_PROGRESS,
          TransportTaskState.DONE,
        ]
      };
    }
    params = {
      ...params,
      columns,
      sort: this.toSortString({ active: sortBy, direction: sortDescending ? 'desc' : 'asc' }),
      zoneId: timezone
    };
    return this.transportService.exportTransportList(params);
  }

  public moveSelectedDoneToArchive(selectedTransports: string[]): Observable<Object> {
    return this.transportService.moveSelectedDoneToArchive(selectedTransports);
  }

}
