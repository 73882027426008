import { Directive, Inject, Input } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: 'mat-select[bhSelectOverlayPanelClass]'
})
export class SelectOverlayPanelClassDirective {

  @Input('bhSelectOverlayPanelClass') set panelClass(className: string) {
    this.matSelect._overlayPanelClass = className ?? '';
  }

  constructor(@Inject(MatSelect) private matSelect: MatSelect) { }

}
