import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { TransferHistoryEntry } from './transfer-history-entry';
import { ProjectTransferHistoryService } from './project-transfer-history.service';
import { ViewTransferRequest } from './view-transfer-request';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class TransferHistoryStore {
  private _projectId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _historyEntries: BehaviorSubject<TransferHistoryEntry[]> = new BehaviorSubject<TransferHistoryEntry[]>([]);
  private _latestTransferDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private _selectedEntry: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _transferRequests: BehaviorSubject<ViewTransferRequest[]> = new BehaviorSubject<ViewTransferRequest[]>([]);
  private _archivedTransferRequests: BehaviorSubject<ViewTransferRequest[]> = new BehaviorSubject<ViewTransferRequest[]>([]);
  private _pendingTransferRequests: BehaviorSubject<ViewTransferRequest[]> = new BehaviorSubject<ViewTransferRequest[]>([]);

  private _length = new BehaviorSubject(0);
  private _pageIndex = new BehaviorSubject(0);
  private _pageSize = new BehaviorSubject(25);

  public readonly historyEntries: Observable<TransferHistoryEntry[]> = this._historyEntries.asObservable();
  public readonly latestTransferDate: Observable<Date> = this._latestTransferDate.asObservable();
  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly selectedEntry: Observable<string> = this._selectedEntry.asObservable();
  public readonly transferRequests: Observable<ViewTransferRequest[]> = this._transferRequests.asObservable();
  private requestsSubscription: Subscription;

  public set projectId(id: string) {
    this._projectId.next(id);
  }

  public get pageIndex(): number {
    return this._pageIndex.getValue();
  }

  constructor(
    private projectTransferHistoryService: ProjectTransferHistoryService
  ) {}

  public getLatestTransfer(projectId: string) {
    this.projectTransferHistoryService
      .getLatestTransfer(projectId)
      .subscribe(res => this._latestTransferDate.next(res));
  }

  public getTransferRequests(projectId: string) {
    if (this.requestsSubscription) {
      this.requestsSubscription.unsubscribe();
    }

    this.projectTransferHistoryService
      .getArchivedTransfers(projectId)
      .subscribe(archivedRequests => this._archivedTransferRequests.next(archivedRequests));

    this.projectTransferHistoryService
      .getPendingTransfers(projectId)
      .subscribe(pendingRequests => this._pendingTransferRequests.next(pendingRequests));

    this.requestsSubscription = combineLatest([
      this._pendingTransferRequests.asObservable(),
      this._archivedTransferRequests.asObservable(),
    ]).subscribe(res => this._transferRequests.next(res[0].concat(res[1])));
  }

  public getLength(): Observable<number> {
    return this._length.asObservable();
  }

  public getPageSize(): Observable<number> {
    return this._pageSize.asObservable();
  }

  public getPageIndex(): Observable<number> {
    return this._pageIndex.asObservable();
  }

  public handlePageEvent(event: PageEvent) {
    if (this.isPageSizeChanged(event.pageSize) || this.isPageIndexChanged(event.pageIndex)) {
      this._pageIndex.next(event.pageIndex);
      this._pageSize.next(event.pageSize);
      this.updateProjectTransfers();
    }
  }

  private isPageSizeChanged(pageSize: number): boolean {
    return pageSize !== this._pageSize.getValue();
  }

  private isPageIndexChanged(pageIndex: number): boolean {
    return pageIndex !== this._pageIndex.getValue();
  }

  public resetPageIndex(): void {
    this._pageIndex.next(0);
  }

  public updateProjectTransfers(): void {
    if (this._loading.value) {
      return;
    }

    this._loading.next(true);

    this.projectTransferHistoryService
      .getProjectTransfers(this._projectId.value, this._pageIndex.value, this._pageSize.value)
      .subscribe(res => {
        this._length.next(res.totalElements);
        this._historyEntries.next(res.content);
        this._loading.next(false);
      });
  }

  public selectEntry(id: string): void {
    if (this._selectedEntry.value === id) {
      this._selectedEntry.next('');
    } else {
      this._selectedEntry.next(id);
    }
  }

  public updateLoadingStatus(status: boolean): void {
    this._loading.next(status);
  }
}
