import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectListItem } from 'app/shared/components/select-list/select-list-item.interface';
import { Languages } from 'app/shared/enums/languages.enum';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { UpdateCustomerLanguagesCommand } from '../../contract/update-customer-languages.command';
import { LanguageService } from 'app/shared/services/language.service';
import { CustomerLanguageStats, CustomersService } from '../../shared/customers.service';
import { ViewCustomer } from '../../contract/view-customer.interface';
import {
  SelectListComponent,
  SelectListItemView,
} from '../../../../shared/components/select-list/select-list.component';
import {
  ConfirmationDialogComponent
} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';

@UntilDestroy()
@Component({
  selector: 'bh-customer-languages',
  templateUrl: './customer-languages.component.html',
  styleUrls: ['./customer-languages.component.scss']
})
export class CustomerLanguagesComponent implements OnInit {
  public readonly faLanguage = faLanguage;
  public optionsListDefault: SelectListItem<string>[];
  public optionsListEnabled: SelectListItem<string>[];
  public languageUsageStats: CustomerLanguageStats = {};
  public form: UntypedFormGroup;
  public isLoading = false;
  private customer: ViewCustomer;
  private currentDefaultLanguage: Languages;

  @ViewChild('enabledLanguagesSelect') enabledLanguagesSelect!: SelectListComponent;

  private get defaultLanguageControl(): AbstractControl {
    return this.form.get('default');
  }

  private get enabledLanguagesControl(): AbstractControl {
    return this.form.get('enabled');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { customer: ViewCustomer },
    private dialogRef: MatDialogRef<CustomerLanguagesComponent>,
    private dialog: MatDialog,
    private customersService: CustomersService,
    private languageService: LanguageService
  ) {
    this.customer = data.customer;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.initListeners();
    this.getLanguages();
    this.getLanguageStats();
    this.patchForm(this.customer);
    this.currentDefaultLanguage = this.customer.defaultLanguage;
  }

  public save(): void {
    const command: UpdateCustomerLanguagesCommand = {
      customerId: this.customer.customerId,
      defaultLanguage: this.defaultLanguageControl.value[0],
      allLanguages: this.enabledLanguagesControl.value
    }
    this.isLoading = true;
    this.customersService.updateCustomerLanguages(command)
      .subscribe({
        next: () => { this.isLoading = false; this.dialogRef.close(); },
        error: () => this.isLoading = false
      });
  }

  private initListeners(): void {
    this.defaultLanguageChangesListener();
  }

  private getLanguages(): void {
    if (this.customer) {
      this.optionsListDefault = this.languageService.getProperLanguages().map(item => ({ id: item, item }));
      this.setEnabledOptions(this.customer.defaultLanguage);
    }
  }

  private getLanguageStats() {
    if (this.customer) {
      this.customersService.getCustomerLanguageStats(this.customer.customerId).subscribe(stats => {
        this.languageUsageStats = stats;
      });
    }
  }

  private defaultLanguageChangesListener(): void {
    this.defaultLanguageControl.valueChanges
      .pipe(
        map(([language]) => language),
        switchMap(language => forkJoin([of(language), this.checkAllowedDefaultLanguage(language)])),
        untilDestroyed(this))
      .subscribe(([language, isAllowed]) => {
        if (language && isAllowed) {
          this.recalculateEnabledLanguages(language);
          this.currentDefaultLanguage = language;
        } else {
          this.defaultLanguageControl.setValue([this.currentDefaultLanguage], { emitEvent: false });
          if (language && !isAllowed) {
            this.warningDisallowedDefaultLanguage();
          }
        }
      });
  }

  private checkAllowedDefaultLanguage(language: Languages): Observable<boolean> {
    return language
      ? this.customersService.isAllowedAsDefaultLanguage(this.customer.customerId, language)
      : of(false);
  }

  private warningDisallowedDefaultLanguage(): void {
    const dialogRef = this.dialog.open(WarningDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.message = this.translate('modules.organisation.customerLanguages.warningDisallowedDefaultLanguage');
  }

  private recalculateEnabledLanguages(currentDefault: Languages): void {
    const enabledLanguages = <Languages[]>this.enabledLanguagesControl.value;
    if (!enabledLanguages.some(lang => lang === currentDefault)) {
      this.enabledLanguagesControl.setValue(
        [currentDefault, ...enabledLanguages.filter(lang => lang !== currentDefault)],
        { emitEvent: false });
    }
    this.setEnabledOptions(currentDefault);
  }

  private buildForm(): void {
    this.form = new UntypedFormGroup({
      default: new UntypedFormControl(null),
      enabled: new UntypedFormControl(null),
    });
  }

  private patchForm({ defaultLanguage, allLanguages }: ViewCustomer): void {
    this.form.patchValue({
      default: [defaultLanguage],
      enabled: allLanguages,
    }, { emitEvent: false });
  }

  private setEnabledOptions(defaultLanguage: Languages): void {
    this.optionsListEnabled = this.optionsListDefault.map(option => option.id === defaultLanguage
      ? { id: option.id, item: `${option.item} (${this.translate('modules.organisation.customerLanguages.default').toLowerCase()})` }
      : option);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  onEnabledLanguagesClick(item: SelectListItemView) {
    // Default language always should be enabled
    const defaultLang = (<Languages[]>this.defaultLanguageControl.value)[0];
    if (item.id === defaultLang) {
      return;
    }

    if (!item.selected) {
      this.enabledLanguagesSelect.select(item);
    } else {
      // When users use language, ask before deselect
      if ((this.languageUsageStats[item.id] ?? 0) > 0) {
        let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
        dialogRef.componentInstance.confirmMessage = this.translate('modules.organisation.customerLanguages.currentlyInUse');
        dialogRef.afterClosed().subscribe(result => {
          if (result === dialogResults.YES) {
            this.enabledLanguagesSelect.deselect(item);
          }
        });
      } else {
        this.enabledLanguagesSelect.deselect(item);
      }
    }
  }
}
