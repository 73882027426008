import { TransferItemUnit } from './transfer-item-unit';

export class UpdateBulkItemCommand {

  public constructor(
  public bulkItemId: string,
  public bulkItemCategory: string,
  public bulkItemName: string,
  public bulkItemShortName: string,
  public bulkItemNumber: string,
  public bulkItemCostCenter: string,
  public bulkItemCostType: string,
  public manufacturerId: string,
  public manufacturerName: string,
  public supplierName: string,
  public supplierId: string,
  public bulkItemUnit: TransferItemUnit,
  public bulkItemWeight: number,
  public bulkItemScanCode: string,
  public bulkItemBGLCode: string,
  public bulkItemBALCode: string,
  public nonReturnable: boolean) {}
}
