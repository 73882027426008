import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportReason, TelematicUnitImportResultInterface } from '../shared/telematic-unit-import-result.interface';
import { LanguageService } from '../../../shared/services/language.service';

@Component({
  selector: 'bh-telematic-units-administration-bulk-import-result',
  templateUrl: './telematic-units-administration-bulk-import-result.component.html',
  styleUrls: ['./telematic-units-administration-bulk-import-result.component.scss']
})
export class TelematicUnitsAdministrationBulkImportResultComponent {

  public result: string;

  constructor(private languageService: LanguageService,
              @Inject(MAT_DIALOG_DATA) data: {result: TelematicUnitImportResultInterface[]}) {
    this.result = this.buildResultString(data.result);
  }

  private buildResultString(result: TelematicUnitImportResultInterface[]) {
    return (result || [])
      .map(item => ` ${item.telematicsUnitId} : ${this.resolveReasonText(item.reason)} `)
      .join('\n');
  }

  private resolveReasonText(reason: ImportReason): string {
    switch (reason) {
      case ImportReason.ASSIGNED_TO_CUSTOMER:
        return this.translate('general.labels.importTeltonikaTelematicUnitsReasons.assignedToCustomer');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
