<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <bh-button-icon mat-dialog-close>
      <bh-icon icon="clear"></bh-icon>
    </bh-button-icon>
  </div>
  <h1 mat-dialog-title>{{'modules.fieldManagement.additionalFieldManagement.assignFields'|translate}}</h1>


  <div mat-dialog-content>
    <mat-form-field class="chips-select">
      <mat-label>{{'modules.fieldManagement.menu.additionalFields'|translate}}</mat-label>
      <mat-select bhSelectOverlayPanelClass [formControl]="control" multiple>

        <mat-select-trigger>
          <mat-chip-listbox>
            <mat-chip *ngFor="let selectedField of (selectedFields | async)"
              [removable]="true" (removed)="remove(selectedField)">
              <ng-container
                [ngTemplateOutlet]="fieldDisplay"
                [ngTemplateOutletContext]="{ field: selectedField }">
              </ng-container>
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-listbox>
        </mat-select-trigger>

        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'general.search'|translate"
            [noEntriesFoundLabel]="'general.labels.noSearchResults'|translate"
            [formControl]="searchControl">
          </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let field of (filteredFields | async)" [value]="field.additionalFieldId">
          <ng-container
            [ngTemplateOutlet]="fieldDisplay"
            [ngTemplateOutletContext]="{ field: field }">
          </ng-container>
        </mat-option>

      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <div>
      <bh-button *ngIf="canCreateField"
        color="primary"
        [disabled]="isLoading | async"
        (click)="createNewAdditionalField()">
        <bh-icon icon="add_circle"></bh-icon>
        {{ 'modules.fieldManagement.additionalFieldManagement.addNewField'|translate }}
      </bh-button>
    </div>

    <div fxLayoutGap="10px">
      <bh-button mat-dialog-close>{{'general.buttons.cancel'|translate}}</bh-button>
      <bh-button color="primary"
        [disabled]="isLoading | async"
        (click)="save()">
        {{'general.buttons.save'|translate}}
      </bh-button>
    </div>
  </div>
</div>

<ng-template #fieldDisplay let-field="field">
  <span>{{ field?.additionalFieldName | translatableString }}</span>
  <ng-container *ngIf="field.additionalFieldUnit as unit">
    <span>, ({{unit}})</span>
  </ng-container>
</ng-template>
