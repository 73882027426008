import { environment } from 'environments/environment';
import { ChatOverview } from '../contract/chat-overview.interface';
import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';
import { ChatWriteCommand } from '../contract/chat-write-command';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { ChatMessage } from '../contract/chat-message';
import { KeycloakService } from '../../../core/keycloak';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { Modules } from '../../../shared/enums/modules.enum';
import { ChatUser } from '../contract/chat-user.interface';

@Injectable()
export class CommunicationDataSource {
  private _chats: BehaviorSubject<ChatOverview[]> = new BehaviorSubject([]);
  private _currentChatMessages: BehaviorSubject<ChatMessage[]> = new BehaviorSubject([]);
  private _knownChatUsers: ReplaySubject<ChatUser[]> = new ReplaySubject<ChatUser[]>(1);

  public readonly chats: Observable<ChatOverview[]> = this._chats.asObservable();
  public readonly currentChatMessages: Observable<ChatMessage[]> = this._currentChatMessages.asObservable();
  public readonly knownChatUsers: Observable<ChatUser[]> = this._knownChatUsers.asObservable();

  private chatsPollingSub: Subscription;
  private messagesPollingSub: Subscription;

  constructor(private communicationService: CommunicationService,
              private authService: KeycloakService) {
  }

  pollChats(): void {
    if (this.authService.hasAuthority(Authorities.MESSAGE_CENTER_USE)
      && this.authService.hasModule(Modules.COMMUNICATION)) {
      this.chatsPollingSub = timer(0, 2000).subscribe(() => this.chatOverview());
    }
  }

  pollMessages(recipientId: string): void {
    if (this.authService.hasAuthority(Authorities.MESSAGE_CENTER_USE)
      && this.authService.hasModule(Modules.COMMUNICATION)) {
      this.messagesPollingSub = timer(0, 2000).subscribe(() => this.chatMessages(recipientId))
    }
  }

  stopChatsPoll(): void {
    if (this.chatsPollingSub) {
      this.chatsPollingSub.unsubscribe();
    }
  }

  stopMessagesPoll(): void {
    if (this.messagesPollingSub) {
      this.messagesPollingSub.unsubscribe();
    }
  }

  chatWrite(command: ChatWriteCommand) {
    this.communicationService.chatWrite(command).subscribe(res => {
      setTimeout(() => {
        this.chatMessages(command.recipientId);
      }, environment.DELAY_SHORTEST);
    });
  }

  chatOverview(): void {
    this.communicationService
      .chatOverview()
      .subscribe(res => {
        this._chats.next(res);
      });
  }

  public chatMessages(recipientId: string): void {
    this.communicationService
      .chatMessages(recipientId)
      .subscribe((messages: ChatMessage[]) => this._currentChatMessages.next((messages)));
  }

  resetChatMessages(): void {
    this._currentChatMessages.next([]);
  }

  public updateKnownUsers(): void {
    this.communicationService.knownChatUsers().subscribe(
      (chatUsers: ChatUser[]) => this._knownChatUsers.next(chatUsers));
  }
}
