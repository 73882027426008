import { of } from 'rxjs';
import { RolesStore } from './../../modules/userrole/shared/roles.store';
import { map } from 'rxjs/operators';

export class RoleNameInUseValidator {
  static isValid(value: string, realmType: string, rolesStore: RolesStore, oldRoleName?: string) {
    if (oldRoleName && value === oldRoleName) {
      return of(null);
    }
    return rolesStore.roleNameAvailable(value, realmType)
      .pipe(map(res => {
        return res ? { taken: true } : null;
      }));
  }
}
