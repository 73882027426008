<div class="transport-list-search" fxFlexFill fxLayout="column">
  <div class="transport-list-search__header" fxLayout="column">
    <div class="transport-list-search__header__title">
      {{'modules.transportation.transportList.transportTasks'|translate}}
    </div>
    <bh-sort-order #sortOrder
      [sortOrderOptions]="sortOrderOptions"
      (sortChanges)="onSortChange($event)"
    ></bh-sort-order>
    <div class="transport-list-search__header__input" fxLayoutAlign="start center">
      <input
        [placeholder]="'general.search'|translate"
        [formControl]="searchControl"
        class="hide-outline"
        data-osp-test="input-search">
      <button mat-button
        class="search-input-button"
        (click)="onSearch()"
        [matTooltip]="'general.search'|translate">
        <mat-icon class="more-icon">search</mat-icon>
      </button>
      <button mat-button
        class="search-input-button"
        (click)="onResetSearchTerm()"
        [matTooltip]="'general.buttons.resetSearchTerm'|translate">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="transport-list-search__result"
    [id]="containerIdentifiers.TRANSPORT_TASK"
    fxFlex
    fxLayout="column"
    fxLayoutGap="5px"
    cdkDropList
    cdkDropListSortingDisabled
    [cdkDropListConnectedTo]="containerIdentifiers.TRANSPORT_SCHEDULER"
    [cdkDropListData]="transports | async"
    [cdkDropListDisabled]="!hasAuthority(authorities.TRANSPORT_TASK_DISPOSITION)">
    <ng-container *ngIf="(transports | async).length > 0; else noResult">
      <bh-transport-list-item *ngFor="let transport of transports | async"
        class="transport-list-search__result__item"
        [transport]="transport"
        cdkDrag
        [class.drag-disabled]="isDataLoading | async"
        [cdkDragDisabled]="isDataLoading | async"
        [bhDraggableItemType]="dragType.TRANSPORT_TASK"
        [dropCellClassName]="'dhx_matrix_cell'"
        [cdkDragData]="transport"
        (cdkDragEnded)="dragEnded()"
        (click)="openDetailView(transport)">
        <div class="drag-item-preview" *cdkDragPreview fxLayout="row" fxLayoutAlign="start center">
          <span class="drag-item-preview__title">{{transport?.title}}</span>
        </div>
      </bh-transport-list-item>
    </ng-container>
  </div>

  <div fxFlex="60px">
    <mat-paginator
      (page)="onPaginateChange($event)"
      [length]="(pagination | async).totalElements"
      [pageIndex]="(pagination | async).index"
      [pageSizeOptions]="[5, 25, 50, 100]"
      [pageSize]="(pagination | async).size">
    </mat-paginator>
  </div>
</div>

<ng-template #noResult>
  <div class="transport-list-search__no-result" fxFlex fxLayout="row"
       fxLayoutAlign="center center">
    <div>{{'general.labels.noSearchResults'|translate}}</div>
  </div>
</ng-template>
