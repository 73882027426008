<div [formGroup]="settingsForm" class="timefence" fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
  <mat-button-toggle-group class="timefence__input-switcher" (change)="inputAccessabilityChanged($event)" multiple>
    <mat-button-toggle [checked]="isFieldVisible(controlNames.SETTINGS_TIMEFENCING_HOURS)"
                       [value]="fieldAccessabilityStatusOptions.OPERATING_HOURS">
      <div fxFlex fxLayout="row">
        <fa-icon [icon]="faClock"></fa-icon>
        <span>{{'general.operatingHour.pl'|translate}}</span>
      </div>
    </mat-button-toggle>
    <mat-button-toggle [checked]="isFieldVisible(controlNames.SETTINGS_TIMEFENCING_DISTANCE)"
                       [value]="fieldAccessabilityStatusOptions.DISTANCE">
      <div fxFlex fxLayout="row">
        <fa-icon [icon]="faRoute"></fa-icon>
        <span>{{'general.units.distance.s'|translate}}</span>
      </div>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div class="timefence__input-container" fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="50" fxLayout="column" *ngIf="isFieldVisible(controlNames.SETTINGS_TIMEFENCING_HOURS)">
      <mat-form-field>
        <mat-label>{{getFieldLabel(controlNames.SETTINGS_TIMEFENCING_HOURS)}}</mat-label>
        <input matInput type="number"
              [formControlName]="controlNames.SETTINGS_TIMEFENCING_HOURS">
        <mat-error *ngIf="hourControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
        <mat-error *ngIf="hourControl.hasError('min')">{{'shared.validation.number.equalOrGreater'|translate:{ value: 1 } }}</mat-error>
      </mat-form-field>
      <bh-explanation-card class="explanation" [text]="getFieldExplanation([controlNames.SETTINGS_TIMEFENCING_HOURS])"></bh-explanation-card>
    </div>

    <div fxFlex="50" fxLayout="column" *ngIf="isFieldVisible(controlNames.SETTINGS_TIMEFENCING_DISTANCE)">
      <mat-form-field>
        <mat-label>{{getFieldLabel(controlNames.SETTINGS_TIMEFENCING_DISTANCE)}}</mat-label>
        <input matInput type="number"
              [formControlName]="controlNames.SETTINGS_TIMEFENCING_DISTANCE">
        <mat-error *ngIf="distanceControl.hasError('required')">{{'shared.validation.requiredField'|translate}}</mat-error>
        <mat-error *ngIf="distanceControl.hasError('min')">{{'shared.validation.number.equalOrGreater'|translate:{ value: 1 } }}</mat-error>
      </mat-form-field>
      <bh-explanation-card class="explanation" [text]="getFieldExplanation([controlNames.SETTINGS_TIMEFENCING_DISTANCE])"></bh-explanation-card>
    </div>
  </div>

  <div class="timefence__table">
    <bh-timefence
      [timezone]="userTimezone"
      [formControlName]="controlNames.SETTINGS_TIMEFENCING_WEEK_DAY">
    </bh-timefence>
  </div>

  <div class="timefence__error-message">
    <span class="timefence__error-message__text" *ngIf="weekDayControl.hasError('required')">
      {{'modules.notification.alarmRuleAddEdit.timefenceRequired'|translate}}
    </span>
  </div>

  <bh-explanation-card class="timefence__explanation"
                       [text]="getFieldExplanation(controlNames.SETTINGS_TIMEFENCING_WEEK_DAY)">
  </bh-explanation-card>
</div>
