<bh-basic-widget [widget]="widget"
                 [index]="index"
                 (deleted)="onDeleted()">
  <ng-container widget-content>
    <div class="widget-main-content">
      <bh-map-global
          [mapSettings]="mapSettings"
          [showRefreshButton]="showRefreshButton"
          [autoRefreshMapMs]="REFRESH_MAP_RATE_MS"
          [ignoreLocationSearchTerms]="true"
          (mapSettingsChanges)="saveMapConfigs($event)"
      ></bh-map-global>
    </div>
  </ng-container>
</bh-basic-widget>
