<div class="m-view-current-loc" fxFlexFill fxLayout="column">
  <div class="m-view-current-loc__address" *ngIf="project?.projectAddress">
    <div class="title">
      {{'modules.disposition.map.projectAddress'|translate|uppercase}}
    </div>
    <div class="address-line">
      {{project?.projectAddress?.street}} {{project?.projectAddress?.streetNumber}}
    </div>
    <div class="address-line">
      {{project?.projectAddress?.postalCode}} {{project?.projectAddress?.city}}
    </div>
  </div>
  <div fxFlex *ngIf="project?.projectAddress && project?.location">

<!-- // TODO: Clean up commented fragment after the task BEUT-20502 is completed -->
    <!-- <agm-map bhCustomMap
             [latitude]="project?.location.lat"
             [longitude]="project?.location.lon"
             [zoom]="13"
             fxFlex>
      <agm-marker-cluster [gridSize]="50">
        <agm-marker [latitude]="project?.location.lat" [longitude]="project?.location.lon">
        </agm-marker>
      </agm-marker-cluster>
    </agm-map> -->
  </div>

  <div *ngIf="!project?.projectAddress || !project?.location">
    {{'general.labels.cannotBeDisplayed.invalidAddress'|translate}}
  </div>
</div>
