import { KeycloakService } from './keycloak';
import { environment } from '../../environments/environment';

export class MultiTenancy {

  /**
   * Method builds URL for currently active tenant
   *
   * @param url base OSP url
   * @return url for currently active tenant
   */
  public static prepareUrl(url: string): string {
    if (KeycloakService.isWackerNeusonDomain()) {
      return MultiTenancy.replaceUrl(url, environment.APP_DOMAIN, environment.APP_WACKER_NEUSON_DOMAIN);
    } else if (KeycloakService.isTriathlonDomain()) {
      return MultiTenancy.replaceUrl(url, environment.APP_DOMAIN, environment.APP_TRIATHLON_DOMAIN);
    } else {
      return url;
    }
  }

  private static replaceUrl(url: string, base: string, replacement: string): string {
    if (url.includes(base)) {
      return url.replace(base, replacement);
    } else if (base.startsWith('app.')) {
      base = base.replace('app.', '');
      replacement = replacement.replace('app.', '');
      return url.replace(base, replacement)
    }
    return url;
  }
}
