import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { EquipmentFilterCollectionView } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-collection-view.interface';
import { EquipmentFilterType } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-type.interface';
import { EquipmentStatusCategoryPipe } from 'app/shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';
import { FilterSingleOptionDisplayNamePipe } from 'app/shared/pipes/filter-single-option-display-name.pipe';
import { forkJoin, Observable } from 'rxjs';
import { EquipmentTransferFilterParams } from '../contract/request-params/equipment-transfer-filter-params.class';
import { EquipmentFiltersBaseService } from 'app/shared/contract/filter/equipment/equipment-filters-base-service.class';
import { SearchFilterMediatorService } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator.service';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';


@Injectable()
export class EquipmentTransferFilterService extends EquipmentFiltersBaseService {

  constructor(
    equipmentsService: EquipmentsService,
    organisationsService: OrganisationsService,
    authService: KeycloakService,
    singleOptionDisplayNameResolver: FilterSingleOptionDisplayNamePipe,
    equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    equipmentStatusNamePipe: EquipmentStatusNamePipe,
    protected translatableStringPipe: TranslatableStringPipe,
    filterMediator: SearchFilterMediatorService,
  ) {
    super(
      new EquipmentTransferFilterParams(),
      equipmentsService,
      organisationsService,
      authService,
      singleOptionDisplayNameResolver,
      equipmentStatusCategoryPipe,
      equipmentStatusNamePipe,
      translatableStringPipe,
      filterMediator,
    );
  }

  protected getFilters(): Observable<EquipmentFilterCollectionView> {
    return forkJoin({
      [EquipmentFilterType.ORGANISATION]: this.getEquipmentOrganisations(),
      [EquipmentFilterType.ASSIGNED_EMPLOYEE]: this.getResponsiblePeople(),
      [EquipmentFilterType.LABEL]: this.getCustomerLabels(),
      [EquipmentFilterType.CLASS]: this.getEquipmentTypes(),
      [EquipmentFilterType.STATUS]: this.getNewEquipmentStatuses(),
      [EquipmentFilterType.WITHOUT_SUBEQUIPMENTS]: this.getSubequipmentOption(),
      [EquipmentFilterType.MATCH_ALL_LABELS]: this.getMatchAllLabelsOption()
    });
  }
}
