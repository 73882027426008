import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EquipmentMaintenanceTask } from '../../../../../../contract/equipment-maintenance-task';
import { byTypeFieldName } from '../../../../../../../../shared/contract/task-completion/maintenance-field-value';
import { EquipmentMaintenanceTaskService } from '../../../../../../shared/services/equipment-maintenance-task.service';
import { FieldType } from '../../../../../../../../shared/enums/field-type';
import { LanguageService } from '../../../../../../../../shared/services/language.service';
import { LifeCycleTask } from '../../../../../../contract/life-cycle-task';

@Component({
  selector: 'bh-task-event',
  templateUrl: './task-event.component.html'
})

@UntilDestroy()
export class TaskEventComponent implements OnInit {

  @Input() maintenanceTaskLifeCycle: LifeCycleTask;

  public task: EquipmentMaintenanceTask;
  public FieldType = FieldType;

  constructor(protected equipmentMaintenanceTaskService: EquipmentMaintenanceTaskService,
              protected languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.getMaintenanceTask();
  }

  public getMaintenanceTask(): void {
    this.equipmentMaintenanceTaskService.getById(this.maintenanceTaskLifeCycle.maintenanceTaskId)
      .pipe(untilDestroyed(this))
      .subscribe((task: EquipmentMaintenanceTask) => {
          if (task) {
            this.task = task;
            this.task.maintenanceTaskCompletionInfo.maintenanceFieldValues.sort(byTypeFieldName());
          }
        },
        error => {
          console.log('error get invoice details: ', error);
        });
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
