import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TransferStock } from './transfer-stock';
import { StockStore } from '../../../../stocks/shared/stock.store';
import { SearchStock } from '../../../../stocks/shared/search-stock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { environment } from 'environments/environment';
import { TransferCartColumnService } from 'app/modules/disposition/shared/services/transfer-target-column.service'
import { BulkItemStockToStockTransferCartService } from '../../../service/bulk-item-stock-to-stock-transfer-cart.service';
import { ViewStock } from 'app/modules/disposition/contract/view-stock';
import { TransferBackToStockDialogService } from '../../../../shared/services/transfer-back-to-stock-dialog.service';
import { ActivatedRoute } from '@angular/router';
import { TransferCartTypes } from 'app/shared/enums/transfer-cart-types.enum';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-transfer-stock-select',
  templateUrl: './bulk-item-transfer-stock-select.component.html',
  styleUrls: ['./bulk-item-transfer-stock-select.component.scss'],
  providers: [StockStore]
})
export class BulkItemTransferStockSelectComponent implements OnInit, OnDestroy {

  @Input() consumeEnabled: boolean;
  @Input() hiddenStockId: string;

  @Output() selectedStock: EventEmitter<TransferStock> = new EventEmitter<TransferStock>();
  @Output() selectedConsumed: EventEmitter<void> = new EventEmitter<void>();

  public readonly faTrashAlt: IconDefinition = faTrashAlt;

  public stocks: SearchStock[];
  public searchForm: UntypedFormGroup;
  public selectedStockId: string;
  public isConsumedSelected = false;
  public isShowStocks = true;
  public paginatorParams = {
    index: 0,
    size: 0,
    length: 0,
  }
  public sourceStocks: ViewStock[] = [];
  private pageEvent: PageEvent;
  private transferCartType: TransferCartTypes;

  public get searchValue(): string {
    return this.searchForm.get('terms').value;
  }

  constructor(public stockStore: StockStore,
              public columnService: TransferCartColumnService,
              public transferBackToStockDialogService: TransferBackToStockDialogService,
              private bulkItemStockToStockTransferCartService: BulkItemStockToStockTransferCartService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder) {
  }

  public ngOnInit(): void {
    this.setTransferCartType();
    this.getUserPageSize();
    this.stockStore.withoutRestriction = false;
    this.createSearchForm();
    this.initSubscriptions();
    this.onSearchFormType();
  }

  public ngOnDestroy(): void {
    this.stockStore.withoutRestriction = false;
    if (this.transferCartType === TransferCartTypes.CLEAR_PROJECT) {
      this.transferBackToStockDialogService.reset();
    }
    if (this.pageEvent) {
      this.stockStore.handleStockTransferPageEvent(this.pageEvent);
    }
  }

  public onPaginateChange(event: PageEvent): void {
    this.pageEvent = event;
    this.paginatorParams.index = event.pageIndex;
    this.paginatorParams.size = event.pageSize;
    switch (this.transferCartType) {
      case TransferCartTypes.CLEAR_PROJECT:
        this.transferBackToStockDialogService.setPaginatorIndex(event.pageIndex);
        this.transferBackToStockDialogService.setPaginatorSizeAndGetStocks(event.pageSize);
        break;
      case TransferCartTypes.STOCK_TO_STOCK_CART:
        this.bulkItemStockToStockTransferCartService.setPaginatorParams(this.paginatorParams);
    }
  }

  public selectStock(stock: SearchStock): void {
    this.transferBackToStockDialogService.setIsSelectedStockVisible(true);
    this.selectedStockId = stock.stockId;
    this.isConsumedSelected = false;
    this.selectedStock.emit({stockId: stock.stockId, stockName: stock.name, stockAddress: stock.address});
  }

  public selectConsumed(): void {
    this.unselectStock();
    this.isConsumedSelected = true;
    this.selectedConsumed.emit();
  }

  public reset(): void {
    this.searchForm.reset();
    this.isConsumedSelected = false;
    this.unselectStock();
  }

  private getUserPageSize(): void {
    this.columnService.transferCartStockViewPageSize
      .pipe(untilDestroyed(this))
      .subscribe(page => this.paginatorParams.size = page);
  }

  private createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      terms: ''
    });
  }

  private onSearchFormType(): void {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(() => this.setSearchValue());
  }

  private initSubscriptions(): void {
    switch (this.transferCartType) {
      case TransferCartTypes.CLEAR_PROJECT:
        this.transferBackToStockDialogService.setPaginatorSize(this.paginatorParams.size);
        this.clearProjectStocksSubscription();
        this.transferBackToStockDialogService.getStocks();
        break;
      case TransferCartTypes.STOCK_TO_STOCK_CART:
        this.bulkItemStockToStockTransferCartService.setPaginatorParams(this.paginatorParams);
        this.stockToStockCartStocksSubscription();
        this.stockToStockCartPaginatorParamsSubscription();
        this.updatestockToStockCartStocks();
        break;
    }
  }

  private stockToStockCartStocksSubscription(): void {
    this.bulkItemStockToStockTransferCartService.stocks
      .pipe(untilDestroyed(this))
      .subscribe(stocks => {
        this.stocks = stocks;
        this.isShowStocks = Boolean(stocks.length);
    })
  }

  private updatestockToStockCartStocks(): void {
    this.bulkItemStockToStockTransferCartService.setPaginatorParams(this.paginatorParams);
    this.bulkItemStockToStockTransferCartService.updateStocks(this.route.snapshot.params.sourceId);
  }

  private stockToStockCartPaginatorParamsSubscription(): void {
    this.bulkItemStockToStockTransferCartService.paginatorParams
      .pipe(untilDestroyed(this))
      .subscribe(params => this.paginatorParams = params);
  }

  private unselectStock(): void {
    this.selectedStockId = null;
    this.selectedStock.emit(null);
  }

  private clearProjectStocksSubscription(): void {
    this.transferBackToStockDialogService.stocks
      .pipe(untilDestroyed(this))
      .subscribe(stocks => {
        this.stocks = stocks;
        if (this.selectedStockId) {
          stocks.filter(stock => stock.stockId === this.selectedStockId).length
            ? this.transferBackToStockDialogService.setIsSelectedStockVisible(true)
            : this.transferBackToStockDialogService.setIsSelectedStockVisible(false);
        }

        this.sourceStocks = this.transferBackToStockDialogService.getSourceStocks();
        this.paginatorParams.index = this.transferBackToStockDialogService.getPaginatorIndex();
        this.paginatorParams.length = this.transferBackToStockDialogService.getPaginatorLength();
        this.isShowStocks = Boolean(stocks.length);
      });
  }

  private setSearchValue(): void {
    switch (this.transferCartType) {
      case TransferCartTypes.CLEAR_PROJECT:
        this.transferBackToStockDialogService.setSearchValue(this.searchValue);
        break;
      case TransferCartTypes.STOCK_TO_STOCK_CART:
        this.bulkItemStockToStockTransferCartService.setSearchValue(this.searchValue);
        break;
    }
  }

  private setTransferCartType(): void {
    switch (this.route.snapshot.url.pop().path) {
      case TransferCartTypes.STOCK_TO_STOCK_CART:
        this.transferCartType = TransferCartTypes.STOCK_TO_STOCK_CART;
        break;
      case TransferCartTypes.CLEAR_PROJECT:
        this.transferCartType = TransferCartTypes.CLEAR_PROJECT;
        break;
    }
  }
}
