import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { faInfoCircle, faStickyNote } from '@fortawesome/pro-light-svg-icons';
import { LocalUserStorageService } from 'app/shared/services/local-user-storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Params } from '@angular/router';
import { ContactDataSource } from '../../../shared/services/contact.datasource';
import { ContactView } from 'app/modules/contact/contract/contact/contact-view.interface';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from 'app/core/keycloak';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';

@UntilDestroy()
@Component({
  selector: 'bh-contact-view-detail',
  templateUrl: './contact-view-detail.component.html',
  styleUrls: ['./contact-view-detail.component.scss']
})
export class ContactViewDetailComponent extends RoleAuthorityGuardsComponent implements OnInit, AfterViewInit {
  @ViewChild('detailTabGroup', { static: true }) public detailTabGroup: MatTabGroup;
  public readonly faInfoCircle: IconDefinition = faInfoCircle;
  public readonly faStickyNote: IconDefinition = faStickyNote;
  public readonly authorities = Authorities;
  public readonly maxDescriptionLength = FieldLimit.ENTITY_DESCRIPTION;
  public contact: ContactView;

  private readonly localStorageKey: string = 'contact-details-tab';

  constructor(
      private route: ActivatedRoute,
      private contactDataSource: ContactDataSource,
      private localStorageService: LocalUserStorageService,
      authService: KeycloakService
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.handleRouting();
    this.currentContactListener();
  }

  public ngAfterViewInit(): void {
    const selectedTab = Number(this.localStorageService.getUserValue(this.localStorageKey));
    if (selectedTab >= 0 && selectedTab <= this.detailTabGroup._tabs.length) {
      this.detailTabGroup.selectedIndex = selectedTab;
    } else {
      this.localStorageService.setUserValue(this.localStorageKey, '0');
    }
  }

  public updateTab(event: MatTabChangeEvent): void {
    this.localStorageService.setUserValue(this.localStorageKey, `${event.index}`);
  }

  public saveDescription(description: string): void {
    this.contactDataSource.setDescription({ contactId: this.contact.contactId, description }).subscribe();
  }

  private handleRouting(): void {
    this.route.params
    .pipe(untilDestroyed(this))
    .subscribe((params: Params) => {
      if (params.id) {
        const contactId = params.id;
        this.contactDataSource.changeCurrentContact(contactId);
      }
    });
  }

  private currentContactListener(): void {
    this.contactDataSource.currentContact
      .pipe(untilDestroyed(this))
      .subscribe(currentContact => this.contact = currentContact);
  }
}
