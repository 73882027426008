import { OnDestroy, OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../core/keycloak';
import { RoleAuthorityGuardsComponent } from '../../../shared/navigation-guards/role-authority-guards.component';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { Modules } from '../../../shared/enums/modules.enum';
import { Subscription, timer } from 'rxjs';
import { NotificationDataSource } from '../../notifications/shared/services/notification.datasource';
import { CommunicationService } from '../../communication/shared/communication.service';
import { filter, switchMapTo } from 'rxjs/operators';

const MEDIUM_FONT_CONDITION_SYMBOLS = 20;
const SMALL_FONT_CONDITION_SYMBOLS = 40;

@Directive()
export abstract class BaseHeaderComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  unreadCount = 0;
  isOnline: boolean;
  private isOnlineSub: Subscription;

  constructor(protected authService: KeycloakService,
              protected onlineStatusService: OnlineStatusService,
              protected communicationService: CommunicationService,
              protected notificationStore: NotificationDataSource) {
    super(authService);
  }

  ngOnInit() {
    this.getUnread();
    this.isOnlineSub = this.onlineStatusService.isOnline.subscribe(isOnline => this.isOnline = isOnline);
    this.getAlarms();
  }

  userFirstName(): string {
    return this.authService.getUserFirstName();
  }

  userLastName(): string {
    return this.authService.getUserLastName();
  }

  userFullNameClassesHandler() {
    const firstNameLength = this.userFirstName()?.length ?? 0;
    const lastNameLength = this.userLastName()?.length ?? 0;

    return {
      'medium-text':
        (firstNameLength >= MEDIUM_FONT_CONDITION_SYMBOLS
          && firstNameLength < SMALL_FONT_CONDITION_SYMBOLS)
        || (lastNameLength >= MEDIUM_FONT_CONDITION_SYMBOLS
          && lastNameLength < SMALL_FONT_CONDITION_SYMBOLS),
      'small-text':
        firstNameLength >= SMALL_FONT_CONDITION_SYMBOLS
        || lastNameLength >= SMALL_FONT_CONDITION_SYMBOLS
    };
  }

  protected getUnread(): void {
    if (this.authService.hasAuthority(Authorities.MESSAGE_CENTER_USE)
      && this.authService.hasModule(Modules.COMMUNICATION)) {
      timer(0, 5000).pipe(
        filter(() => !this.authService.isTokenExpired()),
        switchMapTo(this.communicationService.chatUnread())
      ).subscribe((unreadCount: number) => this.unreadCount = unreadCount);
    }
  }

  ngOnDestroy(): void {
    this.isOnlineSub.unsubscribe();
  }

  private getAlarms() {
    if (this.authService.hasModule(Modules.ALARMS) &&
      this.authService.hasAuthority(Authorities.NOTIFICATIONS_VIEW_ALARMS)) {

      timer(0, 30000).subscribe(() => {
        if (!this.authService.isTokenExpired()) {
          this.notificationStore.getCurrentActive();
          this.onlineStatusService.setOnline();
        }
      });
    }
  }
}
