export enum matomoActions {
  ADD_ASSET_DIALOG_OPEN = 'add-asset-dialog-open',
  ADD_ASSET_DIALOG_SAVE = 'add-asset-dialog-save',

  // Equipment List
  COLUMN_CONFIG_OPEN = 'column-config-open',
  COLUMN_CONFIG_SAVE = 'column-config-save',
  COLUMN_CONFIG_CANCEL = 'column-config-cancel',
  COLUMN_CONFIG_SET_DEFAULT = 'column-config-set-default',
  EXPORT_EQUIPMENT_OPEN = 'export-equipment-open-dialog',
  EXPORT_EQUIPMENT_EXPORT = 'export-equipment-export',
  EXPORT_EQUIPMENT_CANCEL = 'export-equipment-cancel',
  CHANGE_PAGE_SIZE = 'change-page-size',
  FULLSCREEN = 'full-screen',
  FILTER = 'filter',
  EQUIPMENT_SUBEQUIPMENT_ELAPSE = 'equipment-subequipment-elapse',
  EQUIPMENT_SUBEQUIPMENT_COLLAPSE = 'equipment-subequipment-collapse',
  FIELD_MANAGEMENT_EQUIPMENT_STATUS_OPEN = 'field-management-equipment-status-open',
  FIELD_MANAGEMENT_EQUIPMENT_STATUS_SAVE = 'field-management-equipment-status-save',
  FIELD_MANAGEMENT_EQUIPMENT_STATUS_DELETE = 'field-management-equipment-status-delete',

  // OSP-Shop
  OSP_SHOP_LINK_CLICKED = 'osp-shop-link-clicked',

  // Transfer List
  TRANSFER_BULK_ITEM_OPEN = 'transfer-bulk-item-open',
  TRANSFER_EQUIPMENT_OPEN = 'transfer-equipment-open',

  // Generate reporting
  START_GENERATE_REPORT = 'start-generate-report',
  END_GENERATE_REPORT = 'end-generate-report',

  // Maintenance List

  EXPORT_MAINTENANCE_OPEN = 'export-maintenance-open-dialog',
  EXPORT_MAINTENANCE_CANCEL = 'export-equipment-cancel',
  EXPORT_MAINTENANCE_EXPORT = 'export-equipment-export',

  // Global transfer log

  EXPORT_GLOBAL_TRANSFER_LOG_OPEN = 'export-global-transfer-log-dialog-open',
  EXPORT_GLOBAL_TRANSFER_LOG_CANCEL = 'export-global-transfer-log-cancel',
  EXPORT_GLOBAL_TRANSFER_LOG_EXPORT = 'export-global-transfer-log-export',

  // Equipment Usage History

  EXPORT_USAGE_HISTORY_OPEN = 'export-usage-history-dialog-open',
  EXPORT_USAGE_HISTORY_CANCEL = 'export-usage-history-cancel',
  EXPORT_USAGE_HISTORY_EXPORT = 'export-usage-history-export',

  // Equipment Info
  EQUIPMENT_ACTIONS = 'equipment-actions',
  EQUIPMENT_VIEW_GERNERAL = 'equipment-view-general',
  EQUIPMENT_VIEW_TIMELINE = 'equipment-view-timeline',
  EQUIPMENT_VIEW_COSTS = 'equipment-view-costs',
  EQUIPMENT_VIEW_MAINTENANCE = 'equipment-view-maintenance',
  EQUIPMENT_VIEW_TELEMATICS = 'equipment-view-telematics',
  EQUIPMENT_VIEW_DISPOSITION = 'equipment-view-disposition',
  EQUIPMENT_VIEW_USAGE = 'equipment-view-usage',
  EQUIPMENT_VIEW_SUBEQUIPMENT = 'equipment-view-subequipment',
  EQUIPMENT_VIEW_CONTRACT = 'equipment-view-contract',
  EQUIPMENT_VIEW_MAP = 'equipment-view-map',

  // Telematic
  ASSIGN_TELEMATIC_UNIT = 'assign-telematic-unit',
  SELECTED_TYPE = 'selected-type-',
  PROFILE_SELECTED_TYPE = 'selected-profile-',
  PROFILE_TOOLTIP_HOVER = 'profile-tooltip-hover',

  // Telematic Assign Dialog
  SUCCESFUL_CLOSING = 'dialog-closed-succesful',
  ABORTED_CLOSING = 'dialog-closed-aborted',
  NEXT_STEP = 'next-step-',
  BACK_STEP = 'step-back-',

  // Digital Matter Profile
  CHANGE_STARTED = 'change-started',
  CHANGE_SUCCESSFUL = 'change-successful',

}
