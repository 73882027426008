import { environment } from 'environments/environment';
import { LanguageService } from '../../../../shared/services/language.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, pipe } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteRoleCommand } from '../../contract/delete-role-command';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { RolesStore } from '../../shared/roles.store';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { UsersStore } from '../../shared/users.store';
import { UpdateUserCommand } from '../../contract/update-user-command';
import { ConfirmationDialogDeleteUserRoleComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog-delete-user-role/confirmation-dialog-delete-user-role.component';
import { UpdateCustomerRolesCommand } from '../../../organisation/contract/update-customer-roles-command';
import { ViewCustomer } from '../../../organisation/contract/view-customer.interface';
import { CustomersService } from '../../../organisation/shared/customers.service';
import { ViewRole } from '../../contract/view-role.interface';
import { BrzBlacklistAuthorities } from '../../../../shared/enums/brz-blacklist-authorities.enum';
import { ViewUser } from '../../contract/view-user.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { RealmType } from '../../../../shared/contract/realm-type';
import { RoleRealmDefinition } from '../../shared/interfaces/role-realm-definition.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';

@UntilDestroy()
@Component({
  selector: 'bh-role-list',
  templateUrl: 'role-list.component.html',
  styleUrls: ['role-list.component.scss']
})
export class RoleListComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit, OnDestroy {

  public faCopy = faCopy;
  public displayedColumns: string[] = ['name', 'description', 'lastChanged'];
  public roles: Observable<ViewRole[]>;
  searchForm: UntypedFormGroup;

  private customers: ViewCustomer[] = [];
  private brzCustomers: string[] = [];
  private brzRoles: string[] = [];
  private realms: RoleRealmDefinition[] = [];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private usersStore: UsersStore,
              public rolesStore: RolesStore,
              private customersService: CustomersService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);

    this.realms = [
      {
        value: RealmType.DEFAULT,
        name: this.translate('modules.userrole.roleRealm.default')
      },
      {
        value: RealmType.WACKER_NEUSON,
        name: this.translate('modules.userrole.roleRealm.wackerNeuson')
      },
      {
        value: RealmType.TRIATHLON,
        name: this.translate('modules.userrole.roleRealm.triathlon')
      }
    ];
  }

  ngOnInit(): void {
    this.columnPermissionHandler();
    this.rolesStore.getRoles();

    const isPartnerAdmin = this.isPartnerAdmin();
    this.roles = this.rolesStore.connect()
      .pipe(map(roles => roles.filter((role: ViewRole) => {
        return !(isPartnerAdmin && role.name === PrivilegedRole.Flottenadmin.toString());
      })));

    this.buildSearchForm();
  }

  ngAfterViewInit(): void {
    this.filterEvent();
  }

  ngOnDestroy(): void {
  }

  public editRole(roleId: string): void {
    this.router.navigate(['/roles/edit', roleId]);
  }

  public navigateToEdit(roleId: string): void {
    this.router.navigate(['/roles/edit', roleId]);
  }

  public navigateToClone(roleId: string): void {
    this.router.navigate(['/roles/clone', roleId]);
  }

  private columnPermissionHandler(): void {
    if (this.hasAuthority(this.authorities.SUPERADMIN_CREATE)) {
      this.displayedColumns.push('realmType');
    }

    if (this.hasAuthority(this.authorities.ROLE_CREATE)) {
      this.displayedColumns.push('cloneRole');
    }

    if (this.hasAuthority(this.authorities.ROLE_UPDATE)) {
      this.displayedColumns.push('editRole');
    }

    if (this.hasAuthority(this.authorities.ROLE_DELETE)) {
      this.displayedColumns.push('deleteRole');
    }
  }

  private filterEvent(): void {
    this.searchForm.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged(), pipe(untilDestroyed(this)))
      .subscribe(() => {
        if (!this.rolesStore) {
          return;
        }
        this.updateOnSearch();
      });
  }

  private buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl(),
    });
    this.setSearchTerms();
  }

  get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  private setSearchTerms(): void {
    this.searchForm.patchValue({terms: this.rolesStore.filter});
  }

  private updateOnSearch() {
    this.rolesStore.filter = this.termsValue;
    this.rolesStore.updateListing();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public initiateDeleteRole(roleId: string): void {
    this.usersStore.getUsersByRole(roleId)
    .pipe(untilDestroyed(this))
    .subscribe(users => {
      if (users.length > 0) {
        forkJoin(...this.getUniqueCustomerIds(users)
        .map(customerId => this.customersService.getCustomer(customerId)))
        .subscribe((res: ViewCustomer[]) => {
          this.customers = res;
          this.canDelete(users, roleId) ? this.deleteAssignedRole(users, roleId) : this.showDeletionWarning(users);
        });
      } else {
        this.deleteUnassignedRole(roleId);
      }
    });
  }

  private canDelete(users: ViewUser[], roleId: string): boolean {
    return !this.roleIsAssignedToExternalUser(users) && this.alternativeRolesAvailable(roleId);
  }

  private showDeletionWarning(users: ViewUser[]) {
    let message = this.roleIsAssignedToExternalUser(users)
      ? this.translate('modules.userrole.roleList.roleIsExternalAssigned')
      : this.translate('modules.userrole.roleList.roleNoAlternativeRole');
    this.snackBar.open(message, this.translate('general.buttons.okay'));
  }

  private deleteUnassignedRole(roleIdDelete: string): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.userrole.roleList.deleteRole');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.userrole.roleList.deleteRoleMessage');
    dialogRef.componentInstance.secondaryConfirmMessage = this.translate('modules.userrole.roleList.deleteRoleMessageSecondary');
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe((result: string) => {
      if (result === dialogResults.YES) {
        this.deleteRole(roleIdDelete);
      }
    });
  }

  private deleteAssignedRole(users: ViewUser[], roleIdDelete: string): void {
    let dialogRef: MatDialogRef<ConfirmationDialogDeleteUserRoleComponent> =
      this.dialog.open(ConfirmationDialogDeleteUserRoleComponent);
    dialogRef.componentInstance.alternativeRoles =
      this.rolesStore.data.filter(res => !this.isInPrivilegedRole(res.name) && res.roleId !== roleIdDelete);
    dialogRef.componentInstance.affectedUsers = users;
    dialogRef.componentInstance.affectedCustomers = this.customers;
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe((result: any[]) => {
      if (result[0] === dialogResults.YES && result[1].length > 0) {
        this.brzRoles = this.getRoleIdsWithoutBRZRoles(result[1]);
        this.updateCustomers(result[1], roleIdDelete);
        setTimeout(() => this.deleteRole(roleIdDelete), environment.DELAY_LONG);
        setTimeout(() => this.usersStore.updateUsers(this.createUpdateUserCommands(users, roleIdDelete, result[1]))
          .pipe(untilDestroyed(this)).subscribe(),
          environment.DELAY_LONG);
      }
    });
  }

  private deleteRole(roleIdDelete: string): void {
    let cmd: DeleteRoleCommand = new DeleteRoleCommand();
    cmd.roleId = roleIdDelete;
    this.rolesStore.deleteRole(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
      },
      (error: HttpErrorResponse) => {
        console.log('Delete Role error', error.message);
      }
    );
  }

  private updateCustomers(roles: ViewRole[], roleId: string): void {
    this.updateCustomerRoles(this.createUpdateCustomerCommands(roles, roleId));
  }

  private createUpdateUserCommands(users: ViewUser[], roleIdDelete: string, roles: ViewRole[]): UpdateUserCommand[] {
    let roleIds = roles.map(el => el.roleId) as string[];
    let rolesToAssign = roleIds;

    let cmds: UpdateUserCommand[] = [];
    users.forEach(user => {
      let cmd: UpdateUserCommand = new UpdateUserCommand();
      rolesToAssign = roleIds;
      if (this.brzCustomers.includes(user.customer)) {
        rolesToAssign = this.brzRoles;
      }

      cmd.userId = user.userId;
      cmd.firstName = user.firstName;
      cmd.name = user.name;
      cmd.mobileNumber = user.mobileNumber;
      cmd.validUntil = user.validUntil;

      let data: string[] = user.roles.map(role => role.id).concat(rolesToAssign);
      cmd.roleIds = data.filter((value, index) => data.indexOf(value) === index && value !== roleIdDelete);

      cmd.organisationIds = user.organisationList;
      cmd.customerId = user.customer;
      cmds.push(cmd);
    });
    this.brzCustomers = [];
    return cmds;
  }

  private updateCustomerRoles(cmds: UpdateCustomerRolesCommand[]): void {
    let requests: any[] = [];
    cmds.forEach(cmd =>
      requests.push(this.customersService.updateRoles(cmd))
    );
    forkJoin(requests).pipe(untilDestroyed(this)).subscribe();
  }

  private createUpdateCustomerCommands(roles: ViewRole[], roleID: string): UpdateCustomerRolesCommand[] {
    let roleIDs = roles.map(el => el.roleId) as string[];
    let rolesToAssign = roleIDs;
    let cmds: UpdateCustomerRolesCommand[] = [];

    this.customers.forEach(customer => {
      rolesToAssign = roleIDs;
      if (customer.hasBrzConnector) {
        // Need this information for createUpdateUserCommands()
        this.brzCustomers.push(customer.customerId);
        rolesToAssign = this.brzRoles;
      }

      let cmd: UpdateCustomerRolesCommand = new UpdateCustomerRolesCommand();
      cmd.customerId = customer.customerId;

      let data: string[] = customer.roleIds.filter(element => element !== roleID).concat(rolesToAssign);
      // remove duplicates
      cmd.roleIds = data.filter((value, index) => data.indexOf(value) === index);
      cmd.fallbackRoleId = data[0];
      cmds.push(cmd);
    });
    return cmds;
  }

  private getRoleIdsWithoutBRZRoles(roles: ViewRole[]): string[] {
    let rolesWithoutBRZRoles: string[] = [];
    roles.forEach(role => {
      if (!role.authorities.some(authority => Object.keys(BrzBlacklistAuthorities).includes(authority))) {
        rolesWithoutBRZRoles.push(role.roleId);
      }
    });
    return rolesWithoutBRZRoles;
  }

  private alternativeRolesAvailable(roleId: string): boolean {
    return this.rolesStore.data.filter(res => !this.isInPrivilegedRole(res.name) && res.roleId !== roleId).length > 0;
  }

  private roleIsAssignedToExternalUser(users: ViewUser[]): boolean {
    return users.some(user => user.external);
  }

  private getUniqueCustomerIds(users: ViewUser[]): string[] {
    return [...new Set(users.map(user => user.customer))];
  }

  public getRealmTypeName(realmType: RealmType) {
    return this.realms.find(value => value.value === realmType) ? this.realms.find(value => value.value === realmType).name : realmType;
  }

  public resolveDeleteTooltip(canDelete: boolean): string {
    return canDelete
      ? 'modules.userrole.roleList.deleteRole'
      : 'modules.userrole.roleList.cantDeleteRole';
  }
}
