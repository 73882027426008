import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Authorities } from '../../../../shared/enums/authorities.enum';
import { ViewRole } from '../../contract/view-role.interface';
import { UpdateRoleCommand } from '../../contract/update-role-command';
import { PrivilegedAuthorities } from '../../../../shared/enums/privileged-authorities.enum';
import { KeycloakService } from '../../../../core/keycloak';
import { RolesStore } from '../../shared/roles.store';
import { HttpErrorResponse } from '@angular/common/http';
import { RoleAuthority } from '../../shared/interfaces/role-authority.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { validAuthCombinationsValidator } from '../../../../shared/custom-validators/valid-auth-combinations.validator';
import { RouterHistory } from '../../../../shared/router-history';
import { BrzBlacklistAuthorities } from '../../../../shared/enums/brz-blacklist-authorities.enum';
import { RoleNameInUseValidator } from 'app/shared/custom-validators/role-name-in-use.validator';
import { asyncValidatorFactory } from 'app/shared/custom-validators/async-validator.factory';
import { CreateRoleCommand } from '../../contract/create-role-command';
import {RoleBaseComponent} from '../common/role-base.component';
import {LanguageService} from '../../../../shared/services/language.service';
import {RealmType} from '../../../../shared/contract/realm-type';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';

@UntilDestroy()
@Component({
  selector: 'bh-role-edit',
  templateUrl: 'role-edit.component.html',
  styleUrls: ['role-edit.component.scss']
})
export class RoleEditComponent extends RoleBaseComponent implements OnInit, OnDestroy {

  rolesEditForm: UntypedFormGroup;
  roleId: string;
  role: ViewRole;
  isBrzRole = false;
  roleAuthorities: RoleAuthority[] = [];
  cloneMode = false;
  saveButtonPressed = false;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected activatedRoute: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              private formBuilder: UntypedFormBuilder,
              private rolesStore: RolesStore) {
    super(authService, router, activatedRoute, routerHistory, languageService);
  }

  public ngOnInit(): void {
    this.cloneMode = this.route.snapshot.url.some(({ path }) => path === 'clone');
    this.buildForm();
    this.getRole();
  }

  public ngOnDestroy(): void {
  }

  get roleName(): AbstractControl | null {
    return this.rolesEditForm.get('roleName')
  }

  get authoritiesFA(): UntypedFormArray {
    return this.rolesEditForm.get('authorities') as UntypedFormArray;
  }

  public navigateBack(): void {
    this.routerHistory.goBack('/roles/list');
  }

  public selectAuthority(authority: RoleAuthority): void {
    authority.isSelected = !authority.isSelected;
    this.authoritiesFA.markAsDirty();

    if (authority.isSelected) {
      authority.isSelected = true;
      this.authoritiesFA.push(new UntypedFormControl(authority.name));
    } else {
      let index: number = this.authoritiesFA.value.findIndex(au => au === authority.name);

      this.authoritiesFA.removeAt(index);
    }
  }

  public save(): void {
    if (this.cloneMode) {
      this.saveButtonPressed = true;
    }
    if (this.isValid()) {
      if (this.cloneMode) {
        this.cloneRole();
      } else {
        this.updateRole();
      }
    } else {
      this.saveButtonPressed = false;
    }
  }

  private updateRole(): void {
    let cmd: UpdateRoleCommand = new UpdateRoleCommand();
    let formValue = this.rolesEditForm.value;

    cmd.roleId = formValue.roleId;
    cmd.roleName = formValue.roleName;
    cmd.roleDescription = formValue.roleDescription;
    cmd.authorities = formValue.authorities;

    this.rolesStore.updateRole(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
        this.router.navigate(['roles/list']);
      },
      (error: HttpErrorResponse) => {
        console.log('role edit error: ', error);
      }
    );
  }

  private cloneRole(): void {
    let cmd: CreateRoleCommand = new CreateRoleCommand();
    let formValue = this.rolesEditForm.value;

    cmd.roleName = formValue.roleName;
    cmd.roleDescription = formValue.roleDescription;
    cmd.realmType = formValue.realmType;
    cmd.authorities = formValue.authorities;

    this.rolesStore.addRole(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
        this.saveButtonPressed = false;
        this.router.navigate(['/roles/list']);
      },
      (error: HttpErrorResponse) => {
        console.log('role clone error: ', error);
      }
    );
  }

  public isValid(): boolean {
    return this.rolesEditForm.valid;
  }

  private getAuthorities(): void {
    let authorities: string[] = Object.keys(Authorities);
    let privilegedAuthorities: string[] = Object.keys(PrivilegedAuthorities);
    authorities.map((authority: string) => {
      let existingAuthority: string = this.role.authorities.find(au => au === authority);
      let privilegedAuthority: string = privilegedAuthorities.find(pa => pa === authority);

      if (!privilegedAuthority) {
        if (existingAuthority) {
          this.roleAuthorities.push({
            name: authority,
            isSelected: true
          });
          this.authoritiesFA.push(new UntypedFormControl(authority));

        } else {
          this.roleAuthorities.push({
            name: authority,
            isSelected: false
          })
        }
      }
    });
  }

  private getIsBrzRole(): void {
    this.rolesStore.getIsBrzRole(this.role.roleId)
      .pipe(untilDestroyed(this))
      .subscribe(isBrzRole => {
        this.isBrzRole = isBrzRole;
        if (isBrzRole) {
          this.roleAuthorities = this.roleAuthorities
            .filter(auth => !Object.keys(BrzBlacklistAuthorities).includes(auth.name));
        }
      });
  }

  validationConflict(authority): boolean {
    let conflicting = false;

    if (this.authoritiesFA.errors
      && this.authoritiesFA.errors.invalidCombinations) {
      this.authoritiesFA.errors.invalidCombinations.forEach(comb => {
        if (comb.includes(authority.name)) {
          conflicting = true;
        }
      })
    }
    return conflicting;
  }

  private setFormValues(): void {
    this.rolesEditForm.patchValue({
      roleId: this.role.roleId,
      roleName: this.role.name,
      roleDescription: this.role.description,
      realmType: this.role.realmType,
    })
  }

  private getRole() {
    this.roleId = this.route.snapshot.params['id'];
    this.rolesStore.getRole(this.roleId)
      .pipe(untilDestroyed(this))
      .subscribe((res: ViewRole) => {
          if (res) {
            this.role = res;
            this.getAuthorities();
            if (!this.cloneMode) {
              this.getIsBrzRole();
            }
            this.setFormValues();
            this.setRoleNameInUseValidator();
          }
        },
        error => {
          console.log('role get error: ', error);
        }
      );
  }

  private buildForm(): void {
    this.rolesEditForm = this.formBuilder.group({
      roleId: ['', [<any>Validators.required]],
      roleName: ['', [<any>Validators.required, TrimValidator.hasWhitespaces]],
      realmType: [RealmType.DEFAULT, []],
      roleDescription: '',
      authorities: this.formBuilder.array([], Validators.compose([Validators.required, validAuthCombinationsValidator()]))
    });
  }

  private setRoleNameInUseValidator(): void {
    const validatorFn = asyncValidatorFactory((value) =>
      RoleNameInUseValidator.isValid(value, this.role.realmType, this.rolesStore, this.cloneMode ? null : this.role.name));
    this.roleName.setAsyncValidators(validatorFn);

    if (this.cloneMode) {
      this.roleName.updateValueAndValidity();
      this.roleName.markAsTouched();
    }
  }
}
