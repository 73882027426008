<div class="content" fxLayout="column" [formGroup]="searchForm">

  <div class="row-container" fxFlex="60px" fxLayoutAlign="start center" fxLayoutGap="20px">
    <input formControlName="terms"
           class="search-input"
           [placeholder]="'general.search'|translate"/>
  </div>

  <div *ngIf="isShowStocks"
       class="stocks" fxFlex fxLayout="column">
    <div *ngIf="consumeEnabled"
         class="consumed-wrapper" fxFlex="82px" fxLayout="column"
         [ngClass]="{'consumed-selected': isConsumedSelected}">
      <div class="row-container" fxFlex="70px" fxLayoutAlign="start center"
           [ngClass]="{'consumed-selected': isConsumedSelected}"
           (click)="selectConsumed()">
        <div class="row-element">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <span class="upper-row">
              {{'modules.disposition.bulkItemTransfer.consumed'|translate}} / {{'modules.disposition.bulkItemTransfer.lost'|translate}}
            </span>
            <span class="lower-row">{{'modules.disposition.bulkItemTransfer.bulkItemsNotMovedBack'|translate}}</span>
          </div>
        </div>
        <div class="icon-row-element">
          <fa-icon [icon]="faTrashAlt"></fa-icon>
        </div>
      </div>
    </div>
    <div *ngFor="let stock of stocks"
         class="row-container" fxFlex="70px" fxLayout="row" fxLayoutAlign="start center"
         [ngClass]="{'selected': selectedStockId === stock.stockId}"
         (click)="selectStock(stock)">
      <div class="row-element">
        <div fxFlex fxLayout="column" fxLayoutAlign="center start">
          <span class="upper-row" [ngClass]="{'unknown-stock': stock.unknownStock}">
            {{ stock.name }}
            <mat-icon *ngIf="stock.unknownStock && stock.stockType !== 'MAIN'"
                      [matTooltip]="'modules.disposition.bulkItemTransfer.anotherOrgStock'|translate">
              visibility_off
            </mat-icon>
          </span>
          <span class="lower-row" *ngIf="stock.address">{{ stock.address.postalCode }} {{ stock.address.city }}</span>
        </div>
      </div>
      <div class="row-element" fxFlex="0">
        <div fxFlex fxLayout="column" fxLayoutAlign="center start">
          <span class="upper-row uppercase">{{'general.type'|translate}}</span>
          <span class="lower-row">{{ stock.stockType | stockType }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isShowStocks"
       fxFlex="100" class="no-allowed-message">
    {{'modules.disposition.bulkItemTransfer.noAllowedStocks'|translate}}
    <div *ngFor="let sourceStock of sourceStocks">
      <span>{{sourceStock.stockName}}</span>
      <span *ngIf="sourceStock.organisationName"> ({{sourceStock.organisationName}})</span>
    </div>
  </div>

  <div class="row-container paginator-container">
    <mat-paginator *ngIf="isShowStocks"
                   (page)="onPaginateChange($event)"
                   [pageSizeOptions]="[5, 25, 50, 100]"
                   [pageIndex]="paginatorParams.index"
                   [pageSize]="paginatorParams.size"
                   [length]="paginatorParams.length">
    </mat-paginator>
  </div>
</div>
