<div class="list-view-main__right" fxFlexFill fxLayout="column">

  <div class="list-view-main__right-header" fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between center">
    <div (click)="displayService.displayDetailsFullscreen()" class="full-screen"
         fxFlex="0 0 70px" fxLayout="row" fxLayoutAlign="center center"
         [matTooltip]="'general.labels.detailView.enlarge'|translate" *ngIf="displayService.isTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div (click)="displayService.displayTableAndDetails()" class="full-screen" fxFlex="0 0 70px" fxLayout="row"
      fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.reduce'|translate" *ngIf="displayService.isDetailsFullscreen()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>
    <div class="name bulk-item-list-column" fxFlex="10 1 100%">
      <span>
        {{ employee?.employeeFirstName }} {{ employee?.employeeName }}
      </span>
    </div>

    <div fxFlex="0 0 20px" class="more" [matMenuTriggerFor]="employeeMore" [matTooltip]="'general.actions'|translate" *ngIf="showMoreMenu()">
      <mat-icon>more_vert</mat-icon>
    </div>

    <mat-menu #employeeMore="matMenu">
      <button mat-menu-item (click)="openEditDialog()"
        *ngIf="isActiveEmployeeAndHasAuthority(authorities.EMPLOYEE_MANAGE, employee)">
        <mat-icon>edit</mat-icon>
        <span>{{'general.buttons.edit'|translate}}</span>
      </button>

      <div [matTooltip]="'modules.disposition.employeeView.cannotDeactivate'|translate"
           [matTooltipDisabled]="!employee?.isTeamLeader">
        <button mat-menu-item (click)="employeeDeactivate(employee.employeeId)"
                *ngIf="isActiveEmployeeAndHasAuthority(authorities.EMPLOYEE_MANAGE, employee)"
                [disabled]="employee?.isTeamLeader">
          <mat-icon>lock</mat-icon>
          <span>{{'general.buttons.deactivate'|translate}}</span>
        </button>
      </div>

      <button mat-menu-item (click)="employeeActivate(employee.employeeId)"
        *ngIf="isInactiveEmployeeAndHasAuthority(authorities.EMPLOYEE_MANAGE, employee)">
        <mat-icon>lock_open</mat-icon>
        <span>{{'general.buttons.activate'|translate}}</span>
      </button>

      <button mat-menu-item (click)="openUserAssignDialog(employee.employeeId)"
              *ngIf="hasAnyAuthority([authorities.EMPLOYEE_MANAGE, authorities.USER_UPDATE]) && !employee?.assignedUserId">
          <mat-icon [matTooltip]="'modules.disposition.employeeView.linkUser'|translate">link</mat-icon>
          <span>{{'modules.disposition.employeeView.link'|translate}}</span>
      </button>

      <button mat-menu-item (click)="openUserUnassignDialog(employee)"
              *ngIf="hasAnyAuthority([authorities.EMPLOYEE_MANAGE, authorities.USER_UPDATE]) && employee?.assignedUserId">
          <mat-icon [matTooltip]="'modules.disposition.employeeView.unlinkUser'|translate">link_off</mat-icon>
          <span>{{'modules.disposition.employeeView.resolveLink'|translate}}</span>
      </button>

      <div [matTooltip]="'modules.disposition.employeeView.cannotDelete'|translate"
           [matTooltipDisabled]="isDeletable">
        <button mat-menu-item (click)="openDeleteDialog(employee.employeeId)"
                *ngIf="hasAuthority(authorities.EMPLOYEE_MANAGE)"
                [disabled]="!isDeletable">
          <mat-icon>delete_forever</mat-icon>
          <span>{{'general.buttons.delete'|translate}}</span>
        </button>
      </div>
    </mat-menu>

    <div (click)="displayService.displayTableFullscreen()" class="change-display-mode" fxFlex="0 0 60px" fxLayout="row"
      fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.collapse'|translate" *ngIf="!displayService.isTableFullscreen()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="submenu" fxFlex="70px" fxLayout="column" fxLayoutAlign="start stretch">
      <p [routerLink]="['general']" routerLinkActive="submenu--active">
        <mat-icon fontSet="icon-general_outline"></mat-icon>
        <span>{{'general.general.abbr'|translate}}</span>
        <span>{{'general.data'|translate}}</span>
      </p>
      <p *ngIf="showQualificationTab()" [routerLink]="['qualification']" routerLinkActive="submenu--active">
        <fa-icon class="menu-icon fa-flip-horizontal" class="fas fa-certificate" [icon]="faCertificate"></fa-icon>
        <span>{{'modules.disposition.employeeView.qualification'|translate}}</span>
      </p>
      <p [routerLink]="['disposition']" *ngIf="showDisposition()" routerLinkActive="submenu--active">
        <mat-icon fontSet="icon-disposition_outline"></mat-icon>
        <span>{{'general.disposition.s' |translate}}</span>
      </p>
    </div>
    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
