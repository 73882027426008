import { UntypedFormArray, ValidationErrors } from '@angular/forms';
import { FormArrayValidatorErrorType } from '../components/draggable-list/form-array-validator-error-type.enum';

export class FormArrayValidator {
  public static atLeastOneIsSelected(arrayControl: UntypedFormArray): ValidationErrors {
    return arrayControl.controls.some(control => Boolean(control.value))
      ? null
      : { [FormArrayValidatorErrorType.NONE_SELECTED]: true };
  }
}
