import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ViewEquipment } from '../../modules/equipment/contract/view-equipment.interface';
import { SearchEquipment } from '../../modules/equipment/contract/search-equipment.interface';
import { ScanEquipment } from '../../modules/equipment/shared/scan-equipment';

@Pipe({
  name: 'equipmentName'
})

@Injectable()
export class EquipmentNamePipe implements PipeTransform {
  transform(value: ViewEquipment | SearchEquipment | ScanEquipment): string {
    return value ? value.equipmentName || value.equipmentModel : '';
  }
}
