import { LanguageService } from 'app/shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../../../core/keycloak';
import { ProjectDataSource } from '../../../shared/project.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { ViewOrganisation } from '../../../../organisation/contract/view-organisation.interface';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { RouterHistory } from '../../../../../shared/router-history';
import { environment } from 'environments/environment';
import { untilDestroyed } from '@ngneat/until-destroy';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';

@Directive()
export abstract class BaseProjectMapComponent extends GuardedNavigableInputComponent implements OnInit {
  public searchForm: UntypedFormGroup;
  public organisations: ViewOrganisation[] = [];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              public projectStore: ProjectDataSource,
              protected organisationService: OrganisationsService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              private equipmentStore: EquipmentsDataSource,
              private contactDataSource: ContactDataSource) {
    super(authService, router, route, routerHistory);
  }

  public get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  public ngOnInit(): void {
    this.clearStoredLocations();
    this.buildSearchForm();
    this.getOrganisations();
    this.onSearchFormType();
  }

  public reset(): void {
    this.searchForm.reset();
  }

  public onSearchFormType(): void {
    this.searchForm.get('terms').valueChanges
    .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        tap(() => this.projectStore.searchTerms = this.termsValue),
        untilDestroyed(this))
    .subscribe(() => {
      this.projectStore.updateMap(true);
    });
  }

  private getOrganisations(): void {
    this.organisationService
    .getOrganisationsByCustomerId(this.getUserCustomerId())
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewOrganisation[]) => {
      this.organisations = res;
    });
  }

  private buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl()
    });

    this.setSearchTerms();
  }

  private setSearchTerms(): void {
    this.searchForm.patchValue({terms: this.projectStore.searchTerms});
  }

  private clearStoredLocations(): void {
    this.equipmentStore.clearStoredLocations();
    this.projectStore.clearStoredLocations();
    this.contactDataSource.clearStoredLocations();
  }
}
