import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { ArticleGroupsFilterView } from 'app/shared/contract/filter/filter-view/article-groups-filter-view.interface';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { FilterValue } from '../filter-value';

export class ArticleGroupsFilterViewConverter extends BaseFilterViewConverter<ArticleGroupsFilterView> {
  constructor(
    originalFilters: ArticleGroupsFilterView[],
    selectedValues: FilterParams
  ) {
    super(FilterTypes.ARTICLE_GROUPS, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterValue(filterItem: ArticleGroupsFilterView): FilterValue {
    return {
      displayName: filterItem.articleGroup,
      storeName: filterItem.articleGroup,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.articleGroup),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
