import { AssignmentResolveReason } from 'app/shared/enums/assignment-resolve-reason.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { DispositionMessages } from './disposition-messages.interface';

export abstract class DispositionMessageResolver<T = DispositionMessages> {
  constructor(private languageService: LanguageService) {}

  abstract getMessages(reasons: AssignmentResolveReason[]): T

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
