import { OspDateTime } from '../../../equipment/contract/osp-date-time';

export class TransportAssignmentExecutionFinishCommand {
  constructor(
    public transportId: string,
    public assignmentId: string,
    public executionEndDate: OspDateTime,
  ) {
  }
}
