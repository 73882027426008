<mat-card fxFlexFill class="entity-creation">
  <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>view_module</mat-icon>
      <span>{{'modules.organisation.customerModulesEdit.editCustomerModules'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()">
        {{'general.buttons.save'|translate}} |
        <mat-icon>save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.close'|translate}}</button>
    </div>

    <div class="entity-creation-form" fxFlex>
      <div class="entity-creation-form-right" fxFlex="40">
        <div class="entity-creation-form-title">{{'modules.organisation.base.customerModules'|translate|uppercase}}</div>

        <mat-list>
          <mat-list-item *ngFor="let mod of modulesList"
                          class="entity-creation-form-right__list-item"
                          [matTooltip]="mod.tooltip"
                          [ngClass]="{
                            'entity-creation-form-right__list-item--selected': mod.isSelected,
                            'entity-creation-form-right__list-item--disabled': mod.disabled,
                          }"
                          (click)="selectModules(mod)">
              <mat-icon matListItemIcon>check_circle</mat-icon>
              <p matListItemTitle>{{mod.name}} </p>
            <mat-icon *ngIf="!mod.isAvailable">warning</mat-icon>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </mat-card-content>
</mat-card>
