<div class="employee-view-general-association-container" fxLayout="row" fxLayoutAlign="start start">

  <div class="employee-view-general-association"
       fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch">
    <h4>{{'modules.disposition.base.team' |translate}}</h4>
    <bh-team-component></bh-team-component>
  </div>

  <div class="employee-view-general-association" fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch">
    <h4>{{'modules.disposition.employeeViewGeneral.associationsEquipmentsLabel'|translate}}</h4>
    <div *ngFor="let equipmentAssignmentRole of getEquipmentAssignmentRoles(); let first = first"
         fxLayout="column" fxLayoutAlign="start stretch">
      <div class="employee-view-general-association-entry employee-role" [ngClass]="{'employee-role-first': first}">
        {{'general.as'|translate|titlecase}} {{ equipmentAssignmentRole | equipmentEmployeeRole }}
      </div>
      <div class="employee-view-general-association-entry" fxLayout="row" fxLayoutAlign="space-between start"
           *ngFor="let equipmentAssignment of getFilteredEquipmentAssignments(equipmentAssignmentRole)">
        <div>
          <span>{{equipmentAssignment.equipmentNameOrModel}}</span>
          <span *ngIf="equipmentAssignment.equipmentLicensePlate"> ({{equipmentAssignment.equipmentLicensePlate}})</span>
        </div>
        <div>
          <mat-icon *ngIf="!equipmentAssignment.unknownEquipment"
                    [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.equipm.s'|translate}"
                    (click)="navigateToEquipment(equipmentAssignment.equipmentId)">
            open_in_new
          </mat-icon>
        </div>
      </div>
    </div>
  </div>

</div>
