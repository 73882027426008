import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AlarmSettingsFieldData } from '../../../../contract/alarm-field-data.type';
import { AlarmSettingsBase } from '../alarm-settings-base.class';
import { BatteryStatus } from '../../../../../../shared/enums/battery-status.enum';

@Component({
  selector: 'bh-tracker-battery-alarm-status',
  templateUrl: './tracker-battery-status.component.html',
  styleUrls: ['./tracker-battery-status.component.scss']
})
export class TrackerBatteryStatusAlarmComponent extends AlarmSettingsBase implements OnInit {

  public readonly batteryStatuses = [
    BatteryStatus.CRITICAL,
    BatteryStatus.LOW
  ];

  @Input() set form(form: UntypedFormGroup) {
    this.settingsForm = form;
  }

  @Input() set fieldData(fieldData: AlarmSettingsFieldData) {
    this.alarmSettingsFieldData = fieldData;
  }

  ngOnInit() {
  }
}
