import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { ViewProjectEmployeeAssignment } from '../../contract/view-project-employee-assignment.interface';
import { ProjectDataSource } from '../project.datasource';

@Component({
  selector: 'bh-project-employee-assignment-view-dialog',
  templateUrl: './project-employee-assignment-view-dialog.component.html',
  styleUrls: ['./project-employee-assignment-view-dialog.component.scss']
})
export class ProjectEmployeeAssignmentViewDialogComponent implements OnInit {

  employeeAssignment: ViewProjectEmployeeAssignment;
  icon: IconDefinition;
  public hasAssignmentOrTransferRequestWorkflow: boolean;

  public readonly faBadgeCheck: IconDefinition = faBadgeCheck;

  constructor(public dialogRef: MatDialogRef<ProjectEmployeeAssignmentViewDialogComponent>,
     public projectStore: ProjectDataSource) { }

  ngOnInit(): void {
    this.subscribeToAssignmentOrTransferRequestWorkflow();
  }

  private subscribeToAssignmentOrTransferRequestWorkflow(): void {
    this.projectStore.hasAssignmentOrTransferRequestWorkflow.subscribe((hasAssignmentOrTransferRequestWorkflow: boolean) => {
      this.hasAssignmentOrTransferRequestWorkflow = hasAssignmentOrTransferRequestWorkflow;
    });
    this.projectStore.updateAssignmentOrTransferRequestWorkflow();
  }

}
