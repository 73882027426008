import { ViewBulkItem } from '../../../../contract/view-bulk-item.interface';
import { GuardedNavigableInputComponent } from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { BulkItemDatasource } from '../../../../shared/bulk-item.datasource';
import { OnInit, Directive } from '@angular/core';
import { BulkItemsService } from '../../../../shared/bulk-items.service';
import { RouterHistory } from '../../../../../../shared/router-history';

@Directive()
export abstract class BaseBulkItemViewComponent extends GuardedNavigableInputComponent implements OnInit {

  public bulkItem: ViewBulkItem;
  public deleteDisabled: boolean;
  protected componentActive = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected bulkItemStore: BulkItemDatasource,
              protected bulkItemsService: BulkItemsService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
    this.handleRouting();
  }

  public ngOnInit(): void {
    this.subscribeToCurrentBulkItem();
  }

  private handleRouting(): void {
    this.route.params
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((params: Params) => {
        if (params.id) {
          // TODO: Remove once aux url is correctly ommited
          const auxIndex = params.id.indexOf('(');
          let bulkItemId = params.id;
          if (auxIndex > -1) {
            bulkItemId = params.id.slice(0, auxIndex);
          }
          this.bulkItemStore.changeCurrentBulkItem(bulkItemId);
        }
      });
    this.router.events
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd && this.route.snapshot.firstChild) {
          this.bulkItemStore.currentViewTab = this.route.snapshot.firstChild.url[0].path;
        }
      });
  }

  private subscribeToCurrentBulkItem(): void {
    this.bulkItemStore.currentBulkItem
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((res: ViewBulkItem) => {
        if (res) {
          this.bulkItem = res;
        }
      });
    this.bulkItemStore.currentBulkItemDeletable
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((deletable: boolean) => {
        this.deleteDisabled = !deletable;
      });
  }

}
