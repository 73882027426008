<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div fxLayoutAlign="space-between center">
      <bh-transport-state-change
        [state]="transportTask"
        [parent]="dialogRef"
        (selectNewStatus)="updateTransportTaskState($event)">
      </bh-transport-state-change>
      <bh-transport-priority-badge *ngIf="transportTask?.priority as transportPriority" [priority]="transportPriority">
      </bh-transport-priority-badge>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="transport-field__number"># {{transportTask?.identifier}}</div>
      <div class="transport-field__title text-overflow" data-osp-test="field-title">{{transportTask?.title}}</div>
      <mat-icon class="icon-navigation"
                *ngIf="hasAuthority(authorities.TRANSPORT_TASK_DISPOSITION)"
                (click)="navigateToTransport(transportTask.transportId)"
                [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.transport'|translate }">
        open_in_new
      </mat-icon>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label class="label-padding">{{'modules.transportation.base.transportType'|translate}}:</label>
      <span class="text-overflow" data-osp-test="field-transport-type">
        {{transportTask?.transportType | defaultValue : emptyValue}}
      </span>
    </div>

    <div class="transport-field__description" [fxLayout]="transportTask?.text ? 'column' : 'row'">
      <label class="label-padding">{{'general.description'|translate}}:</label>
      <span class="transport-field__description__text" *ngIf="transportTask?.text as description; else noValue"
            data-osp-test="field-description">
        {{description}}
      </span>
    </div>

    <ng-container
      [ngTemplateOutlet]="transportItem"
      [ngTemplateOutletContext]="transportTask | transportContextItem
        : transportItemLocationType.START
        : isUserOrganisationId(transportTask?.startItem?.organisationId, transportTask?.startItem)">
    </ng-container>
    <ng-container
        [ngTemplateOutlet]="transportItem"
        [ngTemplateOutletContext]="transportTask | transportContextItem
        : transportItemLocationType.TARGET
        : isUserOrganisationId(transportTask?.targetItem?.organisationId, transportTask?.targetItem)">
    </ng-container>
    <ng-container
        [ngTemplateOutlet]="transportItem"
        [ngTemplateOutletContext]="transportTask | transportContextProject
        : isUserOrganisationId(transportTask?.projectInfo?.organisationId)">
    </ng-container>

    <div fxLayout="row" fxLayoutGap="4%">
      <div fxLayout="column" fxFlex="1 1 auto" class="fields-column">
        <div class="transport-field__due-date">
          <label class="label-padding">{{'modules.transportation.base.dueDate'|translate}}:</label>
          <span [ngClass]="{'due-date-time-passed': (transportTask | isTransportDueDatePassed)}" data-osp-test="field-due-date">
            <ng-container
              [ngTemplateOutlet]="timestamp"
              [ngTemplateOutletContext]="{ date: transportTask?.dueDate, hideEndOfDayTime: true }">
            </ng-container>
          </span>
        </div>
        <div class="transport-field__assignment" [fxLayout]="(transportTask?.equipmentAssignmentDetails) ? 'column' : 'row'">
          <label class="label-padding">{{'modules.transportation.base.assignment'|translate}}:</label>
          <span *ngIf="transportTask?.equipmentAssignmentDetails; else noValue">
            <ng-container
              [ngTemplateOutlet]="timestamp"
              [ngTemplateOutletContext]="{ date: transportTask?.equipmentAssignmentDetails?.startDate }">
            </ng-container>
            —
            <ng-container
              [ngTemplateOutlet]="timestamp"
              [ngTemplateOutletContext]="{ date: transportTask?.equipmentAssignmentDetails?.endDate }">
            </ng-container>
            <ng-container *ngIf="transportTask?.state === transportState.IN_PROGRESS">
              <span *ngIf="(transportTask | transportPlannedDuration) as planned" class="planned-duration">({{planned}})</span>
            </ng-container>
          </span>
        </div>
      </div>

      <div fxLayout="column" fxFlex="1 1 auto" class="fields-column second-column">
        <div class="transport-field__duration">
          <label class="label-padding">{{'modules.transportation.base.estimatedDuration'|translate}}:</label>
          <span data-osp-test="field-estimated-duration">
            {{transportTask?.estimatedDuration | formatDuration | defaultValue : emptyValue}}
          </span>
        </div>
        <div class="transport-field__vehicle" [fxLayout]="(transportTask?.equipmentAssignmentDetails) ? 'column' : 'row'">
          <label class="label-padding">{{'modules.transportation.base.assignedVehicle'|translate}}:</label>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="text-overflow">
              {{this.transportTask?.equipmentAssignmentDetails | transportViewAssignmentTitle | defaultValue : emptyValue}}
            </span>
            <ng-container *ngIf="transportTask?.equipmentAssignmentDetails as assignment">
              <mat-icon class="icon-navigation"
                *ngIf="hasAnyAuthority([authorities.EQUIPMENT_VIEW, authorities.PROJECT_ASSIGNEE_VIEW])
                  && isUserOrganisationId(assignment.organisationId, null)"
                (click)="navigateToEquipment(assignment.equipmentId)"
                [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.vehicle.s'|translate }">
                open_in_new
              </mat-icon>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div [fxLayout]="(transportTask?.equipmentAssignmentDetails)  &&
        (transportTask?.equipmentAssignmentDetails?.executionStartDate)  ? 'column' : 'row'">
      <label class="label-padding">{{'modules.transportation.base.execution'|translate}}:</label>
      <div>
        <span *ngIf="(transportTask?.equipmentAssignmentDetails) &&
            (transportTask?.equipmentAssignmentDetails?.executionStartDate); else noValue">
          <ng-container
            [ngTemplateOutlet]="timestamp"
            [ngTemplateOutletContext]="{ date: transportTask?.equipmentAssignmentDetails?.executionStartDate }">
          </ng-container>
          —
          <span *ngIf="transportTask?.equipmentAssignmentDetails?.executionEndDate; else openExecution">
            <ng-container
              [ngTemplateOutlet]="timestamp"
              [ngTemplateOutletContext]="{ date: transportTask?.equipmentAssignmentDetails?.executionEndDate }">
            </ng-container>
          </span>
        </span>
        <mat-icon
          *ngIf="canChangeExecutionDateTime()"
          matSuffix
          class="edit-execution-date"
          [matTooltip]="'modules.transportation.base.changeExecutionDateTime'|translate"
          (click)="updateExecutionStartAndEndDate()">
            mode_edit
        </mat-icon>
        <ng-container *ngIf="transportTask?.state === transportState.IN_PROGRESS">
          <span *ngIf="(transportTask | transportEstimatedEnd) as estimatedEnd">
            ({{'modules.transportation.transportDetailView.estimatedEndDate'|translate|lowercase}}
            {{estimatedEnd | formatTimezoneDateTime: {showTime: true} }})
          </span>
        </ng-container>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <div fxFlex="70" fxLayoutAlign="start start">
      <button mat-raised-button
        color="primary"
        (click)="editTransportTask()"
        [disabled]="!canEditTransport">
        <span
          [matTooltip]="'modules.transportation.transportDetailView.editDisallowed'|translate"
          [matTooltipDisabled]="canEditTransport">
          {{'general.buttons.edit'|translate}}
        </span>
      </button>
      <button mat-raised-button
        color="primary"
        [matTooltip]="'modules.transportation.transportDetailView.copyTooltip'|translate"
        [disabled]="!canCopyTransport"
        (click)="copyTransport()">
        <span
          [matTooltip]="'modules.transportation.transportDetailView.copyDisallowedTooltip'|translate"
          [matTooltipDisabled]="canCopyTransport">
          {{'general.buttons.copy'|translate}}
        </span>
      </button>
    </div>

    <div fxFlex="30" fxLayoutAlign="end">
      <button mat-raised-button color="primary" mat-dialog-close>{{'general.buttons.close'|translate}}</button>
    </div>
  </mat-dialog-actions>
</div>



<ng-template #transportItem
  let-locationType="locationType"
  let-itemType="itemType"
  let-name="name"
  let-address="address"
  let-navigationInfo="navigationInfo"
  let-state="state">
  <div class="transport-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <ng-container [ngSwitch]="locationType">
      <mat-icon *ngSwitchCase="transportItemLocationType.START" class="icon-label" [svgIcon]="'transport-start'"></mat-icon>
      <mat-icon *ngSwitchCase="transportItemLocationType.TARGET" class="icon-label" [svgIcon]="'transport-target'"></mat-icon>
      <mat-icon *ngSwitchCase="transportItemLocationType.PROJECT" class="icon-label icon-project" fontSet="icon-main_projects02_maps_solid"></mat-icon>
    </ng-container>

    <div class="transport-item__info" fxLayout="column">
      <span class="text-overflow" [attr.data-osp-test]="'field-title-'+locationType">
        {{name | defaultValue : emptyValue}}
      </span>
      <span class="text-overflow" [attr.data-osp-test]="'field-address-'+locationType">
        {{address | defaultValue : emptyValue}}
      </span>
    </div>
    <ng-container *ngIf="navigationInfo?.url && canSeeLink(itemType)">
      <mat-icon class="icon-navigation"
        (click)="navigateTo(navigationInfo.url)"
        [matTooltip]="getTooltipLink(itemType)">
        open_in_new
      </mat-icon>
      <mat-icon class="icon-info"
        *ngIf="navigationInfo.isLatestItem"
        [matTooltip]="getTooltipLatestItemInfo(state, itemType)">
        info
      </mat-icon>
    </ng-container>
  </div>
</ng-template>

<ng-template #timestamp let-date="date" let-hideEndOfDayTime="hideEndOfDayTime">
  <span class="timestamp" *ngIf="date; else noValue">
    {{date | formatTimezoneDateTime : {dateFormat: 'DD MMM YYYY', showTime: true, hideEndOfDayTime: hideEndOfDayTime} }}
  </span>
</ng-template>

<ng-template #noValue><span>{{emptyValue}}</span></ng-template>
<ng-template #openExecution><span>{{'modules.transportation.base.openExecution'|translate}}</span></ng-template>

<div class="spinner-wrapper" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
  <mat-spinner [strokeWidth]="5" [diameter]="140"></mat-spinner>
</div>
