import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { Pageable } from 'app/shared/contract/task/pageable.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { BehaviorSubject } from 'rxjs';
import { TaskCompletedParams } from '../contracts/task-completed-params.interface';
import { MaintenanceService } from '../maintenance.service';
import { MaintenanceTaskCompletedBaseDataSource } from '../../../maintenance/tasks/shared/service/maintenance-task-completed-datasource/maintenance-task-completed-base.datasource';
import { MaintenanceDatasourceMediatorService } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator.service';


@Injectable()
export class MaintenanceTaskCompletedDataSource extends MaintenanceTaskCompletedBaseDataSource implements Pageable {
  private _equipmentId: string;
  private _completedTasks: BehaviorSubject<TaskCompleted[]> = new BehaviorSubject([]);
  private _isLoading = new BehaviorSubject<boolean>(false);

  public readonly completedTasks = this._completedTasks.asObservable();
  public readonly isLoading = this._isLoading.asObservable();

  public get equipmentId(): string {
    return this._equipmentId;
  }

  public set equipmentId(id: string) {
    this._equipmentId = id;
  }

  constructor(
    router: Router,
    dialog: MatDialog,
    maintenanceService: MaintenanceService,
    languageService: LanguageService,
    maintenanceMediator: MaintenanceDatasourceMediatorService,
  ) {
    super(router, dialog, maintenanceService, maintenanceMediator, languageService);
  }

  public handlePageEvent(event: PageEvent): void {
    this.updatePagination(event.pageIndex, event.pageSize);
    this.loadTasksForEquipment(this.equipmentId);
  }

  public loadTasksForEquipment(equipmentId: string): void {
    this._isLoading.next(true);
    this.equipmentId = equipmentId;
    this.maintenanceService.getCompletedTasks(this.getParams())
      .subscribe(res => this.updateStoreData(res));
  }

  private getParams(): TaskCompletedParams {
    return  {
      equipmentId: this.equipmentId,
      page: this._pagination.getValue().index,
      size: this._pagination.getValue().size,
    };
  }

  private updateStoreData(response: PagedResponse<TaskCompleted>): void {
    this._completedTasks.next(response?.content ?? []);
    this.updatePaginationFromResponse(response);
    this._isLoading.next(false);
  }

  public reset(equipmentId: string = this.equipmentId): void {
    this.equipmentId = equipmentId;
    this.resetPagination();
    this.loadTasksForEquipment(this.equipmentId);
  }

}
