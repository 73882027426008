<div class="warning-dialog">
  <div class="warning-dialog-header">{{'general.warning'|translate}}</div>
  <div class="warning-dialog-content">
    <p>{{'modules.reports.allSelectedWarning.createReportForPart'|translate}}
      <u>{{'general.all'|translate|lowercase}}</u>
      <ng-container [ngSwitch]="data">
        <ng-container *ngSwitchCase="reportParameter.ALL_EQUIPMENTS">
          {{'modules.reports.allSelectedWarning.createReportForEquipment'|translate}}.
        </ng-container>
        <ng-container *ngSwitchCase="reportParameter.ALL_PROJECTS">
          {{'modules.reports.allSelectedWarning.createReportForProject'|translate}}.
        </ng-container>
      </ng-container>
    </p>
    <p>{{'modules.reports.allSelectedWarning.canTakeTime'|translate}}</p>

    <div mat-dialog-actions>
      <button mat-raised-button color="accent" [mat-dialog-close]="false">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">{{'general.buttons.ok'|translate}}</button>
    </div>
  </div>
</div>
