import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TelematicUnitAdministrationDatasource } from '../services/telematic-unit-administration.datasource';
import { Sort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TelematicUnitAdministrationAddEditComponent
} from '../telematic-unit-administration-add-edit/telematic-unit-administration-add-edit.component';
import { TelematicsUnitType } from '../../equipment/contract/telematics-unit-type.enum';
import { TelematicUnitsAdministrationResolver } from '../shared/telematic-units-administration.resolver';
import { catchError, debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RoleAuthorityGuardsComponent } from '../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../core/keycloak';
import { dialogResults } from '../../../shared/enums/dialogResults.enum';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../../../shared/services/language.service';
import {
  DeleteCustomerTelematicUnitCommand
} from '../shared/commands/customer-telematic-unit/delete-customer-telematic-unit-command';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  TelematicUnitsAdministrationBulkImportComponent
} from '../telematic-units-administration-bulk-import/telematic-units-administration-bulk-import.component';
import { TelematicUnitListItem } from '../shared/telematic-unit-list.interface';
import {
  DeactivateCustomerTelematicUnitCommand
} from '../shared/commands/action/deactivate-customer-telematic-unit-command';
import {
  ActivateCustomerTelematicUnitCommand
} from '../shared/commands/action/activate-customer-telematic-unit-command';
import { PartnerInfo } from '../shared/partner-info.interface';
import {
  TelematicUnitAdministrationAssignComponent
} from '../telematic-unit-administration-assign/telematic-unit-administration-assign.component';
import { SearchCustomer } from '../../organisation/contract/search-customer.interface';
import { TelematicUnitAdministrationService } from '../services/telematic-unit-administration.service';
import { faUnlink } from '@fortawesome/pro-light-svg-icons';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import {
  UnassignCustomerTelematicUnitCommand
} from '../shared/commands/action/unassign-customer-telematic-unit-command';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButton } from '@angular/material/button';
import { FocusMonitor } from '@angular/cdk/a11y';
import { EquipmentInfoDialogComponent } from '../equipment-info-dialog/equipment-info-dialog.component';
import { TelematicUnitFotaWebData } from '../shared/telematic-unit-fota-web-data';
import {
  TelematicUnitFotaWebDataDialogComponent
} from '../telematic-unit-fota-web-data-dialog/telematic-unit-fota-web-data-dialog.component';
import { TelematicsService } from '../../equipment/shared/telematics.service';
import {
  TelematicUnitLiveDataDialogComponent,
  TelematicUnitLiveDataDialogData
} from '../telematic-unit-live-data-dialog/telematic-unit-live-data-dialog.component';
import { ViewTeltonikaUnit } from '../../equipment/shared/view-teltonika-unit.interface';
import { ViewDigitalMatterUnit } from '../../equipment/shared/view-digital-matter.unit';
import { TelematicUnitCustomerEquipmentInfo } from '../shared/telematic-unit-customer-equipment-info';
import { TeltonikaDatasource } from '../../equipment/shared/teltonika.datasource';
import { DigitalMatterDatasource } from '../../equipment/shared/digital-matter.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-telematic-units-administration-list',
  templateUrl: './telematic-units-administration-list.component.html',
  styleUrls: ['./telematic-units-administration-list.component.scss']
})
export class TelematicUnitsAdministrationListComponent extends RoleAuthorityGuardsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('importBtn') importBtn: MatButton;

  public telematicUnitsList: Observable<TelematicUnitListItem[]>;

  public readonly faPlus: IconDefinition = faPlus;
  public readonly faUnlink: IconDefinition = faUnlink;
  public readonly faTruckPlow: IconDefinition = faTruckPlow;

  public filterForm: UntypedFormGroup;
  public termControl: UntypedFormControl;
  public partnerFilterList: PartnerInfo[];
  public customersFilterList: SearchCustomer[];
  public telematicTypeFilterList = Object.keys(TelematicsUnitType);
  public simCardProviderFilterList: string[];

  public displayedColumns: string[];

  constructor(
    protected authService: KeycloakService,
    public telematicUnitStore: TelematicUnitAdministrationDatasource,
    protected router: Router,
    protected dialog: MatDialog,
    public telematicUnitResolver: TelematicUnitsAdministrationResolver,
    private formBuilder: UntypedFormBuilder,
    private languageService: LanguageService,
    protected route: ActivatedRoute,
    public telematicUnitAdministrationService: TelematicUnitAdministrationService,
    private telematicService: TelematicsService,
    private focusMonitor: FocusMonitor,
    public teltonikaDataSource: TeltonikaDatasource,
    public digitalMatterDatasource: DigitalMatterDatasource,
  ) {
    super(authService);
    const create = this.route.snapshot.data['create'];
    const assign = this.route.snapshot.data['assign'];
    if (create) {
      this.dialog.open(TelematicUnitAdministrationAddEditComponent, {
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['/telematic-unit-administration/list']);
        }
      })
    } else if (assign) {
      this.dialog.open(TelematicUnitAdministrationAssignComponent, {
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['/telematic-unit-administration/list']);
        }
      })
    }
  }

  get telematicType(): AbstractControl {
    return this.filterForm.get('telematicType');
  }

  get simCardProvider(): AbstractControl {
    return this.filterForm.get('simCardProvider');
  }

  get assignedPartner(): AbstractControl {
    return this.filterForm.get('assignedPartner');
  }

  get customer(): AbstractControl {
    return this.filterForm.get('customerId');
  }

  ngOnInit(): void {
    this.setupDisplayedColumns();
    this.buildForm();
    this.telematicUnitStore.loadTelematicUnits();
    this.telematicUnitsList = this.telematicUnitStore.connect();
    this.telematicTypeFilterListener();
    this.simCardProviderFilterListener();
    this.customerFilterListener();
    this.assignedPartnerFilterListener();
    this.getPartnersFilterlist();
    this.getCustomersFilterList();
    this.getSelectedFilters();
    this.telematicUnitsList.subscribe(value => {
      this.simCardProviderFilterList = value
        .map(item => item.simCardProvider)
        .filter((v, i, a) => a.indexOf(v) === i && v)
    });
    this.onSearchType();
  }

  ngAfterViewInit(): void {
    if (this.importBtn) {
      this.focusMonitor.stopMonitoring(this.importBtn._elementRef.nativeElement);
    }
  }

  private setupDisplayedColumns() {
    this.displayedColumns = [
      'telematicsUnitType',
      'boxNo',
      'serialNumber',
      'telematicsUnitId'
    ];

    if (this.isSuperAdmin()) {
      this.displayedColumns.push(
        'mac',
        'simCardProvider',
        'eSim1IccId',
        'eSim1Imsi',
        'partnerId'
      );
    }

    this.displayedColumns.push('customerName');
    this.displayedColumns.push('addedDate', 'equipment', 'status', 'menu');
  }

  ngOnDestroy(): void {
  }

  private getPartnersFilterlist() {
    this.telematicUnitStore
      .getPartners()
      .subscribe((partners: PartnerInfo[]) => this.partnerFilterList = partners);
  }

  private getCustomersFilterList() {
    this.telematicUnitAdministrationService.getAllCustomers()
      .subscribe(data => this.customersFilterList = data);
  }

  private buildForm(): void {
    this.filterForm = this.formBuilder.group({
      telematicType: [],
      simCardProvider: [],
      assignedPartner: [],
      customerId: []
    });

    this.termControl = new UntypedFormControl('');
  }

  public resolveName(option: string) {
    return this.telematicUnitResolver.resolveName(option);
  }

  public sortChange(sort: Sort): void {
    this.telematicUnitStore.sort = sort;
    this.loadTelematicUnits();
  }

  public searchClick(): void {
    this.telematicUnitStore.term = this.termControl.value;
    this.loadTelematicUnits();
  }

  public onSearchType(): void {
    this.termControl.valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST))
      .subscribe(term => {
        this.telematicUnitStore.term = term;
        this.loadTelematicUnits();
      })
  }

  public canEdit(): boolean {
    return this.isSuperAdmin();
  }

  public getPartnerId(): string {
    return this.getUserPartnerId();
  }

  public edit(item: TelematicUnitListItem): void {
    let dialogRef = this.dialog.open(TelematicUnitAdministrationAddEditComponent, { autoFocus: false });
    dialogRef.componentInstance.telematicItem = item;
  }

  public canActivate(item: TelematicUnitListItem): boolean {
    return (this.isSuperAdmin() || this.isPartnerAdmin()) && !item.isActive;
  }

  public activate(item: TelematicUnitListItem): void {
    const command = new ActivateCustomerTelematicUnitCommand(item.customerTelematicsUnitId)
    this.telematicUnitStore.activateTelematicUnit(command);
  }

  public canDeactivate(item: TelematicUnitListItem): boolean {
    return (this.isSuperAdmin() || this.isPartnerAdmin()) && item.isActive;
  }

  public deactivate(item: TelematicUnitListItem): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.telematic.telematicDeactivate.title');
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === dialogResults.YES) {
          const command = new DeactivateCustomerTelematicUnitCommand(item.customerTelematicsUnitId)
          this.telematicUnitStore.deactivateTelematicUnit(command);
        }
      });
  }

  public showEquipmentInfo(item: TelematicUnitListItem): void {
    this.telematicUnitAdministrationService.getEquipmentInfo(item.telematicsUnitId).subscribe(telematicUnitCustomerEquipmentInfo => {
      const dialogRef: MatDialogRef<EquipmentInfoDialogComponent> = this.dialog.open(EquipmentInfoDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          title: this.translate('modules.telematic.telematicUnitEquipmentInfo.title'),
          telematicsUnitCustomerEquipmentInfo: telematicUnitCustomerEquipmentInfo
        }
      });
    });
  }

  public showFotaWebData(item: TelematicUnitListItem): void {
    this.telematicUnitAdministrationService.getFotaWebData(item.telematicsUnitId).subscribe(obj => {
      const telematicUnitFotaWebData: TelematicUnitFotaWebData = {
        imei: item.telematicsUnitId,
        iccid: obj['iccid'],
        unitType: obj['model'],
        serialNumber: obj['serial'],
        currentConfig: obj['current_configuration'],
        currentFirmware: obj['current_firmware'],
        lastConnected: obj['seen_at']
      };
      const dialogRef: MatDialogRef<TelematicUnitFotaWebDataDialogComponent> = this.dialog.open(TelematicUnitFotaWebDataDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          telematicUnitFotaWebData: telematicUnitFotaWebData
        }
      });
    });
  }

  public showLiveData(item: TelematicUnitListItem): void {
    const fetchEquipmentInfo = this.telematicUnitAdministrationService.getEquipmentInfo(item.telematicsUnitId);
    switch (item.telematicsUnitType) {
      case TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT:
        const fetchDigitalMatterLiveData =
          this.telematicService.getDigitalMatterData(item.telematicsUnitId).pipe(catchError(() => of(undefined)));
        forkJoin([fetchEquipmentInfo, fetchDigitalMatterLiveData])
          .subscribe(([equipmentInfo, liveData]) =>
            this.openDigitalMatterLiveDataDialog(equipmentInfo, liveData));
        break;
      case TelematicsUnitType.TELTONIKA_CABLE_UNIT:
      case TelematicsUnitType.TELTONIKA_OBD_UNIT:
        const fetchTeltonikaLiveData =
          this.telematicService.getTeltonikaData(item.telematicsUnitId).pipe(catchError(() => of(undefined)));
        forkJoin([fetchEquipmentInfo, fetchTeltonikaLiveData])
          .subscribe(([equipmentInfo, liveData]) =>
            this.openTeltonikaLiveDataDialog(equipmentInfo, liveData));
        break;
      default:
        console.warn('Live Data View is currently not supported for type: ' + item.telematicsUnitType)
    }
  }

  private openTeltonikaLiveDataDialog(equipmentInfo: TelematicUnitCustomerEquipmentInfo, liveData: ViewTeltonikaUnit): void {
    const data: TelematicUnitLiveDataDialogData<ViewTeltonikaUnit> = {
      telematicsUnitCustomerEquipmentInfo: equipmentInfo,
      liveData: liveData,
      datasource: this.teltonikaDataSource
    }
    this.openTelematicUnitLiveDataDialogData(data);
  }

  private openDigitalMatterLiveDataDialog(equipmentInfo: TelematicUnitCustomerEquipmentInfo, liveData: ViewDigitalMatterUnit): void {
    const data: TelematicUnitLiveDataDialogData<ViewDigitalMatterUnit> = {
      telematicsUnitCustomerEquipmentInfo: equipmentInfo,
      liveData: liveData,
      datasource: this.digitalMatterDatasource
    }
    this.openTelematicUnitLiveDataDialogData(data);
  }

  private openTelematicUnitLiveDataDialogData(data: any): void {
    this.dialog.open(TelematicUnitLiveDataDialogComponent, {
      width: '1200px',
      autoFocus: false,
      data
    })
  }

  public unassignUnit(item: TelematicUnitListItem): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.telematic.telematicUnassign.title');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.telematic.reassignWarning');
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === dialogResults.YES) {
          const command = new UnassignCustomerTelematicUnitCommand(item.customerTelematicsUnitId)
          this.telematicUnitStore.unAssignTelematicUnitFromCustomer(command)
            .subscribe(() => {
            }, (error: HttpErrorResponse) => {
              console.log('ERROR: Error on adding Telematic Item: ', error);
            });
        }
      });
  }

  public canDelete(): boolean {
    return this.isSuperAdmin();
  }

  public delete(item: TelematicUnitListItem): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.telematic.telematicDelete.title');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.telematic.telematicDelete.message');
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === dialogResults.YES) {
          const command = new DeleteCustomerTelematicUnitCommand(item.customerTelematicsUnitId);
          this.telematicUnitStore.deleteCustomerTelematicUnitCommand(command, item.customerTelematicsUnitType);
        }
      });
  }

  private telematicTypeFilterListener(): void {
    this.telematicType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.telematicUnitStore.telematicType = value;
        this.loadTelematicUnits();
      });
  }

  private simCardProviderFilterListener(): void {
    this.simCardProvider.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.telematicUnitStore.simCardProvider = value;
        this.loadTelematicUnits();
      });
  }

  private customerFilterListener(): void {
    this.customer.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.telematicUnitStore.customer = value;
        this.loadTelematicUnits();
      });
  }

  private assignedPartnerFilterListener(): void {
    this.assignedPartner.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.telematicUnitStore.assignedPartner = value;
        this.loadTelematicUnits();
      });
  }

  private getSelectedFilters(): void {
    this.filterForm.patchValue({
      telematicType: this.telematicUnitStore.telematicType,
      simCardProvider: this.telematicUnitStore.simCardProvider,
      assignedPartner: this.telematicUnitStore.assignedPartner,
      customerId: this.telematicUnitStore.customer,
    });
  }

  public searchReset() {
    this.termControl.reset('');
    this.telematicUnitStore.term = '';
    this.loadTelematicUnits();
  }

  private loadTelematicUnits(): void {
    this.telematicUnitStore.resetPageIndex(); // Filter changed, so reset pageindex to first page
    this.telematicUnitStore.loadTelematicUnits();
  }

  public openAddMenu() {
    this.router.navigate(['telematic-unit-administration/add']);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public bulkImport(): void {
    this.dialog.open(TelematicUnitsAdministrationBulkImportComponent, <MatDialogConfig>{ disableClose: true });
  }

  public assignTelematicUnit(item: TelematicUnitListItem) {
    const dialogRef: MatDialogRef<TelematicUnitAdministrationAssignComponent> = this.dialog
      .open(TelematicUnitAdministrationAssignComponent);
    dialogRef.componentInstance.telematicsUnit = item;
  }

  public isAssignedToEquipment(item: TelematicUnitListItem): boolean {
    return item.equipmentIds && item.equipmentIds.length > 0;
  }
}
