<div class="language-switcher-dialog">
  <h1 mat-dialog-title>{{ 'modules.userrole.userSettings.userLanguageNotSupported' | translate }}</h1>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close="cancel" aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <h4>{{ 'modules.userrole.userSettings.userLanguageNotSupportedByAdmin' | translate: {userLanguage} }}</h4>

  <mat-dialog-content fxLayout="column">
    <bh-language-switcher></bh-language-switcher>
  </mat-dialog-content>
</div>

