import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[bhScrollSelected]'
})
export class ScrollSelectedDirective {
  @Input('bhScrollSelected') itemId: string;
  @Input() set checkSelected(itemId: string) {
    if (this.itemId && this.itemId === itemId) {
      this.scroll();
    }
  }
  private defaultScrollOptions = {
    block: 'nearest',
    behavior: 'smooth',
    inline: 'start'
  };

  constructor(private element: ElementRef) { }

  private scroll(): void {
    setTimeout(() => {
      this.element.nativeElement.scrollIntoView(this.defaultScrollOptions);
    }, 0);
  }
}
