<div class="organisation-delete">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title><mat-icon class="delete-icon">delete</mat-icon>{{'modules.organisation.organisationDelete.deleteOrganisation'|translate}}</h1>
  <mat-dialog-content>
    <p *ngIf="!deleteConfirmed">{{'modules.organisation.organisationDelete.deleteNotConfirmedMessage'|translate}}</p>

    <p *ngIf="deleteConfirmed">{{'modules.organisation.organisationDelete.deleteConfirmedMessage'|translate}}</p>

    <form [formGroup]="organisationDeleteForm" novalidate *ngIf="deleteConfirmed">
      <mat-form-field>
        <mat-label>{{'modules.organisation.organisationDelete.targetOrganisation'|translate|uppercase}}</mat-label>
        <mat-select formControlName="relocationTargetId" required>
          <mat-option *ngFor="let organisation of organisations" [value]="organisation.organisationId">
            {{organisation.organisationName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="relocationTargetId.hasError('required') && !relocationTargetId.pristine">
          {{'shared.validation.required'|translate:{ value: 'modules.organisation.organisationDelete.movingTarget'|translate } }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button mat-button mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button  mat-raised-button
             bhTimeoutSaveButton
             color="primary"
             (click)="deleteConfirmed = true"
            *ngIf="!deleteConfirmed">{{'general.buttons.yes'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()" *ngIf="deleteConfirmed">{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
