import { RouterHistory } from 'app/shared/router-history';

// @angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AsyncPipe, CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import 'hammerjs';
import * as Sentry from '@sentry/angular-ivy';
import { TryCatch } from '@sentry/angular-ivy';

import { routing } from './app.routing';
import { AppComponent } from './app.component';

// modules
import { EquipmentModule } from './modules/equipment/equipment.module';
import { NavigationModule } from './modules/navigation/navigation.module';
import { OrganisationModule } from './modules/organisation/organisation.module';
import { UserRoleModule } from './modules/userrole/userrole.module';
import { ConnectorModule } from './modules/connector/connector.module';
import { DispositionModule } from './modules/disposition/disposition.module';
import { HeaderModule } from './modules/header/header.module';
import { LandingpageModule } from './modules/landingpage/landingpage.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { NotificationModule } from './modules/notifications/notification.module';
import { ChatModule } from './modules/communication/chat.module';
import { ReportsModule } from './modules/reports/reports.module';
import { ContactModule } from './modules/contact/contact.module';

// shared
import { ContentComponent } from './shared/components/content-component/content.component';
import { CustomDateAdapter } from './shared/custom-date-adapter';
import { Utils } from './shared/utils';
import { LocalUserStorageService } from './shared/services/local-user-storage.service';
import { MessagingService } from './shared/messaging.service';
import { ChatUrlParser } from './shared/services/chat-url-parser';
import { LanguageService } from './shared/services/language.service';
import { UserConfigurationService } from './shared/services/user-configuration.service';
import {
  appInitializerTranslationFactory,
  googleMapsConfigFactory,
  localeFactory,
  translateCompilerFactory,
  TranslateHttpLoaderFactory
} from './shared/translation-utils';
import { LANGUAGE_LOCALES } from './shared/language-locale.constants';

// environments
import { environment } from '../environments/environment';
import { versions } from '../environments/versions';

import { RequestInterceptorService } from './core/interceptors/http.interceptor';
import { CanActivateChildModule } from './core/guards/can-activate-child-module';
import { CanActivateModule } from './core/guards/can-activate-module';
import { CanActivateChildAuthority } from './core/guards/can-activate-child-authority';
import { CanActivateChildRole } from './core/guards/can-activate-child-role';
import { CanActivateChildLandscape } from './core/guards/can-activate-child-landscape';
import { CanActivateChildMobile } from './core/guards/can-activate-child-mobile';
import { CanActivateRole } from './core/guards/can-activate-role';
import { CanActivateLanguage } from './core/guards/can-activate-language.guard';
import { SentryErrorHandler } from './core/sentry-error-handler';
import { CustomMaterialModule } from './core/custom-material/custom-material.module';

// ngx
import { NgPipesModule } from 'ngx-pipes';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatomoModule } from 'ngx-matomo';
import { QuillModule } from 'ngx-quill';
import { TransportationModule } from './modules/transportation/transportation.module';
import { TelematicUnitAdministrationModule } from './modules/telematic-unit-administration/telematic-unit-administration.module';
import { CanActivateChildMobileWebApp } from './core/guards/can-activate-child-mobile-web-app.guard';
import { CanActivateMobileWebApp } from './core/guards/can-activate-mobile-web-app.guard';
import { MapModule } from './shared/modules/map/map.module';
import { MAPS_CONFIG } from './shared/modules/map/services/map-loader.service';
import { MaintenanceModule } from './modules/maintenance/maintenance.module';
import { SharedModule } from './shared/shared.module';
import { OspUiModule } from './modules/osp-ui/osp-ui.module';
import { UserLanguageConfigProvider } from './modules/osp-ui/types/user-language-config.provider';
import { TelematicsUnitProfilesModule } from './modules/telematic-unit-profiles/telematics-unit-profiles.module';


LANGUAGE_LOCALES.forEach(({locale, data}) => registerLocaleData(data, locale));

Sentry.init({
  dsn: environment.SENTRY,
  maxValueLength: 8192,
  initialScope: {
    tags: {'git_hash': versions.commit},
  },
  // workaround to fix some unwanted/duplicate errors: https://github.com/getsentry/sentry-javascript/issues/2744
  integrations: [new TryCatch({setTimeout: false})],
  ignoreErrors: [],
});


@NgModule({
  declarations: [
    ContentComponent,
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    CustomMaterialModule,
    HttpClientJsonpModule,
    HttpClientModule,
    FlexLayoutModule,
    HeaderModule,
    ChatModule,
    LandingpageModule,
    EquipmentModule,
    NavigationModule,
    OrganisationModule,
    UserRoleModule,
    ConnectorModule,
    DispositionModule,
    TransportationModule,
    TelematicUnitAdministrationModule,
    TelematicsUnitProfilesModule,
    NotificationModule,
    ReportsModule,
    DashboardModule,
    ContactModule,
    routing,
    MatDatepickerModule,
    QuillModule.forRoot(),
    NgPipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.enableServiceWorker}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useFactory: translateCompilerFactory,
        deps: [UserConfigurationService],
      },
    }),
    MatomoModule.forRoot({
      scriptUrl: `${environment.MATOMO_URL}matomo.js`,
      trackers: [
        {
          siteId: environment.MATOMO_SITE_ID,
          trackerUrl: `${environment.MATOMO_URL}piwik.php`
        }
      ]
    }),
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MapModule.forRoot(),
    MaintenanceModule.forRoot(),
    SharedModule.forRoot(),
    OspUiModule.forRoot([{
      provide: UserLanguageConfigProvider,
      useClass: UserConfigurationService,
    }]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerTranslationFactory,
      deps: [LanguageService, Injector],
      multi: true,
    },
    {
      provide: MAPS_CONFIG,
      useFactory: googleMapsConfigFactory(environment.GOOGLE_MAPS_API_KEY),
      deps: [LanguageService],
    },
    { provide: MAT_DATE_LOCALE, useFactory: localeFactory, deps: [LanguageService] },
    Utils,
    environment.SENTRY && environment.logErrorsInSentry ? {provide: ErrorHandler, useClass: SentryErrorHandler} : [],
    CanActivateChildAuthority,
    CanActivateRole,
    CanActivateChildRole,
    CanActivateModule,
    CanActivateChildModule,
    CanActivateChildMobile,
    CanActivateChildLandscape,
    CanActivateLanguage,
    CanActivateMobileWebApp,
    CanActivateChildMobileWebApp,
    LocalUserStorageService,
    MessagingService,
    LanguageService,
    AsyncPipe,
    ChatUrlParser,
    RouterHistory,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
