<div class="transfer-wrapper">
  <bh-mobile-wizard [title]="'modules.disposition.bulkItemTransfer.transferBulkItems'|translate"
                    [wizardOptions]="wizardOptions"
                    [nextEnabled]="currentStepValid"
                    (tabChanged)="setValidity()"
                    [backEnabled]="backEnabled"
                    (onCancel)="goToLandingPage()"
                    [settingsEnabled]="false"
                    (onFinish)="finish()"
                    [confirmLabel]="'modules.disposition.bulkItemTransfer.completeTransfer'|translate">
    <bh-mobile-wizard-tab key="choose-source" [ngClass]="{hidden: wizard.currentStep !== 0}">
      <bh-mobile-transfer-choose-location *ngIf="startOfProcess || wizard.currentStep === 0"
                                          #sourceSelect
                                          [title]="'modules.disposition.bulkItemTransfer.startPoint'|translate"
                                          [selectedSource]="source"
                                          (tabChanged)="selectSourceTab($event)"
                                          [tab]="sourceTab"
                                          (selected)="selectSource($event)"></bh-mobile-transfer-choose-location>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="scanner" [ngClass]="{hidden: wizard.currentStep !== 1}">
      <bh-mobile-transfer-scanner *ngIf="wizard.currentStep === 1"
                                  [bulkItems]="bulkItems"
                                  (onScan)="bulkItemScanned($event)"></bh-mobile-transfer-scanner>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="item-list" [ngClass]="{hidden: wizard.currentStep !== 2}">
      <bh-mobile-transfer-list [bulkItems]="bulkItems"
                               *ngIf="wizard.currentStep === 2"
                               (entryDeleted)="entryDeleted($event, false)"
                               (entryUpdated)="entryUpdated($event)"></bh-mobile-transfer-list>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="choose-target" [ngClass]="{hidden: wizard.currentStep !== 3}">
      <bh-mobile-transfer-choose-location *ngIf="wizard.currentStep === 3"
                                          #targetSelect
                                          [title]="'modules.disposition.bulkItemTransfer.destination'|translate"
                                          [source]="source"
                                          [selectedTarget]="target"
                                          (tabChanged)="selectTargetTab($event)"
                                          [tab]="targetTab"
                                          (selected)="selectTarget($event)"
                                          (consumed)="selectConsumed()"></bh-mobile-transfer-choose-location>
    </bh-mobile-wizard-tab>
    <bh-mobile-wizard-tab key="summary" [ngClass]="{hidden: wizard.currentStep !== 4}">
      <bh-mobile-transfer-summary *ngIf="wizard.currentStep === 4"
                                  [source]="source"
                                  [target]="target"
                                  [consumed]="consumedSelected"
                                  [bulkItems]="bulkItems"
                                  [additionalInfoForm]="additionalInfoForm"
                                  (editTarget)="wizard.previousTab()"
                                  (entryDeleted)="entryDeleted($event, true)"
                                  (entryUpdated)="entryUpdated($event)"></bh-mobile-transfer-summary>
    </bh-mobile-wizard-tab>
  </bh-mobile-wizard>
</div>
