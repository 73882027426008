<div class="container fx-row gap-md">
  <div class="content-item-image fx-justify-center fx-align-items-center">
    <bh-thumbnail *ngIf="item?.thumbnailKey; else tmpIcon"
      class="image-main"
      [key]="item.thumbnailKey"
      [size]="ThumbnailSize.XS">
    </bh-thumbnail>
    <ng-template #tmpIcon>
      <bh-icon
        [icon]="item?.icon"
        [fontSet]="item?.fontSet"
        size="lg"
        color="primary"></bh-icon>
    </ng-template>
  </div>

  <div class="content-item-title">
    <div class="title">{{item?.title}}</div>
    <div class="sut-title">{{item?.subTitle}}</div>
  </div>

  <div *ngIf="item && !item?.hideJumpLink"
    class="content-item-link fx fx-align-items-center">
    <bh-icon
      [icon]="jumpIcon"
      color="primary"
      size="sm"
      [hover]="true"
      [matTooltip]="item?.jumpLinkTooltipText"
      (click)="onNavigate.emit(item)"
      data-osp-test="jump-icon">
    </bh-icon>
  </div>
</div>
