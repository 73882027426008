import { OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../shared/router-history';

@Directive()
export abstract class BaseLandingpageListComponent extends GuardedNavigableInputComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
  }

}
