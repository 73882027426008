import { Pipe, PipeTransform } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { Modules } from 'app/shared/enums/modules.enum';
import { MaintenanceTaskAdapter } from './maintenance-task-adapter.class';

@Pipe({
  name: 'isAllowEditCompletedTask'
})
export class IsAllowEditCompletedTaskPipe implements PipeTransform {
  constructor(private authService: KeycloakService) {}

  public transform(task: MaintenanceTaskAdapter): boolean {
    if (!Boolean(task)) {
      return false;
    }

    switch (task.category) {
      case MaintenanceCategory.CHECK:
      case MaintenanceCategory.CHECK_AND_REPAIR:
        return this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE)
          && this.authService.hasModule(Modules.SERVICE);
      case MaintenanceCategory.REMINDER:
      default:
        return false;
    }
  }

}
