import { Injectable } from '@angular/core';
import { MaintenanceDatasourceMediatorReceiver } from './maintenance-datasource-mediator-receiver.interface';
import { MediatorBaseService } from '../mediator-notifier-base.class';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceDatasourceMediatorService
  extends MediatorBaseService<MaintenanceDatasourceMediatorReceiver> {

  public notifyCompletedTaskDeleted(sender: any, taskId: string): void {
    this.processNotification(sender, receiver => receiver?.completedTaskDeleted?.(taskId));
  }

  public notifyTaskCompleted(sender: any, taskId: string): void {
    this.processNotification(sender, receiver => receiver?.taskCompleted?.(taskId));
  }

  public notifyTaskDeleted(sender: any, taskId: string): void {
    this.processNotification(sender, receiver => receiver?.taskDeleted?.(taskId));
  }

}
