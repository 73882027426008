import { Component, OnInit } from '@angular/core';
import { OrganisationsService } from '../../shared/organisations.service';
import { ViewOrganisation } from '../../contract/view-organisation.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MoveOrganisationCommand } from '../../contract/move-organisation-command';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bh-organisation-move',
  templateUrl: './organisation-move.component.html',
  styleUrls: ['./organisation-move.component.scss']
})
export class OrganisationMoveComponent implements OnInit {
  public rootLevel = 'rootLevel';
  public dialogRef: MatDialogRef<OrganisationMoveComponent>;
  public organisationId: string;
  public customerId: string;
  public organisationMoveForm: UntypedFormGroup;
  public organisations: ViewOrganisation[];

  constructor(private organisationsService: OrganisationsService,
              private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.buildForm();
    this.getOrganisations();
  }

  private buildForm() {
    this.organisationMoveForm = this.formBuilder.group({
      organisationId: [this.organisationId, [<any>Validators.required]],
      newParentId: [null, [<any>Validators.required]]
    });
  }

  get newParentId() {
    return this.organisationMoveForm.get('newParentId');
  }

  save() {
    if (this.isValid()) {
      let formValue = this.organisationMoveForm.getRawValue();
      let cmd = new MoveOrganisationCommand();
      cmd.organisationId = formValue.organisationId;
      cmd.newParentId = formValue.newParentId === this.rootLevel ? null : formValue.newParentId;

      this.organisationsService.moveOrganisation(cmd).subscribe(
          data => {
            this.dialogRef.close(data);
          },
          (error: HttpErrorResponse) => {
            console.log('add organisation to users error: ', error);
            this.dialogRef.close();
          });
    }
  }

  public isValid(): boolean {
    return this.organisationMoveForm.valid;
  }

  private getOrganisations() {
    this.organisationsService.getOrganisationsByCustomerId(this.customerId, false, this.organisationId).subscribe(
        res => {
          this.organisations = res;
        }, error => {
          console.log('error on getting organisations', error);
        });
  }
}
