import { LanguageService } from 'app/shared/services/language.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ViewProject } from '../../../../contract/view-project.interface';
import { TransferStock } from '../bulk-item-transfer-stock-select/transfer-stock';
import { ProjectToStockTransferItem } from '../../../../contract/project-to-stock-transfer-item';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { TransferItem } from '../../../../shared/transfer/model/transfer-item';
import { CreateProjectToStockTransferCartCommand } from '../../../../contract/create-project-to-stock-transfer-cart-command';
import { filter, map, startWith, debounceTime } from 'rxjs/operators';
import { faBroom } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { BulkItemTransferStockSelectComponent } from '../bulk-item-transfer-stock-select/bulk-item-transfer-stock-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransferStore } from '../../shared/transfer.store';
import { CreateProjectToConsumedTransferCartCommand } from '../../../../contract/create-project-to-consumed-transfer-cart-command';
import { ProjectToConsumedTransferItem } from '../../../../contract/project-to-consumed-transfer-item.interface';
import { TransferItemType } from 'app/modules/disposition/shared/transfer/model/transfer-item-type.enum';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {LocationType } from '../../../../../../shared/enums/location-type.enum';
import { TransferItemTransferDateRequest } from '../../../../projects/project-list/project-view/project-view-transfer-history/transfer-item-transfer-date-request';
import { TransferItemsSearchMatcher } from 'app/modules/disposition/shared/transfer/utils/transfer-items-search-matcher.class';
import { TransferBackToStockDialogService } from '../../../../shared/services/transfer-back-to-stock-dialog.service';

@UntilDestroy()
@Component({
  selector: 'bh-bulk-item-project-to-stock-transfer-cart',
  templateUrl: './bulk-item-project-to-stock-transfer-cart.component.html',
  styleUrls: ['./bulk-item-project-to-stock-transfer-cart.component.scss']
})
export class BulkItemProjectToStockTransferCartComponent implements OnInit, OnDestroy {

  @ViewChild(BulkItemTransferStockSelectComponent, { static: true }) stockSelect: BulkItemTransferStockSelectComponent;

  public readonly faBroom: IconDefinition = faBroom;
  public sourceProject: ViewProject;
  public selectedStock: TransferStock;
  public consumedSelected = false;
  public transferItems: Observable<TransferItem[]>;
  public isLoadingProjectAmounts = false;

  private isLoadingProjectAmountsSubscription: Subscription;
  private filterControl: UntypedFormControl;
  private projectToStockTransferItems: ProjectToStockTransferItem[] = [];
  private summaryTransferItems: TransferItem[];
  private counterForNotSelectedSubEquipments = 0;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private projectStore: ProjectDataSource,
              private transferStore: TransferStore,
              private snackBar: MatSnackBar,
              private transferBackToStockDialogService: TransferBackToStockDialogService,
              private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.transferBackToStockDialogService.setIsClearProjectTransfer(true);
    this.filterControl = new UntypedFormControl();

    this.isLoadingProjectAmountsSubscription = this.transferStore.loadingProjectAmounts.subscribe((loading: boolean) => {
      this.isLoadingProjectAmounts = loading;
    });

    if (this.projectStore.noProjectSelected()) {
      this.projectStore.setCurrentProject(this.route.snapshot.params['sourceId']);
    }
    this.projectStore.currentProject.pipe(
      filter(sourceProject => !!sourceProject),
      untilDestroyed(this))
      .subscribe(sourceProject => {
      this.sourceProject = sourceProject;
      this.transferStore.getProjectAmounts(sourceProject.projectId);
    });

    this.transferItems =
      combineLatest([
        this.transferStore.transferItems,
        this.filterControl.valueChanges.pipe(debounceTime(500), startWith(''))])
      .pipe(
        map(([items, filterTerm]) => ([items, filterTerm, this.getSearchMatchTransferItemIds(items, filterTerm)])),
        map(([items, filterTerm, searchMatchItems]: [TransferItem[], string, Set<string>]) =>
          items.map((item: TransferItem) => {
            item.hiddenOption = !searchMatchItems.has(item.id);
            item.isActiveContainer = (filterTerm && searchMatchItems.has(item.id)) || item.isActiveContainer;
            return item;
          })
        )
      );

    this.transferStore.transferFailed.pipe(untilDestroyed(this)).subscribe(() => {
      this.snackBar.open(this.translate('modules.disposition.bulkItemTransfer.failedNotFulfilled'),
        this.translate('general.buttons.okay'), {
          duration: 3000
        });
    });

    this.transferStore.transferSuccess.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.consumedSelected) {
        this.transferStore.summaryDialog(
          Array.of(this.transferStore.transfer),
          this.summaryTransferItems.filter(item => item.transferAmount > 0),
          LocationType.STOCK,
          this.selectedStock,
        )
        .afterClosed()
        .pipe(filter(result => result !== 'cancel'))
        .subscribe(() => {
            this.stockSelect.reset();
          },
        );

      }  else {
        this.stockSelect.reset();
      }
    });

    this.transferItems.pipe(
      untilDestroyed(this))
      .subscribe(items => {
        this.summaryTransferItems = items;
        this.projectToStockTransferItems = items.map(item => ProjectToStockTransferItem.fromTransferItem(item));
      });
  }

  public ngOnDestroy(): void {
    this.transferBackToStockDialogService.setIsClearProjectTransfer(false);
    this.isLoadingProjectAmountsSubscription.unsubscribe();
  }

  public updateAmount(item: TransferItem): void {
    this.transferStore.updateTransferAmount(item.id, item.transferAmount);
    this.projectToStockTransferItems
      .filter(transferItem => item.matchesStockAndBulkItem(transferItem.sourceStockId, transferItem.transferItemId))
      .forEach(transferItem => transferItem.amount = item.transferAmount);
    this.summaryTransferItems
    .filter(transferItem => item.matchesStockAndBulkItem(transferItem.sourceStockId, transferItem.id))
    .forEach(transferItem => transferItem.transferAmount = item.transferAmount);

    if (!this.transferBackToStockDialogService.getIsSelectAllUsed()) {
      let numberOfNotSelectedSubEquipments = this.summaryTransferItems.filter(transferItem =>
        transferItem.equipmentContainerId === item.transferId && transferItem.transferAmount === 0).length
      if (numberOfNotSelectedSubEquipments && item.transferAmount) {
        this.counterForNotSelectedSubEquipments = numberOfNotSelectedSubEquipments;
      }
      if (this.counterForNotSelectedSubEquipments === 0) {
        let sourceStockIds = new Set<string>();
        this.projectToStockTransferItems.forEach(projectToStockTransferItem => {
          if (projectToStockTransferItem.amount) {
            sourceStockIds.add(projectToStockTransferItem.sourceStockId);
          }
        });
        this.transferBackToStockDialogService.findOrganisationOfSourceStocksAndGetStocks([...sourceStockIds])
      } else {
        this.counterForNotSelectedSubEquipments--;
      }
    }
  }

  public updateFilterControl(searchTerm: string): void {
    this.filterControl.patchValue(searchTerm.trim().toLowerCase());
  }

  public confirmCart(): void {
    const confirmMessage = this.selectedStock ?
      this.translate('modules.disposition.confirmation.message.transferToStock', {value: this.selectedStock.stockName}) :
      this.translate('modules.disposition.confirmation.message.bulkItemsAreUsedAndLost');
    this.transferStore.confirmationDialog('' +
      this.translate('modules.disposition.bulkItemTransfer.clearProject'),
      this.translate('general.buttons.cancel'),
      this.translate('modules.disposition.confirmation.title.confirm'),
      confirmMessage,
      this.selectedStock ? LocationType.STOCK : null,
      this.selectedStock ? this.selectedStock.stockId : null,
      this.calculateTransferItems())
      .afterClosed()
      .pipe(filter(result => result !== 'cancel'))
      .subscribe({next: res => {
          this.transferStore.waitingDialogRef = this.transferStore.waitingDialog();
          this.createCart(res);
        }
      });
  }

  public closeCart(): void {
    this.router.navigate([`sites/projects/list/${this.sourceProject.projectId}/assignments`]);
  }

  public selectStock(stock: TransferStock): void {
    this.selectedStock = stock;
    this.consumedSelected = false;
    this.projectToStockTransferItems.map(item => item.sourceProjectId = this.sourceProject.projectId);
  }

  public selectConsumed(): void {
    this.consumedSelected = true;
  }

  private getSearchMatchTransferItemIds(items: TransferItem[], filterTerm: string): Set<string> {
    if (!filterTerm) {
      return new Set((items || []).map(({ id }) => id));
    }

    const searchMatcher = new TransferItemsSearchMatcher(items);
    return searchMatcher.getMatchItemIds(filterTerm);
  }

  private createCart(form: UntypedFormGroup): void {
    if (this.consumedSelected) {
      this.transferProjectToConsumed(form);
    } else {
      this.transferProjectToStock(form);
    }
  }

  private transferProjectToStock(form: UntypedFormGroup): void {
    const cmd: CreateProjectToStockTransferCartCommand = new CreateProjectToStockTransferCartCommand(
      this.selectedStock.stockId,
      this.projectToStockTransferItems.filter(item => item.amount > 0),
      form.get('employeeId').value ? form.get('employeeId').value : null,
      form.get('externalEmployee').value ? form.get('externalEmployee').value : null,
      form.get('comment').value ? form.get('comment').value : null,
      form.get('historicTransferDateTime').value ? form.get('historicTransferDateTime').value : null);

    this.transferStore.transferProjectToStock(this.sourceProject.projectId, cmd);
  }

  private transferProjectToConsumed(form: UntypedFormGroup): void {
    const cmd: CreateProjectToConsumedTransferCartCommand = new CreateProjectToConsumedTransferCartCommand(
      this.sourceProject.projectId,
      this.projectToStockTransferItems
        .filter(item => item.amount > 0)
        .map(item => ProjectToConsumedTransferItem.fromProjectToStockTransferItem(item)),
      form.get('employeeId').value ? form.get('employeeId').value : null,
      form.get('externalEmployee').value ? form.get('externalEmployee').value : null,
      form.get('comment').value ? form.get('comment').value : null,
      form.get('historicTransferDateTime').value ? form.get('historicTransferDateTime').value : null);

    this.transferStore.transferProjectToConsumed(this.sourceProject.projectId, cmd);
  }

  private translate(key: string, interpolateParam?: Object): string {
    return this.languageService.getInstant(key, interpolateParam);
  }

  public equipmentsSelected(): boolean {
    return this.projectToStockTransferItems.filter(item => item.transferItemType ===
       TransferItemType.EQUIPMENT && item.amount === 1).length > 0;
  }

  private calculateTransferItems(): TransferItemTransferDateRequest[] {
    return this.projectToStockTransferItems
      .filter(item => item.amount > 0)
      .map(item => TransferItemTransferDateRequest.fromProjectToStockTransferItem(item));
  }

}
