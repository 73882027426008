<div fxLayout="row" fxLayoutAlign="end start">
  <div fxLayout="row" fxLayoutAlign="start end" fxFlex="150px">
    <fa-icon class="sort-icon" [icon]="faSortAlphaDown"></fa-icon>
    <mat-form-field class="no-margin-bottom">
      <mat-label>{{'general.labels.sortBy'|translate}}:</mat-label>
      <mat-select [(value)]="documentsSortParameter" (selectionChange)="changeDocumentsSortParameter($event.value)">
        <mat-option [value]="fileName">{{'general.name.s'|translate}}</mat-option>
        <mat-option [value]="fileDescription">{{'general.description'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="attachments" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="attachment-type-container" *ngIf="isEditable">
    <bh-subtitle [text]="'general.labels.uploadDocPict'|translate"></bh-subtitle>
    <bh-file-upload
      [itemId]="employee?.employeeId"
      uploaderType="EMPLOYEE">
    </bh-file-upload>
  </div>

  <bh-attachment-list-category
    [documents]="documents"
    [pendingChanges]="waiting"
    [deletable]="isDeleteEnabled()"
    [editable]="isEditable"
    [editableName]="isEditable"
    (deleted)="deleteDocument($event, false)"
    (descriptionChanged)="saveDescription($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-category>

  <bh-attachment-list-image-category
    [images]="images"
    [setDefaultEnable]="setDefaultEnabled"
    [defaultImage]="defaultImage"
    (defaultImageChanged)="setStandardImage($event.documentKey)"
    [pendingChanges]="waiting"
    [visibleDescription]="true"
    [editableDescription]="isEditable"
    [editableName]="isEditable"
    (descriptionChanged)="saveDescription($event)"
    [deletable]="isDeleteEnabled()"
    (deleted)="deleteDocument($event, true)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-image-category>

  <bh-attachment-list-category *ngIf="isVisibleDocsRenewal"
    [documents]="documentsRenewal"
    [category]="'modules.disposition.employeeViewDetail.attachmentsRenewalTitle'|translate"
    [pendingChanges]="waiting"
    [deletable]="isDeleteEnableDocsRenewal()"
    [editable]="isEditableDocsRenewal"
    [editableName]="isEditableDocsRenewal"
    (deleted)="deleteDocumentRenewal($event)"
    (descriptionChanged)="saveDescriptionRenewal($event)"
    (nameChanged)="saveFileNameRenewal($event)"
    data-osp-test="attachment-list-renewal">
  </bh-attachment-list-category>

</div>
