<div class="dialog-wrapper" fxLayout="column">
  <div class="header-row" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="dialog-close-icon">
      <button mat-icon-button [mat-dialog-close]="dialogResults.ABORT" tabindex="-1">
        <mat-icon aria-hidden="true">clear</mat-icon>
      </button>
    </div>
    <h1 mat-dialog-title>{{title}}</h1>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    <div class="content-subtitle">{{subTitle}}</div>
    <mat-label>{{'shared.maintenance.rules.dueDate'|translate}}</mat-label>
    <bh-date-picker-input
      [control]="control"
      [min]="min"
      [showClearIcon]="true"
      [errorMessageMatcher]="errorMessageMatcher">
    </bh-date-picker-input>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button mat-raised-button [mat-dialog-close]="dialogResults.ABORT">
      {{'general.buttons.cancel'|translate}}
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="control.invalid" data-osp-test="button-save">
      {{'general.buttons.save'|translate}}
    </button>
  </div>
</div>
