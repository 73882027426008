import { UUID } from 'angular2-uuid';
import { PushType } from '../enums/push-type.enum';
import { DeviceType } from './device-type.enum';

export class PushToken {

  static generatePushTokenForFirebase(token: string, uuid?: UUID) {
    return new PushToken(uuid || UUID.UUID(), token, DeviceType.WEB, PushType.FIREBASE)
  }

  constructor(public uuid: UUID,
              public token: string,
              public deviceType: DeviceType,
              public pushType: PushType) {
  }
}
