import { Injectable } from '@angular/core';
import { MaintenanceTaskService } from '../maintenance-task.service';
import {
  MaintenanceTaskCompletedBaseDataSource,
} from 'app/modules/maintenance/tasks/shared/service/maintenance-task-completed-datasource/maintenance-task-completed-base.datasource';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { BehaviorSubject, combineLatest, Subject, switchMap, Observable } from 'rxjs';
import { MaintenanceCompletedRequestParams } from '../../contract/request-params/maintenance-completed-request-params.interface';
import { MaintenanceTaskCompletedSearch } from '../../maintenance-task-completed-search.interface';
import { MaintenanceCompletedFiltersService } from '../maintenance-completed-filters.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { delay, distinctUntilChanged, filter, map, skip, take, tap } from 'rxjs/operators';
import _ from 'lodash';
import { ColumnDefinition } from 'app/shared/column-definition';
import { MaintenanceCompletedColumnService } from '../maintenance-completed-column.service';
import { Sort } from '@angular/material/sort';
import { manualSortString, sortToSortString } from '../maintenance-task-sort.utils';
import { Router } from '@angular/router';
import { MaintenanceCompletedExportRequestParams } from '../../contract/request-params/maintenance-completed-export-request-params.interface';
import { MaintenanceTask } from '../../maintenance-task';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { DeleteMaintenanceTaskFileCommand } from '../../contract/commands/delete-maintenance-task-file.command';
import { UploadMaintenanceTaskFileCommand } from '../../contract/commands/upload-maintenance-task-file.command';
import { AttachedDocument } from 'app/shared/contract/attached-document.interface';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceService } from '../../../../../equipment/shared/maintenance.service';
import { MaintenanceTaskCompletedAuditResponse } from '../../../../shared/contract/maintenance-task-completed-audit-response.interface';
import { MaintenanceDatasourceMediatorService } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator.service';
import { MaintenanceMediatorReceiverCompletedTaskDeleted } from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator-receiver.interface';
import {UpdateMaintenanceTaskDocumentNameCommand} from '../../update-maintenance-task-document-name.command';
import {
  UpdateMaintenanceTaskDocumentDescriptionCommand
} from '../../update-maintenance-task-document-description.command';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { environment } from 'environments/environment';


@Injectable()
export class MaintenanceTaskCompletedDataSource
  extends MaintenanceTaskCompletedBaseDataSource
  implements MaintenanceMediatorReceiverCompletedTaskDeleted {

  private _data = new BehaviorSubject<MaintenanceTaskCompletedSearch[]>([]);
  public readonly data = this._data.asObservable();
  private _totalCount = new BehaviorSubject<number>(0);
  public readonly totalCount = this._totalCount.asObservable();
  private _currentTask = new BehaviorSubject<MaintenanceTask>(null);
  public readonly currentTask = this._currentTask.asObservable();

  public searchTerm: string;
  public sort: Sort;
  public readonly filters = this.maintenanceCompletedFiltersService.filters;
  public readonly onFiltersUpdated = this.maintenanceCompletedFiltersService.onFiltersUpdated;

  private dataRequest = new Subject<MaintenanceCompletedRequestParams>();
  private initialRequest = true;
  private shouldSelectDefaultTask = false;
  private readonly baseListPath = '/maintenance/tasks/maintenance-completed-list';
  private readonly requiredSearchColumns = [
    MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME,
    GENERAL_COLUMN_DEF.EQUIPMENT_NAME,
    GENERAL_COLUMN_DEF.EQUIPMENT_MODEL,
    GENERAL_COLUMN_DEF.EQUIPMENT_SERIAL_NUMBER,
    GENERAL_COLUMN_DEF.EQUIPMENT_CUSTOMER_SERIAL_NUMBER,
  ];
  private searchColumns: string[] = [...this.requiredSearchColumns];

  constructor(
    private maintenanceTaskService: MaintenanceTaskService,
    private maintenanceCompletedFiltersService: MaintenanceCompletedFiltersService,
    private maintenanceCompletedColumnService: MaintenanceCompletedColumnService,
    private userConfigurationService: UserConfigurationService,
    languageService: LanguageService,
    router: Router,
    dialog: MatDialog,
    maintenanceService: MaintenanceService,
    maintenanceMediator: MaintenanceDatasourceMediatorService,
  ) {
    super(
      router,
      dialog,
      maintenanceService,
      maintenanceMediator,
      languageService,
      userConfigurationService.userConfiguration?.completedMaintenanceListConfiguration?.pageSize || 25,
    );
    this.maintenanceMediator.addReceiver(this);
    this.dataRequestListener();
    this.initColumnListener();
  }

  public refreshOnChanges(unselectTask: boolean = false) {
    if (!this.initialRequest) {
      if (unselectTask) {
        this._currentTask.next(null);
        this.shouldSelectDefaultTask = true;
        this.router.navigate([this.baseListPath]);
      }

      this.updateListing();
      this.maintenanceCompletedFiltersService.updateFilters();
    }
  }

  public updateListing(page: number = this._pagination.value.index, size: number = this._pagination.value.size): void {
    if (this._data.value.length === 0 && this._pagination.value.last && page !== 0) {
      page--;
    }
    if (size !== this._pagination.value.size) {
      this.pageSizeChanged(size);
    }
    this._pagination.next({...this._pagination.value, index: page, size});
    this.dataRequest.next(this.defineMaintenanceCompletedParams(page, size));
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.maintenanceCompletedFiltersService.updateFilterParams(commands);
  }

  public changeCurrentTask(taskId: string): void {
    if (taskId) {
      this.maintenanceTaskService.getById(taskId)
        .subscribe(task => this._currentTask.next(task));
    } else {
      this._currentTask.next(null);
    }
  }

  public selectCurrentOrDefault(): void {
    if (this._currentTask.value) {
      this.router.navigate([this.baseListPath, this._currentTask.value.id]);
    } else {
      this.selectDefaultTask();
    }
  }

  protected updateStoreData(response: PagedResponse<MaintenanceTaskCompletedSearch>): void {
    this.updatePaginationFromResponse(response);
    this._data.next(response.content);
    this._totalCount.next(response.totalElements);
    if (this.shouldSelectDefaultTask) {
      this.shouldSelectDefaultTask = false;
      this.selectCurrentOrDefault();
    }
  }

  public exportMaintenanceCompletedList(columns: string[], applyFilter = false, sortBy: string,
                                        sortDescending: boolean, timezone: string): Observable<Blob> {
    const params: MaintenanceCompletedExportRequestParams = {
      term: this.searchTerm,
      columns: columns,
      searchColumns:  this.getSearchColumns(columns),
      sort: `${sortBy},${sortDescending ? 'desc' : 'asc'}`,
      zoneId: timezone,
      ...(applyFilter ? this.maintenanceCompletedFiltersService.getFilterParams() : {}),
    } as MaintenanceCompletedExportRequestParams;
    return this.maintenanceTaskService.exportMaintenanceCompletedList(params);
  }

  public getFilesForTask(id: string): Observable<DocumentLink[]> {
    return this.maintenanceTaskService.getFilesForTask(id);
  }

  public uploadFile(maintenanceTaskId: string, document: AttachedDocument): Observable<string> {
    return this.maintenanceTaskService.uploadFile(new UploadMaintenanceTaskFileCommand(
      maintenanceTaskId,
      document
    ));
  }

  public deleteMaintenanceTaskFile(taskId: string, documentKey: string): Observable<string> {
    return this.maintenanceTaskService.deleteDocument(new DeleteMaintenanceTaskFileCommand(taskId, documentKey));
  }

  public updateDocumentName(task: MaintenanceTask, document: DocumentLink): Observable<any> {
    return this.maintenanceTaskService.updateDocumentName(new UpdateMaintenanceTaskDocumentNameCommand(
      task.id,
      document.documentKey,
      document.fileName
    ));
  }

  public updateDocumentDescription(task: MaintenanceTask, document: DocumentLink): Observable<any> {
    return this.maintenanceTaskService.updateDocumentDescription(new UpdateMaintenanceTaskDocumentDescriptionCommand(
      task.id,
      document.documentKey,
      document.fileDescription
    ));
  }

  public getAuditData(taskId: string): Observable<MaintenanceTaskCompletedAuditResponse> {
    return this.maintenanceTaskService.getMaintenanceCompletedAuditData(taskId);
  }

  public deleteTask(taskId: string, taskCategory: MaintenanceCategory, equipmentId: string): void {
    this.deleteCompletedTask(taskId, taskCategory, equipmentId)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.refreshOnChanges(true));
  }

  public completedTaskDeleted(taskId: string) {
    if (this._currentTask.getValue()?.id === taskId) {
      this.changeCurrentTask(null);
      this.shouldSelectDefaultTask = true;
    }

    const index = (this._data.getValue() || []).findIndex(({ id }) => taskId === id);
    if (index > -1) {
      const newData = [...this._data.getValue()];
      newData.splice(index, 1);
      this._data.next(newData);
    }
  }

  public navigateToTaskCompletedList(taskId: string, filterCommands: UpdateFilterCommand[]): void {
    this.searchTerm = '';
    this.maintenanceCompletedFiltersService.resetFilters(filterCommands, false);
    this.router.navigate([this.baseListPath, taskId].filter(Boolean));
  }

  private pageSizeChanged(pageSize: number): void {
    this.userConfigurationService.saveCompletedMaintenanceListConfigurationPageSize(pageSize);
  }

  private selectDefaultTask(): void {
    this.data
    .pipe(
      filter((res: MaintenanceTaskCompletedSearch[]) => Boolean(res && res.length > 0 && res[0].id)),
      map((res: MaintenanceTaskCompletedSearch[]) => res[0].id),
      take(1))
    .subscribe((taskId: string) => this.router.navigate([this.baseListPath, taskId]));
  }

  private dataRequestListener(): void {
    combineLatest([
      this.dataRequest,
      this.maintenanceCompletedFiltersService.isReady,
    ])
    .pipe(
      tap(([params, isReady]) => this.initialRequest = !isReady ? true : this.initialRequest),
      filter(([params, isReady]) => Boolean(isReady)),
      map(([params, isReady]) => {
        if (this.initialRequest) {
          return { ...params, ...this.maintenanceCompletedFiltersService.getFilterParams() };
        }

        return params;
      }),
      switchMap((params: MaintenanceCompletedRequestParams) => this.maintenanceTaskService.getCompletedTasks(params)))
    .subscribe((response: PagedResponse<MaintenanceTaskCompletedSearch>) => {
      this.initialRequest = false;
      this.updateStoreData(response);
    });
  }

  private defineMaintenanceCompletedParams(page?: number, size?: number): MaintenanceCompletedRequestParams {
    return {
      size,
      page: page || 0,
      sort: this.getSortRequest(),
      term: this.searchTerm,
      searchColumns: this.searchTerm ? this.searchColumns : null,
      ...this.maintenanceCompletedFiltersService.getFilterParams(),
    };
  }

  private initColumnListener(): void {
    this.maintenanceCompletedColumnService.selectedColumns.pipe(
      distinctUntilChanged(_.isEqual),
      map((columns: ColumnDefinition[]) => columns.map(c => c.cdkColumnDef)),
      tap((currentlySelectedColumns: string[]) => this.searchColumns = this.getSearchColumns(currentlySelectedColumns)),
      skip(1))
    .subscribe(() => this.updateListing());
  }

  private getSearchColumns(currentSelectedColumns: string[]): string[] {
    const columns = [...this.requiredSearchColumns, ...currentSelectedColumns];
    return [...new Set(columns)];
  }

  private getSortRequest(): string | string[] {
    if (!this.sort?.active || !this.sort?.direction) {
      return null;
    }

    switch (this.sort.active) {
      case GENERAL_COLUMN_DEF.ASSIGNED_OWNER:
      case GENERAL_COLUMN_DEF.ASSIGNED_MAINTENANCE_ASSIGNEE:
      case GENERAL_COLUMN_DEF.ASSIGNED_PERSON_IN_CHARGE:
      case GENERAL_COLUMN_DEF.ASSIGNED_DRIVER:
      case GENERAL_COLUMN_DEF.ASSIGNED_POSSESSOR:
        return [
          manualSortString(`${this.sort.active}.firstName`, this.sort.direction),
          manualSortString(`${this.sort.active}.name`, this.sort.direction),
        ];
      case GENERAL_COLUMN_DEF.EQUIPMENT_CONSTRUCTION_YEAR:
        return [
          `${this.sort.active}.year,${this.sort.direction}`,
          `${this.sort.active}.month,${this.sort.direction}`
        ];
      case GENERAL_COLUMN_DEF.STATUS:
        return manualSortString('equipmentStatus.rank', this.sort.direction, false);
      case MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_CATEGORY:
      case GENERAL_COLUMN_DEF.ICON:
        return manualSortString('maintenanceCategory', this.sort.direction, false);
      case MAINTENANCE_TASK_COLUMN_DEF.TELEMATIC:
        return [
          manualSortString('assignedTelematicUnits', this.sort.direction, false),
          manualSortString('lastTelematicsUpdate', this.sort.direction, false),
        ];
      case MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_DATE:
      case MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_MILEAGE:
      case MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_OPERATING_HOURS:
        return sortToSortString(this.sort, false);
      case MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_RESULT:
        return manualSortString('maintenanceResultOrder', this.sort.direction, false);
      case MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_BY:
        return [
          manualSortString('completedByInfo.firstName', this.sort.direction),
          manualSortString('completedByInfo.name', this.sort.direction),
        ];
      default:
        return sortToSortString(this.sort);
    }
  }
}
