import { LanguageService } from 'app/shared/services/language.service';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface SelectedElement<T> {
  disableApproveAll?: boolean;
  disableDeleteAll?: boolean;
  element: T;
}

@Injectable()
export class EditListService {
  // Purposely named in a general matter as this should be used on every list in a mobile view in the future.
  public approveAllPressed: EventEmitter<any[]> = new EventEmitter<any[]>();
  public deleteAllPressed: EventEmitter<any[]> = new EventEmitter<any[]>();
  private references: BehaviorSubject<SelectedElement<any>[]> = new BehaviorSubject<SelectedElement<any>[]>([]);
  private disableApproveAll = false;
  private disableDeleteAll = false;
  private _selected: BehaviorSubject<number> = new BehaviorSubject(0);
  private _editMode: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private matSnackBar: MatSnackBar, private languageService: LanguageService) {

  }

  public getSelected(): Observable<number> {
    return this._selected.asObservable();
  }

  public getEditMode(): Observable<boolean> {
    return this._editMode.asObservable();
  }

  public init(): void {
    this._selected.next(0);
    this._editMode.next(false);
    this.references.next([]);
    this.disableDeleteAll = false;
    this.disableApproveAll = false;
  }

  public increment<T>(element: SelectedElement<T>): void {
    this.references.next([...this.references.value, element]);
    this._selected.next(this.references.value.length);
    this.disableOrEnableButtons();
  }

  public decrement<T>(element: T, filterFunction: (searchObj: T, loopObj: SelectedElement<T>) => boolean): void {
    this.references.next([...this.references.value.filter(obj => filterFunction(element, obj))]);
    this._selected.next(this.references.value.length);
    this.disableOrEnableButtons();
  }

  public approveAll(): void {
    if (!this.disableApproveAll) {
      this.approveAllPressed.emit(this.references.value);
    } else {
      this.matSnackBar.open(
        this.translate('general.snackBarMessages.notAuthorizedToConfirm'),
        this.translate('general.buttons.okay'));
    }
  }

  public deleteAll(): void {
    if (!this.disableDeleteAll) {
      this.deleteAllPressed.emit(this.references.value);
    } else {
      this.matSnackBar.open(
        this.translate('general.snackBarMessages.notAuthorizedToDelete'),
        this.translate('general.buttons.okay'));
    }
  }

  public setEditMode(value: boolean): void {
    this._editMode.next(value);
    if (value === false) {
      this.init();
    }
  }

  private disableOrEnableButtons(): void {
    let disableDeleteAll = false;
    let disableApproveAll = false;
    this.references.value.forEach((item) => {
      if (!disableApproveAll && item.disableApproveAll) {
        disableApproveAll = true;
      }
      if (!disableDeleteAll && item.disableDeleteAll) {
        disableDeleteAll = true;
      }
    });
    this.disableApproveAll = disableApproveAll;
    this.disableDeleteAll = disableDeleteAll;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
