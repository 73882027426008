import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../router-history';

@Component({
  selector: 'bh-mobile-sub-header',
  templateUrl: './mobile-sub-header.component.html',
  styleUrls: ['./mobile-sub-header.component.scss']
})
export class MobileSubHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() navigateToList: boolean;
  @Input() cancelRoute: string;

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected routerHistory: RouterHistory) {
  }

  ngOnInit(): void {
  }

  navigateBack(): void {
    if (this.cancelRoute) {
      this.router.navigate([this.cancelRoute]);
    } else if (this.navigateToList) {
      this.backToList();
    } else {
      this.routerHistory.goBack('');
    }
  }

  private backToList(): void {
    const basePath = this.route.snapshot.pathFromRoot[1].routeConfig.path;
    this.router.navigate([basePath, 'list']);
  }
}
