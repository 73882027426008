import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { EmployeeManagementService } from './employee-management.service';
import { Sort } from '@angular/material/sort';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { delay, filter, map, skip, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { SearchEmployee } from '../contract/search-employee.interface';
import { ViewEmployee } from '../contract/view-employee.interface';
import { DataSource } from '@angular/cdk/collections';
import { AddEmployeeLabelCommand } from '../contract/add-employee-label-command';
import { RemoveEmployeeLabelCommand } from '../contract/remove-employee-label-command';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { DeactivateEmployeeCommand } from '../contract/deactivate-employee-command';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { DeleteEmployeeDocumentCommand } from '../contract/delete-employee-document-command';
import { UpdateEmployeeDocumentDescriptionCommand } from '../contract/update-employee-document-description-command';
import { SetEmployeeThumbnailCommand } from '../contract/set-employee-thumbnail-command';
import { DeleteEmployeeCommand } from '../contract/delete-employee.command';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewEmployeeNote } from '../contract/view-employee-note.interface';
import { CreateEmployeeNoteCommand } from '../contract/create-employee-note-command';
import { UpdateEmployeeNoteCommand } from '../contract/update-employee-note.command';
import { DeleteEmployeeNoteCommand } from '../contract/delete-employee-note.command';
import { EmployeeStatus } from '../contract/employee-status.enum';
import { AssignEmployeeToUserCommand } from '../contract/assign-employee-to-user-command';
import { UnassignEmployeeFromUserCommand } from '../contract/unassign-employee-from-user-command';
import { ActivateEmployeeCommand } from '../contract/activate-employee-command';
import { QualificationService } from './qualification.service';
import { Qualification } from 'app/modules/disposition/contract/qualification';

import { RenewEmployeeQualificationCommand } from '../contract/employee-qualification-renew-command';
import { EmployeeQualification } from '../contract/employee-qualification';
import { CreateEmployeeCommand } from '../contract/create-employee.command';
import { UpdateEmployeeCommand } from '../contract/update-employee.command';
import { ViewEmployeeEquipmentAssignment } from '../contract/view-employee-equipment-assignment';
import { TeamService } from './services/team.service';
import { ViewTeam } from '../contract/view-team.interface';
import { CreateTeamCommand } from '../contract/create-team-command';
import { SetTeamNameCommand } from '../contract/set-team-name-command';
import { AddEmployeeToTeamCommand } from '../contract/add-employee-to-team-command';
import { RemoveEmployeeFromTeamCommand } from '../contract/remove-employee-from-team-command';
import { SetTeamLeaderCommand } from '../contract/set-team-leader-command';
import { UnsetTeamLeaderCommand } from '../contract/unset-team-leader-command';
import { EmployeeQualificationDocumentUpdateCommand } from '../contract/employee-qualification-document-update-command';
import { QualificationCreateCommand } from '../contract/qualification-create-command';
import { EmployeeQualificationAssignCommand } from '../contract/employee-qualification-assign-command';
import { UpdateEmployeeQualificationAssignmentCommand } from '../contract/update-employee-qualification-assignment-command';
import { ViewEmployeeQualification } from '../contract/view-employee-qualification';
import { QualificationUpdateCommand } from '../contract/qualification-update-command';
import { EmployeeQualificationAssignmentRemoveCommand } from '../contract/employee-qualification-assignment-remove-command';
import { EmployeeColumnService } from '../staff/service/employee-column.service';
import { EmployeeFilterService } from '../staff/service/employee-filter.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmployeeSearchFilterRequestParams } from '../staff/employee-request-params/employee-search-filter-request-params.interface';
import {UpdateEmployeeDocumentNameCommand} from '../contract/update-employee-document-name.command';
import { SingleEmit } from 'app/shared/single-emit.class';

const emptyEmployee: ViewEmployee = {
  employeeId: '',
  employeeStaffNumber: '',
  employeeFirstName: '',
  employeeName: '',
  employeeAddress: null,
  employeeLocation: null,
  organisationId: '',
  organisationName: '',
  customerId: '',
  employeeEmail: '',
  employeeHomePhoneNumber: '',
  employeeHomeMobileNumber: '',
  employeeOfficePhoneNumber: '',
  employeeOfficeMobileNumber: '',
  labels: [],
  defaultImage: '',
  assignedUserId: '',
  teamId: '',
  employeeStatus: EmployeeStatus.ACTIVE,
  isTeamLeader: false
};
const emptyTeam: ViewTeam = {
  teamId: '',
  teamName: '',
  employeeIds: [],
  teamLeaderEmployeeId: '',
  teamMembers: [],
  customerId: ''
};

@UntilDestroy()
@Injectable()
export class EmployeeManagementDatasource extends DataSource<SearchEmployee> {

  private _data: BehaviorSubject<SearchEmployee[]> = new BehaviorSubject([]);
  private _allEmployees: BehaviorSubject<ViewEmployee[]> = new BehaviorSubject([]);
  private _freeEmployeeFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private _currentEmployee: BehaviorSubject<ViewEmployee> = new BehaviorSubject(null);
  private _equipmentAssignedToCurrentEmployee: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _projectAssignedToCurrentEmployee: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  private _employeeLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _currentViewTab = 'general';
  private _documents: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private _documentsRenewal: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private _notes: BehaviorSubject<ViewEmployeeNote[]> = new BehaviorSubject([]);
  private _employeeQualifications: BehaviorSubject<ViewEmployeeQualification> = new BehaviorSubject(null);
  private _qualifications: BehaviorSubject<Qualification[]> = new BehaviorSubject([]);
  private _equipmentAssignments: BehaviorSubject<ViewEmployeeEquipmentAssignment[]> = new BehaviorSubject([]);
  private _currentTeam: BehaviorSubject<ViewTeam> = new BehaviorSubject(null);
  private _teamNames: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _currentQualification: BehaviorSubject<EmployeeQualification> = new BehaviorSubject(null);

  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private _sort: Sort;

  public readonly employees: Observable<SearchEmployee[]> = this._data.asObservable();
  public readonly currentEmployee: Observable<ViewEmployee> = this._currentEmployee.asObservable();
  public readonly equipmentAssignedToCurrentEmployee: Observable<boolean> = this._equipmentAssignedToCurrentEmployee.asObservable();
  public readonly projectAssignedToCurrentEmployee: Observable<boolean> = this._projectAssignedToCurrentEmployee.asObservable();
  public readonly employeeLabels: Observable<string[]> = this._employeeLabels.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly documents: Observable<DocumentLink[]> = this._documents.asObservable();
  public readonly documentsRenewal: Observable<DocumentLink[]> = this._documentsRenewal.asObservable();
  public readonly notes: Observable<ViewEmployeeNote[]> = this._notes.asObservable();
  public readonly equipmentAssignments: Observable<ViewEmployeeEquipmentAssignment[]> = this._equipmentAssignments.asObservable();
  public readonly employeeQualifications: Observable<ViewEmployeeQualification> = this._employeeQualifications.asObservable();
  public readonly qualifications: Observable<Qualification[]> = this._qualifications.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly currentTeam: Observable<ViewTeam> = this._currentTeam.asObservable();
  public readonly teamNames: Observable<string[]> = this._teamNames.asObservable();
  public readonly currentQualification: Observable<EmployeeQualification> = this._currentQualification.asObservable();
  public readonly filters = this.employeeFilterService.filters;
  public readonly onFiltersUpdated = this.employeeFilterService.onFiltersUpdated;

  public readonly filteredCustomerLabels: Observable<string[]> = combineLatest(this._labels, this._employeeLabels)
  .pipe(map(([availableLabels, selectedLabels]) =>
    availableLabels.map(label => label.name)
    .filter(label => !selectedLabels.includes(label)),
  ));
  public readonly filteredFreeEmployees: Observable<ViewEmployee[]> = combineLatest(this._allEmployees, this._freeEmployeeFilter)
  .pipe(map(([allEmployees, filterTerm]) =>
    allEmployees.filter((employee: ViewEmployee) => {
      const name = employee.employeeName.toLowerCase();
      const firstName = employee.employeeFirstName ? employee.employeeFirstName.toLowerCase() : '';
      const searchTerm = filterTerm ? filterTerm.toLowerCase() : '';
      return this.isEmployeeFree(employee) && (name.includes(searchTerm) || firstName.includes(searchTerm));
    }).sort(( a, b ) =>
      (a.employeeName + ', ' + a.employeeFirstName)
      .localeCompare((b.employeeName + ', ' + b.employeeFirstName)))
  ));


  constructor(private qualificationService: QualificationService,
              private snackBar: MatSnackBar,
              private router: Router,
              private employeeManagementService: EmployeeManagementService,
              private employeeFilterService: EmployeeFilterService,
              public employeeColumnService: EmployeeColumnService,
              private teamService: TeamService) {
    super();
    this.paginatorPageSizeListener();
    this._currentTeam.next(emptyTeam);
    this._currentEmployee
    .subscribe(employee => {
      if (employee) {
        this.getTeam(employee.teamId);
      }
    });
    this.labelsSubscription();
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
    } else {
      this.snackBar.open('Eingabe zu lang. Suche nicht möglich', undefined, {duration: 5000});
    }
  }

  set sort(sort: Sort) {
    this._sort = sort;
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  get currentViewTab(): string {
    return this._currentViewTab;
  }

  set currentViewTab(currentViewTab: string) {
    this._currentViewTab = currentViewTab;
  }

  public connect(): Observable<SearchEmployee[]> {
    return this.employees;
  }

  public disconnect(): void {
  }

  public updateCurrentQualification(): void {
    if (this._currentQualification.value) {
      this.changeCurrentQualification(this._currentQualification.value);
    }
  }

  public changeCurrentQualification(qualification) {
    this._currentQualification.next(qualification);
  }

  public updateCurrentEmployee(): void {
    if (this._currentEmployee.value) {
      this.changeCurrentEmployee(this._currentEmployee.value.employeeId);
    } else {
      this.selectCurrentEmployeeOrDefault();
    }
  }

  public changeCurrentEmployee(employeeId: string): void {
    this.employeeManagementService
    .getEmployee(employeeId)
    .subscribe((res: ViewEmployee) => {
      this._currentEmployee.next(res);
      this._employeeLabels.next(res.labels ? res.labels : []);
    });
    this.employeeManagementService.getEquipmentAssignedToEmployee(employeeId).subscribe((res: boolean) => {
      this._equipmentAssignedToCurrentEmployee.next(res);
    });
    this.employeeManagementService.employeeAssignedToProject(employeeId).subscribe((res: boolean) => {
      this._projectAssignedToCurrentEmployee.next(res);
    })
  }

  public createEmployee(cmd: CreateEmployeeCommand): Observable<string> {
    return this.employeeManagementService
      .createEmployee(cmd)
      .pipe(
        switchMap((employeeId: string) => {
          setTimeout(() => {
            this.getAllEmployees();
            this.updateListing(this.pagination.index, this.pagination.size);
            this.router.navigate(['assets/employee/list', employeeId]);
          }, environment.DELAY_LONG);
          return of(employeeId);
        }));
  }

  public updateEmployee(cmd: UpdateEmployeeCommand): Observable<string> {
    return this.employeeManagementService
      .updateEmployee(cmd)
      .pipe(
        switchMap((employeeId: string) => {
          setTimeout(() => {
            this.getAllEmployees();
            this.updateListing(this.pagination.index, this.pagination.size);
            this.router.navigate(['assets/employee/list', employeeId]);
          }, environment.DELAY_LONG);
          return of(employeeId);
        }));
  }

  public deleteEmployee(cmd: DeleteEmployeeCommand): void {
    this.employeeManagementService
    .deleteEmployee(cmd)
    .pipe(
        delay(environment.DELAY_LONG),
        tap(() => this.getAllEmployees()),
        tap(() => this.getTeamNames()),
        tap(() => this.updateListing(this.pagination.index, this.pagination.size)),
        switchMapTo(this.employees),
        skip(1),
        take(1)
    )
    .subscribe(
        (res: SearchEmployee[]) => {
          if (res.length > 0) {
            this.router.navigate(['assets/employee/list', res[0].employeeId]);
          } else {
            this._currentEmployee.next(emptyEmployee);
            this.router.navigate(['assets/employee/list']);
          }
        },
        (error: HttpErrorResponse) => console.log(error),
    );
  }

  public deactivateEmployee(cmd: DeactivateEmployeeCommand): void {
    this.employeeManagementService
      .deactivateEmployee(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getAllEmployees()),
        tap(() => this.getTeamNames()),
        tap(() => this.updateListing(this.pagination.index, this.pagination.size)),
        switchMapTo(this.employees),
        skip(1),
        take(1)
      )
      .subscribe(
        (res: SearchEmployee[]) => {
          if (res.length > 0) {
            this.router.navigate(['assets/employee/list', res[0].employeeId]);
          } else {
            this._currentEmployee.next(emptyEmployee);
            this.router.navigate(['assets/employee/list']);
          }
        },
        (error: HttpErrorResponse) => console.log(error),
      );
  }

  public activateEmployee(cmd: ActivateEmployeeCommand): void {
    this.employeeManagementService
      .activateEmployee(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.getAllEmployees()),
        tap(() => this.getTeamNames()),
        tap(() => this.updateListing(this.pagination.index, this.pagination.size)),
        switchMapTo(this.employees),
        skip(1),
        take(1)
      )
      .subscribe(
          (res: SearchEmployee[]) => {
            if (res.length > 0) {
              this.router.navigate(['assets/employee/list', res[0].employeeId]);
            } else {
              this._currentEmployee.next(emptyEmployee);
              this.router.navigate(['assets/employee/list']);
            }
          },
          (error: HttpErrorResponse) => console.log(error),
      );
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.employeeColumnService.selectPageSize(size);
    }
    this.pagination.index = index;
    this.pagination.size = size;

    this.employeeManagementService
    .getEmployeeSearch(this.defineEmployeeSearchParams(index, size))
    .subscribe(res => this.updateStoreData(res));
  }

  private defineEmployeeSearchParams(
    page: number = this.pagination.index,
    size: number = this.pagination.size
  ): EmployeeSearchFilterRequestParams {
    let params: EmployeeSearchFilterRequestParams = {
      ...this.employeeFilterService.getFilterParams(),
      page,
      size,
      terms: null,
      sort: null,
    };

    if (this._sort) {
      params.sort = this.sortToSortString(this._sort);
    }

    if (this._searchTerms) {
      params.terms = this._searchTerms;
    }

    return params;
  }

  public reset(): void {
    this.setDefaultValues();
    this.updateListing();
  }

  public selectCurrentEmployeeOrDefault(): void {
    if (this._currentEmployee.value && this._currentEmployee.value.employeeId !== '') {
      this.selectEmployee(this._currentEmployee.value.employeeId);
      this.router.navigate(['assets/employee/list', this._currentEmployee.value.employeeId]);
    } else {
      this.selectDefaultEmployee();
    }
  }

  public selectEmployee(id: string): void {
    this.employeeManagementService.getEmployee(id).subscribe(employee => {
      this._currentEmployee.next(employee);
    })
  }

  public selectDefaultEmployee(): void {
    this.employees.pipe(
      filter((res: SearchEmployee[]) => res && res.length > 0),
      take(1),
    ).subscribe((res: SearchEmployee[]) => {
      this.router.navigate(['assets/employee/list', res[0].employeeId]);
    });
  }

  public updateFilters(): void {
    this.employeeFilterService.updateFilters();
  }

  private updateFilterLabel(): void {
    this.employeeFilterService.updateFilterLabels();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.employeeFilterService.updateFilterParams(commands);
  }

  private labelsSubscription(): void {
    this.employeeFilterService.labels
      .pipe(untilDestroyed(this))
      .subscribe(labels => this._labels.next(labels));
  }

  private setDefaultValues(): void {
    this.initPagination();
    this.searchTerms = null;
    this.sort = null;
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private paginatorPageSizeListener(): void {
    this.employeeColumnService.pageSize.subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  private sortToSortString(sort: Sort): string {
    return sort && sort.active && sort.direction
      ? `${sort.active}.raw,${sort.direction}` : null;
  }

  private updateStoreData(res: PagedResponse<SearchEmployee>): void {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  public getDocuments(employeeId: string): Observable<DocumentLink[]> {
    const result = new SingleEmit<DocumentLink[]>();
    (employeeId ? this.employeeManagementService.getDocuments(employeeId) : of([]))
      .subscribe(res => {
        this._documents.next(res);
        result.next(res);
      });
    return result.asObservable();
  }

  public getDocumentsRenewal(employeeId: string): Observable<DocumentLink[]> {
    const result = new SingleEmit<DocumentLink[]>();
    (employeeId ? this.employeeManagementService.getDocumentsRenewal(employeeId) : of([]))
      .subscribe(res => {
        this._documentsRenewal.next(res);
        result.next(res);
      });
    return result.asObservable();
  }

  public deleteDocument(cmd: DeleteEmployeeDocumentCommand): Observable<string> {
    const result = new SingleEmit<string>();
    this.employeeManagementService.deleteDocument(cmd)
      .pipe(delay(environment.DELAY_LONG))
      .subscribe(res => {
        this.getDocuments(cmd.employeeId);
        result.next(res);
      });
    return result.asObservable();
  }

  public deleteDocumentRenewal(cmd: DeleteEmployeeDocumentCommand): Observable<string> {
    const result = new SingleEmit<string>();
    this.employeeManagementService.deleteDocumentRenewal(cmd)
      .pipe(delay(environment.DELAY_LONG))
      .subscribe(res => {
        this.getDocumentsRenewal(cmd.employeeId);
        result.next(res);
      });
    return result.asObservable();
  }

  public updateDocumentName(cmd: UpdateEmployeeDocumentNameCommand): void {
    this.employeeManagementService.updateDocumentName(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocuments(cmd.employeeId));
  }

  public updateDocumentNameRenewal(cmd: UpdateEmployeeDocumentNameCommand): void {
    this.employeeManagementService.updateDocumentNameRenewal(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocumentsRenewal(cmd.employeeId));
  }

  public updateDocumentDescription(cmd: UpdateEmployeeDocumentDescriptionCommand): void {
    this.employeeManagementService.updateDocumentDescription(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocuments(cmd.employeeId));
  }

  public updateDocumentDescriptionRenewal(cmd: UpdateEmployeeDocumentDescriptionCommand): void {
    this.employeeManagementService.updateDocumentDescriptionRenewal(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocumentsRenewal(cmd.employeeId));
  }

  public setThumbnail(cmd: SetEmployeeThumbnailCommand): Observable<string> {
    return this.employeeManagementService.setThumbnail(cmd);
  }

  public saveLabel(cmd: AddEmployeeLabelCommand): void {
    this.addToCurrentEmployeeLabels(cmd.label);
    this.employeeManagementService
    .saveLabel(cmd)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe({
      next: () => {
        this.updateFilterLabel();
        this.updateListing();
      },
      error: error => {
        console.log('error on adding label', error);
      }
    })
  }

  public removeLabel(cmd: RemoveEmployeeLabelCommand): void {
    const newLabels = [...this._employeeLabels.value];
    newLabels.splice(newLabels.indexOf(cmd.label), 1);
    this._employeeLabels.next(newLabels);

    this.employeeManagementService
    .removeLabel(cmd)
    .pipe(delay(environment.DELAY_SHORTEST))
    .subscribe({
      next: () => {
        this.updateFilterLabel();
        this.updateListing();
      },
      error: error => {
        console.log('error on removing label', error);
      }
    })
  }

  private addToCurrentEmployeeLabels(newLabel: string) {
    const newLabels = [...this._employeeLabels.value];
    newLabels.push(newLabel);
    this._employeeLabels.next(newLabels);
  }

  public assignEmployeeToUser(cmd: AssignEmployeeToUserCommand): Observable<string> {
    return this.employeeManagementService.assignEmployeeToUser(cmd);
  }

  public unassignEmployeeFromUser(cmd: UnassignEmployeeFromUserCommand): Observable<string> {
    return this.employeeManagementService.unassignEmployeeFromUser(cmd);
  }

  public getNotes(employeeId: string): Observable<ViewEmployeeNote[]> {
    this.employeeManagementService
      .getNotes(employeeId)
      .subscribe((res: ViewEmployeeNote[]) => this._notes.next(res));

    return this._notes;
  }

  public addNote(cmd: CreateEmployeeNoteCommand): Observable<string> {
    return this.employeeManagementService
      .addNote(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getNotes(cmd.employeeId);
        }, environment.DELAY_SHORT);
        return res;
      }));
  }

  public updateNote(cmd: UpdateEmployeeNoteCommand): Observable<string> {
    return this.employeeManagementService
      .updateNote(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getNotes(cmd.employeeId);
        }, environment.DELAY_SHORT);
        return res;
      }));
  }

  public deleteNote(cmd: DeleteEmployeeNoteCommand): Observable<string> {
    return this.employeeManagementService
      .deleteNote(cmd)
      .pipe(switchMap((res: string) => {
        setTimeout(() => {
          this.getNotes(cmd.employeeId);
        }, environment.DELAY_SHORT);
        return res;
      }));
  }

  public getEmployeeQualifications(employeeId: string): Observable<ViewEmployeeQualification> {
    if (employeeId) {
      this.qualificationService.getEmployeeQualifications(employeeId).subscribe((res: ViewEmployeeQualification) => {
        this._employeeQualifications.next(res);
      });
    }
    return this._employeeQualifications;
  }

  public getQualifications(): Observable<Qualification[]> {
      this.qualificationService.getQualifications().subscribe((res: Qualification[]) => {
        this._qualifications.next(res);
      });
      return this._qualifications;
  }

  public createQualification(cmd: QualificationCreateCommand): Observable<string> {
    return this.qualificationService
      .createQualification(cmd)
      .pipe(switchMap((qualificationId: string) => {
          setTimeout(() => {
            this.getQualifications();
          }, environment.DELAY_SHORT);
        return of(qualificationId);
      }));
  }

  public addQualificationToEmployee(cmd: EmployeeQualificationAssignCommand): Observable<string> {
    return this.qualificationService
      .addQualificationToEmployee(cmd)
      .pipe(switchMap((qualificationId: string) => {
        setTimeout(() => {
          this.getQualifications();
        }, environment.DELAY_SHORT);
        return of(qualificationId);
      }));
  }

  public updateQualification(cmd: QualificationUpdateCommand): Observable<string> {
    return this.qualificationService
      .updateQualification(cmd)
      .pipe(switchMap((qualificationId: string) => {
          setTimeout(() => {
            this.getQualifications();
          }, environment.DELAY_SHORT);
        return of(qualificationId);
      }));
  }

  public updateQualificationAssignment(cmd: UpdateEmployeeQualificationAssignmentCommand): Observable<string> {
    return this.qualificationService
      .updateQualificationAssignment(cmd)
      .pipe(switchMap((qualificationId: string) => {
          setTimeout(() => {
            this.getQualifications();
          }, environment.DELAY_SHORT);
        return of(qualificationId);
      }));
  }

  public removeQualificationAssignment(cmd: EmployeeQualificationAssignmentRemoveCommand): Observable<string> {
    return this.qualificationService
      .removeQualificationAssignment(cmd)
      .pipe(switchMap((qualificationId: string) => {
        setTimeout(() => {
          this.getQualifications();
        }, environment.DELAY_SHORT);
        return of(qualificationId);
      }));
  }

  public renewQualification(cmd: RenewEmployeeQualificationCommand): Observable<string> {
    return this.qualificationService
      .renewQualification(cmd)
      .pipe(switchMap((qualificationTypeId: string) => {
        setTimeout(() => {
          this.getQualifications();
        }, environment.DELAY_SHORT);
        return of(qualificationTypeId);
      }));
  }

  public getEquipmentAssignments(employeeId: string): Observable<ViewEmployeeEquipmentAssignment[]> {
    this.employeeManagementService
    .getEquipmentAssignments(employeeId)
    .subscribe((res: ViewEmployeeEquipmentAssignment[]) => this._equipmentAssignments.next(res));

    return this._equipmentAssignments;
  }

  public getTeam(teamId: string): Observable<ViewTeam> {
    if (teamId) {
      this.teamService.getTeamById(teamId).subscribe((res: ViewTeam) => this._currentTeam.next(res));
    } else {
      this._currentTeam.next(emptyTeam);
    }
    return this._currentTeam;
  }

  public getAllEmployees(): Observable<ViewEmployee[]> {
    this.employeeManagementService.getAllEmployees().subscribe((res: ViewEmployee[]) => this._allEmployees.next(res));
    return this._allEmployees;
  }

  public createTeam(cmd: CreateTeamCommand): Observable<string> {
    return this.teamService
      .createTeam(cmd)
      .pipe(switchMap((teamId: string) => {
        return of(teamId);
      }));
  }

  public setTeamName(teamId: string, cmd: SetTeamNameCommand): Observable<string> {
    return this.teamService
      .setTeamName(teamId, cmd)
      .pipe(switchMap((resultTeamId: string) => {
        this.getTeamNames();
        return of(resultTeamId);
      }));
  }

  public setTeamLeader(teamId: string, cmd: SetTeamLeaderCommand): Observable<string> {
    return this.teamService
    .setTeamLeader(teamId, cmd)
    .pipe(switchMap((resultTeamId: string) => {
      return of(resultTeamId);
    }));
  }

  public unsetTeamLeader(teamId: string, cmd: UnsetTeamLeaderCommand): Observable<string> {
    return this.teamService
    .unsetTeamLeader(teamId, cmd)
    .pipe(switchMap((resultTeamId: string) => {
      return of(resultTeamId);
    }));
  }

  public getTeamNames(): Observable<string[]> {
    this.teamService.getTeamNames().subscribe((res: string[]) => this._teamNames.next(res));
    return this._teamNames;
  }

  public addEmployeeToTeam(teamId: string, cmd: AddEmployeeToTeamCommand): Observable<string> {
    return this.teamService
    .addEmployeeToTeam(teamId, cmd)
    .pipe(switchMap((resultTeamId: string) => {
      return of(resultTeamId);
    }));
  }

  public removeEmployeeFromTeam(teamId: string, cmd: RemoveEmployeeFromTeamCommand): Observable<string> {
    return this.teamService
    .removeEmployeeFromTeam(teamId, cmd)
    .pipe(switchMap((resultTeamId: string) => {
      return of(resultTeamId);
    }));
  }

  public filterFreeEmployees(value: string): void {
    this._freeEmployeeFilter.next(value);
  }

  private isEmployeeFree(employee: ViewEmployee): boolean {
    return employee &&
        employee.employeeStatus === EmployeeStatus.ACTIVE &&
        (this._currentEmployee.value && employee.employeeId !== this._currentEmployee.value.employeeId) &&
        !employee.teamId;
  }

  public addDocumentToQualification(cmd: EmployeeQualificationDocumentUpdateCommand): Observable<string> {
    return this.qualificationService
      .addDocument(cmd)
      .pipe(switchMap((qualificationId: string) => {
        return of(qualificationId);
      }));
  }

  public employeeStaffNumberInUse(employeeStaffNumber: string): Observable<boolean> {
    return this.employeeManagementService.employeeStaffNumberInUser(employeeStaffNumber);
  }

}
