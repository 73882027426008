import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheck, faTimes, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { ChangeTypeOfUseRequest } from '../../contract/change-type-of-use-request';
import { RequestStatus } from '../enums/request-status.enum';
import { ProjectsService } from '../project.service';
import { TypeOfUseChangeRequestsWidgetApiService } from '../../../dashboard/widgets/type-of-use-change-requests-widget/type-of-use-change-requests-widget-api.service';
import { AcceptTypeOfUseRequest } from '../../contract/accept-type-of-use-request';


@Component({
  selector: 'bh-change-type-of-use-requests-dialog',
  templateUrl: './change-type-of-use-requests-dialog.component.html',
  styleUrls: ['./change-type-of-use-requests-dialog.component.scss']
})
export class ChangeTypeOfUseRequestsDialogComponent implements OnInit {

  public readonly faSpinner: IconDefinition = faSpinner;
  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;

  public requests: ChangeTypeOfUseRequest[] = [];
  public requestsAccept: AcceptTypeOfUseRequest[] = [];

  public requestCount = 0;


  constructor(private projectService: ProjectsService,
    private changeTypeOfUseService: TypeOfUseChangeRequestsWidgetApiService,
    private dialogRef: MatDialogRef<ChangeTypeOfUseRequest>) { }

  ngOnInit(): void {
    this.isDialogForAcceptance() ?
      this.createObservableArrayRequestAcceptance() :
      this.createObservableArray();
  }

  public isDialogForAcceptance(): boolean {
    return this.requests.length > 0 ? false :
      this.requestsAccept.length > 0;
  }

  private createObservableArray(): void {
    this.requestCount = this.requests.length;
    this.requests.forEach((request: ChangeTypeOfUseRequest) => {
      this.projectService.addAssetChargePeriod(request.command).subscribe(() => {
        request.status = RequestStatus.SUCCESS;
        this.requestCount--;
      }, error => {
        request.errorText = JSON.parse(error.error).message;
        request.status = RequestStatus.FAILED;
        this.requestCount--;
      });
    });
  }

  private createObservableArrayRequestAcceptance(): void {
    this.requestCount = this.requestsAccept.length;
    this.requestsAccept.forEach((request: AcceptTypeOfUseRequest) => {
      this.changeTypeOfUseService.acceptTypeOfUseChangeRequest(request.command).subscribe(() => {
        request.status = RequestStatus.SUCCESS;
        this.requestCount--;
      }, error => {
        request.errorText = JSON.parse(error.error).message;
        request.status = RequestStatus.FAILED;
        this.requestCount--;
      });
    });
  }

  public resolveStatusIcon(status: RequestStatus): IconDefinition {
    switch (status) {
      case RequestStatus.FAILED:
        return faTimes;
      case RequestStatus.PENDING:
        return faSpinner;
      case RequestStatus.SUCCESS:
        return faCheck;
    }
  }

  public resolveIconClass(status: RequestStatus): string {
    return status === RequestStatus.FAILED ? 'error' : 'primary';
  }

  public close(): void {
    this.dialogRef.close(true)
  }
}
