import { TransportStateHierarchy } from 'app/modules/transportation/shared/contract/transport-state-hierarchy.class';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { TransportFilterRequestParams } from './transport-filter-request-params.interface';

export class TransportFilterParams extends FilterParams {
  public getFilterParams(): TransportFilterRequestParams {
    return {
      organisation: this.getFilterTypeParams(FilterTypes.ORGANISATION),
      state: this.getFilterTypeParams(FilterTypes.TRANSPORT_STATE).filter(state => !TransportStateHierarchy.isParentState(state)),
      relatedProject: this.getFilterTypeParams(FilterTypes.TRANSPORT_RELATED_PROJECT),
      priority: this.getFilterTypeParams(FilterTypes.PRIORITY),
      transportType: this.getFilterTypeParams(FilterTypes.TRANSPORT_TYPE),
      assignedVehicle: this.getFilterTypeParams(FilterTypes.TRANSPORT_ASSIGNED_VEHICLE),
    }
  }
}
