import { LanguageService } from './../../../../../../../shared/services/language.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { PerformanceType } from '../../../../../contract/performance-type.enum';
import { EquipmentInvoice } from '../../../../../contract/equipment-invoice.interface';
import { Utils } from '../../../../../../../shared/utils';
import { LifeCycleDamage } from '../../../../../contract/life-cycle-damage';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentAttachmentDetailsComponent } from '../../../../../shared/equipment-attachment-details/equipment-attachment-details.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../../../../shared/enums/modules.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Directive()
export abstract class BaseEquipmentViewDamageComponent extends EquipmentAttachmentDetailsComponent implements OnInit, OnDestroy {

  public damage: LifeCycleDamage;
  public invoice: EquipmentInvoice;
  public equipmentId: string;
  public lifeCycleId: string;
  public performanceType = Object.keys(PerformanceType).sort();

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected utils: Utils,
              protected equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, dialog, routerHistory, languageService, fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }

  protected getLifeCycleDetails(): void {
    this.equipmentStore.getLifeCycleDetails(this.equipmentId, this.lifeCycleId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.damage = res as LifeCycleDamage;
          this.lcDocumentsDataSource.data = this.damage.documents;
          this.getInvoice();
        }
      },
      error => {
        console.log('error get lifecycle details: ', error);
      });
  }

  protected getInvoice(): void {
    if (this.damage.equipmentCostId && this.allowedToSeeInvoice) {
      this.equipmentStore.getInvoiceDetails(
        this.equipmentId,
        this.damage.equipmentCostId,
        this.damage.invoiceNumberRef)
      .pipe(untilDestroyed(this))
      .subscribe((res: EquipmentInvoice) => {
        if (res) {
          this.invoice = res;
          this.invoiceDocumentsDataSource.data = res.documents;
        }
      },
      error => {
        console.log('error get invoice details: ', error);
      });
    }
  }

  public getDuration(): string {
    if (this.invoice && this.invoice.performanceDuration) {
      return this.utils.durationISOToString(<string>this.invoice.performanceDuration);
    } else {
      return null;
    }
  }

  public getInvoiceNumber(): string {
    if (this.invoice) {
      return (this.invoice.finCompanyId ? this.invoice.finCompanyId + ' / ' : '') +
        (this.invoice.accountingId ? this.invoice.accountingId + ' / ' : '') +
        this.invoice.invoiceNumber;
    }
    return '';
  }

  get allowedToSeeInvoice(): boolean {
    return this.hasAuthority(Authorities.EQUIPMENT_VIEW_COSTS) && this.hasModule(Modules.COSTS);
  }

}
