import { TypeOfUseStatus } from '../shared/enums/type-of-use-status.enum';
import { TypeOfUseStatusReason } from '../shared/enums/type-of-use-status-reason.enum';

export class InsertProjectTypeOfUseTimelineEntryCommand {
  constructor (public amountId: string,
    public startDate: Date,
    public endDate: Date,
    public projectId: string,
    public status: TypeOfUseStatus,
    public reason: TypeOfUseStatusReason,
    public comment: string) {}
}
