import { Directive, Input, inject } from '@angular/core';
import { ControlErrorMessage } from '../../types/control-error-message.interface';
import { HostControlDirective } from './host-control.directive';
import { AbstractControl } from '@angular/forms';

@Directive()
export class BaseControl {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() readonly = false;
  @Input() errorMessages: ControlErrorMessage = {};

  private hcd = inject(HostControlDirective);

  public get control(): AbstractControl {
    return this.hcd?.control;
  }

  public onTouch = () => {
    this.hcd?.onTouch();
  };

}
