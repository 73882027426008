import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxCharactersLimit'
})
export class MaxCharactersLimitPipe implements PipeTransform {

  public transform(value: string, limitNumber: number): string {
    if (value && value.length > limitNumber) {
      return `${value.substring(0, limitNumber)}...`;
    }
    return value;
  }
}
