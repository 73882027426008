import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceResult } from '../enums/maintenance-result';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'maintenanceResult'
})
export class MaintenanceResultPipe implements PipeTransform {

  private definitionSet = {
    [MaintenanceResult.OK]: this.translate('modules.maintenance.maintenanceResult.ok'),
    [MaintenanceResult.OK_WITH_RESTRICTIONS]: this.translate('modules.maintenance.maintenanceResult.okWithRestrictions'),
    [MaintenanceResult.NOT_OK]: this.translate('modules.maintenance.maintenanceResult.notOk'),
    [MaintenanceResult.NO_RESULT_AVAILABLE]: this.translate('modules.maintenance.maintenanceResult.noResultAvailable'),
  }

  constructor(private languageService: LanguageService) {
  }

  public transform(value: MaintenanceResult | string): string {
    return this.definitionSet[value] || this.translate('general.unknown');
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
