import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import moment from 'moment';
import { RenewEmployeeQualificationCommand } from 'app/modules/disposition/contract/employee-qualification-renew-command';
import { EmployeeQualification } from 'app/modules/disposition/contract/employee-qualification';
import { EmployeeManagementDatasource } from 'app/modules/disposition/shared/employee-management.datasource';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { ViewEmployee } from 'app/modules/disposition/contract/view-employee.interface';
import { DatesService } from 'app/shared/services/dates.service';

@UntilDestroy()
@Component({
  selector: 'bh-qualification-renew',
  templateUrl: './qualification-renew.component.html',
  styleUrls: ['./qualification-renew.component.scss']
})

export class QualificationRenewComponent implements OnInit, OnDestroy {

  public employeeId: string;
  public qualification: EmployeeQualification = new EmployeeQualification();
  public renewForm: UntypedFormGroup;
  public documents: DocumentLink[];
  public images: DocumentLink[];

  constructor(public dialogRef: MatDialogRef<QualificationRenewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private formBuilder: UntypedFormBuilder,
    private employeeStore: EmployeeManagementDatasource,
    protected dialog: MatDialog) { }

  ngOnInit(): void {
    this.buildForm();
    this.subscribeToCurrentQualification();
    this.subscribeToCurrentEmployee();
  }

  ngOnDestroy(): void {
  }

  private subscribeToCurrentQualification(): void {
    this.employeeStore.currentQualification.subscribe((qualification: EmployeeQualification) => {
      this.qualification = qualification;
    });
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeStore.currentEmployee.subscribe((employee: ViewEmployee) => {
      this.employeeId = employee.employeeId;
    })
  }

  private buildForm(): void {
    this.renewForm = this.formBuilder.group({
      renewDate: new UntypedFormControl('', [this.renewDateValidator.bind(this), Validators.required])
    });
  }

  public renewDate(): void {
    if (this.isValid()) {
      let newDate = this.renewForm.controls['renewDate'].value;

      const cmd = new RenewEmployeeQualificationCommand(this.qualification.qualificationId, this.employeeId,
        DatesService.toIsoStartOfDay(newDate));

      this.employeeStore.renewQualification(cmd).subscribe();

      this.close();
    }
  }

  public isValid(): boolean {
    return this.renewForm.valid;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public renewDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const newDate = control.value;

    if (moment(newDate).isBefore(moment(this.qualification.lastRenewal), 'day')) {
      return { 'invalidDate': true };
    }

    return null;
  }
}
