import { Component, OnInit } from '@angular/core';
import { MarkerItemCurrentEquipmentStatus } from '../../../../interfaces/marker-item-current-equipment-status.interface';
import { MapInfoTelematics } from '../map-info-telematics';

@Component({
  selector: 'bh-map-info-telematics-equipment-status',
  templateUrl: '../map-info-telematics.html',
})
export class MapInfoTelematicsEquipmentStatusComponent extends MapInfoTelematics<MarkerItemCurrentEquipmentStatus> implements OnInit{

  public ngOnInit(): void {
    this.setInfoDates();
    super.ngOnInit();
  }

  private setInfoDates(): void {
    this.infoDates = [
      { title: this.translate('general.latest'), date: this.value?.item?.latestChangeDate },
    ];
  }

}
