import { EmployeeColumnDefinitionService } from './../../shared/services/employee-column-definition.service';
import { Injectable } from '@angular/core';
import { ColumnService } from '../../../../shared/column.service';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';
import { DisplayService } from '../../../../shared/display.service';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

@Injectable()
export class EmployeeColumnService extends ColumnService {
  constructor(protected userConfigurationService: UserConfigurationService,
              protected displayService: DisplayService,
              protected authService: KeycloakService,
              employeeColumnDefinitionService: EmployeeColumnDefinitionService) {
    super('employeeOverview',
      employeeColumnDefinitionService.availableColumns,
      ['employeeIcon',
        'employeeStaffNumber',
        'employeeName',
        'employeeFirstName',
        'organisationName',
        'employeeEmail',
        'employeeOfficePhoneNumber',
        'employeeOfficeMobileNumber',
        'openDetails'],
      userConfigurationService.userConfiguration.employeeListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.employeeListConfiguration.pageSize },
      displayService,
      authService);
  }

  public selectColumns(columns: ColumnDefinition[], pageSize: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveEmployeeListConfiguration(
      this.columnConfigsSubject.getValue(),
      this.pageSizeSubject.getValue(),
    );
  }

}
