import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { EquipmentFiltersBaseService } from 'app/shared/contract/filter/equipment/equipment-filters-base-service.class';
import { EquipmentFilterCollectionView } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-collection-view.interface';
import { EquipmentFilterType } from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-type.interface';
import { EquipmentStatusCategoryPipe } from 'app/shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';
import { FilterSingleOptionDisplayNamePipe } from 'app/shared/pipes/filter-single-option-display-name.pipe';
import _ from 'lodash';
import { forkJoin, map, Observable } from 'rxjs';
import { EquipmentFilterParams } from '../../contract/request-params/equipment-filter-params.class';
import { EquipmentsService } from '../equipments.service';
import { SearchFilterMediatorService } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator.service';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

@Injectable()
export class EquipmentFilterService extends EquipmentFiltersBaseService {
  constructor(
    equipmentsService: EquipmentsService,
    organisationsService: OrganisationsService,
    authService: KeycloakService,
    singleOptionDisplayNameResolver: FilterSingleOptionDisplayNamePipe,
    equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    equipmentStatusNamePipe: EquipmentStatusNamePipe,
    protected translatableStringPipe: TranslatableStringPipe,
    filterMediator: SearchFilterMediatorService,
  ) {
    super(
      new EquipmentFilterParams(),
      equipmentsService,
      organisationsService,
      authService,
      singleOptionDisplayNameResolver,
      equipmentStatusCategoryPipe,
      equipmentStatusNamePipe,
      translatableStringPipe,
      filterMediator,
    );
  }

  protected getFilters(): Observable<EquipmentFilterCollectionView> {
    return forkJoin({
      [EquipmentFilterType.ORGANISATION]: this.getEquipmentOrganisations(),
      [EquipmentFilterType.ASSIGNED_EMPLOYEE]: this.getResponsiblePeople(),
      [EquipmentFilterType.LABEL]: this.getCustomerLabels(),
      [EquipmentFilterType.CLASS]: this.getEquipmentTypes(),
      [EquipmentFilterType.STATUS]: this.getNewEquipmentStatuses(),
      [EquipmentFilterType.WITHOUT_SUBEQUIPMENTS]: this.getSubequipmentOption(),
      [EquipmentFilterType.TRANSPORT_OPTIONS]: this.getTransportVehicleOptions(),
      [EquipmentFilterType.FAVOURITE_ONLY]: this.getFavouriteEquipmentOptions(),
      [EquipmentFilterType.MATCH_ALL_LABELS]: this.getMatchAllLabelsOption(),
    }).pipe(
      map(filters => ({
        ...(_.omit(filters, [EquipmentFilterType.TRANSPORT_OPTIONS])),
        ...this.splitEquipmentTransportFilterOptions(filters[EquipmentFilterType.TRANSPORT_OPTIONS])
      }))
    );
  }

  public updateFilterResponsiblePeople(): void {
    if (this.hasParamValue(EquipmentFilterType.ASSIGNED_EMPLOYEE)) {
      this.updateFilters();
    } else {
      this.getResponsiblePeople().subscribe(filters => this.updateFilterByType(EquipmentFilterType.ASSIGNED_EMPLOYEE, filters));
    }
  }

  public updateFilterLabel(): void {
    if (this.hasParamValue(EquipmentFilterType.LABEL)) {
      this.updateFilters();
    } else {
      this.getCustomerLabels().subscribe(filters => this.updateFilterByType(EquipmentFilterType.LABEL, filters));
    }
  }

  public updateFilterStatus(): void {
    if (this.hasParamValue(EquipmentFilterType.STATUS)) {
      this.updateFilters();
    } else {
      this.getNewEquipmentStatuses()
        .subscribe(filters => this.updateFilterByType(EquipmentFilterType.STATUS, filters))
    }
  }

  public updateFilterEquipmentType(): void {
    if (this.hasParamValue(EquipmentFilterType.CLASS)) {
      this.updateFilters();
    } else {
      this.getEquipmentTypes().subscribe(filters => this.updateFilterByType(EquipmentFilterType.CLASS, filters));
    }
  }

  public updateFilterTransportOption(): void {
    if (this.hasParamValue(EquipmentFilterType.TRANSPORT_TYPES) || this.hasParamValue(EquipmentFilterType.TRANSPORT_VEHICLE_ONLY)) {
      this.updateFilters();
    } else {
      this.getTransportVehicleOptions()
        .subscribe(options => this.updateFilterByTypes(this.splitEquipmentTransportFilterOptions(options)));
    }
  }

  public updateFilterFavoriteOption(): void {
    if (this.hasParamValue(EquipmentFilterType.FAVOURITE_ONLY)) {
      this.updateFilters();
    } else {
      this.getFavouriteEquipmentOptions()
      .subscribe(filters => this.updateFilterByType(EquipmentFilterType.FAVOURITE_ONLY, filters));
    }
  }

  public updateFilterOrganisation(): void {
    if (this.hasParamValue(EquipmentFilterType.ORGANISATION)) {
      this.updateFilters();
    } else {
      this.getEquipmentOrganisations().subscribe(filters => this.updateFilterByType(EquipmentFilterType.ORGANISATION, filters))
    }
  }

  private hasParamValue(type: EquipmentFilterType): boolean {
    return (<EquipmentFilterParams>this.params).hasParamValue(type);
  }

}
