import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { EquipmentsDataSource } from '../../equipments.datasource';
import { Utils } from '../../../../../shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseEquipmentInvoiceDetailsComponent } from '../base/base-equipment-invoice-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { RouterHistory } from '../../../../../shared/router-history';
import {
  FileDownloadService
} from '../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-invoice-details',
  templateUrl: './mobile-equipment-invoice-details.component.html',
  styleUrls: ['./mobile-equipment-invoice-details.component.scss']
})
export class MobileEquipmentInvoiceDetailsComponent extends BaseEquipmentInvoiceDetailsComponent implements OnInit {

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              public equipmentStore: EquipmentsDataSource,
              protected utils: Utils,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(router, route, authService, equipmentStore, utils, dialog, routerHistory, languageService, fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe(params => {
      this.equipmentId = params['id'];
      this.invoiceId = params['invoiceId'];
      this.getInvoice();
    });
  }

  public navigateBack(): void {
    this.goBack(`/mobile/equipments/view/${this.route.snapshot.params.id}/timeline`);
  }
}
