import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AdditionalFieldsService,
  BaseAdditionalFieldRequest,
  CreateAdditionalFieldRequest,
  UpdateAdditionalFieldRequest,
} from '../additional-fields.service';
import { Languages } from '../../../../../shared/enums/languages.enum';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AdditionalFieldsDatasource } from '../additional-fields.datasource';
import {
  AdditionalField,
  AdditionalFieldEntity,
  AdditionalFieldSelectOption,
  AdditionalFieldType,
  AdditionalFieldCategory,
} from '../../../../osp-ui/rich-components/bh-additional-field/additional-field';
import { measurementConvert } from '../../../../../shared/pipes/dimension-unit.utils';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { AdditionalFieldsValidators } from '../additional-fields.validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatableString } from 'app/shared/contract/translatable-string.type';
import { BehaviorSubject } from 'rxjs';


export type AdditionalFieldDialogData = {
  additionalField?: AdditionalField,
  defaultLanguage: Languages,
  availableLanguages: Languages[]
};

type FormTypeAdditionalFieldName = FormGroup<{
  language: FormControl<Languages>,
  name: FormControl<string>,
}>;

export type FormTypeAdditionalFieldSelectOptions = FormGroup<{
  isNew: FormControl<boolean>,
  optionKey: FormControl<string>,
  optionName: FormControl<TranslatableString>
}>;

type FormTypeAdditionalField = {
  additionalFieldKey: FormControl<string>,
  additionalFieldName: FormArray<FormTypeAdditionalFieldName>,
  additionalFieldEntity: FormControl<AdditionalFieldEntity>,
  additionalFieldCategory: FormControl<AdditionalFieldCategory>,
  additionalFieldType: FormControl<AdditionalFieldType>,
  additionalFieldUnit: FormControl<string>,
  additionalFieldDefaultValue: FormControl<string>,
  additionalFieldSelectOptions: FormArray<FormTypeAdditionalFieldSelectOptions>,
}

@UntilDestroy()
@Component({
  selector: 'bh-add-edit-additional-field-dialog',
  templateUrl: './add-edit-additional-field-dialog.component.html',
  styleUrls: ['./add-edit-additional-field-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditAdditionalFieldDialogComponent implements OnInit {

  isEdit = false;
  additionalField: AdditionalField;
  defaultLanguage: Languages;
  availableLanguages: Languages[];

  public additionalFieldForm: FormGroup<FormTypeAdditionalField>;
  public unitFilter: FormControl<string> = new FormControl();

  readonly additionalFieldTypes = Object.keys(AdditionalFieldType);
  readonly additionalFieldCategories = Object.keys(AdditionalFieldCategory);
  readonly allAdditionalFieldUnits = measurementConvert().possibilities();
  public additionalFieldUnits = this.allAdditionalFieldUnits;
  protected selectedLanguages = new BehaviorSubject<Languages[]>([]);
  protected languageOptions = new BehaviorSubject<Languages[]>([]);

  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private additionalFieldsService: AdditionalFieldsService,
              private additionalFieldsDatasource: AdditionalFieldsDatasource,
              @Inject(MAT_DIALOG_DATA) data: AdditionalFieldDialogData,
              private dialogRef: MatDialogRef<AddEditAdditionalFieldDialogComponent>) {
    this.applyPayload(data);
    this.buildForm();
  }

  get fieldType(): AdditionalFieldType {
    return this.additionalFieldForm.controls.additionalFieldType.value;
  }

  get fieldKeyFormControl(): FormControl<string> {
    return this.additionalFieldForm.controls.additionalFieldKey;
  }

  get fieldNamesFormControl(): FormArray<FormTypeAdditionalFieldName> {
    return this.additionalFieldForm.controls.additionalFieldName;
  }

  get fieldOptionsFormControl(): FormArray<FormTypeAdditionalFieldSelectOptions> {
    return this.additionalFieldForm.controls.additionalFieldSelectOptions;
  }

  get fieldUnitFormControl(): FormControl<string> {
    return this.additionalFieldForm.controls.additionalFieldUnit;
  }

  get fieldDefaultValueFormControl(): FormControl<string> {
    return this.additionalFieldForm.controls.additionalFieldDefaultValue;
  }

  public ngOnInit(): void {
    this.filterUnitsOnInput();
    this.initListeners();
  }

  public addFieldName(language: Languages): void {
    this.fieldNamesFormControl.push(this.fb.group({
      language: [language, Validators.required],
      name: ['', [Validators.required]],
    }));
    this.cdr.detectChanges();
  }

  public deleteFieldName(index: number): void {
    this.fieldNamesFormControl.removeAt(index);
    this.cdr.detectChanges();
  }

  public save(): void {
    if (this.additionalFieldForm.valid) {
      if (this.isEdit) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  private create(): void {
    const request: CreateAdditionalFieldRequest = {
      ...this.getBaseAdditionalFieldRequest(),
      additionalFieldEntity: this.additionalFieldForm.value.additionalFieldEntity,
      additionalFieldCategory: this.additionalFieldForm.value.additionalFieldCategory
    };
    this.additionalFieldsDatasource.create(request)
      .pipe(untilDestroyed(this))
      .subscribe(additionalFieldId => {
        const newField: AdditionalField = { ...request, additionalFieldId };
        this.dialogRef.close(newField);
      });
  }

  private update(): void {
    const request: UpdateAdditionalFieldRequest = {
      ...this.getBaseAdditionalFieldRequest(),
      additionalFieldId: this.additionalField.additionalFieldId,
    }
    this.additionalFieldsDatasource.update(request)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const changedField: AdditionalField = {
          ...request,
          additionalFieldEntity: this.additionalFieldForm.value.additionalFieldEntity,
          additionalFieldCategory: this.additionalFieldForm.value.additionalFieldCategory
        };
        this.dialogRef.close(changedField);
      });
  }

  private initListeners(): void {
    this.initListenerSelectedLanguages();
    this.initListenerLanguageOptions();
  }

  private initListenerSelectedLanguages(): void {
    this.fieldNamesFormControl.valueChanges
      .pipe(
        startWith(this.fieldNamesFormControl.value),
        map(names => names.map(({ language }) => language)),
        untilDestroyed(this))
      .subscribe(languages => this.selectedLanguages.next(languages))
  }

  private initListenerLanguageOptions(): void {
    this.selectedLanguages
      .pipe(
        map(selected => this.availableLanguages.filter(lang => !selected.includes(lang))),
        untilDestroyed(this))
      .subscribe(languages => this.languageOptions.next(languages));
  }

  private buildForm(): void {
    this.additionalFieldForm = this.fb.group({
      additionalFieldKey: [this.additionalField.additionalFieldKey, [Validators.required], [this.uniqueKeyValidator()]],
      additionalFieldName: this.fb.array(this.buildFieldNameFormArray()),
      additionalFieldEntity: [this.additionalField.additionalFieldEntity, [Validators.required]],
      additionalFieldCategory: [{ value: this.additionalField.additionalFieldCategory, disabled: this.isEdit }, [Validators.required]],
      additionalFieldType: [{ value: this.additionalField.additionalFieldType, disabled: this.isEdit }, [Validators.required]],
      additionalFieldUnit: [this.additionalField.additionalFieldUnit],
      additionalFieldDefaultValue: [this.additionalField.additionalFieldDefaultValue],
      additionalFieldSelectOptions: this.fb.array(this.buildFieldOptionFormArray()),
    });
  }

  private applyPayload(data: AdditionalFieldDialogData) {
    this.isEdit = !!data.additionalField;
    this.additionalField = this.isEdit ? data.additionalField : this.getEmptyAdditionalField();
    this.defaultLanguage = data.defaultLanguage;
    this.availableLanguages = data.availableLanguages;
  }

  private getEmptyAdditionalField(): AdditionalField {
    return {
      additionalFieldId: null,
      additionalFieldKey: null,
      additionalFieldName: {},
      additionalFieldEntity: AdditionalFieldEntity.EQUIPMENT,
      additionalFieldCategory: null,
      additionalFieldType: null,
    };
  }

  private buildFieldNameFormArray(): FormTypeAdditionalFieldName[] {
    if (this.isEdit) {
      const formArray: FormTypeAdditionalFieldName[] = [];
      const {additionalFieldName} = this.additionalField;

      if (additionalFieldName.hasOwnProperty(this.defaultLanguage)) {
        /* Have value with default language, then add first */
        formArray.push(this.createLanguageFormGroup(this.defaultLanguage, additionalFieldName[this.defaultLanguage]));
      } else {
        /* Default value was changed, add new default language first */
        formArray.push(this.createLanguageFormGroup(this.defaultLanguage));
      }

      for (let language in additionalFieldName) {
        if (additionalFieldName.hasOwnProperty(language) && language !== this.defaultLanguage) {
          formArray.push(this.createLanguageFormGroup(language, additionalFieldName[language]));
        }
      }

      return formArray;
    } else {
      return [this.createLanguageFormGroup(this.defaultLanguage)];
    }
  }

  private createLanguageFormGroup(language, value = ''): FormGroup {
    return this.fb.group({
      language: [language, Validators.required],
      name: [value, [Validators.required]],
    });
  }

  private getBaseAdditionalFieldRequest(): BaseAdditionalFieldRequest {
    const value = this.additionalFieldForm.getRawValue();
    return {
      additionalFieldKey: value.additionalFieldKey,
      additionalFieldName: this.toAdditionalFieldName(value.additionalFieldName),
      additionalFieldType: value.additionalFieldType,
      additionalFieldUnit: value.additionalFieldUnit,
      additionalFieldDefaultValue: value.additionalFieldDefaultValue ? `${value.additionalFieldDefaultValue}` : null,
      additionalFieldSelectOptions: value.additionalFieldSelectOptions,
    }
  }

  private toAdditionalFieldName(additionalFieldName: any[]): any {
    return additionalFieldName.reduce((names, item) => ({...names, [item.language]: item.name}), {});
  }

  public isVisibleUnitField(): boolean {
    const fieldType = this.fieldType;
    return AdditionalFieldType.STRING === fieldType
      || AdditionalFieldType.NUMBER === fieldType;
  }

  public isVisibleValueField(): boolean {
    const fieldType = this.fieldType;
    return AdditionalFieldType.STRING === fieldType
      || AdditionalFieldType.NUMBER === fieldType;
  }

  public isNumericValueField(): boolean {
    return AdditionalFieldType.NUMBER === this.fieldType;
  }

  public isVisibleOptionsField(): boolean {
    return AdditionalFieldType.SELECT === this.fieldType;
  }

  private buildFieldOptionFormArray(): FormTypeAdditionalFieldSelectOptions[] {
    return (this.isEdit ? (this.additionalField.additionalFieldSelectOptions || []) : [])
      .map(selectOption => this.getFieldOptionFormControl(selectOption, false));
  }

  private getFieldOptionFormControl(option: AdditionalFieldSelectOption, isNew: boolean): FormTypeAdditionalFieldSelectOptions {
    return new FormGroup({
      isNew: new FormControl(isNew),
      optionKey: new FormControl(
        { value: option.optionKey, disabled: !isNew },
        [Validators.required, AdditionalFieldsValidators.uniqueOptionKeyField]
      ),
      optionName: new FormControl(
        option.optionName,
        [Validators.required]
      ),
    });
  }

  public addFieldOption(): void {
    this.fieldOptionsFormControl.push(this.getFieldOptionFormControl({ optionKey: '', optionName: {} }, true));
    this.cdr.detectChanges();
  }

  public deleteFieldOption(index: number): void {
    this.fieldOptionsFormControl.removeAt(index);
    this.cdr.detectChanges();
  }

  private filterUnitsOnInput(): void {
    this.unitFilter.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(term => {
        this.additionalFieldUnits = this.allAdditionalFieldUnits
          .filter(unit => unit.toLowerCase().includes(term.toLowerCase()))
        this.cdr.detectChanges();
      });
  }

  public onAdditionalFieldTypeChanged(event: MatSelectChange): void {
      if (AdditionalFieldType.STRING === event.value
        || AdditionalFieldType.NUMBER === event.value) {
        this.fieldOptionsFormControl.clear();
        this.fieldDefaultValueFormControl.patchValue(null);
      } else if (AdditionalFieldType.BOOLEAN === event.value) {
        this.fieldOptionsFormControl.clear();
        this.fieldUnitFormControl.patchValue(null);
        this.fieldDefaultValueFormControl.patchValue(null);
      } else if (AdditionalFieldType.SELECT === event.value) {
        this.addFieldOption();
        this.fieldUnitFormControl.patchValue(null);
        this.fieldDefaultValueFormControl.patchValue(null);
      }

      this.cdr.detectChanges();
    }

  private uniqueKeyValidator(): AsyncValidatorFn {
    return AdditionalFieldsValidators.uniqueAdditionalFieldKey(
      this.additionalFieldsService,
      this.additionalField.additionalFieldEntity,
      this.additionalField.additionalFieldId);
  }

  public copyValueIntoDefaultLanguage(): void {
    const defaultNameControlGroup = this.fieldNamesFormControl.at(0) as FormGroup;
    const defaultNameControl = defaultNameControlGroup.get('name') as AbstractControl;

    if (defaultNameControl && !defaultNameControl.value) {
      defaultNameControl.patchValue(this.fieldKeyFormControl.value);
    }
  }

  public copyOptionKeyIntoDefaultLanguage(controls: FormTypeAdditionalFieldSelectOptions): void {
    const optionKey = controls.controls.optionKey.value;
    const defaultOptionNameControlValue = controls.controls.optionName.value;

    if (!defaultOptionNameControlValue) {
      controls.controls.optionName.patchValue({
        [this.defaultLanguage]: optionKey
      });
    } else if (!defaultOptionNameControlValue[this.defaultLanguage]) {
      controls.controls.optionName.patchValue({
        ...defaultOptionNameControlValue,
        [this.defaultLanguage]: optionKey
      });
    }
  }
}
