import { Pipe, PipeTransform } from '@angular/core';
import { ContactView } from 'app/modules/contact/contract/contact/contact-view.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';

@Pipe({
  name: 'mapMarkerConvertContactView'
})
export class MapMarkerConvertContactViewPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: ContactView[]): MapLocationMarker<ContactView>[] {
    return Array.isArray(value) ? this.getMapLocationMarkers(value) : null;
  }

  private getMapLocationMarkers(items: ContactView[]): MapLocationMarker<ContactView>[] {
    return items.filter(Boolean).map(item => ({
      id: item.contactId,
      location: item.location,
      icon: this.getIconUrl(item),
      item
    }));
  }

  private getIconUrl(item: ContactView): string {
    return this.iconResolver.getIconContact(item);
  }

}
