import { Chart, Plugin } from 'chart.js';
import _ from 'lodash';
import { ChartCustomPluginId } from '../enums/chart-custom-plugin-id.enum';
import { ChartDataCategoryLabelsPluginOptions } from '../interfaces/chart-data-category-labels-plugin-options.interface';


export class PluginWrapperDataCategoryLabels {
  private labelShiftLeft: number;
  private labelShiftRight: number;
  private labelLeftPadding: number;
  private labelFortSize: number;
  private labelColor: string;
  private labelCircleColors: string[];
  private labelFont: string;
  private circleRadius = 8;

  public getPlugin(): Plugin {
    return {
      id: ChartCustomPluginId.CUSTOM_DATA_CATEGORY_LABELS,
      afterDatasetDraw: (chart) => {
        const options = this.getPluginOptions(chart)
        if (options) {
          this.setOptions(options);
          this.onRender(chart);
        }
      }
    }
  }

  private getPluginOptions(chart: Chart): ChartDataCategoryLabelsPluginOptions {
    return chart?.config?.options?.plugins?.[ChartCustomPluginId.CUSTOM_DATA_CATEGORY_LABELS];
  }

  private setOptions(options: ChartDataCategoryLabelsPluginOptions): void {
    this.labelShiftLeft = !_.isNil(options.labelShiftLeft) ? options.labelShiftLeft : 2;
    this.labelShiftRight = !_.isNil(options.labelShiftRight) ? options.labelShiftRight : 10;
    this.labelLeftPadding = !_.isNil(options.labelLeftPadding) ? options.labelLeftPadding : 5;
    this.labelFortSize = !_.isNil(options.labelFortSize) ? options.labelFortSize : 12;
    this.labelColor = !_.isNil(options.labelColor) ? options.labelColor : 'gray';
    this.labelCircleColors = !_.isNil(options.labelCircleColors) && options.labelCircleColors?.length > 0
      ? options.labelCircleColors
      : [];
    this.labelFont = `${this.labelFortSize}px "Helvetica Neue", Helvetica, Arial, sans-serif`;
  }

  private onRender(chart: Chart): void {
    const { data: { labels }, scales: { x } } = chart;
    const datasetWidth = this.getDatasetWidth(chart);
    const yPosition = this.getLabelPositionY(chart);

    labels.forEach((label: string, index) => {
      this.drawLabelCircle(chart, (x.left + this.labelShiftLeft), yPosition, index, datasetWidth);
      this.drawLabelText(chart, label, (x.left + this.labelShiftLeft), yPosition, index, datasetWidth);
    });
  }

  private getDatasetWidth({ scales: { x }}: Chart): number {
    return Number(x.width) / x.ticks.length;
  }

  private getLabelPositionY({ options, scales: { y } }: Chart): number {
    return y.getPixelForValue(0) + ((<any>options?.layout?.padding)?.bottom || 18);
  }

  private getNormalizedLabelText({ ctx }: Chart, text: string, necessaryWidth: number): string {
    const k = (necessaryWidth / (ctx.measureText(text).width));
    if (k < 1) {
      return `${text.substring(0, Math.floor(text.length * k))}...`;
    }
    return text;
  }

  private drawLabelCircle({ ctx }: Chart, initialX: number, initialY: number, step: number, stepWidth: number): void {
    ctx.beginPath();
    ctx.arc(
      initialX + (step * stepWidth) + this.circleRadius,
      initialY - (this.circleRadius / 2),
      this.circleRadius,
      0,
      2 * Math.PI
    );
    ctx.fillStyle = this.labelCircleColors[step];
    ctx.fill();
  }

  private drawLabelText(chart: Chart, text: string, initialX: number, initialY: number, step: number, stepWidth: number): void {
    chart.ctx.font = this.labelFont;
    chart.ctx.fillStyle = this.labelColor;
    const normalizedText = this.getNormalizedLabelText(
      chart,
      text,
      (stepWidth - (this.labelShiftLeft + (2 * this.circleRadius) + this.labelLeftPadding) - this.labelShiftRight)
    );

    chart.ctx.fillText(
      normalizedText,
      (stepWidth * step) + initialX + (2 * this.circleRadius) + this.labelLeftPadding,
      initialY
    );
  }

}
