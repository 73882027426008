import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EquipmentsService } from '../../../shared/equipments.service';
import { Observable } from 'rxjs';
import { DateValidator } from '../../../../../shared/custom-validators/date.validator';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { GuardedNavigableInputComponent } from '../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router} from '@angular/router';
import { KeycloakService } from '../../../../../core/keycloak';
import { ViewEquipment } from '../../../contract/view-equipment.interface';
import {delay, filter, switchMap, take, takeWhile, tap} from 'rxjs/operators';
import { isDefined } from '../../../../../shared/utils';
import { LifeCycle } from '../../../contract/life-cycle';
import { SearchEquipment } from '../../../contract/search-equipment.interface';
import { EquipmentCheckerService } from '../../../shared/services/equipment-checker.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterHistory } from '../../../../../shared/router-history';
import { FieldLimit } from '../../../../../shared/enums/fieldLimit.enum';
import { numberBiggerThanValidator } from '../../../../../shared/custom-validators/number-bigger-than.validator';

@Directive()
export abstract class BaseEquipmentLogLifecycle extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public dialogRef: MatDialogRef<any>;

  public currentValue: number;
  public dateOfLastEntry: Date;
  public equipment: ViewEquipment;
  public form: UntypedFormGroup;
  public loadNextEquipment = false;
  public today: Date = new Date();

  public readonly fieldLimit = FieldLimit;

  private componentActive = true;
  // queue of equipments to iterate if loadNextEquipment is enabled
  private queuedEquipmentIds: string[] = [];

  // these have to be implemented by child components
  // description of form input
  public abstract newValueDescription;

  public abstract newValuePattern;

  // callback to check if the equipment has live data (no manual logging allowed then)
  protected abstract hasLiveData(equipment: SearchEquipment): boolean;

  // callback to get the lifecycle from the backend
  protected abstract getLatestLifeCycle(equipmentId: string): Observable<LifeCycle>;

  // callback to get the value of the lifecycle
  protected abstract getLifeCycleValue(lifeCycle: LifeCycle): number;

  // callback to save (send the command)
  protected abstract sendCommand(): Observable<string>;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected formBuilder: UntypedFormBuilder,
              protected equipmentsService: EquipmentsService,
              protected equipmentStore: EquipmentsDataSource,
              protected equipmentStatusService: EquipmentCheckerService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.subscribeToCurrentEquipment();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  public navigateBack(): void {
    const equipmentId: string = this.route.snapshot.params['id'];
    if (equipmentId) {
      this.goBack(`/mobile/equipments/view/${equipmentId}/general`);
    } else {
      this.goBack('');
    }
  }

  private subscribeToCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
      .pipe(
        takeWhile(() => this.componentActive),
        filter(isDefined),
        tap((currentEquipment: ViewEquipment) => this.equipment = currentEquipment),
        switchMap((currentEquipment: ViewEquipment) => this.getLatestLifeCycle(currentEquipment.equipmentId))
      )
      .subscribe((lifeCycle: LifeCycle) => {
        this.dateOfLastEntry = lifeCycle ? lifeCycle.logDate : null;
        this.currentValue = lifeCycle ? this.getLifeCycleValue(lifeCycle) : 0;
        this.updateForm();
        this.queueEquipments();
      });
  }

  private queueEquipments(): void {
    this.equipmentStore.equipments
      .pipe(
        filter(isDefined),
        take(1)
      )
      .subscribe((equipmentList: SearchEquipment[]) => {
        // get the IDs of all active equipments below the currently selected
        const validEquipments = equipmentList.filter((equipment: SearchEquipment) => {
          return this.equipmentStatusService.isActiveEquipment(equipment)
            && !this.equipmentStatusService.isRentedEquipment(equipment)
            && !this.hasLiveData(equipment)
        });
        const equipmentIds = validEquipments.map((equipment: SearchEquipment) => equipment.equipmentId);
        this.queuedEquipmentIds = equipmentIds.splice(equipmentIds.indexOf(this.equipment.equipmentId) + 1);
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      logDate: null,
      newValue: null,
      comment: null
    });
  }

  private updateForm(): void {
    this.updateValidators();
    this.form.setValue({
      logDate: this.today,
      newValue: this.currentValue + 1,
      comment: ''
    });

    this.form.markAllAsTouched();
  }

  private updateValidators(): void {
    let dateValidators = [Validators.required, DateValidator.isValidDate(this.languageService)];
    if (this.dateOfLastEntry !== null) {
      dateValidators.push(DateValidator.inputDateNotBefore(this.dateOfLastEntry))
    }
    this.logDate.setValidators(dateValidators);

    this.newValue.setValidators([
      Validators.required,
      Validators.max(this.fieldLimit.SEVEN_DIGITS_DECIMAL),
      numberBiggerThanValidator(this.currentValue)
    ]);
  }

  public get logDate(): AbstractControl {
    return this.form.get('logDate');
  }

  public get newValue(): AbstractControl {
    return this.form.get('newValue');
  }

  public get comment(): AbstractControl {
    return this.form.get('comment');
  }

  public save(): void {
    if (this.isValid) {
      this.sendCommand().subscribe(() => {
        this.equipmentStore.emitEquipmentManualOperatingHoursSubject();
        this.equipmentStore.emitEquipmentManualMileageSubject();
          if (this.loadNextEquipment && !this.isLastEquipment()) {
            this.nextEquipment();
          } else {
            this.saveAndClose();
          }
        });
    }
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public isLastEquipment(): boolean {
    return this.queuedEquipmentIds.length <= 0;
  }

  private nextEquipment(): void {
    this.router.navigate(['/assets/equipment/list', this.queuedEquipmentIds.shift()]);
  }

  protected saveAndClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close('saved');
    } else {
      this.goBack()
        .pipe(delay(environment.DELAY_SHORT))
        .subscribe(() => {},
          err => {},
          () => {
            this.equipmentStore.updateCurrentEquipment();
          }
        );
    }
  }
}
