import { Component, OnInit } from '@angular/core';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faUser, faUsers, faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import { KeycloakService } from 'app/core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from 'app/shared/router-history';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'app/shared/services/language.service';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { ViewStockEmployeeAssignment } from 'app/modules/disposition/contract/view-stock-employee-assignment.interface';
import { StockStore } from 'app/modules/disposition/stocks/shared/stock.store';
import { StockAssigneeType } from 'app/shared/enums/stock-assignee-type.enum';
import { StockEmployeeAssignmentManageDialogComponent } from 'app/modules/disposition/shared/stock-employee-assignment-manage-dialog/stock-employee-assignment-manage-dialog.component';
import { StockEmployeeAssignmentViewDialogComponent } from 'app/modules/disposition/shared/stock-employee-assignment-view-dialog/stock-employee-assignment-view-dialog.component';
import { RemoveStockResponsibleEmployeeCommand } from 'app/modules/disposition/contract/remove-stock-responsible-employee-command';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'bh-stock-employee-assignee-cards',
  templateUrl: './stock-employee-assignee-cards.component.html',
  styleUrls: ['./stock-employee-assignee-cards.component.scss']
})
export class StockEmployeeAssigneeCardsComponent extends GuardedNavigableInputComponent implements OnInit {
  public readonly faUsers: IconDefinition = faUsers;
  public readonly faBadgeCheck: IconDefinition = faBadgeCheck;

  public employeeAssignments: ViewStockEmployeeAssignment[] = [];
  public employeesAvailable = false;
  public hasStockTransferRequestWorkflow: boolean;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public stockStore: StockStore,
              private dialog: MatDialog,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToEmployeeAssignments();
    this.subscribeToStockTransferRequestWorkflow();
  }

  subscribeToEmployeeAssignments(): void {
    this.stockStore.employeeAssignments.subscribe(employeeAssignments => {
      if (employeeAssignments) {
        this.employeeAssignments = employeeAssignments;
        this.employeesAvailable = true;
      }
    });
  }

  openViewAssignmentDialog(employeeAssignment: ViewStockEmployeeAssignment): void {
    let dialogRef = this.dialog.open(StockEmployeeAssignmentViewDialogComponent);
    dialogRef.componentInstance.employeeAssignment = employeeAssignment;
    dialogRef.componentInstance.icon = this.getRoleIcon(employeeAssignment.assigneeType);
  }

  openDeleteAssignmentDialog(employeeAssignment: ViewStockEmployeeAssignment): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.equipmentDetailGeneral.deleteAssignmentConfirm');
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        const command: RemoveStockResponsibleEmployeeCommand = new RemoveStockResponsibleEmployeeCommand(
            employeeAssignment.stockId,
            employeeAssignment.employeeAssignmentId,
            employeeAssignment.employeeId,
            employeeAssignment.assigneeType,
            );
        this.stockStore.removeEmployeeAssignment(command);
      }
    });
  }

  openAddAssignmentDialog(): void {
    if (this.employeesAvailable) {
      let dialogRef = this.dialog.open(StockEmployeeAssignmentManageDialogComponent);
      dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
      dialogRef.componentInstance.editMode = false;
    }
  }

  public openUpdateAssignmentDialog(employeeAssignment: ViewStockEmployeeAssignment): void {
    if (this.employeesAvailable) {
      let dialogRef = this.dialog.open(StockEmployeeAssignmentManageDialogComponent);
      dialogRef.componentInstance.existingEmployeeAssignments = this.employeeAssignments;
      dialogRef.componentInstance.editMode = true;
      dialogRef.componentInstance.assignment = employeeAssignment;
    }
  }

  getRoleIcon(role: StockAssigneeType): IconDefinition {
    switch (role) {
      case StockAssigneeType.STOCK_MANAGER:
        return faUserTie;
      case StockAssigneeType.OTHER:
        return faUser;
      default:
        return null;
    }
  }

  private subscribeToStockTransferRequestWorkflow(): void {
    this.stockStore.hasStockTransferRequestWorkflow.subscribe((hasStockTransferRequestWorkflow: boolean) => {
      this.hasStockTransferRequestWorkflow = hasStockTransferRequestWorkflow;
    });
    this.stockStore.updateStockTransferRequestsWorkflow();
  }

  getPhoneNumber(employeeAssignment: ViewStockEmployeeAssignment): string {
    const phone = employeeAssignment.employeeOfficePhoneNumber || employeeAssignment.employeeOfficeMobileNumber;
    return phone  || '-';
  }

  get allowAddEdit(): boolean {
     return this.hasAnyAuthority([Authorities.STOCK_MANAGE_ASSIGNEE, Authorities.STOCK_MANAGE]);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
