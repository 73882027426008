<div class="employee-details-wrapper" fxLayout="column">

  <div class="employee-details-header" fxLayout="row" fxLayoutAlign="space-between">
    <bh-title fxFlex="100" class="title-text" text="{{'modules.disposition.qualifications.qualificationTitle' |translate}}">
    </bh-title>
    <div class="plus-div" [matTooltip]="'modules.disposition.qualifications.newQualification' |translate" (click)="addEditQualificationOpen()" *ngIf="allowManageQualifications()"
      fxLayoutAlign="center">
      <fa-icon fxFlex="10" class="plus-icon" [icon]="faPlus"></fa-icon>
    </div>
  </div>

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          {{"modules.disposition.qualifications.qualification" |translate |uppercase}}
        </ng-template>
        <div *ngIf="showQualifications()" class="list-view-main__tab-content list-div">
          <div fxLayoutAlign="row" class="qualification-div"
            *ngFor="let qualification of employeeQualifications |qualification">
            <div fxFlex="50" fxLayout="row" class="first-div">
              <div class="sign-div">
                <fa-icon class="circle-check-icon" [icon]="faCheckCircle"></fa-icon>
              </div>
              <div class="name-div">
                <label>{{qualification.qualificationName}}</label>
              </div>
            </div>
            <div fxFlex="50" fxLayout="row" class="second-div">
              <div class="retake-sign-div">
                <fa-icon *ngIf="hasRetake(qualification)" [ngClass]="checkDelay(qualification)" fxFlex="10"
                  [icon]="checkOverdue(qualification.retakeDate)">
                </fa-icon>
              </div>
              <div *ngIf="isValidRetake(qualification)" class="comment-div">
                <label>{{getCommentText(qualification) | titlecase}}</label>
              </div>
            </div>
            <div fxFlex="10" fxLayout="column" [matMenuTriggerFor]="qualificationExtra"
             fxLayoutAlign="center center" class="third-div">
              <fa-icon class="menu-icon fa-flip-horizontal"
                [icon]="faEllipsisV"></fa-icon>
              <mat-menu #qualificationExtra="matMenu">

                <button mat-menu-item *ngIf="allowManageQualifications()"
                  (click)="addEditQualificationOpen(qualification)">
                  <mat-icon>edit</mat-icon>
                  <span>{{'modules.disposition.qualifications.edit' |translate}}</span>
                </button>

                <button fxLayout="row" fxLayoutAlign="start center" mat-menu-item *ngIf="hasRetake(qualification) && showRenewButton()"
                  (click)="renew(qualification.qualificationId)">
                  <mat-icon><fa-icon class="renew-icon" [icon]="faCheckDouble"></fa-icon></mat-icon>
                  <span class="renew-span">{{'modules.disposition.qualifications.renewQualification' |translate}}</span>
                </button>

                <button (click)="qualificationDelete(qualification.qualificationId)"
                        mat-menu-item *ngIf="allowManageQualifications()">
                  <mat-icon>delete_forever</mat-icon>
                  <span>{{'general.buttons.remove' |translate}}</span>
                </button>

              </mat-menu>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
