<ng-container *ngIf="type && !(value | isNil); else emptyValueTempl">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="fieldType.EMPTY"
      [ngTemplateOutlet]="emptyValueTempl">
    </ng-container>

    <ng-container *ngSwitchCase="fieldType.DATE"
      [ngTemplateOutlet]="typeDate"
      [ngTemplateOutletContext]="{ value }">
    </ng-container>

    <ng-container *ngSwitchCase="fieldType.MAINTENANCE_RESULT"
      [ngTemplateOutlet]="typeResult"
      [ngTemplateOutletContext]="{ value }">
    </ng-container>

    <ng-container *ngSwitchCase="fieldType.MAINTENANCE_FIELD">
      <ng-container *ngIf="value.changedValueType && !(value.changedValue | isNil); else emptyValueTempl">
        <ng-container [ngSwitch]="value.changedValueType">
          <ng-container *ngSwitchCase="fieldType.DATE"
            [ngTemplateOutlet]="typeDate"
            [ngTemplateOutletContext]="{ value: value.changedValue }">
          </ng-container>
          <ng-container *ngSwitchDefault
            [ngTemplateOutlet]="default"
            [ngTemplateOutletContext]="{ value: value.changedValue }">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault
      [ngTemplateOutlet]="default"
      [ngTemplateOutletContext]="{ value }">
    </ng-container>

  </ng-container>
</ng-container>


<ng-template #default let-value="value">
  {{value}}
</ng-template>

<ng-template #typeDate let-value="value">
  {{value | formatTimezoneDateTime }}
</ng-template>

<ng-template #typeResult let-value="value">
  <bh-maintenance-result-badge [result]="value"></bh-maintenance-result-badge>
</ng-template>

<ng-template #emptyValueTempl>{{emptyValue}}</ng-template>
