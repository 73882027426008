import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'bh-day-timeline',
  templateUrl: './day-timeline.component.html',
  styleUrls: ['./day-timeline.component.scss'],
})
export class DayTimelineComponent implements OnInit, OnDestroy {

  public headerInformation: string;
  public hasNext = true;
  public hasPrevious = true;
  private readonly viewTabName = 'timeline';
  private timelineScheduler: any;
  private displayDays = new BehaviorSubject(7);
  private displayDate = new BehaviorSubject(new Date());
  private limitStartDate = new BehaviorSubject(new Date());
  private limitEndDate = new BehaviorSubject(new Date());
  private events = new BehaviorSubject([]);
  @ViewChild('timeline', { static: true }) private timelineContainer: ElementRef;

  constructor() {
  }

  @Input('timeline-display-days') set inputDisplayDays(value: number) {
    this.displayDays.next(value);
  }

  @Input('timeline-display-date') set inputDisplayDate(value: Date) {
    this.displayDate.next(value ? new Date(value.toDateString()) : null);
  }

  @Input('timeline-limit-start') set inputLimitStartDate(value: Date) {
    this.limitStartDate.next(value ? new Date(value.toDateString()) : null);
  }

  @Input('timeline-limit-end') set inputLimitEndDate(value: Date) {
    this.limitEndDate.next(value ? new Date(value.toDateString()) : null);
  }

  @Input('timeline-events') set inputEvents(events: any[]) {
    this.events.next(events);
  }

  ngOnInit() {
    this.timelineScheduler = Scheduler.getSchedulerInstance();
    this.createView();
    this.setTemplates();
    this.attachEvents();
    this.initTimeline();
    this.subscribeToInputs();
  }

  ngOnDestroy(): void {
  }

  renderEvents(): void {
    this.timelineScheduler.clearAll();
    this.timelineScheduler.parse(this.events.getValue(), 'json');
  }

  private createView() {
    this.timelineScheduler.createTimelineView({
      name: this.viewTabName,
      render: 'days',
      days: this.displayDays.getValue(),
      x_unit: 'minute',
      x_date: '%H:%i',
      x_step: 120,
      x_size: 12,
      x_start: 0,
      x_length: 12,
      dx: 125,
      dy: 25,
      fit_events: false,
    });
  }

  private setTemplates() {
    this.timelineScheduler.templates.day_date = (date) => this.formatDate(date, true);
    this.timelineScheduler.templates.tooltip_text = (start, end) => this.buildTooltipText(start, end);
  }

  private attachEvents() {
    this.timelineScheduler.attachEvent('onDataRender', () => this.limitPaging());
    this.timelineScheduler.attachEvent('onDataRender', () => this.updateHeaderInformation());
  }

  private initTimeline(): void {
    this.timelineScheduler.config.readonly = true;

    this.timelineScheduler.init(
      this.timelineContainer.nativeElement,
      this.displayDate.getValue(),
      this.viewTabName,
    );
  }

  private subscribeToInputs(): void {
    merge(
      this.displayDays.pipe(
        untilDestroyed(this),
      ),
      this.displayDate.pipe(
        untilDestroyed(this),
      ),
      this.limitStartDate.pipe(
        untilDestroyed(this),
        distinctUntilChanged((o: Date, n: Date) => {
          return o === n || (o !== null && n !== null && o.getTime() === n.getTime());
        }),
      ),
      this.limitEndDate.pipe(
        untilDestroyed(this),
        distinctUntilChanged((o: Date, n: Date) => {
          return o === n || (o !== null && n !== null && o.getTime() === n.getTime());
        }),
      ),
    ).pipe(
      debounceTime(100),
    )
    .subscribe(() => this.updateConfig());


    this.events.pipe(untilDestroyed(this))
    .subscribe(() => this.renderEvents());
  }

  private updateHeaderInformation() {
    let info = null;
    if (this.timelineScheduler) {
      const start = this.timelineScheduler.getState().min_date;
      const end = new Date(this.timelineScheduler.getState().max_date);
      end.setDate(end.getDate() - 1);

      info = `${this.formatDate(start, false)} - ${this.formatDate(end, false)}`;

      if (this.timelineScheduler.matrix['timeline'].days === 7) {
        info = `${info} (KW ${this.getWeekFromDate(start)})`;
      }
    }

    this.headerInformation = info;
  }

  private updateConfig(): void {
    this.timelineScheduler.matrix['timeline'].days = this.displayDays.getValue();
    this.timelineScheduler.updateView(this.displayDate.getValue());

  }

  private limitPaging() {
    const limitStart = this.limitStartDate.getValue();
    const limitEnd = this.limitEndDate.getValue();

    if (this.timelineScheduler && limitStart && limitEnd && limitEnd >= limitStart) {
      const displayStart = this.timelineScheduler.getState().min_date;
      const displayEnd = this.timelineScheduler.getState().max_date;

      this.hasPrevious = limitStart.getTime() <= displayStart.getTime();
      this.hasNext = limitEnd.getTime() >= displayEnd.getTime();

      if (limitStart.getTime() > displayEnd.getTime()) {
        this.displayDate.next(new Date(limitStart));
      } else if (limitEnd.getTime() < displayStart.getTime()) {
        this.displayDate.next(new Date(limitEnd));
      }

    }
  }

  private buildTooltipText(start: Date, end: Date) {
    let startText = this.formatTime(start);
    let endText = this.formatTime(end);
    let tooltip = `${startText} - ${endText}`;

    if (start.getDate() !== end.getDate() ||
      start.getMonth() !== end.getMonth() ||
      start.getFullYear() !== end.getFullYear()
    ) {
      tooltip =
        `<table><tr><td>Von:</td><td>${this.formatDate(start)}</td><td>${startText}</td></tr>` +
        `<tr><td>Bis:</td><td>${this.formatDate(end)}</td><td>${endText}</td></tr></table>`;
    }

    return tooltip;
  }

  private getWeekFromDate(date: Date): number {
    return moment(date).isoWeek();
  }

  private formatDate(date: Date, weekday?: boolean): string {
    return date ? date.toLocaleDateString('de-DE', {
      weekday: weekday ? 'short' : undefined,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) : null;
  }

  private formatTime(date: Date): string {
    return date ? date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    }) : null;
  }

}
