import { FlexLayoutModule } from '@angular/flex-layout';
import { LanguageService } from 'app/shared/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { ExpandingTreeItemsPipe } from './pipes/expanding-tree-items.pipe';
import { ExpandingTreeRootItemsPipe } from './pipes/expanding-tree-root-items.pipe';
import { ExpandingTreeFlatItemsPipe } from './pipes/expanding-tree-flat-items.pipe';
import { ExpandingTreeComponent } from './component/expanding-tree.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { VirtualExpandingTreeComponent } from './component/virtual-expanding-tree.component';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule,
    OverlayModule,
    ScrollingModule,
    MatTooltipModule
  ],
  declarations: [
    ExpandingTreeComponent,
    VirtualExpandingTreeComponent,
    ExpandingTreeRootItemsPipe,
    ExpandingTreeItemsPipe,
    ExpandingTreeFlatItemsPipe,
  ],
  exports: [
    ExpandingTreeComponent,
    VirtualExpandingTreeComponent,
  ],
  providers: [
    ExpandingTreeRootItemsPipe,
    LanguageService
  ]
})
export class ExpandingTreeModule { }
