import { LanguageService } from './../../services/language.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UpdateEquipmentAssignmentCommand } from '../../../modules/equipment/contract/update-equipment-assignment-command';

import { EquipmentsDataSource } from '../../../modules/equipment/shared/equipments.datasource';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectStatus } from '../../../modules/disposition/shared/enums/project-status.enum';
import { FieldLimit } from '../../enums/fieldLimit.enum';
import moment from 'moment';
import { ViewEquipmentProjectAssignment } from 'app/modules/equipment/contract/view-equipment-project-assignment.interface';


@Component({
  selector: 'bh-assignment-comment',
  templateUrl: './assignment-comment.component.html',
  styleUrls: ['./assignment-comment.component.scss']
})
export class AssignmentCommentComponent implements OnInit {

  @ViewChild('matPanel', { static: true }) private matPanel: MatExpansionPanel;
  @Input() public readOnly: boolean;
  @Input() public assignment: ViewEquipmentProjectAssignment;
  public isCollapsed = true;
  public assignmentEditCommentFormGroup: UntypedFormGroup;
  public isWebkit = false;
  public readonly fieldLimit = FieldLimit;

  constructor(protected equipmentStore: EquipmentsDataSource,
              protected formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.assignmentEditCommentFormGroup = this.formBuilder.group({
      comment: this.assignment.comment
    });
    this.isWebkit = 'webkitLineClamp' in document.documentElement.style;
    this.overwriteReadOnly();
  }

  public getEncodedComment(): string {
    return decodeURIComponent(this.assignment.comment);
  }

  public saveDisabled(): boolean {
    return !this.assignmentEditCommentFormGroup.valid || !this.assignmentEditCommentFormGroup.dirty ||
        this.assignmentEditCommentFormGroup.value.comment === this.assignment.comment || this.isReadOnly();
  }

  public isReadOnly(): boolean {
    return ('readOnly' in this.assignment && this.assignment.readOnly) || ('projectStatus' in this.assignment &&
        (this.assignment.projectStatus === ProjectStatus.DELETED || this.assignment.projectStatus === ProjectStatus.FINISHED));
  }

  public cancelEdit(): void {
    this.assignmentEditCommentFormGroup.patchValue({comment: this.getEncodedComment()});
    this.matPanel.close();
  }

  public save(): void {
    if (this.assignmentEditCommentFormGroup.value.comment === this.assignment.comment) {
      return;
    }
    const cmd: UpdateEquipmentAssignmentCommand = new UpdateEquipmentAssignmentCommand();
    cmd.equipmentId = this.assignment.equipmentId;
    cmd.assignmentId = this.assignment.assignmentId;
    cmd.projectId = this.assignment.projectId;
    cmd.newStartDate = new Date(this.assignment.startDate).toISOString();
    cmd.newEndDate = this.assignment.endDate ? new Date(this.assignment.endDate).toISOString() : null;
    cmd.comment = this.assignmentEditCommentFormGroup.value.comment;

    this.equipmentStore.updateAssignment(cmd).subscribe(() => {
      this.assignment.comment = cmd.comment;
      this.snackBar.open(this.translate('shared.assignment.commentUpdated'), null, {duration: 3000});
    });
  }

  public overwriteReadOnly(): void {
    this.readOnly = this.assignment.endDate
      ? moment().isAfter(moment(this.assignment.endDate))
      : this.readOnly;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
