import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { BaseProjectViewDetailComponent } from '../base/base-project-view-detail.component';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ViewProjectEmployeeAssignment } from 'app/modules/disposition/contract/view-project-employee-assignment.interface';
import { ProjectAssigneeType } from 'app/shared/enums/project-assignee-type.enum';
import { faUser, faUserHardHat, faUserTie, IconDefinition } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'bh-mobile-project-view-detail',
  templateUrl: './mobile-project-view-detail.component.html',
  styleUrls: ['./mobile-project-view-detail.component.scss']
})
export class MobileProjectViewDetailComponent extends BaseProjectViewDetailComponent implements OnInit {

  public employeeAssignments: ViewProjectEmployeeAssignment[] = [];
  public employeesAvailable = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource) {
    super(authService, router, route, routerHistory, projectStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToEmployeeAssignments();
  }

  private subscribeToEmployeeAssignments(): void {
    this.projectStore.employeeAssignments.subscribe(employeeAssignments => {
      if (employeeAssignments) {
        this.employeeAssignments = employeeAssignments;
        this.employeesAvailable = true;
      }
    });
  }

  public getRoleIcon(role: ProjectAssigneeType): IconDefinition {
    switch (role) {
      case ProjectAssigneeType.CONSTRUCTION_MANAGER:
        return faUserTie;
      case ProjectAssigneeType.FOREMAN:
        return faUserHardHat;
      case ProjectAssigneeType.OTHER:
        return faUser;
      default:
        return null;
    }
  }

  public getPhoneNumber(employeeAssignment: ViewProjectEmployeeAssignment): string {
    const phone = employeeAssignment.employeeOfficePhoneNumber || employeeAssignment.employeeOfficeMobileNumber;
    return phone  || '-';
  }
}
