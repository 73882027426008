import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ViewStock } from 'app/modules/disposition/contract/view-stock';
import { StockService } from '../../shared/services/stock.service';
import { SearchStock } from '../../stocks/shared/search-stock';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { StockType } from 'app/shared/enums/stock-type';
import { PaginatorParams } from 'app/shared/contract/paginator-params.interface';

@Injectable()
export class BulkItemStockToStockTransferCartService {

  private isStockData = false;
  private isBrzConnector = false;
  private _stocks = new BehaviorSubject<SearchStock[]>([])
  public readonly stocks = this._stocks.asObservable();
  private _paginatorParams = new BehaviorSubject<PaginatorParams>({index: 0, size: 0, length: 0});
  public readonly paginatorParams = this._paginatorParams.asObservable();
  private _sourceStock = new BehaviorSubject<ViewStock>(ViewStock.empty());
  private _searchValue = new BehaviorSubject<string>('');

  constructor(private stockService: StockService) { }

  public setSearchValue(value: string): void {
    this._searchValue.next(value);
    this.getStocks();
  }

  public setPaginatorParams(params: PaginatorParams): void {
    this._paginatorParams.next(params);
    if (this.isStockData) {
      this.getStocks();
    }
  }

  public updateStocks(sourceId: string): void {
    this.getStockById(sourceId).subscribe(sourceStock => {
      this._sourceStock.next(sourceStock);
      this.isStockData = true;
      this.hasBrzConnector();
    })
  }

  private getStockById(sourceId: string): Observable<ViewStock> {
    return this.stockService.getById(sourceId);
  }

  private hasBrzConnector(): void {
    this.stockService.hasBrzConnector().subscribe(isBrzConnector => {
      this.isBrzConnector = isBrzConnector;
      this.getStocks();
    });
  }

  private getStocks(): void {
    this.getStocksObservable().subscribe(res => {
      this._stocks.next(res.content);
      this._paginatorParams.next({
        index: res.number,
        size: res.size,
        length: res.totalElements,
      });
    })
  }

  private getStocksObservable(): Observable<PagedResponse<SearchStock>> {
    return this.isBrzConnector && !this.hasHauptlager()
      ? this.stocksObservable([this._sourceStock.value.organisationId])
      : this.stocksObservable();
  }

  private stocksObservable(organisationIds: string[] = []): Observable<PagedResponse<SearchStock>> {
    return this.stockService.searchTransferStocks(this._paginatorParams.value.index.toString(),
                                                  this._paginatorParams.value.size.toString(),
                                                  this._searchValue.value,
                                                  this._sourceStock.value.stockId,
                                                  organisationIds);
  }

  private hasHauptlager(): boolean {
    return this._sourceStock.value.stockType === StockType.MAIN;
  }
}
