import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[bhInputAllowedCharacters],textarea[bhInputAllowedCharacters]'
})
export class InputAllowedCharactersDirective {
  @Input() set bhInputAllowedCharacters(pattern: string) {
    this.regex = pattern ? new RegExp(pattern) : null;
  }
  private regex: RegExp;

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    if (this.regex && !this.regex.test(event.key)) {
      event.preventDefault();
    }
  }
}
