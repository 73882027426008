import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewEquipmentEmployeeAssignment } from '../../contract/view-equipment-employee-assignment.interface';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EquipmentsDataSource } from '../equipments.datasource';
import { EquipmentEmployeeRole } from '../../contract/equipment-employee-role.enum';
import { EquipmentEmployeeService } from '../equipment-employee.service';
import { DateValidator } from '../../../../shared/custom-validators/date.validator';
import { AddEquipmentEmployeeAssignmentCommand } from '../../contract/add-equipment-employee-assignment-command';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatesService } from '../../../../shared/services/dates.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EquipmentEmployeeRolePipe } from '../../../../shared/pipes/equipment-employee-role.pipe';
import { LifeCycleEmployeeAdded } from '../../contract/life-cycle-employee-added';
import { LifeCycleEmployeeRemoved } from '../../contract/life-cycle-employee-removed';
import { ReplaceEquipmentEmployeeAssignmentCommand } from '../../contract/replace-equipment-employee-assignment-command';
import { UpdateEquipmentEmployeeAssignmentCommand } from '../../contract/update-equipment-employee-assignment-command';
import { LifeCycleType } from '../../contract/lifecycle-type.enum';
import moment from 'moment';
import { ViewAssignableEmployee } from '../../../disposition/contract/view-assignable-employee';

@Component({
  selector: 'bh-employee-assignment-manage-dialog',
  templateUrl: 'equipment-employee-assignment-manage-dialog.component.html',
  styleUrls: ['equipment-employee-assignment-manage-dialog.component.scss']
})
@UntilDestroy()
export class EquipmentEmployeeAssignmentManageDialogComponent implements OnInit {

  public maxHandOverDate = new Date();

  public form: UntypedFormGroup;
  public editMode = false;
  public replaceMode = false;
  public icon: IconDefinition;
  public assignment: ViewEquipmentEmployeeAssignment;
  public employeeRoleFilter: UntypedFormControl = new UntypedFormControl();
  public employeeFilter: UntypedFormControl = new UntypedFormControl();

  formDisabled = false;
  equipmentId: string;
  existingEmployeeAssignments: ViewEquipmentEmployeeAssignment[];
  availableEmployees: ViewAssignableEmployee[];
  latestLifeCycle: LifeCycleEmployeeAdded | LifeCycleEmployeeRemoved;

  constructor(public dialogRef: MatDialogRef<EquipmentEmployeeAssignmentManageDialogComponent>,
              public employeeRolePipe: EquipmentEmployeeRolePipe,
              private equipmentStore: EquipmentsDataSource,
              private equipmentEmployeeService: EquipmentEmployeeService,
              private formBuilder: UntypedFormBuilder,
              private languageService: LanguageService) { }

  ngOnInit() {
    this.buildForm();
    this.subscribeToFormChanges();
    this.subscribeToCurrentEquipment();
    this.getEmployees();

    if (this.editMode || this.replaceMode) {
      this.getLatestLifeCycle(this.assignment.employeeRole);
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      employee: ['', [<any>Validators.required]],
      employeeRole: ['', [<any>Validators.required]],
      handOverDate: [new Date(), [<any>Validators.required, DateValidator.isValidDate(this.languageService)]]
    });
    if (this.editMode) {
      this.form.patchValue({
        employee: this.assignment.employeeId,
        employeeRole: this.assignment.employeeRole,
        handOverDate: moment(this.assignment.handOverDate).toDate()
      });
      this.form.get('employee').disable();
      this.form.get('employeeRole').disable();
    } else if (this.replaceMode) {
      this.form.patchValue({employeeRole: this.assignment.employeeRole});
      this.form.get('employeeRole').disable();
    }
  }

  private subscribeToFormChanges(): void {
    this.filterEmployeesOnInput();
    if (this.addMode) {
      this.filterEmployeeRolesOnInput();
    }
  }

  private subscribeToCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewEquipment) => {
      if (res) {
        this.equipmentId = res.equipmentId;
      }
    });
  }

  private getEmployees(searchTerm?: string): void {
    this.equipmentEmployeeService.getEmployees(searchTerm).subscribe(employees => {
      this.availableEmployees = employees.filter(employee => !this.isEmployeeAssigned(employee));
      if (this.editMode) {
        this.availableEmployees.push(employees.find(employee => employee.employeeId === this.assignment.employeeId));
      }
    });
  }

  private isEmployeeAssigned(employee: any): boolean {
    return this.existingEmployeeAssignments.some(assignment => assignment.employeeId === employee.employeeId);
  }

  private getLatestLifeCycle(employeeRole: EquipmentEmployeeRole): void {
    this.equipmentEmployeeService.getLatestLifeCycle(
        this.equipmentId,
        employeeRole,
        this.assignment && !this.replaceMode ? this.assignment.employeeAssignmentId : null).subscribe(lifeCycle => {
      this.latestLifeCycle = lifeCycle;
      this.updateFormValidators();
    });
  }

  private updateFormValidators(): void {
    if (this.latestLifeCycle) {
      this.handOverDate.setValidators([
        <any>Validators.required,
        DateValidator.isValidDate(this.languageService),
        DateValidator.inputDateNotBefore(this.latestLifeCycle.logDate)]);
      if (moment(this.handOverDate.value).isBefore(this.latestLifeCycle.logDate)) {
        this.form.patchValue({handOverDate: moment(this.latestLifeCycle.logDate).toDate()});
      }
    } else {
      this.handOverDate.setValidators([
        <any>Validators.required,
        DateValidator.isValidDate(this.languageService)]);
    }
    this.handOverDate.updateValueAndValidity();
  }

  public save(): void {
    const formData = this.form.getRawValue();
    this.formDisabled = true;
    if (this.editMode) {
      this.updateAssignment(formData);
    } else if (this.replaceMode) {
      this.replaceAssignment(formData);
    } else {
      this.addAssignment(formData);
    }
  }

  private addAssignment(formData: any): void {
    const command: AddEquipmentEmployeeAssignmentCommand = new AddEquipmentEmployeeAssignmentCommand(
        this.equipmentId,
        formData.employee,
        DatesService.date(formData.handOverDate),
        formData.employeeRole);

    this.equipmentStore.addEquipmentEmployeeAssignment(command).subscribe({
      error: error => console.log('error on assign employee to equipment: ', error),
      complete: () => this.dialogRef.close()
    });
  }

  private replaceAssignment(formData: any): void {
    const command: ReplaceEquipmentEmployeeAssignmentCommand = new ReplaceEquipmentEmployeeAssignmentCommand(
        this.equipmentId,
        this.assignment.employeeAssignmentId,
        this.assignment.employeeId,
        formData.employee,
        this.assignment.employeeRole,
        DatesService.date(formData.handOverDate));

    this.equipmentStore.replaceEquipmentEmployeeAssignment(command).subscribe({
      error: error => console.log('error on replacing employee on equipment: ', error),
      complete: () => this.dialogRef.close()
    });
  }

  private updateAssignment(formData: any): void {
    const command: UpdateEquipmentEmployeeAssignmentCommand = new UpdateEquipmentEmployeeAssignmentCommand(
        this.equipmentId,
        this.assignment.employeeAssignmentId,
        DatesService.date(formData.handOverDate),
        this.assignment.employeeRole);

    this.equipmentStore.updateEquipmentEmployeeAssignment(command).subscribe({
      error: error => console.log('error on updating employee on equipment: ', error),
      complete: () => this.dialogRef.close(),
    });
  }

  private filterEmployeeRolesOnInput(): void {
    this.form.get('employeeRole')
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(employeeRole => this.getLatestLifeCycle(employeeRole));
  }

  private filterEmployeesOnInput(): void {
    this.employeeFilter
    .valueChanges
    .pipe(debounceTime(150), distinctUntilChanged())
    .subscribe(filterTerm => this.getEmployees(filterTerm));
  }

  get employeeRoles(): string[] {
    let employeeRoles = Object.keys(EquipmentEmployeeRole);
    if (this.addMode) {
      employeeRoles = employeeRoles.filter(employeeRole => this.employeeRoleUnassigned(employeeRole));
    }
    if (this.employeeRoleFilter.value) {
      employeeRoles = employeeRoles.filter(employeeRole => this.employeeRoleContainsSearchTerm(employeeRole));
    }
    return employeeRoles;
  }

  private employeeRoleUnassigned(employeeRole: any): boolean {
    return !this.existingEmployeeAssignments.some(assignment => assignment.employeeRole.toString() === employeeRole);
  }

  private employeeRoleContainsSearchTerm(employeeRole: any): boolean {
    return this.employeeRolePipe.transform(employeeRole).toLocaleLowerCase().includes(this.employeeRoleFilter.value.toLocaleLowerCase());
  }

  public isAddedLifeCycle(lifeCycleType: LifeCycleType): boolean {
    return lifeCycleType === LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT;
  }

  public isReplacedLifeCycle(lifeCycleType: LifeCycleType): boolean {
    return lifeCycleType === LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT;
  }

  public isRemovedLifeCycle(lifeCycleType: LifeCycleType): boolean {
    return lifeCycleType === LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT;
  }

  get handOverDate(): AbstractControl {
    return this.form.get('handOverDate');
  }

  get saveDisabled(): boolean {
    return !this.form.valid || this.formDisabled;
  }

  get addMode(): boolean {
    return !this.editMode && !this.replaceMode;
  }

  get minHandOverDate(): Date {
    return this.latestLifeCycle ? this.latestLifeCycle.logDate : null;
  }
}
