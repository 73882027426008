import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { BaseEmployeeViewNotesComponent } from './base/base-employee-view-notes.component';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeManagementDatasource } from '../../../../../shared/employee-management.datasource';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'bh-employee-view-notes',
  templateUrl: './employee-view-notes.component.html',
  styleUrls: ['./employee-view-notes.component.scss']
})
export class EmployeeViewNotesComponent extends BaseEmployeeViewNotesComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              protected dialog: MatDialog,
              protected employeeManagementStore: EmployeeManagementDatasource,
              protected datePipe: DatePipe,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory, dialog, employeeManagementStore, datePipe, languageService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
