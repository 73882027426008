<div class="bh-form-field" fxLayout="column">
  <label>{{'general.status'|translate}}</label>
  <div class="underline">
    <div [matMenuTriggerFor]="equipmentStatusDropdown.menu"
         class="select" [ngClass]="{'select-disabled': disabled}"
         fxLayout="row" fxLayoutAlign="space-between center">
      <div class="overflow-wrapper">
        <bh-equipment-status-badge [_status]="equipment?.status"></bh-equipment-status-badge>
      </div>
      <div class="mat-mdc-select-arrow-wrapper">
        <div class="mat-mdc-select-arrow custom-select-arrow"></div>
      </div>
    </div>
  </div>
</div>
<bh-equipment-status-dropdown #equipmentStatusDropdown
                              [equipment]="equipment"
                              [disabled]="disabled"
                              (onStatusChange)="onStatusButtonClick($event)">
</bh-equipment-status-dropdown>
