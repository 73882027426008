import { Component, Input } from '@angular/core';
import { IconPayload, IconType } from 'app/shared/contract/icon-payload.interface';

@Component({
  selector: 'bh-icon-resolver',
  templateUrl: './icon-resolver.component.html',
  styleUrls: ['./icon-resolver.component.scss']
})
export class IconResolverComponent {
  @Input() iconPayload: IconPayload;
  public readonly iconType = IconType;
}
