import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentHistoryColumn } from 'app/modules/equipment/contract/equipment-history-column.enum';

@Pipe({name: 'equipmentFields'})
export class EquipmentFieldsPipe implements PipeTransform {
  private readonly techFieldPrefix = 'technicalField.';
  private readonly techFieldPrefixRegExp = new RegExp(this.techFieldPrefix);
  private translationKeys = {
    [EquipmentHistoryColumn.DOCUMENT]:
      this.translate('general.document.s'),
    [EquipmentHistoryColumn.EQUIPMENT_BEUTHAUSER_ID]:
      this.translate('shared.pipes.equipmentFields.equipmentBeutlhauserId'),
    [EquipmentHistoryColumn.EQUIPMENT_CONSTRUCTION_YEAR]:
      this.translate('shared.pipes.equipmentFields.equipmentConstructionYear.year'),
    [EquipmentHistoryColumn.EQUIPMENT_CONSTRUCTION_YEAR_MONTH]:
      this.translate('shared.pipes.equipmentFields.equipmentConstructionYear.month'),
    [EquipmentHistoryColumn.EQUIPMENT_CONSTRUCTION_YEAR_YEAR]:
      this.translate('shared.pipes.equipmentFields.equipmentConstructionYear.year'),
    [EquipmentHistoryColumn.EQUIPMENT_COST_CENTER]:
      this.translate('general.costCenter'),
    [EquipmentHistoryColumn.EQUIPMENT_CUSTOMER_SERIAL_NUMBER]:
      this.translate('shared.pipes.equipmentFields.equipmentCustomerSerialNumber'),
    [EquipmentHistoryColumn.EQUIPMENT_DELIVERY_DATE]:
      this.translate('shared.pipes.equipmentFields.equipmentDeliveryDate'),
    [EquipmentHistoryColumn.EQUIPMENT_GUARANTEE]:
      this.translate('shared.pipes.equipmentFields.equipmentGuarantee'),
    [EquipmentHistoryColumn.EQUIPMENT_GUARANTEE_OPERATING_HOURS_LIMIT]:
      this.translate('shared.pipes.equipmentFields.equipmentGuaranteeOperatingHoursLimit'),
    [EquipmentHistoryColumn.EQUIPMENT_INSURANCE_CONTRACT]:
      this.translate('shared.pipes.equipmentFields.equipmentInsuranceContract'),
    [EquipmentHistoryColumn.EQUIPMENT_INSURANCE_CONTRACT_DOCUMENTS]:
      this.translate('shared.pipes.equipmentFields.documents'),
    [EquipmentHistoryColumn.EQUIPMENT_LICENSE_PLATE]:
      this.translate('shared.pipes.equipmentFields.equipmentLicensePlate'),
    [EquipmentHistoryColumn.EQUIPMENT_NAME]:
      this.translate('general.name.s'),
    [EquipmentHistoryColumn.EQUIPMENT_PRODUCT_CLASS]:
      this.translate('shared.pipes.equipmentFields.equipmentProductClass'),
    [EquipmentHistoryColumn.EQUIPMENT_RENTAL_CONTRACT]:
      this.translate('shared.pipes.equipmentFields.equipmentRentalContract.name'),
    [EquipmentHistoryColumn.EQUIPMENT_RENTAL_CONTRACT_PARTNER]:
      this.translate('shared.pipes.equipmentFields.equipmentRentalContract.contractPartner'),
    [EquipmentHistoryColumn.EQUIPMENT_RENTAL_CONTRACT_DOCUMENTS]:
      this.translate('shared.pipes.equipmentFields.documents'),
    [EquipmentHistoryColumn.EQUIPMENT_RENTAL_CONTRACT_TYPE]:
      this.translate('shared.pipes.equipmentFields.equipmentRentalContract.rentalContractType'),
    [EquipmentHistoryColumn.EQUIPMENT_RENTAL_CONTRACT_MILEAGE_LIMIT_PERIOD]:
      this.translate('shared.pipes.equipmentFields.equipmentRentalContract.rentalMileageLimitPeriod'),
    [EquipmentHistoryColumn.EQUIPMENT_SALES_CONTRACT]:
      this.translate('shared.pipes.equipmentFields.equipmentSalesContract'),
    [EquipmentHistoryColumn.EQUIPMENT_SALES_CONTRACT_DOCUMENTS]:
      this.translate('shared.pipes.equipmentFields.documents'),
    [EquipmentHistoryColumn.EQUIPMENT_SERIAL_NUMBER]:
      this.translate('shared.pipes.equipmentFields.equipmentSerialNumber'),
    [EquipmentHistoryColumn.EQUIPMENT_MODEL]:
      this.translate('modules.equipment.base.model.s'),
    [EquipmentHistoryColumn.EQUIPMENT_SERVICE_CONTRACT]:
      this.translate('shared.pipes.equipmentFields.equipmentServiceContract'),
    [EquipmentHistoryColumn.EQUIPMENT_SERVICE_CONTRACT_DOCUMENTS]:
      this.translate('shared.pipes.equipmentFields.documents'),
    [EquipmentHistoryColumn.EQUIPMENT_STATUS_SERVICE]:
      this.translate('general.status'),
    [EquipmentHistoryColumn.IMAGE]:
      this.translate('shared.pipes.equipmentFields.image'),
    [EquipmentHistoryColumn.LABELS]:
      this.translate('general.label.s'),
    [EquipmentHistoryColumn.MANUFACTURER]:
      this.translate('general.manufacturer'),
    [EquipmentHistoryColumn.ORGANISATION]:
      this.translate('general.org.s'),
    [EquipmentHistoryColumn.SCANCODE]:
      this.translate('general.scanCode'),
    [EquipmentHistoryColumn.TELEMATICS_UNIT_ID]:
      this.translate('shared.pipes.equipmentFields.telematicsUnitId'),
    [EquipmentHistoryColumn.TELEMATICS_UNIT_TYPE]:
      this.translate('shared.pipes.equipmentFields.telematicsUnitType'),
    [EquipmentHistoryColumn.THUMBNAIL]:
      this.translate('shared.pipes.equipmentFields.thumbnail'),
    [EquipmentHistoryColumn.SUBEQUIPMENT]:
      this.translate('general.subEquipm.s'),
    [EquipmentHistoryColumn.CONTAINER]:
      this.translate('general.container'),
    [EquipmentHistoryColumn.EQUIPMENT_BGL_CODE]:
      this.translate('general.BGLCode'),
    [EquipmentHistoryColumn.REGISTRATION_DATE]:
      this.translate('shared.pipes.equipmentFields.registrationDate'),
    [EquipmentHistoryColumn.EQUIPMENT_STATUS]:
      this.translate('general.status'),
    [EquipmentHistoryColumn.EQUIPMENT_TYPE_CATEGORY1]:
      this.translate('shared.pipes.equipmentFields.equipmentType.category1'),
    [EquipmentHistoryColumn.EQUIPMENT_TYPE_CATEGORY2]:
      this.translate('shared.pipes.equipmentFields.equipmentType.category2'),
    [EquipmentHistoryColumn.EQUIPMENT_TYPE_TEMPLATEKEY]:
      this.translate('shared.pipes.equipmentFields.equipmentType.templateKey'),
    [EquipmentHistoryColumn.EQUIPMENT_TYPE_TECHNICAL_FIELDS]:
      this.translate('shared.pipes.equipmentFields.equipmentType.technicalFields'),
    [EquipmentHistoryColumn.EQUIPMENT_TYPE_CONTAINER]:
      this.translate('shared.pipes.equipmentFields.equipmentType.container'),
    [EquipmentHistoryColumn.EQUIPMENT_CREATED]:
      this.translate('shared.pipes.equipmentFields.equipmentCreated'),
    [EquipmentHistoryColumn.EQUIPMENT_STATUS_CATEGORY]:
      this.translate('shared.pipes.equipmentFields.equipmentStatusCategory'),
    [EquipmentHistoryColumn.EQUIPMENT_STATUS_NAME]:
      this.translate('shared.pipes.equipmentFields.equipmentStatusName'),
  };

  constructor(private languageService: LanguageService) {}

  transform(key: string): string {
    if (this.isTechnicalField(key)) {
      return this.extractTechnicalField(key);
    } else {
      return this.translationKeys[key] || this.translate('shared.pipes.equipmentFields.typeNotFound', { value: key });
    }
  }

  private isTechnicalField(key: string): boolean {
    return this.techFieldPrefixRegExp.test(key);
  }

  private extractTechnicalField(key: string): string {
    return key.replace(this.techFieldPrefixRegExp, '');
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
