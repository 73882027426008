import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { UserConfigurationService } from '../../../shared/services/user-configuration.service';

@UntilDestroy()
@Component({
  selector: 'bh-language-switcher-dialog',
  templateUrl: './language-switcher-dialog.component.html',
  styleUrls: ['./language-switcher-dialog.component.scss'],
})
export class LanguageSwitcherDialogComponent {

  userLanguage: string;

  constructor(private dialogRef: MatDialogRef<LanguageSwitcherDialogComponent>,
              userConfigurationService: UserConfigurationService) {
    this.userLanguage = userConfigurationService.getUserLanguage();
  }
}
