<div class="dialog-wrapper add-edit-comment-dialog">
  <h1 mat-dialog-title>{{ (comment ? 'general.comment.view.edit' : 'general.comment.view.add')|translate}}</h1>

  <div mat-dialog-content>
    <form [formGroup]="commentsForm" novalidate fxLayout="row">
      <mat-form-field>
        <mat-label>{{'general.comment.s'|translate}}</mat-label>
        <textarea matInput required
                  class="input-comment"
                  formControlName="comment"
                  [maxlength]="fieldLimit.LONG_DESCRIPTION"
                  bhInputFitHeight
                  minRows="2"
                  maxRowsDefault="10">
        </textarea>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-button color="primary" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button type="button"
            color="primary"
            bhTimeoutSaveButton
            mat-button
            (throttledClick)="save()"
            [disabled]="!isValid()">
      {{'general.buttons.save'|translate}}
    </button>
  </div>
</div>
