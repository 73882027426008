import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { EmployeeFilterType } from 'app/shared/contract/filter/filter-view/employee-filter-collection/employee-filter-type.enum';
import { EmployeeFilterParams } from './employee-filter-params.interface';

export class EmployeeFilterParamsClass extends FilterParams {
  protected paramValuesMapper: { [key in EmployeeFilterType]?: () => any } = {
    [EmployeeFilterType.EMPLOYEE_STATE]: () => this.getFilterTypeParams(FilterTypes.EMPLOYEE_STATE),
    [EmployeeFilterType.LABEL]: () => this.getFilterTypeParams(FilterTypes.LABEL),
    [EmployeeFilterType.ORGANISATION]: () => this.getFilterTypeParams(FilterTypes.ORGANISATION),
  };

  public getFilterParams(): EmployeeFilterParams {
    return {
      [EmployeeFilterType.EMPLOYEE_STATE]: this.getFilterTypeParams(FilterTypes.EMPLOYEE_STATE),
      [EmployeeFilterType.LABEL]: this.getFilterTypeParams(FilterTypes.LABEL),
      [EmployeeFilterType.ORGANISATION]: this.getFilterTypeParams(FilterTypes.ORGANISATION),
    }
  }

  public hasParamValue(type: EmployeeFilterType): boolean {
    return this.paramValuesMapper[type].length > 0;
  }
}
