import { LanguageService } from 'app/shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { ViewProjectComment } from '../../../../../contract/view-project-comment.interface';
import { ConfirmationDialogComponent } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { DeleteProjectCommentCommand } from '../../../../../contract/delete-project-comment-command';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddEditCommentDialogComponent } from '../add-edit-comment-dialog/add-edit-comment-dialog.component';
import { ProjectCheckerService } from '../../../../../shared/services/project-checker.service';
import { ViewProject } from '../../../../../contract/view-project.interface';
import { takeWhile } from 'rxjs/operators';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { dialogResults } from '../../../../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { ProjectStatus } from 'app/modules/disposition/shared/enums/project-status.enum';
import { ProjectStructureType } from 'app/modules/disposition/shared/enums/project-structure-type';

@Directive()
export abstract class BaseProjectViewCommentsComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  private componentActive = true;
  public updateComments = false;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  get project(): ViewProject {
    return this.projectStore.project();
  }

  ngOnInit(): void {
    this.projectStore.currentViewTab = 'comments';
    this.updateComments = this.authService.hasAuthority(Authorities.PROJECT_UPDATE);
    this.subscribeToCurrentProject();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  addEditComment(comment: ViewProjectComment | null): void {
    let dialogRef: MatDialogRef<AddEditCommentDialogComponent> = this.dialog.open(AddEditCommentDialogComponent, { restoreFocus: false });
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.comment = comment;
  }

  deleteComment(comment: ViewProjectComment): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.commentDelete');
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        let cmd: DeleteProjectCommentCommand = new DeleteProjectCommentCommand();

        cmd.commentId = comment.commentId;
        cmd.projectId = this.project.projectId;

        this.projectStore.deleteComment(cmd)
          .pipe(takeWhile(() => this.componentActive))
          .subscribe();
      }
    });
  }

  allowEditingDeleting(userId: string): boolean {
    return userId === this.getUserUserId();
  }

  isProjectFinished(): boolean {
    return this.project.projectStatus === ProjectStatus.FINISHED;
  }

  public getAddTooltip(): string {
    return this.isProjectFinished() ?
     this.languageService.getInstant('general.project.isFinished') :
     this.languageService.getInstant('general.buttons.addComment');
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  protected translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private subscribeToCurrentProject(): void {
    this.projectStore.currentProject
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((res: ViewProject) => {
        if (res && res.projectStructureType !== ProjectStructureType.PROJECT_GROUP) {
          this.getComments();
        }
      });
  }

  private getComments(): void {
    this.projectStore.getComments(this.project.projectId);
  }

}
