import { FilterParams } from '../../../filter-params.class';
import { EquipmentStatusFilterReadinessCheckerBase } from '../../base/equipment-status/equipment-status-filter-readiness-checker-base.class';
import { Observable, map } from 'rxjs';
import { EquipmentStateFilterView } from '../../../filter-view/equipment-state-filter-view.interface';
import { IMaintenanceFilterReadinessChecker } from '../../interfaces/equipment-filter-readiness-checker.interface';
import { MaintenanceTaskService } from 'app/modules/maintenance/tasks/shared/service/maintenance-task.service';

export class MaintenanceCompletedFilterReadinessChecker
  extends EquipmentStatusFilterReadinessCheckerBase
  implements IMaintenanceFilterReadinessChecker {

  constructor(params: FilterParams, private maintenanceTaskService: MaintenanceTaskService) {
    super(params);
  }

  protected getEquipmentStatusFilters(): Observable<EquipmentStateFilterView[]> {
    return this.maintenanceTaskService.getCompletedTasksFilter(this.params.getFilterParams())
      .pipe(map(collection => collection?.equipmentStatuses ?? []));
  }
}
