import { SaveLanguageConfigurationCommand } from '../contract/save-language-configuration-command';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewUser } from '../contract/view-user.interface';
import { SearchUser } from '../contract/search-user.interface';
import { CreateUserCommand } from '../contract/create-user-command';
import { UpdateUserCommand } from '../contract/update-user-command';
import { ResetUserPasswordCommand } from '../contract/reset-user-password-command';
import { ActivateUserCommand } from '../contract/activate-user-command';
import { DeactivateUserCommand } from '../contract/deactivate-user-command';
import { DeleteUserCommand } from '../contract/delete-user-command';
import { CreateSuperAdminCommand } from '../contract/create-super-admin-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { CreatePartnerAdminCommand } from '../contract/create-partner-admin-command';
import { AddOrganisationToUserCommand } from '../contract/add-organisation-to-user-command';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { getHttpParameters } from '../../../shared/utils';
import { SaveUserDashboardCommand } from '../contract/save-user-dashboard-command';
import { SaveEquipmentListConfigurationCommand } from '../contract/save-equipment-list-configuration-command';
import { SaveProjectAddConfigurationCommand } from '../../equipment/contract/save-project-add-configuration-command';
import { SaveStockAddConfigurationCommand } from '../../equipment/contract/save-stock-add-configuration-command';
import { SaveMobileTransferConfigurationCommand } from '../../equipment/contract/save-mobile-transfer-configuration-command';
import { SaveTransferListConfigurationCommand } from '../contract/save-transfer-list-configuration-command';
import { SaveBulkItemListConfigurationCommand } from '../contract/save-bulk-item-list-configuration-command';
import { SaveEmployeeListConfigurationCommand } from '../contract/save-employee-list-configuration-command';
import { SaveEquipmentTransferListConfigurationCommand } from '../contract/save-equipment-transfer-list-configuration-command';
import { SaveContactListConfigurationCommand } from '../contract/save-contact-list-configuration-command';
import { SyncKeycloakCommand } from '../users/shared/sync-keycloak-command';
import { KeycloakInfo } from '../users/shared/keycloak-info';
import { SaveMaintenanceListConfigurationCommand } from '../contract/save-maintenance-list-configuration-command';
import { SaveTransportTaskListConfigurationCommand } from '../contract/save-transport-task-list-configuration-command';
import { SaveProjectListConfigurationCommand } from '../contract/save-project-list-configuration-command';
import { SaveStockListConfigurationCommand } from '../contract/save-stock-list-configuration-command';
import { SavePartnerListConfigurationCommand } from '../contract/save-partner-list-configuration-command';
import { SaveManufacturerListConfigurationCommand } from '../contract/save-manufacturer-list-configuration-command';
import { SaveEquipmentTypeListConfigurationCommand } from '../contract/save-equipment-type-list-configuration-command';
import { SaveTelematicsUnitListConfigurationCommand } from '../contract/save-telematics-unit-administration-list-configuration-command';
import { SaveTransferCartConfigurationCommand } from '../contract/save-transfer-cart-configuration-command';
import { SaveUserConfigListConfigurationCommand } from '../contract/save-user-configuration-command';
import { SaveCustomerConfigListConfigurationCommand } from '../contract/save-customer-configuration-command';
import { SaveDispositionListConfigurationCommand } from '../contract/save-disposition-list-configuration-command';
import { SaveMaintenanceConfigListConfigurationCommand } from '../contract/save-maintenance-config-list-configuration-command';
import { Languages } from 'app/shared/enums/languages.enum';
import { ViewOrganisation } from '../../organisation/contract/view-organisation.interface';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { SaveUserTokensCommand } from '../contract/save-user-tokens-command';
import { SaveTransferLogListConfigurationCommand } from '../contract/save-transfer-log-list-configuration-command';
import { SaveDimensionUnitConfigurationCommand } from '../contract/save-dimension-unit-configuration-command';
import { SaveTimezoneConfigurationCommand } from '../contract/save-timezone-configuration-command';
import { SaveUsageHistoryListConfigurationCommand } from '../contract/save-usage-history-list-configuration-command';

@Injectable()
export class UsersService {

  private url = environment.APP_USERROLE_SERVICE_BASE_URL + '/api/v1/users';
  private mobileUrl = environment.APP_USERROLE_SERVICE_BASE_URL + '/mobile/api/v1/users';
  private orgUrl = environment.APP_USERROLE_SERVICE_BASE_URL + '/api/v1/organisations';
  private urlManagement = environment.APP_USERROLE_SERVICE_BASE_URL + '/management';

  constructor(private http: HttpClient) {
  }

  public getUsers(page = 0, size = 200): Observable<PagedResponse<SearchUser>> {
    const pagingUrl = this.url + '?page=' + page + '&size=' + size;

    return this.http.get<PagedResponse<SearchUser>>(pagingUrl);
  }

  public searchUsers(terms: string, page: number, size: number): Observable<PagedResponse<SearchUser>> {
    let params = {
      'page': page,
      'size': size,
      'terms': terms
    };
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<SearchUser>>(this.url + '/search/all', {params: httpParams});
  }

  public getUser(userId: string): Observable<ViewUser> {
    return this.http.get<ViewUser>(`${this.url}/${userId}`);
  }

  public getUsersByCustomer(customerId: string): Observable<ViewUser[]> {
    return this.http.get<ViewUser[]>(`${this.url}/by-customer/${customerId}`);
  }

  public getOrganisationsTreeByCustomer(customerId: string): Observable<ViewOrganisation[]> {
    return this.http.get<ViewOrganisation[]>(`${this.orgUrl}/by-customer/${customerId}/tree`);
  }

  public addUser(command: CreateUserCommand): Observable<string> {
    return this.http.post(`${this.url}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public addSuperAdmin(command: CreateSuperAdminCommand): Observable<string> {
    return this.http.post(`${this.url}/create-superadmin`, JSON.stringify(command), {responseType: 'text'});
  }

  public addPartnerAdmin(command: CreatePartnerAdminCommand): Observable<string> {
    return this.http.post(`${this.url}/create-partneradmin`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateUser(command: UpdateUserCommand): Observable<string> {
    return this.http.post(`${this.url}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public activateUser(command: ActivateUserCommand): Observable<string> {
    return this.http.post(`${this.url}/activate`, JSON.stringify(command), {responseType: 'text'});
  }

  public deactivateUser(command: DeactivateUserCommand): Observable<string> {
    return this.http.post(`${this.url}/deactivate`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteUser(command: DeleteUserCommand): Observable<string> {
    return this.http.post(`${this.url}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public resetPassword(command: ResetUserPasswordCommand): Observable<string> {
    return this.http.put(`${this.url}/reset-password`, JSON.stringify(command), {responseType: 'text'});
  }

  public resetOwnPassword(command: ResetUserPasswordCommand): Observable<string> {
    return this.http.put(`${this.url}/reset-own-password`, JSON.stringify(command), {responseType: 'text'});
  }

  public addOrganisationToUsers(command: AddOrganisationToUserCommand): Observable<string> {
    return this.http.post(`${this.url}/add-organisation-to-users`, JSON.stringify(command), {responseType: 'text'});
  }

  public saveDashBoardConfig(command: SaveUserDashboardCommand): Promise<string> {
    return this.http.put(`${this.url}/save-dashboard-config`, JSON.stringify(command), {responseType: 'text'}).toPromise();
  }

  public emailInUse(email: string): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
        .set('email', email)
    };
    return this.http.get<boolean>(`${this.url}/email-in-use`, httpOptions);
  }

  public getUsersByRole(roleId: string): Observable<ViewUser[]> {
    let params = getHttpParameters({roleId: roleId});
    return this.http.get<ViewUser[]>(`${this.url}/by-role`, {params: params});
  }

  public saveEquipmentListConfiguration(command: SaveEquipmentListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-equipment-list-config`, command, {responseType: 'text'});
  }

  public saveMaintenanceListConfiguration(command: SaveMaintenanceListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-maintenance-list-config`, command, {responseType: 'text'});
  }

  public saveCompletedMaintenanceListConfiguration(command: SaveMaintenanceListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-completed-maintenance-list-config`, command, {responseType: 'text'});
  }

  public saveTransferLogListConfiguration(command: SaveTransferLogListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-global-transfer-log-list-config`, command, {responseType: 'text'});
  }

  public saveUsageHistoryListConfiguration(command: SaveUsageHistoryListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-usage-history-list-config`, command, {responseType: 'text'});
  }

  public saveProjectListConfiguration(command: SaveProjectListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-project-list-config`, command, {responseType: 'text'});
  }

  public saveStockListConfiguration(command: SaveStockListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-stock-list-config`, command, {responseType: 'text'});
  }

  public savePartnerListConfiguration(command: SavePartnerListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-partner-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveManufacturerListConfiguration(command: SaveManufacturerListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-manufacturer-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveEquipmentTypeListConfiguration(command: SaveEquipmentTypeListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-equipment-type-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveTelematicsUnitListConfiguration(command: SaveTelematicsUnitListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-telematics-unit-administration-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveTransferCartConfiguration(command: SaveTransferCartConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-transfer-cart-config`, command, {responseType: 'text'});
  }

  public saveUserConfigListConfiguration(command: SaveUserConfigListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-user-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveCustomerConfigListConfiguration(command: SaveCustomerConfigListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-customer-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveDispositionListConfiguration(command: SaveDispositionListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-disposition-list-config`, command, {responseType: 'text'});
  }

  public saveMaintenanceConfigListConfiguration(command: SaveMaintenanceConfigListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-maintenance-configuration-list-config`, command, {responseType: 'text'});
  }

  public saveEquipmentTransferListConfiguration(command: SaveEquipmentTransferListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-equipment-transfer-list-config`, command, {responseType: 'text'});
  }

  public saveTransferListConfiguration(command: SaveTransferListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-transfer-list-config`, command, {responseType: 'text'});
  }

  public saveContactListConfiguration(command: SaveContactListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-contact-list-config`, command, {responseType: 'text'});
  }

  public saveTransportTaskListConfiguration(command: SaveTransportTaskListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-transport-task-list-config`, command, {responseType: 'text'});
  }

  public saveProjectAddConfiguration(command: SaveProjectAddConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-project-add-config`, command, {responseType: 'text'});
  }

  public saveStockAddConfiguration(command: SaveStockAddConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-stock-add-config`, command, {responseType: 'text'});
  }

  public saveMobileTransferConfiguration(command: SaveMobileTransferConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-mobile-transfer-config`, command, {responseType: 'text'});
  }

  public saveBulkItemListConfiguration(command: SaveBulkItemListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-bulk-item-list-config`, command, {responseType: 'text'});
  }

  public saveEmployeeListConfiguration(command: SaveEmployeeListConfigurationCommand): Observable<string> {
    return this.http.put(`${this.url}/save-employee-list-config`, command, {responseType: 'text'});
  }

  public saveLanguageConfiguration(command: SaveLanguageConfigurationCommand): Observable<any> {
    return this.http.put(`${this.url}/save-language-config`, command, { responseType: 'text' });
  }

  public saveDimensionUnitConfiguration(command: SaveDimensionUnitConfigurationCommand): Observable<any> {
    return this.http.put(`${this.url}/save-dimension-unit-config`, command, { responseType: 'text' });
  }

  public saveTimezoneConfiguration(command: SaveTimezoneConfigurationCommand): Observable<any> {
    return this.http.put(`${this.url}/save-timezone-config`, command, { responseType: 'text' });
  }

  public syncKeycloak(command: SyncKeycloakCommand): Observable<any> {
    let httpParams = getHttpParameters(command);
    return this.http.put(`${this.urlManagement}/synchronize-keycloak`, {}, {
      params: httpParams,
      responseType: 'text'
    });
  }

  public getSyncKeycloakStatus(): Observable<KeycloakInfo[]> {
    return this.http.get<KeycloakInfo[]>(`${this.urlManagement}/synchronize-keycloak/status`);
  }

  public getAvailableLanguages(): Observable<Languages[]> {
    return this.http.get<any>(`${this.url}/available-languages`)
    .pipe(map(languages => [...languages, ...(!environment.production ? [Languages.DEV] : [])]));
  }

  public saveMapConfigurationEquipment(mapConfig: MapConfiguration): Observable<string> {
    return this.http.put(`${this.url}/save-equipment-map-config`, JSON.stringify(mapConfig), {responseType: 'text'});
  }

  public saveMapConfigurationContact(mapConfig: MapConfiguration): Observable<string> {
    return this.http.put(`${this.url}/save-contact-map-config`, JSON.stringify(mapConfig), {responseType: 'text'});
  }

  public saveMapConfigurationProject(mapConfig: MapConfiguration): Observable<string> {
    return this.http.put(`${this.url}/save-project-map-config`, JSON.stringify(mapConfig), {responseType: 'text'});
  }

  public saveMapConfigurationStock(mapConfig: MapConfiguration): Observable<string> {
    return this.http.put(`${this.url}/save-stock-map-config`, JSON.stringify(mapConfig), {responseType: 'text'});
  }

  public saveCurrentUserImpersonateData(command: SaveUserTokensCommand): Observable<string> {
    return this.http.post(`${this.mobileUrl}/store-token`, JSON.stringify(command), {responseType: 'text'});
  }
}
