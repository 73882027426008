import {Component, Input, OnInit} from '@angular/core';
import {ViewEquipment} from '../../../../modules/equipment/contract/view-equipment.interface';
import {SearchEquipment} from '../../../../modules/equipment/contract/search-equipment.interface';
import {EquipmentCheckerService} from '../../../../modules/equipment/shared/services/equipment-checker.service';
import {
  faTruck
} from '@fortawesome/pro-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {RoleAuthorityGuardsComponent} from '../../../navigation-guards/role-authority-guards.component';
import {KeycloakService} from '../../../../core/keycloak';

@Component({
  selector: 'bh-equipment-transport-types-icon',
  templateUrl: './equipment-transport-types-icon.component.html',
  styleUrls: ['./equipment-transport-types-icon.component.scss']
})
export class EquipmentTransportTypesIconComponent extends RoleAuthorityGuardsComponent implements OnInit {

  public readonly faTruck: IconDefinition = faTruck;

  @Input() equipment: ViewEquipment | SearchEquipment;

  constructor(public equipmentCheckerService: EquipmentCheckerService, protected authService: KeycloakService) {
    super(authService);
  }

  ngOnInit(): void {
  }

  public get concatTransportTypes(): string {
    return this.equipment?.transportTypes?.join(', ');
  }

  public get isTransportVehicle(): boolean {
    return this.equipmentCheckerService.isTransportVehicle(this.equipment);
  }

}
