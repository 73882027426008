import { OptionResolver } from '../card-select/option.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faTruckPlow,
  faTrafficCone,
  faWarehouse,
  faMapMarkedAlt,
  faUsers,
  faAddressCard,
  faTruck,
} from '@fortawesome/pro-duotone-svg-icons';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightArrowLeft, faTimelineArrow } from '@fortawesome/pro-regular-svg-icons';
import {ListType} from '../../enums/list-type.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { Injectable } from '@angular/core';
import { IconPayload, IconType } from 'app/shared/contract/icon-payload.interface';


type TypeMapper = {
  [key in ListType]: IconType
}

@Injectable()
export class AssetListTypeResolver implements OptionResolver {
  private readonly resolveIconMapper: TypeMapper = {
    [ListType.BULK_ITEMS]: IconType.FA,
    [ListType.EQUIPMENTS]: IconType.FA,
    [ListType.BULK_ITEM_TRANSFER]: IconType.FA,
    [ListType.EQUIPMENT_TRANSFER]: IconType.FA,
    [ListType.STOCKS]: IconType.FA,
    [ListType.PROJECTS]: IconType.FA,
    [ListType.EMPLOYEES]: IconType.FA,
    [ListType.CONTACTS]: IconType.FA,
    [ListType.TRANSPORTATION_TASKS]: IconType.FA,
    [ListType.MAINTENANCE_TASKS_COMPLETED]: IconType.FA,
    [ListType.MAINTENANCE_TASKS_CURRENT]: IconType.MAT,
    [ListType.ANALYTICS_TRANSFER_LOG]: IconType.FA,
    [ListType.ANALYTICS_USAGE_HISTORY]: IconType.FA,
    [ListType.NONE]: null,
  }

  constructor(private languageService: LanguageService) {}

  private resolveTranslateKey(option: string): string {
    switch (option) {
      case ListType.BULK_ITEMS:
        return 'general.bulkItems';
      case ListType.EQUIPMENTS:
        return 'general.equipm.pl';
      case ListType.BULK_ITEM_TRANSFER:
        return 'general.bulkItems';
      case ListType.EQUIPMENT_TRANSFER:
        return 'general.equipm.pl';
      case ListType.STOCKS:
        return 'general.stock.pl';
      case ListType.PROJECTS:
        return 'general.project.s';
      case ListType.EMPLOYEES:
        return 'general.employee.pl';
      case ListType.CONTACTS:
        return 'general.contact.pl';
      case ListType.TRANSPORTATION_TASKS:
        return 'general.transportationTasks';
      case ListType.MAINTENANCE_TASKS_CURRENT:
        return 'modules.maintenance.base.maintenanceCurrent';
      case ListType.MAINTENANCE_TASKS_COMPLETED:
        return 'modules.maintenance.base.maintenanceCompleted';
      case ListType.ANALYTICS_TRANSFER_LOG:
        return 'modules.analytics.base.transferLog';
      case ListType.ANALYTICS_USAGE_HISTORY:
        return 'modules.analytics.base.usageHistory';
      default:
        return 'general.unknown';
    }
  }

  resolveName(option: string): string {
    return this.languageService.getInstant(this.resolveTranslateKey(option));
  }

  resolveIcon(option: string): IconDefinition {
    switch (option) {
      case ListType.BULK_ITEMS:
        return faTrafficCone;
      case ListType.EQUIPMENTS:
        return faTruckPlow;
      case ListType.BULK_ITEM_TRANSFER:
        return faTrafficCone;
      case ListType.EQUIPMENT_TRANSFER:
        return faTruckPlow;
      case ListType.STOCKS:
        return faWarehouse;
      case ListType.PROJECTS:
        return faMapMarkedAlt;
      case ListType.EMPLOYEES:
        return faUsers;
      case ListType.CONTACTS:
        return faAddressCard;
      case ListType.TRANSPORTATION_TASKS:
        return faTruck;
      case ListType.MAINTENANCE_TASKS_COMPLETED:
        return faHistory;
      case ListType.ANALYTICS_TRANSFER_LOG:
        return faArrowRightArrowLeft;
      case ListType.ANALYTICS_USAGE_HISTORY:
        return faTimelineArrow;
      default:
        return null;
    }
  }

  public resolveMatIcon(option: string): string {
    switch (option) {
      case ListType.MAINTENANCE_TASKS_CURRENT:
        return 'build';
      default:
        return null;
    }
  }

  public resolveIconPayload(option: string): IconPayload {
    switch (this.resolveIconMapper[option]) {
      case IconType.FA:
        return { type: IconType.FA, icon: this.resolveIcon(option) };
      case IconType.MAT:
        return { type: IconType.MAT, icon: this.resolveMatIcon(option) };
      default:
        return null;
    }
  }

}
