import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { AssetType } from '../contract/asset-type.enum';


@Pipe({
  name: 'assetType'
})
export class AssetTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    switch (value) {
      case AssetType.BULK_ITEM:
        return this.translate('general.bulkItems');
      case AssetType.EQUIPMENT:
        return this.translate('general.equipm.s');
      case AssetType.PROJECT:
        return this.translate('general.project.s');
      case AssetType.STOCK:
        return this.translate('general.stock.s');
      default:
        return this.translate('shared.pipes.assetType.unknownAssetType');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
