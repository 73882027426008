import {Inject, Injectable} from '@angular/core';
import {UserConfigurationService} from './user-configuration.service';
import {DOCUMENT} from '@angular/common';
import {PartnerTheme} from '../../modules/organisation/contract/partner-theme.enum';
import {Title} from '@angular/platform-browser';
import {LanguageService} from './language.service';
import {ThemeSettings} from '../../../assets/styles/themes/theme.settings';
import {WACKER_NEUSON_THEME} from '../../../assets/styles/themes/wacker-neuson/_wacker-neuson-theme.settings';
import {DEFAULT_OSP_THEME} from '../../../assets/styles/themes/default-osp/_default-osp-theme.settings';
import {TRIATHLON_THEME} from '../../../assets/styles/themes/triathlon/_triathlon-theme.settings';
import { OspThemeSwitcherService } from 'app/modules/osp-ui/services/osp-theme-switcher.service';

@Injectable({
  providedIn: 'root'
})
export class CustomThemeService {

  constructor(@Inject(DOCUMENT) private document: Document,
              private titleService: Title,
              private languageService: LanguageService,
              private userConfigurationService: UserConfigurationService,
              private ospThemeSwitcherService: OspThemeSwitcherService) {}

  public applyUserTheme(): void {
    const theme = this.userConfigurationService.getPartnerTheme();

    this.setApplicationTheme(theme);
    this.setApplicationFavicon(theme);
    this.setApplicationTitle();

    this.languageService.onLanguageChange.subscribe(() => {
      this.setApplicationTitle();
    });
  }

  private setApplicationTheme(theme: PartnerTheme): void {
    // this.document.body.classList.add(theme.toLowerCase());
    this.setOspUITheme(theme);
  }

  private setApplicationFavicon(theme: PartnerTheme): void {
    const faviconIcon: HTMLLinkElement = this.document.querySelector('#app-favicon');

    switch (theme) {
      case PartnerTheme.DEFAULT_OSP:
        faviconIcon.href = 'assets/styles/themes/default-osp/assets/osp_favicon.ico';
        faviconIcon.type = 'image/x-icon';
        break;
      case PartnerTheme.UNLABLED:
        faviconIcon.href = 'assets/styles/themes/default-osp/assets/osp_favicon.ico';
        faviconIcon.type = 'image/x-icon';
        break;
      case PartnerTheme.WACKER_NEUSON:
        faviconIcon.href = 'assets/styles/themes/wacker-neuson/assets/wn_favicon.ico';
        faviconIcon.type = 'image/png';
        break;
      case PartnerTheme.TRIATHLON:
        faviconIcon.href = 'assets/styles/themes/triathlon/assets/triathlon_favicon.ico';
        faviconIcon.type = 'image/png';
        break;
    }
  }

  private setApplicationTitle(): void {
    this.titleService.setTitle(this.getApplicationTitle());
  }

  private setOspUITheme(theme: PartnerTheme): void {
    switch (theme) {
      case PartnerTheme.WACKER_NEUSON:
        this.ospThemeSwitcherService.setThemeWN();
        break;
      case PartnerTheme.TRIATHLON:
        this.ospThemeSwitcherService.setThemeTR();
        break;
      case PartnerTheme.DEFAULT_OSP:
      default:
        this.ospThemeSwitcherService.setThemeDefault();
        break;
    }
  }

  public getApplicationTitle(): string {
    const theme = this.userConfigurationService.getPartnerTheme();

    switch (theme) {
      case PartnerTheme.TRIATHLON:
        return this.languageService.getInstant('general.applicationTitle.triathlon');
      case PartnerTheme.WACKER_NEUSON:
        return this.languageService.getInstant('general.applicationTitle.wackerNeuson');
      case PartnerTheme.UNLABLED:
        return this.languageService.getInstant('general.applicationTitle.unlabeled');
      case PartnerTheme.DEFAULT_OSP:
      default:
        return this.languageService.getInstant('general.applicationTitle.defaultOsp');
    }
  }

  public getThemeSettings(): ThemeSettings {
    let theme = this.userConfigurationService.getPartnerTheme();

    switch (theme) {
      case PartnerTheme.WACKER_NEUSON:
        return WACKER_NEUSON_THEME;
      case PartnerTheme.TRIATHLON:
        return TRIATHLON_THEME;
      case PartnerTheme.DEFAULT_OSP:
      case PartnerTheme.UNLABLED:
      default:
        return DEFAULT_OSP_THEME;
    }
  }
}
