export enum MaintenanceFilterType {
  ARTICLE_GROUPS = 'articleGroups',
  LABEL = 'equipmentLabels',
  ORGANISATION = 'organisations',
  MAINTENANCE_TYPES = 'maintenanceTypes',
  TYPES = 'equipmentTypes',
  DUE_DATE = 'warningLevels',
  RESPONSIBLE_PERSON = 'employees',
  EQUIPMENT_LOCATIONS = 'equipmentLocations',
  EQUIPMENT_STATUS = 'equipmentStatuses',
  MAINTENANCE_CATEGORY = 'maintenanceCategories',
  MATCH_ALL_LABELS = 'matchAllLabels',
}
