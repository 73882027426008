import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { TypeOfUseChangeRequestsWidgetApiService } from './type-of-use-change-requests-widget-api.service';
import { ITypeOfUseChangeRequest } from './type-of-use-change-request.interface';
import { ProjectTypeOfUseRequestCommand } from '../../../disposition/contract/project-type-of-use-request-command';

@Injectable({
  providedIn: 'root'
})
export class TypeOfUseChangeRequestsWidgetStoreService {

  private pageSize = 15;
  private page = 0;
  private numberOfPages = 0;

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _requests: BehaviorSubject<ITypeOfUseChangeRequest[]> = new BehaviorSubject<ITypeOfUseChangeRequest[]>([]);
  private _endOfList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _noEntries: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasTypeOfUseWorkflow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly requests: Observable<ITypeOfUseChangeRequest[]> = this._requests.asObservable();
  public readonly endOfList: Observable<boolean> = this._endOfList.asObservable();
  public readonly noEntries: Observable<boolean> = this._noEntries.asObservable();

  constructor(private api: TypeOfUseChangeRequestsWidgetApiService) { }

  public scroll(initialLoad?: boolean): void {
    if (initialLoad) {
      this.page = 0;
      this._requests.next([]);
    }
    if (this._loading.value && !initialLoad) {
      return;
    }

    if (this.page === (this.numberOfPages - 1) && !initialLoad) {
      return;
    }

    this._loading.next(true);

    if (this.page < this.numberOfPages && !initialLoad) {
      this.page++;
    }

    this.api.getTypeOfUseChangeRequests(this.page, this.pageSize)
    .subscribe(res => {
      const noElements = res.totalElements < 1;
      this._noEntries.next(noElements);
      this.numberOfPages = res.totalPages;
      this._loading.next(false);
      this._requests.next([...this._requests.value, ...res.content]);
      this._endOfList.next(res.last && !noElements);
    });
  }

  public async setHasTypeOfUseWorkflow() {
    await this.api.hasTypeOfUseWorkflow().toPromise().then(value => {
      this.hasTypeOfUseWorkflow.next(value);
    })
  }

  public acceptTypeOfUseChangeRequest(command: ProjectTypeOfUseRequestCommand) {
    this.api.acceptTypeOfUseChangeRequest(command);
  }

}
