<table mat-table [dataSource]="dataSource" *ngIf="dataSource.length > 0">
  <ng-container matColumnDef="equipmentGeneral">
    <th mat-header-cell class="custom-cell name-cell" *matHeaderCellDef>{{'general.equipm.s'|translate}}
    </th>
    <td mat-cell class="custom-cell name-cell" *matCellDef="let amount" fxLayout="row"
        fxLayoutAlign="space-between space-between">
      <div fxLayout="column" fxLayoutAlign="space-between start">

      <div class="item-name" [class.link]="amount.currentUserHasAccessToEquipment && canSeeLink" *ngIf="amount.transferItemName"
           (click)="amount.currentUserHasAccessToEquipment && canSeeLink
            && navigateToEquipmentAssignments(amount.transferItemId)">
        <span>{{amount.transferItemName}}</span>
        <mat-icon *ngIf="amount.currentUserHasAccessToEquipment && canSeeLink"
          [matTooltip]="'general.labels.jumpTo'|translate: { value: 'general.equipm.s'|translate }"
          (click)="navigateToEquipmentAssignments(amount.transferItemId)">
          open_in_new
        </mat-icon>
      </div>

        <div class="equipment-field"
             *ngIf="amount.equipmentCustomerSerialNumber">{{ amount.equipmentCustomerSerialNumber }}</div>
        <div class="equipment-field" *ngIf="amount.transferItemCostCenter">{{ amount.transferItemCostCenter }}</div>
        <div class="equipment-field"
             *ngIf="amount.transferredBy">{{'general.labels.disposedBy'|translate}} {{ amount.transferredBy }}</div>
        <div class="equipment-field" *ngIf="amount.transferCreatedDate">{{'general.at'|translate}}
          {{amount.transferCreatedDate | date:'EEEE, d. MMM y':'UTC':getCurrentLocale()}}
        </div>
      </div>
      <div *ngIf="amount.currentChargePeriod" fxLayout="column"  fxLayoutAlign="start flex-end">
        <div *ngIf="amount.currentChargePeriod.status"
             class="amount-status {{resolveStatusClass(amount.currentChargePeriod.status)}}"
             [matTooltip]="amount.currentChargePeriod.comment"
             [matTooltipPosition]="'above'"
        >
          {{ amount.currentChargePeriod.status | typeOfUseStatus }}
        </div>
        <div *ngIf="amount.currentChargePeriod.statusStartDate" class="equipment-field" fxLayout="row">  {{"general.from" | translate}}: {{ amount.currentChargePeriod.statusStartDate | date:'dd.MM.yyyy HH:mm':'':getCurrentLocale() }}</div>
        <div *ngIf="amount.currentChargePeriod.statusEndDate" class="equipment-field" fxLayout="row">  {{"general.to" | translate}}: {{ amount.currentChargePeriod.statusEndDate | date:'dd.MM.yyyy HH:mm':'':getCurrentLocale() }}</div>
        <div class="equipment-field" *ngIf="amount.currentChargePeriod?.reason" fxLayout="row">
          <fa-icon class="mr-5 custom-icon" [icon]="iconService.resolveReasonIcon(amount.currentChargePeriod.reason)"></fa-icon>
          {{amount.currentChargePeriod.reason | typeOfUseStatusReason}}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="transferDate">
    <th mat-header-cell class="custom-cell amount-cell" *matHeaderCellDef>{{'general.period'|translate}}
    </th>
    <td mat-cell class="custom-cell amount-cell" *matCellDef="let amount">
      <div>
        {{amount.transferDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell class="custom-cell edit-cell" *matHeaderCellDef>{{'general.actions'|translate}}
    </th>
    <td mat-cell class="custom-cell edit-cell" [ngClass]="isFirefox && 'edit-cell__firefox'" *matCellDef="let amount">
      <button mat-button (click)="openTransferToStock(amount)">
        <div fxLayout="row">
                      <span fxFlex="80">
                        {{'modules.disposition.projectDispositions.backTo'|translate:{
                        value:
                          'general.stock.s'|translate
                      } }}
                      </span>
          <fa-icon [icon]="faArrowRight" fxFlex="20"></fa-icon>
        </div>
      </button>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: equipmentColumns;"></tr>
</table>
