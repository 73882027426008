import { Component, Input, OnInit } from '@angular/core';
import { TransportHistoryChangedValue } from 'app/modules/transportation/contracts/transport/transport-history-changed-value.interface';
import { TransportHistoryFieldType } from 'app/modules/transportation/shared/enums/transport-history-field-type.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Component({
  selector: 'bh-transport-history-table-item',
  templateUrl: './transport-history-table-item.component.html',
  styleUrls: ['./transport-history-table-item.component.scss']
})
export class TransportHistoryTableItemComponent implements OnInit {
  @Input() item: TransportHistoryChangedValue;
  @Input() emptyValue = '—';
  public readonly transportHistoryFieldType = TransportHistoryFieldType;
  public datePartFormat = 'dd MMM y';
  public timePartFormat = 'shortTime';

  constructor(private languageService: LanguageService) { }

  public ngOnInit(): void {
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
