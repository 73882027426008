import { UntilDestroy } from '@ngneat/until-destroy';
import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseProjectViewComponent } from '../base/base-project-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ViewMenu } from '../../../../shared/interfaces/view-menu.interface';
import { RouterHistory } from '../../../../../../shared/router-history';
import { AmountsService } from '../../../../shared/amounts.service';

@Component({
  selector: 'bh-mobile-project-view',
  templateUrl: './mobile-project-view.component.html',
  styleUrls: ['./mobile-project-view.component.scss']
})
export class MobileProjectViewComponent extends BaseProjectViewComponent implements OnInit, OnDestroy {

  @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel;

  currentViewMenu: ViewMenu;
  viewMenus: ViewMenu[] = [
    {
      name: this.translate('general.generalData').toUpperCase(),
      url: 'general'
    },
    {
      name: this.translate('general.description').toUpperCase(),
      url: 'description'
    },
    {
      name: this.translate('general.map.s').toUpperCase(),
      url: 'map'
    },
    {
      name: this.translate('general.comment.pl').toUpperCase(),
      url: 'comments'
    },
    {
      name: this.translate('general.attachment.pl').toUpperCase(),
      url: 'attachments'
    },
    {
      name: this.translate('modules.disposition.base.inventory.s').toUpperCase(),
      url: 'assignments'
    }
  ];
  backRoute: string;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              protected amountsService: AmountsService,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory, projectStore, amountsService, languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.backRoute = this.route.snapshot.queryParamMap.get('backRoute');
    this.setCurrentViewMenu();
  }

  ngOnDestroy(): void {
  }

  navigateToMenu(menu: ViewMenu): boolean | void {
    const hasAddress = this.project && this.project.projectAddress;
    if (!hasAddress && menu.url === this.viewMenus[2].url) {
      return false;
    }

    this.setMenu(menu);
    this.router.navigate([menu.url], {relativeTo: this.route});
  }

  setCurrentViewMenu(): void {
    const currentMenuUrl: string = this.route.snapshot.firstChild.url[0].path;
    this.currentViewMenu = this.viewMenus.find((vm) => vm.url === currentMenuUrl);
  }

  private setMenu(menu: ViewMenu): void {
    this.currentViewMenu = menu;
    this.expansionPanel.close();
  }
}
