<div class="employee-assignments" fxLayout="row" fxLayoutAlign="start stretch">

  <div *ngFor="let employeeAssignment of employeeAssignments; let index=index; let even=even"
       class="employee-assignment" fxFlex="50" fxLayout="row"
       [ngClass]="{ 'even': even }">
    <mat-card fxFlexFill>
      <mat-card-header (click)="openViewAssignmentDialog(employeeAssignment)">
        <div mat-card-avatar>
          <fa-icon [icon]="getRoleIcon(employeeAssignment.employeeRole)"></fa-icon>
        </div>
        <mat-card-title>{{ employeeAssignment.employeeRole | equipmentEmployeeRole }}</mat-card-title>
        <mat-card-subtitle>{{ employeeAssignment.employeeFirstName }} {{ employeeAssignment.employeeName }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="employee-assignment-info" (click)="openViewAssignmentDialog(employeeAssignment)">
          <div>
            {{ getDateQualifier(employeeAssignment.handOverDate) }}
            {{ employeeAssignment.handOverDate | formatTimezoneDateTime }}
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>email</mat-icon>
            <span>{{ employeeAssignment.employeeEmail | defaultValue: '-' }}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>call</mat-icon>
            <span>{{ getPhoneNumber(employeeAssignment) }}</span>
          </div>
        </div>
        <div class="employee-assignment-actions" fxLayout="row" fxLayoutAlign="end center" *ngIf="allowAddEdit">
          <span class="employee-replace" (click)="openReplaceAssignmentDialog(employeeAssignment)">
            <fa-icon [icon]="faUsers" [matTooltip]="'modules.equipment.employeeAssignment.changeResponsible'|translate"></fa-icon>
          </span>
          <span (click)="openEditAssignmentDialog(employeeAssignment)">
            <mat-icon matSuffix [matTooltip]="'general.buttons.change'|translate">mode_edit</mat-icon>
          </span>
          <span (click)="openDeleteAssignmentDialog(employeeAssignment)">
            <mat-icon matSuffix [matTooltip]="'general.buttons.remove'|translate">delete</mat-icon>
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="allowAddEdit"
       class="employee-assignment-add" fxFlex="50" fxLayout="row"
       [ngClass]="{ 'even': employeeAssignments && employeeAssignments.length % 2 === 0 }">
    <mat-card fxFlex (click)="openAddAssignmentDialog()">
      <mat-card-content fxFlex fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>add_circle_outline</mat-icon>
      </mat-card-content>
    </mat-card>
  </div>

</div>
