import { Component, OnInit } from '@angular/core';
import { BaseProjectViewMapComponent } from '../base/base-project-view-map.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import { RouterHistory } from '../../../../../../../shared/router-history';

@Component({
  selector: 'bh-project-view-map',
  templateUrl: './project-view-map.component.html',
  styleUrls: ['./project-view-map.component.scss']
})
export class ProjectViewMapComponent extends BaseProjectViewMapComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource) {
    super(authService, router, route, routerHistory, projectStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
