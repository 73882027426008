import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { ContactFilterRequestParams } from './contact-request-params/contact-filter-request-params.interface';
import { ContactFilterType } from '../enums/contact-filter-type.enum';
import { ContactType } from '../enums/contact-type.enum';
import { ContactStatus } from '../enums/contact-status.enum';

export class ContactFilterParams extends FilterParams {
  public getContactSearchParams(): ContactFilterRequestParams {
    return {
      [ContactFilterType.CLASS]: <ContactType[]>this.getFilterTypeParams(FilterTypes.CLASS),
      [ContactFilterType.STATUS]: <ContactStatus[]>this.getFilterTypeParams(FilterTypes.CONTACT_STATE),
      [ContactFilterType.ORGANISATION]: this.getFilterTypeParams(FilterTypes.ORGANISATION),
      [ContactFilterType.LABEL]: this.getFilterTypeParams(FilterTypes.LABEL)
    }
  }

  // TO DO: left for compatibility
  // DON'T USE it as an example for filtering implementations!!!
  // Use the logic from the transportation module (VehicleFiltersService)
  public getFilterParams(): ContactFilterRequestParams {
    return null;
  }
}
