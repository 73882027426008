import { DragStatus } from '../contract/drag-item-state.interface';
import { filter } from 'rxjs/operators';
import { DragAndDropService } from 'app/shared/services/drag-and-drop.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Directive, Optional, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DragItemState } from '../enums/drag-item-state.enum';

@UntilDestroy()
@Directive({ selector: '[bhPreventExpandAfterDrag]' })
export class PreventExpandAfterDrag implements OnInit, OnDestroy {

  private wasDragging = false;
  private wasDraggingCanceled = false;

  constructor(private dndService: DragAndDropService,
              @Optional() private cdkDrag: CdkDrag,
              private expansionPanel: MatExpansionPanel) {
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public ngOnDestroy(): void {
  }

  @HostListener('mouseup') onEscKeydownHandler(): void {
    if (this.wasDraggingCanceled) {
      this.wasDraggingCanceled = false;
      setTimeout(() => {
        this.enableExpansionPanel();
      }, 0);
    }
  }

  private initListeners(): void {
    if (this.cdkDrag) {
      this.cdkDrag.started
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.disableExpansionPanel()
          this.wasDragging = true;
        });

      this.cdkDrag.ended
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (!this.wasDraggingCanceled && this.wasDragging) {
            setTimeout(() => {
              this.enableExpansionPanel();
              this.wasDragging = false;
            }, 0);
          }
        });

      this.dndService.dragStatus
        .pipe(
          untilDestroyed(this),
          filter<DragStatus>(status => status && status.state === DragItemState.CANCELED))
        .subscribe(() => {
          if (this.wasDragging) {
            this.wasDraggingCanceled = true;
            this.wasDragging = false;
          }
        });
    }
  }

  private disableExpansionPanel(): void {
    this.expansionPanel.disabled = true;
  }

  private enableExpansionPanel(): void {
    // Trick to force the indicator to appear on the panel after dragging ends
    this.expansionPanel.open();
    this.expansionPanel.close();
    this.expansionPanel.disabled = false;
  }
}
