import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

export class SaveTransportTaskListConfigurationCommand {
  constructor(
    public columns: string[],
    public columnConfigs: ColumnConfig[],
    public listViewPageSize: number,
    public boardViewBoardPageSize: number,
    public boardViewListPageSize: number
  ) {}
}
