<div class="gallery-thumbnails-wrapper gallery-thumbnail-size-{{size}}">
    <div class="gallery-thumbnails"
      [style.transform]="'translateX(' + thumbnailsLeft + ')'"
      [style.marginLeft]="thumbnailsMarginLeft"
      data-osp-test="gallery-thumbnails">
        <bh-image
          *ngFor="
            let image of (images | currentThumbnails : index : columns : rows : moveSize);
            let i = index;
            trackBy: thumbnailTrackBy"
          [href]="hasLink(i) ? links[i] : '#'"
          [target]="linkTarget"
          class="gallery-thumbnail"
          [src]="image"
          (click)="handleClick($event, i)"
          [style.width]="getThumbnailWidth()"
          [style.height]="getThumbnailHeight()"
          [style.left]="getThumbnailLeft(i)"
          [style.top]="getThumbnailTop(i)"
          [ngClass]="{ 'gallery-active': i === selectedIndex, 'gallery-clickable': clickable }"
          [attr.aria-label]="labels[i]">
        </bh-image>
    </div>
</div>
<bh-gallery-arrows *ngIf="canShowArrows()"
  (onPrevClick)="moveLeft()"
  (onNextClick)="moveRight()"
  [prevDisabled]="!canMoveLeft()"
  [nextDisabled]="!canMoveRight()"
  [arrowPrevIcon]="arrowPrevIcon"
  [arrowNextIcon]="arrowNextIcon">
</bh-gallery-arrows>
