import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectDataSource } from '../project.datasource';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewProject } from '../../contract/view-project.interface';
import { FinishProjectCommand } from '../../contract/finish-project-command';
import * as moment from 'moment';
import { DatesService } from 'app/shared/services/dates.service';

@Component({
  selector: 'bh-finish-project-dialog',
  templateUrl: './finish-project-dialog.component.html',
  styleUrls: ['./finish-project-dialog.component.scss']
})
export class FinishProjectDialogComponent implements OnInit {

  public projectFinishForm: UntypedFormGroup;
  public project: ViewProject;
  public min: Date;
  public max: Date;

  constructor(public dialogRef: MatDialogRef<FinishProjectDialogComponent>,
              protected formBuilder: UntypedFormBuilder,
              private projectStore: ProjectDataSource) {
  }

  public ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.min = this.project.projectStart ?
        moment(this.project.projectStart).add(1, 'day').toDate() :
        null;
    this.max = moment().toDate();
    this.projectFinishForm = this.formBuilder.group({
      finishDate: [this.project.projectEnd ? this.project.projectEnd : this.max.toISOString(), Validators.required]
    });
  }

  public finishProject(): void {
    if (this.projectFinishForm.valid) {
      const cmd: FinishProjectCommand = new FinishProjectCommand();
      cmd.projectId = this.project.projectId;
      cmd.finishDate = DatesService.ospDateTimeAtStartOfDay(this.finishDate.value);
      this.projectStore.finishProject(cmd);
      this.dialogRef.close();
    }
  }

  public get finishDate(): AbstractControl {
    return this.projectFinishForm.get('finishDate');
  }
}
