<div fxLayout="row" fxLayoutAlign="space-between start">
  <div fxFlex="90" class="description">
    <div *ngIf="!editMode"
         class="ql-editor ql-container"
         [ngClass]="{'ql-container-blank': !description}"
         [innerHTML]="description | dompurify"
         [attr.data-placeholder]="'shared.description.noDescription'|translate"
         data-osp-test="field-description">
    </div>

    <quill-editor *ngIf="editMode"
                  class="editor"
                  [maxLength]="maxLength"
                  [readOnly]="!editMode"
                  [placeholder]="'shared.description.noDescription'|translate"
                  [modules]="{toolbar: config}"
                  format="html"
                  [(ngModel)]="description"
                  (onContentChanged)="textChanged($event)"
                  data-osp-test="rich-editor-description">
    </quill-editor>
  </div>
  <div fxFlex="10" class="editButton">
    <mat-icon *ngIf="!editMode && isActive && editingAllowed"
              matSuffix
              [matTooltip]="'shared.description.editDescription'|translate"
              (click)="editDescription()">
      mode_edit
    </mat-icon>
  </div>
</div>
<span class="hint" *ngIf="editMode && revertedChanges">
  {{'shared.description.descriptionLengthWarning'|translate}}
</span>
<div class="buttons" *ngIf="editMode" mat-dialog-actions>
  <button mat-button (click)="cancel()">{{'general.buttons.cancel'|translate}}</button>
  <button mat-raised-button
          bhTimeoutSaveButton
          [disabled]="revertedChanges"
          (throttledClick)="save()" color="primary">
    {{'general.buttons.save'|translate}}
  </button>
</div>
