import { Component } from '@angular/core';
import { BasicWidget } from '../basic-widget/basic-widget';

@Component({
  selector: 'bh-clock-widget',
  template: `
    <div class='clockWidget'>
      {{theTime}}
    </div>`,
  styles: ['.clockWidget {width: 100%}']
})
export class ClockWidgetComponent extends BasicWidget {

  public theTime = 'Uhr wird geladen';

  constructor() {
    super();
    setInterval(() => {
      this.theTime = new Date().toLocaleTimeString();
    }, 1000);
  }
}
