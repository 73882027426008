import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from '../../../../../core/keycloak';
import { EquipmentsDataSource } from '../../../shared/equipments.datasource';
import { EquipmentsService } from '../../../shared/equipments.service';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { BaseEquipmentMapComponent } from '../base/base-equipment-map.component';
import { DisplayService } from '../../../../../shared/display.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapConsts } from 'app/shared/modules/map/map.consts';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-map',
  templateUrl: 'equipment-map.component.html',
  styleUrls: ['equipment-map.component.scss']
})
export class EquipmentMapComponent extends BaseEquipmentMapComponent implements SearchFilterActivatedView, OnInit, OnDestroy {

  public readonly REFRESH_MAP_RATE_MS = 60000;
  public readonly mapSettings: MapConfiguration = this.getInitialMapSettings();
  public readonly searchSuggestionsField = '';

  constructor(protected equipmentStore: EquipmentsDataSource,
              protected dialog: MatDialog,
              protected router: Router,
              protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected equipmentService: EquipmentsService,
              protected organisationService: OrganisationsService,
              protected displayService: DisplayService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              private projectStore: ProjectDataSource,
              private contactDataSource: ContactDataSource) {
    super(authService, router, route, equipmentStore, equipmentService, organisationService, dialog, routerHistory, languageService);
  }

  public ngOnInit(): void {
    this.clearStoredLocations();
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
  }

  public onSearchFormClick(): void {
    this.updateOnSearch();
  }

  public saveMapConfigs(mapConfig: MapConfiguration): void {
    this.equipmentStore.saveMapConfiguration(mapConfig);
  }

  private clearStoredLocations(): void {
    this.equipmentStore.clearStoredLocations();
    this.projectStore.clearStoredLocations();
    this.contactDataSource.clearStoredLocations();
  }

  private getInitialMapSettings(): MapConfiguration {
    return {
      ...MapConsts.getDefaultMapConfiguration(),
      showEquipments: true,
      ...(this.equipmentStore.getMapConfiguration() || {}),
    }
  }

}
