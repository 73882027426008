<ng-container *ngIf="locations | isValidLocations; else invalidAddress">
  <google-map bhMapCustomOptions
    *ngIf="isMapAPILoaded | async"
    (mapInitialized)="mapInitialized($event)"
    [height]="'100%'"
    [width]="'100%'">

    <ng-container *ngFor="let marker of locations; let index=index">
      <ng-container *ngIf="(marker?.location | locationToLatLngLiteral) as markerPosition">
        <map-marker
          #mapMarkerComp="mapMarker"
          [position]="markerPosition"
          [icon]="marker.icon | mapIconBuilder"
          [options]="{ opacity: marker.opacity }"
          (mapClick)="markerClick(index)">
          <map-info-window *ngIf="marker.infoWindow?.content && marker.infoWindow?.isOpened"
            bhInfoWindowVisibility
            [marker]="mapMarkerComp"
            [disableAutoPan]="!singleInfoWindow"
            (closeclick)="onCloseInfoWindow(index)">
            <ng-template
              [bhInfoWindowComponentOutlet]="marker.infoWindow.content"
              [bhInfoWindowComponentOutletValue]="marker">
            </ng-template>
          </map-info-window>
        </map-marker>
        <map-circle *ngIf="marker.circle as circleOptions"
          [center]="markerPosition"
          [options]="circleOptions">
        </map-circle>
      </ng-container>
    </ng-container>

    <bh-map-movement-arrows [locations]="movements"></bh-map-movement-arrows>

    <!-- Don't remove empty "map-info-window"-->
    <!-- It helps to prevent info-window glitch during the first initialization -->
    <map-info-window></map-info-window>

  </google-map>
</ng-container>

<ng-template #invalidAddress>
  <div data-osp-test="label-invalid-address">{{'general.labels.cannotBeDisplayed.invalidAddress'|translate}}</div>
</ng-template>
