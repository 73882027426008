<mat-card fxLayout="column">
  <mat-card-content class="comment-content" fxFlex>
    <div class="comment-content__text" fxFlex="1 1 100%">{{comment?.text}}</div>
    <div class="comment-content__actions" fxFlex="1 1 90px" fxLayoutAlign="end start">
      <ng-container *ngIf="isEditable">
        <button mat-icon-button (click)="addEditComment(comment)">
          <mat-icon class="bh-mat-icon" [matTooltip]="'general.buttons.change'|translate">
            edit
          </mat-icon>
        </button>
        <button mat-icon-button (click)="deleteComment(comment)">
          <mat-icon class="bh-mat-icon" [matTooltip]="'general.buttons.delete'|translate">
            delete_forever
          </mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <div *ngIf="comment?.creationDate as creationDate">
      <span>{{'general.comment.view.addedBy'|translate}}:</span>
      <span *ngIf="isActiveUser; else notActiveUser">
        {{comment?.submitterInfo?.firstName}} {{comment?.submitterInfo?.name}}
      </span>
      <span>{{'general.at'|translate}}:</span>
      <ng-container [ngTemplateOutlet]="formatDate" [ngTemplateOutletContext]="{$implicit: creationDate}"></ng-container>
    </div>

    <div *ngIf="comment?.lastUpdateDate as lastUpdateDate">
      <span>{{'general.comment.view.lastEditedAt'|translate}}:</span>
      <ng-container [ngTemplateOutlet]="formatDate" [ngTemplateOutletContext]="{$implicit: lastUpdateDate}"></ng-container>
    </div>
  </mat-card-footer>
</mat-card>

<ng-template #notActiveUser>
  <span>
    {{comment?.submitterInfo?.status | userStatus}}
  </span>
</ng-template>

<ng-template #formatDate let-date>
  <span>
    {{ date | formatTimezoneDateTime: { dateFormat: 'dddd, d. MMM yyyy' } }}
  </span>
</ng-template>
