import { Component, OnInit } from '@angular/core';
import { ProjectViewChangeTypeOfUseStoreService } from './project-view-change-type-of-use-store.service';
import {
  ChangeTypeOfUseKey,
  IHeaderCell,
  ISearchProjectTypeOfUseEntry
} from './project-view-change-type-of-use.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs/internal/Observable';
import { LanguageService } from '../../../../../../shared/services/language.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faCaretCircleRight,
  faCheck,
  faCheckCircle,
  faCloudShowers,
  faExternalLink,
  faMinusCircle,
  faTimes,
  faTrash
} from '@fortawesome/pro-light-svg-icons';
import { faTrafficCone, faTruckPlow } from '@fortawesome/pro-duotone-svg-icons';
import { faBolt, faHourglass, faSync } from '@fortawesome/pro-regular-svg-icons';
import { TypeOfUseStatusReason } from '../../../../shared/enums/type-of-use-status-reason.enum';
import { TypeOfUseStatus } from '../../../../shared/enums/type-of-use-status.enum';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { KeycloakService } from '../../../../../../core/keycloak';
import { Router } from '@angular/router';
import { DisplayService } from '../../../../../../shared/display.service';
import { TransferItemType } from '../../../../shared/transfer/model/transfer-item-type';
import { ProjectStructureType } from 'app/modules/disposition/shared/enums/project-structure-type';

@UntilDestroy()
@Component({
  selector: 'bh-project-view-change-type-of-use',
  templateUrl: './project-view-change-type-of-use.component.html',
  styleUrls: ['./project-view-change-type-of-use.component.scss'],
  providers: [ProjectViewChangeTypeOfUseStoreService],
})
export class ProjectViewChangeTypeOfUseComponent implements OnInit {

  public changeTypeOfUsePeriods: ISearchProjectTypeOfUseEntry[] = [];
  public projectId: string;
  public ChangeTypeOfUseKey = ChangeTypeOfUseKey;
  public periods: Observable<ISearchProjectTypeOfUseEntry[]> = this.store.periods;
  public displayColumnsMap: Map<ChangeTypeOfUseKey, IHeaderCell> = this.store.displayColumnsMap;
  public displayedColumns: ChangeTypeOfUseKey[] = this.store.displayedColumns;

  public readonly faCheck: IconDefinition = faCheck;
  public readonly faTimes: IconDefinition = faTimes;
  public readonly faTrash: IconDefinition = faTrash;
  public readonly faSync: IconDefinition = faSync;
  public readonly faCloudShower: IconDefinition = faCloudShowers;
  public readonly faHourglass: IconDefinition = faHourglass;
  public readonly faBolt: IconDefinition = faBolt;
  public readonly faMinusCircle: IconDefinition = faMinusCircle;
  public readonly faCheckCircle: IconDefinition = faCheckCircle;
  public readonly faCaretCircleRight: IconDefinition = faCaretCircleRight;
  public readonly faExternalLink: IconDefinition = faExternalLink;
  public readonly faTruckPlow: IconDefinition = faTruckPlow;
  public readonly faTrafficCone: IconDefinition = faTrafficCone;

  public TransferItemType = TransferItemType;

  private hasTypeOfUseWorkflow = false;

  constructor(private store: ProjectViewChangeTypeOfUseStoreService,
              private projectStore: ProjectDataSource,
              private languageService: LanguageService,
              private authService: KeycloakService,
              private router: Router,
              private displayService: DisplayService,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToTypeOfUseWorkflow();
    this.subscribeToCurrentProject();
  }

  public onScroll(): void {
    this.store.scroll(this.projectId);
  }

  public subscribeToCurrentProject(): void {
    this.projectStore.currentProject.pipe(untilDestroyed(this))
    .subscribe(project => {
      if (
        project
        && project.projectId !== this.projectId
        && project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP
      ) {
        this.projectId = project.projectId;
        this.store.scroll(this.projectId, true);
      }
    });
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public sortData(sort: Sort): void {
    this.store.sort = sort;
    this.store.updateListing();
  }

  public resolveReasonIcon(reason: TypeOfUseStatusReason): IconDefinition {
    switch (reason) {
      case TypeOfUseStatusReason.BAD_WEATHER:
        return this.faCloudShower;
      case TypeOfUseStatusReason.ACCIDENT:
        return this.faBolt;
      case TypeOfUseStatusReason.CONSTRUCTION_DELAY:
        return this.faHourglass;
      case TypeOfUseStatusReason.OTHER:
        return this.faHourglass;
    }
  }

  public resolveStatusIcon(reason: TypeOfUseStatus): IconDefinition {
    switch (reason) {
      case TypeOfUseStatus.STANDSTILL:
        return this.faCloudShower;
      case TypeOfUseStatus.FREE_OF_CHARGE:
        return this.faMinusCircle;
      case TypeOfUseStatus.IN_USE:
        return this.faCaretCircleRight;
      case TypeOfUseStatus.READY_TO_PICKUP:
        return this.faCheckCircle;
    }
  }

  public resolveStatusClass(reason: TypeOfUseStatus): string {
    switch (reason) {
      case TypeOfUseStatus.STANDSTILL:
        return 'standstill';
      case TypeOfUseStatus.FREE_OF_CHARGE:
        return 'free-of-charge';
      case TypeOfUseStatus.IN_USE:
        return 'in-use';
      case TypeOfUseStatus.READY_TO_PICKUP:
        return 'ready-to-pickup';
    }
  }

  public navigateToEquipment(id: string, type: TransferItemType): void {
    this.displayService.displayTableAndDetails();
    if (type === TransferItemType.EQUIPMENT) {
      this.router.navigate(['/assets/equipment/list', id, 'general']);
    } else {
      this.router.navigate(['/assets/bulk-item/list', id, 'general']);
    }
  }

  public allowChangeTypeOfUse(): boolean {
    return this.hasAnyAuthority([Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE,
        Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE])
      && this.hasTypeOfUseWorkflow;
  }

  public navigateToChangeChargePeriod(): void {
    this.router.navigate(['transfer/change-type-of-use', this.projectId]);
  }

  private hasAnyAuthority(authoritiesToCheck: Authorities[]) {
    return this.authService.hasAnyAuthority(authoritiesToCheck)
  }

  private subscribeToTypeOfUseWorkflow(): void {
    this.projectStore.hasTypeOfUseWorkflow.subscribe(res => {
      this.hasTypeOfUseWorkflow = res;
    });
    this.projectStore.updateTypeOfUseWorkflow();
  }

}
