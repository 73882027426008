import { LanguageService } from 'app/shared/services/language.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { Task } from '../../contract/task/task';
import { Pageable } from '../../contract/task/pageable.interface';
import { TaskRule } from '../../contract/task/task-rule';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReminderTask } from '../../../modules/maintenance/tasks/shared/reminder-task';
import { Authorities } from '../../enums/authorities.enum';
import { Modules } from '../../enums/modules.enum';
import { KeycloakService } from '../../../core/keycloak';
import { dialogResults } from '../../enums/dialogResults.enum';

@Component({
  selector: 'bh-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  @Input() hideEquipmentColumn = false;
  @Input() tasks: Task[];
  @Input() pageable: Pageable;
  @ViewChild('taskListPaginator') paginator: MatPaginator;

  @Output() taskComplete: EventEmitter<string> = new EventEmitter();
  @Output() reminderComplete: EventEmitter<ReminderTask> = new EventEmitter();
  @Output() taskDelete: EventEmitter<string> = new EventEmitter();
  @Output() ruleUnassign: EventEmitter<TaskRule> = new EventEmitter();
  @Output() taskDetails: EventEmitter<Task> = new EventEmitter();

  public displayedColumns: string[] = [
    'icon',
    'name',
    'maintenance-type',
    'equipment',
    'due-date',
    'task-remaining',
    'task-actions'];
  public readonly MaintenanceCategory: typeof MaintenanceCategory = MaintenanceCategory;

  constructor(
      private router: Router,
      private dialog: MatDialog,
      private authService: KeycloakService,
      private languageService: LanguageService) {
  }

  public get allowedToSee(): boolean {
    return this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_VIEW) &&
        this.authService.hasModule(Modules.SERVICE);
  }

  public get allowedToManage(): boolean {
    return this.authService.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE) &&
        this.authService.hasModule(Modules.SERVICE);
  }

  public ngOnInit(): void {
    if (this.hideEquipmentColumn) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('equipment'), 1);
    }
    if (!this.allowedToSee && !this.allowedToManage) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('task-actions'), 1);
    }
  }

  public isCategory(task: Task, category: MaintenanceCategory): boolean {
    return task.category === category;
  }

  public completeReminder(task: ReminderTask): void {
    this.reminderComplete.emit(task);
  }

  public completeTask(taskId: string): void {
    this.taskComplete.emit(taskId);
  }

  public deleteReminder(taskId: string): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.getConfirmationDialog(
        this.translate('shared.maintenance.task.deleteReminderTitle'),
        this.translate('shared.maintenance.task.deleteReminderMessage')
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        this.taskDelete.emit(taskId);
      }
    });
  }

  public unassignRule(task: Task, ruleId: string): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.getConfirmationDialog(
        this.translate('shared.maintenance.task.unassignRuleTitle'),
        this.translate('shared.maintenance.task.unassignRuleMessage')
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        this.ruleUnassign.emit({task: task, ruleId: ruleId});
      }
    });
  }

  public notMaxDate(dueDate: string): boolean {
    if (!dueDate) {
      return true;
    }

    return dueDate.indexOf('3000-01-01') === -1;
  }

  public navigateToEquipmentTasks(equipmentId: string): void {
    this.router.navigate(['assets', 'equipment', 'list', equipmentId, 'tasks']);
  }

  public viewRule(task: Task): void {
    this.taskDetails.emit(task);
  }

  public showMenu(task: Task): boolean {
    if (this.isCategory(task, MaintenanceCategory.REMINDER)) {
      return this.allowedToManage;
    }

    return this.allowedToManage || this.allowedToSee;
  }

  private getConfirmationDialog(confirmTitle: string, confirmMessage: string): MatDialogRef<ConfirmationDialogComponent> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = confirmTitle;
    dialogRef.componentInstance.confirmMessage = confirmMessage;
    return dialogRef;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
