import { TransportTimelineType } from './../../enums/transport-timeline-type.enum';
import { DateRange } from 'app/shared/components/date-range-select-component/date-range';
import { DatesService } from 'app/shared/services/dates.service';
import moment from 'moment';
import { TransportTaskEvent } from './transport-task-event.class';
import { Utils } from 'app/shared/utils';

export class TransportTimelineDateLimitFactory {
  private static endDateOffsetMinuteType = 30;
  private static shiftStartDateDayTypeMs = 7 * 60 * 60 * 1000;        // 7 hours
  private static shiftEndDateDayTypeMs = -1 * (7 * 60 * 60 * 1000);   // -7 hours
  private static readonly durationRegex = /PT(?!$)((\d+)H)?((\d+)M)?/;

  public static getDefaultDateRange(type: TransportTimelineType, date: Date, duration?: string): DateRange {
    return this.durationRegex.test(duration)
      ? this.getDefaultDateRangeWithDuration(type, date, duration)
      : this.getDefaultDateRangeWithoutDuration(type, date);
  }

  private static getDefaultDateRangeWithDuration(type: TransportTimelineType, date: Date, duration: string): DateRange {
    const startDate = this.normalizeDefaultStartDate(type, date);
    const endDate = moment(startDate).add(duration).toDate();
    return new DateRange(startDate, endDate);
  }

  private static getDefaultDateRangeWithoutDuration(type: TransportTimelineType, date: Date): DateRange {
    const endDate = type === TransportTimelineType.DAY
      ? moment(date).add(this.endDateOffsetMinuteType, 'm').toDate()
      : date;
    return new DateRange(this.normalizeDefaultStartDate(type, date), this.normalizeDefaultEndDate(type, endDate));
  }

  public static normalizeStartDate(type: TransportTimelineType, event: TransportTaskEvent): Date {
    switch (type) {
      case TransportTimelineType.MONTH:
        return new Date(DatesService.sameTimeZoneAtStartDateUTC(event.start_date));
      case TransportTimelineType.WEEK:
        return this.normalizeStartDateHourBased(event);
      case TransportTimelineType.DAY:
      default:
        return new Date(DatesService.sameTimeZoneAtStartHalfHourDateUTC(event.start_date));
    };
  }

  public static normalizeEndDate(type: TransportTimelineType, event: TransportTaskEvent): Date {
    let resultDate: Date;
    switch (type) {
      case TransportTimelineType.MONTH: {
        resultDate = new Date(DatesService.sameTimeZoneAtEndDateUTC(event.end_date));
        break;
      }
      case TransportTimelineType.WEEK: {
        resultDate = this.normalizeEndDateHourBased(event);
        break;
      }
      case TransportTimelineType.DAY:
      default: {
        resultDate = new Date(DatesService.sameTimeZoneAtEndHalfHourDateUTC(event.end_date));
        break;
      }
    };

    resultDate.setUTCSeconds(0);
    resultDate.setUTCMilliseconds(0);
    return resultDate;
  }

  private static normalizeStartDateHourBased(event: TransportTaskEvent): Date {
    const result = new Date(DatesService.sameTimeZoneRoundHourDateUTC(event.start_date));
    return result < event.end_date
      ? result
      : new Date(DatesService.sameTimeZoneAtStartHourDateUTC(event.start_date));
  }

  private static normalizeEndDateHourBased(event: TransportTaskEvent): Date {
    const result = new Date(DatesService.sameTimeZoneRoundHourDateUTC(event.end_date, true));
    return result > event.start_date
      ? result
      : new Date(DatesService.sameTimeZoneAtEndHourDateUTC(event.start_date));
  }

  public static roundDateLimits(type: TransportTimelineType, event: TransportTaskEvent): TransportTaskEvent {
    switch (type) {
      case TransportTimelineType.MONTH:
        return this.roundDateLimitsDayBased(event);
      case TransportTimelineType.WEEK:
        return this.roundDateLimitsHourBased(event);
      case TransportTimelineType.DAY:
      default:
        return this.roundDateLimitsHalfHourBased(event);
    };
  }

  private static roundDateLimitsDayBased(event: TransportTaskEvent): TransportTaskEvent {
    const result = { ...event };
    result.start_date = new Date(DatesService.sameTimeZoneRoundDayDateUTC(event.start_date));
    result.end_date = moment(result.start_date).add(Utils.differenceMs(event.start_date, event.end_date)).toDate();
    return result;
  }

  private static roundDateLimitsHourBased(event: TransportTaskEvent): TransportTaskEvent {
    const result = { ...event };
    result.start_date = new Date(DatesService.sameTimeZoneRoundHourDateUTC(event.start_date));
    result.end_date = moment(result.start_date).add(Utils.differenceMs(event.start_date, event.end_date)).toDate();
    return result;
  }

  private static roundDateLimitsHalfHourBased(event: TransportTaskEvent): TransportTaskEvent {
    const result = { ...event };
    result.start_date = new Date(DatesService.sameTimeZoneRoundHalfHourDateUTC(event.start_date));
    result.end_date = moment(result.start_date).add(Utils.differenceMs(event.start_date, event.end_date)).toDate();
    return result;
  }

  private static normalizeDefaultStartDate(type: TransportTimelineType, date: Date): Date {
    return type === TransportTimelineType.MONTH || type === TransportTimelineType.WEEK
      ? this.shiftDateMs(new Date(DatesService.sameTimeZoneAtStartDateUTC(date)), this.shiftStartDateDayTypeMs)
      : new Date(DatesService.sameTimeZoneAtStartHalfHourDateUTC(date));
  }

  private static normalizeDefaultEndDate(type: TransportTimelineType, date: Date): Date {
    return type === TransportTimelineType.MONTH || type === TransportTimelineType.WEEK
      ? this.shiftDateMs(new Date(DatesService.sameTimeZoneAtEndDateUTC(date)), this.shiftEndDateDayTypeMs)
      : new Date(DatesService.sameTimeZoneAtEndHalfHourDateUTC(date));
  }

  private static shiftDateMs(date: Date, shiftMs: number): Date {
    return new Date(date.getTime() + shiftMs);
  }
}
