import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MaintenanceTaskCompletedSearch } from 'app/modules/maintenance/tasks/shared/maintenance-task-completed-search.interface';
import { MaintenanceCompletedColumnDefinitionService } from 'app/modules/maintenance/tasks/shared/service/maintenance-completed-column-definition.service';
import { ColumnDefinition } from 'app/shared/column-definition';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { COLUMN_TYPE } from 'app/shared/constants/column-definition-constants';

@Component({
  selector: 'bh-last-completed-tasks-table',
  templateUrl: './last-completed-tasks-table.component.html',
  styleUrls: ['./last-completed-tasks-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastCompletedTasksTableComponent implements OnInit {

  @Input() data: MaintenanceTaskCompletedSearch[] = [];
  @Output() navigateToTaskCompleted = new EventEmitter<string>();
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly COLUMN_DEFINITION = { ...GENERAL_COLUMN_DEF, ...MAINTENANCE_TASK_COLUMN_DEF };
  public columnDefs: ColumnDefinition[] = [];
  public displayedColumns: string[] = [];

  constructor(private columnDefinitionService: MaintenanceCompletedColumnDefinitionService) {}

  public ngOnInit(): void {
    this.columnDefs = this.columnDefinitionService.availableLastCompletedTasksWidgetColumns;
    this.displayedColumns = this.columnDefs.map(({ cdkColumnDef }) => cdkColumnDef).filter(Boolean);
  }

  public onNavigateToTaskCompleted(task: MaintenanceTaskCompletedSearch): void {
    if (task?.id) {
      this.navigateToTaskCompleted.emit(task.id);
    }
  }
}
