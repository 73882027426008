import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LanguageService } from '../../../../shared/services/language.service';
import { KeycloakService } from '../../../../core/keycloak';
import { TransportListDatasource } from '../../shared/services/transport-list.datasource';
import { DatesService } from '../../../../shared/services/dates.service';
import { AddressService } from '../../../../shared/services/address.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TransportationTasksAddEditComponent } from '../transportation-tasks-add-edit/transportation-tasks-add-edit.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PageType } from 'app/shared/enums/page-type.enum';
import { PageSegment } from 'app/shared/enums/page-segment.enum';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { debounceTime, delay, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { ColumnDefinition } from '../../../../shared/column-definition';
import { TransportColumnService } from '../../shared/services/transport-column.service';
import { COLUMN_TYPE } from '../../../../shared/constants/column-definition-constants';
import { DisplayService } from '../../../../shared/display.service';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { environment } from '../../../../../environments/environment';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { TransportListExportDialogComponent } from './transport-list-export-dialog/transport-list-export-dialog.component';
import { TableConfigurationData, TableConfigurationDialogComponent } from '../../../../shared/components/table-configuration-dialog/table-configuration-dialog.component';
import { TRANSPORT_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { MatDrawer } from '@angular/material/sidenav';
import { TransportSearch } from '../../contracts/transport/transport-search.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TransportTaskState } from '../../shared/enums/transport-task-status.enum';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';

@UntilDestroy()
@Component({
  selector: 'bh-transport-list',
  templateUrl: './transport-list.component.html',
  styleUrls: ['./transport-list.component.scss']
})
export class TransportListComponent extends RoleAuthorityGuardsComponent implements SearchFilterActivatedView, OnInit, OnDestroy {
  public readonly transports = this.transportDataSource.transports;
  public readonly COLUMN_TYPE = COLUMN_TYPE;
  public readonly dueDateColumnDefinition = TRANSPORT_COLUMN_DEF.DUE_DATE;
  public readonly currentTransport = this.transportDataSource.currentTransport;
  public readonly pagination = this.transportDataSource.pagination;
  public readonly faFileExcel: IconDefinition = faFileExcel;
  public displayedColumns: string[];
  public selectedColumns: ColumnDefinition[];
  public selectedTransports: Set<string> = new Set();
  public multiselectActive: boolean;
  public readonly authorities = Authorities;
  public readonly pageType = PageType;
  private readonly baseComponentPath = '/transportation/list';
  public readonly filters: FilterType[] = this.transportDataSource.filters;
  public readonly onFiltersUpdated: EventEmitter<any> = this.transportDataSource.onFiltersUpdated;
  public readonly onTotalCountUpdated: EventEmitter<number> = new EventEmitter<number>();
  public routeButton: ButtonInfo = null;
  public searchForm: UntypedFormGroup;
  public readonly searchSuggestionsField = 'transportListSuggestions';
  private readonly toggleColumn: ColumnDefinition = {
    cdkColumnDef: 'select',
    type: COLUMN_TYPE.SELECT,
    title: 'select',
    readableName: 'select',
    valueCallback: null,
  };
  private detailsColumn: ColumnDefinition = null;
  private detailsColumnIndex = -1;
  @ViewChild('multiMenu', { static: true }) private multiMenu: MatDrawer;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  constructor(
    public displayService: DisplayService,
    protected authService: KeycloakService,
    protected addressService: AddressService,
    private columnService: TransportColumnService,
    public transportDataSource: TransportListDatasource,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(authService);
  }

  ngOnInit(): void {
    this.multiselectActive = false;
    this.handleRouterParams();
    this.transportDataSource.updateFilters();
    this.transportDataSource.updateListing();
    this.initListeners();
  }

  ngOnDestroy(): void {
    if (this.multiselectActive) {
      this.selectedTransports.clear();
      this.selectedColumns.shift();
      this.addDetailsColumn();
      this.columnService.selectColumns(this.selectedColumns);
      this.multiMenu.close();
    }
  }

  private initListeners(): void {
    this.selectedColumnsListener();
    this.transportBoardViewListPageSizeListener();
    this.displayedColumnsListener();
    this.totalCountListener();
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
    .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
    .subscribe(term => this.updateOnSearch(term));

    this.searchForm.patchValue({terms: this.transportDataSource.searchTerm}, { emitEvent: false });
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public isEndOfDaySameTimeZone(date: string): boolean {
    return date && DatesService.isEndOfDaySameTimeZone(date);
  }

  public openDetails(): void {
    if (!this.multiselectActive) {
      this.emptySelection();
      this.displayService.displayTableAndDetails();
    }
  }

  private handleRouterParams(): void {
    if (this.route.snapshot.data['create']) {
      this.openTransportAddEditDialog();
    } else if (!this.isCurrentTransportSelected()) {
      this.transportDataSource.selectCurrentTransportOrDefault();
    } else {
      this.displayService.displayTableAndDetails();
    }
  }

  private openTransportAddEditDialog(): void {
    this.dialog.open(TransportationTasksAddEditComponent, { disableClose: true })
    .afterClosed()
    .pipe(
      map(({ transportId }) => transportId),
      untilDestroyed(this))
    .subscribe((transportId) => {
      const urlAfterDialogClose = [PageSegment.TRANSPORT_ROOT, PageSegment.TRANSPORT_LIST];
      if (transportId) {
        urlAfterDialogClose.push(transportId);
        this.transportDataSource.updateListing(0);
      }
      this.router.navigate(urlAfterDialogClose);
    });
  }

  private isCurrentTransportSelected(): boolean {
    return Boolean(this.router.url !== this.baseComponentPath);
  }

  public onPaginateChange({ pageIndex, pageSize }: PageEvent): void {
    this.transportDataSource.updateListing(pageIndex, pageSize);
  }

  public onSortChange(event: Sort): void {
    this.transportDataSource.sort = event.direction ? event : null;
    this.transportDataSource.updateListing(0);
  }

  public openConfiguration(): void {
    const tableConfigurationData: TableConfigurationData = {
      columnService: this.columnService,
      multiselectActive: this.multiselectActive
    };
    const dialogRef = this.dialog.open(TableConfigurationDialogComponent, {
      data: tableConfigurationData,
      restoreFocus: false,
      autoFocus: false,
    });
    dialogRef.afterClosed()
    .pipe(
      untilDestroyed(this),
      filter(Boolean))
    .subscribe((columns: ColumnDefinition[]) => {
      this.columnService.selectColumns(columns, null, {
        transportTaskPaginatorOptions: { boardViewListPageSize: this.paginator.pageSize }
      });
    });
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.transportDataSource.updateFilterParams(commands);
    this.transportDataSource.updateListing(0);
  }

  private selectedColumnsListener(): void {
    this.columnService.selectedColumns
    .pipe(untilDestroyed(this))
    .subscribe((selectedColumns: ColumnDefinition[]) => this.selectedColumns = [...selectedColumns]);
  }

  private transportBoardViewListPageSizeListener(): void {
    this.columnService.transportBoardViewListPageSize
    .pipe(untilDestroyed(this))
    .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
  }

  private displayedColumnsListener(): void {
    this.columnService.displayedColumns
    .pipe(untilDestroyed(this))
    .subscribe((columns: string[]) => this.displayedColumns = [...columns]);
  }

  private totalCountListener(): void {
    this.transportDataSource.totalCount
      .pipe(untilDestroyed(this))
      .subscribe(count => this.onTotalCountUpdated.emit(count));
  }

  private updateOnSearch(searchTerm: string): void {
    this.transportDataSource.searchTerm = searchTerm;
    this.transportDataSource.updateListing(0);
  }

  public openTransportTaskListExport(): void {
    this.dialog.open(TransportListExportDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { selectedColumns: this.selectedColumns },
    });
  }

  public onCheckboxToggle(transport: TransportSearch, event: MatCheckboxChange): void {
    if (event.checked) {
      this.checkMultiselect(transport);
    } else {
      this.selectedTransports.delete(transport.transportId);
    }
  }

  public checkMultiselect(transport: TransportSearch): void {
    if (this.multiselectActive) {
      if (this.selectedTransports.has(transport.transportId)) {
        this.selectedTransports.delete(transport.transportId);
      } else {
        this.selectedTransports.add(transport.transportId);
      }
    }
  }

  public emptySelection(): void {
    this.selectedTransports.clear();
  }

  public removeDetailsColumn(): void {
    let index = this.selectedColumns.findIndex(column => column.type === COLUMN_TYPE.DETAILS);
    if (index !== -1) {
      this.detailsColumn = this.selectedColumns.splice(index, 1).pop();
      this.detailsColumnIndex = index - 1;
    }
  }

  public addDetailsColumn(): void {
    if (this.detailsColumnIndex !== -1) {
      this.selectedColumns.splice(this.detailsColumnIndex, 0, this.detailsColumn);
    }
  }

  public toggleMultiselect(): void {
    this.multiselectActive = !this.multiselectActive;
    if (this.multiselectActive) {
      this.displayService.displayTableFullscreen();
      this.selectedColumns.unshift(this.toggleColumn);
      this.removeDetailsColumn();
    } else {
      this.emptySelection();
      this.selectedColumns.shift();
      this.addDetailsColumn();
      this.multiMenu.toggle();
    }
    this.columnService.selectColumns(this.selectedColumns);
  }

  public elementsSelected(): boolean {
    return this.selectedTransports.size > 0;
  }

  public selected(transport: TransportSearch): boolean {
    return this.selectedTransports.has(transport.transportId);
  }

  public inActiveTransport(state: TransportTaskState): boolean {
    return state !== TransportTaskState.DONE;
  }

  public selectAll(): void {
    this.transports.pipe(first(), untilDestroyed(this)).subscribe(result => {
      for (let transport of result) {
        if (!this.inActiveTransport(transport.state) && !this.selectedTransports.has(transport.transportId)) {
          this.selectedTransports.add(transport.transportId);
        }
      }
    });
  }

  public openLabelDialogue(): void {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate(
      'modules.transportation.transportStateChange.warningMoveDoneToArchiveForSelected',
      {
        oldState: this.translate('modules.transportation.transportState.done'),
        newState: this.translate('modules.transportation.transportState.archived'),
      });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === dialogResults.YES) {
        this.transportDataSource.moveSelectedDoneToArchive([...this.selectedTransports])
        .pipe(delay(environment.DELAY_SHORTEST))
        .subscribe((res) => {
          this.emptySelection();
          this.transportDataSource.updateCurrentAndListing();
        });
      }
      this.columnService.selectColumns(this.selectedColumns);
    });
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
