import { Component, OnInit } from '@angular/core';
import { DispositionDataSource } from '../../shared/disposition.datasource';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SearchEquipmentTransport } from '../../../equipment/contract/search-equipment-transport';
import { DatesService } from '../../../../shared/services/dates.service';
import { ReportsDataSource } from '../../../reports/shared/services/reports.datasource';
import { ReportGenerateTransportOrdersCommand } from '../../../reports/contract/report-transport-orders-command';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ViewEquipment } from '../../../equipment/contract/view-equipment.interface';
import { EquipmentsService } from '../../../equipment/shared/equipments.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Utils } from '../../../../shared/utils';
import { DateUtils } from '../../../../shared/dateUtils';
import { FileUtils } from '../../../../shared/fileUtils';
import { RouterHistory } from '../../../../shared/router-history';
import { ThumbnailSize } from '../../../../shared/components/test-place/secured-image/enums/thumbnail-size.enum';

@Component({
  selector: 'bh-disposition-board-list',
  templateUrl: './disposition-board-list.component.html',
  styleUrls: ['./disposition-board-list.component.scss'],
})
export class DispositionBoardListComponent extends GuardedNavigableInputComponent implements OnInit {

  public startDateControl: FormControl;
  public endDateControl: FormControl;
  public readonly ThumbnailSize = ThumbnailSize;

  public equipmentTransports: SearchEquipmentTransport[];
  public etSubEquipments: ViewEquipment[];
  private searchForm: FormGroup;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private formBuilder: FormBuilder,
              private reportStore: ReportsDataSource,
              public dispositionStore: DispositionDataSource,
              private equipmentsService: EquipmentsService) {
    super(authService, router, route, routerHistory);
  }

  private get startDate(): Date {
    return this.startDateControl.value;
  }

  public ngOnInit(): void {
    this.buildSearchForm();
    this.onSearchFormSubmit();
    this.setEquipmentsTransport();
  }

  public onSearchFormSubmit(): void {
    if (this.searchForm.valid) {
      this.dispositionStore.searchTransportStart = this.startDate;
      this.dispositionStore.searchTransportEnd = DateUtils.endDateOrDefault(this.endDateControl.value);
      this.dispositionStore.getEquipmentsTransportSearch();
    }
  }

  public print(): void {
    let cmd: ReportGenerateTransportOrdersCommand = new ReportGenerateTransportOrdersCommand();

    cmd.equipmentIds = this.getUniqueEquipmentIds();
    cmd.from = DatesService.sameTimeZoneAtStartDateUTC(this.startDate)
    cmd.until = DatesService.sameTimeZoneAtEndDateUTC(DateUtils.endDateOrDefault(this.endDateControl.value));
    cmd.timeZoneName = DatesService.getLocalTimeZone();

    this.reportStore.generateTransportOrdersReport(cmd)
    .subscribe((res) => FileUtils.downloadFile(res));
  }

  public getSubEquipments(et: SearchEquipmentTransport): void {
    let equipmentIds: string[] = [];
    equipmentIds.push(et.equipmentId);

    if (et.numberOfSubequipments > 0) {
      this.etSubEquipments = [];
      this.equipmentsService.getSubEquipments(equipmentIds).subscribe(res => this.etSubEquipments = res);
    }
  }

  // Detect if arrow icon indicator is shown
  // Inspired by:
  // https://github.com/angular/material2/issues/8190
  public expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
    event.stopPropagation();

    if (!this.isExpansionIndicator(event.target)) {
      matExpansionPanel.close();
    }
  }

  private getUniqueEquipmentIds(): string[] {
    return this.equipmentTransports
    .map((transport: SearchEquipmentTransport) => transport.equipmentId)
    .filter(Utils.uniqueFilter);
  }

  private buildSearchForm(): void {
    this.startDateControl = new FormControl(new Date());
    this.endDateControl = new FormControl(null);

    this.searchForm = this.formBuilder.group({
      transportStart: this.startDateControl,
      transportEnd: this.endDateControl
    });
  }

  private setEquipmentsTransport(): void {
    this.dispositionStore.equipmentsTransport.subscribe(res => {
      if (res) {
        this.equipmentTransports = res;
      }
    });
  }

  private isExpansionIndicator(target: any): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return (target.classList && target.classList.contains(expansionIndicatorClass));
  }
}
