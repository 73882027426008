import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewGlobalGeofence } from '../contract/view-global-geofence.interface';
import { CreateGlobalGeofenceCommand } from '../contract/create-global-geofence-command';
import { DeleteGlobalGeofenceCommand } from '../contract/delete-global-geofence-command';

@Injectable()
export class GlobalGeofencesService {

  private url = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/globalgeofences';

  constructor(private http: HttpClient) {
  }

  getGlobalGeofences(): Observable<ViewGlobalGeofence[]> {
    return this.http.get<ViewGlobalGeofence[]>(`${this.url}`);
  }

  createGlobalGeofence(command: CreateGlobalGeofenceCommand): Observable<string> {
    return this.http.post(`${this.url}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteGlobalGeofence(command: DeleteGlobalGeofenceCommand): Observable<string> {
    return this.http.post(`${this.url}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

}
