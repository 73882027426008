import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { IconSize } from '../type/icon-size.type';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'bh-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @HostBinding('class')
  protected cssClass = 'osp-icon';

  public readonly defaultSize = 'md';
  @Input() fontSet: string;
  @Input() icon: string | IconDefinition;
  @Input() size: IconSize = this.defaultSize;
  @Input() color: ThemePalette;
  @Input() hover = false;
}
