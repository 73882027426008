import {
  DigitalMatterProfileTranslation
} from '../../../../shared/assign-digital-matter-unit/DigitalMatterProfileTranslation';

export class UpdateDigitalMatterProfileCommand {
  constructor(public profileId: string,
              public templateId: string,
              public translations: { [key: string]: DigitalMatterProfileTranslation }) {
  }
}
