import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Languages } from '../../../../shared/enums/languages.enum';
import { Observable } from 'rxjs';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { environment } from '../../../../../environments/environment';
import { getHttpParameters } from '../../../../shared/utils';
import {
  AdditionalField,
  AdditionalFieldEntity,
  AdditionalFieldSelectOption,
  AdditionalFieldType,
  AdditionalFieldCategory,
} from 'app/modules/osp-ui/rich-components/bh-additional-field/additional-field';

export type BaseAdditionalFieldRequest = {
  additionalFieldKey: string;
  additionalFieldName: { [key in Languages]?: string };
  additionalFieldType: AdditionalFieldType;
  additionalFieldUnit?: string;
  additionalFieldDefaultValue?: string;
  additionalFieldSelectOptions?: AdditionalFieldSelectOption[];
}

export type CreateAdditionalFieldRequest = BaseAdditionalFieldRequest & {
  additionalFieldEntity: AdditionalFieldEntity;
  additionalFieldCategory: AdditionalFieldCategory;
};

export type UpdateAdditionalFieldRequest = BaseAdditionalFieldRequest & {
  additionalFieldId: string;
};

export type DeleteAdditionalFieldRequest = {
  additionalFieldId: string;
};

export type SearchAdditionalFieldRequest = {
  page?: number;
  size?: number
  sort?: any
};

@Injectable({
  providedIn: 'root'
})
export class AdditionalFieldsService {

  private url = environment.APP_CORE_DATA_SERVICE_BASE_URL + '/api/v1/additional-fields';

  constructor(private http: HttpClient) {}

  public getAll(request?: SearchAdditionalFieldRequest): Observable<PagedResponse<AdditionalField>> {
    let params = getHttpParameters(request);
    return this.http.get<PagedResponse<AdditionalField>>(`${this.url}/all`, {params});
  }

  public create(request: CreateAdditionalFieldRequest): Observable<string> {
    return this.http.post(`${this.url}/create`, JSON.stringify(request), {responseType: 'text'});
  }

  public update(request: UpdateAdditionalFieldRequest): Observable<string> {
    return this.http.post(`${this.url}/update`, JSON.stringify(request), {responseType: 'text'});
  }

  public delete(request: DeleteAdditionalFieldRequest): Observable<string> {
    return this.http.post(`${this.url}/delete`, JSON.stringify(request), {responseType: 'text'});
  }

  public isAdditionalFieldKeyInUse(additionalFieldKey: string,
                                   additionalFieldEntity: AdditionalFieldEntity,
                                   additionalFieldId: string): Observable<boolean> {

    let params = getHttpParameters({
      'additionalFieldId': additionalFieldId,
      'additionalFieldKey': additionalFieldKey,
      'additionalFieldEntity': additionalFieldEntity
    });

    return this.http.get<boolean>(this.url + '/key-in-use', {params});
  }
}
