import { AssetDocumentType } from './document-type.enum';

export class UpdateEquipmentDocumentDescriptionCommand {
  public constructor(
    public equipmentId: string,
    public documentKey: string,
    public documentDescription: string,
    public documentType: AssetDocumentType) {
  }
}
