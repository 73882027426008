import { Chip } from 'app/shared/contract/chip';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { FilterValue } from 'app/shared/contract/filter/filter-value';
import { FilterChipsConverterBase } from './base/filter-chips-converter-base.class';

export class FilterChipsConverterOrganisation extends FilterChipsConverterBase {
  private readonly chipsMaxAmount = 10;

  protected convertFromFilterType(filterType: FilterType): Chip[] {
    return this.getChips(filterType);
  }

  private getChips(filterType: FilterType): Chip[] {
    const selectedFilters = this.getSelectedFiltersList(filterType);
    return selectedFilters.length > this.chipsMaxAmount
      ? [this.getFirstAncestorChip(selectedFilters)]
      : this.getActualChips(selectedFilters);
  }

  private getSelectedFiltersList(filterType: FilterType): FilterValue[] {
    return filterType.groups
      .map(({ filters }) => filters.filter(({ value }) => Boolean(value)))
      .flat();
  }

  private getFirstAncestorChip(filterValues: FilterValue[]): Chip {
    const firstAncestor: FilterValue = filterValues.find(({ parentStoreName }) => !Boolean(parentStoreName))
      || filterValues[0];
    return this.createChipFilterValue(firstAncestor, { subChips: this.getActualChips(filterValues) });
  }

  private getActualChips(filterValues: FilterValue[]): Chip[] {
    return filterValues.map(filter => this.createChipFilterValue(filter));
  }

}
