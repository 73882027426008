<div class="m-view-list-inputs m-view-list-inputs-overflow assignments">
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
    <mat-card class="assignments-card"
              *ngFor="let assignment of equipmentStore.assignments | async">
      <mat-card-content>
        <p *ngIf="assignment.unknownProject" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <mat-icon [matTooltip]="'modules.equipment.equipmentDetailDisposition.projectBelongsToOrganization'|translate">
            warning
          </mat-icon>
          <span>
            <div><b>{{assignment.projectName}}</b></div>
            <div>
              {{assignment.startDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()}} -
              {{assignment.endDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()}}
            </div>
          </span>
        </p>
        <p *ngIf="!assignment.unknownProject">
          <span>
            <div class="link" (click)="navigateToProjectAssignments(assignment.projectId)">
              <b>{{assignment.projectName}}</b>
            </div>
            <div>
              {{assignment.startDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()}}
              - {{assignment.endDate | date:'EEEE, d. MMMM y':'':getCurrentLocale()}}
            </div>
          </span>
        </p>
        <bh-assignment-comment [assignment]="assignment"></bh-assignment-comment>
        <div fxLayout="row" fxLayoutAlign="end end" *ngIf="!assignment.unknownProject">
            <span class="submission-meta-data">
              {{'general.labels.scheduledBy'|translate}}: {{assignment.submittedBy}}
              {{'general.on'|translate}}: {{assignment.submitDate | date:'EEEE, d. MMM y':'':getCurrentLocale()}}
            </span>
        </div>
      </mat-card-content>
    </mat-card>

    <div class='no-disposition-found' *ngIf="(equipmentStore.assignments | async) === null">
      <quill-editor
        [readOnly]=true
        [placeholder]="'modules.equipment.equipmentDetailDisposition.noData' | translate"
        [modules]="{toolbar: false}"
        format="html"
      >
      </quill-editor>
    </div>

  </div>
</div>
