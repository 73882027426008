import { ViewEmployee } from './view-employee.interface';
import { SearchEmployee } from './search-employee.interface';

export class DispositionEmployee {

    static fromViewEmployee(viewEmployee: ViewEmployee): DispositionEmployee {
        return new DispositionEmployee(viewEmployee.employeeId,
            viewEmployee.employeeName,
            viewEmployee.employeeFirstName,
            null);
      }

    static fromSearchEmployee(searchEmployee: SearchEmployee): DispositionEmployee {
      return new DispositionEmployee (searchEmployee.employeeId,
            searchEmployee.employeeName,
            searchEmployee.employeeFirstName,
            searchEmployee.teamName);
    }

    constructor(public employeeId: string,
        public employeeName: string,
        public employeeFirstName: string,
        public teamName: string) {}
}
