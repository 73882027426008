import { HistoryEntry } from './history-entry';
import { SearchEquipment } from '../../../contract/search-equipment.interface';

export class RentalEquipmentEntry {
  rentalHistory: HistoryEntry[] = [];

  public static from(equipment: SearchEquipment) {
    return new RentalEquipmentEntry(equipment.organisationName, equipment);
  }

  private constructor(
    public organisationName?: string,
    public equipment?: SearchEquipment) {}

}
