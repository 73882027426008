import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewManufacturer } from '../contract/view-manufacturer.interface';
import { CreateManufacturerCommand } from '../contract/create-manufacturer-command';
import { DeleteManufacturerCommand } from '../contract/delete-manufacturer-command';
import { UpdateManufacturerCommand } from '../contract/update-manufacturer-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { getHttpParameters } from '../../../shared/utils';

@Injectable()
export class ManufacturerService {

  private url = environment.APP_EQUIPMENT_SERVICE_BASE_URL + '/api/v1/manufacturers';

  constructor(private http: HttpClient) {
  }

  getManufacturers(searchParams: Object): Observable<PagedResponse<ViewManufacturer>> {
    const params = getHttpParameters(searchParams);
    return this.http.get<PagedResponse<ViewManufacturer>>(`${this.url}`, { params });
  }

  getManufacturer(manufacturerId: string): Observable<ViewManufacturer> {
    return this.http.get<ViewManufacturer>(this.url + '/' + manufacturerId);
  }

  addManufacturer(command: CreateManufacturerCommand) {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  updateManufacturer(command: UpdateManufacturerCommand) {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  deleteManufacturer(command: DeleteManufacturerCommand) {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public manufacturerNameInUse(name: string): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams({encoder: new CustomEncoder()})
      .set('name', name),
    };

    return this.http.get<boolean>(this.url + '/manufacturer-name-in-use', httpOptions);
  }

  public manufacturerInUse(manufacturerId: string): Observable<boolean> {
    let params = getHttpParameters({id: manufacturerId});
    return this.http.get<boolean>(this.url + '/manufacturer-in-use', {params: params});
  }

}
