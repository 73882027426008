import { ConnectorInformation } from '../connector-information';
import { ScheduleInformation } from '../schedule-information';
import { MailConnectionInformation } from './mail-connection-information';

export class CreateMailConnectorCommand {

  connector: ConnectorInformation;
  schedule: ScheduleInformation;
  connection: MailConnectionInformation;
}

