<div class="customer-move">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title><mat-icon class="import-export-icon">import_export</mat-icon>
    {{'modules.organisation.subCustomer.changeParent'|translate}}</h1>
  <mat-dialog-content>
    <p>{{'modules.organisation.subCustomer.moveToNewParent'|translate}}</p>
    <form [formGroup]="customerMoveForm" novalidate>
      <mat-form-field>
        <mat-label>{{'modules.organisation.subCustomer.parentCustomer'|translate|uppercase}}</mat-label>
        <mat-select formControlName="newParentCustomerId" required>
          <mat-option [value]="rootLevel">
            ({{'general.without'|translate}} {{'modules.organisation.subCustomer.parentCustomer'|translate}})
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.customerId">
            {{customer.customerName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="newParentCustomerId.hasError('required') && !newParentCustomerId.pristine">
          {{'shared.validation.required'|translate:{ value:'modules.organisation.subCustomer.parentCustomer'|translate } }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button mat-button mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()">{{'general.buttons.save'|translate}}</button>
  </mat-dialog-actions>
</div>
