import { AlarmSettingsFieldData } from '../../../contract/alarm-field-data.type';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { AlarmRuleControl } from '../../../contract/alarm-rule-control.enum';

export class AlarmSettingsBase {
  public readonly controlNames = AlarmRuleControl;

  public settingsForm: UntypedFormGroup;
  protected alarmSettingsFieldData: AlarmSettingsFieldData = {};

  public get limitControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_LIMIT);
  }

  public get intervalControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_INTERVAL);
  }

  public get geofenceControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_GLOBAL_GEOFENCE);
  }

  public get geofenceTriggerControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_GEOFENCE_TRIGGER);
  }

  public get hourControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_TIMEFENCING_HOURS);
  }
  public get distanceControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_TIMEFENCING_DISTANCE);
  }
  public get weekDayControl(): AbstractControl {
    return this.settingsForm.get(this.controlNames.SETTINGS_TIMEFENCING_WEEK_DAY);
  }

  constructor() {}

  public getFieldLabel(controlName: AlarmRuleControl): string {
    return this.alarmSettingsFieldData && this.alarmSettingsFieldData[controlName] ? this.alarmSettingsFieldData[controlName].label : ''
  }

  public getFieldExplanation(controlName: AlarmRuleControl): string {
    return this.alarmSettingsFieldData && this.alarmSettingsFieldData[controlName]
      ? this.alarmSettingsFieldData[controlName].explanation
      : '';
  }
}
