<mat-card fxFlexFill class="list-view-main__right" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-main__right-header disposition-board-list-header" fxFlex="60px" fxLayout="row"
       fxLayoutAlign="space-between center">

    <div [matTooltip]="(dispositionStore.fullScreen ? 'general.labels.detailView.reduce' : 'general.labels.detailView.enlarge')|translate"
         (click)="dispositionStore.toggleFullScreen()" class="full-screen-list"
         [ngClass]="{'active': dispositionStore.fullScreen}">
      <mat-icon class="bh-mat-icon" *ngIf="!dispositionStore.fullScreen" fontSet="icon-fullscreen_lauch_outline">
      </mat-icon>
      <mat-icon class="bh-mat-icon" *ngIf="dispositionStore.fullScreen" fontSet="icon-fullscreen_exit_outline">
      </mat-icon>
    </div>

    <div fxFlex="40" class="date-filters">
      <bh-date-range-select
        [startDateControl]="startDateControl"
        [endDateControl]="endDateControl"
        [resettable]="true"
        [showErrors]="false"
        (onDateChange)="onSearchFormSubmit()">
      </bh-date-range-select>
    </div>

    <div fxFlex fxLayoutAlign="center">
      <span>{{'modules.disposition.dispositionList.dispositionList'|translate}}</span>
    </div>

    <div fxFlex class="disposition-board-list-header__actions"
         fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">

      <button mat-raised-button (click)="print()" [disabled]="!equipmentTransports.length"
              *ngIf="hasModule(modules.DISPOSITION)">
        <mat-icon>print</mat-icon>
      </button>

      <button mat-raised-button (click)="dispositionStore.toggleTimeline()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-accordion class="mat-mdc-table">
    <section class="mat-mdc-header-row">
      <span class="mat-mdc-header-cell mat-cell-small">{{'modules.disposition.dispositionList.latestTime'|translate}}</span>
      <span class="mat-mdc-header-cell mat-cell-small">{{'modules.disposition.dispositionList.earliestTime'|translate}}</span>
      <span class="mat-mdc-header-cell">{{'general.equipm.s'|translate}}</span>
      <span class="mat-mdc-header-cell">{{'general.from'|translate|titlecase}}</span>
      <span class="mat-mdc-header-cell">{{'modules.disposition.dispositionList.to'|translate}}</span>
      <span class="mat-mdc-header-cell mat-cell-small">{{'general.units.distance.s'|translate}}</span>
      <span class="mat-mdc-header-cell mat-cell-small">{{'general.data'|translate}}</span>
    </section>

    <mat-expansion-panel *ngFor="let et of equipmentTransports;"
                         [hideToggle]="et.numberOfSubequipments === 0"
                         #matExpansionPanel
                         class="expansion-panel"
                         (opened)="getSubEquipments(et)">
      <mat-expansion-panel-header class="mat-mdc-row mat-row--border-bottom"
                                  (click)="expandPanel(matExpansionPanel, $event)">
        <span class="mat-mdc-cell mat-cell-small">{{et.latestTransportStart}}</span>
        <span class="mat-mdc-cell mat-cell-small">{{et.earliestTransportStart}}</span>
        <div class="mat-mdc-cell">

          <div fxFlex="50px"
            *ngIf="(et.equipmentType.category2Name | translatableString) as eqTypeName"
            [matTooltip]="eqTypeName">
            <bh-thumbnail *ngIf="et.thumbnailKey" [key]="et.thumbnailKey" [size]="ThumbnailSize.XS"></bh-thumbnail>
            <!-- Default -->
            <svg height="50" width="50" *ngIf="!et.thumbnailKey">
              <!-- TODO BEUT-271 color code needs to be defined by equipment type -->
              <circle cx="25" cy="25" r="20" stroke-width="0" fill="#ff3a20">
              </circle>
              <text x="25" y="25" text-anchor="middle" alignment-baseline="central" fill="white">
                {{ eqTypeName | slice:0:1 }}
              </text>
            </svg>
          </div>

          <div fxFlex="50px" *ngIf="!et.equipmentType" [matTooltip]="'modules.disposition.dispositionList.unknownEquipmentType'|translate">
            <svg height="50" width="50">
              <circle cx="25" cy="25" r="20" stroke-width="0" fill="#bfbfbf">
              </circle>
              <text x="25" y="25" text-anchor="middle" alignment-baseline="central" fill="white">
                ?
              </text>
            </svg>
          </div>

          <div class="title" fxFlex>
            <div class="number">
              <div *ngIf="et.equipmentSerialNumber">
                {{ et.equipmentSerialNumber | slice:0:20}}
                <span matTooltip="{{ et.equipmentSerialNumber }}"
                      *ngIf="et.equipmentSerialNumber && et.equipmentSerialNumber.length > 20">
                  ...
                </span>
              </div>
              <div *ngIf="!et.equipmentSerialNumber">
                {{'modules.disposition.dispositionList.noSerialNumber'|translate}}
              </div>
            </div>
            <div class="name">
              <div *ngIf="et.equipmentName">
                {{ et.equipmentName | slice:0:35}}
                <span matTooltip="{{ et.equipmentName }}"
                      *ngIf="et.equipmentName && et.equipmentName.length > 35">
                  ...
                </span>
              </div>
              <div *ngIf="!et.equipmentName">
                {{ et.equipmentModel | slice:0:35}}
                <span matTooltip="{{ et.equipmentModel }}"
                      *ngIf="et.equipmentModel && et.equipmentModel.length > 35">
                  ...
                </span>
              </div>
            </div>
          </div>
        </div>
        <span class="mat-mdc-cell">
          {{et.fromAddress.street}}
          {{et.fromAddress.streetNumber}}
          {{et.fromAddress.postalCode}}
          {{et.fromAddress.city}}
        </span>
        <span class="mat-mdc-cell">
          {{et.toAddress.street}}
          {{et.toAddress.streetNumber}}
          {{et.toAddress.postalCode}}
          {{et.toAddress.city}}
        </span>
        <span class="mat-mdc-cell mat-cell-small">
          <ng-container *ngIf="et.distance; else noResult">
            {{et.distance|dimensionUnitConverter:'km'}} {{'km'|dimensionUnitDisplay}}
          </ng-container>
          <ng-template #noResult>
              {{'general.units.distance.noValue'|translate}}
          </ng-template>
        </span>
        <span class="mat-mdc-cell mat-cell-small" fxLayout="column">
          <span *ngIf="et.length">{{'general.units.distance.length.abbr'|translate}}: {{et.length.value}} {{et.length.unit}}</span>
          <span *ngIf="et.width">{{'general.units.distance.width.abbr'|translate}}: {{et.width.value}} {{et.width.unit}}</span>
          <span *ngIf="et.height">{{'general.units.distance.height.abbr'|translate}}: {{et.height.value}} {{et.height.unit}}</span>
          <span *ngIf="et.weight">{{'general.units.weight.abbr'|translate}}: {{et.weight.value}} {{et.weight.unit}}</span>
        </span>
      </mat-expansion-panel-header>

      <div class="mat-mdc-row mat-row__subequipments"
           [ngClass]="{'mat-row--no-border': last}"
           *ngFor="let subEquipment of etSubEquipments; let last = last;">
        <span class="mat-mdc-cell mat-cell-small">&nbsp;</span>
        <span class="mat-mdc-cell mat-cell-small">&nbsp;</span>
        <div class="mat-mdc-cell">
          <div fxFlex="50px"
            *ngIf="(subEquipment.equipmentType.category2Name | translatableString) as eqTypeName"
            [matTooltip]="eqTypeName">
            <!-- Equipment type icon -->
            <bh-thumbnail *ngIf="subEquipment.thumbnailKey"
                          [key]="subEquipment.thumbnailKey" [size]="ThumbnailSize.XS">
            </bh-thumbnail>
            <!-- Default -->
            <svg height="50" width="50" *ngIf="!subEquipment.thumbnailKey">
              <!-- TODO BEUT-271 color code needs to be defined by equipment type -->
              <circle cx="25" cy="25" r="20" stroke-width="0" fill="#ff3a20">
              </circle>
              <text x="25" y="25" text-anchor="middle" alignment-baseline="central" fill="white">
                {{ eqTypeName | slice:0:1 }}
              </text>
            </svg>
          </div>

          <div fxFlex="50px" *ngIf="!subEquipment.equipmentType"
               [matTooltip]="'modules.disposition.dispositionList.unknownEquipmentType'|translate">
            <svg height="50" width="50">
              <circle cx="25" cy="25" r="20" stroke-width="0" fill="#bfbfbf">
              </circle>
              <text x="25" y="25" text-anchor="middle" alignment-baseline="central" fill="white">
                ?
              </text>
            </svg>
          </div>

          <div class="title" fxFlex>
            <div class="number">
              <div *ngIf="subEquipment.equipmentSerialNumber">
                {{ subEquipment.equipmentSerialNumber | slice:0:20}}
                <span matTooltip="{{ subEquipment.equipmentSerialNumber }}"
                      *ngIf="subEquipment.equipmentSerialNumber && subEquipment.equipmentSerialNumber.length > 20">
                  ...
                </span>
              </div>
              <div *ngIf="!subEquipment.equipmentSerialNumber">
                {{'modules.disposition.dispositionList.noSerialNumber'|translate}}
              </div>
            </div>
            <div class="name">
              <div *ngIf="subEquipment.equipmentName">
                {{ subEquipment.equipmentName | slice:0:35}}
                <span matTooltip="{{ subEquipment.equipmentName }}"
                      *ngIf="subEquipment.equipmentName && subEquipment.equipmentName.length > 35">
                  ...
                </span>
              </div>
              <div *ngIf="!subEquipment.equipmentName">
                {{ subEquipment.equipmentModel | slice:0:35}}
                <span matTooltip="{{ subEquipment.equipmentModel }}"
                      *ngIf="subEquipment.equipmentModel && subEquipment.equipmentModel.length > 35">
                  ...
                </span>
              </div>
            </div>
          </div>
        </div>
        <span class="mat-mdc-cell">&nbsp;</span>
        <span class="mat-mdc-cell">&nbsp;</span>
        <span class="mat-mdc-cell mat-cell-small">&nbsp;</span>
        <span class="mat-mdc-cell mat-cell-small" fxLayout="column">
            <span *ngFor="let tf of subEquipment.technicalFields">
              <span *ngIf="tf.technicalFieldKey === 'LAN'">{{'general.units.distance.length.abbr'|translate}}: {{tf.value}} {{tf.technicalFieldUnit}}</span>
              <span *ngIf="tf.technicalFieldKey === 'BRE'">{{'general.units.distance.width.abbr'|translate}}: {{tf.value}} {{tf.technicalFieldUnit}}</span>
              <span *ngIf="tf.technicalFieldKey === 'HOH'">{{'general.units.distance.height.abbr'|translate}}: {{tf.value}} {{tf.technicalFieldUnit}}</span>
              <span *ngIf="tf.technicalFieldKey === 'GEW'">{{'general.units.weight.abbr'|translate}}: {{tf.value}} {{tf.technicalFieldUnit}}</span>
            </span>
          </span>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
</mat-card>
