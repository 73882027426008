import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { ViewStock } from '../../../shared/view-stock.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Address } from '../../../../organisation/contract/address.interface';
import { AddressService } from '../../../../../shared/services/address.service';
import { StockTypeResolver } from '../../../../../shared/pipes/stock-type.resolver';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'bh-equipment-stock-select',
  templateUrl: './equipment-stock-select.component.html',
  styleUrls: ['./equipment-stock-select.component.scss']
})
export class EquipmentStockSelectComponent implements OnInit {

  @Input() stocks: ViewStock[];
  @Input() selectedId: string;

  @Output() stockSelected: EventEmitter<string> = new EventEmitter<string>();

  public readonly faStar: IconDefinition = faStar;

  constructor(private addressService: AddressService,
              private stockTypeResolver: StockTypeResolver) { }

  public ngOnInit(): void {
  }

  public selectStock(id: string): void {
    this.stockSelected.emit(id);
  }

  public formatAddress(address: Address): string {
    return address ? this.addressService.formatAddressSingleLine(address.city, address.street, address.streetNumber) : '-';
  }

  public resolveIcon(stockType: string): IconDefinition {
    return this.stockTypeResolver.resolveIcon(stockType);
  }

}
