import { Injectable } from '@angular/core';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { SearchEquipment } from '../../contract/search-equipment.interface';
import * as moment from 'moment';
import { EquipmentStatusCategory } from '../../contract/equipment-status-category.enum';
import { ViewEquipmentProjectAssignment } from '../../contract/view-equipment-project-assignment.interface';
import { LocationType } from 'app/shared/enums/location-type.enum';
import { Status } from '../../contract/status.interface';

// Remove Rental stuff
interface EquipmentStatusChecker {
  isRentalEquipment(equipment: ViewEquipment | SearchEquipment): boolean;
  isRentedEquipment(equipment: ViewEquipment | SearchEquipment): boolean;
  isInactiveRentalEquipment(equipment: ViewEquipment | SearchEquipment): boolean;
  isActiveEquipment(equipment: ViewEquipment | SearchEquipment | ViewEquipmentProjectAssignment): boolean;
  isSoldEquipment(equipment: ViewEquipment | SearchEquipment): boolean;
  isDecommissionedEquipment(equipment: ViewEquipment | SearchEquipment): boolean;
  isInactiveEquipment(equipment: ViewEquipment | SearchEquipment): boolean;
}

interface EquipmentWithStatus {
  status: Status;
}

@Injectable()
export class EquipmentCheckerService implements EquipmentStatusChecker {
  private statusChecker: EquipmentStatusChecker;

  constructor() {
    this.statusChecker = new EquipmentStatusCheckerNew();
  }

  public isRentalEquipment(equipment: ViewEquipment | SearchEquipment): boolean {
    return this.statusChecker.isRentalEquipment(equipment);
  }

  public isRentedEquipment(equipment: ViewEquipment | SearchEquipment): boolean {
    return this.statusChecker.isRentedEquipment(equipment);
  }

  public isInactiveRentalEquipment(equipment: ViewEquipment | SearchEquipment): boolean {
    return this.statusChecker.isInactiveRentalEquipment(equipment);
  }

  public isActiveEquipment(equipment: ViewEquipment | SearchEquipment | ViewEquipmentProjectAssignment): boolean {
    return this.statusChecker.isActiveEquipment(equipment);
  }

  public isSoldEquipment(equipment: ViewEquipment | SearchEquipment): boolean {
    return this.statusChecker.isSoldEquipment(equipment);
  }

  public isDecommissionedEquipment(equipment: ViewEquipment | SearchEquipment): boolean {
    return this.statusChecker.isDecommissionedEquipment(equipment);
  }

  public isInactiveEquipment(equipment: ViewEquipment | SearchEquipment): boolean {
    return this.statusChecker.isInactiveEquipment(equipment);
  }

  public isViewEquipmentLinkedToTelematicsUnit(equipment: ViewEquipment): boolean {
    return equipment != null
      && equipment.assignedTelematicsUnits != null
      && equipment.assignedTelematicsUnits.length > 0;
  }

  public isSearchEquipmentLinkedToTelematicsUnit(equipment: SearchEquipment): boolean {
    return equipment != null && equipment.assignedTelematicUnits > 0;
  }

  public hasEquipmentCurrentTelematicsData(equipment: ViewEquipment | SearchEquipment): boolean {
    return equipment.lastTelematicsUpdate != null && moment(equipment.lastTelematicsUpdate)
      .isAfter(moment().subtract(7, 'days'));
  }

  public isTransportVehicle(equipment: ViewEquipment | SearchEquipment): boolean {
    return equipment?.transportTypes?.length > 0;
  }

  public isNotAvailableAnymore(equipment: EquipmentWithStatus): boolean {
    return equipment?.status?.category === EquipmentStatusCategory.NOT_AVAILABLE;
  }

  public isTransferedToProject(equipment: ViewEquipment): boolean {
    return equipment?.transferDestinationType === LocationType.PROJECT;
  }

  public hasActiveTransferRequest(equipment: ViewEquipment): boolean {
    return Boolean(equipment?.pendingTransferRequestId);
  }
}

// TODO:  Fake implementation of isRentalEquipment, isRentedEquipment and isInactiveRentalEquipment methods
//        since Rental related statuses are not considered in the scope new eq statuses
class EquipmentStatusCheckerNew implements EquipmentStatusChecker {
  public isRentalEquipment(): boolean {
    return false;
  }

  public isRentedEquipment(): boolean {
    return false;
  }

  public isInactiveRentalEquipment(): boolean {
    return false;
  }

  public isActiveEquipment(equipment: EquipmentWithStatus): boolean {
    return equipment
      && (equipment.status?.category === EquipmentStatusCategory.OPERATIONAL
      || equipment.status?.category === EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS
      || equipment.status?.category === EquipmentStatusCategory.NOT_OPERATIONAL);
  }

  public isSoldEquipment(equipment: EquipmentWithStatus): boolean {
    return equipment && equipment.status?.category === EquipmentStatusCategory.NOT_AVAILABLE;
  }

  public isDecommissionedEquipment(equipment: EquipmentWithStatus): boolean {
    return equipment && equipment.status?.category === EquipmentStatusCategory.NOT_AVAILABLE;
  }

  public isInactiveEquipment(equipment: EquipmentWithStatus): boolean {
    return equipment && equipment.status?.category === EquipmentStatusCategory.NOT_AVAILABLE;
  }
}
