import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import * as moment from 'moment';

@Pipe({
  name: 'formatProjectEndDate'
})
export class FormatProjectEndDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(project: DispositionProject, format = 'DD.MM.YYYY'): string {
    if (project && project.projectEnd) {
      return moment(project.projectEnd).format(format);
    }
    if (project && !project.projectEnd) {
      return this.translate('general.labels.openEnd');
    }
    return '';
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
