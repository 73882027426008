<ng-container *ngIf="field">
  <div class="field-name">
    {{ field?.additionalFieldName | translatableString }}
  </div>
  <div class="field-value">
    {{ field | additionalFieldValue }}
  </div>
  <div class="field-unit">
    {{ field.additionalFieldUnit }}
  </div>
</ng-container>
