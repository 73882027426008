<div class="dialog-wrapper add-edit-comment-dialog">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{ (comment ? 'general.comment.view.edit' : 'general.comment.view.add')|translate}}</h1>

  <mat-dialog-content>
    <form [formGroup]="commentsForm" novalidate fxLayout="row">
      <mat-form-field>
        <mat-label>{{'general.comment.s'|translate}}</mat-label>
        <textarea matInput required
                  class="input-comment"
                  formControlName="comment"
                  [maxlength]="fieldLimit.LONG_DESCRIPTION"
                  bhInputFitHeight
                  minRows="2"
                  maxRowsDefault="10">
        </textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" class="actions">
    <button mat-raised-button mat-dialog-close color="accent">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            color="primary"
            bhTimeoutSaveButton
            mat-button
            (throttledClick)="save()"
            [disabled]="!isValid()">
      {{'general.buttons.save'|translate}}
    </button>
  </mat-dialog-actions>
</div>
