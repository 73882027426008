import { Directive, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroup, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';


@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: HostControlDirective,
    },
  ],
})
export class HostControlDirective implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {

  @Input() formControl: FormControl;
  @Input() formControlName: string;

  public control: AbstractControl;
  public onTouch = () => {};
  private subscription: Subscription;

  constructor(private injector: Injector) {}

  public static hostDirectiveProvider(): any {
    return {
      directive: HostControlDirective,
      inputs: ['formControl', 'formControlName'],
     }
  }

  public ngOnInit() {
    this.extractControl();
  }

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControl'] || changes['formControlName']) {
      this.extractControl();
    }
  }

  private extractControl(): void {
    this.subscription?.unsubscribe();

    const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });

    if (ngControl instanceof NgModel) {
      this.control = ngControl.control;
      this.subscription = ngControl.control.valueChanges.subscribe((value) => {
        if (ngControl.model !== value || ngControl.viewModel !== value) {
          ngControl.viewToModelUpdate(value);
        }
      });

    } else if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;

    } else if (ngControl instanceof FormControlName) {
      const container = this.injector.get(ControlContainer).control as FormGroup;
      this.control = container.controls[ngControl.name] as FormControl;

    } else {
      this.control = new FormControl();
    }
  }

  public writeValue() { }
  public registerOnChange() { }
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
