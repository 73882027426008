<div class="transport-execution-dates-dialog">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h2 mat-dialog-title>{{transportTask.title}}</h2>

  <mat-dialog-content class="transport-execution-dates-dialog__content">
    <form [formGroup]="dataFormField" novalidate fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">
      <ng-container [formGroup]="startDateTime">
        <mat-form-field>
          <input matInput
                 bhInputAllowedCharacters="[0-9./]"
                 formControlName="startDate"
                 autocomplete="off"
                 placeholder="{{'modules.transportation.base.actualStartDate'|translate}}"
                 [matDatepicker]="pickerStart">
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker-toggle matSuffix (click)="clearDate(true)">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
          <mat-error *ngIf="startDate.hasError('required')">
            {{'shared.validation.requiredField'|translate}}
          </mat-error>
          <mat-error *ngIf="startDate.hasError('isValidDateInstance')">
            {{startDate.getError('isValidDateInstance')}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input type="time"
                 formControlName="startTime"
                 autocomplete="off"
                 matInput
                 placeholder="{{'modules.transportation.base.actualStartTime'|translate}}">
          <mat-datepicker-toggle *ngIf="!isFirefoxBrowser" matSuffix (click)="clearTime(true)">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-error *ngIf="startTime.hasError('required')">
            {{'shared.validation.requiredField'|translate}}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container [formGroup]="endDateTime" *ngIf="canChangeEndDateTime">
        <mat-form-field class="execution-dates-separator">
          <input matInput
                 bhInputAllowedCharacters="[0-9./]"
                 formControlName="endDate"
                 autocomplete="off"
                 placeholder="{{'modules.transportation.base.actualEndDate'|translate}}"
                 [matDatepicker]="pickerEnd">
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker-toggle matSuffix (click)="clearDate(false)">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-error *ngIf="endDate.hasError('required')">
            {{'shared.validation.requiredField'|translate}}
          </mat-error>
          <mat-error *ngIf="endDate.hasError('isValidDateInstance')">
            {{endDate.getError('isValidDateInstance')}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input type="time"
                 formControlName="endTime"
                 autocomplete="off"
                 matInput
                 placeholder="{{'modules.transportation.base.actualEndTime'|translate}}">
          <mat-datepicker-toggle *ngIf="!isFirefoxBrowser" matSuffix (click)="clearTime(false)">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-error *ngIf="endTime.hasError('required')">
            {{'shared.validation.requiredField'|translate}}
          </mat-error>
        </mat-form-field>
      </ng-container>

    </form>
    <mat-error *ngIf="!datesValid">
      {{'shared.validation.date.executionStartBeforeEnd'|translate}}
    </mat-error>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button color="accent" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            color="primary"
            (click)="saveDateTime()"
            [disabled]="!datesValid || !form.valid">
      <span>
        {{'general.buttons.save'|translate}}
      </span>
    </button>
  </mat-dialog-actions>
</div>
