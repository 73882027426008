import { environment } from 'environments/environment';
import { LanguageService } from './../../services/language.service';
import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { RequestedAssignment } from '../../../modules/equipment/shared/requested-assignment';
import { EquipmentsService } from '../../../modules/equipment/shared/equipments.service';
import { DeleteRequestedAssignmentCommand } from '../../../modules/disposition/mobile/delete-requested-assignment-command';
import { Router } from '@angular/router';
import { ProjectDataSource } from '../../../modules/disposition/shared/project.datasource';
import { RoleAuthorityGuardsComponent } from '../../navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../core/keycloak';
import { delay } from 'rxjs/operators';
import { EquipmentsDataSource } from '../../../modules/equipment/shared/equipments.datasource';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
  selector: 'bh-pending-assignment-acknowledgements',
  templateUrl: './pending-assignment-acknowledgements.component.html',
  styleUrls: ['./pending-assignment-acknowledgements.component.scss']
})
export class PendingAssignmentAcknowledgementsComponent extends RoleAuthorityGuardsComponent implements OnInit, OnChanges, OnDestroy {

  public readonly equipmentColumns: string[] = [
    'name',
    'timespan',
    'delete'
  ];

  @Input() public equipmentId: string;
  @Input() public projectId: string;

  constructor(
    public equipmentStore: EquipmentsDataSource,
    protected authService: KeycloakService,
    private equipmentService: EquipmentsService,
    private projectStore: ProjectDataSource,
    private router: Router,
    private languageService: LanguageService) {
    super(authService);
  }

  public get isEquipmentView(): boolean {
    return !!this.equipmentId;
  }

  public get isProjectView(): boolean {
    return !!this.projectId;
  }

  public ngOnInit(): void {
    this.getAssignmentRequests();

  }

  public ngOnChanges(): void {
    this.getAssignmentRequests();
  }

  public ngOnDestroy(): void {
  }

  public navigateToEquipmentAssignments(equipmentId: string): void {
    this.router.navigate([`/assets/equipment/list/${equipmentId}/general`]);
  }

  public navigateToProjectAssignments(projectId: string): void {
    this.projectStore.currentViewTab = 'assignments';
    this.router.navigate([`/sites/projects/list/${projectId}/assignments`]);
  }

  public cancelAssignment(requestedAssignment: RequestedAssignment): void {
    const command: DeleteRequestedAssignmentCommand = new DeleteRequestedAssignmentCommand();
    command.assignmentId = requestedAssignment.assignmentId;
    command.equipmentId = requestedAssignment.equipmentId;
    this.equipmentService.deleteRequestedAssignment(command)
      .pipe(
        delay(environment.DELAY_SHORT),
        untilDestroyed(this))
      .subscribe(() => this.getAssignmentRequests())
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private getAssignmentRequests(): void {
    this.equipmentStore.getRequestedAssignments({ equipmentId: this.equipmentId, projectId: this.projectId });
  }

  printRequestedDetailsInfo(assignmentRequest: RequestedAssignment) {
    const {equipmentInternalSerialNumber = '', equipmentCostCenter = ''} = assignmentRequest;
    if (equipmentInternalSerialNumber) {
      return equipmentCostCenter ? equipmentInternalSerialNumber + ' (' + equipmentCostCenter + ')' :
        equipmentInternalSerialNumber;
    } else {
      return equipmentCostCenter;
    }
  }

  printRequestedUserInfo(assignmentRequest: RequestedAssignment) {
    const {requestedByName = '', requestedBy = ''} = assignmentRequest;
    return requestedByName ? requestedByName + ' (' + requestedBy + ')' : requestedBy;
  }
}
