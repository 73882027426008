import { ConnectorInformation } from '../connector-information';
import { ScheduleInformation } from '../schedule-information';
import { RioConnectionInformation } from './rio-connection-information';

export class CreateRioConnectorCommand {

  connector: ConnectorInformation;
  schedule: ScheduleInformation;
  connection: RioConnectionInformation;
}
