import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'bh-swipe-option',
  templateUrl: './mobile-swipe-option.component.html',
  styleUrls: ['./mobile-swipe-option.component.scss']
})
export class MobileSwipeOptionComponent {

  @Input() public appendClass: 'red' | 'blue' | 'white' = 'red';
  @Input() public disabled: boolean;
  @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();

  public currentPosition = 0;
  public index = 0;

  constructor(private domSanitizer: DomSanitizer) {
  }

  public optionClicked(e: MouseEvent): void {
    e.preventDefault();
    if (!this.disabled) {
      this.onClick.emit();
    }
  }

  public getSanitizedStyle(): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(this.getCalculatedStyle());
  }

  public getCalculatedStyle(): string {
    switch (this.index) {
      case 0:
        return `left: '100%'`;
      case 1:
        return `left: calc(100% + ${this.currentPosition / 2}px)`;
    }
  }


}
