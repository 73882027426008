import { HistoryEntry } from './history-entry';

export class RentalEvent {

  public color = 'red';

  public static from(section_id: number, historyEntry: HistoryEntry) {
    return new RentalEvent(
      section_id,
      historyEntry.startDate,
      historyEntry.endDate,
      historyEntry.customerName + ' (' + historyEntry.customerNumber + ')')
  }

  private constructor(
    public section_id: number,
    public start_date: Date,
    public end_date: Date,
    public text?: string,
  ) {}


}
