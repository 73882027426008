import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bh-equipment-type-rename-dialog',
  templateUrl: 'equipment-type-rename-dialog.component.html',
  styleUrls: ['equipment-type-rename-dialog.component.scss']
})
export class EquipmentTypeRenameDialogComponent implements OnInit {

  public oldValue: string;
  public newValue: string;

  constructor(public dialogRef: MatDialogRef<EquipmentTypeRenameDialogComponent>) { }

  ngOnInit() {
  }

}
