import { Section } from '../../../../shared/scheduler/section.interface';
import { EmployeeSection } from './employee-section.interface';

export interface TeamMemberSection extends EmployeeSection {
  employeeTeamId?: string;
}

export function isTeamMemberSection(section: Section): section is TeamMemberSection {
  return (<TeamMemberSection>section).employeeTeamId !== undefined;
}
