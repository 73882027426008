import { Component, Input } from '@angular/core';

@Component({
  selector: 'bh-title',
  template: `<h4>{{ text }}</h4>`,
  styleUrls: ['./title.component.scss']
})

export class TitleComponent {

  @Input() text: string;

  constructor() {
  }

}
