import { AfterViewChecked, Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import {
  CompactType,
  DisplayGrid,
  GridsterConfig,
  GridsterItem,
  GridType
} from 'angular-gridster2';
import { WidgetRegistry } from '../widgets/widget-registry';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DashboardStore } from './dashboard.store';
import { AddWidgetDialogComponent } from '../add-widget-dialog/add-widget-dialog.component';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { BasicWidget } from '../widgets/basic-widget/basic-widget';
import { WidgetConfiguration } from 'app/shared/contract/user-configuration/dashboard-configuration';

@UntilDestroy()
@Component({
  selector: 'bh-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewChecked {
  public viewChecked = false;
  public options: GridsterConfig;
  public dashboard: WidgetConfiguration[];
  public dashboardEdit = false;

  @ViewChild('toolbar', {static: true}) private toolbar: MatExpansionPanel;

  constructor(
    private dashboardStore: DashboardStore,
    private dialog: MatDialog,
    private widgetRegistry: WidgetRegistry,
  ) { }

  public deleteWidget = (index: number) => {
    this.dashboard.splice(index, 1);
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  };

  public ngOnInit(): void {
    this.options = {
      gridType: GridType.Fixed,
      displayGrid: DisplayGrid.None,
      fixedColWidth: 30,
      fixedRowHeight: 30,
      swap: false,
      pushItems: true,
      compactType: CompactType.CompactUp,
      disableWindowResize: false,
      scrollToNewItems: false,
      disableWarnings: false,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false
      },
      resizable: {
        enabled: false
      },
      itemResizeCallback: () => setTimeout(() => window.dispatchEvent(new Event('resize')))
    };

    this.subscribeToWidgetsInitialized();

    this.dashboardStore.editMode
    .pipe(untilDestroyed(this))
    .subscribe((editMode: boolean) => {
      this.dashboardEdit = editMode;
      this.options.displayGrid = this.dashboardEdit ? DisplayGrid.Always : DisplayGrid.None;
      this.options.draggable = {enabled: this.dashboardEdit};
      this.options.resizable = {enabled: this.dashboardEdit};
      if (this.options.api && this.options.api.optionsChanged) {
        this.options.api.optionsChanged();
      }
      setTimeout(() => window.dispatchEvent(new Event('resize')));
    });
  }

  public save(): void {
    this.dashboardStore.saveDashboard(this.dashboard);
    this.closeToolbar();
  }

  public setEditMode(editMode: boolean): void {
    this.dashboardStore.setEditMode(editMode);
  }

  public loadWidgetComponent(widget: GridsterItem): Type<BasicWidget> {
    return this.widgetRegistry.widgetDefinitions.get(widget.widgetType).type;
  }

  public openAddWidgetDialog(): void {
    const dialogRef = this.dialog.open(AddWidgetDialogComponent, {
      width: '400px',
      panelClass: 'padded-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboard.push(this.widgetRegistry.createDefaultConfiguration(result));
        setTimeout(() => window.dispatchEvent(new Event('resize')));
      }
    });
  }

  public closeToolbar(): void {
    this.resetDashboard();
    this.toolbar.close();
  }

  public ngOnDestroy(): void {
    this.setEditMode(false);
  }

  /*
   * TODO: Remove when updating angular material solves the problem
   * The current version of angular material produces a flash of unstyled content when expansion panels are being rendered.
   * Disabling the animation on the panels fixes the problem, however, the animations are desired nonetheless.
   * To prevent the UI from exposing unexpected behavior while still keeping animations, the animations are activated on the
   * AfterViewChecked lifecycle hook, after the iterable elements have been initialized.
   *
   * Possibly related issue: https://github.com/angular/material2/issues/13870
   */
  public ngAfterViewChecked(): void {
    if (this.widgetRegistry.widgetsInitializedValue && !this.viewChecked && this.dashboard.length > 0) {
      // Changing the value in setTimeout prevents expressionchangedafterithasbeencheckederror
      setTimeout(() => this.viewChecked = true);
    }
  }

  private subscribeToWidgetsInitialized(): void {
    this.widgetRegistry.widgetsInitialized.pipe(
      untilDestroyed(this),
    ).subscribe({
      next: value => {
        if (value) {
          this.dashboard = this.dashboardStore.getSavedDashboard();
        }
      }
    });
  }

  private resetDashboard() {
    this.dashboard = this.dashboardStore.getSavedDashboard();
  }
}
