import { ExpandingTreeRootItemsPipe } from '../pipes/expanding-tree-root-items.pipe';
import { ExpandingTreeMapItem } from '../contracts/expanding-tree-map-item.class';
import { ExpandingTreeOption } from '../contracts/expanding-tree-option.interface';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { ExpandingTreeSelectionMode } from '../contracts/expanding-tree-selection-mode.enum';
import { ExpandingTreeComponent } from './expanding-tree.component';

@Component({
  selector: 'bh-virtual-expanding-tree',
  templateUrl: './virtual-expanding-tree.component.html',
  styleUrls: ['./virtual-expanding-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VirtualExpandingTreeComponent),
    multi: true
  }]
})
export class VirtualExpandingTreeComponent extends ExpandingTreeComponent {
  @Input('options') public set treeOptions(options: ExpandingTreeOption[]) {
    this.itemMap = new Map<string, ExpandingTreeMapItem>();
    if (options && this.keyFieldName) {
      this.itemMap = this.getItemMap(options);
      this.refresh();
    }
  }
  @Input() public keyFieldName: string;
  @Input() public contentTemplate: TemplateRef<any>;
  @Input('searchTerm') public set applySearchTerm(term: string) {
    this.searchTerm = term;
    this.filterMap(term);
  }
  @Input() public searchPredicate: (item: ExpandingTreeOption, searchTerm: string) => boolean;
  @Input() public alwaysExpanded = false;
  @Input() public selectionMode: ExpandingTreeSelectionMode = ExpandingTreeSelectionMode.DEFAULT;
  @Input() public tooltipTextPredicate: (item: ExpandingTreeOption) => string = () => null;

  constructor(expandingTreeRootItemsPipe: ExpandingTreeRootItemsPipe,
              private changeDetector: ChangeDetectorRef) {
    super(expandingTreeRootItemsPipe);
  }

  public trackById(index: number, item: ExpandingTreeMapItem): any {
    return item.id;
  }

  public selectAll(): void {
    super.selectAll();
    this.changeDetector.detectChanges();
  }

  public deselectAll(): void {
    super.deselectAll();
    this.changeDetector.detectChanges();
  }

  protected refresh(): void {
    super.refresh();
    this.changeDetector.detectChanges();
  }

  protected filterMap(term: string): void {
    super.filterMap(term);
    this.changeDetector.detectChanges();
  }

  public getItemClasses(treeItem: ExpandingTreeMapItem) {
    return {
      'item__header__content__expandable': !this.isLeaf(treeItem),
      'item__header__content__expand': treeItem.expanded || this.alwaysExpanded,
      'item__header__content__disabled': treeItem.disabled,
    };
  }

  public toggleExpanding(treeItem: ExpandingTreeMapItem) {
    if (!this.isLeaf(treeItem) && !this.alwaysExpanded) {
      treeItem.expanded = !treeItem.expanded;
    }
  }
}
