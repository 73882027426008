import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { ViewReportDefinition } from '../../contract/view-report-definition.interface';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { KeycloakService } from '../../../../core/keycloak';
import { CreateReportDefinitionCommand } from '../../contract/create-report-definition-command';
import { UpdateReportDefinitionCommand } from '../../contract/update-report-definition-command';
import { DeleteReportDefinitionCommand } from '../../contract/delete-report-definition-command';
import { ReportGenerationCommand } from '../../contract/report-generation-command';
import { ViewReportJob } from '../../contract/view-report-job.interface';
import { CreateReportJobCommand } from '../../contract/create-report-job-command';
import { UpdateReportJobCommand } from '../../contract/update-report-job-command';
import { DeleteReportJobCommand } from '../../contract/delete-report-job-command';
import { ReportGenerateTransportOrdersCommand } from '../../contract/report-transport-orders-command';
import { ViewReportDetailsJob } from '../../contract/view-report-details-job.interface';
import { EquipmentTypeCount } from '../../contract/equipment-type-count';
import { CustomerLabel } from '../../../../shared/contract/customer-label.interface';
import { ViewOrganisation } from '../../../organisation/contract/view-organisation.interface';
import { SearchProjectOrProjectGroup } from '../../../disposition/contract/search-project.interface';
import { getHttpParameters } from '../../../../shared/utils';
import { SearchEquipment } from '../../../equipment/contract/search-equipment.interface';
import { ReportParameters } from '../../contract/report-parameters';
import { DatesService } from '../../../../shared/services/dates.service';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';

@Injectable()
export class ReportsService {

  private url = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/reports/definitions';
  private reportsUrl = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/reports';
  private reportParametersUrl = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/reports/parameters';
  private equipmentTypesUrl = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/equipmenttypes';
  private equipmentUrl = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/equipments';
  private organisationUrl = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/organisations';
  private projectUrl = environment.APP_REPORT_SERVICE_BASE_URL + '/api/v1/projects';
  private readonly fileUploadUrl = environment.APP_FILE_SERVICE_BASE_URL + '/api/v1/file/upload';

  constructor(private http: HttpClient,
              private keycloak: KeycloakService,
              private userConfiguration: UserConfigurationService
              ) {
  }

  // Report Definitions APIs

  getReportDefinitions(): Observable<PagedResponse<ViewReportDefinition>> {
    return this.http.get<PagedResponse<ViewReportDefinition>>(this.url);
  }

  getReportDefinition(id: string): Observable<ViewReportDefinition> {
    return this.http.get<ViewReportDefinition>(`${this.url}/${id}`);
  }

  getReportDefinitionsByCustomer(customerId: string): Observable<PagedResponse<ViewReportDefinition>> {
    return this.http.get<PagedResponse<ViewReportDefinition>>(`${this.url}/by-customer/${customerId}`);
  }

  addReportDefinition(command: CreateReportDefinitionCommand) {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  updateReportDefinition(command: UpdateReportDefinitionCommand) {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  deleteReportDefinition(command: DeleteReportDefinitionCommand) {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  getTemplateUploader(): FileUploader {
    let options: FileUploaderOptions = {
      isHTML5: true,
      maxFileSize: 10 * 1024 * 1024, // 10MB
      removeAfterUpload: true,
      authToken: this.keycloak.getTokenForHeader('Bearer'),

      headers: [{name: 'X-Realm', value: KeycloakService.getRealm()}],
      url: this.fileUploadUrl,
    };
    let uploader: FileUploader = new FileUploader(options);

    // CORS does not allow credentials with wildcard origins
    uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };
    return uploader;
  }

  // Report Generate APIs

  generateReport(command: ReportGenerationCommand) {
    return this.http.post(`${this.reportsUrl}/generate`, JSON.stringify(command), {responseType: 'blob', observe: 'response'});
  }

  generateTransportOrdersReport(command: ReportGenerateTransportOrdersCommand) {
    return this.http.post(`${this.reportsUrl}/transport-orders`, JSON.stringify(command), {responseType: 'blob', observe: 'response'});
  }

  // Report Jobs APIs

  getReportJobs(): Observable<PagedResponse<ViewReportDetailsJob>> {
    return this.http.get<PagedResponse<ViewReportDetailsJob>>(`${this.reportsUrl}/jobs`);
  }

  getReportJob(id: string): Observable<ViewReportJob> {
    return this.http.get<ViewReportJob>(`${this.reportsUrl}/jobs/${id}`);
  }

  addReportJob(command: CreateReportJobCommand) {
    return this.http.post(`${this.reportsUrl}/jobs/create`, JSON.stringify(command), {responseType: 'text'});
  }

  updateReportJob(command: UpdateReportJobCommand) {
    return this.http.post(`${this.reportsUrl}/jobs/update`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteReportJob(command: DeleteReportJobCommand) {
    return this.http.post(`${this.reportsUrl}/jobs/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  getUserEquipmentTypes(): Observable<EquipmentTypeCount[]> {
    return this.http.get<EquipmentTypeCount[]>(`${this.equipmentTypesUrl}/user-equipment-types`);
  }

  public getCustomerLabels(): Observable<CustomerLabel[]> {
    return this.http.get<CustomerLabel[]>(`${this.equipmentUrl}/user-labels`);
  }

  public getOrganisations(): Observable<ViewOrganisation[]> {
    return this.http.get<ViewOrganisation[]>(`${this.organisationUrl}/user-organisations`);
  }

  public searchProjects(params: Object): Observable<PagedResponse<SearchProjectOrProjectGroup>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchProjectOrProjectGroup>>(`${this.projectUrl}/search-all`, {params: httpParams});
  }

  public searchEquipments(params: Object): Observable<PagedResponse<SearchEquipment>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchEquipment>>(`${this.equipmentUrl}/search-all`, {params: httpParams});
  }

  public downloadDeliveryNote(transferId: string): Observable<HttpResponse<Blob>> {
    const param = {
      transferId: transferId,
      timeZoneName: DatesService.getLocalTimeZone(),
      dimensionSystem: this.userConfiguration.getUserDimensionSystem()
    }
    return this.http.post<Blob>(`${this.reportsUrl}/delivery-note`, param, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  // Report Parameters API
  public saveReportParameters(command: ReportGenerationCommand) {
    return this.http.post(`${this.reportParametersUrl}/save`, command, {responseType: 'text'});
  }

  public getReportParameters(reportDefinitionId: string): Observable<ReportParameters> {
    return this.http.get<ReportParameters>(`${this.reportParametersUrl}/${reportDefinitionId}`);
  }
}
