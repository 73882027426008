import { Component, OnInit } from '@angular/core';
import { ViewCustomer } from '../../contract/view-customer.interface';
import { CustomersService } from '../../shared/customers.service';
import { ReportsDataSource } from 'app/modules/reports/shared/services/reports.datasource';
import { ViewReportDefinition } from 'app/modules/reports/contract/view-report-definition.interface';
import { Router } from '@angular/router';
import { ReportsService } from 'app/modules/reports/shared/services/reports.service';
import { getBrowserName } from '../../../../shared/utils';
import { BrowserName } from 'app/shared/enums/browser-name.enum';
import { CountryEnum } from '../../../../shared/country.enum';
import { CountryResolver } from '../../../../shared/country.resolver';


@Component({
  selector: 'bh-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent implements OnInit {

  public isFirefox: boolean;
  customer: ViewCustomer | null;
  customerId: string;

  displayedColumns: string[] = [
    'reportDefinitionName',
    'reportDefinitionExportFormat'
  ];

  reportDefinitions: ViewReportDefinition[];

  constructor(private router: Router,
              private customerService: CustomersService,
              public reportStore: ReportsDataSource,
              private reportService: ReportsService,
              private countryResolver: CountryResolver) {
  }

  ngOnInit() {
    this.customerService.getCustomer(this.customerId).subscribe(res => {
      this.customer = res;
    });

    this.reportDefinitions = [];
    this.reportStore.customerId = this.customerId;
    this.reportStore.getReportDefinitionsByCustomer(this.customerId);

    // Show number of reports as customer is assigned
    this.reportService.getReportDefinitionsByCustomer(this.customerId)
      .subscribe(res => {
        this.reportDefinitions = res.content;
      });

      this.isFirefox = getBrowserName() === BrowserName.FIREFOX;
  }

  public getCountryName(option: CountryEnum): string {
    return this.countryResolver.resolveName(option);
  }
}
