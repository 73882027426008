<bh-title [text]="'general.attachment.pl'|translate"></bh-title>

<div class="attachments" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="attachment-type-container">
    <div *ngIf="hasAnyAuthority([authorities.STOCK_MANAGE, authorities.STOCK_ADD_ATTACHMENTS]);
    else noUploadAllowed">
      <bh-subtitle [text]="'general.labels.uploadDocPict'|translate"></bh-subtitle>
      <bh-file-upload [itemId]="stock.stockId"
                      uploaderType="STOCKS"
                      (onFileUploaded)="onFileUploaded($event)">
      </bh-file-upload>
    </div>
    <ng-template #noUploadAllowed>
      <div class="no-upload">
        {{'shared.attachment.uploadNotAllowed' | translate}}
      </div>
    </ng-template>
  </div>

  <bh-attachment-list-category
    [deletable]="isDeletable"
    [editable]="isEditable"
    [editableName]="isEditable"
    [pendingChanges]="waiting"
    [documents]="documents"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="onDescriptionChanged($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-category>

  <bh-attachment-list-image-category
    [images]="images"
    [pendingChanges]="waiting"
    [setDefaultEnable]="false"
    [deletable]="isDeletable"
    [visibleDescription]="true"
    [editableDescription]="isEditable"
    [editableName]="isEditable"
    (deleted)="deleteDocument($event)"
    (descriptionChanged)="onDescriptionChanged($event)"
    (nameChanged)="saveFileName($event)">
  </bh-attachment-list-image-category>
</div>
