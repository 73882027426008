<div class="sub-equipments-dialog">
  <bh-title [text]="'modules.equipment.subequipment.assignSubequipments'|translate"></bh-title>
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <form [formGroup]="subEquipmentForm" novalidate fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">


      <mat-form-field class="example-full-width">
        <mat-label>{{'modules.equipment.subequipment.selectSubequipments'|translate}}</mat-label>
        <mat-chip-grid #equipmentsList>
          <mat-chip-row *ngFor="let selectedEquipment of selectedEquipments" [selectable]="true" [removable]="true"
                    (removed)="removeEquipment(selectedEquipment)">
            <span *ngIf="selectedEquipment.equipmentCustomerSerialNumber">
              {{selectedEquipment.equipmentCustomerSerialNumber}} /
            </span>
            <span *ngIf="selectedEquipment.equipmentName">{{selectedEquipment.equipmentName}}</span>
            <span *ngIf="!selectedEquipment.equipmentName">{{selectedEquipment.equipmentModel}}</span>
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
          </mat-chip-row>

          <input matInput #equipmentIdsInput tabindex="-1"
                 [matChipInputFor]="equipmentsList"
                 [matAutocomplete]="equipmentIdsAuto"
                 formControlName="equipmentIds"/>
        </mat-chip-grid>
        <mat-autocomplete #equipmentIdsAuto="matAutocomplete"
                          (optionSelected)="addEquipment($event, equipmentIdsInput)">
          <mat-option *ngFor="let equipment of equipments" [value]="equipment">
            <span *ngIf="equipment.equipmentCustomerSerialNumber">{{equipment.equipmentCustomerSerialNumber}} / </span>
            <span *ngIf="equipment.equipmentName">{{equipment.equipmentName}}</span>
            <span *ngIf="!equipment.equipmentName">{{equipment.equipmentModel}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-button color="primary" mat-dialog-close>{{'general.buttons.cancel'|translate}}</button>
    <button mat-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()"
            [disabled]="!isValid()">{{'general.buttons.save'|translate}}
    </button>
  </mat-dialog-actions>

</div>
