import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { debounceTime, delay } from 'rxjs/operators';
import { getCombinedId, TransferHistoryEntry } from './transfer-history-entry';
import { TransferHistoryEntryComponent } from '../../../../../../shared/components/transfer-history-entry/transfer-history-entry.component';
import { combineLatest, Observable } from 'rxjs';
import { TransferHistoryStore } from './transfer-history.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationDialogComponent } from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TransferService } from '../../../../shared/transfer.service';
import { RevertTransferCartCommand } from '../../../../contract/revert-transfer-cart-command';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransferListItemType } from 'app/modules/disposition/contract/transfer-list-item-type';
import { ViewTransferRequest } from './view-transfer-request';
import { TransferRequestEntry } from './transfer-request-entry';
import { TransferEntry } from './transfer-entry';
import { TransferItemType } from 'app/modules/disposition/shared/transfer/model/transfer-item-type';
import { DeclineTransferRequestCommand } from '../../../../contract/decline-transfer-request-command';
import { ProjectTransferHistoryService } from './project-transfer-history.service';
import { AcknowledgeTransferRequestCommand } from '../../../../contract/acknowledge-transfer-request-command';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { ViewProjectEmployeeAssignment } from '../../../../contract/view-project-employee-assignment.interface';
import { hashCodeOf } from '../../../../../../shared/hashcode';
import { ProjectStructureType } from '../../../../shared/enums/project-structure-type';
import { ProjectsService } from 'app/modules/disposition/shared/project.service';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { PrivilegedRole } from '../../../../../../shared/enums/privileged-roles.enum';

@UntilDestroy()
@Component({
  selector: 'bh-project-view-transfer-history',
  templateUrl: './project-view-transfer-history.component.html',
  styleUrls: ['./project-view-transfer-history.component.scss'],
})
export class ProjectViewTransferHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(TransferHistoryEntryComponent) transferHistoryEntries: QueryList<TransferHistoryEntryComponent>;
  @ViewChild('transferHistory', { static: false }) transferHistoryElement: ElementRef<HTMLDivElement>;

  public loading: Observable<boolean>;
  public transfers: TransferHistoryEntry[] = [];
  public transferRequests: ViewTransferRequest[] = [];
  public selectedTransferRequest: ViewTransferRequest = null;
  public projectId: string;
  public projectName: string;
  public projectNumber: string;
  public projectCostCenter: string;
  public projectOrganisationId: string;
  public projectCustomerId: string;
  public selectedIndex: string | number;
  public isRequestsTab = false;
  public isPermissionForTransferRequests = false;
  private selectedTransferId: string | number;
  private isAcceptanceUser = false;
  private isResponsibleUser = false;

  constructor(
    private projectStore: ProjectDataSource,
    protected route: ActivatedRoute,
    protected transferHistoryStore: TransferHistoryStore,
    private projectTransferHistoryService: ProjectTransferHistoryService,
    private dialog: MatDialog,
    private transferService: TransferService,
    private snackBar: MatSnackBar,
    private authService: KeycloakService,
    private languageService: LanguageService,
    private projectsService: ProjectsService,
  ) {}

  public ngOnInit(): void {
    this.selectedIndex = this.route.snapshot.queryParamMap.get('index')
      ? this.route.snapshot.queryParamMap.get('index')
      : 0;
    this.selectedTransferId = this.route.snapshot.queryParamMap.get('transferId')
      ? this.route.snapshot.queryParamMap.get('transferId')
      : 0;
    this.projectStore.currentViewTab = 'transfer-history';
    this.loading = this.transferHistoryStore.loading;

    this.subscribeToHistoryEntries();
    this.subscribeToCurrentProject();
    this.hasTransferRequestWorkflow();
  }

  public ngAfterViewInit(): void {
    combineLatest(
      this.transferHistoryStore.selectedEntry,
      this.transferHistoryStore.historyEntries.pipe(debounceTime(100))
    ).pipe(untilDestroyed(this), delay(environment.DELAY_SHORTEST))
      .subscribe(([selectedEntryId, entries]) => {
        const entry = this.transferHistoryEntries.find(e => e.elem.nativeElement.id === selectedEntryId);
        if (entry) {
          entry.open();
        }
      });
  }

  private subscribeToHistoryEntries(): void {
    this.transferHistoryStore.historyEntries
      .pipe(untilDestroyed(this))
      .subscribe(data => this.transfers = data);
  }

  private subscribeToTransferRequests(): void {
    this.transferHistoryStore.transferRequests
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (this.isRequestsTab) {
          this.transferRequests = data;
          this.transferRequests.forEach(transfer => transfer.transferEntries = this.getTransferEntry(transfer.transferItems));
          if (this.selectedTransferId) {
            this.selectedTransferRequest = this.transferRequests.find(transfer => transfer.transferId === this.selectedTransferId);
          }
        }
      });
  }

  private subscribeToCurrentProject(): void {
    this.projectStore.currentProject
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        if (
          project &&
          project.projectStructureType !== ProjectStructureType.PROJECT_GROUP &&
          project.projectId !== this.projectId
        ) {
          this.projectId = project.projectId;
          this.projectName = project?.projectName;
          this.projectNumber = project?.projectNumber;
          this.projectCostCenter = project?.projectCostCenter;
          this.projectOrganisationId = project.organisation.organisationId;
          this.projectCustomerId = project.customerId;
          this.transferHistoryStore.projectId = project.projectId;
          this.transferHistoryStore.resetPageIndex();
          this.transferHistoryStore.updateProjectTransfers();
          this.projectStore.getEmployeeAssignments(this.projectId);
        }
      });
  }

  private getTransferEntry(transferItems: TransferRequestEntry[]): TransferEntry[] {
    let transferEntries = [];
    transferItems.forEach(transferItem => {
      const transferEntry: TransferEntry = {
        sourceStockId: transferItem.sourceStockId,
        sourceStockName: transferItem.sourceStockName,
        sourceStockCostCenter: transferItem.sourceStockCostCenter,
        sourceProjectId: transferItem.transferSourceId,
        sourceProjectName: transferItem.transferSourceName,
        sourceProjectNumber: transferItem.transferSourceNumber,
        sourceProjectCostCenter: transferItem.transferSourceCostCenter,
        transferItemAmount: transferItem.amount,
        transferItemId: transferItem.transferItemId,
        transferItemName: transferItem.transferItemName,
        transferItemNumber: transferItem.transferItemInternalNumber,
        transferListItemType:
          transferItem.transferItemType === TransferItemType.EQUIPMENT
            ? TransferListItemType.EQUIPMENT
            : TransferListItemType.STANDARD_BULK_ITEM,
        transferItemUnit: transferItem.transferItemUnit,
        itemIsAccessibleForUser: transferItem.itemIsAccessibleForUser,
        sourceLocationIsAccessibleForUser: transferItem.sourceLocationIsAccessibleForUser.valueOf(),
      };
      transferEntries.push(transferEntry);
    });
    return transferEntries;
  }

  public ngOnDestroy(): void {
    this.projectStore.clearEmployeeAssignments();
  }

  public getCombinedId(transfer: TransferHistoryEntry): string {
    return getCombinedId(transfer);
  }

  public revertRequest(transferId: string): void {
    this.transferHistoryStore.updateLoadingStatus(true);
    this.transferService.transferRevertPossible(transferId)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.transferHistoryStore.updateLoadingStatus(false);
        res.revertPossible
          ? this.openConfirmTransferDialog(transferId)
          : this.showNotice('modules.disposition.projectTransferHistory.transferNotReversed');
      });
  }

  private openConfirmTransferDialog(transferId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.disposition.confirmation.title.revertTransfer');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.revertTransfer');
    dialogRef.componentInstance.confirmLabel = this.translate('general.buttons.confirm');
    dialogRef.componentInstance.denyLabel = this.translate('general.buttons.cancel');

    dialogRef.afterClosed().subscribe(res => {
      if (res === dialogResults.YES) {
        this.revertTransfer(transferId);
      }
    });
  }

    private revertTransfer(transferId: string): void {
      this.transferService.revertTransfer(new RevertTransferCartCommand(transferId))
        .pipe(untilDestroyed(this))
        .subscribe(() => setTimeout(() => {
          this.showNotice('modules.disposition.projectTransferHistory.transferReversed');
          this.transferHistoryStore.updateProjectTransfers();
          this.projectStore.getProjectAmounts(this.projectId);
          this.projectStore.setCurrentProject(this.projectId);
          this.getTransferRequests();
        }, environment.DELAY_SHORT));
    }

  private showNotice(message: string): void {
    this.snackBar.open(this.translate(message), undefined, { duration: environment.DELAY_LONG });
  }

  public declineTransferRequest(transferId: string): void {
    this.projectTransferHistoryService
      .declineTransferRequest(new DeclineTransferRequestCommand(transferId))
      .pipe(untilDestroyed(this))
      .subscribe(() => setTimeout(() => {
          this.snackBar.open(
            this.translate('modules.disposition.projectTransferHistory.transferRequestDeclined'),
            undefined,
            { duration: environment.DELAY_LONG }
          );
          this.getTransferRequests();
          this.transferHistoryStore.updateProjectTransfers();
        }, environment.DELAY_SHORT),
        () => this.snackBar.open(
          this.translate('modules.disposition.projectTransferHistory.transferRequestNotDeclined'),
          undefined,
          { duration: environment.DELAY_LONG }
        )
      );
  }

  public acknowledgeTransferRequest(transferId: string) {
    this.projectTransferHistoryService
      .acknowledgeTransferRequest(new AcknowledgeTransferRequestCommand(transferId))
      .pipe(untilDestroyed(this))
      .subscribe(() => setTimeout(() => {
        this.snackBar.open(
          this.translate('modules.disposition.projectTransferHistory.transferRequestAccepted'),
          undefined,
          { duration: environment.DELAY_LONG }
        );
        this.getTransferRequests();
        this.transferHistoryStore.updateProjectTransfers();
      }, environment.DELAY_SHORT),
      () => this.snackBar.open(
        this.translate('modules.disposition.projectTransferHistory.transferRequestNotAccepted'),
        undefined,
        { duration: environment.DELAY_LONG }
      )
    );
  }

  public selectEntry(transfer: TransferHistoryEntry): void {
    this.transferHistoryStore.selectEntry(this.getCombinedId(transfer));
  }

  private getTransferRequests(): void {
    this.transferHistoryStore.getTransferRequests(this.projectId);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private subscribeToEmployeeAssignments(): void {
    this.projectStore.employeeAssignments
      .pipe(untilDestroyed(this))
      .subscribe((employeeAssignments) => {
        if (employeeAssignments) {
          const userId = this.authService.getUserUserId();
          this.isAcceptanceUser = this.hasAcceptanceUser(employeeAssignments, userId);
          this.isResponsibleUser = this.hasResponsibleUser(employeeAssignments, userId);
          this.isRequestsTab = this.hasRequestsTab(this.projectOrganisationId, this.projectCustomerId);
          if (this.isRequestsTab) {
            this.getTransferRequests();
            this.isPermissionForTransferRequests = this.hasPermissionForTransferRequests();
          } else {
            this.isPermissionForTransferRequests = false;
          }
        }
      });
  }

  private hasAcceptanceUser(employeeAssignments: ViewProjectEmployeeAssignment[], userId: string): boolean {
    return employeeAssignments.some(el => el.assignedUserId === userId && el.isAcceptanceUser);
  }

  private hasResponsibleUser(employeeAssignments: ViewProjectEmployeeAssignment[], userId: string): boolean {
    return employeeAssignments.some(el => el.assignedUserId === userId);
  }

  private hasUsersOrganisationOwnsProject(projectOrganisationId: string, projectCustomerId: string): boolean {
    return this.authService.getRoles().includes(PrivilegedRole.Flottenadmin.toString())
      ? this.authService.getUserCustomerId() === projectCustomerId
      : this.authService.getUserOrganisations().includes(projectOrganisationId);
  }

  private hasPermissionForTransferRequests(): boolean {
    return (this.authService.hasAuthority(Authorities.ASSIGNMENT_REQUESTS_SUPERVISE) || this.isAcceptanceUser);
  }

  private hasTransferRequestWorkflow(): void {
    this.projectsService.hasTransferRequestWorkflow().subscribe((res) => {
      if (res) {
        this.subscribeToEmployeeAssignments();
        this.subscribeToTransferRequests();
      }
    });
  }

  private hasRequestsTab(projectOrganisationId: string, projectCustomerId: string): boolean {
    return (
      (this.authService.hasAuthority(Authorities.PROJECT_VIEW) &&
      this.hasUsersOrganisationOwnsProject(projectOrganisationId, projectCustomerId)) ||
      (this.authService.hasAuthority(Authorities.PROJECT_ASSIGNEE_VIEW) && this.isResponsibleUser)
    );
  }

  private transferByHash(index, transfer) {
    return hashCodeOf(transfer);
  }
}
