<div class="list-view-main__right" fxFlexFill fxLayout="column">
  <div class="list-view-main__right-header"
    fxFlex="60px"
    fxLayout="row"
    fxLayoutAlign="space-between center">

    <div *ngIf="displayService.isTableAndDetails()"
      class="full-screen"
      fxFlex="0 0 70px"
      fxLayout="row"
      fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.enlarge'|translate"
      (click)="displayService.displayDetailsFullscreen()" >
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div *ngIf="displayService.isDetailsFullscreen()"
      class="full-screen"
      fxFlex="0 0 70px"
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltip]="'general.labels.detailView.reduce'|translate"
      (click)="displayService.displayTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>

    <div class="name" fxFlex="10 1 100%" fxLayout="row" fxLayoutGap="5px">
      <bh-contact-status-indicator [status]="contact?.status"></bh-contact-status-indicator>
      <span>{{ contact?.name }}</span>
    </div>

    <div *ngIf="contact && hasAuthority(authorities.CONTACT_MANAGE)"
      class="more"
      fxFlex="0 0 20px"
      [matMenuTriggerFor]="contactMore"
      [matTooltip]="'general.buttons.actions'|translate">
      <mat-icon>more_vert</mat-icon>
    </div>
    <mat-menu class="contact-custom-mat-menu" #contactMore="matMenu">
      <button *ngIf="hasAuthority(authorities.CONTACT_MANAGE)"
        mat-menu-item
        (click)="edit()">
        <mat-icon>edit</mat-icon>
        <span>{{'general.buttons.edit'|translate}}</span>
      </button>
      <button *ngIf="hasAuthority(authorities.CONTACT_MANAGE) && isActive()"
        mat-menu-item
        (click)="deactivate()">
        <mat-icon>lock</mat-icon>
        <span>{{'general.buttons.deactivate'|translate}}</span>
      </button>
      <button *ngIf="hasAuthority(authorities.CONTACT_MANAGE) && !isActive()"
        mat-menu-item
        (click)="activate()">
        <mat-icon>lock_open</mat-icon>
        <span>{{'general.buttons.activate'|translate}}</span>
      </button>
      <button *ngIf="hasAuthority(authorities.CONTACT_MANAGE)"
        mat-menu-item
        (click)="delete()">
        <mat-icon>delete_forever</mat-icon>
        <span>{{'general.buttons.delete'|translate}}</span>
      </button>
    </mat-menu>

    <div *ngIf="!displayService.isTableFullscreen()"
      class="change-display-mode"
      fxFlex="0 0 60px"
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltip]="'general.labels.detailView.collapse'|translate"
      (click)="displayService.displayTableFullscreen()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
