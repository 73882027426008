import { CustomerTypeEnum } from './../../contract/customer-type.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerTypeDisplay'
})
export class CustomerTypeDisplayPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(value: CustomerTypeEnum): string {
    switch (value) {
      case CustomerTypeEnum.FFZ:
        return this.translate('shared.pipes.customerTypeDisplay.logistics');
      case CustomerTypeEnum.Agrar:
        return this.translate('shared.pipes.customerTypeDisplay.agriculture');
      case CustomerTypeEnum.Kommunal:
        return this.translate('shared.pipes.customerTypeDisplay.municipal');
      case CustomerTypeEnum.Bau:
        return this.translate('shared.pipes.customerTypeDisplay.construction');
    }
    return null;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
