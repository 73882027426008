import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './landscape/header.component';
import { MobileHeaderComponent } from './mobile/mobile-header.component';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { KeycloakService } from '../../core/keycloak';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavService } from '../navigation/sidenav/shared/sidenav.service';
import { MainPipeModule } from '../../shared/pipes/main-pipe.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlarmNotificationComponent } from './alarm-notification/alarm-notification.component';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    FlexLayoutModule,
    MainPipeModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
  ],
  declarations: [
    HeaderComponent,
    MobileHeaderComponent,
    AlarmNotificationComponent
  ],
  exports: [
    HeaderComponent,
    MobileHeaderComponent
  ],
  providers: [
    KeycloakService,
    SidenavService
  ]
})
export class HeaderModule { }
