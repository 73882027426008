
import { Pipe, PipeTransform } from '@angular/core';
import { TransportViewProject } from '../../contracts/transport/transport-view-project.interface';

@Pipe({
  name: 'formatTitleFromRelatedProject'
})
export class FormatTitleFromRelatedProjectPipe implements PipeTransform {

  constructor() {
  }

  public transform(transportViewProject: TransportViewProject): string {
    if (transportViewProject !== null) {
      const titleSecondPart = (transportViewProject.projectCostCenter
        ? transportViewProject.projectCostCenter
        : '').trim();
      return transportViewProject.name.trim() + (titleSecondPart ? ` (${titleSecondPart})` : '');
    }
    return '';
  }

}
