import { IntervalsDurationEnum } from '../intervals-duration.enum';

export function period(interval: number, intervalDuration?: IntervalsDurationEnum): string {
  switch (intervalDuration) {
    case IntervalsDurationEnum.DAYS:
      return `P${interval}D`;
    case IntervalsDurationEnum.MONTHS:
      return `P${interval}M`;
    case IntervalsDurationEnum.YEARS:
      return `P${interval}Y`;
  }
}
