<bh-basic-widget [widget]="widget"
                 [index]="index"
                 (deleted)="onDeleted()">
  <ng-container widget-content>
    <div #scrollContainer class="widget-main-content">
      <div fxLayout="row" fxLayoutAlign="flex-end center">
        <button (click)="onScroll(true)"
                class="transfer-refresh-button request-accept mat-primary mat-mdc-raised-button mdc-button mdc-button--raised"
                [matTooltip]="'modules.dashboard.transferRequestWidgetComponent.refresh' | translate">
          <fa-icon [icon]="faSync" class="accept-icon"></fa-icon>
        </button>
      </div>
      <div class="transfer-history"
           infiniteScroll
           (scrolled)="onScroll()"
           [scrollWindow]="false"
           [infiniteScrollDistance]="3"
           [infiniteScrollThrottle]="150"
           [infiniteScrollContainer]="scrollContainer">
        <ng-container *ngIf="historyEntries.length">
          <bh-transfer-request *ngFor="let transfer of historyEntries"
                               [transfer]="transfer"
                               (onDeclineTransferRequest)="declineTransferRequest($event)"
                               (onAcknowledgeTransferRequest)="acknowledgeTransferRequest($event)">
          </bh-transfer-request>
        </ng-container>
        <bh-text-line *ngIf="endOfList | async"
                      [text]="'modules.disposition.projectTransferHistory.endOfList'|translate">
        </bh-text-line>
        <bh-text-line *ngIf="noEntries | async"
                      [text]="'modules.disposition.projectTransferHistory.noAvailableEntries'|translate">
        </bh-text-line>
      </div>
      <div class="loading-overlay-panel" *ngIf="loading | async">
        <div class="loading-overlay"
             [attr.aria-label]="'modules.disposition.projectTransferHistory.loadedEvents'|translate"
             [class.panel-visible]="loading | async">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </ng-container>
</bh-basic-widget>
