import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';

@Component({
  selector: 'bh-inline-text-edit',
  templateUrl: './inline-text-edit.component.html',
  styleUrls: ['./inline-text-edit.component.scss']
})
export class InlineTextEditComponent implements OnInit {

  @Input() public initialValue: string;
  @Input() public editable = false;
  @Output() public saveValue: EventEmitter<string> = new EventEmitter<string>();

  public inputControl: UntypedFormControl;
  public readonly = true;
  public readonly fieldLimit = FieldLimit;
  private readonly inputValidators: ValidatorFn[] = [Validators.maxLength(FieldLimit.SHORT_TEXT)];

  public ngOnInit(): void {
    this.inputControl = new UntypedFormControl(this.initialValue);
    this.setValidators();
  }

  public edit(): void {
    this.readonly = false;
    this.setValidators();
  }

  public cancel(): void {
    this.inputControl.setValue(this.initialValue);
    this.readonly = true;
    this.setValidators();
  }

  public save(): void {
    this.inputControl.markAsTouched();
    if (!this.inputControl.valid) {
      return
    }

    if (this.initialValue !== this.currentValue) {
      this.initialValue = this.currentValue;
      this.saveValue.emit(this.currentValue);
    }
    this.cancel();
  }

  private setValidators(): void {
    this.inputControl.setValidators(this.readonly ? [] : this.inputValidators);
    this.inputControl.updateValueAndValidity();
    this.inputControl.markAsTouched();
  }

  private get currentValue(): string {
    return this.inputControl.value;
  }
}
