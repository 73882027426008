import { Pipe, PipeTransform } from '@angular/core';
import { SearchEquipment } from 'app/modules/equipment/contract/search-equipment.interface';
import { EquipmentLocationType } from '../contract/equipment-location-type-enum';

@Pipe({
  name: 'equipmentLocationTypeResolver'
})
export class EquipmentLocationTypeResolverPipe implements PipeTransform {

  public transform(equipment: SearchEquipment): EquipmentLocationType {
    if (equipment.projectId) {
      return EquipmentLocationType.PROJECT;
    }
    if (equipment.stockId) {
      return EquipmentLocationType.STOCK;
    }
    return null;
  }
}
