<table
  *ngIf="equipmentTransferItems?.length"
  mat-table
  [dataSource]="equipmentTransferItems"
  class="item-table">
  <ng-container matColumnDef="name">
    <div fxFlex="80">
      <th mat-header-cell *matHeaderCellDef>{{'general.equipm.pl'|translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="transferAmount">
    <div fxFlex="20">
      <th mat-header-cell *matHeaderCellDef>{{'general.quantity'|translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="isSkipped(element); else transferedTmp"
              [matTooltip]="'modules.disposition.bulkItemTransfer.alreadyProjectLocation' | translate"
              class="skipped"
        >
          {{'general.skipped'|translate}}
        </span>
        <ng-template #transferedTmp>
          {{element.transferAmount}}
        </ng-template>
      </td>
    </div>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<table
  *ngIf="bulkItemTransferItems?.length"
  mat-table
  [dataSource]="bulkItemTransferItems"
  class="item-table">
  <ng-container matColumnDef="name">
    <div fxFlex="80">
      <th mat-header-cell *matHeaderCellDef> {{'general.bulkItems'|translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="transferAmount">
    <div fxFlex="20">
      <th mat-header-cell *matHeaderCellDef>{{'general.quantity'|translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{element.transferAmount | dimensionUnitConverter: element.unit:3}}
      </td>
    </div>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>




