import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewPartner } from '../contract/view-partner.interface';
import { UpdatePartnerCommand } from '../contract/update-partner-command';
import { CreatePartnerCommand } from '../contract/create-partner-command';
import { DeletePartnerCommand } from '../contract/delete-partner-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { UpdatePartnerModulesCommand } from '../contract/update-partner-modules-command';

@Injectable()
export class PartnersService {

  private url = environment.APP_ORGANISATION_SERVICE_BASE_URL + '/api/v1/partners';

  constructor(private http: HttpClient) {
  }

  getPartners(page?: number, size?: number): Observable<PagedResponse<ViewPartner>> {
    const pagingUrl = this.url + '?page=' + page + '&size=' + size;

    return this.http.get<PagedResponse<ViewPartner>>(pagingUrl);
  }

  getPartner(partnerId: string): Observable<ViewPartner> {
    return this.http.get<ViewPartner>(this.url + '/' + partnerId);
  }

  addPartner(command: CreatePartnerCommand) {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  updatePartner(command: UpdatePartnerCommand) {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  deletePartner(command: DeletePartnerCommand) {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  updateModules(command: UpdatePartnerModulesCommand) {
    return this.http.post(this.url + '/update-modules', JSON.stringify(command), {responseType: 'text'});
  }
}
