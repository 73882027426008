import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { BatteryStatus } from '../enums/battery-status.enum';

@Pipe({ name: 'translateBatteryStatus' })
export class TranslateBatteryStatusPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  transform(value: BatteryStatus): any {
    switch (value) {
      case BatteryStatus.HIGH:
        return this.translate('modules.equipment.telematics.high');
      case BatteryStatus.LOW:
        return this.translate('modules.equipment.telematics.low');
      case BatteryStatus.CRITICAL:
        return this.translate('modules.equipment.telematics.critical');
      case BatteryStatus.UNKNOWN:
      default:
        return this.translate('modules.equipment.telematics.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
