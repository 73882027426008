import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldLimit } from '../../../../../../../../../shared/enums/fieldLimit.enum';
import { TransportCommentUpdateCommand } from '../../../../../../../contracts/transport/transport-comment-update.command';
import { TransportCommentCreateCommand } from '../../../../../../../contracts/transport/transport-comment-create.command';
import { TransportView } from '../../../../../../../contracts/transport/transport-view.interface';
import { TransportListDatasource } from '../../../../../../../shared/services/transport-list.datasource';
import { TransportComment } from '../../../../../../../contracts/transport/transport-comment.interface';

@UntilDestroy()
@Component({
  selector: 'bh-add-edit-comment-dialog',
  templateUrl: './add-edit-comment-dialog.component.html',
  styleUrls: ['./add-edit-comment-dialog.component.scss'],
})
export class AddEditCommentDialogComponent implements OnInit {

  public readonly fieldLimit = FieldLimit;

  dialogRef: MatDialogRef<AddEditCommentDialogComponent>;
  commentsForm: UntypedFormGroup;
  comment: TransportComment;
  transport: TransportView;

  constructor(private transportDataSource: TransportListDatasource,
              protected formBuilder: UntypedFormBuilder) {
  }

  public ngOnInit(): void {
    this.buildForm();
    if (this.comment) {
      this.setFormValues();
    }
  }

  public save(): void {
    if (this.isValid()) {
      if (this.commentsForm.valid) {
        this.comment ? this.updateComment() : this.saveComment();
      }
    }
  }

  public isValid(): boolean {
    return this.commentsForm.valid;
  }

  private saveComment(): void {
    let formData = this.commentsForm.getRawValue();
    let cmd: TransportCommentCreateCommand = new TransportCommentCreateCommand();

    cmd.text = formData.comment;
    cmd.transportId = this.transport.transportId;

    this.transportDataSource.addComment(cmd).subscribe(
      () => {
        this.dialogRef.close();
      },
    );
  }

  private updateComment(): void {
    let formData = this.commentsForm.getRawValue();
    let cmd: TransportCommentUpdateCommand = new TransportCommentUpdateCommand();

    cmd.text = formData.comment;
    cmd.transportId = this.transport.transportId;
    cmd.commentId = this.comment.commentId;

    this.transportDataSource.updateComment(cmd).subscribe(
      () => {
        this.dialogRef.close();
      },
    );
  }

  private buildForm(): void {
    this.commentsForm = this.formBuilder.group({
      comment: ['', [<any>Validators.required]],
    });
  }

  private setFormValues(): void {
    this.commentsForm.patchValue({
      comment: this.comment.text,
    });
  }

}
