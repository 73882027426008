import { EventEmitter, Injectable } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { PlatformLocation } from '@angular/common';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomDeviceDetectorService } from '../../../../shared/services/custom-device-detector.service';
import { RouterHistory } from '../../../../shared/router-history';

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  public sidenavStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(protected location: PlatformLocation,
              private deviceService: CustomDeviceDetectorService,
              private routerHistory: RouterHistory) {
  }

  setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  open(): Promise<MatDrawerToggleResult> {
    this.sidenavStateChanged.emit(true);
    return this.sidenav.open();
  }

  close(): Promise<MatDrawerToggleResult> {
    this.sidenavStateChanged.emit(false);
    return this.sidenav.close();
  }

  toggle(isOpen?: boolean): Promise<MatDrawerToggleResult> {

    if (this.deviceService.isMobile()) {
      this.opened ? this.routerHistory.goBack('') :
          this.location.pushState(null, null, 'mobile/sidenav');
    }

    this.sidenavStateChanged.emit(!this.sidenav.opened);
    return this.sidenav.toggle(isOpen);
  }

  get opened(): boolean {
    return this.sidenav.opened
  }

  get closed(): Observable<boolean> {
    return this.sidenav.openedChange.pipe(filter(opened => !opened));
  }
}
