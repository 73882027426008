import { firstValueFrom, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { AfterViewChecked, ChangeDetectorRef, OnInit, ViewChild, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { KeycloakService } from '../../../../core/keycloak';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { EquipmentsService } from '../../shared/equipments.service';
import { RouterHistory } from '../../../../shared/router-history';
import { UUID } from 'angular2-uuid';
import { AttachedDocument } from '../../../../shared/contract/attached-document.interface';
import { InvoiceNumberExistValidator } from '../../../../shared/custom-validators/invoice-number-exist.validator';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { DatesService } from '../../../../shared/services/dates.service';
import { AddEquipmentDamageCommand } from '../../contract/add-equipment-damage-command';
import { DamageType } from '../../contract/damage-type';
import { EquipmentInvoice } from '../../contract/equipment-invoice.interface';
import { LifeCycleDamage } from '../../contract/life-cycle-damage';
import { EnrichedAttachedDocument } from '../../../../shared/components/file-upload-component/base/base-file-upload.component';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { Status } from '../../contract/status.interface';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { EquipmentStatusCategory } from '../../contract/equipment-status-category.enum';
import { UpdateEquipmentChangeStatusCommand } from '../../contract/update-equipment-change-status-command';
import { Authorities } from '../../../../shared/enums/authorities.enum';

@Component({
  selector: 'bh-equipment-damage-add',
  templateUrl: './equipment-damage-add-edit.component.html',
  styleUrls: ['./equipment-damage-add-edit.component.scss']
})
export class EquipmentDamageAddEditComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewChecked {

  @ViewChild(MatAutocompleteTrigger) trigger;

  public damageAddForm: UntypedFormGroup;
  public equipmentId: string;
  public equipment: ViewEquipment;
  public newEquipmentStatus: Status;
  public lifecycleId: UUID;
  public damageTypes: string[];
  public documents: AttachedDocument[] = [];
  public filteredInvoices: Observable<string[] | EquipmentInvoice[]>;
  public invoices: EquipmentInvoice[];
  public isEditMode = false;
  public damageDetails: LifeCycleDamage;

  public saving = false;

  public readonly fieldLimit = FieldLimit;

  constructor(protected formBuilder: UntypedFormBuilder,
              protected equipmentService: EquipmentsService,
              protected equipmentStore: EquipmentsDataSource,
              protected cdRef: ChangeDetectorRef,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, activatedRoute, routerHistory);
  }

  get damageDate() {
    return this.damageAddForm.get('damageDate');
  }

  get invoiceId() {
    return this.damageAddForm.get('invoiceId');
  }

  ngOnInit() {
    this.buildForm();
    this.lifecycleId = UUID.UUID();
    this.damageTypes = Object.keys(DamageType);
    this.fetchFormData();
  }

  private async fetchFormData() {
    const params = await firstValueFrom(this.activatedRoute.params);

    this.equipmentId = params['id'];
    this.equipment = await firstValueFrom(this.equipmentService.getEquipment(this.equipmentId));
    this.newEquipmentStatus = this.equipment.status;

    if (params['lifecycleId']) {
      this.isEditMode = true;
      this.lifecycleId = params['lifecycleId'];
      this.getDamageDetails(this.equipmentId, this.lifecycleId.toString());
    }

    if (this.hasAuthority(this.authorities.EQUIPMENT_VIEW_COSTS) && this.hasModule(this.modules.COSTS)) {
      this.getInvoices(this.equipmentId);
    }
  }

  ngAfterViewChecked() {
    // TODO: check if there is another solution
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges();
  }

  getInvoices(equipmentId: string) {
    let params = {
      'only-numbered': true
    };

    this.equipmentStore.getInvoices(equipmentId, params)
    .subscribe(res => {
      if (res) {
        this.invoices = res;
        this.filterInvoices();
        this.setInvoiceNumberValidator();
      }
    });
  }

  save() {
    if (this.isValid) {
      this.saving = true;

      let formValue = this.damageAddForm.getRawValue();
      let cmd = new AddEquipmentDamageCommand();
      cmd.lifecycleId = this.lifecycleId;
      cmd.equipmentId = this.equipmentId;
      cmd.damageDate = DatesService.ospDateTimeAtStartOfDay(formValue.damageDate);
      cmd.damageType = formValue.damageType;

      if (this.hasModule(this.modules.COSTS)) {
        cmd.equipmentCostId = formValue.invoiceId ? formValue.invoiceId : null;
        cmd.invoiceNumberRef = formValue.invoiceId ? this.resolveInvoiceNumber(formValue.invoiceId) : null;
      }

      cmd.damageLocation = formValue.damageLocation ? formValue.damageLocation : null;
      cmd.documents = this.documents ? this.documents : [];
      cmd.documentation = formValue.documentation ? formValue.documentation : null;
      this.isEditMode ? this.editDamage(cmd) : this.addDamage(cmd);
    }
  }

  public isValid(): boolean {
    return this.damageAddForm.valid;
  }

  onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.documents.push(AttachedDocument.fromData(attachedDocument));
  }

  displayInvoiceNumber(invoiceId: string): string {
    if (this.invoices && invoiceId) {
      const invoice = this.invoices.find(inv => inv.invoiceId === invoiceId);
      return invoice.invoiceNumber;
    }
    return '';
  }

  resolveInvoiceNumber(invoiceId: string): string {
    return this.displayInvoiceNumber(invoiceId);
  }

  filterInvoices(): void {
    this.filteredInvoices = this.damageAddForm.valueChanges
    .pipe(startWith(null), map(value => value
      ? this.filterCollection(this.invoices, value.invoiceId, 'invoiceNumber')
      : this.invoices.slice()));
  }

  filterCollection(collection: any[], value: string, field: string | null): string[] {
    if (!value) {
      return collection;
    }

    if (collection && value) {
      return collection.filter((item) => {
        if (field) {
          return item[field].toLowerCase().indexOf(value.toLowerCase()) === 0;
        }
        return item.toLowerCase().indexOf(value.toLowerCase()) === 0;
      });
    }
  }

  getDamageDetails(equipmentId: string, lifecycleId: string) {
    this.equipmentStore.getLifeCycleDetails(equipmentId, lifecycleId)
    .subscribe(
      res => {
        if (res) {
          this.damageDetails = res as LifeCycleDamage;
          this.setFormValues();
          this.fillExistingDocuments();
        }
      },
      error => {
        console.log('error get invoice details: ', error);
      });
  }

  resetForm() {
    this.damageAddForm.reset();
    this.documents = [];
    this.onEquipmentStatusChange(this.equipment.status);
  }

  removeDocument(document: AttachedDocument) {
    const index = this.documents.indexOf(document);
    if (index !== -1) {
      this.documents.splice(index, 1);
    }
  }

  private setInvoiceNumberValidator(): void {
    this.invoiceId.setValidators(InvoiceNumberExistValidator.isValid(this.invoices));
  }

  private buildForm() {
    this.damageAddForm = this.formBuilder.group({
      damageType: [''[<any>Validators.required]],
      damageDate: [''[<any>Validators.required]],
      damageLocation: [''],
      invoiceId: [''],
      documentation: [''],
      documents: ['']
    });
  }

  private setFormValues() {
    this.damageAddForm.patchValue({
      damageDate: this.damageDetails.damageDate,
      damageType: this.damageDetails.damageType,
      invoiceId: this.damageDetails.equipmentCostId,
      damageLocation: this.damageDetails.damageLocation,
      documentation: this.damageDetails.documentation
    });
  }

  private fillExistingDocuments() {
    this.documents = [];
    if (this.damageDetails.documents) {
      this.damageDetails.documents.forEach((obj) => {
        // delete obj.url; NEVER USE THIS
        this.documents.push(AttachedDocument.fromData(obj));
      });
    }
  }

  private addDamage(cmd: AddEquipmentDamageCommand) {
    if (this.canUpdateEquipmentStatus()
      && this.equipment.status.equipmentStatusId !== this.newEquipmentStatus.equipmentStatusId) {
      this.equipmentStore.addDamage(cmd).subscribe(() => {
        const updateStatusCmd = new UpdateEquipmentChangeStatusCommand();
        updateStatusCmd.equipmentId = cmd.equipmentId;
        updateStatusCmd.equipmentStatusId = this.newEquipmentStatus.equipmentStatusId;
        this.equipmentStore.updateEquipmentChangeStatus(updateStatusCmd)
        .pipe(finalize(() => this.saving = false))
        .subscribe(() => this.goBack());
      });

    } else {
      this.equipmentStore.addDamage(cmd)
      .pipe(finalize(() => this.saving = false))
      .subscribe(() => this.goBack());
    }
  }

  private editDamage(cmd: AddEquipmentDamageCommand) {
    this.equipmentStore.updateDamage(cmd)
    .pipe(finalize(() => this.saving = false))
    .subscribe(() => this.goBack());
  }

  public onEquipmentStatusChange(status: Status): void {
    this.newEquipmentStatus = status;
  }

  public getAllExceptNotOperationalCategory(): (category: EquipmentStatusCategory) => boolean {
    return (category: EquipmentStatusCategory) => {
      return EquipmentStatusCategory.NOT_AVAILABLE !== category
    }
  }

  public canUpdateEquipmentStatus(): boolean {
    return !this.isEditMode && this.hasAnyAuthority([
      Authorities.EQUIPMENT_UPDATE,
      Authorities.EQUIPMENT_STATUS_UPDATE_ANY,
      Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE]);
  }

  public navigateBack(): void {
    this.goBack(`/assets/equipment/list/${this.equipmentId}/timeline`);
  }
}
