import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { KeycloakService } from '../../../../../core/keycloak';
import { ProjectDataSource } from '../../../shared/project.datasource';
import { BaseProjectListComponent } from '../base/base-project-list.component';
import { ProjectCheckerService } from '../../../shared/services/project-checker.service';
import { ViewProject } from '../../../contract/view-project.interface';
import { debounceTime, filter } from 'rxjs/operators';
import { isDefined } from '../../../../../shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchProjectOrProjectGroup } from '../../../contract/search-project.interface';
import { TabService } from '../../../../../shared/tab.service';
import { ListType } from '../../../../../shared/enums/list-type.enum';
import { RouterHistory } from '../../../../../shared/router-history';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { CurrentItemPaginatorUtils } from 'app/shared/directives/current-item-paginator/current-item-paginator-utils.class';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-duotone-svg-icons';
import { faWallet } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ProjectStructureType } from '../../../shared/enums/project-structure-type';
import { ProjectSubscriptionsService } from '../../../shared/project-subscriptions.service';
import { MatDialog } from '@angular/material/dialog';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { ProjectAddEditComponent } from '../../project-add-edit/project-add-edit.component';
import { ProjectColumnService } from 'app/modules/disposition/shared/services/project-column.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'bh-project-list',
  templateUrl: 'project-list.component.html',
  styleUrls: ['project-list.component.scss'],
  animations: [
    trigger('rowsExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', borderWidth: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
})
export class ProjectListComponent extends BaseProjectListComponent
  implements SearchFilterActivatedView, AfterViewInit, OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public readonly isIdentifierOfProject = CurrentItemPaginatorUtils.isIdentifierOfProject;
  public readonly filters: FilterType[] = this.projectStore.filters;
  public readonly onFiltersUpdated: EventEmitter<any> = this.projectStore.onFiltersUpdated;
  public readonly onTotalCountUpdated: EventEmitter<number> = new EventEmitter<number>();

  public expandedProjects: SearchProjectOrProjectGroup[] = [];
  public readonly faAngleUp: IconDefinition = faAngleUp;
  public readonly faAngleDown: IconDefinition = faAngleDown;
  public readonly faWallet: IconDefinition = faWallet;
  public readonly searchSuggestionsField = 'projectListSuggestions';

  constructor(protected router: Router,
              protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public projectStore: ProjectDataSource,
              public columnService: ProjectColumnService,
              public projectCheckerService: ProjectCheckerService,
              private tabService: TabService,
              protected languageService: LanguageService,
              protected subscriptions: ProjectSubscriptionsService,
              private dialog: MatDialog,
              ) {
    super(authService, router, route, routerHistory, projectStore, languageService, subscriptions);
  }


  public ngAfterViewInit(): void {
    this.tabService.changeSiteTab(ListType.PROJECTS);
    this.columnService.pageSize
      .pipe(untilDestroyed(this))
      .subscribe((pageSize: number) => this.paginator.pageSize = pageSize);
    this.handleRouter();
    this.projectListSubscription();
    this.totalCountListener();
  }

  public ngOnDestroy(): void {
    // for @TakeUntilDestroy
    this.subscriptions.searchProjectsFormSubscription = null;
    this.subscriptions.currentProjectSubscription = null;
  }

  private projectListSubscription(): void {
    if (this.subscriptions.projectListSubscription === null) {
      this.subscriptions.projectListSubscription = this.projectStore.projects.pipe(
        untilDestroyed(this),
      ).subscribe({
        next: (projectList) => {
          this.expandedProjects = [];
          if (this.projectStore.searchTerms !== null && this.projectStore.searchTerms?.length > 0) {
            const projects = projectList.toArray();
            projects.forEach(project => {
              if (project.subProjectList?.length > 0) {
                this.expandedProjects.push(project);
              }
            });
          }
          const currentProject = this.projectStore.project();
          if (currentProject?.projectStructureType === ProjectStructureType.SUB_PROJECT ||
            currentProject?.projectStructureType === ProjectStructureType.INITIAL_SUB_PROJECT) {
            const projects = projectList.toArray();
            projects.forEach(project => {
              if (project.subProjectList?.length > 0) {
                project.subProjectList.forEach(subProject => {
                  if (subProject.projectId === currentProject.projectId) {
                    this.expandedProjects.push(project);
                    return;
                  }
                });
              }
            });
          }
        }
      });
    }
  }

  private totalCountListener(): void {
    this.projectStore.totalCount
      .pipe(untilDestroyed(this))
      .subscribe(count => this.onTotalCountUpdated.emit(count));
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.projectStore.updateFilterParams(commands);
    this.resetPagination();
  }

  public onPaginateChange(event: PageEvent): void {
    this.projectStore.updateListing(event.pageIndex, event.pageSize);
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
  }

  public selectProject(project: SearchProjectOrProjectGroup): void {
    if (project?.subProjectList.length > 0) {
      let index = this.expandedProjects.findIndex(expandedProject => expandedProject.projectId ===
        project.projectId);
      if (index !== -1) {
        this.expandedProjects.splice(index, 1);
      } else {
        this.expandedProjects.push(project);
      }
    }
  }

  public resolveArrowIcon(project: SearchProjectOrProjectGroup): IconDefinition {
    return this.expandedProjects.findIndex(expandedProject => expandedProject.projectId ===
      project.projectId) !== -1 ? this.faAngleUp : this.faAngleDown;
  }

  public checkExpanded(project: SearchProjectOrProjectGroup): boolean {
    return this.expandedProjects.findIndex(expandedProject => expandedProject.projectId ===
      project.projectId) !== -1;
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.termsValue);
  }

  public selectProjectAndNavigate(projectId: string, subProjectCount: number): void {
    if (this.projectStore.currentViewTab !== 'general' && subProjectCount) {
      this.projectStore.currentViewTab = 'general';
    }
    this.projectStore.setCurrentProject(projectId);
    this.navigateToTab(projectId, this.projectStore.currentViewTab);
  }

  public navigateToTab(projectId: string, tab: string): void {
    this.router.navigate([`sites/projects/list/${projectId}/${tab}`]);
  }

  public reset(): void {
    this.selectLabels([]);
    this.projectStore.initPagination();
    this.projectStore.updateSearchResults(true);
    this.searchForm.reset();
    this.filterForm.reset();
  }

  private handleRouter(): void {
    const create = this.route.snapshot.data['create'];
    if (create) {
      this.dialog.open(ProjectAddEditComponent, {disableClose: true}).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['sites/projects/list']);
        }
      });
    } else if (this.subscriptions.currentProjectSubscription === null) {
      this.subscriptions.currentProjectSubscription = this.projectStore.currentProject
        .pipe(
          debounceTime(500),
          filter(isDefined),
          untilDestroyed(this),
        )
        .subscribe((currentProject: ViewProject) => {
          this.navigateToTab(currentProject.projectId, this.projectStore.currentViewTab);
        });
    }
  }

  private resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.projectStore.updateListing(this.paginator.pageIndex, this.paginator.pageSize);
  }

  public getFirstLabels(project: SearchProjectOrProjectGroup, limitToTwo?: boolean): string[] {
    if (project == null) {
      return [];
    }
    const labelsClone: string[] = [...project.labels || []];
    if (limitToTwo === true || labelsClone.length > 6) {
      return labelsClone.slice(0, 2);
    }
    return labelsClone.slice(0, 4);
  }

  public getRemainingLabels(project: SearchProjectOrProjectGroup, limitToTwo?: boolean): string[] {
    if (project == null) {
      return [];
    }
    const labelsClone: string[] = [...project.labels || []];
    if (limitToTwo === true || labelsClone.length > 6) {
      return labelsClone.slice(2);
    }
    return labelsClone.slice(4);
  }
}
