import { LanguageService } from '../../../../shared/services/language.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { SearchUser } from '../../contract/search-user.interface';
import { UserStatus } from 'app/shared/enums/user-status.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivateUserCommand } from '../../contract/activate-user-command';
import { DeactivateUserCommand } from '../../contract/deactivate-user-command';
import { DeleteUserCommand } from '../../contract/delete-user-command';
import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { UsersStore } from '../../shared/users.store';
import { HttpErrorResponse } from '@angular/common/http';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UserOrganisation } from '../../shared/interfaces/user-organisation.interface';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faShieldAlt } from '@fortawesome/pro-solid-svg-icons';
import { UserAddEditComponent } from '../user-add-edit/user-add-edit.component';
import { ViewUser } from '../../contract/view-user.interface';
import { ResetUserPasswordCommand } from '../../contract/reset-user-password-command';
import { UserSyncKeycloakComponent } from '../user-sync-keycloak/user-sync-keycloak.component';
import { UserConfigListColumnService } from '../../shared/user-config-list-column.service';
import { UserAddEditConfigData } from '../user-add-edit/user-add-edit-config-data.interface';
import { environment } from 'environments/environment';

@UntilDestroy()
@Component({
  selector: 'bh-user-list',
  templateUrl: 'user-list.component.html',
  styleUrls: ['user-list.component.scss'],
})
export class UserListComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly faShieldAlt: IconDefinition = faShieldAlt;
  displayedColumns: string[] = ['name', 'organisation', 'role', 'validUntil', 'status', 'external', 'more'];
  userStatus = UserStatus;
  hasMoreOrganisations: boolean;
  searchForm: UntypedFormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // this is not implemented, just for view show
  selectedRole: string;
  roles = [
    {value: 'all', viewValue: `-- ${this.translate('modules.userrole.userList.showAllRoles')}`},
    {value: 'superadmin', viewValue: this.translate('modules.userrole.userList.superAdmin')},
    {value: 'flottenadmin', viewValue: this.translate('modules.userrole.userList.fleetAdmin')},
  ];

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              public usersStore: UsersStore,
              private columnService: UserConfigListColumnService,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  get termsValue(): string {
    return this.searchForm.get('terms').value;
  }

  ngOnInit(): void {

    if (this.showCustomerNameColumn()) {
      this.displayedColumns.splice(1, 0, 'customerName');
    }
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe((pageSize: number) => {
      this.paginator.pageSize = pageSize;
    });

    this.buildSearchForm();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    this.onSearchFormType();
  }

  onPaginateChange(event: PageEvent): void {
    this.usersStore.updateListing(event.pageIndex, event.pageSize);
    this.columnService.selectColumns([], event.pageSize);
  }

  onSearchFormClick(): void {
    this.updateOnSearch(0, this.paginator.pageSize);
  }

  public onOpenSyncDialog(): void {
    let realm = null;
    const dialogRef = this.dialog.open(UserSyncKeycloakComponent, {width: '800px'})
    dialogRef.afterClosed()
    .pipe(
      untilDestroyed(this),
      filter(res => realm = res))
    .subscribe(() => this.usersStore.syncKeycloak(realm));
  }

  addUser(): void {
    const dialogRef = this.dialog.open(UserAddEditComponent, {disableClose: true});
    dialogRef.afterClosed()
    .pipe(
      untilDestroyed(this),
      filter(res => res === dialogResults.SAVE))
    .subscribe(() => this.usersStore.updateListing(0, this.paginator.pageSize));
  }

  editUser(user: SearchUser): void {
    if (this.isUserPriviledgedAdmin(user.roles)) {
      this.router.navigate(['/users/edit/admin', user.userId]);
      return;
    }

    this.usersStore.getUser(user.userId)
      .pipe(
        untilDestroyed(this),
        switchMap((viewUser: ViewUser) => {
          const data: UserAddEditConfigData = { user: viewUser };
          const dialogRef = this.dialog.open<UserAddEditComponent>(UserAddEditComponent, { data });
          return dialogRef.afterClosed();
        }))
      .subscribe(() => this.usersStore.updateListing(this.paginator.pageIndex, this.paginator.pageSize));
  }

  menuVisible(user: SearchUser): boolean {

    const superAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Superadmin.toString())
      && this.hasAnyAuthority(
        [this.authorities.SUPERADMIN_DELETE,
          this.authorities.SUPERADMIN_UPDATE,
          this.authorities.SUPERADMIN_CHANGE_STATUS]);

    const partnerAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Partneradmin.toString())
      && this.hasAnyAuthority([this.authorities.PARTNERADMIN_DELETE,
        this.authorities.PARTNERADMIN_UPDATE,
        this.authorities.PARTNERADMIN_CHANGE_STATUS]);

    const fleetAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Flottenadmin.toString())
      && this.hasAnyAuthority([
        this.authorities.FLEETADMIN_DELETE,
        this.authorities.FLEETADMIN_UPDATE,
        this.authorities.FLEETADMIN_UPDATE]);

    const regularUserUpdate: boolean = this.hasAnyAuthority([
      this.authorities.USER_DELETE,
      this.authorities.USER_UPDATE,
      this.authorities.USER_UPDATE_STATUS]);

    return superAdminUpdate
      || partnerAdminUpdate
      || fleetAdminUpdate
      || regularUserUpdate;
  }

  canChangeStatusUser(user: SearchUser): boolean {
    const superAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Superadmin.toString())
      && this.hasAuthority(this.authorities.SUPERADMIN_CHANGE_STATUS);

    const partnerAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Partneradmin.toString())
      && this.hasAuthority(this.authorities.PARTNERADMIN_CHANGE_STATUS);

    const fleetAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Flottenadmin.toString())
      && this.hasAuthority(this.authorities.FLEETADMIN_CHANGE_STATUS);

    return superAdminUpdate || partnerAdminUpdate || fleetAdminUpdate || this.hasAuthority(this.authorities.USER_UPDATE_STATUS);
  }

  canUpdateUser(user: SearchUser): boolean {
    const superAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Superadmin.toString())
      && this.hasAuthority(this.authorities.SUPERADMIN_UPDATE);

    const partnerAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Partneradmin.toString())
      && this.hasAuthority(this.authorities.PARTNERADMIN_UPDATE);

    const fleetAdminUpdate: boolean = user.roles.find(r => r.name === this.priviledgedRole.Flottenadmin.toString())
      && this.hasAuthority(this.authorities.FLEETADMIN_UPDATE);

    return superAdminUpdate || partnerAdminUpdate || fleetAdminUpdate || this.hasAuthority(this.authorities.USER_UPDATE);
  }

  canDeleteUser(user: SearchUser): boolean {
    const superAdminDelete: boolean = user.roles.find(r => r.name === this.priviledgedRole.Superadmin.toString())
      && this.hasAuthority(this.authorities.SUPERADMIN_DELETE);

    const partnerAdminDelete: boolean = user.roles.find(r => r.name === this.priviledgedRole.Partneradmin.toString())
      && this.hasAuthority(this.authorities.PARTNERADMIN_DELETE);

    const fleetAdminDelete: boolean = user.roles.find(r => r.name === this.priviledgedRole.Flottenadmin.toString())
      && this.hasAuthority(this.authorities.FLEETADMIN_DELETE);

    return superAdminDelete || partnerAdminDelete || fleetAdminDelete || this.hasAuthority(this.authorities.USER_DELETE);

  }

  deleteUser(user: SearchUser): void {
    let cmd: DeleteUserCommand = new DeleteUserCommand();
    cmd.userId = user.userId;

    let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmTitle = this.translate('modules.userrole.userList.deleteUser');
    dialogRef.componentInstance.confirmMessage = this.translate('modules.userrole.userList.deleteUserMessage');
    dialogRef.componentInstance.secondaryConfirmMessage = this.translate('modules.userrole.userList.deleteUserMessageSecondary');

    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe((result: string) => {
      if (result === dialogResults.YES) {
        this.usersStore.deleteUser(cmd).pipe(untilDestroyed(this)).subscribe(
          () => {
          },
          (error: HttpErrorResponse) => {
            console.log('Delete User error', error.message);
          },
        );
      }
    });
  }

  activateUser(user: SearchUser): void {
    let cmd: ActivateUserCommand = new ActivateUserCommand();
    cmd.userId = user.userId;
    this.usersStore.activateUser(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
      },
      (error: HttpErrorResponse) => {
        console.log('Activate User error', error.message);
      },
    );
  }

  deactivateUser(user: SearchUser): void {
    let cmd: DeactivateUserCommand = new DeactivateUserCommand();
    cmd.userId = user.userId;
    this.usersStore.deactivateUser(cmd).pipe(untilDestroyed(this)).subscribe(
      () => {
      },
      (error: HttpErrorResponse) => {
        console.log('Deactivate User error', error.message);
      },
    );
  }

  resetPassword(user: SearchUser): void {
    let cmd = new ResetUserPasswordCommand();
    cmd.userId = user.userId;
    this.usersStore.resetPassword(cmd);
  }

  toggleUserStatus(event, user: SearchUser): void {
    event.checked ? this.activateUser(user) : this.deactivateUser(user);
  }

  displayOrganisationsInTooltip(organisations: UserOrganisation[]): string {
    let organisationNames = organisations.sort((org1, org2) => org1.name.localeCompare(org2.name))
    .map((organisation: UserOrganisation) => {
      return organisation.name;
    });

    return organisationNames.join(', ');
  }

  showCustomerNameColumn(): boolean {
    return this.hasAuthority(this.authorities.SUPERADMIN_VIEW) || this.hasAuthority(this.authorities.PARTNERADMIN_VIEW);
  }

  public resolveFullName(user: SearchUser): string {
    return user.firstName ? user.name + ', ' + user.firstName : user.name;
  }

  reset(): void {
    const pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this.usersStore.pagination.size,
      index: 0,
      numberOfElements: 0,
    };

    this.usersStore.pagination = pagination;
    this.usersStore.searchTerms = null;
    this.setSearchTerms();
  }

  private updateOnSearch(startIndex: number, size: number): Observable<string> {
    this.usersStore.searchTerms = this.termsValue;
    this.usersStore.updateListing(startIndex, size);

    return of('');
  }

  private setSearchTerms(): void {
    this.searchForm.patchValue({terms: this.usersStore.searchTerms});
  }

  private onSearchFormType(): void {
    this.searchForm.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      switchMap(() => this.updateOnSearch(0, this.paginator.pageSize)),
      untilDestroyed(this))
    .subscribe();
  }

  private buildSearchForm(): void {
    this.searchForm = new UntypedFormGroup({
      terms: new UntypedFormControl(),
    });

    this.setSearchTerms();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
