import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ManufacturerService } from '../../shared/manufacturer.service';
import { CreateManufacturerCommand } from '../../contract/create-manufacturer-command';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';

@Component({
  selector: 'bh-manufacturer-add',
  templateUrl: 'manufacturer-add.component.html',
  styleUrls: ['manufacturer-add.component.scss']
})
export class ManufacturerAddComponent extends GuardedNavigableInputComponent implements OnInit {

  public manufacturerAddForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private manufacturerService: ManufacturerService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory)
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.manufacturerAddForm = this.formBuilder.group({
      manufacturerName: ['', [<any>Validators.required]],
      manufacturerAbbreviation: [''],
      manufacturerMakerCode: ['']
    });
  }

  get manufacturerName() {
    return this.manufacturerAddForm.get('manufacturerName');
  }

  public save(): void {
    if (this.isValid()) {
      let formValue = this.manufacturerAddForm.value;
      let cmd: CreateManufacturerCommand = new CreateManufacturerCommand();
      cmd.manufacturerName = formValue.manufacturerName;
      cmd.manufacturerAbbreviation = formValue.manufacturerAbbreviation;
      cmd.manufacturerMakerCode = formValue.manufacturerMakerCode;
      this.manufacturerService.addManufacturer(cmd).subscribe(
        () => {
            // TODO: discuss again if is ok to redirect to list
            this.navigateAfterSubmit();
          },
        (error: HttpErrorResponse) => {
            console.log('create manufacturer error: ', error);
          }
      );
    }
  }

  public isValid(): boolean {
    return this.manufacturerAddForm.valid;
  }

  resetForm() {
    this.manufacturerAddForm.reset();
  }

}
