<mat-card class="entity-creation">
  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>person_add</mat-icon>
      <span>{{'modules.userrole.userAddEdit.createSuperAdmin'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              [disabled]="!isValid()"
              (throttledClick)="save()">
        {{'general.buttons.add'|translate}} |<mat-icon>add_circle</mat-icon>
      </button>
      <button mat-raised-button (click)="goBack('/users/list')">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button (click)="resetForm()">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <form [formGroup]="userAddForm" novalidate>
        <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="entity-creation-form-left" fxFlex="100">
            <div class="entity-creation-form-title">{{'modules.userrole.userAddEdit.userBaseData'|translate|uppercase}}</div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <mat-form-field fxFlex="33">
                <mat-label>{{'general.firstName'|translate|uppercase}}</mat-label>
                <input matInput
                       formControlName="firstName"
                       [maxlength]="fieldLimit.NAME">
              </mat-form-field>

              <mat-form-field fxFlex="33">
                <mat-label>{{'general.lastName'|translate|uppercase}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="name"
                       [maxlength]="fieldLimit.NAME">
                <mat-error *ngIf="name.hasError('required')">
                  {{'shared.validation.required'|translate:{ value: 'general.lastName'|translate } }}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="33">
                <mat-label>{{'general.email'|translate|uppercase}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="email"
                       [maxlength]="fieldLimit.EMAIL">
                <mat-error *ngIf="email.hasError('validEmail')">
                  {{'shared.validation.enterValidEmail'|translate}}
                </mat-error>
                <mat-error *ngIf="email.hasError('required')">
                  {{'shared.validation.required'|translate:{ value: 'general.email'|translate } }}
                </mat-error>
                <mat-error *ngIf="email.hasError('taken')">
                  {{'modules.userrole.base.emailTaken'|translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start" fxFill>
              <mat-form-field fxFlex="33">
                <mat-label>{{'modules.userrole.base.validUntil'|translate|uppercase}}</mat-label>
                <input matInput
                       formControlName="validUntil" autocomplete="off"
                       [matDatepicker]="picker"
                       [min]="today">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field fxFlex="33">
                <mat-label>{{'general.phone'|translate|uppercase}}</mat-label>
                <input type="tel" matInput
                       formControlName="mobileNumber"
                       [maxlength]="fieldLimit.PHONE_NUMBER">
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
