import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../../../core/custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceHealthComponent } from './service-health.component';
import { HealthCheckComponent } from './health-check/health-check-component';
import { ServiceHealthService } from './service/service-health.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [
    ServiceHealthComponent,
    HealthCheckComponent
  ],
  providers: [
    ServiceHealthService
  ]
})
export class ServiceHealthModule { }
