import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { List } from 'immutable';
import { SearchProjectOrProjectGroup } from '../contract/search-project.interface';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { ProjectsService } from './project.service';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { CustomerProjectLabel } from '../contract/customer-project-label.interface';
import { TransferCartColumnService } from './services/transfer-target-column.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class TransferTargetProjectDatasource {
  private _projects: BehaviorSubject<List<SearchProjectOrProjectGroup>> = new BehaviorSubject(List([]));
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);

  public readonly projects: Observable<List<SearchProjectOrProjectGroup>> = this._projects.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();

  public hideFinished = true;

  private _pagination: PaginationInfo;
  private _searchTerms: string;
  private _searchLabels: string[] = [];

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  get searchTerms(): string {
    return this._searchTerms;
  }

  set searchTerms(searchTerms: string) {
    this._searchTerms = searchTerms;
  }

  get searchLabels() {
    return this._searchLabels;
  }

  set searchLabels(searchLabels: string[]) {
    this._searchLabels = searchLabels;
  }

  constructor(
    private projectService: ProjectsService,
    private transferCartColumnService: TransferCartColumnService
  ) {
    this.setInitOptions();
  }

  public initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: 25,
      index: 0,
      numberOfElements: 0
    } as PaginationInfo;
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    let params = {
      page: index,
      size: size,
      labels: this._searchLabels,
      hideFinished: this.hideFinished,
      terms: null
    };
    this.pagination.size = size;
    this.pagination.index = index;
    params.terms = this._searchTerms;

    this.projectService
    .searchProjectsForTransfer(params)
    .subscribe((res: PagedResponse<SearchProjectOrProjectGroup>) => this.updateStoreData(res));

  }

  public updateStoreData(res: PagedResponse<SearchProjectOrProjectGroup>): void {
    this._projects.next(List(res.content));
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
  }

  public getCustomerLabels(filterByActiveProjects?: boolean): Observable<CustomerProjectLabel[]> {
    this.projectService
    .getCustomerLabels({hideFinished: filterByActiveProjects ? this.hideFinished : false, fromTransferCartView: true})
    .subscribe((labels: CustomerProjectLabel[]) => this._labels.next(labels));
    return this.labels;
  }

  public resetSearchLabels(): void {
    this._searchLabels = [];
  }

  private labelsSubscription(): void {
    this._labels
      .pipe(untilDestroyed(this))
      .subscribe(customerLabels =>
        this.searchLabels = this.searchLabels.filter(label =>
          customerLabels.map(l => l.name).includes(label)));
  }

  private pageSizeSubscription(): void {
    this.transferCartColumnService.transferCartProjectViewPageSize
      .pipe(untilDestroyed(this))
      .subscribe(pageSize => this.pagination.size = pageSize);
  }

  private setInitOptions(): void {
    this.pageSizeSubscription();
    this.labelsSubscription();
    this.getCustomerLabels();
    this.updateListing(this.pagination.index, this.pagination.size);
  }
}
