import { GuardedNavigableInputComponent } from '../../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { KeycloakService } from '../../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../../../shared/router-history';
import { takeWhile } from 'rxjs/operators';
import { ViewEmployee } from '../../../../../../contract/view-employee.interface';
import { EmployeeManagementDatasource } from '../../../../../../shared/employee-management.datasource';
import { UserConfigurationService } from '../../../../../../../../shared/services/user-configuration.service';

@Directive()
export abstract class BaseEmployeeViewPrivateDataComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public employee: ViewEmployee;

  protected componentActive = true;

  protected constructor(protected authService: KeycloakService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected routerHistory: RouterHistory,
                        protected employeeManagementStore: EmployeeManagementDatasource,
                        protected userConfigurationService: UserConfigurationService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.subscribeToCurrentEmployee();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployee) => {
      if (res) {
        this.employee = res;
      }
    });
  }

}
