import { ImageCapture } from 'image-capture';

export class CameraUtils {

  public static getRearCameraDevice(devices: Device[]): Device {
    if (!devices || devices.length < 1) {
      return undefined;
    }
    const rearCameraDevice: Device = devices.find(device => this.isBackCamera(device.label));
    // choose last camera if no rear camera found
    return rearCameraDevice ? rearCameraDevice : devices[devices.length - 1];
  }

  public static isTorchCompatible(deviceId: string, callback: (compatible: boolean) => void): void {
    const browser = <any>navigator;
    browser.getUserMedia = (browser.getUserMedia
      || browser.webkitGetUserMedia
      || browser.mozGetUserMedia
      || browser.msGetUserMedia);

    const constraints: MediaStreamConstraints = {
      video: {
        deviceId: deviceId,
      },
    };

    if (typeof navigator.mediaDevices.getUserMedia !== 'undefined') {
      // new standard
      navigator.mediaDevices.getUserMedia(constraints).then(stream => this.processStream(stream, callback));
    } else {
      // deprecated; still needed for some older browsers
      browser.getUserMedia(constraints, stream => this.processStream(stream, callback), () => {
      });
    }
  }

  private static isBackCamera(deviceLabel: string): boolean {
    return /back|rear|environment|rück/gi.test(deviceLabel);
  }

  private static processStream(stream: MediaStream, callback: (compatible: boolean) => void): void {
    const track: MediaStreamTrack = stream.getVideoTracks()[0];
    (new ImageCapture(track)).getPhotoCapabilities().then(capabilities => {
      const compatible: boolean = !!capabilities.torch
        || ('fillLightMode' in capabilities
          && capabilities.fillLightMode.length > 0
          && capabilities.fillLightMode !== 'none');
      callback(compatible);
    });
    track.stop();
  }
}
