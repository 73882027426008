import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CurrentEquipmentStatus } from '../contract/current-equipment-status.interface';
import { TelematicsLocationRecord } from '../contract/telematics-location-record.interface';
import { TelematicsService } from './telematics.service';
import * as moment from 'moment';
import { DatesService } from '../../../shared/services/dates.service';
import { EquipmentTelematics } from '../contract/equipment-telematics';
import { EquipmentFault } from '../contract/equipment-fault.interface';
import { EquipmentBeacon } from '../contract/equipment-beacon.interface';
import { EquipmentsDataSource } from './equipments.datasource';
import { canDetectBluetoothBeacons } from '../contract/telematics-unit-type.enum';
import { BatteryStatus } from '../../../shared/enums/battery-status.enum';
import { DigitalMatterProfile } from '../../../shared/assign-digital-matter-unit/digital-matter-profile';
import {
  DigitalMatterProfileDeployment
} from '../../../shared/assign-digital-matter-unit/digital-matter-profile-deployment';

@Injectable()
export class TelematicsDataSource {

  equipmentId: string;
  filterFrom: Date;
  filterTo: Date;
  params: Object;
  faultsLoading = false;
  faultPage: number;
  faultPageSize: number;

  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _currentEquipmentStatus: BehaviorSubject<CurrentEquipmentStatus> = new BehaviorSubject(null);
  private _currentEquipmentTelematics: BehaviorSubject<EquipmentTelematics> = new BehaviorSubject(null);
  private _currentEquipmentFaults: BehaviorSubject<EquipmentFault[]> = new BehaviorSubject(null);
  private _currentEquipmentBeacons: BehaviorSubject<EquipmentBeacon[]> = new BehaviorSubject(null);
  private _batteryStatus: BehaviorSubject<BatteryStatus> = new BehaviorSubject(null);
  private _currentEquipmentHasNextFaults: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _telematicsLocations: BehaviorSubject<TelematicsLocationRecord[]> = new BehaviorSubject(null);
  private _allDigitalMatterProfiles: BehaviorSubject<DigitalMatterProfile[]> = new BehaviorSubject(null);
  private _currentDigitalMatterProfile: BehaviorSubject<DigitalMatterProfileDeployment> = new BehaviorSubject(null);
  private equipmentDataSeriesRequest = new Subject<{ equipmentId: string, params: any }>();

  public readonly isLoading: Observable<boolean> = this._isLoading.asObservable();
  public readonly equipmentStatus: Observable<CurrentEquipmentStatus> = this._currentEquipmentStatus.asObservable();
  public readonly equipmentTelematics: Observable<EquipmentTelematics> = this._currentEquipmentTelematics.asObservable();
  public readonly equipmentFaults: Observable<EquipmentFault[]> = this._currentEquipmentFaults.asObservable();
  public readonly equipmentBeacons: Observable<EquipmentBeacon[]> = this._currentEquipmentBeacons.asObservable();
  public readonly batteryStatus: Observable<BatteryStatus> = this._batteryStatus.asObservable();
  public readonly equipmentHasNextFaults: Observable<boolean> = this._currentEquipmentHasNextFaults.asObservable();
  public readonly telematicsLocations: Observable<TelematicsLocationRecord[]> = this._telematicsLocations.asObservable();
  public readonly allDigitalMatterProfiles: Observable<DigitalMatterProfile[]> = this._allDigitalMatterProfiles.asObservable();
  public readonly digitalMatterProfileDeployment: Observable<DigitalMatterProfileDeployment> = this._currentDigitalMatterProfile.asObservable();

  constructor(private telematicsService: TelematicsService,
              private equipmentsDataSource: EquipmentsDataSource) {
    this.equipmentDataSeriesRequest
      .pipe(switchMap(({ equipmentId, params }) => this.telematicsService.getEquipmentDataSeries(equipmentId, params)))
      .subscribe(
        res => {
          this._currentEquipmentTelematics.next(EquipmentTelematics.fromData(res));
          this._isLoading.next(false);
        },
        (err) => {
          this._currentEquipmentTelematics.next(null);
          this._isLoading.next(false);
        }
      );
  }

  public init(equipmentId: string): void {
    this.faultPage = -1;
    this.faultPageSize = 20;
    this.equipmentId = equipmentId;
    this.initSavedFilter();
    this.getEquipmentTelematicsAndStatus(this.filterFrom, this.filterTo);
    this._currentEquipmentFaults.next([]);
    this.getEquipmentFaults(this.filterFrom, this.filterTo);
  }

  public getEquipmentTelematicsAndStatus(filterFrom: Date, filterTo: Date): void {
    this.updateSavedFilter(filterFrom, filterTo);
    this.params = {
      filterFrom: DatesService.date(this.filterFrom),
      filterTo: DatesService.date(this.filterTo)
    };

    this._isLoading.next(true);
    this.telematicsService.getEquipmentStatus(this.equipmentId)
      .subscribe(next => {
        this._currentEquipmentStatus.next(next);
        if (this.hasTelematicsData(next) && this.hasBeaconsData()) {
          this.getEquipmentBeacons();
        }
      });
    this.equipmentDataSeriesRequest.next({ equipmentId: this.equipmentId, params: this.params });
  }

  public getEquipmentFaults(filterFrom: Date, filterTo: Date): void {
    if (!this.faultsLoading) {
      this.faultsLoading = true;
      this.faultPage += 1;
      this.telematicsService.getEquipmentFaults(this.equipmentId, {
        filterFrom: DatesService.date(filterFrom),
        filterTo: DatesService.date(filterTo),
        size: this.faultPageSize,
        page: this.faultPage
      })
        .subscribe(
          (res) => {
            this._currentEquipmentFaults.next(this._currentEquipmentFaults.value.concat(res));
            this._currentEquipmentHasNextFaults.next(res.length === this.faultPageSize);
          },
          (err) => {
            this._currentEquipmentFaults.next([])
          },
          () => this.faultsLoading = false
        )
    }
  }

  public getEquipmentBeacons(): void {
    this.telematicsService.getEquipmentBeacons(this.equipmentId)
      .subscribe(
        (res) => this._currentEquipmentBeacons.next(res),
        () => this._currentEquipmentBeacons.next([]));
  }

  public getBatteryStatus(telematicsUnitId: string): void {
    this.telematicsService.getDigitalMatterBatteryStatus(telematicsUnitId)
      .subscribe(
        (res) => this._batteryStatus.next(res),
        () => this._batteryStatus.next(null))
  }

  public getLocationsByEquipment(equipmentId: string, filterFrom: Date, filterTo: Date): void {
    const params = {
      filterFrom: DatesService.date(filterFrom),
      filterTo: DatesService.date(filterTo)
    };

    this._isLoading.next(true);
    this.telematicsService
      .getLocationsByEquipment(equipmentId, params)
      .subscribe(
        res => this._telematicsLocations.next(res),
        () => this._telematicsLocations.next([]),
        () => this._isLoading.next(false)
      );
  }

  public getAllDigitalMatterProfiles(): void {
    this.telematicsService.getAllDigitalMatterProfiles()
      .subscribe(
        (res) => this._allDigitalMatterProfiles.next(res),
        () => this._batteryStatus.next(null))
  }

  public changeDigitalMatterProfile(telematicsUnitId: string, profileId: string) {
    return this.telematicsService.changeDigitalMatterProfile(telematicsUnitId, profileId);
  }

  public getCurrentDigitalMatterProfile(telematicsUnitId: string): void {
    this.telematicsService.getCurrentDigitalMatterProfile(telematicsUnitId)
      .subscribe(
        (res) => this._currentDigitalMatterProfile.next(res),
        () => this._batteryStatus.next(null))
  }

  public clearTelematicsLocations(): void {
    this._telematicsLocations.next([]);
  }

  public getEquipmentStatus(equipmentId: string): void {
    this._isLoading.next(true);
    this.telematicsService
      .getEquipmentStatus(equipmentId)
      .subscribe(
        res => this._currentEquipmentStatus.next(res),
        (err) => this._currentEquipmentStatus.next(null),
        () => this._isLoading.next(false));
  }

  private initSavedFilter(): void {
    this.filterFrom = this.filterFrom ?
      this.filterFrom :
      moment().subtract(1, 'month').toDate();
    this.filterTo = this.filterTo ?
      this.filterTo :
      moment().toDate();
  }

  private updateSavedFilter(filterFrom: Date, filterTo: Date): void {
    this.filterFrom = filterFrom;
    this.filterTo = filterTo;
  }

  private hasTelematicsData(status: CurrentEquipmentStatus): boolean {
    return !!status && !!status.latestChangeDate;
  }

  private hasBeaconsData(): boolean {
    let equipment = this.equipmentsDataSource.getCurrentEquipment();
    return equipment.assignedTelematicsUnits && canDetectBluetoothBeacons(equipment.assignedTelematicsUnits[0]?.key)
  }
}
