import { Pipe, PipeTransform } from '@angular/core';
import { GroupedEquipmentStatusCount, GroupedEquipmentSubStatusCount } from 'app/shared/contract/grouped-equipment-status-count.interface';
import { EquipmentStatusCategory } from 'app/modules/equipment/contract/equipment-status-category.enum';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';
import { EquipmentStatusCategoryPipe } from 'app/shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusChartDataPrepared, EquipmentStatusChartDataPreparedItem } from '../contract/equipment-status-chart-data-prepared.interface';

@Pipe({
  name: 'equipmentStatusPreparedData'
})
export class EquipmentStatusPreparedDataPipe implements PipeTransform {

  private categoryOrder = [
    EquipmentStatusCategory.OPERATIONAL,
    EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS,
    EquipmentStatusCategory.NOT_OPERATIONAL,
    EquipmentStatusCategory.NOT_AVAILABLE,
  ];

  constructor(
    private equipmentStatusNameResolver: EquipmentStatusNamePipe,
    private statusCategoryResolver: EquipmentStatusCategoryPipe
  ) {}

  public transform(data: GroupedEquipmentStatusCount[]): EquipmentStatusChartDataPrepared {
    return data && data?.length > 0
      ? this.getChartDataPrepared(data)
      : null;
  }

  private getChartDataPrepared(data: GroupedEquipmentStatusCount[]): EquipmentStatusChartDataPrepared {
    const datasetAmount = this.getDatasetAmount(data);
    const categoryMap = this.convertToCategoryMap(data);

    return new Array(datasetAmount)
      .fill(null)
      .map((_, index) =>
        this.categoryOrder.map(
          category => this.convertToChartDataPreparedItem(
            category,
            categoryMap.get(category).statuses[index]
          )
        )
      );
  }

  private getDatasetAmount(data: GroupedEquipmentStatusCount[]): number {
    return Math.max(...(data.map(group => group?.statuses?.length || 0)));
  }

  private convertToCategoryMap(data: GroupedEquipmentStatusCount[]): Map<EquipmentStatusCategory, GroupedEquipmentStatusCount> {
    const categoryMap = new Map<EquipmentStatusCategory, GroupedEquipmentStatusCount>();
    data.forEach(group => categoryMap.set(
      group?.category,
      { ...group, statuses: group.statuses.sort((a, b) => Number(a.rank) - Number(b.rank)) }
    ));
    return categoryMap;
  }

  private convertToChartDataPreparedItem(
    category: EquipmentStatusCategory,
    status: GroupedEquipmentSubStatusCount
  ): EquipmentStatusChartDataPreparedItem {
    if (status) {
      const { equipmentStatusId, rank, count } = status;
      return {
        equipmentStatusId, rank, count,
        category: this.statusCategoryResolver.transform(category),
        equipmentStatusName: this.equipmentStatusNameResolver.transform(status),
      }
    }
    return null;
  }

}
