import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentMaintenanceTaskService } from '../../../../../shared/services/equipment-maintenance-task.service';
import { BaseEquipmentViewCompletedTaskComponent } from '../base/base-equipment-view-completed-task.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { LifeCycleTask } from '../../../../../contract/life-cycle-task';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import { Modules } from '../../../../../../../shared/enums/modules.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { filter } from 'rxjs/operators';
import { LifeCycle } from 'app/modules/equipment/contract/life-cycle';
import {
  FileDownloadService
} from '../../../../../../../shared/components/test-place/secured-image/services/file-download.service';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-equipment-view-completed-task',
  templateUrl: './mobile-equipment-view-completed-task.component.html',
  styleUrls: ['./mobile-equipment-view-completed-task.component.scss']
})
export class MobileEquipmentViewCompletedTaskComponent extends BaseEquipmentViewCompletedTaskComponent implements OnInit, OnDestroy {

  protected lifeCycleTask: LifeCycleTask;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected equipmentMaintenanceTaskService: EquipmentMaintenanceTaskService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              protected languageService: LanguageService,
              protected fileDownloadService: FileDownloadService) {
    super(authService, router, route, equipmentMaintenanceTaskService, dialog, routerHistory, languageService,
      fileDownloadService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.allowedToSee) {
      this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
        const equipmentId = params['id'];
        const lifeCycleId = params['lifecycleId'];
        this.equipmentStore.getLifeCycleDetails(equipmentId, lifeCycleId)
          .pipe(
            filter((res: LifeCycle) => Boolean(res) && res.lifecycleId === lifeCycleId),
            untilDestroyed(this)
          )
          .subscribe(res => {
            this.lifeCycleTask = res as LifeCycleTask;
            this.maintenanceTaskId = this.lifeCycleTask.maintenanceTaskId;
            this.getMaintenanceTask();
        });
      });
    }
  }

  ngOnDestroy(): void {
  }

  get allowedToSee(): boolean {
    return this.hasAuthority(Authorities.MAINTENANCE_TASK_VIEW) && this.hasModule(Modules.SERVICE);
  }

  public navigateBack(): void {
    this.goBack(`/mobile/equipments/view/${this.route.snapshot.params.id}/timeline`);
  }
}
