import { environment } from 'environments/environment';
import { UpdateLeasingContractCommand } from '../contract/update-leasing-contract-command';
import { LanguageService } from 'app/shared/services/language.service';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { PaginationInfo } from '../../../shared/contract/pagination-info-interface';
import { SearchEquipment } from '../contract/search-equipment.interface';
import { ViewEquipment } from '../contract/view-equipment.interface';
import {
  EquipmentsService,
  ExportEquipmentsRequest,
  SearchEquipmentsBody,
  SearchEquipmentsRequest,
} from './equipments.service';
import { CreateEquipmentCommand } from '../contract/create-equipment-command';
import { UpdateEquipmentCommand } from '../contract/update-equipment-command';
import { DeleteEquipmentCommand } from '../contract/delete-equipment-command';
import { AddEquipmentLabelCommand } from '../contract/add-equipment-label-command';
import { AddEquipmentInvoiceCommand } from '../contract/add-equipment-invoice-command';
import { AddEquipmentDamageCommand } from '../contract/add-equipment-damage-command';
import { AddEquipmentIncidentCommand } from '../contract/add-equipment-incident-command';
import { DeleteEquipmentIncidentCommand } from '../contract/delete-equipment-incident-command';
import { ViewEquipmentCosts } from '../contract/view-equipment-costs.interface';
import { EquipmentInvoice } from 'app/modules/equipment/contract/equipment-invoice.interface';
import { DataSource } from '@angular/cdk/table';
import { AssignEquipmentToOrganisationCommand } from '../contract/assign-equipment-to-organisation-command';
import { SetEquipmentStandardImageCommand } from '../contract/set-equipment-standard-image-command';
import { DeleteEquipmentImageCommand } from '../contract/delete-equipment-image-command';
import { ImageLink } from 'app/modules/equipment/contract/image-link.interface';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { DeleteEquipmentDocumentCommand } from '../contract/delete-equipment-document-command';
import { AssignEquipmentToProjectCommand } from '../contract/assign-equipment-to-project-command';
import { ViewEquipmentProjectAssignment } from '../contract/view-equipment-project-assignment.interface';
import { DeleteEquipmentAssignmentCommand } from '../contract/delete-equipment-assignment-command';
import { UpdateEquipmentAssignmentCommand } from '../contract/update-equipment-assignment-command';
import { RevertManualOperatingHoursCommand } from '../contract/revert-manual-operating-hours-command';
import { DeleteEquipmentInvoiceCommand } from '../contract/delete-equipment-invoice-command';
import { DeleteEquipmentDamageCommand } from '../contract/delete-equipment-damage-command';
import { AssignSubEquipmentCommand } from '../contract/assign-sub-equipment-command';
import { UnAssignSubEquipmentCommand } from '../contract/un-assign-sub-equipment-command';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ViewEquipmentCostOverview } from '../contract/view-equipment-cost-overview.interface';
import { ViewEquipmentContracts } from '../contract/view-equipment-contracts.interface';
import { AddRentalContractCommand } from '../contract/add-rental-contract-command';
import { UpdateRentalContractCommand } from '../contract/update-rental-contract-command';
import { DeleteRentalContractCommand } from '../contract/delete-rental-contract-command';
import { AddSalesContractCommand } from '../contract/add-sales-contract-command';
import { UpdateSalesContractCommand } from '../contract/update-sales-contract-command';
import { AddServiceContractCommand } from '../contract/add-service-contract-command';
import { UpdateServiceContractCommand } from '../contract/update-service-contract-command';
import { DeleteServiceContractCommand } from '../contract/delete-service-contract-command';
import { AddInsuranceContractCommand } from '../contract/add-insurance-contract-command';
import { UpdateInsuranceContractCommand } from '../contract/update-insurance-contract-command';
import { DeleteInsuranceContractCommand } from '../contract/delete-insurance-contract-command';
import { DeleteSalesContractCommand } from '../contract/delete-sales-contract-command';
import { RentalDatasource } from './rental.datasource';
import { CustomerLabel } from '../../../shared/contract/customer-label.interface';
import { RemoveEquipmentLabelCommand } from '../contract/remove-equipment-label-command';
import { LifeCycle } from '../contract/life-cycle';
import { PairEquipmentScanCodeCommand } from '../contract/pair-equipment-scan-code-command';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  skip,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { OfflineDataStore } from '../../../shared/services/offline-data.store';
import { DispositionEquipment } from './disposition-equipment';
import { ScanEquipment } from './scan-equipment';
import { RevertManualMileageCommand } from '../contract/revert-manual-mileage-command';
import { UpdateEquipmentTechnicalFieldsCommand } from '../contract/update-equipment-technical-fields-command';
import { UpdateEquipmentChangeTypeCommand } from '../contract/update-equipment-change-type-command';
import { UpdateEquipmentDocumentDescriptionCommand } from '../contract/update-equipment-document-description-command';
import { AssetDocumentType } from '../contract/document-type.enum';
import { ContractDocumentLink } from '../contract/contract-document-link.interface';
import { DeleteReminderAfterCompletionCommand } from '../contract/delete-reminder-after-completion.command';
import { MaintenanceService } from './maintenance.service';
import { ValidateAssignmentParams } from './contracts/validate-assignment-params.interface';
import { RequestedAssignment } from './requested-assignment';
import {
  DeleteMaintenanceTaskAfterCompletionCommand
} from '../contract/delete-maintenance-task-after-completion.command';
import { LifeCycleDocumentLink } from '../contract/lifeCycleDocumentLink.interface';
import { AssignEquipmentToStockCommand } from '../contract/assign-equipment-to-stock-command';
import { DocuwareDocument } from '../../../shared/contract/docuware-document.interface';
import { DocuwareService } from './docuware.service';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { ViewEquipmentEmployeeAssignment } from '../contract/view-equipment-employee-assignment.interface';
import { EquipmentEmployeeService } from './equipment-employee.service';
import { AddEquipmentEmployeeAssignmentCommand } from '../contract/add-equipment-employee-assignment-command';
import {
  DeleteEquipmentEmployeeAddedLifecycleCommand
} from '../contract/delete-equipment-employee-added-lifecycle.command';
import {
  DeleteEquipmentEmployeeRemovedLifecycleCommand
} from '../contract/delete-equipment-employee-removed-lifecycle.command';
import { RemoveEquipmentEmployeeAssignmentCommand } from '../contract/remove-equipment-employee-assignment-command';
import { ReplaceEquipmentEmployeeAssignmentCommand } from '../contract/replace-equipment-employee-assignment-command';
import { UpdateEquipmentEmployeeAssignmentCommand } from '../contract/update-equipment-employee-assignment-command';
import {
  DeleteEquipmentEmployeeReplacedLifecycleCommand
} from '../contract/delete-equipment-employee-replaced-lifecycle.command';
import { EquipmentColumnService } from './equipment-column.service';
import { ColumnDefinition } from '../../../shared/column-definition';
import { TopfactService } from './topfact.service';
import { TopfactDocument } from '../../../shared/contract/topfact-document.interface';
import { DeleteEquipmentLeasingContractCommand } from '../contract/delete-equipment-leasing-contract-command';
import { AddLeasingContractCommand } from '../contract/add-leasing-contract-command';
import { isUndefined } from 'lodash';
import { AssignTelematicsUnitCommand } from '../contract/assign-telematics-unit-command';
import { UnassignTelematicsUnitCommand } from '../contract/unassign-telematics-unit-command';
import { EquipmentUsageRecord } from '../contract/equipment-usage-record';
import { EquipmentAmountInfo } from 'app/modules/disposition/contract/equipment-amount-info';
import { AmountsService } from 'app/modules/disposition/shared/amounts.service';
import { SetEquipmentTransportTypesCommand } from '../contract/set-equipment-transport-types-command';
import { filterByTerm } from '../../../shared/collection-utils';
import { ViewCustomerTelematicUnit } from './view-customer-telematic-unit.interface';
import { TelematicsService } from './telematics.service';
import { TelematicsUnitType } from '../contract/telematics-unit-type.enum';
import { ViewEquipmentTelematicsData } from '../contract/view-equipment-telematics-data.interface';
import { TransportService } from '../../transportation/shared/services/transport.service';
import { EquipmentCheckerService } from './services/equipment-checker.service';
import { KeycloakService } from '../../../core/keycloak';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { EquipmentStatusCategory } from '../contract/equipment-status-category.enum';
import { Status } from '../contract/status.interface';
import { UpdateEquipmentChangeStatusCommand } from '../contract/update-equipment-change-status-command';
import { EquipmentFilterService } from './services/equipment-filter.service';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { UsersService } from 'app/modules/userrole/shared/users.service';
import { TelematicsUnitAssignmentInfo } from '../contract/telematics-unit-assignment-info.interface';
import { ReassignTelematicsUnitCommand } from '../contract/reassign-telematics-unit-command';
import {
  MaintenanceDatasourceMediatorService
} from 'app/shared/services/mediator-notifier/maintenance-datasource-mediator/maintenance-datasource-mediator.service';
import { UpdateEquipmentDocumentNameCommand } from '../contract/update-equipment-document-name-command';
import { MaintenanceTaskService } from '../../maintenance/tasks/shared/service/maintenance-task.service';
import {
  UpdateMaintenanceTaskDocumentNameCommand
} from '../../maintenance/tasks/shared/update-maintenance-task-document-name.command';
import {
  UpdateMaintenanceTaskDocumentDescriptionCommand
} from '../../maintenance/tasks/shared/update-maintenance-task-document-description.command';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from 'assets/matomo/matomo-categories.enum';
import { matomoActions } from 'assets/matomo/matomo-actions.enum';
import {
  EMPTY_MAP_LOCATIONS_PAYLOAD,
  MapLocationsPayload
} from 'app/shared/modules/map/interfaces/map-locations-payload.interface';
import { SearchEquipmentLocation } from '../contract/search-equipment-location.interface';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';
import { SingleEmit } from 'app/shared/single-emit.class';
import { DTZ } from 'app/shared/timezone/date-timezone';
import { SetFavouriteEquipmentCommand } from '../contract/set-favourite-equipment.command';
import { RemoveFavouriteEquipmentCommand } from '../contract/remove-favourite-equipment.command';


@Injectable()
export class EquipmentsDataSource extends DataSource<SearchEquipment> {

  private _data: BehaviorSubject<SearchEquipment[]> = new BehaviorSubject([]);
  private _currentEquipment: BehaviorSubject<ViewEquipment> = new BehaviorSubject(null);
  private _currentTotalElements: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _equipmentLocations = new BehaviorSubject<MapLocationsPayload<SearchEquipmentLocation>>(EMPTY_MAP_LOCATIONS_PAYLOAD);
  private _labels: BehaviorSubject<CustomerLabel[]> = new BehaviorSubject([]);
  private _equipmentLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _images: BehaviorSubject<ImageLink[]> = new BehaviorSubject([]);
  private _documents: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private _files: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private _requestedAssignments: BehaviorSubject<RequestedAssignment[]> = new BehaviorSubject([]);
  private _invoiceDocuments: BehaviorSubject<DocumentLink[]> = new BehaviorSubject([]);
  private _contractDocuments: BehaviorSubject<ContractDocumentLink[]> = new BehaviorSubject([]);
  private _lifeCycleDocuments: BehaviorSubject<LifeCycleDocumentLink[]> = new BehaviorSubject([]);
  private _costCenters: BehaviorSubject<string[]> = new BehaviorSubject(null);
  private _currentViewTab = 'general';
  private _lifeCycle: BehaviorSubject<LifeCycle> = new BehaviorSubject(null);
  private _lifeCycles: BehaviorSubject<LifeCycle[]> = new BehaviorSubject([]);
  private _costs: Subject<ViewEquipmentCosts[]> = new Subject();
  private _costOverview: BehaviorSubject<ViewEquipmentCostOverview> = new BehaviorSubject(null);
  private _invoices: BehaviorSubject<EquipmentInvoice[]> = new BehaviorSubject(null);
  private _assignments: BehaviorSubject<ViewEquipmentProjectAssignment[]> = new BehaviorSubject(null);
  private _employeeAssignments: BehaviorSubject<ViewEquipmentEmployeeAssignment[]> = new BehaviorSubject(null);
  private _subEquipments: BehaviorSubject<ViewEquipment[]> = new BehaviorSubject(null);
  private _invoiceDetails: BehaviorSubject<EquipmentInvoice> = new BehaviorSubject(null);
  private _equipmentByScanCode: Subject<ScanEquipment> = new Subject();
  private _equipmentByScanCodeErrors: Subject<HttpErrorResponse> = new Subject();
  private _contracts: BehaviorSubject<ViewEquipmentContracts> = new BehaviorSubject(null);
  private _scanEquipmentsForTransfer: BehaviorSubject<ScanEquipment[]> = new BehaviorSubject([]);
  private _docuwareDocuments: BehaviorSubject<DocuwareDocument[]> = new BehaviorSubject([]);
  private _topfactDocuments: BehaviorSubject<TopfactDocument[]> = new BehaviorSubject([]);
  private _hasEquipmentDocuwareConnector: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _hasEquipmentTopfactConnector: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _docuwareRequestPending: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _topfactRequestPending: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _docuwareServiceAvailable: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _topfactServiceAvailable: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _equipmentUsage: BehaviorSubject<EquipmentUsageRecord[]> = new BehaviorSubject<EquipmentUsageRecord[]>([]);
  private _equipmentAmountInfo: Subject<EquipmentAmountInfo> = new Subject();
  private _equipmentAmountInfoError: Subject<HttpErrorResponse> = new Subject();
  private _transportTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _equipmentStatuses: BehaviorSubject<Status[]> = new BehaviorSubject<Status[]>([]);
  private _equipmentManualOperatingHoursAddedOrDeleted: Subject<void> = new Subject();
  private _equipmentManualMileageAddedOrDeleted: Subject<void> = new Subject();
  private readonly errorHandlerNotFoundEquipment = new Subject<HttpErrorResponse>();

  private equipmentsRequest = new Subject<SearchEquipmentsRequest>();
  private labelRequests: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private requestedAssignmentsRequest = new Subject<{ equipmentId?: string, projectId?: string }>();
  private _pagination: PaginationInfo;
  private _lifeCyclePagination: PaginationInfo;
  private _lifeCycleFilter: any;
  private _searchTerms: string;
  private _lifeCycleLast: boolean;
  private _sort: Sort;
  private _lastEquipment = false;
  private offlineDispositionEquipments: DispositionEquipment[];
  private currentlySelectedColumns: string[];
  private initialRequest = true;
  private _ignoreLocationSearchTerms = false;
  public readonly equipments: Observable<SearchEquipment[]> = this._data.asObservable();
  public readonly currentEquipment: Observable<ViewEquipment> = this._currentEquipment.asObservable();
  public readonly length: Observable<number> = this._currentTotalElements.asObservable();
  public readonly equipmentLocations = this._equipmentLocations.asObservable();
  public readonly equipmentLabels: Observable<string[]> = this._equipmentLabels.asObservable();
  public readonly labels: Observable<CustomerLabel[]> = this._labels.asObservable();
  public readonly filters = this.equipmentFilterService.filters;
  public readonly onFiltersUpdated = this.equipmentFilterService.onFiltersUpdated;
  public readonly images: Observable<ImageLink[]> = this._images.asObservable();
  public readonly documents: Observable<DocumentLink[]> = this._documents.asObservable();
  public readonly files: Observable<DocumentLink[]> = this._files.asObservable();
  public readonly requestedAssignments: Observable<RequestedAssignment[]> = this._requestedAssignments.asObservable();
  public readonly invoiceDocuments: Observable<DocumentLink[]> = this._invoiceDocuments.asObservable();
  public readonly contractDocuments: Observable<ContractDocumentLink[]> = this._contractDocuments.asObservable();
  public readonly lifeCycleDocuments: Observable<LifeCycleDocumentLink[]> = this._lifeCycleDocuments.asObservable();
  public readonly costCenters: Observable<string[]> = this._costCenters.asObservable();
  public readonly lifeCycles: Observable<LifeCycle[]> = this._lifeCycles.asObservable();
  public readonly costs: Observable<ViewEquipmentCosts[]> = this._costs.asObservable();
  public readonly invoices: Observable<EquipmentInvoice[]> = this._invoices.asObservable();
  public readonly assignments: Observable<ViewEquipmentProjectAssignment[]> = this._assignments.asObservable();
  public readonly employeeAssignments: Observable<ViewEquipmentEmployeeAssignment[]> = this._employeeAssignments.asObservable();
  public readonly subEquipments: Observable<ViewEquipment[]> = this._subEquipments.asObservable();
  public readonly equipmentByScanCode: Observable<ScanEquipment> = this._equipmentByScanCode.asObservable();
  public readonly equipmentByScanCodeErrors: Observable<HttpErrorResponse> = this._equipmentByScanCodeErrors.asObservable();
  public readonly contracts: Observable<ViewEquipmentContracts> = this._contracts.asObservable();
  public readonly docuwareDocuments: Observable<DocuwareDocument[]> = this._docuwareDocuments.asObservable();
  public readonly topfactDocuments: Observable<TopfactDocument[]> = this._topfactDocuments.asObservable();
  public readonly hasEquipmentDocuwareConnector: Observable<boolean> = this._hasEquipmentDocuwareConnector.asObservable();
  public readonly hasEquipmentTopfactConnector: Observable<boolean> = this._hasEquipmentTopfactConnector.asObservable();
  public readonly docuwareRequestPending: Observable<boolean> = this._docuwareRequestPending.asObservable();
  public readonly topfactRequestPending: Observable<boolean> = this._topfactRequestPending.asObservable();
  public readonly docuwareServiceAvailable: Observable<boolean> = this._docuwareServiceAvailable.asObservable();
  public readonly topfactServiceAvailable: Observable<boolean> = this._topfactServiceAvailable.asObservable();
  public readonly equipmentUsage: Observable<EquipmentUsageRecord[]> = this._equipmentUsage.asObservable();
  public readonly equipmentAmountInfo: Observable<EquipmentAmountInfo> = this._equipmentAmountInfo.asObservable();
  public readonly equipmentAmountInfoError: Observable<HttpErrorResponse> = this._equipmentAmountInfoError.asObservable();
  public readonly transportTypes: Observable<string[]> = this._transportTypes.asObservable();
  public readonly equipmentStatuses: Observable<Status[]> = this._equipmentStatuses.asObservable();
  public readonly equipmentManualOperatingHoursAddedOrDeleted: Observable<void>
    = this._equipmentManualOperatingHoursAddedOrDeleted.asObservable();
  public readonly equipmentManualMileageAddedOrDeleted: Observable<void>
    = this._equipmentManualMileageAddedOrDeleted.asObservable();

  private teltonikaIdsFilter: BehaviorSubject<string> = new BehaviorSubject('');
  private allTeltonikaIds: BehaviorSubject<ViewCustomerTelematicUnit[]> = new BehaviorSubject<ViewCustomerTelematicUnit[]>([]);

  public readonly filteredTeltonikaIds: Observable<ViewCustomerTelematicUnit[]> = combineLatest([
    this.allTeltonikaIds,
    this.teltonikaIdsFilter]
  ).pipe(
    map(([teltonikaIds, filterTerm]) => filterByTerm(teltonikaIds, filterTerm, 'telematicsUnitId')));


  public readonly currentTitle: Observable<string> = this._currentEquipment.pipe(
    filter(Boolean),
    map((equipment: ViewEquipment) => equipment.equipmentName ? equipment.equipmentName : equipment.equipmentModel)
  );
  public readonly currentSubtitle: Observable<string> = this._currentEquipment.pipe(
    filter(Boolean),
    map((equipment: ViewEquipment) => equipment.equipmentSerialNumber)
  );
  public readonly filteredCustomerLabels: Observable<string[]> = combineLatest(this._labels, this._equipmentLabels)
    .pipe(map(([availableLabels, selectedLabels]) =>
      availableLabels.map(label => label.name)
        .filter(label => !selectedLabels.includes(label)),
    ));
  public readonly availableCustomerLabels: Observable<string[]> = this.labels
    .pipe(map((availableLabels) =>
      availableLabels.map(label => label.name)
    ));

  constructor(private equipmentsService: EquipmentsService,
              private maintenanceService: MaintenanceService,
              private maintenanceTaskService: MaintenanceTaskService,
              private equipmentEmployeeService: EquipmentEmployeeService,
              private rentalStore: RentalDatasource,
              private snackBar: MatSnackBar,
              private router: Router,
              private onlineStatusService: OnlineStatusService,
              private offlineDataStore: OfflineDataStore,
              private docuwareService: DocuwareService,
              private topfactService: TopfactService,
              private equipmentColumnService: EquipmentColumnService,
              private languageService: LanguageService,
              private amountsService: AmountsService,
              private telematicService: TelematicsService,
              private transportService: TransportService,
              private equipmentCheckerService: EquipmentCheckerService,
              private authService: KeycloakService,
              private equipmentFilterService: EquipmentFilterService,
              private userConfigurationService: UserConfigurationService,
              private usersService: UsersService,
              private maintenanceMediatorService: MaintenanceDatasourceMediatorService,
              private matomoTracker: MatomoTracker,
              private dialog: MatDialog,
  ) {
    super();
    this.setDefaultValues();
    this.offlineDataStore.offlineDispositionEquipments.subscribe(offlineEquipments =>
      this.offlineDispositionEquipments = offlineEquipments);
    this.equipmentColumnService.selectedColumns.subscribe((columns: ColumnDefinition[]) => {
      this.currentlySelectedColumns = columns.map((c: ColumnDefinition) => c.cdkColumnDef);
    });
    this.initEquipmentRequestListener();
    this.initPaginatorPageSizeListener();
    this.initLabelRequestListener();
    this.initRequestedAssignmentsRequest();
    this.initErrorHandlerListener();
  }

  get pagination(): PaginationInfo {
    if (!this._pagination) {
      this.initPagination();
    }
    return this._pagination;
  }

  set pagination(pagination: PaginationInfo) {
    this._pagination = pagination;
  }

  get lifeCyclePagination(): PaginationInfo {
    if (!this._lifeCyclePagination) {
      this.initLifeCyclePagination();
    }
    return this._lifeCyclePagination;
  }

  get lifeCycleFilter(): any {
    if (!this._lifeCycleFilter) {
      this.initLifeCycleFilter();
    }
    return this._lifeCycleFilter;
  }

  set lifeCyclePaginationSize(size: number) {
    this._lifeCyclePagination.size = size;
  }

  set lifeCycleTermFilter(term: string) {
    this._lifeCycleFilter.term = term;
  }

  get lifeCycleTermFilter(): string {
    return this.lifeCycleFilter.term;
  }

  set lifeCycleLogDateFromFilter(date: string) {
    this._lifeCycleFilter.fromDate = date;
  }

  get lifeCycleLogDateFromFilter(): string {
    return this.lifeCycleFilter.fromDate;
  }

  set lifeCycleLogDateToFilter(date: string) {
    this._lifeCycleFilter.toDate = date;
  }

  get lifeCycleLogDateToFilter(): string {
    return this.lifeCycleFilter.toDate;
  }

  set lifeCycleTypesFilter(lifeCycleTypes: string[]) {
    this._lifeCycleFilter.lifeCycleTypes = lifeCycleTypes;
  }

  get lifeCycleTypesFilter(): string[] {
    return this.lifeCycleFilter.lifeCycleTypes;
  }

  get currentViewTab(): string {
    return this._currentViewTab;
  }

  set currentViewTab(currentViewTab: string) {
    this._currentViewTab = currentViewTab;
  }

  get lifeCycleLast(): boolean {
    return this._lifeCycleLast;
  }

  get searchTerms() {
    return this._searchTerms;
  }

  set searchTerms(terms: string) {
    if (!terms || terms.length < 4000) {
      this._searchTerms = terms;
      this.rentalStore.searchTerms = terms;
    } else {
      this.snackBar.open(
        this.languageService.getInstant('general.snackBarMessages.tooLongCannotSearch'),
        undefined,
        { duration: 5000 });
    }
  }

  public get ignoreLocationSearchTerms(): boolean {
    return this._ignoreLocationSearchTerms;
  }

  public set ignoreLocationSearchTerms(ignoreLocationSearchTerms: boolean) {
    this._ignoreLocationSearchTerms = ignoreLocationSearchTerms;
  }

  get sort() {
    return this._sort;
  }

  set sort(sort: Sort) {
    this._sort = sort;
  }

  get lastEquipment(): boolean {
    return this._lastEquipment;
  }

  set lastEquipment(lastEquipment: boolean) {
    this._lastEquipment = lastEquipment;
  }

  get scanEquipmentsForTransfer(): Observable<ScanEquipment[]> {
    return this._scanEquipmentsForTransfer.asObservable();
  }

  get isSearchFiltersApplied(): boolean {
    return this.equipmentFilterService.isSearchFiltersApplied;
  }

  connect(): Observable<SearchEquipment[]> {
    return this.equipments;
  }

  disconnect() {

  }

  initLifeCyclePagination() {
    this._lifeCyclePagination = {
      totalElements: 0,
      totalPages: 0,
      size: 10,
      index: 0,
      numberOfElements: 0,
    };

    this._lifeCycleLast = false;
  }

  initLifeCycleFilter() {
    this._lifeCycleFilter = {};
  }

  private initErrorHandlerListener(): void {
    this.errorHandlerNotFoundEquipment
      .pipe(
        exhaustMap(() => this.notFoundEquipmentDialogOpen()))
      .subscribe(() => {
        this.equipments
          .pipe(skip(1), take(1))
          .subscribe(res => this.router.navigate(['assets/equipment/list', res?.[0]?.equipmentId || '']));
        this.updateListing();
        this.updateFilters();
      });
  }

  public handleNotFoundEquipment(error: HttpErrorResponse): void {
    this.errorHandlerNotFoundEquipment.next(error);
  }

  private notFoundEquipmentDialogOpen(): Observable<any> {
    const dialogRef = this.dialog.open(WarningDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.message =
      this.languageService.getInstant('modules.equipment.confirmation.message.errorHandlingEquipmentNotAvailable');
    return dialogRef.afterClosed();
  }

  public updateCurrentEquipment(): void {
    if (this._currentEquipment.value) {
      this.changeCurrentEquipment(this._currentEquipment.value.equipmentId);
    } else {
      this.selectCurrentEquipmentOrDefault();
    }
  }

  /**
   * You should never have to call this.
   * Use the router to navigate to /assets/equipment/list/*equipmentId*   optionally with a specific tab. Default is currentViewTab
   * BaseEquipmentViewComponent calls this method whenever the equipmentId changes.
   * You can use updateCurrentEquipment() to check for changes to this currentEquipment in the backend.
   **/
  public changeCurrentEquipment(equipmentId: string): void {
    if (equipmentId) {
      this.equipmentsService.getEquipment(equipmentId).subscribe(res => {
        this._currentEquipment.next(res);
        this._equipmentLabels.next(res.labels ? res.labels : []);
      });
    } else {
      this._currentEquipment.next(null);
    }
  }

  public addEquipment(cmd: CreateEquipmentCommand): void {
    this.equipmentsService
      .addEquipment(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe((newEquipmentId: string) => {
          this.updateListingAndCostCenters();
          this.router.navigate(['/assets/equipment/list', newEquipmentId]);
        },
        (error: HttpErrorResponse) => {
          console.log('create equipment error: ', error);
        },
      );
  }

  public getCurrentEquipment(): ViewEquipment {
    return this._currentEquipment.value;
  }

  public loadTeltonikaIds(unitType: TelematicsUnitType): void {
    this.telematicService.getTelematicsUnitNumbers(unitType)
      .subscribe(res => this.allTeltonikaIds.next(res));

  }

  public filterTeltonikaIds(value: string): void {
    this.teltonikaIdsFilter.next(value);
  }

  changeEquipmentType(cmd: UpdateEquipmentChangeTypeCommand): Observable<string> {
    return this.equipmentsService
      .changeEquipmentType(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateListingAndCostCenters();
          this.updateFilterEquipmentType();
          return of(res);
        }));
  }

  updateEquipment(cmd: UpdateEquipmentCommand): Observable<string> {
    return this.equipmentsService
      .updateEquipment(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.updateCurrentEquipment();
          this.updateFilters();
          return of(res);
        }));
  }

  updateEquipmentTechnicalFields(cmd: UpdateEquipmentTechnicalFieldsCommand): Observable<string> {
    return this.equipmentsService
      .updateEquipmentTechnicalFields(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  public deleteEquipment(cmd: DeleteEquipmentCommand): void {
    this.equipmentsService
      .deleteEquipment(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        tap(() => this.updateListing(0)),
        switchMap(() => this.equipments),
        skip(1),
        take(1)
      )
      .subscribe({
        next: (res: SearchEquipment[]) => {
          this.updateFilters();
          const defaultId = res?.[0]?.equipmentId;
          if (defaultId) {
            this.router.navigate(['assets', 'equipment', 'list', defaultId]);
          } else {
            this.changeCurrentEquipment(null);
            this.router.navigate(['assets', 'equipment', 'list']);
          }
        },
        error: (error: HttpErrorResponse) => console.log(error),
      });
  }

  getEquipmentLocations(request: SearchEquipmentsRequest, centerMap: boolean, equipment = null): Observable<any> {
    if (equipment) {
      this._equipmentLocations.next({ centerMap, locations: [equipment] });
      return;
    }
    this.equipmentsService
      .getEquipmentLocations(request)
      .subscribe(res => {
        this._equipmentLocations.next({ centerMap, locations: res || [] });
      });

    return merge(this._equipmentLocations);
  }

  getEquipmentLocationsSearch(request: SearchEquipmentsRequest, centerMap: boolean): Observable<any> {
    this.equipmentsService
      .getEquipmentLocationsSearch(request)
      .subscribe(res => {
        this._equipmentLocations.next({ centerMap, locations: res || [] });
      });

    return merge(this._equipmentLocations);
  }

  public selectCurrentEquipmentOrDefault(): void {
    if (this._currentEquipment.value) {
      this.router.navigate(['assets/equipment/list', this._currentEquipment.value.equipmentId]);
    } else {
      this.equipments.pipe(
        filter((res: SearchEquipment[]) => res && res.length > 0),
        take(1),
      ).subscribe((res: SearchEquipment[]) => {
        this.router.navigate(['assets/equipment/list', res[0].equipmentId]);
      });
    }
  }

  public updateListing(index: number = this.pagination.index, size: number = this.pagination.size): void {
    if (size !== this.pagination.size) {
      this.equipmentColumnService.selectPageSize(size);
      this.matomoTracker.trackEvent(
        matomoCategories.EQUIPMENT_LIST,
        matomoActions.CHANGE_PAGE_SIZE,
        'to',
        size);
    }
    this.pagination.index = index;
    this.pagination.size = size;
    this.equipmentsRequest.next(this.defineSearchEquipmentParams(size, index));
  }

  public getIds(): Observable<any> {
    return this.equipmentsService.getEquipmentIds(this.defineSearchEquipmentParams());
  }

  public updateMap(centerMap = false, equipment?: any) {
    const {
      customerLabels,
      equipmentTypes,
      equipmentOrganisations,
      matchAllLabels
    } = this.equipmentFilterService.getFilterParams();
    const searchColumns = this.currentlySelectedColumns;
    let request: SearchEquipmentsRequest = {
      body: {
        customerLabels,
        equipmentTypes,
        equipmentOrganisations,
        matchAllLabels,
        searchColumns,
        excludedEquipmentStatusCategory: [EquipmentStatusCategory.NOT_AVAILABLE]
      } as SearchEquipmentsBody
    };

    if (this._searchTerms && !this.ignoreLocationSearchTerms) {
      request.body.terms = this._searchTerms;
      if (this.canGetEquipmentLocations && !equipment) {
        this.getEquipmentLocationsSearch(request, centerMap);
      } else if (!!equipment) {
        this.getEquipmentLocations(request, centerMap, equipment);
      }
    } else if (this.ignoreLocationSearchTerms) {
      if (this.canGetEquipmentLocations) {
        request.body = {
          equipmentOrganisations: [],
          customerLabels: [],
          equipmentTypes: [],
          excludedEquipmentStatusCategory: [EquipmentStatusCategory.NOT_AVAILABLE]
        } as SearchEquipmentsBody;
        this.getEquipmentLocations(request, centerMap, equipment);
      }
    } else {
      if (this.canGetEquipmentLocations) {
        this.getEquipmentLocations(request, centerMap, equipment);
      }
    }
  }

  public clearStoredLocations(): void {
    this._equipmentLocations.next(EMPTY_MAP_LOCATIONS_PAYLOAD);
  }

  public get canGetEquipmentLocations(): boolean {
    return this.authService.hasAnyAuthority([Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]);
  }

  public getMapConfiguration(): MapConfiguration {
    return this.userConfigurationService.getMapConfigurationEquipment();
  }

  public saveMapConfiguration(mapConfig: MapConfiguration): void {
    this.userConfigurationService.saveMapConfigurationEquipment(mapConfig);
    this.usersService.saveMapConfigurationEquipment(mapConfig).subscribe();
  }

  public removeLabel(cmd: RemoveEquipmentLabelCommand): void {
    const newLabels = [...this._equipmentLabels.value];
    newLabels.splice(newLabels.indexOf(cmd.label), 1);
    this._equipmentLabels.next(newLabels);

    this.equipmentsService
      .removeLabel(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe({
        next: () => {
          this.updateFilterLabel();
          this.refreshCustomerLabelAndEquipment(cmd.equipmentId);
        },
        error: error => {
          this.refreshCustomerLabelAndEquipment(cmd.equipmentId);
          console.log('error on removing label', error);
        }
      })
  }

  public saveLabel(cmd: AddEquipmentLabelCommand): void {
    this.addToCurrentEquipmentLabels(cmd.label);
    this.equipmentsService
      .saveLabel(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe({
        next: () => {
          this.updateFilterLabel();
          this.refreshCustomerLabelAndEquipment(cmd.equipmentId);
        },
        error: error => {
          this.refreshCustomerLabelAndEquipment(cmd.equipmentId);
          console.log('error on adding label', error);
        }
      });
  }

  public addLabelsToMultipleEquipments(cmdList: AddEquipmentLabelCommand[]): void {
    cmdList.forEach(cmd =>
      this.equipmentsService
        .saveLabel(cmd)
        .subscribe({
          next: () => {
            this.updateFilterLabel();
            this.refreshCustomerLabelAndEquipment(this._currentEquipment.value.equipmentId);
          },
          error: error => {
            this.refreshCustomerLabelAndEquipment(this._currentEquipment.value.equipmentId);
            console.log('error on adding labels', error);
          }
        }));
  }

  public getCustomerLabels(): Observable<CustomerLabel[]> {
    this.equipmentsService
      .getCustomerLabels()
      .subscribe((labels: CustomerLabel[]) => this._labels.next(labels));

    return this.labels;
  }

  public getTransportTypes(): Observable<string[]> {
    this.transportService.getTransportationTasksType()
      .subscribe((transportTypes: string[]) => this._transportTypes.next(transportTypes));

    return this.transportTypes;
  }

  public getNewEquipmentStatuses(): Observable<Status[]> {
    this.equipmentsService.getNewEquipmentStatuses().subscribe((res: Status[]) => this._equipmentStatuses.next(res))

    return this.equipmentStatuses;
  }

  public setEquipmentTransportTypes(command: SetEquipmentTransportTypesCommand): void {
    this.equipmentsService.setEquipmentTransportTypes(command)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => {
        this.updateCurrentEquipment();
        this.updateListing();
        this.updateFilterTransportOption();
      })
  }

  public updateEquipmentUsage(equipmentId: string): void {
    this.equipmentsService
      .getEquipmentUsage(equipmentId)
      .subscribe((res: EquipmentUsageRecord[]) => this._equipmentUsage.next(res));
  }

  deleteManualOperatingHours(cmd: RevertManualOperatingHoursCommand) {
    return this.equipmentsService
      .deleteManualOperatingHours(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          const equipmentId = this._currentEquipment.getValue().equipmentId;
          this.getLifeCycles(equipmentId);
          this.updateCurrentEquipment();
          this.emitEquipmentManualOperatingHoursSubject();
          return of(res);
        }));
  }

  emitEquipmentManualOperatingHoursSubject(): void {
    this._equipmentManualOperatingHoursAddedOrDeleted.next();
  }

  emitEquipmentManualMileageSubject(): void {
    this._equipmentManualMileageAddedOrDeleted.next();
  }

  deleteManualMileage(cmd: RevertManualMileageCommand) {
    return this.equipmentsService
      .deleteManualMileage(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          const equipmentId = this._currentEquipment.getValue().equipmentId;
          this.getLifeCycles(equipmentId);
          this.updateCurrentEquipment();
          this.emitEquipmentManualMileageSubject();
          return of(res);
        }));
  }

  public getLifeCycles(equipmentId: string): void {
    this.equipmentsService
      .getLifeCycles(equipmentId, this.lifeCycleFilter, this.lifeCyclePagination.index, this.lifeCyclePagination.size)
      .subscribe({
        next: (lifeCycles: PagedResponse<LifeCycle>) => {
          this._lifeCycles.next(lifeCycles.content);
          this._lifeCycleLast = lifeCycles.last;
        },
        error: error => console.log('error get life cycle: ', error)
      });
  }

  getLifeCycleDetails(equipmentId: string, lcId: string): Observable<LifeCycle> {
    this.equipmentsService
      .getLifeCycleDetails(equipmentId, lcId)
      .subscribe(res => {
        this._lifeCycle.next(res);
      });
    return merge(this._lifeCycle);
  }

  addInvoice(cmd: AddEquipmentInvoiceCommand): Observable<any> {
    return this.equipmentsService
      .addInvoice(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.getCosts(this._currentEquipment.getValue().equipmentId);
          return of(res);
        }));
  }

  addDocument(document: AttachedDocument, equipmentId: string): Observable<any> {
    return this.equipmentsService
      .addDocument(document, equipmentId)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.getDocuments(this._currentEquipment.getValue().equipmentId);
          return of(res);
        }));
  }

  updateInvoice(cmd: AddEquipmentInvoiceCommand): Observable<any> {
    return this.equipmentsService
      .updateInvoice(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.getCosts(this._currentEquipment.getValue().equipmentId);
          return of(res);
        }));
  }

  deleteInvoice(cmd: DeleteEquipmentInvoiceCommand): Observable<any> {
    return this.equipmentsService
      .deleteInvoice(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.getCosts(this._currentEquipment.getValue().equipmentId);
          return of(res);
        }));
  }

  deleteInvoiceLifecycle(cmd: DeleteEquipmentInvoiceCommand): Observable<any> {
    return this.equipmentsService
      .deleteInvoice(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  addDamage(cmd: AddEquipmentDamageCommand): Observable<any> {
    return this.equipmentsService
      .addDamage(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateDamage(cmd: AddEquipmentDamageCommand): Observable<any> {
    return this.equipmentsService
      .updateDamage(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteDamage(cmd: DeleteEquipmentDamageCommand): Observable<any> {
    return this.equipmentsService
      .deleteDamage(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  addIncident(cmd: AddEquipmentIncidentCommand): Observable<any> {
    return this.equipmentsService
      .addIncident(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateIncident(cmd: AddEquipmentIncidentCommand): Observable<any> {
    return this.equipmentsService
      .updateIncident(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteIncident(cmd: DeleteEquipmentIncidentCommand): Observable<any> {
    return this.equipmentsService
      .deleteIncident(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteReminderAfterCompletion(cmd: DeleteReminderAfterCompletionCommand): Observable<any> {
    return this.maintenanceService
      .deleteReminderAfterCompletion(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.maintenanceMediatorService.notifyTaskDeleted(this, cmd.reminderId);
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteMaintenanceTaskAfterCompletion(
    cmd: DeleteMaintenanceTaskAfterCompletionCommand,
    equipmentId: string): Observable<any> {
    return this.maintenanceService
      .deleteMaintenanceTaskAfterCompletion(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.maintenanceMediatorService.notifyTaskDeleted(this, cmd.maintenanceTaskId);
          this.getLifeCycles(equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  getFiles(equipmentId: string): Observable<DocumentLink[]> {
    this.equipmentsService
      .getFiles(equipmentId)
      .subscribe(res => {
        this._files.next(res);
      });
    return merge(this.files);
  }

  getImages(equipmentId: string): Observable<ImageLink[]> {
    this.equipmentsService
      .getImages(equipmentId)
      .subscribe(res => {
        this._images.next(res);
      });
    return merge(this.images);
  }

  getDocuments(equipmentId: string): Observable<DocumentLink[]> {
    this.equipmentsService
      .getDocuments(equipmentId)
      .subscribe(res => {
        this._documents.next(res);
      });
    return merge(this.documents);
  }

  getDocuwareDocuments(equipmentId: string): Observable<DocuwareDocument[]> {
    this._docuwareRequestPending.next(true);
    let params = {
      equipmentId: equipmentId
    };
    this.docuwareService
      .getDocuments(params)
      .subscribe(res => {
        this._docuwareServiceAvailable.next(true);
        this._docuwareDocuments.next(res);
        this._docuwareRequestPending.next(false);
      }, () => {
        this._docuwareServiceAvailable.next(false);
        this._docuwareDocuments.next([]);
        this._docuwareRequestPending.next(false);
      });
    return merge(this.docuwareDocuments);
  }

  getTopfactDocuments(equipmentId: string): void {
    this._topfactRequestPending.next(true);
    let params = {
      equipmentId: equipmentId
    };
    this.topfactService
      .getDocuments(params)
      .subscribe(res => {
        this._topfactServiceAvailable.next(true);
        this._topfactDocuments.next(res);
        this._topfactRequestPending.next(false);
      }, () => {
        this._topfactServiceAvailable.next(false);
        this._topfactDocuments.next([]);
        this._topfactRequestPending.next(false);
      });
  }

  public getRequestedAssignments(params: { equipmentId?: string, projectId?: string }): void {
    this.requestedAssignmentsRequest.next(params);
  }

  updateEquipmentDocuwareConnectorExists(): void {
    let params = {
      connectorType: 'EQUIPMENT_CONNECTOR'
    };
    this.docuwareService.connectorExists(params)
      .subscribe(res => {
        this._hasEquipmentDocuwareConnector.next(res);
      });
  }

  updateEquipmentTopfactConnectorExists(): void {
    this.topfactService.connectorExists()
      .subscribe(res => {
        this._hasEquipmentTopfactConnector.next(res);
      });
  }

  getDocuwareDocument(equipmentId: string, documentId: string) {
    return this.docuwareService.getDocument(equipmentId, documentId);
  }

  public getTopfactDocument(docId: string, fileId: string): Observable<Blob> {
    return this.topfactService.getDocument(docId, fileId);
  }

  setStandardImage(cmd: SetEquipmentStandardImageCommand) {
    return this.equipmentsService
      .setStandardImage(cmd)
      .pipe(
        delay(environment.DELAY_LONGEST),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteImage(cmd: DeleteEquipmentImageCommand) {
    return this.equipmentsService
      .deleteImage(cmd)
      .pipe(
        delay(environment.DELAY_LONGEST),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteDocument(cmd: DeleteEquipmentDocumentCommand) {
    return this.equipmentsService
      .deleteDocument(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  public getCosts(equipmentId: string, performanceType?: string): void {
    this.equipmentsService
      .getCosts(equipmentId, performanceType)
      .subscribe(
        (costs: ViewEquipmentCosts[]) => this._costs.next(costs),
        error => console.log('error get costs: ', error));
  }

  public getCostOverview(equipmentId: string): Observable<ViewEquipmentCostOverview> {
    this.equipmentsService
      .getCostOverview(equipmentId)
      .subscribe(res => {
        this._costOverview.next(res);
      });
    return this._costOverview;

  }

  getCostCenters() {
    this.equipmentsService
      .getCostCenters()
      .subscribe(res => {
        this._costCenters.next(res);
      });
  }

  getInvoices(equipmentId: string, params?: Object): Observable<EquipmentInvoice[]> {
    this.equipmentsService
      .getInvoices(equipmentId, params)
      .subscribe(res => {
        this._invoices.next(res);
      });
    return merge(this._invoices);
  }

  getInvoicesDocuments(equipmentId: string): Observable<DocumentLink[]> {
    this.equipmentsService
      .getDocumentsForAllInvoices(equipmentId)
      .subscribe(res => {
        this._invoiceDocuments.next(res);
      });
    return this.invoiceDocuments;
  }

  getContractDocuments(equipmentId: string): Observable<ContractDocumentLink[]> {
    this.equipmentsService
      .getDocumentsForAllContracts(equipmentId)
      .subscribe(res => {
        this._contractDocuments.next(res);
      });
    return this.contractDocuments;
  }

  getLifeCycleDocuments(equipmentId: string): Observable<LifeCycleDocumentLink[]> {
    this.equipmentsService
      .getDocumentsForAllLifeCycles(equipmentId)
      .subscribe((lifeCycleDocuments: LifeCycleDocumentLink[]) => this._lifeCycleDocuments.next(lifeCycleDocuments));
    return this.lifeCycleDocuments;
  }

  getAssignments(equipmentId: string): Observable<ViewEquipmentProjectAssignment[]> {
    const result = new SingleEmit<ViewEquipmentProjectAssignment[]>();
    this.equipmentsService
      .getAssignments(equipmentId)
      .subscribe({
        next: res => {
          this._assignments.next(res);
          result.next(res);
        },
        error: error => console.log('error get assignments: ', error)
      });

    return result.asObservable();
  }

  getEmployeeAssignments(equipmentId: string): Observable<any> {
    const result = new SingleEmit<ViewEquipmentEmployeeAssignment[]>();
    this.equipmentEmployeeService
      .getEmployeeAssignments(equipmentId)
      .subscribe({
        next: res => {
          this._employeeAssignments.next(res);
          result.next(res);
        },
        error: error => console.log('error get employee assignments: ', error)
      });
    return result.asObservable();
  }

  deleteAssignment(cmd: DeleteEquipmentAssignmentCommand): Observable<any> {
    return this.equipmentsService
      .deleteAssignment(cmd)
      .pipe(delay(environment.DELAY_LONG))
      .pipe(switchMap((res: any) => {
        this.updateCurrentEquipment();
        return of(res);
      }));
  }

  updateAssignment(cmd: UpdateEquipmentAssignmentCommand): Observable<any> {
    return this.equipmentsService
      .updateAssignment(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.getAssignments(cmd.equipmentId);
          this.getRequestedAssignments({ equipmentId: cmd.equipmentId });
          return of(res);
        }));
  }

  getInvoiceDetails(equipmentId: string, invoiceId: string, invoiceNumber?: string): Observable<EquipmentInvoice> {
    this.equipmentsService
      .getInvoiceDetails(equipmentId, invoiceId, invoiceNumber)
      .subscribe(res => {
        this._invoiceDetails.next(res);
      });
    return merge(this._invoiceDetails);
  }

  assignEquipmentToOrganisation(cmd: AssignEquipmentToOrganisationCommand): Observable<any> {
    return this.equipmentsService
      .assignToOrganisation(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateListing();
          this.updateFilterOrganisation();
          return of(res);
        }));
  }

  public assignEquipmentToStock(cmd: AssignEquipmentToStockCommand): Observable<string> {
    return this.equipmentsService.assignToStock(cmd)
      .pipe(
        delay(environment.DELAY_SHORTEST),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateListing();
          return of(res);
        }));
  }

  assignEquipmentToProject(cmd: AssignEquipmentToProjectCommand): Observable<any> {
    return this.equipmentsService
      .assignToProject(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.getAssignments(cmd.equipmentId);
          this.getRequestedAssignments({ equipmentId: cmd.equipmentId });
          return of(res);
        }));
  }

  addEquipmentEmployeeAssignment(cmd: AddEquipmentEmployeeAssignmentCommand): Observable<any> {
    return this.equipmentEmployeeService
      .addEquipmentEmployeeAssignment(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateFilterResponsiblePeople();
          this.updateCurrentEquipment();
          this.getEmployeeAssignments(cmd.equipmentId);
          this.updateListing();
          return of(res);
        }));
  }

  replaceEquipmentEmployeeAssignment(cmd: ReplaceEquipmentEmployeeAssignmentCommand): Observable<any> {
    return this.equipmentEmployeeService
      .replaceEquipmentEmployeeAssignment(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateFilterResponsiblePeople();
          this.updateCurrentEquipment();
          this.getEmployeeAssignments(cmd.equipmentId);
          this.updateListing();
          return of(res);
        }));
  }

  updateEquipmentEmployeeAssignment(cmd: UpdateEquipmentEmployeeAssignmentCommand): Observable<any> {
    return this.equipmentEmployeeService
      .updateEquipmentEmployeeAssignment(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateFilterResponsiblePeople();
          this.updateCurrentEquipment();
          this.getEmployeeAssignments(cmd.equipmentId);
          this.updateListing();
          return of(res);
        }));
  }

  deleteEquipmentEmployeeAddedLifecycle(cmd: DeleteEquipmentEmployeeAddedLifecycleCommand): Observable<any> {
    return this.equipmentEmployeeService
      .deleteEquipmentEmployeeAddedLifecycle(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteEquipmentEmployeeReplacedLifecycle(cmd: DeleteEquipmentEmployeeReplacedLifecycleCommand): Observable<any> {
    return this.equipmentEmployeeService
      .deleteEquipmentEmployeeReplacedLifecycle(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteEquipmentEmployeeRemovedLifecycle(cmd: DeleteEquipmentEmployeeRemovedLifecycleCommand): Observable<any> {
    return this.equipmentEmployeeService
      .deleteEquipmentEmployeeRemovedLifecycle(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getLifeCycles(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  removeEmployeeAssignment(cmd: RemoveEquipmentEmployeeAssignmentCommand): void {
    this.equipmentEmployeeService
      .removeEmployeeAssignment(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe(() => {
        this.updateFilterResponsiblePeople();
        this.updateCurrentEquipment();
        this.getEmployeeAssignments(cmd.equipmentId);
        this.updateListing();
      });
  }

  replaceEmployeeAssignment(cmd: ReplaceEquipmentEmployeeAssignmentCommand): void {
    this.equipmentEmployeeService
      .replaceEmployeeAssignment(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe(() => {
        this.updateCurrentEquipment();
        this.getEmployeeAssignments(cmd.equipmentId);
      });
  }

  updateEmployeeAssignment(cmd: UpdateEquipmentEmployeeAssignmentCommand): void {
    this.equipmentEmployeeService
      .updateEmployeeAssignment(cmd)
      .pipe(delay(environment.DELAY_SHORTEST))
      .subscribe(() => {
        this.updateCurrentEquipment();
        this.getEmployeeAssignments(cmd.equipmentId);
      });
  }

  // SubEquipments DataSource
  assignSubEquipment(cmd: AssignSubEquipmentCommand): Observable<any> {
    return this.equipmentsService
      .assignSubEquipment(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.getSubEquipments(cmd.equipmentId);
          return of(res);
        }));
  }

  unAssignSubEquipment(cmd: UnAssignSubEquipmentCommand): Observable<any> {
    return this.equipmentsService
      .unAssignSubEquipment(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateListingAndCostCenters();
          this.getSubEquipments(cmd.equipmentId);
          return of(res);
        }));
  }

  public getSubEquipments(equipmentId: string): void {
    let equipmentIds: string[] = [];
    equipmentIds.push(equipmentId);

    this.equipmentsService
      .getSubEquipments(equipmentIds)
      .subscribe(
        (res: ViewEquipment[]) => this._subEquipments.next(res),
        (error: HttpErrorResponse) => console.log('error get sub-equipments: ', error),
      );
  }

  pairScanCode(cmd: PairEquipmentScanCodeCommand): Observable<any> {
    return this.equipmentsService
      .pairScanCode(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.offlineDataStore.loadOfflineData();
          return of(res);
        }));
  }

  searchScanCode(code: string, dispositionMode = false): void {
    this.getDispositionEquipmentMatchingScanCode(code, dispositionMode).subscribe(scanEquipment => {
      this._equipmentByScanCode.next(scanEquipment);
    }, (error: HttpErrorResponse) => {
      this._equipmentByScanCodeErrors.next(error);
    });
  }

  addRentalContract(cmd: AddRentalContractCommand): Observable<any> {
    return this.equipmentsService
      .addRentalContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateRentalContract(cmd: UpdateRentalContractCommand): Observable<any> {
    return this.equipmentsService
      .updateRentalContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  // Contracts DataSource

  deleteRentalContract(cmd: DeleteRentalContractCommand): Observable<any> {
    return this.equipmentsService
      .deleteRentalContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  addSalesContract(cmd: AddSalesContractCommand): Observable<any> {
    return this.equipmentsService
      .addSalesContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateSalesContract(cmd: UpdateSalesContractCommand): Observable<any> {
    return this.equipmentsService
      .updateSalesContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteSalesContract(cmd: DeleteSalesContractCommand): Observable<any> {
    return this.equipmentsService
      .deleteSalesContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  addServiceContract(cmd: AddServiceContractCommand): Observable<any> {
    return this.equipmentsService
      .addServiceContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateServiceContract(cmd: UpdateServiceContractCommand): Observable<any> {
    return this.equipmentsService
      .updateServiceContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteServiceContract(cmd: DeleteServiceContractCommand): Observable<any> {
    return this.equipmentsService
      .deleteServiceContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  addInsuranceContract(cmd: AddInsuranceContractCommand): Observable<any> {
    return this.equipmentsService
      .addInsuranceContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateInsuranceContract(cmd: UpdateInsuranceContractCommand): Observable<any> {
    return this.equipmentsService
      .updateInsuranceContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteInsuranceContract(cmd: DeleteInsuranceContractCommand): Observable<any> {
    return this.equipmentsService
      .deleteInsuranceContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  addLeasingContract(cmd: AddLeasingContractCommand): Observable<any> {
    return this.equipmentsService
      .addLeasingContact(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  updateLeasingContract(cmd: UpdateLeasingContractCommand): Observable<any> {
    return this.equipmentsService
      .updateLeasingContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  deleteLeasingContract(cmd: DeleteEquipmentLeasingContractCommand): Observable<any> {
    return this.equipmentsService
      .deleteLeasingContract(cmd)
      .pipe(
        delay(environment.DELAY_LONG),
        switchMap((res: any) => {
          this.getContracts(cmd.equipmentId);
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  getContracts(equipmentId: string): Observable<ViewEquipmentContracts> {
    const result = new SingleEmit<ViewEquipmentContracts>();
    this.equipmentsService
      .getContracts(equipmentId)
      .subscribe({
        next: res => {
          this._contracts.next(res);
          result.next(res);
        },
        error: error => console.log('error get contracts: ', error)
      });

    return result.asObservable();
  }

  public reset(): void {
    this.setDefaultValues();
    this.updateListing();
  }

  public updateListingAndCostCenters(): void {
    this.getCostCenters();
    this.updateListing();
  }

  updateStoreData(res: PagedResponse<SearchEquipment>) {
    this._data.next(res.content);
    this._currentTotalElements.next(res.totalElements);
    this._currentTotalPages.next(res.totalPages);
    this.lastEquipment = res.last;
  }

  setDefaultValues(): void {
    this.initPagination();
    this.initLifeCycleFilter();
    this.initLifeCyclePagination();
    this.searchTerms = null;
    this.sort = null;
  }

  sortToSortString(sort: Sort, addPostfix = true): string {
    return sort && sort.active && sort.direction
      ? `${sort.active}${addPostfix ? '.raw' : ''},${sort.direction}` : null;
  }

  manualSortString(model, direction, addPostfix = true) {
    const sort: Sort = {
      active: model,
      direction: direction,
    };

    return this.sortToSortString(sort, addPostfix);
  }

  public getAssignmentCollisions(equipmentId: string, startDate: string, endDate?: string, assignmentId?: string):
  Observable<ViewEquipmentProjectAssignment[]> {
    const params: ValidateAssignmentParams = {
      id: equipmentId,
      assignmentStart: DTZ.DateTimezone({ display: startDate }).storeDate.toISOString(),
      assignmentEnd: endDate ? DTZ.DateTimezone({ display: endDate }).storeDate.toISOString() : null,
      assignmentId: assignmentId,
    };
    return this.equipmentsService.validateAssignment(equipmentId, params);
  }

  getOpenAssignmentRequests(equipmentId: string): Observable<RequestedAssignment[]> {
    return this.equipmentsService.validateOpenAssignmentRequest(equipmentId);
  }

  setScanEquipmentListForTransfer(equipments: ScanEquipment[]): void {
    this._scanEquipmentsForTransfer.next(equipments);
  }

  removeScanEquipmentFromTransferList(index: number): void {
    const newArray = this._scanEquipmentsForTransfer.getValue().slice();
    newArray.splice(index, 1);
    this._scanEquipmentsForTransfer.next(newArray);
  }

  serialNumberInUse(serialNumber: string): Observable<boolean> {
    return this.equipmentsService.serialNumberInUse(serialNumber);
  }

  manufacturerSerialNumberInUse(serialNumber: string, manufacturerId?: string): Observable<boolean> {
    return this.equipmentsService.manufacturerSerialNumberInUse(serialNumber, manufacturerId);
  }

  public exportEquipmentList(columns: string[], applyFilter: boolean, sortBy: string, sortDescending: boolean): Observable<Blob> {
    let params: ExportEquipmentsRequest = {
      ...this.defineSearchEquipmentParams()
    };

    if (!applyFilter) {
      /* Fetching new statuses before export */
      return this.equipmentsService.getNewEquipmentStatuses()
        .pipe(switchMap(statuses => {
          const equipmentStatusIds = statuses
            .filter(status => status.category !== EquipmentStatusCategory.NOT_AVAILABLE)
            .map(status => status.equipmentStatusId);

          params = {
            body: {
              status: equipmentStatusIds,
            },
          };

          params.body.columns = Array.from(new Set<string>(columns));
          params.sort = `${sortBy},${sortDescending ? 'desc' : 'asc'}`;

          return this.equipmentsService.exportEquipmentList(params);
        }));
    }

    params.body.columns = Array.from(new Set<string>(columns));
    params.sort = `${sortBy},${sortDescending ? 'desc' : 'asc'}`;

    return this.equipmentsService.exportEquipmentList(params);
  }

  private initPagination(): void {
    this._pagination = {
      totalElements: 0,
      totalPages: 0,
      size: this._pagination?.size || 25,
      index: 0,
      numberOfElements: 0,
    };
  }

  private initEquipmentRequestListener(): void {
    combineLatest([
      this.equipmentsRequest,
      this.equipmentFilterService.isReady
    ])
      .pipe(
        tap(([params, isReady]) => this.initialRequest = !isReady ? true : this.initialRequest),
        filter(([params, isReady]) => Boolean(isReady)),
        map(([params, isReady]) => !this.initialRequest ? params : this.defineSearchEquipmentParams()),
        switchMap(params => params.body.terms
          ? this.equipmentsService.getEquipmentsSearch(params)
          : this.equipmentsService.getEquipments(params)))
      .subscribe(res => {
        this.initialRequest = false;
        this.updateStoreData(res)
      });
  }

  private initPaginatorPageSizeListener(): void {
    this.equipmentColumnService.pageSize.pipe(distinctUntilChanged()).subscribe((pageSize: number) => {
      this.pagination.size = pageSize;
    });
  }

  private initLabelRequestListener(): void {
    this.labelRequests
      .pipe(
        skip(1),
        debounceTime(800),
        delay(environment.DELAY_SHORTEST))
      .subscribe(() => {
        this.getCustomerLabels();
        this.updateCurrentEquipment();
        this.updateListing();
      });
  }

  private initRequestedAssignmentsRequest(): void {
    this.requestedAssignmentsRequest
      .pipe(
        switchMap(({
                     equipmentId,
                     projectId
                   }) => this.equipmentsService.getRequestedAssignments(equipmentId, projectId, false)))
      .subscribe((res) => this._requestedAssignments.next(res));
  }

  private addToCurrentEquipmentLabels(newLabel: string) {
    const newLabels = [...this._equipmentLabels.value];
    newLabels.push(newLabel);
    this._equipmentLabels.next(newLabels);
  }

  private refreshCustomerLabelAndEquipment(equipmentId: string): void {
    this.labelRequests.next(equipmentId);
  }

  public getDispositionEquipmentMatchingScanCode(code: string, dispositionMode = false): Observable<ScanEquipment> {
    if (this.onlineStatusService.onlineStatus) {
      return this.equipmentsService.getByScanCode(code, dispositionMode);
    }

    return this.getOfflineScanEquipmentMatchingScanCode(code);
  }

  private getOfflineScanEquipmentMatchingScanCode(code: string): Observable<ScanEquipment> {
    const matchingEquipment = this.offlineDispositionEquipments.find(eq => eq.equipmentScanCode === code);

    if (isUndefined(matchingEquipment)) {
      return throwError(`Could not find equipment with scan code '${code}'`);
    }

    return of(matchingEquipment);
  }

  public updateDocumentName(cmd: UpdateEquipmentDocumentNameCommand): void {
    this.equipmentsService.updateDocumentName(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.refreshFileNamesAndDescriptions(cmd.equipmentId, cmd.documentType)))
      .subscribe();
  }

  public updateMaintenanceTaskName(cmd: UpdateMaintenanceTaskDocumentNameCommand,
                                   equipmentId: string, documentType: AssetDocumentType): void {
    this.maintenanceTaskService.updateDocumentName(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.refreshFileNamesAndDescriptions(equipmentId, documentType)))
      .subscribe();
  }

  public updateDocumentDescription(cmd: UpdateEquipmentDocumentDescriptionCommand): void {
    this.equipmentsService.updateDocumentDescription(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.refreshFileNamesAndDescriptions(cmd.equipmentId, cmd.documentType)))
      .subscribe();
  }

  public updateMaintenanceTaskDescription(cmd: UpdateMaintenanceTaskDocumentDescriptionCommand,
                                          equipmentId: string, documentType: AssetDocumentType): void {
    this.equipmentsService.updateMaintenanceTaskDescription(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        tap(() => this.refreshFileNamesAndDescriptions(equipmentId, documentType)))
      .subscribe();
  }

  private refreshFileNamesAndDescriptions(equipmentId: string, documentType: AssetDocumentType): void {
    switch (documentType) {
      case AssetDocumentType.MISCELLANEOUS:
        this.getImages(equipmentId);
        this.getDocuments(equipmentId);
        this.getFiles(equipmentId);
        break;
      case AssetDocumentType.INVOICE:
        this.getInvoicesDocuments(equipmentId);
        break;
      case AssetDocumentType.LIFECYCLE:
        this.getLifeCycleDocuments(equipmentId);
        break;
      case AssetDocumentType.INSURANCE_CONTRACT:
      case AssetDocumentType.RENTAL_CONTRACT:
      case AssetDocumentType.SALES_CONTRACT:
      case AssetDocumentType.SERVICE_CONTRACT:
        this.getContractDocuments(equipmentId);
        break;
      default:
        console.error(`unknown EquipmentDocumentType: ${documentType}`);
    }
  }

  public getLinkedInvoices(equipmentId: string): Observable<string[]> {
    return this.equipmentsService.getLinkedInvoices(equipmentId);
  }

  // Telematics unit
  public assignTelematicsUnit(cmd: AssignTelematicsUnitCommand): Observable<any> {
    return this.equipmentsService.assignTelematicsUnit(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  public reassignTelematicsUnit(cmd: ReassignTelematicsUnitCommand): Observable<any> {
    return this.equipmentsService.reassignTelematicsUnit(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          return of(res);
        }));
  }

  public unassignTelematicsUnit(cmd: UnassignTelematicsUnitCommand): Observable<string> {
    return this.equipmentsService.unassignTelematicsUnit(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateMap();
          return of(res);
        }));
  }

  public getTelematicsUnitAssignmentInfo(telematicsUnitId: string): Observable<TelematicsUnitAssignmentInfo> {
    return this.telematicService.getTelematicsUnitAssignmentInfo(telematicsUnitId);
  }

  public getEquipmentAmountInfo(equipmentId: string): void {
    this.amountsService.getEquipmentAmountById(equipmentId).subscribe(res => {
      this._equipmentAmountInfo.next(res);
    }, error => {
      this._equipmentAmountInfoError.next(error);
    });
  }

  private defineSearchEquipmentParams(
    size: number = this.pagination.size,
    page: number = this.pagination.index
  ): SearchEquipmentsRequest {
    let params: SearchEquipmentsRequest = {
      body: { ...this.equipmentFilterService.getFilterParams() },
      page,
      size,
      sort: null
    };

    if (this._searchTerms) {
      params.body.terms = this._searchTerms;
      params.body.searchColumns = this.currentlySelectedColumns;
    }

    if (this._sort && this._sort.active === 'address') {
      // address is a special case
      params.sort = [
        this.manualSortString('currentAddress.street', this._sort.direction),
        this.manualSortString('currentAddress.city', this._sort.direction),
      ];
    } else if (this._sort && (this._sort.active === 'assignedOwner' ||
      this._sort.active === 'assignedMaintenanceAssignee' ||
      this._sort.active === 'assignedPersonInCharge' ||
      this._sort.active === 'assignedDriver' ||
      this._sort.active === 'assignedPossessor')) {
      params.sort = [
        this.manualSortString(`${this._sort.active}.firstName`, this._sort.direction),
        this.manualSortString(`${this._sort.active}.name`, this._sort.direction),
      ];
    } else if (this._sort && this._sort.direction && this._sort.active === 'equipmentConstructionYear') {
      // construction year is saved as object containing year and month
      params.sort = [
        `${this._sort.active}.year,${this._sort.direction}`,
        `${this._sort.active}.month,${this._sort.direction}`
      ];
    } else if (this._sort?.active === 'status') {
      params.sort = this.manualSortString(`${this._sort.active}.rank`, this._sort.direction, false);
    } else if (this._sort) {
      params.sort = this.sortToSortString(this._sort);
    }

    return params;
  }

  public getEquipmentLastTelematicsUpdate(equipmentId: string): Observable<Date> {
    return this.equipmentsService
      .getEquipment(equipmentId)
      .pipe(
        distinctUntilChanged(),
        map((equipment: ViewEquipment) => equipment.lastTelematicsUpdate)
      )
  }

  public getEquipmentTelematicsData(equipmentId: string): Observable<ViewEquipmentTelematicsData> {
    return this.equipmentsService.getEquipmentTelematicsData(equipmentId);
  }

  public hasUnfulfilledTransportsByEquipment(equipment: ViewEquipment): Observable<boolean> {
    if (!this.equipmentCheckerService.isTransportVehicle(equipment)) {
      return of(false);
    }
    return this.hasUnfulfilledTransportsByEquipmentId(equipment.equipmentId);
  }

  public hasAffectedTransportsByEquipmentAndTransportTypes(equipmentId: string, transportTypes: string[]): Observable<boolean> {
    return this.equipmentsService.hasAffectedTransportsByEquipmentAndTransportTypes(equipmentId, transportTypes);
  }

  public hasUnfulfilledTransportsByEquipmentId(equipmentId: string): Observable<boolean> {
    return this.equipmentsService.isTransportVehicleWithUnfulfilledTasks(equipmentId);
  }

  public updateEquipmentChangeStatus(cmd: UpdateEquipmentChangeStatusCommand): Observable<any> {
    return this.equipmentsService
      .updateEquipmentChangeStatus(cmd)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateListing();
          this.updateFilterStatus();
          return of(res);
        }));
  }

  public updateFilters(): void {
    this.equipmentFilterService.updateFilters();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.equipmentFilterService.updateFilterParams(commands);
  }

  public getFilterSlice(categories: FilterTypes[]) {
    return this.equipmentFilterService.getFilterSlice(categories);
  }

  public updateFilterResponsiblePeople(): void {
    this.equipmentFilterService.updateFilterResponsiblePeople();
  }

  public updateFilterLabel(): void {
    this.equipmentFilterService.updateFilterLabel();
  }

  public updateFilterStatus(): void {
    this.equipmentFilterService.updateFilterStatus();
  }

  public updateFilterEquipmentType(): void {
    this.equipmentFilterService.updateFilterEquipmentType();
  }

  public updateFilterTransportOption(): void {
    this.equipmentFilterService.updateFilterTransportOption();
  }

  public updateFilterOrganisation(): void {
    this.equipmentFilterService.updateFilterOrganisation();
  }

  public updateFilterFavoriteOption(): void {
    this.equipmentFilterService.updateFilterFavoriteOption();
  }

  public navigateToFilteredEquipment(commands: UpdateFilterCommand[]): void {
    this._searchTerms = '';
    this.equipmentFilterService.resetFilters(commands, false);
    this.router.navigate(['/assets/equipment/list']);
  }

  public setFavourite(command: SetFavouriteEquipmentCommand): any {
    return this.equipmentsService.setFavouriteEquipment(command)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateListing();
          this.updateFilterFavoriteOption();
          return of(res);
        }));
  }

  public removeFavourite(command: RemoveFavouriteEquipmentCommand): any {
    return this.equipmentsService.removeFavouriteEquipment(command)
      .pipe(
        delay(environment.DELAY_SHORT),
        switchMap((res: any) => {
          this.updateCurrentEquipment();
          this.updateListing();
          this.updateFilterFavoriteOption();
          return of(res);
        }));
  }

}
