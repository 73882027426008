import { Component, OnInit } from '@angular/core';
import { ViewPartner } from '../../contract/view-partner.interface';
import { PartnersService } from '../../shared/partners.service';

@Component({
  selector: 'bh-partner-view',
  templateUrl: './partner-view.component.html',
  styleUrls: ['./partner-view.component.scss']
})
export class PartnerViewComponent implements OnInit {

  partner: ViewPartner | null;
  partnerId: string;

  constructor(private partnerService: PartnersService) {
  }

  ngOnInit() {
    this.partnerService.getPartner(this.partnerId).subscribe(res => {
      this.partner = res;
    });
  }

}
