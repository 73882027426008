import { AbstractControl, ValidatorFn } from '@angular/forms';

const emailRegex = /^([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const valid = emailRegex.test(control.value);
    if (!control.value) {
      return null;
    }
    return valid ? null : { validEmail: control.value };
  };
}


