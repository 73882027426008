<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch" class="task-list-component">
  <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
    <div class="list-view-main__left-body"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="start stretch">
      <div *ngIf="tasks?.length > 0; else noTasks"
        class="list-view-main__left-body scrollable-table-container"
        fxFlex
        fxLayout="row"
        fxLayoutGap="15px"
        fxLayoutAlign="start stretch">
          <mat-table [dataSource]="tasks"
            bhMatTableLongHeaderTooltip
            matSort
            (matSortChange)="sortData($event)"
            class="pagination-data-table left-body-list scrollable-table exclude-initial-fixed-basis-size"
            [ngClass]="{ 'overview-column hide-header': singleOverviewColumn | async }"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.columnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

          <ng-container [cdkColumnDef]="overviewColumnName">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="left-body-list__info">
              <bh-maintenance-task-list-item [task]="row">
              </bh-maintenance-task-list-item>
            </mat-cell>
          </ng-container>

          <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of selectedColumns">

            <ng-container *ngIf="column.type === COLUMN_TYPE.ICON">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="icon-column"></mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="icon-column">
                <span class="maintenance-icon">
                  <mat-icon>{{column.valueCallback(row) | maintenanceCategory: 'icon'}}</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.OTHER">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span>{{ column.valueCallback(row) | maintenanceCategory}}</span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.EQUIPMENT_NAME">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <div fxFlexFill
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="3px"
                    class="name navigation"
                    (click)="navigateToEquipmentTasks(row.equipmentId)">
                    <span class="text-overflow">
                      {{ row.equipmentName ? row.equipmentName : row.equipmentModel }}
                    </span>
                    <mat-icon class="navigation-icon"
                      [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.equipm.s'|translate}">
                      open_in_new
                    </mat-icon>
                  </div>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.CURRENT_LOCATION_NAME">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <bh-location-column-data
                  [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_NAME"
                  [value]="row.projectName ? row.projectName : row.stockName"
                  [locationType]="row | maintenanceTaskLocationTypeResolver"
                  [locationId]="row.projectId ? row.projectId : row.stockId"
                  [isAllowedToSeeLocation]="(row.isAllowedToSeeLocation && (row.projectName || row.stockName))">
                </bh-location-column-data>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.CURRENT_LOCATION_NUMBER">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <bh-location-column-data
                  [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_NUMBER"
                  [value]="row.currentLocationNumber"
                  [locationType]="row | maintenanceTaskLocationTypeResolver"
                  [locationId]="row.projectId ? row.projectId : row.stockId"
                  [isAllowedToSeeLocation]="(row.isAllowedToSeeLocation && row.currentLocationNumber)">
                </bh-location-column-data>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <bh-location-column-data
                  [columnDefName]="COLUMN_TYPE.CURRENT_LOCATION_COST_CENTER"
                  [value]="row.currentLocationCostCenter"
                  [locationType]="row | maintenanceTaskLocationTypeResolver"
                  [locationId]="row.projectId ? row.projectId : row.stockId"
                  [isAllowedToSeeLocation]="(row.isAllowedToSeeLocation && row.currentLocationCostCenter)">
                </bh-location-column-data>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.cdkColumnDef === COLUMN_DEFINITION.DUE_DATE">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span *ngIf="row.dueDateInfo.dueDate && notMaxDate(row.dueDateInfo.dueDate); else noDate">
                  <span *ngIf="row.dueDateInfo.dueDateEstimated" class="text-overflow">{{'general.circa.abbr'|translate}} </span>
                  {{ row.dueDateInfo.dueDate | formatTimezoneDateTime }}
                </span>
                <ng-template #noDate>
                  <span [matTooltip]="'shared.maintenance.task.insufficientDataForDate'|translate">--.--.----</span>
                </ng-template>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.TIME">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <bh-task-remaining [dueDateInfo]="row.dueDateInfo"></bh-task-remaining>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span [bhOverflowTooltip]="column.valueCallback(row)">{{ column.valueCallback(row) }}</span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.IMAGE">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                  <bh-thumbnail *ngIf="row?.thumbnailKey as imageKey" [key]="imageKey" [size]="ThumbnailSize.XS"
                                [altText]="'shared.maintenance.task.equipmentImage'|translate">
                  </bh-thumbnail>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span *ngIf="!isEmptyAddress(column.valueCallback(row))">
                  <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <mat-chip-set>
                  <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                  <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                    matTooltipClass="mat-tooltip-multiline"
                    [disabled]="true" [matTooltip]="getRemainingLabels(row).join('\n')">
                    {{ '+' + getRemainingLabels(row).length }}
                  </mat-chip>
                </mat-chip-set>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.DATE && column.cdkColumnDef !== COLUMN_DEFINITION.DUE_DATE">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span>
                  {{column.valueCallback(row) | formatTimezoneDateTime }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.cdkColumnDef === COLUMN_DEFINITION.EQUIPMENT_INFO">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="maintenance-list-column__header-info">
                <bh-header-cell-title>{{'general.info'|translate}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="3px"
                bhResizeColumnCell
                class="info-cell">
                <mat-icon *ngIf="equipmentCheckerService.isSearchEquipmentLinkedToTelematicsUnit(row)"
                  fontSet="icon-telematics_outline" class="telematics-link-icon"
                  [matTooltip]="(equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)
                    ? 'modules.equipment.equipmentList.telematicData'
                    : 'modules.equipment.equipmentList.telematicNoData')
                    |translate"
                  [ngClass]="{'no-data': !equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)}">
                </mat-icon>
                <fa-icon [icon]="faTruck"
                  size="2px"
                  *ngIf="equipmentCheckerService.isTransportVehicle(row)"
                  class="transport-types-icon"
                  [matTooltip]="concatTransportTypes(row)"></fa-icon>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <bh-equipment-status-badge [_status]="row.equipmentStatus"></bh-equipment-status-badge>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
              <mat-cell *cdkCellDef="let row"
                [routerLink]="[row.equipmentId]"
                [matTooltip]="'modules.equipment.equipmentList.openDetails'|translate"
                bhResizeColumnCell>
                <mat-icon>info</mat-icon>
              </mat-cell>
            </ng-container>

          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="row?.dueDateInfo?.warningLevel | lowercase"
            [routerLink]="routerLinkField && row[routerLinkField]"
            routerLinkActive="left-body-item--active"
            (dblclick)="rowDoubleClick.next()">
          </mat-row>
        </mat-table>
      </div>
    </div>
    <div class="list-view-main__left-body-paginator" fxLayout="row wrap">
      <div class="button-wrapper" fxFlex="100px" fxLayoutAlign="space-around center">
        <mat-icon class="rotatable m-5" fxLayoutAlign="center center"
                  [matTooltip]="'general.labels.confColumnArrangement'|translate"
                  (click)="openConfiguration()">
          settings
        </mat-icon>
        <button id="exportBtn" mat-icon-button (click)="openMaintenanceListExport()"
                          [matTooltip]="'shared.maintenance.maintenanceListExport.exportMaintenanceList'|translate">
          <fa-icon [icon]="faFileExcel" size="2x"></fa-icon>
        </button>
      </div>
      <mat-paginator fxFlex="0"
        *ngIf="tasks?.length > 0 && pageable"
        [length]="pageable.getLength() | async"
        [pageIndex]="pageable.getIndex() | async"
        [pageSize]="pageable.getSize() | async"
        (page)="pageable.handlePageEvent($event, selectedColumns)"
        [pageSizeOptions]="[5, 25, 50, 100]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
  <ng-template #noTasks>
    <div class="no-tasks" fxLayout="row" fxLayoutAlign="center center">
      <span>{{'shared.maintenance.task.noUpcomingInspectionOrMaintenance'|translate}}</span>
    </div>
  </ng-template>
