<div class="list-view-main__right" fxFlexFill fxLayout="column">

  <div class="list-view-main__right-header" fxFlex="60px" fxLayout="row"
       fxLayoutAlign="space-between center">
    <div (click)="displayService.displayDetailsFullscreen()" class="full-screen" fxFlex="0 0 70px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.enlarge'|translate"
         *ngIf="displayService.isTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div (click)="displayService.displayTableAndDetails()" class="full-screen" fxFlex="0 0 70px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.reduce'|translate"
         *ngIf="displayService.isDetailsFullscreen()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>
    <div class="name bulk-item-list-column" fxFlex="10 1 100%" data-osp-test="label-bulk-item-name">
      <span>
        {{bulkItem?.bulkItemName}}
      </span>
    </div>

    <div fxFlex="0 0 20px" class="more" [matMenuTriggerFor]="bulkItemMore" [matTooltip]="'general.actions'|translate"
         *ngIf="showMoreMenu()">
      <mat-icon>more_vert</mat-icon>
    </div>
    <mat-menu #bulkItemMore="matMenu">
      <button mat-menu-item (click)="edit()"
              *ngIf="hasAuthority(authorities.BULK_ITEMS_MANAGE)">
        <mat-icon>edit</mat-icon>
        <span>{{'general.buttons.edit'|translate}}</span>
      </button>
      <span [matTooltip]="'modules.disposition.bulkItemView.cannotBeDeleted'|translate"
            [matTooltipDisabled]="!deleteDisabled"
            *ngIf="hasAuthority(authorities.BULK_ITEMS_MANAGE)">
        <button mat-menu-item (click)="delete(bulkItem.bulkItemId)"
                [disabled]="deleteDisabled">
          <mat-icon>delete</mat-icon>
          <span>{{'general.buttons.delete'|translate}}</span>
        </button>
      </span>
    </mat-menu>

    <div (click)="displayService.displayTableFullscreen()" class="change-display-mode" fxFlex="0 0 60px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.collapse'|translate"
         *ngIf="!displayService.isTableFullscreen()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="submenu" fxFlex="70px" fxLayout="column" fxLayoutAlign="start stretch">
      <p [routerLink]="['general']" routerLinkActive="submenu--active">
        <mat-icon fontSet="icon-general_outline"></mat-icon>
        <span>{{'general.general.abbr'|translate}}</span>
        <span>{{'general.data'|translate}}</span>
      </p>
      <p [routerLink]="['attachment']"
         routerLinkActive="submenu--active"
         *ngIf="hasAnyAuthority([
         authorities.BULK_ITEMS_VIEW,
         authorities.BULK_ITEMS_MANAGE,
         authorities.BULK_ITEMS_ADD_ATTACHMENTS,
         authorities.BULK_ITEMS_DELETE_ATTACHMENTS])">
        <mat-icon fontSet="icon-attachment_outline"></mat-icon>
        <span>{{'general.attachment.pl'|translate}}</span>
      </p>
      <p [routerLink]="['stocks']"
         routerLinkActive="submenu--active"
         *ngIf="hasAuthority(authorities.STOCK_VIEW)"
         data-osp-test="submenu-inventory">
        <fa-icon [icon]="faWarehouse"></fa-icon>
        <span>{{'modules.disposition.base.inventory.s'|translate}}</span>
      </p>
    </div>

    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
