import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bh-gallery-bullets',
  templateUrl: './gallery-bullets.component.html',
  styleUrls: ['./gallery-bullets.component.scss'],
})
export class GalleryBulletsComponent {
  @Input() public count: number;
  @Input() public active = 0;

  @Output() public onChange = new EventEmitter();

  public getBullets(): number[] {
    return Array(this.count);
  }

  public handleChange(event: Event, index: number): void {
    this.onChange.emit(index);
  }
}
