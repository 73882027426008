import { LanguageService } from '../../../shared/services/language.service';
import { OptionResolver } from '../../../shared/components/card-select/option.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {faBatteryBolt, faPalletAlt, faTrafficCone} from '@fortawesome/pro-light-svg-icons';
import { Pipe, PipeTransform } from '@angular/core';
import { TelematicUnitTypeEnum } from './telematic-unit-type.enum';

@Pipe({
  name: 'telematicUnitAdministrationType'
})
export class TelematicUnitAdministrationTypeResolver implements OptionResolver, PipeTransform {
  private readonly translationKeys = {
    [TelematicUnitTypeEnum.TELTONIKA_CABLE_UNIT]: 'modules.telematic.telematicType.teltonicaBaseUnit',
    [TelematicUnitTypeEnum.TELTONIKA_OBD_UNIT]: 'modules.telematic.telematicType.teltonicaObd',
    [TelematicUnitTypeEnum.DIGITAL_MATTER_OYSTER3_UNIT]: 'modules.telematic.telematicType.oyster3Cellular'
  }

  constructor(private languageService: LanguageService) {
  }

  public resolveName(value: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case TelematicUnitTypeEnum.TELTONIKA_CABLE_UNIT:
        return faTrafficCone;
      case TelematicUnitTypeEnum.TELTONIKA_OBD_UNIT:
        return faPalletAlt;
      case TelematicUnitTypeEnum.DIGITAL_MATTER_OYSTER3_UNIT:
        return faBatteryBolt;
      default:
        return;
    }
  }

  public transform(value: any): any {
    return this.resolveName(value);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
