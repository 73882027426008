import {Component, OnInit, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PartnerTheme} from '../../contract/partner-theme.enum';
import {LanguageService} from '../../../../shared/services/language.service';

interface PartnerThemeDefinition {
  name: string;
  value: PartnerTheme;
  cssClass: string;
}

@Component({
  selector: 'bh-partner-theme-choose',
  templateUrl: 'partner-theme-choose.component.html',
  styleUrls: ['partner-theme-choose.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PartnerThemeChooseComponent)
    }
  ]
})
export class PartnerThemeChooseComponent implements OnInit, ControlValueAccessor {

  availableThemes: PartnerThemeDefinition[] = [];
  selectedTheme: PartnerTheme;

  touched = false;
  disabled = false;

  onChange = (selectedTheme: PartnerTheme) => {};
  onTouched = () => {};

  constructor(private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.availableThemes = [
      {
        value: PartnerTheme.DEFAULT_OSP,
        name: this.translate('modules.organisation.partnerTheme.defaultOsp'),
        cssClass: 'default-osp'
      },
      {
        value: PartnerTheme.UNLABLED,
        name: this.translate('modules.organisation.partnerTheme.unlabled'),
        cssClass: 'unlabled'
      },
      {
        value: PartnerTheme.WACKER_NEUSON,
        name: this.translate('modules.organisation.partnerTheme.wackerNeuson'),
        cssClass: 'wacker-neuson'
      },
      {
        value: PartnerTheme.TRIATHLON,
        name: this.translate('modules.organisation.partnerTheme.triathlon'),
        cssClass: 'triathlon'
      }
    ];
  }

  onThemeSelect(theme: PartnerTheme): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.selectedTheme = theme;
      this.onChange(theme);
    }
  }

  writeValue(theme: PartnerTheme): void {
    if (!!theme) {
      this.selectedTheme = theme
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
