import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { Pageable } from 'app/shared/contract/task/pageable.interface';
import { LanguageService } from 'app/shared/services/language.service';
import { MaintenanceCategory } from '../../enums/maintenance-category.enum';

enum TaskColumns {
  ICON = 'icon',
  NAME = 'name',
  CATEGORY = 'category',
  COMPLETION_DATE = 'completionDate',
  RESULT = 'maintenanceResult',
  PERFORMED_BY = 'performedBy',
  ACTION = 'action',
};

@Component({
  selector: 'bh-completed-task-list',
  templateUrl: './completed-task-list.component.html',
  styleUrls: ['./completed-task-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedTaskListComponent {
  @Input() completedTasks: TaskCompleted[] = [];
  @Input() pageable: Pageable;
  @Output() editTask = new EventEmitter<TaskCompleted>();
  @Output() deleteTask = new EventEmitter<TaskCompleted>();
  @Output() navigateDetails = new EventEmitter<TaskCompleted>();
  public readonly maintenanceCategory = MaintenanceCategory

  public readonly taskColumns = TaskColumns;
  public readonly displayedColumns = [
    TaskColumns.ICON,
    TaskColumns.NAME,
    TaskColumns.CATEGORY,
    TaskColumns.COMPLETION_DATE,
    TaskColumns.RESULT,
    TaskColumns.PERFORMED_BY,
    TaskColumns.ACTION,
  ];

  constructor(private languageService: LanguageService) { }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

}
