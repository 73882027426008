import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'bh-gallery-action',
  templateUrl: './gallery-action.component.html',
  styleUrls: ['./gallery-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryActionComponent {
  @Input() public icon: IconDefinition;
  @Input() public disabled = false;
  @Input() public titleText = '';

  @Output() public onClick: EventEmitter<Event> = new EventEmitter();

  public handleClick(event: Event) {
    if (!this.disabled) {
      this.onClick.emit(event);
    }

    event.stopPropagation();
    event.preventDefault();
  }
}
