import { ContactStatus } from 'app/modules/contact/shared/enums/contact-status.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactStatus'
})
export class ContactStatusPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(value: ContactStatus): string {
    switch (value) {
      case ContactStatus.ACTIVE:
        return this.translate('modules.contact.base.contactStatus.active');
      case ContactStatus.INACTIVE:
        return this.translate('modules.contact.base.contactStatus.inactive');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
