<div class="bulk-item-content">

  <bh-title [text]="'general.generalData'|translate"></bh-title>

  <div class="label-field">
    <bh-label-chips (labelAdded)="addLabel($event)"
                    (labelDeleted)="deleteLabel($event)"
                    [customerLabels]="customerLabels | async"
                    [hasAuthority]="hasAuthority(authorities.BULK_ITEMS_MANAGE)"
                    [labels]="bulkItemLabels | async">
    </bh-label-chips>
  </div>

  <div class="bulk-item-details" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
    <div class="bulk-item-view-detail__content" fxFlex="65" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="inputs-line inputs-line__bold" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'general.intNumber.abbr'|translate}}</mat-label>
          <input matInput readonly [value]="bulkItem?.bulkItemNumber">
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'general.costCenter'|translate}}</mat-label>
          <input matInput readonly [value]="bulkItem?.bulkItemCostCenter">
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'modules.disposition.base.costType'|translate}}</mat-label>
          <input matInput readonly [value]="bulkItem?.bulkItemCostType">
        </mat-form-field>
      </div>

      <div class="inputs-line inputs-line__bold" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'general.manufacturer'|translate}}</mat-label>
          <input matInput readonly [value]="bulkItem?.manufacturerName">
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'modules.disposition.base.manufacturerNumber'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.manufacturerId">
        </mat-form-field>
      </div>

      <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'modules.disposition.base.supplier'|translate}}</mat-label>
          <input matInput readonly [value]="bulkItem?.supplierName">
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'modules.disposition.base.supplierNumber'|translate}}</mat-label>
          <input matInput readonly [value]="bulkItem?.supplierId">
        </mat-form-field>
      </div>

      <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'general.type'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.bulkItemType | bulkItemType">
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'modules.disposition.base.category.s'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.bulkItemCategory">
        </mat-form-field>
        <mat-form-field *ngIf="bulkItem?.bulkItemBGLCode?.length > 0" fxFlex="20" floatLabel="always">
          <mat-label>{{'general.BGLCode'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.bulkItemBGLCode"
                 data-osp-test="input-bgl-code">
        </mat-form-field>
        <mat-form-field *ngIf="bulkItem?.bulkItemBALCode?.length > 0" fxFlex="20" floatLabel="always">
          <mat-label>{{'modules.disposition.base.BALCode'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.bulkItemBALCode"
                 data-osp-test="input-bal-code">
        </mat-form-field>
      </div>

      <div class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'general.units.weight.weight'|translate}}</mat-label>
          <input matInput readonly
                 [value]="(bulkItem?.bulkItemWeight ? (bulkItem.bulkItemWeight|dimensionUnitConverter:'kg') : '-') + ' ' +
                 ('kg'|dimensionUnitDisplay) + ' ' +
                 ('general.units.time.per.per'|translate) + ' ' +
                 (bulkItem?.bulkItemUnit | dimensionUnitDisplay)">
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>{{'general.scanCode'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.bulkItemScanCode">
        </mat-form-field>
      </div>

      <div *ngIf="hasShortName()" class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-form-field fxFlex="100" floatLabel="always">
          <mat-label>{{'general.shortName'|translate}}</mat-label>
          <input matInput readonly
                 [value]="bulkItem?.bulkItemShortName">
        </mat-form-field>
      </div>

      <div *ngIf="isConsumable()" class="inputs-line" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <mat-checkbox fxFlex="50" [checked]="bulkItem?.nonReturnable" color="primary" labelPosition="after" disabled>
          {{'modules.disposition.base.consumedAutomatically'|translate}}
        </mat-checkbox>
      </div>

      <div class="bulk-item-description">
        <bh-subtitle [text]="'general.description'|translate|uppercase"></bh-subtitle>
        <bh-description *ngIf="bulkItem"
                        [maxLength]="fieldLimit.LONG_DESCRIPTION"
                        [(description)]="bulkItem.bulkItemDescription"
                        [isActive]="true"
                        [isEditingAllowed]="hasAuthority(authorities.BULK_ITEMS_MANAGE)"
                        (descriptionSave)="saveBulkItemDescription($event)"></bh-description>
      </div>
    </div>

    <div class="bulk-item-detail__map_gallery" fxFlex="35" fxLayout="column">
      <bh-gallery-detail-view
        *ngIf="imageKeys?.length > 0, else icon"
        [keys]="imageKeys">
      </bh-gallery-detail-view>
      <ng-template #icon>
        <div class="icon-no-images">
          <fa-icon [icon]="bulkItemTypeResolver.resolveIcon(bulkItem?.bulkItemType)"></fa-icon>
        </div>
      </ng-template>
    </div>
  </div>
</div>
