import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { delay, finalize } from 'rxjs/operators';
import { GlobalGeofencesService } from './global-geofences.service';
import { CreateGlobalGeofenceCommand } from '../contract/create-global-geofence-command';
import { DeleteGlobalGeofenceCommand } from '../contract/delete-global-geofence-command';
import { ViewGlobalGeofence } from '../contract/view-global-geofence.interface';

@Injectable()
export class GlobalGeofencesDataSource {

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _gloablGeofences: BehaviorSubject<ViewGlobalGeofence[]> = new BehaviorSubject([]);

  public readonly loading: Observable<boolean> = this._loading.asObservable();
  public readonly globalGeofences: Observable<ViewGlobalGeofence[]> = this._gloablGeofences.asObservable();

  constructor(private _globalGeofencesService: GlobalGeofencesService) {
  }

  getGlobalGeofences(): void {
    this._loading.next(true);
    this._globalGeofencesService
      .getGlobalGeofences()
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe((res: ViewGlobalGeofence[]) => this._gloablGeofences.next(res));
  }

  createGlobalGeofence(cmd: CreateGlobalGeofenceCommand): void {
    this._globalGeofencesService
      .createGlobalGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGlobalGeofences());
  }

  deleteGlobalGeofence(cmd: DeleteGlobalGeofenceCommand): void {
    this._globalGeofencesService
      .deleteGlobalGeofence(cmd)
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getGlobalGeofences());
  }

}
