import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { MaintenanceFilterRequestParams } from './maintenance-filter-request-params.interface';
import { EquipmentStatusCategory } from '../../../../equipment/contract/equipment-status-category.enum';
import {
  SingleFilterOption
} from '../../../../../shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';

export class MaintenanceFilterParams extends FilterParams {

  constructor() {
    super();
  }

  public getFilterParams(): MaintenanceFilterRequestParams {
    return {
      articleGroups: this.getFilterTypeParams(FilterTypes.ARTICLE_GROUPS),
      maintenanceTypes: this.getFilterTypeParams(FilterTypes.MAINTENANCE_TYPE),
      responsiblePersons: this.getFilterTypeParams(FilterTypes.RESPONSIBLE_PERSON),
      warningLevels: this.getFilterTypeParams(FilterTypes.DUE_DATE),
      labels: this.getFilterTypeParams(FilterTypes.LABEL),
      organisations: this.getFilterTypeParams(FilterTypes.ORGANISATION),
      equipmentTypes: this.getFilterTypeParams(FilterTypes.EQUIPMENT_CLASS),
      stocks: this.getFilterTypeParams(FilterTypes.STOCK),
      projects: this.getFilterTypeParams(FilterTypes.PROJECT),
      maintenanceCategories: this.getFilterTypeParams(FilterTypes.MAINTENANCE_CATEGORY),
      maintenanceResults: this.getFilterTypeParams(FilterTypes.MAINTENANCE_RESULT),
      onlyLatestCompletedForRule: this.getFilterTypeParams(FilterTypes.ONLY_LATEST_COMPLETED_FOR_RULE)
        .some(value => value === SingleFilterOption.ONLY_LATEST_COMPLETED_FOR_RULE),
      matchAllLabels: this.getFilterTypeParams(FilterTypes.MATCH_ALL_LABELS)
        .some(value => value === SingleFilterOption.ALL_LABELS),
      equipmentStatuses: this.getFilterTypeParams(FilterTypes.EQUIPMENT_STATE)
        .filter(value => !EquipmentStatusCategory[value])
    };
  }
}
