import { MatPaginatorIntl } from '@angular/material/paginator';
import { LanguageService } from 'app/shared/services/language.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlTranslate extends MatPaginatorIntl {
  private readonly keys = {
    perPage: 'general.paginator.itemsPerPageLabel',
    next: 'general.paginator.nextPageLabel',
    previous: 'general.paginator.previousPageLabel',
    range: 'general.paginator.rangeLabel'
  }
  private rangeLabelExpr = '';

  constructor(private languageService: LanguageService) {
    super();
    this.translateLabels();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const start = Math.min((page * pageSize) + 1, length);
    const end = ((page + 1) * pageSize) <= length ? ((page + 1) * pageSize) : length;
    return this.languageService.interpolate(this.rangeLabelExpr, { start, end, length });
  }

  private translateLabels(): void {
    const translationMap = this.languageService.getInstantMap([
      this.keys.perPage,
      this.keys.next,
      this.keys.previous,
      this.keys.range
    ]);
    if (translationMap) {
      this.itemsPerPageLabel = translationMap[this.keys.perPage];
      this.nextPageLabel = translationMap[this.keys.next];
      this.previousPageLabel = translationMap[this.keys.previous];
      this.rangeLabelExpr = translationMap[this.keys.range];
      this.changes.next();
    }
  }
}
