import { LanguageService } from '../../../../shared/services/language.service';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { RouterHistory } from '../../../../shared/router-history';
import { PartnerMailServer } from '../../contract/partner-mail-server.enum';
import { RealmType } from 'app/shared/contract/realm-type';

interface PartnerMailServerDefinition {
  name: string;
  value: PartnerMailServer;
}

interface PartnerRealmDefinition {
  name: string;
  value: RealmType;
}

export abstract class PartnerBaseComponent extends GuardedNavigableInputComponent {

  public mailServers: PartnerMailServerDefinition[] = [];
  public realms: PartnerRealmDefinition[] = [];

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService) {
    super(authService, router, route, routerHistory);

    this.mailServers = [
      {
        value: PartnerMailServer.DEFAULT_OSP,
        name: this.translate('modules.organisation.partnerMailServer.defaultOsp')
      },
      {
        value: PartnerMailServer.WACKER_NEUSON,
        name: this.translate('modules.organisation.partnerMailServer.wackerNeuson')
      },
      {
        value: PartnerMailServer.TRIATHLON,
        name: this.translate('modules.organisation.partnerMailServer.triathlon')
      }
    ];

    this.realms = [
      {
        value: RealmType.DEFAULT,
        name: this.translate('modules.organisation.partnerRealm.default')
      },
      {
        value: RealmType.WACKER_NEUSON,
        name: this.translate('modules.organisation.partnerRealm.wackerNeuson')
      },
      {
        value: RealmType.TRIATHLON,
        name: this.translate('modules.organisation.partnerRealm.triathlon')
      }
    ];
  }

  protected translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
