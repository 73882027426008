import { LanguageService } from 'app/shared/services/language.service';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { ViewEquipment } from 'app/modules/equipment/contract/view-equipment.interface';
import { EquipmentLastPosition } from 'app/shared/contract/equipment-last-position.interface';
import { EquipmentPositionSources } from 'app/shared/contract/equipment-position-sources.enum';
import { EquipmentLastPositionResolver } from 'app/shared/contract/equipment-last-position-resolver';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from 'app/core/keycloak';
import { RouterHistory } from 'app/shared/router-history';
import { Modules } from 'app/shared/enums/modules.enum';
import { Observable } from 'rxjs';
import { ViewProject } from '../../../../../../disposition/contract/view-project.interface';
import { ILocationData } from './locationData.interface';
import { ListType } from 'app/shared/enums/list-type.enum';

@Component({
  selector: 'bh-equipment-view-map-address',
  templateUrl: './equipment-view-map-address.component.html',
  styleUrls: ['./equipment-view-map-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

@UntilDestroy()
export class EquipmentViewMapAddressComponent extends GuardedNavigableInputComponent implements OnDestroy {
  private _equipment: ViewEquipment;
  public readonly faWarehouse: IconDefinition = faWarehouse;

  public isMatIcon = false;
  public locationData: ILocationData = {
    icon: this.faWarehouse,
    iconTooltip: '',
    address: '',
    number: '',
    costCenter: '',
    name: '',
    transferDate: null,
    telematicTransferDate: null,
    locationCannotBeDisplayedMessage: '',
  }

  public locationLinkParameters = {
    objects: '',
    id: '',
    tooltip: ''
  }

  public equipmentLastPosition: EquipmentLastPosition;
  public assignedProject: Observable<ViewProject>;

  public isNavigationToLocationAvailable = false;

  constructor(private languageService: LanguageService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory)
  }

  public get equipment(): ViewEquipment {
    return this._equipment;
  }

  @Input()
  public set equipment(equipment: ViewEquipment) {
    this._equipment = equipment;
    this.setEquipmentLastPosition(EquipmentLastPositionResolver.getEquipmentLastPosition(this._equipment));
  }

  public ngOnDestroy(): void {
  }

  private setEquipmentLastPosition(lastPosition: EquipmentLastPosition): void {
    this.equipmentLastPosition = lastPosition ? {...lastPosition} : null;
    if (this.equipmentLastPosition) {
      this.navigationToLocationAvailable();
      this.setLocationData();
    }
  }

  private setLocationData(): void {
    this.setLocationAddress();
    this.setLocationNumber();
    this.setLocationCostCenter();
    this.setLocationName();
    this.setLocationTransferDate();
    this.setLocationIconsAndTooltip();
    this.setLocationLinkParameters();
    this.setLocationCannotBeDisplayedMessage();
  }

  private setLocationAddress(): void {
    if (this.equipmentLastPosition.currentAddress && this.equipmentLastPosition.currentAddress.street) {
      const {street, streetNumber} = this.equipmentLastPosition.currentAddress;
      this.locationData.address = `${street}${streetNumber ? ` ${streetNumber}` : ''},`;
    } else {
      this.locationData.address = '';
    }
  }

  private setLocationNumber(): void {
    this.equipmentLastPosition.positionSource === EquipmentPositionSources.DISPOSITION
      ? this.locationData.number = this.equipment.projectNumber
      : this.locationData.number = '';
  }

  private setLocationCostCenter(): void {
    this.equipmentLastPosition.positionSource === EquipmentPositionSources.DISPOSITION && this.equipment.projectCostCenter
      ? this.locationData.costCenter = this.equipment.projectCostCenter
      : this.locationData.costCenter = '';
  }

  private setLocationName(): void {
    switch (this.equipmentLastPosition.positionSource) {
      case EquipmentPositionSources.STOCK:
        this.equipmentLastPosition.stockName
          ? this.locationData.name = this.equipmentLastPosition.stockName
          : this.locationData.name = null;
        break;
      case EquipmentPositionSources.DISPOSITION:
        this.equipmentLastPosition.projectName
          ? this.locationData.name = this.equipmentLastPosition.projectName
          : this.locationData.name = null;
        break;
      case EquipmentPositionSources.TELEMATIC:
        this.locationData.name = null;
        break;
    }
  }

  private setLocationTransferDate(): void {
    switch (this.equipmentLastPosition.positionSource) {
      case EquipmentPositionSources.STOCK:
      case EquipmentPositionSources.DISPOSITION:
        this.locationData.transferDate = this.equipment.transferDate;
        this.locationData.telematicTransferDate = null;
        break;
      case EquipmentPositionSources.TELEMATIC:
        this.locationData.transferDate = null;
        this.equipmentLastPosition.lastLocationTime
          ? this.locationData.telematicTransferDate = this.equipmentLastPosition.lastLocationTime
          : this.locationData.telematicTransferDate = null;
        break;
    }
  }

  private setLocationIconsAndTooltip(): void {
    switch (this.equipmentLastPosition.positionSource) {
      case EquipmentPositionSources.STOCK:
        this.locationData.icon = this.faWarehouse;
        this.locationData.iconTooltip = this.translate('general.stock.s');
        this.isMatIcon = false;
        break;
      case EquipmentPositionSources.DISPOSITION:
        this.locationData.icon = 'icon-disposition_outline';
        this.locationData.iconTooltip = this.translate('general.disposition.s');
        this.isMatIcon = true;
        break;
      case EquipmentPositionSources.TELEMATIC:
        this.locationData.icon = 'icon-telematics_outline';
        this.locationData.iconTooltip = this.translate('general.liveData');
        this.isMatIcon = true;
        break;
    }
  }

  private setLocationLinkParameters(): void {
    switch (this.equipmentLastPosition.positionSource) {
      case EquipmentPositionSources.STOCK:
        this.locationLinkParameters.objects = ListType.STOCKS.toLocaleLowerCase();
        this.locationLinkParameters.id = this.equipment.stockId;
        this.locationLinkParameters.tooltip = this.translate('general.labels.jumpTo', { value: this.translate('general.stock.s') });
        break;
      case EquipmentPositionSources.DISPOSITION:
        this.locationLinkParameters.objects = ListType.PROJECTS.toLocaleLowerCase();
        this.locationLinkParameters.id = this.equipment.projectId;
        this.locationLinkParameters.tooltip = this.translate('general.labels.jumpTo', { value: this.translate('general.project.s') });
        break;
    }
  }

  private setLocationCannotBeDisplayedMessage(): void {
    switch (this.equipmentLastPosition.positionSource) {
      case EquipmentPositionSources.STOCK:
        this.locationData.locationCannotBeDisplayedMessage = this.translate('general.labels.cannotBeDisplayed.stock');
        break;
      case EquipmentPositionSources.DISPOSITION:
        this.locationData.locationCannotBeDisplayedMessage = this.translate('general.labels.cannotBeDisplayed.project');
        break;
      case EquipmentPositionSources.TELEMATIC:
        this.locationData.locationCannotBeDisplayedMessage = this.translate('general.labels.cannotBeDisplayed.liveData');
        break;
    }
  }

  private navigationToLocationAvailable(): void {
    const hasBulkItemManagementModule = this.hasModule(Modules.BULK_ITEM_MANAGEMENT);
    const hasStockViewPermission = this.hasAuthority(Authorities.STOCK_VIEW);
    const hasProjectViewPermission = this.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]);

    switch (this.equipmentLastPosition.positionSource) {
      case EquipmentPositionSources.STOCK:
        this.isNavigationToLocationAvailable = hasBulkItemManagementModule && hasStockViewPermission;
        break;
      case EquipmentPositionSources.DISPOSITION:
        this.isNavigationToLocationAvailable = hasBulkItemManagementModule && hasProjectViewPermission;
        break;
      case EquipmentPositionSources.TELEMATIC:
        this.isNavigationToLocationAvailable = false;
        break;
    }
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
