<ng-container
  [ngTemplateOutlet]="tmpIcon"
  [ngTemplateOutletContext]="fontSet | iconParamsTransform : icon">
</ng-container>

<ng-template #tmpIcon
  let-fontSet="fontSet"
  let-fontIcon="fontIcon"
  let-faIcon="faIcon">
  <mat-icon
    [fontSet]="fontSet"
    [fontIcon]="fontIcon"
    [color]="color"
    [ngClass]="[
      'osp-icon-size-' + (size || defaultSize),
      (hover ? 'osp-hover' : '')
    ]">
    <fa-icon *ngIf="faIcon" [icon]="faIcon"></fa-icon>
  </mat-icon>
</ng-template>
