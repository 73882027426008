<div class="list-view" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div fxFlex fxLayout="row" fxLayoutAlign="start stretch">

    <mat-card [ngClass]="{'list-item-hidden': projectStore.fullScreen,
                          'list-item-margin': !projectStore.fullScreen}" class="list-view-main-container"
              class="no-rounded-corners-left"
              fxFlex="35"
              data-osp-test="list-projects">

      <mat-card-content class="list-view-main" fxFlexFill fxLayout="row"
                        fxLayoutAlign="start stretch">

        <div class="list-view-main__left-container" fxFlex>
          <div class="list-view-main__left" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
            <div class="list-view-main__left-header" fxFlex="60px" fxLayout="row">
            </div>
            <div class="list-view-main__left-body" fxFlex>
              <div *ngIf="!(projectStore.projects | async)"
                   class="not-found">{{'modules.disposition.projectList.noProjectsFound'|translate}}</div>
              <ng-container *ngIf="projectStore.projects | async">
                <ng-container *ngFor="let p of projectStore.projects | async">
                  <div (click)="selectProjectAndNavigate(p.projectId,
                   p?.subProjectList ? p?.subProjectList.length : 0)"
                       [routerLink]="[p.projectId]"
                       class="left-body-list"
                       fxLayout="row"
                       fxLayoutAlign="space-between center"
                       routerLinkActive="left-body-item--active"
                       data-osp-test="row-project-list">

                    <div class="left-body-list__icon">
                      <mat-icon fontSet="icon-projects01_maps_solid"></mat-icon>
                    </div>

                    <div class="left-body-list__info" fxFlex fxLayout="column"
                         fxLayoutAlign="center start">
                      <pre class="project-number"># {{ p.projectNumber?.trim() }}</pre>
                      <p>
                    <span class="name" data-osp-test="row-project-name">
                      {{ p.projectName}}
                    </span>
                        <span *ngIf="p.projectType" class="project-type">
                      ({{ p.projectType }})

                      <mat-icon *ngIf="projectCheckerService.isProjectFinished(p)" class="project-icon"
                                [matTooltip]="'general.project.isFinished'|translate">done_all</mat-icon>
                    </span>
                      </p>
                      <div *ngIf="p.projectCostCenter" fxFlex fxLayoutAlign="start center">
                        <fa-icon [icon]="faWallet"></fa-icon>
                        <span class="project-cost-center">{{ p.projectCostCenter }}</span>
                      </div>
                      <p class="details">
                    <span *ngIf="p.projectAddress?.postalCode">
                      {{ p.projectAddress?.postalCode | defaultValue: 'general.address.zipcode'|translate }}
                      {{ p.projectAddress?.city | defaultValue: '-'}} /
                    </span>
                        <span *ngIf="p.projectStart">
                      {{ p.projectStart | date:'dd.MM.yyyy' }} -
                          {{ p.projectEnd | date:'dd.MM.yyyy' | defaultValue: 'general.labels.openEnd'|translate }}
                    </span>
                      </p>
                      <mat-chip-set>
                        <mat-chip *ngFor="let label of getFirstLabels(p, true)"
                                  [disabled]="true">{{ label }}</mat-chip>
                        <mat-chip *ngIf="getRemainingLabels(p, true).length > 0"
                                  [disabled]="true"
                                  [matTooltip]="getRemainingLabels(p, true).join('\n')"
                                  matTooltipClass="mat-tooltip-multiline">
                          {{ '+' + getRemainingLabels(p, true).length }}
                        </mat-chip>
                      </mat-chip-set>
                    </div>
                    <div *ngIf="p.subProjectList?.length>0"
                         class="sub-arrow"
                         (click)="selectProject(p)">
                    <span class="badge sub-equipment-number-badge">
                      {{p.subProjectList.length}}
                    </span>
                      <fa-icon [icon]="resolveArrowIcon(p)"></fa-icon>
                    </div>
                  </div>

                  <ng-container *ngIf="p.subProjectList?.length>0">
                    <div class="expandable-div" [@rowsExpand]="checkExpanded(p) ? 'expanded' : 'collapsed'">
                      <div *ngFor="let subP of p.subProjectList"
                           (click)="selectProjectAndNavigate(subP.projectId, subP?.subProjectList?.length ?? 0)"
                           [routerLink]="[subP.projectId]"
                           class="left-body-list"
                           fxLayout="row"
                           fxLayoutAlign="space-between center"
                           routerLinkActive="left-body-item--active">
                        <mat-icon class="subdirectory">subdirectory_arrow_right</mat-icon>
                        <div class="left-body-list__icon">
                          <mat-icon fontSet="icon-projects01_maps_solid"></mat-icon>
                        </div>

                        <div class="left-body-list__info" fxFlex fxLayout="column"
                             fxLayoutAlign="center start">
                          <pre class="project-number"># {{ subP.projectNumber?.trim() }}</pre>
                          <p>
                    <span class="name">
                      {{ subP.projectName}}
                    </span>
                            <span *ngIf="subP.projectType" class="project-type">
                      ({{ subP.projectType }})

                      <mat-icon *ngIf="projectCheckerService.isProjectFinished(subP)" class="project-icon"
                                [matTooltip]="'general.project.isFinished'|translate">done_all</mat-icon>
                    </span>
                          </p>
                          <div *ngIf="p.projectCostCenter" fxFlex fxLayoutAlign="start center">
                            <fa-icon [icon]="faWallet"></fa-icon>
                            <span class="project-cost-center">{{ p.projectCostCenter }}</span>
                          </div>
                          <p class="details">
                    <span *ngIf="subP.projectAddress?.postalCode">
                      {{ subP.projectAddress?.postalCode | defaultValue: 'general.address.zipcode'|translate }}
                      {{ subP.projectAddress?.city | defaultValue: '-'}} /
                    </span>
                            <span *ngIf="subP.projectStart">
                      {{ subP.projectStart | date:'dd.MM.yyyy' }} -
                              {{ subP.projectEnd | date:'dd.MM.yyyy' | defaultValue: 'general.labels.openEnd'|translate }}
                    </span>
                          </p>
                          <mat-chip-set>
                            <mat-chip *ngFor="let label of getFirstLabels(subP, true)"
                                      [disabled]="true">{{ label }}</mat-chip>
                            <mat-chip *ngIf="getRemainingLabels(subP, true).length > 0"
                                      [disabled]="true"
                                      [matTooltip]="getRemainingLabels(subP, true).join('\n')"
                                      matTooltipClass="mat-tooltip-multiline">
                              {{ '+' + getRemainingLabels(subP, true).length }}
                            </mat-chip>
                          </mat-chip-set>
                        </div>
                      </div>

                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

            <div class="list-view-main__left-body-paginator" fxLayout="column"
                 fxLayoutAlign="start stretch">
              <div fxLayout="row" fxLayoutAlign="end center">
                <mat-paginator #paginator
                               [length]="projectStore.totalCount | async"
                               [pageIndex]="projectStore.pagination.index"
                               [pageSizeOptions]="[5, 25, 50, 100]"
                               [pageSize]="projectStore.pagination.size"
                               bhCurrentItemPaginator
                               [data]="(projectStore.projects | async).toArray()"
                               [currentItemId]="(this.projectStore.currentProject | async)?.projectId"
                               [isIdentifierOfItem]="isIdentifierOfProject"
                               (paginationChanges)="onPaginateChange($event)">
                </mat-paginator>
              </div>
            </div>

          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="list-view-main-container"
              fxFlex="{{ projectStore.fullScreen ? 100 : 65}}">
      <mat-card-content class="list-view-main" fxFlexFill fxLayout="row"
                        fxLayoutAlign="start stretch">
        <div fxFlex>
          <router-outlet></router-outlet>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>
