import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DigitalMatterProfile } from '../../../shared/assign-digital-matter-unit/digital-matter-profile';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Languages } from '../../../shared/enums/languages.enum';
import { TelematicsService } from '../../equipment/shared/telematics.service';
import { UpdateDigitalMatterProfileCommand } from '../shared/commands/update-digital-matter-profile-command';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from '../../../shared/services/language.service';
import { environment } from '../../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface TelematicsUnitProfileFormGroup {
  templateId: FormControl<string>;
  displayName: FormControl<string | null>;
  description: FormControl<string | null>;
}

@Component({
  selector: 'bh-telematics-unit-profile-edit',
  templateUrl: './telematics-unit-profile-edit.component.html',
  styleUrls: ['./telematics-unit-profile-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelematicsUnitProfileEditComponent implements OnInit {
  private profileItem: DigitalMatterProfile;
  private currentLanguage: Languages;
  protected profileDataForm: FormGroup<TelematicsUnitProfileFormGroup>;

  protected buttonThrottle = 1000;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { profileItem: DigitalMatterProfile, currentLanguage: Languages },
              private dialogRef: MatDialogRef<TelematicsUnitProfileEditComponent>,
              private telematicsService: TelematicsService,
              protected snackBar: MatSnackBar,
              private languageService: LanguageService,
              private changeDetectorRef: ChangeDetectorRef
  ) {
    this.profileItem = data.profileItem;
    this.currentLanguage = data.currentLanguage;

    this.buildForm(this.profileItem, this.currentLanguage);
  }

  public get profileDataControl(): FormGroup<TelematicsUnitProfileFormGroup> {
    return this.profileDataForm;
  }

  public ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  private buildForm(profileItem: DigitalMatterProfile, currentLanguage: Languages): void {
    this.profileDataForm = new FormGroup<TelematicsUnitProfileFormGroup>({
      templateId: new FormControl(profileItem.templateId, Validators.required),
      displayName: new FormControl(profileItem.translations.get(currentLanguage)?.displayName, Validators.required),
      description: new FormControl(profileItem.translations.get(currentLanguage)?.description, Validators.required)
    });
  }

  public save(): void {
    if (this.profileDataForm.invalid) {
      return;
    }

    const changedProfile = this.getUpdatedProfile();

    if (changedProfile) {
      this.updateProfileData(changedProfile);
    }
  }

  private getUpdatedProfile(): DigitalMatterProfile {
    const newTemplateId = this.profileDataForm.controls.templateId.value;
    const newDisplayName = this.profileDataForm.controls.displayName.value;
    const newDescription = this.profileDataForm.controls.description.value;

    if (this.profileItem.templateId === newTemplateId
      && this.profileItem.translations.get(this.currentLanguage)?.displayName === newDisplayName
      && this.profileItem.translations.get(this.currentLanguage)?.description === newDescription) {
      this.dialogRef.close();
      return;
    }

    let changedProfile: DigitalMatterProfile = {
      profileId: this.profileItem.profileId,
      templateId: newTemplateId,
      translations: this.profileItem.translations
    };

    changedProfile.translations.set(this.currentLanguage, {
      displayName: newDisplayName,
      description: newDescription
    });

    return changedProfile;
  }

  private updateProfileData(updatedProfile: DigitalMatterProfile): void {
    const serializableTranslationMap = Object.fromEntries(updatedProfile.translations.entries());

    let updateProfileCommand: UpdateDigitalMatterProfileCommand = new UpdateDigitalMatterProfileCommand(
      updatedProfile.profileId,
      updatedProfile.templateId,
      serializableTranslationMap
    );

    this.telematicsService.updateDigitalMatterProfile(updateProfileCommand)
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.languageService.getInstant('modules.telematic.telematicUnitProfiles.couldNotBeChanged')}: ${error.error}`,
            undefined, { duration: environment.DELAY_LONG });
          console.log('update digital matter profile error: ', error);
        }
      })
  }
}
