//This component is not used

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectDataSource } from '../../../../../shared/project.datasource';
import {
  DispositionProject,
  instanceOfDispositionProject
} from '../../../../../shared/disposition-project';
import { UntypedFormControl } from '@angular/forms';
import { EquipmentAssignToProjectUtils } from '../../../../../../equipment/equipments/equipment-assign-to-project/base/equipment-assign-to-project-utils';
import { Point } from '../../../../../../../shared/geolocation/point';
import { LatLon } from '../../../../../../../shared/geolocation/lat-lon.interface';
import { instanceOfViewStock } from '../../../../../contract/view-stock';
import { StockStore } from '../../../../../stocks/shared/stock.store';
import { StockTypeResolver } from '../../../../../../../shared/pipes/stock-type.resolver';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { byLocationClosestToPosition } from '../../../../../../../shared/collection-utils';
import { Html5NavigatorService } from '../../../../../../equipment/shared/services/html5-navigator.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {
  DispositionStock,
  instanceOfDispositionStock
} from '../../../../../shared/disposition-stock';
import { MatTabGroup } from '@angular/material/tabs';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ViewportRuler } from '@angular/cdk/overlay';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-transfer-choose-location',
  templateUrl: './mobile-transfer-choose-location.component.html',
  styleUrls: ['./mobile-transfer-choose-location.component.scss'],
  providers: [StockStore, ProjectDataSource]
})
export class MobileTransferChooseLocationComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() source: DispositionStock | DispositionProject;
  @Input() tab = 0;
  @Input() selectedSource: DispositionStock | DispositionProject;
  @Input() selectedTarget: DispositionStock | DispositionProject;

  @Output() selected: EventEmitter<DispositionStock | DispositionProject> = new EventEmitter<DispositionStock | DispositionProject>();
  @Output() consumed: EventEmitter<void> = new EventEmitter<void>();
  @Output() tabChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(MatTabGroup, { static: true }) tabs: MatTabGroup;

  public readonly faTrash: IconDefinition = faTrash;

  public projects: Observable<DispositionProject[]>;
  public stocks: Observable<DispositionStock[]>;
  public termControl: UntypedFormControl = new UntypedFormControl();
  public position: Point;
  public formatAddress = EquipmentAssignToProjectUtils.formatAddress;
  public selectedStock: DispositionStock;
  public selectedProject: DispositionProject;
  public consumedSelected: boolean;

  constructor(private projectStore: ProjectDataSource,
              private stockStore: StockStore,
              public stockTypeResolver: StockTypeResolver,
              private navigator: Html5NavigatorService,
              public ruler: ViewportRuler) {
  }

  public ngOnInit(): void {
    this.tabs.selectedIndex = this.tab;
    this.projectStore.loadDispositionProjects();
    this.stockStore.getDispositionStocks();

    this.selectSourceProjectOrStock();
    this.selectTargetProjectOrStock();

    if (this.source) {
      this.removeSourceFromSelection();
    } else {
      this.projects = this.projectStore.filteredDispositionProjects;
      this.stocks = this.stockStore.filteredDispositionStocks;
    }

    this.navigator.getCurrentPosition()
      .subscribe(
        (position: GeolocationPosition) => {
          this.setPosition(Point.fromCoordinates(position.coords));
          this.projects = this.projectStore.filteredDispositionProjects
            .pipe(map(projects => this.sortProjects(projects)));
          this.stocks = this.stockStore.filteredDispositionStocks
            .pipe(map(stocks => this.sortStocks(stocks)));
        }
      );

    this.termControl.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.projectStore.filterDispositionProjects(searchTerm);
      this.stockStore.filterStocks(searchTerm);
    });

    this.tabs.selectedTabChange.subscribe(() => {
      this.tabChanged.emit(this.tabs.selectedIndex);
    });
  }

  public ngOnDestroy(): void {
  }

  public isSourceTab(): boolean {
    return !this.source;
  }

  public distanceToPosition(location: LatLon): number {
    return Math.round(this.position.distanceTo(Point.fromLatLon(location)) / 1000);
  }

  public selectProject(project: DispositionProject): void {
    this.selected.emit(project);
    this.selectedProject = project;
    this.selectedStock = null;
    this.consumedSelected = false;
  }

  public selectStock(stock: DispositionStock): void {
    this.selected.emit(stock);
    this.selectedStock = stock;
    this.selectedProject = null;
    this.consumedSelected = false;
  }

  public selectConsumed(): void {
    this.selectedProject = null;
    this.selectedStock = null;
    this.consumedSelected = true;
    this.consumed.emit();
  }

  public isProject(object: DispositionStock | DispositionProject): boolean {
    return instanceOfDispositionProject(object);
  }

  public filterStocks(stock: DispositionStock) {
    this.stocks = this.stockStore.filteredDispositionStocks
      .pipe(
        map(stocks => this.sortStocks(stocks).filter(viewStock => stock.stockId !== viewStock.stockId)));
    this.projects = this.projectStore.filteredDispositionProjects
      .pipe(map(projects => this.sortProjects(projects)));
  }

  public filterProjects(project: DispositionProject) {
    this.projects = this.projectStore.filteredDispositionProjects
      .pipe(map(projects => this.sortProjects(projects).filter(pr => pr.projectId !== project.projectId)));
    this.stocks = this.stockStore.filteredDispositionStocks
      .pipe(map(stocks => this.sortStocks(stocks)));
  }

  private sortProjects(projects: DispositionProject[]): DispositionProject[] {
    if (this.source && instanceOfDispositionProject(this.source)) {
      const projectId = this.source.projectId;
      projects = projects.filter(project => project.projectId !== projectId);
    }

    if (this.position) {
      return projects.sort(byLocationClosestToPosition(this.position));
    }

    return projects.sort((p1, p2) => p1.projectName.toLocaleLowerCase().localeCompare(p2.projectName));
  }

  private removeSourceFromSelection(): void {
    if (instanceOfDispositionProject(this.source)) {
      this.filterProjects(this.source);
    } else if (instanceOfDispositionStock(this.source)) {
      this.filterStocks(this.source);
    }
  }

  private selectTargetProjectOrStock(): void {
    if (this.selectedTarget) {
      if (instanceOfDispositionProject(this.selectedTarget)) {
        this.selectedProject = this.selectedTarget;
      } else if (instanceOfDispositionStock(this.selectedTarget)) {
        this.selectedStock = this.selectedTarget
      }
    }
  }

  private selectSourceProjectOrStock(): void {
    if (this.selectedSource) {
      if (instanceOfDispositionProject(this.selectedSource)) {
        this.selectedProject = this.selectedSource;
      } else if (instanceOfDispositionStock(this.selectedSource)) {
        this.selectedStock = this.selectedSource
      }
    }
  }

  private sortStocks(stocks: DispositionStock[]): DispositionStock[] {
    if (this.source && instanceOfViewStock(this.source)) {
      const stockId = this.source.stockId;
      stocks = stocks.filter(stock => stock.stockId !== stockId);
    }

    if (this.position) {
      return stocks.sort(byLocationClosestToPosition(this.position));
    }

    return stocks.sort((p1, p2) => p1.stockName.toLocaleLowerCase().localeCompare(p2.stockName));
  }

  private setPosition(position: Point): void {
    this.position = position;
  }
}
