import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { EmployeeManagementDatasource } from '../../../../../../shared/employee-management.datasource';
import { takeWhile } from 'rxjs/operators';
import { ViewEmployee } from '../../../../../../contract/view-employee.interface';
import { EquipmentEmployeeRole } from '../../../../../../../equipment/contract/equipment-employee-role.enum';
import { ViewEmployeeEquipmentAssignment } from '../../../../../../contract/view-employee-equipment-assignment';
import { EquipmentEmployeeRolePipe } from '../../../../../../../../shared/pipes/equipment-employee-role.pipe';
import { DisplayService } from '../../../../../../../../shared/display.service';
import { Router } from '@angular/router';
import { KeycloakService } from 'app/core/keycloak';

@Component({
  selector: 'bh-employee-view-general-associations',
  templateUrl: './employee-view-general-associations.component.html',
  styleUrls: ['./employee-view-general-associations.component.scss']
})
export class EmployeeViewGeneralAssociationsComponent implements OnInit, OnDestroy {

  public equipmentAssignments: ViewEmployeeEquipmentAssignment[] = [];
  protected componentActive = true;

  constructor(public employeeManagementStore: EmployeeManagementDatasource,
              protected equipmentEmployeeRolePipe: EquipmentEmployeeRolePipe,
              protected displayService: DisplayService,
              protected authService: KeycloakService,
              protected router: Router,
              protected formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.subscribeToCurrentEmployee();
    this.subscribeToGroupedEquipmentAssignments();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  private subscribeToCurrentEmployee(): void {
    this.employeeManagementStore.currentEmployee
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployee) => {
      if (!!res && !!res.employeeId) {
        this.employeeManagementStore.getEquipmentAssignments(res.employeeId);
      }
    });
  }

  private subscribeToGroupedEquipmentAssignments(): void {
    this.employeeManagementStore.equipmentAssignments
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEmployeeEquipmentAssignment[]) => {
      if (res) {
        this.equipmentAssignments = res;
      }
    });
  }

  public getEquipmentAssignmentRoles(): EquipmentEmployeeRole[] {
    return Array.from(new Set(this.equipmentAssignments
    .map(o => o.employeeRole)
    .sort((role1, role2) => {
      const name1: string = this.equipmentEmployeeRolePipe.transform(role1);
      const name2: string = this.equipmentEmployeeRolePipe.transform(role2);
      return name1.localeCompare(name2);
    })));
  }

  public getFilteredEquipmentAssignments(equipmentEmployeeRole: EquipmentEmployeeRole): ViewEmployeeEquipmentAssignment[] {
    return this.equipmentAssignments
    .filter(o => o.employeeRole === equipmentEmployeeRole)
    .sort();
  }

  public navigateToEquipment(equipmentId: string) {
    this.displayService.displayTableAndDetails();
    this.router.navigate([`assets/equipment/list/${equipmentId}/general`]);
  }
}
