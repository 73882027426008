import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EquipmentMultiseriesItem } from 'app/modules/equipment/contract/equipment-multiseries-item';
import { MultiSeriesTransformStrategy } from 'app/shared/pipes/chart/strategies/multi-series-transform-strategy.class';
import { TransformStrategyDatasetOptions } from 'app/shared/pipes/chart/strategies/transform-strategy-options.interface';
import { CustomThemeService } from 'app/shared/services/custom-theme.service';
import { LanguageService } from 'app/shared/services/language.service';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'bh-equipment-telematic-operating-hours-history',
  templateUrl: './equipment-telematic-operating-hours-history.component.html',
  styleUrls: ['../equipment-chart.style.scss', './equipment-telematic-operating-hours-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentTelematicOperatingHoursHistoryComponent implements OnInit {
  @Input() set value (value: EquipmentMultiseriesItem[]) {
    this.data = value;
  }

  public data: EquipmentMultiseriesItem[] = [];
  public type: ChartType = 'bar';
  public strategy: MultiSeriesTransformStrategy;
  public readonly options: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        grid: {
          display: false,
          drawTicks: false,
        },
        stacked: true,
        min: 0,
        suggestedMax: 12
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: <any>false,
    },
  };

  constructor(
    private customThemeService: CustomThemeService,
    private languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.strategy = new MultiSeriesTransformStrategy(this.getStrategyOptions());
  }

  private getStrategyOptions(): TransformStrategyDatasetOptions[] {
    const themeSettings = this.customThemeService.getThemeSettings();
    return [
      {
        seriesName: 'Arbeit',
        color: themeSettings.chartPrimaryColor,
        label: this.translate('general.operatingHour.pl'),
      },
      {
        seriesName: 'Leerlauf',
        color: themeSettings.chartSecondaryColor,
        label: this.translate('modules.equipment.telematics.idleOperatingHours'),
      },
    ]
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}

