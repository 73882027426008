import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'teamMemberSort'
})

@Injectable()
export class TeamMemberSortPipe implements PipeTransform {
    transform(items: any[], leaderId: string) {
        items = items.sort((a, b) => a.employeeFirstName.localeCompare(b.employeeFirstName));
        items = items.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        items = items.sort((a, b) => {
            if (a.employeeId === leaderId) {
                return -1;
            } else if (b.employeeId === leaderId) {
                return 1;
            } else {
                return 0;
            }
        })
        return items;
    }
}
