import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  GuardedNavigableInputComponent
} from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  EquipmentDeleteDialogComponent
} from '../../../../shared/equipment-delete-dialog/equipment-delete-dialog.component';
import { DeleteEquipmentCommand } from '../../../../contract/delete-equipment-command';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { DisplayService } from '../../../../../../shared/display.service';
import {
  ConfirmationDialogComponent
} from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { delay, filter, switchMap, takeWhile } from 'rxjs/operators';
import {
  EquipmentTypeSelectComponent
} from '../../../../equipment-types/equipment-type-select/equipment-type-select.component';
import { dialogResults } from '../../../../../../shared/enums/dialogResults.enum';
import { EquipmentAddEditComponent } from '../../../equipment-add-edit/equipment-add-edit.component';
import { RouterHistory } from '../../../../../../shared/router-history';
import { LanguageService } from 'app/shared/services/language.service';
import { UnassignTelematicsUnitCommand } from 'app/modules/equipment/contract/unassign-telematics-unit-command';
import { KeyValuePair } from 'app/modules/equipment/contract/key-value-pair';
import { TelematicsUnitType } from '../../../../contract/telematics-unit-type.enum';
import {
  EquipmentTelematicsUnitAssignComponent
} from '../../../equipment-telematics-unit-assign/equipment-telematics-unit-assign.component';
import { environment } from 'environments/environment';
import {
  EquipmentTelematicsUnitCalibrationComponent
} from '../../../equipment-telematics-unit-calibration/equipment-telematics-unit-calibration.component';
import {
  EquipmentTransportTypeEditDialogComponent
} from 'app/modules/equipment/shared/equipment-transport-types-edit-dialog/equipment-transport-types-edit-dialog.component';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';

@Directive()
export abstract class BaseEquipmentViewComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public equipment: ViewEquipment;
  protected componentActive = true;
  protected currentTelematicUnit: KeyValuePair;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              public displayService: DisplayService,
              protected langService: LanguageService,
              protected matomoTracker: MatomoTracker) {
    super(authService, router, route, routerHistory);
    this.handleRouting();
  }

  public ngOnInit(): void {
    this.subscribeToCurrentEquipment();
  }

  public ngOnDestroy(): void {
    this.componentActive = false;
  }

  public deleteDisabled(equipment: ViewEquipment): boolean {
    return this.isTransferredEquipment(equipment) || this.hasPendingTransferRequest(equipment);
  }

  public isTransferredEquipment(equipment: ViewEquipment): boolean {
    return !!equipment && !!equipment.projectId;
  }

  public hasPendingTransferRequest(equipment: ViewEquipment): boolean {
    return !!equipment && !!equipment.pendingTransferRequestId;
  }

  resolveDeleteTooltip(equipment: ViewEquipment): string {
    if (this.isTransferredEquipment(equipment)) {
      return this.translate('modules.equipment.base.deleteTransferredEquipment');
    }
    if (this.hasPendingTransferRequest(equipment)) {
      return this.translate('modules.equipment.base.deleteEquipmentWithPendingTransferRequest');
    }
    return '';
  }

  public equipmentDelete(equipmentId: string): void {
    this.equipmentStore.hasUnfulfilledTransportsByEquipment(this.equipment)
    .pipe(
      switchMap(hasUnfulfilledTransports => {
        const dialogRef = this.dialog.open(EquipmentDeleteDialogComponent, <MatDialogConfig>{
          data: {
            confirmationMessage: this.getConfirmationMessageEquipmentDelete(hasUnfulfilledTransports),
          },
        });
        return dialogRef.afterClosed();
      }),
      takeWhile(() => this.componentActive))
    .subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd = new DeleteEquipmentCommand();
        cmd.equipmentId = equipmentId;
        this.equipmentStore.deleteEquipment(cmd);
      }
    });
  }

  public changeEquipmenttype(equipmentId: string) {
    this.dialog.open(EquipmentTypeSelectComponent, <MatDialogConfig>{
      data: {
        equipmentId: equipmentId,
        currentTypeId: this.equipment.equipmentTypeId,
      },
    });
  }

  public transportVehicleSettings(equipmentId: string): void {
    const dialogRef = this.dialog.open(EquipmentTransportTypeEditDialogComponent, <MatDialogConfig>{
      data: {
        equipmentId: equipmentId,
        equipmentTransportTypes: this.equipment.transportTypes
      }
    });
  }

  // override
  public edit() {
    const dialogRef = this.dialog.open(EquipmentAddEditComponent);
    dialogRef.componentInstance.equipment = this.equipment;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
        this.equipmentStore.updateListing();
      }
    });
  }

  public assignTelematicUnit(): void {
    const dialogRef = this.dialog.open<EquipmentTelematicsUnitAssignComponent>(EquipmentTelematicsUnitAssignComponent);
    dialogRef.componentInstance.equipment = this.equipment;
    dialogRef.afterClosed()
    .pipe(
      takeWhile(() => this.componentActive),
      delay(environment.DELAY_SHORT))
    .subscribe((result) => {
      if (result && result !== dialogResults.ABORT) {
        this.equipmentStore.updateListing();
        this.matomoTracker.trackEvent(matomoCategories.TELEMATIC_ASSIGN_DIALOG, matomoActions.SUCCESFUL_CLOSING);
      }

      if (result === dialogResults.ABORT) {
        this.matomoTracker.trackEvent(matomoCategories.TELEMATIC_ASSIGN_DIALOG, matomoActions.ABORTED_CLOSING);
      }
    });
  }

  public unassignTelematicUnit(): void {
    if (this.currentTelematicUnit) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.unassignTelematicUnit');
      dialogRef.afterClosed()
      .pipe(
        filter(result => result === dialogResults.YES),
        switchMap(() => this.equipmentStore.unassignTelematicsUnit(this.getUnassignTelematicsUnitCommand())),
        delay(environment.DELAY_SHORT),
        takeWhile(() => this.componentActive))
      .subscribe(() => this.equipmentStore.updateListing());
    }
  }

  public calibrateTelematicUnit() {
    const dialogRef = this.dialog.open<EquipmentTelematicsUnitCalibrationComponent>(EquipmentTelematicsUnitCalibrationComponent);
    dialogRef.componentInstance.equipment = this.equipment;
    dialogRef.afterClosed()
      .pipe(
        takeWhile(() => this.componentActive),
        delay(environment.DELAY_SHORT))
      .subscribe((result) => {
        if (result && result !== dialogResults.ABORT) {
          this.equipmentStore.updateListing();
        }
      });
  }

  public toggleFavourite(): void {
    if (this.equipment.favourite) {
      this.equipmentStore.removeFavourite({equipmentId: this.equipment.equipmentId}).subscribe();
    } else {
      this.equipmentStore.setFavourite({equipmentId: this.equipment.equipmentId}).subscribe();
    }
  }

  protected translate(key: string): string {
    return this.langService.getInstant(key);
  }

  private getUnassignTelematicsUnitCommand(): UnassignTelematicsUnitCommand {
    return {
      equipmentId: this.equipment.equipmentId,
      telematicsUnitType: <TelematicsUnitType>this.currentTelematicUnit.key
    }
  }

  private handleRouting(): void {
    this.route.params
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((params: Params) => {
      if (params.id) {
        // TODO: Remove once aux url is correctly ommited
        const auxIndex = params.id.indexOf('(');
        let equipmentId = params.id;
        if (auxIndex > -1) {
          equipmentId = params.id.slice(0, auxIndex);
        }
        this.equipmentStore.changeCurrentEquipment(equipmentId);
      }
    });
    this.router.events
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((event: Event) => {
      if (event instanceof NavigationEnd && this.route.snapshot.firstChild) {
        this.equipmentStore.currentViewTab = this.route.snapshot.firstChild.url[0].path;
      }
    });
  }

  private subscribeToCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(takeWhile(() => this.componentActive))
    .subscribe((res: ViewEquipment) => {
      if (res) {
        this.equipment = res;
        this.currentTelematicUnit = this.equipment.assignedTelematicsUnits ? this.equipment.assignedTelematicsUnits[0] : null;
      }
    });
  }

  private getConfirmationMessageEquipmentDelete(hasUnfulfilledTransports: boolean): string {
    const mainPart = this.translate('modules.equipment.deleteEquipm.text');
    return mainPart + this.getUnfulfilledTransportsMessagePart(hasUnfulfilledTransports);
  }

  private getUnfulfilledTransportsMessagePart(hasUnfulfilledTransports: boolean): string {
    return hasUnfulfilledTransports
      ? `\n${this.translate('modules.equipment.confirmation.message.equipmSellUnfulfilledTransports')}`
      : '';
  }
}
