import { LanguageService } from './services/language.service';
import { DateFormatByLocation } from './date-format-by-location';

export function matDateFormatFactory(langService: LanguageService): Object {
  return {
    parse: {
      dateInput: DateFormatByLocation(langService.getCurrentLocale()),
    },
    display: {
      dateInput: DateFormatByLocation(langService.getCurrentLocale()),
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  }
}
