import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DraggableItemType } from '../enums/draggable-item-type.enum';
import { Directive, HostListener, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { DragAndDropService } from '../services/drag-and-drop.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { filter } from 'rxjs/operators';
import { DragStatus } from '../contract/drag-item-state.interface';
import { DragItemState } from '../enums/drag-item-state.enum';

@UntilDestroy()
@Directive({ selector: '[bhDraggableItemType]' })
export class DraggableItemTypeDirective implements OnInit, OnDestroy {
  @Input('bhDraggableItemType') draggableItemType = DraggableItemType.DEFAULT;
  @Input('dropCellClassName') dropCellClassName: string;

  private draggingState = DragItemState.END;

  constructor(private dndService: DragAndDropService,
              @Optional() private cdkDrag: CdkDrag) {
  }

  public ngOnInit(): void {
    this.subscribeToDragEvents();
  }

  public ngOnDestroy(): void {
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (!this.isDragging()) {
      return;
    }

    this.dndService.emitDragOver({ dragOverElement: this.getDragOverElement(event), event });
  }

  @HostListener('document:keydown.escape') onEscKeydownHandler() {
    if (this.cdkDrag) {
      this.cdkDrag.reset();
      this.dndService.dragCancel();
      document.dispatchEvent(new Event('mouseup'));
    }
  }

  private subscribeToDragEvents(): void {
    if (this.cdkDrag) {
      this.cdkDrag.started
        .pipe(untilDestroyed(this))
        .subscribe(() => this.dndService.dragStart(this.draggableItemType));

      this.cdkDrag.ended
        .pipe(untilDestroyed(this))
        .subscribe(() => this.dndService.dragEnd());

      this.dndService.dragStatus
        .pipe(untilDestroyed(this),
          filter<DragStatus>(Boolean))
        .subscribe(status => this.draggingState = status.state);
    }
  }

  private isDragging(): boolean {
    return this.draggingState && this.draggingState === DragItemState.START;
  }

  private getDragOverElement(event: MouseEvent): Element {
    const elementsInMousePosition = document.elementsFromPoint(event.clientX, event.clientY);
    if (elementsInMousePosition) {
      return this.dropCellClassName
        ? elementsInMousePosition.find(element => element.classList.contains(this.dropCellClassName))
        : elementsInMousePosition[0];
    }
    return null;
  }
}
