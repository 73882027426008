<div class="m-view-list-inputs-overflow" fxFlexFill>
  <div class="m-view-list-inputs">
    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.disposition.base.projectType'|translate|uppercase}}</mat-label>
      <input matInput readonly value="{{project?.projectType}}">
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>{{'general.costCenter'|translate|uppercase}}</mat-label>
      <input matInput readonly value="{{project?.projectCostCenter | defaultValue: '-'}}">
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="15px">
      <div fxLayout="column" fxFlex="50">
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.labels.start'|translate|uppercase}}</mat-label>
          <input matInput readonly value="{{project?.projectStart | date:'dd.MM.yyyy' | defaultValue: '-'}}">
        </mat-form-field>
      </div>

      <div fxLayout="column" fxFlex="50">
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.labels.end'|translate|uppercase}}</mat-label>
          <input matInput readonly value="{{project?.projectEnd | date:'dd.MM.yyyy' | defaultValue: '-'}}">
        </mat-form-field>
      </div>
    </div>

    <mat-form-field floatLabel="always">
      <mat-label>{{'modules.disposition.projectViewDetail.assignedTo'|translate|uppercase}}</mat-label>
      <input matInput readonly value="{{project?.organisation.name | defaultValue: '-'}}">
    </mat-form-field>
  </div>

  <mat-accordion class="m-project-view-detail-assignees-accordion">
    <div *ngIf="employeeAssignments.length > 0" class="m-project-view-detail-section">

      <span>{{'general.responsible'|translate|uppercase}}</span>
    </div>
    <mat-expansion-panel *ngFor="let assignee of employeeAssignments | responsibleSort">
      <mat-expansion-panel-header [expandedHeight]="'64px'" [collapsedHeight]="'64px'">
        <div fxLayout="column" fxLayoutAlign="start start">
          <div fxLayout="row">
            <fa-icon class="role-icon" [icon]="getRoleIcon(assignee.assigneeType)"></fa-icon>
            <span class="panel-title">{{assignee.employeeFirstName | defaultValue: '-'}} {{assignee.employeeName |
              defaultValue: '-'}}</span>
          </div>
          <div class="role-value">
            <span>{{assignee.assigneeType | assigneeType | defaultValue: '-'}}</span>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div class="details">
        <mat-icon>email</mat-icon><span>{{assignee.employeeEmail | defaultValue: '-'}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="details">
        <mat-icon>phone</mat-icon><span>{{ getPhoneNumber(assignee) }}</span>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
