<!--TODO selectedTab functionality is a workaround for a known bug-->
<!-- https://github.com/angular/material2/issues/5269 -->

<div class="m-view-attachment m-view-tabs" fxFlexFill>
  <mat-tab-group fxFlexFill (selectedTabChange)="selectedTab = $event.tab">

    <!--Docuware-->
    <mat-tab [label]="'general.docuware'|translate|uppercase" *ngIf="hasDocuwareConnector">
      <bh-mobile-attachment-docuware [documents]="docuwareDocuments"
                                     [sortParameter]="documentsSortParameter"
                                     [loading]="docuwareRequestPending"
                                     [serviceAvailable]="docuwareServiceAvailable"
                                     [pendingDownloads]="pendingDocuwareDownloads"
                                     (changeSortParameter)="changeDocumentsSortParameter($event)"
                                     (downloadRequested)="downloadDocuwareDocument($event)">
      </bh-mobile-attachment-docuware>
    </mat-tab>

    <mat-tab [label]="'general.topfact'|translate|uppercase" *ngIf="hasTopfactConnector">
      <bh-mobile-attachment-topfact [documents]="topfactDocuments"
                                    [sortParameter]="documentsSortParameter"
                                    [loading]="topfactRequestPending | async"
                                    [serviceAvailable]="topfactServiceAvailable"
                                    [pendingDownloads]="pendingTopfactDownloads"
                                    (changeSortParameter)="changeDocumentsSortParameter($event)"
                                    (downloadRequested)="downloadTopfactDocument($event)">
      </bh-mobile-attachment-topfact>
    </mat-tab>

    <!-- todo rework as components-->
    <!--Documents-->
    <mat-tab [label]="'general.document.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-progress-bar *ngIf="waiting" mode="indeterminate"></mat-progress-bar>

      <mat-accordion displayMode="flat">
        <mat-expansion-panel *ngFor="let document of documents" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, document);"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(document)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{document.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(document.fileName)}}</span>
                    <span>{{getFileNameExtension(document.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(document)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(document)">
              <a [attr.href]="canDownloadFile(document) ? document.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
            <button (click)="deleteDocument(document.documentKey)" class="delete" fxFlex="50"
                    mat-button>
              {{'general.buttons.remove'|translate|uppercase}}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <bh-mobile-file-upload *ngIf="equipmentCheckerService.isActiveEquipment(equipment)
      && hasAnyAuthority([authorities.EQUIPMENT_UPDATE, authorities.EQUIPMENT_ADD_ATTACHMENTS])"
        [itemId]="equipment?.equipmentId"
        uploaderType="EQUIPMENT">
      </bh-mobile-file-upload>
    </mat-tab>

    <!--Images-->
    <mat-tab #imagesTab [label]="'general.image.pl'|translate|uppercase">

      <mat-progress-bar *ngIf="waiting" mode="indeterminate"></mat-progress-bar>

      <mat-accordion *ngIf="imagesTab === selectedTab" displayMode="flat">
        <mat-expansion-panel *ngFor="let image of images" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, image)"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div class="left" fxFlex="50px">
                  <img src="{{ image.url ? image.url : image404 }}"/>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one" *ngIf="image.documentKey === equipment.thumbnailKey">
                    {{'general.standardImage'|translate}}
                  </span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(image.fileName)}}</span>
                    <span>{{getFileNameExtension(image.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(image)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(image)">
              <a [attr.href]="canDownloadFile(image) ? image.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
            <button (click)="deleteImage(image.documentKey)" class="delete" fxFlex="50" mat-button>
              {{'general.buttons.remove'|translate|uppercase}}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <bh-mobile-file-upload *ngIf="equipmentCheckerService.isActiveEquipment(equipment)
      && hasAnyAuthority([authorities.EQUIPMENT_UPDATE, authorities.EQUIPMENT_ADD_ATTACHMENTS])"
          [itemId]="equipment?.equipmentId"
          uploaderType="EQUIPMENT">
      </bh-mobile-file-upload>
    </mat-tab>

    <!--Invoices-->
    <mat-tab #costsTab
             *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW_COSTS) && hasModule(modules.COSTS)"
             [label]="'general.invoice.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-accordion *ngIf="costsTab === selectedTab" displayMode="flat">
        <mat-expansion-panel *ngFor="let docCost of costDocuments" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, docCost)"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(docCost)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{docCost.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(docCost.fileName)}}</span>
                    <span>{{getFileNameExtension(docCost.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(docCost)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(docCost)">
              <a [attr.href]="canDownloadFile(docCost) ? docCost.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!--Contracts-->
    <mat-tab #contractTab
             *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW_CONTRACTS) && hasModule(modules.COSTS)"
             [label]="'general.contract.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-accordion *ngIf="contractTab === selectedTab" displayMode="flat">
        <mat-expansion-panel *ngFor="let contractDoc of contractDocuments" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, contractDoc)"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(contractDoc)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{contractDoc.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(contractDoc.fileName)}}</span>
                    <span>{{getFileNameExtension(contractDoc.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(contractDoc)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(contractDoc)">
              <a [attr.href]="canDownloadFile(contractDoc) ? contractDoc.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!--Life Cycle Damages-->
    <mat-tab #contractTab *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW)"
             [label]="'general.maliciousDamage.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-accordion *ngIf="contractTab === selectedTab" displayMode="flat">
        <mat-expansion-panel *ngFor="let lifeCycleDamage of lifeCycleDamageDocuments" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, lifeCycleDamage);"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(lifeCycleDamage)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{lifeCycleDamage.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(lifeCycleDamage.fileName)}}</span>
                    <span>{{getFileNameExtension(lifeCycleDamage.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(lifeCycleDamage)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(lifeCycleDamage)">
              <a [attr.href]="canDownloadFile(lifeCycleDamage) ? lifeCycleDamage.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!--Life Cycle Incidents-->
    <mat-tab #contractTab *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW)"
             [label]="'general.incident.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-accordion *ngIf="contractTab === selectedTab" displayMode="flat">
        <mat-expansion-panel *ngFor="let lifeCycleIncident of lifeCycleIncidentDocuments" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, lifeCycleIncident);"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(lifeCycleIncident)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{lifeCycleIncident.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(lifeCycleIncident.fileName)}}</span>
                    <span>{{getFileNameExtension(lifeCycleIncident.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(lifeCycleIncident)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(lifeCycleIncident)">
              <a [attr.href]="canDownloadFile(lifeCycleIncident) ? lifeCycleIncident.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!--Life Cycle Maintenance-->
    <mat-tab #contractTab *ngIf="hasAuthority(authorities.EQUIPMENT_VIEW)"
             [label]="'general.maintenance.pl'|translate|uppercase">

      <mat-button-toggle-group class="sort-button-toggle" fxLayout="row" fxLayoutAlign="space-between center"
                               (change)="changeDocumentsSortParameter($event.value)">
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileName"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileName">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.name.s'|translate}}</span>
        </mat-button-toggle>
        <mat-button-toggle class="sort-button-toggle" [checked]="documentsSortParameter === fileDescription"
                           fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center" [value]="fileDescription">
          <fa-icon class="inline-icon" [icon]="faSortAlphaDown"></fa-icon>
          <span>{{'general.description'|translate}}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-accordion *ngIf="contractTab === selectedTab" displayMode="flat">
        <mat-expansion-panel *ngFor="let lifeCycleMaintenance of lifeCycleMaintenanceDocuments" hideToggle="true">
          <mat-expansion-panel-header class="m-view-attachment__panel-header" collapsedHeight="70px"
                                      expandedHeight="70px">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between stretch">
              <div (click)="openFile($event, lifeCycleMaintenance);"
                   class="file-info" fxFlex="calc(100% - 50px)" fxLayout="row"
                   fxLayoutAlign="start stretch">
                <div fxFlex="50px" fxLayout="column" fxLayoutAlign="center">
                  <fa-icon [icon]="getFileTypeIcon(lifeCycleMaintenance)" size="2x"></fa-icon>
                </div>
                <div class="file-text" fxFlex="calc(100% - 110px)" fxLayout="column" fxLayoutAlign="center">
                  <span class="line-one shortening-filename">{{lifeCycleMaintenance.fileDescription}}</span>
                  <div class="line-two" fxLayout="row">
                    <span class="shortening-filename">{{getFileName(lifeCycleMaintenance.fileName)}}</span>
                    <span>{{getFileNameExtension(lifeCycleMaintenance.fileName)}}</span>
                  </div>
                </div>
                <div *ngIf="!canDownloadFile(lifeCycleMaintenance)"
                     fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                  <mat-icon class="unavailable-file-icon">cloud_off</mat-icon>
                </div>
              </div>
              <div class="icon" fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
                <button mat-icon-button>
                  <mat-icon aria-label="more">more_vert</mat-icon>
                </button>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="m-view-attachment__actions" fxLayout="row"
               fxLayoutAlign="space-between stretch">
            <button class="download" fxFlex="50" mat-button [disabled]="!canDownloadFile(lifeCycleMaintenance)">
              <a [attr.href]="canDownloadFile(lifeCycleMaintenance) ? lifeCycleMaintenance.url : null"
                 target="_blank">{{'general.buttons.download'|translate|uppercase}}</a>
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>
  </mat-tab-group>
</div>
