import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fileTypeName'})
export class FileTypeNamePipe implements PipeTransform {

  static DEFAULT_FILE_TYPE_NAME = 'FILE';

  private static sliceWithFileSeparator(value: string, separator: string): string {
    return value.slice(value.lastIndexOf(separator) + 1, value.length);
  }

  private static getFileTypeNameOrDefault(fileTypeName: string) {
    return fileTypeName.length < 5 ? fileTypeName : this.DEFAULT_FILE_TYPE_NAME;
  }

  transform(file: any): string {

    const fileName = file.fileName;
    const fileExtensionIndex = fileName.lastIndexOf('.');

    if (fileExtensionIndex > -1) {
      return FileTypeNamePipe.getFileTypeNameOrDefault(FileTypeNamePipe.sliceWithFileSeparator(fileName, '.'));
    }

    return FileTypeNamePipe.getFileTypeNameOrDefault(FileTypeNamePipe.sliceWithFileSeparator(file.fileType, '/'));
  }
}
