import { Pipe, PipeTransform } from '@angular/core';
import { TransportItem } from '../../../equipment/shared/transport-items.interface';
import { TransportViewProject } from '../../contracts/transport/transport-view-project.interface';

@Pipe({
  name: 'formatAddressFromTransport',
})
export class FormatAddressFromTransportPipe implements PipeTransform {

  constructor() {
  }

  public transform(transportItem: TransportItem | TransportViewProject): string {
    if (transportItem && transportItem.address && (transportItem.address.postalCode || transportItem.address.city)) {
      return [transportItem.address.postalCode, transportItem.address.city]
      .map(value => (value || '').trim())
      .filter(Boolean)
      .join(' ');
    }
    return null;
  }
}
