import { BehaviorSubject, map, Observable, zip } from 'rxjs';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { Injectable } from '@angular/core';
import { FilterTypes, FilterTypesDisplayNames } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import {
  CommonFilterViewConverter,
} from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import { FiltersBaseService } from 'app/shared/contract/filter/filters-base-service.class';
import { MaintenanceTaskService } from './maintenance-task.service';
import { MaintenanceFilterCollectionView } from '../../../shared/contract/maintenance-filter-collection-view.interface';
import { MaintenanceFilterType } from '../filter/maintenance-filter-type.enum';
import { MaintenanceFilterParams } from '../filter/maintenance-filter-params.class';
import { ArticleGroupsFilterViewConverter } from '../../../../../shared/contract/filter/filter-converter/article-groups-filter-view-converter.class';
import { EquipmentTypeFilterViewConverter } from '../../../../../shared/contract/filter/filter-converter/equipment-type-filter-view-converter.class';
import { MaintenanceStatus } from '../filter/maintenance-status.enum';
import { LanguageService } from '../../../../../shared/services/language.service';
import { OrganisationFilterViewConverter } from '../../../../../shared/contract/filter/filter-converter/organisation-filter-view-converter.class';
import { ViewOrganisation } from '../../../../organisation/contract/view-organisation.interface';
import { MaintenanceFilterRequestParams } from '../filter/maintenance-filter-request-params.interface';
import { UpdateFilterCommand } from '../../../../../shared/contract/filter/update-filter-command.interface';
import { EquipmentStatusViewConverter } from '../../../../../shared/contract/filter/filter-converter/equipment-status-view-converter.class';
import { EquipmentStateFilterView } from 'app/shared/contract/filter/filter-view/equipment-state-filter-view.interface';
import { EquipmentStatusCategoryPipe } from '../../../../../shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from '../../../../../shared/pipes/equipment-status-name.pipe';
import { KeycloakService } from '../../../../../core/keycloak';
import { CommonFilterView } from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import { OrganisationFilterView } from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import { ArticleGroupsFilterView } from 'app/shared/contract/filter/filter-view/article-groups-filter-view.interface';
import { MaintenanceTypeFilterView } from 'app/shared/contract/filter/filter-view/maintenance-type-filter-view.interface';
import { MaintenanceStatusFilterView } from 'app/shared/contract/filter/filter-view/maintenance-status-filter-view.interface';
import { AssignedEmployeeFilterView } from 'app/shared/contract/filter/filter-view/assigned-employee-filter-view.interface';
import { EquipmentTypeFilterView } from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import { GroupedEquipmentLocationFilterView } from 'app/shared/contract/filter/filter-view/grouped-equipment-location-filter-view.interface';
import { EquipmentLocationsFilterViewConverterFactoryService } from './equipment-locations-filter-view-converter-factory.service';
import { MaintenanceStatusFilterViewConverter } from 'app/shared/contract/filter/filter-converter/maintenance-status-filter-view-converter.class';
import { ResponsiblePersonFilterViewConverter } from 'app/shared/contract/filter/filter-converter/responsible-person-filter-view-converter.class';
import { MaintenanceTypeFilterViewConverter } from 'app/shared/contract/filter/filter-converter/maintenance-type-filter-view-converter.class';
import { MaintenanceCategoryFilterViewConverter } from 'app/shared/contract/filter/filter-converter/maintenance-category-filter-view-converter.class';
import { MaintenanceCategoryFilterView } from 'app/shared/contract/filter/filter-view/maintenance-category-filter-view.interface';
import { MaintenanceCategoryPipe } from 'app/shared/pipes/maintenance-category.pipe';
import { SearchFilterMediatorReceiverEquipmentStatusesChanged } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator-receiver.interface';
import { SearchFilterMediatorService } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator.service';
import { MaintenanceFilterReadinessChecker } from 'app/shared/contract/filter/readiness-checker/core/checker/maintenance-filter-readiness-checker.class';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { FilterUtils } from '../../../../../shared/contract/filter/filter-utils.class';
import {
  SingleFilterOption
} from '../../../../../shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

type MaintenanceFilterItemView = (
  CommonFilterView |
  OrganisationFilterView |
  ArticleGroupsFilterView |
  MaintenanceTypeFilterView |
  MaintenanceStatusFilterView |
  AssignedEmployeeFilterView |
  EquipmentTypeFilterView |
  GroupedEquipmentLocationFilterView |
  EquipmentStateFilterView |
  MaintenanceCategoryFilterView );

@Injectable()
export class MaintenanceFiltersService
  extends FiltersBaseService<MaintenanceFilterRequestParams>
  implements SearchFilterMediatorReceiverEquipmentStatusesChanged {

  private readonly _isReady = new BehaviorSubject<boolean>(false);
  public readonly isReady = this._isReady.asObservable();
  private organisationMap = new Map<string, ViewOrganisation>();
  private filterChecker = new MaintenanceFilterReadinessChecker(this.params, this.maintenanceTaskService);

  constructor(
    private authService: KeycloakService,
    private organisationsService: OrganisationsService,
    private maintenanceTaskService: MaintenanceTaskService,
    private equipmentLocationsFilterViewConverterFactory: EquipmentLocationsFilterViewConverterFactoryService,
    private equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    private equipmentStatusNamePipe: EquipmentStatusNamePipe,
    private maintenanceCategoryPipe: MaintenanceCategoryPipe,
    protected translatableStringPipe: TranslatableStringPipe,
    protected languageService: LanguageService,
    private filterMediator: SearchFilterMediatorService,
  ) {
    super(new MaintenanceFilterParams());
    this.filterMediator.addReceiver(this);
    this.initIsReadyListener();
  }

  public getFilterParams(): MaintenanceFilterRequestParams {
    this.filterChecker.isReadyCheck();
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.filterChecker.isReadyCheck();
    const updateCommands: UpdateFilterCommand[] = [
      ...commands,
      ...this.createUpdateFilterCommandsForEquipmentLocationsByGroup(commands)
    ];

    super.updateFilterParams(updateCommands, updateFilters);
  }

  public updateFilters(): void {
    if (!this._isReady.value) {
      this.filterChecker.isReadyCheck();
    } else {
      zip(
        this.getFilters(),
        this.getOrganisationMap())
      .subscribe(([filters, organisationMap]) => {
        this.organisationMap = organisationMap;
        filters[MaintenanceFilterType.MATCH_ALL_LABELS] = <CommonFilterView[]>[{ name: SingleFilterOption.ALL_LABELS, count: null }];
        FilterUtils.pushMissingParentOrganisationToFilters(filters[MaintenanceFilterType.ORGANISATION], this.organisationMap);
        const convertedFilters = this.convertToFilterTypes(filters);
        this.mergeToResultFilters(convertedFilters);
        this.onFiltersUpdated.emit();
      });
    }
  }

  public equipmentStatusesChanged(): void {
    this.filterChecker.equipmentStatusesChanged();
  }

  private initIsReadyListener(): void {
    this.filterChecker.isReady
      .subscribe(({ isReady, commands }) => {
        if (isReady) {
          this.updateFilterParams(commands ?? [], false);
          this._isReady.next(isReady);
          this.updateFilters();
        } else {
          this._isReady.next(isReady);
        }
      });
  }

  private getFilters(): Observable<MaintenanceFilterCollectionView> {
    return this.maintenanceTaskService.getMaintenanceTaskFilter(this.params.getFilterParams());
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
    .getOrganisationsByCustomerId(this.getUserCustomerId(), true)
    .pipe(
      map(FilterUtils.toFlatList),
      map(FilterUtils.convertToOrganisationMap));
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }

  private convertToFilterTypes(maintenanceFilters: MaintenanceFilterCollectionView): FilterType[] {
    return Object.keys(maintenanceFilters)
      .map((key: MaintenanceFilterType) => this.createConverter(key, maintenanceFilters[key] as MaintenanceFilterItemView[]))
      .map(filterConverter => filterConverter && filterConverter.getFilters())
      .filter(Boolean);
  }

  private transformStatuses(statuses: any[]) {
    return (statuses || []).map((status: MaintenanceStatusFilterView) => {
      switch (status.warningLevel) {
        case MaintenanceStatus.OVERDUE:
          status.displayName = this.languageService.getInstant('shared.maintenance.statuses.overdue');
          break;
        case MaintenanceStatus.HIGH:
          status.displayName = this.languageService.getInstant('shared.maintenance.statuses.high');
          break;
        case MaintenanceStatus.MEDIUM:
          status.displayName = this.languageService.getInstant('shared.maintenance.statuses.medium');
          break
        case MaintenanceStatus.LOW:
          status.displayName = this.languageService.getInstant('shared.maintenance.statuses.low');
          break;
      }
      return status
    });
  }

  private createUpdateFilterCommandsForEquipmentLocationsByGroup(commands: UpdateFilterCommand[]): UpdateFilterCommand[] {
    return commands
      .filter(({filterType}) => filterType === FilterTypes.EQUIPMENT_LOCATIONS)
      .map((command) => {
        let newCommand: UpdateFilterCommand = {...command};
        newCommand.filterType = newCommand.filterGroupType;
        return newCommand;
      })
  }

  private createConverter(type: MaintenanceFilterType, maintenanceFilters: MaintenanceFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case MaintenanceFilterType.MAINTENANCE_TYPES:
        return new MaintenanceTypeFilterViewConverter(
          maintenanceFilters as MaintenanceTypeFilterView[],
          this.params
        );
      case MaintenanceFilterType.ARTICLE_GROUPS:
        return new ArticleGroupsFilterViewConverter(
          maintenanceFilters as ArticleGroupsFilterView[],
          this.params
        );
      case MaintenanceFilterType.DUE_DATE:
        return new MaintenanceStatusFilterViewConverter(
          this.transformStatuses(maintenanceFilters) as MaintenanceStatusFilterView[],
          this.params
        );
      case MaintenanceFilterType.LABEL:
        return new CommonFilterViewConverter(maintenanceFilters as CommonFilterView[], this.params, FilterTypes.LABEL);
      case MaintenanceFilterType.ORGANISATION:
        return new OrganisationFilterViewConverter(maintenanceFilters as OrganisationFilterView[], this.params,
          this.organisationMap, FilterTypesDisplayNames.ORGANISATION_EQUIPMENT);
      case MaintenanceFilterType.RESPONSIBLE_PERSON:
        return new ResponsiblePersonFilterViewConverter(maintenanceFilters as AssignedEmployeeFilterView[], this.params);
      case MaintenanceFilterType.TYPES:
        return new EquipmentTypeFilterViewConverter(
          maintenanceFilters as EquipmentTypeFilterView[],
          this.params,
          this.translatableStringPipe);
      case MaintenanceFilterType.EQUIPMENT_LOCATIONS:
        return this.equipmentLocationsFilterViewConverterFactory
        .createEquipmentLocationsFilterViewConverter(maintenanceFilters as GroupedEquipmentLocationFilterView[], this.params);
      case MaintenanceFilterType.EQUIPMENT_STATUS:
        return new EquipmentStatusViewConverter(
          maintenanceFilters as EquipmentStateFilterView[],
          this.params,
          this.equipmentStatusCategoryPipe,
          this.equipmentStatusNamePipe
        );
      case MaintenanceFilterType.MAINTENANCE_CATEGORY:
        return new MaintenanceCategoryFilterViewConverter(
          maintenanceFilters as MaintenanceCategoryFilterView[],
          this.params,
          this.maintenanceCategoryPipe
        );
      case MaintenanceFilterType.MATCH_ALL_LABELS:
        return new CommonFilterViewConverter(maintenanceFilters as CommonFilterView[], this.params, FilterTypes.MATCH_ALL_LABELS);
    }
  }

}
