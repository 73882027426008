import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {FilterType, RenderStrategy} from 'app/shared/contract/filter/filter-type';
import {environment} from 'environments/environment';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UntypedFormControl} from '@angular/forms';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {FilterItem, FilterItemType} from '../service/search-filter.converter';
import {FilterTypes} from 'app/shared/contract/filter/filter-types.enum';
import {SearchFilterDatasource} from '../service/search-filter.datasource';
import {FilterValue} from '../../../contract/filter/filter-value';
import {
  SingleFilterOption
} from '../../../contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { ITimeRangeParams } from 'app/shared/contract/time-range-params.interface';
import { TimeRangeFilterDatasource } from '../service/time-range-filter.datasource';
import { ITimeRangeData } from 'app/shared/contract/time-range-data.interface';
import { TimeRangeFilterComponent } from '../../time-range-filter/time-range-filter.component';
import { Router } from '@angular/router';
import { ITimeFrameParams } from 'app/shared/contract/time-frame-params.interface';

@UntilDestroy()
@Component({
  selector: 'bh-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDialogComponent implements OnInit, OnDestroy {

  @ViewChild('timeRangeFilter') timeRangeFilter: TimeRangeFilterComponent;

  filters: FilterType[] = [];
  filtersRaw: FilterItem[] = [];
  public timeRangeParams: ITimeRangeParams;
  public quickFilterParam: string;

  @Input() foundCount: number;
  @Input() isActivated = false;

  public matchAllLabels = false;
  public matchFilterItem: FilterItem;

  public searchTermControl = new UntypedFormControl();
  public showTimeRangeFilter = false;
  public showDateTypeControl = true;
  public readonly FilterItemType = FilterItemType;
  public readonly FilterTypes = FilterTypes;
  private currentPageUrl: string;
  private TRANSFER_LOG = 'analytics/transfer-log';
  private USAGE_HISTORY = 'analytics/usage-history';

  @ViewChild('virtualScroll', {static: true})
  public virtualScrollViewport: CdkVirtualScrollViewport;

  constructor(private searchFilterDatasource: SearchFilterDatasource,
              private timeRangeFilterDatasource: TimeRangeFilterDatasource,
              private changeDetectorRef: ChangeDetectorRef,
              protected router: Router,
  ) { }

  public ngOnInit(): void {
    this.currentPageUrl = this.router.url.slice(1);
    this.searchFilterDatasource.filtersRawChange.pipe(untilDestroyed(this)).subscribe((filters: FilterItem[]) => {
      this.filtersRaw = filters;
      this.changeDetectorRef.detectChanges();
    })

    this.searchFilterDatasource.filtersChange.pipe(untilDestroyed(this)).subscribe((filters: FilterType[]) => {
      this.filters = filters.filter((i: FilterType) => i.typeName !== FilterTypes.MATCH_ALL_LABELS);
      this.matchFilterItem = this.getMatchFilter();
      this.matchAllLabels = (this.matchFilterItem ?.item as FilterValue)?.value;
      this.changeDetectorRef.detectChanges();
    })

    this.searchTermListener();
    this.timeRangeFilterDisplayStatusListener();
    this.setTimeRangeParams();
    this.clearFilterEventSubscription();
  }

  public ngOnDestroy(): void {
    this.searchFilterDatasource.setSearchTerm('');
  }

  public open() {
    setTimeout(() => {
      /* Fix issue when opened dialog is empty */
      /* Reproduce: Open dialog, Scroll to middle, Close dialog, Open dialog */
      this.virtualScrollViewport.scrollToIndex(1);
      this.virtualScrollViewport.scrollToIndex(0);
    });
  }

  public scroll(typeName: string): void {
    const index = this.filtersRaw.findIndex(item => item.filterTypeName === typeName && item.type === FilterItemType.TYPE);
    if (index !== -1) {
      this.virtualScrollViewport.scrollToIndex(index, 'smooth');
    }
  }

  public onValueUpdated(event: UIEvent, filter: FilterItem, index: number): void {
    const newValue = !(filter.item as FilterValue).value;
    this.searchFilterDatasource.changeFilterValue(newValue, index);
    this.changeDetectorRef.detectChanges();
  }

  public getTreeMargin(filter: FilterItem): number {
    if (filter.offset > 1) {
      return (filter.offset - 1) * 26;
    } else {
      return 0;
    }
  }

  public getFilterItemClasses(filter: FilterItem) {
    return {
      'custom-checkbox-container': true,
      'child-checkbox': filter.offset > 0,
    };
  }


  public sliderChanged(): void {
    const newValue = !(this.matchFilterItem.item as FilterValue).value;
    this.searchFilterDatasource.changeFilterValue(newValue, this.matchFilterItem.index);
    this.changeDetectorRef.detectChanges();
  }

  public getMatchFilter(): FilterItem {
    let filter: FilterItem
    this.filtersRaw.forEach((item) => {
      if (item.track === SingleFilterOption.ALL_LABELS) {
        filter = item;
      }
    });
    return filter;
  }

  public showSlider(filterType: FilterType): boolean {
    return filterType.typeName === FilterTypes.LABEL && this.matchFilterItem != null;
  }

  public updateTimeRangeData(params: ITimeRangeData): void {
    switch (this.currentPageUrl) {
      case this.TRANSFER_LOG:
        this.timeRangeFilterDatasource.updateTransferLogTimeRangeParams(
          this.getTimeRangeParams(params),
          params.quickFilterParam,
        );
        break;
      case this.USAGE_HISTORY:
        this.timeRangeFilterDatasource.updateUsageHistoryTimeFrameParams(
          this.getTimeFrameParams(params),
          params.quickFilterParam,
        );
        break;
      default:
        break;
    }
  }

  private getTimeRangeParams(params: ITimeRangeData): ITimeRangeParams {
    return {
      dateType: params.dateType,
      fromDate: params.fromDate,
      toDate: params.toDate,
    }
  }

  private getTimeFrameParams(params: ITimeRangeData): ITimeFrameParams {
    return {
      fromDate: params.fromDate,
      toDate: params.toDate,
    }
  }

  private timeRangeFilterDisplayStatusListener(): void {
    this.timeRangeFilterDatasource.showTimeRangeFilter
      .pipe(untilDestroyed(this))
      .subscribe(status => {
        this.showTimeRangeFilter = status;
        if (status) {
          this.showDateTypeControl = this.timeRangeFilterDatasource.showDateTypeControl;
        }
      });
  }

  private clearFilterEventSubscription(): void {
    this.timeRangeFilterDatasource.clearFilterEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.timeRangeFilter) {
          this.timeRangeFilter.setTimeRangeParams(
            this.timeRangeFilterDatasource.defaultTimeRangeParams,
            this.timeRangeFilterDatasource.defaultQuickFilterParam,
          );
        }
      });
  }

  private setTimeRangeParams(): void {
    switch (this.currentPageUrl) {
      case this.TRANSFER_LOG:
        this.setTransferLogTimeRangeParams();
        break;
      case this.USAGE_HISTORY:
        this.setUsageHistoyTimeFrameParams();
        break;
      default:
        this.timeRangeParams = this.timeRangeFilterDatasource.defaultTimeRangeParams;
        this.quickFilterParam = this.timeRangeFilterDatasource.defaultQuickFilterParam;
        break;
      }
  }

  private setTransferLogTimeRangeParams(): void {
    this.timeRangeParams = this.timeRangeFilterDatasource.getTransferLogTimeRangeParams();
    this.quickFilterParam = this.timeRangeFilterDatasource.getTransferLogQuickFilterParam();
  }

  private setUsageHistoyTimeFrameParams(): void {
    this.timeRangeParams = {
      dateType: '',
      ...this.timeRangeFilterDatasource.getUsageHistoryTimeFrameParams(),
    };
    this.quickFilterParam = this.timeRangeFilterDatasource.getUsageHistoryQuickFilterParam();
  }

  private searchTermListener(): void {
    this.searchTermControl.valueChanges
    .pipe(untilDestroyed(this), debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged())
    .subscribe(term => this.searchFilterDatasource.setSearchTerm(term));
  }

  public getCheckBoxState(filter: FilterItem, index: number): { checked: boolean, indeterminate: boolean } {
    return {
      checked: this.isCheckedFilter(filter),
      indeterminate: this.isIndeterminateFilter(filter, index),
    }
  }

  private isCheckedFilter(filter: FilterItem): boolean {
    return (filter.item as FilterValue).value === true;
  }

  private isIndeterminateFilter(filter: FilterItem, index: number): boolean {
    if (filter.renderStrategy !== RenderStrategy.RELATIONS_TREE
      && filter.renderStrategy !== RenderStrategy.GROUPED_TREE) {
      return false;
    }

    if ((filter.item as FilterValue).value === true) {
      return false;
    }

    const rawFilter = this.filtersRaw;
    for (let i = index + 1; i < rawFilter.length; i++) {
      let nextFilter = rawFilter[i];
      if (filter.offset >= nextFilter.offset) {
        return false;
      }

      if ((nextFilter.item as FilterValue).value === true) {
        return true;
      }
    }

    return false;
  }

  public trackByTrackId(index: number, item: FilterItem): any {
    return item.track;
  }

  public isEquipOrgFilterInGlobalTransferLog(type: FilterTypes) {
    return type === FilterTypes.EQUIPMENT_ORGANISATION && this.router.url.includes('analytics/transfer-log');
  }
}
