import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OrganisationsService } from '../../shared/organisations.service';
import { KeycloakService } from '../../../../core/keycloak';
import { ViewCustomer } from '../../contract/view-customer.interface';
import { ViewOrganisation } from '../../contract/view-organisation.interface';
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { OrganisationType } from '../../../../shared/enums/organisation-type.enum';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateOrganisationCommand } from '../../contract/update-organisation-command';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { take } from 'rxjs/operators';
import { delay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { CountryEnum } from '../../../../shared/country.enum';
import { CountryResolver } from '../../../../shared/country.resolver';
import { OrganisationValidators } from '../organisation.validators';

@Component({
  selector: 'bh-organisation-edit',
  templateUrl: './organisation-edit.component.html',
  styleUrls: ['./organisation-edit.component.scss'],
})
export class OrganisationEditComponent extends GuardedNavigableInputComponent implements OnInit {

  public countryEnum = CountryEnum;
  public organisationEditForm: UntypedFormGroup;
  public organisationToEdit: ViewOrganisation;
  public customer: ViewCustomer;
  public organisationTypes = [OrganisationType.Department, OrganisationType.Area,
    OrganisationType.Company, OrganisationType.Location, OrganisationType.Subcompany];

  public readonly fieldLimit = FieldLimit;

  private organisationId: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private organisationsService: OrganisationsService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private countryResolver: CountryResolver) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.organisationId = params['id'];

      this.buildForm();
      this.organisationEditForm.disable();
      this.getOrganisations();
    });
  }

  get countryKeys() {
    return Object.keys(this.countryEnum);
  }

  public navigateBack(): void {
    this.goBack('/organisations/list');
  }

  private buildForm() {
    this.organisationEditForm = this.formBuilder.group({
      organisationId: [null, [<any>Validators.required]],
      organisationName: [null, [<any>Validators.required],
        [OrganisationValidators.uniqueOrganisationName(this.organisationsService, this.organisationId)]],
      organisationNumber: [null, [],
        [OrganisationValidators.uniqueOrganisationNumber(this.organisationsService, this.organisationId)]],
      organisationType: [null, [<any>Validators.required]],
      organisationContact: this.formBuilder.group({
        name: ['', [<any>Validators.required]],
        phoneNumber: null,
        mobileNumber: null,
        faxNumber: null,
        email: [null, [emailValidator()]],
      }),
      organisationAddress: this.formBuilder.group({
        country: [null, [<any>Validators.required]],
        street: null,
        streetNumber: null,
        postalCode: null,
        city: null,
      }),
    });
  }

  public getCountryName(option: CountryEnum): string {
    return this.countryResolver.resolveName(option);
  }

  get organisationName() {
    return this.organisationEditForm.get('organisationName');
  }

  get organisationNumber() {
    return this.organisationEditForm.get('organisationNumber');
  }

  get organisationType() {
    return this.organisationEditForm.get('organisationType');
  }

  get organisationContactName() {
    return this.organisationEditForm.get('organisationContact').get('name');
  }

  get organisationPhoneNumber() {
    return this.organisationEditForm.get('organisationContact').get('phoneNumber');
  }

  get organisationMobileNumber() {
    return this.organisationEditForm.get('organisationContact').get('mobileNumber');
  }

  get organisationFaxNumber() {
    return this.organisationEditForm.get('organisationContact').get('faxNumber');
  }

  get organisationContactEmail() {
    return this.organisationEditForm.get('organisationContact').get('email');
  }

  get organisationCountry() {
    return this.organisationEditForm.get('organisationAddress').get('country');
  }

  get organisationStreet() {
    return this.organisationEditForm.get('organisationAddress').get('street');
  }

  get organisationStreetNumber() {
    return this.organisationEditForm.get('organisationAddress').get('streetNumber');
  }

  get organisationPostalCode() {
    return this.organisationEditForm.get('organisationAddress').get('postalCode');
  }

  get organisationCity() {
    return this.organisationEditForm.get('organisationAddress').get('city');
  }

  private getOrganisations() {
    this.organisationsService.getOrganisation(this.organisationId)
      .pipe(take(1))
      .subscribe(organisation => {
        this.organisationEditForm.enable();
        this.organisationToEdit = organisation;
        this.setFormValues();
      });
  }

  save() {
    if (this.isValid()) {
      let formValue = this.organisationEditForm.getRawValue();
      let cmd = new UpdateOrganisationCommand();
      cmd.organisationId = formValue.organisationId;
      cmd.organisationName = formValue.organisationName;
      cmd.organisationNumber = formValue.organisationNumber;
      cmd.organisationType = formValue.organisationType;
      cmd.organisationContact = formValue.organisationContact;
      cmd.organisationContact.email = formValue.organisationContact.email ? formValue.organisationContact.email : null;
      cmd.organisationAddress = formValue.organisationAddress;

      this.organisationsService.updateOrganisation(cmd).
        pipe(delay(environment.DELAY_SHORT))
        .subscribe(
        () => {
          this.navigateAfterSubmit();
        },
        (error: HttpErrorResponse) => {
          console.log('add organisation error: ', error);
        },
      );
    }
  }

  public isValid(): boolean {
    return this.organisationEditForm.valid;
  }

  private setFormValues() {
    this.organisationEditForm.patchValue({
      organisationId: this.organisationToEdit.organisationId,
      organisationName: this.organisationToEdit.organisationName,
      organisationNumber: this.organisationToEdit.organisationNumber,
      organisationType: OrganisationEditComponent.transformToEnglishTypes(this.organisationToEdit.organisationType),
    });

    if (this.organisationToEdit.organisationAddress) {
      this.organisationEditForm.patchValue({
        organisationAddress: this.organisationToEdit.organisationAddress,
      });
    }

    if (this.organisationToEdit.organisationContact) {
      this.organisationEditForm.patchValue({
        organisationContact: this.organisationToEdit.organisationContact,
      });
    }
  }

  private static transformToEnglishTypes(organisationType: any): OrganisationType {
    switch (organisationType) {
      case OrganisationType.Abteilung:
        return OrganisationType.Department;
      case OrganisationType.Bereich:
        return OrganisationType.Area;
      case OrganisationType.Firma:
        return OrganisationType.Company;
      case OrganisationType.Standort:
        return OrganisationType.Location;
      case OrganisationType.Subfirma:
        return OrganisationType.Subcompany;
      default:
        return organisationType;
    }
  }
}
