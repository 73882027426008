import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { DocuwareDocument } from '../../../contract/docuware-document.interface';
import { FileUtils } from '../../../fileUtils';

@Directive()
export abstract class BaseAttachmentDocuwareComponent {

  @Input() documents: DocuwareDocument[];
  @Input() loading = false;
  @Input() serviceAvailable = true;
  @Input() pendingDownloads: DocuwareDocument[] = [];

  @Output() downloadRequested = new EventEmitter<DocuwareDocument>();

  public getDocumentTypeIcon(document: DocuwareDocument) {
    return FileUtils.getDocuwareFileTypeIcon(document);
  }

  public downloadDocument(document: DocuwareDocument): void {
    this.downloadRequested.emit(document);
  }

  public isPendingDocument(document: DocuwareDocument): boolean {
    return this.pendingDownloads.indexOf(document) > -1;
  }
}
