import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { GalleryImage } from '../../models/gallery-image.class';
import { ThumbnailSize } from '../../enums/thumbnail-size.enum';
import _ from 'lodash';
import { GalleryOptions } from '../../models/gallery-options.class';
import { GalleryImageSize } from '../../enums/gallery-image-size.enum';
import { GalleryAnimation } from '../../enums/gallery-animation.enum';
import { FileUtils } from 'app/shared/fileUtils';

interface GalleryDetailViewSimpleChanges extends SimpleChanges {
  keys: SimpleChange;
}

@Component({
  selector: 'bh-gallery-detail-view',
  templateUrl: './gallery-detail-view.component.html',
  styleUrls: ['./gallery-detail-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryDetailViewComponent implements OnChanges {

  @Input() keys: string[] = [];
  public images: GalleryImage[] = [];
  public readonly options: GalleryOptions[] = [
    {
      width: '100%',
      height: '300px',
      thumbnailsColumns: 4,
      imageSize: GalleryImageSize.CONTAIN,
      imageAnimation: GalleryAnimation.SLIDE,
      previewCloseOnEsc: true,
      previewCloseOnClick: true
    }
  ];

  public ngOnChanges(changes: GalleryDetailViewSimpleChanges): void {
    if (changes.keys && !_.isEqual(changes.keys.previousValue, changes.keys.currentValue)) {
      this.fillGallery();
    }
  }

  private fillGallery(): void {
    this.images = (this.keys || [])
      .filter(Boolean)
      .map(key => FileUtils.encodeDocumentKey(key))
      .map(encodedKey => ({
        small: `${encodedKey}?thumbnail=${ThumbnailSize.S}`,
        medium: `${encodedKey}?thumbnail=${ThumbnailSize.M}`,
        big: encodedKey,
      }));
  }

}
