import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewUser } from '../../contract/view-user.interface';
import { UpdateUserCommand } from '../../contract/update-user-command';
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { ResetUserPasswordCommand } from '../../contract/reset-user-password-command';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { UsersStore } from '../../shared/users.store';
import { HttpErrorResponse } from '@angular/common/http';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AbstractControl } from '@angular/forms';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';

@UntilDestroy()
@Component({
  selector: 'bh-user-edit-admin',
  templateUrl: './user-edit-admin.component.html',
  styleUrls: ['./user-edit-admin.component.scss']
})
export class UserEditAdminComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public readonly fieldLimit = FieldLimit;

  userEditAdminForm: UntypedFormGroup;
  today: Date = new Date();
  requestInProgress = false;
  roleName: string;

  private user: ViewUser;
  private userId: string;

  constructor(protected router: Router,
              protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private formBuilder: UntypedFormBuilder,
              private usersStore: UsersStore) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    this.buildForm();
    this.getUser().then((viewUser) => {
        this.user = viewUser;
        this.roleName = viewUser.roles[0].name;
        this.requestInProgress = false;
        this.setFormValues();
      });

  }

  ngOnDestroy(): void {
  }

  public navigateBack(): void {
    this.goBack('/users/list');
  }

  save(): void {
    if (this.isValid()) {
      let formValue = this.userEditAdminForm.getRawValue();
      let cmd: UpdateUserCommand = new UpdateUserCommand();

      if (formValue.validUntil instanceof Date) {
        formValue.validUntil = formValue.validUntil.toISOString();
      }

      cmd.userId = formValue.userId;
      cmd.firstName = formValue.firstName;
      cmd.name = formValue.name;
      cmd.mobileNumber = formValue.mobileNumber;
      cmd.validUntil = formValue.validUntil;

      // specific fields for priviledged roles
      cmd.roleIds = [this.user.roles[0].id];
      cmd.customerId = this.user.customer;
      cmd.organisationIds = [];

      this.usersStore.updateUser(cmd).pipe(untilDestroyed(this)).subscribe(
        () => {
          this.router.navigate(['/users/list']);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      );
    }
  }

  public isValid(): boolean {
    return this.userEditAdminForm.getRawValue();
  }

  resetPassword(): void {
    let cmd: ResetUserPasswordCommand = new ResetUserPasswordCommand();
    cmd.userId = this.userId;
    this.usersStore.resetPassword(cmd);
  }

  private getUser(): Promise<ViewUser> {
    this.userId = this.route.snapshot.params['id'];
    this.requestInProgress = true;

    return this.usersStore.getUser(this.userId).toPromise();
  }

  private setFormValues(): void {
    this.userEditAdminForm.patchValue({
      userId: this.user.userId,
      email: this.user.email,
      firstName: this.user.firstName,
      name: this.user.name,
      mobileNumber: this.user.mobileNumber,
      validUntil: this.user.validUntil
    });
  }

  private buildForm(): void {
    this.userEditAdminForm = this.formBuilder.group({
      userId: ['', [<any>Validators.required]],
      email: ['', [<any>Validators.required, emailValidator()]],
      name: ['', [<any>Validators.required]],
      firstName: '',
      mobileNumber: '',
      validUntil: ''
    });
  }

  get email(): AbstractControl | null {
    return this.userEditAdminForm.get('email');
  }

  get validUntil(): AbstractControl | null {
    return this.userEditAdminForm.get('validUntil');
  }

  get name(): AbstractControl | null {
    return this.userEditAdminForm.get('name');
  }
}
