import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (environment.SENTRY.length > 0) {
      if (!(error instanceof HttpErrorResponse)) {
        Sentry.captureException(error);
      }
    }
  }
}
