import { Injectable } from '@angular/core';
import { MapIconResolverProject } from './map-icon-resolver-project.service';
import { MapIconResolverStock } from './map-icon-resolver-stock.service';
import { MapIconResolverContact } from './map-icon-resolver-contact.service';
import { MapIconPayloadTransport } from '../types/map-icon-payload-transport.type';
import { TransportItem } from 'app/modules/equipment/shared/transport-items.interface';
import { TransportItemType } from 'app/modules/transportation/shared/enums/transport-item-type.enum';
import { TransportItemLocation } from '../../../interfaces/transport-item-location.interface';

@Injectable()
export class MapIconResolverTransport {

  constructor(
    private resolverProject: MapIconResolverProject,
    private resolverStock: MapIconResolverStock,
    private resolverContact: MapIconResolverContact,
  ) { }

  public getSvgIcon(transport: MapIconPayloadTransport): string {
    const transportItemType = (<TransportItem>transport)?.transportItemType || (<TransportItemLocation>transport)?.itemType;
    switch (transportItemType) {
      case TransportItemType.PROJECT:
        return this.resolverProject.getSvgIcon();
      case TransportItemType.STOCK:
        return this.resolverStock.getSvgIcon(transport);
      case TransportItemType.CONTACT:
        return this.resolverContact.getSvgIcon(transport);
      default:
        return null;
    }
  }
}
