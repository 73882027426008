import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceTypeFieldTranslationPipe } from 'app/shared/pipes/maintenance-type-field-translation.pipe';
import { LanguageService } from 'app/shared/services/language.service';
import { MaintenanceHistoryColumnMainFields, MaintenanceHistoryColumnType } from '../../enums/maintenance-history-column.interface';

type StaticFieldTranslations = {
  [key in MaintenanceHistoryColumnMainFields]: string;
}

@Pipe({
  name: 'maintenanceTaskCompletedHistoryFieldTranslation'
})
export class MaintenanceTaskCompletedHistoryFieldTranslationPipe implements PipeTransform {
  private translations: StaticFieldTranslations = {
    [MaintenanceHistoryColumnMainFields.CREATED]: this.translate('modules.maintenance.base.maintenanceCompletedCreated'),
    [MaintenanceHistoryColumnMainFields.COMPLETED_AT_DATE]: this.translate('shared.maintenance.task.completionDate'),
    [MaintenanceHistoryColumnMainFields.COMPLETED_AT_MILEAGE]: this.translate('shared.maintenance.task.completedAtMileage'),
    [MaintenanceHistoryColumnMainFields.COMPLETED_AT_OPERATING_HOURS]: this.translate('shared.maintenance.task.completedAtOperatingHours'),
    [MaintenanceHistoryColumnMainFields.MAINTENANCE_RESULT]: this.translate('shared.maintenance.task.maintenanceResult'),
    [MaintenanceHistoryColumnMainFields.DOCUMENT]: this.translate('general.document.s'),
    [MaintenanceHistoryColumnMainFields.IMAGE]: this.translate('general.image.s'),
  }

  constructor(
    private languageService: LanguageService,
    private dynamicFieldResolver: MaintenanceTypeFieldTranslationPipe
  ) { }

  public transform(value: MaintenanceHistoryColumnType): string {
    return this.getTranslation(value);
  }

  private getTranslation(value: MaintenanceHistoryColumnType): string {
    return this.translations[value] || this.dynamicFieldResolver.transform(value);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
