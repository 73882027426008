import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ChatContainerComponent } from '../chat.container.component';
import { ChatListComponent } from '../chat-list/landscape/chat-list.component';
import { ChatComponent } from '../chat/landscape/chat.component';
import { Modules } from '../../../shared/enums/modules.enum';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { CanActivateChildLandscape } from '../../../core/guards/can-activate-child-landscape';

const chatRoutes: Routes = [
  {
    path: 'chat',
    component: ChatContainerComponent,
    outlet: 'sidenav',
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape],
    data: {
      modules: [Modules.COMMUNICATION],
      authorities: [Authorities.MESSAGE_CENTER_USE]
    },
    children: [
      {
        path: '',
        component: ChatListComponent,
      },
      {
        path: ':id',
        component: ChatComponent,
        data: {
          authorities: [Authorities.MESSAGE_CENTER_USE]
        },
      }
    ]
  }
];

export const chatRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(chatRoutes);
