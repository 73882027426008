export class AddEmployeeToProjectAssignmentCommand {
    public employeeId: string;
    public assignmentId: string;
    public projectId: string;
    public assignmentStartDate: string;
    public assignmentEndDate: string;
    public comment: string;
    public projectEndDate: Date;
    public overwrite: boolean;

    constructor (employeeId: string,
        projectId: string,
        assignmentStartDate: string,
        assignmentEndDate: string,
        comment: string,
        overwrite: boolean) {
            this.employeeId = employeeId;
            this.projectId = projectId;
            this.assignmentStartDate = assignmentStartDate;
            this.assignmentEndDate = assignmentEndDate;
            this.comment = comment;
            this.overwrite = overwrite;
        }
}
