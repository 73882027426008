import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeycloakService } from '../keycloak';
import { Modules } from '../../shared/enums/modules.enum';

@Injectable()
export class CanActivateChildModule implements CanActivateChild {
  constructor(private authService: KeycloakService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!childRoute.data.hasOwnProperty('modules')) {
      throw Error('Route has no modules property');
    } else {
      let modules: Array<Modules> = childRoute.data['modules'];
      if (modules instanceof Array) {
        let found = false;
        modules.forEach(module => {
          if (this.authService.hasModule(module)) {
            found = true;
          }
        });
        return found;
      } else {
        throw Error('modules property is not an array');
      }
    }
  }
}
