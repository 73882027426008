<div class="list-view-main__right" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-main__right-header" fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between center">

    <div [matTooltip]="(stockStore.fullScreen ? 'general.labels.detailView.reduce' : 'general.labels.detailView.enlarge')|translate"
         (click)="stockStore.toggleFullScreen()" class="full-screen" fxFlex="0 0 70px" fxLayout="row"
         fxLayoutAlign="center center">
      <mat-icon *ngIf="!stockStore.fullScreen" fontSet="icon-fullscreen_lauch_outline"></mat-icon>
      <mat-icon *ngIf="stockStore.fullScreen" fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>

    <div class="name" fxFlex="10 1 100%" fxLayout="row" fxLayoutGap="15px">
      <span class="stock-name" [ngClass]="{'no-spaces': noSpaces(stock?.stockName)}" data-osp-test="label-stock-name">
        {{ stock?.stockName }}
      </span>
      <fa-icon *ngIf="stock.stockType === 'MAIN'"
               [icon]="faGlobe"
               [matTooltip]="'modules.disposition.stockView.mainStock'|translate"
               class="default-icon"></fa-icon>
    </div>


    <div fxFlex="0 1 90px" class="edit">
      <button mat-raised-button (click)="edit()"
              *ngIf="hasAuthority(authorities.STOCK_MANAGE)">
        <span>{{'general.buttons.edit'|translate}}</span>
      </button>
    </div>

    <div fxFlex="0 1 40px" class="more" [matMenuTriggerFor]="stockMore"
         *ngIf="showMoreMenu()">
      <mat-icon>more_vert</mat-icon>
    </div>

    <mat-menu #stockMore="matMenu">
      <span [matTooltip]="stock.stockType !== 'MAIN' ?
      ('modules.disposition.stockView.stockCannotDelete'|translate) :
      ('modules.disposition.stockView.mainStockCannotDelete'|translate)"
            [matTooltipDisabled]="canDelete()">
        <button mat-menu-item [disabled]="!canDelete()" (click)="delete()">
          <mat-icon>delete_forever</mat-icon>
          <span>{{'modules.disposition.stockView.deleteStock'|translate}}</span>
        </button>
      </span>
    </mat-menu>
  </div>

  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div #submenu class="submenu" fxFlex="70px" fxLayout="column" fxLayoutAlign="start stretch">
      <div>
        <p (click)="setTab(subMenuRouterLinks.GENERAL)"
          [routerLink]="[subMenuRouterLinks.GENERAL]"
          routerLinkActive="submenu--active">
          <mat-icon fontSet="icon-general_outline"></mat-icon>
          <span>{{'general.generalData'|translate}}</span>
        </p>
        <p *ngIf="hasAuthority(authorities.STOCK_VIEW) && hasModule(modules.AUTOMATED_TRANSFERS) && isGeofenceAvailableType"
           (click)="setTab(subMenuRouterLinks.GEOFENCES)"
           [routerLink]="[subMenuRouterLinks.GEOFENCES]"
           routerLinkActive="submenu--active">
          <mat-icon fontSet="icon-geofences_outline"></mat-icon>
          <span>{{'modules.disposition.projectView.geofences'|translate}}</span>
        </p>
        <p (click)="setTab(subMenuRouterLinks.ATTACHMENTS)"
          [routerLink]="[subMenuRouterLinks.ATTACHMENTS]"
          routerLinkActive="submenu--active">
          <mat-icon fontSet="icon-attachment_outline"></mat-icon>
          <span>{{'general.attachment.pl'|translate}}</span>
        </p>
        <p (click)="setTab(subMenuRouterLinks.INVENTORY)"
          [routerLink]="[subMenuRouterLinks.INVENTORY]"
          routerLinkActive="submenu--active">
          <mat-icon><fa-icon [icon]="faBoxesAlt"></fa-icon></mat-icon>
          <span>{{'modules.disposition.base.inventory.s'|translate}}</span>
        </p>
        <p *ngIf="hasAuthority(authorities.STOCK_VIEW)"
           (click)="setTab(subMenuRouterLinks.TRANSFER)"
           [routerLink]="[subMenuRouterLinks.TRANSFER]" routerLinkActive="submenu--active">
          <mat-icon><fa-icon [icon]="faExchange"></fa-icon></mat-icon>
          <span>{{'modules.disposition.projectView.transfers'|translate}}</span>
        </p>
      </div>
      <p #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="tabsMoreMenu"
         [ngClass]="{'submenu--active': isTabMoreMenuActive()}">
        <mat-icon>more_horiz</mat-icon>
        <span>{{'general.buttons.more'|translate}}</span>
      </p>
    </div>

    <mat-menu class="equipment-custom-mat-menu" #tabsMoreMenu="matMenu">
      <ng-container *ngFor="let tab of hiddenTabs">
        <button mat-menu-item [routerLink]="[tab.url]" (click)="setTab(tab.url)">
          <mat-icon *ngIf="tab.matIconFontSet || tab.matIconContent" [fontSet]="tab.matIconFontSet">
            {{ tab.matIconContent }}
          </mat-icon>
          <mat-icon *ngIf="tab.faIcon"><fa-icon [icon]="tab.faIcon"></fa-icon></mat-icon>
          <span>{{ tab.name }}</span>
        </button>
      </ng-container>
    </mat-menu>

    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
