import { DatesService } from 'app/shared/services/dates.service';
import { TransportAssignment } from './../../../contracts/transport/transport-assignment.interface';
import { TransportAssignmentCreateCommand } from './transport-assignment-create.command';
import { TransportAssignmentUpdateCommand } from './transport-assignment-update.command';
import { DTZ } from 'app/shared/timezone/date-timezone';

export class TransportAssignmentPayload {
  constructor(
    public equipmentId: string,
    public assignment: TransportAssignment,
    public startDate: Date,
    public endDate: Date
  ) {}

  public getCreateCommandStore(): TransportAssignmentCreateCommand {
    return {
      transportId: this.assignment.transportId,
      equipmentId: this.equipmentId,
      startDate: {
        timestamp: DTZ.DateTimezone({ display: this.startDate }).storeDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      },
      endDate: {
        timestamp: DTZ.DateTimezone({ display: this.endDate }).storeDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      }
    }
  }

  public getCreateCommandDisplay(): TransportAssignmentCreateCommand {
    return {
      transportId: this.assignment.transportId,
      equipmentId: this.equipmentId,
      startDate: {
        timestamp: this.startDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      },
      endDate: {
        timestamp: this.endDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      }
    }
  }

  public getUpdateCommandStore(): TransportAssignmentUpdateCommand {
    return {
      assignmentId: this.assignment.assignmentId,
      transportId: this.assignment.transportId,
      equipmentId: this.equipmentId,
      startDate: {
        timestamp: DTZ.DateTimezone({ display: this.startDate }).storeDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      },
      endDate: {
        timestamp: DTZ.DateTimezone({ display: this.endDate }).storeDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      }
    }
  }

  public getUpdateCommandDisplay(): TransportAssignmentUpdateCommand {
    return {
      assignmentId: this.assignment.assignmentId,
      transportId: this.assignment.transportId,
      equipmentId: this.equipmentId,
      startDate: {
        timestamp: this.startDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      },
      endDate: {
        timestamp: this.endDate.toISOString(),
        zoneId: DatesService.getLocalTimeZone()
      }
    }
  }

  public clone(props: Partial<Pick<TransportAssignmentPayload, 'equipmentId' | 'assignment' | 'startDate' | 'endDate'>>): TransportAssignmentPayload {
    const { equipmentId, assignment, startDate, endDate } = { ...this, ...(props ?? {}) };
    return new TransportAssignmentPayload(equipmentId, assignment, startDate, endDate);
  }
}
