import { EquipmentEmployeeRole } from './equipment-employee-role.enum';

export class AddEquipmentEmployeeAssignmentCommand {

  public constructor(
    public equipmentId: string,
    public employeeId: string,
    public handOverDate: string,
    public employeeRole: EquipmentEmployeeRole) {
  }

}
