import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ReportParameter } from '../../contract/report-parameter.enum';

@Pipe({
  name: 'reportParameterDisplay'
})
export class ReportParameterPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(value: string): string {
    switch (value) {
      case ReportParameter.ALL_EQUIPMENTS:
        return this.translate('shared.pipes.reportParameterDisplay.allEquipments');
      case ReportParameter.ALL_PROJECTS:
        return this.translate('shared.pipes.reportParameterDisplay.allProjects');
      case ReportParameter.DATE_FROM:
        return this.translate('shared.pipes.reportParameterDisplay.from');
      case ReportParameter.DATE_UNTIL:
        return this.translate('shared.pipes.reportParameterDisplay.to');
      case ReportParameter.EQUIPMENT_ID_LIST:
        return this.translate('shared.pipes.reportParameterDisplay.listOfEquipment');
      case ReportParameter.EQUIPMENT_LABELS:
        return this.translate('general.label.pl');
      case ReportParameter.EQUIPMENT_TYPES:
        return this.translate('general.equipmType.pl');
      case ReportParameter.ORGANISATIONS:
        return this.translate('general.org.pl');
      case ReportParameter.PROJECT_ID_LIST:
        return this.translate('shared.pipes.reportParameterDisplay.listOfProjects');
      default:
        return this.translate('shared.pipes.reportParameterDisplay.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
