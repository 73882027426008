import { PerformanceType } from './performance-type.enum';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import { UUID } from 'angular2-uuid';
import {OspDateTime} from './osp-date-time';

export class AddEquipmentInvoiceCommand {
  invoiceId: UUID;
  invoiceNumber: string;
  equipmentId: string;
  invoiceDate: OspDateTime;
  performanceDate?: OspDateTime;
  performanceType: PerformanceType;
  invoiceAmount: number;
  documentation?: string;
  performanceDuration?: string;
  callOutFee?: number;
  allowance?: number;
  personnelCosts?: number;
  travelCosts?: number;
  materialCosts?: number;
  freightCharges?: number;
  drive?: number;
  serviceContractor?: string;
  serviceWorker?: string;
  documents?: AttachedDocument[];
  imported: boolean;

}
