import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, zip} from 'rxjs';
import {KeycloakService} from 'app/core/keycloak';
import {OrganisationsService} from 'app/modules/organisation/shared/organisations.service';
import {TransportService} from './transport.service';
import {ViewOrganisation} from 'app/modules/organisation/contract/view-organisation.interface';
import {FilterType} from 'app/shared/contract/filter/filter-type';
import {Injectable} from '@angular/core';
import {VehicleFilterRequestParams} from '../contract/request-params/vehicle-filter-request-params.interface';
import {FilterTypes, FilterTypesDisplayNames} from 'app/shared/contract/filter/filter-types.enum';
import {
  VehicleFilterCollectionView
} from 'app/shared/contract/filter/filter-view/vehicle-filter-collection/vehicle-filter-collection-view.interface';
import {
  VehicleFilterType
} from 'app/shared/contract/filter/filter-view/vehicle-filter-collection/vehicle-filter-type.enum';
import {BaseFilterViewConverter} from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import {
  CommonFilterViewConverter
} from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import {
  OrganisationFilterViewConverter
} from 'app/shared/contract/filter/filter-converter/organisation-filter-view-converter.class';
import {VehicleFilterParams} from '../contract/request-params/vehicle-filter-params.class';
import {FiltersBaseService} from 'app/shared/contract/filter/filters-base-service.class';
import {
  EquipmentTypeFilterViewConverter
} from 'app/shared/contract/filter/filter-converter/equipment-type-filter-view-converter.class';
import {CommonFilterView} from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import {OrganisationFilterView} from 'app/shared/contract/filter/filter-view/organisation-filter-view.interface';
import {EquipmentTypeFilterView} from 'app/shared/contract/filter/filter-view/equipment-type-filter-view.interface';
import {FilterUtils} from 'app/shared/contract/filter/filter-utils.class';
import {EquipmentStatusCategoryPipe} from 'app/shared/pipes/equipment-status-category.pipe';
import {EquipmentStatusNamePipe} from 'app/shared/pipes/equipment-status-name.pipe';
import {
  EquipmentStatusViewConverter
} from 'app/shared/contract/filter/filter-converter/equipment-status-view-converter.class';
import {EquipmentStateFilterView} from 'app/shared/contract/filter/filter-view/equipment-state-filter-view.interface';
import {UpdateFilterCommand} from 'app/shared/contract/filter/update-filter-command.interface';
import {
  SingleFilterOption
} from '../../../../shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { SearchFilterMediatorService } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator.service';
import { SearchFilterMediatorReceiverEquipmentStatusesChanged } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator-receiver.interface';
import { VehicleFilterReadinessChecker } from 'app/shared/contract/filter/readiness-checker/core/checker/vehicle-filter-readiness-checker.class';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

type VehicleFilterItemView =
  CommonFilterView
  | OrganisationFilterView
  | VehicleFilterCollectionView
  | EquipmentStateFilterView
  | EquipmentTypeFilterView;

@Injectable()
export class VehicleFiltersService
  extends FiltersBaseService<VehicleFilterRequestParams>
  implements SearchFilterMediatorReceiverEquipmentStatusesChanged {
  private organisationMap = new Map<string, ViewOrganisation>();

  private readonly _isReady = new BehaviorSubject<boolean>(false);
  public readonly isReady = this._isReady.asObservable();
  private filterChecker = new VehicleFilterReadinessChecker(this.params, this.transportService);

  constructor(
    private transportService: TransportService,
    private organisationsService: OrganisationsService,
    private authService: KeycloakService,
    private equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    private equipmentStatusNamePipe: EquipmentStatusNamePipe,
    protected translatableStringPipe: TranslatableStringPipe,
    private filterMediator: SearchFilterMediatorService,
  ) {
    super(new VehicleFilterParams());
    this.filterMediator.addReceiver(this);
    this.initIsReadyListener();
  }

  public getFilterParams(): VehicleFilterRequestParams {
    this.filterChecker.isReadyCheck();
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.filterChecker.isReadyCheck();
    super.updateFilterParams(commands, updateFilters);
  }

  public updateFilters(): void {
    if (!this._isReady.value) {
      this.filterChecker.isReadyCheck();
    } else {
      zip(
        this.getFilters(),
        this.getOrganisationMap(),
        of(<CommonFilterView[]>[{ name: SingleFilterOption.ALL_LABELS, count: null }])
        )
      .subscribe(([filters, organisationMap, matchAllLabels]) => {
        filters[VehicleFilterType.MATCH_ALL_LABELS] = matchAllLabels;
        this.organisationMap = organisationMap;
        FilterUtils.pushMissingParentOrganisationToFilters(filters[VehicleFilterType.ORGANISATION], organisationMap);
        const convertedFilters = this.convertToFilterTypes(filters);
        this.mergeToResultFilters(convertedFilters);
        this.onFiltersUpdated.emit();
      });
    }
  }

  public equipmentStatusesChanged(): void {
    this.filterChecker.equipmentStatusesChanged();
  }

  private initIsReadyListener(): void {
    this.filterChecker.isReady
      .subscribe(({ isReady, commands }) => {
        if (isReady) {
          this.updateFilterParams(commands ?? [], false);
          this._isReady.next(isReady);
          this.updateFilters();
        } else {
          this._isReady.next(isReady);
        }
      });
  }

  private getFilters(): Observable<VehicleFilterCollectionView> {
    return this.transportService.getVehicleFilters(this.params.getFilterParams());
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
      .getOrganisationsByCustomerId(this.getUserCustomerId(), false)
      .pipe(map(FilterUtils.convertToOrganisationMap));
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }

  private convertToFilterTypes(vehicleFilters: VehicleFilterCollectionView): FilterType[] {
    return (Object.keys(vehicleFilters))
      .map((key: VehicleFilterType) => this.createConverter(key, vehicleFilters[key] as VehicleFilterItemView[]))
      .map(filterConverter => filterConverter && filterConverter.getFilters())
      .filter(Boolean);
  }

  private createConverter(type: VehicleFilterType, vehicleFilters: VehicleFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case VehicleFilterType.ORGANISATION:
        return new OrganisationFilterViewConverter(vehicleFilters as OrganisationFilterView[], this.params, this.organisationMap,
            FilterTypesDisplayNames.ORGANISATION_VEHICLE);
      case VehicleFilterType.TRANSPORT_TYPE:
        return new CommonFilterViewConverter(vehicleFilters as CommonFilterView[], this.params, FilterTypes.TRANSPORT_TYPE);
      case VehicleFilterType.LABEL:
        return new CommonFilterViewConverter(vehicleFilters as CommonFilterView[], this.params, FilterTypes.LABEL);
      case VehicleFilterType.TYPE:
        return new EquipmentTypeFilterViewConverter(
          vehicleFilters as EquipmentTypeFilterView[],
          this.params,
          this.translatableStringPipe);
      case VehicleFilterType.EQUIPMENT_STATUS:
        return new EquipmentStatusViewConverter(
          vehicleFilters as EquipmentStateFilterView[],
          this.params,
          this.equipmentStatusCategoryPipe,
          this.equipmentStatusNamePipe,
          FilterTypesDisplayNames.EQUIPMENT_STATUS_VEHICLE
        );
      case VehicleFilterType.MATCH_ALL_LABELS:
        return new CommonFilterViewConverter(vehicleFilters as CommonFilterView[], this.params, FilterTypes.MATCH_ALL_LABELS);
    }
  }

}
