import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EquipmentsService } from '../../../equipment/shared/equipments.service';
import { FleetCompositionWidgetStore } from './fleet-composition-widget.store';
import { ChartWidget } from '../basic-widget/chart-widget';
import { ChartData, ChartOptions } from 'chart.js';
import { CustomThemeService } from '../../../../shared/services/custom-theme.service';
import { ThemeSettings } from '../../../../../assets/styles/themes/theme.settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartUtils } from 'app/shared/pipes/chart/chart-utils.class';
import { KeycloakService } from 'app/core/keycloak';
import { IOptionsList, IOptionPlaceholder } from 'app/shared/contract/selection-tree-options.interface';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import {
  applySelectionToOptionList,
  convertGroupedEquipmentTypesToOptionList,
  defaultPlaceholder,
  getOptionPlaceholder,
  getSelectedFromOptionList,
  viewOrganisationToOptionsList
} from 'app/shared/services/selection-tree.converter';
import { GroupedEquipmentType } from 'app/shared/contract/grouped-equipment-type.interface';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

interface EquipmentsCount {
  name: string;
  value: number;
};

@Component({
  selector: 'bh-fleet-composition-widget',
  templateUrl: './fleet-composition-widget.component.html',
  styleUrls: ['./fleet-composition-widget-component.scss'],
  providers: [FleetCompositionWidgetStore]
})
@UntilDestroy()
export class FleetCompositionWidgetComponent extends ChartWidget implements OnInit {
  public chartColors: string[] = [];
  public chartOptions: ChartOptions<'doughnut'> = {
    cutout: '75%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20,
          generateLabels: ChartUtils.generateLabels
        },
      },
      datalabels: <any>false,
    },
  };
  public chartData: ChartData = {
    labels: [],
    datasets: [{ data: [] }],
  };
  public equipmentsCount: EquipmentsCount[] = [];

  public typesList: IOptionsList[] = [];
  public typesPlaceholder: IOptionPlaceholder = defaultPlaceholder();
  private availableTypesList: GroupedEquipmentType[] = [];

  public organisationsList: IOptionsList[] = [];
  public organisationsPlaceholder: IOptionPlaceholder = defaultPlaceholder();
  private availableOrganisationsList: ViewOrganisation[] = [];

  constructor(public customThemeService: CustomThemeService,
              private authService: KeycloakService,
              private equipmentsService: EquipmentsService,
              private fleetCompositionWidgetStore: FleetCompositionWidgetStore,
              private cdr: ChangeDetectorRef,
              private translatableStringResolver: TranslatableStringPipe) {
    super();
    this.generateChartOptions(customThemeService.getThemeSettings());
  }

  private generateChartOptions(theme: ThemeSettings) {
    this.chartColors = theme.fleetCompositionWidgetColors;
  }

  public ngOnInit(): void {
    this.availableTypesSubscription();
    this.fleetCompositionWidgetStore.loadAvailableEquipmentTypeCategories();
    this.getOrganisationsTreeByCustomer();
    this.fetchChartData();
  }

  public preventDefaultEvent(event: Event): void {
    event.preventDefault();
  }

  public organisationsListClosed(): void {
    this.organisationsPlaceholder = getOptionPlaceholder(this.organisationsList);
  }

  public typesListClosed(): void {
    this.typesPlaceholder = getOptionPlaceholder(this.typesList);
  }

  public openedConfigurations(event: boolean): void {
    if (event) {
      this.setTypesList();
      this.setOrganisationsList();
      this.cdr.detectChanges();
    } else {
      this.typesList = [];
      this.organisationsList = [];
    }
  }

  public onConfigurationSave(index: number): void {
    if (this.index === index) {
      this.widget.organisationIds = getSelectedFromOptionList(this.organisationsList);
      this.widget.equipmentTypeCategories = getSelectedFromOptionList(this.typesList);
      this.fetchChartData();
    }
  }

  private availableTypesSubscription(): void {
    this.fleetCompositionWidgetStore.availableEquipmentTypeCategories
      .pipe(untilDestroyed(this))
      .subscribe(types => {
        if (types === null) {
          return;
        }
        this.availableTypesList = types;
        this.setTypesList();
      });
  }

  private setTypesList(): void {
    this.typesList = applySelectionToOptionList(
      convertGroupedEquipmentTypesToOptionList(this.availableTypesList),
      this.widget.equipmentTypeCategories
    );
    this.typesPlaceholder = getOptionPlaceholder(this.typesList);
  }

  private getOrganisationsTreeByCustomer(): void {
    this.equipmentsService.getOrganisationsTreeByCustomer(this.authService.getUserCustomerId())
      .subscribe(orgs => {
        this.availableOrganisationsList = orgs;
        this.setOrganisationsList();
      })
  }

  private setOrganisationsList(): void {
    this.organisationsList = applySelectionToOptionList(
      viewOrganisationToOptionsList(this.availableOrganisationsList),
      this.widget.organisationIds
    );
    this.organisationsPlaceholder = getOptionPlaceholder(this.organisationsList);
  }

  private updateChartData(): void {
    this.chartData = {
      labels: this.equipmentsCount.map(count => count.name),
      datasets: [{
        data: this.equipmentsCount.map(count => count.value),
        backgroundColor: this.chartColors,
        hoverBackgroundColor: this.chartColors,
        hoverBorderColor: this.chartColors,
      }],
    }
  }

  private fetchChartData(): void {
    let {organisationIds, equipmentTypeCategories} = this.widget;

    let filterEquipmentTypeCategoriesPredicate = equipmentsTypeCount => {
      if (!equipmentTypeCategories || equipmentTypeCategories.length < 1) {
        return true;
      }

      return equipmentTypeCategories.includes(equipmentsTypeCount.equipmentTypeCategory1);
    }

    this.equipmentsService.countsEquipmentsByTypeCategory1(organisationIds)
      .subscribe(res => {
        this.equipmentsCount = res
        .filter(equipmentsTypeCount => equipmentsTypeCount.equipmentTypeCategory1)
        .filter(filterEquipmentTypeCategoriesPredicate)
        .map(equipmentsTypeCount => {
          return {
            name: this.translatableStringResolver.transform(equipmentsTypeCount.equipmentTypeCategory1Name),
            value: equipmentsTypeCount.count
          }
        })
        .sort(this.sortEquipmentCount);
        this.updateChartData();
      }
    )
  }

  private sortEquipmentCount(first: EquipmentsCount, second: EquipmentsCount): number {
    return first.value !== second.value
      ? (second.value || 0) - (first.value || 0)
      : (first.name || '').localeCompare(second.name || '');
  }

}
