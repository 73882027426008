import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import Pica from 'pica/dist/pica';
import { KeycloakService } from '../../../../core/keycloak';
import { EquipmentsDataSource } from '../../../../modules/equipment/shared/equipments.datasource';
import { EquipmentsService } from '../../../../modules/equipment/shared/equipments.service';
import { UploadProjectDocumentCommand } from '../../../../modules/disposition/contract/upload-project-document-command';
import { ProjectDataSource } from '../../../../modules/disposition/shared/project.datasource';
import { ProjectsService } from '../../../../modules/disposition/shared/project.service';
import { AttachedDocument } from '../../../contract/attached-document.interface';
import { MaintenanceService } from '../../../../modules/equipment/shared/maintenance.service';
import { FileUtils } from '../../../fileUtils';
import { AttachedImage } from '../../../contract/attached-image.interface';
import { BulkItemsService } from '../../../../modules/disposition/shared/bulk-items.service';
import { BulkItemDatasource } from '../../../../modules/disposition/shared/bulk-item.datasource';
import { StockService } from '../../../../modules/disposition/shared/services/stock.service';
import { EmployeeManagementService } from '../../../../modules/disposition/shared/employee-management.service';
import { EmployeeManagementDatasource } from '../../../../modules/disposition/shared/employee-management.datasource';
import { QualificationService } from 'app/modules/disposition/shared/qualification.service';
import { EmployeeQualificationDocumentUpdateCommand } from 'app/modules/disposition/contract/employee-qualification-document-update-command';
import { TransportService } from '../../../../modules/transportation/shared/services/transport.service';
import { TransportListDatasource } from 'app/modules/transportation/shared/services/transport-list.datasource';
import { UploadTransportDocumentCommand } from 'app/modules/transportation/contracts/transport/upload-transport-document.command';
import { MultiTenancy } from '../../../../core/multi-tenancy';

declare const EXIF: any;

const ITEM_ID_KEY = 'itemId';
const QUALIFICATION_ID_KEY = 'qualificationId';

export type FileUploaderTypes =
    | 'DAMAGE'
    | 'EQUIPMENT'
    | 'EQUIPMENT_ADD_EDIT'
    | 'EQUIPMENT_INVOICE'
    | 'MOBILE_EQUIPMENT_ADD'
    | 'PROJECTS'
    | 'MAINTENANCE_TASK'
    | 'BULK_ITEM_ADD'
    | 'BULK_ITEM'
    | 'STOCKS'
    | 'EMPLOYEE'
    | 'QUALIFICATION'
    | 'TRANSPORT_TASK_ADD_EDIT'
    | 'TRANSPORT_TASK';


export interface EnrichedAttachedDocument extends AttachedDocument {
  entityId: string;
  fileItem: FileItem;
  url?: string;
}

export interface DeferredUploadEntry {
  deferredEntryNumber: number;
  file: AttachedDocument | AttachedImage;
}

@Directive()
export abstract class BaseFileUploadComponent implements OnInit {

  private readonly fileUploadUrl = environment.APP_FILE_SERVICE_BASE_URL + '/api/v1/file/upload';

  @Input() public itemId: string;
  @Input() public qualificationId: string;
  @Input() public uploaderType: FileUploaderTypes;
  @Input() public deferredEntryNumber: number;
  @Output() public onFileUploaded: EventEmitter<EnrichedAttachedDocument> = new EventEmitter<EnrichedAttachedDocument>();
  @Output() public onAllUploaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onPendingUploadStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onDeferredUploadQueued: EventEmitter<DeferredUploadEntry> = new EventEmitter<DeferredUploadEntry>();
  public fileUploader: FileUploader;
  public fakeFileUploader: FileUploader;
  public fileOverDropZone = false;
  public uploading = false;
  public fileProcessing = 0;
  private pica: Pica;
  private readonly reservedSize = 16 * 1024;       // 12Kb
  private readonly maxFileSize = (100 * 1024 * 1024) - this.reservedSize; // ~100MB

  protected constructor(
      protected qualificationService: QualificationService,
      protected equipmentService: EquipmentsService,
      protected bulkItemService: BulkItemsService,
      protected employeeManagementService: EmployeeManagementService,
      protected equipmentsStore: EquipmentsDataSource,
      protected bulkItemStore: BulkItemDatasource,
      protected employeeManagementStore: EmployeeManagementDatasource,
      protected projectsService: ProjectsService,
      protected projectDataSource: ProjectDataSource,
      protected maintenanceService: MaintenanceService,
      protected stockService: StockService,
      protected transportService: TransportService,
      protected transportDataSource: TransportListDatasource,
      protected keycloak: KeycloakService,
      protected snackBar: MatSnackBar,
      protected languageService: LanguageService) {
    this.pica = new Pica();
  }

  ngOnInit() {
    this.fileUploader = this.setupUploader();
    // Only necessary because the ng2FileDrop directive does only prevent the dropped file
    // from overwriting the current website when an uploader is provided. Also used to
    // iterate over dropped or selected items and then pushing them to the real uploader
    this.fakeFileUploader = this.setupUploader();
  }

  onFileDropOrSelected(selectedFiles: File[]): void {
    const itemId = this.itemId;
    const qualificationId = this.qualificationId;

    this.fileProcessing += selectedFiles.length;
    this.onPendingUploadStatusChanged.emit(true);

    for (let i = 0; i < selectedFiles.length; i++) {
      let currentFile = selectedFiles[i];
      // Save item id into file object
      this.setItemIdentifier(currentFile, itemId);
      this.setQualificationIdentifier(currentFile, qualificationId);
      // Checking if the added file is not a picture, because the added file can be a document,
      // but might not be supported. Basically everything that is not a picture is a document.
      // But the uploader has another check to not allow unwanted file-types.
      if (!FileUtils.isPicture(currentFile)) {
        this.fileUploader.addToQueue([currentFile]);
        this.fileProcessed();
        continue;
      }

      this.getImageElementAfterImageLoaded(currentFile).then((image: HTMLImageElement) => {
        this.getRotatedImage(image, currentFile).then((rotatedFile: File | undefined) => {
          let finalFile = (rotatedFile || currentFile);
          // Save item id into file object
          this.setItemIdentifier(currentFile, itemId);
          this.setQualificationIdentifier(currentFile, qualificationId);

          this.fileUploader.addToQueue([finalFile]);
          this.fileProcessed();
        });
      });
    }
  }

  onFileOverDropZone(event: boolean): void {
    this.fileOverDropZone = event;
  }

  private getImageElementAfterImageLoaded(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.onerror = reject;
      image.src = URL.createObjectURL(file);
    });
  }

  private onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): void {
    switch (filter.name) {
      case 'fileSize':
        this.snackBar.open(this.translate('shared.fileUpload.maxPermittedSize'), undefined, {
          duration: 4000,
        });
        break;
      default:
        this.snackBar.open(this.translate('shared.fileUpload.unknownError', { value: filter.name }), undefined, {duration: 4000});
    }
  }

  private getRotatedImage(image: HTMLImageElement, currentFile: File): Promise<File | void> {
    return new Promise((resolve) => {
      if (this.skipRotation(image)) {
        resolve();
      }

      let canvas: HTMLCanvasElement = this.drawCanvas(image);
      this.pica.toBlob(canvas, currentFile.type).then((blob: Blob) => {
        const myFile: File = new File([blob], currentFile.name, {
          type: currentFile.type,
        });
        return resolve(myFile);
      });
    });
  }

  private drawCanvas(image: HTMLImageElement): HTMLCanvasElement {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    // draw image
    ctx.drawImage(image, 0, 0);
    return canvas;
  }

  private skipRotation(image: HTMLImageElement) {
    // It is necessary to call the `getData` function before we can access the tags of the image
    let orientation: number;
    EXIF.getData(<any>image, () => {
      orientation = EXIF.getTag(image, 'Orientation');
    });
    return !orientation || orientation === 1;
  }

  private fileProcessed(): void {
    this.fileProcessing--;
    if (this.fileProcessing === 0) {
      this.uploadFiles();
    }
  }

  private uploadFiles(): void {
    this.fileUploader.authToken = this.keycloak.getTokenForHeader('Bearer');
    let hasImage: FileItem = this.fileUploader.queue[0];
    if (hasImage) {

      if (this.itemId) {
        if (this.uploaderType === 'QUALIFICATION') {
          this.fileUploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('qualificationId', this.getQualificationId(fileItem));
            form.append('employeeId', this.getItemId(fileItem));
          };
        } else {
          this.fileUploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('id', this.getItemId(fileItem));
          };
        }
      }

      this.uploading = true;
      this.onPendingUploadStatusChanged.emit(true);
      this.fileUploader.uploadAll();
    }
  }

  private setupUploader(): FileUploader {
    // General fileUploader setup
    let errorHappened = false;
    let options: FileUploaderOptions = {
      isHTML5: true,
      maxFileSize: this.maxFileSize,
      removeAfterUpload: false,
      authToken: this.keycloak.getTokenForHeader('Bearer'),
      headers: [{name: 'X-Realm', value: KeycloakService.getRealm()}],
      url: null,
    };
    let uploader: FileUploader = new FileUploader(options);

    // CORS does not allow credentials with wildcard origins
    uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);

    uploader.onErrorItem = (fileItem: FileItem, response: string, status) => {
      errorHappened = true;
      this.snackBar.open(this.translate('shared.fileUpload.errorDuringUploading', { value: fileItem.file.name }),
        this.translate('general.buttons.okay'));
      console.error(`file-upload-error: Statuscode: ${status} -> Error message: ${response}`);
      this.uploading = false;
      this.onPendingUploadStatusChanged.emit(false);
    };

    uploader.onCompleteAll = () => {
      setTimeout(() => {
        if (!errorHappened) {
          this.snackBar.open(this.translate('shared.fileUpload.successfulUpload'),
           this.translate('general.buttons.okay'), {duration: 3000});
        }

        if (this.uploaderType === 'EQUIPMENT') {
          this.equipmentsStore.updateListingAndCostCenters(); // reload thumbnails for search results
          this.equipmentsStore.updateCurrentEquipment();
        } else if (this.uploaderType === 'BULK_ITEM') {
          this.bulkItemStore.updateCurrentBulkItem();
          this.bulkItemStore.updateListing();
        } else if (this.uploaderType === 'EMPLOYEE') {
          this.employeeManagementStore.updateCurrentEmployee();
          this.employeeManagementStore.updateListing();
        } else if (this.uploaderType === 'QUALIFICATION') {
          this.employeeManagementStore.updateCurrentQualification();
        } else if (this.uploaderType === 'TRANSPORT_TASK') {
          this.transportDataSource.getDocuments();
        }
        this.onAllUploaded.emit();
        this.fileUploader.clearQueue();
        this.uploading = false;
        this.onPendingUploadStatusChanged.emit(false);
      }, environment.DELAY_LONG);
    };

    // Type specific fileUpload setup.
    switch (this.uploaderType) {
      case 'DAMAGE':
      case 'EQUIPMENT_INVOICE':
      case 'EQUIPMENT_ADD_EDIT':
        return this.setupUploaderForEquipmentDocumentsOnly(uploader);
      case 'EQUIPMENT':
        return this.setupUploaderForEquipment(uploader);
      case 'PROJECTS':
        return this.setupUploaderForProjects(uploader);
      case 'MAINTENANCE_TASK':
        return this.setupUploaderForMaintenanceTasks(uploader);
      case 'MOBILE_EQUIPMENT_ADD':
        return this.setupUploaderForMobileEquipmentAdd(uploader);
      case 'BULK_ITEM_ADD':
        return this.setupUploaderForBulkItemAdd(uploader);
      case 'BULK_ITEM':
        return this.setupUploaderForBulkItem(uploader);
      case 'STOCKS':
        return this.setupUploaderForStocks(uploader);
      case 'EMPLOYEE':
        return this.setupUploaderForEmployee(uploader);
      case 'QUALIFICATION':
        return this.setupUploadForQualification(uploader);
      case 'TRANSPORT_TASK_ADD_EDIT':
        return this.setupUploaderForTransportTaskAdd(uploader);
      case 'TRANSPORT_TASK':
        return this.setupUploaderForTransportTask(uploader);
      default:
        console.error('ERROR: UNKNOWN uploaderType');
    }
  }

  private setupUploaderForEquipmentDocumentsOnly(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };
    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      this.onFileUploaded.emit({
        entityId: this.getItemId(fileItem),
        fileItem: fileItem,
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      });
    };
    return uploader;
  }

  private setupUploaderForTransportTaskAdd(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };
    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      this.onFileUploaded.emit({
        entityId: this.getItemId(fileItem),
        fileItem: fileItem,
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      });
    };
    return uploader;
  }

  private setupUploaderForTransportTask(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };

    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      const itemId = this.getItemId(fileItem);

      const document = {
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      };

      this.transportService.addDocument(new UploadTransportDocumentCommand(itemId, document)).subscribe();
    };
  return uploader;
  }

  private setupUploaderForProjects(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };

    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      const projectId = this.getItemId(fileItem);

      let cmd = new UploadProjectDocumentCommand();
      cmd.projectId = projectId;
      cmd.document = {
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      };

      this.projectDataSource.addDocument(cmd).subscribe(() => {
            console.log(fileItem.file.name, 'Document should have been added.');
          }
      );
    };

    return uploader;
  }

  private setupUploaderForEquipment(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };

    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      const equipmentId = this.getItemId(fileItem);

      const document: AttachedDocument = {
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      };

      this.equipmentService.addDocument(document, equipmentId).subscribe(
        () => {
          console.log(fileItem.file.name, 'Document should have been added.');
        }
      );
    };
    return uploader;
  }

  private setupUploaderForMaintenanceTasks(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };
    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      this.onFileUploaded.emit({
        entityId: this.getItemId(fileItem),
        fileItem: fileItem,
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      });
    };
    return uploader;
  }

  private setupUploaderForMobileEquipmentAdd(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };
    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      if (FileUtils.isDocument(fileItem.file.type)) {
        this.onDeferredUploadQueued.emit({
          deferredEntryNumber: this.deferredEntryNumber,
          file: AttachedDocument.fromData({
            fileDescription: '',
            documentKey: response,
            fileName: fileItem.file.name,
            fileType: fileItem.file.type,
          }),
        });
      } else {
        this.onDeferredUploadQueued.emit({
          deferredEntryNumber: this.deferredEntryNumber,
          file: AttachedImage.fromData({
            imageKey: response,
            fileName: fileItem.file.name,
            mimeType: fileItem.file.type,
          }),
        });
      }

      this.onFileUploaded.emit({
        entityId: this.getItemId(fileItem),
        fileItem: fileItem,
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      });
    };

    return uploader;
  }

  private setupUploaderForBulkItemAdd(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };
    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      this.onFileUploaded.emit({
        entityId: this.getItemId(fileItem),
        fileItem: fileItem,
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      });
    };
    return uploader;
  }

  private setupUploaderForBulkItem(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
        fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };

    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      const bulkItemId = this.getItemId(fileItem);

      const document = {
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      };

      this.bulkItemService.addDocument(document, bulkItemId).subscribe(
          () => {
            console.log(fileItem.file.name, 'Document should have been added.');
          }
      );
    };
    return uploader;
  }

  private setupUploaderForStocks(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };
    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      this.onFileUploaded.emit({
        entityId: this.getItemId(fileItem),
        fileItem: fileItem,
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      });
    };
    return uploader;
  }

  private setupUploaderForEmployee(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };

    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      const employeeId = this.getItemId(fileItem);

      const document = {
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      };

      this.employeeManagementService.addDocument(document, employeeId).subscribe(
        () => {
          console.log(fileItem.file.name, 'Document should have been added.');
        }
      );
    };
    return uploader;
  }

  private setupUploadForQualification(uploader: FileUploader): FileUploader {
    uploader.onAfterAddingFile = fileItem => {
      fileItem.url = MultiTenancy.prepareUrl(this.fileUploadUrl);
    };

    uploader.onSuccessItem = (fileItem: FileItem, response: string) => {
      const employeeId = this.getItemId(fileItem);
      const qualificationId = this.getQualificationId(fileItem);

      const document = {
        fileDescription: '',
        fileName: fileItem.file.name,
        fileType: fileItem.file.type,
        documentKey: response
      };

      let cmd = new EmployeeQualificationDocumentUpdateCommand(qualificationId, employeeId, document);
      this.employeeManagementStore.addDocumentToQualification(cmd).subscribe(() => {
        console.log(fileItem.file.name, 'Document should have been added.');
      })
    }

    return uploader;
  }

  private translate(key: string, interpolateParam?: Object): string {
    return this.languageService.getInstant(key, interpolateParam);
  }

  private setItemIdentifier(file: File, itemId: string) {
    file[ITEM_ID_KEY] = itemId;
  }

  private setQualificationIdentifier(file: File, qualificationId: string) {
    if (this.uploaderType === 'QUALIFICATION') {
      file[QUALIFICATION_ID_KEY] = qualificationId;
    }
  }

  private getItemId(fileItem: FileItem): string {
    if (fileItem.file[ITEM_ID_KEY]) {
      return fileItem.file[ITEM_ID_KEY];
    } else if (fileItem.file.rawFile[ITEM_ID_KEY]) {
      return fileItem.file.rawFile[ITEM_ID_KEY];
    } else {
      return undefined;
    }
  }

  private getQualificationId(fileItem: FileItem): string {
    if (fileItem.file[QUALIFICATION_ID_KEY]) {
      return fileItem.file[QUALIFICATION_ID_KEY];
    } else if (fileItem.file.rawFile[QUALIFICATION_ID_KEY]) {
      return fileItem.file.rawFile[QUALIFICATION_ID_KEY];
    } else {
      return undefined;
    }
  }
}
