import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomersService } from '../customers.service';
import { SetCustomerShopIdCommand } from '../../contract/set-customer-shopId-command';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { LanguageService } from '../../../../shared/services/language.service';
import { RemoveCustomerShopIdCommand } from '../../contract/remove-customer-shopId-command';

@Component({
  selector: 'bh-shop-id-edit-dialog',
  templateUrl: './shop-id-edit-dialog.component.html',
  styleUrls: ['./shop-id-edit-dialog.component.scss'],
})
export class ShopIdEditDialogComponent implements OnInit {
  public customerId: string;
  public customerName: string;
  public shopIdControl: UntypedFormControl = new UntypedFormControl(null);

  constructor(public dialogRef: MatDialogRef<ShopIdEditDialogComponent>,
              private dialog: MatDialog,
              private customerService: CustomersService,
              private languageService: LanguageService) {
  }

  ngOnInit() {
    this.customerService.getCustomerShopId(this.customerId).subscribe( shopId => {
      this.shopIdControl.setValue(shopId)},
      (error: HttpErrorResponse) => {
        console.log('edit customer error: ', error);
      }
    );
  }

  public save(): void {
    if (this.shopIdControl.value !== '') {
      this.setShopId();
    } else {
      this.deleteShopId();
    }
    this.dialogRef.close();
  }

  private deleteShopId(): void {
    let cmd = new RemoveCustomerShopIdCommand();
    cmd.customerId = this.customerId;
    this.customerService.deleteShopId(cmd).subscribe(
      (result) => {
      },
      (error: HttpErrorResponse) => {
        console.log('edit customer error: ', error);
      },
    );
  }

  private setShopId(): void {
    let cmd = new SetCustomerShopIdCommand();
    cmd.customerId = this.customerId;
    cmd.shopId = this.shopIdControl.value;
    this.customerService.updateShopId(cmd).subscribe(
      (result) => {
        console.log(result)
      },
      (error: HttpErrorResponse) => {
        console.log('edit customer error: ', error);
      }
    );
    this.dialogRef.close();
  }

  public confirmChange(): void {
    if (this.shopIdControl.dirty) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.componentInstance.confirmTitle = this.translate('modules.organisation.customerShopEdit.confirmEdit');
      if (this.shopIdControl.value === '') {
        dialogRef.componentInstance.confirmMessage =
         this.translate('modules.organisation.customerShopEdit.confirmMessageDelete', {value: this.customerName});
      } else {
        dialogRef.componentInstance.confirmMessage = this.translate('modules.organisation.customerShopEdit.confirmMessageAssign', {
          customer: this.customerName,
          shopId: this.shopIdControl.value,
        });
      }

      dialogRef.afterClosed().subscribe(result => {
        if (result === dialogResults.YES) {
          this.save();
        } else {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private translate(key: string, interpolateParam?: Object): string {
    return this.languageService.getInstant(key, interpolateParam);
  }

}

