import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UntypedFormControl } from '@angular/forms';
import { Sort, SortDirection } from '@angular/material/sort';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SortOrderOption } from 'app/shared/contract/sort-order-option.interface';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';

@UntilDestroy()
@Component({
  selector: 'bh-sort-order',
  templateUrl: './sort-order.component.html',
  styleUrls: ['./sort-order.component.scss']
})
export class SortOrderComponent implements OnInit {
  @Input() sortOrderOptions: SortOrderOption[] = [];
  @Input() set sort(value: Sort) {
    this.setSortValues(value);
  };
  @Input() showClearSuffix = false;
  @Output() sortChanges = new EventEmitter<Sort>();

  public currentSortOrderOption: SortOrderOption;
  public sortDirection: SortDirection = 'asc';

  public readonly ordersControl = new UntypedFormControl();
  public readonly faIconSortAsc = faArrowUp;

  constructor() { }

  public ngOnInit(): void {
    this.setDefaultOption();
    this.orderChangesListener();
  }

  public onClear(event: Event): void {
    event.stopPropagation();
    this.ordersControl.setValue(null);
  }

  public directionChange(): void {
    this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    this.currentSortOrderOptionEmit();
  }

  private setDefaultOption(): void {
    this.setSortValues({ active: this.ordersControl.value, direction: this.sortDirection });
  }

  private setSortValues(sort: Sort = { active: null, direction: null }): void {
    this.currentSortOrderOption = sort.active ? this.getSortOrderOptionByValue(sort.active) : null;
    this.ordersControl.setValue(sort.active, { emitEvent: false });
    this.sortDirection = sort.direction || this.sortDirection;
  }

  private getSortOrderOptionByValue(optionValue: string): SortOrderOption {
    return this.sortOrderOptions.find(({value}) => value === optionValue);
  }

  private orderChangesListener(): void {
    this.ordersControl.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe((optionValue: string) => {
      this.currentSortOrderOption = this.getSortOrderOptionByValue(optionValue);
      this.currentSortOrderOptionEmit();
    });
  }

  private currentSortOrderOptionEmit(): void {
    this.sortChanges.next(this.toSort(this.currentSortOrderOption));
  }

  private toSort(option: SortOrderOption): Sort {
    if (!(option && option.value)) {
      return null;
    }

    return {
      active: option.value,
      direction: option.disableSort ? null : this.sortDirection
    };
  }
}
