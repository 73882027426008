import { LanguageService } from '../../services/language.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BulkItemTypeResolver } from '../../../modules/disposition/bulk-items/bulk-item-add-edit/bulk-item-type.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { StockTypeResolver } from '../../pipes/stock-type.resolver';
import { RoleAuthorityGuardsComponent } from '../../navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { ChangeAmountDialogComponent } from '../../../modules/disposition/shared/change-amount-dialog/change-amount-dialog.component';
import { BulkItemsService } from '../../../modules/disposition/shared/bulk-items.service';
import { ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';

@Component({
  selector: 'bh-bulk-item-stocks',
  templateUrl: './bulk-items-stocks.component.html',
  styleUrls: ['./bulk-item-stocks.component.scss']
})
export class BulkItemStocksComponent extends RoleAuthorityGuardsComponent implements OnInit {

  @Input() amounts: ViewBulkItemAmount[];
  @Input() type: string;
  @Input() stockReadonly = false;

  @Output() updatedAmount = new EventEmitter<void>();

  displayedColumns: string[] = [
    'icon',
    'name',
    'amount',
    'actions'];
  noAmountsMessage: string;

  constructor(public bulkItemOptionResolver: BulkItemTypeResolver,
              public stockTypeResolver: StockTypeResolver,
              protected authService: KeycloakService,
              private dialog: MatDialog,
              private bulkItemsService: BulkItemsService,
              private languageService: LanguageService) {
    super(authService);
  }

  public ngOnInit(): void {
    this.noAmountsMessage = this.evaluateNoAmountsMessage();
  }

  public getIcon(amount: ViewBulkItemAmount): IconDefinition {
    if (this.type === 'bulk-item') {
      return this.stockTypeResolver.resolveIcon(amount.stockType);
    } else if (this.type === 'stock') {
      return this.bulkItemOptionResolver.resolveIcon(amount.bulkItemType);
    }
  }

  public getName(amount: ViewBulkItemAmount): string {
    if (this.type === 'bulk-item') {
      return amount.stockName;
    } else if (this.type === 'stock') {
      return amount.bulkItemName;
    }
  }

  public openAmountDialog(amount: ViewBulkItemAmount) {
    this.bulkItemsService.getBulkItem(amount.bulkItemId).subscribe(bulkItem => {
      let dialogRef = this.dialog.open(ChangeAmountDialogComponent);
      dialogRef.componentInstance.bulkItem = bulkItem;
      dialogRef.componentInstance.stockReadonly = this.stockReadonly;
      dialogRef.afterOpened().subscribe(() => {
        dialogRef.componentInstance.amountForm.patchValue({stockId: amount.stockId});
      });
      dialogRef.afterClosed()
        .subscribe(result => {
          if (result === 'update') {
            this.updatedAmount.emit();
          }
      });
    });
  }

  private evaluateNoAmountsMessage(): string {
    switch (this.type) {
      case 'bulk-item': return this.translate('general.labels.notAvailable.stocks');
      case 'stock': return this.translate('general.labels.notAvailable.inventories');
      default: return '';
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
