export class UpdateEmployeeDocumentDescriptionCommand {

  employeeId: string;
  documentKey: string;
  description: string;

  constructor(employeeId: string, documentKey: string, description: string) {
    this.employeeId = employeeId;
    this.documentKey = documentKey;
    this.description = description;
  }
}
