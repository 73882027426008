<div class="mobile-wizard-wrapper" fxFlexFill fxLayout="column">
  <div class="mobile-wizard-header-wrapper">
    <div class="mobile-wizard-header" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div class="mobile-wizard-title">{{title}}</div>
      <button mat-icon-button (click)="openSettings()" *ngIf="settingsEnabled; else cancelButton">
        <mat-icon>settings</mat-icon>
      </button>
      <ng-template #cancelButton>
        <button mat-icon-button (click)="cancel()">
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-template>
    </div>
  </div>

  <bh-mobile-wizard-settings [entryName]="getWizardOptionsKey()"></bh-mobile-wizard-settings>
  <div class="mobile-wizard-content">
    <ng-content></ng-content>
  </div>

  <div class="mobile-wizard-footer" fxFlex="60px" fxLayout="row">
    <div fxFlex fxLayoutAlign="end center" fxLayoutGap="10px" fxLayout="row" *ngIf="!settingsOpen">

      <button mat-button
              (click)="cancel()"
              *ngIf="currentStep === 0 && settingsEnabled">
        {{'general.buttons.cancel'|translate}}
      </button>
      <button mat-button
              (click)="previousTab()"
              *ngIf="currentStep > 0"
              [disabled]="!backEnabled">
        {{'general.buttons.back'|translate}}
      </button>
      <button mat-stroked-button
              (click)="nextTab()"
              *ngIf="currentStep < totalSteps"
              color="primary"
              [disabled]="!nextEnabled">
        {{'general.buttons.continue'|translate}}
      </button>
      <button mat-raised-button
              (click)="finishWizard()"
              color="primary"
              *ngIf="currentStep === totalSteps"
              [disabled]="!nextEnabled">
        {{ confirmLabel }}
      </button>
    </div>
    <div *ngIf="settingsOpen" fxFlex fxLayoutAlign="center center">
      <button mat-raised-button (click)="openSettings()">{{'general.buttons.close'|translate}}</button>
    </div>
  </div>
</div>
