import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { BaseProjectMapComponent } from '../base/base-project-map.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { ProjectDataSource } from '../../../shared/project.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationsService } from '../../../../organisation/shared/organisations.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomDeviceDetectorService } from '../../../../../shared/services/custom-device-detector.service';
import { RouterHistory } from '../../../../../shared/router-history';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';

@Component({
  selector: 'bh-mobile-project-map',
  templateUrl: './mobile-project-map.component.html',
  styleUrls: ['./mobile-project-map.component.scss']
})
export class MobileProjectMapComponent extends BaseProjectMapComponent implements OnInit {

  constructor(public projectStore: ProjectDataSource,
              protected router: Router,
              protected authService: KeycloakService,
              protected route: ActivatedRoute,
              protected deviceService: CustomDeviceDetectorService,
              protected organisationService: OrganisationsService,
              protected dialog: MatDialog,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              equipmentStore: EquipmentsDataSource,
              contactDataSource: ContactDataSource) {
    super(
      authService,
      router,
      route,
      projectStore,
      organisationService,
      routerHistory,
      languageService,
      equipmentStore,
      contactDataSource
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.handleRouter();
  }

  public navigateToList(): void {
    this.router.navigate(['mobile/projects/list']);
  };

  private handleRouter(): void {
    if (!this.deviceService.isMobile()) {
      this.router.navigate(['projects/map']);
    }
  }
}
