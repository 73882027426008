import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MapInfoWindowTableViewOrganisationComponent } from '../map/components/map-info-window-cluster-table-view/map-info-window-table-view-organisation/map-info-window-table-view-organisation.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    MapInfoWindowTableViewOrganisationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer }
  ]
})
export class DialogTopLevelModule { }
