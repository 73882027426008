import {LanguageService} from 'app/shared/services/language.service';
import {Component, Input} from '@angular/core';
import * as moment from 'moment';
import {DueDateInfo} from '../../../contract/due-date-info.interface';
import {RemainingValueType} from '../../../enums/remaining-value-type.enum';
import {Languages} from 'app/shared/enums/languages.enum';
import {DueDateRemainingValuePipe} from '../../../pipes/due-date-remaining-value.pipe';
import { DimensionUnitConverterPipe } from '../../../pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-task-remaining',
  templateUrl: './task-remaining.component.html',
  styleUrls: ['./task-remaining.component.scss']
})
export class TaskRemainingComponent {

  @Input() dueDateInfo: DueDateInfo;
  public readonly languages = {
    DE: Languages.DE,
    EN: Languages.EN
  }

  constructor(private languageService: LanguageService,
              private dueDateRemainingValuePipe: DueDateRemainingValuePipe,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe
              ) {
  }

  public get isToday(): boolean {
    return moment().isSame(this.dueDateInfo.dueDate, 'day')
      && this.dueDateInfo.remainingValueType === RemainingValueType.DAYS;
  }

  public get unknownRemaining(): boolean {
    let dueDateRemainingValue = this.dueDateRemainingValuePipe.transform(this.dueDateInfo);
    return !dueDateRemainingValue;
  }

  public getRemainingValue(): number {
    if (this.dueDateInfo.remainingValueType === RemainingValueType.DAYS) {
      const dueDate = moment(this.dueDateInfo.dueDate).startOf('day');
      const currDate = moment().startOf('day');
      return Math.abs(currDate.diff(dueDate, 'days'));
    } else if (this.dueDateInfo.remainingValueType === RemainingValueType.MILEAGE) {
      return Math.abs(this.dimensionUnitConverterPipe.toUserDimensionUnit(this.dueDateInfo.remainingValue, 'km', 0))
    } else {
      return Math.abs(this.dueDateInfo.remainingValue);
    }
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }
}
