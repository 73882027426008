<div class="settings-wrapper" [class.visible]="show ? true : null">
  <div class="settings">
    <div class="settings-header">
      <h3>{{ settingsTitle || ('general.settings'|translate) }}</h3>
    </div>
    <div class="setting" *ngFor="let key of configKeys">
      <div class="setting-name">
        {{ getTabOptions(key).shortName }}
      </div>
      <div class="toggle-slider-wrapper">
        <mat-slide-toggle color="primary"
                          [(ngModel)]="getTabOptions(key).show"
                          (change)="checkBoxChanged()"
                          [disabled]="!getTabOptions(key).optional">
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="closing-btn">
    <button mat-raised-button color="primary" (click)="show = false">{{'general.buttons.done'|translate}}</button>
  </div>

</div>
