import { map } from 'rxjs/operators';
import { ViewOrganisation } from '../../../organisation/contract/view-organisation.interface';
import { OrganisationsService } from '../../../organisation/shared/organisations.service';
import { ContactService } from 'app/modules/contact/shared/services/contacts.service';
import { Observable, zip } from 'rxjs';
import { FilterValue } from '../../../../shared/contract/filter/filter-value';
import { FilterTypes, FilterTypesDisplayNames } from '../../../../shared/contract/filter/filter-types.enum';
import { ContactStatus } from 'app/modules/contact/shared/enums/contact-status.enum';
import { EventEmitter, Injectable } from '@angular/core';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { FilterType, RenderStrategy } from 'app/shared/contract/filter/filter-type';
import { FilterGroup } from 'app/shared/contract/filter/filter-group';
import * as _ from 'lodash';
import { KeycloakService } from 'app/core/keycloak';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { ContactStatusPipe } from '../pipes/contact-status.pipe';
import { ContactFilterParams } from '../contract/contact-filter-params.class';
import {
  ContactFilterLabelsView,
  ContactFilterOrganisationView,
  ContactFilterStatusView,
  ContactFilterTypeView,
  ContactFilterView,
} from '../contract/contact-filter-view.interface';
import { ContactFilterRequestParams } from '../contract/contact-request-params/contact-filter-request-params.interface';
import { ContactFilterType } from '../enums/contact-filter-type.enum';
import { FilterUtils } from 'app/shared/contract/filter/filter-utils.class';

type ContactFilterItemView = (ContactFilterTypeView | ContactFilterStatusView | ContactFilterOrganisationView | ContactFilterLabelsView);

// DON'T USE this class as an example for filtering implementations!!!
// Use the logic from the transportation module (VehicleFiltersService)
@Injectable()
export class ContactFiltersService {
  public readonly filters: FilterType[] = [];
  public readonly onFiltersUpdated = new EventEmitter();
  private params = new ContactFilterParams();
  private organisationMap = new Map<string, ViewOrganisation>();
  private isFiltersInit = false;

  constructor(
    private contactService: ContactService,
    private organisationsService: OrganisationsService,
    private authService: KeycloakService,
    private contactTypeResolver: ContactTypeResolverPipe,
    private contactStatusResolver: ContactStatusPipe
  ) { }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.checkFilterInit();
    this.params.updateParams(commands);
    this.updateFilters();
  }

  public getContactSearchParams(): ContactFilterRequestParams {
    this.checkFilterInit(true);
    return this.params.getContactSearchParams();
  }

  public hasAnyFilter(): boolean {
    return this.params.hasAnyParam();
  }

  public updateFilters(): void {
    this.checkFilterInit();
    zip(
      this.getFilters(),
      this.getOrganisationMap())
    .subscribe(([filters, organisationMap]) => {
      this.organisationMap = organisationMap;
      FilterUtils.pushMissingParentOrganisationToFilters(filters.organisation, this.organisationMap);
      const convertedFilters = this.convertToFilterTypes(filters);
      this.mergeToResultFilters(convertedFilters);
      this.onFiltersUpdated.emit();
    });
  }

  private checkFilterInit(updateFilters = false): void {
    if (!this.isFiltersInit) {
      this.isFiltersInit = true;
      this.setDefaultFilters();
      if (updateFilters) {
        this.updateFilters();
      }
    }
  }

  private setDefaultFilters(): void {
    this.params.updateParams([{
      filterType: FilterTypes.CONTACT_STATE,
      storeName: ContactStatus.ACTIVE,
      newValue: true
    }]);
  }

  private getFilters(): Observable<ContactFilterView> {
    return this.contactService.getFilters(this.params.getContactSearchParams());
  }

  private mergeToResultFilters(convertedFilters: FilterType[]): void {
    convertedFilters.forEach((filterType: FilterType) => this.pushToResultFilters(filterType));
  }

  private pushToResultFilters(filterType: FilterType): void {
    const indexOfFilterType = this.filters.findIndex(({ typeName }) => filterType.typeName === typeName);
    if (indexOfFilterType >= 0) {
      this.filters[indexOfFilterType] = filterType;
    } else {
      this.filters.push(filterType);
    }
  }

  private convertToFilterTypes(contactFilters: ContactFilterView): FilterType[] {
    return Object.keys(contactFilters)
      .map((key: ContactFilterType) => this.convertToFilterType(this.resolveFilterType(key), contactFilters[key]))
      .filter(Boolean);
  }

  private convertToFilterType(filterType: FilterTypes, filterItems: ContactFilterItemView[]): FilterType {
    if (filterType) {
      return {
        typeName: filterType,
        displayNameType: filterType === FilterTypes.ORGANISATION ? FilterTypesDisplayNames.ORGANISATION_CONTACT : null,
        renderStrategy: filterType === FilterTypes.ORGANISATION ? RenderStrategy.RELATIONS_TREE : RenderStrategy.LIST,
        display: true,
        typeTotal: undefined,
        groups: [this.convertToFilterGroup(filterType, filterItems)]
      };
    }
  }

  private convertToFilterGroup(filterType: FilterTypes, filterItems: ContactFilterItemView[]): FilterGroup {
    return {
      groupName: '',
      display: true,
      groupTotal: undefined,
      filters: filterItems.map(item => this.convertToFilterValue(filterType, item)).filter(Boolean)
    };
  }

  private convertToFilterValue(filterType: FilterTypes, filterItem: ContactFilterItemView): FilterValue {
    switch (filterType) {
      case FilterTypes.CLASS:
        return this.convertToFilterValueType(filterType, filterItem as ContactFilterTypeView);
      case FilterTypes.CONTACT_STATE:
        return this.convertToFilterValueStatus(filterType, filterItem as ContactFilterStatusView);
      case FilterTypes.ORGANISATION:
        return this.convertToFilterValueOrganisation(filterType, filterItem as ContactFilterOrganisationView);
      case FilterTypes.LABEL:
        return this.convertToFilterValueLabel(filterType, filterItem as ContactFilterLabelsView);
      default:
        return null;
    }
  }

  private convertToFilterValueType(
    filterType: FilterTypes,
    filterItem: ContactFilterTypeView
  ): FilterValue {
    return {
      displayName: this.contactTypeResolver.resolveName(filterItem.type),
      storeName: filterItem.type,
      display: true,
      value: this.resolveFilterValueSelection(filterType, filterItem.type),
      filterTotal: this.formatCount(filterItem.count)
    }
  }

  private convertToFilterValueStatus(
    filterType: FilterTypes,
    filterItem: ContactFilterStatusView
  ): FilterValue {
    return {
      displayName: this.contactStatusResolver.transform(filterItem.status),
      storeName: filterItem.status,
      display: true,
      value: this.resolveFilterValueSelection(filterType, filterItem.status),
      filterTotal: this.formatCount(filterItem.count)
    }
  }

  private convertToFilterValueOrganisation(
    filterType: FilterTypes,
    filterItem: ContactFilterOrganisationView
  ): FilterValue {
    return {
      displayName: filterItem.organisationName,
      storeName: filterItem.organisationId,
      display: true,
      value: this.resolveFilterValueSelection(filterType, filterItem.organisationId),
      filterTotal: this.formatCount(filterItem.count),
      parentStoreName: this.organisationMap.get(filterItem.organisationId)?.parentOrganisationId
    }
  }

  private convertToFilterValueLabel(
    filterType: FilterTypes,
    filterItem: ContactFilterLabelsView
  ): FilterValue {
    return {
      displayName: filterItem.name,
      storeName: filterItem.name,
      display: true,
      value: this.resolveFilterValueSelection(filterType, filterItem.name),
      filterTotal: this.formatCount(filterItem.count)
    }
  }

  private resolveFilterType(filterCategoryName: ContactFilterType): FilterTypes {
    switch (filterCategoryName) {
      case ContactFilterType.CLASS:
        return FilterTypes.CLASS;
      case ContactFilterType.STATUS:
        return FilterTypes.CONTACT_STATE;
      case ContactFilterType.ORGANISATION:
        return FilterTypes.ORGANISATION;
      case ContactFilterType.LABEL:
        return FilterTypes.LABEL;
    }
  }

  private resolveFilterValueSelection(filterCategoryName: FilterTypes, value: any): boolean {
    return this.params.hasParam(filterCategoryName, value);
  }

  private formatCount(count: number): string {
    return !_.isNil(count) ? `(${count})` : undefined;
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
      .getOrganisationsByCustomerId(this.getUserCustomerId(), false)
      .pipe(map(FilterUtils.convertToOrganisationMap));
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }
}
