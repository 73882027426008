import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';
import { ITypeOfUseChangeRequest } from './type-of-use-change-request.interface';
import { ProjectTypeOfUseRequestCommand } from '../../../disposition/contract/project-type-of-use-request-command';

@Injectable({
  providedIn: 'root'
})
export class TypeOfUseChangeRequestsWidgetApiService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1';
  private projectUrl = this.url + '/projects';

  constructor(private http: HttpClient) {
  }

  public getTypeOfUseChangeRequests(page: number, pageSize: number): Observable<PagedResponse<ITypeOfUseChangeRequest>> {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('size', pageSize.toString());
    return this.http.get<PagedResponse<ITypeOfUseChangeRequest>>(`${this.projectUrl}/type-of-use/requests`, {params});
  }

  public acceptTypeOfUseChangeRequest(command: ProjectTypeOfUseRequestCommand): Observable<string>  {
    return this.http.post(`${this.projectUrl}/type-of-use/accept`, JSON.stringify(command), {responseType: 'text'});
  }

  public declineTypeOfUseChangeRequest(command: ProjectTypeOfUseRequestCommand): Observable<string>  {
    return this.http.post(`${this.projectUrl}/type-of-use/decline`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteTypeOfUseChangeRequest(command: ProjectTypeOfUseRequestCommand): Observable<string>  {
    return this.http.post(`${this.projectUrl}/type-of-use/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public hasTypeOfUseWorkflow(): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/has-type-of-use-workflow');
  }

}
