import { Pipe, PipeTransform } from '@angular/core';
import { SingleFilterOption } from '../contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'filterSingleOptionDisplayName'
})
export class FilterSingleOptionDisplayNamePipe implements PipeTransform {

  private translationMap = {
    [SingleFilterOption.WITHOUT_SUBEQUIPMENTS]:
      this.languageService.getInstant('modules.equipment.equipmentList.withoutSubequipments'),
    [SingleFilterOption.VEHICLE_ONLY]:
      this.languageService.getInstant('modules.equipment.equipmentList.transportVehiclesOnly'),
    [SingleFilterOption.FAVOURITE_ONLY]:
      this.languageService.getInstant('modules.equipment.equipmentList.favouriteEquipmentsOnly'),
    [SingleFilterOption.ONLY_LATEST_COMPLETED_FOR_RULE]:
      this.languageService.getInstant('modules.maintenance.base.onlyLatestCompletedForRule'),
  };

  constructor(private languageService: LanguageService) {}

  public transform(value: SingleFilterOption): string {
    return this.translationMap[value] ?? value;
  }
}
