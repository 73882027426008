import { WidgetDefinition } from '../widget-definition';
import { MapWidgetComponent } from './map-widget.component';
import { WidgetConfigParams } from '../contract/widget-config-param';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { WidgetType } from '../contract/widget-type.enum';

export class MapWidgetConfiguration extends WidgetDefinition {
  mapType = 'roadmap';
  mapConfig: MapConfiguration;

  constructor({ title }: WidgetConfigParams) {
    super();
    this.widgetType = WidgetType.MAP;
    this.type = MapWidgetComponent;
    this.title = title;
    this.minItemCols = 12;
    this.minItemRows = 8;
    this.cols = 12;
    this.rows = 8;
    this.x = 0;
    this.y = 0;
  }
}
