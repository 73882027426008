import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TransportService } from './../services/transport.service';
import { TransportItem } from './../../../equipment/shared/transport-items.interface';
import { AbstractControl, ValidatorFn, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';

export class TransportationValidators {
  public static timeWithDate(dateControl: AbstractControl): ValidatorFn {
    return (timeControl: AbstractControl): ValidationErrors => {
      const isValid = !timeControl.value || (!dateControl.errors && moment(dateControl.value).isValid());
      return isValid ? null : { 'time-without-valid-date': true };
    }
  }

  public static isTransportItemOrEmpty(control: AbstractControl): ValidationErrors {
    return _.isNil(control.value) || control.value === '' || TransportationValidators.isTransportItemTypeGuard(control.value)
      ? null
      : { 'not-transport-item': true };
  }

  private static isTransportItemTypeGuard(value: TransportItem | any): value is TransportItem {
    return typeof value === 'object'
      && (value as TransportItem).id !== undefined
      && (value as TransportItem).transportItemType !== undefined
  }

  public static hasTransportTypeAsync(transportService: TransportService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }

      return of(control.value).pipe(
        delay(500),
        switchMap((type: string) => transportService.hasTransportType(type)),
        map(result => result ? null : { 'transport-type-not-exist': true })
      );
    }
  }
}
