import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FiltersBaseService } from 'app/shared/contract/filter/filters-base-service.class';
import { ProjectFilterParams } from '../project-request-params/project-filter-params.class';
import { ProjectFilterRequestParams } from '../project-request-params/project-filter-request-params.interface';
import { ProjectFilterStatusView, ProjectFilterCollectionView } from '../filter-view/project-filter-collection-view.interface';
import { ProjectsService } from '../project.service';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { ProjectFilterType } from '../enums/project-filter-type.enum';
import { CommonFilterView } from 'app/shared/contract/filter/filter-view/common-filter-view.interface';
import { CommonFilterViewConverter } from 'app/shared/contract/filter/filter-converter/common-filter-view-converter.class';
import { BaseFilterViewConverter } from 'app/shared/contract/filter/filter-converter/base-filter-view-converter.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { ProjectStatusFilterViewConverter } from '../filter-converter/project-status-filter-view-converter.class';
import { ProjectStatusPipe } from 'app/shared/pipes/project-status.pipe';
import { ProjectStatus } from '../enums/project-status.enum';
import { ProjectFilterView } from 'app/shared/contract/filter/filter-view/project-filter-view.interface';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';


type ProjectFilterItemView = (CommonFilterView | ProjectFilterStatusView | ProjectFilterView);

@Injectable()
export class ProjectFiltersService extends FiltersBaseService<ProjectFilterRequestParams> {
  private isFiltersInit = false;
  constructor(
    private projectsService: ProjectsService,
    private projectStatusPipe: ProjectStatusPipe,
  ) {
    super(new ProjectFilterParams());
  }

  public getFilterParams(): ProjectFilterRequestParams {
    this.checkFilterInit(true);
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[]): void {
    this.checkFilterInit();
    super.updateFilterParams(commands);
  }

  public updateFilters(): void {
    this.checkFilterInit();
    this.getFilters()
      .subscribe((filters) => {
        const convertedFilters = this.convertToFilterTypes(filters);
        this.mergeToResultFilters(convertedFilters);
        this.onFiltersUpdated.emit();
      });
  }

  private checkFilterInit(updateFilters = false): void {
    if (!this.isFiltersInit) {
      this.isFiltersInit = true;
      this.setDefaultFilters();
      if (updateFilters) {
        this.updateFilters();
      }
    }
  }

  private setDefaultFilters(): void {
    this.params.updateParams([{
      filterType: FilterTypes.PROJECT_STATE,
      storeName: ProjectStatus.ACTIVE,
      newValue: true
    }]);
  }

  public getProjectStatusFilter(): string[] {
    return this.getFilterParams().status;
  }

  public getProjectLabelFilter(): string[] {
    return this.getFilterParams().label;
  }

  private getFilters(): Observable<ProjectFilterCollectionView> {
    return this.projectsService.getProjectFilters(this.params.getFilterParams());
  }

  private convertToFilterTypes(projectFilters: ProjectFilterCollectionView): FilterType[] {
    return Object.keys(projectFilters)
      .map((key: ProjectFilterType) => this.createConverter(key, projectFilters[key] as ProjectFilterItemView[]))
      .map(filterConverter => filterConverter && filterConverter.getFilters())
      .filter(Boolean);
  }

  private createConverter(type: ProjectFilterType, projectFilters: ProjectFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case ProjectFilterType.STATUS:
        return new ProjectStatusFilterViewConverter(
          projectFilters as ProjectFilterStatusView[],
          this.params,
          this.projectStatusPipe
        );
      case ProjectFilterType.LABEL:
        return new CommonFilterViewConverter(projectFilters as CommonFilterView[], this.params, FilterTypes.LABEL);
    }
  }
}
