import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { MaintenanceTask, MaintenanceTaskSearch, MaintenanceTaskView } from '../maintenance-task';
import { PagedResponse } from '../../../../../shared/contract/page-response.interface';
import { DeleteReminderCommand } from '../../../../equipment/contract/delete-reminder-command';
import {
  UpdateMaintenanceTaskAfterCompletionCommand,
} from '../contract/commands/update-maintenance-task-after-completion.command';
import { CompleteMaintenanceTaskCommand } from '../contract/commands/complete-maintenance-task.command';
import { UploadMaintenanceTaskFileCommand } from '../contract/commands/upload-maintenance-task-file.command';
import { DeleteMaintenanceTaskFileCommand } from '../contract/commands/delete-maintenance-task-file.command';
import { CompleteReminderCommand } from '../../../../equipment/contract/complete-reminder-command';
import { TelematicsSnapshot } from '../telematics-snapshot.interface';
import { DocumentLink } from '../../../../../shared/contract/document-link.interface';
import { getHttpParameters } from '../../../../../shared/utils';
import { MaintenanceFilterCollectionView } from '../../../shared/contract/maintenance-filter-collection-view.interface';
import { MaintenanceFilterRequestParams } from '../filter/maintenance-filter-request-params.interface';
import {
  MaintenanceCompletedRequestParams,
} from '../contract/request-params/maintenance-completed-request-params.interface';
import { MaintenanceTaskCompletedSearch } from '../maintenance-task-completed-search.interface';
import {
  MaintenanceCompletedFilterRequestParams,
} from '../filter/maintenance-completed-filter-request-params.interface';
import {
  MaintenanceCompletedFilterCollectionView,
} from '../../../shared/contract/maintenance-completed-filter-collection-view.interface';
import {
  MaintenanceExportRequestParams,
} from '../../../../../shared/contract/request-params/maintenance-export-request-params.interface';
import {
  DeleteReminderAfterCompletionCommand,
} from 'app/modules/equipment/contract/delete-reminder-after-completion.command';
import {
  DeleteMaintenanceTaskAfterCompletionCommand,
} from 'app/modules/equipment/contract/delete-maintenance-task-after-completion.command';
import {
  MaintenanceTaskCompletedAuditResponse,
} from '../../../shared/contract/maintenance-task-completed-audit-response.interface';
import { SetDueDateCommand } from '../contract/commands/set-due-date.command';
import { MaintenanceRequestParams } from '../contract/request-params/maintenance-request-params.interface';
import {
  MaintenanceCompletedExportRequestParams
} from '../contract/request-params/maintenance-completed-export-request-params.interface';
import {UpdateMaintenanceTaskDocumentNameCommand} from '../update-maintenance-task-document-name.command';
import {UpdateMaintenanceTaskDocumentDescriptionCommand} from '../update-maintenance-task-document-description.command';

@Injectable()
export class MaintenanceTaskService {
  private url = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/tasks';
  private maintenanceUrlV2 = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v2/maintenance/tasks';
  private reminderUrl = environment.APP_MAINTENANCE_SERVICE_BASE_URL + '/api/v1/maintenance/reminders';

  constructor(private http: HttpClient) {
  }

  getById(taskId: string): Observable<MaintenanceTask> {
    return this.http.get<MaintenanceTask>(`${this.url}/${taskId}`);
  }

  getMaintenanceView(taskId: string): Observable<MaintenanceTaskView> {
    return this.http.get<any>(`${this.url}/unfinished/${taskId}`);
  }

  completeTask(command: CompleteMaintenanceTaskCommand): Observable<string> {
    return this.http.post(`${this.url}/complete`, command, {responseType: 'text'});
  }

  public deleteReminderAfterCompletion(command: DeleteReminderAfterCompletionCommand): Observable<string> {
    return this.http.post(`${this.url}/reminders/delete-after-completion`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteMaintenanceTaskAfterCompletion(command: DeleteMaintenanceTaskAfterCompletionCommand): Observable<string> {
    return this.http.post(`${this.url}/tasks/delete-after-completion`, JSON.stringify(command), {responseType: 'text'});
  }

  updateTaskAfterCompletion(command: UpdateMaintenanceTaskAfterCompletionCommand): Observable<string> {
    return this.http.post(`${this.url}/update-after-completion`, command, {responseType: 'text'});
  }

  uploadFile(command: UploadMaintenanceTaskFileCommand): Observable<string> {
    return this.http.post(`${this.url}/add-file`, command, {responseType: 'text'});
  }

  getTasks(filter: MaintenanceRequestParams): Observable<PagedResponse<MaintenanceTaskSearch>> {
    const params = getHttpParameters({
      size: filter.size,
      page: filter.page,
      sort: filter.sort,
      term: filter.term,
      searchColumns: filter.searchColumns,
    });

    const request = this.buildMaintenanceTaskRequest(filter);
    return this.http.post<PagedResponse<MaintenanceTaskSearch>>(`${this.maintenanceUrlV2}/search`, request, {params});
  }

  completeReminder(command: CompleteReminderCommand): Observable<string> {
    return this.http.post(`${this.reminderUrl}/complete`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteReminder(command: DeleteReminderCommand): Observable<string> {
    return this.http.post(`${this.reminderUrl}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  deleteDocument(command: DeleteMaintenanceTaskFileCommand): Observable<string> {
    return this.http.post(`${this.url}/delete-file`, command, {responseType: 'text'});
  }

  public updateDocumentName(command: UpdateMaintenanceTaskDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentDescription(command: UpdateMaintenanceTaskDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.url}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  getFilesForTask(id: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(`${this.url}/${id}/files`);
  }

  getTelematicsAtDate(equipmentId: string, dateToCheck: string): Observable<TelematicsSnapshot> {
    let params = new HttpParams()
    .set('equipmentId', equipmentId)
    .set('dateToCheck', dateToCheck);

    return this.http.get<TelematicsSnapshot>(`${this.url}/telematics-at-date`, {params: params});
  }

  getMaintenanceTaskFilter(filter: MaintenanceFilterRequestParams): Observable<MaintenanceFilterCollectionView> {
    const request = this.buildMaintenanceTaskRequest(filter);
    return this.http.post<MaintenanceFilterCollectionView>(`${this.url}/filter`, request);
  }

  public exportMaintenanceList(filter: MaintenanceExportRequestParams): Observable<Blob> {
    const params = getHttpParameters({
      sort: filter.sort,
      term: filter.term,
      searchColumns: filter.searchColumns,
      columns: filter.columns,
      zoneId: filter.zoneId
    });

    const request = this.buildMaintenanceTaskRequest(filter);
    return this.http.post(`${this.maintenanceUrlV2}/export-maintenance-list`, request, {
      params,
      responseType: 'blob',
    });
  }

  public exportMaintenanceCompletedList(filter: MaintenanceCompletedExportRequestParams): Observable<Blob> {
    const params = getHttpParameters({
      sort: filter.sort,
      term: filter.term,
      searchColumns: filter.searchColumns,
      columns: filter.columns,
      zoneId: filter.zoneId
    });

    const request = this.buildCompletedMaintenanceTaskRequest(filter);
    return this.http.post(`${this.maintenanceUrlV2}/completed/export`, request, {
      params,
      responseType: 'blob',
    });
  }

  public getCompletedTasks(filter: MaintenanceCompletedRequestParams): Observable<PagedResponse<MaintenanceTaskCompletedSearch>> {
    const params = getHttpParameters({
      size: filter.size,
      page: filter.page,
      sort: filter.sort,
      term: filter.term,
      searchColumns: filter.searchColumns,
    });

    const request = this.buildCompletedMaintenanceTaskRequest(filter);
    return this.http.post<PagedResponse<MaintenanceTaskCompletedSearch>>(`${this.maintenanceUrlV2}/completed`, request, {params});
  }

  public getCompletedTasksFilter(filter: MaintenanceCompletedFilterRequestParams): Observable<MaintenanceCompletedFilterCollectionView> {
    const request = this.buildCompletedMaintenanceTaskRequest(filter);
    return this.http.post<MaintenanceCompletedFilterCollectionView>(`${this.url}/completed/filter`, request);
  }

  public getMaintenanceCompletedAuditData(taskId: string): Observable<MaintenanceTaskCompletedAuditResponse> {
    return this.http.get<MaintenanceTaskCompletedAuditResponse>(`${this.url}/${taskId}/history`);
  }

  public setDueDate(command: SetDueDateCommand): Observable<string> {
    return this.http.post(`${this.url}/set-due-date`, JSON.stringify(command), {responseType: 'text'});
  }

  private buildMaintenanceTaskRequest(filter: MaintenanceFilterRequestParams) {
    return {
      articleGroups: filter.articleGroups,
      maintenanceTypes: filter.maintenanceTypes,
      responsiblePersons: filter.responsiblePersons,
      warningLevels: filter.warningLevels,
      labels: filter.labels,
      stocks: filter.stocks,
      projects: filter.projects,
      organisations: filter.organisations,
      equipmentTypes: filter.equipmentTypes,
      maintenanceCategories: filter.maintenanceCategories,
      equipmentStatuses: filter.equipmentStatuses,
      maintenanceResults: filter.maintenanceResults,
      onlyLatestCompletedForRule: filter.onlyLatestCompletedForRule,
      matchAllLabels: filter.matchAllLabels,
    };
  }

  private buildCompletedMaintenanceTaskRequest(filter: MaintenanceCompletedFilterRequestParams) {
    return {
      articleGroups: filter.articleGroups,
      maintenanceTypes: filter.maintenanceTypes,
      responsiblePersons: filter.responsiblePersons,
      warningLevels: filter.warningLevels,
      labels: filter.labels,
      organisations: filter.organisations,
      equipmentTypes: filter.equipmentTypes,
      maintenanceCategories: filter.maintenanceCategories,
      equipmentStatuses: filter.equipmentStatuses,
      maintenanceResults: filter.maintenanceResults,
      onlyLatestCompletedForRule: filter.onlyLatestCompletedForRule,
      matchAllLabels: filter.matchAllLabels,
    };
  }
}
