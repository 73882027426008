import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateParser, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TranslateConfiguration } from '../contract/language.interfaces';
import { Languages } from '../enums/languages.enum';
import { UserConfigurationService } from './user-configuration.service';

@Injectable()
export class LanguageService {
  private translateConfiguration: TranslateConfiguration;
  public onLanguageChange: Observable<LangChangeEvent>;

  constructor(private translate: TranslateService,
              private translateParser: TranslateParser,
              private userConfigurationService: UserConfigurationService) {
    this.onLanguageChange = this.translate.onLangChange.asObservable();
    this.addLanguages();
    this.getTranslateConfiguration();
  }

  private addLanguages(): void {
    this.translate.addLangs(Array.from(new Set(Object.keys(Languages).map(key => Languages[key])).values()));
  }

  private isLanguageExists(language: string): boolean {
    return this.getLanguages().includes(language);
  }

  public getUserLanguage(): string {
    return this.userConfigurationService.getUserLanguage();
  }

  private setUserLanguage(language: string): Observable<any> {
    return this.userConfigurationService.saveUserLanguage(language);
  }

  public use(language: Languages|string): Observable<any> {
    const usingLanguage = this.isLanguageExists(language) ? language : Languages.DEFAULT;
    return usingLanguage === this.getUserLanguage()
      ? this.translate.use(usingLanguage)
      : this.setUserLanguage(usingLanguage).pipe(switchMap(() => this.translate.use(usingLanguage)));
  }

  public getInstant(key: string, interpolateParams?: Object): string {
    return this.translate.instant(key, interpolateParams);
  }

  public getInstantWithoutFallBack(key: string, interpolateParams?: Object): string {
    const fallBackLang = this.translate.getDefaultLang();
    this.translate.setDefaultLang(this.translate.currentLang);

    let translation = this.translate.instant(key, interpolateParams);
    translation = key === translation ? '' : translation;
    this.translate.setDefaultLang(fallBackLang);
    return translation;
  }

  public getInstantMap(keys: string[], interpolateParams?: Object): Object {
    return this.translate.instant(keys, interpolateParams);
  }

  public get(key: string, interpolateParams?: Object): Observable<string> {
    return this.translate.get(key, interpolateParams);
  }

  public getMap(keys: string[], interpolateParams?: Object): Observable<Object> {
    return this.translate.get(keys, interpolateParams);
  }

  public getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  public getLanguages(): string[] {
    return this.translate.getLangs();
  }

  public getProperLanguages(): Languages[] {
    return <Languages[]>(this.getLanguages()).filter(lang => lang !== Languages.DEV);
  }

  public interpolate(expr: string | Function, params?: any): string {
    return this.translateParser.interpolate(expr, params);
  }

  public switchAndReload(language: string | Languages): void {
    this.setUserLanguage(language)
      .subscribe(() => window.location.reload());
  }

  public getCurrentLocale(): string {
    return this.translate.currentLang === Languages.DEV ? Languages.EN : this.translate.currentLang;
  }

  private getTranslateConfiguration(): void {
    this.translateConfiguration = this.userConfigurationService.getUserTranslateConfiguration();
  }

  private saveTranslateConfiguration(configuration: TranslateConfiguration): void {
    this.translateConfiguration = { ...configuration };
    this.userConfigurationService.saveUserTranslateConfiguration(this.translateConfiguration);
  }

  public getDevLanguagePrefix(): string {
    return this.translateConfiguration.devPrefix || '';
  }

  public setDevLanguagePrefix(devPrefix: string): void {
    this.saveTranslateConfiguration({ ...this.translateConfiguration, devPrefix });
  }

  public setDefaultLanguage(languages: Languages): void {
    this.translate.setDefaultLang(languages);
  }
}
