<div class="delete-manufacturer">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="deletable; else deleteNotAllowed">
    <h1 mat-dialog-title>{{'modules.equipment.manufacturerDelete.manufacturerDeleteTitle'|translate}}</h1>
    <div mat-dialog-content>
      {{'modules.equipment.manufacturerDelete.manufacturerDeleteMessage'|translate}}
    </div>
    <div mat-dialog-actions>
      <button mat-button color="primary" mat-dialog-close="no">{{'general.buttons.no'|translate|uppercase}}</button>
      <button mat-button color="primary" mat-dialog-close="yes">{{'general.buttons.yes'|translate|uppercase}}</button>
    </div>
  </ng-container>
</div>

<ng-template #deleteNotAllowed>
  <h1 mat-dialog-title>{{'modules.equipment.manufacturerDelete.notPossibleTitle'|translate}}</h1>
  <div mat-dialog-content>
    {{'modules.equipment.manufacturerDelete.notPossibleMessage'|translate}}
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" mat-dialog-close="abort">{{'general.buttons.close'|translate|uppercase}}</button>
  </div>
</ng-template>
