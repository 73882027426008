import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { LastTransferDateResponseMessageType } from '../../../../projects/project-list/project-view/project-view-transfer-history/last-transfer-date-response-message-type';
import { DatePipe } from '@angular/common';
import { LastTransferDateReason } from '../../../../projects/project-list/project-view/project-view-transfer-history/last-transfer-date-reason';


@Pipe({
  name: 'lastTransferDateReason'
})
export class LastTransferDateReasonPipe implements PipeTransform {

  private readonly sourceLocationChargeDate = 'modules.disposition.confirmation.lastHistoricTransferDateReason.sourceLocationChargeDate';
  private readonly targetLocationChargeDate = 'modules.disposition.confirmation.lastHistoricTransferDateReason.targetLocationChargeDate';
  private readonly targetProjectStartDate = 'modules.disposition.confirmation.lastHistoricTransferDateReason.targetProjectStartDate';
  private readonly assetLastTransferDate = 'modules.disposition.confirmation.lastHistoricTransferDateReason.assetLastTransferDate';
  private readonly assetsLastTransferDate = 'modules.disposition.confirmation.lastHistoricTransferDateReason.assetsLastTransferDate';

  private translationKeys = {
    [LastTransferDateResponseMessageType.SOURCE_LOCATION_CHARGE_DATE]: this.sourceLocationChargeDate,
    [LastTransferDateResponseMessageType.TARGET_LOCATION_CHARGE_DATE]: this.targetLocationChargeDate,
    [LastTransferDateResponseMessageType.TARGET_PROJECT_START_DATE]: this.targetProjectStartDate
  }

  constructor(private languageService: LanguageService,
              private datePipe: DatePipe) {
  }

  transform(reason: LastTransferDateReason, lastTransferDate?: Date, locale?: string, args?: any): any {
    if (reason.type === LastTransferDateResponseMessageType.ASSET_LAST_TRANSFER_DATE) {
      if (reason?.transferItemsInfo.length === 1) {
        return this.translate(this.assetLastTransferDate,
          {name: reason.transferItemsInfo[0].transferItemName,
            number: reason.transferItemsInfo[0].transferItemNumber,
            date: this.formatDate(lastTransferDate, locale)})
      } else {
        return this.translate(this.assetsLastTransferDate, {date: this.formatDate(lastTransferDate, locale)}) + ' '
      }
    }
    return this.translate(this.translationKeys[reason.type], {date: this.formatDate(lastTransferDate, locale)});
  }

  private translate(key: string, interpolateParam?: Object): string {
    return this.languageService.getInstant(key, interpolateParam);
  }

  private formatDate(lastTransferDate?: Date, locale?: string): string {
    return this.datePipe.transform(lastTransferDate, 'dd.MM.yyyy HH:mm', locale)
  }

}
