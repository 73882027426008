<div class="line" fxLayout="row" fxLayoutAlign="space-between center">
  <span class="text-overflow">
    <ng-container *ngIf="task?.maintenanceResult">
      <bh-maintenance-result-badge
        [result]="task.maintenanceResult"
        id="maintenance-result-badge">
      </bh-maintenance-result-badge>
    </ng-container>
  </span>
  <span id="completed-date">
    {{task?.completedAtDate | date:'dd.MM.yyyy' }}
  </span>
</div>

<div class="line" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="icon__service-type custom-sizing" [matTooltip]="task?.maintenanceCategory | maintenanceCategory">
    {{task?.maintenanceCategory | maintenanceCategory: 'icon'}}
  </mat-icon>
  <span class="name text-overflow">{{task?.maintenanceName}}</span>
</div>

<div class="line">
  <span class="text-overflow">{{task?.equipmentName || task?.equipmentModel}}</span>
</div>

<div class="line">
 <div class="grayed text-overflow">
  <span>{{task?.equipmentSerialNumber || ('modules.equipment.equipmentList.noSerialNumber'|translate)}}</span>
  /
  <span>{{task?.equipmentCustomerSerialNumber}}</span>
 </div>
</div>
