import { UUID } from 'angular2-uuid';
import { AttachedDocument } from '../../../shared/contract/attached-document.interface';
import {OspDateTime} from './osp-date-time';

export class AddEquipmentIncidentCommand {
  lifecycleId: UUID;
  equipmentId: string;
  logDate: OspDateTime;
  currentOperatingHours: number;
  currentMileage: number;
  subject: string;
  description: string;
  documents: AttachedDocument[];
}
