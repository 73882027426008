import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Status } from '../../../../equipment/contract/status.interface';
import { MaintenanceResult } from '../../../../../shared/enums/maintenance-result';
import { ViewEquipment } from '../../../../equipment/contract/view-equipment.interface';
import { EquipmentStatusCategory } from '../../../../equipment/contract/equipment-status-category.enum';

export type UpdateEquipmentStatusDialogData = {
  maintenanceResult: MaintenanceResult,
  equipmentStatus: Status,
  equipment: ViewEquipment
}

export type UpdateEquipmentStatusDialogResult = {
  result: 'update' | 'abort' | 'keep';
  equipmentStatusId?: string;
}

@Component({
  selector: 'bh--update-equipment-status-dialog',
  templateUrl: './update-equipment-status-dialog.component.html',
  styleUrls: ['./update-equipment-status-dialog.component.scss']
})
export class UpdateEquipmentStatusDialogComponent {

  newEquipmentStatus: Status;

  constructor(public dialogRef: MatDialogRef<UpdateEquipmentStatusDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UpdateEquipmentStatusDialogData) {}

  public onSave(): void {
    const result: UpdateEquipmentStatusDialogResult = this.newEquipmentStatus
      ? {result: 'update', equipmentStatusId: this.newEquipmentStatus.equipmentStatusId}
      : {result: 'keep'};

    this.dialogRef.close(result);
  }

  public onCancel(): void {
    const result: UpdateEquipmentStatusDialogResult = {result: 'abort'};
    this.dialogRef.close(result);
  }

  public onEquipmentStatusChange(status: Status): void {
    this.newEquipmentStatus = status;
  }

  public getSameCategoryLikeMaintenanceResultFilter(): (category: EquipmentStatusCategory) => boolean {
    const {maintenanceResult} = this.data;
    return (category: EquipmentStatusCategory) => {
      if (MaintenanceResult.OK === maintenanceResult) {
        return EquipmentStatusCategory.OPERATIONAL === category;
      } else if (MaintenanceResult.OK_WITH_RESTRICTIONS === maintenanceResult) {
        return EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS === category;
      } else if (MaintenanceResult.NOT_OK === maintenanceResult) {
        return EquipmentStatusCategory.NOT_OPERATIONAL === category;
      } else {
        return false;
      }
    }
  }
}
