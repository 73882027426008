<ng-container *ngIf="!cancelReason; else cancel">
  <mat-icon class="icon-{{ isCurrentlyUpdating() ? 'warn' : 'ok' }}">
    {{ isCurrentlyUpdating() ? 'schedule' : 'check' }}
  </mat-icon>
</ng-container>

<ng-template #cancel>
  <mat-icon class="icon-cancel">warning</mat-icon>
</ng-template>

<div class="text-container">
  {{ profile.translations.get(this.selectedLanguage).displayName }}
</div>
