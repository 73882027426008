import { Injectable } from '@angular/core';
import { LocalUserStorageService } from './local-user-storage.service';
import { AmountsService } from '../../modules/disposition/shared/amounts.service';
import { ViewProjectAmount } from '../../modules/disposition/contract/view-project-amount.interface';
import { OfflineDataStore } from './offline-data.store';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ViewBulkItemAmount } from 'app/modules/disposition/contract/view-bulk-item-amount.interface';

@Injectable()
export class OfflineTransferAmountsService {
  constructor(private localUserStorageService: LocalUserStorageService,
              private amountsService: AmountsService,
              private offlineDataStore: OfflineDataStore) {
  }

  public get offlineDispositionAmounts(): ViewBulkItemAmount[] {
    const amounts: ViewBulkItemAmount[] = JSON.parse(this.localUserStorageService.getUserValue('offlinePlannedStockAmounts'));
    return amounts ? amounts : [];
  }

  public get offlineDispositionProjectAmounts(): ViewProjectAmount[] {
    const amounts: ViewBulkItemAmount[] = JSON.parse(this.localUserStorageService.getUserValue('offlinePlannedProjectAmounts'));
    return amounts ? amounts : [];
  }

  public getPlannedAmount(bulkItemId: string, stockId: string): ViewBulkItemAmount {
    const amount = this.offlineDispositionAmounts.find(item => item.bulkItemId === bulkItemId && item.stockId === stockId);
    return amount;
  }

  public getPlannedProjectAmounts(bulkItemId: string, projectId: string): ViewProjectAmount[] {
    const amounts = this.offlineDispositionProjectAmounts
      .filter(item => item.transferItemId === bulkItemId && item.projectId === projectId);
    return amounts;
  }

  public clearPlannedAmounts(): void {
    this.localUserStorageService.setUserValue('offlinePlannedStockAmounts', null);
    this.localUserStorageService.setUserValue('offlinePlannedProjectAmounts', null);
  }

  public offlineAmountDecrease(bulkItemId: string, stockId: string, amount: number): void {
    const amounts = this.offlineDispositionAmounts;
    const item = amounts.find(bulkItem => bulkItem.bulkItemId === bulkItemId
      && bulkItem.stockId === stockId);
    if (item) {
      item.amountAvailable = item.amountAvailable - amount;
    } else {
      const newItem = {
        bulkItemId: bulkItemId,
        stockId: stockId,
        amountAvailable: -amount
      }
      amounts.push(newItem);
    }
    this.localUserStorageService.setUserValue('offlinePlannedStockAmounts', JSON.stringify(amounts));
  }

  public offlineAmountIncrease(bulkItemId: string, targetStockId: string, amount: number): void {
    const amounts = this.offlineDispositionAmounts;
    const item = amounts.find(bulkItem => bulkItem.bulkItemId === bulkItemId
      && bulkItem.stockId === targetStockId);
    if (item) {
      item.amountAvailable = item.amountAvailable + amount
    } else {
      let bulkItemName: string;
      let stockName: string;

      combineLatest(
        this.offlineDataStore.offlineDispositionBulkItems
          .pipe(
            map(items => items.find(bulkItem => bulkItem.bulkItemId === bulkItemId))
          ),
        this.offlineDataStore.offlineDispositionStocks
          .pipe(
            map(items => items.find(stock => stock.stockId === targetStockId))
          )
      ).subscribe(values => {
        bulkItemName = values[0].bulkItemName;
        stockName = values[1].stockName;

        const newItem = {
          bulkItemId: bulkItemId,
          stockId: targetStockId,
          amountAvailable: amount,
          stockName: stockName,
          bulkItemName: bulkItemName,
          bulkItemType: values[0].bulkItemType,
          bulkItemCategory: values[0].bulkItemCategory
        }
        amounts.push(newItem);
      });
    }
    this.localUserStorageService.setUserValue('offlinePlannedStockAmounts', JSON.stringify(amounts));
  }

  public offlineProjectAmountDecrease(bulkItemId: string, stockId: string, projectId: string, amount: number): void {
    const amounts = this.offlineDispositionProjectAmounts;
    const item = amounts.find(bulkItem => bulkItem.transferItemId === bulkItemId
      && bulkItem.stockId === stockId && bulkItem.projectId === projectId);
    if (item) {
      item.amount = item.amount - amount;
    } else {
      const newItem = {
        bulkItemId: bulkItemId,
        stockId: stockId,
        projectId: projectId,
        amount: -amount
      }
      amounts.push(newItem);
    }
    this.localUserStorageService.setUserValue('offlinePlannedProjectAmounts', JSON.stringify(amounts));
  }

  public offlineProjectAmountIncrease(bulkItemId: string, stockId: string, targetProjectId: string, amount: number): void {
    const amounts = this.offlineDispositionProjectAmounts;
    const item = amounts.find(bulkItem => bulkItem.transferItemId === bulkItemId
      && bulkItem.stockId === stockId && bulkItem.projectId === targetProjectId);
    if (item) {
      item.amount = item.amount + amount
    } else {
      let bulkItemName: string;
      let stockName: string;
      let projectName: string;

      combineLatest(
        this.offlineDataStore.offlineDispositionBulkItems
          .pipe(
            map(items => items.find(bulkItem => bulkItem.bulkItemId === bulkItemId))
          ),
        this.offlineDataStore.offlineDispositionStocks
          .pipe(
            map(items => items.find(stock => stock.stockId === stockId))
          ),
        this.offlineDataStore.offlineDispositionProjects
          .pipe(
            map(items => items.find(project => project.projectId === targetProjectId))
          )
      ).subscribe(values => {
        bulkItemName = values[0].bulkItemName;
        stockName = values[1].stockName;
        projectName = values[2].projectName;

        const newItem = {
          bulkItemId: bulkItemId,
          stockId: stockId,
          projectId: targetProjectId,
          stockName: stockName,
          bulkItemName: bulkItemName,
          projectName: projectName,
          bulkItemType: values[0].bulkItemType,
          amount: amount
        }
        amounts.push(newItem);
      });
    }
    this.localUserStorageService.setUserValue('offlinePlannedProjectAmounts', JSON.stringify(amounts));
  }
}
