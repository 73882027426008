import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { ButtonBase } from '../base/button-base.class';


@Component({
  selector: 'bh-button-icon-fab',
  templateUrl: './button-icon-fab.component.html',
  styleUrls: ['./button-icon-fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconFabComponent extends ButtonBase {
  @HostBinding('class')
  protected cssClass = 'osp-button osp-button-icon-fab';
}
