import { LanguageService } from '../../../../shared/services/language.service';
import { AlarmTypeConfig } from '../interfaces/alarm-type-config.interface';
import { AlarmType } from '../enums/alarm-type.enum';
import { Injectable } from '@angular/core';
import { KeycloakService } from '../../../../core/keycloak';
import { Modules } from '../../../../shared/enums/modules.enum';
import { Authorities } from 'app/shared/enums/authorities.enum';

@Injectable()
export class AlarmTypeService {

  private modules = Modules;

  private readonly alarmTypes: AlarmTypeConfig[] = [
    {
      type: AlarmType.OPERATING_HOURS,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.operatingHours'),
      condition: true
    },
    {
      type: AlarmType.OPERATING_HOURS_CONTRACT,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.contractOperatingHours'),
      condition: this.authService.hasModule(this.modules.COSTS)
    },
    {
      type: AlarmType.TANK_FILL_LEVEL,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.tankLevel'),
      condition: this.authService.hasModule(this.modules.LIVE_DATA)
    },
    {
      type: AlarmType.TOTAL_COSTS,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.totalCost'),
      condition: this.authService.hasModule(this.modules.COSTS)
    },
    {
      type: AlarmType.DAMAGE_COSTS,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.violenceDamageCosts'),
      condition: this.authService.hasModule(this.modules.COSTS)
    },
    {
      type: AlarmType.REPAIR_COSTS,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.repairCosts'),
      condition: this.authService.hasModule(this.modules.COSTS)
    },
    {
      type: AlarmType.GUARANTEE,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.guarantee'),
      condition: this.authService.hasModule(this.modules.SERVICE)
    },
    {
      type: AlarmType.FULL_SERVICE_CONTRACT_END,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.fullServiceContractEnd'),
      condition: this.authService.hasModule(this.modules.COSTS)
    },
    {
      type: AlarmType.LEASING_SERVICE_CONTRACT_END,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.leasingOrRentalContractEnd'),
      condition: this.authService.hasModule(this.modules.COSTS)
    },
    {
      type: AlarmType.GLOBAL_GEOFENCE_VIOLATION,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.globalGeofenceViolation'),
      condition: this.authService.hasModule(this.modules.LIVE_DATA)
    },
    {
      type: AlarmType.TIMEFENCING,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.timefence'),
      condition: this.authService.hasModule(this.modules.LIVE_DATA)
    },
    {
      type: AlarmType.TRANSPORT_TASK_CREATION,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.transportCreation'),
      condition: this.authService.hasModule(this.modules.TRANSPORT_TASK_MANAGEMENT)
        && this.authService.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION)
    },
    {
      type: AlarmType.TRANSPORT_TASK_RETURNED,
      explanation: this.translate(
        'modules.notification.alarmRuleAddEdit.expl.transportStatusChange',
        { value: this.translate('modules.transportation.transportState.returned') }),
      condition: this.authService.hasModule(this.modules.TRANSPORT_TASK_MANAGEMENT)
        && this.authService.hasAuthority(Authorities.TRANSPORT_TASK_DISPOSITION)
    },

    {
      type: AlarmType.NEW_DAMAGE_ADDED,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.newDamageAdded'),
      condition: true
    },

    {
      type: AlarmType.INACTIVE_TELEMATICS_UNIT,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.inactiveTelematicsUnit'),
      condition: true
    },

    {
      type: AlarmType.BATTERY_VOLTAGE,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.batteryVoltage'),
      condition: this.authService.hasModule(this.modules.LIVE_DATA)
    },

    {
      type: AlarmType.DEF_TANK_LEVEL,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.defTankLevel'),
      condition: this.authService.hasModule(this.modules.LIVE_DATA)
    },
    {
      type: AlarmType.TELEMATICS_UNIT_BATTERY_STATUS,
      explanation: this.translate('modules.notification.alarmRuleAddEdit.expl.trackerBatteryStatus'),
      condition: true
    }
  ];

  constructor(private authService: KeycloakService, private languageService: LanguageService) {
  }

  public getAllowedAlarmTypes(): AlarmTypeConfig[] {
    return this.alarmTypes.filter((alarm: AlarmTypeConfig) => alarm.condition);
  }

  public getExplanation(alarmTypeSearch: AlarmType): string {
    const matchingType = this.alarmTypes.find(alarmType => alarmType.type === alarmTypeSearch);
    return matchingType ? matchingType.explanation : this.translate('modules.notification.alarmRuleAddEdit.expl.unknown');
  }

  public isTypeAllowed(alarmType: AlarmType): boolean {
    return !!this.getAllowedAlarmTypes().find(allowedType => allowedType.type === alarmType);
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
