export class PolygonUtils {

  public static hasIntersections(coords: google.maps.LatLng[]): boolean {
    if (!coords || coords.length < 3) {
      return false;
    }

    let n = coords?.length - 1;
    for (let i = 1; i < n; i++) {
      for (let j = 0; j < i - 1; j++) {
        let intersection = this.intersectionBetweenSegments(coords[i], coords[i + 1], coords[j], coords[j + 1]);
        if (!!intersection) {
          return true;
        }
      }
    }
    return false;
  }

  // checks if lines p0->p1 and p2->p3 are intersecting
  private static intersectionBetweenSegments(p0: google.maps.LatLng, p1: google.maps.LatLng, p2: google.maps.LatLng,
                                               p3: google.maps.LatLng): google.maps.LatLng {
    let denominator = (p3.lng() - p2.lng()) * (p1.lat() - p0.lat()) - (p3.lat() - p2.lat()) * (p1.lng() - p0.lng());
    let ua = (p3.lat() - p2.lat()) * (p0.lng() - p2.lng()) - (p3.lng() - p2.lng()) * (p0.lat() - p2.lat());
    let ub = (p1.lat() - p0.lat()) * (p0.lng() - p2.lng()) - (p1.lng() - p0.lng()) * (p0.lat() - p2.lat());

    if (denominator < 0) {
      ua = -ua;
      ub = -ub;
      denominator = -denominator;
    }

    if (ua >= 0.0 && ua <= denominator && ub >= 0.0 && ub <= denominator && denominator !== 0) {
      let intersectLat = p0.lat() + ua / denominator * (p1.lat() - p0.lat());
      let intersectLng = p0.lng() + ua / denominator * (p1.lng() - p0.lng());
      return new google.maps.LatLng(intersectLat, intersectLng);
    }
    return null;
  }

}
