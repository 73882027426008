<div [formGroup]="settingsForm" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
    <mat-form-field fxFlex="100">
      <mat-label>{{ 'modules.notification.alarmRuleAddEdit.labels.trackerBatteryStatus' | translate }}</mat-label>
      <mat-select [formControlName]="controlNames.SETTINGS_TELEMATICS_BATTERY_STATUS" required>
        <mat-option *ngFor="let status of batteryStatuses" [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="limitControl.hasError('required')">
        {{ 'shared.validation.requiredField' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
    <div fxFlex="100">
      <bh-explanation-card class="explanation"
                           text="{{ 'modules.notification.alarmRuleAddEdit.expl.trackerBatteryStatus' | translate }}">
      </bh-explanation-card>
    </div>
  </div>
</div>
