import { LogManualOperatingHoursCommand } from '../../../../contract/log-manual-operating-hours-command';
import { Observable } from 'rxjs';
import { DatesService } from '../../../../../../shared/services/dates.service';
import { BaseEquipmentLogLifecycle } from '../../base/base-equipment-log-lifecycle';
import { LifeCycle } from '../../../../contract/life-cycle';
import { LifeCycleOperatingHours } from '../../../../contract/life-cycle-operating-hours';
import { SearchEquipment } from '../../../../contract/search-equipment.interface';
import { Directive, OnInit } from '@angular/core';

@Directive()
export abstract class BaseEquipmentLogOperatingHours extends BaseEquipmentLogLifecycle implements OnInit {

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public newValueDescription = this.languageService
    .getInstant('modules.equipment.lifecycle.newOperatingHours')
    .toUpperCase();

  public newValuePattern = '[0-9]'

  protected hasLiveData(equipment: SearchEquipment): boolean {
    return equipment.hasLiveOperatingHours;
  }

  protected getLatestLifeCycle(equipmentId: string): Observable<LifeCycle> {
    return this.equipmentsService.getLatestOperatingHours(equipmentId);
  }

  protected getLifeCycleValue(lifeCycle: LifeCycleOperatingHours): number {
    return lifeCycle.currentOperatingHours;
  }

  protected sendCommand(): Observable<string> {
      let cmd = new LogManualOperatingHoursCommand();
      cmd.equipmentId = this.equipment.equipmentId;
      cmd.logDate = DatesService.ospDateTimeAtStartOfDay(this.logDate.value);
      cmd.newOperatingHours = this.newValue.value;
      cmd.comment = this.comment.value || null;
      return this.equipmentsService.logManualOperatingHours(cmd);
  }
}
