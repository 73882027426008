import { LanguageService } from '../../../../shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ConnectorService } from '../../shared/connector.service';
import { ConnectorInformation } from '../../contract/connector-information';
import { ScheduleInformation } from '../../contract/schedule-information';
import { CreateAempV2ConnectorCommand } from '../../contract/aemp-v2/create-aemp-v2-connector-command';
import { AempV2ConnectionInformation } from '../../contract/aemp-v2/aemp-v2-connection-information';
import { CreateMailConnectorCommand } from '../../contract/mail/create-mail-connector-command';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthEncoding } from '../../contract/auth-encoding.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthType } from '../../contract/auth-type.enum';
import { ConnectorType } from '../../contract/connector-types.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { MailConnectionInformation } from '../../contract/mail/mail-connection-information';
import {
  CreateTrackUnitClassicConnectorCommand
} from '../../contract/trackunit-classic/create-track-unit-classic-connector-command';
import {
  TrackUnitClassicConnectionInformation
} from '../../contract/trackunit-classic/track-unit-classic-connection-information';
import { CreateGpsOverIpConnectorCommand } from '../../contract/gpsoverip/create-gps-over-ip-connector-command';
import { GpsOverIpConnectionInformation } from '../../contract/gpsoverip/gps-over-ip-connection-information';
import { CreateRioConnectorCommand } from '../../contract/rio/create-rio-connector-command';
import { RioConnectionInformation } from '../../contract/rio/rio-connection-information';
import { CreateReidlConnectorCommand } from '../../contract/reidl/create-reidl-connector-command';
import { ReidlConnectionInformation } from '../../contract/reidl/reidl-connection-information';
import { Subscription } from 'rxjs';
import { CreateSinos2ConnectorCommand } from '../../contract/sinos2/create-sinos2-connector-command';
import { Sinos2ConnectionInformation } from '../../contract/sinos2/sinos2-connection-information';

@Component({
  selector: 'bh-connector-add',
  templateUrl: 'connector-add.component.html',
  styleUrls: ['connector-add.component.scss']
})
export class ConnectorAddComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public type: ConnectorType;
  public connectorAddForm: UntypedFormGroup;
  public availableEncodings: string[];
  public availableAuthTypes: string[];
  public currentAuthType: AuthType;

  private authTypeSubscription: Subscription | undefined;
  private profileKeySubscription: Subscription | undefined;


  constructor(private connectorService: ConnectorService,
              private formBuilder: UntypedFormBuilder,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected snackBar: MatSnackBar,
              protected routerHistory: RouterHistory,
              private dialogRef: MatDialogRef<ConnectorAddComponent>,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory)
  }

  ngOnInit() {
    this.buildForm();
    this.onChanges();
  }

  buildForm() {

    let group = {
      'name': ['', [<any>Validators.required]],
      'intervalInMinutes': [1, [<any>Validators.required]],
      'maxTimeoutInMinutes': [5, [<any>Validators.required]],
    };

    if (this.isAEMPv2()) {
      this.currentAuthType = AuthType.BASIC_AUTH;
      group['url'] = ['', [<any>Validators.required]];
      group['authType'] = [this.currentAuthType, [<any>Validators.required]];
      group['authEncoding'] = ['', <any>Validators.required];
      group['authenticationUrl'] = [''];
      group['clientId'] = [''];
      group['clientSecret'] = [''];
      group['username'] = ['', <any>Validators.required];
      group['password'] = ['', <any>Validators.required];
      group['profileKey'] = ['LIDAT', [<any>Validators.required]];
      group['apiKey'] = [''];
      this.availableEncodings = Object.keys(AuthEncoding).map(key => AuthEncoding[key]);
      this.availableAuthTypes = Object.keys(AuthType).map(key => AuthType[key]);
    }

    if (this.isMail()) {
      group['server'] = ['', [<any>Validators.required]];
      group['port'] = ['', [<any>Validators.required]];
      group['protocol'] = ['IMAP', [<any>Validators.required]];
      group['encryption'] = ['NONE', [<any>Validators.required]];
      group['user'] = ['', [<any>Validators.required]];
      group['password'] = ['', [<any>Validators.required]];
      group['directory'] = ['', [<any>Validators.required]];
      group['destinationDirectory'] = ['', [<any>Validators.required]];
      group['sender'] = ['', [<any>Validators.required]];
      group['filePattern'] = ['', [<any>Validators.required]];
      group['subjectPattern'] = ['', [<any>Validators.required]];
      group['datePattern'] = ['', [<any>Validators.required]];
      group['profileKey'] = ['LINDE_CONNECT', [<any>Validators.required]];
    }

    if (this.isTrackUnitClassic()) {
      group['url'] = [''];
      group['username'] = [''];
      group['password'] = ['', [<any>Validators.required]];
    }

    if (this.isGpsOverIp()) {
      group['url'] = ['https://www.apioverip.de/', [<any>Validators.required]];
      group['liveUrl'] = ['https://live.apioverip.de/', [<any>Validators.required]];
      group['username'] = ['', [<any>Validators.required]];
      group['password'] = ['', [<any>Validators.required]];
    }

    if (this.isRio()) {
      group['assetApiUrl'] = ['https://api.assets.rio.cloud', [<any>Validators.required]];
      group['iotEventsApiUrl'] = ['https://feed.iot-events.rio.cloud', [<any>Validators.required]];
      group['authenticationUrl'] = ['https://auth.iam.rio.cloud/oauth/token', [<any>Validators.required]];
      group['clientId'] = ['', [<any>Validators.required]];
      group['clientSecret'] = ['', [<any>Validators.required]];
      group['integrationId'] = ['', [<any>Validators.required]];
    }

    if (this.isReidl()) {
      group['telematicUnitsUrl'] = ['', [<any>Validators.required]];
      group['telematicUnitsToken'] = ['', [<any>Validators.required]];
      group['eanCableUnit'] = ['', [<any>Validators.required]];
      group['eanObdUnit'] = ['', [<any>Validators.required]];
      group['eanDigitalMatterUnit'] = ['', [<any>Validators.required]];
    }

    if (this.isSinos2()) {
      group['url'] = ['https://portal2.sinos.eu/mp-tracking/rest/', [<any>Validators.required]];
      group['manNumber'] = ['', [<any>Validators.required]];
      group['manAccount'] = ['', [<any>Validators.required]];
      group['manPassword'] = ['', [<any>Validators.required]];
    }

    this.connectorAddForm = this.formBuilder.group(group);
  }

  public updateForm(authType: AuthType) {
    this.currentAuthType = authType;
    if (this.connectorAddForm) {
      if (authType === AuthType.BASIC_AUTH) {
        this.makeControlsRequired(['authEncoding', 'username', 'password']);
        this.makeControlsOptional(['authenticationUrl', 'clientId', 'clientSecret', 'apiKey']);
      } else if (authType === AuthType.OAUTH1 ) {
        this.makeControlsRequired(['clientId', 'clientSecret']);
        this.makeControlsOptional(['authenticationUrl', 'authEncoding', 'username', 'password', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_CLIENT_CREDENTIALS) {
        this.makeControlsRequired(['authenticationUrl', 'clientId', 'clientSecret']);
        this.makeControlsOptional(['authEncoding', 'username', 'password', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_PASSWORD_CREDENTIALS) {
        this.makeControlsRequired(['authenticationUrl', 'username', 'password']);
        this.makeControlsOptional(['authEncoding', 'clientId', 'clientSecret', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS) {
        this.makeControlsRequired(['authenticationUrl', 'clientId', 'clientSecret', 'username', 'password']);
        this.makeControlsOptional(['authEncoding', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS){
        this.makeControlsRequired(['authenticationUrl', 'clientId', 'clientSecret', 'username', 'password', 'apiKey']);
        this.makeControlsOptional(['authEncoding']);
      } else {
        this.makeControlsOptional(
          ['authenticationUrl', 'authEncoding', 'clientId', 'clientSecret', 'username', 'password', 'apiKey']);
      }
    }
  }

  private makeControlsRequired(controlNames: string[]): void {
    controlNames.forEach(control => {
      this.connectorAddForm.controls[control].setValidators(Validators.required);
      this.connectorAddForm.controls[control].updateValueAndValidity();
    });
  }

  private makeControlsOptional(controlNames: string[]): void {
    controlNames.forEach(control => {
      this.connectorAddForm.controls[control].setValidators(null);
      this.connectorAddForm.controls[control].updateValueAndValidity();
    });
  }

  public save() {
    if (this.isValid()) {
      if (this.isAEMPv2()) {
        this.saveAEMPv2();
      }
      if (this.isMail()) {
        this.saveMail();
      }
      if (this.isTrackUnitClassic()) {
        this.saveTrackUnitClassic();
      }
      if (this.isGpsOverIp()) {
        this.saveGpsOverIp();
      }
      if (this.isRio()) {
        this.saveRio();
      }
      if (this.isReidl()) {
        this.saveReidl();
      }
      if (this.isSinos2()) {
        this.saveSinos2();
      }
    }
  }

  public isValid(): boolean {
    return this.connectorAddForm.valid;
  }

  public saveAEMPv2() {
    if (this.connectorAddForm.valid) {
      let command: CreateAempV2ConnectorCommand = new CreateAempV2ConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new AempV2ConnectionInformation();
      command.connection.url = this.connectorAddForm.value.url;
      command.connection.authType = this.connectorAddForm.value.authType;
      command.connection.profileKey = this.connectorAddForm.value.profileKey;
      if (this.providesBasicAuthEncoding()) {
        command.connection.basicAuthEncoding = this.connectorAddForm.value.authEncoding;
      }
      if (this.providesAuthUrl()) {
        command.connection.authenticationUrl = this.connectorAddForm.value.authenticationUrl;
      }
      if (this.providesClientId()) {
        command.connection.clientId = this.connectorAddForm.value.clientId;
      }
      if (this.providesClientSecret()) {
        command.connection.clientSecret = this.connectorAddForm.value.clientSecret;
      }
      if (this.providesUsernamePassword()) {
        command.connection.username = this.connectorAddForm.value.username;
        command.connection.password = this.connectorAddForm.value.password;
      }
      if (this.providesAPIKey()) {
        command.connection.apiKey = this.connectorAddForm.value.apiKey;
      }

      this.connectorService.addAEMPv2Connector(command).subscribe(
        () => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {duration: 4000});
          console.log('create aemp job error: ', error);
        }
      );
    }
  }

  public saveMail() {
    if (this.connectorAddForm.valid) {
      let command: CreateMailConnectorCommand = new CreateMailConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new MailConnectionInformation();
      command.connection.server = this.connectorAddForm.value.server;
      command.connection.port = this.connectorAddForm.value.port;
      command.connection.protocol = this.connectorAddForm.value.protocol;
      command.connection.encryption = this.connectorAddForm.value.encryption;
      command.connection.user = this.connectorAddForm.value.user;
      command.connection.password = this.connectorAddForm.value.password;
      command.connection.directory = this.connectorAddForm.value.directory;
      command.connection.destinationDirectory = this.connectorAddForm.value.destinationDirectory;
      command.connection.sender = this.connectorAddForm.value.sender;
      command.connection.filePattern = this.connectorAddForm.value.filePattern;
      command.connection.subjectPattern = this.connectorAddForm.value.subjectPattern;
      command.connection.datePattern = this.connectorAddForm.value.datePattern;
      command.connection.profileKey = this.connectorAddForm.value.profileKey;

      this.connectorService.addMailConnector(command).subscribe(
        () => {
          this.dialogRef.close();
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {duration: 100000});
          console.log('create aemp job error: ', error);
        }
      );
    }
  }

  public saveTrackUnitClassic() {
    if (this.connectorAddForm.valid) {
      let command: CreateTrackUnitClassicConnectorCommand = new CreateTrackUnitClassicConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new TrackUnitClassicConnectionInformation();
      command.connection.url = this.connectorAddForm.value.url;
      command.connection.username = this.connectorAddForm.value.username;
      command.connection.password = this.connectorAddForm.value.password;

      this.connectorService.addTrackUnitClassicConnector(command).subscribe(
        () => {
          this.dialogRef.close();
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {duration: 100000});
          console.log('create aemp job error: ', error);
        }
      );
    }
  }

  public saveGpsOverIp() {
    if (this.connectorAddForm.valid) {
      let command: CreateGpsOverIpConnectorCommand = new CreateGpsOverIpConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new GpsOverIpConnectionInformation();
      command.connection.url = this.connectorAddForm.value.url;
      command.connection.liveUrl = this.connectorAddForm.value.liveUrl;
      command.connection.username = this.connectorAddForm.value.username;
      command.connection.password = this.connectorAddForm.value.password;

      this.connectorService.addGpsOverIpConnector(command)
        .subscribe(() => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {duration: 100000});
          console.log('create gps over ip job error: ', error);
        }
      );
    }
  }

  public saveRio() {
    if (this.connectorAddForm.valid) {
      let command: CreateRioConnectorCommand = new CreateRioConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new RioConnectionInformation();
      command.connection.assetApiUrl = this.connectorAddForm.value.assetApiUrl;
      command.connection.iotEventsApiUrl = this.connectorAddForm.value.iotEventsApiUrl;
      command.connection.authenticationUrl = this.connectorAddForm.value.authenticationUrl;
      command.connection.clientId = this.connectorAddForm.value.clientId;
      command.connection.clientSecret = this.connectorAddForm.value.clientSecret;
      command.connection.integrationId = this.connectorAddForm.value.integrationId;

      this.connectorService.addRioConnector(command)
        .subscribe(() => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {duration: 100000});
          console.log('create rio job error: ', error);
        }
      );
    }
  }

  public saveReidl() {
    if (this.connectorAddForm.valid) {
      let command: CreateReidlConnectorCommand = new CreateReidlConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new ReidlConnectionInformation();
      command.connection.telematicUnitsUrl = this.connectorAddForm.value.telematicUnitsUrl;
      command.connection.telematicUnitsToken = this.connectorAddForm.value.telematicUnitsToken;
      command.connection.eanCableUnit = this.connectorAddForm.value.eanCableUnit;
      command.connection.eanObdUnit = this.connectorAddForm.value.eanObdUnit;
      command.connection.eanDigitalMatterUnit = this.connectorAddForm.value.eanDigitalMatterUnit;

      this.connectorService.addReidlConnector(command)
        .subscribe(() => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {duration: 100000});
          console.log('create reidl job error: ', error);
        }
      );
    }
  }

  public saveSinos2() {
    if (this.connectorAddForm.valid) {
      let command: CreateSinos2ConnectorCommand = new CreateSinos2ConnectorCommand();

      command.connector = new ConnectorInformation();
      command.connector.customerId = null;
      command.connector.name = this.connectorAddForm.value.name;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorAddForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorAddForm.value.maxTimeoutInMinutes;

      command.connection = new Sinos2ConnectionInformation();
      command.connection.url = this.connectorAddForm.value.url;
      command.connection.manNumber = this.connectorAddForm.value.manNumber;
      command.connection.manAccount = this.connectorAddForm.value.manAccount;
      command.connection.manPassword = this.connectorAddForm.value.manPassword;


      this.connectorService.addSinos2Connector(command)
        .subscribe(() => this.dialogRef.close(),
          (error: HttpErrorResponse) => {
            this.snackBar.open(
              `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
              undefined, {duration: 100000});
            console.log('create Sinos2 job error: ', error);
          }
        );
    }
  }

  public isAEMPv2(): boolean {
    return this.type === ConnectorType.AEMP_V2;
  }

  public isMail(): boolean {
    return this.type === ConnectorType.MAIL;
  }

  public isTrackUnitClassic(): boolean {
    return this.type === ConnectorType.TRACKUNIT_CLASSIC;
  }

  public isGpsOverIp(): boolean {
    return this.type === ConnectorType.GPS_OVER_IP;
  }

  public isRio(): boolean {
    return this.type === ConnectorType.RIO;
  }

  public isReidl(): boolean {
    return this.type === ConnectorType.REIDL;
  }

  public isSinos2(): boolean {
    return this.type === ConnectorType.SINOS_PORTAL_2;
  }

  public providesBasicAuthEncoding() {
    return this.currentAuthType === AuthType.BASIC_AUTH;
  }

  public providesAuthUrl(): boolean {
    return [
      AuthType.OAUTH2_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  public providesClientId(): boolean {
    return [
      AuthType.OAUTH1,
      AuthType.OAUTH2_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  public isClientIdMandatory(): boolean {
    return [
      AuthType.OAUTH1,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  public providesClientSecret(): boolean {
    return [
      AuthType.OAUTH1,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  public providesUsernamePassword(): boolean {
    return [
      AuthType.BASIC_AUTH,
      AuthType.OAUTH2_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  public providesAPIKey(): boolean {
    return [
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  onChanges(): void {
    const authTypeFormField = this.connectorAddForm.get('authType');
    if (authTypeFormField && !this.authTypeSubscription) {
      this.authTypeSubscription = authTypeFormField.valueChanges.subscribe(newValue => {
        if (newValue) {
          this.updateForm(newValue);
        }
      });
    }

    const profileKeyFormField = this.connectorAddForm.get('profileKey');
    if (profileKeyFormField && !this.profileKeySubscription) {
      this.profileKeySubscription = profileKeyFormField.valueChanges.subscribe(newProfileKey => {
        if (newProfileKey === 'VOLVO') {
          this.availableAuthTypes = [AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS];
          this.connectorAddForm.get('authType').setValue(AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS);
          this.updateForm(AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS);
        } else if(newProfileKey === 'KUBOTA') {
          this.availableAuthTypes = [AuthType.OAUTH2_PASSWORD_CREDENTIALS];
          this.connectorAddForm.get('authType').setValue(AuthType.OAUTH2_PASSWORD_CREDENTIALS);
          this.updateForm(AuthType.OAUTH2_PASSWORD_CREDENTIALS);
        } else {
          this.availableAuthTypes = Object.keys(AuthType).map(key => AuthType[key]);
        }
      });
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public ngOnDestroy(): void {
    this.authTypeSubscription?.unsubscribe();
    this.profileKeySubscription?.unsubscribe();
  }

}
