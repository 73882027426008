<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input matInput bhRemoveWhitespaces
           [formControl]="$any(control)"
           [placeholder]="placeholder"
           [type]="type"
           [maxLength]="maxLength"
           [errorStateMatcher]="errorStateMatcher"
           [matAutocomplete]="textInputAutoComplete">

    <mat-autocomplete #textInputAutoComplete="matAutocomplete">
        <mat-option *ngFor="let autoCompleteOption of autoCompleteOptions"
                    [value]="autoCompleteOption | slice:0:30">
            {{ autoCompleteOption }}
        </mat-option>
    </mat-autocomplete>
    <fa-icon *ngIf="icon" [icon]="icon" matSuffix></fa-icon>

    <mat-error *ngIf="control.hasError('required') && !control.hasError('trim')">
        {{'shared.validation.required'|translate:{value: label} }}
    </mat-error>
    <mat-error *ngIf="control.hasError('trim')">{{control.getError('trim')}}</mat-error>
    <mat-error *ngIf="control.hasError('maxlength')">
        {{'shared.validation.maxCharacters'|translate:{value: control.errors?.maxlength?.requiredLength} }}
    </mat-error>
    <mat-error *ngIf="control.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
        {{'shared.validation.string.trimSpaces'|translate}}
    </mat-error>
    <mat-error *ngIf="control.hasError('taken')">
        {{'shared.validation.alreadyAssigned'|translate:{value: label} }}
    </mat-error>
    <mat-error *ngIf="getTranslationKeyOfFirstFoundCustomValidationError()">
        {{getTranslationKeyOfFirstFoundCustomValidationError()|translate}}
    </mat-error>
</mat-form-field>
