import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { LocalUserStorageService } from '../../../../../../shared/services/local-user-storage.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faHome, faInfoCircle, faStickyNote } from '@fortawesome/pro-light-svg-icons';
import { KeycloakService } from '../../../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterHistory } from '../../../../../../shared/router-history';
import { EmployeeManagementDatasource } from '../../../../shared/employee-management.datasource';
import { BaseEmployeeViewDetailComponent } from './base/base-employee-view-detail.component';

@UntilDestroy()
@Component({
  selector: 'bh-employee-view-detail',
  templateUrl: './employee-view-detail.component.html',
  styleUrls: ['./employee-view-detail.component.scss']
})

export class EmployeeViewDetailComponent extends BaseEmployeeViewDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly localStorageKey: string = 'employee-details-tab';
  public readonly faInfoCircle: IconDefinition = faInfoCircle;
  public readonly faHome: IconDefinition = faHome;
  public readonly faStickyNote: IconDefinition = faStickyNote;

  @ViewChild('detailTabGroup', { static: true })
  public detailTabGroup: MatTabGroup;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              public employeeManagementStore: EmployeeManagementDatasource,
              private localStorageService: LocalUserStorageService) {
    super(authService, router, route, routerHistory, employeeManagementStore);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }

  public ngAfterViewInit(): void {
    const selectedTab = Number(this.localStorageService.getUserValue(this.localStorageKey));
    if (selectedTab >= 0 && selectedTab <= this.detailTabGroup._tabs.length) {
      this.detailTabGroup.selectedIndex = selectedTab;
    } else {
      this.localStorageService.setUserValue(this.localStorageKey, '0');
    }
  }

  public updateTab(event: MatTabChangeEvent): void {
    this.localStorageService.setUserValue(this.localStorageKey, `${event.index}`);
  }

}
