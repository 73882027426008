<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch" class="task-list-component">
  <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

    <div class="list-view-main__left-body"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="start stretch">
      <div *ngIf="data?.length > 0; else noTasks"
        class="list-view-main__left-body scrollable-table-container"
        fxFlex
        fxLayout="row"
        fxLayoutGap="15px"
        fxLayoutAlign="start stretch">
        <mat-table [dataSource]="data"
          bhMatTableLongHeaderTooltip
          matSort (matSortChange)="sortData($event)"
          class="pagination-data-table left-body-list scrollable-table exclude-initial-fixed-basis-size"
          [ngClass]="{ 'overview-column hide-header': singleOverviewColumn | async }"
          bhResizeColumn
          [resizeColumnConfigs]="columnService.columnConfigs | async"
          (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

          <ng-container [cdkColumnDef]="overviewColumnName">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <bh-maintenance-task-completed-list-item [task]="row">
              </bh-maintenance-task-completed-list-item>
            </mat-cell>
          </ng-container>

          <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of selectedColumns">
            <ng-container *ngIf="column.type === COLUMN_TYPE.ICON">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="icon-column"></mat-header-cell>
              <mat-cell bhResizeColumnCell class="icon-column" *cdkCellDef="let row">
                <span class="maintenance-icon">
                  <mat-icon>{{column.valueCallback(row) | maintenanceCategory: 'icon'}}</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.OTHER">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" [ngSwitch]="column.cdkColumnDef" bhResizeColumnCell>
                <span *ngSwitchCase="COLUMN_DEFINITION.MAINTENANCE_CATEGORY">{{ column.valueCallback(row) | maintenanceCategory}}</span>
                <ng-container *ngSwitchCase="COLUMN_DEFINITION.MAINTENANCE_RESULT">
                  <bh-maintenance-result-badge *ngIf="column.valueCallback(row) as result" [result]="result"></bh-maintenance-result-badge>
                </ng-container>
                <span *ngSwitchCase="COLUMN_DEFINITION.COMPLETED_BY">
                  {{row.completedByInfo | submitterInfo}}
                </span>
                <span *ngSwitchDefault>{{ column.valueCallback(row) }}</span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.EQUIPMENT_NAME">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <div fxFlexFill
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="3px"
                    class="name navigation"
                    (click)="navigateToEquipmentTasks(row.equipmentId)">
                    <span class="text-overflow">
                      {{ row.equipmentName ? row.equipmentName : row.equipmentModel }}
                    </span>
                    <mat-icon class="navigation-icon"
                      [matTooltip]="'general.labels.jumpTo'|translate:{value: 'general.equipm.s'|translate}">
                      open_in_new
                    </mat-icon>
                  </div>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span [bhOverflowTooltip]="column.valueCallback(row)">{{ column.valueCallback(row) }}</span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.IMAGE">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                  <bh-thumbnail *ngIf="row?.thumbnailKey as imageKey" [key]="imageKey" [size]="ThumbnailSize.XS"
                  [altText]="'shared.maintenance.task.equipmentImage'|translate">
                  </bh-thumbnail>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <mat-chip-set>
                  <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                  <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                      matTooltipClass="mat-tooltip-multiline"
                      [matTooltip]="getRemainingLabels(row).join('\n')"
                      [disabled]="true">
                    {{ '+' + getRemainingLabels(row).length }}
                  </mat-chip>
                </mat-chip-set>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <span>
                  {{column.valueCallback(row) | formatTimezoneDateTime }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS && column.cdkColumnDef === COLUMN_DEFINITION.STATUS">
              <mat-header-cell *cdkHeaderCellDef
                mat-sort-header
                [disabled]="column.disableSort"
                bhResizeColumnCell>
                <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                <bh-equipment-status-badge [_status]="row.equipmentStatus"></bh-equipment-status-badge>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS && column.cdkColumnDef === COLUMN_DEFINITION.EQUIPMENT_INFO">
              <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="maintenance-list-column__header-info">
                <bh-header-cell-title>{{'general.info'|translate}}</bh-header-cell-title>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row"
                bhResizeColumnCell
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="3px"
                class="info-cell">
                <mat-icon *ngIf="equipmentCheckerService.isSearchEquipmentLinkedToTelematicsUnit(row)"
                  fontSet="icon-telematics_outline" class="telematics-link-icon"
                  [matTooltip]="(equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)
                    ? 'modules.equipment.equipmentList.telematicData'
                    : 'modules.equipment.equipmentList.telematicNoData')|translate"
                  [ngClass]="{'no-data': !equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)}">
                </mat-icon>
                <fa-icon [icon]="faTruck" size="2px"
                  *ngIf="equipmentCheckerService.isTransportVehicle(row)"
                  class="transport-types-icon"
                  [matTooltip]="concatTransportTypes(row)"></fa-icon>
              </mat-cell>
            </ng-container>

            <!-- TODO: Left for a future integration of action button for a maintenance list -->
            <!-- <ng-container *ngIf="column.type === COLUMN_TYPE.ACTION">
              <mat-header-cell  class="actions-column table-column-20" *matHeaderCellDef></mat-header-cell>
              <mat-cell class="actions-column table-column-20" *matCellDef="let row">
                <bh-maintenance-completed-task-action-button
                  [task]="row"
                  (edit)="editTask.emit(row)"
                  (delete)="deleteTask.emit(row)">
                </bh-maintenance-completed-task-action-button>
              </mat-cell>
            </ng-container> -->
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"
            [routerLink]="routerLinkField && row[routerLinkField]"
            (dblclick)="rowDoubleClick.next()"
            routerLinkActive="left-body-item--active">
          </mat-row>
        </mat-table>
      </div>
    </div>

    <div class="list-view-main__left-body-paginator" fxLayout="row wrap">
      <div class="button-wrapper" fxFlex="80px">
        <button mat-icon-button class="rotatable" (click)="openConfiguration()"
            [matTooltip]="'general.labels.confColumnArrangement'|translate">
          <mat-icon>settings</mat-icon>
        </button>
        <button id="exportBtn" mat-icon-button (click)="openMaintenanceListExport()"
          [matTooltip]="'shared.maintenance.maintenanceListExport.exportHistoricMaintenanceList'|translate">
          <fa-icon [icon]="faFileExcel" size="2x"></fa-icon>
        </button>
      </div>
      <mat-paginator fxFlex="0"
        *ngIf="data.length > 0"
        [length]="length"
        [pageIndex]="index"
        [pageSize]="size"
        (page)="paginationChange($event)"
        [pageSizeOptions]="[5, 25, 50, 100]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
<ng-template #noTasks>
  <div class="no-tasks" fxLayout="row" fxLayoutAlign="center center">
    <span>{{'shared.maintenance.task.noUpcomingCompletedTasks'|translate}}</span>
  </div>
</ng-template>
