import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { ColumnDefinition } from 'app/shared/column-definition';
import { ColumnService } from 'app/shared/column.service';
import { GENERAL_COLUMN_DEF, MAINTENANCE_TASK_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';
import { DisplayService } from 'app/shared/display.service';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { MaintenanceCompletedColumnDefinitionService } from './maintenance-completed-column-definition.service';
import { ColumnConfig } from 'app/modules/osp-ui/components/bh-table/interfaces/column-config.interface';

@Injectable()
export class MaintenanceCompletedColumnService extends ColumnService {
  public static overviewColumnName = 'maintenanceOverview';

  public get columnLimit(): number {
    return this.maintenanceCompletedColumnDefinitionService.availableColumns.length;
  }

  constructor(
    private userConfigurationService: UserConfigurationService,
    private maintenanceCompletedColumnDefinitionService: MaintenanceCompletedColumnDefinitionService,
    displayService: DisplayService,
    authService: KeycloakService
  ) {
    super(
      MaintenanceCompletedColumnService.overviewColumnName,
      maintenanceCompletedColumnDefinitionService.availableColumns,
      userConfigurationService.userConfiguration.completedMaintenanceListConfiguration.columns,
      userConfigurationService.userConfiguration.completedMaintenanceListConfiguration.columnConfigs,
      { pageSize: userConfigurationService.userConfiguration.completedMaintenanceListConfiguration.pageSize },
      displayService,
      authService
    );
  }

  public selectColumns(columns: ColumnDefinition[], pageSize?: number): void {
    super.selectColumns(columns, pageSize);
    this.saveListConfiguration();
  }

  public selectPageSize(pageSize: number): void {
    if (pageSize && pageSize !== this.pageSizeSubject.getValue()) {
      this.selectColumns(this.selectedColumnsSubject.getValue(), pageSize);
    }
  }

  public updateColumnConfigs(configs: ColumnConfig[]): void {
    super.updateColumnConfigs(configs);
    this.saveListConfiguration();
  }

  public isDefaultColumn(column: ColumnDefinition): boolean {
    return column.cdkColumnDef === GENERAL_COLUMN_DEF.ICON
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_NAME
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_CATEGORY
      || column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_NAME
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_AT_DATE
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.COMPLETED_BY
      || column.cdkColumnDef === MAINTENANCE_TASK_COLUMN_DEF.MAINTENANCE_RESULT
  }

  private saveListConfiguration(): void {
    this.userConfigurationService.saveCompletedMaintenanceListConfiguration(
      this.selectedColumnsSubject.getValue().map(column => column.cdkColumnDef),
      this.columnConfigsSubject.getValue(),
      this.pageSizeSubject.getValue(),
    );
  }
}
