import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { AlarmType } from '../../modules/notifications/shared/enums/alarm-type.enum';


@Pipe({
  name: 'alarmTypeDisplay'
})
export class AlarmTypePipe implements PipeTransform {
  private translationKeys = {
    [AlarmType.OPERATING_HOURS]: this.translate('modules.notification.base.alarmTypes.operatingHours'),
    [AlarmType.OPERATING_HOURS_CONTRACT]: this.translate('modules.notification.base.alarmTypes.contractOperatingHours'),
    [AlarmType.TANK_FILL_LEVEL]: this.translate('modules.notification.base.alarmTypes.tankLevel'),
    [AlarmType.FAULTS]: this.translate('modules.notification.base.alarmTypes.fault'),
    [AlarmType.TOTAL_COSTS]: this.translate('modules.notification.base.alarmTypes.totalCost'),
    [AlarmType.DAMAGE_COSTS]: this.translate('modules.notification.base.alarmTypes.violenceDamageCosts'),
    [AlarmType.REPAIR_COSTS]: this.translate('modules.notification.base.alarmTypes.repairCosts'),
    [AlarmType.GUARANTEE]: this.translate('modules.notification.base.alarmTypes.guarantee'),
    [AlarmType.FULL_SERVICE_CONTRACT_END]: this.translate('modules.notification.base.alarmTypes.fullServiceContractEnd'),
    [AlarmType.LEASING_SERVICE_CONTRACT_END]: this.translate('modules.notification.base.alarmTypes.leasingOrRentalContractEnd'),
    [AlarmType.GEOFENCE_VIOLATION]: this.translate('modules.notification.base.alarmTypes.geofenceViolation'),
    [AlarmType.GLOBAL_GEOFENCE_VIOLATION]: this.translate('modules.notification.base.alarmTypes.globalGeofenceViolation'),
    [AlarmType.TIMEFENCING]: this.translate('modules.notification.base.alarmTypes.timefence'),
    [AlarmType.TRANSPORT_TASK_CREATION]: this.translate('modules.notification.base.alarmTypes.transportCreation'),
    [AlarmType.TRANSPORT_TASK_RETURNED]: this.translate(
      'modules.notification.base.alarmTypes.transportStatusChanged',
      { value: this.translate('modules.transportation.transportState.returned') }),
    [AlarmType.NEW_DAMAGE_ADDED]: this.translate('modules.notification.base.alarmTypes.newDamageAdded'),
    [AlarmType.INACTIVE_TELEMATICS_UNIT]: this.translate('modules.notification.base.alarmTypes.inactiveTelematicsUnit'),
    [AlarmType.BATTERY_VOLTAGE]: this.translate('modules.notification.base.alarmTypes.batteryVoltage'),
    [AlarmType.DEF_TANK_LEVEL]: this.translate('modules.notification.base.alarmTypes.defTankLevel'),
    [AlarmType.TELEMATICS_UNIT_BATTERY_STATUS]: this.translate('modules.notification.base.alarmTypes.trackerBatteryStatus'),
  }

  constructor(private languageService: LanguageService) {
  }

  transform(value: any, args?: any): any {
    return this.translationKeys[value] || this.translate('modules.notification.base.alarmTypes.unknown');
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
