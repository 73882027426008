import {Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {EquipmentStatusCategory} from '../../../../modules/equipment/contract/equipment-status-category.enum';
import {Status} from '../../../../modules/equipment/contract/status.interface';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-status-icon',
  templateUrl: './equipment-status-icon.component.html',
  styleUrls: ['./equipment-status-icon.component.scss']
})
export class EquipmentStatusIconComponent {

  @Input() showTooltip = true;
  @Input() status: Status;

  private get category(): string {
    return this.status?.category;
  }

  public get resolveStyle(): string {
    switch (this.category) {
      case EquipmentStatusCategory.OPERATIONAL:
        return 'operational';
      case EquipmentStatusCategory.OPERATIONAL_WITH_RESTRICTIONS:
        return 'operational_with_restrictions';
      case EquipmentStatusCategory.NOT_OPERATIONAL:
        return 'not_operational';
      case EquipmentStatusCategory.NOT_AVAILABLE:
        return 'not_available';
      default:
        return 'empty';
    }
  }
}
