import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AssignSubEquipmentDialogComponent } from './assign-sub-equipment-dialog/assign-sub-equipment-dialog.component';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { UnAssignSubEquipmentCommand } from '../../../../contract/un-assign-sub-equipment-command';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  GuardedNavigableInputComponent
} from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { RouterHistory } from '../../../../../../shared/router-history';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-sub-equipments',
  templateUrl: './equipment-view-sub-equipments.component.html',
  styleUrls: ['./equipment-view-sub-equipments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EquipmentViewSubEquipmentsComponent extends GuardedNavigableInputComponent implements OnInit {

  public equipment: ViewEquipment;
  public subEquipments: ViewEquipment[];
  public hasSubEquipments = false;
  public canAssignSubEquipments = false;
  public canUnassignSubEquipments  = false;

  constructor(private dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory,
              public equipmentStore: EquipmentsDataSource,
              private equipmentCheckerService: EquipmentCheckerService,
              private matomoTracker: MatomoTracker) {
    super(authService, router, route, routerHistory);
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_SUBEQUIPMENT);
  }

  public ngOnInit(): void {
    this.subscribeToCurrentEquipment();
    this.subscribeToSubEquipments();
    this.canAssignSubEquipments = this.hasAccessToAssignSubEquipments();
    this.canUnassignSubEquipments = this.authService.hasAuthority(Authorities.EQUIPMENT_UPDATE);
  }


  public isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public assignSubEquipment(): void {
    let dialogRef = this.dialog.open(AssignSubEquipmentDialogComponent, <MatDialogConfig>{
      disableClose: true
    });
    dialogRef.componentInstance.equipment = this.equipment;
  }

  public unAssignSubEquipment(subEquipmentId: string): void {
    let cmd = new UnAssignSubEquipmentCommand();
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.subEquipmentId = subEquipmentId;

    this.equipmentStore.unAssignSubEquipment(cmd).subscribe();
  }

  public goToEquipmentDetails(equipmentId: string): void {
    this.router.navigate([`/assets/equipment/list/${equipmentId}/general`]);
  }

  private subscribeToCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewEquipment) => {
      if (res) {
        this.equipment = res;
        this.equipmentStore.getSubEquipments(res.equipmentId);
        this.canAssignSubEquipments = this.hasAccessToAssignSubEquipments();
      }
    });
  }

  private hasAccessToAssignSubEquipments() {
    return this.authService.hasAuthority(Authorities.EQUIPMENT_UPDATE)
      && (this.equipment && !this.equipment.containerId);
  }

  private subscribeToSubEquipments(): void {
    this.equipmentStore.subEquipments
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewEquipment[]) => {
      this.subEquipments = res;
      this.hasSubEquipments = res && !!res.length;
    });
  }

}
