import { Pipe, PipeTransform } from '@angular/core';
import { SearchProjectLocation } from 'app/modules/disposition/contract/search-project-location.interface';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapLocationMarker } from '../interfaces/map-location-marker.interface';
import { MapItemSearchProjectLocation } from '../interfaces/map-list-item/map-item-search-project-location.interface';
import { MapIconResolver } from '../services/map-icon-resolver/map-icon-resolver.service';
import { MapLocationType } from '../enums/map-location-type.enum';
import { MapInfoWindowTableViewProjectComponent } from '../components/map-info-window-cluster-table-view/map-info-window-table-view-project/map-info-window-table-view-project.component';
import { PROJECT_COLUMN_DEF } from 'app/shared/constants/base-column-definition-constants';

@Pipe({
  name: 'mapMarkersConvertProjectLocations'
})
export class MapMarkersConvertProjectLocationsPipe implements PipeTransform {

  constructor(private iconResolver: MapIconResolver) {}

  public transform(value: SearchProjectLocation[], mapSettings: MapConfiguration): MapLocationMarker<MapItemSearchProjectLocation>[] {
    if (!Array.isArray(value)) {
      return null;
    }

    return value.filter(Boolean).map(item => ({
      id: item.projectId,
      location: item.location,
      icon: this.iconResolver.getIconProject(),
      item: { ...item, locationType: MapLocationType.PROJECT },
      infoWindow: { content: MapInfoWindowTableViewProjectComponent },
      label: this.getLabel(item, mapSettings),
    }));
  }

  private getLabel(item: SearchProjectLocation, mapSettings: MapConfiguration): string {
    if (mapSettings?.projectColumn === PROJECT_COLUMN_DEF.PROJECT_NONE) {
      return null;
    } else if (mapSettings?.projectColumn === PROJECT_COLUMN_DEF.PROJECT_NUMBER_AND_PROJECT_NAME_LOCATION) {
      return [item.projectNumber, item.projectName].filter(Boolean).join(' - ');
    }
    return item[mapSettings?.projectColumn]
  }

}
