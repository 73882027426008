import { Action } from './action';
import { LocalUserStorageService } from './local-user-storage.service';

/**
 * Function decorator intended for storing function calls, e.g. EquipmentsService.assignToProject,
 * as {Action} instances to localstorage for later invocation.
 */
export function Queueable() {
  return function (target: any, key: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const result = original.apply(this, args);

      if (!navigator.onLine) {
        const action = Action.create(target.constructor.name, key, args[0]);
        queueAction(action);
      }

      return result;
    };

    return descriptor;
  }
}

function queueAction(action: Action) {
  const queuedActions = LocalUserStorageService.getValueDefault<Action[]>('queuedActions', []);
  queuedActions.push(action);
  LocalUserStorageService.setValue('queuedActions', JSON.stringify(queuedActions));
}
