import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'bh-mobile-wizard-tab',
  templateUrl: './mobile-wizard-tab.component.html',
  styleUrls: ['./mobile-wizard-tab.component.scss']
})
export class MobileWizardTabComponent {

  @Input() public key: string;
  @ViewChild('content', { static: true }) private content: ElementRef<HTMLInputElement>;

  public isValid: boolean;

  private index: number | undefined;
  private currentStep: number;

  constructor(private changeDetectionRef: ChangeDetectorRef) {
  }

  public setCurrentStep(value: number): void {
    this.currentStep = value;
    this.disableTabIndex();
    this.changeDetectionRef.detectChanges();
  }

  public setIndex(value: number): void {
    this.index = value;
    this.disableTabIndex();
    this.changeDetectionRef.detectChanges();
  }

  public getIndex(): number {
    return this.index;
  }

  public shouldBeLeft(): boolean {
    return this.currentStep > this.index;
  }

  public shouldBeRight(): boolean {
    return this.currentStep < this.index;
  }

  public shouldBeHidden(): boolean {
    return this.index === undefined
        || this.currentStep + 2 < this.index
        || this.currentStep - 2 > this.index;
  }

  public shouldBeMiddle(): boolean {
    return this.currentStep === this.index;
  }

  private selectInputFields(): NodeListOf<HTMLInputElement | HTMLSelectElement> {
    return this.content.nativeElement.querySelectorAll('input, select');
  }

  private disableTabIndex(): void {
    if (this.shouldBeMiddle()) {
      this.selectInputFields().forEach((input) => {
        input.setAttribute('tabindex', '0');
      });
    } else {
      this.selectInputFields().forEach((input) => {
        input.setAttribute('tabindex', '-1');
      });
    }
  }
}
