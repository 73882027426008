import { VehicleSearch } from 'app/modules/transportation/contracts/transport/vehicle-search.interface';
import moment from 'moment';
import { TransportTaskState } from '../../enums/transport-task-status.enum';
import { TransportTaskChecker } from '../../services/transport-task-checker.service';
import { TransportAssignment } from '../../../contracts/transport/transport-assignment.interface';
import { Utils } from 'app/shared/utils';

export class TransportTaskEvent {
  private static readonly minEventLengthMs: number = 10 * 60 * 1000;

  public section_id: string
  public id: string;
  public start_date: Date;
  public end_date: Date;
  public isCompatibleType: boolean;

  constructor(public vehicle: VehicleSearch, public assignment: TransportAssignment) {
    if (vehicle && assignment) {
      this.id = assignment.assignmentId;
      this.section_id = vehicle.equipmentId;
      this.start_date = this.getEventStartDate(assignment);
      this.end_date = this.getEventEndDate(assignment);
      this.end_date = Utils.differenceMs(this.start_date, this.end_date) > TransportTaskEvent.minEventLengthMs ? this.end_date
          : moment(this.start_date).add(TransportTaskEvent.minEventLengthMs).toDate();
      this.isCompatibleType = vehicle.transportTypes
        && vehicle.transportTypes.some(transportType => transportType === assignment.transportType);
    }
  }

  private getEventStartDate?(assignment: TransportAssignment): Date {
    return !TransportTaskChecker.isInProgressAndBeyond(assignment)
      ? new Date(assignment?.startDate?.timestamp)
      : new Date(assignment?.executionStartDate?.timestamp);
  }

  private getEventEndDate?(assignment: TransportAssignment): Date {
    switch (assignment.state) {
      case TransportTaskState.IN_PROGRESS: {
        const diff = Utils.differenceMs(
          new Date(assignment?.startDate?.timestamp),
          new Date(assignment?.executionStartDate?.timestamp));
        return moment(assignment?.endDate?.timestamp).add(diff).toDate();
      }
      case TransportTaskState.DONE:
      case TransportTaskState.ARCHIVED:
        return new Date(assignment?.executionEndDate?.timestamp);
      default:
        return new Date(assignment?.endDate?.timestamp);
    }
  }
}
