<div [ngStyle]="{'cursor': 'pointer', 'min-width': '0'}">
  <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="equipmentStatusDropdown.menu" class="disable-pointer-events">
    <bh-equipment-status-icon
      class="enable-pointer-events"
      (click)="onIconClick($event)"
      [status]="equipment?.status"
      [showTooltip]="showTooltip">
    </bh-equipment-status-icon>
  </div>
</div>
<bh-equipment-status-dropdown #equipmentStatusDropdown
                              [equipment]="equipment"
                              [disabled]="!editable"
                              (onStatusChange)="onStatusButtonClick($event)">
</bh-equipment-status-dropdown>
