<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button (click)="closeDialog()" aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{'modules.disposition.bulkItemTransfer.transferToStock'|translate}}
    : {{ projectAmount.transferItemName }}</h1>
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <form [formGroup]="form" fxLayout="column">
      <h4>{{'modules.disposition.bulkItemTransfer.chooseStock'|translate}}:</h4>
      <div class="select-search">
        <mat-form-field>
          <mat-select [placeholder]="'general.labels.search'|translate:{ value: 'general.stock.s'|translate }"
                      disableOptionCentering="true"
                      formControlName="stockId"
                      bhInfiniteScroll
                      (infiniteScroll)="onScroll()"
                      [complete]="stockPageSize > stocks.length">
            <mat-option class="mat-select-search">
              <ngx-mat-select-search [formControl]="filterControl"
                                     [placeholderLabel]="'general.labels.search'|translate:{ value: 'general.stock.s'|translate }"
                                     [noEntriesFoundLabel]="'general.labels.noAnyFound'|translate:{ value: 'general.stock.s'|translate }">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let stock of stocks" [value]="stock?.stockId">
              {{stock?.name}} /
              {{stock?.organisationName}}
              <span *ngIf="stock.stockId === projectAmount.stockId">({{'modules.disposition.base.originStock'|translate}}
                )</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="transferItemType === 'BULKITEM'">{{'modules.disposition.bulkItemTransfer.currentInventory'|translate}}:
          {{ getAvailable() !== null ? getAvailable() : '-'}}
          {{'general.from'|translate}}
          {{ getTotal() !== null ? getTotal() : '-' }}
          {{'general.available'|translate|lowercase}}
        </div>
      </div>
      <div *ngIf="transferItemType === 'BULKITEM'">
        <h4>{{'modules.disposition.bulkItemTransfer.selectAmount'|translate}}:</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">

          <div fxFlex="45" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-form-field class="amount-input">
              <input (input)="decimalNumberValidityService.setInputDecimalDigitsNumberValidity($event.target.value, amount)" matInput formControlName="amount" type="number" class="align-right" [min]="0"
                     [max]="(projectAmount.amountAvailable | dimensionUnitConverter: projectAmount.transferItemUnit: 3)">
              <mat-error *ngIf="amount.hasError('max')">
                {{'shared.validation.transfer.maximumTransferred'|translate:{
                amount: (projectAmount.amountAvailable | dimensionUnitConverter: projectAmount.transferItemUnit: 3),
                unit: projectAmount.transferItemUnit | dimensionUnitDisplay
              } }}
              </mat-error>
              <mat-error *ngIf="amount.hasError('numberBigger')">
                {{'shared.validation.number.greaterThanZero'|translate}}
              </mat-error>
              <mat-error *ngIf="amount.hasError('decimalDigitsNumberInvalid')">
                {{'shared.validation.maxDecimals'|translate:{value: 3} }}
              </mat-error>
            </mat-form-field>
            <div class="unit">
              {{ projectAmount.transferItemUnit | dimensionUnitDisplay }} ({{'general.max'|translate|lowercase}}
              . {{ projectAmount.amountAvailable | dimensionUnitConverter: projectAmount.transferItemUnit: 3 }})
            </div>
          </div>

          <fa-icon [icon]="faArrowRight" class="arrow-right"></fa-icon>

          <div fxLayout="column" fxFlex="45" class="new-amount">
            <span>{{'modules.disposition.base.newInventory'|translate}}: </span>
            <span>
            {{getNewAvailable() !== null ? getNewAvailable() : '-'}}
              {{'general.from'|translate}}
              {{getNewTotal() !== null ? getNewTotal() : '-'}}
              {{'general.available'|translate|lowercase}}
          </span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="checkbox-button-section" fxLayout="row" fxLayoutAlign="end">
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            [disabled]="!isValid()"
            (throttledClick)="save()">
      {{'general.buttons.confirm'|translate}}
    </button>
  </div>
</div>
