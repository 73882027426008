import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ViewTransferRequest } from '../../../disposition/projects/project-list/project-view/project-view-transfer-history/view-transfer-request';
import { PagedResponse } from '../../../../shared/contract/page-response.interface';

@Injectable({
  providedIn: 'root'
})
export class TransferRequestWidgetApiService {

  private requestUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/transfer-request';

  constructor(private http: HttpClient) {
  }

  public getPendingTransfers(projectId): Observable<ViewTransferRequest[]> {
    const params = new HttpParams()
      .set('projectId', projectId);
    return this.http.get<ViewTransferRequest[]>(`${this.requestUrl}/pending`, {params: params});
  }

  public getAllPendingTransfers(page: number, pageSize: number): Observable<PagedResponse<ViewTransferRequest>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', pageSize.toString());
    return this.http.get<PagedResponse<ViewTransferRequest>>(`${this.requestUrl}/stock-and-project/all-pending`, {params: params});
  }
}
