import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators} from '@angular/forms';
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { CreateSuperAdminCommand } from '../../contract/create-super-admin-command';
import { KeycloakService } from '../../../../core/keycloak';
import { UsersStore } from '../../shared/users.store';
import { HttpErrorResponse } from '@angular/common/http';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { UserAddBaseComponent } from '../user-add/user-add-base.component';
import { RouterHistory } from '../../../../shared/router-history';

@UntilDestroy()
@Component({
  selector: 'bh-user-add-superadmin',
  templateUrl: './user-add-superadmin.component.html',
  styleUrls: ['./user-add-superadmin.component.scss']
})
export class UserAddSuperadminComponent extends UserAddBaseComponent implements OnInit, OnDestroy {

  today: Date = new Date();

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              private formBuilder: UntypedFormBuilder,
              protected usersStore: UsersStore,
              protected routerHistory: RouterHistory) {
    super(router, route, authService, usersStore, routerHistory);
  }

  ngOnInit(): void {
    this.buildForm();
    this.setEmailInUseValidator();
  }

  ngOnDestroy(): void {
  }

  public save(): void {
    if (this.userAddForm.valid) {
      let formValue = this.userAddForm.getRawValue();
      let validUntil = formValue.validUntil ? formValue.validUntil.toISOString() : null;
      let cmd = new CreateSuperAdminCommand();

      cmd.email = formValue.email;
      cmd.firstName = formValue.firstName;
      cmd.name = formValue.name;
      cmd.mobileNumber = formValue.mobileNumber;
      cmd.validUntil = validUntil;

      this.usersStore.addSuperAdmin(cmd).pipe(untilDestroyed(this)).subscribe(
        () => {
          this.router.navigate(['/users/list']);
        },
        (error: HttpErrorResponse) => {
          console.log('save user error: ', error);
        }
      );
    }
  }

  public isValid(): boolean {
    return this.userAddForm.valid;
  }

  resetForm(): void {
    this.userAddForm.reset();
  }

  private buildForm(): void {
    this.userAddForm = this.formBuilder.group({
      email: ['', [<any>Validators.required, emailValidator()]],
      name: ['', [<any>Validators.required]],
      firstName: '',
      mobileNumber: '',
      validUntil: ''
    });
  }

}
