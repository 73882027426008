import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bh-basic-widget-configuration-dialog',
  templateUrl: './basic-widget-configuration-dialog.component.html',
  styleUrls: ['./basic-widget-configuration-dialog.component.scss']
})
export class BasicWidgetConfigurationDialogComponent implements OnInit, OnDestroy {
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.opened.emit(true);
  }

  onClose(save: boolean): void {
    this.closed.emit(save);
  }

  ngOnDestroy(): void {
    this.opened.emit(false);
  }
}
