import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaintenanceTask } from 'app/modules/maintenance/tasks/shared/maintenance-task';
import { FieldType } from 'app/shared/enums/field-type';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { MaintenanceResult } from '../../../../../../../shared/enums/maintenance-result';

@Component({
  selector: 'bh-maintenance-task-completed-view-general',
  templateUrl: './maintenance-task-completed-view-general.component.html',
  styleUrls: ['./maintenance-task-completed-view-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskCompletedViewGeneralComponent {
  @Input() public task: MaintenanceTask;

  public readonly categories = MaintenanceCategory;
  public readonly fieldTypes = FieldType;
  public readonly currentLocale = this.languageService.getCurrentLocale();
  public readonly maintenanceResults = Object.values(MaintenanceResult);

  constructor(private router: Router,
              private languageService: LanguageService) { }

  public navigateToEquipment(equipmentId: string): void {
    if (equipmentId) {
      this.router.navigate(['assets', 'equipment', 'list', equipmentId, 'tasks'], {
        queryParams: {tabIndex: 1},
      });
    }
  }
}
