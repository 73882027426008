import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewEquipmentProjectAssignment } from '../../contract/view-equipment-project-assignment.interface';
import { ViewProject } from '../../../disposition/contract/view-project.interface';
import { DatesService } from '../../../../shared/services/dates.service';
import { EquipmentsDataSource } from '../equipments.datasource';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UpdateEquipmentAssignmentCommand } from '../../contract/update-equipment-assignment-command';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateRange } from '../../../../shared/components/date-range-select-component/date-range';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorStateMatcher } from '@angular/material/core';
import { FieldTouchedErrorMatcher } from 'app/shared/custom-validators/field-touched-error-matcher';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import {
  EquipmentAssignmentDeleteDialogComponent
} from '../equipment-assignment-delete-dialog/equipment-assignment-delete-dialog.component';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { DeleteEquipmentAssignmentCommand } from '../../contract/delete-equipment-assignment-command';
import { DispositionDataSource } from '../../../disposition/shared/disposition.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-assignment-edit-dialog',
  templateUrl: './equipment-assignment-edit-dialog.component.html',
  styleUrls: ['./equipment-assignment-edit-dialog.component.scss']
})
export class EquipmentAssignmentEditDialogComponent implements OnInit, OnDestroy {

  public assignmentEditForm: UntypedFormGroup;
  public startDateControl: UntypedFormControl;
  public endDateControl: UntypedFormControl;
  public assignment: ViewEquipmentProjectAssignment;
  public project: ViewProject;
  public conflicts: ViewEquipmentProjectAssignment[] = [];
  public errorMatcher: ErrorStateMatcher = new FieldTouchedErrorMatcher();
  public readonly fieldLimit = FieldLimit;

  constructor(private equipmentStore: EquipmentsDataSource,
              public dispositionStore: DispositionDataSource,
              protected dialog: MatDialog,
              private dialogRef: MatDialogRef<EquipmentAssignmentEditDialogComponent>) {
  }

  public ngOnInit(): void {
    this.assignmentEditForm = new UntypedFormGroup({
      description: new UntypedFormControl(this.assignment.comment)
    })
    this.startDateControl = new UntypedFormControl(new Date(this.assignment.startDate));
    this.endDateControl = this.assignment.endDate ?
      new UntypedFormControl(new Date(this.assignment.endDate)) : new UntypedFormControl();
  }

  get description(): AbstractControl {
    return this.assignmentEditForm.get('description');
  }

  public ngOnDestroy(): void {
  }

  public updateConflicts(dateRange: DateRange, assignment: ViewEquipmentProjectAssignment): void {
    this.updateConflictsEquipment(dateRange, assignment);
  }

  private updateConflictsEquipment(dateRange: DateRange, assignment: ViewEquipmentProjectAssignment): void {
    if (!this.startDateControl.valid || !this.endDateControl.valid) {
      return;
    }
    this.equipmentStore.getAssignmentCollisions(
      assignment.equipmentId,
      DatesService.sameTimeZoneAtStartDateUTC(dateRange.startDate),
      DatesService.sameTimeZoneAtEndDateUTC(dateRange.endDate),
      assignment.assignmentId)
      .pipe(untilDestroyed(this))
      .subscribe((conflictingAssignments: ViewEquipmentProjectAssignment[]) => {
        this.conflicts = conflictingAssignments;
      });
  }

  public save(): void {
    let cmd;
    cmd = new UpdateEquipmentAssignmentCommand();
    cmd.equipmentId = this.assignment.equipmentId;
    cmd.assignmentId = this.assignment.assignmentId;
    cmd.projectId = this.project.projectId;
    cmd.comment = this.description.value;
    cmd.newStartDate = this.startDateControl.value.getTime() === new Date(this.assignment.startDate).getTime() ?
      this.startDateControl.value.toISOString() :
      DatesService.sameTimeZoneAtStartDateUTC(this.startDateControl.value);
    if (this.endDateControl.value) {
      cmd.newEndDate = this.assignment.endDate && this.endDateControl.value.getTime() === new Date(this.assignment.endDate).getTime() ?
        this.endDateControl.value.toISOString() :
        DatesService.sameTimeZoneAtEndDateUTC(this.endDateControl.value);
    } else {
      cmd.newEndDate = null;
    }
    this.dialogRef.close(cmd);
  }

  public deleteAssignment(): void {
    let dialogReference = this.dialog.open(EquipmentAssignmentDeleteDialogComponent);

    dialogReference.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd = new DeleteEquipmentAssignmentCommand();
        cmd.equipmentId = this.assignment.equipmentId;
        cmd.assignmentId = this.assignment.assignmentId;
        cmd.projectId = this.assignment.projectId;
        cmd.startDate = new Date(this.assignment.startDate).toISOString();
        cmd.endDate = this.assignment.endDate ? new Date(this.assignment.endDate).toISOString() : null;
        this.dialogRef.close({ delete: true, command: cmd });
      }
    });

  }

  public isValid(): boolean {
    return this.assignmentEditForm.valid &&
      this.startDateControl.valid &&
      this.endDateControl.valid &&
      (this.conflicts.length === 0);
  }
}
