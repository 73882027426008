import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { AdditionalFieldCategory, } from '../../modules/osp-ui/rich-components/bh-additional-field/additional-field';

@Pipe({
  name: 'additionalFieldCategoryDisplay',
  pure: true
})
export class AdditionalFieldCategoryDisplayPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case AdditionalFieldCategory.ADDITIONAL_DATA:
        return this.translate('general.additionalFieldCategory.additionalData');
      case AdditionalFieldCategory.GENERAL_DATA:
        return this.translate('general.additionalFieldCategory.generalData');
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
