import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OrganisationsService } from '../../shared/organisations.service';
import { CustomersService } from '../../shared/customers.service';
import { KeycloakService } from '../../../../core/keycloak';
import { ViewCustomer } from '../../contract/view-customer.interface';
import { ViewOrganisation } from '../../contract/view-organisation.interface';
import { emailValidator } from '../../../../shared/custom-validators/email.validator';
import { OrganisationType } from '../../../../shared/enums/organisation-type.enum';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { CreateOrganisationCommand } from '../../contract/create-organisation-command';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationAddToUserComponent } from '../organisation-add-to-user/organisation-add-to-user.component';
import { SearchUser } from '../../../userrole/contract/search-user.interface';
import { UsersService } from '../../../userrole/shared/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { CountryEnum } from '../../../../shared/country.enum';
import { CountryResolver } from '../../../../shared/country.resolver';
import { OrganisationValidators } from '../organisation.validators';

@Component({
  selector: 'bh-organisation-add',
  templateUrl: './organisation-add.component.html',
  styleUrls: ['./organisation-add.component.scss'],
})
export class OrganisationAddComponent extends GuardedNavigableInputComponent implements OnInit {

  public countryEnum = CountryEnum;
  public organisationAddForm: UntypedFormGroup;
  public customer: ViewCustomer;
  public organisationTypes =  [OrganisationType.Department, OrganisationType.Area,
    OrganisationType.Company, OrganisationType.Location, OrganisationType.Subcompany];
  public parentOrganisation: ViewOrganisation;
  private parentOrganisationId: string;
  private users: SearchUser[];

  public readonly fieldLimit = FieldLimit;

  constructor(private formBuilder: UntypedFormBuilder,
              private organisationsService: OrganisationsService,
              private customersService: CustomersService,
              private usersService: UsersService,
              private dialog: MatDialog,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private countryResolver: CountryResolver) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.buildForm();
    this.organisationAddForm.disable();
    this.getCustomer();
    this.route.queryParams.subscribe(params => {
      const parentOrganisationId = params['parentOrganisationId'];
      if (parentOrganisationId) {
        this.getOrganisation(parentOrganisationId);
      }
    });
    this.getUsers();
  }

  get countryKeys() {
    return Object.keys(this.countryEnum);
  }

  public navigateBack(): void {
    this.goBack('/organisations/list');
  }

  private buildForm() {
    this.organisationAddForm = this.formBuilder.group({
      parentOrganisationId: [null],
      customerId: [null, [<any>Validators.required]],
      organisationName: [null, [<any>Validators.required],
        [OrganisationValidators.uniqueOrganisationName(this.organisationsService)]],
      organisationNumber: [null, [],
        [OrganisationValidators.uniqueOrganisationNumber(this.organisationsService)]],
      organisationType: [null, [<any>Validators.required]],
      organisationContact: this.formBuilder.group({
        name: [null, [<any>Validators.required]],
        phoneNumber: null,
        mobileNumber: null,
        faxNumber: null,
        email: [null, [emailValidator()]],
      }),
      organisationAddress: this.formBuilder.group({
        country: [null, [<any>Validators.required]],
        street: null,
        streetNumber: null,
        postalCode: null,
        city: null,
      }),
    });
  }

  public getCountryName(option: CountryEnum): string {
    return this.countryResolver.resolveName(option);
  }

  get organisationName() {
    return this.organisationAddForm.get('organisationName');
  }

  get organisationNumber() {
    return this.organisationAddForm.get('organisationNumber');
  }

  get organisationType() {
    return this.organisationAddForm.get('organisationType');
  }

  get organisationContactName() {
    return this.organisationAddForm.get('organisationContact').get('name');
  }

  get organisationPhoneNumber() {
    return this.organisationAddForm.get('organisationContact').get('phoneNumber');
  }

  get organisationMobileNumber() {
    return this.organisationAddForm.get('organisationContact').get('mobileNumber');
  }

  get organisationFaxNumber() {
    return this.organisationAddForm.get('organisationContact').get('faxNumber');
  }

  get organisationContactEmail() {
    return this.organisationAddForm.get('organisationContact').get('email');
  }

  get organisationCountry() {
    return this.organisationAddForm.get('organisationAddress').get('country');
  }

  get organisationStreet() {
    return this.organisationAddForm.get('organisationAddress').get('street');
  }

  get organisationStreetNumber() {
    return this.organisationAddForm.get('organisationAddress').get('streetNumber');
  }

  get organisationPostalCode() {
    return this.organisationAddForm.get('organisationAddress').get('postalCode');
  }

  get organisationCity() {
    return this.organisationAddForm.get('organisationAddress').get('city');
  }

  getCustomer() {
    this.customersService.getCustomer(this.getUserCustomerId())
    .subscribe(
      data => {
        this.customer = data;
        this.organisationAddForm.enable();
        this.setFormCustomerId();
      },
      error => {
        console.log('get customer error: ', error);
      },
    );
  }

  getOrganisation(parentOrganisationId: string) {
    this.organisationsService.getOrganisation(parentOrganisationId).subscribe(
      res => {
        this.parentOrganisation = res;
        // to make sure id exists
        this.parentOrganisationId = res.organisationId;
        this.setFormParentOrganisationId();
      }, error => {
        console.log('error on getting organisations', error);
      });
  }

  private getUsers() {
    this.usersService.getUsers(0, 200).subscribe(
      data => {
        this.users = this.filterFleetAdmins(data.content);
      },
      (error: HttpErrorResponse) => {
        console.log('reading users error: ', error);
      },
    );
  }

  private filterFleetAdmins(users: SearchUser[]): SearchUser[] {
    return users.filter((item) => {
      return item.roles.every((role) => {
        return role.name !== 'Flottenadmin';
      });
    });
  }

  save() {
    if (this.isValid()) {
      let formValue = this.organisationAddForm.getRawValue();
      let cmd = new CreateOrganisationCommand();

      if (formValue.parentOrganisationId) {
        cmd.parentOrganisationId = formValue.parentOrganisationId;
      }

      cmd.customerId = formValue.customerId;
      cmd.organisationName = formValue.organisationName;
      cmd.organisationNumber = formValue.organisationNumber;
      cmd.organisationType = formValue.organisationType;
      cmd.organisationContact = formValue.organisationContact;
      cmd.organisationContact.email = formValue.organisationContact.email ? formValue.organisationContact.email : null;
      cmd.organisationAddress = formValue.organisationAddress;

      if (this.users.length) {
        this.addOrganisationToUsers(cmd, formValue.customerId);
      } else {
        this.organisationsService.addOrganisation(cmd).subscribe(() => {
            this.router.navigate(['organisations/list']);
          },
          (error: HttpErrorResponse) => {
            console.log('add organisation error: ', error);
          });
      }
    }
  }

  public isValid(): boolean {
    return this.organisationAddForm.valid;
  }

  private addOrganisationToUsers(createOrganisationCommand, customerId) {
    const dialogRef = this.dialog.open(OrganisationAddToUserComponent, {
      disableClose: true,
    });

    dialogRef.componentInstance.createOrganisationCommand = createOrganisationCommand;
    dialogRef.componentInstance.customerId = customerId;
    dialogRef.componentInstance.users = this.users;
    dialogRef.componentInstance.dialogRef = dialogRef;
  }

  setFormCustomerId() {
    this.organisationAddForm.patchValue({
      customerId: this.customer.customerId,
    });
  }

  setFormParentOrganisationId() {
    this.organisationAddForm.patchValue({
      parentOrganisationId: this.parentOrganisationId,
    });
  }

  resetForm() {
    this.organisationAddForm.reset();
    this.setFormCustomerId();

    if (this.parentOrganisation) {
      this.setFormParentOrganisationId();
    }
  }
}
