import { AlarmSettingsUtilsService } from './../../../alarm-settings-utils.service';
import { AlarmSettingsFieldData } from './../../../../contract/alarm-field-data.type';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { PartnerTheme } from 'app/modules/organisation/contract/partner-theme.enum';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { AlarmSettingsBase } from '../alarm-settings-base.class';

@Component({
  selector: 'bh-limit-settings',
  templateUrl: './limit-settings.component.html',
  styleUrls: ['./limit-settings.component.scss']
})
@UntilDestroy()
export class LimitSettingsComponent extends AlarmSettingsBase implements OnInit, OnDestroy {
  @Input() set form(form: UntypedFormGroup) {
    this.settingsForm = form;
  }
  @Input() set fieldData(fieldData: AlarmSettingsFieldData) {
    this.alarmSettingsFieldData = fieldData;
  }
  public isIntervalFieldVisible = true;

  constructor(
    private alarmSettingsUtilsService: AlarmSettingsUtilsService,
    private userConfigurationService: UserConfigurationService) {
    super();
  }

  public ngOnInit(): void {
    this.alarmSettingsUtilsService.alarmTypeChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.isIntervalFieldVisible = this.alarmSettingsUtilsService.isControlRequired(this.controlNames.SETTINGS_INTERVAL);
    });
  }

  public ngOnDestroy(): void {}

  public isWNTheme() {
    return this.userConfigurationService.getPartnerTheme() === PartnerTheme.WACKER_NEUSON;
  }

  public getFieldPattern(): string {
    return this.alarmSettingsUtilsService.getFieldPattern();
  }
}
