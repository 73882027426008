<div class="overall-data-wrapper">

  <div class="scanner" *ngIf="scannerActive && !isEnterprise">
    <div class="close-scanner">
      <button mat-icon-button *ngIf="torchAvailable" (click)="toggleTorch()">
        <mat-icon *ngIf="torchEnabled">flash_on</mat-icon>
        <mat-icon *ngIf="!torchEnabled">flash_off</mat-icon>
      </button>
      <button mat-icon-button (click)="closeScanner()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="alreadyPairedAssetType" class="already-paired"
         fxLayoutAlign="start stretch" fxLayout="column">
      <span>
        {{'modules.disposition.bulkItemAddEdit.codeUsedBy'|translate:{ value: alreadyPairedAssetType | assetType } }}
      </span>
      <span *ngIf="alreadyPairedAsset" class="already-paired-name">{{alreadyPairedAsset}}</span>
    </div>
    <bh-scanner #scanner
                [torch]="torchEnabled"
                (onScanSuccess)="parseCode($event)">
    </bh-scanner>
    <div class="searchingBulkItemById" *ngIf="searchingBulkItemByQrCode">
      <mat-spinner [strokeWidth]="2" [diameter]="16"></mat-spinner>
    </div>
  </div>

  <div class="scanner" *ngIf="isEnterprise && scannerActive" fxLayoutAlign="start stretch"
       fxLayout="column">
    <div class="close-scanner">
      <button mat-icon-button (click)="closeScanner()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="icon_container_zebra">
      <mat-icon class="zebra_scanner_icon_add" color="primary">view_week</mat-icon>
    </div>
    <div class="scan_message_zebra">{{'general.buttons.pressToScan'|translate}}</div>
  </div>

  <form [formGroup]="form">
    <bh-title [text]="'general.baseData'|translate"></bh-title>
    <div class="qr-code-details required">
      <div class="centered">
        <button mat-icon-button (click)="showScanner()">
          <mat-icon svgIcon="scan-code-scan"></mat-icon>
        </button>
      </div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.bulkItemAddEdit.qrCode'|translate}}</mat-label>
        <input type="text" matInput
               #qrCode
               formControlName="qrCode">
        <mat-error *ngIf="scanCode.hasError('alreadyPairedToBulkItem')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToBulkItem'|translate}}
        </mat-error>
        <mat-error *ngIf="scanCode.hasError('alreadyPairedToEquipment')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToEquipment'|translate}}
        </mat-error>
        <mat-error  *ngIf="scanCode.hasError('alreadyPairedToStock')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToStock'|translate}}
        </mat-error>
        <mat-error  *ngIf="scanCode.hasError('alreadyPairedToProject')">
          {{'shared.validation.scanCodeAssignment.alreadyPairedToProject'|translate}}
        </mat-error>
      </mat-form-field>
      <div class="centered">
        <button mat-icon-button (click)="activateQrEdit($event)">
          <mat-icon *ngIf="qrCodeFieldDisabled()">create</mat-icon>
          <mat-icon *ngIf="!qrCodeFieldDisabled() && scanCode.valid" color="primary">check</mat-icon>
          <mat-icon *ngIf="scanCode.invalid && scanCode.touched" color="error">error</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'general.intNumber.abbr'|translate}}</mat-label>
        <input matInput required
               formControlName="bulkItemNumber"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER">
        <mat-error *ngIf="internalNumber.hasError('taken')">
          {{'shared.validation.alreadyAssigned'|translate:{ value: 'general.intNumber.s'|translate } }}
        </mat-error>
        <mat-error *ngIf="internalNumber.hasError('required')">
          {{'modules.disposition.bulkItemAddEdit.requiredField'|translate}}
        </mat-error>
        <fa-icon [icon]="faCalculatorAlt" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.bulkItemAddEdit.itemName'|translate}}</mat-label>
        <input matInput required
               formControlName="bulkItemName"
               [maxlength]="fieldLimit.NAME">
        <mat-error *ngIf="name.hasError('required')">
          {{'modules.disposition.bulkItemAddEdit.requiredField'|translate}}
        </mat-error>
        <fa-icon [icon]="faTag" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>{{'modules.disposition.base.category.s'|translate}}</mat-label>
        <input matInput required
               formControlName="bulkItemCategory"
               [maxlength]="fieldLimit.MEDIUM_IDENTIFIER"
               [matAutocomplete]="categoryAuto">
        <mat-error *ngIf="category.hasError('required')">
          {{'modules.disposition.bulkItemAddEdit.requiredField'|translate}}
        </mat-error>
        <mat-autocomplete #categoryAuto="matAutocomplete">
          <mat-option *ngFor="let category of categories | async"
                      [value]="category | slice:0:30">
            {{ category }}
          </mat-option>
        </mat-autocomplete>
        <fa-icon [icon]="faThList" matSuffix></fa-icon>
      </mat-form-field>
    </div>
    <div class="consumable-toggle" *ngIf="showConsumable">
        <mat-checkbox formControlName="bulkItemNonReturnable" color="primary" labelPosition="after">
          {{'modules.disposition.base.consumedAutomatically'|translate}}
        </mat-checkbox>
        <fa-icon [icon]="faRecycle"></fa-icon>
    </div>
  </form>
</div>
