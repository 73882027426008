import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../../navigation/sidenav/shared/sidenav.service';
import { Router } from '@angular/router';
import { BaseHeaderComponent } from '../base/base-header.component';
import { KeycloakService } from '../../../core/keycloak';
import { OnlineStatusService } from '../../../shared/services/online-status.service';
import { OfflineDataStore } from '../../../shared/services/offline-data.store';
import { from, interval, merge } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter, skip } from 'rxjs/operators';
import { EditListService } from '../../../shared/services/edit-list.service';
import { NotificationDataSource } from '../../notifications/shared/services/notification.datasource';
import { CommunicationService } from '../../communication/shared/communication.service';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent extends BaseHeaderComponent implements OnInit {

  editMode = false;
  selected = 0;

  constructor(protected authService: KeycloakService,
              protected onlineStatusService: OnlineStatusService,
              protected communicationService: CommunicationService,
              protected notificationStore: NotificationDataSource,
              protected sidenavService: SidenavService,
              protected router: Router,
              protected offlineDataStore: OfflineDataStore,
              protected editListService: EditListService) {
    super(authService, onlineStatusService, communicationService, notificationStore);
    this.editListService.getEditMode().subscribe((value: boolean) => this.editMode = value);
    this.editListService.getSelected().subscribe((value: number) => this.selected = value);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const online = this.onlineStatusService.isOnline
    .pipe(
        untilDestroyed(this),
        filter(isOnline => isOnline),
        skip(1));

    merge(
        from(navigator.serviceWorker.ready),
        interval(300000),
        online
    )
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.offlineDataStore.loadOfflineData();
    });
  }

  public sidenavToggle(): void {
    this.sidenavService.toggle();
  }

  public openChat(): void {
    this.router.navigate(['mobile/chat']);
  }

  public approveAll(): void {
    this.editListService.approveAll();
  }

  public deleteAll(): void {
    this.editListService.deleteAll();
  }
}
