import { TransportStatePipe } from './../../../../modules/transportation/shared/pipes/transport-state.pipe';
import { CommonFilterView } from './../filter-view/common-filter-view.interface';
import { FilterValue } from './../filter-value';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { TransportStateHierarchy } from 'app/modules/transportation/shared/contract/transport-state-hierarchy.class';
import { FilterType, RenderStrategy } from '../filter-type';

export class TransportStateFilterViewConverter extends BaseFilterViewConverter<CommonFilterView> {
  constructor(
    originalFilters: CommonFilterView[],
    selectedValues: FilterParams,
    private transportStateResolverPipe: TransportStatePipe
  ) {
    super(FilterTypes.TRANSPORT_STATE, originalFilters, selectedValues);
    this.addOriginalFilters(this.getRootStateFilterViews(originalFilters));
    this.convert();
  }

  protected convertToFilterType(filterItems: CommonFilterView[]): FilterType {
    return {
      typeName: this.type,
      display: true,
      typeTotal: undefined,
      groups: this.convertToFilterGroups(filterItems),
      renderStrategy: RenderStrategy.GROUPED_TREE
    };
  }

  protected convertToFilterValue(filterItem: CommonFilterView): FilterValue {
    return {
      displayName: this.transportStateResolverPipe.transform(filterItem.name),
      storeName: filterItem.name,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.name),
      filterTotal: this.formatCount(filterItem.count),
      parentStoreName: TransportStateHierarchy.getParent(filterItem.name)
    }
  }

  private getRootStateFilterViews(originalFilters: CommonFilterView[]): CommonFilterView[] {
    const stateMap = originalFilters
      .reduce((acc, state) => {
        const parentState = TransportStateHierarchy.getParent(state.name);
        return {
          ...acc,
          [parentState]: [...(acc[parentState] || []), state]
        }
      }, {});

    return Object.keys(stateMap)
      .map(state => ({
        name: state,
        count: stateMap[state].reduce((acc, { count }) => acc + count, 0)
      }));
  }

}
