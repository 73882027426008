import { environment } from 'environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EditListService, SelectedElement } from '../../../shared/services/edit-list.service';
import { EquipmentsService } from '../../equipment/shared/equipments.service';
import { EnrichedRequestedAssignment } from '../../equipment/shared/requested-assignment';
import { AcknowledgeAssignmentCommand } from './acknowledge-disposition-command';
import { DeleteRequestedAssignmentCommand } from './delete-requested-assignment-command';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { delay } from 'rxjs/operators';
import { KeycloakService } from '../../../core/keycloak';
import { RoleAuthorityGuardsComponent } from '../../../shared/navigation-guards/role-authority-guards.component';


@UntilDestroy()
@Component({
  selector: 'bh-mobile-accept-disposition',
  templateUrl: './mobile-accept-disposition.component.html',
  styleUrls: ['./mobile-accept-disposition.component.scss']
})
export class MobileAcceptDispositionComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  public requestedPendingAssignments: EnrichedRequestedAssignment[] = [];
  public acknowledgeAssignments: EnrichedRequestedAssignment[] = [];
  public editMode = false;

  constructor(private editListService: EditListService,
              private equipmentsService: EquipmentsService,
              protected authService: KeycloakService) {
    super(authService);
  }

  public ngOnInit(): void {
    this.editListService.init();

    this.editListService.approveAllPressed
    .pipe(untilDestroyed(this))
    .subscribe(this.acknowledgeAllSelected.bind(this));

    this.editListService.deleteAllPressed
    .pipe(untilDestroyed(this))
    .subscribe(this.deleteAllSelected.bind(this));

    this.editListService.getEditMode().subscribe((value: boolean) => this.editMode = value);
    this.getAssignmentList();
  }

  public deleteAssignment(assignment: EnrichedRequestedAssignment): void {
    const deleteRequestedAssignmentCommand = new DeleteRequestedAssignmentCommand();
    deleteRequestedAssignmentCommand.assignmentId = assignment.assignmentId;
    deleteRequestedAssignmentCommand.equipmentId = assignment.equipmentId;
    this.equipmentsService.deleteRequestedAssignment(deleteRequestedAssignmentCommand)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.getAssignmentList());
  }

  public toggleSelection(assignment: EnrichedRequestedAssignment): void {
    if (!this.editMode) {
      return;
    }
    if (assignment.checked) {
      assignment.checked = false;
      this.editListService.decrement(assignment, (searchedObj, loopObj) => {
        return loopObj.element.assignmentId !== searchedObj.assignmentId;
      });
    } else {
      assignment.checked = true;
      // DisableApproveAll field value was depend on primary assignee of project.
      // Since primary project assignee logic has removed, disableApproveAll field logic should be worked out
      // Seems that in BEUT-4265 this logic might be returned as acceptance user
      this.editListService.increment({
        disableApproveAll: false,
        element: assignment
      });
    }
  }

  public acknowledgeAssignment(assignment: EnrichedRequestedAssignment): void {
    const acknowledgeAssignmentCommand = new AcknowledgeAssignmentCommand();
    acknowledgeAssignmentCommand.assignmentId = assignment.assignmentId;
    acknowledgeAssignmentCommand.equipmentId = assignment.equipmentId;
    this.equipmentsService.acknowledgeAssignment(acknowledgeAssignmentCommand)
    .pipe(delay(environment.DELAY_SHORT))
    .subscribe(() => this.getAssignmentList());
  }

  public enableEditMode(): void {
    this.editListService.setEditMode(true);
  }

  public disableEditMode(): void {
    this.requestedPendingAssignments.forEach(assignment => assignment.checked = false);
    this.editListService.setEditMode(false);
  }

  public ngOnDestroy(): void {
  }

  private deleteAllSelected(selectedAssignments: SelectedElement<EnrichedRequestedAssignment>[]): void {
    selectedAssignments.forEach(assignment => this.deleteAssignment(assignment.element));
    this.editListService.setEditMode(false);
  }

  private acknowledgeAllSelected(selectedAssignments: SelectedElement<EnrichedRequestedAssignment>[]): void {
    selectedAssignments.forEach(assignment => this.acknowledgeAssignment(assignment.element));
    this.editListService.setEditMode(false);
  }

  private getAssignmentList(): void {
    this.equipmentsService.getRequestedAssignments()
    .subscribe(requestedDispositions => {
      this.requestedPendingAssignments = requestedDispositions.filter(assignment => !assignment.acknowledged);
      this.acknowledgeAssignments = requestedDispositions.filter(assignments => assignments.acknowledged);
    });
  }
}
