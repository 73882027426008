<div class="fab-container">
  <button *ngIf="showSettingsButton"
          mat-fab class="fab-toggler map-fab"
          (click)="onToggleFab()">
    <mat-icon [@toggle]="{value: toggle}">settings</mat-icon>
  </button>

  <button *ngIf="showRefreshButton"
          mat-fab class="fab-toggler map-fab"
          (click)="onRefreshClick()">
    <mat-icon>sync</mat-icon>
  </button>

  <div [@speedDialStagger]="buttons.length">
    <bh-toggle-button *ngFor="let btn of buttons"
                      class="toggle-button"
                      [static]="btn.static"
                      [tooltip]="btn.tooltip"
                      [value]="btn.value">

      <ng-container icon-button>
        <ng-container *ngIf="!btn.faIcon">
          <mat-icon *ngIf="btn.fontSet; else matIcon" [fontSet]="btn.icon"></mat-icon>
          <ng-template #matIcon>
            <mat-icon>{{btn.icon}}</mat-icon>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="btn.faIcon">
          <fa-icon [icon]="btn.faIcon"></fa-icon>
        </ng-container>
      </ng-container>

    </bh-toggle-button>
  </div>
</div>
