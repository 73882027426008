<div class="m-scan-code" fxFlexFill *ngIf="!isEnterprise">
  <div class="m-scan-code__header" fxLayout="row" fxLayoutAlign="end center" *ngIf="cameraInitialized">
    <button class="switch" mat-icon-button (click)="toggleTorch()" *ngIf="torchAvailable">
      <mat-icon [attr.aria-label]="'modules.equipment.equipmentScanCode.toggleTorch'|translate">
        {{ (torch | async) ? 'flash_on' : 'flash_off' }}
      </mat-icon>
    </button>
    <button mat-icon-button (click)="closeCameraView()">
      <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
    </button>
  </div>

  <div [hidden]="!cameraInitialized" fxFlexFill fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      *ngIf="scanResultString && !transferMode && !alreadyPaired && !equipmentHasLiveOperatingHours && !equipmentForbidden && !wrongAssetType"
      [ngClass]="{'m-scan-code__results': true, 'not-found': codeNotFound && !pairMode, 'pair-success': codeNotFound && pairMode}"
      fxLayout="column" fxLayoutAlign="start stretch">
      <button *ngIf="equipmentCreateAllowed()" class="big-button" (click)="navigateToEquipmentAdd()">
        {{'modules.equipment.base.createNewEquipment'|translate}}
      </button>
      <div class="error-message">
        <div class="description">
          <span>{{ (codeNotFound && !pairMode
            ? 'modules.equipment.equipmentScanCode.unknownCode'
            : 'modules.equipment.equipmentScanCode.recognizedCode')|translate }}:
          </span>
        </div>
        <div class="line-one">{{scanResultString}}</div>
      </div>
    </div>

    <div *ngIf="transferMode && scanResultString && codeNotFound" class="m-scan-code__results duplicate"
      fxLayout="column" fxLayoutAlign="start stretch">
      <button class="big-button" (click)="navigateToEquipmentAdd()">
        {{'modules.equipment.base.createNewEquipment'|translate}}
      </button>
      <div class="line-one">{{'modules.equipment.equipmentScanCode.unknownCode'|translate}}: {{ scanResultString }}
      </div>
    </div>

    <div *ngIf="transferMode && scanResultString && amountNotFound" class="m-scan-code__results duplicate"
      fxLayout="column" fxLayoutAlign="start stretch">
      <button class="big-button" (click)="navigateToEquipmentAdd()">
        {{'modules.equipment.base.createNewEquipment'|translate}}
      </button>
      <div class="line-one">{{'modules.equipment.equipmentScanCode.amountNotFound'|translate}}: {{ scanResultString }}
      </div>
    </div>

    <div *ngIf="transferMode && scanResultString && equipmentReserved" class="m-scan-code__results duplicate"
      fxLayout="column" fxLayoutAlign="start stretch">
      <button class="big-button" (click)="navigateToEquipmentAdd()">
        {{'modules.equipment.base.createNewEquipment'|translate}}
      </button>
      <div class="line-one">{{'modules.equipment.equipmentScanCode.equipmentReserved'|translate}}: {{ scanResultString }}
      </div>
    </div>

    <div *ngIf="scanResultString && equipmentForbidden" class="m-scan-code__results not-found" fxLayout="column"
      fxLayoutAlign="start stretch">
      <div class="line-one">{{'modules.equipment.equipmentScanCode.noPermission'|translate}}</div>
      <div class="line-two">{{'modules.equipment.equipmentScanCode.noAccessToEquipment'|translate}}</div>
    </div>

    <div *ngIf="transferMode && scanResultString && equipmentInactive" class="m-scan-code__results not-found"
      fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">{{'modules.equipment.equipmentScanCode.isInactive'|translate}}</div>
      <div class="line-two">{{'modules.equipment.equipmentScanCode.noAccessToInactiveEquipment'|translate}}</div>
    </div>

    <div *ngIf="wrongAssetType" class="m-scan-code__results not-found" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">{{'modules.equipment.equipmentScanCode.noPermission'|translate}}</div>
      <div class="line-two">
        {{ 'modules.equipment.equipmentScanCode.associatedWith'|translate:{ value:(wrongAssetType | assetType) } }}
      </div>
    </div>

    <div *ngIf="getByScanCodeSucceed && transferMode && duplicatedEntry" class="m-scan-code__results duplicate"
      fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">
        {{ foundEquipment | equipmentName }}
        {{'modules.equipment.equipmentScanCode.hasAlreadyBeenScanned'|translate}}
      </div>
      <div class="line-two">{{'modules.equipment.equipmentScanCode.scannedEquipmentNumber'|translate}}: {{
        equipmentList.length }}</div>
    </div>

    <div *ngIf="getByScanCodeSucceed && transferMode && !duplicatedEntry" class="m-scan-code__results pair-success"
      fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">{{ foundEquipment | equipmentName }}
        {{'modules.equipment.equipmentScanCode.recognized'|translate}}!</div>
      <div class="line-two">{{'modules.equipment.equipmentScanCode.scannedEquipmentNumber'|translate}}: {{
        equipmentList.length }}</div>
    </div>

    <button mat-button (click)="editTransferList()" *ngIf="transferMode && equipmentList.length > 0"
      class="m-scan-code__button-pair enlarge-button">
      {{'modules.equipment.equipmentScanCode.completeScan'|translate}}
    </button>

    <div *ngIf="!pairMode && getByScanCodeSucceed && !transferMode && !equipmentHasLiveOperatingHours"
      class="m-scan-code__results" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">{{foundEquipment?.equipmentName}}</div>
      <div class="line-two">{{foundEquipment?.equipmentSerialNumber}}</div>
    </div>

    <div *ngIf="!pairMode && getByScanCodeSucceed && !transferMode" class="m-scan-code-bottom-bar">
      <div class="line-one"></div>
    </div>

    <button mat-button *ngIf="pairingAllowed()" (click)="pairScanCode()" class="m-scan-code__button-pair">
      {{'modules.equipment.base.linkCode'|translate}}
      <mat-icon aria-label="Done" *ngIf="pairScanCodeSucceed">done</mat-icon>
    </button>

    <div *ngIf="alreadyPaired" class="m-scan-code__results not-found" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-two">{{'modules.equipment.equipmentScanCode.alreadyUsedForThisEquipment'|translate}}:</div>
      <div class="line-one">{{foundEquipment?.equipmentName}}</div>
    </div>

    <div *ngIf="getByScanCodeSucceed && equipmentHasLiveOperatingHours" class="m-scan-code__results not-found"
      fxLayout="column" fxLayoutAlign="start stretch">
      <div class="line-one">{{foundEquipment?.equipmentName}}</div>
      <div class="line-two">{{'modules.equipment.equipmentScanCode.operHoursCannotBeRecorded'|translate}}</div>
      <div class="line-three">{{'modules.equipment.equipmentScanCode.equipmentReceivesOperHours'|translate}}</div>

    </div>
  </div>

  <div *ngIf="!cameraInitialized && !cameraAccessFailed">
    <mat-spinner [attr.aria-label]="'modules.equipment.equipmentScanCode.chargeCamera'|translate"></mat-spinner>
  </div>
  <div *ngIf="cameraAccessFailed" class="m-scan-code__announcements">
    <p>{{'modules.equipment.equipmentScanCode.accessCameraDenied'|translate}}</p>
    <p>{{'modules.equipment.equipmentScanCode.contactSupport'|translate}}:</p>
    <a (click)="navigateToSupport()">
      https://www.onestop-pro.de/support/
      <mat-icon aria-hidden="true" class="link-icon">open_in_new</mat-icon>
    </a>
  </div>
</div>

<!-- Ab Hier enterprise view -->
<div class="m-scan-code" *ngIf="isEnterprise" fxFlexFill>
  <div class="m-scan-code__header" fxLayout="row" fxLayoutAlign="start center">
    <div class="zebra-ready" *ngIf="zebraReady">{{'modules.equipment.equipmentScanCode.pressToScan'|translate}}</div>
    <div class="fill-row"></div>
    <button mat-icon-button (click)="closeCameraView()">
      <mat-icon [attr.aria-label]="'general.buttons.close'|translate">close</mat-icon>
    </button>
  </div>

  <div *ngIf="!scanResultString && zebraReady" class="m-scan-code__results results_zebra" fxLayout="column"
    fxLayoutAlign="start stretch">
    <div>
      <mat-icon class="code_not_found_zebra_icon" color="primary">view_week</mat-icon>
    </div>
    <div class="description_zebra">
      <span>{{'modules.equipment.equipmentScanCode.pressToScan'|translate}}</span>
    </div>
  </div>

  <div *ngIf="scanResultString && !transferMode && !alreadyPaired && !equipmentForbidden" [ngClass]="{'m-scan-code__results results_zebra': true,
       'pair-success dispo_scan_zebra': codeNotFound && !pairMode, 'pair-success': codeNotFound && pairMode}"
    fxLayout="column" fxLayoutAlign="start stretch">
    <div *ngIf="scanResultString && !transferMode && !alreadyPaired && codeNotFound && !pairMode">
      <mat-icon class="code_not_found_zebra_icon" color="warn">warning</mat-icon>
    </div>
    <div *ngIf="scanResultString && !transferMode && !alreadyPaired && codeNotFound && pairMode">
      <mat-icon class="code_not_found_zebra_icon" color="primary">view_week</mat-icon>
    </div>
    <div class="description_zebra">
      <span>{{(codeNotFound && !pairMode
        ? 'modules.equipment.equipmentScanCode.unknownCode'
        : 'modules.equipment.equipmentScanCode.recognizedCode')|translate}}:</span>
    </div>
    <div class="line_zebra">{{scanResultString}}</div>
  </div>

  <div *ngIf="scanResultString && equipmentForbidden" class="m-scan-code__results not-found" fxLayout="column"
    fxLayoutAlign="start stretch">
    <div class="line-one">{{'modules.equipment.equipmentScanCode.noPermission'|translate}}</div>
    <div class="line-two">{{'modules.equipment.equipmentScanCode.noAccessToEquipment'|translate}}</div>
  </div>

  <div *ngIf="transferMode && scanResultString && codeNotFound"
    class="m-scan-code__results pair-success dispo_scan_zebra" fxLayout="column" fxLayoutAlign="start stretch">
    <div>
      <mat-icon class="code_not_found_zebra_icon" color="warn">warning</mat-icon>
    </div>
    <div class="line_zebra">{{'modules.equipment.equipmentScanCode.unknownCode'|translate}}</div>
    <div class="line_zebra">{{scanResultString}}</div>
    <div class="additional_scan_zebra">{{'modules.equipment.equipmentScanCode.anotherScanAvailable'|translate}}</div>
    <div *ngIf="transferMode" class="zebra_dispo_counter" fxLayoutAlign="center end">
      <div>{{'modules.equipment.equipmentScanCode.scannedEquipmentNumber'|translate}}: {{ equipmentList.length }}</div>
    </div>
  </div>

  <div *ngIf="getByScanCodeSucceed && transferMode && duplicatedEntry"
    class="m-scan-code__results pair-success dispo_scan_zebra" fxLayout="column" fxLayoutAlign="start stretch">
    <div>
      <mat-icon class="already_scanned_zebra_icon">replay</mat-icon>
    </div>
    <div class="line_zebra">{{'modules.equipment.equipmentScanCode.alreadyScanned'|translate}}:</div>
    <div class="line_zebra">{{ foundEquipment | equipmentName }}</div>
    <div class="additional_scan_zebra">{{'modules.equipment.equipmentScanCode.anotherScanAvailable'|translate}}</div>
    <div *ngIf="transferMode" class="zebra_dispo_counter" fxLayoutAlign="center end">
      <div>{{'modules.equipment.equipmentScanCode.scannedEquipmentNumber'|translate}}: {{ equipmentList.length }}</div>
    </div>
  </div>

  <div *ngIf="getByScanCodeSucceed && transferMode && !duplicatedEntry"
    class="m-scan-code__results pair-success dispo_scan_zebra" fxLayout="column" fxLayoutAlign="start stretch">
    <div>
      <mat-icon color="primary" class="found_zebra_icon">done_outline</mat-icon>
    </div>
    <div class="line_zebra">{{'modules.equipment.equipmentScanCode.detectedEquipment'|translate}}:</div>
    <div class="line_zebra">{{ foundEquipment | equipmentName }}</div>
    <div class="additional_scan_zebra">{{'modules.equipment.equipmentScanCode.anotherScanAvailable'|translate}}</div>
    <div *ngIf="transferMode" class="zebra_dispo_counter" fxLayoutAlign="center end">
      <div>{{'modules.equipment.equipmentScanCode.scannedEquipmentNumber'|translate}}: {{ equipmentList.length }}</div>
    </div>
  </div>

  <button mat-button (click)="editTransferList()" *ngIf="transferMode && equipmentList.length > 0"
    class="m-scan-code__button-pair button-pair-zebra enlarge-button">
    {{'modules.equipment.equipmentScanCode.completeScan'|translate}}
  </button>

  <div *ngIf="!pairMode && getByScanCodeSucceed && !transferMode" class="m-scan-code__results" fxLayout="column"
    fxLayoutAlign="start stretch">
    <div class="line-one">{{foundEquipment?.equipmentName}}</div>
    <div class="line-two">{{foundEquipment?.equipmentSerialNumber}}</div>
  </div>

  <button mat-button *ngIf="pairingAllowed() && zebraReady" (click)="pairScanCode()"
    class="m-scan-code__button-pair button-pair-zebra">
    {{'modules.equipment.base.linkCode'|translate}}
    <mat-icon aria-label="Done" *ngIf="pairScanCodeSucceed">done</mat-icon>
  </button>

  <button mat-button *ngIf="scanResultString && !transferMode && !alreadyPaired && !pairMode"
    class="m-scan-code__button-pair new-equipment-zebra enlarge-button" (click)="navigateToEquipmentAdd()">
    {{'modules.equipment.base.createNewEquipment'|translate}}
  </button>

  <div *ngIf="alreadyPaired" class="m-scan-code__results not-found_zebra" fxLayout="column"
    fxLayoutAlign="start stretch">
    <div>
      <mat-icon class="code_not_found_zebra_icon" color="warn">warning</mat-icon>
    </div>
    <div class="line_zebra">{{'modules.equipment.equipmentScanCode.codeInUse'|translate}}:</div>
    <div class="line_zebra">{{foundEquipment?.equipmentName}}</div>
  </div>
</div>
