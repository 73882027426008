import { MapLocationSelectComponent } from 'app/shared/modules/map/components/map-location-select/map-location-select.component';
import { ContactView } from '../../contract/contact/contact-view.interface';
import { CreateContactCommand } from '../../contract/contact/create-contact-command.interface';
import { RetryGeocoderStatus } from 'app/shared/geolocation/retry-geocoder-status.enum';
import { environment } from 'environments/environment';
import { debounceTime, delay, pairwise, tap, filter, startWith, finalize } from 'rxjs/operators';
import { AddressLocationValidator } from 'app/shared/custom-validators/address-location.validator';
import { MapLoaderService } from 'app/shared/modules/map/services/map-loader.service';
import { AddressLocation } from 'app/modules/organisation/contract/address-location';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { emailValidator } from 'app/shared/custom-validators/email.validator';
import { ContactDataSource } from '../../shared/services/contact.datasource';
import { asyncValidatorFactory } from 'app/shared/custom-validators/async-validator.factory';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContactBase } from '../../contract/contact/contact-base.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContactAddEditParams } from '../../contract/contact/contact-add-edit-params.interface';
import { ContactPatchForm } from '../../contract/contact/contact-patch-form.interface';
import { ContactType } from '../../shared/enums/contact-type.enum';
import { ContactTypeResolverPipe } from 'app/shared/pipes/contact-type-resolver.pipe';
import { UniqueContactIdentifier } from 'app/shared/custom-validators/unique-contact-identifier.validator';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { RetryGeocoder } from 'app/shared/geolocation/retry-geocoder';
import { faAddressCard } from '@fortawesome/pro-duotone-svg-icons';
import { Router } from '@angular/router';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { UpdateContactCommand } from '../../contract/contact/update-contact-command.interface';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from 'app/core/keycloak';
import { GeoLocationLimit } from 'app/shared/enums/geo-location-limit.enum';
import { Address } from 'app/modules/organisation/contract/address.interface';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';

@UntilDestroy()
@Component({
  selector: 'bh-contact-add-edit',
  templateUrl: './contact-add-edit.component.html',
  styleUrls: ['./contact-add-edit.component.scss']
})
export class ContactAddEditComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {
  @ViewChild(MapLocationSelectComponent, { static: true }) mapLocationSelect: MapLocationSelectComponent;
  public faIconTitle = faAddressCard;
  public contact: ContactView;
  public contactForm: UntypedFormGroup;
  public organisationFilter = new UntypedFormControl();
  public filteredOrganisations: Observable<ViewOrganisation[]>;
  public contactTypeOptions = Object.keys(ContactType);
  public fieldLimit = FieldLimit;
  public geocoderStatus: RetryGeocoderStatus;
  public enteredAddress: AddressLocation;
  public customerLabels: Observable<string[]> = this.contactDataSource.availableCustomerLabels;
  public contactLabels: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public geoLocationLimit = GeoLocationLimit;
  public readonly geoLocationChangeStep = 0.001;
  public saveInProgress = false;

  private isManualAddressInput = true;

  public get typeControl(): AbstractControl {
    return this.contactForm.get('type');
  }

  public get generalDataControl(): AbstractControl {
    return this.contactForm.get('generalData');
  }

  public get addressControl(): AbstractControl {
    return this.contactForm.get('address');
  }

  public get identifierControl(): AbstractControl {
    return this.generalDataControl.get('identifier');
  }

  public get nameControl(): AbstractControl {
    return this.generalDataControl.get('name');
  }

  public get organisationControl(): AbstractControl {
    return this.generalDataControl.get('organisationId');
  }

  public get contactPersonControl(): AbstractControl {
    return this.generalDataControl.get('contactPerson');
  }

  public get emailControl(): AbstractControl {
    return this.generalDataControl.get('email');
  }

  public get phoneNumberControl(): AbstractControl {
    return this.generalDataControl.get('phoneNumber');
  }

  public get streetControl(): AbstractControl {
    return this.addressControl.get('street');
  }

  public get streetNumberControl(): AbstractControl {
    return this.addressControl.get('streetNumber');
  }

  public get postalCodeControl(): AbstractControl {
    return this.addressControl.get('postalCode');
  }

  public get cityControl(): AbstractControl {
    return this.addressControl.get('city');
  }

  public get addressLocationControl(): AbstractControl {
    return this.addressControl.get('location');
  }

  public get latitudeControl(): AbstractControl {
    return this.addressLocationControl.get('lat');
  }

  public get longitudeControl(): AbstractControl {
    return this.addressLocationControl.get('lon');
  }

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: ContactAddEditParams,
    public contactTypeResolver: ContactTypeResolverPipe,
    protected authService: KeycloakService,
    private dialogRef: MatDialogRef<ContactAddEditComponent>,
    private formBuilder: UntypedFormBuilder,
    private contactDataSource: ContactDataSource,
    private mapLoaderService: MapLoaderService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    super(authService);
    this.contact = dialogData && dialogData.contact;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.getOrganisations();
    this.getCustomerLabels();
    this.initListeners();
  }

  public ngOnDestroy(): void {
  }

  public isValid(): boolean {
    return this.contactForm.valid
      && !this.contactForm.pending
      && !(this.geocoderStatus === RetryGeocoderStatus.PENDING);
  }

  public updateAddress(address: AddressLocation): void {
    this.isManualAddressInput = false;
    this.addressControl.patchValue(address);
    this.addressControl.markAsDirty();
    this.cdr.detectChanges();
  }

  public save(): void {
    const baseContact: ContactBase = this.formValueToContactBase(this.contactForm.getRawValue());
    if (this.contact) {
      this.update({ contactId: this.contact.contactId, ...baseContact })
    } else {
      this.create({...baseContact, labels: this.contactLabels.value});
    }
  }

  public addLabel(label: string): void {
    const labels = [...this.contactLabels.value];
    labels.push(label);
    this.contactLabels.next(labels);
  }

  public removeLabel(label: string): void {
    const labels = [...this.contactLabels.value];
    labels.splice(labels.indexOf(label), 1);
    this.contactLabels.next(labels);
  }

  private buildForm(): void {
    this.contactForm = this.formBuilder.group({
      type: [null, Validators.required],
      generalData: this.formBuilder.group({
        identifier: [null, [], this.getIdentifierInUseValidator()],
        name: [null, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        organisationId: [null, Validators.required],
        contactPerson: null,
        phoneNumber: null,
        email: [null, emailValidator()],
        labels: ['']
      }),
      address: this.formBuilder.group({
        street: null,
        streetNumber: null,
        postalCode: null,
        city: null,
        location: this.formBuilder.group({
          lat: [null, [Validators.min(GeoLocationLimit.LAT_MIN), Validators.max(GeoLocationLimit.LAT_MAX)]],
          lon: [null, [Validators.min(GeoLocationLimit.LON_MIN), Validators.max(GeoLocationLimit.LON_MAX)]]
        })
      })
    })

    if (this.contact) {
      this.patchContactForm();
      this.addressControl.markAsDirty();
      this.cdr.detectChanges();
    }
    this.setValidators();
  }

  private getIdentifierInUseValidator(): AsyncValidatorFn {
    return asyncValidatorFactory(this.contact
      ? value => UniqueContactIdentifier.inUse(value, this.contactDataSource, this.contact.identifier)
      : value => UniqueContactIdentifier.inUse(value, this.contactDataSource));
  }

  private patchContactForm(patchContact: ContactBase = this.contact): void {
    if (patchContact) {
      this.contactForm.patchValue(this.toContactPatchForm(this.contact) || {});
    }
  }

  private toContactPatchForm(contact: ContactBase): ContactPatchForm {
    if (contact) {
      const type = contact.type;

      const { identifier, name, organisationId, contactPerson, phoneNumber, email } = contact;
      const generalData = { identifier, name, organisationId, contactPerson, phoneNumber, email }

      const address = {
        location: contact.location,
        ...(contact.address
          ? { ...contact.address }
          : { street: null, streetNumber: null, postalCode: null, city: null })
      }

      return { type, generalData, address };
    }
  }

  private setValidators(): void {
    this.mapLoaderService.isLoaded.subscribe(() => {
      this.addressControl.setAsyncValidators(AddressLocationValidator(new RetryGeocoder(new google.maps.Geocoder())));
      this.contactForm.updateValueAndValidity();
    });
  }

  private getOrganisations(): void {
    this.contactDataSource.getOrganisations();
  }

  private getCustomerLabels(): void {
    this.contactDataSource.getCustomerLabels();
  }

  private initListeners(): void {
    this.filteredOrganisationListener();
    this.organisationFilterListener();
    this.addressInputListener();
  }

  private filteredOrganisationListener(): void {
    this.filteredOrganisations = this.contactDataSource.filteredOrganisations;
  }

  private organisationFilterListener(): void {
    this.organisationFilter.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(term => this.contactDataSource.filterOrganisations(term));
  }

  private addressInputListener(): void {
    this.addressControl.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORT),
        startWith(<AddressLocation>this.addressControl.value),
        pairwise(),
        filter(() => !this.preventValuePassingIfNotManualEntry()),
        untilDestroyed(this))
      .subscribe(([oldAddress, address]: [AddressLocation, AddressLocation]) => {
        this.compareAndHandleNewAddress(oldAddress, address);
      });
  }

  private preventValuePassingIfNotManualEntry(): boolean {
    const shouldBePrevented = !this.isManualAddressInput;
    this.isManualAddressInput = true;
    return shouldBePrevented;
  }

  private compareAndHandleNewAddress(oldAddress: AddressLocation, newAddress: AddressLocation): void {
    this.handleAddressPart(oldAddress, newAddress);
    this.handleLocationPart();
  }

  private handleAddressPart(oldAddress: AddressLocation, newAddress: AddressLocation): void {
    if (!this.isSameAddresses(oldAddress, newAddress)) {
      this.clearLocation(newAddress);
      this.enteredAddress = newAddress;
    }
  }

  private handleLocationPart(): void {
    const locationFormValue: LatLon = this.addressLocationControl.value;
    if (this.locationExists(locationFormValue)) {
      this.mapLocationSelect.mapClicked({
        latLng: { lat: locationFormValue.lat, lng: locationFormValue.lon }
      });
    }
  }

  private isSameAddresses(firstAddress: Address, secondAddress: Address): boolean {
    return firstAddress.city === secondAddress.city
      && firstAddress.postalCode === secondAddress.postalCode
      && firstAddress.street === secondAddress.street
      && firstAddress.streetNumber === secondAddress.streetNumber;
  }

  private clearLocation({ location }: AddressLocation): void {
    if (this.locationExists(location)) {
      this.addressControl.patchValue({ location: { lat: null, lon: null } });
    }
  }

  private locationExists(location: LatLon): boolean {
    return location && Number.isFinite(location.lat) && Number.isFinite(location.lon);
  }

  private create(command: CreateContactCommand): void {
    this.saveInProgress = true;
    this.contactDataSource.addContact(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.contactDataSource.updateListing()),
      finalize(() => this.saveInProgress = false),
      untilDestroyed(this))
    .subscribe((contactId: string) => {
      this.router.navigate(['assets/contact/list', contactId]);
      this.dialogRef.close(dialogResults.SAVE);
    });
  }

  private update(command: UpdateContactCommand): void {
    this.saveInProgress = true;
    this.contactDataSource.updateContact(command)
    .pipe(
      delay(environment.DELAY_SHORT),
      tap(() => this.contactDataSource.updateListing()),
      finalize(() => this.saveInProgress = false),
      untilDestroyed(this))
    .subscribe(() => this.dialogRef.close(dialogResults.SAVE));
  }

  private formValueToContactBase(formValue: any): ContactBase {
    return {
      type: formValue.type,
      identifier: formValue.generalData.identifier?.trim() || null,
      name: formValue.generalData.name.trim(),
      organisationId: formValue.generalData.organisationId,
      contactPerson: formValue.generalData.contactPerson?.trim() || null,
      phoneNumber: formValue.generalData.phoneNumber?.trim() || null,
      email: formValue.generalData.email || null,
      address: {
        street: formValue.address.street || null,
        streetNumber: formValue.address.streetNumber || null,
        postalCode: formValue.address.postalCode || null,
        city: formValue.address.city || null
      },
      location: this.locationExists(formValue.address.location) ? formValue.address.location : null
    };
  }
}
