import { LanguageService } from './../../../../shared/services/language.service';
import { OptionResolver } from '../../../../shared/components/card-select/option.resolver';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faPalletAlt, faQuestion, faTrafficCone } from '@fortawesome/pro-light-svg-icons';
import { BulkItemType } from '../../contract/bulk-item-type';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bulkItemType'
})
export class BulkItemTypeResolver implements OptionResolver, PipeTransform {
  private readonly translationKeys = {
    [BulkItemType.STANDARD]: 'general.bulkItemType.standard',
    [BulkItemType.CONSUMABLE]: 'general.bulkItemType.consumable'
  }

  constructor(private languageService: LanguageService) {
  }

  public resolveName(value: any): any {
    return this.translationKeys[value]
      ? this.translate(this.translationKeys[value])
      : this.translate('general.unknown').toLowerCase();
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case BulkItemType.STANDARD:
        return faTrafficCone;
      case BulkItemType.CONSUMABLE:
        return faPalletAlt;
      default:
        return faQuestion;
    }
  }

  public transform(value: any): any {
    return this.resolveName(value);
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
