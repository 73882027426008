import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { AssignmentResolveReason } from '../../enums/assignment-resolve-reason.enum';
import {
  AssignEquipmentToProjectCommand
} from '../../../modules/equipment/contract/assign-equipment-to-project-command';
import { EquipmentsDataSource } from '../../../modules/equipment/shared/equipments.datasource';
import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatesService } from '../dates.service';
import { DispositionProject } from '../../../modules/disposition/shared/disposition-project';
import { SearchEquipment } from '../../../modules/equipment/contract/search-equipment.interface';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, zip } from 'rxjs';
import {
  EquipmentAssignToProjectDialogComponent
} from 'app/modules/equipment/equipments/equipment-assign-to-project/dialog/equipment-assign-to-project-dialog.component';
import { ProjectsService } from 'app/modules/disposition/shared/project.service';
import { DispositionAssignment } from './disposition-assignment.class';
import { ViewEquipment } from 'app/modules/equipment/contract/view-equipment.interface';
import { EquipmentCheckerService } from 'app/modules/equipment/shared/services/equipment-checker.service';

@Injectable()
export class EquipmentDispositionAssignmentService extends DispositionAssignment<SearchEquipment, AssignEquipmentToProjectCommand[]>  {
  constructor(private dialog: MatDialog,
              private equipmentStore: EquipmentsDataSource,
              private projectService: ProjectsService,
              private equipmentsService: EquipmentsService,
              private equipmentCheckerService: EquipmentCheckerService) {
    super();
  }

  protected checkForConflicts(
    assignment: SearchEquipment,
    project: DispositionProject,
    startDate: Date,
    endDate: Date
  ): Observable<AssignEquipmentToProjectCommand[]> {
    const hasDateRangeConflict = of(this.hasDateRangeConflict(project, startDate, endDate));
    const hasAssignmentCollision = this.hasAssignmentCollision(assignment.equipmentId, startDate, endDate);
    const assignedSubEquipments = this.getSubEquipments(assignment);
    const hasAcknowledgeRequestWorkflow = this.hasAssignmentRequestWorkflow();

    return zip(hasDateRangeConflict, hasAssignmentCollision, assignedSubEquipments, hasAcknowledgeRequestWorkflow)
    .pipe(
      map(([dateRangeConflict, assignmentCollision, subEquipments, acknowledgeRequestWorkflow]) => ({
        subEquipments,
        resolveReasons: [
          dateRangeConflict && AssignmentResolveReason.HAS_DATE_RANGE_CONFLICT,
          assignmentCollision && AssignmentResolveReason.HAS_ASSIGNMENT_COLLISION,
          subEquipments && subEquipments.length > 0 && AssignmentResolveReason.HAS_SUBEQUIPMENT,
          acknowledgeRequestWorkflow && AssignmentResolveReason.HAS_ACKNOWLEDGE_REQUEST_WORKFLOW
        ]
      })),
      map(assignmentPayload => ({
        ...assignmentPayload,
        resolveReasons: assignmentPayload.resolveReasons.filter(Boolean)
      })),
      exhaustMap(({ subEquipments, resolveReasons }) => resolveReasons && resolveReasons.length > 0
        ? this.openDispositionDialog(assignment, subEquipments, project, startDate, endDate, resolveReasons)
        : of(this.createAssignEquipmentToProjectCommands(assignment.equipmentId, project.projectId, startDate, endDate)))
    );
  }

  private hasAssignmentCollision(equipmentId: string, startDate: Date, endDate: Date): Observable<boolean> {
    return this.equipmentStore.getAssignmentCollisions(
      equipmentId,
      DatesService.date(startDate, DatesService.zonedDateTimeFormat),
      DatesService.date(endDate, DatesService.zonedDateTimeFormat))
    .pipe(
      map(conflicts => Boolean(conflicts && conflicts.length > 0)));
  }

  private getSubEquipments({ equipmentId }: SearchEquipment): Observable<ViewEquipment[]> {
    return this.equipmentsService.getSubEquipments([equipmentId])
      .pipe(
        map((res: ViewEquipment[]) => res && res.filter(equipment => this.equipmentCheckerService.isActiveEquipment(equipment))));
  }

  private hasAssignmentRequestWorkflow(): Observable<boolean> {
    return this.projectService.hasAssignmentRequestWorkflow();
  }

  private openDispositionDialog(
    equipment: SearchEquipment,
    subEquipments: ViewEquipment[],
    project: DispositionProject,
    startDate: Date,
    endDate: Date,
    resolveReasons: AssignmentResolveReason[]
  ): Observable<AssignEquipmentToProjectCommand[]> {
    const dialogRef = this.dialog.open(EquipmentAssignToProjectDialogComponent, <MatDialogConfig>{
      disableClose: true
    });
    dialogRef.componentInstance.setAssignmentData({
      project: project,
      startDate,
      endDate,
      resolveReasons,
      assignment: {
        equipment,
        subEquipments
      }
    });
    dialogRef.componentInstance.dialogRef = dialogRef;

    return dialogRef
      .afterClosed()
      .pipe(switchMap(commands => commands ? of(commands) : EMPTY));
  }

  private createAssignEquipmentToProjectCommands(
    equipmentId: string,
    projectId: string,
    startDate: Date,
    endDate: Date
  ): AssignEquipmentToProjectCommand[] {
    return [{
      equipmentId,
      projectId,
      startDate: DatesService.date(startDate, DatesService.zonedDateTimeFormat),
      endDate: DatesService.date(endDate, DatesService.zonedDateTimeFormat),
      overwrite: false,
      comment: ''
    }];
  }
}
