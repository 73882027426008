<div class="dialog-wrapper">
  <div class="dialog-close-icon">
    <button mat-icon-button mat-dialog-close aria-label="clear" tabindex="-1">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title *ngIf="confirmTitle">{{confirmTitle}}</h1>
  <div>
    <form [formGroup]="form">
      <bh-card-select #select formControlName="process"
                      class="select"
                      [options]="options"
                      [optionResolver]="resolver"></bh-card-select>
    </form>
  </div>
</div>
