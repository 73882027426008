/* eslint-disable max-len */
import {TelematicUnitTrafficTooltipPipe} from './shared/pipes/telematic-unit-traffic/telematic-unit-traffic-tooltip';
import {TelematicUnitTrafficLightPipe} from './shared/pipes/telematic-unit-traffic/telematic-unit-traffic-light.pipe';
import {TimelineEventTypesPipe} from './shared/pipes/timeline-event-types.pipe';
import {LifeCycleTitlePipe} from './shared/pipes/life-cycle-title.pipe';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MobileEquipmentViewDescriptionComponent
} from 'app/modules/equipment/equipments/equipment-list/equipment-view/equipment-view-description/mobile/mobile-equipment-view-description.component';
import {FileUploadModule} from 'ng2-file-upload';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {GroupByPipe, NgPipesModule} from 'ngx-pipes';
import {CustomMaterialModule} from '../../core/custom-material/custom-material.module';
import {KeycloakService} from '../../core/keycloak';
import {DamageTypeDisplayPipe} from '../../shared/pipes/damage-type.pipe';
import {MainPipeModule} from '../../shared/pipes/main-pipe.module';
import {SharedModule} from '../../shared/shared.module';
import {Utils} from '../../shared/utils';
import {EquipmentTypeSelectComponent} from './equipment-types/equipment-type-select/equipment-type-select.component';
import {
  EquipmentAssignToProjectDialogComponent
} from './equipments/equipment-assign-to-project/dialog/equipment-assign-to-project-dialog.component';
import {
  DispositionFormItemComponent
} from './equipments/equipment-assign-to-project/form-item/disposition-form-item.component';
import {
  MobileDispositionFormItemComponent
} from '../disposition/equipments/mobile/form-item/mobile-transfer-form-item.component';
import {
  MobileTransferMultipleEquipmentsComponent
} from '../disposition/equipments/mobile/mobile-transfer-multiple-equipments/mobile-transfer-multiple-equipments.component';
import {
  EquipmentDamageAddEditComponent
} from './equipments/equipment-damage-add-edit/equipment-damage-add-edit.component';
import {
  EquipmentIncidentAddEditComponent
} from './equipments/equipment-incident-add-edit/equipment-incident-add-edit.component';
import {
  EquipmentInvoiceAddEditComponent
} from './equipments/equipment-invoice-add-edit/equipment-invoice-add-edit.component';
import {
  EquipmentViewAttachmentComponent
} from './equipments/equipment-list/equipment-view/equipment-view-attachment/landscape/equipment-view-attachment.component';
import {
  MobileEquipmentViewAttachmentComponent
} from './equipments/equipment-list/equipment-view/equipment-view-attachment/mobile/mobile-equipment-view-attachment.component';
import {
  EquipmentViewCardComponent
} from './equipments/equipment-list/equipment-view/equipment-view-card/equipment-view-card.component';
import {
  AddEditEquipmentContractComponent
} from './equipments/equipment-list/equipment-view/equipment-view-contracts/add-edit-equipment-contract/add-edit-equipment-contract.component';
import {
  ContractAttachmentsComponent
} from './equipments/equipment-list/equipment-view/equipment-view-contracts/contract-attachments/contract-attachments.component';
import {
  EquipmentViewContractsComponent
} from './equipments/equipment-list/equipment-view/equipment-view-contracts/equipment-view-contracts.component';
import {
  EquipmentViewCostComponent
} from './equipments/equipment-list/equipment-view/equipment-view-cost/equipment-view-cost.component';
import {
  MobileEquipmentViewCurrentLocationComponent
} from './equipments/equipment-list/equipment-view/equipment-view-current-location/mobile/mobile-equipment-view-current-location.component';
import {
  EquipmentViewDamageComponent
} from './equipments/equipment-list/equipment-view/equipment-view-damage/landscape/equipment-view-damage.component';
import {
  EquipmentFieldsPipe
} from './equipments/equipment-list/equipment-view/equipment-view-history/equipment-fields.pipe';
import {
  EquipmentViewDetailComponent
} from './equipments/equipment-list/equipment-view/equipment-view-detail/landscape/equipment-view-detail.component';
import {
  MobileEquipmentViewDetailComponent
} from './equipments/equipment-list/equipment-view/equipment-view-detail/mobile/mobile-equipment-view-detail.component';
import {
  EquipmentViewDispositionComponent
} from './equipments/equipment-list/equipment-view/equipment-view-disposition/landscape/equipment-view-disposition.component';
import {
  MobileEquipmentViewDispositionComponent
} from './equipments/equipment-list/equipment-view/equipment-view-disposition/mobile/mobile-equipment-view-disposition.component';
import {
  RentalEquipmentWarningDialogComponent
} from './equipments/equipment-list/equipment-view/equipment-view-disposition/rental-equipment-warning-dialog/rental-equipment-warning-dialog.component';
import {
  EquipmentViewIncidentComponent
} from './equipments/equipment-list/equipment-view/equipment-view-incident/landscape/equipment-view-incident.component';
import {
  AssignSubEquipmentDialogComponent
} from './equipments/equipment-list/equipment-view/equipment-view-sub-equipments/assign-sub-equipment-dialog/assign-sub-equipment-dialog.component';
import {
  EquipmentViewSubEquipmentsComponent
} from './equipments/equipment-list/equipment-view/equipment-view-sub-equipments/equipment-view-sub-equipments.component';
import {
  EquipmentViewTechnicalFieldComponent
} from './equipments/equipment-list/equipment-view/equipment-view-technical-field/equipment-view-technical-field.component';
import {
  EquipmentViewTelematicComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-view-telematic.component';
import {
  EquipmentViewTimelineComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/landscape/equipment-view-timeline.component';
import {
  MobileEquipmentViewTimelineComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/mobile/mobile-equipment-view-timeline.component';
import {EquipmentViewComponent} from './equipments/equipment-list/equipment-view/landscape/equipment-view.component';
import {
  MobileViewEquipmentComponent
} from './equipments/equipment-list/equipment-view/mobile/mobile-view-equipment.component';
import {EquipmentListComponent} from './equipments/equipment-list/landscape/equipment-list.component';
import {MobileEquipmentListComponent} from './equipments/equipment-list/mobile/mobile-equipment-list.component';
import {
  EquipmentLogMileageComponent
} from './equipments/equipment-log-lifecycle/equipment-log-mileage/landscape/equipment-log-mileage.component';
import {
  MobileEquipmentLogMileageComponent
} from './equipments/equipment-log-lifecycle/equipment-log-mileage/mobile/mobile-equipment-log-mileage.component';
import {
  EquipmentLogOperatingHoursComponent
} from './equipments/equipment-log-lifecycle/equipment-log-operating-hours/landscape/equipment-log-operating-hours.component';
import {
  MobileEquipmentLogOperatingHoursComponent
} from './equipments/equipment-log-lifecycle/equipment-log-operating-hours/mobile/mobile-equipment-log-operating-hours.component';
import {EquipmentMapComponent} from './equipments/equipment-map/landscape/equipment-map.component';
import {MobileEquipmentMapComponent} from './equipments/equipment-map/mobile/mobile-equipment-map.component';
import {ManufacturerAddComponent} from './manufacturers/manufacturer-add/manufacturer-add.component';
import {ManufacturerEditComponent} from './manufacturers/manufacturer-edit/manufacturer-edit.component';
import {ManufacturerListComponent} from './manufacturers/manufacturer-list/manufacturer-list.component';
import {RentalBoardSearchComponent} from './rentals/rental-board/rental-board-search/rental-board-search.component';
import {
  RentalBoardTimelineComponent
} from './rentals/rental-board/rental-board-timeline/rental-board-timeline.component';
import {RentalBoardComponent} from './rentals/rental-board/rental-board.component';
import {CurrentTabReroute} from './routing/current-tab-reroute';
import {equipmentRouting} from './routing/equipment.routing';
import {manufacturerRouting} from './routing/manufacturer.routing';
import {mobileEquipmentRouting} from './routing/mobile-equipment.routing';
import {rentalRouting} from './routing/rental.routing';
import {technicalFieldRouting} from './routing/technical-field.routing';
import {
  EquipmentInvoiceDetailsComponent
} from './shared/equipment-invoice-details/landscape/equipment-invoice-details.component';
import {
  EquipmentAddLabelDialogComponent
} from './shared/equipment-add-label-dialog/equipment-add-label-dialog.component';
import {
  EquipmentAssignToOrganisationDialogComponent
} from './shared/equipment-assign-to-organisation-dialog/equipment-assign-to-organisation-dialog.component';
import {
  EquipmentAssignmentDeleteDialogComponent
} from './shared/equipment-assignment-delete-dialog/equipment-assignment-delete-dialog.component';
import {
  EquipmentAssignmentEditDialogComponent
} from './shared/equipment-assignment-edit-dialog/equipment-assignment-edit-dialog.component';
import {EquipmentAuditService} from './shared/equipment-audit.service';
import {EquipmentDeleteDialogComponent} from './shared/equipment-delete-dialog/equipment-delete-dialog.component';
import {
  EquipmentInvoiceDeleteDialogComponent
} from './shared/equipment-invoice-delete-dialog/equipment-invoice-delete-dialog.component';
import {
  MobileEquipmentScanCodeScannerComponent
} from './shared/equipment-scan-code-scanner/mobile/mobile-equipment-scan-code-scanner.component';
import {
  EquipmentTypeDeleteDialogComponent
} from './shared/equipment-type-delete-dialog/equipment-type-delete-dialog.component';
import {
  EquipmentTypeRenameDialogComponent
} from './shared/equipment-type-rename-dialog/equipment-type-rename-dialog.component';
import {EquipmentsDataSource} from './shared/equipments.datasource';
import {EquipmentsService} from './shared/equipments.service';
import {EquipmentTypesService} from './shared/equipmenttypes.service';
import {GlobalGeofencesDataSource} from './shared/global-geofences.datasource';
import {GlobalGeofencesService} from './shared/global-geofences.service';
import {
  ManufacturerDeleteDialogComponent
} from './shared/manufacturer-delete-dialog/manufacturer-delete-dialog.component';
import {ManufacturerService} from './shared/manufacturer.service';
import {InsuranceContractMileageTypePipe} from './shared/pipes/insurance-contract-mileage-type.pipe';
import {InsuranceContractTypePipe} from './shared/pipes/insurance-contract-type.pipe';
import {RentalContractAmountIntervalPipe} from './shared/pipes/rental-contract-amount-interval.pipe';
import {RentalContractCalculationBasePipe} from './shared/pipes/rental-contract-calculation-base.pipe';
import {RentalContractTypePipe} from './shared/pipes/rental-contract-type.pipe';
import {SalesContractAmortizationTypePipe} from './shared/pipes/sales-contract-amortization-type.pipe';
import {SalesContractStatusPipe} from './shared/pipes/sales-contract-status.pipe';
import {RentalDatasource} from './shared/rental.datasource';
import {EquipmentCheckerService} from './shared/services/equipment-checker.service';
import {EquipmentResolver} from './shared/services/equipment-resolver.service';
import {Html5NavigatorService} from './shared/services/html5-navigator.service';
import {SpecificationService} from './shared/specification.service';
import {TechnicalFieldsService} from './shared/technical-fields.service';
import {TelematicsDataSource} from './shared/telematics.datasource';
import {TelematicsService} from './shared/telematics.service';
import {TechnicalFieldsListComponent} from './technical-fields/technical-fields-list/technical-field-list.component';
import {
  EquipmentViewTasksComponent
} from './equipments/equipment-list/equipment-view/equipment-view-tasks/equipment-view-tasks.component';
import {MaintenanceService} from './shared/maintenance.service';
import {MaintenanceTaskDataSource} from './shared/maintenance-task.datasource';
import {
  AssignMaintenanceRuleDialogComponent
} from './equipments/equipment-list/equipment-view/equipment-view-tasks/assign-maintenance-rule-dialog/assign-maintenance-rule-dialog.component';
import {EquipmentMaintenanceRuleService} from './shared/services/equipment-maintenance-rule.service';
import {MaintenanceCategoryPipe} from '../../shared/pipes/maintenance-category.pipe';
import {
  EquipmentViewCompletedTaskComponent
} from './equipments/equipment-list/equipment-view/equipment-view-completed-task/landscape/equipment-view-completed-task.component';
import {EquipmentMaintenanceTaskService} from './shared/services/equipment-maintenance-task.service';
import {EquipmentMaintenanceRuleStore} from './shared/services/equipment-maintenance-rule.store';
import {MobileEquipmentAddComponent} from './equipments/mobile-equipment-add/mobile-equipment-add.component';
import {
  EquipmentMobileImageDialogComponent
} from './equipments/equipment-mobile-image-dialog-component/equipment-mobile-image-dialog.component';
import {
  EquipmentListExportDialogComponent
} from './equipments/equipment-list/equipment-list-export-dialog/equipment-list-export-dialog.component';
import {
  MobileEquipmentAddOverallDataComponent
} from './equipments/mobile-equipment-add/mobile-equipment-add-overall-information-component/mobile-equipment-add-overall-data.component';
import {
  MobileEquipmentAddGeneralDataComponent
} from './equipments/mobile-equipment-add/mobile-equipment-add-general-information-component/mobile-equipment-add-general-data-component';
import {
  MobileEquipmentAddTechnicalDataComponent
} from './equipments/mobile-equipment-add/mobile-equipment-add-technical-data-component/mobile-equipment-add-technical-data.component';
import {
  MobileEquipmentAddFileUploadComponent
} from './equipments/mobile-equipment-add/mobile-equipment-add-file-upload-component/mobile-equipment-add-file-upload.component';
import {
  MobileEquipmentViewDamageComponent
} from './equipments/equipment-list/equipment-view/equipment-view-damage/mobile/mobile-equipment-view-damage.component';
import {
  MobileEquipmentViewIncidentComponent
} from './equipments/equipment-list/equipment-view/equipment-view-incident/mobile/mobile-equipment-view-incident.component';
import {
  MobileEquipmentViewMileageComponent
} from './equipments/equipment-list/equipment-view/equipment-view-mileage/mobile-equipment-view-mileage.component';
import {
  MobileEquipmentViewOperatingHoursComponent
} from './equipments/equipment-list/equipment-view/equipment-view-operating-hours/mobile-equipment-view-operating-hours.component';
import {
  MobileEquipmentViewCompletedReminderComponent
} from './equipments/equipment-list/equipment-view/equipment-view-completed-reminder/mobile-equipment-view-completed-reminder.component';
import {
  MobileEquipmentInvoiceDetailsComponent
} from './shared/equipment-invoice-details/mobile/mobile-equipment-invoice-details.component';
import {
  MobileEquipmentViewCompletedTaskComponent
} from './equipments/equipment-list/equipment-view/equipment-view-completed-task/mobile/mobile-equipment-view-completed-task.component';
import {
  DayTimelineComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/day-timeline/day-timeline.component';
import {ProjectSelectComponent} from './equipments/equipment-assign-to-project/project-select/project-select.component';
import {TechnicalFieldEditComponent} from './technical-fields/technical-fields-edit/technical-field-edit-component';
import {TechnicalFieldsDataSource} from './shared/technical-fields.datasource';
import {TechnicalFieldAddComponent} from './technical-fields/technical-fields-add/technical-field-add-component';
import {EquipmentTypesDataSource} from './shared/equipment-types.datasource';
import {EquipmentColumnService} from './shared/equipment-column.service';
import {
  EquipmentAssignToStockDialogComponent
} from './shared/equipment-assign-to-stock-dialog/equipment-assign-to-stock-dialog.component';
import {StockService} from './shared/stock-service';
import {EquipmentAddEditComponent} from './equipments/equipment-add-edit/equipment-add-edit.component';
import {EquipmentFieldStore} from './shared/equipment-field.store';
import {
  ManufacturerCreateDialogComponent
} from './shared/manufacturer-create-dialog/manufacturer-create-dialog.component';
import {DocuwareService} from './shared/docuware.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
  EquipmentViewGeneralComponent
} from './equipments/equipment-list/equipment-view/equipment-view-general/landscape/equipment-view-general.component';
import {
  EquipmentViewHistoryComponent
} from './equipments/equipment-list/equipment-view/equipment-view-history/equipment-view-history.component';
import {
  MobileEquipmentViewGeneralComponent
} from './equipments/equipment-list/equipment-view/equipment-view-general/mobile/mobile-equipment-view-general.component';
import {
  EquipmentEmployeeAssigneeCardsComponent
} from './equipments/equipment-list/equipment-view/equipment-view-general/equipment-employee-assignee-cards/equipment-employee-assignee-cards.component';
import {
  EquipmentEmployeeAssignmentViewDialogComponent
} from './shared/equipment-employee-assignment-view-dialog/equipment-employee-assignment-view-dialog.component';
import {
  EquipmentEmployeeAssignmentManageDialogComponent
} from './shared/equipment-employee-assignment-manage-dialog/equipment-employee-assignment-manage-dialog.component';
import {EquipmentEmployeeService} from './shared/equipment-employee.service';
import {
  MobileEquipmentViewEmployeeAssignmentAddedComponent
} from './equipments/equipment-list/equipment-view/equipment-view-employee-assignment-added/mobile-equipment-view-employee-assignment-added.component';
import {
  MobileEquipmentViewEmployeeAssignmentRemovedComponent
} from './equipments/equipment-list/equipment-view/equipment-view-employee-assignment-removed/mobile-equipment-view-employee-assignment-removed.component';
import {
  MobileEquipmentViewEmployeeAssignmentReplacedComponent
} from './equipments/equipment-list/equipment-view/equipment-view-employee-assignment-replaced/mobile-equipment-view-employee-assignment-replaced.component';
import {TranslateModule} from '@ngx-translate/core';
import {
  EquipmentStockSelectComponent
} from './equipments/equipment-add-edit/equipment-stock-select/equipment-stock-select.component';
import {
  EquipmentViewMapAddressComponent
} from './equipments/equipment-list/equipment-view/equipment-view-general/equipment-view-map-address/equipment-view-map-address.component';
import {PerformanceTypePipe} from './shared/pipes/performance-type.pipe';
import {QuillModule} from 'ngx-quill';
import {EquipmentColumnDefinitionService} from './shared/services/equipment-column-definition.service';
import {TopfactService} from './shared/topfact.service';
import {EquipmentContractTypeResolver} from './shared/services/equipment-contract-type.resolver';
import {TelematicUnitListComponent} from './telematic-unit/telematic-unit-list/telematic-unit-list.component';
import {telematicUnitRouting} from './routing/telematics-unit.routing';
import {TelematicUnitStore} from './shared/services/telematic-unit.store';
import {
  EquipmentTelematicsUnitAssignComponent
} from './equipments/equipment-telematics-unit-assign/equipment-telematics-unit-assign.component';
import {EquipmentTelematicsUnitTypeResolver} from './shared/services/equipment-telematics-unit-type.resolver';
import {
  EventComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/event-component/event.component';
import {
  CostEventComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/event-component/cost-event-component/cost-event.component';
import {
  TaskEventComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/event-component/task-event-component/task-event.component';
import {
  EquipmentViewUsageComponent
} from './equipments/equipment-list/equipment-view/equipment-view-usage/equipment-view-usage.component';
import {
  MobileEquipmentTransferToProjectComponent
} from '../disposition/equipments/mobile/mobile-equipment-transfer-to-project.component';
import {
  ExtendedInvoiceInfoComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/event-component/extended-invoice-info/extended-invoice-info.component';
import {
  DamageEventComponent
} from './equipments/equipment-list/equipment-view/equipment-view-timeline/event-component/damage-event/damage-event.component';
import {
  EquipmentTelematicsUnitCalibrationComponent
} from './equipments/equipment-telematics-unit-calibration/equipment-telematics-unit-calibration.component';
import {
  EquipmentTransportTypeEditDialogComponent
} from './shared/equipment-transport-types-edit-dialog/equipment-transport-types-edit-dialog.component';
import {TeltonikaDatasource} from './shared/teltonika.datasource';
import {
  EquipmentHistoryValueFormatPipe
} from './equipments/equipment-list/equipment-view/equipment-view-history/equipment-history-value-format.pipe';
import {ConstructionYearFormatPipe} from './shared/pipes/construction-year.pipe';
import {EquipmentInfoComponent} from './equipments/equipment-list/landscape/left-body-list/equipment-info.component';
import {
  EquipmentStatusStateChangeComponent
} from './equipment-status/equipment-status-state-change/equipment-status-state-change.component';
import {
  EquipmentStatusStateChangeIconComponent
} from './equipment-status/equipment-status-state-change-icon/equipment-status-state-change-icon.component';
import {ManufacturerColumnService} from '../disposition/shared/services/manufacturer-column.service';
import {EquipmentTypeColumnService} from '../disposition/shared/services/equipment-type-column.service';
import {EquipmentFilterService} from './shared/services/equipment-filter.service';
import {MaintenanceTaskCompletedDataSource} from './shared/services/maintenance-task-completed.datasource';
import {
  EquipmentMaintenanceTaskTabContentControllerService
} from './shared/services/tab-content-controller/equipment-maintenance-task-tab-content-controller.service';
import {ProjectGeofencesDataSource} from './shared/project-geofences.datasource';
import {MaintenanceResultPipe} from '../../shared/pipes/maintenance-result.pipe';
import {
  EquipmentTelematicOperatingHoursHistoryComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-telematics-operating-hours-history/equipment-telematic-operating-hours-history.component';
import {
  EquipmentTelematicsOperatingHoursRatioComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-telematics-operating-hours-ratio/equipment-telematics-operating-hours-ratio.component';
import {
  EquipmentTelematicsTankLevelHistoryComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-telematics-tank-level-history/equipment-telematics-tank-level-history.component';
import {
  EquipmentTelematicsSingleBarComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-telematics-single-bar/equipment-telematics-single-bar.component';
import {
  EquipmentTelematicsExternalVoltageComponent
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-telematics-external-voltage/equipment-telematics-external-voltage.component';
import {
  TelematicsFormatDatePipe
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/pipes/telematics-format-date.pipe';
import {
  ConvertStatusHoursToSeriesPipe
} from './equipments/equipment-list/equipment-view/equipment-view-telematic/pipes/convert-status-hours-to-series.pipe';
import {MaintenanceTaskService} from '../maintenance/tasks/shared/service/maintenance-task.service';
import {MapModule} from 'app/shared/modules/map/map.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DigitalMatterDatasource} from './shared/digital-matter.datasource';
import {BhTableModule} from '../osp-ui/components/bh-table/bh-table.module';
import { BhButtonModule } from '../osp-ui/components/bh-button/bh-button.module';
import { BhIconModule } from '../osp-ui/components/bh-icon/bh-icon.module';
import {DispositionModule} from '../disposition/disposition.module';
import { EquipmentAdditionalFieldService } from './shared/services/equipment-additional-fields.service';
import { AdditionalFieldNameIncludesPipe } from './equipments/equipment-list/equipment-view/equipment-view-technical-field/pipes/additional-field-name-includes.pipe';
import { OspUiPipesModule } from '../osp-ui/pipes/osp-ui-pipes.module';
import {
  EquipmentAdditionalGeneralFieldsComponent
} from './equipments/equipment-list/equipment-view/equipment-view-general/equipment-additional-general-fields/equipment-additional-general-fields.component';
import { BhAdditionalFieldModule } from '../osp-ui/rich-components/bh-additional-field/bh-additional-field.module';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    equipmentRouting,
    mobileEquipmentRouting,
    manufacturerRouting,
    technicalFieldRouting,
    rentalRouting,
    telematicUnitRouting,
    InfiniteScrollModule,
    FileUploadModule,
    MainPipeModule,
    SharedModule,
    NgPipesModule,
    DragDropModule,
    TranslateModule,
    QuillModule,
    MapModule,
    ScrollingModule,
    BhTableModule,
    BhButtonModule,
    BhIconModule,
    DispositionModule,
    OspUiPipesModule,
    BhAdditionalFieldModule,
  ],
  declarations: [
    EquipmentListComponent,
    TechnicalFieldsListComponent,
    EquipmentViewComponent,
    EquipmentMapComponent,
    EquipmentTypeSelectComponent,
    EquipmentDeleteDialogComponent,
    EquipmentAddEditComponent,
    EquipmentTypeDeleteDialogComponent,
    EquipmentTypeRenameDialogComponent,
    ManufacturerListComponent,
    ManufacturerAddComponent,
    ManufacturerEditComponent,
    ManufacturerDeleteDialogComponent,
    EquipmentInvoiceAddEditComponent,
    EquipmentDamageAddEditComponent,
    EquipmentIncidentAddEditComponent,
    EquipmentLogOperatingHoursComponent,
    EquipmentLogMileageComponent,
    EquipmentViewDetailComponent,
    EquipmentViewTechnicalFieldComponent,
    EquipmentViewTimelineComponent,
    EquipmentViewCostComponent,
    EquipmentViewTasksComponent,
    EquipmentViewDamageComponent,
    EquipmentViewIncidentComponent,
    EquipmentAddLabelDialogComponent,
    EquipmentAssignToOrganisationDialogComponent,
    EquipmentAssignToStockDialogComponent,
    EquipmentViewGeneralComponent,
    EquipmentViewHistoryComponent,
    EquipmentViewAttachmentComponent,
    EquipmentViewTelematicComponent,
    EquipmentAssignToProjectDialogComponent,
    EquipmentViewDispositionComponent,
    EquipmentViewUsageComponent,
    EquipmentAssignmentDeleteDialogComponent,
    EquipmentAssignmentEditDialogComponent,
    EquipmentInvoiceDeleteDialogComponent,
    EquipmentViewSubEquipmentsComponent,
    AssignSubEquipmentDialogComponent,
    MobileEquipmentListComponent,
    EquipmentInvoiceDetailsComponent,
    MobileEquipmentInvoiceDetailsComponent,
    MobileEquipmentMapComponent,
    MobileViewEquipmentComponent,
    MobileEquipmentViewDetailComponent,
    MobileEquipmentViewGeneralComponent,
    MobileEquipmentViewCurrentLocationComponent,
    MobileEquipmentViewDispositionComponent,
    MobileEquipmentViewAttachmentComponent,
    MobileEquipmentViewTimelineComponent,
    RentalEquipmentWarningDialogComponent,
    MobileEquipmentScanCodeScannerComponent,
    MobileEquipmentLogOperatingHoursComponent,
    MobileEquipmentLogMileageComponent,
    MobileEquipmentTransferToProjectComponent,
    MobileEquipmentViewDamageComponent,
    MobileEquipmentViewIncidentComponent,
    MobileEquipmentViewMileageComponent,
    MobileEquipmentViewOperatingHoursComponent,
    MobileEquipmentViewCompletedReminderComponent,
    MobileEquipmentViewCompletedTaskComponent,
    MobileEquipmentViewEmployeeAssignmentAddedComponent,
    MobileEquipmentViewEmployeeAssignmentReplacedComponent,
    MobileEquipmentViewEmployeeAssignmentRemovedComponent,
    EquipmentViewContractsComponent,
    RentalContractCalculationBasePipe,
    RentalContractAmountIntervalPipe,
    RentalContractTypePipe,
    SalesContractStatusPipe,
    RentalBoardComponent,
    RentalBoardSearchComponent,
    RentalBoardTimelineComponent,
    SalesContractAmortizationTypePipe,
    InsuranceContractTypePipe,
    InsuranceContractMileageTypePipe,
    RentalBoardTimelineComponent,
    EquipmentViewCardComponent,
    EquipmentFieldsPipe,
    ContractAttachmentsComponent,
    DispositionFormItemComponent,
    MobileDispositionFormItemComponent,
    MobileTransferMultipleEquipmentsComponent,
    MobileEquipmentViewDescriptionComponent,
    AssignMaintenanceRuleDialogComponent,
    EquipmentViewCompletedTaskComponent,
    EquipmentMobileImageDialogComponent,
    MobileEquipmentAddComponent,
    EquipmentListExportDialogComponent,
    ProjectSelectComponent,
    MobileEquipmentAddOverallDataComponent,
    MobileEquipmentAddGeneralDataComponent,
    MobileEquipmentAddTechnicalDataComponent,
    MobileEquipmentAddFileUploadComponent,
    TechnicalFieldEditComponent,
    TechnicalFieldAddComponent,
    DayTimelineComponent,
    ManufacturerCreateDialogComponent,
    EquipmentEmployeeAssigneeCardsComponent,
    EquipmentEmployeeAssignmentViewDialogComponent,
    EquipmentEmployeeAssignmentManageDialogComponent,
    EquipmentAdditionalGeneralFieldsComponent,
    EquipmentStockSelectComponent,
    EquipmentViewMapAddressComponent,
    LifeCycleTitlePipe,
    PerformanceTypePipe,
    AddEditEquipmentContractComponent,
    TelematicUnitListComponent,
    TelematicUnitTrafficLightPipe,
    TelematicUnitTrafficTooltipPipe,
    EquipmentTelematicsUnitAssignComponent,
    EventComponent,
    TimelineEventTypesPipe,
    CostEventComponent,
    TaskEventComponent,
    ExtendedInvoiceInfoComponent,
    DamageEventComponent,
    EquipmentTelematicsUnitCalibrationComponent,
    EquipmentTransportTypeEditDialogComponent,
    EquipmentHistoryValueFormatPipe,
    ConstructionYearFormatPipe,
    EquipmentInfoComponent,
    EquipmentStatusStateChangeComponent,
    EquipmentStatusStateChangeIconComponent,
    EquipmentTelematicOperatingHoursHistoryComponent,
    EquipmentTelematicsOperatingHoursRatioComponent,
    EquipmentTelematicsTankLevelHistoryComponent,
    EquipmentTelematicsSingleBarComponent,
    EquipmentTelematicsExternalVoltageComponent,
    TelematicsFormatDatePipe,
    ConvertStatusHoursToSeriesPipe,
    AdditionalFieldNameIncludesPipe,
  ],
  providers: [
    DatePipe,
    DocuwareService,
    TopfactService,
    EquipmentTypesService,
    EquipmentEmployeeService,
    EquipmentsService,
    MaintenanceService,
    TechnicalFieldsService,
    SpecificationService,
    ManufacturerService,
    KeycloakService,
    EquipmentsDataSource,
    MaintenanceTaskDataSource,
    MaintenanceTaskService,
    MaintenanceTaskCompletedDataSource,
    GlobalGeofencesDataSource,
    ProjectGeofencesDataSource,
    ManufacturerColumnService,
    EquipmentTypeColumnService,
    GlobalGeofencesService,
    TelematicsDataSource,
    TeltonikaDatasource,
    DigitalMatterDatasource,
    RentalDatasource,
    Utils,
    EquipmentCheckerService,
    Html5NavigatorService,
    EquipmentResolver,
    GroupByPipe,
    DamageTypeDisplayPipe,
    TelematicsService,
    EquipmentAuditService,
    CurrentTabReroute,
    EquipmentMaintenanceRuleService,
    EquipmentMaintenanceRuleStore,
    MaintenanceCategoryPipe,
    EquipmentMaintenanceTaskService,
    TechnicalFieldsDataSource,
    EquipmentTypesDataSource,
    EquipmentColumnService,
    StockService,
    EquipmentFieldStore,
    LifeCycleTitlePipe,
    PerformanceTypePipe,
    EquipmentColumnDefinitionService,
    EquipmentContractTypeResolver,
    TelematicUnitStore,
    EquipmentTelematicsUnitTypeResolver,
    TimelineEventTypesPipe,
    CurrencyPipe,
    ConstructionYearFormatPipe,
    EquipmentFilterService,
    EquipmentMaintenanceTaskTabContentControllerService,
    MaintenanceResultPipe,
    EquipmentAdditionalFieldService,
  ],
})
export class EquipmentModule {
}
