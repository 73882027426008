<div class="employee-details-wrapper" fxLayout="column">

  <div class="employee-details-header">
    <bh-title [text]="'general.generalData'|translate"></bh-title>
  </div>

  <div class="list-view-main__tabs" fxFlex>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms" #detailTabGroup (selectedTabChange)="updateTab($event)">

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faInfoCircle"></fa-icon> {{'general.baseData'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-employee-view-general>
          </bh-employee-view-general>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faHome"></fa-icon> {{'modules.disposition.employeeViewDetail.privateData'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-employee-view-private-data>
          </bh-employee-view-private-data>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon fontSet="icon-attachment_outline"></mat-icon> {{'general.attachment.pl'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-employee-view-attachment>
          </bh-employee-view-attachment>
        </div>
      </mat-tab>

      <mat-tab *ngIf="canViewNotes">
        <ng-template mat-tab-label>
          <fa-icon [icon]="faStickyNote"></fa-icon> {{'modules.disposition.employeeViewDetail.notes'|translate}}
        </ng-template>
        <div class="list-view-main__tab-content">
          <bh-employee-view-notes>
          </bh-employee-view-notes>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>

</div>
