import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { ViewOrganisation } from '../contract/view-organisation.interface';
import { CreateOrganisationCommand } from '../contract/create-organisation-command';
import { UpdateOrganisationCommand } from '../contract/update-organisation-command';
import { MoveOrganisationCommand } from '../contract/move-organisation-command';
import { DeleteOrganisationCommand } from '../contract/delete-organisation-command';
import { getHttpParameters } from 'app/shared/utils';

@Injectable()
export class OrganisationsService {

  private url = environment.APP_ORGANISATION_SERVICE_BASE_URL + '/api/v1/organisations';

  constructor(private http: HttpClient) {
  }

  public getOrganisations(page?: number, size?: number): Observable<PagedResponse<ViewOrganisation>> {
    const pagingParams = (page || size) ? '/?' : ''
    + page ? 'page=' + page : ''
    + (size && page) ? '&' : ''
    + size ? 'size=' + size : '';
    const pagingUrl = this.url + pagingParams;

    return this.http.get<PagedResponse<ViewOrganisation>>(pagingUrl);
  }

  public getOrganisation(organisationId: string): Observable<ViewOrganisation> {
    return this.http.get<ViewOrganisation>(this.url + '/' + organisationId);
  }

  public getOrganisationsByCustomerId(customerId: string, tree?: boolean, excludeOrganisationId?: string): Observable<ViewOrganisation[]> {
    const url = this.url + '/by-customer/' + customerId;
    let params: any = {};
    if (tree === true) {
      params.tree = tree;
    }
    if (excludeOrganisationId !== undefined) {
      params.excludeOrganisationId = excludeOrganisationId;
    }
    let httpParams = getHttpParameters(params);

    return this.http.get<ViewOrganisation[]>(url, {params: httpParams});
  }

  public addOrganisation(command: CreateOrganisationCommand): Observable<string> {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  public updateOrganisation(command: UpdateOrganisationCommand): Observable<string> {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  public moveOrganisation(command: MoveOrganisationCommand): Observable<string> {
    return this.http.post(this.url + '/move', JSON.stringify(command), {responseType: 'text'});
  }

  public deleteOrganisation(command: DeleteOrganisationCommand): Observable<string> {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  public organisationNameInUse(organisationName: string, organisationId?: string): Observable<boolean> {
    let params = {
      'name': organisationName,
      'id': organisationId
    };

    let httpParams = getHttpParameters(params);

    return this.http.get<boolean>(this.url + '/name-in-use', {params: httpParams});
  }

  public organisationNumberInUse(organisationNumber: string, organisationId?: string): Observable<boolean> {
    let params = {
      'number': organisationNumber,
      'id': organisationId
    };

    let httpParams = getHttpParameters(params);

    return this.http.get<boolean>(this.url + '/number-in-use', {params: httpParams});
  }

  public getOrganisationsTreeByCustomer(customerId: string): Observable<ViewOrganisation[]> {
    return this.http.get<ViewOrganisation[]>(`${this.url}/by-customer/${customerId}/tree`);
  }
}
