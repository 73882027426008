import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { RentalBoardComponent } from '../rentals/rental-board/rental-board.component';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { Modules } from '../../../shared/enums/modules.enum';


const rentalRoutes: Routes = [
  {
    path: 'rental',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority],
    children: [
      {
        path: 'list',
        component: RentalBoardComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
          modules: [Modules.RENTAL]
        }
      }
    ]
  }
];

export const rentalRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(rentalRoutes);
