import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faSortAlphaDown, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { MaintenanceTask } from 'app/modules/maintenance/tasks/shared/maintenance-task';
import { MaintenanceTaskCompletedDataSource } from 'app/modules/maintenance/tasks/shared/service/maintenance-task-completed-datasource/maintenance-task-completed.datasource';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { EnrichedAttachedDocument, FileUploaderTypes } from 'app/shared/components/file-upload-component/base/base-file-upload.component';
import { AttachedDocument } from 'app/shared/contract/attached-document.interface';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { FileUtils } from 'app/shared/fileUtils';
import { RoleAuthorityGuardsComponent } from 'app/shared/navigation-guards/role-authority-guards.component';
import { LanguageService } from 'app/shared/services/language.service';
import { UserConfigurationService } from 'app/shared/services/user-configuration.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, delay, EMPTY, finalize, map, of, switchMap, tap } from 'rxjs';

type DocLinkField = 'fileName' | 'fileDescription';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-completed-view-attachment',
  templateUrl: './maintenance-task-completed-view-attachment.component.html',
  styleUrls: ['./maintenance-task-completed-view-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskCompletedViewAttachmentComponent extends RoleAuthorityGuardsComponent implements OnInit, OnChanges {
  @Input() public isActive = false;
  @Input() public task: MaintenanceTask;
  public documentsSortParameter: DocLinkField;
  public images: DocumentLink[];
  public documents: DocumentLink[];
  public readonly faSortAlphaDown: IconDefinition = faSortAlphaDown;
  public readonly fileName: DocLinkField = 'fileName';
  public readonly fileDescription: DocLinkField = 'fileDescription';
  public readonly uploaderType: FileUploaderTypes = 'MAINTENANCE_TASK';
  private waiting = new BehaviorSubject<boolean>(false);

  public readonly isDeleteEnabled = this.waiting.asObservable()
    .pipe(map(isWaiting => !isWaiting && this.hasAuthority(this.authorities.MAINTENANCE_TASK_MANAGE)));
  public readonly isDescriptionEditEnabled = this.isDeleteEnabled;

  constructor(
    private userConfigurationService: UserConfigurationService,
    private maintenanceDataSource: MaintenanceTaskCompletedDataSource,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    authService: KeycloakService,
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.documentsSortParameter = this.getDocumentsSortParameter();
  }

  public ngOnChanges(): void {
    if (this.task && this.isActive) {
      this.getDocuments();
    }
  }

  public changeDocumentsSortParameter(sortParam: DocLinkField): void {
    this.documentsSortParameter = sortParam;
    this.userConfigurationService.saveDocumentsSortConfiguration(sortParam);
    this.sortDocumentsLinks(this.documents, this.documentsSortParameter);
    this.sortDocumentsLinks(this.images, this.documentsSortParameter);
  }

  public onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.maintenanceDataSource.uploadFile(attachedDocument.entityId, AttachedDocument.fromData(attachedDocument))
      .pipe(delay(environment.DELAY_SHORT))
      .subscribe(() => this.getDocuments());
  }

  public onAllUploaded(): void {
    this.cdr.markForCheck();
  }

  public deleteDocument(objectKey: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.fileDelete');
    dialogRef.afterClosed()
      .pipe(
        switchMap(result => result === dialogResults.YES ? of(result) : EMPTY),
        tap(() => this.waiting.next(true)),
        switchMap(() => this.maintenanceDataSource.deleteMaintenanceTaskFile(this.task.id, objectKey)),
        delay(environment.DELAY_SHORT),
        finalize(() => this.waiting.next(false)),
        untilDestroyed(this))
      .subscribe(() => this.getDocuments());
  }

  public saveFileName(updatedDocument: DocumentLink): void {
    this.maintenanceDataSource.updateDocumentName(this.task, updatedDocument).subscribe();
    this.updateDocumentFileName(this.documents, updatedDocument);
    this.updateDocumentFileName(this.images, updatedDocument);
    this.sortDocumentsLinks(this.documents, this.documentsSortParameter);
    this.sortDocumentsLinks(this.images, this.documentsSortParameter);
  }

  public saveDescription(updatedDocument: DocumentLink): void {
    this.maintenanceDataSource.updateDocumentDescription(this.task, updatedDocument).subscribe();
    this.updateDocumentDescription(this.documents, updatedDocument);
    this.updateDocumentDescription(this.images, updatedDocument);
    this.sortDocumentsLinks(this.documents, this.documentsSortParameter);
    this.sortDocumentsLinks(this.images, this.documentsSortParameter);
  }

  private getDocuments(): void {
    if (this.task?.id) {
      this.maintenanceDataSource.getFilesForTask(this.task.id)
        .subscribe(files => {
          this.sortDocumentsLinks(files, this.documentsSortParameter);
          const { documents, images } = FileUtils.splitDocumentLinks(files);
          this.documents = documents;
          this.images = images;
          this.cdr.markForCheck();
        });
    }
  }

  private updateDocumentDescription(documents: DocumentLink[], updatedDocument: DocumentLink) {
    let document = documents.find(givenDocument => givenDocument.documentKey === updatedDocument.documentKey);
    if (!!document) {
      document.fileDescription = updatedDocument.fileDescription;
    }
  }

  private updateDocumentFileName(documents: DocumentLink[], updatedDocument: DocumentLink) {
    let document = documents.find(givenDocument => givenDocument.documentKey === updatedDocument.documentKey);
    if (!!document) {
      document.fileName = updatedDocument.fileName;
    }
  }

  private sortDocumentsLinks(documents: DocumentLink[], sortField: DocLinkField): void {
    if (!documents || !sortField) {
      return;
    }
    documents.sort((a, b) => {
      if (a[sortField] === '') {
        return 1;
      } else if (b[sortField] === '') {
        return -1;
      }
      return a[sortField].localeCompare(b[sortField]);
    });
  }

  private getDocumentsSortParameter(): DocLinkField {
    if (this.userConfigurationService.getDocumentsSortConfiguration()) {
      return this.userConfigurationService.getDocumentsSortConfiguration().sortParameter as DocLinkField;
    }
    return this.fileName;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

}
