import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MaintenanceRule } from 'app/modules/maintenance/rules/shared/contract/maintenance-rule';
import { IntervalPanelFormGroupFactoryService } from 'app/shared/services/interval-panel-form-group-factory/interval-panel-form-group-factory.service';
import { IntervalPanelFormType } from 'app/shared/services/interval-panel-form-group-factory/interval-panel-form-type.enum';



@Pipe({
  name: 'intervalPanelFormGroupFactory'
})
export class IntervalPanelFormGroupFactoryPipe implements PipeTransform {

  constructor(private formFactoryService: IntervalPanelFormGroupFactoryService) {}

  public transform(rule: MaintenanceRule, formType: IntervalPanelFormType): UntypedFormGroup {
    return this.formFactoryService.buildAndPatchForm(formType, rule);
  }

}
