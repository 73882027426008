import { Component, OnInit } from '@angular/core';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { MaintenanceRule } from '../../../../../../maintenance/rules/shared/contract/maintenance-rule';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { EquipmentMaintenanceRuleService } from '../../../../../shared/services/equipment-maintenance-rule.service';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { AssignMaintenanceRuleCommand } from '../../../../../../maintenance/rules/shared/contract/commands/assign-maintenance-rule.command';
import { forkJoin, Subject } from 'rxjs';
import { UnassignMaintenanceRuleCommand } from '../../../../../../maintenance/rules/shared/contract/commands/unassign-maintenance-rule.command';
import { MaintenanceCategory } from 'app/shared/enums/maintenance-category.enum';
import { DatesService } from '../../../../../../../shared/services/dates.service';
import { EquipmentMaintenanceRuleStore } from '../../../../../shared/services/equipment-maintenance-rule.store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { environment } from '../../../../../../../../environments/environment';
import { AssignMaintenanceRuleArrayItem, AssignMaintenanceRuleFormGroup } from './interfaces/form.interface';
import { DimensionUnitConverterPipe } from '../../../../../../../shared/pipes/dimension-unit-converter.pipe';

@UntilDestroy()
@Component({
  selector: 'bh-assign-maintenance-rule-dialog',
  templateUrl: './assign-maintenance-rule-dialog.component.html',
  styleUrls: ['./assign-maintenance-rule-dialog.component.scss']
})
export class AssignMaintenanceRuleDialogComponent implements OnInit {

  public equipment: ViewEquipment;
  public form: FormGroup<AssignMaintenanceRuleFormGroup>;
  public filterControl: FormControl<string>;
  public loading = true;
  public assignedRules = new Set<string>();
  public availableRules: MaintenanceRule[];

  public selectedRuleIds: Set<string> = new Set();
  public unselectedRuleIds: Set<string> = new Set();
  public currentSelectedRuleId = new Subject<string>();

  private readonly smallSizeOfAssignments = 3;
  private readonly mediumSizeOfAssignments = 5;
  private readonly bigSizeOfAssignments = 7;
  private readonly DELAY_CUSTOM = 5000;

  constructor(
    private maintenanceRuleService: EquipmentMaintenanceRuleService,
    private maintenanceRuleStore: EquipmentMaintenanceRuleStore,
    private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
    private dialogRef: MatDialogRef<AssignMaintenanceRuleDialogComponent>
  ) { }

  public get rules(): FormArray<FormGroup<AssignMaintenanceRuleArrayItem>> {
    return this.form.controls.rules;
  }

  public ngOnInit() {
    this.setUpForm();
    this.loadRules();
  }

  public setCurrentSelectedRuleId(event: MatCheckboxChange, ruleId: string) {
    this.currentSelectedRuleId.next(event.checked ? ruleId : null);
  }

  public assignRules(): void {
    const rules = this.rules.value;
    const rulesToAssign = rules
    .filter(value => value.selected && !this.assignedRules.has(value.ruleId))
    .map(value => new AssignMaintenanceRuleCommand(
        value.ruleId,
        this.equipment.equipmentId,
        DatesService.ospDateTimeAtStartOfDay(value.periodCalculationBase),
        this.dimensionUnitConverterPipe.toSystemDimensionUnit(value.mileageCalculationBase, 'km'),
        value.operatingHoursCalculationBase));

    const rulesToUnassign = rules
    .filter(value => !value.selected && this.assignedRules.has(value.ruleId))
    .map(value => new UnassignMaintenanceRuleCommand(value.ruleId, this.equipment.equipmentId))

    forkJoin([
      this.maintenanceRuleService.assignAllMaintenanceRuleToEquipment(rulesToAssign),
      this.maintenanceRuleService.unassignAllMaintenanceRuleToEquipment(rulesToUnassign)
    ]).pipe(delay(this.calculateDelayRelatedToAssignmentsSize(this.selectedRuleIds.size)))
      .subscribe(r => {
      this.dialogRef.close();
    })
  }

  // BEUT-20454 Custom calculation of delay related to the number of assignments
  public calculateDelayRelatedToAssignmentsSize(sizeAssignments: number): number {
    if (sizeAssignments <= 1) {
      return environment.DELAY_SHORT;
    } else if (sizeAssignments >= this.smallSizeOfAssignments && sizeAssignments < this.mediumSizeOfAssignments) {
      return environment.DELAY_LONG;
    } else if (sizeAssignments >= this.mediumSizeOfAssignments && sizeAssignments < this.bigSizeOfAssignments) {
      return environment.DELAY_LONGEST
    } else if (sizeAssignments >= this.bigSizeOfAssignments) {
      return this.DELAY_CUSTOM;
    }
  }

  public hasAnyInterval(group: FormGroup<AssignMaintenanceRuleArrayItem>): boolean {
    return group.controls.periodCalculationBase.enabled
      || group.controls.operatingHoursCalculationBase.enabled
      || group.controls.mileageCalculationBase.enabled;
  }

  public ruleAdded(group: FormGroup<AssignMaintenanceRuleArrayItem>): boolean {
    return group.value.selected && !this.assignedRules.has(group.value.ruleId);
  }

  public ruleRemoved(group: FormGroup<AssignMaintenanceRuleArrayItem>): boolean {
    return !group.value.selected && this.assignedRules.has(group.value.ruleId);
  }

  public trackByRuleForm(index: number, group: FormGroup<AssignMaintenanceRuleArrayItem>): string {
    return `${group.value.ruleId}_${group.value.selected}`;
  }

  public onCancel() {
    this.dialogRef.close();
  }

  private setUpForm() {
    this.form = new FormGroup({
      rules: new FormArray([])
    });

    this.filterEvent();
  }

  private filterEvent(): void {
    this.filterControl = new FormControl<string>('');
    this.filterControl.valueChanges
    .pipe(debounceTime(150), distinctUntilChanged(), untilDestroyed(this))
    .subscribe(filterTerm => this.maintenanceRuleStore.filterTypesBy(filterTerm));
    this.filterControl.setValue('');
  }

  private loadRules() {
    this.maintenanceRuleStore.availableMaintenanceRules
    .pipe(untilDestroyed(this))
    .subscribe((rules: MaintenanceRule[]) => {
      rules.sort((a, b) => a.ruleName.localeCompare(b.ruleName));
      this.availableRules = rules
    });

    this.maintenanceRuleService.findAssignedToEquipment(this.equipment.equipmentId).subscribe(assignedRules => {
      this.assignedRules = new Set(assignedRules.map(rule => rule.maintenanceRuleId));
      this.maintenanceRuleStore.filteredMaintenanceRules
      .pipe(untilDestroyed(this))
      .subscribe((rules: MaintenanceRule[]) => {
        this.createForm(rules)
      });

      this.maintenanceRuleStore.loadMaintenanceRules();
    });
  }

  private createForm(filteredRules: MaintenanceRule[]): void {
    const filteredRulesIds = new Set<string>(filteredRules.map(({ maintenanceRuleId }) => maintenanceRuleId));

    const formGroups = this.availableRules.map((rule, i) => {
      const oldGroup = this.rules.controls.find(ctrl => ctrl.value.ruleId === rule.maintenanceRuleId);
      const group: FormGroup<AssignMaintenanceRuleArrayItem> = new FormGroup({
        ruleId: new FormControl<string>(rule.maintenanceRuleId),
        name: new FormControl<string>(rule.ruleName),
        category: new FormControl<MaintenanceCategory>(rule.maintenanceCategory),
        selected: new FormControl<boolean>(
          (this.assignedRules.has(rule.maintenanceRuleId) || this.selectedRuleIds.has(rule.maintenanceRuleId))
          && !this.unselectedRuleIds.has(rule.maintenanceRuleId)),
        periodCalculationBase: oldGroup
          ? oldGroup.controls.periodCalculationBase
          : new FormControl<Date>({value: new Date(), disabled: true}, Validators.required),
        mileageCalculationBase: oldGroup
          ? oldGroup.controls.mileageCalculationBase
          : new FormControl<number>({value: 0, disabled: true}, [Validators.required, Validators.min(0)]),
        operatingHoursCalculationBase: oldGroup
          ? oldGroup.controls.operatingHoursCalculationBase
          : new FormControl<number>({value: 0, disabled: true}, [Validators.required, Validators.min(0)]),
      });

      group.controls.selected.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(selected => {
        if (selected) {
          this.selectedRuleIds.add(rule.maintenanceRuleId);
          this.unselectedRuleIds.delete(rule.maintenanceRuleId);
        } else {
          this.unselectedRuleIds.add(rule.maintenanceRuleId);
          this.selectedRuleIds.delete(rule.maintenanceRuleId);
        }
        if (selected && !this.assignedRules.has(rule.maintenanceRuleId)) {
          if (rule.periodInterval) {
            group.controls.periodCalculationBase.enable();
          }
          if (rule.operatingHoursInterval) {
            group.controls.operatingHoursCalculationBase.enable();
          }
          if (rule.mileageInterval) {
            group.controls.mileageCalculationBase.enable();
          }
        } else {
          group.controls.periodCalculationBase.disable();
          group.controls.operatingHoursCalculationBase.disable();
          group.controls.mileageCalculationBase.disable();
        }
      });

      if (!filteredRulesIds.has(rule.maintenanceRuleId)) {
        group.disable();
      }
      return group;
    });

    this.form = new FormGroup({
      rules: new FormArray(formGroups)
    });
    this.loading = false;
  }
}
