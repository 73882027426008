import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MaintenanceTaskSearch } from '../shared/maintenance-task';
import { Observable } from 'rxjs';
import { MaintenanceTaskDataSource } from '../shared/service/maintenance-task.datasource';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchFilterActivatedView } from '../../../../shared/contract/activated-view.interface';
import { FilterType } from '../../../../shared/contract/filter/filter-type';
import { MaintenanceFiltersService } from '../shared/service/maintenance-filters.service';
import { ButtonInfo } from '../../../../shared/contract/button-info';
import { UpdateFilterCommand } from '../../../../shared/contract/filter/update-filter-command.interface';
import { environment } from '../../../../../environments/environment';
import { DisplayService } from 'app/shared/display.service';

@UntilDestroy()
@Component({
  selector: 'bh-maintenance-task-list',
  templateUrl: './maintenance-task-list.component.html',
  styleUrls: ['./maintenance-task-list.component.scss']
})
export class MaintenanceTaskListComponent implements SearchFilterActivatedView, OnInit, OnDestroy {
  public tasks: Observable<MaintenanceTaskSearch[]>;
  public filters: FilterType[] = this.maintenanceFiltersService.filters;
  public readonly onFiltersUpdated: EventEmitter<any> = this.maintenanceFiltersService.onFiltersUpdated;
  public readonly onTotalCountUpdated: EventEmitter<number> = new EventEmitter<number>();
  public searchForm: UntypedFormGroup;
  public routeButton: ButtonInfo = null;
  public readonly searchSuggestionsField = 'maintenanceTaskListSuggestions';


  constructor(
    public maintenanceTaskStore: MaintenanceTaskDataSource,
    public readonly displayService: DisplayService,
    private route: ActivatedRoute,
    private maintenanceFiltersService: MaintenanceFiltersService,
  ) {}

  private get searchControl(): AbstractControl {
    return this.searchForm.get('terms');
  }

  public ngOnInit(): void {
    this.maintenanceTaskStore.updateFilters();
    this.tasks = this.maintenanceTaskStore.connect();
    this.maintenanceTaskStore.loadTasks();
    this.handleRouting();
  }

  public ngOnDestroy(): void {
  }

  public updateFilters(commands: UpdateFilterCommand[]): void {
    this.maintenanceTaskStore.updateFilterParams(commands);
    this.maintenanceTaskStore.loadTasks(0);
  }

  private updateOnSearch(searchTerm: string): void {
    this.maintenanceTaskStore.term = searchTerm;
    this.maintenanceTaskStore.resetPageIndex();
    this.maintenanceTaskStore.loadTasks(0);
  }

  public onSearchFormType(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(environment.DELAY_SHORTEST),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(term => this.updateOnSearch(term));

    this.searchForm.patchValue({terms: this.maintenanceTaskStore.term}, {emitEvent: false});
  }

  public onSearchFormClick(): void {
    this.updateOnSearch(this.searchControl.value);
  }

  private handleRouting(): void {
    if (!this.isCurrentTaskSelected()) {
      this.maintenanceTaskStore.selectCurrentOrDefault();
    } else {
      this.displayService.displayTableAndDetails();
    }
  }

  public openDetails(): void {
    this.displayService.displayTableAndDetails();
  }

  private isCurrentTaskSelected(): boolean {
    return Boolean(this.route.snapshot.firstChild?.params?.id);
  }
}
