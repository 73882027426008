import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DispositionProject } from 'app/modules/disposition/shared/disposition-project';
import * as moment from 'moment';

@Pipe({
  name: 'formatProjectStartDate'
})
export class FormatProjectStartDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(project: DispositionProject, format = 'DD.MM.YYYY'): string {
    if (project && project.projectStart) {
      return moment(project.projectStart).format(format);
    }
    if (project && !project.projectStart) {
      return this.translate('general.labels.openStart');
    }
    return '';
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
