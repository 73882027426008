import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartWidget } from '../basic-widget/chart-widget';
import { LastCompletedTasksGridsterItem } from '../contract/last-completed-tasks-gridster-item.interface'
import { IOptionsList, IOptionPlaceholder } from 'app/shared/contract/selection-tree-options.interface';
import { LastCompletedTasksWidgetDatasource } from './last-completed-tasks-widget.datasource';
import { map } from 'rxjs';
import { applySelectionToOptionList, convertGroupedEquipmentTypesToOptionList, defaultPlaceholder, getOptionPlaceholder, getSelectedFromOptionList, viewOrganisationToOptionsList } from 'app/shared/services/selection-tree.converter';
import { DashboardStore } from '../../dashboard/dashboard.store';

@UntilDestroy()
@Component({
  selector: 'bh-last-completed-tasks-widget',
  templateUrl: './last-completed-tasks-widget.component.html',
  styleUrls: ['./last-completed-tasks-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastCompletedTasksWidgetComponent extends ChartWidget implements OnInit {

  @Input() public override widget: LastCompletedTasksGridsterItem;
  public readonly tasks = this.widgetDatasource.tasks;
  public organisationList: IOptionsList[] = [];
  public organisationPlaceholder: IOptionPlaceholder = defaultPlaceholder();
  public typeList: IOptionsList[] = [];
  public typePlaceholder: IOptionPlaceholder = defaultPlaceholder();

  constructor(
    private widgetDatasource: LastCompletedTasksWidgetDatasource,
    private dashboardStore: DashboardStore,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initListeners();
    this.widgetDatasource.initWidgetData(this.widget);
  }

  public onConfigurationSave(index: number): void {
    if (this.index === index) {
      this.widget.organisationIds = getSelectedFromOptionList(this.organisationList);
      this.widget.equipmentTypeCategories = getSelectedFromOptionList(this.typeList);
      this.widgetDatasource.loadWidgetData(this.widget);
    }
  }

  public onTitleIconClick(): void {
    this.widgetDatasource.navigateToTaskCompletedList();
  }

  public onNavigateToTaskCompleted(taskId: string): void {
    if (taskId) {
      this.widgetDatasource.navigateToTaskCompletedList(taskId);
    }
  }

  public preventDefaultEvent(event: Event): void {
    event.preventDefault();
  }

  public organisationListClosed(): void {
    this.organisationPlaceholder = getOptionPlaceholder(this.organisationList);
  }

  public typeListClosed(): void {
    this.typePlaceholder = getOptionPlaceholder(this.typeList);
  }

  private initListeners(): void {
    this.initListenerOrganisations();
    this.initListenerEquipmentTypes();
    this.initListenerEditModeChange();
  }

  private initListenerOrganisations(): void {
    this.widgetDatasource.availableOrganisations
      .pipe(
        map(viewOrganisationToOptionsList),
        map(list => applySelectionToOptionList(list, this.widget.organisationIds)),
        untilDestroyed(this))
      .subscribe(list => {
        this.organisationList = list;
        this.organisationPlaceholder = getOptionPlaceholder(list);
      });
  }

  private initListenerEquipmentTypes(): void {
    this.widgetDatasource.availableEquipmentTypeCategories
      .pipe(
        map(convertGroupedEquipmentTypesToOptionList),
        map(list => applySelectionToOptionList(list, this.widget.equipmentTypeCategories)),
        untilDestroyed(this))
      .subscribe(list => {
        this.typeList = list;
        this.typePlaceholder = getOptionPlaceholder(list);
      });
  }

  private initListenerEditModeChange(): void {
    this.dashboardStore.editMode
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());
  }

}
