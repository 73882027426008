import { Injectable } from '@angular/core';
import { TaskCompleted } from 'app/modules/maintenance/tasks/shared/task-completed.interface';
import { EquipmentTask } from '../../../contract/equipment-task';
import { EquipmentMaintenanceTaskTab } from '../../contracts/equipment-maintenance-task-tab.enum';
import { MaintenanceTaskDataSource } from '../../maintenance-task.datasource';
import { MaintenanceTaskCompletedDataSource } from '../maintenance-task-completed.datasource';
import { AdapterMaintenanceTaskCompletedDataSource } from './tab-controller/adapter-datasource/adapter-maintenance-task-completed-datasource.class';
import { AdapterMaintenanceTaskDataSource } from './tab-controller/adapter-datasource/adapter-maintenance-task-datasource.class';
import { TabController } from './tab-controller/tab-controller.class';

type TabConfigs = {
  [key in EquipmentMaintenanceTaskTab]: TabController<EquipmentTask | TaskCompleted>;
}

@Injectable()
export class EquipmentMaintenanceTaskTabContentControllerService {
  private currentEquipmentId: string = null;
  private currentTab: EquipmentMaintenanceTaskTab = null;
  private readonly tabConfigs: TabConfigs = {
    [EquipmentMaintenanceTaskTab.CURRENT]: new TabController(
      new AdapterMaintenanceTaskDataSource(this.maintenanceTaskDataSource)
    ),
    [EquipmentMaintenanceTaskTab.HISTORY]: new TabController(
      new AdapterMaintenanceTaskCompletedDataSource(this.maintenanceTaskCompletedDataSource)
    ),
  }

  public readonly tasks = this.tabConfigs[EquipmentMaintenanceTaskTab.CURRENT].data;
  public readonly completedTasks = this.tabConfigs[EquipmentMaintenanceTaskTab.HISTORY].data;

  constructor(
    private maintenanceTaskDataSource: MaintenanceTaskDataSource,
    private maintenanceTaskCompletedDataSource: MaintenanceTaskCompletedDataSource
  ) { }

  public equipmentIdChanged(equipmentId: string, updateIfNecessary = true): void {
    if (equipmentId !== this.currentEquipmentId) {
      this.currentEquipmentId = equipmentId;
      this.needResetAll();
    } else {
      this.needUpdateAll();
    }

    if (updateIfNecessary) {
      this.update();
    }
  }

  public tabChanged(tab: EquipmentMaintenanceTaskTab, updateIfNecessary = true): void {
    if (tab !== this.currentTab) {
      this.currentTab = tab;
      if (updateIfNecessary) {
        this.update();
      }
    }
  }

  public needUpdate(tab: EquipmentMaintenanceTaskTab): void {
    this.tabConfigs[tab].isNeedUpdate = true;
  }

  public needUpdateAll(): void {
    Object.keys(this.tabConfigs).forEach(tabName => this.tabConfigs[tabName].isNeedUpdate = true);
  }

  private needResetAll(): void {
    Object.keys(this.tabConfigs).forEach(tabName => {
      this.tabConfigs[tabName].isNeedReset = true;
      this.tabConfigs[tabName].cleanData();
    });
  }

  private update(): void {
    if (this.currentTab && this.currentEquipmentId) {
      this.tabConfigs[this.currentTab]?.update(this.currentEquipmentId);
    }
  }

}
