<form cdkDropList [formGroup]="formArray" class="list-container" [ngClass]="containerBoundaryClassName" (cdkDropListDropped)="drop($event)">
  <div *ngFor="let control of formArray.controls; let index = index"
    class="drag-item"
    cdkDragBoundary=".{{containerBoundaryClassName}}"
    cdkDrag>
    <div class="drag-custom-placeholder" *cdkDragPlaceholder></div>
    <div class="drag-handle icon-container" cdkDragHandle>
      <mat-icon>more_vert</mat-icon>
    </div>
    <mat-form-field class="status-input">
      <input matInput
        [formControl]="control"
        [maxlength]="maxInputLength"
        bhRemoveWhitespaces
        [onlySelfControlChanges]="false">
      <mat-icon
        matSuffix
        *ngIf="showInfoIcon && index === 0"
        class="hoverable-icon"
        [matTooltip]="infoIconTooltipText"
        [matTooltipDisabled]="!infoIconTooltipText">
        info
      </mat-icon>
      <mat-icon matSuffix *ngIf="value[index].isNew">fiber_new</mat-icon>
      <mat-error *ngIf="control.hasError('required')">{{ 'shared.validation.requiredField'|translate }}</mat-error>
      <mat-error *ngIf="control.hasError('maxlength')">{{ 'shared.validation.maxCharacters'|translate:{ value: maxInputLength } }}</mat-error>
      <mat-error *ngIf="control.hasError(errorNames.DUPLICATES)">{{ 'shared.validation.duplicate'|translate }}</mat-error>
    </mat-form-field>
    <div class="icon-container" fxFlex="38px">
      <mat-icon *ngIf="value[index] | isRemovable : value" class="hoverable-icon" (click)="onDelete.next(value[index].id)">delete</mat-icon>
    </div>
  </div>
</form>
<div class="action-section" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" [ngClass]="{ 'disabled': isMaxAmountReached }">
  <div [matTooltip]="'modules.fieldManagement.statusCategories.maxStatusAmount'|translate" [matTooltipDisabled]="!isMaxAmountReached">
    <button mat-mini-fab
      class="custom-button-add"
      (click)="addNew()"
      [disabled]="isMaxAmountReached">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <span class="label">{{'modules.fieldManagement.statusCategories.addStatus'|translate}}</span>
</div>
