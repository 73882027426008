import { LanguageService } from './../../../../../../shared/services/language.service';
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { PerformanceType } from '../../../../contract/performance-type.enum';
import {
  GuardedNavigableInputComponent
} from '../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import {
  EquipmentInvoiceDeleteDialogComponent
} from '../../../../shared/equipment-invoice-delete-dialog/equipment-invoice-delete-dialog.component';
import { DeleteEquipmentInvoiceCommand } from '../../../../contract/delete-equipment-invoice-command';
import { HttpErrorResponse } from '@angular/common/http';
import {
  EquipmentInvoiceDetailsComponent
} from '../../../../shared/equipment-invoice-details/landscape/equipment-invoice-details.component';
import { EquipmentInvoice } from '../../../../contract/equipment-invoice.interface';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { ViewEquipmentCostOverview } from '../../../../contract/view-equipment-cost-overview.interface';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewEquipmentCosts } from '../../../../contract/view-equipment-costs.interface';
import { filter } from 'rxjs/operators';
import { isDefined } from '../../../../../../shared/utils';
import { dialogResults } from '../../../../../../shared/enums/dialogResults.enum';
import { RouterHistory } from '../../../../../../shared/router-history';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-cost',
  templateUrl: './equipment-view-cost.component.html',
  styleUrls: ['./equipment-view-cost.component.scss']
})
export class EquipmentViewCostComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy, AfterViewChecked {

  // TODO: add proper icons
  private invoiceTypeIcons = [
    {
      invoiceType: PerformanceType.Wartung,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Reparatur,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Reifenschaden,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Verschleiss,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Gewaltschaden,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Nachrüstung,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Miete,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Sonstiges,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Freimeldung,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Fullservice,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Finanzierung,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Leasing,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Versicherung,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Maschinenbruchversicherung,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Energiekosten,
      icon: 'repeat'
    },
    {
      invoiceType: PerformanceType.Prüfung,
      icon: 'build'
    },
    {
      invoiceType: PerformanceType.Kauf,
      icon: 'repeat'
    }
  ];
  public yearMonthsNavigation: { year: string, months: string[] }[] = [];
  public equipment: ViewEquipment;
  public costOverview: ViewEquipmentCostOverview;
  viewChecked = false;
  public performanceTypes = Object.keys(PerformanceType).sort();
  private linkedInvoices: string[] = [];

  constructor(public equipmentStore: EquipmentsDataSource,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private dialog: MatDialog,
              private equipmentCheckerService: EquipmentCheckerService,
              private languageService: LanguageService,
              private matomoTracker: MatomoTracker) {
    super(authService, router, route, routerHistory);
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_COSTS);
  }

  public ngOnInit(): void {
    this.subscribeToCosts();
    this.getEquipment();
  }

  public ngOnDestroy(): void {
    // for @TakeUntilDestroy
  }

  public isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public invoiceDeletable(invoiceId: string): boolean {
    return !this.linkedInvoices.includes(invoiceId);
  }

  public jumpTo(year: string, month: string): void {
    window.location.hash = year + '-' + month;
  }

  public getInvoiceTypeIcon(performanceType: string): string {
    let invoiceTypeIconObj = this.invoiceTypeIcons.find(invoiceTypeIcon => Object.is(performanceType, invoiceTypeIcon.invoiceType));

    return invoiceTypeIconObj && invoiceTypeIconObj.icon;
  }

  public selectPerformanceType(performanceType: string): void {
    const selectedFilter = performanceType === '' ? null : performanceType;

    this.getCosts(this.equipment.equipmentId, selectedFilter);
  }

  public displayInvoiceDetails(invoice: EquipmentInvoice, imported?: boolean): void {
    let dialogRef = this.dialog.open(EquipmentInvoiceDetailsComponent);

    dialogRef.componentInstance.invoiceId = invoice.invoiceId;
    if (imported) {
      dialogRef.componentInstance.invoiceNumber = invoice.invoiceNumber;
    }
    dialogRef.componentInstance.equipmentId = this.equipment.equipmentId;
  }

  public editInvoice(invoiceId: string): void {
    this.router.navigate([`equipments/${this.equipment.equipmentId}/edit-invoice/${invoiceId}`]);
  }

  public deleteInvoice(invoiceId: string): void {
    let dialogRef = this.dialog.open(EquipmentInvoiceDeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        let cmd = new DeleteEquipmentInvoiceCommand();

        cmd.equipmentId = this.equipment.equipmentId;
        cmd.invoiceId = invoiceId;

        this.equipmentStore.deleteInvoice(cmd).subscribe(
            null,
            (error: HttpErrorResponse) => console.log(error)
        );
      }
    });
  }

  public equipmentAddInvoice(): void {
    this.router.navigate([`/equipments/${this.equipment.equipmentId}/add-invoice`]);
  }

  public showCostOverviewField(field: string): boolean {
    return this.costOverview && this.costOverview[field] > 0;
  }

  /*
   * TODO: Remove when updating angular material solves the problem
   * The current version of angular material produces a flash of unstyled content when expansion panels are being rendered.
   * Disabling the animation on the panels fixes the problem, however, the animations are desired nonetheless.
   * To prevent the UI from exposing unexpected behavior while still keeping animations, the animations are activated on the
   * AfterViewChecked lifecycle hook, after the iterable elements have been initialized.
   *
   * Possibly related issue: https://github.com/angular/material2/issues/13870
   */
  public ngAfterViewChecked(): void {
    if (!this.viewChecked && this.yearMonthsNavigation.length > 0) {
      // Changing the value in setTimeout prevents expressionchangedafterithasbeencheckederror
      setTimeout(() => this.viewChecked = true);
    }
  }

  public tooltipSum(costType: string): string {
    return `${this.translate('modules.equipment.cost.sumInvoiceWithPerformanceType')} ${costType}`;
  }

  public tooltipSumLifeCycle(): string {
    return `${this.translate('modules.equipment.cost.sumInvoiceWithPerformanceType')}
      ${this.translate('general.maintenance.s')},
      ${this.translate('modules.equipment.performanceType.repair.s')},
      ${this.translate('modules.equipment.performanceType.tireDamage.s')},
      ${this.translate('modules.equipment.performanceType.wear')},
      ${this.translate('general.maliciousDamage.s')},
      ${this.translate('modules.equipment.performanceType.retrofitting')},
      ${this.translate('modules.equipment.performanceType.rentalFee')},
      ${this.translate('modules.equipment.performanceType.others')},
      ${this.translate('general.inspection.s')},
      ${this.translate('modules.equipment.performanceType.financing')},
      ${this.translate('modules.equipment.performanceType.machineBreakdownInsurance.s')},
      ${this.translate('modules.equipment.performanceType.fullService')},
      ${this.translate('modules.equipment.performanceType.vacancy')},
      ${this.translate('modules.equipment.performanceType.leasing')}
      ${this.translate('general.or')}
      ${this.translate('modules.equipment.performanceType.insurance')}`;
  }

  public tooltipSumPerOperatingHour(): string {
    return `(${this.translate('general.maintenance.pl')},
    ${this.translate('modules.equipment.performanceType.wear')},
    ${this.translate('modules.equipment.performanceType.repair.pl')},
    ${this.translate('modules.equipment.performanceType.tireDamage.pl')},
    ${this.translate('general.inspection.pl')}) pro
    ${this.translate('general.operatingHour.s')}`
  }

  public tooltipLast(costType: string): string {
    return `${this.translate('modules.equipment.cost.lastInvoiceWithPerformanceType')} ${costType}`;
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private getEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(
        untilDestroyed(this),
        filter(res => isDefined(res))
    )
    .subscribe((res: ViewEquipment) => {
      this.equipment = res;
      this.getCosts(this.equipment.equipmentId);
      this.getCostOverview(this.equipment.equipmentId);
      this.getLinkedInvoices(this.equipment.equipmentId);
    });
  }

  private getLinkedInvoices(equipmentId: string): void {
    this.equipmentStore.getLinkedInvoices(equipmentId)
    .subscribe(res => { this.linkedInvoices = res; });
  }

  private getCostOverview(equipmentId: string): void {
    this.equipmentStore.getCostOverview(equipmentId)
    .pipe(
        untilDestroyed(this),
        filter(res => isDefined(res))
    )
    .subscribe((res: ViewEquipmentCostOverview) => this.costOverview = res);
  }

  private getCosts(equipmentId: string, performanceType?: string): void {
    this.equipmentStore
    .getCosts(equipmentId, performanceType);
  }

  private subscribeToCosts(): void {
    this.equipmentStore.costs
    .pipe(
        untilDestroyed(this),
        filter(res => isDefined(res))
    )
    .subscribe((res: ViewEquipmentCosts[]) => {
      this.yearMonthsNavigation = [];

      res.forEach((costEntry: ViewEquipmentCosts) => {
        const i = this.yearMonthsNavigation.findIndex(x => x.year === costEntry.year);

        if (i > -1) {
          this.yearMonthsNavigation[i].months.push(costEntry.month);
        } else {
          this.yearMonthsNavigation.push({year: costEntry.year, months: [costEntry.month]});
        }
      });
    });
  }
}
