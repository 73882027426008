import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { LanguageService } from '../../../../../../../../shared/services/language.service';
import { ViewProjectAmount } from '../../../../../../contract/view-project-amount.interface';
import { DisplayService } from '../../../../../../../../shared/display.service';
import { Router } from '@angular/router';
import { TransferBackToStockDialogComponent } from '../../../../../../shared/transfer-back-to-stock-dialog/transfer-back-to-stock-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDataSource } from '../../../../../../shared/project.datasource';
import { ViewProject } from '../../../../../../contract/view-project.interface';
import { Authorities } from '../../../../../../../../shared/enums/authorities.enum';
import { KeycloakService } from '../../../../../../../../core/keycloak';
import { getBrowserName } from 'app/shared/utils';
import { BrowserName } from 'app/shared/enums/browser-name.enum';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { ChangeTypeOfUseIconsService } from '../../../../../../shared/change-type-of-use-icons.service';
import { TypeOfUseStatus } from '../../../../../../shared/enums/type-of-use-status.enum';

@Component({
  selector: 'bh-bulk-item-table',
  templateUrl: './bulk-item-table.component.html',
  styleUrls: ['./bulk-item-table.component.scss']
})
export class BulkItemTableComponent implements OnInit {

  @Input()
  public dataSource: ViewProjectAmount[];

  public readonly faArrowRight: IconDefinition = faArrowRight;

  public canSeeLinkBulkItem = false;
  public canSeeLinkStock = false;

  public bulkItemColumns: string[] = [
    'bulkItemName',
    'amount',
    'edit'
  ];
  public isFirefox: boolean;

  constructor(public iconService: ChangeTypeOfUseIconsService,
              protected languageService: LanguageService,
              protected router: Router,
              private displayService: DisplayService,
              public projectStore: ProjectDataSource,
              private dialog: MatDialog,
              private authService: KeycloakService) { }

  ngOnInit(): void {
    if (!this.authService.hasAuthority(Authorities.ASSET_FROM_PROJECT_TRANSFER)) {
      this.bulkItemColumns = [
        'bulkItemName',
        'amount'
      ];
    }
    this.isFirefox = getBrowserName() === BrowserName.FIREFOX;

    if (this.authService.hasAuthority(Authorities.BULK_ITEMS_VIEW)) {
      this.canSeeLinkBulkItem = true;
    }
    if (this.authService.hasAuthority(Authorities.STOCK_VIEW)) {
      this.canSeeLinkStock = true;
    }
  }

  get project(): ViewProject {
    return this.projectStore.project();
  }

  public navigateToBulkItemStocks(bulkItemId: string): void {
    this.displayService.displayTableAndDetails();
    this.router.navigate(['/assets/bulk-item/list', bulkItemId, 'general']);
  }

  public navigateToStock(stockId: string): void {
    this.router.navigate(['sites/stocks/list', stockId, 'inventory']);
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public openTransferToStock(amount: ViewProjectAmount): void {
    const dialogRef = this.dialog.open(TransferBackToStockDialogComponent);
    dialogRef.componentInstance.projectAmount = amount;
    dialogRef.componentInstance.transferItemType = amount.transferItemType;
    dialogRef.componentInstance.projectId = this.project.projectId;
    dialogRef.afterClosed().subscribe(res => {
      if (res === 'update') {
        this.projectStore.getProjectAmounts(this.project.projectId);
        this.projectStore.setCurrentProject(this.project.projectId);
      }
    });
  }

  public resolveStatusClass(reason: TypeOfUseStatus): string {
    switch (reason) {
      case TypeOfUseStatus.STANDSTILL:
        return 'standstill';
      case TypeOfUseStatus.FREE_OF_CHARGE:
        return 'free-of-charge';
      case TypeOfUseStatus.IN_USE:
        return 'in-use';
      case TypeOfUseStatus.READY_TO_PICKUP:
        return 'ready-to-pickup';
    }
  }

  public getUnit(rowItem): string {
    return rowItem.bulkItemUnit ?? rowItem.transferItemUnit;
  }
}
