import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { SidenavService } from '../shared/sidenav.service';
import { Router } from '@angular/router';
import { KeycloakService } from '../../../../core/keycloak';
import { BaseSidenavComponent } from '../base/base-sidenav.component';
import { PlatformLocation } from '@angular/common';
import { OnlineStatusService } from '../../../../shared/services/online-status.service';
import { Alarm } from 'app/modules/notifications/shared/interfaces/alarm.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { NotificationDataSource } from '../../../notifications/shared/services/notification.datasource';
import { EquipmentsService } from '../../../equipment/shared/equipments.service';
import { Modules } from '../../../../shared/enums/modules.enum';
import { MessagingService } from '../../../../shared/messaging.service';
import { CustomDeviceDetectorService } from '../../../../shared/services/custom-device-detector.service';
import { RouterHistory } from '../../../../shared/router-history';
import {CustomersService} from '../../../organisation/shared/customers.service';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-mobile-sidenav',
  templateUrl: './mobile-sidenav.component.html',
  styleUrls: ['./mobile-sidenav.component.scss']
})
export class MobileSidenavComponent extends BaseSidenavComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('matSidenavContainer', { static: true }) matSidenavContainer: MatSidenavContainer;

  items = {
    LANDINGPAGE: 'mobile/landingpage',
    ALARMS: 'mobile/notifications/alarms',
    EQUIPMENTS: 'mobile/equipments/list',
    PROJECTS: 'mobile/projects/list',
    USER_SETTINGS: 'mobile/users/settings',
    DISPOSITION: 'mobile/disposition/list'
  };

  alarms: Alarm[];
  assignmentRequests = 0;

  constructor(protected sidenavService: SidenavService,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              protected router: Router,
              protected deviceService: CustomDeviceDetectorService,
              protected location: PlatformLocation,
              protected messagingService: MessagingService,
              private onlineStatusService: OnlineStatusService,
              protected notificationStore: NotificationDataSource,
              private equipmentsService: EquipmentsService,
              protected  customersService: CustomersService,
              private routerHistory: RouterHistory,
              protected matomoTracker: MatomoTracker,
  ) {
    super(authService, dialog, router, sidenavService, messagingService, deviceService, customersService, matomoTracker);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sidenavService.setSidenav(this.sidenav);
    this.matSidenavContainer.backdropClick.subscribe(() => {
      if (this.location.pathname === '/mobile/sidenav') {
        this.routerHistory.goBack('');
      }
    });
    this.sidenavService.sidenavStateChanged
    .pipe(untilDestroyed(this))
    .subscribe(opened => {
      if (opened) {
        this.getBadgeForAssignmentRequests();
      }
    });
    this.getAlarms();
    this.getBadgeForAssignmentRequests();
  }

  ngOnDestroy(): void {
  }

  get isOnline(): boolean {
    return this.onlineStatusService.onlineStatus;
  }

  private getBadgeForAssignmentRequests(): void {
    if (this.authService.hasModule(Modules.DISPOSITION) && !this.authService.isSuperOrPartnerAdmin()) {
      this.equipmentsService.getRequestedAssignmentAmount()
        .pipe(untilDestroyed(this))
        .subscribe(value => this.assignmentRequests = value);
    }
  }

  private getAlarms(): void {
    this.notificationStore.currentActive
    .pipe(untilDestroyed(this))
    .subscribe(alarms => {
      if (alarms) {
        this.alarms = alarms;
      }
    });
  }
}
