import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { AttachedImage } from './contract/attached-image.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFile, faFileArchive, faFileExcel, faFileImage, faFilePdf, faFileWord } from '@fortawesome/pro-light-svg-icons';
import { HttpResponse } from '@angular/common/http';
import { ImageLink } from '../modules/equipment/contract/image-link.interface';
import { AttachedDocument } from './contract/attached-document.interface';
import { DocumentLink } from './contract/document-link.interface';
import { DocuwareDocument } from './contract/docuware-document.interface';
import { TopfactDocument } from './contract/topfact-document.interface';
import { Observable } from 'rxjs';

// noinspection TypeScriptUnresolvedReference
export class FileUtils {
  public static readonly allowedImageMimeTypes: string[] = [
    'image/jpeg',
    'image/png',
  ];

  public static readonly allowedExtendedImageMimeTypes: string[] = [
    ...this.allowedImageMimeTypes,
    'image/webp',
    'image/gif',
  ];
  public static readonly globalDirectories: string[] = [
    'equipmenttype',
  ];

  public static isPicture(file: ImageLink | DocumentLink | FileLikeObject | File | AttachedDocument): boolean {
    let mimeType = this.getMimeType(file);
    return this.allowedImageMimeTypes.indexOf(mimeType) !== -1;
  }

  public static isPictureV2(file: ImageLink | DocumentLink | FileLikeObject | File | AttachedDocument): boolean {
    let mimeType = this.getMimeType(file);
    return this.allowedExtendedImageMimeTypes.indexOf(mimeType) !== -1;
  }

  private static getMimeType(file: ImageLink | DocumentLink | FileLikeObject | File | AttachedDocument): string {
    let mimeType: string;
    if (this.isImageLink(file)) {
      mimeType = file.mimeType;
    } else if (this.isDocumentLink(file) || this.isAttachedDocument(file)) {
      mimeType = file.fileType;
    } else if (this.isFileLikeObject(file) || this.isFile(file)) {
      mimeType = file.type;
    }
    return mimeType;
  }

  public static isDocument(mimeType: string): boolean {
    return this.allowedImageMimeTypes.indexOf(mimeType) === -1;
  }

  public static getUrlFromFileItem(fileItem: FileItem): string {
    return URL.createObjectURL(fileItem.file.rawFile as unknown as Blob);
  }

  public static isAttachedImage(object: any): object is AttachedImage {
    return !!(object && object.imageKey);
  }

  public static getAttachmentFileTypeIcon(file: ImageLink | DocumentLink | AttachedDocument): IconDefinition {
    if (this.isPicture(file)) {
      return faFileImage;
    }
    if (!file.fileName) {
      return faFile;
    }
    const fileName: string = file.fileName.toLowerCase();
    const fileType = fileName.split('.').slice(-1).pop();
    return this.getFileTypeIcon(fileType);
  }

  public static getDocuwareFileTypeIcon(file: DocuwareDocument) {
    const fileType = file.contentType.split('/').slice(-1).pop();
    return this.getFileTypeIcon(fileType);
  }

  public static getTopfactFileTypeIcon(file: TopfactDocument) {
    return this.getFileTypeIcon(file.contentType);
  }

  public static splitDocumentLinks(docs: DocumentLink[]): { documents: DocumentLink[], images: DocumentLink[] } {
    const documents: DocumentLink[] = [];
    const images: DocumentLink[] = [];
    (docs || []).forEach(doc => (FileUtils.isPictureV2(doc) ? images : documents).push(doc));
    return { documents, images };
  }

  private static getFileTypeIcon(contentType: string): IconDefinition {
    switch (contentType) {
      case 'pdf':
        return faFilePdf;
      case 'doc':
      case 'docx':
        return faFileWord;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
      case 'gif':
        return faFileImage;
      case 'zip':
        return faFileArchive;
      default:
        return faFile;
    }
  }

  public static downloadFile(response: HttpResponse<any>, openInNewTab?: boolean, fileName?: string): void {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const url: string = window.URL.createObjectURL(response.body);
    const link: HTMLAnchorElement = document.createElement('a');
    const fileNameProperty: string = contentDispositionHeader.split(';')[2];
    const filenameString: string = fileNameProperty.split('=')[1];
    const filename: string =  fileName == null ? filenameString.split('"')[1] : fileName;

    if (openInNewTab) {
      window.open(url);
    }

    link.href = url;
    link.download = filename;
    link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  public static downloadDocument(fileUrl: string, fileName: string): void {
    new Observable((observer) => {
      let xhr = new XMLHttpRequest();
      xhr.open('get', fileUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.readyState === 4) {
          observer.next(xhr.response);
          observer.complete();
        }
      };
      xhr.send();
    }).subscribe((blob: any) => {
      let url: string = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
      window.URL.revokeObjectURL(url);
      link.remove();
    });
  }

  public static isDocumentLink(file: any): file is DocumentLink {
    return !!file.fileType && !!file.fileExists;
  }

  public static filterAndSortImages(documentLinks: DocumentLink[], thumbnailKey?: string): DocumentLink[] {
    if (!documentLinks) {
      return documentLinks;
    }

    documentLinks = documentLinks.filter(doc => doc?.documentKey && FileUtils.isPictureV2(doc));
    documentLinks.sort((a, b) =>
        this.getTime(a?.uploadDate) - this.getTime(b?.uploadDate));
    const thumbnailIndex = documentLinks.findIndex(image => image?.documentKey === thumbnailKey);
    if (thumbnailIndex > 0) {
      documentLinks.unshift(documentLinks.splice(thumbnailIndex, 1)[0]);
    }
    return documentLinks;
  }

  private static getTime(date: Date): number {
    return date != null ? new Date(date).getTime() : 0;
  }

  private static isImageLink(file: any): file is ImageLink {
    return !!file.mimeType;
  }

  private static isAttachedDocument(file: any): file is AttachedDocument {
    return !!file.fileType;
  }

  private static isFileLikeObject(file: any): file is FileLikeObject {
    return !!file.type && !!file.rawFile;
  }

  private static isFile(file: any): file is File {
    return !!file.type;
  }

  public static encodeDocumentKey(documentKey: string): string {
    if (!documentKey) {
      return documentKey
    }

    let documentKeyParts = documentKey.split('/');
    return documentKeyParts.map(part => encodeURIComponent(part)).join('/');
  }
}
