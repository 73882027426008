import { Utils } from 'app/shared/utils';
import { LanguageService } from '../../../../../../../../shared/services/language.service';
import { EquipmentInvoice } from '../../../../../../contract/equipment-invoice.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'bh-extended-invoice-info',
  templateUrl: './extended-invoice-info.component.html'
})
export class ExtendedInvoiceInfoComponent {
  @Input() invoice: EquipmentInvoice;
  constructor(private utils: Utils,
              private languageService: LanguageService) { }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  public getDuration(): string {
    return this.invoice && this.invoice.performanceDuration
      ? this.utils.parseFromDurationToString(this.invoice.performanceDuration)
      : null;
  }
}
