import { environment } from 'environments/environment';
import { delay, filter } from 'rxjs/operators';
import { DisplayService } from 'app/shared/display.service';
import { LanguageService } from 'app/shared/services/language.service';
import { TransportItemType } from '../../../../../shared/enums/transport-item-type.enum';
import { KeycloakService } from 'app/core/keycloak';
import { TransportTaskPriority } from '../../../../../shared/enums/transport-task-priority.enum';
import { TransportTaskChecker } from 'app/modules/transportation/shared/services/transport-task-checker.service';
import { TransportView } from '../../../../../contracts/transport/transport-view.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TransportListDatasource } from 'app/modules/transportation/shared/services/transport-list.datasource';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { Router } from '@angular/router';
import { TransportItemLocationType } from 'app/modules/transportation/shared/enums/transport-item-location-type.enum';
import { TransportItemTypeResolverPipe } from 'app/modules/transportation/shared/pipes/transport-item-type-resolver.pipe';
import { ContactType } from 'app/modules/contact/shared/enums/contact-type.enum';
import { TransportDetailViewBase } from 'app/modules/transportation/contracts/transport/transport-detail-view-base.class';
import { ISelectStatusEvent } from 'app/modules/transportation/transport/transport-state-change/select-status-event';
import { BehaviorSubject, Observable } from 'rxjs';
import { TransportStatePipe } from 'app/modules/transportation/shared/pipes/transport-state.pipe';
import { IconPayload } from 'app/shared/contract/icon-payload.interface';
import { MapLocationsComponent } from 'app/shared/modules/map/components/map-locations/map-locations.component';

@UntilDestroy()
@Component({
  selector: 'bh-transport-view-general',
  templateUrl: './transport-view-general.component.html',
  styleUrls: ['./transport-view-general.component.scss'],
})
export class TransportViewGeneralComponent extends TransportDetailViewBase implements OnInit {
  @ViewChild(MapLocationsComponent, { static: true }) map: MapLocationsComponent;

  @Input() set isActive(value: boolean) {
    if (value) {
      setTimeout(() => this.map.refreshPosition());
    }
  }

  public transport: TransportView;
  public isCompatibleTransportType = true;
  public isStateEditAllowed = this.hasAnyAuthority([Authorities.TRANSPORT_TASK_DISPOSITION, Authorities.TRANSPORT_TASK_MANAGE]);
  public readonly emptyValue = '—';
  public readonly priorities = Object.keys(TransportTaskPriority).map(key => TransportTaskPriority[key]);
  public readonly authorities = Authorities;
  public readonly transportItemLocationType = TransportItemLocationType;
  public readonly isPriorityReadonly = true;
  private readonly transportItemPlaceholderMap = {
    [TransportItemLocationType.START]: this.translate('modules.transportation.base.startAddress'),
    [TransportItemLocationType.TARGET]: this.translate('modules.transportation.base.destinationAddress'),
    [TransportItemLocationType.PROJECT]: this.translate('modules.transportation.base.relatedProject'),
  };

  private _isStateEditable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isStateEditable: Observable<boolean> = this._isStateEditable.asObservable();

  constructor(
      private transportDataSource: TransportListDatasource,
      private displayService: DisplayService,
      router: Router,
      languageService: LanguageService,
      transportItemTypeResolver: TransportItemTypeResolverPipe,
      transportStatePipe: TransportStatePipe,
      authService: KeycloakService,
  ) {
    super(router, languageService, transportItemTypeResolver, transportStatePipe, authService);
  }

  public ngOnInit(): void {
    this.initListeners();
  }

  public getTransportItemPlaceholder(locationType: TransportItemLocationType): string {
    return this.transportItemPlaceholderMap[locationType];
  }

  public resolveIconTransportItem(itemType: TransportItemType, contactType: ContactType): IconPayload {
    return this.transportItemTypeResolver.resolveIconByType(itemType, contactType);
  }

  public updateTransportTaskState(event: ISelectStatusEvent): void {
    this.transportDataSource.setNewStatusOfTransportTask(this.transport, event.state, event.date)?.pipe(
        delay(environment.DELAY_SHORTEST),
        untilDestroyed(this),
    ).subscribe(() => {
      this.transportDataSource.updateCurrentAndListing();
      this.transportDataSource.updateFilters();
    });
  }

  public updateHasStateChangeOptions(event: boolean): void {
    this._isStateEditable.next(event && this.isStateEditAllowed);
  }

  private updateTransportSetting(): void {
    if (this.transport) {
      this.isCompatibleTransportType =
          TransportTaskChecker.isCompatibleAssignedVehicleType(this.transport, this.transport.transportType);
    } else {
      this.isCompatibleTransportType = true;
      this._isStateEditable.next(false);
    }
  }

  private initListeners(): void {
    this.currentTransportListener();
    this.transportDisplayModeListener();
  }

  private currentTransportListener(): void {
    this.transportDataSource.currentTransport
    .pipe(untilDestroyed(this))
    .subscribe(transport => {
      this.transport = transport;
      this.updateTransportSetting();
    });
  }

  private transportDisplayModeListener(): void {
    this.displayService.isTableFullscreenChanges
    .pipe(
        filter(displayOption => !displayOption),
        untilDestroyed(this))
    .subscribe(() => setTimeout(() => this.map.refreshPosition()));

  }
}
