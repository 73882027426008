<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <bh-mobile-sub-header [title]="equipment?.equipmentName || equipment?.equipmentModel"
                        [subtitle]="equipment?.equipmentSerialNumber"
                        [navigateToList]="true">
  </bh-mobile-sub-header>

  <mat-expansion-panel class="m-view-details-menu">
    <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
      {{currentViewMenu.name}}
    </mat-expansion-panel-header>

    <div class="m-view-details-menu__content">
      <ng-container *ngFor="let menu of viewMenus">
        <div class="menu-item"
             *ngIf="menu.condition"
             [routerLink]="[menu.url]"
             (click)="setMenu(menu)">
          <span>{{menu.name}}</span>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>

  <div class="m-view-wrapper" fxFlex>
    <router-outlet></router-outlet>
  </div>
</div>
