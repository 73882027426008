import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AssetListTypeResolver } from '../../asset-list-component/asset-list-type-resolver';
import { RouterHistory } from 'app/shared/router-history';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'app/core/keycloak';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { BaseSearchFilterComponent } from '../base/base-search-filter.component';
import { faFilter, faPlus, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChooseAddProcessDialogComponent } from '../../choose-add-process-dialog/choose-add-process-dialog.component';
import { Chip } from 'app/shared/contract/chip';
import { FilterType } from 'app/shared/contract/filter/filter-type';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { LanguageService } from 'app/shared/services/language.service';
import { matomoCategories } from '../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';
import { SearchFilterActivatedView } from 'app/shared/contract/activated-view.interface';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FilterConfig } from 'app/shared/contract/filter/filter-configs.enum';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { TabService } from '../../../tab.service';
import { SearchFilterDatasource } from '../service/search-filter.datasource';
import { SearchFilterChipsFactory } from '../search-filter-chips-factory/search-filter-chips-factory.class';
import { UpdateFilterCommand } from 'app/shared/contract/filter/update-filter-command.interface';
import { TimeRangeFilterDatasource } from '../service/time-range-filter.datasource';
import moment from 'moment';
import { DateFormatByLocation } from 'app/shared/date-format-by-location';
import { TIME_RANGE_DATE_TYPES } from 'app/shared/contract/time-range-date-types';

@Component({
  selector: 'bh-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@UntilDestroy()
export class SearchFilterComponent extends BaseSearchFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('filterForm', {static: false}) filterForm: ElementRef;
  @ViewChild('emptyList', {static: false}) formDiv: ElementRef;
  @ViewChild(MatAutocompleteTrigger, {
    read: MatAutocompleteTrigger,
    static: false,
  }) filterInputTrigger: MatAutocompleteTrigger;
  public readonly faPLus: IconDefinition = faPlus;
  public readonly faFilter: IconDefinition = faFilter;
  public readonly faSearch: IconDefinition = faSearch;
  public chips: Chip[] = [];
  public timeRangeDate = '';
  public timeRangeType = '';
  public inputWidth: Observable<number>;
  public displayShowMore: boolean;
  public foundCount: number;
  public formHeight: number;
  public formOverflow: string;
  public extraChips: number;
  public placeholderString: string;
  public routeButton: ButtonInfo;
  public child: SearchFilterActivatedView;
  public filterSearchTerm = '';
  public isFilterDialogActivated = false;
  public readonly filterConfig = FilterConfig;
  public suggestionField = ''
  private optionsList = {
    fullWidth: '1 1 100%',
    filterWithSearcAndRoutehWidth: '1 1 65%',
    searchWithFilterAndRouteWidth: '1 1 35%',
    filterWithSearchWidth: '1 1 65%',
    searchWithFilterWidth: '1 1 calc(35% + 45px)',
  };
  public formFlexOptions = {
    filterForm: this.optionsList.filterWithSearcAndRoutehWidth,
    searchForm: this.optionsList.searchWithFilterAndRouteWidth,
  };
  public options: string[];
  public readonly FilterTypes = FilterTypes;

  private currentPageUrl: string;
  private recalculateSize = new Subject();
  private ASSETS = 'assets/equipment';
  private TRANSFER = 'transfer/equipment-transfer-list';
  private TRANSFER_LOG = 'analytics/transfer-log';
  private USAGE_HISTORY = 'analytics/usage-history';
  private chipsFactory = new SearchFilterChipsFactory();

  private get selectedCategory(): string {
    const splittedUr = this.routerHistory.getCurrentUrl().split('/');
    switch (`${splittedUr[1]}/${splittedUr[2]}`) {
      case this.ASSETS:
        return matomoCategories.EQUIPMENT_LIST;
      case this.TRANSFER:
        return matomoCategories.EQUIPMENT_TRANSFER_LIST;
    }
  }

  private get formDivWrapper(): any {
    return this.formDiv?.nativeElement?.getElementsByClassName('mat-mdc-form-field-flex')?.[0];
  }

  constructor(
    public optionResolver: AssetListTypeResolver,
    public tabService: TabService,
    protected authService: KeycloakService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerHistory: RouterHistory,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private matomoTracker: MatomoTracker,
    private timeRangeFilterDatasource: TimeRangeFilterDatasource,
    private searchFilterDatasource: SearchFilterDatasource,
  ) {
    super(authService, router, route, routerHistory);
  }

  @HostListener('document:mousedown ', ['$event.target'])
  public onMouseDown(target): void {
    const autocomplete = document.getElementsByClassName('main-grid').length !== 0 ?
      document.getElementsByClassName('main-grid')[0] : undefined;

    const clickedInsideAutocomplete = autocomplete !== undefined ? autocomplete.contains(target) : false;
    if (!this.hideFilterForm) {
      const clickedInsideForm = this.filterForm.nativeElement.contains(target);
      if (!clickedInsideForm && !clickedInsideAutocomplete) {
        if (this.formOverflow !== 'hidden') {
          this.collapseFilterForm();
        }
        this.filterInputTrigger.closePanel();
      }
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setInitOptions();
  }

  public ngAfterViewInit(): void {
    this.onWindowResize();
    this.recalculateSize.next(null);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public navigate(): void {
    this.router.navigate([this.routeButton.route]);
  }

  public search(): void {
    this.child.onSearchFormClick();
  }

  public openAddMenu(): void {
    this.matomoTracker.trackEvent(matomoCategories.ASSET_LIST, matomoActions.ADD_ASSET_DIALOG_OPEN);
    this.dialog.open(ChooseAddProcessDialogComponent);
  }

  public onActivate(componentRef: SearchFilterActivatedView): void {
    setTimeout(() => this.processChildComponent(componentRef), 0);
  }

  public createExistedChips(filters: FilterType[]): void {
    this.chips = this.chipsFactory.convert(filters);
    this.checkChipsSize();
  }

  public deleteChip(chip: Chip, $event?: Event): void {
    if ($event) {
      $event.stopPropagation();
    }

    const commands: UpdateFilterCommand[] = [
      chip,
      ...(chip.subChips || [])
    ].map(({ filterType, storeFilter: storeName }) => ({ newValue: false, filterType, storeName }));
    this.searchFilterDatasource.changeFilter(commands);
  }

  public checkChipsSize(): void {
    // setTimeout is used to prevent a calculation before rendering
    if (!this.hideFilterForm && this.formDivWrapper) {
      setTimeout(() => {
        let height = this.formDivWrapper.getBoundingClientRect().height;
        this.displayShowMore = (height > this.filterConfig.FORM_HEIGHT) && (this.formOverflow === 'hidden');
        if (this.formOverflow !== 'hidden') {
          this.formHeight = height < this.filterConfig.FORM_HEIGHT ? this.filterConfig.FORM_HEIGHT : height;
          if (height < this.filterConfig.FORM_HEIGHT) {
            this.collapseFilterForm();
          }
        }
        this.countExtraChips();
      }, 0);
    }
  }

  public openPanel(): void {
    if (this.displayShowMore) {
      this.expandFilterForm();
    }
    setTimeout(() => this.filterInputTrigger.openPanel(), 0);
  }

  public expandFilterForm(): void {
    this.displayShowMore = false;
    let height = this.formDivWrapper.getBoundingClientRect().height;
    this.formOverflow = 'initial';
    this.formHeight = height;
  }

  public collapseFilterForm(): void {
    this.formHeight = this.filterConfig.FORM_HEIGHT;
    this.formOverflow = 'hidden';
    this.checkChipsSize();
  }

  public resetFilters(): void {
    this.searchFilterDatasource.resetFilter();
    this.timeRangeFilterDatasource.triggerClearFilterEvent();
  }

  public updatePlaceholder(opened: boolean): void {
    if (this.isFilterDialogActivated) {
      this.sendFiltersReportToMatomo();
    }
    this.isFilterDialogActivated = opened;
    this.placeholderString = opened ? '' : this.languageService.getInstant('general.filter').toUpperCase();
  }

  public showAddButton(): boolean {
    return this.canCreateNewEntity() && this.isDisplayedOnPage();
  }

  public showRouteButton(): boolean {
    return Boolean(!this.hideRouteButton && this.routeButton?.route);
  }

  private onWindowResize(): void {
    const resizeEvent = merge(fromEvent(window, 'resize'), this.recalculateSize).pipe(
      untilDestroyed(this),
      filter(() => this.filterForm && this.filterForm.nativeElement),
      tap(() => this.checkChipsSize()),
      map(() => this.filterForm.nativeElement.getBoundingClientRect()),
      share(),
    );

    this.inputWidth = resizeEvent.pipe(map(({width}) => width));
  }

  private transferLogTimeRangeParamsSubscription(): void {
    this.timeRangeFilterDatasource.transferLogTimeRangeParams.subscribe(params => {
      this.timeRangeDate = this.getTimeRangeDate(
        params.fromDate,
        params.toDate,
        DateFormatByLocation(this.languageService.getCurrentLocale()),
      );

      this.timeRangeType = this.getTimeRangeType(params.dateType);
    });
  }

  private usageHistoryTimeFrameParamsSubscription(): void {
    this.timeRangeFilterDatasource.usageHistoryTimeFrameParams.subscribe(params => {
      this.timeRangeDate = this.getTimeRangeDate(
        params.fromDate,
        params.toDate,
        DateFormatByLocation(this.languageService.getCurrentLocale()),
      );

      this.timeRangeType = '';
    });
  }

  private getTimeRangeDate(fromDate: string, toDate: string, format): string {
    return moment(fromDate).format(format) + ' - ' + moment(toDate).format(format);
  }

  private getTimeRangeType(type: string): string {
    switch(type) {
      case TIME_RANGE_DATE_TYPES.CREATION_DATE:
        return this.languageService.getInstant('general.filterTimerange.byCreationDate');
      case TIME_RANGE_DATE_TYPES.TRANSFER_DATE:
        return this.languageService.getInstant('general.filterTimerange.byTransferDate');
    }
  }

  private countExtraChips(): void {
    this.extraChips = 0;

    const chipListElement = document.getElementsByClassName('custom-chip-list')[0];
    if (!chipListElement) {
      return;
    }

    const {
      top: wrapperTopCoordinate,
      right: wrapperRightCoordinate,
    } = chipListElement?.getBoundingClientRect();
    let chipsElements = document.getElementsByClassName('custom-chip');
    let j = (chipsElements?.length || 0) - 1;

    while (j >= 0) {
      let chip = chipsElements[j].getBoundingClientRect();
      if (chip.top - wrapperTopCoordinate <= this.filterConfig.EXTRA_CHIP_TOP_LIMIT) {
        const {width: hintWidth} = document.getElementsByClassName('search-hint')[0].getBoundingClientRect();
        this.extraChips = !this.isFilterDialogActivated && (chip.right + hintWidth > wrapperRightCoordinate)
          ? chipsElements.length - j
          : chipsElements.length - (j + 1);
        break;
      }
      j--;
    }
  }

  private processChildComponent(componentRef: SearchFilterActivatedView): void {
    this.child = componentRef;
    if (!this.hideFilterForm) {
      this.searchFilterDatasource.updateFilters = (commands) => {
        this.child.updateFilters(commands);
      };
      this.searchFilterDatasource.pushFilters(this.child.filters);

      this.child.onTotalCountUpdated
      .pipe(untilDestroyed(this))
      .subscribe((value: number) => {
        this.foundCount = value;
      });

      this.child.onFiltersUpdated
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.searchFilterDatasource.pushFilters(this.child.filters)
      });
    }

    const childSearchFormValue = this.child.searchForm?.value;
    this.child.searchForm = this.searchForm;
    if (childSearchFormValue) {
      this.child.searchForm.patchValue(childSearchFormValue);
    }
    this.child.onSearchFormType();

    this.routeButton = this.child.routeButton;
    this.setFormFlexOptions();
    this.recalculateSize.next(null);
    this.suggestionField = this.child.searchSuggestionsField;
  }

  private sendFiltersReportToMatomo(): void {
    this.createSelectedFiltersList()
        .forEach((number, filterName) => {
          const action = `${matomoActions.FILTER}-${filterName.toLowerCase().replace('_', '-')}=${number}`;
          if (action && this.selectedCategory) {
            this.matomoTracker.trackEvent(this.selectedCategory, action);
          }
        });
  }

  private createSelectedFiltersList(): Map<string, string> {
    const filters = new Map();
    Object.keys(this.chips).forEach((key) => {
      if (this.chips[key] && (this.chips[key].length > 0)) {
        this.chips[key].forEach((el) => {
          filters.has(el.filterType)
          ? filters.set(el.filterType, (filters.get(el.filterType) + 1))
          : filters.set(el.filterType, 1);
        })
      }
    });
    return filters;
  }

  private setFormFlexOptions(): void {
    if (this.showRouteButton()) {
      this.formFlexOptions.searchForm = this.hideFilterForm
        ? this.optionsList.fullWidth
        : this.optionsList.searchWithFilterAndRouteWidth;

      this.formFlexOptions.filterForm = this.hideSearchForm
        ? this.optionsList.fullWidth
        : this.optionsList.filterWithSearcAndRoutehWidth;

    } else {
      this.formFlexOptions.searchForm = this.hideFilterForm
        ? this.optionsList.fullWidth
        : this.optionsList.searchWithFilterWidth;

      this.formFlexOptions.filterForm = this.hideSearchForm
        ? this.optionsList.fullWidth
        : this.optionsList.filterWithSearchWidth;
    }
  }

  private optionsSubscription(): void {
    this.tabService.availableTabs.pipe(untilDestroyed(this)).subscribe(list => this.options = list);
  }

  private filtersChangeSubscription(): void {
    this.searchFilterDatasource.filtersChange
      .pipe(untilDestroyed(this))
      .subscribe(filters => this.createExistedChips(filters));
  }

  private setInitOptions(): void {
    this.hideRouteButton = Boolean(this.route.snapshot.data['hideRouteButton']);
    this.hideSearchForm = Boolean(this.route.snapshot.data['hideSearchForm']);
    this.hideFilterForm = Boolean(this.route.snapshot.data['hideFilterForm']);
    this.showTimeRangeFilter = Boolean(this.route.snapshot.data['showTimeRangeFilter']);
    this.showDateTypeControl = Boolean(this.route.snapshot.data['showDateTypeControl']);
    this.currentPageUrl = this.router.url.slice(1);
    this.timeRangeFilterDatasource.changeDatePickerDisplayStatus(this.showTimeRangeFilter);
    this.timeRangeFilterDatasource.changeDateTypeControlDisplayStatus(this.showDateTypeControl);
    this.timeRangeType = this.getTimeRangeType(this.timeRangeFilterDatasource.getTransferLogTimeRangeParams().dateType);
    this.setFormFlexOptions();
    this.routeButton = new ButtonInfo();
    this.chips = [];
    this.displayShowMore = false;
    this.formHeight = this.filterConfig.FORM_HEIGHT;
    this.formOverflow = 'hidden';
    this.extraChips = 0;
    this.placeholderString = this.languageService.getInstant('general.filter').toUpperCase();
    this.tabService.setAvailableTabs(this.routerHistory.getCurrentUrl());
    if (!this.hideFilterForm) {
      this.filtersChangeSubscription();
    }
    if (this.showTimeRangeFilter && this.currentPageUrl === this.TRANSFER_LOG) {
      this.transferLogTimeRangeParamsSubscription();
    }
    if (this.showTimeRangeFilter && this.currentPageUrl === this.USAGE_HISTORY) {
      this.usageHistoryTimeFrameParamsSubscription();
    }
    this.optionsSubscription();
  }

  public getForm(): any {
    return this.searchForm.get('terms');
  }

}
