import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { KeycloakService } from '../../../../core/keycloak';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from '../../../../shared/router-history';
import { ViewTechnicalField } from '../../contract/view-technical-field.interface';
import { TechnicalFieldsDataSource } from '../../shared/technical-fields.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-technical-field-list',
  templateUrl: 'technical-field-list.component.html',
  styleUrls: ['technical-field-list.component.scss']
})
export class TechnicalFieldsListComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {
  public displayedColumns = [
    'technicalFieldKey',
    'technicalFieldName',
    'technicalFieldUnit',
    'technicalFieldType',
    'technicalFieldEdit'
  ];

  @ViewChild('filter', { static: true }) filter: ElementRef;
  public searchTermControl = new UntypedFormControl();
  public isPermissionOfCreatingTechnicalField: boolean;
  technicalFields: ViewTechnicalField[] = [];
  dataSourceFields: Observable<ViewTechnicalField[]>;

  constructor(private technicalFieldsDataSource: TechnicalFieldsDataSource,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
    this.technicalFieldsDataSource.loadTechnicalFields();
  }

  public ngOnInit(): void {
    this.dataSourceFields = this.technicalFieldsDataSource.filteredTechnicalFields;
    this.filterEvent();
    this.isPermissionOfCreatingTechnicalField = this.checkPermissionOfCreatingTechnicalField();
  }

  public ngOnDestroy(): void {
  }

  public reset(): void {
    this.technicalFieldsDataSource.reset();
    this.searchTermControl.setValue('', { emitEvent: false });
  }

  public search(): void {
    if (this.searchTermControl.value) {
      this.technicalFieldsDataSource.filterTechnicalFieldsBy(this.searchTermControl.value);
    }
  }

  private filterEvent(): void {
    this.searchTermControl.valueChanges
      .pipe(
        debounceTime(300),
        untilDestroyed(this))
      .subscribe(searchTerm => this.technicalFieldsDataSource.filterTechnicalFieldsBy(searchTerm));
  }

  private checkPermissionOfCreatingTechnicalField(): boolean {
    return this.hasAuthority(Authorities.TECHNICAL_FIELD_CREATE);
  }
}
