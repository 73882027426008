import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { LabelValidator } from '../../custom-validators/label.validator';
import { LanguageService } from 'app/shared/services/language.service';

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'bh-label-chips',
  templateUrl: 'label-chips.component.html',
  styleUrls: ['label-chips.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LabelChipsComponent implements OnInit, OnChanges {

  @Input() public labels: string[];
  @Input() public hasAuthority: boolean;
  public selectable = false;
  public removable = true;
  public addOnBlur = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public labelForm: AbstractControl = new UntypedFormControl('');
  public filteredLabels: string[] = [];
  @Input() private customerLabels: string[];
  @Input() public placeholder = 'general.labelChips.placeholder';
  @Input() public tooltip = 'general.labelChips.tooltip';
  @Input() public propertyName = 'general.label.s';
  @ViewChild('labelInput') private labelInput: ElementRef<HTMLInputElement>;
  @Output() private labelAdded: EventEmitter<string> = new EventEmitter<string>();
  @Output() private labelDeleted: EventEmitter<string> = new EventEmitter<string>();

  constructor(private langService: LanguageService) {
  }

  public ngOnInit(): void {
    if (!this.labels) {
      this.labels = [];
    }
    this.labelForm.valueChanges.subscribe(value => {
      this.refreshAvailableLabels(value);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.labelForm.setValidators(LabelValidator.isValidLabel(this.propertyName, this.labels, this.langService));
    this.refreshAvailableLabels(this.labelForm.value);
  }

  public add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;
    if (this.labelForm.invalid || value === '') {
      return;
    }

    this.addValue(value);
    if (input) {
      input.value = '';
    }
  }

  public remove(label: string): void {
    this.labelDeleted.emit(label.trim());
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    this.addValue(value);

  }

  public resetLabelForm(): void {
    this.labelInput.nativeElement.value = '';
  }

  public activateAutocompleteList(event: Event): void {
    this.labelInput.nativeElement.focus();
    event.stopPropagation();
  }

  private refreshAvailableLabels(value: string): void {
    this.filteredLabels = value ? this._filter(value) : this.customerLabels.slice();
  }

  private addValue(value: string): void {
    if ((value || '').trim()) {
      this.labelAdded.emit(this.pickPreviousIfExisting(this.filteredLabels, value));
      this.labelForm.reset('');
    }

    this.labelInput.nativeElement.value = '';
    this.labelForm.setValue('');
  }

  private pickPreviousIfExisting(selection: string[], label: string): string {
    const pickPrevious = selection
    .filter(existingLabel => existingLabel.toLowerCase() === label.toLowerCase());
    return pickPrevious.length === 1 ? pickPrevious[0] : label.trim();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.customerLabels.filter(label => label.toLowerCase().includes(filterValue));
  }
}
