import { Subscription, BehaviorSubject, fromEvent } from 'rxjs';
import { HorizontalScrollOffset } from '../contract/horizontal-scroll-offset.interface';

interface ScrollPosition {
  left: number;
  top: number;
}

export class ScrollTrackerService {
  private scrollContainer: HTMLElement;
  private scrollListener: Subscription;
  private _position: ScrollPosition = { left: 0, top: 0 };
  private _positionLeftChange = new BehaviorSubject<number>(null);
  private _hScrollOffset = new BehaviorSubject<HorizontalScrollOffset>(null);
  private lastHorisontalScrollPosition: number;

  public readonly positionLeftChange = this._positionLeftChange.asObservable();
  public readonly hScrollOffset = this._hScrollOffset.asObservable();

  private get position() {
    return this._position;
  }
  private set position(value: ScrollPosition) {
    if (this.position.left !== value.left) {
      this._positionLeftChange.next(value.left);
    }
    this._position = value;
  }

  constructor(defaultPosition: ScrollPosition) {
    this.position = defaultPosition;
  }

  public releaseData(): void {
    if (this.scrollListener) {
      this.scrollListener.unsubscribe();
    }
  }

  public setLastScrollPositionHorizontal(): void {
    if (this.hasHorizontalScroll()) {
      this.scrollContainer.scrollLeft = this.lastHorisontalScrollPosition || this.position.left;
      this.lastHorisontalScrollPosition = null;
    } else {
      this.lastHorisontalScrollPosition = this.lastHorisontalScrollPosition || this.position.left;
    }
    this.recalculate();
  }

  public subscribeToScrollEvent(scrollContainer: HTMLElement): void {
    this.releaseData();
    this.scrollContainer = scrollContainer;
    this.scrollListener = fromEvent(this.scrollContainer, 'scroll')
      .subscribe(() => this.recalculate());
  }

  private recalculate(): void {
    const { scrollLeft: left, scrollTop: top } = this.scrollContainer;
    this.changeHScrollOffset();
    this.position = { left, top };
  }

  private changeHScrollOffset(): void {
    const containerStyle = getComputedStyle(this.scrollContainer);
    const fullContainerPadding = parseFloat(containerStyle.paddingLeft) + parseFloat(containerStyle.paddingRight);

    const hScroll: HorizontalScrollOffset = {
      left: this.scrollContainer.scrollLeft,
      right: (this.scrollContainer.scrollWidth - fullContainerPadding)
        - this.scrollContainer.scrollLeft
        - (this.scrollContainer.clientWidth - fullContainerPadding),
      scrollSize: this.scrollContainer.scrollWidth - fullContainerPadding
    }
    this._hScrollOffset.next(hScroll);
  }

  private hasHorizontalScroll(): boolean {
    return this.scrollContainer.scrollWidth > this.scrollContainer.clientWidth;
  }
}
