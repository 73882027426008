import { Injectable } from '@angular/core';
import { StockTypeResolver } from 'app/shared/pipes/stock-type.resolver';
import { MapSvgMarkerFactory } from 'app/shared/services/map-svg-marker-factory.service';
import { MapIconPayloadStock } from '../types/map-icon-payload-stock.type';

@Injectable()
export class MapIconResolverStock {

  constructor(
    private markerFactory: MapSvgMarkerFactory,
    private stockTypeResolver: StockTypeResolver,
  ) { }

  public getSvgIcon(stock: MapIconPayloadStock): string {
    return stock?.stockType
      ? this.markerFactory.getSvgUrl({ iconDefinition: this.stockTypeResolver.resolveIcon(stock.stockType) })
      : null;
  }
}
