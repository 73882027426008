import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { MaintenanceTypeListComponent } from '../types/maintenance-type-list/maintenance-type-list.component';
import { MaintenanceTypeAddEditComponent } from '../types/maintenance-type-add-edit/maintenance-type-add-edit.component';
import { MaintenanceTypeResolve } from '../types/shared/service/maintenance-type.resolve';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { Modules } from '../../../shared/enums/modules.enum';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { CanActivateChildLandscape } from '../../../core/guards/can-activate-child-landscape';
import { CanActivateChildModule } from '../../../core/guards/can-activate-child-module';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { MaintenanceRuleAddEditComponent } from '../rules/maintenance-rule-add-edit/maintenance-rule-add-edit.component';
import { MaintenanceRuleResolve } from '../rules/shared/service/maintenance-rule.resolve';
import { EquipmentResolver } from '../../equipment/shared/services/equipment-resolver.service';
import { MaintenanceRuleListComponent } from '../rules/maintenance-rule-list/maintenance-rule-list.component';
import { MaintenanceTaskCompleteComponent } from '../tasks/maintenance-task-complete/maintenance-task-complete.component';
import { MaintenanceTaskResolver } from '../tasks/shared/service/maintenance-task.resolver';
import { MaintenanceTaskListComponent } from '../tasks/maintenance-task-list/maintenance-task-list.component';
import {
  SearchFilterComponent
} from '../../../shared/components/search-filter-component/landscape/search-filter.component';
import { CanActivateChildMobileWebApp } from '../../../core/guards/can-activate-child-mobile-web-app.guard';
import { ContentComponent } from 'app/shared/components/content-component/content.component';
import { MaintenanceTaskCompletedListComponent } from '../tasks/maintenance-task-completed-list/maintenance-task-completed-list.component';
import { MaintenanceTaskCompletedViewComponent } from '../tasks/maintenance-task-completed-list/maintenance-task-completed-view/maintenance-task-completed-view.component';
import { MaintenanceTaskViewComponent } from '../tasks/maintenance-task-list/maintenance-task-view/maintenance-task-view.component';
import { MaintenanceTaskCompletedResolver } from '../tasks/shared/service/maintenance-task-completed.resolver';


const maintenanceRoutes: Routes = [
  {
    path: 'types',
    canActivate: [CanActivateModule],
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape, CanActivateChildModule, CanActivateChildMobileWebApp],
    data: {
      modules: [Modules.SERVICE]
    },
    children: [
      {
        path: 'list',
        component: MaintenanceTypeListComponent,
        data: {
          authorities: [Authorities.MAINTENANCE_TYPE_MANAGE],
        }
      },
      {
        path: 'add',
        component: MaintenanceTypeAddEditComponent,
        data: {
          authorities: [Authorities.MAINTENANCE_TYPE_MANAGE],
        }
      },
      {
        path: 'edit/:id',
        component: MaintenanceTypeAddEditComponent,
        resolve: {
          maintenanceType: MaintenanceTypeResolve
        },
        data: {
          authorities: [Authorities.MAINTENANCE_TYPE_MANAGE],
        }
      }
    ]
  },
  {
    path: 'rules',
    canActivateChild: [CanActivateChildMobileWebApp],
    children: [
      {
        path: 'list',
        component: MaintenanceRuleListComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'add',
        component: MaintenanceRuleAddEditComponent
      },
      {
        path: 'edit/:id',
        resolve: {
          maintenanceRule: MaintenanceRuleResolve
        },
        component: MaintenanceRuleAddEditComponent
      },
      {
        path: 'assign/:id',
        resolve: {
          equipment: EquipmentResolver
        },
        component: MaintenanceRuleAddEditComponent,
      }
    ],
  },
  {
    path: 'tasks',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildMobileWebApp],
    children: [
      {
        path: 'maintenance-list',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.MAINTENANCE_TASK_VIEW]
        },
        children: [
          {
            path: '',
            component: MaintenanceTaskListComponent,
            data: {
              authorities: [Authorities.MAINTENANCE_TASK_VIEW]
            },
            children: [
              {
                path: ':id',
                component: MaintenanceTaskViewComponent,
                data: {
                  authorities: [Authorities.MAINTENANCE_TASK_VIEW]
                },
              }
            ],
          }
        ]
      },
      {
        path: 'maintenance-completed-list',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.MAINTENANCE_TASK_VIEW]
        },
        children: [
          {
            path: '',
            component: MaintenanceTaskCompletedListComponent,
            data: {
              authorities: [Authorities.MAINTENANCE_TASK_VIEW]
            },
            children: [
              {
                path: ':id',
                component: MaintenanceTaskCompletedViewComponent,
                data: {
                  authorities: [Authorities.MAINTENANCE_TASK_VIEW]
                },
              }
            ],
          },
        ]
      },
      {
        path: 'complete/:id',
        resolve: {
          taskInfo: MaintenanceTaskResolver
        },
        component: MaintenanceTaskCompleteComponent,
        data: {
          authorities: [Authorities.MAINTENANCE_TASK_MANAGE],
        },
      },
      {
        path: 'edit/:id',
        resolve: {
          taskInfo: MaintenanceTaskCompletedResolver,
        },
        component: MaintenanceTaskCompleteComponent,
        data: {
          authorities: [Authorities.MAINTENANCE_TASK_MANAGE],
        },
      }
    ]
  }
];

export const  maintenanceRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(maintenanceRoutes);
