import { IAmountStatusPeriod, IAmountStatusPeriodWithId, ViewProjectAmount } from '../../../contract/view-project-amount.interface';
import {ViewEquipmentAmount} from 'app/modules/disposition/contract/view-equipment-amount.interface';
import {ViewAmount} from 'app/modules/disposition/contract/view-amount';
import {TransferItemType} from './transfer-item-type.enum';
import {LocationType} from '../../../../../shared/enums/location-type.enum';
import { ITypeOfUseChangeRequest } from '../../../../dashboard/widgets/type-of-use-change-requests-widget/type-of-use-change-request.interface';
import { RequestStatus } from '../../enums/request-status.enum';

export interface ITransferItemWithValidityStatus {
  status: RequestStatus;
  transferItem: TransferItem;
  errorText?: string;
}

export class TransferItem {

  constructor(
    public id: string,
    public name: string,
    public sourceStockId: string,
    public transferId: string,
    public transferItemType: TransferItemType,
    public equipmentContainerId: string,
    public nonReturnable: boolean,
    public sourceName: string,
    public unit: string,
    public transferAmount: number,
    public minAmount: number,
    public maxAmount: number,
    public thumbnailKey: string,
    public equipmentTypeCategory2: string,
    public transferItemSerialNumber: string,
    public currentLocationType: LocationType,
    public currentChargePeriod: IAmountStatusPeriod,
    public amountStatusPeriods: Record<string, IAmountStatusPeriod>,
    public amountStatusPeriodArray: IAmountStatusPeriodWithId[],
    public currentProjectId: string,
    public projectStart: string | Date,
    public projectEnd: string | Date,
    public lastTransferDate: Date,
    public hiddenOption = false,
    public isActiveContainer = true
    ) {}

  public static fromViewProjectAmount(item: ViewProjectAmount, initialAmount: number, minAmount: number) {
    return new TransferItem(
      item.amountId,
      item.transferItemName,
      item.stockId,
      item.transferItemId,
      item.transferItemType,
      item.equipmentContainerId,
      false,
      item.stockName,
      item.transferItemUnit,
      initialAmount,
      minAmount,
      item.amountAvailable,
      item.thumbnailKey,
      null,
      item.transferItemType === TransferItemType.EQUIPMENT ? item.equipmentCustomerSerialNumber : item.transferItemNumber,
      LocationType.PROJECT,
      item.currentChargePeriod,
      item.amountStatusPeriods,
      null,
      null,
      item.projectStart,
      item.projectEnd,
      item.lastTransferDate,
    );
  }

  public static fromViewAmount(item: ViewAmount, transferAmount: number, minAmount: number): TransferItem {
    return new TransferItem(
      item.amountId,
      item.bulkItemId ? item.bulkItemName : item.equipmentName,
      item.stockId,
      item.bulkItemId ? item.bulkItemId : item.equipmentId,
      item.bulkItemId ? TransferItemType.BULKITEM : TransferItemType.EQUIPMENT,
      item.equipmentContainerId,
      item.nonReturnable,
      item.stockName,
      item.bulkItemUnit,
      transferAmount,
      minAmount,
      item.bulkItemId ? item.amountAvailable : 1,
      item.thumbnailKey,
      item.equipmentTypeCategory2,
      item.bulkItemId ? item.bulkItemNumber : item.equipmentCustomerSerialNumber,
      item.currentProjectId ? LocationType.PROJECT : LocationType.STOCK,
      item.currentChargePeriod,
      item.amountStatusPeriods,
      null,
      item.currentProjectId,
      null,
      null,
      null,
    );
  }

  public static fromViewEquipmentAmount(item: ViewEquipmentAmount): TransferItem {
    return new TransferItem(
      item.amountId,
      item.equipmentName || item.equipmentModel,
      item.stockId,
      item.equipmentId,
      TransferItemType.EQUIPMENT,
      item.containerId,
      false,
      item.stockName,
      null,
      0,
      0,
      1,
      item.thumbnailKey,
      item.equipmentTypeCategory2,
      item.equipmentCustomerSerialNumber,
      LocationType.STOCK,
      item.currentChargePeriod,
      item.amountStatusPeriods,
      null,
      null,
      null,
      null,
      null,
    );
  }

  public static fromITypeOfUseChangeRequest(item: ITypeOfUseChangeRequest): TransferItem {
    return new TransferItem(
      item.amountId,
      item.transferItemName,
      item.sourceStockId,
      item.transferItemId,
      item.transferItemType,
      null,
      null,
      item.sourceStockName,
      null,
      item.amount,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    );
  }

  public matchesStockAndBulkItem(sourceStockId: string, bulkItemId: string): boolean {
    return this.sourceStockId === sourceStockId && this.transferId === bulkItemId;
  }

  public matchesName(searchTerm: string): boolean {
    if (!this.notNullNotBlank(this.name)) {
      return false;
    }
    return this.notNullNotBlank(searchTerm) ?
      this.name.trim().toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1 :
      true;
  }

  public matchesSourceName(searchTerm: string): boolean {
    if (!this.notNullNotBlank(this.sourceName)) {
      return false;
    }
    return this.notNullNotBlank(searchTerm) ?
      this.sourceName.trim().toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1 :
      true;
  }

  public matchesInternalNumber(searchTerm: string): boolean {
    if (!this.notNullNotBlank(this.transferItemSerialNumber)) {
      return false;
    }
    return this.notNullNotBlank(searchTerm) ?
      this.transferItemSerialNumber.trim().toLowerCase().indexOf(searchTerm.trim().toLowerCase()) !== -1 :
      true;
  }

  private notNullNotBlank(term: string): boolean {
    return term != null && term !== '';
  }
}
