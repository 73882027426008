import { NgModule } from '@angular/core';
import {
  TelematicsUnitProfileListComponent
} from './telematics-unit-profile-list/telematics-unit-profile-list.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../../core/custom-material/custom-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { telematicsUnitProfilesRouting } from './telematics-unit-profiles.routing';
import { DigitalMatterProfileDatasource } from './services/digital-matter-profile.datasource';
import { TelematicsUnitProfileEditComponent } from './telematics-unit-profile-edit/telematics-unit-profile-edit.component';

@NgModule({
  declarations: [
    TelematicsUnitProfileListComponent,
    TelematicsUnitProfileEditComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexLayoutModule,
    telematicsUnitProfilesRouting,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    DigitalMatterProfileDatasource
  ],
})
export class TelematicsUnitProfilesModule {
}
