import { SchedulerHighlighter } from 'app/shared/scheduler/scheduler-highlighter';
import { ElementRef } from '@angular/core';

export class SchedulerMoveHighlighter {
  private currentSectionKey: string;
  private currentEventId: string;

  private get isInMove(): boolean {
    return Boolean(this.currentSectionKey);
  }

  constructor(
    private schedulerContainer: ElementRef,
    private inMoveClassName = 'dhx_custom_in_move'
  ) { }

  public appendClass(sectionKey: string, eventId: string): void {
    if (this.currentSectionKey !== sectionKey) {
      this.currentSectionKey = sectionKey;
      this.currentEventId = eventId;
      this.removeAndAppendClass(sectionKey, eventId);
    }
  }

  public refreshClassIfInMove(): void {
    if (this.isInMove) {
      this.removeAndAppendClass(this.currentSectionKey, this.currentEventId);
    }
  }

  public removeClass(): void {
    this.currentSectionKey = this.currentEventId = null;
    this.removeClassFromContainer();
    this.removeClassFromCells();
  }

  private removeAndAppendClass(sectionKey: string, eventId: string): void {
    this.removeClassFromContainer();
    this.removeClassFromCells();
    this.appendClassToContainer(sectionKey);
    this.appendClassToCells(sectionKey);
    this.appendClassToEvent(eventId);
  }

  private removeClassFromContainer(): void {
    this.schedulerContainer.nativeElement.classList.remove(this.inMoveClassName);
  }

  private removeClassFromCells(): void {
    const previousHighlighted = this.schedulerContainer
      .nativeElement
      .getElementsByClassName(this.inMoveClassName) as HTMLCollection;
    Array.from(previousHighlighted).forEach((el) => el.classList.remove(this.inMoveClassName));
  }

  private appendClassToContainer(sectionKey: string): void {
    if (!sectionKey) {
      return;
    }
    this.schedulerContainer.nativeElement.classList.add(this.inMoveClassName);
  }

  private appendClassToCells(sectionKey: string): void {
    if (!sectionKey) {
      return;
    }
    const needHighlightClassName = SchedulerHighlighter.getCellSectionClassName(sectionKey);
    const needHighlight = this.schedulerContainer
      .nativeElement
      .getElementsByClassName(needHighlightClassName) as HTMLCollection;
    Array.from(needHighlight).forEach((el) => el.classList.add(this.inMoveClassName));
  }

  private appendClassToEvent(eventId: string): void {
    const eventElem = this.schedulerContainer
      .nativeElement
      .querySelector(`[event_id='${eventId}']`);
    if (eventElem) {
      eventElem.classList.add(this.inMoveClassName)
    }
  }
}
