import { DatesService } from 'app/shared/services/dates.service';
import { UUID } from 'angular2-uuid';
import { DocumentLink } from 'app/shared/contract/document-link.interface';
import { AttachedDocument } from 'app/shared/contract/attached-document.interface';
import { ViewEquipmentContracts } from '../../../../../contract/view-equipment-contracts.interface';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { ViewEquipment } from '../../../../../contract/view-equipment.interface';
import { SalesContractAmortizationType } from '../../../../../contract/sales-contract-amortization-type.enum';
import { RentalContractType } from '../../../../../contract/rental-contract-type.enum';
import { LanguageService } from 'app/shared/services/language.service';
import { FieldLimit } from 'app/shared/enums/fieldLimit.enum';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EquipmentContractType } from '../../../../../contract/equipment-contract-type.enum';
import { Component, OnInit } from '@angular/core';
import { EquipmentContractTypeResolver } from 'app/modules/equipment/shared/services/equipment-contract-type.resolver';
import { InsuranceContractType } from 'app/modules/equipment/contract/insurance-contract-type.enum';
import { InsuranceContractMileageType } from 'app/modules/equipment/contract/insurance-contract-mileage-type.enum';
import { EquipmentRentalContract } from 'app/modules/equipment/contract/equipment-rental-contract.interface';
import { EquipmentSalesContract } from 'app/modules/equipment/contract/equipment-sales-contract.interface';
import { EquipmentServiceContract } from 'app/modules/equipment/contract/equipment-service-contract.interface';
import { EquipmentInsuranceContract } from 'app/modules/equipment/contract/equipment-insurance-contract.interface';
import { Utils } from 'app/shared/utils';
import { EnrichedAttachedDocument } from 'app/shared/components/file-upload-component/base/base-file-upload.component';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { AddRentalContractCommand } from 'app/modules/equipment/contract/add-rental-contract-command';
import { AddSalesContractCommand } from 'app/modules/equipment/contract/add-sales-contract-command';
import { AddServiceContractCommand } from 'app/modules/equipment/contract/add-service-contract-command';
import { AddInsuranceContractCommand } from 'app/modules/equipment/contract/add-insurance-contract-command';
import { UpdateRentalContractCommand } from 'app/modules/equipment/contract/update-rental-contract-command';
import { UpdateSalesContractCommand } from 'app/modules/equipment/contract/update-sales-contract-command';
import { UpdateServiceContractCommand } from 'app/modules/equipment/contract/update-service-contract-command';
import { UpdateInsuranceContractCommand } from 'app/modules/equipment/contract/update-insurance-contract-command';
import { UpdateLeasingContractCommand } from 'app/modules/equipment/contract/update-leasing-contract-command';
import { Observable } from 'rxjs';
import { LimitationPeriod } from 'app/modules/equipment/contract/limitation-period.enum';
import { EquipmentLeasingContract } from 'app/modules/equipment/contract/equipment-leasing-contract.interface';
import { DateValidator } from 'app/shared/custom-validators/date.validator';
import { AddLeasingContractCommand } from '../../../../../contract/add-leasing-contract-command';
import { GroupFormValidator } from 'app/shared/custom-validators/group-form.validator';
import { faFileContract } from '@fortawesome/pro-regular-svg-icons';
import { DimensionUnitConverterPipe } from '../../../../../../../shared/pipes/dimension-unit-converter.pipe';

@Component({
  selector: 'bh-add-edit-equipment-contract',
  templateUrl: './add-edit-equipment-contract.component.html',
  styleUrls: ['./add-edit-equipment-contract.component.scss']
})
@UntilDestroy()
export class AddEditEquipmentContractComponent implements OnInit {
  // set in parent component
  public equipment: ViewEquipment;
  public updatedContractId: string;
  public updatedContractType: EquipmentContractType;

  public faFileContract = faFileContract;
  public isSavingProcess = false;
  public readonly fieldLimit = FieldLimit;
  public contractTypeControl: UntypedFormControl;
  public rentalContractForm: UntypedFormGroup;
  public salesContractForm: UntypedFormGroup;
  public serviceContractForm: UntypedFormGroup;
  public insuranceContractForm: UntypedFormGroup;
  public leasingContractForm: UntypedFormGroup;

  public readonly contractTypesOptions: string[] = Object.keys(EquipmentContractType);
  public readonly equipmentContractTypes = EquipmentContractType;
  public readonly amortizationTypes = Object.keys(SalesContractAmortizationType);
  public readonly insuranceContractTypes = Object.keys(InsuranceContractType);
  public readonly insuranceContractMileageTypes = Object.keys(InsuranceContractMileageType);
  public readonly rentalContractTypes = RentalContractType;
  public readonly rentalOptions = [
    {label: this.translate('general.units.time.per.month'), value: 'M'},
    {label: this.translate('general.units.time.per.year'), value: 'Y'}
  ];
  public readonly options = [
    {label: this.translate('general.units.time.month.pl'), value: 'M'},
    {label: this.translate('general.units.time.year.pl'), value: 'Y'}
  ];
  public limitationPeriodOptions = [
    {label: this.translate('modules.equipment.contract.contractPeriodTypes.monthly'), value: LimitationPeriod.MONTHLY},
    {label: this.translate('modules.equipment.contract.contractPeriodTypes.yearly'), value: LimitationPeriod.YEARLY},
    {label: this.translate('modules.equipment.contract.contractPeriodTypes.contractPeriod'), value: LimitationPeriod.CONTRACT_PERIOD}
  ];

  private equipmentRentalContracts: EquipmentRentalContract[];
  private equipmentSalesContract: EquipmentSalesContract;
  private equipmentServiceContracts: EquipmentServiceContract[];
  private equipmentInsuranceContracts: EquipmentInsuranceContract[];
  private equipmentLeasingContracts: EquipmentLeasingContract[];
  public documents: AttachedDocument[] = [];

  constructor(public equipmentContractTypeResolver: EquipmentContractTypeResolver,
              private formBuilder: UntypedFormBuilder,
              private equipmentStore: EquipmentsDataSource,
              private utils: Utils,
              private dialogRef: MatDialogRef<AddEditEquipmentContractComponent>,
              private languageService: LanguageService,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe) { }

  public ngOnInit(): void {
    this.buildForms();
    this.getContracts();

    this.contractTypeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resetForm());
  }

  public resetForm(): void {
    this.rentalContractForm.get('rentalContractType').clearValidators();
    this.rentalContractForm.reset({period: this.rentalOptions[0]});
    this.salesContractForm.reset({period: this.options[0]});
    this.serviceContractForm.reset();
    this.insuranceContractForm.reset({insuranceContractMileageType: this.insuranceContractMileageTypes[0]});
    this.leasingContractForm.reset();
    this.documents = [];
  }

  public isContractType(type: EquipmentContractType): boolean {
    return this.contractTypeControl.value === type;
  }

  public leasingDateFilter = (date: Date): boolean => true;

  private setLeasingDateFilter() {
    this.leasingDateFilter = (date: Date): boolean => {
      if (this.equipmentLeasingContracts) {
        return !this.equipmentLeasingContracts.some(({ leasingContractId, contractStartDate, contractEndDate }) =>
          this.updatedContractId !== leasingContractId
          && date >= DatesService.fromIsoStartOfDay(contractStartDate)
          && date <= DatesService.fromIsoStartOfDay(contractEndDate));
      }
      return true;
    }
  }

  public onFileUploaded(attachedDocument: EnrichedAttachedDocument): void {
    this.documents.push(AttachedDocument.fromData(attachedDocument));
  }

  public removeDocument(document: DocumentLink) {
    const index = this.documents.indexOf(document);
    if (index !== -1) {
      this.documents.splice(index, 1);
    }
  }

  public getContractForm(): UntypedFormGroup {
    if (this.isContractType(EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT)
      || this.isContractType(EquipmentContractType.RENTAL_CONTRACT)) {
      return this.rentalContractForm;
    }
    if (this.isContractType(EquipmentContractType.SALES_CONTRACT)) {
      return this.salesContractForm;
    }
    if (this.isContractType(EquipmentContractType.SERVICE_CONTRACT)) {
      return this.serviceContractForm;
    }
    if (this.isContractType(EquipmentContractType.INSURANCE_CONTRACT)) {
      return this.insuranceContractForm;
    }
    if (this.isContractType(EquipmentContractType.LEASING_CONTRACT)) {
      return this.leasingContractForm;
    }
  }

  public save(): void {
    this.isSavingProcess = true;
    if (this.updatedContractId) {
      this.updateContract();
      return;
    }

    this.addContract();
  }

  public getInsuranceEndDate(): Date {
    return this.insuranceContractForm.get('contractEndDate').value;
  }

  public getInsuranceStartDate(): Date {
    return this.insuranceContractForm.get('contractStartDate').value;
  }

  public getRentalStartDate(): Date {
    return this.rentalContractForm.get('rentalStartDate').value;
  }

  public getRentalEndDate(): Date {
    return this.rentalContractForm.get('rentalEndDate').value;
  }

  public getLeasingStartDate(): Date {
    return this.leasingContractForm.get('contractStartDate').value;
  }

  public getLeasingEndDate(): Date {
    return this.leasingContractForm.get('contractEndDate').value;
  }

  private addContract(): void {
    if (this.isContractType(EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT)
      || this.isContractType(EquipmentContractType.RENTAL_CONTRACT)) {
      this.addRentalContract();
    }
    if (this.isContractType(EquipmentContractType.SALES_CONTRACT)) {
      this.addSalesContract();
    }
    if (this.isContractType(EquipmentContractType.SERVICE_CONTRACT)) {
      this.addServiceContract();
    }
    if (this.isContractType(EquipmentContractType.INSURANCE_CONTRACT)) {
      this.addInsuranceContract();
    }
    if (this.isContractType(EquipmentContractType.LEASING_CONTRACT)) {
      this.addLeasingContract();
    }
  }

  private updateContract() {
    if (this.isContractType(EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT)
      || this.isContractType(EquipmentContractType.RENTAL_CONTRACT)) {
      this.updateRentalContract();
    }
    if (this.isContractType(EquipmentContractType.SALES_CONTRACT)) {
      this.updateSalesContract();
    }
    if (this.isContractType(EquipmentContractType.SERVICE_CONTRACT)) {
      this.updateServiceContract();
    }
    if (this.isContractType(EquipmentContractType.INSURANCE_CONTRACT)) {
      this.updateInsuranceContract();
    }
    if (this.isContractType(EquipmentContractType.LEASING_CONTRACT)) {
      this.updateLeasingContract();
    }
  }

  private afterTodayRental = erc => moment(erc.rentalEndDate).isSameOrAfter(moment(), 'day');
  private afterTodayContract = erc => moment(erc.contractEndDate).isSameOrAfter(moment(), 'day');

  private buildForms(): void {
    this.contractTypeControl = new UntypedFormControl('', Validators.required);
    this.buildRentalContractForm();
    this.buildSalesContractForm();
    this.buildServiceContractForm();
    this.buildInsuranceContractForm();
    this.buildLeasingContractForm();
  }

  private buildRentalContractForm(): void {
    this.rentalContractForm = this.formBuilder.group({
      rentalContractNumber: ['', [Validators.required]],
      amount: [null, Validators.min(1)],
      contractPartner: [null],
      rentalStartDate: ['', [Validators.required]],
      rentalEndDate: ['', [Validators.required]],
      rentalMileageLimitPeriod: [null, Validators.min(0)],
      period: [null],
      composedOf: [null],
      rentalContractType: ['', Validators.required],
      comment: [null]
    });
  }

  private buildSalesContractForm(): void {
    this.salesContractForm = this.formBuilder.group({
      salesContractNumber: ['', [Validators.required]],
      contractPartner: [null],
      cost: ['', [Validators.required, Validators.min(0)]],
      amortizationPeriod: [null, [Validators.min(0)]],
      period: [null],
      amortizationType: [null],
      comment: [null]
    });
  }

  private buildServiceContractForm(): void {
    this.serviceContractForm = this.formBuilder.group({
      serviceContractNumber: ['', [Validators.required]],
      contractPartner: [null],
      composedOf: [null],
      monthlyRate: [null, [Validators.min(0)]],
      comment: [null]
    });
  }

  private buildInsuranceContractForm(): void {
    this.insuranceContractForm = this.formBuilder.group({
      insuranceContractNumber: ['', [Validators.required]],
      insuranceContractType: [null],
      contractStartDate: [null],
      contractEndDate: [null],
      costsPerMonth: [null, [Validators.min(0)]],
      mileageLimitPerYear: [null, [Validators.min(0)]],
      insuranceContractMileageType: [null],
      comment: [null]
    });
  }

  private buildLeasingContractForm(): void {
    this.leasingContractForm = this.formBuilder.group({
      leasingContractNumber: [null, Validators.required],
      contractPartner: [null],
      contractStartDate: [null, Validators.required],
      contractEndDate: [null, Validators.required],
      mileageLimit: [null, Validators.min(0)],
      operatingHoursLimit: [null, Validators.min(0)],
      limitationPeriod: [null],
      comment: [null]
    });
  }

  private setLeasingContractFormValidators(): void {
    this.leasingContractForm.setValidators([
      GroupFormValidator.requiredIfOthersNotEmpty('limitationPeriod', ['mileageLimit', 'operatingHoursLimit']),
      DateValidator.intervalOverlapsDateRange(
        'contractStartDate',
        'contractEndDate',
        this.equipmentLeasingContracts
          .filter(({ leasingContractId }) => leasingContractId !== this.updatedContractId)
          .map(({ contractStartDate, contractEndDate }) => ({
              start: DatesService.fromIsoStartOfDay(contractStartDate),
              end: DatesService.fromIsoStartOfDay(contractEndDate)
          }))
    )]);
  }

  private setRentalContractFormValues(): void {
    let rentalContract = this.equipmentRentalContracts.find(rc => rc.rentalContractId === this.updatedContractId);

    const rentalMileageLimitPeriod = rentalContract.rentalMileageLimitPeriod ?
        this.utils.parseFromPeriod(rentalContract.rentalMileageLimitPeriod) : undefined;
    this.rentalContractForm.patchValue({
      rentalContractNumber: rentalContract.rentalContractNumber,
      amount: rentalContract.amount,
      contractPartner: rentalContract.contractPartner,
      rentalStartDate: rentalContract.rentalStartDate,
      rentalEndDate: rentalContract.rentalEndDate,
      rentalMileageLimitPeriod: rentalMileageLimitPeriod ? rentalMileageLimitPeriod.value : '',
      period: rentalMileageLimitPeriod
        ? this.findMatchingOptionFromPeriod(this.rentalOptions, rentalMileageLimitPeriod.period)
        : this.rentalOptions[0],
      composedOf: this.isContractType(EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT)
        ? rentalContract.composedOf
        : null,
      rentalContractType: this.isContractType(EquipmentContractType.RENTAL_CONTRACT)
        ? rentalContract.rentalContractType
        : RentalContractType.FULL_SERVICE,
      comment: rentalContract.comment
    });

    this.getDocumentsFrom(rentalContract);
  }

  private setSalesContractFormValues(): void {
    const amortizationPeriod = this.equipmentSalesContract.amortizationPeriod ?
        this.utils.parseFromPeriod(this.equipmentSalesContract.amortizationPeriod) : undefined;
    this.salesContractForm.patchValue({
      salesContractNumber: this.equipmentSalesContract.salesContractNumber,
      contractPartner: this.equipmentSalesContract.contractPartner,
      cost: this.equipmentSalesContract.cost,
      amortizationPeriod: amortizationPeriod ? amortizationPeriod.value : '',
      period: amortizationPeriod
        ? this.findMatchingOptionFromPeriod(this.options, amortizationPeriod.period)
        : this.options[0],
      amortizationType: this.equipmentSalesContract.amortizationType,
      comment: this.equipmentSalesContract.comment
    });

    this.getDocumentsFrom(this.equipmentSalesContract);
  }

  private setServiceContractFormValues(): void {
    let serviceContract = this.equipmentServiceContracts.find(sc => sc.serviceContractId === this.updatedContractId);

    this.serviceContractForm.patchValue({
      serviceContractNumber: serviceContract.serviceContractNumber,
      contractPartner: serviceContract.contractPartner,
      composedOf: serviceContract.composedOf,
      monthlyRate: serviceContract.monthlyRate,
      comment: serviceContract.comment
    });

    this.getDocumentsFrom(serviceContract);
  }

  private setInsuranceContractFormValues(): void {
    const insuranceContract = this.equipmentInsuranceContracts.find(ic => ic.insuranceContractId === this.updatedContractId);

    this.insuranceContractForm.patchValue({
      insuranceContractNumber: insuranceContract.insuranceContractNumber,
      insuranceContractType: insuranceContract.insuranceContractType,
      contractStartDate: insuranceContract.contractStartDate,
      contractEndDate: insuranceContract.contractEndDate,
      costsPerMonth: insuranceContract.costsPerMonth,
      mileageLimitPerYear: this.dimensionUnitConverterPipe.toUserDimensionUnit(insuranceContract.mileageLimitPerYear, 'km'),
      insuranceContractMileageType: insuranceContract.insuranceContractMileageType,
      comment: insuranceContract.comment
    });

    this.getDocumentsFrom(insuranceContract);
  }

  private setLeasingContractFormValues(): void {
    const leasingContract = this.equipmentLeasingContracts.find(lc => lc.leasingContractId === this.updatedContractId);

    this.leasingContractForm.patchValue({
      leasingContractNumber: leasingContract.leasingContractNumber,
      contractPartner: leasingContract.contractPartner,
      contractStartDate: leasingContract.contractStartDate,
      contractEndDate: leasingContract.contractEndDate,
      mileageLimit: this.dimensionUnitConverterPipe.toUserDimensionUnit(leasingContract.mileageLimit, 'km'),
      operatingHoursLimit: leasingContract.operatingHoursLimit,
      limitationPeriod: leasingContract.limitationPeriod,
      comment: leasingContract.comment
    });

    this.getDocumentsFrom(leasingContract);
  }

  private isContractTypeDisabled(contractType: string): boolean {
    const restrictedContractTypes = contractType === EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT
      || contractType === EquipmentContractType.RENTAL_CONTRACT
      || contractType === EquipmentContractType.SALES_CONTRACT;
    if (this.equipment && this.equipment.equipmentImported && restrictedContractTypes) {
      return true;
    }

    const hasRentalContracts = this.equipmentRentalContracts
      .filter(contract => contract.rentalContractType !== RentalContractType.FULL_SERVICE)
      .some(this.afterTodayRental);
    const isRentalOrSalesContractType = contractType === EquipmentContractType.RENTAL_CONTRACT
      || contractType === EquipmentContractType.SALES_CONTRACT;

    const isFullServiceContractType = contractType === EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT;
    const hasActiveFullServiceContracts = this.equipmentRentalContracts
      .filter(fullServiceContract => fullServiceContract.rentalContractType === RentalContractType.FULL_SERVICE)
      .some(this.afterTodayRental);

    const isInsuranceContractType = contractType === EquipmentContractType.INSURANCE_CONTRACT;
    const hasActiveInsuranceContracts = this.equipmentInsuranceContracts.some(this.afterTodayContract);

    const isLeasingContractType = contractType === EquipmentContractType.LEASING_CONTRACT;
    const hasActiveLeasingContract = this.equipmentLeasingContracts.some(this.afterTodayContract);

    return !!((isRentalOrSalesContractType && (hasRentalContracts || this.equipmentSalesContract))
      || (isFullServiceContractType && hasActiveFullServiceContracts)
      || (isInsuranceContractType && hasActiveInsuranceContracts)
      || (isLeasingContractType && hasActiveLeasingContract));
  }

  private getTooltipText(): string {
    if (this.equipment && this.equipment.equipmentImported) {
      return this.translate('modules.equipment.contract.equipmentImported');
    }
    return this.translate('modules.equipment.contract.contractAlreadyExists');
  }

  private setDisabledOptions(): void {
    this.equipmentContractTypeResolver.disabledOptionsConfiguration = this.contractTypesOptions
      .reduce((acc, contractType) => this.isContractTypeDisabled(contractType)
        ? [...acc, { value: contractType, tooltipText: this.getTooltipText() }]
        : acc,
      []);
  }

  private setFormData(): void {
    this.contractTypeControl.setValue(this.updatedContractType);

    if (this.isContractType(EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT)
      || this.isContractType(EquipmentContractType.RENTAL_CONTRACT)) {
      this.setRentalContractFormValues();
    }
    if (this.isContractType(EquipmentContractType.SALES_CONTRACT)) {
      this.setSalesContractFormValues();
    }
    if (this.isContractType(EquipmentContractType.SERVICE_CONTRACT)) {
      this.setServiceContractFormValues();
    }
    if (this.isContractType(EquipmentContractType.INSURANCE_CONTRACT)) {
      this.setInsuranceContractFormValues();
    }
    if (this.isContractType(EquipmentContractType.LEASING_CONTRACT)) {
      this.setLeasingContractFormValues();
    }
  }

  private addRentalContract(): void {
    if (this.rentalContractForm.valid) {
      const cmd = <AddRentalContractCommand>this.fillRentalContractCommand(new AddRentalContractCommand());
      cmd.rentalContractId = UUID.UUID();
      this.closeDialogAfterSave(this.equipmentStore.addRentalContract(cmd));
    }
  }

  private addSalesContract(): void {
    if (this.salesContractForm.valid) {
      const cmd = <AddSalesContractCommand>this.fillSalesContractCommand(new AddSalesContractCommand());
      cmd.salesContractId = UUID.UUID();
      this.closeDialogAfterSave(this.equipmentStore.addSalesContract(cmd));
    }
  }

  private addServiceContract(): void {
    if (this.serviceContractForm.valid) {
      const cmd = <AddServiceContractCommand>this.fillServiceContractCommand(new AddServiceContractCommand());
      cmd.serviceContractId = UUID.UUID();
      this.closeDialogAfterSave(this.equipmentStore.addServiceContract(cmd));
    }
  }

  private addInsuranceContract(): void {
    if (this.insuranceContractForm.valid) {
      const cmd = <AddInsuranceContractCommand>this.fillInsuranceContractCommand(new AddInsuranceContractCommand());
      cmd.insuranceContractId = UUID.UUID();
      this.closeDialogAfterSave(this.equipmentStore.addInsuranceContract(cmd));
    }
  }

  private addLeasingContract(): void {
    if (this.leasingContractForm.valid) {
      const cmd = <AddLeasingContractCommand>this.fillLeasingContractCommand(new AddLeasingContractCommand());
      cmd.leasingContractId = UUID.UUID();
      this.closeDialogAfterSave(this.equipmentStore.addLeasingContract(cmd));
    }
  }

  private updateRentalContract(): void {
    if (this.rentalContractForm.valid) {
      const cmd = <UpdateRentalContractCommand>this.fillRentalContractCommand(new UpdateRentalContractCommand());
      cmd.rentalContractId = this.updatedContractId;
      this.closeDialogAfterSave(this.equipmentStore.updateRentalContract(cmd));
    }
  }

  private updateSalesContract(): void {
    if (this.salesContractForm.valid) {
      const cmd = <UpdateSalesContractCommand>this.fillSalesContractCommand(new UpdateSalesContractCommand());
      cmd.salesContractId = this.updatedContractId;
      this.closeDialogAfterSave(this.equipmentStore.updateSalesContract(cmd));
    }
  }

  private updateServiceContract(): void {
    if (this.serviceContractForm.valid) {
      const cmd = <UpdateServiceContractCommand>this.fillServiceContractCommand(new UpdateServiceContractCommand());
      cmd.serviceContractId = this.updatedContractId;
      this.closeDialogAfterSave(this.equipmentStore.updateServiceContract(cmd));
    }
  }

  private updateInsuranceContract(): void {
    if (this.insuranceContractForm.valid) {
      let cmd = <UpdateInsuranceContractCommand>this.fillInsuranceContractCommand(new UpdateInsuranceContractCommand());
      cmd.insuranceContractId = this.updatedContractId;
      this.closeDialogAfterSave(this.equipmentStore.updateInsuranceContract(cmd));
    }
  }

  private updateLeasingContract(): void {
    if (this.leasingContractForm.valid) {
      let cmd = <UpdateLeasingContractCommand>this.fillLeasingContractCommand(new UpdateLeasingContractCommand());
      cmd.leasingContractId = this.updatedContractId;
      this.closeDialogAfterSave(this.equipmentStore.updateLeasingContract(cmd));
    }
  }

  private closeDialogAfterSave(saveContractObs: Observable<any>): void {
    saveContractObs
      .pipe(untilDestroyed(this))
      .subscribe(
        () => this.dialogRef.close(),
        () => this.isSavingProcess = false
      );
  }

  private fillRentalContractCommand(cmd: AddRentalContractCommand | UpdateRentalContractCommand) {
    const formData = this.rentalContractForm.value;
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.rentalContractNumber = formData.rentalContractNumber;
    cmd.amount = formData.amount;
    cmd.contractPartner = formData.contractPartner;
    cmd.rentalStartDate = DatesService.date(formData.rentalStartDate);
    cmd.rentalEndDate = DatesService.date(formData.rentalEndDate);
    cmd.rentalMileageLimitPeriod = formData.rentalMileageLimitPeriod
        ? this.utils.parseToPeriod(formData.rentalMileageLimitPeriod, formData.period.value)
        : '';
    cmd.composedOf = this.isContractType(EquipmentContractType.RENTAL_FULL_SERVICE_CONTRACT)
        ? formData.composedOf
        : null;
    cmd.rentalContractType = this.isContractType(EquipmentContractType.RENTAL_CONTRACT)
        ? formData.rentalContractType
        : RentalContractType.FULL_SERVICE;
    cmd.imported = false;
    cmd.importContractIdentifier = '';
    cmd.documents = this.documents;
    cmd.comment = formData.comment;

    return cmd;
  }

  private fillSalesContractCommand(cmd: AddSalesContractCommand | UpdateSalesContractCommand) {
    const formData = this.salesContractForm.value;
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.salesContractNumber = formData.salesContractNumber;
    cmd.contractPartner = formData.contractPartner;
    cmd.cost = formData.cost;
    cmd.amortizationPeriod = formData.amortizationPeriod
        ? this.utils.parseToPeriod(formData.amortizationPeriod, formData.period.value)
        : '';
    cmd.amortizationType = formData.amortizationType;
    cmd.imported = false;
    cmd.documents = this.documents;
    cmd.comment = formData.comment;

    return cmd;
  }

  private fillServiceContractCommand(cmd: AddServiceContractCommand | UpdateServiceContractCommand) {
    const formData = this.serviceContractForm.value;
    cmd.equipmentId = this.equipment.equipmentId;
    cmd.serviceContractNumber = formData.serviceContractNumber;
    cmd.contractPartner = formData.contractPartner;
    cmd.composedOf = formData.composedOf;
    cmd.monthlyRate = formData.monthlyRate;
    cmd.imported = false;
    cmd.documents = this.documents;
    cmd.comment = formData.comment;

    return cmd;
  }

  private fillInsuranceContractCommand(cmd: AddInsuranceContractCommand | UpdateInsuranceContractCommand) {
    let formData = this.insuranceContractForm.value;

    cmd.equipmentId = this.equipment.equipmentId;
    cmd.insuranceContractNumber = formData.insuranceContractNumber;
    cmd.insuranceContractType = formData.insuranceContractType;
    cmd.contractStartDate = DatesService.date(formData.contractStartDate);
    cmd.contractEndDate = DatesService.date(formData.contractEndDate);
    cmd.costsPerMonth = formData.costsPerMonth;
    cmd.mileageLimitPerYear = this.dimensionUnitConverterPipe.toSystemDimensionUnit(formData.mileageLimitPerYear, 'km');
    cmd.insuranceContractMileageType = formData.insuranceContractMileageType;
    cmd.imported = false;
    cmd.documents = this.documents;
    cmd.comment = formData.comment;

    return cmd;
  }

  private fillLeasingContractCommand(cmd: AddLeasingContractCommand) {
    let formData = this.leasingContractForm.value;

    cmd.equipmentId = this.equipment.equipmentId;
    cmd.leasingContractId = formData.leasingContractId;
    cmd.leasingContractNumber = formData.leasingContractNumber;
    cmd.contractPartner = formData.contractPartner;
    cmd.contractStartDate = DatesService.date(formData.contractStartDate, DatesService.utcFormat);
    cmd.contractEndDate = DatesService.date(formData.contractEndDate, DatesService.utcFormat);
    cmd.mileageLimit = this.dimensionUnitConverterPipe.toSystemDimensionUnit(formData.mileageLimit, 'km');
    cmd.operatingHoursLimit = formData.operatingHoursLimit;
    cmd.limitationPeriod = formData.limitationPeriod;
    cmd.comment = formData.comment;
    cmd.documents = this.documents;
    cmd.imported = false;

    return cmd;
  }

  private getContracts(): void {
    if (this.equipment?.equipmentId) {
      this.equipmentStore.getContracts(this.equipment.equipmentId)
        .pipe(untilDestroyed(this))
        .subscribe((res: ViewEquipmentContracts) => {
          if (res) {
            this.equipmentRentalContracts = res.equipmentRentalContracts;
            this.equipmentSalesContract = res.equipmentSalesContract;
            this.equipmentServiceContracts = res.equipmentServiceContracts;
            this.equipmentInsuranceContracts = res.equipmentInsuranceContracts;
            this.equipmentLeasingContracts = res.equipmentLeasingContracts;
            this.setDisabledOptions();
            this.setLeasingDateFilter();
            this.setLeasingContractFormValidators();

            if (this.updatedContractId) {
              this.setFormData();
            }
          }
        });
    }
  }

  private findMatchingOptionFromPeriod(options: any[], period: string): void {
    return options.find(option => option.value === this.utils.parseFromPeriod(period).period);
  }

  private getDocumentsFrom(contract: any): void {
    this.documents = contract.documents.map(document => AttachedDocument.fromData(document));
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
