import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '../../../../core/keycloak';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseLandingpageListComponent } from '../base/base-landingpage-list.component';
import { OnlineStatusService } from '../../../../shared/services/online-status.service';
import { CustomDeviceDetectorService } from '../../../../shared/services/custom-device-detector.service';
import { RouterHistory } from '../../../../shared/router-history';

@Component({
  selector: 'bh-landingpage-list',
  templateUrl: './landingpage-list.component.html',
  styleUrls: ['./landingpage-list.component.scss']
})
export class LandingpageListComponent extends BaseLandingpageListComponent implements OnInit {

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private deviceService: CustomDeviceDetectorService,
              private onlineStatusService: OnlineStatusService) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.handleRouter();
  }

  handleRouter() {
    if (this.deviceService.isMobile()) {
      return this.router.navigate(['mobile/landingpage']);
    }

    if (this.onlineStatusService.onlineStatus) {
      if (this.getUserCustomerId()) {
        this.router.navigate(['assets/equipment/list']);
      } else {
        this.router.navigate(['customers/list']);
      }
    }
  }

}
